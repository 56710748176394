import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, Observable, of, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { ImpersonatUserContext, UserContextModel } from '../shared/models/appsettings.model';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from '../shared/models/common';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { MenuService } from './menu.service';
import { AppSettingsService } from './app-settings.service';
import { PermissionService } from './permission.service';
import { MsalService } from '@azure/msal-angular';
import { TermsAndConditionsService } from './terms-and-conditions.service';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoading.asObservable();
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public _isLoading$ = this.isLoadingSubject.asObservable();
  private apiUrl = environment.apiBaseUrl;
  private refreshTokenSubject = new Subject<void>();
  private readonly refreshTokenInterval = 30000; // 30 seconds
  private timer$ = timer(0, this.refreshTokenInterval);

  public get C3userId() {
    if (this.IsCustomerImpersonated) {
      return this.ImpersonationContext.C3UserId;
    }
    else if (this.IsResellerImpersonated) {
      return this.ResellerImpersonationContext.C3UserId;
    }
    else {
      return null;
    }
  }

  public get LoggedInUserName() {
    return localStorage.getItem("EmailAddress");
  }

   // Set loading state to true
  startLoading() {
    this._isLoading.next(true);
  }

  // Set loading state to false
  stopLoading() {
    this._isLoading.next(false);
  }

  private _userContext: BehaviorSubject<UserContextModel> = new BehaviorSubject<UserContextModel | null>(null);

  constructor(private _http: HttpClient,
    private commonService: CommonService,
    private appSettingService: AppSettingsService,
    private permissionService: PermissionService,
    private _authService: MsalService,
    private menuService: MenuService,) {



  }

  public get UserContext() {
    return this._userContext.value;
  }

  public get ImpersonationContext() {
    return <ImpersonatUserContext>JSON.parse(this.commonService.getFromLocalStorge('impersonationContext'));
  }

  public get ResellerImpersonationContext() {
    return <ImpersonatUserContext>JSON.parse(this.commonService.getFromLocalStorge('resellerImpersonationContext'));
  }

  public get IsCustomerImpersonated() {
    if (this.ImpersonationContext != null) {
      return true;
    }
    return false;
  }

  public get IsResellerImpersonated() {
    if (this.ResellerImpersonationContext != null) {
      return true;
    }
    return false;
  }

  // Method to update the user context
  updateUserContext(newContext: any) {
    this._userContext.next(newContext);
  }


  setCurrentUserContextAsPrimary(userContext: UserContextModel) {
    if (userContext !== undefined && userContext !== null) {
      let postData = {
        C3UserId: userContext.C3UserId
      };

      this._http.put<ResponseData<any[]>>(`${this.apiUrl}/usercontext/SetCurrentUserContextAsPrimary`, postData)
        .subscribe((response: any) => {
          if (response.Status === 'Success') {
            localStorage.setItem("IsRequestFromContextChanged", "true");
            localStorage.removeItem("userContextList");
            this.updateUserContext(null);
            localStorage.removeItem("EntityName");
            localStorage.removeItem("RecordId");
            localStorage.removeItem("EmailAddress");
            localStorage.removeItem("C3UserId");
            localStorage.removeItem("ResellerC3Id");
            //window.location.reload();
            window.location.href = '/loggedin';
             
          }
        });
    }
  }

  setUserContext(url:string=null) {
    let userContextList = [];
    let userContext: any = { entityName: null, recordId: null, userC3Id: null, roleName: null, resellerC3Id: null };

    var oldUserContextJsonString = localStorage.getItem("userContextList");
    if (oldUserContextJsonString !== undefined && oldUserContextJsonString !== null && oldUserContextJsonString !== "null" && oldUserContextJsonString !== "" && oldUserContextJsonString !== "[]") {
      var oldUserContextList = JSON.parse(oldUserContextJsonString);

      if (oldUserContextList.length > 0 && oldUserContextList[0].EntityName !== undefined && oldUserContextList[0].EntityName !== null && oldUserContextList[0].EntityName !== 'null') {
        let topLevelUser = oldUserContextList[0];
        userContextList.push(topLevelUser);
      }
      else {
        userContext = {
          EntityName: localStorage.getItem("EntityName"),
          RecordId: localStorage.getItem("RecordId"),
          UserEmail: localStorage.getItem("EmailAddress"),
          C3UserId: localStorage.getItem("C3UserId"),
          IsInheritedByPartner: false,
          IsInheritedByReseller: false,
          RoleName: localStorage.getItem("RoleName"),
          ResellerC3Id: localStorage.getItem("ResellerC3Id")
        };
        this.updateUserContext(userContext);
        userContextList.push(userContext);
      }
    }
    else {
      userContext = {
        EntityName: localStorage.getItem("EntityName"),
        RecordId: localStorage.getItem("RecordId"),
        UserEmail: localStorage.getItem("EmailAddress"),
        C3UserId: localStorage.getItem("C3UserId"),
        IsInheritedByPartner: false,
        IsInheritedByReseller: false,
        RoleName: localStorage.getItem("RoleName"),
        ResellerC3Id: localStorage.getItem("ResellerC3Id")
      };
      this.updateUserContext(userContext);
      userContextList.push(userContext);
    }

    //Check for impersonation
    let isInheritedByPartner = false;
    let isInheritedByPartnerOrReseller = false;
    let resellerImpersonationContext: any = localStorage.getItem("resellerImpersonationContext");
    if (resellerImpersonationContext !== null) {
      resellerImpersonationContext = JSON.parse(resellerImpersonationContext);

      isInheritedByPartner = resellerImpersonationContext.InheritRole;

      //If user name conatains double quotes inside, then add slash before double quote
      resellerImpersonationContext.Username = resellerImpersonationContext.Username.replace(/"/g, '\\"');
      let resellerUserContext =
      {
        EntityName: resellerImpersonationContext.EntityName,
        RecordId: resellerImpersonationContext.RecordId,
        UserEmail: encodeURIComponent(resellerImpersonationContext.Username),
        C3UserId: resellerImpersonationContext.C3UserId,
        IsInheritedByPartner: resellerImpersonationContext.InheritRole,
        IsInheritedByReseller: false,
        RoleName: localStorage.getItem("RoleName")
      };
      userContextList.push(resellerUserContext);
    }

    let impersonationContext: any = localStorage.getItem("impersonationContext");
    if (impersonationContext !== null) {
      impersonationContext = JSON.parse(impersonationContext);

      let resellerContexts = userContextList.filter((userContext) => { return userContext.EntityName === "Reseller"; });

      let isPartnerInherited = false;
      let isResellerInherited = false;

      if (resellerContexts !== undefined && resellerContexts !== null && resellerContexts.length > 0) {
        if (impersonationContext.InheritRole === true && resellerContexts[0].IsInheritedByPartner) {
          isResellerInherited = true;
          isPartnerInherited = true;
        }
        else if (impersonationContext.InheritRole === true && !resellerContexts[0].IsInheritedByPartner) {
          isResellerInherited = true;
        }
      }
      else if (impersonationContext.InheritRole === true) {
        isPartnerInherited = true;
      }

      //If user name conatains double quotes inside, then add slash before double quote
      impersonationContext.Username = impersonationContext.Username.replace(/"/g, '\\"');
      var partnerUserContext =
      {
        EntityName: impersonationContext.EntityName,
        RecordId: impersonationContext.RecordId,
        UserEmail: encodeURIComponent(impersonationContext.Username),
        C3UserId: impersonationContext.C3UserId,
        IsInheritedByPartner: isPartnerInherited,
        IsInheritedByReseller: isResellerInherited,
        RoleName: impersonationContext.RoleName,
        ResellerC3Id: impersonationContext.ResellerC3Id
      };
      userContextList.push(partnerUserContext);
    }

    //localStorage.setItem("userContextList", JSON.stringify(userContextList));

    this.commonService.setValueInLocalStorage("userContextList", JSON.stringify(userContextList)).then(()=>{
      this.appSettingService.getUserProfileContext().subscribe((profileContext:any) => {
        if(profileContext){
          this.menuService.SetMenuItems(profileContext.MenuItems);
          this.permissionService.setPermissionList(profileContext.UserRoleAccessPermissions);
          //this._router.navigate([`home/dashboard`])
          let anchor = document.createElement('a');
          let urlHref = url? url : `${window.location.protocol}//${window.location.host}/home/dashboard`;
          anchor.href = urlHref;
          anchor.click();
        }else{
          this.logOut();
        }
        //this.GetIsTermsAndConditions();
      });
    })

    
  }

  // GetIsTermsAndConditions(){
  //   const res = await  this.TermsAndConditionsService.hasUserAcceptedTermsAndConditions();
  //   this.TermsAndConditionsService.IsAcceptedTermsAndConditions = (res as any)?.Data?.HasAcceptedTermsAndConditions
  // }

  stopImpersonation(isCustomerContext: boolean) { 
    let url = `${window.location.protocol}//${window.location.host}`;
    if (isCustomerContext) {
      var impersonationContext: any = localStorage.getItem("impersonationContext");
      if (impersonationContext !== null) {
        impersonationContext = JSON.parse(impersonationContext);
      }
      if (impersonationContext.ImpersonatedFrom == 'partner.customers') {
        url = url + "/partner/customers";
      }
      if (impersonationContext.ImpersonatedFrom == 'customer.microsoftuser') {
        url = url + "/customer/microsoftuser";
      }
      localStorage.removeItem("impersonationContext");
      if (!this.IsResellerImpersonated) {
        localStorage.removeItem("RecordId");
        localStorage.setItem("EntityName", "Partner");
      } else {
        let resellerImpersonationContext: any = localStorage.getItem("resellerImpersonationContext");
        resellerImpersonationContext = JSON.parse(resellerImpersonationContext);
        localStorage.setItem("RecordId", resellerImpersonationContext.RecordId);
        localStorage.setItem("EntityName", "Reseller");
      }

    }
    else {
      url = url + "/partner/resellers";
      localStorage.removeItem("resellerImpersonationContext");
      localStorage.setItem("EntityName", "Partner");
      localStorage.removeItem("RecordId");
    }
    this.setUserContext(url);
  }

  saveOrUpdateUserConfiguration(body: any) {
    return this._http.post(`${this.apiUrl}/user/SaveOrUpdateUserConfiguration`, body);
  }

  logOut() {
    localStorage.clear();
    this._authService.logoutRedirect()
  }


  triggerRefreshAccessToken(): Observable<any> {
    return this.timer$.pipe(
      switchMap(() => this.refreshAccessToken()),
      takeUntil(this.refreshTokenSubject),
      switchMap((response) => {
        return of(response);
      }),
      catchError((error) => {
        console.error('Token acquisition failed:', error);
        this.logOut();
        return of(null);
      })
    )
  }

  stopRefreshAccessToken(): void {
    this.refreshTokenSubject.next();
  }

  refreshAccessToken(): Observable<string> {
    const account = this._authService.instance.getActiveAccount();
    if (!account) {
      this._authService.loginRedirect({
        scopes: [...environment.apiConfig.scopes],
      });
      return of(null);
    }

    return this._authService.acquireTokenSilent({
      scopes: [...environment.apiConfig.scopes],
      account: account
    }).pipe(
      switchMap((response) => {
        console.log('Token refreshed successfully');
        return of(response.accessToken);
      }),
      catchError((error) => {
        console.error('Token acquisition failed:', error);
        this._authService.loginRedirect({
          scopes: [...environment.apiConfig.scopes],
        });
        return of(null);
      })
    );
  }

  setLoading(isLoading: boolean): void {
    this.isLoadingSubject.next(isLoading);
  }
}