<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <iframe frameborder="0" class="embed-responsive-item" frameborder="0" src="https://docs.cspcontrolcenter.com/" marginheight="1" marginwidth="1" name="cboxmain" id="cboxmain" seamless="seamless"  allowtransparency="true"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>