<!--begin::Header container-->
<div class="app-container" id="kt_app_header_container" [class]="headerContainerCssClass" [ngClass]="{'' : impersonated}">
  <ng-container *ngIf="appSidebarDefaultCollapseDesktopEnabled">
    <div class="app-sidebar-collapse-d-flex align-items-center me-3">
      <!--begin::sidebar toggle-->
      <div class="btn btn-icon w-auto px-0 btn-color-muted btn-active-icon-primary" data-kt-toggle="true"
        data-kt-toggle-target="body" data-kt-toggle-mode="on" data-kt-toggle-name="app-sidebar-collapse">
        <app-keenicon name="double-right" class="fs-1"></app-keenicon>
      </div>
      <!--end::sidebar toggle-->
    </div>
  </ng-container>

  <ng-container *ngIf="appSidebarDisplay">
    <!--begin::sidebar mobile toggle-->
    <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
      <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
        <app-keenicon name="abstract-14" class="fs-1"></app-keenicon>
      </div>
    </div>
    <!--end::sidebar mobile toggle-->
    <!--begin::Mobile logo-->
    <!--end::Mobile logo-->
  </ng-container>

  <ng-container *ngIf="!appSidebarDisplay">
    <!--begin::Logo-->
    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
        <ng-container *ngIf="currentLayoutType === 'dark-header'">
          <img alt="Logo" src="./assets/media/logos/default-dark.svg"
            class="h-20px h-lg-30px app-sidebar-logo-default" />
        </ng-container>
        <ng-container *ngIf="currentLayoutType !== 'dark-header'">
          <img alt="Logo" src="./assets/media/logos/default.svg"
            class="h-20px h-lg-30px app-sidebar-logo-default theme-light-show" />
          <img alt="Logo" src="./assets/media/logos/default-dark.svg"
            class="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show" />
        </ng-container>
    </div>
    <!--end::Logo-->
  </ng-container>

  <!--begin::Header wrapper-->
  <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
    <div class="align-items-stretch"></div>

    <app-navbar class="app-navbar flex-shrink-0" [appHeaderDefaulMenuDisplay]="appHeaderDefaulMenuDisplay"
      [isRtl]="false"></app-navbar>
  </div>
  <!--end::Header wrapper-->
  <!-- <div class="menu-item me-lg-1">
    <a class="menu-link py-3" routerLinkActive="active menu-here" routerLink="/dashboard">
      <span class="menu-title"
            translate="MENU.DASHBOARD"></span>
    </a>
  </div> -->
</div>

<!--end::Header container-->