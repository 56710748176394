import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PartnerModule } from '../../partner/partner.module';

@Component({
  selector: 'app-notification-template',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    PartnerModule,
  ],
  templateUrl: './notification-template.component.html',
  styleUrl: './notification-template.component.scss'
})
export class NotificationTemplateComponent {
@Input() title?: string;
  @Input() description?: string;
  @Input() buttonDetails?: any
  @Input() templateName?:string;
  @Input() notificationType?:string;


}
