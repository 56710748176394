import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageInfoService } from 'src/app/_c3-lib/layout/core/page-info.service';
import { CommonService } from 'src/app/services/common.service';
import { DynamicTemplateService } from 'src/app/services/dynamic-template.service';
import { FileService } from 'src/app/services/file.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserContextService } from 'src/app/services/user-context.service';
import { QuoteService } from '../quotes.service';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { C3BaseComponent } from 'src/app/shared/models/c3BaseComponent';
import _ from 'lodash';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { UnsavedChangesService } from 'src/app/services/unsaved-changes.service';

@Component({
  selector: 'app-quote-addnewcustomer',
  templateUrl: './quote-addnewcustomer.component.html',
  styleUrl: './quote-addnewcustomer.component.scss'
})
export class QuoteAddnewcustomerComponent extends C3BaseComponent implements OnInit,OnDestroy {

  frmAddNewUser: FormGroup;
  userArray = [] ;
  @Input() type: string = 'form';
  userList = [];
  selectedUserList: any[] = [];
  @Input() quoteContact: any[] = [];
  @Input() addCustomerC3Id  = null;
  isloading: boolean = false;
  private destroy$ = new Subject<void>;
  constructor(
    private _formBuilder: FormBuilder,
    public _router: Router,
    public _permissionService: PermissionService,
    public _dynamicTemplateService: DynamicTemplateService,
    private userContext: UserContextService,
    private _translateService: TranslateService,
    private _notifierService: NotifierService,
    private _toastService: ToastService,
    private pageInfo: PageInfoService,
    private _fileService: FileService,
    private _commonService: CommonService,
    private renderer: Renderer2,
    private _cdRef: ChangeDetectorRef,
    private _quotesService: QuoteService,
    private _clientSettingsService: ClientSettingsService,
    private _applicationSettings : AppSettingsService,
    private _modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private _unsavedChangesService: UnsavedChangesService
  ) {
    super(_permissionService, _dynamicTemplateService, _router, _applicationSettings);
    this.frmAddNewUser = this._formBuilder.group({
      FirstName :['', [Validators.required,Validators.maxLength(50)]],
      LastName :['', [Validators.required,Validators.maxLength(50)]],
      Email :['', [Validators.required,Validators.email]],
      PhoneNumber: [''],
    });

    }

    ngOnInit(): void {
      if(this.type == 'list'){
        this.customerContactList();
      }
    }

   

  submitForm() {
    let user = this.frmAddNewUser.getRawValue();
    this.frmAddNewUser.markAllAsTouched();
    user.FullName = user.FirstName + " " + user.LastName;
    user.EmailId = user.Email;
    if (this.frmAddNewUser.valid) {
      this.userArray.push(user);
      console.log(this.userArray);
      this.frmAddNewUser.reset();

      let result = this.userArray;
      this.activeModal.close(result);
    }
    else {
      this._toastService.error(
        this._translateService.instant('TRANSLATE.VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT'));
    }
  }

  cancel() {
    this.activeModal.dismiss()
  }

customerContactList() {
  this._quotesService.getCustomerAdminUsers(this.addCustomerC3Id).subscribe((response:any)=> {
        this.isloading = false;
        this.userList = response.Data;
        this.userList = this.userList.filter(user => user.IsPrimaryContext != false);
        this.userList.forEach((user) => {
            if (_.find(this.quoteContact, (selectedUser: any) => selectedUser.EmailId === user.EmailId)) {
                user.IsChecked = true;
                user.isDisabled = true;
            }
        });
       
    });
};

checkAllIsCheck(column) {
    if (column.IsChecked) {
        this.selectedUserList.push(column);

    } else {
        var index = this.selectedUserList.indexOf(column);
        if (index > -1) {
            this.selectedUserList.splice(index, 1);
        }
    }
}
submitInList() {
    var result = this.selectedUserList;
    this.activeModal.close(result)
}; 
cancelInList() {
  this.activeModal.dismiss()
};

trackByFn(index: number, item: any): any {
  return item.id; // Adjust this as per your data model
}
onScroll() {
  if (!this.isloading) {
    this.isloading = true;
  }
}
ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
  this._unsavedChangesService.setUnsavedChanges(false);
}
  
}
