import { CommonModule, NgFor } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, input } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerModule, NgbModal, NgbModalOptions, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { Api } from 'datatables.net';
import { Subject, Subscription, debounceTime, fromEvent, map, merge } from 'rxjs';
import { SharedModule } from 'src/app/_c3-lib/shared/shared.module';
import { checkboxColumn, defaultCheckboxProps } from './c3-table-utils'; 
import { AddressRowComponent } from '../../home/profile/profile-user/addresses-details/adress-row/adress-row.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import _ from 'lodash';
import { CommonNoRecordComponent } from "../common-no-record/common-no-record.component";
declare const bootstrap: any;
@Component({
  selector: 'app-c3-table',
  standalone: true,
  imports: [
    DataTablesModule,
    NgFor,
    SharedModule,
    CommonModule,
    NgbModule,
    NgbDatepickerModule,
    FormsModule,
    TranslateModule,
    CommonNoRecordComponent
],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],

  templateUrl: './c3-table.component.html',
  styleUrl: './c3-table.component.scss'
})
export class C3TableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('c3table', { static: true }) c3table: ElementRef;
  @Output() tableReady = new EventEmitter<ElementRef>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Input() datatableConfig: ADTSettings = {};
  @Input() tableId!: any;
  @ViewChild('tableBody', { read: ViewContainerRef, static: false }) tableBody!: ViewContainerRef;
  @ViewChild('additionalRow', { read: ViewContainerRef, static: false }) additionalRow!: ViewContainerRef;
  private componentRefs = new Map<number, any>(); // To track components
  private nextRowId = 0;
  public totalCount:number = 0
  activePageSize: number = 10
  previousSelectedData: any = [];
  @Input() route: string = '/';
  @Input() isNestedGrid: boolean = false;
  @Input() tooltipText!: any;

  // Reload emitter inside datatable
  @Input() reload: EventEmitter<boolean>;

  @Input() checkboxWithTooltip: boolean;
  @Input() modal: TemplateRef<any>;
  @Input() searchFilter: boolean = true;
  @Input() customeHeader!: TemplateRef<any> | null;
  @Input() multiSearch: TemplateRef<any> | null;
  @Input() isPaginationHide?: boolean = false;
  @Input() defaultPageObj?: any;
  @Input() isPaginationRowHide?: boolean = false;
  @Input() selection: boolean;
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  @Input() hasPagination: boolean = true;
  isServerSide : boolean;

  pageOptions: number[] = [5,10, 25, 50, 100, 200, 500, 1000]
  enableSearch: boolean;
  columnSearchOptions: any[] = [];
  dtOptions: ADTSettings | any = {
    dom: "<'row'<'col-sm-12 p-0'tr>>" +
      "<'row'<'d-flex col-sm-12 col-md-5 ps-0'il><'col-sm-12 col-md-7'p>>",
    // dom:'<"bottom"lfip><"clear">rt',
    language: {
      emptyTable: '  ',
      processing: '<span class="spinner-border spinner-border-sm align-middle"></span> Loading...',
      paginate: {
        next: 'Next',
        previous: 'Previous'
      },
      info: 'Showing _START_ to _END_ of _TOTAL_ records',
      infoEmpty: 'Showing 0 to 0 of 0 records',
      infoFiltered: '',
      // infoFiltered: '(filtered from _MAX_ total records)',
    },
    length: this.activePageSize,
    lengthMenu: [5,10, 25, 50, 100, 200, 500, 1000],
    initComplete: function () {
      const self = this;
      self.columnSearchOptions = [];
      self.api().columns().every(function () {
        const column = this;
        const title = column.header().textContent.trim(); // Column title
        // Check if column is searchable based on its settings
        if (column.settings()[0].aoColumns[column.index()].searchable) {
          self.columnSearchOptions.push(column.dataSrc())
          // Create input element and add event listener
          $(`<div class="d-grid align-items-center position-relative my-1">
              <app-keenicon name="magnifier" class="fs-3 ms-5 mb-1" >
                <span class="fs-3 ki-duotone ki-magnifier ms-5 position-absolute">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </app-keenicon>
              <input type="text" data-field="${column.dataSrc()}" data-action="filter" class="header-input-field form-control form-control-sm form-control-solid w-150px ps-10"
                placeholder="Search...">
            </div>`)
            .appendTo($(column.footer()).empty());
        } else if (column.settings()[0].aoColumns[column.index()].selectable) {
          let optionArray = column.settings()[0].aoColumns[column.index()].optionsArray
          let option = ``;
          optionArray.forEach(element => {
            option += `<option value="${element.id}">${element.name}</option>`;
          });
          // Create input element and add event listener
          $(`<div class="col-auto">
            <select  data-field="${column.dataSrc()}" data-action="filter" class="header-select form-select form-select-solid form-select-lg fw-bold">
            <option value="" ${column.settings()[0].aoColumns[column.index()]?.disableDefaultSelect ? 'disabled selected' : ''}>Select</option>
            ${option}
            </select>
        </div>`)
            .appendTo($(column.footer()).empty());
        }
        else {
          // If column is not searchable, do not add any search field
          $(column.footer()).empty(); // Clear footer content
        }
      });
    },
    drawCallback: function () { 
      let isServerSide = "";
      if(localStorage.getItem("isServerSide") != undefined){
        isServerSide = localStorage.getItem("isServerSide");
      }
      const table = this.api(); 
      const uncheckedCheckboxes = document.querySelectorAll('input[type=checkbox].row-selection-checkbox:not(:checked)');
      const selectAllCheckbox = document.querySelector('input.dt-checkboxes') as HTMLInputElement;
      const allCheckboxes = document.querySelectorAll('input[type=checkbox].row-selection-checkbox'); 
      allCheckboxes.forEach((checkbox: any) => {
        const rowData = table.rows(checkbox.value).data()[0];
        if (rowData.isCheckBoxChecked) {
          checkbox.checked = true;
        } 
      }); 
      if(selectAllCheckbox && selectAllCheckbox.checked && !this.removeSelectAll){
        uncheckedCheckboxes.forEach((checkbox: any) => {
          checkbox.checked = true;
        });
      }
      if(selectAllCheckbox && selectAllCheckbox.checked && isServerSide == "true"){
        allCheckboxes.forEach((checkbox : any)=>{
          checkbox.checked = false;
        })
        selectAllCheckbox.checked =false;
      }
      
      const tableBody = $(table.table().body());
      const columnCount = table.columns().count();
      let columnData = Array(columnCount).fill(null).map(() => []);
      const dataCount= table.rows().count() 
      // Initialize an array to hold total costs for each column
      let totalCosts = Array(columnCount).fill('');
      let currencySymbol;
      let currencyCode;
      let showTotalRow = false;
      table.columns().every(function () {
        const column = this;
        const columnIndex = column.index();
        // Check if the column should show a total
        const columnSettings = column.settings()[0].aoColumns[columnIndex];
        if (columnSettings.totalCost) {
          const data = column.data().toArray();
          const rowData = table.rows().data().toArray();
          const totalSum = rowData[0]?.TotalCost;
          currencySymbol = rowData[0]?.CurrencySymbol;
          currencyCode = rowData[0]?.CurrencyCode;
          // Get the total cost from the column settings
          totalCosts[columnIndex] = totalSum.toFixed(2); // Adjust format as needed
          if (!!totalSum) {
            showTotalRow = true;
          }
        }
      });

      // Create HTML for the total cost row
      let totalCostHtml = totalCosts.map((cost, index) =>
        index === 0
          ? `<td class="text-dark-75 fw-bolder font-size-lg">Total Amount</td>`  // Always display "Total Amount" in the first column
          : (!!cost
            ? `<td class="text-end text-dark-75 fw-bolder font-size-lg">${currencyCode}${currencySymbol}${cost}</td>`
            : `<td></td>`) // Empty cell for columns with no cost
      ).join('');

      // Clear old total row and append new total row
      tableBody.find('.total-cost-row').remove();
      if (showTotalRow) {
        tableBody.append(`<tr class="total-cost-row">${totalCostHtml}</tr>`);
      }
    }

  };
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();


  @ViewChild(DataTableDirective, { static: true })
  private _datatableElement: DataTableDirective;

  private _subscription: Subscription;
  modalConfig: NgbModalOptions = {
    modalDialogClass: 'modal-dialog modal-dialog-centered mw-650px',
  };
  private _modalRef: NgbModalRef;

  private clickListener: () => void;
  loadPagination: boolean = false;
  dataTable: any;
  childTableInit: boolean = false;
  isTableNoRecord: boolean = false;

  constructor(
    private _modalService: NgbModal,
    private _cdref: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2

  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      ...this.dtOptions,
      ...this.datatableConfig
    };
    this.isServerSide = this.datatableConfig?.serverSide || false;
    localStorage.setItem("isServerSide",this.isServerSide.toString());
    this.enableSearch = this.dtOptions.columns.some(elm => elm.searchable || elm.selectable);
    // make use of this function to add logic for advance features
    this.handleTableConfiguration();
    if (this.reload) {
      this.reload.subscribe(data => {
        //this._modalService?.dismissAll();
        this._datatableElement.dtInstance.then((dtInstance: Api) => {
          this.loadPagination = true;
          this.isTableNoRecord = false;
          if (!!this.datatableConfig.ajax) {
            dtInstance.ajax.reload(()=>{
              let rowCount = dtInstance.rows({ search: 'applied' }).count();
              this.setPaginationDefault(rowCount);
            })
          } else if (!!this.datatableConfig.data) {
            dtInstance.clear();            
            dtInstance.rows.add(this.datatableConfig.data).draw();
            if(this.datatableConfig.data.length == 0){
              this.loadPagination = false;
              this.isTableNoRecord = true; 
              this.isPaginationHide = true; 
            }
            let self = this;
            this._datatableElement.dtInstance.then((dtInstance: Api) => {
              dtInstance.columns().every(function () { 
                let rowCount = dtInstance.rows({ search: 'applied' }).count();
                self.setPaginationDefault(rowCount); 
              });
              // dtInstance.search(value);
              dtInstance.draw();
            })
            let rowCount = dtInstance.rows({ search: 'applied' }).count();
            this.setPaginationDefault(rowCount);
          } 

        });
      });
    }
    this.addDataTableEventListener();
  }

  reloadGrid() {
    this.dtOptions.length = this.activePageSize;
    this.dtOptions.pageLength = this.activePageSize;
    if (this._datatableElement.dtInstance) {
      this._datatableElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next(this.dtOptions);
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // race condition fails unit tests if dtOptions isn't sent with dtTrigger
      this.dtTrigger.next(this.dtOptions);
      this.addDataTableEventListener();
      // Emit the table reference once the view has been initialized
      if (this.isNestedGrid) {
        this.tableReady.emit(this.c3table); 
      } 
    },);

  }

  generateArray(length:any) {
    let result = [];
    for (let i = 0; i < length; i++) {
      result.push(i.toString());
    }
    return result;
  }
  
  

  handleTableConfiguration() {
    if (this.selection) {
      let totalCount:any
      const selectionColumnDef: any = {
        ...checkboxColumn,
        checkboxes: {
          ...(this.checkboxWithTooltip ? checkboxColumn.checkboxesWithTooltip : checkboxColumn.checkboxes),
          selectCallback: async (nodes: any, selected: any) => {
            const api: Api = await this._datatableElement.dtInstance;
            totalCount = api.rows().count();
            let arr:any = this.generateArray(totalCount||0);
            let selectedData: any[] = [];
            const selectedCheckboxes = document.querySelectorAll('input[type=checkbox].row-selection-checkbox:checked');
            const uncheckedCheckboxes = document.querySelectorAll('input[type=checkbox].row-selection-checkbox:not(:checked)');
            const selectAllCheckbox = document.querySelector('input.dt-checkboxes') as HTMLInputElement;
            let unSelectedCheckboxData:any = [];
                  uncheckedCheckboxes.forEach((checkbox: any) => {
                    const rowData = api.rows(checkbox.value).data()[0];
                    if (rowData) {
                      unSelectedCheckboxData.push(rowData);
                    }
                  });
            let selectedCheckboxData:any = [];
                  selectedCheckboxes.forEach((checkbox: any) => {
                    const rowData = api.rows(checkbox.value).data()[0];
                    if (rowData) {
                      selectedCheckboxData.push(rowData);
                    }
                  });
            // Update "Select all" checkbox state
            this.updateSelectAllCheckboxState(totalCount);
            if(this.datatableConfig.serverSide){
              this.previousSelectedData = [];
            }
            let previousData:number = this.previousSelectedData?.length || 0;
            if(selectAllCheckbox.checked){
              arr.forEach((checkbox: any) => {
                const rowData = api.rows(checkbox).data()[0];
                if (rowData) {
                  selectedData.push(rowData);
                } 
              });
            }
            else{
              let totalCurrentPageElements = (selectedCheckboxes?.length + uncheckedCheckboxes?.length) || 0;
              if(uncheckedCheckboxes?.length>0 && selectedCheckboxes?.length>0 && previousData > 0 && previousData >= selectedCheckboxData?.length ){
                selectedData = this.removeUncheckedAndCombineUnique(this.previousSelectedData, selectedCheckboxData,  unSelectedCheckboxData);

              }else{
                selectedCheckboxes.forEach((checkbox: any) => {
                  const rowData = api.rows(checkbox.value).data()[0];
                  if (rowData) {
                    selectedData.push(rowData);
                  }
                });
              }
            }
            this.totalCount = totalCount || 0;
            if(!this.datatableConfig.serverSide){
              // Emit selected data
              this.previousSelectedData = selectedData;
            }
            this.onSelection.emit(selectedData);
          }
        }
      };

      // Add event listener for individual checkboxes
      document.addEventListener('change', (event) => {
        const target = event.target as Element; // Cast event.target to Element
        if (target.matches('.row-selection-checkbox')) {
          this.updateSelectAllCheckboxState(totalCount);
        }
      });

      // Update datatable options
      this.dtOptions = { ...this.dtOptions, ...defaultCheckboxProps };
      this.dtOptions.columns = [selectionColumnDef, ...this.dtOptions.columns];
    }
  }

  removeUncheckedAndCombineUnique( arr1: any[], arr2: any[], toRemove: any[] = []  ) { 
    // Remove unchecked items using Lodash's _.isEqual    
    const filteredArr = arr1.filter(item =>       !_.some(toRemove, removeItem => _.isEqual(item, removeItem))  
    );    // Combine both arrays    
    const combined = [...filteredArr, ...arr2];    
    // Use a Map to ensure uniqueness by storing the stringified version as the key    
    const uniqueMap = new Map();    
    combined.forEach(item => {      // Generate a unique key based on the stringified version of the object      
    const key = JSON.stringify(item);      
    if (!uniqueMap.has(key)) {        uniqueMap.set(key, item); }    });    
    // Return the unique values from the map    
    return Array.from(uniqueMap.values());  
 }



  // Function to update the "Select all" checkbox state
  updateSelectAllCheckboxState(totalCount?:any) {
    
    const allCheckboxes = document.querySelectorAll('input[type=checkbox].row-selection-checkbox');
    const selectAllCheckbox = document.querySelector('input.dt-checkboxes') as HTMLInputElement;

    // Check if all individual checkboxes are checked
    const allChecked = Array.from(allCheckboxes).every((checkbox) => (checkbox as HTMLInputElement).checked);
    const anyChecked = Array.from(allCheckboxes).some((checkbox) => (checkbox as HTMLInputElement).checked);
    // Update the state of the "Select all" checkbox
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = allCheckboxes?.length==0 ? false : allChecked;
      selectAllCheckbox.indeterminate = !allChecked && anyChecked; // Set indeterminate if some but not all are checked
    }
    if(totalCount && allCheckboxes?.length==0 && allCheckboxes?.length < totalCount){
      selectAllCheckbox.checked = false;
    }
  }

  addRow(component: any, data: any = null) {
    if (this.tableBody) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.tableBody.createComponent(componentFactory);
      if (!!data && componentRef.instance.hasOwnProperty('data')) {
        componentRef.instance['data'] = data
      }

      // Assign a unique ID to the row
      const rowId = this.nextRowId++;
      this.componentRefs.set(rowId, componentRef);

      // Optionally: Add ID to the component instance if needed
      const element = componentRef.location.nativeElement;
      element.setAttribute('data-row-id', rowId);
      // Insert the new row before the first child (if any)
      const tableBodyElement = this.tableBody.element.nativeElement;
      const emptyTableElement = tableBodyElement.querySelector('.dt-empty');
      if (emptyTableElement) {
        emptyTableElement.style.display = 'none';
      }
      tableBodyElement.prepend(element);
      this.isTableNoRecord = false;
    }
  }

  addRowForCustom(component: any, data: any = null) {
    if (this.tableBody) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.tableBody.createComponent(componentFactory);
      if (!!data && componentRef.instance.hasOwnProperty('data')) {
        componentRef.instance['data'] = data
      }

      // Assign a unique ID to the row
      const rowId = data.index;
      this.componentRefs.set(rowId, componentRef);

      // Optionally: Add ID to the component instance if needed
      const element = componentRef.location.nativeElement;
      element.setAttribute('data-row-id', rowId);
      // Insert the new row before the first child (if any)
      const tableBodyElement = this.tableBody.element.nativeElement;
      const emptyTableElement = tableBodyElement.querySelector('.dt-empty');
      if (emptyTableElement) {
        emptyTableElement.style.display = 'none';
      }
      tableBodyElement.prepend(element);
    }
  }

  // Remove a row by its ID
  // Remove a row by its ID
  removeRow(rowId: number): void {
    if (this.componentRefs.has(rowId)) {
      const componentRef = this.componentRefs.get(rowId);

      // Destroy the component and remove it from the map
      if (componentRef) {
        componentRef.destroy();
        this.componentRefs.delete(rowId);

        // Remove the element from the DOM
        const tableBodyElement = this.tableBody?.element.nativeElement;
        if (tableBodyElement) {
          const rowElement = tableBodyElement.querySelector(`[data-row-id="${rowId}"]`);
          if (rowElement) {
            tableBodyElement.removeChild(rowElement);  // Remove the specific row
          }
        }
      }
    }
  } 

  editRow(component, data) {
    if (this._datatableElement) {
      this._datatableElement.dtInstance.then((dtInstance: Api) => {
        const rowIndex = dtInstance.rows().indexes().toArray().find(index => {
          const rowData = dtInstance.row(index).data();
          if (rowData?.AddressId && rowData.AddressId === data.AddressId) return true;
          if (rowData?.PhoneId && rowData.PhoneId === data.PhoneId) return true;
          if (rowData?.EmailId && rowData.EmailId === data.EmailId) return true;
          return false;
        });

        if (rowIndex !== undefined) {
          const rowNode = dtInstance.row(rowIndex).node();
          const existingElement = $(rowNode).find(`[data-row-id]`).get(0);

          if (existingElement) {
            const existingRowId = existingElement.getAttribute('data-row-id');
            this.removeRow(Number(existingRowId)); // Remove the existing component
          }

          // Create and insert the new component
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          const componentRef = this.tableBody.createComponent(componentFactory);

          const addressRowComponent = componentRef.instance as AddressRowComponent;
          addressRowComponent.address = data;

          const newElement = componentRef.location.nativeElement;
          newElement.setAttribute('data-row-id', this.nextRowId);
          this.componentRefs.set(this.nextRowId, componentRef);
          this.nextRowId++;

          $(rowNode).empty().append(newElement);
        } else {
          console.warn('No matching row found for AddressID:', data?.AddressId);
        }
      });
    } else {
      console.error('Datatable element is not initialized.');
    }
  }

  ngOnDestroy(): void {
    if (this.reload && !this.reload.closed) {
      this.reload.unsubscribe();
    }
    //this._modalService?.dismissAll();
    this._subscription?.unsubscribe();
  }

  triggerFilter() {
    const selectElements = document.querySelectorAll('.header-select');
    const inputElements = document.querySelectorAll('.header-input-field'); // Assuming your table inputs have this class

    const selectObservables = Array.from(selectElements).map(selectElement =>
      fromEvent(selectElement, 'change')
    );

    const inputObservables = Array.from(inputElements).map(inputElement =>
      fromEvent(inputElement, 'keyup')
    );
    // Merge all observables into a single observable
    const combinedObservable = merge(
      ...inputObservables, // only keyup events from table input fields
      ...selectObservables
    );
    const self = this;
    this._subscription =
      combinedObservable
        .pipe(
          debounceTime(300),
          map((event: any) => {
            // const event = keyup || change;
            const target = event.target as HTMLElement;
            const action = target.getAttribute('data-action');
            const field = target.getAttribute('data-field');
            const value = (target as HTMLInputElement).value?.trim().toLowerCase();

            return { action, value, field };
          })
        )
        .subscribe(({ action, value, field }) => {
          const selectAllCheckbox = document.querySelector('input.dt-checkboxes') as HTMLInputElement;
          if(selectAllCheckbox){
            selectAllCheckbox.checked = false;
          } 
          this._datatableElement.dtInstance.then((dtInstance: Api) => {
            dtInstance.columns().every(function () {
              if (this.dataSrc() === field) {
                this.search(value)
                let rowCount = dtInstance.rows({ search: 'applied' }).count();
                self.setPaginationDefault(rowCount);
              }
            });
            // dtInstance.search(value);
            dtInstance.draw();
          })
        });
  }


  addDataTableEventListener(): void {
    let self = this;
    if (this._datatableElement && this._datatableElement.dtInstance) {
      this._datatableElement.dtInstance.then((dtInstance: Api) => {
        this.triggerFilter();
        let rowCount = dtInstance.rows({ search: 'applied' }).count();
        if (!self.datatableConfig?.serverSide) self.setPaginationDefault(rowCount);
        dtInstance.on('draw', () => {
          let rowCount = dtInstance.rows({ search: 'applied' }).count();
          if (self.datatableConfig?.serverSide || self.isTableNoRecord) self.setPaginationDefault(rowCount);
          // Initialize DataTable
          this.dataTable = $(this.c3table.nativeElement).DataTable();
          if (this.dataTable && this.defaultPageObj && !this.childTableInit) {
            this.childTableInit = true;
            setTimeout(() => {
              this.dataTable.page.len(this.defaultPageObj.PageSize).page(this.defaultPageObj?.StartInd - 1).draw('page');
              this.activePageSize = this.defaultPageObj.PageSize;
            }, 100)
            // if(this.defaultPageObj?.pageSize) this.dataTable.page.len(this.defaultPageObj?.pageSize).draw();
          }
          if (this.isPaginationRowHide) {
            this.dtOptions = {
              ...this.dtOptions, dom: `<'row'<'col-sm-12 p-0'tr>>" +
            "<'row pagination-empty'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>`}
          }
          this._cdref.detectChanges()
        });
        dtInstance.on('init', () => {
          this.triggerFilter();
          this.initalizeTooltip()
        });
        this._cdref.detectChanges()
      });
    }
  }

  setPaginationDefault(rowCount: any) {
    if (rowCount && rowCount > 0) { 
      // Conditionally show the length menu
      this.dtOptions.dom ="<'row'<'col-sm-12 p-0'tr>>" +
      "<'row'<'d-flex col-sm-12 col-md-5 ps-0'il><'col-sm-12 col-md-7'p>>";
      setTimeout(() => {
        const lengthMenus = document.querySelectorAll('.dt-length');
        if (lengthMenus.length > 0) {
          lengthMenus.forEach(lengthMenu => {
            this.renderer.setStyle(lengthMenu, 'display', 'unset');
          });
        }
      }, 100)
      this.loadPagination = true;
      this.isTableNoRecord = false;
      if (this.c3table) {
        let currentElement = this.c3table.nativeElement;
        let i = 0;
        while (currentElement && i < 6) {
          // Check if the current element has the 'pagination' class
          if (currentElement.classList.contains('dt-bootstrap5')) {
            if(this.hasPagination){
              this.renderer.removeClass(currentElement, 'pagination-empty');
            }
            else{
              this.loadPagination = false;
              this.renderer.addClass(currentElement, 'pagination-empty');
            }
            return true; // Class found
          }
          // Move to the next parent element
          currentElement = currentElement.parentElement;
          i++;
        }
      }
    } else {
      this.dtOptions.dom ="";
      this.loadPagination = false;
      this.isTableNoRecord = true;
      if (this.c3table) {
        let currentElement = this.c3table.nativeElement;
       
        let i = 0;
        while (currentElement && i < 6) {
          // Check if the current element has the 'pagination' class
          if (currentElement.classList.contains('dt-bootstrap5')) {
            this.renderer.addClass(currentElement, 'pagination-empty');
            return true; // Class found
          }
          // Move to the next parent element
          currentElement = currentElement.parentElement;
          i++;
        }
      }
    }
  }
 

  initalizeTooltip() {
    var exampleEl = document.getElementById('tooltip-checkbox');
    if (!!exampleEl) {
      const tooltip = new bootstrap.Tooltip(exampleEl, {
        title: this.tooltipText,
        placement: 'bottom'
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._datatableElement && this._datatableElement.dtInstance) {
      this._datatableElement.dtInstance.then((dtInstance: Api) => {
        if (!!changes?.datatableConfig?.currentValue.data) {
          dtInstance.clear();
          dtInstance.rows.add(this.datatableConfig.data).draw();
        }
        if (this.datatableConfig.data.length == 0) {
          this.loadPagination = false;
          this.isTableNoRecord = true;
          this.isPaginationHide = true;
        }
        let self = this;
        dtInstance.columns().every(function () {
          let rowCount = dtInstance.rows({ search: 'applied' }).count();
          self.setPaginationDefault(rowCount);
        });
        // dtInstance.search(value);
        dtInstance.draw();
        let rowCount = dtInstance.rows({ search: 'applied' }).count();
        this.setPaginationDefault(rowCount);
      });
    }
  }
}
