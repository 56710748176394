// USA
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    TRANSLATE: {
      "academic":"Académique",
      "Academic":"Académique",
      "ACCEPT_BUTTON_INFO":"En cliquant sur le bouton Accepter, vous acceptez les conditions d'utilisation ci-dessus.",
      "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
      "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Accepter ou refuser le consentement au renouvellement de l'offre du partenaire",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"accès au centre partenaire à l'aide des nouveaux paramètres de sécurité.",
      "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Accès aux API de gestion Microsoft Azure.",
      "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Accès à l'API de tarification Microsoft.",
      "ACCEPTANCE_STATUS":"Statut d'acceptation",
      "ACCESS_TOKEN":"Jeton d'accès",
      "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"Gestionnaire de compte créé avec succès",
      "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Gestionnaire de compte supprimé avec succès",
      "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"Êtes-vous sûr de vouloir supprimer le gestionnaire de compte ?",
      "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"Le gestionnaire de compte est attribué au client/revendeur, êtes-vous sûr, voulez-vous supprimer le gestionnaire de compte.",
      "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Recherche par identifiant de gestionnaire de compte",
      "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Recherche par adresse courriel",
      "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Recherche par prénom",
      "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Recherche par nom de famille",
      "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Recherche par numéro de téléphone",
      "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Fermer les filtres",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"Adresse courriel",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Réinitialiser",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Rechercher",
      "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Les détails du gestionnaire de compte sont mis à jour avec succès",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter un gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier le gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Courriel",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Ajouter un gestionnaire de compte",
      "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Gestionnaires de comptes",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"Identifiant",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Actions",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Date créée",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"Adresse courriel",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer le gestionnaire de compte",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Modifier les détails",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Afficher les clients affectés",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Numéro de compte",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"Type de compte",
      "ACH_FORM_LABEL_BANK_NAME":"Nom de banque",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"Est un compte d’entreprise",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Nom de compte",
      "ACH_FORM_LABEL_NO":"Non",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Numéro d'acheminement",
      "ACH_FORM_LABEL_YES":"Oui",
      "ACTIONS_TO_FIX":"Veuillez contacter l'administrateur pour obtenir du support",
      "ACTIONS_TO_FIX_500":"Nous travaillons fort afin de régler le problème, veuillez vérifier dans un moment",
      "ACTIVATE_CONFORMATION":"Êtes-vous sûr de vouloir activer ?",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Sélectionner un client",
      "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Toutes les actions--",
      "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Affichage des journaux de",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Tous les clients",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Tous les domaines.",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Notifications",
      "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Recommencez",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Date de fin",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Actions",
      "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Méthode de contact",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Nom de l'événement",
      "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Date envoyée",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Statut",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Sujet",
      "ACTIVITY_LOGS_HEADER_TITLE_TO":"À",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Événement",
      "ACTIVITY_LOGS_TEXT_BCC":"Cci",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Date de début",
      "ACTIVITY_LOGS_TEXT_FAILED":"Échoué",
      "ACTIVITY_LOGS_TEXT_FROM":"De",
      "ACTIVITY_LOGS_TEXT_ON":"Activé",
      "ACTIVITY_LOGS_TEXT_STATUS":"Statut",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"Réussite ",
      "ACTIVITY_LOGS_TEXT_TO":"À",
      "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"Afficher l'e-mail",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Notifications",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Ajouter un ajustement",
      "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"Le nom de domaine n'est pas disponible",
      "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"Le nom de domaine n'est pas disponible",
      "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ajouter des offres partenaires",
      "ADD_CUSTOM_ROLE":"ajouter un rôle personnalisé",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Le client a accepté la dernière",
      "ADD_CUSTOMER_IN_QUOTE":"Ajouter un client",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"Pays",
      "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Veuillez entrer un code postal.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Veuillez entrer l'adresse 1.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":"Entrez l'adresse 2.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Veuillez entrer la ville.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Veuillez saisir le nom de l'entreprise.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"S'il vous plaît entrer votre adresse mail.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Veuillez entrer le prénom.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Veuillez entrer le nom de famille.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Veuillez entrer le numéro de téléphone.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Veuillez sélectionner un pays.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Veuillez sélectionner un code de devise.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Veuillez sélectionner un Plan.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Veuillez sélectionner un état.",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Ajouter des clients",
      "ADD_DUE_DATE":"Ajouter une date d'échéance",
      "ADD_ENTITLEMENT":"Ajouter un droit",
      "ADD_ENTITLEMENT_DESCRIPTION":"Veuillez fournir un nom convivial pour l'abonnement.",
      "ADD_ENTITLEMENT_FAILED":"Échec de l'ajout d'un droit",
      "ADD_ENTITLEMENT_SUCCESS":"Le droit est ajouté avec succès dans Partner Center veuillez patienter 15 minutes pour le provisionnement dans C3",
      "ADD_MACRO_VALUE_ERROR":"La valeur de la macro est requise",
      "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Créer un nouveau niveau",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!Valeur max) ? \"Veuillez saisir une valeur supérieure à \" + minValue : \"Veuillez saisir une valeur comprise entre \" + minValue + \" et \" + maxValue}}",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Veuillez saisir une valeur comprise entre {{Min}} et {{Max}}",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Annuler",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Soumettre",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Terminé",
      "ADD_MISSING_OFFERS_IN_PROGRESS":"Le plan de mise à jour avec les dernières offres est déjà en cours",
      "ADD_MISSING_OFFERS_INITIAT_FAIL":"Échec du plan de mise à jour avec le lancement des dernières offres",
      "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Le plan de mise à jour avec les dernières offres a été lancé",
      "ADD_MISSING_OFFERS_STATUS":"Progression de la mise à jour du plan",
      "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Ajouter les offres manquantes au forfait existant",
      "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Ajouter des offres manquantes à plusieurs forfaits",
      "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Ajouter les offres manquantes au forfait unique",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Voulez-vous autoriser l'accès au contact principal (en tant qu'administrateur client) ?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Voulez-vous envoyer une invitation pour accéder à l'application ?",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "ADD_NEW_QUOTE":"Créer un devis",
      "ADD_NEW_USER_IN_QUOTE":"Ajouter un nouvel utilisateur",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Sélectionner",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Ajouter des détails de base du plan",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Description",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Devise de facturation",
      "ADD_PLAN_LABEL_TEXT_NAME":"Nom",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Devise d'achat",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Afficher les détails du contrat",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Actions",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Macro",
      "ADD_PO_NUMBER":"Ajouter BDC #",
      "ADD_PONUMBER":"Ajouter un numéro de bon de commande",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Il est recommandé d'utiliser l'option <b>Fournisseur de lien</b> pour fournir l'ID MPN du revendeur et aider le revendeur à intégrer éventuellement les clients.",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"Recommandé! Définir l'ID MPN après avoir ajouté le revendeur",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Diviseur de blocs",
      "ADD_TO_CART_INFO":"Ce produit est lié avec <b>{{linkedProductName}}</b>, si vous ajoutez ce produit, le lien ajoutera également ",
      "addCustomer":"Ajouter un client",
      "ADDONS_LEBEL_TEXT":"Extensions",
      "ADDONS_PRODUCT_LEBEL_TEXT":"Produit complémentaire",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Actions",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Ligne d'adresse 1 ",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Ligne d'adresse 2 ",
      "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Type d'adresse",
      "ADDRESS_TABLE_HEADER_CITY":"Ville",
      "ADDRESS_TABLE_HEADER_COUNTRY":"Pays",
      "ADDRESS_TABLE_HEADER_STATE":"État",
      "ADDRESS_TABLE_HEADER_ZIP":"Code postal",
      "ADDRESS_TYPE_BILLING":"Facturation",
      "ADDRESS_TYPE_COMPANY":"Entreprise",
      "ADDRESS_TYPE_INVOICE":"Facture",
      "ADDRESS_TYPE_SHIPPING":"Expédition",
      "AGO":"Passé(s)",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Copié dans le presse-papiers",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"La facture a été approuvée avec succès.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"La facture a été finalisée avec succès.",
      "ALERT_MESSAGE_MISSING_MPNID":"Un problème est survenu lors de la copie de l'URl. Veuillez vous connecter au partenaire.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Veuillez sélectionner au moins une facture",
      "ALL_CART_ITEM_COMMENT":"Article du panier",
      "ALL_COMMENTS":"Commentaires",
      "ALL_PRODUCT_COMMENTS":"Produit",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Veuillez contacter votre administrateur pour la suppression des licences.",
      "AN_ERROR_OCCURED":"une erreur s’est produite pendant le traitement de votre demande",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Historique de facturation Azure ",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Sommaire de facturation Azure",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Utilisation Azure du client  ",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Revenus par client",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Les abonnements par client",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Les abonnements par plans",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"historique des abonnements",
      "AND":"et",
      "APP_CONFIG_AzureAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_AzureAD_PRICING_APPLICATION_RESOUNCE_URL":"URL de ressouNCE d'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de ressource d'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Veuillez saisir la couleur hexadécimale du bouton de la page d'accueil",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_AzureAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de ressource d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AzureAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Couleur du bouton de la page d'accueil",
      "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Bannière d'inscription publique",
      "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Logo d'inscription publique",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Autoriser le téléchargement de l'utilisation",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Lorsqu'elle est activée, cette configuration augmentera les coûts d'utilisation mensuels encourus pour les produits qui ont une consommation d'utilisation. Les types de produits incluront Azure-Plan et Custom-Usage.",
      "APPCONFIG_AUTOTASK_BILLING_CODE":"Code de facturation",
      "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Ce code de facturation sera utilisé pour créer des produits dans Autotask",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Est le mappage automatique (Autotask)",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Si vous souhaitez mapper manuellement entre les entités C3 et Autotask, laissez cette option décochée. Cependant, si le mappage automatique est activé, les entités sont mises en correspondance en comparant les noms des entités dans C3 avec les entités dans Autotask",
      "APPCONFIG_AUTOTASK_PASSWORD":"Mot de passe",
      "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Mot de passe appartenant à l'utilisateur de l'API",
      "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Prorata",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Fréquence de téléchargement automatique",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Définir la fréquence de téléchargement automatique",
      "APPCONFIG_AUTOTASK_USERNAME":"Nom d'utilisateur",
      "APPCONFIG_AUTOTASK_USERNAME_DESC":"Nom d'utilisateur de l'utilisateur de l'API créé dans Autotask",
      "APPCONFIG_DESC_ACCOUNT_NAME":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_ACCOUNT_NUMBER":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Adresse 1 qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Adresse 2 qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_ADVANCE_TEXT":"Texte personnalisé de la ligne avancée",
      "APPCONFIG_DESC_API_URL":"URL de l'API de base pour la gestion connectée",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Activer le suivi des licences",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Afficher l'analyse",
      "APPCONFIG_DESC_APPLICATION_NAME":"Nom de l'application",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Fin du cycle de facturation pour le partenaire dans le centre partenaire",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"Identifiant d'offre CSP Azure",
      "APPCONFIG_DESC_BANK_NAME":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_BILLING_CYCLE":"Aligné avec le centre partenaire ou personnalisé",
      "APPCONFIG_DESC_BILLING_DATE":"Applicable lorsque le cycle de facturation est personnalisé",
      "APPCONFIG_DESC_BILLING_PERIOD":"Période de facturation (déterminée à l'aide d'autres paramètres)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"Fournisseur de facturation pour le portail C3",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Configuration du fournisseur de facturation",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Début du cycle de facturation. Applicable pour le cycle de facturation est personnalisé",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Apparaît sur la vue facture et le PDF facture dans la section « Facturer de »",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Apparaît sur la vue facture et le PDF facture dans la section « Facturer de »",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Vérifier les rôles par rapport à 0365",
      "APPCONFIG_DESC_CIN_NUMBER":"CIN (Numéro d'identification d'entreprise) qui apparaît sur la facture pdf",
      "APPCONFIG_DESC_CITY":"Ville qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_CLIENT_ID":"ClientId pour ConnectWise Manage",
      "APPCONFIG_DESC_COMPANY_NAME":"Nom de l'entreprise qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_COMPANY_URL":"URL de votre site Web",
      "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Nom de l'entreprise pour Connectwise Manage",
      "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Tenir compte de la date de génération dans le rapport de chiffre d'affaires",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Nom qui devrait apparaître sur la page d'accueil ainsi que le pied de page après qu'un utilisateur est connecté",
      "APPCONFIG_DESC_CONTACT_EMAIL":"Courriel de contact (généralement vos informations ou courriel de support)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Prénom de la personne-ressource pour votre demande C3",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"Nom de famille de la personne-ressource pour votre application C3",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Numéro de téléphone de la personne-ressource",
      "APPCONFIG_DESC_COUNTRY":"Pays qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Code de pays",
      "APPCONFIG_DESC_CURRENCY_CODE":"Code de devise",
      "APPCONFIG_DESC_CUSTOM_BILLING":"Appliquer la facturation personnalisée",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL de consentement du client",
      "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Restreindre le client lors de l'intégration sous plusieurs entités",
      "APPCONFIG_DESC_DATE_FORMAT":"Format de la date",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Format Date/Heure",
      "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Utiliser pour définir un format de date pour la valeur du type de date dans les API C3",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"URL cible pour la page, si vous souhaitez avoir une page de destination personnalisée.       Remarque : dans la page d'accueil, vous devriez retrouver un bouton et l'URL cible de ce bouton devrait être <C3Site URL>/#/login",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Langue par défaut pour le site Web",
      "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Définit le nombre de pages par défaut pour chaque table du système.",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Termes et conditions pour commander des produits",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"URL des conditions générales (texte)",
      "APPCONFIG_DESC_EMAIL_LOGO":"Logo qui apparaît sur les courriels envoyé par C3. La dimension préférée est de 300x100.",
      "APPCONFIG_DESC_ENABLE_TOUR":"Activez le bouton « Faire un tour » dans l'en-tête du site.",
      "APPCONFIG_DESC_FAVICON_LOGO":"Un favicon est une petite icône visible sur l'onglet du navigateur Web, juste avant le titre de la page. Veuillez télécharger un fichier .ico de 16x16",
      "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Devrait-on considérer l'ID MPN des revendeurs pour les clients et les achats ?",
      "APPCONFIG_DESC_IFSC_CODE":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Apparaît sur la vue facture et la facture pdf en tant que ligne de pied de page autorisée",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Apparaît sur la vue facture et la facture pdf en tant que ligne de pied de page",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Jours de génération de factures",
      "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Chaîne de connexion au stockage cloud",
      "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Jours de validité du code postal de la facture",
      "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Lorsque cette fonctionnalité est activée, le partenaire recevra les demandes d'approbation approuvées par le revendeur.",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Lien vers la page Nous contacter (il suffit de taper /#/contact si vous n'en avez pas)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Lien vers la FAQ client (il suffit de le laisser vide, si vous voulez utiliser celui par défaut)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Lien vers le portail",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Lien vers la page Soutien (il suffit de taper home.support si vous n'en avez pas)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Lien vers le logo de bienvenue",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Le logo qui apparaît en haut à gauche après que les utilisateurs sont connectés à C3. La dimension préférée est 170x40. La page a un fond noir de sorte que le graphique devrait dans une couleur qui contraste avec le noir.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Connexion au portail",
      "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"CustomerId obligatoire dans les appels API",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Nombre maximal d'entrées à exporter vers Excel",
      "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Nombre maximal de créations de droits",
      "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Nombre maximal de créations de locataires",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Rappel de consentement de sécurité Microsoft en jours",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Montant minimal des frais pour les paiements Test",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"Termes et conditions NCE pour commander les produits Microsoft NCE",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL des termes  et conditions des NCE (texte)",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Active la fonctionnalité de recherche client déjà présent dans la facturation",
      "APPCONFIG_DESC_PAN_NUMBER":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Identifiant d'application à partir d'une application native dans votre centre partenaire. Vous pouvez l'obtenir en naviguant vers Gestion des applications -> Application native",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"Devise du partenaire",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Mot de passe de l'agent admin",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Langues de préférence du partenaire",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"Identifiant du domaine partenaire",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Nom d'utilisateur du compte auquel le rôle d'administrateur d'agent est affecté",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL du contrat de revendeur Microsoft Cloud",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL des conditions générales du partenaire",
      "APPCONFIG_DESC_PBI_GROUP_ID":"Identifiant du groupe Power BI",
      "APPCONFIG_DESC_PBI_PASSWORD":"Mot de passe Power BI",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Identifiant du rapport Power BI",
      "APPCONFIG_DESC_PBI_USER_NAME":"Nom d'utilisateur Power BI",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"Identifiant du client Power BI",
      "APPCONFIG_DESC_PDF_LOGO":"Logo devant figurer sur le pdf de votre candidature C3. La dimension préférée est 186x32. La page a un arrière-plan blanc, le texte ou le graphique doit donc être de n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logo qui devrait apparaître sur le pdf à droite. La dimension préférée est 186x32. La page a un arrière-plan blanc, le texte ou le graphique doit donc être de n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_POSTAL_CODE":"Le code postal qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Nom qui devrait apparaître sur la page d'accueil ainsi que le pied de page après qu'un utilisateur est connecté",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"URL de votre site Web",
      "APPCONFIG_DESC_PRIVATE_KEY":"Clé privée pour ConnectWise Manage",
      "APPCONFIG_DESC_PUBLIC_KEY":"Clé publique pour \"ConnectWise Manage\"",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"Identifiant client de l'application QuickBooks",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"Clé secrète du client de l'application QuickBooks",
      "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Incompatibilité de reconnaissance sur les destinataires des e-mails de génération de facture initiale",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logo qui apparaît sur la vue Rapport de rapprochement à droite du logo Microsoft. La dimension préférée est 170x40. La page a un fond blanc, de sorte que le texte ou le graphique doit être dans n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Ajouter les destinataires en tant que valeurs séparées par des virgules",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Devrait informer le client",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Suspendre l'abonnement lorsque le seuil de danger est atteint",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Afficher le menu de rapports Power BI",
      "APPCONFIG_DESC_SHOW_FOOTER":"L'activation de ce paramètre affichera le pied de page sur toutes les pages",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Afficher l'étape suivante",
      "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Activez cette option pour afficher les conditions générales de gestion des abonnements",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP Activer SSL",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"SMTP à partir d'un courriel",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"À partir du nom d'affichage",
      "APPCONFIG_DESC_SMTP_HOST":"Hôte SMTP",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Mot de passe",
      "APPCONFIG_DESC_SMTP_PORT":"Port",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"Préfixe de sujet SMTP",
      "APPCONFIG_DESC_SMTP_USERNAME":"Nom d'utilisateur SMTP",
      "APPCONFIG_DESC_STATE":"L'état qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DESC_TAX_TYPE_KEY":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_TAX_TYPE_VALUE":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_WELCOME_LOGO":"Logo qui devrait apparaître sur la page d'accueil de votre application C3. La dimension préférée est 186x32. La page a un fond blanc, de sorte que le texte ou le graphique doit être dans n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Code de compte de l'application Xero pour les éléments de ligne d'ajustement",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_Azure":"Code de compte d'application Xero pour Azure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Code de compte d'application Xero pour Azure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Code de compte d'application Xero pour les offres personnalisées",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Code de compte d'application Xero pour les licences Microsoft",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Code de compte de l'application Xero pour les éléments de ligne uniques",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Code de compte de l'application Xero pour les éléments de ligne des instances réservées",
      "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Nom du thème de marque de l'application Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"Identifiant client de l'application Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Clé secrète du client de l'application Xero",
      "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"La valeur décimale à laquelle les montants postés à Xero sont arrondis à",
      "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Description de l'ajout et format de description de la facture",
      "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Date Culture pour les dates de ConnectWise Manage ",
      "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Envisagez ConnectWise Manage les prix au lieu des prix C3",
      "APPCONFIG_DISC_MAPPING_TYPE":"Sélectionnez le type de mappage pour mapper les entités entre C3 et le service externe",
      "APPCONFIG_DISP_ACCOUNT_NAME":"Nom du compte",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Numéro de compte",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Adresse 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Adresse 2",
      "APPCONFIG_DISP_ADVANCE_TEXT":"Texte personnalisé de la ligne avancée",
      "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Appconfig disp texte avancé pour annuel",
      "APPCONFIG_DISP_API_URL":"URL de l'API",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Activer le suivi des licences",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Afficher l'analyse",
      "APPCONFIG_DISP_APPLICATION_NAME":"Nom de l'application",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Date de génération de facture Microsoft",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"Identifiant d'offre CSP Azure",
      "APPCONFIG_DISP_BANK_NAME":"Nom de banque",
      "APPCONFIG_DISP_BILLING_CYCLE":"Cycle de facturation",
      "APPCONFIG_DISP_BILLING_DATE":"Date à laquelle les factures sont générées dans C3",
      "APPCONFIG_DISP_BILLING_PERIOD":"Période de facturation",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Fournisseur de facturation",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Configuration du fournisseur de facturation",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Date de début de la facturation",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"Numéro d'entreprise",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Clé de numéro d'entreprise",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Vérifier les rôles par rapport à 0365",
      "APPCONFIG_DISP_CIN_NUMBER":"Numéro CIN",
      "APPCONFIG_DISP_CITY":"Ville",
      "APPCONFIG_DISP_CLIENT_ID":"ClientId",
      "APPCONFIG_DISP_COMPANY_NAME":"Nom de l'entreprise",
      "APPCONFIG_DISP_COMPANY_URL":"URL de l'entreprise",
      "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Description de l'ajout",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Nom de l'entreprise",
      "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Culture des Dattes",
      "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Considérez ConnectWise Manage les prix",
      "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Tenir compte de la date de génération dans le rapport de chiffre d'affaires",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Nom de l'entreprise de contact",
      "APPCONFIG_DISP_CONTACT_EMAIL":"Courriel de contact",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Prénom du contact",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"Nom de famille du contact",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Numéro de téléphone du contact",
      "APPCONFIG_DISP_COUNTRY":"Pays",
      "APPCONFIG_DISP_COUNTRY_CODE":"Code de pays",
      "APPCONFIG_DISP_CURRENCY_CODE":"Code de devise",
      "APPCONFIG_DISP_CUSTOM_BILLING":"Appliquer la facturation personnalisée",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL de consentement du client",
      "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Restreindre le client lors de l'intégration sous plusieurs entités",
      "APPCONFIG_DISP_DATE_FORMAT":"Format de la date",
      "APPCONFIG_DISP_DATE_TIME_FORMAT":"Format Date/Heure",
      "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Format de date et d'heure pour les API C3",
      "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Jours pour envoyer une notification d'état du panier",
      "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Nombre de jours pour stocker le fichier de factures groupées dans le stockage cloud",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"URL de page d'accueil personnalisée",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Langue par défaut",
      "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Nombre de pages par défaut",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"URL des conditions générales (texte)",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL des conditions générales",
      "APPCONFIG_DISP_EMAIL_LOGO":"Envoyer le logo par courriel",
      "APPCONFIG_DISP_ENABLE_TOUR":"Activer la visite",
      "APPCONFIG_DISP_FAVICON_LOGO":"Logo favori",
      "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Devrait-on considérer l'ID MPN des revendeurs pour les clients et les achats ?",
      "APPCONFIG_DISP_IFSC_CODE":"Code IFSC",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Ligne autorisée de pied de page de facture",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Ligne de pied de page de facture",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Jours de génération de factures",
      "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Chaîne de connexion au stockage cloud",
      "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Jours de validité du code postal de la facture",
      "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Approbation du partenaire requise",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AzureSEARCH":"Catalogue public soutenu par la recherche Azure",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Catalogue public soutenu par la recherche Azure",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Lien vers la page Nous contacter",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Lien vers la FAQ client",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Lien vers le portail",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Lien vers le soutien",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Lien vers le logo de bienvenue",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Logo connecté",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Connexion au portail",
      "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":"CustomerId obligatoire dans les appels API",
      "APPCONFIG_DISP_MAPPING_TYPE":"Type de cartographie",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Nombre maximal d'entrées à exporter",
      "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Nombre maximal de créations de droits",
      "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Nombre maximal de créations de locataires",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Rappel de consentement de sécurité Microsoft en jours",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Montant minimal des frais",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL des termes  et conditions des NCE (texte)",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL des termes et conditions du NCE",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Recherche de client intégré avec facturation",
      "APPCONFIG_DISP_PAN_NUMBER":"Numéro de casserole",
      "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"Clé numérique PAN",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Identifiant d'application native",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"Devise du partenaire",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Mot de passe de l'agent admin",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Groupes de langues",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"Identifiant du domaine partenaire",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Nom d'utilisateur de l'agent admin",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL du contrat de revendeur Microsoft Cloud",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL des conditions générales du partenaire",
      "APPCONFIG_DISP_PBI_GROUP_ID":"Identifiant du groupe Power BI",
      "APPCONFIG_DISP_PBI_PASSWORD":"Mot de passe Power BI",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Identifiant du rapport Power BI",
      "APPCONFIG_DISP_PBI_USER_NAME":"Nom d'utilisateur Power BI",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"Identifiant du client Power BI",
      "APPCONFIG_DISP_PDF_LOGO":"Logo PDF",
      "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"Logo du côté droit du PDF",
      "APPCONFIG_DISP_POSTAL_CODE":"Code postal",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Propulsé par Nom de l'entreprise",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Propulsé par l'URL de l'entreprise",
      "APPCONFIG_DISP_PRIVATE_KEY":"Clé privée",
      "APPCONFIG_DISP_PUBLIC_KEY":"Clé publique",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks ClientId",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks ClientSecret",
      "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Incompatibilité de reconnaissance sur les destinataires des e-mails de génération de facture initiale",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Logo du rapport de rapprochement",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Destinataire de la notification du rapport de rapprochement",
      "APPCONFIG_DISP_REFUND_TEXT":"Texte de remboursement distribué par Appconfig",
      "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Appconfig diffuse le texte de remboursement pour l'année",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Suspendre l'abonnement lorsque le seuil de danger est atteint",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Afficher le menu de rapports Power BI",
      "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Permet d'obtenir le numéro de facture externe dans le rapport récapitulatif des factures",
      "APPCONFIG_DISP_SHOW_FOOTER":"Afficher le pied de page",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Afficher l'étape suivante",
      "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Activer les conditions générales pour la gestion des abonnements",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"Activer SSL",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"À partir du courriel",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"À partir du nom d'affichage",
      "APPCONFIG_DISP_SMTP_HOST":"Hôte",
      "APPCONFIG_DISP_SMTP_PASSWORD":"Mot de passe SMTP",
      "APPCONFIG_DISP_SMTP_PORT":"Port SMTP",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Préfixe du sujet",
      "APPCONFIG_DISP_SMTP_USERNAME":"Nom d'utilisateur",
      "APPCONFIG_DISP_STATE":"État",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Type de taxe",
      "APPCONFIG_DISP_TAX_TYPE_VALUE":"Valeur fiscale",
      "APPCONFIG_DISP_WELCOME_LOGO":"Logo de bienvenue",
      "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Page d'accueil",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Code de compte Xero pour les ajustements",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Code de compte Xero pour OneTime",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Code de compte Xero pour les instances réservées",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
      "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xéro points décimaux",
      "APPCONFIG_SELECTED_SMTP_OPTION":"Sélectionnez le type SMTP",
      "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Sélectionnez l'option SMTP",
      "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Afficher le numéro de facture externe dans le rapport récapitulatif des factures",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"Identifiant de l'application d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"ID client de l’application Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Mot de passe de l'application d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Clé secrète client de l'application Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Authentification moderne à partir du courrier électronique",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"SMTP à partir d'un courriel",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"ID de locataire d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"Identifiant du locataire du compte utilisateur qui sera utilisé pour envoyer des e-mails",
      "APPEND_CUSTOM_TRIAL_DAYS":"(Période d'essai)",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":"La limite restante après approbation de cette demande sera",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" La limite restante actuelle sera mise à jour après un renouvellement réussi.",
      "approvals":"Approbations",
      "APPROVED_AND_INITIATED":"Approuvé et initié",
      "APPROVED_BY":"par distributeur",
      "APRIL":"Avril",
      "ASSIGN_BUNDLE_PRODUCTS":"Affecter des produits groupés",
      "ASSIGN_CUSTOMER_PLAN":"Affecter un plan client",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Voulez-vous vraiment Affecter {{customert}} à {{accountManager}} ?",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le client a été affecté avec succès",
      "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le revendeur a été affecté avec succès",
      "ASSIGN_USAGE_PRODUCTS":"Affecter des produits d'utilisation",
      "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Client affecté au gestionnaire de compte avec succès",
      "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Revendeur affecté au gestionnaire de compte avec succès",
      "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Je comprends que chaque personne utilisant Windows 365 Business avec Windows Hybrid Benefit doit également disposer d'une copie valide de Windows 10/11 Pro installée sur son appareil de travail principal.",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Fermer",
      "AUDIT_LOG_DETAIL":"Détails",
      "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Masquer les détails",
      "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Plus de détails",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Dissimulé",
      "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Toutes les actions--",
      "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Tous les clients--",
      "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Tous les domaines--",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Journal d'audits",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Rechercher",
      "AUDITLOG_TEXT_ACTION_NAME":"Actions",
      "AUDITLOG_TEXT_AUDITLOG":"Journal d'audits",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"Nom du client",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"En vigueur à partir de",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Effectif pour",
      "AUDITLOG_TEXT_FROM":"De",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Nom de domaine client",
      "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Afficher les journaux d'audit masqués",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Actions",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Client concerné",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Date",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Initiateur",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Afficher les détails",
      "AUDITLOG_TEXT_TO":"À",
      "AUGUST":"Août",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Code d'authentification reçu de Quickbooks",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Code d'authentification reçu de Xero",
      "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"Le lot est déjà fermé.",
      "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Demande terminée avec succès",
      "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"La carte de crédit a expiré.",
      "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"Le lot d'appareils est plein. Veuillez fermer le lot.",
      "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"L'appareil a été désactivé.",
      "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Une transaction dupliquée a été soumise.",
      "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"L'identifiant de l'appareil n'est pas enregistré ou n'est pas activé.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"L'ID de lot de l'appareil n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"L'ID de l'appareil n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"Le drapeau d'inversion n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"Le code de sécurité spécifié n'était pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Une erreur de validation s'est produite au niveau du processeur.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Les données de la demande n'ont pas réussi la vérification des champs obligatoires pour cette application.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Le(s) champ(s) de la requête sont invalides ou manquants.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"Les transactions V.me ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"La transaction est introuvable.",
      "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"La demande d'annulation a échoué. Soit le type de transaction d'origine ne prend pas en charge l'annulation, soit la transaction est en cours de règlement.",
      "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"Le marchand est activé avec succès.",
      "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"Le marchand est configuré pour la fermeture automatique.",
      "AUTHORIZE_NET_CODE_NEED_CONSET":"Besoin du consentement du payeur.",
      "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Cette commande a déjà été validée ou annulée, de nouvelles associations de transaction ne peuvent donc pas être acceptées.",
      "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Votre commande a été reçue. Merci pour votre entreprise!",
      "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"La transaction d'origine se trouve dans un lot fermé.",
      "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Le service Payment Gateway Account (id=8) a déjà été accepté.",
      "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Un nouveau code de sécurité a été demandé.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Cette transaction a déjà été autorisée.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal a déjà capturé cette transaction.",
      "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"L'annulation a été traitée avec succès.",
      "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"L'appareil mobile a été inséré avec succès dans la base de données.",
      "AUTHORIZE_NET_CODE_SUCCESS":"La demande a été traitée avec succès.",
      "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"L'appareil mobile a été enregistré avec succès et approuvé par l'administrateur du compte.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Cette transaction a déjà été annulée.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Cette transaction a déjà été capturée.",
      "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"Transaction originale pour l'annulation introuvable.",
      "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal a rejeté la transaction. x_paypal_hdrimg dépasse la longueur maximale autorisée.",
      "AUTHORIZE_NET_CODE_USE_XMETHOD":"Utilisez x_method pour spécifier la méthode ou envoyer uniquement x_call_id ou les informations de carte/compte.",
      "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"C'est un travail en cours de réalisation. Ce message ne sera pas diffusé en production. C'est juste un espace réservé de développement.",
      "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"Le taux de conversion pour cette carte est disponible.",
      "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"Le code de la carte est invalide.",
      "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Cette transaction ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Cette transaction ne peut pas être traitée.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Ce compte n'a pas reçu les autorisations requises pour cette demande.",
      "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"Le prénom sur le compte et/ou le type de compte ne correspond pas au compte réel.",
      "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"Les transactions ACH ne peuvent pas être acceptées par ce système.",
      "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"L'indicateur d'authentification est invalide.",
      "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"Le code d'autorisation n'est pas valide.",
      "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"Le type de compte bancaire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"La facturation récurrente n'est pas autorisée pour ce type eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La valeur d'authentification du titulaire de la carte n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"Le code ABA est invalide",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"Le jeton d'accès fourni a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"Le jeton d'accès utilisé pour valider la requête est insuffisant pour le faire.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"La méthode API demandée ne peut pas être exécutée lorsque le compte de la passerelle de paiement est en mode test.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Ce marchand n'est pas associé à ce revendeur.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"Le compte marchand doit être configuré pour accepter les paiements par carte de crédit, les paiements par chèque électronique ou les deux.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"La passerelle de paiement, le revendeur ou le compte utilisateur n'est pas actif actuellement.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"Le compte de la passerelle de paiement n'est pas configuré pour traiter les abonnements par carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"Le compte de la passerelle de paiement n'est pas configuré pour traiter les abonnements eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"Le numéro de compte est invalide",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"Le montant de l'abonnement est requis lors de la création d'un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"Les transactions ACH ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"La somme de tous les crédits de cette transaction est supérieure au montant de la transaction d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Tous les champs étaient vides ou manquants.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Tous les champs étaient vides ou manquants.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Valeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Valeur trop longue.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"L'abonnement a déjà été annulé.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"L'enregistrement a déjà été supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"Aucune approbation n'a été renvoyée pour la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"Une ou plusieurs valeurs d'enchères requises n'ont pas été soumises.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"Le type d'authentification n'est pas autorisé pour l'appel de méthode demandé.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Un code d'autorisation est requis mais non présent.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Ce processeur n'accepte pas les autorisations à zéro dollar.",
      "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"La transaction a été refusée en raison d'une non-concordance AVS. L'adresse fournie ne correspond pas à l'adresse de facturation du titulaire de la carte.",
      "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Le mode de paiement bancaire n'est pas accepté pour le pays d'activité sélectionné.",
      "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s ne peut pas être laissé vide.",
      "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Une erreur s'est produite lors du traitement. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"L'appel du service Web renvoie une erreur.",
      "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"L'URL d'annulation est requise.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Cette transaction est en attente de règlement et ne peut être remboursée.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"Le nombre d'occurrences totales ne peut prolonger la durée de l'abonnement au-delà de trois ans à compter de la date de début.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"La carte de crédit a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Les totaux des montants des articles du panier ne correspondent pas aux montants des commandes. Assurez-vous que le total des paramètres de l'élément de détail du paiement correspond au total de la commande.",
      "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"Le compte de passerelle de paiement n'est pas activé pour la facturation ou le service CIM.",
      "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Autorisation client refusée.",
      "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Les seuls types de contenu pris en charge sont text/xml et application/xml.",
      "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"Impossible de décrypter les blobs DUKPT et une erreur renvoyée.",
      "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"Le pays n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"La carte de crédit n'est plus valable à la date de début de l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Le mode de paiement par carte de crédit n'est pas accepté pour le pays d'activité sélectionné.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Les transactions par carte de crédit ne sont pas acceptées par ce commerçant.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId et Customer data s'excluent mutuellement, un seul d'entre eux peut être fourni pour une même demande.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Erreur lors de la création d'un profil client à partir de la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Le numéro d'identification fiscale du client ou les informations sur le permis de conduire (numéro de permis de conduire, état du permis de conduire, date de naissance du permis de conduire) sont requis pour l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Lors de l'utilisation du profil client, CustomerPaymentProfileId doit être inclus.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Erreur lors de la création d'un profil de paiement client à partir de la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil client spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"L'opération de base de données a expiré avant d'avoir pu être terminée.",
      "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"La somme des crédits par rapport à la transaction référencée dépasserait le montant du débit d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Une erreur s'est produite lors du déchiffrement des données EMV.",
      "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"Le profil client n'a pas de profil de paiement/livraison par défaut.",
      "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"La date d'échéance est passée ou n'est pas spécifiée.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Un duplicata de l'abonnement a déjà été soumis.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Une transaction dupliquée a été soumise.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"KSN est dupliqué.",
      "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Un identifiant de profil client ou un e-mail valide est requis",
      "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"L'ID d'employé doit comporter entre 1 et %x caractères.",
      "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"Les données EMV ne sont pas prises en charge avec ce type de marché.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"La date d'expiration de la carte de crédit n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"Échec de l'envoi de l'e-mail.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Échec de la validation de la clé partagée.",
      "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Erreur fatale lors de l'appel du service Web.",
      "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"L'ID de marchand FDC ou l'ID de terminal est incorrect. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"Le type de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"L'une des valeurs de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"L'un des champs a une longueur non valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"L'un des champs obligatoires n'était pas présent.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"L'empreinte digitale ne peut pas être générée.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"La valeur de l'empreinte digitale n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Échec générique InsertNewMerchant.",
      "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Les numéros d'identification du Global Payment System sont incorrects. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_HASH":"Correspondance de hachage doesnâ€™t.",
      "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"La page de paiement hébergée saisira les informations de paiement (banque/carte) de sorte que ces informations ne doivent pas être incluses avec cette demande.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"La clé d'API d'emprunt d'identité n'est pas valide ou n'est pas présente.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"L'ID de connexion du Partenaire d'emprunt d'identité n'est pas valide ou n'est pas présent.",
      "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Une fois qu'un abonnement est créé, l'intervalle ne peut pas être modifié.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"Le nom d'utilisateur de l'API n'est pas valide et/ou la clé de transaction ou la clé d'API n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"L'ID de l'acheteur de l'enchère n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"Le type de transaction n'est pas valide pour les transactions ACH.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"Il y a des informations manquantes ou non valides dans un champ obligatoire.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"La durée de l'intervalle doit être de 7 à 365 jours ou de 1 à 12 mois.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal a rejeté la transaction.Identifiant de payeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"La combinaison de l'ID de la plateforme d'enchères et du nom de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"La valeur du mode d'entrée POS n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"L'ID de profil client, l'ID de profil de paiement, l'ID d'adresse de livraison ou l'ID de transaction pour cette demande n'est pas valide pour ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"Le nom du nœud racine de la requête XML correspond à la méthode API appelée. Ce n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"L'ID du vendeur aux enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"Le montant demandé pour le règlement ne peut pas être différent du montant initial autorisé. Veuillez annuler la transaction si nécessaire",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"La transaction V.me est dans un état invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"L'authentification de l'utilisateur nécessite une valeur valide pour la clé de transaction ou la clé d'API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"L'authentification de l'utilisateur nécessite une valeur valide pour le nom d'utilisateur de l'API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Valeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"La version EMV n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"La valeur x_call_id est introuvable ou invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"La valeur x_discount n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"La valeur x_giftwrap n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"La valeur x_misc n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal a rejeté la transaction. x_paypal_payflowcolor doit être une valeur hexadécimale de 6 caractères.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"La valeur x_subtotal n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Cette facture a été annulée par l'expéditeur. Veuillez contacter directement l'expéditeur si vous avez des questions.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Le numéro de facture n'a trouvé aucun enregistrement.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"Le montant de la transaction soumise était supérieur au montant maximum autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Le nombre maximum d'éléments pour un tableau a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Le nombre maximum de profils de paiement pour le profil client a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Le nombre maximum d'adresses de livraison pour le profil client a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"Le compte revendeur n'est pas activé pour l'API Merchant Boarding.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"Le marchand n'accepte pas ce type de carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"Le marchand a refusé l'autorisation de ressouNCE.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"Le marchand n'accepte pas ce type de carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"L'identifiant de connexion ou le mot de passe du marchand est invalide ou le compte est inactif.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"Le marchand a trop d'appareils en attente.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"Le marchand n'est pas activé.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"Le mot de passe marchand est invalide ou absent.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"Le marchand a été mal configuré chez le processeur. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Les numéros d'identification de Paymentech sont incorrects. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"Échec de l'analyse du MerchantId en nombre entier",
      "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"La valeur x_call_id est manquante.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"L'identifiant de l'appareil mobile n'est pas associé au compte marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"L'appareil mobile existe mais est en attente.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"L'appareil mobile existe mais son état est désactivé.",
      "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"Le pays doit être une valeur valide à deux ou trois caractères si spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"Le commerçant authentifié n'a pas accès au jeton OTS spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"Aucun enregistrement correspondant à votre requête n'a été trouvé.",
      "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"Il n'y a pas d'abonnements disponibles pour le compte marchand pour le type d'abonnement demandé.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"Le jeton d'accès n'est pas autorisé à appeler la méthode API.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"La transaction a été autorisée mais le client n'a pas pu être avisé ; ce ne sera pas réglé.",
      "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me by Visa ne prend pas en charge les annulations sur les transactions capturées ou créditées. Veuillez autoriser le règlement de la transaction, puis procéder au remboursement de la transaction capturée.",
      "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"L'une des valeurs de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Seule la Carte de Crédit ou la Banque est autorisée.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile et profileIds s'excluent mutuellement, un seul d'entre eux peut être fourni à la fois.",
      "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"La création d'un profil de paiement avec ce descripteur OpaqueData nécessite que transactionMode soit défini sur liveMode.",
      "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Si l'ID de profil client est inclus, il doit correspondre à l'ID de profil client utilisé pour l'abonnement d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Les données de jeton OTS spécifiées ne sont pas valides.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"Le jeton OTS spécifié a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"Le jeton OTS spécifié n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"Le service OTS ne peut pas traiter la demande en raison d'une erreur de validation ou de configuration.",
      "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"Le décalage de pagination dépasse la valeur autorisée. Vérifiez et réduisez la valeur.",
      "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Une erreur s'est produite lors de l'analyse des données EMV.",
      "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Ce processeur n'accepte pas les inversions partielles.",
      "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"Le compte Partenaire n'est pas autorisé à usurper l'identité du compte de connexion.",
      "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal a rejeté la transaction. Cet identifiant de payeur appartient à un autre client.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Le service Payment Gateway Account (id=8) a déjà été accepté. Le refus n'est pas autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"Le service de compte de passerelle de paiement (id=8) a déjà été refusé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"Le compte de passerelle de paiement n'est pas activé pour la facturation récurrente automatisée.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"Le compte de passerelle de paiement n'est pas activé pour Customer Information Manager (CIM).",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Les informations de paiement sont requises lors de la création d'un abonnement ou d'un profil de paiement.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"La création du profil client a échoué. Ce mode de paiement ne prend pas en charge la création de profil.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil de paiement spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Un échéancier de paiement est requis lors de la création d'un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"La modification du type de paiement de l'abonnement entre carte de crédit et eCheck.Net n'est actuellement pas prise en charge.",
      "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Statut en attente (non terminé).",
      "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"La condition préalable a échoué.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"Le compte du processeur n'a pas été configuré pour définir l'indicateur de mode de test sur faux.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"Le marchand n'a pas encore fourni d'informations sur le processeur pour définir l'indicateur de mode test sur faux.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"La configuration avec le processeur n'est pas valide. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"La création du profil client a échoué. Cet ID de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Les ID de profil de paiement et d'expédition ne peuvent pas être spécifiés lors de la création de nouveaux profils.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Les ID de profil de paiement et d'expédition ne peuvent pas être spécifiés lors de la demande d'une page de paiement hébergée.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Si l'ID du profil client, l'ID du profil de paiement et l'ID de l'adresse de livraison sont inclus, ils doivent correspondre à la transaction d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"La recherche d'un profil d'expédition à l'aide de l'ID de profil client et de l'ID de profil d'expédition n'a trouvé aucun enregistrement.",
      "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Un duplicata du profil client, du profil de paiement client ou de l'adresse client a déjà été soumis.",
      "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"Le paramètre RecurringBilling est un champ obligatoire pour les transactions de paiement à jeton récurrentes.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"ID de référence introuvable.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"La transaction référencée ne répond pas aux critères d'émission d'un avoir.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"L'action demandée n'est pas autorisée en raison de l'état actuel de l'objet.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Lors de la demande de CreateProfile, les données de paiement ne peuvent pas être nulles.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Paiement non autorisé. Le paiement n'a pas été autorisé par l'utilisateur.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"La version EMV est requise.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"Le numéro de terminal/voie doit être numérique.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Un descripteur opaque est requis.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"L'identifiant du payeur est requis.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"La signature doit être des données au format PNG.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"Les transactions PayPal nécessitent une URL valide pour cancel_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Les transactions PayPal nécessitent une URL valide pour success_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Nœud requis manquant.",
      "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Une erreur de validation a été renvoyée par V.me.",
      "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Nous retenons actuellement la dernière transaction pour examen. Avant de réactiver l'abonnement, vérifiez la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"Le nœud racine ne fait pas référence à un espace de noms XML valide.",
      "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"Impossible de définir un taux d'achat inférieur au taux de vente",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"Le serveur est actuellement trop occupé, veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Erreur du serveur",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"Le serveur est en maintenance, la méthode demandée n'est donc pas disponible. Veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Votre session a expiré ou n'existe pas. Vous devez vous reconnecter pour continuer à travailler.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"Le montant demandé pour le règlement ne peut être supérieur au montant initialement autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"L'autorisation a été approuvée mais le règlement a échoué.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"L'identifiant de l'adresse d'expédition et les données d'expédition s'excluent mutuellement, un seul d'entre eux peut être fourni pour une même demande.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil d'expédition spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Lorsque vous utilisez le profil client avec les informations de carte de crédit pour spécifier le profil d'expédition, l'identifiant du profil d'expédition doit être inclus.",
      "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Clé de signature manquante.",
      "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"Les rapports ne peuvent pas être générés pour des dates futures, la date spécifiée n'est donc pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Une fois qu'un abonnement est créé, la date de début ne peut pas être modifiée.",
      "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"L'état n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"La date de début de l'abonnement ne peut pas être antérieure à la date de soumission de l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Les abonnements arrivés à expiration ou résiliés ne peuvent pas être annulés.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Les abonnements arrivés à expiration, annulés ou résiliés ne peuvent pas être mis à jour.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"L'ID d'abonnement pour cette demande n'est pas valide pour ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"La date de début de l'abonnement est requise pour créer un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"Le code de devise fourni n'est pas valide, n'est pas pris en charge, n'est pas autorisé pour ce marchand ou n'a pas de taux de change.",
      "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"Le numéro de banque Elavon ou l'identifiant du terminal est incorrect. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Les données de signature sont trop volumineuses.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Cette transaction a été approuvée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"La transaction a été soumise à partir d'une adresse IP bloquée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"La transaction référencée ne répond pas aux critères d'émission d'une autorisation continue.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"La transaction référencée ne répond pas aux critères d'émission d'une autorisation continue avec capture automatique.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"La création du profil client a échoué. Ce type de transaction ne prend pas en charge la création de profil.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Cette transaction doit être cryptée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"L'ID de transaction est invalide ou absent.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Cette transaction ne peut pas être acceptée pour le moment en raison de la maintenance du système. Veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"La transaction est introuvable.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"Les transactions basées sur EMV ne sont actuellement pas prises en charge pour ce processeur et ce type de carte.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"Les données EMV ne sont pas prises en charge avec ce type de transaction.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"Les transactions PayPal ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"Les transactions PayPal nécessitent x_version d'au moins 3.1.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"Le type de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"Le nombre d'occurrences d'essai spécifié doit être inférieur au nombre total d'occurrences spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Une erreur système inattendue s'est produite lors du traitement de cette demande.",
      "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Erreur inconnue.",
      "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Facture créée mais échec de l'envoi de l'e-mail et mise à jour de l'état",
      "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"L'URL du lien de référence, de réponse de relais ou de réception n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"L'APIUser existe déjà.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"L'utilisateur ne dispose pas des autorisations suffisantes pour utiliser un appareil mobile avec ce compte marchand.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"L'utilisateur n'est pas autorisé à appeler l'API.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"L'utilisateur n'est pas autorisé à appeler la méthode API.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Un montant valide est requis.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"Le nombre d'occurrences d'essai ne peut pas être égal à zéro si un montant d'essai valide est soumis.",
      "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Si un montant d'essai ou un nombre d'occurrences d'essai est spécifié, les valeurs pour les deux doivent être soumises.",
      "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Les numéros d'identification VITAL sont incorrects. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Ceci est le résultat d'une erreur de l'analyseur XML.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Ceci est le résultat d'une erreur de l'analyseur XML. Le nœud est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Ceci est le résultat d'une erreur de l'analyseur XML. Champ(s) manquant(s).",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Ceci est le résultat d'une erreur de l'analyseur XML. Trop de nœuds spécifiés.",
      "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La combinaison du type de carte, de l'indicateur d'authentification et de la valeur d'authentification du titulaire de la carte n'est pas valide.",
      "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"Le type de carte commerciale n'est pas valide.",
      "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Ce pays n'est pas autorisé pour les acheteurs.",
      "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"Le cryptogramme est invalide ou ne peut pas être utilisé en combinaison avec d'autres paramètres.",
      "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"Le type d'appareil n'est pas valide ou est manquant.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"La date de naissance du permis de conduire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"Le numéro de permis de conduire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"L'état du permis de conduire est invalide.",
      "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"Le montant des droits n'est pas valide.",
      "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Ce type eCheck.Net n'est pas autorisé lors de l'utilisation du formulaire de paiement hébergé par la passerelle de paiement.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Ce type eCheck.Net n'est pas autorisé.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Ce type eCheck.Net n'est pas autorisé pour ce type de compte bancaire.",
      "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"Le paramètre de type eCheck n'est pas valide.",
      "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"Le montant du fret n'est pas valide.",
      "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"La passerelle ne prend plus en charge la méthode d'intégration demandée.",
      "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"Le numéro de chèque est invalide.",
      "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"La date d'expiration de la carte de crédit n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"L'élément de ligne %1 n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"L'ID de la liste d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"Le type d'enchère n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"L'ID de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Il y a un ou plusieurs champs obligatoires manquants ou non valides.",
      "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"Le SplitTenderID spécifié n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Longueur ECI du demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Longueur d'ID de demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Nom de demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"Le nom de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"Le nombre d'éléments de ligne soumis n'est pas autorisé. Un maximum de %1 éléments de ligne peut être soumis.",
      "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"Le type de marché n'est pas valide",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Ce compte marchand a été clôturé.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Le compte marchand n'est pas actif. Ce paiement automatisé ne sera pas traité.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Le compte marchand est en mode test. Ce paiement automatisé ne sera pas traité.",
      "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Ce processeur n'accepte pas l'autorisation à zéro dollar pour ce type de carte.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Une erreur s'est produite lors du traitement. Veuillez contacter le commerçant.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Un ou plusieurs des champs de configuration de type HTML ne semblent pas sûrs.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Une ou plusieurs des valeurs de sous-commerçant requises sont manquantes.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Une ou plusieurs des valeurs de sous-marchand ne sont pas valides.",
      "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"Le PayformMask n'est pas valide.",
      "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Une erreur s'est produite lors du traitement des données de paiement.",
      "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"Le traitement des paiements Apple n'est pas activé pour ce compte marchand.",
      "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Ce processeur n'accepte pas les transactions récurrentes.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Ce processeur ne permet pas l'annulation des crédits.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Ce processeur ne prend pas en charge cette méthode de soumission des données de paiement.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Cette demande ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"L'ID de transaction et l'ID d'appel d'offres fractionné ne peuvent pas être utilisés tous les deux dans la même demande.",
      "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"Le type de formulaire demandé n'est pas valide.",
      "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"Le montant de cette demande n'a été que partiellement approuvé sur la carte prépayée donnée. Un paiement supplémentaire est requis pour régler le solde de cette transaction.",
      "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"L'URL de réussite est requise.",
      "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"Le format de réponse n'est pas valide",
      "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"Annulation non prise en charge pour ce type de transaction.",
      "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"Le script demandé n'est pas valide ou n'est plus pris en charge.",
      "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Les scripts ne sont pris en charge que dans la version 2.5.",
      "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"L'état ou le pays d'expédition n'est pas valide.",
      "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"Le SSN ou l'identifiant fiscal n'est pas valide.",
      "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"Le montant du supplément n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"Le montant de la taxe n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"Le montant du pourboire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Les données de Track1 ne sont pas dans un format valide.",
      "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Les données Track2 ne sont pas dans un format valide.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"La transaction a été autorisée mais le client n'a pas pu être avisé ; ce ne sera pas réglé.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Cette transaction ne peut pas être acceptée pour le moment.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Cette transaction ne peut pas être traitée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Les transactions ayant des valeurs d'authentification de titulaire de carte ne peuvent pas être marquées comme récurrentes.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Les transactions de ce type de marché ne peuvent pas être traitées sur ce système.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"La méthode de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Cette transaction ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Cette transaction ne peut pas être acceptée pour traitement.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"La transaction référencée n'a pas été approuvée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"Le type de transaction indiqué n'est pas pris en charge pour ce marchand.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"La transaction a entraîné une non-concordance AVS. L'adresse fournie ne correspond pas à l'adresse de facturation du titulaire de la carte.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"Le type de transaction n'est pas valide",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"Le type de transaction n'est pas valide. Veuillez contacter le marchand.",
      "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Un pays de facturation valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Un pays valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Un état/province de facturation valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Un état valide est requis.",
      "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"La version 2.5 n'est plus prise en charge.",
      "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"Le paramètre de version n'est pas valide",
      "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Autoriser l'accès aux administrateurs globaux CSP",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"Le paiement a été initié avec succès. Le traitement prendra quelques temps. Veuillez vérifier le statut après un certain moment.",
      "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"L'indicateur de renouvellement automatique s'applique uniquement aux offres NCE",
      "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"La création du produit du plan n'a pas réussi pendant le processus de synchronisation automatique",
      "AUTO_SYNC_MACRO_NOT_FOUND":"Macro introuvable",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Plusieurs offres mappées trouvées",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Plusieurs offres mappées trouvées avec la promotion",
      "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Plans multiples trouvés",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"Aucune offre mappée trouvée",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Aucune offre mappée trouvée avec la promotion",
      "AUTO_SYNC_NO_PLAN_FOUND":"Aucun plan trouvé",
      "Autotask":"Autotask",
      "AUTOTASK_API_USER_NAME_IS_MISSING":"Le nom d'utilisateur de l'API est manquant. Veuillez mettre à jour le nom d'utilisateur dans l'écran des paramètres.",
      "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"Incompatibilité de quantité de tâche automatique avec l'ancienne quantité C3",
      "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Sélectionnez un droit",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Catégorie",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Description",
      "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Impact",
      "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Ressource impactée",
      "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Dernière mise à jour",
      "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Des bénéfices potentiels",
      "Azure_BILLING":"Facturation Azure",
      "AZURE_BILLING":"Facturation Azure",
      "AZURE_BILLING_CYCLE":"Cycle de facturation",
      "Azure_ENTITLEMENT_MANAGEMENT":"Droit Azure",
      "AZURE_ENTITLEMENT_MANAGEMENT":"Droit Azure",
      "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Gestion des droits Azure",
      "Azure_ENTITLEMENT_NOT_FOUND_FOR_THE_AzurePLAN":"Il manque un droit Azure au locataire !",
      "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"Il manque un droit Azure au locataire !",
      "AZURE_ERROR_RECOMMENDATION_TEXT":"Erreur lors de la récupération des recommandations Azure Advisor",
      "Azure_ESTIMATE":"Estimation Azur",
      "AZURE_ESTIMATE":"Estimation Azur",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Période de facturation",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Identifiant du droit",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Nom du droit",
      "AZURE_ESTIMATE_LABEL_DEPARTMENT":"Département :",
      "AZURE_ESTIMATE_LABEL_SITE":"Site :",
      "AZURE_ESTIMATE_REPORT":"Rapport d'estimation Azure",
      "AZURE_ESTIMATE_TAGS":"Balises",
      "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Veuillez sélectionner au moins une balise pour télécharger l'estimation azur",
      "Azure_ESTIMATE_TAGS_POPUP_INFO":"Veuillez sélectionner au moins une balise pour télécharger l'estimation Azure",
      "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Balises azur",
      "Azure_ESTIMATE_TAGS_POPUP_TITLE":"Balises Azure",
      "AZURE_ESTIMATE_TAGS_TOOLTIP":"Balises de ressources définies dans le portail Azure",
      "Azure_ESTIMATE_TAGS_TOOLTIP":"Balises ResouNCE définies dans le portail Azure",
      "AZURE_ESTIMATED_COST":"Coût estimé",
      "AZURE_ESTIMATES":"Estimations Azure ",
      "AZURE_GROUP_DETAILS":"Informations du groupe",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Groupes Azure ",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Ajouter Admin au Groupe",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Ajouter un groupe",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Retour aux groupes d'utilisateurs",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Retour aux groupes",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Modifier le groupe",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Utilisateurs du groupe",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Ajouter un groupe d'utilisateurs",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Groupes Azure ",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Utilisateurs de {{groupname}}",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrateur",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Accès facturation",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Seuil de danger",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Description",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Courriel",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Seuil d'erreur",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Nom du groupe",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Seuil d'information",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Fréquence de notifications",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":" Seuil d’avertissement",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrateur",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Accès facturation",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Nom",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Fréquence de notifications",
      "AZURE_LOADING":"Tentative de chargement des données à partir d'azure, veuillez patienter...",
      "AZURE_LOADING_MESSAGE":"Interroger Microsoft Azure et cela peut prendre une minute à charger ...",
      "Azure_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"La valeur de la marge Azure a été mise à jour avec succès",
      "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"La valeur de la marge Azure a été mise à jour avec succès",
      "AZURE_NEW_MARKUP":"Nouveau balisage",
      "Azure_OLD_MARKUP":"Marge actuelle",
      "AZURE_OLD_MARKUP":"Marge actuelle",
      "Azure_PLAN_CREATION_FAILED":"Échec de la création du plan Azure",
      "AZURE_PLAN_CREATION_FAILED":"Échec de la création du plan Azure",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"Le montant de la facture sera calculé pour le client, ainsi que pour leurs sites ou départements respectifs, en fonction du pourcentage alloué.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"L’allocation du plan Azure et ses droits peuvent être partagés entre différentes entités de site et de service en attribuant un pourcentage.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Instructions",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Étapes à attribuer : <br /> a. Développez l'abonnement souhaité. <br /> b. Cliquez sur le pourcentage associé à une entité. <br /> c. Entrez le pourcentage souhaité. <br /> d. Cliquez sur <strong> Soumettre <strong/> pour enregistrer l'allocation.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Pour attribuer entièrement le plan Azure ou l’autorisation à un site/service, entrez 100.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Pour libérer une allocation, entrez 0.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Les allocations libérées seront attribuées à l'entité mère immédiate.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Ces allocations n’auront aucun impact sur les rapports et le rapport Azure Estimate and Billing continuera d’afficher toutes les données du client.",
      "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Note par catégorie",
      "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Toutes catégories",
      "AZURE_RECOMMENDATION_ALL_COST":"Coût",
      "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"La haute disponibilité",
      "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"L'excellence opérationnelle",
      "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Performance",
      "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Fiabilité",
      "AZURE_RECOMMENDATION_ALL_SECURITY":"Sécurité",
      "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Télécharger les détails de la recommandation",
      "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Détails de la recommandation",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Ce champ est obligatoire",
      "AZURE_RECOMMENDATION_SUBMIT":"Soumettre",
      "AZURE_RECOMMENDATION_TEXT":"Recommandation Azure",
      "AZURE_RECOMMENDATIONS_TEXT":"Recommandations",
      "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Sélectionnez un code de devise",
      "AZURE_REPORT_DISTRIBUTOR_LIST":"Distributeur",
      "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Veuillez sélectionner soit le partenaire, soit l'un des revendeurs.",
      "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Sélectionnez une entité",
      "AZURE_REPORT_ENTITY_LIST":"Entité",
      "AZURE_REPORT_ENTITY_TOOLTIP":"Veuillez sélectionner soit le partenaire, soit l'un des revendeurs.",
      "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Sélectionnez un revendeur",
      "AZURE_REPORT_RESELLERS_LIST":"Choisissez un revendeur",
      "Azure_REPORTS_BITTON_TEXT":"Rapport d'estimation Azure",
      "AZURE_REPORTS_BITTON_TEXT":"Rapport d'estimation Azure",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Détails par",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Détails par groupe de ressource",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Détails par service",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Obtenir les détails",
      "Azure_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sans étiquettes",
      "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sans étiquettes",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Rapports Azure ",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "Azure_REPORTS_BY_TAGS_BITTON_TEXT":"Avec des tags",
      "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Avec des tags",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Résumé de facturation",
      "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Tarifs du plan Azure",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"Date",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Droit",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"Groupe",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Mois",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Sélectionner un groupe",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Abonnement",
      "Azure_REPORTS_OPTION_SELECT_TAG":"Sélectionnez une balise pour obtenir des données par balise",
      "AZURE_REPORTS_OPTION_SELECT_TAG":"Sélectionnez une balise pour obtenir des données par balise",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Tous les droits",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Tous les groupes",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Tous les abonnements",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Sélectionner une date",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Non attribué",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Coût",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Nom de ressource ",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Nom de service",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Audit",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Détails",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Groupe de ressource",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Nom de ressource ",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Nom de service",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Coût total",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Date-heure",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Catégorie d'événement",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Événement initié par",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Nom de l'opération",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Statut",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Audit",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Détails",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Coût",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Date",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Coût total",
      "AZURE_REPPORT_CURRENCY":"Devise",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Vous devez sélectionner une devise pour obtenir le rapport",
      "AZURE_REPPORT_CUSTOMERS":"Clients",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Sélectionner un client",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Sélectionner un client",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"Contributeur",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Rôle personnalisé",
      "AZURE_ROLE_NAME_OWNER":"Propriétaire",
      "AZURE_ROLE_NAME_READER":"Lecteur",
      "AZURE_SUBSCRIPTION":"Abonnement Azure",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Modifier",
      "AZURE_SUBSCRIPTION_DETAILS":"Détails de l'abonnement",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Sélectionner",
      "AZURE_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "Azure_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Il manque un plan Azure au locataire !",
      "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Il manque un plan Azure au locataire !",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Échec de la mise à jour de l'abonnement Azure",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"L'abonnement Azure a été mis à jour avec succès",
      "Azure_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Voulez-vous vraiment effectuer la mise à niveau ?",
      "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Voulez-vous vraiment effectuer la mise à niveau ?",
      "AZURE_THRESHOLD_DETAILS":"Détails du seuil",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Définir un budget mensuel pour cet abonnement et définir les seuils avec plusieurs sévérités telles qu’Info, Avertissement, Erreur et Danger.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Pour les abonnements gérés par le client, tous les administrateurs de groupes seront avertis lorsque l'utilisation atteindra ou dépassera un certain seuil.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Les notifications sont envoyées une fois par cycle de facturation.",
      "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Forfait Azur",
      "Azure_UPGRADE_Azure_PLAN_TEXT":"Plan Azur",
      "Azure_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Veuillez patienter, nous vérifions l'éligibilité au surclassement",
      "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Veuillez patienter, nous vérifions l'éligibilité au surclassement",
      "Azure_UPGRADE_INELIGIBLE_MESSAGE":"L'abonnement n'est pas éligible à la mise à niveau",
      "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"L'abonnement n'est pas éligible à la mise à niveau",
      "Azure_UPGRADE_WAIT_MESSAGE":"Veuillez patienter Nous traitons la mise à niveau...",
      "AZURE_UPGRADE_WAIT_MESSAGE":"Veuillez patienter Nous traitons la mise à niveau...",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Retour à la liste",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Utilisation Azure",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Détails d'utilisation",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Consulter les détails",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Estimation du prix",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Nom",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} ajouté au groupe {{groupname}} avec succès",
      "AZUREGROUPS_SAVE_SUCCESS":"Groupe {{username}} sauvegardé avec succès",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Groupe d'utilisateur ajouté avec succès",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Groupe sauvegardé avec succès",
      "BACK_TO_AZURE_ADVISOR":"Retour au conseiller Azure",
      "BACK_TO_POLICIES":"Retour aux politiques personnalisées",
      "BACK_TO_PUBLIC_SIGNUP_SHOP":"Retour à la boutique",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Retour aux domaines du fournisseur",
      "BACKGROUND_DROPDOWN_TEMPLATE_1":"Modèle 1",
      "BACKGROUND_DROPDOWN_TEMPLATE_10":"Modèle 10",
      "BACKGROUND_DROPDOWN_TEMPLATE_2":"Modèle 2",
      "BACKGROUND_DROPDOWN_TEMPLATE_3":"Modèle 3",
      "BACKGROUND_DROPDOWN_TEMPLATE_4":"Modèle 4",
      "BACKGROUND_DROPDOWN_TEMPLATE_5":"Modèle 5",
      "BACKGROUND_DROPDOWN_TEMPLATE_6":"Modèle 6",
      "BACKGROUND_DROPDOWN_TEMPLATE_7":"Modèle 7",
      "BACKGROUND_DROPDOWN_TEMPLATE_8":"Modèle 8",
      "BACKGROUND_DROPDOWN_TEMPLATE_9":"Modèle 9",
      "BAD_INPUT_INVALID_PRODUCTID":"ID de produit invalide",
      "BAD_INPUT_INVALID_SEATS":"Sièges invalides",
      "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entités impliquées dans la notification de la bannière",
      "BILL_ACTION_DESC_FULL_CHARGE":"Plein tarif pour tout le mois.",
      "BILL_ACTION_DESC_NO_REFUND":"Aucun remboursement ne sera fourni",
      "BILL_ACTION_DESC_PRORATE":"Le prorata sera appliqué dans la facture.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Plein tarif",
      "BILL_ACTION_NAME_NO_REFUND":"Aucun remboursement",
      "BILL_ACTION_NAME_PRORATE":"Au prorata",
      "BILL_CYCLE_DESC_ANNUAL":"Annuel",
      "BILL_CYCLE_DESC_MONTHLY":"Mensuel",
      "BILL_CYCLE_DESC_ONETIME":"Une fois",
      "BILL_CYCLE_DESC_TRIENNIAL":"Triennal",
      "BILL_CYCLE_NAME_ANNUAL":"Annuel",
      "BILL_CYCLE_NAME_MONTHLY":"Mensuel",
      "BILL_CYCLE_NAME_TRIENNIAL":"Triennal",
      "BILL_FROM":"Facture de",
      "BILL_TO":"Facturé à",
      "Billing":"Facturation",
      "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"Échec de la suppression du lien client de facturation.",
      "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"Lien client de facturation supprimé avec succès.",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"La modification du cycle de facturation d'un produit n'est pas autorisée, car une affectation de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Nom de produit actuel",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Le cycle de facturation de la commande a été modifié avec succès",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Cycle de facturation",
      "BILLING_FORECAST_REPORT":"Prévision de facturation",
      "BILLING_PERIOD":"Période de facturation",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Sélectionnez au moins une période de facturation",
      "BILLING_TYPE_DESC_MARK_UP":"Majoration",
      "BILLING_TYPE_DESC_METERED_BILLING":"Facturation au compteur",
      "BILLING_TYPE_DESC_PERCENTAGE":"Pourcentage",
      "BILLING_TYPE_DESC_PRICE":"Prix",
      "BILLING_TYPE_DESC_UNIT":"Unité",
      "BILLING_TYPE_TOOLTIP":"Type de facturation",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Estimations Azure",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Abonnement Azure",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Licences",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Rapports Power BI",
      "BREADCRUMB_HEADER_TEST_PAYMENT":"Paiement test",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Détails de l'état du paiement",
      "BTN_Azure_BILLING_COMPARISON_REPORT":"Rapport de comparaison de facturation Azure",
      "BTN_AZURE_BILLING_COMPARISON_REPORT":"Rapport de comparaison de facturation Azure",
      "BTN_BILLING_FORECAST_REPORT_LOADING":"Prévision de facturation...",
      "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Supprimer l'enregistrement",
      "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Télécharger le zip de la facture",
      "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Afficher les détails de la facture",
      "BTN_INVOICE_LINE_ITEMS":"Postes de facture",
      "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Éléments de ligne de facture avec taxes...",
      "BTN_INVOICE_PAYMENTS":"Paiements de facture",
      "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Retour aux paiements",
      "BTN_QUOTE_COPY_URL":"Copier l'URL",
      "BTN_QUOTE_PUBLISH":"Publier une citation",
      "BTN_QUOTE_REPUBLISH":"Rééditer la citation",
      "BTN_QUOTE_SAVE_DRAFT":"Enregistrer le brouillon",
      "BTN_TEXT_ADD_TO_CART":"Ajouter au panier",
      "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Retour à l'histoire",
      "BTN_TEXT_BEGIN_UPLOAD":"Charger",
      "BTN_TEXT_BEGIN_VALIDATE_DATA":"Valider",
      "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Regardes les connexions",
      "BTN_TEXT_CANCEL_ORDER":"annuler la commande",
      "BTN_TEXT_COMMENT":"Commentaire",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"Copier dans le presse-papiers",
      "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Télécharger tous les bons",
      "BTN_TEXT_FILTER":"Filtrer",
      "BTN_TEXT_FIX":"Corriger",
      "BTN_TEXT_IGNORE":"Ignorer",
      "BTN_TEXT_INCLUDE":"Inclure",
      "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Promotion de la carte",
      "BTN_TEXT_MAPPING_OF_PRODUCTS":"Mappage des produits",
      "BTN_TEXT_OPEN_IN_EMAIL":"Ouvrir dans le courriel",
      "BTN_TEXT_PROCEED":"Procéder",
      "BTN_TEXT_QUICK_VIEW":"Aperçu rapide",
      "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Paiements multiples",
      "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Paiement unique",
      "BTN_TEXT_REMOVE":"Supprimer",
      "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Supprimer la promotion",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Demander une relation de revendeur",
      "BTN_TEXT_SHOW_FILTERS":"Afficher les filtres",
      "BTN_TEXT_SIGN_IN":"Connexion",
      "BTN_TEXT_SKIP":"Ignorer",
      "BTN_TEXT_TAKE_ON":"Inclure",
      "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Gérer les téléchargements de tâches automatiques",
      "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Gérer les téléchargements Connectwise",
      "BTN_TEXT_VIEW_PRODUCT":"Voir le produit",
      "BULK_ADD_ENTITLEMENT_BATCH_ID":"BatchId",
      "BULK_ADD_ENTITLEMENT_DESCRIPTION":"Le dernier nom d'autorisation créé est",
      "BULK_ADD_TENANT_DESCRIPTION":"Le nom du dernier locataire créé est",
      "BULK_ADD_TENANTS_BATCH_ID":"BatchId",
      "BULK_DATA_MAPPING_CSV_UPLOAD":"Télécharger une feuille cartographiée",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Retour au résumé des revenus et des coûts",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"Annuler",
      "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Sélectionnez au moins un client",
      "BULK_INVOICE_DOWNLOAD_HEADER":"Télécharger des factures groupées",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Recevez toutes les factures par e-mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Entrez l'adresse e-mail à qui vous souhaitez envoyer un e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur Soumettre pour recevoir un fichier zip contenant la liste des factures PDF pour la période de facturation sélectionnée",
      "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"Nous informerons les e-mails fournis une fois que le fichier zip sera prêt à être téléchargé ! Il faut généralement une heure pour terminer",
      "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Sélectionnez le plan",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Fermer",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"La facture groupée a été supprimée avec succès.",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Propriétaire",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Statut",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Utilisateur",
      "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Sélectionnez le plan",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"Soumettre",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Voulez-vous vraiment supprimer cet enregistrement ?",
      "BULK_INVOICE_PROGRESS_POPUP_HEADER":"État du téléchargement de la facture",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Actions",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Terminé à",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-mails",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Initiateur",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Lien disponible à ce jour",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Date de début",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Statut",
      "BULK_ONBOARD_BATCH_ID":"BatchId d'intégration massive",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"BatchId d'intégration massive",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Télécharger les détails du mappage",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Télécharger le mappage d'abonnement",
      "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"Mappé <b>{{ TotalSubscriptionsOnboarded }}</b> sur <b>{{ TotalSubscriptionsAtProvider }}</b> abonnements depuis l'Espace Partenaires avec succès.",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous supprimer tous les clients sélectionnés ?",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner tous les clients ?",
      "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":"Sélectionné <b>{{selectedCustomers}}</b> sur <b>{{totalCustomers}}</b> clients pour l'intégration",
      "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Revendeurs existants à bord",
      "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Veuillez fournir une adresse e-mail valide",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Demande en attente pour l'intégration massive de clients",
      "BULK_ONBOARD_RESELLERS_BATCH_ID":"BatchId",
      "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"Le lot est terminé avec succès. Vous serez redirigé vers les revendeurs.",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Sélectionner dans la liste",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"Importer un fichier CSV",
      "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Veuillez sélectionner au moins 1 revendeur à intégrer.",
      "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Résumé des résultats à bord",
      "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Total sélectionné : {{totalResellersInBatch}}, Total intégré : {{resellersOnboarded}}, Total échoué : {{resellersFailedToOnboard}}",
      "BULK_ONBOARD_RESELLERS_LBL_OPTION":"Comment voulez-vous embarquer ?",
      "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous supprimer tous les revendeurs sélectionnés ?",
      "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner tous les revendeurs ?",
      "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"La demande de revendeurs intégrés en masse est mise en file d'attente.<br /> Vous serez redirigé vers l'écran d'état qui affiche la progression.",
      "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"Sélection de <b>{{selectedResellers}}</b> sur <b>{{totalResellers}}</b> revendeurs pour l'intégration",
      "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"Erreurs",
      "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":"Identifiant MPN -",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"ResellersFailed",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"ResellersSucceeded",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"Nombre total de revendeurs sélectionnés",
      "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Entrez une adresse e-mail valide",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Il semble que les détails de l'ID MPN manquent pour le revendeur. Veuillez contacter le Partenaire pour mettre à jour les détails.",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"L'intégration n'est pas prise en charge !",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"L'intégration massive des clients MicrosoftNonCSP n'est pas prise en charge.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Revendeurs intégrés en vrac",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Statut",
      "BULK_ONBOARDING_STATUS_INFO_HEADER":"Conseil! Étapes pour corriger l'intégration partielle",
      "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Si des problèmes de mappage d'abonnement sont signalés par le système lors de l'intégration d'un client, cliquez sur le lien hypertexte pour télécharger et consulter les détails. Corrigez vos plans et travaillez avec le support pour synchroniser les données dans C3.",
      "BULK_PRODUCT_MAPPING":"Cartographie des produits en vrac",
      "BULK_PURCHASED_PRODUCT_MAPPING":"Cartographie des produits d'achat en gros",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Téléchargez le catalogue pour obtenir les clients et leurs identifiants.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Remplissez le code devise, le montant du paiement et la date de paiement pour le C3Id correspondant.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Assurez-vous que les données respectent les règles suivantes :",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> Identifiant client C3 (ou identifiant revendeur C3) du catalogue pour lequel le client souhaite que le paiement soit enregistré.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode - Texte (code de devise standard ISO.) –</strong> Dans quelle devise le paiement doit être enregistré",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount - Décimal –</strong> Montant à enregistrer",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Remarques – Texte –</strong> Commentaires (Facultatif)",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Date (AAAA-MM-JJ) –</strong> À quelle date le paiement doit être enregistré ! Uniquement les dates passées !",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Cliquez sur <strong>Sélectionner</strong> et accédez au fichier.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Cliquez sur <strong>Valider le catalogue</strong> pour que le système valide la saisie.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"S'il n'y a aucune erreur de validation dans la saisie, le bouton <strong> Importer les paiements</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Terminer",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Clients sélectionnés",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Sélectionner au moins un client/sélectionner un plan",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Voulez-vous supprimer le client ?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"La demande d'ajout de plusieurs clients est acceptée",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Télécharger un exemple de fichier .csv",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Téléchargez le catalogue pour obtenir les derniers codes et séquences.",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"source pour référence.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Instructions",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Remplissez le code ou la séquence ou les deux pour les produits souhaités et supprimez les lignes que vous ne souhaitez pas modifier. Le code produit est facultatif et prend en charge 100 caractères. La séquence d'affichage est un nombre et est facultative.<br /><strong>Remarque :</strong> Si vous laissez les deux vides, le code et la séquence existants sont effacés.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Cliquez sur <strong class=\"text-uppercase\">Valider le catalogue</strong> pour que le système valide l'entrée.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"Importation terminée !",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"Validation terminée !",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Importer le catalogue",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Valider le catalogue",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez tous les clients",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez le département",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Téléchargez le catalogue pour obtenir les derniers détails sur les commissions sur les produits.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Remplissez le nom de l'agent, le SPCode, le PeNCEntage de commission, la date de début de la commission, la date de fin de la commission pour les produits souhaités et supprimez les lignes que vous ne souhaitez pas modifier. Toutes ces colonnes sont facultatives. <br /> Le nom de l'agent est un texte prenant en charge 100 caractères. <br />SPCode est un texte prenant en charge 100 caractères. <br />Commission PeNCEntage est un nombre qui prend également en charge les décimales. <br />La date de début de la commission est une date qui doit être définie au format <strong>{{dateFormat}}</strong> format.<br />La date de fin de la commission est une date qui doit être définie au format <strong>{ {dateFormat}}</strong> format.<br /><strong>Remarque :</strong> Si vous mettez à jour les valeurs existantes à vide, les valeurs existantes seront effacées une fois le catalogue importé.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Cliquez sur <strong class=\"text-uppercase\">Valider le catalogue</strong> pour que le système valide l'entrée.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez un site",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"Voulez-vous supprimer le revendeur ?",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Les abonnements suivants n'ont pas d'offres équivalentes dans le plan. Veuillez mettre à jour le plan et réessayer.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"Impossible de trouver un client dans CSP avec l'identifiant : {{CSPCustomerId}}",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Les offres suivantes ont été achetées plus d'une fois dans un seul niveau, ce qui n'est pas pris en charge dans C3. Veuillez vérifier les données.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Supprimer",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Votre dernière opération de mise à jour semble avoir quelques erreurs, veuillez contacter le soutien technique pour obtenir de l'aide",
      "BUNDLE_PRODUCTS_PLACEHOLDER":"Produits en bouquet",
      "BUNDLE_REPORT_FILE_TYPES_HEADER":"Rapport détaillé sur les produits groupés",
      "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les détails du produit groupé",
      "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des détails du produit Bundle.",
      "BUNDLE_SAVE_SUCCESS":"Paquet {{customoffer}} enregistré avec succès",
      "BUNDLE_UPDATE_SUCCESS":"Le pack {{customoffer}} a été mis à jour avec succès",
      "bundles":"Forfaits",
      "BUNDLES_BILLING_CYCLE_TOOLTIP":"Fréquence de facturation à laquelle le bouquet serait facturé",
      "BUNDLES_BILLING_TYPE_TOOLTIP":"Catégorie de facturation sur laquelle le bouquet serait facturé",
      "BUNDLES_CANNOT_BE_DELETED":"Les bouquet ne peuvent pas être supprimés car ils sont affectés à un plan",
      "BUNDLES_SALETYPE_TOOLTIP":"Type de catégorie pour l'offre",
      "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"L'importation est en cours",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Veuillez patienter...",
      "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"La validation est en cours",
      "BUTTON_ACTIVATE":"Activer",
      "BUTTON_APPLY_COUPON_TEXT":"Appliquer un coupon",
      "BUTTON_CLOSE":"Fermer",
      "BUTTON_DEACTIVATE":"Désactiver",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Annuler",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Télécharger",
      "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Actualiser",
      "BUTTON_LANG_CN":"Chinois",
      "BUTTON_LANG_DE":"Allemand",
      "BUTTON_LANG_EN":"Anglais",
      "BUTTON_LANG_ES":"Espagnol",
      "BUTTON_LANG_FR":"français",
      "BUTTON_LANG_IT":"Italien",
      "BUTTON_LANG_SK":"slovaque",
      "BUTTON_LANG_TH":"thaïlandais",
      "BUTTON_LANG_TR":"turc",
      "BUTTON_LANG_TW":"Taïwanais",
      "BUTTON_LANGUAGE":"Langue",
      "BUTTON_MANAGE_PRODUCT":"Gérer",
      "BUTTON_ONHOLD":"En attente",
      "BUTTON_PROCESSING":"Traitement",
      "BUTTON_RELEASE":"Libérer",
      "BUTTON_REVOKE":"Révoquer",
      "BUTTON_START_TOUR":"Faites un tour",
      "BUTTON_TEXT_ACCEPT":"Accepter",
      "BUTTON_TEXT_ACTIONS":"Actions",
      "BUTTON_TEXT_ADD":"Ajouter",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Ajouter une nouvelle conversion de devises",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"Ajouter une politique personnalisée ",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Ajouter une nouvelle notification par courriel d'événement",
      "BUTTON_TEXT_ADD_SUB_TAX":"Ajouter une sous taxe",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Ajouter avec extensions",
      "BUTTON_TEXT_APPLY":"Appliquer",
      "BUTTON_TEXT_APPROVE":"Approuver",
      "BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "BUTTON_TEXT_CANCEL":"Annuler",
      "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Vérifier tous les statuts",
      "BUTTON_TEXT_CLEAR":"Effacer",
      "BUTTON_TEXT_CLOSE":"Fermer",
      "BUTTON_TEXT_DELETE":"Supprimer",
      "BUTTON_TEXT_DOWNLOAD":"Télécharger",
      "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Télécharger les gestionnaires de comptes",
      "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Télécharger le catalogue",
      "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Télécharger les clients",
      "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Télécharger le récapitulatif des journaux de publication des factures",
      "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Télécharger l'élément de ligne de facture",
      "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Télécharger la facture des cotisations",
      "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Télécharger les offres partenaires",
      "BUTTON_TEXT_DOWNLOAD_PLAN":"Télécharger le plan",
      "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Télécharger l'offre de plan",
      "BUTTON_TEXT_DOWNLOAD_REPORT":"Télécharger le rapport",
      "BUTTON_TEXT_EDIT":"Modifier",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Exporter vers Excel",
      "BUTTON_TEXT_FILTER":"Filtrer",
      "BUTTON_TEXT_LOAD_OFFERS":"Charger les offres",
      "BUTTON_TEXT_OK":"OK",
      "BUTTON_TEXT_OK_CLOSE":"Ok, ferme",
      "BUTTON_TEXT_OVERRIDE":"Remplacer",
      "BUTTON_TEXT_PAY_INVOICES":"Payer des factures en attente",
      "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Procéder à l'achat",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Enregistrer le paiement anticipé",
      "BUTTON_TEXT_REJECT":"Rejeter",
      "BUTTON_TEXT_REMOVE":"Supprimer",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Supprimer la sous taxe",
      "BUTTON_TEXT_REPORTS":"Rapports",
      "BUTTON_TEXT_RESET":"Réinitialiser",
      "BUTTON_TEXT_REVERT":"Rétablir",
      "BUTTON_TEXT_SAVE":"Enregistrer",
      "BUTTON_TEXT_SEARCH":"Rechercher",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Définir l'entité opérationnelle",
      "BUTTON_TEXT_SUBMIT":"Soumettre",
      "BUTTON_TEXT_UPDATE":"Mettre à jour",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"CHARGER SUR QUICKBOOKS ONLINE",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"CHARGER SUR XERO",
      "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Voir les parents",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"AFFICHER LES JOURNAUX DE PUBLICATION",
      "BUTTON_TEXT_VIEW_OFFERS":"Afficher les offres",
      "BUTTON_VIEW_ERRORS":"Afficher les erreurs",
      "BUTTON_VIEW_PRODUCT":"Afficher",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Ajouter balise",
      "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Modifier la balise",
      "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"pour",
      "C3_CART":"Panier",
      "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Catégorie de fournisseur",
      "C3_TEXT_ONHOLD_STATUS":"En attente",
      "c3supportAzuremargin":"Marge d'azur",
      "CALENDAR_CLEAR_BUTTON":"Effacer",
      "CALENDAR_CLOSE_BUTTON":"Fermer",
      "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Veuillez saisir le motif de l'annulation de la commande",
      "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Un motif est requis pour annuler la commande programmée",
      "CANCEL_SCHEDULED_ORDER_SUCCESS":"La commande est annulée avec succès",
      "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"annuler la commande",
      "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Annuler la commande programmée",
      "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Un motif est requis pour annuler le produit programmé",
      "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Une raison est requise pour annuler la commande",
      "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"Le produit a été annulé avec succès de cette commande",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Renouvellement programmé annulé en raison d'un changement dans le statut de renouvellement automatique",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Renouvellement planifié annulé en raison d'une mise à jour de la quantité",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Renouvellement programmé annulé en raison du rejet de la demande de renouvellement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Annulation du renouvellement planifié car la propriété de planification est modifiée depuis le Centre des partenaires",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Renouvellement programmé annulé en raison d'un changement de statut d'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Renouvellement programmé annulé en raison d'une mise à jour de l'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Renouvellement programmé annulé en raison d'une mise à niveau de l'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"Échec de l'annulation du renouvellement programmé",
      "CANCEL_SCHEDULED_RENEWAL_REASON":"Raison",
      "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Veuillez fournir une raison",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Ce champ est obligatoire",
      "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Le renouvellement programmé a été annulé avec succès",
      "CANCELLED_REASON":"Motif annulé",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Impossible de supprimer une ancienne définition de conversion de devise",
      "CANNOT_INITIATE_PAYMENT":"Impossible de lancer un paiement à cause d'une transaction en attente",
      "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Ce produit a déjà été acheté par le client, il n'est pas possible de lier le produit.",
      "CanPriceLag":"CanPriceLag",
      "CanPriceLead":"CanPriceLead",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Demande d'une relation de revendeur",
      "CARD_CARD_DELETE_ADDON":"Supprimer",
      "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Centre d'échange",
      "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Données d'utilisation du produit",
      "CART_CARD_COMMENT_ACTION":"Commentaires",
      "CART_CARD_DELETE_PRODUCT":"Supprimer",
      "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans votre panier. Voulez-vous toujours continuer ?",
      "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Votre locataire n'est pas éligible pour acheter Azure Plan. Raisons possibles : soit vous disposez déjà d'un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans l'Espace partenaires et nécessite une mise à niveau. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "CART_CART_ITEM_Azure_PLAN_INELIGIBLE_WARNING":"Votre locataire n'est pas éligible pour acheter le plan Azure . Raisons possibles : soit vous disposez déjà d'un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans le Partner Center et nécessite une mise à niveau. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Voulez-vous vraiment modifier le domaine pour l'offre sélectionnée ?",
      "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Date de fin personnalisée enregistrée avec succès",
      "CART_DISABLE_PROMOTION":"Désactivez la promotion du produit.",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Une erreur s'est produite lors de la mise à jour du domaine pour l'offre sélectionnée. Veuillez contacter le soutien technique",
      "CART_GENERIC_ERROR_MESSAGE":"Une erreur s'est produite lors de la finalisation de la commande. Veuillez vérifier auprès du Partenaire avant de réessayer",
      "CART_ITEM_EFFECTIVE_PRICE":"Prix effectif",
      "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Commande pour Site/Département",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Domaine",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Panier non disponible à la caisse ou déjà traité !",
      "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"Vous ne pouvez pas planifier ce panier car il n'est pas disponible ou est déjà programmé",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Produit lié",
      "CART_PAGE_AUTORELEASE":"Libération automatique",
      "CART_PAGE_Azure_PLAN_DISCOUNT_INFORMATION":"{{discount}}% de réduction",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":"{{discount}}% de réduction",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Rabais",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Supprimer",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Appliquer",
      "CART_PAGE_DISCOUNT_INFORMATION":"(rabais de {{discount}} %)",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Types de consommation",
      "CART_PAGE_TEMPLATE_ADD_COMMENT":"Ajouter un commentaire",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong>Bonjour !</strong> Votre panier est vide. <a ng-click='vm.GotoCart()' class='alert-link'> Cliquez ici pour magasin. <span class='fa fa-cart-plus'></span> </a>",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>Bonjour !</span> Votre panier est vide.",
      "CART_PAGE_TITLE_PRICE":"Prix",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Date de fin personnalisée",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Sélectionnez un type de date de fin personnalisé",
      "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Sélectionnez",
      "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Sélectionnez",
      "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Version automatique mise à jour avec succès",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Une erreur s'est produite lors du passage à la caisse",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Commande passée avec succès. OrderId : {{orderId}}",
      "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande de conversion de l'offre d'essai {{productName}} en abonnement",
      "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Demande de conversion de l'offre d'essai de {{productName}} en abonnement envoyée avec succès",
      "CART_PRODUCT_DELETE_CONFIRMATION":"Voulez-vous vraiment supprimer cet article du panier ?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Une erreur s'est produite lors de la suppression de l'article du panier !",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"Suppression de l'élément demandé avec succès !",
      "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Une erreur s'est produite lors de la vérification de l'éligibilité à la promotion",
      "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"L'ID de promotion disponible est valable sur la promotion.",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Quantité mise à jour avec succès",
      "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Une erreur s'est produite lors de la planification de la commande",
      "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"La commande a été planifiée avec succès.<br/>OrderId est : {{orderId}}",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans vos produits achetés. Voulez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Ce produit a déjà été acheté il y a {{days}} jours. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Ce produit a déjà été acheté il y a {{hours}} heures. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Ce produit a déjà été acheté il y a {{minutes}} minutes. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Ce produit a déjà été commandé il y a {{days}} jours. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Ce produit a déjà été commandé il y a {{hours}} heures. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Ce produit a déjà été commandé il y a {{minutes}} minutes. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Cette offre d'essai est déjà achetée.",
      "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"Sur ce produit, la commande programmée est présente. Voulez-vous toujours continuer ?",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Mise à jour du locataire au regard de l'offre sélectionnée avec succès !",
      "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Une erreur s'est produite lors de la mise à jour du champ de paiement instantané dans le panier",
      "CATEGORY_DESC_ALL":"Tout",
      "CATEGORY_DESC_AZURE":"Azure",
      "CATEGORY_DESC_AZURE_PLAN":"Plan Azure",
      "CATEGORY_DESC_BUNDLES":"Forfaits",
      "CATEGORY_DESC_CUSTOM":"Personnalisé",
      "CATEGORY_DESC_CUSTOM_TRIAL":"Essai personnalisé",
      "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Offres des distributeurs",
      "CATEGORY_DESC_LICENSE_SUPPORTED":"Licence prise en charge",
      "CATEGORY_DESC_NCE":"NCE",
      "CATEGORY_DESC_NON_CSP_AZURE":"Azure non CSP",
      "CATEGORY_DESC_ONE_TIME":"Une fois",
      "CATEGORY_DESC_ONLINCE_SERVICES":"Services en ligne",
      "CATEGORY_DESC_ONLINE_SERVICES_NCE":"Services en ligneNCE",
      "CATEGORY_DESC_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "CATEGORY_DESC_RESERVED_INSTANCES":"RI",
      "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Abonnements logiciels",
      "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Licence prise en charge",
      "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Instance réservée",
      "CATEGORY_FILTER_DESC_NCE":"NCE",
      "CHAMP OBLIGATOIRE":"{{atribute}} est requis",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Êtes-vous sûr, voulez-vous désactiver ce coupon",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Êtes-vous sûr, voulez-vous activer ce coupon",
      "CHANGE_PLAN_CHANGE_LABEL_SOUNCE_PLANS":"Plan SouNCE",
      "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Forfait source",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Plan cible",
      "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":"Annuler l'attribution des plans après le passage à un nouveau forfait",
      "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Une erreur s'est produite lors du changement de plan.",
      "CHANGE_PLAN_HELP":"Aide",
      "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} n'est pas mappé avec le bon produit enfant {{secondProduct}}",
      "CHANGE_PLAN_INSTRUCTIONS_1":"Suivez ces instructions pour déplacer les produits du client d'un plan existant vers un plan nouvellement sélectionné.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Plan SouNCE </b>– Répertorie les plans associés aux produits achetés du client. Sélectionnez un ou plusieurs plans si vous souhaitez opérer sur les produits associés.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Plan cible</b> – Sélectionnez le nouveau plan tarifaire.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Désattribuer les plans après le passage à un nouveau plan</b>– Ceci est pratique car le système dissocierait le client d'un plan existant s'il ne reste plus de produits dans le plan souNCE. Aide à résoudre la confusion des utilisateurs clients dans la page de la boutique.",
      "CHANGE_PLAN_INSTRUCTIONS_2":"La page s'actualise pour répertorier les produits achetés à partir du plan souNCE sur la gauche et l'option de mappage vers un équivalent du plan cible, sur la droite.",
      "CHANGE_PLAN_INSTRUCTIONS_3":"Mappez les produits et soumettez la demande pour que le système exécute le changement.",
      "CHANGE_PLAN_INSTRUCTIONS_4":"Les journaux d'audit vous aident avec les détails de l'activité pour référence ultérieure.",
      "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Remarque : les modifications de prix dues à un changement de plan entreraient en vigueur immédiatement.</b>",
      "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Instructions pour passer au plan",
      "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}",
      "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"La quantité partagée doit correspondre à {{firstProduct}}",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOUNCE_PLANS":"Sélectionnez les plans souNCEs que vous souhaitez modifier",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Sélectionnez les plans sources que vous souhaitez modifier",
      "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Sélectionnez le plan cible",
      "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"Aucun produit correspondant dans le plan cible",
      "CHANGE_PLAN_STATUS_FAILED":"Échoué",
      "CHANGE_PLAN_STATUS_SUCCESS":"Réussite ",
      "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan modifié avec succès.",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Passer à un produit du plan cible",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"Produits achetés",
      "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Catégorie",
      "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":"Produit lié",
      "CHANGE_PLAN_TABLE_TEXT_OFFER":"Offre:",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":"Prix",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Fournisseur",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Quantité",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Valide",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOUNCEPLANS":"Les plans sélectionnés ont une configuration de devise différente.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Les plans sélectionnés ont une configuration de devise différente.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"Le produit du plan est en état de provisionnement.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Veuillez sélectionner le produit.",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour du statut du produit acheté : ",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Êtes-vous sûr, voulez-vous discontinuer cette offre",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Êtes-vous sûr, voulez-vous désactiver ce plan",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Êtes-vous sûr, voulez-vous activer ce plan",
      "Charge":"Charge",
      "charity":"Charité",
      "Charity":"Charité",
      "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Activer toutes les promotions",
      "CHOISIS UNE OPTION":"Choisis une option",
      "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Choisissez un cycle de facturation",
      "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Choisissez un type de facturation",
      "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Choisissez un type de consommation",
      "CHOOSE_AN_OPTION":"Sélectionner une option",
      "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Choisir le client",
      "CHOOSE_INVOICE_PDF_LANGUAGE":"Choisir la langue du PDF de la facture",
      "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"Le PDF de la facture sera disponible dans la langue sélectionnée",
      "CHOOSE_ONE_RG":"Sélectionner au moins un groupe de ressources",
      "CHOOSE_RESOURCE_GROUP":"Sélectionner une politique",
      "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"Voulez-vous vraiment effacer l'icône de ce produit ? Le produit obtiendrait l'icône par sa catégorie lorsqu'il est effacé ici.",
      "CLICK":"Cliquer",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Cliquer",
      "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Cloner la conversion de devises",
      "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Veuillez sélectionner une date",
      "CLONE_PLAN_FAILURE":"Impossible de cloner le plan",
      "CLONE_PLAN_SUCCESS":"Plan dupliqué avec succès",
      "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Pas maintenant",
      "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Pas maintenant",
      "COLLAPSE_ALL_PAYMENTS":"Effondrement des paiements",
      "COMMENT_AUTHOR":"Auteur",
      "COMMENTS_LATEST_COMMENT":"Dernier commentaire",
      "commercial":"Commercial",
      "Commercial":"Commercial",
      "COMMISION_REPORT_SEARCH":"Rechercher",
      "COMMISION_REPORTS":"Rapport de commission",
      "Commission Report":"Rapport de commission",
      "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Toutes périodes",
      "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez tous les clients",
      "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez le département",
      "COMMISSION_REPORT_HEADER_TEXT":"Rapport de commission",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Fermer",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Réinitialiser",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Rechercher",
      "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez un site",
      "COMMISSION_REPORTS":"Rapport de commission",
      "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Rapport de commission",
      "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Rapport sur les revenus",
      "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Date de fin de commission",
      "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Date de début des commissions",
      "COMMISSION_TABLE_HEADER_COMMISSION_PENCENT":"Commission(%)",
      "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Commission(%)",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Nom du domaine",
      "COMMISSION_TABLE_HEADER_ENTITY_ID":"ID d'entité",
      "COMMISSION_TABLE_HEADER_ORDERED_DATE":"Date de commande",
      "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"Planifier l'identifiant du produit",
      "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agent",
      "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Montant des commissions",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Client",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"ID de produit du fournisseur",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"ID de produit du fournisseur",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"ID d'abonnement au produit",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Nom de l'abonnement au produit",
      "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Nom du produit de l'achat",
      "COMMISSION_TABLE_HEADER_RECORD_ID":"ID d'enregistrement",
      "COMMISSION_TABLE_HEADER_SP_CODE":"Code SP",
      "COMMON_LOADING_MESSAGE":"Chargement ...",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Êtes-vous sûr de vouloir approuver cette facture ?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Êtes-vous sûr de vouloir finaliser cette facture ?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Vous avez des modifications non enregistrées. Cliquez sur OK pour quitter sans enregistrer ou sur Annuler pour rester sur la page",
      "CONFIRMATION_POPUP_MESSAGE":"Êtes-vous certain de vouloir activer cet abonnement ?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Êtes-vous certain de vouloir annuler la demande relative à cet abonnement ?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"Activer",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"Annulé",
      "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"En cliquant sur « Approuver », les articles de la ligne de devis seront achetés. <br/> <b>Remarque</b> : la limite de siège/transaction ne sera pas applicable.",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Voulez-vous supprimer cet ajustement ?",
      "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>En cliquant sur approuver, le statut du devis sera mis à jour sur approuvé.</p></li><li><p style='text-align:left;'>Le propriétaire du devis doit effectuer manuellement l'achat au nom du client.</p></li></ul>",
      "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"Voulez-vous mettre à jour le nom de l'abonnement ?",
      "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"Êtes-vous sûr de vouloir mettre à jour le numéro de bon de commande ?",
      "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"Voulez-vous vraiment mettre à jour le nom du produit ?",
      "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":"Voulez-vous mettre à jour le nom de l'abonnement s'il est partagé entre les entités enfants dans C3 ?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"Êtes-vous sûr de vouloir annuler ?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Voulez-vous continuer ?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Êtes-vous sûr de vouloir continuer avec la sélection actuelle ?",
      "CONSENT_CREATION_FAILED":"La création du consentement a échoué",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Activez cette option pour appliquer des frais de traitement aux paiements par carte de crédit.",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"Inclure les frais de traitement de carte de crédit ?",
      "CONSTANT_EMPTY_STRING":"--Sélectionner--",
      "CONSTANT_EVERY_DAY":"Tous les jours",
      "CONSTANT_EVERY_MONTH":"Chaque mois",
      "CONSTANT_EVERY_TWO_WEEKS":"Chaque deux semaines",
      "CONSTANT_EVERY_WEEK":"Chaque semaine",
      "CONSTANT_FULL_AUTOMATIC_MAPPING":"Mappage automatique",
      "CONSTANT_FULL_MANUAL_MAPPING":"Cartographie manuelle",
      "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Cartographie manuelle avec création de service",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Montant",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Produit",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Quantité",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Siège",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Sièges de clôture",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Différence",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Ouverture des sièges",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"Contrat",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"Quantité",
      "CONSUMPTION_TYPES_DESC_SERVICE":"Service",
      "CONSUMPTION_TYPES_DESC_USAGE":"Utilisation",
      "CONTACT_CANCEL":"Voulez-vous annuler?",
      "CONTACT_HEADER_TEXT":"Vous avez une question?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Nos contactes",
      "CONTACT_LABEL_TEXT_EMAIL":"Courriel",
      "CONTACT_LABEL_TEXT_MESSAGE":"Message",
      "CONTACT_LABEL_TEXT_NAME":"Nom",
      "CONTACT_PARAGRAPH_TEXT":"Contactez-nous en remplissant le formulaire suivant et nous vous répondrons sous peu.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Merci de nous avoir fait part de vos commentaires. Notre équipe vous contactera sous peu.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Votre commentaire a été soumis avec succès.",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Ajouter",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Annuler",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Modifier",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Sélectionnez un type de facturation",
      "CONTRACT_OFFER_BREADCRUMB":"Offre de contrat",
      "CONTRACT_OFFER_FEEDSOUNCE_TOOLTIP":"SouNCE sur laquelle le contrat serait créé",
      "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Source sur laquelle le contrat serait créé",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ce bloc ?",
      "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Plage d'utilisation sur laquelle le contrat s'appliquerait",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Voulez-vous vraiment désactiver cette offre de contrat ?",
      "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Service contractuel supprimé d'Autotask",
      "COOKIE_CONSENT_MESSAGE":"L'application utilise des témoins pour vous offrir une meilleure expérience. En accédant à l'application, vous acceptez notre utilisation de témoins.",
      "COOKIE_POLICY_BUTTON_TEXT":"POLITIQUE EN MATIÈRE DE TÉMOINS",
      "corporate":"Entreprise",
      "Corporate":"Entreprise",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Réinitialiser",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Rechercher",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Clients",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Plans",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Revendeurs",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Sélectionner des clients",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Sélectionner des plans",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Sélectionner des revendeurs",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filtrer",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Prix du fournisseur",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Quantité",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Coût pour le client",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Coût pour le partenaire",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Extension",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Forfait",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Lié",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"Non intégré",
      "coupon":"Coupon",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"Le coupon sera appliqué à tous les clients",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"Le coupon ne sera pas appliqué aux clients même s'ils sont étiquetés avec le plan sélectionné",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"Le coupon sélectionné est un coupon direct (sans plan).",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Les clients ne peuvent avoir qu'un seul coupon direct à la fois.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Veuillez noter que si des clients sélectionnés ont des attributions de coupons existantes, ils seront supprimés.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Coupon Direct",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"Le coupon sélectionné est un coupon de plan.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"Les clients associés à ce plan ne peuvent être attribués qu'avec ce coupon.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Coupon d'abonnement",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de Coupon sauvegardé avec succès",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Coupon",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Clients",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Actif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inactif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Actif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inactif",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Ajouter une nouvelle attribution de coupon",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Modifier",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Action",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Assigné à",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Applicable jusqu'au",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Statut",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"code promo",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Code promotionnel",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Description de coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"nom du coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Nom du coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Statut",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Est actif",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Veuillez sélectionner un coupon valide",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Le coupon sélectionné est disponible pour ce client.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Ajouter un coupon",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Retour aux coupons",
      "COUPON_CAPTION_TEXT_ADD":"Ajouter un coupon",
      "COUPON_CAPTION_TEXT_EDIT":"Modifier le coupon",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Sélectionner toutes les offres",
      "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Effondrement",
      "COUPON_DETAILS_TOOLTIP_EXPAND":"Agrandir",
      "COUPON_DISABLE_BUTTON":"Désactiver le coupon",
      "COUPON_DISCOUNT_TOOLTIP":"Remise peNCEntage à appliquer sur le coupon",
      "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Veuillez sélectionner une valeur de remise entre 1 et 100.",
      "COUPON_EDIT_BUTTON":"Modifier le coupon",
      "COUPON_ENABLE_BUTTON":"Activer le coupon",
      "COUPON_HEADER_ACTION":"Action",
      "COUPON_OWNERSHIPS_CATEGORY":"Catégorie",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Appliquer à tous les clients tagués",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"La sélection de cette case à cocher attribuera le coupon à tous les clients associés au plan sélectionné. Cependant, vous pouvez gérer les affectations dans l'écran des affectations de coupons.",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"Applicabilité",
      "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Promotion disponible",
      "COUPON_OWNERSHIPS_PLAN":"Plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Offres de plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Prix effectif",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Est sélectionné",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Nom de l'offre",
      "COUPON_OWNERSHIPS_PROVIDER":"Fournisseurs",
      "COUPON_OWNERSHIPS_SALETYPE":"Type de vente",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Applicable à",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Vous devez sélectionner au moins un produit du plan.",
      "COUPON_RECORDS_VALIDATION_MESSAGE":"Vous devez sélectionner au moins une {{entity}}.",
      "COUPON_SAVE_SUCCESS":"Coupon {{coupon}} sauvegardé avec succès",
      "COUPON_STATUS_FLAG_ACTIVE":"Actif",
      "COUPON_STATUS_FLAG_EXPIRED":"Expiré",
      "COUPON_STATUS_FLAG_INACTIVE":"Inactif",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Code promotionnel",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Nom du coupon",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Expire le",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Nom du plan",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Applicable aux nouveaux clients",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Applicable aux anciens clients",
      "COUPON_TABLE_HEADER_TEXT_code":"code",
      "COUPON_TABLE_HEADER_TEXT_CODE":"Code",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Créé le",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Rabais",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Expire le",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Est actif",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"Est un Pourcentage",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Est publique",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Remise maximale",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Rachats maximum",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Montant minimum",
      "COUPON_TABLE_HEADER_TEXT_NAME":"Nom",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Récurrences",
      "COUPON_TABLE_HEADER_TEXT_planname":"nom du plan",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Nom du plan",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Valide jusqu'à",
      "COUPON_UPDATED_SUCCESS":"Coupon {{coupon}} mis à jour avec succès",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"La date ValideJusqua doit être plus grande que la date ExpireLe ",
      "couponassignment":"CouponAssignment",
      "coupondetails":"Détails du coupon",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Ajouter une nouvelle attribution de coupon",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Assignations de coupons",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Modifier attribution de coupon",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Date limite pour appliquer le coupon au moment de l'achat",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Statut du coupon",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Coupons",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Date limite pour prendre le coupon en compte lors de la facturation après son application",
      "couponstatus":"Statut du coupon",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Le lien pour initier la procédure de consentement est copié dans votre presse-papiers",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Cliquer <b><a ngclipboard data-clipboard-text='{{consentURL}}' ng-click='confirmCopy()'></b> Copier le lien sur le presse-papiers</a> pour copier le lien qui capture le consentement du partenaire à autoriser l'accès à cette application au centre partenaire selon le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Ouvrez le lien dans une session de navigation privée et complétez la procédure de consentement à l'aide d'un compte d'administrateur global dans votre domaine (partenaire) et auquel le rôle d'agent admin lui est affecté dans le centre partenaire.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquer <b>Basculer vers le nouveau modèle de sécurité</b> (activé après le succès du test) pour permettre à l'application d'effacer vos anciens identifiants et basculer vers le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Copier le lien vers le presse-papiers< /b></a></b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":"pour accorder à cette application l'accès aux API de gestion Azure, ce lien capturera votre consentement en tant qu'administrateur client.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Après avoir copié le lien, ouvrez-le dans une nouvelle session de navigation privée. Ensuite, donnez votre consentement pour le rôle d’administrateur client. Cette étape est cruciale pour faire avancer le processus.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec les API de gestion Azure",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instructions pour passer au nouveau modèle de sécurité",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Instruction pour fournir le consentement de l'administrateur à l'API de gestion Azure",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Instruction pour fournir le consentement de l'administrateur client par l'utilisateur client",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Instructions",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Copier le lien vers le presse-papier</a> </b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" pour copier le lien qui capture le consentement de l'administrateur du client pour accorder à cette application l'accès aux API de gestion Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Une fois que vous avez copié le lien, partagez-le avec le client via son canal de communication préféré, tel que le courrier électronique. Ensuite, demandez leur approbation pour le rôle d’administrateur client.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec les API de gestion Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"OU Vous pouvez informer votre client de se connecter au portail C3 -> profil -> Microsoft Dans la section, fournissez le consentement nécessaire. Obtenir le consentement du client est crucial pour que nous puissions aller plus loin.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Voici une liste de rapports qui peuvent être préparés et exécutés à l'heure prévue.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Veuillez utiliser l'icône Destinataires pour ajouter les destinataires au rapport.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"Vous pouvez également vérifier le nombre de fois où le rapport a été exécuté dans le passé et qui l'a reçu.",
      "CPV_LOADING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Merci d'avoir fourni votre consentement. L'application peut être configurée pour accéder au centre partenaire suivant la nouvelle directive de sécurité.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions retrouvées sur Paramètres -> Centre partenaire dans <b>{{applicationName}}</b>.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Tentative d'accès au centre partenaire à l'aide des nouveaux paramètres de sécurité réussie.",
      "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"La tentative d'accès à l'API de gestion Microsoft Azure a réussi.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Numéro de carte ",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Mois",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"04-Avr",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"08-Aou",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12-Déc",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"02-Fev",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"01-Jan",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"07-Juil",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"06-Juin",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"03-Mar",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"05-Mai",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11-Nov",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Oct",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"09-Sep",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Nom sur la carte",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Année",
      "CREATE_BANNER_NOTIFICATIONS":"Créer une bannière de notification",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"Impossible de créer le coupon",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Coupon créé avec succès",
      "CREATE_CUSTOMER_EXCEPTION":"Une erreur s'est produite lors de la création du client",
      "CREATE_CUSTOMER_USER_ROLE":"Créer des utilisateurs clients",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Impossible de créer des utilisateurs clients",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Créer une facture",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Créer un client fournisseur sous un revendeur logique",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Autoriser le revendeur logique à créer un client fournisseur",
      "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Les offres d'utilisation ajoutées au plan revendeur seront de type de facturation <b>Markup</b>. Veuillez définir le balisage pour les offres d'utilisation une fois le plan créé avec succès.",
      "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Gestionnaire de compte créé avec succès",
      "CREATED_SUCCESSFULLY":"a été créé avec succès",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Il semble que les détails de l'ID MPN manquent pour le revendeur. Veuillez contacter le Partenaire pour mettre à jour les détails.",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"La création d'un nouveau Locataire de Fournisseur de Services n'est pas prise en charge !",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"Le client sera créé dans l'espace partenaire sous le compte partenaire",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Frais en pourcentage pour le traitement des cartes de crédit.",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Frais de traitement de carte de crédit %",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Veuillez entrer au moins un nom de taxe et un pourcentage pour les taxes cumulatives.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Règle de conversion de devise ajoutée avec succès",
      "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Cloner la devise",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Retour à la conversion des devises",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Ajouter",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Conversion de devise",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"conversion de devise soumise avec succès",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Modifier",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"Règle de conversion de devise supprimée avec succès",
      "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Mois de facturation en cours",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Tout",
      "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Dernier mois de facturation",
      "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Mois de facturation suivant",
      "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Sélectionnez une date",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Taux de conversion",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Client",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Devise source",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Devise cible",
      "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Période de temps",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Taux de conversion",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Client",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Devise source",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Statut",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Devise cible",
      "CURRENCY_CONVERSION_RATE_SUCCESS":"Saisissez un taux de conversion valide.",
      "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"La règle de conversion de devise a été créée avec succès.",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Règle de conversion de devise mise à jour avec succès",
      "CurrencyCode":"Code de devise",
      "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Icône actuelle",
      "CURRENT_PARTNER_PRODUCT_ICON":"Icône actuelle",
      "CURRENT_SUBSCRIPTION_DETAILS":"Détails de l'abonnement actuel",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Détails d'utilisation pas encore disponibles, veuillez réessayer plus tard.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Quantité",
      "Custom Notifications":"Notifications personnalisées",
      "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Si vous choisissez de planifier une commande pour une date future et que vous avez spécifié une date de fin personnalisée, sachez que la date de fin personnalisée sera automatiquement réinitialisée à sa valeur par défaut après avoir sélectionné votre commande planifiée pour le futur.",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOM_ICON_HELP_TEXT":"Taille recommandée : 150 x 175. Seuls les fichiers de type jpg, svg, png, gif, bmp sont supportés",
      "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"Le nom de l'élément de ligne doit comporter moins de 150 caractères.",
      "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Ajouter des entités balisées",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Le corps du texte",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"Identifiant de notification",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Modèle",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Titre",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Le Lorem Ipsum est le texte factice standard de l'industrie depuis les années 1500, lorsqu'un imprimeur inconnu a pris une galère de caractères et l'a brouillé pour en faire un livre de spécimens de caractères.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Offrir des remises pour votre boutique en ligne peut être une arme puissante pour fidéliser.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Stimulez le marketing &amp; confiance dans les produits de vente.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Offrir des rabais pour une meilleure vente en ligne dans un magasin peut fidéliser la conduite.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Commencez par une conception de site moderne et personnalisez-la avec le contenu et les fonctionnalités de votre marque. Tous les blogs Premium incluent un CSS personnalisé.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Créer une notification personnalisée",
      "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Créer une notification personnalisée",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Annuler",
      "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Fermer les filtres",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Nom du plan",
      "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Entrez la description",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Sélectionner des plans",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Choisissez la date d'entrée en vigueur",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Choisir en vigueur à ce jour",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Date de fin",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Attribuer une licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Modifier le statut du produit client",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Gérer la propriété du produit",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Gérer la licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Intégrer un client",
      "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Acheter des produits",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Diminution de la quantité",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Augmentation de la quantité",
      "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Supprimer la licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Mettre à jour la licence",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Une erreur s'est produite lors d'événements de notification personnalisés",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Actions",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Description",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Entités marquées",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Titre",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Description",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Entité",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Événement",
      "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Modèle",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Titre",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Notification personnalisée",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Réinitialiser",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Soumettre",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notification personnalisée enregistrée avec succès",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Rechercher",
      "CUSTOM_NOTIFICATION_SELECT":"Sélectionner",
      "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Sélectionner des produits",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Types de consommation",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Fournisseurs",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Date de début",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Entités marquées",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Éléments d'entité balisés",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Actions",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Éléments",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Entité",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Événement",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Afficher les entités marquées",
      "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Choisir le modèle",
      "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Cela dépasse la longueur maximale de 30",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Commencer ici",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Rejoignez SAP maintenant pour bénéficier de 35 % de réduction",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Confiance des utilisateurs",
      "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Sélectionnez le titre",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Mettre à jour",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notification personnalisée mise à jour avec succès",
      "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"Notification de l'interface utilisateur",
      "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"Est-ce qu'il s'agit d'une offre d'essai ?",
      "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"Est-il auto-renouvelable",
      "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Quantité par défaut",
      "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Produits en offre d'essai",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Type de consommation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Catégorie",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Offres partenaires",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Nouvelle offre de contrat",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Ajouter une offre personnalisée",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Retour aux offres personnalisées",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Applicabilité",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Ajouter",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Offres de contrat",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Offres partenaires",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Modifier",
      "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"DURÉE DE L'ESSAI",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Sélectionner les valeurs par défaut telles que 'Charger les périodes' comme 'Default', 'Règle de facturation pour l'achat de licences’ et ‘Règle de facturation pour la réduction des licences’ comme ‘Prorate’",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Si vous voulez ajouter l'offre personnalisée à l'offre composite, vous devez suivre les instructions suivantes ",
      "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Quantité d'approbation",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Charger les périodes",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Comment voulez-vous que les périodes de facturation pour les abonnements soient? ",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "CUSTOM_OFFERS_LABEL_TEXT_Description":"Description",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOM_OFFERS_LABEL_TEXT_DISPLAY_NAME":"Nom d'affichage",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiatement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Instructions",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"L'offre est-elle une extension ?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Nom",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nombre de jours pour l'annulation gratuite",
      "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"ID de référence de l'offre",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les changements de quantité sont calculés au prorata ou à pleine charge pour la nouvelle quantité",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les changements de quantité doivent être calculés au prorata ou à pleine charge",
      "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icône",
      "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Propriétés",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Prix de vente",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Valide",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Ce que vous désirez appliquer comme période de facturation pour les abonnements",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Quel type de consommation voulez-vous que les abonnements aient?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Nombre de jours maximum permis sans frais d'annulation.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité doivent être calculées au prorata ou à frais complets",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Bloc",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validité de l'offre.",
      "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Sélectionnez une image",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"Offre personnalisée {{customoffer}} sauvegardée avec succès",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Inclure les offres supprimées",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Offres d'essai",
      "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Sélectionnez le cycle de facturation",
      "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Sélectionnez la durée du mandat",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Coût d'acquisition",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Détails de l'offre",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Aller à l'écran des étiquettes de produit",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Afficher",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(Supprimé)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Ceci est le prix auquel vous avez obtenu ce composant. Vous pouvez spécifier le prix de vente pour cette offre lorsque vous l'incluez dans un plan.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"C'est le prix auquel vous vendez ce composant.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"L'offre partenaire {{customoffer}} mise à jour avec succès",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Les dates de début et de fin des frais seront les dates de début et de fin d'un mois.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Les dates de début et de fin des frais seront alignées avec les dates de début et de fin de facturation MS.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Les dates de début et de fin des frais seront alignées avec les dates de début et de fin de facturation partenaire.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Aligner avec le mois du calendrier",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"Aligner avec la facturation MS",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"Aligner avec la facturation du partenaire",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Aligner avec la facturation du fournisseur",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Par défaut (à partir de la date d'achat)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Définir un nouveau mot de passe",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Êtes-vous sûr de vouloir réinitialiser le mot de passe ?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Un nouveau mot de passe sera généré et le mot de passe actuel de l'utilisateur ne fonctionnera plus.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Entrer un nouveau mot de passe",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Montrer le mot de passe ",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Mot de passe généré automatiquement",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Mot de passe personnalisé",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Minimum 8 caractères. Contient au moins une majuscule, une minuscule, un caractère spécial, et un caractère numérique",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Veuillez choisir un mot de passe d'au moins 8 caractères.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Le mot de passe personnalisé ne doit pas contenir le prénom de l'utilisateur.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Politique activée avec succès",
      "CUSTOM_POLICY_ADD_TITLE":"Ajouter une politique personnalisée ",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Règles affectées",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Politique affectée avec succès",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Affecter une politique",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"PolicyId Azure ",
      "CUSTOM_POLICY_CUSTOMER_NAME":"Nom du client",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Politique désactivée avec succès",
      "CUSTOM_POLICY_DESCRIPTION":"Description",
      "CUSTOM_POLICY_INSTRUCTIONS":"Pour créer une politique personnalisée, suivez les étapes ci-dessous",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Fournissez un nom et une description pour la politique personnalisée ",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Fournir une définition appropriée",
      "CUSTOM_POLICY_LABEL_SCOPE":"Portée",
      "CUSTOM_POLICY_NAME":"Nom",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Affectation de la politique",
      "CUSTOM_POLICY_POLICYDEFINITION":"Définition",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Nom du groupe de ressources",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Politique révoquée avec succès",
      "CUSTOM_POLICY_SAVE_SUCCESS":"La politique {{policy}} a été enregistrée avec succès",
      "CUSTOM_POLICY_SUBSCRIPTION":"Nom de l'abonnement",
      "CUSTOM_POLICY_TITLE":"Politiques personnalisées",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Pour affecter la politique au niveau de l'abonnement, sélectionnez tous les groupes de ressources.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rôle activé avec succès",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"RoleId Azure",
      "CUSTOM_ROLE_CUSTOMER_NAME":"Nom du client",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rôle désactivé avec succès",
      "CUSTOM_ROLE_DESCRIPTION":"Description",
      "CUSTOM_ROLE_INSTRUCTIONS":"Pour créer un rôle personnalisé, suivez les étapes ci-dessous",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Fournissez un nom et une description pour le rôle personnalisé",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Entrez uniquement les champs dans les sections « Action » et « Non action » pour la définition de rôle.",
      "CUSTOM_ROLE_NAME":"Nom",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Nom du groupe de ressources",
      "CUSTOM_ROLE_ROLEDEFINITION":"Définition de rôle",
      "CUSTOM_ROLE_SAVE_SUCCESS":"Le rôle {{rôle}} a été enregistré avec succès",
      "CUSTOM_ROLE_SUBSCRIPTION":"Nom de l'abonnement",
      "Customer":"Client",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Est imposable",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Mettre à jour le panier",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Catégorie",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Prix par \"Seed\"",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Extensions",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"J'autorise / Nous autorisons Ezidebit Pty Ltd ACN 096 902 813 (Identifiant d'utilisateur numéro 165969, 303909, 301203, 234040, 234072, 428198) à débiter mon / notre compte à l'institution financière identifiée ci-dessus à travers le Bulk Electronic Clearing System (BECS), conformément à cette demande de prélèvement automatique et conformément au contrat de service Ezidebit DDR. <br> J'autorise / Nous autorisons ces paiements à être débités à des intervalles et selon les montants indiqués par BeCloudSmart conformément aux conditions générales du contrat BeCloudSmart et aux accords ultérieurs.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Accord",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Contrat Ezidebit",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Contrat de revendeur Microsoft Cloud",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Conditions d'utilisation du partenaire",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Lire l'accord Ezidebit",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Veuillez lire le",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Paiement enregistré avec succès.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Le processus d'intégration des détails de facturation du client est en cours",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Procéder à l'intégration",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Détails de facturation des clients intégrés avec succès.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Le client existe déjà dans le système de facturation",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Correspondance trouvée pour l'identifiant Id : <strong> {{billingProviderReferenceID}} </strong> dans le système de facturation.<br/><span> L'adresse de facturation dans le fichier est <strong> {{formattedAddress}} </strong>.</span> <br/>Veuillez personnifier l'identité et utiliser l'option <strong>Profile</strong> pour modifier ce détail une fois que les détails de facturation du client ont été intégrés avec succès.",
      "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Chiffre d'affaires mensuel...",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Erreur",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"InProgress",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Réussite ",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Lire plus",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Rechargement du consentement du client",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Rapport de grille de rechargement",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Synchronisation des profils client et fournisseurs",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Rechargement initialisé",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Postes de facture",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Lignes de facture...",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Éléments de ligne de facture avec commissions",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Éléments de ligne de facture avec commissions...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Sommaire de facture...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Rapport de profitabilité",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Rapport de rentabilité par produit",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Rapport de rentabilité par produit...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Rapport de rentabilité par revendeur",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Rapport de rentabilité par locataire",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Rapport de rentabilité par locataire",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Rapport de profitabilité...",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Postes de taxe",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Postes de taxe...",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Résumé fiscal",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Résumé fiscal...",
      "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Rapport de consentement",
      "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Télécharger le rapport client",
      "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Exporter le rapport de rapprochement",
      "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Exporter la licence utilisateur",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Recharger le consentement du client",
      "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Synchroniser les profils client et fournisseur",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Recharger le rapport de rapprochement",
      "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Rapport sur les termes et conditions",
      "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Rapport sur les limites du montant des transactions",
      "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Les nouveaux achats sont désactivés via la plateforme. Veuillez contacter votre gestionnaire de compte pour passer une commande.",
      "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Contactez votre gestionnaire de compte pour acheter un nouveau produit",
      "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Les nouveaux achats et les modifications du nombre de licences sont actuellement suspendus. Veuillez contacter votre responsable de compte pour plus d'informations.",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Supprimer",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Panier client",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Poursuivre votre magasinage",
      "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"Je suis prêt à payer",
      "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Payer maintenant",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Passer la commande",
      "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Vérifiez la date de fin personnalisée sur les éléments de campagne du panier avant de procéder à l'achat.",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Aligner la date de fin avec le mois civil",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Défaut",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Date de fin des abonnements existants",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Date de fin des abonnements existants",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Valide",
      "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Promotion appliquée",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Montant à payer",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Devise : ",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Nom de la taxe :",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Coût total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Nombre total : ",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Rabais total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Taxe total:",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Résumé de la commande",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Il y a déjà une offre de contrat dans le panier. Veuillez supprimer l'offre de contrat existante pour en ajouter une nouvelle.",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Vous avez déjà une offre de contrat achetée. Veuillez supprimer l'offre de contrat existante pour en acheter une nouvelle.",
      "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Une commande pour ce produit a déjà été envoyée au fournisseur. Veuillez contacter votre gestionnaire de compte pour plus d'informations",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Cet article nécessite l'approbation du Partenaire pour être exécuté, une fois la commande passée. Le nombre de sièges achetés dépasse la limite autorisée de {{SeatLimit}}.",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Cet article nécessite l'approbation du Partenaire pour l'exécution une fois la commande passée.",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Sélectionnez un site/un département",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_Azure_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure. Raisons possibles - vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure. Raisons possibles - vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure Plan. Raisons possibles : soit vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans l'Espace partenaires et nécessite une mise à niveau, soit vous avez plusieurs éléments de ligne de panier de plan Azure sous le même locataire.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_Azure_PLAN_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter le plan Azure . Raisons possibles : soit vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans le Partner Center et nécessite une mise à niveau, soit vous avez plusieurs éléments de ligne de panier de plan Azure sous le même locataire.",
      "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Cette commande nécessite l'approbation du Partenaire pour exécution, après la commande. Le montant de la transaction dépasse la limite autorisée de <b> {{TransactionAmountLimit}}</b>.",
      "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Afficher la taxe dans le panier du client",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Les numéros de facture commenceront par ce texte",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texte du numéro de facture",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permet à l'utilisateur de visualiser les cotisations impayées jusqu'à la facture en cours",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Afficher les cotisations impayées sur la facture",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"Êtes-vous sûr de vouloir {{Consent}} le renouvellement du consentement pour {{ProductName}} ?",
      "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Votre consentement est accepté avec succès",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Ajouter",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Mettre à jour",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Consentement du client",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"URL de contrat",
      "CUSTOMER_CONSENT_HELP_TEXT":"Veuillez préciser les détails de la personne de l'entreprise client qui a accepté le <a ng-click='vm.CustomerConsentClick()'>contrat client Microsoft.</a>",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Prénom",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Nom de famille",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Cliquer <a id='customerConsentLink' ng-click='vm.MicrosoftCloudAgreementClicked()'> ici </a>pour lire le Contrat de client Microsoft",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Contrat client Microsoft",
      "CUSTOMER_CONSENT_REJECT_POPUP":"Êtes-vous sûr de vouloir rejeter le<br>{{ProductName}} ?",
      "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Votre consentement a été rejeté avec succès",
      "CUSTOMER_CONSENT_SAVED_MESSAGE":"Votre consentement est enregistré avec succès",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Consentement au renouvellement de l'offre du partenaire",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Échec du consentement au renouvellement de l'offre du partenaire",
      "CUSTOMER_CONSENT_TEXT_DATE":"Date",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"Courriel",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Heure",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Veuillez accéder au menu du profil et mettre à jour le profil de paiement",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Veuillez remplir le profil de paiement",
      "CUSTOMER_DASHBOARD_Azure_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Azure",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Azure",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Portail Azure",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Nombre de produits achetés jusqu'à présent",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Produits",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Nombre d'utilisateurs ayant accès à vos informations",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Utilisateurs",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Alerte pour une facture en attente",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Facture",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Office 365",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Portail Office",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Lien rapide vers les rapports d'utilisation d'Office 365",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Rapports d'utilisation d'Office 365",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 des SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Nombre de produits par SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Comptes par SKU",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Gamme de filtres",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 mois",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 mois",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 an",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sièges achetés",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Sièges du client",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Sièges client",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Journal d'acceptation des conditions générales",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Conditions générales",
      "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Sièges",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Ligne de facture supprimé avec succès",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Ajouter un département",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Département supprimé avec succès",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Modifier le département",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Départements",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Département enregistré avec succès",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Sites affectés",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Site",
      "CUSTOMER_EXPORTINVOICE_WARNING":"La facture pour {{customername}} n'est pas encore générée. Veuillez revérifier après un petit moment.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Rechargement du consentement des fournisseurs clients",
      "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Synchronisation des profils client et fournisseurs",
      "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Texte généré par la facture client",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Retour aux factures",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Ajouter lineItem",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Annuler",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Sauvegarder la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Voulez-vous vraiment annuler vos modifications ?",
      "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"La date de début de facturation des éléments de ligne ne doit pas être supérieure à la date de fin de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"Voulez-vous vraiment supprimer l'élément de campagne ?",
      "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"La date d'échéance de la facture doit être supérieure à la date de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Articles de ligne",
      "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Les taxes seront appliquées après l'enregistrement de la facture.",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Date de la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Date d'échéance",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Sous-total",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adresse",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"Pays",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Choisissez le pays",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"État",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Veuillez fournir au moins un élément de ligne pour enregistrer la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Date de fin",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Date de début",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"Est taxable",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Nom de l'article",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"Montant de la ligne",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Taxe de vente",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Crédit",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Charge Additionnelle ",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Montant d'ajustement",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Détail d’ajustement",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Nom du fournisseur",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Retour à la facture",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Ajouter",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Ajustement",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Modifier",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Factures",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Créer une facture",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Factures",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":"Balise client  ",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Valeur",
      "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Le rapport de licence client est envoyé au courrier respectif",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filtres",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Gérer le produit",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Retour au plan Azure",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Nom du groupe",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Seuils",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Ajouter un abonnement Azure",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Aucun droit trouvé",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Le propriétaire n'a pas encore été affecté",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Propriétaire",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Le contenu des rôles et des politiques va ici....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Facturation",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Commentaires",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Estimer",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Groupes",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Info",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Gérer le propriétaire",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Entités opérationnelles",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Paiements",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Rôles et politiques",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Gérer le renouvellement",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Utilisation",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Abonnement",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Sélectionnez le code du marché",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Télécharger un exemple de fichier .csv",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Charger des données de compte pour plusieurs utilisateurs en format .csv. Télécharger un exemple de fichier .csv",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"source pour référence.",
      "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Rapport de chiffre d'affaires",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Veuillez entrer le nouveau nom",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Modifier le nom du client",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"Nom du client modifié avec succès",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Mise à jour du nom du client échouée",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Mise à jour du nom du client réussie",
      "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Le client n'est affecté à aucun gestionnaire de compte",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Vous n'êtes lié à aucun domaine Microsoft.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"L'achat de produits du fournisseur peut échouer en raison de l'absence de domaines liés de fournisseur",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Affectation",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"Créé",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"Supprimé",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Erreur",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Intégré",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"Rechercher dans la facturation !",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Veuillez suivre ces étapes pour obtenir l'identifiant du système de facturation d'un client",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Se connecter su système de facturation",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Aller aux clients",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Cliquez sur la fiche du client pour l'ouvrir",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Récupérer le string qui vient après recordkey",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Récupérer la chaîne qui se trouve après « recordkey= » dans l'URL",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"par exemple :",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"valeur-à-copier",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Se connecter à Authorize.Net",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Prendre l'ID du profile du gestionnaire d'information client",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Chemin de Gestionnaire de l'information client : Accueil->Gestionnaire de l'information client",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"Récupérer la chaîne à partir de l'URL",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Identifiant du système de facturation",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Intégration massive de clients",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Inscription de client ",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Recueillir les détails client",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Terminer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"ici",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Rechercher!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Procéder à l'inscription de ce client",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Supprimer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Ignorer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Essayez un autre",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Intégration massive de clients",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Inscription de client ",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"licences mappées",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Succès de l'intégration",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Type de paiement",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Le client n'est pas encore dans le système. Cliquer sur le bouton « Procéder à l’intégration de ce client » pour poursuivre",
      "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"Veuillez fournir une adresse email valide",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"Rechercher dans la facturation !",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"Identifiant EziDebit",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Veuillez suivre ces étapes pour obtenir l'identifiant EziDebit d'un client",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Se connecter à EziDebit",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Aller aux payeurs",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Cliquez sur la fiche du payeur pour l'ouvrir",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Récupérer le string qui vient après contractId",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"Récupérer la chaîne qui se trouve après « contractId= » dans l'URL",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"par exemple :",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"valeur-à-copier",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Clients disponibles à l'ajout à partir du centre partenaire",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"a été créé avec succès",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"Intégré avec succès",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Étape suivante ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Détails de plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Code de devise client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Identifiant client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Le produit est-il partageable",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Ligne 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Ligne 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Identifiant Microsoft ",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Sélectionnez le domaine Microsoft.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Mois",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Prochaine date de facturation",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"REMARQUE:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Ne mettez à jour aucun contenu dans le fichier autre que les détails utilisateur tel qu'indiqué dans l'exemple ci-dessus.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"optionnel:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Si vous avez les détails de licences qui sont déjà attribuées aux utilisateurs dans les abonnements,  vous pouvez nous les fournir en suivant les étapes suivantes :",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Remplissez les informations dans le format disponible en téléchargement à partir de",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Cliquez sur utilisateurs et accordez l'accès à ce portail aux utilisateurs du domaine client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Représenter et valider les détails de l'abonnement",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Veuillez corriger les erreurs en rechargeant un fichier mis à jour avec le détail de la licence afin de compléter le processus d'inscription",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Il suffit de fournir les adresses email de l'utilisateur entre crochets ([]) en suivant l'exemple:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Charger le fichier et cliquez",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Vous pouvez ne pas fournir les détails de la licence en cliquant sur IGNORER pour terminer le processus d'intégration.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Cliquez sur Terminé pour accéder à la liste des clients",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Localisez le client qui a vient d'être intégré",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Un bouton d'action <i class=\"fa fa-star red\"></i> va apparaitre en cas d'erreurs durant le traitement du détail de la licence que vous avez chargée",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Cliquez sur le bouton <i class=\"fa fa-star red\"></i> pour voir les erreurs",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Veuillez corriger les erreurs et essayer de charger à nouveau.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Catégorie de fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"État",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Domaines",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Charger le fichier",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Sélectionner",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Année",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"Supprimé",
      "CUSTOMER_ONBOARDING_LBL_DISABLED":"Désactivé",
      "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Expiré",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendu",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"Aucun plan n'est attribué au client. Veuillez créer un nouveau plan et l'attribuer au client, puis corriger l'inadéquation.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Pour les produits d'instance réservée, nous considérons le prix de vente en pourcentage. La valeur par défaut est donc un pourcentage de 0 %. Si vous souhaitez fournir une autre valeur, veuillez modifier le prix de vente.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Nom du plan",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Code de devise",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Prix de vente (en pourcentage)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente (en pourcentage)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Cette valeur sera appliquée au prix de facturation Microsoft pour générer la facture en conséquence.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Veuillez saisir une valeur jusqu'à deux décimales.",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Fournisseur",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Procéder à l'inscription de ce client",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Non",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Oui",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Rechercher des abonnements client et carte",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"licences mappées",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Terminé",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Terminé",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"S'identifier",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Actions",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Courriel de facturation",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Quantité de système de facturation",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Identifiant Microsoft",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Domaine",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Prix effectif",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Majoration",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_OFFERS":"Offres correspondantes ",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Correspondance de produits",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Quantité de produits correspondants",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_OFFER_NAME":"Nom d'offre ",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Pourcentage",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Prix",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Nom de produit du fournisseur",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Quantité de fournisseurs",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Aucun abonnement trouvé.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"PERSONNALISÉ",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Vous pouvez changer de mode de paiement à travers les options du profile. Les factures sont envoyées par email par Partner.",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Abonnements d'utilisation chargés avec succès.",
      "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Chargement....",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Désactiver",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Modifier",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Activer",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Gérer",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adresses",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Type d'entité",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Rôle",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Ajouter un nouvel utilisateur",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Adresse de facturation",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"Modifier l'utilisateur",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Adresse d'expédition",
      "CUSTOMER_OPTION_ALL_PERIODS":"Toutes les périodes",
      "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Termes et conditions.",
      "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez cocher la case des termes et conditions NCE pour commander des produits Microsoft NCE.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez cocher la case des termes et conditions pour commander des produits Microsoft.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-Azure-experience-in-csp/\">Conditions générales NCE.</a>",
      "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"Le montant à payer doit être la somme du produit et de la taxe totale",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Réessayer le paiement",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Afficher la facture",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Voir les produits",
      "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Retour aux commandes",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Nouvel achat",
      "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Mise à jour de la quantité",
      "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Changement de statut",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Commandes client",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Afficher les détails",
      "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Statut",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Commandes client",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"En attente",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Actions",
      "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Opération",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Nom du département",
      "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Opération",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Identifiant de commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"À ce jour",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"À partir de la date",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"Fonctionnement de la commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"Statut de la commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Commandé par",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Commandé le",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"Commandes de",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Commandes à",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Nom du site",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Statut",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Dernière actualisation",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Lire plus",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Retour aux paiements client",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Factures en attente pour",
      "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Sélectionnez un identifiant unique",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Code de réduction actuel",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong> Erreur! </ strong> Le client ne peut pas être déplacé vers <strong> {{targetPlanName}} </ strong> à partir de <strong> {{PlanName}} </ strong> car le plan cible n'a pas d'offres qui correspondent aux abonnements actuellement actifs.",
      "CUSTOMER_PLAN_CHANGE_INFO":"La modification de plan n'est pas encore prise en charge s'il existe au moins un abonnement actif dans une offre composite dans le plan actuel.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Plan actuel",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Abonnements clients",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Prix effectif",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Correspondance trouvée",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Nom de la cible",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Prix cible",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Ce coupon a déjà été assigné au client.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Réussite! </ strong> Le client peut être déplacé vers <strong> {{targetPlanName}} </ strong> à partir de <strong> {{PlanName}} </ strong>. Cliquez sur <strong> Envoyer </ strong> pour continuer.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Plan cible",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Utilisez le code promotionnel actuel",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(Supprimé)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Extensions",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Modifier",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Réactiver l'abonnement",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Réactiver l'abonnement avec des extension s",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Suspendre l'abonnement",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Forfait",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Ajouter plus de bons",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Soumettre",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Continuer l'abonnement</b> <br/> Êtes-vous sûr de vouloir continuer l'abonnement <b>{{productName}></b> après l'essai ?",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" L'offre d'essai {{productName}} sera transformée en abonnement une fois la date de fin effective du fournisseur atteinte.",
      "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"Êtes-vous sûr de vouloir changer le cycle de facturation de {{billingCycle}} à {{c3billingCycle}} ?<br> Après la génération de la facture, le cycle de facturation ne peut pas revenir en arrière.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Annuler l'abonnement</b> <br/> Êtes-vous sûr de vouloir annuler votre abonnement d'essai <b>{{productName}></b> ?",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Une erreur s'est produite lors de l'annulation de l'abonnement {{productName}}.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Offre d'essai annulée pour {{productName}} !",
      "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"off",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorer",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Détails de l'erreur",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Période d'essai",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Veuillez saisir des valeurs séparées par des virgules.",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Instructions",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Perpétuel",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"Logiciel perpétuel",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Abonnements logiciels",
      "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Voulez-vous vraiment modifier le nom du produit ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Voulez-vous vraiment modifier le cycle de facturation de ce produit pour {{targetBillingCycle}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"Voulez-vous vraiment changer l'offre de ce produit en {{targetOffer}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir modifier la portée de ce produit en {{targetScope}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir modifier la durée d'utilisation de ce produit en {{targetTermDuration}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir réactiver {{productName}} {{withAddons ? 'avec les extensions' : ''}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Voulez-vous vraiment suspendre {{productName}} ?",
      "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Abonnement parent manquant pour {{Name}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Modification de nom effectuée avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"J'ai changé le numéro de bon de commande avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Modification du cycle de facturation de {{productName}} en {{targetBillingCycle}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Quantité de produit invalide",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"impossible de mettre à jour la quantité à zéro pour les produits déjà achetés",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"La demande de mise à jour de l'abonnement a été soumise avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Demande de réactivation de {{productName}} {{withAddons ? 'with addons' : ''}} a été soumis avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"Réactivation réussie de {{productName}} {{withAddons ? 'avec les extensions' : ''}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Propriété libérée avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"La demande de suspension du produit, {{productName}} a été soumise avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{ProductName}} suspendu avec succès",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Renouvellement automatique",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"Se renouvelle le",
      "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Annulation autorisée jusqu'au",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"La modification du cycle de facturation mettra à jour tous les abonnements faisant partie de la \"Commande\". Cela créera un nouveau terme annuel pour tous les abonnements au prix actuel basé sur la liste de prix. Les abonnements ci-dessous seront mis à jour vers une facturation \"Annuelle\". Pour terminer cette demande, veuillez cliquer sur \"OK\" puis sur Soumettre.",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Modifier le cycle de facturation",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Annuler l'abonnement</b> </br> <br/> <b> Remarque : </b> Votre fenêtre d'annulation de 7 jours est fermée. Nous ne sommes pas en mesure d'annuler votre abonnement.</br> Si vous souhaitez annuler cet abonnement lors du prochain cycle de facturation, mettez à jour le statut de renouvellement automatique sur faux.",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Annuler l'abonnement",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Définir le Renouvellement Automatique</b> </br> <br/> <b> Remarque : </b> nous ne pouvons pas modifier l'état du renouvellement automatique.",
      "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Expire le",
      "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Gérer la fréquence de facturation",
      "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Suspendre l'accès du client au service",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"La reprise d'un abonnement suspendu restaure l'accès du client au service. Cela n'aura aucun impact sur votre facture. Vous continuerez à être facturé normalement. Si le renouvellement automatique était activé et que des modifications étaient programmées avant de suspendre l'accès, vous pouvez les réactiver.",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Rétablir l'accès du client au service",
      "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Soumettre les changements",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Annuler l'abonnement",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Annuler l'abonnement</b> <br/> Êtes-vous sûr de vouloir annuler {{productName}} ? <p style='text-align:left;' > Pour recevoir un remboursement, vous devez annuler les abonnements dans les 7 jours calendaires suivant l'achat ou le renouvellement. <br/> <b>Remarque :</b> Le client perdra l'accès à cet abonnement immédiatement après l'annulation et l'accès ne pourra pas être restauré après l'annulation. Si vous annulez l'abonnement dans les 7 jours calendaires, vous serez remboursé pour toute période non utilisée. Un crédit apparaîtra sur votre prochaine facture mensuelle. <a target='_blank' href=\"{{cancelNewCommeNCESubscriptionGuidLineURL}}\"> En savoir plus.</a> </p>",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Annuler avec succès !",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Définir le Renouvellement Automatique</b> <br/> Êtes-vous sûr de vouloir {{autoRenewStatus}} statut de renouvellement automatique pour {{productName}} ?",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Expire",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Statut de renouvellement automatique mis à jour avec succès pour {{productName}}",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"Mis à jour avec succés !",
      "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Voulez-vous vraiment suspendre {{productName}} ? <br/> <b> Remarque : </b> Bien que la suspension de l'accès du client au service bloque immédiatement la capacité du client à utiliser les services, cela n'a aucune incidence sur votre facture. Vous continuerez à être facturé normalement. La pause désactivera et désactivera le renouvellement automatique et supprimera toutes les modifications planifiées.",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Activité",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Statut d'activité",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Date",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Quantité d'origine",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Mise à niveau de {{sourceProductName}} vers {{targetProductName}}",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Quantité cible",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Transition",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Produits clients",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Affecter",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Retour aux détails de base",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Statut",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"La quantité ne peut pas être supérieure à",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Cycle de facturation personnalisé",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Quantité (disponible)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Quantité pour",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Abonnement lié",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Affecter",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Quantité (disponible)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"La quantité ne peut pas être supérieure à",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Quantité pour",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Acheter un abonnement</b> <br/> Êtes-vous sûr de vouloir convertir votre offre d'essai {{productName}} en abonnement ?",
      "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre à jour la quantité de <b>{{oldQuantity}}</b> à <b>{{newQuantity}}</b> pour le produit actuel ?",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"Est-il auto-renouvelable",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Expire le",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"Identifiant d'abonnement Azure",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"Identifiant de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"Identifiant de la commande de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Région de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Portée",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"Se renouvelle le",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Cet article nécessite l'approbation du Partenaire pour être exécuté, une fois la commande passée. Le nombre de postes achetés dépasse la limite autorisée de <b> {{SeatLimit}}</b> pour <b>{{productName}}</b>.",
      "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Filtrer les entités",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Séquence de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Séquence de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Extension ",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Lié",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"département",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":"Pour",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Voulez-vous vraiment annuler vos modifications ?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Voulez-vous vraiment mettre à jour la séquence du produit ?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Séquence de produit client mise à jour",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"Des erreurs sont survenues lors de la dernière demande de mise à jour En savoir plus...",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Actif",
      "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Annulé",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Annuler l'essai",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Continuer l'abonnement après l'essai",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Acheter un abonnement",
      "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Complété",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"Supprimé",
      "CUSTOMER_PRODUCT_STATUS_DISABLED":"Désactivé",
      "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Expiré",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Échoué",
      "CUSTOMER_PRODUCT_STATUS_INPROVISION":"InProvision",
      "CUSTOMER_PRODUCT_STATUS_PENDING":"En attente",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Réussi",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendu",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"Pays",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Domaine",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Prix du produit lié",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Fournisseur",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Réactiver l'abonnement",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Réactiver l'abonnement avec des extension s",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Portée",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Types de service",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Statut",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Suspendre l'abonnement",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Période d'essai",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"L'offre {{productName}} sera convertie en abonnement à la fin de la période d'essai.",
      "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Offre d'essai Parent",
      "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Retour aux commandes",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"Inclure 0 quantités",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Renouvellement",
      "CUSTOMER_PRODUCTS_IN_ACTIVE":"L'abonnement est inactif",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Détenu PAR",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Abonnement lié",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"Statut",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"département",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Domaine",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Site",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Domaine",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Annuler l'abonnement",
      "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Bon copié dans le presse-papiers",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Commentaires",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Notifications",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Gérer la propriété",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Utilisateurs",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Copier le lien",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Copier le lien",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Télécharger",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Récupérer les clés et les téléchargements",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Lien copié dans le presse-papiers",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Sélectionner un type de fichier",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Sélectionner une langue",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Sélectionner un produit",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Actions",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Taille du fichier",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Identifiant",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Clé",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Nom",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Droits",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Date d'annulation",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Date de renouvellement",
      "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Promotion appliquée",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Commandé",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Gérer",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"Traitement",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Appliquer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"Effacer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Inclure les entités enfants",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Domaine",
      "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Options de filtrage",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Gérer le produit",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Actions",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Montant",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"Commandé le",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Détenu PAR",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"Se renouvelle le",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Montant total",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Prix unitaire",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"département",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Site",
      "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Les modifications programmées sont présentes sur cet abonnement",
      "CUSTOMER_PRODUCTS_VALIDITY":"Valide",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Affecté au gestionnaire le",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Courriel",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Numéro de téléphone",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Nom du gestionnaire",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Détails du gestionnaire de compte",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Détails de base mis à jour avec succès",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Adresse d'expédition",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Profil client",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Départements",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Sites",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Ajouter une adresse",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"Ajouter un courriel",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Ajouter un numéro de téléphone",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adresses",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"Adresses courriel",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Numéros de téléphone",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Sites",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Aucun enregistrement trouvé !",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Ajouter",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Profils de paiement",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Aucun profil trouvé. Ajoutez un nouveau profil de paiement en cliquant sur le bouton AJOUTER",
      "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"L'ajout du profil de paiement est en cours. Veuillez patienter un moment pendant que le processus se termine.",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Adresse d'expédition",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Comptes d'utilisateur",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Date de création du compte",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Adresse de facturation",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"Date d'intégration C3 ",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Nom canonique",
      "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Merci d'avoir choisi",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Détails de base",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Magasin",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Revoir la commande",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Confirmer",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Terminer",
      "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"L'adresse email est déjà utilisée. Veuillez vous connecter à C3 en utilisant vos informations d'identification ou créer un nouveau compte avec une adresse e-mail différente",
      "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Veuillez remplir les champs obligatoires pour continuer",
      "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"La demande d'inscription a été placée avec succès",
      "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Vos coordonnées ont été soumises avec succès. Nous vous contacterons sous peu",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Soumettre",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Achetez des produits",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"L'acceptation de l'accord client du fournisseur doit être confirmée",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existe",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"n'existe pas",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Choisissez le pays",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Contact principal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Address 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"Pays",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Nom du domaine",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"Adresse e-mail de l'administrateur global",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Grant access to the primary contact (as Customer Administrator)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"L'e-mail fourni permettra à l'administrateur client d'accéder au portail.",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Voulez-vous envoyer une invitation pour accéder à l'application ?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"État",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Identifiant de domaine",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Ce nom de domaine n'est pas valide",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"est disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"n'est pas disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Validating domain availability",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Sélectionnez l'état",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Entrer l'état",
      "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Pour conserver les détails du domaine du locataire",
      "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Pour communiquer des éléments liés à la facturation",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Veuillez soumettre les détails ci-dessous et le partenaire vous contactera pour vous fournir une assistance supplémentaire.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Cliquez sur Copier le lien vers le presse-papiers pour copier le lien de la relation revendeur et suivre le modèle revendeur.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Ouvrez le lien dans une session de navigation privée et terminez la relation de revendeur en utilisant un compte qui est un administrateur global.<br>Après avoir terminé, fournissez les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Si vous ne souhaitez pas accepter la relation revendeur, fournissez simplement les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Instructions pour accepter la relation revendeur",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Instructions pour accepter la relation revendeur",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"En attendant, vous pouvez autoriser votre partenaire en tant que revendeur CSP en fournissant le consentement sur le lien de relation revendeur à l'aide de votre utilisateur administrateur global.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Cliquez sur copier le lien dans le presse-papiers",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":" pour copier le lien de la relation revendeur et suivre le modèle revendeur.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Ouvrez le lien dans une session de navigation privée et terminez la relation de revendeur en utilisant un compte qui est un administrateur global.<br>Après avoir terminé, fournissez les détails ci-dessous et cliquez sur Soumettre, après quoi ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Si vous ne souhaitez pas accepter la relation revendeur, fournissez simplement les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"SignUp BatchId",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"Le traitement peut prendre un certain temps et le statut sera affiché sur cette page. Si vous quittez la page ou fermez accidentellement le navigateur, nous vous tiendrons informé par e-mail",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"Inscription en cours",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Veuillez patienter pendant que nous configurons votre compte. Vous pouvez continuer à utiliser votre appareil comme d'habitude. Nous vous enverrons une notification par e-mail une fois la configuration terminée.",
      "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Pour récupérer les informations sur les locataires et les abonnements",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Panier",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Détails de paiement",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Achetez des produits",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Nom du client externe",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Action",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"Produits C3",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Produit externe",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Numéro de séquence",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Accord",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Nom de catégorie",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Contrat",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"Nom du client PSA",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Veuillez utiliser plus de filtres si vous souhaitez définir des contraintes supplémentaires sur la catégorie, le locataire, etc.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"Le contrat est-il par défaut ?",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Les contrats par défaut seront utilisés pour télécharger les services contractuels associés aux services qui sont créés et mappés automatiquement.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Locataire",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":"Département :",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Site :",
      "CUSTOMER_RECONCILIATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Cette fonctionnalité est disponible pour les offres personnalisées et est prise en charge pour une durée d'un an.",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"L'abonnement lié ne sera pas pris en charge par cette fonctionnalité.",
      "CUSTOMER_REPORT":"Rapports clients",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filtrer les produits",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Date de commande maximale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Quantité maximale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Date de commande minimale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Quantité minimale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Expire dans quelques jours",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Commandé entre",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Quantité",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Rapport de produits",
      "CUSTOMER_REPORTS":"Rapports",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Ajustement sauvegardé avec succès.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Ajout des frais antécédents en tant qu'ajustement à la facture actuelle",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Paiement enregistré avec succès",
      "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"Identifiant de référence de facturation",
      "CUSTOMER_SEARCH_CLOSE_OPTION":"Fermer les filtres",
      "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Inclure le client supprimé",
      "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Méthode de paiement par défaut",
      "CUSTOMER_SEARCH_CUSTOMER_name":"nom",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"Nom",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Méthode de paiement",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Balises",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Nom du domaine",
      "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"Identifiant client ERP",
      "CUSTOMER_SEARCH_FILTER":"Filtrer les clients",
      "CUSTOMER_SEARCH_INVOICE_NOTES":"Notes de facturation",
      "CUSTOMER_SEARCH_MARKET":"Code du marché",
      "CUSTOMER_SEARCH_PROVIDER":"Fournisseur",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "CUSTOMER_SEARCH_RESET_OPTION":"Réinitialiser",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Rechercher",
      "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Identifiant unique",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Ajouter au panier",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Sélectionner toutes les extensions",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Abonnement lié",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Magasin",
      "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Pour filtrer ou inclure des produits du niveau enfants",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Types de facturation",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Valide",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Type de validité",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"DURÉE DE L'ESSAI",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"OFFRE D'ESSAI",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_SIGNUP_FAILED":"L'inscription du Client a Échoué",
      "CUSTOMER_SIGNUP_SUCCESS":"Succès de L'inscription du Client",
      "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Succès de l'inscription. Vous pouvez vous connecter au portail C3 avec des informations d'identification envoyées à -",
      "CUSTOMER_SITE_ADD_SITE":"Ajouter un site",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site supprimé avec succès",
      "CUSTOMER_SITE_EDIT_SITE":"Modifier le site",
      "CUSTOMER_SITE_HEADER_TEXT":"Sites",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Site enregistré avec succès",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"Il n'est pas possible d'ignorer les erreurs pour cet abonnement. Veuillez contacter le support C3 pour obtenir de l'aide.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Plan Azure",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Sélectionnez le plan Azure.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Sélectionnez les droits.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Êtes-vous sûr de vouloir continuer ?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"Appliquer la promotion Au client ?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" Le prix ne peut pas être autre que <strong>{{price}}</strong> car ce ReservationOrderID {{reservationorderID}} a déjà un produit de réservation avec un prix.<br>Voulez-vous synchroniser avec le prix <strong>{{price} </strong> ?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Accepter",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Rejeter",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Actions",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Retour aux produits",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Responsable du renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Responsable du renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Action",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Retour au gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Mis à jour le",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Télécharger l'historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Prix",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Nom de produit",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Quantité",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nom d'utilisateur",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Prix",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Produits",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Quantité",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Renouvelle le",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Nom",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Veuillez sélectionner au moins un fichier.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Chargement de fichier réussi",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Approuver",
      "CUSTOMER_SUBSCRIPTIONS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Chargement du rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Rechargement des détails de facturation des clients ...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Ajouter un client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Ajouter l'utilisateur à l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Retour aux utilisateurs de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux abonnements",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Existant (multiple)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Intégration massive de clients",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Existant (unique)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Client existant",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Exportation du résumé de facturation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exporter les détails d'utilisation ",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Aller au rapport",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"ici",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Nouveau",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Nouveau Client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Intégrer un client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Recharger les détails de facturation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Recharger le mois en cours",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Recharger complètement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recharger le récapitulatif de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Recharger les détails d'utilisation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Supprimer",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Sélectionner",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Ajouter un client",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"Ajouter abonnements pour",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"Abonnements Azure de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Gérer l'utilisateur de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Offres",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Plan et offres",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Rapport de rapprochement pour",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Les utilisateurs de l'abonnement de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Configuration client de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Valeur",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Détails d'utilisation de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Utilisateurs de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Valider les erreurs d'intégration pour",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finaliser",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Facturation",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Profile de la société",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Type de paiement",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Contact principal",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"S'identifier",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"La facture a été approuvée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit finalisée et que le client ne soit facturé. Le client peut maintenant voir cette facture et vous pouvez y ajouter des paiements une fois finalisée. ",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"La facture a été finalisée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Le client est maintenant capable de voir cette facture et vous pouvez ajouter des paiements à cette facture.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"La facture a été générée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit finalisée et que le client ne soit facturé.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Payer automatiquement ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Paiement automatique : DÉSACTIVÉ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Paiement automatique : activé",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Facturation",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organisation IIN",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Méthode de paiement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Seuil de danger",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Seuil d'erreur",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Nom du groupe",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Seuil d'information",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Masquer pour le client",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Articles sélectionnés",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Ligne 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Ligne 3",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Mois",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Rôle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"État",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":" Seuil d’avertissement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Année",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Quantité",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Non",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Oui",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Abonnement inactif",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Quantités incompatibles",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Supprimer utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Activer l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Utilisateurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Consulter les détails",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Activer en CSP",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Nom de composant",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Identifiant de courriel",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Erreur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Coût estimé",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Masquer pour le client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Les abonnements ne sont pas visibles aux clients",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Dernière actualisation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"Facture MSFT ",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Statut d'intégration",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Prix",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Bénéfice",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Rôle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Seuils",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nom d'utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Aucun abonnement n'a encore été ajouté.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Affecter un plan client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Modifier le cycle de facturation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Modifier le nom",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Définir les balises",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Définir l'impôt %",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Corriger l'incompatibilité d'état d'activation dans le centre partenaire",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Correction de l'incompatibilité d'état de renouvellement automatique dans le Partner Center",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Corriger une disparité ...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Corriger une disparité",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Modifier l'offre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Fournisseurs de paiement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":"Mettre à jour le statut permanent du produit",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Changer de plan",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Corriger la promotion",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Impersonation rapide",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recharger le récapitulatif de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Corriger la portée",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Configuration client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":"Changer de terme",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Test de paiement automatique",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Erreurs de validation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"PERSONNALISÉ",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Total",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Budget mensuel-Info-Avertissement-Erreur-Danger",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"est disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"n'est pas disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Veuillez télécharger le modèle de",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Supprimer un utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Accorder l'accès au portail",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Personnifier",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Révoquer l'accès depuis le portail",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"La valeur doit être comprise entre 0 et 100",
      "CUSTOMER_SUP_TEXT_DELETED":"(Supprimé)",
      "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Synchroniser les profils",
      "CUSTOMER_TAGS_EXAMPLE":"Définir des balises, des paires de valeurs, pour le client, qui seraient utilisées lors de la définition du contrôle d'accès pour les utilisateurs. <br/> Exemple: Vous pouvez définir une balise nommée SupportAgent et un surnom pour un agent (disons Agent001) comme valeur. Si un préposé au service d'assistance possède l'une des balises définie comme SupportAgent et l'une des valeurs de cette balise particulière est Agent001, l'agent pourra voir les clients dont la paire de balises {SupportAgent, Agent001} est définie.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Nom",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Valeur",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Modifier",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Valeur",
      "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"La date de fin de mandat personnalisée spécifiée dans les instructions du prochain mandat prévu n'est valide que pour les produits OnlineServicesNCE.",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Voir les factures en attente",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"Paiements pour",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Client",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Client",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Détails de l'utilisateur enregistrés avec succès",
      "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"Le rapport d'attribution de licence utilisateur sera envoyé à l'adresse e-mail fournie.",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Ajouter un nouvel utilisateur",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Autoriser l'accès",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Gérer l'utilisateur",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Révoquer l'accès",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"département",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"Rôle",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Type de rôle",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"Site",
      "CUSTOMER_USERS_HEADING":"Utilisateurs",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Rôle",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Télécharger des factures ou les Paiements",
      "customerconfiguration":"Configuration client",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Veuillez mentionner si les détails ACH sont liés à un compte professionnel",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Ce client a déjà été sélectionné",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Le client existe déjà dans le système",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Client créé avec succès",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Le processus d'intégration du client est en cours",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner au moins un fichier.",
      "CUSTOMERONBOARDING_SUCCESS":"Client intégré avec succès.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Une erreur s’est produite pendant le chargement du fichier",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} a déjà une licence pour {offername}",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Le nombre d'utilisateurs fournis est plus grand que le nombre de licences disponibles. Veuillez corriger les détails de l'utilisateur et réessayer.",
      "customers":"Clients",
      "Customers":"Clients",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Actions",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Entreprise",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clients",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Lien vers le profil de facturation",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Lien vers le fournisseur",
      "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Supprimer le lien vers le profil de facturation",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Création de factures…",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Rechargement du rapport de rentabilité des clients ...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Retour aux factures",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Ajouter un ajustement",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Ajoutez le dû comme ajustement!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Télécharger une facture",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Aller au rapport",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Historique par date",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Historique par offre",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Initier le paiement",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test de facturation de client",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BÊTA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Facture pour",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Profitabilité par abonnements pour",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Sommaire des revenus et des coûts",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Historique des modifications de l'abonnement pour",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"Impossible de vérifier la disponibilité du domaine",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"Êtes-vous certain de vouloir accorder l'accès à {{username}} ?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Clients",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Aucune Facture trouvée. Veuillez contacter l'équipe de support.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adresse",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Client",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Résumé de compte",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Date d'échéance",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Date de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Date d'échéance de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Facture générée le",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Statut de facture",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"La facture a été générée !",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit traitée et que le client ne soit facturé.",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"La facture a été traitée !",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Le client est maintenant capable de voir cette facture et vous pouvez ajouter des paiements à cette facture.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Droits sur les factures précédentes",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"On dirait qu'il y a des frais provenant des factures antécédentes. Si vous êtes certain d'avoir enregistré tous les paiements du client, cliquez sur le bouton suivant pour",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"pour ajouter <strong>{{InvoiceDueAmount}}</strong> en tant qu'ajustement à la facture courante.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"On dirait qu'il y a un montant dû",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"au total.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Modifier",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Supprimer",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Détail de la facture",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Basé sur licence",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Basé sur l'utilisation",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Montant",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Type de charge",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Description",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Montant d'escompte",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Prix de l'offre",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Quantité",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Date de fin",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Montant total",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Montant du sous-total",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Date de début",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Nom de l'abonnement",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Montant de la taxe",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Pourcentage d'impôt",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Prix unitaire",
      "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"éléments de facture",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Enregistrer le paiement",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Montant du paiement",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Raison de l'échec",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Montant payé",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Identifiant de paiement",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Paiements de facture",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"Identifiant de transaction",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Enregistrez les paiements sur cette facture.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"par exemple :",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Paiement partiel en acompte, etc.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Contactez l'assistance si vous avez besoin d'aide supplémentaire sur les paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"Frais de traitement CC ({{CCFeePercentage}}% sur {{CCPaidAmount}})",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Total",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Sous-total",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Raison de l'échec",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Montant",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Date",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Statut de paiement",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Les détails de facturation, incluant l'historique, ont été actualisé avec succès",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Détails d'utilisation, incluant l'historique, actualisés avec succès",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Courriel de facturation",
      "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"Intégré le",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Plans",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"C3Id",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Nom",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Clients intégrés",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Rechercher un enregistrement correspondant dans le système",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Résultat trouvé pour l'identifiant de Référence {{ID}} dans le système de facturation.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Recherche de clients dans C3 pour une correspondance par l'identifiant du client fournisseur",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Client trouvé avec le nom - {{customername}}",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Rechercher des clients dans le centre Partner pour une correspondance avec l'identifiant Microsoft",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Trouvé un client avec le nom - {{customername}} dans le centre Partner",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Les quantités pour les offres enfants dans un composite doivent être les mêmes",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Une ou plusieurs offres dans CSP sont associées à la même offre dans la facturation. S'il vous plaît ajuster votre mappage",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"L'offre {{Name}} dans le plan à plusieurs abonnements CSP correspondant.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"L'abonnement {{nameinbilling}} dans la facturation ne peut être mappé",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"L'offre {{name}} n'a pas d'abonnements CSP correspondants dans le centre Partner",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"L'abonnement au centre Partner - {{name}} n'a aucune offre qui correspondante dans le plan de mappage",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Les offres suivantes devraient être mappées aux abonnements CSP -",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"L'offre {{name}} a plus d'utilisateurs spécifiés que le nombre de droits disponibles",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Les quantités pour les abonnements devraient correspondre à celles du centre Partner ",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Les quantités pour l'abonnement {{subscriptionname}} devraient correspondre entre le centre Partner et la facturation",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Les quantités pour les abonnements devraient correspondre entre le centre Partner et la facturation",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"Le nombre de droits d'utilisateurs par rapport à un abonnement CSP {{cspsubscription}} doit correspondre à la somme de tous les chiffres fournis avec les offres associées dans le plan",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Une ou plusieurs offres CSP ne sont pas mappées ou il manque des abonnements dans CSP",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Une erreur s’est produite pendant l'actualisation des abonnements pour  {{customerName}}",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Abonnements actualisés pour le client - {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"L'inscription client s'est terminée avec succès. Un email sera envoyé à {{registeredemail}} avec des instructions pour se connecter au portail.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"L'inscription du client s'est terminée avec succès.<br/>Les informations d'identification de l'utilisateur administrateur sont :<br/><b>UserId -</b> {{userId}},<br/><b>Temporary Password -</b> {{password }}",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"L'inscription du client a été effectuée avec succès.<br/>Les informations d'identification de l'utilisateur administrateur sont :<br/><b>UserId -</b> {{userId}},<br/><b>Mot de passe temporaire -</b> {{password }} <span class =\"text-danger\"> <br/><br/> <b>Échec de l'attribution des groupes de sécurité à la requête GDAP, veuillez compléter ceci dans l'Espace partenaires, sinon d'autres actions échoueront.</ b> </span>",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"L'enregistrement du client a été effectué avec succès.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"L'enregistrement du client a été effectué avec succès. Connexion au portail.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Veuillez associer une offre pour l'ajustement",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
      "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Synchroniser les profils",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Paiements clients",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Montant facturé",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Date",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nom du département",
      "CUSTOMERS_TABLE_HEADER_TEXT_MSFT_INVOICE":"Facture MSFT ",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Nouveau prix",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Ancien prix",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Autre coût",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Montant facturé avant impôt",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Facture MSFT avant taxes",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Bénéfice",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"% de bénéfice",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Nom du site",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Aucun abonnement n'a encore été ajouté.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Supprimer le client",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Paramètres client",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Gérer les plans",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Action",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Nom",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Télécharger les lignes basés sur l'utilisation",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Profitabilité par abonnements",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Recharger le rapport de profitabilité",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Historique des modifications de l'abonnement",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Afficher la facture",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Afficher les éléments de ligne d'utilisation",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"{{username}} ajouté à l'abonnement Azure avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"{{username}} ajouté à l'abonnement Azure {{azuresubscription}} avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} retiré de l'abonnement Azure avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Êtes-vous sûr, voulez-vous supprimer l'utilisateur ?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} retiré de {{azuresubscription}} avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Abonnement Azure sauvegardé avec succès",
      "customNotifications":"Notifications personnalisées",
      "customoffer":"Offre personnalisée",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Statut de facture mis à jour avec succès",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Commande passée avec succès. OrderId : {{orderId}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Destinataires du mot de passe",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Courriel des destinataires",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Entrez les destinataires de courriel séparés par des virgules",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Ajouter une nouvelle entité",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Annuler",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Gérer",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Ajouter une nouvelle entité",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Entités opérationnelles",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Type d'entité",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nom",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Journal d'acceptation des conditions générales",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Afficher la facture",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Nouvelle facture générée le <b>{{InvoiceGeneratedDate}}</b> pour la période de facturation à partir de <b>{{BillingStartDate}}</b> à <b>{{BillingEndDate}}</b> au montant de <b>{{InvoiceAmount}}</b>.",
      "DASHBOAR_TEXT_DATE":"Date",
      "DASHBOAR_TEXT_EMAIL":"Courriel",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL de contrat Ezidebit",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"URL du contrat client Microsoft",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL des conditions générales du partenaire",
      "DASHBOAR_TEXT_TIME":"Heure",
      "Dashboard":"Tableau de bord",
      "DASHBOARD_BREADCRUMB":"Tableau de bord",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Analytique client",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Rapports",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Rapport de changement de licence",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Rapport récapitulatif de licence",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Rapport d'utilisation",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Mettre à jour le profil",
      "DASHBOARD_CAPTION_PARAGRAPH":"Fournissez des ressources pour exploiter une entreprise prospère grâce à la puissance du cloud.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Bienvenue au marché Cloud",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Tous les SKU au format CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"Télécharger CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"Télécharger PNG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"Télécharger SVG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Top 10 des SKU en CSV",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Partenaire",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Tout",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Dernière période de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 mois",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 mois",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 mois",
      "DASHBOARD_COMPLAINCE_TEXT":"Centre de conformité",
      "DASHBOARD_COST_TEXT":"Coût",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Nombre de départements",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Départements",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Départements",
      "DASHBOARD_HEADER_TEXT_WARNING":"Avertissement!",
      "DASHBOARD_ICON_TITLE":"Icônes de Stockholm / Design / Calques",
      "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Mois en cours",
      "DASHBOARD_LBL_TEXT_NET_SEATS":"Net",
      "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Le mois précédent",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Bienvenue sur le tableau de bord de votre portail d'octroi des licences",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Utilisez les tuiles ci-dessous en tant que liens rapides afin de gérer vos abonnements et vos utilisateurs.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Ce portail vous permet d'ajouter de nouveaux utilisateurs à votre domaine, attribuer des licences et réinitialiser votre mot de passe.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Vous pouvez acheter des licences et des abonnements supplémentaires.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Mettre à jours votre profile (Adresse de facturation et méthode de paiement )",
      "DASHBOARD_REVENUE_TEXT":"Chiffre d'affaires",
      "DASHBOARD_SEAT_PURCHASED_TEXT":"Siège acheté",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Nombre de sites",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Sites",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Sites",
      "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Connectez-vous au portail",
      "DASHBOARD_TAIL_1_TEXT_Azure_PORTAL_TITLE":"Portail Azure",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Portail Azure",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Abonnements Azure",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Utilisation Azure depuis {{startDate}}",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Produits",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Abonnements de licences",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Afficher plus",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Rapports Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Utilisateurs",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Afficher plus",
      "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TENDANCE CE MOIS",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Échoué",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"En cours",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"Non reçu",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Reçu",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":"Date:",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":"Sièges:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":"Date:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":"Des produits:",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Dernière synchronisation {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Nouvelle facture générée le <b>{{InvoiceGeneratedDate}}</b> pour la période de facturation à partir de <b>{{BillingStartDate}}</b> à <b>{{BillingEndDate}}</b> au montant de <b>{{InvoiceAmount}}</b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"pour voir la facture. Ignorer si déjà payé.",
      "DASHBOARD_WARNING_MESSAGE":"Vous devez mettre à jour votre profile de paiement avant de pouvoir utiliser C3. Veuillez cliquer sur le bouton suivant pour le mettre à jour.",
      "DATA_PUSHED_DATES":"Certaines ou toutes les données ont été poussées précédemment, veuillez vérifier les journaux précédents.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Description de l'élément",
      "DAYS":"Jour(s) ",
      "DEACTIVATE_CONFORMATION":"Êtes-vous sûr de vouloir désactiver ?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"Impossible de désactiver le produit client",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Produit client désactivé",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Cet utilisateur n'est pas autorisé à supprimer cette offre",
      "DECEMBER":"Décembre",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Activez cette configuration pour définir la grille/liste comme vue par défaut sur la page des produits pour les clients",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Vue par défaut des produits clients",
      "DEFAULT_COTERMINOSITY_TYPE_DESC":"La sélection d’une valeur ici la définira comme valeur par défaut pour la fonctionnalité de coterminosité lors des achats d’abonnements.",
      "DEFAULT_COTERMINOSITY_TYPE_DISP":"Type de coterminosité",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Liste des types de contre-minosité",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Type de contreminiosité",
      "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Supprimer toutes les extensions",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de la suppression de la conversion de devises",
      "DELETE_CUSTOMER_FAILURE":"Mise à jour du nom du client échouée",
      "DELETE_CUSTOMER_SUCCESS":"Le client a été supprimé avec succès.",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Une erreur s'est produite lors de la suppression des destinataires de la notification par courriel",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"L'ajustement de facture sélectionné sera supprimé. Voulez-vous continuer ?",
      "DELETE_PARTNER_BUNDLE_TOOLTIP":"Supprimer le produit groupé",
      "DELETE_PARTNER_OFFERS":"Supprimer les offres des partenaires",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la suppression des détails sur l'impôt",
      "DELETE_PARTNER_USER":"Supprimer un utilisateur partenaire",
      "DELETE_PARTNER_USER_FAILURE":"Impossible de supprimer l'utilisateur partenaire",
      "DELETE_PONUMBER":"Supprimer le numéro de bon de commande",
      "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Supprimer un produit",
      "DELETE_QUOTE_SUCCESSFULLY":"Citation supprimée avec succès",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
      "DELETE_USER_EXCEPTION":"Cet utilisateur a un accès à C3, veuillez donc le supprimer",
      "DÉPARTEMENT":"(Département)",
      "DEPARTMENT":"(Service)",
      "DEPUIS":"Depuis",
      "DES DONNÉES D'ENTRÉE":"Entrée utilisateur",
      "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Titre principal de la bannière d'inscription publique",
      "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"En-tête de paragraphe de la bannière d'inscription publique",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Devise client",
      "DESC_CUSTOMER_DASHBOARD":"Tableau de bord du client",
      "DESC_PARTNER_DASHBOARD":"Tableau de bord des Partenaires",
      "DESC_PLAN_CURRENCY_OPTION":"Devise du plan",
      "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"URL du site Web pour le lien hypertexte d'administration dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"En-tête principal de la bannière d'inscription publique",
      "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Le paragraphe qui apparaît sur la bannière d'inscription publique sous l'en-tête de la bannière",
      "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"URL du site Web pour le lien hypertexte du site Web dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Nous contacter",
      "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":"URL du site Web pour le lien hypertexte du portail d'apprentissage dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_SUPPORT":"URL du site Web pour le lien hypertexte d'assistance dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Termes et conditions d'inscription publique 1",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Termes et conditions d'inscription publique 2",
      "DESC_SHOW_INVOICES_TO_CUSTOMER":"Activez cette option pour afficher les factures au client",
      "Description":"Description",
      "DESCRIPTION_FOR":"Description pour",
      "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Désactiver un produit",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Ancien modèle sécurisé désactivé et nouveau modèle sécurisé activé",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Merci pour la confirmation. L'application accédera à votre centre partenaire en suivant les nouvelles directives de sécurité.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Échec de la désactivation de l'ancien modèle sécurisé et activation du nouveau modèle",
      "DISCOUNT_MAX_ERROR":"La valeur doit être inférieure ou égale à 100",
      "DISCOUNT_MIN_ERROR":"La valeur doit être supérieure ou égale à 0",
      "DISP_CATEGORY_COUPON_ENTITY":"Catégorie",
      "DISP_PLANOFFER_COUPON_ENTITY":"Offre de forfait",
      "DISP_PROVIDER_COUPON_ENTITY":"Fournisseur",
      "DISP_SALETYPE_COUPON_ENTITY":"Type de vente",
      "DISP_SHOW_INVOICES_TO_CUSTOMER":"Afficher les factures au client",
      "DisplayCurrency":"Afficher la devise",
      "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Durée du mandat",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Réinitialiser",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Entrez une valeur positive",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Nouvelle offre distributeur",
      "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Retour à l'offre distributeur",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Offres des distributeurs",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Catégorie",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Offres des distributeurs",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Action",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Types de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Catégorie",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Charger les périodes",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Coût d'acquisition (de)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Coût d'acquisition (à)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Description",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiate?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Nom",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Ancien prix de revient",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Ancien prix de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icône",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Prix de vente (à partir de)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Prix de vente (à)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Terme",
      "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Offre distributeur {{distributorOffer}} enregistrée avec succès",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"nom",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Offre distributeur {{distributorOffer}} mise à jour avec succès",
      "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Supprimer le gestionnaire de compte avec succès",
      "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"Voulez-vous annuler?",
      "DOCUMETATION_HEADING":"Documentation",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Entité",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Montant facturé",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Nom qualifié",
      "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Télécharger la progression et le lien",
      "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Télécharger les factures",
      "DOWNLOAD_COLUMN_BUNDLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Règle de facturation pour l'achat de places",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Règle de facturation pour la réduction du nombre de sièges",
      "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Prix de revient",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Type de vente",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"Est-ce que c'est renouvelable automatiquement ?",
      "DOWNLOAD_COLUMN_BUNDLE_NAME":"Nom du bouquet",
      "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Nom du produit",
      "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Statut",
      "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Marché soutenu",
      "DOWNLOAD_COLUMN_BUNDLE_TERM":"Terme",
      "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Rapport sur les produits groupés",
      "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Nom du coupon actif",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Applicabilité",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Assigné à",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Code promotionnel",
      "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Description du coupon",
      "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Nom du Client",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Rabais",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Expire le",
      "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"AutoPay",
      "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"Identifiant de référence de facturation",
      "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Code du marché",
      "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Nom",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Méthode de paiement ",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
      "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Date d'inscription",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Balises",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Domaines",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Est-ce une offre d'essai",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Numéro de facture du fournisseur",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Numéro de référence de facturation",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Date de fin des frais",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Date de début des frais",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Taux de conversion",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Coût sur le Partenaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"Numéro client ERP",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Montant de la remise",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"Identifiant de l'offre durable",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Prix unitaire effectif par MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Date de la facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Notes de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Numéro de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Détail de la ligne de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Code de l'article",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Nom",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Nom de l'offre",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"Numéro de bon de commande",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Montant après taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Montant avant taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Identifiant promotionnel",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Coût unitaire au prorata",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Quantité",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Date de fin d'effet de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Abonnement effectif",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Durée d'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Type d'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Numéro d'abonnement du partenaire de syndication",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Montant de la taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Code fiscal",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Montant total",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Montant total pour le partenaire par MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Coût unitaire par produit",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Coût unitaire du partenaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Prix unitaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Prix unitaire par MS",
      "DOWNLOAD_COLUMN_MARKET_REGION":"Marchés pris en charge",
      "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Disponible pour le regroupement",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Disponible à la consommation immédiatement",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Règle de facturation pour l'achat de licences",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Règle de facturation pour la réduction des licences",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Type de facturation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Type de consommation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Coût d'acquisition",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Nom",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Type de vente",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Terme",
      "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Devise de facturation",
      "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"Est publique",
      "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Macro détail",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Valeur de la macro",
      "DOWNLOAD_COLUMN_PLAN_NAME":"Nom",
      "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Disponible pour le client",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Catégorie Cycle de facturation",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Type de facturation",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Catégorie",
      "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Coût pour le Partenaire",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Efficacité",
      "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Nom de l'offre amicale",
      "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"Le dernier changement de prix aura lieu le",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Nom de la macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Valeur de la macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Nom du plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"Identifiant de l'offre",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Nom de l'offre",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Nom du plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Prix en détail",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"type de service",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Marché pris en charge",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Terme",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Durée du mandat",
      "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Devise d'achat",
      "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"Partager l'URL",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Est disponible à l'achat",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Disponible pour la publication automatique",
      "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Catégorie",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Type de consommation",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Nom du département",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Rabais",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Prix effectif du produit",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Nom du produit",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Séquence de produit",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Nom de la catégorie du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Date de création du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Date de fin du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Date de début du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"ID de produit du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Quantité",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Nom du site",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Identifiant de domaine du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Prix unitaire",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Catégorie",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Coût pour le partenaire",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Code de devise",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Id Produit ",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Nom de produit",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Catégorie de fournisseur",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Nom du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Région",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Prix de détail",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Rapport sur le catalogue de produits",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Valide",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Type de validité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Nom de catégorie",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Crée par",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Créer un rendez-vous",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Code de devise",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Nom du client",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Entité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"Est un module complémentaire",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Nom de l'entité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Nouveau prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Nouvelle quantité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Nouveau statut",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Ancien prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Ancienne quantité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Ancien statut",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Nom de produit",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Catégorie de fournisseur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Prix neuf revendeur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Revendeur ancien prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Identifiant d'abonnement Azure",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"Type de changement",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Valide",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Type de validité",
      "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Sélectionnez au moins une colonne",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Télécharger",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Télécharger les coupons",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Télécharger les clients",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Télécharger des produits",
      "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Télécharger les forfaits",
      "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Télécharger les offres de forfaits",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Postes de facture",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Paiements de facture",
      "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Sélectionnez au moins une colonne",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"Télécharger CSV",
      "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Télécharger les clients non intégrés",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_POPUP_GRID_HEADER":"Télécharger le rapport de grille",
      "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"Il n'y a pas de données disponibles pour le téléchargement CSV",
      "DOWNLOAD_PRODUCTS_GRID":"Télécharger les produits",
      "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Télécharger le logiciel et les clés",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Ajouter plusieurs",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Lien multiple",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Lien unique",
      "DROPDOWN_PLACEHOLDER_SELECT":"Sélectionner",
      "DUE_STATUS_DUE":"Échéance",
      "DUE_STATUS_FINALIZED":"Finaliser la facture",
      "DUE_STATUS_OVERDUE":"En retard",
      "DUE_STATUS_PAID":"Payé",
      "DUE_STATUS_PARTIAL_PAID":"Partiellement payé",
      "earningsreport":"Rapport de revenus",
      "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Modifier les informations de facturation",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Modifier le groupe",
      "EDIT_INVOICE_BUTTON_TEXT":"Modifier la facture",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Produit",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Résumé",
      "EDIT_PONUMBER":"Modifier le numéro de bon de commande",
      "EffectivenessTypeId":"ID du type d'efficacité",
      "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Veuillez fournir une image avec une résolution maximale de 300 x 100 pour le logo de l'e-mail",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"L'adresse courriel spécifiée comme contact de facturation dans le profil du client",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Liste des adresses courriel des personnes qui devraient être notifiées par courriel",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Rôles autres que le service d'assistance",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"BillingContacts",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Personnes",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Rôle",
      "EMAIL_TABLE_HEADER_ACTIONS":"Actions",
      "EMAIL_TABLE_HEADER_EMAIL":"Courriel",
      "EMAIL_TABLE_HEADER_EMAIL_TYPE":"Type de courriel",
      "EMAIL_TEMPLATE":"Aperçu",
      "EMAIL_TEMPLATE_LABEL":"Modèles",
      "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Sélectionnez un modèle",
      "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"Modèle d'e-mail mis à jour avec succès",
      "EMAIL_TEMPLATE_SUBMIT":"Soumettre",
      "EMAIL_TYPE_BILLING":"Facturation",
      "EMAIL_TYPE_COMPANY":"Entreprise",
      "EMAIL_TYPE_INVOICE":"Facture",
      "EMAIL_TYPE_PERSONAL":"Personnel",
      "EMAIL_TYPE_SHIPPING":"Expédition",
      "EmailConfigurations":"SMTP",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"La configuration est conçue pour activer la tarification en fonction des niveaux d'admissibilité. Une fois activée, nous générerons des factures en fonction des niveaux d'admissibilité, quel que soit le type de facture. Veuillez confirmer que cela vous convient.",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Activer la tarification en fonction des niveaux d'admissibilité",
      "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Activer le produit",
      "ENABLE_RESELLER_PLAN":"Affecter un plan au revendeur",
      "ENABLE_RESELLER_PLAN_FAILURE":"Impossible d'affecter le plan au revendeur",
      "END_BILLING_PERIOD":"Fin de la période de facturation",
      "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"La date de fin doit être supérieure à la date de début",
      "END_DATE_REQUIRED_MESSAGE":"La date de fin est requise",
      "ENGAGE_BREADCRUMB_ADD":"Créer de l'engagement",
      "ENGAGE_BREADCRUMB_LIST":"Liste de l'engagement",
      "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Couleur du bouton d'appel à l'action",
      "ENGAGE_BUTTON_TEXT_COLOR":"Couleur du texte du bouton",
      "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Couleur du texte du bouton",
      "ENGAGE_BUTTON_URL_PLACEHOLDER":"Lien hypertexte du bouton d'appel à l'action",
      "ENGAGE_CTA_BUTTON_COLOR":"Couleur du bouton CTA",
      "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Texte du bouton d'appel à l'action",
      "ENGAGE_CUSTOMER_PAGENAME":"CustomerDashboard",
      "ENGAGE_EFFECTIVE_FROM":"En vigueur à partir de",
      "ENGAGE_EXPIRES_ON":"Expire le",
      "ENGAGE_HEADER_ACTIONS":"Actions",
      "ENGAGE_HEADER_ACTIONS_ADD":"Créer de l'engagement",
      "ENGAGE_HEADER_ACTIONS_BACK":"Retour à la liste",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "ENGAGE_HEADER_ENTITES":"Entités",
      "ENGAGE_HEADER_EXPIRES_ON":"Expire le",
      "ENGAGE_HEADER_MESSAGE":"Message",
      "ENGAGE_HEADER_PAGE":"Page",
      "ENGAGE_HEADER_TITLE":"Titre",
      "ENGAGE_NOTIFICATION_BACKGROUND":"Arrière plan",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Le Lorem Ipsum est le texte factice standard de l'industrie depuis les années 1500, lorsqu'un imprimeur inconnu a pris une galère de caractères et l'a brouillé pour en faire un livre de spécimens de caractères.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Offrir des remises pour votre boutique en ligne peut être une arme puissante pour fidéliser.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Stimulez le marketing &amp; confiance dans les produits de vente.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Commencez par une création de contenu moderne de conception de site de marque.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Offrir des rabais pour une meilleure vente en ligne dans un magasin peut fidéliser la conduite.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Commencez par une création de contenu moderne de conception de site de marque.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Commencez par une conception de site moderne et personnalisez-la avec le contenu et les fonctionnalités de votre marque. Tous les blogs Premium incluent un CSS personnalisé.",
      "ENGAGE_NOTIFICATION_BODY_TEXT":"Le corps du texte",
      "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"La notification d'engagement a été créée avec succès",
      "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Cela dépasse la longueur minimale de 30",
      "ENGAGE_NOTIFICATION_PORTAL_PAGE":"Portail",
      "ENGAGE_NOTIFICATION_TITLE_TEXT":"Texte du titre",
      "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"La notification d'engagement a été mise à jour avec succès",
      "ENGAGE_PARTNER_PAGENAME":"Tableau de bord des Partenaires",
      "ENGAGE_POPUP_BUTTON_URL":"URL du bouton",
      "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Texte du bouton CTA",
      "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Sélectionnez tous les clients",
      "ENGAGE_SUBMIT":"Soumettre",
      "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":"Le texte dépasse la longueur maximale de 15",
      "ENGAGE_THE_MAX_LENGTH":"La longueur maximale est de 250",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Veuillez sélectionner au moins une facture à publier sur QuickBooks.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Votre demande d'envoi de factures à QuickBooks est acceptée et mise en file d'attente pour traitement. Veuillez cliquer sur le bouton <strong>Afficher les journaux de publication</strong> pour obtenir des mises à jour sur les progrès.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Échec de la mise en file d'attente de la demande de chargement des factures sur QuickBooks online",
      "ENQUEUED_REQUEST_TO_CLONE_PLAN":"Demande en attente pour cloner le plan",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Demande en attente pour créer un nouveau plan comprenant toutes les offres",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Demande en file d'attente pour créer un nouveau plan revendeur avec toutes les offres",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Échec de la mise en file d'attente de la demande de chargement des factures sur Xero",
      "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":"Entrez un e-mail après la virgule ou supprimez la virgule supplémentaire",
      "ENTER_Azure_NEW_MARKUP_VALUE":"Saisissez une nouvelle valeur de majoration",
      "ENTER_AZURE_NEW_MARKUP_VALUE":"Saisissez une nouvelle valeur de majoration",
      "ENTER_REASON_FOR_Azure_NEW_MARKUP_VALUE":"Entrez la raison de la nouvelle valeur de majoration",
      "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Entrez la raison de la nouvelle valeur de majoration",
      "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Entrez la raison de la mise à jour de la valeur de majoration",
      "enterAddress1":"Entrez l'adresse 1",
      "enterAddress2":"Entrez l'adresse 2",
      "enterCity":"Entrer dans la ville",
      "enterEmail":"Entrez l'email",
      "enterPhoneNumber":"Entrez le numéro de téléphone",
      "enterState":"Entrer l'état",
      "enterZip":"Entrez le code postal",
      "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Nombre de droits à créer, ne dépassant pas {{Count}}",
      "ENTITLEMENT_MANAGEMENT":"Gestion des droits Azure",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":"Le nombre de droits à créer ne peut pas dépasser",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Les noms des droits commenceront par ceci.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Instructions",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Lorsque le prix d’autorisation est remplacé, ce prix s’appliquera à la fois aux calculs de facture et aux estimations Azure.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Remarque :</b>",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Utilisez le bouton <b>Remplacer</b> pour modifier le prix du droit.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Utilisez le bouton <b>Rétablir</b> pour réinitialiser le prix au prix du plan Azure par défaut pour le droit.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Utilisez <b>Exporter</b> pour télécharger les données d’autorisation pour un niveau de client et de locataire spécifique.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Récupérez les données d'autorisation pour tous les clients actifs à la fois en choisissant <b>Exporter tout</b>.",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Action",
      "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Ajouter un droit",
      "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Ajouter des droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Terminé",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Date créée",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Statut de création",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"département",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Nom du département",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Nombre de droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"Identifiant",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Liste des droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Nom",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Définir un nouveau prix pour le droit",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Exporter",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Exporter tout",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Exporter",
      "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Ce prix est applicable à partir du moment où la prochaine facture est générée et continue par la suite.",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Prix de vente",
      "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Séquence de démarrage",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Site",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Nom du site",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":"Processus terminé. Veuillez consulter le journal d'audit pour plus de détails.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"La demande d'ajout de droits est acceptée et en cours de traitement",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La demande d'ajout de droits a échoué.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Veuillez fournir une séquence de numéros",
      "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"Êtes-vous sûr de vouloir annuler le prix de vente de ce droit ?",
      "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"Le prix du droit a été modifié avec succès.",
      "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"Le prix du droit a été rétabli avec succès.",
      "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"L'incrément séquentiel sera utilisé comme suffixe dans les noms de droits.",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Statut de création du droit",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Veuillez patienter pendant la création des droits.",
      "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"La valeur doit être supérieure ou égale à -100 et inférieure ou égale à 100.",
      "ENTITY_DESC_AZURE_GROUP":"Groupe Azure",
      "ENTITY_DESC_BILLING_PERIODS":"Périodes de facturation",
      "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"Identifiant du revendeur du fournisseur de facturation",
      "ENTITY_DESC_BILLING_PROVIDER_USER":"Utilisateur du fournisseur de facturation",
      "ENTITY_DESC_CART":"Panier",
      "ENTITY_DESC_CART_LINE_ITEM":"Élément de campagne du panier",
      "ENTITY_DESC_COUPON":"Coupon",
      "ENTITY_DESC_CURRENCY":"Devise",
      "ENTITY_DESC_CUSTOM_OFFER":"Offre personnalisée",
      "ENTITY_DESC_CUSTOMER":"Client",
      "ENTITY_DESC_CUSTOMER_PRODUCT":"Produit client",
      "ENTITY_DESC_CUSTOMER_USER":"Utilisateur client",
      "ENTITY_DESC_DEPARTMENT":"département",
      "ENTITY_DESC_INVOICE":"Facture",
      "ENTITY_DESC_JOB_LOGS":"Journaux de tâches",
      "ENTITY_DESC_JOBS":"Emplois",
      "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Client du magasin local",
      "ENTITY_DESC_LOCAL_STORE_RESELLER":"Revendeur en magasin local",
      "ENTITY_DESC_PARTNER":"Partenaire",
      "ENTITY_DESC_PARTNER_SETTINGS":"Paramètres du partenaire",
      "ENTITY_DESC_PARTNER_USER":"Utilisateur partenaire",
      "ENTITY_DESC_PAYMENT_PROFILE":"Profil de paiement",
      "ENTITY_DESC_PLAN":"Plan",
      "ENTITY_DESC_PLAN_PRODUCT":"Planifier le produit",
      "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Planifier un bon produit",
      "ENTITY_DESC_PRODUCT":"Produit",
      "ENTITY_DESC_PRODUCT_VARIANT":"Variante de produit",
      "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Bon pour une variante de produit",
      "ENTITY_DESC_PROVIDER_CUSTOMER":"Client fournisseur",
      "ENTITY_DESC_RESELLER":"Revendeur",
      "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Profil de paiement du revendeur",
      "ENTITY_DESC_RESELLER_PLAN":"Forfait revendeur",
      "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Client prestataire de services",
      "ENTITY_DESC_SHOP":"Magasin",
      "ENTITY_DESC_SITE":"Site",
      "ENTITY_DESC_SITE_DEPARTMENT":"Département chantier",
      "ENTITY_DESC_TAG":"Étiqueter",
      "ENTITY_DESC_TAX":"Impôt",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Conditions générales",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Mettre à jour les paramètres du fournisseur",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres du fournisseur",
      "ENTITY_MAPPING":"Cartographie des entités",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"Êtes-vous sûr de vouloir libérer le mappage",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"Voulez-vous vraiment enregistrer le mappage pour <b> {{customerName}}</b> ?",
      "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Au moins mappez 1 produit",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Ces contrats ne sont plus par défaut : {{ContractNames}}",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"Le numéro de séquence en double n'est pas autorisé",
      "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Mappages enregistrés avec succès",
      "ENTITY_NAME":"Nom de l'entité",
      "ENTRÉE INVALIDE":"Entrée invalide",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Actions",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"Pays",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Courriel",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"Statut",
      "ENVOYEZ À":"Envoyez à",
      "ERROR__DATA_CONTACT_TYPE":"type de contact",
      "ERROR__DATA_PAYMENT_TYPE":"Type de paiement",
      "ERROR__DATA_PROFILE_TYPE":"Type de profil",
      "ERROR__DATA_URL":"URL",
      "ERROR_AVAILABLE_IN_BATCH":"L'erreur est disponible dans le lot.",
      "ERROR_BILLING_PROVIDER_NAME":"Nom du fournisseur de facturation",
      "ERROR_BUTTON_CONTINUE":"Continuer",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"Identifiant de produit client C3",
      "ERROR_C3_ENTITY_NAME":"Nom de l'entité",
      "ERROR_C3_RECORD_ID":"Identifiant d'enregistrement",
      "ERROR_C3_USER_ID":"Identifiant utilisateur",
      "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Quantité de panier invalide",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"Identifiant client de facturation",
      "ERROR_DATA_BILLING_REFERENCE_ID":"Identifiant de référence de facturation",
      "ERROR_DATA_C3_CUSTOMER_ID":"Identifiant de client C3",
      "ERROR_DATA_C3_RECORD_ID":"Identifiant d'enregistrement",
      "ERROR_DATA_C3_RESELLER_ID":"Identifiant de revendeur C3",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"Identifiant d'abonnement C3",
      "ERROR_DATA_COUNTRY_CODE":"Code de pays",
      "ERROR_DATA_CUSTOMER_NAME":"Nom du client",
      "ERROR_DATA_DOMAIN_PREFIX":"Nom du domaine",
      "ERROR_DATA_EMAIL_ID":"Identifiant de courriel",
      "ERROR_DATA_ENTITY_NAME":"Nom de l'entité",
      "ERROR_DATA_EVENT_NAME":"Nom de l'événement",
      "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Prend en charge les revendeurs avec ID MPN",
      "ERROR_DATA_INPUT_JSON":"Des données d'entrée",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "ERROR_DATA_PROVIDER_NAME":"Nom du fournisseur",
      "ERROR_DATA_RESELLER_ID":"Identifiant de référence",
      "ERROR_DATA_RESELLER_MPNID":"Identifiant de fournisseur revendeur",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"Identifiant de fournisseur revendeur",
      "ERROR_DATA_SEARCH_VALUE":"Rechercher une valeur",
      "ERROR_DATA_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "ERROR_DATA_TAX_CODE":"Code fiscal",
      "ERROR_DATA_TRANSACTION_REFERENCE":"Numéro de référence de la transaction",
      "ERROR_DATA_USER_ID":"Identifiant utilisateur",
      "ERROR_DELETE_DEPARTMENT":"Ce département contient des enregistrements associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Ce rayon contient des produits achetés associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_SITE":"Ce site contient des enregistrements associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_SITE_PRODUCT":"Ce site contient des produits achetés associés, vous ne pouvez donc pas supprimer cet enregistrement.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Impossible de créer le domaine dans le fournisseur.",
      "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le nom de l'agent ne peut pas être nul",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Aucune réponse de Authorize.net",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Impossible de trouver le client.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Impossible de trouver ce produit parmi vos abonnements",
      "ERROR_DESC_BAD_INPUT":"Il semble y avoir une erreur avec votre entrée",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"La gestion client a échoué en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Une erreur s'est produite lors de l'enregistrement des détails de la notification personnalisée",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Une erreur s'est produite lors du téléchargement du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"La récupération du client a échoué en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Récupération de profils de paiement échouée en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Récupération du statut de paiement échouée en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Une erreur s'est produite lors de la récupération du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"L'action a échoué car le profil de paiement est manquant",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"L'action a échoué car la configuration du profil de paiement est manquante",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Une erreur s'est produite lors de l'enregistrement de votre profil de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Il semble y avoir un problème avec vos profils de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Il semble y avoir un problème avec votre fournisseur de facturation, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Un problème est survenu lors de l'enregistrement de votre profil de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Une erreur s'est produite lors de la récupération du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Le fournisseur n'a pas pu être trouvé",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Service non mis en œuvre. Veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Il semble y avoir un problème avec vos types de paiement pris en charge pour le fournisseur de facturation, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Une erreur s'est produite lors de l'enregistrement des détails de la notification du webhook",
      "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Mauvaise demande - une autre tâche est en cours",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Une erreur s'est produite lors de la création/mise à jour de la facturation client, veuillez contacter le soutien technique.",
      "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Les revendeurs intégrés en vrac non pris en charge ont un Partenaire qui ne prend pas en charge les revendeurs avec un ID MPN",
      "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"Le panier contient des éléments de ligne avec des erreurs. Veuillez les corriger pour procéder à cet achat.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Cet abonnement est déjà utilisé par ce client",
      "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Une erreur s'est produite lors du changement de forfait client, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_SOUNCE_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans souNCE pour la fonctionnalité de plan de modification, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans source pour la fonctionnalité de plan de modification, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans cibles pour la fonctionnalité de plan de changement, veuillez contacter l'assistance.",
      "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"La date de fin de la commission ne peut pas être antérieure à la date de début de la commission",
      "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"La date de fin de la commission ne peut pas être nulle",
      "ERROR_DESC_COMMISSION_PENCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le PeNCEntage de commission ne peut pas être nul",
      "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le pourcentage de commission ne peut pas être nul",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Erreur de ConnectWise Manage lors de la tentative de suppression de l'ajout par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Erreur de ConnectWise Manage lors de la tentative d'ajout par identifiant et identifiant de contrat",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'ajouts par identifiant de contrat",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails de l'accord par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'accords",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails de l'accord par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'accords",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'un élément de catalogue par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'éléments de catalogue",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails du client par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Erreur de ConnectWise Manage lors de la tentative d'obtention de clients",
      "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Entrées en double dans les paiements importés",
      "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entrées en double dans le catalogue",
      "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Entrées en double dans le catalogue",
      "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"Une entrée en double existe dans l'entrée",
      "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Il y a des erreurs dans le formulaire",
      "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Une erreur s'est produite lors du lancement de la référence aux données d'entité à partir d'un service externe.",
      "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId n'est pas valide. Veuillez enregistrer votre application en tant qu'application native dans https://dev.powerbi.com/apps et contactez le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"GroupId est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"Le mot de passe est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"Le rapport est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"Le nom d'utilisateur n'est pas valide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Une erreur s'est produite lors de la suppression du client. Veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Une erreur s'est produite lors du chargement de votre image. Veuillez vérifier à nouveau les dimensions de l'image.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Échoué en raison d'une mauvaise demande. Merci d'essayer de nouveau.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Échoué en raison d'un conflit. Veuillez revérifier vos informations d'identification",
      "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Délai d'expiration de la passerelle. Veuillez réessayer.",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Service indisponible. Veuillez vérifier la configuration",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Une erreur s'est produite lors de l'initialisation du paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Une erreur s'est produite lors du déchiffrement de la valeur",
      "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Une erreur s'est produite lors du chiffrement de la valeur",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"L'identifiant AD APP du fournisseur du panneau de contrôle ou l'identifiant du domaine partenaire n'existent pas.",
      "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"L'ID d'application AD de l'API de tarification Microsoft ou l'ID de locataire du Partenaire n'existent pas.",
      "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Une erreur s'est produite lors de la tentative d'importation de plusieurs paiements",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Une erreur s'est produite lors de la tentative d'importation de l'entrée du catalogue",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Une erreur s'est produite lors de la tentative d'importation des commissions sur les produits",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Impossible d'importer les détails de l'extension du produit",
      "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"Échec de l'intégration du revendeur dans le cadre de l'intégration groupée en raison d'erreurs de validation",
      "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Une erreur s'est produite lors de la soumission de la demande d'inscription",
      "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"Échec de l'envoi de l'e-mail à la liste d'utilisateurs indiquée. La révision de vos configurations SMTP peut aider à résoudre ce problème.",
      "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Une erreur s'est produite lors de la tentative d'importation des données d'enregistrement de paiement",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Une erreur s'est produite lors de la préparation de l'entrée du catalogue de produits",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Une erreur s'est produite lors de la préparation des détails des commissions sur les produits",
      "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"Échec de la synchronisation de l'abonnement aux instances réservées en raison de l'indisponibilité du produit. Veuillez contacter l'équipe d'assistance.",
      "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"Vous ne pouvez pas mettre à jour le pourcentage décimal avec une valeur du pourcentage disponible.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Impossible de charger l'image. Veuillez vérifier votre connectivité Internet et assurez-vous que la taille de l'image correspond aux spécifications données.",
      "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Une erreur s'est produite lors de l'obtention de l'état du lot à bord des revendeurs fournisseurs en masse",
      "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Une erreur s'est produite lors de la vérification de l'état de la référence aux données d'entité provenant d'un service externe.",
      "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Une erreur s'est produite lors de la récupération des paiements en attente. Veuillez contacter l'assistance.",
      "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id manquant ou invalide dans l'entrée",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le coût pour le Partenaire doit être supérieur au nombre de l'entrée",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le coût pour le Partenaire doit être décimal à partir de l'entrée",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Code de devise manquant ou invalide dans l'entrée",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Code de devise manquant ou invalide",
      "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le code de devise doit correspondre à la devise du produit de l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Code de devise invalide dans l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Identifiant client manquant ou non valide dans l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Vous ne pouvez pas télécharger l'utilisation pour ce guid (client)",
      "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de produit interne non valide dans le catalogue",
      "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Séquence invalide dans le catalogue",
      "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Le code de la devise doit correspondre à l'entité concernée",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Montant du paiement manquant ou invalide",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"Le montant du paiement doit être positif",
      "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Format de date de paiement invalide",
      "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Date de paiement invalide",
      "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Identifiant de produit manquant ou non valide dans l'entrée",
      "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Vous ne pouvez pas télécharger l'utilisation du produit car le produit n'existe pas ou ne vous appartient pas.",
      "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de produit de fournisseur non valide dans le catalogue",
      "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"L'unité de mesure ne doit pas être un nombre entier/décimal à partir de l'entrée",
      "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Les unités doivent être des nombres entiers à partir de l'entrée",
      "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Les unités doivent être +ve nombre à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être au format AAAA-MM-JJ à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être de type date à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être postérieure à la date d'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"La date de paiement doit être postérieure",
      "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"La facture n'est pas générée pour les produits achetés",
      "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"L'article n'est pas achetable",
      "ERROR_DESC_MATCHING_PLAN_OFFER":"Une erreur s'est produite lors de la correspondance des offres de plan pour le plan de changement, veuillez contacter le support.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"Le client a été créé avec succès, mais n'a pas réussi à attribuer les groupes de sécurité à la demande GDAP. Veuillez compléter cette opération dans l'Espace partenaires, sinon d'autres actions échoueront.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Un problème s'est produit avec la demande GDAP. Veuillez travailler avec votre partenaire.",
      "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Client non valide",
      "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Non-concordance entre le ProviderResellerId spécifié et le ResellerId dans le fournisseur",
      "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"AgentName manquant dans le catalogue",
      "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id manquant dans l'entrée",
      "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Date de fin de commission manquante dans le catalogue",
      "ERROR_DESC_MISSING_COMMISSION_PENCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"PeNCEntage de commission manquant dans le catalogue",
      "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Pourcentage de commission manquant dans le catalogue",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner manquant dans l'entrée",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner est obligatoire pour le pourcentage du type de facturation",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CurrencyCode manquant dans l'entrée",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Code de devise manquant dans l'entrée",
      "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CustomerId manquant dans l'entrée",
      "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Identifiant interne de produit manquant dans le catalogue",
      "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"OfferId manquant dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Cycle de facturation manquant ou invalide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Catégorie manquante ou non valide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Fournisseur manquant ou non valide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Montant du paiement manquant ou invalide dans l'entrée",
      "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Date de paiement manquante dans l'entrée",
      "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ProductId manquant dans l'entrée",
      "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Identifiant de produit fournisseur manquant dans le catalogue",
      "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"SPCode manquant dans le catalogue",
      "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"UnitOfMeasure manquante dans l'entrée",
      "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unités manquantes en entrée",
      "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"UsageDate manquante dans l'entrée",
      "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"Échec de la connexion à l'API de gestion Microsoft Azure.",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Erreur du centre de partenaires Microsoft lors de la mise à jour du nom du produit client",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Une exception du centre partenaire s'est produite lors de la tentative de modification du statut de l'abonnement. Veuillez contacter le soutien technique.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Erreur du centre partenaire lors de la rérification MPN ID. Veuillez vérifier votre entrée à nouveau.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Un problème est survenu lors de la connexion au centre partenaire Veuillez laisser l'administrateur actualiser le jeton et réessayer. Contactez le soutien technique pour obtenir de l'aide.",
      "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Une erreur s'est produite lors de l'attribution de groupes de sécurité à l'accès à la relation d'administration déléguée du client",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Erreur du centre partenaire lors de la création de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Erreur du centre partenaire lors de la suppression de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Une erreur s'est produite lors de l'obtention des groupes de sécurité de l'agent d'administration Microsoft",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Erreur du centre partenaire lors de la récupération de tous les clients",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Erreur du Centre des Partenaires Microsoft lors de la tentative d'obtention de tous les revendeurs",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Erreur du centre partenaire lors de la récupération des règles de validation de pays",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Erreur du centre partenaire lors de la récupération de clients",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Une erreur s'est produite lors de l'obtention des détails de la relation d'administration déléguée du client fournisseur à partir du centre des partenaires",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"Le centre partenaire MS a rencontré une erreur en essayant de récupérer votre profil.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Impossible de récupérer le jeton d'actualisation.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Erreur du centre partenaire lors de la récupération de l'utilisateur de domaine",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Erreur du centre partenaire lors de la récupération des licences affectées à l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Erreur du centre partenaire lors de la récupération de données de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Erreur du centre partenaire lors de la récupération des rôles d'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Erreur du centre partenaire lors de la récupération des utilisateurs",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Erreur du centre partenaire lors de l'enregistrement du consentement du client",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Erreur du centre partenaire lors de la mise à jour de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Erreur du centre partenaire lors de la mise à jour des affectations de licence utilisateur",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Erreur du centre partenaire lors de la mise à jour du mot de passe de l'utilisateur",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Erreur du centre partenaire lors de la récupération des clients",
      "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Nom de domaine non disponible",
      "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"Impossible d'obtenir l'accès à partir de l'API de tarification Microsoft",
      "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Veuillez vérifier à nouveau. Fichier manquant dans la demande de validation des données",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"Aucune donnée disponible pour la mise en scène pour Autotask",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"Aucune donnée disponible pour la mise en scène pour \"ConnectWise Manage\"",
      "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"Aucun détail de revendeur de fournisseur trouvé dans le centre des Partenaires Microsoft",
      "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"Aucun revendeur avec identifiant MPN n'a été trouvé dans C3",
      "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"Le Partenaire n'est pas autorisé à acheter l'article",
      "ERROR_DESC_NOT_FOUND_ERROR":"Impossible de trouver la demande",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande",
      "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Une erreur s'est produite lors de la planification de la commande.",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"L'intégration a échoué en raison d'un domaine invalide.",
      "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"Offre parent indisponible pour cette offre d'essai",
      "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Le profil de paiement est manquant. Veuillez fournir un profil de paiement et réessayer.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"La gestion client a échoué en raison d'une exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestion du profil de paiement a échoué en raison d'une exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"La suppression de l'utilisateur a échoué en raison d'une erreur dans le portail du fournisseur de paiement.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Impossible de trouver un client avec l'identifiant",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Récupération des profils de paiement échouée en raison de l'exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Récupération du statut de paiement échouée en raison de l'exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Une erreur s'est produite lors de la gestion de la facturation client. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Une erreur s'est produite lors de la gestion du profil de paiement. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"Une erreur s'est produite lors de la création de la transaction. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Une erreur s'est produite lors de la mise à jour de l'état du paiement. Veuillez contacter l'assistance.",
      "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Cartographie du produit avec le mauvais client",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de locataire et de catégorie ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de catégorie ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de locataire ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation tombe dans la période de suspension du produit",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Le fournisseur BusinessId existe déjà dans le système",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"La BusinessId du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"L'e-mail de facturation n'est pas valide",
      "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"Le pays du revendeur du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Le fournisseur ResellerId existe déjà dans le système",
      "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"ResellerId du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"Le nom du revendeur du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Les détails du revendeur du fournisseur ne sont pas liés à c3, veuillez contacter votre Partenaire ou notre équipe d'assistance",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"La longueur des caractères des remarques doit être inférieure à 200",
      "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Veuillez vérifier à nouveau. Fichier manquant dans la demande de validation des données",
      "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"Le revendeur existe déjà dans le système",
      "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Sélectionnez au moins un produit",
      "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"Le paiement a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"La récupération du paiement en attente a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"La configuration du fournisseur de facturation a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"L'attribution du coupon a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"La gestion des coupons a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"La gestion des clients a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestion du profil de paiement a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_DELETE_CUSTOMER":"La suppression du client a échoué en raison d'une erreur de base de données.",
      "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de l'ajout d'offres au plan. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors du clonage du plan. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de la tentative de demande de plan de clonage. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de la tentative de demande de création d'un nouveau plan avec toutes les offres. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Erreur de la base de données lors de l'enregistrement de l'identifiant MPN",
      "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_DESC_SQL_GET_CUSTOMER":"La récupération du client a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"Échec de l'obtention des profils de paiement en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"Échec de l'obtention de l'état du paiement en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Erreur de la base de données lors de l'obtention des clients.",
      "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"Le nombre d'abonnements souhaité 6 a dépassé le nombre maximum d'abonnements autorisé par client 5",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Vous n'êtes pas autorisé à modifier le statut du produit, car la propriété est partagée avec des entités opérationnelles",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"La mise à jour du statut du produit n'a pas été entièrement réussie, veuillez contacter notre équipe de soutien pour obtenir de l'aide",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Ce code fiscal existe déjà, veuillez sélectionner un code fiscal unique.",
      "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"Les paramètres SMTP fournis sont erronés",
      "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"Nous ne pouvons pas traiter cette demande car les transactions sont désactivées.",
      "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"L'offre d'essai ne peut pas être achetée avec d'autres catégories",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"Impossible de traiter votre demande, veuillez contacter le soutien technique",
      "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"Impossible de traiter cette commande",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Vous n'êtes pas autorisé à accéder à cette fonctionnalité",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"L'emplacement d'utilisation n'est pas valide",
      "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Une erreur s'est produite lors de la validation du plan client, veuillez contacter l'assistance.",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Erreur survenue lors de l'accès à la base de données",
      "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Une erreur s'est produite lors de l'ajout d'un droit",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Une erreur s'est produite lors de l'ajout du locataire",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Une erreur s'est produite lors de la fourniture du consentement au locataire",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_Azure_PLAN":"Le locataire a été créé, mais une erreur s'est produite lors de l'ajout de l'abonnement",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Le locataire a été créé, mais une erreur s'est produite lors de l'ajout de l'abonnement",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Une erreur s'est produite lors de l'attribution du rôle",
      "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de l'annulation du renouvellement programmé",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"L'ID de catalogue donné n'est pas valide",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Une erreur s'est produite lors de la vérification de l'éligibilité à la promotion Microsoft NCE",
      "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Une erreur s'est produite lors de la création d'un abonnement aux instances réservées par synchronisation",
      "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de la création du renouvellement programmé",
      "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Erreur lors de la mise en file d'attente du message pour télécharger l'historique des abonnements dans le service PSA",
      "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Une erreur s'est produite lors de la génération d'une facture groupée",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Une erreur s'est produite lors de la génération du fichier CSV pour le rapport récapitulatif des éléments de facture",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Une erreur s'est produite lors de la génération du CSV pour les éléments de ligne de facture avec le rapport sur les détails de la commission",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Une erreur s'est produite lors de la génération du CSV pour le rapport de rentabilité",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Une erreur s'est produite lors de la génération du fichier CSV pour le rapport récapitulatif des montants de taxe",
      "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Erreur lors de l'obtention des détails du service PSA actif",
      "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Une erreur s'est produite lors de la tentative d'obtention de catégories pour la comparaison des revenus et des coûts.",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Une erreur s'est produite lors de l'obtention des détails de l'abonnement",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Erreur lors de l'obtention du mappage des abonnements client vers PSA",
      "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Une erreur s'est produite lors de l'obtention du nombre de clients",
      "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Une erreur s'est produite lors de la tentative d'obtention de détails sur les revenus et les coûts par catégorie.",
      "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Une erreur s'est produite lors de l'obtention des détails de l'abonnement au service externe par ID d'abonnement c3, ID de service de contrat et ID de contrat",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors de l'obtention de la liste des configurations gérées par le client",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors de l'obtention de la liste des configurations gérées par le revendeur",
      "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Une erreur s'est produite lors de l'obtention de la liste des dates de fin d'abonnement",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Erreur lors de l'obtention des entités mappées et des services contractuels pour Autotask",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Erreur lors de l'obtention des entités et produits mappés pour ConnectWise Manage",
      "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Une erreur s'est produite lors de l'obtention de revendeurs de fournisseurs non intégrés",
      "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Une erreur s'est produite lors de l'obtention des paramètres du portlet",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Une erreur s'est produite lors de l'obtention du nombre de produits",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Une erreur s'est produite lors de la récupération du produit comme à la date",
      "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Une erreur s'est produite lors de la récupération des détails de la promotion",
      "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Erreur lors de l'obtention du résumé du journal de publication PSA",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Une erreur s'est produite lors de l'obtention des sièges achetés par identifiant Sku",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Une erreur s'est produite lors de l'obtention des sièges achetés au cours d'une période donnée",
      "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Une erreur s'est produite lors de l'obtention du revenu par rapport au coût",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Une erreur s'est produite lors du décompte des sièges",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Une erreur s'est produite lors du décompte des sièges comme à la date",
      "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Erreur lors de l'obtention des paramètres de la tâche automatique",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Erreur lors de l'obtention de l'état du journal du service PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Erreur lors de l'obtention des journaux de publication du service PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Erreur lors de l'obtention des enregistrements échelonnés PSA",
      "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Une erreur s'est produite lors de l'obtention des clients estimés",
      "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Une erreur s'est produite lors de l'obtention de la valeur des produits achetés par chiffre d'affaires",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Erreur lors de la gestion des entités mappées et des services contractuels pour Autotask",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Erreur lors de la gestion des entités et des produits mappés pour ConnectWise Manage",
      "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Une erreur s'est produite lors de la tentative d'intégration du revendeur du fournisseur",
      "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Une erreur s'est produite lors du traitement de la demande de revendeurs de fournisseurs intégrés en masse",
      "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Une erreur s'est produite lors de la mise en file d'attente pour les revendeurs de fournisseurs intégrés en masse",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors du rétablissement des configurations gérées par le client par défaut",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors du rétablissement des configurations gérées par le revendeur par défaut",
      "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Une erreur s'est produite lors de l'enregistrement ou de la mise à jour de la date de fin personnalisée de l'élément de campagne du panier.",
      "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Erreur lors de l'enregistrement des données d'historique d'abonnement des clients sélectionnés dans la mise en scène",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors de la mise à jour des configurations gérées par le client",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors de la mise à jour des configurations gérées par le revendeur",
      "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de la mise à jour du renouvellement programmé",
      "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Erreur lors de la mise à jour du statut des enregistrements échelonnés",
      "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Une erreur s'est produite lors de la mise à niveau de l'abonnement",
      "ERROR_DETAILS":"Détails de l'erreur",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Ce nom de département existe déjà sous ce client et ce site.",
      "ERROR_DUPLICATE_SITE_NMAE":"Ce nom de site existe déjà sous ce client.",
      "ERROR_EMPTY_COMMENTS_SUBMITTED":"Les commentaires vides ne peuvent pas être soumis",
      "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"Échec de l'importation des détails du paiement",
      "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Échec de l'importation des détails de la commission sur les produits",
      "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"Échec de la mise en scène de l'ajout groupé d'entrées de locataire",
      "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Erreur lors de la génération des détails dans les détails de l'entité de la facture groupée",
      "ERROR_FIELD_REQUIRED":"Ce champ est obligatoire",
      "ERROR_FORM_INVALID":"Le formulaire n'est pas valide.",
      "ERROR_GETTING_HOSTED_TOKEN":"Erreur lors de la récupération du jeton hébergé",
      "ERROR_GETTING_STRIPE_DETAILS":"Une erreur s'est produite lors de la récupération des informations Stripe",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Vous ne semblez pas avoir les autorisations nécessaires pour effectuer cette action",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Interdit !",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Impossible de mettre à jour le statut du produit, car une affectation de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "ERROR_MESSAGE":"Quelque chose s'est mal passé!",
      "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"L'approbation n'a pas abouti, veuillez contacter l'assistance",
      "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Au moins un client doit être sélectionné",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Vous n'êtes pas autorisé à réduire le nombre de licences",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Une erreur s'est produite lors de la création du plan. Veuillez contacter le soutien technique.",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Une erreur s'est produite lors de la création du plan revendeur. Veuillez contacter notre équipe d'assistance.",
      "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Autotask. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Autotask via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Tâche automatique</b>.",
      "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Connectwise. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Connectwise via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Gestion Connectwise</b>.",
      "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"Les paramètres SMTP ne sont pas valides",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Nous sommes désolés mais la connectivité semble rompue entre votre compte C3 et Microsoft Partner Center. Cela peut entraîner un échec lors de l'exécution de toutes les transactions de l'Espace partenaires via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Fournisseurs</b>",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Nous sommes désolés, mais la connectivité semble interrompue entre votre compte C3 et l'API Microsoft Partner Pricing. Cela peut entraîner un échec lors de la récupération des détails de l'API de tarification Microsoft via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Fournisseurs</b>",
      "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Quickbooks. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Quickbooks via C3. Veuillez établir la connectivité en visitant <b>Analyser -> Entreprise -> Télécharger les factures/paiements</b>.",
      "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Xero. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Xero via C3. Veuillez établir la connectivité en visitant <b>Analyser -> Entreprise -> Télécharger les factures/paiements</b>.",
      "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Numéro de panier invalide",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Client non valide",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"L'utilisateur du domaine n'est pas valide",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"La modification de statut n'est pas valide.",
      "ERROR_MESSAGE_MANAGE_OWNERSHIP":"Le champ « Quantité » est obligatoire et ne peut pas contenir de valeur nulle ou décimale. Veuillez saisir un nombre entier valide.",
      "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Jusqu'à 3 marchés peuvent être sélectionnés.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Aucun message trouvé relatif à une demande de relation revendeur, veuillez contacter le soutien technique de C3",
      "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"Le panier contient des offres autres que les offres Partner - LicenseSupported, veuillez les supprimer pour continuer.",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Un utilisateur possédant cette adresse courriel existe déjà ou vous n'avez pas l'autorisation d'ajouter cet utilisateur",
      "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Les achats peuvent échouer. Veuillez cliquer pour en savoir plus.",
      "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"La diminution de la quantité n'est pas autorisée",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Ce client n'est pas lié à Microsoft csp",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"Impossible de traiter votre demande.",
      "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Vous avez saisi plus de bons que nécessaire",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Une erreur s’est produite lors de l'exportation du rapport des éléments de ligne la facture. Veuillez contacter l'administrateur",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Une erreur s’est produite lors de l'exportation du rapport sommaire de la facture. Veuillez contacter l'administrateur",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Une erreur s’est produite pendant le chargement du fichier",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Erreur lors du chargement de l'image. Veuillez réessayer avec une autre image.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Veuillez sélectionner l'extension de l'image ex : .jpg, .png etc.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Erreur du centre partenaire lors de la rérification MPN ID. Veuillez vérifier votre entrée à nouveau.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Erreur du centre partenaire lors de la création de l'utilisateur",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Erreur du centre partenaire lors de la suppression de l'utilisateur",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Erreur du centre partenaire lors de la récupération de l'utilisateur de domaine",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Erreur du centre partenaire lors de la récupération de données de l'utilisateur",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Erreur du centre partenaire lors de la récupération des rôles d'utilisateur",
      "ERROR_MS_FAILED_TO_GET_USERS":"Erreur du centre partenaire lors de la récupération des utilisateurs",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Erreur du centre partenaire lors de la mise à jour de l'utilisateur",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Erreur du centre partenaire lors de la mise à jour des affectations de licence utilisateur",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Erreur du centre partenaire lors de la mise à jour du mot de passe de l'utilisateur",
      "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Une erreur s'est produite lors de la tentative de mise en file d'attente d'une demande de mise à jour des dernières offres vers le plan",
      "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Une erreur s'est produite lors de la vérification de l'éligibilité aux promotions.",
      "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Une erreur s'est produite lors du comptage des promotions du mois en cours.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Une erreur s'est produite lors de la récupération des détails des promotions.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Une erreur s'est produite lors de l'obtention des détails des promotions auprès du fournisseur.",
      "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Une erreur s'est produite lors du chargement des promotions.",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande",
      "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"L'erreur s'est produite lors de l'enregistrement des détails de la mise à niveau de l'abonnement dans la table intermédiaire",
      "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Une erreur s'est produite lors de la planification de la commande. Veuillez contacter l'assistance",
      "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"L'erreur s'est produite lors de la tentative de récupération des produits éligibles pour la mise à niveau de l'abonnement",
      "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Une erreur s'est produite lors de la mise à jour de l'ID de promotion dans cartlineItem.",
      "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Le produit est manquant lors de l'importation des données d'utilisation quotidienne de l'offre du Partenaire",
      "ERROR_PLAN_ID":"Identifiant du forfait",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Veuillez confirmer l'acceptation par le client du contrat client Microsoft",
      "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Erreur lors de l'importation des données d'utilisation quotidienne de l'offre Partenaire",
      "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Une erreur s'est produite lors de la tentative d'importation des commissions sur les produits",
      "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La séquence de produits contient des valeurs non valides ou négatives. Veuillez fournir une séquence valide.",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"Le locataire que vous essayez d'intégrer/lier existe déjà dans le portail, veuillez contacter l'assistance",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Le domaine que vous essayez d'intégrer/lier existe pour l'un de vos clients. Veuillez essayer un autre domaine.",
      "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La ligne de devis contient des valeurs non valides ou négatives. Veuillez fournir une quantité valide comprise entre 1 et 9999.",
      "ERROR_RESELLER_PROVIDER_ID":"Identifiant de fournisseur revendeur",
      "ERROR_SAVING_CUSTOMER":"Erreur lors de l'enregistrement du client",
      "ERROR_SAVING_PARTNER_OFFER":"Une erreur s'est produite lors de l'enregistrement de l'offre du Partenaire",
      "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"Le bundle doit contenir la même catégorie d'offres MS.",
      "ERROR_SAVING_PAYMENT_ACCOUNT":"Erreur lors de l'enregistrement du compte de paiement",
      "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Erreur de la base de données lors de l'enregistrement de l'identifiant MPN",
      "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Seules les offres personnalisées sont éligibles à l'offre d'essai.",
      "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Seules les offres basées sur la quantité sont éligibles à l'offre d'essai.",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Impossible de trouver un domaine du fournisseur.",
      "ERROR_UPDATING_AUTO_RENEW_STATUS":"La mise à jour du renouvellement automatique a échoué pour {{productName}}.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Erreur survenue lors de l'accès à la base de données",
      "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Une erreur s'est produite lors de la tentative de vérification du lien entre le revendeur et le fournisseur",
      "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Une erreur s'est produite lors du téléchargement de l'exemple de fichier de modèle de transfert groupé .csv",
      "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Erreur lors de la génération du rapport sur le catalogue de produits",
      "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Une erreur s'est produite lors de l'obtention de l'historique du consentement au renouvellement",
      "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Une erreur s'est produite lors de l'obtention des dates de fin à partir du Centre des partenaires. Veuillez contacter l'assistance.",
      "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Une erreur s'est produite lors de la tentative d'obtention des cotisations de la facture",
      "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Une erreur s'est produite lors de l'obtention des sièges du produit évalué",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Veuillez sélectionner au moins une facture à publier sur Xero.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Erreur : Abandon du téléchargement en raison d'une erreur lors de la création des entités",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Erreur : l'ajout est dans ConnectWise Manage actif",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant de l'accord est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Erreur : la facturation n'est pas activée pour cet accord dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Erreur : l'accord mappé n'est pas actif dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Erreur : l'accord n'est pas présent dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Erreur : les accords ne sont pas mappés pour ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Erreur : les accords ne sont pas présents dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : Des ajouts ambigus sont présents, le mappage n'est pas possible.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant du contrat est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Erreur : la facturation n'est pas activée pour ce contrat dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Erreur : le contrat mappé n'est pas actif dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Erreur : le contrat n'est pas présent dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : le contrat n'est pas présent dans le service externe sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : le service contractuel n'est pas mappé sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Erreur : le service de contrat mappé n'est pas présent dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : les services contractuels ne sont pas mappés sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Erreur : les contrats ne sont pas mappés pour ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Erreur : les contrats ne sont pas présents dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : les contrats ne sont pas présents dans le service externe sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Erreur : le client n'est pas actif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Erreur : le client externe n'est pas mappé avec le client c3",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : le client n'est pas présent dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Erreur : le mappage de l'entité ou du produit est manquant.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Erreur : une tentative d'exportation a déjà été effectuée. Le statut de cette opération est",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Erreur : le contrat n'est pas actif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant client externe est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Erreur : les clients ne sont pas présents dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Erreur : une erreur s'est produite lors de la création de l'ajout dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Erreur : Une erreur s'est produite lors de la création d'un ajustement :",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Erreur : une erreur s'est produite lors de la création d'un ajustement pour changement de prix",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Erreur : une erreur s'est produite lors de la création du service",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Erreur : échec de création de frais de contrat pour un produit à usage unique",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Erreur : une erreur s'est produite lors de la création du service de contrat :",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Erreur : une erreur s'est produite lors de la mise à jour de l'ajout dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Erreur : impossible de créer un mappage car plusieurs services correspondants ont été trouvés dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Erreur : aucun contrat correspondant n'a pu être trouvé pour être mappé automatiquement.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Erreur : aucun client externe correspondant n'a pu être trouvé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Erreur : aucun produit externe correspondant n'a pu être trouvé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : le produit n'est pas associé à ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Erreur : le produit mappé n'est pas présent dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : les produits ne sont pas mappés sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Erreur : les produits C3 n'ont pas de service externe mappé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant du service est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin de l'accord.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation de l'accord",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Erreur : l'abonnement est actif dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Erreur : l'abonnement est inactif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Erreur : Impossible de terminer le mappage automatique car plusieurs contrats correspondants ont été trouvés",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Erreur : impossible de terminer le mappage automatique car plusieurs clients externes ont été mis en correspondance",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Erreur : impossible de terminer le mappage automatique car plusieurs produits externes ont été mis en correspondance",
      "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Erreur : la validation n'a pas réussi",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
      "ERRORMESSAGEPOPUP_HEADER":"Veuillez inclure au moins un des prérequis pour les extensions dans la liste ci-dessous",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Extension",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Offre pré-requise",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Erreur : l'accord PSA n'est pas mappé avec le client c3",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Erreur : le produit externe n'est pas mappé avec le produit c3",
      "ERRORS_FROM_PREVIOUS_ACTION":"Votre dernière action a entraîné des erreurs, cliquez pour plus de détails...",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande Veuillez contacter le soutien technique.",
      "ET":"et",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"ID d'abonnement du plan Azure",
      "EVENT_Azure_PLAN_SUBSCRIPTION_ID":"subscriptionId du plan Azure",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Nom du plan Azure",
      "EVENT_Azure_SUBSCRIPTION_UPGRADEID":"ID de mise à niveau",
      "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"ID de mise à niveau",
      "EVENT_BATCH_ID":"ID de lot",
      "EVENT_BATCH_STEP_ID":"ID d'étape de lot",
      "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Résumé du lot du revendeur intégré en masse",
      "EVENT_CAN_PRICE_LAG":"AncienCanPriceLag",
      "EVENT_CAN_PRICE_LEAD":"NouveauCanPriceLead",
      "EVENT_CONSTANT_TRUE":"TRUE",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Continuer l'offre d'essai jusqu'à l'échec de l'abonnement",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Continuer l'offre d'essai jusqu'au succès de l'abonnement",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Convertir l'offre d'essai en échec d'abonnement",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Convertir l'offre d'essai en succès d'abonnement",
      "EVENT_CREATED_BY":"Événement créé par",
      "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Identifiant interne",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Courriel",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Prénom",
      "EVENT_DATA_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Nom de famille",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Numéro de téléphone",
      "EVENT_DATA_ACCOUNT_NUMBER":"Numéro de compte",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Action",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Erreur",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Statut",
      "EVENT_DATA_ADDRESS_CITY":"Ville",
      "EVENT_DATA_ADDRESS_COUNTRY":"Pays",
      "EVENT_DATA_ADDRESS_LINE_1":"Adresse Ligne 1",
      "EVENT_DATA_ADDRESS_LINE_2":"Adresse Ligne 2",
      "EVENT_DATA_ADDRESS_STATE":"État",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Code postal",
      "EVENT_DATA_AGREEMENT":"Accord",
      "EVENT_DATA_AGREEMENT_DATE":"Date de contrat",
      "EVENT_DATA_AGREEMENT_LINK":"Lien du contrat",
      "EVENT_DATA_AGREEMENT_NAME":"Accord",
      "EVENT_DATA_AGREEMENT_TYPE":"Type de contrat",
      "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"L'attribution des groupes de sécurité Microsoft à la requête GDAP a réussi ?",
      "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Coupon attribué à",
      "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":"Nouvelle valeur",
      "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Ancienne valeur",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Actions",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Erreur",
      "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Raison",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Statut",
      "EVENT_DATA_BCC_RECIPIENTS":"Destinataires CCI",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Nom du cycle de facturation",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Alias de paiement",
      "EVENT_DATA_BILLING_PERIOD_ID":"Identifiant de période de facturation",
      "EVENT_DATA_BILLING_PROVIDER":"Fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Identifiant de client fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Nom de client fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Identifiant de revendeur de fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_USER":"UserId",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"UserId",
      "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Nom du bouquet",
      "EVENT_DATA_C3_CUSTOMER_NAME":"Client C3",
      "EVENT_DATA_C3_PRODUCT_NAME":"Produit C3",
      "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Nom de l'abonnement C3",
      "EVENT_DATA_CARD_TYPE":"Type de carte",
      "EVENT_DATA_CART_ID":"Identifiant de panier",
      "EVENT_DATA_CART_ITEMS":"Articles du panier",
      "EVENT_DATA_CATEGORY_ID":"Identifiant de catégorie",
      "EVENT_DATA_CATEGORY_NAME":"Nom de catégorie",
      "EVENT_DATA_CC_RECIPIENTS":"Destinataires CC",
      "EVENT_DATA_CHANGE_TYPE":"Modifier le type",
      "EVENT_DATA_CLONED_FROM_PLAN":"Cloné à partir de",
      "EVENT_DATA_COMPANY_NAME":"Nom de l'entreprise",
      "EVENT_DATA_CONSUMED_SEATS":"Sièges consommés",
      "EVENT_DATA_CONSUMPTION_TYPE":"Type de consommation",
      "EVENT_DATA_CONTRACT":"Contrat",
      "EVENT_DATA_CONTRACT_NAME":"Nom du contrat",
      "EVENT_DATA_CONTRACT_SERVICE_NAME":"Nom du service contractuel",
      "EVENT_DATA_CONVERSION_RATE":"Taux de conversion",
      "EVENT_DATA_CORRELATION_ID":"ID de corrélation",
      "EVENT_DATA_COUNTRY_CODE":"Code de pays",
      "EVENT_DATA_COUPON_CODE":"Code promotionnel",
      "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Nombre de clients avec de nouvelles missions",
      "EVENT_DATA_COUPON_DESCRIPTION":"Description de coupon",
      "EVENT_DATA_COUPON_DETAILS":"Détails du coupon",
      "EVENT_DATA_COUPON_DISCOUNT":"Rabais",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Expire le",
      "EVENT_DATA_COUPON_NOOFRECURRENCES":"Nombre de récurrences",
      "EVENT_DATA_COUPON_VALID_TILL":"Valide jusqu'à",
      "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Créé par dans le fournisseur",
      "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Date de création dans le fournisseur",
      "EVENT_DATA_CURRENCY_CODE":"Code de devise",
      "EVENT_DATA_CURRENT_QUANTITY":"Quantité actuelle",
      "EVENT_DATA_CURRENT_STATUS":"Statut actuel",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Nouveau cycle de facturation personnalisé",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Ancien cycle de facturation personnalisé",
      "EVENT_DATA_CUSTOMER_C3_ID":"Identifiant de client C3",
      "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Consentement",
      "EVENT_DATA_CUSTOMER_NAME":"Nom du client",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Nouveau nom du client",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Ancien nom du client",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Identifiant de référence client",
      "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"Inscription client batchid",
      "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"Interne planid",
      "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"Tous les journaux PSA sont-ils désactivés ?",
      "EVENT_DATA_DEPARTMENT_NAME":"Nom du département",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Cycle de facturation",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Nom",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Coût d'acquisition",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Prix de vente",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Valide",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Type de validité",
      "EVENT_DATA_DOMAIN":"Domaine",
      "EVENT_DATA_DOMAIN_NAME":"Nom du domaine",
      "EVENT_DATA_EFFECTIVE_FROM":"En vigueur à partir de",
      "EVENT_DATA_EMAIL":"Courriel",
      "EVENT_DATA_EMAIL_ADDRESS":"Courriel",
      "EVENT_DATA_END_BILLING_PERIOD_ID":"Fin de facturation periodId",
      "EVENT_DATA_END_DATE":"Date de fin",
      "EVENT_DATA_ENTITY_ID":"Identifiant d'entité",
      "EVENT_DATA_ENTITY_NAME":"Nom de l'entité",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entité de l'utilisateur",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entité de l'utilisateur",
      "EVENT_DATA_ERROR":"Erreur",
      "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Cartographie du produit avec erreur",
      "EVENT_DATA_EVENT":"Événement",
      "EVENT_DATA_EVENT_ID":"Identifiant de l'événement",
      "EVENT_DATA_EVENT_NAME":"Nom de l'événement",
      "EVENT_DATA_EXCEPTION_MESSAGE":"Message d'exception",
      "EVENT_DATA_EXPIRES_ON":"ExpiresOn",
      "EVENT_DATA_EXPORT_ERROR":"Erreur d'exportation",
      "EVENT_DATA_EXPORT_STATUS":"Statut d'exportation",
      "EVENT_DATA_EXTERNAL_AGREEMENTS":"Accords externes",
      "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Services contractuels externes",
      "EVENT_DATA_EXTERNAL_CONTRACTS":"Contrats externes",
      "EVENT_DATA_EXTERNAL_CUSTOMERS":"Client PSA",
      "EVENT_DATA_EXTERNAL_PRODUCTS":"Produit PSA",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"CustomerId",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"ID de l'historique des abonnements",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Identifiant de l'abonnement",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Identifiant du contrat",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"Identifiant de service de contrat",
      "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"Identifiant client externe",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Erreur d'exportation",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Statut d'exportation",
      "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Type de mappage de produit",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Erreur de validation",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Statut de validation",
      "EVENT_DATA_FAILED_TENANT_ID":"Échec de l'ID de locataire",
      "EVENT_DATA_GROUP_DESCRIPTION":"Description du groupe",
      "EVENT_DATA_GROUP_NAME":"Nom du groupe",
      "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"A des détails supplémentaires",
      "EVENT_DATA_IMPERSONATOR":"Personnificateur",
      "EVENT_DATA_INPUT_DATA":"Demande de détails",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Identifiant du client interne",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Identifiant de produit client interne",
      "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Identifiant interne pour le nouveau renouvellement programmé",
      "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Identifiant interne de l'ancien renouvellement programmé",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId de plan source",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId de plan cible",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"Identifiant de produit C3",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Identifiant d'abonnement interne",
      "EVENT_DATA_INVOICE_LINEITEM":"Nom de l'élément de ligne de facture",
      "EVENT_DATA_INVOICE_NUMBER":"Numéro de facture",
      "EVENT_DATA_INVOICE_PROPERTY":"Nom de la propriété",
      "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Ancienne valeur",
      "EVENT_DATA_INVOICE_PROPERTY_VALUE":"Nouvelle valeur",
      "EVENT_DATA_IS_ACCEPTED":"IsAccepted",
      "EVENT_DATA_IS_ENABLED":"IsEnabled",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"IsImmediateProvisioning",
      "EVENT_DATA_IS_SHOW_ON_UI":"Est affiché à l'écran",
      "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
      "EVENT_DATA_LINE_ITEM":"Élément de ligne",
      "EVENT_DATA_LINKED_PRODUCT_NAME":"Nom du produit lié",
      "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Nom du produit lié",
      "EVENT_DATA_LOGGED_IN_USER":"Utilisateur connecté",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Gérer les détails de la licence utilisateur",
      "EVENT_DATA_MESSAGE":"Message",
      "EVENT_DATA_Name":"Nom",
      "EVENT_DATA_NAME":"Nom",
      "EVENT_DATA_NAME_OF_ENTITY":"Nom de l'entité",
      "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Nouvelle valeur",
      "EVENT_DATA_NEW_COUPON_CODE":"Nouveau code promo",
      "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Nouvelle description du coupon",
      "EVENT_DATA_NEW_COUPON_DISCOUNT":"Nouveau bon de réduction",
      "EVENT_DATA_NEW_COUPON_EXPIRESON":"Le nouveau coupon expire le",
      "EVENT_DATA_NEW_COUPON_NAME":"Nouveau nom du coupon",
      "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Nouveau coupon no de récurrences",
      "EVENT_DATA_NEW_COUPON_VALIDTILL":"Nouveau coupon valable jusqu'au",
      "EVENT_DATA_NEW_DESCRIPTION":"Description du nouveau plan",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description de l'offre nouveau distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'offre du nouveau distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_END_DATE_TYPE":"Nouveau type de date de fin",
      "EVENT_DATA_NEW_ENTITY_NAME":"Nouvelle entité",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transféré à",
      "EVENT_DATA_NEW_ISPUBLIC":"Le nouveau plan est public",
      "EVENT_DATA_NEW_MARKUP":"Nouveau balisage",
      "EVENT_DATA_NEW_NAME":"Nom du nouveau plan",
      "EVENT_DATA_NEW_OFFER":"Nouvelle offre",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Description de l'offre du nouveau Partenaire",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Nom de l'offre du nouveau Partenaire",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PLAN":"Nouveau plan",
      "EVENT_DATA_NEW_PRICE":"Nouveau prix",
      "EVENT_DATA_NEW_PRODUCT_MAPPING":"Cartographie des nouveaux produits",
      "EVENT_DATA_NEW_PROMOTION_ID":"Nouvel identifiant de promotion",
      "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Nouvelle date de fin d'effet",
      "EVENT_DATA_NEW_QUANTITY":"Nouvelle quantité",
      "EVENT_DATA_NEW_SEATS":"Nouveaux sièges",
      "EVENT_DATA_NEW_STATUS":"Nouveau statut",
      "EVENT_DATA_NEW_VALIDITY":"Nouvelle validité",
      "EVENT_DATA_NEW_VALIDITY_TERM":"Nouvelle validité",
      "EVENT_DATA_NEW_VALIDITYTYPE":"Nouveau type de validité",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Destinataires de notification",
      "EVENT_DATA_OFFER_NAME":"Nom de l'offre",
      "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Prix de l'offre après remise",
      "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Ancienne valeur",
      "EVENT_DATA_OLD_COUPON_CODE":"Ancien code promo",
      "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Ancienne description du coupon",
      "EVENT_DATA_OLD_COUPON_DISCOUNT":"Ancien bon de réduction",
      "EVENT_DATA_OLD_COUPON_EXPIRESON":"L'ancien coupon expire le",
      "EVENT_DATA_OLD_COUPON_NAME":"Nom de l'ancien coupon",
      "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Ancien coupon n° de récurrences",
      "EVENT_DATA_OLD_COUPON_VALIDTILL":"Ancien coupon valable jusqu'au",
      "EVENT_DATA_OLD_DESCRIPTION":"Description de l'ancien régime",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description de l'offre du distributeur ancien",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'ancienne offre du distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_ENTITY_ID":"Ancienne entité",
      "EVENT_DATA_OLD_ENTITY_NAME":"Ancienne entité",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transféré à partir de",
      "EVENT_DATA_OLD_ISPUBLIC":"L'ancien plan ispublic",
      "EVENT_DATA_OLD_NAME":"Nom de l'ancien plan",
      "EVENT_DATA_OLD_OFFER":"Ancienne offre",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Description de l'ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Nom de l'ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PLAN":"Ancien régime",
      "EVENT_DATA_OLD_PRICE":"Ancien prix",
      "EVENT_DATA_OLD_QUANTITY":"Ancienne quantité",
      "EVENT_DATA_OLD_SEATS":"Anciens sièges",
      "EVENT_DATA_OLD_STATUS":"Ancien statut",
      "EVENT_DATA_OLD_VALIDITY":"Ancienne validité",
      "EVENT_DATA_OLD_VALIDITY_TERM":"Ancienne validité",
      "EVENT_DATA_OLD_VALIDITYTYPE":"Ancien type de validité",
      "EVENT_DATA_PAID_AMOUNT":"Montant payé",
      "EVENT_DATA_PAID_DATE":"Date de paiement",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Identifiant d'abonnement au plan Azure",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Nom de produit parent",
      "EVENT_DATA_PARTNER_NAME":"Nom du partenaire",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Alias de profil de paiement",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"Identifiant de profil de paiement",
      "EVENT_DATA_PAYMENT_TYPE":"Type de paiement",
      "EVENT_DATA_PHONE_NUMBER":"Numéro de téléphone",
      "EVENT_DATA_PLAN_COUNT":"Nombre de forfaits",
      "EVENT_DATA_PLAN_DESCRIPTION":"Description",
      "EVENT_DATA_PLAN_ID":"Identifiant de plan",
      "EVENT_DATA_PLAN_NAME":"Nom du plan",
      "EVENT_DATA_PLAN_PRODUCT_ID":"Identifiant de produit de plan",
      "EVENT_DATA_PLAN_TYPE":"Type de plan",
      "EVENT_DATA_PRICE":"Prix",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Identifiant d'abonnement de produit C3",
      "EVENT_DATA_PRODUCT_MAPPING":"Mappage des produits",
      "EVENT_DATA_PRODUCT_NAME":"Nom de produit",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Nouveau nom de produit",
      "EVENT_DATA_PRODUCT_PRICE":"Prix du produit",
      "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"ID d'abonnement au produit",
      "EVENT_DATA_PROMOTION_ID":"Identifiant promotionnel",
      "EVENT_DATA_PROVIDER":"Fournisseur",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"Identifiant d'entreprise fournisseur",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Nom du client fournisseur",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Nom de l'entité du fournisseur",
      "EVENT_DATA_PROVIDER_ID":"Identifiant de fournisseur",
      "EVENT_DATA_PROVIDER_NAME":"Nom du fournisseur",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Identifiant de profil de paiement du fournisseur",
      "EVENT_DATA_PROVIDER_PRICE":"Prix du fournisseur",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Identifiant d'abonnement Azure",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"Identifiant du revendeur fournisseur",
      "EVENT_DATA_PROVIDER_RESELLER_NAME":"Nom du revendeur du fournisseur",
      "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "EVENT_DATA_PSA_CUSTOMER_NAME":"Client PSA",
      "EVENT_DATA_PSA_LOG_ID":"Identifiant du lot",
      "EVENT_DATA_PSA_NAME":"Nom du PSA",
      "EVENT_DATA_PSA_PRODUCT_NAME":"Produit PSA",
      "EVENT_DATA_QUANTITY":"Quantité",
      "EVENT_DATA_RECORD_ID":"Identifiant d'enregistrement",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"RecordId de l'utilisateur",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"RecordId de l'utilisateur",
      "EVENT_DATA_RECORD_INT_ID":"Identifiant interne d'enregistrement",
      "EVENT_DATA_RECORD_NAME":"Nom de l'enregistrement",
      "EVENT_DATA_RELEASED_SEATS":"Sièges libérés",
      "EVENT_DATA_RENEWS_EXPIRES_ON":"Renouvelle/Expire le",
      "EVENT_DATA_RENEWS_ON":"Se renouvelle le",
      "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Statut de renouvellement automatique du produit C3",
      "EVENT_DATA_REQUESTED_QUANTITY":"Quantité demandée",
      "EVENT_DATA_REQUESTED_STATUS":"Statut demandé",
      "EVENT_DATA_RESELLER_NAME":"Nom du revendeur",
      "EVENT_DATA_RESELLER_NEW_NAME":"Nouveau nom du revendeur",
      "EVENT_DATA_RESELLER_OLD_NAME":"Ancien nom du revendeur",
      "EVENT_DATA_RESERVATION_ORDER_ID":"Numéro de commande de réservation",
      "EVENT_DATA_ROLE":"Rôle",
      "EVENT_DATA_SALE_PRICE":"Prix de vente",
      "EVENT_DATA_SEAT_LIMIT":"Limite de siège",
      "EVENT_DATA_SITE_NAME":"Nom du site",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Nom du département",
      "EVENT_DATA_SOURCE_CURRENCY":"Devise source",
      "EVENT_DATA_SOURCE_OFFER_ID":"Identifiant de l'offre source",
      "EVENT_DATA_SOURCE_PRODUCT_NAME":"Nom du produit source",
      "EVENT_DATA_SOURCE_QUANTITY":"Quantité d'origine",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Cycle de facturation source",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"ID d'abonnement source",
      "EVENT_DATA_SOURCE_VALIDITY":"Validité de la source",
      "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Type de validité de la source",
      "EVENT_DATA_START_BILLING_PERIOD_ID":"Debut de la facturation periodId",
      "EVENT_DATA_START_DATE":"Date de début",
      "EVENT_DATA_STATUS":"Statut",
      "EVENT_DATA_SUB_TAXES":"Sous taxes",
      "EVENT_DATA_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "EVENT_DATA_SUBSCRIPTIONID":"Identifiant de l'abonnement",
      "EVENT_DATA_SUPPORTED_MARKET_CODE":"Code de marché pris en charge",
      "EVENT_DATA_SUPPORTED_MARKETS":"Marchés pris en charge",
      "EVENT_DATA_SYNCED_PRODUCT":"Nombre de produits synchronisés",
      "EVENT_DATA_TARGET_CURRENCY":"Devise cible",
      "EVENT_DATA_TARGET_OFFER_ID":"Identifiant de l'offre cible",
      "EVENT_DATA_TARGET_PRODUCT_NAME":"Nom du produit cible",
      "EVENT_DATA_TARGET_QUANTITY":"Quantité cible",
      "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Cycle de facturation cible",
      "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"ID d'abonnement cible",
      "EVENT_DATA_TARGET_VALIDITY":"Validité cible",
      "EVENT_DATA_TARGET_VALIDITY_TYPE":"Type de validité cible",
      "EVENT_DATA_TAX_CODE":"Code fiscal",
      "EVENT_DATA_TAX_NAME":"Nom de taxe",
      "EVENT_DATA_TAX_PENCENTAGE":"Taxe PeNCEntage",
      "EVENT_DATA_TAX_PERCENTAGE":"Pourcentage de taxe",
      "EVENT_DATA_TENANT_ID":"Identifiant de domaine",
      "EVENT_DATA_TENANT_NAME":"Nom de domaine",
      "EVENT_DATA_TO_RECIPIENTS":"Aux destinataires",
      "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Nombre de produits concernés",
      "EVENT_DATA_TOTAL_RELEASED_SEATS":"Nombre de sièges concernés",
      "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Montant total de la transaction",
      "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"Limite du montant de la transaction",
      "EVENT_DATA_TRANSITION_TYPE":"Type de transition",
      "EVENT_DATA_UNSYNCED_PRODUCT":"Nombre de produits non synchronisés",
      "EVENT_DATA_UPDATED_BUNDLE_NAME":"Nom du bouquet mis à jour",
      "EVENT_DATA_UPDATED_COUPON_NAME":"Nom du coupon mis à jour",
      "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'offre du distributeur mis à jour",
      "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Nom de l'offre Partenaire mis à jour",
      "EVENT_DATA_UPDATED_PLAN_NAME":"Nom du plan mis à jour",
      "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Modification de la cartographie des produits",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Emplacement d'utilisation",
      "EVENT_DATA_USER_DISPLAY_NAME":"Nom d'affichage",
      "EVENT_DATA_USER_EMAIL":"Courriel de l'utilisateur",
      "EVENT_DATA_USER_TYPE":"Type d'utilisateur",
      "EVENT_DATA_USERNAME":"Nom d'utilisateur",
      "EVENT_DATA_VALIDATION_ERROR":"Erreur de validation",
      "EVENT_DATA_VALIDATION_STATUS":"Statut de validation",
      "EVENT_DATA_VALIDITY":"Valide",
      "EVENT_DATA_VALIDITY_TYPE":"Type de validité",
      "EVENT_DATA_VALUE":"Valeur",
      "EVENT_DATA_VOUCHER_ENTITY":"Entité du bon",
      "EVENT_DATA_VOUCHER_IDS":"ID de bon",
      "EVENT_DATA_VOUCHERS":"Pièces justificatives",
      "EVENT_DATE":"Date",
      "EVENT_DEPARTMENT_DESCRIPTION":"Description du département",
      "EVENT_DEPARTMENT_ID":"Identifiant de département",
      "EVENT_DEPARTMENT_NAME":"Nom du département",
      "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Accepter ou refuser le consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Termes et conditions NCE acceptés pour commander des produits Microsoft NCE",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Conditions générales acceptées",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Échec de l'acceptation des conditions générales",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Activer l'abonnement DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Activer l'abonnement DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Activer le seuil de danger atteint en cas d'échec de l'abonnement",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Activer l'offre personnalisée d'approvisionnement",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Échec d'activation de l'offre personnalisée d'approvisionnement",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Échec de l'activation de l'abonnement",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Réussite de l'activation de l'abonnement",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Impossible de créer le coupon",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"Échec de l'ajout de client C3",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Réussite de l'ajout de client C3",
      "EVENT_DESC_ADD_ENTITLEMENTS":"Ajouter un droit",
      "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Échec de l'ajout d'un droit",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Ajouter un administrateur de groupe",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Échec de l'ajout d'un administrateur de groupe",
      "EVENT_DESC_ADJUST_Azure_MARGIN":"Ajuster la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN":"Ajuster la marge Azure",
      "EVENT_DESC_ADJUST_Azure_MARGIN_FAILED":"Échec de l'ajustement de la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"Échec de l'ajustement de la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Ajuster la marge Azure",
      "EVENT_DESC_APPROVED_BY":"Approuvé par",
      "EVENT_DESC_APPROVED_ON":"Approuvé le",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Modifications du prochain renouvellement du calendrier approuvées ou rejetées",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Modifications du prochain renouvellement du calendrier approuvées ou rejetées",
      "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Demande d'achat approuvée ou rejetée",
      "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Demande d'achat approuvée",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Attribuer un plan au client",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"Échec de l'attribution du plan au client",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Échec de l'attribution des bons manqués",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Coupons manqués attribués avec succès",
      "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin obtient automatiquement l'accès aux clients",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Abonnements de produits clients traités automatiquement",
      "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Libération automatique des sièges inutilisés",
      "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Non-concordances corrigées grâce à la synchronisation automatique",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Cycle de facturation modifié avec succès (Synchronisation Automatique)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"La modification du cycle de facturation a échoué (Auto Sync)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Cycle de facturation modifié avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Échec de la modification du cycle de facturation (Synchronisation Manuelle)",
      "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Résumé des lots intégrés en masse",
      "EVENT_DESC_BULK_USER_UPLOAD":"Chargement massif d'utilisateurs",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Échec du chargement massif d'utilisateurs",
      "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Les offres bouquet sont achetées",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Bon de commande de produits groupés",
      "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Annulez le succès du renouvellement planifié.",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Demande d'achat d'abonnement annulé ",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Échec de la demande d'annulation d'achat d'abonnement",
      "EVENT_DESC_CART_AVAILABILITY_STATUS":"Statut de disponibilité du panier",
      "EVENT_DESC_CART_CHECKOUT":"Passage à la caisse",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Échec du passage à la caisse",
      "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Notifier que la limite de places a été dépassée",
      "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Notifier que la limite de transaction a été dépassée",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Statut de Renouvellement Automatique du produit client modifié",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"Le statut de Renouvellement Automatique du produit client n'a pas pu être modifié",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Statut du produit client mis à jour avec succès",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Échec de la modification du statut du produit",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"Status ChangeInvoice",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Échec de la modification du statut de la facture",
      "EVENT_DESC_CHANGE_PLAN_FAILURE":"Échec du plan de modification",
      "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Réussite du plan de changement",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Échec de la conversion de devise clone",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Succès de la conversion de devise de clonage",
      "EVENT_DESC_CLONE_PLAN":"Plan dupliqué avec succès",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"Impossible de cloner le plan",
      "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"Le coupon s'applique à tous les clients marqués ?",
      "EVENT_DESC_COUPON_NAME":"Nom du coupon",
      "EVENT_DESC_COUPON_STATUS":"Statut du coupon",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Créer des groupes Azure",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Échec de la création des groupes Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Créer un abonnement Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Échec de la création de l'abonnement Azure",
      "EVENT_DESC_CREATE_CART":"Créer un panier",
      "EVENT_DESC_CREATE_CART_FAILURE":"Échec de la création du panier",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Échec de la création d'un client",
      "EVENT_DESC_CREATE_INVOICE":"Créer une facture",
      "EVENT_DESC_CREATE_ORDER":"Créer une commande",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Échec de la création d'une commande",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Créer un profil de paiement",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Échec de la création du profil de paiement",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"Échec de la création d'un abonnement aux instances réservées par synchronisation",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Abonnement aux instances réservées créé avec succès par synchronisation",
      "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Créer un succès de renouvellement planifié",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Créer un contrôle d'expiration d'abonnement",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Échec de l'expiration de la création de l'abonnement",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Abonnement créé avec succès (Auto Sync)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Échec de la création de l'abonnement (Synchronisation Automatique)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Ignoré la création d'un abonnement à partir de la Synchronisation Automatique",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Abonnement créé avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"La création de l'abonnement a échoué (Synchronisation Manuelle)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Ignoré la création de l'abonnement à partir de la Synchronisation Manuelle",
      "EVENT_DESC_CREATE_TAX_RULE":"Créer une règle fiscale",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Échec de la création d'une règle fiscale",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Créer un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Échec de la création d'un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_CREATE_USER":"Créer un utilisateur",
      "EVENT_DESC_CREATE_USER_FAILURE":"Échec de la création d'un utilisateur",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Échec du consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Création de clients via API",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Création de client via un échec d'API",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Échec de la suppression du client",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Succès de la suppression du client",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Facture client générée",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du client",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Mise à jour du nom du client réussie",
      "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"La limite de sièges client dépasse la notification",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"Désactiver le journal PSA",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"Échec de la désactivation du journal PSA",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Définir la conversion de devises",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Échec de la définition de conversion de devises",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Définir les prix du plan dans une nouvelle devise",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Échec de la définition des prix du plan dans une nouvelle devise",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Dissocier le client de facturation",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Échec de dissociation du client de facturation",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Supprimer le succès du gestionnaire de compte",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Supprimer l'échec du gestionnaire de compte",
      "EVENT_DESC_DELETE_CART_ITEM":"Article de panier supprimé",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Échec de la suppression de l'article de panier",
      "EVENT_DESC_DELETE_CONTRACT_OFFER":"Offre de contrat supprimée avec succès",
      "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Échec de la suppression de l'offre de contrat",
      "EVENT_DESC_DELETE_COUPON":"Coupon désactivé avec succès",
      "EVENT_DESC_DELETE_COUPON_FAILED":"La suppression du coupon a échoué.",
      "EVENT_DESC_DELETE_COUPON_FAILURE":"Échec de la désactivation du coupon",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Supprimer la conversion de devises",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Échec de la suppression de conversion de devises",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Impossible de supprimer la conversion de devises",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Supprimer l'échec de la notification personnalisée",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Supprimer le succès de la notification personnalisée",
      "EVENT_DESC_DELETE_CUSTOM_OFFER":"Offre personnalisée supprimée avec succès",
      "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Échec de la suppression de l'offre personnalisée",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Supprimer le paiement du client",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Impossible de supprimer le paiement client",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Échec de la suppression du département",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Suppression du département réussie",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Offre de distributeur supprimée avec succès",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Échec de la suppression de l'offre du distributeur",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Supprimer la notification par courriel de l'événement",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la suppression de la notification par courriel de l'événement",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Supprimer l'ajustement de la facture",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Échec de la suppression de l'ajustement de la facture",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Supprimer un poste de factures",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Échec de la suppression de poste de factures",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Échec de la suppression de l'utilisateur partenaire",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Supprimer une option de paiement",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Échec de la suppression de l'option de paiement",
      "EVENT_DESC_DELETE_PLAN":"Supprimer un plan",
      "EVENT_DESC_DELETE_PLAN_FAILURE":"Échec de la suppression du plan",
      "EVENT_DESC_DELETE_QUOTE_FAILED":"La suppression de la citation a échoué",
      "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Supprimer la citation avec succès",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Échec de la suppression du site",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Site supprimé avec succès",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Supprimer la vérification de l'expiration de l'abonnement",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Échec de la suppression de l'expiration de l'abonnement",
      "EVENT_DESC_DELETE_TAX_RULE":"Supprimer une règle fiscale",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Échec de la suppression d'une règle fiscale",
      "EVENT_DESC_DELETE_UPGRADE_AzureSUBSCRIPTION_FAILURE":"Échec de la mise à niveau de l'abonnement Azure",
      "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Échec de la mise à niveau de l'abonnement Azure",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Supprimer un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Échec de la suppression de l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_DELETE_USER":"Supprimer un utilisateur",
      "EVENT_DESC_DELETE_USER_FAILURE":"Échec de la suppression de l'utilisateur",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Échec de la notification de suppression du webhook",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Supprimer le succès de la notification du webhook",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Désactiver un abonnement Azure",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Échec de la désactivation de l'attribution du coupon",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de coupon désactivée",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Désactiver le plan pour le client",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"Échec de la désactivation du plan pour le client",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Rapport sur les éléments de ligne de facture planifiés",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Éléments de ligne de facture planifiés avec rapport de commission",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Rapport récapitulatif des factures programmées",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Rapport de rentabilité programmé",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Rapport sur les éléments de campagne de taxe planifiés",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Rapport récapitulatif des taxes planifié",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Activer l'abonnement Azure",
      "EVENT_DESC_ENABLE_COUPON":"Coupon activé avec succès",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Échec de l'activation de l'attribution du coupon",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de coupon activée",
      "EVENT_DESC_ENABLE_COUPON_FAILED":"L'activation du coupon a échoué.",
      "EVENT_DESC_ENABLE_COUPON_FAILURE":"Échec de l'activation du coupon",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Affecter un plan au client",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Impossible d'affecter le plan au client",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Activer la notification pour l'expiration de l'abonnement annuel",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Activer la notification lors du renouvellement d'un abonnement mensuel",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Échec de mise en file d'attente de la demande d'intégration massive des clients",
      "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Demande en file d'attente pour cloner le plan revendeur",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Rapport de notification du journal des erreurs",
      "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Échec du renouvellement programmé de la mise à jour",
      "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Exporter le rapport de licence client.",
      "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Exporter le rapport d'attribution de licence utilisateur",
      "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"Échec de l'approbation ou du rejet de la prochaine demande de renouvellement programmée",
      "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Échec de l'annulation du renouvellement programmé",
      "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"Échec du clonage du forfait revendeur",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"Échec de la connexion au logiciel de comptabilité",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"Échec de la connexion au Centre des partenaires Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"Échec de la connexion à l'API de tarification Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"Échec de la connexion à PSA",
      "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"Échec de la création de la facture à la demande",
      "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Échec de la création du renouvellement programmé",
      "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"Échec de la mise en file d'attente de la demande de clonage du forfait revendeur",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Impossible de définir le statut du lot d'embarquement massif à Terminé",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Impossible de définir le statut du lot d'affectation de la licence utilisateur pour terminer",
      "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"Échec de la synchronisation du profil client et fournisseur",
      "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"Échec de la mise à jour de la facture à la demande",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Correction de l'incompatibilité d'état de renouvellement automatique automatiquement",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Correction de l'inadéquation de l'état de renouvellement automatique a automatiquement échoué",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Correction manuelle de l'incompatibilité d'état de renouvellement automatique",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Correction de l'incompatibilité d'état de renouvellement automatique a échoué manuellement",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Correction de l'incompatibilité de promotion avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'incohérence de la promotion (Synchronisation Automatique)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Corriger manuellement l'incohérence des promotions",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Échec de la correction manuelle de l'incompatibilité de promotion",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Erreur de quantité corrigée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'inadéquation de la quantité (Synchronisation Automatique)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Erreur de quantité corrigée avec succès (Synchronisation manuelle)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Échec de la correction de l'inadéquation de la quantité (Synchronisation Manuelle)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Correction de l'incompatibilité d'état avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'incompatibilité d'état (Synchronisation Automatique)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Correction de l'incompatibilité de statut avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"Échec de la correction de l'incompatibilité d'état (Synchronisation Manuelle)",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Écart de consommation de licence",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Échec de l'autorisation de l'accès au portail",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Autoriser l'accès au portail",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Ignorer les erreurs de chargement en masse des utilisateurs",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Impossible d'ignorer les erreurs de chargement en masse des utilisateurs",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Ignorer les erreurs d'achat d'abonnements",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Ignorer les erreurs de mise à jour d'abonnements",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Impossible d'ignorer les erreurs de mise à jour d'abonnement",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Impossible d'ignorer l'erreur dans l'achat d'abonnement",
      "EVENT_DESC_INVOICE_AND_REPORT":"Facture et rapport",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Paiement de facture initié",
      "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Facture et rapport de cotisation",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Échec de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Échec de l'association du client fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Échec de l'association du client non-csp fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Réussite de l'association du client non-csp fournisseur de services",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Réussite de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Échec de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Échec de l'association du client fournisseur de services",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de services",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"La gestion des licences utilisateurs a échoué.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"La gestion des licences utilisateurs a réussi.",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Planifier les prochaines modifications de renouvellement",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"Échec de la planification des prochaines modifications de renouvellement",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Demande d'achat de produit de partenaire NI acceptée",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Demande d'achat de produit de partenaire NI annulée",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"Produit de partenaire Ni acheté",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"Quantité de produit de partenaire NI mise à jour",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Statut d'activation de la notification d'achat de produits de provisionnement NI",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Statut de désactivation de la notification d'achat de produits de provisionnement NI",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Offre modifiée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Le changement d'offre a échoué (Auto Sync)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Offre modifiée avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Le changement d'offre a échoué (Synchronisation Manuelle)",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Les offres sont achetées",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Plan Azure intégré avec succès (Auto Sync)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_AUTO_SYNC":"Plan Azure intégré avec succès (Synchronisation Automatique)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_AUTO_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation automatique)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation automatique)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_MANUAL_SYNC":"Plan Azure intégré avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Plan Azure intégré avec succès (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_MANUAL_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Échec de l'intégration du client",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Échec de l'intégration du client",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Intégration du client effectuée avec succès",
      "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Le partenaire propose un rapport exportable",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Échec de l'ajout de l'utilisateur partenaire",
      "EVENT_DESC_PARTNER_USERADDED":"Utilisateur partenaire ajouté",
      "EVENT_DESC_PASSWORD_EXPIRED":"Mot de passe expiré",
      "EVENT_DESC_PAYMENT_FAILURE":"Défaut de paiement",
      "EVENT_DESC_PAYMENT_SUCCESS":"Paiement effectué avec succès",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Échec de la saisie du consentement de sécurité du centre partenaire",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Consentement de sécurité du centre partenaire saisi avec succès",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip créé avec succès pour toutes les factures.",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Une erreur s'est produite lors de la création de Zip pour toutes les factures.",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Affectation de produit",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Échec de l'affectation de produit",
      "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Promotions chargées avec succès",
      "EVENT_DESC_PROMOTION_LOADING_FAILED":"Échec du chargement des promotions",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Prix de produits de fournisseur mis à jour",
      "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Demande d'aide à l'inscription publique",
      "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"L'achat du bouquet a échoué",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Acheter une nouvelle offre partenaire",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Échec de l'achat d'une nouvelle offre personnalisée",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Acheter un produit NI Provisioning",
      "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Demande d'achat en attente d'approbation",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Acheter des abonnements",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Échec de l'achat des abonnements",
      "EVENT_DESC_QUOTATION_CUSTOMER":"Devis client",
      "EVENT_DESC_QUOTATION_NAME":"Nom de la citation",
      "EVENT_DESC_QUOTATION_OWNER":"Propriétaire de devis",
      "EVENT_DESC_QUOTE_APPROVAL":"Approbation du devis",
      "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Conversion de devis réussie",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Demande de mise à jour du produit acheté",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"Échec de la demande de mise à jour du produit acheté",
      "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Non-concordance de reconnaissance lors de la génération de facture initiale",
      "EVENT_DESC_RECORD_PAYMENT":"Enregistrer le paiement",
      "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Enregistrer l'échec du paiement",
      "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Demande d'achat rejetée",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Conditions générales rejetées",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Échec du rejet des conditions générales",
      "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du revendeur",
      "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Succès de la mise à jour du nom du revendeur",
      "EVENT_DESC_RESET_USER_PASSWORD":"Réinitialiser le mot de passe utilisateur",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Échec de la réinitialisation du mot de passe utilisateur",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Rétablir le droit au prix réussi",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Échec du rétablissement du prix d'attribution",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Rétablir TenantConfiguration",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Échec du rétablissement de TenantConfiguration",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Révoquer l'accès au portail",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Échec de la révoquation de l'accès au portail",
      "EVENT_DESC_SAVE_COMMENT":"Nouveau commentaire ajouté",
      "EVENT_DESC_SAVE_CONTRACT_OFFER":"Offre de contrat sauvgardé avec succès",
      "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"La sauvegarde de l'offre de contrat a échoué",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Enregistrer l'échec de la notification personnalisée",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Enregistrer le succès des notifications personnalisées",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Enregistrer l'offre personnalisée",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Échec de l'enregistrement de l'offre personnalisée",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Impossible d'enregistrer le profil de facturation du client",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Enregistrer le profil de facturation du client",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Enregistrer le client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Échec de l'enregistrement du client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Échec de l'enregistrement du client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Enregistrer le profil de paiement du client",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Échec de l'enregistrement du profil de paiement du client",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Enregistrer l'utilisateur client",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Échec de l'enregistrement de l'utilisateur client",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Échec de l'enregistrement du département",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Département enregistré avec succès",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Enregistrer l'offre du distributeur",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Enregistrer l'échec de l'offre du distributeur",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Enregistrer le droit Prix Succès",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Enregistrer le droit d'accès Échec du prix",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Notification par courriel de l'enregistrement de l'événement",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la notification par courriel de l'enregistrement de l'événement",
      "EVENT_DESC_SAVE_INVITES":"Enregistrer les invitations",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Échec de l'enregistrement des invitations",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Enregistrer les ajustements de facture",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Échec de l'enregistrement des ajustements de facture",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Enregistrer le forfait partenaire",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Échec de l'enregistrement du forfait de partenaire",
      "EVENT_DESC_SAVE_PARTNER_USER":"Enregistrer l'utilisateur partenaire",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Échec de l'enregistrement de l'utilisateur partenaire",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Enregistrer le profil de paiement",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Échec de l'enregistrement du profil de paiement",
      "EVENT_DESC_SAVE_PLAN":"Enregistrer le plan",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Échec de l'enregistrement du plan",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Enregistrer les produits du plan dans la devise cible",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Échec de l'enregistrement des produits du plan dans la devise cible",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Consentement du client fournisseur enregistré",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Échec de l'enregistrement du consentement du client fournisseur",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Enregistrer le paiement du fournisseur",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Échec de l'enregistrement du paiement du fournisseur",
      "EVENT_DESC_SAVE_QUOTE_FAILED":"Impossible d'enregistrer le devis",
      "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Citation sauvegardée avec succès",
      "EVENT_DESC_SAVE_RESELLER":"Enregistrer le revendeur",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"Échec de l'enregistrement du revendeur",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Échec de l'enregistrement du site",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Site enregistré avec succès",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Échec de l'enregistrement de la configuration utilisateur",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Enregistrer le succès de la configuration utilisateur",
      "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Sauvegarder la notification de message Web",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Échec de la notification du webhook d’enregistrement",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Enregistrer le succès de la notification du webhook",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Acceptation des conditions générales échouée",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Conditions générales acceptées avec succès",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"La tentative de mise en place des licences à ajouter/supprimer a échoué.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Mise en place des licences à ajouter/supprimer effectuée avec succès.",
      "EVENT_DESC_SCHEDULE_ORDER":"Planifier la commande",
      "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Échec de la commande planifiée",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Rapport sur les éléments de ligne de facture planifiés",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Éléments de ligne de facture planifiés avec rapport de commission",
      "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Rapport récapitulatif des factures programmées",
      "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Rapport de rentabilité programmé",
      "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Rapport sur les éléments de campagne de taxe planifiés",
      "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Rapport récapitulatif des taxes planifié",
      "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Envoyer la mise à jour de l'offre du distributeur",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Envoyer la facture PDF au client depuis l'interface utilisateur",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Envoyer la facture PDF à la transparence des clients",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Envoyer des alertes de seuil d'utilisation de l'abonnement",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Alertes de seuil d’utilisation d’abonnement ayant échoué",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Définir le statut du lot d'affectation de la licence utilisateur pour terminer",
      "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"La connectivité SMTP a échoué",
      "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notification d'expiration d'abonnement pour l'offre d'essai",
      "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Rapport sur l'historique des abonnements",
      "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Suspendre lorsque le seuil de danger est atteint",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Synchronisation de l'abonnement",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Échec de synchronisation de l'abonnement",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonnement mis à jour",
      "EVENT_DESC_SUCCESS":"Réussite ",
      "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"La prochaine demande de renouvellement programmée a été approuvée ou rejetée avec succès",
      "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Plan revendeur cloné avec succès",
      "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"Facture à la demande créée avec succès",
      "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Profil client et fournisseur synchronisé avec succès",
      "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"Facture à la demande mise à jour avec succès",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Échec de la suspension de l'abonnement",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Suspendre le succès de l'abonnement",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Suspendre lorsque le seuil de danger est atteint",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Suspendre lorsque le seuil de danger atteint l'échec",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"impossible de synchroniser le consentement du client fournisseur",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Consentement client fournisseur synchronisé",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Échec de la synchronisation des abonnements sur PC",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Synchronisation des abonnements sur PC",
      "EVENT_DESC_TAGS_ADDED":"Balises ajoutées",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Échec de l'ajout de balises",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Échec de la suppression de balises",
      "EVENT_DESC_TAGS_DELETED":"Balises supprimées",
      "EVENT_DESC_TAGS_EDITED":"Balises modifiées",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Échec de la modification de balises",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"La durée du terme a été modifiée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Échec de la modification de la durée du trimestre (Synchronisation Automatique)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"La durée du mandat a été modifiée avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Échec de la modification de la durée du trimestre (Synchronisation Manuelle)",
      "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Détails du produit non synchronisés",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Mettre à jour les groupes Azure",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Échec de la mise à jour des groupes Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Mettre à jour l'abonnement Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Échec de la mise à jour de l'abonnement Azure",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Offre de contrat mise à jour avec succès",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"La mise à jour de l'offre de contrat a échoué",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Mettre à jour le cycle de facturation personnalisé",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"Échec de la mise à jour du cycle de facturation personnalisé",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Échec de la mise à jour de la notification personnalisée",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Mettre à jour le succès de la notification personnalisée",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Mettre à jour la quantité d'offres partenaires",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Échec de la mise à jour de quantité d'offres partenaires",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Option de paiement par défaut mise à jour",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Impossible de mettre à jour l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Mettre à jour du profil de paiement du client",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Échec de la mise à jour du profil de paiement du client",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Profil client mis à jour",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Échec de la mise à jour du profil client",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Mettre à jour l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Échec de la mise à jour de l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Mettre à jour la notification par courriel de l'événement",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la mise à jour de la notification par courriel de l'événement",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Mettre à jour l'administrateur du groupe",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Échec de la mise à jour de l'administrateur du groupe",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":"Échec de la mise à jour des propriétés de la facture",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Les propriétés de la facture ont été mises à jour avec succès",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Mettre à jour l'indicateur de publication automatique",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"Échec de la mise à jour de l'indicateur de libération automatique",
      "EVENT_DESC_UPDATE_LICENSE":"Mettre à jour la licence",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Échec de la mise à jour de la licence",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Échec de la mise à jour des paramètres du centre partenaire",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Mettre à jour les paramètres généraux du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres généraux du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"Mettre à jour le logo du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Échec de la mise à jour du logo du partenaire",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Mettre à jour les paramètres du centre partenaire",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Mettre à jour le prix de l'offre de plan",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Échec de la mise à jour du prix de l'offre de plan",
      "EVENT_DESC_UPDATE_PROFILE":"Mettre à jour le profil",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Échec de la mise à jour du profil",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Mettre à jour les paramètres du fournisseur",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres du fournisseur",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Attributs de produit mis à jour",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Impossible de mettre à jour les attributs du produit",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Mettre à jour la quantité de produits de provisionnement NI",
      "EVENT_DESC_UPDATE_QUOTE_FAILED":"Impossible de mettre à jour le devis",
      "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Citation mise à jour avec succès",
      "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Mettre à jour le succès du renouvellement planifié",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valeur par défaut du client fournisseur de services mise à jour",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Échec de la mise à jour de la valeur par défaut du client fournisseur de services",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Mettre à jour les paramètres SMTP",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres SMTP",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Mise à jour de la vérification de l'expiration de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"La vérification de l'expiration de l'abonnement a échoué",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Échec de la mise à jour de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":"Abonnement à la mise à jour de l'API",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Échec de l'abonnement à la mise à jour de l'API",
      "EVENT_DESC_UPDATE_SUBTAX":"Mettre à jour la sous-taxe",
      "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Échec de la mise à jour de la sous-taxe",
      "EVENT_DESC_UPDATE_TAX_RULE":"Mettre à jour la règle fiscale",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Échec de la mise à jour de la règle fiscale",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Mettre à jour la configuration du domaine",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Échec de la mise à jour de la configuration du domaine",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Mettre à jour l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Échec de la mise à jour de l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_UPDATE_USER":"Mettre à jour l'utilisateur",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Échec de la mise à jour de l'utilisateur",
      "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Mettre à jour la notification WebMessage",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Échec de la notification du webhook de mise à jour",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Mise à jour réussie de la notification du webhook",
      "EVENT_DESC_UPGRADE_AzureSUBSCRIPTION":"Mettre à niveau l'abonnement Azure",
      "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Mettre à niveau l'abonnement Azure",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"Échec de l'attribution du bon",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Bon attribué avec succès",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Échec du mappage de produits en masse",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"Cartographie des produits en masse réussie",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Le processus de rafraîchissement des entités de services externes dans C3 est lancé",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" Le processus d'actualisation pour les entités de service externes dans C3 a échoué",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" Le processus d'actualisation pour les entités de service externes dans C3 a réussi",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Mise à niveau de l'abonnement",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Échec de la mise à niveau de l'abonnement",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"Échec du téléchargement du catalogue client.",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Catalogue client téléchargé avec succès.",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Télécharger le catalogue de produits",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Échec du téléchargement du catalogue de produits",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Télécharger le catalogue des commissions sur les produits",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Télécharger le catalogue des commissions sur les produits",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"Échec du téléchargement du catalogue d'utilisation des rapports",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Télécharger le catalogue d'utilisation des rapports avec succès",
      "EVENT_ENTITLEMENT_ID":"ID d'autorisation",
      "EVENT_ENTITLEMENT_NAME":"Nom de l'entité",
      "EVENT_FILE_NAME":"Nom de fichier",
      "EVENT_IMPERSONATOR":"Personnificateur",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Échec de l’importation de plusieurs paiements",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Succès de l’importation de plusieurs paiements",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Échec de l'importation des données validées",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Données validées importées avec succès",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Échec des commissions sur les produits d'importation",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Réussite des commissions sur les produits d'importation",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Échec de l'importation des extensions de produit",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Réussite de l'importation des extensions de produit",
      "EVENT_IMPORT_VOUCHER_FAILURE":"Échec de l'importation des bons.",
      "EVENT_IMPORT_VOUCHER_SUCCESS":" Les bons ont été importés avec succès.",
      "EVENT_IS_LICENSE_ASSIGNED":"La licence est attribuée",
      "EVENT_IS_LICENSE_REMOVED":"La licence est supprimée",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Lier un revendeur",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Impossible de lier le revendeur",
      "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"Échec du mappage d'entités PSA",
      "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"Mappage réussi de l'entité C3 vers l'entité dans PSA",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"La suppression du mappage avec l'entité PSA a échoué",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Suppression réussie du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"Échec du mappage du produit PSA",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Mappage réussi des produits C3 aux produits dans PSA",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"Échec de la suppression du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Suppression réussie du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Suppression réussie du mappage avec le produit PSA",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"La suppression du mappage avec le produit PSA a échoué",
      "EVENT_NEW_CAN_PRICE_LAG":"NewCanPriceLag",
      "EVENT_NEW_CAN_PRICE_LEAD":"NouveauCanPriceLead",
      "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Nouvelle limite de sièges au niveau du plan",
      "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Notificateur d'erreur d'enque à la prochaine exécution",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Veuillez sélectionner au moins un type de bénéficiaire.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Aperçu d'email",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"Notification par email supprimée avec succès.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"CCI",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Activé",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Type de destinataire",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Destinataires",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"À",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"Paramètres de notification par email sauvegardés avec succès.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Veuillez cliquer ici",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"pour voir le contenu du modèle d'email.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"Destinataires CCI",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"Destinataires CC",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Événement",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Nom de l'événement",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Activé ?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"Aux destinataires",
      "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Notifier la création du client via l'inscription au client",
      "EVENT_Notify_Purchase_Limit_Error":"Notifier une erreur de limite d'achat",
      "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Notifier une erreur de limite d'achat",
      "EVENT_OLD_CAN_PRICE_LAG":"AncienCanPriceLag",
      "EVENT_OLD_CAN_PRICE_LEAD":"OldCanPriceLead",
      "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Limite de sièges au niveau de l'ancien plan",
      "EVENT_PRODUCT_NAME":"Nom du produit",
      "EVENT_PURCHASE_APPROVE_REASON":"Raison",
      "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Raison, si échec",
      "EVENT_PURCHASE_PRODUCT_ID":"Id Produit",
      "EVENT_PURCHASE_PRODUCT_NAME":"Nom de produit",
      "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Planifier l'identifiant du produit",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"Quantité",
      "EVENT_PURCHASE_REASON_ONHOLD":"Raison de la mise en attente",
      "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Envoyer les factures PDF au partenaire",
      "EVENT_SITE_DESCRIPTION":"Description du site",
      "EVENT_SITE_ID":"Identifiant du site",
      "EVENT_SITE_NAME":"Nom du site",
      "EVENT_SMTP_TEST_EMAIL":"E-mail de test SMTP",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Organiser et valider l'échec des paiements à l'importation",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Organiser et valider le succès des paiements à l'importation",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Échec de la préparation et de la validation du rapport d'utilisation du Partenaire",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Organisez et valider le rapport d'utilisation des Partenaires",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Organisez et valider l'échec du catalogue de produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Organisez et valider le succès du catalogue de produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Organisez et valider la validation des commissions sur les produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Organisez et validez le succès des commissions sur les produits",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"Échec de la préparation et de la validation du catalogue de bons.",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Catalogue de bons d’achat mis en scène et validé avec succès.",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Notification d'expiration de l'abonnement",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Notification d'expiration d'abonnement pour le client",
      "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notification d'expiration d'abonnement pour l'offre d'essai",
      "EVENT_TOTAL_RECORD_COUNT":"Nombre d'enregistrements",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Dissocier le revendeur",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Impossible de dissocier le revendeur",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Mise à jour des détails du produit d'utilisation du Partenaire",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Échec de la mise à jour des détails du produit d'utilisation du Partenaire",
      "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Notification d'offre de plan de mise à jour pour le revendeur",
      "EVENT_USER_CONFIGURATION_NAME":"Nom de la configuration utilisateur",
      "EVENT_USER_CONFIGURATION_VALUE":"Valeur de configuration utilisateur",
      "EVENT_USER_EMAILID ":"ID de messagerie de l'utilisateur",
      "EVENT_VOUCHER_ASSIGNMENT":"Attribution de bons",
      "EVENT_WEBJOB_STATUS_NOTIFIER":"Notificateur d'état de tâche Web",
      "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Nouvelle description de bouquet",
      "EVNET_DATA_NEW_BUNDLE_NAME":"Nouveau nom de bouquet",
      "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Ancienne description du lot",
      "EVNET_DATA_OLD_BUNDLE_NAME":"Ancien nom du bouquet",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Demande de revendeurs intégrés en vrac acceptée",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"L'acceptation de la demande groupée des revendeurs intégrés a échoué",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"L'intégration du revendeur dans le cadre de l'intégration groupée a échoué",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"L'intégration du revendeur dans le cadre de l'intégration groupée s'est terminée avec succès",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"L'intégration groupée des revendeurs a échoué",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Intégration groupée des revendeurs réussie",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Ajout en vrac de droits terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Échec de l'ajout en vrac de droits",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Demande d'ajout en vrac de droits acceptée",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"Échec de la demande d'ajout de droits en vrac",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Processus d'ajout en vrac de locataires terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"Échec du processus d'ajout de locataires en vrac",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Demande d'ajout en vrac de locataires acceptée",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"Échec de la demande d'ajout en vrac de locataires",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Processus d'intégration groupée des revendeurs terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Échec du processus d'intégration groupée des revendeurs",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Processus d'intégration groupée des revendeurs lancé",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Suppression de la relation revendeur terminée",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Échec de la suppression de la relation revendeur",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Échec de la demande de suppression de la relation avec le revendeur",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Supprimer la demande de locataire de la relation revendeur acceptée",
      "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Une erreur s'est produite lors de la nouvelle tentative d'envoi d'une notification webhook",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement ou de la mise à jour des détails de la notification personnalisée",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Erreur lors de l'enregistrement des détails de la notification du webhook",
      "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Une erreur s'est produite lors de la tentative de création d'un panier pour l'offre principale à partir de l'offre d'essai.",
      "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Erreur lors de la mise à jour des données intermédiaires pour les détails de la notification",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Une erreur s'est produite lors de l'envoi de la notification du webhook",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"L'URL du webhook fournie n'est pas valide",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Il n'y a pas de produits mappés pour la modification du cycle de facturation. Veuillez contacter le soutien technique.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Une exception s'est produite lors de la création d'un nouveau client fournisseur puisque l'acceptation du Contrat de client fournisseur n'est pas confirmée par le partenaire",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"L'acceptation de l'accord client du fournisseur doit être confirmée",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Une erreur s'est produite lors de la vérification de l'acceptation des conditions générales du partenaire par l'utilisateur",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Une erreur s'est produite lors de la récupération du contenu des conditions générales du partenaire",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Une erreur s'est produite lors de la récupération des détails d'acceptation du consentement du client fournisseur",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Une erreur s'est produite lors de la récupération des clients fournisseurs qui n'ont pas fourni le consentement du client fournisseur",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Une erreur s'est produite lors de l'enregistrement des détails d'acceptation des conditions générales du partenaire",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Une erreur s'est produite lors de l'enregistrement du consentement du client fournisseur",
      "EXISTANT":"Existant",
      "EXISTING":"Existant",
      "EXISTING_REFRESH_TOKEN":"Jeton d'actualisation existant",
      "EXPAND_ALL_PAYMENTS":"Développer les paiements",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir supprimer cet enregistrement?",
      "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"Le chèque d'expiration a été supprimé avec succès",
      "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Les chèques d'expiration ont été enregistrés avec succès",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Fermer les filtres",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Nom du client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez Client ou Revendeur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Recherche par client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Sélectionner un client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Sélectionnez un statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Réinitialiser",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Rechercher",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Rechercher par statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Abandonné",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Désactivé",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Erreur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Échoué",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"En cours",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"En file d'attente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Arrêté",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Réussite ",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Avertissement",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Cycle de facturation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Date créée",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Date de fin",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Détails de l'erreur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Erreur d'exportation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Statut d'exportation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Nom du client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"LogMessage",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Nom de l'opération",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Prix",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Prix du fournisseur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Quantité",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Date de début",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Nombre d'états",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Action",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Date de l'historique des abonnements",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Statut de validation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Erreur de validation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"département",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Site",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Détail",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Résumé",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Échoué",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Réussite ",
      "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Lancement de l'actualisation des données locales à partir d'un service externe",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Supprimé",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_ACTIVE_OFFER_IN_C3_CATALOGUE":"Impossible de trouver l'offre dans C3",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"Impossible de trouver les détails du contrat localement pour créer un mappage.",
      "EXTERNAL_SERVICE_IN_ACTIVE":"Inactif",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Statut de journal de publication de service externe",
      "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abandon du téléchargement en raison d'une erreur lors de la création d'entités",
      "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"L'ajout est actif \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"L'identifiant de l'accord est manquant",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"La facturation n'est pas activée pour ce contrat dans \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"L'accord mappé n'est pas actif dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"L'accord n'est pas présent dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Les accords ne sont pas mappés pour ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Les accords ne sont pas présents dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Des ajouts ambigus sont présents, la cartographie n'est pas possible.",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"L'identifiant du contrat est manquant",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"La facturation n'est pas activée pour ce contrat dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Le contrat mappé n'est pas actif dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Le contrat n'est pas présent dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Le contrat n'est pas présent dans le service externe sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Le service contractuel n'est pas mappé sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Le service de contrat mappé n'est pas présent dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Les services contractuels ne sont pas mappés sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Les contrats ne sont pas mappés pour ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Les contrats ne sont pas présents dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Les contrats ne sont pas présents dans le service externe sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Le client n'est pas actif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Le client externe n'est pas mappé avec le client c3",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Le client n'est pas présent dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Le mappage de l'entité ou du produit est manquant.",
      "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":"Une tentative d'exportation a été tentée précédemment. Le statut de cette opération est",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Le contrat n'est pas actif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"L'identifiant client externe est manquant",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Les clients ne sont pas présents dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Une erreur s'est produite lors de la création d'un ajout dans ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":"Une erreur s'est produite lors de la création d'un ajustement :",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Une erreur s'est produite lors de la création de l'ajustement pour le changement de prix",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Une erreur s'est produite lors de la création du service",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Impossible de créer des frais de contrat pour un produit ponctuel",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":"Une erreur s'est produite lors de la création du service de contrat :",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Une erreur s'est produite lors de la mise à jour de l'ajout dans ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Impossible de créer un mappage car plusieurs services correspondants ont été trouvés dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Le contrat correspondant n'est pas disponible pour mapper automatiquement.",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Aucun client externe correspondant n'a pu être trouvé",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Aucun produit externe correspondant n'a pu être trouvé",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Le produit n'est pas mappé sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Le produit mappé n'est pas présent dans \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Les produits ne sont pas mappés sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"C3 Les produits n'ont pas de service externe mappé",
      "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"L'ID de service est manquant",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin de l'accord.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation de l'accord",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de l'accord",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation du contrat",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"L'abonnement est en Autotask",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"L'abonnement est inactif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Impossible de terminer le mappage automatique car plusieurs contrats correspondants ont été trouvés",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Impossible de terminer le mappage automatique car plusieurs clients externes ont été mis en correspondance",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Impossible de terminer le mappage automatique car plusieurs produits externes ont été mis en correspondance",
      "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"La validation échoue",
      "EXTERNAL_SERVICE_NAME":"Nom du service externe",
      "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Sélectionnez les clients à valider et à télécharger",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"Identifiant de lot de publication de service externe",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Retour à la liste",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Erreur ignorée avec succès",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Exporter",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validation",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Statut des journaux de publication de service externe",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Journaux de publication de service externe",
      "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Tester la connectivité",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Instructions",
      "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Veuillez cliquer sur ce bouton pour tester la connectivité à PSA une fois la configuration terminée.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Connectivité établie avec succès. Vous pouvez procéder au téléchargement des journaux d'abonnements maintenant.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Veuillez sélectionner au moins un client",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"Échec de la connexion à Datto Autotask. Veuillez revérifier vos configurations dans la page des paramètres",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"Échec de la connexion à ConnectWise Manage. Veuillez revérifier vos configurations dans la page des paramètres",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"La date de fin doit être supérieure à la date de début",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Veuillez sélectionner la date de fin",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"La date de début ne peut pas être supérieure à la date de fin",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Veuillez sélectionner la date de début",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Vos données sont en cours de téléchargement. Vous pouvez revoir cette page pour plus de mises à jour",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"La validation a commencé",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Remarque :",
      "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"La clé numérique est incorrecte ou l'accès à cette fonction est refusé. La validation de la clé numérique a échoué. Vérifiez que vous utilisez la bonne clé numérique",
      "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Il existe déjà un client avec la référence de téléchargement que vous avez fournie.",
      "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Remboursement non autorisé. Un remboursement a déjà été traité pour cette transaction.",
      "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Votre statut est xxxxx et il ne vous permet pas d'utiliser l'API.",
      "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"Le numéro de compte bancaire ne peut pas être vide",
      "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"Vous n'avez pas de produit BPay actif",
      "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"Vous ne pouvez pas modifier le statut d'un client qui est annulé.",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Vous devez fournir une valeur pour le paramètre 'ChangeFromDate' ou 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Vous devez fournir une valeur pour le paramètre 'ChangeFromDate' ou 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"Le client n'est PAS actif.",
      "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"L'année/le mois de la carte de crédit a déjà expiré.",
      "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"Impossible de trouver un client avec les détails fournis.",
      "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Vous devez fournir une valeur pour le paramètre 'DateField' lorsque les valeurs 'DateFrom' ou 'DateTo' sont fournies.",
      "EZIDEBIT_ERROR_CODE_DATE_FROM":"Vous devez fournir une valeur pour les paramètres 'DateFrom' ou 'DateTo' lorsque la valeur 'DateField' est fournie.",
      "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom ne peut pas être plus de xx mois avant \"DateTo\"",
      "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Vous devez fournir une valeur pour le paramètre 'DebitDate'",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"La clé numérique est incorrecte ou l'accès à cette fonction est refusé. La validation de la clé numérique a échoué. Vérifiez que vous utilisez la bonne clé numérique",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Jeton invalide. Le jeton client n'est pas valide pour cette clé numérique",
      "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Vous devez fournir une valeur pour le paramètre 'EziDebitCustomerID' ou le paramètre 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Cette fonction est actuellement indisponible car le traitement Ezidebit est en cours d'exécution. Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Client non actif (Statut :<Status Code>). Le statut du client n'est pas A (Actif) ou N (Nouveau)",
      "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Numéro de carte de crédit saisi invalide - Votre gamme de produits n'inclut pas xxxxxxx",
      "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"Le client ne dispose pas de fonds suffisants pour couvrir ce montant d'ajustement.",
      "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Longueur du numéro de compte invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Valeur non valide fournie pour le paramètre \"ApplyToAllFuturePayments\". Les valeurs valides sont : 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Format invalide fourni pour le paramètre 'BankAccountBSB'. Les valeurs valides ne doivent comporter que six chiffres",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Numéro de compte bancaire BSB non valide saisi",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Numéro de compte bancaire saisi incorrect",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Nom de compte bancaire saisi incorrect",
      "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"'BankReceiptId' n'a pas pu être déterminé",
      "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Numéro de carte de crédit saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Valeur non valide fournie pour le paramètre 'ChangeFromPaymentNumber'. Les valeurs valides doivent être numériques et supérieures ou égales à un.",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Valeur non valide fournie pour le paramètre 'ChangeFromDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Valeur non valide fournie pour le paramètre 'ChangeToDate'. Les valeurs valides sont n'importe quelle date au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Valeur non valide fournie pour le paramètre 'ContractStartDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Valeur non valide fournie pour le paramètre 'DateField'. Les valeurs valides sont : 'PAYMENT' ou 'SETTLEMENT'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Valeur non valide fournie pour le paramètre 'DateFrom'. Les valeurs valides sont n'importe quelle date au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Valeur non valide fournie pour le paramètre 'DateTo'. Les valeurs valides sont n'importe quelle date au format 'AAAAMM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Valeur non valide fournie pour le paramètre 'DayOfMonth'. Les valeurs valides doivent être comprises entre 1 et 31.",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Valeur non valide fournie pour le paramètre 'DayOfWeek'. Les valeurs valides sont : 'MON', 'TUE', 'WED', 'THU' ou 'FRI'.",
      "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Valeur non valide fournie pour le paramètre 'DebitDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Clé numérique non valide.",
      "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Données de prélèvement automatiques saisies incorrectes - Votre gamme de produits n'inclut pas les prélèvements automatiques.",
      "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Adresse e-mail saisie invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Mois d'expiration de carte de crédit saisi non valide",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"L'année d'expiration de la carte de crédit saisie n'est pas valide",
      "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Valeur non valide fournie pour le paramètre 'EzidebitCustomerID'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Valeur non valide fournie pour le paramètre 'SchedulePeriodType'. Les valeurs valides sont : '4', 'F', 'H', 'M', 'N', 'Q', 'W' ou 'Y'",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'FirstWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"Le prénom contient des caractères non standard qui ne sont pas autorisés",
      "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'FourthWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'EziDebitCustomerID' et 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"Le nom de famille contient des caractères non standard qui ne sont pas autorisés",
      "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"La longueur du paramètre 'YourPayerNumber' est supérieure à la longueur maximale.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Valeur non valide fournie pour le paramètre 'LimitToNumberOfPayments'. Les valeurs valides doivent être numériques et supérieures ou égales à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'LimitToTotalAmountInCents'. Les valeurs valides doivent être numériques et supérieures ou égales à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Valeur non valide fournie pour le paramètre 'KeepManualPayments'. Les valeurs valides sont : 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Nom invalide sur la carte de crédit saisie",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'NewPaymentAmountInCents'. Les valeurs valides doivent être supérieures ou égales à 200 (2 dollars).",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Valeur non valide fournie pour le paramètre 'NewStatus'. Les valeurs valides sont : 'A', 'H' ou 'C'",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Valeur non valide fournie pour le paramètre 'NewYourSystemReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Conflit de paramètre. Vous ne pouvez pas fournir un nombre supérieur à zéro pour 'LimitToNumberOfPayments' et 'LimitToTotalAmountInCents'",
      "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"Le montant du remboursement partiel ne peut pas être compris entre le montant du débit programmé et le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'PaymentAmountInCents'. Les valeurs valides doivent être supérieures ou égales à 200 (2 dollars).",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Valeur non valide fournie pour le paramètre 'PaymentMethod'. Les valeurs valides sont : 'ALL', 'DR' ou 'CR'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Référence de paiement saisie invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOUNCE":"Valeur non valide fournie pour le paramètre 'PaymentSouNCE'. Les valeurs valides sont : 'ALL', 'SCHEDULED', 'WEB', 'PHONE' ou 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Valeur non valide fournie pour le paramètre 'PaymentSource'. Les valeurs valides sont : 'ALL', 'SCHEDULED', 'WEB', 'PHONE' ou 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Valeur non valide fournie pour le paramètre 'PaymentType'. Les valeurs valides sont : 'ALL', 'PENDING', 'SUCCESSFUL' ou 'FAILED'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Numéro de téléphone mobile saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Code postal saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"Le paiement avec la référence 'xxxxxxx' est introuvable.",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Valeur non valide fournie pour le paramètre \"RefundAmountInCents\". Les valeurs valides sont un montant de remboursement compris entre 100 et le montant de la transaction d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"L'instrument de paiement du remboursement ne correspond pas à l'instrument de paiement d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Valeur non valide fournie pour le paramètre \"RefundAmountInCents\". Les valeurs valides sont un montant de remboursement compris entre 100 et le montant de la transaction d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"Le montant du remboursement dépasse le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Valeur non valide fournie pour le paramètre 'ScheduleStartDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'SecondWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Valeur invalide fournie pour le paramètre 'SmsExpiredCard'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Valeur non valide fournie pour le paramètre 'SmsFailedNotification'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Valeur invalide fournie pour le paramètre 'SmsPaymentReminder'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'ThirdWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Jeton invalide. Le jeton n'est pas numérique",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Numéro CCV de carte de crédit saisi non valide - Le numéro CCV doit comporter 3 ou 4 chiffres",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Valeur non valide fournie pour le paramètre 'CustomerName'. La valeur doit être inférieure à 80 caractères.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Valeur non valide fournie pour le paramètre 'CustomerStatus'. Les valeurs valides sont : 'ALL', 'HOLD', 'PENDING', 'CANCELLED', 'ACTIVE'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Valeur non valide fournie pour le paramètre Order. Les valeurs valides sont : 'ASC', 'DESC'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Valeur non valide fournie pour le paramètre OrderBy. Les valeurs valides sont : 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Valeur non valide fournie pour le paramètre PageNumber. Les valeurs valides doivent être numériques et supérieures à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Valeur invalide saisie pour le paramètre 'Réactiver'. Les valeurs valides sont 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Une valeur non valide est fournie pour le paramètre 'YourPayerNumber'. Les valeurs valides sont des entiers positifs.",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Valeur non valide fournie pour le paramètre 'YourGeneralReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Valeur non valide fournie pour le paramètre 'YourSystemReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Valeur non valide pour le paramètre 'DebitDate'. La valeur valide est n'importe quelle date à partir de trente et un (31) jours dans le passé, au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_LAST_NAME":"Vous devez fournir une valeur pour le paramètre 'LastName'",
      "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Vous devez fournir une valeur pour le paramètre 'DigitalKey'",
      "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Vous devez fournir une valeur pour le paramètre 'CustomerStatus'",
      "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Vous devez fournir une valeur pour le paramètre 'PaymentID' ou 'BankReceiptID'. Indiquez une valeur pour PaymentID ou BankReceiptID.",
      "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"Vous n'avez fourni aucune carte de crédit ou coordonnées bancaires. Les données obligatoires n'ont pas été fournies pour la mise à jour des détails de facturation",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Vous devez fournir une valeur pour le paramètre 'customerName'",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Vous devez fournir une valeur pour le paramètre 'Order' lorsque la valeur 'OrderBy' est fournie.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Vous devez fournir une valeur pour le paramètre 'OrderBy' lorsque la valeur 'Order' est fournie.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Vous devez fournir une valeur pour le paramètre 'YourPayerNumber'",
      "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Vous devez fournir une valeur valide pour le paramètre 'MobileNumber' si vous souhaitez que le client reçoive des notifications par SMS",
      "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Vous devez fournir un jour de la semaine lors de la création d'un horaire Hebdomadaire, Bimensuel, 4 Hebdomadaire ou Jour de la semaine dans le mois",
      "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Aucune donnée ne correspondait aux paramètres de sélection.",
      "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Valeur nulle détectée, veuillez passer par la chaîne vide au lieu de null pour les paramètres pour lesquels vous ne souhaitez pas fournir de valeur",
      "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Vous devez fournir une valeur pour le paramètre 'PaymentID' ou 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'ChangeFromDate' et 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Conflit de paramètre. 'ScheduleStartDate' et 'DayOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Conflit de paramètre. 'ScheduleStartDate' et 'DayOfWeek' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Ajouter un paiement refusé - Seuls les clients actifs peuvent avoir des paiements ajoutés à leur calendrier",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Ajouter paiement refusé - Vous avez déjà enregistré un paiement avec la référence xxxxxxx. Une référence de paiement doit identifier de manière unique un seul paiement.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Ajouter paiement refusé - Ce client a déjà xx paiements à cette date.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Ajouter un paiement refusé - Ce client est en attente en raison de détails de compte bancaire invalides et ces détails de compte n'ont pas été modifiés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Ajouter un paiement refusé - Ce client est en attente en raison de détails de carte de crédit invalides et ces détails de carte n'ont pas été modifiés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Ajouter un paiement refusé - Le statut xx de ce client ne permet pas d'ajouter de nouveaux paiements.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Ajouter un paiement refusé - Le statut de ce client 'xxxxx' ne permet pas d'ajouter de nouveaux paiements.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"Non configuré pour les paiements par carte de crédit. Le compte client n'est pas configuré pour traiter les paiements par carte de crédit en temps réel pour le type de carte du client",
      "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Vous devez fournir une valeur pour le paramètre 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"Le paiement a été traité et ne peut pas être supprimé",
      "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"Le paiement initial est toujours en cours de traitement. Il ne peut pas être remboursé à ce stade.",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"Aucun enregistrement de paiement trouvé pour PaymentId",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"Aucun enregistrement correspondant trouvé pour 'PaymentId' ou 'BankReceiptId'",
      "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"Le montant du remboursement dépasse le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"Les remboursements ne sont pas autorisés pour le client",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Vous avez transmis à la fois des informations de carte de crédit et de compte bancaire. Seules les données de carte de crédit ou de compte bancaire peuvent être transmises, pas les deux.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'PaymentID' et 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"Tous les paramètres requis n'ont pas été fournis",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'FirstWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'FourthWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'SecondWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'ThirdWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"Le paiement sélectionné pour suppression est introuvable.",
      "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"Vous n'utilisez pas de code de facturation Ezidebit BPay partagé.",
      "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Mise à jour du statut refusée - Seuls les clients dont le statut est 'A', 'N' ou 'H' peuvent être modifiés à l'aide de ce service.",
      "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"Impossible de traiter la mise à jour - Numéro de carte de crédit CCV non valide - Le numéro CCV doit comporter 4 chiffres pour AMEX (WSvc)",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Les données du rapport sont actuellement indisponibles car le traitement des paiements est en cours. Veuillez réessayer plus tard",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Les données du rapport sont actuellement indisponibles car le traitement des paiements est en cours (PT). Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Les données du rapport ne sont actuellement pas disponibles car le traitement des paiements est en cours d'exécution (SPS). Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"Le système est actuellement indisponible. Veuillez réessayer plus tard. Une connexion ne peut pas être ouverte à la base de données pour une raison quelconque. Veuillez contacter partner@ezidebit.com.au pour obtenir de l'aide",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"Le système est actuellement indisponible. Veuillez réessayer plus tard. Une erreur non gérée s'est produite lors de l'extraction des détails de la base de données. Veuillez contacter partner@ezidebit.com.au pour obtenir de l'aide",
      "EZIDEBIT_ERROR_CODE_YES":"Vous devez fournir une valeur « OUI » pour au moins un des paramètres de la semaine du mois lors de la création d'un programme Jour de la semaine dans le mois.",
      "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Vous devez fournir une valeur pour le paramètre 'YourGeneralReference'",
      "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Vous devez fournir une valeur pour le paramètre 'YourSystemReference'",
      "EZIDEBIT_NO_ERROR_DETECTED":"Aucune erreur détectée",
      "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Impossible d'affecter le client au gestionnaire de compte",
      "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"Impossible d'affecter le revendeur au gestionnaire de compte",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Impossible de modifier le cycle de facturation de la commande",
      "FAILED_TO_CREATE_ACCOUNT_MANAGER":"Impossible de créer le gestionnaire de compte",
      "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Impossible de créer un nouveau plan avec toutes les offres",
      "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Échec de la création d'un nouveau forfait revendeur avec toutes les offres",
      "FAILED_TO_DELETE_ACCOUNT_MANAGER":"Échec de la suppression du gestionnaire de compte",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Échec de mise en file d'attente de la demande du clonage du plan",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"Échec de mise en file d'attente de la demande de création d'un nouveau plan comprenant toutes les offres",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Échec de la mise en file d'attente de la demande de création d'un nouveau forfait revendeur avec toutes les offres",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Impossible d'obtenir les jetons d'accès par le code d'authentification reçu de Quickbooks",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Impossible d'obtenir les jetons d'accès par le code d'authentification reçu de Xero",
      "FAILED_TO_RESEND_INVOICE":"Impossible de renvoyer la facture",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Impossible d'enregistrer le code d'authentification reçu de Quickbooks",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Impossible d'enregistrer le code d'authentification reçu de Xero",
      "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Impossible de désaffecter le client d'un gestionnaire de compte",
      "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"Impossible de désaffecter le revendeur d'un gestionnaire de compte",
      "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Échec de la mise à jour des détails du gestionnaire de compte",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"Général",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Vous pouvez facilement mettre à jour les « seeds » en utilisant la fonctionnalité Modifier dans les abonnements.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Vous pouvez autoriser l'accès à l'aide de la fonctionnalité Autoriser l'accès au portail dans la vue utilisateur.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Oui, vous pouvez effectuer la mise à jour en utilisant notre portail.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Oui, vous pouvez suspendre l'abonnement en utilisant C3.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Comment augmenter les sources d'abonnement ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Comment donner accès au portail à un utilisateur normal ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Existe-t-il une option pour mettre à jour le mode de paiement à l'aide de C3 ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. C3 a-t-il une option pour suspendre les abonnements ?",
      "FAQ_HEADING":"Foire aux questions",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Application générale",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"À partir de maintenant, C3 est pris en charge en anglais (en-us), espagnol (es) et en taïwanais (tw). En plus, C3 prend en charge les devises en fonction des régions et de la facturation du centre partenaire ",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Oui, cela est réalisable dans C3 en définissant des balises. Vous devez définir des balises (paire de valeur) et les affecter aux clients. Par exemple, vous pouvez définir une balise nommée SupportAgent et un pseudonyme pour un agent (par exemple, Agent001) comme valeur. Si un agent du Centre d'assistance a une de ses balises définies comme SupportAgent et l'une des valeurs de cette balise spécifique comme Agent001, l'agent pourrait voir les clients qui ont la paire de valeurs de balise définie {SupportAgent, Agent001}.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Vous devez aller dans Paramètres >> Notification par courriel où vous pourrez configurer C3 pour envoyer des courriels en fonction d'événements. C3 vous offre la possibilité de configurer des courriels pour divers événements. Vous pouvez également voir le courriel de prévisualisation selon l'événement.  Vous pouvez configurer la notification par courriels pour un événement où une offre est achetée par le client. Vous pouvez sélectionner le destinataire en fonction du rôle ou des contacts de facturation. En outre, vous pouvez également sélectionner la liste CC et CCI ainsi. Une fois cela fait, chaque fois qu'un tel événement se produira, C3 enverra des notifications par courriel à tous les destinataires configurés dans le paramètre de notification par courriel.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Vous pouvez télécharger les rapports sommaires d'impôts et des postes d'impôt à partir de l'onglet Résumé des revenus et des coûts, disponible sur le portail partenaire.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Oui, C3 prend en charge les rabais. Vous pouvez créer des coupons rabais et les appliquer à un plan ou des clients spécifiques. Vous pouvez définir différents paramètres pour un coupon tels que le remboursement maximum, « valide à partir de », « valide jusqu'à » et le pourcentage de rabais.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Vous pouvez personnaliser C3 en utilisant des logos pour votre image de marque. C3 vous propose de remplacer le logo de bienvenue sur la page d'accueil et le logo de connexion par le vôtre. Vous pouvez même personnaliser les courriels de notification en utilisant votre logo pour renforcer l'impact de votre image de marque.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Oui, nous pouvons apporter les modifications nécessaires de notre côté afin que le client parvienne à la page de connexion au lieu de la page d'accueil C3.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Non, nous ne sommes pas encore certifiés. Mais, nous utilisons une configuration du même niveau pour stocker vos données en toute sécurité.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Oui, nous pouvons le faire. Nous vous fournirons une adresse IP et vous devrez créer un enregistrement avec la même, où votre DNS sera géré. Votre fournisseur DNS vous aidera à le faire.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Non, vous pouvez directement aller à la configuration et mettre à jour les informations d'identification pour le centre partenaire ou SMTP en cas de modification.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. Quelles sont les langues et les devises prises en charge dans C3 ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Puis-je avoir des utilisateurs qui ne traitent qu'avec un ensemble de clients ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Comment puis-je configurer C3 pour envoyer des courriels lorsqu'un achat est effectué par un client ?  Comment puis-je également configurer les destinataires de ces courriels ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Quels types de rapports fiscaux peuvent être téléchargés ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. C3 prend-il en charge les rabais ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. Quel type de personnalisation C3 offre-t-il en matière de présentation et de convivialité (marque blanche) ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Y a-t-il un moyen pour que mes clients puissent cliquer sur un lien de mon portail qui les amènerait à la page de connexion au lieu de la page d'accueil de C3 ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. Est-ce que C3 est conforme PCI ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Puis-je lier mon nom de domaine préféré à C3 ? Que faut-il pour le faire ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. Dois-je contacter le service d'assistance C3 lorsque les informations d'identification de mon centre partenaire ou SMTP ont changé ou expiré ?",
      "FAQ_PARTNER_CATEGORY_BILLING":"Facturation",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Actuellement, C3 prend en charge Manuel, Carte de crédit, ACH (seulement pour les clients américains) et les comptes bancaires (seulement pour les clients australiens et néo-zélandais).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Oui, les clients reçoivent une copie de la facture en format pdf dans un courriel de notification lorsque la facture est approuvée par le partenaire.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"Dans de tels cas, vous pouvez empêcher C3 de facturer les clients en enregistrant le paiement sur la facture en question. Vous devez aller au Résumé des revenus et des coûts >> Sélectionnez un client >> Factures. De là, vous devez sélectionner la facture pour laquelle vous avez perçu le paiement. Vous trouverez ici une option d'Enregistrement de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Nous ne prenons pas en charge de multiples cycles de facturation. Mais dans ce cas, nous pouvons aligner tous les clients à un cycle de facturation spécifique. Ceci est un processus manuel.  Par exemple, si votre cycle de facturation actuel pour un client est du 10 de ce mois au 9ème jour du mois prochain et que dans C3, le cycle de facturation est du 1er de ce mois au 1er du mois prochain. Puisque tous les clients ont effectué tous les paiements pour le 9 de ce mois. Nous calculerons le montant correspondant du 1er au 9 et ferons le même ajustement dans la prochaine facture, ce qui résoudra le problème.  De même, nous pouvons faire la même chose pour les autres cycles de facturation. À partir du mois prochain, tous les clients seront alignés sur le même cycle de facturation.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Oui, nous pouvons faire des ajustements concernant les paiements dans la facture correspondante. Il n'y a pas de version automatisée pour l'instant. Nous pouvons nous charger de cela.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Nous prenons maintenant en charge Bill&Pay, Stripe, EziDebit, Authorize.net, Cybersource. Si vous utilisez un autre système que ceux mentionnés, nous pouvons certainement veiller à l'intégration de votre passerelle de paiement avec C3.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 est conçu pour prendre en charge plusieurs passerelles de paiements tels que Bill&Pay, Stripe, EziDebit, Authorize.net, et CyberSource. Ce serait idéal si vous utilisiez l'une d'entre elles comme passerelle de paiement existante, ou nous pouvons essayer de concevoir C3 pour prendre en charge vos passerelles de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Nous mettons à jour les offres C3 en fonction de l'offre Microsoft la plus récente tous les mois. En cas de changement de prix, vous devez créer un nouveau plan ou mettre à jour le plan existant avec de nouveaux prix d'offres.  Par défaut, C3 appliquera les nouveaux prix pour les nouveaux clients. Mais pour les clients existants, vous pouvez définir un nouveau prix pour l'offre actuellement souscrite. En outre, vous pouvez choisir la date d'entrée en vigueur pour les nouveaux prix, par exemple avec effet immédiat pour le changement de prix, le prochain cycle de facturation ou la prochaine date de renouvellement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"Dans C3, les règles fiscales sont définies par des combinaisons { Pays, État/Province et Code fiscal }, { Pays, Code postal } ou { Pays, État/Province et Code postal}. Par exemple, pour la règle fiscale définie pour le Pays - États-Unis, laisser la province de l'état et le code zip en blanc signifie que le taux d'imposition est appliqué à tous les clients des États-Unis. Si vous souhaitez définir une règle pour un état particulier, indiquez les valeurs Pays et État lors de la définition de la règle.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Oui, vous pouvez le faire. Vous devez aller dans Paramètres >> Taxes, et créer une règle fiscale avec un pourcentage de taxe égal à 0 %. Veuillez l'appliquer au client que vous souhaitez exempter de la règle fiscale (applicable à d'autres clients).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Vous pouvez décider et nous faire savoir la date de génération de facture Nous la configurerons pour vous et vous pourrez le vérifier en allant dans Paramètres de partenaire>> Facturation. Ici vous pouvez voir toutes les dates liées à la facturation. Vous pouvez même modifier cette date pour un client spécifique en allant à la configuration client.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3 vous permet de décider des dates auxquelles votre client devrait être en mesure de consulter la facture. Après la génération de la facture, le partenaire doit approuver la facture (après avoir effectué des modification le cas échéant) pour la rendre disponible pour les clients.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Oui, C3 vous offre la possibilité d'apporter des modifications aux postes de la facture après la génération de la facture. Vous devez apporter les modifications requises (par exemple, vous souhaitez ajouter des frais ou renoncer à des frais sur une base unique) en fonction de vos besoins avant la date de finalisation de la facture. Vous ne pouvez pas apporter de modification à la facture une fois qu'elle est finalisée.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"Dans C3, nous pouvons configurer la notification par courriel pour de tels événements. En cas de succès ou d'échec de paiement, C3 enverra un courriel pour le communiquer. Tous les destinataires configurés pour de tels événements recevront des courriels de notification.   En outre, C3 a une fonctionnalité d'échec de paiement sous la rubrique Résumé des revenus et des coûts, dans laquelle le partenaire peut suivre les enregistrements pour tout incident d'échec de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. Quelles sont les méthodes de paiement prises en charge par C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Le client reçoit-il une copie de la facture en format pdf lorsque la facture est prête ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Que faire si j'ai perçu un paiement par chèque ou en espèces et que le mode de paiement du client est la carte de crédit ? Comment puis-je empêcher C3 de facturer le client pour la facture actuelle ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Nous avons utilisé nos propres systèmes de facturation et avons facturé nos clients jusqu'à ce jour. Nos clients avaient des cycles de facturation différents. Quel type de soutien obtenons-nous lorsque nous migrons vers C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Y a-t-il un moyen de traiter les paiements anticipés pour une facture ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. Quelles sont les passerelles de paiement que C3 prend en charge de série ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Puis-je utiliser mon fournisseur de passerelle de paiement existant ou dois-je opter pour ce qui est fourni par C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Que se passe-t-il lorsque les prix changent chez Microsoft et que nous voulons mettre à jour nos prix pour nos clients ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. Comment C3 traite-t-il l'impôt ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Puis-je exempter un client particulier d'une règle fiscale applicable à d'autres clients ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. Comment définir le moment où C3 doit générer des factures ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Mon client va-t-il pouvoir consulter les factures juste après leur génération ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Ai-je la possibilité d'apporter des modifications aux postes de la facture après sa génération ? Puis-je ajouter des frais uniques ou annuler des modifications connexes à une facture ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Comment puis-je (en tant que partenaire) savoir quand un paiement a réussi ou échoué ?",
      "FAQ_PARTNER_CATEGORY_SELL":"Vendre",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Un plan est un groupe d'offres que vous pouvez vendre à votre client au prix de votre choix. Vous devez avoir un plan pour intégrer ou créer un client.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Oui, C3 vous offre la fonctionnalité de création d'offres de partenaire. Par exemple, vous souhaitez fournir une assistance 24 heures sur 24, 7 jours sur 7 à vos clients. Ensuite, vous pouvez créer une offre partenaire 24*7 Office365 Support et la commercialiser au prix de votre choix.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Oui, vous pouvez intégrer dans C3 n'importe quel client existant dans le centre partenaire. Vous devez avoir un identifiant Microsoft (à obtenir du centre partenaire) et un plan correspondant à l'offre souscrite dans le centre partenaire. En outre, C3 vous donne également une fonctionnalité pour créer un nouveau client directement à partir de C3 (pas besoin d'aller sur le centre partenaire pour la création de clients).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Pour ce faire, nous devons intégrer ce client dans un plan. Vous pouvez mettre la quantité d'offres à zéro. Une fois que le client est intégré avec succès, il synchronisera automatiquement les abonnements basés sur l'utilisation pour ce client particulier.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"Dans de tels cas, il y aura une discordance de quantité entre PC et C3. Notre équipe peut corriger de telles discordances.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Nous pouvons faire des ajustements dans les factures, mais à partir de maintenant, C3 ne prend plus en charge les remboursements.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Oui, C3 calcule l'utilisation des clients, et basé sur la consommation de données, il génère la facture.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Oui, vous pouvez créer un coupon-rabais avec le pourcentage de rabais requis et les récurrences maximales définies sur 3. Affectez maintenant le coupon nouvellement créé à un client spécifique. Une fois cela fait, le client obtiendra le rabais applicable pour les trois prochaines factures.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Qu'est-ce qu'un plan ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Puis-je vendre des offres non basées sur Microsoft comme des services personnalisés ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Puis-je intégrer un client existant ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Comment puis-je intégrer un client qui a uniquement des abonnements basés sur l'utilisation ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mon équipe a accidentellement acheté des licences par le biais de PC. Quelles sont les répercussions de telles actions ? Existe-t-il un processus pour corriger ces écarts ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. C3 prend-t-il en charge les remboursements ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. C3 gère-t-il la facturation Azure pour l'utilisation de mes clients ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. J'ai un client intégré dans C3 et je veux offrir des rabais pour les trois prochaines factures, puis-je le faire ?",
      "FEATURE_COMMENTS_ORDER_NO":" Numéro de commande :",
      "FEBRUARY":"Février",
      "FIELD_REQUIRED":"{{atribute}} est obligatoire",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"Sélectionner",
      "FILTER_SELECT_A_TARGET_CURRENCY":"Sélectionner",
      "FIX_AUTO_RENEW_STATUS_MISMATCH":"Correction de l'incohérence du statut de renouvellement automatique",
      "FIX_AZURE_MISSING_OFFER":"Correction de l'offre manquante d'azur",
      "FIX_Azure_MISSING_OFFER":"Correction de l'offre manquante d'Azure",
      "FIX_BILLING_CYCLE_MISMATCH":"Corriger l'incohérence du cycle de facturation",
      "FIX_MISSING_OFFER":"Correction de l'offre manquant",
      "FIX_OFFER_MISMATCH":"Correction de l'inadéquation de l'offre",
      "FIX_QUANTITY_MISMATCH":"Corriger un écart de quantité",
      "FIX_SCOPE_MISMATCH":"Corriger l'inadéquation de la portée",
      "FIX_STATUS_MISMATCH":"Corriger l'incompatibilité de statut",
      "FIX_TERM_DURATION_MISMATCH":"Corriger l'incohérence de la durée du terme",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":".Tous droits réservés.",
      "FOOTER_TEXT_1_POWERED_BY":"Pris en charge par",
      "FROM":"De",
      "General":"Général",
      "GENERAL_SETTINGS_MAIN_ERROR":"Veuillez corriger les erreurs dans le formulaire avant de soumettre",
      "GENERATE_INVOICE_REASON":"Raison",
      "GENERATE_INVOICE_REASON_PLACEHOLDER":"Veuillez fournir une raison",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Jetons d'accès QuickBooks générés par le code d'authentification reçu de Quickbooks",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Jetons d'accès Xero générés par le code d'authentification reçu de Xero",
      "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la récupération des détails du fournisseur de facturation.",
      "GET_CURRENCY_CODES_EXCEPTION":"Une erreur s'est produite lors de la récupération des codes de devises",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de la récupération de la conversion de devises",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération de la liste de conversion de devises",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des options de devises",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des événements client pour les notifications par courriel",
      "GET_CUSTOMER_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération des clients",
      "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Une erreur s'est produite lors de l'obtention des forfaits client avec la promotion active",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération de la liste des destinataires de notification par courriel",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des événements clients pour les notifications par courriel",
      "GET_PARTNER_LOGO_EXCEPTION":"Une erreur s'est produite lors de la récupération du logo du partenaire",
      "GET_PARTNER_SETTINGS_EXCEPTION":"Une erreur s'est produite lors de la récupération des paramètres du partenaire",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la récupération des informations sur l'impôt",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Une erreur s'est produite lors de la récupération des types d'impôts",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Une erreur s'est produite lors de la récupération des devises cibles",
      "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Une erreur s'est produite lors de la réception de la demande de relation avec le revendeur.",
      "GOT_RESELLER_DETAILS":"Vous avez obtenu le revendeur depuis de CSP avec le nom: {{resellerName}}",
      "government":"Gouvernement",
      "Government":"Gouvernement",
      "GRAND_ACCESS_USER_EXCEPTION":"Cet utilisateur a déjà accès, veuillez vérifier pour un autre",
      "GRAND_TOTAL":"Total",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Consommation par produit",
      "GRAPH_CUSTOMERS_ONBOARDED":"Clients intégrés",
      "GRAPH_DATA_BILLED_AMOUNT":"Montant facturé",
      "GRAPH_DATA_QUANTITY":"Quantité",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Revenus par client",
      "GRAPH_TOP_SELLING_PRODUCT":"Les 10 produits les plus vendus",
      "GREATER_THAN_ERP":"supérieur à l'ERP",
      "GRID_DOWNLOAD_COUPON_GRID":"Télécharger les coupons",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Un administrateur de groupe avec ce courriel existe déjà dans ce groupe",
      "GROUP_USERS_OF":"Groupe d'utilisateurs de",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Utilisateurs du groupe",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Afficher l'application dans une langue prise en charge par l'application",
      "HEADER_CONTENT_HELP_MENU":"Contenu d'aide contextuel présenté dans la barre latérale de droite",
      "HEADER_CONTENT_MENU_CONTACT_LOG":"Permet au Partenaire de voir l'état de toutes les notifications et le contenu de l'e-mail.",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Suivre l'activité dans le système",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Détails du profil de base de l'utilisateur connecté",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Vous permet de gérer les paramètres ou d'accéder au journal des activités",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_SIGNIN_USER":"Détails du profil de base de l'utilisateur connecté",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Nous contacter",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Se déconnecter",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Utilisateur non autorisé",
      "HEADER_GO_TO_PROFILE":"Aller au profil",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Nous contacter",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Arrêter représentation",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Utilisateur non autorisé",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Langue",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Langue",
      "HEADER_TITLE_HELP_MENU":"Aide",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Notifications",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Journal d'audits",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Paramètres",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Paramètres",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Paramètres",
      "HEADER_TITLE_SIGNIN_USER":"Utilisateur connecté",
      "HEADING_ENTITY_LIST":"Liste {{EntityName}}",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Fournir l'adresse courriel pour obtenir le courriel de test.",
      "HEURES":"Heures)",
      "HIGH":"Haut",
      "HOURS":"Heure(s) ",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"ici",
      "I have read and agreed to":"J'ai lu et accepté",
      "ID DU SITE":"C3SiteId",
      "IF_ALREADY_EXISTS":"existe déjà",
      "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Cette valeur décide si les filtres de recherche doivent être activés/désactivés dans l'écran de la boutique d'inscription publique.",
      "IGNORE_FROM_PSA_TEXT_DISP":"Ignorer le client du service externe",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Avec votre privilège actuel",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Sans vos privilèges actuels",
      "IMPORT_LIST":"Importer la liste",
      "In Progress":"En cours",
      "IN_ACTIVE":"Inactif",
      "INDEX_LOGOUT_APOLOGY_MESSAGE":"Ce programme est resté inactif pendant trop longtemps. Nous nous excusons donc de vous avoir déconnecté, mais nous n'avions pas le choix.",
      "INDEX_LOGOUT_MESSAGE":"Désolé, vous avez été déconnecté",
      "INDEX_MSG_LOGOUT_SOON_MESSAGE":"Le mode veille est activé car vous restez inactif trop longtemps. En conséquence, vous allez vous déconnecter dans quelques instants.",
      "INDXE_LOGOUT_TEXT":"FAITES QUELQUE CHOSE RAPIDEMENT ! Vous serez déconnecté dans {{countdown}} seconde(s).",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Aucune devise disponible dans l'enregistrement",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Aucun état disponible dans l'enregistrement",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Veuillez sélectionner un pays",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Veuillez d'abord sélectionner une devise source",
      "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"Êtes-vous sûr de vouloir initier le débit de la facture pour <%= currencySymbol%><%= factureAmount%> en utilisant le mode de paiement : <br> <b><%= PaymentType%> | <%= NuméroCompte%></b> ? <br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide concernant des paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur. </petit>",
      "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Il n'y a pas de montant en attente à payer.",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Toutes les factures sélectionnées seront mises en file d'attente pour les paiements. Voulez-vous continuer ?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"Le statut de la facture passe à <%= invoiceStatus%>. Voulez-vous continuer ?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Le client sera facturé $<%= amount%>. Voulez-vous continuer?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Le revendeur sera facturé $<%= amount%>. Voulez-vous poursuivre?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
      "INPUT_CATEGORYID":"CategoryId",
      "INPUT_CHANGE_AUTO_RENEW_STATUS":"Statut de renouvellement automatique",
      "INPUT_CHANGE_TYPE":"ChangeType",
      "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "INPUT_CUSTOMERC3ID":"CustomerC3Id",
      "INPUT_CUSTOMERID":"CustomerId",
      "INPUT_CUSTOMERNAME":"CustomerName",
      "INPUT_DATA":"Entrée de l'utilisateur",
      "INPUT_DESCRIPTION":"Description",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"InternalProductId",
      "INPUT_INVOICEID":"InvoiceId",
      "INPUT_LABEL_PURCHASE_QUANTITY":"Quantité d'achat",
      "INPUT_NAME":"Nom",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"PageCount",
      "INPUT_PAGEINDEX":"PageIndex",
      "INPUT_PAGESIZE":"PageSize",
      "INPUT_PLANC3ID":"Plaid",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"PriceforPartner",
      "INPUT_PRODUCTID":"Identifiant de produit",
      "INPUT_PROVIDERID":"ProviderId",
      "INPUT_PROVIDERSELLINGPRICE":"Prix de vente du fournisseur",
      "INPUT_QUANTITY":"Quantité",
      "INPUT_REACTIVATE":"Réactiver",
      "INPUT_SALETYPE":"Type de vente",
      "INPUT_STARTIND":"StartInd",
      "INPUT_SUSPEND":"Suspendre",
      "INPUT_TENANTID":"TenantId",
      "INPUT_WITHADDONS":"WithAddons",
      "INSCRIPTION_CUSTOMER_CONSENT_AGREEMENT_DATE":"Date de l'accord",
      "INSCRIPTION_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"Retour au panier",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Retour aux commandes",
      "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Profil de paiement",
      "INSTANT_PAY_BUTTON_TEXT_PAY":"Payer",
      "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"La passerelle de paiement actuelle n'est pas encore prise en charge avec le paiement instantané. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Méthode de paiement ",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Paiement sécurisé",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Téléchargez la feuille cartographiée pour la cartographie au format .csv. Téléchargez le fichier <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le télécharger.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Ce fichier est utilisé pour mapper les entités entre C3 et AutoTask Manage. Ne supprimez aucun texte ou donnée existant dans ce fichier.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Seuls les champs qui doivent être mis à jour sont « AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId et ContractServiceName ». Le reste des champs est là pour aider à identifier les homologues C3 pour le mappage.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Si les champs mentionnés ci-dessus sont remplis dans la feuille, le mappage est disponible, si les champs mentionnés ci-dessus sont vides, alors le mappage est requis.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Pour remplir la feuille de cartographie, vous pouvez vous référer à la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"Le nom et l'identifiant entier (numérique) requis pour remplir la <b> feuille de mappage</b> sont disponibles dans la <b>feuille d'entité AutoTask.</b>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"La suppression du mappage n’est pas possible via la feuille de mappage. Pour modifier la correspondance entre l'accord et le produit, veuillez utiliser la feuille de correspondance. Le remappage client n'est possible que via l'écran de mappage des produits achetés.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_BULK_BUTTON":"Instructions pour le téléchargement groupé de la cartographie des produits achetés :",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Téléchargez la feuille cartographiée pour la cartographie au format .csv. Téléchargez le fichier <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le télécharger.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Ce fichier est utilisé pour mapper les entités entre C3 et ConnectWise Manage. Ne supprimez aucun texte ou donnée existant dans ce fichier.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Seuls les champs qui doivent être mis à jour sont « ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId et ConnectwiseProductname ». Le reste des champs est là pour aider à identifier les homologues C3 pour le mappage.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Si les champs mentionnés ci-dessus sont remplis dans la feuille, le mappage est disponible, si les champs mentionnés ci-dessus sont vides, alors le mappage est requis.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Pour remplir la feuille de cartographie, vous pouvez vous référer à la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"Le nom et l'identifiant entier (numérique) requis pour remplir la <b> feuille de mappage</b> sont disponibles dans la <b>feuille d'entité Connectwise.</b>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"La suppression du mappage n’est pas possible via la feuille de mappage. Pour modifier la correspondance entre l'accord et le produit, veuillez utiliser la feuille de correspondance. Le remappage client n'est possible que via l'écran de mappage des produits achetés.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Pour faciliter la mise en correspondance entre les produits C3 et ConnectWise Manage, veuillez télécharger la feuille de correspondance au format .csv. Vous pouvez télécharger le fichier <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le mettre à jour.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"Le fichier sert à établir des mappages de produits entre C3 et ConnectWise Manage. Veillez à ne supprimer aucun texte ou donnée existant dans le fichier.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Seuls les champs ConnectwiseProductId et ConnectwiseProductname nécessitent une mise à jour. D'autres champs permettent d'identifier les homologues C3 à des fins de mappage.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Si les champs mentionnés sont remplis, le mapping est terminé. S'ils sont vides, un mapping est nécessaire.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Reportez-vous à la <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Fiche d'entité Connectwise.xlsx.</a> pour obtenir des conseils sur la manière de remplir la feuille de mappage. Le nom et les identifiants entiers (numériques) requis sont disponibles dans la feuille d'entité ConnectWise.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Veuillez noter que la suppression des mappages n'est pas possible via la feuille de mappage. Pour modifier les mappages de produits, utilisez la feuille de mappage fournie.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Téléchargez le fichier et cliquez sur SOUMETTRE pour finaliser le processus.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON":"Instructions pour utiliser le bouton Actualiser :",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Cliquez sur le bouton Actualiser pour obtenir les dernières données de PSA vers C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"Par défaut, aucune donnée ne sera disponible dans Product Mapping au début. Cliquez sur le bouton Actualiser pour obtenir toutes les données disponibles de PSA vers C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Lorsque « Actualiser » est en cours, le système désactivera les autres onglets. En cas de succès, les onglets désactivés seront à nouveau visibles. En cas d'erreur, cet écran offrirait une option pour ignorer l'erreur. Vous pouvez cliquer sur le bouton Ignorer l'erreur pour activer les onglets désactivés.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Lorsque « Actualiser » est en cours, le système supprimera les données PSA existantes de C3 et insérera de nouvelles données de PSA.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Si des modifications sont apportées à PSA, par exemple l'ajout ou la suppression d'une société, d'un accord, de produits ou un changement de statut pour ces entités, l'utilisateur doit alors actualiser le mappage des produits pour refléter ces modifications dans C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Veuillez cliquer sur le bouton </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\"> Actualiser</a> pour obtenir les dernières données du PSA au C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" bouton pour obtenir les dernières données de PSA vers C3.",
      "INTÉGRATION":"Intégration",
      "INTERNAL_BILLING_ID":"Identifiant de référence de facturation : ",
      "INTERNAL_SCHEDULE_RENEWAL_ID":"Identifiant de renouvellement planifié interne",
      "INTERNAL_SCHEDULED_RENEWAL_ID":"Identifiant de renouvellement programmé interne",
      "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"Le changement de statut de renouvellement automatique n'est pas autorisé",
      "INVALID_ATTEMPT_DECREASE":"Une quantité décroissante n'est pas autorisée",
      "INVALID_ATTEMPT_REACTIVATE":"La réactivation du produit n'est pas autorisée",
      "INVALID_ATTEMPT_SUSPEND":"La suspension du produit n'est pas autorisée",
      "INVALID_COUNT":"Le nombre total de sièges ne peut pas être inférieur à 1",
      "INVALID_COUPON":"Ce bon n'est pas valable",
      "INVALID_EXPIRY_DATE":"Date d'expiration invalide",
      "INVALID_INPUT":"Entrée non valide",
      "INVALID_MESSAGE_ENTITY":"Entité de message invalide",
      "INVALID_MESSAGE_TYPE":"Type de message invalide",
      "INVALID_PORTAL_PAGE":"Page de portail invalide",
      "INVALID_PRODUCT_ID":"Un produit avec l'identifiant donné est introuvable dans le système.",
      "INVALID_QUANTITY":"Quantité invalide",
      "INVALID_RECIPIENTS":"Destinataires invalides",
      "INVALID_SEATS":"Détail manquant ou invalide. Les sièges doivent être un nombre positif ou négatif, limité par les contraintes du fournisseur",
      "INVALID_VALUE":"{{atribute}} n'est pas valide",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Retour aux invitations",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Ajouter une invitation",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Modifier invitation",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Tout effacer",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Articles sélectionnés",
      "INVITES_ADD_FORM_LABEL_OFFER":"Offres",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Prix",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Description",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Nom",
      "INVITES_ADD_FORM_URL_INVITE_URL":"Invitation URL",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Invitations",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Ajouter une nouvelle invitation",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"Invitations",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Modifier",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Description de l'invitation",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Nom de l'invitation",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "INVITES_SAVE_SUCCESS":"Invitation sauvegardé avec succès",
      "INVITES_SELECT_OFFERS_PROMPT":"Veuillez inclure au moins  une offre dans l'invitation ",
      "INVITES_UPDATE_SUCCESS":"Invitation mise à jour avec succès",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Date de fin",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Date de début",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"Voulez-vous que cela soit dû comme un ajustement dans cette facture ?",
      "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports de factures et de cotisations.",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Tableau de bord",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Factures",
      "INVOICE_COMMENTS":"Facture",
      "INVOICE_COUPON_APPLIED_MESSAGE":"Coupon d'escompte <strong>{{CouponCode}}*</strong> appliqué à cette facture",
      "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Solde dû",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Sélectionner tout",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"Courriel envoyé avec succès à la liste des utilisateurs",
      "INVOICE_FOOTER_LINE_DESC":"Apparaît sur la vue de la facture et sur le PDF de la facture sous forme de ligne de pied de page",
      "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Apparaît sur la vue de la facture et sur le PDF de la facture sous forme de ligne de pied de page",
      "INVOICE_FOOTER_LINE_DISP":"Ligne de pied de page de facture",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Ligne de pied de page de facture",
      "INVOICE_GENERATION_REASON":"Motif de génération de la facture",
      "INVOICE_GENERATION_STATUS_TITLE":"Statut de génération de facture",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Aller au résumé des revenus et des coûts",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Télécharger des factures ou les Paiements",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Retour au résumé des revenus et des coûts",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Télécharger des factures ou les Paiements",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importation de factures clients réussie",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Veuillez sélectionner au moins une facture client avant de continuer.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Factures à télécharger pour les clients",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Code de devise",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Nom qualifié",
      "INVOICE_INVOICE_APPROVE":"Approuver",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Retour aux postes de facturation",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Retour aux factures",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Télécharger une facture",
      "INVOICE_INVOICE_CAPTION_TEXT":"Facture pour",
      "INVOICE_INVOICE_FINALIZE":"Finaliser",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Résumé de compte",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adresse",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Client",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Résumé de facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"période de facturation",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Date de facturation",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"numéro de TVA",
      "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Facture fiscale",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Détail de la facture",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Facture de cotisation",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Solde des paiements antérieurs",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Basé sur licence",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Basé sur l'utilisation",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Montant",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Type de charge",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Coût pour le partenaire",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Code promotionnel",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Description",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Rabais",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Montant d'escompte",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Prix de l'offre",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Quantité",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Date de fin",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Montant de la ligne",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Particuliers",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Montant total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Montant du sous-total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Domaine",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Date de début",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Montant de la taxe",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Pourcentage d'impôt",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Prix unitaire",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Aucune Facture trouvée. Veuillez contacter l'équipe de support.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Paiements de facture",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Montant",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Date",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Raison de l'échec",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Total",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Remarques",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Statut",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Montant dû",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Date d'échéance",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Date de la facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Statut",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Montant payé",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Montant restant",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Quantité utilisée",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Ajustements",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Instance de cycle au prorata",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Les factures échues",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Frais de prorata",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Frais au prorata lors de l'achat",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Frais à l'achat",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"Rembourser le montant",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Frais de renouvellement",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Taxes",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Niveau d'utilisation",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Crédit",
      "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--lié à {{linkedProductName}}",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>Domaine {{providerName}}  </b> {{providerTenantName}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Taux de conversion",
      "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Total",
      "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Éléments de ligne de facture avec taxes",
      "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Remise promotionnelle",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Actions",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Courriel",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Rapport sur les éléments de ligne de facture",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les éléments de ligne de facture",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Entrez l'adresse e-mail à laquelle vous souhaitez envoyer par e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur Soumettre pour recevoir un fichier contenant la liste des éléments de ligne de facture.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Envoyer » pour recevoir un fichier contenant la liste du rapport des éléments de ligne de facture.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Annuler",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Enregistrer les favoris",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Factures",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Paiements",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Facture générée le",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Raison de l'échec",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Montant restant",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Montant payé",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Date de paiement",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"Identifiant de transaction",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Remarques",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Statut de paiement ",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Quantité utilisée",
      "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Afficher les journaux de contacts",
      "INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importation de paiements de factures clients réussie",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Paiements de factures à télécharger pour les clients",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"Impossible de mettre à jour la date d'échéance avec une valeur nulle",
      "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"La propriété de la facture a été mise à jour avec succès",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"La date de début ne doit pas être supérieure à la date de fin",
      "INVOICE_SENT_SUCCESSFULLY":"Facture envoyée avec succès",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Approuvé pour examen par le client",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Finaliser la facture",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"En cours",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Facture générée",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Facture publiée",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Montant total payé",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Montant partiel payé",
      "INVOICE_TAB_NAME_COMMENTS":"Commentaires",
      "INVOICE_TAB_NAME_DETAILS":"Détails",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Notifications",
      "INVOICE_TRANSACTION_REPORT_HEADER":"Factures et rapport de cotisations",
      "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "INVOICE_WILLBE_MAILED":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "InvoiceCurrency":"Devise de la facture",
      "INVOICES_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Période de facturation",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance de la facture",
      "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Date de facture générée",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Est gérable uniquement par le partenaire",
      "IS_MASTER":"Maître",
      "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"L'approbation du partenaire est-elle requise pour les clients revendeurs",
      "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"Le produit est réactivé avec add-ons",
      "IS_PRODUCT_BEING_UPDATED_WITH_ADDONS":"Le produit est-il mis à jour avec des extensions",
      "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"Le filtre est disponible pour le client",
      "IS_TRIAL_OFFER":"Offre d'essai",
      "IsCustomPrice":"C'est un prix personnalisé",
      "JANUARY":"Janvier",
      "JETON D'ACCÈS":"Jeton d'accès",
      "JOURNÉES":"Journées)",
      "JULY":"Juillet",
      "JUNE":"Juin",
      "LABEL_ASSIGNED_RG":"Groupes de ressources affectées",
      "LABEL_BANNER_TEXT":"Message",
      "LABEL_BANNER_TEXT_TOOL_TIP":"Écrivez le message pour la bannière.",
      "LABEL_BILLING_ADDRESS":"Adresse de facturation",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"L'offre d'essai ne peut être achetée qu'une seule fois.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Cette offre d'essai est déjà disponible dans votre panier.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"Offre d'essai pour",
      "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Offre d'essai parent indisponible",
      "LABEL_Customer_Name":"nom du client",
      "LABEL_CUSTOMER_NAME":"nom du client",
      "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"le(s) produit(s) du plan ont été sélectionnés",
      "LABEL_DESC_SELECTED_PLANS":"le(s) plan(s) ont été choisis",
      "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"des variantes de produit ont été sélectionnées.",
      "LABEL_EFFECTIVE_ENTITY":"Entité Concernée",
      "LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "LABEL_EFFECTIVE_RECORD_NAME":"Enregistrement effectif",
      "LABEL_EFFECTIVE_TO":"Expire le",
      "LABEL_IS_ACTIVE":"Est actif",
      "LABEL_NOTIFICATION_RECIPIENTS":"Destinataires de notification",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Mettez les utilisateurs séparés par des virgules",
      "LABEL_NOTIFICATION_TABLE":"Notifications de bannière",
      "LABEL_NOTIFICATION_TYPE":"Style des messages",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Affecter une politique pour ",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Affectations de politique de",
      "LABEL_POLICY_CUSTOMER":"Client",
      "LABEL_POLICY_DEFINITION":"Définition de la politique",
      "LABEL_POLICY_DESCRIPTION":"Description de la politique",
      "LABEL_POLICY_NAME":"Nom de la politique",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "LABEL_POLICY_SUBSCRIPTION":"Abonnement",
      "LABEL_PORTAL_PAGE":"Portail",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"ajouter un rôle personnalisé",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Retour aux rôles personnalisés",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Ajouter un rôle personnalisé",
      "LABEL_ROLE_CUSTOMER":"Client",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "LABEL_ROLE_ROLE_DEFINITION":"Définition de rôle",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Description du rôle",
      "LABEL_ROLE_ROLE_NAME":"Nom de rôle",
      "LABEL_ROLE_SUBSCRIPTION":"Abonnement",
      "LABEL_SHIPPING_ADDRESS":"Adresse d'expédition",
      "LABEL_TEXT_ADDONS":"Extensions",
      "LABEL_TEXT_ADDRESS_1":"Adresse 1",
      "LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Disponible pour le client",
      "LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "LABEL_TEXT_BILLING_TYPE":"Type de facturation",
      "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" La fenêtre d'annulation expire dans",
      "LABEL_TEXT_CATEGORY":"Catégorie",
      "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Créer un compte Microsoft",
      "LABEL_TEXT_DISCOUNT":"Rabais",
      "LABEL_TEXT_DISTRIBUTER_OFFER":"Offres des distributeurs",
      "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Activer toutes les promotions",
      "LABEL_TEXT_LINKED_PRODUCT":"Produit lié",
      "LABEL_TEXT_NEW_COMMENCE_EXPERIENCE_VALIDITY_TERM":"Terme -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Catégorie :",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Abonnements logiciels",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Terme",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":"Terme -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Terme :",
      "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"Non disponible pour le client",
      "LABEL_TEXT_OWNER_NAME":"Le nom du propriétaire",
      "LABEL_TEXT_PRICE":"Prix",
      "LABEL_TEXT_RESERVED_INSTANCES":"RI",
      "LABEL_TEXT_RETAIL_PRICE":"Prix de détail",
      "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Abonnement source",
      "LABEL_TEXT_STATE_PROVINCE":"Province d'état",
      "LABEL_TEXT_STATUS":"Statut",
      "LABEL_TEXT_TARGET_SUBSCRIPTION":"Abonnement cible",
      "LABEL_TEXT_TAX_CODE":"Code fiscal",
      "LABEL_TEXT_TAX_TYPE":"Type de taxe",
      "LABEL_TEXT_TENANT_NAME":"Nom de domaine",
      "LABEL_TEXT_TYPE_IN_NAME":"Tapez un nom",
      "LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "LABEL_TEXT_ZIP_CODE":"Code postal",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Nouveau",
      "LABEL_UNASSIGNED_RG":"Groupes de ressources dissociés",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Charges Additionnelles",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Administrateur client",
      "LABLE_CUSTOMER_READER_ROLE":"Lecteur client",
      "LABLE_DISCOUNTS_TOTAL":"Rabais total",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Total des abonnements",
      "LABLE_TAXES_TOTAL":"Total des taxes ",
      "LABLE_TEXT_COST_PRICE":"Coût d'acquisition",
      "LABLE_TEXT_COST_TO_PARTNER":"Coût pour le partenaire",
      "LABLE_TEXT_DISPLAY_NAME":"Nom d'affichage",
      "LABLE_TEXT_EVENTS":"Événements",
      "LABLE_TEXT_FRIENDLY_NAME":"Nom convivial",
      "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"Disponible pour le client ?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"Promotion disponible pour le client ?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"Promotion disponible pour le revendeur ?",
      "LABLE_TEXT_MAXVALUE":"MaxValue : ",
      "LABLE_TEXT_MINVALUE":"MinValue : ",
      "LABLE_TEXT_OFFER_NAME":"Nom de l'offre",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur",
      "LABLE_TEXT_SALE_PRICE":"Prix de vente",
      "LABLE_TEXT_SALEPRICE":"Prix de vente : ",
      "LABLE_TEXT_SLAB":"Détails du bloc",
      "LABLE_TEXT_SLAB_SALE_PRICE":"Prix de vente (UdM)",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Coût pour le partenaire",
      "LABLE_TEXT_TYPE":"Type de facturation",
      "LABLE_TEXT_UNIT_OF_MEASURE":"(UdM)",
      "LABLE_WAIVER":"Renonciation",
      "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"La dernière tentative de chargement des données a abouti à un échec. Veuillez cliquer sur Actualiser pour synchroniser les dernières modifications de PSA vers C3.",
      "LATEST_COMMENTS":"Dernier commentaire",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"S'identifier",
      "LAYOUTLOGIN_HEADER_TEXT":"S'identifier",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Veuillez vous connecter pour continuer",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Systèmes Spektra. Tous droits réservés.",
      "LBL_COUPON_CODE_TEXT":"Code promotionnel",
      "LBL_DISCOUNT_TEXT":"Rabais",
      "LBL_OFF":"off",
      "LBL_TEXT_NOT_PROVIDED":"Non fourni",
      "LESS_THAN_LIST_PRICE":"lessThanListPrice",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Veuillez trouver les erreurs ci-dessous.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Cette opération ne peut pas être complétée car les conditions préalables ne sont pas remplies. L'attribution d'une extension nécessite une offre de base et la révocation d'une offre de base nécessite la libération des extensions.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Offre",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Offres pré-requises",
      "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Demande rejetée",
      "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Télécharger le rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Obtenir le rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"Quantité finale",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Créé par",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nom du client",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Date du changement",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Nouveau statut",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Ancien statut",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Quantité d'ouverture",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Produit",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nom du revendeur",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nom du locataire lié",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"Statut des CGU",
      "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"La date \"de\" du rapport ne doit pas être supérieure à la date \"à\"",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Accepté",
      "LICENSE_CONSUMPTION_REPORT":"Rapport de consommation de licence",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Sélectionner",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Client",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Domaine",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Veuillez sélectionner un client",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Veuillez entrer le nom de l'abonnement",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Veuillez sélectionner un domaine",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filtres",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Différence de quantité affectée",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Disponible dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Disponible dans le centre partenaire",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Licences affectées dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Licences dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Client",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"Adresse courriel",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Différence de quantité totale",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Produits mappés",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Licences affectées dans Provider",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Licences dans le fournisseur",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Licences inutilisées pour le fournisseur",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Abonnement",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Domaine",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Afficher les détails",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Rapport de consommation de licence",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Rapport sur les revenus",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Exporter le rapport sur la consommation des licences en format CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Domaines",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Récupération du résumé de licence",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Télécharger le rapport sommaire des licences",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Résumé de licence",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nom du revendeur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Cycle de facturation",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Nom de l'abonnement parent",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Nom du fournisseur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Identifiant de domaine du fournisseur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Quantité",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Renouvellement – (en jours)",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"Se renouvelle le",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Actions",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Date d'expiration",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Nom",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"Clé de bon",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Consommation de licence",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Libérer les licences inutilisées",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Libérer les sièges non utilisés",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Libérer les droits inutilisés. Veuillez patienter...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Affecté",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Produits mappés",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Total",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom d'abonnement ",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Actions",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Affecté",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Valide",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
      "LICENSES_TOOLTIP_MS_USER":"Licences",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Lien vers le profil de facturation de",
      "LINK_CUSTOMER_HEADER_TEXT":"Lien vers le fournisseur de",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Vous êtes lié à tous les fournisseurs présents",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"Type de client",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"Type de client",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Identifiant de domaine",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Nom de domaine",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Liaison réussie du client Microsoft non-csp à c3.",
      "LINK_RESELLER_CAPTION_TEXT":"Lier un revendeur",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Fournisseur",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Identifiant de revendeur (comme pour le fournisseur)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Supprimer",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Identifiant d'entreprise",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Nom du fournisseur",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Identifiant du revendeur fournisseur",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Vous êtes lié à tous vos profils fournisseurs.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Lien vers {{providerName}} a été supprimé.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Lié à {{providerName}} avec succès.",
      "LINK_TEXT_CLICK_HERE":"Cliquez ici",
      "linkCustomerBillingProfile":"Lien vers le profil de facturation",
      "LINKED_PRODUCT_INFO_SHOPE":"Ce produit est lié avec ",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Sélectionner",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Lien vers un produit",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Actions",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Validation de la disponibilité du domaine",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Catégorie",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Fournisseur",
      "LOADING_INDICATOR_MESSAGE":"Traitement...",
      "LOADING_MESSAGE":"Chargement...",
      "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>les promotions du mois en cours sont déjà chargées</p> <p> voulez-vous recharger les promotions ?</p>",
      "LOADPROMOTION_CATEGORY_TOOLTIP":"Veuillez choisir la catégorie de produit dans la liste déroulante",
      "LOADPROMOTION_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Donnez-nous un moment pour compléter votre personnification en tant que",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Salutations,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Vous êtes maintenant connecté. Donnez-nous un moment pour préparer l'application pour que vous puissiez y accéder...",
      "LOW":"Faible",
      "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Appliquer X % sur le balisage",
      "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Appliquer X % sur le prix du partenaire",
      "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Appliquer X % sur le balisage",
      "MACRO_COPY_PARTNERT_PRICE":"Copier le prix du partenaire",
      "MACRO_COPY_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur de copie",
      "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"Le prix de vente du produit plan sera la somme du prix du partenaire + X % sur la marge entre le prix du partenaire et le prix de vente du fournisseur.",
      "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"Le prix de vente du produit plan sera le prix partenaire + X % (prix partenaire)",
      "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera le prix de vente du fournisseur + X % (prix de vente du fournisseur)",
      "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera égal au prix partenaire",
      "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera égal au prix de vente du fournisseur.",
      "MACRO_DET_EXAMPLE":"Exemple:",
      "MACRO_DET_PARTNER_PRICE":"Prix partenaire",
      "MACRO_DET_PERCENTAGE":"Pourcentage(X)",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur",
      "MACRO_DET_SALE_PRICE":"Prix de vente:",
      "MACRO_PERCENTAGE_TOOLTIP_TEXT":"Macro%",
      "MAIL_SENT_SUCCESSFULY":"Courriel envoyé avec succès",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Considérer dans la version automatique",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Non",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Oui",
      "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Est pris en compte dans la version automatique",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Considéré dans la publication automatique",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Gérable exclusivement par le partenaire",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Votre demande d'attribution de licences est en attente. Veuillez revenir sur cette page après un certain moment pour les mises à jour.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Veuillez entrer les adresses email valides des utilisateurs dans le domaine",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"Utilisateur {{username}} détient déjà une licence pour {{subscriptionname}}",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner au moins un fichier",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Êtes-vous certain de vouloir ignorer les erreurs ?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Le nombre minimum de droits pour un abonnement doit être de 1. Ne peut pas libérer de droits si le nombre disponible est de 1.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Êtes-vous certain de vouloir libérer les licences inutilisées ?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Les licences non utilisées ont été libérées avec succès",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Veuillez ajouter au moins un utilisateur à la section licences à supprimer",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Veuillez sélectionner un utilisateur dans la liste suggérée avant de cliquer sur le bouton Ajouter.",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"Attribution de licence effectuée avec succès",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Une erreur s’est produite pendant le traitement du fichier, veuillez réessayer",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Détails de licence chargés avec succès. Veuillez revenir à cet écran après un certain moment afin de vérifier le statut.",
      "MANAGE_NAME_CHANGE_ERROR":"Le nom ne peut pas être vide",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Consulter les offres)",
      "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Modifier la macro",
      "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Les produits ne peuvent pas être liés à cette offre",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Afficher uniquement la promotion",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Oui",
      "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Tapez un nom",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Copiez le Prix courant dans le prix de vente, le prix de vente sera identique au Prix courant",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Ajouter X% au Prix courant, le prix de vente sera X% du Prix courant + Prix courant",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Augmenter le prix de Y cents, le prix de vente sera le Prix courant + Y cents",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Effacer les prix de toutes les offres sélectionnées",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"Ajouter X% sur la marge, le prix de vente sera le Prix courant + (ERPPrice-Prix courant)*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"Ajouter X% au prix MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"le prix de vente sera X% du prix MS + prix MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"Copier le prix MS dans le prix de vente ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"prix de vente sera le même que le prix MS ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Ajouter X% sur la marge",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"le prix de vente sera le Prix MS + (ERPPrice-Prix MS)*X% ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Augmenter le prix de Y cents",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"prix de vente sera Prix MS + Y cents ",
      "MANAGE_PLAN_LBL_1":"Copier le Prix courant dans le prix de vente",
      "MANAGE_PLAN_LBL_2":"Ajouter X% au Prix courant",
      "MANAGE_PLAN_LBL_3":"Augmenter le prix de Y cents",
      "MANAGE_PLAN_LBL_4":"Effacer tous les prix",
      "MANAGE_PLAN_LBL_5":"Ajouter X% sur la marge",
      "MANAGE_PLAN_LBL_6":"Copiez le prix ERP dans le prix de vente",
      "MANAGE_PLAN_MACRO_APPLIED":"Macro appliquée",
      "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"La macro nouvellement sélectionnée s'appliquera aux offres qui seront ajoutées au plan à l'avenir et n'affectera pas la macro existante sur les offres qui ont déjà été ajoutées.",
      "MANAGE_PLAN_NO_MACRO_APPLIED":"Aucune macro appliquée",
      "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"Non disponible à l'achat",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Fermer",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Offres incluses",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Fermer",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Nom",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Noms des parents",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer toutes les extensions ?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ce produit ?",
      "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}></b> est une offre parent pour un produit d'essai. <br/> La suppression de l'offre premium entraînera également la suppression de l'offre d'essai associée.<br/> Êtes-vous sûr de vouloir supprimer <b>{{productName}}<b/> ?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Les extensions sont ajoutées au plan, veuillez enregistrer le plan pour terminer votre opération.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"ajouté au plan !",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"ajouté au plan avec des extensions",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" ajouté au forfait avec une offre parentale",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Aucune extension correspondante trouvée",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Voulez-vous retirer l'offre?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"L'extension que vous essayez d'ajouter est déjà incluse",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Types de consommation",
      "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"L'offre de sentiers est-elle",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Statut de l'abonnement",
      "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Marchés pris en charge",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Veuillez vérifier les offres suivantes, car le Prix de vente est inférieur au Prix courant.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Cliquez sur « OK » pour soumettre les modifications du plan. « ANNULER » pour revérifier les offres du plan.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"L’offre sélectionnée était déjà incluse",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Veuillez fournir un pseudonyme et le prix effectif pour les offres incluses dans le plan",
      "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Veuillez vérifier les offres suivantes car le prix de vente est supérieur au prix ERP.",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Veuillez ajouter des offres au plan",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Ajouter plus d'extensions",
      "MANAGE_PONUMBER_CHANGE_ERROR":"Le PONumber ne peut pas être vide",
      "MANAGE_PRODUCT_OWNERSHIP":"Gérer la propriété du produit",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Échec de la gestion de la propriété du produit",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"La gestion de la propriété du produit n'est pas autorisée car une affectation de licence est en cours pour l'un des produits ou ses extensions. Veuillez essayer plus tard.",
      "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Nouveaux coupons ajoutés avec succès",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Effacer les prix",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Rendre le prix MS égal au prix du revendeur – le prix du revendeur sera le même que le prix MS.",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"Ajouter X% au prix MS – Le prix du revendeur sera X% du prix MS + prix MS",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Augmenter le prix de Y centimes – le prix du revendeur sera MS Price + Y centimes",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Effacer les prix de toutes les offres sélectionnées",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"Ajouter X% à la marge - Le prix du revendeur sera le prix MS + (prix ERP- prix MS)*X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Si vous voulez ajouter les offres du revendeur, vous devez suivre les instructions suivantes",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Articles sélectionnés",
      "MANAGE_RELLER_OFFERS_LBL_1":"Rendre le prix MS égal au prix du revendeur",
      "MANAGE_RELLER_OFFERS_LBL_2":"Ajouter X% au prix MS ",
      "MANAGE_RELLER_OFFERS_LBL_3":"Augmenter le prix de Y cents",
      "MANAGE_RELLER_OFFERS_LBL_4":"Effacer tous les prix",
      "MANAGE_RELLER_OFFERS_LBL_5":"Ajouter X% sur la marge",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Voulez-vous supprimer toutes les offres filtrées des offres sélectionnées ?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous retirer toutes les offres sélectionnées?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Voulez-vous sélectionner toutes les offres filtrées ?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner toutes les offres?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Valide de",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Type de licence",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Prix du revendeur",
      "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Si vous souhaitez réduire la quantité, veuillez contacter l'équipe d'assistance.",
      "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Retour aux renouvellements programmés",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Annuler",
      "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Créer un nouvel horaire",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Soumettre",
      "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Quantité cumulée",
      "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Abonnement actuel",
      "MANAGE_RENEWAL_DESCRIPTION_1":"Les modifications prennent effet à la date de renouvellement de l'abonnement : <b>{{renewalDate}}</b>. Pour les mises à niveau de SKU, si la quantité ne change pas, les licences seront attribuées automatiquement. Sinon, vous devrez attribuer manuellement des licences.",
      "MANAGE_RENEWAL_HEADER_TEXT":"Gérer le renouvellement",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Actuel : {{currentEnddate}}",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Choisissez parmi différents SKU :",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Choisissez un cycle de facturation différent :",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Choisissez un terme différent :",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Date de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Alignement des dates de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Type de date de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Planifier le produit",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Quantité",
      "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Choisissez un produit de plan éligible pour votre abonnement (utilisez cette option si vous souhaitez modifier le SKU/le cycle de facturation/la durée ainsi que la planification des modifications)",
      "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Terme",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Produit de plan précédemment sélectionné pour un renouvellement programmé pour un changement de SKU/Durée/cycle de facturation :",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Nom du produit du plan",
      "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Vous pouvez mettre fin à votre abonnement avec un abonnement OnlineServicesNCE sans essai existant ou aligner la date de fin sur le mois civil en choisissant une date de fin appropriée en fonction de la durée du terme.",
      "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Calendrier de mise à jour",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Offres",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Revendeurs",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Ajouter les offres revendeurs",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Offres",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Veuillez fournir un prix effectif pour les offres sélectionnées",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Offres sauvegardées avec succès",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Veuillez ajouter des offres au revendeur",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Toutes les offres sélectionnées. Si vous voulez modifier quelque chose lié à une offre de plan, vous devez passer par Modifier le plan !",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Liste des plans revendeurs",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Statut du plan mis à jour avec succès",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Affecter",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Désactiver",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Activer",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Nom",
      "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Marchés pris en charge",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Expire le",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Renouvellement automatique activé",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"ID Produit ",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Si le renouvellement automatique est désactivé, cet abonnement expire le",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":"Si le renouvellement automatique est désactivé, cet abonnement expire à la fin de la période de validité",
      "manageplans":"Gérer les plans",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"Voulez-vous supprimer le groupe de ressources ?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Mandater le client pour mettre à jour les détails de paiement.",
      "MANUAL_SYNC_PUPUP_TITLE":"Synchronisation manuelle",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Correctif appliqué avec succès",
      "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Veuillez sélectionner un accord PSA",
      "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Veuillez sélectionner un contrat",
      "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Veuillez sélectionner un client",
      "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Veuillez sélectionner un client PSA",
      "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Veuillez sélectionner un produit PSA",
      "MARCH":"Mars",
      "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer cette adresse par défaut ?",
      "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer cet e-mail comme e-mail par défaut ?",
      "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer ce numéro de téléphone comme numéro par défaut ?",
      "Markup":"Majoration",
      "MATCHING_PRODUCT_PLAN_TEXT":"Plan:",
      "MAY":"Mai",
      "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Création de paiement récurrent. Vous pouvez voir une charge de 1,00 $ sur votre carte qui sera ajustée en crédit sur votre prochaine facture.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"La banque du client (Émetteur de la carte) a refusé la transaction. Le client doit contacter sa banque et réessayer la transaction.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"La banque du client a refusé la transaction et demande au marchand de contacter la banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"La transaction a réussi.",
      "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"La transaction a réussi.",
      "MCB_ERROR_CODE_APPROVED_VIP":"La transaction a réussi. (Ce code n'est pas utilisé en Australie.)",
      "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"La banque du client (Émetteur de la carte) a refusé la transaction car elle n'autorise pas les transactions provenant de commandes par courrier/téléphone, fax, e-mail ou Internet. Cette erreur est associée aux clients qui tentent d'utiliser une Discover Card. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"La banque du client a refusé la transaction et demande au client de contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CAPTURE_CARD":"La banque du client a refusé la transaction car la carte est suspectée d'être contrefaite. La banque du client (Émetteur de la carte) a demandé que la carte de crédit de votre client soit conservée. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit du client soit conservée (carte déclarée perdue ou volée). Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"La banque du client ne peut pas être contactée pour autoriser la transaction. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"La banque du client n'est temporairement pas en mesure de traiter la carte de crédit de ce client. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"La banque du client a refusé la transaction car le CVV est incorrect. Le client doit vérifier les détails du CVV et réessayer. En cas d'échec, le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_DO_NOT_HONOUR":"La banque du client a refusé la transaction car le numéro de carte de crédit a échoué à un contrôle de sécurité ou les fonds ont été gelés ou épuisés. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"La banque du client a refusé la transaction car cette transaction semble être une transmission en double. Aucune action nécessaire.",
      "MCB_ERROR_CODE_ERROR":"La banque du client (Émetteur de la carte) a refusé la transaction car il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"La banque du client a refusé la transaction car le client a dépassé la limite de fréquence de retrait. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"La banque du client a refusé la transaction car elle dépassera la limite de la carte du client. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXPIRED_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit semble avoir expiré. Le client doit vérifier la date d'expiration saisie et réessayer, ou utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit a expiré ou la date est incorrecte. Vérifiez la date d'expiration dans la transaction et essayez de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_FORMAT_ERROR":"La banque du client (Émetteur de la carte) ne reconnaît pas les détails de la transaction. Le client doit vérifier les informations sur la transaction et réessayer de traiter la transaction. Généralement dû à un TID incorrect.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"La banque du Client a refusé la transaction car cette carte de crédit ne peut pas être utilisée pour ce type de transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"La banque du Client a refusé la transaction car cette carte de crédit ne peut pas être utilisée pour ce type de transaction. Celui-ci peut être associé à un numéro de carte de crédit de test. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"La banque du client (Émetteur de la carte) a refusé la transaction car elle n'autorise pas ce type de transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"La transaction a réussi. Certaines banques utilisent ce code au lieu de '00'.",
      "MCB_ERROR_CODE_INCORRECT_PIN":"La banque du client (Émetteur de la carte) a refusé la transaction car le client a saisi un code PIN incorrect. Le client doit ressaisir son NIP, sinon utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit ne dispose pas de fonds suffisants. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_INVALID_AMOUNT":"Un caractère non valide (par exemple, un signe dollar ou un espace) peut être transmis à la passerelle. Vérifiez le code de votre site Web.",
      "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit n'existe pas. Vérifiez les informations de la carte de crédit et réessayez de traiter la transaction.",
      "MCB_ERROR_CODE_INVALID_TRANSACTION":"La banque du client (Émetteur de la carte) a refusé la transaction en raison d'un format ou d'un champ non valide. Vérifiez les informations de transaction et essayez de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_LOST_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte a été déclarée perdue. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CARD_RECORD":"La banque du client a refusé la transaction car le numéro de carte de crédit n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit est associé à un compte chèque qui n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"La banque du client a refusé la transaction car le numéro de carte de crédit utilisé n'est pas un compte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le type de compte sélectionné n'est pas valide pour ce numéro de carte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_ISSUER":"La banque du client (Émetteur de la carte) n'existe pas. Vérifiez les informations de la carte de crédit et réessayez de traiter la transaction.",
      "MCB_ERROR_CODE_NO_MERCHANT":"L'identifiant marchand n'est pas valide, vous devez contacter votre banque et vous assurer que vous avez fourni le bon numéro de compte marchand.",
      "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit est associé à un compte d'épargne qui n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le type de compte sélectionné n'est pas valide pour ce numéro de carte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"La banque du client a refusé la transaction en raison du montant à traiter. Le client doit vérifier le montant de la transaction et réessayer.",
      "MCB_ERROR_CODE_PICK_UP_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée). Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"La banque du client a refusé la transaction, car le client a saisi le code PIN incorrect plus de trois fois. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"La banque du client (Émetteur de la carte) a refusé la transaction car le client a saisi trois fois un code PIN incorrect. La banque du client (émetteur de la carte) vous a demandé de conserver la carte de crédit. Le client doit utiliser une autre carte de crédit et contacter sa banque.",
      "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"La transaction n'a pas été traitée et le client doit tenter de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_RESTRICTED_CARD":"La banque du client a refusé la transaction car la carte de crédit a certaines restrictions. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit du client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_SECURITY_VIOLATION":"La banque du client a refusé la transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_STOLEN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte a été déclarée volée. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD":"La banque du client a refusé cette transaction car la carte de crédit semble frauduleuse.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction en raison d'une suspicion de fraude sur ce numéro de carte de crédit.",
      "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"La banque du client (Émetteur de la carte) ne peut pas être contactée pendant la transaction. Le client doit vérifier les informations de sa carte de crédit et réessayer de traiter la transaction.",
      "MCB_ERROR_CODE_SYSTEM_ERROR":"La banque du client n'a pas été en mesure de traiter la transaction. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_TRANSACTION_APPROVED":"La transaction a réussi.",
      "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"La banque du client (Émetteur de la carte) ne reconnaît pas les détails de la carte de crédit. Le client doit vérifier les informations de sa carte de crédit et réessayer de traiter la transaction.",
      "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"La banque du client est introuvable pour l'acheminement. Ce code de réponse est souvent renvoyé lorsque le client utilise un numéro de carte de crédit de test. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Une erreur non spécifiée s'est produite.",
      "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Numéro de carte invalide ou manquant.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Mois d'expiration invalide ou manquant.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Année d'expiration invalide ou manquante.",
      "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Code de sécurité invalide.",
      "MEDIUM":"Moyen",
      "MENU_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "MENU_ADMINISTRATION":"Administration",
      "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notifications personnalisées",
      "MENU_ADMINISTRATION_ENGAGE":"Engagez la <sup class='text-warning'> bêta</sup>",
      "MENU_ADMINISTRATION_MANAGE":"Notifications de bannière",
      "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Notifications de bannière",
      "MENU_ADMINISTRATION_MANAGE_HEADING":"Administration",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Notifications",
      "MENU_ADMINISTRATION_WEBHOOK":"Webhook",
      "MENU_AZURE_ADVISOR":"Conseiller Azure",
      "MENU_AZURE_BILLING_REPORT":"Rapport de facturation Azure",
      "MENU_AZURE_CUSTOM_POLICY":"Politiques personnalisées",
      "MENU_AZURE_CUSTOM_ROLE":"Rôles personnalisés",
      "MENU_AZURE_MARGIN":"Soutien C3",
      "MENU_AZURE_SUBACRIPTION":"Abonnement Azure",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft",
      "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Commissions",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Attributs du produit",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Détails des citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Liste des citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Utilisation du rapport",
      "MENU_CONTACT_LOG":"Notifications",
      "MENU_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "MENU_CUSTOMER_ANALYTICS":"Analytique client",
      "MENU_CUSTOMER_COMMENTS":"Commentaires",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consommation de licence",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Rapport Power BI",
      "MENU_CUSTOMER_PROFILE":"Profil",
      "MENU_DASHBOARD":"Tableau de bord",
      "MENU_EXISTING_ACCOUNT_MANAGERS":"Gestionnaires existants",
      "MENU_HELP":"Aide",
      "MENU_LICENSE_CHANGE":"Rapport de changement de licence",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Rapport récapitulatif de consommation de licence",
      "MENU_LICENSE_SUMMARY_REPORT":"Rapport récapitulatif de licence",
      "MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer les droits Azure",
      "MENU_MANAGE_Azure_ENTITLEMENTS":"Gérer les droits d'Azure",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Approvisionnement des offres personnalisées ",
      "MENU_ONBOARDED_CUSTOMERS_REPORT":"Analyse d'intégration",
      "MENU_PARTNER_AUDIT_LOG":"Journal d'audits",
      "MENU_PARTNER_COMMISSION_REPORT":"Rapport de commission",
      "MENU_PARTNER_COUPON":"Coupons",
      "MENU_PARTNER_DOCUMENTATION":"Documentation",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "MENU_PARTNER_SUPPORT_LOG":"Soutien",
      "MENU_PARTNER_UPLOAD":"Charger",
      "MENU_PENDING_PURCHASE_REQUESTS":"Approbations",
      "MENU_PRODUCT_COMMISSIONS":"Commissions",
      "MENU_PRODUCT_EXTENSIONS":"Attributs du produit",
      "MENU_PRODUCT_SEQUENCE":"Séquence de produit",
      "MENU_PURCHSED_PRODUCT_MAPPING":"Mappage des produits achetés",
      "MENU_RENEWAL_MANAGER":"Responsable du renouvellement",
      "MENU_REPORT_USAGE":"Utilisation",
      "MENU_REPORTS_FOR_CUSTOMER":"Rapports",
      "MENU_RESELLER_PLAN":"Plan de revendeur",
      "MENU_RESELLER_PROFILE":"Profil d'entreprise",
      "MENU_SCHEDULED_REPORTS":"Rapports planifiés",
      "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Destinataires pour",
      "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Exécuter l'historique pour",
      "MENU_SETTINGS":"Paramètres",
      "MENU_TOPBAR_SETTINGS":"Paramètres",
      "MENU_USAGE_REPORT":"Rapport d'utilisation",
      "MENU_USERS":"Gérer les accès",
      "MENUS_ANALYTICS":"Analyses",
      "MENUS_ANALYZE":"Analyser",
      "MENUS_AUDITLOGS":"Journal d'audit ",
      "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Gérer les tarifs des niveaux d'autorisation Azure",
      "MENUS_AZURE_ESTIMATE":"Estimation Azure",
      "MENUS_AZURE_GROUPS":"Groupes Azure ",
      "MENUS_AZURE_MANGE":"Gestion d'Azure ",
      "MENUS_AZURE_REPORTS":"Rapports Azure ",
      "MENUS_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "MENUS_CART":"Panier",
      "MENUS_COUPONS":"Coupons",
      "MENUS_CUSTOM_OFFERS":"Offres partenaires",
      "MENUS_CUSTOMER_CART":"Panier",
      "MENUS_CUSTOMER_DASHBOARD":"Tableau de bord",
      "MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Utilisateurs",
      "MENUS_CUSTOMER_ONBOARDING":"Intégration de client ",
      "MENUS_CUSTOMER_ORDERS":"Commandes",
      "MENUS_CUSTOMER_PRODUCTS":"Produits",
      "MENUS_CUSTOMER_PROFILE":"Profil",
      "MENUS_CUSTOMER_SHOP":"Magasin",
      "MENUS_CUSTOMERS":"Clients",
      "MENUS_DAAS":"DaaS<sup class='text-danger'>BÊTA</sup>",
      "MENUS_DASHBOARD":"Tableau de bord",
      "MENUS_DISTRIBUTOR_OFFERS":"Offres des distributeurs",
      "MENUS_DOCUMENTATION":"Documentation<sup class='text-danger'>BETA</sup>",
      "MENUS_ENTITY_INVOICES":"Factures",
      "MENUS_FAQ":"FAQ",
      "MENUS_INVITES":"Invitations",
      "MENUS_INVOICES":"Factures",
      "MENUS_LICENSE_CONSUMPTION":"Consommation de licence",
      "MENUS_LICENSE_LIST":"Liste des licences",
      "MENUS_MS_OFFERS":"Offres MS",
      "MENUS_OPERATING_ENTITIES":"Entités opérationnelles",
      "MENUS_ORDERS":"Commandes",
      "MENUS_PARTNER_BUNDLES":"Forfaits",
      "MENUS_PARTNER_CUSTOMERS":"Clients",
      "MENUS_PARTNER_DASHBOARD":"Tableau de bord",
      "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Statut de paiement en attente",
      "MENUS_PARTNER_PLANS":"Plans",
      "MENUS_PARTNER_QUOTES":"Citation",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_PARTNER_SETTINGS":"Paramètres",
      "MENUS_PLANS":"Plans",
      "MENUS_POWER_BI_REPORTS":"Rapports Power BI",
      "MENUS_PRODUCTS":"Produits",
      "MENUS_PROFILE":"Profil",
      "MENUS_RESELLER_DASHBOARD":"Tableau de bord",
      "MENUS_RESELLER_INVOICES":"Facturation",
      "MENUS_RESELLER_PAYMENTS":"Paiements",
      "MENUS_RESELLER_PLANS":"Plans",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Prix et offres",
      "MENUS_RESELLER_PROFILE":"Profil d'entreprise",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_RESELLER_SETTINGS":"Paramètres",
      "MENUS_RESELLERS":"Revendeurs",
      "MENUS_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_SELL":"Vendre",
      "MENUS_SELL_DIRECT":"Vente directe",
      "MENUS_SELL_INDIRECT":"Vente indirect",
      "MENUS_SELLER_INDIRECT":"Vente indirect",
      "MENUS_SHOP":"Magasin",
      "MENUS_SUBSCRIPTIONS":"Abonnements",
      "MENUS_SUPPORT":"Soutien",
      "MENUS_TAXES":"Taxes",
      "MENUS_USER_MANAGEMENT":"Gestion des utilisateurs",
      "MENUS_USERS":"Utilisateurs",
      "merge":"bifurquer",
      "MESSAGE_ALREADY_EXISTING":"Le message existe déjà pour les entités concernées",
      "MESSAGE_BODY":"Corps du message",
      "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Veuillez vérifier tous les champs obligatoires et réessayer de soumettre",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Pour l'entité Variante de produit/Client, la même combinaison ne peut pas être réinsérée.",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Veuillez sélectionner tous les champs",
      "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND":"Aucun enregistrement trouvé!",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"Aucune donnée à présenter, soit en raison de l'absence d'utilisation pendant la période de facturation en cours ou car la facture de Microsoft n'est pas encore disponible. Veuillez vérifier de nouveau sous peu.",
      "MESSAGE_NOTIFCATION_SAVED":"Notification de message enregistrée avec succès",
      "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Veuillez résoudre toutes les erreurs de validation et réessayer de soumettre",
      "MESSAGE_NOTIFICATION_EDIT_MODE":"Modifier les notifications de messages",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Modifier",
      "MESSAGE_NOTIFICATION_ID":"Identifiant",
      "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Veuillez sélectionner au moins un destinataire pour la notification de message",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Actions",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Date effective",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Expire le",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Corps du message",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Style des messages",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Nom de la page",
      "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Entités impliquées",
      "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Un produit a été sélectionné",
      "MESSAGE_RECORDS_POP_UP":"liste d'enregistrements",
      "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Une erreur est survenue",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"Aucun enregistrement trouvé !",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Désolé, vous n'avez pas de sites et de départements configurés. Vous pouvez ajouter de nouvelles entités opérationnelles à partir de la page Profil",
      "MESSAGE_TEXT_PLEASE_WAIT":"Veuillez patienter...",
      "MESSAGE_TOOLTIP_AZUREGROUP":"Info, Avertir, Erreur, Danger",
      "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Sélectionnez le type de message",
      "MESSAGE_TYPE_NAME":"Nom du type de message",
      "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Veuillez sélectionner tous les champs",
      "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"URL invalide. Veuillez saisir une URL valide.",
      "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Veuillez vérifier tous les champs obligatoires et réessayer de soumettre",
      "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"L'URL est valide !",
      "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" Les valeurs décimales ne sont pas autorisées.",
      "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Nouveau niveau",
      "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Entrez un numéro valide.",
      "METERED_BILLING_TEXT_MODAL_HEADER":"Niveaux d'utilisation",
      "METTRE À JOUR LE PROFIL":"Profil mis à jour avec succès",
      "Microsoft":"Microsoft",
      "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Type d'attestation :",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Supprimer",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Catégorie",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Rabais",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Supprimer",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Catégorie",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabais",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Majoration",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Pourcentage",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Produit lié",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA accepté par",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA accepté le",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Statut d'acceptation",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"Non fourni",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Fourni",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Acceptation du client confirmée par le partenaire",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Acceptation du client via Microsoft",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Contrat client Microsoft enregistré avec succès.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Mot de passe temporaire",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nom d'utilisateur",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"L'utilisateur a été créé avec succès et ci-dessous se trouvent les informations d'identification de l'utilisateur.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Détails de l'utilisateur",
      "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Après avoir fermé cette fenêtre contextuelle, vous ne pourrez plus voir ce mot de passe temporaire.",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"Échec de la capture du consentement de l'API de tarification Microsoft",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Le consentement de l'API de tarification Microsoft a été capturé avec succès",
      "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"La tentative d'accès à l'API de tarification Microsoft a réussi.",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} de {{applicationName}} souhaite être votre fournisseur de solutions Microsoft Cloud pour votre compte Microsoft Cloud.<br/><br/>Pour accepter l'invitation, procédez comme suit :<br/><br/>Étape 1 : Vérifiez et assurez-vous que le profil client est complet ici : https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Étape 2 : Une fois le profil client complété (l'actualisation peut prendre jusqu'à 5 minutes après la mise à jour du profil), cliquez sur le lien suivant pour accepter cette invitation et autoriser {{applicationName}} à être votre fournisseur de solutions Microsoft Cloud et accepter le client Microsoft Accord.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Remarque : un utilisateur disposant de l'autorisation d'administrateur général est requis pour accepter la relation.<br/><br/><br/>Informations supplémentaires sur le partenaire :<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Cher client,<br/><br/>Pour vous fournir une gamme complète de solutions, de services et d'assistance cloud, {{resellerName}} travaille en étroite collaboration avec {{partnerCompanyName}}. Pour autoriser {{resellerName}} et {{partnerCompanyName}} à gérer votre compte, procédez comme suit : <br/><br/>Étape 1 : vérifiez et assurez-vous que le profil client est complet ici : https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/> Étape 2 : une fois le profil client complet (l'actualisation peut prendre jusqu'à 5 minutes après la mise à jour du profil), cliquez sur le lien suivant pour accepter cette option invitation.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Remarque : un utilisateur disposant d'une autorisation d'administrateur global est requis pour accepter la relation.<br/><br/>{{resellerName}} continuera d'être votre expert local de référence pour les produits, les abonnements, les licences et l'assistance. Soyez assuré que sa relation commerciale avec vous reste la même.<br/><br/>N'hésitez pas à nous contacter si vous avez des questions. Nous et {{resellerName}} sommes impatients de travailler avec vous !<br/><br/>Cordialement,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marge pour l'abonnement au logiciel",
      "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration pour l'abonnement tiers",
      "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Toutes les licences existantes et l'accès au portail seront supprimés. Êtes-vous sûr de vouloir continuer ?",
      "MicrosoftNonCSP":"Microsoft non CSP",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Compte de migration créé avec succès avec le username :",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Comptes de migration",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Client",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Compte de migration",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Créer",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Comptes de migration",
      "MIGRATION_USER_CREATE_SUCCESS":"Compte de migration créé avec succès avec le  {{username}}",
      "MINUTES":"Minute(s) ",
      "MISSING_PLAN_OFFER_ADD_FAILED":"Le rapprochement a échoué, veuillez contacter le support pour obtenir de l'aide",
      "MISSING_PLAN_OFFER_ADDED":"Rapprochement complété en utilisant l'offre choisie",
      "MNUAL_SYNC_PURCHASED_DATE":" Date d'achat :",
      "MODAL_HEADER_TEXT_DESC_FILTER":"Filtrer",
      "Month(s)":"Mois",
      "MULTIPLE_COMMENT_STATIC":"Commentaires",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Sélectionner",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }><br />Succès : {{ importSuccessCount }}",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }><br />Succès : {{ validationSuccessCount }}",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Instructions",
      "Name":"Nom",
      "NAME":"Nom de l'entité",
      "NCE_ACRONYM":"Services en ligne NCE",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Politique d'annulation",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 heures : Remboursement complet",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 heures : 1er jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 heures : 2e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 heures : 3e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 heures : 4e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 heures : 5e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 heures : 6e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Après 7 jours calendaires : l'annulation n'est pas autorisée",
      "NCE_INSTRUCTIONS_HEADING":"Instructions RCE",
      "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Prix de vente estimé du produit lié",
      "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>Ce produit suivant n'est pas éligible à la promotion. Veuillez contacter l'assistance pour appliquer la promotion. si vous ne voulez pas de promotion, continuez votre processus de commande.</p>",
      "NCE_PROMOTIONS_APPLIED":"Cette promotion est appliquée",
      "NCE_PROMOTIONS_AVAILABLE_TEXT":"Promotion disponible",
      "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Vérifier l'admissibilité à la promotion",
      "NCE_PROMOTIONS_NOT_APPLIED":"La promotion n'est pas encore appliquée",
      "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"Promotions disponibles",
      "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Fréquence de facturation",
      "NCE_PROMOTIONS_POPUP_CANCEL":"Annuler",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Fermer",
      "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"L'éligibilité du client sera validée lors de l'examen du panier",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"Rabais",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Date de fin",
      "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Remise promotionnelle pour le lancement de la nouvelle commeNCE",
      "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Détails de la promotion",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":"ID:",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":"Nom:",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Appliquer",
      "NCE_PROMOTIONS_POPUP_TERM":"Terme",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"La quantité/sièges peut être augmentée à tout moment.",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"La quantité/sièges peut être réduite dans les 7 jours calendaires, à sa précédente ou à la somme des quantités/sièges précédents.",
      "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Changement de quantité",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Modification du calendrier de renouvellement",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Le calendrier actuel sera supprimé si la quantité/le statut de renouvellement automatique/le cycle de facturation/la durée sont modifiés.",
      "NCE_SKU_NOT_PRESENT_IN_PLAN":"Produit non disponible dans le plan client",
      "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Veuillez accepter tous les termes et conditions",
      "NCE_TEXT":"Nouveau commeNCE...",
      "NEW_BILLING_CYCLE":"Nouveau cycle de facturation",
      "NEW_COMMENT_SEND":"Envoyer",
      "NEW_COMMENTS_RECIEVED":"Nouveau commentaire reçu",
      "NEW_CUSTOMER_ONBOARDING_ERRORS":"l'achat/la mise à jour des produits peut échouer",
      "NEW_DISTRIBUTOR_PRODUCT_ICON":"Nouvelle icône",
      "NEW_PARTNER_PRODUCT_ICON":"Nouvelle icône",
      "NEW_PRODUCT_STATUS":"Nouveau statut",
      "NewPrice":"NouveauPrix",
      "NEXT_SCHEDULE_RENEWAL_CHANGE":"Prochain changement de renouvellement d'horaire",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Recherche...",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Aucun article n'existe avec l'identifiant : {cartLineItemId} dans le panier",
      "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"Aucun administrateur client n’est disponible pour ce locataire.",
      "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Aucun client enregistré",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Aucun plan existant",
      "NO_PAYMENT_TYPES_ENABLED":"Aucun type de paiement n'est activé. Veuillez contacter le soutien technique.",
      "NO_PLAN_WITH_OFFER_AVAILABLE":"Il n'y a pas de plan avec toutes les offres disponibles. Veuillez en créer un",
      "NO_PRODUCT_SELECTED_PROMPT":"Veuillez sélectionner au moins un produit.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Aucun détail de facture n'a été trouvé pour la période de facturation ou le fournisseur sélectionné",
      "NO_RESOURCE_GROUP_LEFT":"Aucun groupe de ressources à affecter/aucun enregistrement trouvé !",
      "NOM":"Nom de l'entité",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Détails de produit non CSP",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Identifiant principal de service",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Clé principale de service",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"L'utilisateur du revendeur ne peut pas ajouter de produit partenaire sous le plan du revendeur.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Les produits fournisseurs suivants ne sont pas mappés avec les produits du plan. Si vous voulez continuer, cliquez sur Continuer, sinon cliquez sur Annuler et mapper ces produits",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Produits de fournisseur",
      "NOT_VALID_MESSAGE":"n'est pas un email valide",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Créer une bannière de notification",
      "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"L'étiquette client a été supprimée avec succès",
      "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"Le nom de l'abonnement a été modifié avec succès",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Cette valeur sera mise à jour sous peu",
      "notifications":"Notification",
      "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"La longueur des caractères doit être comprise entre 2 et 200 caractères",
      "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"La longueur des caractères doit être comprise entre 2 et 50 caractères",
      "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Date de fin personnalisée enregistrée avec succès",
      "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"URL du webhook non valide",
      "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"URL de webhook valide",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Entrez une adresse courriel et cliquez sur OK pour recevoir un fichier zip contenant une liste de factures PDF pour la période de facturation sélectionnée",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"Un courriel avec une liste de factures en format PDF (Zip) en pièce jointe sera envoyé à {{emailAddress}} sous peu.",
      "NOVEMBER":"Novembre",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
      "NUMBER_OF_PROMOTIONS_LOADED":"Nombre de promotions chargées",
      "NUMÉRO DE COMMANDE":"Numéro de commande",
      "NUMÉRO DE FACTURE":"Numéro de facture",
      "OCTOBER":"Octobre",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Le pseudonyme de l'offre {{FriendlyName}} doit être unique",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Afficher les offres avec abonnement acheté",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"offreAvecAbonnementAcheté",
      "offers":"Offres",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Ajouter abonnements",
      "OFFERS_HEADER_TEXT_OFFERS":"Offres",
      "OFFERS_NO_MATCH_MESSAGE":"Aucun résultat correspondant trouvée pour le nom : ",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Articles sélectionnés",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Pourcentage d'escompte",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Quantité (No. de licences)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Taux par droit (Par mois)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Total",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Montant total",
      "OLD_BILLING_CYCLE":"Ancien cycle de facturation",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Échec de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Initiation de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Réussite de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Correspondance trouvée pour l'identifiant Id : <strong> {{billingProviderReferenceID}} </strong> dans le système de facturation.<br/><span> L'adresse de facturation dans le fichier est <strong> {{formattedAddress}} </strong>.</span> <br/>Veuillez personnifier l'identité et utiliser l'option <strong>Profile</strong> pour modifier ce détail une fois que les détails de facturation du client ont été intégrés avec succès.",
      "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}.",
      "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous ne devez pas ignorer {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez inclure {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}, c'est-à-dire ; La quantité minimale doit être 1.",
      "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"La quantité du fournisseur et la quantité partagée doivent correspondre pour {{product}}",
      "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"La quantité doit être égale à {{firstProduct}} et {{secondProduct}}",
      "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Sélectionner le client CSP à intégrer",
      "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Détails du compte principal",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":"Remplir",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Télécharger un modèle .csv",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":"en ajoutant des lignes après l'en-tête. Chaque ligne doit correspondre au détail d'un revendeur.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"Le traitement prendrait un certain temps et le statut sera signalé sur cette page. Si vous vous éloignez de la page ou avez fermé le navigateur par accident, vous pourriez revenir à cette page et le système afficherait l'état de votre téléchargement précédent.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Cliquez sur Sélectionner et accédez au fichier que vous souhaitez télécharger.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"L'entrée est validée pour s'assurer que les règles suivantes sont respectées :",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Le nom du revendeur est obligatoire et ne doit pas dépasser 50 caractères.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"L'ID de revendeur est obligatoire et ne doit pas dépasser 50 caractères. Il doit s'agir du GUID de relation du revendeur du \"Partner Center\"",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"L'identifiant MPN du revendeur est obligatoire et ne doit pas dépasser 50 caractères. Il doit s'agir de l'ID \"Microsoft Partner Network\" du revendeur.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"L'emplacement est obligatoire et doit correspondre au code pays à deux lettres du revendeur. Par exemple : États-Unis, Royaume-Uni, etc.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"L'e-mail est facultatif mais doit être une adresse e-mail valide si vous en saisissez une.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Cliquez sur <strong class=\"text-uppercase\">soumettre</strong> pour que les données soient traitées.",
      "ONBOARDING":"Intégration",
      "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Télécharger le rapport",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Sélectionner le type de client",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Sélectionner l'unité de durée",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Fermer",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Revendeurs",
      "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Sélectionner un revendeur",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Réinitialiser",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Rechercher",
      "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"Type de client",
      "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Unité de durée",
      "ONBOARDING_DURATION_UNIT_MONTH":"Mois",
      "ONBOARDING_DURATION_UNIT_WEEK":"Semaine",
      "ONBOARDING_DURATION_UNIT_YEAR":"Année",
      "ONBOARDING_GRAPH_COUNT_TEXT":" Compter:",
      "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"L'intégration des clients n'est pas autorisée car il n'y a aucun lien avec le revendeur fournisseur. Veuillez contacter votre Partenaire.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"L'intégration d'un client MicrosoftNonCSP n'est pas prise en charge. Veuillez lier le client à un client C3 existant.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"L'intégration d'un client MicrosoftNonCSP n'est pas prise en charge !",
      "ONBOARDING_STATUS":"Statut d'intégration",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Mappage des produits du client fournisseur avec les produits du plan...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Chargement des plans...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Chargement des clients fournisseurs...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Recherche des détails du client dans C3...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Tous les clients",
      "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Clients directs",
      "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Revendeur clients",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Êtes-vous sûr de vouloir supprimer ?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Sélectionnez un produit dans la liste ou recherchez par nom de produit",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"La demande de suppression de licences des utilisateurs est acceptée et en cours de traitement.",
      "OnlineServicesNCE":"Service en ligne NCE",
      "Open":"Ouvrir",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "OPERATING_ENTITY_CUSTOMER":"Client",
      "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Configuration de l'organisation",
      "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Sites & Départements",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Affecter",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Libérer",
      "ORDER_BACK_TO_ORDER_DETAILS":"Retour aux produits",
      "ORDER_DETAILS_ENTITY_NAME":"Nom de l'entité",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"Commandé le",
      "ORDER_DETAILS_SCHEDULED_DATE":"Date prévue",
      "ORDER_ID":"Identifiant de commande",
      "ORDER_LBL_TEXT_PROMOTIONID":"PromotionId",
      "ORDER_LIST_STATUS_SUCCESS":"Réussite ",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Approuvé pour examen par le client",
      "ORDER_STATUS_APPROVAL_REQUIRED":"Approbation exigée",
      "ORDER_STATUS_COMMENTS":"Voir les commentaires",
      "ORDER_STATUS_DELETED":"Supprimé",
      "ORDER_STATUS_DESC_ACCEPTED":"Accepté",
      "ORDER_STATUS_DESC_FAILED":"Échoué",
      "ORDER_STATUS_DESC_IGNORE":"Ignorer",
      "ORDER_STATUS_DESC_INPROGRESS":"InProgress",
      "ORDER_STATUS_DESC_ONHOLD":"En attente",
      "ORDER_STATUS_DESC_ORDERED":"Commandé",
      "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Partiellement réalisé",
      "ORDER_STATUS_DESC_PROCESSING":"Traitement",
      "ORDER_STATUS_DESC_PROVISIONING":"Approvisionnement",
      "ORDER_STATUS_DESC_REJECTED":"Rejeté",
      "ORDER_STATUS_DESC_SCHEDULED":"Programmé",
      "ORDER_STATUS_DESC_SUCCESS":"Réussite ",
      "ORDER_STATUS_ERRORS_IGNORED":"Échec <br/>(erreurs examinées et ignorées)",
      "ORDER_STATUS_FAILED":"Échoué",
      "ORDER_STATUS_INPROGRESS":"En cours de résolution",
      "ORDER_STATUS_ONHOLD":"En attente",
      "ORDER_STATUS_ORDERED":"Commandé",
      "ORDER_STATUS_PROCESSING":"En cours de résolution",
      "ORDER_STATUS_PROVISIONING":"En cours de résolution",
      "ORDER_STATUS_REACTIVATED":"Réactivé",
      "ORDER_STATUS_REJECTED":"Rejeté",
      "ORDER_STATUS_SCHEDULED":"Programmé",
      "ORDER_STATUS_SUCCESS":"Commande exécutée",
      "ORDER_STATUS_SUSPENDED":"Suspendu",
      "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Devis approuvé et commande passée avec succès.<br/> L'identifiant de commande est <b>{{OrderNumber}}</b>",
      "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Devis approuvé avec succès.",
      "OWNER_ENTITY_NAME":"Nom de l'entité propriétaire",
      "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Détails de l'état du paiement",
      "PaidAmount":"Montant payé",
      "PARENT_ENTITY_NAME":"Nom de l'entité parente",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"L'extension ne peut pas être activée si l'offre parent n'est pas active.",
      "PARENT_PRODUCT_NOT_EXISTS":"Impossible d'intégrer un produit sans parent !",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Il n'y a pas de clés disponibles pour ce fournisseur",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Il n'y a pas de fournisseurs configurés, veuillez contacter le soutien technique",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Enregistrer vos modifications",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Description",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Devise d'affichage",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Devise de facturation",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valeur de la macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Nom",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Devise d'achat",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Sélectionner",
      "partner":"Partenaire",
      "Partner":"Partenaire",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Affecter",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Dissocier",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Retour aux gestionnaires de comptes",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Clients du gestionnaire de compte",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Gestionnaire de compte clients <span class='text-primary'> {{FirstName}} </span>",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Actions",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nom",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"C3Id",
      "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Nom du revendeur",
      "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Mettez à jour tous les forfaits avec les dernières offres",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Aucune extension n'a été trouvée",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Supprimer un plan",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Consulter les offres de plan",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Enregistrer le plan",
      "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Pour l'offre d'essai, aucune offre parent n'a été trouvée. Incluez l'offre parentale et réessayez",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Quantité",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Utilisation",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Entrez la date d'effet à partir de",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Entrez la date d'entrée en vigueur pour",
      "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Entrez le message",
      "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Sélectionnez le type de message",
      "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Sélectionnez le portail de la page",
      "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Icônes-de-Stockholm / Communication / Chat de groupe",
      "PARTNER_APPROVAL_PENDING":" Approbation du partenaire -",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"ajouté au forfait",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"ajouté au forfait avec des extensions",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"est déjà ajouté au forfait et ne peut pas être ajouté à nouveau",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filtres",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Réinitialiser",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Rechercher",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Valide",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Durée du mandat",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Durée du mandat",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Types de facturation",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Coût d'acquisition (de)",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Coût d'acquisition (à)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Prix de vente (à partir de)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Prix de vente (à)",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Données de bloc",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":"- à - ",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Type de validité",
      "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Inclure les bundles supprimés",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Veuillez sélectionner au moins un produit.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Veuillez sélectionner au moins 2 offres à regrouper",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Veuillez fournir le prix de vente",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Produits groupés",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Forfaits",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Nouveau forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Retour aux détails du forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Supprimer",
      "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Télécharger les packs",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Modifier",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Recherche appliquée : ",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Suivant",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Examiner les produits groupés",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Enregistrer",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Enregistrer le forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Afficher les offres",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Retour aux forfaits partenaires",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Ajouter un forfait",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Afficher le forfait",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Prix suggéré :",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Période des frais",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Période des frais",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Texte d'aide",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Description",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiate ?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Nom",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nombre de jours pour l'annulation gratuite",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Fournisseur",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Valide",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Recherche de produits",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Quantité",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Description",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Nom",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Détails de l'offre",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Afficher",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Catégories",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Fournisseurs",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Types de service",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Coût pour le partenaire",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Coût pour le client",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Paiements multiples",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Paiements multiples",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Paiements",
      "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Veuillez actualiser le rapport sur les revenus en cliquant sur le bouton Recharger avant de continuer.",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Aucun historique trouvé !",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Types de service",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "PARTNER_COMMENTS_DETAILS":"Commentaires",
      "PARTNER_COMMENTS_TAB_CART_ITEM":"Article du panier",
      "PARTNER_COMMENTS_TAB_INVOICE":"Facture",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Produit",
      "PARTNER_COMMISIONS":"Commissions Partenaires",
      "PARTNER_CONSENT_HELP_TEXT":"Copiez le lien en cliquant sur le bouton ci-dessus et ouvrez une nouvelle fenêtre de navigation privée, puis compléter la procédure de consentement.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Veuillez sélectionner le type de facturation pour le bloc",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Ajouter",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Modifier",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Veuillez sélectionner l'applicabilité de l'offre",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Courriel",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Nom",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Numéro de téléphone",
      "PARTNER_CUSTOMER_FILTER_NAME":"Nom",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} n'est affecté à aucun gestionnaire de compte",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Il n'y a pas de clients liés à un domaine du fournisseur.",
      "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"L'attribution de ce plan inclura les offres avec des majorations définies dans le plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Affecter",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Désactiver",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Activer",
      "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"La désactivation supprimera ce plan, y compris ses offres. Les produits achetés existants auront le même balisage que ce plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"L'activation attribuera ce plan, y compris les offres avec des majorations définies dans le plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Retour aux plans des clients",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Changer de plan",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Gérer les plans pour : ",
      "PARTNER_CUSTOMER_REPORTS":"Rapports",
      "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"balise client ajoutée avec succès",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"balise client mise à jour avec succès",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Désolé, aucun client n'a été trouvé",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Comptes débiteurs",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"En souffrance",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilité",
      "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"10 meilleurs clients",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Vos clients actifs",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clients",
      "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Sièges clients",
      "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Sièges du client direct",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Abonnements directs du client",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Abonnements Clients",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"Les détails de la facture du revendeur n'ont pas été trouvés pour le resellerId : {{resellerId}}, billingPeriodId : {{billingPeriodId}}",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Facture générée le",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Profit Net ",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Autres charges",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% de profit",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Montant facturé",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"Facture MSFT ",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Aperçu rapide des recettes de votre entreprise",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilité",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Résumé de vos comptes débiteurs",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Comptes débiteurs",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Les paiements doivent être collectés",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"En souffrance",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"En attente",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Veuillez vérifier une fois les factures prêtes",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 des SKU",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Nombre de produits par les meilleures 10 SKU",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Comptes par SKU",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Gamme de filtres",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 an",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Achat de sièges",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Tendance des achats de sièges au fil des mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Achat de sièges",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Sièges achetés",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sièges achetés",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Les partenaires actifs travaillant avec vous",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Revendeurs",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Chiffre d'affaires vs Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Un graphique pour afficher les revenus par rapport aux détails des coûts par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Revenus vs coûts par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Sélectionnez le fournisseur",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Chiffre d'affaires vs Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Chiffre d'affaires par rapport au coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Chiffre d'affaires par rapport au coût",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Clients",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Sièges",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonnements",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Factures",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"Revendeurs",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Par date de renouvellement",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Les 5 meilleurs produits",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Les meilleurs 5 des produits par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Les meilleurs 5 des produits par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Sièges",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Les 5 meilleurs produits",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Par places achetées",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Les meilleurs 5 des produits par sièges achetés",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Les meilleurs 5 des produits par sièges achetés",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Renouvellements à venir",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"10 meilleurs clients",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Par chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Les meilleurs 10 des clients par chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Les meilleurs 10 des clients par chiffre d'affaires",
      "PARTNER_LOGOS_LABEL_TEXT":"Sélectionner le logo",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Aperçu d'image",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Supprimer le module complémentaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Type de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Rabais",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Nom",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Supprimer l'offre",
      "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Ajouter une offre Partenaire",
      "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Entrez la quantité que vous souhaitez acheter.",
      "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Modifier l'offre de contrat",
      "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Modifier l'offre du Partenaire",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Entrez une valeur positive",
      "PARTNER_OFFER_HYPERLINK_READ_LESS":"Lire moins",
      "PARTNER_OFFER_HYPERLINK_READ_MORE":"En savoir plus",
      "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Activez l'offre d'essai en cochant cette case",
      "PARTNER_OFFER_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "PARTNER_OFFER_SLAB_DATA":"Données de bloc",
      "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":" Durée de la période d'essai",
      "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Tous les clients",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Actions",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Courriel",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports sur les offres des partenaires.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Rapport sur les offres des partenaires",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les offres des partenaires",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Entrez l'adresse e-mail à laquelle vous souhaitez envoyer par e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur soumettre pour recevoir un rapport.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Annuler",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"Le produit parent n'est pas approuvé !",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"Les produits pour enfants ne sont pas rejetés !",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Tous les articles du panier sont approuvés, quelles que soient les violations de seuil à chaque élément de ligne. Voulez-vous vraiment approuver le panier ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"Voulez-vous vraiment approuver l'élément ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Approbation réussie du panier Lineitem",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Le panier a été approuvé avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Approuver",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Approuver",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Approuver la demande",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Retour aux demandes",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Catégories",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Types de service",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Statut",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"Commandé le",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Prix unitaire",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"Rejeter",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Rejeter",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Rejeter la demande",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Soumettre des changements",
      "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Veuillez vous assurer d'approuver ou de rejeter au moins un produit avant de le soumettre",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"Voulez-vous vraiment rejeter l'intégralité du panier ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"Voulez-vous vraiment rejeter l'élément ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Le panier Lineitem a été rejeté avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Le panier a été rejeté avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Le statut a été mis à jour avec succès",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Approbations",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Les demandes d'achat des clients suivantes attendent votre approbation.",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Approbations pour",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Demandes d'achat",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Cycles de facturation sélectionnés",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Catégories sélectionnées",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Catégories de fournisseurs sélectionnées",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Fournisseurs sélectionnés",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Appliquer la promotion à toutes les offres",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Catégories",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Sélectionner le type de macro",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Limite de places du plan :",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Catégories de fournisseurs",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Limite de siège",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Types de service",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Duplication de plan complétée avec succès",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"La demande de clonage de plan est acceptée et en cours de traitement.",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"La demande de création de nouveau plan avec toutes les offres est acceptée et en cours de traitement",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Valeur de la macro",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"Le prix de vente peut être inférieur à <br/> Prix partenaire",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Le prix de vente peut être inférieur au prix du partenaire",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"Le prix de vente peut-il dépasser le prix de vente du fournisseur ?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Activez cette option pour permettre au prix de vente de dépasser le prix de vente du fournisseur",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Définir le plan dans une nouvelle devise",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Nom",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Code de devise du plan",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Nom de produit",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Devise source",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Devise cible",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Il n'y a pas de devise cible, veuillez contacter le partenaire pour la définir.",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"Comment?",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Définissez la limite à -1 pour exiger l'approbation de tous les achats/mises à jour de sièges",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Utilisez cet écran pour configurer le seuil par nombre de sièges",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Définissez la limite sur 0 pour permettre l'achat de sièges illimités",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Définir un nombre positif différent de zéro pour définir le seuil",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Tout achat dépassant le seuil nécessite l'approbation du Partenaire",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Cliquez sur le bouton Soumettre pour enregistrer les modifications<b>",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Configurer les limites de siège pour {{PlanName}}",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Définir la macro et l'enregistrer avec toutes les offres",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Ajouter les offres manquantes",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Annuler",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Cloner le plan",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Enregistrer le plan avec toutes les offres",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Cloner le plan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Clonage du plan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Création de plans",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"Le plan est en cours. Cliquez pour voir le statut",
      "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Limites de sièges",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Catalogue des produits",
      "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Catalogue des produits",
      "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Catalogue des produits",
      "PARTNER_PRODUCT_CATEGORYID":"CategoryId",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "PARTNER_PRODUCT_DESCRIPTION":"Description",
      "PARTNER_PRODUCT_NAME":"Nom",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"PriceforPartner",
      "PARTNER_PRODUCT_ProviderId":"ProviderId",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Prix de vente du fournisseur",
      "PARTNER_PRODUCT_SALETYPE":"Type de vente",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Coût pour le client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Devise",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Identifiant du client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Nom du client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Description",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Id Produit ",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Nom de produit",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Unité de mesure",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Unités",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Date d'utilisation",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"Voulez-vous vraiment accepter cette demande ?",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Voulez-vous vraiment rejeter cette demande ?",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Retour à l'historique des abonnements",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Nouveau produit PSA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Libérer",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Cartographie des accords de libération",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Cartographie des contrats de publication",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Publier la cartographie client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Créer un nouveau produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Ce champ est obligatoire.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Catégorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Coût",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Description du client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Description",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Livraison directe",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Identifiant",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Référence croisée d'intégration",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Remarques",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Prix",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Classe de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"SLA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Commande spéciale",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Sous-catégorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Taxe de vente",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Type de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Unité de mesure (UOM)",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Entrer le coût",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Entrez la description du client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Entrer l'identifiant",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Entrer des notes",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Entrez le prix",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Entrez la description du produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Entrer la référence croisée d'intégration",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Sélectionnez le type de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Création d'un nouveau produit PSA avec succès",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_RESELLER_FILTER_NAME":"Nom",
      "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{reseller}} n'est affecté à aucun gestionnaire de compte",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Enregistrer vos modifications",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Description",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Nom",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Sélectionner",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"Le prix du nouveau partenaire peut être inférieur à<br/>Prix du partenaire",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Activez cette option pour permettre au nouveau prix du partenaire d'être inférieur au prix du partenaire",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"Le prix du partenaire peut-il dépasser le prix de vente du fournisseur ?",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Activez cette option pour permettre au prix du partenaire de dépasser le prix de vente du fournisseur",
      "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Ajouter un rapport",
      "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ceci ?",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Actions",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Ajouter un destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Retour aux rapports",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Retour au destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"CCI",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Annuler",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Supprimer",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Modifier",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Courriel",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Type de destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Destinataires",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Enregistrer",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"À",
      "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Méthode de livraison",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Destinataires",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Type de destinataire",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Destinataires",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Historique d'exécution",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Actions",
      "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Dernière exécution le (heure locale)",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Rapports",
      "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Prévu le",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Date",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Détails",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Statut",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Veuillez entrer au moins un nom de taxe et un pourcentage pour {{taxType}}",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Veuillez entrer le pourcentage de taxe valide",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Veuillez remplir les champs du nom de taxe",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Vous avez des sous taxes non enregistrées, voulez-vous continuer ?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Notification par courriel de l'événement",
      "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Sélectionner un mode d'affichage",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"balise de partenaire supprimée avec succès",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"balise partenaire mise à jour avec succès",
      "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Modes",
      "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Modèles",
      "PARTNER_STATUS":"Statut de partenaire",
      "PARTNER_TABLE_HEADER_NAME":"Nom",
      "PARTNER_TABLE_HEADER_VALUE":"Valeur",
      "PARTNER_TERMS_OF_USE":"Conditions d'utilisation du partenaire",
      "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Journées d'essai",
      "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Offres parents associées à l'offre d'essai",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Coût pour le client",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Devise",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Description",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Soumettre",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Mois",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Coût total",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Date d'utilisation",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Unité de mesure",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Unités",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Date d'utilisation",
      "PARTNER_USER_ADD_SUCCESSFULLY":"Utilisateur Partner ajouté avec succès",
      "PARTNER_USER_ALREADY_EXECUTE":"Un utilisateur Partner existe déjà avec cet email.",
      "PARTNER_USER_REMOVED":"Utilisateur Partner supprimé avec succès",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Modifier",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Clé de Balise",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Valeurs de balise",
      "PARTNER_USERS_ADD_SUCCESS":"Accorder l'accès a {{username}} avec succès",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Un utilisateur avec l'email {{useremail}} existe déjà dans le système.",
      "PARTNER_USERS_REMOVE_SUCCESS":"Utilisateur Partner {{username}} supprimé avec succès",
      "PARTNER_USERS_SAVE_SUCCESS":"Utilisateur Partner {{username}} mis à jour avec succès",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Veuillez attribuer un rôle pour continuer",
      "partnerauditlog":"Journal d'audits",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Utilisateur Partner mis à jour avec succès",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Retour aux utilisateurs Partner",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Ajouter",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Modifier",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Utilisateur Partner ",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Courriel",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Gérer la balise",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Clé de Balise",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Valeurs de balise",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
      "PAYMENT_LABEL_FILTER":"Filtrer par",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Profil de paiement défini par défaut",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Profil de paiement supprimé",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Nom du titulaire",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Numéro de carte",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Mois d'expiration",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Année d'expiration",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Code de sécurité",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Nom sur la carte de crédit",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Numéro de carte de crédit",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Numéro du mois à deux chiffres",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Année à quatre chiffres",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Numéro CVV à trois chiffres sur la carte de crédit",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Échec de l'enregistrement des détails de paiement",
      "PAYMENT_PROFILE_SAVE_SUCCESS":"Détails de paiement enregistrés",
      "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Les détails de paiement seront mis à jour sous peu. Veuillez NE PAS RÉESSAYER et revérifier après un certain temps.",
      "PAYMENT_PROFILE_TEXT_FAILURE":"Échec",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"Réussite ",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Accepté",
      "PAYMENT_STATUS_DESC_CHARGED":"Facturé",
      "PAYMENT_STATUS_DESC_DECLINED":"Refusé",
      "PAYMENT_STATUS_DESC_FAILED":"Échoué",
      "PAYMENT_STATUS_DESC_FINALIZED":"Mois en cours impayé",
      "PAYMENT_STATUS_DESC_INPROGRESS":"En cours",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Partiellement payé",
      "PAYMENT_STATUS_DESC_SUCCESS":"Réussi",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Erreur technique",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Annuler",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Mettre à jour",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Ce champ est obligatoire.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Numéro de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Nom de l'alias",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"Est un compte d'entreprise ?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Entreprise",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individuel",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Non",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Oui",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Numéro de carte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Vérification",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Mois d'expiration",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Année d'expiration",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUINT":"Nom de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Le nom sur le compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"Enregistrement",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Veuillez patienter pendant le chargement de la page",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Montant facturé",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Montant payé",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Statut de paiement",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Raison de l'échec",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Remarques",
      "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Payé par",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Transactions d'échec",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Paiements en attente",
      "PAYMENTS_STATUS_DESC_FAILED":"Échoué",
      "PAYMENTS_STATUS_DESC_SUCCESS":"Réussite ",
      "PAYMENTS_TABLE_HEADER_AMONT":"Montant payé",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Montant facturé",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Courriel de facturation",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Raison de l'échec",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Période de facturation",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Statut d'échéance",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Est basé sur la facture?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Détenu PAR",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Montant payé",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Montant payable",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Identifiant de paiement",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Méthode de paiement ",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Montant en attente",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Montant en attente",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Remarques",
      "PAYMENTS_TABLE_HEADER_STATUS":"Statut",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Paiement partiel",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"Identifiant de transaction",
      "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Vous avez dépassé votre fenêtre de 7 jours calendaires pour réduire le nombre de places pour cet abonnement.",
      "PC_ACTIVATION_ERROR":"Impossible d'activer un abonnement enfant lorsque l'abonnement parent n'est pas actif",
      "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"L'addon n'est pas achetable sans un produit de base compatible",
      "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":"Informations sur le Partenaire du conseiller introuvables pour répondre à cette demande.",
      "PC_ADVISOR_QUOTE_NOT_FOUND":"Devis du conseiller introuvable. Veuillez vérifier les informations avant de réessayer.",
      "PC_ALREADY_ACTIVATE_ITEM":"Cet élément a déjà été activé.",
      "PC_AN_Azure_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Un plan Azure est requis pour acheter un abonnement SaaS avec un plan de facturation mesuré. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Un plan Azure est requis pour acheter un abonnement SaaS avec un plan de facturation mesuré. Veuillez contacter le partenaire pour plus d'informations.",
      "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Cette méthode d'API n'est pas prise en charge pour l'ancien abonnement",
      "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Disponibilité référencée par élément de campagne introuvable",
      "PC_AVAILIBILTY_QUANTITY":"La disponibilité a plus d'une option de quantité incluse. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_Azure_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Le plan Azure ne peut pas être déplacé vers l'état suspendu car vous avez un ou plusieurs abonnements Azure à l'état actif.",
      "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Le plan Azure ne peut pas être déplacé vers l'état suspendu car vous avez un ou plusieurs abonnements Azure à l'état actif.",
      "PC_Azure_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_Azure_SUBSCRIPTIONS_UNDER_IT":"L'ID de plan Azure spécifié est introuvable ou il n'y a aucun abonnement Azure actif sous celui-ci. Un plan Azure avec un ou plusieurs abonnements actifs est requis pour acheter un produit SaaS avec un plan de facturation mesuré",
      "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"L'ID de plan Azure spécifié est introuvable ou il n'y a aucun abonnement Azure actif sous celui-ci. Un plan Azure avec un ou plusieurs abonnements actifs est requis pour acheter un produit SaaS avec un plan de facturation mesuré",
      "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"L'abonnement Azure avec l'ID de droit donné est introuvable.",
      "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"Le cycle de facturation ne peut pas être plus long que le terme.",
      "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"Le cycle de facturation n'a pas pu être modifié car la commande contient un ou plusieurs abonnements qui ne sont pas actifs ou qui ne prennent pas en charge les conditions de facturation",
      "PC_BILLING_CYCLE_IS_INVALID":"Le cycle de facturation n'est pas valide.",
      "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Cette demande de transfert de propriété de facturation ne peut pas être complétée car les produits tiers de la place de marché ne sont pas transférés avec les abonnements. Supprimez les produits du marché tiers de votre sélection et réessayez.",
      "PC_C3_CONNECTION_ERROR":"Erreur lors de la connexion à Partner Center, veuillez réessayer ou contacter l'assistance.",
      "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"Impossible de modifier le cycle de facturation si l'abonnement n'est pas associé à un compte Mint",
      "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"Impossible de traiter la mise à niveau Azure pour Azure Partner Shared Services",
      "PC_CANCEL_PURCHASE_NON_TENANT":"Les locataires non Sandbox ne sont pas autorisés à annuler des achats autres que les abonnements logiciels et les logiciels perpétuels",
      "PC_CANCELLATION_NOT_ALLOWED":"L'annulation n'est pas autorisée pour le moment !",
      "PC_CANNOT_CHECKOUT":"Un panier déjà soumis ne peut pas être vérifié !",
      "PC_CANNOT_PROCESS_Azure_UPGRADE":"Impossible de traiter la mise à niveau Azure. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CANNOT_PROCESS_AZURE_UPGRADE":"Impossible de traiter la mise à niveau Azure. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CANNOT_PROCESS_REQUEST":"Impossible de traiter la demande de transfert pour {1} transfert {0}",
      "PC_CANNOT_TRANSITION_TO_Azure_PLAN":"Vous avez un ou plusieurs abonnements Azure à l'état suspendu. Ceux-ci ne peuvent pas être transférés vers le plan Azure",
      "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Vous avez un ou plusieurs abonnements Azure à l'état suspendu. Ceux-ci ne peuvent pas être transférés vers le plan Azure",
      "PC_CANNOT_UPDATE_BILLING_CYCLE":"Des défis techniques empêchent les mises à jour de l'abonnement. Veuillez contacter l'assistance.",
      "PC_CART_IN_PROCESS":"Le panier est déjà en cours de traitement, veuillez patienter et revenir après un certain temps.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":"L'article du catalogue n'est pas éligible à l'achat. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":"L'article de catalogue n'est pas éligible pour ajouter ou supprimer une quantité. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"L'élément de catalogue nécessite l'acceptation d'une attestation.",
      "PC_CREATE_ORDER_ERROR":"La création de la commande échoue",
      "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"Le compte client est en cours de vérification. Les transactions ne sont pas autorisées tant que le compte n'est pas approuvé.",
      "PC_CUSTOMER_MIGRATION_FAILED":"La migration de la licence client a échoué. Veuillez contacter l'assistance.",
      "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Le numéro de téléphone du client est manquant. Cette erreur est générée si l'entreprise/l'organisation du client est située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie (MD ), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA) mais billingProfile.defaultAddress.phoneNumber n'est pas transmis.",
      "PC_DISCONTINUED_OFFER":"Cette offre n'est pas actuellement  disponible à l'achat car elle a peut-être été interrompue. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Un ou plusieurs éléments de la commande ne prennent pas en charge la devise indiquée, USD. La devise d'entrée doit être la devise du partenaire ou celle du client (marché).",
      "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"La date de fin de mandat personnalisée spécifiée dans les instructions planifiées pour le prochain trimestre doit être comprise dans la durée du premier mandat après le renouvellement. De plus, les produits à terme mensuel ne peuvent pas correspondre à un abonnement se terminant le 28, le 29 ou le 30 du mois, à moins que cette date ne soit le dernier jour du mois.",
      "PC_GENERIC_ERROR":"Une erreur s'est produite. Veuillez contacter l'assistance pour plus d'informations ou réessayer après avoir vérifié l'état de l'opération effectuée.",
      "PC_INACTIVE_RESELLER_PROGRAM":"Le statut du programme Revendeur n'est pas actif. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_INCORRECT_CUSTOMER_ID":"Identifiant client incorrect. Veuillez vérifier les informations et réessayer.",
      "PC_INITIATE_TRANSFER_REQUEST_FAILED":"Échec du lancement de la demande de transfert. Veuillez réessayer plus tard.",
      "PC_INVALID_ADD_ON":"L'addon n'est pas lié à l'abonnement parent spécifié",
      "PC_INVALID_Azure_SUBSCRIPTION":"Cet abonnement n'est pas un abonnement Azure valide",
      "PC_INVALID_AZURE_SUBSCRIPTION":"Cet abonnement n'est pas un abonnement Azure valide",
      "PC_INVALID_CUSTOMER_INFORMATION":"Les informations fournies ne correspondent pas aux informations client actuelles. Veuillez vérifier les informations et réessayer.",
      "PC_INVALID_DOMAIN":"Domaine invalide. Veuillez fournir l'adresse de domaine correcte et réessayer.",
      "PC_INVALID_ETAG":"L'Etag n'est plus valide",
      "PC_INVALID_LINE_ITEM":"La séquence des éléments de ligne n'est pas correctement définie. Veuillez contacter l'assistance.",
      "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Métadonnées non valides. (Si le prix du client, la devise est manquante).",
      "PC_INVALID_MPN":"MPN non valide, veuillez contacter l'assistance pour une enquête plus approfondie",
      "PC_INVALID_OPERATION":"Cette opération n'est pas prise en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID":"La valeur de l'identifiant d'enregistrement de l'organisation n'est pas prise en charge. Veuillez vérifier la valeur d'entrée avant de réessayer.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":"La valeur ou le format de l'identifiant d'enregistrement de l'organisation n'est pas pris en charge. Veuillez vérifier la valeur d'entrée avant de réessayer.",
      "PC_INVALID_PROMOTION_CODE":"Le code promotionnel n'est pas valide. Veuillez vérifier et réessayer.",
      "PC_INVALID_QTY_FOR_SUBSCRIPTION":"La quantité d'abonnement n'est pas comprise entre les quantités minimale et maximale autorisées",
      "PC_INVALID_REQUEST":"Requête invalide. Veuillez vérifier les attributs de la requête avant de réessayer.",
      "PC_INVALID_SUBSCRIPTION":"Abonnement non valide, veuillez contacter l'assistance.",
      "PC_INVALID_SUBSCRIPTION_TRANSER":"Le transfert ne peut pas être créé avec un abonnement invalide. Veuillez vérifier l'abonnement avant de réessayer.",
      "PC_ISSUE_ASSET_STATUS_CHANGE":"L'opération de modification de l'état de l'actif n'a pas pu se terminer. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_ISSUE_OFFER_TYPE":"L'achat n'a pas pu être effectué en raison d'un problème avec le type d'offre. Veuillez contacter l'assistance.",
      "PC_ITEM_IS_NOT_AVAILABLE":"Cet article n'est pas disponible",
      "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":"Le nombre demandé de dépasse la limite restante autorisée par abonnement pour l'offre. Veuillez ajuster la quantité avant de réessayer.",
      "PC_LIMIT_EXCEEDED":"La limite d'utilisation est dépassée pour l'identifiant de l'offre",
      "PC_LINE_ITEM_ISSUE":"Il y a un problème avec l'élément de campagne. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"La migration ne peut pas être effectuée car au moins deux migrations de modules complémentaires ont été spécifiées avec le même ID d'abonnement actuel",
      "PC_MISSING_ASSET_ID":"L'opération n'a pas pu se terminer en raison d'un ID d'actif manquant. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_MISSING_Azure_SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car les informations d'abonnement Azure sont introuvables.",
      "PC_MISSING_AZURE_SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car les informations d'abonnement Azure sont introuvables.",
      "PC_MISSING_MCA":"Veuillez accepter MCA avant de procéder à l'achat.",
      "PC_MISSING_REQUIRED_TAGS_IN OFFER":"Le compte n'a pas de balises requises pour l'offre. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_MISSING_TRANSFER_REQUEST":"Aucune demande de transfert n'a été trouvée.",
      "PC_MISSING_UPGRADE_REQUEST":"Aucune demande de mise à niveau n'a été trouvée.",
      "PC_MPN_NOT_FOUND":"L'identifiant MPN est introuvable",
      "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"Plusieurs demandes de transfert non autorisées. Une autre demande de transfert en cours.",
      "PC_NCE_MIGRATION_COULD_NOT_FOUND":"La migration New-Commerce est introuvable.",
      "PC_NO_Azure SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car le client n'a pas d'abonnement Azure.",
      "PC_NO_AZURE SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car le client n'a pas d'abonnement Azure.",
      "PC_NOT_ELIGIBLE_AUTO_RENEWED":"L'abonnement n'est pas éligible au renouvellement automatique. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"Le Partenaire n'est pas éligible pour cette transaction, veuillez contacter l'assistance.",
      "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"L'API des offres ne prend pas en charge l'élément. Essayez d'utiliser les API Produits et/ou SKU",
      "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Offre non valable dans le pays spécifié",
      "PC_OFFER_PURCHASE_ERROR":"L'offre n'est plus disponible à l'achat",
      "PC_ONE_OR_MORE_Azure_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Un ou plusieurs abonnements Azure ont été achetés récemment, ces abonnements ne peuvent pas être transférés pour le moment. Veuillez réessayer plus tard.",
      "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Un ou plusieurs abonnements Azure ont été achetés récemment, ces abonnements ne peuvent pas être transférés pour le moment. Veuillez réessayer plus tard.",
      "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":"Le type d'opération n'est pas pris en charge dans le lot. Veuillez effectuer l'opération sur abonnement individuel.",
      "PC_ORDER_CANCELLATION":"La commande ne peut pas être annulée. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PC_ORDER_CANCELLATION_ERROR":"La commande ne peut pas être annulée.",
      "PC_ORDER_IN_PROGRESS":"Votre commande est en cours. Veuillez vérifier l'historique de vos commandes pour les commandes récentes en quelques minutes.",
      "PC_ORDER_NOT_FOUND":"Commande introuvable !",
      "PC_ORDER_VENDOR_POLICY":"La commande n'a pas pu être passée en raison d'une politique de fournisseur en place. Veuillez contacter l'assistance pour en savoir plus.",
      "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Les informations d'identification d'enregistrement de l'organisation sont manquantes. Cette erreur est générée si l'entreprise/l'organisation du client est située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie (MD ), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA) mais le numéro d'enregistrement de l'organisation n'est pas transmis.",
      "PC_ORGANISATION_REGESTRATION_ID_MISSING":"Les informations d'identification d'enregistrement de l'organisation sont manquantes. Veuillez saisir un identifiant d'enregistrement valide avant de réessayer.",
      "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"La valeur de l'identifiant d'enregistrement de l'organisation n'est pas prise en charge. Cette erreur est générée si l'entreprise/l'organisation du client n'est pas située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie ( MD), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA), mais ils ont essayé de spécifier le numéro d'enregistrement de l'organisation.",
      "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":"Les informations d'identification d'enregistrement de l'organisation ne sont pas valides. Veuillez vérifier l'entrée et réessayer.",
      "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Ce transfert n'est pas autorisé car le transfert d'origine {0} est en cours",
      "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"L'excédent est déjà attribué à un autre locataire. Contactez votre client pour résoudre les questions de propriété",
      "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"Le dépassement ne peut pas être défini car le client a des abonnements Azure hérités.",
      "PC_PARTNER_ATTESTATION_MISSING":"L'attestation de partenaire est manquante",
      "PC_PARTNER_INFORMATION_MISSING":"La demande n'a pas pu être effectuée car les informations sur le Partenaire sont manquantes. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"L'ID de locataire partenaire n'est pas encore autorisé à effectuer des migrations vers New Commerce sur ses abonnements",
      "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"Impossible de mettre à jour le Partenaire enregistré pour cet abonnement. Veuillez contacter le support Microsoft pour obtenir de l'aide.",
      "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Il y a un ajustement en attente demandé pour cette commande. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Il y a une opération en attente sur cet abonnement. Veuillez réessayer après 24 heures.",
      "PC_PHONE_NUMBER_MISSING":"Le numéro de téléphone du client est manquant. Veuillez fournir le numéro de téléphone du client avant de réessayer.",
      "PC_PRICING_INFORMATION_NOT_PERMITTED":"L'accès aux informations de tarification n'est pas autorisé. Veuillez contacter l'assistance.",
      "PC_PRODUCT_NOT_FOUND":"Le produit n'a pas été trouvé",
      "PC_PROPERT_NOT_SUPPORTED":"Cette propriété n'est pas prise en charge. Veuillez vérifier et réessayer.",
      "PC_PURCHASE_NOT_SUPPORTED":"Système d'achat non pris en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Un bon de commande est requis.",
      "PC_PURCHASE_PRE_REQUISTIES_MISSING":"Un ou plusieurs pré-requis d'achat ne sont pas remplis. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"La quantité d'abonnement ne peut pas être augmentée ou diminuée pendant que l'abonnement est suspendu",
      "PC_QUANTITY_CANNOT_DECREASED":"La quantité d'abonnement ne peut pas être diminuée. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":"La quantité n'est pas disponible pour cet article de catalogue. Veuillez ajuster la quantité avant de passer une commande ou contacter le Partenaire pour plus d'informations.",
      "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":"Les changements de quantité sur l'abonnement avec le statut suspendu et supprimé ne sont pas pris en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_REQUEST_INFORMATION_NOT_AVAILABLE":"L'opération n'a pas pu être terminée car les informations demandées ne sont pas disponibles actuellement. Veuillez réessayer ou contacter l'assistance.",
      "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"La quantité demandée n'est pas dans la quantité autorisée pour cette offre.",
      "PC_RESELLER_RELATIONSHIP_MISSING":"Il n'y a pas de relation de revendeur avec le client. Veuillez établir une relation de revendeur avant de tenter à nouveau cette action.",
      "PC_RI_PURCHASE_NOT_ENABLED":"Cet abonnement n'est pas activé pour l'achat d'instances réservées. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SANDBOX_LIMIT":"La limite du bac à sable a été atteinte. Veuillez ajuster la limite avant de passer une commande.",
      "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":"La vente dans le pays du client n'est pas prise en charge. Veuillez consulter la liste des marchés Microsoft CSP pour en savoir plus.",
      "PC_SKU_NOT_FOUND":"SKU introuvable",
      "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Quelque chose s'est mal passé. Réessayez après un certain temps.",
      "PC_SUBCRIPTION_ACTIVE_ISSUE":"Cet abonnement n'est pas un abonnement actif. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"L'abonnement ne peut pas être migré vers New Commerce car il contient des modules complémentaires.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"L'abonnement ne peut pas être migré vers New Commerce car la combinaison de la durée du terme et du cycle de facturation n'est pas prise en charge pour le produit New-Commerce.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"Impossible de migrer l'abonnement vers New Commerce, car il n'existe pas encore de chemin de migration valide pour cette offre.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"L'abonnement ne peut pas être migré vers New Commerce pour une ou plusieurs raisons (raisons possibles : l'abonnement n'est pas à l'état Actif - l'abonnement a des promotions qui lui sont appliquées - l'abonnement a des modules complémentaires qui lui sont attachés - l'abonnement est trop proche de la fin du terme - l'offre d'abonnement est pas encore disponible dans New Commerce)",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"L'abonnement ne peut pas être migré vers New Commerce car des promotions lui sont appliquées",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"L'abonnement ne peut pas être migré vers New Commerce car l'abonnement est en cours de traitement.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"L'abonnement ne peut pas être migré vers New Commerce car il est à moins de 24 heures de la fin du terme.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"L'abonnement ne peut pas être migré vers New Commerce car il est actif depuis 1 mois ou moins. Veuillez réessayer plus tard.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"L'abonnement ne peut pas être migré vers New-Commerce car un ou plusieurs abonnements complémentaires spécifiés dans la collection AddOnMigrations n'existent pas ou ne sont pas actifs.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"L'abonnement ne peut pas être migré vers New Commerce car l'ID de partenaire enregistré dans l'abonnement actuel n'est pas valide.",
      "PC_SUBSCRIPTION_HISTORY_ISSUE":"L'historique des abonnements n'a pas pu être récupéré. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Votre abonnement est en cours d'expiration. Aucune autre mise à jour n'est autorisée sur cet abonnement.",
      "PC_SUBSCRIPTION_NOT_FOUND":"L'abonnement que vous essayez d'utiliser n'est plus disponible auprès de Microsoft.",
      "PC_SUBSCRIPTION_NOT_REGISTERED":"Cet abonnement n'est pas enregistré. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":"L'offre d'abonnement n'est pas disponible à l'achat. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_UPDATE":"L'abonnement n'est pas prêt à être mis à jour. Veuillez réessayer ultérieurement ou contacter l'assistance si le problème persiste.",
      "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"Le nombre d'abonnements souhaité a dépassé le nombre maximum d'abonnements autorisé par client",
      "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"L'abonnement ne peut pas être migré vers New Commerce car le statut n'est pas actif.",
      "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"La date de fin de l'engagement d'abonnement cible doit être postérieure à l'abonnement source",
      "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"La transition cible ne peut pas avoir une durée inférieure à celle de l'abonnement source.",
      "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible n'est pas prêt à être mis à niveau. Veuillez réessayer plus tard.",
      "PC_TENANT_ALREADY_USED":"Le domaine spécifié lors de la création du locataire est déjà utilisé par un autre locataire. Choisissez un autre domaine et réessayez",
      "PC_TENANT_NOT_EXISTS":"Le nom de domaine n'existe pas, veuillez vérifier le nom de domaine.",
      "PC_TENANT_NOT_FOUND":"Locataire introuvable.",
      "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"La durée du terme sur la transition cible n'est pas valide.",
      "PC_TERM_EXCEEDED":"La durée d'achat initiale n'est plus disponible à l'achat/à la mise à jour",
      "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":"L'ID de l'élément de catalogue n'est pas présent. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"La date de fin du terme personnalisé spécifiée dans les instructions du prochain terme prévu ne correspond pas à la date de fin d'un abonnement OnlineServicesNCE actif sans essai ou ne correspond pas à la fin du mois civil.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"La date de fin de mandat personnalisée spécifiée dans les instructions du prochain mandat prévu doit être comprise dans la durée du premier mandat après le renouvellement. Les produits à terme mensuel ne peuvent pas correspondre à un abonnement se terminant le 28, 29 ou 30 du mois, sauf si cette date est le dernier jour du mois.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOUNCE_INFORMATION":"La demande n'a pas pu récupérer toutes les informations sur les ressouNCEs de disponibilité. Veuillez vérifier après un certain temps avant de réessayer.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"La demande n'a pas pu récupérer toutes les informations sur les ressources de disponibilité. Veuillez vérifier après un certain temps avant de réessayer.",
      "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":"Le programme demandé n'est pas actif. Veuillez contacter le support pour en savoir plus.",
      "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":"Le rôle fourni n'a pas le droit d'effectuer l'opération demandée. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED":"Cette opération n'est pas prise en charge. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":"Cette opération n'est pas prise en charge dans cet environnement. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_Azure_PLAN":"Cette demande de transfert ne peut pas être initiée car le client n'a pas acheté le plan Azure.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Cette demande de transfert ne peut pas être initiée car le client n'a pas acheté le plan Azure.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_Azure_PLAN_IS_NOT":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas achetable. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas achetable. Veuillez contacter le partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_Azure_PLAN_IS_NOT_ACTIVE":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas actif, veuillez activer le plan Azure et réessayer.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas actif, veuillez activer le plan Azure et réessayer.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":"Cette demande de transfert ne peut pas être initiée car le client a des abonnements Azure hérités.",
      "PC_TOKEN_MISSING":"Jeton de Partenaire manquant dans le contexte de la demande.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible CommitmentEndDate doit être postérieur à l'abonnement source",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible doit être actif.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'ID d'abonnement cible ne peut pas être le même que l'ID d'abonnement source.",
      "PC_TRANSACTION_ELIGIBLE":"Vous pourriez être admissible à cette transaction. Veuillez contacter l'assistance pour obtenir de l'aide",
      "PC_TRANSACTION_NOT_ELIGIBLE":"Vous n'êtes pas éligible pour cette transaction. Veuillez réduire la quantité ou contacter le support pour obtenir de l'aide.",
      "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"Le transfert ne peut pas être créé en tant que Partenaire : le {0} national cloud {1} n'est pas pris en charge",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOUNCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Le transfert ne peut pas être lancé car les détails de l'e-mail/du domaine du Partenaire souNCE manquent dans la demande. Veuillez saisir les informations demandées avant de réessayer.",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Le transfert ne peut pas être lancé car les détails de l'e-mail/du domaine du partenaire source manquent dans la demande. Veuillez saisir les informations demandées avant de réessayer.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOUNCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"La création du transfert a échoué en raison des détails du Partenaire souNCE correspondant au Partenaire demandeur. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"La création du transfert a échoué en raison des détails du partenaire source correspondant au partenaire demandeur. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_Azure_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Cette demande de transfert de propriété de facturation ne peut pas être effectuée car les réservations Azure ne sont pas transférées avec les abonnements. Annulez les réservations Azure associées aux abonnements de votre sélection et réessayez.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Cette demande de transfert de propriété de facturation ne peut pas être effectuée car les réservations Azure ne sont pas transférées avec les abonnements. Annulez les réservations Azure associées aux abonnements de votre sélection et réessayez.",
      "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"La demande de transfert ne peut être acceptée. Veuillez demander au Partenaire de créer un transfert sans abonnement(s) Azure",
      "PC_TRANSFER_REQUEST_DISABLE":"Le transfert ne peut pas être créé en tant que Partenaire : {0} n'est pas activé pour cette fonctionnalité",
      "PC_UNABLE_TO_COMPLETE_THE_REQUEST":"Impossible de terminer la demande. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"Impossible de créer la commande. Il y a une limite au nombre de plans Azure pouvant être créés sous des comptes sandbox",
      "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":"Impossible de trouver le profil du client pour compléter la demande. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_UNABLE_TO_GET_Azure_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Impossible d'obtenir des abonnements Azure Active Directory pour un client avec l'ID de locataire {0} et l'ID de provisionnement souNCE {1}",
      "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Impossible d'obtenir des abonnements Azure Active Directory pour un client avec l'ID de locataire {0} et l'ID de provisionnement source {1}",
      "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"Impossible de traiter la commande en raison d'une limite de crédit insuffisante, veuillez contacter ucmwrcsp@microsoft.com pour obtenir de l'aide",
      "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Une erreur inattendue s'est produite lors du traitement de la migration New-Commerce.",
      "PC_UNSUCCESSFUL_TRANSFER_ORDER":"L'ordre de transfert n'a pas abouti. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Les opérations de mise à jour ne sont actuellement pas autorisées pour cet abonnement.",
      "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Les opérations de mise à jour ne sont plus autorisées pour cet abonnement.",
      "PC_UPDATES_NOT_ALLOWED":"Les mises à jour de l'abonnement ne sont pas autorisées",
      "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":"La demande de mise à niveau ne peut pas être traitée pour un abonnement déjà mis à niveau.",
      "PC_UPGRADE_REQUEST_IN_PROGRESS":"La demande de mise à niveau n'a pas abouti car une autre demande de mise à niveau est en cours.",
      "PC_USER_LIMIT_EXCEEDED":"Le nombre de sièges souhaité a dépassé le nombre de sièges maximum autorisé par abonnement",
      "PC_USER_NOT_FOUND":"Utilisateur introuvable dans le locataire cible.",
      "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Lorsqu'il fait partie de l'appel d'offres, un lien d'appel d'offres ou un document d'appel d'offres est requis.",
      "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"Vous n'êtes pas autorisé à gérer le dépassement pour ce client.",
      "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Vous avez dépassé votre fenêtre d'annulation. Nous ne pouvons pas annuler votre achat",
      "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Vous avez dépassé votre fenêtre de jour pour réduire les places pour cet abonnement.",
      "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Votre entreprise n'a pas accepté le Microsoft Partner Agreement (MPA). L'administrateur global du compte CSP doit accepter le MPA pour reprendre toutes les fonctionnalités transactionnelles.",
      "PCA_MISSING_MPA":"Contrat de Partenaire Microsoft manquant du Partenaire.",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Obtenir le statut de paiement en attente à partir de la facturation",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Mettre à jour le statut du paiement en c3",
      "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Mise à jour du statut de paiement pour {{recordName}}",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Actions",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"État du paiement de la facturation",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"Statut de paiement C3",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Nom de l'entité",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Montant payé",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Nom de l'enregistrement",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Afficher les détails",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Actions",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Identifiant de commande",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Commandé le",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Remarques",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Type de demande",
      "Percentage":"Pourcentage",
      "PÉRIODE DE FACTURATION":"Période de facturation",
      "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Clés logicielles",
      "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Télécharger le logiciel et les clés",
      "PHONE_TABLE_HEADER_ACTIONS":"Actions",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Numéro de téléphone",
      "PHONE_TABLE_HEADER_PHONE_TYPE":"Type de téléphone",
      "PHONE_TYPE_HOME":"Maison",
      "PHONE_TYPE_HOME_FAX":"Télécopie à domicile",
      "PHONE_TYPE_MAIN":"Principal",
      "PHONE_TYPE_MOBILE":"Mobile",
      "PHONE_TYPE_OFFICE":"Bureau",
      "PHONE_TYPE_OTHER":"Autre",
      "PHONE_TYPE_WORK":"Travail",
      "PHONE_TYPE_WORK_FAX":"Télécopieur professionnel",
      "PLACE_HOLDER_SEARCH_CUSTOMER":"Rechercher un client",
      "PLACEHOLDER_ENTER_TEXT":"Entrez le texte",
      "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Sélectionnez le cycle de facturation...",
      "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Sélectionnez le type de facturation...",
      "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Sélectionner les catégories",
      "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Sélectionnez le type de catégorie...",
      "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Sélectionnez le type de consommation...",
      "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Rechercher le code de devise",
      "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Rechercher un client",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Sélectionnez un fournisseur client dans la liste ou recherchez le nom ou l'identifiant de domaine...",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"En vigueur à partir de",
      "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Efficace pour",
      "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"MM",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Entrez le texte",
      "PLACEHOLDER_FOR_PLAN_NAME":"Entrez le nom du plan",
      "PLACEHOLDER_FOR_PRICE":"Prix",
      "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Sélectionner des fournisseurs",
      "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Rechercher un client",
      "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Sélectionnez des rôles..",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Sélectionner",
      "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Sélectionnez les valeurs de balise..",
      "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Sélectionnez la durée du terme...",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Recherche...",
      "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Sélectionnez la durée de l'essai...",
      "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Sélectionnez le type de validité...",
      "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"AAAA",
      "PLACEHOLDER_SPECIAL_QUALIFICATION":"Qualifications spéciales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Modifier",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Modifier les qualifications spéciales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"le nombre maximum de caractères autorisés est de 50",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"devrait avoir au moins un caractère",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Enregistrer",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Aucun",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Ce champ est obligatoire",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Fermer",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Éducation",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Secteur de l'éducation",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Enseignement supérieur",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Site Internet",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Aucun",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Entité appartenant à l'État",
      "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"Sélectionnez {{EntityName}} spécifique ? Cliquez ici...",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Tapez pour rechercher une balise",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Sélectionnez un domaine dans la liste ou recherchez le nom ou l'identifiant de domaine...",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Rechercher un plan",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Nom de produit",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Id Produit ",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Rechercher avec le nom du produit ou l'ID du produit",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Nom du produit, identifiant",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Recherche d'abonnement",
      "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Entrez des identifiants de courriel valides",
      "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"Prix de revient max.",
      "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"Prix de vente max.",
      "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"Validité max.",
      "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"Prix de revient min.",
      "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"Prix de vente min.",
      "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"Validité min.",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Montant du paiement",
      "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Saisir ici...",
      "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Sélectionnez un client existant dans la liste ou effectuez une recherche à l'aide du nom du client ou de l'identifiant du domaine",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Remarques",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Recherche par client",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Rechercher par statut",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Cochez cette case si vous prévoyez d'avoir des offres composites dans ce plan à l'avenir.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"Le statut a été mis à jour avec succès",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"Plans",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Ajouter un nouveau plan",
      "PLAN_CAPTION_TEXT_PLANS":"Plans",
      "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PLAN_CHANGE_FOR_CUSTOMER":"Changer le plan du client ",
      "PLAN_CHANGE_TEXT":"Changement de plan ",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Offres de plans composites",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Plans",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Retour aux offres composites",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Ajouter une offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Modifier l'offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Offres",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Articles sélectionnés",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Prix suggéré : ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Indiquez l'identifiant du composant de facturation au cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Extension(s)",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Extensions",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Supprimer",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Description",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Identifiant du composant de facturation",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Nom de l'offre",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Prix",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Ajouter une offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Retour aux détails du Plan ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Offres composite pour - ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Modifier",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Offres incluses",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Nom",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Prix",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>sous</b> {{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"S'inscrire",
      "PLAN_DETAILS_IS_PUBLIC":"Est publique",
      "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Marchés pris en charge",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"par mois",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Estimation du prix de vente au détail",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"par an",
      "PLAN_DETAILS_PARAGRAPH_4_TEXT":"une fois",
      "PLAN_DETAILS_SHARING_URL":"Partager l'URL",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Vos modifications ont été soumises avec succès",
      "PLAN_IS_IN_PROGRESS_EXCEPTION":"Plan toujours en cours. Veuillez vérifier le statut",
      "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"La valeur minimale est -1",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Définissez la limite sur -1 pour exiger l’approbation de tous les achats/mises à jour de postes.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Fixez la limite à 0 pour permettre l’achat de sièges illimités.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Définissez un nombre positif non nul pour définir le seuil.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_4":"Tout achat dépassant le seuil nécessite l’accord du Partenaire.",
      "PLAN_LEVEL_SEATLIMIT_EDIT":"Définir la limite de sièges par produit du plan",
      "PLAN_LEVEL_SEATLIMIT_REVERT":"Revenir à la limite de sièges au niveau du plan",
      "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Chargez les dernières promotions Microsoft NCE disponibles dans les plans.",
      "PLAN_MACRO_DETAILS_LABEL_TEXT":"Détails de la macro",
      "PLAN_MACRO_NAME_LABEL_TEXT":"Nom de la macro",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Quantité",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Valeur de la macro",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Retour aux Plans",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Gérer les offres composites",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Détails pour plan -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Ajouter un nouveau plan",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Charger les promotions",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Annuler",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Charger les promotions du fournisseur",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Soumettre",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Est privé",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"Autoriser les abonnements CSP à être partagés par rapport aux offres du plan?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Offres",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Vous trouverez ici les étapes pour remplacer une offre transmise:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Cochez la case à côté de l'offre que vous désirez remplacer",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Fournir un nouveau pseudonyme",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Spécifier le nouveau prix de vente",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Le nouveau plan commencera à lister les offres remplacées avec celles qui sont héritées mais pas remplacées.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Indiquez l'identifiant du composant de facturation au cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"REMARQUE:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Remplacer depuis le plan parent",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"sélectionné",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Articles",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Extension(s)",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible à l'achat",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Identifiant du composant de facturation",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Nom de l'offre",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"Extension",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Type de licence",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Supprimer",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Prix de vente",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Mettre à jours le prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Nom du plan : ",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Prix : ",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Définir un nouveau prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Type de licence",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Offre remplacée",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Offre sélectionnée",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Description",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"Partager l'URL",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Plans",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Gérer",
      "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"Le nom convivial ne peut pas être vide",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Afficher les offres avec promotion",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"offreAvecPromotion",
      "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"OFFRE D'ESSAI",
      "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Noms des parents",
      "PLAN_NAME_IS_INVALID":"Le nom du plan n'est pas valide",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"Est basé sur la facture?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"Définir un nouveau prix pour",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Le changement de prix sera effectif",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Nouveau prix",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Prix actuel",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Mise à jour réussie du prix du plan d'offre ",
      "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Afficher les offres avec une marge <= 0",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Afficher les offres avec marge > 0 et < 10",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Afficher les offres avec une marge >= 30",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Afficher les offres avec marge >= 10 et < 20",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Afficher les offres avec une marge >= 20 et < 30",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Gérer le plan",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Remplacer le plan",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Plans",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Retour aux Plans",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Modifier plan",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Remplacer le plan",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"Autoriser les abonnements CSP à être partagés par rapport aux offres du plan?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Est privé",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Description",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Fournissez l'identifiant du composant de facturation dans le cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"REMARQUE:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Vous trouverez ici les étapes pour remplacer une offre transmise:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Cochez la case à côté de l'offre que vous désirez remplacer",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Fournir un nouveau pseudonyme",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Spécifier le nouveau prix de vente",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible à l'achat",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Identifiant du composant de facturation",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Offre remplacée",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Éléments sélectionnés pour remplacer",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Offres",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"Partager l'URL",
      "PLAN_POPUP_GRID_HEADER":"Télécharger la grille du plan",
      "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans le plan, voulez-vous ajouter le produit à nouveau ?",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Code de devise du plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Sélectionner",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Code de devise source",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Définir une nouvelle devise",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Nombre de sièges autorisées",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Nombre de sièges",
      "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Valeur de siège non valide pour le produit {{PlanProductName}}",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Une erreur s'est produite lors de la mise à jour des sièges",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Sièges mis à jour avec succès",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Blocs enregistrés avec succès",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Modifier",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Remplacer",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "PLAN_TABLE_HEADER_TEXT_NAME":"Nom",
      "plandetails":"Détails du plan",
      "planproductcurrencyconversion":"Conversion de devises",
      "PlanProductId":"PlanProductId",
      "plans":"Plans",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Veuillez sélectionner au moins deux offres afin de créer une offre composite",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Offre composite sauvegardé avec succès",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Offre composite mise à jour avec succès",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Annuler",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Fermer",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Soumettre",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Tous les produits",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"Aucun enregistrement trouvé",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Veuillez enregistrer ou fermer la ligne nouvelle ou modifiée existante.",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Veuillez entrer une valeur supérieure à \" + minValue : \"Veuillez entrer une valeur entre \" + minValue + \" et \" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Veuillez saisir une valeur comprise entre {{minValue}} et {{maxValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Veuillez saisir une valeur supérieure à {{minValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Détails du contrat",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Type de facturation",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Coût pour le partenaire",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Blocs",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Prix de vente",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"Valeur maximale",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Valeur minimale",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Applicabilité",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Vous avez déjà une offre de contrat dans votre plan. Supprimer/désactiver l'offre avant d'en ajouter une nouvelle.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Contrat",
      "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Réservation",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Plan remplacé mis à jour avec succès",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan remplacé avec succès",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Veuillez sélectionner les offres à remplacer dans le plan de base ",
      "PLANS_SAVE_SUCCESS":"Plan sauvegardé avec succès",
      "PLANS_UPDATE_SUCCESS":"Plan mis à jour avec succès",
      "PLEASE_ENTER_THE_REASON":"Veuillez fournir une raison.",
      "PO_NUMBER":"Numéro de commande",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Portée d'affectation de politique",
      "PONUMBER_BUTTON_CLOSE":"Fermer",
      "PONUMBER_BUTTON_SKIP":"Ignorer",
      "PONUMBER_BUTTON_SUBMIT":"Soumettre",
      "PONUMBER_DELETE_CONFIRMATION":"Êtes-vous sûr de vouloir supprimer ce numéro de bon de commande du panier ?",
      "PONUMBER_DELETE_ERROR_MESSAGE":"Une erreur s'est produite lors de la suppression du numéro de commande du panier !",
      "PONUMBER_DELETE_SUCCESS_MESSAGE":"Le numéro de bon de commande demandé a été supprimé avec succès !",
      "PONUMBER_PLACEHOLDER":"Entrez le numéro de bon de commande",
      "PONUMBER_POPUP_TITLE":"Ajouter un numéro de bon de commande",
      "PONUMBER_TITLE":"Numéro de bon de commande",
      "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre tous les plans en file d'attente ?",
      "POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "POPUP_BUTTON_TEXT_CLOSE":"Fermer",
      "POPUP_BUTTON_TEXT_CONFIRM":"Confirmer",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Toutes les offres sélectionnées dans le cadre du cycle de facturation sélectionné seront perdues. Voulez-vous continuer ?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Ce client possède déjà un coupon : <b>{{CouponName}}</b>, voulez-vous le remplacer ?.",
      "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"La suppression du client de facturation supprimera également les profils de paiement associés. Voulez-vous vraiment supprimer le lien client de facturation pour {{CustomerName}} ?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la notification personnalisée ?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Notification personnalisée supprimée avec succès.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{CustomerName}} ?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"La suppression ici entraînera la suppression de l'offre des forfaits revendeur ajoutés et leurs clients ne pourront plus l'acheter ! Êtes-vous sûr de vouloir supprimer l'offre {{distributorOffer}} ?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} supprimé avec succès.",
      "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"Voulez-vous vraiment supprimer cette notification ?",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"SUPPRIMER",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{customOfferName}} ?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} supprimé avec succès.",
      "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>OFFRE D'ESSAI</sup>",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{planName}} ?",
      "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"Etes-vous sûr de vouloir supprimer la citation ?",
      "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la ligne de devis <b>{{ProductName}}</b> ?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"Êtes-vous sûr de vouloir supprimer ?",
      "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer cette entité marquée ?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer le message {{MessageBody | limitTo : 30}} ?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"Etes-vous sur de vouloir supprimer ce message?",
      "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la notification du webhook ?",
      "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"La notification Webhook a été supprimée avec succès",
      "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"La notification du webhook a été supprimée avec succès.",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"Voulez-vous vraiment désactiver {{productName}} ?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> est une offre parent pour le produit d'essai.<br/> Si cette offre est désactivée, l'offre d'essai sera également désactivée.<br/> Êtes-vous sûr de vouloir désactiver < b>{{productName}}<b/> ?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> est une offre parent pour le produit d'essai.<br/> La désactivation de ce produit affectera la conversion du produit d'essai en abonnement<br/> Êtes-vous sûr de vouloir désactiver <b>{ {productName}}<b/> ?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"Voulez-vous vraiment activer {{productName}} ?",
      "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> l'offre parent n'est pas active.<br/> L'activation de l'offre d'essai activera également l'offre parent.<br/> Êtes-vous sûr de vouloir activer <b>{{productName}} <b/>?",
      "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Engager la notification est supprimée.",
      "POPUP_PLAN_DELETED":"Le plan {{planName}} est supprimé.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"RÉTABLIR",
      "POPUP_REVERT_SUB_HEADER_TEXT":"Êtes-vous sûr que vous voulez rétablir ?",
      "POPUP_TEXT_DEACTIVATE_PSA_LOG":"Êtes-vous sûr de vouloir désactiver le(s) journal(s). Nous ne supprimons pas les journals dans le PSA, cette opération vous permettra de re-télécharger les données plus d'une fois de C3 vers PSA. Veuillez noter que la remise en ligne peut potentiellement créer des doublons dans PSA.",
      "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"Désactivation des journaux PSA réussie",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Vos offres sélectionnées avec le cycle de facturation actuel seront perdues, voulez-vous toujours continuer ?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Vous avez des modifications non enregistrées, voulez-vous toujours continuer ?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Voulez-vous vraiment mettre à jour le nom du client ?",
      "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre à jour le nom du revendeur ?",
      "POPUP_WEB_NOTIFICATION_DELETED":"La notification Web {{MessageBody}} est supprimée.",
      "POPUP_WEB_NOTIFICATION_DELETED2":"La notification Web est supprimée.",
      "PORTAL_ACCESS_GRANTED_TO_USER":"L'accès au portail est accordé à {{UserName}}",
      "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Sélectionnez la page du portail",
      "PORTAL_PAGE_NAME":"Nom de la page du portail",
      "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Données d'utilisation",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Le changement de prix sera appliqué dès le début du cycle de facturation de l'abonnement en cours",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Immédiatement",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"À partir de la prochaine facturation",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"À partir du prochain renouvellement",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Fermer",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Fermer",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Aperçu de la notification par courriel",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Aperçu de la notification par courriel",
      "Price":"Prix",
      "PRICE_CHANGE_CURRENCY":"Devise",
      "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"La valeur saisie ne correspond pas à la configuration de verrouillage des prix choisie",
      "PRICE_CHANGE_NEW_PRICE":"Nouveau prix",
      "PRICE_CHANGE_OLD_PRICE":"Ancien prix",
      "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instructions pour l'API de tarification Microsoft",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Merci d'avoir donné votre consentement. L'application peut être configurée pour accéder à la tarification Microsoft.",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions retrouvées sur Paramètres -> Centre partenaire dans <b>{{applicationName}}</b>.",
      "PRICING_API_VIEW_INSTRUCTIONS_1":"pour copier le lien qui capture le consentement du Partenaire pour accorder à cette application l'accès au \"Partner Center\" conformément à l'API de tarification Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Ouvrez le lien dans une session de navigation privée et complétez la procédure de consentement à l'aide d'un compte d'administrateur global dans votre domaine (partenaire) et auquel le rôle d'agent admin lui est affecté dans le centre partenaire.",
      "PRICING_API_VIEW_INSTRUCTIONS_3":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec l'API de tarification Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_5":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Actions",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Type de facturation",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Coût d'acquisition",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Nom d'affichage",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"MaxValue",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"MinValue",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Prix de vente",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Prix de vente",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Type de facturation",
      "PRIVACY_POLICY_BUTTON_TEXT":"POLITIQUE DE CONFIDENTIALITÉ",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Processus d'intégration massive des clients terminé",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Processus d'intégration massive des clients initié",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Processus de chargement des factures sur Quickbooks terminé",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Processus de chargement des factures sur Quickbooks initié",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Processus de chargement des factures sur Xero terminé",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Processus de chargement des factures sur Xero initié",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Processus de chargement des factures sur Quickbooks échoué",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Processus de chargement des factures sur Quickbooks réussi",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Processus de chargement des factures sur Xero échoué",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Processus de chargement des factures sur Xero réussi",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Vos éléments sélectionnés ont été supprimés.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"La modification de la valeur de ce champ entraînera la perte des éléments sélectionnés",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Nombre de jours maximum permis sans frais d'annulation.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Type de vente",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Valide",
      "PRODUCT_CANCELLATION_TEXT":"L'annulation est disponible",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Nous apportons actuellement quelques modifications à ce produit. Veuillez attendre la fin de ces procédures avant d'effectuer une nouvelle demande.",
      "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Sélectionnez un moyen d'obtenir le rapport sur le catalogue de produits",
      "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"E-mail envoyé avec succès",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée : ",
      "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Date de fin",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Commission(%)",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Date de début",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"ID produit interne",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"Code SP",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"Montant des commissions",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Code de devise",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"Code SP",
      "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"La commission pour les produits d'un Client sera calculée une fois les factures finalisées. Pour le calcul des commissions d'un Client, les détails de la commission spécifiés par l'utilisateur dans la période de facturation respective seront uniquement pris en considération. Si aucun détail de commission n'est spécifié dans une période de facturation, les détails de commission définis dans la période de facturation précédente et valables jusqu'à la période de facturation de la facture seront pris en considération.",
      "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Valider le catalogue",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Date de fin de commission",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Commission(%)",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Date de début des commissions",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Nom du domaine",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"ID produit interne",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nom du produit",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"Code SP",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Instructions",
      "PRODUCT_COULD_NOT_FOUND_TEXT":"Le type de produit est introuvable.",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Approuver",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Quantité actuelle",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Remarque du client",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Rejeter",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Quantité demandée",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Limite de siège",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Quantité d'origine",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Lire moins",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Lire plus",
      "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Attributs du produit",
      "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Valider le catalogue",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Catégorie",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Séquence d'affichage",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"ID de l'offre",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Code produit",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Nom du fournisseur",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "PRODUCT_LIST_ADDON_LABEL":"Add-on",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Nous avons annulé cette demande car certaines modifications ont été mises à jour.",
      "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"En attente de l'approbation.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Sélectionnez au moins un produit actif avant de continuer.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Sélectionnez au moins un utilisateur dans la liste.",
      "PRODUCT_MAPPING":"Mappage des produits",
      "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer le mappage pour les produits sélectionnés ?",
      "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Ce produit n'est pas disponible à l'achat.",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Produit non mappé car le locataire est lié à plusieurs clients",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Produit non mappé car le locataire est lié à plusieurs clients ou le produit a déjà été ignoré",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"Le produit est déjà mappé",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Pourcentage",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Quantité",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Nom de portée",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Vous ne pouvez pas mettre à jour la quantité à une valeur supérieure à la quantité disponible. Si vous voulez faire cela, veuillez augmenter la quantité à partir de la vue Détails de base.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Vous pouvez mettre à jour la quantité par l'édition de la ligne et cliquez sur le bouton Envoyer pour enregistrer",
      "PRODUCT_OWNERSHIP_SUCCESS":"Propriété mise à jour avec succès",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Ajouter",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Sauvegarder les modifications",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Saisir une valeur de balise unique",
      "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Balises de produits enregistrées avec succès",
      "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Étiquettes de produit",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Description",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Ajouter une clé de balise",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Ajouter une valeur de balise",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Actions",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Description",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Clé de Balise",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Valeur de balise",
      "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Étiquettes de produit",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Code de devise",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Code du marché pris en charge",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "PRODUCT_VIEW_GRID":"Grille",
      "PRODUCT_VIEW_LIST":"Liste",
      "productcatalogue":"Catalogue des produits",
      "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Renouvellement/Expiration (dans quelques jours)",
      "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Date de commande max",
      "PRODUCTS_REPORT_MAX_QUANTITY":"Quantité maximale",
      "PRODUCTS_REPORT_MIN_QUANTITY":"Quantité minimale",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Tableau de bord",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Profil client",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Détails bancaires",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Carte de crédit",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Type de paiement",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Veuillez sélectionner le type de paiement",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Profile de paiement mis à jour avec succès",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Détails de l'utilisateur",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Ville",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"Pays",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Courriel",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Prénom",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Nom de famille",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Ligne 4",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Ligne 5",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"État",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Code postal",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Courriel",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Configuration client",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Configuration revendeur",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Supprimer un profil de paiement",
      "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Définir comme profil de paiement par défaut",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Numéro de téléphone",
      "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "PROFILE_TAB_CONFIGURATION_SETTING":"Paramètres de configuration",
      "PROFILE_TAB_ORGANIZATION_SETUP":"Configuration de l'organisation",
      "PROFILE_TAB_PAYMENT_PROFILE":"Profil de paiement",
      "PROFILE_TAB_USER_DETAILS":"Détails de l'utilisateur",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Faire défaut",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Soumettre",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Enregistrer",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Enregistrer",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Ville",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"Pays",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Courriel",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Prénom",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Nom de famille",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Ligne 3",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Ligne 4",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Numéro de téléphone",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"État",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Code postal",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Adresse de facturation",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Détails de facturation",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Ajouter une méthode de paiement",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"REMARQUE:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Le nouveau profile de paiement sera utilisé pour toutes les transactions ultérieures",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Changer la méthode de paiement",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Détails du profile de paiement ",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Veuillez ajouter une méthode de paiement.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Vous avez déjà un profil de paiement dans nos dossiers.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Les 4 derniers chiffres de votre méthode de paiement",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Méthode de paiement ",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Détails de l'utilisateur",
      "PROMOTION_ERRORS_ALERT_TEXT":"Erreurs de promotion",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Vous ne pouvez pas acheter cet article, car votre compte n'est pas lié à ce fournisseur !",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"Échec de la génération du mot de passe pour l'administrateur du client du fournisseur",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Mot de passe généré avec succès pour l'utilisateur administrateur du client du fournisseur",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Courriel de facturation",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Les achats contre les domaines CSP peuvent échouer en raison du manque de consentement du client. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><i class=\"red bold\"> Cliquez ici pour rediriger vers le profil du domaine et ajouter le MCA </i></a>",
      "PROVIDER_CUSTOMER_DOMAIN":"Domaine client du fournisseur",
      "PROVIDER_CUSTOMER_ID":"CustomerId du fournisseur",
      "PROVIDER_CUSTOMER_NAME":"Nom du client fournisseur",
      "PROVIDER_DESC_MICROSOFT":"Microsoft",
      "PROVIDER_DESC_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "PROVIDER_DESC_PARTNER":"Partenaire",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nom du fournisseur",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Coût pour le partenaire",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Montant payé",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Date de paiement",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Fournisseur",
      "PROVIDER_PRODUCT_ID":" Identifiant du produit du fournisseur :",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Courriel de facturation",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Code de pays",
      "PROVIDER_RESELLER_ID":"Identifiant du revendeur du fournisseur",
      "PROVIDER_RESELLER_MPNID":"Identifiant MPN du revendeur du fournisseur",
      "PROVIDER_RESELLER_NAME":"Nom du revendeur du fournisseur",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Détails de l'erreur",
      "PROVIDER_RESELLER_ONBOARDING_STATUS":"Statut d'intégration",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft",
      "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Client existant",
      "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Nouveau client",
      "providers":"Fournisseurs",
      "provisioning":"Approvisionnement",
      "PROVISIONING_LABLE_TEXT":"Provisionnement ...",
      "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Maître",
      "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Accord-cadre",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Veuillez sélectionner  un fichier à charger",
      "PSA_BULK_UPLOAD_FAILED_STATUS":"Le mappage d'entités en bloc mis à jour dans C3 a échoué.",
      "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"La cartographie des produits d'achat en gros a été partiellement téléchargée avec succès dans C3. Veuillez vérifier la feuille de mappage d'entité invalide téléchargée pour retravailler.",
      "PSA_BULK_UPLOAD_SUCCESS_STATUS":"La cartographie des produits d’achat en gros est téléchargée avec succès dans C3.",
      "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Tester la connectivité",
      "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Contrat de recherche",
      "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Sélectionner un contrat",
      "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"Recherche de service PSA",
      "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"Sélectionnez le produit PSA",
      "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Aucun accord mappé pour ce cycle de facturation",
      "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"Nous n'avons pas été en mesure d'obtenir la date de début du contrat de PSA, donc incapables de nous aligner",
      "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Aucun contrat mappé pour ce cycle de facturation",
      "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Ces journaux ne tombent pas dans la période sélectionnée",
      "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"La date de début, la date de fin et le rapport d'heure nécessitent des heures de début et de fin ou la valeur des frais d'installation est manquante. Ce sont des valeurs requises pour créer un contrat. Veuillez mettre à jour ces valeurs dans les configurations client.",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"La date de l'historique des abonnements est antérieure à la nouvelle date calculée après l'exécution de l'opération d'alignement sur la date de début du contrat",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Lors de la tentative d'alignement, aucun abonnement actif n'a pu être trouvé pour effectuer l'opération d'alignement.",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Choisir une catégorie",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Sélectionnez la classe de produit",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"Sélectionnez SLA",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Sélectionnez la sous-catégorie",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Sélectionnez UOM",
      "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inactif",
      "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Rechercher un client PSA",
      "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Sélectionnez l'accord",
      "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Choisir une catégorie",
      "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Sélectionnez {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'accord de contrat' }}",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Sélectionner un client",
      "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Sélectionnez le client PSA",
      "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"Sélectionnez le produit PSA",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Sélectionnez {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'Service PSA' : 'Produit PSA' }}",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Sélectionnez le locataire",
      "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"L'actualisation des données est en cours, d'autres options seront accessibles une fois le dernier processus de données terminé.",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Avertissement",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Les données des dates précédentes ne seront pas disponibles après l'utilisation de la date filtrée pour les clients sélectionnés.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Note",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Veuillez noter que pour le téléchargement de factures régénérées, vous avez besoin de l'assistance de l'équipe d'assistance C3.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"La définition de StartDate et EndDate ici se refléterait dans les ajouts créés dans Connectwise comme date de début d'ajout et date d'annulation pour tous les éléments de ligne de facture applicables.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Si aucune date de début ni de date de fin n'est définie, les dates de la période de facturation de la facture seront prises en compte.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"La liste des clients ci-dessous contiendra uniquement les clients dont la ou les factures ont le statut « Finalisé » pour la période sélectionnée.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Si la case <span class=\"font-italic\">(Aligner avec la date de début du contrat)</span> est cochée, nous calculerons la prochaine période de contrat récente à télécharger. Veuillez noter que la prochaine période de contrat récente est calculée uniquement sur une base mensuelle, même si la période de contrat est <span class=\"font-italic\">Annuelle</span>.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"La date de début empêchera les anciens journaux d'être pris en compte pour le téléchargement.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Veuillez utiliser les filtres uniquement si vous essayez de transmettre des données pour la première fois pour un client. Par exemple : <ul><li>Inscription du partenaire, c'est-à-dire, première poussée de C3 vers Autotask</li><li>Intégration d'un nouveau client</li></ul>",
      "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Commencez le processus de téléchargement en sélectionnant des clients et en cliquant sur le bouton <span class=\"bold\">Valider</span> pour démarrer le processus de validation",
      "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Veuillez synchroniser avec le Partner Center avant le téléchargement",
      "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Veuillez noter que toutes les dates (y compris les filtres) seront prises en compte en UTC.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Suivez la progression de la validation en cliquant sur le bouton <span class=\"bold\">Afficher les journaux</span>.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_3":"En cas d'erreurs de validation, apportez les corrections nécessaires et réessayez.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Si la validation est réussie, vous pouvez continuer à <span class=\"bold\">télécharger</span> les données vers Autotask.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Si la validation est réussie, vous pouvez continuer à <span class=\"bold\">télécharger</span> les données sur ConnectWise.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"L'option de répéter le téléchargement n'est donnée qu'en cas d'erreur.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"L'option de répéter le téléchargement n'est donnée qu'en cas d'erreur.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Veuillez cliquer sur ce bouton pour tester la connectivité à PSA avant de procéder à la validation.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Seuls les nouveaux journaux d'historique d'abonnement des clients sélectionnés, le cas échéant, seront pris en compte pour le téléchargement. Les clients sélectionnés peuvent être exclus s'il n'y a pas de nouveaux journaux.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_8":"En cliquant sur la case à côté du titre, vous pouvez valider les journaux d'abonnement pour tous les clients actifs dans le système.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Veuillez sélectionner les clients que vous souhaitez télécharger dans la liste ci-dessous",
      "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Cochez cette case uniquement si vous souhaitez valider les journaux d'abonnement pour tous les clients actifs du système.",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"L'accord PSA n'est pas mappé avec le client c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"Le client externe n'est pas mappé avec le client c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Le produit externe n'est pas mappé avec le produit c3",
      "PUBLIC_SIGNUP_ADD_TO_CART":"Ajouter au panier",
      "PUBLIC_SIGNUP_ADMIN_PORTAL":"Portail administrateur",
      "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"appliquer les promotions",
      "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Demande d'aide à l'inscription publique",
      "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"La passerelle de paiement d'autorisation n'est pas encore prise en charge. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Retour à la liste des produits",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Rechercher un produit",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Recherche de produits",
      "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Les meilleures fonctionnalités de sa catégorie pour vous aider à réussir.",
      "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"En-tête de paragraphe de la bannière d'inscription publique",
      "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Détails de base",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"une fois",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"par 3 ans",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"par mois",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"par an",
      "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Voir le panier",
      "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Contient des addons",
      "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Contient un produit lié",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Veuillez sélectionner au moins une offre Microsoft pour découvrir",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Veuillez appliquer le coupon avant de passer la commande",
      "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Termes et conditions.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez activer les conditions générales NCE pour commander des produits Microsoft NCE.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-Azure-experience-in-csp/\">Conditions générales NCE.</a>",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"Votre panier est actuellement vide !",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"N'hésitez pas à parcourir notre sélection pour trouver les produits dont vous avez besoin. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Retournez à notre boutique en ligne</strong></a> et commencez à ajouter des articles à votre panier",
      "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Site Internet",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Confirmer",
      "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Contactez le support",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Nous contacter",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Poursuivre votre magasinage",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"J'accepte la dernière",
      "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"Ne fermez pas ce navigateur tant que le processus n'est pas terminé",
      "PUBLIC_SIGNUP_EDIT_TEXT":"Modifier la commande",
      "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"La passerelle de paiement Ezidebit n'est pas encore prise en charge. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_FAILED_WARNING":"Excuses. Le système backend n'a pas réussi à configurer le compte en raison d'une erreur inconnue. Veuillez contacter le support avec le BatchID",
      "PUBLIC_SIGNUP_FAILED_WARNING1":"pour vous aider davantage.",
      "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Marchés pris en charge",
      "PUBLIC_SIGNUP_GO_TO_CART":"Aller au panier",
      "PUBLIC_SIGNUP_GOTO_HOME":"Aller à la page d'accueil",
      "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"S'inscrire",
      "PUBLIC_SIGNUP_INVALID_QUANTITY":"Les produits dans le panier ont des quantités invalides",
      "PUBLIC_SIGNUP_LEARNER_PORTAL":"Portail de l'apprenant",
      "PUBLIC_SIGNUP_LOGS_WARNING":"Le traitement peut prendre un certain temps et le statut sera signalé sur cette page. Si vous vous éloignez de la page ou avez fermé le navigateur par accident, nous vous tiendrons au courant par e-mail.",
      "PUBLIC_SIGNUP_NEXT":"Suivant",
      "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"Pas de description disponible",
      "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Nous avons créé le panier avec succès",
      "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"L'offre est déjà disponible dans le panier",
      "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Veuillez fournir au moins 1 quantité",
      "PUBLIC_SIGNUP_ORGANIZATION_ID":"Identifiant de l'organisation",
      "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"L'inscription n'est pas prise en charge car la passerelle de paiement n'est pas encore configurée. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Méthode de paiement ",
      "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Payer maintenant",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Passer la commande",
      "PUBLIC_SIGNUP_PORTLET_HEADER":"S'inscrire",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Panier",
      "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Revoir le panier",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Magasin",
      "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Détail",
      "PUBLIC_SIGNUP_QTY":"QTÉ",
      "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"La quantité doit être supérieure à 0",
      "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"La quantité est requise",
      "PUBLIC_SIGNUP_QUICK_CART":"Fermer le panier",
      "PUBLIC_SIGNUP_QUICK_VIEW":"Aperçu rapide",
      "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"En cliquant sur OK, les produits NCE précédemment ajoutés seront supprimés car les produits NCE de différentes régions de marché ne peuvent pas être achetés en même temps.",
      "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Retour aux détails",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Appliquer des filtres",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Effacer les filtres",
      "PUBLIC_SIGNUP_SIDE_CART":"Panier",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"add-ons",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"add-ons",
      "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Prix estimé",
      "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":"Produit lié",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Supprimer",
      "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"S'inscrire et acheter",
      "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"Vous disposez déjà d’un client MS existant ?",
      "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Veuillez patienter pendant que nous configurons votre compte. Vous pouvez continuer à utiliser votre appareil normalement. Nous vous informerons par e-mail une fois la configuration terminée.",
      "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Passer et continuer",
      "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Payer maintenant",
      "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"Inscription réussie ! Vous pouvez désormais vous connecter au portail C3 en utilisant les identifiants envoyés à",
      "PUBLIC_SIGNUP_SUPPORT":"Soutien",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Termes et conditions d'inscription publique 1",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Termes et conditions d'inscription publique 2",
      "PUBLIC_SIGNUP_TERMS_OF_USE":"Conditions d'utilisation",
      "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Trier de A à Z",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Trier de Z à A",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Trier par",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Prix : du plus élevé au plus bas",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Prix croissant",
      "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Entrer le code de réduction",
      "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Les offres sélectionnées ne sont pas prises en charge pour le pays sélectionné, alors revérifiez les offres sélectionnées",
      "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Acheté le.",
      "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Expire le.",
      "PURCHASE_PRODUCT_MAPPING":"Acheter la cartographie des produits",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Achetez automatiquement les articles de la ligne de devis.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Si nous décochons la case après avoir approuvé le devis, le partenaire/revendeur doit acheter les produits manuellement au nom du client.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Si nous décochons la case après avoir approuvé le devis, le partenaire/revendeur doit acheter les produits manuellement au nom du client.",
      "PurchaseCurrency":"AchatDevise",
      "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Mappage des produits achetés",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Extensions",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Expire le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Commandé le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Nom de produit",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Quantité",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"Se renouvelle le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Statut",
      "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Modifier la quantité",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"La tentative de harmoniser l’écart a échouée. Veuillez contacter le support pour obtenir de l'aide",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"Correction des quantités terminée avec succès",
      "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Compte attendu",
      "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Est applicable",
      "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Nombre de messages",
      "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"Nom de file d'attente",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Impersonation rapide",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Les utilisateurs suivants sont disponibles pour en représenter d’autres, choisissez l'un des utilisateurs pour continuer",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Personnifier",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Actions",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Rôle",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Utilisateur(s)",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Activités",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Alertes",
      "QUICK_SIDEBAR_LABEL_MORE":"Autre",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Notifications",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"Paramètres",
      "QUICK_SIDEBAR_LABEL_USERS":"Utilisateurs",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Merci d'avoir fourni votre consentement pour QuickBooks. L'application peut être configurée pour accéder à QuickBooks suivant la nouvelle directive de sécurité. Vous pouvez publier les factures de vos clients sur QuickBooks.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions pour Charger les factures dans QuickBooks dans <b>{{applicationName}}</b>.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Le lien ci-dessous s'ouvre dans un nouvel onglet. Veuillez fournir votre consentement avec vos identifiants QuickBooks. Si une erreur se produit lors de votre consentement, veuillez contacter notre équipe d'assistance.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"pour fournir le consentement à QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Si vous avez déjà fourni le consentement, veuillez cliquer sur le bouton <b> Valider le jeton </b> pour vérifier la connectivité avec QuickBooks.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquez sur ce bouton pour vérifier la validité du jeton d'actualisation QuickBooks existant.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Conditions préalables pour que le partenaire publie les factures des clients dans <b> QuickBooks Online </b>",
      "QUICKBOOKS_POST_BATCH_ID":"Identifiant de lot de publication QuickBooks",
      "QUICKBOOKS_POST_LOGS_STATUS":"Statut des journaux de publication Quickbooks",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Le jeton d'actualisation QuickBooks existant n'est pas valide. Veuillez fournir à nouveau votre consentement",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Le jeton d'actualisation QuickBooks existant est valide. Vous pouvez continuer à charger des factures",
      "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Détails du devis",
      "QUOTE_ALL_SELECTION":"Sélectionner tout",
      "QUOTE_APPROVAL_CONFIRMATION":"Etes-vous sûr de vouloir approuver le devis ?",
      "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Devis approuvé et commande passée avec succès.<br/> L'identifiant de commande est <b>{{OrderNumber}}</b>",
      "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Devis approuvé avec succès.",
      "QUOTE_AUDIT_LOG_DELETED_BY":"Supprimé par",
      "QUOTE_AUDIT_LOG_ID":"ID de citation",
      "QUOTE_AUDIT_LOG_NAME":"Nom de la citation",
      "QUOTE_AUDIT_LOG_QUOTE_NAME":"Nom de la citation",
      "QUOTE_BREADCRUMB_QUOTE_ADD":"Créer des devis",
      "QUOTE_BREADCRUMB_QUOTE_EDIT":"Modifier les citations",
      "QUOTE_BREADCRUMB_QUOTE_LIST":"Citations",
      "QUOTE_BUTTON_TEXT_AGE":"âge",
      "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Retour à la liste des citations",
      "QUOTE_BUTTON_TEXT_CANCEL":"Annuler",
      "QUOTE_BUTTON_TEXT_CLOSE":"Fermer",
      "QUOTE_BUTTON_TEXT_COPY":"Copie",
      "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"Écrire un e-mail avec une citation",
      "QUOTE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "QUOTE_BUYER_INFO":"Informations sur l'acheteur",
      "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Informations pour les acheteurs",
      "QUOTE_CHOOSE_BUYER_DETAILS":"Choisissez les coordonnées de l'acheteur que vous souhaitez inclure dans le devis.",
      "QUOTE_CHOOSE_SELLER_DETAILS":"Vérifiez les détails vous concernant et concernant votre entreprise qui seront affichés sur le devis.",
      "QUOTE_COMMENTS_TO_BUYERS":"Commentaires",
      "QUOTE_COMPANY":"Entreprise",
      "QUOTE_CONTACT":"Contact",
      "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Jusqu'à trois contacts de devis peuvent être ajoutés.",
      "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Erreur lors de la copie du texte :",
      "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL copiée avec succès.",
      "QUOTE_COUNTERSIGNATURE":" Contre-signature",
      "QUOTE_CREATE_A_LINE_ITEM":"Créer un poste",
      "QUOTE_CREATED":"Citation créée le",
      "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Informations sur les postes",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Enregistrer",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Fréquence de facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Période de facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Description",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Date d'échéance",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Date de fin",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Date de la facture",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"Est taxable",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Nom",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Prix",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Type de vente",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Date de début",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Prix unitaire",
      "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Veuillez saisir une valeur comportant jusqu'à deux décimales.",
      "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"Le devis ne peut pas être approuvé car le client a été supprimé.",
      "QUOTE_DATE":"Date",
      "QUOTE_DOWNLOAD_AS_PDF":" Télécharger en PDF",
      "QUOTE_EMAIL_ERROR":"Erreur de devis par e-mail",
      "QUOTE_EMAIL_MODAL_HEADER":"Texte de l'e-mail de citation",
      "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement du nom du devis et du client car il existe déjà un devis à l'état de brouillon ou publié.",
      "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement. Le client que vous avez sélectionné possède déjà un devis à l'état de brouillon ou publié.",
      "QUOTE_ERROR_NAME_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement du devis car le nom du devis n'est pas unique ; il existe déjà un autre devis portant le même nom, soit dans un état publié, soit dans un état de brouillon.",
      "QUOTE_EVENT_ERROR":"Erreur",
      "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Veuillez saisir la date d'expiration",
      "QUOTE_EXPIRES":"Le devis expire le",
      "QUOTE_INCLUDE":"Inclus dans le devis",
      "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Contacts",
      "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Choisissez le client",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Commentaires",
      "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Contre-signature",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "QUOTE_LABEL_TEXT_EMAIL":"Courriel",
      "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Date d'expiration",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"Prénom",
      "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Prévoir un espace pour une signature écrite",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Pas de signature",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Conditions d'achat",
      "QUOTE_LABEL_TEXT_QUOTE_NAME":"Nom de la citation",
      "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Vos modifications ont été enregistrées",
      "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Options de signature",
      "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Prévoir un espace pour une contre-signature",
      "QUOTE_LABEL_TEXT_TEMPLATE":"Modèle",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Ajouter un client",
      "QUOTE_LABEL_TEXT_WEB_LINK":"Copiez le lien de la page Web ci-dessous pour le partager avec des prospects ou choisissez d'écrire un e-mail avec le devis inclus.",
      "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Veuillez remplir tous les champs obligatoires avant de passer à l'étape suivante.",
      "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Ajouter un élément de ligne personnalisé",
      "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Ajouter un produit de plan",
      "QUOTE_LINE_ITEM_HEADER":"Ligne d'article",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Rabais total",
      "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Total",
      "QUOTE_LINE_ITEM_LABEL_TAX":"Total des impôts",
      "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Valeur totale du devis",
      "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Un maximum de 50 produits peuvent être ajoutés.",
      "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Un maximum de dix quantités peuvent être ajoutées.",
      "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Jusqu'à 9999 éléments de ligne peuvent être ajoutés",
      "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Une quantité minimale d'une est requise.",
      "QUOTE_LINE_ITEM_SUB_HEADER":"Vérifiez la ligne d'article à afficher dans votre devis",
      "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Total",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Quantité",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Les taxes affichées sont approximatives et seront finalisées au moment de la génération de la facture.",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Type de taxe",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Rabais total",
      "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Valeur totale du devis",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Ajoutez au moins un élément.",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Enregistrez les modifications apportées aux lignes de devis avant de continuer.",
      "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Produits pour devis",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Coût d'acquisition",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Quantité",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Éléments de ligne",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Résumé",
      "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Plans clients",
      "QUOTE_LIST_BREADCRUMB_QUOTE":"Liste de citations",
      "QUOTE_LIST_STATUS_PUBLISHED":"Facture publiée",
      "QUOTE_MOVE_BACK_TO_STATE":"Précédent",
      "QUOTE_MOVE_TO_STATE":"Suivant",
      "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"Le nom de la citation doit comporter moins de 150 caractères.",
      "QUOTE_NAME_REQUIRED_ERROR":"Veuillez saisir le nom de la citation",
      "QUOTE_ONE_TIME_SUBTOTAL":"Sous-total unique :",
      "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Approuver le devis",
      "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Supprimer la citation",
      "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Modifier la citation",
      "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Voir la citation",
      "QUOTE_OWNER":"Propriétaire de devis",
      "QUOTE_PDF_PURCHASE_TERM":"Conditions d'achat",
      "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Plans attribués au client",
      "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"S'il vous plaît entrer votre adresse mail.",
      "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Veuillez entrer le prénom.",
      "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Veuillez entrer le nom de famille.",
      "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Veuillez entrer le numéro de téléphone.",
      "QUOTE_PREPARED_BY":"Préparé par:",
      "QUOTE_PRICE_SUMMARY":"Résumé",
      "QUOTE_PRICE_TOTAL":"Total",
      "QUOTE_PRINTED_NAME":"Nom imprimé",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Nom du plan",
      "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Nom du produit",
      "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Propriétés",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Prix de vente",
      "QUOTE_READY_FOR_REVIEW":"Devis prêt à être révisé",
      "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Réviser et publier",
      "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Informations sur le vendeur",
      "QUOTE_SENDER":"Expéditeur de la citation",
      "QUOTE_SIGNATURE":"Signature",
      "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Signature",
      "QUOTE_STATUS":"Statut",
      "QUOTE_STATUS_APPROVED":"Approuvé pour examen par le client",
      "QUOTE_STATUS_DELETED":"Supprimé",
      "QUOTE_STATUS_DRAFT":"Brouillon",
      "QUOTE_STATUS_EXPIRED":"Expiré",
      "QUOTE_STATUS_OOPS_ERROR":"Oups !",
      "QUOTE_STATUS_ORDERPROCESSED":"Commande traitée",
      "QUOTE_STATUS_PUBLISHED":"Facture publiée",
      "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"Nous ne trouvons pas cette page.",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Action",
      "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Créer un rendez-vous",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Date d'expiration",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"Nom",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Montant",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Propriétaire de devis",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"Statut",
      "QUOTE_TABLE_LABELS_USER_ROLES":"Rôle",
      "QUOTE_TABLE_LABELS_USERS":"Clients)",
      "QUOTE_TABLE_PRICE":"Prix unitaire",
      "QUOTE_TABLE_PRODUCT_SERVICE":"Produit et service",
      "QUOTE_TABLE_QUANTITY":"Quantité",
      "QUOTE_TEXT_EMAIL_DETAILS":"Bonjour, <br/><br/>Votre devis est prêt à être examiné,<br/><br/>Détails clés :<br/><br/>Nom du devis : {{quoteName}}<br/><br/>Valable jusqu'au : {{expirationDate}} <br/><br/>Vous pouvez accéder à votre devis et le consulter en utilisant le lien ci-dessous<br/><br/>{{quoteURL}}<br/><br/>",
      "RCON_APPLY_PROMOTIONS_NCE":"Appliquer la promotion",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"Retour aux déploiements RD",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Déployer un nouveau RD",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Déployer",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Détails",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"Paramètres RD ",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Ajouter un nom de domaine",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Mot de passe Admin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Nom d'utilisateur Admin ",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Vignette préfix de la passerelle DNS",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Nom de l'adresse IP publique de la passerelle",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"SKU de l'image",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Sélectionner une image SKU",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Emplacement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Sélectionner l'emplacement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Aucune instance de Rd",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Groupe de ressource",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Sélectionner l'abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"Taille de la VM",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Sélectionner une image SKU",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"Déploiements RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Déployer un nouveau RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Actualiser le statut de déploiement",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"Déploiements RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Gérer",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Actions",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Déployé sur",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Emplacement",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Groupe de ressource",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Statut",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"Retour aux déploiements RD",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Détails de déploiement",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Détails",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Environnement",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Déployé sur",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"Déploiement RD commencé avec succès",
      "READ_THROUGH":"Veuillez lire attentivement les conditions générales ci-dessous",
      "REASON_FOR_FAILURE":"Raison de l'échec",
      "REASON_FOR_UPDATE":"Raison de la mise à jour",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Échec du rechargement du rapport de rapprochement",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Réussite du rechargement du rapport de rapprochement ",
      "RECON_CUSTOMER_REPORT_RELOADING":"Rechargement du rapport de rapprochement",
      "RECON_PRODUCT_INPROVISION_TOOLTIP":"Le produit est en état de mise à disposition",
      "RECON_PRODUCT_ONHOLD_TOOLTIP":"Le produit est en attente d'approbation",
      "RECON_REPORT_COLLAPSE_ALL":"Tout réduire",
      "RECON_REPORT_EXPAND_ALL":"Développer tout",
      "RECON_REPORT_MISMATCH_COUNT_BADGE":"Inadéquations",
      "RECON_REPORT_RELOAD":"Rapport de rapprochement rechargé",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Échec de l'initiation du rechargement du rapport de rapprochement",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Le rechargement du rapport de rapprochement a été initié",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Rechercher",
      "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Recherche par offre, identifiant d'abonnement",
      "RECON_REPORT_SHOW_FIXED_INACTIVE":"Afficher les inactifs fixes",
      "RECON_REPORT_SHOW_MISMATCH":"Afficher uniquement les incohérences",
      "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" Identifiant d'abonnement Azure :",
      "RECON_TABLE_TEXT_BILLING_CYCLE":"Cycle de facturation :",
      "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Abonnement fournisseur",
      "RECON_TABLE_TEXT_REGION":" Région :",
      "RECON_TABLE_TEXT_RESERVATION_ID":" Identifiant de réservation :",
      "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" Identifiant de la commande de réservation :",
      "RECON_TABLE_TEXT_SUBSCRIPTION_ID":"Abonnement :",
      "RECON_TABLE_TEXT_TERM_DURATION":"Validité :",
      "RECON_TABLE_TEXT_TRIAL_OFFER":"Offre d'essai",
      "RECONCILIATION_CUSTOMER_NAME":"Nom du client",
      "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"FOURNISSEUR",
      "RECONCILIATION_MISSMATCH":"Match manqué de réconciliation",
      "RECONCILIATION_NOTES":"Remarques",
      "RECONCILIATION_NOTES_MISMATCH":" Les éléments en <span class='text-danger'>rouge</span> indiquent une incompatibilité",
      "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Les articles en <span class='text-mediumvioletred'>rouge violet</span> ​indiquent une inadéquation liée à la promotion. Contactez l'équipe d'assistance pour relayer l'avantage promotionnel au client final.",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"Les éléments en <span class='red'>rouge</span>  indiquent des écarts dans les quantités",
      "RECONCILIATION_NOTES_RI_INVOICE":"Suite à l’intégration d’une instance réservée, toutes les réservations supplémentaires comportant la même offre dans le Reservation OrderId intégré seront facturées au prix d’intégration.",
      "RECONCILIATION_NOTES_SUSPENDED":"Les éléments <span class = 'strike-through'> saisis </ span> indiquent des abonnements suspendus",
      "RECONCILIATION_PROVIDER":"Fournisseur",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Montant du paiement",
      "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"Êtes-vous sûr de vouloir enregistrer un paiement de <%= montant%> (<%= currencyCode%>) à l'encontre du client ?<br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide supplémentaire concernant les paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur.</small>",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Code de devise",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Sélectionner un client",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Clients",
      "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Le client est requis pour enregistrer le paiement.",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Enregistrer le paiement anticipé",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Remarques",
      "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Code de devise",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Sélectionner un client",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Paiement enregistré avec succès",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Date de paiement",
      "RECORD_ID":"Identifiant d'enregistrement",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Identifiant d'enregistrement reçu du service externe",
      "RECORD_MANUAL_PAYMENT_CONFIRMATION":"Voulez-vous vraiment enregistrer un paiement de <%= currencySymbol%> <%= amount%> sur cette facture ?<br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide concernant des paiements qui ne s'affichent pas. ou ont été enregistrés par erreur.</small>",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Nom de l'entité",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Nom",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importer",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Paiements à l'importation",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Modèle",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catalogue",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Télécharger le modèle",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Paiements à l'importation",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Erreur",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Réussite ",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"C3Id",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Montant du paiement",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Date de paiement",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Nom",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Remarques",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Valider le catalogue",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Erreur",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Le montant du paiement doit être supérieur à 0,00.",
      "RECORD_PAYMENT_PAID_DATE":"Date de paiement",
      "RECURRENCES_MAX_ERROR":"La valeur doit être inférieure ou égale à 11",
      "RECURRENCES_MIN_ERROR":"La valeur doit être supérieure ou égale à 0",
      "RECURRENCES_TOOLTIP":"Nombre de fois que le coupon de réduction peut être appliqué",
      "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"ACTUALISER_LA_LISTE_D'ENTITÉS_EXTERNES_EN_LOCAL",
      "REFRESH_TOKEN":"Actualiser le jeton",
      "REFRESH_TOOLTIP_TEST_PAYMENT":"Actualiser",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Merci d'avoir choisi <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Veuillez compléter les informations ci-dessous afin de traiter votre nouvelle demande.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Si vous possédez un compte Microsoft déjà existant qui n'est pas avec nous, veuillez <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">nous contacter</a> pour de l'aide.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Veuillez entrer un coupon valide ",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Courriel",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Veuillez fournir un identifiant email valide. Nous enverrons un email avec un nom d'utilisateur et un mot de passe une fois l'inscription terminée",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Prénom",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Nom de famille",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Numéro de téléphone",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Ville",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Nom de l'entreprise",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Ligne 5",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Ligne 6",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"\"Pour débuter, vous devez créer un compte en utilisant le nom de votre entreprise suivi de \".onmicrosoft.com\". Si votre organisation possède déjà son propre domaine personnalisé que vous souhaitez utiliser sans \".onmicrosoft.com\", vous pourrez le modifier ultérieurement sur Office 365\"",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Qu'est-ce que c'est",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"État",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Code postal",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Date de contrat",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"J'accepte la dernière",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Configurez votre plan",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Pour chaque offre du plan que vous souhaitez ajouter, prière de choisir une quantité minimum de 1",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Contact principal",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Profile de la société",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Compte bancaire",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Carte de crédit",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Supprimer le coupon",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Veuillez entrer un coupon valide ",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Code promotionnel appliqué : <b>{{CouponCode}}</b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Type de paiement",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Veuillez sélectionner le type de paiement",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Sommaire de plan ",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Frais récurrents (tous les mois)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"puis <span class = 'bold'> $ {{totalCount}} </ span> lors du premier renouvellement le {{nextBillingDate}}",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Total",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"REMARQUE:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Les frais d'achat sont calculés au prorata pour le reste des jours dans le cycle de facturation en cours.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Licences",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"Vous devez spécifier si le compte ACH est un compte professionnel ou non!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Utilisateur créé avec succès",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Impossible de vérifier la disponibilité du domaine",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"Statut d'enregistrement",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Votre enregistrement a été traité avec succès. Veuillez vous connecter sur le portail en utilisant les identifiants suivant: ",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Nom d'utilisateur",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Mot de passe",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Un email vous a été envoyé avec les détails suivants au cas où vous auriez manqué de les recopier d'ici",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Traiter ma commande",
      "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Veuillez fournir la raison du refus",
      "REJECT_ALL_PRODUCT_POPUP_BUTTON":"Rejeter",
      "REJECT_ALL_PRODUCT_POPUP_HEADER":"Rejeter tous les produits",
      "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Rejeter tous les produits",
      "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Veuillez expliquer la raison du refus de la demande",
      "REJECT_PRODUCT_COMMENT_HEADER":"Rejeter le produit",
      "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Veuillez indiquer les raisons pour lesquelles ce produit doit être rejeté",
      "REJECT_PRODUCT_POPUP_BUTTON":"Rejeter",
      "REJECTED_BY":" Rejeté par",
      "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Veuillez entrer une raison pour refuser ce produit",
      "RELEASE_PRODUCT_OWNERSHIP":"Libérer la propriété du produit",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Échec de la libération de la propriété du produit",
      "RELEASE_SEED_ERROR":"Vous ne pouvez réduire la quantité tout en libérant, veuillez réessayer ",
      "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"J'ai essayé de mettre à jour plus que la quantité disponible pour \"{{childName}}\"",
      "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"J'ai essayé de mettre à jour plus que la quantité disponible au parent \"{{parentName}}\" pour \"{{childName}}\"",
      "RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'>Une erreur s'est produite lors du traitement des données pour  <b> {{firstName}} </b>. L'erreur est : {{errorMessage}} </span><br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'>Enregistrements traités avec succès pour  <b> {{firstName}} </b></span><br/>",
      "RELOAD_RECONCILIATION_STATUS":"Recharger le statut de rapprochement",
      "RELOAD_TOOLTIP_REVENUE":"Recharger",
      "reLoadingCustomersProfileData":"Synchronisation des profils client et fournisseurs",
      "REMOVE_ALL_SELECTED_RGS":"Voulez-vous supprimer tous les groupes de ressources sélectionnés ?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Supprimer",
      "REMOVE_PARTNER_PRODUCT_ICON":"Supprimer",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Supprimer le produit",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"Êtes-vous sûr de vouloir supprimer le produit",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Voulez-vous vraiment révoquer l'accès pour cet utilisateur ?",
      "RENEWAL_ALL_DAYS":"Tout",
      "RENEWAL_DAYS_TEXT":"Jours",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"Télécharger",
      "REPORT_ACTIONS_SEND_EMAIL_TEXT":"Envoyer un e-mail",
      "REPORT_ACTIONS_TEXT":"Actions",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Tous les clients",
      "REPORT_FILE_INVALID_EMAILS_ERROR":"Veuillez entrer une adresse e-mail valide",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Ce champ est obligatoire",
      "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Sélectionnez la période",
      "REPORT_INTERVAL_TEXT_CUSTOME":"Personnalisé",
      "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"Les 12 derniers mois",
      "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"Les 3 derniers mois",
      "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"Les 6 derniers mois",
      "REPORT_INTERVAL_TEXT_TILL_DATE":"Jusqu'à la date",
      "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport Factures et cotisations",
      "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Retour au rapport d'utilisation",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Afficher l'historique des téléchargements",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Afficher l'historique des téléchargements",
      "REPORT_POPUP_ACTIONS_TEXT":"Actions",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "REPORT_POPUP_EMAIL_TEXT":"Courriel",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Sélectionner tout",
      "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur l'historique des abonnements",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports sur l'historique des abonnements.",
      "REPORT_SELECT_CUSTOMER_All":"Tous les clients",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Sélectionner un client",
      "REPORT_SELECT_RESELLER_ALL":"Tous les revendeurs",
      "REPORT_SEND_EMAIL_TEXT":"Courriel",
      "REPORT_TEXT_FROM":"De",
      "REPORT_TEXT_TO":"À",
      "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"Le coût pour le partenaire est obligatoire si le type de facturation est en pourcentage et facultatif pour l'unité de type de facturation.",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catalogue",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importer",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Modèle",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Télécharger",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Une erreur s'est produite lors du téléchargement du fichier",
      "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Télécharger le modèle",
      "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Télécharger l'historique des téléchargements",
      "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "REPORT_USAGE_INSTRUCTIONS_PART_1":"Téléchargez le catalogue pour obtenir les identifiants client et produit des offres Partenaires basées sur l'utilisation.",
      "REPORT_USAGE_INSTRUCTIONS_PART_2":"Téléchargez le modèle et remplissez le détail d'utilisation des produits du catalogue.",
      "REPORT_USAGE_INSTRUCTIONS_PART_3":"Assurez-vous que les données respectent les règles suivantes : <br />&nbsp;&nbsp <b>Units</b> : Number <br />&nbsp;&nbsp <b>UnitOfMeasure</b> : Text <br />&nbsp; &nbsp <b>CostToPartner</b> : Decimal <br />&nbsp;&nbsp <b>CurrencyCode</b> : Text (Code de devise standard ISO.) <br />&nbsp;&nbsp <b>UsageDate</b> : Date (AAAA-MM-JJ)",
      "REPORT_USAGE_INSTRUCTIONS_PART_4":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "REPORT_USAGE_INSTRUCTIONS_PART_5":"Cliquez sur <strong class=\"text-uppercase\">Valider l'entrée</strong> pour que le système valide l'entrée.",
      "REPORT_USAGE_INSTRUCTIONS_PART_6":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "REPORT_USAGE_INSTRUCTIONS_PART_7":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "REPORT_USAGE_INSTRUCTIONS_PART_8":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "REPORT_USAGE_NOTE":"Remarque :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Coût pour le partenaire",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Devise",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Identifiant du client",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"ID Produit ",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Unité de mesure",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Unités",
      "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Date d'utilisation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Valider l'entrée",
      "REPORT_USER_LICENSE_ASSIGNMENT":"Le rapport d’attribution de licence utilisateur sera envoyé à l’e-mail fourni.",
      "reportusage":"Utilisation du rapport",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Pour demander une relation de revendeur avec un client, copiez et collez le texte suivant, y compris l'URL, dans un e-mail. Modifiez le texte si nécessaire et envoyez l'e-mail à votre client.",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Incluez des privilèges d'administration déléguée pour Azure Active Directory et Office 365.",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"Texte de l'e-mail",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Demande de relation de revendeur",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Demande d'intégration massive initiée par",
      "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"La demande de chargement des promotions est en file d'attente",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Demande de chargement de factures sur Quickbooks initiée par",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"La demande de chargement de factures sur Xero est effectuée par",
      "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Demande de mise à jour du statut de l'offre NI",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Demande de chargement de factures sur QuickBooks acceptée",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Demande de chargement de factures sur Xero acceptée",
      "REQUESTED_PRODUCT_STATUS":"Statut demandé",
      "requestForResellerRelationship":"Demande de relation de revendeur",
      "REQUIRED_FIELD_TEXT":"Ce champ est obligatoire",
      "REQUIRED_PATTERN_PHONE":"Seuls les chiffres et les + sont autorisés",
      "REQUIRED_PATTERN_TEXT":"Les caractères spéciaux tels que `~!@#{}|/<>' ne sont pas autorisés",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Une erreur s’est produite lors de l'exportation des postes de facture de revendeur",
      "RESELLER":"(Revendeur)",
      "Reseller":"Revendeur",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Ajustement",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Est imposable",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Supprimer un plan",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Consulter les offres de plan",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Enregistrer le plan",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Réussite ",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Prix mis à jour avec succès",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Quantité",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Utilisation",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Macro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":"Prix",
      "RESELLER_AZURE_MACRO_VALUE":"Valeur de la macro",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Revendeurs",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Récapitulatif des revenus et des coûts du revendeur",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Retour à la liste",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Lier un nouveau fournisseur",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Ajouter revendeur",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Nouveau",
      "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Existant (Multiple)",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Ajouter revendeur",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Coordonnées",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Type de paiement",
      "RESELLER_CAPTION_TEXT_PERSONAL_DETAILS":"Détails personnels",
      "RESELLER_CAPTION_TEXT_RESELLER":"Revendeurs",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Profil de facturation du revendeur",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Détails du revendeur",
      "RESELLER_CAPTION_TEXT_RESELLER_NAME_AND_MPN_ID":"Nom du revendeur et identifiant MPN",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Profil de revendeur",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Activez cette option pour appliquer des frais de traitement aux paiements par carte de crédit.",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"Inclure les frais de traitement de carte de crédit ?",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Frais en pourcentage pour le traitement des cartes de crédit.",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Frais de traitement de carte de crédit %",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Cocher cet indicateur empêche les clients de réduire la quantité d'abonnement",
      "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Autoriser le revendeur à initier la transaction pour sa ou ses factures",
      "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Sélectionnez si vous souhaitez appliquer la promotion au revendeur ou non",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Permettre aux clients de gérer les abonnements Azure",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permettre aux clients de définir des groupes sur les abonnements Azure",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du revendeur azure. Par exemple: Entrer 1.15 si la majoration doit être 15 %",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Pour référence interne. Cet identifiant peut être utilisé pour associer le client à un enregistrement dans votre système de comptabilité interne",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Jour du mois où les factures sont approuvées automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Jour du mois où les paiements sont traités automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Jour du mois où les paiements sont traités automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Date d'échéance des factures",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"Nombre de jours dus pour le paiement de la facture",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Jour du mois où les factures sont finalisées automatiquement",
      "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'adresse nouvellement ajoutée comme adresse par défaut du revendeur.",
      "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'e-mail nouvellement ajouté comme e-mail par défaut pour le revendeur.",
      "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"L'activation de cette option désignera automatiquement le numéro de téléphone nouvellement ajouté comme numéro de téléphone par défaut du revendeur.",
      "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les lignes de facture uniques du revendeur.",
      "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements logiciels perpétuels des revendeurs",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Définir le numéro de bon de commande pour le revendeur",
      "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Activer la fonctionnalité de transmission des factures à un service de comptabilité externe",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Cochez cet indicateur si vous souhaitez que le système traite les paiements (CC/ACH, le cas échéant)",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PENCENTAGE":"Majoration (%) à appliquer sur l'utilisation des instances réservées du revendeur.",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur la réservation azur du revendeur.",
      "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Le revendeur recevra le courriel avec les détails de la facture, une fois la facture approuvée et si les notifications par courriel sont activées",
      "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Le revendeur recevra une notification par courriel concernant les factures ayant des montants nuls",
      "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements logiciels revendeurs.",
      "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Nous ne pouvons pas traiter cette demande car les transactions sont désactivées.",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Définir le code fiscal pour les revendeurs",
      "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements tiers revendeurs.",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Permettre au client de réduire le nombre de licences",
      "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Autoriser le revendeur à initier la transaction",
      "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Appliquer la promotion au revendeur",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Voulez-vous activer Azure ?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Voulez-vous activer Azure pour Entreprise ?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Majoration pour l'utilisation Azure",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Identifiant de référence de facturation",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Jour où les revendeurs peuvent consulter les factures",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Jour du paiement automatique",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Jour du paiement automatique",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Date d'échéance des factures",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Jours d'échéance des factures",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Jour où les factures sont finalisées",
      "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Attribuer automatiquement une nouvelle adresse par défaut",
      "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Attribuer automatiquement un nouvel e-mail par défaut",
      "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Attribuer automatiquement un nouveau numéro de téléphone par défaut",
      "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Balisage pour les éléments de ligne de facture unique Microsoft",
      "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Balisage pour les logiciels perpétuels",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"Numéro de commande",
      "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Enregistrer les factures auprès d'un service de comptabilité externe",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Désirez-vous automatiser le paiement de factures ?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Envoyer des courriels de facture automatiquement",
      "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Envoyer des factures de montant zéro",
      "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour l'abonnement au logiciel",
      "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Arrêter toutes les transactions",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Code fiscal",
      "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour tiers",
      "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorer le revendeur du service externe",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Les numéros de facture commenceront par ce texte",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texte du numéro de facture",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permet à l'utilisateur de visualiser les cotisations impayées jusqu'à la facture en cours",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Afficher les cotisations impayées sur la facture",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Configuration du revendeur a été rétablie avec succès",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Configuration du revendeur sauvegardée avec succès",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Vous n'avez pas la possibilité d'effectuer des modifications",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Configuration du revendeur mise à jour avec succès",
      "RESELLER_CONFIGURATION_OF":"Configuration revendeur de",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Aperçu rapide des recettes de votre entreprise",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilité",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Résumé de vos comptes débiteurs",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Compte à recevoir",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Les paiements doivent être collectés",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"En souffrance",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Vos clients actifs",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clients",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Alerte pour une facture en attente",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Facture",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Un vendeur avec le même identifiant mpn existe déjà dans le système. Veuillez vérifier l'identifiant mpn donné.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "RESELLER_IMPERSONATE":"Personnifier",
      "RESELLER_IMPERSONATE_USER_ROLE":"Personnifier",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"représenter son propre rôle ",
      "RESELLER_INVOICE":"Facture",
      "RESELLER_INVOICE_TEXT_LINE":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Statut de facture mis à jour avec succès",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Paiement enregistré avec succès",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Postes de facture",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Ligne d'adresse 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Ligne d'adresse 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "RESELLER_LABEL_TEXT_CITY":"Ville",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "RESELLER_LABEL_TEXT_COUNTRY":"Pays",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"Courriel",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"Prénom",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "RESELLER_LABEL_TEXT_MONTH":"Mois",
      "RESELLER_LABEL_TEXT_MPN_ID":"Identifiant MPN",
      "RESELLER_LABEL_TEXT_NAME":"Nom",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "RESELLER_LABEL_TEXT_STATE":"État",
      "RESELLER_LABEL_TEXT_YEAR":"Année",
      "RESELLER_LABEL_TEXT_ZIP":"Code postal",
      "RESELLER_LIST_PAYMENT_METHOD":"Méthode de paiement ",
      "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marge pour l'abonnement au logiciel",
      "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration pour l'abonnement tiers",
      "RESELLER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du revendeur",
      "RESELLER_NAME_UPDATE_SUCCESS":"Succès de la mise à jour du nom du revendeur",
      "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Le revendeur n'est affecté à aucun gestionnaire de compte",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"Aucun revendeur trouvé dans CSP. S'il vous plaît vérifier l'identité mpn fournie.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Erreurs détectées dans les données chargées. Télécharger les erreurs, corrigez-les et essayez de charger à nouveau.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Veuillez mentionner si les détails ACH sont liés à un compte professionnel",
      "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"ID MPN du revendeur",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Courriel de facturation",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"Pays",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"Identifiant",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"Identifiant MPN",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Nom",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Réussite ",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Détails de paiement enregistrés",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Profil de paiement supprimé",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Profil de paiement défini par défaut",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Voulez-vous vraiment supprimer cette option de paiement ?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Définir la macro et l'enregistrer avec toutes les offres",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Ajouter les offres manquantes",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Enregistrer le plan avec toutes les offres",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Cycles de facturation sélectionnés",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Catégories sélectionnées",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Catégories de fournisseurs sélectionnées",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Fournisseurs sélectionnés",
      "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Marchés pris en charge",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Catégories",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Utilisation de la macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Valeur de la macro d'utilisation",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valeur de la macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Catégories de fournisseurs",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Fournisseurs",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Types de service",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"La demande de clonage du plan revendeur est acceptée et en cours de traitement",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"La demande de création d'un nouveau plan revendeur avec toutes les offres est acceptée et en cours de traitement",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Fixation des prix",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Sélectionner",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Appliquer une macro pour définir les nouveaux prix",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Copier les prix du Plan Revendeur Source",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Vos modifications ont été soumises avec succès",
      "RESELLER_PLAN_DISABLE_BUTTON":"Désactiver le plan",
      "RESELLER_PLAN_ENABLE_BUTTON":"Activer le plan",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"L'offre est interrompue avec succès",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Discontinuer l'offre",
      "RESELLER_PLAN_SELECTED_OFFERS":"Offres sélectionnées",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Plans",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Annuler",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Plan de clonage",
      "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Création de plans",
      "RESELLER_PLANS_CAPTION_1":"Ajouter un nouveau plan",
      "RESELLER_PLANS_CAPTION_2":"Détails pour plan -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Plans",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Afficher",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Prix et offres",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Télécharger la liste des prix et des offres",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Prix et offres",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Type de contrat de licence",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Valide de",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Une erreur s’est produite lors de l'exportation de la liste des prix et des offres du revendeur",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Profil d'entreprise",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Profil client",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Ajouter une adresse",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"Ajouter un courriel",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Ajouter un numéro de téléphone",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Adresses",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Détails de base",
      "RESELLER_PROFILE_PARTNER_CANCEL":"Annuler",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Nom de l'entreprise",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"Adresses courriel",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Prénom",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Nom de famille",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Détails de base mis à jour avec succès",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Aucun enregistrement trouvé !",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Numéros de téléphone",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"Soumettre",
      "RESELLER_PROFILE_PAYMENT_ADD":"Ajouter",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Compte bancaire",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Carte de crédit",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Aucun profil trouvé. Ajoutez un nouveau profil de paiement en cliquant sur le bouton AJOUTER",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Profils de paiement",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Date de création du compte :",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Adresse de facturation",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"Date d'intégration C3",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Profil de revendeur",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Adresse d'expédition",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Comptes d'utilisateur",
      "RESELLER_PROFIT_BILLED_AMOUNT":"Montant facturé au revendeur",
      "RESELLER_PROFIT_COST_ON_PARTNER":"Coût pour le distributeur",
      "RESELLER_PROFIT_COST_ON_RESELLER":"Coût pour le revendeur",
      "RESELLER_PROFIT_CUSTOMER_NAME":"Montant client",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Nom du revendeur",
      "RESELLER_PROFIT_PRODUCT_NAME":"Nom de produit",
      "RESELLER_PROFIT_PROFIT_AMOUNT":"Bénéfice du distributeur",
      "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Montant facturé par le revendeur",
      "RESELLER_PROFIT_RESELLER_NAME":"Nom du revendeur",
      "RESELLER_PROFIT_SUMMARY_REPORT":"Télécharger le rapport",
      "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"Aucune donnée disponible au téléchargement",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Tout",
      "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Voir par revendeur",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Bénéfice du revendeur",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Bénéfice du distributeur %",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"% de bénéfice du revendeur",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"Non",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"Oui",
      "RESELLER_REGISTRATION_SUCCESS":"L'inscription du revendeur a été complétée avec succès.",
      "RESELLER_RELATIONSHIP_LBL":"Veuillez partager le contenu suivant avec le client pour établir une relation de revendeur",
      "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Sélectionner un revendeur",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Afficher par",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Veuillez entrer le pourcentage de taxe valide",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Veuillez remplir les champs du nom de taxe",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Ajouter une nouvelle notification par courriel d'événement",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"balise de partenaire supprimée avec succès",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"balise partenaire mise à jour avec succès",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Il n'y a pas de fournisseurs configurés, veuillez contacter le soutien technique",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Il n'y a pas de clés disponibles pour ce fournisseur",
      "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Revendeur : Usurpation d'identité rapide",
      "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Aucun marché n'a été trouvé pour le code devise choisi",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Configuration revendeur",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Paramètres du revendeur",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Assigner des offres",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Modifier",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Personnifier",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Gérer les liens fournisseurs",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Créé le",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"Nom",
      "RESELLER_TABLE_SIGNUP_DATE":"Date d'inscription",
      "resellers":"Revendeurs",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Facture",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Rapprochement",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test de facturation de revendeur",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Revendeur ajouté avec succès.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Paiements revendeur",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Gérer les plans",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"Courriels",
      "RESEND_INVOICE":"Renvoyer la facture",
      "RESEND_INVOICE_HEADER_TEXT":"Veuillez entrer les identifiants de courriel afin de renvoyer la facture",
      "RESEND_INVOICE_NOTE":"<b>Remarque : </b> si vous souhaitez renvoyer la facture à plusieurs identifiants de messagerie, veuillez les indiquer en les séparant par une virgule. Ex : abc@mail.com,new@mail.com",
      "RESEND_INVOICE_USERS_LIST":"Liste d'utilisateurs pour renvoi de facture",
      "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Prix en détail (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Coût pour le partenaire (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Prix de vente (%)",
      "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Saisissez un pourcentage d'IR valide",
      "RESET_VALUE":"Réinitialiser la valeur",
      "Resolved":"Résolu",
      "RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Le paiement a été initié avec succès. Le traitement prendra quelques temps. Veuillez vérifier le statut après un certain moment.",
      "REVENDEUR":"(Revendeur)",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Montant facturé",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Envoyer toutes les factures par courriel",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Catégorie",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Coût pour le partenaire",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Client",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Facture du distributeur",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Fournisseur",
      "REVENUE_AND_COST_SUMMARY_RESELLER":"Revendeur",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"Type de vente",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Redevances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Factures et redevances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Paiements",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Défaillances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Les frais de paiement",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Afficher par",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Génération de factures terminée",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Une erreur s'est produite lors de la génération des factures clients",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Factures clients générées avec succès",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Détails de paiement du fournisseur",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Chargement des détails de paiement du fournisseur...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Régénérer la facture",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Afficher les factures",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Afficher l'historique des abonnements",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Montant",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Période de facturation",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Client",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Coût sur le Partenaire",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Montant du bénéfice",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Coût pour le client",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Coût pour le partenaire",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Bénéfice",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Recevoir toutes les factures par courriel",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Détails de paiement du fournisseur",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Catégorie",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Client",
      "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Durée",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Dernière période de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 périodes de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 périodes de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 périodes de facturation",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Voulez-vous vraiment révoquer l'attribution",
      "RFRESH_DATA_FROM_PSA":"Actualiser les données de PSA",
      "RIBBON_TITLE_DEPARTMENT":"Département",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Veuillez entrer la quantité pour au moins une offre de plan de niveau supérieur.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Remplissez les données de l'utilisateur",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Portée d'affectation des rôles",
      "ROLE_NAME_C3_SUPPORT":"Soutien C3",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Responsable de compte client",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"L'utilisateur pourra accéder aux cartes et aux menus concernant les produits",
      "ROLE_NAME_CUSTOMER_ADMIN":"Administrateur client",
      "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"L'utilisateur peut afficher et effectuer toutes les actions disponibles dans le portail client",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Administrateur client (Pour les Ventes)",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Administrateur client (Pour les Ventes)",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Administrateur Client Lite",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"L'utilisateur dispose de toutes les autorisations d'administrateur client, mais ne pourra pas acheter de nouveaux abonnements",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Administrateur client Lite Plus",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"L'utilisateur dispose de toutes les autorisations de Customer Admin Lite mais ne pourra pas gérer les produits d'une durée d'un an ou plus",
      "ROLE_NAME_CUSTOMER_MAINTENANCE":"Entretien client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Entretien client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Un rôle pour maintenir l'application par client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Un rôle pour maintenir l'application par client",
      "ROLE_NAME_CUSTOMER_READER":"Lecteur client",
      "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"L'utilisateur ne peut lire que les données disponibles dans le portail client",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Super Administrateur Client",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Super Administrateur Client",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Admin de département",
      "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Qui peut gérer le département et ils ne peuvent gérer que les produits relevant des départements spécifiques.",
      "ROLE_NAME_DEPARTMENT_READER":"Lecteur de département",
      "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Qui peut uniquement lire les données disponibles dans les Départements Spécifiques.",
      "ROLE_NAME_DIRECT_ADMIN":"Administrateur Direct",
      "ROLE_NAME_FINANCE_ADMIN":"Administrateur des finances",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Peut gérer les pages liées aux finances.",
      "ROLE_NAME_GROUP_ADMIN":"Administrateur du groupe",
      "ROLE_NAME_HELP_DESK":"Centre d'assistance",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Centre d'assistance",
      "ROLE_NAME_INDIRECT_ADMIN":"Administrateur indirect",
      "ROLE_NAME_PARTNER_MAINTENANCE":"Entretien des Partenaires",
      "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Un rôle pour maintenir l'application par Partenaire",
      "ROLE_NAME_SITE_ADMIN":"Administrateur de site",
      "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Qui peut gérer le Site et ne peut gérer que les produits des Sites Spécifiques.",
      "ROLE_NAME_SITE_READER":"Lecteur du site",
      "ROLE_NAME_SITE_READER_DESCRIPTION":"Qui peut uniquement lire les données disponibles sous le Site.",
      "ROLE_NAME_SYSTEM_ADMIN":"Admin du système",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Peut accéder/modifier toutes les pages du portail.",
      "ROLE_NAME_SYSTEM_READER":"Lecteur du système",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Peut visiter toutes les pages, mais ne peut pas apporter de modifications.",
      "ROLE_NAME_USER":"Utilisateur",
      "ROLE_NAME_USER_ADMIN":"Administrateur utilisateur",
      "ROLE_NOTE_C3_SUPPORT":"Le soutien technique de C3 peut résoudre les cas de discordance de quantité et c'est un super ensemble d'autorisations",
      "ROLE_NOTE_CUSTOMER_ADMIN":"L'administrateur client peut consulter toutes les données et effectuer toutes les actions relatives au domaine",
      "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"L'administrateur client (Pour les Ventes) peut voir et effectuer des actions sur les données, mais pas sur les abonnements des Partenaires",
      "ROLE_NOTE_CUSTOMER_READER":"Lecteurs clients ayant un accès en lecture seule à toutes les données relatives au client.",
      "ROLE_NOTE_DIRECT_ADMIN":"Administrateur Direct, serait capable de faire tout ce qu'un administrateur système peut faire, mais uniquement pour les clients directs, donc pas d'accès aux parties Sell Indirect",
      "ROLE_NOTE_FINANCE_ADMIN":"Les administrateurs des finances peuvent accéder au résumé des revenus et des coûts des clients, définir le % d'impôt, consulter le rapport de rapprochement",
      "ROLE_NOTE_GROUP_ADMIN":"Les administrateurs de groupe peuvent gérer tous les aspects liés aux abonnements Azure sous leur groupe.",
      "ROLE_NOTE_HELP_DESK":"Les utilisateurs du centre d'assistance peuvent afficher toutes les informations du client, intégrer de nouveaux clients et fournir un soutien. Les utilisateurs du centre d'assistance peuvent personnifier et accéder aux données des clients",
      "ROLE_NOTE_INDIRECT_ADMIN":"L'administrateur indirect pourrait faire tout ce qu'un administrateur système peut faire, mais uniquement pour les revendeurs (et leurs clients), donc aucun accès aux parties Sell Direct.",
      "ROLE_NOTE_SYSTEM_ADMIN":"L'administrateur du système peut consulter toutes les données et effectuer toutes les actions relatives au partenaire et aux clients",
      "ROLE_NOTE_SYSTEM_READER":"Lecteurs du système ayant un accès en lecture seule à toutes les données relatives au partenaire. Les lecteurs ne peuvent pas personnifier",
      "ROLE_NOTE_USER_ADMIN":"L'administrateur de l'utilisateur peut voir et effectuer certaines actions sur les données, mais pas sur les abonnements",
      "RowNum":"Numéro de ligne",
      "SALE":"Vente",
      "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Modifier le prix de vente",
      "SALE_PRICE_EMPTY_ERROR":"Veuillez saisir un prix valide",
      "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Prix de vente supérieur au prix ERP",
      "SALE_PRICE_LESS_THAN_LIST_PRICE":"Prix de vente inférieur au prix courant",
      "SALE_TYPE_DESC_PRODUCT":"Produit",
      "SALE_TYPE_DESC_SERVICE":"Service",
      "SalePrice":"Prix de vente",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Tentative d'enregistrement du code d'authentification reçu de Quickbooks",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Tentative d'enregistrement du code d'authentification reçu de Xero",
      "SAVE_CART_EXCEPTION":"Une erreur s'est produite lors de l'ajout du produit au panier",
      "SAVE_COUPON_FAILURE":"Échec de l'enregistrement du coupon",
      "SAVE_COUPON_SUCCESS":"Coupon enregistré avec succès",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement de la conversion de devises",
      "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Les colonnes sélectionnées ont été enregistrées avec succès comme favoris.",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Une erreur s'est produite lors de la sauvegarde ou de la mise à jour de la liste des destinataires de notification par courriel",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement des informations sur l'impôt",
      "SAVE_PLAN_PRODUCT_SLAB":"Blocs d'offres de contrat enregistrés",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Échec de l'enregistrement des blocs d'offre contractuels",
      "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan créé avec succès",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Les détails de paiement du fournisseur ont été enregistrés avec succès.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Enregistrer le profil de paiement du revendeur",
      "SAVE_RESELLER_PLAN":"Enregistrer le plan du revendeur",
      "SAVE_RESELLER_PLAN_FAILURE":"Échec de l'enregistrement du plan du revendeur",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Définition du taux de change du produit du plan de revendeur",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Impossible de définir le taux de change du produit du plan de revendeur",
      "SAVE_SUB_TAX":"Enregistrez-la sous taxe",
      "SAVE_SUB_TAX_FAILURE":"Échec de l'enregistrement de la sous taxe",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Nouveau Ticket créé avec succès",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Ticket mis à jour avec succès",
      "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Planifier une commande",
      "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Veuillez sélectionner une date",
      "SCHEDULE_ORDER_CHECKBOX_TEXT":"J'accepte de planifier une commande pour une date ultérieure",
      "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Sélectionnez une date",
      "SCHEDULE_ORDER_EVENT":"Planifier la commande",
      "SCHEDULE_ORDER_FAILED_EVENT":"Échec de la commande planifiée",
      "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"créer un rendez-vous",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Type de destinataire",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Valeur du destinataire",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Recettes",
      "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"ID d'enregistrement",
      "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Nom du rapport",
      "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Résultat",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Statut",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Supprimer",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Modifier",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Scheduled_Invoice_line_Item_Report",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"ScheduledInvoicelineItemReportDelivery",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Scheduled_Invoice_Line_Items_With_Commission_Report",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"ScheduledInvoiceLineItemsWithCommissionReportDelivery",
      "SCHEDULED_INVOICE_SUMMARY_REPORT":"Scheduled_Invoice_Summary_Report",
      "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Livraison du rapport de résumé de facture planifiée",
      "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Supprimer",
      "SCHEDULED_PROFITABILITY_REPORT":"Rapport_de_profitabilité_programmé",
      "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Livraison du rapport de rentabilité planifiée",
      "SCHEDULED_RENEWAL_CHANGES":"Modifications de renouvellement programmées",
      "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"Souhaitez-vous appliquer la promotion à ce produit ?",
      "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"en attente d'approbation",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Actif",
      "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Annulé",
      "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Complété",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Échoué",
      "SCHEDULED_RENEWAL_STATUS_ONHOLD":"En attente",
      "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Retour aux détails de base",
      "SCHEDULED_RENEWALS_HEADER_TEXT":"Renouvellements programmés",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Actions",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Aligner la date de fin",
      "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Motif d'annulation",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Cycle de facturation",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Nouveau SKU",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Quantité",
      "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Date prévue",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Statut",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Terme",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"Conditions générales de vente",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Accepté",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Poste de facture",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Postes de facture avec commission",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Résumé de facture",
      "SCHEDULED_REPORT_PROFITABILTY":"Rapport de profitabilité",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Type de destinataire",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Destinataires",
      "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Rapport",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Postes de taxe",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Résumé fiscal",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Scheduled_Tax_Line_Items_Report",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"ScheduledTaxLineItemsReportDelivery",
      "SCHEDULED_TAX_SUMMARY_REPORT":"Scheduled_Tax_Summary_Report",
      "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Livraison planifiée du rapport TaxSummary",
      "SCHEDULNG_LODING_MESSAGE_TEXT":"Le processus de chargement de l’offre éligible à partir de l’Espace partenaires prend généralement environ 30 secondes. Veuillez ne pas actualiser la page.",
      "SCORE":"Score",
      "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Rechercher par événement",
      "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Actions de recherche",
      "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Recherche par nom de client",
      "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Recherche par nom de domaine",
      "SEAT_LIMIT_EXCEED":"Limite de sièges dépassée",
      "SELECT_A_SOUNCE_CURRENCY":"Sélectionnez la devise souNCE",
      "SELECT_A_SOURCE_CURRENCY":"Sélectionnez la devise source",
      "SELECT_A_SUBSCRIPTION_TEXT":"Sélectionnez un abonnement",
      "SELECT_AGENT":"Agent",
      "SELECT_ALL_CUSTOMER":"Tous les clients",
      "SELECT_ALL_RESELLER":"Tous les revendeurs",
      "SELECT_ALL_RG":"Voulez-vous sélectionner tous les groupes de ressources ?",
      "SELECT_COUPON":"Sélectionner un coupon",
      "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Sélectionnez le code de la devise",
      "SELECT_CUSTOMER":"Sélectionner un client",
      "SELECT_DATE_RANGE":"Sélectionnez la plage de dates",
      "SELECT_DATE_VALUE":"Sélectionner une date",
      "SELECT_DEFAULT_OPTION_SELECT":"Sélectionner",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Sélectionner un client",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Sélectionner un événement",
      "SELECT_DEPARTMENT":"Sélectionnez le département",
      "SELECT_DEPARTMENTS":"Sélectionnez le département",
      "SELECT_EDU_SEGMENT":"Sélectionnez le segment d'éducation",
      "SELECT_EVENT":"Sélectionner un événement",
      "SELECT_MESSAGE_TYPE":"Sélectionnez le type de message",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Veuillez sélectionner au moins une facture à payer",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Options rapides pour définir votre prix de vente",
      "SELECT_PARTNER_INVOICE":"Factures des Partenaires",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Sélectionner un événement",
      "SELECT_PLAN_TOOLTIP_TEXT":"Sélectionnez le plan",
      "SELECT_PORTAL_PAGE":"Sélectionnez la page du portail",
      "SELECT_PRIORITY":"Sélectionner la priorité",
      "SELECT_PRODUCT_AREA":"Sélectionner la zone de produit",
      "SELECT_RESELLER_AZURE_MACRO":"Sélectionnez une macro",
      "SELECT_RESELLER_AZURE_MACRO_TYPE":"Sélectionner le type de macro",
      "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Sélectionnez le type de prix de vente",
      "SELECT_SITE":"Sélectionnez un site",
      "SELECT_SITES":"Sélectionnez un site",
      "SELECT_STATUS":"Sélectionner le statut",
      "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Sélectionnez la devise cible",
      "SELECT_TYPE":"Sélectionner le type",
      "selectAddressType":"Sélectionnez le type d'adresse",
      "selectCountry":"sélectionner un pays",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Clients sélectionnés pour le chargement de factures sur Quickbooks",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Clients sélectionnés pour le chargement de factures sur Xero",
      "SELECTED_FILTER":"Sélectionnez le filtre",
      "SELECTED_FILTER_ALL":"Sélectionner tout",
      "selectEmailType":"Sélectionnez le type d'e-mail",
      "selectPhoneType":"Sélectionnez le type de téléphone",
      "selectState":"Sélectionnez l'état",
      "SELL_DIRECT_ICON_TITLE":"Icônes de Stockholm / Shopping / Box2",
      "sell-direct":"Vente directe",
      "sell-indirect":"Vente indirect",
      "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Saisissez l'adresse email à laquelle vous souhaitez envoyer un email, saisissez avec une virgule(,) ou un point-virgule(;) et cliquez sur Soumettre pour recevoir un fichier contenant une liste du catalogue de produits",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Renvoyer la facture",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Envoyer",
      "SEND_MAIL_BUTTON":"Envoyer un courriel",
      "SEPTEMBER":"Septembre",
      "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Domaine client du fournisseur",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
      "SERVICE_PROVIDER_TENANT":"Domaines du fournisseur",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Domaine par défaut",
      "SERVICE_PROVIDER_TENANT_HEADER":"Domaine du fournisseur de ",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Actions",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Identifiant de domaine",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Nom de domaine",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"Voulez - vous vraiment marquer <b> {{customerName}}</b> comme domaine par défaut ?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{customerName}} mis à jour en tant que domaine par défaut",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Votre session a expiré. Veuillez vous reconnecter pour continuer. Malheureusement, toutes les modifications non enregistrées seront perdues.",
      "SESSION_TIMEOUT_WARNING":"Avertissement de dépassement de délai de session",
      "SESSION_WARNING_DURATION":"Votre session expirera dans {{secondsLeft}} secondes.",
      "SET_NEW_MACRO":"Définir une nouvelle macro",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Le statut de lot de l'intégration massive des clients est défini sur Terminé",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Tentative de définition du statut des journaux de publications de service externe sur ErrorIgnored",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Impossible de définir le statut des journaux de publications de service externe sur ErrorIgnored",
      "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"Modèle d'e-mail",
      "settings":"Paramètres",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Notifications par email",
      "SETTINGS_HEADING_TEXT_BILLING":"Facturation",
      "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"Paramètres de ConnectWise Manage",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Conversion de devise",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Les choix par défaut du client ",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Supprimer un plan",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"Notifications par email",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Configurations client  (Global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Partner par défaut",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Gestion des utilisateurs",
      "SETTINGS_LOGO_SELECT_A_LOGO":"Sélectionnez un logo",
      "SETTINGS_PASSWORD_HELP_TEXT":"Laissez le champ vide si vous ne voulez pas mettre à jours le mot de passe.",
      "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Autotask",
      "SETTINGS_TAB_HEADING_CONNECTWISE":"ConnectWise Manage",
      "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Vues personnalisées",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Général",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logos",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Centre Partner ",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Fournisseurs",
      "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Inscription publique",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Vous pouvez spécifier n'importe quel nom de taxe, par exemple : TPS.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Vous pouvez spécifier le pourcentage de taxe ici. Par exemple : 15 pour 15 %",
      "SHIP_TO":"Envoyer à",
      "SHOP_ADDED_TO_CART_TEXT":" ajouté au panier",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Ajouter au panier",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Lire plus...",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Lire plus...",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Afficher le paramètre de configuration client dans le portail client",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Permettre au client de gérer les configurations",
      "SHOW_NON_ACTIVE_OFFERS":"Afficher les offres désactivées",
      "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
      "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Afficher les offres qui ne sont pas disponibles pour le client",
      "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Afficher les offres qui n'ont pas de macro",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Afficher le paramètre de configuration du revendeur dans le portail des revendeurs",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Permettre au revendeur de gérer les configurations",
      "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Icônes de Stockholm / Communication / Ajouter un utilisateur",
      "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Icônes de Stockholm / Communication / Groupe",
      "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm-/-Général-/-Notification2",
      "SIDEBAR_ANALYZE_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Calculatrice",
      "SIDEBAR_C3_SUPPORT_Azure_MARGIN":"Marge d'azur",
      "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Marge d'azur",
      "SIDEBAR_CONTENT_ALL_COMMENTS":"Permet au Partenaire d'examiner les commentaires fournis pour diverses entités (article de panier, factures, commandes) par les clients et d'y répondre",
      "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Conduit à la liste des gestionnaires de comptes et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Gérer les avis Web au niveau de l'entité",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Créez et gérez des notifications personnalisées pour divers événements de gestion de produits/licences et d'intégration des clients.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Partagez des informations en temps réel avec vos partenaires et clients via des diffusions interactives sur leurs tableaux de bord respectifs.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Créez et gérez des avis Web à différents niveaux d'entité pour vous tenir au courant de l'état du système, des factures et d'autres notifications importantes",
      "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor est une fonctionnalité basée sur des recommandations qui aide les partenaires à adopter les meilleures recommandations d'optimisation des coûts.",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Rapport sur les données de facturation Azure",
      "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Il vous permet de mettre à jour le balisage du plan Azure des mois précédents et de charger les données de facturation avec de nouvelles valeurs de balisage.",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Abonnements basés sur l'utilisation",
      "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Aperçu rapide du rapport de synthèse des coûts",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Aperçu rapide de l'analytique client",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Afficher la liste des licences consommées par le domaine",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Décrire l'utilisation d'AZURE",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Gérez votre profil d'entreprise, vos adresses, vos profils de paiement et plus encore...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Revoir vos modifications de licence à travers des dates personnalisées.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Aperçu rapide du résumé de la consommation de licences",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Aperçu rapide de l'attribution des licences et les licences disponibles.",
      "SIDEBAR_CONTENT_MENU_MANAGE_Azure_ENTITLEMENTS":"Gérer et créer plusieurs droits Azure (Abonnements) dans le cadre du plan Azure en bloc",
      "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer et créer plusieurs droits Azure (abonnements) sous le plan Azure en masse",
      "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Aperçu rapide de l'analytique de l'intégration des clients",
      "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Rapports détaillés de la commission de vente au niveau de l'agent et du montant gagné",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Définir des rabais",
      "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Guide pratique du produit pour les partenaires et les clients finaux",
      "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":"Permet au Partenaire d'afficher et de télécharger les détails d'utilisation des produits enregistrés en dehors de la solution C3.",
      "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Permet au Partenaire de télécharger les codes/attributs de produit souhaités dans les rapports sur les éléments de ligne de facturation.",
      "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Permet au Partenaire d'approuver/rejeter les demandes d'achat de produits des clients.",
      "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Téléchargez les valeurs du nom de l'agent, les produits associés aux ventes, le pouNCEntage de commission et divers autres attributs de commission",
      "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Vous aide à créer des colonnes personnalisées à ajouter à votre rapport sur les éléments de ligne de facture.",
      "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Permet au Partenaire de télécharger les détails d'utilisation enregistrés en dehors de C3 pour les produits du catalogue pour la génération de factures.",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Fournis plusieurs rapports sur vos données qui sont dans C3.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Vous aide à définir des plans avec des offres que vous souhaitez commercialiser",
      "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Cette fonctionnalité aide les partenaires à planifier divers rapports à différentes fréquences.",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Rapport basé sur Power BI sur l'utilisation Azure",
      "SIDEBAR_CONTENT_MENU_USERS":"Gérer l'accès aux utilisateurs",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analyser votre rentabilité",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Afficher les estimations Azure",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Regroupez un ou plusieurs abonnements et définissez des budgets et des seuils",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Vous aide à définir vos services et offres exclusifs à valeur ajoutée",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Accédez à vos factures depuis le partenaire",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Vous permet d'accéder aux fonctionnalités pertinentes pour les domaines Microsoft CSP liés à vos clients",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Répertorier et gérer les utilisateurs Azure AD",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Suivre vos commandes",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Afficher les produits achetés",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Magasin et acheter des offres du catalogue",
      "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Fonctionnalité permettant aux fournisseurs indirects (distributeurs) de créer et de vendre leurs solutions IP via des revendeurs indirects aux clients finaux.",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Gérer les factures et les paiements",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Permet de regrouper plus d'une offre et de les mettre à disposition de vos clients pour l'achat",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Conduit à la liste des clients actifs et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Vous amène à la page du tableau de bord",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Vérifiez l'état des paiements déclenchés par le système.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Vous aide à définir des plans avec des offres que vous souhaitez commercialiser",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Vous donne un aperçu de votre rentabilité et des détails de la facture aussi",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Vous donne un aperçu de votre rentabilité et des détails de la facture aussi",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Conduit à la liste des revendeurs actifs et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENUS_SELL":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Accédez à des fonctionnalités exclusives au rôle de support C3.",
      "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Icônes-de-Stockholm-/-Design-/-Union",
      "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Stockholm-icônes-/-Communication-/-Groupe",
      "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Stockholm-icônes-/-Communication-/-Groupe",
      "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Icônes-de-Stockholm-/-Design-/-Union",
      "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm / Shopping / Chart-line1",
      "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Stockholm-icons-/-Files-/-Group-folders",
      "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Ticket",
      "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Stockholm-icônes-/-Shopping-/-Cadeau",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Icônes-de-Stockholm-/-Accueil-/-Clé",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Icônes de Stockholm / Shopping / Argent",
      "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Icônes-de-Stockholm-/-Navigation-/-Double-vérification",
      "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Icônes de Stockholm / Shopping / Argent",
      "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm-/-Design-/-Composant",
      "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Icônes de Stockholm / Communication / Groupe",
      "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Icônes-de Stockholm / Shopping / Box3",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Commentaires",
      "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Administration",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notifications personnalisées",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"S'engager",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Notifications de bannière",
      "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Conseiller Azure",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Rapport de facturation Azure",
      "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Marge",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Abonnements Azure",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Analytique client",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consommation de licence",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Rapport d'utilisation Microsoft Azure",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Rapport de changement de licence.",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Rapport récapitulatif de consommation de licence",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Rapport de consommation de licence",
      "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer les droits Azure",
      "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Analyse d'intégration",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Commission",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Rapports de commissions",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Coupons",
      "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Documentation produit",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Citations",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Charger",
      "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Approbations",
      "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Commissions",
      "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Attributs du produit",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Utilisation",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Rapports",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Plans",
      "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Rapports planifiés",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Rapport d'utilisation",
      "SIDEBAR_TITLE_MENU_USERS":"Utilisateurs",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"Analyser",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Estimations Azure",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Groupes Azure",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Offres partenaires",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Factures",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Utilisateurs Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Commandes",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Produits",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Magasin",
      "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Offres distributeurs",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Factures",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Forfaits",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Clients",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Statut de paiement en attente.",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Plans",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"Revendeurs",
      "SIDEBAR_TITLE_MENUS_SELL":"Vendre",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Vente directe",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Vente indirect",
      "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Prise en charge C3",
      "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Icônes de Stockholm / Fichiers / Télécharger",
      "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIGN_UP_DATE":"Date d'inscription",
      "SIGNUP_CART_COUPON_APPLY":"Appliquer",
      "SIGNUP_CART_COUPON_CODE":"Appliquer le bon",
      "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Supprimer le bon",
      "simplereport":"Rapport de commission",
      "SINGLE_COMMENT_STATIC":"Commentaire",
      "SITE":"(Site)",
      "SITE_DEPARTMENT_ID":"SiteDepartmentId",
      "SITE_ID":"C3SiteId",
      "SLAB_BACKWARD_CONFIRMATION_POPUP":"Veux-tu vraiment y retourner ? Vous avez enregistré des données de dalle.",
      "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Veuillez remplir et enregistrer les données de la dalle.",
      "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Veuillez remplir les données de la dalle.",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Actions",
      "SLAB_DATA_TABLE_HEADER_COST_RATE":"Taux de coût/unité",
      "SLAB_DATA_TABLE_HEADER_RANGE":"Plage",
      "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Vente/unité",
      "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"La longueur du texte est trop longue",
      "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Veuillez remplir les données de la ligne.",
      "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Les données de dalle ne peuvent pas dépasser 10",
      "SLAB_TYPE_SELECT_OPTION":"Sélectionner",
      "SMTP_SETTINGS_TEST":"Veuillez cliquer sur le bouton pour tester les paramètres SMTP",
      "SMTP_TEST_BUTTON":"Tester SMTP",
      "SMTPTYPE_LEGACY_SMTP":"SMTP hérité",
      "SMTPTYPE_MODERN_0365":"Moderne 0365",
      "SMTPTYPE_SENDGRID":"Grille d'envoi",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Politique d'annulation",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 heures : Remboursement complet",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 heures : 1er jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 heures : 2e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 heures : 3e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 heures : 4e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 heures : 5e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 heures : 6e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Après 7 jours calendaires : l'annulation n'est pas autorisée",
      "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Abonnements logiciels Instructions",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"La quantité/sièges peut être augmentée à tout moment.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"La quantité/sièges peut être réduite dans les 7 jours calendaires, à sa précédente ou à la somme des quantités/sièges précédents.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Changement de quantité",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Modification du calendrier de renouvellement",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Le calendrier actuel sera supprimé si la quantité/le statut de renouvellement automatique/le cycle de facturation/la durée sont modifiés.",
      "SoftwareSubscriptions":"Abonnements logiciels",
      "SOUNCE_PRODUCT_DISPLAY_NAME":"Produit SouNCE",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Devise source",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Devise source",
      "SOURCE_PLAN_DISPLAY_NAME":"Forfait source",
      "SOURCE_PRODUCT_DISPLAY_NAME":"Produit d'origine",
      "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"Le profil client a été synchronisé",
      "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Modifier les détails de qualification spéciale",
      "SQL_ERROR_DESC":"Il semble y avoir une erreur technique dans le traitement de votre demande, veuillez contacter le support pour plus d'assistance.",
      "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Erreur de la base de données lors de la tentative d'obtention des cotisations de la facture",
      "START_BILLING_PERIOD":"Début de la période de facturation",
      "START_DATE_REQUIRED_MESSAGE":"La date de début est requise",
      "STATUS":"Statut",
      "STATUS_ALL":"Tout",
      "STATUS_DUE":"Redevances",
      "STATUS_OVER_DUE":"En retard",
      "STATUS_PENDING":"En attente",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Statut mis à jour avec succès",
      "STATUT: EN ATTENTE":"En attente",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Veuillez entrer au moins un nom de taxe et un pourcentage pour les sous taxes.",
      "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"La quantité maximale autorisée est",
      "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"La quantité minimale autorisée est de 1",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Ce champ est obligatoire",
      "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Supprimer la promotion",
      "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"La mise à niveau est en cours, veuillez cliquer sur la transition pour voir la progression",
      "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Étant donné que la quantité de licences de mise à niveau est inférieure à la quantité actuelle, vous devez réattribuer manuellement les sièges",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Cet abonnement client est partagé entre le client partenaire et le client revendeur, le produit ne peut donc pas être mis à niveau",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Cet abonnement est partagé par plusieurs entités, par conséquent, après la mise à niveau, un remappage manuel est nécessaire",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Ajouter un contrôle d'expiration d'abonnement",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Ajouter un contrôle d'expiration d'abonnement",
      "SUBSCRIPTION_CHECK_ID":"Vérification de l'identifiant d'abonnement",
      "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Requis",
      "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"le nombre maximum de jours doit être inférieur ou égal à {{MaxDate}}",
      "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"le nombre minimum de jours doit être supérieur ou égal à 1",
      "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Vous avez atteint votre limite maximale de 50 caractères autorisés",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Échec de la notification d'expiration de l'abonnement",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"La notification d'expiration de l'abonnement pour le client a échoué",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Notification d'expiration d'abonnement pour la réussite du client",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Notification d'expiration d'abonnement réussie",
      "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Sélectionnez un terme",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Soumettre",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Période de facturation",
      "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Cycle de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Période de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Catégorie",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Clients",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Date de fin",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Nom de l'abonnement",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Fournisseur",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Date de début",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Catégories",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Fournisseurs",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Catégories de fournisseurs",
      "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Télécharger l'historique des abonnements",
      "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Rapport sur l'historique des abonnements",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Fermer",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Rechercher",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Réinitialiser",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Date",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nom du département",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Nouveau prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Extension",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de produit",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Ancien prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Nom du site",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Date de fin",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Date de début",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Gérer les attributions de licence",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Libérer les droits inutilisés. Veuillez patienter...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"Gérer les attributions de licences pour ",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Assigner des licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Supprimer les licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Attribuer des utilisateurs",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Instructions",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Instructions",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Afficher et rechercher",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Licences à supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Erreurs de validation",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Saisissez des adresses email d'utilisateur valides séparées par une virgule.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Ce portail valide l'entrée et détermine si l'achat de licences supplémentaires est nécessaire avant l'affectation.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Aucune opération ne peut être réalisée pendant que nous travaillons sur des changements pour cet abonnement.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Toutes les erreurs détectées durant la validation de l'entrée seront affichées.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Les options Affecter ou Retirer des licences (onglet actif) est prise en compte lors de la soumission.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Cliquez <i class='fa fa-remove red'></i> sur un utilisateur dans le tableau sur la gauche pour l'ajouter à la liste des licences à supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Cliquez sur soumettre pour retirer les licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Les options Affecter ou Retirer des licences (onglet actif) sont prises en compte lors de la soumission",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Coût",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Quantité originale ",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Taux par droit",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Plusieurs utilisateurs",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Téléchargement de fichier",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Nom",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"ajouter une nouvelle adresse email pour utilisateur",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Entrer les emails utilisateur valides  séparés par une virgule dans un fichier .txt et le charger ici.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Entrer les emails utilisateur valides  séparés par une virgule dans un fichier .txt et charger. Exemple : abc@xyz.com,bcd@xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Ignorer les erreurs",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Corriger des erreurs",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Statut",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"\"Veuillez patienter pendant que nous traitons votre demande, cela peut prendre quelques minutes. Vous pouvez également revenir en arrière en cliquant sur le bouton \"Gérer\" sur la page des abonnements\"",
      "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"Souhaitez-vous continuer avec les prochaines modifications de renouvellement de planification comme {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'limite de sièges et limite de transactions' : (isSeatLimitExceeded ? 'la limite de sièges est' : 'la limite de transactions est')}} dépassée",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Commande annulée",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Commande complétée",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonnement acheté",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonnement supprimé",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"L'abonnement sélectionné est activé avec succès",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Les abonnements d'approvisionnement",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"La demande sélectionnée a été annulée avec succès.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Tous les clients",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Les abonnements d'approvisionnement",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"Activer",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Nom de l'entité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Titre",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Veuillez patienter pendant que nous traitons votre achat précédent, cela peut prendre quelques minutes.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"\"Veuillez patienter pendant que nous traitons votre achat précédent, cela peut prendre quelques minutes. Vous pouvez également vérifier l'état en cliquant sur le bouton \"Ajouter un nouvel abonnement\"",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Ajouter un chèque",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Retour à la liste",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Contrôles d'expiration d'abonnement",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Action",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Jours",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Nom",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Terme",
      "SUBSCRIPTION_STATUS_ACTIVE":"Actif",
      "SUBSCRIPTION_STATUS_CANCELLED":"Annulé",
      "SUBSCRIPTION_STATUS_COMPLETED":"Complété",
      "SUBSCRIPTION_STATUS_DELETED":"Supprimé",
      "SUBSCRIPTION_STATUS_DISABLED":"Désactivé",
      "SUBSCRIPTION_STATUS_EXPIRED":"Expiré",
      "SUBSCRIPTION_STATUS_FAILED":"Échoué",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Provision",
      "SUBSCRIPTION_STATUS_PENDING":"En attente",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Réussi",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendu",
      "SUBSCRIPTION_UGRADE_TITLE_2":"Important",
      "SUBSCRIPTION_UPDATED":"Abonnement mis à jour avec succès.",
      "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automatique",
      "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Choisissez une mise à niveau éligible pour votre abonnement",
      "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Quantité cumulée",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Abonnement actuel",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Abonnement actuel",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Sélectionnez une nouvelle offre pour mettre à niveau l'abonnement actuel. Cela mettra à niveau votre abonnement et fournira les services associés à la nouvelle offre",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Pendant la mise à niveau, évitez de modifier une partie de l'abonnement d'origine ou des comptes du client. Cela créera un nouvel abonnement, fournira des services et réattribuera des licences utilisateur au nouvel abonnement. Ces modifications ne peuvent pas être annulées.",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Assurez-vous d'effectuer une sauvegarde des données avant de mettre à niveau l'abonnement.",
      "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Ignorer l'erreur",
      "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Ignorer l'avertissement",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuel",
      "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"Aucun produit de plan éligible n'était présent pour l'abonnement",
      "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"Aucune transition trouvée",
      "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"Souhaitez-vous continuer la mise à niveau de l'abonnement en tant que {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'limite de sièges et limite de transactions' : (isSeatLimitExceeded ? 'la limite de sièges est' : 'la limite de transactions est')}} dépassée",
      "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Réattribution de siège",
      "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Sièges qui doivent être mis à niveau (abonnement source)",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Sélectionnez une cible d'abonnements",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Ce champ est obligatoire",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Cibler les abonnements",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Nouvel abonnement",
      "SUBSCRIPTION_UPGRADE_TITLE":"Choisissez une mise à niveau éligible",
      "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Activité de transition",
      "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"La mise à niveau est en cours",
      "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Mise à niveau",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Ce client a déjà été assigné.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Les utilisateurs suivants possèdent déjà une licence assignée pour cette offre. . Veuillez les supprimer et essayer d'enregistrer.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Êtes-vous certain de vouloir ignorer les erreurs ",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Les attributions de licence ont été mises à jour avec succès",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"Êtes-vous certain de vouloir réactiver cet abonnement ?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"L'abonnement sélectionné est réactivé avec succès",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Êtes-vous certain de vouloir libérer les licences inutilisées ?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Veuillez retirer au moins un utilisateur pour révoquer la licence de la table des licences attribuées!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Veuillez sélectionner un utilisateur dans la liste suggérée avant de cliquer sur le bouton Ajouter.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"Êtes-vous certain de vouloir suspendre cet abonnement ? Veuillez noter que les abonnements d'extension active connexes seront également suspendus (si existe)",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"L'abonnement (incluant les extensions, le cas échéant) est suspendu avec succès",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Les droits d'abonnement inutilisés libérés avec succès.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Votre demande d'attribution en bloc est en attente",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Chargé avec succès",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Veuillez entrer les emails des utilisateurs valides pour attribuer les licences dans le zone de texte",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter un nouvel abonnement ",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Modifier",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Gérer",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Total",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Actions",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Nom",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Coût",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Total",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Nouvel abonnement Azure ",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Nom",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter un nouvel abonnement ",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azure",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter de nouveaux abonnements",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Abonnements Azure",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Estimation du prix",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Nouvelle quantité totale ",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"La valeur minimale est 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"L'entrée doit être un nombre entier valide",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Offre",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Achetez des droits supplémentaires en augmentant le <strong> nouveau total de la quantité </ strong>",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Assurez-vous que vous avez libéré les licences des utilisateurs à travers  <strong>Utilisateurs -&gt; licences</strong>au cas où vous voudriez réduire le nombre de droits d'ici",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Votre demande d'achat d'abonnements est en attente.",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Réactiver",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonnement enregistré avec succès",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Veuillez sélectionner au moins une offre avant de placer une commande",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Suspendre",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Paramètres généraux mis à jour avec succès",
      "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"Les paramètres de ConnectWise Manage ont été mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"Paramètres SMTP partenaire mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"Partner {{logo}} mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"paramètres Partner mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Paramètres du fournisseur mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"Les paramètres d'inscription publique ont bien été mis à jour.",
      "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Importation de plusieurs paiements terminée avec succès",
      "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Les données d'utilisation quotidienne de l'offre Partenaire ont bien été importées",
      "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Importation des commissions sur les produits terminée avec succès",
      "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Création réussie d'un nouveau plan comprenant toutes les offres",
      "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Création d'un nouveau plan revendeur avec toutes les offres",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Corriger un écart de quantité",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Veuillez mettre à jour le plan pour que ce client inclue des offres pertinentes et revenir ici pour résoudre le problème.",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"Nom de l'offre CSP",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Aucune offre correspondante trouvée",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Offre correspondante dans le plan actuel",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Prix",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Quantité",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapping de l'offre",
      "SUPPLIER_DETAILS":"Détails du fournisseur",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Nouveau ticket de support",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"Numéro de téléphone",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Description",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":"Priorité",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Zone produit",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"Sujet",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"Type",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Soutien",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Numéro de ticket de support",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Ajouter un commentaire",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Commentaires",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"Numéro de téléphone",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Effort cumulatif",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Client",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Description",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Travail (en heures)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Priorité",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Zone produit",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Statut",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Sujet",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Agent de support ",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Sélectionner l'agent de support ",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Type",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Aucun commentaire ajouté.",
      "SUPPORT_FOR_TEXT":"Soutien pour",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Ajouter ticket de support",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Tickets de support",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Client",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Rechercher",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"Statut",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Agent de support ",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Identifiant de ticket",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Actions",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Détails",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Date créée",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Client",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Priorité",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Zone produit",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Statut",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Sujet",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Identifiant de ticket",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Type",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Nouveau ticket de support créé avec succès",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Archivé",
      "SUPPORT_TICKET_STATUS_CLOSED":"Fermé",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"En cours",
      "SUPPORT_TICKET_STATUS_OPEN":"Ouvrir",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Ré-ouvrir",
      "SUPPORT_TICKET_STATUS_RESOLVED":"Résolu",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"Ne peut être corrigé",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Problèmes d'accès",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"Analyses",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Gestion de profil",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Gestion des abonnements",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Gestion des utilisateurs",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Détails de ticket de support mis à jour avec succès",
      "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Aucun marché n'a été trouvé pour le code devise choisi",
      "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Les marchés ci-dessous ne sont pas pris en charge car aucune conversion de devise n'a été trouvée pour leurs devises respectives.",
      "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"Le marché pris en charge s'applique uniquement aux offres NCE",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Marché",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Code du marché",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Devise",
      "SupportedMarkets":"Marchés pris en charge",
      "SWAL_OK_TEXT":"OK",
      "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Passer à l'API de tarification Microsoft",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Passer au nouveau modèle de sécurité",
      "SYNC_CUSTOMER_PROFILE":"Synchroniser le profil client",
      "TAB_HEADING_PROVISIONING_STATUS":"Statut de Provisionnement",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Affichage de {{StartIndex}} - {{EndIndex}} des {{Total}} enregistrements",
      "TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "TABLE_HEADER_TEXT_DATE":"Date",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "TABLE_HEADER_TEXT_DISTRIBUTOR_INVOICE":"Facture du distributeur",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Raison de l'échec",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Montant total",
      "TABLE_HEADER_TEXT_KEY":"Clé",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Montant payé",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Statut de paiement",
      "TABLE_HEADER_TEXT_REMARKS":"Remarques",
      "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Montant du sous-total",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Montant de la taxe",
      "TABLE_HEADER_TEXT_VALUE":"Valeur",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Total de facture(s) sélectionnée(s)",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Recherche...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Balise supprimée avec succès",
      "TAG_NAME_PLACEHOLDER":"Sélectionnez une balise",
      "TAG_SAVED_SUCCESS_MESSAGE":"Balise sauvegardée avec succès",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Nom du plan",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Nom de produit",
      "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Propriétés",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Retour aux balises",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier",
      "TAGS_CAPTION_TEXT_TAG_FOR":"Balise pour ",
      "TAGS_CAPTION_TEXT_TAGS_FOR":"Balises pour ",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Devise cible",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Devise cible",
      "TARGET_PLAN_DISPLAY_NAME":"Plan cible",
      "TARGET_PRODUCT_DISPLAY_NAME":"Produit cible",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Retour au taux d'imposition",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Pourcentages d'impôt",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Ajouter",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Modifier",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Les règles fiscales sont définies par des combinaisons de {Pays, Province d’état et Code d’impôts}, {Pays, code postal} ou {Pays, Province d’état et Code postal}",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Par exemple: La règle d'imposition définie pour un Pays - États-Unis - ne pas remplir le champ état, province et le code postal signifie que le taux d'imposition s'applique à tous les clients aux États-Unis. Si vous souhaitez définir une règle pour un état en particulier, indiquez les valeurs Pays et État lors de la définition de la règle",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Nom de taxe",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Pourcentage de taxes",
      "TAX_PERCENTAGES_ADD_SUCCESS":"Pourcentage d'impôt ajouté avec succès.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Clients",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Pourcentage d'impôt supprimé avec succès.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Ajouter pourcentage de taxes",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Pourcentages d'impôt",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Pourcentage d'impôt pour ",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Modifier",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Actions",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"En vigueur à partir de",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"% Impôt  ",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Pourcentage d'impôt mis à jour avec succès.",
      "TAX_RULES_ADD_SUCCESS":"Règle fiscale ajoutée avec succès",
      "TAX_RULES_DELETE_SUCCESS":"Règle fiscale supprimée avec succès",
      "TAX_RULES_UPDATE_SUCCESS":"Règle fiscale mise à jour avec succès",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Impôt cumulatif",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Les règles d'impôt pour enfants sont appliquées dans l'ordre sur le montant résultant de chaque étape du calcul.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Général",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Pourcentage de taxe unique appliqué sur le montant total.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Sous taxes",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Le pourcentage de taxe pour cette définition sera le total (somme) des règles fiscales pour enfants.",
      "TENANT TOOLTIP":"Sélectionnez le locataire pour charger les utilisateurs",
      "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activer le seuil de danger avec abonnement",
      "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Activer la libération automatique pour les sièges non attribués",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permet au client de modifier la quantité de produits à partir de la vue grille ou liste dans l'écran des produits",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Autoriser le client à initier la transaction pour sa ou ses factures",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Cocher cet indicateur empêche les clients de réduire la quantité d'abonnement",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Autoriser la synchronisation du nom du produit",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Autoriser la synchronisation des noms de produits pour les offres basées sur la quantité",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Permet la synchronisation du nom convivial de l'abonnement pour les offres basées sur la quantité",
      "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Possibilité d’appliquer des remises sur le plan Azure niveau 2 au client. la valeur par défaut est Non. Si elle est définie sur oui, à l'exception des réductions PEC, toutes les autres réductions seront également appliquées au client.",
      "TENANT_CONFIG_DESC_AUTO_SYNC":"Ceci est utilisé pour activer/désactiver le processus de synchronisation automatique dans la réconciliation.",
      "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Durée du contrat annuel Autotask (années)",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Date de fin du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Cette valeur de date sera utilisée comme date de fin pour créer automatiquement des contrats pour le client",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Frais de mise en place du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Veuillez saisir les frais d'établissement du contrat, le cas échéant",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Date de début du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Cette valeur de date sera utilisée comme date de début pour créer automatiquement des contrats pour le client",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Jour de début du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Cochez la case si les rapports sur les heures contractuelles nécessitent des heures de début et de fin",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Rapport de temps de contrat Autotask requis heures de début et d'arrêt",
      "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Durée du contrat mensuel Autotask (mois)",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Permettre aux clients de gérer les abonnements Azure",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permettre aux clients de définir des groupes sur les abonnements Azure",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du client azure. Par exemple: Entrer 1.15 si la majoration doit être 15 %",
      "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, l'abonnement NCE est défini sur le renouvellement automatique.",
      "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Permet de prendre en compte le prix de l'ERP lors du renouvellement sans macro",
      "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Après achat ou augmentation de quantité, le produit ne sera pas considéré en lancement automatique jusqu'à cette période (en jours).",
      "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Type de facturation client",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Devise client",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Type d'efficacité de soutien pour la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Seuil de danger par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Seuil d'erreur par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Seuil d'informations par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Budget mensuel par défaut pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinataire de l'e-mail de seuil par défaut pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Seuil d'avertissement par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Activer les termes et conditions pour commander des produits",
      "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Activer les termes et conditions NCE",
      "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Activer les rapports Power BI",
      "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"L'activation de cette option permettra aux utilisateurs de planifier une commande",
      "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ID client ERP",
      "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Client exonéré pour l'exportation PSA",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Si ce champ est coché, nous prenons en considération le consentement du client.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Ce champ est mis à jour si au moins un des clients a accepté le MCA. Décochez cette case pour faire respecter à nouveau l'acceptation du ou des clients.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Acceptez-vous les nouvelles conditions générales ?",
      "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Limite de transactions",
      "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Constante entière",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Pour référence interne. Cet identifiant peut être utilisé pour associer le client à un enregistrement dans votre système de comptabilité interne",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Jour du mois où les factures sont approuvées automatiquement",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Jour où les paiements automatiques sont traités",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Date d'échéance des factures",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"Nombre de jours dus pour le paiement de la facture",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Jour du mois où les factures sont finalisées automatiquement",
      "TENANT_CONFIG_DESC_INVOICE_NOTE":"Apparaît sur la facture pdf",
      "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendater la disponibilité du profil de paiement avant le paiement du panier",
      "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"La version automatique est requise pour les produits de site et de service",
      "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Mandater l'utilisateur pour soumettre un mode de paiement",
      "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'adresse nouvellement ajoutée comme adresse par défaut du client.",
      "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'e-mail nouvellement ajouté comme e-mail par défaut pour le client.",
      "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"L'activation de cette option désignera automatiquement le numéro de téléphone nouvellement ajouté comme numéro de téléphone par défaut pour le client.",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Propriétaire de la facture unique (type)",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Propriétaire de la facture unique (identifiant)",
      "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les éléments de ligne du client une seule fois.",
      "TENANT_CONFIG_DESC_PAN_NUMBER":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Lien des conditions générales",
      "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels perpétuels.",
      "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Définir le numéro de bon de commande pour le client",
      "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Si ce paramètre est activé, l'e-mail de facturation sera envoyé aux services de comptabilité externes",
      "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Activer la fonctionnalité de transmission des factures à un service de comptabilité externe",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Cochez cet indicateur si vous souhaitez que le système traite les paiements (CC/ACH, le cas échéant)",
      "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Lorsqu'il est activé, affichera le nom de l'abonnement donné dans le centre des Partenaires",
      "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"La facturation perpétuelle des logiciels sera incluse dans les factures de ce site.",
      "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"EnfoNCE une limite sur le nombre de sièges que le client peut acheter",
      "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Les conditions de paiement à marquer sur les factures publiées sur QuickBooks",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Saisissez le nombre de jours avant la date de renouvellement pendant lequel les e-mails de consentement seront envoyés aux clients.",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Spécifiez le nombre de jours après la notification de consentement pendant lequel la notification de rappel sera envoyée aux clients.",
      "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"La facturation des réservations Azure sera incluse dans les factures de ce site.",
      "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur la réservation Azure du client.",
      "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"Modèle de tarification des IR",
      "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du client RI.",
      "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Le client recevra le courriel avec les détails de la facture, une fois la facture approuvée et si les notifications par courriel sont activées",
      "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Le client recevra une notification par courriel concernant les factures ayant des montants nuls",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Devrait activer la valeur par défaut pour le client.",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Devrait informer le client",
      "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Devrait exempter le client du paiement instantané.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut annuler l'abonnement NCE dans une fenêtre d'annulation de 7 jours calendaires.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Lorsque cette option est activée, le client peut annuler les abonnements logiciels dans un délai d'annulation de 7 jours calendaires.",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Autoriser le client à aligner les dates de fin d'abonnement",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Si cette option est activée, le client peut aligner les dates de fin d'abonnement pour obtenir une coïncidence.",
      "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Afficher le menu de renouvellement du prochain programme",
      "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Activez l'affichage des abonnements non intégrés pour la sélection d'une date de fin personnalisée lors de l'achat.",
      "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut réactiver un abonnement NCE suspendu.",
      "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Bouton Afficher les qualifications spéciales",
      "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut suspendre l'abonnement NCE.",
      "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Lorsque cette option est activée, le client peut mettre à niveau le produit NCE vers un SKU supérieur",
      "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Abonnement à ignorer lors d'une facturation unique",
      "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer sur le prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"La facturation des abonnements logiciels sera incluse dans les factures de ce Site.",
      "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Mettre cette valeur à vrai empêchera l'utilisateur d'effectuer des achats.",
      "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Chaîne constante pour le client",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Soutien pour la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspendre lorsque le seuil de danger est atteint",
      "TENANT_CONFIG_DESC_TAX_CODE":"Le code fiscal est utilisé pour déterminer la taxe applicable %",
      "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_TAX_VALUE":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer sur le prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"La facturation des abonnements tiers sera incluse dans les factures de ce Site.",
      "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"EnfoNCE une limite sur la valeur monétaire de l'achat de produit basé sur la quantité (dans la devise du client)",
      "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Type d'ajout ConnectWise",
      "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Facturation anticipée",
      "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"En fonction du format sélectionné, les ajouts seront créés dans Connectwise",
      "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Placer la facturation du mois en cours sur le mois futur spécifié pour la facturation",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Afficher le journal d'acceptation des conditions générales au domaine",
      "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activer le seuil de danger avec abonnement",
      "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Autoriser la libération automatique des sièges inutilisés",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Autoriser le client à modifier la quantité de produits à partir de la liste",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Autoriser le client à initier une transaction",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Permettre au client de réduire le nombre de licences",
      "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Appliquer les remises du plan Azure niveau 2 au client",
      "TENANT_CONFIG_DISP_AUTO_SYNC":"Synchronisation automatique",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Voulez-vous activer Azure ?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Voulez-vous activer Azure pour Entreprise ?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour l'utilisation Azure",
      "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Gérer les produits NCE renouvelle automatiquement",
      "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Considérez le prix de l'ERP lors du renouvellement sans macro",
      "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Période de refroidissement de la libération automatique",
      "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Type de facturation client",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Devise client",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Efficacité de la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Seuil de danger par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Seuil d'erreur par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Seuil d'informations par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Budget mensuel par défaut",
      "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinataire de l'e-mail de seuil par défaut",
      "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Seuil d'avertissement par défaut (%)",
      "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Activer les termes et conditions pour commander des produits",
      "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Activer les termes et conditions NCE pour commander des produits Microsoft NCE",
      "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Activer l'ordre de planification",
      "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ID client ERP",
      "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Client exonéré pour l'exportation PSA",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Consentement du client activé ?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Acceptez-vous le contrat client Microsoft ?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Acceptez-vous les conditions générales du partenaire ?",
      "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Limite de transactions",
      "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Identifiant de référence de facturation",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Jour où le client peut consulter les factures",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Jour du paiement automatique",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Date d'échéance des factures",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Jours d'échéance des factures",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Jour où les factures sont finalisées",
      "TENANT_CONFIG_DISP_INVOICE_NOTE":"Notes de facturation",
      "TENANT_CONFIG_DISP_INVOICE_TYPE":"Type de facture",
      "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendater Profil de paiement Panier Commander",
      "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"La version automatique est requise pour les produits de site et de service",
      "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Profil du mandat",
      "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Attribuer automatiquement une nouvelle adresse par défaut",
      "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Attribuer automatiquement un nouvel e-mail par défaut",
      "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Attribuer automatiquement un nouveau numéro de téléphone par défaut",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Propriétaire de la facture unique (type)",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Propriétaire de la facture unique (identifiant)",
      "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Balisage pour les éléments de campagne ponctuels",
      "TENANT_CONFIG_DISP_PAN_NUMBER":"Numéro PAN",
      "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"Clé numérique PAN",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"URL des conditions générales",
      "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration pour l'abonnement perpétuel au logiciel",
      "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Lieu de fourniture",
      "TENANT_CONFIG_DISP_PO_NUMBER":"Numéro de commande",
      "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Envoyer l'e-mail de facturation aux services comptables externes",
      "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Enregistrer les factures auprès d'un service de comptabilité externe",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Désirez-vous automatiser le paiement de factures ?",
      "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Afficher le nom de l'abonnement du fournisseur",
      "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Propriétaire perpétuel de la facture logicielle (identifiant)",
      "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Limite de siège applicable",
      "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Conditions de paiement de QuickBooks",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Période d'initiation du consentement au renouvellement",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Jours de notification de rappel de consentement de renouvellement",
      "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Propriétaire de la facture de réservation Azure (identifiant)",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"Modèle de tarification des IR",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Envoyer des courriels de facture automatiquement",
      "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Envoyer des factures de montant zéro",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Expédier au nom du contact",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Expédier au numéro de contact",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Expédier au numéro PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Expédier à la clé du numéro PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Expédier au type de taxe",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Expédier à la valeur fiscale",
      "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Devrait activer la valeur par défaut pour le client",
      "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Paiement instantané exonéré",
      "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Bouton Afficher les qualifications spéciales",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton d'annulation du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Afficher le bouton d'annulation du produit des abonnements logiciels",
      "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Afficher les abonnements non intégrés pour une date de fin personnalisée",
      "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton de réactivation du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton de suspension du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Afficher le journal d'acceptation des conditions générales",
      "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Afficher le bouton de mise à niveau du produit NCE",
      "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour l'abonnement au logiciel",
      "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Propriétaire de la facture d'abonnement au logiciel (identifiant)",
      "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Arrêter toutes les transactions",
      "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Identifiant unique du client",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspendre lorsque le seuil de danger est atteint",
      "TENANT_CONFIG_DISP_TAX_CODE":"Code fiscal",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Type de taxe",
      "TENANT_CONFIG_DISP_TAX_VALUE":"Valeur fiscale",
      "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration pour l'abonnement tiers",
      "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Propriétaire de la facture d'abonnement tiers (identifiant)",
      "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"Limite du montant de la transaction",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Veuillez entrer la valeur de {{tenantConfigName}}",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Veuillez entrer un numéro de {{tenantConfigName}} valide.",
      "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"La gestion de la configuration du renouvellement automatique n’est pas activée pour ce locataire.",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Configuration client rétablie avec succès",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Client configuration sauvegardé avec succès",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"Configuration client pour {{clientname}} mise à jour avec succès",
      "TENANT_COUNTS":"Compter",
      "TENANT_CREATION_FAILED":"La création du locataire a échoué",
      "TENANT_EXCEEDED_COUNT_ERROR":"Nombre de locataires à créer, ne dépassant pas {{Count}}",
      "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":"Si vous souhaitez créer l'abonnement Azure avec le locataire, veuillez le cocher",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Ajouter le préfixe 'OTU' dans le nom",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Ajouter 'OTU' comme préfixe dans le nom du locataire",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Attribuer un administrateur de locataire en tant que propriétaire",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Attribuer l'utilisateur admin en tant que propriétaire de l'abonnement créé",
      "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Ajouter des locataires",
      "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Créer des abonnements",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"département",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Nom du domaine",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Les noms de domaine commenceront par ceci.",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Sélectionnez l'offre de plan Azure",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Utilisez ceci pour sélectionner le produit de plan correct pour mapper le plan Azure",
      "TENANT_MANAGEMENT_LABEL_PREFIX":"Préfixe",
      "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Ajouter un préfixe au nom du locataire",
      "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Séquence de démarrage",
      "TENANT_MANAGEMENT_LABEL_SITE":"Site",
      "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Nombre de locataires",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Nom",
      "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Processus terminé. Veuillez consulter le journal d'audit pour plus de détails.",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"La demande d'ajout de locataires est acceptée et en cours de traitement",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La demande d'ajout de locataires a échoué.",
      "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Veuillez fournir une séquence de numéros",
      "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"L'incrément séquentiel sera utilisé comme suffixe dans les noms de locataire.",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Statut de création du locataire",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Veuillez patienter pendant la création des locataires.",
      "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Nombre de locataires à créer.",
      "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Les noms des locataires commenceront par ceci.",
      "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Retour aux locataires",
      "TENANT_SEQUENCE":"Séquence",
      "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"En cliquant sur le bouton « Accepter », vous acceptez les conditions d’utilisation ci-dessus.",
      "TERM_AND_CONDITIONS_LINK_TEXT":"Conditions d'utilisation du partenaire",
      "TERM_AND_CONDITIONS_READ_TEXT":"Veuillez lire les termes et conditions ci-dessous.",
      "TERM_DURATION_DESC_DAY(S)":"Jour(s)",
      "TERM_DURATION_DESC_DAYS":"Jour",
      "TERM_DURATION_DESC_MONTH":"Mois",
      "TERM_DURATION_DESC_MONTH(S)":"Mois",
      "TERM_DURATION_DESC_YEAR":"Année",
      "TERM_DURATION_DESC_YEAR(S)":"Année(s)",
      "TERM_DURATION_DESC_YEARS":"Années",
      "TERM_DURATION_FILTER_DESC_DAYS":"jours",
      "TERMS_AND_CONDITIONS":"conditions générales",
      "TERMS_AND_CONDITIONS_ACCEPT":"Veuillez accepter les conditions générales",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"conditions générales acceptées avec succès.",
      "TERMS_AND_CONDITIONS_BACK":"Retour  aux conditions générales",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Veuillez lire les conditions générales ci-dessous</b>.    </br>    </br>    <ul>    <li> <a ng-click='vm.PartnerAgreementClicked()'>Conditions d'utilisation de partenaire</a></li> </ul>    </br>    En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Veuillez lire les conditions générales ci-dessous fournies par Microsoft. </br> En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Veuillez lire les conditions générales du contrat ci-dessous du <b>{{partnerName}}</b> et Microsoft.    </br>    </br>    <ul>    <li> <a ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li>  </ul>    </br>    En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Cliquer <a id='linkEzidebit' ng-click='vm.EzidebitAgreementClicked()'> ici </a>pour lire le Contrat intégral Ezidebit",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Contrat Ezidebit",
      "TERMS_AND_CONDITIONS_READ":"J'ai lu les conditions générales",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"Impossible de traiter votre demande.",
      "TEST_EMAIL_LABEL":"Tester EmailId",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Tester",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Tester",
      "TEST_SMTP_SETTING_TITLE":"Tester les paramètres SMTP",
      "TEXT_ACH":"ACH",
      "TEXT_ACTIONS":"Actions",
      "TEXT_BILL_FROM":"Facture de",
      "TEXT_BILL_TO":"Facturé à",
      "TEXT_BILLING_PERIOD":"Période de facturation",
      "TEXT_EMAIL_ID":"Identifiant de courriel",
      "TEXT_GROUP_NAME":"Nom du groupe",
      "TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "TEXT_INVOICE_DATE":"Date de la facture",
      "TEXT_INVOICE_NUMBER":"Numéro de facture",
      "TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "TEXT_OFF":"À L'ARRÊT)",
      "TEXT_PLAN_ID":"Identifiant de plan",
      "TEXT_RESPONSIBLE_OFFICIAL":"Responsable officiel",
      "TEXT_SAAS_SUBSCRIPTION_STATUS":"Statut de l'abonnement Saas",
      "TEXT_SIGN_IN_REQUIRED":"Connexion requise",
      "TEXT_TENANT_ID":"Identifiant de domaine",
      "TEXT_THRESHOLDS":"Seuils",
      "TEXT_VAT_NUMBER":"Numéro de TVA",
      "THE_SEQUENCE_LABEL_THE_STARTING_NAME":"Le nom de la séquence commencera à partir de",
      "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Sélectionnez l'accord",
      "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Compléter le mappage pour l'entité C3",
      "THIRD_PARTY_ENTITY_MAPPING":"Cartographie des entités tierces",
      "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer le mappage pour le client : <b>{{customerName}}</b> ?",
      "THIRD_PARTY_PRODUCT_MAPPING":"Cartographie des produits tiers",
      "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer les produits tiers sélectionnés ?",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Action",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Accord",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"Client C3",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"Client PSA",
      "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"Sélectionnez le service PSA",
      "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Sélectionnez le client PSA",
      "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Cartographie des abonnements tiers",
      "TIME_DESC_DAY":"jour",
      "TIMER_DESC_DAYS":"jours",
      "TITLE_COUPON_CODE_TEXT":"Codes promotionnels",
      "TITLE_CUMULATIVE_TAXES":"Impôts cumulatifs",
      "TITLE_ROLE_SELECT":"Sélectionner le rôle",
      "TITLE_SUB_TAXES":"Sous taxes",
      "TITLE_TAGS":"Balises",
      "TITLE_TEXT":"Bienvenue au centre de contrôle CSP",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"pour rejoindre les abonnements Azure",
      "TOOL_TIP_TEXT_HIDE":"Cacher",
      "TOOL_TIP_TEXT_SHOW":"Montrer",
      "TOOLTIP_COUPON_CODE_TEXT":"Code promotionnel",
      "TOOLTIP_TEXT_DELETE_PSA_LOG":"Désactiver le journal",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Désactiver un produit",
      "TOOLTIP_TEXT_EDIT_NAME":"Modifier le nom",
      "TOOLTIP_TEXT_EDIT_QUANTITY":"Modifier la quantité",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Activer le produit",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Lier un produit",
      "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"Le produit Link n'est pas pris en charge",
      "TOOLTIP_TEXT_QUANTITY":"Quantité",
      "TOUR_POPUP_CONTENT":"<b>Bienvenue à bord !</b> </br> Découvrez un aperçu des fonctionnalités de l'application.",
      "TOUR_POPUP_DISMISS_BUTTON":"Rejeter",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Faites un tour",
      "TOUR_POPUP_TITLE":"Bienvenue dans ",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Actions",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Montant",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Catégorie",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Évaluer",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Description",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Rabais",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Quantité",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Évaluer",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"Montant cumulé",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"Taxé",
      "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Le montant de la transaction dépasse",
      "TRANSACTION_LIMIT_MESSAGE":"Limite restante {{RemainingAmount}} sur {{TotalLimit}}",
      "TRANSACTION_TYPE_PURCHASE":"Nouvel achat",
      "TRANSACTION_TYPE_UPDATE":"Mise à jour de la quantité",
      "TRANSACTION_TYPE_UPGRADE":"Mise à niveau de l'abonnement",
      "Trial offer parent unavailable":"Offre d'essai parent indisponible",
      "Trial offers":"Offres d'essai",
      "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Actuellement, il existe une demande pour transformer l’offre d’essai en abonnement ; aucune opération n’est autorisée sur ce produit.",
      "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Les deux valeurs valides les plus proches sont {{lower}} et {{upper}}.",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Cycle de facturation",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Type de facturation",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Catégorie",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Type de consommation",
      "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"Est-il auto-renouvelable",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Nom de produit",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Fournisseur",
      "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Prix de vente",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Type de vente",
      "TRIAL_OFFER_PARENT_TERM_DURATION":"Durée du mandat",
      "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Continuer l'abonnement après l'essai",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Instructions",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Note",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"La période d'essai dure un mois et le produit sera ensuite annulé.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"jours, et le produit sera annulé par la suite.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" La période d'essai dure",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"En cliquant sur « Continuer l'abonnement », le produit sera acheté après la période d'essai et la facturation commencera en conséquence.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Vous avez la possibilité de prolonger votre abonnement au-delà de la période d'essai en sélectionnant le bouton « Acheter un abonnement ».",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"L'offre d'essai partagée sur l'ensemble du site/département ne sera pas convertie en abonnement.",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Un abonnement d'une quantité sera acheté auprès de l'entité opérationnelle du client.",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Veuillez entrer une valeur valide",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"La quantité maximale est de 10 000",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"La quantité minimale est de 1",
      "TRIAL_PERIOD_DAYS_TEXT":"Jours",
      "TROUE_BTN_TEXT_END_TOUR":"Fin de la visite",
      "TROUE_BTN_TEXT_NEXT":"Suivant &raquo;",
      "TROUE_BTN_TEXT_PREV":"&laquo; Précédent",
      "TRUE":"TRUE",
      "UI Notification":"Notification de l'interface utilisateur",
      "UNABLE_TO_FIND_ANY_Azure_PLAN_FROM_PC":"Impossible de trouver un plan Azure à partir du PC",
      "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"Impossible de trouver un plan Azure à partir du PC",
      "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le client a été dissocié avec succès",
      "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Voulez-vous vraiment dissocier {{customer}} ?",
      "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le revendeur a été dissocié avec succès",
      "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Le client a été dissocié d'un gestionnaire de compte avec succès",
      "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Le revendeur a été dissocié d'un gestionnaire de compte avec succès",
      "UNIQUE_NAME_ERROR":"Ce nom existe déjà, veuillez choisir un nom différent.",
      "UNSUPPORTED_FILE_TYPE_SELECTED":"Seuls les fichiers de type jpg, svg, png, gif, bmp sont pris en charge",
      "UP_GRADE_Azure_SUBSCRIPTION":"Mettre à niveau l'abonnement Azure vers Azure Plan.",
      "UP_GRADE_AZURE_SUBSCRIPTION":"Mettre à niveau l'abonnement Azure vers Azure Plan.",
      "Update license":"Mettre à jour la licence",
      "Update webhook notification failure":"Échec de la notification du webhook de mise à jour",
      "Update webhook notification success":"Mise à jour réussie de la notification du webhook",
      "UPDATE_CART_LINE_ITEM":"Article de panier mis à jour",
      "UPDATE_CART_LINE_ITEM_FAILURE":"Échec de la mise à jour de l'article du panier",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Configurations globales du client ont été mises à jour avec succès",
      "UPDATE_DEFAULT_VALUE":"Marquer comme valeur par défaut",
      "UPDATE_DUE_DATE_NAME":"Date d'échéance",
      "UPDATE_INVOICE_LINEITEM_PONUMBER":"Numéro de bon de commande de l'élément de facture",
      "UPDATE_INVOICE_PONUMBER":"Numéro de commande",
      "UPDATE_INVOICE_PROPERTIES":"Mettre à jour la propriété de la facture",
      "UPDATE_MANAGE_RENEWAL_TITLE":"Renouvellement de la mise à jour",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour des paramètres du partenaire",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour des informations sur l'impôt",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"La mise à jour des produits n'est pas autorisée, car une attribution de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "UPDATE_PROFILE":"Profil mis à jour avec succès",
      "UPDATE_PROFILE_FAILURE":"Échec de la mise à jour du profil",
      "UPDATE_RESELLER_CONFIGURATION":"Mettre à jour la configuration du revendeur",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Échec de la mise à jour de la configuration du revendeur",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Mettre à jour l'option de paiement par défaut du revendeur",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Échec de la mise à jour de l'option de paiement par défaut du revendeur",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Profil de paiement du revendeur mis à jour",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Impossible de mettre à jour le profil de paiement du revendeur",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Prix de produit de plan de revendeur mis à jour",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Impossible de mettre à jour le prix du produit du plan du revendeur",
      "UPDATE_RESELLER_PROFILE":"Mettre à jour le profil du revendeur",
      "UPDATE_RESELLER_PROFILE_FAILURE":"Impossible de mettre à jour le profil du revendeur",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valeur par défaut mise à jour du client fournisseur",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Impossible de mettre à jour la valeur par défaut du client fournisseur",
      "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Informations du gestionnaire de compte mis à jour avec succès",
      "UPDATED_FAILED_C3_PSA_DATA_FAIL":"Échec de la mise à jour des données PSA",
      "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"L'actualisation des données a échoué, d'autres options seront accessibles une fois que vous aurez cliqué sur ok.",
      "UPDATED_PAGE_MODE_TO":"Mise à jour réussie vers la vue {{PageMode}}",
      "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"Mise à jour réussie des données C3 PSA avec les dernières données du système externe PSA",
      "UPGRADE_ELIGIBILITY_CHECK":"Vérifier l'admissibilité à la mise à niveau",
      "UPGRADE_FAILED_MSG":"La mise à niveau Azure a échoué",
      "UPGRADE_SUCCESS_MSG":"La demande de mise à niveau est acceptée et en cours de traitement.",
      "UPGRADE_SYNC_PUPUP_TITLE":"Mettre à niveau Azure",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Aligner avec la date de début du contrat",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Alignez la date d'entrée en vigueur du produit avec la prochaine période contractuelle récente. Veuillez noter que la prochaine date récente est calculée uniquement sur une base mensuelle, même si la durée du contrat est annuelle.",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Charger des factures sur l'identifiant de lot de QuickBooks Online",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Message d'erreur pour le chargement de factures sur Quickbooks",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"Voulez-vous vraiment télécharger les factures sélectionnées dans <b>QuickBooks en ligne</b> ?",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Charger des factures sur l'identifiant de lot de Xero Batch",
      "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"Êtes-vous sûr de vouloir télécharger les factures sélectionnées dans <b>Xero</b> ?",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Message d'erreur pour le chargement de factures sur Xero",
      "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Télécharger les détails des revendeurs",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"Échec du téléchargement de l'historique des abonnements dans le service externe",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Téléchargement réussi de l'historique des abonnements dans le service externe",
      "UPLOAD_TO_PSA_SELECT_ALL":"Sélectionner tout",
      "URL is valid!":"L'URL est valide !",
      "Usage":"Utilisation du rapport",
      "Usage tiers":"Niveaux d'utilisation",
      "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"Vous ne pouvez pas mettre à jour le pourcentage -ve avec une valeur du pourcentage disponible.",
      "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Vous ne pouvez pas mettre à jour le pourcentage à une valeur supérieure au pourcentage disponible.",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Récupération du rapport d'utilisation",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Identifiant d'abonnement Azure",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Facturé au client",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Date de fin des frais",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Date de début des frais",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Coût pour le client",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Coût pour le partenaire (après impôt)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Coût pour le partenaire (avant impôt)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Code de devise",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nom du client",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Télécharger le rapport d'utilisation",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nom de domaine",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Rapport d'utilisation",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Seuil de danger",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Seuil d'erreur",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Nom du groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Sélectionner un groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Seuil d'information",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Masquer pour le client",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Budget mensuel",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":" Seuil d’avertissement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Retour aux utilisateurs de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Gérer l'utilisateur de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Courriel",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Rôle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Contributeur",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Propriétaire",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Lecteur",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux inscriptions",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exporter les détails d'utilisation ",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Résumé de facturation",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Détails d'utilisation de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Dépenser",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Unités consommées",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Type de ressource",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour aux clients",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"Abonnements Azure pour",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Tous les abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Période de facturation",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Utilisateurs",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Consulter les détails",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Actions",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Coût estimé",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Seuils",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Ajouter l'utilisateur à l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Les utilisateurs de l'abonnement de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Courriel",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Nom",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Rôle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Supprimer un utilisateur",
      "USER_CREATED_POPUP":"Utilisateur créé",
      "USER_CREATION_SUCCESS_MESSAGE":"L'utilisateur {{UserName}} a été créé avec succès",
      "USER_DETAILS_NOT_FOUND_BODY":"Impossible de trouver les informations de l'utilisateur connecté. Veuillez contacter l'administrateur !",
      "USER_DETAILS_NOT_FOUND_HEADER":"Détails introuvables",
      "USER_HAS_BEEN_CREATED":"L'utilisateur a été créé avec les détails suivants",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"BatchId de l'attribution des licences utilisateurs",
      "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Statut du lot d'attribution de licence utilisateur",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Terminer",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Statut d'affectation de licence",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Nom du client",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Utilisateurs",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"E-mail",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Nom de domaine",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Identifiant de domaine",
      "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"Impossible de mettre à jour le statut, veuillez contacter le support",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Extension ",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Forfait",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Lié",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Statut de suppression de l'utilisateur",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Appliquer les modifications aux extensions",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Utilisateurs du produit",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Une erreur s'est produite lors de la libération des sièges. Veuillez contacter le soutien technique.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Il devrait y avoir au moins une licence active. Veuillez suspendre le produit si vous ne souhaitez plus l'utiliser.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"La demande de libération des sièges est en cours de traitement.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Chargé avec succès",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"La demande d'ajout de licences est acceptée et en cours de traitement.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"La demande d'affectation globale de licences est acceptée et en cours de traitement.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Affecté",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Nombre total de sièges",
      "USER_LISTING_FILTER_LABEL":"Identifiant de courriel",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Recherche par prénom, nom de famille, identifiant de courriel",
      "USER_LISTING_SEARCH_BUTTON":"Rechercher",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Exporter le rapport d'affectation des utilisateurs",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entités",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Sélectionnez au moins une entité",
      "USER_REMOVED":"Utilisateur supprimé avec succès",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Nom de rôle",
      "USER_ROLE_LIST_LABEL_SCOPE":"Nom de portée",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Nom d'utilisateur",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Utilisateur ajouté avec succès",
      "USERCONTROLLER_USERUPDATESUCCESS":"Détails de l'utilisateur mis à jour avec succès",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Accès de l'utilisateur révoqué",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Utilisateur",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"n'est pas disponible",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"est disponible",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Informations Utilisateur",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Cochez / décochez les cases à cocher à côté des offres pour attribuer / libérer des licences",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"S'il n'y a pas assez de licences libres disponibles pour une offre, un achat sera effectué avant que l'attribution ne soit effectuée",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"Indique qu'un abonnement est en cours de provisionnement par C3. Veuillez attendre que l'opération se termine.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Nom d'affichage",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Courriel",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Prénom",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Instructions",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nom de famille",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Emplacement d'utilisation",
      "USERS_ADD_EDIT_FORM_SEATS":"Droits",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Retour aux utilisateurs",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Ajouter",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Modifier",
      "USERS_ADD_OR_EDIT_CAPTION_3":"Utilisateur",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Courriel",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Prénom",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Nom de famille",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Le dernier chargement groupé pour créer des utilisateurs est terminé!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Impossible d'accorder le privilège d'administration client aux utilisateurs externes",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"Êtes-vous certain de vouloir accorder le privilège admin client à",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Utilisateurs",
      "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Analyse d'intégration",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Votre demande de création d'utilisateurs a été mise en attente. Veuillez revenir sur cette page après un certain moment pour les mises à jour. Le processus prend généralement quelques minutes à compléter.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Une erreur s’est produite lors de l'exportation des détails d'erreur",
      "USERS_BULK_CREATION_FIX_ERRORS":"Veuillez corriger les erreurs reportées dans le fichier qui vient d'être téléchargé et réessayer.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Votre demande de création d'utilisateurs a été effectuée avec succès.",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Obtenir les détails d'utilisateurs chargés",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"Il reste {{AvailableUnits}} sur {{TotalUnits}}",
      "USERS_BUY_MORE":"Acheter plus",
      "USERS_CREATE_SUCCESS":"L'utilisateur {{UserName}} a été créé avec succès",
      "USERS_DELETE_CONFIRM":"Toutes les licences affectées seront supprimées et {{userName}} sera supprimé. Voulez-vous continuer ?",
      "USERS_DELETE_EXISTENCE_CONFIRM":"Cet utilisateur a un accès dans C3 qui sera supprimé. Voulez-vous continuer ?",
      "USERS_DELETE_SUCCESS":"{{username}} a été supprimé avec succès",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Data non trouvée. Veuillez essayer plus tard",
      "USERS_GRANT_ACCESS_CONFIRM":"Êtes-vous certain de vouloir accorder le privilège admin client à {{username}} ?",
      "USERS_GRANT_ACCESS_SUCCESS":"Accorder l'accès a {{username}} avec succès",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Accorder l'accès aux utilisateurs externes n'est pas permis",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"Êtes-vous certain de vouloir ignorer les erreurs ?",
      "USERS_LICENSE_FREE_OUT_OF":"Licences libres de",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Attribution de licences pour l'utilisateur {{username}} en cours. Ce processus pourrait prendre quelques minutes avant de se terminer.",
      "USERS_LICENSES_REVOKE_SUCCESS":"Les licences de {{username}}  ont été révoquées avec succès",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Les licences de {{username}}  ont été révoquées avec succès. L'attribution de nouvelles licences en cours. Ce processus prendrait quelques minutes à compléter.",
      "USERS_LICENSES_UPDATE_SUCCESS":"Les licences pour l'utilisateur {{username}} mises à jour avec succès",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Ajouter un nouvel utilisateur",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Chargement de plusieurs utilisateurs",
      "USERS_LIST_CAPTION_TEXT_USERS":"Utilisateurs",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Modifier les détails utilisateur ",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Accorder l'accès au portail",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Réinitialiser le mot de passe",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Afficher licences",
      "USERS_LIST_TABLE_HEADER_ACTION":"Actions",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Identifiant de courriel",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Est actif",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Aucun rôle d'utilisateur attribué.",
      "USERS_LIST_TABLE_HEADER_ROLE":"Rôle(s) CSP",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Nom d'utilisateur",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Montrer les règles",
      "USERS_PASSWORD_RESET_SUCCESS":"Mot de passe pour {{username}} a été réinitialisé avec succès",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Mot de passe envoyé avec succès aux destinataires spécifiés",
      "USERS_RESETUSERPASSWORD_NEW_PASSWORD":"<br/> le nouveau mot de passe est {{userPasword}}",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Le mot de passe réinitialisé pour",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"La réinitialisation du mot de passe est réussie, le mot de passe est :<à ngclipboard='' data-clipboard-text='{{Password}}' ng-click='vm.confirmCopy()'><b>{{Password}}</b></a> <br/><br/><b>Après avoir quitté cette page, vous ne pouvez plus afficher ce mot de passe temporaire.</b><br/><br/>Si vous souhaitez envoyer le mot de passe par courriel, veuillez indiquer les destinataires séparés par des virgules dans la zone de texte ci-dessous, sinon appuyez sur Annuler.<br/>",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"La réinitialisation du mot de passe a réussi, le mot de passe est :",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Après avoir quitté cette page, vous ne pourrez plus afficher ce mot de passe temporaire.</b><br/><br/>Si vous souhaitez envoyer le mot de passe par e-mail, veuillez indiquer les destinataires séparés par des virgules dans la zone de texte ci-dessous, sinon appuyez sur Annuler.<br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"Êtes-vous certain de vouloir révoquer le privilège {{UserRoleDescription}} pour {{userName}}",
      "USERS_REVOKE_ACCESS_SUCCESS":"L'accès de {{username}} a été révoqué avec succès",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Obtenir les détails d'utilisateurs chargés",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Essayez un nouveau chargement!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Afficher et corriger les erreurs",
      "USERS_STATUS_CAPTION_STATUS":"Statut",
      "USERS_STATUS_WARNING_MESSAGE":"Création d'utilisateurs depuis le dernier chargement est toujours en cours. Veuillez revérifier en cliquant sur le bouton 'TransfertGroupéUtilisateurs' sur la page utilisateurs.",
      "USERS_TABLE_HEADER_EMAIL":"Courriel",
      "USERS_TABLE_HEADER_NAME":"Nom",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "USERS_UPDATE_LICENCES_QUEUED":"Les licences sont mises en file pour la mise à jour",
      "USERS_UPDATE_SUCCESS":"Détails utilisateurs pour {{username}} mis à jour avec succès",
      "USERS_UPDATELICENCES_SUCCESS":"Licences mises à jour avec succès",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Détails utilisateurs pour {{username}} mis à jour avec succès",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Charger",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"Erreurs détectées dans les données chargées. Télécharger les erreurs, corrigez-les et essayez de charger à nouveau.",
      "USERS_UPLOAD_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Supprimer",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Informations Utilisateur",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"Instructions",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Charger les données de compte pour de multiples utilisateurs en format .csv. Télécharger fichier .csv en échantillon <strong><a ng-click='downloadTemplate()'>Télécharger un fichier .csv en échantillon</a></strong> comme référence.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Ce fichier peut être utilisé pour créer plusieurs comptes utilisateurs pour un client. Ne modifier ou supprimer aucun texte existant ou données dans ce fichier. Les comptes utilisateurs seront créés avec des autorisations Utilisateur (pas Administrateur)",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Le prénom est un champ optionnel supportant entre 0 et 50 caractères. Exemple : Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Le nom est un champ optionnel supportant entre 0 et 50 caractères. Exemple: Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Le nom d'affichage est un champ obligatoire qui supporte entre 1 et 50 caractères. Exemple: Chris Green",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Le champ email est obligatoire. Veuillez saisir une adresse email valide dans ce champ pour chaque enregistrement. Exemple : chris@contoso.com ",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Le champ de mise à jour du statut est automatiquement rempli pour indiquer le statut de validation. Laissez ce champ vide.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Charger le fichier et cliquez sur <strong class=\"text-uppercase\">soumettre</strong>",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Téléchargement de fichier",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Emplacement d'utilisation",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Retour aux licences",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Retour aux utilisateurs",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Modifier licences",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Licences attribuées pour",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Date d'attribution de licence ",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Produit",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Résumé",
      "UTILISATEUR SUPPRIMÉ":"Utilisateur supprimé avec succès",
      "VALEUR INVALIDE":"{{atribute}} n'est pas valide",
      "Valid webhook URL":"URL de webhook valide",
      "Validate URL":"Valider l'URL",
      "VALIDATE_LIST":"Valider la liste",
      "VALIDATE_PRODUCT_QUANTITY":"La quantité de {{produit}} {{quantite}} doit être comprise entre {{minQuantity}} et {{maxQuantity}}",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"Échec de la validation de l'historique des abonnements pour le service externe",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Historique d'abonnement validé avec succès pour le service externe",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Client déjà lié à ce domaine.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Ce code promotionnel existe déjà",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Seuls les caractères alphanumériques sont autorisés dans le coupon-rabais !",
      "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Nous n'autorisons les remises qu'entre 0 et 100",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Cet email '{{emailAdresse}}' est déjà utilisé.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Veuillez entrer un nombre valide",
      "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Veuillez remplir tous les champs obligatoires avant de soumettre.",
      "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"La date de la facture doit être antérieure à la date d'échéance.",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Veuillez entrer une URL valide ou entrer l'URL par défaut en tant que '/#/contact/'",
      "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"La date de début doit être antérieure à la date de fin.",
      "VALIDATION_MESSAGE_FOR_URL":"Veuillez entrer une URL valide. ",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Veuillez entrer une URL valide ou entrer l'URL par défaut en tant que 'home.support'",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Veuillez entrer un code postal valide",
      "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"La longueur de l'IIN de l'entreprise doit être inférieure ou égale à 22",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Ce champ accepte un maximum de 50 caractères.",
      "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"La longueur de l'IIN de l'entreprise doit être supérieure à 4",
      "VALIDATION_MESSAGE_MINIMUM_0":"La valeur minimale est 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"La valeur minimale est 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Veuillez entrer un email valide",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Veuillez entrer un numéro de téléphone valide",
      "VALIDATION_MESSAGE_REQUIRED":"Ce champ est obligatoire",
      "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Saisissez un taux de conversion valide",
      "VALIDITY_TEXT_FROM":"De",
      "VALIDITY_TEXT_TO":"À",
      "VALIDITY_TYPE_DESC_DAYS":"Jour(s)",
      "VALIDITY_TYPE_DESC_MONTHS":"Mois",
      "VALIDITY_TYPE_DESC_YEARS":"Année(s)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Type de validité",
      "VALIDITY_TYPE_NAME_DAYS":"Jour(s)",
      "VALIDITY_TYPE_NAME_MONTHS":"Mois",
      "VALIDITY_TYPE_NAME_YEARS":"Année(s)",
      "VALUE_SHOULD_BE_GREATER_THAN":"La valeur doit être supérieure à 0",
      "VAT_NUMBER_DESC":"Numéro de TVA du client",
      "VAT_NUMBER_DESC_FOR_RESELLER":"Numéro de TVA du revendeur",
      "VAT_NUMBER_DISP":"numéro de TVA",
      "VAT_NUMBER_DISP_FOR_RESELLER":"numéro de TVA",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Valider le jeton",
      "VIEW_ALL_SELECTION":"Sélectionner tout",
      "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Afficher plus de détails",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Afficher les éléments de ligne d'utilisation",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Afficher les éléments de ligne d'utilisation",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Générer une facture",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Recharger le rapport de profitabilité",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Ajouter un utilisateur",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Supprimer un utilisateur",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Nom",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Affecter des balises",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Modifier les rôles",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Ajouter un utilisateur Partner",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Affecter des balises",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Supprimer Partner",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Modifier les rôles",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Gestion des utilisateurs Partner",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Gestion des utilisateurs",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Rôles",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"Rôles",
      "viewuploadhistory":"Utilisation du rapport",
      "WAITING_FOR_APPROVAL":"en attente d'approbation",
      "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":"Vous avez désélectionné tous les",
      "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":"Vous avez sélectionné tous les",
      "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Entrez un nom d'entité",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "WEBHHOOK_LABEL_EFFECTIVE_TO":"Efficace pour",
      "WEBHHOOK_LABEL_ENTITY":"Entité",
      "WEBHHOOK_LABEL_EVENT":"Événement",
      "Webhook":"Webhook",
      "Webhook method":"Méthode Webhook",
      "Webhook Notification":"Notification de webhook",
      "Webhook notification created successfully":"Notification Webhook créée avec succès",
      "Webhook notification deleted successfully":"La notification Webhook a été supprimée avec succès",
      "Webhook notification sent successfully":"Notification Webhook envoyée avec succès",
      "Webhook notification updated successfully":"La notification Webhook a été mise à jour avec succès",
      "Webhook URL":"URL du webhook",
      "Webhook URL is valid":"L'URL du webhook est valide",
      "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Entités marquées",
      "WEBHOOK_BREADCRUMB_ADD":"Créer un webhook",
      "WEBHOOK_BUTTON_ACTIONS_ADD":"Créer une notification de webhook",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "WEBHOOK_CANCEL_NOTIFICATION":"Annuler",
      "WEBHOOK_DESCRIPTION_TOOLTIP":"Entrez la description",
      "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Choisir en vigueur à ce jour",
      "WEBHOOK_END_DATE_PLACEHOLER":"Date de fin",
      "WEBHOOK_ENTITY_TOOLTIP":"Choisir une catégorie",
      "WEBHOOK_EVENT_TOOLTIP":"Choisir un événement",
      "WEBHOOK_HEADER_ACTIONS":"Actions",
      "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Entités associées",
      "WEBHOOK_HEADER_CREATET_DATE":"Créer un rendez-vous",
      "WEBHOOK_HEADER_DESCRIPTION":"Description",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "WEBHOOK_HEADER_EFFECTIVE_TO":"Efficace pour",
      "WEBHOOK_HEADER_EVENTNAME":"Événement",
      "WEBHOOK_HEADER_TITLE":"Titre",
      "WEBHOOK_LABEL_DESCRIPTION":"Description",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Effectif pour",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Catégorie",
      "WEBHOOK_LABEL_EVENT_TEXT":"Événement",
      "WEBHOOK_LABEL_TITLE_TEXT":"Titre",
      "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"Méthode Webhook",
      "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Nombre de tentatives",
      "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"URL du webhook",
      "WEBHOOK_METHOD_METHOD_TOOLTIP":" Méthode à utiliser lors de l’envoi de données à un système externe via un webhook.",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Une erreur s'est produite lors de l'envoi de la notification du webhook",
      "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Notification Webhook envoyée avec succès",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Description",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"Identifiant",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Ancien nombre de tentatives",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"Ancienne URL du webhook",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Nom de produit",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Nombre de tentatives",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Titre",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"URL du webhook",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"Méthode Webhook",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Un seul produit peut être sélectionné.",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Erreur lors de la sélection du produit",
      "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notification Webhook créée avec succès",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Tous les produits ont été sélectionnés",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"Le produit a été sélectionné.",
      "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Éléments d'entité associés",
      "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"La notification Webhook a été mise à jour avec succès",
      "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Tous les produits ont été sélectionnés.",
      "WEBHOOK_SAVE_NOTIFICATION":"Soumettre",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"Sélectionner",
      "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Sélectionnez l'élément d'entité associé",
      "WEBHOOK_START_DATE_PLACEHOLER":"Date de début",
      "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Notification de webhook",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Cela dépasse la longueur maximale de 30",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Veuillez vous assurer que la valeur est comprise entre 0 et 3",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "WEBHOOK_TITLE_TOOLTIP":"Entrez le titre",
      "WEBHOOK_URL_TOOLTIP":" Saisissez l'URL de destination à laquelle le système enverra des requêtes HTTP pour informer un service externe d'événements ou de mises à jour spécifiques. Cette URL doit être capable de recevoir et de traiter les charges utiles des webhooks.",
      "WEBHOOK_VALIDATE_BUTTON":"Valider",
      "WEBHOOK_VALIDATE_URL":"Veuillez valider l'URL",
      "WEBHOOK_VALIDATE_URL_BUTTON":"Valider l'URL",
      "WEBHOOK_VALIDATION_ERROR_MESSAGE":"URL du webhook non valide",
      "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"L'URL du webhook est valide",
      "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Nombre de tentatives que le système effectuera pour effectuer une action spécifique en cas d'échec.",
      "WEBHOOK_WEBHOOK_URL_TOOLTIP":"URL du webhook",
      "webhookNotifications":"Webhook",
      "WELCOME_HEADER_1_PART_1":"Avec nous, vos possibilités sont infinies. ",
      "WELCOME_HEADER_1_PART_2":"Et ça commence maintenant.",
      "WELCOME_PORTAL_FEATUR_1":"Portail libre-service",
      "WELCOME_PORTAL_FEATUR_2":"Ajouter/retirer des abonnements rapidement et facilement",
      "WELCOME_PORTAL_FEATUR_3":"Paiements automatisés",
      "WELCOME_PORTAL_FEATUR_4":"Payer automatiquement les factures ",
      "WELCOME_PORTAL_FEATUR_5":"Garantir",
      "WELCOME_PORTAL_FEATUR_6":"Sécurisé avec Microsoft Azure Active Directory et l'authentification Multi-facteurs ",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"S'identifier",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Bienvenue",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Se déconnecter",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Contact",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Aller au tableau de bord",
      "Won''t Fix":"Ne peut être corrigé",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Merci d'avoir fourni votre consentement pour Xero. L'application peut être configurée pour accéder à Xero suivant la nouvelle directive de sécurité. Vous pouvez publier les factures de vos clients sur Xero.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions pour Charger les factures sur Xero dans <b>{{applicationName}}</b>.",
      "XERO_CONSENT_DESCRIPTION":"Pour publier des factures sur Xero, vous devez fournir votre consentement.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Le lien ci-dessous s'ouvre dans un nouvel onglet. Veuillez fournir votre consentement avec vos identifiants Xero. Si une erreur se produit lors de votre consentement, veuillez contacter notre équipe d'assistance.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"pour fournir le consentement à Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Si vous avez déjà fourni le consentement, veuillez cliquer sur le bouton <b> Valider le jeton </b> pour vérifier la connectivité avec Xero.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquez sur ce bouton pour vérifier la validité du jeton d'actualisation Xero existant.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Conditions préalables pour que le partenaire publie les factures des clients dans <b> XERO </b>",
      "XERO_POST_BATCH_ID":"Identifiant de lot de publication Xero",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Votre demande d'envoi de factures à Xero est acceptée et mise en file d'attente pour traitement. Veuillez cliquer sur le bouton <strong>Afficher les journaux de publication</strong> pour obtenir des mises à jour sur les progrès.",
      "XERO_POST_LOGS_STATUS":"Statut des journaux de publication Xero",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Le jeton d'actualisation Xero existant n'est pas valide. Veuillez fournir à nouveau votre consentement",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Le jeton d'actualisation Xero existant est valide. Vous pouvez continuer à charger des factures",
      "XERO_TENANT_ID":"Identifiant de domaine Xero",
      "Year(s)":"Année(s)",
      "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Vous pouvez spécifier le pourcentage de taxe pour le client. Par exemple : 15 pour 15 %",
      "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Vous pouvez spécifier le nom de la taxe comme la TPS..",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Vous n'êtes pas autorisé à effectuer cette action",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Vous devez sélectionner une ou plusieurs offres pour créer un abonnement"
    }, 
      "academic":"Académique",
      "Academic":"Académique",
      "ACCEPT_BUTTON_INFO":"En cliquant sur le bouton Accepter, vous acceptez les conditions d'utilisation ci-dessus.",
      "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
      "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Accepter ou refuser le consentement au renouvellement de l'offre du partenaire",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"accès au centre partenaire à l'aide des nouveaux paramètres de sécurité.",
      "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Accès aux API de gestion Microsoft Azure.",
      "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Accès à l'API de tarification Microsoft.",
      "ACCEPTANCE_STATUS":"Statut d'acceptation",
      "ACCESS_TOKEN":"Jeton d'accès",
      "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"Gestionnaire de compte créé avec succès",
      "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Gestionnaire de compte supprimé avec succès",
      "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"Êtes-vous sûr de vouloir supprimer le gestionnaire de compte ?",
      "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"Le gestionnaire de compte est attribué au client/revendeur, êtes-vous sûr, voulez-vous supprimer le gestionnaire de compte.",
      "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Recherche par identifiant de gestionnaire de compte",
      "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Recherche par adresse courriel",
      "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Recherche par prénom",
      "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Recherche par nom de famille",
      "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Recherche par numéro de téléphone",
      "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Fermer les filtres",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"Adresse courriel",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Réinitialiser",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Rechercher",
      "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Les détails du gestionnaire de compte sont mis à jour avec succès",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter un gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier le gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Courriel",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Ajouter un gestionnaire de compte",
      "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Gestionnaires de comptes",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"Identifiant",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Actions",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Date créée",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"Adresse courriel",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Prénom",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Nom de famille",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Numéro de téléphone",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer le gestionnaire de compte",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Modifier les détails",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Afficher les clients affectés",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Numéro de compte",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"Type de compte",
      "ACH_FORM_LABEL_BANK_NAME":"Nom de banque",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"Est un compte d’entreprise",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Nom de compte",
      "ACH_FORM_LABEL_NO":"Non",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Numéro d'acheminement",
      "ACH_FORM_LABEL_YES":"Oui",
      "ACTIONS_TO_FIX":"Veuillez contacter l'administrateur pour obtenir du support",
      "ACTIONS_TO_FIX_500":"Nous travaillons fort afin de régler le problème, veuillez vérifier dans un moment",
      "ACTIVATE_CONFORMATION":"Êtes-vous sûr de vouloir activer ?",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Sélectionner un client",
      "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Toutes les actions--",
      "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Affichage des journaux de",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Tous les clients",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Tous les domaines.",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Notifications",
      "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Recommencez",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Date de fin",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Actions",
      "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Méthode de contact",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Nom de l'événement",
      "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Date envoyée",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Statut",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Sujet",
      "ACTIVITY_LOGS_HEADER_TITLE_TO":"À",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Événement",
      "ACTIVITY_LOGS_TEXT_BCC":"Cci",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Date de début",
      "ACTIVITY_LOGS_TEXT_FAILED":"Échoué",
      "ACTIVITY_LOGS_TEXT_FROM":"De",
      "ACTIVITY_LOGS_TEXT_ON":"Activé",
      "ACTIVITY_LOGS_TEXT_STATUS":"Statut",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"Réussite ",
      "ACTIVITY_LOGS_TEXT_TO":"À",
      "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"Afficher l'e-mail",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Notifications",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Ajouter un ajustement",
      "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"Le nom de domaine n'est pas disponible",
      "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"Le nom de domaine n'est pas disponible",
      "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ajouter des offres partenaires",
      "ADD_CUSTOM_ROLE":"ajouter un rôle personnalisé",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Le client a accepté la dernière",
      "ADD_CUSTOMER_IN_QUOTE":"Ajouter un client",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"Pays",
      "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Veuillez entrer un code postal.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Veuillez entrer l'adresse 1.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":"Entrez l'adresse 2.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Veuillez entrer la ville.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Veuillez saisir le nom de l'entreprise.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"S'il vous plaît entrer votre adresse mail.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Veuillez entrer le prénom.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Veuillez entrer le nom de famille.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Veuillez entrer le numéro de téléphone.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Veuillez sélectionner un pays.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Veuillez sélectionner un code de devise.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Veuillez sélectionner un Plan.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Veuillez sélectionner un état.",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Ajouter des clients",
      "ADD_DUE_DATE":"Ajouter une date d'échéance",
      "ADD_ENTITLEMENT":"Ajouter un droit",
      "ADD_ENTITLEMENT_DESCRIPTION":"Veuillez fournir un nom convivial pour l'abonnement.",
      "ADD_ENTITLEMENT_FAILED":"Échec de l'ajout d'un droit",
      "ADD_ENTITLEMENT_SUCCESS":"Le droit est ajouté avec succès dans Partner Center veuillez patienter 15 minutes pour le provisionnement dans C3",
      "ADD_MACRO_VALUE_ERROR":"La valeur de la macro est requise",
      "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Créer un nouveau niveau",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!Valeur max) ? \"Veuillez saisir une valeur supérieure à \" + minValue : \"Veuillez saisir une valeur comprise entre \" + minValue + \" et \" + maxValue}}",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Veuillez saisir une valeur comprise entre {{Min}} et {{Max}}",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Annuler",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Soumettre",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Terminé",
      "ADD_MISSING_OFFERS_IN_PROGRESS":"Le plan de mise à jour avec les dernières offres est déjà en cours",
      "ADD_MISSING_OFFERS_INITIAT_FAIL":"Échec du plan de mise à jour avec le lancement des dernières offres",
      "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Le plan de mise à jour avec les dernières offres a été lancé",
      "ADD_MISSING_OFFERS_STATUS":"Progression de la mise à jour du plan",
      "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Ajouter les offres manquantes au forfait existant",
      "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Ajouter des offres manquantes à plusieurs forfaits",
      "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Ajouter les offres manquantes au forfait unique",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Voulez-vous autoriser l'accès au contact principal (en tant qu'administrateur client) ?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Voulez-vous envoyer une invitation pour accéder à l'application ?",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "ADD_NEW_QUOTE":"Créer un devis",
      "ADD_NEW_USER_IN_QUOTE":"Ajouter un nouvel utilisateur",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Sélectionner",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Ajouter des détails de base du plan",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Description",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Devise de facturation",
      "ADD_PLAN_LABEL_TEXT_NAME":"Nom",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Devise d'achat",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Afficher les détails du contrat",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Actions",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Macro",
      "ADD_PO_NUMBER":"Ajouter BDC #",
      "ADD_PONUMBER":"Ajouter un numéro de bon de commande",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Il est recommandé d'utiliser l'option <b>Fournisseur de lien</b> pour fournir l'ID MPN du revendeur et aider le revendeur à intégrer éventuellement les clients.",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"Recommandé! Définir l'ID MPN après avoir ajouté le revendeur",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Diviseur de blocs",
      "ADD_TO_CART_INFO":"Ce produit est lié avec <b>{{linkedProductName}}</b>, si vous ajoutez ce produit, le lien ajoutera également ",
      "addCustomer":"Ajouter un client",
      "ADDONS_LEBEL_TEXT":"Extensions",
      "ADDONS_PRODUCT_LEBEL_TEXT":"Produit complémentaire",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Actions",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Ligne d'adresse 1 ",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Ligne d'adresse 2 ",
      "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Type d'adresse",
      "ADDRESS_TABLE_HEADER_CITY":"Ville",
      "ADDRESS_TABLE_HEADER_COUNTRY":"Pays",
      "ADDRESS_TABLE_HEADER_STATE":"État",
      "ADDRESS_TABLE_HEADER_ZIP":"Code postal",
      "ADDRESS_TYPE_BILLING":"Facturation",
      "ADDRESS_TYPE_COMPANY":"Entreprise",
      "ADDRESS_TYPE_INVOICE":"Facture",
      "ADDRESS_TYPE_SHIPPING":"Expédition",
      "AGO":"Passé(s)",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Copié dans le presse-papiers",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"La facture a été approuvée avec succès.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"La facture a été finalisée avec succès.",
      "ALERT_MESSAGE_MISSING_MPNID":"Un problème est survenu lors de la copie de l'URl. Veuillez vous connecter au partenaire.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Veuillez sélectionner au moins une facture",
      "ALL_CART_ITEM_COMMENT":"Article du panier",
      "ALL_COMMENTS":"Commentaires",
      "ALL_PRODUCT_COMMENTS":"Produit",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Veuillez contacter votre administrateur pour la suppression des licences.",
      "AN_ERROR_OCCURED":"une erreur s’est produite pendant le traitement de votre demande",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Historique de facturation Azure ",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Sommaire de facturation Azure",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Utilisation Azure du client  ",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Revenus par client",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Les abonnements par client",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Les abonnements par plans",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"historique des abonnements",
      "AND":"et",
      "APP_CONFIG_AzureAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_AzureAD_PRICING_APPLICATION_RESOUNCE_URL":"URL de ressouNCE d'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de ressource d'application de tarification Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Veuillez saisir la couleur hexadécimale du bouton de la page d'accueil",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentement de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_AzureAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"ID d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de ressource d'application de tarification Azure AD",
      "APP_CONFIG_DISP_AzureAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Clé secrète de l'application de tarification Azure AD",
      "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Couleur du bouton de la page d'accueil",
      "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Bannière d'inscription publique",
      "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Logo d'inscription publique",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Autoriser le téléchargement de l'utilisation",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Lorsqu'elle est activée, cette configuration augmentera les coûts d'utilisation mensuels encourus pour les produits qui ont une consommation d'utilisation. Les types de produits incluront Azure-Plan et Custom-Usage.",
      "APPCONFIG_AUTOTASK_BILLING_CODE":"Code de facturation",
      "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Ce code de facturation sera utilisé pour créer des produits dans Autotask",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Est le mappage automatique (Autotask)",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Si vous souhaitez mapper manuellement entre les entités C3 et Autotask, laissez cette option décochée. Cependant, si le mappage automatique est activé, les entités sont mises en correspondance en comparant les noms des entités dans C3 avec les entités dans Autotask",
      "APPCONFIG_AUTOTASK_PASSWORD":"Mot de passe",
      "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Mot de passe appartenant à l'utilisateur de l'API",
      "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Prorata",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Fréquence de téléchargement automatique",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Définir la fréquence de téléchargement automatique",
      "APPCONFIG_AUTOTASK_USERNAME":"Nom d'utilisateur",
      "APPCONFIG_AUTOTASK_USERNAME_DESC":"Nom d'utilisateur de l'utilisateur de l'API créé dans Autotask",
      "APPCONFIG_DESC_ACCOUNT_NAME":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_ACCOUNT_NUMBER":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Adresse 1 qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Adresse 2 qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_ADVANCE_TEXT":"Texte personnalisé de la ligne avancée",
      "APPCONFIG_DESC_API_URL":"URL de l'API de base pour la gestion connectée",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Activer le suivi des licences",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Afficher l'analyse",
      "APPCONFIG_DESC_APPLICATION_NAME":"Nom de l'application",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Fin du cycle de facturation pour le partenaire dans le centre partenaire",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"Identifiant d'offre CSP Azure",
      "APPCONFIG_DESC_BANK_NAME":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_BILLING_CYCLE":"Aligné avec le centre partenaire ou personnalisé",
      "APPCONFIG_DESC_BILLING_DATE":"Applicable lorsque le cycle de facturation est personnalisé",
      "APPCONFIG_DESC_BILLING_PERIOD":"Période de facturation (déterminée à l'aide d'autres paramètres)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"Fournisseur de facturation pour le portail C3",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Configuration du fournisseur de facturation",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Début du cycle de facturation. Applicable pour le cycle de facturation est personnalisé",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Apparaît sur la vue facture et le PDF facture dans la section « Facturer de »",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Apparaît sur la vue facture et le PDF facture dans la section « Facturer de »",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Vérifier les rôles par rapport à 0365",
      "APPCONFIG_DESC_CIN_NUMBER":"CIN (Numéro d'identification d'entreprise) qui apparaît sur la facture pdf",
      "APPCONFIG_DESC_CITY":"Ville qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_CLIENT_ID":"ClientId pour ConnectWise Manage",
      "APPCONFIG_DESC_COMPANY_NAME":"Nom de l'entreprise qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_COMPANY_URL":"URL de votre site Web",
      "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Nom de l'entreprise pour Connectwise Manage",
      "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Tenir compte de la date de génération dans le rapport de chiffre d'affaires",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Nom qui devrait apparaître sur la page d'accueil ainsi que le pied de page après qu'un utilisateur est connecté",
      "APPCONFIG_DESC_CONTACT_EMAIL":"Courriel de contact (généralement vos informations ou courriel de support)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Prénom de la personne-ressource pour votre demande C3",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"Nom de famille de la personne-ressource pour votre application C3",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Numéro de téléphone de la personne-ressource",
      "APPCONFIG_DESC_COUNTRY":"Pays qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Code de pays",
      "APPCONFIG_DESC_CURRENCY_CODE":"Code de devise",
      "APPCONFIG_DESC_CUSTOM_BILLING":"Appliquer la facturation personnalisée",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL de consentement du client",
      "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Restreindre le client lors de l'intégration sous plusieurs entités",
      "APPCONFIG_DESC_DATE_FORMAT":"Format de la date",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Format Date/Heure",
      "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Utiliser pour définir un format de date pour la valeur du type de date dans les API C3",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"URL cible pour la page, si vous souhaitez avoir une page de destination personnalisée.       Remarque : dans la page d'accueil, vous devriez retrouver un bouton et l'URL cible de ce bouton devrait être <C3Site URL>/#/login",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Langue par défaut pour le site Web",
      "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Définit le nombre de pages par défaut pour chaque table du système.",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Termes et conditions pour commander des produits",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"URL des conditions générales (texte)",
      "APPCONFIG_DESC_EMAIL_LOGO":"Logo qui apparaît sur les courriels envoyé par C3. La dimension préférée est de 300x100.",
      "APPCONFIG_DESC_ENABLE_TOUR":"Activez le bouton « Faire un tour » dans l'en-tête du site.",
      "APPCONFIG_DESC_FAVICON_LOGO":"Un favicon est une petite icône visible sur l'onglet du navigateur Web, juste avant le titre de la page. Veuillez télécharger un fichier .ico de 16x16",
      "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Devrait-on considérer l'ID MPN des revendeurs pour les clients et les achats ?",
      "APPCONFIG_DESC_IFSC_CODE":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Apparaît sur la vue facture et la facture pdf en tant que ligne de pied de page autorisée",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Apparaît sur la vue facture et la facture pdf en tant que ligne de pied de page",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Jours de génération de factures",
      "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Chaîne de connexion au stockage cloud",
      "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Jours de validité du code postal de la facture",
      "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Lorsque cette fonctionnalité est activée, le partenaire recevra les demandes d'approbation approuvées par le revendeur.",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Lien vers la page Nous contacter (il suffit de taper /#/contact si vous n'en avez pas)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Lien vers la FAQ client (il suffit de le laisser vide, si vous voulez utiliser celui par défaut)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Lien vers le portail",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Lien vers la page Soutien (il suffit de taper home.support si vous n'en avez pas)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Lien vers le logo de bienvenue",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Le logo qui apparaît en haut à gauche après que les utilisateurs sont connectés à C3. La dimension préférée est 170x40. La page a un fond noir de sorte que le graphique devrait dans une couleur qui contraste avec le noir.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Connexion au portail",
      "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"CustomerId obligatoire dans les appels API",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Nombre maximal d'entrées à exporter vers Excel",
      "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Nombre maximal de créations de droits",
      "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Nombre maximal de créations de locataires",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Rappel de consentement de sécurité Microsoft en jours",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Montant minimal des frais pour les paiements Test",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"Termes et conditions NCE pour commander les produits Microsoft NCE",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL des termes  et conditions des NCE (texte)",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Active la fonctionnalité de recherche client déjà présent dans la facturation",
      "APPCONFIG_DESC_PAN_NUMBER":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Identifiant d'application à partir d'une application native dans votre centre partenaire. Vous pouvez l'obtenir en naviguant vers Gestion des applications -> Application native",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"Devise du partenaire",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Mot de passe de l'agent admin",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Langues de préférence du partenaire",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"Identifiant du domaine partenaire",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Nom d'utilisateur du compte auquel le rôle d'administrateur d'agent est affecté",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL du contrat de revendeur Microsoft Cloud",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL des conditions générales du partenaire",
      "APPCONFIG_DESC_PBI_GROUP_ID":"Identifiant du groupe Power BI",
      "APPCONFIG_DESC_PBI_PASSWORD":"Mot de passe Power BI",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Identifiant du rapport Power BI",
      "APPCONFIG_DESC_PBI_USER_NAME":"Nom d'utilisateur Power BI",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"Identifiant du client Power BI",
      "APPCONFIG_DESC_PDF_LOGO":"Logo devant figurer sur le pdf de votre candidature C3. La dimension préférée est 186x32. La page a un arrière-plan blanc, le texte ou le graphique doit donc être de n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logo qui devrait apparaître sur le pdf à droite. La dimension préférée est 186x32. La page a un arrière-plan blanc, le texte ou le graphique doit donc être de n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_POSTAL_CODE":"Le code postal qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Nom qui devrait apparaître sur la page d'accueil ainsi que le pied de page après qu'un utilisateur est connecté",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"URL de votre site Web",
      "APPCONFIG_DESC_PRIVATE_KEY":"Clé privée pour ConnectWise Manage",
      "APPCONFIG_DESC_PUBLIC_KEY":"Clé publique pour \"ConnectWise Manage\"",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"Identifiant client de l'application QuickBooks",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"Clé secrète du client de l'application QuickBooks",
      "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Incompatibilité de reconnaissance sur les destinataires des e-mails de génération de facture initiale",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logo qui apparaît sur la vue Rapport de rapprochement à droite du logo Microsoft. La dimension préférée est 170x40. La page a un fond blanc, de sorte que le texte ou le graphique doit être dans n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Ajouter les destinataires en tant que valeurs séparées par des virgules",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Devrait informer le client",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Suspendre l'abonnement lorsque le seuil de danger est atteint",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Afficher le menu de rapports Power BI",
      "APPCONFIG_DESC_SHOW_FOOTER":"L'activation de ce paramètre affichera le pied de page sur toutes les pages",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Afficher l'étape suivante",
      "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Activez cette option pour afficher les conditions générales de gestion des abonnements",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP Activer SSL",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"SMTP à partir d'un courriel",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"À partir du nom d'affichage",
      "APPCONFIG_DESC_SMTP_HOST":"Hôte SMTP",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Mot de passe",
      "APPCONFIG_DESC_SMTP_PORT":"Port",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"Préfixe de sujet SMTP",
      "APPCONFIG_DESC_SMTP_USERNAME":"Nom d'utilisateur SMTP",
      "APPCONFIG_DESC_STATE":"L'état qui apparaît sur la vue facture et la facture en format pdf.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DESC_TAX_TYPE_KEY":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_TAX_TYPE_VALUE":"Apparaît sur la facture pdf",
      "APPCONFIG_DESC_WELCOME_LOGO":"Logo qui devrait apparaître sur la page d'accueil de votre application C3. La dimension préférée est 186x32. La page a un fond blanc, de sorte que le texte ou le graphique doit être dans n'importe quelle couleur qui contraste avec le blanc.",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Code de compte de l'application Xero pour les éléments de ligne d'ajustement",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_Azure":"Code de compte d'application Xero pour Azure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Code de compte d'application Xero pour Azure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Code de compte d'application Xero pour les offres personnalisées",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Code de compte d'application Xero pour les licences Microsoft",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Code de compte de l'application Xero pour les éléments de ligne uniques",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Code de compte de l'application Xero pour les éléments de ligne des instances réservées",
      "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Nom du thème de marque de l'application Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"Identifiant client de l'application Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Clé secrète du client de l'application Xero",
      "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"La valeur décimale à laquelle les montants postés à Xero sont arrondis à",
      "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Description de l'ajout et format de description de la facture",
      "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Date Culture pour les dates de ConnectWise Manage ",
      "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Envisagez ConnectWise Manage les prix au lieu des prix C3",
      "APPCONFIG_DISC_MAPPING_TYPE":"Sélectionnez le type de mappage pour mapper les entités entre C3 et le service externe",
      "APPCONFIG_DISP_ACCOUNT_NAME":"Nom du compte",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Numéro de compte",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Adresse 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Adresse 2",
      "APPCONFIG_DISP_ADVANCE_TEXT":"Texte personnalisé de la ligne avancée",
      "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Appconfig disp texte avancé pour annuel",
      "APPCONFIG_DISP_API_URL":"URL de l'API",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Activer le suivi des licences",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Afficher l'analyse",
      "APPCONFIG_DISP_APPLICATION_NAME":"Nom de l'application",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Date de génération de facture Microsoft",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"Identifiant d'offre CSP Azure",
      "APPCONFIG_DISP_BANK_NAME":"Nom de banque",
      "APPCONFIG_DISP_BILLING_CYCLE":"Cycle de facturation",
      "APPCONFIG_DISP_BILLING_DATE":"Date à laquelle les factures sont générées dans C3",
      "APPCONFIG_DISP_BILLING_PERIOD":"Période de facturation",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Fournisseur de facturation",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Configuration du fournisseur de facturation",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Date de début de la facturation",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"Numéro d'entreprise",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Clé de numéro d'entreprise",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Vérifier les rôles par rapport à 0365",
      "APPCONFIG_DISP_CIN_NUMBER":"Numéro CIN",
      "APPCONFIG_DISP_CITY":"Ville",
      "APPCONFIG_DISP_CLIENT_ID":"ClientId",
      "APPCONFIG_DISP_COMPANY_NAME":"Nom de l'entreprise",
      "APPCONFIG_DISP_COMPANY_URL":"URL de l'entreprise",
      "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Description de l'ajout",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Nom de l'entreprise",
      "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Culture des Dattes",
      "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Considérez ConnectWise Manage les prix",
      "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Tenir compte de la date de génération dans le rapport de chiffre d'affaires",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Nom de l'entreprise de contact",
      "APPCONFIG_DISP_CONTACT_EMAIL":"Courriel de contact",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Prénom du contact",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"Nom de famille du contact",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Numéro de téléphone du contact",
      "APPCONFIG_DISP_COUNTRY":"Pays",
      "APPCONFIG_DISP_COUNTRY_CODE":"Code de pays",
      "APPCONFIG_DISP_CURRENCY_CODE":"Code de devise",
      "APPCONFIG_DISP_CUSTOM_BILLING":"Appliquer la facturation personnalisée",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL de consentement du client",
      "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Restreindre le client lors de l'intégration sous plusieurs entités",
      "APPCONFIG_DISP_DATE_FORMAT":"Format de la date",
      "APPCONFIG_DISP_DATE_TIME_FORMAT":"Format Date/Heure",
      "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Format de date et d'heure pour les API C3",
      "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Jours pour envoyer une notification d'état du panier",
      "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Nombre de jours pour stocker le fichier de factures groupées dans le stockage cloud",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"URL de page d'accueil personnalisée",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Langue par défaut",
      "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Nombre de pages par défaut",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"URL des conditions générales (texte)",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL des conditions générales",
      "APPCONFIG_DISP_EMAIL_LOGO":"Envoyer le logo par courriel",
      "APPCONFIG_DISP_ENABLE_TOUR":"Activer la visite",
      "APPCONFIG_DISP_FAVICON_LOGO":"Logo favori",
      "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Devrait-on considérer l'ID MPN des revendeurs pour les clients et les achats ?",
      "APPCONFIG_DISP_IFSC_CODE":"Code IFSC",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Ligne autorisée de pied de page de facture",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Ligne de pied de page de facture",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Jours de génération de factures",
      "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Chaîne de connexion au stockage cloud",
      "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Jours de validité du code postal de la facture",
      "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Approbation du partenaire requise",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AzureSEARCH":"Catalogue public soutenu par la recherche Azure",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Catalogue public soutenu par la recherche Azure",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Lien vers la page Nous contacter",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Lien vers la FAQ client",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Lien vers le portail",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Lien vers le soutien",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Lien vers le logo de bienvenue",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Logo connecté",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Connexion au portail",
      "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":"CustomerId obligatoire dans les appels API",
      "APPCONFIG_DISP_MAPPING_TYPE":"Type de cartographie",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Nombre maximal d'entrées à exporter",
      "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Nombre maximal de créations de droits",
      "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Nombre maximal de créations de locataires",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Rappel de consentement de sécurité Microsoft en jours",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Montant minimal des frais",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL des termes  et conditions des NCE (texte)",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL des termes et conditions du NCE",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Recherche de client intégré avec facturation",
      "APPCONFIG_DISP_PAN_NUMBER":"Numéro de casserole",
      "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"Clé numérique PAN",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Identifiant d'application native",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"Devise du partenaire",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Mot de passe de l'agent admin",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Groupes de langues",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"Identifiant du domaine partenaire",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Nom d'utilisateur de l'agent admin",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL du contrat de revendeur Microsoft Cloud",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL des conditions générales du partenaire",
      "APPCONFIG_DISP_PBI_GROUP_ID":"Identifiant du groupe Power BI",
      "APPCONFIG_DISP_PBI_PASSWORD":"Mot de passe Power BI",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Identifiant du rapport Power BI",
      "APPCONFIG_DISP_PBI_USER_NAME":"Nom d'utilisateur Power BI",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"Identifiant du client Power BI",
      "APPCONFIG_DISP_PDF_LOGO":"Logo PDF",
      "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"Logo du côté droit du PDF",
      "APPCONFIG_DISP_POSTAL_CODE":"Code postal",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Propulsé par Nom de l'entreprise",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Propulsé par l'URL de l'entreprise",
      "APPCONFIG_DISP_PRIVATE_KEY":"Clé privée",
      "APPCONFIG_DISP_PUBLIC_KEY":"Clé publique",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks ClientId",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks ClientSecret",
      "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Incompatibilité de reconnaissance sur les destinataires des e-mails de génération de facture initiale",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Logo du rapport de rapprochement",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Destinataire de la notification du rapport de rapprochement",
      "APPCONFIG_DISP_REFUND_TEXT":"Texte de remboursement distribué par Appconfig",
      "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Appconfig diffuse le texte de remboursement pour l'année",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Suspendre l'abonnement lorsque le seuil de danger est atteint",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Afficher le menu de rapports Power BI",
      "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Permet d'obtenir le numéro de facture externe dans le rapport récapitulatif des factures",
      "APPCONFIG_DISP_SHOW_FOOTER":"Afficher le pied de page",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Afficher l'étape suivante",
      "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Activer les conditions générales pour la gestion des abonnements",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"Activer SSL",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"À partir du courriel",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"À partir du nom d'affichage",
      "APPCONFIG_DISP_SMTP_HOST":"Hôte",
      "APPCONFIG_DISP_SMTP_PASSWORD":"Mot de passe SMTP",
      "APPCONFIG_DISP_SMTP_PORT":"Port SMTP",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Préfixe du sujet",
      "APPCONFIG_DISP_SMTP_USERNAME":"Nom d'utilisateur",
      "APPCONFIG_DISP_STATE":"État",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Type de taxe",
      "APPCONFIG_DISP_TAX_TYPE_VALUE":"Valeur fiscale",
      "APPCONFIG_DISP_WELCOME_LOGO":"Logo de bienvenue",
      "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Page d'accueil",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Code de compte Xero pour les ajustements",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Code de compte Xero pour OneTime",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Code de compte Xero pour les instances réservées",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
      "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xéro points décimaux",
      "APPCONFIG_SELECTED_SMTP_OPTION":"Sélectionnez le type SMTP",
      "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Sélectionnez l'option SMTP",
      "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Afficher le numéro de facture externe dans le rapport récapitulatif des factures",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"Identifiant de l'application d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"ID client de l’application Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Mot de passe de l'application d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Clé secrète client de l'application Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Authentification moderne à partir du courrier électronique",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"SMTP à partir d'un courriel",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"ID de locataire d'authentification moderne",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"Identifiant du locataire du compte utilisateur qui sera utilisé pour envoyer des e-mails",
      "APPEND_CUSTOM_TRIAL_DAYS":"(Période d'essai)",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":"La limite restante après approbation de cette demande sera",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" La limite restante actuelle sera mise à jour après un renouvellement réussi.",
      "approvals":"Approbations",
      "APPROVED_AND_INITIATED":"Approuvé et initié",
      "APPROVED_BY":"par distributeur",
      "APRIL":"Avril",
      "ASSIGN_BUNDLE_PRODUCTS":"Affecter des produits groupés",
      "ASSIGN_CUSTOMER_PLAN":"Affecter un plan client",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Voulez-vous vraiment Affecter {{customert}} à {{accountManager}} ?",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le client a été affecté avec succès",
      "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le revendeur a été affecté avec succès",
      "ASSIGN_USAGE_PRODUCTS":"Affecter des produits d'utilisation",
      "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Client affecté au gestionnaire de compte avec succès",
      "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Revendeur affecté au gestionnaire de compte avec succès",
      "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Je comprends que chaque personne utilisant Windows 365 Business avec Windows Hybrid Benefit doit également disposer d'une copie valide de Windows 10/11 Pro installée sur son appareil de travail principal.",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Fermer",
      "AUDIT_LOG_DETAIL":"Détails",
      "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Masquer les détails",
      "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Plus de détails",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Dissimulé",
      "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Toutes les actions--",
      "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Tous les clients--",
      "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Tous les domaines--",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Journal d'audits",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Rechercher",
      "AUDITLOG_TEXT_ACTION_NAME":"Actions",
      "AUDITLOG_TEXT_AUDITLOG":"Journal d'audits",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"Nom du client",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"En vigueur à partir de",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Effectif pour",
      "AUDITLOG_TEXT_FROM":"De",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Nom de domaine client",
      "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Afficher les journaux d'audit masqués",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Actions",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Client concerné",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Date",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Initiateur",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Afficher les détails",
      "AUDITLOG_TEXT_TO":"À",
      "AUGUST":"Août",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Code d'authentification reçu de Quickbooks",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Code d'authentification reçu de Xero",
      "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"Le lot est déjà fermé.",
      "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Demande terminée avec succès",
      "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"La carte de crédit a expiré.",
      "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"Le lot d'appareils est plein. Veuillez fermer le lot.",
      "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"L'appareil a été désactivé.",
      "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Une transaction dupliquée a été soumise.",
      "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"L'identifiant de l'appareil n'est pas enregistré ou n'est pas activé.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"L'ID de lot de l'appareil n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"L'ID de l'appareil n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"Le drapeau d'inversion n'est pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"Le code de sécurité spécifié n'était pas valide.",
      "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Une erreur de validation s'est produite au niveau du processeur.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Les données de la demande n'ont pas réussi la vérification des champs obligatoires pour cette application.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Le(s) champ(s) de la requête sont invalides ou manquants.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"Les transactions V.me ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"La transaction est introuvable.",
      "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"La demande d'annulation a échoué. Soit le type de transaction d'origine ne prend pas en charge l'annulation, soit la transaction est en cours de règlement.",
      "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"Le marchand est activé avec succès.",
      "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"Le marchand est configuré pour la fermeture automatique.",
      "AUTHORIZE_NET_CODE_NEED_CONSET":"Besoin du consentement du payeur.",
      "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Cette commande a déjà été validée ou annulée, de nouvelles associations de transaction ne peuvent donc pas être acceptées.",
      "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Votre commande a été reçue. Merci pour votre entreprise!",
      "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"La transaction d'origine se trouve dans un lot fermé.",
      "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Le service Payment Gateway Account (id=8) a déjà été accepté.",
      "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Un nouveau code de sécurité a été demandé.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Cette transaction a déjà été autorisée.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal a déjà capturé cette transaction.",
      "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"L'annulation a été traitée avec succès.",
      "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"L'appareil mobile a été inséré avec succès dans la base de données.",
      "AUTHORIZE_NET_CODE_SUCCESS":"La demande a été traitée avec succès.",
      "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"L'appareil mobile a été enregistré avec succès et approuvé par l'administrateur du compte.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Cette transaction a déjà été annulée.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Cette transaction a déjà été capturée.",
      "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"Transaction originale pour l'annulation introuvable.",
      "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal a rejeté la transaction. x_paypal_hdrimg dépasse la longueur maximale autorisée.",
      "AUTHORIZE_NET_CODE_USE_XMETHOD":"Utilisez x_method pour spécifier la méthode ou envoyer uniquement x_call_id ou les informations de carte/compte.",
      "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"C'est un travail en cours de réalisation. Ce message ne sera pas diffusé en production. C'est juste un espace réservé de développement.",
      "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"Le taux de conversion pour cette carte est disponible.",
      "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"Le code de la carte est invalide.",
      "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Cette transaction ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Cette transaction ne peut pas être traitée.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Ce compte n'a pas reçu les autorisations requises pour cette demande.",
      "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"Le prénom sur le compte et/ou le type de compte ne correspond pas au compte réel.",
      "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"Les transactions ACH ne peuvent pas être acceptées par ce système.",
      "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"L'indicateur d'authentification est invalide.",
      "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"Le code d'autorisation n'est pas valide.",
      "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"Le type de compte bancaire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"La facturation récurrente n'est pas autorisée pour ce type eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La valeur d'authentification du titulaire de la carte n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"Le code ABA est invalide",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"Le jeton d'accès fourni a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"Le jeton d'accès utilisé pour valider la requête est insuffisant pour le faire.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"La méthode API demandée ne peut pas être exécutée lorsque le compte de la passerelle de paiement est en mode test.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Ce marchand n'est pas associé à ce revendeur.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"Le compte marchand doit être configuré pour accepter les paiements par carte de crédit, les paiements par chèque électronique ou les deux.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"La passerelle de paiement, le revendeur ou le compte utilisateur n'est pas actif actuellement.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"Le compte de la passerelle de paiement n'est pas configuré pour traiter les abonnements par carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"Le compte de la passerelle de paiement n'est pas configuré pour traiter les abonnements eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"Le numéro de compte est invalide",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"Le montant de l'abonnement est requis lors de la création d'un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"Les transactions ACH ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"La somme de tous les crédits de cette transaction est supérieure au montant de la transaction d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Tous les champs étaient vides ou manquants.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Tous les champs étaient vides ou manquants.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Valeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Valeur trop longue.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"L'abonnement a déjà été annulé.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"L'enregistrement a déjà été supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"Aucune approbation n'a été renvoyée pour la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"Une ou plusieurs valeurs d'enchères requises n'ont pas été soumises.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"Le type d'authentification n'est pas autorisé pour l'appel de méthode demandé.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Un code d'autorisation est requis mais non présent.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Ce processeur n'accepte pas les autorisations à zéro dollar.",
      "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"La transaction a été refusée en raison d'une non-concordance AVS. L'adresse fournie ne correspond pas à l'adresse de facturation du titulaire de la carte.",
      "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Le mode de paiement bancaire n'est pas accepté pour le pays d'activité sélectionné.",
      "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s ne peut pas être laissé vide.",
      "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Une erreur s'est produite lors du traitement. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"L'appel du service Web renvoie une erreur.",
      "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"L'URL d'annulation est requise.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Cette transaction est en attente de règlement et ne peut être remboursée.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"Le nombre d'occurrences totales ne peut prolonger la durée de l'abonnement au-delà de trois ans à compter de la date de début.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"La carte de crédit a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Les totaux des montants des articles du panier ne correspondent pas aux montants des commandes. Assurez-vous que le total des paramètres de l'élément de détail du paiement correspond au total de la commande.",
      "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"Le compte de passerelle de paiement n'est pas activé pour la facturation ou le service CIM.",
      "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Autorisation client refusée.",
      "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Les seuls types de contenu pris en charge sont text/xml et application/xml.",
      "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"Impossible de décrypter les blobs DUKPT et une erreur renvoyée.",
      "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"Le pays n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"La carte de crédit n'est plus valable à la date de début de l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Le mode de paiement par carte de crédit n'est pas accepté pour le pays d'activité sélectionné.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Les transactions par carte de crédit ne sont pas acceptées par ce commerçant.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId et Customer data s'excluent mutuellement, un seul d'entre eux peut être fourni pour une même demande.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Erreur lors de la création d'un profil client à partir de la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Le numéro d'identification fiscale du client ou les informations sur le permis de conduire (numéro de permis de conduire, état du permis de conduire, date de naissance du permis de conduire) sont requis pour l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Lors de l'utilisation du profil client, CustomerPaymentProfileId doit être inclus.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Erreur lors de la création d'un profil de paiement client à partir de la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil client spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"L'opération de base de données a expiré avant d'avoir pu être terminée.",
      "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"La somme des crédits par rapport à la transaction référencée dépasserait le montant du débit d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Une erreur s'est produite lors du déchiffrement des données EMV.",
      "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"Le profil client n'a pas de profil de paiement/livraison par défaut.",
      "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"La date d'échéance est passée ou n'est pas spécifiée.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Un duplicata de l'abonnement a déjà été soumis.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Une transaction dupliquée a été soumise.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"KSN est dupliqué.",
      "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Un identifiant de profil client ou un e-mail valide est requis",
      "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"L'ID d'employé doit comporter entre 1 et %x caractères.",
      "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"Les données EMV ne sont pas prises en charge avec ce type de marché.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"La date d'expiration de la carte de crédit n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"Échec de l'envoi de l'e-mail.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Échec de la validation de la clé partagée.",
      "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Erreur fatale lors de l'appel du service Web.",
      "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"L'ID de marchand FDC ou l'ID de terminal est incorrect. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"Le type de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"L'une des valeurs de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"L'un des champs a une longueur non valide.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"L'un des champs obligatoires n'était pas présent.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"L'empreinte digitale ne peut pas être générée.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"La valeur de l'empreinte digitale n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Échec générique InsertNewMerchant.",
      "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Les numéros d'identification du Global Payment System sont incorrects. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_HASH":"Correspondance de hachage doesnâ€™t.",
      "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"La page de paiement hébergée saisira les informations de paiement (banque/carte) de sorte que ces informations ne doivent pas être incluses avec cette demande.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"La clé d'API d'emprunt d'identité n'est pas valide ou n'est pas présente.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"L'ID de connexion du Partenaire d'emprunt d'identité n'est pas valide ou n'est pas présent.",
      "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Une fois qu'un abonnement est créé, l'intervalle ne peut pas être modifié.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"Le nom d'utilisateur de l'API n'est pas valide et/ou la clé de transaction ou la clé d'API n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"L'ID de l'acheteur de l'enchère n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"Le type de transaction n'est pas valide pour les transactions ACH.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"Il y a des informations manquantes ou non valides dans un champ obligatoire.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"La durée de l'intervalle doit être de 7 à 365 jours ou de 1 à 12 mois.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal a rejeté la transaction.Identifiant de payeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"La combinaison de l'ID de la plateforme d'enchères et du nom de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"La valeur du mode d'entrée POS n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"L'ID de profil client, l'ID de profil de paiement, l'ID d'adresse de livraison ou l'ID de transaction pour cette demande n'est pas valide pour ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"Le nom du nœud racine de la requête XML correspond à la méthode API appelée. Ce n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"L'ID du vendeur aux enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"Le montant demandé pour le règlement ne peut pas être différent du montant initial autorisé. Veuillez annuler la transaction si nécessaire",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"La transaction V.me est dans un état invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"L'authentification de l'utilisateur nécessite une valeur valide pour la clé de transaction ou la clé d'API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"L'authentification de l'utilisateur nécessite une valeur valide pour le nom d'utilisateur de l'API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Valeur invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"La version EMV n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"La valeur x_call_id est introuvable ou invalide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"La valeur x_discount n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"La valeur x_giftwrap n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"La valeur x_misc n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal a rejeté la transaction. x_paypal_payflowcolor doit être une valeur hexadécimale de 6 caractères.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"La valeur x_subtotal n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Cette facture a été annulée par l'expéditeur. Veuillez contacter directement l'expéditeur si vous avez des questions.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Le numéro de facture n'a trouvé aucun enregistrement.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"Le montant de la transaction soumise était supérieur au montant maximum autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Le nombre maximum d'éléments pour un tableau a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Le nombre maximum de profils de paiement pour le profil client a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Le nombre maximum d'adresses de livraison pour le profil client a été atteint.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"Le compte revendeur n'est pas activé pour l'API Merchant Boarding.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"Le marchand n'accepte pas ce type de carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"Le marchand a refusé l'autorisation de ressouNCE.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"Le marchand n'accepte pas ce type de carte de crédit.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"L'identifiant de connexion ou le mot de passe du marchand est invalide ou le compte est inactif.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"Le marchand a trop d'appareils en attente.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"Le marchand n'est pas activé.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"Le mot de passe marchand est invalide ou absent.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"Le marchand a été mal configuré chez le processeur. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Les numéros d'identification de Paymentech sont incorrects. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"Échec de l'analyse du MerchantId en nombre entier",
      "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"La valeur x_call_id est manquante.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"L'identifiant de l'appareil mobile n'est pas associé au compte marchand.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"L'appareil mobile existe mais est en attente.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"L'appareil mobile existe mais son état est désactivé.",
      "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"Le pays doit être une valeur valide à deux ou trois caractères si spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"Le commerçant authentifié n'a pas accès au jeton OTS spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"Aucun enregistrement correspondant à votre requête n'a été trouvé.",
      "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"Il n'y a pas d'abonnements disponibles pour le compte marchand pour le type d'abonnement demandé.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"Le jeton d'accès n'est pas autorisé à appeler la méthode API.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"La transaction a été autorisée mais le client n'a pas pu être avisé ; ce ne sera pas réglé.",
      "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me by Visa ne prend pas en charge les annulations sur les transactions capturées ou créditées. Veuillez autoriser le règlement de la transaction, puis procéder au remboursement de la transaction capturée.",
      "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"L'une des valeurs de champ n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Seule la Carte de Crédit ou la Banque est autorisée.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile et profileIds s'excluent mutuellement, un seul d'entre eux peut être fourni à la fois.",
      "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"La création d'un profil de paiement avec ce descripteur OpaqueData nécessite que transactionMode soit défini sur liveMode.",
      "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Si l'ID de profil client est inclus, il doit correspondre à l'ID de profil client utilisé pour l'abonnement d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Les données de jeton OTS spécifiées ne sont pas valides.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"Le jeton OTS spécifié a expiré.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"Le jeton OTS spécifié n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"Le service OTS ne peut pas traiter la demande en raison d'une erreur de validation ou de configuration.",
      "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"Le décalage de pagination dépasse la valeur autorisée. Vérifiez et réduisez la valeur.",
      "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Une erreur s'est produite lors de l'analyse des données EMV.",
      "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Ce processeur n'accepte pas les inversions partielles.",
      "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"Le compte Partenaire n'est pas autorisé à usurper l'identité du compte de connexion.",
      "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal a rejeté la transaction. Cet identifiant de payeur appartient à un autre client.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Le service Payment Gateway Account (id=8) a déjà été accepté. Le refus n'est pas autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"Le service de compte de passerelle de paiement (id=8) a déjà été refusé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"Le compte de passerelle de paiement n'est pas activé pour la facturation récurrente automatisée.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"Le compte de passerelle de paiement n'est pas activé pour Customer Information Manager (CIM).",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Les informations de paiement sont requises lors de la création d'un abonnement ou d'un profil de paiement.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"La création du profil client a échoué. Ce mode de paiement ne prend pas en charge la création de profil.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil de paiement spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Un échéancier de paiement est requis lors de la création d'un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"La modification du type de paiement de l'abonnement entre carte de crédit et eCheck.Net n'est actuellement pas prise en charge.",
      "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Statut en attente (non terminé).",
      "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"La condition préalable a échoué.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"Le compte du processeur n'a pas été configuré pour définir l'indicateur de mode de test sur faux.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"Le marchand n'a pas encore fourni d'informations sur le processeur pour définir l'indicateur de mode test sur faux.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"La configuration avec le processeur n'est pas valide. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"La création du profil client a échoué. Cet ID de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Les ID de profil de paiement et d'expédition ne peuvent pas être spécifiés lors de la création de nouveaux profils.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Les ID de profil de paiement et d'expédition ne peuvent pas être spécifiés lors de la demande d'une page de paiement hébergée.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Si l'ID du profil client, l'ID du profil de paiement et l'ID de l'adresse de livraison sont inclus, ils doivent correspondre à la transaction d'origine.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"La recherche d'un profil d'expédition à l'aide de l'ID de profil client et de l'ID de profil d'expédition n'a trouvé aucun enregistrement.",
      "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Un duplicata du profil client, du profil de paiement client ou de l'adresse client a déjà été soumis.",
      "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"Le paramètre RecurringBilling est un champ obligatoire pour les transactions de paiement à jeton récurrentes.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"ID de référence introuvable.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"La transaction référencée ne répond pas aux critères d'émission d'un avoir.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"L'action demandée n'est pas autorisée en raison de l'état actuel de l'objet.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Lors de la demande de CreateProfile, les données de paiement ne peuvent pas être nulles.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Paiement non autorisé. Le paiement n'a pas été autorisé par l'utilisateur.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"La version EMV est requise.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"Le numéro de terminal/voie doit être numérique.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Un descripteur opaque est requis.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"L'identifiant du payeur est requis.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"La signature doit être des données au format PNG.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"Les transactions PayPal nécessitent une URL valide pour cancel_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Les transactions PayPal nécessitent une URL valide pour success_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Nœud requis manquant.",
      "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Une erreur de validation a été renvoyée par V.me.",
      "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Nous retenons actuellement la dernière transaction pour examen. Avant de réactiver l'abonnement, vérifiez la transaction.",
      "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"Le nœud racine ne fait pas référence à un espace de noms XML valide.",
      "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"Impossible de définir un taux d'achat inférieur au taux de vente",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"Le serveur est actuellement trop occupé, veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Erreur du serveur",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"Le serveur est en maintenance, la méthode demandée n'est donc pas disponible. Veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Votre session a expiré ou n'existe pas. Vous devez vous reconnecter pour continuer à travailler.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"Le montant demandé pour le règlement ne peut être supérieur au montant initialement autorisé.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"L'autorisation a été approuvée mais le règlement a échoué.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"L'identifiant de l'adresse d'expédition et les données d'expédition s'excluent mutuellement, un seul d'entre eux peut être fourni pour une même demande.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Le profil d'expédition spécifié est associé à un abonnement actif ou suspendu et ne peut pas être supprimé.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Lorsque vous utilisez le profil client avec les informations de carte de crédit pour spécifier le profil d'expédition, l'identifiant du profil d'expédition doit être inclus.",
      "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Clé de signature manquante.",
      "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"Les rapports ne peuvent pas être générés pour des dates futures, la date spécifiée n'est donc pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Une fois qu'un abonnement est créé, la date de début ne peut pas être modifiée.",
      "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"L'état n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"La date de début de l'abonnement ne peut pas être antérieure à la date de soumission de l'abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Les abonnements arrivés à expiration ou résiliés ne peuvent pas être annulés.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Les abonnements arrivés à expiration, annulés ou résiliés ne peuvent pas être mis à jour.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"L'ID d'abonnement pour cette demande n'est pas valide pour ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"La date de début de l'abonnement est requise pour créer un abonnement.",
      "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"Le code de devise fourni n'est pas valide, n'est pas pris en charge, n'est pas autorisé pour ce marchand ou n'a pas de taux de change.",
      "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"Le numéro de banque Elavon ou l'identifiant du terminal est incorrect. Appelez le fournisseur de services du marchand.",
      "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Les données de signature sont trop volumineuses.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Cette transaction a été approuvée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"La transaction a été soumise à partir d'une adresse IP bloquée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"La transaction référencée ne répond pas aux critères d'émission d'une autorisation continue.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"La transaction référencée ne répond pas aux critères d'émission d'une autorisation continue avec capture automatique.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"La création du profil client a échoué. Ce type de transaction ne prend pas en charge la création de profil.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Cette transaction doit être cryptée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"L'ID de transaction est invalide ou absent.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Cette transaction ne peut pas être acceptée pour le moment en raison de la maintenance du système. Veuillez réessayer plus tard.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"La transaction est introuvable.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"Les transactions basées sur EMV ne sont actuellement pas prises en charge pour ce processeur et ce type de carte.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"Les données EMV ne sont pas prises en charge avec ce type de transaction.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"Les transactions PayPal ne sont pas acceptées par ce marchand.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"Les transactions PayPal nécessitent x_version d'au moins 3.1.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"Le type de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"Le nombre d'occurrences d'essai spécifié doit être inférieur au nombre total d'occurrences spécifié.",
      "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Une erreur système inattendue s'est produite lors du traitement de cette demande.",
      "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Erreur inconnue.",
      "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Facture créée mais échec de l'envoi de l'e-mail et mise à jour de l'état",
      "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"L'URL du lien de référence, de réponse de relais ou de réception n'est pas valide.",
      "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"L'APIUser existe déjà.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"L'utilisateur ne dispose pas des autorisations suffisantes pour utiliser un appareil mobile avec ce compte marchand.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"L'utilisateur n'est pas autorisé à appeler l'API.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"L'utilisateur n'est pas autorisé à appeler la méthode API.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Un montant valide est requis.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"Le nombre d'occurrences d'essai ne peut pas être égal à zéro si un montant d'essai valide est soumis.",
      "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Si un montant d'essai ou un nombre d'occurrences d'essai est spécifié, les valeurs pour les deux doivent être soumises.",
      "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Les numéros d'identification VITAL sont incorrects. Appelez le fournisseur de services marchand.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Ceci est le résultat d'une erreur de l'analyseur XML.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Ceci est le résultat d'une erreur de l'analyseur XML. Le nœud est invalide.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Ceci est le résultat d'une erreur de l'analyseur XML. Champ(s) manquant(s).",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Ceci est le résultat d'une erreur de l'analyseur XML. Trop de nœuds spécifiés.",
      "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La combinaison du type de carte, de l'indicateur d'authentification et de la valeur d'authentification du titulaire de la carte n'est pas valide.",
      "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"Le type de carte commerciale n'est pas valide.",
      "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Ce pays n'est pas autorisé pour les acheteurs.",
      "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"Le cryptogramme est invalide ou ne peut pas être utilisé en combinaison avec d'autres paramètres.",
      "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"Le type d'appareil n'est pas valide ou est manquant.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"La date de naissance du permis de conduire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"Le numéro de permis de conduire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"L'état du permis de conduire est invalide.",
      "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"Le montant des droits n'est pas valide.",
      "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Ce type eCheck.Net n'est pas autorisé lors de l'utilisation du formulaire de paiement hébergé par la passerelle de paiement.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Ce type eCheck.Net n'est pas autorisé.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Ce type eCheck.Net n'est pas autorisé pour ce type de compte bancaire.",
      "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"Le paramètre de type eCheck n'est pas valide.",
      "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"Le montant du fret n'est pas valide.",
      "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"La passerelle ne prend plus en charge la méthode d'intégration demandée.",
      "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"Le numéro de chèque est invalide.",
      "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"Le numéro de carte de crédit est invalide.",
      "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"La date d'expiration de la carte de crédit n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"L'élément de ligne %1 n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"L'ID de la liste d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"Le type d'enchère n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"L'ID de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Il y a un ou plusieurs champs obligatoires manquants ou non valides.",
      "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"Le SplitTenderID spécifié n'est pas valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Longueur ECI du demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Longueur d'ID de demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Nom de demandeur de jeton non valide.",
      "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"Le nom de la plateforme d'enchères n'est pas valide.",
      "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"Le nombre d'éléments de ligne soumis n'est pas autorisé. Un maximum de %1 éléments de ligne peut être soumis.",
      "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"Le type de marché n'est pas valide",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Ce compte marchand a été clôturé.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Le compte marchand n'est pas actif. Ce paiement automatisé ne sera pas traité.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Le compte marchand est en mode test. Ce paiement automatisé ne sera pas traité.",
      "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Ce processeur n'accepte pas l'autorisation à zéro dollar pour ce type de carte.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Une erreur s'est produite lors du traitement. Veuillez contacter le commerçant.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Un ou plusieurs des champs de configuration de type HTML ne semblent pas sûrs.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Une ou plusieurs des valeurs de sous-commerçant requises sont manquantes.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Une ou plusieurs des valeurs de sous-marchand ne sont pas valides.",
      "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"Le PayformMask n'est pas valide.",
      "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Une erreur s'est produite lors du traitement des données de paiement.",
      "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"Le traitement des paiements Apple n'est pas activé pour ce compte marchand.",
      "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Une erreur s'est produite lors du traitement. Veuillez réessayer.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Ce processeur n'accepte pas les transactions récurrentes.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Ce processeur ne permet pas l'annulation des crédits.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Ce processeur ne prend pas en charge cette méthode de soumission des données de paiement.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Cette demande ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"L'ID de transaction et l'ID d'appel d'offres fractionné ne peuvent pas être utilisés tous les deux dans la même demande.",
      "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"Le type de formulaire demandé n'est pas valide.",
      "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"Le montant de cette demande n'a été que partiellement approuvé sur la carte prépayée donnée. Un paiement supplémentaire est requis pour régler le solde de cette transaction.",
      "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"L'URL de réussite est requise.",
      "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"Le format de réponse n'est pas valide",
      "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"Annulation non prise en charge pour ce type de transaction.",
      "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"Le script demandé n'est pas valide ou n'est plus pris en charge.",
      "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Les scripts ne sont pris en charge que dans la version 2.5.",
      "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"L'état ou le pays d'expédition n'est pas valide.",
      "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"Le SSN ou l'identifiant fiscal n'est pas valide.",
      "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"Le montant du supplément n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"Le montant de la taxe n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"Le montant du pourboire n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Les données de Track1 ne sont pas dans un format valide.",
      "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Les données Track2 ne sont pas dans un format valide.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"La transaction a été autorisée mais le client n'a pas pu être avisé ; ce ne sera pas réglé.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Cette transaction ne peut pas être acceptée pour le moment.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Cette transaction ne peut pas être traitée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Les transactions ayant des valeurs d'authentification de titulaire de carte ne peuvent pas être marquées comme récurrentes.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"La transaction est actuellement à l'étude.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"Cette transaction a été refusée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Les transactions de ce type de marché ne peuvent pas être traitées sur ce système.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"La méthode de transaction n'est pas valide.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Cette transaction ne peut être acceptée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Cette transaction ne peut pas être acceptée pour traitement.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"La transaction référencée n'a pas été approuvée.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"Le type de transaction indiqué n'est pas pris en charge pour ce marchand.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"La transaction a entraîné une non-concordance AVS. L'adresse fournie ne correspond pas à l'adresse de facturation du titulaire de la carte.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"Le type de transaction n'est pas valide",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"Le type de transaction n'est pas valide. Veuillez contacter le marchand.",
      "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Un pays de facturation valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Un pays valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Un état/province de facturation valide est requis.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Un état valide est requis.",
      "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"La version 2.5 n'est plus prise en charge.",
      "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"Le paramètre de version n'est pas valide",
      "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Autoriser l'accès aux administrateurs globaux CSP",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"Le paiement a été initié avec succès. Le traitement prendra quelques temps. Veuillez vérifier le statut après un certain moment.",
      "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"L'indicateur de renouvellement automatique s'applique uniquement aux offres NCE",
      "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"La création du produit du plan n'a pas réussi pendant le processus de synchronisation automatique",
      "AUTO_SYNC_MACRO_NOT_FOUND":"Macro introuvable",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Plusieurs offres mappées trouvées",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Plusieurs offres mappées trouvées avec la promotion",
      "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Plans multiples trouvés",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"Aucune offre mappée trouvée",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Aucune offre mappée trouvée avec la promotion",
      "AUTO_SYNC_NO_PLAN_FOUND":"Aucun plan trouvé",
      "Autotask":"Autotask",
      "AUTOTASK_API_USER_NAME_IS_MISSING":"Le nom d'utilisateur de l'API est manquant. Veuillez mettre à jour le nom d'utilisateur dans l'écran des paramètres.",
      "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"Incompatibilité de quantité de tâche automatique avec l'ancienne quantité C3",
      "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Sélectionnez un droit",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Catégorie",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Description",
      "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Impact",
      "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Ressource impactée",
      "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Dernière mise à jour",
      "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Des bénéfices potentiels",
      "Azure_BILLING":"Facturation Azure",
      "AZURE_BILLING":"Facturation Azure",
      "AZURE_BILLING_CYCLE":"Cycle de facturation",
      "Azure_ENTITLEMENT_MANAGEMENT":"Droit Azure",
      "AZURE_ENTITLEMENT_MANAGEMENT":"Droit Azure",
      "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Gestion des droits Azure",
      "Azure_ENTITLEMENT_NOT_FOUND_FOR_THE_AzurePLAN":"Il manque un droit Azure au locataire !",
      "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"Il manque un droit Azure au locataire !",
      "AZURE_ERROR_RECOMMENDATION_TEXT":"Erreur lors de la récupération des recommandations Azure Advisor",
      "Azure_ESTIMATE":"Estimation Azur",
      "AZURE_ESTIMATE":"Estimation Azur",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Période de facturation",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Identifiant du droit",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Nom du droit",
      "AZURE_ESTIMATE_LABEL_DEPARTMENT":"Département :",
      "AZURE_ESTIMATE_LABEL_SITE":"Site :",
      "AZURE_ESTIMATE_REPORT":"Rapport d'estimation Azure",
      "AZURE_ESTIMATE_TAGS":"Balises",
      "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Veuillez sélectionner au moins une balise pour télécharger l'estimation azur",
      "Azure_ESTIMATE_TAGS_POPUP_INFO":"Veuillez sélectionner au moins une balise pour télécharger l'estimation Azure",
      "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Balises azur",
      "Azure_ESTIMATE_TAGS_POPUP_TITLE":"Balises Azure",
      "AZURE_ESTIMATE_TAGS_TOOLTIP":"Balises de ressources définies dans le portail Azure",
      "Azure_ESTIMATE_TAGS_TOOLTIP":"Balises ResouNCE définies dans le portail Azure",
      "AZURE_ESTIMATED_COST":"Coût estimé",
      "AZURE_ESTIMATES":"Estimations Azure ",
      "AZURE_GROUP_DETAILS":"Informations du groupe",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Groupes Azure ",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Ajouter Admin au Groupe",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Ajouter un groupe",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Retour aux groupes d'utilisateurs",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Retour aux groupes",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Modifier le groupe",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Utilisateurs du groupe",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Ajouter un groupe d'utilisateurs",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Groupes Azure ",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Utilisateurs de {{groupname}}",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrateur",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Accès facturation",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Seuil de danger",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Description",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Courriel",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Seuil d'erreur",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Nom du groupe",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Seuil d'information",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Fréquence de notifications",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":" Seuil d’avertissement",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrateur",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Accès facturation",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Nom",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Fréquence de notifications",
      "AZURE_LOADING":"Tentative de chargement des données à partir d'azure, veuillez patienter...",
      "AZURE_LOADING_MESSAGE":"Interroger Microsoft Azure et cela peut prendre une minute à charger ...",
      "Azure_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"La valeur de la marge Azure a été mise à jour avec succès",
      "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"La valeur de la marge Azure a été mise à jour avec succès",
      "AZURE_NEW_MARKUP":"Nouveau balisage",
      "Azure_OLD_MARKUP":"Marge actuelle",
      "AZURE_OLD_MARKUP":"Marge actuelle",
      "Azure_PLAN_CREATION_FAILED":"Échec de la création du plan Azure",
      "AZURE_PLAN_CREATION_FAILED":"Échec de la création du plan Azure",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"Le montant de la facture sera calculé pour le client, ainsi que pour leurs sites ou départements respectifs, en fonction du pourcentage alloué.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"L’allocation du plan Azure et ses droits peuvent être partagés entre différentes entités de site et de service en attribuant un pourcentage.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Instructions",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Étapes à attribuer : <br /> a. Développez l'abonnement souhaité. <br /> b. Cliquez sur le pourcentage associé à une entité. <br /> c. Entrez le pourcentage souhaité. <br /> d. Cliquez sur <strong> Soumettre <strong/> pour enregistrer l'allocation.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Pour attribuer entièrement le plan Azure ou l’autorisation à un site/service, entrez 100.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Pour libérer une allocation, entrez 0.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Les allocations libérées seront attribuées à l'entité mère immédiate.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Ces allocations n’auront aucun impact sur les rapports et le rapport Azure Estimate and Billing continuera d’afficher toutes les données du client.",
      "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Note par catégorie",
      "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Toutes catégories",
      "AZURE_RECOMMENDATION_ALL_COST":"Coût",
      "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"La haute disponibilité",
      "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"L'excellence opérationnelle",
      "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Performance",
      "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Fiabilité",
      "AZURE_RECOMMENDATION_ALL_SECURITY":"Sécurité",
      "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Télécharger les détails de la recommandation",
      "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Détails de la recommandation",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Ce champ est obligatoire",
      "AZURE_RECOMMENDATION_SUBMIT":"Soumettre",
      "AZURE_RECOMMENDATION_TEXT":"Recommandation Azure",
      "AZURE_RECOMMENDATIONS_TEXT":"Recommandations",
      "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Sélectionnez un code de devise",
      "AZURE_REPORT_DISTRIBUTOR_LIST":"Distributeur",
      "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Veuillez sélectionner soit le partenaire, soit l'un des revendeurs.",
      "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Sélectionnez une entité",
      "AZURE_REPORT_ENTITY_LIST":"Entité",
      "AZURE_REPORT_ENTITY_TOOLTIP":"Veuillez sélectionner soit le partenaire, soit l'un des revendeurs.",
      "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Sélectionnez un revendeur",
      "AZURE_REPORT_RESELLERS_LIST":"Choisissez un revendeur",
      "Azure_REPORTS_BITTON_TEXT":"Rapport d'estimation Azure",
      "AZURE_REPORTS_BITTON_TEXT":"Rapport d'estimation Azure",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Détails par",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Détails par groupe de ressource",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Détails par service",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Obtenir les détails",
      "Azure_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sans étiquettes",
      "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sans étiquettes",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Rapports Azure ",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "Azure_REPORTS_BY_TAGS_BITTON_TEXT":"Avec des tags",
      "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Avec des tags",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Résumé de facturation",
      "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Tarifs du plan Azure",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"Date",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Droit",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"Groupe",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Mois",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Sélectionner un groupe",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Abonnement",
      "Azure_REPORTS_OPTION_SELECT_TAG":"Sélectionnez une balise pour obtenir des données par balise",
      "AZURE_REPORTS_OPTION_SELECT_TAG":"Sélectionnez une balise pour obtenir des données par balise",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Tous les droits",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Tous les groupes",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Tous les abonnements",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Sélectionner une date",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Non attribué",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Coût",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Nom de ressource ",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Nom de service",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Audit",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Détails",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Groupe de ressource",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Nom de ressource ",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Nom de service",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Coût total",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Date-heure",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Catégorie d'événement",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Événement initié par",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Nom de l'opération",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Statut",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Audit",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Détails",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Coût",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Date",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Quantité",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Coût total",
      "AZURE_REPPORT_CURRENCY":"Devise",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Vous devez sélectionner une devise pour obtenir le rapport",
      "AZURE_REPPORT_CUSTOMERS":"Clients",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Sélectionner un client",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Sélectionner un client",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"Contributeur",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Rôle personnalisé",
      "AZURE_ROLE_NAME_OWNER":"Propriétaire",
      "AZURE_ROLE_NAME_READER":"Lecteur",
      "AZURE_SUBSCRIPTION":"Abonnement Azure",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Modifier",
      "AZURE_SUBSCRIPTION_DETAILS":"Détails de l'abonnement",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Sélectionner",
      "AZURE_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "Azure_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Il manque un plan Azure au locataire !",
      "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Il manque un plan Azure au locataire !",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Échec de la mise à jour de l'abonnement Azure",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"L'abonnement Azure a été mis à jour avec succès",
      "Azure_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Voulez-vous vraiment effectuer la mise à niveau ?",
      "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Voulez-vous vraiment effectuer la mise à niveau ?",
      "AZURE_THRESHOLD_DETAILS":"Détails du seuil",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Définir un budget mensuel pour cet abonnement et définir les seuils avec plusieurs sévérités telles qu’Info, Avertissement, Erreur et Danger.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Pour les abonnements gérés par le client, tous les administrateurs de groupes seront avertis lorsque l'utilisation atteindra ou dépassera un certain seuil.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Les notifications sont envoyées une fois par cycle de facturation.",
      "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Forfait Azur",
      "Azure_UPGRADE_Azure_PLAN_TEXT":"Plan Azur",
      "Azure_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Veuillez patienter, nous vérifions l'éligibilité au surclassement",
      "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Veuillez patienter, nous vérifions l'éligibilité au surclassement",
      "Azure_UPGRADE_INELIGIBLE_MESSAGE":"L'abonnement n'est pas éligible à la mise à niveau",
      "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"L'abonnement n'est pas éligible à la mise à niveau",
      "Azure_UPGRADE_WAIT_MESSAGE":"Veuillez patienter Nous traitons la mise à niveau...",
      "AZURE_UPGRADE_WAIT_MESSAGE":"Veuillez patienter Nous traitons la mise à niveau...",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Retour à la liste",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Utilisation Azure",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Détails d'utilisation",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Consulter les détails",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Estimation du prix",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Nom",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} ajouté au groupe {{groupname}} avec succès",
      "AZUREGROUPS_SAVE_SUCCESS":"Groupe {{username}} sauvegardé avec succès",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Groupe d'utilisateur ajouté avec succès",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Groupe sauvegardé avec succès",
      "BACK_TO_AZURE_ADVISOR":"Retour au conseiller Azure",
      "BACK_TO_POLICIES":"Retour aux politiques personnalisées",
      "BACK_TO_PUBLIC_SIGNUP_SHOP":"Retour à la boutique",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Retour aux domaines du fournisseur",
      "BACKGROUND_DROPDOWN_TEMPLATE_1":"Modèle 1",
      "BACKGROUND_DROPDOWN_TEMPLATE_10":"Modèle 10",
      "BACKGROUND_DROPDOWN_TEMPLATE_2":"Modèle 2",
      "BACKGROUND_DROPDOWN_TEMPLATE_3":"Modèle 3",
      "BACKGROUND_DROPDOWN_TEMPLATE_4":"Modèle 4",
      "BACKGROUND_DROPDOWN_TEMPLATE_5":"Modèle 5",
      "BACKGROUND_DROPDOWN_TEMPLATE_6":"Modèle 6",
      "BACKGROUND_DROPDOWN_TEMPLATE_7":"Modèle 7",
      "BACKGROUND_DROPDOWN_TEMPLATE_8":"Modèle 8",
      "BACKGROUND_DROPDOWN_TEMPLATE_9":"Modèle 9",
      "BAD_INPUT_INVALID_PRODUCTID":"ID de produit invalide",
      "BAD_INPUT_INVALID_SEATS":"Sièges invalides",
      "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entités impliquées dans la notification de la bannière",
      "BILL_ACTION_DESC_FULL_CHARGE":"Plein tarif pour tout le mois.",
      "BILL_ACTION_DESC_NO_REFUND":"Aucun remboursement ne sera fourni",
      "BILL_ACTION_DESC_PRORATE":"Le prorata sera appliqué dans la facture.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Plein tarif",
      "BILL_ACTION_NAME_NO_REFUND":"Aucun remboursement",
      "BILL_ACTION_NAME_PRORATE":"Au prorata",
      "BILL_CYCLE_DESC_ANNUAL":"Annuel",
      "BILL_CYCLE_DESC_MONTHLY":"Mensuel",
      "BILL_CYCLE_DESC_ONETIME":"Une fois",
      "BILL_CYCLE_DESC_TRIENNIAL":"Triennal",
      "BILL_CYCLE_NAME_ANNUAL":"Annuel",
      "BILL_CYCLE_NAME_MONTHLY":"Mensuel",
      "BILL_CYCLE_NAME_TRIENNIAL":"Triennal",
      "BILL_FROM":"Facture de",
      "BILL_TO":"Facturé à",
      "Billing":"Facturation",
      "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"Échec de la suppression du lien client de facturation.",
      "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"Lien client de facturation supprimé avec succès.",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"La modification du cycle de facturation d'un produit n'est pas autorisée, car une affectation de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Nom de produit actuel",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Le cycle de facturation de la commande a été modifié avec succès",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Cycle de facturation",
      "BILLING_FORECAST_REPORT":"Prévision de facturation",
      "BILLING_PERIOD":"Période de facturation",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Sélectionnez au moins une période de facturation",
      "BILLING_TYPE_DESC_MARK_UP":"Majoration",
      "BILLING_TYPE_DESC_METERED_BILLING":"Facturation au compteur",
      "BILLING_TYPE_DESC_PERCENTAGE":"Pourcentage",
      "BILLING_TYPE_DESC_PRICE":"Prix",
      "BILLING_TYPE_DESC_UNIT":"Unité",
      "BILLING_TYPE_TOOLTIP":"Type de facturation",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Estimations Azure",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Abonnement Azure",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Licences",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Rapports Power BI",
      "BREADCRUMB_HEADER_TEST_PAYMENT":"Paiement test",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Détails de l'état du paiement",
      "BTN_Azure_BILLING_COMPARISON_REPORT":"Rapport de comparaison de facturation Azure",
      "BTN_AZURE_BILLING_COMPARISON_REPORT":"Rapport de comparaison de facturation Azure",
      "BTN_BILLING_FORECAST_REPORT_LOADING":"Prévision de facturation...",
      "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Supprimer l'enregistrement",
      "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Télécharger le zip de la facture",
      "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Afficher les détails de la facture",
      "BTN_INVOICE_LINE_ITEMS":"Postes de facture",
      "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Éléments de ligne de facture avec taxes...",
      "BTN_INVOICE_PAYMENTS":"Paiements de facture",
      "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Retour aux paiements",
      "BTN_QUOTE_COPY_URL":"Copier l'URL",
      "BTN_QUOTE_PUBLISH":"Publier une citation",
      "BTN_QUOTE_REPUBLISH":"Rééditer la citation",
      "BTN_QUOTE_SAVE_DRAFT":"Enregistrer le brouillon",
      "BTN_TEXT_ADD_TO_CART":"Ajouter au panier",
      "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Retour à l'histoire",
      "BTN_TEXT_BEGIN_UPLOAD":"Charger",
      "BTN_TEXT_BEGIN_VALIDATE_DATA":"Valider",
      "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Regardes les connexions",
      "BTN_TEXT_CANCEL_ORDER":"annuler la commande",
      "BTN_TEXT_COMMENT":"Commentaire",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"Copier dans le presse-papiers",
      "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Télécharger tous les bons",
      "BTN_TEXT_FILTER":"Filtrer",
      "BTN_TEXT_FIX":"Corriger",
      "BTN_TEXT_IGNORE":"Ignorer",
      "BTN_TEXT_INCLUDE":"Inclure",
      "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Promotion de la carte",
      "BTN_TEXT_MAPPING_OF_PRODUCTS":"Mappage des produits",
      "BTN_TEXT_OPEN_IN_EMAIL":"Ouvrir dans le courriel",
      "BTN_TEXT_PROCEED":"Procéder",
      "BTN_TEXT_QUICK_VIEW":"Aperçu rapide",
      "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Paiements multiples",
      "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Paiement unique",
      "BTN_TEXT_REMOVE":"Supprimer",
      "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Supprimer la promotion",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Demander une relation de revendeur",
      "BTN_TEXT_SHOW_FILTERS":"Afficher les filtres",
      "BTN_TEXT_SIGN_IN":"Connexion",
      "BTN_TEXT_SKIP":"Ignorer",
      "BTN_TEXT_TAKE_ON":"Inclure",
      "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Gérer les téléchargements de tâches automatiques",
      "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Gérer les téléchargements Connectwise",
      "BTN_TEXT_VIEW_PRODUCT":"Voir le produit",
      "BULK_ADD_ENTITLEMENT_BATCH_ID":"BatchId",
      "BULK_ADD_ENTITLEMENT_DESCRIPTION":"Le dernier nom d'autorisation créé est",
      "BULK_ADD_TENANT_DESCRIPTION":"Le nom du dernier locataire créé est",
      "BULK_ADD_TENANTS_BATCH_ID":"BatchId",
      "BULK_DATA_MAPPING_CSV_UPLOAD":"Télécharger une feuille cartographiée",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Retour au résumé des revenus et des coûts",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"Annuler",
      "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Sélectionnez au moins un client",
      "BULK_INVOICE_DOWNLOAD_HEADER":"Télécharger des factures groupées",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Recevez toutes les factures par e-mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Entrez l'adresse e-mail à qui vous souhaitez envoyer un e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur Soumettre pour recevoir un fichier zip contenant la liste des factures PDF pour la période de facturation sélectionnée",
      "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"Nous informerons les e-mails fournis une fois que le fichier zip sera prêt à être téléchargé ! Il faut généralement une heure pour terminer",
      "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Sélectionnez le plan",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Fermer",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"La facture groupée a été supprimée avec succès.",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Propriétaire",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Statut",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Utilisateur",
      "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Sélectionnez le plan",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"Soumettre",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Voulez-vous vraiment supprimer cet enregistrement ?",
      "BULK_INVOICE_PROGRESS_POPUP_HEADER":"État du téléchargement de la facture",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Actions",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Terminé à",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-mails",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Initiateur",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Lien disponible à ce jour",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Date de début",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Statut",
      "BULK_ONBOARD_BATCH_ID":"BatchId d'intégration massive",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"BatchId d'intégration massive",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Télécharger les détails du mappage",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Télécharger le mappage d'abonnement",
      "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"Mappé <b>{{ TotalSubscriptionsOnboarded }}</b> sur <b>{{ TotalSubscriptionsAtProvider }}</b> abonnements depuis l'Espace Partenaires avec succès.",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous supprimer tous les clients sélectionnés ?",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner tous les clients ?",
      "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":"Sélectionné <b>{{selectedCustomers}}</b> sur <b>{{totalCustomers}}</b> clients pour l'intégration",
      "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Revendeurs existants à bord",
      "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Veuillez fournir une adresse e-mail valide",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Demande en attente pour l'intégration massive de clients",
      "BULK_ONBOARD_RESELLERS_BATCH_ID":"BatchId",
      "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"Le lot est terminé avec succès. Vous serez redirigé vers les revendeurs.",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Sélectionner dans la liste",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"Importer un fichier CSV",
      "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Veuillez sélectionner au moins 1 revendeur à intégrer.",
      "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Résumé des résultats à bord",
      "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Total sélectionné : {{totalResellersInBatch}}, Total intégré : {{resellersOnboarded}}, Total échoué : {{resellersFailedToOnboard}}",
      "BULK_ONBOARD_RESELLERS_LBL_OPTION":"Comment voulez-vous embarquer ?",
      "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous supprimer tous les revendeurs sélectionnés ?",
      "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner tous les revendeurs ?",
      "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"La demande de revendeurs intégrés en masse est mise en file d'attente.<br /> Vous serez redirigé vers l'écran d'état qui affiche la progression.",
      "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"Sélection de <b>{{selectedResellers}}</b> sur <b>{{totalResellers}}</b> revendeurs pour l'intégration",
      "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"Erreurs",
      "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":"Identifiant MPN -",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"ResellersFailed",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"ResellersSucceeded",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"Nombre total de revendeurs sélectionnés",
      "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Entrez une adresse e-mail valide",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Il semble que les détails de l'ID MPN manquent pour le revendeur. Veuillez contacter le Partenaire pour mettre à jour les détails.",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"L'intégration n'est pas prise en charge !",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"L'intégration massive des clients MicrosoftNonCSP n'est pas prise en charge.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Revendeurs intégrés en vrac",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Statut",
      "BULK_ONBOARDING_STATUS_INFO_HEADER":"Conseil! Étapes pour corriger l'intégration partielle",
      "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Si des problèmes de mappage d'abonnement sont signalés par le système lors de l'intégration d'un client, cliquez sur le lien hypertexte pour télécharger et consulter les détails. Corrigez vos plans et travaillez avec le support pour synchroniser les données dans C3.",
      "BULK_PRODUCT_MAPPING":"Cartographie des produits en vrac",
      "BULK_PURCHASED_PRODUCT_MAPPING":"Cartographie des produits d'achat en gros",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Téléchargez le catalogue pour obtenir les clients et leurs identifiants.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Remplissez le code devise, le montant du paiement et la date de paiement pour le C3Id correspondant.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Assurez-vous que les données respectent les règles suivantes :",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> Identifiant client C3 (ou identifiant revendeur C3) du catalogue pour lequel le client souhaite que le paiement soit enregistré.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode - Texte (code de devise standard ISO.) –</strong> Dans quelle devise le paiement doit être enregistré",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount - Décimal –</strong> Montant à enregistrer",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Remarques – Texte –</strong> Commentaires (Facultatif)",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Date (AAAA-MM-JJ) –</strong> À quelle date le paiement doit être enregistré ! Uniquement les dates passées !",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Cliquez sur <strong>Sélectionner</strong> et accédez au fichier.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Cliquez sur <strong>Valider le catalogue</strong> pour que le système valide la saisie.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"S'il n'y a aucune erreur de validation dans la saisie, le bouton <strong> Importer les paiements</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Terminer",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Clients sélectionnés",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Sélectionner au moins un client/sélectionner un plan",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Voulez-vous supprimer le client ?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"La demande d'ajout de plusieurs clients est acceptée",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Télécharger un exemple de fichier .csv",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Téléchargez le catalogue pour obtenir les derniers codes et séquences.",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"source pour référence.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Instructions",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Remplissez le code ou la séquence ou les deux pour les produits souhaités et supprimez les lignes que vous ne souhaitez pas modifier. Le code produit est facultatif et prend en charge 100 caractères. La séquence d'affichage est un nombre et est facultative.<br /><strong>Remarque :</strong> Si vous laissez les deux vides, le code et la séquence existants sont effacés.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Cliquez sur <strong class=\"text-uppercase\">Valider le catalogue</strong> pour que le système valide l'entrée.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"Importation terminée !",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"Validation terminée !",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Importer le catalogue",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Valider le catalogue",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez tous les clients",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez le département",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Téléchargez le catalogue pour obtenir les derniers détails sur les commissions sur les produits.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Remplissez le nom de l'agent, le SPCode, le PeNCEntage de commission, la date de début de la commission, la date de fin de la commission pour les produits souhaités et supprimez les lignes que vous ne souhaitez pas modifier. Toutes ces colonnes sont facultatives. <br /> Le nom de l'agent est un texte prenant en charge 100 caractères. <br />SPCode est un texte prenant en charge 100 caractères. <br />Commission PeNCEntage est un nombre qui prend également en charge les décimales. <br />La date de début de la commission est une date qui doit être définie au format <strong>{{dateFormat}}</strong> format.<br />La date de fin de la commission est une date qui doit être définie au format <strong>{ {dateFormat}}</strong> format.<br /><strong>Remarque :</strong> Si vous mettez à jour les valeurs existantes à vide, les valeurs existantes seront effacées une fois le catalogue importé.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Cliquez sur <strong class=\"text-uppercase\">Valider le catalogue</strong> pour que le système valide l'entrée.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }}<br />Succès : {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }}<br />Succès : {{ validationSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez un site",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Erreur",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"Voulez-vous supprimer le revendeur ?",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Les abonnements suivants n'ont pas d'offres équivalentes dans le plan. Veuillez mettre à jour le plan et réessayer.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"Impossible de trouver un client dans CSP avec l'identifiant : {{CSPCustomerId}}",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Les offres suivantes ont été achetées plus d'une fois dans un seul niveau, ce qui n'est pas pris en charge dans C3. Veuillez vérifier les données.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Supprimer",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Votre dernière opération de mise à jour semble avoir quelques erreurs, veuillez contacter le soutien technique pour obtenir de l'aide",
      "BUNDLE_PRODUCTS_PLACEHOLDER":"Produits en bouquet",
      "BUNDLE_REPORT_FILE_TYPES_HEADER":"Rapport détaillé sur les produits groupés",
      "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les détails du produit groupé",
      "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des détails du produit Bundle.",
      "BUNDLE_SAVE_SUCCESS":"Paquet {{customoffer}} enregistré avec succès",
      "BUNDLE_UPDATE_SUCCESS":"Le pack {{customoffer}} a été mis à jour avec succès",
      "bundles":"Forfaits",
      "BUNDLES_BILLING_CYCLE_TOOLTIP":"Fréquence de facturation à laquelle le bouquet serait facturé",
      "BUNDLES_BILLING_TYPE_TOOLTIP":"Catégorie de facturation sur laquelle le bouquet serait facturé",
      "BUNDLES_CANNOT_BE_DELETED":"Les bouquet ne peuvent pas être supprimés car ils sont affectés à un plan",
      "BUNDLES_SALETYPE_TOOLTIP":"Type de catégorie pour l'offre",
      "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"L'importation est en cours",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Veuillez patienter...",
      "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"La validation est en cours",
      "BUTTON_ACTIVATE":"Activer",
      "BUTTON_APPLY_COUPON_TEXT":"Appliquer un coupon",
      "BUTTON_CLOSE":"Fermer",
      "BUTTON_DEACTIVATE":"Désactiver",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Annuler",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Télécharger",
      "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Actualiser",
      "BUTTON_LANG_CN":"Chinois",
      "BUTTON_LANG_DE":"Allemand",
      "BUTTON_LANG_EN":"Anglais",
      "BUTTON_LANG_ES":"Espagnol",
      "BUTTON_LANG_FR":"français",
      "BUTTON_LANG_IT":"Italien",
      "BUTTON_LANG_SK":"slovaque",
      "BUTTON_LANG_TH":"thaïlandais",
      "BUTTON_LANG_TR":"turc",
      "BUTTON_LANG_TW":"Taïwanais",
      "BUTTON_LANGUAGE":"Langue",
      "BUTTON_MANAGE_PRODUCT":"Gérer",
      "BUTTON_ONHOLD":"En attente",
      "BUTTON_PROCESSING":"Traitement",
      "BUTTON_RELEASE":"Libérer",
      "BUTTON_REVOKE":"Révoquer",
      "BUTTON_START_TOUR":"Faites un tour",
      "BUTTON_TEXT_ACCEPT":"Accepter",
      "BUTTON_TEXT_ACTIONS":"Actions",
      "BUTTON_TEXT_ADD":"Ajouter",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Ajouter une nouvelle conversion de devises",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"Ajouter une politique personnalisée ",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Ajouter une nouvelle notification par courriel d'événement",
      "BUTTON_TEXT_ADD_SUB_TAX":"Ajouter une sous taxe",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Ajouter avec extensions",
      "BUTTON_TEXT_APPLY":"Appliquer",
      "BUTTON_TEXT_APPROVE":"Approuver",
      "BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "BUTTON_TEXT_CANCEL":"Annuler",
      "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Vérifier tous les statuts",
      "BUTTON_TEXT_CLEAR":"Effacer",
      "BUTTON_TEXT_CLOSE":"Fermer",
      "BUTTON_TEXT_DELETE":"Supprimer",
      "BUTTON_TEXT_DOWNLOAD":"Télécharger",
      "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Télécharger les gestionnaires de comptes",
      "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Télécharger le catalogue",
      "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Télécharger les clients",
      "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Télécharger le récapitulatif des journaux de publication des factures",
      "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Télécharger l'élément de ligne de facture",
      "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Télécharger la facture des cotisations",
      "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Télécharger les offres partenaires",
      "BUTTON_TEXT_DOWNLOAD_PLAN":"Télécharger le plan",
      "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Télécharger l'offre de plan",
      "BUTTON_TEXT_DOWNLOAD_REPORT":"Télécharger le rapport",
      "BUTTON_TEXT_EDIT":"Modifier",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Exporter vers Excel",
      "BUTTON_TEXT_FILTER":"Filtrer",
      "BUTTON_TEXT_LOAD_OFFERS":"Charger les offres",
      "BUTTON_TEXT_OK":"OK",
      "BUTTON_TEXT_OK_CLOSE":"Ok, ferme",
      "BUTTON_TEXT_OVERRIDE":"Remplacer",
      "BUTTON_TEXT_PAY_INVOICES":"Payer des factures en attente",
      "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Procéder à l'achat",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Enregistrer le paiement anticipé",
      "BUTTON_TEXT_REJECT":"Rejeter",
      "BUTTON_TEXT_REMOVE":"Supprimer",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Supprimer la sous taxe",
      "BUTTON_TEXT_REPORTS":"Rapports",
      "BUTTON_TEXT_RESET":"Réinitialiser",
      "BUTTON_TEXT_REVERT":"Rétablir",
      "BUTTON_TEXT_SAVE":"Enregistrer",
      "BUTTON_TEXT_SEARCH":"Rechercher",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Définir l'entité opérationnelle",
      "BUTTON_TEXT_SUBMIT":"Soumettre",
      "BUTTON_TEXT_UPDATE":"Mettre à jour",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"CHARGER SUR QUICKBOOKS ONLINE",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"CHARGER SUR XERO",
      "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Voir les parents",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"AFFICHER LES JOURNAUX DE PUBLICATION",
      "BUTTON_TEXT_VIEW_OFFERS":"Afficher les offres",
      "BUTTON_VIEW_ERRORS":"Afficher les erreurs",
      "BUTTON_VIEW_PRODUCT":"Afficher",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Ajouter balise",
      "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Modifier la balise",
      "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"pour",
      "C3_CART":"Panier",
      "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Catégorie de fournisseur",
      "C3_TEXT_ONHOLD_STATUS":"En attente",
      "c3supportAzuremargin":"Marge d'azur",
      "CALENDAR_CLEAR_BUTTON":"Effacer",
      "CALENDAR_CLOSE_BUTTON":"Fermer",
      "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Veuillez saisir le motif de l'annulation de la commande",
      "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Un motif est requis pour annuler la commande programmée",
      "CANCEL_SCHEDULED_ORDER_SUCCESS":"La commande est annulée avec succès",
      "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"annuler la commande",
      "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Annuler la commande programmée",
      "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Un motif est requis pour annuler le produit programmé",
      "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Une raison est requise pour annuler la commande",
      "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"Le produit a été annulé avec succès de cette commande",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Renouvellement programmé annulé en raison d'un changement dans le statut de renouvellement automatique",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Renouvellement planifié annulé en raison d'une mise à jour de la quantité",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Renouvellement programmé annulé en raison du rejet de la demande de renouvellement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Annulation du renouvellement planifié car la propriété de planification est modifiée depuis le Centre des partenaires",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Renouvellement programmé annulé en raison d'un changement de statut d'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Renouvellement programmé annulé en raison d'une mise à jour de l'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Renouvellement programmé annulé en raison d'une mise à niveau de l'abonnement",
      "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"Échec de l'annulation du renouvellement programmé",
      "CANCEL_SCHEDULED_RENEWAL_REASON":"Raison",
      "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Veuillez fournir une raison",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Ce champ est obligatoire",
      "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Le renouvellement programmé a été annulé avec succès",
      "CANCELLED_REASON":"Motif annulé",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Impossible de supprimer une ancienne définition de conversion de devise",
      "CANNOT_INITIATE_PAYMENT":"Impossible de lancer un paiement à cause d'une transaction en attente",
      "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Ce produit a déjà été acheté par le client, il n'est pas possible de lier le produit.",
      "CanPriceLag":"CanPriceLag",
      "CanPriceLead":"CanPriceLead",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Demande d'une relation de revendeur",
      "CARD_CARD_DELETE_ADDON":"Supprimer",
      "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Centre d'échange",
      "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Données d'utilisation du produit",
      "CART_CARD_COMMENT_ACTION":"Commentaires",
      "CART_CARD_DELETE_PRODUCT":"Supprimer",
      "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans votre panier. Voulez-vous toujours continuer ?",
      "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Votre locataire n'est pas éligible pour acheter Azure Plan. Raisons possibles : soit vous disposez déjà d'un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans l'Espace partenaires et nécessite une mise à niveau. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "CART_CART_ITEM_Azure_PLAN_INELIGIBLE_WARNING":"Votre locataire n'est pas éligible pour acheter le plan Azure . Raisons possibles : soit vous disposez déjà d'un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans le Partner Center et nécessite une mise à niveau. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Voulez-vous vraiment modifier le domaine pour l'offre sélectionnée ?",
      "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Date de fin personnalisée enregistrée avec succès",
      "CART_DISABLE_PROMOTION":"Désactivez la promotion du produit.",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Une erreur s'est produite lors de la mise à jour du domaine pour l'offre sélectionnée. Veuillez contacter le soutien technique",
      "CART_GENERIC_ERROR_MESSAGE":"Une erreur s'est produite lors de la finalisation de la commande. Veuillez vérifier auprès du Partenaire avant de réessayer",
      "CART_ITEM_EFFECTIVE_PRICE":"Prix effectif",
      "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Commande pour Site/Département",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Domaine",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Panier non disponible à la caisse ou déjà traité !",
      "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"Vous ne pouvez pas planifier ce panier car il n'est pas disponible ou est déjà programmé",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Produit lié",
      "CART_PAGE_AUTORELEASE":"Libération automatique",
      "CART_PAGE_Azure_PLAN_DISCOUNT_INFORMATION":"{{discount}}% de réduction",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":"{{discount}}% de réduction",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Rabais",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Supprimer",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Appliquer",
      "CART_PAGE_DISCOUNT_INFORMATION":"(rabais de {{discount}} %)",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Types de consommation",
      "CART_PAGE_TEMPLATE_ADD_COMMENT":"Ajouter un commentaire",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong>Bonjour !</strong> Votre panier est vide. <a ng-click='vm.GotoCart()' class='alert-link'> Cliquez ici pour magasin. <span class='fa fa-cart-plus'></span> </a>",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>Bonjour !</span> Votre panier est vide.",
      "CART_PAGE_TITLE_PRICE":"Prix",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Date de fin personnalisée",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Sélectionnez un type de date de fin personnalisé",
      "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Sélectionnez",
      "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Sélectionnez",
      "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Version automatique mise à jour avec succès",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Une erreur s'est produite lors du passage à la caisse",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Commande passée avec succès. OrderId : {{orderId}}",
      "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande de conversion de l'offre d'essai {{productName}} en abonnement",
      "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Demande de conversion de l'offre d'essai de {{productName}} en abonnement envoyée avec succès",
      "CART_PRODUCT_DELETE_CONFIRMATION":"Voulez-vous vraiment supprimer cet article du panier ?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Une erreur s'est produite lors de la suppression de l'article du panier !",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"Suppression de l'élément demandé avec succès !",
      "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Une erreur s'est produite lors de la vérification de l'éligibilité à la promotion",
      "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"L'ID de promotion disponible est valable sur la promotion.",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Quantité mise à jour avec succès",
      "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Une erreur s'est produite lors de la planification de la commande",
      "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"La commande a été planifiée avec succès.<br/>OrderId est : {{orderId}}",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans vos produits achetés. Voulez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Ce produit a déjà été acheté il y a {{days}} jours. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Ce produit a déjà été acheté il y a {{hours}} heures. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Ce produit a déjà été acheté il y a {{minutes}} minutes. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Ce produit a déjà été commandé il y a {{days}} jours. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Ce produit a déjà été commandé il y a {{hours}} heures. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Ce produit a déjà été commandé il y a {{minutes}} minutes. Souhaitez-vous toujours continuer ?",
      "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Cette offre d'essai est déjà achetée.",
      "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"Sur ce produit, la commande programmée est présente. Voulez-vous toujours continuer ?",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Mise à jour du locataire au regard de l'offre sélectionnée avec succès !",
      "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Une erreur s'est produite lors de la mise à jour du champ de paiement instantané dans le panier",
      "CATEGORY_DESC_ALL":"Tout",
      "CATEGORY_DESC_AZURE":"Azure",
      "CATEGORY_DESC_AZURE_PLAN":"Plan Azure",
      "CATEGORY_DESC_BUNDLES":"Forfaits",
      "CATEGORY_DESC_CUSTOM":"Personnalisé",
      "CATEGORY_DESC_CUSTOM_TRIAL":"Essai personnalisé",
      "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Offres des distributeurs",
      "CATEGORY_DESC_LICENSE_SUPPORTED":"Licence prise en charge",
      "CATEGORY_DESC_NCE":"NCE",
      "CATEGORY_DESC_NON_CSP_AZURE":"Azure non CSP",
      "CATEGORY_DESC_ONE_TIME":"Une fois",
      "CATEGORY_DESC_ONLINCE_SERVICES":"Services en ligne",
      "CATEGORY_DESC_ONLINE_SERVICES_NCE":"Services en ligneNCE",
      "CATEGORY_DESC_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "CATEGORY_DESC_RESERVED_INSTANCES":"RI",
      "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Abonnements logiciels",
      "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Licence prise en charge",
      "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Instance réservée",
      "CATEGORY_FILTER_DESC_NCE":"NCE",
      "CHAMP OBLIGATOIRE":"{{atribute}} est requis",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Êtes-vous sûr, voulez-vous désactiver ce coupon",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Êtes-vous sûr, voulez-vous activer ce coupon",
      "CHANGE_PLAN_CHANGE_LABEL_SOUNCE_PLANS":"Plan SouNCE",
      "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Forfait source",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Plan cible",
      "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":"Annuler l'attribution des plans après le passage à un nouveau forfait",
      "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Une erreur s'est produite lors du changement de plan.",
      "CHANGE_PLAN_HELP":"Aide",
      "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} n'est pas mappé avec le bon produit enfant {{secondProduct}}",
      "CHANGE_PLAN_INSTRUCTIONS_1":"Suivez ces instructions pour déplacer les produits du client d'un plan existant vers un plan nouvellement sélectionné.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Plan SouNCE </b>– Répertorie les plans associés aux produits achetés du client. Sélectionnez un ou plusieurs plans si vous souhaitez opérer sur les produits associés.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Plan cible</b> – Sélectionnez le nouveau plan tarifaire.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Désattribuer les plans après le passage à un nouveau plan</b>– Ceci est pratique car le système dissocierait le client d'un plan existant s'il ne reste plus de produits dans le plan souNCE. Aide à résoudre la confusion des utilisateurs clients dans la page de la boutique.",
      "CHANGE_PLAN_INSTRUCTIONS_2":"La page s'actualise pour répertorier les produits achetés à partir du plan souNCE sur la gauche et l'option de mappage vers un équivalent du plan cible, sur la droite.",
      "CHANGE_PLAN_INSTRUCTIONS_3":"Mappez les produits et soumettez la demande pour que le système exécute le changement.",
      "CHANGE_PLAN_INSTRUCTIONS_4":"Les journaux d'audit vous aident avec les détails de l'activité pour référence ultérieure.",
      "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Remarque : les modifications de prix dues à un changement de plan entreraient en vigueur immédiatement.</b>",
      "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Instructions pour passer au plan",
      "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}",
      "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"La quantité partagée doit correspondre à {{firstProduct}}",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOUNCE_PLANS":"Sélectionnez les plans souNCEs que vous souhaitez modifier",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Sélectionnez les plans sources que vous souhaitez modifier",
      "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Sélectionnez le plan cible",
      "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"Aucun produit correspondant dans le plan cible",
      "CHANGE_PLAN_STATUS_FAILED":"Échoué",
      "CHANGE_PLAN_STATUS_SUCCESS":"Réussite ",
      "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan modifié avec succès.",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Passer à un produit du plan cible",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"Produits achetés",
      "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Catégorie",
      "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":"Produit lié",
      "CHANGE_PLAN_TABLE_TEXT_OFFER":"Offre:",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":"Prix",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Fournisseur",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Quantité",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Valide",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOUNCEPLANS":"Les plans sélectionnés ont une configuration de devise différente.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Les plans sélectionnés ont une configuration de devise différente.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"Le produit du plan est en état de provisionnement.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Veuillez sélectionner le produit.",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour du statut du produit acheté : ",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Êtes-vous sûr, voulez-vous discontinuer cette offre",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Êtes-vous sûr, voulez-vous désactiver ce plan",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Êtes-vous sûr, voulez-vous activer ce plan",
      "Charge":"Charge",
      "charity":"Charité",
      "Charity":"Charité",
      "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Activer toutes les promotions",
      "CHOISIS UNE OPTION":"Choisis une option",
      "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Choisissez un cycle de facturation",
      "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Choisissez un type de facturation",
      "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Choisissez un type de consommation",
      "CHOOSE_AN_OPTION":"Sélectionner une option",
      "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Choisir le client",
      "CHOOSE_INVOICE_PDF_LANGUAGE":"Choisir la langue du PDF de la facture",
      "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"Le PDF de la facture sera disponible dans la langue sélectionnée",
      "CHOOSE_ONE_RG":"Sélectionner au moins un groupe de ressources",
      "CHOOSE_RESOURCE_GROUP":"Sélectionner une politique",
      "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"Voulez-vous vraiment effacer l'icône de ce produit ? Le produit obtiendrait l'icône par sa catégorie lorsqu'il est effacé ici.",
      "CLICK":"Cliquer",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Cliquer",
      "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Cloner la conversion de devises",
      "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Veuillez sélectionner une date",
      "CLONE_PLAN_FAILURE":"Impossible de cloner le plan",
      "CLONE_PLAN_SUCCESS":"Plan dupliqué avec succès",
      "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Pas maintenant",
      "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Pas maintenant",
      "COLLAPSE_ALL_PAYMENTS":"Effondrement des paiements",
      "COMMENT_AUTHOR":"Auteur",
      "COMMENTS_LATEST_COMMENT":"Dernier commentaire",
      "commercial":"Commercial",
      "Commercial":"Commercial",
      "COMMISION_REPORT_SEARCH":"Rechercher",
      "COMMISION_REPORTS":"Rapport de commission",
      "Commission Report":"Rapport de commission",
      "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Toutes périodes",
      "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez tous les clients",
      "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez le département",
      "COMMISSION_REPORT_HEADER_TEXT":"Rapport de commission",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Fermer",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Réinitialiser",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Rechercher",
      "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez un site",
      "COMMISSION_REPORTS":"Rapport de commission",
      "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Rapport de commission",
      "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Rapport sur les revenus",
      "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Date de fin de commission",
      "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Date de début des commissions",
      "COMMISSION_TABLE_HEADER_COMMISSION_PENCENT":"Commission(%)",
      "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Commission(%)",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Nom du domaine",
      "COMMISSION_TABLE_HEADER_ENTITY_ID":"ID d'entité",
      "COMMISSION_TABLE_HEADER_ORDERED_DATE":"Date de commande",
      "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"Planifier l'identifiant du produit",
      "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agent",
      "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Montant des commissions",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Client",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"ID de produit du fournisseur",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"ID de produit du fournisseur",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"ID d'abonnement au produit",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Nom de l'abonnement au produit",
      "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Nom du produit de l'achat",
      "COMMISSION_TABLE_HEADER_RECORD_ID":"ID d'enregistrement",
      "COMMISSION_TABLE_HEADER_SP_CODE":"Code SP",
      "COMMON_LOADING_MESSAGE":"Chargement ...",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Êtes-vous sûr de vouloir approuver cette facture ?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Êtes-vous sûr de vouloir finaliser cette facture ?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Vous avez des modifications non enregistrées. Cliquez sur OK pour quitter sans enregistrer ou sur Annuler pour rester sur la page",
      "CONFIRMATION_POPUP_MESSAGE":"Êtes-vous certain de vouloir activer cet abonnement ?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Êtes-vous certain de vouloir annuler la demande relative à cet abonnement ?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"Activer",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"Annulé",
      "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"En cliquant sur « Approuver », les articles de la ligne de devis seront achetés. <br/> <b>Remarque</b> : la limite de siège/transaction ne sera pas applicable.",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Voulez-vous supprimer cet ajustement ?",
      "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>En cliquant sur approuver, le statut du devis sera mis à jour sur approuvé.</p></li><li><p style='text-align:left;'>Le propriétaire du devis doit effectuer manuellement l'achat au nom du client.</p></li></ul>",
      "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"Voulez-vous mettre à jour le nom de l'abonnement ?",
      "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"Êtes-vous sûr de vouloir mettre à jour le numéro de bon de commande ?",
      "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"Voulez-vous vraiment mettre à jour le nom du produit ?",
      "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":"Voulez-vous mettre à jour le nom de l'abonnement s'il est partagé entre les entités enfants dans C3 ?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"Êtes-vous sûr de vouloir annuler ?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Voulez-vous continuer ?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Êtes-vous sûr de vouloir continuer avec la sélection actuelle ?",
      "CONSENT_CREATION_FAILED":"La création du consentement a échoué",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Activez cette option pour appliquer des frais de traitement aux paiements par carte de crédit.",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"Inclure les frais de traitement de carte de crédit ?",
      "CONSTANT_EMPTY_STRING":"--Sélectionner--",
      "CONSTANT_EVERY_DAY":"Tous les jours",
      "CONSTANT_EVERY_MONTH":"Chaque mois",
      "CONSTANT_EVERY_TWO_WEEKS":"Chaque deux semaines",
      "CONSTANT_EVERY_WEEK":"Chaque semaine",
      "CONSTANT_FULL_AUTOMATIC_MAPPING":"Mappage automatique",
      "CONSTANT_FULL_MANUAL_MAPPING":"Cartographie manuelle",
      "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Cartographie manuelle avec création de service",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Montant",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Produit",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Quantité",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Siège",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Sièges de clôture",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Différence",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Ouverture des sièges",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"Contrat",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"Quantité",
      "CONSUMPTION_TYPES_DESC_SERVICE":"Service",
      "CONSUMPTION_TYPES_DESC_USAGE":"Utilisation",
      "CONTACT_CANCEL":"Voulez-vous annuler?",
      "CONTACT_HEADER_TEXT":"Vous avez une question?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Nos contactes",
      "CONTACT_LABEL_TEXT_EMAIL":"Courriel",
      "CONTACT_LABEL_TEXT_MESSAGE":"Message",
      "CONTACT_LABEL_TEXT_NAME":"Nom",
      "CONTACT_PARAGRAPH_TEXT":"Contactez-nous en remplissant le formulaire suivant et nous vous répondrons sous peu.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Merci de nous avoir fait part de vos commentaires. Notre équipe vous contactera sous peu.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Votre commentaire a été soumis avec succès.",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Ajouter",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Annuler",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Modifier",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Sélectionnez un type de facturation",
      "CONTRACT_OFFER_BREADCRUMB":"Offre de contrat",
      "CONTRACT_OFFER_FEEDSOUNCE_TOOLTIP":"SouNCE sur laquelle le contrat serait créé",
      "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Source sur laquelle le contrat serait créé",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ce bloc ?",
      "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Plage d'utilisation sur laquelle le contrat s'appliquerait",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Voulez-vous vraiment désactiver cette offre de contrat ?",
      "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Service contractuel supprimé d'Autotask",
      "COOKIE_CONSENT_MESSAGE":"L'application utilise des témoins pour vous offrir une meilleure expérience. En accédant à l'application, vous acceptez notre utilisation de témoins.",
      "COOKIE_POLICY_BUTTON_TEXT":"POLITIQUE EN MATIÈRE DE TÉMOINS",
      "corporate":"Entreprise",
      "Corporate":"Entreprise",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Réinitialiser",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Rechercher",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Clients",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Plans",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Revendeurs",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Sélectionner des clients",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Sélectionner des plans",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Sélectionner des revendeurs",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filtrer",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Prix du fournisseur",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Quantité",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Coût pour le client",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Coût pour le partenaire",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Extension",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Forfait",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Lié",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"Non intégré",
      "coupon":"Coupon",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"Le coupon sera appliqué à tous les clients",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"Le coupon ne sera pas appliqué aux clients même s'ils sont étiquetés avec le plan sélectionné",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"Le coupon sélectionné est un coupon direct (sans plan).",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Les clients ne peuvent avoir qu'un seul coupon direct à la fois.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Veuillez noter que si des clients sélectionnés ont des attributions de coupons existantes, ils seront supprimés.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Coupon Direct",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"Le coupon sélectionné est un coupon de plan.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"Les clients associés à ce plan ne peuvent être attribués qu'avec ce coupon.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Coupon d'abonnement",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de Coupon sauvegardé avec succès",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Coupon",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Clients",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Actif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inactif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Actif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inactif",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Ajouter une nouvelle attribution de coupon",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Modifier",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Action",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Assigné à",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Applicable jusqu'au",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Statut",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"code promo",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Code promotionnel",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Description de coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"nom du coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Nom du coupon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Statut",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Est actif",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Veuillez sélectionner un coupon valide",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Le coupon sélectionné est disponible pour ce client.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Ajouter un coupon",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Retour aux coupons",
      "COUPON_CAPTION_TEXT_ADD":"Ajouter un coupon",
      "COUPON_CAPTION_TEXT_EDIT":"Modifier le coupon",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Sélectionner toutes les offres",
      "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Effondrement",
      "COUPON_DETAILS_TOOLTIP_EXPAND":"Agrandir",
      "COUPON_DISABLE_BUTTON":"Désactiver le coupon",
      "COUPON_DISCOUNT_TOOLTIP":"Remise peNCEntage à appliquer sur le coupon",
      "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Veuillez sélectionner une valeur de remise entre 1 et 100.",
      "COUPON_EDIT_BUTTON":"Modifier le coupon",
      "COUPON_ENABLE_BUTTON":"Activer le coupon",
      "COUPON_HEADER_ACTION":"Action",
      "COUPON_OWNERSHIPS_CATEGORY":"Catégorie",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Appliquer à tous les clients tagués",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"La sélection de cette case à cocher attribuera le coupon à tous les clients associés au plan sélectionné. Cependant, vous pouvez gérer les affectations dans l'écran des affectations de coupons.",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"Applicabilité",
      "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Promotion disponible",
      "COUPON_OWNERSHIPS_PLAN":"Plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Offres de plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Prix effectif",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Est sélectionné",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Nom de l'offre",
      "COUPON_OWNERSHIPS_PROVIDER":"Fournisseurs",
      "COUPON_OWNERSHIPS_SALETYPE":"Type de vente",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Applicable à",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Vous devez sélectionner au moins un produit du plan.",
      "COUPON_RECORDS_VALIDATION_MESSAGE":"Vous devez sélectionner au moins une {{entity}}.",
      "COUPON_SAVE_SUCCESS":"Coupon {{coupon}} sauvegardé avec succès",
      "COUPON_STATUS_FLAG_ACTIVE":"Actif",
      "COUPON_STATUS_FLAG_EXPIRED":"Expiré",
      "COUPON_STATUS_FLAG_INACTIVE":"Inactif",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Code promotionnel",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Nom du coupon",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Expire le",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Nom du plan",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Applicable aux nouveaux clients",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Applicable aux anciens clients",
      "COUPON_TABLE_HEADER_TEXT_code":"code",
      "COUPON_TABLE_HEADER_TEXT_CODE":"Code",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Créé le",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Rabais",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Expire le",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Est actif",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"Est un Pourcentage",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Est publique",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Remise maximale",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Rachats maximum",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Montant minimum",
      "COUPON_TABLE_HEADER_TEXT_NAME":"Nom",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Récurrences",
      "COUPON_TABLE_HEADER_TEXT_planname":"nom du plan",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Nom du plan",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Valide jusqu'à",
      "COUPON_UPDATED_SUCCESS":"Coupon {{coupon}} mis à jour avec succès",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"La date ValideJusqua doit être plus grande que la date ExpireLe ",
      "couponassignment":"CouponAssignment",
      "coupondetails":"Détails du coupon",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Ajouter une nouvelle attribution de coupon",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Assignations de coupons",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Modifier attribution de coupon",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Date limite pour appliquer le coupon au moment de l'achat",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Statut du coupon",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Coupons",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Date limite pour prendre le coupon en compte lors de la facturation après son application",
      "couponstatus":"Statut du coupon",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Le lien pour initier la procédure de consentement est copié dans votre presse-papiers",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Cliquer <b><a ngclipboard data-clipboard-text='{{consentURL}}' ng-click='confirmCopy()'></b> Copier le lien sur le presse-papiers</a> pour copier le lien qui capture le consentement du partenaire à autoriser l'accès à cette application au centre partenaire selon le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Ouvrez le lien dans une session de navigation privée et complétez la procédure de consentement à l'aide d'un compte d'administrateur global dans votre domaine (partenaire) et auquel le rôle d'agent admin lui est affecté dans le centre partenaire.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquer <b>Basculer vers le nouveau modèle de sécurité</b> (activé après le succès du test) pour permettre à l'application d'effacer vos anciens identifiants et basculer vers le nouveau modèle de sécurité.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Copier le lien vers le presse-papiers< /b></a></b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":"pour accorder à cette application l'accès aux API de gestion Azure, ce lien capturera votre consentement en tant qu'administrateur client.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Après avoir copié le lien, ouvrez-le dans une nouvelle session de navigation privée. Ensuite, donnez votre consentement pour le rôle d’administrateur client. Cette étape est cruciale pour faire avancer le processus.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec les API de gestion Azure",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instructions pour passer au nouveau modèle de sécurité",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Instruction pour fournir le consentement de l'administrateur à l'API de gestion Azure",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Instruction pour fournir le consentement de l'administrateur client par l'utilisateur client",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Instructions",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Copier le lien vers le presse-papier</a> </b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" pour copier le lien qui capture le consentement de l'administrateur du client pour accorder à cette application l'accès aux API de gestion Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Une fois que vous avez copié le lien, partagez-le avec le client via son canal de communication préféré, tel que le courrier électronique. Ensuite, demandez leur approbation pour le rôle d’administrateur client.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec les API de gestion Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"OU Vous pouvez informer votre client de se connecter au portail C3 -> profil -> Microsoft Dans la section, fournissez le consentement nécessaire. Obtenir le consentement du client est crucial pour que nous puissions aller plus loin.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Voici une liste de rapports qui peuvent être préparés et exécutés à l'heure prévue.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Veuillez utiliser l'icône Destinataires pour ajouter les destinataires au rapport.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"Vous pouvez également vérifier le nombre de fois où le rapport a été exécuté dans le passé et qui l'a reçu.",
      "CPV_LOADING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Merci d'avoir fourni votre consentement. L'application peut être configurée pour accéder au centre partenaire suivant la nouvelle directive de sécurité.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions retrouvées sur Paramètres -> Centre partenaire dans <b>{{applicationName}}</b>.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Tentative d'accès au centre partenaire à l'aide des nouveaux paramètres de sécurité réussie.",
      "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"La tentative d'accès à l'API de gestion Microsoft Azure a réussi.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Numéro de carte ",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Mois",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"04-Avr",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"08-Aou",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12-Déc",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"02-Fev",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"01-Jan",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"07-Juil",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"06-Juin",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"03-Mar",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"05-Mai",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11-Nov",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Oct",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"09-Sep",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Nom sur la carte",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Année",
      "CREATE_BANNER_NOTIFICATIONS":"Créer une bannière de notification",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"Impossible de créer le coupon",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Coupon créé avec succès",
      "CREATE_CUSTOMER_EXCEPTION":"Une erreur s'est produite lors de la création du client",
      "CREATE_CUSTOMER_USER_ROLE":"Créer des utilisateurs clients",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Impossible de créer des utilisateurs clients",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Créer une facture",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Créer un client fournisseur sous un revendeur logique",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Autoriser le revendeur logique à créer un client fournisseur",
      "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Les offres d'utilisation ajoutées au plan revendeur seront de type de facturation <b>Markup</b>. Veuillez définir le balisage pour les offres d'utilisation une fois le plan créé avec succès.",
      "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Gestionnaire de compte créé avec succès",
      "CREATED_SUCCESSFULLY":"a été créé avec succès",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Il semble que les détails de l'ID MPN manquent pour le revendeur. Veuillez contacter le Partenaire pour mettre à jour les détails.",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"La création d'un nouveau Locataire de Fournisseur de Services n'est pas prise en charge !",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"Le client sera créé dans l'espace partenaire sous le compte partenaire",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Frais en pourcentage pour le traitement des cartes de crédit.",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Frais de traitement de carte de crédit %",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Veuillez entrer au moins un nom de taxe et un pourcentage pour les taxes cumulatives.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Règle de conversion de devise ajoutée avec succès",
      "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Cloner la devise",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Retour à la conversion des devises",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Ajouter",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Conversion de devise",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"conversion de devise soumise avec succès",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Modifier",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"Règle de conversion de devise supprimée avec succès",
      "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Mois de facturation en cours",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Tout",
      "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Dernier mois de facturation",
      "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Mois de facturation suivant",
      "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Sélectionnez une date",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Taux de conversion",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Client",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Devise source",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Devise cible",
      "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Période de temps",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Taux de conversion",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Client",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Devise source",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Statut",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Devise cible",
      "CURRENCY_CONVERSION_RATE_SUCCESS":"Saisissez un taux de conversion valide.",
      "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"La règle de conversion de devise a été créée avec succès.",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Règle de conversion de devise mise à jour avec succès",
      "CurrencyCode":"Code de devise",
      "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Icône actuelle",
      "CURRENT_PARTNER_PRODUCT_ICON":"Icône actuelle",
      "CURRENT_SUBSCRIPTION_DETAILS":"Détails de l'abonnement actuel",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Détails d'utilisation pas encore disponibles, veuillez réessayer plus tard.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Quantité",
      "Custom Notifications":"Notifications personnalisées",
      "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Si vous choisissez de planifier une commande pour une date future et que vous avez spécifié une date de fin personnalisée, sachez que la date de fin personnalisée sera automatiquement réinitialisée à sa valeur par défaut après avoir sélectionné votre commande planifiée pour le futur.",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOM_ICON_HELP_TEXT":"Taille recommandée : 150 x 175. Seuls les fichiers de type jpg, svg, png, gif, bmp sont supportés",
      "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"Le nom de l'élément de ligne doit comporter moins de 150 caractères.",
      "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Ajouter des entités balisées",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Le corps du texte",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"Identifiant de notification",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Modèle",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Titre",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Le Lorem Ipsum est le texte factice standard de l'industrie depuis les années 1500, lorsqu'un imprimeur inconnu a pris une galère de caractères et l'a brouillé pour en faire un livre de spécimens de caractères.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Offrir des remises pour votre boutique en ligne peut être une arme puissante pour fidéliser.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Stimulez le marketing &amp; confiance dans les produits de vente.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Offrir des rabais pour une meilleure vente en ligne dans un magasin peut fidéliser la conduite.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Commencez par une conception de site moderne et personnalisez-la avec le contenu et les fonctionnalités de votre marque. Tous les blogs Premium incluent un CSS personnalisé.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Commencez par une création de contenu moderne de conception de site de marque.",
      "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Créer une notification personnalisée",
      "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Créer une notification personnalisée",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Annuler",
      "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Fermer les filtres",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Nom du plan",
      "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Entrez la description",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Sélectionner des plans",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Choisissez la date d'entrée en vigueur",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Choisir en vigueur à ce jour",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Date de fin",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Attribuer une licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Modifier le statut du produit client",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Gérer la propriété du produit",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Gérer la licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Intégrer un client",
      "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Acheter des produits",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Diminution de la quantité",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Augmentation de la quantité",
      "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Supprimer la licence utilisateur",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Mettre à jour la licence",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Une erreur s'est produite lors d'événements de notification personnalisés",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Actions",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Description",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Entités marquées",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Titre",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Description",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Efficace pour",
      "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Entité",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Événement",
      "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Modèle",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Titre",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Notification personnalisée",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Réinitialiser",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Soumettre",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notification personnalisée enregistrée avec succès",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Rechercher",
      "CUSTOM_NOTIFICATION_SELECT":"Sélectionner",
      "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Sélectionner des produits",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Types de consommation",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Fournisseurs",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Date de début",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Entités marquées",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Éléments d'entité balisés",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Actions",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Éléments",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Entité",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Événement",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Afficher les entités marquées",
      "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Choisir le modèle",
      "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Cela dépasse la longueur maximale de 30",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Commencer ici",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Rejoignez SAP maintenant pour bénéficier de 35 % de réduction",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Confiance des utilisateurs",
      "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Sélectionnez le titre",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Mettre à jour",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notification personnalisée mise à jour avec succès",
      "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"Notification de l'interface utilisateur",
      "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"Est-ce qu'il s'agit d'une offre d'essai ?",
      "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"Est-il auto-renouvelable",
      "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Quantité par défaut",
      "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Produits en offre d'essai",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Type de consommation",
      "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Catégorie",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Offres partenaires",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Nouvelle offre de contrat",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Ajouter une offre personnalisée",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Retour aux offres personnalisées",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Applicabilité",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Ajouter",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Offres de contrat",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Offres partenaires",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Modifier",
      "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"DURÉE DE L'ESSAI",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Sélectionner les valeurs par défaut telles que 'Charger les périodes' comme 'Default', 'Règle de facturation pour l'achat de licences’ et ‘Règle de facturation pour la réduction des licences’ comme ‘Prorate’",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Si vous voulez ajouter l'offre personnalisée à l'offre composite, vous devez suivre les instructions suivantes ",
      "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Quantité d'approbation",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Charger les périodes",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Comment voulez-vous que les périodes de facturation pour les abonnements soient? ",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "CUSTOM_OFFERS_LABEL_TEXT_Description":"Description",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOM_OFFERS_LABEL_TEXT_DISPLAY_NAME":"Nom d'affichage",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiatement ?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Instructions",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"L'offre est-elle une extension ?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Nom",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nombre de jours pour l'annulation gratuite",
      "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"ID de référence de l'offre",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les changements de quantité sont calculés au prorata ou à pleine charge pour la nouvelle quantité",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les changements de quantité doivent être calculés au prorata ou à pleine charge",
      "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icône",
      "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Propriétés",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Prix de vente",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Valide",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Ce que vous désirez appliquer comme période de facturation pour les abonnements",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Quel type de consommation voulez-vous que les abonnements aient?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Nombre de jours maximum permis sans frais d'annulation.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité doivent être calculées au prorata ou à frais complets",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Fournisseur",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Bloc",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validité de l'offre.",
      "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Sélectionnez une image",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"Offre personnalisée {{customoffer}} sauvegardée avec succès",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Inclure les offres supprimées",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Offres d'essai",
      "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Sélectionnez le cycle de facturation",
      "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Sélectionnez la durée du mandat",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Coût d'acquisition",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Détails de l'offre",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Aller à l'écran des étiquettes de produit",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Afficher",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(Supprimé)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Ceci est le prix auquel vous avez obtenu ce composant. Vous pouvez spécifier le prix de vente pour cette offre lorsque vous l'incluez dans un plan.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"C'est le prix auquel vous vendez ce composant.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"L'offre partenaire {{customoffer}} mise à jour avec succès",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Les dates de début et de fin des frais seront les dates de début et de fin d'un mois.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Les dates de début et de fin des frais seront alignées avec les dates de début et de fin de facturation MS.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Les dates de début et de fin des frais seront alignées avec les dates de début et de fin de facturation partenaire.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Aligner avec le mois du calendrier",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"Aligner avec la facturation MS",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"Aligner avec la facturation du partenaire",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Aligner avec la facturation du fournisseur",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Par défaut (à partir de la date d'achat)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Définir un nouveau mot de passe",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Êtes-vous sûr de vouloir réinitialiser le mot de passe ?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Un nouveau mot de passe sera généré et le mot de passe actuel de l'utilisateur ne fonctionnera plus.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Entrer un nouveau mot de passe",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Montrer le mot de passe ",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Mot de passe généré automatiquement",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Mot de passe personnalisé",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Minimum 8 caractères. Contient au moins une majuscule, une minuscule, un caractère spécial, et un caractère numérique",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Veuillez choisir un mot de passe d'au moins 8 caractères.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Le mot de passe personnalisé ne doit pas contenir le prénom de l'utilisateur.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Politique activée avec succès",
      "CUSTOM_POLICY_ADD_TITLE":"Ajouter une politique personnalisée ",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Règles affectées",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Politique affectée avec succès",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Affecter une politique",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"PolicyId Azure ",
      "CUSTOM_POLICY_CUSTOMER_NAME":"Nom du client",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Politique désactivée avec succès",
      "CUSTOM_POLICY_DESCRIPTION":"Description",
      "CUSTOM_POLICY_INSTRUCTIONS":"Pour créer une politique personnalisée, suivez les étapes ci-dessous",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Fournissez un nom et une description pour la politique personnalisée ",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Fournir une définition appropriée",
      "CUSTOM_POLICY_LABEL_SCOPE":"Portée",
      "CUSTOM_POLICY_NAME":"Nom",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Affectation de la politique",
      "CUSTOM_POLICY_POLICYDEFINITION":"Définition",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Nom du groupe de ressources",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Politique révoquée avec succès",
      "CUSTOM_POLICY_SAVE_SUCCESS":"La politique {{policy}} a été enregistrée avec succès",
      "CUSTOM_POLICY_SUBSCRIPTION":"Nom de l'abonnement",
      "CUSTOM_POLICY_TITLE":"Politiques personnalisées",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Pour affecter la politique au niveau de l'abonnement, sélectionnez tous les groupes de ressources.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rôle activé avec succès",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"RoleId Azure",
      "CUSTOM_ROLE_CUSTOMER_NAME":"Nom du client",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rôle désactivé avec succès",
      "CUSTOM_ROLE_DESCRIPTION":"Description",
      "CUSTOM_ROLE_INSTRUCTIONS":"Pour créer un rôle personnalisé, suivez les étapes ci-dessous",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Fournissez un nom et une description pour le rôle personnalisé",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Entrez uniquement les champs dans les sections « Action » et « Non action » pour la définition de rôle.",
      "CUSTOM_ROLE_NAME":"Nom",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Nom du groupe de ressources",
      "CUSTOM_ROLE_ROLEDEFINITION":"Définition de rôle",
      "CUSTOM_ROLE_SAVE_SUCCESS":"Le rôle {{rôle}} a été enregistré avec succès",
      "CUSTOM_ROLE_SUBSCRIPTION":"Nom de l'abonnement",
      "Customer":"Client",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Est imposable",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Mettre à jour le panier",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Catégorie",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Prix par \"Seed\"",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Extensions",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"J'autorise / Nous autorisons Ezidebit Pty Ltd ACN 096 902 813 (Identifiant d'utilisateur numéro 165969, 303909, 301203, 234040, 234072, 428198) à débiter mon / notre compte à l'institution financière identifiée ci-dessus à travers le Bulk Electronic Clearing System (BECS), conformément à cette demande de prélèvement automatique et conformément au contrat de service Ezidebit DDR. <br> J'autorise / Nous autorisons ces paiements à être débités à des intervalles et selon les montants indiqués par BeCloudSmart conformément aux conditions générales du contrat BeCloudSmart et aux accords ultérieurs.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Accord",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Contrat Ezidebit",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Contrat de revendeur Microsoft Cloud",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Conditions d'utilisation du partenaire",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Lire l'accord Ezidebit",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Veuillez lire le",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Paiement enregistré avec succès.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Le processus d'intégration des détails de facturation du client est en cours",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Procéder à l'intégration",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Détails de facturation des clients intégrés avec succès.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Le client existe déjà dans le système de facturation",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Correspondance trouvée pour l'identifiant Id : <strong> {{billingProviderReferenceID}} </strong> dans le système de facturation.<br/><span> L'adresse de facturation dans le fichier est <strong> {{formattedAddress}} </strong>.</span> <br/>Veuillez personnifier l'identité et utiliser l'option <strong>Profile</strong> pour modifier ce détail une fois que les détails de facturation du client ont été intégrés avec succès.",
      "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Chiffre d'affaires mensuel...",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Erreur",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"InProgress",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Réussite ",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Lire plus",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Rechargement du consentement du client",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Rapport de grille de rechargement",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Synchronisation des profils client et fournisseurs",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Rechargement initialisé",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Postes de facture",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Lignes de facture...",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Éléments de ligne de facture avec commissions",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Éléments de ligne de facture avec commissions...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Sommaire de facture...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Rapport de profitabilité",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Rapport de rentabilité par produit",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Rapport de rentabilité par produit...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Rapport de rentabilité par revendeur",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Rapport de rentabilité par locataire",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Rapport de rentabilité par locataire",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Rapport de profitabilité...",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Postes de taxe",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Postes de taxe...",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Résumé fiscal",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Résumé fiscal...",
      "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Rapport de consentement",
      "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Télécharger le rapport client",
      "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Exporter le rapport de rapprochement",
      "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Exporter la licence utilisateur",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Recharger le consentement du client",
      "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Synchroniser les profils client et fournisseur",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Recharger le rapport de rapprochement",
      "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Rapport sur les termes et conditions",
      "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Rapport sur les limites du montant des transactions",
      "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Les nouveaux achats sont désactivés via la plateforme. Veuillez contacter votre gestionnaire de compte pour passer une commande.",
      "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Contactez votre gestionnaire de compte pour acheter un nouveau produit",
      "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Les nouveaux achats et les modifications du nombre de licences sont actuellement suspendus. Veuillez contacter votre responsable de compte pour plus d'informations.",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Supprimer",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Panier client",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Poursuivre votre magasinage",
      "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"Je suis prêt à payer",
      "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Payer maintenant",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Passer la commande",
      "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Vérifiez la date de fin personnalisée sur les éléments de campagne du panier avant de procéder à l'achat.",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Aligner la date de fin avec le mois civil",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Défaut",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Date de fin des abonnements existants",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Date de fin des abonnements existants",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Valide",
      "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Promotion appliquée",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Montant à payer",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Devise : ",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Nom de la taxe :",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Coût total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Nombre total : ",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Rabais total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Taxe total:",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Résumé de la commande",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Il y a déjà une offre de contrat dans le panier. Veuillez supprimer l'offre de contrat existante pour en ajouter une nouvelle.",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Vous avez déjà une offre de contrat achetée. Veuillez supprimer l'offre de contrat existante pour en acheter une nouvelle.",
      "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Une commande pour ce produit a déjà été envoyée au fournisseur. Veuillez contacter votre gestionnaire de compte pour plus d'informations",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Cet article nécessite l'approbation du Partenaire pour être exécuté, une fois la commande passée. Le nombre de sièges achetés dépasse la limite autorisée de {{SeatLimit}}.",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Cet article nécessite l'approbation du Partenaire pour l'exécution une fois la commande passée.",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Sélectionnez un site/un département",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_Azure_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure. Raisons possibles - vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure. Raisons possibles - vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter Azure Plan. Raisons possibles : soit vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans l'Espace partenaires et nécessite une mise à niveau, soit vous avez plusieurs éléments de ligne de panier de plan Azure sous le même locataire.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_Azure_PLAN_ELIGIBILITY":"Votre locataire n'est pas éligible pour acheter le plan Azure . Raisons possibles : soit vous avez déjà un plan Azure et il n'est pas encore synchronisé avec C3, soit un abonnement Azure est actif dans le Partner Center et nécessite une mise à niveau, soit vous avez plusieurs éléments de ligne de panier de plan Azure sous le même locataire.",
      "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Cette commande nécessite l'approbation du Partenaire pour exécution, après la commande. Le montant de la transaction dépasse la limite autorisée de <b> {{TransactionAmountLimit}}</b>.",
      "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Afficher la taxe dans le panier du client",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Les numéros de facture commenceront par ce texte",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texte du numéro de facture",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permet à l'utilisateur de visualiser les cotisations impayées jusqu'à la facture en cours",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Afficher les cotisations impayées sur la facture",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"Êtes-vous sûr de vouloir {{Consent}} le renouvellement du consentement pour {{ProductName}} ?",
      "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Votre consentement est accepté avec succès",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Ajouter",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Mettre à jour",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Consentement du client",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"URL de contrat",
      "CUSTOMER_CONSENT_HELP_TEXT":"Veuillez préciser les détails de la personne de l'entreprise client qui a accepté le <a ng-click='vm.CustomerConsentClick()'>contrat client Microsoft.</a>",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Prénom",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Nom de famille",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Cliquer <a id='customerConsentLink' ng-click='vm.MicrosoftCloudAgreementClicked()'> ici </a>pour lire le Contrat de client Microsoft",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Contrat client Microsoft",
      "CUSTOMER_CONSENT_REJECT_POPUP":"Êtes-vous sûr de vouloir rejeter le<br>{{ProductName}} ?",
      "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Votre consentement a été rejeté avec succès",
      "CUSTOMER_CONSENT_SAVED_MESSAGE":"Votre consentement est enregistré avec succès",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Consentement au renouvellement de l'offre du partenaire",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Échec du consentement au renouvellement de l'offre du partenaire",
      "CUSTOMER_CONSENT_TEXT_DATE":"Date",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"Courriel",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Heure",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Veuillez accéder au menu du profil et mettre à jour le profil de paiement",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Veuillez remplir le profil de paiement",
      "CUSTOMER_DASHBOARD_Azure_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Azure",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Azure",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Portail Azure",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Nombre de produits achetés jusqu'à présent",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Produits",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Nombre d'utilisateurs ayant accès à vos informations",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Utilisateurs",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Alerte pour une facture en attente",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Facture",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Lien rapide vers le portail Office 365",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Portail Office",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Lien rapide vers les rapports d'utilisation d'Office 365",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Rapports d'utilisation d'Office 365",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 des SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Nombre de produits par SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Comptes par SKU",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Gamme de filtres",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 mois",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 mois",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 an",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Achat de sièges",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sièges achetés",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Sièges du client",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Sièges client",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Journal d'acceptation des conditions générales",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Conditions générales",
      "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Sièges",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Ligne de facture supprimé avec succès",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Ajouter un département",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Département supprimé avec succès",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Modifier le département",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Départements",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Département enregistré avec succès",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Sites affectés",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Site",
      "CUSTOMER_EXPORTINVOICE_WARNING":"La facture pour {{customername}} n'est pas encore générée. Veuillez revérifier après un petit moment.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Rechargement du consentement des fournisseurs clients",
      "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Synchronisation des profils client et fournisseurs",
      "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Texte généré par la facture client",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Retour aux factures",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Ajouter lineItem",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Annuler",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Sauvegarder la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Voulez-vous vraiment annuler vos modifications ?",
      "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"La date de début de facturation des éléments de ligne ne doit pas être supérieure à la date de fin de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"Voulez-vous vraiment supprimer l'élément de campagne ?",
      "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"La date d'échéance de la facture doit être supérieure à la date de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Articles de ligne",
      "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Les taxes seront appliquées après l'enregistrement de la facture.",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Date de la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Date d'échéance",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Sous-total",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adresse",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"Pays",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Choisissez le pays",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"État",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Veuillez fournir au moins un élément de ligne pour enregistrer la facture",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Date de fin",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Date de début",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"Est taxable",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Nom de l'article",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"Montant de la ligne",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Type de vente",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Taxe de vente",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Crédit",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Charge Additionnelle ",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Montant d'ajustement",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Détail d’ajustement",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Nom du fournisseur",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Retour à la facture",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Ajouter",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Ajustement",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Modifier",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Factures",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Créer une facture",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Factures",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":"Balise client  ",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Valeur",
      "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Le rapport de licence client est envoyé au courrier respectif",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filtres",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Gérer le produit",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Retour au plan Azure",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Nom du groupe",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Seuils",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Ajouter un abonnement Azure",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Aucun droit trouvé",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Le propriétaire n'a pas encore été affecté",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Propriétaire",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Le contenu des rôles et des politiques va ici....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Facturation",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Commentaires",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Estimer",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Groupes",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Info",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Gérer le propriétaire",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Entités opérationnelles",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Paiements",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Rôles et politiques",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Gérer le renouvellement",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Utilisation",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Abonnement",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Sélectionnez le code du marché",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Télécharger un exemple de fichier .csv",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Charger des données de compte pour plusieurs utilisateurs en format .csv. Télécharger un exemple de fichier .csv",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"source pour référence.",
      "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Rapport de chiffre d'affaires",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Veuillez entrer le nouveau nom",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Modifier le nom du client",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"Nom du client modifié avec succès",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Mise à jour du nom du client échouée",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Mise à jour du nom du client réussie",
      "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Le client n'est affecté à aucun gestionnaire de compte",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Vous n'êtes lié à aucun domaine Microsoft.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"L'achat de produits du fournisseur peut échouer en raison de l'absence de domaines liés de fournisseur",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Affectation",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"Créé",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"Supprimé",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Erreur",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Intégré",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"Rechercher dans la facturation !",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Veuillez suivre ces étapes pour obtenir l'identifiant du système de facturation d'un client",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Se connecter su système de facturation",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Aller aux clients",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Cliquez sur la fiche du client pour l'ouvrir",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Récupérer le string qui vient après recordkey",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Récupérer la chaîne qui se trouve après « recordkey= » dans l'URL",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"par exemple :",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"valeur-à-copier",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Se connecter à Authorize.Net",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Prendre l'ID du profile du gestionnaire d'information client",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Chemin de Gestionnaire de l'information client : Accueil->Gestionnaire de l'information client",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"Récupérer la chaîne à partir de l'URL",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Identifiant du système de facturation",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Intégration massive de clients",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Inscription de client ",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Recueillir les détails client",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Terminer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"ici",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Rechercher!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Procéder à l'inscription de ce client",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Supprimer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Ignorer",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Essayez un autre",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Intégration massive de clients",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Inscription de client ",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"licences mappées",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Succès de l'intégration",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Type de paiement",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Le client n'est pas encore dans le système. Cliquer sur le bouton « Procéder à l’intégration de ce client » pour poursuivre",
      "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"Veuillez fournir une adresse email valide",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"Rechercher dans la facturation !",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"Identifiant EziDebit",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Veuillez suivre ces étapes pour obtenir l'identifiant EziDebit d'un client",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Se connecter à EziDebit",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Aller aux payeurs",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Cliquez sur la fiche du payeur pour l'ouvrir",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Récupérer le string qui vient après contractId",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"Récupérer la chaîne qui se trouve après « contractId= » dans l'URL",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"par exemple :",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"valeur-à-copier",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Clients disponibles à l'ajout à partir du centre partenaire",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"a été créé avec succès",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"Intégré avec succès",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Étape suivante ",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Détails de plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Code de devise client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Identifiant client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Le produit est-il partageable",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Ligne 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Ligne 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Identifiant Microsoft ",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Sélectionnez le domaine Microsoft.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Mois",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Prochaine date de facturation",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"REMARQUE:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Ne mettez à jour aucun contenu dans le fichier autre que les détails utilisateur tel qu'indiqué dans l'exemple ci-dessus.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"optionnel:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Si vous avez les détails de licences qui sont déjà attribuées aux utilisateurs dans les abonnements,  vous pouvez nous les fournir en suivant les étapes suivantes :",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Remplissez les informations dans le format disponible en téléchargement à partir de",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Cliquez sur utilisateurs et accordez l'accès à ce portail aux utilisateurs du domaine client",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Représenter et valider les détails de l'abonnement",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Veuillez corriger les erreurs en rechargeant un fichier mis à jour avec le détail de la licence afin de compléter le processus d'inscription",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Il suffit de fournir les adresses email de l'utilisateur entre crochets ([]) en suivant l'exemple:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Charger le fichier et cliquez",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Vous pouvez ne pas fournir les détails de la licence en cliquant sur IGNORER pour terminer le processus d'intégration.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Cliquez sur Terminé pour accéder à la liste des clients",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Localisez le client qui a vient d'être intégré",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Un bouton d'action <i class=\"fa fa-star red\"></i> va apparaitre en cas d'erreurs durant le traitement du détail de la licence que vous avez chargée",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Cliquez sur le bouton <i class=\"fa fa-star red\"></i> pour voir les erreurs",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Veuillez corriger les erreurs et essayer de charger à nouveau.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Catégorie de fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"État",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Domaines",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Charger le fichier",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Sélectionner",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Année",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"Supprimé",
      "CUSTOMER_ONBOARDING_LBL_DISABLED":"Désactivé",
      "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Expiré",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendu",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"Aucun plan n'est attribué au client. Veuillez créer un nouveau plan et l'attribuer au client, puis corriger l'inadéquation.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Pour les produits d'instance réservée, nous considérons le prix de vente en pourcentage. La valeur par défaut est donc un pourcentage de 0 %. Si vous souhaitez fournir une autre valeur, veuillez modifier le prix de vente.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Nom du plan",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Code de devise",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Prix de vente (en pourcentage)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente (en pourcentage)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Cette valeur sera appliquée au prix de facturation Microsoft pour générer la facture en conséquence.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Veuillez saisir une valeur jusqu'à deux décimales.",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Fournisseur",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Procéder à l'inscription de ce client",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Non",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Oui",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Rechercher des abonnements client et carte",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"licences mappées",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Terminé",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Terminé",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"S'identifier",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Actions",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Courriel de facturation",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Quantité de système de facturation",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Identifiant Microsoft",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Domaine",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Prix effectif",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Majoration",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_OFFERS":"Offres correspondantes ",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Correspondance de produits",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Quantité de produits correspondants",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_OFFER_NAME":"Nom d'offre ",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Pourcentage",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Prix",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Nom de produit du fournisseur",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Quantité de fournisseurs",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Aucun abonnement trouvé.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"PERSONNALISÉ",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Vous pouvez changer de mode de paiement à travers les options du profile. Les factures sont envoyées par email par Partner.",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Abonnements d'utilisation chargés avec succès.",
      "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Chargement....",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Désactiver",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Modifier",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Activer",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Gérer",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adresses",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Type d'entité",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Rôle",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Ajouter un nouvel utilisateur",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Adresse de facturation",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"Modifier l'utilisateur",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Adresse d'expédition",
      "CUSTOMER_OPTION_ALL_PERIODS":"Toutes les périodes",
      "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Termes et conditions.",
      "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez cocher la case des termes et conditions NCE pour commander des produits Microsoft NCE.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez cocher la case des termes et conditions pour commander des produits Microsoft.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-Azure-experience-in-csp/\">Conditions générales NCE.</a>",
      "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"Le montant à payer doit être la somme du produit et de la taxe totale",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Réessayer le paiement",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Afficher la facture",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Voir les produits",
      "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Retour aux commandes",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Nouvel achat",
      "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Mise à jour de la quantité",
      "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Changement de statut",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Commandes client",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Afficher les détails",
      "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Statut",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Commandes client",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"En attente",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Actions",
      "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Opération",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Nom du département",
      "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Opération",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Identifiant de commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"À ce jour",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"À partir de la date",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"Fonctionnement de la commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"Statut de la commande",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Commandé par",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Commandé le",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"Commandes de",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Commandes à",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Nom du site",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Statut",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Dernière actualisation",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Lire plus",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Retour aux paiements client",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Factures en attente pour",
      "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Sélectionnez un identifiant unique",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Code de réduction actuel",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong> Erreur! </ strong> Le client ne peut pas être déplacé vers <strong> {{targetPlanName}} </ strong> à partir de <strong> {{PlanName}} </ strong> car le plan cible n'a pas d'offres qui correspondent aux abonnements actuellement actifs.",
      "CUSTOMER_PLAN_CHANGE_INFO":"La modification de plan n'est pas encore prise en charge s'il existe au moins un abonnement actif dans une offre composite dans le plan actuel.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Plan actuel",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Abonnements clients",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Prix effectif",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Correspondance trouvée",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Nom de la cible",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Prix cible",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Ce coupon a déjà été assigné au client.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Réussite! </ strong> Le client peut être déplacé vers <strong> {{targetPlanName}} </ strong> à partir de <strong> {{PlanName}} </ strong>. Cliquez sur <strong> Envoyer </ strong> pour continuer.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Plan cible",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Utilisez le code promotionnel actuel",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(Supprimé)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Extensions",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Modifier",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Réactiver l'abonnement",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Réactiver l'abonnement avec des extension s",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Suspendre l'abonnement",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Forfait",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Entité opérationnelle",
      "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Ajouter plus de bons",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Soumettre",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Continuer l'abonnement</b> <br/> Êtes-vous sûr de vouloir continuer l'abonnement <b>{{productName}></b> après l'essai ?",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" L'offre d'essai {{productName}} sera transformée en abonnement une fois la date de fin effective du fournisseur atteinte.",
      "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"Êtes-vous sûr de vouloir changer le cycle de facturation de {{billingCycle}} à {{c3billingCycle}} ?<br> Après la génération de la facture, le cycle de facturation ne peut pas revenir en arrière.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Annuler l'abonnement</b> <br/> Êtes-vous sûr de vouloir annuler votre abonnement d'essai <b>{{productName}></b> ?",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Une erreur s'est produite lors de l'annulation de l'abonnement {{productName}}.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Offre d'essai annulée pour {{productName}} !",
      "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"off",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorer",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Détails de l'erreur",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Produits clients",
      "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Période d'essai",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Veuillez saisir des valeurs séparées par des virgules.",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Instructions",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Perpétuel",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"Logiciel perpétuel",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Abonnements logiciels",
      "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Voulez-vous vraiment modifier le nom du produit ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Voulez-vous vraiment modifier le cycle de facturation de ce produit pour {{targetBillingCycle}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"Voulez-vous vraiment changer l'offre de ce produit en {{targetOffer}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir modifier la portée de ce produit en {{targetScope}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir modifier la durée d'utilisation de ce produit en {{targetTermDuration}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir réactiver {{productName}} {{withAddons ? 'avec les extensions' : ''}} ?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Voulez-vous vraiment suspendre {{productName}} ?",
      "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Abonnement parent manquant pour {{Name}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Modification de nom effectuée avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"J'ai changé le numéro de bon de commande avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Modification du cycle de facturation de {{productName}} en {{targetBillingCycle}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Quantité de produit invalide",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"impossible de mettre à jour la quantité à zéro pour les produits déjà achetés",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"La demande de mise à jour de l'abonnement a été soumise avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Demande de réactivation de {{productName}} {{withAddons ? 'with addons' : ''}} a été soumis avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"Réactivation réussie de {{productName}} {{withAddons ? 'avec les extensions' : ''}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Propriété libérée avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"La demande de suspension du produit, {{productName}} a été soumise avec succès",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{ProductName}} suspendu avec succès",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Renouvellement automatique",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"Se renouvelle le",
      "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Annulation autorisée jusqu'au",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"La modification du cycle de facturation mettra à jour tous les abonnements faisant partie de la \"Commande\". Cela créera un nouveau terme annuel pour tous les abonnements au prix actuel basé sur la liste de prix. Les abonnements ci-dessous seront mis à jour vers une facturation \"Annuelle\". Pour terminer cette demande, veuillez cliquer sur \"OK\" puis sur Soumettre.",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Modifier le cycle de facturation",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Annuler l'abonnement</b> </br> <br/> <b> Remarque : </b> Votre fenêtre d'annulation de 7 jours est fermée. Nous ne sommes pas en mesure d'annuler votre abonnement.</br> Si vous souhaitez annuler cet abonnement lors du prochain cycle de facturation, mettez à jour le statut de renouvellement automatique sur faux.",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Annuler l'abonnement",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Définir le Renouvellement Automatique</b> </br> <br/> <b> Remarque : </b> nous ne pouvons pas modifier l'état du renouvellement automatique.",
      "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Expire le",
      "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Gérer la fréquence de facturation",
      "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Suspendre l'accès du client au service",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"La reprise d'un abonnement suspendu restaure l'accès du client au service. Cela n'aura aucun impact sur votre facture. Vous continuerez à être facturé normalement. Si le renouvellement automatique était activé et que des modifications étaient programmées avant de suspendre l'accès, vous pouvez les réactiver.",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Rétablir l'accès du client au service",
      "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Soumettre les changements",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Annuler l'abonnement",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Annuler l'abonnement</b> <br/> Êtes-vous sûr de vouloir annuler {{productName}} ? <p style='text-align:left;' > Pour recevoir un remboursement, vous devez annuler les abonnements dans les 7 jours calendaires suivant l'achat ou le renouvellement. <br/> <b>Remarque :</b> Le client perdra l'accès à cet abonnement immédiatement après l'annulation et l'accès ne pourra pas être restauré après l'annulation. Si vous annulez l'abonnement dans les 7 jours calendaires, vous serez remboursé pour toute période non utilisée. Un crédit apparaîtra sur votre prochaine facture mensuelle. <a target='_blank' href=\"{{cancelNewCommeNCESubscriptionGuidLineURL}}\"> En savoir plus.</a> </p>",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Annuler avec succès !",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Définir le Renouvellement Automatique</b> <br/> Êtes-vous sûr de vouloir {{autoRenewStatus}} statut de renouvellement automatique pour {{productName}} ?",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Expire",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Statut de renouvellement automatique mis à jour avec succès pour {{productName}}",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"Mis à jour avec succés !",
      "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Voulez-vous vraiment suspendre {{productName}} ? <br/> <b> Remarque : </b> Bien que la suspension de l'accès du client au service bloque immédiatement la capacité du client à utiliser les services, cela n'a aucune incidence sur votre facture. Vous continuerez à être facturé normalement. La pause désactivera et désactivera le renouvellement automatique et supprimera toutes les modifications planifiées.",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Activité",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Statut d'activité",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Date",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Quantité d'origine",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Mise à niveau de {{sourceProductName}} vers {{targetProductName}}",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Quantité cible",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Transition",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Produits clients",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Affecter",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Retour aux détails de base",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Statut",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"La quantité ne peut pas être supérieure à",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Cycle de facturation personnalisé",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Quantité (disponible)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Quantité pour",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Abonnement lié",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Affecter",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Enregistrer",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Soumettre les modifications",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Extensions",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Quantité (disponible)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"La quantité ne peut pas être supérieure à",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Quantité pour",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Acheter un abonnement</b> <br/> Êtes-vous sûr de vouloir convertir votre offre d'essai {{productName}} en abonnement ?",
      "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre à jour la quantité de <b>{{oldQuantity}}</b> à <b>{{newQuantity}}</b> pour le produit actuel ?",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"Est-il auto-renouvelable",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Expire le",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"Identifiant d'abonnement Azure",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"Identifiant de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"Identifiant de la commande de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Région de réservation",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Portée",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"Se renouvelle le",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Cet article nécessite l'approbation du Partenaire pour être exécuté, une fois la commande passée. Le nombre de postes achetés dépasse la limite autorisée de <b> {{SeatLimit}}</b> pour <b>{{productName}}</b>.",
      "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Filtrer les entités",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Séquence de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Séquence de produit",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Extension ",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Lié",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"département",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":"Pour",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Voulez-vous vraiment annuler vos modifications ?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Voulez-vous vraiment mettre à jour la séquence du produit ?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Séquence de produit client mise à jour",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"Des erreurs sont survenues lors de la dernière demande de mise à jour En savoir plus...",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Rabais",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Actif",
      "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Annulé",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Annuler l'essai",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Continuer l'abonnement après l'essai",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Acheter un abonnement",
      "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Complété",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"Supprimé",
      "CUSTOMER_PRODUCT_STATUS_DISABLED":"Désactivé",
      "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Expiré",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Échoué",
      "CUSTOMER_PRODUCT_STATUS_INPROVISION":"InProvision",
      "CUSTOMER_PRODUCT_STATUS_PENDING":"En attente",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Réussi",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendu",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"Pays",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Domaine",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Prix du produit lié",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Fournisseur",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Réactiver l'abonnement",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Réactiver l'abonnement avec des extension s",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Portée",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Types de service",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Statut",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Suspendre l'abonnement",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Période d'essai",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"L'offre {{productName}} sera convertie en abonnement à la fin de la période d'essai.",
      "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Offre d'essai Parent",
      "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Retour aux commandes",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"Inclure 0 quantités",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Renouvellement",
      "CUSTOMER_PRODUCTS_IN_ACTIVE":"L'abonnement est inactif",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Détenu PAR",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Abonnement lié",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"Statut",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"département",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Domaine",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Site",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Domaine",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Annuler l'abonnement",
      "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Bon copié dans le presse-papiers",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Commentaires",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Notifications",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Gérer la propriété",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Utilisateurs",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Copier le lien",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Copier le lien",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Télécharger",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Récupérer les clés et les téléchargements",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Lien copié dans le presse-papiers",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Sélectionner un type de fichier",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Sélectionner une langue",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Sélectionner un produit",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Actions",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Taille du fichier",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Identifiant",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Clé",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Nom",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Droits",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Date d'annulation",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Date de renouvellement",
      "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Promotion appliquée",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Commandé",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Gérer",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"Traitement",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Appliquer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"Effacer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Fermer",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Inclure les entités enfants",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Domaine",
      "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Options de filtrage",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Gérer le produit",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Actions",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Montant",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"Commandé le",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Détenu PAR",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"Se renouvelle le",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Montant total",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Prix unitaire",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Prix unitaire",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"département",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Site",
      "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Les modifications programmées sont présentes sur cet abonnement",
      "CUSTOMER_PRODUCTS_VALIDITY":"Valide",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Affecté au gestionnaire le",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Courriel",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Numéro de téléphone",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Nom du gestionnaire",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Détails du gestionnaire de compte",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Détails de base mis à jour avec succès",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Adresse d'expédition",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Profil client",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Départements",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Sites",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Ajouter une adresse",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"Ajouter un courriel",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Ajouter un numéro de téléphone",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adresses",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"Adresses courriel",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Numéros de téléphone",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Nom",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Sites",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Aucun enregistrement trouvé !",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Ajouter",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Profils de paiement",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Aucun profil trouvé. Ajoutez un nouveau profil de paiement en cliquant sur le bouton AJOUTER",
      "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"L'ajout du profil de paiement est en cours. Veuillez patienter un moment pendant que le processus se termine.",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Adresse d'expédition",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Comptes d'utilisateur",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Date de création du compte",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Adresse de facturation",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"Date d'intégration C3 ",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Nom canonique",
      "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Merci d'avoir choisi",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Détails de base",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Magasin",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Revoir la commande",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Confirmer",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Terminer",
      "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"L'adresse email est déjà utilisée. Veuillez vous connecter à C3 en utilisant vos informations d'identification ou créer un nouveau compte avec une adresse e-mail différente",
      "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Veuillez remplir les champs obligatoires pour continuer",
      "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"La demande d'inscription a été placée avec succès",
      "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Vos coordonnées ont été soumises avec succès. Nous vous contacterons sous peu",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Soumettre",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Achetez des produits",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"L'acceptation de l'accord client du fournisseur doit être confirmée",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existe",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"n'existe pas",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Choisissez le pays",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Contact principal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Address 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"Pays",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Nom du domaine",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"Adresse e-mail de l'administrateur global",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Grant access to the primary contact (as Customer Administrator)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Deuxième nom",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Numéro d'enregistrement de l'organisation",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"L'e-mail fourni permettra à l'administrateur client d'accéder au portail.",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Voulez-vous envoyer une invitation pour accéder à l'application ?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"État",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Identifiant de domaine",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Ce nom de domaine n'est pas valide",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"est disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"n'est pas disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Validating domain availability",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Sélectionnez l'état",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Entrer l'état",
      "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Pour conserver les détails du domaine du locataire",
      "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Pour communiquer des éléments liés à la facturation",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Veuillez soumettre les détails ci-dessous et le partenaire vous contactera pour vous fournir une assistance supplémentaire.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Cliquez sur Copier le lien vers le presse-papiers pour copier le lien de la relation revendeur et suivre le modèle revendeur.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Ouvrez le lien dans une session de navigation privée et terminez la relation de revendeur en utilisant un compte qui est un administrateur global.<br>Après avoir terminé, fournissez les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Si vous ne souhaitez pas accepter la relation revendeur, fournissez simplement les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Instructions pour accepter la relation revendeur",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Instructions pour accepter la relation revendeur",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"En attendant, vous pouvez autoriser votre partenaire en tant que revendeur CSP en fournissant le consentement sur le lien de relation revendeur à l'aide de votre utilisateur administrateur global.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Cliquez sur copier le lien dans le presse-papiers",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":" pour copier le lien de la relation revendeur et suivre le modèle revendeur.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Ouvrez le lien dans une session de navigation privée et terminez la relation de revendeur en utilisant un compte qui est un administrateur global.<br>Après avoir terminé, fournissez les détails ci-dessous et cliquez sur Soumettre, après quoi ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Si vous ne souhaitez pas accepter la relation revendeur, fournissez simplement les détails ci-dessous et cliquez sur Soumettre après que ce partenaire communiquera avec vous.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"SignUp BatchId",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"Le traitement peut prendre un certain temps et le statut sera affiché sur cette page. Si vous quittez la page ou fermez accidentellement le navigateur, nous vous tiendrons informé par e-mail",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"Inscription en cours",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Veuillez patienter pendant que nous configurons votre compte. Vous pouvez continuer à utiliser votre appareil comme d'habitude. Nous vous enverrons une notification par e-mail une fois la configuration terminée.",
      "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Pour récupérer les informations sur les locataires et les abonnements",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Panier",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Détails client ",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Détails de paiement",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Achetez des produits",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Nom du client externe",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Action",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"Produits C3",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Produit externe",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Numéro de séquence",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Accord",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Nom de catégorie",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Contrat",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"Nom du client PSA",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Veuillez utiliser plus de filtres si vous souhaitez définir des contraintes supplémentaires sur la catégorie, le locataire, etc.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"Le contrat est-il par défaut ?",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Les contrats par défaut seront utilisés pour télécharger les services contractuels associés aux services qui sont créés et mappés automatiquement.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Locataire",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":"Département :",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Site :",
      "CUSTOMER_RECONCILIATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Cette fonctionnalité est disponible pour les offres personnalisées et est prise en charge pour une durée d'un an.",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"L'abonnement lié ne sera pas pris en charge par cette fonctionnalité.",
      "CUSTOMER_REPORT":"Rapports clients",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filtrer les produits",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Date de commande maximale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Quantité maximale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Date de commande minimale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Quantité minimale",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Expire dans quelques jours",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Commandé entre",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Quantité",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Rapport de produits",
      "CUSTOMER_REPORTS":"Rapports",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Ajustement sauvegardé avec succès.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Ajout des frais antécédents en tant qu'ajustement à la facture actuelle",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Paiement enregistré avec succès",
      "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"Identifiant de référence de facturation",
      "CUSTOMER_SEARCH_CLOSE_OPTION":"Fermer les filtres",
      "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Inclure le client supprimé",
      "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Méthode de paiement par défaut",
      "CUSTOMER_SEARCH_CUSTOMER_name":"nom",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"Nom",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Méthode de paiement",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Balises",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Nom du domaine",
      "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"Identifiant client ERP",
      "CUSTOMER_SEARCH_FILTER":"Filtrer les clients",
      "CUSTOMER_SEARCH_INVOICE_NOTES":"Notes de facturation",
      "CUSTOMER_SEARCH_MARKET":"Code du marché",
      "CUSTOMER_SEARCH_PROVIDER":"Fournisseur",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "CUSTOMER_SEARCH_RESET_OPTION":"Réinitialiser",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Rechercher",
      "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Identifiant unique",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Ajouter au panier",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Sélectionner toutes les extensions",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Abonnement lié",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Fermer",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Magasin",
      "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Pour filtrer ou inclure des produits du niveau enfants",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Types de facturation",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Valide",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Type de validité",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"DURÉE DE L'ESSAI",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"Extension",
      "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"OFFRE D'ESSAI",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filtres",
      "CUSTOMER_SIGNUP_FAILED":"L'inscription du Client a Échoué",
      "CUSTOMER_SIGNUP_SUCCESS":"Succès de L'inscription du Client",
      "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Succès de l'inscription. Vous pouvez vous connecter au portail C3 avec des informations d'identification envoyées à -",
      "CUSTOMER_SITE_ADD_SITE":"Ajouter un site",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Annuler",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site supprimé avec succès",
      "CUSTOMER_SITE_EDIT_SITE":"Modifier le site",
      "CUSTOMER_SITE_HEADER_TEXT":"Sites",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Site enregistré avec succès",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"Il n'est pas possible d'ignorer les erreurs pour cet abonnement. Veuillez contacter le support C3 pour obtenir de l'aide.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Plan Azure",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Sélectionnez le plan Azure.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Sélectionnez les droits.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Êtes-vous sûr de vouloir continuer ?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"Appliquer la promotion Au client ?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" Le prix ne peut pas être autre que <strong>{{price}}</strong> car ce ReservationOrderID {{reservationorderID}} a déjà un produit de réservation avec un prix.<br>Voulez-vous synchroniser avec le prix <strong>{{price} </strong> ?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Accepter",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Rejeter",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Actions",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Retour aux produits",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Responsable du renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Responsable du renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Action",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Retour au gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Mis à jour le",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Télécharger l'historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Historique du gestionnaire de renouvellement",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Prix",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Nom de produit",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Quantité",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nom d'utilisateur",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Prix",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Produits",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Quantité",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Renouvelle le",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Nom",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Veuillez sélectionner au moins un fichier.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Chargement de fichier réussi",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Approuver",
      "CUSTOMER_SUBSCRIPTIONS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Chargement du rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Rechargement des détails de facturation des clients ...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Ajouter un client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Ajouter l'utilisateur à l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Retour aux utilisateurs de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux abonnements",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Existant (multiple)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Intégration massive de clients",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Existant (unique)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Client existant",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Exportation du résumé de facturation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exporter les détails d'utilisation ",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Aller au rapport",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"ici",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Nouveau",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Nouveau Client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Intégrer un client",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Recharger les détails de facturation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Recharger le mois en cours",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Recharger complètement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recharger le récapitulatif de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Recharger les détails d'utilisation",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Supprimer",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Sélectionner",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Ajouter un client",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"Ajouter abonnements pour",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"Abonnements Azure de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Gérer l'utilisateur de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Offres",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Plan et offres",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Rapport de rapprochement pour",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Les utilisateurs de l'abonnement de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Configuration client de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Valeur",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Détails d'utilisation de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Utilisateurs de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Valider les erreurs d'intégration pour",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finaliser",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Facturation",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Profile de la société",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CUSTOMERS":"Clients",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Type de paiement",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Contact principal",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"S'identifier",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"La facture a été approuvée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit finalisée et que le client ne soit facturé. Le client peut maintenant voir cette facture et vous pouvez y ajouter des paiements une fois finalisée. ",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"La facture a été finalisée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Le client est maintenant capable de voir cette facture et vous pouvez ajouter des paiements à cette facture.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"La facture a été générée !",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit finalisée et que le client ne soit facturé.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Payer automatiquement ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Paiement automatique : DÉSACTIVÉ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Paiement automatique : activé",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Facturation",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organisation IIN",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Méthode de paiement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Ville",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Seuil de danger",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Courriel",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Seuil d'erreur",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Prénom",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Nom du groupe",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Seuil d'information",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Masquer pour le client",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Articles sélectionnés",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Ligne 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Ligne 3",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Mois",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Rôle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"État",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":" Seuil d’avertissement",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Année",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Code postal",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Plans",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Quantité",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Non",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Oui",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Abonnement inactif",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Quantités incompatibles",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Supprimer utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Activer l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Utilisateurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Consulter les détails",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Activer en CSP",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Nom de composant",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Identifiant de courriel",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Droits",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Erreur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Coût estimé",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Masquer pour le client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Les abonnements ne sont pas visibles aux clients",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Dernière actualisation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"Facture MSFT ",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Statut d'intégration",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Prix",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Bénéfice",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Rôle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Seuils",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nom d'utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Aucun abonnement n'a encore été ajouté.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Affecter un plan client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Modifier le cycle de facturation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Modifier le nom",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Définir les balises",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Définir l'impôt %",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Corriger l'incompatibilité d'état d'activation dans le centre partenaire",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Correction de l'incompatibilité d'état de renouvellement automatique dans le Partner Center",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Corriger une disparité ...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Corriger une disparité",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Modifier l'offre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Fournisseurs de paiement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":"Mettre à jour le statut permanent du produit",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Changer de plan",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Corriger la promotion",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Fournisseurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Impersonation rapide",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Rapport de rapprochement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recharger le récapitulatif de l'abonnement",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Corriger la portée",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Configuration client",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":"Changer de terme",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Test de paiement automatique",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Utilisateurs",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Erreurs de validation",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"PERSONNALISÉ",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Total",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Budget mensuel-Info-Avertissement-Erreur-Danger",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"est disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"n'est pas disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Veuillez télécharger le modèle de",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Supprimer un utilisateur",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Accorder l'accès au portail",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Personnifier",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Révoquer l'accès depuis le portail",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"La valeur doit être comprise entre 0 et 100",
      "CUSTOMER_SUP_TEXT_DELETED":"(Supprimé)",
      "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Synchroniser les profils",
      "CUSTOMER_TAGS_EXAMPLE":"Définir des balises, des paires de valeurs, pour le client, qui seraient utilisées lors de la définition du contrôle d'accès pour les utilisateurs. <br/> Exemple: Vous pouvez définir une balise nommée SupportAgent et un surnom pour un agent (disons Agent001) comme valeur. Si un préposé au service d'assistance possède l'une des balises définie comme SupportAgent et l'une des valeurs de cette balise particulière est Agent001, l'agent pourra voir les clients dont la paire de balises {SupportAgent, Agent001} est définie.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Nom",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Valeur",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Modifier",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Valeur",
      "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"La date de fin de mandat personnalisée spécifiée dans les instructions du prochain mandat prévu n'est valide que pour les produits OnlineServicesNCE.",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Voir les factures en attente",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"Paiements pour",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Client",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Client",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Détails de l'utilisateur enregistrés avec succès",
      "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"Le rapport d'attribution de licence utilisateur sera envoyé à l'adresse e-mail fournie.",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Ajouter un nouvel utilisateur",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Autoriser l'accès",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Gérer l'utilisateur",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Révoquer l'accès",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"département",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"Rôle",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Type de rôle",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"Site",
      "CUSTOMER_USERS_HEADING":"Utilisateurs",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"Adresse courriel",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Rôle",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Télécharger des factures ou les Paiements",
      "customerconfiguration":"Configuration client",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Veuillez mentionner si les détails ACH sont liés à un compte professionnel",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Ce client a déjà été sélectionné",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Le client existe déjà dans le système",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Client créé avec succès",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Le processus d'intégration du client est en cours",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner au moins un fichier.",
      "CUSTOMERONBOARDING_SUCCESS":"Client intégré avec succès.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Une erreur s’est produite pendant le chargement du fichier",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} a déjà une licence pour {offername}",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Le nombre d'utilisateurs fournis est plus grand que le nombre de licences disponibles. Veuillez corriger les détails de l'utilisateur et réessayer.",
      "customers":"Clients",
      "Customers":"Clients",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Actions",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Entreprise",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clients",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Lien vers le profil de facturation",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Lien vers le fournisseur",
      "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Supprimer le lien vers le profil de facturation",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Création de factures…",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Rechargement du rapport de rentabilité des clients ...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Retour aux factures",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Ajouter un ajustement",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Ajoutez le dû comme ajustement!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Télécharger une facture",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Aller au rapport",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Historique par date",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Historique par offre",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Initier le paiement",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test de facturation de client",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BÊTA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Facture pour",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Profitabilité par abonnements pour",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Sommaire des revenus et des coûts",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Historique des modifications de l'abonnement pour",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"Impossible de vérifier la disponibilité du domaine",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"Êtes-vous certain de vouloir accorder l'accès à {{username}} ?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Clients",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Aucune Facture trouvée. Veuillez contacter l'équipe de support.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adresse",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Client",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Résumé de compte",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Période de facturation",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Date d'échéance",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Date de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Date d'échéance de la facture",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Facture générée le",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Statut de facture",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"La facture a été générée !",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Vous pouvez toujours ajouter des ajustements (frais supplémentaires ou crédits) sur cette facture avant qu'elle ne soit traitée et que le client ne soit facturé.",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"La facture a été traitée !",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Le client est maintenant capable de voir cette facture et vous pouvez ajouter des paiements à cette facture.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Droits sur les factures précédentes",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"On dirait qu'il y a des frais provenant des factures antécédentes. Si vous êtes certain d'avoir enregistré tous les paiements du client, cliquez sur le bouton suivant pour",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"pour ajouter <strong>{{InvoiceDueAmount}}</strong> en tant qu'ajustement à la facture courante.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"On dirait qu'il y a un montant dû",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"au total.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Modifier",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Supprimer",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Détail de la facture",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Basé sur licence",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Basé sur l'utilisation",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Montant",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Type de charge",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Description",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Montant d'escompte",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Prix de l'offre",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Quantité",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Date de fin",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Montant total",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Montant du sous-total",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Date de début",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Nom de l'abonnement",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Montant de la taxe",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Pourcentage d'impôt",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Prix unitaire",
      "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"éléments de facture",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Enregistrer le paiement",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Montant du paiement",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Raison de l'échec",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Montant payé",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Identifiant de paiement",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Paiements de facture",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"Identifiant de transaction",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Enregistrez les paiements sur cette facture.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"par exemple :",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Paiement partiel en acompte, etc.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Contactez l'assistance si vous avez besoin d'aide supplémentaire sur les paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"Frais de traitement CC ({{CCFeePercentage}}% sur {{CCPaidAmount}})",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Total",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Sous-total",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Raison de l'échec",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Montant",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Date",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Remarques",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Statut de paiement",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Les détails de facturation, incluant l'historique, ont été actualisé avec succès",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Détails d'utilisation, incluant l'historique, actualisés avec succès",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Courriel de facturation",
      "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"Intégré le",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Plans",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"C3Id",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Nom",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Clients intégrés",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Rechercher un enregistrement correspondant dans le système",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Résultat trouvé pour l'identifiant de Référence {{ID}} dans le système de facturation.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Recherche de clients dans C3 pour une correspondance par l'identifiant du client fournisseur",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Client trouvé avec le nom - {{customername}}",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Rechercher des clients dans le centre Partner pour une correspondance avec l'identifiant Microsoft",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Trouvé un client avec le nom - {{customername}} dans le centre Partner",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Les quantités pour les offres enfants dans un composite doivent être les mêmes",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Une ou plusieurs offres dans CSP sont associées à la même offre dans la facturation. S'il vous plaît ajuster votre mappage",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"L'offre {{Name}} dans le plan à plusieurs abonnements CSP correspondant.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"L'abonnement {{nameinbilling}} dans la facturation ne peut être mappé",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"L'offre {{name}} n'a pas d'abonnements CSP correspondants dans le centre Partner",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"L'abonnement au centre Partner - {{name}} n'a aucune offre qui correspondante dans le plan de mappage",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Les offres suivantes devraient être mappées aux abonnements CSP -",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"L'offre {{name}} a plus d'utilisateurs spécifiés que le nombre de droits disponibles",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Les quantités pour les abonnements devraient correspondre à celles du centre Partner ",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Les quantités pour l'abonnement {{subscriptionname}} devraient correspondre entre le centre Partner et la facturation",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Les quantités pour les abonnements devraient correspondre entre le centre Partner et la facturation",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"Le nombre de droits d'utilisateurs par rapport à un abonnement CSP {{cspsubscription}} doit correspondre à la somme de tous les chiffres fournis avec les offres associées dans le plan",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Une ou plusieurs offres CSP ne sont pas mappées ou il manque des abonnements dans CSP",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Une erreur s’est produite pendant l'actualisation des abonnements pour  {{customerName}}",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Abonnements actualisés pour le client - {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"L'inscription client s'est terminée avec succès. Un email sera envoyé à {{registeredemail}} avec des instructions pour se connecter au portail.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"L'inscription du client s'est terminée avec succès.<br/>Les informations d'identification de l'utilisateur administrateur sont :<br/><b>UserId -</b> {{userId}},<br/><b>Temporary Password -</b> {{password }}",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"L'inscription du client a été effectuée avec succès.<br/>Les informations d'identification de l'utilisateur administrateur sont :<br/><b>UserId -</b> {{userId}},<br/><b>Mot de passe temporaire -</b> {{password }} <span class =\"text-danger\"> <br/><br/> <b>Échec de l'attribution des groupes de sécurité à la requête GDAP, veuillez compléter ceci dans l'Espace partenaires, sinon d'autres actions échoueront.</ b> </span>",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"L'enregistrement du client a été effectué avec succès.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"L'enregistrement du client a été effectué avec succès. Connexion au portail.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Veuillez associer une offre pour l'ajustement",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
      "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Synchroniser les profils",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Paiements clients",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Montant facturé",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Date",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nom du département",
      "CUSTOMERS_TABLE_HEADER_TEXT_MSFT_INVOICE":"Facture MSFT ",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Nouveau prix",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Ancien prix",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Autre coût",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Montant facturé avant impôt",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Facture MSFT avant taxes",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Bénéfice",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"% de bénéfice",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Nom du site",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Aucun abonnement n'a encore été ajouté.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Supprimer le client",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Paramètres client",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Gérer les plans",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Action",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Nom",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Télécharger les lignes basés sur l'utilisation",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Profitabilité par abonnements",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Recharger le rapport de profitabilité",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Historique des modifications de l'abonnement",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Afficher la facture",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Afficher les éléments de ligne d'utilisation",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"{{username}} ajouté à l'abonnement Azure avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"{{username}} ajouté à l'abonnement Azure {{azuresubscription}} avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} retiré de l'abonnement Azure avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Êtes-vous sûr, voulez-vous supprimer l'utilisateur ?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} retiré de {{azuresubscription}} avec succès",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Abonnement Azure sauvegardé avec succès",
      "customNotifications":"Notifications personnalisées",
      "customoffer":"Offre personnalisée",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Statut de facture mis à jour avec succès",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Commande passée avec succès. OrderId : {{orderId}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Destinataires du mot de passe",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Courriel des destinataires",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Entrez les destinataires de courriel séparés par des virgules",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Ajouter une nouvelle entité",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Annuler",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Gérer",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Soumettre",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Ajouter une nouvelle entité",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Entités opérationnelles",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Description",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Type d'entité",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nom",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Journal d'acceptation des conditions générales",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Afficher la facture",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Nouvelle facture générée le <b>{{InvoiceGeneratedDate}}</b> pour la période de facturation à partir de <b>{{BillingStartDate}}</b> à <b>{{BillingEndDate}}</b> au montant de <b>{{InvoiceAmount}}</b>.",
      "DASHBOAR_TEXT_DATE":"Date",
      "DASHBOAR_TEXT_EMAIL":"Courriel",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL de contrat Ezidebit",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"URL du contrat client Microsoft",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL des conditions générales du partenaire",
      "DASHBOAR_TEXT_TIME":"Heure",
      "Dashboard":"Tableau de bord",
      "DASHBOARD_BREADCRUMB":"Tableau de bord",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Analytique client",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Rapports",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Rapport de changement de licence",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Rapport récapitulatif de licence",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Rapport d'utilisation",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Mettre à jour le profil",
      "DASHBOARD_CAPTION_PARAGRAPH":"Fournissez des ressources pour exploiter une entreprise prospère grâce à la puissance du cloud.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Bienvenue au marché Cloud",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Tous les SKU au format CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"Télécharger CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"Télécharger PNG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"Télécharger SVG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Top 10 des SKU en CSV",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Partenaire",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Tout",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Dernière période de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 périodes de facturation",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 mois",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 mois",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 mois",
      "DASHBOARD_COMPLAINCE_TEXT":"Centre de conformité",
      "DASHBOARD_COST_TEXT":"Coût",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Nombre de départements",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Départements",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Départements",
      "DASHBOARD_HEADER_TEXT_WARNING":"Avertissement!",
      "DASHBOARD_ICON_TITLE":"Icônes de Stockholm / Design / Calques",
      "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Mois en cours",
      "DASHBOARD_LBL_TEXT_NET_SEATS":"Net",
      "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Le mois précédent",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Bienvenue sur le tableau de bord de votre portail d'octroi des licences",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Utilisez les tuiles ci-dessous en tant que liens rapides afin de gérer vos abonnements et vos utilisateurs.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Ce portail vous permet d'ajouter de nouveaux utilisateurs à votre domaine, attribuer des licences et réinitialiser votre mot de passe.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Vous pouvez acheter des licences et des abonnements supplémentaires.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Mettre à jours votre profile (Adresse de facturation et méthode de paiement )",
      "DASHBOARD_REVENUE_TEXT":"Chiffre d'affaires",
      "DASHBOARD_SEAT_PURCHASED_TEXT":"Siège acheté",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Nombre de sites",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Sites",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Sites",
      "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Connectez-vous au portail",
      "DASHBOARD_TAIL_1_TEXT_Azure_PORTAL_TITLE":"Portail Azure",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Portail Azure",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Abonnements Azure",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Utilisation Azure depuis {{startDate}}",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Produits",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Abonnements de licences",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Afficher plus",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Rapports Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Se connecter au Portail",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Utilisateurs",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Afficher plus",
      "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TENDANCE CE MOIS",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Échoué",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"En cours",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"Non reçu",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Reçu",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":"Date:",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":"Sièges:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":"Date:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":"Des produits:",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Dernière synchronisation {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Nouvelle facture générée le <b>{{InvoiceGeneratedDate}}</b> pour la période de facturation à partir de <b>{{BillingStartDate}}</b> à <b>{{BillingEndDate}}</b> au montant de <b>{{InvoiceAmount}}</b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"pour voir la facture. Ignorer si déjà payé.",
      "DASHBOARD_WARNING_MESSAGE":"Vous devez mettre à jour votre profile de paiement avant de pouvoir utiliser C3. Veuillez cliquer sur le bouton suivant pour le mettre à jour.",
      "DATA_PUSHED_DATES":"Certaines ou toutes les données ont été poussées précédemment, veuillez vérifier les journaux précédents.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Description de l'élément",
      "DAYS":"Jour(s) ",
      "DEACTIVATE_CONFORMATION":"Êtes-vous sûr de vouloir désactiver ?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"Impossible de désactiver le produit client",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Produit client désactivé",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Cet utilisateur n'est pas autorisé à supprimer cette offre",
      "DECEMBER":"Décembre",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Activez cette configuration pour définir la grille/liste comme vue par défaut sur la page des produits pour les clients",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Vue par défaut des produits clients",
      "DEFAULT_COTERMINOSITY_TYPE_DESC":"La sélection d’une valeur ici la définira comme valeur par défaut pour la fonctionnalité de coterminosité lors des achats d’abonnements.",
      "DEFAULT_COTERMINOSITY_TYPE_DISP":"Type de coterminosité",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Liste des types de contre-minosité",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Type de contreminiosité",
      "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Supprimer toutes les extensions",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de la suppression de la conversion de devises",
      "DELETE_CUSTOMER_FAILURE":"Mise à jour du nom du client échouée",
      "DELETE_CUSTOMER_SUCCESS":"Le client a été supprimé avec succès.",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Une erreur s'est produite lors de la suppression des destinataires de la notification par courriel",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"L'ajustement de facture sélectionné sera supprimé. Voulez-vous continuer ?",
      "DELETE_PARTNER_BUNDLE_TOOLTIP":"Supprimer le produit groupé",
      "DELETE_PARTNER_OFFERS":"Supprimer les offres des partenaires",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la suppression des détails sur l'impôt",
      "DELETE_PARTNER_USER":"Supprimer un utilisateur partenaire",
      "DELETE_PARTNER_USER_FAILURE":"Impossible de supprimer l'utilisateur partenaire",
      "DELETE_PONUMBER":"Supprimer le numéro de bon de commande",
      "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Supprimer un produit",
      "DELETE_QUOTE_SUCCESSFULLY":"Citation supprimée avec succès",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
      "DELETE_USER_EXCEPTION":"Cet utilisateur a un accès à C3, veuillez donc le supprimer",
      "DÉPARTEMENT":"(Département)",
      "DEPARTMENT":"(Service)",
      "DEPUIS":"Depuis",
      "DES DONNÉES D'ENTRÉE":"Entrée utilisateur",
      "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Titre principal de la bannière d'inscription publique",
      "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"En-tête de paragraphe de la bannière d'inscription publique",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Devise client",
      "DESC_CUSTOMER_DASHBOARD":"Tableau de bord du client",
      "DESC_PARTNER_DASHBOARD":"Tableau de bord des Partenaires",
      "DESC_PLAN_CURRENCY_OPTION":"Devise du plan",
      "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"URL du site Web pour le lien hypertexte d'administration dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"En-tête principal de la bannière d'inscription publique",
      "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Le paragraphe qui apparaît sur la bannière d'inscription publique sous l'en-tête de la bannière",
      "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"URL du site Web pour le lien hypertexte du site Web dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Nous contacter",
      "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":"URL du site Web pour le lien hypertexte du portail d'apprentissage dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_SUPPORT":"URL du site Web pour le lien hypertexte d'assistance dans la barre de navigation d'inscription publique",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Termes et conditions d'inscription publique 1",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Termes et conditions d'inscription publique 2",
      "DESC_SHOW_INVOICES_TO_CUSTOMER":"Activez cette option pour afficher les factures au client",
      "Description":"Description",
      "DESCRIPTION_FOR":"Description pour",
      "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Désactiver un produit",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Ancien modèle sécurisé désactivé et nouveau modèle sécurisé activé",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Merci pour la confirmation. L'application accédera à votre centre partenaire en suivant les nouvelles directives de sécurité.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Échec de la désactivation de l'ancien modèle sécurisé et activation du nouveau modèle",
      "DISCOUNT_MAX_ERROR":"La valeur doit être inférieure ou égale à 100",
      "DISCOUNT_MIN_ERROR":"La valeur doit être supérieure ou égale à 0",
      "DISP_CATEGORY_COUPON_ENTITY":"Catégorie",
      "DISP_PLANOFFER_COUPON_ENTITY":"Offre de forfait",
      "DISP_PROVIDER_COUPON_ENTITY":"Fournisseur",
      "DISP_SALETYPE_COUPON_ENTITY":"Type de vente",
      "DISP_SHOW_INVOICES_TO_CUSTOMER":"Afficher les factures au client",
      "DisplayCurrency":"Afficher la devise",
      "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Durée du mandat",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Réinitialiser",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Entrez une valeur positive",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Nouvelle offre distributeur",
      "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Retour à l'offre distributeur",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Offres des distributeurs",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Catégorie",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Offres des distributeurs",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Action",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Types de facturation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Catégorie",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Charger les périodes",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Coût d'acquisition (de)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Coût d'acquisition (à)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Description",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiate?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Nom",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Ancien prix de revient",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Ancien prix de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icône",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Fournisseur",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Prix de vente (à partir de)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Prix de vente (à)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Terme",
      "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Offre distributeur {{distributorOffer}} enregistrée avec succès",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"nom",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Nom",
      "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Offre distributeur {{distributorOffer}} mise à jour avec succès",
      "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Supprimer le gestionnaire de compte avec succès",
      "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"Voulez-vous annuler?",
      "DOCUMETATION_HEADING":"Documentation",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Entité",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Montant facturé",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Nom qualifié",
      "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Télécharger la progression et le lien",
      "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Télécharger les factures",
      "DOWNLOAD_COLUMN_BUNDLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Règle de facturation pour l'achat de places",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Règle de facturation pour la réduction du nombre de sièges",
      "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Prix de revient",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Type de vente",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"Est-ce que c'est renouvelable automatiquement ?",
      "DOWNLOAD_COLUMN_BUNDLE_NAME":"Nom du bouquet",
      "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Nom du produit",
      "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Statut",
      "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Marché soutenu",
      "DOWNLOAD_COLUMN_BUNDLE_TERM":"Terme",
      "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Rapport sur les produits groupés",
      "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Nom du coupon actif",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Applicabilité",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Assigné à",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Code promotionnel",
      "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Description du coupon",
      "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Nom du Client",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Rabais",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Expire le",
      "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"AutoPay",
      "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"Identifiant de référence de facturation",
      "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Code du marché",
      "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Nom",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Méthode de paiement ",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
      "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Date d'inscription",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Balises",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Domaines",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Est-ce une offre d'essai",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Numéro de facture du fournisseur",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Numéro de référence de facturation",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Date de fin des frais",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Date de début des frais",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Taux de conversion",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Coût sur le Partenaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"Numéro client ERP",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Montant de la remise",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"Identifiant de l'offre durable",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Prix unitaire effectif par MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Date de la facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Notes de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Numéro de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Détail de la ligne de facture",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Code de l'article",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Nom",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Nom de l'offre",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"Numéro de bon de commande",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Montant après taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Montant avant taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Identifiant promotionnel",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Coût unitaire au prorata",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Quantité",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Date de fin d'effet de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Abonnement effectif",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Durée d'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Type d'abonnement",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Numéro d'abonnement du partenaire de syndication",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Montant de la taxe",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Code fiscal",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Montant total",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Montant total pour le partenaire par MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Coût unitaire par produit",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Coût unitaire du partenaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Prix unitaire",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Prix unitaire par MS",
      "DOWNLOAD_COLUMN_MARKET_REGION":"Marchés pris en charge",
      "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Disponible pour le regroupement",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Disponible à la consommation immédiatement",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Règle de facturation pour l'achat de licences",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Règle de facturation pour la réduction des licences",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Type de facturation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Type de consommation",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Coût d'acquisition",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Nom",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Type de vente",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Terme",
      "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Description",
      "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Devise de facturation",
      "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"Est publique",
      "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Macro détail",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Valeur de la macro",
      "DOWNLOAD_COLUMN_PLAN_NAME":"Nom",
      "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Disponible pour le client",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Catégorie Cycle de facturation",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Type de facturation",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Catégorie",
      "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Coût pour le Partenaire",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Code de devise",
      "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Efficacité",
      "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Nom de l'offre amicale",
      "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"Le dernier changement de prix aura lieu le",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Nom de la macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Valeur de la macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Nom du plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"Identifiant de l'offre",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Nom de l'offre",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Nom du plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Prix en détail",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Prix de vente",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"type de service",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Marché pris en charge",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Terme",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Durée du mandat",
      "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Devise d'achat",
      "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"Partager l'URL",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Est disponible à l'achat",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Disponible pour la publication automatique",
      "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Catégorie",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Type de consommation",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Nom du département",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Rabais",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Prix effectif du produit",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Nom du produit",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Séquence de produit",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Nom de la catégorie du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Date de création du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Date de fin du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Date de début du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"ID de produit du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Quantité",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Nom du site",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Statut",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Identifiant de domaine du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Nom de domaine",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Prix unitaire",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Catégorie",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Coût pour le partenaire",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Code de devise",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Est une offre d'essai",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Id Produit ",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Nom de produit",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Catégorie de fournisseur",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Nom du fournisseur",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Région",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Prix de détail",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Rapport sur le catalogue de produits",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Valide",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Type de validité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Cycle de facturation",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Nom de catégorie",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Crée par",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Créer un rendez-vous",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Code de devise",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Nom du client",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Nom du domaine",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Entité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"Est un module complémentaire",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Nom de l'entité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Nouveau prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Nouvelle quantité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Nouveau statut",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Ancien prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Ancienne quantité",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Ancien statut",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Nom de produit",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Fournisseur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Catégorie de fournisseur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Prix neuf revendeur",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Revendeur ancien prix",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Identifiant d'abonnement Azure",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Identifiant de domaine",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"Type de changement",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Valide",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Type de validité",
      "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Sélectionnez au moins une colonne",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Télécharger",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Télécharger les coupons",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Télécharger les clients",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Télécharger des produits",
      "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Télécharger les forfaits",
      "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Télécharger les offres de forfaits",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Postes de facture",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Paiements de facture",
      "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Sélectionnez au moins une colonne",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"Télécharger CSV",
      "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Télécharger les clients non intégrés",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "DOWNLOAD_POPUP_GRID_HEADER":"Télécharger le rapport de grille",
      "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"Il n'y a pas de données disponibles pour le téléchargement CSV",
      "DOWNLOAD_PRODUCTS_GRID":"Télécharger les produits",
      "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Télécharger le logiciel et les clés",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Ajouter plusieurs",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Lien multiple",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Lien unique",
      "DROPDOWN_PLACEHOLDER_SELECT":"Sélectionner",
      "DUE_STATUS_DUE":"Échéance",
      "DUE_STATUS_FINALIZED":"Finaliser la facture",
      "DUE_STATUS_OVERDUE":"En retard",
      "DUE_STATUS_PAID":"Payé",
      "DUE_STATUS_PARTIAL_PAID":"Partiellement payé",
      "earningsreport":"Rapport de revenus",
      "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Modifier les informations de facturation",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Modifier le groupe",
      "EDIT_INVOICE_BUTTON_TEXT":"Modifier la facture",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Produit",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Résumé",
      "EDIT_PONUMBER":"Modifier le numéro de bon de commande",
      "EffectivenessTypeId":"ID du type d'efficacité",
      "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Veuillez fournir une image avec une résolution maximale de 300 x 100 pour le logo de l'e-mail",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"L'adresse courriel spécifiée comme contact de facturation dans le profil du client",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Liste des adresses courriel des personnes qui devraient être notifiées par courriel",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Rôles autres que le service d'assistance",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"BillingContacts",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Personnes",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Rôle",
      "EMAIL_TABLE_HEADER_ACTIONS":"Actions",
      "EMAIL_TABLE_HEADER_EMAIL":"Courriel",
      "EMAIL_TABLE_HEADER_EMAIL_TYPE":"Type de courriel",
      "EMAIL_TEMPLATE":"Aperçu",
      "EMAIL_TEMPLATE_LABEL":"Modèles",
      "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Sélectionnez un modèle",
      "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"Modèle d'e-mail mis à jour avec succès",
      "EMAIL_TEMPLATE_SUBMIT":"Soumettre",
      "EMAIL_TYPE_BILLING":"Facturation",
      "EMAIL_TYPE_COMPANY":"Entreprise",
      "EMAIL_TYPE_INVOICE":"Facture",
      "EMAIL_TYPE_PERSONAL":"Personnel",
      "EMAIL_TYPE_SHIPPING":"Expédition",
      "EmailConfigurations":"SMTP",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"La configuration est conçue pour activer la tarification en fonction des niveaux d'admissibilité. Une fois activée, nous générerons des factures en fonction des niveaux d'admissibilité, quel que soit le type de facture. Veuillez confirmer que cela vous convient.",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Activer la tarification en fonction des niveaux d'admissibilité",
      "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Activer le produit",
      "ENABLE_RESELLER_PLAN":"Affecter un plan au revendeur",
      "ENABLE_RESELLER_PLAN_FAILURE":"Impossible d'affecter le plan au revendeur",
      "END_BILLING_PERIOD":"Fin de la période de facturation",
      "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"La date de fin doit être supérieure à la date de début",
      "END_DATE_REQUIRED_MESSAGE":"La date de fin est requise",
      "ENGAGE_BREADCRUMB_ADD":"Créer de l'engagement",
      "ENGAGE_BREADCRUMB_LIST":"Liste de l'engagement",
      "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Couleur du bouton d'appel à l'action",
      "ENGAGE_BUTTON_TEXT_COLOR":"Couleur du texte du bouton",
      "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Couleur du texte du bouton",
      "ENGAGE_BUTTON_URL_PLACEHOLDER":"Lien hypertexte du bouton d'appel à l'action",
      "ENGAGE_CTA_BUTTON_COLOR":"Couleur du bouton CTA",
      "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Texte du bouton d'appel à l'action",
      "ENGAGE_CUSTOMER_PAGENAME":"CustomerDashboard",
      "ENGAGE_EFFECTIVE_FROM":"En vigueur à partir de",
      "ENGAGE_EXPIRES_ON":"Expire le",
      "ENGAGE_HEADER_ACTIONS":"Actions",
      "ENGAGE_HEADER_ACTIONS_ADD":"Créer de l'engagement",
      "ENGAGE_HEADER_ACTIONS_BACK":"Retour à la liste",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "ENGAGE_HEADER_ENTITES":"Entités",
      "ENGAGE_HEADER_EXPIRES_ON":"Expire le",
      "ENGAGE_HEADER_MESSAGE":"Message",
      "ENGAGE_HEADER_PAGE":"Page",
      "ENGAGE_HEADER_TITLE":"Titre",
      "ENGAGE_NOTIFICATION_BACKGROUND":"Arrière plan",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Le Lorem Ipsum est le texte factice standard de l'industrie depuis les années 1500, lorsqu'un imprimeur inconnu a pris une galère de caractères et l'a brouillé pour en faire un livre de spécimens de caractères.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Offrir des remises pour votre boutique en ligne peut être une arme puissante pour fidéliser.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Stimulez le marketing &amp; confiance dans les produits de vente.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Commencez par une création de contenu moderne de conception de site de marque.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Offrir des rabais pour une meilleure vente en ligne dans un magasin peut fidéliser la conduite.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Commencez par une création de contenu moderne de conception de site de marque.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Commencez par une conception de site moderne et personnalisez-la avec le contenu et les fonctionnalités de votre marque. Tous les blogs Premium incluent un CSS personnalisé.",
      "ENGAGE_NOTIFICATION_BODY_TEXT":"Le corps du texte",
      "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"La notification d'engagement a été créée avec succès",
      "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Cela dépasse la longueur minimale de 30",
      "ENGAGE_NOTIFICATION_PORTAL_PAGE":"Portail",
      "ENGAGE_NOTIFICATION_TITLE_TEXT":"Texte du titre",
      "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"La notification d'engagement a été mise à jour avec succès",
      "ENGAGE_PARTNER_PAGENAME":"Tableau de bord des Partenaires",
      "ENGAGE_POPUP_BUTTON_URL":"URL du bouton",
      "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Texte du bouton CTA",
      "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Sélectionnez tous les clients",
      "ENGAGE_SUBMIT":"Soumettre",
      "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":"Le texte dépasse la longueur maximale de 15",
      "ENGAGE_THE_MAX_LENGTH":"La longueur maximale est de 250",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Veuillez sélectionner au moins une facture à publier sur QuickBooks.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Votre demande d'envoi de factures à QuickBooks est acceptée et mise en file d'attente pour traitement. Veuillez cliquer sur le bouton <strong>Afficher les journaux de publication</strong> pour obtenir des mises à jour sur les progrès.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Échec de la mise en file d'attente de la demande de chargement des factures sur QuickBooks online",
      "ENQUEUED_REQUEST_TO_CLONE_PLAN":"Demande en attente pour cloner le plan",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Demande en attente pour créer un nouveau plan comprenant toutes les offres",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Demande en file d'attente pour créer un nouveau plan revendeur avec toutes les offres",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Échec de la mise en file d'attente de la demande de chargement des factures sur Xero",
      "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":"Entrez un e-mail après la virgule ou supprimez la virgule supplémentaire",
      "ENTER_Azure_NEW_MARKUP_VALUE":"Saisissez une nouvelle valeur de majoration",
      "ENTER_AZURE_NEW_MARKUP_VALUE":"Saisissez une nouvelle valeur de majoration",
      "ENTER_REASON_FOR_Azure_NEW_MARKUP_VALUE":"Entrez la raison de la nouvelle valeur de majoration",
      "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Entrez la raison de la nouvelle valeur de majoration",
      "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Entrez la raison de la mise à jour de la valeur de majoration",
      "enterAddress1":"Entrez l'adresse 1",
      "enterAddress2":"Entrez l'adresse 2",
      "enterCity":"Entrer dans la ville",
      "enterEmail":"Entrez l'email",
      "enterPhoneNumber":"Entrez le numéro de téléphone",
      "enterState":"Entrer l'état",
      "enterZip":"Entrez le code postal",
      "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Nombre de droits à créer, ne dépassant pas {{Count}}",
      "ENTITLEMENT_MANAGEMENT":"Gestion des droits Azure",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":"Le nombre de droits à créer ne peut pas dépasser",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Les noms des droits commenceront par ceci.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Instructions",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Lorsque le prix d’autorisation est remplacé, ce prix s’appliquera à la fois aux calculs de facture et aux estimations Azure.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Remarque :</b>",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Utilisez le bouton <b>Remplacer</b> pour modifier le prix du droit.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Utilisez le bouton <b>Rétablir</b> pour réinitialiser le prix au prix du plan Azure par défaut pour le droit.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Utilisez <b>Exporter</b> pour télécharger les données d’autorisation pour un niveau de client et de locataire spécifique.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Récupérez les données d'autorisation pour tous les clients actifs à la fois en choisissant <b>Exporter tout</b>.",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Action",
      "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Ajouter un droit",
      "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Ajouter des droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Terminé",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Date créée",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Statut de création",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"département",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Nom du département",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Nombre de droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"Identifiant",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Liste des droits",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Nom",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Définir un nouveau prix pour le droit",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Exporter",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Exporter tout",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Exporter",
      "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Ce prix est applicable à partir du moment où la prochaine facture est générée et continue par la suite.",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Prix de vente",
      "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Séquence de démarrage",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Site",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Nom du site",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":"Processus terminé. Veuillez consulter le journal d'audit pour plus de détails.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"La demande d'ajout de droits est acceptée et en cours de traitement",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La demande d'ajout de droits a échoué.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Veuillez fournir une séquence de numéros",
      "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"Êtes-vous sûr de vouloir annuler le prix de vente de ce droit ?",
      "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"Le prix du droit a été modifié avec succès.",
      "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"Le prix du droit a été rétabli avec succès.",
      "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"L'incrément séquentiel sera utilisé comme suffixe dans les noms de droits.",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Statut de création du droit",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Veuillez patienter pendant la création des droits.",
      "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"La valeur doit être supérieure ou égale à -100 et inférieure ou égale à 100.",
      "ENTITY_DESC_AZURE_GROUP":"Groupe Azure",
      "ENTITY_DESC_BILLING_PERIODS":"Périodes de facturation",
      "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"Identifiant du revendeur du fournisseur de facturation",
      "ENTITY_DESC_BILLING_PROVIDER_USER":"Utilisateur du fournisseur de facturation",
      "ENTITY_DESC_CART":"Panier",
      "ENTITY_DESC_CART_LINE_ITEM":"Élément de campagne du panier",
      "ENTITY_DESC_COUPON":"Coupon",
      "ENTITY_DESC_CURRENCY":"Devise",
      "ENTITY_DESC_CUSTOM_OFFER":"Offre personnalisée",
      "ENTITY_DESC_CUSTOMER":"Client",
      "ENTITY_DESC_CUSTOMER_PRODUCT":"Produit client",
      "ENTITY_DESC_CUSTOMER_USER":"Utilisateur client",
      "ENTITY_DESC_DEPARTMENT":"département",
      "ENTITY_DESC_INVOICE":"Facture",
      "ENTITY_DESC_JOB_LOGS":"Journaux de tâches",
      "ENTITY_DESC_JOBS":"Emplois",
      "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Client du magasin local",
      "ENTITY_DESC_LOCAL_STORE_RESELLER":"Revendeur en magasin local",
      "ENTITY_DESC_PARTNER":"Partenaire",
      "ENTITY_DESC_PARTNER_SETTINGS":"Paramètres du partenaire",
      "ENTITY_DESC_PARTNER_USER":"Utilisateur partenaire",
      "ENTITY_DESC_PAYMENT_PROFILE":"Profil de paiement",
      "ENTITY_DESC_PLAN":"Plan",
      "ENTITY_DESC_PLAN_PRODUCT":"Planifier le produit",
      "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Planifier un bon produit",
      "ENTITY_DESC_PRODUCT":"Produit",
      "ENTITY_DESC_PRODUCT_VARIANT":"Variante de produit",
      "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Bon pour une variante de produit",
      "ENTITY_DESC_PROVIDER_CUSTOMER":"Client fournisseur",
      "ENTITY_DESC_RESELLER":"Revendeur",
      "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Profil de paiement du revendeur",
      "ENTITY_DESC_RESELLER_PLAN":"Forfait revendeur",
      "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Client prestataire de services",
      "ENTITY_DESC_SHOP":"Magasin",
      "ENTITY_DESC_SITE":"Site",
      "ENTITY_DESC_SITE_DEPARTMENT":"Département chantier",
      "ENTITY_DESC_TAG":"Étiqueter",
      "ENTITY_DESC_TAX":"Impôt",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Conditions générales",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Mettre à jour les paramètres du fournisseur",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres du fournisseur",
      "ENTITY_MAPPING":"Cartographie des entités",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"Êtes-vous sûr de vouloir libérer le mappage",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"Voulez-vous vraiment enregistrer le mappage pour <b> {{customerName}}</b> ?",
      "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Au moins mappez 1 produit",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Ces contrats ne sont plus par défaut : {{ContractNames}}",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"Le numéro de séquence en double n'est pas autorisé",
      "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Mappages enregistrés avec succès",
      "ENTITY_NAME":"Nom de l'entité",
      "ENTRÉE INVALIDE":"Entrée invalide",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Actions",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"Pays",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Courriel",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"Statut",
      "ENVOYEZ À":"Envoyez à",
      "ERROR__DATA_CONTACT_TYPE":"type de contact",
      "ERROR__DATA_PAYMENT_TYPE":"Type de paiement",
      "ERROR__DATA_PROFILE_TYPE":"Type de profil",
      "ERROR__DATA_URL":"URL",
      "ERROR_AVAILABLE_IN_BATCH":"L'erreur est disponible dans le lot.",
      "ERROR_BILLING_PROVIDER_NAME":"Nom du fournisseur de facturation",
      "ERROR_BUTTON_CONTINUE":"Continuer",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"Identifiant de produit client C3",
      "ERROR_C3_ENTITY_NAME":"Nom de l'entité",
      "ERROR_C3_RECORD_ID":"Identifiant d'enregistrement",
      "ERROR_C3_USER_ID":"Identifiant utilisateur",
      "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Quantité de panier invalide",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"Identifiant client de facturation",
      "ERROR_DATA_BILLING_REFERENCE_ID":"Identifiant de référence de facturation",
      "ERROR_DATA_C3_CUSTOMER_ID":"Identifiant de client C3",
      "ERROR_DATA_C3_RECORD_ID":"Identifiant d'enregistrement",
      "ERROR_DATA_C3_RESELLER_ID":"Identifiant de revendeur C3",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"Identifiant d'abonnement C3",
      "ERROR_DATA_COUNTRY_CODE":"Code de pays",
      "ERROR_DATA_CUSTOMER_NAME":"Nom du client",
      "ERROR_DATA_DOMAIN_PREFIX":"Nom du domaine",
      "ERROR_DATA_EMAIL_ID":"Identifiant de courriel",
      "ERROR_DATA_ENTITY_NAME":"Nom de l'entité",
      "ERROR_DATA_EVENT_NAME":"Nom de l'événement",
      "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Prend en charge les revendeurs avec ID MPN",
      "ERROR_DATA_INPUT_JSON":"Des données d'entrée",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "ERROR_DATA_PROVIDER_NAME":"Nom du fournisseur",
      "ERROR_DATA_RESELLER_ID":"Identifiant de référence",
      "ERROR_DATA_RESELLER_MPNID":"Identifiant de fournisseur revendeur",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"Identifiant de fournisseur revendeur",
      "ERROR_DATA_SEARCH_VALUE":"Rechercher une valeur",
      "ERROR_DATA_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "ERROR_DATA_TAX_CODE":"Code fiscal",
      "ERROR_DATA_TRANSACTION_REFERENCE":"Numéro de référence de la transaction",
      "ERROR_DATA_USER_ID":"Identifiant utilisateur",
      "ERROR_DELETE_DEPARTMENT":"Ce département contient des enregistrements associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Ce rayon contient des produits achetés associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_SITE":"Ce site contient des enregistrements associés, il est donc impossible de supprimer cet enregistrement.",
      "ERROR_DELETE_SITE_PRODUCT":"Ce site contient des produits achetés associés, vous ne pouvez donc pas supprimer cet enregistrement.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Impossible de créer le domaine dans le fournisseur.",
      "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le nom de l'agent ne peut pas être nul",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Aucune réponse de Authorize.net",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Impossible de trouver le client.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Impossible de trouver ce produit parmi vos abonnements",
      "ERROR_DESC_BAD_INPUT":"Il semble y avoir une erreur avec votre entrée",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"La gestion client a échoué en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Une erreur s'est produite lors de l'enregistrement des détails de la notification personnalisée",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Une erreur s'est produite lors du téléchargement du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"La récupération du client a échoué en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Récupération de profils de paiement échouée en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Récupération du statut de paiement échouée en raison d'une mauvaise entrée.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Une erreur s'est produite lors de la récupération du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"L'action a échoué car le profil de paiement est manquant",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"L'action a échoué car la configuration du profil de paiement est manquante",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Une erreur s'est produite lors de l'enregistrement de votre profil de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Il semble y avoir un problème avec vos profils de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Il semble y avoir un problème avec votre fournisseur de facturation, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Un problème est survenu lors de l'enregistrement de votre profil de paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Une erreur s'est produite lors de la récupération du rapport de consommation de licence, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Le fournisseur n'a pas pu être trouvé",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"Le fichier est vide",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"Le format de fichier n'est pas compatible",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Service non mis en œuvre. Veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Il semble y avoir un problème avec vos types de paiement pris en charge pour le fournisseur de facturation, veuillez contacter le soutien technique.",
      "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Une erreur s'est produite lors de l'enregistrement des détails de la notification du webhook",
      "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Mauvaise demande - une autre tâche est en cours",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Une erreur s'est produite lors de la création/mise à jour de la facturation client, veuillez contacter le soutien technique.",
      "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Les revendeurs intégrés en vrac non pris en charge ont un Partenaire qui ne prend pas en charge les revendeurs avec un ID MPN",
      "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"Le panier contient des éléments de ligne avec des erreurs. Veuillez les corriger pour procéder à cet achat.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Cet abonnement est déjà utilisé par ce client",
      "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Une erreur s'est produite lors du changement de forfait client, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_SOUNCE_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans souNCE pour la fonctionnalité de plan de modification, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans source pour la fonctionnalité de plan de modification, veuillez contacter l'assistance.",
      "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Une erreur s'est produite lors de l'obtention des plans cibles pour la fonctionnalité de plan de changement, veuillez contacter l'assistance.",
      "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"La date de fin de la commission ne peut pas être antérieure à la date de début de la commission",
      "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"La date de fin de la commission ne peut pas être nulle",
      "ERROR_DESC_COMMISSION_PENCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le PeNCEntage de commission ne peut pas être nul",
      "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Le pourcentage de commission ne peut pas être nul",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Erreur de ConnectWise Manage lors de la tentative de suppression de l'ajout par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Erreur de ConnectWise Manage lors de la tentative d'ajout par identifiant et identifiant de contrat",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'ajouts par identifiant de contrat",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails de l'accord par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'accords",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails de l'accord par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'accords",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'un élément de catalogue par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Erreur de ConnectWise Manage lors de la tentative d'obtention d'éléments de catalogue",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Erreur de ConnectWise Manage lors de la tentative d'obtention des détails du client par identifiant",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Erreur de ConnectWise Manage lors de la tentative d'obtention de clients",
      "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Entrées en double dans les paiements importés",
      "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entrées en double dans le catalogue",
      "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Entrées en double dans le catalogue",
      "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"Une entrée en double existe dans l'entrée",
      "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Il y a des erreurs dans le formulaire",
      "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Une erreur s'est produite lors du lancement de la référence aux données d'entité à partir d'un service externe.",
      "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId n'est pas valide. Veuillez enregistrer votre application en tant qu'application native dans https://dev.powerbi.com/apps et contactez le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"GroupId est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"Le mot de passe est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"Le rapport est invalide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"Le nom d'utilisateur n'est pas valide, veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Une erreur s'est produite lors de la suppression du client. Veuillez contacter le soutien technique.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Une erreur s'est produite lors du chargement de votre image. Veuillez vérifier à nouveau les dimensions de l'image.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Échoué en raison d'une mauvaise demande. Merci d'essayer de nouveau.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Échoué en raison d'un conflit. Veuillez revérifier vos informations d'identification",
      "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Délai d'expiration de la passerelle. Veuillez réessayer.",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Service indisponible. Veuillez vérifier la configuration",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Une erreur s'est produite lors de l'initialisation du paiement, veuillez contacter le soutien technique.",
      "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Une erreur s'est produite lors du déchiffrement de la valeur",
      "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Une erreur s'est produite lors du chiffrement de la valeur",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"L'identifiant AD APP du fournisseur du panneau de contrôle ou l'identifiant du domaine partenaire n'existent pas.",
      "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"L'ID d'application AD de l'API de tarification Microsoft ou l'ID de locataire du Partenaire n'existent pas.",
      "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Une erreur s'est produite lors de la tentative d'importation de plusieurs paiements",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Une erreur s'est produite lors de la tentative d'importation de l'entrée du catalogue",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Une erreur s'est produite lors de la tentative d'importation des commissions sur les produits",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Impossible d'importer les détails de l'extension du produit",
      "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"Échec de l'intégration du revendeur dans le cadre de l'intégration groupée en raison d'erreurs de validation",
      "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Une erreur s'est produite lors de la soumission de la demande d'inscription",
      "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"Échec de l'envoi de l'e-mail à la liste d'utilisateurs indiquée. La révision de vos configurations SMTP peut aider à résoudre ce problème.",
      "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Une erreur s'est produite lors de la tentative d'importation des données d'enregistrement de paiement",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Une erreur s'est produite lors de la préparation de l'entrée du catalogue de produits",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Une erreur s'est produite lors de la préparation des détails des commissions sur les produits",
      "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"Échec de la synchronisation de l'abonnement aux instances réservées en raison de l'indisponibilité du produit. Veuillez contacter l'équipe d'assistance.",
      "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"Vous ne pouvez pas mettre à jour le pourcentage décimal avec une valeur du pourcentage disponible.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Impossible de charger l'image. Veuillez vérifier votre connectivité Internet et assurez-vous que la taille de l'image correspond aux spécifications données.",
      "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Une erreur s'est produite lors de l'obtention de l'état du lot à bord des revendeurs fournisseurs en masse",
      "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Une erreur s'est produite lors de la vérification de l'état de la référence aux données d'entité provenant d'un service externe.",
      "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Une erreur s'est produite lors de la récupération des paiements en attente. Veuillez contacter l'assistance.",
      "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id manquant ou invalide dans l'entrée",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le coût pour le Partenaire doit être supérieur au nombre de l'entrée",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le coût pour le Partenaire doit être décimal à partir de l'entrée",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Code de devise manquant ou invalide dans l'entrée",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Code de devise manquant ou invalide",
      "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Le code de devise doit correspondre à la devise du produit de l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Code de devise invalide dans l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Identifiant client manquant ou non valide dans l'entrée",
      "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Vous ne pouvez pas télécharger l'utilisation pour ce guid (client)",
      "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de produit interne non valide dans le catalogue",
      "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Séquence invalide dans le catalogue",
      "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Le code de la devise doit correspondre à l'entité concernée",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Entrées invalides dans le catalogue",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Montant du paiement manquant ou invalide",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"Le montant du paiement doit être positif",
      "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Format de date de paiement invalide",
      "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Date de paiement invalide",
      "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Identifiant de produit manquant ou non valide dans l'entrée",
      "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Vous ne pouvez pas télécharger l'utilisation du produit car le produit n'existe pas ou ne vous appartient pas.",
      "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de produit de fournisseur non valide dans le catalogue",
      "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"L'unité de mesure ne doit pas être un nombre entier/décimal à partir de l'entrée",
      "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Les unités doivent être des nombres entiers à partir de l'entrée",
      "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Les unités doivent être +ve nombre à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être au format AAAA-MM-JJ à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être de type date à partir de l'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation doit être postérieure à la date d'entrée",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"La date de paiement doit être postérieure",
      "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"La facture n'est pas générée pour les produits achetés",
      "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"L'article n'est pas achetable",
      "ERROR_DESC_MATCHING_PLAN_OFFER":"Une erreur s'est produite lors de la correspondance des offres de plan pour le plan de changement, veuillez contacter le support.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"Le client a été créé avec succès, mais n'a pas réussi à attribuer les groupes de sécurité à la demande GDAP. Veuillez compléter cette opération dans l'Espace partenaires, sinon d'autres actions échoueront.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Un problème s'est produit avec la demande GDAP. Veuillez travailler avec votre partenaire.",
      "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Client non valide",
      "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Non-concordance entre le ProviderResellerId spécifié et le ResellerId dans le fournisseur",
      "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"AgentName manquant dans le catalogue",
      "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id manquant dans l'entrée",
      "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Date de fin de commission manquante dans le catalogue",
      "ERROR_DESC_MISSING_COMMISSION_PENCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"PeNCEntage de commission manquant dans le catalogue",
      "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Pourcentage de commission manquant dans le catalogue",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner manquant dans l'entrée",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner est obligatoire pour le pourcentage du type de facturation",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CurrencyCode manquant dans l'entrée",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Code de devise manquant dans l'entrée",
      "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CustomerId manquant dans l'entrée",
      "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Identifiant interne de produit manquant dans le catalogue",
      "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"OfferId manquant dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Cycle de facturation manquant ou invalide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Catégorie manquante ou non valide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Fournisseur manquant ou non valide dans le catalogue",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Veuillez vérifier le fichier que vous avez téléchargé car il ne semble pas correspondre au modèle recommandé.",
      "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Montant du paiement manquant ou invalide dans l'entrée",
      "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Date de paiement manquante dans l'entrée",
      "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ProductId manquant dans l'entrée",
      "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Identifiant de produit fournisseur manquant dans le catalogue",
      "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"SPCode manquant dans le catalogue",
      "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"UnitOfMeasure manquante dans l'entrée",
      "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unités manquantes en entrée",
      "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"UsageDate manquante dans l'entrée",
      "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"Échec de la connexion à l'API de gestion Microsoft Azure.",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Erreur du centre de partenaires Microsoft lors de la mise à jour du nom du produit client",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Une exception du centre partenaire s'est produite lors de la tentative de modification du statut de l'abonnement. Veuillez contacter le soutien technique.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Erreur du centre partenaire lors de la rérification MPN ID. Veuillez vérifier votre entrée à nouveau.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Un problème est survenu lors de la connexion au centre partenaire Veuillez laisser l'administrateur actualiser le jeton et réessayer. Contactez le soutien technique pour obtenir de l'aide.",
      "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Une erreur s'est produite lors de l'attribution de groupes de sécurité à l'accès à la relation d'administration déléguée du client",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Erreur du centre partenaire lors de la création de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Erreur du centre partenaire lors de la suppression de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Une erreur s'est produite lors de l'obtention des groupes de sécurité de l'agent d'administration Microsoft",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Erreur du centre partenaire lors de la récupération de tous les clients",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Erreur du Centre des Partenaires Microsoft lors de la tentative d'obtention de tous les revendeurs",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Erreur du centre partenaire lors de la récupération des règles de validation de pays",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Erreur du centre partenaire lors de la récupération de clients",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Une erreur s'est produite lors de l'obtention des détails de la relation d'administration déléguée du client fournisseur à partir du centre des partenaires",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"Le centre partenaire MS a rencontré une erreur en essayant de récupérer votre profil.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Impossible de récupérer le jeton d'actualisation.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Erreur du centre partenaire lors de la récupération de l'utilisateur de domaine",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Erreur du centre partenaire lors de la récupération des licences affectées à l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Erreur du centre partenaire lors de la récupération de données de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Erreur du centre partenaire lors de la récupération des rôles d'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Erreur du centre partenaire lors de la récupération des utilisateurs",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Erreur du centre partenaire lors de l'enregistrement du consentement du client",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Erreur du centre partenaire lors de la mise à jour de l'utilisateur",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Erreur du centre partenaire lors de la mise à jour des affectations de licence utilisateur",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Erreur du centre partenaire lors de la mise à jour du mot de passe de l'utilisateur",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Erreur du centre partenaire lors de la récupération des clients",
      "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Nom de domaine non disponible",
      "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"Impossible d'obtenir l'accès à partir de l'API de tarification Microsoft",
      "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Veuillez vérifier à nouveau. Fichier manquant dans la demande de validation des données",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"Aucune donnée disponible pour la mise en scène pour Autotask",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"Aucune donnée disponible pour la mise en scène pour \"ConnectWise Manage\"",
      "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"Aucun détail de revendeur de fournisseur trouvé dans le centre des Partenaires Microsoft",
      "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"Aucun revendeur avec identifiant MPN n'a été trouvé dans C3",
      "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"Le Partenaire n'est pas autorisé à acheter l'article",
      "ERROR_DESC_NOT_FOUND_ERROR":"Impossible de trouver la demande",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande",
      "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Une erreur s'est produite lors de la planification de la commande.",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"L'intégration a échoué en raison d'un domaine invalide.",
      "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"Offre parent indisponible pour cette offre d'essai",
      "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Le profil de paiement est manquant. Veuillez fournir un profil de paiement et réessayer.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"La gestion client a échoué en raison d'une exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestion du profil de paiement a échoué en raison d'une exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"La suppression de l'utilisateur a échoué en raison d'une erreur dans le portail du fournisseur de paiement.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Impossible de trouver un client avec l'identifiant",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Récupération des profils de paiement échouée en raison de l'exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Récupération du statut de paiement échouée en raison de l'exception du fournisseur.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Une erreur s'est produite lors de la gestion de la facturation client. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Une erreur s'est produite lors de la gestion du profil de paiement. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"Une erreur s'est produite lors de la création de la transaction. Veuillez contacter le soutien technique.",
      "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Une erreur s'est produite lors de la mise à jour de l'état du paiement. Veuillez contacter l'assistance.",
      "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Cartographie du produit avec le mauvais client",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de locataire et de catégorie ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de catégorie ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La sélection de locataire ne prend pas en charge les produits déjà mappés",
      "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"La date d'utilisation tombe dans la période de suspension du produit",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Le fournisseur BusinessId existe déjà dans le système",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"La BusinessId du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"L'e-mail de facturation n'est pas valide",
      "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"Le pays du revendeur du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Le fournisseur ResellerId existe déjà dans le système",
      "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"ResellerId du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"Le nom du revendeur du fournisseur ne peut pas être nul",
      "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Les détails du revendeur du fournisseur ne sont pas liés à c3, veuillez contacter votre Partenaire ou notre équipe d'assistance",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"La longueur des caractères des remarques doit être inférieure à 200",
      "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Veuillez vérifier à nouveau. Fichier manquant dans la demande de validation des données",
      "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"Le revendeur existe déjà dans le système",
      "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Sélectionnez au moins un produit",
      "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"Le paiement a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"La récupération du paiement en attente a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"La configuration du fournisseur de facturation a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"L'attribution du coupon a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"La gestion des coupons a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"La gestion des clients a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestion du profil de paiement a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_DELETE_CUSTOMER":"La suppression du client a échoué en raison d'une erreur de base de données.",
      "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de l'ajout d'offres au plan. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors du clonage du plan. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de la tentative de demande de plan de clonage. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Nous sommes désolés de vous informer qu'une exception de base de données s'est produite lors de la tentative de demande de création d'un nouveau plan avec toutes les offres. Veuillez contacter l'assistance",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Erreur de la base de données lors de l'enregistrement de l'identifiant MPN",
      "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_DESC_SQL_GET_CUSTOMER":"La récupération du client a échoué en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"Échec de l'obtention des profils de paiement en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"Échec de l'obtention de l'état du paiement en raison d'une exception de base de données.",
      "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Erreur de la base de données lors de l'obtention des clients.",
      "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"Le nombre d'abonnements souhaité 6 a dépassé le nombre maximum d'abonnements autorisé par client 5",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Vous n'êtes pas autorisé à modifier le statut du produit, car la propriété est partagée avec des entités opérationnelles",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"La mise à jour du statut du produit n'a pas été entièrement réussie, veuillez contacter notre équipe de soutien pour obtenir de l'aide",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Ce code fiscal existe déjà, veuillez sélectionner un code fiscal unique.",
      "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"Les paramètres SMTP fournis sont erronés",
      "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"Nous ne pouvons pas traiter cette demande car les transactions sont désactivées.",
      "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"L'offre d'essai ne peut pas être achetée avec d'autres catégories",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"Impossible de traiter votre demande, veuillez contacter le soutien technique",
      "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"Impossible de traiter cette commande",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Vous n'êtes pas autorisé à accéder à cette fonctionnalité",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"L'emplacement d'utilisation n'est pas valide",
      "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Une erreur s'est produite lors de la validation du plan client, veuillez contacter l'assistance.",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Erreur survenue lors de l'accès à la base de données",
      "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Une erreur s'est produite lors de l'ajout d'un droit",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Une erreur s'est produite lors de l'ajout du locataire",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Une erreur s'est produite lors de la fourniture du consentement au locataire",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_Azure_PLAN":"Le locataire a été créé, mais une erreur s'est produite lors de l'ajout de l'abonnement",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Le locataire a été créé, mais une erreur s'est produite lors de l'ajout de l'abonnement",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Une erreur s'est produite lors de l'attribution du rôle",
      "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de l'annulation du renouvellement programmé",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"L'ID de catalogue donné n'est pas valide",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Une erreur s'est produite lors de la vérification de l'éligibilité à la promotion Microsoft NCE",
      "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Une erreur s'est produite lors de la création d'un abonnement aux instances réservées par synchronisation",
      "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de la création du renouvellement programmé",
      "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Erreur lors de la mise en file d'attente du message pour télécharger l'historique des abonnements dans le service PSA",
      "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Une erreur s'est produite lors de la génération d'une facture groupée",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Une erreur s'est produite lors de la génération du fichier CSV pour le rapport récapitulatif des éléments de facture",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Une erreur s'est produite lors de la génération du CSV pour les éléments de ligne de facture avec le rapport sur les détails de la commission",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Une erreur s'est produite lors de la génération du CSV pour le rapport de rentabilité",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Une erreur s'est produite lors de la génération du fichier CSV pour le rapport récapitulatif des montants de taxe",
      "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Erreur lors de l'obtention des détails du service PSA actif",
      "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Une erreur s'est produite lors de la tentative d'obtention de catégories pour la comparaison des revenus et des coûts.",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Une erreur s'est produite lors de l'obtention des détails de l'abonnement",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Erreur lors de l'obtention du mappage des abonnements client vers PSA",
      "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Une erreur s'est produite lors de l'obtention du nombre de clients",
      "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Une erreur s'est produite lors de la tentative d'obtention de détails sur les revenus et les coûts par catégorie.",
      "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Une erreur s'est produite lors de l'obtention des détails de l'abonnement au service externe par ID d'abonnement c3, ID de service de contrat et ID de contrat",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors de l'obtention de la liste des configurations gérées par le client",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors de l'obtention de la liste des configurations gérées par le revendeur",
      "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Une erreur s'est produite lors de l'obtention de la liste des dates de fin d'abonnement",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Erreur lors de l'obtention des entités mappées et des services contractuels pour Autotask",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Erreur lors de l'obtention des entités et produits mappés pour ConnectWise Manage",
      "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Une erreur s'est produite lors de l'obtention de revendeurs de fournisseurs non intégrés",
      "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Une erreur s'est produite lors de l'obtention des paramètres du portlet",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Une erreur s'est produite lors de l'obtention du nombre de produits",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Une erreur s'est produite lors de la récupération du produit comme à la date",
      "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Une erreur s'est produite lors de la récupération des détails de la promotion",
      "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Erreur lors de l'obtention du résumé du journal de publication PSA",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Une erreur s'est produite lors de l'obtention des sièges achetés par identifiant Sku",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Une erreur s'est produite lors de l'obtention des sièges achetés au cours d'une période donnée",
      "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Une erreur s'est produite lors de l'obtention du revenu par rapport au coût",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Une erreur s'est produite lors du décompte des sièges",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Une erreur s'est produite lors du décompte des sièges comme à la date",
      "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Erreur lors de l'obtention des paramètres de la tâche automatique",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Erreur lors de l'obtention de l'état du journal du service PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Erreur lors de l'obtention des journaux de publication du service PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Erreur lors de l'obtention des enregistrements échelonnés PSA",
      "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Une erreur s'est produite lors de l'obtention des clients estimés",
      "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Une erreur s'est produite lors de l'obtention de la valeur des produits achetés par chiffre d'affaires",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Erreur lors de la gestion des entités mappées et des services contractuels pour Autotask",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Erreur lors de la gestion des entités et des produits mappés pour ConnectWise Manage",
      "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Une erreur s'est produite lors de la tentative d'intégration du revendeur du fournisseur",
      "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Une erreur s'est produite lors du traitement de la demande de revendeurs de fournisseurs intégrés en masse",
      "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Une erreur s'est produite lors de la mise en file d'attente pour les revendeurs de fournisseurs intégrés en masse",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors du rétablissement des configurations gérées par le client par défaut",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors du rétablissement des configurations gérées par le revendeur par défaut",
      "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Une erreur s'est produite lors de l'enregistrement ou de la mise à jour de la date de fin personnalisée de l'élément de campagne du panier.",
      "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Erreur lors de l'enregistrement des données d'historique d'abonnement des clients sélectionnés dans la mise en scène",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Une erreur s'est produite lors de la mise à jour des configurations gérées par le client",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Une erreur s'est produite lors de la mise à jour des configurations gérées par le revendeur",
      "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Une erreur s'est produite lors de la mise à jour du renouvellement programmé",
      "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Erreur lors de la mise à jour du statut des enregistrements échelonnés",
      "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Une erreur s'est produite lors de la mise à niveau de l'abonnement",
      "ERROR_DETAILS":"Détails de l'erreur",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Ce nom de département existe déjà sous ce client et ce site.",
      "ERROR_DUPLICATE_SITE_NMAE":"Ce nom de site existe déjà sous ce client.",
      "ERROR_EMPTY_COMMENTS_SUBMITTED":"Les commentaires vides ne peuvent pas être soumis",
      "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"Échec de l'importation des détails du paiement",
      "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Échec de l'importation des détails de la commission sur les produits",
      "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"Échec de la mise en scène de l'ajout groupé d'entrées de locataire",
      "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Erreur lors de la génération des détails dans les détails de l'entité de la facture groupée",
      "ERROR_FIELD_REQUIRED":"Ce champ est obligatoire",
      "ERROR_FORM_INVALID":"Le formulaire n'est pas valide.",
      "ERROR_GETTING_HOSTED_TOKEN":"Erreur lors de la récupération du jeton hébergé",
      "ERROR_GETTING_STRIPE_DETAILS":"Une erreur s'est produite lors de la récupération des informations Stripe",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Vous ne semblez pas avoir les autorisations nécessaires pour effectuer cette action",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Interdit !",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Impossible de mettre à jour le statut du produit, car une affectation de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "ERROR_MESSAGE":"Quelque chose s'est mal passé!",
      "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"L'approbation n'a pas abouti, veuillez contacter l'assistance",
      "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Au moins un client doit être sélectionné",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Vous n'êtes pas autorisé à réduire le nombre de licences",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Une erreur s'est produite lors de la création du plan. Veuillez contacter le soutien technique.",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Une erreur s'est produite lors de la création du plan revendeur. Veuillez contacter notre équipe d'assistance.",
      "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Autotask. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Autotask via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Tâche automatique</b>.",
      "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Connectwise. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Connectwise via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Gestion Connectwise</b>.",
      "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"Les paramètres SMTP ne sont pas valides",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Nous sommes désolés mais la connectivité semble rompue entre votre compte C3 et Microsoft Partner Center. Cela peut entraîner un échec lors de l'exécution de toutes les transactions de l'Espace partenaires via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Fournisseurs</b>",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Nous sommes désolés, mais la connectivité semble interrompue entre votre compte C3 et l'API Microsoft Partner Pricing. Cela peut entraîner un échec lors de la récupération des détails de l'API de tarification Microsoft via C3. Veuillez établir la connectivité en visitant <b>Paramètres -> Fournisseurs</b>",
      "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Quickbooks. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Quickbooks via C3. Veuillez établir la connectivité en visitant <b>Analyser -> Entreprise -> Télécharger les factures/paiements</b>.",
      "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Nous sommes désolés que la connectivité semble interrompue entre votre compte C3 et Xero. Cela peut entraîner un échec lors de l'exécution de toutes les transactions Xero via C3. Veuillez établir la connectivité en visitant <b>Analyser -> Entreprise -> Télécharger les factures/paiements</b>.",
      "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Numéro de panier invalide",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Client non valide",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"L'utilisateur du domaine n'est pas valide",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"La modification de statut n'est pas valide.",
      "ERROR_MESSAGE_MANAGE_OWNERSHIP":"Le champ « Quantité » est obligatoire et ne peut pas contenir de valeur nulle ou décimale. Veuillez saisir un nombre entier valide.",
      "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Jusqu'à 3 marchés peuvent être sélectionnés.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Aucun message trouvé relatif à une demande de relation revendeur, veuillez contacter le soutien technique de C3",
      "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"Le panier contient des offres autres que les offres Partner - LicenseSupported, veuillez les supprimer pour continuer.",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Un utilisateur possédant cette adresse courriel existe déjà ou vous n'avez pas l'autorisation d'ajouter cet utilisateur",
      "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Les achats peuvent échouer. Veuillez cliquer pour en savoir plus.",
      "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"La diminution de la quantité n'est pas autorisée",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Ce client n'est pas lié à Microsoft csp",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"Impossible de traiter votre demande.",
      "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Vous avez saisi plus de bons que nécessaire",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Une erreur s’est produite lors de l'exportation du rapport des éléments de ligne la facture. Veuillez contacter l'administrateur",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Une erreur s’est produite lors de l'exportation du rapport sommaire de la facture. Veuillez contacter l'administrateur",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Une erreur s’est produite pendant le chargement du fichier",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Erreur lors du chargement de l'image. Veuillez réessayer avec une autre image.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Veuillez sélectionner l'extension de l'image ex : .jpg, .png etc.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Erreur du centre partenaire lors de la rérification MPN ID. Veuillez vérifier votre entrée à nouveau.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Erreur du centre partenaire lors de la création de l'utilisateur",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Erreur du centre partenaire lors de la suppression de l'utilisateur",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Erreur du centre partenaire lors de la récupération de l'utilisateur de domaine",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Erreur du centre partenaire lors de la récupération de données de l'utilisateur",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Erreur du centre partenaire lors de la récupération des rôles d'utilisateur",
      "ERROR_MS_FAILED_TO_GET_USERS":"Erreur du centre partenaire lors de la récupération des utilisateurs",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Erreur du centre partenaire lors de la mise à jour de l'utilisateur",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Erreur du centre partenaire lors de la mise à jour des affectations de licence utilisateur",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Erreur du centre partenaire lors de la mise à jour du mot de passe de l'utilisateur",
      "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Une erreur s'est produite lors de la tentative de mise en file d'attente d'une demande de mise à jour des dernières offres vers le plan",
      "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Une erreur s'est produite lors de la vérification de l'éligibilité aux promotions.",
      "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Une erreur s'est produite lors du comptage des promotions du mois en cours.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Une erreur s'est produite lors de la récupération des détails des promotions.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Une erreur s'est produite lors de l'obtention des détails des promotions auprès du fournisseur.",
      "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Une erreur s'est produite lors du chargement des promotions.",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande",
      "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"L'erreur s'est produite lors de l'enregistrement des détails de la mise à niveau de l'abonnement dans la table intermédiaire",
      "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Une erreur s'est produite lors de la planification de la commande. Veuillez contacter l'assistance",
      "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"L'erreur s'est produite lors de la tentative de récupération des produits éligibles pour la mise à niveau de l'abonnement",
      "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Une erreur s'est produite lors de la mise à jour de l'ID de promotion dans cartlineItem.",
      "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Le produit est manquant lors de l'importation des données d'utilisation quotidienne de l'offre du Partenaire",
      "ERROR_PLAN_ID":"Identifiant du forfait",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Veuillez confirmer l'acceptation par le client du contrat client Microsoft",
      "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Erreur lors de l'importation des données d'utilisation quotidienne de l'offre Partenaire",
      "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Une erreur s'est produite lors de la tentative d'importation des commissions sur les produits",
      "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La séquence de produits contient des valeurs non valides ou négatives. Veuillez fournir une séquence valide.",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"Le locataire que vous essayez d'intégrer/lier existe déjà dans le portail, veuillez contacter l'assistance",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Le domaine que vous essayez d'intégrer/lier existe pour l'un de vos clients. Veuillez essayer un autre domaine.",
      "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La ligne de devis contient des valeurs non valides ou négatives. Veuillez fournir une quantité valide comprise entre 1 et 9999.",
      "ERROR_RESELLER_PROVIDER_ID":"Identifiant de fournisseur revendeur",
      "ERROR_SAVING_CUSTOMER":"Erreur lors de l'enregistrement du client",
      "ERROR_SAVING_PARTNER_OFFER":"Une erreur s'est produite lors de l'enregistrement de l'offre du Partenaire",
      "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"Le bundle doit contenir la même catégorie d'offres MS.",
      "ERROR_SAVING_PAYMENT_ACCOUNT":"Erreur lors de l'enregistrement du compte de paiement",
      "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Erreur de la base de données lors de l'enregistrement de l'identifiant MPN",
      "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Erreur de la base de données lors de la tentative d'enregistrement de l'utilisateur client",
      "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Seules les offres personnalisées sont éligibles à l'offre d'essai.",
      "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Seules les offres basées sur la quantité sont éligibles à l'offre d'essai.",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Impossible de trouver un domaine du fournisseur.",
      "ERROR_UPDATING_AUTO_RENEW_STATUS":"La mise à jour du renouvellement automatique a échoué pour {{productName}}.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Erreur survenue lors de l'accès à la base de données",
      "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Une erreur s'est produite lors de la tentative de vérification du lien entre le revendeur et le fournisseur",
      "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Une erreur s'est produite lors du téléchargement de l'exemple de fichier de modèle de transfert groupé .csv",
      "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Erreur lors de la génération du rapport sur le catalogue de produits",
      "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Une erreur s'est produite lors de l'obtention de l'historique du consentement au renouvellement",
      "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Une erreur s'est produite lors de l'obtention des dates de fin à partir du Centre des partenaires. Veuillez contacter l'assistance.",
      "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Une erreur s'est produite lors de la tentative d'obtention des cotisations de la facture",
      "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Une erreur s'est produite lors de l'obtention des sièges du produit évalué",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Veuillez sélectionner au moins une facture à publier sur Xero.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Erreur : Abandon du téléchargement en raison d'une erreur lors de la création des entités",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Erreur : l'ajout est dans ConnectWise Manage actif",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant de l'accord est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Erreur : la facturation n'est pas activée pour cet accord dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Erreur : l'accord mappé n'est pas actif dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Erreur : l'accord n'est pas présent dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Erreur : les accords ne sont pas mappés pour ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Erreur : les accords ne sont pas présents dans ConnectWise Manage sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : Des ajouts ambigus sont présents, le mappage n'est pas possible.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant du contrat est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Erreur : la facturation n'est pas activée pour ce contrat dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Erreur : le contrat mappé n'est pas actif dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Erreur : le contrat n'est pas présent dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : le contrat n'est pas présent dans le service externe sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : le service contractuel n'est pas mappé sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Erreur : le service de contrat mappé n'est pas présent dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : les services contractuels ne sont pas mappés sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Erreur : les contrats ne sont pas mappés pour ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Erreur : les contrats ne sont pas présents dans Autotask sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : les contrats ne sont pas présents dans le service externe sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Erreur : le client n'est pas actif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Erreur : le client externe n'est pas mappé avec le client c3",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Erreur : le client n'est pas présent dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Erreur : le mappage de l'entité ou du produit est manquant.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Erreur : une tentative d'exportation a déjà été effectuée. Le statut de cette opération est",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Erreur : le contrat n'est pas actif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant client externe est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Erreur : les clients ne sont pas présents dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Erreur : une erreur s'est produite lors de la création de l'ajout dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Erreur : Une erreur s'est produite lors de la création d'un ajustement :",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Erreur : une erreur s'est produite lors de la création d'un ajustement pour changement de prix",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Erreur : une erreur s'est produite lors de la création du service",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Erreur : échec de création de frais de contrat pour un produit à usage unique",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Erreur : une erreur s'est produite lors de la création du service de contrat :",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Erreur : une erreur s'est produite lors de la mise à jour de l'ajout dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Erreur : impossible de créer un mappage car plusieurs services correspondants ont été trouvés dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Erreur : aucun contrat correspondant n'a pu être trouvé pour être mappé automatiquement.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Erreur : aucun client externe correspondant n'a pu être trouvé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Erreur : aucun produit externe correspondant n'a pu être trouvé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : le produit n'est pas associé à ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Erreur : le produit mappé n'est pas présent dans ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Erreur : les produits ne sont pas mappés sous ce client",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Erreur : les produits C3 n'ont pas de service externe mappé",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Erreur : l'identifiant du service est manquant",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin de l'accord.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin du contrat.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation de l'accord",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Erreur : la date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Erreur : l'abonnement est actif dans Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Erreur : l'abonnement est inactif dans le service externe",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Erreur : Impossible de terminer le mappage automatique car plusieurs contrats correspondants ont été trouvés",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Erreur : impossible de terminer le mappage automatique car plusieurs clients externes ont été mis en correspondance",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Erreur : impossible de terminer le mappage automatique car plusieurs produits externes ont été mis en correspondance",
      "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Erreur : la validation n'a pas réussi",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
      "ERRORMESSAGEPOPUP_HEADER":"Veuillez inclure au moins un des prérequis pour les extensions dans la liste ci-dessous",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Extension",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Offre pré-requise",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Erreur : l'accord PSA n'est pas mappé avec le client c3",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Erreur : le produit externe n'est pas mappé avec le produit c3",
      "ERRORS_FROM_PREVIOUS_ACTION":"Votre dernière action a entraîné des erreurs, cliquez pour plus de détails...",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Une erreur s'est produite lors du traitement de votre demande Veuillez contacter le soutien technique.",
      "ET":"et",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"ID d'abonnement du plan Azure",
      "EVENT_Azure_PLAN_SUBSCRIPTION_ID":"subscriptionId du plan Azure",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Nom du plan Azure",
      "EVENT_Azure_SUBSCRIPTION_UPGRADEID":"ID de mise à niveau",
      "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"ID de mise à niveau",
      "EVENT_BATCH_ID":"ID de lot",
      "EVENT_BATCH_STEP_ID":"ID d'étape de lot",
      "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Résumé du lot du revendeur intégré en masse",
      "EVENT_CAN_PRICE_LAG":"AncienCanPriceLag",
      "EVENT_CAN_PRICE_LEAD":"NouveauCanPriceLead",
      "EVENT_CONSTANT_TRUE":"TRUE",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Continuer l'offre d'essai jusqu'à l'échec de l'abonnement",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Continuer l'offre d'essai jusqu'au succès de l'abonnement",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Convertir l'offre d'essai en échec d'abonnement",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Convertir l'offre d'essai en succès d'abonnement",
      "EVENT_CREATED_BY":"Événement créé par",
      "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Identifiant interne",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Courriel",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Prénom",
      "EVENT_DATA_ACCOUNT_MANAGER_ID":"Identifiant du gestionnaire de compte",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Nom de famille",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Numéro de téléphone",
      "EVENT_DATA_ACCOUNT_NUMBER":"Numéro de compte",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Action",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Erreur",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Statut",
      "EVENT_DATA_ADDRESS_CITY":"Ville",
      "EVENT_DATA_ADDRESS_COUNTRY":"Pays",
      "EVENT_DATA_ADDRESS_LINE_1":"Adresse Ligne 1",
      "EVENT_DATA_ADDRESS_LINE_2":"Adresse Ligne 2",
      "EVENT_DATA_ADDRESS_STATE":"État",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Code postal",
      "EVENT_DATA_AGREEMENT":"Accord",
      "EVENT_DATA_AGREEMENT_DATE":"Date de contrat",
      "EVENT_DATA_AGREEMENT_LINK":"Lien du contrat",
      "EVENT_DATA_AGREEMENT_NAME":"Accord",
      "EVENT_DATA_AGREEMENT_TYPE":"Type de contrat",
      "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"L'attribution des groupes de sécurité Microsoft à la requête GDAP a réussi ?",
      "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Coupon attribué à",
      "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":"Nouvelle valeur",
      "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Ancienne valeur",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Actions",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Erreur",
      "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Raison",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Statut",
      "EVENT_DATA_BCC_RECIPIENTS":"Destinataires CCI",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Nom du cycle de facturation",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Alias de paiement",
      "EVENT_DATA_BILLING_PERIOD_ID":"Identifiant de période de facturation",
      "EVENT_DATA_BILLING_PROVIDER":"Fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Identifiant de client fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Nom de client fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Identifiant de revendeur de fournisseur de facturation",
      "EVENT_DATA_BILLING_PROVIDER_USER":"UserId",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"UserId",
      "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Nom du bouquet",
      "EVENT_DATA_C3_CUSTOMER_NAME":"Client C3",
      "EVENT_DATA_C3_PRODUCT_NAME":"Produit C3",
      "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Nom de l'abonnement C3",
      "EVENT_DATA_CARD_TYPE":"Type de carte",
      "EVENT_DATA_CART_ID":"Identifiant de panier",
      "EVENT_DATA_CART_ITEMS":"Articles du panier",
      "EVENT_DATA_CATEGORY_ID":"Identifiant de catégorie",
      "EVENT_DATA_CATEGORY_NAME":"Nom de catégorie",
      "EVENT_DATA_CC_RECIPIENTS":"Destinataires CC",
      "EVENT_DATA_CHANGE_TYPE":"Modifier le type",
      "EVENT_DATA_CLONED_FROM_PLAN":"Cloné à partir de",
      "EVENT_DATA_COMPANY_NAME":"Nom de l'entreprise",
      "EVENT_DATA_CONSUMED_SEATS":"Sièges consommés",
      "EVENT_DATA_CONSUMPTION_TYPE":"Type de consommation",
      "EVENT_DATA_CONTRACT":"Contrat",
      "EVENT_DATA_CONTRACT_NAME":"Nom du contrat",
      "EVENT_DATA_CONTRACT_SERVICE_NAME":"Nom du service contractuel",
      "EVENT_DATA_CONVERSION_RATE":"Taux de conversion",
      "EVENT_DATA_CORRELATION_ID":"ID de corrélation",
      "EVENT_DATA_COUNTRY_CODE":"Code de pays",
      "EVENT_DATA_COUPON_CODE":"Code promotionnel",
      "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Nombre de clients avec de nouvelles missions",
      "EVENT_DATA_COUPON_DESCRIPTION":"Description de coupon",
      "EVENT_DATA_COUPON_DETAILS":"Détails du coupon",
      "EVENT_DATA_COUPON_DISCOUNT":"Rabais",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Expire le",
      "EVENT_DATA_COUPON_NOOFRECURRENCES":"Nombre de récurrences",
      "EVENT_DATA_COUPON_VALID_TILL":"Valide jusqu'à",
      "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Créé par dans le fournisseur",
      "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Date de création dans le fournisseur",
      "EVENT_DATA_CURRENCY_CODE":"Code de devise",
      "EVENT_DATA_CURRENT_QUANTITY":"Quantité actuelle",
      "EVENT_DATA_CURRENT_STATUS":"Statut actuel",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Nouveau cycle de facturation personnalisé",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Ancien cycle de facturation personnalisé",
      "EVENT_DATA_CUSTOMER_C3_ID":"Identifiant de client C3",
      "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Consentement",
      "EVENT_DATA_CUSTOMER_NAME":"Nom du client",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Nouveau nom du client",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Ancien nom du client",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Identifiant de référence client",
      "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"Inscription client batchid",
      "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"Interne planid",
      "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"Tous les journaux PSA sont-ils désactivés ?",
      "EVENT_DATA_DEPARTMENT_NAME":"Nom du département",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Cycle de facturation",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Nom",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Coût d'acquisition",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Prix de vente",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Valide",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Type de validité",
      "EVENT_DATA_DOMAIN":"Domaine",
      "EVENT_DATA_DOMAIN_NAME":"Nom du domaine",
      "EVENT_DATA_EFFECTIVE_FROM":"En vigueur à partir de",
      "EVENT_DATA_EMAIL":"Courriel",
      "EVENT_DATA_EMAIL_ADDRESS":"Courriel",
      "EVENT_DATA_END_BILLING_PERIOD_ID":"Fin de facturation periodId",
      "EVENT_DATA_END_DATE":"Date de fin",
      "EVENT_DATA_ENTITY_ID":"Identifiant d'entité",
      "EVENT_DATA_ENTITY_NAME":"Nom de l'entité",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entité de l'utilisateur",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entité de l'utilisateur",
      "EVENT_DATA_ERROR":"Erreur",
      "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Cartographie du produit avec erreur",
      "EVENT_DATA_EVENT":"Événement",
      "EVENT_DATA_EVENT_ID":"Identifiant de l'événement",
      "EVENT_DATA_EVENT_NAME":"Nom de l'événement",
      "EVENT_DATA_EXCEPTION_MESSAGE":"Message d'exception",
      "EVENT_DATA_EXPIRES_ON":"ExpiresOn",
      "EVENT_DATA_EXPORT_ERROR":"Erreur d'exportation",
      "EVENT_DATA_EXPORT_STATUS":"Statut d'exportation",
      "EVENT_DATA_EXTERNAL_AGREEMENTS":"Accords externes",
      "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Services contractuels externes",
      "EVENT_DATA_EXTERNAL_CONTRACTS":"Contrats externes",
      "EVENT_DATA_EXTERNAL_CUSTOMERS":"Client PSA",
      "EVENT_DATA_EXTERNAL_PRODUCTS":"Produit PSA",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"CustomerId",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"ID de l'historique des abonnements",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Identifiant de l'abonnement",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Identifiant du contrat",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"Identifiant de service de contrat",
      "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"Identifiant client externe",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Erreur d'exportation",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Statut d'exportation",
      "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Type de mappage de produit",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Erreur de validation",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Statut de validation",
      "EVENT_DATA_FAILED_TENANT_ID":"Échec de l'ID de locataire",
      "EVENT_DATA_GROUP_DESCRIPTION":"Description du groupe",
      "EVENT_DATA_GROUP_NAME":"Nom du groupe",
      "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"A des détails supplémentaires",
      "EVENT_DATA_IMPERSONATOR":"Personnificateur",
      "EVENT_DATA_INPUT_DATA":"Demande de détails",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Identifiant du client interne",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Identifiant de produit client interne",
      "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Identifiant interne pour le nouveau renouvellement programmé",
      "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Identifiant interne de l'ancien renouvellement programmé",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId de plan source",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId de plan cible",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"Identifiant de produit C3",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Identifiant d'abonnement interne",
      "EVENT_DATA_INVOICE_LINEITEM":"Nom de l'élément de ligne de facture",
      "EVENT_DATA_INVOICE_NUMBER":"Numéro de facture",
      "EVENT_DATA_INVOICE_PROPERTY":"Nom de la propriété",
      "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Ancienne valeur",
      "EVENT_DATA_INVOICE_PROPERTY_VALUE":"Nouvelle valeur",
      "EVENT_DATA_IS_ACCEPTED":"IsAccepted",
      "EVENT_DATA_IS_ENABLED":"IsEnabled",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"IsImmediateProvisioning",
      "EVENT_DATA_IS_SHOW_ON_UI":"Est affiché à l'écran",
      "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
      "EVENT_DATA_LINE_ITEM":"Élément de ligne",
      "EVENT_DATA_LINKED_PRODUCT_NAME":"Nom du produit lié",
      "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Nom du produit lié",
      "EVENT_DATA_LOGGED_IN_USER":"Utilisateur connecté",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Gérer les détails de la licence utilisateur",
      "EVENT_DATA_MESSAGE":"Message",
      "EVENT_DATA_Name":"Nom",
      "EVENT_DATA_NAME":"Nom",
      "EVENT_DATA_NAME_OF_ENTITY":"Nom de l'entité",
      "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Nouvelle valeur",
      "EVENT_DATA_NEW_COUPON_CODE":"Nouveau code promo",
      "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Nouvelle description du coupon",
      "EVENT_DATA_NEW_COUPON_DISCOUNT":"Nouveau bon de réduction",
      "EVENT_DATA_NEW_COUPON_EXPIRESON":"Le nouveau coupon expire le",
      "EVENT_DATA_NEW_COUPON_NAME":"Nouveau nom du coupon",
      "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Nouveau coupon no de récurrences",
      "EVENT_DATA_NEW_COUPON_VALIDTILL":"Nouveau coupon valable jusqu'au",
      "EVENT_DATA_NEW_DESCRIPTION":"Description du nouveau plan",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description de l'offre nouveau distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'offre du nouveau distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Nouvelle offre distributeur",
      "EVENT_DATA_NEW_END_DATE_TYPE":"Nouveau type de date de fin",
      "EVENT_DATA_NEW_ENTITY_NAME":"Nouvelle entité",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transféré à",
      "EVENT_DATA_NEW_ISPUBLIC":"Le nouveau plan est public",
      "EVENT_DATA_NEW_MARKUP":"Nouveau balisage",
      "EVENT_DATA_NEW_NAME":"Nom du nouveau plan",
      "EVENT_DATA_NEW_OFFER":"Nouvelle offre",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Description de l'offre du nouveau Partenaire",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Nom de l'offre du nouveau Partenaire",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Ajouter une offre personnalisée",
      "EVENT_DATA_NEW_PLAN":"Nouveau plan",
      "EVENT_DATA_NEW_PRICE":"Nouveau prix",
      "EVENT_DATA_NEW_PRODUCT_MAPPING":"Cartographie des nouveaux produits",
      "EVENT_DATA_NEW_PROMOTION_ID":"Nouvel identifiant de promotion",
      "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Nouvelle date de fin d'effet",
      "EVENT_DATA_NEW_QUANTITY":"Nouvelle quantité",
      "EVENT_DATA_NEW_SEATS":"Nouveaux sièges",
      "EVENT_DATA_NEW_STATUS":"Nouveau statut",
      "EVENT_DATA_NEW_VALIDITY":"Nouvelle validité",
      "EVENT_DATA_NEW_VALIDITY_TERM":"Nouvelle validité",
      "EVENT_DATA_NEW_VALIDITYTYPE":"Nouveau type de validité",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Destinataires de notification",
      "EVENT_DATA_OFFER_NAME":"Nom de l'offre",
      "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Prix de l'offre après remise",
      "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Ancienne valeur",
      "EVENT_DATA_OLD_COUPON_CODE":"Ancien code promo",
      "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Ancienne description du coupon",
      "EVENT_DATA_OLD_COUPON_DISCOUNT":"Ancien bon de réduction",
      "EVENT_DATA_OLD_COUPON_EXPIRESON":"L'ancien coupon expire le",
      "EVENT_DATA_OLD_COUPON_NAME":"Nom de l'ancien coupon",
      "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Ancien coupon n° de récurrences",
      "EVENT_DATA_OLD_COUPON_VALIDTILL":"Ancien coupon valable jusqu'au",
      "EVENT_DATA_OLD_DESCRIPTION":"Description de l'ancien régime",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Description de l'offre du distributeur ancien",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'ancienne offre du distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Ancienne offre distributeur",
      "EVENT_DATA_OLD_ENTITY_ID":"Ancienne entité",
      "EVENT_DATA_OLD_ENTITY_NAME":"Ancienne entité",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transféré à partir de",
      "EVENT_DATA_OLD_ISPUBLIC":"L'ancien plan ispublic",
      "EVENT_DATA_OLD_NAME":"Nom de l'ancien plan",
      "EVENT_DATA_OLD_OFFER":"Ancienne offre",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Description de l'ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Nom de l'ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Ancienne offre Partenaire",
      "EVENT_DATA_OLD_PLAN":"Ancien régime",
      "EVENT_DATA_OLD_PRICE":"Ancien prix",
      "EVENT_DATA_OLD_QUANTITY":"Ancienne quantité",
      "EVENT_DATA_OLD_SEATS":"Anciens sièges",
      "EVENT_DATA_OLD_STATUS":"Ancien statut",
      "EVENT_DATA_OLD_VALIDITY":"Ancienne validité",
      "EVENT_DATA_OLD_VALIDITY_TERM":"Ancienne validité",
      "EVENT_DATA_OLD_VALIDITYTYPE":"Ancien type de validité",
      "EVENT_DATA_PAID_AMOUNT":"Montant payé",
      "EVENT_DATA_PAID_DATE":"Date de paiement",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Identifiant d'abonnement au plan Azure",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Nom de produit parent",
      "EVENT_DATA_PARTNER_NAME":"Nom du partenaire",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Alias de profil de paiement",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"Identifiant de profil de paiement",
      "EVENT_DATA_PAYMENT_TYPE":"Type de paiement",
      "EVENT_DATA_PHONE_NUMBER":"Numéro de téléphone",
      "EVENT_DATA_PLAN_COUNT":"Nombre de forfaits",
      "EVENT_DATA_PLAN_DESCRIPTION":"Description",
      "EVENT_DATA_PLAN_ID":"Identifiant de plan",
      "EVENT_DATA_PLAN_NAME":"Nom du plan",
      "EVENT_DATA_PLAN_PRODUCT_ID":"Identifiant de produit de plan",
      "EVENT_DATA_PLAN_TYPE":"Type de plan",
      "EVENT_DATA_PRICE":"Prix",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Identifiant d'abonnement de produit C3",
      "EVENT_DATA_PRODUCT_MAPPING":"Mappage des produits",
      "EVENT_DATA_PRODUCT_NAME":"Nom de produit",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Nouveau nom de produit",
      "EVENT_DATA_PRODUCT_PRICE":"Prix du produit",
      "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"ID d'abonnement au produit",
      "EVENT_DATA_PROMOTION_ID":"Identifiant promotionnel",
      "EVENT_DATA_PROVIDER":"Fournisseur",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"Identifiant d'entreprise fournisseur",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Nom du client fournisseur",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Nom de l'entité du fournisseur",
      "EVENT_DATA_PROVIDER_ID":"Identifiant de fournisseur",
      "EVENT_DATA_PROVIDER_NAME":"Nom du fournisseur",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Identifiant de profil de paiement du fournisseur",
      "EVENT_DATA_PROVIDER_PRICE":"Prix du fournisseur",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Identifiant d'abonnement Azure",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"Identifiant du revendeur fournisseur",
      "EVENT_DATA_PROVIDER_RESELLER_NAME":"Nom du revendeur du fournisseur",
      "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "EVENT_DATA_PSA_CUSTOMER_NAME":"Client PSA",
      "EVENT_DATA_PSA_LOG_ID":"Identifiant du lot",
      "EVENT_DATA_PSA_NAME":"Nom du PSA",
      "EVENT_DATA_PSA_PRODUCT_NAME":"Produit PSA",
      "EVENT_DATA_QUANTITY":"Quantité",
      "EVENT_DATA_RECORD_ID":"Identifiant d'enregistrement",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"RecordId de l'utilisateur",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"RecordId de l'utilisateur",
      "EVENT_DATA_RECORD_INT_ID":"Identifiant interne d'enregistrement",
      "EVENT_DATA_RECORD_NAME":"Nom de l'enregistrement",
      "EVENT_DATA_RELEASED_SEATS":"Sièges libérés",
      "EVENT_DATA_RENEWS_EXPIRES_ON":"Renouvelle/Expire le",
      "EVENT_DATA_RENEWS_ON":"Se renouvelle le",
      "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Statut de renouvellement automatique du produit C3",
      "EVENT_DATA_REQUESTED_QUANTITY":"Quantité demandée",
      "EVENT_DATA_REQUESTED_STATUS":"Statut demandé",
      "EVENT_DATA_RESELLER_NAME":"Nom du revendeur",
      "EVENT_DATA_RESELLER_NEW_NAME":"Nouveau nom du revendeur",
      "EVENT_DATA_RESELLER_OLD_NAME":"Ancien nom du revendeur",
      "EVENT_DATA_RESERVATION_ORDER_ID":"Numéro de commande de réservation",
      "EVENT_DATA_ROLE":"Rôle",
      "EVENT_DATA_SALE_PRICE":"Prix de vente",
      "EVENT_DATA_SEAT_LIMIT":"Limite de siège",
      "EVENT_DATA_SITE_NAME":"Nom du site",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Nom du département",
      "EVENT_DATA_SOURCE_CURRENCY":"Devise source",
      "EVENT_DATA_SOURCE_OFFER_ID":"Identifiant de l'offre source",
      "EVENT_DATA_SOURCE_PRODUCT_NAME":"Nom du produit source",
      "EVENT_DATA_SOURCE_QUANTITY":"Quantité d'origine",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Cycle de facturation source",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"ID d'abonnement source",
      "EVENT_DATA_SOURCE_VALIDITY":"Validité de la source",
      "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Type de validité de la source",
      "EVENT_DATA_START_BILLING_PERIOD_ID":"Debut de la facturation periodId",
      "EVENT_DATA_START_DATE":"Date de début",
      "EVENT_DATA_STATUS":"Statut",
      "EVENT_DATA_SUB_TAXES":"Sous taxes",
      "EVENT_DATA_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "EVENT_DATA_SUBSCRIPTIONID":"Identifiant de l'abonnement",
      "EVENT_DATA_SUPPORTED_MARKET_CODE":"Code de marché pris en charge",
      "EVENT_DATA_SUPPORTED_MARKETS":"Marchés pris en charge",
      "EVENT_DATA_SYNCED_PRODUCT":"Nombre de produits synchronisés",
      "EVENT_DATA_TARGET_CURRENCY":"Devise cible",
      "EVENT_DATA_TARGET_OFFER_ID":"Identifiant de l'offre cible",
      "EVENT_DATA_TARGET_PRODUCT_NAME":"Nom du produit cible",
      "EVENT_DATA_TARGET_QUANTITY":"Quantité cible",
      "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Cycle de facturation cible",
      "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"ID d'abonnement cible",
      "EVENT_DATA_TARGET_VALIDITY":"Validité cible",
      "EVENT_DATA_TARGET_VALIDITY_TYPE":"Type de validité cible",
      "EVENT_DATA_TAX_CODE":"Code fiscal",
      "EVENT_DATA_TAX_NAME":"Nom de taxe",
      "EVENT_DATA_TAX_PENCENTAGE":"Taxe PeNCEntage",
      "EVENT_DATA_TAX_PERCENTAGE":"Pourcentage de taxe",
      "EVENT_DATA_TENANT_ID":"Identifiant de domaine",
      "EVENT_DATA_TENANT_NAME":"Nom de domaine",
      "EVENT_DATA_TO_RECIPIENTS":"Aux destinataires",
      "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Nombre de produits concernés",
      "EVENT_DATA_TOTAL_RELEASED_SEATS":"Nombre de sièges concernés",
      "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Montant total de la transaction",
      "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"Limite du montant de la transaction",
      "EVENT_DATA_TRANSITION_TYPE":"Type de transition",
      "EVENT_DATA_UNSYNCED_PRODUCT":"Nombre de produits non synchronisés",
      "EVENT_DATA_UPDATED_BUNDLE_NAME":"Nom du bouquet mis à jour",
      "EVENT_DATA_UPDATED_COUPON_NAME":"Nom du coupon mis à jour",
      "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Nom de l'offre du distributeur mis à jour",
      "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Nom de l'offre Partenaire mis à jour",
      "EVENT_DATA_UPDATED_PLAN_NAME":"Nom du plan mis à jour",
      "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Modification de la cartographie des produits",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Emplacement d'utilisation",
      "EVENT_DATA_USER_DISPLAY_NAME":"Nom d'affichage",
      "EVENT_DATA_USER_EMAIL":"Courriel de l'utilisateur",
      "EVENT_DATA_USER_TYPE":"Type d'utilisateur",
      "EVENT_DATA_USERNAME":"Nom d'utilisateur",
      "EVENT_DATA_VALIDATION_ERROR":"Erreur de validation",
      "EVENT_DATA_VALIDATION_STATUS":"Statut de validation",
      "EVENT_DATA_VALIDITY":"Valide",
      "EVENT_DATA_VALIDITY_TYPE":"Type de validité",
      "EVENT_DATA_VALUE":"Valeur",
      "EVENT_DATA_VOUCHER_ENTITY":"Entité du bon",
      "EVENT_DATA_VOUCHER_IDS":"ID de bon",
      "EVENT_DATA_VOUCHERS":"Pièces justificatives",
      "EVENT_DATE":"Date",
      "EVENT_DEPARTMENT_DESCRIPTION":"Description du département",
      "EVENT_DEPARTMENT_ID":"Identifiant de département",
      "EVENT_DEPARTMENT_NAME":"Nom du département",
      "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Accepter ou refuser le consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Termes et conditions NCE acceptés pour commander des produits Microsoft NCE",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Conditions générales acceptées",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Échec de l'acceptation des conditions générales",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Activer l'abonnement DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Activer l'abonnement DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Activer le seuil de danger atteint en cas d'échec de l'abonnement",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Activer l'offre personnalisée d'approvisionnement",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Échec d'activation de l'offre personnalisée d'approvisionnement",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Échec de l'activation de l'abonnement",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Réussite de l'activation de l'abonnement",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Impossible de créer le coupon",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"Échec de l'ajout de client C3",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Réussite de l'ajout de client C3",
      "EVENT_DESC_ADD_ENTITLEMENTS":"Ajouter un droit",
      "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Échec de l'ajout d'un droit",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Ajouter un administrateur de groupe",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Échec de l'ajout d'un administrateur de groupe",
      "EVENT_DESC_ADJUST_Azure_MARGIN":"Ajuster la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN":"Ajuster la marge Azure",
      "EVENT_DESC_ADJUST_Azure_MARGIN_FAILED":"Échec de l'ajustement de la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"Échec de l'ajustement de la marge Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Ajuster la marge Azure",
      "EVENT_DESC_APPROVED_BY":"Approuvé par",
      "EVENT_DESC_APPROVED_ON":"Approuvé le",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Modifications du prochain renouvellement du calendrier approuvées ou rejetées",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Modifications du prochain renouvellement du calendrier approuvées ou rejetées",
      "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Demande d'achat approuvée ou rejetée",
      "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Demande d'achat approuvée",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Attribuer un plan au client",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"Échec de l'attribution du plan au client",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Échec de l'attribution des bons manqués",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Coupons manqués attribués avec succès",
      "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin obtient automatiquement l'accès aux clients",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Abonnements de produits clients traités automatiquement",
      "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Libération automatique des sièges inutilisés",
      "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Non-concordances corrigées grâce à la synchronisation automatique",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Cycle de facturation modifié avec succès (Synchronisation Automatique)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"La modification du cycle de facturation a échoué (Auto Sync)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Cycle de facturation modifié avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Échec de la modification du cycle de facturation (Synchronisation Manuelle)",
      "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Résumé des lots intégrés en masse",
      "EVENT_DESC_BULK_USER_UPLOAD":"Chargement massif d'utilisateurs",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Échec du chargement massif d'utilisateurs",
      "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Les offres bouquet sont achetées",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Bon de commande de produits groupés",
      "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Annulez le succès du renouvellement planifié.",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Demande d'achat d'abonnement annulé ",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Échec de la demande d'annulation d'achat d'abonnement",
      "EVENT_DESC_CART_AVAILABILITY_STATUS":"Statut de disponibilité du panier",
      "EVENT_DESC_CART_CHECKOUT":"Passage à la caisse",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Échec du passage à la caisse",
      "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Notifier que la limite de places a été dépassée",
      "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Notifier que la limite de transaction a été dépassée",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Statut de Renouvellement Automatique du produit client modifié",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"Le statut de Renouvellement Automatique du produit client n'a pas pu être modifié",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Statut du produit client mis à jour avec succès",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Échec de la modification du statut du produit",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"Status ChangeInvoice",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Échec de la modification du statut de la facture",
      "EVENT_DESC_CHANGE_PLAN_FAILURE":"Échec du plan de modification",
      "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Réussite du plan de changement",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Échec de la conversion de devise clone",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Succès de la conversion de devise de clonage",
      "EVENT_DESC_CLONE_PLAN":"Plan dupliqué avec succès",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"Impossible de cloner le plan",
      "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"Le coupon s'applique à tous les clients marqués ?",
      "EVENT_DESC_COUPON_NAME":"Nom du coupon",
      "EVENT_DESC_COUPON_STATUS":"Statut du coupon",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Créer des groupes Azure",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Échec de la création des groupes Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Créer un abonnement Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Échec de la création de l'abonnement Azure",
      "EVENT_DESC_CREATE_CART":"Créer un panier",
      "EVENT_DESC_CREATE_CART_FAILURE":"Échec de la création du panier",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Échec de la création d'un client",
      "EVENT_DESC_CREATE_INVOICE":"Créer une facture",
      "EVENT_DESC_CREATE_ORDER":"Créer une commande",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Échec de la création d'une commande",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Créer un profil de paiement",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Échec de la création du profil de paiement",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"Échec de la création d'un abonnement aux instances réservées par synchronisation",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Abonnement aux instances réservées créé avec succès par synchronisation",
      "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Créer un succès de renouvellement planifié",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Créer un contrôle d'expiration d'abonnement",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Échec de l'expiration de la création de l'abonnement",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Abonnement créé avec succès (Auto Sync)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Échec de la création de l'abonnement (Synchronisation Automatique)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Ignoré la création d'un abonnement à partir de la Synchronisation Automatique",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Abonnement créé avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"La création de l'abonnement a échoué (Synchronisation Manuelle)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Ignoré la création de l'abonnement à partir de la Synchronisation Manuelle",
      "EVENT_DESC_CREATE_TAX_RULE":"Créer une règle fiscale",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Échec de la création d'une règle fiscale",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Créer un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Échec de la création d'un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_CREATE_USER":"Créer un utilisateur",
      "EVENT_DESC_CREATE_USER_FAILURE":"Échec de la création d'un utilisateur",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Échec du consentement au renouvellement de l'offre du partenaire",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Création de clients via API",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Création de client via un échec d'API",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Échec de la suppression du client",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Succès de la suppression du client",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Facture client générée",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du client",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Mise à jour du nom du client réussie",
      "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"La limite de sièges client dépasse la notification",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"Désactiver le journal PSA",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"Échec de la désactivation du journal PSA",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Définir la conversion de devises",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Échec de la définition de conversion de devises",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Définir les prix du plan dans une nouvelle devise",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Échec de la définition des prix du plan dans une nouvelle devise",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Dissocier le client de facturation",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Échec de dissociation du client de facturation",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Supprimer le succès du gestionnaire de compte",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Supprimer l'échec du gestionnaire de compte",
      "EVENT_DESC_DELETE_CART_ITEM":"Article de panier supprimé",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Échec de la suppression de l'article de panier",
      "EVENT_DESC_DELETE_CONTRACT_OFFER":"Offre de contrat supprimée avec succès",
      "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Échec de la suppression de l'offre de contrat",
      "EVENT_DESC_DELETE_COUPON":"Coupon désactivé avec succès",
      "EVENT_DESC_DELETE_COUPON_FAILED":"La suppression du coupon a échoué.",
      "EVENT_DESC_DELETE_COUPON_FAILURE":"Échec de la désactivation du coupon",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Supprimer la conversion de devises",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Échec de la suppression de conversion de devises",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Impossible de supprimer la conversion de devises",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Supprimer l'échec de la notification personnalisée",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Supprimer le succès de la notification personnalisée",
      "EVENT_DESC_DELETE_CUSTOM_OFFER":"Offre personnalisée supprimée avec succès",
      "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Échec de la suppression de l'offre personnalisée",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Supprimer le paiement du client",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Impossible de supprimer le paiement client",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Échec de la suppression du département",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Suppression du département réussie",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Offre de distributeur supprimée avec succès",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Échec de la suppression de l'offre du distributeur",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Supprimer la notification par courriel de l'événement",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la suppression de la notification par courriel de l'événement",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Supprimer l'ajustement de la facture",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Échec de la suppression de l'ajustement de la facture",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Supprimer un poste de factures",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Échec de la suppression de poste de factures",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Échec de la suppression de l'utilisateur partenaire",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Supprimer une option de paiement",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Échec de la suppression de l'option de paiement",
      "EVENT_DESC_DELETE_PLAN":"Supprimer un plan",
      "EVENT_DESC_DELETE_PLAN_FAILURE":"Échec de la suppression du plan",
      "EVENT_DESC_DELETE_QUOTE_FAILED":"La suppression de la citation a échoué",
      "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Supprimer la citation avec succès",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Échec de la suppression du site",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Site supprimé avec succès",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Supprimer la vérification de l'expiration de l'abonnement",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Échec de la suppression de l'expiration de l'abonnement",
      "EVENT_DESC_DELETE_TAX_RULE":"Supprimer une règle fiscale",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Échec de la suppression d'une règle fiscale",
      "EVENT_DESC_DELETE_UPGRADE_AzureSUBSCRIPTION_FAILURE":"Échec de la mise à niveau de l'abonnement Azure",
      "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Échec de la mise à niveau de l'abonnement Azure",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Supprimer un utilisateur d'abonnement d'utilisation",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Échec de la suppression de l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_DELETE_USER":"Supprimer un utilisateur",
      "EVENT_DESC_DELETE_USER_FAILURE":"Échec de la suppression de l'utilisateur",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Échec de la notification de suppression du webhook",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Supprimer le succès de la notification du webhook",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Désactiver un abonnement Azure",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Échec de la désactivation de l'attribution du coupon",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de coupon désactivée",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Désactiver le plan pour le client",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"Échec de la désactivation du plan pour le client",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Rapport sur les éléments de ligne de facture planifiés",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Éléments de ligne de facture planifiés avec rapport de commission",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Rapport récapitulatif des factures programmées",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Rapport de rentabilité programmé",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Rapport sur les éléments de campagne de taxe planifiés",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Rapport récapitulatif des taxes planifié",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Activer l'abonnement Azure",
      "EVENT_DESC_ENABLE_COUPON":"Coupon activé avec succès",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Échec de l'activation de l'attribution du coupon",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Attribution de coupon activée",
      "EVENT_DESC_ENABLE_COUPON_FAILED":"L'activation du coupon a échoué.",
      "EVENT_DESC_ENABLE_COUPON_FAILURE":"Échec de l'activation du coupon",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Affecter un plan au client",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Impossible d'affecter le plan au client",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Activer la notification pour l'expiration de l'abonnement annuel",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Activer la notification lors du renouvellement d'un abonnement mensuel",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Échec de mise en file d'attente de la demande d'intégration massive des clients",
      "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Demande en file d'attente pour cloner le plan revendeur",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Rapport de notification du journal des erreurs",
      "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Échec du renouvellement programmé de la mise à jour",
      "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Exporter le rapport de licence client.",
      "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Exporter le rapport d'attribution de licence utilisateur",
      "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"Échec de l'approbation ou du rejet de la prochaine demande de renouvellement programmée",
      "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Échec de l'annulation du renouvellement programmé",
      "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"Échec du clonage du forfait revendeur",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"Échec de la connexion au logiciel de comptabilité",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"Échec de la connexion au Centre des partenaires Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"Échec de la connexion à l'API de tarification Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"Échec de la connexion à PSA",
      "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"Échec de la création de la facture à la demande",
      "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Échec de la création du renouvellement programmé",
      "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"Échec de la mise en file d'attente de la demande de clonage du forfait revendeur",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Impossible de définir le statut du lot d'embarquement massif à Terminé",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Impossible de définir le statut du lot d'affectation de la licence utilisateur pour terminer",
      "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"Échec de la synchronisation du profil client et fournisseur",
      "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"Échec de la mise à jour de la facture à la demande",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Correction de l'incompatibilité d'état de renouvellement automatique automatiquement",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Correction de l'inadéquation de l'état de renouvellement automatique a automatiquement échoué",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Correction manuelle de l'incompatibilité d'état de renouvellement automatique",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Correction de l'incompatibilité d'état de renouvellement automatique a échoué manuellement",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Correction de l'incompatibilité de promotion avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'incohérence de la promotion (Synchronisation Automatique)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Corriger manuellement l'incohérence des promotions",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Échec de la correction manuelle de l'incompatibilité de promotion",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Erreur de quantité corrigée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'inadéquation de la quantité (Synchronisation Automatique)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Erreur de quantité corrigée avec succès (Synchronisation manuelle)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Échec de la correction de l'inadéquation de la quantité (Synchronisation Manuelle)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Correction de l'incompatibilité d'état avec succès (Synchronisation Automatique)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Échec de la correction de l'incompatibilité d'état (Synchronisation Automatique)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Correction de l'incompatibilité de statut avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"Échec de la correction de l'incompatibilité d'état (Synchronisation Manuelle)",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Écart de consommation de licence",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Échec de l'autorisation de l'accès au portail",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Autoriser l'accès au portail",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Ignorer les erreurs de chargement en masse des utilisateurs",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Impossible d'ignorer les erreurs de chargement en masse des utilisateurs",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Ignorer les erreurs d'achat d'abonnements",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Ignorer les erreurs de mise à jour d'abonnements",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Impossible d'ignorer les erreurs de mise à jour d'abonnement",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Impossible d'ignorer l'erreur dans l'achat d'abonnement",
      "EVENT_DESC_INVOICE_AND_REPORT":"Facture et rapport",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Paiement de facture initié",
      "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Facture et rapport de cotisation",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Échec de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Échec de l'association du client fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Échec de l'association du client non-csp fournisseur de services",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Réussite de l'association du client non-csp fournisseur de services",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Réussite de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Échec de l'association du client fournisseur de facturation",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Échec de l'association du client fournisseur de services",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Réussite de l'association du client fournisseur de services",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"La gestion des licences utilisateurs a échoué.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"La gestion des licences utilisateurs a réussi.",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Planifier les prochaines modifications de renouvellement",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"Échec de la planification des prochaines modifications de renouvellement",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Demande d'achat de produit de partenaire NI acceptée",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Demande d'achat de produit de partenaire NI annulée",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"Produit de partenaire Ni acheté",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"Quantité de produit de partenaire NI mise à jour",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Statut d'activation de la notification d'achat de produits de provisionnement NI",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Statut de désactivation de la notification d'achat de produits de provisionnement NI",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Offre modifiée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Le changement d'offre a échoué (Auto Sync)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Offre modifiée avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Le changement d'offre a échoué (Synchronisation Manuelle)",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Les offres sont achetées",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Plan Azure intégré avec succès (Auto Sync)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_AUTO_SYNC":"Plan Azure intégré avec succès (Synchronisation Automatique)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_AUTO_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation automatique)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation automatique)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_MANUAL_SYNC":"Plan Azure intégré avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Plan Azure intégré avec succès (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_Azure_PLAN_FROM_MANUAL_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Échec de l'intégration du plan Azure (synchronisation manuelle)",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Échec de l'intégration du client",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Échec de l'intégration du client",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Intégration du client effectuée avec succès",
      "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Le partenaire propose un rapport exportable",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Échec de l'ajout de l'utilisateur partenaire",
      "EVENT_DESC_PARTNER_USERADDED":"Utilisateur partenaire ajouté",
      "EVENT_DESC_PASSWORD_EXPIRED":"Mot de passe expiré",
      "EVENT_DESC_PAYMENT_FAILURE":"Défaut de paiement",
      "EVENT_DESC_PAYMENT_SUCCESS":"Paiement effectué avec succès",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Échec de la saisie du consentement de sécurité du centre partenaire",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Consentement de sécurité du centre partenaire saisi avec succès",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip créé avec succès pour toutes les factures.",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Une erreur s'est produite lors de la création de Zip pour toutes les factures.",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Affectation de produit",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Échec de l'affectation de produit",
      "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Promotions chargées avec succès",
      "EVENT_DESC_PROMOTION_LOADING_FAILED":"Échec du chargement des promotions",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Prix de produits de fournisseur mis à jour",
      "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Demande d'aide à l'inscription publique",
      "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"L'achat du bouquet a échoué",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Acheter une nouvelle offre partenaire",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Échec de l'achat d'une nouvelle offre personnalisée",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Acheter un produit NI Provisioning",
      "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Demande d'achat en attente d'approbation",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Acheter des abonnements",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Échec de l'achat des abonnements",
      "EVENT_DESC_QUOTATION_CUSTOMER":"Devis client",
      "EVENT_DESC_QUOTATION_NAME":"Nom de la citation",
      "EVENT_DESC_QUOTATION_OWNER":"Propriétaire de devis",
      "EVENT_DESC_QUOTE_APPROVAL":"Approbation du devis",
      "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Conversion de devis réussie",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Demande de mise à jour du produit acheté",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"Échec de la demande de mise à jour du produit acheté",
      "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Non-concordance de reconnaissance lors de la génération de facture initiale",
      "EVENT_DESC_RECORD_PAYMENT":"Enregistrer le paiement",
      "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Enregistrer l'échec du paiement",
      "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Demande d'achat rejetée",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Conditions générales rejetées",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Échec du rejet des conditions générales",
      "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du revendeur",
      "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Succès de la mise à jour du nom du revendeur",
      "EVENT_DESC_RESET_USER_PASSWORD":"Réinitialiser le mot de passe utilisateur",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Échec de la réinitialisation du mot de passe utilisateur",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Rétablir le droit au prix réussi",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Échec du rétablissement du prix d'attribution",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Rétablir TenantConfiguration",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Échec du rétablissement de TenantConfiguration",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Révoquer l'accès au portail",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Échec de la révoquation de l'accès au portail",
      "EVENT_DESC_SAVE_COMMENT":"Nouveau commentaire ajouté",
      "EVENT_DESC_SAVE_CONTRACT_OFFER":"Offre de contrat sauvgardé avec succès",
      "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"La sauvegarde de l'offre de contrat a échoué",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Enregistrer l'échec de la notification personnalisée",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Enregistrer le succès des notifications personnalisées",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Enregistrer l'offre personnalisée",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Échec de l'enregistrement de l'offre personnalisée",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Impossible d'enregistrer le profil de facturation du client",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Enregistrer le profil de facturation du client",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Enregistrer le client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Échec de l'enregistrement du client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Échec de l'enregistrement du client dans la facturation",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Enregistrer le profil de paiement du client",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Échec de l'enregistrement du profil de paiement du client",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Enregistrer l'utilisateur client",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Échec de l'enregistrement de l'utilisateur client",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Échec de l'enregistrement du département",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Département enregistré avec succès",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Enregistrer l'offre du distributeur",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Enregistrer l'échec de l'offre du distributeur",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Enregistrer le droit Prix Succès",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Enregistrer le droit d'accès Échec du prix",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Notification par courriel de l'enregistrement de l'événement",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la notification par courriel de l'enregistrement de l'événement",
      "EVENT_DESC_SAVE_INVITES":"Enregistrer les invitations",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Échec de l'enregistrement des invitations",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Enregistrer les ajustements de facture",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Échec de l'enregistrement des ajustements de facture",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Enregistrer le forfait partenaire",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Échec de l'enregistrement du forfait de partenaire",
      "EVENT_DESC_SAVE_PARTNER_USER":"Enregistrer l'utilisateur partenaire",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Échec de l'enregistrement de l'utilisateur partenaire",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Enregistrer le profil de paiement",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Échec de l'enregistrement du profil de paiement",
      "EVENT_DESC_SAVE_PLAN":"Enregistrer le plan",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Échec de l'enregistrement du plan",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Enregistrer les produits du plan dans la devise cible",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Échec de l'enregistrement des produits du plan dans la devise cible",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Consentement du client fournisseur enregistré",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Échec de l'enregistrement du consentement du client fournisseur",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Enregistrer le paiement du fournisseur",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Échec de l'enregistrement du paiement du fournisseur",
      "EVENT_DESC_SAVE_QUOTE_FAILED":"Impossible d'enregistrer le devis",
      "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Citation sauvegardée avec succès",
      "EVENT_DESC_SAVE_RESELLER":"Enregistrer le revendeur",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"Échec de l'enregistrement du revendeur",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Échec de l'enregistrement du site",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Site enregistré avec succès",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Échec de l'enregistrement de la configuration utilisateur",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Enregistrer le succès de la configuration utilisateur",
      "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Sauvegarder la notification de message Web",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Échec de la notification du webhook d’enregistrement",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Enregistrer le succès de la notification du webhook",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Acceptation des conditions générales échouée",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Conditions générales acceptées avec succès",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"La tentative de mise en place des licences à ajouter/supprimer a échoué.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Mise en place des licences à ajouter/supprimer effectuée avec succès.",
      "EVENT_DESC_SCHEDULE_ORDER":"Planifier la commande",
      "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Échec de la commande planifiée",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Rapport sur les éléments de ligne de facture planifiés",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Éléments de ligne de facture planifiés avec rapport de commission",
      "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Rapport récapitulatif des factures programmées",
      "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Rapport de rentabilité programmé",
      "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Rapport sur les éléments de campagne de taxe planifiés",
      "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Rapport récapitulatif des taxes planifié",
      "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Envoyer la mise à jour de l'offre du distributeur",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Envoyer la facture PDF au client depuis l'interface utilisateur",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Envoyer la facture PDF à la transparence des clients",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Envoyer des alertes de seuil d'utilisation de l'abonnement",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Alertes de seuil d’utilisation d’abonnement ayant échoué",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Définir le statut du lot d'affectation de la licence utilisateur pour terminer",
      "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"La connectivité SMTP a échoué",
      "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notification d'expiration d'abonnement pour l'offre d'essai",
      "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Rapport sur l'historique des abonnements",
      "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Suspendre lorsque le seuil de danger est atteint",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Synchronisation de l'abonnement",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Échec de synchronisation de l'abonnement",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonnement mis à jour",
      "EVENT_DESC_SUCCESS":"Réussite ",
      "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"La prochaine demande de renouvellement programmée a été approuvée ou rejetée avec succès",
      "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Plan revendeur cloné avec succès",
      "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"Facture à la demande créée avec succès",
      "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Profil client et fournisseur synchronisé avec succès",
      "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"Facture à la demande mise à jour avec succès",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Échec de la suspension de l'abonnement",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Suspendre le succès de l'abonnement",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Suspendre lorsque le seuil de danger est atteint",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Suspendre lorsque le seuil de danger atteint l'échec",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"impossible de synchroniser le consentement du client fournisseur",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Consentement client fournisseur synchronisé",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Échec de la synchronisation des abonnements sur PC",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Synchronisation des abonnements sur PC",
      "EVENT_DESC_TAGS_ADDED":"Balises ajoutées",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Échec de l'ajout de balises",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Échec de la suppression de balises",
      "EVENT_DESC_TAGS_DELETED":"Balises supprimées",
      "EVENT_DESC_TAGS_EDITED":"Balises modifiées",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Échec de la modification de balises",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"La durée du terme a été modifiée avec succès (Synchronisation Automatique)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Échec de la modification de la durée du trimestre (Synchronisation Automatique)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"La durée du mandat a été modifiée avec succès (Synchronisation Manuelle)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Échec de la modification de la durée du trimestre (Synchronisation Manuelle)",
      "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Détails du produit non synchronisés",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Mettre à jour les groupes Azure",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Échec de la mise à jour des groupes Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Mettre à jour l'abonnement Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Échec de la mise à jour de l'abonnement Azure",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Offre de contrat mise à jour avec succès",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"La mise à jour de l'offre de contrat a échoué",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Mettre à jour le cycle de facturation personnalisé",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"Échec de la mise à jour du cycle de facturation personnalisé",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Échec de la mise à jour de la notification personnalisée",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Mettre à jour le succès de la notification personnalisée",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Mettre à jour la quantité d'offres partenaires",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Échec de la mise à jour de quantité d'offres partenaires",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Option de paiement par défaut mise à jour",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Impossible de mettre à jour l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Mettre à jour du profil de paiement du client",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Échec de la mise à jour du profil de paiement du client",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Profil client mis à jour",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Échec de la mise à jour du profil client",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Mettre à jour l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Échec de la mise à jour de l'option de paiement par défaut",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Mettre à jour la notification par courriel de l'événement",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Échec de la mise à jour de la notification par courriel de l'événement",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Mettre à jour l'administrateur du groupe",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Échec de la mise à jour de l'administrateur du groupe",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":"Échec de la mise à jour des propriétés de la facture",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Les propriétés de la facture ont été mises à jour avec succès",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Mettre à jour l'indicateur de publication automatique",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"Échec de la mise à jour de l'indicateur de libération automatique",
      "EVENT_DESC_UPDATE_LICENSE":"Mettre à jour la licence",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Échec de la mise à jour de la licence",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Échec de la mise à jour des paramètres du centre partenaire",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Mettre à jour les paramètres généraux du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres généraux du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"Mettre à jour le logo du partenaire",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Échec de la mise à jour du logo du partenaire",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Mettre à jour les paramètres du centre partenaire",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Mettre à jour le prix de l'offre de plan",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Échec de la mise à jour du prix de l'offre de plan",
      "EVENT_DESC_UPDATE_PROFILE":"Mettre à jour le profil",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Échec de la mise à jour du profil",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Mettre à jour les paramètres du fournisseur",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres du fournisseur",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Attributs de produit mis à jour",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Impossible de mettre à jour les attributs du produit",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Mettre à jour la quantité de produits de provisionnement NI",
      "EVENT_DESC_UPDATE_QUOTE_FAILED":"Impossible de mettre à jour le devis",
      "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Citation mise à jour avec succès",
      "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Mettre à jour le succès du renouvellement planifié",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valeur par défaut du client fournisseur de services mise à jour",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Échec de la mise à jour de la valeur par défaut du client fournisseur de services",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Mettre à jour les paramètres SMTP",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Échec de la mise à jour des paramètres SMTP",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Mise à jour de la vérification de l'expiration de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"La vérification de l'expiration de l'abonnement a échoué",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Échec de la mise à jour de l'abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":"Abonnement à la mise à jour de l'API",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Échec de l'abonnement à la mise à jour de l'API",
      "EVENT_DESC_UPDATE_SUBTAX":"Mettre à jour la sous-taxe",
      "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Échec de la mise à jour de la sous-taxe",
      "EVENT_DESC_UPDATE_TAX_RULE":"Mettre à jour la règle fiscale",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Échec de la mise à jour de la règle fiscale",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Mettre à jour la configuration du domaine",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Échec de la mise à jour de la configuration du domaine",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Mettre à jour l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Échec de la mise à jour de l'utilisateur de l'abonnement d'utilisation",
      "EVENT_DESC_UPDATE_USER":"Mettre à jour l'utilisateur",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Échec de la mise à jour de l'utilisateur",
      "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Mettre à jour la notification WebMessage",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Échec de la notification du webhook de mise à jour",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Mise à jour réussie de la notification du webhook",
      "EVENT_DESC_UPGRADE_AzureSUBSCRIPTION":"Mettre à niveau l'abonnement Azure",
      "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Mettre à niveau l'abonnement Azure",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"Échec de l'attribution du bon",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Bon attribué avec succès",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Échec du mappage de produits en masse",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"Cartographie des produits en masse réussie",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Le processus de rafraîchissement des entités de services externes dans C3 est lancé",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" Le processus d'actualisation pour les entités de service externes dans C3 a échoué",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" Le processus d'actualisation pour les entités de service externes dans C3 a réussi",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Mise à niveau de l'abonnement",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Échec de la mise à niveau de l'abonnement",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"Échec du téléchargement du catalogue client.",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Catalogue client téléchargé avec succès.",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Télécharger le catalogue de produits",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Échec du téléchargement du catalogue de produits",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Télécharger le catalogue des commissions sur les produits",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Télécharger le catalogue des commissions sur les produits",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"Échec du téléchargement du catalogue d'utilisation des rapports",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Télécharger le catalogue d'utilisation des rapports avec succès",
      "EVENT_ENTITLEMENT_ID":"ID d'autorisation",
      "EVENT_ENTITLEMENT_NAME":"Nom de l'entité",
      "EVENT_FILE_NAME":"Nom de fichier",
      "EVENT_IMPERSONATOR":"Personnificateur",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Échec de l’importation de plusieurs paiements",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Succès de l’importation de plusieurs paiements",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Échec de l'importation des données validées",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Données validées importées avec succès",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Échec des commissions sur les produits d'importation",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Réussite des commissions sur les produits d'importation",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Échec de l'importation des extensions de produit",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Réussite de l'importation des extensions de produit",
      "EVENT_IMPORT_VOUCHER_FAILURE":"Échec de l'importation des bons.",
      "EVENT_IMPORT_VOUCHER_SUCCESS":" Les bons ont été importés avec succès.",
      "EVENT_IS_LICENSE_ASSIGNED":"La licence est attribuée",
      "EVENT_IS_LICENSE_REMOVED":"La licence est supprimée",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Lier un revendeur",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Impossible de lier le revendeur",
      "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"Échec du mappage d'entités PSA",
      "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"Mappage réussi de l'entité C3 vers l'entité dans PSA",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"La suppression du mappage avec l'entité PSA a échoué",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Suppression réussie du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"Échec du mappage du produit PSA",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Mappage réussi des produits C3 aux produits dans PSA",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"Échec de la suppression du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Suppression réussie du mappage avec l'entité PSA",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Suppression réussie du mappage avec le produit PSA",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"La suppression du mappage avec le produit PSA a échoué",
      "EVENT_NEW_CAN_PRICE_LAG":"NewCanPriceLag",
      "EVENT_NEW_CAN_PRICE_LEAD":"NouveauCanPriceLead",
      "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Nouvelle limite de sièges au niveau du plan",
      "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Notificateur d'erreur d'enque à la prochaine exécution",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Veuillez sélectionner au moins un type de bénéficiaire.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Aperçu d'email",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"Notification par email supprimée avec succès.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"CCI",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Activé",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Type de destinataire",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Destinataires",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"À",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"Paramètres de notification par email sauvegardés avec succès.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Veuillez cliquer ici",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"pour voir le contenu du modèle d'email.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"Destinataires CCI",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"Destinataires CC",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Événement",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Nom de l'événement",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Activé ?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"Aux destinataires",
      "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Notifier la création du client via l'inscription au client",
      "EVENT_Notify_Purchase_Limit_Error":"Notifier une erreur de limite d'achat",
      "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Notifier une erreur de limite d'achat",
      "EVENT_OLD_CAN_PRICE_LAG":"AncienCanPriceLag",
      "EVENT_OLD_CAN_PRICE_LEAD":"OldCanPriceLead",
      "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Limite de sièges au niveau de l'ancien plan",
      "EVENT_PRODUCT_NAME":"Nom du produit",
      "EVENT_PURCHASE_APPROVE_REASON":"Raison",
      "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Raison, si échec",
      "EVENT_PURCHASE_PRODUCT_ID":"Id Produit",
      "EVENT_PURCHASE_PRODUCT_NAME":"Nom de produit",
      "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Planifier l'identifiant du produit",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"Quantité",
      "EVENT_PURCHASE_REASON_ONHOLD":"Raison de la mise en attente",
      "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Envoyer les factures PDF au partenaire",
      "EVENT_SITE_DESCRIPTION":"Description du site",
      "EVENT_SITE_ID":"Identifiant du site",
      "EVENT_SITE_NAME":"Nom du site",
      "EVENT_SMTP_TEST_EMAIL":"E-mail de test SMTP",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Organiser et valider l'échec des paiements à l'importation",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Organiser et valider le succès des paiements à l'importation",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Échec de la préparation et de la validation du rapport d'utilisation du Partenaire",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Organisez et valider le rapport d'utilisation des Partenaires",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Organisez et valider l'échec du catalogue de produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Organisez et valider le succès du catalogue de produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Organisez et valider la validation des commissions sur les produits",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Organisez et validez le succès des commissions sur les produits",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"Échec de la préparation et de la validation du catalogue de bons.",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Catalogue de bons d’achat mis en scène et validé avec succès.",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Notification d'expiration de l'abonnement",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Notification d'expiration d'abonnement pour le client",
      "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notification d'expiration d'abonnement pour l'offre d'essai",
      "EVENT_TOTAL_RECORD_COUNT":"Nombre d'enregistrements",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Dissocier le revendeur",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Impossible de dissocier le revendeur",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Mise à jour des détails du produit d'utilisation du Partenaire",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Échec de la mise à jour des détails du produit d'utilisation du Partenaire",
      "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Notification d'offre de plan de mise à jour pour le revendeur",
      "EVENT_USER_CONFIGURATION_NAME":"Nom de la configuration utilisateur",
      "EVENT_USER_CONFIGURATION_VALUE":"Valeur de configuration utilisateur",
      "EVENT_USER_EMAILID ":"ID de messagerie de l'utilisateur",
      "EVENT_VOUCHER_ASSIGNMENT":"Attribution de bons",
      "EVENT_WEBJOB_STATUS_NOTIFIER":"Notificateur d'état de tâche Web",
      "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Nouvelle description de bouquet",
      "EVNET_DATA_NEW_BUNDLE_NAME":"Nouveau nom de bouquet",
      "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Ancienne description du lot",
      "EVNET_DATA_OLD_BUNDLE_NAME":"Ancien nom du bouquet",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Demande de revendeurs intégrés en vrac acceptée",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"L'acceptation de la demande groupée des revendeurs intégrés a échoué",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"L'intégration du revendeur dans le cadre de l'intégration groupée a échoué",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"L'intégration du revendeur dans le cadre de l'intégration groupée s'est terminée avec succès",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"L'intégration groupée des revendeurs a échoué",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Intégration groupée des revendeurs réussie",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Ajout en vrac de droits terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Échec de l'ajout en vrac de droits",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Demande d'ajout en vrac de droits acceptée",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"Échec de la demande d'ajout de droits en vrac",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Processus d'ajout en vrac de locataires terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"Échec du processus d'ajout de locataires en vrac",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Demande d'ajout en vrac de locataires acceptée",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"Échec de la demande d'ajout en vrac de locataires",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Processus d'intégration groupée des revendeurs terminé",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Échec du processus d'intégration groupée des revendeurs",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Processus d'intégration groupée des revendeurs lancé",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Suppression de la relation revendeur terminée",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Échec de la suppression de la relation revendeur",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Échec de la demande de suppression de la relation avec le revendeur",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Supprimer la demande de locataire de la relation revendeur acceptée",
      "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Une erreur s'est produite lors de la nouvelle tentative d'envoi d'une notification webhook",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement ou de la mise à jour des détails de la notification personnalisée",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Erreur lors de l'enregistrement des détails de la notification du webhook",
      "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Une erreur s'est produite lors de la tentative de création d'un panier pour l'offre principale à partir de l'offre d'essai.",
      "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Erreur lors de la mise à jour des données intermédiaires pour les détails de la notification",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Une erreur s'est produite lors de l'envoi de la notification du webhook",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"L'URL du webhook fournie n'est pas valide",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Il n'y a pas de produits mappés pour la modification du cycle de facturation. Veuillez contacter le soutien technique.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Une exception s'est produite lors de la création d'un nouveau client fournisseur puisque l'acceptation du Contrat de client fournisseur n'est pas confirmée par le partenaire",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"L'acceptation de l'accord client du fournisseur doit être confirmée",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Une erreur s'est produite lors de la vérification de l'acceptation des conditions générales du partenaire par l'utilisateur",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Une erreur s'est produite lors de la récupération du contenu des conditions générales du partenaire",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Une erreur s'est produite lors de la récupération des détails d'acceptation du consentement du client fournisseur",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Une erreur s'est produite lors de la récupération des clients fournisseurs qui n'ont pas fourni le consentement du client fournisseur",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Une erreur s'est produite lors de l'enregistrement des détails d'acceptation des conditions générales du partenaire",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Une erreur s'est produite lors de l'enregistrement du consentement du client fournisseur",
      "EXISTANT":"Existant",
      "EXISTING":"Existant",
      "EXISTING_REFRESH_TOKEN":"Jeton d'actualisation existant",
      "EXPAND_ALL_PAYMENTS":"Développer les paiements",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir supprimer cet enregistrement?",
      "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"Le chèque d'expiration a été supprimé avec succès",
      "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Les chèques d'expiration ont été enregistrés avec succès",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Fermer les filtres",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Nom du client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Sélectionnez Client ou Revendeur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Recherche par client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Sélectionner un client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Sélectionnez un statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Réinitialiser",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Rechercher",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Rechercher par statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Abandonné",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Désactivé",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Erreur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Échoué",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"En cours",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"En file d'attente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Arrêté",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Réussite ",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Avertissement",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Cycle de facturation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Date créée",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Date de fin",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Détails de l'erreur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Erreur d'exportation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Statut d'exportation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Nom du client",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"LogMessage",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Nom de l'opération",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Prix",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Prix du fournisseur",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Quantité",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Date de début",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Statut",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Nombre d'états",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Action",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Date de l'historique des abonnements",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Statut de validation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Erreur de validation",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"département",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Site",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Détail",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Résumé",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Échoué",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Réussite ",
      "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Lancement de l'actualisation des données locales à partir d'un service externe",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Supprimé",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_ACTIVE_OFFER_IN_C3_CATALOGUE":"Impossible de trouver l'offre dans C3",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"Impossible de trouver les détails du contrat localement pour créer un mappage.",
      "EXTERNAL_SERVICE_IN_ACTIVE":"Inactif",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Statut de journal de publication de service externe",
      "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abandon du téléchargement en raison d'une erreur lors de la création d'entités",
      "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"L'ajout est actif \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"L'identifiant de l'accord est manquant",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"La facturation n'est pas activée pour ce contrat dans \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"L'accord mappé n'est pas actif dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"L'accord n'est pas présent dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Les accords ne sont pas mappés pour ce client",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Les accords ne sont pas présents dans \"ConnectWise Manage\" sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Des ajouts ambigus sont présents, la cartographie n'est pas possible.",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"L'identifiant du contrat est manquant",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"La facturation n'est pas activée pour ce contrat dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Le contrat mappé n'est pas actif dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Le contrat n'est pas présent dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Le contrat n'est pas présent dans le service externe sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Le service contractuel n'est pas mappé sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Le service de contrat mappé n'est pas présent dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Les services contractuels ne sont pas mappés sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Les contrats ne sont pas mappés pour ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Les contrats ne sont pas présents dans Autotask sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Les contrats ne sont pas présents dans le service externe sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Le client n'est pas actif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Le client externe n'est pas mappé avec le client c3",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Le client n'est pas présent dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Le mappage de l'entité ou du produit est manquant.",
      "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":"Une tentative d'exportation a été tentée précédemment. Le statut de cette opération est",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Le contrat n'est pas actif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"L'identifiant client externe est manquant",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Les clients ne sont pas présents dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Une erreur s'est produite lors de la création d'un ajout dans ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":"Une erreur s'est produite lors de la création d'un ajustement :",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Une erreur s'est produite lors de la création de l'ajustement pour le changement de prix",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Une erreur s'est produite lors de la création du service",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Impossible de créer des frais de contrat pour un produit ponctuel",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":"Une erreur s'est produite lors de la création du service de contrat :",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Une erreur s'est produite lors de la mise à jour de l'ajout dans ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Impossible de créer un mappage car plusieurs services correspondants ont été trouvés dans Autotask",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Le contrat correspondant n'est pas disponible pour mapper automatiquement.",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Aucun client externe correspondant n'a pu être trouvé",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Aucun produit externe correspondant n'a pu être trouvé",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Le produit n'est pas mappé sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Le produit mappé n'est pas présent dans \"ConnectWise Manage\"",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Les produits ne sont pas mappés sous ce client",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"C3 Les produits n'ont pas de service externe mappé",
      "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"L'ID de service est manquant",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin de l'accord.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date d'annulation du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"La date de création de l'historique d'abonnement ne peut pas être postérieure à la date de fin du contrat.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation de l'accord",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de l'accord",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début de facturation du contrat",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"La date de création de l'historique d'abonnement ne peut pas être antérieure à la date de début du contrat",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"L'abonnement est en Autotask",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"L'abonnement est inactif dans le service externe",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Impossible de terminer le mappage automatique car plusieurs contrats correspondants ont été trouvés",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Impossible de terminer le mappage automatique car plusieurs clients externes ont été mis en correspondance",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Impossible de terminer le mappage automatique car plusieurs produits externes ont été mis en correspondance",
      "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"La validation échoue",
      "EXTERNAL_SERVICE_NAME":"Nom du service externe",
      "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Sélectionnez les clients à valider et à télécharger",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"Identifiant de lot de publication de service externe",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Retour à la liste",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Erreur ignorée avec succès",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Exporter",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validation",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Statut des journaux de publication de service externe",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Journaux de publication de service externe",
      "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Tester la connectivité",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Instructions",
      "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Veuillez cliquer sur ce bouton pour tester la connectivité à PSA une fois la configuration terminée.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Connectivité établie avec succès. Vous pouvez procéder au téléchargement des journaux d'abonnements maintenant.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Veuillez sélectionner au moins un client",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"Échec de la connexion à Datto Autotask. Veuillez revérifier vos configurations dans la page des paramètres",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"Échec de la connexion à ConnectWise Manage. Veuillez revérifier vos configurations dans la page des paramètres",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"La date de fin doit être supérieure à la date de début",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Veuillez sélectionner la date de fin",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"La date de début ne peut pas être supérieure à la date de fin",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Veuillez sélectionner la date de début",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Vos données sont en cours de téléchargement. Vous pouvez revoir cette page pour plus de mises à jour",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"La validation a commencé",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Remarque :",
      "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"La clé numérique est incorrecte ou l'accès à cette fonction est refusé. La validation de la clé numérique a échoué. Vérifiez que vous utilisez la bonne clé numérique",
      "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Il existe déjà un client avec la référence de téléchargement que vous avez fournie.",
      "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Remboursement non autorisé. Un remboursement a déjà été traité pour cette transaction.",
      "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Votre statut est xxxxx et il ne vous permet pas d'utiliser l'API.",
      "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"Le numéro de compte bancaire ne peut pas être vide",
      "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"Vous n'avez pas de produit BPay actif",
      "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"Vous ne pouvez pas modifier le statut d'un client qui est annulé.",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Vous devez fournir une valeur pour le paramètre 'ChangeFromDate' ou 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Vous devez fournir une valeur pour le paramètre 'ChangeFromDate' ou 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"Le client n'est PAS actif.",
      "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"L'année/le mois de la carte de crédit a déjà expiré.",
      "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"Impossible de trouver un client avec les détails fournis.",
      "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Vous devez fournir une valeur pour le paramètre 'DateField' lorsque les valeurs 'DateFrom' ou 'DateTo' sont fournies.",
      "EZIDEBIT_ERROR_CODE_DATE_FROM":"Vous devez fournir une valeur pour les paramètres 'DateFrom' ou 'DateTo' lorsque la valeur 'DateField' est fournie.",
      "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom ne peut pas être plus de xx mois avant \"DateTo\"",
      "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Vous devez fournir une valeur pour le paramètre 'DebitDate'",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"La clé numérique est incorrecte ou l'accès à cette fonction est refusé. La validation de la clé numérique a échoué. Vérifiez que vous utilisez la bonne clé numérique",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Jeton invalide. Le jeton client n'est pas valide pour cette clé numérique",
      "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Vous devez fournir une valeur pour le paramètre 'EziDebitCustomerID' ou le paramètre 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Cette fonction est actuellement indisponible car le traitement Ezidebit est en cours d'exécution. Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Client non actif (Statut :<Status Code>). Le statut du client n'est pas A (Actif) ou N (Nouveau)",
      "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Numéro de carte de crédit saisi invalide - Votre gamme de produits n'inclut pas xxxxxxx",
      "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"Le client ne dispose pas de fonds suffisants pour couvrir ce montant d'ajustement.",
      "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Longueur du numéro de compte invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Valeur non valide fournie pour le paramètre \"ApplyToAllFuturePayments\". Les valeurs valides sont : 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Format invalide fourni pour le paramètre 'BankAccountBSB'. Les valeurs valides ne doivent comporter que six chiffres",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Numéro de compte bancaire BSB non valide saisi",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Numéro de compte bancaire saisi incorrect",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Nom de compte bancaire saisi incorrect",
      "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"'BankReceiptId' n'a pas pu être déterminé",
      "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Numéro de carte de crédit saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Valeur non valide fournie pour le paramètre 'ChangeFromPaymentNumber'. Les valeurs valides doivent être numériques et supérieures ou égales à un.",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Valeur non valide fournie pour le paramètre 'ChangeFromDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Valeur non valide fournie pour le paramètre 'ChangeToDate'. Les valeurs valides sont n'importe quelle date au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Valeur non valide fournie pour le paramètre 'ContractStartDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Valeur non valide fournie pour le paramètre 'DateField'. Les valeurs valides sont : 'PAYMENT' ou 'SETTLEMENT'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Valeur non valide fournie pour le paramètre 'DateFrom'. Les valeurs valides sont n'importe quelle date au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Valeur non valide fournie pour le paramètre 'DateTo'. Les valeurs valides sont n'importe quelle date au format 'AAAAMM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Valeur non valide fournie pour le paramètre 'DayOfMonth'. Les valeurs valides doivent être comprises entre 1 et 31.",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Valeur non valide fournie pour le paramètre 'DayOfWeek'. Les valeurs valides sont : 'MON', 'TUE', 'WED', 'THU' ou 'FRI'.",
      "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Valeur non valide fournie pour le paramètre 'DebitDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Clé numérique non valide.",
      "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Données de prélèvement automatiques saisies incorrectes - Votre gamme de produits n'inclut pas les prélèvements automatiques.",
      "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Adresse e-mail saisie invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Mois d'expiration de carte de crédit saisi non valide",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"L'année d'expiration de la carte de crédit saisie n'est pas valide",
      "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Valeur non valide fournie pour le paramètre 'EzidebitCustomerID'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Valeur non valide fournie pour le paramètre 'SchedulePeriodType'. Les valeurs valides sont : '4', 'F', 'H', 'M', 'N', 'Q', 'W' ou 'Y'",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'FirstWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"Le prénom contient des caractères non standard qui ne sont pas autorisés",
      "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'FourthWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'EziDebitCustomerID' et 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"Le nom de famille contient des caractères non standard qui ne sont pas autorisés",
      "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"La longueur du paramètre 'YourPayerNumber' est supérieure à la longueur maximale.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Valeur non valide fournie pour le paramètre 'LimitToNumberOfPayments'. Les valeurs valides doivent être numériques et supérieures ou égales à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'LimitToTotalAmountInCents'. Les valeurs valides doivent être numériques et supérieures ou égales à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Valeur non valide fournie pour le paramètre 'KeepManualPayments'. Les valeurs valides sont : 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Nom invalide sur la carte de crédit saisie",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'NewPaymentAmountInCents'. Les valeurs valides doivent être supérieures ou égales à 200 (2 dollars).",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Valeur non valide fournie pour le paramètre 'NewStatus'. Les valeurs valides sont : 'A', 'H' ou 'C'",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Valeur non valide fournie pour le paramètre 'NewYourSystemReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Conflit de paramètre. Vous ne pouvez pas fournir un nombre supérieur à zéro pour 'LimitToNumberOfPayments' et 'LimitToTotalAmountInCents'",
      "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"Le montant du remboursement partiel ne peut pas être compris entre le montant du débit programmé et le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Valeur non valide fournie pour le paramètre 'PaymentAmountInCents'. Les valeurs valides doivent être supérieures ou égales à 200 (2 dollars).",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Valeur non valide fournie pour le paramètre 'PaymentMethod'. Les valeurs valides sont : 'ALL', 'DR' ou 'CR'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Référence de paiement saisie invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOUNCE":"Valeur non valide fournie pour le paramètre 'PaymentSouNCE'. Les valeurs valides sont : 'ALL', 'SCHEDULED', 'WEB', 'PHONE' ou 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Valeur non valide fournie pour le paramètre 'PaymentSource'. Les valeurs valides sont : 'ALL', 'SCHEDULED', 'WEB', 'PHONE' ou 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Valeur non valide fournie pour le paramètre 'PaymentType'. Les valeurs valides sont : 'ALL', 'PENDING', 'SUCCESSFUL' ou 'FAILED'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Numéro de téléphone mobile saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Code postal saisi invalide",
      "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"Le paiement avec la référence 'xxxxxxx' est introuvable.",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Valeur non valide fournie pour le paramètre \"RefundAmountInCents\". Les valeurs valides sont un montant de remboursement compris entre 100 et le montant de la transaction d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"L'instrument de paiement du remboursement ne correspond pas à l'instrument de paiement d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Valeur non valide fournie pour le paramètre \"RefundAmountInCents\". Les valeurs valides sont un montant de remboursement compris entre 100 et le montant de la transaction d'origine",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"Le montant du remboursement dépasse le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Valeur non valide fournie pour le paramètre 'ScheduleStartDate'. Les valeurs valides sont n'importe quelle date future au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'SecondWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Valeur invalide fournie pour le paramètre 'SmsExpiredCard'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Valeur non valide fournie pour le paramètre 'SmsFailedNotification'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Valeur invalide fournie pour le paramètre 'SmsPaymentReminder'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Valeur non valide fournie pour le paramètre 'ThirdWeekOfMonth'. Les valeurs valides sont : 'OUI' ou 'NON'.",
      "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Jeton invalide. Le jeton n'est pas numérique",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Numéro CCV de carte de crédit saisi non valide - Le numéro CCV doit comporter 3 ou 4 chiffres",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Valeur non valide fournie pour le paramètre 'CustomerName'. La valeur doit être inférieure à 80 caractères.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Valeur non valide fournie pour le paramètre 'CustomerStatus'. Les valeurs valides sont : 'ALL', 'HOLD', 'PENDING', 'CANCELLED', 'ACTIVE'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Valeur non valide fournie pour le paramètre Order. Les valeurs valides sont : 'ASC', 'DESC'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Valeur non valide fournie pour le paramètre OrderBy. Les valeurs valides sont : 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Valeur non valide fournie pour le paramètre PageNumber. Les valeurs valides doivent être numériques et supérieures à zéro.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Valeur invalide saisie pour le paramètre 'Réactiver'. Les valeurs valides sont 'OUI' ou 'NON'",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Une valeur non valide est fournie pour le paramètre 'YourPayerNumber'. Les valeurs valides sont des entiers positifs.",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Valeur non valide fournie pour le paramètre 'YourGeneralReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Valeur non valide fournie pour le paramètre 'YourSystemReference'. Les valeurs valides doivent être inférieures ou égales à 50 caractères",
      "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Valeur non valide pour le paramètre 'DebitDate'. La valeur valide est n'importe quelle date à partir de trente et un (31) jours dans le passé, au format 'AAAA-MM-JJ'",
      "EZIDEBIT_ERROR_CODE_LAST_NAME":"Vous devez fournir une valeur pour le paramètre 'LastName'",
      "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Vous devez fournir une valeur pour le paramètre 'DigitalKey'",
      "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Vous devez fournir une valeur pour le paramètre 'CustomerStatus'",
      "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Vous devez fournir une valeur pour le paramètre 'PaymentID' ou 'BankReceiptID'. Indiquez une valeur pour PaymentID ou BankReceiptID.",
      "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"Vous n'avez fourni aucune carte de crédit ou coordonnées bancaires. Les données obligatoires n'ont pas été fournies pour la mise à jour des détails de facturation",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Vous devez fournir une valeur pour le paramètre 'customerName'",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Vous devez fournir une valeur pour le paramètre 'Order' lorsque la valeur 'OrderBy' est fournie.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Vous devez fournir une valeur pour le paramètre 'OrderBy' lorsque la valeur 'Order' est fournie.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Vous devez fournir une valeur pour le paramètre 'YourPayerNumber'",
      "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Vous devez fournir une valeur valide pour le paramètre 'MobileNumber' si vous souhaitez que le client reçoive des notifications par SMS",
      "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Vous devez fournir un jour de la semaine lors de la création d'un horaire Hebdomadaire, Bimensuel, 4 Hebdomadaire ou Jour de la semaine dans le mois",
      "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Aucune donnée ne correspondait aux paramètres de sélection.",
      "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Valeur nulle détectée, veuillez passer par la chaîne vide au lieu de null pour les paramètres pour lesquels vous ne souhaitez pas fournir de valeur",
      "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Vous devez fournir une valeur pour le paramètre 'PaymentID' ou 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'ChangeFromDate' et 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Conflit de paramètre. 'ScheduleStartDate' et 'DayOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Conflit de paramètre. 'ScheduleStartDate' et 'DayOfWeek' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Ajouter un paiement refusé - Seuls les clients actifs peuvent avoir des paiements ajoutés à leur calendrier",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Ajouter paiement refusé - Vous avez déjà enregistré un paiement avec la référence xxxxxxx. Une référence de paiement doit identifier de manière unique un seul paiement.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Ajouter paiement refusé - Ce client a déjà xx paiements à cette date.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Ajouter un paiement refusé - Ce client est en attente en raison de détails de compte bancaire invalides et ces détails de compte n'ont pas été modifiés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Ajouter un paiement refusé - Ce client est en attente en raison de détails de carte de crédit invalides et ces détails de carte n'ont pas été modifiés.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Ajouter un paiement refusé - Le statut xx de ce client ne permet pas d'ajouter de nouveaux paiements.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Ajouter un paiement refusé - Le statut de ce client 'xxxxx' ne permet pas d'ajouter de nouveaux paiements.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"Non configuré pour les paiements par carte de crédit. Le compte client n'est pas configuré pour traiter les paiements par carte de crédit en temps réel pour le type de carte du client",
      "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Vous devez fournir une valeur pour le paramètre 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"Le paiement a été traité et ne peut pas être supprimé",
      "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"Le paiement initial est toujours en cours de traitement. Il ne peut pas être remboursé à ce stade.",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"Aucun enregistrement de paiement trouvé pour PaymentId",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"Aucun enregistrement correspondant trouvé pour 'PaymentId' ou 'BankReceiptId'",
      "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"Le montant du remboursement dépasse le montant total du paiement",
      "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"Les remboursements ne sont pas autorisés pour le client",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Vous avez transmis à la fois des informations de carte de crédit et de compte bancaire. Seules les données de carte de crédit ou de compte bancaire peuvent être transmises, pas les deux.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Conflit de paramètre. Vous ne pouvez pas entrer une valeur pour les paramètres 'PaymentID' et 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"Tous les paramètres requis n'ont pas été fournis",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'FirstWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'FourthWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'SecondWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflit de paramètre. 'ScheduleStartDate' et 'ThirdWeekOfMonth' ne sont pas alignés.",
      "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"Le paiement sélectionné pour suppression est introuvable.",
      "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"Vous n'utilisez pas de code de facturation Ezidebit BPay partagé.",
      "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Mise à jour du statut refusée - Seuls les clients dont le statut est 'A', 'N' ou 'H' peuvent être modifiés à l'aide de ce service.",
      "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"Impossible de traiter la mise à jour - Numéro de carte de crédit CCV non valide - Le numéro CCV doit comporter 4 chiffres pour AMEX (WSvc)",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Les données du rapport sont actuellement indisponibles car le traitement des paiements est en cours. Veuillez réessayer plus tard",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Les données du rapport sont actuellement indisponibles car le traitement des paiements est en cours (PT). Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Les données du rapport ne sont actuellement pas disponibles car le traitement des paiements est en cours d'exécution (SPS). Veuillez réessayer plus tard...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"Le système est actuellement indisponible. Veuillez réessayer plus tard. Une connexion ne peut pas être ouverte à la base de données pour une raison quelconque. Veuillez contacter partner@ezidebit.com.au pour obtenir de l'aide",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"Le système est actuellement indisponible. Veuillez réessayer plus tard. Une erreur non gérée s'est produite lors de l'extraction des détails de la base de données. Veuillez contacter partner@ezidebit.com.au pour obtenir de l'aide",
      "EZIDEBIT_ERROR_CODE_YES":"Vous devez fournir une valeur « OUI » pour au moins un des paramètres de la semaine du mois lors de la création d'un programme Jour de la semaine dans le mois.",
      "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Vous devez fournir une valeur pour le paramètre 'YourGeneralReference'",
      "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Vous devez fournir une valeur pour le paramètre 'YourSystemReference'",
      "EZIDEBIT_NO_ERROR_DETECTED":"Aucune erreur détectée",
      "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Impossible d'affecter le client au gestionnaire de compte",
      "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"Impossible d'affecter le revendeur au gestionnaire de compte",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Impossible de modifier le cycle de facturation de la commande",
      "FAILED_TO_CREATE_ACCOUNT_MANAGER":"Impossible de créer le gestionnaire de compte",
      "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Impossible de créer un nouveau plan avec toutes les offres",
      "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Échec de la création d'un nouveau forfait revendeur avec toutes les offres",
      "FAILED_TO_DELETE_ACCOUNT_MANAGER":"Échec de la suppression du gestionnaire de compte",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Échec de mise en file d'attente de la demande du clonage du plan",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"Échec de mise en file d'attente de la demande de création d'un nouveau plan comprenant toutes les offres",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Échec de la mise en file d'attente de la demande de création d'un nouveau forfait revendeur avec toutes les offres",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Impossible d'obtenir les jetons d'accès par le code d'authentification reçu de Quickbooks",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Impossible d'obtenir les jetons d'accès par le code d'authentification reçu de Xero",
      "FAILED_TO_RESEND_INVOICE":"Impossible de renvoyer la facture",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Impossible d'enregistrer le code d'authentification reçu de Quickbooks",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Impossible d'enregistrer le code d'authentification reçu de Xero",
      "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Impossible de désaffecter le client d'un gestionnaire de compte",
      "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"Impossible de désaffecter le revendeur d'un gestionnaire de compte",
      "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Échec de la mise à jour des détails du gestionnaire de compte",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"Général",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Vous pouvez facilement mettre à jour les « seeds » en utilisant la fonctionnalité Modifier dans les abonnements.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Vous pouvez autoriser l'accès à l'aide de la fonctionnalité Autoriser l'accès au portail dans la vue utilisateur.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Oui, vous pouvez effectuer la mise à jour en utilisant notre portail.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Oui, vous pouvez suspendre l'abonnement en utilisant C3.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Comment augmenter les sources d'abonnement ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Comment donner accès au portail à un utilisateur normal ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Existe-t-il une option pour mettre à jour le mode de paiement à l'aide de C3 ?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. C3 a-t-il une option pour suspendre les abonnements ?",
      "FAQ_HEADING":"Foire aux questions",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Application générale",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"À partir de maintenant, C3 est pris en charge en anglais (en-us), espagnol (es) et en taïwanais (tw). En plus, C3 prend en charge les devises en fonction des régions et de la facturation du centre partenaire ",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Oui, cela est réalisable dans C3 en définissant des balises. Vous devez définir des balises (paire de valeur) et les affecter aux clients. Par exemple, vous pouvez définir une balise nommée SupportAgent et un pseudonyme pour un agent (par exemple, Agent001) comme valeur. Si un agent du Centre d'assistance a une de ses balises définies comme SupportAgent et l'une des valeurs de cette balise spécifique comme Agent001, l'agent pourrait voir les clients qui ont la paire de valeurs de balise définie {SupportAgent, Agent001}.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Vous devez aller dans Paramètres >> Notification par courriel où vous pourrez configurer C3 pour envoyer des courriels en fonction d'événements. C3 vous offre la possibilité de configurer des courriels pour divers événements. Vous pouvez également voir le courriel de prévisualisation selon l'événement.  Vous pouvez configurer la notification par courriels pour un événement où une offre est achetée par le client. Vous pouvez sélectionner le destinataire en fonction du rôle ou des contacts de facturation. En outre, vous pouvez également sélectionner la liste CC et CCI ainsi. Une fois cela fait, chaque fois qu'un tel événement se produira, C3 enverra des notifications par courriel à tous les destinataires configurés dans le paramètre de notification par courriel.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Vous pouvez télécharger les rapports sommaires d'impôts et des postes d'impôt à partir de l'onglet Résumé des revenus et des coûts, disponible sur le portail partenaire.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Oui, C3 prend en charge les rabais. Vous pouvez créer des coupons rabais et les appliquer à un plan ou des clients spécifiques. Vous pouvez définir différents paramètres pour un coupon tels que le remboursement maximum, « valide à partir de », « valide jusqu'à » et le pourcentage de rabais.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Vous pouvez personnaliser C3 en utilisant des logos pour votre image de marque. C3 vous propose de remplacer le logo de bienvenue sur la page d'accueil et le logo de connexion par le vôtre. Vous pouvez même personnaliser les courriels de notification en utilisant votre logo pour renforcer l'impact de votre image de marque.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Oui, nous pouvons apporter les modifications nécessaires de notre côté afin que le client parvienne à la page de connexion au lieu de la page d'accueil C3.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Non, nous ne sommes pas encore certifiés. Mais, nous utilisons une configuration du même niveau pour stocker vos données en toute sécurité.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Oui, nous pouvons le faire. Nous vous fournirons une adresse IP et vous devrez créer un enregistrement avec la même, où votre DNS sera géré. Votre fournisseur DNS vous aidera à le faire.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Non, vous pouvez directement aller à la configuration et mettre à jour les informations d'identification pour le centre partenaire ou SMTP en cas de modification.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. Quelles sont les langues et les devises prises en charge dans C3 ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Puis-je avoir des utilisateurs qui ne traitent qu'avec un ensemble de clients ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Comment puis-je configurer C3 pour envoyer des courriels lorsqu'un achat est effectué par un client ?  Comment puis-je également configurer les destinataires de ces courriels ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Quels types de rapports fiscaux peuvent être téléchargés ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. C3 prend-il en charge les rabais ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. Quel type de personnalisation C3 offre-t-il en matière de présentation et de convivialité (marque blanche) ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Y a-t-il un moyen pour que mes clients puissent cliquer sur un lien de mon portail qui les amènerait à la page de connexion au lieu de la page d'accueil de C3 ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. Est-ce que C3 est conforme PCI ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Puis-je lier mon nom de domaine préféré à C3 ? Que faut-il pour le faire ?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. Dois-je contacter le service d'assistance C3 lorsque les informations d'identification de mon centre partenaire ou SMTP ont changé ou expiré ?",
      "FAQ_PARTNER_CATEGORY_BILLING":"Facturation",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Actuellement, C3 prend en charge Manuel, Carte de crédit, ACH (seulement pour les clients américains) et les comptes bancaires (seulement pour les clients australiens et néo-zélandais).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Oui, les clients reçoivent une copie de la facture en format pdf dans un courriel de notification lorsque la facture est approuvée par le partenaire.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"Dans de tels cas, vous pouvez empêcher C3 de facturer les clients en enregistrant le paiement sur la facture en question. Vous devez aller au Résumé des revenus et des coûts >> Sélectionnez un client >> Factures. De là, vous devez sélectionner la facture pour laquelle vous avez perçu le paiement. Vous trouverez ici une option d'Enregistrement de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Nous ne prenons pas en charge de multiples cycles de facturation. Mais dans ce cas, nous pouvons aligner tous les clients à un cycle de facturation spécifique. Ceci est un processus manuel.  Par exemple, si votre cycle de facturation actuel pour un client est du 10 de ce mois au 9ème jour du mois prochain et que dans C3, le cycle de facturation est du 1er de ce mois au 1er du mois prochain. Puisque tous les clients ont effectué tous les paiements pour le 9 de ce mois. Nous calculerons le montant correspondant du 1er au 9 et ferons le même ajustement dans la prochaine facture, ce qui résoudra le problème.  De même, nous pouvons faire la même chose pour les autres cycles de facturation. À partir du mois prochain, tous les clients seront alignés sur le même cycle de facturation.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Oui, nous pouvons faire des ajustements concernant les paiements dans la facture correspondante. Il n'y a pas de version automatisée pour l'instant. Nous pouvons nous charger de cela.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Nous prenons maintenant en charge Bill&Pay, Stripe, EziDebit, Authorize.net, Cybersource. Si vous utilisez un autre système que ceux mentionnés, nous pouvons certainement veiller à l'intégration de votre passerelle de paiement avec C3.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 est conçu pour prendre en charge plusieurs passerelles de paiements tels que Bill&Pay, Stripe, EziDebit, Authorize.net, et CyberSource. Ce serait idéal si vous utilisiez l'une d'entre elles comme passerelle de paiement existante, ou nous pouvons essayer de concevoir C3 pour prendre en charge vos passerelles de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Nous mettons à jour les offres C3 en fonction de l'offre Microsoft la plus récente tous les mois. En cas de changement de prix, vous devez créer un nouveau plan ou mettre à jour le plan existant avec de nouveaux prix d'offres.  Par défaut, C3 appliquera les nouveaux prix pour les nouveaux clients. Mais pour les clients existants, vous pouvez définir un nouveau prix pour l'offre actuellement souscrite. En outre, vous pouvez choisir la date d'entrée en vigueur pour les nouveaux prix, par exemple avec effet immédiat pour le changement de prix, le prochain cycle de facturation ou la prochaine date de renouvellement.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"Dans C3, les règles fiscales sont définies par des combinaisons { Pays, État/Province et Code fiscal }, { Pays, Code postal } ou { Pays, État/Province et Code postal}. Par exemple, pour la règle fiscale définie pour le Pays - États-Unis, laisser la province de l'état et le code zip en blanc signifie que le taux d'imposition est appliqué à tous les clients des États-Unis. Si vous souhaitez définir une règle pour un état particulier, indiquez les valeurs Pays et État lors de la définition de la règle.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Oui, vous pouvez le faire. Vous devez aller dans Paramètres >> Taxes, et créer une règle fiscale avec un pourcentage de taxe égal à 0 %. Veuillez l'appliquer au client que vous souhaitez exempter de la règle fiscale (applicable à d'autres clients).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Vous pouvez décider et nous faire savoir la date de génération de facture Nous la configurerons pour vous et vous pourrez le vérifier en allant dans Paramètres de partenaire>> Facturation. Ici vous pouvez voir toutes les dates liées à la facturation. Vous pouvez même modifier cette date pour un client spécifique en allant à la configuration client.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3 vous permet de décider des dates auxquelles votre client devrait être en mesure de consulter la facture. Après la génération de la facture, le partenaire doit approuver la facture (après avoir effectué des modification le cas échéant) pour la rendre disponible pour les clients.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Oui, C3 vous offre la possibilité d'apporter des modifications aux postes de la facture après la génération de la facture. Vous devez apporter les modifications requises (par exemple, vous souhaitez ajouter des frais ou renoncer à des frais sur une base unique) en fonction de vos besoins avant la date de finalisation de la facture. Vous ne pouvez pas apporter de modification à la facture une fois qu'elle est finalisée.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"Dans C3, nous pouvons configurer la notification par courriel pour de tels événements. En cas de succès ou d'échec de paiement, C3 enverra un courriel pour le communiquer. Tous les destinataires configurés pour de tels événements recevront des courriels de notification.   En outre, C3 a une fonctionnalité d'échec de paiement sous la rubrique Résumé des revenus et des coûts, dans laquelle le partenaire peut suivre les enregistrements pour tout incident d'échec de paiement.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. Quelles sont les méthodes de paiement prises en charge par C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Le client reçoit-il une copie de la facture en format pdf lorsque la facture est prête ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Que faire si j'ai perçu un paiement par chèque ou en espèces et que le mode de paiement du client est la carte de crédit ? Comment puis-je empêcher C3 de facturer le client pour la facture actuelle ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Nous avons utilisé nos propres systèmes de facturation et avons facturé nos clients jusqu'à ce jour. Nos clients avaient des cycles de facturation différents. Quel type de soutien obtenons-nous lorsque nous migrons vers C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Y a-t-il un moyen de traiter les paiements anticipés pour une facture ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. Quelles sont les passerelles de paiement que C3 prend en charge de série ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Puis-je utiliser mon fournisseur de passerelle de paiement existant ou dois-je opter pour ce qui est fourni par C3 ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Que se passe-t-il lorsque les prix changent chez Microsoft et que nous voulons mettre à jour nos prix pour nos clients ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. Comment C3 traite-t-il l'impôt ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Puis-je exempter un client particulier d'une règle fiscale applicable à d'autres clients ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. Comment définir le moment où C3 doit générer des factures ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Mon client va-t-il pouvoir consulter les factures juste après leur génération ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Ai-je la possibilité d'apporter des modifications aux postes de la facture après sa génération ? Puis-je ajouter des frais uniques ou annuler des modifications connexes à une facture ?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Comment puis-je (en tant que partenaire) savoir quand un paiement a réussi ou échoué ?",
      "FAQ_PARTNER_CATEGORY_SELL":"Vendre",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Un plan est un groupe d'offres que vous pouvez vendre à votre client au prix de votre choix. Vous devez avoir un plan pour intégrer ou créer un client.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Oui, C3 vous offre la fonctionnalité de création d'offres de partenaire. Par exemple, vous souhaitez fournir une assistance 24 heures sur 24, 7 jours sur 7 à vos clients. Ensuite, vous pouvez créer une offre partenaire 24*7 Office365 Support et la commercialiser au prix de votre choix.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Oui, vous pouvez intégrer dans C3 n'importe quel client existant dans le centre partenaire. Vous devez avoir un identifiant Microsoft (à obtenir du centre partenaire) et un plan correspondant à l'offre souscrite dans le centre partenaire. En outre, C3 vous donne également une fonctionnalité pour créer un nouveau client directement à partir de C3 (pas besoin d'aller sur le centre partenaire pour la création de clients).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Pour ce faire, nous devons intégrer ce client dans un plan. Vous pouvez mettre la quantité d'offres à zéro. Une fois que le client est intégré avec succès, il synchronisera automatiquement les abonnements basés sur l'utilisation pour ce client particulier.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"Dans de tels cas, il y aura une discordance de quantité entre PC et C3. Notre équipe peut corriger de telles discordances.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Nous pouvons faire des ajustements dans les factures, mais à partir de maintenant, C3 ne prend plus en charge les remboursements.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Oui, C3 calcule l'utilisation des clients, et basé sur la consommation de données, il génère la facture.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Oui, vous pouvez créer un coupon-rabais avec le pourcentage de rabais requis et les récurrences maximales définies sur 3. Affectez maintenant le coupon nouvellement créé à un client spécifique. Une fois cela fait, le client obtiendra le rabais applicable pour les trois prochaines factures.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Qu'est-ce qu'un plan ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Puis-je vendre des offres non basées sur Microsoft comme des services personnalisés ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Puis-je intégrer un client existant ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Comment puis-je intégrer un client qui a uniquement des abonnements basés sur l'utilisation ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mon équipe a accidentellement acheté des licences par le biais de PC. Quelles sont les répercussions de telles actions ? Existe-t-il un processus pour corriger ces écarts ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. C3 prend-t-il en charge les remboursements ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. C3 gère-t-il la facturation Azure pour l'utilisation de mes clients ?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. J'ai un client intégré dans C3 et je veux offrir des rabais pour les trois prochaines factures, puis-je le faire ?",
      "FEATURE_COMMENTS_ORDER_NO":" Numéro de commande :",
      "FEBRUARY":"Février",
      "FIELD_REQUIRED":"{{atribute}} est obligatoire",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"Sélectionner",
      "FILTER_SELECT_A_TARGET_CURRENCY":"Sélectionner",
      "FIX_AUTO_RENEW_STATUS_MISMATCH":"Correction de l'incohérence du statut de renouvellement automatique",
      "FIX_AZURE_MISSING_OFFER":"Correction de l'offre manquante d'azur",
      "FIX_Azure_MISSING_OFFER":"Correction de l'offre manquante d'Azure",
      "FIX_BILLING_CYCLE_MISMATCH":"Corriger l'incohérence du cycle de facturation",
      "FIX_MISSING_OFFER":"Correction de l'offre manquant",
      "FIX_OFFER_MISMATCH":"Correction de l'inadéquation de l'offre",
      "FIX_QUANTITY_MISMATCH":"Corriger un écart de quantité",
      "FIX_SCOPE_MISMATCH":"Corriger l'inadéquation de la portée",
      "FIX_STATUS_MISMATCH":"Corriger l'incompatibilité de statut",
      "FIX_TERM_DURATION_MISMATCH":"Corriger l'incohérence de la durée du terme",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":".Tous droits réservés.",
      "FOOTER_TEXT_1_POWERED_BY":"Pris en charge par",
      "FROM":"De",
      "General":"Général",
      "GENERAL_SETTINGS_MAIN_ERROR":"Veuillez corriger les erreurs dans le formulaire avant de soumettre",
      "GENERATE_INVOICE_REASON":"Raison",
      "GENERATE_INVOICE_REASON_PLACEHOLDER":"Veuillez fournir une raison",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Jetons d'accès QuickBooks générés par le code d'authentification reçu de Quickbooks",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Jetons d'accès Xero générés par le code d'authentification reçu de Xero",
      "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la récupération des détails du fournisseur de facturation.",
      "GET_CURRENCY_CODES_EXCEPTION":"Une erreur s'est produite lors de la récupération des codes de devises",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de la récupération de la conversion de devises",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération de la liste de conversion de devises",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des options de devises",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des événements client pour les notifications par courriel",
      "GET_CUSTOMER_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération des clients",
      "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Une erreur s'est produite lors de l'obtention des forfaits client avec la promotion active",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Une erreur s'est produite lors de la récupération de la liste des destinataires de notification par courriel",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Une erreur s'est produite lors de la récupération des événements clients pour les notifications par courriel",
      "GET_PARTNER_LOGO_EXCEPTION":"Une erreur s'est produite lors de la récupération du logo du partenaire",
      "GET_PARTNER_SETTINGS_EXCEPTION":"Une erreur s'est produite lors de la récupération des paramètres du partenaire",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la récupération des informations sur l'impôt",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Une erreur s'est produite lors de la récupération des types d'impôts",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Une erreur s'est produite lors de la récupération des devises cibles",
      "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Une erreur s'est produite lors de la réception de la demande de relation avec le revendeur.",
      "GOT_RESELLER_DETAILS":"Vous avez obtenu le revendeur depuis de CSP avec le nom: {{resellerName}}",
      "government":"Gouvernement",
      "Government":"Gouvernement",
      "GRAND_ACCESS_USER_EXCEPTION":"Cet utilisateur a déjà accès, veuillez vérifier pour un autre",
      "GRAND_TOTAL":"Total",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Consommation par produit",
      "GRAPH_CUSTOMERS_ONBOARDED":"Clients intégrés",
      "GRAPH_DATA_BILLED_AMOUNT":"Montant facturé",
      "GRAPH_DATA_QUANTITY":"Quantité",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Revenus par client",
      "GRAPH_TOP_SELLING_PRODUCT":"Les 10 produits les plus vendus",
      "GREATER_THAN_ERP":"supérieur à l'ERP",
      "GRID_DOWNLOAD_COUPON_GRID":"Télécharger les coupons",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Un administrateur de groupe avec ce courriel existe déjà dans ce groupe",
      "GROUP_USERS_OF":"Groupe d'utilisateurs de",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Utilisateurs du groupe",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Afficher l'application dans une langue prise en charge par l'application",
      "HEADER_CONTENT_HELP_MENU":"Contenu d'aide contextuel présenté dans la barre latérale de droite",
      "HEADER_CONTENT_MENU_CONTACT_LOG":"Permet au Partenaire de voir l'état de toutes les notifications et le contenu de l'e-mail.",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Suivre l'activité dans le système",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Détails du profil de base de l'utilisateur connecté",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Vous permet de gérer les paramètres ou d'accéder au journal des activités",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Vous permet de gérer les paramètres de niveau d'application, les notifications par courriel, définir les taxes et plus encore...",
      "HEADER_CONTENT_SIGNIN_USER":"Détails du profil de base de l'utilisateur connecté",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Nous contacter",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Se déconnecter",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Utilisateur non autorisé",
      "HEADER_GO_TO_PROFILE":"Aller au profil",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Nous contacter",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Arrêter représentation",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Utilisateur non autorisé",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Langue",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Langue",
      "HEADER_TITLE_HELP_MENU":"Aide",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Notifications",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Journal d'audits",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Paramètres",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Paramètres",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Paramètres",
      "HEADER_TITLE_SIGNIN_USER":"Utilisateur connecté",
      "HEADING_ENTITY_LIST":"Liste {{EntityName}}",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Fournir l'adresse courriel pour obtenir le courriel de test.",
      "HEURES":"Heures)",
      "HIGH":"Haut",
      "HOURS":"Heure(s) ",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"ici",
      "I have read and agreed to":"J'ai lu et accepté",
      "ID DU SITE":"C3SiteId",
      "IF_ALREADY_EXISTS":"existe déjà",
      "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Cette valeur décide si les filtres de recherche doivent être activés/désactivés dans l'écran de la boutique d'inscription publique.",
      "IGNORE_FROM_PSA_TEXT_DISP":"Ignorer le client du service externe",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Avec votre privilège actuel",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Sans vos privilèges actuels",
      "IMPORT_LIST":"Importer la liste",
      "In Progress":"En cours",
      "IN_ACTIVE":"Inactif",
      "INDEX_LOGOUT_APOLOGY_MESSAGE":"Ce programme est resté inactif pendant trop longtemps. Nous nous excusons donc de vous avoir déconnecté, mais nous n'avions pas le choix.",
      "INDEX_LOGOUT_MESSAGE":"Désolé, vous avez été déconnecté",
      "INDEX_MSG_LOGOUT_SOON_MESSAGE":"Le mode veille est activé car vous restez inactif trop longtemps. En conséquence, vous allez vous déconnecter dans quelques instants.",
      "INDXE_LOGOUT_TEXT":"FAITES QUELQUE CHOSE RAPIDEMENT ! Vous serez déconnecté dans {{countdown}} seconde(s).",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Aucune devise disponible dans l'enregistrement",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Aucun état disponible dans l'enregistrement",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Veuillez sélectionner un pays",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Veuillez d'abord sélectionner une devise source",
      "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"Êtes-vous sûr de vouloir initier le débit de la facture pour <%= currencySymbol%><%= factureAmount%> en utilisant le mode de paiement : <br> <b><%= PaymentType%> | <%= NuméroCompte%></b> ? <br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide concernant des paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur. </petit>",
      "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Il n'y a pas de montant en attente à payer.",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Toutes les factures sélectionnées seront mises en file d'attente pour les paiements. Voulez-vous continuer ?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"Le statut de la facture passe à <%= invoiceStatus%>. Voulez-vous continuer ?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Le client sera facturé $<%= amount%>. Voulez-vous continuer?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Le revendeur sera facturé $<%= amount%>. Voulez-vous poursuivre?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
      "INPUT_CATEGORYID":"CategoryId",
      "INPUT_CHANGE_AUTO_RENEW_STATUS":"Statut de renouvellement automatique",
      "INPUT_CHANGE_TYPE":"ChangeType",
      "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "INPUT_CUSTOMERC3ID":"CustomerC3Id",
      "INPUT_CUSTOMERID":"CustomerId",
      "INPUT_CUSTOMERNAME":"CustomerName",
      "INPUT_DATA":"Entrée de l'utilisateur",
      "INPUT_DESCRIPTION":"Description",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"InternalProductId",
      "INPUT_INVOICEID":"InvoiceId",
      "INPUT_LABEL_PURCHASE_QUANTITY":"Quantité d'achat",
      "INPUT_NAME":"Nom",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"PageCount",
      "INPUT_PAGEINDEX":"PageIndex",
      "INPUT_PAGESIZE":"PageSize",
      "INPUT_PLANC3ID":"Plaid",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"PriceforPartner",
      "INPUT_PRODUCTID":"Identifiant de produit",
      "INPUT_PROVIDERID":"ProviderId",
      "INPUT_PROVIDERSELLINGPRICE":"Prix de vente du fournisseur",
      "INPUT_QUANTITY":"Quantité",
      "INPUT_REACTIVATE":"Réactiver",
      "INPUT_SALETYPE":"Type de vente",
      "INPUT_STARTIND":"StartInd",
      "INPUT_SUSPEND":"Suspendre",
      "INPUT_TENANTID":"TenantId",
      "INPUT_WITHADDONS":"WithAddons",
      "INSCRIPTION_CUSTOMER_CONSENT_AGREEMENT_DATE":"Date de l'accord",
      "INSCRIPTION_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"Retour au panier",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Retour aux commandes",
      "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Profil de paiement",
      "INSTANT_PAY_BUTTON_TEXT_PAY":"Payer",
      "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"La passerelle de paiement actuelle n'est pas encore prise en charge avec le paiement instantané. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Méthode de paiement ",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Paiement sécurisé",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Téléchargez la feuille cartographiée pour la cartographie au format .csv. Téléchargez le fichier <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le télécharger.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Ce fichier est utilisé pour mapper les entités entre C3 et AutoTask Manage. Ne supprimez aucun texte ou donnée existant dans ce fichier.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Seuls les champs qui doivent être mis à jour sont « AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId et ContractServiceName ». Le reste des champs est là pour aider à identifier les homologues C3 pour le mappage.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Si les champs mentionnés ci-dessus sont remplis dans la feuille, le mappage est disponible, si les champs mentionnés ci-dessus sont vides, alors le mappage est requis.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Pour remplir la feuille de cartographie, vous pouvez vous référer à la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"Le nom et l'identifiant entier (numérique) requis pour remplir la <b> feuille de mappage</b> sont disponibles dans la <b>feuille d'entité AutoTask.</b>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"La suppression du mappage n’est pas possible via la feuille de mappage. Pour modifier la correspondance entre l'accord et le produit, veuillez utiliser la feuille de correspondance. Le remappage client n'est possible que via l'écran de mappage des produits achetés.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_BULK_BUTTON":"Instructions pour le téléchargement groupé de la cartographie des produits achetés :",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Téléchargez la feuille cartographiée pour la cartographie au format .csv. Téléchargez le fichier <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le télécharger.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Ce fichier est utilisé pour mapper les entités entre C3 et ConnectWise Manage. Ne supprimez aucun texte ou donnée existant dans ce fichier.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Seuls les champs qui doivent être mis à jour sont « ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId et ConnectwiseProductname ». Le reste des champs est là pour aider à identifier les homologues C3 pour le mappage.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Si les champs mentionnés ci-dessus sont remplis dans la feuille, le mappage est disponible, si les champs mentionnés ci-dessus sont vides, alors le mappage est requis.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Pour remplir la feuille de cartographie, vous pouvez vous référer à la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"Le nom et l'identifiant entier (numérique) requis pour remplir la <b> feuille de mappage</b> sont disponibles dans la <b>feuille d'entité Connectwise.</b>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"La suppression du mappage n’est pas possible via la feuille de mappage. Pour modifier la correspondance entre l'accord et le produit, veuillez utiliser la feuille de correspondance. Le remappage client n'est possible que via l'écran de mappage des produits achetés.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Pour faciliter la mise en correspondance entre les produits C3 et ConnectWise Manage, veuillez télécharger la feuille de correspondance au format .csv. Vous pouvez télécharger le fichier <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> pour le mettre à jour.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"Le fichier sert à établir des mappages de produits entre C3 et ConnectWise Manage. Veillez à ne supprimer aucun texte ou donnée existant dans le fichier.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Seuls les champs ConnectwiseProductId et ConnectwiseProductname nécessitent une mise à jour. D'autres champs permettent d'identifier les homologues C3 à des fins de mappage.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Si les champs mentionnés sont remplis, le mapping est terminé. S'ils sont vides, un mapping est nécessaire.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Reportez-vous à la <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Fiche d'entité Connectwise.xlsx.</a> pour obtenir des conseils sur la manière de remplir la feuille de mappage. Le nom et les identifiants entiers (numériques) requis sont disponibles dans la feuille d'entité ConnectWise.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Veuillez noter que la suppression des mappages n'est pas possible via la feuille de mappage. Pour modifier les mappages de produits, utilisez la feuille de mappage fournie.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Téléchargez le fichier et cliquez sur SOUMETTRE pour finaliser le processus.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Téléchargez le fichier et cliquez sur SOUMETTRE.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON":"Instructions pour utiliser le bouton Actualiser :",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Cliquez sur le bouton Actualiser pour obtenir les dernières données de PSA vers C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"Par défaut, aucune donnée ne sera disponible dans Product Mapping au début. Cliquez sur le bouton Actualiser pour obtenir toutes les données disponibles de PSA vers C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Lorsque « Actualiser » est en cours, le système désactivera les autres onglets. En cas de succès, les onglets désactivés seront à nouveau visibles. En cas d'erreur, cet écran offrirait une option pour ignorer l'erreur. Vous pouvez cliquer sur le bouton Ignorer l'erreur pour activer les onglets désactivés.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Lorsque « Actualiser » est en cours, le système supprimera les données PSA existantes de C3 et insérera de nouvelles données de PSA.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Si des modifications sont apportées à PSA, par exemple l'ajout ou la suppression d'une société, d'un accord, de produits ou un changement de statut pour ces entités, l'utilisateur doit alors actualiser le mappage des produits pour refléter ces modifications dans C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Veuillez cliquer sur le bouton </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\"> Actualiser</a> pour obtenir les dernières données du PSA au C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" bouton pour obtenir les dernières données de PSA vers C3.",
      "INTÉGRATION":"Intégration",
      "INTERNAL_BILLING_ID":"Identifiant de référence de facturation : ",
      "INTERNAL_SCHEDULE_RENEWAL_ID":"Identifiant de renouvellement planifié interne",
      "INTERNAL_SCHEDULED_RENEWAL_ID":"Identifiant de renouvellement programmé interne",
      "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"Le changement de statut de renouvellement automatique n'est pas autorisé",
      "INVALID_ATTEMPT_DECREASE":"Une quantité décroissante n'est pas autorisée",
      "INVALID_ATTEMPT_REACTIVATE":"La réactivation du produit n'est pas autorisée",
      "INVALID_ATTEMPT_SUSPEND":"La suspension du produit n'est pas autorisée",
      "INVALID_COUNT":"Le nombre total de sièges ne peut pas être inférieur à 1",
      "INVALID_COUPON":"Ce bon n'est pas valable",
      "INVALID_EXPIRY_DATE":"Date d'expiration invalide",
      "INVALID_INPUT":"Entrée non valide",
      "INVALID_MESSAGE_ENTITY":"Entité de message invalide",
      "INVALID_MESSAGE_TYPE":"Type de message invalide",
      "INVALID_PORTAL_PAGE":"Page de portail invalide",
      "INVALID_PRODUCT_ID":"Un produit avec l'identifiant donné est introuvable dans le système.",
      "INVALID_QUANTITY":"Quantité invalide",
      "INVALID_RECIPIENTS":"Destinataires invalides",
      "INVALID_SEATS":"Détail manquant ou invalide. Les sièges doivent être un nombre positif ou négatif, limité par les contraintes du fournisseur",
      "INVALID_VALUE":"{{atribute}} n'est pas valide",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Retour aux invitations",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Ajouter une invitation",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Modifier invitation",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Tout effacer",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Articles sélectionnés",
      "INVITES_ADD_FORM_LABEL_OFFER":"Offres",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Prix",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Description",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Nom",
      "INVITES_ADD_FORM_URL_INVITE_URL":"Invitation URL",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Invitations",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Ajouter une nouvelle invitation",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"Invitations",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Modifier",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Description de l'invitation",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Nom de l'invitation",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "INVITES_SAVE_SUCCESS":"Invitation sauvegardé avec succès",
      "INVITES_SELECT_OFFERS_PROMPT":"Veuillez inclure au moins  une offre dans l'invitation ",
      "INVITES_UPDATE_SUCCESS":"Invitation mise à jour avec succès",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Date de fin",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Date de début",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"Voulez-vous que cela soit dû comme un ajustement dans cette facture ?",
      "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports de factures et de cotisations.",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Tableau de bord",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Factures",
      "INVOICE_COMMENTS":"Facture",
      "INVOICE_COUPON_APPLIED_MESSAGE":"Coupon d'escompte <strong>{{CouponCode}}*</strong> appliqué à cette facture",
      "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Solde dû",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Sélectionner tout",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"Courriel envoyé avec succès à la liste des utilisateurs",
      "INVOICE_FOOTER_LINE_DESC":"Apparaît sur la vue de la facture et sur le PDF de la facture sous forme de ligne de pied de page",
      "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Apparaît sur la vue de la facture et sur le PDF de la facture sous forme de ligne de pied de page",
      "INVOICE_FOOTER_LINE_DISP":"Ligne de pied de page de facture",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Ligne de pied de page de facture",
      "INVOICE_GENERATION_REASON":"Motif de génération de la facture",
      "INVOICE_GENERATION_STATUS_TITLE":"Statut de génération de facture",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Aller au résumé des revenus et des coûts",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Télécharger des factures ou les Paiements",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Retour au résumé des revenus et des coûts",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Télécharger des factures ou les Paiements",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importation de factures clients réussie",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Veuillez sélectionner au moins une facture client avant de continuer.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Factures à télécharger pour les clients",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Code de devise",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Nom qualifié",
      "INVOICE_INVOICE_APPROVE":"Approuver",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Retour aux postes de facturation",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Retour aux factures",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Télécharger une facture",
      "INVOICE_INVOICE_CAPTION_TEXT":"Facture pour",
      "INVOICE_INVOICE_FINALIZE":"Finaliser",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Résumé de compte",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adresse",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Client",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Résumé de facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"période de facturation",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Date de facturation",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"numéro de TVA",
      "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Facture fiscale",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Détail de la facture",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Facture de cotisation",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Solde des paiements antérieurs",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Basé sur licence",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Basé sur l'utilisation",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Montant",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Type de charge",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Coût pour le partenaire",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Code promotionnel",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Description",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Rabais",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Montant d'escompte",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Prix de l'offre",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Quantité",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Date de fin",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Montant de la ligne",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Particuliers",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Montant total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Montant du sous-total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Domaine",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Date de début",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Montant de la taxe",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Pourcentage d'impôt",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Prix unitaire",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Aucune Facture trouvée. Veuillez contacter l'équipe de support.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Paiements de facture",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Montant",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Date",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Raison de l'échec",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Total",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Remarques",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Statut",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Montant dû",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Date d'échéance",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Date de la facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Statut",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Montant payé",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Montant restant",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Quantité utilisée",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Ajustements",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Instance de cycle au prorata",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Les factures échues",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Frais de prorata",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Frais au prorata lors de l'achat",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Frais à l'achat",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"Rembourser le montant",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Frais de renouvellement",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Taxes",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Niveau d'utilisation",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Crédit",
      "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--lié à {{linkedProductName}}",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>Domaine {{providerName}}  </b> {{providerTenantName}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Taux de conversion",
      "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Total",
      "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Éléments de ligne de facture avec taxes",
      "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Remise promotionnelle",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Actions",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Courriel",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Rapport sur les éléments de ligne de facture",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les éléments de ligne de facture",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Entrez l'adresse e-mail à laquelle vous souhaitez envoyer par e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur Soumettre pour recevoir un fichier contenant la liste des éléments de ligne de facture.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Envoyer » pour recevoir un fichier contenant la liste du rapport des éléments de ligne de facture.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Annuler",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Enregistrer les favoris",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Factures",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Paiements",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Facture générée le",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Raison de l'échec",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Montant restant",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Montant payé",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Date de paiement",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"Identifiant de transaction",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Remarques",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Statut de paiement ",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Quantité utilisée",
      "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Afficher les journaux de contacts",
      "INVOICE_NUMBER":"Numéro de facture",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importation de paiements de factures clients réussie",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Paiements de factures à télécharger pour les clients",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"Impossible de mettre à jour la date d'échéance avec une valeur nulle",
      "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"La propriété de la facture a été mise à jour avec succès",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"La date de début ne doit pas être supérieure à la date de fin",
      "INVOICE_SENT_SUCCESSFULLY":"Facture envoyée avec succès",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Approuvé pour examen par le client",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Finaliser la facture",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"En cours",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Facture générée",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Facture publiée",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Montant total payé",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Montant partiel payé",
      "INVOICE_TAB_NAME_COMMENTS":"Commentaires",
      "INVOICE_TAB_NAME_DETAILS":"Détails",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Notifications",
      "INVOICE_TRANSACTION_REPORT_HEADER":"Factures et rapport de cotisations",
      "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Tapez un message",
      "INVOICE_WILLBE_MAILED":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "InvoiceCurrency":"Devise de la facture",
      "INVOICES_LBL_OWNED_BY":"<b> Propriété de </b> {{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Période de facturation",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Montant de la facture",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Date d'échéance de la facture",
      "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Date de facture générée",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Est gérable uniquement par le partenaire",
      "IS_MASTER":"Maître",
      "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"L'approbation du partenaire est-elle requise pour les clients revendeurs",
      "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"Le produit est réactivé avec add-ons",
      "IS_PRODUCT_BEING_UPDATED_WITH_ADDONS":"Le produit est-il mis à jour avec des extensions",
      "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"Le filtre est disponible pour le client",
      "IS_TRIAL_OFFER":"Offre d'essai",
      "IsCustomPrice":"C'est un prix personnalisé",
      "JANUARY":"Janvier",
      "JETON D'ACCÈS":"Jeton d'accès",
      "JOURNÉES":"Journées)",
      "JULY":"Juillet",
      "JUNE":"Juin",
      "LABEL_ASSIGNED_RG":"Groupes de ressources affectées",
      "LABEL_BANNER_TEXT":"Message",
      "LABEL_BANNER_TEXT_TOOL_TIP":"Écrivez le message pour la bannière.",
      "LABEL_BILLING_ADDRESS":"Adresse de facturation",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"L'offre d'essai ne peut être achetée qu'une seule fois.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Cette offre d'essai est déjà disponible dans votre panier.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"Offre d'essai pour",
      "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Offre d'essai parent indisponible",
      "LABEL_Customer_Name":"nom du client",
      "LABEL_CUSTOMER_NAME":"nom du client",
      "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"le(s) produit(s) du plan ont été sélectionnés",
      "LABEL_DESC_SELECTED_PLANS":"le(s) plan(s) ont été choisis",
      "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"des variantes de produit ont été sélectionnées.",
      "LABEL_EFFECTIVE_ENTITY":"Entité Concernée",
      "LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "LABEL_EFFECTIVE_RECORD_NAME":"Enregistrement effectif",
      "LABEL_EFFECTIVE_TO":"Expire le",
      "LABEL_IS_ACTIVE":"Est actif",
      "LABEL_NOTIFICATION_RECIPIENTS":"Destinataires de notification",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Mettez les utilisateurs séparés par des virgules",
      "LABEL_NOTIFICATION_TABLE":"Notifications de bannière",
      "LABEL_NOTIFICATION_TYPE":"Style des messages",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Affecter une politique pour ",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Affectations de politique de",
      "LABEL_POLICY_CUSTOMER":"Client",
      "LABEL_POLICY_DEFINITION":"Définition de la politique",
      "LABEL_POLICY_DESCRIPTION":"Description de la politique",
      "LABEL_POLICY_NAME":"Nom de la politique",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "LABEL_POLICY_SUBSCRIPTION":"Abonnement",
      "LABEL_PORTAL_PAGE":"Portail",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"ajouter un rôle personnalisé",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Retour aux rôles personnalisés",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Ajouter un rôle personnalisé",
      "LABEL_ROLE_CUSTOMER":"Client",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "LABEL_ROLE_ROLE_DEFINITION":"Définition de rôle",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Description du rôle",
      "LABEL_ROLE_ROLE_NAME":"Nom de rôle",
      "LABEL_ROLE_SUBSCRIPTION":"Abonnement",
      "LABEL_SHIPPING_ADDRESS":"Adresse d'expédition",
      "LABEL_TEXT_ADDONS":"Extensions",
      "LABEL_TEXT_ADDRESS_1":"Adresse 1",
      "LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Disponible pour le client",
      "LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "LABEL_TEXT_BILLING_TYPE":"Type de facturation",
      "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" La fenêtre d'annulation expire dans",
      "LABEL_TEXT_CATEGORY":"Catégorie",
      "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Créer un compte Microsoft",
      "LABEL_TEXT_DISCOUNT":"Rabais",
      "LABEL_TEXT_DISTRIBUTER_OFFER":"Offres des distributeurs",
      "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Activer toutes les promotions",
      "LABEL_TEXT_LINKED_PRODUCT":"Produit lié",
      "LABEL_TEXT_NEW_COMMENCE_EXPERIENCE_VALIDITY_TERM":"Terme -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Catégorie :",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"Logiciel perpétuel",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Abonnements logiciels",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Terme",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":"Terme -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Terme :",
      "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"Non disponible pour le client",
      "LABEL_TEXT_OWNER_NAME":"Le nom du propriétaire",
      "LABEL_TEXT_PRICE":"Prix",
      "LABEL_TEXT_RESERVED_INSTANCES":"RI",
      "LABEL_TEXT_RETAIL_PRICE":"Prix de détail",
      "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Abonnement source",
      "LABEL_TEXT_STATE_PROVINCE":"Province d'état",
      "LABEL_TEXT_STATUS":"Statut",
      "LABEL_TEXT_TARGET_SUBSCRIPTION":"Abonnement cible",
      "LABEL_TEXT_TAX_CODE":"Code fiscal",
      "LABEL_TEXT_TAX_TYPE":"Type de taxe",
      "LABEL_TEXT_TENANT_NAME":"Nom de domaine",
      "LABEL_TEXT_TYPE_IN_NAME":"Tapez un nom",
      "LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "LABEL_TEXT_ZIP_CODE":"Code postal",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Nouveau",
      "LABEL_UNASSIGNED_RG":"Groupes de ressources dissociés",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Charges Additionnelles",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Administrateur client",
      "LABLE_CUSTOMER_READER_ROLE":"Lecteur client",
      "LABLE_DISCOUNTS_TOTAL":"Rabais total",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Total des abonnements",
      "LABLE_TAXES_TOTAL":"Total des taxes ",
      "LABLE_TEXT_COST_PRICE":"Coût d'acquisition",
      "LABLE_TEXT_COST_TO_PARTNER":"Coût pour le partenaire",
      "LABLE_TEXT_DISPLAY_NAME":"Nom d'affichage",
      "LABLE_TEXT_EVENTS":"Événements",
      "LABLE_TEXT_FRIENDLY_NAME":"Nom convivial",
      "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"Disponible pour le client ?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"Promotion disponible pour le client ?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"Promotion disponible pour le revendeur ?",
      "LABLE_TEXT_MAXVALUE":"MaxValue : ",
      "LABLE_TEXT_MINVALUE":"MinValue : ",
      "LABLE_TEXT_OFFER_NAME":"Nom de l'offre",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur",
      "LABLE_TEXT_SALE_PRICE":"Prix de vente",
      "LABLE_TEXT_SALEPRICE":"Prix de vente : ",
      "LABLE_TEXT_SLAB":"Détails du bloc",
      "LABLE_TEXT_SLAB_SALE_PRICE":"Prix de vente (UdM)",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Coût pour le partenaire",
      "LABLE_TEXT_TYPE":"Type de facturation",
      "LABLE_TEXT_UNIT_OF_MEASURE":"(UdM)",
      "LABLE_WAIVER":"Renonciation",
      "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"La dernière tentative de chargement des données a abouti à un échec. Veuillez cliquer sur Actualiser pour synchroniser les dernières modifications de PSA vers C3.",
      "LATEST_COMMENTS":"Dernier commentaire",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"S'identifier",
      "LAYOUTLOGIN_HEADER_TEXT":"S'identifier",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Veuillez vous connecter pour continuer",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Systèmes Spektra. Tous droits réservés.",
      "LBL_COUPON_CODE_TEXT":"Code promotionnel",
      "LBL_DISCOUNT_TEXT":"Rabais",
      "LBL_OFF":"off",
      "LBL_TEXT_NOT_PROVIDED":"Non fourni",
      "LESS_THAN_LIST_PRICE":"lessThanListPrice",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Veuillez trouver les erreurs ci-dessous.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Cette opération ne peut pas être complétée car les conditions préalables ne sont pas remplies. L'attribution d'une extension nécessite une offre de base et la révocation d'une offre de base nécessite la libération des extensions.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Offre",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Offres pré-requises",
      "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Demande rejetée",
      "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Télécharger le rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Obtenir le rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"Quantité finale",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Créé par",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nom du client",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Date du changement",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Rapport de changement de licence",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Nouveau statut",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Ancien statut",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Quantité d'ouverture",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Produit",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nom du revendeur",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nom du locataire lié",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"Statut des CGU",
      "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"La date \"de\" du rapport ne doit pas être supérieure à la date \"à\"",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Accepté",
      "LICENSE_CONSUMPTION_REPORT":"Rapport de consommation de licence",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Sélectionner",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Client",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Domaine",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Veuillez sélectionner un client",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Veuillez entrer le nom de l'abonnement",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Veuillez sélectionner un domaine",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filtres",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Différence de quantité affectée",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Disponible dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Disponible dans le centre partenaire",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Licences affectées dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Licences dans C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Client",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"Adresse courriel",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Différence de quantité totale",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Produits mappés",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Licences affectées dans Provider",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Licences dans le fournisseur",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Licences inutilisées pour le fournisseur",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Abonnement",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Domaine",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Afficher les détails",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Rapport de consommation de licence",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Rapport sur les revenus",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Exporter le rapport sur la consommation des licences en format CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Domaines",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Récupération du résumé de licence",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Télécharger le rapport sommaire des licences",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Résumé de licence",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nom du revendeur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Cycle de facturation",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Nom de produit parent",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Nom de l'abonnement parent",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Nom du fournisseur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Identifiant de domaine du fournisseur",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Quantité",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Renouvellement – (en jours)",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"Se renouvelle le",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Actions",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Date d'expiration",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Nom",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"Clé de bon",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Consommation de licence",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Libérer les licences inutilisées",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Libérer les sièges non utilisés",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Libérer les droits inutilisés. Veuillez patienter...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Affecté",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Produits mappés",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Total",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom d'abonnement ",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Actions",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consommation de licence",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Affecté",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Valide",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
      "LICENSES_TOOLTIP_MS_USER":"Licences",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Lien vers le profil de facturation de",
      "LINK_CUSTOMER_HEADER_TEXT":"Lien vers le fournisseur de",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Vous êtes lié à tous les fournisseurs présents",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"Type de client",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"Type de client",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Identifiant de domaine",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Nom de domaine",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Liaison réussie du client Microsoft non-csp à c3.",
      "LINK_RESELLER_CAPTION_TEXT":"Lier un revendeur",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Fournisseur",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Identifiant de revendeur (comme pour le fournisseur)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Supprimer",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Actions",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Identifiant d'entreprise",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Nom du fournisseur",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Identifiant du revendeur fournisseur",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Vous êtes lié à tous vos profils fournisseurs.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Lien vers {{providerName}} a été supprimé.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Lié à {{providerName}} avec succès.",
      "LINK_TEXT_CLICK_HERE":"Cliquez ici",
      "linkCustomerBillingProfile":"Lien vers le profil de facturation",
      "LINKED_PRODUCT_INFO_SHOPE":"Ce produit est lié avec ",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Sélectionner",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Lien vers un produit",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Actions",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Validation de la disponibilité du domaine",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Catégorie",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Fournisseur",
      "LOADING_INDICATOR_MESSAGE":"Traitement...",
      "LOADING_MESSAGE":"Chargement...",
      "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>les promotions du mois en cours sont déjà chargées</p> <p> voulez-vous recharger les promotions ?</p>",
      "LOADPROMOTION_CATEGORY_TOOLTIP":"Veuillez choisir la catégorie de produit dans la liste déroulante",
      "LOADPROMOTION_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Donnez-nous un moment pour compléter votre personnification en tant que",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Salutations,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Vous êtes maintenant connecté. Donnez-nous un moment pour préparer l'application pour que vous puissiez y accéder...",
      "LOW":"Faible",
      "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Appliquer X % sur le balisage",
      "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Appliquer X % sur le prix du partenaire",
      "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Appliquer X % sur le balisage",
      "MACRO_COPY_PARTNERT_PRICE":"Copier le prix du partenaire",
      "MACRO_COPY_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur de copie",
      "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"Le prix de vente du produit plan sera la somme du prix du partenaire + X % sur la marge entre le prix du partenaire et le prix de vente du fournisseur.",
      "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"Le prix de vente du produit plan sera le prix partenaire + X % (prix partenaire)",
      "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera le prix de vente du fournisseur + X % (prix de vente du fournisseur)",
      "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera égal au prix partenaire",
      "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Le prix de vente du produit du plan sera égal au prix de vente du fournisseur.",
      "MACRO_DET_EXAMPLE":"Exemple:",
      "MACRO_DET_PARTNER_PRICE":"Prix partenaire",
      "MACRO_DET_PERCENTAGE":"Pourcentage(X)",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Prix de vente du fournisseur",
      "MACRO_DET_SALE_PRICE":"Prix de vente:",
      "MACRO_PERCENTAGE_TOOLTIP_TEXT":"Macro%",
      "MAIL_SENT_SUCCESSFULY":"Courriel envoyé avec succès",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Considérer dans la version automatique",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Non",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Oui",
      "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Est pris en compte dans la version automatique",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Considéré dans la publication automatique",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Gérable exclusivement par le partenaire",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Votre demande d'attribution de licences est en attente. Veuillez revenir sur cette page après un certain moment pour les mises à jour.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Veuillez entrer les adresses email valides des utilisateurs dans le domaine",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"Utilisateur {{username}} détient déjà une licence pour {{subscriptionname}}",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner au moins un fichier",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Êtes-vous certain de vouloir ignorer les erreurs ?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Le nombre minimum de droits pour un abonnement doit être de 1. Ne peut pas libérer de droits si le nombre disponible est de 1.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Êtes-vous certain de vouloir libérer les licences inutilisées ?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Les licences non utilisées ont été libérées avec succès",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Veuillez ajouter au moins un utilisateur à la section licences à supprimer",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Veuillez sélectionner un utilisateur dans la liste suggérée avant de cliquer sur le bouton Ajouter.",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"Attribution de licence effectuée avec succès",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Une erreur s’est produite pendant le traitement du fichier, veuillez réessayer",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Détails de licence chargés avec succès. Veuillez revenir à cet écran après un certain moment afin de vérifier le statut.",
      "MANAGE_NAME_CHANGE_ERROR":"Le nom ne peut pas être vide",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Consulter les offres)",
      "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Modifier la macro",
      "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Les produits ne peuvent pas être liés à cette offre",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Afficher uniquement la promotion",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Oui",
      "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Tapez un nom",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Copiez le Prix courant dans le prix de vente, le prix de vente sera identique au Prix courant",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Ajouter X% au Prix courant, le prix de vente sera X% du Prix courant + Prix courant",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Augmenter le prix de Y cents, le prix de vente sera le Prix courant + Y cents",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Effacer les prix de toutes les offres sélectionnées",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"Ajouter X% sur la marge, le prix de vente sera le Prix courant + (ERPPrice-Prix courant)*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"Ajouter X% au prix MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"le prix de vente sera X% du prix MS + prix MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"Copier le prix MS dans le prix de vente ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"prix de vente sera le même que le prix MS ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Ajouter X% sur la marge",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"le prix de vente sera le Prix MS + (ERPPrice-Prix MS)*X% ",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Augmenter le prix de Y cents",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"prix de vente sera Prix MS + Y cents ",
      "MANAGE_PLAN_LBL_1":"Copier le Prix courant dans le prix de vente",
      "MANAGE_PLAN_LBL_2":"Ajouter X% au Prix courant",
      "MANAGE_PLAN_LBL_3":"Augmenter le prix de Y cents",
      "MANAGE_PLAN_LBL_4":"Effacer tous les prix",
      "MANAGE_PLAN_LBL_5":"Ajouter X% sur la marge",
      "MANAGE_PLAN_LBL_6":"Copiez le prix ERP dans le prix de vente",
      "MANAGE_PLAN_MACRO_APPLIED":"Macro appliquée",
      "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"La macro nouvellement sélectionnée s'appliquera aux offres qui seront ajoutées au plan à l'avenir et n'affectera pas la macro existante sur les offres qui ont déjà été ajoutées.",
      "MANAGE_PLAN_NO_MACRO_APPLIED":"Aucune macro appliquée",
      "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"Non disponible à l'achat",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Fermer",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Offres incluses",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Fermer",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Nom",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Noms des parents",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer toutes les extensions ?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ce produit ?",
      "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}></b> est une offre parent pour un produit d'essai. <br/> La suppression de l'offre premium entraînera également la suppression de l'offre d'essai associée.<br/> Êtes-vous sûr de vouloir supprimer <b>{{productName}}<b/> ?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Les extensions sont ajoutées au plan, veuillez enregistrer le plan pour terminer votre opération.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"ajouté au plan !",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"ajouté au plan avec des extensions",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" ajouté au forfait avec une offre parentale",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Aucune extension correspondante trouvée",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Voulez-vous retirer l'offre?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"L'extension que vous essayez d'ajouter est déjà incluse",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Types de consommation",
      "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"L'offre de sentiers est-elle",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Statut de l'abonnement",
      "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Marchés pris en charge",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Veuillez vérifier les offres suivantes, car le Prix de vente est inférieur au Prix courant.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Cliquez sur « OK » pour soumettre les modifications du plan. « ANNULER » pour revérifier les offres du plan.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"L’offre sélectionnée était déjà incluse",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Veuillez fournir un pseudonyme et le prix effectif pour les offres incluses dans le plan",
      "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Veuillez vérifier les offres suivantes car le prix de vente est supérieur au prix ERP.",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Veuillez ajouter des offres au plan",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Ajouter plus d'extensions",
      "MANAGE_PONUMBER_CHANGE_ERROR":"Le PONumber ne peut pas être vide",
      "MANAGE_PRODUCT_OWNERSHIP":"Gérer la propriété du produit",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Échec de la gestion de la propriété du produit",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"La gestion de la propriété du produit n'est pas autorisée car une affectation de licence est en cours pour l'un des produits ou ses extensions. Veuillez essayer plus tard.",
      "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Nouveaux coupons ajoutés avec succès",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Une erreur s'est produite lors du traitement de votre demande",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Effacer les prix",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Rendre le prix MS égal au prix du revendeur – le prix du revendeur sera le même que le prix MS.",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"Ajouter X% au prix MS – Le prix du revendeur sera X% du prix MS + prix MS",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Augmenter le prix de Y centimes – le prix du revendeur sera MS Price + Y centimes",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Effacer les prix de toutes les offres sélectionnées",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"Ajouter X% à la marge - Le prix du revendeur sera le prix MS + (prix ERP- prix MS)*X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Si vous voulez ajouter les offres du revendeur, vous devez suivre les instructions suivantes",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Articles sélectionnés",
      "MANAGE_RELLER_OFFERS_LBL_1":"Rendre le prix MS égal au prix du revendeur",
      "MANAGE_RELLER_OFFERS_LBL_2":"Ajouter X% au prix MS ",
      "MANAGE_RELLER_OFFERS_LBL_3":"Augmenter le prix de Y cents",
      "MANAGE_RELLER_OFFERS_LBL_4":"Effacer tous les prix",
      "MANAGE_RELLER_OFFERS_LBL_5":"Ajouter X% sur la marge",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Voulez-vous supprimer toutes les offres filtrées des offres sélectionnées ?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Voulez-vous retirer toutes les offres sélectionnées?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Voulez-vous sélectionner toutes les offres filtrées ?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Voulez-vous sélectionner toutes les offres?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Valide de",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Type de licence",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Prix du revendeur",
      "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Si vous souhaitez réduire la quantité, veuillez contacter l'équipe d'assistance.",
      "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Retour aux renouvellements programmés",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Annuler",
      "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Créer un nouvel horaire",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Soumettre",
      "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Quantité cumulée",
      "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Abonnement actuel",
      "MANAGE_RENEWAL_DESCRIPTION_1":"Les modifications prennent effet à la date de renouvellement de l'abonnement : <b>{{renewalDate}}</b>. Pour les mises à niveau de SKU, si la quantité ne change pas, les licences seront attribuées automatiquement. Sinon, vous devrez attribuer manuellement des licences.",
      "MANAGE_RENEWAL_HEADER_TEXT":"Gérer le renouvellement",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Actuel : {{currentEnddate}}",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Choisissez parmi différents SKU :",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Choisissez un cycle de facturation différent :",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Choisissez un terme différent :",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Date de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Alignement des dates de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Type de date de fin",
      "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Planifier le produit",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Quantité",
      "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Choisissez un produit de plan éligible pour votre abonnement (utilisez cette option si vous souhaitez modifier le SKU/le cycle de facturation/la durée ainsi que la planification des modifications)",
      "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Terme",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Produit de plan précédemment sélectionné pour un renouvellement programmé pour un changement de SKU/Durée/cycle de facturation :",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Nom du produit du plan",
      "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Vous pouvez mettre fin à votre abonnement avec un abonnement OnlineServicesNCE sans essai existant ou aligner la date de fin sur le mois civil en choisissant une date de fin appropriée en fonction de la durée du terme.",
      "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Calendrier de mise à jour",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Offres",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Revendeurs",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Ajouter les offres revendeurs",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Offres",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Veuillez fournir un prix effectif pour les offres sélectionnées",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Offres sauvegardées avec succès",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Veuillez ajouter des offres au revendeur",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Toutes les offres sélectionnées. Si vous voulez modifier quelque chose lié à une offre de plan, vous devez passer par Modifier le plan !",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Liste des plans revendeurs",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Statut du plan mis à jour avec succès",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Affecter",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Désactiver",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Activer",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Nom",
      "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Marchés pris en charge",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Expire le",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Renouvellement automatique activé",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"ID Produit ",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Si le renouvellement automatique est désactivé, cet abonnement expire le",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":"Si le renouvellement automatique est désactivé, cet abonnement expire à la fin de la période de validité",
      "manageplans":"Gérer les plans",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"Voulez-vous supprimer le groupe de ressources ?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Mandater le client pour mettre à jour les détails de paiement.",
      "MANUAL_SYNC_PUPUP_TITLE":"Synchronisation manuelle",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Correctif appliqué avec succès",
      "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Veuillez sélectionner un accord PSA",
      "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Veuillez sélectionner un contrat",
      "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Veuillez sélectionner un client",
      "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Veuillez sélectionner un client PSA",
      "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Veuillez sélectionner un produit PSA",
      "MARCH":"Mars",
      "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer cette adresse par défaut ?",
      "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer cet e-mail comme e-mail par défaut ?",
      "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"Êtes-vous sûr de vouloir marquer ce numéro de téléphone comme numéro par défaut ?",
      "Markup":"Majoration",
      "MATCHING_PRODUCT_PLAN_TEXT":"Plan:",
      "MAY":"Mai",
      "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Création de paiement récurrent. Vous pouvez voir une charge de 1,00 $ sur votre carte qui sera ajustée en crédit sur votre prochaine facture.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"La banque du client (Émetteur de la carte) a refusé la transaction. Le client doit contacter sa banque et réessayer la transaction.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"La banque du client a refusé la transaction et demande au marchand de contacter la banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"La transaction a réussi.",
      "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"La transaction a réussi.",
      "MCB_ERROR_CODE_APPROVED_VIP":"La transaction a réussi. (Ce code n'est pas utilisé en Australie.)",
      "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"La banque du client (Émetteur de la carte) a refusé la transaction car elle n'autorise pas les transactions provenant de commandes par courrier/téléphone, fax, e-mail ou Internet. Cette erreur est associée aux clients qui tentent d'utiliser une Discover Card. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"La banque du client a refusé la transaction et demande au client de contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CAPTURE_CARD":"La banque du client a refusé la transaction car la carte est suspectée d'être contrefaite. La banque du client (Émetteur de la carte) a demandé que la carte de crédit de votre client soit conservée. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit du client soit conservée (carte déclarée perdue ou volée). Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"La banque du client ne peut pas être contactée pour autoriser la transaction. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"La banque du client n'est temporairement pas en mesure de traiter la carte de crédit de ce client. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"La banque du client a refusé la transaction car le CVV est incorrect. Le client doit vérifier les détails du CVV et réessayer. En cas d'échec, le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_DO_NOT_HONOUR":"La banque du client a refusé la transaction car le numéro de carte de crédit a échoué à un contrôle de sécurité ou les fonds ont été gelés ou épuisés. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"La banque du client a refusé la transaction car cette transaction semble être une transmission en double. Aucune action nécessaire.",
      "MCB_ERROR_CODE_ERROR":"La banque du client (Émetteur de la carte) a refusé la transaction car il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"La banque du client a refusé la transaction car le client a dépassé la limite de fréquence de retrait. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"La banque du client a refusé la transaction car elle dépassera la limite de la carte du client. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXPIRED_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit semble avoir expiré. Le client doit vérifier la date d'expiration saisie et réessayer, ou utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit a expiré ou la date est incorrecte. Vérifiez la date d'expiration dans la transaction et essayez de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_FORMAT_ERROR":"La banque du client (Émetteur de la carte) ne reconnaît pas les détails de la transaction. Le client doit vérifier les informations sur la transaction et réessayer de traiter la transaction. Généralement dû à un TID incorrect.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"La banque du Client a refusé la transaction car cette carte de crédit ne peut pas être utilisée pour ce type de transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"La banque du Client a refusé la transaction car cette carte de crédit ne peut pas être utilisée pour ce type de transaction. Celui-ci peut être associé à un numéro de carte de crédit de test. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"La banque du client (Émetteur de la carte) a refusé la transaction car elle n'autorise pas ce type de transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"La transaction a réussi. Certaines banques utilisent ce code au lieu de '00'.",
      "MCB_ERROR_CODE_INCORRECT_PIN":"La banque du client (Émetteur de la carte) a refusé la transaction car le client a saisi un code PIN incorrect. Le client doit ressaisir son NIP, sinon utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte de crédit ne dispose pas de fonds suffisants. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_INVALID_AMOUNT":"Un caractère non valide (par exemple, un signe dollar ou un espace) peut être transmis à la passerelle. Vérifiez le code de votre site Web.",
      "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit n'existe pas. Vérifiez les informations de la carte de crédit et réessayez de traiter la transaction.",
      "MCB_ERROR_CODE_INVALID_TRANSACTION":"La banque du client (Émetteur de la carte) a refusé la transaction en raison d'un format ou d'un champ non valide. Vérifiez les informations de transaction et essayez de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_LOST_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte a été déclarée perdue. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CARD_RECORD":"La banque du client a refusé la transaction car le numéro de carte de crédit n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit est associé à un compte chèque qui n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"La banque du client a refusé la transaction car le numéro de carte de crédit utilisé n'est pas un compte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le type de compte sélectionné n'est pas valide pour ce numéro de carte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_ISSUER":"La banque du client (Émetteur de la carte) n'existe pas. Vérifiez les informations de la carte de crédit et réessayez de traiter la transaction.",
      "MCB_ERROR_CODE_NO_MERCHANT":"L'identifiant marchand n'est pas valide, vous devez contacter votre banque et vous assurer que vous avez fourni le bon numéro de compte marchand.",
      "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le numéro de carte de crédit est associé à un compte d'épargne qui n'existe pas. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"La banque du client (Émetteur de la carte) a refusé la transaction car le type de compte sélectionné n'est pas valide pour ce numéro de carte de crédit. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"La banque du client a refusé la transaction en raison du montant à traiter. Le client doit vérifier le montant de la transaction et réessayer.",
      "MCB_ERROR_CODE_PICK_UP_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée). Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit de votre client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"La banque du client a refusé la transaction, car le client a saisi le code PIN incorrect plus de trois fois. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"La banque du client (Émetteur de la carte) a refusé la transaction car le client a saisi trois fois un code PIN incorrect. La banque du client (émetteur de la carte) vous a demandé de conserver la carte de crédit. Le client doit utiliser une autre carte de crédit et contacter sa banque.",
      "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"La transaction n'a pas été traitée et le client doit tenter de traiter à nouveau la transaction.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"La banque du client (Émetteur de la carte) a indiqué qu'il y a un problème avec le numéro de carte de crédit. Le client doit contacter sa banque. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_RESTRICTED_CARD":"La banque du client a refusé la transaction car la carte de crédit a certaines restrictions. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction et a demandé que la carte de crédit du client soit conservée. (carte déclarée perdue ou volée) Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_SECURITY_VIOLATION":"La banque du client a refusé la transaction. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_STOLEN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction car la carte a été déclarée volée. Le client doit utiliser une autre carte de crédit.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD":"La banque du client a refusé cette transaction car la carte de crédit semble frauduleuse.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"La banque du client (Émetteur de la carte) a refusé la transaction en raison d'une suspicion de fraude sur ce numéro de carte de crédit.",
      "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"La banque du client (Émetteur de la carte) ne peut pas être contactée pendant la transaction. Le client doit vérifier les informations de sa carte de crédit et réessayer de traiter la transaction.",
      "MCB_ERROR_CODE_SYSTEM_ERROR":"La banque du client n'a pas été en mesure de traiter la transaction. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_TRANSACTION_APPROVED":"La transaction a réussi.",
      "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"La banque du client (Émetteur de la carte) ne reconnaît pas les détails de la carte de crédit. Le client doit vérifier les informations de sa carte de crédit et réessayer de traiter la transaction.",
      "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"La banque du client est introuvable pour l'acheminement. Ce code de réponse est souvent renvoyé lorsque le client utilise un numéro de carte de crédit de test. Le client doit tenter de traiter à nouveau cette transaction.",
      "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Une erreur non spécifiée s'est produite.",
      "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Numéro de carte invalide ou manquant.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Mois d'expiration invalide ou manquant.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Année d'expiration invalide ou manquante.",
      "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Code de sécurité invalide.",
      "MEDIUM":"Moyen",
      "MENU_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "MENU_ADMINISTRATION":"Administration",
      "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notifications personnalisées",
      "MENU_ADMINISTRATION_ENGAGE":"Engagez la <sup class='text-warning'> bêta</sup>",
      "MENU_ADMINISTRATION_MANAGE":"Notifications de bannière",
      "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Notifications de bannière",
      "MENU_ADMINISTRATION_MANAGE_HEADING":"Administration",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Notifications",
      "MENU_ADMINISTRATION_WEBHOOK":"Webhook",
      "MENU_AZURE_ADVISOR":"Conseiller Azure",
      "MENU_AZURE_BILLING_REPORT":"Rapport de facturation Azure",
      "MENU_AZURE_CUSTOM_POLICY":"Politiques personnalisées",
      "MENU_AZURE_CUSTOM_ROLE":"Rôles personnalisés",
      "MENU_AZURE_MARGIN":"Soutien C3",
      "MENU_AZURE_SUBACRIPTION":"Abonnement Azure",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft",
      "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Commissions",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Attributs du produit",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Détails des citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Liste des citations",
      "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Utilisation du rapport",
      "MENU_CONTACT_LOG":"Notifications",
      "MENU_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "MENU_CUSTOMER_ANALYTICS":"Analytique client",
      "MENU_CUSTOMER_COMMENTS":"Commentaires",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consommation de licence",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Rapport Power BI",
      "MENU_CUSTOMER_PROFILE":"Profil",
      "MENU_DASHBOARD":"Tableau de bord",
      "MENU_EXISTING_ACCOUNT_MANAGERS":"Gestionnaires existants",
      "MENU_HELP":"Aide",
      "MENU_LICENSE_CHANGE":"Rapport de changement de licence",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Rapport récapitulatif de consommation de licence",
      "MENU_LICENSE_SUMMARY_REPORT":"Rapport récapitulatif de licence",
      "MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer les droits Azure",
      "MENU_MANAGE_Azure_ENTITLEMENTS":"Gérer les droits d'Azure",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Approvisionnement des offres personnalisées ",
      "MENU_ONBOARDED_CUSTOMERS_REPORT":"Analyse d'intégration",
      "MENU_PARTNER_AUDIT_LOG":"Journal d'audits",
      "MENU_PARTNER_COMMISSION_REPORT":"Rapport de commission",
      "MENU_PARTNER_COUPON":"Coupons",
      "MENU_PARTNER_DOCUMENTATION":"Documentation",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "MENU_PARTNER_SUPPORT_LOG":"Soutien",
      "MENU_PARTNER_UPLOAD":"Charger",
      "MENU_PENDING_PURCHASE_REQUESTS":"Approbations",
      "MENU_PRODUCT_COMMISSIONS":"Commissions",
      "MENU_PRODUCT_EXTENSIONS":"Attributs du produit",
      "MENU_PRODUCT_SEQUENCE":"Séquence de produit",
      "MENU_PURCHSED_PRODUCT_MAPPING":"Mappage des produits achetés",
      "MENU_RENEWAL_MANAGER":"Responsable du renouvellement",
      "MENU_REPORT_USAGE":"Utilisation",
      "MENU_REPORTS_FOR_CUSTOMER":"Rapports",
      "MENU_RESELLER_PLAN":"Plan de revendeur",
      "MENU_RESELLER_PROFILE":"Profil d'entreprise",
      "MENU_SCHEDULED_REPORTS":"Rapports planifiés",
      "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Destinataires pour",
      "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Exécuter l'historique pour",
      "MENU_SETTINGS":"Paramètres",
      "MENU_TOPBAR_SETTINGS":"Paramètres",
      "MENU_USAGE_REPORT":"Rapport d'utilisation",
      "MENU_USERS":"Gérer les accès",
      "MENUS_ANALYTICS":"Analyses",
      "MENUS_ANALYZE":"Analyser",
      "MENUS_AUDITLOGS":"Journal d'audit ",
      "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Gérer les tarifs des niveaux d'autorisation Azure",
      "MENUS_AZURE_ESTIMATE":"Estimation Azure",
      "MENUS_AZURE_GROUPS":"Groupes Azure ",
      "MENUS_AZURE_MANGE":"Gestion d'Azure ",
      "MENUS_AZURE_REPORTS":"Rapports Azure ",
      "MENUS_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "MENUS_CART":"Panier",
      "MENUS_COUPONS":"Coupons",
      "MENUS_CUSTOM_OFFERS":"Offres partenaires",
      "MENUS_CUSTOMER_CART":"Panier",
      "MENUS_CUSTOMER_DASHBOARD":"Tableau de bord",
      "MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Utilisateurs",
      "MENUS_CUSTOMER_ONBOARDING":"Intégration de client ",
      "MENUS_CUSTOMER_ORDERS":"Commandes",
      "MENUS_CUSTOMER_PRODUCTS":"Produits",
      "MENUS_CUSTOMER_PROFILE":"Profil",
      "MENUS_CUSTOMER_SHOP":"Magasin",
      "MENUS_CUSTOMERS":"Clients",
      "MENUS_DAAS":"DaaS<sup class='text-danger'>BÊTA</sup>",
      "MENUS_DASHBOARD":"Tableau de bord",
      "MENUS_DISTRIBUTOR_OFFERS":"Offres des distributeurs",
      "MENUS_DOCUMENTATION":"Documentation<sup class='text-danger'>BETA</sup>",
      "MENUS_ENTITY_INVOICES":"Factures",
      "MENUS_FAQ":"FAQ",
      "MENUS_INVITES":"Invitations",
      "MENUS_INVOICES":"Factures",
      "MENUS_LICENSE_CONSUMPTION":"Consommation de licence",
      "MENUS_LICENSE_LIST":"Liste des licences",
      "MENUS_MS_OFFERS":"Offres MS",
      "MENUS_OPERATING_ENTITIES":"Entités opérationnelles",
      "MENUS_ORDERS":"Commandes",
      "MENUS_PARTNER_BUNDLES":"Forfaits",
      "MENUS_PARTNER_CUSTOMERS":"Clients",
      "MENUS_PARTNER_DASHBOARD":"Tableau de bord",
      "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Statut de paiement en attente",
      "MENUS_PARTNER_PLANS":"Plans",
      "MENUS_PARTNER_QUOTES":"Citation",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_PARTNER_SETTINGS":"Paramètres",
      "MENUS_PLANS":"Plans",
      "MENUS_POWER_BI_REPORTS":"Rapports Power BI",
      "MENUS_PRODUCTS":"Produits",
      "MENUS_PROFILE":"Profil",
      "MENUS_RESELLER_DASHBOARD":"Tableau de bord",
      "MENUS_RESELLER_INVOICES":"Facturation",
      "MENUS_RESELLER_PAYMENTS":"Paiements",
      "MENUS_RESELLER_PLANS":"Plans",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Prix et offres",
      "MENUS_RESELLER_PROFILE":"Profil d'entreprise",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_RESELLER_SETTINGS":"Paramètres",
      "MENUS_RESELLERS":"Revendeurs",
      "MENUS_REVENUE_AND_COST_SUMMARY":"Entreprise",
      "MENUS_SELL":"Vendre",
      "MENUS_SELL_DIRECT":"Vente directe",
      "MENUS_SELL_INDIRECT":"Vente indirect",
      "MENUS_SELLER_INDIRECT":"Vente indirect",
      "MENUS_SHOP":"Magasin",
      "MENUS_SUBSCRIPTIONS":"Abonnements",
      "MENUS_SUPPORT":"Soutien",
      "MENUS_TAXES":"Taxes",
      "MENUS_USER_MANAGEMENT":"Gestion des utilisateurs",
      "MENUS_USERS":"Utilisateurs",
      "merge":"bifurquer",
      "MESSAGE_ALREADY_EXISTING":"Le message existe déjà pour les entités concernées",
      "MESSAGE_BODY":"Corps du message",
      "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Veuillez vérifier tous les champs obligatoires et réessayer de soumettre",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Pour l'entité Variante de produit/Client, la même combinaison ne peut pas être réinsérée.",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Veuillez sélectionner tous les champs",
      "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND":"Aucun enregistrement trouvé!",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"Aucune donnée à présenter, soit en raison de l'absence d'utilisation pendant la période de facturation en cours ou car la facture de Microsoft n'est pas encore disponible. Veuillez vérifier de nouveau sous peu.",
      "MESSAGE_NOTIFCATION_SAVED":"Notification de message enregistrée avec succès",
      "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Veuillez résoudre toutes les erreurs de validation et réessayer de soumettre",
      "MESSAGE_NOTIFICATION_EDIT_MODE":"Modifier les notifications de messages",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Modifier",
      "MESSAGE_NOTIFICATION_ID":"Identifiant",
      "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Veuillez sélectionner au moins un destinataire pour la notification de message",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Actions",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Date effective",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Expire le",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Corps du message",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Style des messages",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Nom de la page",
      "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Entités impliquées",
      "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Un produit a été sélectionné",
      "MESSAGE_RECORDS_POP_UP":"liste d'enregistrements",
      "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Une erreur est survenue",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"Aucun enregistrement trouvé !",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Désolé, vous n'avez pas de sites et de départements configurés. Vous pouvez ajouter de nouvelles entités opérationnelles à partir de la page Profil",
      "MESSAGE_TEXT_PLEASE_WAIT":"Veuillez patienter...",
      "MESSAGE_TOOLTIP_AZUREGROUP":"Info, Avertir, Erreur, Danger",
      "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Sélectionnez le type de message",
      "MESSAGE_TYPE_NAME":"Nom du type de message",
      "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Veuillez sélectionner tous les champs",
      "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"URL invalide. Veuillez saisir une URL valide.",
      "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Veuillez vérifier tous les champs obligatoires et réessayer de soumettre",
      "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"L'URL est valide !",
      "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" Les valeurs décimales ne sont pas autorisées.",
      "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Nouveau niveau",
      "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Entrez un numéro valide.",
      "METERED_BILLING_TEXT_MODAL_HEADER":"Niveaux d'utilisation",
      "METTRE À JOUR LE PROFIL":"Profil mis à jour avec succès",
      "Microsoft":"Microsoft",
      "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Type d'attestation :",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Supprimer",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Catégorie",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Rabais",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Supprimer",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Catégorie",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabais",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Majoration",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Pourcentage",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Produit lié",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA accepté par",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA accepté le",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Statut d'acceptation",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"Non fourni",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Fourni",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Acceptation du client confirmée par le partenaire",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Acceptation du client via Microsoft",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Contrat client Microsoft enregistré avec succès.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Mot de passe temporaire",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nom d'utilisateur",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"L'utilisateur a été créé avec succès et ci-dessous se trouvent les informations d'identification de l'utilisateur.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Détails de l'utilisateur",
      "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Après avoir fermé cette fenêtre contextuelle, vous ne pourrez plus voir ce mot de passe temporaire.",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"Échec de la capture du consentement de l'API de tarification Microsoft",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Le consentement de l'API de tarification Microsoft a été capturé avec succès",
      "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"La tentative d'accès à l'API de tarification Microsoft a réussi.",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} de {{applicationName}} souhaite être votre fournisseur de solutions Microsoft Cloud pour votre compte Microsoft Cloud.<br/><br/>Pour accepter l'invitation, procédez comme suit :<br/><br/>Étape 1 : Vérifiez et assurez-vous que le profil client est complet ici : https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Étape 2 : Une fois le profil client complété (l'actualisation peut prendre jusqu'à 5 minutes après la mise à jour du profil), cliquez sur le lien suivant pour accepter cette invitation et autoriser {{applicationName}} à être votre fournisseur de solutions Microsoft Cloud et accepter le client Microsoft Accord.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Remarque : un utilisateur disposant de l'autorisation d'administrateur général est requis pour accepter la relation.<br/><br/><br/>Informations supplémentaires sur le partenaire :<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Cher client,<br/><br/>Pour vous fournir une gamme complète de solutions, de services et d'assistance cloud, {{resellerName}} travaille en étroite collaboration avec {{partnerCompanyName}}. Pour autoriser {{resellerName}} et {{partnerCompanyName}} à gérer votre compte, procédez comme suit : <br/><br/>Étape 1 : vérifiez et assurez-vous que le profil client est complet ici : https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/> Étape 2 : une fois le profil client complet (l'actualisation peut prendre jusqu'à 5 minutes après la mise à jour du profil), cliquez sur le lien suivant pour accepter cette option invitation.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Remarque : un utilisateur disposant d'une autorisation d'administrateur global est requis pour accepter la relation.<br/><br/>{{resellerName}} continuera d'être votre expert local de référence pour les produits, les abonnements, les licences et l'assistance. Soyez assuré que sa relation commerciale avec vous reste la même.<br/><br/>N'hésitez pas à nous contacter si vous avez des questions. Nous et {{resellerName}} sommes impatients de travailler avec vous !<br/><br/>Cordialement,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marge pour l'abonnement au logiciel",
      "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration pour l'abonnement tiers",
      "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Toutes les licences existantes et l'accès au portail seront supprimés. Êtes-vous sûr de vouloir continuer ?",
      "MicrosoftNonCSP":"Microsoft non CSP",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Compte de migration créé avec succès avec le username :",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Comptes de migration",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Client",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Compte de migration",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Créer",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Comptes de migration",
      "MIGRATION_USER_CREATE_SUCCESS":"Compte de migration créé avec succès avec le  {{username}}",
      "MINUTES":"Minute(s) ",
      "MISSING_PLAN_OFFER_ADD_FAILED":"Le rapprochement a échoué, veuillez contacter le support pour obtenir de l'aide",
      "MISSING_PLAN_OFFER_ADDED":"Rapprochement complété en utilisant l'offre choisie",
      "MNUAL_SYNC_PURCHASED_DATE":" Date d'achat :",
      "MODAL_HEADER_TEXT_DESC_FILTER":"Filtrer",
      "Month(s)":"Mois",
      "MULTIPLE_COMMENT_STATIC":"Commentaires",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Sélectionner",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"Importation terminée !<br />Total : {{ importProcessedCount }}<br />Erreurs : {{ importErrorCount }><br />Succès : {{ importSuccessCount }}",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"Validation terminée !<br />Total : {{ validationProcessedCount }}<br />Erreurs : {{ validationErrorCount }><br />Succès : {{ validationSuccessCount }}",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Instructions",
      "Name":"Nom",
      "NAME":"Nom de l'entité",
      "NCE_ACRONYM":"Services en ligne NCE",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Politique d'annulation",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 heures : Remboursement complet",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 heures : 1er jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 heures : 2e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 heures : 3e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 heures : 4e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 heures : 5e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 heures : 6e jour proratisé",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Après 7 jours calendaires : l'annulation n'est pas autorisée",
      "NCE_INSTRUCTIONS_HEADING":"Instructions RCE",
      "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Prix de vente estimé du produit lié",
      "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>Ce produit suivant n'est pas éligible à la promotion. Veuillez contacter l'assistance pour appliquer la promotion. si vous ne voulez pas de promotion, continuez votre processus de commande.</p>",
      "NCE_PROMOTIONS_APPLIED":"Cette promotion est appliquée",
      "NCE_PROMOTIONS_AVAILABLE_TEXT":"Promotion disponible",
      "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Vérifier l'admissibilité à la promotion",
      "NCE_PROMOTIONS_NOT_APPLIED":"La promotion n'est pas encore appliquée",
      "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"Promotions disponibles",
      "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Fréquence de facturation",
      "NCE_PROMOTIONS_POPUP_CANCEL":"Annuler",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Fermer",
      "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"L'éligibilité du client sera validée lors de l'examen du panier",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"Rabais",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Date de fin",
      "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Remise promotionnelle pour le lancement de la nouvelle commeNCE",
      "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Détails de la promotion",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":"ID:",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":"Nom:",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Appliquer",
      "NCE_PROMOTIONS_POPUP_TERM":"Terme",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"La quantité/sièges peut être augmentée à tout moment.",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"La quantité/sièges peut être réduite dans les 7 jours calendaires, à sa précédente ou à la somme des quantités/sièges précédents.",
      "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Changement de quantité",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Modification du calendrier de renouvellement",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Le calendrier actuel sera supprimé si la quantité/le statut de renouvellement automatique/le cycle de facturation/la durée sont modifiés.",
      "NCE_SKU_NOT_PRESENT_IN_PLAN":"Produit non disponible dans le plan client",
      "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Veuillez accepter tous les termes et conditions",
      "NCE_TEXT":"Nouveau commeNCE...",
      "NEW_BILLING_CYCLE":"Nouveau cycle de facturation",
      "NEW_COMMENT_SEND":"Envoyer",
      "NEW_COMMENTS_RECIEVED":"Nouveau commentaire reçu",
      "NEW_CUSTOMER_ONBOARDING_ERRORS":"l'achat/la mise à jour des produits peut échouer",
      "NEW_DISTRIBUTOR_PRODUCT_ICON":"Nouvelle icône",
      "NEW_PARTNER_PRODUCT_ICON":"Nouvelle icône",
      "NEW_PRODUCT_STATUS":"Nouveau statut",
      "NewPrice":"NouveauPrix",
      "NEXT_SCHEDULE_RENEWAL_CHANGE":"Prochain changement de renouvellement d'horaire",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Recherche...",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Aucun article n'existe avec l'identifiant : {cartLineItemId} dans le panier",
      "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"Aucun administrateur client n’est disponible pour ce locataire.",
      "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Aucun client enregistré",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Aucun plan existant",
      "NO_PAYMENT_TYPES_ENABLED":"Aucun type de paiement n'est activé. Veuillez contacter le soutien technique.",
      "NO_PLAN_WITH_OFFER_AVAILABLE":"Il n'y a pas de plan avec toutes les offres disponibles. Veuillez en créer un",
      "NO_PRODUCT_SELECTED_PROMPT":"Veuillez sélectionner au moins un produit.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Aucun détail de facture n'a été trouvé pour la période de facturation ou le fournisseur sélectionné",
      "NO_RESOURCE_GROUP_LEFT":"Aucun groupe de ressources à affecter/aucun enregistrement trouvé !",
      "NOM":"Nom de l'entité",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Détails de produit non CSP",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Identifiant principal de service",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Clé principale de service",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"L'utilisateur du revendeur ne peut pas ajouter de produit partenaire sous le plan du revendeur.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Les produits fournisseurs suivants ne sont pas mappés avec les produits du plan. Si vous voulez continuer, cliquez sur Continuer, sinon cliquez sur Annuler et mapper ces produits",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Produits de fournisseur",
      "NOT_VALID_MESSAGE":"n'est pas un email valide",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Créer une bannière de notification",
      "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"L'étiquette client a été supprimée avec succès",
      "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"Le nom de l'abonnement a été modifié avec succès",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Cette valeur sera mise à jour sous peu",
      "notifications":"Notification",
      "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"La longueur des caractères doit être comprise entre 2 et 200 caractères",
      "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"La longueur des caractères doit être comprise entre 2 et 50 caractères",
      "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Date de fin personnalisée enregistrée avec succès",
      "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"URL du webhook non valide",
      "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"URL de webhook valide",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Entrez une adresse courriel et cliquez sur OK pour recevoir un fichier zip contenant une liste de factures PDF pour la période de facturation sélectionnée",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"Un courriel avec une liste de factures en format PDF (Zip) en pièce jointe sera envoyé à {{emailAddress}} sous peu.",
      "NOVEMBER":"Novembre",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
      "NUMBER_OF_PROMOTIONS_LOADED":"Nombre de promotions chargées",
      "NUMÉRO DE COMMANDE":"Numéro de commande",
      "NUMÉRO DE FACTURE":"Numéro de facture",
      "OCTOBER":"Octobre",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Le pseudonyme de l'offre {{FriendlyName}} doit être unique",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Afficher les offres avec abonnement acheté",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"offreAvecAbonnementAcheté",
      "offers":"Offres",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Ajouter abonnements",
      "OFFERS_HEADER_TEXT_OFFERS":"Offres",
      "OFFERS_NO_MATCH_MESSAGE":"Aucun résultat correspondant trouvée pour le nom : ",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Articles sélectionnés",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Pourcentage d'escompte",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Quantité (No. de licences)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Taux par droit (Par mois)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Total",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Montant total",
      "OLD_BILLING_CYCLE":"Ancien cycle de facturation",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Échec de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Initiation de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Réussite de l'intégration d'un client lors d'une intégration massive",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Correspondance trouvée pour l'identifiant Id : <strong> {{billingProviderReferenceID}} </strong> dans le système de facturation.<br/><span> L'adresse de facturation dans le fichier est <strong> {{formattedAddress}} </strong>.</span> <br/>Veuillez personnifier l'identité et utiliser l'option <strong>Profile</strong> pour modifier ce détail une fois que les détails de facturation du client ont été intégrés avec succès.",
      "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}.",
      "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous ne devez pas ignorer {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez inclure {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si {{firstProduct}} doit être mappé, vous devez également mapper {{secondProduct}}, c'est-à-dire ; La quantité minimale doit être 1.",
      "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"La quantité du fournisseur et la quantité partagée doivent correspondre pour {{product}}",
      "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"La quantité doit être égale à {{firstProduct}} et {{secondProduct}}",
      "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Sélectionner le client CSP à intégrer",
      "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Détails du compte principal",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":"Remplir",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Télécharger un modèle .csv",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":"en ajoutant des lignes après l'en-tête. Chaque ligne doit correspondre au détail d'un revendeur.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"Le traitement prendrait un certain temps et le statut sera signalé sur cette page. Si vous vous éloignez de la page ou avez fermé le navigateur par accident, vous pourriez revenir à cette page et le système afficherait l'état de votre téléchargement précédent.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Cliquez sur Sélectionner et accédez au fichier que vous souhaitez télécharger.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"L'entrée est validée pour s'assurer que les règles suivantes sont respectées :",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Le nom du revendeur est obligatoire et ne doit pas dépasser 50 caractères.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"L'ID de revendeur est obligatoire et ne doit pas dépasser 50 caractères. Il doit s'agir du GUID de relation du revendeur du \"Partner Center\"",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"L'identifiant MPN du revendeur est obligatoire et ne doit pas dépasser 50 caractères. Il doit s'agir de l'ID \"Microsoft Partner Network\" du revendeur.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"L'emplacement est obligatoire et doit correspondre au code pays à deux lettres du revendeur. Par exemple : États-Unis, Royaume-Uni, etc.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"L'e-mail est facultatif mais doit être une adresse e-mail valide si vous en saisissez une.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Cliquez sur <strong class=\"text-uppercase\">soumettre</strong> pour que les données soient traitées.",
      "ONBOARDING":"Intégration",
      "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Télécharger le rapport",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Sélectionner le type de client",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Sélectionner l'unité de durée",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Fermer",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Revendeurs",
      "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Sélectionner un revendeur",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Réinitialiser",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Rechercher",
      "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"Type de client",
      "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Unité de durée",
      "ONBOARDING_DURATION_UNIT_MONTH":"Mois",
      "ONBOARDING_DURATION_UNIT_WEEK":"Semaine",
      "ONBOARDING_DURATION_UNIT_YEAR":"Année",
      "ONBOARDING_GRAPH_COUNT_TEXT":" Compter:",
      "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"L'intégration des clients n'est pas autorisée car il n'y a aucun lien avec le revendeur fournisseur. Veuillez contacter votre Partenaire.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"L'intégration d'un client MicrosoftNonCSP n'est pas prise en charge. Veuillez lier le client à un client C3 existant.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"L'intégration d'un client MicrosoftNonCSP n'est pas prise en charge !",
      "ONBOARDING_STATUS":"Statut d'intégration",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Mappage des produits du client fournisseur avec les produits du plan...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Chargement des plans...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Chargement des clients fournisseurs...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Recherche des détails du client dans C3...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Tous les clients",
      "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Clients directs",
      "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Revendeur clients",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Êtes-vous sûr de vouloir supprimer ?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Sélectionnez un produit dans la liste ou recherchez par nom de produit",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"La demande de suppression de licences des utilisateurs est acceptée et en cours de traitement.",
      "OnlineServicesNCE":"Service en ligne NCE",
      "Open":"Ouvrir",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Nom du plan",
      "OPERATING_ENTITY_CUSTOMER":"Client",
      "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Configuration de l'organisation",
      "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Sites & Départements",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Affecter",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Libérer",
      "ORDER_BACK_TO_ORDER_DETAILS":"Retour aux produits",
      "ORDER_DETAILS_ENTITY_NAME":"Nom de l'entité",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"Commandé le",
      "ORDER_DETAILS_SCHEDULED_DATE":"Date prévue",
      "ORDER_ID":"Identifiant de commande",
      "ORDER_LBL_TEXT_PROMOTIONID":"PromotionId",
      "ORDER_LIST_STATUS_SUCCESS":"Réussite ",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Approuvé pour examen par le client",
      "ORDER_STATUS_APPROVAL_REQUIRED":"Approbation exigée",
      "ORDER_STATUS_COMMENTS":"Voir les commentaires",
      "ORDER_STATUS_DELETED":"Supprimé",
      "ORDER_STATUS_DESC_ACCEPTED":"Accepté",
      "ORDER_STATUS_DESC_FAILED":"Échoué",
      "ORDER_STATUS_DESC_IGNORE":"Ignorer",
      "ORDER_STATUS_DESC_INPROGRESS":"InProgress",
      "ORDER_STATUS_DESC_ONHOLD":"En attente",
      "ORDER_STATUS_DESC_ORDERED":"Commandé",
      "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Partiellement réalisé",
      "ORDER_STATUS_DESC_PROCESSING":"Traitement",
      "ORDER_STATUS_DESC_PROVISIONING":"Approvisionnement",
      "ORDER_STATUS_DESC_REJECTED":"Rejeté",
      "ORDER_STATUS_DESC_SCHEDULED":"Programmé",
      "ORDER_STATUS_DESC_SUCCESS":"Réussite ",
      "ORDER_STATUS_ERRORS_IGNORED":"Échec <br/>(erreurs examinées et ignorées)",
      "ORDER_STATUS_FAILED":"Échoué",
      "ORDER_STATUS_INPROGRESS":"En cours de résolution",
      "ORDER_STATUS_ONHOLD":"En attente",
      "ORDER_STATUS_ORDERED":"Commandé",
      "ORDER_STATUS_PROCESSING":"En cours de résolution",
      "ORDER_STATUS_PROVISIONING":"En cours de résolution",
      "ORDER_STATUS_REACTIVATED":"Réactivé",
      "ORDER_STATUS_REJECTED":"Rejeté",
      "ORDER_STATUS_SCHEDULED":"Programmé",
      "ORDER_STATUS_SUCCESS":"Commande exécutée",
      "ORDER_STATUS_SUSPENDED":"Suspendu",
      "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Devis approuvé et commande passée avec succès.<br/> L'identifiant de commande est <b>{{OrderNumber}}</b>",
      "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Devis approuvé avec succès.",
      "OWNER_ENTITY_NAME":"Nom de l'entité propriétaire",
      "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Détails de l'état du paiement",
      "PaidAmount":"Montant payé",
      "PARENT_ENTITY_NAME":"Nom de l'entité parente",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"L'extension ne peut pas être activée si l'offre parent n'est pas active.",
      "PARENT_PRODUCT_NOT_EXISTS":"Impossible d'intégrer un produit sans parent !",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Il n'y a pas de clés disponibles pour ce fournisseur",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Il n'y a pas de fournisseurs configurés, veuillez contacter le soutien technique",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Enregistrer vos modifications",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Description",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Devise d'affichage",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Devise de facturation",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valeur de la macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Nom",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Devise d'achat",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Sélectionner",
      "partner":"Partenaire",
      "Partner":"Partenaire",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Affecter",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Dissocier",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Retour aux gestionnaires de comptes",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Clients du gestionnaire de compte",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Gestionnaire de compte clients <span class='text-primary'> {{FirstName}} </span>",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Actions",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nom",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"C3Id",
      "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Nom du revendeur",
      "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Mettez à jour tous les forfaits avec les dernières offres",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Aucune extension n'a été trouvée",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Supprimer un plan",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Consulter les offres de plan",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Enregistrer le plan",
      "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Pour l'offre d'essai, aucune offre parent n'a été trouvée. Incluez l'offre parentale et réessayez",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Quantité",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Utilisation",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Entrez la date d'effet à partir de",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Entrez la date d'entrée en vigueur pour",
      "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Entrez le message",
      "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Sélectionnez le type de message",
      "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Sélectionnez le portail de la page",
      "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Icônes-de-Stockholm / Communication / Chat de groupe",
      "PARTNER_APPROVAL_PENDING":" Approbation du partenaire -",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"ajouté au forfait",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"ajouté au forfait avec des extensions",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"est déjà ajouté au forfait et ne peut pas être ajouté à nouveau",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filtres",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Réinitialiser",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Rechercher",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Valide",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Durée du mandat",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Durée du mandat",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Types de facturation",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Coût d'acquisition (de)",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Coût d'acquisition (à)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Prix de vente (à partir de)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Prix de vente (à)",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Données de bloc",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":"- à - ",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Type de validité",
      "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Inclure les bundles supprimés",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Veuillez sélectionner au moins un produit.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Veuillez sélectionner au moins 2 offres à regrouper",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Veuillez fournir le prix de vente",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé !",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Produits groupés",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Forfaits",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Ajouter",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Nouveau forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Retour aux détails du forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Annuler",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Supprimer",
      "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Télécharger les packs",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Modifier",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Recherche appliquée : ",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Suivant",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Examiner les produits groupés",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Enregistrer",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Enregistrer le forfait",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Afficher les offres",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Retour aux forfaits partenaires",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Ajouter un forfait",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Afficher le forfait",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Prix suggéré :",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Est renouvelable automatiquement ?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Type de facturation",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Disponible pour le regroupement ?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Période des frais",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Période des frais",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Type de consommation",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Texte d'aide",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Coût d'acquisition",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Description",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Fournir un fichier ",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Disponible pour la consommation immédiate ?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Nom",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nombre de jours pour l'annulation gratuite",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Règle de facturation pour l'achat de licences",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Règle de facturation pour la réduction des licences",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Les modifications de quantité sont calculées au prorata ou à frais complets pour la nouvelle quantité",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Fournisseur",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Prix de vente",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Type de vente",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Valide",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Recherche de produits",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Quantité",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Description",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Nom",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Détails de l'offre",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Afficher",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Catégories",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Fournisseurs",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Types de service",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Coût pour le partenaire",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Coût pour le client",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Paiements multiples",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Paiements multiples",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Paiements",
      "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Veuillez actualiser le rapport sur les revenus en cliquant sur le bouton Recharger avant de continuer.",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Aucun historique trouvé !",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Types de service",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "PARTNER_COMMENTS_DETAILS":"Commentaires",
      "PARTNER_COMMENTS_TAB_CART_ITEM":"Article du panier",
      "PARTNER_COMMENTS_TAB_INVOICE":"Facture",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Produit",
      "PARTNER_COMMISIONS":"Commissions Partenaires",
      "PARTNER_CONSENT_HELP_TEXT":"Copiez le lien en cliquant sur le bouton ci-dessus et ouvrez une nouvelle fenêtre de navigation privée, puis compléter la procédure de consentement.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Veuillez sélectionner le type de facturation pour le bloc",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Ajouter",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Modifier",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Veuillez sélectionner l'applicabilité de l'offre",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Courriel",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Nom",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Numéro de téléphone",
      "PARTNER_CUSTOMER_FILTER_NAME":"Nom",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Identifiant du client fournisseur",
      "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} n'est affecté à aucun gestionnaire de compte",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Il n'y a pas de clients liés à un domaine du fournisseur.",
      "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"L'attribution de ce plan inclura les offres avec des majorations définies dans le plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Affecter",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Désactiver",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Activer",
      "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"La désactivation supprimera ce plan, y compris ses offres. Les produits achetés existants auront le même balisage que ce plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"L'activation attribuera ce plan, y compris les offres avec des majorations définies dans le plan. Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Retour aux plans des clients",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Changer de plan",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Gérer les plans pour : ",
      "PARTNER_CUSTOMER_REPORTS":"Rapports",
      "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"balise client ajoutée avec succès",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"balise client mise à jour avec succès",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Voulez-vous vraiment mettre à jour le statut du plan ?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Désolé, aucun client n'a été trouvé",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Comptes débiteurs",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"En souffrance",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilité",
      "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"10 meilleurs clients",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Vos clients actifs",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clients",
      "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Sièges clients",
      "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Sièges du client direct",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Abonnements directs du client",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Abonnements Clients",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"Les détails de la facture du revendeur n'ont pas été trouvés pour le resellerId : {{resellerId}}, billingPeriodId : {{billingPeriodId}}",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Facture générée le",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Profit Net ",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Autres charges",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% de profit",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Montant facturé",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"Facture MSFT ",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Aperçu rapide des recettes de votre entreprise",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilité",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Résumé de vos comptes débiteurs",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Comptes débiteurs",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Les paiements doivent être collectés",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"En souffrance",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"En attente",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Veuillez vérifier une fois les factures prêtes",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 des SKU",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Nombre de produits par les meilleures 10 SKU",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Comptes par SKU",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Gamme de filtres",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 an",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Achat de sièges",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Tendance des achats de sièges au fil des mois",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Achat de sièges",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Sièges achetés",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sièges achetés",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Les partenaires actifs travaillant avec vous",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Revendeurs",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Chiffre d'affaires vs Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Un graphique pour afficher les revenus par rapport aux détails des coûts par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Revenus vs coûts par catégorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Sélectionnez le fournisseur",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Chiffre d'affaires vs Coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Chiffre d'affaires par rapport au coût",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Chiffre d'affaires par rapport au coût",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Clients",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Sièges",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonnements",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Factures",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"Revendeurs",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Par date de renouvellement",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Les 5 meilleurs produits",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Les meilleurs 5 des produits par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Les meilleurs 5 des produits par chiffre d'affaires",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Sièges",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Les 5 meilleurs produits",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Par places achetées",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Les meilleurs 5 des produits par sièges achetés",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Les meilleurs 5 des produits par sièges achetés",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Renouvellements à venir",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"10 meilleurs clients",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Par chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Les meilleurs 10 des clients par chiffre d'affaires",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Les meilleurs 10 des clients par chiffre d'affaires",
      "PARTNER_LOGOS_LABEL_TEXT":"Sélectionner le logo",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Aperçu d'image",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Mettre à jour la quantité",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabais",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Supprimer le module complémentaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Supprimer",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Type de facturation",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Catégorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Rabais",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Nom",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Prix unitaire",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Extensions",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Supprimer l'offre",
      "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Ajouter une offre Partenaire",
      "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Entrez la quantité que vous souhaitez acheter.",
      "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Modifier l'offre de contrat",
      "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Modifier l'offre du Partenaire",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Entrez une valeur positive",
      "PARTNER_OFFER_HYPERLINK_READ_LESS":"Lire moins",
      "PARTNER_OFFER_HYPERLINK_READ_MORE":"En savoir plus",
      "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Activez l'offre d'essai en cochant cette case",
      "PARTNER_OFFER_PROVIDER_TOOLTIP":"Veuillez choisir le fournisseur de solutions dans la liste déroulante",
      "PARTNER_OFFER_SLAB_DATA":"Données de bloc",
      "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":" Durée de la période d'essai",
      "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Tous les clients",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Actions",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Courriel",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports sur les offres des partenaires.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Rapport sur les offres des partenaires",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur les offres des partenaires",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Entrez l'adresse e-mail à laquelle vous souhaitez envoyer par e-mail, entrez avec une virgule (,) ou un point-virgule (;) et cliquez sur soumettre pour recevoir un rapport.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Annuler",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Soumettre",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"Le produit parent n'est pas approuvé !",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"Les produits pour enfants ne sont pas rejetés !",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Tous les articles du panier sont approuvés, quelles que soient les violations de seuil à chaque élément de ligne. Voulez-vous vraiment approuver le panier ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"Voulez-vous vraiment approuver l'élément ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Approbation réussie du panier Lineitem",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Le panier a été approuvé avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Approuver",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Approuver",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Approuver la demande",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Retour aux demandes",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Catégories",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Types de service",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Statut",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"Commandé le",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Prix unitaire",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"Rejeter",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Rejeter",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Rejeter la demande",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Soumettre des changements",
      "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Veuillez vous assurer d'approuver ou de rejeter au moins un produit avant de le soumettre",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"Voulez-vous vraiment rejeter l'intégralité du panier ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"Voulez-vous vraiment rejeter l'élément ?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Le panier Lineitem a été rejeté avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Le panier a été rejeté avec succès",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Le statut a été mis à jour avec succès",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Approbations",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Les demandes d'achat des clients suivantes attendent votre approbation.",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Approbations pour",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Demandes d'achat",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Cycles de facturation sélectionnés",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Catégories sélectionnées",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Catégories de fournisseurs sélectionnées",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Fournisseurs sélectionnés",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Appliquer la promotion à toutes les offres",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Catégories",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Sélectionner le type de macro",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Limite de places du plan :",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Catégories de fournisseurs",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Limite de siège",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Types de service",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Duplication de plan complétée avec succès",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"La demande de clonage de plan est acceptée et en cours de traitement.",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"La demande de création de nouveau plan avec toutes les offres est acceptée et en cours de traitement",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Valeur de la macro",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"Le prix de vente peut être inférieur à <br/> Prix partenaire",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Le prix de vente peut être inférieur au prix du partenaire",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"Le prix de vente peut-il dépasser le prix de vente du fournisseur ?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Activez cette option pour permettre au prix de vente de dépasser le prix de vente du fournisseur",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Définir le plan dans une nouvelle devise",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Nom",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Code de devise du plan",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Nom de produit",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Devise source",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Devise cible",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Il n'y a pas de devise cible, veuillez contacter le partenaire pour la définir.",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"Comment?",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Définissez la limite à -1 pour exiger l'approbation de tous les achats/mises à jour de sièges",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Utilisez cet écran pour configurer le seuil par nombre de sièges",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Définissez la limite sur 0 pour permettre l'achat de sièges illimités",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Définir un nombre positif différent de zéro pour définir le seuil",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Tout achat dépassant le seuil nécessite l'approbation du Partenaire",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Cliquez sur le bouton Soumettre pour enregistrer les modifications<b>",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Configurer les limites de siège pour {{PlanName}}",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Définir la macro et l'enregistrer avec toutes les offres",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Ajouter les offres manquantes",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Annuler",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Cloner le plan",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Enregistrer le plan avec toutes les offres",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Cloner le plan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Clonage du plan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Création de plans",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"Le plan est en cours. Cliquez pour voir le statut",
      "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Limites de sièges",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Catalogue des produits",
      "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Catalogue des produits",
      "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Catalogue des produits",
      "PARTNER_PRODUCT_CATEGORYID":"CategoryId",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "PARTNER_PRODUCT_DESCRIPTION":"Description",
      "PARTNER_PRODUCT_NAME":"Nom",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"PriceforPartner",
      "PARTNER_PRODUCT_ProviderId":"ProviderId",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Prix de vente du fournisseur",
      "PARTNER_PRODUCT_SALETYPE":"Type de vente",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Coût pour le client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Devise",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Identifiant du client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Nom du client",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Description",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Id Produit ",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Nom de produit",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Unité de mesure",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Unités",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Date d'utilisation",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"Voulez-vous vraiment accepter cette demande ?",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Voulez-vous vraiment rejeter cette demande ?",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Retour à l'historique des abonnements",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Nouveau produit PSA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Libérer",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Cartographie des accords de libération",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Cartographie des contrats de publication",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Publier la cartographie client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Créer un nouveau produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Ce champ est obligatoire.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Catégorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Coût",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Description du client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Description",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Livraison directe",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Identifiant",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Référence croisée d'intégration",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Remarques",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Prix",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Classe de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"SLA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Commande spéciale",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Sous-catégorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Taxe de vente",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Type de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Unité de mesure (UOM)",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Entrer le coût",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Entrez la description du client",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Entrer l'identifiant",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Entrer des notes",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Entrez le prix",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Entrez la description du produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Entrer la référence croisée d'intégration",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Sélectionnez le type de produit",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Création d'un nouveau produit PSA avec succès",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Retour au clients",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "PARTNER_RESELLER_FILTER_NAME":"Nom",
      "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{reseller}} n'est affecté à aucun gestionnaire de compte",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Enregistrer vos modifications",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Détails de base",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Description",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Nom",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Sélectionner",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"Le prix du nouveau partenaire peut être inférieur à<br/>Prix du partenaire",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Activez cette option pour permettre au nouveau prix du partenaire d'être inférieur au prix du partenaire",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"Le prix du partenaire peut-il dépasser le prix de vente du fournisseur ?",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Activez cette option pour permettre au prix du partenaire de dépasser le prix de vente du fournisseur",
      "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Ajouter un rapport",
      "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer ceci ?",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Actions",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Ajouter un destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Retour aux rapports",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Retour au destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"CCI",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Annuler",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Supprimer",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Modifier",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Courriel",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Type de destinataire",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Destinataires",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Enregistrer",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"À",
      "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Méthode de livraison",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Destinataires",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Type de destinataire",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Destinataires",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Historique d'exécution",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Actions",
      "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Dernière exécution le (heure locale)",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Rapports",
      "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Prévu le",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Date",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Détails",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Statut",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Veuillez entrer au moins un nom de taxe et un pourcentage pour {{taxType}}",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Veuillez entrer le pourcentage de taxe valide",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Veuillez remplir les champs du nom de taxe",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Vous avez des sous taxes non enregistrées, voulez-vous continuer ?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Notification par courriel de l'événement",
      "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Sélectionner un mode d'affichage",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"balise de partenaire supprimée avec succès",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"balise partenaire mise à jour avec succès",
      "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Modes",
      "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Modèles",
      "PARTNER_STATUS":"Statut de partenaire",
      "PARTNER_TABLE_HEADER_NAME":"Nom",
      "PARTNER_TABLE_HEADER_VALUE":"Valeur",
      "PARTNER_TERMS_OF_USE":"Conditions d'utilisation du partenaire",
      "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Journées d'essai",
      "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Offres parents associées à l'offre d'essai",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Coût pour le client",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Devise",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Description",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Soumettre",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Mois",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Coût total",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Date d'utilisation",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Unité de mesure",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Unités",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Date d'utilisation",
      "PARTNER_USER_ADD_SUCCESSFULLY":"Utilisateur Partner ajouté avec succès",
      "PARTNER_USER_ALREADY_EXECUTE":"Un utilisateur Partner existe déjà avec cet email.",
      "PARTNER_USER_REMOVED":"Utilisateur Partner supprimé avec succès",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Modifier",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Clé de Balise",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Valeurs de balise",
      "PARTNER_USERS_ADD_SUCCESS":"Accorder l'accès a {{username}} avec succès",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Un utilisateur avec l'email {{useremail}} existe déjà dans le système.",
      "PARTNER_USERS_REMOVE_SUCCESS":"Utilisateur Partner {{username}} supprimé avec succès",
      "PARTNER_USERS_SAVE_SUCCESS":"Utilisateur Partner {{username}} mis à jour avec succès",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Veuillez attribuer un rôle pour continuer",
      "partnerauditlog":"Journal d'audits",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Utilisateur Partner mis à jour avec succès",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Retour aux utilisateurs Partner",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Ajouter",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Modifier",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Utilisateur Partner ",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Courriel",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Gérer la balise",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Clé de Balise",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Valeurs de balise",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
      "PAYMENT_LABEL_FILTER":"Filtrer par",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Profil de paiement défini par défaut",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Profil de paiement supprimé",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Nom du titulaire",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Numéro de carte",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Mois d'expiration",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Année d'expiration",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Code de sécurité",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Nom sur la carte de crédit",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Numéro de carte de crédit",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Numéro du mois à deux chiffres",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Année à quatre chiffres",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Numéro CVV à trois chiffres sur la carte de crédit",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Échec de l'enregistrement des détails de paiement",
      "PAYMENT_PROFILE_SAVE_SUCCESS":"Détails de paiement enregistrés",
      "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Les détails de paiement seront mis à jour sous peu. Veuillez NE PAS RÉESSAYER et revérifier après un certain temps.",
      "PAYMENT_PROFILE_TEXT_FAILURE":"Échec",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"Réussite ",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Accepté",
      "PAYMENT_STATUS_DESC_CHARGED":"Facturé",
      "PAYMENT_STATUS_DESC_DECLINED":"Refusé",
      "PAYMENT_STATUS_DESC_FAILED":"Échoué",
      "PAYMENT_STATUS_DESC_FINALIZED":"Mois en cours impayé",
      "PAYMENT_STATUS_DESC_INPROGRESS":"En cours",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Partiellement payé",
      "PAYMENT_STATUS_DESC_SUCCESS":"Réussi",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Erreur technique",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Annuler",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Mettre à jour",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Ce champ est obligatoire.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Numéro de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Nom de l'alias",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"Est un compte d'entreprise ?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Entreprise",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individuel",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Non",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Oui",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Numéro de carte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Vérification",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Mois d'expiration",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Année d'expiration",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUINT":"Nom de compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Le nom sur le compte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"Enregistrement",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Carte de crédit",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Veuillez patienter pendant le chargement de la page",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Montant facturé",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Nom de l'entreprise",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Montant payé",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Statut de paiement",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Raison de l'échec",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Remarques",
      "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Payé par",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Transactions d'échec",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Paiements en attente",
      "PAYMENTS_STATUS_DESC_FAILED":"Échoué",
      "PAYMENTS_STATUS_DESC_SUCCESS":"Réussite ",
      "PAYMENTS_TABLE_HEADER_AMONT":"Montant payé",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Montant facturé",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Courriel de facturation",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Raison de l'échec",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Période de facturation",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Statut d'échéance",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Est basé sur la facture?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Détenu PAR",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Montant payé",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Montant payable",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Identifiant de paiement",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Méthode de paiement ",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Montant en attente",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Montant en attente",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Remarques",
      "PAYMENTS_TABLE_HEADER_STATUS":"Statut",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Paiement partiel",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Date de transaction",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"Identifiant de transaction",
      "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Vous avez dépassé votre fenêtre de 7 jours calendaires pour réduire le nombre de places pour cet abonnement.",
      "PC_ACTIVATION_ERROR":"Impossible d'activer un abonnement enfant lorsque l'abonnement parent n'est pas actif",
      "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"L'addon n'est pas achetable sans un produit de base compatible",
      "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":"Informations sur le Partenaire du conseiller introuvables pour répondre à cette demande.",
      "PC_ADVISOR_QUOTE_NOT_FOUND":"Devis du conseiller introuvable. Veuillez vérifier les informations avant de réessayer.",
      "PC_ALREADY_ACTIVATE_ITEM":"Cet élément a déjà été activé.",
      "PC_AN_Azure_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Un plan Azure est requis pour acheter un abonnement SaaS avec un plan de facturation mesuré. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Un plan Azure est requis pour acheter un abonnement SaaS avec un plan de facturation mesuré. Veuillez contacter le partenaire pour plus d'informations.",
      "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Cette méthode d'API n'est pas prise en charge pour l'ancien abonnement",
      "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Disponibilité référencée par élément de campagne introuvable",
      "PC_AVAILIBILTY_QUANTITY":"La disponibilité a plus d'une option de quantité incluse. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_Azure_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Le plan Azure ne peut pas être déplacé vers l'état suspendu car vous avez un ou plusieurs abonnements Azure à l'état actif.",
      "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Le plan Azure ne peut pas être déplacé vers l'état suspendu car vous avez un ou plusieurs abonnements Azure à l'état actif.",
      "PC_Azure_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_Azure_SUBSCRIPTIONS_UNDER_IT":"L'ID de plan Azure spécifié est introuvable ou il n'y a aucun abonnement Azure actif sous celui-ci. Un plan Azure avec un ou plusieurs abonnements actifs est requis pour acheter un produit SaaS avec un plan de facturation mesuré",
      "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"L'ID de plan Azure spécifié est introuvable ou il n'y a aucun abonnement Azure actif sous celui-ci. Un plan Azure avec un ou plusieurs abonnements actifs est requis pour acheter un produit SaaS avec un plan de facturation mesuré",
      "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"L'abonnement Azure avec l'ID de droit donné est introuvable.",
      "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"Le cycle de facturation ne peut pas être plus long que le terme.",
      "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"Le cycle de facturation n'a pas pu être modifié car la commande contient un ou plusieurs abonnements qui ne sont pas actifs ou qui ne prennent pas en charge les conditions de facturation",
      "PC_BILLING_CYCLE_IS_INVALID":"Le cycle de facturation n'est pas valide.",
      "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Cette demande de transfert de propriété de facturation ne peut pas être complétée car les produits tiers de la place de marché ne sont pas transférés avec les abonnements. Supprimez les produits du marché tiers de votre sélection et réessayez.",
      "PC_C3_CONNECTION_ERROR":"Erreur lors de la connexion à Partner Center, veuillez réessayer ou contacter l'assistance.",
      "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"Impossible de modifier le cycle de facturation si l'abonnement n'est pas associé à un compte Mint",
      "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"Impossible de traiter la mise à niveau Azure pour Azure Partner Shared Services",
      "PC_CANCEL_PURCHASE_NON_TENANT":"Les locataires non Sandbox ne sont pas autorisés à annuler des achats autres que les abonnements logiciels et les logiciels perpétuels",
      "PC_CANCELLATION_NOT_ALLOWED":"L'annulation n'est pas autorisée pour le moment !",
      "PC_CANNOT_CHECKOUT":"Un panier déjà soumis ne peut pas être vérifié !",
      "PC_CANNOT_PROCESS_Azure_UPGRADE":"Impossible de traiter la mise à niveau Azure. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CANNOT_PROCESS_AZURE_UPGRADE":"Impossible de traiter la mise à niveau Azure. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CANNOT_PROCESS_REQUEST":"Impossible de traiter la demande de transfert pour {1} transfert {0}",
      "PC_CANNOT_TRANSITION_TO_Azure_PLAN":"Vous avez un ou plusieurs abonnements Azure à l'état suspendu. Ceux-ci ne peuvent pas être transférés vers le plan Azure",
      "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Vous avez un ou plusieurs abonnements Azure à l'état suspendu. Ceux-ci ne peuvent pas être transférés vers le plan Azure",
      "PC_CANNOT_UPDATE_BILLING_CYCLE":"Des défis techniques empêchent les mises à jour de l'abonnement. Veuillez contacter l'assistance.",
      "PC_CART_IN_PROCESS":"Le panier est déjà en cours de traitement, veuillez patienter et revenir après un certain temps.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":"L'article du catalogue n'est pas éligible à l'achat. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":"L'article de catalogue n'est pas éligible pour ajouter ou supprimer une quantité. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"L'élément de catalogue nécessite l'acceptation d'une attestation.",
      "PC_CREATE_ORDER_ERROR":"La création de la commande échoue",
      "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"Le compte client est en cours de vérification. Les transactions ne sont pas autorisées tant que le compte n'est pas approuvé.",
      "PC_CUSTOMER_MIGRATION_FAILED":"La migration de la licence client a échoué. Veuillez contacter l'assistance.",
      "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Le numéro de téléphone du client est manquant. Cette erreur est générée si l'entreprise/l'organisation du client est située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie (MD ), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA) mais billingProfile.defaultAddress.phoneNumber n'est pas transmis.",
      "PC_DISCONTINUED_OFFER":"Cette offre n'est pas actuellement  disponible à l'achat car elle a peut-être été interrompue. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Un ou plusieurs éléments de la commande ne prennent pas en charge la devise indiquée, USD. La devise d'entrée doit être la devise du partenaire ou celle du client (marché).",
      "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"La date de fin de mandat personnalisée spécifiée dans les instructions planifiées pour le prochain trimestre doit être comprise dans la durée du premier mandat après le renouvellement. De plus, les produits à terme mensuel ne peuvent pas correspondre à un abonnement se terminant le 28, le 29 ou le 30 du mois, à moins que cette date ne soit le dernier jour du mois.",
      "PC_GENERIC_ERROR":"Une erreur s'est produite. Veuillez contacter l'assistance pour plus d'informations ou réessayer après avoir vérifié l'état de l'opération effectuée.",
      "PC_INACTIVE_RESELLER_PROGRAM":"Le statut du programme Revendeur n'est pas actif. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_INCORRECT_CUSTOMER_ID":"Identifiant client incorrect. Veuillez vérifier les informations et réessayer.",
      "PC_INITIATE_TRANSFER_REQUEST_FAILED":"Échec du lancement de la demande de transfert. Veuillez réessayer plus tard.",
      "PC_INVALID_ADD_ON":"L'addon n'est pas lié à l'abonnement parent spécifié",
      "PC_INVALID_Azure_SUBSCRIPTION":"Cet abonnement n'est pas un abonnement Azure valide",
      "PC_INVALID_AZURE_SUBSCRIPTION":"Cet abonnement n'est pas un abonnement Azure valide",
      "PC_INVALID_CUSTOMER_INFORMATION":"Les informations fournies ne correspondent pas aux informations client actuelles. Veuillez vérifier les informations et réessayer.",
      "PC_INVALID_DOMAIN":"Domaine invalide. Veuillez fournir l'adresse de domaine correcte et réessayer.",
      "PC_INVALID_ETAG":"L'Etag n'est plus valide",
      "PC_INVALID_LINE_ITEM":"La séquence des éléments de ligne n'est pas correctement définie. Veuillez contacter l'assistance.",
      "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Métadonnées non valides. (Si le prix du client, la devise est manquante).",
      "PC_INVALID_MPN":"MPN non valide, veuillez contacter l'assistance pour une enquête plus approfondie",
      "PC_INVALID_OPERATION":"Cette opération n'est pas prise en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID":"La valeur de l'identifiant d'enregistrement de l'organisation n'est pas prise en charge. Veuillez vérifier la valeur d'entrée avant de réessayer.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":"La valeur ou le format de l'identifiant d'enregistrement de l'organisation n'est pas pris en charge. Veuillez vérifier la valeur d'entrée avant de réessayer.",
      "PC_INVALID_PROMOTION_CODE":"Le code promotionnel n'est pas valide. Veuillez vérifier et réessayer.",
      "PC_INVALID_QTY_FOR_SUBSCRIPTION":"La quantité d'abonnement n'est pas comprise entre les quantités minimale et maximale autorisées",
      "PC_INVALID_REQUEST":"Requête invalide. Veuillez vérifier les attributs de la requête avant de réessayer.",
      "PC_INVALID_SUBSCRIPTION":"Abonnement non valide, veuillez contacter l'assistance.",
      "PC_INVALID_SUBSCRIPTION_TRANSER":"Le transfert ne peut pas être créé avec un abonnement invalide. Veuillez vérifier l'abonnement avant de réessayer.",
      "PC_ISSUE_ASSET_STATUS_CHANGE":"L'opération de modification de l'état de l'actif n'a pas pu se terminer. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_ISSUE_OFFER_TYPE":"L'achat n'a pas pu être effectué en raison d'un problème avec le type d'offre. Veuillez contacter l'assistance.",
      "PC_ITEM_IS_NOT_AVAILABLE":"Cet article n'est pas disponible",
      "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":"Le nombre demandé de dépasse la limite restante autorisée par abonnement pour l'offre. Veuillez ajuster la quantité avant de réessayer.",
      "PC_LIMIT_EXCEEDED":"La limite d'utilisation est dépassée pour l'identifiant de l'offre",
      "PC_LINE_ITEM_ISSUE":"Il y a un problème avec l'élément de campagne. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"La migration ne peut pas être effectuée car au moins deux migrations de modules complémentaires ont été spécifiées avec le même ID d'abonnement actuel",
      "PC_MISSING_ASSET_ID":"L'opération n'a pas pu se terminer en raison d'un ID d'actif manquant. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_MISSING_Azure_SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car les informations d'abonnement Azure sont introuvables.",
      "PC_MISSING_AZURE_SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car les informations d'abonnement Azure sont introuvables.",
      "PC_MISSING_MCA":"Veuillez accepter MCA avant de procéder à l'achat.",
      "PC_MISSING_REQUIRED_TAGS_IN OFFER":"Le compte n'a pas de balises requises pour l'offre. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_MISSING_TRANSFER_REQUEST":"Aucune demande de transfert n'a été trouvée.",
      "PC_MISSING_UPGRADE_REQUEST":"Aucune demande de mise à niveau n'a été trouvée.",
      "PC_MPN_NOT_FOUND":"L'identifiant MPN est introuvable",
      "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"Plusieurs demandes de transfert non autorisées. Une autre demande de transfert en cours.",
      "PC_NCE_MIGRATION_COULD_NOT_FOUND":"La migration New-Commerce est introuvable.",
      "PC_NO_Azure SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car le client n'a pas d'abonnement Azure.",
      "PC_NO_AZURE SUBSCRIPTION":"Impossible de traiter la mise à niveau Azure car le client n'a pas d'abonnement Azure.",
      "PC_NOT_ELIGIBLE_AUTO_RENEWED":"L'abonnement n'est pas éligible au renouvellement automatique. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"Le Partenaire n'est pas éligible pour cette transaction, veuillez contacter l'assistance.",
      "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"L'API des offres ne prend pas en charge l'élément. Essayez d'utiliser les API Produits et/ou SKU",
      "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Offre non valable dans le pays spécifié",
      "PC_OFFER_PURCHASE_ERROR":"L'offre n'est plus disponible à l'achat",
      "PC_ONE_OR_MORE_Azure_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Un ou plusieurs abonnements Azure ont été achetés récemment, ces abonnements ne peuvent pas être transférés pour le moment. Veuillez réessayer plus tard.",
      "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Un ou plusieurs abonnements Azure ont été achetés récemment, ces abonnements ne peuvent pas être transférés pour le moment. Veuillez réessayer plus tard.",
      "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":"Le type d'opération n'est pas pris en charge dans le lot. Veuillez effectuer l'opération sur abonnement individuel.",
      "PC_ORDER_CANCELLATION":"La commande ne peut pas être annulée. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PC_ORDER_CANCELLATION_ERROR":"La commande ne peut pas être annulée.",
      "PC_ORDER_IN_PROGRESS":"Votre commande est en cours. Veuillez vérifier l'historique de vos commandes pour les commandes récentes en quelques minutes.",
      "PC_ORDER_NOT_FOUND":"Commande introuvable !",
      "PC_ORDER_VENDOR_POLICY":"La commande n'a pas pu être passée en raison d'une politique de fournisseur en place. Veuillez contacter l'assistance pour en savoir plus.",
      "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Les informations d'identification d'enregistrement de l'organisation sont manquantes. Cette erreur est générée si l'entreprise/l'organisation du client est située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie (MD ), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA) mais le numéro d'enregistrement de l'organisation n'est pas transmis.",
      "PC_ORGANISATION_REGESTRATION_ID_MISSING":"Les informations d'identification d'enregistrement de l'organisation sont manquantes. Veuillez saisir un identifiant d'enregistrement valide avant de réessayer.",
      "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"La valeur de l'identifiant d'enregistrement de l'organisation n'est pas prise en charge. Cette erreur est générée si l'entreprise/l'organisation du client n'est pas située dans les pays suivants : Arménie (AM), Azerbaïdjan (AZ), Biélorussie (BY), Hongrie (HU), Kazakhstan (KZ), Kirghizistan (KG), Moldavie ( MD), Russie (RU), Tadjikistan (TJ), Ouzbékistan (UZ), Ukraine (UA), mais ils ont essayé de spécifier le numéro d'enregistrement de l'organisation.",
      "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":"Les informations d'identification d'enregistrement de l'organisation ne sont pas valides. Veuillez vérifier l'entrée et réessayer.",
      "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Ce transfert n'est pas autorisé car le transfert d'origine {0} est en cours",
      "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"L'excédent est déjà attribué à un autre locataire. Contactez votre client pour résoudre les questions de propriété",
      "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"Le dépassement ne peut pas être défini car le client a des abonnements Azure hérités.",
      "PC_PARTNER_ATTESTATION_MISSING":"L'attestation de partenaire est manquante",
      "PC_PARTNER_INFORMATION_MISSING":"La demande n'a pas pu être effectuée car les informations sur le Partenaire sont manquantes. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"L'ID de locataire partenaire n'est pas encore autorisé à effectuer des migrations vers New Commerce sur ses abonnements",
      "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"Impossible de mettre à jour le Partenaire enregistré pour cet abonnement. Veuillez contacter le support Microsoft pour obtenir de l'aide.",
      "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Il y a un ajustement en attente demandé pour cette commande. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Il y a une opération en attente sur cet abonnement. Veuillez réessayer après 24 heures.",
      "PC_PHONE_NUMBER_MISSING":"Le numéro de téléphone du client est manquant. Veuillez fournir le numéro de téléphone du client avant de réessayer.",
      "PC_PRICING_INFORMATION_NOT_PERMITTED":"L'accès aux informations de tarification n'est pas autorisé. Veuillez contacter l'assistance.",
      "PC_PRODUCT_NOT_FOUND":"Le produit n'a pas été trouvé",
      "PC_PROPERT_NOT_SUPPORTED":"Cette propriété n'est pas prise en charge. Veuillez vérifier et réessayer.",
      "PC_PURCHASE_NOT_SUPPORTED":"Système d'achat non pris en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Un bon de commande est requis.",
      "PC_PURCHASE_PRE_REQUISTIES_MISSING":"Un ou plusieurs pré-requis d'achat ne sont pas remplis. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"La quantité d'abonnement ne peut pas être augmentée ou diminuée pendant que l'abonnement est suspendu",
      "PC_QUANTITY_CANNOT_DECREASED":"La quantité d'abonnement ne peut pas être diminuée. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":"La quantité n'est pas disponible pour cet article de catalogue. Veuillez ajuster la quantité avant de passer une commande ou contacter le Partenaire pour plus d'informations.",
      "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":"Les changements de quantité sur l'abonnement avec le statut suspendu et supprimé ne sont pas pris en charge. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_REQUEST_INFORMATION_NOT_AVAILABLE":"L'opération n'a pas pu être terminée car les informations demandées ne sont pas disponibles actuellement. Veuillez réessayer ou contacter l'assistance.",
      "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"La quantité demandée n'est pas dans la quantité autorisée pour cette offre.",
      "PC_RESELLER_RELATIONSHIP_MISSING":"Il n'y a pas de relation de revendeur avec le client. Veuillez établir une relation de revendeur avant de tenter à nouveau cette action.",
      "PC_RI_PURCHASE_NOT_ENABLED":"Cet abonnement n'est pas activé pour l'achat d'instances réservées. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SANDBOX_LIMIT":"La limite du bac à sable a été atteinte. Veuillez ajuster la limite avant de passer une commande.",
      "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":"La vente dans le pays du client n'est pas prise en charge. Veuillez consulter la liste des marchés Microsoft CSP pour en savoir plus.",
      "PC_SKU_NOT_FOUND":"SKU introuvable",
      "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Quelque chose s'est mal passé. Réessayez après un certain temps.",
      "PC_SUBCRIPTION_ACTIVE_ISSUE":"Cet abonnement n'est pas un abonnement actif. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"L'abonnement ne peut pas être migré vers New Commerce car il contient des modules complémentaires.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"L'abonnement ne peut pas être migré vers New Commerce car la combinaison de la durée du terme et du cycle de facturation n'est pas prise en charge pour le produit New-Commerce.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"Impossible de migrer l'abonnement vers New Commerce, car il n'existe pas encore de chemin de migration valide pour cette offre.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"L'abonnement ne peut pas être migré vers New Commerce pour une ou plusieurs raisons (raisons possibles : l'abonnement n'est pas à l'état Actif - l'abonnement a des promotions qui lui sont appliquées - l'abonnement a des modules complémentaires qui lui sont attachés - l'abonnement est trop proche de la fin du terme - l'offre d'abonnement est pas encore disponible dans New Commerce)",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"L'abonnement ne peut pas être migré vers New Commerce car des promotions lui sont appliquées",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"L'abonnement ne peut pas être migré vers New Commerce car l'abonnement est en cours de traitement.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"L'abonnement ne peut pas être migré vers New Commerce car il est à moins de 24 heures de la fin du terme.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"L'abonnement ne peut pas être migré vers New Commerce car il est actif depuis 1 mois ou moins. Veuillez réessayer plus tard.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"L'abonnement ne peut pas être migré vers New-Commerce car un ou plusieurs abonnements complémentaires spécifiés dans la collection AddOnMigrations n'existent pas ou ne sont pas actifs.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"L'abonnement ne peut pas être migré vers New Commerce car l'ID de partenaire enregistré dans l'abonnement actuel n'est pas valide.",
      "PC_SUBSCRIPTION_HISTORY_ISSUE":"L'historique des abonnements n'a pas pu être récupéré. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Votre abonnement est en cours d'expiration. Aucune autre mise à jour n'est autorisée sur cet abonnement.",
      "PC_SUBSCRIPTION_NOT_FOUND":"L'abonnement que vous essayez d'utiliser n'est plus disponible auprès de Microsoft.",
      "PC_SUBSCRIPTION_NOT_REGISTERED":"Cet abonnement n'est pas enregistré. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":"L'offre d'abonnement n'est pas disponible à l'achat. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_SUBSCRIPTION_UPDATE":"L'abonnement n'est pas prêt à être mis à jour. Veuillez réessayer ultérieurement ou contacter l'assistance si le problème persiste.",
      "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"Le nombre d'abonnements souhaité a dépassé le nombre maximum d'abonnements autorisé par client",
      "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"L'abonnement ne peut pas être migré vers New Commerce car le statut n'est pas actif.",
      "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"La date de fin de l'engagement d'abonnement cible doit être postérieure à l'abonnement source",
      "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"La transition cible ne peut pas avoir une durée inférieure à celle de l'abonnement source.",
      "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible n'est pas prêt à être mis à niveau. Veuillez réessayer plus tard.",
      "PC_TENANT_ALREADY_USED":"Le domaine spécifié lors de la création du locataire est déjà utilisé par un autre locataire. Choisissez un autre domaine et réessayez",
      "PC_TENANT_NOT_EXISTS":"Le nom de domaine n'existe pas, veuillez vérifier le nom de domaine.",
      "PC_TENANT_NOT_FOUND":"Locataire introuvable.",
      "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"La durée du terme sur la transition cible n'est pas valide.",
      "PC_TERM_EXCEEDED":"La durée d'achat initiale n'est plus disponible à l'achat/à la mise à jour",
      "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":"L'ID de l'élément de catalogue n'est pas présent. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"La date de fin du terme personnalisé spécifiée dans les instructions du prochain terme prévu ne correspond pas à la date de fin d'un abonnement OnlineServicesNCE actif sans essai ou ne correspond pas à la fin du mois civil.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"La date de fin de mandat personnalisée spécifiée dans les instructions du prochain mandat prévu doit être comprise dans la durée du premier mandat après le renouvellement. Les produits à terme mensuel ne peuvent pas correspondre à un abonnement se terminant le 28, 29 ou 30 du mois, sauf si cette date est le dernier jour du mois.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOUNCE_INFORMATION":"La demande n'a pas pu récupérer toutes les informations sur les ressouNCEs de disponibilité. Veuillez vérifier après un certain temps avant de réessayer.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"La demande n'a pas pu récupérer toutes les informations sur les ressources de disponibilité. Veuillez vérifier après un certain temps avant de réessayer.",
      "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":"Le programme demandé n'est pas actif. Veuillez contacter le support pour en savoir plus.",
      "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":"Le rôle fourni n'a pas le droit d'effectuer l'opération demandée. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED":"Cette opération n'est pas prise en charge. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":"Cette opération n'est pas prise en charge dans cet environnement. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_Azure_PLAN":"Cette demande de transfert ne peut pas être initiée car le client n'a pas acheté le plan Azure.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Cette demande de transfert ne peut pas être initiée car le client n'a pas acheté le plan Azure.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_Azure_PLAN_IS_NOT":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas achetable. Veuillez contacter le Partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas achetable. Veuillez contacter le partenaire pour plus d'informations.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_Azure_PLAN_IS_NOT_ACTIVE":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas actif, veuillez activer le plan Azure et réessayer.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":"Cette demande de transfert ne peut pas être initiée car le plan Azure n'est pas actif, veuillez activer le plan Azure et réessayer.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":"Cette demande de transfert ne peut pas être initiée car le client a des abonnements Azure hérités.",
      "PC_TOKEN_MISSING":"Jeton de Partenaire manquant dans le contexte de la demande.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible CommitmentEndDate doit être postérieur à l'abonnement source",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'abonnement cible doit être actif.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"Le champ de transition cible ToSubscriptionId n'est pas valide. L'ID d'abonnement cible ne peut pas être le même que l'ID d'abonnement source.",
      "PC_TRANSACTION_ELIGIBLE":"Vous pourriez être admissible à cette transaction. Veuillez contacter l'assistance pour obtenir de l'aide",
      "PC_TRANSACTION_NOT_ELIGIBLE":"Vous n'êtes pas éligible pour cette transaction. Veuillez réduire la quantité ou contacter le support pour obtenir de l'aide.",
      "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"Le transfert ne peut pas être créé en tant que Partenaire : le {0} national cloud {1} n'est pas pris en charge",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOUNCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Le transfert ne peut pas être lancé car les détails de l'e-mail/du domaine du Partenaire souNCE manquent dans la demande. Veuillez saisir les informations demandées avant de réessayer.",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Le transfert ne peut pas être lancé car les détails de l'e-mail/du domaine du partenaire source manquent dans la demande. Veuillez saisir les informations demandées avant de réessayer.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOUNCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"La création du transfert a échoué en raison des détails du Partenaire souNCE correspondant au Partenaire demandeur. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"La création du transfert a échoué en raison des détails du partenaire source correspondant au partenaire demandeur. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_Azure_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Cette demande de transfert de propriété de facturation ne peut pas être effectuée car les réservations Azure ne sont pas transférées avec les abonnements. Annulez les réservations Azure associées aux abonnements de votre sélection et réessayez.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Cette demande de transfert de propriété de facturation ne peut pas être effectuée car les réservations Azure ne sont pas transférées avec les abonnements. Annulez les réservations Azure associées aux abonnements de votre sélection et réessayez.",
      "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"La demande de transfert ne peut être acceptée. Veuillez demander au Partenaire de créer un transfert sans abonnement(s) Azure",
      "PC_TRANSFER_REQUEST_DISABLE":"Le transfert ne peut pas être créé en tant que Partenaire : {0} n'est pas activé pour cette fonctionnalité",
      "PC_UNABLE_TO_COMPLETE_THE_REQUEST":"Impossible de terminer la demande. Veuillez contacter le support Microsoft pour plus d'informations.",
      "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"Impossible de créer la commande. Il y a une limite au nombre de plans Azure pouvant être créés sous des comptes sandbox",
      "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":"Impossible de trouver le profil du client pour compléter la demande. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_UNABLE_TO_GET_Azure_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Impossible d'obtenir des abonnements Azure Active Directory pour un client avec l'ID de locataire {0} et l'ID de provisionnement souNCE {1}",
      "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Impossible d'obtenir des abonnements Azure Active Directory pour un client avec l'ID de locataire {0} et l'ID de provisionnement source {1}",
      "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"Impossible de traiter la commande en raison d'une limite de crédit insuffisante, veuillez contacter ucmwrcsp@microsoft.com pour obtenir de l'aide",
      "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Une erreur inattendue s'est produite lors du traitement de la migration New-Commerce.",
      "PC_UNSUCCESSFUL_TRANSFER_ORDER":"L'ordre de transfert n'a pas abouti. Veuillez contacter l'assistance pour plus d'informations.",
      "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Les opérations de mise à jour ne sont actuellement pas autorisées pour cet abonnement.",
      "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Les opérations de mise à jour ne sont plus autorisées pour cet abonnement.",
      "PC_UPDATES_NOT_ALLOWED":"Les mises à jour de l'abonnement ne sont pas autorisées",
      "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":"La demande de mise à niveau ne peut pas être traitée pour un abonnement déjà mis à niveau.",
      "PC_UPGRADE_REQUEST_IN_PROGRESS":"La demande de mise à niveau n'a pas abouti car une autre demande de mise à niveau est en cours.",
      "PC_USER_LIMIT_EXCEEDED":"Le nombre de sièges souhaité a dépassé le nombre de sièges maximum autorisé par abonnement",
      "PC_USER_NOT_FOUND":"Utilisateur introuvable dans le locataire cible.",
      "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Lorsqu'il fait partie de l'appel d'offres, un lien d'appel d'offres ou un document d'appel d'offres est requis.",
      "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"Vous n'êtes pas autorisé à gérer le dépassement pour ce client.",
      "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Vous avez dépassé votre fenêtre d'annulation. Nous ne pouvons pas annuler votre achat",
      "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Vous avez dépassé votre fenêtre de jour pour réduire les places pour cet abonnement.",
      "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Votre entreprise n'a pas accepté le Microsoft Partner Agreement (MPA). L'administrateur global du compte CSP doit accepter le MPA pour reprendre toutes les fonctionnalités transactionnelles.",
      "PCA_MISSING_MPA":"Contrat de Partenaire Microsoft manquant du Partenaire.",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Obtenir le statut de paiement en attente à partir de la facturation",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Mettre à jour le statut du paiement en c3",
      "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Mise à jour du statut de paiement pour {{recordName}}",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Actions",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"État du paiement de la facturation",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"Statut de paiement C3",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Nom de l'entité",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Numéro de facture",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Montant payé",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Nom de l'enregistrement",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Afficher les détails",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Actions",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Identifiant de commande",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Commandé le",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Remarques",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Type de demande",
      "Percentage":"Pourcentage",
      "PÉRIODE DE FACTURATION":"Période de facturation",
      "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Clés logicielles",
      "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Télécharger le logiciel et les clés",
      "PHONE_TABLE_HEADER_ACTIONS":"Actions",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Numéro de téléphone",
      "PHONE_TABLE_HEADER_PHONE_TYPE":"Type de téléphone",
      "PHONE_TYPE_HOME":"Maison",
      "PHONE_TYPE_HOME_FAX":"Télécopie à domicile",
      "PHONE_TYPE_MAIN":"Principal",
      "PHONE_TYPE_MOBILE":"Mobile",
      "PHONE_TYPE_OFFICE":"Bureau",
      "PHONE_TYPE_OTHER":"Autre",
      "PHONE_TYPE_WORK":"Travail",
      "PHONE_TYPE_WORK_FAX":"Télécopieur professionnel",
      "PLACE_HOLDER_SEARCH_CUSTOMER":"Rechercher un client",
      "PLACEHOLDER_ENTER_TEXT":"Entrez le texte",
      "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Sélectionnez le cycle de facturation...",
      "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Sélectionnez le type de facturation...",
      "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Sélectionner les catégories",
      "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Sélectionnez le type de catégorie...",
      "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Sélectionnez le type de consommation...",
      "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Rechercher le code de devise",
      "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Rechercher un client",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Sélectionnez un fournisseur client dans la liste ou recherchez le nom ou l'identifiant de domaine...",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"En vigueur à partir de",
      "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Efficace pour",
      "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"MM",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Entrez le texte",
      "PLACEHOLDER_FOR_PLAN_NAME":"Entrez le nom du plan",
      "PLACEHOLDER_FOR_PRICE":"Prix",
      "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Sélectionner des fournisseurs",
      "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Rechercher un client",
      "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Sélectionnez des rôles..",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Sélectionner",
      "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Sélectionnez les valeurs de balise..",
      "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Sélectionnez la durée du terme...",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Recherche...",
      "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Sélectionnez la durée de l'essai...",
      "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Sélectionnez le type de validité...",
      "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"AAAA",
      "PLACEHOLDER_SPECIAL_QUALIFICATION":"Qualifications spéciales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Modifier",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Modifier les qualifications spéciales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"le nombre maximum de caractères autorisés est de 50",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"devrait avoir au moins un caractère",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Enregistrer",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Aucun",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Ce champ est obligatoire",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Fermer",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Éducation",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Secteur de l'éducation",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Enseignement supérieur",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Site Internet",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Aucun",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Entité appartenant à l'État",
      "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"Sélectionnez {{EntityName}} spécifique ? Cliquez ici...",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Tapez pour rechercher une balise",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Sélectionnez un domaine dans la liste ou recherchez le nom ou l'identifiant de domaine...",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Rechercher un plan",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Nom de produit",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Id Produit ",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Rechercher avec le nom du produit ou l'ID du produit",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Nom du produit, identifiant",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Recherche d'abonnement",
      "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Entrez des identifiants de courriel valides",
      "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"Prix de revient max.",
      "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"Prix de vente max.",
      "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"Validité max.",
      "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"Prix de revient min.",
      "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"Prix de vente min.",
      "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"Validité min.",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Montant du paiement",
      "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Saisir ici...",
      "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Sélectionnez un client existant dans la liste ou effectuez une recherche à l'aide du nom du client ou de l'identifiant du domaine",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Remarques",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Recherche par client",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Rechercher par statut",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Cochez cette case si vous prévoyez d'avoir des offres composites dans ce plan à l'avenir.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"Le statut a été mis à jour avec succès",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"Plans",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Ajouter un nouveau plan",
      "PLAN_CAPTION_TEXT_PLANS":"Plans",
      "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PLAN_CHANGE_FOR_CUSTOMER":"Changer le plan du client ",
      "PLAN_CHANGE_TEXT":"Changement de plan ",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Offres de plans composites",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Plans",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Retour aux offres composites",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Ajouter une offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Modifier l'offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Offres",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Articles sélectionnés",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Prix suggéré : ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Indiquez l'identifiant du composant de facturation au cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Extension(s)",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Extensions",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Supprimer",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Description",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Identifiant du composant de facturation",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Nom de l'offre",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Prix",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Ajouter une offre composite",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Retour aux détails du Plan ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Offres composite pour - ",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Modifier",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Offres incluses",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Nom",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Prix",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>sous</b> {{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"S'inscrire",
      "PLAN_DETAILS_IS_PUBLIC":"Est publique",
      "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Marchés pris en charge",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"par mois",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Estimation du prix de vente au détail",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"par an",
      "PLAN_DETAILS_PARAGRAPH_4_TEXT":"une fois",
      "PLAN_DETAILS_SHARING_URL":"Partager l'URL",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Vos modifications ont été soumises avec succès",
      "PLAN_IS_IN_PROGRESS_EXCEPTION":"Plan toujours en cours. Veuillez vérifier le statut",
      "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"La valeur minimale est -1",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Définissez la limite sur -1 pour exiger l’approbation de tous les achats/mises à jour de postes.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Fixez la limite à 0 pour permettre l’achat de sièges illimités.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Définissez un nombre positif non nul pour définir le seuil.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_4":"Tout achat dépassant le seuil nécessite l’accord du Partenaire.",
      "PLAN_LEVEL_SEATLIMIT_EDIT":"Définir la limite de sièges par produit du plan",
      "PLAN_LEVEL_SEATLIMIT_REVERT":"Revenir à la limite de sièges au niveau du plan",
      "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Chargez les dernières promotions Microsoft NCE disponibles dans les plans.",
      "PLAN_MACRO_DETAILS_LABEL_TEXT":"Détails de la macro",
      "PLAN_MACRO_NAME_LABEL_TEXT":"Nom de la macro",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Quantité",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Valeur de la macro",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Retour aux Plans",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Gérer les offres composites",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Détails pour plan -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Ajouter un nouveau plan",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Charger les promotions",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Annuler",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Charger les promotions du fournisseur",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Soumettre",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Est privé",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"Autoriser les abonnements CSP à être partagés par rapport aux offres du plan?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Offres",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Vous trouverez ici les étapes pour remplacer une offre transmise:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Cochez la case à côté de l'offre que vous désirez remplacer",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Fournir un nouveau pseudonyme",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Spécifier le nouveau prix de vente",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Le nouveau plan commencera à lister les offres remplacées avec celles qui sont héritées mais pas remplacées.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Indiquez l'identifiant du composant de facturation au cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"REMARQUE:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Remplacer depuis le plan parent",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"sélectionné",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Articles",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Extension(s)",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible à l'achat",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Identifiant du composant de facturation",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Nom de l'offre",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"Extension",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Type de licence",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Supprimer",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Prix de vente",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Mettre à jours le prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Nom du plan : ",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Prix : ",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Définir un nouveau prix",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Type de licence",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Offre remplacée",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Offre sélectionnée",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Description",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"Partager l'URL",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Plans",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Gérer",
      "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"Le nom convivial ne peut pas être vide",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Afficher les offres avec promotion",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"offreAvecPromotion",
      "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"OFFRE D'ESSAI",
      "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Noms des parents",
      "PLAN_NAME_IS_INVALID":"Le nom du plan n'est pas valide",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"Est basé sur la facture?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"Définir un nouveau prix pour",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Le changement de prix sera effectif",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Nouveau prix",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Prix actuel",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Mise à jour réussie du prix du plan d'offre ",
      "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Afficher les offres avec une marge <= 0",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Afficher les offres avec marge > 0 et < 10",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Afficher les offres avec une marge >= 30",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Afficher les offres avec marge >= 10 et < 20",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Afficher les offres avec une marge >= 20 et < 30",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Gérer le plan",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Remplacer le plan",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Plans",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Retour aux Plans",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Modifier plan",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Remplacer le plan",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"Autoriser les abonnements CSP à être partagés par rapport aux offres du plan?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Est privé",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Description",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Fournissez l'identifiant du composant de facturation dans le cas où vous souhaiteriez utiliser un composant existant dans votre système de facturation. Le laisser à 0 crée un nouveau composant dans votre système de facturation.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"REMARQUE:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Assurez-vous de spécifier le prix correspondant au composant dans votre système de facturation. Pour le moment, le système ne valide pas la combinaison de prix et de composants dans le système de facturation.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Vous trouverez ici les étapes pour remplacer une offre transmise:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Cochez la case à côté de l'offre que vous désirez remplacer",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Fournir un nouveau pseudonyme",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Spécifier le nouveau prix de vente",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible à l'achat",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Identifiant du composant de facturation",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nom convivial",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Offre remplacée",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Prix de vente",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Éléments sélectionnés pour remplacer",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Offres",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"Partager l'URL",
      "PLAN_POPUP_GRID_HEADER":"Télécharger la grille du plan",
      "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Ce produit est déjà disponible dans le plan, voulez-vous ajouter le produit à nouveau ?",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Code de devise du plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Nom du plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Sélectionner",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Code de devise source",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Code de devise cible",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Définir une nouvelle devise",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Nombre de sièges autorisées",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Nombre de sièges",
      "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Valeur de siège non valide pour le produit {{PlanProductName}}",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Une erreur s'est produite lors de la mise à jour des sièges",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Sièges mis à jour avec succès",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Blocs enregistrés avec succès",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Modifier",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Remplacer",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "PLAN_TABLE_HEADER_TEXT_NAME":"Nom",
      "plandetails":"Détails du plan",
      "planproductcurrencyconversion":"Conversion de devises",
      "PlanProductId":"PlanProductId",
      "plans":"Plans",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Veuillez sélectionner au moins deux offres afin de créer une offre composite",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Offre composite sauvegardé avec succès",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Offre composite mise à jour avec succès",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Annuler",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Fermer",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Soumettre",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Tous les produits",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"Aucun enregistrement trouvé",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Veuillez enregistrer ou fermer la ligne nouvelle ou modifiée existante.",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Veuillez entrer une valeur supérieure à \" + minValue : \"Veuillez entrer une valeur entre \" + minValue + \" et \" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Veuillez saisir une valeur comprise entre {{minValue}} et {{maxValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Veuillez saisir une valeur supérieure à {{minValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Détails du contrat",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Type de facturation",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Coût pour le partenaire",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Blocs",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Prix de vente",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"Valeur maximale",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Valeur minimale",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Applicabilité",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Vous avez déjà une offre de contrat dans votre plan. Supprimer/désactiver l'offre avant d'en ajouter une nouvelle.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Contrat",
      "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Réservation",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Plan remplacé mis à jour avec succès",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan remplacé avec succès",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Veuillez sélectionner les offres à remplacer dans le plan de base ",
      "PLANS_SAVE_SUCCESS":"Plan sauvegardé avec succès",
      "PLANS_UPDATE_SUCCESS":"Plan mis à jour avec succès",
      "PLEASE_ENTER_THE_REASON":"Veuillez fournir une raison.",
      "PO_NUMBER":"Numéro de commande",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Portée d'affectation de politique",
      "PONUMBER_BUTTON_CLOSE":"Fermer",
      "PONUMBER_BUTTON_SKIP":"Ignorer",
      "PONUMBER_BUTTON_SUBMIT":"Soumettre",
      "PONUMBER_DELETE_CONFIRMATION":"Êtes-vous sûr de vouloir supprimer ce numéro de bon de commande du panier ?",
      "PONUMBER_DELETE_ERROR_MESSAGE":"Une erreur s'est produite lors de la suppression du numéro de commande du panier !",
      "PONUMBER_DELETE_SUCCESS_MESSAGE":"Le numéro de bon de commande demandé a été supprimé avec succès !",
      "PONUMBER_PLACEHOLDER":"Entrez le numéro de bon de commande",
      "PONUMBER_POPUP_TITLE":"Ajouter un numéro de bon de commande",
      "PONUMBER_TITLE":"Numéro de bon de commande",
      "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre tous les plans en file d'attente ?",
      "POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "POPUP_BUTTON_TEXT_CLOSE":"Fermer",
      "POPUP_BUTTON_TEXT_CONFIRM":"Confirmer",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Toutes les offres sélectionnées dans le cadre du cycle de facturation sélectionné seront perdues. Voulez-vous continuer ?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Ce client possède déjà un coupon : <b>{{CouponName}}</b>, voulez-vous le remplacer ?.",
      "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"La suppression du client de facturation supprimera également les profils de paiement associés. Voulez-vous vraiment supprimer le lien client de facturation pour {{CustomerName}} ?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la notification personnalisée ?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Notification personnalisée supprimée avec succès.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{CustomerName}} ?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"La suppression ici entraînera la suppression de l'offre des forfaits revendeur ajoutés et leurs clients ne pourront plus l'acheter ! Êtes-vous sûr de vouloir supprimer l'offre {{distributorOffer}} ?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} supprimé avec succès.",
      "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"Voulez-vous vraiment supprimer cette notification ?",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"SUPPRIMER",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{customOfferName}} ?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} supprimé avec succès.",
      "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>OFFRE D'ESSAI</sup>",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"Voulez-vous vraiment supprimer {{planName}} ?",
      "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"Etes-vous sûr de vouloir supprimer la citation ?",
      "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la ligne de devis <b>{{ProductName}}</b> ?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"Êtes-vous sûr de vouloir supprimer ?",
      "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer cette entité marquée ?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer le message {{MessageBody | limitTo : 30}} ?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"Etes-vous sur de vouloir supprimer ce message?",
      "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir supprimer la notification du webhook ?",
      "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"La notification Webhook a été supprimée avec succès",
      "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"La notification du webhook a été supprimée avec succès.",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"Voulez-vous vraiment désactiver {{productName}} ?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> est une offre parent pour le produit d'essai.<br/> Si cette offre est désactivée, l'offre d'essai sera également désactivée.<br/> Êtes-vous sûr de vouloir désactiver < b>{{productName}}<b/> ?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> est une offre parent pour le produit d'essai.<br/> La désactivation de ce produit affectera la conversion du produit d'essai en abonnement<br/> Êtes-vous sûr de vouloir désactiver <b>{ {productName}}<b/> ?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"Voulez-vous vraiment activer {{productName}} ?",
      "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> l'offre parent n'est pas active.<br/> L'activation de l'offre d'essai activera également l'offre parent.<br/> Êtes-vous sûr de vouloir activer <b>{{productName}} <b/>?",
      "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Engager la notification est supprimée.",
      "POPUP_PLAN_DELETED":"Le plan {{planName}} est supprimé.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"RÉTABLIR",
      "POPUP_REVERT_SUB_HEADER_TEXT":"Êtes-vous sûr que vous voulez rétablir ?",
      "POPUP_TEXT_DEACTIVATE_PSA_LOG":"Êtes-vous sûr de vouloir désactiver le(s) journal(s). Nous ne supprimons pas les journals dans le PSA, cette opération vous permettra de re-télécharger les données plus d'une fois de C3 vers PSA. Veuillez noter que la remise en ligne peut potentiellement créer des doublons dans PSA.",
      "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"Désactivation des journaux PSA réussie",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Vos offres sélectionnées avec le cycle de facturation actuel seront perdues, voulez-vous toujours continuer ?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Vous avez des modifications non enregistrées, voulez-vous toujours continuer ?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Voulez-vous vraiment mettre à jour le nom du client ?",
      "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"Êtes-vous sûr de vouloir mettre à jour le nom du revendeur ?",
      "POPUP_WEB_NOTIFICATION_DELETED":"La notification Web {{MessageBody}} est supprimée.",
      "POPUP_WEB_NOTIFICATION_DELETED2":"La notification Web est supprimée.",
      "PORTAL_ACCESS_GRANTED_TO_USER":"L'accès au portail est accordé à {{UserName}}",
      "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Sélectionnez la page du portail",
      "PORTAL_PAGE_NAME":"Nom de la page du portail",
      "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Données d'utilisation",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Le changement de prix sera appliqué dès le début du cycle de facturation de l'abonnement en cours",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Immédiatement",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"À partir de la prochaine facturation",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"À partir du prochain renouvellement",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Fermer",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Fermer",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Aperçu de la notification par courriel",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Aperçu de la notification par courriel",
      "Price":"Prix",
      "PRICE_CHANGE_CURRENCY":"Devise",
      "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"La valeur saisie ne correspond pas à la configuration de verrouillage des prix choisie",
      "PRICE_CHANGE_NEW_PRICE":"Nouveau prix",
      "PRICE_CHANGE_OLD_PRICE":"Ancien prix",
      "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instructions pour l'API de tarification Microsoft",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Merci d'avoir donné votre consentement. L'application peut être configurée pour accéder à la tarification Microsoft.",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions retrouvées sur Paramètres -> Centre partenaire dans <b>{{applicationName}}</b>.",
      "PRICING_API_VIEW_INSTRUCTIONS_1":"pour copier le lien qui capture le consentement du Partenaire pour accorder à cette application l'accès au \"Partner Center\" conformément à l'API de tarification Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Ouvrez le lien dans une session de navigation privée et complétez la procédure de consentement à l'aide d'un compte d'administrateur global dans votre domaine (partenaire) et auquel le rôle d'agent admin lui est affecté dans le centre partenaire.",
      "PRICING_API_VIEW_INSTRUCTIONS_3":"Cliquez sur le bouton Test pour vérifier si l'application fonctionne avec l'API de tarification Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_5":"Cliquez sur <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copier le lien dans le presse-papiers </a>",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Actions",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Type de facturation",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Coût d'acquisition",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Nom d'affichage",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"MaxValue",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"MinValue",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Prix de vente",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Prix de vente",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Type de facturation",
      "PRIVACY_POLICY_BUTTON_TEXT":"POLITIQUE DE CONFIDENTIALITÉ",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Processus d'intégration massive des clients terminé",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Processus d'intégration massive des clients initié",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Processus de chargement des factures sur Quickbooks terminé",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Processus de chargement des factures sur Quickbooks initié",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Processus de chargement des factures sur Xero terminé",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Processus de chargement des factures sur Xero initié",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Processus de chargement des factures sur Quickbooks échoué",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Processus de chargement des factures sur Quickbooks réussi",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Processus de chargement des factures sur Xero échoué",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Processus de chargement des factures sur Xero réussi",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Vos éléments sélectionnés ont été supprimés.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"La modification de la valeur de ce champ entraînera la perte des éléments sélectionnés",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Type de facturation",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Nombre de jours maximum permis sans frais d'annulation.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Type de vente",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Valide",
      "PRODUCT_CANCELLATION_TEXT":"L'annulation est disponible",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Nous apportons actuellement quelques modifications à ce produit. Veuillez attendre la fin de ces procédures avant d'effectuer une nouvelle demande.",
      "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Sélectionnez un moyen d'obtenir le rapport sur le catalogue de produits",
      "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"E-mail envoyé avec succès",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée : ",
      "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Date de fin",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Commission(%)",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Date de début",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"ID produit interne",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nom de produit",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"Code SP",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"Montant des commissions",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Code de devise",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"Code SP",
      "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"La commission pour les produits d'un Client sera calculée une fois les factures finalisées. Pour le calcul des commissions d'un Client, les détails de la commission spécifiés par l'utilisateur dans la période de facturation respective seront uniquement pris en considération. Si aucun détail de commission n'est spécifié dans une période de facturation, les détails de commission définis dans la période de facturation précédente et valables jusqu'à la période de facturation de la facture seront pris en considération.",
      "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Valider le catalogue",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Nom d'agent",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Date de fin de commission",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Commission(%)",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Date de début des commissions",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Nom du domaine",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"ID produit interne",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nom du produit",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"Identifiant d'abonnement du fournisseur",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"Code SP",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Instructions",
      "PRODUCT_COULD_NOT_FOUND_TEXT":"Le type de produit est introuvable.",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Approuver",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Quantité actuelle",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Remarque du client",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Rejeter",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Quantité demandée",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Limite de siège",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Quantité d'origine",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Lire moins",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Lire plus",
      "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Attributs du produit",
      "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Valider le catalogue",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Catégorie",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Séquence d'affichage",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"ID de l'offre",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Code produit",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Nom du fournisseur",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "PRODUCT_LIST_ADDON_LABEL":"Add-on",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Nous avons annulé cette demande car certaines modifications ont été mises à jour.",
      "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"En attente de l'approbation.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Sélectionnez au moins un produit actif avant de continuer.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Sélectionnez au moins un utilisateur dans la liste.",
      "PRODUCT_MAPPING":"Mappage des produits",
      "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer le mappage pour les produits sélectionnés ?",
      "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Ce produit n'est pas disponible à l'achat.",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Produit non mappé car le locataire est lié à plusieurs clients",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Produit non mappé car le locataire est lié à plusieurs clients ou le produit a déjà été ignoré",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"Le produit est déjà mappé",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Pourcentage",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Quantité",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Nom de portée",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Vous ne pouvez pas mettre à jour la quantité à une valeur supérieure à la quantité disponible. Si vous voulez faire cela, veuillez augmenter la quantité à partir de la vue Détails de base.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Vous pouvez mettre à jour la quantité par l'édition de la ligne et cliquez sur le bouton Envoyer pour enregistrer",
      "PRODUCT_OWNERSHIP_SUCCESS":"Propriété mise à jour avec succès",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Ajouter",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Retour aux produits",
      "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Sauvegarder les modifications",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Saisir une valeur de balise unique",
      "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Balises de produits enregistrées avec succès",
      "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Étiquettes de produit",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Description",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Ajouter une clé de balise",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Ajouter une valeur de balise",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Actions",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Description",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Clé de Balise",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Valeur de balise",
      "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Étiquettes de produit",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Cycle de facturation",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Catégorie",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Code de devise",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Code du marché pris en charge",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Durée du mandat",
      "PRODUCT_VIEW_GRID":"Grille",
      "PRODUCT_VIEW_LIST":"Liste",
      "productcatalogue":"Catalogue des produits",
      "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Renouvellement/Expiration (dans quelques jours)",
      "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Date de commande max",
      "PRODUCTS_REPORT_MAX_QUANTITY":"Quantité maximale",
      "PRODUCTS_REPORT_MIN_QUANTITY":"Quantité minimale",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Tableau de bord",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Profil client",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Détails bancaires",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Carte de crédit",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Compte bancaire",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Type de paiement",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Veuillez sélectionner le type de paiement",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Profile de paiement mis à jour avec succès",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Détails de l'utilisateur",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Ville",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"Pays",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Courriel",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Prénom",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Nom de famille",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Ligne 4",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Ligne 5",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"État",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Code postal",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Courriel",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Configuration client",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Configuration revendeur",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Supprimer un profil de paiement",
      "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Définir comme profil de paiement par défaut",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Numéro de téléphone",
      "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "PROFILE_TAB_CONFIGURATION_SETTING":"Paramètres de configuration",
      "PROFILE_TAB_ORGANIZATION_SETUP":"Configuration de l'organisation",
      "PROFILE_TAB_PAYMENT_PROFILE":"Profil de paiement",
      "PROFILE_TAB_USER_DETAILS":"Détails de l'utilisateur",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Faire défaut",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Soumettre",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Enregistrer",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Enregistrer",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Ville",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"Pays",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Courriel",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Prénom",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Nom de famille",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Ligne 3",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Ligne 4",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Numéro de téléphone",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"État",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Code postal",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Adresse de facturation",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Détails de facturation",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Ajouter une méthode de paiement",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"REMARQUE:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Le nouveau profile de paiement sera utilisé pour toutes les transactions ultérieures",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Changer la méthode de paiement",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Détails du profile de paiement ",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Veuillez ajouter une méthode de paiement.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Vous avez déjà un profil de paiement dans nos dossiers.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Les 4 derniers chiffres de votre méthode de paiement",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Méthode de paiement ",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Détails de l'utilisateur",
      "PROMOTION_ERRORS_ALERT_TEXT":"Erreurs de promotion",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Vous ne pouvez pas acheter cet article, car votre compte n'est pas lié à ce fournisseur !",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"Échec de la génération du mot de passe pour l'administrateur du client du fournisseur",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Mot de passe généré avec succès pour l'utilisateur administrateur du client du fournisseur",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Courriel de facturation",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Les achats contre les domaines CSP peuvent échouer en raison du manque de consentement du client. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><i class=\"red bold\"> Cliquez ici pour rediriger vers le profil du domaine et ajouter le MCA </i></a>",
      "PROVIDER_CUSTOMER_DOMAIN":"Domaine client du fournisseur",
      "PROVIDER_CUSTOMER_ID":"CustomerId du fournisseur",
      "PROVIDER_CUSTOMER_NAME":"Nom du client fournisseur",
      "PROVIDER_DESC_MICROSOFT":"Microsoft",
      "PROVIDER_DESC_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "PROVIDER_DESC_PARTNER":"Partenaire",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nom du fournisseur",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Coût pour le partenaire",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Montant payé",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Date de paiement",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Fournisseur",
      "PROVIDER_PRODUCT_ID":" Identifiant du produit du fournisseur :",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Courriel de facturation",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Code de pays",
      "PROVIDER_RESELLER_ID":"Identifiant du revendeur du fournisseur",
      "PROVIDER_RESELLER_MPNID":"Identifiant MPN du revendeur du fournisseur",
      "PROVIDER_RESELLER_NAME":"Nom du revendeur du fournisseur",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Détails de l'erreur",
      "PROVIDER_RESELLER_ONBOARDING_STATUS":"Statut d'intégration",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"Sélectionner",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft",
      "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Client existant",
      "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Nouveau client",
      "providers":"Fournisseurs",
      "provisioning":"Approvisionnement",
      "PROVISIONING_LABLE_TEXT":"Provisionnement ...",
      "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Maître",
      "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Accord-cadre",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Veuillez sélectionner  un fichier à charger",
      "PSA_BULK_UPLOAD_FAILED_STATUS":"Le mappage d'entités en bloc mis à jour dans C3 a échoué.",
      "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"La cartographie des produits d'achat en gros a été partiellement téléchargée avec succès dans C3. Veuillez vérifier la feuille de mappage d'entité invalide téléchargée pour retravailler.",
      "PSA_BULK_UPLOAD_SUCCESS_STATUS":"La cartographie des produits d’achat en gros est téléchargée avec succès dans C3.",
      "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Tester la connectivité",
      "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Contrat de recherche",
      "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Sélectionner un contrat",
      "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"Recherche de service PSA",
      "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"Sélectionnez le produit PSA",
      "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Aucun accord mappé pour ce cycle de facturation",
      "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"Nous n'avons pas été en mesure d'obtenir la date de début du contrat de PSA, donc incapables de nous aligner",
      "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Aucun contrat mappé pour ce cycle de facturation",
      "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Ces journaux ne tombent pas dans la période sélectionnée",
      "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"La date de début, la date de fin et le rapport d'heure nécessitent des heures de début et de fin ou la valeur des frais d'installation est manquante. Ce sont des valeurs requises pour créer un contrat. Veuillez mettre à jour ces valeurs dans les configurations client.",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"La date de l'historique des abonnements est antérieure à la nouvelle date calculée après l'exécution de l'opération d'alignement sur la date de début du contrat",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Lors de la tentative d'alignement, aucun abonnement actif n'a pu être trouvé pour effectuer l'opération d'alignement.",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Choisir une catégorie",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Sélectionnez la classe de produit",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"Sélectionnez SLA",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Sélectionnez la sous-catégorie",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Sélectionnez UOM",
      "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inactif",
      "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Rechercher un client PSA",
      "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Sélectionnez l'accord",
      "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Choisir une catégorie",
      "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Sélectionnez {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'accord de contrat' }}",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Sélectionner un client",
      "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Sélectionnez le client PSA",
      "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"Sélectionnez le produit PSA",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Sélectionnez {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'Service PSA' : 'Produit PSA' }}",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Sélectionnez le locataire",
      "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"L'actualisation des données est en cours, d'autres options seront accessibles une fois le dernier processus de données terminé.",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Avertissement",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Les données des dates précédentes ne seront pas disponibles après l'utilisation de la date filtrée pour les clients sélectionnés.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Note",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Veuillez noter que pour le téléchargement de factures régénérées, vous avez besoin de l'assistance de l'équipe d'assistance C3.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"La définition de StartDate et EndDate ici se refléterait dans les ajouts créés dans Connectwise comme date de début d'ajout et date d'annulation pour tous les éléments de ligne de facture applicables.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Si aucune date de début ni de date de fin n'est définie, les dates de la période de facturation de la facture seront prises en compte.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"La liste des clients ci-dessous contiendra uniquement les clients dont la ou les factures ont le statut « Finalisé » pour la période sélectionnée.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Si la case <span class=\"font-italic\">(Aligner avec la date de début du contrat)</span> est cochée, nous calculerons la prochaine période de contrat récente à télécharger. Veuillez noter que la prochaine période de contrat récente est calculée uniquement sur une base mensuelle, même si la période de contrat est <span class=\"font-italic\">Annuelle</span>.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"La date de début empêchera les anciens journaux d'être pris en compte pour le téléchargement.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Veuillez utiliser les filtres uniquement si vous essayez de transmettre des données pour la première fois pour un client. Par exemple : <ul><li>Inscription du partenaire, c'est-à-dire, première poussée de C3 vers Autotask</li><li>Intégration d'un nouveau client</li></ul>",
      "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Commencez le processus de téléchargement en sélectionnant des clients et en cliquant sur le bouton <span class=\"bold\">Valider</span> pour démarrer le processus de validation",
      "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Veuillez synchroniser avec le Partner Center avant le téléchargement",
      "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Veuillez noter que toutes les dates (y compris les filtres) seront prises en compte en UTC.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Suivez la progression de la validation en cliquant sur le bouton <span class=\"bold\">Afficher les journaux</span>.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_3":"En cas d'erreurs de validation, apportez les corrections nécessaires et réessayez.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Si la validation est réussie, vous pouvez continuer à <span class=\"bold\">télécharger</span> les données vers Autotask.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Si la validation est réussie, vous pouvez continuer à <span class=\"bold\">télécharger</span> les données sur ConnectWise.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"L'option de répéter le téléchargement n'est donnée qu'en cas d'erreur.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"L'option de répéter le téléchargement n'est donnée qu'en cas d'erreur.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Veuillez cliquer sur ce bouton pour tester la connectivité à PSA avant de procéder à la validation.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Seuls les nouveaux journaux d'historique d'abonnement des clients sélectionnés, le cas échéant, seront pris en compte pour le téléchargement. Les clients sélectionnés peuvent être exclus s'il n'y a pas de nouveaux journaux.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_8":"En cliquant sur la case à côté du titre, vous pouvez valider les journaux d'abonnement pour tous les clients actifs dans le système.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Veuillez sélectionner les clients que vous souhaitez télécharger dans la liste ci-dessous",
      "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Cochez cette case uniquement si vous souhaitez valider les journaux d'abonnement pour tous les clients actifs du système.",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"L'accord PSA n'est pas mappé avec le client c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"Le client externe n'est pas mappé avec le client c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Le produit externe n'est pas mappé avec le produit c3",
      "PUBLIC_SIGNUP_ADD_TO_CART":"Ajouter au panier",
      "PUBLIC_SIGNUP_ADMIN_PORTAL":"Portail administrateur",
      "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"appliquer les promotions",
      "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Demande d'aide à l'inscription publique",
      "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"La passerelle de paiement d'autorisation n'est pas encore prise en charge. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Retour à la liste des produits",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Rechercher un produit",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Recherche de produits",
      "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Les meilleures fonctionnalités de sa catégorie pour vous aider à réussir.",
      "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"En-tête de paragraphe de la bannière d'inscription publique",
      "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Détails de base",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"une fois",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"par 3 ans",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"par mois",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"par an",
      "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Voir le panier",
      "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Contient des addons",
      "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Contient un produit lié",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Veuillez sélectionner au moins une offre Microsoft pour découvrir",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Veuillez appliquer le coupon avant de passer la commande",
      "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Termes et conditions.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Veuillez activer les conditions générales NCE pour commander des produits Microsoft NCE.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-Azure-experience-in-csp/\">Conditions générales NCE.</a>",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"Votre panier est actuellement vide !",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"N'hésitez pas à parcourir notre sélection pour trouver les produits dont vous avez besoin. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Retournez à notre boutique en ligne</strong></a> et commencez à ajouter des articles à votre panier",
      "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Site Internet",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Confirmer",
      "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Contactez le support",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Nous contacter",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Poursuivre votre magasinage",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"J'accepte la dernière",
      "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"Ne fermez pas ce navigateur tant que le processus n'est pas terminé",
      "PUBLIC_SIGNUP_EDIT_TEXT":"Modifier la commande",
      "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"La passerelle de paiement Ezidebit n'est pas encore prise en charge. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_FAILED_WARNING":"Excuses. Le système backend n'a pas réussi à configurer le compte en raison d'une erreur inconnue. Veuillez contacter le support avec le BatchID",
      "PUBLIC_SIGNUP_FAILED_WARNING1":"pour vous aider davantage.",
      "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Marchés pris en charge",
      "PUBLIC_SIGNUP_GO_TO_CART":"Aller au panier",
      "PUBLIC_SIGNUP_GOTO_HOME":"Aller à la page d'accueil",
      "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"S'inscrire",
      "PUBLIC_SIGNUP_INVALID_QUANTITY":"Les produits dans le panier ont des quantités invalides",
      "PUBLIC_SIGNUP_LEARNER_PORTAL":"Portail de l'apprenant",
      "PUBLIC_SIGNUP_LOGS_WARNING":"Le traitement peut prendre un certain temps et le statut sera signalé sur cette page. Si vous vous éloignez de la page ou avez fermé le navigateur par accident, nous vous tiendrons au courant par e-mail.",
      "PUBLIC_SIGNUP_NEXT":"Suivant",
      "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"Pas de description disponible",
      "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Nous avons créé le panier avec succès",
      "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"L'offre est déjà disponible dans le panier",
      "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Veuillez fournir au moins 1 quantité",
      "PUBLIC_SIGNUP_ORGANIZATION_ID":"Identifiant de l'organisation",
      "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"L'inscription n'est pas prise en charge car la passerelle de paiement n'est pas encore configurée. Veuillez contacter l'assistance pour obtenir de l'aide.",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Méthode de paiement ",
      "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Payer maintenant",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Passer la commande",
      "PUBLIC_SIGNUP_PORTLET_HEADER":"S'inscrire",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Panier",
      "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Revoir le panier",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Magasin",
      "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Détail",
      "PUBLIC_SIGNUP_QTY":"QTÉ",
      "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"La quantité doit être supérieure à 0",
      "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"La quantité est requise",
      "PUBLIC_SIGNUP_QUICK_CART":"Fermer le panier",
      "PUBLIC_SIGNUP_QUICK_VIEW":"Aperçu rapide",
      "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"En cliquant sur OK, les produits NCE précédemment ajoutés seront supprimés car les produits NCE de différentes régions de marché ne peuvent pas être achetés en même temps.",
      "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Retour aux détails",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Appliquer des filtres",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Effacer les filtres",
      "PUBLIC_SIGNUP_SIDE_CART":"Panier",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"add-ons",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"add-ons",
      "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Prix estimé",
      "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":"Produit lié",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Supprimer",
      "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"S'inscrire et acheter",
      "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"Vous disposez déjà d’un client MS existant ?",
      "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Veuillez patienter pendant que nous configurons votre compte. Vous pouvez continuer à utiliser votre appareil normalement. Nous vous informerons par e-mail une fois la configuration terminée.",
      "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Passer et continuer",
      "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Payer maintenant",
      "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"Inscription réussie ! Vous pouvez désormais vous connecter au portail C3 en utilisant les identifiants envoyés à",
      "PUBLIC_SIGNUP_SUPPORT":"Soutien",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Termes et conditions d'inscription publique 1",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Termes et conditions d'inscription publique 2",
      "PUBLIC_SIGNUP_TERMS_OF_USE":"Conditions d'utilisation",
      "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Trier de A à Z",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Trier de Z à A",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Trier par",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Prix : du plus élevé au plus bas",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Prix croissant",
      "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Entrer le code de réduction",
      "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Les offres sélectionnées ne sont pas prises en charge pour le pays sélectionné, alors revérifiez les offres sélectionnées",
      "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Acheté le.",
      "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Expire le.",
      "PURCHASE_PRODUCT_MAPPING":"Acheter la cartographie des produits",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Achetez automatiquement les articles de la ligne de devis.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Si nous décochons la case après avoir approuvé le devis, le partenaire/revendeur doit acheter les produits manuellement au nom du client.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Si nous décochons la case après avoir approuvé le devis, le partenaire/revendeur doit acheter les produits manuellement au nom du client.",
      "PurchaseCurrency":"AchatDevise",
      "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Mappage des produits achetés",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Extensions",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Expire le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Commandé le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Nom de produit",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Quantité",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"Se renouvelle le",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Statut",
      "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Modifier la quantité",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"La tentative de harmoniser l’écart a échouée. Veuillez contacter le support pour obtenir de l'aide",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"Correction des quantités terminée avec succès",
      "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Compte attendu",
      "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Est applicable",
      "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Nombre de messages",
      "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"Nom de file d'attente",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Impersonation rapide",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Les utilisateurs suivants sont disponibles pour en représenter d’autres, choisissez l'un des utilisateurs pour continuer",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Personnifier",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Actions",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Rôle",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Utilisateur(s)",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Activités",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Alertes",
      "QUICK_SIDEBAR_LABEL_MORE":"Autre",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Notifications",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"Paramètres",
      "QUICK_SIDEBAR_LABEL_USERS":"Utilisateurs",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Merci d'avoir fourni votre consentement pour QuickBooks. L'application peut être configurée pour accéder à QuickBooks suivant la nouvelle directive de sécurité. Vous pouvez publier les factures de vos clients sur QuickBooks.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions pour Charger les factures dans QuickBooks dans <b>{{applicationName}}</b>.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Le lien ci-dessous s'ouvre dans un nouvel onglet. Veuillez fournir votre consentement avec vos identifiants QuickBooks. Si une erreur se produit lors de votre consentement, veuillez contacter notre équipe d'assistance.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"pour fournir le consentement à QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Si vous avez déjà fourni le consentement, veuillez cliquer sur le bouton <b> Valider le jeton </b> pour vérifier la connectivité avec QuickBooks.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquez sur ce bouton pour vérifier la validité du jeton d'actualisation QuickBooks existant.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Conditions préalables pour que le partenaire publie les factures des clients dans <b> QuickBooks Online </b>",
      "QUICKBOOKS_POST_BATCH_ID":"Identifiant de lot de publication QuickBooks",
      "QUICKBOOKS_POST_LOGS_STATUS":"Statut des journaux de publication Quickbooks",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Le jeton d'actualisation QuickBooks existant n'est pas valide. Veuillez fournir à nouveau votre consentement",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Le jeton d'actualisation QuickBooks existant est valide. Vous pouvez continuer à charger des factures",
      "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Détails du devis",
      "QUOTE_ALL_SELECTION":"Sélectionner tout",
      "QUOTE_APPROVAL_CONFIRMATION":"Etes-vous sûr de vouloir approuver le devis ?",
      "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Devis approuvé et commande passée avec succès.<br/> L'identifiant de commande est <b>{{OrderNumber}}</b>",
      "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Devis approuvé avec succès.",
      "QUOTE_AUDIT_LOG_DELETED_BY":"Supprimé par",
      "QUOTE_AUDIT_LOG_ID":"ID de citation",
      "QUOTE_AUDIT_LOG_NAME":"Nom de la citation",
      "QUOTE_AUDIT_LOG_QUOTE_NAME":"Nom de la citation",
      "QUOTE_BREADCRUMB_QUOTE_ADD":"Créer des devis",
      "QUOTE_BREADCRUMB_QUOTE_EDIT":"Modifier les citations",
      "QUOTE_BREADCRUMB_QUOTE_LIST":"Citations",
      "QUOTE_BUTTON_TEXT_AGE":"âge",
      "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Retour à la liste des citations",
      "QUOTE_BUTTON_TEXT_CANCEL":"Annuler",
      "QUOTE_BUTTON_TEXT_CLOSE":"Fermer",
      "QUOTE_BUTTON_TEXT_COPY":"Copie",
      "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"Écrire un e-mail avec une citation",
      "QUOTE_BUTTON_TEXT_SUBMIT":"Soumettre",
      "QUOTE_BUYER_INFO":"Informations sur l'acheteur",
      "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Informations pour les acheteurs",
      "QUOTE_CHOOSE_BUYER_DETAILS":"Choisissez les coordonnées de l'acheteur que vous souhaitez inclure dans le devis.",
      "QUOTE_CHOOSE_SELLER_DETAILS":"Vérifiez les détails vous concernant et concernant votre entreprise qui seront affichés sur le devis.",
      "QUOTE_COMMENTS_TO_BUYERS":"Commentaires",
      "QUOTE_COMPANY":"Entreprise",
      "QUOTE_CONTACT":"Contact",
      "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Jusqu'à trois contacts de devis peuvent être ajoutés.",
      "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Erreur lors de la copie du texte :",
      "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL copiée avec succès.",
      "QUOTE_COUNTERSIGNATURE":" Contre-signature",
      "QUOTE_CREATE_A_LINE_ITEM":"Créer un poste",
      "QUOTE_CREATED":"Citation créée le",
      "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Informations sur les postes",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Enregistrer",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Fréquence de facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Période de facturation",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Description",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Date d'échéance",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Date de fin",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Date de la facture",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Numéro de facture",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"Est taxable",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Nom",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Prix",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Type de vente",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Date de début",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Prix unitaire",
      "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Veuillez saisir une valeur comportant jusqu'à deux décimales.",
      "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"Le devis ne peut pas être approuvé car le client a été supprimé.",
      "QUOTE_DATE":"Date",
      "QUOTE_DOWNLOAD_AS_PDF":" Télécharger en PDF",
      "QUOTE_EMAIL_ERROR":"Erreur de devis par e-mail",
      "QUOTE_EMAIL_MODAL_HEADER":"Texte de l'e-mail de citation",
      "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement du nom du devis et du client car il existe déjà un devis à l'état de brouillon ou publié.",
      "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement. Le client que vous avez sélectionné possède déjà un devis à l'état de brouillon ou publié.",
      "QUOTE_ERROR_NAME_NOT_UNIQUE":"Une erreur s'est produite lors de l'enregistrement du devis car le nom du devis n'est pas unique ; il existe déjà un autre devis portant le même nom, soit dans un état publié, soit dans un état de brouillon.",
      "QUOTE_EVENT_ERROR":"Erreur",
      "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Veuillez saisir la date d'expiration",
      "QUOTE_EXPIRES":"Le devis expire le",
      "QUOTE_INCLUDE":"Inclus dans le devis",
      "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Contacts",
      "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Choisissez le client",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Commentaires",
      "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Contre-signature",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Code de devise",
      "QUOTE_LABEL_TEXT_EMAIL":"Courriel",
      "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Date d'expiration",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"Prénom",
      "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Prévoir un espace pour une signature écrite",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Pas de signature",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Conditions d'achat",
      "QUOTE_LABEL_TEXT_QUOTE_NAME":"Nom de la citation",
      "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Vos modifications ont été enregistrées",
      "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Options de signature",
      "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Prévoir un espace pour une contre-signature",
      "QUOTE_LABEL_TEXT_TEMPLATE":"Modèle",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Ajouter un client",
      "QUOTE_LABEL_TEXT_WEB_LINK":"Copiez le lien de la page Web ci-dessous pour le partager avec des prospects ou choisissez d'écrire un e-mail avec le devis inclus.",
      "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Veuillez remplir tous les champs obligatoires avant de passer à l'étape suivante.",
      "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Ajouter un élément de ligne personnalisé",
      "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Ajouter un produit de plan",
      "QUOTE_LINE_ITEM_HEADER":"Ligne d'article",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Rabais total",
      "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Total",
      "QUOTE_LINE_ITEM_LABEL_TAX":"Total des impôts",
      "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Valeur totale du devis",
      "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Un maximum de 50 produits peuvent être ajoutés.",
      "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Un maximum de dix quantités peuvent être ajoutées.",
      "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Jusqu'à 9999 éléments de ligne peuvent être ajoutés",
      "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Une quantité minimale d'une est requise.",
      "QUOTE_LINE_ITEM_SUB_HEADER":"Vérifiez la ligne d'article à afficher dans votre devis",
      "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Total",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Quantité",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Les taxes affichées sont approximatives et seront finalisées au moment de la génération de la facture.",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Type de taxe",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Rabais total",
      "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Valeur totale du devis",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Ajoutez au moins un élément.",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Enregistrez les modifications apportées aux lignes de devis avant de continuer.",
      "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Produits pour devis",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Actions",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Coût d'acquisition",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Quantité",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Prix de vente",
      "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Éléments de ligne",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Résumé",
      "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Plans clients",
      "QUOTE_LIST_BREADCRUMB_QUOTE":"Liste de citations",
      "QUOTE_LIST_STATUS_PUBLISHED":"Facture publiée",
      "QUOTE_MOVE_BACK_TO_STATE":"Précédent",
      "QUOTE_MOVE_TO_STATE":"Suivant",
      "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"Le nom de la citation doit comporter moins de 150 caractères.",
      "QUOTE_NAME_REQUIRED_ERROR":"Veuillez saisir le nom de la citation",
      "QUOTE_ONE_TIME_SUBTOTAL":"Sous-total unique :",
      "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Approuver le devis",
      "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Supprimer la citation",
      "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Modifier la citation",
      "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Voir la citation",
      "QUOTE_OWNER":"Propriétaire de devis",
      "QUOTE_PDF_PURCHASE_TERM":"Conditions d'achat",
      "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Plans attribués au client",
      "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"S'il vous plaît entrer votre adresse mail.",
      "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Veuillez entrer le prénom.",
      "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Veuillez entrer le nom de famille.",
      "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Veuillez entrer le numéro de téléphone.",
      "QUOTE_PREPARED_BY":"Préparé par:",
      "QUOTE_PRICE_SUMMARY":"Résumé",
      "QUOTE_PRICE_TOTAL":"Total",
      "QUOTE_PRINTED_NAME":"Nom imprimé",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Nom du plan",
      "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Nom du produit",
      "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Propriétés",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Prix de vente",
      "QUOTE_READY_FOR_REVIEW":"Devis prêt à être révisé",
      "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Réviser et publier",
      "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Informations sur le vendeur",
      "QUOTE_SENDER":"Expéditeur de la citation",
      "QUOTE_SIGNATURE":"Signature",
      "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Signature",
      "QUOTE_STATUS":"Statut",
      "QUOTE_STATUS_APPROVED":"Approuvé pour examen par le client",
      "QUOTE_STATUS_DELETED":"Supprimé",
      "QUOTE_STATUS_DRAFT":"Brouillon",
      "QUOTE_STATUS_EXPIRED":"Expiré",
      "QUOTE_STATUS_OOPS_ERROR":"Oups !",
      "QUOTE_STATUS_ORDERPROCESSED":"Commande traitée",
      "QUOTE_STATUS_PUBLISHED":"Facture publiée",
      "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"Nous ne trouvons pas cette page.",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Action",
      "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Créer un rendez-vous",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Date d'expiration",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"Nom",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Montant",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Propriétaire de devis",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"Statut",
      "QUOTE_TABLE_LABELS_USER_ROLES":"Rôle",
      "QUOTE_TABLE_LABELS_USERS":"Clients)",
      "QUOTE_TABLE_PRICE":"Prix unitaire",
      "QUOTE_TABLE_PRODUCT_SERVICE":"Produit et service",
      "QUOTE_TABLE_QUANTITY":"Quantité",
      "QUOTE_TEXT_EMAIL_DETAILS":"Bonjour, <br/><br/>Votre devis est prêt à être examiné,<br/><br/>Détails clés :<br/><br/>Nom du devis : {{quoteName}}<br/><br/>Valable jusqu'au : {{expirationDate}} <br/><br/>Vous pouvez accéder à votre devis et le consulter en utilisant le lien ci-dessous<br/><br/>{{quoteURL}}<br/><br/>",
      "RCON_APPLY_PROMOTIONS_NCE":"Appliquer la promotion",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"Retour aux déploiements RD",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Déployer un nouveau RD",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Déployer",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Détails",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"Paramètres RD ",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Ajouter un nom de domaine",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Mot de passe Admin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Nom d'utilisateur Admin ",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Vignette préfix de la passerelle DNS",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Nom de l'adresse IP publique de la passerelle",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"SKU de l'image",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Sélectionner une image SKU",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Emplacement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Sélectionner l'emplacement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Aucune instance de Rd",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Groupe de ressource",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Sélectionner l'abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"Taille de la VM",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Sélectionner une image SKU",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"Déploiements RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Déployer un nouveau RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Actualiser le statut de déploiement",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"Déploiements RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Gérer",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Actions",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Déployé sur",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Emplacement",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Groupe de ressource",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Statut",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"Retour aux déploiements RD",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Détails de déploiement",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Détails",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Nom de déploiement",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Environnement",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Déployé sur",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"Déploiement RD commencé avec succès",
      "READ_THROUGH":"Veuillez lire attentivement les conditions générales ci-dessous",
      "REASON_FOR_FAILURE":"Raison de l'échec",
      "REASON_FOR_UPDATE":"Raison de la mise à jour",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Échec du rechargement du rapport de rapprochement",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Réussite du rechargement du rapport de rapprochement ",
      "RECON_CUSTOMER_REPORT_RELOADING":"Rechargement du rapport de rapprochement",
      "RECON_PRODUCT_INPROVISION_TOOLTIP":"Le produit est en état de mise à disposition",
      "RECON_PRODUCT_ONHOLD_TOOLTIP":"Le produit est en attente d'approbation",
      "RECON_REPORT_COLLAPSE_ALL":"Tout réduire",
      "RECON_REPORT_EXPAND_ALL":"Développer tout",
      "RECON_REPORT_MISMATCH_COUNT_BADGE":"Inadéquations",
      "RECON_REPORT_RELOAD":"Rapport de rapprochement rechargé",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Échec de l'initiation du rechargement du rapport de rapprochement",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Le rechargement du rapport de rapprochement a été initié",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Rechercher",
      "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Recherche par offre, identifiant d'abonnement",
      "RECON_REPORT_SHOW_FIXED_INACTIVE":"Afficher les inactifs fixes",
      "RECON_REPORT_SHOW_MISMATCH":"Afficher uniquement les incohérences",
      "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" Identifiant d'abonnement Azure :",
      "RECON_TABLE_TEXT_BILLING_CYCLE":"Cycle de facturation :",
      "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Abonnement fournisseur",
      "RECON_TABLE_TEXT_REGION":" Région :",
      "RECON_TABLE_TEXT_RESERVATION_ID":" Identifiant de réservation :",
      "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" Identifiant de la commande de réservation :",
      "RECON_TABLE_TEXT_SUBSCRIPTION_ID":"Abonnement :",
      "RECON_TABLE_TEXT_TERM_DURATION":"Validité :",
      "RECON_TABLE_TEXT_TRIAL_OFFER":"Offre d'essai",
      "RECONCILIATION_CUSTOMER_NAME":"Nom du client",
      "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"FOURNISSEUR",
      "RECONCILIATION_MISSMATCH":"Match manqué de réconciliation",
      "RECONCILIATION_NOTES":"Remarques",
      "RECONCILIATION_NOTES_MISMATCH":" Les éléments en <span class='text-danger'>rouge</span> indiquent une incompatibilité",
      "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Les articles en <span class='text-mediumvioletred'>rouge violet</span> ​indiquent une inadéquation liée à la promotion. Contactez l'équipe d'assistance pour relayer l'avantage promotionnel au client final.",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"Les éléments en <span class='red'>rouge</span>  indiquent des écarts dans les quantités",
      "RECONCILIATION_NOTES_RI_INVOICE":"Suite à l’intégration d’une instance réservée, toutes les réservations supplémentaires comportant la même offre dans le Reservation OrderId intégré seront facturées au prix d’intégration.",
      "RECONCILIATION_NOTES_SUSPENDED":"Les éléments <span class = 'strike-through'> saisis </ span> indiquent des abonnements suspendus",
      "RECONCILIATION_PROVIDER":"Fournisseur",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Montant du paiement",
      "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"Êtes-vous sûr de vouloir enregistrer un paiement de <%= montant%> (<%= currencyCode%>) à l'encontre du client ?<br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide supplémentaire concernant les paiements qui ne s'affichent pas ou qui ont été enregistrés par erreur.</small>",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Code de devise",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Sélectionner un client",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Clients",
      "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Le client est requis pour enregistrer le paiement.",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Enregistrer le paiement anticipé",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Remarques",
      "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Code de devise",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Sélectionner un client",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Paiement enregistré avec succès",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Date de paiement",
      "RECORD_ID":"Identifiant d'enregistrement",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Identifiant d'enregistrement reçu du service externe",
      "RECORD_MANUAL_PAYMENT_CONFIRMATION":"Voulez-vous vraiment enregistrer un paiement de <%= currencySymbol%> <%= amount%> sur cette facture ?<br/><small> Veuillez contacter l'assistance si vous avez besoin d'aide concernant des paiements qui ne s'affichent pas. ou ont été enregistrés par erreur.</small>",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Nom de l'entité",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Nom",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importer",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Paiements à l'importation",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Modèle",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catalogue",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Télécharger le modèle",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Paiements à l'importation",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Erreur",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Réussite ",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"C3Id",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Code de devise",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Montant du paiement",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Date de paiement",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Nom",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Remarques",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Valider le catalogue",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Erreur",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Réussite ",
      "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Le montant du paiement doit être supérieur à 0,00.",
      "RECORD_PAYMENT_PAID_DATE":"Date de paiement",
      "RECURRENCES_MAX_ERROR":"La valeur doit être inférieure ou égale à 11",
      "RECURRENCES_MIN_ERROR":"La valeur doit être supérieure ou égale à 0",
      "RECURRENCES_TOOLTIP":"Nombre de fois que le coupon de réduction peut être appliqué",
      "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"ACTUALISER_LA_LISTE_D'ENTITÉS_EXTERNES_EN_LOCAL",
      "REFRESH_TOKEN":"Actualiser le jeton",
      "REFRESH_TOOLTIP_TEST_PAYMENT":"Actualiser",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Merci d'avoir choisi <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Veuillez compléter les informations ci-dessous afin de traiter votre nouvelle demande.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Si vous possédez un compte Microsoft déjà existant qui n'est pas avec nous, veuillez <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">nous contacter</a> pour de l'aide.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Veuillez entrer un coupon valide ",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Courriel",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Veuillez fournir un identifiant email valide. Nous enverrons un email avec un nom d'utilisateur et un mot de passe une fois l'inscription terminée",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Prénom",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Nom de famille",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Numéro de téléphone",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Ville",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Nom de l'entreprise",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Ligne 5",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Ligne 6",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Le nom de domaine principal",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"\"Pour débuter, vous devez créer un compte en utilisant le nom de votre entreprise suivi de \".onmicrosoft.com\". Si votre organisation possède déjà son propre domaine personnalisé que vous souhaitez utiliser sans \".onmicrosoft.com\", vous pourrez le modifier ultérieurement sur Office 365\"",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Qu'est-ce que c'est",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"État",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Code postal",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Date de contrat",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"J'accepte la dernière",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Configurez votre plan",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Pour chaque offre du plan que vous souhaitez ajouter, prière de choisir une quantité minimum de 1",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Contact principal",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Profile de la société",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Contrat client Microsoft",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Compte bancaire",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Carte de crédit",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Supprimer le coupon",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Veuillez entrer un coupon valide ",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Code promotionnel appliqué : <b>{{CouponCode}}</b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Type de paiement",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Veuillez sélectionner le type de paiement",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Sommaire de plan ",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Frais récurrents (tous les mois)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"puis <span class = 'bold'> $ {{totalCount}} </ span> lors du premier renouvellement le {{nextBillingDate}}",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Total",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"REMARQUE:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Les frais d'achat sont calculés au prorata pour le reste des jours dans le cycle de facturation en cours.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Licences",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"Vous devez spécifier si le compte ACH est un compte professionnel ou non!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Utilisateur créé avec succès",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Impossible de vérifier la disponibilité du domaine",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Veuillez entrer un nom de domaine valide. Les noms de domaine peuvent uniquement contenir des lettres et des chiffres; pas de traits d'union, de points ou d'Under scores",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"Statut d'enregistrement",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Votre enregistrement a été traité avec succès. Veuillez vous connecter sur le portail en utilisant les identifiants suivant: ",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Nom d'utilisateur",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Mot de passe",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Un email vous a été envoyé avec les détails suivants au cas où vous auriez manqué de les recopier d'ici",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Traiter ma commande",
      "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Veuillez fournir la raison du refus",
      "REJECT_ALL_PRODUCT_POPUP_BUTTON":"Rejeter",
      "REJECT_ALL_PRODUCT_POPUP_HEADER":"Rejeter tous les produits",
      "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Rejeter tous les produits",
      "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Veuillez expliquer la raison du refus de la demande",
      "REJECT_PRODUCT_COMMENT_HEADER":"Rejeter le produit",
      "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Veuillez indiquer les raisons pour lesquelles ce produit doit être rejeté",
      "REJECT_PRODUCT_POPUP_BUTTON":"Rejeter",
      "REJECTED_BY":" Rejeté par",
      "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Veuillez entrer une raison pour refuser ce produit",
      "RELEASE_PRODUCT_OWNERSHIP":"Libérer la propriété du produit",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Échec de la libération de la propriété du produit",
      "RELEASE_SEED_ERROR":"Vous ne pouvez réduire la quantité tout en libérant, veuillez réessayer ",
      "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"J'ai essayé de mettre à jour plus que la quantité disponible pour \"{{childName}}\"",
      "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"J'ai essayé de mettre à jour plus que la quantité disponible au parent \"{{parentName}}\" pour \"{{childName}}\"",
      "RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'>Une erreur s'est produite lors du traitement des données pour  <b> {{firstName}} </b>. L'erreur est : {{errorMessage}} </span><br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'>Enregistrements traités avec succès pour  <b> {{firstName}} </b></span><br/>",
      "RELOAD_RECONCILIATION_STATUS":"Recharger le statut de rapprochement",
      "RELOAD_TOOLTIP_REVENUE":"Recharger",
      "reLoadingCustomersProfileData":"Synchronisation des profils client et fournisseurs",
      "REMOVE_ALL_SELECTED_RGS":"Voulez-vous supprimer tous les groupes de ressources sélectionnés ?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Supprimer",
      "REMOVE_PARTNER_PRODUCT_ICON":"Supprimer",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Supprimer le produit",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"Êtes-vous sûr de vouloir supprimer le produit",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Voulez-vous vraiment révoquer l'accès pour cet utilisateur ?",
      "RENEWAL_ALL_DAYS":"Tout",
      "RENEWAL_DAYS_TEXT":"Jours",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"Télécharger",
      "REPORT_ACTIONS_SEND_EMAIL_TEXT":"Envoyer un e-mail",
      "REPORT_ACTIONS_TEXT":"Actions",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Tous les clients",
      "REPORT_FILE_INVALID_EMAILS_ERROR":"Veuillez entrer une adresse e-mail valide",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Ce champ est obligatoire",
      "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "REPORT_FILE_TYPES_SUB_HEADER":"Sélectionnez un format de fichier",
      "REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Sélectionnez la période",
      "REPORT_INTERVAL_TEXT_CUSTOME":"Personnalisé",
      "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"Les 12 derniers mois",
      "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"Les 3 derniers mois",
      "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"Les 6 derniers mois",
      "REPORT_INTERVAL_TEXT_TILL_DATE":"Jusqu'à la date",
      "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport Factures et cotisations",
      "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Retour au rapport d'utilisation",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Afficher l'historique des téléchargements",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Afficher l'historique des téléchargements",
      "REPORT_POPUP_ACTIONS_TEXT":"Actions",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Télécharger",
      "REPORT_POPUP_EMAIL_TEXT":"Courriel",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Sélectionner tout",
      "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"Envoyer un e-mail",
      "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Sélectionnez un moyen d'obtenir le rapport sur l'historique des abonnements",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Veuillez saisir les adresses e-mail des destinataires, séparées par des virgules (,) ou des points-virgules (;) et cliquez sur « Soumettre » pour recevoir un fichier contenant la liste des rapports sur l'historique des abonnements.",
      "REPORT_SELECT_CUSTOMER_All":"Tous les clients",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Sélectionner un client",
      "REPORT_SELECT_RESELLER_ALL":"Tous les revendeurs",
      "REPORT_SEND_EMAIL_TEXT":"Courriel",
      "REPORT_TEXT_FROM":"De",
      "REPORT_TEXT_TO":"À",
      "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtrer par :",
      "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"Le coût pour le partenaire est obligatoire si le type de facturation est en pourcentage et facultatif pour l'unité de type de facturation.",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Télécharger le catalogue",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catalogue",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importer",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Modèle",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Télécharger",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Une erreur s'est produite lors du téléchargement du fichier",
      "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Télécharger le modèle",
      "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Télécharger l'historique des téléchargements",
      "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importer le catalogue",
      "REPORT_USAGE_INSTRUCTIONS_PART_1":"Téléchargez le catalogue pour obtenir les identifiants client et produit des offres Partenaires basées sur l'utilisation.",
      "REPORT_USAGE_INSTRUCTIONS_PART_2":"Téléchargez le modèle et remplissez le détail d'utilisation des produits du catalogue.",
      "REPORT_USAGE_INSTRUCTIONS_PART_3":"Assurez-vous que les données respectent les règles suivantes : <br />&nbsp;&nbsp <b>Units</b> : Number <br />&nbsp;&nbsp <b>UnitOfMeasure</b> : Text <br />&nbsp; &nbsp <b>CostToPartner</b> : Decimal <br />&nbsp;&nbsp <b>CurrencyCode</b> : Text (Code de devise standard ISO.) <br />&nbsp;&nbsp <b>UsageDate</b> : Date (AAAA-MM-JJ)",
      "REPORT_USAGE_INSTRUCTIONS_PART_4":"Cliquez sur <strong class=\"text-uppercase\">Sélectionner</strong> et accédez au fichier.",
      "REPORT_USAGE_INSTRUCTIONS_PART_5":"Cliquez sur <strong class=\"text-uppercase\">Valider l'entrée</strong> pour que le système valide l'entrée.",
      "REPORT_USAGE_INSTRUCTIONS_PART_6":"Revoir les erreurs dans le tableau une fois la validation terminée.",
      "REPORT_USAGE_INSTRUCTIONS_PART_7":"Corrigez les erreurs dans le fichier et téléchargez à nouveau.",
      "REPORT_USAGE_INSTRUCTIONS_PART_8":"S'il n'y a pas d'erreurs de validation dans l'entrée, le bouton <strong class=\"text-uppercase\">Importer le catalogue</strong> est activé. Cliquez sur le bouton pour soumettre vos modifications.",
      "REPORT_USAGE_NOTE":"Remarque :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Erreur",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Réussite ",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Coût pour le partenaire",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Devise",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Identifiant du client",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nom du client",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Erreur d'importation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"État de l'importation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"ID Produit ",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Unité de mesure",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Unités",
      "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Date d'utilisation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Erreur de validation",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Statut de validation",
      "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Valider l'entrée",
      "REPORT_USER_LICENSE_ASSIGNMENT":"Le rapport d’attribution de licence utilisateur sera envoyé à l’e-mail fourni.",
      "reportusage":"Utilisation du rapport",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Pour demander une relation de revendeur avec un client, copiez et collez le texte suivant, y compris l'URL, dans un e-mail. Modifiez le texte si nécessaire et envoyez l'e-mail à votre client.",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Incluez des privilèges d'administration déléguée pour Azure Active Directory et Office 365.",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"Texte de l'e-mail",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Demande de relation de revendeur",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Demande d'intégration massive initiée par",
      "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"La demande de chargement des promotions est en file d'attente",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Demande de chargement de factures sur Quickbooks initiée par",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"La demande de chargement de factures sur Xero est effectuée par",
      "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Demande de mise à jour du statut de l'offre NI",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Demande de chargement de factures sur QuickBooks acceptée",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Demande de chargement de factures sur Xero acceptée",
      "REQUESTED_PRODUCT_STATUS":"Statut demandé",
      "requestForResellerRelationship":"Demande de relation de revendeur",
      "REQUIRED_FIELD_TEXT":"Ce champ est obligatoire",
      "REQUIRED_PATTERN_PHONE":"Seuls les chiffres et les + sont autorisés",
      "REQUIRED_PATTERN_TEXT":"Les caractères spéciaux tels que `~!@#{}|/<>' ne sont pas autorisés",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Une erreur s’est produite lors de l'exportation des postes de facture de revendeur",
      "RESELLER":"(Revendeur)",
      "Reseller":"Revendeur",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Ajustement",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Est imposable",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ajouter",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Sélectionner tout",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Liste d'extensions",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Ajouter plus de produits",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Supprimer un plan",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Consulter les offres de plan",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Enregistrer le plan",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Recherche appliquée",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Recherche rapide",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Catégorie",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Fournisseurs",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Types de service",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Durée du mandat",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Réussite ",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Prix mis à jour avec succès",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Quantité",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Utilisation",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Aucun produit trouvé",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Macro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":"Prix",
      "RESELLER_AZURE_MACRO_VALUE":"Valeur de la macro",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Revendeurs",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Récapitulatif des revenus et des coûts du revendeur",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Retour à la liste",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Retour aux revendeurs",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Lier un nouveau fournisseur",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Ajouter revendeur",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Nouveau",
      "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Existant (Multiple)",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Ajouter revendeur",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Coordonnées",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Type de paiement",
      "RESELLER_CAPTION_TEXT_PERSONAL_DETAILS":"Détails personnels",
      "RESELLER_CAPTION_TEXT_RESELLER":"Revendeurs",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Profil de facturation du revendeur",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Détails du revendeur",
      "RESELLER_CAPTION_TEXT_RESELLER_NAME_AND_MPN_ID":"Nom du revendeur et identifiant MPN",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Profil de revendeur",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Activez cette option pour appliquer des frais de traitement aux paiements par carte de crédit.",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"Inclure les frais de traitement de carte de crédit ?",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Frais en pourcentage pour le traitement des cartes de crédit.",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Frais de traitement de carte de crédit %",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Cocher cet indicateur empêche les clients de réduire la quantité d'abonnement",
      "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Autoriser le revendeur à initier la transaction pour sa ou ses factures",
      "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Sélectionnez si vous souhaitez appliquer la promotion au revendeur ou non",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Permettre aux clients de gérer les abonnements Azure",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permettre aux clients de définir des groupes sur les abonnements Azure",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du revendeur azure. Par exemple: Entrer 1.15 si la majoration doit être 15 %",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Pour référence interne. Cet identifiant peut être utilisé pour associer le client à un enregistrement dans votre système de comptabilité interne",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Jour du mois où les factures sont approuvées automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Jour du mois où les paiements sont traités automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Jour du mois où les paiements sont traités automatiquement",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Date d'échéance des factures",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"Nombre de jours dus pour le paiement de la facture",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Jour du mois où les factures sont finalisées automatiquement",
      "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'adresse nouvellement ajoutée comme adresse par défaut du revendeur.",
      "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'e-mail nouvellement ajouté comme e-mail par défaut pour le revendeur.",
      "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"L'activation de cette option désignera automatiquement le numéro de téléphone nouvellement ajouté comme numéro de téléphone par défaut du revendeur.",
      "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les lignes de facture uniques du revendeur.",
      "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements logiciels perpétuels des revendeurs",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Définir le numéro de bon de commande pour le revendeur",
      "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Activer la fonctionnalité de transmission des factures à un service de comptabilité externe",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Cochez cet indicateur si vous souhaitez que le système traite les paiements (CC/ACH, le cas échéant)",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PENCENTAGE":"Majoration (%) à appliquer sur l'utilisation des instances réservées du revendeur.",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur la réservation azur du revendeur.",
      "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Le revendeur recevra le courriel avec les détails de la facture, une fois la facture approuvée et si les notifications par courriel sont activées",
      "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Le revendeur recevra une notification par courriel concernant les factures ayant des montants nuls",
      "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements logiciels revendeurs.",
      "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Nous ne pouvons pas traiter cette demande car les transactions sont désactivées.",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Définir le code fiscal pour les revendeurs",
      "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les abonnements tiers revendeurs.",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Permettre au client de réduire le nombre de licences",
      "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Autoriser le revendeur à initier la transaction",
      "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Appliquer la promotion au revendeur",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Voulez-vous activer Azure ?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Voulez-vous activer Azure pour Entreprise ?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Majoration pour l'utilisation Azure",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Identifiant de référence de facturation",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Jour où les revendeurs peuvent consulter les factures",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Jour du paiement automatique",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Jour du paiement automatique",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Date d'échéance des factures",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Jours d'échéance des factures",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Jour où les factures sont finalisées",
      "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Attribuer automatiquement une nouvelle adresse par défaut",
      "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Attribuer automatiquement un nouvel e-mail par défaut",
      "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Attribuer automatiquement un nouveau numéro de téléphone par défaut",
      "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Balisage pour les éléments de ligne de facture unique Microsoft",
      "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Balisage pour les logiciels perpétuels",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"Numéro de commande",
      "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Enregistrer les factures auprès d'un service de comptabilité externe",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Désirez-vous automatiser le paiement de factures ?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Envoyer des courriels de facture automatiquement",
      "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Envoyer des factures de montant zéro",
      "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour l'abonnement au logiciel",
      "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Arrêter toutes les transactions",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Code fiscal",
      "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour tiers",
      "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorer le revendeur du service externe",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Les numéros de facture commenceront par ce texte",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texte du numéro de facture",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permet à l'utilisateur de visualiser les cotisations impayées jusqu'à la facture en cours",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Afficher les cotisations impayées sur la facture",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Configuration du revendeur a été rétablie avec succès",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Configuration du revendeur sauvegardée avec succès",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Vous n'avez pas la possibilité d'effectuer des modifications",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Configuration du revendeur mise à jour avec succès",
      "RESELLER_CONFIGURATION_OF":"Configuration revendeur de",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Aperçu rapide des recettes de votre entreprise",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilité",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Résumé de vos comptes débiteurs",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Compte à recevoir",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Les paiements doivent être collectés",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"En souffrance",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Vos clients actifs",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clients",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Alerte pour une facture en attente",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Facture",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Un vendeur avec le même identifiant mpn existe déjà dans le système. Veuillez vérifier l'identifiant mpn donné.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Détails bancaires",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Détails de la carte de crédit ",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Veuillez sélectionner le type de paiement",
      "RESELLER_IMPERSONATE":"Personnifier",
      "RESELLER_IMPERSONATE_USER_ROLE":"Personnifier",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"représenter son propre rôle ",
      "RESELLER_INVOICE":"Facture",
      "RESELLER_INVOICE_TEXT_LINE":"La facture sera envoyée à votre adresse de facturation. Merci!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Statut de facture mis à jour avec succès",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Paiement enregistré avec succès",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Retour au résumé des revenus et des coûts",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Factures",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Postes de facture",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Numéro de compte",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Type de compte",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Ligne d'adresse 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Ligne d'adresse 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Nom de banque",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Numéro de carte ",
      "RESELLER_LABEL_TEXT_CITY":"Ville",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Nom de l'entreprise",
      "RESELLER_LABEL_TEXT_COUNTRY":"Pays",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Type de carte de crédit",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"Courriel",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"Prénom",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Est un compte d’entreprise",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Nom de famille",
      "RESELLER_LABEL_TEXT_MONTH":"Mois",
      "RESELLER_LABEL_TEXT_MPN_ID":"Identifiant MPN",
      "RESELLER_LABEL_TEXT_NAME":"Nom",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Nom de compte",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Nom sur la carte",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Numéro de téléphone",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Numéro d'acheminement",
      "RESELLER_LABEL_TEXT_STATE":"État",
      "RESELLER_LABEL_TEXT_YEAR":"Année",
      "RESELLER_LABEL_TEXT_ZIP":"Code postal",
      "RESELLER_LIST_PAYMENT_METHOD":"Méthode de paiement ",
      "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marge pour l'abonnement au logiciel",
      "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Majoration pour l'abonnement tiers",
      "RESELLER_NAME_UPDATE_FAILURE":"Échec de la mise à jour du nom du revendeur",
      "RESELLER_NAME_UPDATE_SUCCESS":"Succès de la mise à jour du nom du revendeur",
      "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Le revendeur n'est affecté à aucun gestionnaire de compte",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"Aucun revendeur trouvé dans CSP. S'il vous plaît vérifier l'identité mpn fournie.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Erreurs détectées dans les données chargées. Télécharger les erreurs, corrigez-les et essayez de charger à nouveau.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Veuillez mentionner si les détails ACH sont liés à un compte professionnel",
      "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"ID MPN du revendeur",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Courriel de facturation",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"Pays",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"Identifiant",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"Identifiant MPN",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Nom",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Réussite ",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Détails de paiement enregistrés",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Profil de paiement supprimé",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Profil de paiement défini par défaut",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Voulez-vous vraiment supprimer cette option de paiement ?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Définir la macro et l'enregistrer avec toutes les offres",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Ajouter les offres manquantes",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Enregistrer le plan avec toutes les offres",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Cycles de facturation sélectionnés",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Catégories sélectionnées",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Catégories de fournisseurs sélectionnées",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Fournisseurs sélectionnés",
      "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Marchés pris en charge",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Catégories",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Utilisation de la macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Valeur de la macro d'utilisation",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valeur de la macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Catégories de fournisseurs",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Fournisseurs",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Types de service",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"La demande de clonage du plan revendeur est acceptée et en cours de traitement",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"La demande de création d'un nouveau plan revendeur avec toutes les offres est acceptée et en cours de traitement",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Fixation des prix",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Sélectionner",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Appliquer une macro pour définir les nouveaux prix",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Copier les prix du Plan Revendeur Source",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Vos modifications ont été soumises avec succès",
      "RESELLER_PLAN_DISABLE_BUTTON":"Désactiver le plan",
      "RESELLER_PLAN_ENABLE_BUTTON":"Activer le plan",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"L'offre est interrompue avec succès",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Discontinuer l'offre",
      "RESELLER_PLAN_SELECTED_OFFERS":"Offres sélectionnées",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Plans",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Annuler",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Plan de clonage",
      "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Création de plans",
      "RESELLER_PLANS_CAPTION_1":"Ajouter un nouveau plan",
      "RESELLER_PLANS_CAPTION_2":"Détails pour plan -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Plans",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Afficher",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Prix et offres",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Télécharger la liste des prix et des offres",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Prix et offres",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"Prix ERP",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Type de contrat de licence",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Liste de prix",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Nom de l'offre",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Valide de",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Une erreur s’est produite lors de l'exportation de la liste des prix et des offres du revendeur",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Profil d'entreprise",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Annuler",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Supprimer",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Modifier",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Enregistrer",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Profil client",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Ajouter une adresse",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"Ajouter un courriel",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Ajouter un numéro de téléphone",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Adresses",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Détails de base",
      "RESELLER_PROFILE_PARTNER_CANCEL":"Annuler",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Nom de l'entreprise",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"Adresses courriel",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Prénom",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Nom de famille",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Détails de base mis à jour avec succès",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Aucun enregistrement trouvé !",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Numéros de téléphone",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"Soumettre",
      "RESELLER_PROFILE_PAYMENT_ADD":"Ajouter",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Compte bancaire",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Carte de crédit",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Aucun profil trouvé. Ajoutez un nouveau profil de paiement en cliquant sur le bouton AJOUTER",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Profils de paiement",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Date de création du compte :",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Adresse de facturation",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"Date d'intégration C3",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Profil de revendeur",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Adresse d'expédition",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Comptes d'utilisateur",
      "RESELLER_PROFIT_BILLED_AMOUNT":"Montant facturé au revendeur",
      "RESELLER_PROFIT_COST_ON_PARTNER":"Coût pour le distributeur",
      "RESELLER_PROFIT_COST_ON_RESELLER":"Coût pour le revendeur",
      "RESELLER_PROFIT_CUSTOMER_NAME":"Montant client",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Nom du revendeur",
      "RESELLER_PROFIT_PRODUCT_NAME":"Nom de produit",
      "RESELLER_PROFIT_PROFIT_AMOUNT":"Bénéfice du distributeur",
      "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Montant facturé par le revendeur",
      "RESELLER_PROFIT_RESELLER_NAME":"Nom du revendeur",
      "RESELLER_PROFIT_SUMMARY_REPORT":"Télécharger le rapport",
      "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"Aucune donnée disponible au téléchargement",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Tout",
      "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Voir par revendeur",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Bénéfice du revendeur",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Bénéfice du distributeur %",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"% de bénéfice du revendeur",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"Non",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"Oui",
      "RESELLER_REGISTRATION_SUCCESS":"L'inscription du revendeur a été complétée avec succès.",
      "RESELLER_RELATIONSHIP_LBL":"Veuillez partager le contenu suivant avec le client pour établir une relation de revendeur",
      "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Sélectionner un revendeur",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Afficher par",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Veuillez entrer le pourcentage de taxe valide",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Veuillez remplir les champs du nom de taxe",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Ajouter une nouvelle notification par courriel d'événement",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"balise de partenaire supprimée avec succès",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"balise partenaire mise à jour avec succès",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Il n'y a pas de fournisseurs configurés, veuillez contacter le soutien technique",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Il n'y a pas de clés disponibles pour ce fournisseur",
      "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Revendeur : Usurpation d'identité rapide",
      "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Aucun marché n'a été trouvé pour le code devise choisi",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Configuration revendeur",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Paramètres du revendeur",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Assigner des offres",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Modifier",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Personnifier",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Gérer les liens fournisseurs",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Créé le",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"Nom",
      "RESELLER_TABLE_SIGNUP_DATE":"Date d'inscription",
      "resellers":"Revendeurs",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Facture",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Rapprochement",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test de facturation de revendeur",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Revendeur ajouté avec succès.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Paiements revendeur",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Détails du gestionnaire de compte",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Gérer les plans",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"Courriels",
      "RESEND_INVOICE":"Renvoyer la facture",
      "RESEND_INVOICE_HEADER_TEXT":"Veuillez entrer les identifiants de courriel afin de renvoyer la facture",
      "RESEND_INVOICE_NOTE":"<b>Remarque : </b> si vous souhaitez renvoyer la facture à plusieurs identifiants de messagerie, veuillez les indiquer en les séparant par une virgule. Ex : abc@mail.com,new@mail.com",
      "RESEND_INVOICE_USERS_LIST":"Liste d'utilisateurs pour renvoi de facture",
      "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Prix en détail (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Coût pour le partenaire (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Prix de vente (%)",
      "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Saisissez un pourcentage d'IR valide",
      "RESET_VALUE":"Réinitialiser la valeur",
      "Resolved":"Résolu",
      "RESOURCE_GROUP_NAME":"Nom du groupe de ressources",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Le paiement a été initié avec succès. Le traitement prendra quelques temps. Veuillez vérifier le statut après un certain moment.",
      "REVENDEUR":"(Revendeur)",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Montant facturé",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Envoyer toutes les factures par courriel",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Catégorie",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Coût pour le partenaire",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Client",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Facture du distributeur",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Fournisseur",
      "REVENUE_AND_COST_SUMMARY_RESELLER":"Revendeur",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"Type de vente",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Redevances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Factures et redevances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Paiements",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Défaillances",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Les frais de paiement",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Afficher par",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Génération de factures terminée",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Une erreur s'est produite lors de la génération des factures clients",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Factures clients générées avec succès",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Détails de paiement du fournisseur",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Chargement des détails de paiement du fournisseur...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Régénérer la facture",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Afficher les factures",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Afficher l'historique des abonnements",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Montant",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Période de facturation",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Client",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Coût sur le Partenaire",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Montant du bénéfice",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Coût pour le client",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Coût pour le partenaire",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Bénéfice",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Recevoir toutes les factures par courriel",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Détails de paiement du fournisseur",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Catégorie",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Client",
      "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Durée",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Dernière période de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 périodes de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 périodes de facturation",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 périodes de facturation",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Voulez-vous vraiment révoquer l'attribution",
      "RFRESH_DATA_FROM_PSA":"Actualiser les données de PSA",
      "RIBBON_TITLE_DEPARTMENT":"Département",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Veuillez entrer la quantité pour au moins une offre de plan de niveau supérieur.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Remplissez les données de l'utilisateur",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Portée d'affectation des rôles",
      "ROLE_NAME_C3_SUPPORT":"Soutien C3",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Responsable de compte client",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"L'utilisateur pourra accéder aux cartes et aux menus concernant les produits",
      "ROLE_NAME_CUSTOMER_ADMIN":"Administrateur client",
      "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"L'utilisateur peut afficher et effectuer toutes les actions disponibles dans le portail client",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Administrateur client (Pour les Ventes)",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Administrateur client (Pour les Ventes)",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Administrateur Client Lite",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"L'utilisateur dispose de toutes les autorisations d'administrateur client, mais ne pourra pas acheter de nouveaux abonnements",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Administrateur client Lite Plus",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"L'utilisateur dispose de toutes les autorisations de Customer Admin Lite mais ne pourra pas gérer les produits d'une durée d'un an ou plus",
      "ROLE_NAME_CUSTOMER_MAINTENANCE":"Entretien client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Entretien client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Un rôle pour maintenir l'application par client",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Un rôle pour maintenir l'application par client",
      "ROLE_NAME_CUSTOMER_READER":"Lecteur client",
      "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"L'utilisateur ne peut lire que les données disponibles dans le portail client",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Super Administrateur Client",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Super Administrateur Client",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Admin de département",
      "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Qui peut gérer le département et ils ne peuvent gérer que les produits relevant des départements spécifiques.",
      "ROLE_NAME_DEPARTMENT_READER":"Lecteur de département",
      "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Qui peut uniquement lire les données disponibles dans les Départements Spécifiques.",
      "ROLE_NAME_DIRECT_ADMIN":"Administrateur Direct",
      "ROLE_NAME_FINANCE_ADMIN":"Administrateur des finances",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Peut gérer les pages liées aux finances.",
      "ROLE_NAME_GROUP_ADMIN":"Administrateur du groupe",
      "ROLE_NAME_HELP_DESK":"Centre d'assistance",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Centre d'assistance",
      "ROLE_NAME_INDIRECT_ADMIN":"Administrateur indirect",
      "ROLE_NAME_PARTNER_MAINTENANCE":"Entretien des Partenaires",
      "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Un rôle pour maintenir l'application par Partenaire",
      "ROLE_NAME_SITE_ADMIN":"Administrateur de site",
      "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Qui peut gérer le Site et ne peut gérer que les produits des Sites Spécifiques.",
      "ROLE_NAME_SITE_READER":"Lecteur du site",
      "ROLE_NAME_SITE_READER_DESCRIPTION":"Qui peut uniquement lire les données disponibles sous le Site.",
      "ROLE_NAME_SYSTEM_ADMIN":"Admin du système",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Peut accéder/modifier toutes les pages du portail.",
      "ROLE_NAME_SYSTEM_READER":"Lecteur du système",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Peut visiter toutes les pages, mais ne peut pas apporter de modifications.",
      "ROLE_NAME_USER":"Utilisateur",
      "ROLE_NAME_USER_ADMIN":"Administrateur utilisateur",
      "ROLE_NOTE_C3_SUPPORT":"Le soutien technique de C3 peut résoudre les cas de discordance de quantité et c'est un super ensemble d'autorisations",
      "ROLE_NOTE_CUSTOMER_ADMIN":"L'administrateur client peut consulter toutes les données et effectuer toutes les actions relatives au domaine",
      "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"L'administrateur client (Pour les Ventes) peut voir et effectuer des actions sur les données, mais pas sur les abonnements des Partenaires",
      "ROLE_NOTE_CUSTOMER_READER":"Lecteurs clients ayant un accès en lecture seule à toutes les données relatives au client.",
      "ROLE_NOTE_DIRECT_ADMIN":"Administrateur Direct, serait capable de faire tout ce qu'un administrateur système peut faire, mais uniquement pour les clients directs, donc pas d'accès aux parties Sell Indirect",
      "ROLE_NOTE_FINANCE_ADMIN":"Les administrateurs des finances peuvent accéder au résumé des revenus et des coûts des clients, définir le % d'impôt, consulter le rapport de rapprochement",
      "ROLE_NOTE_GROUP_ADMIN":"Les administrateurs de groupe peuvent gérer tous les aspects liés aux abonnements Azure sous leur groupe.",
      "ROLE_NOTE_HELP_DESK":"Les utilisateurs du centre d'assistance peuvent afficher toutes les informations du client, intégrer de nouveaux clients et fournir un soutien. Les utilisateurs du centre d'assistance peuvent personnifier et accéder aux données des clients",
      "ROLE_NOTE_INDIRECT_ADMIN":"L'administrateur indirect pourrait faire tout ce qu'un administrateur système peut faire, mais uniquement pour les revendeurs (et leurs clients), donc aucun accès aux parties Sell Direct.",
      "ROLE_NOTE_SYSTEM_ADMIN":"L'administrateur du système peut consulter toutes les données et effectuer toutes les actions relatives au partenaire et aux clients",
      "ROLE_NOTE_SYSTEM_READER":"Lecteurs du système ayant un accès en lecture seule à toutes les données relatives au partenaire. Les lecteurs ne peuvent pas personnifier",
      "ROLE_NOTE_USER_ADMIN":"L'administrateur de l'utilisateur peut voir et effectuer certaines actions sur les données, mais pas sur les abonnements",
      "RowNum":"Numéro de ligne",
      "SALE":"Vente",
      "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Modifier le prix de vente",
      "SALE_PRICE_EMPTY_ERROR":"Veuillez saisir un prix valide",
      "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Prix de vente supérieur au prix ERP",
      "SALE_PRICE_LESS_THAN_LIST_PRICE":"Prix de vente inférieur au prix courant",
      "SALE_TYPE_DESC_PRODUCT":"Produit",
      "SALE_TYPE_DESC_SERVICE":"Service",
      "SalePrice":"Prix de vente",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Tentative d'enregistrement du code d'authentification reçu de Quickbooks",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Tentative d'enregistrement du code d'authentification reçu de Xero",
      "SAVE_CART_EXCEPTION":"Une erreur s'est produite lors de l'ajout du produit au panier",
      "SAVE_COUPON_FAILURE":"Échec de l'enregistrement du coupon",
      "SAVE_COUPON_SUCCESS":"Coupon enregistré avec succès",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement de la conversion de devises",
      "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Les colonnes sélectionnées ont été enregistrées avec succès comme favoris.",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Une erreur s'est produite lors de la sauvegarde ou de la mise à jour de la liste des destinataires de notification par courriel",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de l'enregistrement des informations sur l'impôt",
      "SAVE_PLAN_PRODUCT_SLAB":"Blocs d'offres de contrat enregistrés",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Échec de l'enregistrement des blocs d'offre contractuels",
      "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan créé avec succès",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Les détails de paiement du fournisseur ont été enregistrés avec succès.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Enregistrer le profil de paiement du revendeur",
      "SAVE_RESELLER_PLAN":"Enregistrer le plan du revendeur",
      "SAVE_RESELLER_PLAN_FAILURE":"Échec de l'enregistrement du plan du revendeur",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Définition du taux de change du produit du plan de revendeur",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Impossible de définir le taux de change du produit du plan de revendeur",
      "SAVE_SUB_TAX":"Enregistrez-la sous taxe",
      "SAVE_SUB_TAX_FAILURE":"Échec de l'enregistrement de la sous taxe",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Nouveau Ticket créé avec succès",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Ticket mis à jour avec succès",
      "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Planifier une commande",
      "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Veuillez sélectionner une date",
      "SCHEDULE_ORDER_CHECKBOX_TEXT":"J'accepte de planifier une commande pour une date ultérieure",
      "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Sélectionnez une date",
      "SCHEDULE_ORDER_EVENT":"Planifier la commande",
      "SCHEDULE_ORDER_FAILED_EVENT":"Échec de la commande planifiée",
      "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"créer un rendez-vous",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Type de destinataire",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Valeur du destinataire",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Recettes",
      "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"ID d'enregistrement",
      "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Nom du rapport",
      "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Résultat",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Statut",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Supprimer",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Modifier",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Scheduled_Invoice_line_Item_Report",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"ScheduledInvoicelineItemReportDelivery",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Scheduled_Invoice_Line_Items_With_Commission_Report",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"ScheduledInvoiceLineItemsWithCommissionReportDelivery",
      "SCHEDULED_INVOICE_SUMMARY_REPORT":"Scheduled_Invoice_Summary_Report",
      "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Livraison du rapport de résumé de facture planifiée",
      "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Annuler",
      "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Supprimer",
      "SCHEDULED_PROFITABILITY_REPORT":"Rapport_de_profitabilité_programmé",
      "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Livraison du rapport de rentabilité planifiée",
      "SCHEDULED_RENEWAL_CHANGES":"Modifications de renouvellement programmées",
      "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"Souhaitez-vous appliquer la promotion à ce produit ?",
      "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"en attente d'approbation",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Actif",
      "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Annulé",
      "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Complété",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Échoué",
      "SCHEDULED_RENEWAL_STATUS_ONHOLD":"En attente",
      "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Retour aux détails de base",
      "SCHEDULED_RENEWALS_HEADER_TEXT":"Renouvellements programmés",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Actions",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Aligner la date de fin",
      "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Motif d'annulation",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Cycle de facturation",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Nouveau SKU",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Quantité",
      "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Date prévue",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Statut",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Terme",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"Conditions générales de vente",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Accepté",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Poste de facture",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Postes de facture avec commission",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Résumé de facture",
      "SCHEDULED_REPORT_PROFITABILTY":"Rapport de profitabilité",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Type de destinataire",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Destinataires",
      "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Rapport",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Postes de taxe",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Résumé fiscal",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Scheduled_Tax_Line_Items_Report",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"ScheduledTaxLineItemsReportDelivery",
      "SCHEDULED_TAX_SUMMARY_REPORT":"Scheduled_Tax_Summary_Report",
      "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Livraison planifiée du rapport TaxSummary",
      "SCHEDULNG_LODING_MESSAGE_TEXT":"Le processus de chargement de l’offre éligible à partir de l’Espace partenaires prend généralement environ 30 secondes. Veuillez ne pas actualiser la page.",
      "SCORE":"Score",
      "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Rechercher par événement",
      "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Actions de recherche",
      "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Recherche par nom de client",
      "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Recherche par nom de domaine",
      "SEAT_LIMIT_EXCEED":"Limite de sièges dépassée",
      "SELECT_A_SOUNCE_CURRENCY":"Sélectionnez la devise souNCE",
      "SELECT_A_SOURCE_CURRENCY":"Sélectionnez la devise source",
      "SELECT_A_SUBSCRIPTION_TEXT":"Sélectionnez un abonnement",
      "SELECT_AGENT":"Agent",
      "SELECT_ALL_CUSTOMER":"Tous les clients",
      "SELECT_ALL_RESELLER":"Tous les revendeurs",
      "SELECT_ALL_RG":"Voulez-vous sélectionner tous les groupes de ressources ?",
      "SELECT_COUPON":"Sélectionner un coupon",
      "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Sélectionnez le code de la devise",
      "SELECT_CUSTOMER":"Sélectionner un client",
      "SELECT_DATE_RANGE":"Sélectionnez la plage de dates",
      "SELECT_DATE_VALUE":"Sélectionner une date",
      "SELECT_DEFAULT_OPTION_SELECT":"Sélectionner",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Sélectionner un client",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Sélectionner un événement",
      "SELECT_DEPARTMENT":"Sélectionnez le département",
      "SELECT_DEPARTMENTS":"Sélectionnez le département",
      "SELECT_EDU_SEGMENT":"Sélectionnez le segment d'éducation",
      "SELECT_EVENT":"Sélectionner un événement",
      "SELECT_MESSAGE_TYPE":"Sélectionnez le type de message",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Veuillez sélectionner au moins une facture à payer",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Options rapides pour définir votre prix de vente",
      "SELECT_PARTNER_INVOICE":"Factures des Partenaires",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Sélectionner un événement",
      "SELECT_PLAN_TOOLTIP_TEXT":"Sélectionnez le plan",
      "SELECT_PORTAL_PAGE":"Sélectionnez la page du portail",
      "SELECT_PRIORITY":"Sélectionner la priorité",
      "SELECT_PRODUCT_AREA":"Sélectionner la zone de produit",
      "SELECT_RESELLER_AZURE_MACRO":"Sélectionnez une macro",
      "SELECT_RESELLER_AZURE_MACRO_TYPE":"Sélectionner le type de macro",
      "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Sélectionnez le type de prix de vente",
      "SELECT_SITE":"Sélectionnez un site",
      "SELECT_SITES":"Sélectionnez un site",
      "SELECT_STATUS":"Sélectionner le statut",
      "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Sélectionnez la devise cible",
      "SELECT_TYPE":"Sélectionner le type",
      "selectAddressType":"Sélectionnez le type d'adresse",
      "selectCountry":"sélectionner un pays",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Clients sélectionnés pour le chargement de factures sur Quickbooks",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Clients sélectionnés pour le chargement de factures sur Xero",
      "SELECTED_FILTER":"Sélectionnez le filtre",
      "SELECTED_FILTER_ALL":"Sélectionner tout",
      "selectEmailType":"Sélectionnez le type d'e-mail",
      "selectPhoneType":"Sélectionnez le type de téléphone",
      "selectState":"Sélectionnez l'état",
      "SELL_DIRECT_ICON_TITLE":"Icônes de Stockholm / Shopping / Box2",
      "sell-direct":"Vente directe",
      "sell-indirect":"Vente indirect",
      "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Saisissez l'adresse email à laquelle vous souhaitez envoyer un email, saisissez avec une virgule(,) ou un point-virgule(;) et cliquez sur Soumettre pour recevoir un fichier contenant une liste du catalogue de produits",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Renvoyer la facture",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Envoyer",
      "SEND_MAIL_BUTTON":"Envoyer un courriel",
      "SEPTEMBER":"Septembre",
      "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Domaine client du fournisseur",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
      "SERVICE_PROVIDER_TENANT":"Domaines du fournisseur",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Domaine par défaut",
      "SERVICE_PROVIDER_TENANT_HEADER":"Domaine du fournisseur de ",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Actions",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Identifiant de domaine",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Nom de domaine",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"Voulez - vous vraiment marquer <b> {{customerName}}</b> comme domaine par défaut ?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{customerName}} mis à jour en tant que domaine par défaut",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Votre session a expiré. Veuillez vous reconnecter pour continuer. Malheureusement, toutes les modifications non enregistrées seront perdues.",
      "SESSION_TIMEOUT_WARNING":"Avertissement de dépassement de délai de session",
      "SESSION_WARNING_DURATION":"Votre session expirera dans {{secondsLeft}} secondes.",
      "SET_NEW_MACRO":"Définir une nouvelle macro",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Le statut de lot de l'intégration massive des clients est défini sur Terminé",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Tentative de définition du statut des journaux de publications de service externe sur ErrorIgnored",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Impossible de définir le statut des journaux de publications de service externe sur ErrorIgnored",
      "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"Modèle d'e-mail",
      "settings":"Paramètres",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Notifications par email",
      "SETTINGS_HEADING_TEXT_BILLING":"Facturation",
      "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"Paramètres de ConnectWise Manage",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Conversion de devise",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Les choix par défaut du client ",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Supprimer un plan",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"Notifications par email",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Configurations client  (Global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Partner par défaut",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Gestion des utilisateurs",
      "SETTINGS_LOGO_SELECT_A_LOGO":"Sélectionnez un logo",
      "SETTINGS_PASSWORD_HELP_TEXT":"Laissez le champ vide si vous ne voulez pas mettre à jours le mot de passe.",
      "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Autotask",
      "SETTINGS_TAB_HEADING_CONNECTWISE":"ConnectWise Manage",
      "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Vues personnalisées",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Général",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logos",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Centre Partner ",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Fournisseurs",
      "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Inscription publique",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Vous pouvez spécifier n'importe quel nom de taxe, par exemple : TPS.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Vous pouvez spécifier le pourcentage de taxe ici. Par exemple : 15 pour 15 %",
      "SHIP_TO":"Envoyer à",
      "SHOP_ADDED_TO_CART_TEXT":" ajouté au panier",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Ajouter au panier",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Aucun produit trouvé !",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Lire plus...",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Lire plus...",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Afficher le paramètre de configuration client dans le portail client",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Permettre au client de gérer les configurations",
      "SHOW_NON_ACTIVE_OFFERS":"Afficher les offres désactivées",
      "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
      "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Afficher les offres qui ne sont pas disponibles pour le client",
      "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Afficher les offres qui n'ont pas de macro",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Afficher le paramètre de configuration du revendeur dans le portail des revendeurs",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Permettre au revendeur de gérer les configurations",
      "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Icônes de Stockholm / Communication / Ajouter un utilisateur",
      "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Icônes de Stockholm / Communication / Groupe",
      "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm-/-Général-/-Notification2",
      "SIDEBAR_ANALYZE_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Calculatrice",
      "SIDEBAR_C3_SUPPORT_Azure_MARGIN":"Marge d'azur",
      "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Marge d'azur",
      "SIDEBAR_CONTENT_ALL_COMMENTS":"Permet au Partenaire d'examiner les commentaires fournis pour diverses entités (article de panier, factures, commandes) par les clients et d'y répondre",
      "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Conduit à la liste des gestionnaires de comptes et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Gérer les avis Web au niveau de l'entité",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Créez et gérez des notifications personnalisées pour divers événements de gestion de produits/licences et d'intégration des clients.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Partagez des informations en temps réel avec vos partenaires et clients via des diffusions interactives sur leurs tableaux de bord respectifs.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Créez et gérez des avis Web à différents niveaux d'entité pour vous tenir au courant de l'état du système, des factures et d'autres notifications importantes",
      "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor est une fonctionnalité basée sur des recommandations qui aide les partenaires à adopter les meilleures recommandations d'optimisation des coûts.",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Rapport sur les données de facturation Azure",
      "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Il vous permet de mettre à jour le balisage du plan Azure des mois précédents et de charger les données de facturation avec de nouvelles valeurs de balisage.",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Abonnements basés sur l'utilisation",
      "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Aperçu rapide du rapport de synthèse des coûts",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Aperçu rapide de l'analytique client",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Afficher la liste des licences consommées par le domaine",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Décrire l'utilisation d'AZURE",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Gérez votre profil d'entreprise, vos adresses, vos profils de paiement et plus encore...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Revoir vos modifications de licence à travers des dates personnalisées.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Aperçu rapide du résumé de la consommation de licences",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Aperçu rapide de l'attribution des licences et les licences disponibles.",
      "SIDEBAR_CONTENT_MENU_MANAGE_Azure_ENTITLEMENTS":"Gérer et créer plusieurs droits Azure (Abonnements) dans le cadre du plan Azure en bloc",
      "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer et créer plusieurs droits Azure (abonnements) sous le plan Azure en masse",
      "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Aperçu rapide de l'analytique de l'intégration des clients",
      "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Rapports détaillés de la commission de vente au niveau de l'agent et du montant gagné",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Définir des rabais",
      "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Guide pratique du produit pour les partenaires et les clients finaux",
      "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":"Permet au Partenaire d'afficher et de télécharger les détails d'utilisation des produits enregistrés en dehors de la solution C3.",
      "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Permet au Partenaire de télécharger les codes/attributs de produit souhaités dans les rapports sur les éléments de ligne de facturation.",
      "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Permet au Partenaire d'approuver/rejeter les demandes d'achat de produits des clients.",
      "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Téléchargez les valeurs du nom de l'agent, les produits associés aux ventes, le pouNCEntage de commission et divers autres attributs de commission",
      "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Vous aide à créer des colonnes personnalisées à ajouter à votre rapport sur les éléments de ligne de facture.",
      "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Permet au Partenaire de télécharger les détails d'utilisation enregistrés en dehors de C3 pour les produits du catalogue pour la génération de factures.",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Fournis plusieurs rapports sur vos données qui sont dans C3.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Vous aide à définir des plans avec des offres que vous souhaitez commercialiser",
      "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Cette fonctionnalité aide les partenaires à planifier divers rapports à différentes fréquences.",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Rapport basé sur Power BI sur l'utilisation Azure",
      "SIDEBAR_CONTENT_MENU_USERS":"Gérer l'accès aux utilisateurs",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analyser votre rentabilité",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Afficher les estimations Azure",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Regroupez un ou plusieurs abonnements et définissez des budgets et des seuils",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Vous aide à définir vos services et offres exclusifs à valeur ajoutée",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Accédez à vos factures depuis le partenaire",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Vous permet d'accéder aux fonctionnalités pertinentes pour les domaines Microsoft CSP liés à vos clients",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Répertorier et gérer les utilisateurs Azure AD",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Suivre vos commandes",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Afficher les produits achetés",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Magasin et acheter des offres du catalogue",
      "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Fonctionnalité permettant aux fournisseurs indirects (distributeurs) de créer et de vendre leurs solutions IP via des revendeurs indirects aux clients finaux.",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Gérer les factures et les paiements",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Permet de regrouper plus d'une offre et de les mettre à disposition de vos clients pour l'achat",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Conduit à la liste des clients actifs et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Vous amène à la page du tableau de bord",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Vérifiez l'état des paiements déclenchés par le système.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Vous aide à définir des plans avec des offres que vous souhaitez commercialiser",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Vous donne un aperçu de votre rentabilité et des détails de la facture aussi",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Conduit au tableau de bord",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Vous donne un aperçu de votre rentabilité et des détails de la facture aussi",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Conduit à la liste des revendeurs actifs et des options pour gérer leurs données",
      "SIDEBAR_CONTENT_MENUS_SELL":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"Répertorie les fonctionnalités qui vous aident à définir vos offres de vente",
      "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Accédez à des fonctionnalités exclusives au rôle de support C3.",
      "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Icônes-de-Stockholm-/-Design-/-Union",
      "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Stockholm-icônes-/-Communication-/-Groupe",
      "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Stockholm-icônes-/-Communication-/-Groupe",
      "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Icônes-de-Stockholm-/-Design-/-Union",
      "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm / Shopping / Chart-line1",
      "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Stockholm-icons-/-Files-/-Group-folders",
      "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Ticket",
      "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Stockholm-icônes-/-Shopping-/-Cadeau",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Icônes-de-Stockholm-/-Accueil-/-Clé",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Icônes de Stockholm / Shopping / Argent",
      "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Icônes-de-Stockholm-/-Shopping-/-Argent",
      "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Icônes-de-Stockholm-/-Navigation-/-Double-vérification",
      "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Icônes de Stockholm / Shopping / Argent",
      "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Icônes-de-Stockholm-/-Design-/-Composant",
      "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Icônes de Stockholm / Communication / Groupe",
      "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Icônes-de Stockholm / Shopping / Box3",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Commentaires",
      "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Gestionnaires de comptes",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Administration",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notifications personnalisées",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"S'engager",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Notifications de bannière",
      "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Conseiller Azure",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Rapport de facturation Azure",
      "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Marge",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Abonnements Azure",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Rapport sommaire des coûts",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Analytique client",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consommation de licence",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Rapport d'utilisation Microsoft Azure",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Rapport de changement de licence.",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Rapport récapitulatif de consommation de licence",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Rapport de consommation de licence",
      "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Gérer les droits Azure",
      "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Analyse d'intégration",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Commission",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Rapports de commissions",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Coupons",
      "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Documentation produit",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Rapport d'utilisation",
      "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Citations",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Charger",
      "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Approbations",
      "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Commissions",
      "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Attributs du produit",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Utilisation",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Rapports",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Plans",
      "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Rapports planifiés",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Rapport d'utilisation",
      "SIDEBAR_TITLE_MENU_USERS":"Utilisateurs",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"Analyser",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Estimations Azure",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Groupes Azure",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Offres partenaires",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Factures",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Utilisateurs Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Commandes",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Produits",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Magasin",
      "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Offres distributeurs",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Factures",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Forfaits",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Clients",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Statut de paiement en attente.",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Plans",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Tableau de bord",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Résumé des revenus et des coûts",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"Revendeurs",
      "SIDEBAR_TITLE_MENUS_SELL":"Vendre",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Vente directe",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Vente indirect",
      "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Prise en charge C3",
      "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Icônes de Stockholm / Fichiers / Télécharger",
      "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Icônes-de-Stockholm / Communication / Liste du Presse-papiers",
      "SIGN_UP_DATE":"Date d'inscription",
      "SIGNUP_CART_COUPON_APPLY":"Appliquer",
      "SIGNUP_CART_COUPON_CODE":"Appliquer le bon",
      "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Supprimer le bon",
      "simplereport":"Rapport de commission",
      "SINGLE_COMMENT_STATIC":"Commentaire",
      "SITE":"(Site)",
      "SITE_DEPARTMENT_ID":"SiteDepartmentId",
      "SITE_ID":"C3SiteId",
      "SLAB_BACKWARD_CONFIRMATION_POPUP":"Veux-tu vraiment y retourner ? Vous avez enregistré des données de dalle.",
      "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Veuillez remplir et enregistrer les données de la dalle.",
      "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Veuillez remplir les données de la dalle.",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Actions",
      "SLAB_DATA_TABLE_HEADER_COST_RATE":"Taux de coût/unité",
      "SLAB_DATA_TABLE_HEADER_RANGE":"Plage",
      "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Vente/unité",
      "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"La longueur du texte est trop longue",
      "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Veuillez remplir les données de la ligne.",
      "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Les données de dalle ne peuvent pas dépasser 10",
      "SLAB_TYPE_SELECT_OPTION":"Sélectionner",
      "SMTP_SETTINGS_TEST":"Veuillez cliquer sur le bouton pour tester les paramètres SMTP",
      "SMTP_TEST_BUTTON":"Tester SMTP",
      "SMTPTYPE_LEGACY_SMTP":"SMTP hérité",
      "SMTPTYPE_MODERN_0365":"Moderne 0365",
      "SMTPTYPE_SENDGRID":"Grille d'envoi",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Politique d'annulation",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 heures : Remboursement complet",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 heures : 1er jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 heures : 2e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 heures : 3e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 heures : 4e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 heures : 5e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 heures : 6e jour proratisé",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Après 7 jours calendaires : l'annulation n'est pas autorisée",
      "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Abonnements logiciels Instructions",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"La quantité/sièges peut être augmentée à tout moment.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"La quantité/sièges peut être réduite dans les 7 jours calendaires, à sa précédente ou à la somme des quantités/sièges précédents.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Changement de quantité",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Modification du calendrier de renouvellement",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Le calendrier actuel sera supprimé si la quantité/le statut de renouvellement automatique/le cycle de facturation/la durée sont modifiés.",
      "SoftwareSubscriptions":"Abonnements logiciels",
      "SOUNCE_PRODUCT_DISPLAY_NAME":"Produit SouNCE",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Devise source",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Devise source",
      "SOURCE_PLAN_DISPLAY_NAME":"Forfait source",
      "SOURCE_PRODUCT_DISPLAY_NAME":"Produit d'origine",
      "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"Le profil client a été synchronisé",
      "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Modifier les détails de qualification spéciale",
      "SQL_ERROR_DESC":"Il semble y avoir une erreur technique dans le traitement de votre demande, veuillez contacter le support pour plus d'assistance.",
      "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Erreur de la base de données lors de la tentative d'obtention des cotisations de la facture",
      "START_BILLING_PERIOD":"Début de la période de facturation",
      "START_DATE_REQUIRED_MESSAGE":"La date de début est requise",
      "STATUS":"Statut",
      "STATUS_ALL":"Tout",
      "STATUS_DUE":"Redevances",
      "STATUS_OVER_DUE":"En retard",
      "STATUS_PENDING":"En attente",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Statut mis à jour avec succès",
      "STATUT: EN ATTENTE":"En attente",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Veuillez entrer au moins un nom de taxe et un pourcentage pour les sous taxes.",
      "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"La quantité maximale autorisée est",
      "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"La quantité minimale autorisée est de 1",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Ce champ est obligatoire",
      "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Supprimer la promotion",
      "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"La mise à niveau est en cours, veuillez cliquer sur la transition pour voir la progression",
      "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Étant donné que la quantité de licences de mise à niveau est inférieure à la quantité actuelle, vous devez réattribuer manuellement les sièges",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Cet abonnement client est partagé entre le client partenaire et le client revendeur, le produit ne peut donc pas être mis à niveau",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Cet abonnement est partagé par plusieurs entités, par conséquent, après la mise à niveau, un remappage manuel est nécessaire",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Ajouter un contrôle d'expiration d'abonnement",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Ajouter un contrôle d'expiration d'abonnement",
      "SUBSCRIPTION_CHECK_ID":"Vérification de l'identifiant d'abonnement",
      "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Requis",
      "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"le nombre maximum de jours doit être inférieur ou égal à {{MaxDate}}",
      "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"le nombre minimum de jours doit être supérieur ou égal à 1",
      "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Vous avez atteint votre limite maximale de 50 caractères autorisés",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Échec de la notification d'expiration de l'abonnement",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"La notification d'expiration de l'abonnement pour le client a échoué",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Notification d'expiration d'abonnement pour la réussite du client",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Notification d'expiration d'abonnement réussie",
      "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Sélectionnez un terme",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Soumettre",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Période de facturation",
      "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Cycle de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Période de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Catégorie",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Clients",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Date de fin",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Nom de l'abonnement",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Fournisseur",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Date de début",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Cycles de facturation",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Catégories",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Types de consommation",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Fournisseurs",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Catégories de fournisseurs",
      "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"Historique des abonnements",
      "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Télécharger l'historique des abonnements",
      "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Rapport sur l'historique des abonnements",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Fermer",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Rechercher",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Réinitialiser",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nom du client",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Date",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nom du département",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Nouveau prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Extension",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Nom de produit",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Ancien prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Prix",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Nom du site",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Statut",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Utilisateur",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Date de fin",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Date de début",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Gérer les attributions de licence",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Libérer les droits inutilisés. Veuillez patienter...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"Gérer les attributions de licences pour ",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Assigner des licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Supprimer les licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Attribuer des utilisateurs",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Instructions",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Instructions",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Afficher et rechercher",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Licences à supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Erreurs de validation",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Saisissez des adresses email d'utilisateur valides séparées par une virgule.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Ce portail valide l'entrée et détermine si l'achat de licences supplémentaires est nécessaire avant l'affectation.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Aucune opération ne peut être réalisée pendant que nous travaillons sur des changements pour cet abonnement.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Toutes les erreurs détectées durant la validation de l'entrée seront affichées.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Les options Affecter ou Retirer des licences (onglet actif) est prise en compte lors de la soumission.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Cliquez <i class='fa fa-remove red'></i> sur un utilisateur dans le tableau sur la gauche pour l'ajouter à la liste des licences à supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Cliquez sur soumettre pour retirer les licences",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Les options Affecter ou Retirer des licences (onglet actif) sont prises en compte lors de la soumission",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Coût",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Quantité originale ",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Taux par droit",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Plusieurs utilisateurs",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Supprimer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Téléchargement de fichier",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Nom",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"ajouter une nouvelle adresse email pour utilisateur",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Entrer les emails utilisateur valides  séparés par une virgule dans un fichier .txt et le charger ici.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Entrer les emails utilisateur valides  séparés par une virgule dans un fichier .txt et charger. Exemple : abc@xyz.com,bcd@xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Ignorer les erreurs",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Corriger des erreurs",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Statut",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"\"Veuillez patienter pendant que nous traitons votre demande, cela peut prendre quelques minutes. Vous pouvez également revenir en arrière en cliquant sur le bouton \"Gérer\" sur la page des abonnements\"",
      "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"Souhaitez-vous continuer avec les prochaines modifications de renouvellement de planification comme {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'limite de sièges et limite de transactions' : (isSeatLimitExceeded ? 'la limite de sièges est' : 'la limite de transactions est')}} dépassée",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Commande annulée",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Commande complétée",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonnement acheté",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonnement supprimé",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"L'abonnement sélectionné est activé avec succès",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Les abonnements d'approvisionnement",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"La demande sélectionnée a été annulée avec succès.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Tous les clients",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Les abonnements d'approvisionnement",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"Activer",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Nom de l'entité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Titre",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nouvelle quantité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Nouveau statut",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Ancienne quantité",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Ancien statut",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Veuillez patienter pendant que nous traitons votre achat précédent, cela peut prendre quelques minutes.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"\"Veuillez patienter pendant que nous traitons votre achat précédent, cela peut prendre quelques minutes. Vous pouvez également vérifier l'état en cliquant sur le bouton \"Ajouter un nouvel abonnement\"",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Ajouter un chèque",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Retour à la liste",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Contrôles d'expiration d'abonnement",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Action",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Jours",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Nom",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Terme",
      "SUBSCRIPTION_STATUS_ACTIVE":"Actif",
      "SUBSCRIPTION_STATUS_CANCELLED":"Annulé",
      "SUBSCRIPTION_STATUS_COMPLETED":"Complété",
      "SUBSCRIPTION_STATUS_DELETED":"Supprimé",
      "SUBSCRIPTION_STATUS_DISABLED":"Désactivé",
      "SUBSCRIPTION_STATUS_EXPIRED":"Expiré",
      "SUBSCRIPTION_STATUS_FAILED":"Échoué",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Provision",
      "SUBSCRIPTION_STATUS_PENDING":"En attente",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Réussi",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendu",
      "SUBSCRIPTION_UGRADE_TITLE_2":"Important",
      "SUBSCRIPTION_UPDATED":"Abonnement mis à jour avec succès.",
      "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automatique",
      "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Choisissez une mise à niveau éligible pour votre abonnement",
      "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Quantité cumulée",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Abonnement actuel",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Abonnement actuel",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Sélectionnez une nouvelle offre pour mettre à niveau l'abonnement actuel. Cela mettra à niveau votre abonnement et fournira les services associés à la nouvelle offre",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Pendant la mise à niveau, évitez de modifier une partie de l'abonnement d'origine ou des comptes du client. Cela créera un nouvel abonnement, fournira des services et réattribuera des licences utilisateur au nouvel abonnement. Ces modifications ne peuvent pas être annulées.",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Assurez-vous d'effectuer une sauvegarde des données avant de mettre à niveau l'abonnement.",
      "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Ignorer l'erreur",
      "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Ignorer l'avertissement",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuel",
      "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"Aucun produit de plan éligible n'était présent pour l'abonnement",
      "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"Aucune transition trouvée",
      "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"Souhaitez-vous continuer la mise à niveau de l'abonnement en tant que {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'limite de sièges et limite de transactions' : (isSeatLimitExceeded ? 'la limite de sièges est' : 'la limite de transactions est')}} dépassée",
      "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Réattribution de siège",
      "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Sièges qui doivent être mis à niveau (abonnement source)",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Sélectionnez une cible d'abonnements",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Ce champ est obligatoire",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Cibler les abonnements",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Nouvel abonnement",
      "SUBSCRIPTION_UPGRADE_TITLE":"Choisissez une mise à niveau éligible",
      "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Activité de transition",
      "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"La mise à niveau est en cours",
      "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Mise à niveau",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Ce client a déjà été assigné.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Les utilisateurs suivants possèdent déjà une licence assignée pour cette offre. . Veuillez les supprimer et essayer d'enregistrer.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Êtes-vous certain de vouloir ignorer les erreurs ",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Les attributions de licence ont été mises à jour avec succès",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"Êtes-vous certain de vouloir réactiver cet abonnement ?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"L'abonnement sélectionné est réactivé avec succès",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Êtes-vous certain de vouloir libérer les licences inutilisées ?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Veuillez retirer au moins un utilisateur pour révoquer la licence de la table des licences attribuées!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Veuillez sélectionner un utilisateur dans la liste suggérée avant de cliquer sur le bouton Ajouter.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"Êtes-vous certain de vouloir suspendre cet abonnement ? Veuillez noter que les abonnements d'extension active connexes seront également suspendus (si existe)",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"L'abonnement (incluant les extensions, le cas échéant) est suspendu avec succès",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Les droits d'abonnement inutilisés libérés avec succès.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Votre demande d'attribution en bloc est en attente",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Chargé avec succès",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Veuillez entrer les emails des utilisateurs valides pour attribuer les licences dans le zone de texte",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter un nouvel abonnement ",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Modifier",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Gérer",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Total",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Actions",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Nom",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Quantité",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Coût",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Total",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Nouvel abonnement Azure ",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Nom",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter un nouvel abonnement ",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azure",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Ajouter de nouveaux abonnements",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Abonnements Azure",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Estimation du prix",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Nom de l'offre",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Mettre à jour de l'abonnement",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Nouvelle quantité totale ",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"La valeur minimale est 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"L'entrée doit être un nombre entier valide",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Offre",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Achetez des droits supplémentaires en augmentant le <strong> nouveau total de la quantité </ strong>",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Assurez-vous que vous avez libéré les licences des utilisateurs à travers  <strong>Utilisateurs -&gt; licences</strong>au cas où vous voudriez réduire le nombre de droits d'ici",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Votre demande d'achat d'abonnements est en attente.",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Réactiver",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonnement enregistré avec succès",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Veuillez sélectionner au moins une offre avant de placer une commande",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Suspendre",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Paramètres généraux mis à jour avec succès",
      "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"Les paramètres de ConnectWise Manage ont été mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"Paramètres SMTP partenaire mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"Partner {{logo}} mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"paramètres Partner mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Paramètres du fournisseur mis à jour avec succès.",
      "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"Les paramètres d'inscription publique ont bien été mis à jour.",
      "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Importation de plusieurs paiements terminée avec succès",
      "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Les données d'utilisation quotidienne de l'offre Partenaire ont bien été importées",
      "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Importation des commissions sur les produits terminée avec succès",
      "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Création réussie d'un nouveau plan comprenant toutes les offres",
      "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Création d'un nouveau plan revendeur avec toutes les offres",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Corriger un écart de quantité",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Veuillez mettre à jour le plan pour que ce client inclue des offres pertinentes et revenir ici pour résoudre le problème.",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"Nom de l'offre CSP",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Aucune offre correspondante trouvée",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Offre correspondante dans le plan actuel",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Prix",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Quantité",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapping de l'offre",
      "SUPPLIER_DETAILS":"Détails du fournisseur",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Nouveau ticket de support",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"Numéro de téléphone",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Description",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":"Priorité",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Zone produit",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"Sujet",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"Type",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Soutien",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Numéro de ticket de support",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Ajouter un commentaire",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Commentaires",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"Numéro de téléphone",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Effort cumulatif",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Client",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Description",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Travail (en heures)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Priorité",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Zone produit",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Statut",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Sujet",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Agent de support ",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Sélectionner l'agent de support ",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Type",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Aucun commentaire ajouté.",
      "SUPPORT_FOR_TEXT":"Soutien pour",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Ajouter ticket de support",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Tickets de support",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Client",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Rechercher",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"Statut",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Agent de support ",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Identifiant de ticket",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Actions",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Détails",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Courriel de contact",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Date créée",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Client",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Priorité",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Zone produit",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Statut",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Sujet",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Identifiant de ticket",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Type",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Nouveau ticket de support créé avec succès",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Archivé",
      "SUPPORT_TICKET_STATUS_CLOSED":"Fermé",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"En cours",
      "SUPPORT_TICKET_STATUS_OPEN":"Ouvrir",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Ré-ouvrir",
      "SUPPORT_TICKET_STATUS_RESOLVED":"Résolu",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"Ne peut être corrigé",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Problèmes d'accès",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"Analyses",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Gestion de profil",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Gestion des abonnements",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Gestion des utilisateurs",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Détails de ticket de support mis à jour avec succès",
      "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Aucun marché n'a été trouvé pour le code devise choisi",
      "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Les marchés ci-dessous ne sont pas pris en charge car aucune conversion de devise n'a été trouvée pour leurs devises respectives.",
      "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"Le marché pris en charge s'applique uniquement aux offres NCE",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Marché",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Code du marché",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Devise",
      "SupportedMarkets":"Marchés pris en charge",
      "SWAL_OK_TEXT":"OK",
      "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Passer à l'API de tarification Microsoft",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Passer au nouveau modèle de sécurité",
      "SYNC_CUSTOMER_PROFILE":"Synchroniser le profil client",
      "TAB_HEADING_PROVISIONING_STATUS":"Statut de Provisionnement",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Affichage de {{StartIndex}} - {{EndIndex}} des {{Total}} enregistrements",
      "TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "TABLE_HEADER_TEXT_DATE":"Date",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Description",
      "TABLE_HEADER_TEXT_DISTRIBUTOR_INVOICE":"Facture du distributeur",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Raison de l'échec",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Montant total",
      "TABLE_HEADER_TEXT_KEY":"Clé",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Montant payé",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Statut de paiement",
      "TABLE_HEADER_TEXT_REMARKS":"Remarques",
      "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Montant du sous-total",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Montant de la taxe",
      "TABLE_HEADER_TEXT_VALUE":"Valeur",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Total de facture(s) sélectionnée(s)",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Recherche...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Balise supprimée avec succès",
      "TAG_NAME_PLACEHOLDER":"Sélectionnez une balise",
      "TAG_SAVED_SUCCESS_MESSAGE":"Balise sauvegardée avec succès",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Nom du plan",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Nom de produit",
      "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Propriétés",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Retour aux balises",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier",
      "TAGS_CAPTION_TEXT_TAG_FOR":"Balise pour ",
      "TAGS_CAPTION_TEXT_TAGS_FOR":"Balises pour ",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Devise cible",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Devise cible",
      "TARGET_PLAN_DISPLAY_NAME":"Plan cible",
      "TARGET_PRODUCT_DISPLAY_NAME":"Produit cible",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Retour au taux d'imposition",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Pourcentages d'impôt",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Ajouter",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Modifier",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Les règles fiscales sont définies par des combinaisons de {Pays, Province d’état et Code d’impôts}, {Pays, code postal} ou {Pays, Province d’état et Code postal}",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Par exemple: La règle d'imposition définie pour un Pays - États-Unis - ne pas remplir le champ état, province et le code postal signifie que le taux d'imposition s'applique à tous les clients aux États-Unis. Si vous souhaitez définir une règle pour un état en particulier, indiquez les valeurs Pays et État lors de la définition de la règle",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Nom de taxe",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Pourcentage de taxes",
      "TAX_PERCENTAGES_ADD_SUCCESS":"Pourcentage d'impôt ajouté avec succès.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Clients",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Pourcentage d'impôt supprimé avec succès.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Ajouter pourcentage de taxes",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Retour à la liste",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Pourcentages d'impôt",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Pourcentage d'impôt pour ",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Supprimer",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Modifier",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Actions",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"En vigueur à partir de",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"% Impôt  ",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Pourcentage d'impôt mis à jour avec succès.",
      "TAX_RULES_ADD_SUCCESS":"Règle fiscale ajoutée avec succès",
      "TAX_RULES_DELETE_SUCCESS":"Règle fiscale supprimée avec succès",
      "TAX_RULES_UPDATE_SUCCESS":"Règle fiscale mise à jour avec succès",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Impôt cumulatif",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Les règles d'impôt pour enfants sont appliquées dans l'ordre sur le montant résultant de chaque étape du calcul.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Général",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Pourcentage de taxe unique appliqué sur le montant total.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Sous taxes",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Le pourcentage de taxe pour cette définition sera le total (somme) des règles fiscales pour enfants.",
      "TENANT TOOLTIP":"Sélectionnez le locataire pour charger les utilisateurs",
      "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activer le seuil de danger avec abonnement",
      "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Activer la libération automatique pour les sièges non attribués",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permet au client de modifier la quantité de produits à partir de la vue grille ou liste dans l'écran des produits",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Autoriser le client à initier la transaction pour sa ou ses factures",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Cocher cet indicateur empêche les clients de réduire la quantité d'abonnement",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Autoriser la synchronisation du nom du produit",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Autoriser la synchronisation des noms de produits pour les offres basées sur la quantité",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Permet la synchronisation du nom convivial de l'abonnement pour les offres basées sur la quantité",
      "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Possibilité d’appliquer des remises sur le plan Azure niveau 2 au client. la valeur par défaut est Non. Si elle est définie sur oui, à l'exception des réductions PEC, toutes les autres réductions seront également appliquées au client.",
      "TENANT_CONFIG_DESC_AUTO_SYNC":"Ceci est utilisé pour activer/désactiver le processus de synchronisation automatique dans la réconciliation.",
      "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Durée du contrat annuel Autotask (années)",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Date de fin du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Cette valeur de date sera utilisée comme date de fin pour créer automatiquement des contrats pour le client",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Frais de mise en place du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Veuillez saisir les frais d'établissement du contrat, le cas échéant",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Date de début du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Cette valeur de date sera utilisée comme date de début pour créer automatiquement des contrats pour le client",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Jour de début du contrat Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Cochez la case si les rapports sur les heures contractuelles nécessitent des heures de début et de fin",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Rapport de temps de contrat Autotask requis heures de début et d'arrêt",
      "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Durée du contrat mensuel Autotask (mois)",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Permettre aux clients de gérer les abonnements Azure",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permettre aux clients de définir des groupes sur les abonnements Azure",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du client azure. Par exemple: Entrer 1.15 si la majoration doit être 15 %",
      "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, l'abonnement NCE est défini sur le renouvellement automatique.",
      "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Permet de prendre en compte le prix de l'ERP lors du renouvellement sans macro",
      "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Après achat ou augmentation de quantité, le produit ne sera pas considéré en lancement automatique jusqu'à cette période (en jours).",
      "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Type de facturation client",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Devise client",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Type d'efficacité de soutien pour la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Seuil de danger par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Seuil d'erreur par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Seuil d'informations par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Budget mensuel par défaut pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinataire de l'e-mail de seuil par défaut pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Seuil d'avertissement par défaut en peNCEntage pour l'abonnement Azure.",
      "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Activer les termes et conditions pour commander des produits",
      "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Activer les termes et conditions NCE",
      "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Activer les rapports Power BI",
      "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"L'activation de cette option permettra aux utilisateurs de planifier une commande",
      "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ID client ERP",
      "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Client exonéré pour l'exportation PSA",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Si ce champ est coché, nous prenons en considération le consentement du client.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Ce champ est mis à jour si au moins un des clients a accepté le MCA. Décochez cette case pour faire respecter à nouveau l'acceptation du ou des clients.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Acceptez-vous les nouvelles conditions générales ?",
      "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Limite de transactions",
      "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Constante entière",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Pour référence interne. Cet identifiant peut être utilisé pour associer le client à un enregistrement dans votre système de comptabilité interne",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Jour du mois où les factures sont approuvées automatiquement",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Jour où les paiements automatiques sont traités",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Date d'échéance des factures",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"Nombre de jours dus pour le paiement de la facture",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Jour du mois où les factures sont finalisées automatiquement",
      "TENANT_CONFIG_DESC_INVOICE_NOTE":"Apparaît sur la facture pdf",
      "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendater la disponibilité du profil de paiement avant le paiement du panier",
      "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"La version automatique est requise pour les produits de site et de service",
      "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Mandater l'utilisateur pour soumettre un mode de paiement",
      "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'adresse nouvellement ajoutée comme adresse par défaut du client.",
      "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"L'activation de cette option désignera automatiquement l'e-mail nouvellement ajouté comme e-mail par défaut pour le client.",
      "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"L'activation de cette option désignera automatiquement le numéro de téléphone nouvellement ajouté comme numéro de téléphone par défaut pour le client.",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Propriétaire de la facture unique (type)",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Propriétaire de la facture unique (identifiant)",
      "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur les éléments de ligne du client une seule fois.",
      "TENANT_CONFIG_DESC_PAN_NUMBER":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Lien des conditions générales",
      "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer au prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels perpétuels.",
      "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Définir le numéro de bon de commande pour le client",
      "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Si ce paramètre est activé, l'e-mail de facturation sera envoyé aux services de comptabilité externes",
      "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Activer la fonctionnalité de transmission des factures à un service de comptabilité externe",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Cochez cet indicateur si vous souhaitez que le système traite les paiements (CC/ACH, le cas échéant)",
      "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Lorsqu'il est activé, affichera le nom de l'abonnement donné dans le centre des Partenaires",
      "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"La facturation perpétuelle des logiciels sera incluse dans les factures de ce site.",
      "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"EnfoNCE une limite sur le nombre de sièges que le client peut acheter",
      "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Les conditions de paiement à marquer sur les factures publiées sur QuickBooks",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Saisissez le nombre de jours avant la date de renouvellement pendant lequel les e-mails de consentement seront envoyés aux clients.",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Spécifiez le nombre de jours après la notification de consentement pendant lequel la notification de rappel sera envoyée aux clients.",
      "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"La facturation des réservations Azure sera incluse dans les factures de ce site.",
      "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur la réservation Azure du client.",
      "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"Modèle de tarification des IR",
      "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration (%) à appliquer sur l'utilisation du client RI.",
      "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Le client recevra le courriel avec les détails de la facture, une fois la facture approuvée et si les notifications par courriel sont activées",
      "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Le client recevra une notification par courriel concernant les factures ayant des montants nuls",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Apparaît sur la facture pdf dans la section Expédier à.",
      "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Devrait activer la valeur par défaut pour le client.",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Devrait informer le client",
      "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Devrait exempter le client du paiement instantané.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut annuler l'abonnement NCE dans une fenêtre d'annulation de 7 jours calendaires.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Lorsque cette option est activée, le client peut annuler les abonnements logiciels dans un délai d'annulation de 7 jours calendaires.",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Autoriser le client à aligner les dates de fin d'abonnement",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Si cette option est activée, le client peut aligner les dates de fin d'abonnement pour obtenir une coïncidence.",
      "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Afficher le menu de renouvellement du prochain programme",
      "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Activez l'affichage des abonnements non intégrés pour la sélection d'une date de fin personnalisée lors de l'achat.",
      "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut réactiver un abonnement NCE suspendu.",
      "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Bouton Afficher les qualifications spéciales",
      "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Lorsqu'il est activé, le client peut suspendre l'abonnement NCE.",
      "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Lorsque cette option est activée, le client peut mettre à niveau le produit NCE vers un SKU supérieur",
      "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Abonnement à ignorer lors d'une facturation unique",
      "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer sur le prix de revient pour atteindre le prix de vente souhaité pour les abonnements logiciels.",
      "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"La facturation des abonnements logiciels sera incluse dans les factures de ce Site.",
      "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Mettre cette valeur à vrai empêchera l'utilisateur d'effectuer des achats.",
      "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Chaîne constante pour le client",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Soutien pour la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspendre lorsque le seuil de danger est atteint",
      "TENANT_CONFIG_DESC_TAX_CODE":"Le code fiscal est utilisé pour déterminer la taxe applicable %",
      "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_TAX_VALUE":"Apparaît sur la facture pdf dans la section Facturer à.",
      "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration totale (%) à appliquer sur le prix de revient pour atteindre le prix de vente souhaité pour les abonnements tiers.",
      "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"La facturation des abonnements tiers sera incluse dans les factures de ce Site.",
      "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"EnfoNCE une limite sur la valeur monétaire de l'achat de produit basé sur la quantité (dans la devise du client)",
      "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Type d'ajout ConnectWise",
      "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Facturation anticipée",
      "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"En fonction du format sélectionné, les ajouts seront créés dans Connectwise",
      "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Placer la facturation du mois en cours sur le mois futur spécifié pour la facturation",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Afficher le journal d'acceptation des conditions générales au domaine",
      "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activer le seuil de danger avec abonnement",
      "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Autoriser la libération automatique des sièges inutilisés",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Autoriser le client à modifier la quantité de produits à partir de la liste",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Autoriser le client à initier une transaction",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Permettre au client de réduire le nombre de licences",
      "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Appliquer les remises du plan Azure niveau 2 au client",
      "TENANT_CONFIG_DISP_AUTO_SYNC":"Synchronisation automatique",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Voulez-vous activer Azure ?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Voulez-vous activer Azure pour Entreprise ?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour l'utilisation Azure",
      "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Gérer les produits NCE renouvelle automatiquement",
      "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Considérez le prix de l'ERP lors du renouvellement sans macro",
      "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Période de refroidissement de la libération automatique",
      "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"ID d'opportunité CRM",
      "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Type de facturation client",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Devise client",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Efficacité de la mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Seuil de danger par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Seuil d'erreur par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Seuil d'informations par défaut (%)",
      "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Budget mensuel par défaut",
      "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinataire de l'e-mail de seuil par défaut",
      "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Seuil d'avertissement par défaut (%)",
      "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Activer les termes et conditions pour commander des produits",
      "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Activer les termes et conditions NCE pour commander des produits Microsoft NCE",
      "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Activer l'ordre de planification",
      "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ID client ERP",
      "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Client exonéré pour l'exportation PSA",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Consentement du client activé ?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Acceptez-vous le contrat client Microsoft ?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Acceptez-vous les conditions générales du partenaire ?",
      "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Limite de transactions",
      "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Identifiant de référence de facturation",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Jour où le client peut consulter les factures",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Jour du paiement automatique",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Date d'échéance des factures",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Jours d'échéance des factures",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Jour où les factures sont finalisées",
      "TENANT_CONFIG_DISP_INVOICE_NOTE":"Notes de facturation",
      "TENANT_CONFIG_DISP_INVOICE_TYPE":"Type de facture",
      "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendater Profil de paiement Panier Commander",
      "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"La version automatique est requise pour les produits de site et de service",
      "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Profil du mandat",
      "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Attribuer automatiquement une nouvelle adresse par défaut",
      "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Attribuer automatiquement un nouvel e-mail par défaut",
      "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Attribuer automatiquement un nouveau numéro de téléphone par défaut",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Propriétaire de la facture unique (type)",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Propriétaire de la facture unique (identifiant)",
      "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Balisage pour les éléments de campagne ponctuels",
      "TENANT_CONFIG_DISP_PAN_NUMBER":"Numéro PAN",
      "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"Clé numérique PAN",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"URL des conditions générales",
      "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Majoration pour l'abonnement perpétuel au logiciel",
      "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Lieu de fourniture",
      "TENANT_CONFIG_DISP_PO_NUMBER":"Numéro de commande",
      "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Envoyer l'e-mail de facturation aux services comptables externes",
      "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Enregistrer les factures auprès d'un service de comptabilité externe",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Désirez-vous automatiser le paiement de factures ?",
      "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Afficher le nom de l'abonnement du fournisseur",
      "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Propriétaire perpétuel de la facture logicielle (identifiant)",
      "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Limite de siège applicable",
      "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Conditions de paiement de QuickBooks",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Période d'initiation du consentement au renouvellement",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Jours de notification de rappel de consentement de renouvellement",
      "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Propriétaire de la facture de réservation Azure (identifiant)",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"Modèle de tarification des IR",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Majoration pour RI",
      "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Envoyer des courriels de facture automatiquement",
      "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Envoyer des factures de montant zéro",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Expédier au nom du contact",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Expédier au numéro de contact",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Expédier au numéro PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Expédier à la clé du numéro PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Expédier au type de taxe",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Expédier à la valeur fiscale",
      "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Devrait activer la valeur par défaut pour le client",
      "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Paiement instantané exonéré",
      "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Bouton Afficher les qualifications spéciales",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton d'annulation du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Afficher le bouton d'annulation du produit des abonnements logiciels",
      "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Afficher les abonnements non intégrés pour une date de fin personnalisée",
      "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton de réactivation du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Afficher le bouton de suspension du produit NCE",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Afficher le journal d'acceptation des conditions générales",
      "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Afficher le bouton de mise à niveau du produit NCE",
      "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Balisage pour l'abonnement au logiciel",
      "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Propriétaire de la facture d'abonnement au logiciel (identifiant)",
      "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Arrêter toutes les transactions",
      "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Identifiant unique du client",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Mise à jour automatique des prix des produits clients",
      "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspendre lorsque le seuil de danger est atteint",
      "TENANT_CONFIG_DISP_TAX_CODE":"Code fiscal",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Type de taxe",
      "TENANT_CONFIG_DISP_TAX_VALUE":"Valeur fiscale",
      "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Majoration pour l'abonnement tiers",
      "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Propriétaire de la facture d'abonnement tiers (identifiant)",
      "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"Limite du montant de la transaction",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Veuillez entrer la valeur de {{tenantConfigName}}",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Veuillez entrer un numéro de {{tenantConfigName}} valide.",
      "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"La gestion de la configuration du renouvellement automatique n’est pas activée pour ce locataire.",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Configuration client rétablie avec succès",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Client configuration sauvegardé avec succès",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"Configuration client pour {{clientname}} mise à jour avec succès",
      "TENANT_COUNTS":"Compter",
      "TENANT_CREATION_FAILED":"La création du locataire a échoué",
      "TENANT_EXCEEDED_COUNT_ERROR":"Nombre de locataires à créer, ne dépassant pas {{Count}}",
      "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":"Si vous souhaitez créer l'abonnement Azure avec le locataire, veuillez le cocher",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Ajouter le préfixe 'OTU' dans le nom",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Ajouter 'OTU' comme préfixe dans le nom du locataire",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Attribuer un administrateur de locataire en tant que propriétaire",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Attribuer l'utilisateur admin en tant que propriétaire de l'abonnement créé",
      "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Ajouter des locataires",
      "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Créer des abonnements",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"département",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Nom du domaine",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Les noms de domaine commenceront par ceci.",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Sélectionnez l'offre de plan Azure",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Utilisez ceci pour sélectionner le produit de plan correct pour mapper le plan Azure",
      "TENANT_MANAGEMENT_LABEL_PREFIX":"Préfixe",
      "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Ajouter un préfixe au nom du locataire",
      "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Séquence de démarrage",
      "TENANT_MANAGEMENT_LABEL_SITE":"Site",
      "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Nombre de locataires",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Nom",
      "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Processus terminé. Veuillez consulter le journal d'audit pour plus de détails.",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"La demande d'ajout de locataires est acceptée et en cours de traitement",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La demande d'ajout de locataires a échoué.",
      "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Veuillez fournir une séquence de numéros",
      "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"L'incrément séquentiel sera utilisé comme suffixe dans les noms de locataire.",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Statut de création du locataire",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Veuillez patienter pendant la création des locataires.",
      "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Nombre de locataires à créer.",
      "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Les noms des locataires commenceront par ceci.",
      "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Retour aux locataires",
      "TENANT_SEQUENCE":"Séquence",
      "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"En cliquant sur le bouton « Accepter », vous acceptez les conditions d’utilisation ci-dessus.",
      "TERM_AND_CONDITIONS_LINK_TEXT":"Conditions d'utilisation du partenaire",
      "TERM_AND_CONDITIONS_READ_TEXT":"Veuillez lire les termes et conditions ci-dessous.",
      "TERM_DURATION_DESC_DAY(S)":"Jour(s)",
      "TERM_DURATION_DESC_DAYS":"Jour",
      "TERM_DURATION_DESC_MONTH":"Mois",
      "TERM_DURATION_DESC_MONTH(S)":"Mois",
      "TERM_DURATION_DESC_YEAR":"Année",
      "TERM_DURATION_DESC_YEAR(S)":"Année(s)",
      "TERM_DURATION_DESC_YEARS":"Années",
      "TERM_DURATION_FILTER_DESC_DAYS":"jours",
      "TERMS_AND_CONDITIONS":"conditions générales",
      "TERMS_AND_CONDITIONS_ACCEPT":"Veuillez accepter les conditions générales",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"conditions générales acceptées avec succès.",
      "TERMS_AND_CONDITIONS_BACK":"Retour  aux conditions générales",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Veuillez lire les conditions générales ci-dessous</b>.    </br>    </br>    <ul>    <li> <a ng-click='vm.PartnerAgreementClicked()'>Conditions d'utilisation de partenaire</a></li> </ul>    </br>    En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Veuillez lire les conditions générales ci-dessous fournies par Microsoft. </br> En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Veuillez lire les conditions générales du contrat ci-dessous du <b>{{partnerName}}</b> et Microsoft.    </br>    </br>    <ul>    <li> <a ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li>  </ul>    </br>    En cliquant sur le bouton « Accepter », vous acceptez les conditions d'utilisation ci-dessus.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Cliquer <a id='linkEzidebit' ng-click='vm.EzidebitAgreementClicked()'> ici </a>pour lire le Contrat intégral Ezidebit",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Contrat Ezidebit",
      "TERMS_AND_CONDITIONS_READ":"J'ai lu les conditions générales",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"Impossible de traiter votre demande.",
      "TEST_EMAIL_LABEL":"Tester EmailId",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Tester",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Tester",
      "TEST_SMTP_SETTING_TITLE":"Tester les paramètres SMTP",
      "TEXT_ACH":"ACH",
      "TEXT_ACTIONS":"Actions",
      "TEXT_BILL_FROM":"Facture de",
      "TEXT_BILL_TO":"Facturé à",
      "TEXT_BILLING_PERIOD":"Période de facturation",
      "TEXT_EMAIL_ID":"Identifiant de courriel",
      "TEXT_GROUP_NAME":"Nom du groupe",
      "TEXT_INVOICE_AMOUNT":"Montant de la facture",
      "TEXT_INVOICE_DATE":"Date de la facture",
      "TEXT_INVOICE_NUMBER":"Numéro de facture",
      "TEXT_INVOICE_SUMMARY":"Résumé de facture",
      "TEXT_MONTHLY_BUDGET":"Budget mensuel",
      "TEXT_OFF":"À L'ARRÊT)",
      "TEXT_PLAN_ID":"Identifiant de plan",
      "TEXT_RESPONSIBLE_OFFICIAL":"Responsable officiel",
      "TEXT_SAAS_SUBSCRIPTION_STATUS":"Statut de l'abonnement Saas",
      "TEXT_SIGN_IN_REQUIRED":"Connexion requise",
      "TEXT_TENANT_ID":"Identifiant de domaine",
      "TEXT_THRESHOLDS":"Seuils",
      "TEXT_VAT_NUMBER":"Numéro de TVA",
      "THE_SEQUENCE_LABEL_THE_STARTING_NAME":"Le nom de la séquence commencera à partir de",
      "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Sélectionnez l'accord",
      "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Compléter le mappage pour l'entité C3",
      "THIRD_PARTY_ENTITY_MAPPING":"Cartographie des entités tierces",
      "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer le mappage pour le client : <b>{{customerName}}</b> ?",
      "THIRD_PARTY_PRODUCT_MAPPING":"Cartographie des produits tiers",
      "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Êtes-vous sûr de vouloir enregistrer les produits tiers sélectionnés ?",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Action",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Accord",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"Client C3",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"Client PSA",
      "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"Sélectionnez le service PSA",
      "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Sélectionnez le client PSA",
      "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Cartographie des abonnements tiers",
      "TIME_DESC_DAY":"jour",
      "TIMER_DESC_DAYS":"jours",
      "TITLE_COUPON_CODE_TEXT":"Codes promotionnels",
      "TITLE_CUMULATIVE_TAXES":"Impôts cumulatifs",
      "TITLE_ROLE_SELECT":"Sélectionner le rôle",
      "TITLE_SUB_TAXES":"Sous taxes",
      "TITLE_TAGS":"Balises",
      "TITLE_TEXT":"Bienvenue au centre de contrôle CSP",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"pour rejoindre les abonnements Azure",
      "TOOL_TIP_TEXT_HIDE":"Cacher",
      "TOOL_TIP_TEXT_SHOW":"Montrer",
      "TOOLTIP_COUPON_CODE_TEXT":"Code promotionnel",
      "TOOLTIP_TEXT_DELETE_PSA_LOG":"Désactiver le journal",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Désactiver un produit",
      "TOOLTIP_TEXT_EDIT_NAME":"Modifier le nom",
      "TOOLTIP_TEXT_EDIT_QUANTITY":"Modifier la quantité",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Activer le produit",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Lier un produit",
      "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"Le produit Link n'est pas pris en charge",
      "TOOLTIP_TEXT_QUANTITY":"Quantité",
      "TOUR_POPUP_CONTENT":"<b>Bienvenue à bord !</b> </br> Découvrez un aperçu des fonctionnalités de l'application.",
      "TOUR_POPUP_DISMISS_BUTTON":"Rejeter",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Faites un tour",
      "TOUR_POPUP_TITLE":"Bienvenue dans ",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Actions",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Montant",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Catégorie",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Évaluer",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Description",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Rabais",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Nom de produit",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Quantité",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Évaluer",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"Montant cumulé",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"Taxé",
      "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Le montant de la transaction dépasse",
      "TRANSACTION_LIMIT_MESSAGE":"Limite restante {{RemainingAmount}} sur {{TotalLimit}}",
      "TRANSACTION_TYPE_PURCHASE":"Nouvel achat",
      "TRANSACTION_TYPE_UPDATE":"Mise à jour de la quantité",
      "TRANSACTION_TYPE_UPGRADE":"Mise à niveau de l'abonnement",
      "Trial offer parent unavailable":"Offre d'essai parent indisponible",
      "Trial offers":"Offres d'essai",
      "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Actuellement, il existe une demande pour transformer l’offre d’essai en abonnement ; aucune opération n’est autorisée sur ce produit.",
      "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Les deux valeurs valides les plus proches sont {{lower}} et {{upper}}.",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Cycle de facturation",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Type de facturation",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Catégorie",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Type de consommation",
      "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"Est-il auto-renouvelable",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Nom de produit",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Fournisseur",
      "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Prix de vente",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Type de vente",
      "TRIAL_OFFER_PARENT_TERM_DURATION":"Durée du mandat",
      "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Continuer l'abonnement après l'essai",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Instructions",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Note",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"La période d'essai dure un mois et le produit sera ensuite annulé.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"jours, et le produit sera annulé par la suite.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" La période d'essai dure",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"En cliquant sur « Continuer l'abonnement », le produit sera acheté après la période d'essai et la facturation commencera en conséquence.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Vous avez la possibilité de prolonger votre abonnement au-delà de la période d'essai en sélectionnant le bouton « Acheter un abonnement ».",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"L'offre d'essai partagée sur l'ensemble du site/département ne sera pas convertie en abonnement.",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Un abonnement d'une quantité sera acheté auprès de l'entité opérationnelle du client.",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Veuillez entrer une valeur valide",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"La quantité maximale est de 10 000",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"La quantité minimale est de 1",
      "TRIAL_PERIOD_DAYS_TEXT":"Jours",
      "TROUE_BTN_TEXT_END_TOUR":"Fin de la visite",
      "TROUE_BTN_TEXT_NEXT":"Suivant &raquo;",
      "TROUE_BTN_TEXT_PREV":"&laquo; Précédent",
      "TRUE":"TRUE",
      "UI Notification":"Notification de l'interface utilisateur",
      "UNABLE_TO_FIND_ANY_Azure_PLAN_FROM_PC":"Impossible de trouver un plan Azure à partir du PC",
      "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"Impossible de trouver un plan Azure à partir du PC",
      "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le client a été dissocié avec succès",
      "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Voulez-vous vraiment dissocier {{customer}} ?",
      "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Le revendeur a été dissocié avec succès",
      "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Le client a été dissocié d'un gestionnaire de compte avec succès",
      "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Le revendeur a été dissocié d'un gestionnaire de compte avec succès",
      "UNIQUE_NAME_ERROR":"Ce nom existe déjà, veuillez choisir un nom différent.",
      "UNSUPPORTED_FILE_TYPE_SELECTED":"Seuls les fichiers de type jpg, svg, png, gif, bmp sont pris en charge",
      "UP_GRADE_Azure_SUBSCRIPTION":"Mettre à niveau l'abonnement Azure vers Azure Plan.",
      "UP_GRADE_AZURE_SUBSCRIPTION":"Mettre à niveau l'abonnement Azure vers Azure Plan.",
      "Update license":"Mettre à jour la licence",
      "Update webhook notification failure":"Échec de la notification du webhook de mise à jour",
      "Update webhook notification success":"Mise à jour réussie de la notification du webhook",
      "UPDATE_CART_LINE_ITEM":"Article de panier mis à jour",
      "UPDATE_CART_LINE_ITEM_FAILURE":"Échec de la mise à jour de l'article du panier",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Configurations globales du client ont été mises à jour avec succès",
      "UPDATE_DEFAULT_VALUE":"Marquer comme valeur par défaut",
      "UPDATE_DUE_DATE_NAME":"Date d'échéance",
      "UPDATE_INVOICE_LINEITEM_PONUMBER":"Numéro de bon de commande de l'élément de facture",
      "UPDATE_INVOICE_PONUMBER":"Numéro de commande",
      "UPDATE_INVOICE_PROPERTIES":"Mettre à jour la propriété de la facture",
      "UPDATE_MANAGE_RENEWAL_TITLE":"Renouvellement de la mise à jour",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour des paramètres du partenaire",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Une erreur s'est produite lors de la mise à jour des informations sur l'impôt",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"La mise à jour des produits n'est pas autorisée, car une attribution de licence est en cours pour le produit ou l'une de ses extensions. Veuillez essayer plus tard.",
      "UPDATE_PROFILE":"Profil mis à jour avec succès",
      "UPDATE_PROFILE_FAILURE":"Échec de la mise à jour du profil",
      "UPDATE_RESELLER_CONFIGURATION":"Mettre à jour la configuration du revendeur",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Échec de la mise à jour de la configuration du revendeur",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Mettre à jour l'option de paiement par défaut du revendeur",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Échec de la mise à jour de l'option de paiement par défaut du revendeur",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Profil de paiement du revendeur mis à jour",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Impossible de mettre à jour le profil de paiement du revendeur",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Prix de produit de plan de revendeur mis à jour",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Impossible de mettre à jour le prix du produit du plan du revendeur",
      "UPDATE_RESELLER_PROFILE":"Mettre à jour le profil du revendeur",
      "UPDATE_RESELLER_PROFILE_FAILURE":"Impossible de mettre à jour le profil du revendeur",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valeur par défaut mise à jour du client fournisseur",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Impossible de mettre à jour la valeur par défaut du client fournisseur",
      "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Informations du gestionnaire de compte mis à jour avec succès",
      "UPDATED_FAILED_C3_PSA_DATA_FAIL":"Échec de la mise à jour des données PSA",
      "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"L'actualisation des données a échoué, d'autres options seront accessibles une fois que vous aurez cliqué sur ok.",
      "UPDATED_PAGE_MODE_TO":"Mise à jour réussie vers la vue {{PageMode}}",
      "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"Mise à jour réussie des données C3 PSA avec les dernières données du système externe PSA",
      "UPGRADE_ELIGIBILITY_CHECK":"Vérifier l'admissibilité à la mise à niveau",
      "UPGRADE_FAILED_MSG":"La mise à niveau Azure a échoué",
      "UPGRADE_SUCCESS_MSG":"La demande de mise à niveau est acceptée et en cours de traitement.",
      "UPGRADE_SYNC_PUPUP_TITLE":"Mettre à niveau Azure",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Aligner avec la date de début du contrat",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Alignez la date d'entrée en vigueur du produit avec la prochaine période contractuelle récente. Veuillez noter que la prochaine date récente est calculée uniquement sur une base mensuelle, même si la durée du contrat est annuelle.",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Charger des factures sur l'identifiant de lot de QuickBooks Online",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Message d'erreur pour le chargement de factures sur Quickbooks",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"Voulez-vous vraiment télécharger les factures sélectionnées dans <b>QuickBooks en ligne</b> ?",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Charger des factures sur l'identifiant de lot de Xero Batch",
      "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"Êtes-vous sûr de vouloir télécharger les factures sélectionnées dans <b>Xero</b> ?",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Message d'erreur pour le chargement de factures sur Xero",
      "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Télécharger les détails des revendeurs",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"Échec du téléchargement de l'historique des abonnements dans le service externe",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Téléchargement réussi de l'historique des abonnements dans le service externe",
      "UPLOAD_TO_PSA_SELECT_ALL":"Sélectionner tout",
      "URL is valid!":"L'URL est valide !",
      "Usage":"Utilisation du rapport",
      "Usage tiers":"Niveaux d'utilisation",
      "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"Vous ne pouvez pas mettre à jour le pourcentage -ve avec une valeur du pourcentage disponible.",
      "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Vous ne pouvez pas mettre à jour le pourcentage à une valeur supérieure au pourcentage disponible.",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Récupération du rapport d'utilisation",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Identifiant d'abonnement Azure",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Facturé au client",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Date de fin des frais",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Date de début des frais",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Coût pour le client",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Coût pour le partenaire (après impôt)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Coût pour le partenaire (avant impôt)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Code de devise",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nom du client",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Télécharger le rapport d'utilisation",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nom de domaine",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Rapport d'utilisation",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Abonnements Azure",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Retour aux abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Seuil de danger",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Seuil d'erreur",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Nom du groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Sélectionner un groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Seuil d'information",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Masquer pour le client",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Budget mensuel",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":" Seuil d’avertissement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Retour aux utilisateurs de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Gérer l'utilisateur de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Courriel",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Rôle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Contributeur",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Propriétaire",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Lecteur",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux inscriptions",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exporter les détails d'utilisation ",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Résumé de facturation",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Détails d'utilisation de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Dépenser",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Unités consommées",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Type de ressource",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Ajouter abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Retour aux clients",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"Abonnements Azure pour",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Tous les abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Période de facturation",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Modifier abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Utilisateurs",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Consulter les détails",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Actions",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Coût estimé",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Groupe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Identifiant de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Nom de l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Seuils",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Ajouter l'utilisateur à l'abonnement",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Retour aux abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Les utilisateurs de l'abonnement de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Courriel",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Nom",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Rôle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Supprimer un utilisateur",
      "USER_CREATED_POPUP":"Utilisateur créé",
      "USER_CREATION_SUCCESS_MESSAGE":"L'utilisateur {{UserName}} a été créé avec succès",
      "USER_DETAILS_NOT_FOUND_BODY":"Impossible de trouver les informations de l'utilisateur connecté. Veuillez contacter l'administrateur !",
      "USER_DETAILS_NOT_FOUND_HEADER":"Détails introuvables",
      "USER_HAS_BEEN_CREATED":"L'utilisateur a été créé avec les détails suivants",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"BatchId de l'attribution des licences utilisateurs",
      "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Statut du lot d'attribution de licence utilisateur",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Terminer",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Statut d'affectation de licence",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Nom du client",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Utilisateurs",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"E-mail",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Nom de domaine",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Identifiant de domaine",
      "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"Impossible de mettre à jour le statut, veuillez contacter le support",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Extension ",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Forfait",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Lié",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Statut de suppression de l'utilisateur",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Libérer les licences inutilisées",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Appliquer les modifications aux extensions",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Utilisateurs du produit",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Une erreur s'est produite lors de la libération des sièges. Veuillez contacter le soutien technique.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Il devrait y avoir au moins une licence active. Veuillez suspendre le produit si vous ne souhaitez plus l'utiliser.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"La demande de libération des sièges est en cours de traitement.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Chargé avec succès",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"La demande d'ajout de licences est acceptée et en cours de traitement.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"La demande d'affectation globale de licences est acceptée et en cours de traitement.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Affecté",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Nombre total de sièges",
      "USER_LISTING_FILTER_LABEL":"Identifiant de courriel",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Recherche par prénom, nom de famille, identifiant de courriel",
      "USER_LISTING_SEARCH_BUTTON":"Rechercher",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Exporter le rapport d'affectation des utilisateurs",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entités",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Sélectionnez au moins une entité",
      "USER_REMOVED":"Utilisateur supprimé avec succès",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Nom de rôle",
      "USER_ROLE_LIST_LABEL_SCOPE":"Nom de portée",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Nom d'utilisateur",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Utilisateur ajouté avec succès",
      "USERCONTROLLER_USERUPDATESUCCESS":"Détails de l'utilisateur mis à jour avec succès",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Accès de l'utilisateur révoqué",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ajouter",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Modifier",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Utilisateur",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"n'est pas disponible",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"est disponible",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Informations Utilisateur",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Cochez / décochez les cases à cocher à côté des offres pour attribuer / libérer des licences",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"S'il n'y a pas assez de licences libres disponibles pour une offre, un achat sera effectué avant que l'attribution ne soit effectuée",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"Indique qu'un abonnement est en cours de provisionnement par C3. Veuillez attendre que l'opération se termine.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Nom d'affichage",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Courriel",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Prénom",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Instructions",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nom de famille",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Emplacement d'utilisation",
      "USERS_ADD_EDIT_FORM_SEATS":"Droits",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Retour aux utilisateurs",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Ajouter",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Modifier",
      "USERS_ADD_OR_EDIT_CAPTION_3":"Utilisateur",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Courriel",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Prénom",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Nom de famille",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Le dernier chargement groupé pour créer des utilisateurs est terminé!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Impossible d'accorder le privilège d'administration client aux utilisateurs externes",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"Êtes-vous certain de vouloir accorder le privilège admin client à",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Tableau de bord",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Utilisateurs",
      "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Analyse d'intégration",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Votre demande de création d'utilisateurs a été mise en attente. Veuillez revenir sur cette page après un certain moment pour les mises à jour. Le processus prend généralement quelques minutes à compléter.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Une erreur s’est produite lors de l'exportation des détails d'erreur",
      "USERS_BULK_CREATION_FIX_ERRORS":"Veuillez corriger les erreurs reportées dans le fichier qui vient d'être téléchargé et réessayer.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Votre demande de création d'utilisateurs a été effectuée avec succès.",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Obtenir les détails d'utilisateurs chargés",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"Il reste {{AvailableUnits}} sur {{TotalUnits}}",
      "USERS_BUY_MORE":"Acheter plus",
      "USERS_CREATE_SUCCESS":"L'utilisateur {{UserName}} a été créé avec succès",
      "USERS_DELETE_CONFIRM":"Toutes les licences affectées seront supprimées et {{userName}} sera supprimé. Voulez-vous continuer ?",
      "USERS_DELETE_EXISTENCE_CONFIRM":"Cet utilisateur a un accès dans C3 qui sera supprimé. Voulez-vous continuer ?",
      "USERS_DELETE_SUCCESS":"{{username}} a été supprimé avec succès",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Data non trouvée. Veuillez essayer plus tard",
      "USERS_GRANT_ACCESS_CONFIRM":"Êtes-vous certain de vouloir accorder le privilège admin client à {{username}} ?",
      "USERS_GRANT_ACCESS_SUCCESS":"Accorder l'accès a {{username}} avec succès",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Accorder l'accès aux utilisateurs externes n'est pas permis",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"Êtes-vous certain de vouloir ignorer les erreurs ?",
      "USERS_LICENSE_FREE_OUT_OF":"Licences libres de",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Attribution de licences pour l'utilisateur {{username}} en cours. Ce processus pourrait prendre quelques minutes avant de se terminer.",
      "USERS_LICENSES_REVOKE_SUCCESS":"Les licences de {{username}}  ont été révoquées avec succès",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Les licences de {{username}}  ont été révoquées avec succès. L'attribution de nouvelles licences en cours. Ce processus prendrait quelques minutes à compléter.",
      "USERS_LICENSES_UPDATE_SUCCESS":"Les licences pour l'utilisateur {{username}} mises à jour avec succès",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Ajouter un nouvel utilisateur",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Chargement de plusieurs utilisateurs",
      "USERS_LIST_CAPTION_TEXT_USERS":"Utilisateurs",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Modifier les détails utilisateur ",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Accorder l'accès au portail",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Réinitialiser le mot de passe",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Afficher licences",
      "USERS_LIST_TABLE_HEADER_ACTION":"Actions",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Identifiant de courriel",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Est actif",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Aucun rôle d'utilisateur attribué.",
      "USERS_LIST_TABLE_HEADER_ROLE":"Rôle(s) CSP",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Nom d'utilisateur",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Montrer les règles",
      "USERS_PASSWORD_RESET_SUCCESS":"Mot de passe pour {{username}} a été réinitialisé avec succès",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Mot de passe envoyé avec succès aux destinataires spécifiés",
      "USERS_RESETUSERPASSWORD_NEW_PASSWORD":"<br/> le nouveau mot de passe est {{userPasword}}",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Le mot de passe réinitialisé pour",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"La réinitialisation du mot de passe est réussie, le mot de passe est :<à ngclipboard='' data-clipboard-text='{{Password}}' ng-click='vm.confirmCopy()'><b>{{Password}}</b></a> <br/><br/><b>Après avoir quitté cette page, vous ne pouvez plus afficher ce mot de passe temporaire.</b><br/><br/>Si vous souhaitez envoyer le mot de passe par courriel, veuillez indiquer les destinataires séparés par des virgules dans la zone de texte ci-dessous, sinon appuyez sur Annuler.<br/>",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"La réinitialisation du mot de passe a réussi, le mot de passe est :",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Après avoir quitté cette page, vous ne pourrez plus afficher ce mot de passe temporaire.</b><br/><br/>Si vous souhaitez envoyer le mot de passe par e-mail, veuillez indiquer les destinataires séparés par des virgules dans la zone de texte ci-dessous, sinon appuyez sur Annuler.<br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"Êtes-vous certain de vouloir révoquer le privilège {{UserRoleDescription}} pour {{userName}}",
      "USERS_REVOKE_ACCESS_SUCCESS":"L'accès de {{username}} a été révoqué avec succès",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Obtenir les détails d'utilisateurs chargés",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Essayez un nouveau chargement!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Afficher et corriger les erreurs",
      "USERS_STATUS_CAPTION_STATUS":"Statut",
      "USERS_STATUS_WARNING_MESSAGE":"Création d'utilisateurs depuis le dernier chargement est toujours en cours. Veuillez revérifier en cliquant sur le bouton 'TransfertGroupéUtilisateurs' sur la page utilisateurs.",
      "USERS_TABLE_HEADER_EMAIL":"Courriel",
      "USERS_TABLE_HEADER_NAME":"Nom",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Description du plan",
      "USERS_UPDATE_LICENCES_QUEUED":"Les licences sont mises en file pour la mise à jour",
      "USERS_UPDATE_SUCCESS":"Détails utilisateurs pour {{username}} mis à jour avec succès",
      "USERS_UPDATELICENCES_SUCCESS":"Licences mises à jour avec succès",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Détails utilisateurs pour {{username}} mis à jour avec succès",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Charger",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"Erreurs détectées dans les données chargées. Télécharger les erreurs, corrigez-les et essayez de charger à nouveau.",
      "USERS_UPLOAD_ERROR":"Une erreur s’est produite pendant l'analyse du fichier chargé",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Veuillez sélectionner  un fichier à charger",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Supprimer",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Informations Utilisateur",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"Instructions",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Charger les données de compte pour de multiples utilisateurs en format .csv. Télécharger fichier .csv en échantillon <strong><a ng-click='downloadTemplate()'>Télécharger un fichier .csv en échantillon</a></strong> comme référence.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Ce fichier peut être utilisé pour créer plusieurs comptes utilisateurs pour un client. Ne modifier ou supprimer aucun texte existant ou données dans ce fichier. Les comptes utilisateurs seront créés avec des autorisations Utilisateur (pas Administrateur)",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Le prénom est un champ optionnel supportant entre 0 et 50 caractères. Exemple : Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Le nom est un champ optionnel supportant entre 0 et 50 caractères. Exemple: Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Le nom d'affichage est un champ obligatoire qui supporte entre 1 et 50 caractères. Exemple: Chris Green",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Le champ email est obligatoire. Veuillez saisir une adresse email valide dans ce champ pour chaque enregistrement. Exemple : chris@contoso.com ",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Le champ de mise à jour du statut est automatiquement rempli pour indiquer le statut de validation. Laissez ce champ vide.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Charger le fichier et cliquez sur <strong class=\"text-uppercase\">soumettre</strong>",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Téléchargement de fichier",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Emplacement d'utilisation",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Retour aux licences",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Retour aux utilisateurs",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Modifier licences",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Licences attribuées pour",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Date d'attribution de licence ",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Produit",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Résumé",
      "UTILISATEUR SUPPRIMÉ":"Utilisateur supprimé avec succès",
      "VALEUR INVALIDE":"{{atribute}} n'est pas valide",
      "Valid webhook URL":"URL de webhook valide",
      "Validate URL":"Valider l'URL",
      "VALIDATE_LIST":"Valider la liste",
      "VALIDATE_PRODUCT_QUANTITY":"La quantité de {{produit}} {{quantite}} doit être comprise entre {{minQuantity}} et {{maxQuantity}}",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"Échec de la validation de l'historique des abonnements pour le service externe",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Historique d'abonnement validé avec succès pour le service externe",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Client déjà lié à ce domaine.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Ce code promotionnel existe déjà",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Seuls les caractères alphanumériques sont autorisés dans le coupon-rabais !",
      "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Nous n'autorisons les remises qu'entre 0 et 100",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Cet email '{{emailAdresse}}' est déjà utilisé.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Veuillez entrer un nombre valide",
      "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Veuillez remplir tous les champs obligatoires avant de soumettre.",
      "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"La date de la facture doit être antérieure à la date d'échéance.",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Veuillez entrer une URL valide ou entrer l'URL par défaut en tant que '/#/contact/'",
      "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"La date de début doit être antérieure à la date de fin.",
      "VALIDATION_MESSAGE_FOR_URL":"Veuillez entrer une URL valide. ",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Veuillez entrer une URL valide ou entrer l'URL par défaut en tant que 'home.support'",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Veuillez entrer un code postal valide",
      "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"La longueur de l'IIN de l'entreprise doit être inférieure ou égale à 22",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Ce champ accepte un maximum de 50 caractères.",
      "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"La longueur de l'IIN de l'entreprise doit être supérieure à 4",
      "VALIDATION_MESSAGE_MINIMUM_0":"La valeur minimale est 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"La valeur minimale est 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Veuillez entrer un email valide",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Veuillez entrer un numéro de téléphone valide",
      "VALIDATION_MESSAGE_REQUIRED":"Ce champ est obligatoire",
      "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Saisissez un taux de conversion valide",
      "VALIDITY_TEXT_FROM":"De",
      "VALIDITY_TEXT_TO":"À",
      "VALIDITY_TYPE_DESC_DAYS":"Jour(s)",
      "VALIDITY_TYPE_DESC_MONTHS":"Mois",
      "VALIDITY_TYPE_DESC_YEARS":"Année(s)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Type de validité",
      "VALIDITY_TYPE_NAME_DAYS":"Jour(s)",
      "VALIDITY_TYPE_NAME_MONTHS":"Mois",
      "VALIDITY_TYPE_NAME_YEARS":"Année(s)",
      "VALUE_SHOULD_BE_GREATER_THAN":"La valeur doit être supérieure à 0",
      "VAT_NUMBER_DESC":"Numéro de TVA du client",
      "VAT_NUMBER_DESC_FOR_RESELLER":"Numéro de TVA du revendeur",
      "VAT_NUMBER_DISP":"numéro de TVA",
      "VAT_NUMBER_DISP_FOR_RESELLER":"numéro de TVA",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Valider le jeton",
      "VIEW_ALL_SELECTION":"Sélectionner tout",
      "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Afficher plus de détails",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Afficher les éléments de ligne d'utilisation",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Afficher les éléments de ligne d'utilisation",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Générer une facture",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Recharger le rapport de profitabilité",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Ajouter un utilisateur",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Supprimer un utilisateur",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Nom",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Affecter des balises",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Modifier les rôles",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Ajouter un utilisateur Partner",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Affecter des balises",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Supprimer Partner",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Modifier les rôles",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Gestion des utilisateurs Partner",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Gestion des utilisateurs",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Actions",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Courriel",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Rôles",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"Rôles",
      "viewuploadhistory":"Utilisation du rapport",
      "WAITING_FOR_APPROVAL":"en attente d'approbation",
      "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":"Vous avez désélectionné tous les",
      "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":"Vous avez sélectionné tous les",
      "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Entrez un nom d'entité",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"En vigueur à partir de",
      "WEBHHOOK_LABEL_EFFECTIVE_TO":"Efficace pour",
      "WEBHHOOK_LABEL_ENTITY":"Entité",
      "WEBHHOOK_LABEL_EVENT":"Événement",
      "Webhook":"Webhook",
      "Webhook method":"Méthode Webhook",
      "Webhook Notification":"Notification de webhook",
      "Webhook notification created successfully":"Notification Webhook créée avec succès",
      "Webhook notification deleted successfully":"La notification Webhook a été supprimée avec succès",
      "Webhook notification sent successfully":"Notification Webhook envoyée avec succès",
      "Webhook notification updated successfully":"La notification Webhook a été mise à jour avec succès",
      "Webhook URL":"URL du webhook",
      "Webhook URL is valid":"L'URL du webhook est valide",
      "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Entités marquées",
      "WEBHOOK_BREADCRUMB_ADD":"Créer un webhook",
      "WEBHOOK_BUTTON_ACTIONS_ADD":"Créer une notification de webhook",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Retour à la liste",
      "WEBHOOK_CANCEL_NOTIFICATION":"Annuler",
      "WEBHOOK_DESCRIPTION_TOOLTIP":"Entrez la description",
      "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Choisir en vigueur à ce jour",
      "WEBHOOK_END_DATE_PLACEHOLER":"Date de fin",
      "WEBHOOK_ENTITY_TOOLTIP":"Choisir une catégorie",
      "WEBHOOK_EVENT_TOOLTIP":"Choisir un événement",
      "WEBHOOK_HEADER_ACTIONS":"Actions",
      "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Entités associées",
      "WEBHOOK_HEADER_CREATET_DATE":"Créer un rendez-vous",
      "WEBHOOK_HEADER_DESCRIPTION":"Description",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"En vigueur à partir de",
      "WEBHOOK_HEADER_EFFECTIVE_TO":"Efficace pour",
      "WEBHOOK_HEADER_EVENTNAME":"Événement",
      "WEBHOOK_HEADER_TITLE":"Titre",
      "WEBHOOK_LABEL_DESCRIPTION":"Description",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Effectif pour",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Catégorie",
      "WEBHOOK_LABEL_EVENT_TEXT":"Événement",
      "WEBHOOK_LABEL_TITLE_TEXT":"Titre",
      "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"Méthode Webhook",
      "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Nombre de tentatives",
      "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"URL du webhook",
      "WEBHOOK_METHOD_METHOD_TOOLTIP":" Méthode à utiliser lors de l’envoi de données à un système externe via un webhook.",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Une erreur s'est produite lors de l'envoi de la notification du webhook",
      "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Notification Webhook envoyée avec succès",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Description",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"Identifiant",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Ancien nombre de tentatives",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"Ancienne URL du webhook",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Nom de produit",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Nombre de tentatives",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Titre",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"URL du webhook",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"Méthode Webhook",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Un seul produit peut être sélectionné.",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Erreur lors de la sélection du produit",
      "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notification Webhook créée avec succès",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Tous les produits ont été sélectionnés",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"Le produit a été sélectionné.",
      "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Éléments d'entité associés",
      "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"La notification Webhook a été mise à jour avec succès",
      "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Tous les produits ont été sélectionnés.",
      "WEBHOOK_SAVE_NOTIFICATION":"Soumettre",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"Sélectionner",
      "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Sélectionnez l'élément d'entité associé",
      "WEBHOOK_START_DATE_PLACEHOLER":"Date de début",
      "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Notification de webhook",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Cela dépasse la longueur maximale de 30",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Veuillez vous assurer que la valeur est comprise entre 0 et 3",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Ce champ est obligatoire",
      "WEBHOOK_TITLE_TOOLTIP":"Entrez le titre",
      "WEBHOOK_URL_TOOLTIP":" Saisissez l'URL de destination à laquelle le système enverra des requêtes HTTP pour informer un service externe d'événements ou de mises à jour spécifiques. Cette URL doit être capable de recevoir et de traiter les charges utiles des webhooks.",
      "WEBHOOK_VALIDATE_BUTTON":"Valider",
      "WEBHOOK_VALIDATE_URL":"Veuillez valider l'URL",
      "WEBHOOK_VALIDATE_URL_BUTTON":"Valider l'URL",
      "WEBHOOK_VALIDATION_ERROR_MESSAGE":"URL du webhook non valide",
      "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"L'URL du webhook est valide",
      "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Nombre de tentatives que le système effectuera pour effectuer une action spécifique en cas d'échec.",
      "WEBHOOK_WEBHOOK_URL_TOOLTIP":"URL du webhook",
      "webhookNotifications":"Webhook",
      "WELCOME_HEADER_1_PART_1":"Avec nous, vos possibilités sont infinies. ",
      "WELCOME_HEADER_1_PART_2":"Et ça commence maintenant.",
      "WELCOME_PORTAL_FEATUR_1":"Portail libre-service",
      "WELCOME_PORTAL_FEATUR_2":"Ajouter/retirer des abonnements rapidement et facilement",
      "WELCOME_PORTAL_FEATUR_3":"Paiements automatisés",
      "WELCOME_PORTAL_FEATUR_4":"Payer automatiquement les factures ",
      "WELCOME_PORTAL_FEATUR_5":"Garantir",
      "WELCOME_PORTAL_FEATUR_6":"Sécurisé avec Microsoft Azure Active Directory et l'authentification Multi-facteurs ",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"S'identifier",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Bienvenue",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Se déconnecter",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Contact",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Aller au tableau de bord",
      "Won''t Fix":"Ne peut être corrigé",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Merci d'avoir fourni votre consentement pour Xero. L'application peut être configurée pour accéder à Xero suivant la nouvelle directive de sécurité. Vous pouvez publier les factures de vos clients sur Xero.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Veuillez suivre les instructions pour Charger les factures sur Xero dans <b>{{applicationName}}</b>.",
      "XERO_CONSENT_DESCRIPTION":"Pour publier des factures sur Xero, vous devez fournir votre consentement.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Veuillez patienter pendant que nous traitons votre consentement Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Le lien ci-dessous s'ouvre dans un nouvel onglet. Veuillez fournir votre consentement avec vos identifiants Xero. Si une erreur se produit lors de votre consentement, veuillez contacter notre équipe d'assistance.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"pour fournir le consentement à Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Si vous avez déjà fourni le consentement, veuillez cliquer sur le bouton <b> Valider le jeton </b> pour vérifier la connectivité avec Xero.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Cliquez sur ce bouton pour vérifier la validité du jeton d'actualisation Xero existant.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Conditions préalables pour que le partenaire publie les factures des clients dans <b> XERO </b>",
      "XERO_POST_BATCH_ID":"Identifiant de lot de publication Xero",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Votre demande d'envoi de factures à Xero est acceptée et mise en file d'attente pour traitement. Veuillez cliquer sur le bouton <strong>Afficher les journaux de publication</strong> pour obtenir des mises à jour sur les progrès.",
      "XERO_POST_LOGS_STATUS":"Statut des journaux de publication Xero",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Le jeton d'actualisation Xero existant n'est pas valide. Veuillez fournir à nouveau votre consentement",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Le jeton d'actualisation Xero existant est valide. Vous pouvez continuer à charger des factures",
      "XERO_TENANT_ID":"Identifiant de domaine Xero",
      "Year(s)":"Année(s)",
      "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Vous pouvez spécifier le pourcentage de taxe pour le client. Par exemple : 15 pour 15 %",
      "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Vous pouvez spécifier le nom de la taxe comme la TPS..",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Vous n'êtes pas autorisé à effectuer cette action",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Vous devez sélectionner une ou plusieurs offres pour créer un abonnement"
   
  },

};
