export class ActionableElement {
    GET_CUSTOMERS_REVENUE = 'GET_CUSTOMERS_REVENUE';
    GET_CUSTOMER_CART = 'BTN_VIEW_CART';
    GET_CART_SIZE = 'BTN_VIEW_CART';
    SAVE_TO_CART = 'BTN_ADD_TO_CART';
    VIEW_CART = 'BTN_VIEW_CART';
    UPDATE_QUANTITY_CART = 'BTN_UPDATE_QUANTITY';
    REMOVE_QUANTITY_CART = 'BTN_DELETE_CART_ITEM';
    // REMOVE_QUANTITY_CART = 'BTN_CONTINUE_SHOPPING';
    // REMOVE_QUANTITY_CART = 'BTN_PLACE_ORDER';
    UPDATE_CART_ITEM = 'BTN_ADD_TO_CART';
    DELETE_CART_ITEM = 'BTN_DELETE_CART_ITEM';
    CART_CHECKOUT = 'BTN_PLACE_ORDER';
    SCHEDULE_ORDER = 'BTN_SCHEDULE_ORDER';
    BTN_REMOVE_AND_CANCEL_ORDER = 'BTN_REMOVE_AND_CANCEL_ORDER';
    UPDATE_PRODUCT_NAME_AT_CART = 'UPDATE_PRODUCT_NAME_AT_CART';
    GET_PLAN_OFFER_CATEGORIES = 'GET_PLAN_OFFER_CATEGORIES';
    GET_CUSTOMER_PRODUCTS = 'GET_CUSTOMER_PRODUCTS';
    BTN_MANAGE_PRODUCT = 'BTN_MANAGE_PRODUCT';
    BTN_DEACTIVATE_CUSTOMER_PRODUCT = 'BTN_DEACTIVATE_CUSTOMER_PRODUCT';
    SAVE_CUSTOMER_PRODUCTS = 'BTN_SAVE_PRODUCT_CHANGES';
    ASSIGN_CUSTOMER_PRODUCTS = 'BTN_ASSIGN_PRODUCT';
    GENERATE_CSV_FOR_PROFITABILITY_REPORT = 'BTN_DOWNLOAD_PROFITABILITY_REPORT';
    DOWNLOAD_PROFITABILITY_REPORT_BY_PRODUCT = 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_PRODUCT';
    DOWNLOAD_PROFITABILITY_REPORT_BY_TENANT = 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_TENANT';
    BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_RESELLER = 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_RESELLER';
    GET_RESELLER_REVENUE = 'GET_RESELLER_REVENUE';
    DOWNLOAD_PROFITABILITY_REPORT_BY_RESELLER = 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_RESELLER';
    COST_ON_PARTNER = 'COST_ON_PARTNER';
    PROFIT = 'PROFIT';
    PROFIT_PERCENTAGE = 'PROFIT_PERCENTAGE';
    GET_TAX_AMOUNTS_SUMMARY_REPORT = 'BTN_DOWNLOAD_TAX_SUMMARY_REPORT';
    GET_TAX_AMOUNTS_SUMMARY_BY_SUBSCRIPTION_REPORT = 'BTN_DOWNLOAD_TAX_LINE_ITEMS_REPORT';
    GET_INVOICE_SUMMARY_REPORT = 'BTN_DOENLOAD_INVOICE_SUMMARY_REPORT';
    GET_INVOICE_LINE_ITEMS_REPORT = 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_REPORT';
    DOWNLOADINVOICELINEITEMSWITHCOMMISSIONS = 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_WITH_COMMISSIONS';
    GET_MONTHLY_TURNOVER_REPORT = 'BTN_DOWNLOAD_MONTHLY_TURNOVER_REPORT';
    GETPROFITABILITY = 'PARTNER_DASH_BOARD_VIEW_PROFITABILITY';
    GETACTIVECUSTOMERSCOUNT = 'VIEW_CUTOMERS_COUNT_TILE';
    GETINVOICEPAYMENTDETAILS = 'PARTNER_DASH_BOARD_VIEW_ACCOUNTS_RECEIVABLE';
    GETPENDINGPAYMENTS = 'PARTNER_DASH_BOARD_VIEW_PAST_DUES';
    BTN_RELOAD_PROFITABILITY_REPORT = 'BTN_RELOAD_PROFITABILITY_REPORT';
    GET_CUSTOMERS = 'GET_CUSTOMERS';
    GET_ADMIN_USERS = 'GET_ADMIN_USERS';
    BTN_ADD_NEW_C3_CUSTOMER_USER = 'BTN_ADD_NEW_C3_CUSTOMER_USER';
    BTN_MANAGE_C3_CUSTOMER_USER = 'BTN_MANAGE_C3_CUSTOMER_USER';
    BTN_GRANT_ACCESS_TO_C3_CUSTOMER_USER = 'BTN_GRANT_ACCESS_TO_C3_CUSTOMER_USER';
    BTN_REVOKE_ACCESS_FROM_C3_CUSTOMER_USER = 'BTN_REVOKE_ACCESS_FROM_C3_CUSTOMER_USER';
    BTN_OVERRIDE_CUSTOMER_CONFIGURATION = 'BTN_OVERRIDE_CUSTOMER_CONFIGURATION';
    BTN_SAVE_CUSTOMER_CONFIGURATION = 'BTN_SAVE_CUSTOMER_CONFIGURATION';
    BTN_REVERT_CUSTOMER_CONFIGURATION = 'BTN_REVERT_CUSTOMER_CONFIGURATION';
    DOWNLOAD_LICENSE_SUMMARY_REPORT = 'DOWNLOAD_LICENSE_SUMMARY_REPORT';
    DOWNLOAD_USAGE_REPORT = 'DOWNLOAD_USAGE_REPORT';
    DOWNLOAD_LICENSE_CHANGE = 'DOWNLOAD_LICENSE_CHANGE';
    ACCESS_USER_LICENSE_TRACKING_VIEW = 'ACCESS_USER_LICENSE_TRACKING_VIEW';
    BTN_RELEASE_SEATS = 'BTN_RELEASE_SEATS';
    MANAGE_USER_LICENSES = 'MANAGE_USER_LICENSES';
    GET_USER_LICENSE_DETAILS = 'GET_USER_LICENSE_DETAILS';
    UPDATE_USER_LICENSE_ASSIGNMENT_BATCH_STATUS = 'UPDATE_USER_LICENSE_ASSIGNMENT_BATCH_STATUS';
    GET_CUSTOM_VIEW_SETTINGS = 'GET_CUSTOM_VIEW_SETTINGS';
    GET_CONNECTWISE_SETTINGS = 'GET_CONNECTWISE_SETTINGS';
    SAVE_CUSTOM_VIEW_SETTINGS = 'SAVE_CUSTOM_VIEW_SETTINGS';
    menu_customer_comments = 'menu_customer_comments';
    ALL_COMMENTS = 'ALL_COMMENTS';
    BTN_CUSTOMERS_GRID_DOWNLOADABLE_REPORTS = 'BTN_CUSTOMERS_GRID_DOWNLOADABLE_REPORTS';
    BTN_INVOICELINEITEM_GRID_DOWNLOADABLE_REPORTS = 'BTN_INVOICELINEITEM_GRID_DOWNLOADABLE_REPORTS';
    ADD_NEW_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_ADD_NEW_SERVICE_PROVIDER_CUSTOMER_USER';
    EDIT_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_EDIT_SERVICE_PROVIDER_CUSTOMER_USER';
    DELETE_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_DELETE_SERVICE_PROVIDER_CUSTOMER_USER';
    CHANGE_PASSWORD_OF_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_CHANGE_PASSWORD_OF_SERVICE_PROVIDER_CUSTOMER_USER';
    GRANT_ACCESS_TO_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_GRANT_ACCESS_TO_SERVICE_PROVIDER_CUSTOMER_USER';
    REVOKE_ACCESS_FROM_SERVICE_PROVIDER_CUSTOMER_USER = 'BTN_REVOKE_ACCESS_FROM_SERVICE_PROVIDER_CUSTOMER_USER';
    GET_PROVIDER_USER_LICENSES = 'BTN_GET_PROVIDER_USER_LICENSES';
    ASSIGN_PROVIDER_LICENSES_TO_PROVIDER_CUSTOMER_USER = 'BTN_ASSIGN_PROVIDER_LICENSES_TO_PROVIDER_CUSTOMER_USER';
    SHOW_USER_ROLE = 'SHOW_USER_ROLE';
    SHOW_PROVIDER_USER_ROLE = 'SHOW_PROVIDER_USER_ROLE';
    EXPORT_LICENSE_REPORT = 'BUTTON_TEXT_EXPORT_USER_LICENSE';
    ADD_PARTNER_USER = 'BTN_ADD_PARTNER_USER';
    DELETE_PARTNER_USER = 'BTN_DELETE_PARTNER_USER';
    RESENDINVOICE = 'BTN_RESEND_INVOICE';
    GETCUSTOMPRODUCTSEQUENCE = 'GET_PRODUCT_SEQUENCE';
    UPDATECUSTOMPRODUCTSEQUENCE = 'UPDATE_PRODUCT_SEQUENCE';
    MANAGEPRODUCTUSAGETAB = 'MANAGE_PRODUCT_USAGE_TAB';
    ADDUSAGEBASEDPARTNEROFFER = 'ADD_USAGE_BASED_PARTNER_OFFER';
    GETCUSTOMERANDRESELLERDETAILSFORBULKINVOICEDOWNLOAD = 'GET_CUSTOMER_AND_RESELLER_DETAILS_FOR_BULK_INVOICE_DOWNLOAD';
    SAVEBULKINVOICEDOWNLOADDETAILSTOSTAGINGTABLE = 'SAVE_BULK_INVOICE_DOWNLOAD_DETAILS_TO_STAGING_TABLE';
    DELETEBULKINVOICEDETAILS = 'DELETE_BULK_INVOICE_DETAILS';
    GETBULKINVOICESTATUSDETAILS = 'GET_BULK_INVOICE_STATUS_DETAILS';
    GETBULKINVOICEDOWNLOADPROGRESS = 'GET_BULK_INVOICE_DOWNLOAD_PROGRESS';
    GETBULKINVOICEDOWNLOADLINK = 'GET_BULK_INVOICE_DOWNLOAD_LINK';
    SUBSCRIPTION_END_DATE_ALIGNMENT = 'SUBSCRIPTION_END_DATE_ALIGNMENT';
    EXPORTUSERLICENSEASSIGNMENTREPORT = 'BTN_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT';
    DOWNLOADPAYMENTSCATALOGUE = 'DOWNLOAD_PAYMENTS_CATALOGUE';
    VALIDATEANDIMPORTPAYMENTRECORD = 'VALIDATE_AND_IMPORT_PAYMENT_RECORD';
    GET_AUTOTASK_SETTINGS = 'GET_AUTOTASK_SETTINGS';
    TAB_TAXES = 'TAB_TAXES';
    GET_USERS_FOR_ENTITY = 'GET_USERS_FOR_ENTITY';

    //Manage Product
    AUTO_RELEASE = 'AUTO_RELEASE';
    MANAGE_PRODUCT_AUTO_RELEASE = 'MANAGE_PRODUCT_AUTO_RELEASE';
    MANAGE_PRODUCT_APPROVAL = 'MANAGE_PRODUCT_APPROVAL';
    BTN_PURCHASED_PRODUCTS_GRID_DOWNLOADABLE_REPORTS = 'BTN_PURCHASED_PRODUCTS_GRID_DOWNLOADABLE_REPORTS';

    //User management
    ADD_USER = 'BTN_ADD_USER';
    DELETE_USER = 'BTN_DELETE_USER';
    ASSIGN_TAGS= 'BTN_ASSIGN_TAGS'

    ADD_CUSTOMER = 'BTN_ADD_CUSTOMER';
    GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS';
    GET_ORDER_ITEMS = 'BTN_VIEW_ORDER_DETAILS';
    GET_PARTNER_SETTINGS = 'GET_PARTNER_SETTINGS';
    GET_PLANS = 'sidebar_Partner_Plans';
    SIDEBAR_PARTNER_DOCUMENTATION = 'sidebar_partner_documentation';
    GET_PLAN_OFFERS = 'ACTION_GET_PLAN_OFFERS';
    SAVE_PLAN = 'BTN_SAVE_OR_UPDATE_PLAN';
    LINK_PRODUCT = 'BTN_LINK_PRODUCT';
    EDIT_PLAN = 'BTN_EDIT_PLAN';
    GET_PRODUCT_CATALOGUE = 'GET_PRODUCT_CATALOGUE';
    BTN_DEFINE_PLAN_IN_OTHER_CURRENCY = 'BTN_DEFINE_PLAN_IN_OTHER_CURRENCY';
    SAVE_CURRENCY_CONVERSION = 'ACTION_SAVE_PLAN_IN_OTHER_CURRENCY';
    GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
    GET_PARTNER_OFFERS = 'GET_PARTNER_OFFERS';
    SAVE_OR_UPDATE_PARTNER_OFFER = 'BTN_SAVE_OR_UPDATE_PARTNER_OFFER';
    EDIT_PARTNER_OFFER = 'BTN_EDIT_PARTNER_OFFER';
    BTN_DELETE_PARTNER_OFFER = 'BTN_DELETE_PARTNER_OFFER';
    BTN_ADD_PARTNER_OFFER = 'BTN_ADD_PARTNER_OFFER';
    BTN_SAVE_OR_UPDATE_CONTRACT_OFFER = 'BTN_SAVE_OR_UPDATE_CONTRACT_OFFER';
    BTN_EDIT_CONTRACT_OFFER = 'BTN_EDIT_CONTRACT_OFFER';
    BTN_DELETE_CONTRACT_OFFER = 'BTN_DELETE_CONTRACT_OFFER';
    BTN_ADD_CONTRACT_OFFER = 'BTN_ADD_CONTRACT_OFFER';
    PARTNER_PROVISIONING_STATUS = 'TAB_PARTNER_OFFER_PROVISIONING_STATUS';
    UPDATE_PARTNER_OFFER_PROVISIONING_STATUS = 'BTN_UPDATE_PARTNER_OFFER_PROVISIONING_STATUS';
    VIEW_BUNDLES_LIST = 'sidebar_partner_bundles';
    GET_PARTNER_BUNDLES = 'GET_PARTNER_BUNDLES';
    ADD_PARTNER_BUNDLES = 'BTN_ADD_BUNDLES';
    ACTION_SAVE_BUNDLE = 'ACTION_SAVE_BUNDLE';
    GET_PRODUCTS = 'GET_ALL_PRODUCTS';
    VIEW_OFFERS = 'ACTION_GET_PLAN_OFFERS';
    GET_USER_PROFILE = 'MENU_CUSTOMER_PROFILE';
    SAVE_BASIC_DETAILS = 'BTN_SAVE_CUSTOMER_BASIC_DETAILS';
    VIEW_CUSTOMER_ADDRESS = 'VIEW_CUSTOMER_ADDRESS';
    ADD_CUSTOMER_ADDRESS = 'BTN_ADD_CUSTOMER_ADDRESS';
    EDIT_CUSTOMER_ADDRESS = 'BTN_EDIT_CUSTOMER_ADDRESS';
    DELETE_CUSTOMER_ADDRESS = 'BTN_DELETE_CUSTOMER_ADDRESS';
    VIEW_CUSTOMER_PHONE = 'VIEW_CUSTOMER_PHONE';
    ADD_CUSTOMER_PHONE = 'BTN_ADD_CUSTOMER_PHONE';
    EDIT_CUSTOMER_PHONE = 'BTN_EDIT_CUSTOMER_PHONE';
    DELETE_CUSTOMER_PHONE = 'BTN_DELETE_CUSTOMER_PHONE';
    VIEW_CUSTOMER_EMAIL = 'VIEW_CUSTOMER_EMAIL';
    ADD_CUSTOMER_EMAIL = 'BTN_ADD_CUSTOMER_EMAIL';
    EDIT_CUSTOMER_EMAIL = 'BTN_EDIT_CUSTOMER_EMAIL';
    DELETE_CUSTOMER_EMAIL = 'BTN_DELETE_CUSTOMER_EMAIL';
    TAB_AZURE_ESTIMATES = 'TAB_AZURE_ESTIMATES';
    TAB_AZURE_USERS = 'TAB_PRODUCT_AZURE_USERS';
    DOWNLOAD_AZURE_ESTIMATES_REPORT = 'BTN_DOWNLOAD_AZURE_ESTIMATE_REPORT';
    BTN_DOWNLOAD_AZURE_ESTIMATE_REPORT_BY_TAGS = 'BTN_DOWNLOAD_AZURE_ESTIMATE_REPORT_BY_TAGS';
    ACTION_DOWNLOAD_AZURE_ESTIMATE_REPORT = 'ACTION_DOWNLOAD_AZURE_ESTIMATE_REPORT';
    DOWNLOAD_AZURE_BILLING_REPORT = 'BTN_DOWNLOAD_AZURE_BILLING_REPORT';
    BTN_ADD_USERS_TO_SUBSCRIPTIONS = 'BTN_ADD_USERS_TO_SUBSCRIPTIONS';
    BTN_REMOVE_USERS_FROM_SUBSCRIPTIONS = 'BTN_REMOVE_USERS_FROM_SUBSCRIPTIONS';
    BTN_ASSIGN_ACCESS_FOR_USER = 'BTN_ASSIGN_ACCESS_FOR_USER';
    BTN_REVOKE_ACCESS_FOR_USER = 'BTN_REVOKE_ACCESS_FOR_USER';
    GET_PARTNER_PRODUCT_USAGE_DATA = 'sidebar_partner_offer_usage_report';
    BTN_EXPORT_USER_ROLE_ASSIGNMENT_REPORT = 'BTN_EXPORT_USER_ROLE_ASSIGNMENT_REPORT';
    ADD_DISTRIBUTOR_OFFERS = 'ADD_DISTRIBUTOR_OFFERS';
    EDIT_DISTRIBUTOR_OFFERS = 'EDIT_DISTRIBUTOR_OFFERS';
    DELETE_DISTRIBUTOR_OFFERS = 'DELETE_DISTRIBUTOR_OFFERS';
    BTN_ADD_DISTRIBUTOR_OFFERS = 'BTN_ADD_DISTRIBUTOR_OFFERS';
    BTN_ADD_MISSING_OFFERS_TO_PLANS = 'BTN_ADD_MISSING_OFFERS_TO_PLANS';
    GET_PARTNER_TRIAL_OFFER = 'GET_PARTNER_TRIAL_OFFER';
    CANCEL_PARTNER_OFFER_TRIAL = 'CANCEL_PARTNER_OFFER_TRIAL';
    CREATE_PARTNER_TRIAL_OFFER = 'CREATE_PARTNER_TRIAL_OFFER';
    GET_PARTNER_TRIAL_OFFER_FILTER = 'GET_PARTNER_TRIAL_OFFER_FILTER';
    PURCHASE_PARTNER_OFFER_TRIAL = 'PURCHASE_PARTNER_OFFER_TRIAL';



    /*BEGIN = Reconciliation Report*/
    BTN_ONBOARD_SUBSCRIPTION_BY_MANUAL_SYNC = 'BTN_ONBOARD_SUBSCRIPTION_BY_MANUAL_SYNC';
    BTN_FIX_SUBSCRIPTION_QUANTITY_BY_MANUAL_SYNC = 'BTN_FIX_SUBSCRIPTION_QUANTITY_BY_MANUAL_SYNC';
    BTN_FIX_SUBSCRIPTION_STATUS_BY_MANUAL_SYNC = 'BTN_FIX_SUBSCRIPTION_STATUS_BY_MANUAL_SYNC';
    UPDATE_BILLING_CYCLE = 'UPDATE_BILLING_CYCLE';
    UPDATE_TERM_DURATION = 'UPDATE_TERM_DURATION';
    UPDATE_OFFER = 'UPDATE_OFFER';
    GET_RECONCILIATION_REPORT = 'GET_RECONCILIATION_REPORT';
    /*END = Reconciliation Report*/

    /*BEGIN = External service (PSA) */
    GET_CUSTOMERS_LIST_TO_PICK_FOR_EXTERNAL_SERVICE = 'GET_CUSTOMERS_LIST_TO_PICK_FOR_EXTERNAL_SERVICE';
    UPLOAD_TO_AUTOTASK = 'UPLOAD_TO_AUTOTASK';
    UPLOAD_TO_CONNECTWISE = 'UPLOAD_TO_CONNECTWISE';
    VALIDATE_UPLOAD_TO_EXTERNAL_SERVICE = 'VALIDATE_UPLOAD_TO_EXTERNAL_SERVICE';
    UPLOAD_TO_EXTERNAL_SERVICE = 'UPLOAD_TO_EXTERNAL_SERVICE';
    GET_EXTERNAL_SERVICE_POST_LOGS = 'GET_EXTERNAL_SERVICE_POST_LOGS';
    GET_ENTITY_AND_PRODUCT_MAPPING = 'GET_ENTITY_AND_PRODUCT_MAPPING';
    MANAGE_ENTITY_AND_PRODUCT_MAPPING = 'MANAGE_ENTITY_AND_PRODUCT_MAPPING';
    DEACTIVATE_PSA_LOG = 'DEACTIVATE_PSA_LOG';
    REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL = 'REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL';
    BULK_MANAGE_ENTITY_AND_PRODUCT_MAPPING = 'BULK_MANAGE_ENTITY_AND_PRODUCT_MAPPING';
    /*END = External service (PSA) */

    UPDATE_PREFERRED_LANGUAGE = 'UPDATE_PREFERRED_LANGUAGE';
    GET_SHOP_PRODUCTS = 'GET_SHOP_PRODUCTS';
    IMPERSONATION = 'BTN_QUICK_IMPERSONATION';
    BTN_CUSTOMER_NAME_CHANGE = 'BTN_CUSTOMER_NAME_CHANGE';
    BTN_RESELLER_NAME_CHANGE = 'BTN_RESELLER_NAME_CHANGE';
    RESELLER_IMPERSONATION = 'BTN_QUICK_RESELLER_IMPERSONATION';
    GET_INVOICES = 'BTN_VIEW_INVOICES';
    GET_ALL_INVOICES = 'GET_ALL_INVOICES';
    GET_INVOICE_DETAILS = 'BTN_VIEW_INVOICE_DETAILS';
    BTN_DOWNLOAD_PREVIOUS_INVOICE_DUES = 'VIEW_PREVIOUS_INVOICE_DUES';
    GET_PREVIOUS_INVOICE_DUES = 'BTN_DOWNLOAD_PREVIOUS_INVOICE_DUES';
    BTN_DOWNLOAD_INVOICE_DUES_REPORT = 'BTN_DOWNLOAD_INVOICE_DUES_REPORT';
    SYNC_MICROSOFT_CUSTOMER_CONSENT = 'SYNC_MICROSOFT_CUSTOMER_CONSENT';
    SYNC_PROVIDER_CUSTOMER_PROFILE = 'BTN_SYNC_PROVIDER_CUSTOMER_PROFILE';
    RELOAD_RECON_REPORT = 'RELOAD_RECON_REPORT';
    TAB_INVOICE_PAYMENTS = 'TAB_INVOICE_PAYMENTS';
    GET_SUBSCRIPTION_HISTORY = 'BTN_VIEW_SUBSCRIPTION_HISTORY';
    BTN_DOWNLOAD_INVOICES_OR_PAYMENTS = 'BTN_DOWNLOAD_INVOICES_OR_PAYMENTS';
    BTN_PROVIDER_PAYMENT_DETAILS = 'BTN_PROVIDER_PAYMENT_DETAILS';
    //BTN_PROVIDER_PAYMENT_DETAILS = 'BTN_PROVIDER_PAYMENT_DETAILS';
    BTN_INITIATE_PAYMENT = 'BTN_INITIATE_PAYMENT';
    BTN_GENERATE_INVOICES = 'BTN_GENERATE_INVOICES';
    BTN_ADD_ADJUSTMENTS = 'BTN_ADD_ADJUSTMENTS';
    BTN_SAVE_PAYMENT = 'BTN_SAVE_PAYMENT';
    GET_AZURE_GROUPS = 'GET_AZURE_GROUPS';
    ACTION_AZURE_STATUS_CHANGE = 'ACTION_AZURE_STATUS_CHANGE';
    ACTION_UPGRADE_AZURE_SUBSCRIPTION = 'ACTION_UPGRADE_AZURE_SUBSCRIPTION';
    BTN_ADD_AZURE_GROUP = 'BTN_ADD_AZURE_GROUP';
    GET_AZURE_GROUP_ADMINS = 'GET_GROUP_ADMINS';
    SAVE_AZURE_GROUP_ADMINS = 'SAVE_GROUP_ADMINS';
    DOWNLOAD_INVOICE_PDF = 'BTN_DOWNLOAD_INVOICE_PDF';
    UPDATE_DUEDATE = 'UPDATE_DUEDATE';
    UPDATE_SUBSCRIPTION_PO_NUMBER = 'UPDATE_SUBSCRIPTION_PO_NUMBER';
    UPDATE_PO_NUMBER = 'UPDATE_PO_NUMBER';
    ACTION_UPDATE_INVOICE_STATUS = 'ACTION_UPDATE_INVOICE_STATUS';
    GET_MICROSOFT_CUSTOMER_CONSENT = 'GET_MICROSOFT_CUSTOMER_CONSENT';
    DOWNLOAD_INVOICE_CSV = 'BTN_DOWNLOAD_INVOICE_CSV';
    CUSTOMER_ONBOARDING = 'BTN_ONBOARD_SINGLE_CUSTOMER';
    BTN_CUSTOMER_CONFIGURATIONS = 'BTN_CUSTOMER_CONFIGURATIONS';
    BTN_CUSTOMER_PLANS = 'BTN_CUSTOMER_PLANS';
    BTN_ASSIGN_PLAN_TO_CUSTOMER = 'BTN_ASSIGN_PLAN_TO_CUSTOMER';
    BTN_LINK_CUSTOMER = 'BTN_LINK_CUSTOMER';
    BTN_CUSTOMER_REPORTS = 'BTN_CUSTOMER_REPORTS';
    BTN_LINK_BILLING_PROFILE = 'BTN_LINK_BILLING_PROFILE';
    GET_TERMS_AND_CONDITIONS_ACCEPTED_LIST = 'GET_TERMS_AND_CONDITIONS_ACCEPTED_LIST';
    GET_CUSTOMER_PRODUCT_COUNT = 'GET_PURCHASED_PRODUCT_COUNT';
    GET_USERS_COUNT_FOR_CUSTOMER = 'GET_USERS_COUNT_FOR_CUSTOMER';
    BTN_DOWNLOAD_ALL_INVOICES_AS_ZIP = 'BTN_DOWNLOAD_ALL_INVOICES_AS_ZIP';
    GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
    GET_RESELLERS = 'GET_RESELLERS';
    BTN_SAVE_RESELLER = 'BTN_SAVE_RESELLER';
    BTN_VIEW_SERVICE_PROVIDER_RESELLERS = 'BTN_VIEW_SERVICE_PROVIDER_RESELLERS';
    BTN_UNLINK_RESELLER = 'BTN_UNLINK_RESELLER';
    BTN_LINK_RESELLER = 'BTN_LINK_RESELLER';
    BTN_VIEW_RESELLER_PLANS = 'BTN_VIEW_RESELLER_PLANS';
    BTN_UPDATE_RESELLER_PLAN_STATUS = 'BTN_UPDATE_RESELLER_PLAN_STATUS';
    BTN_VIEW_RESELLER_CONFIGURATION = 'BTN_VIEW_RESELLER_CONFIGURATION';
    BTN_UPDATE_RESELLER_CONFIGURATION = 'BTN_UPDATE_RESELLER_CONFIGURATION';
    VIEW_RESELLER_COUNT_TILE = 'VIEW_RESELLER_COUNT_TILE';
    BTN_SAVE_PROVIDER_PAYMENT_DETAILS = 'BTN_SAVE_PROVIDER_PAYMENT_DETAILS';
    TAB_CUSTOMER_PAYMENT_PROFILE = 'TAB_CUSTOMER_PAYMENT_PROFILE';
    BTN_SAVE_CUTOMER_PAYMENT_PROFILE = 'BTN_SAVE_CUTOMER_PAYMENT_PROFILE';
    BTN_ADD_CUSTOMER_PAYMENT_PROFILE = 'BTN_ADD_CUSTOMER_PAYMENT_PROFILE';
    BTN_MAKE_AS_DEFAULT_PAYMENT_PROFILE = 'BTN_MAKE_AS_DEFAULT_PAYMENT_PROFILE';
    BTN_DELETE_PAYMENT_PROFILE = 'BTN_DELETE_PAYMENT_PROFILE';
    BTN_SAVE_BILLING_PROFILE_CHANGES = 'BTN_SAVE_BILLING_PROFILE_CHANGES';
    SUSPEND_CUSTOMER_PRODUCT_SUBSCRIPTION = 'SUSPEND_CUSTOMER_PRODUCT_SUBSCRIPTION';
    CANCEL_CUSTOMER_PRODUCT_SUBSCRIPTION = 'CANCEL_CUSTOMER_PRODUCT_SUBSCRIPTION';
    CUSTOMER_PRODUCT_AUTO_RENEW_STATUS = 'CUSTOMER_PRODUCT_AUTO_RENEW_STATUS';
    REACTIVATE_CUSTOMER_PRODUCT_SUBSCRIPTION = 'REACTIVATE_CUSTOMER_PRODUCT_SUBSCRIPTION';
    PRODUCT_OWNERSHIP_MANAGEMENT = 'PRODUCT_OWNERSHIP_MANAGEMENT';
    BULKONBOARDCUSTOMERS = 'BTN_BULK_ONBOARD_CUSTOMERS';
    SUBMITBULKONBOARDCUSTOMERSREQUEST = 'BTN_SUBMIT_BULK_ONBOARD_CUSTOMERS';
    BTN_SAVE_MICROSOFT_CUSTOMER_CONSENT = 'BTN_SAVE_MICROSOFT_CUSTOMER_CONSENT';
    BUTTON_UPDATE_DEFAULT_TENANT = 'BUTTON_UPDATE_DEFAULT_TENANT';
    CLONE_PLAN = 'CLONE_PLAN';
    SAVE_PLAN_WITH_ALL_OFFERS = 'SAVE_PLAN_WITH_ALL_OFFERS';
    DELETECUSTOMER = 'BUTTON_DELETE_CUSTOMER';
    PUBLIC_SIGNUP = 'PUBLIC_SIGNUP';
    VIEW_HIDDEN_AUDIT_LOGS = 'VIEW_HIDDEN_AUDIT_LOGS';
    SAVE_RESELLER_PLAN_WITH_ALL_OFFERS = 'SAVE_RESELLER_PLAN_WITH_ALL_OFFERS';
    GET_CONTACT_LOGS = 'GET_CONTACT_LOGS';
    GET_RESELLERS_HAVING_CUSTOMER_USAGE_PRODUCT = 'GET_RESELLER_HAVING_CUSTOMER_WITH_USAGE_PRODUCT';
    BTN_OWNERSHIP_MANAGEMENT_SUBMIT = 'BTN_OWNERSHIP_MANAGEMENT_SUBMIT';

    /*BEGIN = Plan CurrencyConversion*/
    PLAN_CURRENCY_ATTRIBUTES = 'PLAN_CURRENCY_ATTRIBUTES';
    ACTION_SAVE_PLAN_IN_OTHER_CURRENCY = 'ACTION_SAVE_PLAN_IN_OTHER_CURRENCY';
    /*END = Plan CurrencyConversion*/

    /*BEGIN = Reseller Plan CurrencyConversion*/
    RESELLER_PLAN_CURRENCY_ATTRIBUTES = 'RESELLER_PLAN_CURRENCY_ATTRIBUTES';
    ACTION_SAVE_RESELLER_PLAN_IN_OTHER_CURRENCY = 'ACTION_SAVE_RESELLER_PLAN_IN_OTHER_CURRENCY';
    BTN_DEFINE_RESELLER_PLAN_IN_OTHER_CURRENCY = 'BTN_DEFINE_RESELLER_PLAN_IN_OTHER_CURRENCY';
    /*END = Reseller Plan CurrencyConversion*/

    ACTION_RESELLER_RELATION_SHIP_REQUEST = 'ACTION_RESELLER_RELATION_SHIP_REQUEST';

    /*BEGIN = Bulk onbord resellers*/
    BTN_BULK_ONBOARD_RESELLERS = 'BTN_BULK_ONBOARD_RESELLERS';
    BTN_BULK_ONBOARD_RESELLERS_BY_LIST = 'BTN_BULK_ONBOARD_RESELLERS_BY_LIST';
    BTN_BULK_ONBOARD_RESELLERS_BY_UPLOAD = 'BTN_BULK_ONBOARD_RESELLERS_BY_UPLOAD';
    BTN_SUBMIT_BULKONBOARD_RESELLERS = 'BTN_SUBMIT_BULKONBOARD_RESELLERS';
    UPDATE_BULK_ONBOARD_PROVIDER_RESELLERS_STATUS = 'UPDATE_BULK_ONBOARD_PROVIDER_RESELLERS_STATUS';
    /*END = Bulk onbord resellers*/

    /*BEGIN = Partner tags*/
    GETPRODUCTTAGS = 'GET_PRODUCT_TAGS';
    ADDPRODUCTTAGS = 'SAVE_PRODUCT_TAGS';
    ADD_OR_UPDATE_TAGS= 'BTN_ADD_OR_UPDATE_TAGS';
    DELETE_TAGS ='BTN_DELETE_TAGS';
    GET_TAGS= 'GET_TAGS'
    /*END = Partner tags*/

    /*BEGIN = PSA related constants*/
    VALIDATE_SUBSCRIPTION_HISTORY_FOR_CONNECTWISE = 'ValidateSubscriptionHistoryForConnectWise';
    UPLOAD_SUBSCRIPTION_HISTORY_INTO_CONNECTWISE = 'UploadSubscriptionHistoryIntoConnectWise';
    VALIDATE_SUBSCRIPTION_HISTORY_FOR_AUTOTASK = 'ValidateSubscriptionHistoryForAutoTask';
    EXTERNAL_SERVICE_JOB_TYPE_VALIDATE = 'Validate';
    EXTERNAL_SERVICE_JOB_TYPE_UPLOAD = 'Upload';
    PSA_NAME_AUTOTASK = 'autotask';
    PSA_NAME_CONNECTWISE = 'connectwise';
    INVOICE_LINE_ITEM_ADDITIONTYPE = 'Invoice_Line_Item_Push';
    SINGLE_LINE_ADDITIONTYPE = 'Single_Line(Per_Subscription_Proration)';
    DUAL_LINE_ADDITIONTYPE = 'Dual_Line(Per_Subscription_Separate_Proration)';
    /*END = PSA related constants*/

    /*BEGIN = AccessType*/
    ACCESS_TYPE_ALLOWED = 'allowed';
    ACCESS_TYPE_DENIED = 'denied';
    ACCESS_TYPE_READONLY = 'readonly';
    /*END = AccessType*/

    /*BEGIN = SubscriptionStatus*/
    SUBSCRIPTION_STATUS_ACTIVE = 'active';
    /*END = SubscriptionStatus*/

    /*BEGIN = BillingTypes*/
    BILLING_TYPE_PRICE = 'price';
    BILLING_TYPE_MARKUP = 'markup';
    BILLING_TYPE_PERCENTAGE = 'percentage';
    BILLING_TYPE_UNIT = 'unit';
    BILLING_TYPE_METERED_BILLING = 'meteredbilling';
    /*END = BillingTypes*/

    /*BEGIN = Consumption types*/
    CONSUMPTION_QUANTITY_BASED = 'quantity';
    CONSUMPTION_USAGE_BASED = 'usage';
    CONSUMPTION_CONTRACT = 'contract';
    CONSUMPTION_RESERVATION = 'Reservation';
    /*END = Consumption types*/

    /*BEGIN = Category types*/
    CATEGORY_ONLINE_SERVICES = 'onlineservices';
    CATEGORY_ONLINE_SERVICES_NEW_COMMERCE_EXPERIENCE = 'onlineservicesnce';
    CATEGORY_CUSTOM = 'custom';
    CATEGORY_AZURE = 'azure';
    CATEGORY_BUNDLES = 'bundles';
    CATEGORY_SOFTWARE_SUBSCRIPTIONS = 'softwaresubscriptions';
    CATEGORY_AZURE_NON_CSP = 'azure';
    CATEGORY_AZURE_PLAN = 'azureplan';
    CATEGORY_PERPETUAL_SOFTWARE = 'perpetualsoftware';
    CATEGORY_LICENSE_SUPPORTED = 'licensesupported';
    CATEGORY_ONLINE_SERVICES_NCE = 'onlineservicesnce';
    CATEGORY_DISTRIBUTOR_OFFERS = 'distributoroffers';
    CATEGORY_CUSTOM_TRIAL = 'customtrial';
    RESERVED_INSTANCES = 'reservedinstances';
    /*END = Category types*/

    /*BEGIN = Subscription Status*/
    SUBSCRIPTION_STATUS_SUSPENDED = 'suspended';
    //SUBSCRIPTION_STATUS_ACTIVE = 'active';
    SUBSCRIPTION_STATUS_EXPIRED = 'expired';
    SUBSCRIPTION_STATUS_PENDING = 'pending';
    SUBSCRIPTION_STATUS_INPROVISION = 'InProvision';
    SUBSCRIPTION_STATUS_CANCELLED = 'Cancelled';
    SUBSCRIPTION_STATUS_COMPLETED = 'Completed';
    SUBSCRIPTION_STATUS_SUCCEEDED = 'Succeeded';
    /*END = Subscription Status*/

    /*BEGIN = Provider types*/
    PROVIDER_MICROSOFT = 'microsoft';
    PROVIDER_PARTNER = 'partner';
    PROVIDER_MICROSOFT_NON_CSP = 'microsoftnoncsp';
    /*END = Provider types*/

    /*BEGIN = Audit Log*/
    AUDIT_LOG = 'sidebar_Partner_AuditLog';
    // AUDIT_LOG = 'sidebar_Customer_AuditLog';
    EXPORT_EVENT_AUDIT_LOGS = 'EXPORT_EVENT_AUDIT_LOGS';
    /*End = Audit Log*/

    /*BEGIN = Bulk ADD Entitlements*/
    BULK_ADD_ENTITLEMENTS = 'BULK_ADD_ENTITLEMENTS';
    /*End =  Bulk ADD Entitlements*/

    /*BEGIN = Bulk ADD tenants*/
    BULK_ADD_TENANTS = 'BULK_ADD_TENANTS';
    /*End =  Bulk ADD tenants*/

    /*BEGIN = Contact Methods*/
    CONTACT_METHOD_EMAIL = 'email';
    CONTACT_METHOD_WEBHOOK = 'webhook';
    /*END = Contact Methods*/

    /*BEGIN = Couons*/
    TAB_COUPNS = 'TAB_COUPNS';
    TAB_COUPON_ASSIGNMENT = 'TAB_COUPON_ASSIGNMENT';
    BTN_SAVE_COUPON = 'BTN_SAVE_COUPON';
    GET_COUPONS = 'GET_COUPONS';

    BTN_SAVE_COUPON_ASSIGNMENT = 'BTN_SAVE_COUPON_ASSIGNMENT';
    GET_COUPON_ASSIGNMENT = 'GET_COUPON_ASSIGNMENT';

    SIDEBAR_PARTNER_COUPON = 'sidebar_Partner_Coupon';
    SAVE_COUPON = 'SAVE_COUPON';
    SAVE_COUPON_ASSIGNMENT = 'SAVE_COUPON_ASSIGNMENT';
    CHANGE_COUPON_STATUS = 'CHANGE_COUPON_STATUS';
    CHANGE_COUPON_ASSIGNMENT_STATUS = 'CHANGE_COUPON_ASSIGNMENT_STATUS';
    GET_COUPON_STATUS = 'GET_COUPON_STATUS';
    TAB_COUPON_STATUS = 'TAB_COUPON_STATUS';
    BTN_COUPONS_GRID_DOWNLOADABLE_REPORTS = 'BTN_COUPONS_GRID_DOWNLOADABLE_REPORTS';
    /*END = Coupons*/

    /*BEGIN = Couons*/
    TAB_ORGANIZATION_SETUP = 'TAB_ORGANIZATION_SETUP';

    TAB_MANAGE_SITES = 'TAB_MANAGE_SITES';
    TAB_MANAGE_DEPARTMENTS = 'TAB_MANAGE_DEPARTMENTS';

    GET_SITES = 'GET_SITES';
    ADD_CUSTOMER_SITE = 'ADD_CUSTOMER_SITE';
    EDIT_CUSTOMER_SITE = 'EDIT_CUSTOMER_SITE';
    DELETE_CUSTOMER_SITE = 'DELETE_CUSTOMER_SITE';
    SAVE_CUSTOMER_SITE = 'SAVE_CUSTOMER_SITE';
    ADD_SITE_ADDRESS = 'ADD_SITE_ADDRESS';
    EDIT_SITE_ADDRESS = 'EDIT_SITE_ADDRESS';
    DELETE_SITE_ADDRESS = 'DELETE_SITE_ADDRESS';
    ADD_SITE_PHONE = 'ADD_SITE_PHONE';
    EDIT_SITE_PHONE = 'EDIT_SITE_PHONE';
    DELETE_SITE_PHONE = 'DELETE_SITE_PHONE';
    ADD_SITE_EMAIL = 'ADD_SITE_EMAIL';
    EDIT_SITE_EMAIL = 'EDIT_SITE_EMAIL';
    DELETE_SITE_EMAIL = 'DELETE_SITE_EMAIL';

    GET_DEPARTMENTS = 'GET_DEPARTMENTS';
    ADD_CUSTOMER_DEPARTMENT = 'ADD_CUSTOMER_DEPARTMENT';
    EDIT_CUSTOMER_DEPARTMENT = 'EDIT_CUSTOMER_DEPARTMENT';
    DELETE_CUSTOMER_DEPARTMENT = 'DELETE_CUSTOMER_DEPARTMENT';
    SAVE_CUSTOMER_DEPARTMENT = 'SAVE_CUSTOMER_DEPARTMENT';
    ADD_DEPARTMENT_ADDRESS = 'ADD_DEPARTMENT_ADDRESS';
    EDIT_DEPARTMENT_ADDRESS = 'EDIT_DEPARTMENT_ADDRESS';
    DELETE_DEPARTMENT_ADDRESS = 'DELETE_DEPARTMENT_ADDRESS';
    ADD_DEPARTMENT_PHONE = 'ADD_DEPARTMENT_PHONE';
    EDIT_DEPARTMENT_PHONE = 'EDIT_DEPARTMENT_PHONE';
    DELETE_DEPARTMENT_PHONE = 'DELETE_DEPARTMENT_PHONE';
    ADD_DEPARTMENT_EMAIL = 'ADD_DEPARTMENT_EMAIL';
    EDIT_DEPARTMENT_EMAIL = 'EDIT_DEPARTMENT_EMAIL';
    DELETE_DEPARTMENT_EMAIL = 'DELETE_DEPARTMENT_EMAIL';
    /*END = Coupons*/

    /*BEGIN = ResellerPlan */
    GET_RESELLER_PLANS = 'API_GET_RESELLER_PLANS';
    SAVE_RESELLER_PLAN = 'BTN_SAVE_OR_UPDATE_RESELLER_PLAN';
    GET_RESELLER_PRODUCTS = 'GET_ALL_PRODUCTS';
    BTN_VIEW_RESELLER_PLAN_OFFERS = 'BTN_VIEW_RESELLER_PLAN_OFFERS';
    BTN_ADD_RESELLER_PLAN = 'BTN_ADD_RESELLER_PLAN';
    BTN_EDIT_RESELLER_PLAN = 'BTN_EDIT_RESELLER_PLAN';
    CLONERESELLERPLAN = 'CLONE_RESELLER_PLAN';
    RESELLER_USAGE_PLAN_MACRO = 'RESELLER_USAGE_PLAN_MACRO';

    /*BEGIN = Macros*/
    MACRO_COPY_PARTNERT_PRICE = 'macro_copy_partnert_price';
    MACRO_COPY_PROVIDER_SELLING_PRICE = 'macro_copy_provider_selling_price';
    MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE = 'macro_apply_x_percent_on_partner_price';
    MACRO_APPLY_X_PERCENT_ON_MARKUP = 'macro_apply_x_percent_on_markup';
    MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE = 'macro_apply_x_percent_on_provider_selling_price';
    /*END = Macros*/

    /*BEGIN = Currency*/
    BTN_SAVE_CURRENCY_CONVERSION = 'ACTION_SAVE_CURRENCY_CONVERSION_RATE';
    BTN_DELETE_CURRENCY_CONVERSION = 'ACTION_DELETE_CURRENCY_CONVERSION_RATE';
    SETTINGS_TAB_CURRENCY_CONVERSION = 'SETTINGS_TAB_CURRENCY_CONVERSION';
    /*END = Currency*/

    /*BEGIN = Entity*/
    ENTITY_SITE = 'site';
    ENTITY_SITEDEPARTMENT = 'sitedepartment';
    ENTITY_PARTNER = 'partner';
    ENTITY_RESELLER = 'reseller';
    ENTITY_CUSTOMER = 'customer';
    ENTITY_CUSTOMER_PRODUCT = 'customerproduct';
    ENTITY_INVOICE = 'invoice';
    ENTITY_CUSTOMOFFER = 'customoffer';
    ENTITY_PLAN = 'plan';
    /*END = Entity*/

    /*BEGIN = Static values*/
    STATIC_VALUE_TRUE = 'true';
    STATIC_VALUE_FALSE = 'false';
    /*END = Static values*/

    /*BEGIN = Contact method*/
    //CONTACT_METHOD_EMAIL = 'email';
    //CONTACT_METHOD_WEBHOOK = 'webhook';
    /*END = Contact method*/

    /*BEGIN = Test Charge*/
    TEST_PAYMENT = 'TEST_PAYMENT';

    /*END = Test Charge*/

    /*BEGIN = Download Invoice*/
    DOWNLOAD_INVOICE_AND_PAYMENT_DETAILS = 'DOWNLOAD_INVOICE_AND_PAYMENT_DETAILS';
    /*END = Download Invoice*/

    /*BEGIN = Download Plans*/
    BTN_PLAN_OFFERS_GRID_DOWNLOADABLE_REPORTS = 'BTN_PLAN_OFFERS_GRID_DOWNLOADABLE_REPORTS';
    BTN_PLANS_GRID_DOWNLOADABLE_REPORTS = 'BTN_PLANS_GRID_DOWNLOADABLE_REPORTS';
    /*END = Download plans*/

    /*BEGIN = Post Invoices To ExternalService */
    POSTINVOICESTOXERO = 'BTN_POST_INVOICES_TO_XERO';
    VIEWEXTERNALSERVICEPOSTLOGS = 'BTN_VIEW_EXTERNAL_SERVICE_POST_LOGS';
    IGNOREPOSTTOEXTERNALSERVICEERRORS = 'BTN_IGNORE_ERRORS';
    POSTINVOICESTOQUICKBOOKSONLINE = 'BTN_POST_INVOICES_TO_QUICKBOOKS';
    DOWNLOADPOSTINVOICESTOEXTERNALSERVICESSUMMARY = 'BTN_DOWNLOAD_POST_INVOICES_TO_EXTERNAL_SERVICES_SUMMARY';
    /*END*/

    /*BEGIN = Account Managers*/
    SAVEORUPDATEACCOUNTMANAGER = 'SAVE_OR_UPDATE_ACCOUNT_MANAGER';
    GETACCOUNTMANAGERSDETAILS = 'GET_ACCOUNT_MANAGERS_DETAILS';
    ASSIGNCUSTOMERSTOACCOUNTMANAGERS = 'ASSIGN_CUSTOMERS_TO_ACCOUNT_MANAGERS';
    UNASSIGNACCOUNTMANAGERCUSTOMERS = 'UNASSIGN_ACCOUNT_MANAGER_CUSTOMERS';
    GETACCOUNTMANAGERDETAILSOFCUSTOMER = 'GET_ACCOUNT_MANAGER_DETAILS_OF_CUSTOMER';
    /*END*/

    /*BEGIN = profile*/
    TABCONFIGURATIONSMANAGEDBYCUSTOMER = 'TAB_CONFIGURATIONS_MANAGED_BY_CUSTOMER';
    TABCONFIGURATIONSMANAGEDBYRESELLER = 'TAB_CONFIGURATIONS_MANAGED_BY_RESELLER';
    UPDATECONFIGURATIONSMANAGEDBYCUSTOMER = 'UPDATE_CONFIGURATIONS_MANAGED_BY_CUSTOMER';
    UPDATECONFIGURATIONSMANAGEDBYRESELLER = 'UPDATE_CONFIGURATIONS_MANAGED_BY_RESELLER';
    /*END*/

    /*BEGIN = Product Extensions*/
    DOWNLOADPRODUCTCATALOGUEFORCODES = 'DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES';
    STAGEANDVALIDATEPRODUCTCATALOGUE = 'STAGE_AND_VALIDATE_PRODUCT_CATALOGUE';
    /*END*/

    /*BEGIN = Seat Limits*/
    GETSEATLIMIT = 'GET_SEAT_LIMIT';
    UPDATESEATLIMIT = 'UPDATE_SEAT_LIMIT';
    /*END*/

    /*BEGIN = Purchase Requests*/
    GETPENDINGPURCHASEREQUESTS = 'GET_PENDING_PURCHASE_REQUESTS';
    UPDATEPENDINGPURCHASEREQUESTS = 'UPDATE_PENDING_PURCHASE_REQUESTS';
    /*END*/

    /*BEGIN = Report Usage*/
    DOWNLOADPRODUCTSCATALOGUE = 'DOWNLOAD_PRODUCTS_CATALOGUE';
    DOWNLOADPRODUCTSTEMPLATE = 'DOWNLOAD_PRODUCTS_TEMPLATE';
    STAGEANDVALIDATEPARTNEROFFERDAILYUSAGEDATA = 'STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA';
    /*END*/

    /*BEGIN = Reports*/
    VIEW_LICENSE_CONSUMPTION_REPORT = 'VIEW_LICENSE_CONSUMPTION_REPORT';
    EXPORT_LICENSE_CONSUMPTION_REPORT = 'EXPORT_LICENSE_CONSUMPTION_REPORT';
    EXPORT_BILLING_FORECAST_REPORT = 'BTN_DOWNLOAD_BILLING_FORECAST_REPORT';
    EXPORT_INVOICE_LINE_ITEMS_WITH_TAX_REPORT = 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_WITH_TAX_REPORT';
    EXPORT_CUSTOMER_CONSENT_ACCEPTANCE_REPORT = 'BTN_DOWNLOAD_CUSTOMER_CONSENT_ACCEPTANCE_REPORT';
    EXPORT_CUSTOMER_TEARMS_AND_CONDITIONS_ACCEPTANCE_REPORT = 'BTN_DOWNLOAD_CUSTOMER_TEARMS_AND_CONDITIONS_ACCEPTANCE_REPORT';
    EXPORT_AZURE_BILLING_COMPARISON_REPORT = 'BTN_DOWNLOAD_AZURE_BILLING_COMPARISON_REPORT';
    VIEW_LICENSE_CHANGE = 'VIEW_LICENSE_CHANGE';
    VIEW_PURCHASED_PRODUCTS_REPORT = 'VIEW_PURCHASED_PRODUCTS_REPORT';

    /*END = Reports*/

    /*BEGIN = Settings*/

    /*BEGIN = Event emailnotification*/
    VIEW_EVENT_EMAIL_NOTIFICATION = 'VIEW_EVENT_EMAIL_NOTIFICATION';
    ADD_AND_EDIT_EVENT_EMAIL_NOTIFICATION = 'ADD_AND_EDIT_EVENT_EMAIL_NOTIFICATION';
    DELETE_EVENT_EMAIL_NOTIFICATION = 'DELETE_EVENT_EMAIL_NOTIFICATION';
    /*END = Event email notification*/

    /*BEGIN = General Settings*/
    GET_GENERAL_SETTINGS = 'GET_GENERAL_SETTINGS';
    SAVE_GENERAL_SETTINGS = 'SAVE_GENERAL_SETTINGS';
    /*END = General Settings*/

    /*BEGIN = Provider Settings*/
    GET_PROVIDER_SETTINGS = 'GET_PROVIDER_SETTINGS';
    SAVE_PROVIDER_SETTINGS = 'SAVE_PROVIDER_SETTINGS';
    /*END = Provider Settings*/

    /*BEGIN = Logo Settings*/
    GET_LOGO_SETTINGS = 'GET_LOGO_SETTINGS';
    SAVE_LOGO_SETTINGS = 'SAVE_LOGO_SETTINGS';
    /*END = Logo Settings*/

    /*BEGIN = SMTP Settings*/
    GET_SMTP_SETTINGS = 'GET_SMTP_SETTINGS';
    SAVE_SMTP_SETTINGS = 'SAVE_SMTP_SETTINGS';
    /*END = SMTP Settings*/

    /*BEGIN = Billing Settings*/
    GET_BILLING_SETTINGS = 'GET_BILLING_SETTINGS';
    /*END = Billing Settings*/

    /*BEGIN = Change Plan Settings*/
    GET_CUSTOMER_SOURCE_PLANS = 'GET_CUSTOMER_SOURCE_PLANS';
    GET_TARGET_CHANGE_PLANS = 'GET_TARGET_CHANGE_PLANS';
    GET_MATCHING_PLAN_PRODUCTS_FOR_PLAN_CHANGE = 'GET_MATCHING_PLAN_PRODUCTS_FOR_PLAN_CHANGE';
    CHANGE_PURCHASED_PRODUCTS_PLAN = 'CHANGE_PURCHASED_PRODUCTS_PLAN';
    VALIDATE_PLAN_PRODUCTS_FOR_PLAN_CHANGE = 'VALIDATE_PLAN_PRODUCTS_FOR_PLAN_CHANGE';
    /*END = Change Plan Settings*/

    /*BEGIN = Product catalogue*/
    //GET_PRODUCT_CATALOGUE = 'GET_PRODUCT_CATALOGUE';
    /*END = Product catalogue*/

    /*BEGIN = Invoice On Demand*/
    CREATE_INVOICE = 'BTN_CREATE_INVOICE';
    EDIT_INVOICE = 'BTN_EDIT_INVOICE';
    /*END = Invoice On Demand*/

    /*BEGIN = Product Commissions*/
    DOWNLOADPRODUCTCOMMISSIONSCATALOGUE = 'DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE';
    IMPORTPRODUCTCOMMISSIONS = 'IMPORT_PRODUCT_COMMISSIONS';
    /*END = Product Commissions*/

    /*BEGIN = Connectwise Maping*/
    UPDATE_PURCHSED_PRODUCT_MAPPING = 'UPDATE_PURCHSED_PRODUCT_MAPPING';
    /*END = Connectwise Maping*/

    /*BEGIN = webNotification*/
    side_bar_banner_manage = 'side_bar_banner_manage';
    /*END = webNotification*/

    /*BEGIN = engageNotification*/
    side_bar_administration_engage = 'side_bar_administration_engage';
    /*END = engageNotification*/

    /*BEGIN = customNotification*/
    side_bar_administration_custom_notifications = 'side_bar_administration_custom_notifications';
    VIEW_UI_NOTIFICATION = 'VIEW_UI_NOTIFICATION';
    SAVE_UI_NOTIFICATION = 'SAVE_UI_NOTIFICATION';
    DELETE_UI_NOTIFICATION = 'DELETE_UI_NOTIFICATION';
    /*END = customNotification*/

    /*BEGIN  = WebhookNotification*/
    MENUADMINISTRATIONWEBHOOKNOTIFICATIONS = 'MENU_ADMINISTRATION_WEBHOOK_NOTIFICATIONS';
    GETWEBHOOKNOTIFICATIONDETAILS = 'GET_WEBHOOK_NOTIFICATION_DETAILS';
    SAVEORUPDATEWEBHOOKNOTIFICATIONDETAILS = 'SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_DETAILS';
    DELETEWEBHOOKNOTIFICATIONDETAILS = 'DELETE_WEBHOOK_NOTIFICATION_DETAILS';
    /*END  = WebhookNotification*/

    /*BEGIN = c3support*/
    SIDEBAR_C3_SUPPORT = 'SIDEBAR_C3_SUPPORT';
    /*END = c3support*/

    /*BEGIN = nce promotions*/
    ARE_NCE_PROMOTIONS_ENABLED = 'ARE_NCE_PROMOTIONS_ENABLED';
    BTN_LOAD_PROMOTIONS = 'BTN_LOAD_PROMOTIONS';
    FILTER_SHOW_PROMOTION_OFFER = 'FILTER_SHOW_PROMOTION_OFFER';
    /*END = nce promotions*/

    GET_PENDING_PAYMENT_STATUS = 'GET_PENDING_PAYMENT_STATUS';

    /*END = Settings*/

    /*BEGIN = DOCUMENTATION*/
    MENU_PARTNER_DOCUMENTATION = 'MENU_PARTNER_DOCUMENTATION';
    /*END = DOCUMENTATION*/

    /*BEGIN = SKU UPGRADE*/
    BTN_UPGRADE_PRODUCT = 'BTN_UPGRADE_PRODUCT';
    GET_UPGRADE_PLAN_PRODUCTS = 'GET_UPGRADE_PLAN_PRODUCTS';
    GET_UPGRADE_TRANSITION = 'GET_UPGRADE_TRANSITION';
    GET_UPGRADE_STATUS = 'GET_UPGRADE_STATUS';
    /*END = SKU UPGRADE*/

    /*BEGIN = EMAIL TEMPLATE*/
    VIEW_EVENT_EMAIL_TEMPLATES = 'VIEW_EVENT_EMAIL_TEMPLATES';
    SAVE_EVENT_EMAIL_TEMPLATES = 'SAVE_EVENT_EMAIL_TEMPLATES';
    /*END = EMAIL TEMPLATE*/

    /*subscription expiry, renewal settings*/
    EXPIRY_RENEWAL_SETTINGS_ELEMENT = 'EXPIRY_RENEWAL_SETTINGS_ELEMENT';


    /*BEGIN = SCHEDULING CHANGES*/
    SHOW_NEXT_SCHEDULE_RENEWAL_MENU = 'SHOW_NEXT_SCHEDULE_RENEWAL_MENU';
    BTN_CREATE_SCHEDULE_RENEWAL_CHANGES = 'BTN_CREATE_SCHEDULE_RENEWAL_CHANGES';
    BTN_UPDATE_SCHEDULE_RENEWAL_CHANGES = 'BTN_UPDATE_SCHEDULE_RENEWAL_CHANGES';
    BTN_CANCEL_SCHEDULE_RENEWAL_CHANGES = 'BTN_CANCEL_SCHEDULE_RENEWAL_CHANGES';


    /*BEGIN = Distributed offers*/
    UPDATE_DISTRIBUTOR_OFFERS = 'UPDATE_DISTRIBUTOR_OFFERS';
    //ADD_DISTRIBUTOR_OFFERS = 'ADD_DISTRIBUTOR_OFFERS';
    /*END = Distributed offers*/

    /*BEGIN  = USER CONFIGURATION*/
    USER_CONFIGURATION_GRID_LIST_VIEW = 'gridorlistview';
    /*END = SCHEDULING CHANGES*/

    /* BEGIN  = Customer consent on subscription renewal*/
    CUSTOMER_DASH_BOARD_VIEW_RENEWAL_SUBSCRIPTIONS = 'CUSTOMER_DASH_BOARD_VIEW_RENEWAL_SUBSCRIPTIONS';
    BTN_ACCEPT_REJECT_CUSTOMER_CONSENT = 'BTN_ACCEPT_REJECT_CUSTOMER_CONSENT';
    /* END  = Customer consent on subscription renewal*/

    /*BEGIN = CUSTOMER TRANSACTION AMOUNT LIMIT REPORT*/
    EXPORT_TRANSACTION_AMOUNT_LIMIT_REPORT = 'EXPORT_TRANSACTION_AMOUNT_LIMIT_REPORT';
    /*END =  CUSTOMER TRANSACTION AMOUNT LIMIT REPORT*/


    /*BEGIN = SHOW TAX IN ORDER SUMMARY*/
    VIEW_SHOW_TAX_IN_CART = 'VIEW_SHOW_TAX_IN_CART';
    /*END =  SHOW TAX IN ORDER SUMMARY*/

    /*BEGIN = EXPORTABLE SKUs  REPORT FOR CUSTOMER*/
    CUSTOMER_EXPORTABLE_SKU_REPORT = 'CUSTOMER_EXPORTABLE_SKU_REPORT';
    /*END =  EXPORTABLE SKUs REPORT FOR  CUSTOMER*/

    /*BEGIN = EXPORTABLE SKUs  REPORT FOR PARTNER*/
    PARTNER_EXPORTABLE_SKU_REPORT = 'PARTNER_EXPORTABLE_SKU_REPORT';
    /*END =  EXPORTABLE SKUs REPORT FOR  PARTNER*/

    /*BEGIN = SHOW SPECIAL QUALIFICATIONS*/
    SHOW_SPECIAL_QUALIFICATIONS = 'SHOW_SPECIAL_QUALIFICATIONS';
    /*END = SHOW SPECIAL QUALIFICATIONS*/

    SIDEBAR_PARTNER_MICROSOFT_AZURE_ADVISOR = 'sidebar_Partner_Microsoft_Azure_Advisor';
    TEXT_BOX_PO_NUMBER_IN_HISTORY = 'TEXT_BOX_PO_NUMBER_IN_HISTORY';

    /*BEGIN = TAX SETTING*/
    BTN_ADD_OR_UPDATE_TAXES = 'BTN_ADD_OR_UPDATE_TAXES';
    BTN_DELETE_TAXES = 'BTN_DELETE_TAXES'
    /*END = TAX SETTING*/

    DOWNLOAD_ONBOARDED_CUSTOMERS_REPORT='DOWNLOAD_ONBOARDED_CUSTOMERS_REPORT';
    BTN_RECORD_ADVANCE_PAYMENT = 'BTN_RECORD_ADVANCE_PAYMENT';
    BTN_VIEW_RENEWAL_SUBSCRIPTIONS = 'BTN_VIEW_RENEWAL_SUBSCRIPTIONS';

    PLANS_MANIPULATE_SALE_PRICE_LOCK = 'MANIPULATE_SALE_PRICE_LOCK';

    /*BEGIN = MICROSOFT AZURE ENTITLEMENT LEVEL PRICING*/
    EXPORT_MICROSOFT_AZURE_ENTITLEMENT_LEVEL_PRICING = 'EXPORT_MICROSOFT_AZURE_ENTITLEMENT_LEVEL_PRICING';
    MANAGE_MICROSOFT_AZURE_ENTITLEMENT_LEVEL_PRICING = 'MANAGE_MICROSOFT_AZURE_ENTITLEMENT_LEVEL_PRICING'
    /*END = MICROSOFT AZURE ENTITLEMENT LEVEL PRICING*/

    getConstantValue(constantName: string): string {
        return (this as any)[constantName];
    }
}
