@if(isLoading){
  <app-loader></app-loader>
}
<ng-container *ngIf="appContentContiner">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container content-layout"
    [ngClass]="appContentContainerClass + ' ' + contentContainerCSSClass"
    [ngClass]="{'container-xxl': appContentContiner === 'fixed', 'container-fluid': appContentContiner === 'fluid'}">
    <router-outlet></router-outlet>
   
  </div>
  <!--end::Content container-->
</ng-container>
<ng-container *ngIf="!appContentContiner">
  <router-outlet></router-outlet>
</ng-container>