import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-common-no-record',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './common-no-record.component.html',
  styleUrl: './common-no-record.component.scss'
})
export class CommonNoRecordComponent {

}
