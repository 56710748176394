// USA
export const locale = {
  lang: 'ch',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  },
  TRANSLATE:{
    academic: "Academic",
    Academic: "Academic",
    ACCEPT_COOKIES_BUTTON_TEXT: "Ok",
    ACCEPT_THE_CONSENT_BTN_TEXT: "Access to partner center using the new security settings.",
    ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT: "Access to Microsoft Azure management APIs.",
    ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT: "Access to Microsoft pricing API.",
    "ACCEPTANCE_STATUS": "Acceptance status",
    "ACCESS_TOKEN": "Access Token",
    "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE": "Account Manager is created Successfully",
    "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE": "Account Manager deleted successfully",
    "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE": "Are you sure, do you want to delete the account manager?",
    "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT": "The Account Manager is assigned to customer/reseller,  Are you sure, do you want to delete the Account Manager.",
    "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID": "Search by Account Manager Id",
    "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS": "Search by Email Address",
    "ACCOUNT_MANAGER_FILTER_FIRST_NAME": "Search by First Name",
    "ACCOUNT_MANAGER_FILTER_LAST_NAME": "Search by Last Name",
    "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER": "Search by Phone number",
    "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID": "Account Manager Id",
    "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION": "Close Filters",
    "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS": "Email Address",
    "ACCOUNT_MANAGER_SEARCH_FIRST_NAME": "First Name",
    "ACCOUNT_MANAGER_SEARCH_LAST_NAME": "Last Name",
    "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER": "Phone number",
    "ACCOUNT_MANAGER_SEARCH_RESET_OPTION": "Reset",
    "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION": "Search",
    "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE": "Account Manager details are updated successfully",
    "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD": "Add Account Manager",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT": "Edit Account Manager",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID": "Account manager id",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL": "Email",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME": "First name",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME": "Last name",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER": "Phone number",
    "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER": "Add account manager",
    "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT": "Account Managers",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID": "ID",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS": "Actions",
    "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE": "Created date",
    "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS": "Email address",
    "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME": "First name",
    "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME": "Last name",
    "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER": "Phone number",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE": "Delete account manager",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS": "Edit details",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED": "View assigned customers",
    "ACH_FORM_LABEL_ACCOUNT_NUMBER": "Account number",
    "ACH_FORM_LABEL_ACCOUNT_TYPE": "Account type",
    "ACH_FORM_LABEL_BANK_NAME": "Bank name",
    "ACH_FORM_LABEL_BSB": "BSB",
    "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT": "Is Business Account",
    "ACH_FORM_LABEL_NAME_ON_ACCOUNT": "Name on account",
    "ACH_FORM_LABEL_NO": "No",
    "ACH_FORM_LABEL_ROUTING_NUMBER": "Routing number",
    "ACH_FORM_LABEL_YES": " Yes",
    "ACTIONS_TO_FIX": "Please contact administrator for support",
    "ACTIONS_TO_FIX_500": "We are working on fixing the issue, please check back in a while",
    "ACTIVATE_CONFORMATION": "Are you sure do you want to activate",
    "ACTIVE_CUSTOMER_PLACEHOLDER": "Select Customer",
    "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT": "--All Actions--",
    "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM": "Showing logs from",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS": "All customers",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS": "All domains.",
    "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS": "Notifications",
    "ACTIVITY_LOGS_BUTTON_TEXT_RETRY": "Retry",
    "ACTIVITY_LOGS_EFFECTIVE_TO": "End date",
    "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS": "Actions",
    "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD": "Contact method",
    "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME": "Event name",
    "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE": "Sent date",
    "ACTIVITY_LOGS_HEADER_TITLE_STATUS": "Status",
    "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT": "Subject",
    "ACTIVITY_LOGS_HEADER_TITLE_TO": "To",
    "ACTIVITY_LOGS_TEXT_ACTION_NAME": "Event",
    "ACTIVITY_LOGS_TEXT_BCC": "Bcc",
    "ACTIVITY_LOGS_TEXT_CC": "CC",
    "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM": "Start date",
    "ACTIVITY_LOGS_TEXT_FAILED": "Failed",
    "ACTIVITY_LOGS_TEXT_FROM": "From",
    "ACTIVITY_LOGS_TEXT_ON": "On",
    "ACTIVITY_LOGS_TEXT_STATUS": "Status",
    "ACTIVITY_LOGS_TEXT_SUCCESS": "Success",
    "ACTIVITY_LOGS_TEXT_TO": "To",
    "ACTIVITY_LOGS_TEXT_VIEW_EMAIL": "View Email",
    "ACTIVITY_LOGS_TITLE_CONTACT_LOGS": "Notifications",
    "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT": "Add adjustment",
    "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE": "Domain Name is not available",
    "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE": "Domain name is not available",
    "ADD_CUSTOM_ROLE": "add custom role",
    "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT": "The customer has accepted the latest",
    "ADD_CUSTOMER_LABEL_TEXT_COUNTRY": "Country",
    "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE": "Please enter a zip code.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1": "Please enter the address 1.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2": " Enter the address 2.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY": "Please enter the city.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME": "Please enter the company name.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS": "Please enter the email address.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME": "Please enter the first name.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME": "Please enter the last name.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER": "Please enter the phone number.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY": "Please select a country.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE": "Please select a currency code.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN": "Please select a plan.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_STATE": "Please select a state.",
    "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT": "Add customers",
    "ADD_DUE_DATE": " Add due date",
    "ADD_ENTITLEMENT": "Add Entitlement",
    "ADD_ENTITLEMENT_DESCRIPTION": "Please provide a friendly name for the subscription.",
    "ADD_ENTITLEMENT_FAILED": "Add entitlement failed",
    "ADD_ENTITLEMENT_SUCCESS": "Entitlement is added successfully in Parter Ceneter please wait 15 min to provisioning in C3",
    "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT": "Create a new tier",
    "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1": "{{(!maxValue) ? \"Please enter value greater than \" + minValue :  \"Please enter value between \" + minValue + \" and \" + maxValue}}",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL": "Cancel",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT": "Submit",
    "ADD_MISSING_OFFERS_COMPLETE_BUTTON": "Complete",
    "ADD_MISSING_OFFERS_IN_PROGRESS": "Update plan with latest offers is already in progress",
    "ADD_MISSING_OFFERS_INITIAT_FAIL": "Update plan with latest offers initiation failed",
    "ADD_MISSING_OFFERS_INITIAT_SUCCESS": "Update plan with latest offers has initiated",
    "ADD_MISSING_OFFERS_STATUS": "Plan update progress",
    "ADD_MISSING_OFFERS_TO_EXISTING_PLAN": "Add missing offers to existing plan",
    "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN": "Add missing offers to multiple plan",
    "ADD_MISSING_OFFERS_TO_SINGLE_PLAN": "Add missing offers to single plan",
    "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT": "Grant access to the primary contact (as Customer Administrator)?",
    "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION": "Send invitation to access the application?",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME": "Middle Name",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER": "Organization Registration Number",
    "ADD_PLAN_DROPDOWN_TEXT_SELECT": "Select",
    "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS": "Add plan basic details",
    "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE": "Currency code",
    "ADD_PLAN_LABEL_TEXT_DESCRIPTION": "Description",
    "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY": "Invoice currency",
    "ADD_PLAN_LABEL_TEXT_NAME": "Name",
    "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY": "Purchase currency",
    "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS": "View contract details",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS": "Actions",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE": "Billing type",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE": "Macro",
    "ADD_PO_NUMBER": "Add PO #",
    "ADD_PONUMBER": "Add PO Number",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT": "It is recommended that you use the <b>Link Provider</b> option to provide the MPN ID of the reseller and help Reseller onboard customers eventually.",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE": "Recommended! Set MPN Id after adding the reseller",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK": "Ok",
    "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER": "Slab breaker",
    "ADD_TO_CART_INFO": "This product is linked with <b>{{linkedProductName}}</b> if you add this product the link will also add ",
    "ADDONS_LEBEL_TEXT": "Addons",
    "ADDRESS_TABLE_HEADER_ACTIONS": "Actions",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1": "Address line 1",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2": "Address line 2",
    "ADDRESS_TABLE_HEADER_ADDRESS_TYPE": "Address type",
    "ADDRESS_TABLE_HEADER_CITY": "City",
    "ADDRESS_TABLE_HEADER_COUNTRY": "Country",
    "ADDRESS_TABLE_HEADER_STATE": "State",
    "ADDRESS_TABLE_HEADER_ZIP": "Zip",
    "ADDRESS_TYPE_BILLING": "Billing",
    "ADDRESS_TYPE_COMPANY": "Company",
    "ADDRESS_TYPE_INVOICE": "Invoice",
    "ADDRESS_TYPE_SHIPPING": "Shipping",
    "AGO": "Ago",
    "ALERT_MESSAGE_COPIED_TO_CLIPBOARD": "Link copied to clipboard",
    "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY": "Invoice is approved successfully.",
    "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY": "Invoice is finalized successfully.",
    "ALERT_MESSAGE_MISSING_MPNID": "An issue had occured while copying the URl,Please connect to the partner.",
    "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE": "Please select at-least one invoice",
    "ALL_CART_ITEM_COMMENT": "Cart Item",
    "ALL_COMMENTS": "Comments",
    "ALL_PRODUCT_COMMENTS": "Product",
    "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE": "Please contact your administrator to removal of licenses.",
    "AN_ERROR_OCCURED": "An error had occurred while processing your request",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY": "Azure Billing History",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY": "Azure Billing Summary",
    "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE": "Customer Azure Usage",
    "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS": "Revenue by Customers",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS": "Subscriptions by Customers",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS": "Subscriptions by Plans",
    "AND": "and",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL": "Azure AD pricing application consent url",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID": "Azure AD pricing application id",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL": "Azure AD pricing application resource url",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET": "Azure AD pricing application secret key",
    "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE": "Please enter welcome page button's hex color",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL": "Azure AD pricing application consent url",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID": "Azure AD pricing application id",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL": "Azure AD pricing application resource url",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET": "Azure AD pricing application secret key",
    "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE": "Welcome page button color",
    "APP_CONFIG_PUBLIC_SIGNNUP_BANNER": "Public signup banner",
    "APP_CONFIG_PUBLIC_SIGNNUP_LOGO": "Public signup logo",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD": "Allow usage upload",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION": "When enabled, this configuration will push the monthly usage costs incurred for products that have usage consumption. Product types will include Azure-Plan and Custom-Usage.",
    "APPCONFIG_AUTOTASK_BILLING_CODE": "Billing code",
    "APPCONFIG_AUTOTASK_BILLING_CODE_DESC": "This billing code will be used to create products in Autotask",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE": "Is Automatic Mapping (Autotask)",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC": "If you wish to manually map between C3 and Autotask entities keep this un-checked. However, if auto-mapping is enabled entites are matched by comparing names of entites in C3 against entities in Autotask",
    "APPCONFIG_AUTOTASK_PASSWORD": "Password",
    "APPCONFIG_AUTOTASK_PASSWORD_DESC": "Password that belongs to the API user",
    "APPCONFIG_AUTOTASK_PRORATION_TYPE": "Proration",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY": "Auto upload frequency",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC": "Set the frequency of auto-upload",
    "APPCONFIG_AUTOTASK_USERNAME": "Username",
    "APPCONFIG_AUTOTASK_USERNAME_DESC": "Username of API user created in Autotask",
    "APPCONFIG_DESC_ACCOUNT_NAME": "Appears on the invoice pdf",
    "APPCONFIG_DESC_ACCOUNT_NUMBER": "Appears on the invoice pdf",
    "APPCONFIG_DESC_ADDRESS_LINE1": "Address 1 that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_ADDRESS_LINE2": "Address 2 that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_API_URL": "Base api Url for ConnectWise Manage",
    "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING": "Enable License Tracking",
    "APPCONFIG_DESC_APP_SHOW_ANALYTICS": "Show Analytics",
    "APPCONFIG_DESC_APPLICATION_NAME": "Application name",
    "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE": "End of billing cycle for the partner in Partner Center",
    "APPCONFIG_DESC_AZURE_CSPOFFERID": "Azure CSP Offer ID",
    "APPCONFIG_DESC_BANK_NAME": "Appears on the invoice pdf",
    "APPCONFIG_DESC_BILLING_CYCLE": "Aligned with Partner Center or Custom",
    "APPCONFIG_DESC_BILLING_DATE": "Applicable when Billing cycle is Custom",
    "APPCONFIG_DESC_BILLING_PERIOD": "Billing Period (determined using other settings)",
    "APPCONFIG_DESC_BILLING_PROVIDER": "Billing Provider for the C3 Portal",
    "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION": "Billing Provider Configuration",
    "APPCONFIG_DESC_BILLING_STARTDAY": "Start of the billing cycle. Applicable for Billing cycle is Custom",
    "APPCONFIG_DESC_BUSINESS_NUMBER": "Appears on the invoice view and invoice pdf in bill from section",
    "APPCONFIG_DESC_BUSINESS_NUMBER_KEY": "Appears on the invoice view and invoice pdf in bill from section",
    "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365": "Check Roles Against 0365",
    "APPCONFIG_DESC_CIN_NUMBER": "CIN(Corporate Identification Number)That appears on the invoice pdf",
    "APPCONFIG_DESC_CITY": "City that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_CLIENT_ID": "ClientId for ConnectWise Manage",
    "APPCONFIG_DESC_COMPANY_NAME": "Company Name that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_COMPANY_URL": "Your website URL",
    "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME": "Company Name for ConnectWise Manage",
    "APPCONFIG_DESC_CONTACT_COMPANY_NAME": "Name that should appear on the landing page as well as footer after a user is logged in",
    "APPCONFIG_DESC_CONTACT_EMAIL": "Contact Email (typically your info or support email)",
    "APPCONFIG_DESC_CONTACT_FIRST_NAME": "First name of the contact person for your C3 application",
    "APPCONFIG_DESC_CONTACT_LAST_NAME": "Last name of the contact person for your C3 application",
    "APPCONFIG_DESC_CONTACT_PHONE_NUMBER": "Contact person phone number",
    "APPCONFIG_DESC_COUNTRY": "Country that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_COUNTRY_CODE": "Country Code",
    "APPCONFIG_DESC_CURRENCY_CODE": "Currency code",
    "APPCONFIG_DESC_CUSTOM_BILLING": "Apply custom billing",
    "APPCONFIG_DESC_CUSTOMER_CONSENT_URL": "Customer consent URL",
    "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS": "Restricting customer while  onboarding under multiple entities",
    "APPCONFIG_DESC_DATE_FORMAT": "Date Format",
    "APPCONFIG_DESC_DATE_TIME_FORMAT": "Date Time Format",
    "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL": "Target URL for the page, if you want to have a customized landing page.       Note: In the landing page, you should have a button and the target URL of that button should be <C3Site URL>/#/login",
    "APPCONFIG_DESC_DEFAULT_LANGUAGE": "Default language for the website",
    "APPCONFIG_DESC_DEFAULT_PAGE_COUNT": "Sets the default page count for every table in the system.",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION": "Terms & conditions to order products",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT": "Terms & conditions URL (text)",
    "APPCONFIG_DESC_EMAIL_LOGO": "Logo that appears on emails sent out by C3.Preferred dimension is 300x100.",
    "APPCONFIG_DESC_ENABLE_TOUR": "Enable 'Take a Tour' button in the site header.",
    "APPCONFIG_DESC_FAVICON_LOGO": "A favicon is a little icon visible on the web browser tab, just before the page title. Please upload an .ico file of 16x16",
    "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID": "Should consider MPN ID of resellers for customers and purchases?",
    "APPCONFIG_DESC_IFSC_CODE": "Appears on the invoice pdf",
    "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE": "Appears on the invoice view and invoice pdf as footer authorized line",
    "APPCONFIG_DESC_INVOICE_FOOTER_LINE": "Appears on the invoice view and invoice pdf as footer line",
    "APPCONFIG_DESC_INVOICE_GENARATION_DAYS": "Invoice Generation Days",
    "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING": "Cloud storage connection string",
    "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS": "Invoice zip valid days",
    "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS": "When this feature is turned on, the partner will get the reseller-approved requests for their approval.",
    "APPCONFIG_DESC_LINK_TO_CONTACT": "Link to Contact Us page (just type /#/contact if you do not have one)",
    "APPCONFIG_DESC_LINK_TO_FAQ": "Link To Customer FAQ (just leave it empty, if you want to use the default one)",
    "APPCONFIG_DESC_LINK_TO_PORTAL": "Link to Portal",
    "APPCONFIG_DESC_LINK_TO_SUPPORT": "Link to Support page (just type home.support if you do not have one)",
    "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO": "Link to Welcome Logo",
    "APPCONFIG_DESC_LOGGEDIN_LOGO": "Logo that appears on the top left after users are logged in to C3.Preferred dimension is 170x40.The page has black background so the graphic should in a color that is in contrast with Black.",
    "APPCONFIG_DESC_LOGIN_TO_PORTAL": "Login to Portal",
    "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS": "Mandatory CustomerId In Api Calls",
    "APPCONFIG_DESC_MAX_AUDITLOG_INDEX": "Maximum number of entries for export to excel",
    "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT": "Maximum Entitlement Creation Count",
    "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT": "Maximum Tenants Creation Count",
    "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS": "Microsoft security consent remind in days",
    "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT": "Minimum Charge Amount for Test Payments",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION": "NCE terms & conditions to order Microsoft NCE products",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT": "NCE Terms & conditions URL (text)",
    "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY": "Enables the Functionality of searching already present customer in Billing",
    "APPCONFIG_DESC_PAN_NUMBER": "Appears on the invoice pdf",
    "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT": "Appears on the invoice pdf",
    "APPCONFIG_DESC_PARTNER_APPLICATIONID": "Application Id from a native application in your partner center. You can get this via App Management -> Native Application",
    "APPCONFIG_DESC_PARTNER_CURRENCY": "Partner Currency",
    "APPCONFIG_DESC_PARTNER_PASSWORD": "Admin Agent Password",
    "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES": "Partner Preference Languages",
    "APPCONFIG_DESC_PARTNER_TENANT_ID": "Partner Tenant ID",
    "APPCONFIG_DESC_PARTNER_USERNAME": "Username of the account that has the Agent Admin role assigned",
    "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT": "Microsoft cloud reseller agreement URL",
    "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT": "Partner terms & conditions URL",
    "APPCONFIG_DESC_PBI_GROUP_ID": "Power BI Group Id",
    "APPCONFIG_DESC_PBI_PASSWORD": "Power BI Password",
    "APPCONFIG_DESC_PBI_REPORT_ID": "Power BI Report Id",
    "APPCONFIG_DESC_PBI_USER_NAME": "Power BI User Name",
    "APPCONFIG_DESC_PBIC_LIENT_ID": "Power BI Client Id",
    "APPCONFIG_DESC_PDF_LOGO": "Logo that should appear on the pdf of your C3 application.Preferred dimension is 186x32. The page has a white background so the text or graphic should be in any color that is in contrast with White.",
    "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO": "Logo that should appear on the pdf at right side.Preferred dimension is 186x32. The page has a white background so the text or graphic should be in any color that is in contrast with White.",
    "APPCONFIG_DESC_POSTAL_CODE": "Postal code that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME": "Name that should appear on the landing page as well as footer after a user is logged in",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_URL": "Your website URL",
    "APPCONFIG_DESC_PRIVATE_KEY": "Private Key for ConnectWise Manage",
    "APPCONFIG_DESC_PUBLIC_KEY": "Public Key for ConnectWise Manage",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID": "QuickBooks App Client Id",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET": "QuickBooks App Client Secret",
    "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT": "Recon mismatch on upfront invoice generation email recipients",
    "APPCONFIG_DESC_RECON_REPORT_LOGO": "Logo that appears on the reconciliation report view right side of Microsoft logo. Preferred dimension is 170x40.The page has a white background so the text or graphic should be in any color that is in contrast with White.",
    "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE": "Add the recipients as comma separated values",
    "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER": "Should notify customer",
    "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD": "Suspend subscription when danger threshold met",
    "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS": "Show Power BI Report Menu",
    "APPCONFIG_DESC_SHOW_NEXT_STEP": "Show next step",
    "APPCONFIG_DESC_SMTP_ENABLESSL": "SMTP enable SSL",
    "APPCONFIG_DESC_SMTP_FROM_MAIL": "Email from which the user will recieve notifications",
    "APPCONFIG_DESC_SMTP_FROM_NAME": "Sender display name that would appear in email",
    "APPCONFIG_DESC_SMTP_HOST": "SMTP email server address",
    "APPCONFIG_DESC_SMTP_PASSWORD": "Password",
    "APPCONFIG_DESC_SMTP_PORT": "SMTP port number",
    "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX": "Text to be preceded by the subject of email notification",
    "APPCONFIG_DESC_SMTP_USERNAME": "SMTP User Name",
    "APPCONFIG_DESC_STATE": "State that appears on the invoice view and invoice pdf.",
    "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS": "TagMPNIDForCustomersAndResellers",
    "APPCONFIG_DESC_TAX_TYPE_KEY": "Appears on the invoice pdf",
    "APPCONFIG_DESC_TAX_TYPE_VALUE": "Appears on the invoice pdf",
    "APPCONFIG_DESC_WELCOME_LOGO": "Logo that should appear on the landing page of your C3 application.Preferred dimension is 186x32. The page has a white background so the text or graphic should be in any color that is in contrast with White.",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS": "Xero App Account Code for Adjustment LineItems",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE": "Xero App Account Code for Azure",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM": "Xero App Account code for Custom Offers",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE": "Xero App Account Code for Microsoft Licenses",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME": "Xero App Account Code for OneTime LineItems",
    "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME": "Xero App Branding Theme name",
    "APPCONFIG_DESC_XERO_APP_CLIENT_ID": "Xero App Client Id",
    "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET": "Xero App Client Secret",
    "APPCONFIG_DESC_XERO_DECIMAL_POINTS": "The Decimal Points value to which the amounts posted to Xero are rounded to",
    "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION": "Addition description and invoice description format",
    "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE": "Date Culture for ConnectWise Manage dates",
    "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES": "Consider ConnectWise Manage Prices instead of C3 prices",
    "APPCONFIG_DISP_ACCOUNT_NAME": "Account Name",
    "APPCONFIG_DISP_ACCOUNT_NUMBER": "Account Number",
    "APPCONFIG_DISP_ADDRESS_LINE1": "Address 1",
    "APPCONFIG_DISP_ADDRESS_LINE2": "Address 2",
    "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL": "Appconfig disp advance text for annual",
    "APPCONFIG_DISP_API_URL": "Api Url",
    "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING": "Enable License Tracking",
    "APPCONFIG_DISP_APP_SHOW_ANALYTICS": "Show Analytics",
    "APPCONFIG_DISP_APPLICATION_NAME": "Application name",
    "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE": "Microsoft bill generation date",
    "APPCONFIG_DISP_AZURE_CSPOFFERID": "Azure CSP Offer ID",
    "APPCONFIG_DISP_BANK_NAME": "Bank Name",
    "APPCONFIG_DISP_BILLING_CYCLE": "Billing cycle",
    "APPCONFIG_DISP_BILLING_DATE": "Date when invoices are generated in C3",
    "APPCONFIG_DISP_BILLING_PERIOD": "Billing Period",
    "APPCONFIG_DISP_BILLING_PROVIDER": "Billing Provider",
    "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION": "Billing Provider Configuration",
    "APPCONFIG_DISP_BILLING_STARTDAY": "Billing start Day",
    "APPCONFIG_DISP_BUSINESS_NUMBER": "Business number",
    "APPCONFIG_DISP_BUSINESS_NUMBER_KEY": "Business number key",
    "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365": "Check roles against 0365",
    "APPCONFIG_DISP_CIN_NUMBER": "CIN number",
    "APPCONFIG_DISP_CITY": "City",
    "APPCONFIG_DISP_CLIENT_ID": "ClientId",
    "APPCONFIG_DISP_COMPANY_URL": "Company URL",
    "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION": "Addition Description",
    "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME": "Company Name",
    "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE": "Date Culture",
    "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES": "Consider ConnectWise Manage Prices",
    "APPCONFIG_DISP_CONTACT_COMPANY_NAME": "Contact company name",
    "APPCONFIG_DISP_CONTACT_EMAIL": "Contact Email",
    "APPCONFIG_DISP_CONTACT_FIRST_NAME": "Contact first name",
    "APPCONFIG_DISP_CONTACT_LAST_NAME": "Contact last name",
    "APPCONFIG_DISP_CONTACT_PHONE_NUMBER": "Contact phone number",
    "APPCONFIG_DISP_COUNTRY": "Country",
    "APPCONFIG_DISP_COUNTRY_CODE": "Country code",
    "APPCONFIG_DISP_CURRENCY_CODE": "Currency code",
    "APPCONFIG_DISP_CUSTOM_BILLING": "Apply custom billing ",
    "APPCONFIG_DISP_CUSTOMER_CONSENT_URL": "Customer consent URL",
    "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS": "Restricting customer while onboarding under multiple entities",
    "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION": "Days To Send Cart Status Notification",
    "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE": "Number of days to store Bulk Invoice file in cloud storage",
    "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL": "Customized landing page URL",
    "APPCONFIG_DISP_DEFAULT_LANGUAGE": "Default language",
    "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT": "Default page count",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT": "Terms & conditions URL (text)",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL": "Terms & conditions URL",
    "APPCONFIG_DISP_EMAIL_LOGO": "Email Logo",
    "APPCONFIG_DISP_ENABLE_TOUR": "Enable Tour",
    "APPCONFIG_DISP_FAVICON_LOGO": "Favicon logo",
    "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID": "Should consider MPN ID of resellers for customers and purchases?",
    "APPCONFIG_DISP_IFSC_CODE": "IFSC Code",
    "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE": "Invoice footer authorized line",
    "APPCONFIG_DISP_INVOICE_FOOTER_LINE": "Invoice footer line",
    "APPCONFIG_DISP_INVOICE_GENARATION_DAYS": "Invoice Generation Days",
    "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING": "Cloud storage connection string",
    "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS": "Invoice zip valid days",
    "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS": "Partner approval required",
    "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH": "Public catalogue backed by azure search",
    "APPCONFIG_DISP_LINK_TO_CONTACT": "Link to Contact Us page",
    "APPCONFIG_DISP_LINK_TO_FAQ": "Link to Customer FAQ",
    "APPCONFIG_DISP_LINK_TO_PORTAL": "Link to Portal",
    "APPCONFIG_DISP_LINK_TO_SUPPORT": "Link to Support",
    "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO": "Link to Welcome Logo",
    "APPCONFIG_DISP_LOGGEDIN_LOGO": "Logged in Logo",
    "APPCONFIG_DISP_LOGIN_TO_PORTAL": "Login to Portal",
    "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS": " Mandatory CustomerId In Api Calls",
    "APPCONFIG_DISP_MAX_AUDITLOG_INDEX": "Maximum Entries For Export",
    "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT": "Maximum Entitlement Creation Count",
    "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT": "Maximum Tenants Creation Count",
    "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS": "Microsoft security consent remind in days",
    "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT": "Minimum Charge Amount",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT": "NCE Terms & conditions URL (text)",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL": "NCE terms & conditions URL",
    "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY": "Onboard Customer Search With Billing",
    "APPCONFIG_DISP_PAN_NUMBER": "Pan number",
    "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT": "PAN number key",
    "APPCONFIG_DISP_PARTNER_APPLICATIONID": "Native Application ID",
    "APPCONFIG_DISP_PARTNER_CURRENCY": "Partner Currency",
    "APPCONFIG_DISP_PARTNER_PASSWORD": "Admin Agent Password",
    "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES": "Language packs",
    "APPCONFIG_DISP_PARTNER_TENANT_ID": "Partner Tenant ID",
    "APPCONFIG_DISP_PARTNER_USERNAME": "Admin agent User Name",
    "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT": "Microsoft Cloud Reseller Agreement URL",
    "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT": "Partner Terms & Conditions URL",
    "APPCONFIG_DISP_PBI_GROUP_ID": "Power BI Group Id",
    "APPCONFIG_DISP_PBI_PASSWORD": "Power BI Password",
    "APPCONFIG_DISP_PBI_REPORT_ID": "Power BI Report Id",
    "APPCONFIG_DISP_PBI_USER_NAME": "Power BI User Name",
    "APPCONFIG_DISP_PBIC_LIENT_ID": "Power BI Client Id",
    "APPCONFIG_DISP_PDF_LOGO": "PDF Logo",
    "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO": "PDF Right Side Logo",
    "APPCONFIG_DISP_POSTAL_CODE": "Zip",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME": "Powered by company name",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_URL": "Powered by company URL",
    "APPCONFIG_DISP_PRIVATE_KEY": "Private Key",
    "APPCONFIG_DISP_PUBLIC_KEY": "Public Key",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID": "QuickBooks ClientId",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET": "QuickBooks ClientSecret",
    "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT": "Recon mismatch on upfront invoice generation email recipients",
    "APPCONFIG_DISP_RECON_REPORT_LOGO": "Reconciliation Report Logo",
    "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE": "Reconciliation Report Notify Recipient",
    "APPCONFIG_DISP_REFUND_TEXT": "Appconfig disp refund text",
    "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL": "Appconfig disp refund text for annual",
    "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD": "Suspend subscription when danger threshold met",
    "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS": "Show Power BI Report Menu",
    "APPCONFIG_DISP_SHOW_NEXT_STEP": "Show next step",
    "APPCONFIG_DISP_SMTP_ENABLESSL": "Enable SSL",
    "APPCONFIG_DISP_SMTP_FROM_MAIL": "From email",
    "APPCONFIG_DISP_SMTP_FROM_NAME": "From display name",
    "APPCONFIG_DISP_SMTP_HOST": "Host",
    "APPCONFIG_DISP_SMTP_PASSWORD": "SMTP password",
    "APPCONFIG_DISP_SMTP_PORT": "SMTP port",
    "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX": "Subject prefix",
    "APPCONFIG_DISP_SMTP_USERNAME": "User name",
    "APPCONFIG_DISP_STATE": "State",
    "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS": "TagMPNIDForCustomersAndResellers",
    "APPCONFIG_DISP_TAX_TYPE_KEY": "Tax type",
    "APPCONFIG_DISP_TAX_TYPE_VALUE": "Tax value",
    "APPCONFIG_DISP_WELCOME_LOGO": "Welcome logo",
    "APPCONFIG_DISP_WELCOME_PAGE_VIEW": "Welcome page",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS": "Xero account code for adjustments",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE": "Xero app account code for Azure",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM": "Xero app account code for Custom",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE": "Xero app account code for MSLicense",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME": "Xero account code for OneTime",
    "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME": "Xero app BrandingTheme name",
    "APPCONFIG_DISP_XERO_APP_CLIENT_ID": "Xero ClientId",
    "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET": "Xero clientsecret",
    "APPCONFIG_DISP_XERO_DECIMAL_POINTS": "Xero decimal points",
    "APPCONFIG_SELECTED_SMTP_OPTION": "Select SMTP type",
    "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION": "Select the SMTP option",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID": "Modern authentication application id",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION": "Client Id of the Azure AD App",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET": "Modern authentication application password",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION": "Client Secret of the Azure AD App",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM": "Modern authentication from email",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION": "Email from which the user will recieve notifications",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID": "Modern authentication tenant id",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION": "Tenant Id of user account which will be used to send emails",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY": "Remaining limit after approving this request will be ",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL": "Current remaining limit will be updated after a successful renewal. ",
    "APPROVED_AND_INITIATED": "Approved and initiated",
    "APPROVED_BY": "by distributor",
    "APRIL": "April",
    "ASSIGN_BUNDLE_PRODUCTS": "Assign Bundle Products",
    "ASSIGN_CUSTOMER_PLAN": "Assign Customer Plan",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION": "Are you sure you want to assign {{customer}} to {{accountManager}}?",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE": "Customer is assigned successfully",
    "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE": "Reseller is assigned successfully",
    "ASSIGN_USAGE_PRODUCTS": "Assign Usage Products",
    "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY": "Assigned Customer To Account Manager Successfully",
    "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY": "Assigned Reseller To Account Manager Successfully",
    "ATTESTATION_DESCRIPTION_FOR_WINDOWS365": "I understand that each person using Windows 365 Business with Windows Hybrid Benefit also needs to have a valid copy of Windows 10/11 Pro installed on their primary work device.",
    "AUDIT_LOG_BUTTON_TEXT_CLOSE": "Close",
    "AUDIT_LOG_DETAIL": "Details",
    "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN": "Hidden",
    "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT": "--All Actions--",
    "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT": "--All Customers--",
    "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT": "--All Domains--",
    "AUDITLOG_BUTTON_LABEL_AUDIT_LOG": "Audit Log",
    "AUDITLOG_BUTTON_LABEL_SEARCH": "Search",
    "AUDITLOG_TEXT_ACTION_NAME": "Actions",
    "AUDITLOG_TEXT_AUDITLOG": "Audit Log",
    "AUDITLOG_TEXT_CUSTOMER_NAME": "Customer name",
    "AUDITLOG_TEXT_EFFECTIVE_FROM": "Effective From",
    "AUDITLOG_TEXT_EFFECTIVE_TO": "Effective To",
    "AUDITLOG_TEXT_FROM": "From",
    "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN": "Customer domain name",
    "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG": "Show hidden audit logs",
    "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS": "Actions",
    "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER": "Affected customer",
    "AUDITLOG_TEXT_TABLE_HEADER_DATE": "Date",
    "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR": "Initiator",
    "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS": "View details",
    "AUDITLOG_TEXT_TO": "To",
    "AUGUST": "August",
    "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS": "Authentication code received from Quickbooks",
    "AUTH_CODE_RECIEVED_FROM_XERO": "Authentication Code recieved from Xero",
    "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED": "The batch is already closed.",
    "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY": "Request completed successfully",
    "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED": "The credit card has expired.",
    "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL": "The device batch is full. Please close the batch.",
    "AUTHORIZE_NET_CODE_DEVICE_DISABLED": "The device has been disabled.",
    "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED": "A duplicate transaction has been submitted.",
    "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED": "The device identifier is either not registered or not enabled.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID": "The device batch ID is invalid.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID": "The device ID is invalid.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID": "The reversal flag is invalid.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE": "The specified security code was invalid.",
    "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR": "A validation error occurred at the processor.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT": "The request data did not pass the required fields check for this application.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING": "The request field(s) are either invalid or missing.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED": "V.me transactions are not accepted by this merchant.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND": "The transaction cannot be found.",
    "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED": "The void request failed. Either the original transaction type does not support void, or the transaction is in the process of being settled.",
    "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS": "The merchant is activated successfully.",
    "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED": "The merchant is configured for auto-close.",
    "AUTHORIZE_NET_CODE_NEED_CONSET": "Need payer consent.",
    "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED": "This order has already been released or voided therefore new transaction associations cannot be accepted.",
    "AUTHORIZE_NET_CODE_ORDER_RECEIVED": "Your order has been received. Thank you for your business!",
    "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH": "The original transaction is in a closed batch.",
    "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED": "The Payment Gateway Account service (id=8) has already been accepted.",
    "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE": "A new security code was requested.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT": "This transaction has already been authorized.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION": "PayPal has already captured this transaction.",
    "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY": "The reversal was processed successfully.",
    "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY": "The mobile device was successfully inserted into the database.",
    "AUTHORIZE_NET_CODE_SUCCESS": "The request was processed successfully.",
    "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED": "The mobile device was successfully registered and approved by the account administrator.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED": "This transaction has already been voided.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED": "This transaction has already been captured.",
    "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND": "Original transaction for reversal not found.",
    "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED": "PayPal has rejected the transaction. x_paypal_hdrimg exceeds maximum allowable length.",
    "AUTHORIZE_NET_CODE_USE_XMETHOD": "Use x_method to specify method or send only x_call_id or card/account information.",
    "AUTHORIZE_NET_CODE_WORK_IN_PROGESS": "This is a work in progress. This message will not be released to production. It's just a dev placeholder.",
    "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE": "Conversion rate for this card is available.",
    "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID": "The card code is invalid.",
    "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW": "The transaction is currently under review.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED": "This transaction cannot be accepted.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED": "This transaction cannot be processed.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST": "This account has not been given the permission(s) required for this request.",
    "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT": "The given name on the account and/or the account type does not match the actual account.",
    "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM": "ACH transactions cannot be accepted by this system.",
    "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID": "The authentication indicator is invalid.",
    "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID": "The authorization code is invalid.",
    "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID": "The bank account type is invalid.",
    "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED": "Recurring billing is not allowed for this eCheck.Net type.",
    "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID": "The cardholder authentication value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID": "The ABA code is invalid",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED": "The access token provided has expired.",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE": "The access token used to validate the request is insufficient to do so.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE": "The requested API method cannot be executed while the payment gateway account is in Test Mode.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER": "This merchant is not associated with this reseller.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS": "The merchant account must be set up to accept credit card payments, eCheck payments, or both.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE": "The payment gateway, reseller, or user account is not currently active.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS": "The payment gateway account is not set up to process credit card subscriptions.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK": "The payment gateway account is not set up to process eCheck.Net subscriptions.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID": "The account number is invalid",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED": "The subscription amount is required when creating a subscription.",
    "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS": "ACH transactions are not accepted by this merchant.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS": "The sum of all credits against this transaction is greater than the original transaction amount.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY": "All of the fields were empty or missing.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY": "All of the fields were empty or missing.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES": "Invalid value.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG": "Value too long.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED": "The subscription has already been canceled.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED": "The record has already been deleted.",
    "AUTHORIZE_NET_ERROR_CODE_AN_ERROR": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION": "An approval was not returned for the transaction.",
    "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED": "One or more required auction values were not submitted.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL": "The authentication type is not allowed for the requested method call.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED": "An authorization code is required but not present.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED": "This processor does not accept zero dollar authorizations.",
    "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH": "The transaction has been declined because of an AVS mismatch. The address provided does not match billing address of cardholder.",
    "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY": "Bank payment method is not accepted for the selected business country.",
    "AUTHORIZE_NET_ERROR_CODE_BLANK": "%s cannot be left blank.",
    "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER": "An error occurred during processing. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR": "Calling web service return error.",
    "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL": "Cancel URL is required.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED": "This transaction is awaiting settlement and cannot be refunded.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION": "The number of total occurrences cannot extend the duration of the subscription beyond three years from the start date.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED": "The credit card has expired.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID": "The credit card number is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT": "The totals of the cart item amounts do not match order amounts. Be sure the total of the payment detail item parameters add up to the order total.",
    "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE": "The payment gateway account is not enabled for Invoicing or CIM service.",
    "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED": "Client authorization denied.",
    "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED": "The only supported content-types are text/xml and application/xml.",
    "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS": "Could not decrypt DUKPT blobs and returned error.",
    "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID": "Country is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID": "The credit card is not valid as of the start date of the subscription.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID": "The credit card number is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY": "Credit card payment method is not accepted for the selected business country.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED": "Credit card transactions are not accepted by this merchant.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE": "CustomerProfileId and Customer data are mutually exclusive, only one of them can be provided for any single request.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED": "Error creating a customer profile from transaction.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED": "The customer tax ID or driver's license information (driver's license number, driver's license state, driver's license DOB) is required for the subscription.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID": "When using Customer Profile, CustomerPaymentProfileId must be included.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED": "Error creating a customer payment profile from transaction.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED": "The specified customer profile is associated with an active or suspended subscription and cannot be deleted.",
    "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT": "The database operation timed out before it could be completed.",
    "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT": "The sum of credits against the referenced transaction would exceed original debit amount.",
    "AUTHORIZE_NET_ERROR_CODE_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA": "An error occurred while decrypting the EMV data.",
    "AUTHORIZE_NET_ERROR_CODE_DEFAULT": "The customer profile does not have a default payment/shipping profile.",
    "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED": "Due date is past date or not specified.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED": "A duplicate of the subscription was already submitted.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION": "A duplicate transaction has been submitted.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN": "KSN is duplicated.",
    "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED": "Valid Customer Profile ID or Email is required",
    "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X": "Employee ID must be 1 to %x characters in length.",
    "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED": "EMV data is not supported with this market type.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID": "Credit card expiration date is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL": "Failed sending email.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY": "Failed shared key validation.",
    "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR": "Fatal error when calling web service.",
    "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID": "The FDC Merchant ID or Terminal ID is incorrect. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID": "The field type is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID": "One of the field values is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH": "One of the fields has an invalid length.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT": "One of the required fields was not present.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED": "Finger Print can't be generated.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID": "Finger Print value is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE": "Generic InsertNewMerchant failure.",
    "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM": "The Global Payment System identification numbers are incorrect. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_HASH": "Hash doesnâ€™t match.",
    "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE": "Hosted Payment Page will capture the payment (bank/card) information so this information should not be included with this request.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID": "Impersonation API Key is invalid or not present.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID": "Impersonation partner login ID is invalid or not present.",
    "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED": "Once a subscription is created the interval cannot be changed.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES": "The API user name is invalid and/or the transaction key or API key is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID": "The auction buyer ID is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS": "The transaction type is invalid for ACH transactions.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION": "There is missing or invalid information in a required field.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH": "The interval length must be 7 to 365 days or 1 to 12 months.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID": "PayPal has rejected the transaction.Invalid Payer ID.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME": "The combination of auction platform ID and auction platform name is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY": "The POS Entry Mode value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE": "The customer profile ID, payment profile ID, shipping address ID, or transaction ID for this request is not valid for this merchant.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE": "The name of the root node of the XML request is the API method being called. It is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID": "The auction seller ID is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT": "The amount requested for settlement cannot be different than the original amount authorized. Please void transaction if required",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE": "The V.me transaction is in an invalid state.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY": "User authentication requires a valid value for transaction key or API key.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME": "User authentication requires a valid value for API user name.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE": "Invalid value.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION": "The EMV version is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID": "The x_call_id value is not found or invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT": "The x_discount value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP": "The x_giftwrap value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC": "The x_misc value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR": "PayPal has rejected the transaction. x_paypal_payflowcolor must be a 6 character hexadecimal value.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL": "The x_subtotal value is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED": "This invoice has been canceled by the sender. Please contact the sender directly if you have questions.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER": "Invoice number did not find any records.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED": "The transaction amount submitted was greater than the maximum amount allowed.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED": "The maximum number of elements for an array has been reached.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED": "The maximum number of payment profiles for the customer profile has been reached.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED": "The maximum number of shipping addresses for the customer profile has been reached.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE": "The reseller account is not enabled for Merchant Boarding API.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD": "The merchant does not accept this type of credit card.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION": "Merchant has declined authorization to resource.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT": "The merchant does not accept this type of credit card.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID": "The merchant login ID or password is invalid or the account is inactive.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS": "The merchant has too many devices in a pending status.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED": "The merchant is not activated.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID": "The merchant password is invalid or not present.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR": "The merchant was incorrectly set up at the processor. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT": "The Paymentech identification numbers are incorrect. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTID": "Failed to parse the MerchantId to integer",
    "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID": "The x_call_id value is missing.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT": "The mobile device identifier is not associated with the merchant account.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL": "The mobile device exists but is in a pending status.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED": "The mobile device exists but has a status of disabled.",
    "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME": "Country must be a valid two or three-character value if specified.",
    "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN": "The authenticated merchant does not have access to the specified OTS Token.",
    "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND": "No records have been found that match your query.",
    "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE": "There are no subscriptions available for the merchant account for the type of subscriptions requested.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION": "The Access token does not have permission to call the API method.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED": "The transaction was authorized but the client could not be notified; it will not be settled.",
    "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS": "V.me by Visa does not support voids on captured or credit transactions. Please allow the transaction to settle, then process a refund for the captured transaction.",
    "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID": "One of the field values is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK": "Only either CreditCard or Bank is allowed.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME": "CreateProfile and profileIds are mutually exclusive, only one of them can be provided at a time.",
    "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA": "Payment Profile creation with this OpaqueData descriptor requires transactionMode to be set to liveMode.",
    "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION": "If Customer Profile ID is included, it must match the Customer Profile ID used for the original subscription.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID": "The specified OTS Token Data is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED": "The specified OTS Token has expired.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID": "The specified OTS Token is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR": "The OTS Service cannot complete the request due to a validation or configuration error.",
    "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED": "Paging Offset exceeds allowed value. Check and lower the value.",
    "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR": "An error occurred while parsing the EMV data.",
    "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS": "This processor does not accept partial reversals.",
    "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT": "The partner account is not authorized to impersonate the login account.",
    "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER": "PayPal has rejected the transaction. This Payer ID belongs to a different customer.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED": "The Payment Gateway Account service (id=8) has already been accepted. Decline is not allowed.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED": "The Payment Gateway Account service (id=8) has already been declined.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING": "The payment gateway account is not enabled for Automated Recurring Billing.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER": "The payment gateway account is not enabled for Customer Information Manager (CIM).",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED": "Payment information is required when creating a subscription or payment profile.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED": "Customer profile creation failed. This payment method does not support profile creation.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED": "The specified payment profile is associated with an active or suspended subscription and cannot be deleted.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED": "A payment schedule is required when creating a subscription.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED": "Changing the subscription payment type between credit card and eCheck.Net is not currently supported.",
    "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS": "Pending Status (not completed).",
    "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED": "Prerequisite failed.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE": "Processor account has not been setup to set test mode flag to false.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION": "Merchant has not yet provided processor information to set test mode flag to false.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID": "The configuration with processor is invalid. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED": "Customer profile creation failed. This transaction ID is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs": "Payment and Shipping Profile IDs cannot be specified when creating new profiles.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED": "Payment and Shipping Profile IDs cannot be specified when requesting a hosted payment page.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION": "If customer profile ID, payment profile ID, and shipping address ID are included, they must match the original transaction.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND": "Search for shipping profile using customer profile id and shipping profile id did not find any records.",
    "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS": "A duplicate of the customer profile, customer payment profile, or customer address was already submitted.",
    "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING": "RecurringBilling setting is a required field for recurring tokenized payment transactions.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND": "Reference Id not found.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION": "The referenced transaction does not meet the criteria for issuing a credit.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION": "Requested action is not allowed due to current status of the object.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL": "When requesting CreateProfile payment data cannot be null.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT": "Payment not authorized. Payment has not been authorized by the user.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION": "The EMV version is required.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER": "Terminal/lane number must be numeric.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR": "Opaque Descriptor is required.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID": "Payer ID is required.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE": "Signature must be PNG formatted data.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL": "PayPal transactions require valid URL for cancel_url",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL": "PayPal transactions require valid URL for success_url",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING": "Required node missing.",
    "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR": "A validation error was returned from V.me.",
    "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION": "We are currently holding the last transaction for review. Before you reactivate the subscription, review the transaction.",
    "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE": "The root node does not reference a valid XML namespace.",
    "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE": "Cannot set a buyrate to less than the sellrate",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_": "The server is currently too busy, please try again later.",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR": "Server error",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE": "The server is in maintenance, so the requested method is unavailable. Please try again later.",
    "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED": "Your session has expired or does not exist. You must log in again to continue working.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER": "The amount requested for settlement cannot be greater than the original amount authorized.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED": "The authorization was approved but settlement failed.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE": "Shipping Address ID and Shipping data are mutually exclusive, only one of them can be provided for any single request.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED": "The specified shipping profile is associated with an active or suspended subscription and cannot be deleted.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE": "When using Customer Profile with Credit Card Info to specify Shipping Profile, Shipping Profile Id must be included.",
    "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY": "Signature key missing.",
    "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID": "Reports cannot be generated for future dates, thus the specified date is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED": "Once a subscription is created the start date cannot be changed.",
    "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID": "State is not valid.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE": "The subscription start date cannot occur before the subscription submission date.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED": "Subscriptions that are expired or terminated cannot be canceled.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE": "Subscriptions that are expired, canceled or terminated cannot be updated.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND": "The subscription ID for this request is not valid for this merchant.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED": "The subscription start date is required to create a subscription.",
    "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID": "The supplied currency code is either invalid, not supported, not allowed for this merchant or doesnt have an exchange rate.",
    "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT": "The Elavon bank number or terminal ID is incorrect. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA": "Signature data is too large.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED": "This transaction has been approved.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS": "The transaction was submitted from a blocked IP address.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE": "The referenced transaction does not meet the criteria for issuing a Continued Authorization.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE": "The referenced transaction does not meet the criteria for issuing a Continued Authorization w/ Auto Capture.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE": "Customer profile creation failed. This transaction type does not support profile creation.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED": "This transaction must be encrypted.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID": "The transaction ID is invalid or not present.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE": "This transaction cannot be accepted at this time due to system maintenance. Please try again later.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND": "The transaction cannot be found.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE": "EMV-based transactions are not currently supported for this processor and card type.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE": "EMV data is not supported with this transaction type.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED": "PayPal transactions are not accepted by this merchant.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION": "PayPal transactions require x_version of at least 3.1.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID": "The transaction type is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES": "The number of trial occurrences specified must be less than the number of total occurrences specified.",
    "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR": "An unexpected system error occurred while processing this request.",
    "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR": "Unknown Error.",
    "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS": "Invoice created but failed send email and update status",
    "AUTHORIZE_NET_ERROR_CODE_URL_INVALID": "The referrer, relay response or receipt link URL is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS": "The APIUser already exists.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT": "The user does not have sufficient permissions to use a mobile device with this merchant account.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION": "The user does not have permission to call the API.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API": "The user does not have permission to call the API method.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED": "A valid amount is required.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ": "The number of trial occurrences cannot be zero if a valid trial amount is submitted.",
    "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED": "If either a trial amount or number of trial occurrences is specified then values for both must be submitted.",
    "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION": "The VITAL identification numbers are incorrect. Call Merchant Service Provider.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR": "This is the result of an XML parser error.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE": "This is the result of an XML parser error. The node is invalid.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD": "This is the result of an XML parser error. Missing field(s).",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES": "This is the result of an XML parser error. Too many nodes specified.",
    "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID": "The combination of card type, authentication indicator and cardholder authentication value is invalid.",
    "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID": "The commercial card type is invalid.",
    "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS": "This country is not authorized for buyers.",
    "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER": "The cryptogram is either invalid or cannot be used in combination with other parameters.",
    "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW": "The transaction is currently under review.",
    "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING": "The device type is invalid or missing.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID": "The drivers license date of birth is invalid.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID": "The drivers license number is invalid.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID": "The drivers license state is invalid.",
    "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID": "The duty amount is invalid.",
    "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED": "This eCheck.Net type is not allowed when using the payment gateway hosted payment form.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED": "This eCheck.Net type is not allowed.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE": "This eCheck.Net type is not allowed for this Bank Account Type.",
    "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID": "The eCheck type parameter is invalid.",
    "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID": "The freight amount is invalid.",
    "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION": "The gateway no longer supports the requested method of integration.",
    "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER": "The check number is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER": "The credit card number is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE": "Credit card expiration date is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM": "Line item %1 is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGID": "The auction listing ID is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE": "The auction listing type is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID": "The auction platform ID is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS": "There is one or more missing or invalid required fields.",
    "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID": "The specified SplitTenderID is invalid.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH": "Invalid Token Requestor ECI Length.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH": "Invalid Token Requestor ID Length.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME": "Invalid Token Requestor Name.",
    "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM": "The auction platform name is invalid.",
    "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED": "The number of line items submitted is not allowed. A maximum of %1 line items can be submitted.",
    "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID": "The market type is invalid",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED": "This merchant account has been closed.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED": "The merchant account is not active. This automated payment will not be processed.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED": "The merchant account is in test mode. This automated payment will not be processed.",
    "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION": "This processor does not accept zero dollar authorization for this card type.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT": "An error occurred during processing. Please contact the merchant.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE": "One or more of the HTML type configuration fields do not appear to be safe.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING": "One or more of the required sub-merchant values are missing.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID": "One or more of the sub-merchant values are invalid.",
    "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID": "The PayformMask is invalid.",
    "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA": "There was an error processing the payment data.",
    "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT": "Processing Apple Payments is not enabled for this merchant account.",
    "AUTHORIZE_NET_ERROR_PROCESSING_ERROR": "An error occurred during processing. Please try again.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST": "This processor does not accept recurring transactions.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS": "This processor does not allow voiding of credits.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA": "This processor does not support this method of submitting payment data.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED": "This request cannot be accepted.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED": "Transaction ID and Split Tender ID cannot both be used in the same request.",
    "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID": "The requested form type is invalid.",
    "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT": "The amount of this request was only partially approved on the given prepaid card. An additional payment is required to fulfill the balance of this transaction.",
    "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL": "Success URL is required.",
    "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID": "The Response Format is invalid",
    "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED": "Reversal not supported for this transaction type.",
    "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED": "The requested script is either invalid or no longer supported.",
    "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5": "Scripts are only supported in version 2.5.",
    "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID": "The shipping state or country is invalid.",
    "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID": "The SSN or tax ID is invalid.",
    "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID": "The surcharge amount is invalid.",
    "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID": "The tax amount is invalid.",
    "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID": "The Tip amount is invalid.",
    "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT": "Track1 data is not in a valid format.",
    "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT": "Track2 data is not in a valid format.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED": "The transaction was authorized but the client could not be notified; it will not be settled.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME": "This transaction cannot be accepted at this time.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED": "This transaction cannot be processed.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING": "Transactions having cardholder authentication values cannot be marked as recurring.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW": "The transaction is currently under review.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED": "This transaction has been declined.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM": "Transactions of this market type cannot be processed on this system.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID": "The transaction method is invalid.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED": "This transaction cannot be accepted.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING": "This transaction cannot be accepted for processing.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED": "The referenced transaction was not approved.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT": "The given transaction type is not supported for this merchant.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER": "The transaction resulted in an AVS mismatch. The address provided does not match billing address of cardholder.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID": "The transaction type is invalid",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT": "The transaction type is invalid. Please contact the merchant.",
    "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED": "A valid billing country is required.",
    "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED": "A valid country is required.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED": "A valid billing state/province is required.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED": "A valid state is required.",
    "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED": "Version 2.5 is no longer supported.",
    "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID": "The version parameter is invalid",
    "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS": "Allow access to CSP global administrators",
    "AUTO_PAYMENT_REQUEST_SUCCESS": "Payment has been initiated successfully. It will take some time to process. Please check the status after sometime.",
    "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE": "Auto-renew flag is only applicable to NCE offers",
    "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED": "Create plan product is not successful during autoSync process",
    "AUTO_SYNC_MACRO_NOT_FOUND": "Macro not found",
    "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND": "Multiple mapped offers found",
    "AUTO_SYNC_MULTIPLE_PLAN_FOUND": "Multiple plans found",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND": "No mapped offers found",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION": "No mapped offers found with promotion",
    "AUTO_SYNC_NO_PLAN_FOUND": "No plan found",
    "AUTOTASK_API_USER_NAME_IS_MISSING": "API user name is missing. Please update the user name in the settings screen.",
    "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY": "Autotask quantity missmatch with C3 old quantity",
    "AZURE_ALL_RECOMMENDATIONS_CATEGORY": "Category",
    "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION": "Description",
    "AZURE_ALL_RECOMMENDATIONS_IMPACT": "Impact",
    "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE": "Impacted Resource",
    "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED": "Last updated",
    "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS": "Potential benefits",
    "AZURE_BILLING": "Azure billing",
    "AZURE_BILLING_CYCLE": "Billing cycle",
    "AZURE_ENTITLEMENT_MANAGEMENT": "Azure entitlement",
    "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT": "Azure Entitlement Management",
    "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN": "The tenant is missing an Azure entitlement!",
    "AZURE_ESTIMATE": "Azure estimate",
    "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL": "Billing Period",
    "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL": "Entitlement ID",
    "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL": "Entitlement Name",
    "AZURE_ESTIMATE_LABEL_DEPARTMENT": "Department : ",
    "AZURE_ESTIMATE_LABEL_SITE": "Site : ",
    "AZURE_ESTIMATE_REPORT": "Azure Estimate Report",
    "AZURE_ESTIMATE_TAGS": "Tags",
    "AZURE_ESTIMATE_TAGS_POPUP_INFO": "Please select Atleast one tag for download azure estimate",
    "AZURE_ESTIMATE_TAGS_POPUP_TITLE": "Azure Tags",
    "AZURE_ESTIMATE_TAGS_TOOLTIP": "Resource tags defined in Azure portal",
    "AZURE_ESTIMATED_COST": "Estimated Cost",
    "AZURE_ESTIMATES": "Azure Estimates",
    "AZURE_GROUP_DETAILS": "Group Details",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS": "Azure groups",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP": "Add admin to group",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP": "Add group",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS": "Back to group users",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS": "Back to groups",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP": "Edit Group",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS": "Group Users",
    "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER": "Add Group User",
    "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS": "Azure Groups",
    "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF": "Users of {{groupname}}",
    "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR": "Administrator",
    "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS": "Billing Access",
    "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD": "Danger Threshold",
    "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION": "Description",
    "AZURE_GROUPS_LABEL_TEXT_EMAIL": "Email",
    "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD": "Error Threshold",
    "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME": "Group Name",
    "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD": "Info Threshold",
    "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET": "Monthly Budget",
    "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY": "Notification Frequency",
    "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD": "Warn Threshold",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR": "Administrator",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS": "Billing access",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME": "Name",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY": "Notification frequency",
    "AZURE_LOADING": "Trying to load Data from azure please wait ...",
    "AZURE_LOADING_MESSAGE": "Querying Microsoft Azure and it could take a minute to load...",
    "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT": "Azure margin value updated successfully",
    "AZURE_NEW_MARKUP": "New markup",
    "AZURE_OLD_MARKUP": "Current markup",
    "AZURE_PLAN_CREATION_FAILED": "Azure plan creation failed",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT": "Invoice amount will be calculated for customer, as well as their respective sites or departments, based on the allocated percentage.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT": "The allocation of the Azure plan and its entitlements can be shared among different site and department entities by assigning a percentage.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS": "Instructions",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1": "Steps to allocate: <br /> a. Expand the desired subscription. <br /> b. Click on the percentage number associated with an entity. <br /> c. Enter the desired percentage. <br /> d. Click on <strong> Submit <strong/> to save the allocation.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2": "To fully assign the Azure Plan or entitlement to a Site/Department, enter 100.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3": "To release an allocation, enter 0.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4": "Released allocations will be assigned to the immediate parent entity.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5": "These allocations will not have any impact on reports and Azure Estimate and Billing report will keep showing all the data against the Customer.",
    "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY": "Score by Category",
    "AZURE_RECOMMENDATION_ALL_CATEGORIES": "All categories",
    "AZURE_RECOMMENDATION_ALL_COST": "Cost",
    "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY": "High availability",
    "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE": "Operational excellence",
    "AZURE_RECOMMENDATION_ALL_PERFORMANCE": "Performance",
    "AZURE_RECOMMENDATION_ALL_RELIABILITY": "Reliability",
    "AZURE_RECOMMENDATION_ALL_SECURITY": "Security",
    "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP": "Download recommendation details",
    "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS": "Recommendation Details",
    "AZURE_RECOMMENDATION_REQUIRED_ERROR": "This field is required",
    "AZURE_RECOMMENDATION_SUBMIT": "Submit",
    "AZURE_RECOMMENDATION_TEXT": "Azure Recommendation",
    "AZURE_RECOMMENDATIONS_TEXT": "Recommendations",
    "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT": "Select a currency code",
    "AZURE_REPORT_DISTRIBUTOR_LIST": "Distributor",
    "AZURE_REPORT_DISTRIBUTOR_TOOLTIP": "Please select either the Partner or one of the Resellers.",
    "AZURE_REPORT_ENTITY_DEFAULT_SELECT": "Select a Entity",
    "AZURE_REPORT_ENTITY_LIST": "Entity",
    "AZURE_REPORT_ENTITY_TOOLTIP": "Please select either the Partner or one of the Resellers.",
    "AZURE_REPORT_RESELLERS_DEFAULT_SELECT": "Select a Distributor",
    "AZURE_REPORT_RESELLERS_LIST": "Choose a reseller",
    "AZURE_REPORTS_BITTON_TEXT": "Azure estimate report",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY": "Details by",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP": "Details by resource group",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE": "Details by service",
    "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS": "Get details",
    "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS": "Without tags",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS": "Azure reports",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "AZURE_REPORTS_BY_TAGS_BITTON_TEXT": "With tags",
    "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY": "Billing Summary",
    "AZURE_REPORTS_LABEL_TEXT_DATE": "Date",
    "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT": "Entitlement",
    "AZURE_REPORTS_LABEL_TEXT_GROUP": "Group",
    "AZURE_REPORTS_LABEL_TEXT_MONTH": "Month",
    "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP": "Select Group",
    "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION": "Subscription",
    "AZURE_REPORTS_OPTION_SELECT_TAG": "Select a tag for getting tag wise data",
    "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS": "All Entitlements",
    "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS": "All Groups",
    "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS": "All Subscriptions",
    "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE": "Select Date",
    "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED": "Unassigned",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST": "Cost",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY": "Quantity",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME": "Resource Name",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME": "Service Name",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT": "Audit",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS": "Details",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP": "Resource group",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME": "Resource name",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME": "Service name",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION": "Subscription",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST": "Total cost",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME": "Date-time",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY": "Event Category",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY": "Event Initiated By",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME": "Operation Name",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS": "Status",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT": "Audit",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS": "Details",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST": "Cost",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE": "Date",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY": "Quantity",
    "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST": "Total cost",
    "AZURE_REPPORT_CURRENCY": "Currency",
    "AZURE_REPPORT_CURRENCY_TOOLTIP": "You must select a currency to get the report",
    "AZURE_REPPORT_CUSTOMERS": "Customers",
    "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT": "Select Customer",
    "AZURE_REPPORT_CUSTOMERS_TOOLTIP": "Kindly choose a customer.",
    "AZURE_ROLE_NAME_CONTRIBUTOR": "Contributor",
    "AZURE_ROLE_NAME_CUSTOMER_ROLE": "Custom Role",
    "AZURE_ROLE_NAME_OWNER": "Owner",
    "AZURE_ROLE_NAME_READER": "Reader",
    "AZURE_SUBSCRIPTION": "Azure Subscription",
    "AZURE_SUBSCRIPTION_BUTTON_EDIT": "Edit",
    "AZURE_SUBSCRIPTION_DETAILS": "Subscription Details",
    "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT": "Select",
    "AZURE_SUBSCRIPTION_ID": "Subscription Id",
    "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT": "The tenant is missing an Azure plan!",
    "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION": "Azure subscription is  Update Failed",
    "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION": "Azure subscription is  Updated successfully",
    "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP": "Are you sure you want to upgrade?",
    "AZURE_THRESHOLD_DETAILS": "Threshold Details",
    "AZURE_THRESHOLD_HELP_TEXT_FIRST": "Set a monthly budget for this subscription and define the thresholds at various severities like Info, Warn, Error and Danger.",
    "AZURE_THRESHOLD_HELP_TEXT_SECOND": "For Customer Managed subscriptions, all the group administrators would get notified when the usage meets or exceeds a particular threshold.",
    "AZURE_THRESHOLD_HELP_TEXT_THIRD": "Notifications are sent out once per billing cycle.",
    "AZURE_UPGRADE_AZURE_PLAN_TEXT": "Azure Plan",
    "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE": "Please wait we are checking the eligibility for Upgrade",
    "AZURE_UPGRADE_INELIGIBLE_MESSAGE": "The Subscription is not eligible for Upgrade",
    "AZURE_UPGRADE_WAIT_MESSAGE": "Please Wait We are processing on Upgrade...",
    "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST": "Back to List",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE": "Azure usage",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS": "Azure Subscriptions",
    "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS": "Usage Details",
    "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS": "View usage details",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE": "Estimate price",
    "AZURE_USAGE_TABLE_HEADER_TEXT_NAME": "Name",
    "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID": "Subscription ID",
    "AZUREGROUPS_ADD_USER_SUCCESS": "{{username}} added to Group {{groupname}} successfully",
    "AZUREGROUPS_SAVE_SUCCESS": "Group {{username}} saved successfully",
    "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL": "Group user added successfully",
    "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL": "Group saved successfully",
    "BACK_TO_AZURE_ADVISOR": "Back to azure advisor",
    "BACK_TO_POLICIES": "Back to Custom policies",
    "BACK_TO_PUBLIC_SIGNUP_SHOP": "Back to shop",
    "BACK_TO_SERVICE_PROVIDER_TENANT": "Back to Provider tenants",
    "BACKGROUND_DROPDOWN_TEMPLATE_1": "Template 1",
    "BACKGROUND_DROPDOWN_TEMPLATE_10": "Template 10",
    "BACKGROUND_DROPDOWN_TEMPLATE_2": "Template 2",
    "BACKGROUND_DROPDOWN_TEMPLATE_3": "Template 3",
    "BACKGROUND_DROPDOWN_TEMPLATE_4": "Template 4",
    "BACKGROUND_DROPDOWN_TEMPLATE_5": "Template 5",
    "BACKGROUND_DROPDOWN_TEMPLATE_6": "Template 6",
    "BACKGROUND_DROPDOWN_TEMPLATE_7": "Template 7",
    "BACKGROUND_DROPDOWN_TEMPLATE_8": "Template 8",
    "BACKGROUND_DROPDOWN_TEMPLATE_9": "Template 9",
    "BAD_INPUT_INVALID_PRODUCTID": "Invalid product ID",
    "BAD_INPUT_INVALID_SEATS": "Invalid seats",
    "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER": "Entities involved in the banner notification",
    "BILL_ACTION_DESC_FULL_CHARGE": "Full charge for whole month.",
    "BILL_ACTION_DESC_NO_REFUND": "No refund will be provided",
    "BILL_ACTION_DESC_PRORATE": "Proration will be applied in invoice.",
    "BILL_ACTION_NAME_FULL_CHARGE": "Full Charge",
    "BILL_ACTION_NAME_NO_REFUND": "No Refund",
    "BILL_ACTION_NAME_PRORATE": "Prorate",
    "BILL_CYCLE_DESC_ANNUAL": "Annual",
    "BILL_CYCLE_DESC_MONTHLY": "Monthly",
    "BILL_CYCLE_DESC_ONETIME": "One time",
    "BILL_CYCLE_DESC_TRIENNIAL": "Triennial",
    "BILL_CYCLE_NAME_ANNUAL": "Annual",
    "BILL_CYCLE_NAME_MONTHLY": "Monthly",
    "BILL_CYCLE_NAME_TRIENNIAL": "Triennial",
    "BILL_FROM": "Bill From",
    "BILL_TO": "Bill To",
    "BILLING_CUSTOMER_LINK_REMOVAL_FAIL": "Billing customer link removal failed.",
    "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS": "Billing customer link removed successfully.",
    "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS": "Billing cycle change of product is not allowed as license assignment is in progress for the product or one of its addons. Please try later.",
    "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME": "Current product name",
    "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY": "Product billing cycle updated successfully",
    "BILLING_CYCLE_SEARCH_DEFAULT_OPTION": "Select",
    "BILLING_CYCLE_TOOLTIP_BUNDLE": "Billing cycle",
    "BILLING_FORECAST_REPORT": "Billing Forecast",
    "BILLING_PERIOD": "Billing Period",
    "BILLING_PERIOD_SEARCH_DEFAULT_OPTION": "Select",
    "BILLING_TYPE_DESC_MARK_UP": "Markup",
    "BILLING_TYPE_DESC_METERED_BILLING": "Metered billing",
    "BILLING_TYPE_DESC_PERCENTAGE": "Percentage",
    "BILLING_TYPE_DESC_PRICE": "Price",
    "BILLING_TYPE_DESC_UNIT": "Unit",
    "BILLING_TYPE_TOOLTIP": "Billing type",
    "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES": "Azure estimates",
    "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION": "Azure subscription",
    "BREADCRUM_BUTTON_TEXT_LICENSES": "Licenses",
    "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS": "Azure usage",
    "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS": "Customer Products",
    "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL": "Pending Payment Status",
    "BTN_AZURE_BILLING_COMPARISON_REPORT": "Azure billing comparison report",
    "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP": "Delete record",
    "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP": "Download invoice zip",
    "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP": "View invoice details",
    "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS": "Back to payments",
    "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY": "Back to history",
    "BTN_TEXT_BEGIN_UPLOAD": "Upload",
    "BTN_TEXT_BEGIN_VALIDATE_DATA": "Validate",
    "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS": "View logs",
    "BTN_TEXT_CANCEL_ORDER": "Cancel order",
    "BTN_TEXT_COMMENT": "Comment",
    "BTN_TEXT_COPY_TO_CLIPBOARD": "Copy to clipboard",
    "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS": "Download all vouchers",
    "BTN_TEXT_FIX": "Fix",
    "BTN_TEXT_IGNORE": "Ignore",
    "BTN_TEXT_INCLUDE": "Include",
    "BTN_TEXT_MAP_PROMOTIONAL_OFFER": "Map promotion",
    "BTN_TEXT_MAPPING_OF_PRODUCTS": "Product Mapping",
    "BTN_TEXT_OPEN_IN_EMAIL": "Open in email",
    "BTN_TEXT_PROCEED": "Proceed",
    "BTN_TEXT_QUICK_VIEW": "Quick view",
    "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT": "Multiple  payments",
    "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT": " Single payment",
    "BTN_TEXT_REMOVE": "Remove",
    "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER": "Remove promotion",
    "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP": "Request a reseller relationship",
    "BTN_TEXT_SHOW_FILTERS": "Show filters",
    "BTN_TEXT_SIGN_IN": "Sign in",
    "BTN_TEXT_SKIP": "Skip",
    "BTN_TEXT_TAKE_ON": "Include",
    "BTN_TEXT_UPLOAD_TO_AUTOTASK": "Manage Autotask Uploads",
    "BTN_TEXT_UPLOAD_TO_CONNECTWISE": "Manage Connectwise Uploads",
    "BTN_TEXT_VIEW_PRODUCT": "View product",
    "BULK_ADD_ENTITLEMENT_DESCRIPTION": "Last created entitlement name is ",
    "BULK_ADD_TENANT_DESCRIPTION": "Last created tenant name is ",
    "BULK_ADD_TENANTS_BATCH_ID": "BatchId",
    "BULK_DATA_MAPPING_CSV_UPLOAD": "Upload mapped sheet",
    "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY": "Back to revenue & cost summary",
    "BULK_INVOICE_DOWNLOAD_CANCEL": "Cancel",
    "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE": "Select atleast one customer",
    "BULK_INVOICE_DOWNLOAD_HEADER": "Download bulk invoices",
    "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS": "Emails",
    "BULK_INVOICE_DOWNLOAD_MODAL_HEADER": "Get all invoices via email",
    "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION": "Enter email address to whom you want to send as email, enter with comma(,) or semicolon(;) and click on Submit to receive a zip file containing list of invoices PDF's for the selected Billing Period",
    "BULK_INVOICE_DOWNLOAD_NOTIFICATION": "We'll notify to the emails provided once the zip file is ready for download! It usually takes an hour to complete",
    "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS": "Select Plan",
    "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE": "Close",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL": "Bulk Invoice is deleted Successfully.",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER": "Invoice Number",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER": "Owner",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS": "Status",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER": "User",
    "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION": "Select Plan",
    "BULK_INVOICE_DOWNLOAD_SUBMIT": "Submit",
    "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE": "Are you sure you want to delete this record?",
    "BULK_INVOICE_PROGRESS_POPUP_HEADER": "Invoice download status",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS": "Actions",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT": "Completed at",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS": "Emails",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR": "Initiator",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE": "Link available till date",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE": "Start date",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS": "Status",
    "BULK_ONBOARD_BATCH_ID": "Bulk Onboard BatchId",
    "BULK_ONBOARD_CUSTOMERS_BATCH_ID": "Bulk Onboard BatchId",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS": "Download Mapping Details",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH": "Download Subscription Mapping",
    "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS": "Mapped <b>{{ TotalSubscriptionsOnboarded }}</b> out of <b>{{ TotalSubscriptionsAtProvider }}</b> subscriptions from Partner Center successfully.",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE": "Do you want to remove all selected customers?",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE": "Do you want to select all customers?",
    "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS": "Selected <b>{{selectedCustomers}}</b> out of <b>{{totalCustomers}}</b> customers for onboarding ",
    "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS": "Onboard Exisitng Resellers",
    "BULK_ONBOARD_REQUEST_ACCEPTED": "Enqueued Request for Bulk Onboard of Customers",
    "BULK_ONBOARD_RESELLERS_BATCH_ID": "BatchId",
    "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE": "The batch is completed successfully. You will be redirected to resellers.",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST": "Select from list",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV": "Upload CSV",
    "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE": "Please select atleast 1 reseller to onboard.",
    "BULK_ONBOARD_RESELLERS_INFO_HEADER": "Onboard results summary",
    "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT": "Total Selected: {{totalResellersInBatch}}, Total Onboarded: {{resellersOnboarded}}, Total Failed: {{resellersFailedToOnboard}}",
    "BULK_ONBOARD_RESELLERS_LBL_OPTION": "How do you want to onboard?",
    "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE": "Do you want to remove all selected resellers?",
    "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE": "Do you want to select all resellers?",
    "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED": "Request for Bulk Onboard Resellers is queued.<br /> You will be redirected to status screen that shows the progress.",
    "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS": "Selected <b>{{selectedResellers}}</b> out of <b>{{totalResellers}}</b> resellers for onboarding",
    "BULK_ONBOARD_RESELLERS_STATUS_ERRORS": "Errors",
    "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID": "MPN ID - ",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH": "ResellersFailed",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH": "ResellersSucceeded",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH": "TotalResellersSelected",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER": "It looks like the MPN ID detail is missing for the reseller. Please contact the Partner to get the detail updated.",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE": "Onboarding is not supported !",
    "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED": "Bulk Onboarding MicrosoftNonCSP customers is not supported.",
    "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS": "Back to resellers",
    "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT": "Bulk Onboard Resellers",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER": "Provider",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS": "Please choose the solution provider from the dropdown list",
    "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT": "Status",
    "BULK_ONBOARDING_STATUS_INFO_HEADER": "Tip! Steps to fix partial onboarding",
    "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT": "If there are subscription mapping issues reported by the system during the onboarding of a customer, click the hyperlink to download and review the details. Fix your plans and work with Support in synchronizing the data into C3.",
    "BULK_PURCHASED_PRODUCT_MAPPING": "Bulk Purchase Product Mapping",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1": "Download the catalogue to get customers and their IDs.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2": "Fill out the currency code, payment amount and payment date for the corresponding C3Id.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3": "Ensure that the data abides by the following rules:",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A": "<strong>C3Id -Text–</strong> C3 Customer Id (or the C3 Reseller Id) from the catalogue against which customer the payment wants to be recorded",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B": "<strong>CurrencyCode - Text (ISO standard currency code.) –</strong> In which currency the payment wants to be recorded",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C": "<strong>PaymentAmount - Decimal –</strong> Amount to record",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D": "<strong>Remarks - Text –</strong> Comments (Optional)",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E": "<strong>PaymentDate - Date (YYYY-MM-DD) –</strong> On which date the payment has to be recorded! Only Past dates!",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4": "Click <strong>Select</strong> and browse to the file.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5": "Click <strong>Validate catalogue</strong> for the system to validate the input.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6": "Review the errors in the table after validation is complete.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7": "Fix the errors in the file and upload again.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8": "If there are no validation errors in the input the, <strong> Import payments</strong> button gets enabled. Click the button to submit your changes.",
    "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE": "Done",
    "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS": "Selected Customers",
    "BULK_UPLOAD_CUSTOMER_NOT_SELECTED": "Choose at least one customer",
    "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION": "Do you want to remove the customer?",
    "BULK_UPLOAD_CUSTOMER_SUCCESS": "Request to add multiple customers is accepted",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA": "An error occurred while parsing the uploaded file",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE": "Import Complete!<br />Total : {{ importProcessedCount }}<br />Errors : {{ importErrorCount }}<br />Success : {{ importSuccessCount }}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE": "Validation Completed!<br />Total : {{ validationProcessedCount }}<br />Errors : {{ validationErrorCount }}<br />Success : {{ validationSuccessCount }}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR": "Error",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS": "Success",
    "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR": "An error occurred while parsing the uploaded file",
    "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT": "Download a sample .csv",
    "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT": "Download Catalogue to get the latest codes and sequence.",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2": "file for reference.",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR": "Error",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS": "Success",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS": "Instructions",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1": "Fill out the code or sequence or both for the desired products and remove the lines that you don't wish to change. Product Code is optional and supports 100 characters. Display Sequence is a number and is optional.<br /><strong>Note:</strong> If you leave the two as blank, the existing code and sequence get erased.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2": "Click <strong class=\"text-uppercase\">Select</strong> and browse to the file.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3": "Click <strong class=\"text-uppercase\">Validate Catalogue</strong> for the system to validate the input.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4": "Review the errors in the table after validation is complete.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5": "Fix the errors in the file and upload again.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6": "If there are no validation errors in the input, <strong class=\"text-uppercase\">Import Catalogue</strong> button gets enabled. Click the button to submit your changes.",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED": "Import Completed!",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED": "Validation Completed!",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE": "Import Complete!<br />Total : {{ importProcessedCount }}<br />Errors : {{ importErrorCount }}<br />Success : {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE": "Validation Completed!<br />Total : {{ validationProcessedCount }}<br />Errors : {{ validationErrorCount }}<br />Success : {{ validationSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT": "Import Catalogue",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE": "Validate Catalogue",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR": "Error",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS": "Success",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select All Customers",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select Department",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR": "An error occurred while parsing the uploaded file",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT": "Download Catalogue to get the latest Product Commissions details.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR": "Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS": "Success",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1": "Fill out the Agent Name, SPCode, Commission Percentage, Commission Start Date, Commission End Date for desired products and remove the lines that you don't wish to change. All these columns are optional. <br /> Agent Name is a text supporting 100 characters. <br />SPCode is a text supporting 100 characters. <br />Commission Percentage is a number that also supports decimals. <br />Commission Start Date is a Date that has to be defined in <strong>{{dateFormat}}</strong> format.<br />Commission End Date is a Date that has to be defined in <strong>{{dateFormat}}</strong> format.<br /><strong>Note:</strong> If you update the existing values to blank, the existing values will get erased once the catalogue is imported.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2": "Click <strong class=\"text-uppercase\">Select</strong> and browse to the file.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3": "Click <strong class=\"text-uppercase\">Validate Catalogue</strong> for the system to validate the input.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4": "Review the errors in the table after validation is complete.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5": "Fix the errors in the file and upload again.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6": "If there are no validation errors in the input, <strong class=\"text-uppercase\">Import Catalogue</strong> button gets enabled. Click the button to submit your changes.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE": "Import Complete!<br />Total : {{ importProcessedCount }}<br />Errors : {{ importErrorCount }}<br />Success : {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE": "Validation Completed!<br />Total : {{ validationProcessedCount }}<br />Errors : {{ validationErrorCount }}<br />Success : {{ validationSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select Site",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR": "Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS": "Success",
    "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION": "Do you want to remove the reseller?",
    "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN": "The following subscriptions do not have equivalent offers in the plan. Please update the plan and try again.",
    "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER": "Unable to find a customer in CSP with id: {{CSPCustomerId}}",
    "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE": "The following offer(s) have been purchased more than once in single level, which is not supported in C3. Please verify the data.",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE": "Remove",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY": "Update quantity",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY": "Category",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT": "Discount",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS": "Add-ons",
    "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE": "Your last update operation seems to have some errors, please contact support team for assistance",
    "BUNDLE_PRODUCTS_PLACEHOLDER": "Bundle products",
    "BUNDLES_BILLING_CYCLE_TOOLTIP": "Billing frequency at which the bundle would be billed",
    "BUNDLES_BILLING_TYPE_TOOLTIP": "Billing category on which the bundle would be billed",
    "BUNDLES_CANNOT_BE_DELETED": "Bundles cannot be deleted since its assigned to a plan",
    "BUNDLES_SALETYPE_TOOLTIP": "Category type for the offer",
    "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS": "Import is in progress",
    "BUSY_MESSAGE_TEXT_PLEASE_WAIT": "Please wait...",
    "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS": "Validation is in progress",
    "BUTTON_ACTIVATE": "Activate",
    "BUTTON_APPLY_COUPON_TEXT": "Apply Coupon",
    "BUTTON_CLOSE": "Close",
    "BUTTON_DEACTIVATE": "Deactivate",
    "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL": "Cancel",
    "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD": "Download",
    "BUTTON_LANG_CN": "Chinese",
    "BUTTON_LANG_DE": "German",
    "BUTTON_LANG_EN": "English",
    "BUTTON_LANG_ES": "Spanish",
    "BUTTON_LANG_FR": "French",
    "BUTTON_LANG_IT": "Italian",
    "BUTTON_LANG_SK": "Slovak",
    "BUTTON_LANG_TH": "Thai",
    "BUTTON_LANG_TR": "Turkish",
    "BUTTON_LANG_TW": "Taiwanese",
    "BUTTON_LANGUAGE": "Language",
    "BUTTON_MANAGE_PRODUCT": "Manage",
    "BUTTON_ONHOLD": "Onhold",
    "BUTTON_PROCESSING": "Processing",
    "BUTTON_RELEASE": "Release",
    "BUTTON_REVOKE": "Revoke",
    "BUTTON_START_TOUR": "Take a tour",
    "BUTTON_TEXT_ACCEPT": "Accept",
    "BUTTON_TEXT_ACTIONS": "Actions",
    "BUTTON_TEXT_ADD": "Add",
    "BUTTON_TEXT_ADD_CURRENCY_CONVERSION": "Add new currency conversion",
    "BUTTON_TEXT_ADD_CUSTOM_POLICY": "add custom policy",
    "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION": "Add new event email notification",
    "BUTTON_TEXT_ADD_SUB_TAX": "Add sub-tax",
    "BUTTON_TEXT_ADD_WITH_ADDONS": "Add with add-ons",
    "BUTTON_TEXT_APPLY": "Apply",
    "BUTTON_TEXT_APPROVE": "Approve",
    "BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "BUTTON_TEXT_BACK_TO_RESELLERS": "Back to resellers",
    "BUTTON_TEXT_CANCEL": "Cancel",
    "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS": "Pending Payment Status",
    "BUTTON_TEXT_DELETE": "Delete",
    "BUTTON_TEXT_DOWNLOAD": "Download",
    "BUTTON_TEXT_DOWNLOAD_CATALOGUE": "Download catalogue",
    "BUTTON_TEXT_DOWNLOAD_CUSTOMER": "Download customers",
    "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY": "Download invoices post log summary",
    "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM": "Download invoice line item",
    "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES": "Download invoice dues",
    "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS": "Download partner offers",
    "BUTTON_TEXT_DOWNLOAD_PLAN": "Download plan",
    "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER": "Download plan offer",
    "BUTTON_TEXT_DOWNLOAD_REPORT": "Download report",
    "BUTTON_TEXT_EDIT": "Edit",
    "BUTTON_TEXT_EXPORT_TO_EXCEL": "Export to excel",
    "BUTTON_TEXT_FILTER": "Filter",
    "BUTTON_TEXT_LOAD_OFFERS": "Load offers",
    "BUTTON_TEXT_OK": "Ok",
    "BUTTON_TEXT_OK_CLOSE": "Ok, close",
    "BUTTON_TEXT_OVERRIDE": "Override",
    "BUTTON_TEXT_PAY_INVOICES": "Pay pending invoices",
    "BUTTON_TEXT_PROCEED_TO_PURCHASE": "Proceed to purchase",
    "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT": "Record advance payment",
    "BUTTON_TEXT_REJECT": "Reject",
    "BUTTON_TEXT_REMOVE": "Remove",
    "BUTTON_TEXT_REMOVE_SUB_TAX": "Remove sub-tax",
    "BUTTON_TEXT_REPORTS": "Reports",
    "BUTTON_TEXT_RESET": "Reset",
    "BUTTON_TEXT_REVERT": "Revert",
    "BUTTON_TEXT_SAVE": "Save",
    "BUTTON_TEXT_SEARCH": "Search",
    "BUTTON_TEXT_SET_OPERATIONAL_ENTITY": "Set operating entity",
    "BUTTON_TEXT_SUBMIT": "Submit",
    "BUTTON_TEXT_UPDATE": "Update",
    "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE": "Upload to Quickbooks online",
    "BUTTON_TEXT_UPLOAD_TO_XERO": "Upload to Xero",
    "BUTTON_TEXT_VIEW_ADDON_PARENTS": "View parents",
    "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS": "View post logs",
    "BUTTON_TEXT_VIEW_OFFERS": "View offers",
    "BUTTON_VIEW_ERRORS": "View errors",
    "BUTTON_VIEW_PRODUCT": "View",
    "BUTTONS_TEXT_ADD_PARTNER_USER_TAG": "Add Tag",
    "C3_CART": "Cart",
    "C3_SHOP_POPUP_PROVIDER_CATEGORY": "Provider category",
    "C3_TEXT_ONHOLD_STATUS": "On hold",
    "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION": "Please enter a reason for cancelling order",
    "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR": "Reason is required to cancel the scheduled order",
    "CANCEL_SCHEDULED_ORDER_SUCCESS": "Order is successfully cancelled",
    "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER": "Cancel order",
    "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR": "Reason is required to cancel the scheduled product",
    "CANCEL_SCHEDULED_PRODUCT_SUCCESS": "Product is successfully cancelled from this order",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE": "Cancelled scheduled renewal due to change in auto renew status",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE": "Cancelled scheduled renewal due to quantity update",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE": "Cancelled scheduled renewal due to rejection of request for renewal",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER": "Cancelled the scheduled renewal as the schedule property is changed from partner center",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE": "Cancelled scheduled renewal due to subscription status change",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE": "Cancelled scheduled renewal due to subscription update",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE": "Cancelled scheduled renewal due to subscription upgrade",
    "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE": "Failed to cancel the scheduled renewal",
    "CANCEL_SCHEDULED_RENEWAL_REASON": "Reason",
    "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER": "Please provide a reason",
    "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR": "This field is required",
    "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE": "Successfully cancelled the scheduled renewal",
    "CANCELLED_REASON": "Cancelled reason",
    "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE": "Cannot delete an older currency conversion definition",
    "CANNOT_INITIATE_PAYMENT": "Cannot initiate a payment due to a pending transaction",
    "CANNOT_LINK_PRODUCT_MESSAGE_INFO": "This product has already been purchased by the customer, It is not possible to link the product.",
    "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP": "Request for reseller relationship",
    "CARD_CARD_DELETE_ADDON": "Delete",
    "CARD_EXCHANGE_ADMIN_CENTER_TEXT": "Exchange Center",
    "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA": "Product usage data",
    "CART_CARD_COMMENT_ACTION": "Comments",
    "CART_CARD_DELETE_PRODUCT": "Delete",
    "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION": "This product is already available in your Cart. Do you still want to continue ?",
    "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING": "Your tenant is not eligible to purchase Azure Plan. Possible reasons - either you already have an Azure Plan and it is not yet synced to C3 or there is an Azure subscription active in Partner Center and it needs an upgrade. Please contact support for further assistance.",
    "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER": "Are you sure you want to change the tenant for the selected offer?",
    "CART_DISABLE_PROMOTION": "Disable Promotion from the product.",
    "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER": "Error occurred while updating the tenant on selected offer. Please contact Support team",
    "CART_GENERIC_ERROR_MESSAGE": "Something went wrong in completing the order. Please check with Partner before you retry",
    "CART_ITEM_EFFECTIVE_PRICE": "Effective price",
    "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT": "Order for Site/Department",
    "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER": "Tenant",
    "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT": "Cart not available to checkout or already checked out!",
    "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED": "You cannot schedule this cart as it is either not available or already scheduled",
    "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT": "Linked Product",
    "CART_PAGE_AUTORELEASE": "Auto Release",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION": " {{discount}}% off",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT": "Discount",
    "CART_PAGE_BUTTON_TEXT_REMOVE": "Remove",
    "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY": "Apply",
    "CART_PAGE_DISCOUNT_INFORMATION": "( {{discount}}% off)",
    "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES": "Consumption types",
    "CART_PAGE_TEMPLATE_ADD_COMMENT": "Add comment",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS": "<span class='font-weight-boldest'>Hi!</span> Your cart is empty. <a ng-click='vm.GotoCart()' class='text-primary' role='button'> Click here to shop. <span class='fa fa-cart-plus'></span> </a>",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS1": "<span class='font-weight-boldest'>Hi!</span> Your cart is empty.",
    "CART_PAGE_TITLE_PRICE": "Estimated Retail Price",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE": "Custom end date",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE": "Select custom end date type",
    "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER": "Select..",
    "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT": "Select..",
    "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE": "Auto release updated successfully",
    "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE": "Error occurred While checking out cart",
    "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE": "Order placed successfully. </br> OrderId : {{orderId}}",
    "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE": "Error occures while processing your request to convert {{productName}} trial offer to subscription",
    "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE": "Request to convert {{productName}} trial offer to subscription sent successfully",
    "CART_PRODUCT_DELETE_CONFIRMATION": "Are you sure you want to delete this item from cart?",
    "CART_PRODUCT_DELETE_ERROR_MESSAGE": "Error occurred While removing item from cart!",
    "CART_PRODUCT_DELETE_SUCCESS_MESSAGE": "Removed the requested item successfully!",
    "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS": "The available promotion ID is valid on promotion.",
    "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE": "Quantity updated successfully",
    "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE": "Error occurred While scheduling the order",
    "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE": "Order has been scheduled successfully.<br/>OrderId is: {{orderId}}",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS": "This product is already purchased {{days}} days ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS": "This product is already purchased {{hours}} hours ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES": "This product is already purchased {{minutes}} minutes ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS": "This product is already ordered {{days}} days ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS": "This product is already ordered {{hours}} hours ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES": "This product is already ordered {{minutes}} minutes ago. Do you still want to continue?",
    "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER": "This trial offer is already purchased.",
    "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION": "On this product, scheduled order is present. Do you still want to continue?",
    "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER": "Updated the tenant against the selected offer successfully!",
    "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE": "Error occurred While updating the instant pay field in cart",
    "CATEGORY_DESC_AZURE": "Azure",
    "CATEGORY_DESC_AZURE_PLAN": "Azure plan",
    "CATEGORY_DESC_BUNDLES": "Bundles",
    "CATEGORY_DESC_CUSTOM": "Custom",
    "CATEGORY_DESC_CUSTOM_TRIAL": "Custom trial",
    "CATEGORY_DESC_DISTRIBUTOR_OFFERS": "Distributor Offers",
    "CATEGORY_DESC_LICENSE_SUPPORTED": "License supported",
    "CATEGORY_DESC_NCE": "NCE",
    "CATEGORY_DESC_NON_CSP_AZURE": "Non-CSP Azure",
    "CATEGORY_DESC_ONE_TIME": "One time",
    "CATEGORY_DESC_ONLINCE_SERVICES": "Online services",
    "CATEGORY_DESC_ONLINE_SERVICES_NCE": "OnlineServicesNCE",
    "CATEGORY_DESC_PERPETUAL_SOFTWARE": "Perpetual software",
    "CATEGORY_DESC_RESERVED_INSTANCES": "RI",
    "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS": "Software subscriptions",
    "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE": "Perpetual Software",
    "CATEGORY_FILTER_DESC_NCE": "NCE",
    "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM": "Are you sure, do you want to disable this coupon?",
    "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM": "Are you sure, do you want to enable this coupon?",
    "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS": "Source Plan",
    "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS": "Target Plan",
    "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN": "Unassign plans after changing to new plan ",
    "CHANGE_PLAN_FAILURE_MESSAGE_TEXT": "Error occured while changing plan.",
    "CHANGE_PLAN_HELP": "Help ",
    "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE": "{{firstProduct}} is not mapped with right child product {{secondProduct}}",
    "CHANGE_PLAN_INSTRUCTIONS_1": "Follow these instructions to move products of the customer from an existing plan to a newly selected plan.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1": "<b>Source Plan </b>– Lists the plans associated with the purchased products of the customer. Select one or more plans if you want to operate on the related products.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2": "<b>Target Plan </b> – Select the new pricing plan.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3": "<b>Unassign plans after changing to new plan </b>– This comes in handy as the system would disassociate the customer from an existing plan if there are no products left in the source plan. Helps resolve confusion to the customer users in the shop page.",
    "CHANGE_PLAN_INSTRUCTIONS_2": "The page refreshes to list down the purchased products from the source plan on the left and the option to map to an equivalent from the target plan, on the right.",
    "CHANGE_PLAN_INSTRUCTIONS_3": "Map the products and submit the request for the system to execute the change.",
    "CHANGE_PLAN_INSTRUCTIONS_4": "Audit logs help you with the details on the activity for reference at a later point in time.",
    "CHANGE_PLAN_INSTRUCTIONS_5": "<b>Note: Price change due to changing the plan would come into effect immediately.</b>",
    "CHANGE_PLAN_INSTRUCTIONS_HEADING": "Instructions to switch to plan",
    "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE": "If {{firstProduct}} has to be mapped, you should also map {{secondProduct}}",
    "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE": "Shared quantity should match for {{firstProduct}}",
    "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS": "Select the source plans you want to change",
    "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS": "Select target plan",
    "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN": "No matched products in target Plan",
    "CHANGE_PLAN_STATUS_FAILED": "Failed",
    "CHANGE_PLAN_STATUS_SUCCESS": "Success",
    "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT": "Plan changed successfully.",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS": "Switch to a product in the target plan",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS": "Purchased products",
    "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE": "Biiling cycle ",
    "CHANGE_PLAN_TABLE_TEXT_CATEGORY": "Category ",
    "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT": "Linked product ",
    "CHANGE_PLAN_TABLE_TEXT_OFFER": "Offer :",
    "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME": "Plan ",
    "CHANGE_PLAN_TABLE_TEXT_PRICE": "Price ",
    "CHANGE_PLAN_TABLE_TEXT_PROVIDER": "Provider ",
    "CHANGE_PLAN_TABLE_TEXT_QUANTITY": "Quantity ",
    "CHANGE_PLAN_TABLE_TEXT_VALIDITY": "Validity ",
    "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS": "Selected plans have different currency configuration.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE": "Plan product is in-provision state.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT": "Please select product.",
    "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION": "Error Occurred while Updating Purchased product status : ",
    "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM": "Are you sure, do you want to discontinue this offer",
    "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM": "Are you sure, do you want to disable this plan",
    "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM": "Are you sure, do you want to enable this plan",
    "charity": "Charity",
    "Charity": "Charity",
    "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS": "Enable all promotions",
    "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT": "Choose a billing-cycle",
    "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT": "Choose a billing-type",
    "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT": "Choose a consumption-type",
    "CHOOSE_AN_OPTION": "Choose an option",
    "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT": "Choose Customer",
    "CHOOSE_INVOICE_PDF_LANGUAGE": "Choose invoice PDF language",
    "CHOOSE_INVOICE_PDF_LANGUAGE_DESC": "The invoice PDF will be available in selected language",
    "CHOOSE_ONE_RG": "Choose at least one Resource Group",
    "CHOOSE_RESOURCE_GROUP": "Choose Policy",
    "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION": "Are you sure you want to clear the icon for this product? The product would get the icon by its category when cleared here.",
    "CLICK": "click",
    "CLICK_TEXT_FOR_EXTERNAL_LINK": "Click",
    "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY": "Clone Currency Conversion",
    "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE": "Please select a date",
    "CLONE_PLAN_FAILURE": "Failed to clone plan",
    "CLONE_PLAN_SUCCESS": "Successfully cloned plan",
    "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP": "Not Now",
    "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP": "Not Now",
    "COLLAPSE_ALL_PAYMENTS": "Collapse payments",
    "COMMENT_AUTHOR": "Author",
    "COMMENTS_LATEST_COMMENT": "Latest Comment",
    "COMMISION_REPORT_SEARCH": "Search",
    "COMMISION_REPORTS": "Commission Reports",
    "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS": "All periods",
    "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select all customers",
    "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select department",
    "COMMISSION_REPORT_HEADER_TEXT": "Commission Report",
    "COMMISSION_REPORT_SEARCH_CLOSE_OPTION": "Close",
    "COMMISSION_REPORT_SEARCH_RESET_OPTION": "Reset",
    "COMMISSION_REPORT_SEARCH_SUBMIT": "Search",
    "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select site",
    "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT": "Commission Report",
    "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT": "Earnings Report",
    "COMMISSION_TABLE_HEADER_COMMISION_END_DATE": "Commission end date",
    "COMMISSION_TABLE_HEADER_COMMISION_START_DATE": "Commission start date",
    "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT": "Commission(%)",
    "COMMISSION_TABLE_HEADER_DOMAIN_NAME": "Domain Name",
    "COMMISSION_TABLE_HEADER_ENTITY_ID": "Entity ID",
    "COMMISSION_TABLE_HEADER_ORDERED_DATE": "Ordered Date",
    "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID": "Plan product ID",
    "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME": "Agent",
    "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT": "Commission amount",
    "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME": "Customer",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID": "Provider product ID",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID": "Provider product ID",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID": "Product Subscription ID",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME": "Product Subscription Name",
    "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME": "Purchase Product Name",
    "COMMISSION_TABLE_HEADER_RECORD_ID": "Record ID",
    "COMMISSION_TABLE_HEADER_SP_CODE": "SP Code",
    "COMMON_LOADING_MESSAGE": "Loading ...",
    "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE": "Are you sure, do you want to approve this invoice.",
    "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE": "Are you sure, do you want to finalize this invoice.",
    "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES": "You have unsaved changes. Click Ok to leave without saving or click Cancel to stay on the page",
    "CONFIRMATION_POPUP_MESSAGE": "Are you sure you want to activate this subscription?",
    "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED": "Are you sure you want to cancel the request related to this subscription?",
    "CONFIRMATION_POPUP_TITLE_ACTIVATE": "Activate",
    "CONFIRMATION_POPUP_TITLE_CANCELLED": "Canceled",
    "CONFIRMATION_TEXT_DELETE_ADJUSTMENT": "Do you want to delete this adjustment?",
    "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME": "Do you want to update subscription name ?",
    "CONFIRMATION_TEXT_UPDATE_PO_NUMBER": "Are you sure you want to update the PO Number?",
    "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME": "Are you sure want to update the product name",
    "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME": "  Do you want to update subscription name if shared among child entities in C3?",
    "CONFIRMATION_TEXT_WANT_TO_CANCEL": "Are you sure you want to cancel?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE": "Do you want to continue?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION": "Are you sure you want to continue with the current selection?",
    "CONSENT_CREATION_FAILED": "Consent creation failed",
    "CONSTANT_EMPTY_STRING": "--Select--",
    "CONSTANT_EVERY_DAY": "Every day",
    "CONSTANT_EVERY_MONTH": "Every month",
    "CONSTANT_EVERY_TWO_WEEKS": "Every two weeks",
    "CONSTANT_EVERY_WEEK": "Every week",
    "CONSTANT_FULL_AUTOMATIC_MAPPING": "Automatic mapping (Deprecated)",
    "CONSTANT_FULL_MANUAL_MAPPING": "Manual mapping",
    "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION": "Manual mapping with service creation",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT": "Amount",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT": "Product",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY": "Quantity",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS": "Seat",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS": "Closing seats",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS": "Difference",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS": "Opening seats",
    "CONSUMPTION_TYPES_DESC_CONTRACT": "Contract",
    "CONSUMPTION_TYPES_DESC_QUANTITY": "Quantity",
    "CONSUMPTION_TYPES_DESC_SERVICE": "Service",
    "CONSUMPTION_TYPES_DESC_USAGE": "Usage",
    "CONTACT_CANCEL": "Do you want cancel?",
    "CONTACT_HEADER_TEXT": "Have a question?",
    "CONTACT_HEADER_TEXT_OUR_CONTACTS": "Our Contacts",
    "CONTACT_LABEL_TEXT_EMAIL": "Email",
    "CONTACT_LABEL_TEXT_MESSAGE": "Message",
    "CONTACT_LABEL_TEXT_NAME": "Name",
    "CONTACT_PARAGRAPH_TEXT": "Reach out to us by filling the following form and we shall revert to you shortly.",
    "CONTACT_SENDFEEDBACK_SUCCESS": "Thank you for providing your feedback to us. Our team will get in touch with you shortly.",
    "CONTACTCONTROLLER_SENDFEEDBACK": "Your feedback has been submitted successfully",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD": "Add",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL": "Cancel",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE": "Delete",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT": "Edit",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE": "Save",
    "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER": "Select a billing type",
    "CONTRACT_OFFER_BREADCRUMB": "Contract offer",
    "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP": "Source on which the contract would be created",
    "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT": "Are you sure you want to delete this slab?",
    "CONTRACT_OFFER_SLABDATA_TOOLTIP": "Usage range on which the contract would be applied",
    "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT": "Are you sure you want to deactivate this contract offer?",
    "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK": "Contract service deleted from Autotask",
    "COOKIE_CONSENT_MESSAGE": "The application uses cookies to give you a better experience. By proceeding to access the application, you agree to our use of cookies.",
    "COOKIE_POLICY_BUTTON_TEXT": "Cookie policy",
    "corporate": "Corporate",
    "Corporate": "Corporate",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION": "Reset",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION": "Search",
    "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS": "Customers",
    "COST_SUMMARY_REPORT_FORM_LABEL_PLANS": "Plans",
    "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS": "Resellers",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER": "Select Customers",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN": "Select Plans",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS": "Select Resellers",
    "COST_SUMMARY_REPORT_SEARCH_FILTER": "Filter",
    "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE": "Currency code",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME": "Parent product name",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME": "Plan name",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE": "Provider price",
    "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY": "Quantity",
    "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE": "Sale price",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER": "Cost to customer",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER": "Cost to partner",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON": "Add-on",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE": "Bundle",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT": "Linked",
    "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT": "Cost Summary Report",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE": "Coupon will be applied to all customers",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE": "Coupon will not be applied to customers even though they are tagged with the selected plan",
    "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1": "Selected coupon is a direct coupon(with no plan).",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2": "Customers can have only one direct coupon at a time.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3": "Please note that if any selected customers have existing coupon assignments, they will be removed.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING": "Direct Coupon",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1": "Selected coupon is a plan coupon.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2": "Customers tagged with this plan can only be assigned with this coupon.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING": "Plan Coupon",
    "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS": "Coupon assignment saved successfully",
    "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON": "Coupon",
    "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER": "Customers",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE": "Active",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE": "Inactive",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE": "Active",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE": "Inactive",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT": "Add new coupon assignment",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT": "Edit",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION": "Action",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON": "Assigned on",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY": "Applicable till",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS": "Status",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE": "Coupon code",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION": "Coupon description",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME": "Coupon name",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS": "Status",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE": "Is active",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON": "Please select a valid coupon",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS": "The selected coupon is available to that customer.",
    "COUPON_BUTTON_LABEL_ADD_NEW_COUPON": "Add coupon",
    "COUPON_BUTTON_TEXT_BACK_TO_COUPON": "Back to coupons",
    "COUPON_CAPTION_TEXT_ADD": "Add coupon",
    "COUPON_CAPTION_TEXT_EDIT": "Edit coupon",
    "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS": "Select all offers",
    "COUPON_DETAILS_TOOLTIP_COLLAPSE": "Collapse",
    "COUPON_DETAILS_TOOLTIP_EXPAND": "Expand",
    "COUPON_DISABLE_BUTTON": "Disable the coupon",
    "COUPON_DISCOUNT_TOOLTIP": "Discount percentage to be applied to the coupon",
    "COUPON_DISCOUNT_VALIDATION_MESSAGE": "Please select discount value between 1 to 100.",
    "COUPON_EDIT_BUTTON": "Edit coupon",
    "COUPON_ENABLE_BUTTON": "Enable the coupon",
    "COUPON_HEADER_ACTION": "Action",
    "COUPON_OWNERSHIPS_CATEGORY": "Category",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS": "Apply to all tagged customers",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT": "Selecting this checkox will assign the coupon to all customers who are tagged with the selected plan. However you can manage the assignments in coupon assignments screen.",
    "COUPON_OWNERSHIPS_COUPON_ENTITY": "Applicability",
    "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER": "Promotion available",
    "COUPON_OWNERSHIPS_PLAN": "Plan",
    "COUPON_OWNERSHIPS_PLAN_OFFERS": "Plan Offers",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE": "Effective price",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED": "Is Selected",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME": "Offer name",
    "COUPON_OWNERSHIPS_PROVIDER": " Providers",
    "COUPON_OWNERSHIPS_SALETYPE": "SaleType",
    "COUPON_PLAN_COUPON_OWNERSHIPS": "Applicable to",
    "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE": "You should select at least one plan product.",
    "COUPON_RECORDS_VALIDATION_MESSAGE": "You should select at least one {{entity}}.",
    "COUPON_SAVE_SUCCESS": "Coupon {{coupon}} saved successfully",
    "COUPON_STATUS_FLAG_ACTIVE": "Active",
    "COUPON_STATUS_FLAG_EXPIRED": "Expired",
    "COUPON_STATUS_FLAG_INACTIVE": "Inactive",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE": "Coupon code",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME": "Coupon name",
    "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON": "Expire on",
    "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME": "Plan name",
    "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS": "Applicable to new customers",
    "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS": "Applicable to old customers",
    "COUPON_TABLE_HEADER_TEXT_CODE": "Code",
    "COUPON_TABLE_HEADER_TEXT_CREATED_ON": "Created on",
    "COUPON_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "COUPON_TABLE_HEADER_TEXT_DISCOUNT": "Discount",
    "COUPON_TABLE_HEADER_TEXT_EXPIRESON": "Expires on",
    "COUPON_TABLE_HEADER_TEXT_ISACTIVE": "Is active",
    "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE": "Is percentage",
    "COUPON_TABLE_HEADER_TEXT_ISPUBLIC": "Is public",
    "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT": "Max discount",
    "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS": "Max redemptions",
    "COUPON_TABLE_HEADER_TEXT_MINAMOUNT": "Min amount",
    "COUPON_TABLE_HEADER_TEXT_NAME": "Name",
    "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES": "Recurrences",
    "COUPON_TABLE_HEADER_TEXT_PLANNAME": "Plan name",
    "COUPON_TABLE_HEADER_TEXT_VALIDTILL": "Valid till",
    "COUPON_UPDATED_SUCCESS": "Coupon {{coupon}} updated successfully",
    "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE": "Expires on date should be greater than Valid till date",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT": "Add new coupon assignment",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT": "Coupon assignments",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT": "Edit coupon assignment",
    "COUPONS_EXPIRES_ON_TOOLTIP_TEXT": "Date until when the coupon can be applied at the time of purchase",
    "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS": "Coupon status",
    "COUPONS_TAB_HEADING_TEXT_COUPONS": "Coupons",
    "COUPONS_VALID_TILL_TOOLTIP_TEXT": "Date until when the coupon is considered during billing after it is applied",
    "CPV_CLIPBOARD_SUCCESS_MESSAGE": "Link to initiate the consent experience is copied to your clipboard",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_1": " to copy the link that captures the consent from Partner to grant this application access to Partner Center following the new security model.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_2": "Open the link in an incognito session and complete the consent experience using an account that is a Global Administrator in your (Partner) tenant and has the Admin Agent role assigned to it in the Partner Center.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_3": "Click the Test button to check if the application works with the new security model.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_4": "Click <b>Switch to new security model</b> (enabled after the test is successful) to let the application clear your older credentials and switch to the new security model.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_5": "Click <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copy link to clipboard</a>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1": "Click <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Copy link to clipboard</b></a></b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2": " to grant this application access to the Azure Management APIs, This link will capture your consent as the Customer Admin.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3": "After copying the link, open it in a new incognito session. Then, proceed to provide consent for the customer admin role. This step is crucial for us to move forward with the process.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4": "Click the Test button to check if the application works with the Azure management APIs",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING": "Instructions to switch to new security model",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER": "Instruction to provide admin consent to Azure Management API",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER": "Instruction to provide customer admin consent by the customer user",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1": "Click <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Copy link to clipboard</a></b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2": " to copy the link that captures the consent from Customer admin to grant this application access to Azure management APIs.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3": "Once you’ve copied the link, share it with the customer through their preferred communication channel, such as email. Then, Request their approval for the customer admin role.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4": "Click the Test button to check if the application works with the Azure management APIs.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5": "OR You could inform your customer to log in to the C3 portal -> profile -> Microsoft Under the section provide the necessary consent. Obtaining customer consent is crucial for us to proceed further.",
    "CPV_LOADING_MESSAGE": "Please wait while we process your consent ...",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1": "Thank you for providing your consent. The application can be configured to access Partner Center following the new security guideline.",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2": "Please follow the instructions on Settings -> Partner Center in <b>{{applicationName}}</b>.",
    "CPV_PC_TEST_CONFIRMATION_MESSAGE": "Attempt to access Partner Center using the new security settings is successful.",
    "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS": "Attempt to access the Microsoft Azure management API is successful.",
    "CREADITCARD_FORM_LABEL_CARD_NUMBER": "Card Number",
    "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE": "Credit Card Type ",
    "CREADITCARD_FORM_LABEL_TEXT_CVV": "CVV",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH": "Month",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR": "04 - Apr",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG": "08 - Aug",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC": "12 - Dec",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB": "02 - Feb",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN": "01 - Jan",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL": "07 - Jul",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN": "06 - Jun",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR": "03 - Mar",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY": "05 - May",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV": "11 - Nov",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT": "10 - Oct",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP": "09 - Sep",
    "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD": "Name On Card ",
    "CREADITCARD_FORM_LABEL_TEXT_YEAR": "Year",
    "CREATE_BANNER_NOTIFICATIONS": " Create banner notification",
    "CREATE_COUPON_ASSIGNMENT_FAILURE": "Failed to create coupon assignment",
    "CREATE_COUPON_ASSIGNMENT_SUCCESS": "Created coupon assignment successfully",
    "CREATE_CUSTOMER_EXCEPTION": "Error occurred while creating customer",
    "CREATE_CUSTOMER_USER_ROLE": "Create Customer Users",
    "CREATE_CUSTOMER_USER_ROLE_FAILURE": "Failed to create customer users",
    "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT": "Create Invoice",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER": "Create provider customer under logical reseller",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC": "Allow logical reseller to create provider customer",
    "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE": "The usage offers added to reseller plan will be of billing type <b>Markup</b>. Please define the Markup for usage offers once the plan is created successfully.",
    "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY": "Created Account Manager Successfully",
    "CREATED_SUCCESSFULLY": "is created successfully",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE": "It looks like the MPN ID detail is missing for the reseller. Please contact the Partner to get the detail updated.",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE": "Creating a new Service Provider Tenant is not supported !",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER": "The customer will be created in partner centre under the partner account",
    "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION": "Please enter atleast one tax name and percentage for the cumulative taxes.",
    "CURRENCY_CONVERSION_ADD_SUCCESS": "Currency conversion rule added successfully",
    "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION": "Clone Currency",
    "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION": "Back to currency conversion",
    "CURRENCY_CONVERSION_CAPTION_TEXT_ADD": "Add",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION": "Currency Conversion",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG": "currency conversion submitted successfully",
    "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT": "Edit",
    "CURRENCY_CONVERSION_DELETE_SUCCESS": "Currency conversion rule deleted successfully",
    "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH": "Current billing month",
    "CURRENCY_CONVERSION_LABEL_DEFAULT": "All",
    "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH": "Last billing month",
    "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH": "Next billing month",
    "CURRENCY_CONVERSION_LABEL_PICK_A_DATE": "Select a date",
    "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE": "Conversion rate",
    "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER": "Customer",
    "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY": "Source currency",
    "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY": "Target currency",
    "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD": "Time period",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS": "Actions",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE": "Conversion rate",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER": "Customer",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM": "Effective from",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY": "Source currency",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS": "Status",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY": "Target currency",
    "CURRENCY_CONVERSION_RATE_SUCCESS": "Enter valid conversion rate.",
    "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY": "Currency conversion rule created successfully.",
    "CURRENCY_CONVERSION_UPDATE_SUCCESS": "Currency conversion rule updated successfully",
    "CURRENT_DISTRIBUTOR_PRODUCT_ICON": "Current icon",
    "CURRENT_PARTNER_PRODUCT_ICON": "Current icon",
    "CURRENT_SUBSCRIPTION_DETAILS": "Current subscription details",
    "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR": "Usage details not available yet, please try after some time.",
    "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY": "Quantity",
    "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER": "If you choose to schedule an order for a future date and have specified a custom end date, please be aware that the custom end date will automatically reset to the default after selecting your scheduled order for the future.",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOM_ICON_HELP_TEXT": "Recommended size : 150 x 175. Only files of type jpg, svg, png, gif, bmp are supported",
    "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT": "Add tagged entities",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT": "Body text",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM": "Effective from",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO": "Effective to",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID": "Notification Id",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE": "Template",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE": "Title",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10": "Start with a branding site design modern content creation.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2": "Offering discounts for your online store can be a powerful weapon in to drive loyalty.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3": "Boost marketing &amp; sales product confidence.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4": "Start with a branding site design modern content creation.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5": "Offering discounts for better online a store can loyalty weapon into driving.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6": "Start with a branding site design modern content creation.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7": "Start with a branding site design modern content creation.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8": "Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9": "Start with a branding site design modern content creation.",
    "CUSTOM_NOTIFICATION_BREADCRUMB_ADD": "Create custom notification",
    "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD": "Create custom notification",
    "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION": "Cancel",
    "CUSTOM_NOTIFICATION_CLOSE_OPTION": "Close filters",
    "CUSTOM_NOTIFICATION_CUSTOMER_PLAN": "Plan name",
    "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP": "Enter description",
    "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION": "Select Plans",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP": "Choose effective from date",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP": "Choose effective to date",
    "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER": "End date",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE": "Assign user license",
    "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS": "Change customer product status",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP": "Manage product ownership",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE": "Manage user license",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER": "Onboard customer",
    "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT": "Purchase products",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE": "Quantity decrease",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE": "Quantity increase",
    "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE": "Remove user license",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE": "Update license",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION": "Update subscription",
    "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION": "Error occured while custom notification events",
    "CUSTOM_NOTIFICATION_HEADER_ACTIONS": "Actions",
    "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION": "Description",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM": "Effective from",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO": "Effective to",
    "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES": "Tagged entities",
    "CUSTOM_NOTIFICATION_HEADER_TITLE": "Title",
    "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION": "Description",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM": "Effective from",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO": "Effective to",
    "CUSTOM_NOTIFICATION_LABEL_ENTITY": "Entity",
    "CUSTOM_NOTIFICATION_LABEL_EVENT": "Event",
    "CUSTOM_NOTIFICATION_LABEL_TEMPLATE": "Template",
    "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT": "Title",
    "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT": "Close",
    "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT": "Custom notification",
    "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT": "Submit",
    "CUSTOM_NOTIFICATION_RESET_OPTION": "Reset",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION": "Submit",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE": "Saved custom notification successfully",
    "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION": "Select",
    "CUSTOM_NOTIFICATION_SEARCH_OPTION": "Search",
    "CUSTOM_NOTIFICATION_SELECT": "Select",
    "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP": "Select products",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE": "Billing Cycle",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES": "Consumption Types",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS": "Providers",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION": "Term Duration",
    "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER": "Start date",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE": "Tagged entities",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER": "Tagged entity elements",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS": "Actions",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS": "Elements",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY": "Entity",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT": "Event",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP": "View tagged entities",
    "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP": "Choose template",
    "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30": "This exceeds max length of 30",
    "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED": "This field is required",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1": "Start here",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2": "Join SAP now to get 35% off",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3": "User Confidence",
    "CUSTOM_NOTIFICATION_TITLE_TOOLTIP": "Select title",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION": "Update",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE": "Updated custom notification successfully",
    "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS": "UI Notification",
    "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER": "Is trial-offer?",
    "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT": "Is auto renewable",
    "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY": "Default quantity",
    "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS": "Products for trial offer",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE": "Billing type",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY": "Category",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE": "Consumption type",
    "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION": "Term duration",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY": "Category",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER": "Provider",
    "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS": "Partner offers",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER": "New contract offer",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER": "New partner offer",
    "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS": "Back to partner offers",
    "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY": "Applicability",
    "CUSTOM_OFFERS_CAPTION_TEXT_ADD": "Add contract offers",
    "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS": "Contract Offers",
    "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS": "Partner Offers",
    "CUSTOM_OFFERS_CAPTION_TEXT_EDIT": "Edit",
    "CUSTOM_OFFERS_HELP_TEXT_CONTENT": "Select 'ChargePeriods' as 'Default', 'Billing rule for purchase of seats’ and ‘Billing rule for reduction of seats’ as ‘Prorate’",
    "CUSTOM_OFFERS_HELP_TEXT_HEADER": "Here are some things to keep in mind while adding a Partner Offer to a Composite Offer",
    "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY": "Approval Quantity",
    "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL": "Is auto-renewable?",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type": "Billing type",
    "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING": "Available for bundling?",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY": "Category",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME": "Category",
    "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS": "Billing period type",
    "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE": "Consumption type",
    "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE": "Cost price",
    "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE": "Feed source",
    "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING": "Available for consumption immediately?",
    "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS": "Instructions",
    "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON": "Is the offer an AddOn?",
    "CUSTOM_OFFERS_LABEL_TEXT_NAME": "Name",
    "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION": "Number of days for free cancellation",
    "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID": "Offer Ref Id",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION": "Billing rule for purchase of seats",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION": "Billing rule for reduction of seats",
    "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON": "Icon",
    "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES": "Properties",
    "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER": "Provider",
    "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE": "Sale price",
    "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE": "Sale type",
    "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY": "Validity",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE": "Billing type",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY": "Category",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT": "What you want as the billing period for the subscriptions",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT": "Which Consumption type you want the subscriptions to have?",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE": "Feed source",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION": "Number of maximum days allowed for no cost cancellation.",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT": "Quantity changes are prorated or full charge for the new quantity",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT": "Quantity changes should be prorated or full charge",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER": "Provider",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE": "Sale Type",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA": "Slab",
    "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY": "Validity of the offer.",
    "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT": "Select an image",
    "CUSTOM_OFFERS_SAVE_SUCCESS": "Partner offer {{customoffer}} saved successfully",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS": "Include deleted offers",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS": "Trial offers",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE": "Cost price",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS": "Offer details",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE": "Sale price",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS": "Go to product tags screen",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW": "View",
    "CUSTOM_OFFERS_TEXT_INFO_DELETED": "(Deleted)",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE": "This is the price at which you obtained this component. You can specify the Sale Price for this offer when you include this in a plan.",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE": "This is the price at which you sell this component.",
    "CUSTOM_OFFERS_UPDATE_SUCCESS": "Partner offer {{customoffer}} updated successfully",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH": "Charge start and end dates will start and end dates of a month.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING": "Charge start and end dates will align with MS billing start and end dates.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING": "Charge start and end dates will align with Partner billing start and end dates.",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH": "Align with Calender month",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING": "Align With MS Billing",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING": "Align With Partner Billing",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING": "Align with Provider Billing",
    "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT": "Default (from the date of purchase)",
    "CUSTOM_PASSWORD_POPUP_HEADER_TEXT": "Set new password",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_1": "Are you sure you want to reset the password?",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_2": "A new password will be generated and the user’s current password will no longer work.",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD": "Enter New Password",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD": "Show Password",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD": "Auto-generate password",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD": "Custom password",
    "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD": "Minimum 8 characters. Must contain at least one uppercase character, one lowercase character, one special character, one numeric value",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR": "Please choose a password with at least 8 characters.",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME": "Custom Password should not contain user's first name.",
    "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY": "Policy activated successfully",
    "CUSTOM_POLICY_ADD_TITLE": "Add custom policy ",
    "CUSTOM_POLICY_ASSIGNED_POLICIES": "Assigned policies",
    "CUSTOM_POLICY_ASSIGNED_SUCCESS": "Policy assigned successfully",
    "CUSTOM_POLICY_ASSIGNMENT_ADD": "Assign policy",
    "CUSTOM_POLICY_AZURE_POLICY_ID": "Azure Policy id",
    "CUSTOM_POLICY_CUSTOMER_NAME": "Customer name",
    "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY": "Policy deactivated successfully",
    "CUSTOM_POLICY_DESCRIPTION": "Description",
    "CUSTOM_POLICY_INSTRUCTIONS": "To create a custom policy follow the below steps",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP1": "Provide a name and description for the custom policy ",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP2": "Provide proper definition",
    "CUSTOM_POLICY_LABEL_SCOPE": "Scope",
    "CUSTOM_POLICY_NAME": "Name",
    "CUSTOM_POLICY_POLICY_ASSIGNMENT": "Policy assignment",
    "CUSTOM_POLICY_POLICYDEFINITION": "Definition",
    "CUSTOM_POLICY_RESOURCEGROUPNAME": "Resource group name",
    "CUSTOM_POLICY_REVOKE_SUCCESS": "Policy revoked successfully",
    "CUSTOM_POLICY_SAVE_SUCCESS": "Policy {{policy}} saved successfully",
    "CUSTOM_POLICY_SUBSCRIPTION": "Subscription Name",
    "CUSTOM_POLICY_TITLE": "Custom Policies",
    "CUSTOM_POLICYASSIGNMENT_INSTRUCTION": "For assigning the policy to subscription level please select all Resource Groups.",
    "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY": "Role activated successfully",
    "CUSTOM_ROLE_AZURE_ROLE_ID": "Azure RoleId",
    "CUSTOM_ROLE_CUSTOMER_NAME": "Customer name",
    "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY": "Role deactivated successfully",
    "CUSTOM_ROLE_DESCRIPTION": "Description",
    "CUSTOM_ROLE_INSTRUCTIONS": "To create a custom role follow the below steps",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP1": "Provide a name and description for the custom role ",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP2": "Input only the “Action” and “Not Action” sections for role definition.",
    "CUSTOM_ROLE_NAME": "Name",
    "CUSTOM_ROLE_RESOURCEGROUPNAME": "Resource Group Name",
    "CUSTOM_ROLE_ROLEDEFINITION": "Role Definition",
    "CUSTOM_ROLE_SAVE_SUCCESS": "Role {{role}} saved successfully",
    "CUSTOM_ROLE_SUBSCRIPTION": "Subscription Name",
    "Customer": "Customer",
    "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE": "Is Taxable",
    "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART": "Update cart",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category": "Category",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE": "Per Seed Price",
    "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT": "I / We authorize Ezidebit Pty Ltd ACN 096 902 813 (User ID No 165969, 303909, 301203, 234040, 234072, 428198) to debit my/our account at the Financial Institution identified above through the Bulk Electronic Clearing System (BECS), in accordance with this Direct Debit Request and as per the Ezidebit DDR Service Agreement. <br> I / We authorize these payments to be debited at intervals and amounts as directed by BeCloudSmart as per the Terms and Conditions of the BeCloudSmart agreement and subsequent agreements. ",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT": "Agreement",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT": "Ezidebit Agreement",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT": "Microsoft Customer Agreement",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER": "Microsoft Cloud Reseller Agreement",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER": "Partner terms of use",
    "CUSTOMER_AGREEMENT_HYPERLINK_TEXT": "Read Ezidebit Agreement",
    "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT": "Before using the system, please read the",
    "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED": "Payment recorded successfully.",
    "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS": "The process of onboarding customer's billing details is in progress",
    "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD": "Proceed to onboard",
    "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS": "Customer billing details on-boarded successfully.",
    "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR": "Customer already exists in the billing system",
    "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE": "<p/><p>Found a match on Id : <strong> {{billingProviderReferenceID}} </strong> in Billing System.<br/><span>Billing Address on file is <strong> {{formattedAddress}} </strong>.</span> <br/>Please impersonate and use the <strong>Profile</strong> option to change this detail after the customer billing details onboarded successfully.",
    "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING": "Monthly Turnover...",
    "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR": "Error",
    "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS": "InProgress",
    "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS": "Success",
    "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT": "Read More",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT": "Reloading Customer Consent",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT": "Reloading grid report",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES": "Syncing Provider Customer Profiles",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT": "Initiating Reload",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS": "Invoice Line Items",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING": "Invoice Line Items...",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS": "Invoice Line Items With Commissions",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING": "Invoice Line Items With Commissions...",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY": "Invoice Summary",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING": "Invoice Summary...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT": "Profitability Report",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT": "Profitability Report by Product",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING": "Profitability Report by Product...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER": "Profitability Report by Reseller",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT": "Profitability Report by Tenant",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING": "Profitability Report by Tenant",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING": "Profitability Report...",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS": "Tax Line Items",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING": "Tax Line Items...",
    "CUSTOMER_BUTTON_TAX_SUMMARY": "Tax Summary",
    "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING": "Tax Summary...",
    "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT": "Consent Report",
    "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT": "Export Reconciliation Report",
    "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE": "Export user license",
    "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT": "Reload Customer Consent",
    "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES": "Sync Provider Customer Profiles",
    "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT": "Reload Reconciliation Report",
    "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT": "Terms & Conditions Report",
    "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT": "Transaction Amount Limit Report",
    "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE": "New purchases are disabled through the platform. Please contact your account manager to place an order.",
    "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE": "Get in touch with your Account Manager to purchase new product",
    "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE": "New purchases and license count changes are currently on hold. Kindly contact your account manager for more information.",
    "CUSTOMER_CART_ADDON_POPUP_REMOVE": "Remove",
    "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART": "Customer Cart",
    "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING": "Continue shopping",
    "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY": "I'm ready to pay",
    "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW": "Pay now",
    "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER": "Place order",
    "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT": "Type a message",
    "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES": "Check custom end date on cart line items, before proceeding to purchase.",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH": "Align end date with calendar month",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT": "Default",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS": "End date from existing subscriptions",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER": "End date from existing subscriptions",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID": "Subscription ID",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription Name",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY": "Validity",
    "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT": "Promotion Applied",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE": "Amount payable:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY": "Currency: ",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME": "Tax name:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST": "Total cost:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT": "Total count: ",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT": "Total discount:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES": "Total tax:",
    "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS": "Order Summary",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART": "There is already a contract offer in the cart. Please remove the existing contract offer to add a new one.",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS": "You already have a contract offer purchased. Please remove the existing contract offer to purchased a new one.",
    "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD": "An order for this product is already sent to provider. Kindly contact your account manager for more information",
    "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE": "This item requires approval for fulfillment from the Partner, after the order is placed. Number of seats being purchased exceeds the allowed limit of {{SeatLimit}}.",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER": "This item requires approval for fulfillment from the Partner after the order is placed.",
    "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT": "Select a Site/Department",
    "CUSTOMER_CART_SUP_TEXT_ADD_ON": "ADD-ON",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY": "Your tenant is not eligible to purchase Azure. Possible reasons - you already have an Azure Plan and it is not yet synced to C3.",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY": "Your tenant is not eligible to purchase Azure Plan. Possible reasons - either you already have an Azure Plan and it is not yet synced to C3 or there is an Azure subscription active in Partner Center and it needs an upgrade or you have more than one Azure plan cart lineitem under same tenant.",
    "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION": "This order requires approval for fulfilment from the Partner, after the order is placed. Transaction amount exceeds the allowed limit of <b> {{TransactionAmountLimit}}</b>.",
    "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT": "Type a message",
    "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART": "Show tax in customer cart",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC": "Allows the user to view unpaid dues until the current invoice",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP": "Show unpaid dues in invoice",
    "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY": "Operational Entity",
    "CUSTOMER_CONSENT_BTN_TEXT_ADD": "Add",
    "CUSTOMER_CONSENT_BTN_TEXT_UPDATE": "Update",
    "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT": "Customer Consent",
    "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL": "Agreement URL",
    "CUSTOMER_CONSENT_HELP_TEXT": "Please specify the details of the individual at the customer's organization who accepted the <a (click)='CustomerConsentClick()'>Microsoft Customer Agreement.</a>",
    "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS": "Email Address",
    "CUSTOMER_CONSENT_LABELS_FIRST_NAME": "First Name",
    "CUSTOMER_CONSENT_LABELS_LAST_NAME": "Last Name",
    "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER": "Phone number",
    "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT": "Click <a id='customerConsentLink' href='#' ng-click='vm.MicrosoftCloudAgreementClicked()'> here </a>to read Microsoft Customer Agreement",
    "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT": "Microsoft Customer Agreement",
    "CUSTOMER_CONSENT_TEXT_DATE": "Date",
    "CUSTOMER_CONSENT_TEXT_EMAIL": "Email",
    "CUSTOMER_CONSENT_TEXT_IP": "IP",
    "CUSTOMER_CONSENT_TEXT_TIME": "Time",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION": "Please go to profile menu and update the payment profile",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE": "Please fill the payment profile",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT": "Quick link to Azure Portal",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE": "Azure Portal",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT": "Number of products purchased so far",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE": "Products",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT": "Number of users having access to your information",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE": "Users",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT": "Alert on a pending invoice",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE": "Invoice",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT": "Quick link to Office 365 portal",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE": "Office Portal",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT": "Quick link to Office 365 Usage Reports",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE": "Office 365 Usage Reports",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER": "Top 10 SKUs",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT": "Product counts by SKU",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE": "Counts by SKU",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER": "Filter Range",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR": "6 Months",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER": "3 Months",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR": "1 Year",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER": "Purchase of Seats",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT": "Purchase of Seats",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE": "Purchase of Seats",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT": "Seats Purchased",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT": "Customer's Seats",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE": "Customer Seats",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT": "Terms and conditions acceptance log",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE": "Terms and conditions",
    "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS": "Seats",
    "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS": "Invoice line item deleted successfully",
    "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT": "Add Department",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION": "Department deleted successfully",
    "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT": "Edit department",
    "CUSTOMER_DEPARTMENT_HEADER_TEXT": "Departments",
    "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION": "Department saved successfully",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES": "Assigned sites",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE": "Site",
    "CUSTOMER_EXPORTINVOICE_WARNING": "Invoice for {{customername}} is not generated yet. Please check back after some time.",
    "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS": "Reloading Providers Customer Consent",
    "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES": "Syncing Provider Customer Profiles",
    "CUSTOMER_INVOICE_GENERATED_SUBJECT": "Customer invoice generated text",
    "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES": "Back to Invoices",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM": "Add lineItem",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION": "Cancel",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE": "Save Invoice",
    "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT": "Are you sure you want to undo your changes?",
    "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR": "The charge start date of lineitems must not be greater than charge end date",
    "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE": "Are you sure you want to delete the line item?",
    "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR": "The due date of Invoice must be greater than Invoice Date",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS": "Customer Details",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY": "Invoice Summary",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS": "Line Items",
    "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION": "Taxes will be applied after the invoice is saved.",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD": "Billing Period",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE": "Currency code",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT": "Invoice Amount",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE": "Invoice Date",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE": "Due Date",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER": "Invoice Number",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL": "Sub Total",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS": "Address",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY": "City",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY": "Country",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION": "Select Country",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME": "Customer name",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY": "Operational entity",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE": "State",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP": "Zip",
    "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR": "Please provide atleast one lineitem to Save the invoice",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL": "Cancel",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE": "Save",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE": "End date",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE": "Start date",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE": "Is taxable",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME": "Item name",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT": "Line amount",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE": "Sale type",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX": "Sales tax",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE": "Unit price",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT": "Credit",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES": "Additional Charge",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT": "Adjustment Amount ",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS": "Adjustment Detail",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME": "Provider Name",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME": "Subscription Name",
    "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE": "Back to Invoice",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD": "Add",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT": "Adjustment",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT": "Edit",
    "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES": "Invoices",
    "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY": "Back to revenue and cost summary",
    "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE": "Create invoice",
    "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES": "Invoices",
    "CUSTOMER_LABLE_TEXT_TAG_KEY": "Customer Tag ",
    "CUSTOMER_LABLE_TEXT_TAG_VALUES": "Value",
    "CUSTOMER_LICENSE_REPORT_NOTIFCATION": "Customer License report is sent to the respective mail",
    "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE": "Filters",
    "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT": "Manage Product",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN": "Back to azure plan",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME": "Group name",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET": "Monthly budget",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS": "Thresholds",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS": "Add Azure subscription",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND": "No entitlements found",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE": "Save",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT": "Owner has not been assigned yet",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME": "Name",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER": "Owner",
    "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT": "Roles &amp; Policies content goes here....",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING": "Billing",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS": "Comments",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS": "Entitlements",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE": "Estimate",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS": "Groups",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO": "Info",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER": "Manage Owner",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES": "Operational Entities",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments": "Payments",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES": "Roles & Policies",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING": "Manage renewal",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE": "Usage",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS": "Users",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP": "ResourceGroup",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION": "Subscription",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES": "Submit changes",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT": "Download a sample .csv",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT": "Upload account data for multiple users in .csv format. Download a sample .csv",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD": "file for reference.",
    "CUSTOMER_MONTHLY_TURNOVER_REPORT": "Turnover Report",
    "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT": "Please enter the new name",
    "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT": "Change customer name",
    "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME": "Name",
    "CUSTOMER_NAME_CHANGE_SUCCESS": "Name of the customer changed successfully",
    "CUSTOMER_NAME_UPDATE_FAILURE": "Customer name update failed",
    "CUSTOMER_NAME_UPDATE_SUCCESS": "Customer name update success",
    "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER": "No Account Manager has been currently assigned",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR": "You are not linked to any Microsoft Tenant.",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE": "Purchase of Provider products might fail due to no linked provider tenants",
    "CUSTOMER_ONB_STATUS_DESC_ASSIGNING": "Assigning",
    "CUSTOMER_ONB_STATUS_DESC_CREATED": "Created",
    "CUSTOMER_ONB_STATUS_DESC_DELETED": "Deleted",
    "CUSTOMER_ONB_STATUS_DESC_ERROR": "Error",
    "CUSTOMER_ONB_STATUS_DESC_ONBOARDED": "Onboarded",
    "CUSTOMER_ONBOARDING_BILLANDPAY_": "Lookup in Billing!",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING": "Please follow these steps to get the Billing system ID of a customer",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1": "Log on to Billing System",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2": "Go to Customers",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3": "Click the customer record to open",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4": "Grab the string that is after recordkey",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1": " Grab the string that is after recordkey= in the url",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2": "eg:",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3": "value-to-be-copied",
    "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET": "Log on to Authorize.Net",
    "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET": " Take Profile ID from Customer Information Manager",
    "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT": " Root for Customer Information Manager : Home->Customer Information Manager",
    "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1": " Grab the string from the url",
    "CUSTOMER_ONBOARDING_BILLINGID_LABEL": "Billing system ID",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING": "Bulk customer onboarding",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING": "Customer onboarding",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS": "Collect customer details",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE": "Done",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE": "here",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP": "Lookup!",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER": "Proceed to onboard this customer",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD": "Remove",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP": "Skip",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER": "Try another",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING": "Bulk Customer onboarding",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING": "Customer onboarding",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES": "Map Licenses",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS": "Onboard Success",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE": "Payment Type",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE": "Please select Payment Type",
    "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE": "Onboarding is not complete. Click on 'Proceed to Onboard this Customer' button to proceed further.",
    "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE": "Please provide a valid email address",
    "CUSTOMER_ONBOARDING_EZIDEBIT_": "Lookup in Billing!",
    "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL": "EziDebit Id",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING": "Please follow these steps to get the EziDebit ID of a customer",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1": "Log on to EziDebit",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2": "Go to Payers",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3": "Click the payer record to open",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4": "Grab the string that is after contractId",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1": " Grab the string that is after contractId= in the url",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2": "eg:",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3": "value-to-be-copied",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS": "Bank Details",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS": "Basic Details",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS": "Credit Card Details",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS": "Customer Details",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS": "Customers available to be added from Partner Center",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY": "is created successfully",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY": "onboarded successfully",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS": "Next steps",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS": "Assign plan",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER": "Account Number",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE": "Account Type",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME": "Bank name",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER": "Card Number",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY": "City",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE": "Credit Card Type",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE": "Customer currency code",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID": "Customer  Id",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV": "CVV",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL": "Email",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME": "First Name",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT": "Is Business Account",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE": "Is Product Shareable",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME": "Last Name",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1": "Line 1",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2": "Line 2",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID": "Microsoft Id",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR": "Microsoft unique identifier assigned to an organization.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME": "Middle Name",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH": "Month",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT": "Name On Account",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD": "Name On Card",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE": "Next Billing Date",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE": "NOTE:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT": "Do not update any content in the file other than the user detail as given in the above example.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1": "optional:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2": "If you have the details on the licenses already that are assigned to users against their subscription, you can provide that to us by following these steps:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1": "Fill the information in the format that is available for download from",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10": "Click Users and grant access to this portal to the users in the customer tenant",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11": "Impersonate and validate the subscription detail",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12": "Please fix the errors by re-uploading an updated file with license detail to complete the onboarding process",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2": "Just provide the user email addresses between the square brackets ([]) following this example:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3": "Upload the file and click",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4": "You could skip providing the license detail by clicking on SKIP to complete the onboarding process",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5": "Click Done to go back to customer listing",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6": "Locate the customer that was onboarded just now",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7": "An action button <i class=\"fa fa-star red\"></i> will show up in case there are errors while processing the license detail you uploaded",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8": "Click the button <i class=\"fa fa-star red\"></i> to view the errors",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9": "Please correct the errors and try uploading again",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER": "Organization Registration Number",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER": "Phone number",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN": "Select a plan",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER": "Provider",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY": "Provider Category",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID": "Provider Customer Id",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP": "Please choose the solution provider from the dropdown list",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER": "Routing Number",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE": "State",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS": "Tenants",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE": "Upload File",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT": "Select",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR": "Year",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP": "Zip",
    "CUSTOMER_ONBOARDING_LBL_DELETED": "Deleted",
    "CUSTOMER_ONBOARDING_LBL_DISABLED": "Disabled",
    "CUSTOMER_ONBOARDING_LBL_EXPIRED": "Expired",
    "CUSTOMER_ONBOARDING_LBL_SUSPENDED": "Suspended",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE": "There is no plan assigned to the customer, Please create new plan and assign to the customer then fix the mismatch.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE": "For Reserved Instance Products, sale prices are expressed as percentages. The default value is 0%. If you wish to specify a different value, please adjust the sale price accordingly.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME": "Plan name",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME": "Product name",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE": "Currency code",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE": "Sale price (in percentage)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE": "Sale price (in percentage)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE": "This value will be applied to the Microsoft billing price to generate the invoice accordingly.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE": "Please enter value upto two decimal place.",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS": "Select plan",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER": "Provider",
    "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING": "Proceed to Onboard this Customer",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH": "ACH",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT": "Bank account",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD": "Credit card",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE": "Manual",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO": "No",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES": "Yes",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1": "Look up customer and map subscriptions",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2": "Map Licenses",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3": "Complete",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4": "Complete",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO": "logo",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION": "Actions",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE": "Billing cycle : ",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL": "Billing email",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY": "Billing system quantity",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME": "Company name",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID": "Microsoft ID",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN": "Domain",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE": "Effective price",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME": "Friendly name",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP": "Markup",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS": "Matching products",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY": "Matching products quantity",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE": "Percentage",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE": "Price",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME": "Provider product name",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY": "Provider quantity",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND": "No provider product subscriptions found to map, you can proceed by clicking on Collect Customer Details button.",
    "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM": "CUSTOM",
    "CUSTOMER_ONBOARDING_THANKS_MESSAGE": "You can switch to other payment methods via Profile option. Invoices are sent to you via email by the Partner",
    "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS": "Usage subscriptions loaded into successfully.",
    "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT": "Loading....",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE": "Disable",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT": "Edit",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE": "Enable",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE": "Manage",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES": "Addresses",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL": "Email",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE": "Entity type",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME": "First Name",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME": "Last Name",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME": "Name",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE": "Role",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE": "Profile",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS": "Users",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER": "Add New User",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES": "Billing Address",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER": "Edit User",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES": "Shipping Address",
    "CUSTOMER_OPTION_ALL_PERIODS": "All Periods",
    "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT": "Terms and conditions.",
    "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT": "Please select the NCE terms and conditions checkbox to order Microsoft NCE products.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT": "Please select the terms and conditions checkbox to order Microsoft products.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT": "NCE terms and conditions.",
    "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT": "The amount payable should be the sum of the product and total tax",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT": "Retry payment",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE": "View invoice",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS": "View products",
    "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT": "Back to Orders",
    "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "CUSTOMER_ORDER_SUP_TEXT_ADD_ON": "ADD-ON",
    "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT": "Filters",
    "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE": "New purchase",
    "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE": "Quantity update",
    "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE": "Status change",
    "CUSTOMER_ORDERS_BREADCRUMB_BUTTON": "Customer Orders",
    "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS": "View details",
    "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT": "Type a message",
    "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS": "No products found!",
    "CUSTOMER_ORDERS_LABEL_TEXT_STATUS": "Status",
    "CUSTOMER_ORDERS_PAGE_HEADER_TEXT": "Customer Orders",
    "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT": "Onhold",
    "CUSTOMER_ORDERS_TABLE_TITLE_ACTION": "Actions",
    "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION": "Operation",
    "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME": "Department name",
    "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION": "Operation",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID": "Order ID",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE": "To Date",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE": "From Date",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION": "Order's operation",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS": "Order's status",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY": "Ordered by",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON": "Ordered on",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM": "Orders from",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO": "Orders To",
    "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME": "Site name",
    "CUSTOMER_ORDERS_TABLE_TITLE_STATUS": "Status",
    "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED": "Last refreshed",
    "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT": "Read More",
    "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS": "Back to customer payments",
    "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR": "Pending Invoices for",
    "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST": "Back to list",
    "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT": "Current Coupon Code",
    "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE": "<strong>Error!</strong> Customer can't be moved to <strong>{{targetPlanName}}</strong> from <strong>{{PlanName}}</strong> as the target plan doesn’t have offers that match the currently active subscriptions.",
    "CUSTOMER_PLAN_CHANGE_INFO": "Plan change is not supported yet if there is at least one active subscription against a composite offer in the current plan.",
    "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN": "Current Plan",
    "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS": "Customer Subscriptions",
    "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE": "Effective price",
    "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND": "Match Found",
    "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME": "Subscription Name",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME": "Target Name",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE": "Target Price",
    "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE": "This coupon already assigned to the customer.",
    "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS": "Plans",
    "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE": "<strong>Success!</strong> Customer can be moved to <strong>{{targetPlanName}}</strong> from <strong>{{PlanName}}</strong>. Click <strong>Submit</strong> to continue.",
    "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT": "Target Plan",
    "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT": "Use current coupon code",
    "CUSTOMER_PLAN_SUBSCRIPT_DELETED": "(Deleted)",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION": "Plan description",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME": "Plan name",
    "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE": "Close",
    "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT": "Addons",
    "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT": "Change",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION": "Reactivate subscription",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS": "Reactivate subscription with addons",
    "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION": "Suspend Subscription",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES": "Submit changes",
    "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS": "Customer Products",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE": "Bundle",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY": "Operational Entity",
    "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS": "Add more vouchers",
    "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS": "Submit",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE": "<b>Continue subscription</b>  <br/> Are you sure that you want to continue the <b>{{productName}}</b> subscription after the trial?",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT": "The trial {{productName}} offer will transition into a subscription once the provider’s effective end date is reached. ",
    "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION": "Are you sure you want to change billing cycle from {{billingCycle}} to {{c3billingCycle}} ?<br> After invoice generation billing cycle can't revert.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT": "<b>Cancel subscription</b> <br/> Are you sure you want to cancel your <b>{{productName}}</b> trial subscription?",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT": "Error occurred while canceling the {{productName}} subscription.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT": "Cancelled trial offer for {{productName}} successfully!",
    "CUSTOMER_PRODUCT_DISCOUNT_TEXT": "off",
    "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE": "Ignore",
    "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS": "Error Details",
    "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT": "Close",
    "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS": "Customer Products",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE": "Please enter comma separated values.",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT": "Instructions",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT": "Perpetual",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT": "Perpetual software",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT": "Software subscriptions",
    "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT": "Type a message",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT": "Confirm name update in C3",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT": "Are you sure you want to change the billing cycle of this product to {{targetBillingCycle}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT": "Are you sure you want to change the Offer of this product to {{targetOffer}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT": "Are you sure you want to change the scope of this product to {{targetScope}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT": "Are you sure you want to change the term duration  of this product to {{targetTermDuration}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT": "Are you sure you want to reactivate {{productName}} {{withAddons ? 'with addons' : ''}} ?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT": "Are you sure you want to suspend {{productName}}?",
    "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER": "Parent subscription is missing for {{Name}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY": "Changed the name successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY": "Updated the PO Number successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY": "Changed the billing cycle of {{productName}} to {{targetBillingCycle}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO": "Invalid product quantity",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO": "can't update the quantity to zero for already purchased product",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY": "Request for updating subscription has been submitted successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY": "Request to reactivate {{productName}} {{withAddons ? 'with addons' : ''}} has been submitted successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY": "Reactivated {{productName}} successfully {{withAddons ? 'with addons' : ''}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP": "Released ownership successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY": "Request to suspend product, {{productName}} has been submitted successfully",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY": "Suspended {{productName}} successfully",
    "CUSTOMER_PRODUCT_NCE_AUTO_MANAGE_LABEL": "",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL": "Auto-renew",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL": "Renews on",
    "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT": "Cancellation allowed until",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION": "Changing the billing cycle will update all the subscriptions that are part of the \"order\". This will create a new annual term for all subscriptions at the current price based on the price list. The below subscriptions will be updated to \"Annual\" billing. To complete this request please click \"OK\" and then Submit.",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL": "Change Billing Cycle",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION": "<b>Cancel subscription</b> </br> <br/> <b> Note: </b> Your 7-day cancellation window has closed. We are unable to cancel your subscription.</br>If you want to cancel this subscription in next billing cycle update the Auto-renew status to false.",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE": "Cancel subscription",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS": "<b>Set Auto Renew</b> </br> <br/> <b> Note: </b> We are unable to change the auto renew status.",
    "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL": "Expires On",
    "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE": "Manage billing frequency",
    "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION": "Pause customer's access to service",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION": "Resuming a suspended subscription restores customer’s access to service. It will have no impact on your invoice. You will continue to be billed as normal. If you had auto renew on and changes scheduled before pausing access, you can turn them on again.",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE": "Resume customer’s access to service",
    "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON": "Submit Changes",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON": "Cancel Subscription",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT": "<b>Cancel subscription</b> <br/> Are you sure that you want to cancel {{productName}} ? <p style='text-align:left;' > To receive a refund, you must cancel subscriptions within 7 calendar days of purchase or renewal.<br/> <b>Please Note:</b> The customer will lose access to this subscription immediately upon cancellation and access can’t be restored after cancellation.  If you cancel the subscription within 7 calendar days, you'll be refunded for any time you've not used. A credit will appear in your next monthly invoice. <a target='_blank' href=\"{{cancelNewCommerceSubscriptionGuidLineURL}}\"> Learn more.</a> </p>",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT": "Cancelled successfully!",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT": "<b>Set Auto Renew</b> <br/> Are you sure that you want to {{autoRenewStatus}} auto renew status for  {{productName}} ?",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL": "Expires",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT": "Auto-renew status updated successfully for {{productName}}",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT": "Updated Successfully !",
    "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT": "Are you sure you want to suspend {{productName}}? <br/> <b> Note: </b> Although pausing customer access to service immediately blocks customer’s ability to use services, this doesn’t impact your invoice. You’ll continue to be billed as normal. Pausing will turn off and disable auto-renew and will delete any scheduled changes.",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY": "Activity",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS": "Activity status",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE": "Date",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY": "Source quantity",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET": "Upgrade from {{sourceProductName}} to {{targetProductName}}",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY": "Target quantity",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON": "Transition",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE": "Customer Products",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN": "Assign",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS": "Back to basic details",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE": "Save",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES": "Submit changes",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY": "Quantity",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS": "Status",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN": "Quantity cannot be greater than ",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE": "Custom billing cycle",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT": "Discount",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY": "Quantity(Available)",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR": "Quantity for ",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME": "Subscription Name",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE": "Unit price",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION": "Linked Subscription",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN": "Assign",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE": "Save",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES": "Submit changes",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS": "Add-ons",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT": "Discount",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY": "Quantity(Available)",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN": "Quantity cannot be greater than",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR": "Quantity for",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT": "Discount",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT": "<b>Purchase subscription</b> <br/> Are you sure you want to convert your {{productName}}  trial offer to subscription?",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL": "Is auto renewable",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL": "Expires on",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID": "Azure subscription id",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID": "Reservation id",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID": "Reservation order id",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION": "Reservation region",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE": "Scope",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL": "Renews on",
    "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION": "This item requires approval for fulfilment from the Partner, after the order is placed. Number of seats being purchased exceeds the allowed limit of <b> {{SeatLimit}}</b> for <b>{{productName}}</b>.",
    "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES": "Filter entities",
    "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT": "Product sequence",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME": "Product name",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE": "Product sequence",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX": "AddOn ",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX": "Linked ",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT": "Department",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR": "For ",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL": "Are you sure you want to undo your changes?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION": "Are you sure you want to update the product sequence?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATED": "Customer Product sequence updated",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE": "Last update request have some errors Read More...",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT": "Discount",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "CUSTOMER_PRODUCT_STATUS_ACTIVE": "Active",
    "CUSTOMER_PRODUCT_STATUS_CANCELLED": "Cancelled",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL": "Cancel trial",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION": "Continue subscription after trial",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION": "Purchase subscription",
    "CUSTOMER_PRODUCT_STATUS_COMPLETED": "Completed",
    "CUSTOMER_PRODUCT_STATUS_DELETED": "Deleted",
    "CUSTOMER_PRODUCT_STATUS_DISABLED": "Disabled",
    "CUSTOMER_PRODUCT_STATUS_EXPIRED": "Expired",
    "CUSTOMER_PRODUCT_STATUS_FAILED": "Failed",
    "CUSTOMER_PRODUCT_STATUS_INPROVISION": "InProvision",
    "CUSTOMER_PRODUCT_STATUS_PENDING": "Pending",
    "CUSTOMER_PRODUCT_STATUS_SUCCEEDED": "Succeeded",
    "CUSTOMER_PRODUCT_STATUS_SUSPENDED": "Suspended",
    "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT": "Filters",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE": "Billing Cycle",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY": "Category",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE": "Country",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN": "Domain",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE": "Linked product price",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER": "Provider",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION": "Reactivate Subscription",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS": "Reactivate Subscription with Addons",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE": "Scope",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE": "Service Types",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS": "Status",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION": "Suspend Subscription",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION": "Term Duration",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE": "Error occured while processing your request",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE": "{{productName}} offer will be converted to a subscription at the end of the trial period.",
    "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY": "Trial offer Parent",
    "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION": "Term Duration",
    "CUSTOMER_PRODUCTS_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS": "Back to orders",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES": "Include 0 quantities",
    "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE": "Renewal",
    "CUSTOMER_PRODUCTS_IN_ACTIVE": "The subscription is inactive",
    "CUSTOMER_PRODUCTS_LABEL_OWNED_BY": "Owned by",
    "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION": "Linked Subscription",
    "CUSTOMER_PRODUCTS_LIST_STATUS": "Status",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT": "Department",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN": "Domain",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SITE": "Site",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID": "Subscription Id",
    "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT": "Tenant",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON": "Cancel subscription",
    "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY": "Copied voucher to clipboard",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS": "Basic details",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS": "Comments",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS": "Notifications",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP": "Manage ownership",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING": "Users",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE": "Close",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK": "Copy link",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK": "Copy Link",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD": "Download",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS": "Get keys and downloads",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY": "Copied link to clipboard",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE": "Select file type",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE": "Select language",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT": "Select product",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS": "Actions",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE": "File Size",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID": "Id",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY": "Key",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME": "Name",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY": "Quantity",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS": "Entitlements",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE": "Cancellation date",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE": "Renewal date",
    "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT": "Promotion Applied",
    "CUSTOMER_PRODUCTS_ORDERED_ON": "Ordered",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE": "Manage",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING": "Processing",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY": "Apply",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR": "Clear",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE": "Close",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES": "Include child entities",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME": "Domain",
    "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT": "Manage Product",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION": "Actions",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT": "Amount",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON": "Ordered on",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY": "Owned by",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON": "Renews on",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT": "Total amount",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT": "Unit price",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE": "Unit price",
    "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND": "No products found!",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT": "Department",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE": "Site",
    "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL": "This subscription has scheduled changes",
    "CUSTOMER_PRODUCTS_VALIDITY": "Validity",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON": "Assigned to Manager On",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL": "Email",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER": "Phone Number",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME": "Manager Name",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT": "Account Manager Details",
    "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION": "Basic details updated successfully",
    "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS": "Shipping Address",
    "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE": "Profile",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS": "Departments",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES": "Sites",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES": "Add address",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL": "Add email",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone": "Add phone",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES": "Addresses",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS": "Basic details",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES": "Email addresses",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS": "Phone numbers",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME": "Company name",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME": "First name",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME": "Last name",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME": "Name",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES": "Sites",
    "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT": "No records found!",
    "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD": "Add",
    "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES": "Payment Profiles",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT": "Bank Account",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD": "Credit Card",
    "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE": "No profiles found. Add a new payment profile by clicking on the Add button.",
    "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE": "Payment Profile Adding is in progress. Please wait a moment while the process completes.",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS": "Shipping Address",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS": "User Accounts",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE": "Account Creation Date",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS": "Billing Address",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE": " C3 Onboarding Date",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME": "Canonical Name",
    "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT": "Thank you for choosing",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1": "Account Setup",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2": "Shop ",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3": "Review Order",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4": "Confirm",
    "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE": "Done",
    "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE": "The email address is already in use. Please login to C3 by using your credentials or create a new account with a different email address",
    "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE": "Please fill the required fields to proceed further",
    "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY": "Successfully placed the request for signup",
    "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE": "Your details have been submitted successfully. We’ll contact you shortly",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS": "Submit",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS": "Shop products",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER": "Provider Customer Agreement Acceptance should be confirmed",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST": "exists",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST": "does not exist",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION": "Select Country",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT": "Primary Contact",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1": "Address 1",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2": "Address 2",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY": "City",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME": "Company name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY": "Country",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME": "Domain name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL": "Email",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME": "First name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS": "Global admin's email address",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT": "Grant access to the primary contact (as Customer Administrator)?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME": "Last name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME": "Middle Name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER": "Organization Registration Number",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER": "Phone number",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME": "Primary Domain Name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS": "Email provided will have the CustomerAdmin access to the portal.",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION": "Send invitation to access the application?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE": "State",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID": "Tenant Id",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP": "Zip",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE": "is available",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE": "is not available",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN": "Validating domain availability",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION": "Select State",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION": "Enter State",
    "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP": "To maintain tenant domain detail",
    "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP": "To communicate billing related things",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1": "Kindly submit the details below and Partner will be in touch with you to provide further assistance.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2": "Click copy link to clipboard to copy the link of reseller relationship and follow the reseller model.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3": "Open the link in an incognito session and complete the reseller relationship using an account that is a global administrator.<br>After completing provide the below details and click on submit after that partner will communicate with you.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4": "If you don’t want to accept the reseller relation then just provide the below details and click on submit after that partner will communicate with you.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING": "Instructions for accepting reseller relationship",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING": "Instructions for accepting reseller relationship",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0": "In the interim, you can can authorize partner as your CSP Reseller by providing the consent on reseller relationship link using your Global Admin User.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1": "Click copy link to clipboard",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2": " to copy the link of reseller relationship and follow the reseller model.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3": "Open the link in an incognito session and complete the reseller relationship using an account that is a global administrator.<br>After completing provide the below details and click on submit, after that partner will communicate with you.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4": "If you don’t want to accept the reseller relation then just provide the below details and click on submit after that partner will communicate with you.",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL": "SignUp BatchId",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER": "Signup in progress",
    "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP": "To grab the tenant information and subscriptions",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS": "Payment Details",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS": "Shop Products",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action": "Action",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS": "C3 Products",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS": "External Product",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER": "Sequence Number",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT": "Agreement",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE": "Billing Cycle",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME": "Category name",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT": "Contract",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME": "Customer name",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME": "PSA customer name",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1": "Please use more filters if you wish to set additional constraints on category, tenant etc.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT": "Is default contract?",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT": "Default contracts will be used to upload contract services associated with services that are created and mapped automatically.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER": "Tenant",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT": "Department :",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE": "Site :",
    "CUSTOMER_RECONCILIATION_REPORT": "Reconciliation report",
    "CUSTOMER_REPORT": "Customer Reports",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER": "Filter Products",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE": "Max Ordered Date",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY": "Max Quantity",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE": "Min Ordered Date",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY": "Min Quantity",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS": "Expires in days",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN": "Ordered Between",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY": "Quantity",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE": "Products Report",
    "CUSTOMER_REPORTS": "Reports",
    "CUSTOMER_SAVEADJUSTMENT_SUCCESS": "Adjustment saved successfully.",
    "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS": "Added past dues as an adjustment to the current invoice",
    "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS": "Payment recorded successfully",
    "CUSTOMER_SEARCH_CLOSE_OPTION": "Close filters",
    "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER": "Include deleted customer",
    "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD": "Default Payment method",
    "CUSTOMER_SEARCH_CUSTOMER_NAME": "Name",
    "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD": "Payment method",
    "CUSTOMER_SEARCH_CUSTOMER_PLAN": "Plan",
    "CUSTOMER_SEARCH_CUSTOMER_TAGS": "Tags",
    "CUSTOMER_SEARCH_DEFAULT_OPTION": "Select",
    "CUSTOMER_SEARCH_DOMAIN_NAME": "Domain Name",
    "CUSTOMER_SEARCH_FILTER": "Filter Customers",
    "CUSTOMER_SEARCH_PROVIDER": "Provider",
    "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID": "Provider Customer Id",
    "CUSTOMER_SEARCH_RESET_OPTION": "Reset",
    "CUSTOMER_SEARCH_SEARCH_OPTION": "Search",
    "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART": "Add to cart",
    "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS": "Select all add-ons",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY": "Category",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME": "Linked subscription",
    "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT": "Close",
    "CUSTOMER_SHOP_HEADER_TEXT_SHOP": "Shop",
    "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY": "To filter or include products from children level",
    "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES": "Billing types",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION": "Term Duration",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY": "Validity",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE": "Validity Type",
    "CUSTOMER_SHOP_SUP_TEXT_ADD_ON": "ADD-ON",
    "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER": "TRIAL OFFER",
    "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT": "Filters",
    "CUSTOMER_SIGNUP_FAILED": "Customer signup failed",
    "CUSTOMER_SIGNUP_SUCCESS": "Customer signup success",
    "CUSTOMER_SIGNUP_SUCCESS_MESSAGE": "SignUp Success. You can login to C3 Portal with credentials that are sent to - ",
    "CUSTOMER_SITE_ADD_SITE": "Add Site",
    "CUSTOMER_SITE_BUTTON_TEXT_CANCEL": "Cancel",
    "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT": "Submit",
    "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION": "Site deleted successfully",
    "CUSTOMER_SITE_EDIT_SITE": "Edit Site",
    "CUSTOMER_SITE_HEADER_TEXT": "Sites",
    "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION": "Site saved successfully",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN": "Azure plan",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS": "Entitlements",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN": "Select Azure plan.",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS": "Select entitlements.",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP": "Are you sure you want to continue?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION": "Apply promotion to customer?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID": "Price cannot be other than <strong>{{price}}</strong> beacuse this ReservationOrderID {{reservationorderID}} already have resvervation product with price.<br>Do you want to sync with the price <strong>{{price}}</strong> ? ",
    "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE": "Please select at least one file.",
    "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS": "Upload file successful",
    "CUSTOMER_SUBSCRIPTIONS_APPROVE": "Approve",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT": "Loading the Reconciliation Report",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS": "Reloading the Customers Billing Details...",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER": "Add customer",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION": "Add subscription",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION": "Add user to subscription",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS": "Back to customers",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS": "Back to subscription users",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS": "Back to subscriptions",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK": "Existing (multiple)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER": "Bulk customer onboarding",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING": "Existing (single)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER": "Existing customer",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS": "Export billing summary",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS": "Export usage details",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT": "Go to report",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE": "here",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW": "New",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER": "New customer",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER": "Onboard customer",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT": "Reconciliation report",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS": "Reload billing details",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH": "Reload current month",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL": "Reload  full",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY": "Reload subscription summary",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS": "Reload usage details",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE": "Remove",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT": "Select",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER": "Add Customer",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR": "Add Subscriptions for ",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF": "Azure Subscriptions of ",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS": "Customers",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION": "Edit Subscription",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER": "Manage Subscription User ",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS": "Offers",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS": "Plan & offers",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR": "Reconciliation Report for",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF": "Subscription Users of",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF": "Customer Configuration of",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE": "Value",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF": "Usage Details of",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF": "Users of ",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR": "Validate Onboarding Errors for",
    "CUSTOMER_SUBSCRIPTIONS_FINALIZE": "Finalize",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS": "Bank Details",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING": "Billing",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD": "Billing Period",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE": "Company Profile",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS": "Credit Card Details",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE": "Payment Type",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE": "Please select Payment Type",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT": "Primary contact",
    "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO": "logo",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED": "Invoice has been Approved!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2": "You can still add adjustments (additional charges or credits) against this invoice before this gets finalized and the customer is charged. Customer is now able to see this invoice and you can record payments against this invoice once it is finalized.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1": "Invoice has been Finalized!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2": "User is now able to see this invoice and you can record payments against this invoice.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1": "Invoice has been generated!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2": "You can add adjustments (additional charges or credits) against this invoice before this gets finalized and the customer is charged",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE": "Invoice will be mailed to your billing address. Thank you.",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY": "Autopay ",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN": "Organization IIN",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD": "Payment method ",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER": "Account Number",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE": "Account Type",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME": "Bank name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER": "Card number",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY": "City",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME": "Company name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE": "Credit Card Type",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV": "CVV",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD": "Danger Threshold",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL": "Email",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD": "Error Threshold",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME": "First Name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME": "Group Name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD": "Info Threshold",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT": "Is Business Account",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED": "Hide from Customer",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED": "Items selected",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME": "Last Name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1": "Line 1",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2": "Line 2",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH": "Month",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET": "Monthly Budget",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT": "Name On Account",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD": "Name On Card",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER": "Phone number",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN": "Plan",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME": "Primary domain name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE": "Role",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER": "Routing Number",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE": "State",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME": "Subscription Name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD": "Warn Threshold",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR": "Year",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP": "Zip",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS": "Plans",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY": "Quantity",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH": "ACH",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT": "Bank account",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD": "Credit card",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE": "Manual",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO": "No",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES": "Yes",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION": "Inactive subscription",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH": "Quantity mismatch",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER": "Remove Access",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION": "Edit Subscription",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION": "Enable Subscription",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS": "Users",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS": "View Usage Details",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP": "Active in CSP",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME": "Component name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL": "Email",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID": "Email ID",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS": "Entitlements",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR": "Error",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST": "Estimated cost",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME": "Friendly name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED": "Hide from customer",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT": "Subscriptions are not visible to customers",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON": "Last refreshed",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE": "MSFT invoice",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS": "Onboarding status",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE": "Price",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT": "Profit",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE": "Role",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID": "Subscription ID",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME": "Subscription name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS": "Thresholds",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER": "User",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME": "User name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET": "No subscriptions added yet.",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN": "Assign Customer Plan",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS": "Azure Subscriptions",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES": " Change Billing Cycle",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME": "Change Name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS": "Define Tags",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX": "Define Tax %",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE": "Fix activation state mismatch in Partner Center",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE": "Fix auto renew state mismatch in Partner Center",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE": "Fix mismatch ...",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH": "Fix mismatch",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES": "Change Offer",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES": "Payment Providers",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX": " Update Perpetual Product Status",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE": "Change Plan",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX": "Fix promotion",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS": "Providers",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION": "Customer : Quick impersonation",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT": "Reconciliation Report",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY": "Reload Subscription Summary",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX": "Fix scope",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION": "Customer configuration",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES": " Change Term",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS": "Test Auto Payment",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS": "Users",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES": "Validation Errors",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM": "CUSTOM",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL": "Total",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER": "Monthly Budget-Info-Warn-Error-Danger",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE": "is available",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE": "is not available",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM": "Please download the template from",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER": "Delete User",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL": "Grant access to the portal",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE": "Impersonate",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL": "Revoke access from the portal",
    "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100": "Value should be between 0 and 100",
    "CUSTOMER_SUP_TEXT_DELETED": "(deleted)",
    "CUSTOMER_SYNC_PROFILES_TOOLTIP": "Sync profiles",
    "CUSTOMER_TAGS_EXAMPLE": "Define tag, value pairs for the customer that would be used while defining access control for users. <br/> Example: You can define a tag named SupportAgent and a nick name of an agent (say, Agent001) as the value. If a Help Desk agent has one of the tags set as SupportAgent and one of the values for this particular tag as Agent001, the agent would be able to see those customers that have the tag value pair {SupportAgent, Agent001} set.",
    "CUSTOMER_TAGS_LABLE_TEXT_NAME": "Name",
    "CUSTOMER_TAGS_LABLE_TEXT_VALUE": "Value",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE": "Delete",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT": "Edit",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE": "Value",
    "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS": "The custom term end date specified on the scheduled next term instructions is only valid for OnlineServicesNCE products.",
    "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES": "View pending invoices",
    "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR": "Payments for ",
    "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION": "Customer",
    "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION": "Customer",
    "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE": "User details saved successfully",
    "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION": "User licence assignment report will be sent to the provided email.",
    "CUSTOMER_USERS_BUTTON_ADD_NEW_USER": "Add New User",
    "CUSTOMER_USERS_BUTTON_BACK_TO_LIST": "Back to list",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS": "Grant Access",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER": "Manage User",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS": "Revoke Access",
    "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT": "Department",
    "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS": "Email Address",
    "CUSTOMER_USERS_FORM_LABEL_ROLE": "Role",
    "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE": "Role Type",
    "CUSTOMER_USERS_FORM_LABEL_SITE": "Site",
    "CUSTOMER_USERS_HEADING": "Users",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS": "Email address",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE": "Role",
    "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES": "Download invoices/Payments",
    "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT": "Please mention if the ACH details are related to a business account",
    "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3": "This customer has already been selected",
    "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR": "Customer already exists in the system",
    "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS": "Customer created successfully",
    "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS": "Customer's onboarding process is in progress",
    "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT": "Please select at least one file.",
    "CUSTOMERONBOARDING_SUCCESS": "Customer onboarded successfully.",
    "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR": "An error occurred while uploading the file",
    "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR": "{username} already has a license to {offername}",
    "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR": "The number of users provided is more than the number of available licenses. Please fix the user detail and try again.",
    "CUSTOMERS_ACTIONS_BUTTON_TEXT": "Actions",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS": "Business",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS": "Customers",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY": "Revenue and cost summary",
    "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE": "Link to billing profile",
    "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER": "Link to provider",
    "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE": "Remove link to billing profile",
    "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES": "Creating Invoices…",
    "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT": "Reloading the Customers Profitability Report...",
    "CUSTOMERS_BUTTON_BACK_TO_INVOICES": "Back to Invoices",
    "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT": "Add adjustment",
    "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT": "Add the due as adjustment!",
    "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS": "Back to customers",
    "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE": "Download invoice",
    "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT": "Go to report",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE": "History by date",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER": "History by offer",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT": "Initiate payment",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT": "Test charge customer",
    "CUSTOMERS_CAPTION_TEXT_BETA": "BETA",
    "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR": "Invoice for",
    "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR": "Profitability by subscriptions for",
    "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY": "Revenue & Cost Summary",
    "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR": "Subscription history change for",
    "CUSTOMERS_DOMAIN_CHECK_ERROR": "Unable to check the domain availability",
    "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT": "Please enter a valid domain name. Domain names can only contain letters and numbers; no hyphens, periods, or underscores",
    "CUSTOMERS_GRANT_ACCESS_CONFIRM": "Are you sure you want to grant access to {{username}}?",
    "CUSTOMERS_HEADER_TEXT_CUSTOMERS": "Customers",
    "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT": "No invoices found. Please contact support.",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS": "Address",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER": "Customer",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER": "Invoice number",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY": "Account Summary",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY": "Invoice Summary",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD": "Billing period",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE": "Due date",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT": "Invoice amount",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE": "Invoice date",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE": "Invoice due date",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE": "Generated on",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS": "Invoice status",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1": "Invoice has been generated!",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2": "You can add adjustments (additional charges or credits) against this invoice before this gets processed and the customer is charged",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1": "Invoice has been processed!",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2": "Customer is now able to see this invoice and you can record payments against this invoice.",
    "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE": "Dues from past invoices",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1": "It looks like there are dues from the past invoices. If you are sure that you have recorded all the payments from the customer, click the following button to",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2": "to add <strong>{{InvoiceDueAmount}}</strong> as an adjustment to the current invoice.",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_2": "It looks like there are dues of amount",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_3": " in total.",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT": "Edit",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE": "Delete",
    "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL": "Invoice Detail",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED": "License Based",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED": "Usage Based",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS": " Actions",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT": "Amount",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE": "Charge type",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION": " Description",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT": "Discount amount",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE": "Offer price",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY": " Quantity",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE": "End date",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT": "Grand amount",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT": "Subtotal amount",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE": "Start date",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME": "Subscription name",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT": "Tax amount",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE": "Tax percentage",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE": "Unit price",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON": "Record Payment",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT": "Payment Amount",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS": "Remarks",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON": "Failure Reason",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT": "Paid Amount",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID": "Payment Id",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS": "Invoice Payments",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS": "Remarks",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID": "Transaction Id",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1": "Instruction for recording payment",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2": "Record a payment ( partial or full ) for a transaction that happened outside the system",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3": "Excess amount from the payments would be treated as balance and adjusted for a subsequent invoice",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4": "Contact support in case you need further assistance on payments that either do not show up or were recorded in error",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL": "Grand Total",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL": "Sub Total",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON": "Failure Reason",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT": "Amount",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE": "Date",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS": "Remarks",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS": "Payment Status",
    "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS": "Billing details including history is refreshed successfully",
    "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS": "Usage details including history is refreshed successfully",
    "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL": "Billing email",
    "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE": "Onboarded on",
    "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME": "Plans",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID": "C3Id",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME": "Name",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT": "Customers Onboarded",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS": "Looking for a matching record in the billing system",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS": "Found a match on Reference Id {{ID}} in Billing System.",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS": "Looking up customers in C3 for a match by the Provider Customer Id",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS": "Found a customer with name - {{customername}}",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS": "Looking up customers in {{providerName}} for a match by the Provider Customer Id",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS": "Found a customer with name - {{customername}} in {{providerName}}",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR": "The quantities for children offers in a composite should be same",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR": "One or more offers in CSP are mapped to the same offer in billing. Please adjust your mapping",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS": "Offer {{Name}} in the plan has multiple matching CSP Subscriptions.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR": "The subscription {{nameinbilling}} in billing could not be mapped",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR": "The offer {{name}} does not have matching CSP subscriptions in Partner Center",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR": "The subscription in Partner Center - {{name}} has no matching offer in the plan to map",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR": "The following offers should be mapped to CSP subscriptions - ",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR": "Offer {{name}} has more users specified than the seats it has",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1": "The quantities for the subscriptions should match with Partner Center",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR": "The quantities for the subscription {{subscriptionname}} should match between Partner Center and Billing",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1": "The quantities for the subscriptions should match between Partner Center and Billing",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT": "The number of seats against a CSP subscription {{cspsubscription}} should match the sum of all the numbers provided against related offers in the plan",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR": "One or more CSP offers are not mapped or there are missing subscriptions in CSP",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR": "An error occurred while refreshing subscriptions for {{customerName}}",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS": "Refreshed subscriptions for customer - {{customername}}",
    "CUSTOMERS_REGISTRATION_SUCCESS": "Customer registration completed successfully. An email is sent to {{registeredemail}} with instructions to login to the portal.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET": "Customer registration completed successfully.<br/>Admin user credentials are :<br/><b>UserId -</b> {{userId}},<br/><b>Temporary Password -</b> {{password}}",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL": "Customer registration completed successfully.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN": "Customer registration completed successfully. Login to the portal.",
    "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT": "Please associate an offer for the adjustment",
    "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD": "CustomersSelectedForOnboard",
    "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT": "Sync profiles",
    "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS": "Customer payments",
    "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT": "Billed amount",
    "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "CUSTOMERS_TABLE_HEADER_TEXT_DATE": "Date",
    "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME": "Department name",
    "CUSTOMERS_TABLE_HEADER_TEXT_NAME": "Name",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE": "New price",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY": "New quantity",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS": "New status",
    "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE": "Old price ",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY": "Old quantity",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS": "Old status",
    "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST": "Other cost",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT": "Pre-Tax billed amount",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT": "Pre-Tax MSFT invoice",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT": "Profit",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE": "Profit %",
    "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME": "Site name",
    "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION": "Subscription",
    "CUSTOMERS_TABLE_HEADER_TEXT_USER": "User",
    "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET": "No subscriptions added yet.",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER": "Delete Customer",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS": "Account Manager Details",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS": "Customer settings",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS": "Manage Plans",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION": "Action",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME": "Name",
    "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS": "Download usage based line items",
    "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS": "Profitability by subscriptions",
    "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT": "Reload Profitability Report",
    "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY": "Subscription Change History",
    "CUSTOMERS_TOOLTIP_VIEW_INVOICE": "View Invoice",
    "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS": "View Usage Line Items",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS": "Added {{username}} to Azure subscription successfully",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS": "Added {{username}} to Azure subscription {{azuresubscription}} successfully",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS": "{{username}} removed from Azure subscription successfully",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM": "Are you sure you want to delete the user?",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS": "{{username}} removed from {{azuresubscription}} successfully",
    "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS": "Azure subscription saved successfully",
    "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE": "Invoice status updated successfully",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE": "Error occurred while processing your request",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE": "Order placed successfully. </br> OrderId : {{orderId}}",
    "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT": "Password Recipients",
    "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS": "Recipients Email",
    "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS": "Enter comma separated Email recipients",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY": "Add new entity",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL": "Cancel",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE": "Manage",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT": "Submit",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY": "Add New Entity",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES": "Operating Entities",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION": "Description",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE": "Entity type",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME": "Name",
    "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED": "Terms and Conditions Acceptance Log",
    "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE": "View invoice",
    "DASHBOAR_INVOICE_HELP_TEXT_LINE_1": "New invoice generated on <b>{{InvoiceGeneratedDate}}</b> for the billing period from <b>{{BillingStartDate}}</b> to <b>{{BillingEndDate}}</b> with amount <b>{{InvoiceAmount}}</b>.",
    "DASHBOAR_TEXT_DATE": "Date",
    "DASHBOAR_TEXT_EMAIL": "Email",
    "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL": "Ezidebit Agreement URL",
    "DASHBOAR_TEXT_IP": "IP",
    "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL": "Microsoft Customer Agreement URL",
    "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL": "Partner Terms & Conditions URL",
    "DASHBOAR_TEXT_TIME": "Time",
    "DASHBOARD_BREADCRUMB": "Dashboard",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS": "Customer analytics",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS": "Reports",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT": "License Change Report",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT": "License summary report",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT": "Usage report",
    "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT": "Usage Report",
    "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE": "Update profile",
    "DASHBOARD_CAPTION_PARAGRAPH": "Provision resources to run a successful business with the power of cloud.",
    "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE": "Welcome to Cloud Market Place",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV": "Download All SKUs (CSV)",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV": "Download CSV",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG": "Download PNG",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG": "Download SVG",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV": "Download Top 10 SKUs (CSV)",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT": "Microsoft",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP": "MicrosoftNonCSP",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER": "Partner",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL": "All",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD": "Last Billing Period",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS": "6 Billing Periods",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS": "3 Billing Periods",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS": "12 Billing Periods",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS": "6 Months",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS": "3 Months",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS": "12 Months",
    "DASHBOARD_COMPLAINCE_TEXT": "Compliance Center",
    "DASHBOARD_COST_TEXT": "Cost",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT": "Number of Departments",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE": "Departments",
    "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS": "Departments",
    "DASHBOARD_HEADER_TEXT_WARNING": "Warning!",
    "DASHBOARD_ICON_TITLE": "Stockholm-icons / Design / Layers",
    "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS": "Current Month",
    "DASHBOARD_LBL_TEXT_NET_SEATS": "Net",
    "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS": "Previous Month",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL": "Welcome to your licensing portal Dashboard",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1": "Use the tiles below as quick links to manage your subscriptions and users.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2": "This portal allows you to add new users in your tenant, assign licenses, and reset your password.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3": "You can purchase additional licenses and subscriptions.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4": "Update your profile (Billing address and Payment method)",
    "DASHBOARD_REVENUE_TEXT": "Revenue",
    "DASHBOARD_SEAT_PURCHASED_TEXT": "Seat purchased",
    "DASHBOARD_SITES_COUNT_INTRO_CONTENT": "Number of sites",
    "DASHBOARD_SITES_COUNT_INTRO_TITLE": "Sites",
    "DASHBOARD_SITES_COUNT_TEXT_SITES": "Sites",
    "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT": "Log on to portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE": "Azure Portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON": "Log on to Portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION": "Azure Subscriptions",
    "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM": "Current Azure Usage from {{startDate}}",
    "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS": "Products",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION": "License Subscriptions",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON": "View more",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365": "Office 365",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON": "Log on to Portal",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS": "Office 365 Reports",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON": "Log on to Portal",
    "DASHBOARD_TAIL_1_TEXT_USERS": "Users",
    "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON": "View more",
    "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH": "TREND THIS MONTH",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED": "Failed",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS": "In progress",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED": "Not received",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED": "Received",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE": "Date: ",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS": "Seats: ",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE": "Date: ",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS": "Products: ",
    "DASHBOARD_TOOLTIP_LAST_SYNC": "Last sync {{LastSyncMessage}}",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1": "New Invoice generated on <b>{{InvoiceGeneratedDate}}</b> for the billing period from <b>{{BillingStartDate}}</b> to <b>{{BillingEndDate}}</b> with amount <b>{{InvoiceAmount}}</b>.",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2": "to view the invoice. Ignore if already paid.",
    "DASHBOARD_WARNING_MESSAGE": "You have to update your payment profile before using C3. Please click the following button to update.",
    "DATA_PUSHED_DATES": "Some or all of the data is pushed previously, please check the previous logs.",
    "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION": "Item Description",
    "DAYS": " Day(s) ",
    "DEACTIVATE_CONFORMATION": "Are you sure do you want to deactivate?",
    "DEACTIVATE_CUSTOMER_PRODUCT": "Failed to Deactivate Customer Product",
    "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE": "Deactivated Customer Product",
    "DEACTIVATE_PRODUCT_EXCEPTION": "This user does not have permission to remove this offer",
    "DECEMBER": "December",
    "DEFAULT_COTERMINOSITY_TYPE_DESC": "List of coterminosity type",
    "DEFAULT_COTERMINOSITY_TYPE_DISP": "Coterminosity type",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DESC": "List of counterminiosity type",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DISP": "Counterminiosity Type",
    "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT": "Delete all add-ons",
    "DELETE_CURRENCY_CONVERSION_EXCEPTION": "Error occurred while deleting the currency conversion",
    "DELETE_CUSTOMER_FAILURE": "Customer Name Update failed",
    "DELETE_CUSTOMER_SUCCESS": "Customer is deleted successfully.",
    "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION": "Error occurred while deleting the email notification recipients",
    "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION": "Selected invoice adjustment will be deleted. Do you want to continue?",
    "DELETE_PARTNER_BUNDLE_TOOLTIP": "Delete bundle product",
    "DELETE_PARTNER_TAX_DETAILS_EXCEPTION": "Error occurred while deleting the tax details",
    "DELETE_PARTNER_USER": "Delete Partner User",
    "DELETE_PARTNER_USER_FAILURE": "Failed to delete partner user",
    "DELETE_PONUMBER": "Delete PO Number",
    "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT": "Delete product",
    "DELETE_RECORD_CONFIRMATION_PROMPT": "Are you sure you want to delete this record?",
    "DELETE_USER_EXCEPTION": "This user have some access to C3, so please remove those",
    "DEPARTMENT": "(Department)",
    "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING": "Public signup banner main heading",
    "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING": "Public signup banner paragraph heading",
    "DESC_CUSTOMER_CURRENCY_OPTION": "Customer currency",
    "DESC_CUSTOMER_DASHBOARD": "Customer dashboard",
    "DESC_PARTNER_DASHBOARD": "Partner dashboard",
    "DESC_PLAN_CURRENCY_OPTION": "Plan currency",
    "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL": "Website url for Admin hyperlink in public signup navbar",
    "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING": "Main heading in public signup banner",
    "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING": "The paragraph that appears on the public signup banner below the banner heading",
    "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE": "Website url for the Website hyperlink in public signup navbar",
    "DESC_PUBLIC_SIGNUP_CONTACTUS_URL": "Contact Us",
    "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL": "Website url for Learning Portal hyperlink in public signup navbar ",
    "DESC_PUBLIC_SIGNUP_SUPPORT": "Website url for Support  hyperlink in public signup navbar",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1": "Public signup terms and conditions 1",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2": "Public signup terms and conditions 2",
    "DESCRIPTION_FOR": "Description for",
    "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT": "Disable product",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL": "Disabled Old And Enabled New Secure Model",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE": "Thanks for the confirmation. The application will access your Partner Center following the new security guidelines.",
    "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED": "Disabling of Old And Enabling New Secure Model Failed",
    "DISP_CATEGORY_COUPON_ENTITY": "Category",
    "DISP_PLANOFFER_COUPON_ENTITY": "Plan Offer",
    "DISP_PROVIDER_COUPON_ENTITY": "Provider",
    "DISP_SALETYPE_COUPON_ENTITY": "SaleType",
    "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION": "Term duration",
    "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET": "Reset",
    "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE": "Enter a positive value",
    "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP": "Please choose the solution provider from the dropdown list",
    "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS": "New distributor offer",
    "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS": "Back to distributor offer",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS": "Distributor Offers",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT": "Category",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT": "Distributor Offers",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION": "Action",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL": "Is auto-renewable?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES": "Billing cycles",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type": "Billing type",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES": "Billing types",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING": "Available for bundling?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY": "Category",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS": "Billing period type",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE": "Consumption type",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES": "Consumption types",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE": "Cost price",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM": "Cost price (from)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO": "Cost price (to)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION": "Description",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING": "Available for consumption immediately?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME": "Name",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE": "Old cost price",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE": "Old sale price",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION": "Billing rule for purchase of seats",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION": "Billing rule for reduction of seats",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON": "Icon",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER": "Provider",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE": "Sale price",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM": "Sale price (from)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO": "Sale price (to)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE": "Sale type",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM": "Term",
    "DISTRIBUTOR_OFFERS_SAVE_SUCCESS": "Distributor offer {{distributorOffer}} saved successfully",
    "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME": "Name",
    "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS": "Distributor offer {{distributorOffer}} updated successfully",
    "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE": "Currency code",
    "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY": "Entity",
    "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT": "Billed amount",
    "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME": "Qualified name",
    "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS": "Download progress & link",
    "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS": "Download invoices",
    "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME": "Active Coupon Name",
    "DOWNLOAD_COLUMN_COUPON_APPLICABILITY": "Applicability",
    "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON": "Assigned on",
    "DOWNLOAD_COLUMN_COUPON_COUPON_CODE": "Coupon Code",
    "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION": "Coupon Description",
    "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME": "Customer Name",
    "DOWNLOAD_COLUMN_COUPON_DISCOUNT": "Discount",
    "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON": "Expires on",
    "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY": "AutoPay",
    "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID": "Billing Reference Id",
    "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE": "Currency Code",
    "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME": "Domain Name",
    "DOWNLOAD_COLUMN_CUSTOMER_NAME": "Name",
    "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD": "Payment Method",
    "DOWNLOAD_COLUMN_CUSTOMER_PLAN": "Plan",
    "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER": "Is TrialOffer",
    "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE": "Signup Date",
    "DOWNLOAD_COLUMN_CUSTOMER_TAGS": "Tags",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID": "Tenant Id",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME": "Tenant Name",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANTS": "Tenants",
    "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER": "Is Trial Offer",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE": "Billing Cycle",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER": "Billing Reference Number",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE": "Charge End Date",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE": "Charge Start Date",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE": "Conversion Rate",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER": "Cost On Partner",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE": "Currency Code",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID": "ERP Customer Id",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT": "Discount Amount",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME": "Domain Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID": "Durable Offer Id",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS": "Effective Unit Price By MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE": "Invoice Date",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES": "Invoice Notes",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER": "Invoice Number",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS": "Invoice Line Detail",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE": "Item Code",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT": "MANDT",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME": "Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME": "Offer Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER": "PO Number",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT": "Post Tax Amount",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT": "Pre Tax Amount",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID": "Promotion Id",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST": "Prorated Unit Cost",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER": "Provider",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN": "PS_PSP_PN",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY": "Quantity",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE": "Subscription Effective End Date",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE": "Subscription Effective Start Date",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID": "Subscription Id",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME": "Subscription Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM": "Subscription Term",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE": "Subscription Type",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER": "Syndication Partner Subscription Number",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT": "Tax Amount",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE": "Tax Code",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID": "Tenant Id",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME": "Tenant Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT": "Total Amount",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS": "Total Amount For Partner By MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT": "Unit Cost Per Product",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST": "Unit Partner Cost",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE": "Unit Price",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS": "Unit Price By MS",
    "DOWNLOAD_COLUMN_MARKET_REGION": "Supported Markets",
    "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER": "Is TrialOffer",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING": "Available for bundling",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY": "Available for consumption immediately",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE": "Billing cycle",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS": "Billing rule for purchase of seats",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS": "Billing rule for reduction of seats",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE": "Billing type",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE": "Consumption type",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE": "Cost price",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION": "Description",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME": "Name",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE": "Sale price",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE": "Sale type",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS": "Status",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM": "Term",
    "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE": "Currency Code",
    "DOWNLOAD_COLUMN_PLAN_DESCRIPTION": "Description",
    "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY": "Invoice Currency",
    "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC": "Is Public",
    "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS": "Macro Details",
    "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE": "Macro Value",
    "DOWNLOAD_COLUMN_PLAN_NAME": "Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER": "Available to Customer",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY": "Billing Cycle category",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE": "Billing type",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE": "Category",
    "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER": "Cost to Partner",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE": "Currency Code",
    "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS": "Effectiveness",
    "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME": "Friendly Offer Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON": "Last Price change happens on",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME": "Macro Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE": "Macro Value",
    "DOWNLOAD_COLUMN_PLAN_OFFER_NAME": "Plan Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID": "Offer Id",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME": "Offer Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME": "Plan Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER": "Provider",
    "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE": "Retail Price",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE": "Sale Price",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE": "Service Type",
    "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS": "Status",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET": "Supported Market",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM": "Term",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION": "Term Duration",
    "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY": "Purchase Currency",
    "DOWNLOAD_COLUMN_PLAN_SHARING_URL": "Sharing Url",
    "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER": "Is TrialOffer",
    "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE": "Available for Auto release",
    "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE": "Billing Cycle",
    "DOWNLOAD_COLUMN_PRODUCT_CATEGORY": "Category",
    "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE": "Consumption Type",
    "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME": "Department Name",
    "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT": "Discount",
    "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME": "Domain Name",
    "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE": "Product Effective Price",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME": "Product Name",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE": "Product sequence",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER": "Provider",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME": "Provider Category Name",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE": "Provider Creation Date",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE": "Provider End Date",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE": "Provider Start Date",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID": "Provider Product ID",
    "DOWNLOAD_COLUMN_PRODUCT_QUANTITY": "Quantity",
    "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME": "Site Name",
    "DOWNLOAD_COLUMN_PRODUCT_STATUS": "Status",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID": "Tenant ID",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME": "Tenant Name",
    "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE": "Unit Price",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE": "Billing cycle",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY": "Category",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER": "Cost to partner",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY": "Currency code",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER": "Is TrialOffer",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID": "Product Id",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME": "Product name",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY": "Provider category",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME": "Provider name",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION": "Region",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE": "Retail price",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE": "Product catalogue report",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY": "Validity",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE": "Validity type",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE": "Billing cycle",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME": "Category name",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY": "Create by",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE": "Create date",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE": "Currency code",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME": "Customer name",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME": "Domain name",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY": "Entity",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON": "Is addon",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY": "Name of entity",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE": "New price",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY": "New quantity",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS": "New status",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE": "Old price",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY": "Old quantity",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS": "Old status",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME": "Product name",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER": "Provider",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY": "Provider category",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE": "Reseller new price",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE": "Reseller old price",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID": "Subscription id",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID": "Tenant id",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE": "Type of change",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY": "Validity",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE": "Validity type",
    "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR": "Select atleast one column",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD": "Download",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select all",
    "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER": "Download Coupons",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER": "Download Customers",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER": "Download Products",
    "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER": "Download Plans",
    "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER": "Download Plan Offers",
    "DOWNLOAD_INVOICE_LINE_ITEMS_LBL": "Invoice line items",
    "DOWNLOAD_INVOICE_PAYMENTS_LBL": "Invoice payments",
    "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR": "Select atleast one column",
    "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select all",
    "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV": "Download CSV",
    "DOWNLOAD_NON_ONBOARDED_CUSTOMERS": "Download non onboarded customers",
    "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select all",
    "DOWNLOAD_POPUP_GRID_HEADER": "Download grid report",
    "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR": "There is no data available for download CSV",
    "DOWNLOAD_PRODUCTS_GRID": "Download products",
    "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER": "Download Software And Keys",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS": "Add multiple",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS": "Link multiple",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER": "Link single",
    "DROPDOWN_PLACEHOLDER_SELECT": "Select",
    "DUE_STATUS_DUE": "Due",
    "DUE_STATUS_FINALIZED": "Finalized",
    "DUE_STATUS_OVERDUE": "Over Due",
    "DUE_STATUS_PAID": "Paid",
    "DUE_STATUS_PARTIAL_PAID": "Partially Paid",
    "EDIT_GROUP_TOOLTIP_AZUREGROUP": "Edit Group",
    "EDIT_INVOICE_BUTTON_TEXT": "Edit invoice",
    "EDIT_LICENSES_TABLE_HEADER_PRODUCT": "Product",
    "EDIT_LICENSES_TABLE_HEADER_SUMMARY": "Summary",
    "EDIT_PONUMBER": "Edit PO Number",
    "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN": "Please provide an image with max resolution of 300 x 100 for email logo",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS": "The email address specified as billing contact in the customer profile",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE": "List of email addresses of the people that should be notified via email",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE": "Roles other than HelpDesk",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS": "BillingContacts",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE": "People",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE": "Role",
    "EMAIL_TABLE_HEADER_ACTIONS": "Actions",
    "EMAIL_TABLE_HEADER_EMAIL": "Email",
    "EMAIL_TABLE_HEADER_EMAIL_TYPE": "Email type",
    "EMAIL_TEMPLATE": "Preview",
    "EMAIL_TEMPLATE_LABEL": "Templates",
    "EMAIL_TEMPLATE_LABEL_PLACEHOLDER": "Select template",
    "EMAIL_TEMPLATE_NOTIFY_SUCCESS": "Email template updated successfully",
    "EMAIL_TEMPLATE_SUBMIT": "Submit",
    "EMAIL_TYPE_BILLING": "Billing",
    "EMAIL_TYPE_COMPANY": "Company",
    "EMAIL_TYPE_INVOICE": "Invoice",
    "EMAIL_TYPE_PERSONAL": "Personal",
    "EMAIL_TYPE_SHIPPING": "Shipping",
    "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT": "Enable product",
    "ENABLE_RESELLER_PLAN": "Assign Plan to Reseller",
    "ENABLE_RESELLER_PLAN_FAILURE": "Failed to Assign  Plan to Reseller",
    "END_BILLING_PERIOD": "End billing period",
    "END_DATE_GRATER_THAN_START_DATE_MESSAGE": "End date should be greater than Start date",
    "END_DATE_REQUIRED_MESSAGE": "End Date is required",
    "ENGAGE_BREADCRUMB_ADD": "Create engagement",
    "ENGAGE_BREADCRUMB_LIST": "Engagement list",
    "ENGAGE_BUTTON_COLOR_PLACEHOLDER": "Color of the call to action button",
    "ENGAGE_BUTTON_TEXT_COLOR": "Button text color",
    "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER": "Color of the button text",
    "ENGAGE_BUTTON_URL_PLACEHOLDER": "Call to action button hyperlink",
    "ENGAGE_CTA_BUTTON_COLOR": "CTA button color",
    "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER": "Call to action button text",
    "ENGAGE_CUSTOMER_PAGENAME": "CustomerDashboard",
    "ENGAGE_EFFECTIVE_FROM": "Effective from",
    "ENGAGE_EXPIRES_ON": "Expires on",
    "ENGAGE_HEADER_ACTIONS": "Actions",
    "ENGAGE_HEADER_ACTIONS_ADD": "Create engagement",
    "ENGAGE_HEADER_ACTIONS_BACK": "Back to list",
    "ENGAGE_HEADER_EFFECTIVE_FROM": "Effective from",
    "ENGAGE_HEADER_ENTITES": "Entities",
    "ENGAGE_HEADER_EXPIRES_ON": "Expires on",
    "ENGAGE_HEADER_MESSAGE": "Message",
    "ENGAGE_HEADER_PAGE": "Page",
    "ENGAGE_HEADER_TITLE": "Title",
    "ENGAGE_NOTIFICATION_BACKGROUND": "Background",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2": "Offering discounts for your online store can be a powerful weapon in to drive loyalty.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3": "Boost marketing &amp; sales product confidence.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4": "Start with a branding site design modern content creation.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5": "Offering discounts for better online a store can loyalty weapon into driving.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6": "Start with a branding site design modern content creation.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8": "Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.",
    "ENGAGE_NOTIFICATION_BODY_TEXT": "Body text",
    "ENGAGE_NOTIFICATION_PORTAL_PAGE": "Portal page",
    "ENGAGE_NOTIFICATION_TITLE_TEXT": "Title text",
    "ENGAGE_PARTNER_PAGENAME": "PartnerDashboard",
    "ENGAGE_POPUP_BUTTON_URL": "Button url",
    "ENGAGE_POPUP_CTA_BUTTON_TEXT": "CTA button text",
    "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS": "Select all customers",
    "ENGAGE_SUBMIT": "Submit",
    "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15": " The text exceeds the max length of 15",
    "ENGAGE_THE_MAX_LENGTH": "The max length is 250",
    "ENGAGE_THIS_FIELD_IS_REQUIRED": "This field is required",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE": "Please select at least one invoice for posting to QuickBooks.",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE": "Your request to post invoices to QuickBooks is accepted and enqueued for processing. Please click on <strong>View Post Logs</strong> button for updates on the progress.",
    "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED": "Failed to enqueue the request to upload Invoices to QuickBooks online",
    "ENQUEUED_REQUEST_TO_CLONE_PLAN": "Enqueued request to clone plan",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS": "Enqueued request to create a new plan with all offers",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS": "Enqueued request to create a new reseller plan with all offers",
    "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED": "Failed to enqueue the request to upload Invoices to Xero",
    "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA": " Enter an e-mail after the comma/semicolon or remove the extra comma/semicolon",
    "ENTER_AZURE_NEW_MARKUP_VALUE": "Enter new markup value",
    "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE": "Enter reason for new markup value",
    "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE": "Enter reason for updating markup value",
    "ENTITLEMENT_EXCEEDED_COUNT_ERROR": "Number of entitlements to be created, not exceeding {{Count}}",
    "ENTITLEMENT_MANAGEMENT": "Azure Entitlement Management",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP": "Number of entitlements to be created cannot exceed ",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP": "Entitlement names will start with this.",
    "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT": "Add entitlement",
    "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT": "Add Entitlements",
    "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE": "Complete",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE": "Created date",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS": "Creation status",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT": "Department",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME": "Department name",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT": "Number of entitlements",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID": "ID",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST": "Entitlement list",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME": "Name",
    "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE": "Start sequence",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE": "Site",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME": "Site name",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED": " Process complete. Please check the audit log for more details.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST": "Request to add entitlements is accepted and being processed",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED": "Request to add entitlements failed.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR": "Please provide a number sequence",
    "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP": "The sequential increment will be used as a suffix in entitlement names.",
    "ENTITY_DESC_AZURE_GROUP": "Azure group",
    "ENTITY_DESC_BILLING_PERIODS": "Billing periods",
    "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID": "Billing provider reseller Id",
    "ENTITY_DESC_BILLING_PROVIDER_USER": "Billing provider user",
    "ENTITY_DESC_CART": "Cart",
    "ENTITY_DESC_CART_LINE_ITEM": "Cart line item",
    "ENTITY_DESC_COUPON": "Coupon",
    "ENTITY_DESC_CURRENCY": "Currency",
    "ENTITY_DESC_CUSTOM_OFFER": "Custom offer",
    "ENTITY_DESC_CUSTOMER": "Customer",
    "ENTITY_DESC_CUSTOMER_PRODUCT": "Customer product",
    "ENTITY_DESC_CUSTOMER_USER": "Customer user",
    "ENTITY_DESC_DEPARTMENT": "Department",
    "ENTITY_DESC_INVOICE": "Invoice",
    "ENTITY_DESC_JOB_LOGS": "Job logs",
    "ENTITY_DESC_JOBS": "Jobs",
    "ENTITY_DESC_LOCAL_STORE_CUSTOMER": "Local store customer",
    "ENTITY_DESC_LOCAL_STORE_RESELLER": "Local store reseller",
    "ENTITY_DESC_PARTNER": "Partner",
    "ENTITY_DESC_PARTNER_SETTINGS": "Partner settings",
    "ENTITY_DESC_PARTNER_USER": "Partner user",
    "ENTITY_DESC_PAYMENT_PROFILE": "Payment profile",
    "ENTITY_DESC_PLAN": "Plan",
    "ENTITY_DESC_PLAN_PRODUCT": "Plan product",
    "ENTITY_DESC_PLAN_PRODUCT_VOUCHER": "Plan product voucher",
    "ENTITY_DESC_PRODUCT": "Product",
    "ENTITY_DESC_PRODUCT_VARIANT": "Product variant",
    "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER": "Product variant voucher",
    "ENTITY_DESC_PROVIDER_CUSTOMER": "Provider customer",
    "ENTITY_DESC_RESELLER": "Reseller",
    "ENTITY_DESC_RESELLER_PAYMENT_PROFILE": "Reseller payment profile",
    "ENTITY_DESC_RESELLER_PLAN": "Reseller plan",
    "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER": "Sevice provider customer",
    "ENTITY_DESC_SHOP": "Shop",
    "ENTITY_DESC_SITE": "Site",
    "ENTITY_DESC_SITE_DEPARTMENT": "Site department",
    "ENTITY_DESC_TAG": "Tag",
    "ENTITY_DESC_TAX": "Tax",
    "ENTITY_DESC_TERMS_AND_CONDITIONS": "Terms and conditions",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS": "Update provider settings",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE": "Update provider settings failure",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING": "Are you sure you want to release the mapping",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING": "Are you sure you want to save the mapping for <b> {{customerName}}</b>?",
    "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT": "Atleast map 1 product",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS": "These contracts are not default anymore : {{ContractNames}}",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED": "Duplicate sequence number is not allowed",
    "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY": "Mappings saved successfully",
    "ENTITY_NAME": "Entity Name",
    "ENVIRONMENTS_TABLE_HEADER_ACTIONS": "Actions",
    "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME": "Company name",
    "ENVIRONMENTS_TABLE_HEADER_COUNTRY": "Country",
    "ENVIRONMENTS_TABLE_HEADER_EMAIL": "Email",
    "ENVIRONMENTS_TABLE_HEADER_STATUS": "Status",
    "ERROR__DATA_CONTACT_TYPE": "Contact Type",
    "ERROR__DATA_PAYMENT_TYPE": "Payment Type",
    "ERROR__DATA_PROFILE_TYPE": "Profile Type",
    "ERROR__DATA_URL": "URL",
    "ERROR_AVAILABLE_IN_BATCH": "Error is Available in the batch.",
    "ERROR_BILLING_PROVIDER_NAME": "Billing Provider Name",
    "ERROR_BUTTON_CONTINUE": "Continue",
    "ERROR_C3_CUSTOMER_PRODUCT_ID": "C3 Customer Product Id",
    "ERROR_C3_ENTITY_NAME": "Entity Name",
    "ERROR_C3_RECORD_ID": "Record Id",
    "ERROR_C3_USER_ID": "User ID",
    "ERROR_CART_QUANTITY_INVALID_QUANTITY": "Invalid cart quantity",
    "ERROR_DATA_BILLING_CUSTOMER_ID": "Billing Customer Id",
    "ERROR_DATA_BILLING_REFERENCE_ID": "Billing reference Id",
    "ERROR_DATA_C3_CUSTOMER_ID": "Customer C3 Id",
    "ERROR_DATA_C3_RECORD_ID": "Record Id",
    "ERROR_DATA_C3_RESELLER_ID": "Reseller C3 Id",
    "ERROR_DATA_C3_SUBSCRIPTION_ID": "C3 Subscription Id",
    "ERROR_DATA_COUNTRY_CODE": "Country code",
    "ERROR_DATA_CUSTOMER_NAME": "Customer name",
    "ERROR_DATA_DOMAIN_PREFIX": "Domain name",
    "ERROR_DATA_EMAIL_ID": "Email id",
    "ERROR_DATA_ENTITY_NAME": "Entity name",
    "ERROR_DATA_EVENT_NAME": "Event name",
    "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID": "Has Supports Resellers With MPN ID",
    "ERROR_DATA_INPUT_JSON": "Input data",
    "ERROR_DATA_PROVIDER_CUSTOMER_ID": "Provider Customer Id",
    "ERROR_DATA_PROVIDER_NAME": "Provider Name",
    "ERROR_DATA_RESELLER_ID": "Reference Id",
    "ERROR_DATA_RESELLER_MPNID": "Reseller Provider Id",
    "ERROR_DATA_RESELLER_PROVIDER_ID": "Reseller Provider Id",
    "ERROR_DATA_SEARCH_VALUE": "Search Value",
    "ERROR_DATA_SUBSCRIPTION_ID": "Subscription Id",
    "ERROR_DATA_TAX_CODE": "Tax Code",
    "ERROR_DATA_TRANSACTION_REFERENCE": "Transaction Reference",
    "ERROR_DATA_USER_ID": "User Id",
    "ERROR_DELETE_DEPARTMENT": "This department contains associated records so can not delete this record.",
    "ERROR_DELETE_DEPARTMENT_PRODUCTS": "This department contains associated purchased products so can not delete this record.",
    "ERROR_DELETE_SITE": "This site contains associated records so can not delete this record.",
    "ERROR_DELETE_SITE_PRODUCT": "This site contains associated purchased products so can not delete this record.",
    "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED": "Unable to create tenant in provider.",
    "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS": "Agent Name cannot be null",
    "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION": "No response from Authorize.net",
    "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION": "Unable to find customer.",
    "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS": "Unable to find this product among your subscriptions",
    "ERROR_DESC_BAD_INPUT": "There seems to be something wrong with your input",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY": "File is empty",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT": "File format is not compatible",
    "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER": "Customer management failed due to bad input.",
    "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION": "Error occured while saving custom notification details",
    "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT": "Error occurred while downloading license consumption report, please contact support.",
    "ERROR_DESC_BAD_INPUT_GET_CUSTOMER": "Customer fetch failed due to bad input.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES": "Getting of payment profiles failed due to bad input.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS": "Getting of payment status failed due to bad input.",
    "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT": "Error occurred while getting license consumption report, please contact support.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE": "Action failed as the payment profile is missing.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION": "Action failed as payment profile configuration is missing",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY": "File is empty",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT": "File format is not compatible",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE": "Error occurred while saving your payment profile, please contact support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES": "There seems to be some issue with your payment profiles, please contact support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER": "There seems to be some issue with your billing provider, please contact support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE": "There's some issue with saving your payment profile, please contact support.",
    "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT": "Error occurred while getting license consumption report, please contact support.",
    "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND": "Provider could not be found",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY": "File is empty",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT": "File format is not compatible",
    "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND": "Service is not implemented. Please contact support.",
    "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES": "There seems to be some issue with your supported payment types for billing provider, please contact support.",
    "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION": "Error occured while saving webhook notifcation details",
    "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS": "Bad request - another job is in progress",
    "ERROR_DESC_BILLING_CUSTOMER_CREATION": "Error occurred while creating/updating billing customer, please contact support.",
    "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID": "Bulk onboard resellers not supported has partner not supporting resellers with MPN ID",
    "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS": "The cart has line items with errors. Please fix them to proceed with this purchase.",
    "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER": "This subscription already used by this customer",
    "ERROR_DESC_CHANGE_CUSTOMER_PLAN": "Error occurred while changing customer plan, please contact support.",
    "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET": "Error occurred while getting source plans for change plan feature, please contact support.",
    "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET": "Error occurred while getting target plans for change plan feature, please contact support.",
    "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE": "Commission End Date cannot be less than Commission Start Date",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID": "Error from ConnectWise Manage while trying to get delete addition by id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID": "Error from ConnectWise Manage while trying to get addition by id and agreement id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID": "Error from ConnectWise Manage while trying to get additions by agreement id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID": "Error from ConnectWise Manage while trying to get agreement detail by id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS": "Error from ConnectWise Manage while trying to get agreements",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID": "Error from ConnectWise Manage while trying to get agreement detail by id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS": "Error from ConnectWise Manage while trying to get agreements",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID": "Error from ConnectWise Manage while trying to get catalog item by id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS": "Error from ConnectWise Manage while trying to get catalog items",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID": "Error from ConnectWise Manage while trying to get customer details by id",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS": "Error from ConnectWise Manage while trying to get customers",
    "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD": "Duplicate entries in imported payments",
    "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES": "Duplicate entries in Catalogue",
    "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS": "Duplicate entries in Catalogue",
    "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT": "Duplicate entry exists in the input",
    "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT": "There are errors in the form",
    "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA": "Error occured while initiating for entity data refersh from external service",
    "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID": "ClientId is invalid. please register your application as Native app in https://dev.powerbi.com/apps and contact support.",
    "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID": "GroupId is invalid, please contact support team.",
    "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD": "Password is invalid, please contact support team.",
    "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID": "Report is invalid, please contact support team.",
    "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME": "UserName is invalid, please contact support team.",
    "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER": "Error occured while deleting the customer. Please contact support.",
    "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE": "There was an error while uploading your image. Please check the image dimensions once again.",
    "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST": "Failed due to bad request. Please try again.",
    "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE": "Failed due to a conflict. Please re-check your credentials",
    "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT": "Gateway timeout. Please try again.",
    "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE": "Service Unavailable. Please check the configuration",
    "ERROR_DESC_FAILED_PAYMENT_INITIATION": "Error occurred while initiating payment, please contact support.",
    "ERROR_DESC_FAILED_TO_DCRYPT_VALUE": "An error occurred while decrypt the value",
    "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE": "An error occurred while encrypt the value",
    "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID": "Control panel vendor AD APP ID or partner tenant id do not exist.",
    "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID": "Microsoft pricing API AD APP ID or partner tenant id do not exist.",
    "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD": "Error occurred while trying to import multiple payments",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT": "Error occurred while trying to import the catalogue input",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS": "Error occurred while trying to import Product Commissions",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS": "Falied to import product extention details",
    "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS": "Failed to onboard reseller under bulk onboard due to validation errors",
    "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP": "An error occured while placing the request for signup",
    "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS": "Failed to send email to the given list of users. Revising your SMTP configurations may help to solve this issue.",
    "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT": "Error occurred while trying to import payment record input",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT": "Error occurred while staging the product catalogue input",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS": "Error occurred while staging the product commissions details",
    "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY": "Failed to sync reserved instances subscription due to product unavailability. Please contact support team.",
    "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT": "You can't update the decimal percentage to a value of the available Pecentage.",
    "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE": "Failed to upload image. Please check your Internet connectivity and make sure that the image size matches the given specifications.",
    "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS": "An error occurred while getting provider resellers bulk onboard batch status",
    "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA": "Error occured while checking status for entity data refersh from external service",
    "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION": "Error occurred while retriving pending payments. Please contact support.",
    "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD": "Missing or Invalid C3Id in Input",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Cost to partner should be +ve number from Input",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Cost to partner should be decimal from Input",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing or Invalid Currency Code in Input",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD": "Missing or Invalid Currency Code",
    "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Currency code should match with product currency from Input",
    "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD": "Invalid Currency code in Input",
    "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing or Invalid Customer Id in Input",
    "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "You cannot upload usage for this guid(customer)",
    "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS": "Invalid Internal Product Id in Catalogue",
    "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES": "Invalid sequence in Catalogue",
    "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD": "Currency code should match with relevant entity",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA": "Invalid entries in Catalogue",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES": "Invalid entries in Catalogue",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS": "Invalid entries in Catalogue",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD": "Missing or Invalid payment amount",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD": "Payment amount should be positive",
    "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD": "Invalid format of payment date",
    "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD": "Invalid payment date",
    "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing or Invalid Product Id in Input",
    "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "You cannot upload usage for the product because either the product does not exist or is not owned by you.",
    "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS": "Invalid Provider Product Id in Catalogue",
    "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Unit Of Measure should not be integer/decimal from Input",
    "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Units should be integer from Input",
    "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Units should be +ve number from Input",
    "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Usage date should be YYYY-MM-DD format from Input",
    "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Usage date should be date type from Input",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Usage date should be past date from Input",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD": "Payment date should be past date",
    "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED": "Invoice is not generated for products purchased",
    "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE": "Item is not purchasable",
    "ERROR_DESC_MATCHING_PLAN_OFFER": "Error occurred while matcchhing plan offers for change plan, please contact support.",
    "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing AgentName in Catalogue",
    "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD": "Missing C3Id in Input",
    "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing Commission End Date in Catalogue",
    "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing Commission Percentage in Catalogue",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing CostToPartner in Input",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "CostToPartner in required for billing type percentage",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing CurrencyCode in Input",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD": "Missing Currency code in Input",
    "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing CustomerId in Input",
    "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing Internal Product Id in Catalogue",
    "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES": "Missing OfferId in Catalogue",
    "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES": "Missing or Invalid Billing Cycle in Catalogue",
    "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES": "Missing or Invalid Category in Catalogue",
    "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES": "Missing or Invalid Provider in Catalogue",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS": "Please check the file you uploaded as it doesn't seem to match the template that is recommended.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD": "Please check the file you uploaded as it doesn't seem to match the template that is recommended.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS": "Please check the file you uploaded as it doesn't seem to match the template that is recommended.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS": "Please check the file you uploaded as it doesn't seem to match the template that is recommended.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS": "Please check the file you uploaded as it doesn't seem to match the template that is recommended",
    "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE": "Please check the file you uploaded as it doesn't seem to match the recommended template.",
    "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD": "Missing or Invalid payment amount in Input",
    "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD": "Missing payment date in Input",
    "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing product id",
    "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing Provider Product Id in Catalogue",
    "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS": "Missing SPCode in Catalogue",
    "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing UnitOfMeasure in Input",
    "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing Units in Input",
    "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Missing UsageDate in Input",
    "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS": "Failed to connect Microsoft azure mamagement API.",
    "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR": "Partner Center exception occurred while trying to change subscription status. Please contact support.",
    "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID": "Error from partner center while verifying MPNID. Please check your input again.",
    "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER": "We are facing an issue while connecting to Partner Center. Please let the administrator refresh the token and try again. Contact support for further assistance.",
    "ERROR_DESC_MS_FAILED_TO_CREATE_USER": "Error from partner center while creating user",
    "ERROR_DESC_MS_FAILED_TO_DELETE_USER": "Error from partner center while deleting user",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS": "Error from Partner Center while trying to get all customers",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS": "Error from Microsoft Partner Center while trying to get all resellers",
    "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES": "Error from Partner Center while trying to get country validation rules",
    "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER": "Error from Partner Center while trying to get customers",
    "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE": "MS Partner Center threw an error while trying to get your profile.",
    "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN": "Failed to fetch refresh token.",
    "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER": "Error from partner center while getting tenant user",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES": "Error from partner center while getting user assigned licenses",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS": "Error from partner center while getting user details",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES": "Error from partner center while getting user roles",
    "ERROR_DESC_MS_FAILED_TO_GET_USERS": "Error from partner center while getting users",
    "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT": "Error from Partner Center while registering customer consent",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER": "Error from partner center while updating user",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS": "Error from partner center while updating user license assignments",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD": "Error from partner center while updating user password",
    "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS": "Error from partner center while getting customers.",
    "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST": "Please check again. File is missing in the request to validate data",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK": "No data available to stage for Autotask",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE": "No data available to stage for ConnectWise Manage",
    "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER": "No provider reseller detail found in Microsoft partner center",
    "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3": "No reseller with MPN ID is found in C3",
    "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM": "Partner is not eligible to purchase item",
    "ERROR_DESC_NOT_FOUND_ERROR": "Unable to find the request",
    "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST": "An error has occurred while processing your request",
    "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER": "Error occurred while scheduling the order.",
    "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT": "Onboading Failed due to invalid tenant.",
    "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY": "Parent offer unavailable for this trial offer",
    "ERROR_DESC_PAYMENT_PROFILE_MENDATED": "Payment profile is missing. Please provide payment profile and try again.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER": "Customer management failed due to provider exception.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE": "Payment profile management failed due to provider exception.",
    "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER": "User deletion failed due to an error in the payment provider portal.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER": "Unable to find a customer with id",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES": "Getting of payment profiles failed due to provider exception.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS": "Getting of payment status failed due to provider exception.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION": "Error occurred while managing billing customer. Please contact support.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION": "Error occurred while managing  payment profile. Please contact support.",
    "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA": "Customer Id can't be null",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS": "Tenant and category selection does not support already mapped products",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS": "Category selection does not support already mapped products",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS": "Tenant selection does not support already mapped products",
    "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA": "Usage date falls in product suspension period",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM": "Provider BusinessId already exists in system",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL": "Provider BusinessId cannot be null",
    "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID": "Billing Email is invalid",
    "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL": "Provider Reseller Country cannot be null",
    "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM": "Provider ResellerId already exists in system",
    "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL": "Provider ResellerId cannot be null",
    "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL": "Provider Reseller Name cannot be null",
    "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3": "Provider reseller detail not linked to c3, please contact your partner or support team",
    "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR": "An error occurred while parsing the uploaded file",
    "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD": "Remarks character length should be less than 200",
    "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST": "Please check again. File is missing in the request to validate data",
    "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM": "Reseller already exists in system",
    "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER": "Select atleast one product",
    "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER": "Payment failed due to database exception.",
    "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS": "Retriving of pending payment failed due to database exception.",
    "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION": "Billing provider configuration failed due to database exception.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT": "Coupon assignment failed due to database exception.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS": "Coupon management failed due to database exception.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER": "Customer management failed due to database exception.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE": "Payment profile management failed due to database exception.",
    "ERROR_DESC_SQL_DELETE_CUSTOMER": "Customer deletion failed due to a database error.",
    "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN": "We're sorry to inform you that a database exception occured while adding offers to plan. Please contact support",
    "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN": "We're sorry to inform you that a database exception occured while cloning the plan. Please contact support",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN": "We're sorry to inform you that a database exception occured while trying to place request for Clone Plan. Please contact support",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS": "We're sorry to inform you that a database exception occured while trying to place request for creating a new plan with all offers. Please contact support",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER": "Error from database while trying to save customer user",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID": "Error from database while saving MPN Id",
    "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER": "Error from database while trying to save customer user",
    "ERROR_DESC_SQL_GET_CUSTOMER": "Customer fetch failed due to database exception.",
    "ERROR_DESC_SQL_GET_PAYMENT_PROFILES": "Getting of payment profiles failed due to database exception.",
    "ERROR_DESC_SQL_GET_PAYMENT_STATUS": "Getting of payment status failed due to database exception.",
    "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS": "Error from database while getting customers.",
    "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED": "The desired number of subscriptions 6 exceeded the maximum number of subscriptions allowed per customer 5",
    "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY": "You don't have permission to change the product status as ownership is shared with operational entities",
    "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL": "Updation of product status was not entirely successful, please contact our support team for assistance",
    "ERROR_DESC_TAX_CODE_ALREADY_EXISTS": "Tax code already exists, Please select a unique tax code.",
    "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG": "The provided SMTP settings are wrong",
    "ERROR_DESC_TRANSACTIONS_ARE_DISABLED": "We are unable to process this request as transactions are disabled.",
    "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY": "Trial offer cannot be purchased with other categories",
    "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST": "Unable to process your request, please contact support",
    "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER": "Unable to process this order",
    "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS": "You're not authorized to access this feature",
    "ERROR_DESC_USAGE_LOCATION_IS_INVALID": "Usage location is invalid",
    "ERROR_DESC_VALIDATE_CUSTOMER_PLAN": "Error occurred while validating customer plan, please contact support.",
    "ERROR_DESC_WHILE_ACCESSING_DATABASE": "Error while accessing database. Please try again after sometime. If the error still persists, please contact support for further assistance.",
    "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT": "An error occurred while adding entitlement",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT": "An error occurred while adding tenant",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT": "An error occurred while providing consent for tenant",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN": "Tenant has been created, but an error occurred while adding the subscription",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN": "An error occurred while assign role",
    "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL": "Error occurred while cancelling scheduled renewal",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID": "The given catalog ID is Invalid",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY": "Error occurred while checking the Microsoft NCE promotion eligibility",
    "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC": "Error occurred while creating a reserved instances subscription by sync",
    "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL": "Error occurred while creating scheduled renewal",
    "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA": "Error while enqueuing message for upload subscriptions history into PSA service",
    "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE": "Error occured while generating bulk invoice",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT": "Error occurred while generating CSV for invoice lineitems summary report",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT": "Error occurred while generating CSV for invoice line items with commission details report",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT": "Error occurred while generating CSV for profitability report",
    "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL": "Error while getting the active PSA service details",
    "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION": "Error occurred while trying to get categories for revenue versus cost comparision",
    "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA": "Error while getting the customer subscriptions mapping to PSA",
    "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY": "Error occurred while trying to get details of revenue versus cost by category",
    "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID": "Error occurred while getting external service subscription detail by c3 subscription id, contract service id and contract id",
    "ERROR_DESC_WHILE_GETTING_EXTERNAL_SEVICE_MAPPED_ENTITIES_AND_CONTRACT_SERVICES_BY_SEVICESNAME": "",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER": "Error occured while getting list of configurations managed by customer",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER": "Error occured while getting list of configurations managed by reseller",
    "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES": "Error occurred while getting the list of subscription end dates",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK": "Error while getting the mapped entities and contract services for Autotask",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE": "Error while getting the mapped entities and products for ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS": "Error occurred while getting non onboarded provider resellers",
    "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS": "Error occurred while getting the portlet parameters",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT": "Error occurred while getting the product count",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE": "Error occurred while getting the product counts as on date",
    "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID": "Error occurred while getting the prmotion detail",
    "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY": "Error while getting the PSA post log summary",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU": "Error occurred while getting the purchased seats by sku id",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD": "Error occurred while getting the purchased seats in given period",
    "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS": "Error while getting the Autotask settings",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS": "Error while getting the PSA service log status",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS": "Error while getting the PSA service post logs",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS": "Error while getting the PSA stagged records",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK": "Error while managing the mapped entities and contract services for Autotask",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE": "Error while managing the mapped entities and products for ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD": "An error occurred while trying to onboard the provider reseller",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER": "Error occured while reverting configurations managed by customer to the default",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER": "Error occured while reverting configurations managed by reseller to the default",
    "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE": "Error occurred while saving or updating the cart line item custom end date",
    "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA": "Error while saving the selected customers subscription history data into staging",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER": "Error occured while updating configurations managed by customer",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER": "Error occured while updating configurations managed by reseller",
    "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL": "Error occurred while updating scheduled renewal",
    "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS": "Error while updating the status of stagged records",
    "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS": "Erorr occured while upgrading the subscription",
    "ERROR_DETAILS": "Error Details",
    "ERROR_DUPLICATE_DEPARTMENT_NMAE": "This department name already exists under this customer and site.",
    "ERROR_DUPLICATE_SITE_NMAE": "This site name already exists under this customer.",
    "ERROR_EMPTY_COMMENTS_SUBMITTED": "Empty comments can’t be submitted",
    "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS": "Failed to import payment details",
    "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS": "Failed to import Product Commission details",
    "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT": "Failed to stage add bulk tenant input",
    "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS": "Error While Genrating details in bulk invoice entity details",
    "ERROR_FIELD_REQUIRED": "This field is required",
    "ERROR_GETTING_HOSTED_TOKEN": "Error while getting hosted token",
    "ERROR_GETTING_STRIPE_DETAILS": "Error occurred while getting stripe details",
    "ERROR_INSUFFICIENT_PRIVILEGES_BODY": "You do not seem to have adequate permissions to perform this action",
    "ERROR_INSUFFICIENT_PRIVILEGES_TITLE": "Forbidden!",
    "ERROR_LICENSE_ASSIGNMENT_INPROGRESS": "Cannot update the status of the product as license assignment is in progress for the product or one of its addons. Please try later.",
    "ERROR_MESSAGE": "Something went wrong!",
    "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT": "Approval is not successfull, please contact support",
    "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS": "You don't have permission to reduce seats",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN": "An error occurred while creating plan. Please contact our support team.",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN": "An error occurred while creating reseller plan. Please contact our support team.",
    "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS": "The SMTP settings are not valid",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY": "We are sorry but the connectivity seems broken between your C3 account and Microsoft Partner Center. This may lead to failure while performing all Partner Center transactions via C3. Please establish connectivity by visiting <b>Settings -> Providers</b>",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY": "We are sorry but the connectivity seems broken between your C3 account and Microsoft Partner Pricing API. This may lead to failure while fetching details from Microsoft Pricing API via C3. Please establish connectivity by visiting <b>Settings -> Providers</b>",
    "ERROR_MESSAGE_INVAILD_CART_NUMBER": "Invalid cart number",
    "ERROR_MESSAGE_INVALID_CUSTOMER": "Invalid Customer",
    "ERROR_MESSAGE_INVALID_TENANT_USER": "The tenant user is not valid",
    "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID": "Status change is not valid.",
    "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN": "Upto 3 markets can be selected.",
    "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST": "No message found for requesting a reseller relationship, please contact C3 support",
    "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS": "Cart contains offers other than Partner - LicenseSupported offers, please remove those to proceed.",
    "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS": "User with this email already exists or you do not have permission to add this user",
    "ERROR_MESSAGE_PURCHASES_MAY_FAIL": "Purchases may fail. Please click to know more.",
    "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED": "Quantity decreament is not allowed",
    "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED": "This customer is not linked with Microsoft csp",
    "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST": "Unable to process your request.",
    "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY": "You have entered more vouchers than necessary",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT": "Error occurred while exporting the invoice line items report.Please contact administrator",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT": "Error occurred while exporting the invoice summary report.Please contact administrator",
    "ERROR_MESSAGE_WHILE_UPLOADING_FILE": "An error occurred while uploading the file",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE": "Error in uploading image. Please try again with another image.",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION": "Please select image extension ex:.jpg,.png etc.",
    "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID": "Error from partner center while verifying MPNID. Please check your input again.",
    "ERROR_MS_FAILED_TO_CREATE_USER": "Error from partner center while creating user",
    "ERROR_MS_FAILED_TO_DELETE_USER": "Error from partner center while deleting user",
    "ERROR_MS_FAILED_TO_GET_TENANT_USER": "Error from partner center while getting tenant user",
    "ERROR_MS_FAILED_TO_GET_USER_DETAILS": "Error from partner center while getting user details",
    "ERROR_MS_FAILED_TO_GET_USER_ROLES": "Error from partner center while getting user roles",
    "ERROR_MS_FAILED_TO_GET_USERS": "Error from partner center while getting users",
    "ERROR_MS_FAILED_TO_UPDATE_USER": "Error from partner center while updating user",
    "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS": "Error from partner center while updating user license assignments",
    "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD": "Error from partner center while updating user password",
    "ERROR_OCCURRED_LOADING_MISSING_OFFERS": "An error occured while trying to enqueue a request for updating latest offers to plan",
    "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY": "Error occurred while checking the promotions eligibility.",
    "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH": "Error occurred while counting the promotions in current month.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS": "Error occured while getting the promotions detail.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER": "Error occurred while getting the promotions details from provider.",
    "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS": "Error occurred while loading the promotions.",
    "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST": "An error has occurred while processing your request",
    "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE": "The error occurred while saving the subscription upgrade details into staging table",
    "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER": "Error occurred while scheduling the order. Please contact support",
    "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE": "The error occurred while trying to fetch the eligible products for subscription upgrade",
    "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM": "Error occurred while updating the promotion id in cartlineItem.",
    "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING": "Product is missing while importing partner offer daily usage data",
    "ERROR_PLAN_ID": "Plan ID",
    "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT": "Please confirm the customer acceptance of Microsoft Customer Agreement",
    "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA": "Error while importing partner offer daily usage data",
    "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS": "Error occurred while trying to import Product Commissions",
    "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE": "Product sequence is having invalid or negative values. Please provide valid sequence.",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE": "The tenant you are trying to onboard/link already exists in portal, kindly contact support",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE": "The tenant you are tring to onboard/link is exists with one of your customers. Please try another tenant.",
    "ERROR_RESELLER_PROVIDER_ID": "Reseller Provider Id",
    "ERROR_SAVING_PARTNER_OFFER": "An error occurred while saving partner offer",
    "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS": "The bundle should contains same category MS offers.",
    "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER": "Error from database while trying to save customer user",
    "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID": "Error from database while saving MPN Id",
    "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER": "Error from database while trying to save customer user",
    "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR": "Only custom offers are eligible for the trial offer.",
    "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR": "Only quantity-based offers are eligible for the trial offer.",
    "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT": "Unable to find a provider tenant.",
    "ERROR_WHILE_ACCESSING_DATABASE": "Error while accessing database",
    "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER": "Error occurred while trying to check reseller has linked with provider",
    "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE": "Error occurred while downloading .csv sample bulk upload template file",
    "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID": "Error occurred while trying to getting invoice dues",
    "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS": "Error occurred while getting the valued product seats",
    "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE": "Please select at least one invoice for posting to Xero.",
    "ERRORMESSAGEPOPUP_BUTTON_OK": "Ok",
    "ERRORMESSAGEPOPUP_HEADER": "Please include atleast one of the prerequisites for the add-ons in the below listing",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON": "Add-on",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER": "Prerequisite Offer",
    "ERRORS_FROM_PREVIOUS_ACTION": "Your last action resulted in errors, click for details ...",
    "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST": "An error occurred while processing your request. Please contact Support.",
    "EVENT_AZURE_PLAN_SUBSCRIPTION_ID": "Azure plan subscriptionId",
    "EVENT_AZURE_SUBSCRIPTION_UPGRADEID": "Upgrade ID",
    "EVENT_BATCH_ID": "Batch Id",
    "EVENT_BATCH_STEP_ID": "Batch Step Id",
    "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER": "Bulk onboard reseller batch summary",
    "EVENT_CONSTANT_TRUE": "True",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE": "Continue trial offer to subscription failure",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS": "Continue trial offer to subscription success",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE": "Convert trial offer to subscription failure",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS": "Convert trial offer to subscription success",
    "EVENT_CREATED_BY": "Event Created By",
    "EVENT_DATA_ACCOUNT_MANAGER_C3ID": "Internal Id",
    "EVENT_DATA_ACCOUNT_MANAGER_EMAIL": "Email",
    "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME": "First Name",
    "EVENT_DATA_ACCOUNT_MANAGER_ID": "Account Manager Id",
    "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME": "Last Name",
    "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER": "Phone number",
    "EVENT_DATA_ACCOUNT_NUMBER": "Account Number",
    "EVENT_DATA_ADD_MISSING_OFFERS_ACTION": "Action",
    "EVENT_DATA_ADD_MISSING_OFFERS_ERROR": "Error",
    "EVENT_DATA_ADD_MISSING_OFFERS_Status": "Status",
    "EVENT_DATA_ADDRESS_CITY": "City",
    "EVENT_DATA_ADDRESS_COUNTRY": "Country",
    "EVENT_DATA_ADDRESS_LINE_1": "Address Line1",
    "EVENT_DATA_ADDRESS_LINE_2": "Address Line2",
    "EVENT_DATA_ADDRESS_STATE": "State",
    "EVENT_DATA_ADDRESS_ZIP_CODE": "Zip",
    "EVENT_DATA_AGREEMENT": "Agreement",
    "EVENT_DATA_AGREEMENT_DATE": "Agreement Date",
    "EVENT_DATA_AGREEMENT_LINK": "Agreement Link",
    "EVENT_DATA_AGREEMENT_NAME": "Agreement",
    "EVENT_DATA_AGREEMENT_TYPE": "Agreement Type",
    "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES": "Coupon assigned to",
    "EVENT_DATA_AUTO_RELEASE_NEW_VALUE": " New value",
    "EVENT_DATA_AUTO_RELEASE_OLD_VALUE": "Old value",
    "EVENT_DATA_AUTO_SYNC_ACTION": "Actions",
    "EVENT_DATA_AUTO_SYNC_ACTION_ERROR": "Error",
    "EVENT_DATA_AUTO_SYNC_ACTION_REASON": "Reason",
    "EVENT_DATA_AUTO_SYNC_ACTION_STATUS": "Status",
    "EVENT_DATA_BCC_RECIPIENTS": "BCC Recipients",
    "EVENT_DATA_BILLING_CYCLE_NAME": "Billing cycle name",
    "EVENT_DATA_BILLING_PAYMENT_ALIAS": "Payment Alias",
    "EVENT_DATA_BILLING_PERIOD_ID": "Billing Period Id",
    "EVENT_DATA_BILLING_PROVIDER": "Billing Provider",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID": "Billing provider customer id",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME": "Billing provider customer name",
    "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID": "Billing provider reseller id",
    "EVENT_DATA_BILLING_PROVIDER_USER_ID": "UserId",
    "EVENT_DATA_BUNDLE_PRODUCT_NAME": "Bundle Name",
    "EVENT_DATA_C3_CUSTOMER_NAME": "C3 Customer",
    "EVENT_DATA_C3_PRODUCT_NAME": "C3 Product",
    "EVENT_DATA_C3_SUBSCRIPTION_NAME": "C3 subscription name",
    "EVENT_DATA_CARD_TYPE": "Card Type",
    "EVENT_DATA_CART_ID": "Cart Id",
    "EVENT_DATA_CART_ITEMS": "Cart Items",
    "EVENT_DATA_CATEGORY_ID": "Category Id",
    "EVENT_DATA_CATEGORY_NAME": "Category Name",
    "EVENT_DATA_CC_RECIPIENTS": "CC Recipients",
    "EVENT_DATA_CHANGE_TYPE": "Change Type",
    "EVENT_DATA_CLONED_FROM_PLAN": "Cloned From",
    "EVENT_DATA_COMPANY_NAME": "Company Name",
    "EVENT_DATA_CONSUMED_SEATS": "Consumed Seats",
    "EVENT_DATA_CONSUMPTION_TYPE": "Consumption Type",
    "EVENT_DATA_CONTRACT": "Contract",
    "EVENT_DATA_CONTRACT_NAME": "Contract Name",
    "EVENT_DATA_CONTRACT_SERVICE_NAME": "Contract service name",
    "EVENT_DATA_CONVERSION_RATE": "Conversion Rate",
    "EVENT_DATA_CORRELATION_ID": "Correlation Id",
    "EVENT_DATA_COUNTRY_CODE": "Country Code",
    "EVENT_DATA_COUPON_CODE": "Coupon code",
    "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED": "Number of customers with new assignments",
    "EVENT_DATA_COUPON_DESCRIPTION": "Coupon description",
    "EVENT_DATA_COUPON_DETAILS": "Coupon details",
    "EVENT_DATA_COUPON_DISCOUNT": "Discount",
    "EVENT_DATA_COUPON_EXPIRES_ON": "Expires on ",
    "EVENT_DATA_COUPON_NOOFRECURRENCES": "Number of recurrences",
    "EVENT_DATA_COUPON_VALID_TILL": "Valid till",
    "EVENT_DATA_CREATED_BY_IN_PROVIDER": "Created by in provider",
    "EVENT_DATA_CREATED_DATE_IN_PROVIDER": "Created date in provider",
    "EVENT_DATA_CURRENCY_CODE": "Currency code",
    "EVENT_DATA_CURRENT_QUANTITY": "Current Quantity",
    "EVENT_DATA_CURRENT_STATUS": "Current Status",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE": "New custom billing cycle",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE": "Old custom billing cycle",
    "EVENT_DATA_CUSTOMER_C3_ID": "Customer C3 Id",
    "EVENT_DATA_CUSTOMER_NAME": "Customer name",
    "EVENT_DATA_CUSTOMER_NEW_NAME": "Customer new name",
    "EVENT_DATA_CUSTOMER_OLD_NAME": "Customer old name",
    "EVENT_DATA_CUSTOMER_REFERENCE_ID": "Customer reference id",
    "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID": "Customer signup batchid",
    "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID": "Internal planid",
    "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS": "Are all PSA logs being deactivated?",
    "EVENT_DATA_DEPARTMENT_NAME": "Department name",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE": "Billing cycle",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION": "Description",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME": "Name",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER": "Cost price",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE": "Sell Price",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY": "Validity",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE": "Validity type",
    "EVENT_DATA_DOMAIN": "Domain",
    "EVENT_DATA_DOMAIN_NAME": "Domain Name",
    "EVENT_DATA_EFFECTIVE_FROM": "Effective from",
    "EVENT_DATA_EMAIL": "Email",
    "EVENT_DATA_EMAIL_ADDRESS": "Email",
    "EVENT_DATA_END_BILLING_PERIOD_ID": "End billing periodId",
    "EVENT_DATA_END_DATE": "End date",
    "EVENT_DATA_ENTITY_ID": "Entity id",
    "EVENT_DATA_ENTITY_NAME": "Entity name",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED": "Entity of user",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED": "Entity of user",
    "EVENT_DATA_ERROR": "Error",
    "EVENT_DATA_ERROR_PRODUCT_MAPPING": "Product Mapping With Error",
    "EVENT_DATA_EVENT": "Event",
    "EVENT_DATA_EVENT_ID": "Event id",
    "EVENT_DATA_EVENT_NAME": "Event name",
    "EVENT_DATA_EXCEPTION_MESSAGE": "Exception message",
    "EVENT_DATA_EXPIRES_ON": "ExpiresOn",
    "EVENT_DATA_EXPORT_ERROR": "Export error",
    "EVENT_DATA_EXPORT_STATUS": "Export status",
    "EVENT_DATA_EXTERNAL_AGREEMENTS": "External Agreements",
    "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES": "External Contract Services",
    "EVENT_DATA_EXTERNAL_CUSTOMERS": "PSA Customer",
    "EVENT_DATA_EXTERNAL_PRODUCTS": "PSA Product",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId": "CustomerId",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID": "Subscription History Id",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID": "Subscription Id",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID": "Contract Id",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID": "Contract Service Id",
    "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID": "PSA Customer Id",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR": "Export Error",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS": "Export Status",
    "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE": "Product Mapping Type",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR": "Validation Error",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS": "Validation Status",
    "EVENT_DATA_FAILED_TENANT_ID": "Failed tenant ID",
    "EVENT_DATA_GROUP_DESCRIPTION": "Group description",
    "EVENT_DATA_GROUP_NAME": "Group name",
    "EVENT_DATA_HAS_ADDITIONAL_DETAIL": "Has additional detail",
    "EVENT_DATA_IMPERSONATOR": "Impersonator",
    "EVENT_DATA_INPUT_DATA": "Request detail",
    "EVENT_DATA_INTERNAL_CUSTOMER_ID": "Internal customer id",
    "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID": "Internal Customer Product Id",
    "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL": "Internal Id for new scheduled renewal",
    "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL": "Internal Id for old scheduled renewal",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN": "InternalPlanId of source plan",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN": "InternalPlanId of target plan",
    "EVENT_DATA_INTERNAL_PRODUCT_ID": "C3 Product Id",
    "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID": "Internal Subscription Id",
    "EVENT_DATA_INVOICE_LINEITEM": "Invoice Line Item Name",
    "EVENT_DATA_INVOICE_NUMBER": "Invoice Number",
    "EVENT_DATA_INVOICE_PROPERTY": "Property Name",
    "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE": "Old Value",
    "EVENT_DATA_INVOICE_PROPERTY_VALUE": " New Value",
    "EVENT_DATA_IS_ACCEPTED": "Is request accepted",
    "EVENT_DATA_IS_ENABLED": "IsEnabled",
    "EVENT_DATA_IS_IMMEDIATE_PROVISIONING": "Is this product available for use immediately",
    "EVENT_DATA_JOB_LOG_C3_ID": "JobLogC3Id",
    "EVENT_DATA_LINE_ITEM": "Line Item",
    "EVENT_DATA_LINKED_PRODUCT_NAME": "Linked product name",
    "EVENT_DATA_LINKED_SUBSCRIPTION_NAME": "Linked product name",
    "EVENT_DATA_LOGGED_IN_USER": "Logged In User",
    "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS": "Manage User License Details",
    "EVENT_DATA_MESSAGE": "Message",
    "EVENT_DATA_NAME": "Name",
    "EVENT_DATA_NAME_OF_ENTITY": "Name of Entity",
    "EVENT_DATA_NEW_CONFIGURATION_VALUE": "New value",
    "EVENT_DATA_NEW_COUPON_CODE": "New coupon code",
    "EVENT_DATA_NEW_COUPON_DESCRIPTION": "New coupon description",
    "EVENT_DATA_NEW_COUPON_DISCOUNT": "New coupon discount",
    "EVENT_DATA_NEW_COUPON_EXPIRESON": "New coupon expires on",
    "EVENT_DATA_NEW_COUPON_NAME": "New coupon name",
    "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES": "New coupon no of recurrences",
    "EVENT_DATA_NEW_COUPON_VALIDTILL": "New coupon valid till",
    "EVENT_DATA_NEW_DESCRIPTION": "New plan description",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION": "New distributor offer description",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING": "New distributor offer",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING": "New distributor offer",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME": "New distributor offer name",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER": "New distributor offer",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE": "New distributor offer",
    "EVENT_DATA_NEW_END_DATE_TYPE": "New End Date Type",
    "EVENT_DATA_NEW_ENTITY_NAME": "New Entity",
    "EVENT_DATA_NEW_ENTITY_RECORD_NAME": "Transferred To",
    "EVENT_DATA_NEW_ISPUBLIC": "New plan ispublic",
    "EVENT_DATA_NEW_MARKUP": "New markup",
    "EVENT_DATA_NEW_NAME": "New plan name",
    "EVENT_DATA_NEW_OFFER": "New Offer",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION": "New partner offer description",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING": "New partner offer",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING": "New partner offer",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME": "New partner offer name",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE": "New partner offer",
    "EVENT_DATA_NEW_PLAN": "New Plan",
    "EVENT_DATA_NEW_PRICE": "New price",
    "EVENT_DATA_NEW_PRODUCT_MAPPING": "New Product Mapping",
    "EVENT_DATA_NEW_PROMOTION_ID": "New Promotion Id",
    "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE": "New Effective End Date",
    "EVENT_DATA_NEW_QUANTITY": "New Quantity",
    "EVENT_DATA_NEW_SEATS": "New Seats",
    "EVENT_DATA_NEW_STATUS": "New Status",
    "EVENT_DATA_NEW_VALIDITY": "New Validity",
    "EVENT_DATA_NEW_VALIDITY_TERM": "New Validity",
    "EVENT_DATA_NEW_VALIDITYTYPE": "New Validity Type",
    "EVENT_DATA_NOTIFICATION_RECIPIENTS": "Notification Recipients",
    "EVENT_DATA_OFFER_NAME": "Offer name",
    "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT": "Offer Price After Discount",
    "EVENT_DATA_OLD_CONFIGURATION_VALUE": "Old value",
    "EVENT_DATA_OLD_COUPON_CODE": "Old coupon code",
    "EVENT_DATA_OLD_COUPON_DESCRIPTION": "Old coupon description",
    "EVENT_DATA_OLD_COUPON_DISCOUNT": "Old coupon discount",
    "EVENT_DATA_OLD_COUPON_EXPIRESON": "Old coupon expires on",
    "EVENT_DATA_OLD_COUPON_NAME": "Old coupon name",
    "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES": "Old coupon no of recurrences",
    "EVENT_DATA_OLD_COUPON_VALIDTILL": "Old coupon valid till",
    "EVENT_DATA_OLD_DESCRIPTION": "Old plan description",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION": "Old distributor offer description",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING": "Old distributor offer",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING": "Old distributor offer",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME": "Old distributor offer name",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER": "Old distributor offer",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE": "Old distributor offer",
    "EVENT_DATA_OLD_ENTITY_ID": "Old Entity",
    "EVENT_DATA_OLD_ENTITY_NAME": "Old Entity",
    "EVENT_DATA_OLD_ENTITY_RECORD_NAME": "Transferred From",
    "EVENT_DATA_OLD_ISPUBLIC": "Old plan ispublic",
    "EVENT_DATA_OLD_NAME": "Old plan name",
    "EVENT_DATA_OLD_OFFER": "Old Offer",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION": "Old partner offer description",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING": "Old partner offer",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING": "Old partner offer",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME": "Old partner offer name",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER": "Old partner offer",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE": "Old partner offer",
    "EVENT_DATA_OLD_PLAN": "Old Plan",
    "EVENT_DATA_OLD_PRICE": "Old price",
    "EVENT_DATA_OLD_QUANTITY": "Old Quantity",
    "EVENT_DATA_OLD_SEATS": "Old Seats",
    "EVENT_DATA_OLD_STATUS": "Old Status",
    "EVENT_DATA_OLD_VALIDITY": "Old Validity",
    "EVENT_DATA_OLD_VALIDITY_TERM": "Old Validity",
    "EVENT_DATA_OLD_VALIDITYTYPE": "Old Validity Type",
    "EVENT_DATA_PAID_AMOUNT": "Paid Amount",
    "EVENT_DATA_PAID_DATE": "Paid Date",
    "EVENT_DATA_PARENT_PRODUCT_NAME": "Parent product name",
    "EVENT_DATA_PARENT_SUBCRIPTION_ID": "Azure Plan Subscription Id",
    "EVENT_DATA_PARENT_SUBSCRIPTION_NAME": "Parent product name",
    "EVENT_DATA_PARTNER_NAME": "Partner Name",
    "EVENT_DATA_PAYMENT_PROFILE_ALIAS": "Payment Profile Alias",
    "EVENT_DATA_PAYMENT_PROFILE_ID": "Payment Profile Id",
    "EVENT_DATA_PAYMENT_TYPE": "Payment Type",
    "EVENT_DATA_PHONE_NUMBER": "Phone number",
    "EVENT_DATA_PLAN_COUNT": "No. of Plans",
    "EVENT_DATA_PLAN_DESCRIPTION": "Description",
    "EVENT_DATA_PLAN_ID": "Plan Id",
    "EVENT_DATA_PLAN_NAME": "Plan Name",
    "EVENT_DATA_PLAN_PRODUCT_ID": "PlanProduct Id",
    "EVENT_DATA_PLAN_TYPE": "Plan Type",
    "EVENT_DATA_PRICE": "Price",
    "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID": "Product C3 Subscription Id",
    "EVENT_DATA_PRODUCT_MAPPING": "Product mapping",
    "EVENT_DATA_PRODUCT_NAME": "Product name",
    "EVENT_DATA_PRODUCT_NEW_NAME": "Product new name",
    "EVENT_DATA_PRODUCT_PRICE": "Product price",
    "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID": "Product subscription id",
    "EVENT_DATA_PROMOTION_ID": "Promotion Id",
    "EVENT_DATA_PROVIDER": "Provider",
    "EVENT_DATA_PROVIDER_BUSINESS_ID": "Provider business id",
    "EVENT_DATA_PROVIDER_CUSTOMER_ID": "Provider customer d",
    "EVENT_DATA_PROVIDER_CUSTOMER_NAME": "Provider customer name",
    "EVENT_DATA_PROVIDER_ENTITY_NAME": "Provider Entity Name",
    "EVENT_DATA_PROVIDER_ID": "Provider Id",
    "EVENT_DATA_PROVIDER_NAME": "Provider Name",
    "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID": "Provider Payment Profile Id",
    "EVENT_DATA_PROVIDER_PRICE": "Provider price",
    "EVENT_DATA_PROVIDER_PRODUCT_ID": "Subscription id",
    "EVENT_DATA_PROVIDER_RESELLER_ID": "Provider Reseller Id",
    "EVENT_DATA_PROVIDER_RESELLER_NAME": "Provider Reseller Name",
    "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID": "Provider Subscription Id",
    "EVENT_DATA_PSA_CUSTOMER_NAME": "PSA Customer",
    "EVENT_DATA_PSA_LOG_ID": "Batch id",
    "EVENT_DATA_PSA_NAME": "PSA Name",
    "EVENT_DATA_PSA_PRODUCT_NAME": "PSA Product",
    "EVENT_DATA_QUANTITY": "Quantity",
    "EVENT_DATA_RECORD_ID": "Record Id",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED": "RecordId of User",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED": "RecordId of User",
    "EVENT_DATA_RECORD_INT_ID": "Record Int Id",
    "EVENT_DATA_RECORD_NAME": "Record Name",
    "EVENT_DATA_RELEASED_SEATS": "Released seats",
    "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS": "C3 Product Auto Renew Status",
    "EVENT_DATA_REQUESTED_QUANTITY": "Requested quantity",
    "EVENT_DATA_REQUESTED_STATUS": "Requested status",
    "EVENT_DATA_RESELLER_NAME": "Reseller name",
    "EVENT_DATA_RESELLER_NEW_NAME": "Reseller new name",
    "EVENT_DATA_RESELLER_OLD_NAME": "Reseller old name",
    "EVENT_DATA_RESERVATION_ORDER_ID": "Reservation Order Id",
    "EVENT_DATA_ROLE": "Role",
    "EVENT_DATA_SALE_PRICE": "Sale price",
    "EVENT_DATA_SEAT_LIMIT": "Seat limit",
    "EVENT_DATA_SITE_NAME": "Site Name",
    "EVENT_DATA_SITEDEPARTMENT_NAME": "Department name",
    "EVENT_DATA_SOURCE_CURRENCY": "Source Currency",
    "EVENT_DATA_SOURCE_OFFER_ID": "Source offer Id",
    "EVENT_DATA_SOURCE_PRODUCT_NAME": "Source product name",
    "EVENT_DATA_SOURCE_QUANTITY": "Source quantity",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME": "Source billing cycle",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_ID": "Source Subscription id",
    "EVENT_DATA_SOURCE_VALIDITY": "Source validity",
    "EVENT_DATA_SOURCE_VALIDITY_TYPE": "Source validity type",
    "EVENT_DATA_START_BILLING_PERIOD_ID": "Start billing periodId",
    "EVENT_DATA_START_DATE": "Start date",
    "EVENT_DATA_STATUS": "Status",
    "EVENT_DATA_SUB_TAXES": "Sub Taxes",
    "EVENT_DATA_SUBSCRIPTION_ID": "Subscription Id",
    "EVENT_DATA_SUBSCRIPTION_NAME": "Subscription Name",
    "EVENT_DATA_SUPPORTED_MARKET_CODE": "Supported market code",
    "EVENT_DATA_SUPPORTED_MARKETS": "Supported Markets",
    "EVENT_DATA_SYNCED_PRODUCT": "Number of Synced Products",
    "EVENT_DATA_TARGET_CURRENCY": "Target Currency",
    "EVENT_DATA_TARGET_OFFER_ID": "Target offer Id",
    "EVENT_DATA_TARGET_PRODUCT_NAME": "Target product name",
    "EVENT_DATA_TARGET_QUANTITY": "Target quantity",
    "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME": "Target billing cycle",
    "EVENT_DATA_TARGET_SUBSCRIPTION_ID": "Target subscription id",
    "EVENT_DATA_TARGET_VALIDITY": "Target validity",
    "EVENT_DATA_TARGET_VALIDITY_TYPE": " Target validity type",
    "EVENT_DATA_TAX_CODE": "Tax Code",
    "EVENT_DATA_TAX_NAME": "Tax Name",
    "EVENT_DATA_TAX_PERCENTAGE": "Tax Percentage",
    "EVENT_DATA_TENANT_ID": "Tenant Id",
    "EVENT_DATA_TENANT_NAME": "Tenant Name",
    "EVENT_DATA_TO_RECIPIENTS": "To Recipients",
    "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS": "Number of Affected Products",
    "EVENT_DATA_TOTAL_RELEASED_SEATS": "Number of Affected Seats",
    "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT": "Total transaction amount",
    "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT": "Transaction amount limit",
    "EVENT_DATA_TRANSITION_TYPE": "Transition type",
    "EVENT_DATA_UNSYNCED_PRODUCT": "Number Of Unsynced Products",
    "EVENT_DATA_UPDATED_BUNDLE_NAME": "Updated bundle name",
    "EVENT_DATA_UPDATED_COUPON_NAME": "Updated coupon name",
    "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME": "Updated distributor offer name",
    "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME": "Updated partner offer name",
    "EVENT_DATA_UPDATED_PLAN_NAME": "Updated Plan Name",
    "EVENT_DATA_UPDATED_PRODUCT_MAPPING": "Product Mapping Change",
    "EVENT_DATA_URL": "URL",
    "EVENT_DATA_USAGE_LOCATION": "Usage Location",
    "EVENT_DATA_USER_DISPLAY_NAME": "Display Name",
    "EVENT_DATA_USER_EMAIL": "User Email",
    "EVENT_DATA_USER_TYPE": "User Type",
    "EVENT_DATA_USERNAME": "Username",
    "EVENT_DATA_VALIDATION_ERROR": "Validation error",
    "EVENT_DATA_VALIDATION_STATUS": "Validation status",
    "EVENT_DATA_VALUE": "Value",
    "EVENT_DATA_VOUCHER_ENTITY": "Voucher Entity",
    "EVENT_DATA_VOUCHER_IDS": "Voucher Ids",
    "EVENT_DATA_VOUCHERS": "Vouchers",
    "EVENT_DATE": "Date",
    "EVENT_DEPARTMENT_DESCRIPTION": "Department Description",
    "EVENT_DEPARTMENT_ID": "Department Id",
    "EVENT_DEPARTMENT_NAME": "Department Name",
    "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS": "Accepted NCE terms & conditions to order Microsoft NCE products",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS": "Accepted Terms AndConditions",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE": "Accepted Terms And Conditions Failure",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION": "Activate DangerThreshold Met Subscription",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL": "Activate DangerThreshold Met Subscription",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE": "Activate Danger Threshold Met Subscription Failure",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER": "Provisioning Custom Offer",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE": "Provisioning Custom Offer Failure",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE": "Activate subscription failure",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS": "Activate subscription success",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILED": "Failed to Create Customer",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE": "Add C3 Customer Failure",
    "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS": "Add C3 Customer Success",
    "EVENT_DESC_ADD_ENTITLEMENTS": "Add Entitlement",
    "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE": "Add Entitlement Failure",
    "EVENT_DESC_ADD_GROUP_ADMIN": "Add Group Admin",
    "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE": "Add Group Admin Failure",
    "EVENT_DESC_ADJUST_AZURE_MARGIN": "Adjust Azure Margin",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED": "Adjust Azure Margin Failed",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS": "Adjust Azure Margin",
    "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST": "Approved or Rejected next schedule renewal changes",
    "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST": "Approved or rejected purchase request",
    "EVENT_DESC_APPROVED_PURCHASE_REQUEST": "Approved Purchase Request",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN": "Assign Plan to Customer",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE": "Failed to Assign Plan to Customer",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED": "Assigning missed vouchers failed",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS": "Assigned missed vouchers successfully",
    "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS": "AD Global Admin is granted access to customers automatically",
    "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS": "customer subscription Auto-price updated",
    "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS": "Auto-release unused seats",
    "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH": "Mismatches fixed due to auto-sync",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC": "Changed billing cycle successfully (Auto Sync)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED": "Changing of billing cycle failed (Auto Sync)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC": "Changed billing cycle successfully (Manual Sync)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED": "Changing of billing cycle failed (Manual Sync)",
    "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY": "Bulk onboard batch summary",
    "EVENT_DESC_BULK_USER_UPLOAD": "Bulk User Upload",
    "EVENT_DESC_BULK_USER_UPLOAD_FAILURE": "Bulk User Upload Failure",
    "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED": "Bundle offers are purchased",
    "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER": "Bundle product purchased successfully",
    "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL": "Cancel scheduled renewal success.",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST": "Canceled subscription purchase request",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE": "Canceled Subscription Purchase Request Failure",
    "EVENT_DESC_CART_AVAILABILITY_STATUS": "Cart availability status",
    "EVENT_DESC_CART_CHECKOUT": "Cart Checkout",
    "EVENT_DESC_CART_CHECKOUT_FAILURE": "Cart Checkout Failed",
    "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION": "Notify that seat limit exceeded",
    "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION": "Notify that transaction limit exceeded",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS": "Customer product auto renew status changed",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED": "Customer product auto renew status failed to change",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS": "Product status updated successfully",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE": "Product status update failed",
    "EVENT_DESC_CHANGE_INVOICE_STATUS": "ChangeInvoice Status",
    "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE": "Change Invoice Status Failure",
    "EVENT_DESC_CHANGE_PLAN_FAILURE": "Change Plan Failure",
    "EVENT_DESC_CHANGE_PLAN_SUCCESS": "Change Plan Success",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE": "Clone Currency Conversion Failure",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS": "Clone Currency Conversion Success",
    "EVENT_DESC_CLONE_PLAN": "Successfully cloned plan",
    "EVENT_DESC_CLONE_PLAN_FAILURE": "Failed to clone plan",
    "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS": "Coupon applies to all tagged customers?",
    "EVENT_DESC_COUPON_NAME": "Coupon name",
    "EVENT_DESC_COUPON_STATUS": "Coupon status",
    "EVENT_DESC_CREATE_AZURE_GROUPS": "Create Azure Groups",
    "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE": "Create Azure Groups Failure",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION": "Create Azure Subscription",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE": "Create Azure Subscription Failure",
    "EVENT_DESC_CREATE_CART": "Create Cart",
    "EVENT_DESC_CREATE_CART_FAILURE": "Create Cart Failure",
    "EVENT_DESC_CREATE_CUSTOMER_FAILURE": "Create Customer Failure",
    "EVENT_DESC_CREATE_INVOICE": "Create Invoice",
    "EVENT_DESC_CREATE_ORDER": "Products purchased successfully",
    "EVENT_DESC_CREATE_ORDER_FAILURE": "Purchasing products failed",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE": "Create Payment Profile",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE": "Create Payment Profile Failure",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED": "Failed to create reserved instances subscription by sync",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS": "Successfully created reserved instances subscription by sync",
    "EVENT_DESC_CREATE_SCHEDULED_RENEWAL": "Create scheduled renewal success",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC": "Created subscription successfully (Auto Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED": "Creating of subscription failed (Auto Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED": "Skipped create Subscription from Auto Sync",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC": "Created subscription successfully (Manual Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED": "Creating of subscription failed (Manual Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED": "Skipped creating the subscription from manual sync",
    "EVENT_DESC_CREATE_TAX_RULE": "Create Tax Rule",
    "EVENT_DESC_CREATE_TAX_RULE_FAILURE": "Create Tax RuleFailure",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER": "Create Usage Subscription User",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE": "Create UsageSubscription User Failure",
    "EVENT_DESC_CREATE_USER": "Create User",
    "EVENT_DESC_CREATE_USER_FAILURE": "Create User Failure",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API": "Customer creation through API",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE": "Customer creation through API failure",
    "EVENT_DESC_CUSTOMER_DELETION_FAILURE": "Customer deletion Failure",
    "EVENT_DESC_CUSTOMER_DELETION_SUCCESS": "Customer deletion Success",
    "EVENT_DESC_CUSTOMER_INVOICE_GENERATED": "Customer invoice generated",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE": "Customer name Update Failure",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS": "Customer name Update Success",
    "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION": "Customer seats limit exceeds Notification",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC": "Deactivate PSA log",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC": "Deactivate PSA log failed",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION": "Define Currency Conversion",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE": "Define Currency Conversion Failure",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY": "Define Plan Prices In New Currency",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE": "Define Plan Prices In New Currency Failure",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER": "Delink Billing Customer",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE": "Delink Billing Customer Failure",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER": "Delete Account Manager Success",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED": "Delete Account Manager Failure",
    "EVENT_DESC_DELETE_CART_ITEM": "Deleted Cart Item",
    "EVENT_DESC_DELETE_CART_ITEM_FAILURE": "Deletion of Cart Item Failed",
    "EVENT_DESC_DELETE_CONTRACT_OFFER": "Deleted contract offer successfully",
    "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED": "Deleting of contract offer failed",
    "EVENT_DESC_DELETE_COUPON": "Disabled coupon successfully",
    "EVENT_DESC_DELETE_COUPON_FAILED": "Coupon deletion failed.",
    "EVENT_DESC_DELETE_COUPON_FAILURE": "Failed to disable coupon",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION": "Delete Currency Conversion",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE": "Delete Currency Conversion Failure",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE": "Failed to Delete Currency Conversion",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED": "Delete custom notification failure",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS": "Delete custom notification success",
    "EVENT_DESC_DELETE_CUSTOM_OFFER": "Deleted custom offer successfully",
    "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED": "Deleting of custom offer failed",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT": "Delete customer payment",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE": "Failed to delete customer payment",
    "EVENT_DESC_DELETE_DEPARTMENT_FAILURE": "Delete Department Failure",
    "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS": "Delete Department Success",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER": "Deleted distributor offer successfully",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE": "Deleting of distributor offer failed",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION": "Delete Event Email Notification",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE": "Delete Event Email Notification Failure",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT": "Delete Invoice Adjustment",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE": "Delete Invoice Adjustment Failure",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM": "Delete Invoices LineItem",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE": "Delete Invoices LineItemFailure",
    "EVENT_DESC_DELETE_PARTNER_USER_FAILURE": "Delete Partner User Failure",
    "EVENT_DESC_DELETE_PAYMENT_OPTION": "Delete Payment Option",
    "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE": "Delete Payment Option Failure",
    "EVENT_DESC_DELETE_PLAN": "Delete Plan",
    "EVENT_DESC_DELETE_PLAN_FAILURE": "Delete Plan Failure",
    "EVENT_DESC_DELETE_SITE_FAILURE": "Delete Site Failure",
    "EVENT_DESC_DELETE_SITE_SUCCESS": "Delete Site Success",
    "EVENT_DESC_DELETE_TAX_RULE": "Delete Tax Rule",
    "EVENT_DESC_DELETE_TAX_RULE_FAILURE": "Delete Tax Rule Failure",
    "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE": "Upgrade Azure Subscription Failure",
    "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER": "Delete Usage Subscription User",
    "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE": "Delete Usage Subscription User Failure",
    "EVENT_DESC_DELETE_USER": "Delete User",
    "EVENT_DESC_DELETE_USER_FAILURE": "Delete User Failure",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED": "Delete webhook notification failure",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS": "Delete webhook notification success",
    "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION": "Disable Azure Subscription",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE": "Failed to disable coupon assignment",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS": "Disabled coupon assignment",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN": "Disable Plan to Customer",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE": "Failed to Disable Plan to Customer",
    "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION": "Enable Azure Subscription",
    "EVENT_DESC_ENABLE_COUPON": "Enabled coupon successfully",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE": "Failed to enable coupon assignment",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS": "Enabled coupon assignment",
    "EVENT_DESC_ENABLE_COUPON_FAILED": "Coupon enabling failed.",
    "EVENT_DESC_ENABLE_COUPON_FAILURE": "Failed to enable coupon",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN": "Enable Plan to Customer",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE": "Failed to enable Plan to Customer",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION": "Enable notification when an annual subscription gets renewed",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION": "Enable notification when a monthly subscription gets renewed",
    "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED": "Failed to enqueue request for Bulk Onboarding customers",
    "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN": "Enqueued request to clone reseller plan",
    "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT": "Error Log Notify Report",
    "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL": "Update scheduled renewal failed",
    "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT": "Export customer license report.",
    "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT": "Export user license assignment report",
    "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST": "Failed to approve or reject next scheduled renewal request",
    "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL": "Cancel scheduled renewal failed",
    "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN": "Failed to clone reseller plan",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER": "Failed to connect to Microsoft Partner Center",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API": "Failed to connect to Microsoft Pricing API",
    "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE": "Failed to create On Demand Invoice",
    "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL": "Create scheduled renewal failed",
    "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN": "Failed to enqueue request for cloning reseller plan",
    "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE": "Failed to set Bulk Onboard batch status to Complete",
    "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE": "Failed to set User License Assignment batch status to Complete",
    "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE": "Failed to sync provider customer profile",
    "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE": "Failed to update On Demand Invoice",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY": "Fix Auto Renew status Mismatch Automatically",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED": "Fix Auto Renew status Mismatch Automatically Failed",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY": "Fix Auto Renew status Mismatch Manually",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED": "Fix Auto Renew status Mismatch Manually Failed",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY": "Fixed promotion mismatch successfully (Auto Sync)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED": "Fixing of promotion mismatch failed (Auto Sync)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY": "Fix promotion mismatch manually",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED": "Fix promotion mismatch manually failed",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY": "Fixed quantity mismatch successfully (Auto Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED": "Fixing of quantity mismatch failed (Auto Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY": "Fixed quantity mismatch successfully (Manual Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED": "Fixing of quantity mismatch failed (Manual Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY": "Fixed status mismatch successfully (Auto Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED": "Fixing of status mismatch failed (Auto Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY": "Fixed status mismatch successfully (Manual Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED": "Fixing of status mismatch failed (Manual Sync)",
    "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY": "License consumption discrepancy",
    "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE": "Grant Access to Portal Failure",
    "EVENT_DESC_GRANT_ACCESTO_PORTAL": "Grant access to portal",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS": "Ignore Bulk User Upload Errors",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE": "Ignore Bulk User Upload Errors Failure",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS": "Ignore Subscription Purchase Errors",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS": "Ignore Subscription Update Errors",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE": "Ignore Subscription Update Errors Failure",
    "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE": "Ignore The Error In Subscription Purchase Failure",
    "EVENT_DESC_INVOICE_AND_REPORT": "Invoice and report",
    "EVENT_DESC_INVOICE_PAYMENT_INITIATED": "Invoice Payment Initiated",
    "EVENT_DESC_INVOICES_AND_DUES_REPORT": "Invoice and dues report",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE": "Link Billing Provider Customer Failure",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS": "Link Billing Provider Customer Success",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE": "Link Service Provider Customer Failure",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS": "Link Service Provider Customer Success",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED": "Link service provider non-csp customer failure",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS": "Link service provider non-csp customer success",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED": "Link billing provider customer success",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS": "Link billing provider customer failure",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED": "Link service provider customer failure",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS": "Link service provider customer success",
    "EVENT_DESC_MANAGE_USER_LICENSES_FAILED": "Management of user licenses failed.",
    "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS": "Management of user licenses succeeded.",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES": "Schedule next renewl changes",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED": "Failed to schedule next renewl changes",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS": "NI Partner product provisioning request fulfilled",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS": "NI Partner product provisioning request canceled",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED": "NI Partner product purchased",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED": "NI Partner product quantity updated",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS": "Notify NI Provisioning Product Purchase Activate Status",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS": "Notify NI Provisioning Product Purchase Canceled Status",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC": "Changed offer successfully (Auto Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED": "Changing of offer failed (Auto Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC": "Changed offer successfully (Manual Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED": "Changing of offer failed (Manual Sync)",
    "EVENT_DESC_OFFERS_ARE_PURCHASED": "Products purchased successfully",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC": "Onboarded azure plan successfully (Auto Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED": "Onboarding of azure plan failed (Auto Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC": "Onboarded azure plan successfully (Manual Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED": "Onboarding of azure plan failed (Manual Sync)",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILED": "Onboard customer failure",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE": "Onboard Customer Failure",
    "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS": "Onboard Customer Success",
    "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT": "Partner offers exportable report",
    "EVENT_DESC_PARTNER_USER_ADDED_FAILURE": "Partner User Added Failure",
    "EVENT_DESC_PARTNER_USERADDED": "Partner User Added",
    "EVENT_DESC_PASSWORD_EXPIRED": "Password Expired",
    "EVENT_DESC_PAYMENT_FAILURE": "Payment failure",
    "EVENT_DESC_PAYMENT_SUCCESS": "Payment success",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed": "Partner Center security consent captured failed",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS": "Partner Center security consent captured successfully",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES": "Successfully created Zip for all invoices.",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED": "An error occurred while creating Zip for all invoices.",
    "EVENT_DESC_PRODUCT_ASSIGNMENT": "Product Assignmnet",
    "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE": "Product Assignmnet Failure",
    "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY": "Promotions loaded successfully",
    "EVENT_DESC_PROMOTION_LOADING_FAILED": "Promotions loading failed",
    "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED": "Provider Products Price Updated",
    "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST": "Public sign up assistance request",
    "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE": "Bundle purchase failed",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER": "Purchase New Partner Offer",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE": "Purchase New Custom Offer Failure",
    "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT": "Purchase NI Provisioning Product",
    "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL": "Purchase request pending approval",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS": "Products purchased successfully",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE": "Purchasing products Failed",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT": "Raised request to update purchased product",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED": "Request to update purchase product failed",
    "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION": "Recon mismatch on upfront invoice generation",
    "EVENT_DESC_RECORD_PAYMENT": "Record Payment",
    "EVENT_DESC_RECORD_PAYMENT_FAILURE": "Record Payment Failure",
    "EVENT_DESC_REJECTED_PURCHASE_REQUEST": "Rejected purchase request",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS": "Rejected Terms And Conditions",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE": "Rejected Terms And Conditions Failure",
    "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE": "Reseller name Update Failure",
    "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS": "Reseller name Update Success",
    "EVENT_DESC_RESET_USER_PASSWORD": "Reset user password",
    "EVENT_DESC_RESET_USER_PASSWORD_FAILURE": "Reset User Password Failure",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION": "Revert Customer Configuration",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE": "Revert Customer Configuration Failure",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL": "Revoke access to portal",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE": "Revoke Access to Portal Failure",
    "EVENT_DESC_SAVE_COMMENT": "New comment added",
    "EVENT_DESC_SAVE_CONTRACT_OFFER": "Saved contract offer successfully",
    "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED": "Saving of contract offer failed",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED": "Save custom notification failure",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS": "Save custom notification success",
    "EVENT_DESC_SAVE_CUSTOM_OFFER": "Save Custom Offer",
    "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE": "Save Custom Offer Failure",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE": "Failed to Save Customer Billing Profile",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE": "Save Customer Billing Profile",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING": "Save Customer In Billing",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE": "Save Customer In Billing Failure",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE": "Failed to Save Customer In Billing",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE": "Save Customer Payment Profile",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE": "Save Customer Payment Profile Failure",
    "EVENT_DESC_SAVE_CUSTOMER_USER": "Save customer user",
    "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE": "Save customer user failure",
    "EVENT_DESC_SAVE_DEPARTMENT_FAILURE": "Save Department Failure",
    "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS": "Save Department Success",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER": "Save distributor offer",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE": "Save distributor offer failure",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION": "Save Event Email Notification",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE": "Save Event Email Notification Failure",
    "EVENT_DESC_SAVE_INVITES": "Save Invites",
    "EVENT_DESC_SAVE_INVITES_FAILURE": "Save Invites Failure",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS": "Save Invoice Adjustments",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE": "Save Invoice Adjustments Failure",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE": "Save Partner Bundle",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE": "Save Partner Bundle Failure",
    "EVENT_DESC_SAVE_PARTNER_USER": "Save Partner User",
    "EVENT_DESC_SAVE_PARTNER_USER_FAILURE": "Save Partner User Failure",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE": "Payment profile saved",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE": "Failed to save payment profile",
    "EVENT_DESC_SAVE_PLAN": "Save Plan",
    "EVENT_DESC_SAVE_PLAN_FAILURE": "Save Plan Failure",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY": "Save Plan Products In Target Currency",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE": "Save Plan Products In Target Currency Failure",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT": "Saved provider customer consent",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED": "Failed to Save provider customer consent",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT": "Save Provider Payment",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE": "Save Provider Payment Failure",
    "EVENT_DESC_SAVE_RESELLER": "Save Reseller",
    "EVENT_DESC_SAVE_RESELLER_FAILURE": "Failed to Save Reseller",
    "EVENT_DESC_SAVE_SITE_FAILURE": "Save Site Failure",
    "EVENT_DESC_SAVE_SITE_SUCCESS": "Save Site Success",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE": "Save user configuration failure",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS": "Save user configuration success",
    "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION": "Save Web Message Notification",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE": "Save webhook notification failure",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS": "Save webhook notification success",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED": "Terms and condition acceptance failed",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS": "Terms and conditions accepted successfully",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED": "The attempt to stage the licenses to be added / removed failed.",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS": "Staged the licenses to be added / removed successfully.",
    "EVENT_DESC_SCHEDULE_ORDER": "Schedule order",
    "EVENT_DESC_SCHEDULE_ORDER_FAILED": "Schedule order failed",
    "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE": "Send distributor offer update",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI": "Send Invoice pdf to customer from UI",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY": "Send Invoice pdf to customers transparity",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL": "Subscription usage threshold alerts",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE": "Failed subscription usage threshold alerts",
    "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE": "Set user license assignment batch status to complete",
    "EVENT_DESC_SMTP_CONNECTIVITY_FAILED": "Smtp connectivity failed",
    "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL": "Subscription Expiration Notification For Trial Offer",
    "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT": "Subscription history report",
    "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL": "Suspend When Danger Threshold Met",
    "EVENT_DESC_SUBSCRIPTION_SYNC": "Subscription Sync",
    "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE": "Subscription Sync Failure",
    "EVENT_DESC_SUBSCRIPTION_UPDATED": "Products updated successfully",
    "EVENT_DESC_SUCCESS": "Success",
    "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST": "Successfully approved or rejected next scheduled renewal request",
    "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN": "Successfully cloned reseller plan",
    "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE": "Successfully created On Demand Invoice",
    "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE": "Successfully synced provider customer profile",
    "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE": "Successfully updated On Demand Invoice",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE": "Suspend subscription failure",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS": "Suspend subscription success",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET": "Suspend When Danger Threshold Met",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE": "Suspend When Danger Threshold Met Failure",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED": "failed to Sync provider customer consent",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS": "Synced provider customer consent",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE": "Synchronize Subscriptions From PC Failure",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED": "Synchronize Subscriptions From PC Succeeded",
    "EVENT_DESC_TAGS_ADDED": "Tags Added",
    "EVENT_DESC_TAGS_ADDED_FAILURE": "Tags Added Failure",
    "EVENT_DESC_TAGS_DELETE_DFAILURE": "Tags Deleted Failure",
    "EVENT_DESC_TAGS_DELETED": "Tags Deleted",
    "EVENT_DESC_TAGS_EDITED": "Tags Edited",
    "EVENT_DESC_TAGS_EDITED_FAILURE": "Tags Edited Failure",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC": "Changed term duration successfully (Auto Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED": "Changing of term duration failed (Auto Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC": "Changed term duration successfully (Manual Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED": "Changing of term duration failed (Manual Sync)",
    "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS": "Unsynced Product Details",
    "EVENT_DESC_UPDATE_AZURE_GROUPS": "Update Azure Groups",
    "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE": "Update Azure Groups Failure",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION": "Update Azure Subscription",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE": "Update Azure Subscription Failure",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER": "Updated contract offer successfully",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED": "Updating of contract offer failed",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE": "Update custom billing cycle",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED": "Update custom billing cycle failed",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED": "Update custom notification failure",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS": "Update custom notification success",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY": "Update Partner Offer Quantity",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE": "Update Partner Offer Quantity Failure",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION": "Updated default payment option",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE": "Failed to update default payment option",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE": "Update CustomerPayment Profile",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE": "Update Customer Payment Profile Failure",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE": "Updated customer profile",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE": "Failed to update of customer profile",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION": "Update Default Payment Option",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE": "Update Default Payment Option Failure",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION": "Update Event Email Notification",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE": "Update Event Email Notification Failure",
    "EVENT_DESC_UPDATE_GROUP_ADMIN": "Update Group Admin",
    "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE": "Update Group Admin Failure",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE": "Updated Invoice Properties Failure ",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS": "Updated Invoice Properties successfully",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS": "Update auto release flag",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED": "Update auto release flag failed",
    "EVENT_DESC_UPDATE_LICENSE": "Update License",
    "EVENT_DESC_UPDATE_LICENSE_FAILURE": "Update License Failure",
    "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE": "Update Partner Center Settings Failure",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS": "Update Partner General Settings",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE": "Update Partner General Settings Failure",
    "EVENT_DESC_UPDATE_PARTNER_LOGO": "Update Partner Logo",
    "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE": "Update Partner Logo Failure",
    "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS": "Update Partner Center Settings",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE": "Update Plan Offer Price",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE": "Update Plan Offer Price Failure",
    "EVENT_DESC_UPDATE_PROFILE": "Update Profile",
    "EVENT_DESC_UPDATE_PROFILE_FAILURE": "Update Profile Failure",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS": "Update Provider Settings",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE": "Failed to update Provider Settings",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME": "Updated Product Attributes",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE": "Failed to Update Product Attributes",
    "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT": "Update Quantity NI Provisioning Product",
    "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL": "Update scheduled renewal success",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE": "Updated Service Provider Customer Default Value",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE": "Failed to update Service Provider Customer Default Value",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS": "Update SMTP Settings",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE": "Update SMTP Settings Failure",
    "EVENT_DESC_UPDATE_SUBSCRIPTION": "Products updated successfully",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE": "Updating products Failed",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API": " API Update Subscription",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED": "API Update Subscription Failure",
    "EVENT_DESC_UPDATE_SUBTAX": "Update Subtax",
    "EVENT_DESC_UPDATE_SUBTAX_FAILED": "Update Subtax Failed",
    "EVENT_DESC_UPDATE_TAX_RULE": "Update Tax Rule",
    "EVENT_DESC_UPDATE_TAX_RULE_FAILURE": "Update Tax Rule Failure",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION": "Update Customer Configuration",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE": "Update Customer Configuration Failure",
    "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER": "Update Usage Subscription User",
    "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE": "Update Usage Subscription User Failure",
    "EVENT_DESC_UPDATE_USER": "Update User",
    "EVENT_DESC_UPDATE_USER_FAILURE": "Update User Failure",
    "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION": "Update WebMessage Notification",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED": "Update webhook notification failure",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS": "Update webhook notification success",
    "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION": "Upgrade Azure Subscription",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED": "Voucher assignment failed",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS": "Voucher assigned successfully",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES": "Bulk product mapping failed",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS": "Bulk product mapping succeeded",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL": "The Refresh process for external service entities in C3 is initiated",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES": " The Refresh process for external service entities in C3 has failed",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS": " The Refresh process for external service entities in C3 has succeeded",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE": "Subscription upgrade",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED": "Subscription upgrade failed",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE": "Failed to download customer catalogue.",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS": "Successfully downloaded customer catalogue.",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS": "Download Product Catalogue Success",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE": "Download Product Catalogue Failure",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE": "Download Product Commissions Catalogue Failure",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS": "Download Product Commissions Catalogue Success",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE": "Failed to download report usage catalogue",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS": "Download report usage catalogue successfully",
    "EVENT_ENTITLEMENT_NAME": "Entity Name",
    "EVENT_FILE_NAME": "File Name",
    "EVENT_IMPERSONATOR": "Impersonator",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE": "Import multiple payments failure",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS": "Import multiple payments success",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE": "Failed to import validated data",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS": "Imported validated data successfully",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE": "Import Product Commissions Failure",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS": "Import Product Commissions Success",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE": "Import Product Extensions Failure",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS": "Import Product Extensions Success",
    "EVENT_IMPORT_VOUCHER_FAILURE": "Failed to import the vouchers.",
    "EVENT_IMPORT_VOUCHER_SUCCESS": " Successfully imported the vouchers.",
    "EVENT_IS_LICENSE_ASSIGNED": "Is License Assigned",
    "EVENT_IS_LICENSE_REMOVED": "Is License Removed",
    "EVENT_LINK_RESELLER_TO_PROVIDER": "Link Reseller",
    "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE": "Failed to Link Reseller",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED": "PSA product mapping failed",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS": "Mapped C3 products to products in PSA successfully",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED": "Removing Mapping with PSA Entity failed",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS": "Removed Mapping with PSA Entity successfully",
    "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT": "New plan level seat limit",
    "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER": "Next run enque error notifier",
    "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE": "Please select minimum one recipient type.",
    "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL": "Preview email",
    "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE": "Email notification deleted successfully.",
    "EVENT_NOTIFICATION_LABLE_TEXT_BCC": "BCC",
    "EVENT_NOTIFICATION_LABLE_TEXT_CC": "CC",
    "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED": "Enabled",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE": "Recipient Type",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS": "Recipients",
    "EVENT_NOTIFICATION_LABLE_TEXT_TO": "TO",
    "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE": "Email notification settings saved successfully.",
    "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK": "Please click Preview Email",
    "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE": "to view the email template content.",
    "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS": "BCC recipients",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS": "CC recipients",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT": "Event",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME": "Event name",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED": "Enabled?",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS": "To recipients",
    "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER": "Notify Customer creation through signup to customer",
    "EVENT_Notify_Purchase_Limit_Error": "Notify Purchase Limit Error",
    "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT": "Old plan level seat limit",
    "EVENT_PRODUCT_NAME": "Product Name",
    "EVENT_PURCHASE_APPROVE_REASON": "Reason",
    "EVENT_PURCHASE_PRODUCT_ERROR_REASON": "Reason, if failed",
    "EVENT_PURCHASE_PRODUCT_ID": "Product id",
    "EVENT_PURCHASE_PRODUCT_NAME": "Product name",
    "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID": "Plan product id",
    "EVENT_PURCHASE_PRODUCT_QUANTITY": "Quantity",
    "EVENT_PURCHASE_REASON_ONHOLD": "Reason for onhold",
    "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER": "Send Invoices PDF To Partner",
    "EVENT_SITE_DESCRIPTION": "Site description",
    "EVENT_SITE_ID": "Site id",
    "EVENT_SITE_NAME": "Site name",
    "EVENT_SMTP_TEST_EMAIL": "Smtp test email",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE": "Stage and validate import payments failure",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS": "Stage and validate import payments success",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE": "Failed to stage and validate partner usage report",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS": "Stage and validate partner usage report",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE": "Stage and Validate Product Catalogue Failure",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS": "Stage and Validate Product Catalogue Success",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE": "Stage and Validate Product Commissions Failure",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS": "Stage and Validate Product Commissions Success",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE": "Failed to stage and validate vouchers catalogue.",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS": "Successfully staged and validated vouchers catalogue.",
    "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION": "Subscription Expiration Notification",
    "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL": "Subscription Expiration Notification For Trial Offer",
    "EVENT_TOTAL_RECORD_COUNT": "Record Count",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER": "Unlink Reseller ",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE": "Failed to unlink Reseller",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT": "Updated partner usage product details",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE": "Failed to update partner usage product details",
    "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER": "Update plan offer notification for reseller",
    "EVENT_USER_CONFIGURATION_NAME": "User configuration name",
    "EVENT_USER_CONFIGURATION_VALUE": "User configuration value",
    "EVENT_USER_EMAILID ": "User EmailId",
    "EVENT_VOUCHER_ASSIGNMENT": "Voucher Assignment",
    "EVENT_WEBJOB_STATUS_NOTIFIER": "Web job status notifier",
    "EVNET_DATA_NEW_BUNDLE_DESCRIPTION": "New bundle description",
    "EVNET_DATA_NEW_BUNDLE_NAME": "New bundle name",
    "EVNET_DATA_OLD_BUNDLE_DESCRIPTION": "Old bundle description",
    "EVNET_DATA_OLD_BUNDLE_NAME": "Old bundle name",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED": "Bulk onboard resellers request accepted",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED": "Accepting of bulk onboard resellers request failed",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED": "Onboarding of reseller under bulk onbording failed",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS": "Onboarding of reseller under bulk onboarding completed successfully",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED": "Resellers bulk onbording failed",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS": "Resellers bulk onboarding successfully",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED": "Bulk add entitlements completed",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED": "Bulk add entitlements failed",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED": "Bulk add entitlements request accepted",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED": "Bulk add entitlements request failed",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED": "Bulk add tenants process completed",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED": "Bulk add tenants process Failed",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED": "Bulk add tenants request accepted",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED": "Bulk add tenants request failed",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED": "Process for bulk onboard of resellers completed",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED": "Process for bulk onboard of resellers failed",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE": "Process for bulk onboard of resellers initiated",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED": "Remove reseller relation completed",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED": "Remove reseller relation failed",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED": "Remove reseller relation request failed",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED": "Remove reseller relation tenant request accepted",
    "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND": "Error occured while retrying to send webhook notification",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION": "Error occured while saving or updating custom notification details",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION": "Error while saving webhook notification details",
    "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR": "An error was encountered while attempting to create a cart for the main offer from the trial offer.",
    "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION": "Error while updating staged data for notification details",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION": "Error occured while sending webhook notification",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID": "The provided webhook URL is invalid",
    "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE": "There are no mapped products for billing cycle change. Please contact support team.",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER": "Exception occurred while creating a new provider customer as Provider Customer Agreement Acceptance is not confirmed by Partner",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED": "Provider Customer Agreement Acceptance should be confirmed",
    "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS": "Error occurred while checking has user accepted partner terms and conditions",
    "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT": "Error occurred while getting partner terms and conditions content",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL": "Error occurred while getting provider customer consent acceptance detail",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT": "Error occurred while getting provider customers who not provided provider customer consent",
    "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS": "Error occurred while saving partner terms and conditions acceptance details",
    "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT": "Error occurred while saving provider customer consent",
    "EXISTING": "Existing",
    "EXISTING_REFRESH_TOKEN": "Existing Refresh Token",
    "EXPAND_ALL_PAYMENTS": "Expand Payments",
    "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT": "Are you sure you want to delete this record?",
    "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG": "The expiry check deleted successfully",
    "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG": "The expiry checks has been saved successfully",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT": "Close filters",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER": "Customer name",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT": "Select Customer or Reseller",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1": "Search by customer",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER": "Select Customer",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION": "Select a status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER": "Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT": "Reset",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT": "Search",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1": "Search by status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED": "Abandoned",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED": "De-activated",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR": "Error",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED": "Failed",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS": "In Progress",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED": "Queued",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED": "Stopped",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS": "Success",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING": "Warning",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME": "Billing cycle",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE": "Created date",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME": "Customer",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE": "End date",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS": "Error details",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR": "Export Error",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS": "Export Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME": "Customer name",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE": "LogMessage",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME": "Operation name",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE": "Price",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE": "Provider price",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY": "Quantity",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE": "Start date",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS": "Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT": "Status count",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE": "Subscription History Date",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS": "Validation Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR": "Validation Error",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT": "Department",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE": "Site",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL": "Detail",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY": "Summary",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED": "Failed",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS": "Success",
    "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT": "Initiated the local data refresh from external service",
    "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED": "Deleted",
    "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE": "Couldn't find any contract detail locally to create mapping.",
    "EXTERNAL_SERVICE_IN_ACTIVE": "In-active",
    "EXTERNAL_SERVICE_LOGS_STATUS_TITLE": "External Service post log status",
    "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR": "Abandoning upload due to error while creating entities",
    "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE": "Addition is in active ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT": "Agreement id is missing",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE": "Invoicing is not enabled for this agreement in ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE": "Mapped Agreement is not active in ConnectWise Manage under this customer",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER": "Agreement is not present in ConnectWise Manage under this customer",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER": "Agreements are not mapped for this customer",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER": "Agreements are not present in ConnectWise Manage under this customer",
    "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE": "Ambiguous additions are present, mapping is not possible.",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT": "Contract id is missing",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK": "Invoicing is not enabled for this contract in Autotask",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK": "Mapped contract is not active in Autotask under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER": "Contract is not present in Autotask under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE": "Contract is not present in external service under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER": "Contract service is not mapped under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK": "Mapped contract service is not present in Autotask",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER": "Contract services are not mapped under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER": "Contracts are not mapped for this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER": "Contracts are not present in Autotask under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE": "Contracts are not present in external service under this customer",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE": "Customer is not active in external service",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER": "External customer is not mapped with c3 customer",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE": "Customer is not present in external service",
    "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD": "An attempt to export was attempted previously. The status of this operation is ",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE": "Contract is not active in external service",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT": "External customer id is missing",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE": "Customers are not present in external service",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE": "Error occurred while creating addition in ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT": "Error occurred while creating an adjustment : ",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE": "Error occurred while creating adjustment for price change",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE": "Error occured while creating service",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE": "Failed to create contract charge for one time product",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE": "Error occurred while creating contract service : ",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE": "Error occurred while updating addition in ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK": "Unable to create a mapping as multiple matching services were found in Autotask",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND": "No matching contracts could be found to map automatically.",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE": "No matching external customer could be found",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND": "No matching external product could be found",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER": "Product is not mapped under this customer",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE": "Mapped product is not present in ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER": "Products are not mapped under this customer",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE": "C3 Products do not have mapped external service",
    "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT": "Service id is missing",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE": "Subscription history create date can not be after agreement cancellation date.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE": "Subscription history create date can not be after agreement end date.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE": "Subscription history create date can not be after contract cancellation date.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE": "Subscription history create date can not be after contract end date.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE": "Subscription history create date cannot be before agreement billing start date",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE": "Subscription history create date cannot be before agreement start date",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE": "Subscription history create date cannot be before contract billing start date",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE": "Subscription history create date cannot be before contract start date",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK": "Subscription is in active Autotask",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE": "Subscription is in-active in external service",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING": "Unable to complete automatic mapping as multiple matching contracts were found",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH": "Unable to complete automatic mapping as multiple external customers were matched",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS": "Unable to complete automatic mapping as multiple external products were matched",
    "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD": "Validation is not successful",
    "EXTERNAL_SERVICE_NAME": "External Service Name",
    "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD": "Select customers to validate and upload",
    "EXTERNAL_SERVICE_POST_BATCH_ID": "External service post batch id",
    "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST": "Back to list",
    "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE": "Error ignored successfully",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT": "Export",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT": "Validation",
    "EXTERNAL_SERVICE_POST_LOGS_STATUS": "External service Post logs Status",
    "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS": "External Service post logs",
    "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY": "Test connectivity",
    "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS": "INSTRUCTIONS",
    "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS": "Please click this button to  test connectivity to PSA after configuration complete.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY": "Successfully established connectivity. You can proceed to upload subscriptions logs now.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER": "Please select at least one customer",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK": "Failed to connect to Datto Autotask. Please re-check your configurations in settings page",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE": "The provided ConnectWiseManage details are not valid. Please check your configurations on the settings page.",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE": "Enddate should be greater than startdate",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED": "Please select the Enddate",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE": "Startdate can not be greater than Enddate",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED": "Please select the Startdate",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED": "Your data is being uploaded. You can re-visit this page for more updates",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED": "Validation has commenced",
    "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE": "Note :",
    "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY": "Digital key is incorrect or denied access to this function. The digital key validation has failed. Check that you are using the correct digital key",
    "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF": "There is already a customer existing with the upload reference you have provided.",
    "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT": "Refund not allowed. A refund has already been processed for this transaction.",
    "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS": "Your status is xxxxx and it doesn't enable you to use the API.",
    "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER": "Bank account number cannot be blank",
    "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE": "You don't have an active BPay product",
    "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS": "You cannot change the status of a customer who is cancelled.",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE": "You must provide a value for the 'ChangeFromDate' or the 'PaymentReference' parameter",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER": "You must provide a value for either the 'ChangeFromDate' or 'ChangeFromPaymentNumber' parameter",
    "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE": "Client is NOT active.",
    "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED": "Credit card year/month has already expired.",
    "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND": "Could not find a customer with the provided details.",
    "EZIDEBIT_ERROR_CODE_DATE_FIELD": "You must provide a value for the 'DateField' parameter when 'DateFrom' or 'DateTo' values areprovided.",
    "EZIDEBIT_ERROR_CODE_DATE_FROM": "You must provide a value for the 'DateFrom' or 'DateTo' parameters when the 'DateField' value is provided.",
    "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO": "\"DateFrom\" can be no more than xx months prior to \"DateTo\"",
    "EZIDEBIT_ERROR_CODE_DEBIT_DATE": "You must provide a value for the 'DebitDate' parameter",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED": "Digital key is incorrect or is denied access to this function. The digital key validation has failed. Check that you are using the correct digital key",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED": "Invalid Token. The customer token is not valid for this digital key",
    "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID": "You must provide a value for either the 'EziDebitCustomerID' parameter or the 'YourSystemReference' parameter",
    "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING": "This function is currently unavailable as Ezidebit processing is currently being performed. Please try again later...",
    "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS": "Customer not Active(Status:<Status Code>).Customer status is not A (Active) or N (New)",
    "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS": "Invalid credit card number entered - Your product range does not include xxxxxxx",
    "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS": "Client does not have sufficent Funds to cover this Adjustment Amount.",
    "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH": "Invalid Account Number length",
    "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS": "Invalid value provided for the 'ApplyToAllFuturePayments' parameter. Valid values are: 'YES' or 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB": "Invalid format provided for the 'BankAccountBSB' parameter. Valid values must be six digits only",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED": "Invalid bank account BSB number entered",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED": "Invalid bank account number entered",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED": "Invalid bank account name entered",
    "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID": "'BankReceiptId' could not be determined",
    "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER": "Invalid credit card number entered",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER": "Invalid value provided for the 'ChangeFromPaymentNumber' parameter. Valid values must be numeric and greater than or equal to one.",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE": "Invalid value provided for the 'ChangeFromDate' parameter. Valid values are any future date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE": "Invalid value provided for the 'ChangeToDate' parameter. Valid values are any date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE": "Invalid value provided for the 'ContractStartDate' parameter. Valid values are any future date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD": "Invalid value provided for the 'DateField' parameter. Valid values are: 'PAYMENT' or 'SETTLEMENT'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM": "Invalid value provided for the 'DateFrom' parameter. Valid values are any date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATETO": "Invalid value provided for the 'DateTo' parameter. Valid values are any date in the format of 'YYYYMM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH": "Invalid value provided for the 'DayOfMonth' parameter. Valid values must be between 1 and 31.",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK": "Invalid value provided for the 'DayOfWeek' parameter. Valid values are: 'MON', 'TUE', 'WED', 'THU' or 'FRI'.",
    "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE": "Invalid value provided for the 'DebitDate' parameter. Valid values are any future date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY": "Invalid DigitalKey.",
    "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS": "Invalid direct debit details entered - Your product range does not include direct debits.",
    "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS": "Invalid email address entered",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH": "Invalid credit card expiry month entered",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR": "Invalid credit card expiry year entered",
    "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID": "Invalid value provided for the ‘EzidebitCustomerID’ parameter. Valid values must be 50 characters or less",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE": "Invalid value provided for the 'SchedulePeriodType' paramater. Valid values are: '4', 'F', 'H', 'M', 'N', 'Q', 'W' or 'Y'",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH": "Invalid value provided for the 'FirstWeekOfMonth' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME": "The First Name contains non standard characters which is not permitted",
    "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH": "Invalid value provided for the 'FourthWeekOfMonth' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE": "Parameter conflict. You can't enter a value for both the 'EziDebitCustomerID' and 'YourSystemReference' parameters",
    "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME": "The Last Name contains non standard characters which is not permitted",
    "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER": "The length for the ‘YourPayerNumber’ parameter is greater than the maximum length.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS": "Invalid value provided for the 'LimitToNumberOfPayments' parameter. Valid values must be numeric and greater than or equal to zero.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS": "Invalid value provided for the 'LimitToTotalAmountInCents' parameter. Valid values must be numeric and greater than or equal to zero.",
    "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS": "Invalid value provided for the 'KeepManualPayments' parameter. Valid values are: 'YES' or 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD": "Invalid name on credit card entered",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS": "Invalid value provided for the 'NewPaymentAmountInCents' parameter. Valid values must be greater than or equal to 200 ($2 dollars).",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS": "Invalid value provided for the 'NewStatus' parameter. Valid values are: 'A', 'H' or 'C'",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE": "Invalid value provided for the 'NewYourSystemReference' parameter. Valid values must be 50 characters or less",
    "EZIDEBIT_ERROR_CODE_INVALID_NUMBER": "Parameter conflict. You can't provide a number greater than zero for both 'LimitToNumberOfPayments' and 'LimitToTotalAmountInCents'",
    "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT": "Partial Refund Amount cannot be between the Scheduled Debit Amount and the Total Payment Amount",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS": "Invalid value provided for the 'PaymentAmountInCents' parameter. Valid values must be greater than or equal to 200 ($2 dollars).",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS": "Invalid value provided for the 'PaymentMethod' parameter. Valid values are: 'ALL', 'DR' or 'CR'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE": "Invalid payment reference entered",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE": "Invalid value provided for the 'PaymentSource' parameter. Valid values are: 'ALL', 'SCHEDULED', 'WEB', 'PHONE' or 'BPAY'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE": "Invalid value provided for the 'PaymentType' parameter. Valid values are: 'ALL', 'PENDING', 'SUCCESSFUL' or 'FAILED'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER": "Invalid mobile phone number entered",
    "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE": "Invalid postcode entered",
    "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE": "Payment with reference 'xxxxxxx' could not be found.",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS": "Invalid value provided for the ‘RefundAmountInCents’ parameter. Valid values are a refund amount between 100 and the amount of the original transaction",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT": "The refund payment instrument does not match the original payment instrument",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS": "Invalid value provided for the 'RefundAmountInCents' parameter. Valid values are a refund amount between 100 and the amount of the original transaction",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN": "Refund Amount exceeds the Total Payment Amount",
    "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE": "Invalid value provided for the 'ScheduleStartDate' parameter. Valid values are any future date in the format of 'YYYY-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH": "Invalid value provided for the 'SecondWeekOfMonth' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE": "Invalid value provided for the 'SmsExpiredCard' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION": "Invalid value provided for the 'SmsFailedNotification' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER": "Invalid value provided for the 'SmsPaymentReminder' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH": "Invalid value provided for the 'ThirdWeekOfMonth' parameter. Valid values are: 'YES' or 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_TOKEN": "Invalid Token. The token is not numeric",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER": "Invalid credit card CCV number entered - CCV number must be either 3 or 4 digits long",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME": "Invalid value provided for the ‘CustomerName’ parameter. Value must be less than 80 characters.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS": "Invalid value provided for the ‘CustomerStatus’ parameter. Valid values are: ‘ALL’, ‘HOLD’, ‘PENDING’, ‘CANCELLED’, ‘ACTIVE’.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER": "Invalid value provided for the Order parameter. Valid values are: ‘ASC’, ‘DESC’.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY": "Invalid value provided for the OrderBy parameter. Valid values are: ‘EzidebitCustomerId’, ‘YourSystemReference’, ‘YourGeneralReference’, ‘CustomerCreationDate’.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER": "Invalid value provided for the PageNumber parameter. Valid values must be numeric and greater than zero.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED": "Invalid value entered for ‘Reactivate’ parameter. Valid values are ‘YES’ or ‘NO’",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER": "Invalid value is provided for the ‘YourPayerNumber’ parameter. Valid values are positive integers.",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE": "Invalid value provided for the 'YourGeneralReference' parameter. Valid values must be 50 characters or less",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE": "Invalid value provided for the 'YourSystemReference' parameter. Valid values must be 50 characters or less",
    "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE": "Invalid value for provided for the ‘DebitDate’ parameter. Valid value is any date forward from thirty-one (31) days in the past, in the format of ‘YYYY-MM-DD’",
    "EZIDEBIT_ERROR_CODE_LAST_NAME": "You must provide a value for the 'LastName' parameter",
    "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY": "You must provide a value for the 'DigitalKey' parameter",
    "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS": "You must provide a value for the ‘CustomerStatus’ parameter",
    "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID": "You must provide a value for either the 'PaymentID' or 'BankReceiptID' parameter. Provide a value for either PaymentID or BankReceiptID.",
    "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE": "You have not supplied any credit card or bank account details. Mandatory data has not been provided for the update of billing details",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME": "You must provide a value for the ‘customerName’ parameter",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER": "You must provide a value for the ‘Order’ parameter when the ‘OrderBy’ value is provided.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY": "You must provide a value for the ‘OrderBy’ parameter when the ‘Order’ value is provided.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER": "You must provide a value for the ‘YourPayerNumber’ parameter",
    "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER": "You must provide a valid value for the 'MobileNumber' parameter if you wish for the customer to receive SMS notifications",
    "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE": "You must provide a day of the week when creating a Weekly, Fortnightly, 4 Weekly or Weekday In Month schedule",
    "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS": "No data matched the selection parameters.",
    "EZIDEBIT_ERROR_CODE_NULL_VALUE": "Null value detected, please pass through the empty string instead of null for parameters that you don't wish to supply a value for",
    "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID": "You must provide a value for either the 'PaymentID' or 'BankReceiptID' parameter",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER": "Parameter conflict. You cannot enter a value for both the ‘ChangeFromDate’ and ‘ChangeFromPaymentNumber’ parameters",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH": "Parameter conflict. ‘ScheduleStartDate’ and ‘DayOfMonth’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK": "Parameter conflict. ‘ScheduleStartDate’ and ‘DayOfWeek’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED": "Add payment denied - Only active customers can have payments added to their schedule",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF": "Add payment denied - You already have a payment registered with ref xxxxxxx. A payment reference must uniquely identify a single payment.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE": "Add payment denied - This customer already has xx payments on this date.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS": "Add payment denied - This customer is on hold due to invalid bank account details and these account details have not been changed.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS": "Add payment denied - This customer is on hold due to invalid credit card details and these card details have not been changed.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS": "Add payment denied - This customer's status of xx does not allow new payments to be added.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT": "Add payment denied - This customer's status of ‘xxxxx’ does not allow new payments to be added.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED": "Not Configured for Credit Card Payments. The client account is not configured to process real time credit card payments for the customerâ€™s card type",
    "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE": "You must provide a value for the 'PaymentReference' parameter",
    "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED": "Payment has been processed and cannot be deleted",
    "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT": "Original payment is still being processed. It cannot be refunded at this stage.",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID": "No Payment Record found for PaymentId",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID": "No matching record found for the 'PaymentId' or 'BankReceiptId'",
    "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT": "Refund amount exceeds the total payment amount",
    "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED": "Refunds are not allowed for the client",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD": "You have passed in both credit card and bank account information.Only credit card or bank account data may be passed in, not both.",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD": "Parameter conflict. You can't enter a value for both the 'PaymentID' and 'BankReceiptID' parameters",
    "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS": "Not all required parameters were supplied",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED": "Parameter conflict. ‘ScheduleStartDate’ and ‘FirstWeekOfMonth’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED": "Parameter conflict. ‘ScheduleStartDate’ and ‘FourthWeekOfMonth’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED": "Parameter conflict. ‘ScheduleStartDate’ and ‘SecondWeekOfMonth’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED": "Parameter conflict. ‘ScheduleStartDate’ and ‘ThirdWeekOfMonth’ are not in alignment.",
    "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION": "Payment selected for deletion could not be found.",
    "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED": "You don't use a shared Ezidebit BPay biller code.",
    "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED": "Status update denied - Only customers with a status of ‘A’,’N’ or ‘H’ may be changed using this service.",
    "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER": "Unable to process update - Invalid credit card CCV number entered - CCV number must be 4 digits long for AMEX (WSvc)",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA": "Report data is currently unavailable as payment processing is currently being performed. Please try again later",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT": "Report data is currently unavailable as payment processing is currently being performed (PT). Please try again later...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS": "Report data is currently unavailable as payment processing is currently being performed (SPS). Please try again later...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION": "System is currently unavailable. Please try again later. A connection cannot be opened to the database for some reason. Please contact partner@ezidebit.com.au for assistance",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR": "System is currently unavailable. Please try again later. An unhandled error occurred extracting the details from the database. Please contact partner@ezidebit.com.au for assistance",
    "EZIDEBIT_ERROR_CODE_YES": "You must provide a 'YES' value for at least one of the week of the month parameters when creating a Weekday In Month schedule.",
    "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE": "You must provide a value for the 'YourGeneralReference' parameter",
    "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE": "You must provide a value for the 'YourSystemReference' parameter",
    "EZIDEBIT_NO_ERROR_DETECTED": "No errors detected",
    "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER": "Failed To Assign Customer To Account Manager",
    "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER": "Failed To Assign Reseller To Account Manager",
    "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER": "Product billing cycle update failed",
    "FAILED_TO_CREATE_ACCOUNT_MANAGER": "Failed To Create Account Manager",
    "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS": "Failed to create a new plan with all offers",
    "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS": "Failed to create a new reseller plan with all offers",
    "FAILED_TO_DELETE_ACCOUNT_MANAGER": "Failed To Delete Account Manager",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN": "Failed to enqueue request for clone plan",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS": "Failed to enqueue request for creating a new plan with all offers",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS": "Failed to enqueue request for creating a new reseller plan with all offers",
    "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE": "Failed to get Access Tokens by authetication code recieved from Quickbooks",
    "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE": "Failed to get Access Tokens by authetication code recieved from Xero",
    "FAILED_TO_RESEND_INVOICE": "Failed to resend Invoice",
    "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS": "Failed to save the authentication code recieved from Quickbooks",
    "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO": "Failed to save the authentication code recieved from Xero",
    "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER": "Failed To Unassign Customer Of An Account Manager",
    "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER": "Failed To Unassign Reseller Of An Account Manager",
    "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS": "Failed To Update Account Manager Details",
    "FAQ_CUSTOMER_CATEGORY_GENERAL": "General",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1": "You can easily update the seeds using edit functionality under subscriptions.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2": "You can give access using grant access to portal functionality under user view.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3": "Yes, you can update using our portal.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4": "Yes, you can suspend subscription using c3.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1": "1. How to increase the subscription seeds?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2": "2. How to give access to portal to a normal user?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3": "3. Is there any option to update the payment method using C3?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4": "4. Is C3 have any option to suspend subscriptions?",
    "FAQ_HEADING": "Frequently asked questions",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL": "Application General",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1": "As of now, C3 is supported by English (en-us), Spanish(es) and Taiwanese(tw). Along with this, C3 supports currencies depending upon the Partner Center regions & Billing ",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10": "Yes, this is achievable in C3 by defining Tags. You need to Define Tags (Pair of value) & assign it to customers. For example, you can define a tag named SupportAgent and a nick name of an agent (say, Agent001) as the value. If a Help Desk agent has one of the tags set as SupportAgent and one of the values for this particular tag as Agent001, the agent would be able to see those customers that have the tag value pair {SupportAgent, Agent001} set.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2": "You need to go to Setting>>Email Notification where you can configure C3 to send out event based emails. C3 provides you various events for which you can configure emails. You can also see the preview email as per the event.  You can configure emails notification for an event where any offer is purchased by customer. You can select recipient based upon role or Billing contacts. Additionally, you can also select the CC list & BCC as well. Once this is done, whenever any such event occurs, C3 will send out email notifications to all recipients configured in Email notification setting.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3": " You can download Tax Summary & Tax Line Items reports from Revenue & Cost Summary tile available on Partner Portal.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4": "Yes, C3 support discounts. You can create discount coupons & apply to it to specific plan or customers as well. You can define different parameters for a coupon such as max redemption, valid from date, valid till date & discount percentage.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5": "You can customize C3 using Logos for Branding. C3 offers you to replace Welcome logo at welcome page & Logged-in Logo with your one. You can even customize notification emails using your logo to enhance branding impact.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6": "Yes, we can make the required changes at our end so that customer will land on login page instead of C3 welcome page.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7": "No, we are not certified yet. But, we are using the configuration of the same level to keep your data safe& secure.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8": "Yes, we can do that. We will provide you one IP address, you need to create a record with same where your DNS is managed. Your DNS provider will help you in doing so.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9": "No, you can directly go to Setting & update the credentials for Partner Center or SMTP in case of any change.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1": "1. What all languages and currencies are supported by C3?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10": "10. Can I have users on my side deal with only a set of customers?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2": " 2. How do I configure C3 to send out emails when a purchase is made by a customer?  How do I configure the recipients of such emails too?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3": "3. What kind of tax reports are available for download?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4": "4. Does C3 support discounts?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5": "5. What kind of customization does C3 offer w.r.t look-and-feel (white labeling / branding)",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6": "6. Is there a way to let my customers click a link from my portal that would take them to the login page instead of the C3 welcome page?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7": "7. Is C3 PCI compliant?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8": " 8. Can I link my preferred domain name to C3? What does it take to do that?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9": "9. Should I contact C3 support when the credentials to my Partner Center or SMTP changed or expired?",
    "FAQ_PARTNER_CATEGORY_BILLING": "Billing",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_1": "Currently, C3 is supporting Manual, Credit Card, ACH (only for US customers) & Bank accounts (only for Australian & New Zealand customers).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_10": "Yes, customers get a copy of invoice as pdf in notification email when invoice is approved by partner.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_11": "In such cases, you can prevent C3 from charging the customers by recording payment against the particular invoice. You need to go to Revenue & Cost Summary>> Select Customer>>Invoices. From here, you need to select the invoice for which you have collected the payment. Here you will find an option as Record Payment.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_12": "We don’t support multiple billing cycles. But in this case, we can align all customer to a particular billing cycle. This is a manual process.  For example, your current billing cycle for one customer is 10th of this month to 9th of next month & in C3, billing cycle to 1st of this month to 1st of next month. Since all customer has made all payment till 9 of this month. We’ll calculate the corresponding amount for 1st to 9th & adjust the same in next invoice which will resolve the issue.  Similarly, we can do the same for other billing cycle as well. From next month onwards, all customer will be aligned to same billing cycle.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_13": "Yes, we can make adjustments regarding the payments in corresponding invoice. There is not automated version for now. We can take care of this.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_14": "Now we are supporting Bill&Pay, Stripe, EziDebit , Authorize.net , Cybersource. If you are using any other than these ones, we can definitely look out for your payment gateway’s integration with C3.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_2": "C3 is designed to support several Payments gateways such as Bill&Pay, Stripe, EziDebit , Authorize.net, CyberSource so far. It would be great if you are using any one of them as your existing payment gateway, or else we can try to design C3 to support your Payment gateways.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_3": "We update C3 offers with latest offer from Microsoft every month. In case of price change, you need to create a new plan or update the existing plan with new offer prices.  By default, C3 will take new price for new customer. But for existing customer, you can set a new price for currently subscribed offer. Additionally, you can choose the effective date for new prices like with immediate effect in price change, next billing cycle or next renewal date.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_4": "In C3, Tax rules are defined by { Country,State Province and Tax Code }, { Country, Zip } or { Country, State Province and Zip } combinations. For example, Tax rule defined for Country - US leaving state province and zip code as blank means that the tax rate is applied to all the customers from US. If you want to define a rule for a particular state, provide the Country and State values while defining the rule.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_5": "Yes, you can do this. You need to go to Setting>> Taxes & create a Tax rule with Tax percentage as 0% . Apply it to customer whom you want to exempt from Tax rule (applicable to other set of customers).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_6": "You can decide & let us know date for invoice generation. We’ll configure it for you & you can verify it by going to Partner Setting>> Billing. Here you can see all dates related to Billings. Even you can change such date for specific customer by going to customer configuration.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_7": "C3 enables you to decide the dates when your customer should be able to see invoice. After invoice generation, partner need to approve the invoice (having made any adjustment if required) to make it available for customers.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_8": "Yes, C3 provide you option to make changes to line items in invoice after invoice generation. You need to make required change (e.g. – you want to add one-time fee/waive off) based upon your requirement before the invoice finalization date. You cannot make any change to invoice once it is finalized.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_9": "In C3, we can configure email notification for such events. In case of any payment success or failure, C3 will shoot an email for the same. All recipient configured for such events will receive notification emails.   Also, C3 has a Payment Failure feature under Revenue & Cost Summary where Partner can track records for any payment failure incident.",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_1": "1. What are the payment methods supported by C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_10": "10. Does the customer get a copy of the invoice as a pdf when the invoice is ready?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_11": "11. What if I collected a payment via check or cash and the customer’s payment method is credit card? How do I prevent C3 from charging the customer for the current invoice?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_12": "12. We have been using our own billing systems and have charged our customers till date. Our customers were having different billing cycles. What kind of support do we get when we migrate to C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_13": "13. Is there a way to deal with Advance payments against an invoice?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_14": "14. What are the payment gateways that C3 supports out-of-the-box?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_2": "2. Can I use my existing payment gateway provider or should I go with what C3 comes with?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_3": "3. What happens when prices change from Microsoft and we want to update our pricing to our customers?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_4": "4. How does C3 deal with taxes?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_5": "5. Can I exempt a particular customer from a tax rule that is applicable to other set of customers?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_6": "6. How do I define when C3 should generate invoices?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_7": "7. Will my customer get to see the invoices right after they get generated?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_8": "8. Do I have an option to make changes to the line items in the invoice after it is generated? Can I add one-time fee / waive off related changes to an invoice?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_9": "9. How do I (Partner) know when a payment succeeded or failed?",
    "FAQ_PARTNER_CATEGORY_SELL": "Sell",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_1": " A plan is group of offers which you can sell to your customer on a price of your own choice. You must have a plan to onboard or create a customer.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_2": " Yes, C3 provides you feature of creating partner offers. For example, you want to provide 24*7 support to your customers. Then, you can create a partner offer 24*7 Office365 Support & sell it on a price of your own choice.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_3": "Yes, you can onboard any existing customer in Partner Center to C3. You need to have Microsoft Id (get from Partner Center) & plan having offer corresponding to subscribed offer in PC. Apart from this, C3 also gives you feature to create a new customer directly from C3 as well (No need to go to PC for customer creation).",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_4": "To accomplish this, we need to onboard such customer with any plan. You can put offer quantity as Zero. Once customer is onboarded successfully, it will automatically sync Usage Based Subscriptions for that particular customer.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_5": " In such cases, there will be quantity mismatch between PC & C3. Our team can fix such mismatches.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_6": "We can make adjustments in invoices, but as of now, C3 doesn’t support refunds.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_7": "Yes, C3 calculate the usage from customers & based on the data consumption, it generates the invoice.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_8": "Yes, you can create a discount coupon with required discount percentage & max recurrences set to 3. Now assign the newly created coupon to specific customer. Once this is done, customer will get the applicable discount for next three invoices.",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_1": "1. What is a plan?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_2": "2. Can I sell non-Microsoft based offers like custom services?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_3": "3. Can I onboard an existing customer?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_4": "4. How can I onboard a customer that has just usage based subscriptions?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_5": "5. My team accidentally purchased licenses via PC, what are the repercussions of such actions? Is there a process in place to fix such discrepancies?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_6": "6. Does C3 support refunds?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_7": "7. Does C3 handle Azure billing for the usage from my customers?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_8": "8. I have a customer onboarded to C3 and I want to provide discount for the next three invoices, can I do that?",
    "FEBRUARY": "February",
    "FIELD_REQUIRED": "{{atribute}} is required",
    "FILTER_SELECT_A_SOURCE_CURRENCY": "Select",
    "FILTER_SELECT_A_TARGET_CURRENCY": "Select",
    "FIX_AUTO_RENEW_STATUS_MISMATCH": "Fix auto renew status mismatch",
    "FIX_AZURE_MISSING_OFFER": "Fix azure missing offer",
    "FIX_BILLING_CYCLE_MISMATCH": "Fix billing cycle mismatch",
    "FIX_MISSING_OFFER": "Fix missing offer",
    "FIX_OFFER_MISMATCH": "Fix offer mismatch",
    "FIX_QUANTITY_MISMATCH": "Fix quantity mismatch",
    "FIX_SCOPE_MISMATCH": "Fix scope mismatch",
    "FIX_STATUS_MISMATCH": "Fix status mismatch",
    "FIX_TERM_DURATION_MISMATCH": "Fix term duration mismatch",
    "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED": ". All rights reserved.",
    "FOOTER_TEXT_1_POWERED_BY": " Powered by ",
    "FROM": "From",
    "GENERATE_INVOICE_REASON": "Reason",
    "GENERATE_INVOICE_REASON_PLACEHOLDER": "Please provide a reason",
    "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE": "Generated Quickbooks Access Tokens by authentication code recieved from Quickbooks",
    "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE": "Generated Xero Access Tokens by authentication code recieved from Xero",
    "GET_BILLING_PROVIDER_DETAILS_EXCEPTION": "Error occurred while retriving the billing provider details.",
    "GET_CURRENCY_CODES_EXCEPTION": "Error occurred while getting the cuurency codes",
    "GET_CURRENCY_CONVERSION_EXCEPTION": "Error occurred while getting the currency conversion",
    "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION": "Error occurred while getting the currency conversions list",
    "GET_CURRENCY_OPTIONS_EXCEPTION": "Error occurred while getting the currency options",
    "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION": "Error occurred while getting the customer events for email notifications",
    "GET_CUSTOMER_LIST_EXCEPTION": "Error occurred while getting customers",
    "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC": "Error occurred while getting the customer plans with promotion active",
    "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION": "Error occurred while getting email notification recipients list",
    "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION": "Error occurred while getting the events for email notifications",
    "GET_PARTNER_LOGO_EXCEPTION": "Error occurred while getting the partner logo",
    "GET_PARTNER_SETTINGS_EXCEPTION": "Error occurred while getting the partner settings",
    "GET_PARTNER_TAX_DETAILS_EXCEPTION": "Error occurred while getting the tax details",
    "GET_PARTNER_TAX_TYPES_EXCEPTION": "Error occurred while getiing the tax types",
    "GET_TARGET_CURRENCIES_EXCEPTION": "Error occurred while gettingthe target currencies",
    "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC": "Error occurred while getting the reseller relationship request.",
    "GOT_RESELLER_DETAILS": "Got the reseller from CSP with name :  {{resellerName}}",
    "government": "Government",
    "Government": "Government",
    "GRAND_ACCESS_USER_EXCEPTION": "This user already have access please check with another",
    "GRAND_TOTAL": "Grand Total",
    "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE": "Consumption By Product",
    "GRAPH_CUSTOMERS_ONBOARDED": "Customers onboarded",
    "GRAPH_DATA_BILLED_AMOUNT": "Billed Amount",
    "GRAPH_DATA_QUANTITY": "Quantity",
    "GRAPH_REVENUE_BY_CUSTOMER_TITLE": "Revenue By Customer",
    "GRAPH_TOP_SELLING_PRODUCT": "Top 10 selling products",
    "GREATER_THAN_ERP": "greaterThanERP",
    "GRID_DOWNLOAD_COUPON_GRID": "Download coupons",
    "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS": "A group admin with this email already exists in this group",
    "GROUP_USERS_OF": "Group Users of ",
    "GROUP_USERS_TOOLTIP_AZUREGROUP": "Group Users",
    "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE": "View the application in a language supported by the application",
    "HEADER_CONTENT_HELP_MENU": "Context sensitive help content presented in the right sidebar",
    "HEADER_CONTENT_MENU_CONTACT_LOG": "Allows partner to view all notifications status and content of the email.",
    "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG": "Track user operation activity.",
    "HEADER_CONTENT_MENU_RESELLER_PROFILE": "Basic profile detail of the signed in user",
    "HEADER_CONTENT_MENU_TOPBAR_SETTINGS": "Manage your C3 account settings and access activity logs.",
    "HEADER_CONTENT_MENUS_PARTNER_SETTINGS": "Lets you manage application level settings, email notifications, define taxes and more ...",
    "HEADER_CONTENT_MENUS_RESELLER_SETTINGS": "Lets you manage application level settings, email notifications, define taxes and more ...",
    "HEADER_CONTENT_SIGNIN_USER": "Basic profile detail of the signed in user",
    "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US": "Contact Us",
    "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT": "Log Out",
    "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED": "User not authorized",
    "HEADER_GO_TO_PROFILE": "Go To Profile",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US": "Contact Us",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION": "Stop Impersonation",
    "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED": "User not authorized",
    "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE": "Language",
    "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE": "Language",
    "HEADER_TITLE_HELP_MENU": "Help",
    "HEADER_TITLE_MENU_CONTACT_LOG": "Notifications",
    "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG": "Audit Log",
    "HEADER_TITLE_MENU_RESELLER_PROFILE": "Profile",
    "HEADER_TITLE_MENU_TOPBAR_SETTINGS": "Settings",
    "HEADER_TITLE_MENUS_PARTNER_SETTINGS": "Settings",
    "HEADER_TITLE_MENUS_RESELLER_SETTINGS": "Settings",
    "HEADER_TITLE_SIGNIN_USER": "Signed in user",
    "HEADING_ENTITY_LIST": "{{EntityName}} list",
    "HELP_TEXT_SMTP_TEST_USERNAME": "Provide the email address to get the test email.",
    "HOURS": " Hour(s) ",
    "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT": "here",
    "I have read and agreed to": "I have read and agreed to",
    "IF_ALREADY_EXISTS": " already exists",
    "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC": "Used to enable/disable the filters in the public signup landing page",
    "IMPERSONATE_WITH_CURRENT_PRIVILEGE": "Along with your current privilege",
    "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE": "Without your current privilege",
    "IMPORT_LIST": "Import list",
    "In Progress": "In Progress",
    "IN_ACTIVE": "In-active",
    "INDEX_LOGOUT_APOLOGY_MESSAGE": "This program was idle for far too long. So we apologize for logging you out, but we had no option.",
    "INDEX_LOGOUT_MESSAGE": "Sorry, you have been Logged Out",
    "INDEX_MSG_LOGOUT_SOON_MESSAGE": "The Idle mode is activated, because you are idle for far too long. As a result, you are going to be logout after in a few moments.",
    "INDXE_LOGOUT_TEXT": "DO SOMETHING FAST! You are getting logged out in {{countdown}} second(s).",
    "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD": "No currencies available on record",
    "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD": "No states available on record",
    "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY": "Please select a country",
    "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY": "Please select source currency first",
    "INITIATE_INVOICE_PAYMENT_CONFIRMATION": "Are you sure you want to initiate invoice charge for <%= currencySymbol%><%= invoiceAmount%> using the payment method: <br> <b><%= PaymentType%> | <%= AccountNumber%></b> ? <br/><small> Please contact support in case you need further assistance on payments that either do not show up or were recorded in error. </small>",
    "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT": "There is no pending amount to be paid.",
    "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION": "All the selected invoices will be queued for payments. Do you want to continue?",
    "INITIATE_STATUS_UPDATE_CONFIRMATION": "Invoice status will change to <%= invoiceStatus%>. Do you want to continue?",
    "INITIATE_TEST_PAYMENT_CONFIRMATION": "Customer will be charged $<%= amount%>. Do you want to continue?",
    "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION": "Reseller will be charged $<%= amount%>. Do you want to continue?",
    "INPUT_BILLINGPERIODID": "BillingPeriodId",
    "INPUT_BILLINGPERIODTYPE": "BillingPeriodType",
    "INPUT_CATEGORYID": "CategoryId",
    "INPUT_CHANGE_AUTO_RENEW_STATUS": "Auto Renew Status",
    "INPUT_CHANGE_TYPE": "ChangeType",
    "INPUT_CONSUMPTIONTYPEID": "ConsumptionTypeId",
    "INPUT_CUSTOMERC3ID": "CustomerC3Id",
    "INPUT_CUSTOMERID": "CustomerId",
    "INPUT_CUSTOMERNAME": "CustomerName",
    "INPUT_DATA": "User input",
    "INPUT_DESCRIPTION": "Description",
    "INPUT_ENDIND": "EndInd",
    "INPUT_INTERNALPRODUCTID": "InternalProductId",
    "INPUT_INVOICEID": "InvoiceId",
    "INPUT_LABEL_PURCHASE_QUANTITY": "Purchase quantity",
    "INPUT_NAME": "Name",
    "INPUT_ONPURCHASEBILLINGACTION": "OnPurchaseBillingAction",
    "INPUT_ONRELEASEBILLINGACTION": "OnReleaseBillingAction",
    "INPUT_PAGECOUNT": "PageCount",
    "INPUT_PAGEINDEX": "PageIndex",
    "INPUT_PAGESIZE": "PageSize",
    "INPUT_PLANC3ID": "PlaId",
    "INPUT_PLANPRODUCTID": "PlanProductId",
    "INPUT_PRICEFORPARTNER": "PriceforPartner",
    "INPUT_PRODUCTID": "ProductId",
    "INPUT_PROVIDERID": "ProviderId",
    "INPUT_PROVIDERSELLINGPRICE": "ProviderSellingPrice",
    "INPUT_QUANTITY": "Quantity",
    "INPUT_REACTIVATE": "ReActivate",
    "INPUT_SALETYPE": "SaleType",
    "INPUT_STARTIND": "StartInd",
    "INPUT_SUSPEND": "Suspend",
    "INPUT_TENANTID": "TenantId",
    "INPUT_WITHADDONS": "WithAddons",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART": "Back to cart",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS": "Back to orders",
    "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER": "Payment profile",
    "INSTANT_PAY_BUTTON_TEXT_PAY": "Pay",
    "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED": "Current payment gateway is not supported with instant pay yet. Please contact support for assistance.",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD": "Payment Method",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT": "Secure Payment",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1": "Upload mapped sheet for mapping in .csv format. Download the <a  href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> file for uploading.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2": "This file is used to map the entities between C3 and AutoTask Manage. Do not delete any existing text or data in this file.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3": "Only fields that needs update are “AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId and ContractServiceName”. Rest of the fields are there to help identify the C3 counterparts for mapping.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4": "If the above mentioned fields are filled in the sheet, the mapping is available, if the above mentioned fields are empty, then mapping is required.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5": "For filling up the mapping sheet you can refer to the <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a>",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6": "Name and Integer (Numerical) Id required for filling up the <b> Mapping Sheet</b> are available in <b>AutoTask Entity Sheet.</b>",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7": "Removal of mapping is not possible through the mapping sheet. To change the mapping of agreement and product, please use the mapping sheet. Customer remapping is only possible through the purchased product mapping screen.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8": "Upload the file and click on SUBMIT.",
    "INSTRUNCTION_PSA_BULK_BUTTON": "Instructions for Purchased Product Mapping Bulk Upload:",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT1": "Upload mapped sheet for mapping in .csv format. Download the <a  href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> file for uploading.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT2": "This file is used to map the entities between C3 and ConnectWise Manage. Do not delete any existing text or data in this file.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT3": "Only fields that needs update are “ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId and ConnectwiseProductname”. Rest of the fields are there to help identify the C3 counterparts for mapping.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT4": "If the above mentioned fields are filled in the sheet, the mapping is available, if the above mentioned fields are empty, then mapping is required.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT5": "For filling up the mapping sheet you can refer to the <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a>",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT6": "Name and Integer (Numerical) Id required for filling up the <b> Mapping Sheet</b> are available in <b>Connectwise Entity Sheet.</b>",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT7": "Removal of mapping is not possible through the mapping sheet. To change the mapping of agreement and product, please use the mapping sheet. Customer remapping is only possible through the purchased product mapping screen.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT8": "Upload the file and click on SUBMIT.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON": "Instructions to use Refresh button:",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1": "Click on Refresh button to get latest data from PSA to C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2": "By default, there will be no data available in Product Mapping in the beginning. Click Refresh button to get all available data from PSA to C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3": "When “Refresh” is in progress, system will disable other tabs. Upon success, disabled tabs will be visible again. In case of error this screen would provide an option to ignore error. You can click on ignore error button to enable the disabled tabs.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4": "When “Refresh” is in progress, system will delete existing PSA data from C3 and insert new data from PSA.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5": "If there any change made in PSA for example, addition or removal of company, agreement, products or change in status for these entities, then user must refresh the product mapping to reflect those changes in C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6": "<span> Please click the </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\"> Refresh</a> button to get latest data from PSA to C3.",
    "INTERNAL_BILLING_ID": "Billing reference ID : ",
    "INTERNAL_SCHEDULE_RENEWAL_ID": "Internal scheduled renewal Id",
    "INTERNAL_SCHEDULED_RENEWAL_ID": "Internal Scheduled Renewal Id",
    "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS": "Auto Renew Status change is not allowed",
    "INVALID_ATTEMPT_DECREASE": "Decreasing quantity is not allowed",
    "INVALID_ATTEMPT_REACTIVATE": "Re-Activating the product is not allowed",
    "INVALID_ATTEMPT_SUSPEND": "Suspending the product is not allowed",
    "INVALID_COUNT": "Total seats cannot be less than 1",
    "INVALID_COUPON": "This voucher is not valid",
    "INVALID_EXPIRY_DATE": "Invalid expiry date",
    "INVALID_INPUT": "Invalid input",
    "INVALID_MESSAGE_ENTITY": "Invalid message entity",
    "INVALID_MESSAGE_TYPE": "Invalid message type",
    "INVALID_PORTAL_PAGE": "Invalid portal page",
    "INVALID_PRODUCT_ID": "A product with the given identifier is not found in the system.",
    "INVALID_RECIPIENTS": "Invalid recipients",
    "INVALID_SEATS": "Missing or invalid detail. Seats should be either a positive or a negative number, limited by the constraints from the Provider",
    "INVALID_VALUE": "{{atribute}} is invalid",
    "INVITES_ADD_BUTTON_BACK_TO_INVITES": "Back to Invites",
    "INVITES_ADD_CAPTION_TEXT_ADD_INVITE": "Add Invite",
    "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE": "Edit Invite",
    "INVITES_ADD_FORM_BUTTON_CLEAR": "Clear All",
    "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN": "Plan",
    "INVITES_ADD_FORM_LABEL_ITEM_SELECTED": "Items selected ",
    "INVITES_ADD_FORM_LABEL_OFFER": "Offers",
    "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME": "Offer name",
    "INVITES_ADD_FORM_TABLE_HEADER_PRICE": "Price",
    "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION": "Description",
    "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME": "Name",
    "INVITES_ADD_FORM_URL_INVITE_URL": "Invite URL",
    "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES": "Invites",
    "INVITES_LIST_BUTTON_ADD_NEW_INVITATION": "Add New invitation",
    "INVITES_LIST_CAPTION_TEXT_INVITES": "Invites",
    "INVITES_LIST_TABLE_HEADER_ACTIONS": "Actions",
    "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT": "Edit",
    "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION": "Invitation description",
    "INVITES_LIST_TABLE_HEADER_INVITATION_NAME": "Invitation name",
    "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION": "Plan description",
    "INVITES_LIST_TABLE_HEADER_PLAN_NAME": "Plan name",
    "INVITES_SAVE_SUCCESS": "Invitation saved successfully",
    "INVITES_SELECT_OFFERS_PROMPT": "Please include atleast one offer in the invitation",
    "INVITES_UPDATE_SUCCESS": "Invitation updated successfully",
    "INVOICE_ADD_ADJUSTMENT_END_DATE": "End Date",
    "INVOICE_ADD_ADJUSTMENT_START_DATE": "Start Date",
    "INVOICE_ADJUSTMENT_CONFIRMATION": "Do you want this due as an adjustment in this invoice?",
    "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION": "Please input the email addresses of recipients, separated by commas (,) or semicolons (;) and click  \"Submit\" to receive a file containing the Invoices and dues report list.",
    "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD": "Dashboard",
    "INVOICE_BREADCRUMB_BUTTON_2_INVOICES": "Invoices",
    "INVOICE_COMMENTS": "Invoice",
    "INVOICE_COUPON_APPLIED_MESSAGE": "Discount coupon <strong>{{CouponCode}}*</strong> is applied",
    "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE": "Balance Due",
    "INVOICE_DOWNLOAD_SELECT_ALL": "Select all",
    "INVOICE_EMAIL_POST_SUCCESS_MESSAGE": "Email is sent successfully to the list of users",
    "INVOICE_FOOTER_LINE_DESC": "Appears on the invoice view and invoice PDF as footer line",
    "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER": "Appears on the invoice view and invoice PDF as footer line",
    "INVOICE_FOOTER_LINE_DISP": "Invoice footer line",
    "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER": "Invoice footer line",
    "INVOICE_GENERATION_REASON": "Invoice generation reason",
    "INVOICE_GENERATION_STATUS_TITLE": "Invoice Generation Status",
    "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY": "Go To Revenue & Cost Summary",
    "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE": "Download invoices/Payments",
    "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY": "Back to revenue & cost summary",
    "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE": "Download invoices/Payments",
    "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS": "Customers invoices importing successful",
    "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER": "Please select at least one customer invoice before proceeding further.",
    "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE": "Invoices to be downloaded for the customers",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE": "Currency code",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT": "Invoice amount",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID": "MicrosoftId",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME": "Qualified name",
    "INVOICE_INVOICE_APPROVE": "Approve",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS": "Back to Invoice line items",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES": "Back to Invoices",
    "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE": "Download Invoice",
    "INVOICE_INVOICE_CAPTION_TEXT": "Invoice for ",
    "INVOICE_INVOICE_FINALIZE": "Finalize",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY": "Account Summary",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS": "Address",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER": "Customer",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY": "Invoice Summary",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD": " Billing period",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT": "Invoice amount",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE": " Invoice date",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE": "Due date",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER": "VAT number",
    "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE": "Tax Invoice",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS": "Invoice Detail",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES": "Invoice dues",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT": "Balance from past payments",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED": "License based",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED": "Usage based",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS": "Actions",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT": " Amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE": "Charge type",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER": "Cost on partner",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE": "Coupon code",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION": "Description",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT": "Discount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT": "Discount amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE": "Offer price",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY": "Quantity",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE": "End date",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL": "Grand total",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT": "Invoice line amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS": "Particulars",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT": " Post tax amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT": "Subtotal amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT": "Tenant",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE": "Start date",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription name",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT": "Tax amount",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE": "Tax percentage",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE": "Unit price",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE": "No invoices found. Please contact c3 support.",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT": "Invoice payments",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT": "Amount",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE": "Date",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON": "Failure reason",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL": "Grand total",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK": "Remarks",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS": "Status",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT": "Due amount",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE": "Due date",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT": "Invoice amount",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE": "Invoice date",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS": "Status",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT": "Paid amount",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT": "Remaining amount",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE": "Transaction date",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT": "Used amount",
    "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS": "Adjustments",
    "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE": "Cycle Instance Prorate",
    "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES": "Past Invoices Dues",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE": "Prorate Fee",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE": "Prorate Fees When Purchase",
    "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE": "Purchase Fee",
    "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT": "Refund Amount",
    "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE": "Renewal Fee",
    "INVOICE_LINE_ITEM_TYPE_TAXES": "Taxes",
    "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT": "Usage Amount",
    "INVOICE_LINE_ITEMS_LABEL_CREDIT": "Credit",
    "INVOICE_LINE_ITEMS_LBL_LINKED_TO": "--linked to {{linkedProductName}}",
    "INVOICE_LINE_ITEMS_LBL_OWNED_BY": "<b>Owned By  </b> {{ownedBy}}",
    "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME": "<b>{{providerName}} Tenant  </b> {{providerTenantName}}",
    "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE": "Conversion rate",
    "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL": "Subtotal",
    "INVOICE_LINE_ITEMS_WITH_TAX_REPORT": "Invoice Line Items With Taxes",
    "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT": "Promo Discount",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS": "Actions",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT": "CSV",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL": "Email",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER": "Invoice line items report",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION": "Select a way to get the invoice line item report",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION": "Enter email address to whom you want to send as email, enter with comma(,) or semicolon(;) and click on submit to receive a file containing list of invoice line item report",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED": "Please input the email addresses of recipients, separated by commas (,) or semicolons (;) and click  \"Submit\" to receive a file containing the Invoice line item report list.",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT": "JSON",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT": "Cancel",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT": "Download",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT": "Send Email",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT": "Submit",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER": "Select a file format",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT": "XLSX",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT": "XML",
    "INVOICE_LIST_CAPTION_TEXT_INVOICES": "Invoices",
    "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS": "Payments",
    "INVOICE_LIST_TABLE_HEADER_ACTIONS": "Actions",
    "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE": "Currency code",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT": "Invoice amount",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE": "Due date",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE": "Generated on",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON": "Failue reason",
    "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT": "Remaining amount",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT": "Paid amount",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE": "Payment date",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID": "Transaction ID",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS": "Remarks",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS": "Payment status",
    "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT": "Used amount",
    "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL": "View contact logs",
    "INVOICE_NUMBER": "Invoice Number",
    "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS": "Customers invoices payments importing successful",
    "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE": "Invoice payments to be downloaded for the customers",
    "INVOICE_PAYMENTS_LBL_OWNED_BY": "<b>Owned By  </b> {{ownedBy}}",
    "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR": "Can't update due date with null value",
    "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE": "Invoice property updated successfully",
    "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR": "The Start Date must not be greater than End Date",
    "INVOICE_SENT_SUCCESSFULLY": "Invoice sent successfully",
    "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW": "Approved",
    "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE": "Finalized",
    "INVOICE_STATUS_NAME_IN_PROGRESS": "In progress",
    "INVOICE_STATUS_NAME_INVOICE_GENERATED": "Generated",
    "INVOICE_STATUS_NAME_INVOICE_PUBLISHED": "Published",
    "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT": "Paid",
    "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT": "Partially paid",
    "INVOICE_TAB_NAME_COMMENTS": "Comments",
    "INVOICE_TAB_NAME_DETAILS": "Details",
    "INVOICE_TAB_NAME_NOTIFICATIONS": "Notifications",
    "INVOICE_TRANSACTION_REPORT_HEADER": "Invoices and dues report",
    "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT": "Type a message",
    "INVOICE_WILLBE_MAILED": "Invoice will be mailed to your billing address. Thank you.",
    "INVOICES_LBL_OWNED_BY": "<b>Owned By  </b> {{ownedBy}}",
    "INVOICES_TABLE_HEADER_BILLING_PERIOD": "Billing period",
    "INVOICES_TABLE_HEADER_COMPANY_NAME": "Company name",
    "INVOICES_TABLE_HEADER_INVOICE_AMOUNT": "Invoice amount",
    "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE": "Invoice due date",
    "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE": "Invoice generated date",
    "INVOICES_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "IS_MANAGEABLE_ONLY_BY_PARTNER": "Is manageable only by partner",
    "IS_MASTER": "Master",
    "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS": "Is Partner Approval Require For Reseller Customers",
    "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS": "Is product being re-activated with Addons",
    "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER": "Is filter available for customer",
    "IS_TRIAL_OFFER": "Trial offer",
    "JANUARY": "January",
    "JULY": "July",
    "JUNE": "June",
    "LABEL_ASSIGNED_RG": "Assigned Resource Groups",
    "LABEL_BANNER_TEXT": "Message",
    "LABEL_BANNER_TEXT_TOOL_TIP": "Write the message for banner.",
    "LABEL_BILLING_ADDRESS": "Billing Address",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT": "The trial offer can only be purchased once.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT": "This trial offer is already available in your cart.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT": "Trial offer for",
    "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE": "Trial offer parent unavailable",
    "LABEL_Customer_Name": "customer name",
    "LABEL_CUSTOMER_NAME": "customer name",
    "LABEL_DESC_SELECTED_PLAN_PRODUCTS": "plan product(s) were selected",
    "LABEL_DESC_SELECTED_PLANS": "plan(s) were chosen",
    "LABEL_DESC_SELECTED_PRODUCT_VARIANTS": "product variant(s) were selected.",
    "LABEL_EFFECTIVE_ENTITY": "Relevant Entity",
    "LABEL_EFFECTIVE_FROM": "Effective from",
    "LABEL_EFFECTIVE_RECORD_NAME": "Effective Record",
    "LABEL_EFFECTIVE_TO": "Expires on",
    "LABEL_IS_ACTIVE": "Is active",
    "LABEL_NOTIFICATION_RECIPIENTS": "Notification Recipients",
    "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION": "Enter the users as comma separated values",
    "LABEL_NOTIFICATION_TABLE": "Banner notifications",
    "LABEL_NOTIFICATION_TYPE": "Message style",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT": "Assign Policy For ",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST": "Policy Assignments Of ",
    "LABEL_POLICY_CUSTOMER": "Customer",
    "LABEL_POLICY_DEFINITION": "Policy Definition",
    "LABEL_POLICY_DESCRIPTION": "Policy Description",
    "LABEL_POLICY_NAME": "Policy Name",
    "LABEL_POLICY_RESOURCE_GROUP_NAME": "Resource Group Name",
    "LABEL_POLICY_SUBSCRIPTION": "Subscription",
    "LABEL_PORTAL_PAGE": "Portal page",
    "LABEL_ROLE_ADD_CUSTOM_ROLE": "add custom role",
    "LABEL_ROLE_BACK_TO_CUSTOM_ROLE": "Back to Custom Roles",
    "LABEL_ROLE_CUSTOM_ROLE_ADD": "Add custom Role",
    "LABEL_ROLE_CUSTOMER": "Customer",
    "LABEL_ROLE_RESOURCE_GROUP_NAME": "Resource Group Name",
    "LABEL_ROLE_ROLE_DEFINITION": " Role Definition",
    "LABEL_ROLE_ROLE_DESCRIPTION": "Role Description",
    "LABEL_ROLE_ROLE_NAME": "Role Name",
    "LABEL_ROLE_SUBSCRIPTION": "Subscription",
    "LABEL_SHIPPING_ADDRESS": "Shipping Address",
    "LABEL_TEXT_ADDONS": "Addons",
    "LABEL_TEXT_ADDRESS_1": "Address 1",
    "LABEL_TEXT_ADDRESS_2": "Address 2",
    "LABEL_TEXT_AVAILABLE_TO_CUSTOMER": "Available to Customer",
    "LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "LABEL_TEXT_BILLING_TYPE": "Billing type",
    "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN": "Cancellation window expires in ",
    "LABEL_TEXT_CATEGORY": "Category",
    "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT": "Create Microsoft Account",
    "LABEL_TEXT_DISCOUNT": "Discount",
    "LABEL_TEXT_DISTRIBUTER_OFFER": "Distributor Offers",
    "LABEL_TEXT_ENABLE_ALL_PROMOTIONS": "Enable all promotions",
    "LABEL_TEXT_LINKED_PRODUCT": "Linked Product",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE": "NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY": "Category : ",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS": "NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP": "NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE": "Perpetual software",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS": "Software subscriptions",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM": "Term",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM": "Term - ",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON": "Term : ",
    "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER": "Not Available to Customer",
    "LABEL_TEXT_OWNER_NAME": "Owner name",
    "LABEL_TEXT_PRICE": "Price",
    "LABEL_TEXT_RESERVED_INSTANCES": "RI",
    "LABEL_TEXT_RETAIL_PRICE": "Retail price",
    "LABEL_TEXT_SOURCE_SUBSCRIPTION": "Source Subscription",
    "LABEL_TEXT_STATE_PROVINCE": "State/Province",
    "LABEL_TEXT_STATUS": "Status",
    "LABEL_TEXT_TARGET_SUBSCRIPTION": "Target Subscription",
    "LABEL_TEXT_TAX_CODE": "Tax code",
    "LABEL_TEXT_TAX_TYPE": "Tax type",
    "LABEL_TEXT_TENANT_NAME": "Tenant Name",
    "LABEL_TEXT_TYPE_IN_NAME": "Type in a name",
    "LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "LABEL_TEXT_ZIP_CODE": "ZIP Code",
    "LABEL_TO_INDICATE_A_NEW_OFFER": "New",
    "LABEL_UNASSIGNED_RG": "Unassigned resource groups",
    "LABLE_ADDITIONAL_CHARGES_TOTAL": "Additional charges",
    "LABLE_CUSTOMER_ADMIN_ROLE": "Customer admin",
    "LABLE_CUSTOMER_READER_ROLE": "Customer reader",
    "LABLE_DISCOUNTS_TOTAL": "Total discount",
    "LABLE_SUBSCRIPTIONS_TOTAL": "Subscriptions total",
    "LABLE_TAXES_TOTAL": "Taxes Total",
    "LABLE_TEXT_COST_PRICE": "Cost price",
    "LABLE_TEXT_COST_TO_PARTNER": "Cost to partner",
    "LABLE_TEXT_DISPLAY_NAME": "Display Name",
    "LABLE_TEXT_EVENTS": "Events",
    "LABLE_TEXT_FRIENDLY_NAME": "Friendly name",
    "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER": "Available to customer?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER": "Promotion available to customer?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER": "Promotion Available To Reseller?",
    "LABLE_TEXT_MAXVALUE": "Max Value",
    "LABLE_TEXT_MINVALUE": "Min Value",
    "LABLE_TEXT_OFFER_NAME": "Offer name",
    "LABLE_TEXT_PROVIDER_SELLING_PRICE": "Provider selling price",
    "LABLE_TEXT_SALE_PRICE": "Sale price",
    "LABLE_TEXT_SALEPRICE": "Sale price : ",
    "LABLE_TEXT_SLAB": "Slab details",
    "LABLE_TEXT_SLAB_SALE_PRICE": "Sale price(UOM)",
    "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER": "Cost to partner",
    "LABLE_TEXT_TYPE": "Billing type",
    "LABLE_TEXT_UNIT_OF_MEASURE": "(UOM)",
    "LABLE_WAIVER": "Waiver",
    "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE": "Last attempt to load the data resulted in failure. Please click refresh to sync the latest changes from PSA to C3.",
    "LATEST_COMMENTS": "Latest Comment",
    "LAYOUTLOGIN_BUTTON_TEXT_LOGIN": "Login",
    "LAYOUTLOGIN_HEADER_TEXT": "Login",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_1": "Please login to continue",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_2": "2016  Spektra Systems. All rights reserved.",
    "LBL_COUPON_CODE_TEXT": "Coupon Code",
    "LBL_DISCOUNT_TEXT": "discount",
    "LBL_OFF": "off",
    "LBL_TEXT_NOT_PROVIDED": "Not provided",
    "LESS_THAN_LIST_PRICE": "lessThanListPrice",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT": "Please find the errors below.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1": "This operation cannot be completed as the prerequisites are not met. Assignment of an add-on requires a base offer and revoking a base offer requires the add-ons to be released.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2": "This operation cannot be completed as the prerequisites are not met. Assignment of an add-on requires a base offer and revoking a base offer requires the add-ons to be released.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER": "Offer",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS": "Prerequisite offers",
    "LICENSE_ASSIGNMENT_STATUS_REJECTED": "Request rejected",
    "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT": "Download license change report",
    "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT": "Getting License Change Report",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY": "Closing quantity",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY": "Created by",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME": "Customer name",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE": "Date of change",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT": "License Change Report",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY": "New qty",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS": "New status",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY": "Old qty",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS": "Old status",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY": "Opening quantity",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME": "Product",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME": "Reseller name",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME": "Linked tenant name",
    "LICENSE_CHANGE_REPORT_END_DATE_ERROR": "The 'from' date of report must not be greater than the 'to' date",
    "LICENSE_CONSUMPTION_REPORT": "License consumption report",
    "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION": "Select",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME": "Customer",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME": "Subscription Name",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME": "Tenant",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME": "Please select customer",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME": "Please enter subscription name",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME": "Please select tenant",
    "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER": "Filters",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE": "Assigned quantity difference",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3": "Available in C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER": "Available in PC",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT": "Assigned licenses in C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT": "Licenses in C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER": "Customer",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS": "Email address",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE": "Total quantity difference",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS": "Mapped products",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT": "Assigned licenses in provider",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT": "Licenses in provider",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT": "Unused licenses in provider",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION": "Subscription",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT": "Tenant",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS": "View details",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT": "License Consumption Report",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT": "Earnings Report",
    "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV": "Export license consumption report CSV",
    "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS": "Tenants",
    "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY": "Getting License Summary",
    "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT": "Download License Summary Report",
    "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY": "License Summary",
    "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME": "Reseller name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME": "Billing cycle",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME": "Parent product name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME": "Parent subscription name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME": "Product name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME": "Provider name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID": "Tenant ID",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY": "Quantity",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN": "Renews/Expires(In Days)",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON": "Renews/Expires on",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME": "Subscription name",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS": "Actions",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE": "Expiry date",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME": "Name",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY": "Voucher key",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION": "License consumption",
    "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS": "Release unused seats",
    "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION": "License consumption",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS": " Release unused seats",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE": "Releasing unused seats. Please wait...",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY": "Assigned",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS": "Mapped products",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY": "Total",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription name(s)",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION": "Actions",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY": "Unused ",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION": "License consumption",
    "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION": "License Consumption",
    "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY": "Assigned",
    "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY": "Valid",
    "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription name",
    "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY": "Available",
    "LICENSES_TOOLTIP_MS_USER": "Licenses",
    "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT": "Link to Billing Profile of ",
    "LINK_CUSTOMER_HEADER_TEXT": "Link to Provider of ",
    "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS": "You're linked to all the Providers present",
    "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE": "Customer Type",
    "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE": "Customer Type",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID": "Tenant Id",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME": "Tenant Name",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE": "Successfully linked microsoft non-csp customer to c3.",
    "LINK_RESELLER_CAPTION_TEXT": "Link Reseller",
    "LINK_RESELLER_FORM_LABEL_PROVIDER": "Provider",
    "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER": "Reseller Id (as in provider)",
    "LINK_RESELLER_LIST_BUTTON_DELETE": "Delete",
    "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS": "Actions",
    "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID": "Business ID",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME": "Provider name",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID": "Provider reseller ID",
    "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED": "You're linked to all your provider profiles.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER": "Link to {{providerName}} has been removed.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER": "Linked to {{providerName}} successfully.",
    "LINK_TEXT_CLICK_HERE": "Click here",
    "LINKED_PRODUCT_INFO_SHOPE": "This product is linked with ",
    "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT": "Select",
    "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING": "Link to a product",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION": "Actions",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE": "Billing cycle",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN": "Validating domain availability",
    "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY": "Category",
    "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER": "Provider",
    "LOADING_INDICATOR_MESSAGE": " Processing...",
    "LOADING_MESSAGE": "Loading...",
    "LOADING_PROMOTIONS_AGAIN_WARNING": "<p>already current month promotions are loaded</p> <p>  do u want to reload the promotions?</p>",
    "LOADPROMOTION_CATEGORY_TOOLTIP": "Please choose the product category from the dropdown list",
    "LOADPROMOTION_PROVIDER_TOOLTIP": "Please choose the solution provider from the dropdown list",
    "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION": "Give us a moment to complete your impersonation as",
    "LOGGED_IN_GETTING_DETAILS_GREETINGS": "Greetings, ",
    "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT": "You are now logged in. Give us a moment to get the application ready for you to access...",
    "MACRO_APPLY_X_PERCENT_ON_MARKUP": "Apply X% on Markup",
    "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE": "Apply X% on Partner Price",
    "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE": "Apply X% on Provider Selling Price",
    "MACRO_COPY_PARTNERT_PRICE": "Copy Partner Price",
    "MACRO_COPY_PROVIDER_SELLING_PRICE": "Copy Provider Selling Price",
    "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC": "Sale Price of the plan product will be sum of partner price + X% on the margin between partner price and provider selling price",
    "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC": "Sale price of the plan product will be partner price + X% (partner price)",
    "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC": "Sale price of the plan product will be provider selling price + X% (provider selling price)",
    "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC": "Sale price of the plan product will be equals to the partner price",
    "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC": "Sale price of the plan product will be equals to provider selling price",
    "MACRO_DET_EXAMPLE": "Example:",
    "MACRO_DET_PARTNER_PRICE": "Partner price",
    "MACRO_DET_PERCENTAGE": "Percentage(X)",
    "MACRO_DET_PROVIDER_SELLING_PRICE": "Provider selling price",
    "MACRO_DET_SALE_PRICE": "Sale price:",
    "MACRO_PERCENTAGE_TOOLTIP_TEXT": "Macro %",
    "MAIL_SENT_SUCCESSFULY": "Mail sent successfully",
    "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE": "Error occurred while processing your request",
    "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE": "Consider in auto release",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO": "No",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES": "Yes",
    "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE": "Is consider in auto release",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE": "Considered in the auto-release",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER": "Manageable exclusively by the partner",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS": "Your request for license assignments is enqueued. Please visit this page after some time for updates.",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR": "Please enter valid email addresses of the users in the tenant",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR": "User {{username}} already holds a license for {{subscriptionname}}",
    "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT": "Please select at least one file",
    "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM": "Are you sure you want to ignore the errors?",
    "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN": "Minimum number of seats against a subscription should be 1. Cannot release seats if the available number of seats is 1.",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM": "Are you sure you want to release unused seats?",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS": "Unused licenses released successfully",
    "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT": "Please add atleast one user to the Licenses to be removed bucket",
    "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT": "Please select user from the suggested list before clicking the Add button",
    "MANAGE_LICENSES_UPDATE_SUCCESS": "License assignment completed successfully",
    "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR": "An error occurred while processing the file, please try again",
    "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS": "License detail uploaded successfully. Please come back to this screen after some time to check the status.",
    "MANAGE_NAME_CHANGE_ERROR": "The name cannot be empty",
    "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE": "Error occurred while processing your request",
    "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS": "(View Offers)",
    "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED": "Products cannot be linked to this offer",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY": "Show Only Promotion",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES": "Yes",
    "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER": "Type in a name",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_1": "Copy the List price to sale price – sale price will be same as List price",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_2": "Add X% to List price – sale price will be X% of List Price + List price",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_3": "Increase the price by Y cents – sale price will be List Price + Y cents",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_4": "Clear all selected offers price",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_5": "Add X% on mark-up - sale price will be List Price + (ERPPrice-List Price)*X%",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X": "Add X% to MS price ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES": "sale price will be X% of MS Price + MS price ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS": "Copy the MS price to sale price ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES": "sale price will be same as MS price ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP": "Add X% on mark-up ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES": "sale price will be MS Price + (ERPPrice-MS Price)*X% ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT": "Increase the price by Y cents ",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES": "sale price will be MS Price + Y cents ",
    "MANAGE_PLAN_LBL_1": "Copy the List price to sale price",
    "MANAGE_PLAN_LBL_2": "Add X% to List price",
    "MANAGE_PLAN_LBL_3": "Increase the price by Y cents",
    "MANAGE_PLAN_LBL_4": "Clear all prices",
    "MANAGE_PLAN_LBL_5": "Add X% on mark-up",
    "MANAGE_PLAN_LBL_6": "Copy the ERP price to sale price",
    "MANAGE_PLAN_NO_MACRO_APPLIED": "No Macro applied",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE": "Close",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE": "Included Offers",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE": "Close",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME": "Name",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE": "Parent names",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT": "Are you sure you want to delete all the add-ons?",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT": "Are you sure you want to delete this product?",
    "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT": "<b>{{productName}}</b> is parent offer for trial product. <br/> Deleting the premium offer will also result in the deletion of the associated trial offe .<br/> Are you sure you want to delete <b>{{productName}}<b/>?",
    "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE": "Addons are added to the plan, please save plan to finish your operation.",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN": " added to plan!",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS": " added to plan with add-ons",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER": " added to plan with a parent offer",
    "MANAGE_PLAN_TOOLTIP_ON_SELECTING_MARKUP_OR_PERCENTAGE_VALUE": "",
    "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR": "No matching add-ons found",
    "MANAGE_PLANS_DELETE_OFFERS_PROMPT": "Do you want to remove the offer?",
    "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR": "The add-on you are trying to add is already included",
    "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES": "Consumption types",
    "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER": "Is trail offer",
    "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS": "Subscription Status",
    "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS": "Supported Markets",
    "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS": "No products found!",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN": "Please check the following offers as the sale price is less than List Price.",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END": "Click 'OK' to submit the plan changes. 'CANCEL' to recheck the plan offers.",
    "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR": "Selected offer was already included",
    "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT": "Please provide Friendly name and Effective price for the offers included in the plan",
    "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN": "Please check the following offers as the sale price is more than ERP Price.",
    "MANAGE_PLANS_SELECT_OFFERS_PROMPT": "Please add offers to the plan",
    "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS": "Add More Addons",
    "MANAGE_PONUMBER_CHANGE_ERROR": "The PONumber cannot be empty",
    "MANAGE_PRODUCT_OWNERSHIP": "Manage product ownership",
    "MANAGE_PRODUCT_OWNERSHIP_FAILED": "Manage Product Ownership Failed",
    "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS": "Managing product ownership is not allowed as license assignment is in progress for one of the products or their addons. Please try later.",
    "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS": "Added new vouchers successfully",
    "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE": "Error occurred while processing your request",
    "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES": "Clear Prices",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1": "Copy the MS price to reseller price – reseller price will be same as MS price",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2": "Add X% to MS price – Reseller price will be X% of MS Price + MS price",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3": "Increase the price by Y cents – reseller price will be MS Price + Y cents",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4": "Clear all selected offers price",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5": "Add X% on mark-up - reseller price will be MS Price + (ERPPrice-MS Price)*X%",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER": "If you want to add the reseller offers you need to following below instruction",
    "MANAGE_RELLER_OFFERS_ITEMS_SELECTED": "Items selected",
    "MANAGE_RELLER_OFFERS_LBL_1": "Copy the MS price to reseller price",
    "MANAGE_RELLER_OFFERS_LBL_2": "Add X% to MS price",
    "MANAGE_RELLER_OFFERS_LBL_3": "Increase the price by Y cents",
    "MANAGE_RELLER_OFFERS_LBL_4": "Clear all prices",
    "MANAGE_RELLER_OFFERS_LBL_5": "Add X% on mark-up",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE": "Do you want to remove the all filtered offers from selected offers?",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE": "Do you want to remove the all selected offers?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE": "Do you want to select all the filtered offers?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE": "Do you want to select the all offers?",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE": "ERP Price",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM": "Valid From",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE": "ERP price",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE": "License type",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE": "List price",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE": "Reseller price",
    "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY": "If you want to reduce the quantity please contact support team.",
    "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS": "Back to scheduled renewals",
    "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL": "Cancel",
    "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE": "Create new schedule",
    "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT": "Submit",
    "MANAGE_RENEWAL_CUMULATIVE_QUANTITY": "Cumulative quantity",
    "MANAGE_RENEWAL_CURRENT_SUBCRIPTION": "Current Subscription",
    "MANAGE_RENEWAL_DESCRIPTION_1": "Changes take effect upon subscription renewal date: <b>{{renewalDate}}</b>. For SKU upgrades, if the quantity doesn't change, licenses will be assigned automatically. Otherwise, you will need to manually assign licenses.",
    "MANAGE_RENEWAL_HEADER_TEXT": "Manage renewal",
    "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE": "Current : {{currentEnddate}}",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS": "Choose from different SKU's :",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE": "Choose different Billing cycle :",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM": "Choose different Term :",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE": "End date",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT": "End date alignment",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE": "End date type",
    "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT": "Plan product",
    "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY": "Quantity",
    "MANAGE_RENEWAL_LABEL_TEXT_SKU": "Choose an eligible plan product for your subscription (use this option if you want to change SKU/ Billing cycle/ Term along with scheduling the changes)",
    "MANAGE_RENEWAL_LABEL_TEXT_TERM": "Term",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT": "Previously selected plan product for scheduled renewal for SKU/Term/Billing cycle change :",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME": "Plan product name",
    "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT": "You can coterminate your subscription with an existing non-trial OnlineServicesNCE subscription or align the end date with the calendar month by choosing an appropriate end date depending on the term duration.",
    "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE": "Update Schedule",
    "MANAGE_RESELLER_BACK_TO_RESELLERS": "Back to Resellers",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS": "Offers",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS": "Resellers",
    "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS": "Add Resellers Offers",
    "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER": "Offers",
    "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT": "Please provide Effective Price for the selected offers",
    "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS": "Offers saved successfully",
    "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT": "Please add offers to the reseller",
    "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE": "Selected All Offers. If you want to edit some thing related to any plan offer, need to go through edit plan!",
    "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS": "Reseller Plans List",
    "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY": "Updated plan status successfully",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN": "Assign",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE": "Disable",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE": "Enable",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME": "Name",
    "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS": "Supported Markets",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS": "Expires on ",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS": "Auto Renew on ",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID": "Product ID",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE": "If auto renew is off this subscription expires on ",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE": "If auto renew is off this subscription expires at end of term period ",
    "MANAGERG_REMOVE_OFFERS_PROMPT": "Do you want to remove the Resource Group?",
    "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE": "Mandate Customer to update Payment Details.",
    "MANUAL_SYNC_PUPUP_TITLE": "Manual Sync",
    "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE": "Successfully applied your fix",
    "MARCH": "March",
    "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT": "Are you sure you want to mark this address as default?",
    "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT": "Are you sure you want to mark this email as default?",
    "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT": "Are you sure you want to mark this phone number as default?",
    "MATCHING_PRODUCT_PLAN_TEXT": "Plan:",
    "MAY": "May",
    "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT": "Recurring Payment Creation. You may see a $1.00 charge on your card which would get adjusted as credit in your next invoice.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER": "The customer’s bank (card issuer) has declined the transaction. The customer should contact their bank and retry the transaction.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY": "The customer’s bank has declined the transaction and request the Merchant to contact the bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT": "The transaction was successful.",
    "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3": "The transaction was successful.",
    "MCB_ERROR_CODE_APPROVED_VIP": "The transaction was successful. (This code is not used in Australia.)",
    "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH": "The customer’s bank (Card Issuer) has declined the transaction as it does not allow transactions originating through mail /telephone, fax, email or Internet orders. This error is associated customers attempting to use a Discover Card. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW": "The customer’s bank has declined the transaction and request the customer to contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_CAPTURE_CARD": "The customer’s bank has declined the transaction as the card is suspected to be counterfeit. The customer’s bank (Card Issuer) has requested that your customer’s credit card be retained. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD": "The customer’s bank (Card Issuer) has declined the transaction and requested that the customer’s credit card be retained (card reported lost or stolen). The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE": "The customer’s bank is unable to be contacted to authorise the transaction. The customer should attempt to process this transaction again.",
    "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD": "The customer’s bank (Card Issuer) has declined the transaction and requested that your customer’s credit card be retained. (card reported lost or stolen) The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS": "The customer’s bank is temporarily not able to process this customer’s credit card. The customer should attempt to process this transaction again.",
    "MCB_ERROR_CODE_CVV_VALIDATION_ERROR": "The customer’s bank has declined the transaction as the CVV is incorrect. The customer should check the CVV details and try again. If not successful, the customer should use an alternate credit card.",
    "MCB_ERROR_CODE_DO_NOT_HONOUR": "The customer’s bank has declined the transaction as the credit card number has failed a security check, or the funds have been frozen or depleted. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_DUPLICATE_TRANSACTION": "The customer’s bank has declined the transaction as this transaction appears to be a duplicate transmission. No action required.",
    "MCB_ERROR_CODE_ERROR": "The customer’s bank (Card Issuer) has declined the transaction as there is a problem with the credit card number. The customer should contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL": "The customer’s bank has declined the transaction as the customer has exceeded the withdrawal frequency limit. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT": "The customer’s bank has declined the transaction as it will exceed the customer’s card limit. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_EXPIRED_CARD": "The customer’s bank (Card Issuer) has declined the transaction as the credit card appears to have expired. The customer should check the expiry date entered and try again, or use an alternate credit card.",
    "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE": "The customer’s bank (Card Issuer) has declined the transaction as Credit Card has expired or the date is incorrect. Check the expiry date in the transaction and try processing the transaction again.",
    "MCB_ERROR_CODE_FORMAT_ERROR": "The customer’s bank (Card Issuer) does not recognise the transaction details. The customer should check the transaction information and try processing the transaction again. Usually due to an incorrect TID.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER": "The Customer’s bank has declined the transaction as this credit card cannot be used for this type of transaction. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL": "The Customer’s bank has declined the transaction as this credit card cannot be used for this type of transaction. This may be associated with a test credit card number. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED": "The customer’s bank (Card Issuer) has declined the transaction as it does not allow this type of transaction. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION": "The transaction was successful. Some Banks use this code instead of ‘00’.",
    "MCB_ERROR_CODE_INCORRECT_PIN": "The customer’s bank (Card Issuer) has declined the transaction as the customer has entered an incorrect PIN. The customer should re-enter their PIN, otherwise use an alternate credit card.",
    "MCB_ERROR_CODE_INSUFFICIENT_FUNDS": "The customer’s bank (Card Issuer) has declined the transaction as the credit card does not have sufficient funds. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_INVALID_AMOUNT": "An invalid character (e.g. a dollar sign or a space) may be being passed to the gateway. Check your website’s code.",
    "MCB_ERROR_CODE_INVALID_CARD_NUMBER": "The customer’s bank (Card Issuer) has declined the transaction as the Credit Card number does not exist. Check the credit card information and try processing the transaction again.",
    "MCB_ERROR_CODE_INVALID_TRANSACTION": "The customer’s bank (Card Issuer) has declined the transaction because of an invalid format or field. Check the transaction information and try processing the transaction again.",
    "MCB_ERROR_CODE_LOST_CARD": "The customer’s bank (Card Issuer) has declined the transaction as the card has been reported lost. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN": "The customer’s bank (Card Issuer) has indicated there is a problem with the credit card number. The customer should contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_CARD_RECORD": "The Customer’s bank has declined the transaction as the credit card number does not exist. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT": "The customer’s bank (Card Issuer) has declined the transaction as the credit card number is associated to a cheque account that does not exist. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT": "The customer’s bank has declined the transaction as the Credit Card number used is not a credit account. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT": "The customer’s bank (Card Issuer) has declined the transaction as the account type selected is not valid for this credit card number. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_ISSUER": "The customer’s bank (Card Issuer) does not exist. Check the credit card information and try processing the transaction again.",
    "MCB_ERROR_CODE_NO_MERCHANT": "The Merchant ID is invalid, you should contact your Bank and ensure you have provided the correct Merchant Account Number.",
    "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT": "The customer’s bank (Card Issuer) has declined the transaction as the credit card number is associated to a savings account that does not exist. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT": "The customer’s bank (Card Issuer) has declined the transaction as the account type selected is not valid for this credit card number. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT": "The customer’s bank has declined the transaction due to the amount attempting to be processed. The customer should check the transaction amount and try again.",
    "MCB_ERROR_CODE_PICK_UP_CARD": "The customer’s bank (Card Issuer) has declined the transaction and requested that your customer’s credit card be retained. (card reported lost or stolen). The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL": "The customer’s bank (Card Issuer) has declined the transaction and requested that your customer’s credit card be retained. (card reported lost or stolen) The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED": "The customer’s bank has declined the transaction as the customer has entered the incorrect PIN more than three times. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE": "The customer’s bank (Card Issuer) has declined the transaction as the customer has entered the incorrect PIN three times. The customer’s bank (Card Issuer) has requested you retain the credit card. The customer should use an alternate credit card and contact their bank.",
    "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION": "The transaction has not been processed and the customer should attempt to process the transaction again.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER": "The customer’s bank (Card Issuer) has indicated there is a problem with the credit card number. The customer should contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL": "The customer’s bank (Card Issuer) has indicated there is a problem with the credit card number. The customer should contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_REQUEST_IN_PROGRESS": "The customer’s bank (Card Issuer) has indicated there is a problem with the credit card number. The customer should contact their bank. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_RESTRICTED_CARD": "The customer’s bank has declined the transaction as the credit card has some restrictions. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD": "The customer’s bank (Card Issuer) has declined the transaction and requested that the customer’s credit card be retained. (card reported lost or stolen) The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_SECURITY_VIOLATION": "The customer’s bank has declined the transaction. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_STOLEN_CARD": "The customer’s bank (Card Issuer) has declined the transaction as the card has been reported stolen. The customer should use an alternate credit card.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD": "The customer’s bank has declined this transaction as the credit card appears to be fraudulent.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD": "The customer’s bank (Card Issuer) has declined the transaction as there is a suspected fraud on this Credit Card number.",
    "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION": "The customer’s bank (Card Issuer) cannot be contacted during the transaction. The customer should check the credit card information and try processing the transaction again.",
    "MCB_ERROR_CODE_SYSTEM_ERROR": "The customer’s bank was not able to process the transaction. The customer should attempt to process this transaction again.",
    "MCB_ERROR_CODE_TRANSACTION_APPROVED": "The transaction was successful.",
    "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE": "The customer’s bank (Card Issuer) does not recognise the credit card details. The customer should check the credit card information and try processing the transaction again.",
    "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION": "The customer’s bank cannot be found for routing. This response code is often returned when the customer is using a test credit card number. The customer should attempt to process this transaction again.",
    "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE": "An unspecified error has occurred.",
    "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING": "Card number invalid or missing.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING": "Expiry month invalid or missing.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING": "Expiry year invalid or missing.",
    "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID": "Security code invalid.",
    "MENU_ACCOUNT_MANAGERS": "Account Managers",
    "MENU_ADMINISTRATION": "Administration",
    "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS": "Custom Notifications",
    "MENU_ADMINISTRATION_ENGAGE": "Engage <sup class='text-warning'> Beta</sup>",
    "MENU_ADMINISTRATION_MANAGE": "Banner Notifications",
    "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING": "Banner notifications",
    "MENU_ADMINISTRATION_MANAGE_HEADING": "Administration",
    "MENU_ADMINISTRATION_NOTIFICATIONS": "Notifications",
    "MENU_ADMINISTRATION_WEBHOOK": "Webhook",
    "MENU_AZURE_ADVISOR": "Azure Advisor",
    "MENU_AZURE_BILLING_REPORT": "Azure Billing Report",
    "MENU_AZURE_CUSTOM_POLICY": "Custom Policies",
    "MENU_AZURE_CUSTOM_ROLE": "Custom Roles",
    "MENU_AZURE_MARGIN": "C3 Support",
    "MENU_AZURE_SUBACRIPTION": "Azure Subscription",
    "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT": "Microsoft",
    "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS": "Account Managers",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS": "Commissions",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS": "Product Attributes",
    "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE": "Report usage",
    "MENU_CONTACT_LOG": "Notifications",
    "MENU_COST_SUMMARY_REPORT": "Cost Summary Report",
    "MENU_CUSTOMER_ANALYTICS": "Customer Analytics",
    "MENU_CUSTOMER_COMMENTS": "Comments",
    "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION": "License Consumption",
    "MENU_CUSTOMER_MICROSOFT_REPORT": "Azure Usage",
    "MENU_CUSTOMER_PROFILE": "Profile",
    MENU_DASHBOARD: "Dashboard",
    "MENU_EXISTING_ACCOUNT_MANAGERS": "Existing Managers",
    "MENU_HELP": "Help",
    "MENU_LICENSE_CHANGE": "License Change Report",
    "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT": "License Consumption Summary Report",
    "MENU_LICENSE_SUMMARY_REPORT": "License Summary Report",
    "MENU_MANAGE_AZURE_ENTITLEMENTS": "Manage Azure Entitlements",
    "MENU_MANAGE_SUBSCRIPTIONS_PROVISION": "Partner Offers Provisioning",
    "MENU_ONBOARDED_CUSTOMERS_REPORT": "Onboarding Analytics",
    "MENU_PARTNER_AUDIT_LOG": "Audit Log",
    "MENU_PARTNER_COMMISSION_REPORT": "Commission Report",
    "MENU_PARTNER_COUPON": "Coupons",
    "MENU_PARTNER_DOCUMENTATION": "Documentation",
    "MENU_PARTNER_OFFER_USAGE_REPORT": "Usage Report",
    "MENU_PARTNER_SUPPORT_LOG": "Support",
    "MENU_PARTNER_UPLOAD": "Upload",
    "MENU_PENDING_PURCHASE_REQUESTS": "Approvals",
    "MENU_PRODUCT_COMMISSIONS": "Commissions",
    "MENU_PRODUCT_EXTENSIONS": "Product Attributes",
    "MENU_PRODUCT_SEQUENCE": "Product sequence",
    "MENU_PURCHSED_PRODUCT_MAPPING": "Purchased Product Mapping",
    "MENU_REPORT_USAGE": "Usage",
    "MENU_REPORTS_FOR_CUSTOMER": "Reports",
    "MENU_RESELLER_PLAN": "Reseller Plan",
    "MENU_RESELLER_PROFILE": "Organization Profile",
    "MENU_TOPBAR_SETTINGS": "Settings",
    "MENU_USAGE_REPORT": "Azure Usage Report",
    "MENU_USERS": "Portal Users",
    "MENUS_ANALYTICS": "Analytics",
    "MENUS_ANALYZE": "Analyze",
    "MENUS_AZURE_ESTIMATE": "Azure Estimate",
    "MENUS_AZURE_GROUPS": "Azure Groups",
    "MENUS_AZURE_MANGE": "Azure Management ",
    "MENUS_AZURE_REPORTS": "Azure Reports",
    "MENUS_AZURE_SUBSCRIPTIONS": "Azure Subscriptions",
    "MENUS_CART": "Cart",
    "MENUS_COUPONS": "Coupons",
    "MENUS_CUSTOM_OFFERS": "Partner Offers",
    "MENUS_CUSTOMER_CART": "Cart",
    "MENUS_CUSTOMER_DASHBOARD": "Dashboard",
    "MENUS_CUSTOMER_MICROSOFT": "Microsoft",
    "MENUS_CUSTOMER_MICROSOFT_USERS": "Users",
    "MENUS_CUSTOMER_ONBOARDING": "Customer Onboarding",
    "MENUS_CUSTOMER_ORDERS": "Orders",
    "MENUS_CUSTOMER_PRODUCTS": "Products",
    "MENUS_CUSTOMER_PROFILE": "Profile",
    "MENUS_CUSTOMER_SHOP": "Shop",
    "MENUS_DAAS": "DaaS<sup class='text-danger'>BETA</sup>",
    "MENUS_DISTRIBUTOR_OFFERS": "Distributor Offers",
    "MENUS_DOCUMENTATION": "Documentation<sup class='text-danger'>BETA</sup>",
    "MENUS_ENTITY_INVOICES": "Invoices",
    "MENUS_FAQ": "FAQ",
    "MENUS_INVITES": "Invites",
    "MENUS_INVOICES": "Invoices",
    "MENUS_LICENSE_CONSUMPTION": "License Consumption",
    "MENUS_LICENSE_LIST": "License List",
    "MENUS_MS_OFFERS": "MS Offers",
    "MENUS_OPERATING_ENTITIES": "Operating Entities",
    "MENUS_ORDERS": "Orders",
    "MENUS_PARTNER_BUNDLES": "Bundles",
    "MENUS_PARTNER_CUSTOMERS": "Customers",
    "MENUS_PARTNER_DASHBOARD": "Dashboard",
    "MENUS_PARTNER_PENDING_PAYMENT_STATUS": "Pending Payment Status",
    "MENUS_PARTNER_PLANS": "Plans",
    "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY": "Business",
    "MENUS_PARTNER_SETTINGS": "Settings",
    "MENUS_POWER_BI_REPORTS": "Azure Usage",
    "MENUS_PRODUCTS": "Products",
    "MENUS_PROFILE": "Profile",
    "MENUS_RESELLER_DASHBOARD": "Dashboard",
    "MENUS_RESELLER_INVOICES": "Billing",
    "MENUS_RESELLER_PAYMENTS": "Payments",
    "MENUS_RESELLER_PLANS": "Plans",
    "MENUS_RESELLER_PRICE_AND_OFFERS": "Pricing and offers",
    "MENUS_RESELLER_PROFILE": "Organization Profile",
    "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY": "Business",
    "MENUS_RESELLER_SETTINGS": "Settings",
    "MENUS_RESELLERS": "Resellers",
    "MENUS_SELL": "Sell",
    "MENUS_SELL_DIRECT": "Sell Direct",
    "MENUS_SELL_INDIRECT": "Sell Indirect",
    "MENUS_SELLER_INDIRECT": "Sell Indirect",
    "MENUS_SHOP": "Shop",
    "MENUS_SUBSCRIPTIONS": "Subscriptions",
    "MENUS_SUPPORT": "Support",
    "MENUS_TAXES": "Taxes",
    "MENUS_USER_MANAGEMENT": "User Management",
    "merge": "branch",
    "MESSAGE_ALREADY_EXISTING": "The message is already existing for the relevent entities",
    "MESSAGE_BODY": "Message Body",
    "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR": "Please check all the required fields and try submitting again",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR": "For the entity Product variant/Customer, the same combination cannot be inserted again.",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR": "Please select all the fields",
    "MESSAGE_NO_COMMENTS_FOUND": "<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND": "<div ng-include=\"'app/templates/no-data-message.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND_USAGE": "No data to present either because of no usage in the current billing period or the bill from Microsoft is not available yet. Please check back again shortly.",
    "MESSAGE_NOTIFCATION_SAVED": "Message notification saved successfully",
    "MESSAGE_NOTIFCATION_SUBMIT_ERROR": "Please resolve all the validation errors and try submitting again",
    "MESSAGE_NOTIFICATION_EDIT_MODE": "Edit message notifications",
    "MESSAGE_NOTIFICATION_EDIT_TEXT": "Edit",
    "MESSAGE_NOTIFICATION_ID": "ID",
    "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED": "Please select atleast one recepient for the message notification",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS": "Actions",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE": "Effective date",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE": "Expires on",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY": "Message body",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE": "Message style",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME": "Page name",
    "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST": "Entites involved",
    "MESSAGE_ONE_APPLICABLE_FOR_ALL_CUSTOM_OFFERS": "",
    "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED": "One product has been selected",
    "MESSAGE_RECORDS_POP_UP": "record list",
    "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED": "An error has occurred",
    "MESSAGE_TEXT_NO_RECORDS_FOUND": "No records found!",
    "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED": "Sorry, you do not have sites and departments configured. You can add new operating entities from the Profile Page",
    "MESSAGE_TEXT_PLEASE_WAIT": "Please wait..",
    "MESSAGE_TOOLTIP_AZUREGROUP": "Info, Warn, Error, Danger",
    "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT": "Select Message type",
    "MESSAGE_TYPE_NAME": "Message Type Name",
    "MESSAGE_WEBHOOK_ENTITIES_ERROR": "Please select all the fields",
    "MESSAGE_WEBHOOK_INVALID_URL_ERROR": "Invalid URL. Please enter a valid URL.",
    "MESSAGE_WEBHOOK_SUBMIT_ERROR": "Please check all the required fields and try submitting again",
    "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE": "URL is valid!",
    "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR": " Decimal values are not allowed.",
    "METERED_BILLING_DATA_POPUP_HEADER_TEXT": "New tier",
    "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR": "Enter a valid number.",
    "METERED_BILLING_TEXT_MODAL_HEADER": "Usage tiers",
    "Microsoft": "Microsoft",
    "MICROSOFT_ATTESTATION_TYPE_TOOLTIP": "Attestation type: ",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE": "Remove",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY": "Category",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT": "Discount",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS": "Add-ons",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE": "Remove",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY": "Update quantity",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY": "Category",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT": "Discount",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP": "Markup",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE": "Percentage",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE": "Unit price",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS": "Add-ons",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION": "Linked Product",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY": "MCA Accepted by",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE": "MCA Accepted on",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT": "Acceptance status",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED": "Not Provided",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED": "Provided",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER": "Customer acceptance confirmed by partner",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT": "Customer acceptance through microsoft",
    "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE": "Microsoft customer agreement saved successfully.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD": "Temporary password",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME": "Username",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE": "User has been created successfully and below are the user credentials.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER": "User details",
    "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION": "After you close this popup, you can't see this temporary password again.",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED": "Microsoft Pricing API consent captured failed",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS": "Microsoft Pricing API consent captured successfully",
    "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE": "Attempt to access Microsoft pricing API using the new security settings is successful.",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS": "{{loggedInUserName}} of {{applicationName}} would like to be your Microsoft Cloud Solution Provider for your Microsoft Cloud account.<br/><br/>Click the following link to accept this invitation and authorize {{applicationName}} to be your Microsoft Cloud Solution Provider and accept the Microsoft Customer Agreement.<br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0&DAP={{canConsiderDAP}}#/BillingAccounts/partner-invitation <br/><br/>Note: User with Global Admin permission is required to accept relationship<br/><br/><br/>Additional partner information:<br/>{{contactEmail}}<br/>{{contactNumber}}",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS": "Dear Customer:<br/><br/>To provide you with a full range of cloud solutions, services and technical support, {{resellerName}} works closely with {{partnerCompanyName}} If you want to authorize {{resellerName}} and {{partnerCompanyName}} to provide services on your account, click the link below and follow the instructions on the screen.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}&DAP={{canConsiderDAP}}#/BillingAccounts/partner-invitation<br/><br/>Note: A user with global administrator permission is required to accept the relationship.<br/><br/>{{resellerName}} will continue to be your local expert that you should turn to in everything related to products, plans, licenses and technical support. Your business relationship will remain the same.<br/><br/>Feel free to contact us if you have any questions. Both {{resellerName}} and we are looking forward to working with you.<br/><br/>Sincerely,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
    "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Markup for Software Subscription",
    "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Markup for third-party subscription",
    "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Total % to be applied to cost price to attain the desired sale price for software subscriptions.",
    "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Total % to be applied to cost price to attain the desired sale price for third party subscriptions.",
    "MICROSOFT_USERS_DELETE_CONFIRM": "Any existing licenses and access to the portal will be removed. Are you sure you want to continue?",
    "MicrosoftNonCSP": "Microsoft Non CSP",
    "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY": "Migration account created successfully with the username :",
    "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS": "Migration accounts",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER": "Customer",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT": "Migration account",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE": "Create",
    "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS": "Migration Accounts",
    "MIGRATION_USER_CREATE_SUCCESS": "Migration account created successfully with the {{username}}",
    "MINUTES": " Minute(s) ",
    "MISSING_PLAN_OFFER_ADD_FAILED": "Reconciliation failed, please contact support for assistance",
    "MISSING_PLAN_OFFER_ADDED": "Reconciliation completed using the offer chosen",
    "MNUAL_SYNC_PURCHASED_DATE": "Purchased date: ",
    "MODAL_HEADER_TEXT_DESC_FILTER": "Filter",
    "Month(s)": "Month(s)",
    "MULTIPLE_COMMENT_STATIC": "Comments",
    "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT": "Select",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE": "Import complete!<br />Total : {{ importProcessedCount }}<br />Errors : {{ importErrorCount }}<br />Success : {{ importSuccessCount }}",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE": "Validation completed!<br />Total : {{ validationProcessedCount }}<br />Errors : {{ validationErrorCount }}<br />Success : {{ validationSuccessCount }}",
    "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS": "Instructions",
    "NAME": "Name of Entity",
    "NCE_ACRONYM": "Online Services NCE",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS": "Cancellation Policy",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1": "0 - 24 hours: Full Refund",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2": "24 - 48 hours: 1st Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3": "48 – 72 hours: 2nd Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4": "72 – 96 hours: 3rd Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5": "96 – 120 hours: 4th Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6": "120 – 144 hours: 5th Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7": "144 – 168 hours: 6th Day Prorated",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8": "After 7 calendar days: Cancellation is not allowed",
    "NCE_INSTRUCTIONS_HEADING": "NCE Instructions",
    "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT": "Linked product estimate sale price",
    "NCE_PROMOTION_WARNING_MESSAGE": "<p class='font-weight-bold text-danger'>This following product is not eligible for promotion. Please contact support to apply promotion. if you don't want promotion then, continue your process order.</p>",
    "NCE_PROMOTIONS_APPLIED": "This promotion is applied",
    "NCE_PROMOTIONS_AVAILABLE_TEXT": "Promotion available",
    "NCE_PROMOTIONS_CHECK_ELIGIBILITY": "Check promotion eligibilty",
    "NCE_PROMOTIONS_NOT_APPLIED": "The promotion is not yet applied",
    "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS": "Available promotions",
    "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY": "Billing frequency",
    "NCE_PROMOTIONS_POPUP_CANCEL": "Cancel",
    "NCE_PROMOTIONS_POPUP_CLOSE": "Close",
    "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED": "Customer eligibilty will be validated when reviewing the cart",
    "NCE_PROMOTIONS_POPUP_DISCOUNT": "Discount",
    "NCE_PROMOTIONS_POPUP_END_DATE": "End date",
    "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT": "New commerce launch promotional discount",
    "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS": "Promotion Details",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID": "ID: ",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name": "Name: ",
    "NCE_PROMOTIONS_POPUP_SUBMIT": "Apply",
    "NCE_PROMOTIONS_POPUP_TERM": "Term",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_1": "Quantity/seats can be increased at any time.",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_2": "Quantity/seats can be decreased within 7 calendar days, to its previous or sum of previous quantity/seats.",
    "NCE_QUANTITY_CHANGE_INSTRUCATIONS": "Quantity Change",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS": "Schedule Renewal Change",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1": "Current schedule will be deleted if Quantity/Auto renewal status/Billing Cycle/ Term is changed.",
    "NCE_SKU_NOT_PRESENT_IN_PLAN": "Product not available in Customer Plan",
    "NCE_TEXT": "New commerce ...",
    "NEW_BILLING_CYCLE": "New billing cycle",
    "NEW_COMMENT_SEND": "Send",
    "NEW_COMMENTS_RECIEVED": "New comment recieved",
    "NEW_CUSTOMER_ONBOARDING_ERRORS": "purchase/updation products may fail",
    "NEW_DISTRIBUTOR_PRODUCT_ICON": "New icon",
    "NEW_PARTNER_PRODUCT_ICON": "New icon",
    "NEXT_SCHEDULE_RENEWAL_CHANGE": "Next schedule renewal change",
    "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR": "Search ...",
    "NO_CART_LINE_ITEM_EXSITS_WITH_ID": "No item exists with id : {cartLineItemId} in cart",
    "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT": "No customer admin is available for this tenant.",
    "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT": "No customers on record",
    "NO_EXISTING_PLAN_FOR_THE_CUSTOMER": "No existing plan",
    "NO_PAYMENT_TYPES_ENABLED": "No payment types are enabled. Please contact support.",
    "NO_PLAN_WITH_OFFER_AVAILABLE": "There is no plan with all offer available. Please create one",
    "NO_PRODUCT_SELECTED_PROMPT": "Please select minimum one product.",
    "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE": "No invoice details found for selected billing period or provider",
    "NO_RESOURCE_GROUP_LEFT": "No resource Group left to assign/No records found!",
    "NON_CSP_OFFER_DETAILS_CAPTION_TEXT": "Non CSP Product Details",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID": "Service Principal Id",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY": "Service Principal Key",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID": "Subscription Id",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME": "Subscription Name",
    "NON_RESELLER_PRODUCT_EXIST_EXCEPTION": "You donot have the permission to make this change, please contact your partner for more information.",
    "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER": "Following provider products are not mapped with plan products, if you want to proceed click on proceed else click on cancel and map these products",
    "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS": "Provider Products",
    "NOT_VALID_MESSAGE": " is not a valid email",
    "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION": "Create banner notification",
    "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY": "Subscription name changed successfully",
    "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY": "This value will be updated shortly",
    "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR": "Character length must be between 2 and 200 characters",
    "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR": "Please enter a value with 2 to 50 characters.",
    "NOTIFY_ERROR_WEBHOOK_URL_INVALID": "Invalid webhook URL",
    "NOTIFY_SUCCESS_WEBHOOK_URL_VALID": "Valid webhook URL",
    "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT": "Enter email addresses separated by comma (,) or semicolon(;) and click Ok to receive a zip file containing a list of invoices PDFs for the selected billing period",
    "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE": "An email with a list of invoice PDFs (Zip) as an attachment will be sent {{emailsList}} shortly.",
    "NOVEMBER": "November",
    "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD": "CustomersFailedToOnboard",
    "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY": "CustomersOnboardedSuccessfully",
    "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD": "CustomersSelectedUnderBatch",
    "NUMBER_OF_PROMOTIONS_LOADED": "Count of promotions loaded",
    "OCTOBER": "October",
    "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE": "Offer Friendly name {{FriendlyName}} must be unique",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME": "Show offers with purchased subscription",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE": "offerWithPurchasedSubscription",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS": "Subscriptions",
    "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION": "Back to Subscriptions",
    "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS": "Add Subscriptions",
    "OFFERS_HEADER_TEXT_OFFERS": "Offers",
    "OFFERS_NO_MATCH_MESSAGE": "No matching results found for Name : ",
    "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED": "Items selected",
    "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE": "Discount percentage",
    "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "OFFERS_TABLE_HEADER_TEXT_QUANTITY": "Quantity (No. of licenses)",
    "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT": "Rate per seat(Per month)",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL": "Total",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT": "Total amount",
    "OLD_BILLING_CYCLE": "Old billing cycle",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED": "Onboarding a customer under bulk onboard failed",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED": "Onboarding a customer under bulk onboard initiated",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS": "Onboarding a customer under bulk onboard success",
    "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE": "<p/><p>Found a match on Id : <strong> {{billingProviderReferenceID}} </strong> in Billing System.<br/><span>Billing Address on file is <strong> {{formattedAddress}} </strong>.</span> <br/>Please impersonate and use the <strong>Profile</strong> option to change this detail after the customer billing details onboarded successfully.",
    "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE": "If {{firstProduct}} has to be mapped, you should also map {{secondProduct}}.",
    "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE": "If {{firstProduct}} has to be mapped, you shouldn't ignore {{secondProduct}}",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE": "If {{firstProduct}} has to be mapped, you should include {{secondProduct}}",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE": "If {{firstProduct}} has to be mapped, you should also map {{secondProduct}}, i.e.; Min Quantity should be 1.",
    "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE": "Provider Quantity and shared quantity should match for {{product}}",
    "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE": "Quantity should be equal for {{firstProduct}} and {{secondProduct}}",
    "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP": "Select CSPCustomer to OnBoard",
    "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT": "Primary account details",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1": "Fill out ",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2": "Download a template .csv",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3": " by adding rows following the header. Each row should correspond to the detail of a reseller.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10": "The processing would take a while and the status will be reported on this page. If you move away from the page or have closed the browser by accident, you could come back to this page again and the system would show the status from your previous upload.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2": "Click Select and browse to the file that you wish to upload.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3": "The input is validated to ensure that the following rules are met:",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4": "Reseller name is required and should not exceed 50 characters in length.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5": "Reseller ID is required and should not exceed 50 characters in length. This should be the relationship GUID of the reseller from Partner Center.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6": "Reseller MPN ID is required and should not exceed 50 characters in length. This should be the Microsoft Partner Network ID of the reseller.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7": "Location is required and should be the two letter country code of the reseller. Eg: US, UK, etc.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8": "Email is optional but should be a valid email address if one is input.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9": "Click <strong class=\"text-uppercase\">submit</strong> for the data to be processed.",
    "ONBOARDING": "Onboarding",
    "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT": "Download report",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER": "Select type of customer",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION": "Select unit of duration",
    "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION": "Close",
    "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS": "Resellers",
    "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS": "Select reseller",
    "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION": "Reset",
    "ONBOARDING_ANALYTICS_SEARCH_SUBMIT": "Search",
    "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER": "Type of customer",
    "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION": "Unit of duration",
    "ONBOARDING_DURATION_UNIT_MONTH": "Month",
    "ONBOARDING_DURATION_UNIT_WEEK": "Week",
    "ONBOARDING_DURATION_UNIT_YEAR": "Year",
    "ONBOARDING_GRAPH_COUNT_TEXT": "Count: ",
    "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER": "Onboarding customers is not allowed as there is no link with Provider Reseller. Please contact your Partner.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED": "Onboarding a MicrosoftNonCSP customer is not supported. Please link the customer to an existing C3 Customer.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE": "Onboarding a MicrosoftNonCSP customer is not supported !",
    "ONBOARDING_STATUS": "Onboarding Status",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS": "Mapping the provider customer products with the plan products...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN": "Loading plans...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS": "Loading the provider customers...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3": "Looking up the customer details in c3...",
    "ONBOARDING_TYPES_OF_CUSTOMERS_ALL": "All customers",
    "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT": "Direct Customers",
    "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER": "Reseller Customers",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE": "Are you sure you want to delete?",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT": "Select a product in the list or search by product name",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY": "Request to remove licenses from users is accepted and being processed.",
    "OnlineServicesNCE": "Online Service NCE",
    "Open": "Open",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION": "Plan description",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME": "Plan name",
    "OPERATING_ENTITY_CUSTOMER": "Customer",
    "OPERATING_ENTITY_ORGANIZATIONAL_SETUP": "Organization Setup",
    "OPERATING_ENTITY_SITES_AND_DEPARTMENT": "Sites & Departments",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN": "Assign",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE": "Release",
    "ORDER_BACK_TO_ORDER_DETAILS": "back to Products",
    "ORDER_DETAILS_ENTITY_NAME": "Entity Name",
    "ORDER_DETAILS_FIELD_ORDERED_ON": "Ordered on",
    "ORDER_DETAILS_SCHEDULED_DATE": "Scheduled date",
    "ORDER_ID": "Order ID",
    "ORDER_LBL_TEXT_PROMOTIONID": "PromotionId",
    "ORDER_LIST_STATUS_SUCCESS": "Success",
    "ORDER_STATUS_APPROVAL_ACCEPTED": "Approved",
    "ORDER_STATUS_APPROVAL_REQUIRED": "Approval required",
    "ORDER_STATUS_COMMENTS": "View Comments",
    "ORDER_STATUS_DELETED": "Deleted",
    "ORDER_STATUS_DESC_ACCEPTED": "Accepted",
    "ORDER_STATUS_DESC_FAILED": "Failed",
    "ORDER_STATUS_DESC_IGNORE": "Ignore",
    "ORDER_STATUS_DESC_INPROGRESS": "InProgress",
    "ORDER_STATUS_DESC_ONHOLD": "Onhold",
    "ORDER_STATUS_DESC_ORDERED": "Ordered",
    "ORDER_STATUS_DESC_PARTIALLYFULFILLED": "Partially Fulfilled",
    "ORDER_STATUS_DESC_PROCESSING": "Processing",
    "ORDER_STATUS_DESC_PROVISIONING": "Provisioning",
    "ORDER_STATUS_DESC_REJECTED": "Rejected",
    "ORDER_STATUS_DESC_SCHEDULED": "Scheduled",
    "ORDER_STATUS_DESC_SUCCESS": "Success",
    "ORDER_STATUS_ERRORS_IGNORED": "Failed <br/>(errors reviewed and ignored)",
    "ORDER_STATUS_FAILED": "Failed",
    "ORDER_STATUS_INPROGRESS": "Working on it",
    "ORDER_STATUS_ONHOLD": "On hold",
    "ORDER_STATUS_ORDERED": "Ordered",
    "ORDER_STATUS_PROCESSING": "Working on it",
    "ORDER_STATUS_PROVISIONING": "Working on it",
    "ORDER_STATUS_REACTIVATED": "Reactivated",
    "ORDER_STATUS_REJECTED": "Rejected",
    "ORDER_STATUS_SCHEDULED": "Scheduled",
    "ORDER_STATUS_SUCCESS": "Order fulfilled",
    "ORDER_STATUS_SUSPENDED": "Suspended",
    "OWNER_ENTITY_NAME": "Owner Entity Name",
    "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL": "Payment Status Details",
    "PARENT_ENTITY_NAME": "Parent entity name",
    "PARENT_OFFER_NOT_ACTIVE_EXCEPTION": "The Add-on cannot be activated if the parent offer is not active.",
    "PARENT_PRODUCT_NOT_EXISTS": "Can't onboard a product without parent!",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE": "There are no keys available for this provider",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED": "There are no providers configured, Please Contact Support",
    "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES": " Save your changes",
    "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS": "Basic details",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE": "Currency code",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION": "Description",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY": "Display currency",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY": "Invoice currency",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO": "Macro",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE": "Macro value",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME": "Name",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY": "Purchase currency",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT": "Select",
    "Partner": "Partner",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN": "Assign",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN": "Unassign",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME": "Customer name",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS": "Back to account managers",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE": "Account manager customers",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS": "Actions",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME": "Name",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID": "C3ID",
    "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME": "Reseller Name",
    "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON": "Update all plans with latest offers",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD": "Add",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select All",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST": "Add-ons list",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND": "No addons were found",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS": "Add more products",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN": "Delete plan",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS": "Review plan offers",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN": "Save plan",
    "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND": "For the trial offer, no parent offer was found. Include parent offer and try it again",
    "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION": "Term Duration",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY": "Quantity",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE": "Usage",
    "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND": "No products found!",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP": "Enter effective date from",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP": "Enter effective date to",
    "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP": "Enter message",
    "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP": "Select message type",
    "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP": "Select page portal",
    "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT": "Stockholm-icons / Communication / Group-chat",
    "PARTNER_APPROVAL_PENDING": "Partner approval - ",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT": "added to bundle",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT": " added to bundle with add-ons",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD": "Add",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select All",
    "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST": "Add-ons list",
    "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT": " is already added to bundle and can't be added again",
    "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS": "Filters",
    "PARTNER_BUNDLE_BUTTON_TEXT_RESET": "Reset",
    "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH": "Search",
    "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY": "Validity",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION": "Term duration",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP": "Term duration",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES": "Billing cycles",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES": "Billing types",
    "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES": "Consumption types",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE": "Cost price",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM": "Cost price (from)",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO": "Cost price (to)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE": "Sale price",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM": "Sale price (from)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO": "Sale price (to)",
    "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA": "Slab data",
    "PARTNER_BUNDLE_LABEL_TEXT_TO": " - to - ",
    "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE": "Validity type",
    "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES": "Include deleted bundles",
    "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT": "Please select minimum one product.",
    "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT": "Please Select minimum 2 offers to be clubbed together",
    "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT": "Please provide selling price",
    "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND": "No products found!",
    "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND": "Bundle Products",
    "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES": "Bundles",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD": "Add",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE": "New bundle",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS": "Add more products",
    "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS": "Back to bundle details",
    "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL": "Cancel",
    "PARTNER_BUNDLES_BUTTON_TEXT_DELETE": "Delete",
    "PARTNER_BUNDLES_BUTTON_TEXT_EDIT": "Edit",
    "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED": "Search applied: ",
    "PARTNER_BUNDLES_BUTTON_TEXT_NEXT": "Next",
    "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS": "Review bundle products",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE": "Save",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE": "Save bundle",
    "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS": "Show offers",
    "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH": "Quick search",
    "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES": "Back to partner bundles",
    "PARTNER_BUNDLES_CAPTION_TEXT_ADD": "Add Bundle",
    "PARTNER_BUNDLES_CAPTION_TEXT_VIEW": "View Bundle",
    "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS": "No products found!",
    "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE": "Suggested Price:",
    "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL": "Is auto renewable?",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type": "Billing type",
    "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING": "Available for bundling?",
    "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY": "Category",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS": "Charge Period",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT": "Charge Period",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE": "Consumption Type",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT": "Help Text",
    "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE": "Cost price",
    "PARTNER_BUNDLES_LABEL_TEXT_Description": "Description",
    "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE": "Feed Source",
    "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING": "Available for consumption immediately?",
    "PARTNER_BUNDLES_LABEL_TEXT_NAME": "Name",
    "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION": "No of days for free cancellation",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION": "Billing rule for purchase of seats",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT": "Quantity changes are prorated or full charge for the new quantity",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION": "Billing rule for reduction of seats",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT": "Quantity changes are prorated or full charge for the new quantity",
    "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER": "Provider",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE": "Sale price",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE": "Sale type",
    "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY": "Validity",
    "PARTNER_BUNDLES_PLACEHOLDER_SEARCH": "Search for products",
    "PARTNER_BUNDLES_TAB_TITLE_QUANTITY": "Quantity",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE": "Billing cycle",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC": "Description",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME": "Name",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS": "Offer details",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW": "View",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES": "Categories",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS": "Providers",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES": "Service types",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE": "Cost to partner",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE": "Cost to customer",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE": "Multiple payments",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB": "Multiple payments",
    "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB": "Payments",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES": "Billing cycles",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND": "No history found!",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED": "Search applied",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES": "Service types",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search applied",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick search",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service types",
    "PARTNER_COMMENTS_DETAILS": "Comments",
    "PARTNER_COMMENTS_TAB_CART_ITEM": "Cart Item",
    "PARTNER_COMMENTS_TAB_INVOICE": "Invoice",
    "PARTNER_COMMENTS_TAB_PRODUCT": "Product",
    "PARTNER_COMMISIONS": "Partner Commisions",
    "PARTNER_CONSENT_HELP_TEXT": "Copy the link, by clicking the above button and open in new the incognito window and complete the consent flow.",
    "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB": "Please Select Billing Type for the slab",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD": "Add",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL": "Cancel",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE": "Delete",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT": "Edit",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE": "Save",
    "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER": "Please Select Applicability for the offer",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS": "Account Manager Details",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON": "Ok",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL": "Email",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME": "Name",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER": "Phone number",
    "PARTNER_CUSTOMER_FILTER_NAME": "Name",
    "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID": "Provider Customer Id",
    "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER": "{{customer}} is not assigned to any Account Manager",
    "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR": "There are no customers linked to any provider tenant.",
    "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT": "Assigning this plan will include the offers with markups defined in the plan. Are you sure you want to update the Plan status?",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN": "Assign",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE": "Disable",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE": "Enable",
    "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT": "Disabling will remove this plan including its offers. Existing Purchased Products will have the same markup of this plan. Are you sure you want to update the Plan status?",
    "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT": "Enabling will assign this plan including the offers with markups defined in the plan. Are you sure you want to update the Plan status?",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS": "Back to customer plans",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS": "Back to customers",
    "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN": "Change Plan",
    "PARTNER_CUSTOMER_PLANS_PAGE_TITLE": "Manage Plans For : ",
    "PARTNER_CUSTOMER_REPORTS": "Reports",
    "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS": "customer tag updated successfully",
    "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP": "Are you sure you want to update the Plan status?",
    "PARTNER_CUSTOMERS_TEXT_INFO": "Sorry, no customers found",
    "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE": "Accounts Receivable",
    "PARTNER_DASHBOARD_CAPTION_PAST_DUES": "Past Dues",
    "PARTNER_DASHBOARD_CAPTION_PROFITABILITY": "Profitability",
    "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS": "Top 10 Customers",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT": "Your active customers",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE": "Customers",
    "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE": "Customers Seats",
    "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT": "Direct Customer's Seats",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT": "Direct Customer's Subscriptions",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE": "Customers Subscriptions",
    "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE": "Reseller invoice details are not found for resellerId : {{resellerId}}, billingPeriodId : {{billingPeriodId}}",
    "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT": "Invoice generated on",
    "PARTNER_DASHBOARD_LABLE_NET_PROFIT": "Net Profit",
    "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES": "Other Charges",
    "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT": "% Profit",
    "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT": "Billed Amount",
    "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE": "MSFT Invoice",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT": "Quick look at your CSP profitability",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE": "Profitability",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT": "Summary of your accounts receivable",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE": "Accounts Receivable",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT": "Payments yet to be collected",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE": "Past dues",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME": "Customer name",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT": "Pending",
    "PARTNER_DASHBOARD_PIE_CHART_MESSAGE": "Please check back after invoices are ready",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER": "Top 10 SKUs",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT": "Product counts by top 10 SKUs",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE": "Counts by SKU",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER": "Filter Range",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR": "6 Months",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER": "3 Months",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR": "1 Year",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER": "Purchase of Seats",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT": "Purchase of Seats trend across months",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE": "Purchase of Seats",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT": "Seats Purchased",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT": "Your active resellers",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE": "Resellers",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY": "By category",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER": "Revenue vs Cost",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT": "A chart to view revenue versus cost details by category",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE": "Revenue vs Cost by Category",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST": "Cost",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE": "Revenue",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER": "Select Provider",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER": "Revenue vs Cost",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT": "Revenue versus Cost",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE": "Revenue versus Cost",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS": "Customers",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS": "Seats",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS": "Subscriptions",
    "PARTNER_DASHBOARD_TILE_INVOICES": "Invoices",
    "PARTNER_DASHBOARD_TILE_RESELLERS": "Resellers",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE": "Revenue",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1": "Top 5 Products",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2": "By revenue",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT": "Top 5 Products by Revenue",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE": "Top 5 Products by Revenue",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED": "Seats",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1": "Top 5 Products",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2": "By seats purchased",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT": "Top 5 Products by seats purchased",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE": "Top 5 Products by seats purchased",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME": "Customer name",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE": "Revenue",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1": "Top 10 Customers",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2": "By revenue",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT": "Top 10 customers by revenue",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE": "Top 10 customers by revenue",
    "PARTNER_LOGOS_LABEL_TEXT": "Select Logo",
    "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE": "Preview Image",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE": "Remove",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY": "Category",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE": "Remove",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY": "Update quantity",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY": "Category",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT": "Discount",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS": "Add-ons",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE": "Remove",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE": "Billing cycle",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE": "Billing type",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY": "Category",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT": "Discount",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME": "Name",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE": "Unit Price",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS": "Add-ons",
    "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER": "Remove offer",
    "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB": "Add Partner Offer",
    "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP": "Enter the quantity that you want to purchase.",
    "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB": "Edit Contract Offer",
    "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB": "Edit Partner Offer",
    "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE": "Enter a positive value",
    "PARTNER_OFFER_HYPERLINK_READ_LESS": "Read less",
    "PARTNER_OFFER_HYPERLINK_READ_MORE": "...Read more",
    "PARTNER_OFFER_IS_TRIAL_TOOLTIP": "Enable trial offer by checking this box",
    "PARTNER_OFFER_PROVIDER_TOOLTIP": "Please choose the solution provider from the dropdown list",
    "PARTNER_OFFER_SLAB_DATA": "Slab data",
    "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT": "All Customers",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS": "Actions",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT": "CSV",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL": "Email",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION": "Please input the email addresses of recipients, separated by commas (,) or semicolons (;) and click  \"Submit\" to receive a file containing the Partner offers report list.",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER": "Partner offers report",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION": "Select a way to get the partner offers report",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION": "Enter email address to whom you want to send as email, enter with comma(,) or semicolon(;) and click on submit to receive a report",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT": "JSON",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT": "Cancel",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT": "Download",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT": "Send Email",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT": "Submit",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER": "Select a file format",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT": "XML",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION": "Parent produt is not approved!",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION": "Child produt is not rejected!",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART": "All the items in the cart get approved irrespective of the threshold violations at each line item. Are you sure you want to approve the cart?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM": "Are you sure you want to approve the item?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE": "Successfully approved the Cart Lineitem",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE": "Successfully approved the Cart",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART": "Approve all",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX": "Approve",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST": "Approve Request",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST": "Back to Requests",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES": "Billing cycles",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES": "Categories",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES": "Consumption Types",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES": "Service Types",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS": "Providers",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS": "Status",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT": "Ordered On",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT": "Unit Price",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART": "Reject all",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX": "Reject",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST": "Reject Request",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES": "Submit changes",
    "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE": "Please ensure to approve or reject atleast one product before submitting",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART": "Are you sure you want to reject the entire cart?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM": "Are you sure you want to reject the item?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE": "Successfully rejected the Cart Lineitem",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE": "Successfully rejected the Cart",
    "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE": "Status was updated successfully",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB": "Approvals",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1": "The following customer purchase requests are awaiting for your approval.",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE": "Approvals For",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE": "Purchase Requests",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES": "Selected Billing cycles",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES": "Selected Categories",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES": "Selected provider categories",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS": "Selected providers",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION": "Apply promotion to all offer",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES": "Billing cycles",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES": "Categories",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT": "Plan seat limit: ",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES": "Provider categories",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS": "Providers",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT": "Seat limit",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE": "Service Types",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN": "Successfully completed clone plan",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN": "Request to Clone Plan is accepted and being processed",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS": "Request to create a new plan with all offers is accepted and being processed",
    "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE": "Macro Value",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY": "Define plan in new currency",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME": "Name",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE": "Plan currency code",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME": "Product name",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY": "Source currency",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY": "Target currency",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE": "Target currency code",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE": "There is no target currency, please contact partner to define.",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING": "How to?",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02": "Set the limit as -1 to require approval for all the purchases/update seats",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1": "Use this screen to configure the threshold by seat count",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2": "Set the limit as 0 to allow purchase of unlimited seats",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3": "Set a non-zero, positive number to define the threshold",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4": "Any purchase exceeding the threshold requires approval from the Partner",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5": "<b>Click on the submit button to save the changes<b>",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT": "Configure seat limits for {{PlanName}}",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS": "Define macro and save with all offers",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS": "Update plan with latest offers",
    "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS": "Cancel",
    "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN": "Clone plan",
    "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS": "Save plan with all offers",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN": "Clone Plan",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS": "Cloning Plan",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS": "Creating Plan",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS": "Plan is in progress. Click to see the status",
    "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS": "Seat Limits",
    "PARTNER_PRODUCT_BILLINGCYCLEID": "BillingCycleId",
    "PARTNER_PRODUCT_BILLINGPERIODTYPE": "BillingPeriodType",
    "PARTNER_PRODUCT_BILLINGTYPEID": "BillingTypeId",
    "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT": "Product catalogue",
    "PARTNER_PRODUCT_CATALOGUE_BUTTON": "Product catalogue",
    "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT": "Product catalogue",
    "PARTNER_PRODUCT_CATEGORYID": "CategoryId",
    "PARTNER_PRODUCT_CONSUMPTIONTYPEID": "ConsumptionTypeId",
    "PARTNER_PRODUCT_DESCRIPTION": "Description",
    "PARTNER_PRODUCT_NAME": "Name",
    "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION": "OnPurchaseBillingAction",
    "PARTNER_PRODUCT_ONRELEASEBILLINGACTION": "OnReleaseBillingAction",
    "PARTNER_PRODUCT_PRICEFORPARTNER": "PriceforPartner",
    "PARTNER_PRODUCT_ProviderId": "ProviderId",
    "PARTNER_PRODUCT_PROVIDERSELLINGPRICE": "ProviderSellingPrice",
    "PARTNER_PRODUCT_SALETYPE": "SaleType",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER": "Cost to customer",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE": "Currency",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID": "Customer Id",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME": "Customer name",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION": "Description",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID": "Product Id",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME": "Product name",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE": "Unit of measure",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS": "Units",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE": "Usage date",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE": "Are you sure you want to accept this request?",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED": "Are you sure you want to reject this request?",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY": "Back to subscription history",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE": "Release",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING": "Release agreement mapping",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING": "Release contract mapping",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING": "Release customer mapping",
    "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS": "Back to customers",
    "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "PARTNER_RESELLER_FILTER_NAME": "Name",
    "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER": "{{reseller}} is not assigned to any Account Manager",
    "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES": "Save your changes",
    "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS": "Basic details",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE": "Currency code",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION": "Description",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME": "Name",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT": "Select",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE": "Please enter the atleast one tax name and percentage for the {{taxType}}",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE": "Please enter the valid tax percentage",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME": "Please fill the fields tax name",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX": "You have un-saved sub taxes, want to continue?",
    "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION": "Event Email Notification",
    "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW": "Select a view",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY": "partner tag deleted successfully",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY": "partner tag updated successfully",
    "PARTNER_SETTINGS_PORTLET_TITLE_STYLES": "Styles",
    "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES": "Templates",
    "PARTNER_STATUS": "Partner status",
    "PARTNER_TABLE_HEADER_NAME": "Name",
    "PARTNER_TABLE_HEADER_VALUE": "Value",
    "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST": "Parent offers associated with the trial offer",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER": "Cost to customer",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE": "Currency",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION": "Description",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT": "Submit",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH": "Month",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST": "Total cost",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE": "Usage date",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE": "Unit of measure",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS": "Units",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE": "Usage date",
    "PARTNER_USER_ALREADY_EXECUTE": "Partner user already exists with this email.",
    "PARTNER_USER_REMOVED": "Partner user removed successfully",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE": "Delete",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT": "Edit",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY": "Tag key",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES": "Tag values",
    "PARTNER_USERS_ADD_SUCCESS": "Granted access to {{username}} successfully",
    "PARTNER_USERS_ALREADY_EXISTS_ERROR": "A user with the email {{useremail}} already exists in the system",
    "PARTNER_USERS_REMOVE_SUCCESS": "Partner user removed {{username}} successfully",
    "PARTNER_USERS_SAVE_SUCCESS": "Partner user updated {{username}} successfully",
    "PARTNER_USERS_SELECT_ROLE_PROMPT": "Please assign a role to proceed",
    "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2": "Partner user updated successfully",
    "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS": "Back to partner users",
    "PARTNERS_ADD_OR_EDIT_CAPTION_1": "Add",
    "PARTNERS_ADD_OR_EDIT_CAPTION_2": "Edit",
    "PARTNERS_ADD_OR_EDIT_CAPTION_3": "Partner User",
    "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL": "Email",
    "PARTNERS_HEADER_TEXT_MANAGE_TAG": "Manage Tag",
    "PARTNERS_LABLE_TEXT_TAG_KEY": "Tag Key",
    "PARTNERS_LABLE_TEXT_TAG_VALUES": "Tag Values",
    "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER": "Password should contain at least one capital letter, one small letter ,one number and one special character.",
    "PAYMENT_LABEL_FILTER": "Filter by",
    "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS": "Payment profile Set to default",
    "PAYMENT_PROFILE_DELETE_SUCCESS": "Payment profile deleted",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME": "Cardholder Name",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER": "Card Number",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH": "Expiry Month",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR": "Expiry Year",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE": "Security Code",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME": "Name on credit card",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER": "Credit card number",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH": "Two digit month number",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR": "Four digit year",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE": "Three digit CVV number on credit card",
    "PAYMENT_PROFILE_SAVE_FAILURE": "Payment details saving failed ",
    "PAYMENT_PROFILE_SAVE_SUCCESS": "Payment details saved ",
    "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY": "Payment details will be updated shortly. Please DO NOT RETRY and check back after sometime.",
    "PAYMENT_PROFILE_TEXT_FAILURE": "Failure ",
    "PAYMENT_PROFILE_TEXT_SUCCESS": "Success ",
    "PAYMENT_STATUS_DESC_ACCEPTED": "Accepted",
    "PAYMENT_STATUS_DESC_CHARGED": "Charged",
    "PAYMENT_STATUS_DESC_DECLINED": "Declined",
    "PAYMENT_STATUS_DESC_FAILED": "Failed",
    "PAYMENT_STATUS_DESC_FINALIZED": "Current month outstanding",
    "PAYMENT_STATUS_DESC_INPROGRESS": "In Progress",
    "PAYMENT_STATUS_DESC_PARTIAL_PAID": "Partially Paid",
    "PAYMENT_STATUS_DESC_SUCCESS": "Succeeded",
    "PAYMENT_STATUS_DESC_TECHNICAL_ERROR": "Technical Error",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL": "Cancel",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT": "Submit",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE": "Update",
    "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD": "This field is required.",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER": "Account Number",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE": " Account Type",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME": "Alias Name",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT": "Is Business Account?",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY": "Company",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL": "Individual",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO": "No",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES": "Yes",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER": "Card Number",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING": " Checking",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CVV": "CVV",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH": "Expire Month",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR": "Expire Year",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT": "Name on Account",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD": "Name on Card",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER": "Routing Number",
    "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING": " Saving",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT": "Bank account",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD": "Credit card",
    "PAYMENT_TEMPLATE_WAIT_MESSAGE": "Please wait while the page loads",
    "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT": "Billed amount",
    "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME": "Company name",
    "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT": "Paid amount",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS": "Payment status",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE": "Reason for failure",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS": "Remarks",
    "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE": "Transaction date",
    "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER": "Paid by",
    "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS": "Failure Transactions",
    "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS": "Pending Payments",
    "PAYMENTS_STATUS_DESC_FAILED": "Failed",
    "PAYMENTS_STATUS_DESC_SUCCESS": "Success",
    "PAYMENTS_TABLE_HEADER_AMONT": "Paid amount",
    "PAYMENTS_TABLE_HEADER_BILLED_AMONT": "Billed amount",
    "PAYMENTS_TABLE_HEADER_BILLING_EMAIL": "Billing email",
    "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME": "Customer name",
    "PAYMENTS_TABLE_HEADER_FAILURE_REASON": "Failure reason",
    "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD": "Billing period",
    "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS": "Invoice status",
    "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER": "Invoice number",
    "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED": "Is invoice based?",
    "PAYMENTS_TABLE_HEADER_OWNED_BY": "Owned by",
    "PAYMENTS_TABLE_HEADER_PAID_AMONT": "Paid amount",
    "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT": "Payable amount",
    "PAYMENTS_TABLE_HEADER_PAYMENT_ID": "Payment ID",
    "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD": "Payment method",
    "PAYMENTS_TABLE_HEADER_PENDING_AMONT": "Pending amount",
    "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT": "Pending amount",
    "PAYMENTS_TABLE_HEADER_REMARKS": "Remarks",
    "PAYMENTS_TABLE_HEADER_STATUS": "Status",
    "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL": "Partial payment",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE": "Transaction Date",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_ID": "Transaction Id",
    "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION": "You have passed your 7 calendar days window for reducing seats for this subscription.",
    "PC_ACTIVATION_ERROR": "Cannot enable a child subscription, when the parent subscription is not Active",
    "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT": "The addon is not purchasable without a compatible base product",
    "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND": "Advisor partner information not found to complete this request. ",
    "PC_ADVISOR_QUOTE_NOT_FOUND": "Advisor quote not found. Kindly check the information before trying again. ",
    "PC_ALREADY_ACTIVATE_ITEM": "This item has already been activated. ",
    "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING": "An Azure plan is required to purchase a SaaS subscription with a metered billing plan. Kindly contact partner for more information. ",
    "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION": "This API method is not supported for legacy subscription",
    "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND": "Availability referenced by line item not found",
    "PC_AVAILIBILTY_QUANTITY": "Availability has more than one included quantity options. Kindly contact partner for more information. ",
    "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE": "Azure plan cannot be moved to suspended state since you have one or more Azure Subscriptions in the active state. ",
    "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT": "The specified Azure plan ID was not found or there are no active Azure subscriptions under it. An Azure Plan with active subscription(s) is required to purchase a SaaS product with a metered billing plan",
    "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND": "Azure subscription with the given entitlement ID is not found.",
    "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM": "The billing cycle cannot be longer than the term.",
    "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED": "Subscriptions billing cycle does npt match with the original order billing cycle",
    "PC_BILLING_CYCLE_IS_INVALID": "The billing cycle is invalid.",
    "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS": "This request for transfer of billing ownership cannot be completed as third-party marketplace products do not transfer with subscriptions. Remove the third-party marketplace products from your selection and try again.",
    "PC_C3_CONNECTION_ERROR": "Error while connecting to Partner Center, please retry or contact support.",
    "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT": "Can not change billing cycle if subscription is not associated with a Mint account",
    "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE": "Can not process Azure upgrade for Azure Partner Shared Services",
    "PC_CANCEL_PURCHASE_NON_TENANT": "Non-Sandbox tenants are not allowed to cancel purchases other than software subscriptions and perpetual software",
    "PC_CANCELLATION_NOT_ALLOWED": "Cancellation is not allowed at this time!",
    "PC_CANNOT_CHECKOUT": "An already submitted cart cannot be checked out!",
    "PC_CANNOT_PROCESS_AZURE_UPGRADE": "Cannot process Azure upgrade. Kindly contact support for more information. ",
    "PC_CANNOT_PROCESS_REQUEST": "Cannot process the transfer request for {1} transfer {0}",
    "PC_CANNOT_TRANSITION_TO_AZURE_PLAN": "You have one or more Azure subscriptions in the suspended state. These cannot be transitioned to the Azure plan",
    "PC_CANNOT_UPDATE_BILLING_CYCLE": "Technical challenges are preventing the updates to subscription. Please contact support.",
    "PC_CART_IN_PROCESS": "Cart is already being processed, please wait and check back after some time.",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE": "The catalog item is not eligible for purchase. Please contact support for more information. ",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION": "The catalog item is not eligible for add or remove quantity. Kindly contact partner for more information. ",
    "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE": "Catalog Item requires attestation acceptance.",
    "PC_CREATE_ORDER_ERROR": "Create order is not successful",
    "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW": "Customer account is under review. Transactions are not allowed until the account is approved.",
    "PC_CUSTOMER_MIGRATION_FAILED": "The customer license migration failed. Please contact support. ",
    "PC_CUSTOMER_PHONE_NUMBER_MISSING": "Customer phone number is missing. This error is thrown if the customer’s company/organization is located in the following countries: Armenia(AM), Azerbaijan(AZ), Belarus(BY), Hungary(HU), Kazakhstan(KZ), Kyrgyzstan(KG), Moldova(MD), Russia(RU), Tajikistan(TJ), Uzbekistan(UZ), Ukraine(UA) but billingProfile.defaultAddress.phoneNumber is not passed in.",
    "PC_DISCONTINUED_OFFER": "This offer is not currently available for purchase as it may have been discontinued. Please contact Partner for more information. ",
    "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY": "One or more of the order items do not support the given currency, USD. Input currency should be either partner or customer (market) currency.",
    "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS": "The custom term end date specified on the scheduled next term instructions must be within the first term duration after renewal. Also, monthly term products cannot align with a subscription ending on the 28th, 29th, or 30th of the month, unless that date is the last day of the month.",
    "PC_GENERIC_ERROR": "Something went wrong. Please contact support for more information or try again after checking the status of operation performed. ",
    "PC_INACTIVE_RESELLER_PROGRAM": "Reseller program status is not active. Kindly contact support for more information. ",
    "PC_INCORRECT_CUSTOMER_ID": "Incorrect customer ID. Please check the information and try again. ",
    "PC_INITIATE_TRANSFER_REQUEST_FAILED": "Initiate transfer request failed. Please try again later after sometime. ",
    "PC_INVALID_ADD_ON": "The add-on is not related to the specified parent subscription",
    "PC_INVALID_AZURE_SUBSCRIPTION": "This subscription is not a valid Azure subscription",
    "PC_INVALID_CUSTOMER_INFORMATION": "The information provided doesn't match with current customer information. Kindly check the information and try again. ",
    "PC_INVALID_DOMAIN": "Invalid domain. Kindly provide correct domain address and try again.",
    "PC_INVALID_ETAG": "Etag is no longer valid",
    "PC_INVALID_LINE_ITEM": "Line item sequence is not properly set, please contact support.",
    "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING": "Invalid metadata. (If the customer price, currency is missing).",
    "PC_INVALID_MPN": "Invalid MPN, please contact support for further investigation",
    "PC_INVALID_OPERATION": "This operation is not supported. Please contact partner for more information. ",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID": "Organization registration id value is not supported. Kindly check the input value before trying again. ",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT": "Organization registration id value or format is not supported. Kindly check the input value before trying again. ",
    "PC_INVALID_PROMOTION_CODE": "Promotion code is not valid. Kindly check and try again. ",
    "PC_INVALID_QTY_FOR_SUBSCRIPTION": "Subscription quantity is not within minimum and maximum allowed quantity",
    "PC_INVALID_REQUEST": "Invalid request. Kindly check the request attributes before trying again. ",
    "PC_INVALID_SUBSCRIPTION": "Not a valid subscription, please contact support.",
    "PC_INVALID_SUBSCRIPTION_TRANSER": "Transfer cannot be created with invalid subscription. Kindly check the subscription before trying again. ",
    "PC_ISSUE_ASSET_STATUS_CHANGE": "Asset status change operation could not complete. Kindly contact partner for more information. ",
    "PC_ISSUE_OFFER_TYPE": "Purchase couldn't complete because of an issue with the offer type. Kindly contact support. ",
    "PC_ITEM_IS_NOT_AVAILABLE": "This item is not available",
    "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER": "The requested number of exceeded the remaining limit  allowed per subscription for the offer. Kindly adjust the quantity before trying again. ",
    "PC_LIMIT_EXCEEDED": "Use limit is exceeded for Offer id",
    "PC_LINE_ITEM_ISSUE": "There is an issue with the line item. Please contact support for more information. ",
    "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID": "Migration cannot be performed because two or more add-on migrations were specified with the same current subscription ID",
    "PC_MISSING_ASSET_ID": "The operation could not  complete because of missing asset id. Kindly contact support for more information. ",
    "PC_MISSING_AZURE_SUBSCRIPTION": "Cannot process Azure upgrade since Azure subscription information was not found. ",
    "PC_MISSING_MCA": "Please accept MCA before proceeding with the purchase.",
    "PC_MISSING_REQUIRED_TAGS_IN OFFER": "Account does not have required tags for the offer. Kindly contact partner for more information. ",
    "PC_MISSING_TRANSFER_REQUEST": "No transfer request was found. ",
    "PC_MISSING_UPGRADE_REQUEST": "No upgrade request was found.",
    "PC_MPN_NOT_FOUND": "MPN Id is not found",
    "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED": "Multiple transfer requests not allowed. Another transfer request in progress. ",
    "PC_NCE_MIGRATION_COULD_NOT_FOUND": "New-Commerce migration could not be found.",
    "PC_NO_AZURE SUBSCRIPTION": "Cannot process Azure upgrade since customer has no Azure subscriptions.",
    "PC_NOT_ELIGIBLE_AUTO_RENEWED": "The subscription is not eligible to be auto-renewed. Kindly contact partner for more information. ",
    "PC_NOT_ELIGIBLE_FOR_TRANSACTION": "Partner is not eligible for this transaction, please contact support.",
    "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED": "One or more Azure subscriptions were purchased recently, these subscriptions cannot be transitioned at this time. Please try again later. ",
    "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH": "Operation type is not supported in batch. Kindly perform the operation on individual subscription.",
    "PC_ORDER_CANCELLATION": "The order cannot be cancelled. Please contact support for assistance. ",
    "PC_ORDER_CANCELLATION_ERROR": "Order cannot be cancelled.",
    "PC_ORDER_IN_PROGRESS": "Your order is in progress. Please check your order history for recent orders in few minutes. ",
    "PC_ORDER_NOT_FOUND": "Order not found!",
    "PC_ORDER_VENDOR_POLICY": "The order could not be placed because of a vendor policy in place. Kindly contact support to know more. ",
    "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING": "Organization registration id information is missing. This error is thrown if the customer’s company/organization is located in the following countries: Armenia(AM), Azerbaijan(AZ), Belarus(BY), Hungary(HU), Kazakhstan(KZ), Kyrgyzstan(KG), Moldova(MD), Russia(RU), Tajikistan(TJ), Uzbekistan(UZ), Ukraine(UA)but organizationRegistrationNumber is not passed in.",
    "PC_ORGANISATION_REGESTRATION_ID_MISSING": "Organization registration id information is missing. Kindly input a valid registration Id before trying again. ",
    "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED": "Organization registration id value is not supported. This error is thrown if the customer’s company/organization is not located in the following countries: Armenia(AM), Azerbaijan(AZ), Belarus(BY), Hungary(HU), Kazakhstan(KZ), Kyrgyzstan(KG), Moldova(MD), Russia(RU), Tajikistan(TJ), Uzbekistan(UZ), Ukraine(UA), but they tried to specify organizationRegistrationNumber.",
    "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID": "Organization registration id information is invalid. Kindly check the input and try again. ",
    "PC_ORGINAL_TRANSFER_IN_PROGRESS": "This transfer is not allowed as original transfer {0} is in progress",
    "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP": "Overage is already assigned to another tenant. Contact your customer to resolve ownership questions",
    "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION": "Overage can not be set as the customer has legacy Azure subscriptions.",
    "PC_PARTNER_ATTESTATION_MISSING": "Partner Attestation is missing",
    "PC_PARTNER_INFORMATION_MISSING": "The request could not complete as partner information is missing. Kindly contact support for more information. ",
    "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE": "Partner tenant ID is not yet allowed to perform migrations to New Commerce on their subscriptions",
    "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE": "Cannot update the partner on record for this subscription. Kindly contact Microsoft support for assistance. ",
    "PC_PENDING_ADJUSTMENT_ORDER_REQUEST": "There is a pending adjustment requested for this order. Please contact partner for more information.",
    "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION": "There is a pending operation on this subscription. Please try again after 24 hours.",
    "PC_PHONE_NUMBER_MISSING": "Customer phone number is missing. Kindly provide customer phone number before trying again. ",
    "PC_PRICING_INFORMATION_NOT_PERMITTED": "Access to pricing information is not permitted. Kindly contact support. ",
    "PC_PRODUCT_NOT_FOUND": "Product was not found",
    "PC_PROPERT_NOT_SUPPORTED": "This property is not supported. Please review and try again. ",
    "PC_PURCHASE_NOT_SUPPORTED": "Purchase system not supported. Kindly contact partner for more information.",
    "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED": "Purchase order document is required.",
    "PC_PURCHASE_PRE_REQUISTIES_MISSING": "One or more purchase pre-requisites are not met. Kindly contact partner for more information. ",
    "PC_QTY_UPDATE_ERROR_DURING_SUSPEND": "Subscription quantity cannot be increased or decreased while subscription is being suspended",
    "PC_QUANTITY_CANNOT_DECREASED": "Subscription quantity cannot be decreased. Kindly contact partner for more information. ",
    "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM": "The quantity is not available for this catalog item. Kindly adjust the quantity before placing an order or contact partner for more information. ",
    "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION": "Quantity changes on subscription with suspended and deleted status is not supported. Kindly contact the partner for more information. ",
    "PC_REQUEST_INFORMATION_NOT_AVAILABLE": "The operation could not be complete because the requested information is not currently available.  Please try again or contact support.  ",
    "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER": "The requested quantity is not within the allowed quantity for this offer.",
    "PC_RESELLER_RELATIONSHIP_MISSING": "There is no reseller relationship with the customer. Kindly establish reseller relationship before attempting this action again. ",
    "PC_RI_PURCHASE_NOT_ENABLED": "This subscription is not enabled for RI purchase. Please contact partner for more information.",
    "PC_SANDBOX_LIMIT": "The sandbox limit has been met. Kindly adjust the limit before placing an order. ",
    "PC_SELLING_NOT_ALLOWED_IN_COUNTRY": "Selling in the customer country in not supported. Kindly check Microsoft CSP markets list to find more. ",
    "PC_SKU_NOT_FOUND": "SKU not found",
    "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME": "Something went wrong. Try again after some time.",
    "PC_SUBCRIPTION_ACTIVE_ISSUE": "This subscription is not an active subscription. Please contact partner for more information. ",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS": "Subscription cannot be migrated to New Commerce because it has add-ons.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE": "Subscription cannot be migrated to New Commerce because the combination of term duration and billing cycle is not supported for the New-Commerce product.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH": "Subscription cannot be migrated to New Commerce because there is not a valid migration path for this offer yet.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON": "Subscription cannot be migrated to New Commerce because of one or more reasons (possible reasons: subscription not in Active state - subscription has promotions applied to it - subscription has add-ons attached to it - subscription is too close to term end - subscription offer is not available in New Commerce yet)",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED": "Subscription cannot be migrated to New Commerce because it has promotions applied to it",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED": "Subscription cannot be migrated to New Commerce because the subscription is currently being processed.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END": "Subscription cannot be migrated to New Commerce because it is less than 24 hours from the term end.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS": "Subscription cannot be migrated to New Commerce because it has been active for 1 month or less. Please try again later.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION": "Subscription cannot be migrated to New-Commerce because one or more add-on subscriptions specified in the AddOnMigrations collection do not exist or are not active.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID": "Subscription cannot be migrated to New Commerce because the partner ID on record in the current subscription is not valid.",
    "PC_SUBSCRIPTION_HISTORY_ISSUE": "Subscription history could not be retrived. Kindly contact support for more information. ",
    "PC_SUBSCRIPTION_NOT_REGISTERED": "This subscription is not registered. Kindly contact partner for more information. ",
    "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE": "Subscription offer is not available for purchase. Kindly contact partner for more information. ",
    "PC_SUBSCRIPTION_UPDATE": "Subscription is not ready to be updated. Please try again after sometime or contact support if the issue persists. ",
    "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED": "The desired number of subscriptions exceeded the maximum number of subscriptions allowed per customer",
    "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE": "Subscription cannot be migrated to New Commerce because the status is not active.",
    "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION": "Target subscription commitment end date must be later than source subscription",
    "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION": "Target transition can not have a term duration shorter than that of the source subscription.",
    "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE": "The target transition ToSubscriptionId field is invalid. The target subscription is not ready to be upgraded. Please try again after sometime.",
    "PC_TENANT_ALREADY_USED": "The domain specified during tenant creation is already used by another tenant. Choose another domain and retry",
    "PC_TENANT_NOT_EXISTS": "The domain name does not exists, please verify the domain name.",
    "PC_TENANT_NOT_FOUND": "Tenant not found.",
    "PC_TERM_DURATION_ON_TARGET_IS_INVALID": "The term duration on the target transition is invalid.",
    "PC_TERM_EXCEEDED": "The initial purchase term is no longer available for purchase/update",
    "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT": "The catalog item id is not present. Kindly contact support for assistance. ",
    "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION": "The request failed to retrieve all availabilities resource information. Kindly check after sometime before trying again. ",
    "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE": "The requested program is not active. Kindly contact support for know more. ",
    "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION": "The supplied role does not have the rights to perform the requested operation. Kindly contact support for more information. ",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED": "This operation is not supported. Kindly contact support for more information. ",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV": "This operation is not supported in this environment. Kindly contact partner for more information. ",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN": "This transfer request cannot be initiated as customer has not purchased Azure plan. ",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT": "This transfer request cannot be initiated as the Azure plan is not purchasable. Kindly contact partner for more information. ",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE": "This transfer request cannot be initiated as the Azure plan is not active, please enable the Azure plan and try again. ",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION": "This transfer request cannot be initiated as the customer has legacy Azure subscriptions. ",
    "PC_TOKEN_MISSING": "Partner Token missing in request context.",
    "PC_TRANSACTION_ELIGIBLE": "You may be eligible for this transaction. Please contact Support for help",
    "PC_TRANSACTION_NOT_ELIGIBLE": "You are not eligible for this transaction. Please reduce the quantity or  contact Support for help. ",
    "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED": "Transfer cannot be created as partner's: {0} national cloud {1} is not supported",
    "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING": "Transfer cannot be initiated as source partner email/domain details missing in the request. Kindly input the requested information before trying again. ",
    "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER": "Transfer creation failed due to source partner details matched with requesting partner. Kindly contact Microsoft support for more information. ",
    "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T": "This request for transfer of billing ownership cannot be completed as Azure reservations do not transfer with subscriptions. Cancel the Azure reservations associated with the subscriptions in your selection and try again.",
    "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED": "Transfer request cannot be accepted. Please request the partner to create transfer without Azure subscription(s)",
    "PC_TRANSFER_REQUEST_DISABLE": "Transfer cannot be created as partner: {0} not enabled for this feature",
    "PC_UNABLE_TO_COMPLETE_THE_REQUEST": "Unable to complete the request. Kindly contact Microsoft support for more information. ",
    "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE": "Unable to find customer profile to complete the request. Kindly contact support for more information. ",
    "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER": "Unable to get Azure Active Directory subscriptions for a customer with tenant id {0} and source provisioning id {1}",
    "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE": "Unable to process order due to insufficient credit limit, please contact ucmwrcsp@microsoft.com for further assistance",
    "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION": "There was an unexpected error when processing the New-Commerce migration.",
    "PC_UNSUCCESSFUL_TRANSFER_ORDER": "Transfer order is not successful. Kindly contact support for more information. ",
    "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION": "Upgrade request cannot be processed for already upgraded subscription. ",
    "PC_UPGRADE_REQUEST_IN_PROGRESS": "Upgrade request has not been completed because there is another upgrade request in progress. ",
    "PC_USER_LIMIT_EXCEEDED": "The desired seat count exceeded the maximum seat count allowed per subscription",
    "PC_USER_NOT_FOUND": "User not found in the target tenant.",
    "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED": "When part of tender, either tender link or tender document is required.",
    "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER": "You are not eligible to manage overage for this customer.",
    "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE": "You have passed your cancellation window. We are unable to cancel your purchase",
    "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION": "You have passed your day window for reducing seats for this subscription.",
    "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT": "Your company has not accepted the Microsoft Partner Agreement (MPA). The global admin of the CSP account must accept the MPA to resume the full transactional capabilities.",
    "PCA_MISSING_MPA": "Missing Microsoft Partner Agreement from the Partner.",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS": "Get Pending payment status from billing",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3": "Update payment statusin c3",
    "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS": "Updated the payment status for {{recordName}}",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION": "Actions",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS": "Billing payment status",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS": "C3 payment status",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME": "Entity name",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER": "Invoice number",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT": "Paid amount",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME": "Record name",
    "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS": "View details",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS": "Actions",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME": "Customer name",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID": "Order ID",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON": "Ordered on",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS": "Remarks",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE": "Request type",
    "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS": "Software Keys",
    "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS": "Download Software And Keys",
    "PHONE_TABLE_HEADER_ACTIONS": "Actions",
    "PHONE_TABLE_HEADER_PHONE_NUMBER": "Phone number",
    "PHONE_TABLE_HEADER_PHONE_TYPE": "Phone type",
    "PHONE_TYPE_HOME": "Home",
    "PHONE_TYPE_HOME_FAX": "Home Fax",
    "PHONE_TYPE_MAIN": "Main",
    "PHONE_TYPE_MOBILE": "Mobile",
    "PHONE_TYPE_OFFICE": "Office",
    "PHONE_TYPE_OTHER": "Other",
    "PHONE_TYPE_WORK": "Work",
    "PHONE_TYPE_WORK_FAX": "Work Fax",
    "PLACE_HOLDER_SEARCH_CUSTOMER": "Search customer",
    "PLACEHOLDER_ENTER_TEXT": "Enter text",
    "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN": "Select billing-cycle...",
    "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN": "Select billing-type...",
    "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN": "Select Categories",
    "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN": "Select category-type...",
    "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN": "Select consumption-type...",
    "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN": "Search currency code",
    "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN": "Search Customer",
    "PLACEHOLDER_FOR_CVV_INPUT_FIELD": "CVV",
    "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER": "Select a provider customer in the list or search the name or tenant-id...",
    "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE": "Effective From",
    "PLACEHOLDER_FOR_MONTH_INPUT_FIELD": "MM",
    "PLACEHOLDER_FOR_PLAN_DESCRIPTION": "Enter text",
    "PLACEHOLDER_FOR_PLAN_NAME": "Enter Plan Name",
    "PLACEHOLDER_FOR_PRICE": "Price",
    "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN": "Select Providers",
    "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER": "Search Customer",
    "PLACEHOLDER_FOR_ROLES_DROPDOWN": "Select Roles..",
    "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE": "Select",
    "PLACEHOLDER_FOR_TAGS_DROPDOWN": "Select Tag values..",
    "PLACEHOLDER_FOR_TEXT_SEARCH": "Search...",
    "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN": "Select validity type...",
    "PLACEHOLDER_FOR_YEAR_INPUT_FIELD": "YYYY",
    "PLACEHOLDER_SPECIFIC_IDIVIDUALS": "Select Specific {{EntityName}}? Click here...",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG": "Type to search for a tag",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT": "Select a Tenant in the list or search the name/tenant-id...",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN": "Search plan",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT": "Product name",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID": "Product Id",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID": "Search with product name or product Id",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE": "Product name, Id",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION": "Search for Subscription",
    "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS": "Enter valid email ids",
    "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE": "Max. cost price",
    "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE": "Max. sale price",
    "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY": "Max. validity",
    "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE": "Min. cost price",
    "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE": "Min. sale price",
    "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY": "Min. validity",
    "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT": "Payment Amount",
    "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT": "Type here...",
    "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN": "Choose an existing customer from the list or search by using customer name/tenant id/domain",
    "PLACEHOLDER_TEXT_FOR_REMARKS": "Remarks",
    "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP": "Check this box if you plan to have composite offers in this plan in future.",
    "PLAN_ASSIGN_STATUS_SUCCESS": "Status was updated successfully",
    "PLAN_BREADCRUMB_BUTTON_PLAN": "Plans",
    "PLAN_BUTTON_LABEL_ADD_NEW_PLAN": "Add New Plan",
    "PLAN_CAPTION_TEXT_PLANS": "Plans",
    "PLAN_CATEGORY_TAG_TEXT_NCE": "NCE",
    "PLAN_CHANGE_FOR_CUSTOMER": "Change plan of the customer ",
    "PLAN_CHANGE_TEXT": "Plan Change",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS": "Composite plan offers",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN": "Plans",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS": "Back to Composite Offers",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER": "Add Composite Offer",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER": "Edit Composite Offer",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS": "Offers",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED": "Items selected",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE": "Suggested Price : ",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1": "Provide the billing component Id in case you wish to use an existing component in your billing system. Leaving it at 0 creates a new component in your billing system.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2": "Do ensure that you specify the price that matches your component in your billing system. The system doesn't validate the price and component combination against the billing system, at the moment.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS": "Add-on(s)",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER": "Add-ons",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE": "ERP Price",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE": "List Price",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME": "Offer name",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE": "Remove",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION": "Description",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID": "Billing Component Id",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME": "Offer name",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE": "Price",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER": "Add Composite Offer",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS": "Back to Plan Details",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR": "Composite Offers for - ",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT": "Edit",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS": "Included offers",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME": "Name",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE": "Price",
    "PLAN_DETAILS_ADDON_PARENT_NAME": "<b>under</b> {{ParentOfferName}}",
    "PLAN_DETAILS_BUTTON_TEXT_SIGNUP": "SignUp",
    "PLAN_DETAILS_IS_PUBLIC": "Is Public",
    "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS": "Supported Markets",
    "PLAN_DETAILS_PARAGRAPH_1_TEXT": "per month",
    "PLAN_DETAILS_PARAGRAPH_2_TEXT": "Estimated retail price",
    "PLAN_DETAILS_PARAGRAPH_3_TEXT": "per year",
    "PLAN_DETAILS_PARAGRAPH_4_TEXT": "one time",
    "PLAN_DETAILS_SHARING_URL": "Sharing Url",
    "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT": "Successfully submitted your changes",
    "PLAN_IS_IN_PROGRESS_EXCEPTION": "Plan still in progress. Please check the status",
    "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR": "Minimum value is -1",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_1": "Set the limit as -1 to require approval for all the purchases/update seats.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_2": "Set the limit as 0 to allow purchase of unlimited seats.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_3": "Set a non-zero, positive number to define the threshold.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_4": "   Any purchase exceeding the threshold requires approval from the Partner.",
    "PLAN_LEVEL_SEATLIMIT_EDIT": "Set plan product wise seat limit",
    "PLAN_LEVEL_SEATLIMIT_REVERT": "Revert back to plan level seat limit",
    "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP": "Load latest available Microsoft NCE promotions to the plans.",
    "PLAN_MACRO_DETAILS_LABEL_TEXT": "Macro details",
    "PLAN_MACRO_VALUE_LABEL_TEXT": "Macro value",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN": "Back to plans",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS": "Manage composite offers",
    "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN": "Details for Plan -",
    "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN": "New plan",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS": "Load promotions",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT": "Cancel",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT": "Load promotions from provider",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT": "Submit",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE": "Is private",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE": "Allow CSP subscriptions to be shared against offers in the plan?",
    "PLAN_MANAGE_ADD_FORM_HEADER_OFFER": "Offers",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1": "Here are the steps to override an inherited offer:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2": "Check the box against the offer you wish to override",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3": "Provide a new friendly name",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4": "Specify the new sale price",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5": "The new plan would start listing the overridden offers along with those that are inherited but not overridden.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2": "Provide the billing component Id in case you wish to use an existing component in your billing system. Leaving it at 0 creates a new component in your billing system.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1": "Note:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2": "Do ensure that you specify the price that matches your component in your billing system. The system doesn't validate the price and component combination against the billing system, at the moment.",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN": "Overridden from Parent Plan",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED": "selected",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS": "Items",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON": "Add-on(s)",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE": "Available for purchase",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID": "Billing Component Id",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE": "ERP Price",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME": "Friendly name",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE": "List price",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER": "Offer name",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON": "ADD-ON",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE": "License type",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE": "NCE",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE": "Remove",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE": "Sale price",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE": "Update price",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME": "Plan name: ",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE": "Price: ",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE": "Set new price",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE": "License type",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME": "Offer name",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN": "Offer overridden",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED": "Offer selected",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION": "Description",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME": "Plan name",
    "PLAN_MANAGE_ADD_FORM_URL_LABEL": "Sharing URL",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS": "Plans",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS": "Manage ",
    "PLAN_MANAGE_ERROR_FRIENDLY_NAME": "Friendly name cannot be empty",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME": "Show offers with promotion",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE": "offerWithPromotion",
    "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER": "TRIAL OFFER",
    "PLAN_MANAGEMENT_TITLE_PARENT_NAMES": "Parent names",
    "PLAN_OFFER_PRICE_CHANGE_HEDER": "Is Invoice Based?",
    "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE": "Set new price for ",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE": "Price change would be effective",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE": "New Price",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE": "Current price",
    "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION": "Successfully updated the plan offer price",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN": "Manage Plan",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN": "Override Plan",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS": "Plans",
    "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS": "Back to plans",
    "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN": "Edit Plan",
    "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN": "Override Plan",
    "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE": "Allow CSP Subscriptions to be shared against offers in the plan?",
    "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE": "Is Private",
    "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION": "Description",
    "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME": "Plan Name",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2": "  Provide the billing component Id in case you wish to use an existing component in your billing system. Leaving it at 0 creates a new component in your billing system.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1": "Note:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2": "Do ensure that you specify the price that matches your component in your billing system. The system doesn't validate the price and component combination against the billing system, at the moment.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1": "Here are the steps to override an inherited offer:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2": "Check the box against the offer you wish to override",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3": "Provide a new friendly name",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4": "Specify the new sale price",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5": "The new plan would start listing the overridden offers along with those that are inherited but not overridden.",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE": "Available for purchase",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID": "Billing component ID",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE": "ERP price",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME": "Friendly name",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE": "List price",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME": "Offer name",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN": "Offer overridden",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE": "Sale price",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE": "Items selected to override ",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS": "Offers",
    "PLAN_OVERRIDE_FORM_URL_SHARING_URL": " Sharing URL",
    "PLAN_POPUP_GRID_HEADER": "Download plan grid",
    "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION": "This product is already available in plan, do you want to add the product again?",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE": "Plan currency code",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME": "Plan Name",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE": "Target currency code",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT": "Select",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE": "Source currency code",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE": "Target currency code",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY": "Define new currency",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS": "Number of seats allowed",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE": "Sale price",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS": "Number of Seats",
    "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE": "Invalid seat value for product {{PlanProductName}}",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE": "Error occured while updating seats",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE": "Seats updated successfully",
    "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE": "Slabs successfully saved",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT": "Edit",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE": "Override",
    "PLAN_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "PLAN_TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "PLAN_TABLE_HEADER_TEXT_NAME": "Name",
    "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT": "Please select atleast two offers in order to create a composite offer",
    "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS": "Composite offer saved successfully",
    "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS": "Composite offer updated successfully",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL": "Cancel",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE": "Close",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT": "Submit",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL": "All products",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND": "No records found",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION": "{{(!maxValue) ? \"Please enter value greater than \" + minValue :  \"Please enter value between \" + minValue + \" and \" + maxValue }}",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT": "Contract Details",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE": "Billing Type",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER": "Cost to partner",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA": "Slabs",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE": "Sale price",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE": "Max value",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE": "Min value",
    "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY": "Applicability",
    "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE": "You already have a contract offer in your plan. Delete/disable the offer before adding a new one.",
    "PLANS_MANAGE_TAB_TITLE_CONTRACT": "Contract",
    "PLANS_MANAGE_TAB_TITLE_RSERVATION": "Reservation",
    "PLANS_OVERRIDE_EDIT_SUCCESS": "Overridden plan updated successfully",
    "PLANS_OVERRIDE_SAVE_SUCCESS": "Plan overridden successfully",
    "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT": "Please select offers to be overridden from the base plan",
    "PLANS_SAVE_SUCCESS": "Plan saved successfully",
    "PLANS_UPDATE_SUCCESS": "Plan updated successfully",
    "PO_NUMBER": "PO #",
    "POLICY_ASSIGNMENT_SCOPE_TITLE": "Policy assignment Scopes",
    "PONUMBER_BUTTON_CLOSE": "Close",
    "PONUMBER_BUTTON_SKIP": "Skip",
    "PONUMBER_BUTTON_SUBMIT": "Submit",
    "PONUMBER_DELETE_CONFIRMATION": "Are you sure you want to delete this PO Number?",
    "PONUMBER_DELETE_ERROR_MESSAGE": "Error occurred While removing po number from cart!",
    "PONUMBER_DELETE_SUCCESS_MESSAGE": "Removed the requested PO Number successfully!",
    "PONUMBER_PLACEHOLDER": "Enter PO Number",
    "PONUMBER_TITLE": "PO Number",
    "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT": "Are you sure you want to enqueue all the plans?",
    "POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "POPUP_BUTTON_TEXT_CLOSE": "Close",
    "POPUP_BUTTON_TEXT_CONFIRM": "Confirm",
    "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT": "All selected offer under the selected billing cycle will be lost. Do you want to continue?",
    "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT": "This customer already contains a coupon: <b>{{CouponName}}</b>, do you want to replace it?.",
    "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT": "Removing of billing customer will remove associated payment profiles too. Are you sure you want to remove billing customer link for {{CustomerName}}?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT": "Are you sure you want to delete the custom notification?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT": "Deleted custom notification successfully.",
    "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT": "Are you sure you want to delete {{CustomerName}}?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT": "Deleting here will results in removing the offer from the reseller plans added and their customers can’t purchase it further! Are you sure want to delete {{distributorOffer}} offer?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT": "Deleted {{customOfferName}} successfully.",
    "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2": "Are you sure you want to delete this notification?",
    "POPUP_DELETE_HEADER_TEXT_DELETE": "DELETE",
    "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT": "Are you sure you want to delete <br> {{customOfferName}}?",
    "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT": "Deleted {{customOfferName}} successfully.",
    "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT": "<sup class='text-warning'>TRIAL OFFER</sup>",
    "POPUP_DELETE_PLAN_CONFIRMATION_TEXT": "Are you sure you want to delete {{planName}}?",
    "POPUP_DELETE_SUB_HEADER_TEXT": "Are you sure you want to delete?",
    "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT": "Are you sure you want to delete this tagged entity?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT": "Are you sure you want to delete the message {{MessageBody | limitTo:30}}?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2": "Are you sure you want to delete this message?",
    "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT": "Are you sure you want to delete the webhook notification?",
    "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT": "Webhook notification deleted successfully",
    "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT": "Deleted webhook notification successfully.",
    "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION": "Are you sure you want to disable {{productName}}?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION": "<b>{{productName}}</b> is parent offer for trail product.<br/> If this offer is disabled, the trail offer will be disabled as well.<br/>  Are you sure you want to disable <b>{{productName}}<b/>?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION": "<b>{{productName}}</b> is parent offer for trail product.<br/> Disabling this product will affect the conversion of trail product to subscription<br/> Are you sure you want to disable <b>{{productName}}<b/>?",
    "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION": "Are you sure you want to enable {{productName}}?",
    "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION": "<b>{{productName}}</b> parent offer is not active.<br/> Enabling trial offer will also activate parent offer.<br/> Are you sure you want to enable <b>{{productName}}<b/>?",
    "POPUP_ENGAGE_NOTIFICATION_DELETED2": "Engage Notification is deleted.",
    "POPUP_PLAN_DELETED": "Plan {{planName}} is deleted.",
    "POPUP_REVERT_HEADER_TEXT_DELETE": "REVERT",
    "POPUP_REVERT_SUB_HEADER_TEXT": "Are you sure you want to revert?",
    "POPUP_TEXT_DEACTIVATE_PSA_LOG": "Are you sure you want to deactivate the log(s). We do not delete logs in the PSA, this operation will enable you to re-upload the data more than once from C3 to PSA. Please be advised that reupload could potentially create duplicates in PSA.",
    "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY": "De-activated PSA logs successfully",
    "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT": "Your selected offers with the current billing cycle will be lost, do you still want to continue?",
    "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT": "You have unsaved changes, Do you still want to continue?",
    "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT": "Are you sure you want to update customer name?",
    "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT": "Are you sure you want to update reseller name?",
    "POPUP_WEB_NOTIFICATION_DELETED": "Web Notification {{MessageBody}} is deleted.",
    "POPUP_WEB_NOTIFICATION_DELETED2": "Web Notification is deleted.",
    "PORTAL_ACCESS_GRANTED_TO_USER": "Portal access is granted to {{UserName}}",
    "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT": "Select portal page",
    "PORTAL_PAGE_NAME": "Portal page name",
    "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA": "Usage data",
    "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE": "Price change will be applied from the start of subscription current charge cycle",
    "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT": "Immediately",
    "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE": "From next billing",
    "PRC_CNG_EFF_TYP_DESC_NEXTRENEW": "From next renewal",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE": "Close",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE": "Close",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION": "Preview Email Notification",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION": "Preview Email Notification",
    "PRICE_CHANGE_CURRENCY": "Currency",
    "PRICE_CHANGE_NEW_PRICE": "New Price",
    "PRICE_CHANGE_OLD_PRICE": "Old Price",
    "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING": "Instructions to Microsoft pricing API",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1": "Thank you for providing your consent. The application can be configured to access the Microsoft pricing .",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2": "Please follow the instructions on Settings -> Partner Center in <b>{{applicationName}}</b>.",
    "PRICING_API_VIEW_INSTRUCTIONS_1": " to copy the link that captures the consent from Partner to grant this application access to Partner Center following the Microsoft pricing API.",
    "PRICING_API_VIEW_INSTRUCTIONS_2": "Open the link in an incognito session and complete the consent experience using an account that is a Global Administrator in your (Partner) tenant and has the Admin Agent role assigned to it in the Partner Center.",
    "PRICING_API_VIEW_INSTRUCTIONS_3": "Click the Test button to check if the application works with the Microsoft pricing API.",
    "PRICING_API_VIEW_INSTRUCTIONS_5": "Click <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copy link to clipboard</a>",
    "PRICING_SLABS_TABLE_HEADER_ACTIONS": "Actions",
    "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE": "Billing type",
    "PRICING_SLABS_TABLE_HEADER_COST_PRICE": "Cost price ",
    "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME": " Display name  ",
    "PRICING_SLABS_TABLE_HEADER_MAXVALUE": "Max value",
    "PRICING_SLABS_TABLE_HEADER_MINVALUE": "Min value",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE": "Selling price",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL": "Selling price",
    "PRICING_SLABS_TABLE_HEADER_TYPE": "Billing type",
    "PRIVACY_POLICY_BUTTON_TEXT": "PRIVACY POLICY",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED": "Process of Bulk Onboarding customers completed",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED": "Process of Bulk Onboarding customers started",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED": "Process for uploading Invoices to Quickbooks completed",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED": "Process for uploading Invoices to Quickbooks initiated",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED": "Process for uploading Invoices to Xero completed",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED": "Process for uploading Invoices to Xero initiated",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE": "Process of uploading Invoices to Quickbooks failed",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS": "Process of uploading Invoices to Quickbooks success",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE": "Process of uploading Invoices to Xero failed",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS": "Process of uploading Invoices to Xero success",
    "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS": "Your selected items has been removed.",
    "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS": "Changing the value of this field will result in the loss of selected items",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE": "Billing cycle",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE": "Billing Type",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION": "Number of maximum days allowed for no cost cancellation.",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE": "Sale Type",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY": "Validity",
    "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE": "We are currently making some changes on this product. Please wait for these proccesses to finish before making a new request.",
    "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT": "Select a way to get the product catalogue report",
    "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY": "Email sent successfully",
    "PRODUCT_CATEGORY_TAG_TEXT_NCE": "NCE",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME": "Agent name",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE": "End date",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE": "Commission(%)",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE": "Start date",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID": "Internal product ID",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME": "Product name",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID": "Provider subscription ID",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE": "SP code",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME": "Agent name",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT": "Commission amount",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE": "Currency code",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE": "SP code",
    "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT": "Commission for products of a Customer will be calculated once invoices are finalized. For calculating commissions of a Customer, the commission details specified by user in the respective Billing Period will only be taken into consideration. If there is no commission details specified in a billing period, then the commission details that are defined in previous billing period and having validity until the billing period of Invoice will be taken into consideration.",
    "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY": "Filter by :",
    "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT": "Download catalogue",
    "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT": "Import catalogue",
    "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT": "Validate catalogue",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR": "Error",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS": "Success",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME": "Agent Name",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE": "Commission End Date",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE": "Commission(%)",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE": "Commission start date",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME": "Customer Name",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME": "Domain Name",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS": "Import Error",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS": "Import Status",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID": "Internal Product Id",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME": "Product Name",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID": "Provider Subscription ID",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE": "SP Code",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS": "Validation Error",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS": "Validation Status",
    "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS": "Instructions",
    "PRODUCT_COULD_NOT_FOUND_TEXT": "Product type could not be found.",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT": "Approve",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE": "Billing cycle",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY": "Current quantity",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK": "Customer Remark",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT": "Reject",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY": "Requested quantity",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT": "Seat limit",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY": "Source quantity",
    "PRODUCT_DETAILS_READ_LESS_LINK_TEXT": "Read Less",
    "PRODUCT_DETAILS_READ_MORE_LINK_TEXT": "Read More",
    "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY": "Filter by  :",
    "PRODUCT_EXTENSIONS_CAPTION_TEXT": "Product Attributes",
    "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT": "Download catalogue",
    "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT": "Import catalogue",
    "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT": "Validate catalogue",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR": "Error",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS": "Success",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE": "Billing cycle",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY": "Category",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE": "Display sequence",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS": "Import error",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS": "Import status",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID": "Offer ID",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME": "Offer name",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE": "Product code",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME": "Provider name",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS": "Validation error",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS": "Validation status",
    "PRODUCT_LIST_ADDON_LABEL": "Add-On",
    "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT": "We cancelled this request due to some changes are updated.",
    "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT": "Waiting for the approval.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT": "Select atleast one active product before you proceed.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE": "Select at least one user from the list.",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED": "Product not mapped as the tenant is linked to multiple customers",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED": "Product not mapped as the tenant is linked to multiple customers or the product was already ignored",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED": "Product is already mapped",
    "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE": "Percentage",
    "PRODUCT_OWNERSHIP_LABEL_QUANTITY": "Quantity",
    "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME": "Scope name",
    "PRODUCT_OWNERSHIP_SUBMIT_ERROR": "You can't update the quantity to a value above the available quantity. If you want to do this, please increase quantity from the basic details view.",
    "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP": "You can update the quantity through inline editing and click the submit button to save",
    "PRODUCT_OWNERSHIP_SUCCESS": "Updated ownership successfully ",
    "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS": "Add",
    "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS": "Back to products",
    "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES": "Save changes",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL": "Cancel",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE": "Save",
    "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE": "Enter unique tag value",
    "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY": "Product tags saved successfully",
    "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS": "Product tags",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION": "Description",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY": "Add tag key",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE": "Add tag value",
    "PRODUCT_TAGS_TABLE_HEADER_ACTIONS": "Actions",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION": "Description",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY": "Tag key",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE": "Tag value",
    "PRODUCT_TAGS_TITLE_PRODUCT_TAGS": "Product tags",
    "PRODUCT_VIEW_GRID": "Grid",
    "PRODUCT_VIEW_LIST": "List",
    "PRODUCTS_REPORT_MAX_ORDERED_DATE": "Max ordered date",
    "PRODUCTS_REPORT_MAX_QUANTITY": "Max quantity",
    "PRODUCTS_REPORT_MIN_QUANTITY": "Min quantity",
    "PROFILE_BREADCRUMB_BUTTON_DASHBOARD": "Dashboard",
    "PROFILE_BREADCRUMB_BUTTON_PROFILE": "Customer Profile",
    "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE": "Profile",
    "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS": "Bank Details",
    "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD": " Credit Card",
    "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH": "ACH",
    "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT": "Bank Account",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT": " Payment Type",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT": "Please select Payment Type",
    "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS": "Credit Card Details",
    "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS": "Payment profile updated successfully",
    "PROFILE_EDITUSERDETAILS_CAPTION_TEXT": "User Details",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY": "City",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY": "Country",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL": "Email",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME": "First Name",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME": "Last Name",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1": "Line 1",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2": "Line 2",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE": "State",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP": "Zip",
    "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT": "Email",
    "PROFILE_HEADER_CUSTOMER_CONFIGURATION": "Customer configuration",
    "PROFILE_HEADER_RESELLER_CONFIGURATION": "Reseller configuration",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL": "Cancel",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE": "Delete",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT": "Edit",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE": "Save",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL": "Cancel",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE": "Save",
    "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT": "Delete payment profile",
    "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT": "Set as default payment profile",
    "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT": "Phone number",
    "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS": "Account Manager Details",
    "PROFILE_TAB_CONFIGURATION_SETTING": "Configuration/Settings",
    "PROFILE_TAB_ORGANIZATION_SETUP": "Organization Setup",
    "PROFILE_TAB_PAYMENT_PROFILE": "Payment profile",
    "PROFILE_TAB_USER_DETAILS": "User Details",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL": "Cancel",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT": "Make default",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE": "Save",
    "PROFILE_VIEW_BILLING_FORM_LABEL_CITY": "City",
    "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY": "Country",
    "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL": "Email",
    "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME": "First Name",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME": "Last Name",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1": "Line 1",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2": "Line 2",
    "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE": "Phone number",
    "PROFILE_VIEW_BILLING_FORM_LABEL_STATE": "State",
    "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP": "Zip",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS": "Billing Address",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS": "Billing Details",
    "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE": "Add payment method",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1": "Note:",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2": "The new payment profile will be used for all your subsequent transactions",
    "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE": "Change payment method",
    "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS": "Payment Profile Details",
    "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE": "Please add a payment method.",
    "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE": "You already have a payment profile on file.",
    "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT": "Last 4 digits from your payment method",
    "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD": "Payment method",
    "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT": "User Details",
    "PROMOTION_ERRORS_ALERT_TEXT": "Promotion errors",
    "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE": "You can not purchase this item as your account is not linked to this Provider!",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED": "Failed to generate password for provider customer admin user",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED": "Password generated successfully for provider customer admin user",
    "PROVIDER_CUSTOMER_BILLING_EMAIL": "Billing email",
    "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE": "Purchases against CSP Tenants might fail due to lack of Customer Consent. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><b class=\"red \"> Click here to redirect to Tenant Profile and add MCA </b></a>",
    "PROVIDER_CUSTOMER_DOMAIN": "Provider Customer Domain",
    "PROVIDER_CUSTOMER_ID": "Provider CustomerId",
    "PROVIDER_CUSTOMER_NAME": "Provider customer name",
    "PROVIDER_DESC_MICROSOFT": "Microsoft",
    "PROVIDER_DESC_MICROSOFT_NON_CSP": "Microsoft non CSP",
    "PROVIDER_DESC_PARTNER": "Partner",
    "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD": "Provider name",
    "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER": "Cost To Partner",
    "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT": "Paid amount",
    "PROVIDER_PAYMENT_DETAILS_PAID_DATE": "Paid date",
    "PROVIDER_PAYMENT_DETAILS_PROVIDER": "Provider",
    "PROVIDER_PRODUCT_ID": "Provider Product Id: ",
    "PROVIDER_RESELLER_BILLING_EMAIL": "Billing email",
    "PROVIDER_RESELLER_COUNTRY_CODE": "Country code",
    "PROVIDER_RESELLER_ID": "Provider ResellerId",
    "PROVIDER_RESELLER_MPNID": "Provider Reseller MPN Id",
    "PROVIDER_RESELLER_NAME": "Provider Reseller Name",
    "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS": "Error Details",
    "PROVIDER_RESELLER_ONBOARDING_STATUS": "Onboarding Status",
    "PROVIDER_SEARCH_DEFAULT_OPTION": "Select",
    "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT": "Microsoft",
    "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER": "Existing Customer",
    "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER": "New Customer",
    "PROVISIONING_LABLE_TEXT": "Provisioning...",
    "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE": "Please select a file to upload",
    "PSA_BULK_UPLOAD_FAILED_STATUS": "Bulk Entity Mapping Updated into C3 failed.",
    "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS": "Bulk purchase product mapping was partially uploaded successfully into C3. Please check the downloaded invalid entity mapping sheet for rework.",
    "PSA_BULK_UPLOAD_SUCCESS_STATUS": "Bulk purchase product mapping is uploaded successfully into C3.",
    "PSA_BUTTON_TEXT_TEST_CONNECTIVITY": "Test connectivity",
    "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE": "No agreement mapped for this billing cycle",
    "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA": "We were unable to get contract start date from PSA hence unable to align",
    "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE": "No contracts mapped for this billing cycle",
    "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER": "These logs donot fall in selected period",
    "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING": "Start date, end date, time reporting requires start and stop times or setup fee value is missing. These are required values to create a contract. Please update these values in customer configurations.",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE": "Subscription history date is before the new date that is calculated after performing align with contract start date operation",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN": "While trying to align, no active subscriptions could be found to perform the align operation.",
    "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT": "Select Agreement",
    "PSA_PRODUCT_MAPPING_SELECT_CATEGORY": "Select category",
    "PSA_PRODUCT_MAPPING_SELECT_CONTRACT": "Select {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'contract' : 'agreement' }}",
    "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER": "Select Customer",
    "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER": "Select PSA Customer",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE": "Select {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'PSA service' : 'PSA product' }}",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER": "Select tenant",
    "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE": "Data refresh is in process, other options will be accessible once the latest data process is complete.",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING": "Warning",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1": "Data for previous dates will not be available after using the filtered date for selected customers.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE": "Note",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1": "Please note that for regenerated invoice upload need support from C3 support team.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2": "Setting the StartDate and EndDate here would reflect in Additions created in connectwise as addition startdate and cancellation date for all the applicable invoice line items.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3": "If no StartDate and EndDate is set then the invoice billing period dates will be considered.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4": "Customers list below, will only contain the customers that have invoice(s) with 'Finalized' status for the selected period.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2": "Start date will restrict past logs from being considered for upload.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3": "Please use the filters only if you're trying to push data for the first time for a customer. For example : <ul><li>Partner sign up i.e., first push from C3 to Autotask</li><li>New customer onboarding</li></ul>",
    "PSA_UPLOAD_INSTRUCTIONS_PART_1": "Begin the upload process by selecting customers and clicking on the <span class=\"bold\">Validate</span> button to start the validation process",
    "PSA_UPLOAD_INSTRUCTIONS_PART_10": "Please sync with Partner Center before upload",
    "PSA_UPLOAD_INSTRUCTIONS_PART_11": "Please note that all dates(including filters) will be considered in UTC.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_2": "Track validation progress by clicking on <span class=\"bold\">View Logs</span> button.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_3": "In case of any validation errors, make the necessary corrections and re-attempt the validation.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK": "Once validation is successful, you can proceed to <span class=\"bold\">Upload</span> the data to Autotask.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE": "Once validation is successful, you can proceed to <span class=\"bold\">Upload</span> the data to Connectwise.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK": "Option to repeat the upload is only given in case of an error.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE": "Option to repeat the upload is only given in case of an error.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_6": "Click this button to test the connectivity to PSA before proceeding to validate.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_7": "Only new subscription history logs of selected customers, if any, will be considered for upload. Selected customers may be excluded if there are no new logs.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_8": "By clicking on the checkbox beside the title, you can validate subscription logs for all active customers in the system.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_9": "Please select customers you wish to upload from the below list",
    "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS": "Check this box only if you wish to validate subscription logs for all active customers in the system.",
    "PUBLIC_SIGNUP_ADMIN_PORTAL": "Admin Portal",
    "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE": "apply promotions",
    "PUBLIC_SIGNUP_ASSISTANCE_REQUEST": "Public Signup Assistance Request",
    "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED": "Authorization payment gateway is not supported yet. Please contact support for assistance.",
    "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST": "Back to product list",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING": "Search for product",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT": "Search for products",
    "PUBLIC_SIGNUP_BANNER_MINOR_HEADING": "Best in class features to help you succeed.",
    "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING": "Public signup banner paragraph heading",
    "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER": "Account information",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME": "one time",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR": "per 3 year",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH": "per month",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR": "per year",
    "PUBLIC_SIGNUP_BUTTON_VIEW_CART": "View Cart",
    "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS": "Contains addons",
    "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED": "Contains linked product",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER": "Please select atleast one Microsoft offer to check out",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED": "Please apply the coupon before placing the order",
    "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT": "Terms and conditions.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT": "Please enable the NCE terms and conditions to order Microsoft NCE products.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT": "NCE terms and conditions.",
    "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE": "Website",
    "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER": "Confirm",
    "PUBLIC_SIGNUP_CONTACT_SUPPORT": "Contact Support",
    "PUBLIC_SIGNUP_CONTACTUS_URL": "Contact Us",
    "PUBLIC_SIGNUP_CONTINUE_SHOPPING": "Continue shopping",
    "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT": "I accept the latest",
    "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER": "Do not close this browser until the process is complete",
    "PUBLIC_SIGNUP_EDIT_TEXT": "Edit order",
    "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED": "Ezidebit payment gateway is not supported yet. Please contact support for assistance.",
    "PUBLIC_SIGNUP_FAILED_WARNING": "Apologies. Backend system has failed to setup the account because of an unknown error. Please contact support with the BatchID ",
    "PUBLIC_SIGNUP_FAILED_WARNING1": " to assist you further.",
    "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS": "Supported Markets",
    "PUBLIC_SIGNUP_GO_TO_CART": "Go to cart",
    "PUBLIC_SIGNUP_GOTO_HOME": "Go to Home",
    "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP": "Sign-up",
    "PUBLIC_SIGNUP_INVALID_QUANTITY": "Products in the cart have invalid Quantities",
    "PUBLIC_SIGNUP_LEARNER_PORTAL": "Learner Portal",
    "PUBLIC_SIGNUP_LOGS_WARNING": "The processing may take a while and the status will be reported on this page. If you move away from the page or have closed the browser by accident,we will keep you updated through an email.",
    "PUBLIC_SIGNUP_NEXT": "Next",
    "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE": "No description available",
    "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL": "We have created the cart successfully",
    "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART": "Offer is already available in cart",
    "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART": "Please provide at least 1 quantity",
    "PUBLIC_SIGNUP_ORGANIZATION_ID": "Organization Id",
    "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED": "Sign up is not supported as the payment gateway is not configured yet. Please contact support for assistance.",
    "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT": "Payment Method",
    "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT": "Pay now",
    "PUBLIC_SIGNUP_PLACE_ORDER": "Place order",
    "PUBLIC_SIGNUP_PORTLET_HEADER": "Signup",
    "PUBLIC_SIGNUP_PORTLET_HEADER_CART": "Cart",
    "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART": "Review Cart",
    "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP": "Shop",
    "PUBLIC_SIGNUP_PRODUCT_DETAILS": "Detail",
    "PUBLIC_SIGNUP_QTY": "QTY",
    "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO": "The quantity must be greater than 0",
    "PUBLIC_SIGNUP_QUANTITY_REQUIRED": "Quantity is required",
    "PUBLIC_SIGNUP_QUICK_CART": "Close cart",
    "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE": "By clicking OK, the previously added products will be removed as products from different market regions cannot be purchased at the same time.",
    "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON": "Back to Details",
    "PUBLIC_SIGNUP_SIDE_CART": "Cart",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING": "addons",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2": "addons",
    "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE": "Estimated price",
    "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING": "Linked product",
    "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT": "Remove",
    "PUBLIC_SIGNUP_SIGNUP_AND_BUY": "Next/Buy",
    "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX": "Already having existing MS tenant?",
    "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT": "Please wait while we set up your account. You may continue use of your device as normal. We will notify you via email once the set up is complete.",
    "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT": "Skip & Continue",
    "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT": "Pay now",
    "PUBLIC_SIGNUP_SUPPORT": "Support",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITION_1": "",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITION_2": "",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1": "Public signup terms and conditions 1",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2": "Public signup terms and conditions 2",
    "PUBLIC_SIGNUP_TERMS_OF_USE": "Terms of use",
    "PUBLIC_SIGNUP_TEXT_ACH": "ACH",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC": "Sort A - Z",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC": "Sort Z - A",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER": "Sort by",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW": "Price: High to Low",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH": "Price: Low to High",
    "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE": "Enter coupon code",
    "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR": "The selected offers are not supported for the country selected, so recheck the offers selected",
    "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT": "Renews On.",
    "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT": "Expires On.",
    "PURCHASE_PRODUCT_MAPPING": "Purchase Product Mapping",
    "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING": "Purchased Product Mapping",
    "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT": "Addons",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON": "Expires on",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON": "Ordered on",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME": "Product name",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY": "Quantity",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON": "Renews on",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS": "Status",
    "QUANTITY_MISMATCH_ISSUE_FIX_FAILED": "Attempt to reconcile the difference failed. Please contact support for assistance",
    "QUANTITY_MISMATCH_ISSUE_FIXED": "Quantity reconciliation completed successfully",
    "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT": "Expected count",
    "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE": "Is applicable",
    "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT": "Message count",
    "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME": "Queue name",
    "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION": "Quick Impersonation",
    "QUICK_IMPERSONATION_HEADER_TEXT": "Following users are available for impersonation, choose any to proceed",
    "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION": "Impersonate",
    "QUICK_IMPERSONATION_TABLE_LABELS_ACTION": "Actions",
    "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES": "Role",
    "QUICK_IMPERSONATION_TABLE_LABELS_USERS": "User(s)",
    "QUICK_SIDEBAR_LABEL_ACTIVITIES": "Activities",
    "QUICK_SIDEBAR_LABEL_ALERTS": "Alerts",
    "QUICK_SIDEBAR_LABEL_MORE": "More",
    "QUICK_SIDEBAR_LABEL_NOTIFICATION": "Notifications",
    "QUICK_SIDEBAR_LABEL_SETTINGS": "Settings",
    "QUICK_SIDEBAR_LABEL_USERS": "Users",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE": "Thank you for providing your consent for QuickBooks. The application can be configured to access QuickBooks following the new security guideline. You can post the invoices of your customers to QuickBooks.",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2": "Please follow the instructions to Upload Invoices to QuickBooks in <b>{{applicationName}}</b>.",
    "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE": "Please wait while we process your QuickBooks consent",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1": "The link below opens in a new tab. Please provide consent with your QuickBooks credentials. If you've got an error while providing consent, please contact our support team.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2": " to provide QuickBooks consent",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3": "If you have provided the consent already, please click the <b> Validate Token </b> button to verify the connectivity with QuickBooks.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4": "Click this button to verify the validity of existing QuickBooks Refresh Token.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING": "Prerequisites for Partner to post customer invoices to <b> QuickBooks Online </b>",
    "QUICKBOOKS_POST_BATCH_ID": "Quickbooks post Batch Id",
    "QUICKBOOKS_POST_LOGS_STATUS": "Quickbooks Post Logs Status",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE": "The exisiting QuickBooks Refresh Token is invalid. Please provide your consent again",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE": "The exisiting QuickBooks Refresh Token is valid. You can continue with uploading Invoices",
    "RCON_APPLY_PROMOTIONS_NCE": "Apply Promotion",
    "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT": " Back to RD deployments",
    "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD": "Deploy new RD",
    "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY": "Deploy",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS": "Details",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS": "RD Settings",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME": "Add Domain Name",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD": "Admin Password",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER": "Admin Username",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME": "Deployment Name",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX": "Gateway DNS Label Prefix",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME": "Gateway public IP Address Name",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU": "Image SKU",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER": "Select Image SKU",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION": "Location",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER": "Select Location",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES": "No Of Rd Instances",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP": "Resource Group",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION": "Subscription",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER": "Select Subscription",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE": "VM Size",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER": "Select Image SKU",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS": "RD deployments",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD": "Deploy new RD",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS": " Refresh deployment status",
    "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS": "RD Deployments",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE": "Manage",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION": "Actions",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON": "Deployed on",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME": "Deployment name",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION": "Location",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP": "Resource group",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS": "Status",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION": "Subscription",
    "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS": " Back to RD Deployments",
    "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS": "Deployment Details",
    "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS": "Details",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME": "Deployment Name",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT": "Environment",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON": "Deployed On",
    "RDS_DEPLOYMENT_STARTED_SUCCESS": "RD Deployment started successfully",
    "REASON_FOR_FAILURE": "Reason for failure",
    "REASON_FOR_UPDATE": "Reason for update",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL": "Reconciliation report reload failed",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS": "Reconciliation report reload successfully ",
    "RECON_CUSTOMER_REPORT_RELOADING": "Reloading reconciliation report",
    "RECON_PRODUCT_INPROVISION_TOOLTIP": "Product is In Provision status",
    "RECON_PRODUCT_ONHOLD_TOOLTIP": "Product is waiting for approval",
    "RECON_REPORT_COLLAPSE_ALL": "Collapse all",
    "RECON_REPORT_EXPAND_ALL": "Expand all",
    "RECON_REPORT_MISMATCH_COUNT_BADGE": "Mismatches",
    "RECON_REPORT_RELOAD": "Reloaded reconciliation report",
    "RECON_REPORT_RELOAD_INITIAT_FAIL": "Reconciliation report reload initiation failed",
    "RECON_REPORT_RELOAD_INITIAT_SUCCESS": "Reconciliation report reload has initiated",
    "RECON_REPORT_SEARCH_BUTTON_TEXT": "Search",
    "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER": "Search by Offer, Subscription Id",
    "RECON_REPORT_SHOW_FIXED_INACTIVE": "Show fixed Inactive",
    "RECON_REPORT_SHOW_MISMATCH": "Show mismatches only",
    "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID": "Azure Subscription id : ",
    "RECON_TABLE_TEXT_BILLING_CYCLE": "Billing cycle : ",
    "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION": "Provider subscription",
    "RECON_TABLE_TEXT_REGION": "Region : ",
    "RECON_TABLE_TEXT_RESERVATION_ID": "Reservation id : ",
    "RECON_TABLE_TEXT_RESERVATION_ORDER_ID": "Reservation order Id : ",
    "RECON_TABLE_TEXT_SUBSCRIPTION_ID": "Subscription : ",
    "RECON_TABLE_TEXT_TERM_DURATION": "Validity : ",
    "RECON_TABLE_TEXT_TRIAL_OFFER": "Trial Offer ",
    "RECONCILIATION_CUSTOMER_NAME": "Customer name",
    "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT": "PROVIDER",
    "RECONCILIATION_MISSMATCH": "Reconciliation mismatch",
    "RECONCILIATION_NOTES": "Notes",
    "RECONCILIATION_NOTES_MISMATCH": " Items in <span class='text-danger'>red</span>  indicate a mismatch",
    "RECONCILIATION_NOTES_PROMOTION_MISMATCH": " Items in <span class='text-mediumvioletred'>violet red</span> ​indicates a promotion related mismatch, get in touch with Support team to relay the promotional benefit to end customer",
    "RECONCILIATION_NOTES_QUANTITY_MISMATCH": " Items in <span class='text-danger'>red</span>  indicate a quantity mismatch",
    "RECONCILIATION_NOTES_RI_INVOICE": "Following the onboarding of a RI, all additional reservations featuring the same offer within the onboarded Reservation OrderId will be invoiced at the onboarded price.",
    "RECONCILIATION_NOTES_SUSPENDED": " Items <span class='strike-through'>struck through</span> indicate suspended subscriptions",
    "RECONCILIATION_PROVIDER": "Provider",
    "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT": "Payment amount",
    "RECORD_ADVANCE_PAYMENT_CONFIRMATION": "Are you sure you want to record a payment for <%= amount%> (<%= currencyCode%>) against the Customer?<br/><small> Please contact support in case you need further assistance on payments that either do not show up or were recorded in error.</small>",
    "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT": "Currency code",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT": "Select customer",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT": "Customers",
    "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED": "Customer is required for recording payment.",
    "RECORD_ADVANCE_PAYMENT_MODAL_HEADER": "Record advance payment",
    "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT": "Remarks",
    "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT": "Select Currency Code",
    "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT": "Select Customer",
    "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE": "Payment recorded successfully",
    "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE": "Payment Date",
    "RECORD_ID": "Record Id",
    "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE": "Record Id received from external service",
    "RECORD_MANUAL_PAYMENT_CONFIRMATION": "Are you sure you want to record a payment for <%= currencySymbol%> <%= amount%> against this invoice?<br/><small> Please contact support in case you need further assistance on payments that either do not show up or were recorded in error.</small>",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT": "Download catalogue",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY": "Entity name",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME": "Name",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT": "Import",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS": "Import payments",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE": "Template",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE": "Catalogue",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT": "Download template",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT": "Import payments",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR": "Error",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS": "Success",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID": "C3Id",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE": "Currency code",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS": "Import status",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT": "Payment amount",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE": "Payment date",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME": "Name",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS": "Remarks",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS": "Validation status",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS": "Import error",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS": "Validation error",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT": "Validate input",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR": "Error",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS": "Success",
    "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT": "Payment amount should be greater than 0.00.",
    "RECORD_PAYMENT_PAID_DATE": "Payment Date",
    "RECURRENCES_TOOLTIP": "Number of times the discount coupon can be applied",
    "REFRESH_TOKEN": "Refresh Token",
    "REFRESH_TOOLTIP_TEST_PAYMENT": "Refresh",
    "REGISTER_CAPTION_TEXT_THANK_YOU": "Thank you for choosing <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Please complete the information below to process your new subscription.",
    "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK": "If you have an existing Microsoft Account that is not with us, please <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">contact us</a> for assistance.",
    "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON": "Please enter a valid coupon",
    "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN": "Please enter a valid domain name. Domain names can only contain letters and numbers; no hyphens, periods, or underscores.",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL": "Email",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO": "Please provide a valid email id. We will send an email with username and password after the registration is complete",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME": "First Name",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME": "Last Name",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER": "Phone number",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY": "City",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME": "Company name",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1": "Line 1 ",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2": "Line 2",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME": "Primary domain name",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT": "  To get started, you must create an account using your company name followed by .onmicrosoft.com. If your organization has its own custom domain that you want to use without .onmicrosoft.com, you can change this later in Office 365 Admin Portal.",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL": "What's this",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE": "State",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP": "Zip",
    "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE": "Agreement Date",
    "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT": "Microsoft customer agreement",
    "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT": "I accept the latest ",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN": "Configure Your Plan",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO": "For each offer in the plan you wish to add please select a minimum quantity of 1",
    "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT": "Primary contact",
    "REGISTER_HEADER_TEXT_COMPANY_PROFILE": "Company Profile",
    "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT": "Microsoft Customer Agreement",
    "REGISTER_PAYMENT_PROFILE_BUTTON_ACH": "ACH",
    "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT": "Bank Account",
    "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD": "Credit Card",
    "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON": "Remove Coupon",
    "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON": "Please enter a valid coupon",
    "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE": "Coupon code applied : <b>{{CouponCode}}</b>",
    "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS": "Credit Card Details",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE": "Payment Type",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT": "Please select Payment Type",
    "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY": "Plan Summary",
    "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE": "Recurring Fee (every 1 month)",
    "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON": "then <span class='bold'>${{totalCount}} </span>at first renewal on {{nextBillingDate}}",
    "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL": "Total",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME": "Note:",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE": "The charges for the purchase are prorated for the rest of the days in the current billing cycle.",
    "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE": "Licenses",
    "REGISTER_REGISTERUSER_BUSINESs_OR_NOT": "You must specify if the ACH account is a business account or not!",
    "REGISTER_REGISTERUSER_CREATED_USER": "User created successfully",
    "REGISTER_REGISTERUSER_UNABLE_TO_CHECK": "Unable to check the domain availability",
    "REGISTER_REGISTERUSER_VALID_DOMAIN": "Please enter a valid domain name. Domain names can only contain letters and numbers; no hyphens, periods, or underscores.",
    "REGISTER_REGISTRATION_STATUS_CAPTION": "Registration Status",
    "REGISTER_REGISTRATION_STATUS_HEADER": "Your registration has been processed successfully. Please log on to the portal using the following credentials:",
    "REGISTER_REGISTRATION_STATUS_LINE_1": "Username",
    "REGISTER_REGISTRATION_STATUS_LINE_2": "Password",
    "REGISTER_REGISTRATION_STATUS_LINE_3": "An email was sent to you with the above detail in case you missed copying them from here",
    "REGUISTER_BUTTON_PROCESS_MY_ORDER": "Process my Order",
    "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR": "Please provide the reason for the rejection",
    "REJECT_ALL_PRODUCT_POPUP_BUTTON": "Reject all",
    "REJECT_ALL_PRODUCT_POPUP_HEADER": "Reject all products",
    "REJECT_ALL_PRODUCTS_COMMENT_HEADER": "Reject All Products",
    "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION": "Please explain the reason for denying the request",
    "REJECT_PRODUCT_COMMENT_HEADER": "Reject product",
    "REJECT_PRODUCT_COMMENT_INSTRUCTION": "Please state the reasons why this product should be rejected",
    "REJECT_PRODUCT_POPUP_BUTTON": "Reject",
    "REJECTED_BY": "Rejected by ",
    "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR": "Please enter a reason to reject this product",
    "RELEASE_PRODUCT_OWNERSHIP": "Release Product ownership",
    "RELEASE_PRODUCT_OWNERSHIP_FAILED": "Release of product ownership failed",
    "RELEASE_SEED_ERROR": "You can only reduce the Quantity while releasing, please try again ",
    "RELEASE_SETAS_ERROR_MESSAGE_CHILD": "Tried to update more than available quantity for \"{{childName}}\"",
    "RELEASE_SETAS_ERROR_MESSAGE_PARENT": "Tried to update more than available quantity to parent \"{{parentName}}\" for \"{{childName}}\"",
    "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE": "<span class='red'>An error occurred while processing data for  <b> {{firstName}} </b>. The error is : {{errorMessage}} </span><br/>",
    "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE": "<span class='success'>Processed records successfully for  <b> {{firstName}} </b></span><br/>",
    "RELOAD_RECONCILIATION_STATUS": "Reload Reconciliation Status",
    "RELOAD_TOOLTIP_REVENUE": "Reload",
    "REMOVE_ALL_SELECTED_RGS": "Do you want to remove all selected Resource Groups?",
    "REMOVE_DISTRIBUTOR_PRODUCT_ICON": "Remove",
    "REMOVE_PARTNER_PRODUCT_ICON": "Remove",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER": "Remove product",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION": "Please provide the reason:",
    "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT": "Are you sure you want revoke access for this user?",
    "RENEWAL_ALL_DAYS": "All",
    "RENEWAL_DAYS_TEXT": "Days",
    "REPORT_ACTIONS_DOWNLOAD_TEXT": "Download",
    "REPORT_ACTIONS_SEND_EMAIL_TEXT": "Send Email",
    "REPORT_ACTIONS_TEXT": "Actions",
    "REPORT_ALL_CUSTOMERS_PLACEHOLDER": "All customers",
    "REPORT_FILE_INVALID_EMAILS_ERROR": "Please enter valid email",
    "REPORT_FILE_INVALID_EMAILS_REQUIRED": "This field is required",
    "REPORT_FILE_TYPES_CSV_FORMAT": "CSV",
    "REPORT_FILE_TYPES_JSON_FORMAT": "JSON",
    "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT": "Send email",
    "REPORT_FILE_TYPES_SUB_HEADER": "Select a file format",
    "REPORT_FILE_TYPES_XML_FORMAT": "XML",
    "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD": "Select Period",
    "REPORT_INTERVAL_TEXT_CUSTOME": "Custom",
    "REPORT_INTERVAL_TEXT_LAST_12_MONTHS": "Last 12 months",
    "REPORT_INTERVAL_TEXT_LAST_3_MONTHS": "Last 3 months",
    "REPORT_INTERVAL_TEXT_LAST_6_MONTHS": "Last 6 months",
    "REPORT_INTERVAL_TEXT_TILL_DATE": "Till Date",
    "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION": "Select a way to get the Invoices and dues report",
    "REPORT_POPUP_ACTIONS_TEXT": "Actions",
    "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT": "Download",
    "REPORT_POPUP_EMAIL_TEXT": "Email",
    "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT": "Send Email",
    "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER": "abc@spektra.com",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION": "Select a way to get the subscription history report",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION": "Please input the email addresses of recipients, separated by commas (,) or semicolons (;) and click  \"Submit\" to receive a file containing the subscription history report list.",
    "REPORT_SELECT_CUSTOMER_All": "All Customers",
    "REPORT_SELECT_CUSTOMER_PLACEHOLDER": "Select customer",
    "REPORT_SELECT_RESELLER_ALL": "All Resellers",
    "REPORT_SEND_EMAIL_TEXT": "Email",
    "REPORT_TEXT_FROM": "From",
    "REPORT_TEXT_TO": "To",
    "REPORT_USAGE_BUTTON_TEXT_FILTER_BY": "Filter by  :",
    "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION": "Cost to partner is required if the billing type is percentage and it is optional if billing types are unit and metered billing.",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT": "Download catalogue",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE": "Catalogue",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT": "Import",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE": "Template",
    "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT": "Download template",
    "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT": "Import catalogue",
    "REPORT_USAGE_INSTRUCTIONS_PART_1": "Download catalogue to get customer and product IDs of usage based partner offers.",
    "REPORT_USAGE_INSTRUCTIONS_PART_2": "Download template and fill out the usage detail for products in the catalogue.",
    "REPORT_USAGE_INSTRUCTIONS_PART_3": "Ensure that the data abides by the following rules: <br />&nbsp;&nbsp <b>Units</b> : Number <br />&nbsp;&nbsp <b>UnitOfMeasure</b> : Text <br />&nbsp;&nbsp <b>CostToPartner</b> : Decimal <br />&nbsp;&nbsp <b>CurrencyCode</b> : Text (ISO standard currency code.) <br />&nbsp;&nbsp <b>UsageDate</b> : Date (YYYY-MM-DD)",
    "REPORT_USAGE_INSTRUCTIONS_PART_4": "Click <strong class=\"text-uppercase\">Select</strong> and browse to the file.",
    "REPORT_USAGE_INSTRUCTIONS_PART_5": "Click <strong class=\"text-uppercase\">Validate Input</strong> for the system to validate the input.",
    "REPORT_USAGE_INSTRUCTIONS_PART_6": "Review the errors in the table after validation is complete.",
    "REPORT_USAGE_INSTRUCTIONS_PART_7": "Fix the errors in the file and upload again.",
    "REPORT_USAGE_INSTRUCTIONS_PART_8": "If there are no validation errors in the input, <strong class=\"text-uppercase\">Import Catalogue</strong> button gets enabled. Click the button to submit your changes.",
    "REPORT_USAGE_NOTE": "Note :",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR": "Error",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS": "Success",
    "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER": "Cost on partner",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE": "Currency",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID": "Customer ID",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS": "Import error",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS": "Import status",
    "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID": "Product ID",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE": "Unit of measure",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS": "Units",
    "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE": "Usage date",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS": "Validation error",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS": "Validation status",
    "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT": "Validate input",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1": "To request a reseller relationship with a customer, copy and paste the following text, including the URL, into an email. Edit the text if necessary, and send the email to your customer.",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2": "Include delegated administration privileges for Azure Active Directory and Office 365.",
    "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT": "Email Text",
    "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY": "Request for Bulk Onboard initiated by",
    "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE": "Request for loading promotions is queued",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY": "Request to upload Invoices to Quickbooks initiated by",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY": "Request to upload invoices to Xero is placed by",
    "REQUEST_STATUS_CHANGE_OF_NI_OFFER": "Requested status update of NI offer",
    "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED": "Request to upload Invoices to QuickBooks accepted",
    "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED": "Request to upload Invoices to Xero accepted",
    "REQUESTED_PRODUCT_STATUS": "Requested Status",
    "REQUIRED_FIELD_TEXT": "This field is required",
    "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE": "Error occurred while exporting the reseller invoice line items",
    "Reseller": "Reseller",
    "RESELLER": "(Reseller)",
    "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT": "Adjustment",
    "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE": "Is Taxable",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD": "Add",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL": "Cancel",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL": "Select All",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST": "Add-ons list",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS": "Add more products",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN": "Delete plan",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS": "Review plan offers",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN": "Save plan",
    "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED": "Search Applied",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH": "Quick Search",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES": "Billing cycles",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY": "Category",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS": "Providers",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES": "Service Types",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION": "Term Duration",
    "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS": "Success",
    "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY": "Price updated successfully",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY": "Quantity",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE": "Usage",
    "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND": "No products found",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER": "Resellers",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY": "Reseller revenue and cost summary",
    "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS": "Back to list",
    "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS": "Back to resellers",
    "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER": "Link new provider",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER": "Add reseller",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW": "New",
    "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS": "Existing (Multiple)",
    "RESELLER_CAPTION_TEXT_ADD_RESELLER": "Add Reseller",
    "RESELLER_CAPTION_TEXT_CONTACT_DETAILS": "Contact Details",
    "RESELLER_CAPTION_TEXT_PAYMENT_TYPE": "Payment Type",
    "RESELLER_CAPTION_TEXT_RESELLER": "Resellers",
    "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE": "Reseller Billing Profile",
    "RESELLER_CAPTION_TEXT_RESELLER_DETAILS": "Reseller Details",
    "RESELLER_CAPTION_TEXT_RESELLER_PROFILE": "Reseller Profile",
    "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS": "Checking this flag prevents customers from reducing the subscription quantity",
    "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION": "Allow reseller to initiate the transaction for their invoice(s)",
    "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER": "Select whether to apply promotion to reseller or not",
    "RESELLER_CONFIG_DESC_AZURE_APPLICABLE": "Enable customers to manage Azure subscriptions",
    "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE": "Enable customers to define Groups over Azure Subscriptions",
    "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller azure usage. Eg: Input 1.15 if markup should be 15%",
    "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID": "For internal reference. This ID can be used to relate the customer to a record in your internal accounting system",
    "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY": "Day of the month when invoices get approved automatically",
    "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY": "Day of the month when payments are processed automatically",
    "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY": "Day of the month when payments are processed automatically",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY": "Due date for invoices",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS": "No of days due for invoice payment",
    "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY": "Day of the month when invoices get finalized automatically",
    "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT": "Enabling this option will automatically designate the newly added address as the default address for the reseller.",
    "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT": "Enabling this option will automatically designate the newly added email as the default email for the reseller.",
    "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT": "Enabling this option will automatically designate the newly added phone number as the default phone number for the reseller.",
    "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller one time invoice line items.",
    "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller perpetual softwarr subscriptions",
    "RESELLER_CONFIG_DESC_PO_NUMBER": "Define purchase order number for reseller",
    "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY": "Check this flag if you want the system to process payments (CC/ACH as applicable)",
    "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller azure reservation.",
    "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY": "Reseller will get the email with invoice details once invoice is approved and if email notifications are enabled",
    "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES": "Reseller will recieve email notification regarding invoices having zero amounts",
    "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller software subscriptions.",
    "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS": "We are unable to process this request as transactions are disabled.",
    "RESELLER_CONFIG_DESC_TAX_CODE": "Define Tax code for resellers",
    "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the reseller third party subscriptions.",
    "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS": "Allow customer to reduce seats",
    "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION": "Allow Reseller to initiate transaction",
    "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER": "Apply Promotion To Reseller",
    "RESELLER_CONFIG_DISP_AZURE_APPLICABLE": "Enable Azure?",
    "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE": "Enable Azure for Enterprise?",
    "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE": "Markup for azure usage",
    "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID": "Billing reference ID",
    "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY": "Day when resellers can see invoices",
    "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY": "Day for auto payment",
    "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY": "Day for auto payment",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY": "Due date for invoices",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS": "Due days for invoices",
    "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY": "Day when invoices are finalized",
    "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT": "Automatically assign a new address as the default",
    "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT": "Automatically assign a new email as the default",
    "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT": "Automatically assign a new phone number as the default",
    "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE": "Markup for microsoft one time invoice line items",
    "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE": "Markup for perpetual software",
    "RESELLER_CONFIG_DISP_PO_NUMBER": "PO #",
    "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY": "Auto pay invoices?",
    "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE": "Markup for azure reservation",
    "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY": "Send invoice emails automatically",
    "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES": "Send zero amount invoices",
    "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Markup for software subscription",
    "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS": "Stop all transactions",
    "RESELLER_CONFIG_DISP_TAX_CODE": "Tax code",
    "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Markup for third party",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC": "Allows the user to view unpaid dues until the current invoice",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP": "Show unpaid dues in invoice",
    "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS": "Reseller Configuration reverted successfully",
    "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS": "Reseller configuration saved successfully",
    "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED": "You don't have access to edit",
    "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION": "Updated reseller configuration successfully",
    "RESELLER_CONFIGURATION_OF": "Reseller Configuration of",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT": "Quick look at your business revenue",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE": "Profitability",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT": "Summary of your accounts receivable",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE": "Account Receivable",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT": "Payments yet to be collected",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE": "Past Dues",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT": "Your active customers",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE": "Customers",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT": "Alert on a pending invoice",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE": "Invoice",
    "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN": "A reseller already exists in system with given mpn id. Please check the given mpn id.",
    "RESELLER_HEADER_TEXT_BANK_DETAILS": "Bank Details",
    "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS": "Credit Card Details",
    "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE": "Please Select Payment Type",
    "RESELLER_IMPERSONATE": "Impersonate",
    "RESELLER_IMPERSONATE_USER_ROLE": "impersonate",
    "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE": "impersonate with own role",
    "RESELLER_INVOICE": "Invoice",
    "RESELLER_INVOICE_TEXT_LINE": "Invoice will be mailed to your billing address. Thanks!",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY": "Invoice status updated successfully",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY": "Payment recorded successfully",
    "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY": "Back To Revenue and Cost Summary",
    "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES": "Invoices",
    "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES": "Invoices",
    "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY": "Back to revenue and cost summary",
    "RESELLER_INVOICES_HEADER_TEXT_INVOICES": "Invoices",
    "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS": "Invoice line items",
    "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER": "Account number",
    "RESELLER_LABEL_TEXT_ACCOUNT_TYPE": "Account type",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_1": "Address Line 1",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_2": "Address Line 2",
    "RESELLER_LABEL_TEXT_BANK_NAME": "Bank name",
    "RESELLER_LABEL_TEXT_CARD_NUMBER": "Card number",
    "RESELLER_LABEL_TEXT_CITY": "City",
    "RESELLER_LABEL_TEXT_COMPANY_NAME": "Company name",
    "RESELLER_LABEL_TEXT_COUNTRY": "Country",
    "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE": "Credit card type",
    "RESELLER_LABEL_TEXT_CVV": "CVV",
    "RESELLER_LABEL_TEXT_EMAIL": "Email",
    "RESELLER_LABEL_TEXT_FIRST_NAME": "First name",
    "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT": "Is Business Account",
    "RESELLER_LABEL_TEXT_LAST_NAME": "Last name",
    "RESELLER_LABEL_TEXT_MONTH": "Month",
    "RESELLER_LABEL_TEXT_MPN_ID": "MPN Id",
    "RESELLER_LABEL_TEXT_NAME": "Name",
    "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT": "Name on account",
    "RESELLER_LABEL_TEXT_NAME_ON_CARD": "Name on card",
    "RESELLER_LABEL_TEXT_PHONE_NUMBER": "Phone number",
    "RESELLER_LABEL_TEXT_PLAN_ID": "Plan",
    "RESELLER_LABEL_TEXT_ROUTING_NUMBER": "Routing number",
    "RESELLER_LABEL_TEXT_STATE": "State",
    "RESELLER_LABEL_TEXT_YEAR": "Year",
    "RESELLER_LABEL_TEXT_ZIP": "Zip",
    "RESELLER_LIST_PAYMENT_METHOD": "Payment Method",
    "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Total % to be applied to cost price to attain the desired sale price for software subscriptions.",
    "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Total % to be applied to cost price to attain the desired sale price for third party subscriptions.",
    "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Markup for Software Subscription",
    "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT": "Markup for Third-party subscription",
    "RESELLER_NAME_UPDATE_FAILURE": "Reseller name update failed",
    "RESELLER_NAME_UPDATE_SUCCESS": "Reseller name update success",
    "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER": "Reseller is not assigned to any Account Manager",
    "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN": "No reseller found in CSP. Please check the given mpn id.",
    "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR": "Errors detected in the data uploaded. Download the errors, fix and try uploading again.",
    "RESELLER_ONBOARD_UPLOAD_ERROR": "An error occurred while parsing the uploaded file",
    "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT": "Please mention if the ACH details are related to a business account",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL": "Billing email",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY": "Country",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID": "ID",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID": "MPN ID",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME": "Name",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS": "Success",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED": "Payment details saved ",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED": "Payment profile deleted",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT": "Payment profile Set to default",
    "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION": "Are you sure you want to delete this Payment option?",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS": "Define macro and save with all offers",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS": "Update plan with latest offers",
    "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS": "Save plan with all offers",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES": "Selected Billing cycles",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES": "Selected Categories",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES": "Selected provider categories",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS": "Selected providers",
    "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS": "Supported Markets",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES": "Billing cycles",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES": "Categories",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO": "Macro",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE": "Macro value",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES": "Provider categories",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS": "Providers",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE": "Service Types",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN": "Request to Clone Reseller Plan is accepted and being processed",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS": "Request to create a new reseller plan with all offers is accepted and being processed",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING": "Price setting",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION": "Select",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES": "Apply macro to define the new prices",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN": "Copy prices from Source Reseller Plan",
    "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT": "Successfully submitted your changes",
    "RESELLER_PLAN_DISABLE_BUTTON": "Disable the plan",
    "RESELLER_PLAN_ENABLE_BUTTON": "Enable the plan",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE": "Offer is successfully discontinued",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT": "Discontinue the offer",
    "RESELLER_PLAN_SELECTED_OFFERS": "Selected Offers ",
    "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS": "Plans",
    "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS": "Cancel",
    "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN": "Clone Plan",
    "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS": "Creating Plan",
    "RESELLER_PLANS_CAPTION_1": "Add new Plan",
    "RESELLER_PLANS_CAPTION_2": "Details for Plan -",
    "RESELLER_PLANS_CAPTION_TEXT_PLANS": "Plans",
    "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW": "View",
    "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT": "Pricing and offers",
    "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST": "Download pricing and offers list",
    "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT": "Pricing And Offers",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE": "ERP Price",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE": "License Agreement Type",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE": "List price",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME": "Offer name",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM": "Valid From",
    "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE": "Error occurred while exporting reseller pricing and offers list",
    "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE": "Organization profile",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL": "Cancel",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE": "Delete",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT": "Edit",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE": "Save",
    "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE": "Profile",
    "RESELLER_PROFILE_CUSTOMER_PROFILE": "Customer Profile",
    "RESELLER_PROFILE_PARTNER_ADD_ADDRESS": "Add Address",
    "RESELLER_PROFILE_PARTNER_ADD_EMAIL": "Add Email",
    "RESELLER_PROFILE_PARTNER_ADD_PHONE": "Add Phone",
    "RESELLER_PROFILE_PARTNER_ADDRESS": "Addresses",
    "RESELLER_PROFILE_PARTNER_BASIC_DETAILS": "Basic Details",
    "RESELLER_PROFILE_PARTNER_CANCEL": "Cancel",
    "RESELLER_PROFILE_PARTNER_COMPANY_NAME": "Company Name",
    "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES": "Email Addresses",
    "RESELLER_PROFILE_PARTNER_FIRST_NAME": "First Name",
    "RESELLER_PROFILE_PARTNER_LAST_NAME": "Last Name",
    "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY": "Basic details updated successfully",
    "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!": "No records found!",
    "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS": "Phone numbers",
    "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD": "Are you sure you want to delete this record?",
    "RESELLER_PROFILE_PARTNER_SUBMIT": "Submit",
    "RESELLER_PROFILE_PAYMENT_ADD": "Add",
    "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT": "Bank Account",
    "RESELLER_PROFILE_PAYMENT_CREDIT_CARD": "Credit Card",
    "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON": "No profiles found. Add a new payment profile by clicking on the ADD button.",
    "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES": "Payment Profiles",
    "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE": "Account Creation Date:",
    "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS": "Billing Address",
    "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE": "C3 Onboarding Date",
    "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE": "Reseller Profile",
    "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS": "Shipping Address",
    "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS": "User Accounts",
    "RESELLER_PROFIT_BILLED_AMOUNT": "Billed amount to reseller",
    "RESELLER_PROFIT_COST_ON_PARTNER": "Cost to distributor",
    "RESELLER_PROFIT_COST_ON_RESELLER": "Cost to reseller",
    "RESELLER_PROFIT_CUSTOMER_NAME": "Customer amount",
    "RESELLER_PROFIT_DISTRIBUTOR_NAME": "Reseller name",
    "RESELLER_PROFIT_PRODUCT_NAME": "Product name",
    "RESELLER_PROFIT_PROFIT_AMOUNT": "Distributor profit",
    "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT": "Billed amount by reseller",
    "RESELLER_PROFIT_RESELLER_NAME": "Reseller name",
    "RESELLER_PROFIT_SUMMARY_REPORT": "Download report",
    "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA": "No data available for download",
    "RESELLER_PROFIT_SUMMARY_VIEW_ALL": "All",
    "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER": "View by reseller",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT": "Reseller profit",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE": "Distributor profit %",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE": "Reseller profit %",
    "RESELLER_RADIO_BUTTON_TEXT_NO": "NO",
    "RESELLER_RADIO_BUTTON_TEXT_YES": "YES",
    "RESELLER_REGISTRATION_SUCCESS": "Reseller registration completed successfully.",
    "RESELLER_RELATIONSHIP_LBL": "Please share the following content with the client to establish a reseller relationship",
    "RESELLER_REVENUE_AND_COST_SELECT_RESELLER": "Select reseller",
    "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY": "View by",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE": "Please enter the valid tax percentage",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME": "Please fill the fields tax name",
    "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION": "Add New Event Email Notification",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY": "partner tag deleted successfully",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY": "partner tag updated successfully",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT": "There are no providers configured, Please Contact Support",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER": "There are no keys available for this provider",
    "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION": "Reseller : Quick impersonation",
    "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY": "No market has been found for the choosen currency code",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION": "Reseller configuration",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING": "Reseller setting",
    "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS": "Assign offers",
    "RESELLER_TABLE_BUTTON_TEXT_EDIT": "Edit",
    "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE": "Impersonate",
    "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER": "Manage provider links",
    "RESELLER_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "RESELLER_TABLE_HEADER_TEXT_CREATED_ON": "Created on",
    "RESELLER_TABLE_HEADER_TEXT_NAME": "Name",
    "RESELLER_TABLE_SIGNUP_DATE": "Signup date",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF": "Invoice",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT": "Reconciliation",
    "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT": "Test charge reseller",
    "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY": "Successfully added reseller.",
    "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS": "Reseller payments",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS": "Account manager details",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS": "Manage plans",
    "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION": "Emails",
    "RESEND_INVOICE": "Resend invoice",
    "RESEND_INVOICE_HEADER_TEXT": "Please enter Email Ids to resend the Invoice",
    "RESEND_INVOICE_NOTE": "<b>Note :</b> If you wish to resend invoice to multiple Email Id's please give the id's by separating with comma. Ex: abc@mail.com,new@mail.com",
    "RESEND_INVOICE_USERS_LIST": "Resend Invoice users list",
    "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE": "Retail price (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER": "Cost to partner (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE": "Sale price (%)",
    "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE": "Enter valid RI percentage",
    "RESET_VALUE": "Reset value",
    "Resolved": "Resolved",
    "RESOURCE_GROUP_NAME": "Resource Group Name",
    "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS": "Payment has been initiated successfully. It will take some time to process. Please check the status after sometime.",
    "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT": "Billed amount",
    "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES": "Download bulk invoices",
    "REVENUE_AND_COST_SUMMARY_CATEGORY": "Category",
    "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER": "Cost on partner",
    "REVENUE_AND_COST_SUMMARY_CUSTOMER": "Customer",
    "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE": "Distributor Invoice",
    "REVENUE_AND_COST_SUMMARY_PROVIDER": "Provider",
    "REVENUE_AND_COST_SUMMARY_RESELLER": "Reseller",
    "REVENUE_AND_COST_SUMMARY_SALETYPE": "SaleType",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE": "Dues",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS": "Invoices and dues",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS": "Payments",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT": "Failures",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT": "Overdues",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY": "Subscription history",
    "REVENUE_AND_COST_SUMMARY_VIEWBY": "View by",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED": "Invoice Generation Completed",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT": "Error occurred while generating the customer invoices",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT": "Customer invoices generated successfully",
    "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS": "Provider payment details",
    "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS": "Loading Provider Payment Details...",
    "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE": "Regenerate invoice",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES": "View invoices",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY": "View subscription history",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT": "Amount",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD": "Billing Period",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER": "Customer",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER": "Cost On Partner",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT": "Profit Amount",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER": "Cost to customer",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER": "Cost to partner",
    "REVENUE_BY_CUSTOMER_TEXT_PROFIT": "Profit",
    "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES": "Get all invoices via email",
    "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS": "Provider Payment Details",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT": "Category",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT": "Customer",
    "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT": "Duration",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD": "Last Billing Period",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS": "6 Billing Periods",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS": "3 Billing Periods",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS": "12 Billing Periods",
    "REVOKE_POLICY_ASSIGNMENT_NOTIFIER": " Are you sure do you want to revoke the assignment",
    "RFRESH_DATA_FROM_PSA": "Refresh Data From PSA",
    "RIBBON_TITLE_DEPARTMENT": "Dept",
    "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY": "Please enter the quantity for at least one top level plan offer.",
    "ROLE_ASSIGNMENT_EXCEPTION": "Fill the User details",
    "ROLE_ASSIGNMENT_SCOPE_TITLE": "Role assignment Scopes",
    "ROLE_NAME_C3_SUPPORT": "C3 Support",
    "ROLE_NAME_CUSTOMER_ADMIN": "Customer Admin",
    "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION": "User can view and perform all the actions available in customer portal",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES": "Customer Admin (For Sales)",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION": "Customer Admin (For Sales)",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE": "Customer Admin Lite",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION": "User has all permissions of Customer Admin, but won’t be able to purchase new subscriptions",
    "ROLE_NAME_CUSTOMER_MAINTENANCE": "Customer Maintenance",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION": "Customer Maintenance",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES": "A role to maintain app by customer",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION": "A role to maintain app by customer",
    "ROLE_NAME_CUSTOMER_READER": "Customer Reader",
    "ROLE_NAME_CUSTOMER_READER_DESCRIPTION": "User can only read the data available in customer portal",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN": "Customer Super Admin",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION": "Customer Super Admin",
    "ROLE_NAME_DEPARTMENT_ADMIN": "Department Admin",
    "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION": "Who can manage the Department and they can only manage products under the Specific Departments.",
    "ROLE_NAME_DEPARTMENT_READER": "Department Reader",
    "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION": "Who can only read the data available under the  Specific Departments.",
    "ROLE_NAME_DIRECT_ADMIN": "Direct Admin",
    "ROLE_NAME_FINANCE_ADMIN": "Finance Admin",
    "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION": "Can manage pages related to finance.",
    "ROLE_NAME_GROUP_ADMIN": "Group Admin",
    "ROLE_NAME_HELP_DESK": "Help Desk",
    "ROLE_NAME_HELP_DESK_DESCRIPTION": "Help Desk",
    "ROLE_NAME_INDIRECT_ADMIN": "Indirect Admin",
    "ROLE_NAME_PARTNER_MAINTENANCE": "Partner Maintenance",
    "ROLE_NAME_PARTNER_MAINTENANCE_NOTES": "A role to maintain app by partner",
    "ROLE_NAME_SITE_ADMIN": "Site Admin",
    "ROLE_NAME_SITE_ADMIN_DESCRIPTION": "Who can manage the Site and they can only manage products under the Specific Sites.",
    "ROLE_NAME_SITE_READER": "Site Reader",
    "ROLE_NAME_SITE_READER_DESCRIPTION": "Who can only read the data available under the Site.",
    "ROLE_NAME_SYSTEM_ADMIN": "System Admin",
    "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION": "Can access/change all the pages in the portal.",
    "ROLE_NAME_SYSTEM_READER": "System Reader",
    "ROLE_NAME_SYSTEM_READER_DESCRIPTION": "Can visit all the pages but he cannot make any changes.",
    "ROLE_NAME_USER": "User",
    "ROLE_NAME_USER_ADMIN": "User Admin",
    "ROLE_NOTE_C3_SUPPORT": "C3 Support can resolve quantity mismatch situations & it is a super set of permissions",
    "ROLE_NOTE_CUSTOMER_ADMIN": "Customer admin can view all the data and perform all the actions in the context of the tenant",
    "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES": "Customer admin (for sales) can see and perform actions on data but not on partner subscriptions",
    "ROLE_NOTE_CUSTOMER_READER": "Customer readers having read-only access to all the data related to customer.",
    "ROLE_NOTE_DIRECT_ADMIN": "Direct Admin, would be able to do anything a system admin can do, but only for the direct customers, so no access to the Sell Indirect parts",
    "ROLE_NOTE_FINANCE_ADMIN": "Finance admins can access customer revenue and cost summary, define tax %, view reconciliation report",
    "ROLE_NOTE_GROUP_ADMIN": "GroupAdmins can manage all the Azure subscriptions related things under his group.",
    "ROLE_NOTE_HELP_DESK": "Help Desk users can view all the customer details, onboard new customers and provide support. Help Desk users can impersonate and access customer data",
    "ROLE_NOTE_INDIRECT_ADMIN": "Indirect Admin, would be able to do anything a system admin can do, but only for the resellers (and their customers), so no access to the Sell Direct parts.",
    "ROLE_NOTE_SYSTEM_ADMIN": "System admin can view all the data and perform all the actions in the context of partner as well as customers",
    "ROLE_NOTE_SYSTEM_READER": "System readers having read-only access to all the data related to partner. Readers cannot impersonate",
    "ROLE_NOTE_USER_ADMIN": "User admin can see and perform some actions on data but not on any subscriptions",
    "SALE": "Sale",
    "SALE_PRICE_EDIT_TOOLTIP_TEXT": "Edit sale price",
    "SALE_PRICE_GREATER_THAN_ERP_PRICE": "Sale price greater than ERP Price",
    "SALE_PRICE_LESS_THAN_LIST_PRICE": "Sale price less than List Price",
    "SALE_TYPE_DESC_PRODUCT": "Product",
    "SALE_TYPE_DESC_SERVICE": "Service",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS": "Trying to save the authentication code recieved from Quickbooks",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO": "Trying to save the authentication code recieved from Xero",
    "SAVE_CART_EXCEPTION": "Error Occurred while adding product to cart",
    "SAVE_COUPON_FAILURE": "Failed to save coupon",
    "SAVE_COUPON_SUCCESS": "Saved coupon successfully",
    "SAVE_CURRENCY_CONVERSION_EXCEPTION": "Error occurred while saving the currency conversion",
    "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION": "Error occurred while saving or updating email notification recipients list",
    "SAVE_PARTNER_TAX_DETAILS_EXCEPTION": "Error occurred while saving the tax details",
    "SAVE_PLAN_PRODUCT_SLAB": "Saved contract offer slabs",
    "SAVE_PLAN_PRODUCT_SLAB_FAILURE": "Failed to save contract offer slabs",
    "SAVE_PLAN_SUCCESS_MESSAGE_TEXT": "Plan created successfully",
    "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE": "Provider payment details saved successfully.",
    "SAVE_RESELLER_PAYMENT_PROFILE": "Save Reseller Payment Profile",
    "SAVE_RESELLER_PLAN": "Save Reseller Plan",
    "SAVE_RESELLER_PLAN_FAILURE": "Failed to Save Reseller Plan",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY": "Defining Reseller Plan Product Currency Rate",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE": "Failed to Defining Reseller Plan Product Currency Rate",
    "SAVE_SUB_TAX": "Save SubTax",
    "SAVE_SUB_TAX_FAILURE": "Save SubTax Failed",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET": "New Ticket created successfully",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET": "Ticket updated successfully",
    "SCHEDULE_AN_ORDER_BUTTON_TEXT": "Schedule an order",
    "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE": "Please select a date",
    "SCHEDULE_ORDER_CHECKBOX_TEXT": "I agree to schedule an order for future date",
    "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT": "Select a date",
    "SCHEDULE_ORDER_EVENT": "Schedule order",
    "SCHEDULE_ORDER_FAILED_EVENT": "Schedule order failed",
    "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT": "Delete",
    "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT": "Edit",
    "SCHEDULED_RENEWAL_CHANGES": "Scheduled renewal changes",
    "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT": "Do you want to apply the promotion to this product?",
    "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL": "Waiting for approval",
    "SCHEDULED_RENEWAL_STATUS_ACTIVE": "Active",
    "SCHEDULED_RENEWAL_STATUS_CANCELLED": "Cancelled",
    "SCHEDULED_RENEWAL_STATUS_COMPLETED": "Completed",
    "SCHEDULED_RENEWAL_STATUS_FAILED": "Failed",
    "SCHEDULED_RENEWAL_STATUS_ONHOLD": "On hold",
    "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS": "Back to basic details",
    "SCHEDULED_RENEWALS_HEADER_TEXT": "Scheduled renewals",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS": "Actions",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE": "Align end date",
    "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON": "Cancellation reason",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE": "Billing cycle",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU": "New SKU",
    "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY": "Quantity",
    "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE": "Scheduled date",
    "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS": "Status",
    "SCHEDULED_RENEWALS_TABLE_HEADER_TERM": "Term",
    "SCHEDULNG_LODING_MESSAGE_TEXT": "The process of loading the eligible offer from Partner Centre usually takes about 30 seconds. Please do not refresh the page.",
    "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME": "Search by Event",
    "SEARCH_AUDITLOG_TEXT_ACTION_NAME": "Search Actions",
    "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME": "Search by customer name",
    "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME": "Search by domain name",
    "SEAT_LIMIT_EXCEED": "Seat limit exceed",
    "SELECT_A_SOURCE_CURRENCY": "Select source currency",
    "SELECT_A_SUBSCRIPTION_TEXT": "Select a subscription",
    "SELECT_AGENT": "Agent",
    "SELECT_ALL_CUSTOMER": "All Customers",
    "SELECT_ALL_RESELLER": "All Resellers",
    "SELECT_ALL_RG": "Do you want to select all resource groups?",
    "SELECT_COUPON": "Select coupon",
    "SELECT_CURRENCY_CODE_TOOLTIP_TEXT": "Select currency code",
    "SELECT_CUSTOMER": "Select customer",
    "SELECT_DATE_VALUE": "Select date",
    "SELECT_DEFAULT_OPTION_SELECT": "Select",
    "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER": "Select customer",
    "SELECT_DEFAULT_OPTION_SELECT_EVENT": "Select event",
    "SELECT_DEPARTMENT": "Select department",
    "SELECT_DEPARTMENTS": "Select departments",
    "SELECT_EVENT": "Select event",
    "SELECT_MESSAGE_TYPE": "Select message type",
    "SELECT_MINUMUN_INVOICE_NOTIFICATION": "Please select atleast one invoice to pay",
    "SELECT_OFFER_QUICK_PRICING_MESSAGE": "Quick options to define your sale price",
    "SELECT_PARTNER_INVOICE": "Partner Invoices",
    "SELECT_PLACEHOLDER_DROPDOWN_EVENT": "Select event",
    "SELECT_PLAN_TOOLTIP_TEXT": "Select plan",
    "SELECT_SITE": "Select site",
    "SELECT_SITES": "Select sites",
    "SELECT_STATUS": "Select status",
    "SELECT_TARGET_CURRENCY_PLACEHOLDER": "Select target currency",
    "SELECT_TYPE": "Select type",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS": "Selected customers for uploading invoices to Quickbooks",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO": "Selected customers for uploading Invoices to Xero",
    "SELECTED_FILTER": "Select filter",
    "SELL_DIRECT_ICON_TITLE": "Stockholm-icons / Shopping / Box2",
    "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT": "Enter the email address to whom you want to send an email, enter with a comma(,) or semicolon(;) and click on Submit to receive a file containing a product catalogue list",
    "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT": "Resend invoice",
    "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT": "Send",
    "SEND_MAIL_BUTTON": "Send mail",
    "SEPTEMBER": "September",
    "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD": "Provider customer domain",
    "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD": "Service provider customer id",
    "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD": "Service provider customer name",
    "SERVICE_PROVIDER_TENANT": "Provider tenants",
    "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER": "Default tenant",
    "SERVICE_PROVIDER_TENANT_HEADER": "Provider tenants of ",
    "SERVICE_PROVIDER_TENANT_LABEL_ACTION": "Actions",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID": "Tenant id",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME": "Tenant name",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM": "Are you sure you want to mark <b> {{customerName}}</b> as default tenant?",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS": "Updated {{customerName}} as default tenant",
    "SESSION_EXPIRED_ERROR_MESSAGE": "Your session has expired. Please sign-in again to continue. Unfortunately any unsaved changes will be lost.",
    "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE": "Bulk Onboard Customers batch status is set to Complete",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED": "Trying to set status of external service post logs to Errorignored",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED": "Failed to set status of external service post logs to ErrorIgnored",
    "SETTING_HEADING_TEXT_EMAIL_TEMPLATE": "Email template",
    "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION": "Event email notifications",
    "SETTINGS_HEADING_TEXT_BILLING": "Billing",
    "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS": "ConnectWise Manage Settings",
    "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION": "Currency Conversion",
    "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS": "Customer defaults",
    "SETTINGS_HEADING_TEXT_DELETE_PLAN": "Delete Plan",
    "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION": "Email Notifications",
    "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION": "Customer Configurations (Global)",
    "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS": "Partner defaults",
    "SETTINGS_HEADING_TEXT_USER_MANAGEMENT": "User Management",
    "SETTINGS_PASSWORD_HELP_TEXT": "Leave the field empty, if you don't want to update the password.",
    "SETTINGS_TAB_AUTOTASK_CONFIGURATION": "Autotask",
    "SETTINGS_TAB_HEADING_CONNECTWISE": "ConnectWise Manage",
    "SETTINGS_TAB_HEADING_CUSTOM_VIEWS": "Custom Views",
    "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT": "General",
    "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT": "Logos",
    "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT": "Partner Center",
    "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT": "Providers",
    "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP": "Public Signup",
    "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT": "Email Configurations",
    "SETTINGS_TAX_NAME_TOOLTIP": "You can specify any tax name eg: GST.",
    "SETTINGS_TAX_PERCENTAGE_TOOLTIP": "You can specify the tax percentage here. Eg: 15 for 15%",
    "SHIP_TO": "Ship To",
    "SHOP_ADDED_TO_CART_TEXT": " added to cart",
    "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART": "Add to cart",
    "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS": "No products found!",
    "SHOP_PAGE_LINK_TEXT_READ_MORE": "Read More..",
    "SHOP_TILE_HYPERLINK_READ_MORE": "Read More..",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL": "Show customer configuration setting in customer portal",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC": "Enable the customer to manage configurations",
    "SHOW_NON_ACTIVE_OFFERS": "Show disabled offers",
    "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER": "Show offers which are not available for customer",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL": "Show reseller configuration setting in reseller portal",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC": "Enable the reseller to manage configurations",
    "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE": "Stockholm-icons / Communication / Add-user",
    "SIDEBAR_ADMINISTRATION_ICON_TITLE": "Stockholm-icons / Communication / Group",
    "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT": "Stockholm-icons-/-General-/-Notification2",
    "SIDEBAR_ANALYZE_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Calculator",
    "SIDEBAR_C3_SUPPORT_AZURE_MARGIN": "Azure Margin",
    "SIDEBAR_CONTENT_ALL_COMMENTS": "Allows Partner to review the comments provided for various entities ( Cart item, Invoices, Orders ) by customers and reply to them ",
    "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS": "List of account managers and options to manage the customers associated with them.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION": "Manage web notices on entity level",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS": "Create and manage custom notifications for various product/license management events and customer onboarding.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE": "Share real-time information with partners and customers through interactive broadcasts on their respective dashboards.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE": "Create and manage web notices on various entity levels to apprise about system health, invoices and other important notifications",
    "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT": "Report over the Azure billing data",
    "SIDEBAR_CONTENT_MENU_AZURE_MARGIN": "It enables you to update the Azure Plan markup for past months and load billing data with new markup values.",
    "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION": "Review your Azure subscription consumption details.",
    "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT": "Quick look at the cost summary report",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS": "Quick look at customer analytics",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION": "Display list of licenses consumed by the tenant",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT": "PowerBI based report to view Azure usage at multiple dimensions",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE": "Manage your organization profile, addresses, payment profiles and more ...",
    "SIDEBAR_CONTENT_MENU_DASHBOARD": "It gives partner thorough understanding  about the customers and their business on various dimensions.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE": "Review your license changes across custom dates.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT": "Quick look at the license consumption summary",
    "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT": "Quick overview of the license allocation and  the unassigned seats.",
    "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS": "Manage and create multiple Azure entitlements (subscriptions) under Azure plan in bulk",
    "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT": "Quick look at your indirect resellers' customer onboarding analytics.",
    "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT": "Detailed reporting of sales commission on agent level and the amount earned",
    "SIDEBAR_CONTENT_MENU_PARTNER_COUPON": "Define discounts coupons which can be applied on plan/customer/service level.",
    "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION": "Product hands-on guide for partners and end customers",
    "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT": " Allows partner to view & Download usage details for products recorded outside C3 Solution.",
    "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD": "Allows partner to upload product codes/attributes that are desired in invoiceline item reports.",
    "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS": "Allows partner to approve/reject product purchase requests from customers.",
    "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS": "Upload agent name values, products associated with sales, commission percentage and various other commission attributes",
    "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS": "Helps you create custom columns to be added to your invoice line items report.",
    "SIDEBAR_CONTENT_MENU_REPORT_USAGE": "Allows partner to upload usage details recorded outside C3 for products in the catalogue for invoice generation.",
    "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER": "Provides several reports over your data that is with C3.",
    "SIDEBAR_CONTENT_MENU_RESELLER_PLAN": "Helps you manage ( create/edit ) plans with offers that you wish to sell to indirect resellers by setting your custom margins.",
    "SIDEBAR_CONTENT_MENU_USAGE_REPORT": "Review your Azure subscription usage with associated costs.",
    "SIDEBAR_CONTENT_MENU_USERS": "Manage access to users",
    "SIDEBAR_CONTENT_MENUS_ANALYZE": "Analyze your business profitability on multiple dimensions.",
    "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE": "View Azure consumption on resource group/resource/tags level.",
    "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS": "Group one or more subscriptions and define budgets and thresholds",
    "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS": "Helps you manage ( create/edit )  3rd party  product/services and your own consulting/advisory/support services and offers.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD": "Leads to the dashboard",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES": "Access your invoices from the Partner",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT": "Allows you to access features relevant to Microsoft CSP tenants linked to your customers.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS": "List all Azure AD users of customers and assign them as customer side RBAC roles",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS": "Track your orders",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS": "View the products that were purchased",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP": "Shop and purchase offers from the catalog",
    "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES": "Manage invoices and payments",
    "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES": "Allows you to combine two or more offers (Microsoft and/or partner offers) and make it available to your customers for purchase",
    "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS": "Leads to the list of active customers and options to manage their settings.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD": "Takes you to the dashboard page",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS": "Check the status of the payments triggered by the system.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS": "Helps you create a catalog of offers ( products/services ) and lets you define your custom margins as sale price for your customers.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY": "Gives you an overview of your profitability and review the invoices in details.",
    "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD": "Leads to the dashboard",
    "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY": "Gives you an overview on your profitability and a look at the invoice details too",
    "SIDEBAR_CONTENT_MENUS_RESELLERS": "Leads to the list of active resellers and options to manage their csp portfolio.",
    "SIDEBAR_CONTENT_MENUS_SELL": "Lists the features that help you define your offers for sale",
    "SIDEBAR_CONTENT_MENUS_SELL_DIRECT": " <b>Direct bill partners ( Tier 1 )</b> - Lists all the features that help you manage  your customer offers and pricing.",
    "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT": "<b>Indirect Provider ( Tier 2 )</b> - Lists the features that help indirect providers to manage their indirect resellers.",
    "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN": "Access features exclusive to the C3 Support role.",
    "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE": "Stockholm-icons-/-Design-/-Union",
    "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE": "Stockholm-icons-/-Communication-/-Group",
    "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE": "Stockholm-icons-/-Communication-/-Group",
    "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE": "Stockholm-icons / Communication / Clipboard-list",
    "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE": "Stockholm-icons-/-Design-/-Union",
    "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE": "Stockholm-icons / Communication / Clipboard-list",
    "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT": "Stockholm-icons / Shopping / Chart-line1",
    "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE": "Stockholm-icons-/-Files-/-Group-folders",
    "SIDEBAR_PARTNER_COUPON_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Ticket",
    "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Gift",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Money",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE": "Stockholm-icons-/-Home-/-Key",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Money",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT": "Stockholm-icons / Shopping / Money",
    "SIDEBAR_PARTNER_PLAN_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Bag1",
    "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Money",
    "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE": "Stockholm-icons-/-Navigation-/-Double-check",
    "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT": "Stockholm-icons / Shopping / Money",
    "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT": "Stockholm-icons-/-Design-/-Component",
    "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE": "Stockholm-icons / Communication / Group",
    "SIDEBAR_RESELLER_PLAN_ICON_TITLE": "Stockholm-icons-/-Shopping-/-Bag1",
    "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP": "Stockholm-icons / Shopping / Box3",
    "SIDEBAR_TITLE_ALL_COMMENTS": "Comments",
    "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS": "Account Managers",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION": "Administration",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS": "Custom notifications",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE": "Engage",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE": "Banner notifications",
    "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT": "Azure Billing Report",
    "SIDEBAR_TITLE_MENU_AZURE_MARGIN": "Margin",
    "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION": "Azure Subscriptions",
    "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT": "Cost Summary Report",
    "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS": "Customer Analytics",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION": "License Consumption",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT": "Microsoft Azure Usage Report",
    "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE": "Profile",
    "SIDEBAR_TITLE_MENU_DASHBOARD": "Dashboard",
    "SIDEBAR_TITLE_MENU_LICENSE_CHANGE": "License Change Report.",
    "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT": "License Consumption Summary Report",
    "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT": "License Consumption Report",
    "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS": "Manage Azure Entitlements",
    "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT": "Onboarding Analytics",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS": "Commission",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT": "Commission Reports",
    "SIDEBAR_TITLE_MENU_PARTNER_COUPON": "Coupons",
    "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION": "Product Documentation",
    "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT": "Usage Report",
    "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD": "Upload",
    "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS": "Approvals",
    "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS": "Commissions",
    "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS": "Product Attributes",
    "SIDEBAR_TITLE_MENU_REPORT_USAGE": "Usage",
    "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER": "Reports",
    "SIDEBAR_TITLE_MENU_RESELLER_PLAN": "Plans",
    "SIDEBAR_TITLE_MENU_USAGE_REPORT": "Usage Report",
    "SIDEBAR_TITLE_MENU_USERS": "Users",
    "SIDEBAR_TITLE_MENUS_ANALYZE": "Analyze",
    "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE": "Azure Estimates",
    "SIDEBAR_TITLE_MENUS_AZURE_GROUPS": "Azure Groups",
    "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS": "Partner offers",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD": "Dashboard",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES": "Invoices",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT": "Microsoft",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS": "Microsoft Users",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS": "Orders",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS": "Products",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP": "Shop",
    "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES": "Invoices",
    "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES": "Bundles",
    "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS": "Customers",
    "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD": "Dashboard",
    "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS": "Pending payment status.",
    "SIDEBAR_TITLE_MENUS_PARTNER_PLANS": "Plans",
    "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY": "Revenue and Cost Summary",
    "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD": "Dashboard",
    "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY": "Revenue and Cost Summary",
    "SIDEBAR_TITLE_MENUS_RESELLERS": "Resellers",
    "SIDEBAR_TITLE_MENUS_SELL": "Sell",
    "SIDEBAR_TITLE_MENUS_SELL_DIRECT": "Sell Direct",
    "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT": "Sell Indirect",
    "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN": "C3 support",
    "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT": "Stockholm-icons / Files / Upload",
    "SIDEBAR_USAGE_REPORT_ICON_TITLE": "Stockholm-icons / Communication / Clipboard-list",
    "SIGN_UP_DATE": "Signup date",
    "SIGNUP_CART_COUPON_APPLY": "Apply",
    "SIGNUP_CART_COUPON_CODE": "Apply voucher",
    "SIGNUP_CART_REMOVE_APPLIED_COUPON": "Remove Voucher",
    "SINGLE_COMMENT_STATIC": "Comment",
    "SITE": "(Site)",
    "SITE_DEPARTMENT_ID": "SiteDepartmentId",
    "SITE_ID": "C3SiteId",
    "SLAB_BACKWARD_CONFIRMATION_POPUP": "Do you really want to go back? You have some saved slab data.",
    "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR": "Please fill and save slab data.",
    "SLAB_DATA_EMPTY_RAW_DATA_ERROR": "Please fill the slab data.",
    "SLAB_DATA_TABLE_HEADER_ACTIONS": "Actions",
    "SLAB_DATA_TABLE_HEADER_COST_RATE": "Cost rate/unit",
    "SLAB_DATA_TABLE_HEADER_RANGE": "Range",
    "SLAB_DATA_TABLE_HEADER_SALE_RATE": "Sale rate/unit",
    "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30": "Text length is too long",
    "SLAB_TABLE_EMPTY_RAW_DATA_ERROR": "Please fill the row data.",
    "SLAB_TABLE_EXCEED_10_DATA_ERROR": "Slab data can't be more then 10",
    "SLAB_TYPE_SELECT_OPTION": "Select",
    "SMTP_SETTINGS_TEST": "Please click the button to test SMTP settings",
    "SMTP_TEST_BUTTON": "Test SMTP",
    "SMTPTYPE_LEGACY_SMTP": "Legacy SMTP",
    "SMTPTYPE_MODERN_0365": "Modern 0365",
    "SMTPTYPE_SENDGRID": "Sendgrid",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1": "Quantity/seats can be increased at any time.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2": "Quantity/seats can be decreased within 7 calendar days, to its previous or sum of previous quantity/seats.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS": "Quantity Change",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS": "Schedule Renewal Change",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1": "Current schedule will be deleted if Quantity/Auto renewal status/Billing Cycle/ Term is changed.",
    "SoftwareSubscriptions": "Software subscriptions",
    "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION": "Source Currency",
    "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION": "Source Currency",
    "SOURCE_PLAN_DISPLAY_NAME": "Source Plan",
    "SOURCE_PRODUCT_DISPLAY_NAME": "Source Product",
    "SQL_ERROR_DESC": "There seems to be a technical error in processing your request, please contact support for further assistance.",
    "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID": "Error from database while trying to getting invoice dues",
    "START_BILLING_PERIOD": "Start billing period",
    "START_DATE_REQUIRED_MESSAGE": "Start Date is required",
    "STATUS": "Status",
    "STATUS_ALL": "All",
    "STATUS_DUE": "Dues",
    "STATUS_OVER_DUE": "Over-dues",
    "STATUS_PENDING": "Pending",
    "STATUS_UPDATED_SUCCESSFULLY_MESSAGE": "Status updated successfully",
    "SUB_TAXES_NOT_BOND_EXCEPTION": "Please enter the atleast one tax name and percentage for the sub taxes.",
    "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR": "The maximum quantity that can be allowed is",
    "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR": "The minimum quantity that can be allowed is 1",
    "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR": "This field is required",
    "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION": "Remove promotion",
    "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP": "The upgrade is in progress please click on transition to see the progress",
    "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP": "Because the upgrade license quantity is less than the current, you must manually reassign seats",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE": "This Customer Subscription is shared between Partner Customer and Reseller Customer, hence product cannot be upgraded",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2": "This subscription is shared by multiple entities, hence after the upgrade manual remapping is needed",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE": "Add Subscription Expiry Check",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE": "Add Subscription Expiry Check",
    "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED": "Required",
    "SUBSCRIPTION_EXPIRATION_MAX_DAYS": "the max number of days should be lesser than or equal to {{MaxDate}}",
    "SUBSCRIPTION_EXPIRATION_MIN_DAYS": "the minimum number of days should be greater than or equal to 1",
    "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH": "You have reached your maximum limit of 50 characters allowed",
    "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM": "Select a term",
    "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT": "Submit",
    "SUBSCRIPTION_HISTORY_BILLING_PERIOD": "Billing Period",
    "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY": "Subscription history",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE": "Billing cycle",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD": "Billing period",
    "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY": "Category",
    "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS": "Customers",
    "SUBSCRIPTION_HISTORY_FILTERS_END_DATE": "End date",
    "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD": "Subscription name",
    "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER": "Provider",
    "SUBSCRIPTION_HISTORY_FILTERS_START_DATE": "Start date",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES": "Billing cycles",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY": "Categories",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES": "Consumption types",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS": "Providers",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES": "Provider categories",
    "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY": "Subscription history",
    "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP": "Download subscription history",
    "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER": "Subscription history report",
    "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION": "Close",
    "SUBSCRIPTION_HISTORY_SEARCH_OPTION": "Search",
    "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION": "Reset",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME": "Customer name",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE": "Date",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME": "Department name",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE": "New price",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY": "New quantity",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS": "New status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON": "Add-on",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME": "Product name",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE": "Old price",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY": "Old quantity",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS": "Old status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE": "Price",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME": "Site name",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS": "Status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER": "User",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE": "End date",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE": "Start date",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS": "Manage license assignments ",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION": "Subscriptions",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION": "Back to Subscriptions",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS": "Release Unused Seats",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE": "Releasing unused seats. Please wait...",
    "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR": "Manage License Assignments for ",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES": "Assign licenses",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES": "Remove licenses",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS": " Assign users",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1": "Instructions",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2": "Instructions",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED": "View and search",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED": "Licenses to be removed",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS": "Validation Errors",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1": "Type in valid user email addresses separated by a comma.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2": "This Portal validates the input and determines if additional licenses are required to be purchased before the assignment.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3": "No operation can be performed while we work on changes to this subscription.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4": "Any errors found during the validation of input will be displayed.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5": "Either Assign or Remove licenses (active tab) is considered during submit. ",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1": "Click <i class='fa fa-remove red'></i> against a user in the table on the left to add to the list of licenses to be removed",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2": "Click Submit to remove the licenses",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3": "Either Assign or Remove licenses (active tab) is considered during submit",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST": "Cost",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY": "Original Quantity.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT": "Rate Per Seat",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER": "Multiple User",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE": "Remove",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE": " Upload File",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME": "Name",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT": " add new user email address",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT": "Enter valid user emails separated by a comma in a .txt file and upload it here.",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE": "Enter valid user emails separated by a comma in a .txt file and upload. Example : abc@xyz.com,bcd@xyz.com",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS": "Ignore errors",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS": "Fix errors",
    "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS": "Status ",
    "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE": "Please wait while we process your request, this may take a couple of minutes. You may also check back by clicking 'Manage' button from subscriptions page",
    "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED": "Do you wish to continue with the next scheduling renewal changes as {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'seat limit and transaction limit' : (isSeatLimitExceeded ? 'seat limit is' : 'transaction limit is')}} exceeded",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED": "Order Canceled",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED": "Order Completed",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED": "Subscription Purchased",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED": "Subscription Removed",
    "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS": "The selected request approved successfully.",
    "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING": "Subscriptions provisioning",
    "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS": "The selected request canceled successfully.",
    "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS": "All customers",
    "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING": "Subscriptions Provisioning",
    "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED": "Activate",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME": "Entity name",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE": "Title",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY": "New quantity",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS": "New status",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY": "Old quantity",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS": "Old status",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME": "Subscription name",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE": "Please wait  while we process your earlier purchase, this may take a couple of minutes.",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW": "Please wait  while we process your earlier purchase, this may take a couple of minutes. You may also check status by clicking 'Add New Subscription' button.",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK": "Add Check",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST": "Back to list",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS": "Subscription Expiry Checks",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION": "Action",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS": "Days",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME": "Name",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM": "Term",
    "SUBSCRIPTION_STATUS_ACTIVE": "Active",
    "SUBSCRIPTION_STATUS_CANCELLED": "Cancelled",
    "SUBSCRIPTION_STATUS_COMPLETED": "Completed",
    "SUBSCRIPTION_STATUS_DELETED": "Deleted",
    "SUBSCRIPTION_STATUS_DISABLED": "Disabled",
    "SUBSCRIPTION_STATUS_EXPIRED": "Expired",
    "SUBSCRIPTION_STATUS_FAILED": "Failed",
    "SUBSCRIPTION_STATUS_IN_PROVISION": "In-Provision",
    "SUBSCRIPTION_STATUS_PENDING": "Pending",
    "SUBSCRIPTION_STATUS_SUCCEEDED": "Succeeded",
    "SUBSCRIPTION_STATUS_SUSPENDED": "Suspended",
    "SUBSCRIPTION_UGRADE_TITLE_2": "Important",
    "SUBSCRIPTION_UPDATED": "Subscription updated successfully.",
    "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE": "Automatic",
    "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE": "Choose an eligible upgrade for your subscription",
    "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY": "Cumulative quantity",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION": "Current Subscription",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE": "Current Subscription",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_1": "Select a new offer to upgrade the current subscription. This will upgrade your subscription and provision services associated with new offer",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_2": "During the upgrade, avoid changing any part of the original subscription or the customer's accounts. This will create a new subscription, provision services, and reassign user licenses to the new subscription. These changes cannot be reverted.",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_3": "Please make sure you take data backup before upgrading the subscription.",
    "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS": "Ignore error",
    "SUBSCRIPTION_UPGRADE_IGNORE_WARNING": "Ignore warning",
    "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE": "Manual",
    "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND": "No eligible plan products are present for this subscription",
    "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND": "No transitions found",
    "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED": "Do you wish to continue with the subscription upgrade as {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'seat limit and transaction limit' : (isSeatLimitExceeded ? 'seat limit is' : 'transaction limit is')}} exceeded",
    "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT": "Seat Reassignment",
    "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED": "Seats that needs to be upgraded (Source Subscription)",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST": "Select a target subscriptions",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME": "Subscription Name",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION": "This field is required",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL": "Target subscriptions",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST": "New Subscription",
    "SUBSCRIPTION_UPGRADE_TITLE": "Choose an eligible upgrade",
    "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY": "Transition activity",
    "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS": "Upgrade is in progress",
    "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT": "Upgrade",
    "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED": "This customer has already been assigned.",
    "SUBSCRIPTIONMANAGE_ERRORMESSAGE": "The following users already have an assigned license on this offer. Please remove them and try to save.",
    "SUBSCRIPTIONMANAGE_IGNORE_ERROR": "Are you sure you want Ignore the Errors",
    "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS": "License Assignments updated successfully",
    "SUBSCRIPTIONMANAGE_REACTIVATE": "Are you sure want to reactivate this subscription?",
    "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY": "The selected subscription reactivated successfully",
    "SUBSCRIPTIONMANAGE_RELEASE_UNUSED": "Are you sure you want release the unused seats?",
    "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER": "Please remove atleast one user to revoke license from the Licenses Assigned table!",
    "SUBSCRIPTIONMANAGE_SELECT_USER": "Please select user from the suggested list before clicking Add button.",
    "SUBSCRIPTIONMANAGE_SUSPEND": "Are you sure want to suspend this subscription? Please note that related active add-on subscriptions will also get suspended(if any)",
    "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY": "Subscription (including add-ons as applicable) is suspended successfully",
    "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY": "Subscription's unused seats released successfully.",
    "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE": "Your bulk assignment request is in queue",
    "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY": "Successfully uploaded",
    "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT": "Please enter the valid user emails to assign the licenses in the text box.",
    "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION": "Add new subscription",
    "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS": "Subscriptions",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT": " Edit",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE": "Manage",
    "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL": "Total",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION": "Actions",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME": "Name",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY": "Quantity",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT": "Cost",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM": "Total",
    "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION": "New Azure subscription",
    "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL": "Name",
    "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION": "Add new subscription",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE": "Azure",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION": "Add new subscriptions",
    "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION": "Azure Subscriptions",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE": "Estimate price",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME": "Offer name",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS": "Subscriptions",
    "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION": "Update subscription",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY": "New quantity total",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1": "Min value is 1",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2": "Input should be a valid integer",
    "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER": "Offer",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1": "Purchase additional seats by increasing the <strong>New Quantity Total</strong>",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2": "Make sure that you have released Licenses from users via <strong>Users -&gt; Licenses</strong> in case you want to reduce the number of seats from here",
    "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE": "Your subscriptions purchasing request is in queue",
    "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE": "Reactivate",
    "SUBSCRIPTIONS_SAVE_SUCCESS": "Subscription saved successfully",
    "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT": "Please select atleast one offer before you place an order",
    "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE": "Suspend",
    "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE": "General settings updated successfully",
    "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS": "ConnectWise Manage settings updated successfully.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS": "Partner SMTP settings updated successfully.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO": "Partner {{logo}} updated successfully.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS": "Partner settings updated successfully.",
    "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS": "Provider settings updated successfully.",
    "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS": "Public signup  settings updated successfully.",
    "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS": "Import of multiple payments completed successfully",
    "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA": "Successfully imported partner offer daily usage data",
    "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS": "Import of Product Commissions completed successfully",
    "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS": "Successfully created a new plan with all offers",
    "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS": "Successfully created a new reseller plan with all offers",
    "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX": "Fix quantity mismatch",
    "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN": "Please update the plan for this customer to include relevant offers and come back here to fix the issue",
    "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME": "CSP offer name",
    "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND": "No matching offers found",
    "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME": "Matching offer in the current plan",
    "SUPER_USER_POPUP_LABLE_TEXT_PRICE": "Price",
    "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY": "Quantity",
    "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING": "Offer mapping",
    "SUPPLIER_DETAILS": "Supplier Details",
    "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET": "New Support Ticket",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL": "Contact Email",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE": "Contact Phone",
    "SUPPORT_ADD_FORM_LABELS_DESCRIPTION": "Description",
    "SUPPORT_ADD_FORM_LABELS_PRIORITY": " Priority",
    "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA": " Product Area",
    "SUPPORT_ADD_FORM_LABELS_SUJECT": "Subject",
    "SUPPORT_ADD_FORM_LABELS_TYPE": "Type",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT": "Support",
    "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#": "Support Ticket #",
    "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS": "Add Comment",
    "SUPPORT_DETAILS_FORM_LABELS_COMMENTS": "Comments",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL": "Contact Email",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE": "Contact Phone",
    "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT": "Cumulative Effort ",
    "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER": "Customer",
    "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION": "Description",
    "SUPPORT_DETAILS_FORM_LABELS_EFFORT": " Effort (In Hours)",
    "SUPPORT_DETAILS_FORM_LABELS_PRIORITY": "Priority",
    "SUPPORT_DETAILS_FORM_LABELS_PRODUCT": "Product Area",
    "SUPPORT_DETAILS_FORM_LABELS_STATUS": "Status",
    "SUPPORT_DETAILS_FORM_LABELS_SUBJECT": "Subject",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT": "Support Agent",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER": "Select Support Agent",
    "SUPPORT_DETAILS_FORM_LABELS_TYPE": "Type",
    "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS": "No comments added.",
    "SUPPORT_FOR_TEXT": "Support for",
    "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET": "Add support ticket",
    "SUPPORT_LIST_CAPTION_SUPPORT_TICKET": "Support Tickets",
    "SUPPORT_LIST_FORM_LABEL_CUSTOMER": "Customer",
    "SUPPORT_LIST_FORM_LABEL_SEARCH": "Search",
    "SUPPORT_LIST_FORM_LABEL_STATUS": "Status",
    "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT": "Support Agent",
    "SUPPORT_LIST_FORM_LABEL_TICKET_ID": "Ticket Id",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION": "Actions",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS": "Details",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL": "Contact email",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE": "Created date",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER": "Customer",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY": "Priority",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA": "Product area",
    "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS": "Status",
    "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT": "Subject",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID": "Ticket ID",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE": "Type",
    "SUPPORT_TICKET_CREATE_SUCCESS": "New support ticket created successfully",
    "SUPPORT_TICKET_STATUS_ARCHIVED": "Archived",
    "SUPPORT_TICKET_STATUS_CLOSED": "Closed",
    "SUPPORT_TICKET_STATUS_IN PROGRESS": "In Progress",
    "SUPPORT_TICKET_STATUS_OPEN": "Open",
    "SUPPORT_TICKET_STATUS_RE_OPEN": "Re-open",
    "SUPPORT_TICKET_STATUS_RESOLVED": "Resolved",
    "SUPPORT_TICKET_STATUS_WONT_FIX": "Won't Fix",
    "SUPPORT_TICKET_TYPE_ACCESS_ISSUES": "Access issues",
    "SUPPORT_TICKET_TYPE_ANALYTICS": "Analytics",
    "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE": "Microsoft Azure",
    "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT": "Profile Management",
    "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT": "Subscription Management",
    "SUPPORT_TICKET_TYPE_USER_MANAGEMENT": "User Management",
    "SUPPORT_TICKET_UPDATE_SUCCESS": "Support ticket details updated successfully",
    "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY": "No market has been found for the choosen currency code",
    "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND": "Below markets are not supported as there is no currency conversion found for their respective currencies.",
    "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP": "Supported market only applies to NCE offers",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY": "Market",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE": "Market Code",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY": "Currency",
    "SWAL_OK_TEXT": "Ok",
    "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT": "Switch to microsoft pricing api",
    "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT": "Switch to new security model",
    "TAB_HEADING_PROVISIONING_STATUS": "Provisioning status",
    "TABLE_FOOTER_TEXT_SHOWING_COUNT": "Showing {{StartIndex}} - {{EndIndex}} of {{Total}} records",
    "TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "TABLE_HEADER_TEXT_DATE": "Date",
    "TABLE_HEADER_TEXT_DESCRIPTION": "Description",
    "TABLE_HEADER_TEXT_FAILURE_REASON": "Failure reason",
    "TABLE_HEADER_TEXT_GRAND_AMOUNT": "Grand amount",
    "TABLE_HEADER_TEXT_KEY": "Key",
    "TABLE_HEADER_TEXT_PAID_AMOUNT": "Paid amount",
    "TABLE_HEADER_TEXT_PAYMENT_STATUS": "Payment status",
    "TABLE_HEADER_TEXT_REMARKS": "Remarks",
    "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT": "Sub total amount",
    "TABLE_HEADER_TEXT_TAX_AMOUNT": "Tax amount",
    "TABLE_HEADER_TEXT_VALUE": "Value",
    "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL": "Selected Invoice(s) Total",
    "TABLE_PLACEHOLDER_TEXT_SEARCH": "Search...",
    "TAG_DEELTE_SUCCESS_MESSAGE": "Tag deleted successfully",
    "TAG_NAME_PLACEHOLDER": "Select a tag",
    "TAG_SAVED_SUCCESS_MESSAGE": "Tag saved successfully",
    "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER": "Plan name",
    "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER": "Product name",
    "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER": "Properties",
    "TAGS_ACTIONS_TEXT_BACK_TO_TAGS": "Back to Tags",
    "TAGS_ADD_EDIT_CAPTION_TEXT_ADD": "Add",
    "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT": "Edit",
    "TAGS_CAPTION_TEXT_TAG_FOR": "Tag for ",
    "TAGS_CAPTION_TEXT_TAGS_FOR": "Tags for ",
    "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION": "Target Currency",
    "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION": "Target Currency",
    "TARGET_PLAN_DISPLAY_NAME": "Target Plan",
    "TARGET_PRODUCT_DISPLAY_NAME": "Target Product",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES": "Back to tax percentages",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE": "Tax percentages",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD": "Add",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT": "Edit",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1": "Tax rules are defined by { Country,State Province and Tax Code }, { Country, Zip } or { Country, State Province and Zip } combinations",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2": "Eg: Consider tax rule definition for a country like USA, leaving state, province and zip code as blank means that the tax rate is applied to all the customers from US. If you want to define a rule for a particular state, provide the Country and State values while defining the rule",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM": "Effective from",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME": " Tax Name",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE": " Tax Percentage",
    "TAX_PERCENTAGES_ADD_SUCCESS": "Tax Percentage added successfully.",
    "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER": "Customers",
    "TAX_PERCENTAGES_DELETE_SUCCESS": "Tax Percentage deleted successfully.",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE": "Add Tax Percentage",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST": "Back to list",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE": "Tax Percentages",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR": "Tax Percentages for ",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE": "Delete",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT": "Edit",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION": "Actions",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM": "Effective from",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE": "Tax % ",
    "TAX_PERCENTAGES_UPDATE_SUCCESS": "Tax Percentage updated successfully.",
    "TAX_RULES_ADD_SUCCESS": "Tax rule added successfully",
    "TAX_RULES_DELETE_SUCCESS": "Tax rule deleted successfully",
    "TAX_RULES_UPDATE_SUCCESS": "Tax rule updated successfully",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX": "Cumulative taxes",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION": "The child tax rules are applied in sequence on the resultant amount from each step in the calculation.",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX": "General",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION": "Single tax percentage applied on the total amount.",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX": "Sub taxes",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION": "The tax percentage for this definition will be the total ( sum ) from the child tax rules.",
    "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT": "Activate the danger threshold met subscription",
    "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS": "Enable the auto release for unassigned seats",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION": "Allow customer to initiate the transaction for their invoice(s)",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS": "Checking this flag prevents customers from reducing the subscription quantity",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC": "Allow product name sync for usage based offers",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS": "Allow product name sync for quantity based offers",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP": "Allows sync of subscription friendly name for quantity based offers",
    "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER": "Option to apply azure plan tier 2 discounts to customer. default value is No. If it is set to yes other than PEC discounts all other discounts will be applied to customer also.",
    "TENANT_CONFIG_DESC_AUTO_SYNC": "This is used to enable/disable the Automatic Sync process in Reconciliation.",
    "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM": "Autotask Annual Contract Term (Years)",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE": "Autotask contract end date",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC": "This date value will be used as end date to create contracts Automatically for customer",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE": "Autotask contract set up fee",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC": "Please enter contract set up fees if any",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE": "Autotask contract start date",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC": "This date value will be used as start date to create contracts Automatically for customer",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE": "Autotask Contract Start Day",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES": "Activate the checkbox if contract time reporting requires start and stop times",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC": "Autotask contract time reporting required start and stop times",
    "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM": "Autotask Monthly Contract Term (Months)",
    "TENANT_CONFIG_DESC_AZURE_APPLICABLE": "Enable customers to manage Azure subscriptions",
    "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE": "Enable customers to define Groups over Azure Subscriptions",
    "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the customer azure usage. Eg: Input 1.15 if markup should be 15%",
    "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT": "When enabled, NCE subscription is set to auto-renew.",
    "TENANT_CONFIG_DESC_COOL_OFF_PERIOD": "After purchase or quantity increase, the product will not be considered in autorelease up to this period (in days).",
    "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID": "CRM Opportunity ID",
    "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE": "Customer billing type",
    "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY": "Customer currency",
    "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS": "Support Customer Product Auto Price Update Effectiveness Type. This setting will comes to effect if and only if Customer product auto price update is enabled!",
    "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT": "Default danger threshold in percentage for the azure subscription.",
    "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT": "Default error threshold in percentage for the azure subscription.",
    "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT": "Default info threshold in percentage for the azure subscription.",
    "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT": "Default monthly budget for the azure subscription.",
    "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT": "Default threshold email recipient for the azure subscription.",
    "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT": "Default warning threshold in percentage for the azure subscription.",
    "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION": "Enable terms & conditions to order products",
    "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION": "Enable NCE terms & conditions",
    "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS": "Enable power BI reports",
    "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER": "Enabling this option will allow users to schedule an order",
    "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID": "ERP Customer ID",
    "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT": "If this field is checked we take customer consent into consideration.",
    "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED": "This field gets updated if at least one of the customers has accepted the MCA. Uncheck this box to enforce the acceptance from the customer(s) again.",
    "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED": "Accepted latest terms and conditions?",
    "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT": "Transaction limit",
    "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER": "Integer Constant",
    "TENANT_CONFIG_DESC_INTERNAL_BILLINGID": "For internal reference. This ID can be used to relate the customer to a record in your internal accounting system",
    "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY": "Day of the month when the invoices get approved automatically",
    "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY": "Day when auto payments are processed",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAY": "Due date for invoices",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAYS": "No of days due for invoice payment",
    "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY": "Day of the month when invoices get finalized automatically",
    "TENANT_CONFIG_DESC_INVOICE_NOTE": "Appears on the invoice pdf",
    "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT": "Mandate payment profile availability before cart checkout",
    "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS": "Is Required Auto Release For Site and Department Products",
    "TENANT_CONFIG_DESC_MANDATE_PROFILE": "Mandate user to submit payment method",
    "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT": "Enabling this option will automatically designate the newly added address as the default address for the customer.",
    "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT": "Enabling this option will automatically designate the newly added email as the default email for the customer.",
    "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT": "Enabling this option will automatically designate the newly added phone number as the default phone number for the customer.",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY": "<span><b>None:</b> It will disable billing for One time subscriptions.     <b>Customers:</b> Customer invoices will include one time subscription billing.     <b>Site:</b> One time subscription billing will be included in Site invoice. Enter Site name in respective identifier fields.</span>",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD": "Onetime subscription billing will be included into this Site's invoices.",
    "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the customer one time line items.",
    "TENANT_CONFIG_DESC_PAN_NUMBER": "Appears on the invoice pdf in the Bill To section.",
    "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT": "Appears on the invoice pdf in the Bill To section.",
    "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION": "Terms and conditions link",
    "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE": "Total mark up (%) to be applied to cost price to attain the desired sale price for perpetual software subscriptions.",
    "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY": "Appears on the invoice pdf in the Bill To section.",
    "TENANT_CONFIG_DESC_PO_NUMBER": "Define purchase order number for customer",
    "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES": "If this setting is enabled, billing email will be posted to external accounting services",
    "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY": "Check this flag if you want the system to process payments (CC/ACH as applicable)",
    "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME": "When enabled, will display subscription name given in partner center",
    "TENANT_CONFIG_DESC_PS_INVOICE_RECORD": "Perpetual software billing will be included into this Site's invoices.",
    "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT": "Enforce a limit on the number of seats that the customer can purchase",
    "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS": "The payment terms to be marked on invoices that are posted to QuickBooks",
    "TENANT_CONFIG_DESC_RI_INVOICE_RECORD": "Azure reservation billing will be included into this Site's invoices.",
    "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE": "Mark up (%) to be applied on the customer azure reservation.",
    "TENANT_CONFIG_DESC_RI_PRICING_MODEL": "RI pricing model",
    "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY": "Customer will get the email with invoice details once invoice is approved and if email notifications are enabled",
    "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES": "Customer will recieve email notification regarding invoices having zero amounts",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE": "Appears on the invoice pdf in the Ship To section.",
    "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT": "Should enable default for customer.",
    "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER": "Should notify customer",
    "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT": "Should Exempt Customer From Instant Pay Payment.",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "When enabled, customer can cancel NCE subscription within cancellation window of  7 calendar days.",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES": "Allow customer to align subscription end dates",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP": "If enabled, Customer can align subscription end dates to achieve coterminosity",
    "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT": "Show NCE Schedule Renewal Menu",
    "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "When enabled, customer can re-activate a suspended NCE subscription.",
    "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "When enabled, customer can suspend NCE subscription.",
    "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT": "When enabled the customer can Upgrade the NCE product to a higher SKU",
    "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE": "Subscription to be skipped during one time invoice",
    "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Total mark up (%) to be applied to cost price to attain the desired sale price for software subscriptions.",
    "TENANT_CONFIG_DESC_SS_INVOICE_RECORD": "Software subscription billing will be included into this Site's invoices.",
    "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS": "Setting this value to true will stop the user's ability to make any purchases.",
    "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER": "String Constant for Customer",
    "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE": "Support Customer product auto uprice Update",
    "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT": "Suspend when danger threshold met",
    "TENANT_CONFIG_DESC_TAX_CODE": "Tax code is used to determine the applicable tax %",
    "TENANT_CONFIG_DESC_TAX_TYPE_NAME": "Appears on the invoice pdf in the Bill To section.",
    "TENANT_CONFIG_DESC_TAX_VALUE": "Appears on the invoice pdf in the Bill To section.",
    "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Total mark up (%) to be applied to cost price to attain the desired sale price for third party subscriptions.",
    "TENANT_CONFIG_DESC_TP_INVOICE_RECORD": "Third party subscriptions billing will be included into this Site's invoices.",
    "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT": "Enforce a limit on the monetary value of the quantity based product purchase ( In customer currency )",
    "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT": "ConnectWise Addition Type",
    "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT": "Advance Billing",
    "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT": "Based on the selected format, additions will be created in connectwise",
    "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT": "Placing Current month billing to specified month in future for invoicing",
    "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG": "Show Terms & Conditions Acceptance Log to Tenant",
    "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT": "Activate the danger threshold met subscription",
    "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS": "Allow auto release un-used seats",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION": "Allow Customer to initiate transaction",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS": "Allow customer to reduce seats",
    "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER": "Apply azure plan tier 2 discounts to customer",
    "TENANT_CONFIG_DISP_AUTO_SYNC": "Auto Sync",
    "TENANT_CONFIG_DISP_AZURE_APPLICABLE": "Enable Azure?",
    "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE": "Enable Azure for Enterprise?",
    "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE": "Mark up for Azure usage",
    "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT": "Manage auto-renew for NCE product",
    "TENANT_CONFIG_DISP_COOL_OFF_PERIOD": "Auto release cool off period",
    "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID": "CRM opportunity ID",
    "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE": "Customer billing type",
    "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY": "Customer currency",
    "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS": "Customer product auto price update effectiveness",
    "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT": "Default danger threshold (%)",
    "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT": "Default error threshold (%)",
    "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT": "Default info threshold (%)",
    "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT": "Default monthly budget",
    "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT": "Default threshold email recipient",
    "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT": "Default warning threshold (%)",
    "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION": "Enable default terms & conditions to order products",
    "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION": "Enable NCE terms & conditions to order Microsoft NCE products",
    "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER": "Enable schedule order",
    "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID": "ERP customer ID",
    "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT": "Enabled customer consent?",
    "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED": "Microsoft customer agreement accepted?",
    "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED": "Accepted partner terms and conditions?",
    "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT": "Transaction limit",
    "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER": "MANDT",
    "TENANT_CONFIG_DISP_INTERNAL_BILLINGID": "Billing reference ID",
    "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY": "Day when customer can see invoices",
    "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY": "Day for auto payment",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAY": "Due date for invoices",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAYS": "Due days for invoices",
    "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY": "Day when invoices are finalized",
    "TENANT_CONFIG_DISP_INVOICE_NOTE": "Invoice notes",
    "TENANT_CONFIG_DISP_INVOICE_TYPE": "Invoice Type",
    "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT": "Mandate Payment Profile Cart Checkout",
    "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS": "Is Required Auto Release For Site and Department Products",
    "TENANT_CONFIG_DISP_MANDATE_PROFILE": "Mandate profile",
    "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT": "Automatically assign a new address as the default",
    "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT": "Automatically assign a new email as the default",
    "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT": "Automatically assign a new phone number as the default",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY": "One time invoice owner (type)",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD": "One time invoice owner (identifier)",
    "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE": "Markup for one time line items",
    "TENANT_CONFIG_DISP_PAN_NUMBER": "PAN number",
    "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT": "PAN number key",
    "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION": "Terms and condition URL",
    "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE": "Markup for perpetual software subscription",
    "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY": "Place of supply",
    "TENANT_CONFIG_DISP_PO_NUMBER": "PO #",
    "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES": "Post billing email to external accounting services",
    "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY": "Auto pay invoices?",
    "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME": "Display provider subscription name",
    "TENANT_CONFIG_DISP_PS_INVOICE_RECORD": "Perpetual software invoice owner (identifier)",
    "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT": "Seat limit applicable",
    "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS": "QuickBooks payment terms",
    "TENANT_CONFIG_DISP_RI_INVOICE_RECORD": "Azure reservation invoice owner (identifier)",
    "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE": "Markup for azure reservation",
    "TENANT_CONFIG_DISP_RI_PRICING_MODEL": "RI pricing model",
    "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE": "Markup for azure reservation",
    "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY": "Send invoice emails automatically",
    "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES": "Send zero amount invoices",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME": "Ship to contact name",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER": "Ship to contact number",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER": "Ship to PAN number",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT": "Ship to PAN number key",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME": "Ship to tax type",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE": "Ship to tax value",
    "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT": "Should enable default for customer",
    "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT": "Exempt instant pay payment",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "Show NCE Product Cancel Button",
    "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "Show NCE Product Reactivate Button",
    "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT": "Show NCE Product Suspend Button",
    "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG": "Show terms & conditions acceptance log",
    "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT": "Show NCE Product Upgrade button",
    "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Markup for software subscription",
    "TENANT_CONFIG_DISP_SS_INVOICE_RECORD": "Software subscription invoice owner (identifier)",
    "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS": "Stop all transactions",
    "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER": "Customer unique identifier",
    "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE": "Customer product auto price update",
    "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT": "Suspend when danger threshold met",
    "TENANT_CONFIG_DISP_TAX_CODE": "Tax code",
    "TENANT_CONFIG_DISP_TAX_TYPE_NAME": "Tax type",
    "TENANT_CONFIG_DISP_TAX_VALUE": "Tax value",
    "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE": "Markup for third-party subscription",
    "TENANT_CONFIG_DISP_TP_INVOICE_RECORD": "Third party subscription invoice owner (identifier)",
    "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT": "Transaction amount limit",
    "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE": "Please enter value of {{tenantConfigName}}",
    "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE": "Please enter valid value of {{tenantConfigName}}",
    "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP": "Manage auto-renew configuration is not enabled for this tenant.",
    "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS": "Customer configuration reverted successfully",
    "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS": "Customer configuration saved successfully",
    "TENANT_CONFIGURATION_SAVE_SUCCESS": "Customer configuration for {{customername}} updated successfully",
    "TENANT_COUNTS": "Count",
    "TENANT_CREATION_FAILED": "Tenant creation failed",
    "TENANT_EXCEEDED_COUNT_ERROR": "Number of tenant to be created, not exceeding {{Count}}",
    "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP": "If you want to create the Azure subscription with the tenant please tick it ",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX": "Add prefix 'OTU' in name",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP": "Add 'OTU' as prefix in tenant name",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN": "Assign tenant admin as an owner",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP": "Assign the admin user as owner of the created subscription",
    "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT": "Add tenants",
    "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION": "Create Subscriptions",
    "TENANT_MANAGEMENT_LABEL_DEPARTMENT": "Department",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME": "Domain Name",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP": "Domain names will start with this.",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT": "Select Azure plan offer",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP": "Use this to select correct plan product to map Azure plan",
    "TENANT_MANAGEMENT_LABEL_PREFIX": "Prefix",
    "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP": "Add prefix in tenant name",
    "TENANT_MANAGEMENT_LABEL_SEQUENCE": "Start Sequence",
    "TENANT_MANAGEMENT_LABEL_SITE": "Site",
    "TENANT_MANAGEMENT_LABEL_TENANT_COUNT": "Number of tenants",
    "TENANT_MANAGEMENT_LABEL_TENANT_NAME": "Name",
    "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED": "Process complete. Please check the audit log for more details.",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST": "Request to add tenants is accepted and being processed",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED": "Request to add tenants failed.",
    "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR": "Please provide a number sequence",
    "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP": "The sequential increment will be used as a suffix in tenant names.",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER": "Tenant creation status",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO": "Please wait while the tenants are being created.",
    "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP": "Number of tenants to be created.",
    "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP": "Tenant names will start with this.",
    "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT": "Back to Tenants",
    "TENANT_SEQUENCE": "Sequence",
    "TERM_DURATION_DESC_DAY(S)": "Day(s)",
    "TERM_DURATION_DESC_DAYS": "Day",
    "TERM_DURATION_DESC_MONTH": "Month",
    "TERM_DURATION_DESC_MONTH(S)": "Month(s)",
    "TERM_DURATION_DESC_YEAR": "Year",
    "TERM_DURATION_DESC_YEAR(S)": "Year(s)",
    "TERM_DURATION_DESC_YEARS": "Years",
    "TERMS_AND_CONDITIONS": "Terms And Conditions",
    "TERMS_AND_CONDITIONS_ACCEPT": "Please accept terms and conditions",
    "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY": "Terms and conditions accepted successfully.",
    "TERMS_AND_CONDITIONS_BACK": "Back to Terms and Conditions",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT": "Please read through the below provided terms and conditions</b>.    </br>    </br>    <ul>    <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>Partner terms of use</a></li> </ul>    </br>    By clicking \"Accept\" button, you agree to the above terms of use.",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA": "Please read through the below provided terms and conditions from Microsoft. </br> By clicking \"Accept\" button, you agree to the above terms of use.",
    "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM": "Please read through the below provided contract, terms and conditions from <b>{{partnerName}}</b> and Microsoft.    </br>    </br>    <ul>    <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li>  </ul>    </br>    By clicking \"Accept\" button, you agree to the above terms of use.",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT": "Click <a id='linkEzidebit' href='#' ng-click='vm.EzidebitAgreementClicked()'> here </a>to read complete Ezidebit Agreement",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE": "Ezidebit Agreement",
    "TERMS_AND_CONDITIONS_READ": "I have read the terms and conditions",
    "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS": "Unable process your request.",
    "TEST_EMAIL_LABEL": "Test Email Id",
    "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT": "Test",
    "TEST_PARTNER_ACCESS_BTN_TEXT": "Test",
    "TEST_SMTP_SETTING_TITLE": "Test SMTP settings",
    "TEXT_ACH": "ACH",
    "TEXT_ACTIONS": "Actions",
    "TEXT_BILL_FROM": "Bill From",
    "TEXT_BILL_TO": "Bill To",
    "TEXT_BILLING_PERIOD": "Billing Period",
    "TEXT_EMAIL_ID": "Email Id",
    "TEXT_GROUP_NAME": "Group Name",
    "TEXT_INVOICE_AMOUNT": "Invoice Amount",
    "TEXT_INVOICE_DATE": "Invoice Date",
    "TEXT_INVOICE_NUMBER": "Invoice Number",
    "TEXT_INVOICE_SUMMARY": "Invoice Summary",
    "TEXT_MONTHLY_BUDGET": "Monthly Budget",
    "TEXT_OFF": "OFF)",
    "TEXT_PLAN_ID": "Plan Id",
    "TEXT_RESPONSIBLE_OFFICIAL": "Responsible Official",
    "TEXT_SAAS_SUBSCRIPTION_STATUS": "Saas Subscription Status",
    "TEXT_SIGN_IN_REQUIRED": "Sign in required",
    "TEXT_TENANT_ID": "Tenant Id",
    "TEXT_THRESHOLDS": "Thresholds",
    "TEXT_VAT_NUMBER": "VAT Number",
    "THE_SEQUENCE_LABEL_THE_STARTING_NAME": "The sequence name will start from ",
    "TIME_DESC_DAY": "day",
    "TIMER_DESC_DAYS": "days",
    "TITLE_COUPON_CODE_TEXT": "Coupon Codes",
    "TITLE_CUMULATIVE_TAXES": "Cumulative Taxes",
    "TITLE_ROLE_SELECT": "Select Role",
    "TITLE_SUB_TAXES": "Sub Taxes",
    "TITLE_TAGS": "Tags",
    "TO_ONBOARD_AZURE_SUBSCRIPTIONS": "to onboard Azure Subscriptions",
    "TOOLTIP_COUPON_CODE_TEXT": "Coupon Code",
    "TOOLTIP_TEXT_DELETE_PSA_LOG": "Deactivate log",
    "TOOLTIP_TEXT_DISABLE_PRODUCT": "Disable Product",
    "TOOLTIP_TEXT_EDIT_NAME": "Edit Name",
    "TOOLTIP_TEXT_ENABLE_PRODUCT": "Enable Product",
    "TOOLTIP_TEXT_LINK_PRODUCT": "Link Product",
    "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT": "Link product is unsupported",
    "TOOLTIP_TEXT_QUANTITY": "Quantity",
    "TOUR_POPUP_CONTENT": "<b>Welcome onboarded!</b></br> Checkout a brief tour of the features in the application.",
    "TOUR_POPUP_DISMISS_BUTTON": "Dismiss",
    "TOUR_POPUP_TAKE_A_TOUR_BUTTON": "Take a tour",
    "TOUR_POPUP_TITLE": "Welcome to ",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS": "Actions",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT": "Amount",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY": "Category",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE": "Rate",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION": "Description",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT": "Discount",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME": "Product name",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY": "Quantity",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE": "Rate",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT": "Rolled-up amount",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED": "Taxed",
    "TRANSACTION_AMOUNT_LIMIT_EXCEED": "Transaction amount exceed",
    "TRANSACTION_LIMIT_MESSAGE": "Remaining limit {{RemainingAmount}} out of {{TotalLimit}}",
    "TRANSACTION_TYPE_PURCHASE": "New purchase",
    "TRANSACTION_TYPE_UPDATE": "Quantity update",
    "TRANSACTION_TYPE_UPGRADE": "Subscription upgrade",
    "TRIAL_OFFER_IN_PROVISION_MESSAGE": "Currently, there is a request to convert the trial offer into a subscription; no operation is allowed to be performed on this product.",
    "TRIAL_OFFER_QUANTITY_HEADER_POPUP": "Continue the subscription after trial",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS": "Instructions",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE": "Note",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1": "The trial period lasts for one month, and the product will be canceled afterward.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2": "By clicking on \"Continue Subscription,\" the product will be purchased after the trial period, and billing will commence accordingly.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3": "You have the flexibility to extend your subscription beyond the trial period by selecting the \"Purchase Subscription\" button.",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_1": "The trial offer shared across site/department will not be converted to subscription.",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_2": "A subscription of one quantity will be purchased at the customer's operating entity.",
    "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR": "Please enter a valid value",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000": "Maximum quantity is 10000",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1": "Minimum quantity is 1",
    "TROUE_BTN_TEXT_END_TOUR": "End tour",
    "TROUE_BTN_TEXT_NEXT": "Next &raquo;",
    "TROUE_BTN_TEXT_PREV": "&laquo; Prev",
    "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC": "Unable to find any Azure plan from PC",
    "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE": "Customer is unassigned successfully",
    "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION": "Are you sure you want to unassign {{customer}}?",
    "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE": "Reseller is unassigned successfully",
    "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY": "Unassigned Customer Of An Account Manager Successfully",
    "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY": "Unassigned Reseller Of An Account Manager Successfully",
    "UNIQUE_NAME_ERROR": "This name already exists, please choose some thing different.",
    "UNSUPPORTED_FILE_TYPE_SELECTED": "Only files of type jpg, svg, png, gif, bmp are supported",
    "UP_GRADE_AZURE_SUBSCRIPTION": "Upgrade Azure subscription to Azure Plan.",
    "UPDATE_CART_LINE_ITEM": "Updated Cart Item",
    "UPDATE_CART_LINE_ITEM_FAILURE": "Failed to update cart item",
    "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE": "Customer global configurations updated successfully",
    "UPDATE_DEFAULT_VALUE": "Mark As Default",
    "UPDATE_DUE_DATE_NAME": "Due Date",
    "UPDATE_INVOICE_LINEITEM_PONUMBER": "Invoice Line Item PO#",
    "UPDATE_INVOICE_PONUMBER": "PO #",
    "UPDATE_INVOICE_PROPERTIES": "Update invoice property",
    "UPDATE_MANAGE_RENEWAL_TITLE": "Update renewal",
    "UPDATE_PARTNER_SETTINGS_EXCEPTION": "Error occurred while updating the partner settings",
    "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION": "Error occurred while updating the tax details",
    "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS": "Updating the products is not allowed as license assignment is in progress for the product or one of its addons. Please try later.",
    "UPDATE_PROFILE": "Updated profile successfully",
    "UPDATE_PROFILE_FAILURE": "Failed to update profile",
    "UPDATE_RESELLER_CONFIGURATION": "Update Reseller Configuration",
    "UPDATE_RESELLER_CONFIGURATION_FAILURE": "Failed to update Reseller Configuration",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION": "Update Reseller Default Payment Option",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE": "Update Reseller Default Payment Option Failure",
    "UPDATE_RESELLER_PAYMENT_PROFILE": "Updated Reseller Payment Profile",
    "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE": "Failed to update Reseller Payment Profile",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE": "Reseller Plan Product Price Updated",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE": "Failed to update reseller plan product price",
    "UPDATE_RESELLER_PROFILE": "Update Reseller Profile",
    "UPDATE_RESELLER_PROFILE_FAILURE": "Failed to Update Reseller Profile",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE": "Updated default value of provider customer",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE": "Failed to update the default value of provider customer",
    "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY": "Updated Account Manager Details Successfully",
    "UPDATED_FAILED_C3_PSA_DATA_FAIL": "PSA Data Update Failed",
    "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE": "Data refresh is failed, other options will be accessible once you click on ok.",
    "UPDATED_PAGE_MODE_TO": "Updated to {{PageMode}} view successfully",
    "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE": "Updated successfully C3 PSA data with latest data from PSA external system",
    "UPGRADE_ELIGIBILITY_CHECK": "Check Upgrade Eligibility",
    "UPGRADE_FAILED_MSG": "Azure  upgrade got failed",
    "UPGRADE_SUCCESS_MSG": "Request to upgrade is accepted and being processed.",
    "UPGRADE_SYNC_PUPUP_TITLE": "Upgrade Azure",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE": "Align with contract start date",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP": "Align the effectiveness date of product with next recent contract period. Please note that next recent date is calculated only a monthly basis, even if the contract period is Yearly.",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID": "Upload Invoices to QuickBooks Online Batch Id",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE": "Upload Invoices to Quickbooks error message",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE": "Are you sure you want to upload the selected invoices to <b>QuickBooks Online</b>?",
    "UPLOAD_INVOICES_TO_XERO_BATCHID": "Upload invoices to Xero Batch Id",
    "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE": "Are you sure you want to upload the selected invoices to <b>Xero</b>?",
    "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE": "Uploading invoices to Xero error message",
    "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD": "Upload Resellers Details",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE": "Failed to upload Subscription history into external service",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS": "Successfully upload Subscription history into external service",
    "UPLOAD_TO_PSA_SELECT_ALL": "Select all",
    "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE": "You can't update the -ve percentage to a value of the available Pecentage.",
    "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR": "You can't update the percentage to a value above the available percentage.",
    "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT": "Getting Usage Report",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID": "Subscription id",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME": "Subscription name",
    "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER": "Bill To Customer",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE": "Charge end date",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE": "Charge start date",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER": "Cost to customer",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX": "Cost to partner (post tax)",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX": "Cost to partner (pre tax)",
    "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE": "Currency code",
    "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME": "Customer name",
    "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT": "Download usage report",
    "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME": "Tenant Name ",
    "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT": "Azure Usage Report",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS": "Azure subscriptions",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION": "Back to subscriptions",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION": "Edit Subscription",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION": "Add Subscription",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD": " Danger Threshold",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD": "Error Threshold",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME": "Group Name",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER": "Select Group",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD": "Info Threshold",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED": "Hide from Customer",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET": "Monthly Budget",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME": "Subscription Name",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD": "Warn Threshold",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER": "Back to subscription users",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER": "Manage Subscription User ",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL": "Email",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE": "Role",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR": "Contributor",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER": "Owner",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER": "Reader",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS": " Back to subscriptions",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS": " Export usage details",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF": "Billing Summary",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF": "Usage Details of ",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND": "Spend",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS": "Consumed units",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE": "Resource type",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription Name",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION": "Add subscription",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS": " Back to customers",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS": "Azure Subscriptions for ",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION": "All Subscriptions",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD": "Billing Period",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP": "Group",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION": "Edit Subscription",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS": "Users",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS": "View Usage Details",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION": "Actions",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST": "Estimated cost",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP": "Group",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID": "Subscription ID",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME": "Subscription name",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD": "Thresholds",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION": "Add user to subscription",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS": "Back to subscriptions",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF": "Subscription Users of",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL": "Email",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME": "Name",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE": "Role",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER": "Delete user",
    "USER_CREATED_POPUP": "User Created",
    "USER_CREATION_SUCCESS_MESSAGE": "User {{UserName}} created successfully",
    "USER_DETAILS_NOT_FOUND_BODY": "Unable to find the logged in user details. Please contact administrator!",
    "USER_DETAILS_NOT_FOUND_HEADER": "Details not found",
    "USER_HAS_BEEN_CREATED": "User has been created with the following details",
    "USER_LICENSE_ASSIGNMENT_BATCH_ID": "User License Assignment BatchId",
    "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE": "Done",
    "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION": "License Assignment Status",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME": "Customer name",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS": "Users",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS": "Email's",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME": "Tenant name",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID": "Tenant Id",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON": "Addon",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle": "Bundle",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked": "Linked",
    "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION": "User Removal Status",
    "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS": "Release unused seats",
    "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS": "Apply changes to addons",
    "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT": "Users Of Product",
    "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS": "Error occurred while releasing seats. Please contact support.",
    "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE": "There should be atleast one active license. Please suspend the product if you do not wish to use it anymore.",
    "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED": "Request to release seats is being processed.",
    "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED": "Successfully uploaded",
    "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY": "Request to add licenses is accepted and being processed.",
    "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE": "Request for the bulk assignment of licenses is accepted and being processed.",
    "USER_LICENSE_TRACKING_TEXT_ASSIGNED": "Assigned",
    "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS": "Total Seats",
    "USER_LISTING_FILTER_LABEL": "Email Id",
    "USER_LISTING_FILTER_PLACEHOLDER_TEXT": "Search by display name or email id",
    "USER_LISTING_SEARCH_BUTTON": "Search",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON": "Export user assignment report",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL": "Entities",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR": "Select atleast one entity",
    "USER_REMOVED": "User removed successfully",
    "USER_ROLE_LIST_LABEL_ROLE_NAME": "Role Name",
    "USER_ROLE_LIST_LABEL_SCOPE": "Scope Name",
    "USER_ROLE_LIST_LABEL_USER_NAME": "User Name",
    "USERCONTROLLER_USER_ADD_SUCCESSFULLY": "User added successfully",
    "USERCONTROLLER_USERUPDATESUCCESS": "User details updated successfully",
    "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE": "User's access revoked",
    "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "USERS_ADD_EDIT_CAPTION_TEXT_ADD": "Add",
    "USERS_ADD_EDIT_CAPTION_TEXT_EDIT": "Edit",
    "USERS_ADD_EDIT_CAPTION_TEXT_USER": " User Under ",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1": "is not available",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2": "is available",
    "USERS_ADD_EDIT_FORM_HEADER_USER_INFO": "User Info",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1": "Check / uncheck the checkboxes next to the offers to assign / release licenses respectively",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2": "If there are not enough free licenses available against an offer, a purchase would be made before the assignment is performed",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3": "indicates that a subscription is being provisioned by C3. Please wait until the operation is complete.",
    "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME": " Display name",
    "USERS_ADD_EDIT_FORM_LABELS_EMAIL": "Email",
    "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME": "First name",
    "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION": "Instructions",
    "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME": "Last name",
    "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION": "User location ",
    "USERS_ADD_EDIT_FORM_SEATS": "seats",
    "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS": "Back to users",
    "USERS_ADD_OR_EDIT_CAPTION_1": "Add",
    "USERS_ADD_OR_EDIT_CAPTION_2": "Edit",
    "USERS_ADD_OR_EDIT_CAPTION_3": " User",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL": "Email",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME": "First Name",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME": "Last Name",
    "USERS_ADDUSER_COMPLETE_LAST_UPLOAD": "Last bulk upload to create users is completed!",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER": "Cannot grant customer admin privilege to External users",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ": "Are you sure you want to grant customer admin privilege to",
    "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD": "Dashboard",
    "USERS_BREADCRUM_BUTTON_TEXT_USERS": "Users",
    "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT": "Onboarding analytics",
    "USERS_BULK_CREATION_ENQUEUED_SUCCESS": "Your request to create users is enqueued. Please come back to this page after some time for updates. The process typically takes a few minutes to complete.",
    "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR": "Error occurred while exporting the error details",
    "USERS_BULK_CREATION_FIX_ERRORS": "Please correct the errors reported in the file that was just downloaded and try again.",
    "USERS_BULK_CREATION_STATUS_SUCCESS": "Your request to create users is completed successfully",
    "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS": "Get uploaded users details",
    "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF": "{{AvailableUnits}} remaining out of {{TotalUnits}}",
    "USERS_BUY_MORE": "Buy More",
    "USERS_CREATE_SUCCESS": "User {{username}} created successfully",
    "USERS_DELETE_CONFIRM": "Any assigned licenses will be removed and {{userName}} will be deleted. Do you want to continue?",
    "USERS_DELETE_EXISTENCE_CONFIRM": " This user have some access in C3 ,that will be deleted. Do you want to continue?",
    "USERS_DELETE_SUCCESS": "Deletion of the {{username}} succeeded",
    "USERS_DOWNLOADTEMPLATE_DATANOTFOUND": "Data not found. Please try later",
    "USERS_GRANT_ACCESS_CONFIRM": "Are you sure you want to grant customer admin privilege to {{username}}?",
    "USERS_GRANT_ACCESS_SUCCESS": "Granted access to {{username}} successfully",
    "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN": "Granting access to external users is not allowed",
    "USERS_IGNOREERRORS_IGNOR_ERRORS": "Are you sure you want to proceed?",
    "USERS_LICENSE_FREE_OUT_OF": "licenses free out of",
    "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS": "Working on assigning licenses for the user {{username}}. This process would take a few minutes to complete.",
    "USERS_LICENSES_REVOKE_SUCCESS": "Revoked licenses from {{username}} successfully",
    "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS": "Revoked licenses from {{username}} successfully. Working on assigning new licenses. This process will take a few minutes to complete.",
    "USERS_LICENSES_UPDATE_SUCCESS": "Licenses for user {{username}} updated successfully",
    "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS": "Add new user",
    "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD": "Multiple user upload",
    "USERS_LIST_CAPTION_TEXT_USERS": "Users",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS": "Edit User Details",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL": "Grant access to the portal",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD": "Reset Password",
    "USERS_LIST_TABLE_ACTION_VIEW_LICENSE": "View Licenses",
    "USERS_LIST_TABLE_HEADER_ACTION": "Actions",
    "USERS_LIST_TABLE_HEADER_EMAIL_ID": "Email ID",
    "USERS_LIST_TABLE_HEADER_IS_ACTIVE": "Is active",
    "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED": "No users roles assigned.",
    "USERS_LIST_TABLE_HEADER_ROLE": "CSP role(s)",
    "USERS_LIST_TABLE_HEADER_USER_NAME": "User name",
    "USERS_LIST_TABLE_ROLE_TOOLTIP": "Show Roles",
    "USERS_PASSWORD_RESET_SUCCESS": "Password for {{username}} is reset successfully",
    "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT": "Password successfully sent to the specified recipients",
    "USERS_RESETUSERPASSWORD_PASSWORD_RESET": "Password reset successful",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD": "Password reset is successful, Password is: <a href='#' ngclipboard='' data-clipboard-text='{{Password}}' ng-click='vm.confirmCopy()'><b>{{Password}}</b></a> <br/><br/><b>After you leave this page, you can’t view this temporary password again.</b><br/><br/>If you want to send the password through email please provide the comma separated recipients in the text-box below otherwise press cancel.<br/>",
    "USERS_REVOKE_ACCESS_CONFIRM": "Are you sure you want to revoke {{UserRoleDescription | translate}} privilege from {{userName}}",
    "USERS_REVOKE_ACCESS_SUCCESS": "Revoked access from {{username}} successfully",
    "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS": "Get uploaded users details",
    "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD": "Try a new upload!",
    "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS": "View & Fix Errors",
    "USERS_STATUS_CAPTION_STATUS": "Status",
    "USERS_STATUS_WARNING_MESSAGE": "Creation of users from the last upload is still in progress. Please check back by clicking 'BulkUserUpload' button on the Users page.",
    "USERS_TABLE_HEADER_EMAIL": "Email",
    "USERS_TABLE_HEADER_NAME": "Name",
    "USERS_TABLE_HEADER_PLAN_DESCRIPTION": "Plan description",
    "USERS_UPDATE_LICENCES_QUEUED": "Licenses are queued for update",
    "USERS_UPDATE_SUCCESS": "User details for {{username}} updated successfully",
    "USERS_UPDATELICENCES_SUCCESS": "Licenses updated successfully",
    "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS": "User details for {{UserName}} updated Successfully",
    "USERS_UPLOAD_CAPTION_TEXT_UPLOAD": "Upload",
    "USERS_UPLOAD_DATA_INVALID_ERROR": "Errors detected in the data uploaded. Download the errors, fix and try uploading again.",
    "USERS_UPLOAD_ERROR": "An error occurred while parsing the uploaded file",
    "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT": "Please select a file to upload",
    "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE": "Remove",
    "USERS_UPLOAD_FORM_HEADER_USER_INFO": "User Info",
    "USERS_UPLOAD_FORM_INSTRUCTIONS": "INSTRUCTIONS",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1": "Upload account data for multiple users in .csv format. Download a sample .csv <strong><a href='#' ng-click='vm.DownloadTemplate()'>Download a sample .csv</a></strong> file for reference.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2": " This file can be used to create multiple user accounts for a customer. Do not update or delete any existing text or data in this file. User accounts will be created with User (not an admin) permissions.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3": "First name is an optional field supporting zero to 50 characters. An example: Chris.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4": " Last name is an optional field supporting zero to 50 characters. An example: Green.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5": " Display name is a mandatory field supporting one to 50 characters. An example: Chris Green.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6": " Email is a mandatory field. Enter a valid email address in this field for each user record. An example: chris@contoso.com.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7": "Status update field is automatically populated to convey status of validation. Leave this field empty.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8": "Upload the file and click on <strong class=\"text-uppercase\">submit</strong>.",
    "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE": " Upload File",
    "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION": "Usage Location",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES": "Back to licenses",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS": "Back to users",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES": "Edit licenses",
    "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR": "Licenses Assigned for",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE": "License assigned date",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT": "Product",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY": "Summary",
    "VALIDATE_LIST": "Validate list",
    "VALIDATE_PRODUCT_QUANTITY": "{{product}} quantity {{quantity}} should be between {{minQuantity}} and {{maxQuantity}}",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE": "Failed to validate subscription history for external service",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS": "Successfully validated subscription history for external service",
    "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT": "Already linked customer to this tenant.",
    "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS": "This coupon code already exists",
    "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER": "Only alpha numericals are allowed in Coupon Code!",
    "VALIDATION_MESSAGE_DISCOUNT_VALEU": "We are allowing discount only in between 0 to 100",
    "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST": "This email '{{emailAddress}}' is already in use.",
    "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER": "Please enter a valid number",
    "VALIDATION_MESSAGE_FOR_LINKTOCONTACT": "Please enter valid URL or enter default url as '/#/contact/'",
    "VALIDATION_MESSAGE_FOR_URL": "Please enter valid URL.",
    "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT": "Please enter valid URL or enter default url as 'home.support'",
    "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE": "Please enter valid Zip code",
    "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22": "Company IIN length should less than or equal to 22",
    "VALIDATION_MESSAGE_MAX_LENGTH_50": "This field accepts a maximum of 50 characters.",
    "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5": "Company IIN length should be greater than 4",
    "VALIDATION_MESSAGE_MINIMUM_0": "Minimum value is 0",
    "VALIDATION_MESSAGE_MINIMUM_1": "Minimum value is 1",
    "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL": "Please enter a valid email",
    "VALIDATION_MESSAGE_REQUIRED": "This field is required",
    "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE": "Enter valid conversion rate",
    "VALIDITY_TEXT_FROM": "From",
    "VALIDITY_TEXT_TO": "To",
    "VALIDITY_TYPE_DESC_DAYS": "Day(s)",
    "VALIDITY_TYPE_DESC_MONTHS": "Month(s)",
    "VALIDITY_TYPE_DESC_YEARS": "Year(s)",
    "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT": "Validity type",
    "VALIDITY_TYPE_NAME_DAYS": "Day(s)",
    "VALIDITY_TYPE_NAME_MONTHS": "Month(s)",
    "VALIDITY_TYPE_NAME_YEARS": "Year(s)",
    "VALUE_SHOULD_BE_GREATER_THAN": "Value should be greater than 0",
    "VAT_NUMBER_DESC": "VAT number for customer",
    "VAT_NUMBER_DESC_FOR_RESELLER": "VAT number for reseller",
    "VAT_NUMBER_DISP": "VAT number",
    "VAT_NUMBER_DISP_FOR_RESELLER": "VAT number",
    "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT": "Validate token",
    "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG": "View More Details",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES": "View Usage Line Items",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES": "View Usage Line Items",
    "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE": "Generate invoice",
    "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT": "Reload Profitability Report",
    "VIEWS_HOME_BUTTONS_TEXT_ADD_USER": "Add User",
    "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS": "Delete User",
    "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL": "Email",
    "VIEWS_HOME_TABLE_HEADER_TEXT_NAME": "Name",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS": "Assign Tags",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES": "Edit Roles",
    "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER": "Add Partner User",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS": "Assign Tags",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER": "Delete Partner",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES": "Edit Roles",
    "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT": "Partner User Management",
    "VIEWS_PARTNERS_LINK_USER_MANAGEMENT": "User Management",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS": "Actions",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL": "Email",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES": "Roles",
    "VIEWS_TABLE_HEADER_TEXT_ROLES": "Roles",
    "WAITING_FOR_APPROVAL": "Waiting for approval",
    "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER": "You deselected all the ",
    "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER": "You selected all the ",
    "WEB_NOTIFICATION_SEARCH_PLACEHOLDER": "Enter an entity name",
    "WEBHHOOK_LABEL_EFFECTIVE_FROM": "Effective from",
    "WEBHHOOK_LABEL_EFFECTIVE_TO": "Effective to",
    "WEBHHOOK_LABEL_ENTITY": "Entity",
    "WEBHHOOK_LABEL_EVENT": "Event",
    "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP": "Tagged entities",
    "WEBHOOK_BREADCRUMB_ADD": "Create webhook",
    "WEBHOOK_BUTTON_ACTIONS_ADD": "Create webhook notification",
    "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST": "Back to list",
    "WEBHOOK_CANCEL_NOTIFICATION": "Cancel",
    "WEBHOOK_DESCRIPTION_TOOLTIP": "Enter Description",
    "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP": "Choose effective to date",
    "WEBHOOK_END_DATE_PLACEHOLER": "End date",
    "WEBHOOK_ENTITY_TOOLTIP": "Select category",
    "WEBHOOK_EVENT_TOOLTIP": "Choose Event",
    "WEBHOOK_HEADER_ACTIONS": "Actions",
    "WEBHOOK_HEADER_ASSOCIATED_ENTITIES": "Associated entities",
    "WEBHOOK_HEADER_CREATET_DATE": "Create date",
    "WEBHOOK_HEADER_DESCRIPTION": "Description",
    "WEBHOOK_HEADER_EFFECTIVE_FROM": "Effective from",
    "WEBHOOK_HEADER_EFFECTIVE_TO": "Effective to",
    "WEBHOOK_HEADER_EVENTNAME": "Event",
    "WEBHOOK_HEADER_TITLE": "Title",
    "WEBHOOK_LABEL_DESCRIPTION": "Description",
    "WEBHOOK_LABEL_EFFECTIVE_TO": "Effective To",
    "WEBHOOK_LABEL_ENTITY_TEXT": "Category",
    "WEBHOOK_LABEL_EVENT_TEXT": "Event",
    "WEBHOOK_LABEL_TITLE_TEXT": "Title",
    "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT": "Webhook method",
    "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT": "Retry count",
    "WEBHOOK_LABEL_WEBHOOK_URL_TEXT": "Webhook URL",
    "WEBHOOK_METHOD_METHOD_TOOLTIP": " Method to be used when sending data to an external system through a webhook.",
    "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED": "This field is required",
    "WEBHOOK_NOTIFCATION_ERROR_MESSAGE": "Error occured while sending webhook notification",
    "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE": "Webhook notification sent successfully",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION": "Description",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID": "ID",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT": "Old retry count",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL": "Old webhook url",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME": "Product name",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT": "Retry count",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE": "Title",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL": "Webhook URL",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD": "Webhook method",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR": "Only one product can be selected.",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT": "Error while selecting product",
    "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE": "Webhook notification created successfully",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT": "All products have been selected",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT": "Product has been selected.",
    "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER": "Associated entity elements",
    "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE": "Webhook notification updated successfully",
    "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT": "All products have been selected.",
    "WEBHOOK_SAVE_NOTIFICATION": "Submit",
    "WEBHOOK_SELECT_PRODUCT_BUTTON": "Select",
    "WEBHOOK_SELECT_PRODUCT_TOOLTIP": "Select associated entity element",
    "WEBHOOK_START_DATE_PLACEHOLER": "Start date",
    "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK": "Webhook Notification",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30": "This exceeds max length of 30",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3": "Please ensure the value is between 0 and 3",
    "WEBHOOK_THIS_FIELD_IS_REQUIRED": "This field is required",
    "WEBHOOK_TITLE_TOOLTIP": "Enter Title",
    "WEBHOOK_URL_TOOLTIP": " Enter the destination URL where the system will send HTTP requests to notify an external service about specific events or updates.This URL should be capable of receiving and processing webhook payloads.",
    "WEBHOOK_VALIDATE_BUTTON": "Validate",
    "WEBHOOK_VALIDATE_URL": "Please validate the URL",
    "WEBHOOK_VALIDATE_URL_BUTTON": "Validate URL",
    "WEBHOOK_VALIDATION_ERROR_MESSAGE": "Invalid webhook URL",
    "WEBHOOK_VALIDATION_SUCCESS_MESSAGE": "Webhook URL is valid",
    "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP": " Number of attempts the system will make to perform a specific action in case of failure.",
    "WELCOME_HEADER_1_PART_1": "With us, you have endless possibilities.",
    "WELCOME_HEADER_1_PART_2": "And it starts now.",
    "WELCOME_PORTAL_FEATUR_1": "Self-service Portal",
    "WELCOME_PORTAL_FEATUR_2": "Add/remove subscriptions quickly and easily",
    "WELCOME_PORTAL_FEATUR_3": "Automated payments",
    "WELCOME_PORTAL_FEATUR_4": "Automatically pay invoices",
    "WELCOME_PORTAL_FEATUR_5": "Secure",
    "WELCOME_PORTAL_FEATUR_6": "Secured with Microsoft Azure Active Directory and Multi-factor Authentication",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN": "Login",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME": "Welcome",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT": "Logout",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT": "Contact us",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD": "Go To Dashboard",
    "Won''t Fix": "Won't Fix",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE": "Thank you for providing your consent for Xero. The application can be configured to access Xero following the new security guideline. You can post the invoices of your customers to Xero.",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2": "Please follow the instructions to Upload Invoices to Xero in <b>{{applicationName}}</b>.",
    "XERO_CONSENT_DESCRIPTION": "To Post Invoices to Xero you need to provide your consent.",
    "XERO_CONSENT_PROCESSING_MESSAGE": "Please wait while we process your Xero consent",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_1": "The link below opens in a new tab. Please provide consent with your Xero credentials. If you've got an error while providing consent, please contact our support team.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_2": " to provide Xero consent",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_3": "If you have provided the consent already, please click the <b> Validate Token </b> button to verify the connectivity with Xero.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_4": "Click this button to verify the validity of existing Xero Refresh Token.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING": "Prerequisites for Partner to post customer invoices to <b> XERO </b>",
    "XERO_POST_BATCH_ID": "Xero Post Batch Id",
    "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE": "Your request to post invoices to Xero is accepted and enqueued for processing. Please click on <strong>View Post Logs</strong> button for updates on the progress.",
    "XERO_POST_LOGS_STATUS": "Xero Post Logs Status",
    "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE": "The exisiting Xero Refresh Token is invalid. Please provide your consent again",
    "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE": "The exisiting Xero Refresh Token is valid. You can continue with uploading Invoices",
    "XERO_TENANT_ID": "Xero Tenant Id",
    "Year(s)": "Year(s)",
    "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE": "You can specify the Tax Percentage for the customer. Eg: 15 for 15%",
    "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE": "You can specify the tax name like GST..",
    "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION": "You don't have permission to perform this action",
    "YOU_MUST_SELECT_ONE_OR_MORE_OFFER": "You must select one or more offers to create a subscription",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION": "Show Software Subscriptions Product Cancel Button",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION": "When enabled, customer can cancel software subscriptions product within cancellation window of 7 calendar days.",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS": "Cancellation Policy",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1": "0 - 24 hours: Full Refund",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2": "24 - 48 hours: 1st Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3": "48 – 72 hours: 2nd Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4": "72 – 96 hours: 3rd Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5": "96 – 120 hours: 4th Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6": "120 – 144 hours: 5th Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7": "144 – 168 hours: 6th Day Prorated",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8": "After 7 calendar days: Cancellation is not allowed",
    "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING": "Software subscriptions Instructions",
    "PONUMBER_POPUP_TITLE": "Add PO Number",
    "SESSION_TIMEOUT_WARNING" : "Session Timeout Warning",
    "AZURE_ERROR_RECOMMENDATION_TEXT" : "Error while fetching Azure Advisor Recommendations",
    "SCORE" : "Score",
    "HIGH" : "Hign",
    "MEDIUM" : "Medium",
    "LOW" : "Low",
    "SESSION_WARNING_DURATION" : "Your session will expire in {{secondsLeft}} seconds."
}
};
