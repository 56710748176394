import { ComponentFactoryResolver, Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class DynamicTemplateService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver){
    
  }

  private templateSubject = new Subject<TemplateRef<any>|null>();
  public template$ = this.templateSubject.asObservable();

  sendTemplate(template: TemplateRef<any>|null) {
    this.templateSubject.next(template);
  } 

  loadComponent(dynamicHost:ViewContainerRef, widgetMap:Map<string, Type<any>>, key:string, properties: { [key: string]: any }|null = null): any {
    let DynamicComponent = widgetMap.get(key);
    if(DynamicComponent){
      const viewContainerRef = dynamicHost;
      viewContainerRef.clear();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicComponent);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      // Set properties on the component instance
      if(properties){
        try{
          Object.keys(properties).forEach(prop => {
            componentRef.instance[prop] = properties[prop];
          });
          componentRef.instance.cdRef.detectChanges();
        }
        catch(e){
          console.error("dynamic implemetation missing cdRef dependency in widget ",DynamicComponent)
        }
      }
      return componentRef;
    }
  } 
}
