export class CloudHubConstants {

    /*BEGIN= SubscriptionStatus*/
    static SUBSCRIPTION_STATUS_ACTIVE = 'active';
    /*END= SubscriptionStatus*/

    static IMPERSONATION = "BTN_QUICK_IMPERSONATION";

    /*BEGIN= BillingTypes*/
    static BILLING_TYPE_PRICE = 'price';
    static BILLING_TYPE_MARKUP = 'markup';
    static BILLING_TYPE_PERCENTAGE = 'percentage';
    static BILLING_TYPE_UNIT = 'unit';
    static BILLING_TYPE_METERED_BILLING = 'meteredbilling';
    /*END= BillingTypes*/

    /*BEGIN= Consumption types*/
    static CONSUMPTION_QUANTITY_BASED = 'quantity';
    static CONSUMPTION_USAGE_BASED = 'usage';
    static CONSUMPTION_CONTRACT = 'contract';
    static CONSUMPTION_RESERVATION = 'Reservation';
    /*END= Consumption types*/

    /*BEGIN= Category types*/
    static CATEGORY_ONLINE_SERVICES = 'onlineservices';
    static CATEGORY_ONLINE_SERVICES_NEW_COMMERCE_EXPERIENCE = 'onlineservicesnce';
    static CATEGORY_CUSTOM = 'custom';
    static CATEGORY_AZURE = 'azure';
    static CATEGORY_BUNDLES = 'bundles';
    static CATEGORY_SOFTWARE_SUBSCRIPTIONS = 'softwaresubscriptions';
    static CATEGORY_AZURE_NON_CSP = 'azure';
    static CATEGORY_AZURE_PLAN = 'azureplan';
    static CATEGORY_PERPETUAL_SOFTWARE = 'perpetualsoftware';
    static CATEGORY_LICENSE_SUPPORTED = 'licensesupported';
    static CATEGORY_ONLINE_SERVICES_NCE = 'onlineservicesnce';
    static CATEGORY_DISTRIBUTOR_OFFERS = 'distributoroffers';
    static CATEGORY_CUSTOM_TRIAL = 'customtrial';
    static RESERVED_INSTANCES = 'reservedinstances';
    /*END= Category types*/

    /*BEGIN= Subscription Status*/
    static SUBSCRIPTION_STATUS_SUSPENDED = 'suspended';
    static SUBSCRIPTION_STATUS_EXPIRED = 'expired';
    static SUBSCRIPTION_STATUS_PENDING = 'pending';
    static SUBSCRIPTION_STATUS_INPROVISION = 'InProvision';
    static SUBSCRIPTION_STATUS_CANCELLED = 'Cancelled';
    static SUBSCRIPTION_STATUS_COMPLETED = 'Completed';
    static SUBSCRIPTION_STATUS_SUCCEEDED = 'Succeeded';
    /*END= Subscription Status*/

    /*BEGIN= Provider types*/
    static PROVIDER_MICROSOFT = 'microsoft';
    static PROVIDER_PARTNER = 'partner';
    static PROVIDER_MICROSOFT_NON_CSP = 'microsoftnoncsp';
    /*END= Provider types*/

    /*BEGIN= Audit Log*/
    static AUDIT_LOG_PARTNER = 'sidebar_Partner_AuditLog';
    static AUDIT_LOG_CUSTOMER = 'sidebar_Customer_AuditLog'
    static AUDIT_LOG_RESELLER = 'sidebar_Reseller_AuditLog'
    static EXPORT_EVENT_AUDIT_LOGS = 'EXPORT_EVENT_AUDIT_LOGS';
    static VIEW_HIDDEN_AUDIT_LOGS = 'VIEW_HIDDEN_AUDIT_LOGS';
    static GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
    /*End= Audit Log*/

    /*BEGIN= Bulk ADD Entitlements*/
    static BULK_ADD_ENTITLEMENTS = 'BULK_ADD_ENTITLEMENTS';
    /*End=  Bulk ADD Entitlements*/

    /*BEGIN= Bulk ADD tenants*/
    static BULK_ADD_TENANTS = 'BULK_ADD_TENANTS';
    static BULK_ONBOARD_CUSTOMERS = 'BTN_BULK_ONBOARD_CUSTOMERS';
    /*End=  Bulk ADD tenants*/

    /*BEGIN= Contact Methods*/
    static CONTACT_METHOD_EMAIL = 'email';
    static CONTACT_METHOD_WEBHOOK = 'webhook';
    /*END= Contact Methods*/

    /*BEGIN= Contact Offers*/
    static TAB_COUPNS = 'TAB_COUPNS';
    static TAB_COUPON_ASSIGNMENT = 'TAB_COUPON_ASSIGNMENT';
    static BTN_SAVE_COUPON = 'BTN_SAVE_COUPON';
    static GET_COUPONS = 'GET_COUPONS';
    static CUSTOM_PRORATE = 1;
    static CUSTOM_DEFAULT = 4;
    static CUSTOM_ANUAL = '2';
    /*END= Contact Offers*/

    static BTN_SAVE_COUPON_ASSIGNMENT = 'BTN_SAVE_COUPON_ASSIGNMENT';
    static GET_COUPON_ASSIGNMENT = 'GET_COUPON_ASSIGNMENT';

    static SIDEBAR_PARTNER_COUPON = 'sidebar_Partner_Coupon';
    static SAVE_COUPON = 'SAVE_COUPON';
    static SAVE_COUPON_ASSIGNMENT = 'SAVE_COUPON_ASSIGNMENT';
    static CHANGE_COUPON_STATUS = 'CHANGE_COUPON_STATUS';
    static CHANGE_COUPON_ASSIGNMENT_STATUS = 'CHANGE_COUPON_ASSIGNMENT_STATUS';
    static GET_COUPON_STATUS = 'GET_COUPON_STATUS';
    static TAB_COUPON_STATUS = 'TAB_COUPON_STATUS';
    static BTN_COUPONS_GRID_DOWNLOADABLE_REPORTS = 'BTN_COUPONS_GRID_DOWNLOADABLE_REPORTS';
    /*END= Coupons*/

    /*BEGIN= Couons*/
    static TAB_ORGANIZATION_SETUP = 'TAB_ORGANIZATION_SETUP';

    static TAB_MANAGE_SITES = 'TAB_MANAGE_SITES';
    static TAB_MANAGE_DEPARTMENTS = 'TAB_MANAGE_DEPARTMENTS';

    static GET_SITES = 'GET_SITES';
    static ADD_CUSTOMER_SITE = 'ADD_CUSTOMER_SITE';
    static EDIT_CUSTOMER_SITE = 'EDIT_CUSTOMER_SITE';
    static DELETE_CUSTOMER_SITE = 'DELETE_CUSTOMER_SITE';
    static SAVE_CUSTOMER_SITE = 'SAVE_CUSTOMER_SITE';
    static ADD_SITE_ADDRESS = 'ADD_SITE_ADDRESS';
    static EDIT_SITE_ADDRESS = 'EDIT_SITE_ADDRESS';
    static DELETE_SITE_ADDRESS = 'DELETE_SITE_ADDRESS';
    static ADD_SITE_PHONE = 'ADD_SITE_PHONE';
    static EDIT_SITE_PHONE = 'EDIT_SITE_PHONE';
    static DELETE_SITE_PHONE = 'DELETE_SITE_PHONE';
    static ADD_SITE_EMAIL = 'ADD_SITE_EMAIL';
    static EDIT_SITE_EMAIL = 'EDIT_SITE_EMAIL';
    static DELETE_SITE_EMAIL = 'DELETE_SITE_EMAIL';

    static GET_DEPARTMENTS = 'GET_DEPARTMENTS';
    static ADD_CUSTOMER_DEPARTMENT = 'ADD_CUSTOMER_DEPARTMENT';
    static EDIT_CUSTOMER_DEPARTMENT = 'EDIT_CUSTOMER_DEPARTMENT';
    static DELETE_CUSTOMER_DEPARTMENT = 'DELETE_CUSTOMER_DEPARTMENT';
    static SAVE_CUSTOMER_DEPARTMENT = 'SAVE_CUSTOMER_DEPARTMENT';
    static ADD_DEPARTMENT_ADDRESS = 'ADD_DEPARTMENT_ADDRESS';
    static EDIT_DEPARTMENT_ADDRESS = 'EDIT_DEPARTMENT_ADDRESS';
    static DELETE_DEPARTMENT_ADDRESS = 'DELETE_DEPARTMENT_ADDRESS';
    static ADD_DEPARTMENT_PHONE = 'ADD_DEPARTMENT_PHONE';
    static EDIT_DEPARTMENT_PHONE = 'EDIT_DEPARTMENT_PHONE';
    static DELETE_DEPARTMENT_PHONE = 'DELETE_DEPARTMENT_PHONE';
    static ADD_DEPARTMENT_EMAIL = 'ADD_DEPARTMENT_EMAIL';
    static EDIT_DEPARTMENT_EMAIL = 'EDIT_DEPARTMENT_EMAIL';
    static DELETE_DEPARTMENT_EMAIL = 'DELETE_DEPARTMENT_EMAIL';
    /*END= Coupons*/

    /*BEGIN Reseller */
    static GET_RESELLERS = 'GET_RESELLERS';
    static BTN_UPDATE_RESELLER_CONFIGURATION = 'BTN_UPDATE_RESELLER_CONFIGURATION';

    /*END Reseller */

    /*BEGIN= ResellerPlan */
    static GET_RESELLER_PLANS = 'API_GET_RESELLER_PLANS';
    static SAVE_RESELLER_PLAN = 'BTN_SAVE_OR_UPDATE_RESELLER_PLAN';
    static GET_RESELLER_PRODUCTS = 'GET_ALL_PRODUCTS';
    static BTN_VIEW_RESELLER_PLAN_OFFERS = 'BTN_VIEW_RESELLER_PLAN_OFFERS';
    static BTN_ADD_RESELLER_PLAN = 'BTN_ADD_RESELLER_PLAN';
    static BTN_EDIT_RESELLER_PLAN = 'BTN_EDIT_RESELLER_PLAN';
    static CLONERESELLERPLAN = 'CLONE_RESELLER_PLAN';
    static RESELLER_USAGE_PLAN_MACRO = 'RESELLER_USAGE_PLAN_MACRO';

    /*BEGIN= Macros*/
    static MACRO_COPY_PARTNERT_PRICE = 'macro_copy_partnert_price';
    static MACRO_COPY_PROVIDER_SELLING_PRICE = 'macro_copy_provider_selling_price';
    static MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE = 'macro_apply_x_percent_on_partner_price';
    static MACRO_APPLY_X_PERCENT_ON_MARKUP = 'macro_apply_x_percent_on_markup';
    static MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE = 'macro_apply_x_percent_on_provider_selling_price';
    /*END= Macros*/

    /*BEGIN= Currency*/
    static BTN_SAVE_CURRENCY_CONVERSION = 'ACTION_SAVE_CURRENCY_CONVERSION_RATE';
    static BTN_DELETE_CURRENCY_CONVERSION = 'ACTION_DELETE_CURRENCY_CONVERSION_RATE';
    static SETTINGS_TAB_CURRENCY_CONVERSION = 'SETTINGS_TAB_CURRENCY_CONVERSION';
    /*END= Currency*/

    /*BEGIN= Entity*/
    static ENTITY_SITE = 'site';
    static ENTITY_SITEDEPARTMENT = 'sitedepartment';
    static ENTITY_PARTNER = 'partner';
    static ENTITY_RESELLER = 'reseller';
    static ENTITY_CUSTOMER = 'customer';
    static ENTITY_CUSTOMER_PRODUCT = 'customerproduct';
    static ENTITY_INVOICE = 'invoice';
    static ENTITY_CUSTOMOFFER = 'customoffer';
    static ENTITY_PLAN = 'plan';
    /*END= Entity*/

    /*BEGIN= Static values*/
    static STATIC_VALUE_TRUE = 'true';
    static STATIC_VALUE_FALSE = 'false';
    /*END= Static values*/

    /*BEGIN= Test Charge*/
    static TEST_PAYMENT = 'TEST_PAYMENT';

    /*END= Test Charge*/

    /*BEGIN= Download Invoice*/
    static DOWNLOAD_INVOICE_AND_PAYMENT_DETAILS = 'DOWNLOAD_INVOICE_AND_PAYMENT_DETAILS';
    /*END= Download Invoice*/

    /*BEGIN= Download Plans*/
    static BTN_PLAN_OFFERS_GRID_DOWNLOADABLE_REPORTS = 'BTN_PLAN_OFFERS_GRID_DOWNLOADABLE_REPORTS';
    static BTN_PLANS_GRID_DOWNLOADABLE_REPORTS = 'BTN_PLANS_GRID_DOWNLOADABLE_REPORTS';
    /*END= Download plans*/

    /*BEGIN= Post Invoices To ExternalService */
    static POSTINVOICESTOXERO = 'BTN_POST_INVOICES_TO_XERO';
    static VIEWEXTERNALSERVICEPOSTLOGS = 'BTN_VIEW_EXTERNAL_SERVICE_POST_LOGS';
    static IGNOREPOSTTOEXTERNALSERVICEERRORS = 'BTN_IGNORE_ERRORS';
    static POSTINVOICESTOQUICKBOOKSONLINE = 'BTN_POST_INVOICES_TO_QUICKBOOKS';
    static DOWNLOADPOSTINVOICESTOEXTERNALSERVICESSUMMARY = 'BTN_DOWNLOAD_POST_INVOICES_TO_EXTERNAL_SERVICES_SUMMARY';
    /*END*/

    /*BEGIN= Account Managers*/
    static SAVEORUPDATEACCOUNTMANAGER = 'SAVE_OR_UPDATE_ACCOUNT_MANAGER';
    static GETACCOUNTMANAGERSDETAILS = 'GET_ACCOUNT_MANAGERS_DETAILS';
    static ASSIGNCUSTOMERSTOACCOUNTMANAGERS = 'ASSIGN_CUSTOMERS_TO_ACCOUNT_MANAGERS';
    static UNASSIGNACCOUNTMANAGERCUSTOMERS = 'UNASSIGN_ACCOUNT_MANAGER_CUSTOMERS';
    static GETACCOUNTMANAGERDETAILSOFCUSTOMER = 'GET_ACCOUNT_MANAGER_DETAILS_OF_CUSTOMER';
    /*END*/

    /*BEGIN= profile*/
    static TABCONFIGURATIONSMANAGEDBYCUSTOMER = 'TAB_CONFIGURATIONS_MANAGED_BY_CUSTOMER';
    static TABCONFIGURATIONSMANAGEDBYRESELLER = 'TAB_CONFIGURATIONS_MANAGED_BY_RESELLER';
    static UPDATECONFIGURATIONSMANAGEDBYCUSTOMER = 'UPDATE_CONFIGURATIONS_MANAGED_BY_CUSTOMER';
    static UPDATECONFIGURATIONSMANAGEDBYRESELLER = 'UPDATE_CONFIGURATIONS_MANAGED_BY_RESELLER';
    /*END*/

    /*BEGIN= Product Extensions*/
    static DOWNLOADPRODUCTCATALOGUEFORCODES = 'DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES';
    static STAGEANDVALIDATEPRODUCTCATALOGUE = 'STAGE_AND_VALIDATE_PRODUCT_CATALOGUE';
    /*END*/

    /*BEGIN= Seat Limits*/
    static GETSEATLIMIT = 'GET_SEAT_LIMIT';
    static UPDATESEATLIMIT = 'UPDATE_SEAT_LIMIT';
    /*END*/

    /*BEGIN= Purchase Requests*/
    static GETPENDINGPURCHASEREQUESTS = 'GET_PENDING_PURCHASE_REQUESTS';
    static UPDATEPENDINGPURCHASEREQUESTS = 'UPDATE_PENDING_PURCHASE_REQUESTS';
    /*END*/

    /*BEGIN= Report Usage*/
    static DOWNLOADPRODUCTSCATALOGUE = 'DOWNLOAD_PRODUCTS_CATALOGUE';
    static DOWNLOADPRODUCTSTEMPLATE = 'DOWNLOAD_PRODUCTS_TEMPLATE';
    static STAGEANDVALIDATEPARTNEROFFERDAILYUSAGEDATA = 'STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA';
    /*END*/

    /*BEGIN= Reports*/
    static VIEW_LICENSE_CONSUMPTION_REPORT = 'VIEW_LICENSE_CONSUMPTION_REPORT';
    static EXPORT_LICENSE_CONSUMPTION_REPORT = 'EXPORT_LICENSE_CONSUMPTION_REPORT';
    static EXPORT_BILLING_FORECAST_REPORT = 'BTN_DOWNLOAD_BILLING_FORECAST_REPORT';
    static EXPORT_INVOICE_LINE_ITEMS_WITH_TAX_REPORT = 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_WITH_TAX_REPORT';
    static EXPORT_CUSTOMER_CONSENT_ACCEPTANCE_REPORT = 'BTN_DOWNLOAD_CUSTOMER_CONSENT_ACCEPTANCE_REPORT';
    static EXPORT_CUSTOMER_TEARMS_AND_CONDITIONS_ACCEPTANCE_REPORT = 'BTN_DOWNLOAD_CUSTOMER_TEARMS_AND_CONDITIONS_ACCEPTANCE_REPORT';
    static EXPORT_AZURE_BILLING_COMPARISON_REPORT = 'BTN_DOWNLOAD_AZURE_BILLING_COMPARISON_REPORT';
    static VIEW_LICENSE_CHANGE = 'VIEW_LICENSE_CHANGE';
    static VIEW_PURCHASED_PRODUCTS_REPORT = 'VIEW_PURCHASED_PRODUCTS_REPORT';

    /*END= Reports*/

    /*BEGIN= Settings*/
    static PSA_NAME_AUTOTASK = 'autotask';
    static PSA_NAME_CONNECTWISE = 'connectwise';
    static INVOICE_LINE_ITEM_ADDITIONTYPE = 'Invoice_Line_Item_Push';
    static SINGLE_LINE_ADDITIONTYPE = 'Single_Line(Per_Subscription_Proration)';
    static DUAL_LINE_ADDITIONTYPE = 'Dual_Line(Per_Subscription_Separate_Proration)';
    static EXTERNAL_SERVICE_JOB_TYPE_VALIDATE = 'Validate';
    /*END= Settings*/

    /*BEGIN= Event emailnotification*/
    static VIEW_EVENT_EMAIL_NOTIFICATION = 'VIEW_EVENT_EMAIL_NOTIFICATION';
    static ADD_AND_EDIT_EVENT_EMAIL_NOTIFICATION = 'ADD_AND_EDIT_EVENT_EMAIL_NOTIFICATION';
    static DELETE_EVENT_EMAIL_NOTIFICATION = 'DELETE_EVENT_EMAIL_NOTIFICATION';
    /*END= Event email notification*/

    /*BEGIN= General Settings*/
    static GET_GENERAL_SETTINGS = 'GET_GENERAL_SETTINGS';
    static SAVE_GENERAL_SETTINGS = 'SAVE_GENERAL_SETTINGS';
    /*END= General Settings*/

    /*BEGIN= Provider Settings*/
    static GET_PROVIDER_SETTINGS = 'GET_PROVIDER_SETTINGS';
    static SAVE_PROVIDER_SETTINGS = 'SAVE_PROVIDER_SETTINGS';
    /*END= Provider Settings*/

    /*BEGIN= Logo Settings*/
    static GET_LOGO_SETTINGS = 'GET_LOGO_SETTINGS';
    static SAVE_LOGO_SETTINGS = 'SAVE_LOGO_SETTINGS';
    /*END= Logo Settings*/

    /*BEGIN= SMTP Settings*/
    static GET_SMTP_SETTINGS = 'GET_SMTP_SETTINGS';
    static SAVE_SMTP_SETTINGS = 'SAVE_SMTP_SETTINGS';
    /*END= SMTP Settings*/

    /*BEGIN= Billing Settings*/
    static GET_BILLING_SETTINGS = 'GET_BILLING_SETTINGS';
    /*END= Billing Settings*/

    /*BEGIN= Change Plan Settings*/
    static GET_CUSTOMER_SOURCE_PLANS = 'GET_CUSTOMER_SOURCE_PLANS';
    static GET_TARGET_CHANGE_PLANS = 'GET_TARGET_CHANGE_PLANS';
    static GET_MATCHING_PLAN_PRODUCTS_FOR_PLAN_CHANGE = 'GET_MATCHING_PLAN_PRODUCTS_FOR_PLAN_CHANGE';
    static CHANGE_PURCHASED_PRODUCTS_PLAN = 'CHANGE_PURCHASED_PRODUCTS_PLAN';
    static VALIDATE_PLAN_PRODUCTS_FOR_PLAN_CHANGE = 'VALIDATE_PLAN_PRODUCTS_FOR_PLAN_CHANGE';
    /*END= Change Plan Settings*/

    /*BEGIN= Product catalogue*/
    static GET_PRODUCT_CATALOGUE = 'GET_PRODUCT_CATALOGUE';
    /*END= Product catalogue*/

    /*BEGIN= Invoice On Demand*/
    static CREATE_INVOICE = 'BTN_CREATE_INVOICE';
    static EDIT_INVOICE = 'BTN_EDIT_INVOICE';
    static GET_CUSTOMERS_REVENUE = 'GET_CUSTOMERS_REVENUE';
    static GET_INVOICE_DETAILS = 'BTN_VIEW_INVOICE_DETAILS';
    static TAB_INVOICE_PAYMENTS = 'TAB_INVOICE_PAYMENTS';
    static BTN_DOWNLOAD_PREVIOUS_INVOICE_DUES = 'VIEW_PREVIOUS_INVOICE_DUES';
    static GENERATE_CSV_FOR_PROFITABILITY_REPORT= 'BTN_DOWNLOAD_PROFITABILITY_REPORT';
    static GET_TAX_AMOUNTS_SUMMARY_REPORT= 'BTN_DOWNLOAD_TAX_SUMMARY_REPORT';
    static GET_TAX_AMOUNTS_SUMMARY_BY_SUBSCRIPTION_REPORT= 'BTN_DOWNLOAD_TAX_LINE_ITEMS_REPORT';
    static GET_INVOICE_SUMMARY_REPORT= 'BTN_DOENLOAD_INVOICE_SUMMARY_REPORT';
    static GET_INVOICE_LINE_ITEMS_REPORT= 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_REPORT';
    static DOWNLOADINVOICELINEITEMSWITHCOMMISSIONS= 'BTN_DOWNLOAD_INVOICE_LINE_ITEMS_WITH_COMMISSIONS';
    static GET_MONTHLY_TURNOVER_REPORT= 'BTN_DOWNLOAD_MONTHLY_TURNOVER_REPORT';
    static GETPROFITABILITY= 'PARTNER_DASH_BOARD_VIEW_PROFITABILITY';
    static GET_INVOICES= 'BTN_VIEW_INVOICES';
    static GET_SUBSCRIPTION_HISTORY= 'BTN_VIEW_SUBSCRIPTION_HISTORY';
    static BTN_DOWNLOAD_INVOICES_OR_PAYMENTS= 'BTN_DOWNLOAD_INVOICES_OR_PAYMENTS';
    static BTN_PROVIDER_PAYMENT_DETAILS= 'BTN_PROVIDER_PAYMENT_DETAILS';
    static BTN_GENERATE_INVOICES= 'BTN_GENERATE_INVOICES';
    static BTN_RELOAD_PROFITABILITY_REPORT= 'BTN_RELOAD_PROFITABILITY_REPORT';
    static DOWNLOAD_PROFITABILITY_REPORT_BY_TENANT= 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_TENANT';
    static DOWNLOAD_PROFITABILITY_REPORT_BY_PRODUCT= 'BTN_DOWNLOAD_PROFITABILITY_REPORT_BY_PRODUCT';
    static COST_ON_PARTNER = 'COST_ON_PARTNER';
    static PROFIT= 'PROFIT';
    static GETCUSTOMERANDRESELLERDETAILSFORBULKINVOICEDOWNLOAD = 'GET_CUSTOMER_AND_RESELLER_DETAILS_FOR_BULK_INVOICE_DOWNLOAD';
    static PROFIT_PERCENTAGE= 'PROFIT_PERCENTAGE';
    static BTN_INVOICELINEITEM_GRID_DOWNLOADABLE_REPORTS= 'BTN_INVOICELINEITEM_GRID_DOWNLOADABLE_REPORTS';
    static GET_RESELLER_REVENUE = 'GET_RESELLER_REVENUE';
    /*END= Invoice On Demand*/

    /*BEGIN= Product Commissions*/
    static DOWNLOADPRODUCTCOMMISSIONSCATALOGUE = 'DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE';
    static IMPORTPRODUCTCOMMISSIONS = 'IMPORT_PRODUCT_COMMISSIONS';
    /*END= Product Commissions*/

    /*BEGIN= Connectwise Maping*/
    static UPDATE_PURCHSED_PRODUCT_MAPPING = 'UPDATE_PURCHSED_PRODUCT_MAPPING';
    /*END= Connectwise Maping*/

    /*BEGIN= webNotification*/
    static side_bar_banner_manage = 'side_bar_banner_manage';
    /*END= webNotification*/

    /*BEGIN= engageNotification*/
    static side_bar_administration_engage = 'side_bar_administration_engage';
    /*END= engageNotification*/

    /*BEGIN= customNotification*/
    static side_bar_administration_custom_notifications = 'side_bar_administration_custom_notifications';
    static VIEW_UI_NOTIFICATION = 'VIEW_UI_NOTIFICATION';
    static SAVE_UI_NOTIFICATION = 'SAVE_UI_NOTIFICATION';
    static DELETE_UI_NOTIFICATION = 'DELETE_UI_NOTIFICATION';
    /*END= customNotification*/

    /*BEGIN = WebhookNotification*/
    static MENUADMINISTRATIONWEBHOOKNOTIFICATIONS = 'MENU_ADMINISTRATION_WEBHOOK_NOTIFICATIONS';
    static GETWEBHOOKNOTIFICATIONDETAILS = 'GET_WEBHOOK_NOTIFICATION_DETAILS';
    static SAVEORUPDATEWEBHOOKNOTIFICATIONDETAILS = 'SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_DETAILS';
    static DELETEWEBHOOKNOTIFICATIONDETAILS = 'DELETE_WEBHOOK_NOTIFICATION_DETAILS';
    /*END = WebhookNotification*/

    /*BEGIN= c3support*/
    static SIDEBAR_C3_SUPPORT = 'SIDEBAR_C3_SUPPORT';
    /*END= c3support*/

    /*BEGIN= nce promotions*/
    static ARE_NCE_PROMOTIONS_ENABLED = 'ARE_NCE_PROMOTIONS_ENABLED';
    static GET_PENDING_PAYMENT_STATUS = 'GET_PENDING_PAYMENT_STATUS';
    static BTN_LOAD_PROMOTIONS = 'BTN_LOAD_PROMOTIONS';
    static FILTER_SHOW_PROMOTION_OFFER = 'FILTER_SHOW_PROMOTION_OFFER';
    /*END= nce promotions*/

    /*BEGIN= Profile */
    static BTN_SAVE_CUTOMER_PAYMENT_PROFILE = 'BTN_SAVE_CUTOMER_PAYMENT_PROFILE';
    /*END= Profile*/

    /*END= Settings*/

    /*BEGIN= DOCUMENTATION*/
    static MENU_PARTNER_DOCUMENTATION = 'MENU_PARTNER_DOCUMENTATION';
    /*END= DOCUMENTATION*/

    /*BEGIN= SKU UPGRADE*/
    static BTN_UPGRADE_PRODUCT = 'BTN_UPGRADE_PRODUCT';
    static GET_UPGRADE_PLAN_PRODUCTS = 'GET_UPGRADE_PLAN_PRODUCTS';
    static GET_UPGRADE_TRANSITION = 'GET_UPGRADE_TRANSITION';
    static GET_UPGRADE_STATUS = 'GET_UPGRADE_STATUS';
    /*END= SKU UPGRADE*/


        /*BEGIN: Quote List*/
            static QUOTE_LIST_STATUS_PUBLISHED = 'Published';
            static QUOTE_LIST_STATUS_APPROVED = 'Approved';
            static QUOTE_LIST_STATUS_EXPIRED = 'Expired';
            static QUOTE_LIST_STATUS_DRAFT = 'Draft';
            static QUOTE_LIST_STATUS_DELETED = 'Deleted';
            static QUOTE_LIST_STATUS_ORDER_PROCESSED = 'Order Processed';
         /*END: Quote List*/

        /*BEGIN: Quote PDF*/
          static  PDF_LOGO = 'PDFLogo';
         /*END: Quote PDF*/
        
    /*BEGIN= EMAIL TEMPLATE*/
    static VIEW_EVENT_EMAIL_TEMPLATES = 'VIEW_EVENT_EMAIL_TEMPLATES';
    static SAVE_EVENT_EMAIL_TEMPLATES = 'SAVE_EVENT_EMAIL_TEMPLATES';
    /*END= EMAIL TEMPLATE*/
        /*BEGIN: Quote details*/
        static  GETQUOTELIST = 'GET_QUOTE_LIST';
        static  DELETEQUOTE = 'DELETE_QUOTE';
        static  SAVEQUOTE = 'SAVE_QUOTE';
        static  APPROVEQUOTE = 'APPROVE_QUOTE';
        /*END: Quote details */

        static QUOTE_CUSTOMER_ADMIN = 'CustomerAdmin';

    /*subscription expiry, renewal settings*/
    static EXPIRY_RENEWAL_SETTINGS_ELEMENT = 'EXPIRY_RENEWAL_SETTINGS_ELEMENT';

           

    /*BEGIN= SCHEDULING CHANGES*/
    static SHOW_NEXT_SCHEDULE_RENEWAL_MENU = 'SHOW_NEXT_SCHEDULE_RENEWAL_MENU';
    static BTN_CREATE_SCHEDULE_RENEWAL_CHANGES = 'BTN_CREATE_SCHEDULE_RENEWAL_CHANGES';
    static BTN_UPDATE_SCHEDULE_RENEWAL_CHANGES = 'BTN_UPDATE_SCHEDULE_RENEWAL_CHANGES';
    static BTN_CANCEL_SCHEDULE_RENEWAL_CHANGES = 'BTN_CANCEL_SCHEDULE_RENEWAL_CHANGES';


    /*BEGIN= Distributed offers*/
    static UPDATE_DISTRIBUTOR_OFFERS = 'UPDATE_DISTRIBUTOR_OFFERS';
    static ADD_DISTRIBUTOR_OFFERS = 'ADD_DISTRIBUTOR_OFFERS';
    static EDIT_DISTRIBUTOR_OFFERS = 'EDIT_DISTRIBUTOR_OFFERS';
    static DELETE_DISTRIBUTOR_OFFERS = 'DELETE_DISTRIBUTOR_OFFERS';
    static BTN_ADD_DISTRIBUTOR_OFFERS = 'BTN_ADD_DISTRIBUTOR_OFFERS';

    /*END= Distributed offers*/

    /*BEGIN = USER CONFIGURATION*/
    static USER_CONFIGURATION_GRID_LIST_VIEW = 'gridorlistview';
    /*END= SCHEDULING CHANGES*/

    /* BEGIN = Customer consent on subscription renewal*/
    static CUSTOMER_DASH_BOARD_VIEW_RENEWAL_SUBSCRIPTIONS = 'CUSTOMER_DASH_BOARD_VIEW_RENEWAL_SUBSCRIPTIONS';
    static BTN_ACCEPT_REJECT_CUSTOMER_CONSENT = 'BTN_ACCEPT_REJECT_CUSTOMER_CONSENT';
    /* END = Customer consent on subscription renewal*/

    /*BEGIN= CUSTOMER TRANSACTION AMOUNT LIMIT REPORT*/
    static EXPORT_TRANSACTION_AMOUNT_LIMIT_REPORT = 'EXPORT_TRANSACTION_AMOUNT_LIMIT_REPORT';
    /*END=  CUSTOMER TRANSACTION AMOUNT LIMIT REPORT*/

     /*BEGIN= CUSTOMER SYNC PROFILE*/
     static SYNC_MICROSOFT_CUSTOMER_CONSENT = 'SYNC_MICROSOFT_CUSTOMER_CONSENT';
     static SYNC_PROVIDER_CUSTOMER_PROFILE = 'BTN_SYNC_PROVIDER_CUSTOMER_PROFILE';
     /*END=  CUSTOMER SYNC PROFILE*/
 

    /*BEGIN= SHOW TAX IN ORDER SUMMARY*/
    static VIEW_SHOW_TAX_IN_CART = 'VIEW_SHOW_TAX_IN_CART';
    /*END=  SHOW TAX IN ORDER SUMMARY*/

    /*BEGIN= EXPORTABLE SKUs  REPORT FOR CUSTOMER*/
    static CUSTOMER_EXPORTABLE_SKU_REPORT = 'CUSTOMER_EXPORTABLE_SKU_REPORT';
    /*END=  EXPORTABLE SKUs REPORT FOR  CUSTOMER*/

    /*BEGIN= EXPORTABLE SKUs  REPORT FOR PARTNER*/
    static PARTNER_EXPORTABLE_SKU_REPORT = 'PARTNER_EXPORTABLE_SKU_REPORT';
    /*END=  EXPORTABLE SKUs REPORT FOR  PARTNER*/

    /*BEGIN= SHOW SPECIAL QUALIFICATIONS*/
    static SHOW_SPECIAL_QUALIFICATIONS = 'SHOW_SPECIAL_QUALIFICATIONS';
    /*END= SHOW SPECIAL QUALIFICATIONS*/

    static SIDEBAR_PARTNER_MICROSOFT_AZURE_ADVISOR = 'sidebar_Partner_Microsoft_Azure_Advisor';
    static TEXT_BOX_PO_NUMBER_IN_HISTORY = 'TEXT_BOX_PO_NUMBER_IN_HISTORY';
    static ACCESS_TYPE_ALLOWED = 'allowed';
    static ACCESS_TYPE_DENIED = 'denied';
    static ACCESS_TYPE_READONLY = 'readonly';
    static BTN_MANAGE_PRODUCT = 'BTN_MANAGE_PRODUCT';

    /*BEGIN :: CUSTOMER ROLES*/
    static ROLE_NAME_SYSTEM_ADMIN = 'systemadmin';
    /// <summary>
    /// Save microsoft customer consent
    /// </summary>
    static SAVEMICROSOFTCUSTOMERCONSENT = "BTN_SAVE_MICROSOFT_CUSTOMER_CONSENT";
    static ROLE_NAME_CUSTOMER_ADMIN = 'customeradmin';
    static ROLE_NAME_C3_SUPPORT = 'c3support';
    static ROLE_NAME_SYSTEM_READER = 'systemreader';
    static ROLE_NAME_FINANCE_ADMIN = 'financeadmin';
    static ROLE_NAME_HELP_DESK = 'helpdesk';
    /// <summary>
    /// button for Update Tenant action
    /// </summary>
    static BUTTONUPDATEDEFAULTTENANT = "BUTTON_UPDATE_DEFAULT_TENANT";
    static ROLE_NAME_CUSTOMER_READER = 'customerreader';
    static ROLE_NAME_GROUP_ADMIN = 'groupadmin';
    static ROLE_NAME_SITE_ADMIN = 'siteadmin';
    static ROLE_NAME_SITE_READER = 'sitereader';
    static ROLE_NAME_DEPARTMENT_ADMIN = 'departmentadmin';
    static ROLE_NAME_DEPARTMENT_READER = 'departmentreader';
    static ROLE_NAME_CUSTOMER_ADMIN_LITE = 'customeradminlite';
    static ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS = 'customeradminliteplus';
    /*END :: CUSTOMER ROLES*/

    //Azure plan manage 
    static SAVE_CUSTOMER_PRODUCTS = 'BTN_SAVE_PRODUCT_CHANGES';
    static GET_AZURE_GROUPS = 'GET_AZURE_GROUPS';
    static ACTION_AZURE_STATUS_CHANGE = 'ACTION_AZURE_STATUS_CHANGE';
    static ACTION_UPGRADE_AZURE_SUBSCRIPTION = 'ACTION_UPGRADE_AZURE_SUBSCRIPTION';

    //Usage manage
    static REACTIVATE_CUSTOMER_PRODUCT_SUBSCRIPTION = 'REACTIVATE_CUSTOMER_PRODUCT_SUBSCRIPTION';
    static SUSPEND_CUSTOMER_PRODUCT_SUBSCRIPTION = 'SUSPEND_CUSTOMER_PRODUCT_SUBSCRIPTION'
    /// <summary> 

    static GET_CONTACT_LOGS = 'GET_CONTACT_LOGS';
    static ALL_COMMENTS = 'ALL_COMMENTS';
    static menu_customer_comments = 'menu_customer_comments';

    /* BEGIN INVOICE LINE ITEMS */
    static DOWNLOAD_INVOICE_PDF = 'BTN_DOWNLOAD_INVOICE_PDF';
    static DOWNLOAD_INVOICE_CSV = 'BTN_DOWNLOAD_INVOICE_CSV';
    static BTN_ADD_ADJUSTMENTS = 'BTN_ADD_ADJUSTMENTS';
    static BTN_INITIATE_PAYMENT = 'BTN_INITIATE_PAYMENT';
    static ACTION_UPDATE_INVOICE_STATUS = 'ACTION_UPDATE_INVOICE_STATUS';
    static BTN_SAVE_PAYMENT = 'BTN_SAVE_PAYMENT';
    static RESENDINVOICE = 'BTN_RESEND_INVOICE';
    static UPDATE_DUEDATE = 'UPDATE_DUEDATE';
    static UPDATE_PO_NUMBER = 'UPDATE_PO_NUMBER';
    static UPDATE_SUBSCRIPTION_PO_NUMBER = 'UPDATE_SUBSCRIPTION_PO_NUMBER';
    static GET_PREVIOUS_INVOICE_DUES = 'BTN_DOWNLOAD_PREVIOUS_INVOICE_DUES';

    /* END INVOICE LINE ITEMS */

    static ADDUSAGEBASEDPARTNEROFFER = 'ADD_USAGE_BASED_PARTNER_OFFER';
    static GET_PARTNER_TRIAL_OFFER_FILTER = 'GET_PARTNER_TRIAL_OFFER_FILTER';
    static CREATE_PARTNER_TRIAL_OFFER = 'CREATE_PARTNER_TRIAL_OFFER';
    static BTN_ADD_CONTRACT_OFFER = 'BTN_ADD_CONTRACT_OFFER';
    static GETPRODUCTTAGS = 'GET_PRODUCT_TAGS';
    static EDIT_PARTNER_OFFER = 'BTN_EDIT_PARTNER_OFFER';
    static SAVE_OR_UPDATE_PARTNER_OFFER = 'BTN_SAVE_OR_UPDATE_PARTNER_OFFER';
    static BTN_DELETE_PARTNER_OFFER = 'BTN_DELETE_PARTNER_OFFER';
    static BTN_ADD_PARTNER_OFFER = 'BTN_ADD_PARTNER_OFFER';
    static BTN_EDIT_CONTRACT_OFFER = 'BTN_EDIT_CONTRACT_OFFER';
    static BTN_DELETE_CONTRACT_OFFER = 'BTN_DELETE_CONTRACT_OFFER';






    //azure estimate rbac
    static ACTION_DOWNLOAD_AZURE_ESTIMATE_REPORT = 'ACTION_DOWNLOAD_AZURE_ESTIMATE_REPORT';
    static BTN_DOWNLOAD_AZURE_ESTIMATE_REPORT_BY_TAGS = "BTN_DOWNLOAD_AZURE_ESTIMATE_REPORT_BY_TAGS";
    //Azure plan entitlement users
    static BTN_ADD_USERS_TO_SUBSCRIPTIONS = 'BTN_ADD_USERS_TO_SUBSCRIPTIONS';
    static BTN_ASSIGN_ACCESS_FOR_USER = 'BTN_ASSIGN_ACCESS_FOR_USER';
    static BTN_REVOKE_ACCESS_FOR_USER = 'BTN_REVOKE_ACCESS_FOR_USER';

    static PLANS_MANIPULATE_SALE_PRICE_LOCK = "MANIPULATE_SALE_PRICE_LOCK";

    static CUSTOM_PARTNER_TERMS_AND_CONDITIONS_HYPERLINK = "CustomPartnerTermsAndConditionsHyperlink";
    static CUSTOMER_PRODUCT_AUTO_RENEW_STATUS = 'CUSTOMER_PRODUCT_AUTO_RENEW_STATUS';

    //public signup cart
    static MANAGE_PRODUCT_APPROVAL = "MANAGE_PRODUCT_APPROVAL";
    static SUBSCRIPTION_END_DATE_ALIGNMENT = "SUBSCRIPTION_END_DATE_ALIGNMENT";
    static DOES_ENABLE_AZURE_SEARCH = "false"; //need to fetch from API
    static DateFormat = 'MMM dd, yyyy'; //need to fetch from API

    static RESELLER_PLANS_MANIPULATE_PARTNER_PRICE_LOCK = "MANIPULATE_PARTNER_PRICE_LOCK";
    static BTN_DEFINE_RESELLER_PLAN_IN_OTHER_CURRENCY= 'BTN_DEFINE_RESELLER_PLAN_IN_OTHER_CURRENCY';
    static SAVE_RESELLER_PLAN_WITH_ALL_OFFERS= 'SAVE_RESELLER_PLAN_WITH_ALL_OFFERS';
    static RESELLER_PLAN_CURRENCY_ATTRIBUTES= 'RESELLER_PLAN_CURRENCY_ATTRIBUTES'
    static SAVE_PLAN= 'BTN_SAVE_OR_UPDATE_PLAN';
    static ACCESS_USER_LICENSE_TRACKING_VIEW = 'ACCESS_USER_LICENSE_TRACKING_VIEW'
    static DOWNLOADONBOARDEDCUSTOMERSREPORT = 'DOWNLOAD_ONBOARDED_CUSTOMERS_REPORT';
    static ASSIGN_PROVIDER_LICENSES_TO_PROVIDER_CUSTOMER_USER = 'BTN_ASSIGN_PROVIDER_LICENSES_TO_PROVIDER_CUSTOMER_USER';
    static UPDATE_PARTNER_OFFER_PROVISIONING_STATUS = 'BTN_UPDATE_PARTNER_OFFER_PROVISIONING_STATUS';
    static ADD_CUSTOMER = 'BTN_ADD_CUSTOMER';
    static CUSTOMER_ONBOARDING = 'BTN_ONBOARD_SINGLE_CUSTOMER';


    static CLONE_PLAN = 'CLONE_PLAN';
    static VIEW_OFFERS = 'ACTION_GET_PLAN_OFFERS';
    static PLAN_CURRENCY_ATTRIBUTES = 'PLAN_CURRENCY_ATTRIBUTES';
    static SAVE_PLAN_WITH_ALL_OFFERS = 'SAVE_PLAN_WITH_ALL_OFFERS';
    static PUBLIC_SIGNUP = 'PUBLIC_SIGNUP';
    static BTN_CUSTOMER_NAME_CHANGE = 'BTN_CUSTOMER_NAME_CHANGE';
    static BTN_RESELLER_NAME_CHANGE = 'BTN_RESELLER_NAME_CHANGE';
    static GET_PRODUCTS = 'GET_ALL_PRODUCTS';
    static LINK_PRODUCT = 'BTN_LINK_PRODUCT';
    static TAB_TAXES = 'TAB_TAXES';
    static GET_USERS_FOR_ENTITY = 'GET_USERS_FOR_ENTITY';
    static GET_CUSTOM_VIEW_SETTINGS = 'GET_CUSTOM_VIEW_SETTINGS';
    static GET_CONNECTWISE_SETTINGS = 'GET_CONNECTWISE_SETTINGS';
    static GET_AUTOTASK_SETTINGS = 'GET_AUTOTASK_SETTINGS';
    static ADD_OR_UPDATE_TAGS= 'BTN_ADD_OR_UPDATE_TAGS';
    static VIEW_CART = 'BTN_VIEW_CART';
    static DELETE_TAGS ='BTN_DELETE_TAGS';


     /*BEGIN = External service (PSA) */
     static GET_CUSTOMERS_LIST_TO_PICK_FOR_EXTERNAL_SERVICE = 'GET_CUSTOMERS_LIST_TO_PICK_FOR_EXTERNAL_SERVICE';
     static UPLOAD_TO_AUTOTASK = 'UPLOAD_TO_AUTOTASK';
     static UPLOAD_TO_CONNECTWISE = 'UPLOAD_TO_CONNECTWISE';
     static VALIDATE_UPLOAD_TO_EXTERNAL_SERVICE = 'VALIDATE_UPLOAD_TO_EXTERNAL_SERVICE';
     static UPLOAD_TO_EXTERNAL_SERVICE = 'UPLOAD_TO_EXTERNAL_SERVICE';
     static GET_EXTERNAL_SERVICE_POST_LOGS = 'GET_EXTERNAL_SERVICE_POST_LOGS';
     static GET_ENTITY_AND_PRODUCT_MAPPING = 'GET_ENTITY_AND_PRODUCT_MAPPING';
     static MANAGE_ENTITY_AND_PRODUCT_MAPPING = 'MANAGE_ENTITY_AND_PRODUCT_MAPPING';
     static DEACTIVATE_PSA_LOG = 'DEACTIVATE_PSA_LOG';
     static REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL = 'REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL';
     static BULK_MANAGE_ENTITY_AND_PRODUCT_MAPPING = 'BULK_MANAGE_ENTITY_AND_PRODUCT_MAPPING';
     /*END = External service (PSA) */

     static BTN_BUNDLE_GRID_DOWNLOADABLE_REPORTS = 'BTN_BUNDLE_GRID_DOWNLOADABLE_REPORTS';
 

}
