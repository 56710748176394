<!--begin::Logo image-->
  <ng-container *ngIf="currentLayoutType === 'dark-sidebar'">
    <img alt="Logo" [height]="45" *ngIf="sideMenuHeaderdata?.LoggedInLogoPath" [src]="sideMenuHeaderdata?.LoggedInLogoPath" class="object-fill w-150px app-sidebar-logo-default" (error)="handleImageError($event)" />
    <img alt="Logo" [height]="45" *ngIf="!sideMenuHeaderdata?.LoggedInLogoPath || imgLoadingFailed" [src]="defaultLogo" class="object-fill w-150px app-sidebar-logo-default" />
  </ng-container>
  <ng-container *ngIf="currentLayoutType !== 'dark-sidebar'">
    <img alt="Logo" [src]="sideMenuHeaderdata?.LoggedInLogoPath" [height]="45" class="object-fill w-150px app-sidebar-logo-default theme-light-show" />
    <img alt="Logo" [src]="sideMenuHeaderdata?.LoggedInLogoPath" [height]="45"
      class="app-sidebar-logo-default theme-dark-show" />
  </ng-container>
  <ng-container *ngIf="toggleEnabled">
    <!--begin::Sidebar toggle-->
    <div id="kt_app_sidebar_toggle"
      class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
      [ngClass]="toggleButtonClass" data-kt-toggle="true" [attr.data-kt-toggle-state]="toggleState"
      data-kt-toggle-target="body" [attr.data-kt-toggle-name]="toggleAttr">
      @if(iconClicked){
        <app-keenicon name="double-left" class="fs-2 rotate-180" (click)="Clickedicon()"></app-keenicon>
      }
      @else{
        <app-keenicon name="double-left" class="fs-2 rotate-180" (click)="Clickedicon()"></app-keenicon>
      }
      
    </div>
    <!--end::Sidebar toggle-->
  </ng-container>

<!--end::Logo image-->



