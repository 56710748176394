<!-- begin::Menu toggle -->
<div class="btn btn-icon" [ngClass]="toggleBtnClass" data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
  data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
  <ng-container *ngIf="mode$ | async as mode">
    <ng-container *ngIf="mode === 'dark'">
      <!-- <span [inlineSVG]="'./assets/media/icons/duotune/general/gen061.svg'" [ngClass]="toggleBtnIconClass"
      class="svg-icon theme-light-hide"></span> -->
      <i class="ki-outline ki-moon" [ngClass]="toggleBtnIconClass"></i>
    </ng-container>
    <ng-container *ngIf="mode === 'light'">
      <!-- <span [inlineSVG]="'./assets/media/icons/duotune/general/gen060.svg'" [ngClass]="toggleBtnIconClass"
      class="svg-icon theme-dark-hide"></span> -->
      <i class="ki-outline ki-sun text-primary" [ngClass]="toggleBtnIconClass"></i>

    </ng-container>
  </ng-container>
</div>
<!-- end::Menu toggle -->

<!-- begin::Menu -->
<div
  class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
  data-kt-menu='true'>
  <ng-container *ngIf="menuMode$ | async as menuMode">
    <!-- begin::Menu item  -->
    <div class='menu-item px-3 my-0'>
      <a class="menu-link px-3 py-2" [ngClass]="{'active': menuMode === 'light'}" (click)="switchMode('light')">
        <span class='menu-icon' data-kt-element='icon'>
          <!-- <app-keenicon name="night-day" class="fs-3"></app-keenicon> -->
           <i class="ki-outline ki-night-day fs-3"></i>
        </span>
        <span class='menu-title'>Light</span>
      </a>
    </div>
    <!-- end::Menu item -->

    <!-- begin::Menu item  -->
    <div class='menu-item px-3 my-0'>
      <a class="menu-link px-3 py-2" [ngClass]="{'active': menuMode === 'dark'}" (click)="switchMode('dark')">
        <span class='menu-icon' data-kt-element='icon'>
          <!-- <app-keenicon name="moon" class="fs-3"></app-keenicon> -->
          <i class="ki-outline ki-moon fs-3"></i>
        </span>
        <span class='menu-title'>Dark</span>
      </a>
    </div>
    <!-- end::Menu item -->

    <!-- begin::Menu item  -->
    <!-- <div class='menu-item px-3 my-0'>
      <a class="menu-link px-3 py-2" [ngClass]="{'active': menuMode === 'system'}" (click)="switchMode('system')">
        <span class='menu-icon' data-kt-element='icon'>
          <app-keenicon name="screen" class="fs-3"></app-keenicon>
        </span>
        <span class='menu-title'>System</span>
      </a>
    </div> -->
    <!-- end::Menu item -->

  </ng-container>

</div>
<!-- end::Menu -->
