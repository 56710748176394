const customTemplateTypes = [
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1",
        type: "Template-1",
        defaultBtnColor: "#F64E60"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2",
        type: "Template-2",
        defaultBtnColor: "#F64E60"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3",
        type: "Template-3",
        defaultBtnColor: "#FFA800"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4",
        type: "Template-4",
        defaultBtnColor: "#0BB7AF"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5",
        type: "Template-5",
        defaultBtnColor: "#0BB7AF"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6",
        type: "Template-6",
        defaultBtnColor: "#FFA800"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7",
        type: "Template-7",
        defaultBtnColor: "#F64E60"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8",
        type: "Template-8",
        defaultBtnColor: "#F64E60"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9",
        type: "Template-9",
        defaultBtnColor: "#F64E60"
    },
    {
        title: "TRANSLATE.CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2",
        description: "TRANSLATE.CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10",
        type: "Template-10",
        defaultBtnColor: "#F64E60"
    },
];

export {customTemplateTypes };