export enum SellDirectRoutes {
    customers = '/partner/customers',
    plans = '/partner/plans',
    partnerOffers ='/partner/customoffer/offers',
    provisioning = '/partner/customoffer/provisioning',
    bundles = '/partner/bundles',
    coupons = '/partner/coupon/coupondetails',
    couponsAssignments = '/partner/coupon/couponassignment',
    couponStatus = '/partner/coupon/couponstatus',
    approvals = '/partner/approvals',
    addCustomer = '/partner/customers/addCustomer'
}

export enum  SellIndirectRoutes {
    resellers = '/partner/resellers',
    resellerPlans = '/partner/reseller-plans'
}