// USA
export const locale = {
  lang: 'tr',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    TRANSLATE: { 
      "ACCEPT_COOKIES_BUTTON_TEXT":"tamam",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"yeni güvenlik ayarlarını kullanarak Kitap Ortağı Merkezi'ne erişim.",
      "ACCESS_TOKEN":"Erişim Simgesi",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"E",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Telefon numarası",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Sıfırla",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Arama",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"E-posta",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Telefon numarası",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"E",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Telefon numarası",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Hesap numarası",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"hesap tipi",
      "ACH_FORM_LABEL_BANK_NAME":"Banka adı",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"İşletme Hesabı",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "ACH_FORM_LABEL_NO":"Hayır",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Yönlendirme numarası",
      "ACH_FORM_LABEL_YES":"Evet",
      "Açık":"Açık",
      "ACTIONS_TO_FIX":"Lütfen destek için yöneticiye başvurun",
      "ACTIONS_TO_FIX_500":"Sorunu çözmek için çalışıyoruz, lütfen bir süre sonra tekrar kontrol edin",
      "ACTIVATE_CONFORMATION":"Etkinleştirmek istediğinden emin misin",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Müşteri Seçin",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Tüm Müşteriler",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Bildirimler",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Bitiş tarihi",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Aksiyon",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Etkinlik adı",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"durum",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"konu",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Etkinlik",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Başlangıç ​​tarihi",
      "ACTIVITY_LOGS_TEXT_FAILED":"Başarısız oldu",
      "ACTIVITY_LOGS_TEXT_STATUS":"durum",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"başarı",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Bildirimler",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Ayarlama Ekle",
      "ADD_CUSTOM_ROLE":"özel rol ekle",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Müşteri en son kabul etti",
      "ADD_CUSTOMER_IN_QUOTE":"Müşteri Ekle",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"ülke",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Müşteri Ekle",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"İptal etmek",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Sunmak",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Tamamlayınız",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Birincil kişiye erişim izni verilsin mi (Müşteri Yöneticisi olarak)?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Uygulamaya erişmek için davetiye gönderilsin mi?",
      "ADD_NEW_USER_IN_QUOTE":"Yeni Kullanıcı Ekle",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"seçmek",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Plan Temel Ayrıntıları Ekle",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Fatura Para Birimi",
      "ADD_PLAN_LABEL_TEXT_NAME":"ad",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Satın Alma Para Birimi",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Sözleşme Ayrıntılarını Görüntüle",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Aksiyon",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Makro",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"tamam",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Döşeme kırıcı",
      "ADD_TO_CART_INFO":"Bu ürün, <b> {{LinkedProductName}} </b> ile bağlantılıdır. Bu ürünü eklerseniz, bağlantı ayrıca",
      "addCustomer":"Müşteri Ekle",
      "ADDONS_LEBEL_TEXT":"Eklentileri",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ADDRESS_TABLE_HEADER_CITY":"Kent",
      "ADDRESS_TABLE_HEADER_COUNTRY":"ülke",
      "ADDRESS_TABLE_HEADER_STATE":"Durum",
      "ADDRESS_TABLE_HEADER_ZIP":"Zip",
      "ADDRESS_TYPE_BILLING":"fatura",
      "ADDRESS_TYPE_COMPANY":"şirket",
      "ADDRESS_TYPE_INVOICE":"Fatura",
      "ADI":"Kuruluşun adı",
      "AĞUSTOS":"Ağustos",
      "ALAN GEREKLİ":"{{atribute}} gerekli",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Panoya kopyalandı",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"Fatura başarıyla onaylandı.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"Fatura başarıyla sonuçlandırıldı.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Lütfen en az bir fatura seçin",
      "ALICI":"Alıcı",
      "ALL_COMMENTS":"Yorumlar",
      "ALL_PRODUCT_COMMENTS":"Ürün",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Lütfen lisansların kaldırılması için yöneticinize başvurun.",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Azure Faturalandırma Geçmişi",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Azure Faturalandırma Özeti",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Müşteri Azure Kullanımı",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Müşterilerin Geliri",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Müşterilerin Abonelikleri",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Planlara Göre Abonelikler",
      "APPCONFIG_AUTOTASK_PASSWORD":"Parola",
      "APPCONFIG_AUTOTASK_USERNAME":"Kullanıcı adı",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Fatura görünümünde ve fatura pdf'inde görünen 1 adresi.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Fatura görünümünde ve fatura pdf'inde görünen adres 2.",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Lisans İzlemeyi Etkinleştir",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Analytics'i göster",
      "APPCONFIG_DESC_APPLICATION_NAME":"Uygulama Adı",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"İş Ortağı Merkezi'ndeki iş ortağı için faturalandırma döngüsünün sonu",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"Azure CSP Teklif Kimliği",
      "APPCONFIG_DESC_BILLING_CYCLE":"İş Ortağı Merkezi veya Özel ile uyumlu",
      "APPCONFIG_DESC_BILLING_DATE":"Fatura Döngüsü Özel olduğunda uygulanabilir",
      "APPCONFIG_DESC_BILLING_PERIOD":"Faturalandırma Süresi (diğer ayarlar kullanılarak belirlenir)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"C3 Portalı için Faturalandırma Sağlayıcısı",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Faturalandırma Sağlayıcısı Yapılandırması",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Faturalandırma döngüsünün başlangıcı. Fatura Döngüsü için uygulanabilir Özel",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Bölümden faturada fatura görünümünde ve fatura pdf'sinde görünür",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Bölümden faturada fatura görünümünde ve fatura pdf'sinde görünür",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"0365'e Karşı Rolleri Kontrol Et",
      "APPCONFIG_DESC_CITY":"Fatura görünümü ve fatura pdf'inde görünen şehir.",
      "APPCONFIG_DESC_COMPANY_NAME":"Fatura görünümünde ve fatura pdf'inde görünen Şirket Adı.",
      "APPCONFIG_DESC_COMPANY_URL":"Web sitenizin URL'si",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Açılış sayfasında görünmesi gereken ad ve kullanıcı giriş yaptıktan sonra altbilgi",
      "APPCONFIG_DESC_CONTACT_EMAIL":"İletişim E-postası (genellikle bilgileriniz veya destek e-postanız)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"C3 başvurunuz için ilgili kişinin adı",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"C3 başvurunuz için irtibat kişisinin soyadı",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"İlgili kişinin telefon numarası",
      "APPCONFIG_DESC_COUNTRY":"Fatura görünümünde ve fatura pdf'inde görünen ülke.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Ülke kodu",
      "APPCONFIG_DESC_CURRENCY_CODE":"Para Birimi Kodu",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"Müşteri Onayı URL'si",
      "APPCONFIG_DESC_DATE_FORMAT":"Tarih formatı",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Tarih Saat Biçimi",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"Özelleştirilmiş bir açılış sayfanız olmasını istiyorsanız, sayfanın hedef URL'si. Not: Açılış sayfasında bir düğmeniz olmalı ve bu düğmenin hedef URL'si <C3Site URL'si> / # / login olmalıdır.",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Web sitesi için varsayılan dil",
      "APPCONFIG_DESC_EMAIL_LOGO":"C3 tarafından gönderilen e-postalarda görünen logo. Tercih edilen boyut 300x100'dür.",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Fatura görünümünde ve fatura pdf'sinde altbilgi onaylı satırı olarak görünür",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Fatura görünümünde ve fatura pdf'sinde altbilgi satırı olarak görünür",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Fatura Oluşturma Günleri",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Bize Ulaşın Bağlantı sayfası (eğer yoksa / / # / contact yazın)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Müşteriye SSS Bağlantısı (varsayılanı kullanmak istiyorsanız boş bırakın)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Portal Bağlantısı",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Destek Bağlantısı sayfası (eğer yoksa, home.support yazın)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Kullanıcılar C3'te oturum açtıktan sonra sol üstte görünen logo. Tercih edilen boyut 170x40'tır. Sayfanın siyah arka planı vardır, bu nedenle grafik Siyah ile kontrast teşkil edecek bir renkte olmalıdır.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Portal'a Giriş",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Excel'e dışa aktarma için maksimum giriş sayısı",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft güvenlik onayı günler içinde hatırlatıyor",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Test Ödemeleri için Minimum Ücret Tutarı",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Faturalandırma’da zaten mevcut olan müşteriyi arama işlevini etkinleştirir",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"İş ortağı merkezinizdeki yerel bir uygulamadan Uygulama Kimliği. Bunu Uygulama Yönetimi -> Yerel Uygulama aracılığıyla alabilirsiniz",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"İş Ortağı Para Birimi",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Yönetici Aracısı Parolası",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"İş Ortağı Tercih Dilleri",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"Ortak Kiracı Kimliği",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Ajan Yöneticisi rolü atanan hesabın kullanıcı adı",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"Microsoft bulut satıcı sözleşmesi URL'si",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"İş ortağı şartlar ve koşulları URL'si",
      "APPCONFIG_DESC_PBI_GROUP_ID":"Power BI Grup Kimliği",
      "APPCONFIG_DESC_PBI_PASSWORD":"Power BI Parolası",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Power BI Rapor Kimliği",
      "APPCONFIG_DESC_PBI_USER_NAME":"Power BI Kullanıcı Adı",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"Power BI İstemci Kimliği",
      "APPCONFIG_DESC_POSTAL_CODE":"Fatura görünümü ve fatura pdf'inde görünen posta kodu.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Açılış sayfasında görünmesi gereken ad ve kullanıcı giriş yaptıktan sonra altbilgi",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"Web sitenizin URL'si",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Mutabakat raporu görünümünde Microsoft logosunun sağ tarafında görünen logo. Tercih edilen boyut 170x40'tır. Sayfanın beyaz bir arka planı vardır, bu nedenle metin veya grafik Beyaz ile kontrast teşkil eden herhangi bir renkte olmalıdır.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Alıcıları virgülle ayrılmış değerler olarak ekleyin",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Müşteriyi Bildirmeli",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Tehlike eşiği karşılandığında aboneliği askıya alma",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Power BI Rapor Menüsünü Göster",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Sonraki Adımı Göster",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP SSL'yi etkinleştir",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"E-postadan SMTP",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"Görünen Addan",
      "APPCONFIG_DESC_SMTP_HOST":"SMTP Ana Bilgisayarı",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Parola",
      "APPCONFIG_DESC_SMTP_PORT":"Liman",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"SMTP Konu Öneki",
      "APPCONFIG_DESC_SMTP_USERNAME":"SMTP Kullanıcı Adı",
      "APPCONFIG_DESC_STATE":"Fatura görünümünde ve fatura pdf'inde görünen durum.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DESC_WELCOME_LOGO":"C3 uygulamanızın açılış sayfasında görünmesi gereken logo. Tercih edilen boyut 186x32'dir. Sayfanın beyaz bir arka planı vardır, bu nedenle metin veya grafik Beyaz ile kontrast teşkil eden herhangi bir renkte olmalıdır.",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Hesap numarası",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Adres 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Adres 2",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Lisans İzlemeyi Etkinleştir",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Analytics'i göster",
      "APPCONFIG_DISP_APPLICATION_NAME":"Uygulama Adı",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Microsoft fatura oluşturma tarihi",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"Azure CSP Teklif Kimliği",
      "APPCONFIG_DISP_BILLING_CYCLE":"Fatura döngüsü",
      "APPCONFIG_DISP_BILLING_DATE":"C3'te Faturaların oluşturulduğu tarih",
      "APPCONFIG_DISP_BILLING_PERIOD":"Fatura donemi",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Faturalandırma Sağlayıcısı",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Faturalandırma Sağlayıcısı Yapılandırması",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Faturalandırma Başlangıç ​​Günü",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"İş numarası",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"İşletme Numarası Anahtarı",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"0365'e Karşı Rolleri Kontrol Et",
      "APPCONFIG_DISP_CITY":"Kent",
      "APPCONFIG_DISP_COMPANY_NAME":"Şirket Adı",
      "APPCONFIG_DISP_COMPANY_URL":"şirket url'si",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Şirket Adı",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"İletişim Firma Adı",
      "APPCONFIG_DISP_CONTACT_EMAIL":"İletişim E-posta",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"İletişim Adı",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"İletişim Soyadı",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"İletişim Telefon Numarası",
      "APPCONFIG_DISP_COUNTRY":"ülke",
      "APPCONFIG_DISP_COUNTRY_CODE":"Ülke kodu",
      "APPCONFIG_DISP_CURRENCY_CODE":"Para Birimi Kodu",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"Müşteri Onayı URL'si",
      "APPCONFIG_DISP_DATE_FORMAT":"Tarih formatı",
      "APPCONFIG_DISP_DATE_TIME_FORMAT":"Tarih Saat Biçimi",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"Özelleştirilmiş Açılış Sayfası URL'si",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Varsayılan dil",
      "APPCONFIG_DISP_EMAIL_LOGO":"E-posta Logosu",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Fatura Altbilgisi Yetkili Hattı",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Fatura Altbilgisi Satırı",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Fatura Oluşturma Günleri",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Bize Bağlanın sayfası",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Müşteriye Bağlantı SSS",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Portal Bağlantısı",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Destek Bağlantısı",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Giriş Logo",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Portal'a Giriş",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"İhracat İçin Maksimum Giriş",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft güvenlik onayı günler içinde hatırlatıyor",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Minimum Şarj Tutarı",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Faturalamayla Dahili Müşteri Arama",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Yerel Uygulama Kimliği",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"İş Ortağı Para Birimi",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Yönetici Aracısı Parolası",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Dil Paketleri",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"Ortak Kiracı Kimliği",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Yönetici Aracısı Kullanıcı Adı",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"Microsoft Bulut Satıcı Sözleşmesi URL'si",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"İş Ortağı Şartlar ve Koşulları URL'si",
      "APPCONFIG_DISP_PBI_GROUP_ID":"Power BI Grup Kimliği",
      "APPCONFIG_DISP_PBI_PASSWORD":"Power BI Parolası",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Power BI Rapor Kimliği",
      "APPCONFIG_DISP_PBI_USER_NAME":"Power BI Kullanıcı Adı",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"Power BI İstemci Kimliği",
      "APPCONFIG_DISP_POSTAL_CODE":"Zip",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Şirket Adına Göre",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Powered by Şirket URL'si",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Mutabakat Raporu Logosu",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Mutabakat Raporu Alıcıya Bildir",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Tehlike eşiği karşılandığında aboneliği askıya alma",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Power BI Rapor Menüsünü Göster",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Sonraki Adımı Göster",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"SSL'yi etkinleştir",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"E-postadan",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"Görünen Addan",
      "APPCONFIG_DISP_SMTP_HOST":"evsahibi",
      "APPCONFIG_DISP_SMTP_PASSWORD":"SMTP Parolası",
      "APPCONFIG_DISP_SMTP_PORT":"SMTP Bağlantı Noktası",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Konu Öneki",
      "APPCONFIG_DISP_SMTP_USERNAME":"Kullanıcı adı",
      "APPCONFIG_DISP_STATE":"Durum",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Vergi Türü",
      "APPCONFIG_DISP_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"E-postadan SMTP",
      "ARALIK":"Aralık",
      "ASSIGN_BUNDLE_PRODUCTS":"Paket Ürünleri Atama",
      "ASSIGN_CUSTOMER_PLAN":"Müşteri Planı Atama",
      "ASSIGN_USAGE_PRODUCTS":"Kullanım Ürünleri Atama",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Kapat",
      "AUDIT_LOG_DETAIL":"ayrıntılar",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Gizli",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Denetim Günlüğü",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Arama",
      "AUDITLOG_TEXT_ACTION_NAME":"Aksiyon",
      "AUDITLOG_TEXT_AUDITLOG":"Denetim Günlüğü",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"müşteri adı",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"İtibaren geçerli",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Etkili",
      "AUDITLOG_TEXT_FROM":"itibaren",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Müşteri Alan Adı",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Aksiyon",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Etkilenen Müşteri",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"tarih",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"öncü",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Detayları göster",
      "AUDITLOG_TEXT_TO":"Etkili",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodu",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan Kimlik Doğrulama Kodu",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"Ödeme başarıyla başlatıldı. İşleme biraz zaman alacaktır. Lütfen bir süre sonra durumu kontrol edin.",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Kategori",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Açıklama",
      "AZURE_BILLING_CYCLE":"Fatura döngüsü",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Fatura donemi",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Yetkilendirme Kimliği",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Yetkilendirme Adı",
      "AZURE_ESTIMATE_REPORT":"Azure Tahmin Raporu",
      "AZURE_ESTIMATE_TAGS":"Etiketler",
      "AZURE_ESTIMATED_COST":"Tahmini maliyeti",
      "AZURE_ESTIMATES":"Azure Tahminleri",
      "AZURE_GROUP_DETAILS":"Grup Ayrıntıları",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Azure Grupları",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Gruba Yönetici Ekle",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Grup ekle",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Grup Kullanıcılarına Dön",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Gruplara Dön",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Grubu Düzenle",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Grup Kullanıcıları",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Grup Kullanıcısı Ekle",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Azure Grupları",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"{{Groupname}} kullanıcısı",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"yönetici",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Faturalandırma Erişimi",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Tehlike Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"E-posta",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Hata Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Grup ismi",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Bilgi Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Bildirim Sıklığı",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Uyarı Eşiği",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"yönetici",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Faturalandırma Erişimi",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"ad",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Bildirim Sıklığı",
      "AZURE_LOADING":"Azure'dan Veri yüklemeye çalışırken lütfen bekleyin ...",
      "AZURE_LOADING_MESSAGE":"Microsoft Azure'u sorgulamak ve yüklemek bir dakika sürebilir ...",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Talimatlar",
      "AZURE_RECOMMENDATION_ALL_COST":"Maliyet",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Bu alan gereklidir",
      "AZURE_RECOMMENDATION_SUBMIT":"Sunmak",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Kaynak Grubuna Göre Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Hizmete Göre Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Ayrıntıları Al",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Azure Raporları",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Faturalandırma Özeti",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"tarih",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"yetki verme",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"grup",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Ay",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Grup Seç",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"abone",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Tüm Hak Sahipleri",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Tüm Gruplar",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Tüm Abonelikler",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Tarih seç",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Atanmayanlar",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Maliyet",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Kaynak Adı",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"hizmet adı",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Denetim",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"ayrıntılar",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Kaynak Grubu",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Kaynak Adı",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"hizmet adı",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Toplam tutar",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Tarih zamanlı",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Etkinlik Kategorisi",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Tarafından Başlatılan Etkinlik",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"İşlem Adı",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"durum",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Denetim",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"ayrıntılar",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Maliyet",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"tarih",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Toplam tutar",
      "AZURE_REPPORT_CURRENCY":"Para birimi",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Raporu almak için bir para birimi seçmelisiniz",
      "AZURE_REPPORT_CUSTOMERS":"Müşteriler",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Müşteri Seçin",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Bir müşteri seçin",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"iştirakçi",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Özel Rol",
      "AZURE_ROLE_NAME_OWNER":"Sahip",
      "AZURE_ROLE_NAME_READER":"Okuyucu",
      "AZURE_SUBSCRIPTION":"Azure Aboneliği",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Düzenle",
      "AZURE_SUBSCRIPTION_DETAILS":"Abonelik Ayrıntıları",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"seçmek",
      "AZURE_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Azure aboneliği Güncelleme Başarısız",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"Azure aboneliği başarıyla güncellendi",
      "AZURE_THRESHOLD_DETAILS":"Eşik Ayrıntıları",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Bu abonelik için aylık bir bütçe belirleyin ve Bilgi, Uyar, Hata ve Tehlike gibi çeşitli önem derecelerindeki eşikleri tanımlayın.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Müşteri Tarafından Yönetilen aboneliklerde, kullanım belirli bir eşiği karşıladığında veya aştığında tüm grup yöneticilerine bildirim gönderilir.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Bildirimler her faturalandırma döngüsünde bir kez gönderilir.",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Azure Kullanımı",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Kullanım Detayları",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Tahmini Fiyat",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"ad",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}}, {{groupname}} Grubuna başarıyla eklendi",
      "AZUREGROUPS_SAVE_SUCCESS":"{{Username}} grubu başarıyla kaydedildi",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Grup kullanıcısı başarıyla eklendi",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Grup başarıyla kaydedildi",
      "BACK_TO_POLICIES":"Özel politikalara dön",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Sağlayıcı kiracılarına geri dön",
      "BAYİ":"(Bayi)",
      "BILL_ACTION_DESC_FULL_CHARGE":"Tüm ay boyunca tam şarj.",
      "BILL_ACTION_DESC_NO_REFUND":"Para iadesi yapılmayacak",
      "BILL_ACTION_DESC_PRORATE":"Oranlama faturada uygulanacaktır.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Tam şarj",
      "BILL_ACTION_NAME_NO_REFUND":"Para iadesi yok",
      "BILL_ACTION_NAME_PRORATE":"Prorate",
      "BILL_CYCLE_DESC_ANNUAL":"Yıllık",
      "BILL_CYCLE_DESC_MONTHLY":"Aylık",
      "BILL_CYCLE_NAME_ANNUAL":"Yıllık",
      "BILL_CYCLE_NAME_MONTHLY":"Aylık",
      "BILL_FROM":"Fatura Gönderen",
      "Billing":"fatura",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ettiği için ürünün faturalandırma döngüsü değişikliğine izin verilmiyor. Lütfen daha sonra tekrar deneyin.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Mevcut Ürün Adı",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Siparişin faturalandırma döngüsü başarıyla değiştirildi",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"seçmek",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Fatura döngüsü",
      "BILLING_PERIOD":"Fatura donemi",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"seçmek",
      "BILLING_TYPE_DESC_MARK_UP":"Biçimlendirme",
      "BILLING_TYPE_DESC_PERCENTAGE":"Yüzde",
      "BILLING_TYPE_DESC_PRICE":"Fiyat",
      "BILLING_TYPE_TOOLTIP":"Faturalandırma Türü",
      "BİR HATA OLUŞTU":"İsteğiniz işlenirken bir hata oluştu",
      "BÖLÜM":"(Bölüm)",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Azure Tahminleri",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Azure Aboneliği",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Lisanslar",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Power BI Raporları",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "BTN_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "BTN_INVOICE_PAYMENTS":"Fatura Ödemeleri",
      "BTN_TEXT_BEGIN_UPLOAD":"Yükleme",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"Panoya kopyala",
      "BTN_TEXT_FILTER":"filtre",
      "BTN_TEXT_FIX":"Fix",
      "BTN_TEXT_IGNORE":"Göz ardı etmek",
      "BTN_TEXT_INCLUDE":"Dahil etmek",
      "BTN_TEXT_OPEN_IN_EMAIL":"E-postada aç",
      "BTN_TEXT_PROCEED":"İlerlemek",
      "BTN_TEXT_REMOVE":"Kaldırmak",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Bayi ilişkisi isteyin",
      "BTN_TEXT_SIGN_IN":"oturum aç",
      "BTN_TEXT_SKIP":"Atla",
      "BTN_TEXT_TAKE_ON":"Dahil etmek",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"İptal etmek",
      "BULK_INVOICE_DOWNLOAD_HEADER":"Tüm faturaları indir",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-postalar",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Tüm faturaları e-posta ile alın",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Kapat",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Sahip",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"durum",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"kullanıcı",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"Sunmak",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Bu kaydı silmek istediğinizden emin misiniz?",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-postalar",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"öncü",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"durum",
      "BULK_ONBOARD_BATCH_ID":"Yerleşik Toplu Toplu İş Kimliği",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"Yerleşik Toplu Toplu İş Kimliği",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Müşterilerin Teknede Toplu Yığın İsteği",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"Toplu Katılım MicrosoftNonCSP müşterileri desteklenmez.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"durum",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Bitti",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Seçilmiş Müşteriler",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"En az bir müşteri seçin / bir plan seçin",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Müşteriyi kaldırmak istiyor musunuz?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"Birden fazla müşteri ekleme isteği kabul edildi",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Bir örnek .csv indirin",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"başvuru için dosya.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Talimatlar",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Aşağıdaki aboneliklerin planda eşdeğer teklifler yoktur. Lütfen planı güncelleyin ve tekrar deneyin.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"CSP'de kimliğine sahip bir müşteri bulunamadı: {{CSPCustomerId}}",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Aşağıdaki teklif (ler), C3'te desteklenmeyen tek bir seviyede birden fazla kez satın alınmıştır. Lütfen verileri doğrulayın.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Son güncelleme işleminizde bazı hatalar var gibi görünüyor, lütfen yardım için destek ekibiyle iletişime geçin",
      "bundles":"Paketler",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Lütfen bekle...",
      "BUTTON_ACTIVATE":"etkinleştirme",
      "BUTTON_APPLY_COUPON_TEXT":"kuponu onayla",
      "BUTTON_CLOSE":"Kapat",
      "BUTTON_DEACTIVATE":"Devre dışı bırakmak",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"İptal etmek",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"İndir",
      "BUTTON_LANG_CN":"Çince",
      "BUTTON_LANG_EN":"ingilizce",
      "BUTTON_LANG_ES":"İspanyol",
      "BUTTON_LANG_FR":"Fransızca",
      "BUTTON_LANG_TH":"Tayland",
      "BUTTON_LANG_TW":"Tayvanlı",
      "BUTTON_LANGUAGE":"Dil",
      "BUTTON_MANAGE_PRODUCT":"yönetme",
      "BUTTON_PROCESSING":"İşleme",
      "BUTTON_RELEASE":"Serbest bırakmak",
      "BUTTON_REVOKE":"İptal Et",
      "BUTTON_START_TOUR":"Bir tur at",
      "BUTTON_TEXT_ACCEPT":"Kabul etmek",
      "BUTTON_TEXT_ACTIONS":"Aksiyon",
      "BUTTON_TEXT_ADD":"Ekle",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Yeni Para Birimi Dönüştürme Ekle",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"özel politika ekle",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Yeni Etkinlik E-posta Bildirimi Ekle",
      "BUTTON_TEXT_ADD_SUB_TAX":"Alt Vergi Ekle",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Eklentiler ile ekleyin",
      "BUTTON_TEXT_APPLY":"Uygulamak",
      "BUTTON_TEXT_APPROVE":"onaylamak",
      "BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "BUTTON_TEXT_CANCEL":"İptal etmek",
      "BUTTON_TEXT_CLEAR":"Açık",
      "BUTTON_TEXT_CLOSE":"Kapat",
      "BUTTON_TEXT_DELETE":"Sil",
      "BUTTON_TEXT_DOWNLOAD":"İndir",
      "BUTTON_TEXT_EDIT":"Düzenle",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Excel'e aktar",
      "BUTTON_TEXT_FILTER":"filtre",
      "BUTTON_TEXT_LOAD_OFFERS":"Teklifleri Yükle",
      "BUTTON_TEXT_OK":"tamam",
      "BUTTON_TEXT_OVERRIDE":"Geçersiz Kıl",
      "BUTTON_TEXT_PAY_INVOICES":"Bekleyen Faturaları Öde",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Avans Ödemesini Kaydedin",
      "BUTTON_TEXT_REJECT":"reddetmek",
      "BUTTON_TEXT_REMOVE":"Kaldırmak",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Alt Vergiyi Kaldır",
      "BUTTON_TEXT_REPORTS":"Raporlar",
      "BUTTON_TEXT_RESET":"Sıfırla",
      "BUTTON_TEXT_REVERT":"Geri Al",
      "BUTTON_TEXT_SAVE":"Kayıt etmek",
      "BUTTON_TEXT_SEARCH":"Arama",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"İşletme Varlığını Ayarla",
      "BUTTON_TEXT_SUBMIT":"Sunmak",
      "BUTTON_TEXT_UPDATE":"Güncelleme",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"ONLINE QUICKBOOKS'A YÜKLE",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"XERO'YA YÜKLE",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"POST GÜNLÜKLERİNİ GÖRÜNTÜLE",
      "BUTTON_TEXT_VIEW_OFFERS":"Teklifleri Görüntüle",
      "BUTTON_VIEW_PRODUCT":"Görünüm",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Etiket Ekle",
      "C3_CART":"Araba",
      "CALENDAR_CLOSE_BUTTON":"Kapat",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Bu alan gereklidir",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Eski bir para birimi dönüştürme tanımı silinemiyor",
      "CANNOT_INITIATE_PAYMENT":"Bekleyen bir işlem nedeniyle ödeme başlatılamıyor",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Bayi ilişkisi talebi",
      "CARD_CARD_DELETE_ADDON":"Sil",
      "CART_CARD_COMMENT_ACTION":"Yorumlar",
      "CART_CARD_DELETE_PRODUCT":"Sil",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Seçilen teklifin kiracısını değiştirmek istediğinizden emin misiniz?",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Seçilen teklifte kiracı güncellenirken hata oluştu. Lütfen Destek ekibiyle iletişime geçin",
      "CART_ITEM_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Kiraci",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Sepet ödeme için uygun değil veya zaten teslim alınmış!",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Bağlantılı Ürün",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"İndirim",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Uygulamak",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Tüketim türleri",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong> Merhaba! </strong> Sepetiniz boş veya arama öğesi bulunamadı <a ng-click='vm.GotoCart()' class='alert-link'> Alışveriş yapmak için burayı tıklayın. <span class = 'fa fa-cart-plus'> </span> </a>",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Alışveriş sepeti kontrol edilirken hata oluştu",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Sipariş başarıyla verildi. OrderId: {{orderId}}",
      "CART_PRODUCT_DELETE_CONFIRMATION":"Bu öğeyi alışveriş sepetinden silmek istediğinizden emin misiniz?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Ürün alışveriş sepetinden kaldırılırken hata oluştu!",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"İstenen öğe başarıyla kaldırıldı!",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Miktar başarıyla güncellendi",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Seçilen teklife karşı kiracı başarıyla güncellendi!",
      "CATEGORY_DESC_ALL":"Herşey",
      "CATEGORY_DESC_AZURE":"masmavi",
      "CATEGORY_DESC_AZURE_PLAN":"Azure Planı",
      "CATEGORY_DESC_BUNDLES":"Paketler",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Bu kuponu devre dışı bırakmak istediğinizden emin misiniz?",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Bu kuponu etkinleştirmek istediğinden emin misin?",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Hedef Planı",
      "CHANGE_PLAN_HELP":"Yardım",
      "CHANGE_PLAN_STATUS_FAILED":"Başarısız oldu",
      "CHANGE_PLAN_STATUS_SUCCESS":"başarı",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Kategori",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":"Fiyat",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Sağlayıcı",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"miktar",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Geçerlilik",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Satın Alınan ürün durumu Güncellenirken Hata Oluştu:",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Emin misin, bu teklifi durdurmak istiyor musun",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Emin misiniz, bu planı devre dışı bırakmak istiyor musunuz?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Bu planı etkinleştirmek istediğinden emin misin?",
      "CHOOSE_AN_OPTION":"Bir seçenek belirleyin",
      "CHOOSE_ONE_RG":"En az bir Kaynak Grubu seçin",
      "CHOOSE_RESOURCE_GROUP":"Politika Seçin",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Tıklayın",
      "CLONE_PLAN_FAILURE":"Plan klonlanamadı",
      "CLONE_PLAN_SUCCESS":"Başarıyla klonlanmış plan",
      "COLLAPSE_ALL_PAYMENTS":"Collapse Ödemeler",
      "COMMISION_REPORT_SEARCH":"Arama",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Kapat",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Sıfırla",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Arama",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Alan adı",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Müşteri",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Emin misiniz, bu faturayı onaylamak istiyor musunuz?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Emin misiniz, bu faturayı tamamlamak istiyor musunuz?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Kaydedilmemiş değişiklikleriniz mevcut. Kaydetmeden çıkmak için Tamam'ı veya sayfada kalmak için İptal'i tıklayın",
      "CONFIRMATION_POPUP_MESSAGE":"Bu aboneliği etkinleştirmek istediğinizden emin misiniz?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Bu abonelikle ilgili isteği iptal etmek istediğinizden emin misiniz?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"etkinleştirme",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"İptal edildi",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Bu düzenlemeyi silmek istiyor musunuz?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"İptal etmek istediğinden emin misin?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Devam etmek istiyor musun?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Geçerli seçime devam etmek istediğinizden emin misiniz?",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Miktar",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Ürün",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"miktar",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"sözleşme",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"miktar",
      "CONSUMPTION_TYPES_DESC_USAGE":"kullanım",
      "CONTACT_CANCEL":"İptal etmek ister misiniz?",
      "CONTACT_HEADER_TEXT":"Bir sorunuz mu var?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Bağlantılarımız",
      "CONTACT_LABEL_TEXT_EMAIL":"E-posta",
      "CONTACT_LABEL_TEXT_MESSAGE":"İleti",
      "CONTACT_LABEL_TEXT_NAME":"ad",
      "CONTACT_PARAGRAPH_TEXT":"Aşağıdaki formu doldurarak bize ulaşın, kısa süre içinde size geri dönelim.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Bize görüşlerinizi bildirdiğiniz için teşekkür ederiz. Ekibimiz kısa süre içinde sizinle iletişime geçecektir.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Geri bildiriminiz başarıyla gönderildi",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Ekle",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Sil",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Bu levhayı silmek istediğinizden emin misiniz?",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Bu sözleşme teklifini devre dışı bırakmak istediğinizden emin misiniz?",
      "COOKIE_CONSENT_MESSAGE":"Uygulama size daha iyi bir deneyim sunmak için çerezleri kullanır. Uygulamaya erişmeye devam ederek çerezleri kullanmamızı kabul edersiniz.",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Sıfırla",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Arama",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Müşteriler",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Planlar",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Bayiler",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Müşteri Seçiniz",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Planları Seçin",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Bayileri Seçin",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"filtre",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"miktar",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Is ortagi maliyeti",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Ayriyeten",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Paketi",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Bağlantılı",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "coupon":"Kupon",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Kupon Ataması başarıyla kaydedildi",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Kupon",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Müşteriler",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Aktif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Aktif",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Yeni Kupon Ataması Ekle",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Düzenle",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Atandı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"durum",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Kupon Kodu",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Kupon Açıklaması",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Kupon Adı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"durum",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Aktif",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Lütfen geçerli bir kupon seçin",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Seçilen kupon bu müşteri tarafından kullanılabilir.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Kupon Ekle",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Kuponlara Geri Dön",
      "COUPON_CAPTION_TEXT_ADD":"Kupon Ekle",
      "COUPON_CAPTION_TEXT_EDIT":"Kuponu Düzenle",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Tüm teklifleri seç",
      "COUPON_DISABLE_BUTTON":"Kuponu devre dışı bırak",
      "COUPON_EDIT_BUTTON":"Kuponu Düzenle",
      "COUPON_ENABLE_BUTTON":"Kuponu etkinleştir",
      "COUPON_HEADER_ACTION":"Aksiyon",
      "COUPON_OWNERSHIPS_CATEGORY":"Kategori",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"uygulanabilirlik",
      "COUPON_OWNERSHIPS_PLAN":"Plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Teklifleri Planla",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Etkili Fiyat",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Seçildi",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Teklif Adı",
      "COUPON_OWNERSHIPS_SALETYPE":"SaleType",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Uygulanabilir",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"En az bir plan ürünü seçmelisiniz.",
      "COUPON_SAVE_SUCCESS":"{{Coupon}} kuponu başarıyla kaydedildi",
      "COUPON_STATUS_FLAG_ACTIVE":"Aktif",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Kupon Kodu",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Kupon Adı",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Son kullanım tarihi",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Adı",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Yeni Müşterilere Uygulanabilir",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Eski Müşterilere Uygulanabilir",
      "COUPON_TABLE_HEADER_TEXT_CODE":"kod",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Oluşturma Tarihi",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"İndirim",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Tarihinde sona eriyor",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Aktif",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"IsPercentage",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Herkese Açık mı",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Maksimum İndirim",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Maks. Geri Ödeme",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Minimum Tutar",
      "COUPON_TABLE_HEADER_TEXT_NAME":"ad",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Reküranslar",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Plan Adı",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Kadar geçerli",
      "COUPON_UPDATED_SUCCESS":"{{Coupon}} kuponu başarıyla güncellendi",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"GeçerliTarih tarihi, Sona Erme tarihinden sonra olmalıdır",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Yeni Kupon Ataması Ekle",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Kupon Atamaları",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Kupon Atamasını Düzenle",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Kuponun satın alındığı tarihte uygulanabileceği tarih",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Kupon Durumu",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Kuponlar",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Kuponun uygulandıktan sonra faturalandırma sırasında dikkate alındığı tarih",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Onay deneyimini başlatma bağlantısı panonuza kopyalanır",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Onayı yakalayan bağlantıyı kopyalamak için <b> <a ngclipboard data-clipboard-text='{{consentURL} {'ng-click='confirmCopy()'> </b> Bağlantıyı panoya kopyala </a>' yı tıklayın. , yeni güvenlik modelini izleyerek bu uygulamaya İş Ortağı Merkezi'ne erişim izni vermek için",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Bağlantıyı gizli bir oturumda açın ve (İş Ortağı) kiracınızda Global Yönetici olan ve İş Ortağı Merkezi'nde Yönetici Aracısı rolü atanmış bir hesap kullanarak onay deneyimini tamamlayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Uygulamanın yeni güvenlik modeliyle çalışıp çalışmadığını kontrol etmek için Test düğmesine tıklayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Uygulamanın eski kimlik bilgilerinizi temizlemesine ve yeni güvenlik modeline geçmesine izin vermek için <b> Yeni güvenlik modeline geç </b> 'i (test başarılı olduktan sonra etkinleştirilir) tıklayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Yeni güvenlik modeline geçme talimatları",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Talimatlar",
      "CPV_LOADING_MESSAGE":"Lütfen onayınızı işleme koyarken bekleyin ...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Onayınızı verdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek İş Ortağı Merkezi'ne erişecek şekilde yapılandırılabilir.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Ayarlar -> İş Ortağı Merkezi ile ilgili talimatları uygulayın.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Yeni güvenlik ayarlarını kullanarak Kitap Ortağı Merkezi'ne erişme girişimi başarılı.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Kart numarası",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Ay",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"4-Nisan",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"8-Ağustos",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12-Aralık",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"2-Şubat",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"1-Ocak",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"7-Temmuz",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"6-Haziran",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"3-Mart",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"5-Mayıs",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11-Kasım",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Ekim",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"9-Eylül",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Karttaki İsim",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Yıl",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"Kupon oluşturulamadı",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Kupon başarıyla oluşturuldu",
      "CREATE_CUSTOMER_EXCEPTION":"Müşteri oluşturulurken hata oluştu",
      "CREATE_CUSTOMER_USER_ROLE":"Müşteri Kullanıcıları Oluşturun",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Müşteri kullanıcıları oluşturulamadı",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Fatura oluşturmak",
      "CREATED_SUCCESSFULLY":"başarıyla oluşturuldu",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Lütfen kümülatif vergiler için en az bir vergi adı ve yüzde girin.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Para birimi dönüştürme kuralı başarıyla eklendi",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeye Geri Dön",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Ekle",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Para birimi dönüştürme",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Düzenle",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"Para birimi dönüştürme kuralı başarıyla silindi",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Herşey",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Dönüşüm oranı",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Müşteri",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Dönüşüm oranı",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Müşteri",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"durum",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Para birimi dönüştürme kuralı başarıyla güncellendi",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Kullanım ayrıntıları henüz mevcut değil, lütfen bir süre sonra tekrar deneyin.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"miktar",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Başlık",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"İptal etmek",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Plan Adı",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Planları Seçin",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Bitiş tarihi",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Ürün sahipliğini yönetme",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Yerleşik Müşteri",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Aksiyon",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Açıklama",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Başlık",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Açıklama",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Etkinlik",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Başlık",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Sıfırla",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Sunmak",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"seçmek",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Arama",
      "CUSTOM_NOTIFICATION_SELECT":"seçmek",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Sağlayıcıları",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Başlangıç ​​tarihi",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Aksiyon",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Etkinlik",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Güncelleme",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Tüketim türleri",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Kategori",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Ortak Teklifleri",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Sözleşme Teklifi Ekle",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"İş Ortağı Teklifi Ekle",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"İş Ortağı Tekliflerine Geri Dön",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"uygulanabilirlik",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Ekle",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Sözleşme Teklifleri",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ortak Teklifleri",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Düzenle",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"'Varsayılan' olarak 'ChargePeriods', 'Koltuk satın almak için faturalandırma kuralı' ve 'Koltuklar için' koltuk azaltma için faturalandırma kuralı 'seçeneğini belirleyin.",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Kompozit Teklife İş Ortağı Teklifi eklerken aklınızda bulundurmanız gereken bazı noktalar",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Kategori",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Faturalandırma Dönemi Türü",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim türleri",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "CUSTOM_OFFERS_LABEL_TEXT_Description":"Açıklama",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Feed Kaynağı",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Talimatlar",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"Teklif bir AddOn mu?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"ad",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Ücretsiz iptal için gün sayısı",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Satış ücreti",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Geçerlilik",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Abonelikler için faturalandırma dönemi olarak ne istiyorsunuz?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Aboneliklerin hangi Tüketim türüne sahip olmasını istiyorsunuz?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Feed kaynağı",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Maliyet iptali olmadan izin verilen maksimum gün sayısı.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya tam şarjlı olmalıdır",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Döşeme",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Teklifin geçerliliği.",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"{{Customoffer}} iş ortağı teklifi başarıyla kaydedildi",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Maliyet fiyatı",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Teklif Ayrıntıları",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Satış ücreti",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Görünüm",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(Silindi)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Bu bileşeni aldığınız fiyattır. Bunu bir plana dahil ettiğinizde bu teklifin Satış Fiyatı'nı belirleyebilirsiniz.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Bu bileşeni sattığınız fiyattır.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"{{Customoffer}} iş ortağı teklifi başarıyla güncellendi",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Ücret başlangıç ​​ve bitiş tarihleri ​​bir ayın başlangıç ​​ve bitiş tarihleri ​​olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Ücret başlangıç ​​ve bitiş tarihleri, MS faturalandırma başlangıç ​​ve bitiş tarihleriyle uyumlu olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Ücret başlangıç ​​ve bitiş tarihleri, İş Ortağı faturalandırma başlangıç ​​ve bitiş tarihleriyle uyumlu olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Takvim ayına göre hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"MS Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"İş Ortağı Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Tedarikçi Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Varsayılan (satın alma tarihinden itibaren)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Yeni şifre belirle",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Parolayı sıfırlamak istediğinizden emin misiniz?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Yeni bir şifre oluşturulacak ve kullanıcının geçerli şifresi artık çalışmaz.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Yeni Şifre Girin",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Şifreyi göster",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Otomatik Şifre Oluştur",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Özel Şifre",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"En az 8 karakter. En az bir büyük harf, bir küçük harf, bir özel karakter, bir sayısal değer içermelidir",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Lütfen en az 8 karakterden oluşan bir şifre seçin.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Özel Parola kullanıcının adını içermemelidir.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Politika başarıyla etkinleştirildi",
      "CUSTOM_POLICY_ADD_TITLE":"Özel Politika ekle",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Atanan Politikalar",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Politika Başarıyla Atandı",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Politika ata",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"Azure PolicyId",
      "CUSTOM_POLICY_CUSTOMER_NAME":"müşteri adı",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Politika başarıyla devre dışı bırakıldı",
      "CUSTOM_POLICY_DESCRIPTION":"Açıklama",
      "CUSTOM_POLICY_INSTRUCTIONS":"Özel bir politika oluşturmak için aşağıdaki adımları izleyin",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Özel politika için bir ad ve açıklama girin",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Uygun tanım sağlayın",
      "CUSTOM_POLICY_LABEL_SCOPE":"Dürbün",
      "CUSTOM_POLICY_NAME":"ad",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Politika Ataması",
      "CUSTOM_POLICY_POLICYDEFINITION":"Tanım",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Kaynak Grubu Adı",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Politika Başarıyla İptal Edildi",
      "CUSTOM_POLICY_SAVE_SUCCESS":"{{Policy}} politikası başarıyla kaydedildi",
      "CUSTOM_POLICY_SUBSCRIPTION":"Abonelik Adı",
      "CUSTOM_POLICY_TITLE":"Özel Politikalar",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Politikayı abonelik düzeyine atamak için lütfen tüm Kaynak Gruplarını seçin.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rol başarıyla etkinleştirildi",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"Azure Rolü",
      "CUSTOM_ROLE_CUSTOMER_NAME":"müşteri adı",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rol başarıyla devre dışı bırakıldı",
      "CUSTOM_ROLE_DESCRIPTION":"Açıklama",
      "CUSTOM_ROLE_INSTRUCTIONS":"Özel bir rol oluşturmak için aşağıdaki adımları izleyin",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Özel rol için bir ad ve açıklama girin",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Rol tanımı için yalnızca “Eylem” ve “Eylem Yok” bölümlerini girin.",
      "CUSTOM_ROLE_NAME":"ad",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Kaynak Grubu Adı",
      "CUSTOM_ROLE_ROLEDEFINITION":"Rol Tanımı",
      "CUSTOM_ROLE_SAVE_SUCCESS":"{{Role}} rolü başarıyla kaydedildi",
      "CUSTOM_ROLE_SUBSCRIPTION":"Abonelik Adı",
      "Customer":"Müşteri",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Vergilendirilebilir mi",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Sepeti Güncelle",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Kategori",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Tohum Başına Fiyat",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Ezidebit Pty Ltd ACN 096 902 813'e (Kullanıcı Kimliği No 165969, 303909, 301203, 234040, 234072, 428198) yukarıda / bu Otomatik Ödeme Talebi uyarınca ve Ezidebit DDR Hizmet Sözleşmesi uyarınca. <br> I / Bu ödemelerin BeCloudSmart sözleşmesinin Şartları ve Koşulları ile müteakip sözleşmelere göre BeCloudSmart tarafından belirtilen aralıklarla ve tutarlarda borçlandırılmasına izin veriyoruz.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"anlaşma",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Ezidebit Anlaşması",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Microsoft Bulut Satıcı Sözleşmesi",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"İş ortağı kullanım şartları",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Ezidebit Anlaşmasını Okuyun",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Lütfen okuyun",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Ödeme başarıyla kaydedildi.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Müşterinin fatura ayrıntılarını işe alma süreci devam ediyor",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Dahili'ye devam et",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Müşteri faturalandırma bilgileri başarıyla tamamlandı.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Müşteri faturalandırma sisteminde zaten var",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/> <p> Faturalandırma Sisteminde Kimlik: <strong> {{billingProviderReferenceID}} </strong> ile bir eşleşme bulundu. <br/> <span> Dosyadaki Fatura Adresi <strong> {{formattedAddress}} < / strong>. </span> <br/> Müşteri faturalandırma bilgileri başarıyla eklendiğinde bu ayrıntıyı değiştirmek için lütfen kimliğine bürünün ve <strong> Profil </strong> seçeneğini kullanın.",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Hata",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"Devam etmekte",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"başarı",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Daha fazla oku",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Yeniden Yükleme Müşteri Onayı",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Yeniden Yükleme Başlatılıyor",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Fatura Satır Öğeleri ...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Fatura özeti",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Kârlilik Raporu",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Kârlilik Raporu",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Vergi Satir Ögeleri",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Vergi Satir Ögeleri",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Vergi Özeti",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Vergi Özeti",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Müşteri Onayını Yeniden Yükle",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Mutabakat Raporunu Yeniden Yükle",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Kaldırmak",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Müşteri Sepeti",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Alışverişe devam",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Sipariş Vermek",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Geçerlilik",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Ödenecek tutar",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Para birimi:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Toplam tutar",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Toplam sayım:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Toplam indirim",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Fiyat Detayları",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Operasyonel Varlık",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Ekle",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Güncelleme",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Müşteri onayı",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"Sözleşme URL'si",
      "CUSTOMER_CONSENT_HELP_TEXT":"Lütfen müşterinin kuruluşundaki <a ng-click='vm.CustomerConsentClick()'> Microsoft Müşteri Sözleşmesi'ni </a> kabul eden kişinin ayrıntılarını belirtin.",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"E",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"İsim",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Soyadı",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Microsoft Müşteri Sözleşmesi'ni okumak için <a id='customerConsentLink' ng-click='vm.MicrosoftCloudAgreementClicked()'> buraya </a> tıklayın.",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Microsoft Müşteri Sözleşmesi",
      "CUSTOMER_CONSENT_TEXT_DATE":"tarih",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"E-posta",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Zaman",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Şimdiye kadar satın alınan ürün sayısı",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Ürün",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Bilgilerinize erişimi olan kullanıcı sayısı",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Kullanıcılar",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Bekleyen bir faturada uyarı",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Fatura",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Office 365 portalına hızlı bağlantı",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Office Portalı",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Şartlar ve koşullar kabul günlüğü",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Şartlar ve koşullar",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Fatura satır öğesi başarıyla silindi",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Departman Ekle",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Departman başarıyla silindi",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Bölümü Düzenle",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Bölümler",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Departman başarıyla kaydedildi",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Atanan Siteler",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"yer",
      "CUSTOMER_EXPORTINVOICE_WARNING":"{{Customername}} için fatura henüz oluşturulmadı. Lütfen bir süre sonra tekrar kontrol edin.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Yeniden Yükleme Sağlayıcıları Müşteri Onayı",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"İptal etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Değişikliklerinizi geri almak istediğinizden emin misiniz?",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Müşteri detayları",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Ara toplam",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adres",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"ülke",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Bitiş tarihi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Başlangıç ​​tarihi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Kredi",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Ek ücret",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Ayarlama Tutarı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Ayarlama Ayrıntısı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Sağlayıcı Adı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Faturaya Geri Dön",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Ekle",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"ayarlama",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Düzenle",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Faturalar",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Faturalar",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":"Müşteri Etiketi",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"değer",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filtreler",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Ürünü Yönet",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Azure Plan sayfasına geri dön",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Grup ismi",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Eşik",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Hiçbir yetki bulunamadı",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Sahip henüz atanmadı",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Sahip",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Roller ve amp; Politika içeriği buraya gelir ....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"fatura",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Yorumlar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Tahmin",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Gruplar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Bilgi",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Sahibini Yönet",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Operasyonel Varlıklar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Ödemeler",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Roller ve Politikalar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"kullanım",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"abone",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Bir örnek .csv indirin",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Birden çok kullanıcı için hesap verilerini .csv biçiminde yükleyin. Bir örnek .csv indirin",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"başvuru için dosya.",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Lütfen yeni adı girin",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Müşteri Adını Değiştir",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"Müşterinin adı başarıyla değiştirildi",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Müşteri Adı Güncelleme başarısız",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Müşteri Adı Güncelleme başarılı",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Herhangi bir Microsoft Kiracısına bağlı değilsiniz.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"Bağlı sağlayıcı kiracısı olmadığından Sağlayıcı ürünlerinin satın alınması başarısız olabilir",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"atama",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"düzenlendi",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"silindi",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Hata",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Onboarded",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"Faturalandırmada Arama!",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Bir müşterinin Faturalandırma sistemi kimliğini almak için lütfen aşağıdaki adımları izleyin",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Faturalandırma Sisteminde oturum açın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Müşterilere Git",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Açmak için müşteri kaydını tıklayın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Kayıt anahtarından sonraki dizeyi alın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Url'de recordkey = öğesinden sonra gelen dizeyi alın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"Örneğin:",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"değeri-için kopyalanabilir",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Authorize.Net'e giriş yapın",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Müşteri Bilgisi Yöneticisi'nden Profil Kimliği Alma",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Müşteri Bilgi Yöneticisi için kök: Ana Sayfa-> Müşteri Bilgi Yöneticisi",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"URL'den dizeyi al",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Faturalandırma Sistemi Kimliği",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Toplu Müşteri Katılımı",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Müşteri Katılımı",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Müşteri Ayrıntılarını Toplayın",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Bitti",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"buraya",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Yukarı Bak!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Bu Müşteri için Yerleşik Sürüme Geçin",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Kaldırmak",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Atla",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Başka bir tane dene",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Toplu Müşteri katılımı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Müşteri katılımı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Harita Lisansları",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Yerleşik Başarı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Lütfen bir ödeme şekli seçin",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Yeni katılım tamamlanmadı. İlerlemek için 'Bu Müşteriye Devam Et' düğmesine tıklayın.",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"Faturalandırmada Arama!",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"EziDebit Kimliği",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Bir müşterinin EziDebit kimliğini almak için lütfen aşağıdaki adımları izleyin",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"EziDebit oturumu aç",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Ödeme Yapanlara Git",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Açmak için ödeme yapan kaydı tıklayın",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"ContractId'den sonraki dizeyi al",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"URL'de contractId = öğesinden sonra gelen dizeyi al",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"Örneğin:",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"değeri-için kopyalanabilir",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Müşteri detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"İş Ortağı Merkezi'nden eklenebilecek müşteriler",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"başarıyla oluşturuldu",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"başarıyla dahil edildi",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Sonraki adımlar",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Plan ata",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Banka adı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Müşteri Para Birimi Kodu",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Müşteri Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Ürün Paylaşılabilir mi?",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Satır 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Hat 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Microsoft Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Microsoft kiracısını seçin.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Ay",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Sonraki Fatura Tarihi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"NOT:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Yukarıdaki örnekte verildiği gibi dosyadaki kullanıcı ayrıntısı dışında bir içeriği güncellemeyin.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"isteğe bağlı:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Aboneliklerine karşı kullanıcılara atanmış olan lisanslarla ilgili ayrıntılara sahipseniz, aşağıdaki adımları izleyerek bunu bize sağlayabilirsiniz:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Bilgileri, şu adresten indirilebilecek biçimde doldurun:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Kullanıcılar'ı tıklayın ve müşteri kiracısındaki kullanıcılara bu portala erişim izni verin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Abonelik ayrıntısının kimliğine bürünme ve doğrulama",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Lütfen katılım işlemini tamamlamak için güncellenmiş bir dosyayı lisans ayrıntılarıyla yeniden yükleyerek hataları düzeltin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Bu örneği izleyerek köşeli parantez ([]) arasında kullanıcı e-posta adreslerini sağlamanız yeterlidir:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Dosyayı yükleyin ve tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Yeni giriş işlemini tamamlamak için SKIP'i tıklayarak lisans ayrıntılarını sağlamayı atlayabilirsiniz.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Müşteri listesine geri dönmek için Bitti'yi tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Şu anda yerleşik olan müşteriyi bulun",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Yüklediğiniz lisans ayrıntılarını işlerken hata olması durumunda <i class = \"fa fa-star red \"> </i> bir işlem düğmesi görünecektir",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Hataları görüntülemek için <i class = \"fa fa-star red \"> </i> düğmesini tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Lütfen hataları düzeltin ve tekrar yüklemeyi deneyin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Bir plan seçin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Kiracılar",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Dosya yükleme",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"seçmek",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Yıl",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"silindi",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Askıya alındı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Adı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Para Birimi Kodu",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Sağlayıcı",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Bu Müşteri için Yerleşik Sürüme Geçin",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Hayır",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Evet",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Müşteri ve harita aboneliklerini arayın",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Harita Lisansları",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Tamamlayınız",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Tamamlayınız",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Faturalandırma E-postası",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Faturalandırma Sistemi Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Microsoft Kimliği",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Biçimlendirme",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Eşleşen Ürünler",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Eşleşen Ürün Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Yüzde",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Sağlayıcı Ürün Adı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Tedarikçi Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Eşleştirilecek sağlayıcı ürünü aboneliği bulunamadı, Müşteri Ayrıntılarını Topla düğmesini tıklayarak devam edebilirsiniz.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"ÖZEL",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Profil seçeneğini kullanarak diğer ödeme yöntemlerine geçebilirsiniz. Faturalar size İş Ortağı tarafından e-posta yoluyla gönderilir",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Kullanım abonelikleri başarıyla yüklendi.",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Düzenle",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"yönetme",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adresler",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Varlık türü",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"rol",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Yeni Kullanıcı Ekle",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Fatura Adresi",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"kullanıcıyı düzenle",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"teslimat adresi",
      "CUSTOMER_OPTION_ALL_PERIODS":"Tüm Dönemler",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Faturayı Görüntüle",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Müşteri siparişleri",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Detayları göster",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"durum",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Müşteri siparişleri",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Aksiyon",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Bölüm Adı",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Sipariş Kimliği",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Sıralama:",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Sipariş verildi",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Site adı",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"durum",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Son yenilendi",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Daha fazla oku",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Müşteri Ödemelerine Dön",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"İçin Bekleyen Faturalar",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Mevcut Kupon Kodu",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"Hedef planın sahip olmadığı için <strong> {{PlanName}} </strong> 'den <strong> Hata! </strong> Müşteri <strong> {{targetPlanName}} </strong>' e taşınamaz. şu anda etkin olan aboneliklerle eşleşen teklifler.",
      "CUSTOMER_PLAN_CHANGE_INFO":"Mevcut plandaki bileşik teklife karşı en az bir etkin abonelik varsa, plan değişikliği henüz desteklenmemektedir.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Mevcut Plan",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Müşteri Abonelikleri",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Eşleşme bulundu",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Hedef Adı",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Hedef fiyat",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Bu kupon müşteriye zaten atanmış.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Başarılı! </strong> Müşteri, <strong> {{PlanName}} </strong> 'den <strong> {{targetPlanName}} </strong>' a taşınabilir. Devam etmek için <strong> Gönder </strong> 'i tıklayın.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Hedef Planı",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Geçerli kupon kodunu kullan",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(Silindi)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Eklentileri",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Değişiklik",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Aboneliği Yeniden Etkinleştir",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Eklentilerle Aboneliği Yeniden Etkinleştirin",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Aboneliği Askıya Al",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Paketi",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Operasyonel Varlık",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Sunmak",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Göz ardı etmek",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Hata detayları",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Talimatlar",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Bu ürünün faturalandırma döngüsünü {{targetBillingCycle}} olarak değiştirmek istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"{{ProductName}} {{ile Eklentileri yeniden etkinleştirmek istediğinizden emin misiniz? 'eklentilerle': ''}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"{{ProductName}} adlı kullanıcıyı askıya almak istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Adı başarıyla değiştirdi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"{{ProductName}} faturalandırma döngüsünü {{targetBillingCycle}} olarak değiştirdi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"satın alınan ürün için miktarı sıfıra güncelleyemiyorum",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"Abonelik güncelleme talebi başarıyla gönderildi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"{{ProductName}} başarıyla {{Adddons? 'eklentilerle': ''}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Mülkiyet başarıyla onaylandı",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{ProductName}} başarıyla askıya alındı",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Aboneliği iptal et",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"tarih",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Atamak",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"miktar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"durum",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"Miktar daha büyük olamaz",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Miktar (Mevcut)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"İçin miktar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Bağlantılı Abonelik",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Atamak",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Miktar (Mevcut)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"Miktar daha büyük olamaz",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"İçin miktar",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Tarihinde sona eriyor",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Dürbün",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Ürün siparisi",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Ürün siparisi",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Ayriyeten",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Bağlantılı",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Değişikliklerinizi geri almak istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Ürün sırasını güncellemek istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Müşteri Ürün sırası güncellendi",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Aktif",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"silindi",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Başarısız oldu",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Başarılı",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Askıya alındı",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"ülke",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Aboneliği Yeniden Etkinleştir",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Eklentilerle Aboneliği Yeniden Etkinleştirin",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Dürbün",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"durum",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Aboneliği Askıya Al",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Yenileme Tarihi",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Tarafından sahip olunan",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Bağlantılı Abonelik",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"durum",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"yer",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Kiraci",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Aboneliği iptal et",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Temel ayrıntılar",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Yorumlar",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Bildirimler",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Sahipliği Yönetme",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Kullanıcılar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Bağlantıyı kopyala",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Bağlantıyı kopyala",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"İndir",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Anahtarları ve indirmeleri alın",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Bağlantı panoya kopyalandı",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Dosya türünü seçin",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Dil Seçin",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Ürünü seç",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Aksiyon",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Dosya boyutu",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"İD",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"anahtar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"ad",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"miktar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Siparis verildi",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"yönetme",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"İşleme",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Uygulamak",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Ürünü Yönet",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Miktar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Tarafından sahip olunan",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Toplam tutar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Birim fiyat",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"yer",
      "CUSTOMER_PRODUCTS_VALIDITY":"Geçerlilik",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"E-posta",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Temel ayrıntılar başarıyla güncellendi",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"teslimat adresi",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Müşteri profili",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Bölümler",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Siteler",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Adres Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"E-posta Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Telefon Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adresler",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"E-mail adresleri",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Telefon numaraları",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Siteler",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Kayıt bulunamadı!",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Ekle",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Ödeme Profilleri",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Profil bulunamadı. EKLE düğmesine tıklayarak yeni bir ödeme profili ekleyin",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"teslimat adresi",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Kullanıcı hesapları",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Hesap Oluşturma Tarihi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Fatura Adresi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"C3 Başlangıç ​​Tarihi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Kanonik İsim",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Dükkan",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Onaylamak",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Bitti",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Sunmak",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Adres 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adres 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"ülke",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Alan adı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Birincil kişiye erişim izni verilsin mi (Müşteri Yöneticisi olarak)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Uygulamaya erişmek için davetiye gönderilsin mi?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Kiracı Kimliği",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"gecerli",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"mevcut değil",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Alan kullanılabilirliğini doğrulama",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Aksiyon",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"anlaşma",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"sözleşme",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Kiraci",
      "CUSTOMER_RECONCILIATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_REPORT":"Müşteri Raporları",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Ürünleri Filtrele",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Maksimum Sipariş Tarihi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Maksimum Miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Minimum Sipariş Tarihi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Minimum Miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Gün İçinde Sona Erer",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Arasında Sipariş Verildi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Ürün Raporu",
      "CUSTOMER_REPORTS":"Raporlar",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Ayarlama başarıyla kaydedildi.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Geçerli faturaya düzenleme olarak geçmiş aidatlar eklendi",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Ödeme başarıyla kaydedildi",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Varsayılan Ödeme Şekli",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"ad",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Ödeme şekli",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Etiketler",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"seçmek",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Alan adı",
      "CUSTOMER_SEARCH_FILTER":"Müşterileri Filtrele",
      "CUSTOMER_SEARCH_PROVIDER":"Sağlayıcı",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "CUSTOMER_SEARCH_RESET_OPTION":"Sıfırla",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Arama",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Sepete ekle",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Tüm Eklentileri Seç",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Dükkan",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Fatura döngüsü",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Faturalandırma Türü",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Geçerlilik",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_SITE_ADD_SITE":"Site Ekle",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site başarıyla silindi",
      "CUSTOMER_SITE_EDIT_SITE":"Siteyi Düzenle",
      "CUSTOMER_SITE_HEADER_TEXT":"Siteler",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Site başarıyla kaydedildi",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Azure Planı",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Azure Planı'nı seçin.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Yetkiler'i seçin.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Devam etmek istediğine emin misin?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Kabul etmek",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"reddetmek",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Aksiyon",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Ürünlere Geri Dön",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Aksiyon",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Fiyat",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Ürün adı",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"miktar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Kullanıcı adı",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Fiyat",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Ürün",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"miktar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"ad",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Lütfen en az bir dosya seçin.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Dosya başarıyla yüklendi",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"onaylamak",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Mutabakat Raporunu Yükleme",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Müşterilerin Faturalandırma Ayrıntılarını Yeniden Yükleme ...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Müşteri Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Aboneliğe Kullanıcı Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Abonelik Kullanıcılarına Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Mevcut (çoklu)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Toplu Müşteri Katılımı",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Mevcut (tekli)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Mevcut müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Faturalandırma Özetini Dışa Aktar",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"İhracat Kullanım Ayrıntıları",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Rapora git",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"buraya",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Yeni",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Yeni müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Yerleşik Müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Fatura Ayrıntılarını Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Geçerli Ayı Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonelik Özetini Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Kullanım Ayrıntılarını Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"seçmek",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Müşteri Ekle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"İçin Abonelik Ekle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"İçin Azure Abonelikleri",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonelik Kullanıcısını Yönet",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Teklifler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Plan ve teklifler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"İçin Mutabakat Raporu",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonelik kullanıcıları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Müşteri Yapılandırması",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"değer",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Kullanım Detayları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Kullanıcıları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"İçin Başlangıç ​​Hatalarını Doğrula",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Sonuçlandırmak",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"fatura",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"şirket Profili",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Lütfen bir ödeme şekli seçin",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Birincil iletişim",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"Fatura Onaylandı!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Bu fatura kesilmeden ve müşteri borçlandırılmadan önce yine de bu faturaya ayarlamalar (ek masraflar veya krediler) ekleyebilirsiniz. Müşteri artık bu faturayı görebiliyor ve sonlandırıldıktan sonra bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"Fatura Kesinleşti!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Kullanıcı artık bu faturayı görebiliyor ve bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"Fatura oluşturuldu!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Sonlandırılmadan ve müşteri borçlandırılmadan önce bu faturaya ayarlamalar (ek masraflar veya krediler) ekleyebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"Fatura, fatura adresinize gönderilecektir. Teşekkür ederim.",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Autopay",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"fatura",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Ödeme şekli",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Banka adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Tehlike Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Hata Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Grup ismi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Bilgi Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Müşteriden Gizle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Seçilen öğeler",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Satır 1",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Hat 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Ay",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Birincil alan adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"rol",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Uyarı Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Yıl",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"miktar",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Hayır",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Evet",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Etkin olmayan abonelik",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Miktar uyuşmazlığı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Erişimi Kaldır",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Aboneliği Etkinleştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Kullanıcılar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"CSP'de etkin",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Bileşen Adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Email kimliği",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Hata",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Tahmini maliyeti",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Müşteriden Gizle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Abonelikler müşteriler tarafından görülemez",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Son yenilendi",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"MSFT Faturası",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Başlangıç ​​Durumu",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"kâr",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"rol",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Eşik",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Kullanıcı adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Henüz abonelik eklenmedi.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Müşteri Planı Atama",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Fatura Döngüsünü Değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"İsmini değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Etiketleri Tanımla",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Vergi Tanımlama%",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"İş Ortağı Merkezi'ndeki etkinleştirme durumu uyuşmazlığını düzeltin",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Uyumsuzluğu düzelt ...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Uyumsuzluğu düzelt",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Ödeme Sağlayıcıları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Plan değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Hızlı Kimliğe Bürünme",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonelik Özetini Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Müşteri yapılandırması",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Otomatik Ödemeyi Test Et",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Doğrulama Hataları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"ÖZEL",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Toplam",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Aylık Bütçe-Bilgi-Uyarı-Hata-Tehlike",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"gecerli",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"mevcut değil",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Lütfen şablonu şuradan indirin:",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Kullanıcıyı sil",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Portala erişim izni verme",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"impersonate",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Portaldan erişimi iptal et",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"Değer 0 ile 100 arasında olmalıdır",
      "CUSTOMER_TAGS_EXAMPLE":"Kullanıcılar için erişim kontrolü tanımlanırken kullanılacak müşteri için etiket ve değer çiftlerini tanımlayın. <br/> Örnek: Değer olarak SupportAgent adında bir etiket ve bir ajanın takma adı (örneğin, Agent001) tanımlayabilirsiniz. Bir Yardım Masası aracısı, SupportAgent olarak ayarlanmış etiketlerden birine ve Agent001 olarak bu özel etiket için değerlerden birine sahipse, aracı, {SupportAgent, Agent001} etiket değeri çiftine sahip müşterileri görebilir.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"ad",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"değer",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Sil",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"değer",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Bekleyen Faturaları Görüntüle",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"İçin ödemeler",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Müşteri",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Müşteri",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Kullanıcı bilgileri başarıyla kaydedildi",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Yeni Kullanıcı Ekle",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Erişim İzni Ver",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Kullanıcıyı Yönet",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Erişimi İptal Et",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Bölüm",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"E",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"rol",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Rol Türü",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"yer",
      "CUSTOMER_USERS_HEADING":"Kullanıcılar",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"E",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"rol",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Faturaları / Ödemeleri İndirin",
      "customerconfiguration":"Müşteri yapılandırması",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"ACH ayrıntılarının bir işletme hesabıyla ilgili olup olmadığını lütfen belirtin",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Bu müşteri zaten seçildi",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Müşteri sistemde zaten var",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Müşteri başarıyla oluşturuldu",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Müşterinin işe alım süreci devam ediyor",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Lütfen en az bir dosya seçin.",
      "CUSTOMERONBOARDING_SUCCESS":"Müşteri başarıyla dahil edildi.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Dosya yüklenirken bir hata oluştu",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} zaten {offername} için bir lisansa sahip",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Sağlanan kullanıcı sayısı mevcut lisans sayısından fazla. Lütfen kullanıcı ayrıntılarını düzeltin ve tekrar deneyin.",
      "customers":"Müşteriler",
      "Customers":"Müşteriler",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Aksiyon",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"iş",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Fatura Profiline Bağlantı",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Sağlayıcı Bağlantısı",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Faturalar Oluşturma",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Müşterilerin Kârlılık Raporunu Yeniden Yükleme ...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Ayarlama Ekle",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Teslim tarihini düzeltme olarak ekleyin!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Faturayı İndir",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Rapora git",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Tarihe Göre Tarih",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Teklife Göre Tarih",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Ödemeyi Başlat",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Ücreti Müşteri",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"İçin fatura",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"İçin aboneliklerle kârlılık",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"İçin Abonelik Değişiklik Geçmişi",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"Alan adı kullanılabilirliği kontrol edilemiyor",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"{{Username}} sitesine erişim vermek istediğinizden emin misiniz?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Fatura bulunamadı. Lütfen desteğe başvurun.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adres",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Müşteri",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Hesap özeti",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Bitis tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Fatura tutarı",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Fatura tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Fatura ödeme tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Oluşturulan Fatura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Fatura Durumu",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"Fatura oluşturuldu!",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Bu işleme koyulmadan ve müşteri borçlandırılmadan önce bu faturaya düzenleme (ek masraf veya kredi) ekleyebilirsiniz.",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"Fatura işleme koyuldu!",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Müşteri artık bu faturayı görebiliyor ve bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Geçmiş faturalardan alınan aidatlar",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Geçmiş faturalardan aidat varmış gibi görünüyor. Müşteriden yapılan tüm ödemeleri kaydettiğinizden eminseniz,",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"mevcut faturaya düzenleme olarak <strong> {{InvoiceDueAmount}} </strong> eklemek için.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Miktar aidatları var gibi görünüyor",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"toplamda.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Düzenle",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Sil",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Fatura Ayrıntısı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Lisans Tabanlı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Kullanım Bazlı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Hareketler",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Şarj türü",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"İndirim tutarı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Teklif fiyatı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Büyük Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Ara Toplam Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Abonelik Adi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Vergi miktarı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Birim fiyat",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Kayıt Ödeme",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Ödeme miktarı",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Arıza Nedeni",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Ödenen miktar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Ödeme Kimliği",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Fatura Ödemeleri",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"İşlem Kimliği",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Bu faturaya yapılan ödemeleri kaydedin.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"Örneğin:",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Taksitli kısmi ödeme vb.",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Genel Toplam",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Ara toplam",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Arıza Nedeni",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Miktar",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"tarih",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Ödeme Durumu",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Geçmiş dahil fatura bilgileri başarıyla yenilendi",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Geçmiş dahil olmak üzere kullanım ayrıntıları başarıyla yenilendi",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Faturalandırma E-postası",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Planlar",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"ad",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Faturalandırma sisteminde eşleşen bir kayıt mı arıyorsunuz?",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Faturalandırma Sisteminde {{ID}} Referans Kimliği ile bir eşleşme bulundu.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Tedarikçi Müşteri Kimliği ile bir eşleşme için C3'teki müşterileri arama",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Adlı bir müşteri buldum - {{customername}}",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"{{SağlayıcıAdı}} içindeki Sağlayıcı Müşteri Kimliği ile eşleşecek müşterileri arama",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"{{SağlayıcıAdı}} alanında {{customername}} adında bir müşteri buldum",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Kompozit olarak sunulan çocuk miktarları aynı olmalıdır",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"CSP'deki bir veya daha fazla teklif, faturalandırmada aynı teklifle eşleştirilir. Lütfen eşlemenizi ayarlayın",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"Plandaki {{Name}} teklifinde birden fazla eşleşen CSP Aboneliği var.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"Faturalandırmadaki {{nameinbilling}} aboneliği eşleştirilemedi",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"{{Name}} teklifinin İş Ortağı Merkezi'nde eşleşen CSP abonelikleri yok",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"Kitap Ortağı Merkezi'ndeki aboneliğin - {{name}} eşleme planında eşleşen bir teklifi yok",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Aşağıdaki teklifler CSP abonelikleriyle eşleştirilmelidir -",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"{{Name}} teklifinde, sahip olduğu koltuklardan daha fazla kullanıcı belirtildi",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Aboneliklerin miktarları İş Ortağı Merkezi ile eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"{{Subscriptionname}} aboneliğinin miktarları İş Ortağı Merkezi ile Faturalandırma arasında eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Aboneliklerin miktarları İş Ortağı Merkezi ile Faturalandırma arasında eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"{{Cspsubscription}} CSP aboneliğine karşı koltuk sayısı, plandaki ilgili tekliflere karşı sağlanan tüm sayıların toplamıyla eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Bir veya daha fazla CSP teklifi eşlenmiyor veya CSP'de eksik abonelik var",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"{{CustomerName}} için abonelikler yenilenirken bir hata oluştu",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Müşteri için yenilenen abonelikler - {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"Müşteri kaydı başarıyla tamamlandı. Portala giriş yapma talimatlarını içeren {{kayıtlı posta}} adresine bir e-posta gönderilir.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"Müşteri kaydı başarıyla tamamlandı.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"Müşteri kaydı başarıyla tamamlandı. Portalda oturum açın.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Lütfen düzenleme için bir teklif ilişkilendirin",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Müşteri Ödemeleri",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Fatura tutari",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"tarih",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Bölüm Adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Yeni fiyat",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Eski fiyat",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Diğer maliyet",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Vergi Öncesi Fatura Tutarı",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Vergi Öncesi MSFT Faturası",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"kâr",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Kâr%",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Site adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Henüz abonelik eklenmedi.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Müşteriyi Sil",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Planları Yönet",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Aksiyon",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"ad",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Kullanıma dayalı satır öğelerini indir",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Aboneliklerle karlılık",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Kârlılık Raporunu Yeniden Yükle",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Abonelik Değişiklik Geçmişi",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Faturayı Görüntüle",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Kullanım Satır Öğelerini Görüntüle",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"Azure aboneliğine {{username}} başarıyla eklendi",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"Azure aboneliğine {{azuresubscription}} {{username}} başarıyla eklendi",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} Azure aboneliğinden başarıyla kaldırıldı",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Kullanıcıyı silmek istediğinizden emin misiniz?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}}, {{azuresubscription}} 'dan başarıyla kaldırıldı",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Azure aboneliği başarıyla kaydedildi",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Fatura durumu başarıyla güncellendi",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Sipariş başarıyla verildi. OrderId: {{orderId}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Şifre Alıcıları",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Alıcıların E-postası",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Virgülle ayrılmış E-posta alıcılarını girin",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Yeni varlık ekle",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"yönetme",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Yeni Varlık Ekle",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"İşletme Kuruluşları",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Varlık türü",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"ad",
      "DAKİKA":"Dakika (lar)",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Şartlar ve Koşullar Kabul Günlüğü",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Faturayı Görüntüle",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"<b> {{BillingStartDate}} </b> 'den <b> {{BillingEndDate}} </b>' e kadar <b> {{InvoiceGeneratedDate}} </b> fatura dönemi için oluşturulan yeni fatura > {{INVOICEAMOUNT}} </ b>.",
      "DASHBOAR_TEXT_DATE":"tarih",
      "DASHBOAR_TEXT_EMAIL":"E-posta",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"Ezidebit Anlaşma URL'si",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"Microsoft Müşteri Sözleşmesi URL'si",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"İş Ortağı Şartlar ve Koşulları URL'si",
      "DASHBOAR_TEXT_TIME":"Zaman",
      "Dashboard":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Müşteri Analizi",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Raporlar",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Lisans Özet Raporu",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Kullanım Raporu",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Profili Güncelle",
      "DASHBOARD_CAPTION_PARAGRAPH":"Bulutun gücü ile başarılı bir iş yürütmek için kaynaklar sağlayın.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Cloud Market Place'e Hoş Geldiniz",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft,",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Herşey",
      "DASHBOARD_COST_TEXT":"Maliyet",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Bölüm Sayısı",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Bölümler",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Bölümler",
      "DASHBOARD_HEADER_TEXT_WARNING":"Uyarı!",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Lisanslama portalınıza hoş geldiniz",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Aboneliklerinizi ve kullanıcılarınızı yönetmek için aşağıdaki kutucukları hızlı bağlantılar olarak kullanın.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Bu portal, kiracınıza yeni kullanıcılar eklemenize, lisans atamanıza ve şifrenizi sıfırlamanıza olanak tanır.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Ek lisanslar ve abonelikler satın alabilirsiniz.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Profilinizi güncelleyin (Fatura adresi ve Ödeme yöntemi)",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Site sayısı",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Siteler",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Siteler",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Azure Abonelikleri",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"{{StartDate}} 'dan Mevcut Azure Kullanımı",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Ürün",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Lisans Abonelikleri",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Daha fazla göster",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Ofis 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Kullanıcılar",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Daha fazla göster",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Başarısız oldu",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"Devam etmekte",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Son senkronizasyon {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"<b> {{BillingStartDate}} </b> 'den <b> {{BillingEndDate}} </b>' e kadar <b> {{InvoiceGeneratedDate}} </b> fatura dönemi için oluşturulan yeni Fatura > {{INVOICEAMOUNT}} </ b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"faturayı görüntülemek için. Zaten ödenmişse yoksay.",
      "DASHBOARD_WARNING_MESSAGE":"C3'ü kullanmadan önce ödeme profilinizi güncellemelisiniz. Güncellemek için lütfen aşağıdaki düğmeyi tıklayın.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Ürün Açıklaması",
      "DEACTIVATE_CONFORMATION":"Devre dışı bırakmak istediğinizden emin misiniz?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"Müşteri Ürünü Devre Dışı Bırakılamadı",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Devre Dışı Bırakılan Müşteri Ürünü",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Bu kullanıcının bu teklifi kaldırma izni yok",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü silinirken hata oluştu",
      "DELETE_CUSTOMER_FAILURE":"Müşteri Adı Güncelleme başarısız",
      "DELETE_CUSTOMER_SUCCESS":"Müşteri Adı Güncelleme başarılı",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"E-posta bildirimi alıcıları silinirken hata oluştu",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Seçilen fatura düzenlemesi silinecek. Devam etmek istiyor musun?",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları silinirken hata oluştu",
      "DELETE_PARTNER_USER":"İş Ortağı Kullanıcısını Sil",
      "DELETE_PARTNER_USER_FAILURE":"Ortak kullanıcı silinemedi",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"Bu kaydı silmek istediğinizden emin misiniz?",
      "DELETE_USER_EXCEPTION":"Bu kullanıcının C3'e biraz erişimi var, bu yüzden lütfen bunları kaldırın",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Müşteri Para Birimi",
      "DESC_PLAN_CURRENCY_OPTION":"Para Birimi Planla",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Bize Ulaşın",
      "Description":"Açıklama",
      "DESCRIPTION_FOR":"Açıklaması",
      "Devam etmekte":"Devam etmekte",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Engelli Eski ve Etkin Yeni Güvenli Model",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Onay için teşekkürler. Uygulama, yeni güvenlik yönergelerini izleyerek İş Ortağı Merkezinize erişecektir.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Eski Devre Dışı Bırakma ve Yeni Güvenli Modeli Etkinleştirme Başarısız Oldu",
      "DISP_CATEGORY_COUPON_ENTITY":"Kategori",
      "DISP_PROVIDER_COUPON_ENTITY":"Sağlayıcı",
      "DISP_SALETYPE_COUPON_ENTITY":"SaleType",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Sıfırla",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Pozitif bir değer girin",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Kategori",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Aksiyon",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Faturalandırma türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Kategori",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Faturalandırma Dönemi Türü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"ad",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"ad",
      "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"İptal etmek istiyor musun?",
      "DOCUMETATION_HEADING":"belgeleme",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Para Birimi Kodu",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Fatura tutari",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Nitelikli Ad",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Satış Tipi",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"durum",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"uygulanabilirlik",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Atandı",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Kupon Kodu",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"İndirim",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Tarihinde sona eriyor",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"ad",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Ödeme şekli",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Etiketler",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Kiracılar",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Dönüşüm oranı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Fatura numarası",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"ad",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"miktar",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Abonelik Adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Vergi kodu",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Birim fiyat",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Koltuk alımı için faturalandırma kuralı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Koltukların azaltılması için faturalandırma kuralı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Faturalandırma Türü",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Tüketim türleri",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Maliyet fiyatı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"ad",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Satış ücreti",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Satış Tipi",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"durum",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Makro Değeri",
      "DOWNLOAD_COLUMN_PLAN_NAME":"ad",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Faturalandırma Türü",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Kategori",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Makro Değeri",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Plan Adı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Plan Adı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Hizmet Türleri",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"durum",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Kategori",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Tüketim Türü",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Bölüm Adı",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"İndirim",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Ürün siparisi",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"miktar",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Site adı",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"durum",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Sağlayıcı Kiracı Kimliği",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Birim fiyat",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Kategori",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Is ortagi maliyeti",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Para Birimi Kodu",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Ürün adı",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Saglayici Adi",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Perakende fiyatı",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Geçerlilik",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Geçerlilik türü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Para Birimi Kodu",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"müşteri adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Alan adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Yeni fiyat",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Yeni Miktar",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Yeni statü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Eski fiyat",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Eski Miktar",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Eski Durum",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Ürün adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Azure Abonelik Kimliği",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Geçerlilik",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Geçerlilik türü",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"İndir",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Fatura Satır Öğeleri",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Fatura Ödemeleri",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Birden Çok Bağlantı",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Bağlantı Tek",
      "DROPDOWN_PLACEHOLDER_SELECT":"seçmek",
      "DUE_STATUS_DUE":"nedeniyle",
      "DUE_STATUS_FINALIZED":"Faturayı kesinleştirdi",
      "DUE_STATUS_OVERDUE":"Süresi Sonu",
      "DUE_STATUS_PAID":"ödenmiş",
      "DUE_STATUS_PARTIAL_PAID":"Kısmen ödenmiş",
      "DURUM: BEKLEMEDE":"Bekliyor",
      "Düzeltilmeyecek":"Düzeltilmeyecek",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Grubu Düzenle",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Ürün",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"özet",
      "EKİM":"Ekim",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"Müşteri profilinde faturalandırma ilgili kişisi olarak belirtilen e-posta adresi",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"E-posta ile bildirilmesi gereken kişilerin e-posta adreslerinin listesi",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"HelpDesk dışındaki roller",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"BillingContacts",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"İnsanlar",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"rol",
      "EMAIL_TABLE_HEADER_ACTIONS":"Aksiyon",
      "EMAIL_TABLE_HEADER_EMAIL":"E-posta",
      "EMAIL_TEMPLATE_SUBMIT":"Sunmak",
      "EMAIL_TYPE_BILLING":"fatura",
      "EMAIL_TYPE_COMPANY":"şirket",
      "EMAIL_TYPE_INVOICE":"Fatura",
      "EmailConfigurations":"SMTP",
      "ENABLE_RESELLER_PLAN":"Bayiye Plan Ata",
      "ENABLE_RESELLER_PLAN_FAILURE":"Bayiye Plan Atanamadı",
      "ENGAGE_EFFECTIVE_FROM":"İtibaren geçerli",
      "ENGAGE_EXPIRES_ON":"Tarihinde sona eriyor",
      "ENGAGE_HEADER_ACTIONS":"Aksiyon",
      "ENGAGE_HEADER_ACTIONS_BACK":"Listeye geri dön",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "ENGAGE_HEADER_EXPIRES_ON":"Tarihinde sona eriyor",
      "ENGAGE_HEADER_MESSAGE":"İleti",
      "ENGAGE_HEADER_TITLE":"Başlık",
      "ENGAGE_SUBMIT":"Sunmak",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Lütfen QuickBooks'a göndermek için en az bir fatura seçin.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"QuickBooks'a fatura gönderme isteğiniz kabul edilir ve işleme koyulur. Devam eden güncellemeler için lütfen <strong> Yazı Günlüklerini Görüntüle </strong> düğmesini tıklayın.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Faturaları çevrimiçi QuickBooks'a yükleme isteği sıralanamadı",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Faturaları Xero'ya yükleme isteği sıralanamadı",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Talimatlar",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Aksiyon",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Tamamlayınız",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Oluşturma Tarihi",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Bölüm",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Bölüm Adı",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"ad",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Satış ücreti",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"yer",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Site adı",
      "ENTITY_DESC_CART":"Araba",
      "ENTITY_DESC_COUPON":"Kupon",
      "ENTITY_DESC_CURRENCY":"Para birimi",
      "ENTITY_DESC_CUSTOMER":"Müşteri",
      "ENTITY_DESC_DEPARTMENT":"Bölüm",
      "ENTITY_DESC_INVOICE":"Fatura",
      "ENTITY_DESC_PLAN":"Plan",
      "ENTITY_DESC_PRODUCT":"Ürün",
      "ENTITY_DESC_SHOP":"Dükkan",
      "ENTITY_DESC_SITE":"yer",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Şartlar ve koşullar",
      "ENTITY_NAME":"Varlık adı",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"ülke",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"E-posta",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"durum",
      "ERROR__DATA_CONTACT_TYPE":"İletişim Türü",
      "ERROR__DATA_PAYMENT_TYPE":"Ödeme şekli",
      "ERROR__DATA_PROFILE_TYPE":"Profil Tipi",
      "ERROR__DATA_URL":"URL",
      "ERROR_BUTTON_CONTINUE":"Devam et",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Müşteri Ürün Kimliği",
      "ERROR_C3_ENTITY_NAME":"Varlık adı",
      "ERROR_C3_RECORD_ID":"Kayıt Kimliği",
      "ERROR_C3_USER_ID":"Kullanıcı kimliği",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"Faturalandırma Müşteri Kimliği",
      "ERROR_DATA_BILLING_REFERENCE_ID":"Faturalandırma Referans Kimliği",
      "ERROR_DATA_C3_CUSTOMER_ID":"Müşteri C3 Kimliği",
      "ERROR_DATA_C3_RECORD_ID":"Kayıt Kimliği",
      "ERROR_DATA_C3_RESELLER_ID":"Bayi C3 Kimliği",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"C3 Abonelik Kimliği",
      "ERROR_DATA_COUNTRY_CODE":"Ülke kodu",
      "ERROR_DATA_CUSTOMER_NAME":"müşteri adı",
      "ERROR_DATA_DOMAIN_PREFIX":"Alan adı",
      "ERROR_DATA_EMAIL_ID":"Email kimliği",
      "ERROR_DATA_ENTITY_NAME":"Varlık adı",
      "ERROR_DATA_EVENT_NAME":"Etkinlik adı",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "ERROR_DATA_PROVIDER_NAME":"Sağlayıcı Adı",
      "ERROR_DATA_RESELLER_ID":"Referans Kimliği",
      "ERROR_DATA_RESELLER_MPNID":"Bayi Sağlayıcı Kimliği",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"Bayi Sağlayıcı Kimliği",
      "ERROR_DATA_SEARCH_VALUE":"Arama Değeri",
      "ERROR_DATA_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "ERROR_DATA_TAX_CODE":"Vergi kodu",
      "ERROR_DATA_TRANSACTION_REFERENCE":"İşlem referansı",
      "ERROR_DATA_USER_ID":"Kullanıcı kimliği",
      "ERROR_DELETE_DEPARTMENT":"Bu departman ilişkili kayıtları içerdiğinden bu kaydı silemezsiniz.",
      "ERROR_DELETE_SITE":"Bu site ilişkili kayıtları içerdiğinden bu kaydı silemezsiniz.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Sağlayıcıda kiracı oluşturulamıyor.",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Authorize.net kişisinden yanıt yok",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Müşteri bulunamadı.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Bu ürün abonelikleriniz arasında bulunamadı",
      "ERROR_DESC_BAD_INPUT":"Girdilerinizde bir sorun var gibi görünüyor",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"Kötü yönetim nedeniyle müşteri yönetimi başarısız oldu.",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu indirilirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"Kötü giriş nedeniyle müşteri getirilemedi.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Hatalı girdi nedeniyle ödeme profillerinin alınamadı.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Hatalı giriş nedeniyle ödeme durumu alınamadı.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu alınırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"Ödeme profili eksik olduğu için işlem başarısız oldu.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"Ödeme profili yapılandırması eksik olduğu için işlem başarısız oldu",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Ödeme profiliniz kaydedilirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Ödeme profillerinizle ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Faturalandırma sağlayıcınızla ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Ödeme profilinizi kaydetmeyle ilgili bir sorun var, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Lisans tüketim raporu alınırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Sağlayıcı bulunamadı",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Hizmet uygulanmadı. Lütfen desteğe başvurun.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Faturalandırma sağlayıcısı için desteklenen ödeme türlerinizle ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Faturalandırma müşterisi oluşturulurken / güncellenirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Bu müşteri tarafından zaten kullanılan bu abonelik",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Müşteri silinirken bir hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Resminizi yüklerken bir hata oluştu. Lütfen resim boyutlarını bir kez daha kontrol edin.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Hatalı istek nedeniyle başarısız oldu. Lütfen tekrar deneyin.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Bir çakışma nedeniyle başarısız oldu. Lütfen kimlik bilgilerinizi tekrar kontrol edin",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Hizmet kullanılamıyor. Lütfen yapılandırmayı kontrol edin",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Ödeme başlatılırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"Kontrol paneli satıcısı AD APP Kimliği veya iş ortağı kiracı kimliği mevcut değil.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Resim yüklenemedi. Lütfen İnternet bağlantınızı kontrol edin ve görüntü boyutunun verilen teknik özelliklere uygun olduğundan emin olun.",
      "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Geçersiz Müşteri",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"İş Ortağı Merkezi istisnası, abonelik durumunu değiştirmeye çalışırken ortaya çıktı. Lütfen desteğe başvurun.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"MPN ID doğrulanırken iş ortağı merkezinden hata. Lütfen girişinizi tekrar kontrol edin.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"İş Ortağı Merkezi'ne bağlanırken bir sorunla karşı karşıyayız. Lütfen yöneticinin belirteci yenilemesine izin verin ve tekrar deneyin. Daha fazla yardım için desteğe başvurun.",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Kullanıcı oluşturulurken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Kullanıcı silinirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Tüm müşterileri almaya çalışırken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Ülke doğrulama kurallarını almaya çalışırken Ortak Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Müşteri almaya çalışırken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS İş Ortağı Merkezi, profilinizi almaya çalışırken bir hata verdi.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Yenileme belirteci getirilemedi.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Kiracı kullanıcısı alınırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Kullanıcıya atanan lisanslar alınırken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Kullanıcı ayrıntılarını alırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Kullanıcı rolleri alınırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Kullanıcıları alırken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Müşteri onayı kaydedilirken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Kullanıcı güncellenirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Kullanıcı lisansı atamaları güncellenirken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Kullanıcı şifresi güncellenirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Müşteri alırken iş ortağı merkezinden gelen hata.",
      "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Alan adı mevcut değil",
      "ERROR_DESC_NOT_FOUND_ERROR":"İstek bulunamadı",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"İsteğiniz işlenirken bir hata oluştu",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"Onboading Geçersiz kiracı nedeniyle başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"Sağlayıcı istisnası nedeniyle müşteri yönetimi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Sağlayıcı istisnası nedeniyle ödeme profili yönetimi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"Ödeme sağlayıcısı portalındaki bir hata nedeniyle kullanıcı silme işlemi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Kimliğine sahip bir müşteri bulunamadı",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Sağlayıcı istisnası nedeniyle ödeme profillerinin alınamadı.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Sağlayıcı istisnası nedeniyle ödeme durumu alınamadı.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Faturalandırma müşterisi yönetilirken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Ödeme profili yönetilirken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"İşlem oluşturulurken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Sahiplik operasyonel varlıklarla paylaşıldığı için ürün durumunu değiştirme izniniz yok",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"Ürün durumunun güncellenmesi tamamen başarılı olmadı, lütfen yardım için destek ekibimizle iletişime geçin",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Vergi kodu zaten var, Lütfen benzersiz bir vergi kodu seçin.",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"İsteğiniz işleme koyulamıyor, lütfen destek ile iletişim kurun",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Bu özelliğe erişme yetkiniz yok",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"Kullanım yeri geçersiz",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Veritabanına erişilirken hata oluştu",
      "ERROR_DETAILS":"Hata detayları",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Bu departman adı bu müşteri ve sitede zaten var.",
      "ERROR_DUPLICATE_SITE_NMAE":"Bu site adı bu müşterinin altında zaten var.",
      "ERROR_FIELD_REQUIRED":"Bu alan gereklidir",
      "ERROR_GETTING_HOSTED_TOKEN":"Barındırılan jeton alınırken hata oluştu",
      "ERROR_GETTING_STRIPE_DETAILS":"Şerit ayrıntıları alınırken hata oluştu",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Bu işlemi gerçekleştirmek için yeterli izniniz yok gibi görünüyor",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Yasak!",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ettiği için ürünün durumu güncellenemiyor. Lütfen daha sonra tekrar deneyin.",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Koltukları azaltma izniniz yok",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Geçersiz Müşteri",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"Kiracı kullanıcı geçerli değil",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"Durum değişikliği geçerli değil.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Bayi ilişkisi talep etmek için mesaj bulunamadı, lütfen C3 desteğine başvurun",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Bu e-postaya sahip kullanıcı zaten var veya bu kullanıcıyı ekleme izniniz yok",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Bu müşteri Microsoft csp ile bağlantılı değil",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"İsteğiniz işleme koyulamıyor.",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Fatura satır öğeleri raporu dışa aktarılırken hata oluştu. Lütfen yöneticiye başvurun",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Fatura özeti raporu dışa aktarılırken hata oluştu.Lütfen yöneticiye başvurun",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Dosya yüklenirken bir hata oluştu",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Resim yüklenirken hata oluştu. Lütfen başka bir resimle tekrar deneyin.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Lütfen ör. .Jpg, .png vb. Resim uzantılarını seçin.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"MPN ID doğrulanırken iş ortağı merkezinden hata. Lütfen girişinizi tekrar kontrol edin.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Kullanıcı oluşturulurken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Kullanıcı silinirken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Kiracı kullanıcısı alınırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Kullanıcı ayrıntılarını alırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Kullanıcı rolleri alınırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USERS":"Kullanıcıları alırken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Kullanıcı güncellenirken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Kullanıcı lisansı atamaları güncellenirken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Kullanıcı şifresi güncellenirken iş ortağı merkezinden hata",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"İsteğiniz işlenirken bir hata oluştu",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Lütfen müşterinin Microsoft Müşteri Sözleşmesi'ni kabul ettiğini onaylayın",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Teknede / linkte kiraladığınız kiracı müşterilerinizden birinde var. Lütfen başka bir kiracı deneyin.",
      "ERROR_RESELLER_PROVIDER_ID":"Bayi Sağlayıcı Kimliği",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Sağlayıcı kiracısı bulunamadı.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Veritabanına erişilirken hata oluştu",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Lütfen Xero'ya göndermek için en az bir fatura seçin.",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"tamam",
      "ERRORMESSAGEPOPUP_HEADER":"Lütfen aşağıdaki listeye eklentiler için en az ön koşullardan birini ekleyin",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Ayriyeten",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Önkoşul Teklif",
      "ERRORS_FROM_PREVIOUS_ACTION":"Son işleminiz hatalarla sonuçlandı, ayrıntılar için tıklayın ...",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"İşleminiz gerçekleştirilirken bir hata oluştu. Lütfen Destek ile iletişime geçin.",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"E-posta",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"İsim",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Soyadı",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefon numarası",
      "EVENT_DATA_ACCOUNT_NUMBER":"Hesap numarası",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Aksiyon",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Hata",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"durum",
      "EVENT_DATA_ADDRESS_CITY":"Kent",
      "EVENT_DATA_ADDRESS_COUNTRY":"ülke",
      "EVENT_DATA_ADDRESS_STATE":"Durum",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Zip",
      "EVENT_DATA_AGREEMENT":"anlaşma",
      "EVENT_DATA_AGREEMENT_DATE":"Sözleşme Tarihi",
      "EVENT_DATA_AGREEMENT_LINK":"Sözleşme Bağlantısı",
      "EVENT_DATA_AGREEMENT_NAME":"anlaşma",
      "EVENT_DATA_AGREEMENT_TYPE":"Sözleşme Türü",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Aksiyon",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Hata",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"durum",
      "EVENT_DATA_BCC_RECIPIENTS":"BCC Alıcıları",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Fatura Döngüsü Adı",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Ödeme Takma Adı",
      "EVENT_DATA_BILLING_PERIOD_ID":"Faturalandırma Dönemi Kimliği",
      "EVENT_DATA_BILLING_PROVIDER":"Faturalandırma Sağlayıcısı",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Faturalandırma sağlayıcısı müşteri kimliği",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Faturalandırma sağlayıcısı müşteri adı",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Faturalandırma sağlayıcısı satıcı kimliği",
      "EVENT_DATA_BILLING_PROVIDER_USER":"Kullanıcı kimliği",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"Kullanıcı kimliği",
      "EVENT_DATA_CARD_TYPE":"Kart tipi",
      "EVENT_DATA_CART_ID":"Sepet Kimliği",
      "EVENT_DATA_CATEGORY_ID":"Kimlik kategorisi",
      "EVENT_DATA_CATEGORY_NAME":"Kategori adı",
      "EVENT_DATA_CC_RECIPIENTS":"CC Alıcıları",
      "EVENT_DATA_CHANGE_TYPE":"Değişiklik Türü",
      "EVENT_DATA_CLONED_FROM_PLAN":"Klonlandı",
      "EVENT_DATA_COMPANY_NAME":"Şirket Adı",
      "EVENT_DATA_CONSUMPTION_TYPE":"Tüketim Türü",
      "EVENT_DATA_CONTRACT":"sözleşme",
      "EVENT_DATA_CONVERSION_RATE":"Dönüşüm oranı",
      "EVENT_DATA_COUNTRY_CODE":"Ülke kodu",
      "EVENT_DATA_COUPON_CODE":"Kupon Kodu",
      "EVENT_DATA_COUPON_DESCRIPTION":"Kupon Açıklaması",
      "EVENT_DATA_COUPON_DISCOUNT":"İndirim",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Tarihinde sona eriyor",
      "EVENT_DATA_COUPON_VALID_TILL":"Kadar geçerli",
      "EVENT_DATA_CURRENCY_CODE":"Para Birimi Kodu",
      "EVENT_DATA_CUSTOMER_C3_ID":"Müşteri C3 Kimliği",
      "EVENT_DATA_CUSTOMER_NAME":"müşteri adı",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Müşterinin Yeni Adı",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Müşterinin Eski Adı",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Müşteri Referans Kimliği",
      "EVENT_DATA_DEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Fatura döngüsü",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Açıklama",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"ad",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Maliyet fiyatı",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Geçerlilik",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Geçerlilik türü",
      "EVENT_DATA_DOMAIN_NAME":"Alan adı",
      "EVENT_DATA_EFFECTIVE_FROM":"İtibaren geçerli",
      "EVENT_DATA_EMAIL":"E-posta",
      "EVENT_DATA_EMAIL_ADDRESS":"E-posta",
      "EVENT_DATA_END_DATE":"Bitiş tarihi",
      "EVENT_DATA_ENTITY_NAME":"Varlık adı",
      "EVENT_DATA_ERROR":"Hata",
      "EVENT_DATA_EVENT":"Etkinlik",
      "EVENT_DATA_EVENT_ID":"Etkinlik Kimliği",
      "EVENT_DATA_EVENT_NAME":"Etkinlik adı",
      "EVENT_DATA_EXCEPTION_MESSAGE":"İstisna Mesajı",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Müşteri Kimliği",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Abonelik Kimliği",
      "EVENT_DATA_GROUP_DESCRIPTION":"Grup Açıklaması",
      "EVENT_DATA_GROUP_NAME":"Grup ismi",
      "EVENT_DATA_IMPERSONATOR":"Taklitçi",
      "EVENT_DATA_INPUT_DATA":"Ayrıntı isteyin",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Dahili Müşteri Kimliği",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Dahili Müşteri Ürün Kimliği",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"C3 Ürün Kimliği",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Dahili Abonelik Kimliği",
      "EVENT_DATA_INVOICE_NUMBER":"Fatura numarası",
      "EVENT_DATA_IS_ACCEPTED":"Kabul edildi",
      "EVENT_DATA_IS_ENABLED":"Etkin",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"IsImmediateProvisioning",
      "EVENT_DATA_LINE_ITEM":"Satır Öğesi",
      "EVENT_DATA_LOGGED_IN_USER":"Giriş Yapan Kullanıcı",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Kullanıcı Lisansı Ayrıntılarını Yönetme",
      "EVENT_DATA_MESSAGE":"İleti",
      "EVENT_DATA_Name":"ad",
      "EVENT_DATA_NAME":"ad",
      "EVENT_DATA_NEW_ENTITY_NAME":"Yeni Varlık",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transfer edildi",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PRICE":"Yeni fiyat",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Bildirim Alıcıları",
      "EVENT_DATA_OFFER_NAME":"Teklif Adı",
      "EVENT_DATA_OLD_ENTITY_ID":"Eski Varlık",
      "EVENT_DATA_OLD_ENTITY_NAME":"Eski Varlık",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Gönderen",
      "EVENT_DATA_OLD_PRICE":"Eski fiyat",
      "EVENT_DATA_PAID_AMOUNT":"Ödenen miktar",
      "EVENT_DATA_PAID_DATE":"Ödenen tarih",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Ana Ürün Adı",
      "EVENT_DATA_PARTNER_NAME":"Ortak adı",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Ödeme Profili Takma Adı",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"Ödeme Profili Kimliği",
      "EVENT_DATA_PAYMENT_TYPE":"Ödeme şekli",
      "EVENT_DATA_PHONE_NUMBER":"Telefon numarası",
      "EVENT_DATA_PLAN_DESCRIPTION":"Açıklama",
      "EVENT_DATA_PLAN_NAME":"Plan Adı",
      "EVENT_DATA_PLAN_TYPE":"Plan Türü",
      "EVENT_DATA_PRICE":"Fiyat",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Ürün C3 Abonelik Kimliği",
      "EVENT_DATA_PRODUCT_NAME":"Ürün adı",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Ürünün Yeni Adı",
      "EVENT_DATA_PRODUCT_PRICE":"Ürün fiyatı",
      "EVENT_DATA_PROVIDER":"Sağlayıcı",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"Sağlayıcı İşletme Kimliği",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Sağlayıcı Müşteri Adı",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Sağlayıcı Varlık Adı",
      "EVENT_DATA_PROVIDER_ID":"Sağlayıcı Kimliği",
      "EVENT_DATA_PROVIDER_NAME":"Sağlayıcı Adı",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Sağlayıcı Ödeme Profili Kimliği",
      "EVENT_DATA_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Azure Abonelik Kimliği",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"Sağlayıcı Satıcı Kimliği",
      "EVENT_DATA_QUANTITY":"miktar",
      "EVENT_DATA_RECORD_ID":"Kayıt Kimliği",
      "EVENT_DATA_RESELLER_NAME":"Bayi Adı",
      "EVENT_DATA_ROLE":"rol",
      "EVENT_DATA_SALE_PRICE":"Satış ücreti",
      "EVENT_DATA_SITE_NAME":"Site adı",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DATA_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "EVENT_DATA_START_DATE":"Başlangıç ​​tarihi",
      "EVENT_DATA_STATUS":"durum",
      "EVENT_DATA_SUB_TAXES":"Alt Vergiler",
      "EVENT_DATA_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Abonelik Adı",
      "EVENT_DATA_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "EVENT_DATA_TAX_CODE":"Vergi kodu",
      "EVENT_DATA_TAX_NAME":"Vergi Adı",
      "EVENT_DATA_TENANT_ID":"Kiracı Kimliği",
      "EVENT_DATA_TENANT_NAME":"Kiracı adı",
      "EVENT_DATA_TO_RECIPIENTS":"Alıcılara",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Kullanım Yeri",
      "EVENT_DATA_USER_DISPLAY_NAME":"Ekran adı",
      "EVENT_DATA_USER_EMAIL":"Kullanıcı e-postası",
      "EVENT_DATA_USER_TYPE":"Kullanıcı tipi",
      "EVENT_DATA_USERNAME":"Kullanıcı adı",
      "EVENT_DATA_VALIDITY":"Geçerlilik",
      "EVENT_DATA_VALIDITY_TYPE":"Geçerlilik türü",
      "EVENT_DATA_VALUE":"değer",
      "EVENT_DATE":"tarih",
      "EVENT_DEPARTMENT_DESCRIPTION":"Bölüm Tanımı",
      "EVENT_DEPARTMENT_ID":"Departman Kimliği",
      "EVENT_DEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Kabul Edilen Şartlar ve Koşullar",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Kabul Edilen Şartlar ve Koşullar Hatası",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Sağlama Özel Teklifini Etkinleştir",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Sağlama Özel Teklif Hatası'nı Etkinleştirin",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Müşteri Oluşturulamadı",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"C3 Müşteri Hatası Ekleme",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"C3 Müşteri Başarısı Ekleyin",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Grup Yöneticisi Ekle",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Grup Yöneticisi Hatası Ekleme",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Otomatik İşlenen Müşteri Ürün Abonelikleri",
      "EVENT_DESC_BULK_USER_UPLOAD":"Toplu Kullanıcı Yükleme",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Toplu Kullanıcı Yükleme Hatası",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Paket Ürün Satın Alma Siparişi",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"İptal Edilen Abonelik Satın Alma İsteği",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"İptal Edilen Abonelik Satın Alma İsteği Hatası",
      "EVENT_DESC_CART_CHECKOUT":"Alışveriş Sepeti Ödeme",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Sepet Ödeme Başarısız Oldu",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Müşteri ürün durumu başarıyla güncellendi",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Ürün durumunu değiştirme başarısız oldu",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"Fatura Durumunu Değiştir",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Fatura Durumunu Değiştirme Hatası",
      "EVENT_DESC_CLONE_PLAN":"Başarıyla klonlanmış plan",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"Plan klonlanamadı",
      "EVENT_DESC_COUPON_NAME":"Kupon Adı",
      "EVENT_DESC_COUPON_STATUS":"Kupon Durumu",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Azure Grupları Oluşturun",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Azure Grupları Hatası Oluşturma",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Azure Aboneliği Oluşturun",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Azure Abonelik Hatası Oluşturma",
      "EVENT_DESC_CREATE_CART":"Sepet Oluştur",
      "EVENT_DESC_CREATE_CART_FAILURE":"Sepet Hatası Yarat",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Müşteri Hatası Yaratın",
      "EVENT_DESC_CREATE_INVOICE":"Fatura oluşturmak",
      "EVENT_DESC_CREATE_ORDER":"Sipariş Yarat",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Sipariş Hatası Yarat",
      "EVENT_DESC_CREATE_TAX_RULE":"Vergi Kuralı Oluştur",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Vergi Kuralı Oluştur",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısı Oluşturun",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"UsageSubscription Kullanıcı Hatası Oluştur",
      "EVENT_DESC_CREATE_USER":"Kullanıcı oluştur",
      "EVENT_DESC_CREATE_USER_FAILURE":"Kullanıcı Hatası Oluştur",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Müşteri silme Hatası",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Müşteri silme Başarısı",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Müşteri Faturası Oluşturuldu",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Müşteri Adı Güncelleme Hatası",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Müşteri Adı Güncelleme Başarısı",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeyi Tanımlama",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Para Birimi Dönüştürme Hatasını Tanımlama",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Plan Fiyatlarını Yeni Para Biriminde Tanımlayın",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Yeni Para Birimi Arızasında Plan Fiyatlarını Tanımlayın",
      "EVENT_DESC_DELETE_CART_ITEM":"Silinmiş Sepet Öğesi",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Alışveriş Sepeti Öğesi Silinemedi",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeyi Sil",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Para Birimi Dönüştürme Hatasını Sil",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Para Birimi Dönüştürme Silinemedi",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Müşteri ödemelerini sil",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Müşteri ödemesi silinemedi",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Departman Hatasını Sil",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Departmanın Başarısını Sil",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Sil",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimi Silme Hatası",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Fatura Ayarlamasını Sil",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Fatura Ayarlama Hatasını Sil",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Faturaları Sil LineItem",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Faturaları Sil LineItemFailure",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"İş Ortağı Kullanıcı Hatasını Sil",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Ödeme Seçeneğini Sil",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Ödeme Seçeneği Silme Hatası",
      "EVENT_DESC_DELETE_PLAN":"Planı Sil",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Site Hatasını Sil",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Site Başarısını Sil",
      "EVENT_DESC_DELETE_TAX_RULE":"Vergi Kuralını Sil",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Vergi Kuralını Silme Hatası",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısını Sil",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Kullanım Aboneliğini Sil Kullanıcı Hatası",
      "EVENT_DESC_DELETE_USER":"Kullanıcıyı sil",
      "EVENT_DESC_DELETE_USER_FAILURE":"Kullanıcı Arızasını Sil",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Devre Dışı Bırak",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Etkinleştir",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Müşteriye Plan Ata",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Müşteriye Plan Atanamadı",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Toplu Katılım müşterileri için istek kuyruğa alınamadı",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Hata Günlüğü Bildirim Raporu",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Yerleşik Toplu toplu işlem durumu Tamamlandı olarak ayarlanamadı",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Kullanıcı Lisans Ataması toplu durumu Tamamlandı olarak ayarlanamadı",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Lisans Tüketimi Tutarsızlığı",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Portal Arızasına Erişim Verme",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Portala Erişim İzni Ver",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Toplu Kullanıcı Yükleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Toplu Kullanıcı Yükleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Abonelik Satın Alma Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Abonelik Güncelleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Abonelik Güncelleme Hataları Yoksayma Hatası",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Abonelik Satın Alma Başarısındaki Hatayı Yoksay",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Fatura Ödemesi Başlatıldı",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Bağlantı Faturalandırma Sağlayıcısı Müşteri Hatası",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Bağlantı Faturalandırma Sağlayıcısı Müşteri Başarısı",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Bağlantı Servis Sağlayıcısı Müşteri Hatası",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Bağlantı Servis Sağlayıcı Müşteri Başarısı",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Bağlantı servis sağlayıcısı csp olmayan müşteri hatası",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Bağlantı servis sağlayıcı csp olmayan müşteri başarısı",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Faturalandırma sağlayıcısının müşteri başarısını bağlayın",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Bağlantı faturalandırma sağlayıcısı müşteri hatası",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Bağlantı servis sağlayıcısı müşteri hatası",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Servis sağlayıcı müşteri başarısını bağlayın",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Kullanıcı lisanslarının yönetimi başarısız oldu.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"Kullanıcı lisanslarının yönetimi başarılı oldu.",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"NI Partner ürün satın alma talebi kabul edildi",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"NI Partner ürün satın alma isteği iptal edildi",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"NI Partner ürünü satın alındı",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"NI Partner ürün miktarı güncellendi",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"NI Hazırlama Ürün Satın Alma Etkinleştirme Durumunu Bildir",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"NI Sağlama Ürünü Satın Alma İptal Edildi Durumu Bildir",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Teklifler satın alındı",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Yerleşik müşteri hatası",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Yerleşik Müşteri Hatası",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Yerleşik Müşteri Başarısı",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"İş Ortağı Kullanıcısı Eklendi Hatası",
      "EVENT_DESC_PARTNER_USERADDED":"Ortak Kullanıcı Eklendi",
      "EVENT_DESC_PASSWORD_EXPIRED":"Şifrenin zamanı doldu",
      "EVENT_DESC_PAYMENT_FAILURE":"Ödeme Hatası",
      "EVENT_DESC_PAYMENT_SUCCESS":"Başarılı ödeme",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"İş Ortağı Merkezi güvenlik izni alınamadı",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Kitap Ortağı Merkezi güvenlik onayı başarıyla alındı",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Ürün Atama",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Ürün Atama Ağı Hatası",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Sağlayıcı Ürünleri Fiyatı Güncelleme",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Yeni İş Ortağı Teklifi Satın Alın",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Yeni Özel Teklif Satın Alma Hatası",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"NI Hazırlama Ürünü Satın Alın",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Abonelik Satın Alın",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Abonelik Satın Alma Hatası",
      "EVENT_DESC_RECORD_PAYMENT":"Kayıt Ödeme",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Reddedilen Şartlar ve Koşullar",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Reddedilen Şartlar ve Koşullar Hatası",
      "EVENT_DESC_RESET_USER_PASSWORD":"Kullanıcı Parolasını Sıfırla",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Kullanıcı Parolasını Sıfırlama Hatası",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Kiracıyı Geri Yapılandır",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Kiracıyı Geri Yapılandırma Hatası",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Portala Erişimi İptal Et",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Portal Arızasına Erişimi İptal Et",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Özel Teklifi Kaydet",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Özel Teklif Kaydetme Hatası",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Müşteri Faturalandırma Profili Kaydedilemedi",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Müşteri Faturalandırma Profilini Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Müşteriyi Faturaya Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Faturalandırma Başarısızlığında Müşteriyi Tasarruf Edin",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Müşteri Faturalandırmada Kaydedilemedi",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Müşteri Ödeme Profilini Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Müşteri Ödeme Profilini Kaydetme Hatası",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Müşteri kullanıcısını kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Müşteri kullanıcı hatasını kaydetme",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Departman Hatasını Kaydet",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Departman Başarısını Kaydet",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Kaydet",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimini Kaydetme Hatası",
      "EVENT_DESC_SAVE_INVITES":"Davetleri Kaydet",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Davet Kaydetme Hatası",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Fatura Ayarlarını Kaydet",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Fatura Ayarlamalarını Kaydetme Hatası",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"İş Ortağı Paketini Kaydet",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"İş Ortağı Paketi Hatasını Kaydet",
      "EVENT_DESC_SAVE_PARTNER_USER":"Ortak Kullanıcıyı Kaydet",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"İş Ortağı Kullanıcı Hatasını Kaydet",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Ödeme Profilini Kaydet",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Ödeme profili kaydedilemedi",
      "EVENT_DESC_SAVE_PLAN":"Planı Kaydet",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Plan Hatasını Kaydet",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Plan Ürünlerini Hedef Para Biriminde Kaydetme",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Plan Ürünlerini Hedef Para Birimi Arızasına Kaydetme",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Kayıtlı sağlayıcı müşteri onayı",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Sağlayıcı müşteri onayı kaydedilemedi",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Sağlayıcı Ödemesini Kaydet",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Sağlayıcıyı Kaydetme Ödeme Hatası",
      "EVENT_DESC_SAVE_RESELLER":"Satıcıyı Kaydet",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"Bayi Kaydedilemedi",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Site Hatasını Kaydet",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Site Başarısını Kaydet",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Şartlar ve koşul kabulü başarısız oldu",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Şartlar ve koşullar başarıyla kabul edildi",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"Eklenecek / kaldırılacak lisansları hazırlama denemesi başarısız oldu.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Başarılı bir şekilde eklenecek / kaldırılacak lisansları hazırladı.",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Abonelik kullanım eşiği uyarıları gönderme",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Kullanıcı lisans ataması toplu durumunu tamamlanacak şekilde ayarlama",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Abonelik Senkronizasyonu",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Abonelik Senkronizasyon Hatası",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonelik güncellendi",
      "EVENT_DESC_SUCCESS":"başarı",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"sağlayıcı müşteri onayı senkronize edilemedi",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Senkronize sağlayıcı müşteri onayı",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Abonelikleri Bilgisayar Arızasından Senkronize Edin",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Abonelikleri Bilgisayardan Senkronize Etme Başarılı",
      "EVENT_DESC_TAGS_ADDED":"Etiketler Eklendi",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Eklenen Etiketler Hatası",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Etiketler Silinemedi Hatası",
      "EVENT_DESC_TAGS_DELETED":"Etiketler Silindi",
      "EVENT_DESC_TAGS_EDITED":"Etiketler Düzenlendi",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Etiketler Düzenleme Hatası",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Azure Gruplarını Güncelle",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Azure Grupları Hatasını Güncelleme",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Güncelle",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Azure Abonelik Hatası Güncelleme",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Özel Teklif Miktarını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Özel Teklif Miktarı Arızasını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Güncellenmiş varsayılan ödeme seçeneği",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Varsayılan ödeme seçeneği güncellenemedi",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"CustomerPayment Profilini Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Müşteri Ödeme Profili Hatasını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Güncellenmiş müşteri profili",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Müşteri profili güncellenemedi",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Varsayılan Ödeme Seçeneğini Güncelle",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Varsayılan Ödeme Seçeneği Arızasını Güncelleme",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Güncelle",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimi Güncelleme Hatası",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Grup Yöneticisini Güncelle",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Grup Yöneticisi Hatasını Güncelle",
      "EVENT_DESC_UPDATE_LICENSE":"Lisansı Güncelle",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Güncelleme Lisans Hatası",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"İş Ortağı Merkezi Ayarlarında Güncelleme Hatası",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"İş Ortağı Genel Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"İş Ortağı Genel Ayarlarını Güncelleme Hatası",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"İş Ortağı Logosunu Güncelleyin",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"İş Ortağı Logo Hatasını Güncelle",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"İş Ortağı Merkezi Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Güncelleme Planı Teklif Fiyatı",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Güncelleme Planı Teklif Fiyat Hatası",
      "EVENT_DESC_UPDATE_PROFILE":"Profili Güncelle",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Profil Hatası Güncelleme",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Sağlayıcı Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Sağlayıcı Ayarları güncellenemedi",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Güncellenmiş Ürün Özellikleri",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Ürün Özellikleri Güncellenemedi",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Miktarı Güncelle NI Hazırlama Ürünü",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Güncellenmiş Servis Sağlayıcı Müşteri Varsayılan Değeri",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Servis Sağlayıcı Müşteri Varsayılan Değeri güncellenemedi",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"SMTP Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"SMTP Ayarlarını Güncelleme Hatası",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Abonelik Hatasını Güncelle",
      "EVENT_DESC_UPDATE_TAX_RULE":"Vergi Kuralını Güncelle",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Vergi Kuralı Arızasını Güncelleme",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Kiracı Yapılandırmasını Güncelle",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"TenantConfiguration Hatasını Güncelle",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısını Güncelle",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Güncelleme Kullanım Aboneliği Kullanıcı Hatası",
      "EVENT_DESC_UPDATE_USER":"Kullanıcıyı Güncelle",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Kullanıcı Arızasını Güncelle",
      "EVENT_ENTITLEMENT_NAME":"Yetkilendirme Adı",
      "EVENT_IMPERSONATOR":"Taklitçi",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Bağlantı Satıcısı",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Bayi Bağlanamadı",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Lütfen en az bir alıcı türü seçin.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"E-postayı Önizle",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"E-posta bildirimi başarıyla silindi.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Etkin",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Alıcı Türü",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Alıcılar",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"TO",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"E-posta bildirim ayarları başarıyla kaydedildi.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Lütfen tıklayın",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"e-posta şablonu içeriğini görüntülemek için.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"BCC Alıcıları",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"CC Alıcıları",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Etkinlik",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Etkinlik adı",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Etkin mi?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"Alıcılara",
      "EVENT_PURCHASE_PRODUCT_NAME":"Ürün adı",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"miktar",
      "EVENT_SITE_DESCRIPTION":"Site Açıklaması",
      "EVENT_SITE_ID":"Site kimliği",
      "EVENT_SITE_NAME":"Site adı",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Bayinin Bağlantısını Kes",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Bayi bağlantısı kaldırılamadı",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Faturalandırma döngüsü değişikliği için eşlenmiş ürün yok. Lütfen destek ekibiyle iletişime geçin.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Sağlayıcı Müşteri Sözleşmesi Kabulü İş Ortağı tarafından onaylanmadığı için yeni bir sağlayıcı müşteri oluşturulurken istisna oluştu",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Kullanıcı tarafından kabul edilen iş ortağı şart ve koşulları kontrol edilirken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"İş ortağı şartlar ve koşulları içeriği alınırken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Sağlayıcı müşteri onayı kabul ayrıntısı alınırken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Sağlayıcı müşteri onayı sağlamayan sağlayıcı müşteriler alınırken hata oluştu",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"İş ortağı şartlar ve koşulları kabul ayrıntıları kaydedilirken hata oluştu",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Sağlayıcı müşteri onayı kaydedilirken hata oluştu",
      "EXISTING_REFRESH_TOKEN":"Mevcut Yenileme Simgesi",
      "EXPAND_ALL_PAYMENTS":"Expand Ödemeler",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Bu kaydı silmek istediğinizden emin misiniz?",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"müşteri adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Müşteri Seçin",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"durum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Sıfırla",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Arama",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Hata",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Başarısız oldu",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"Devam etmekte",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"başarı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Müşteri",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"müşteri adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Fiyat",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"miktar",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"durum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Bölüm",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"yer",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"özet",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Başarısız oldu",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"başarı",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"silindi",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Harici Hizmet posta günlüğü durumu",
      "EXTERNAL_SERVICE_NAME":"Harici Hizmet Adı",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"Harici hizmet sonrası toplu iş kimliği",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Hata başarıyla yoksayıldı",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Harici hizmet Kayıt günlükleri Durum",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Harici Hizmet posta günlükleri",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Talimatlar",
      "EYLÜL":"Eylül",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Not :",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Siparişin faturalandırma döngüsü değiştirilemedi",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Quickbooks'tan alınan kimlik doğrulama koduyla Erişim Jetonları alınamadı",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Xero'dan alınan kimlik doğrulama koduyla Erişim Jetonları alınamadı",
      "FAILED_TO_RESEND_INVOICE":"Fatura yeniden gönderilemedi",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodu kaydedilemedi",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan kimlik doğrulama kodu kaydedilemedi",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"Genel",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Abonelikler altındaki düzenleme işlevini kullanarak tohumları kolayca güncelleyebilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Kullanıcı görünümü altında portal işlevine erişim izni vererek erişim verebilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Evet, portalımızı kullanarak güncelleme yapabilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Evet, c3 kullanarak aboneliği askıya alabilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Abonelik tohumlarını nasıl artırabilirim?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Normal bir kullanıcıya portala nasıl erişim verilir?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Ödeme yöntemini C3 kullanarak güncelleme seçeneği var mı?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. C3'ün abonelikleri askıya alma seçeneği var mı?",
      "FAQ_HEADING":"Sıkça Sorulan Sorular",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Uygulama Genel",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"Şu an itibariyle C3, İngilizce (en-us), İspanyolca (es) ve Taiwanese (tw) tarafından desteklenmektedir. Bunun yanı sıra C3, İş Ortağı Merkezi bölgelerine ve Faturalandırmaya bağlı olarak para birimlerini destekler",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Evet, bu Etiketleri tanımlayarak C3'te gerçekleştirilebilir. Etiketleri tanımlamanız (değer çifti) ve müşterilere atamanız gerekir. Örneğin, değer olarak SupportAgent adlı bir etiketi ve bir aracının (örneğin, Agent001) takma adını tanımlayabilirsiniz. Bir Yardım Masası aracısı, SupportAgent olarak ayarlanmış etiketlerden birine ve Agent001 olarak bu özel etiket için değerlerden birine sahipse, aracı, {SupportAgent, Agent001} etiket değeri çiftine sahip müşterileri görebilir.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"C3'ü etkinlik tabanlı e-postalar gönderecek şekilde yapılandırabileceğiniz Ayar >> E-posta Bildirimi'ne gitmeniz gerekir. C3, e-postaları yapılandırabileceğiniz çeşitli etkinlikler sağlar. Etkinliğe göre önizleme e-postasını da görebilirsiniz. \\ N Herhangi bir teklifin müşteri tarafından satın alındığı bir etkinlik için e-posta bildirimlerini yapılandırabilirsiniz. Rolü veya Faturalandırma kişilerini temel alarak alıcı seçebilirsiniz. Ayrıca, CC listesini ve BCC'yi de seçebilirsiniz. Bu yapıldıktan sonra, böyle bir olay meydana geldiğinde C3, E-posta bildirim ayarında yapılandırılan tüm alıcılara e-posta bildirimleri gönderir.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Vergi Özeti ve Vergi Satır Öğeleri raporlarını İş Ortağı Portalı'nda bulunan Gelir ve Maliyet Özeti kutucuğundan indirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Evet, C3 indirimleri destekler. İndirim kuponları oluşturabilir ve belirli bir plana veya müşterilere de başvurabilirsiniz. Bir kupon için, maksimum itfa, tarihten itibaren geçerli, tarihe kadar geçerli olan ve indirim yüzdesi gibi farklı parametreler tanımlayabilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Markalama için Logoları kullanarak C3'ü özelleştirebilirsiniz. C3, hoş geldiniz sayfasındaki Hoş Geldiniz logosunu ve Oturum Açılan Logosunu biriyle değiştirmenizi sağlar. Marka etkisini daha da artırmak için logonuzu kullanarak bildirim e-postalarını özelleştirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Evet, müşterinin C3 karşılama sayfası yerine giriş sayfasına gelmesi için gerekli değişiklikleri yapabiliriz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Hayır, henüz sertifika almadık. Ancak, verilerinizin güvenliğini sağlamak için aynı düzeydeki yapılandırmayı kullanıyoruz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Evet bunu yapabiliriz. Size bir IP adresi vereceğiz, DNS'inizin yönetildiği yerde bir kayıt oluşturmanız gerekir. DNS sağlayıcınız bunu yapmanıza yardımcı olacaktır.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Hayır, herhangi bir değişiklik durumunda doğrudan İş Ortağı Merkezi veya SMTP kimlik bilgilerini ayarlama ve güncelleme sayfasına gidebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. C3 tarafından hangi tüm diller ve para birimleri desteklenir?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Yanımda kullanıcılar sadece bir dizi müşteriyle ilgilenebilir miyim?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Müşteri tarafından bir satın alma işlemi gerçekleştirildiğinde C3'ü e-posta gönderecek şekilde nasıl yapılandırabilirim? Bu tür e-postaların alıcılarını da nasıl yapılandırabilirim?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Ne tür vergi raporları indirilebilir?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. C3 indirimleri destekliyor mu?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. C3 ne tür özelleştirme sunar wrt görünüm ve his (beyaz etiketleme / markalaşma)",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Müşterilerimin portalımdan C3 karşılama sayfası yerine giriş sayfasına götürecek bir bağlantıyı tıklatmasına izin vermenin bir yolu var mı?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. C3 PCI uyumlu mu?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Tercih edilen alan adımı C3'e bağlayabilir miyim? Bunu yapmak için ne gerekiyor?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. İş Ortağı Merkezim veya SMTP kimlik bilgileri değiştiğinde veya süresi dolduğunda C3 desteğiyle iletişime geçmeli miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING":"fatura",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Şu anda C3, Manuel, Kredi Kartı, ACH (yalnızca ABD müşterileri için) ve Banka hesaplarını (yalnızca Avustralya ve Yeni Zelanda müşterileri için) desteklemektedir.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Evet, müşteriler fatura fatura ortağı tarafından onaylandığında bildirim e-postasında faturayı pdf olarak alır.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"Bu gibi durumlarda, belirli bir faturaya yapılan ödemeyi kaydederek C3'ün müşterileri ücretlendirmesini engelleyebilirsiniz. Gelir ve Maliyet Özeti >> Müşteri Seç >> Faturalar bölümüne gitmeniz gerekir. Buradan, ödemeyi tahsil ettiğiniz faturayı seçmeniz gerekir. Burada Kayıt Ödeme olarak bir seçenek bulacaksınız.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Birden çok faturalandırma döngüsünü desteklemiyoruz. Ancak bu durumda, tüm müşterileri belirli bir faturalandırma döngüsüne hizalayabiliriz. Bu manuel bir işlemdir. \\ N Örneğin, bir müşteri için mevcut faturalandırma döngünüz bu ayın 10'undan sonraki ayın 9'una ve C3'te, faturalandırma döngüsünün bu ayın 1'inden 1'ine kadar. Tüm müşteriler bu ayın 9'una kadar tüm ödemeleri yaptı. 1'den 9'a kadar ilgili tutarı hesaplayacağız ve sorunu çözecek bir sonraki faturada aynı tutarı ayarlayacağız. \\ N Benzer şekilde, diğer faturalandırma döngüsü için de aynısını yapabiliriz. Önümüzdeki aydan itibaren tüm müşteriler aynı fatura döngüsüne göre düzenlenecektir.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Evet, ilgili faturadaki ödemelerle ilgili ayarlamalar yapabiliriz. Şimdilik otomatik bir sürüm yok. Bununla ilgilenebiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Şimdi Bill & Pay, Stripe, EziDebit, Authorize.net, Cybersource'u destekliyoruz. Bunlardan başka birini kullanıyorsanız, ödeme ağ geçidinizin C3 ile entegrasyonunu kesinlikle arayabiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3, şu ana kadar Bill & Pay, Stripe, EziDebit, Authorize.net, CyberSource gibi çeşitli Payments ağ geçitlerini desteklemek üzere tasarlanmıştır. Bunlardan herhangi birini mevcut ödeme ağ geçidiniz olarak kullanıyorsanız harika olur, aksi takdirde Ödeme ağ geçitlerinizi desteklemek için C3 tasarlamaya çalışabiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"C3 tekliflerini her ay Microsoft'tan en son teklifle güncelliyoruz. Fiyat değişikliği durumunda, yeni bir plan oluşturmanız veya mevcut planı yeni teklif fiyatları ile güncellemeniz gerekir. \\ N Varsayılan olarak C3, yeni müşteri için yeni fiyat alır. Ancak mevcut müşteri için, şu anda abone olunan teklif için yeni bir fiyat belirleyebilirsiniz. Ayrıca, fiyat değişikliği, bir sonraki faturalandırma döngüsü veya bir sonraki yenileme tarihi gibi ani etkiler gibi yeni fiyatların geçerlilik tarihini seçebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"C3'te, Vergi kuralları {Ülke, Eyalet Eyaleti ve Vergi Kodu}, {Ülke, Zip} veya {Ülke, Eyalet Eyaleti ve Zip} kombinasyonlarıyla tanımlanır. Örneğin, Ülke - ABD çıkış eyaleti eyaleti ve posta kodu için boş olarak tanımlanan vergi kuralı, vergi oranının ABD'deki tüm müşterilere uygulandığı anlamına gelir. Belirli bir eyalet için kural tanımlamak istiyorsanız, kuralı tanımlarken Ülke ve Eyalet değerlerini belirtin.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Evet, bunu yapabilirsiniz. Ayar >> Vergiler bölümüne gitmeniz ve Vergi yüzdesi% 0 olan bir Vergi kuralı oluşturmanız gerekir. Vergi kuralından muaf tutmak istediğiniz müşteriye uygulayın (diğer müşteri grupları için geçerlidir).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Fatura oluşturma tarihine karar verebilir ve bize bildirebilirsiniz. Bunu sizin için yapılandıracağız ve İş Ortağı Ayarı >> Faturalandırma bölümüne giderek doğrulayabilirsiniz. Burada Billings ile ilgili tüm tarihler var. Hatta müşteri yapılandırmasına giderek belirli bir müşteri için bu tarihi değiştirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3, müşterinizin faturayı görebileceği tarihlere karar vermenizi sağlar. Fatura oluşturulduktan sonra, iş ortağının faturayı onaylaması gerekir (gerekirse herhangi bir düzenleme yapmış olabilir).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Evet, C3 size fatura oluşturulduktan sonra faturadaki satır öğelerinde değişiklik yapma seçeneği sunar. Fatura kesinleştirme tarihinden önce gereksiniminize göre gerekli değişikliği (örneğin, bir kerelik ücret / feragat eklemek istiyorsanız) yapmanız gerekir. Sonlandırıldıktan sonra faturada herhangi bir değişiklik yapamazsınız.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"C3'te, bu tür etkinlikler için e-posta bildirimi yapılandırabiliriz. Herhangi bir ödeme başarısı veya başarısızlığı durumunda, C3 bunun için bir e-posta gönderecektir. Bu tür etkinlikler için yapılandırılan tüm alıcılara bildirim e-postaları gönderilir. \\ n Ayrıca C3, Gelir ve Maliyet Özeti altında, İş Ortağının herhangi bir ödeme hatası olayı için kayıtları izleyebileceği bir Ödeme Hatası özelliğine sahiptir.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. C3 tarafından desteklenen ödeme yöntemleri nelerdir?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Müşteri, fatura hazır olduğunda faturanın bir kopyasını pdf olarak alıyor mu?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Ödemeyi çek veya nakit yoluyla topladıysam ve müşterinin ödeme yöntemi kredi kartıysa ne olur? C3'ün müşteriyi mevcut fatura için ücretlendirmesini nasıl önleyebilirim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Kendi faturalandırma sistemlerimizi kullanıyoruz ve bugüne kadar müşterilerimizden ücret aldık. Müşterilerimiz farklı faturalandırma döngülerine sahipti. C3'e taşınırken ne tür bir destek alırız?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Faturaya karşı peşin ödemelerle başa çıkmanın bir yolu var mı?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. C3'ün kullanıma hazır olarak desteklediği ödeme ağ geçitleri nelerdir?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Mevcut ödeme ağ geçidi sağlayıcımı kullanabilir miyim veya C3 ile birlikte gelenlere mi gitmeliyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Fiyatlar Microsoft'tan değiştiğinde ve fiyatlandırmamızı müşterilerimize güncellemek istediğimizde ne olur?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. C3 vergilerle nasıl başa çıkıyor?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Belirli bir müşteriyi, diğer müşteri grupları için geçerli olan vergi kuralından muaf tutabilir miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. C3'ün ne zaman fatura oluşturması gerektiğini nasıl tanımlarım?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Müşterim faturalar oluşturulduktan hemen sonra görecek mi?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Oluşturulduktan sonra faturadaki satır öğelerinde değişiklik yapma seçeneğim var mı? Faturaya bir kerelik ücret ekleyebilir / ilgili değişikliklerden feragat edebilir miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Bir ödemenin ne zaman başarılı veya başarısız olduğunu nasıl anlarım (Ortak)?",
      "FAQ_PARTNER_CATEGORY_SELL":"Satmak",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Plan, müşterinize kendi seçeceğiniz bir fiyattan satabileceğiniz teklifler grubudur. Yerleşik veya müşteri oluşturmak için bir planınız olmalıdır.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Evet, C3 size iş ortağı teklifleri oluşturma özelliği sunar. Örneğin, müşterilerinize 24 * 7 destek vermek istersiniz. Ardından, iş ortağı teklifi 24 * 7 Office365 Desteği oluşturabilir ve kendi seçtiğiniz bir fiyattan satabilirsiniz.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Evet, İş Ortağı Merkezi'nde mevcut herhangi bir müşteriyi C3'e taşıyabilirsiniz. Microsoft Kimliğinizin (İş Ortağı Merkezi'nden alın) & PC'deki abone teklifine karşılık gelen bir teklif almayı planlamanız gerekir. Bunun yanı sıra, C3 size doğrudan C3'ten yeni bir müşteri oluşturma özelliği de sunar (Müşteri oluşturmak için PC'ye gitmeye gerek yoktur).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Bunu başarmak için, böyle bir müşteriyi herhangi bir planla birleştirmemiz gerekir. Teklif miktarını Sıfır olarak koyabilirsiniz. Müşteri başarılı bir şekilde devreye alındığında, söz konusu müşteri için Kullanım Tabanlı Abonelikleri otomatik olarak senkronize eder.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"Bu gibi durumlarda PC ve C3 arasında miktar uyuşmazlığı olacaktır. Ekibimiz bu tür uyumsuzlukları düzeltebilir.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Faturalarda ayarlamalar yapabiliriz, ancak şu andan itibaren C3 geri ödemeleri desteklememektedir.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Evet, C3 müşterilerden kullanımı hesaplar ve veri tüketimine dayanarak faturayı oluşturur.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Evet, gerekli indirim yüzdesi ve maks. Yineleme 3 olarak ayarlanmış bir indirim kuponu oluşturabilirsiniz. Şimdi yeni oluşturulan kuponu belirli bir müşteriye atayın. Bu yapıldıktan sonra müşteri sonraki üç fatura için geçerli indirimi alacaktır.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Plan nedir?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Özel hizmetler gibi Microsoft dışındaki teklifleri satabilir miyim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Mevcut bir müşteriye katılabilir miyim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Yalnızca kullanıma dayalı abonelikleri olan bir müşteriye nasıl katılabilirim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Ekibim yanlışlıkla PC üzerinden lisans satın aldı, bu tür eylemlerin sonuçları nelerdir? Bu tür tutarsızlıkları gidermek için bir süreç var mı?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. C3 geri ödemeleri destekliyor mu?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. C3, müşterilerimden kullanım için Azure faturalandırmasını ele alıyor mu?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. C3'e katılan bir müşterim var ve sonraki üç fatura için indirim sağlamak istiyorum, bunu yapabilir miyim?",
      "FATURA DONEMİ":"Fatura donemi",
      "FATURA NUMARASI":"Fatura numarası",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"seçmek",
      "FILTER_SELECT_A_TARGET_CURRENCY":"seçmek",
      "FIX_QUANTITY_MISMATCH":"Miktar uyuşmazlığını düzeltin",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Tüm hakları Saklıdır.",
      "FOOTER_TEXT_1_POWERED_BY":"Tarafından desteklenmektedir",
      "GEÇERSİZ DEĞER":"{{atribute}} geçersiz",
      "GEÇERSİZ GİRİŞ":"Geçersiz Giriş",
      "GENEL TOPLAM":"Genel Toplam",
      "General":"Genel",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Quickbooks'tan alınan kimlik doğrulama koduyla oluşturulan Quickbooks Erişim Jetonları",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Xero'dan alınan kimlik doğrulama koduyla oluşturulan Xero Erişim Jetonları",
      "GET_CURRENCY_CODES_EXCEPTION":"Tedavi kodları alınırken hata oluştu",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü alınırken hata oluştu",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Para birimi dönüşümleri listesi alınırken hata oluştu",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Para birimi seçenekleri alınırken hata oluştu",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"E-posta bildirimleri için müşteri etkinlikleri alınırken hata oluştu",
      "GET_CUSTOMER_LIST_EXCEPTION":"Müşteri alınırken hata oluştu",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"E-posta bildirimi alıcıları listesi alınırken hata oluştu",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"E-posta bildirimleri için etkinlikler alınırken hata oluştu",
      "GET_PARTNER_LOGO_EXCEPTION":"İş ortağı logosu alınırken hata oluştu",
      "GET_PARTNER_SETTINGS_EXCEPTION":"İş ortağı ayarları alınırken hata oluştu",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları alınırken hata oluştu",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Vergi türleri alınırken hata oluştu",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Hedef para birimleri alınırken hata oluştu",
      "GOT_RESELLER_DETAILS":"{{ResellerName}} adında CSP satıcısını aldım",
      "GRAND_ACCESS_USER_EXCEPTION":"Kullanılan bu zaten erişim var başka bir kontrol",
      "GRAND_TOTAL":"Genel Toplam",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Ürüne Göre Tüketim",
      "GRAPH_DATA_BILLED_AMOUNT":"Fatura Tutarı",
      "GRAPH_DATA_QUANTITY":"miktar",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Müşteri Geliri",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Bu e-postaya sahip bir grup yöneticisi bu grupta zaten var",
      "GROUP_USERS_OF":"Grup Kullanıcıları",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Grup Kullanıcıları",
      "GÜN":"Gün (ler)",
      "HATA MESAJI":"Bir şeyler yanlış gitti!",
      "HAZİRAN":"Haziran",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Uygulamayı, uygulamanın desteklediği bir dilde görüntüleme",
      "HEADER_CONTENT_HELP_MENU":"Sağ kenar çubuğunda sunulan içeriğe duyarlı yardım içeriği",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Sistemdeki etkinliği izleme",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Oturum açan kullanıcının temel profil ayrıntıları",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Ayarları yönetmenizi veya etkinlik günlüğüne erişmenizi sağlar",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_SIGNIN_USER":"Oturum açan kullanıcının temel profil ayrıntıları",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Bize Ulaşın",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Çıkış Yap",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Kullanıcı yetkilendirilmedi",
      "HEADER_GO_TO_PROFILE":"Profile Git",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Bize Ulaşın",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Kimliğe Bürünmeyi Durdur",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Kullanıcı yetkilendirilmedi",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Dil",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Dil",
      "HEADER_TITLE_HELP_MENU":"Yardım",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Bildirimler",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Denetim Günlüğü",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Ayarlar",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Ayarlar",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Ayarlar",
      "HEADER_TITLE_SIGNIN_USER":"Oturum açan kullanıcı",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Test e-postasını almak için e-posta adresini girin.",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"buraya",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Mevcut ayrıcalığınızla birlikte",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Mevcut ayrıcalığınız olmadan",
      "In Progress":"Devam etmekte",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Kayıtlı para birimi yok",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Kayıtlı durum yok",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Lütfen bir ülke seçin",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Lütfen önce bir kaynak para birimi seçin",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Seçilen tüm faturalar ödeme ağları için sıraya alınır. Devam etmek istiyor musun?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"Fatura durumu <% = invoiceStatus%> olarak değişecektir. Devam etmek istiyor musun?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Müşteriye $ <% = miktar%> tahsil edilecektir. Devam etmek istiyor musun?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Satıcıdan $ <% = miktar%> tahsil edilecektir. Devam etmek istiyor musun?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
      "INPUT_CATEGORYID":"Kimlik kategorisi",
      "INPUT_CHANGE_TYPE":"changetype",
      "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "INPUT_CUSTOMERC3ID":"CustomerC3Id",
      "INPUT_CUSTOMERID":"Müşteri Kimliği",
      "INPUT_CUSTOMERNAME":"Müşteri adı",
      "INPUT_DATA":"Kullanıcı girişi",
      "INPUT_DESCRIPTION":"Açıklama",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"InternalProductId",
      "INPUT_INVOICEID":"InvoiceId",
      "INPUT_NAME":"ad",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"Sayfa sayısı",
      "INPUT_PAGEINDEX":"PageIndex",
      "INPUT_PAGESIZE":"Sayfa boyutu",
      "INPUT_PLANC3ID":"kareli",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"PriceforPartner",
      "INPUT_PRODUCTID":"Ürün kimliği",
      "INPUT_PROVIDERID":"ProviderId",
      "INPUT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "INPUT_QUANTITY":"miktar",
      "INPUT_REACTIVATE":"yeniden etkinleştirmek",
      "INPUT_SALETYPE":"SaleType",
      "INPUT_STARTIND":"StartInd",
      "INPUT_SUSPEND":"askıya almak",
      "INPUT_TENANTID":"TenantId",
      "INPUT_WITHADDONS":"WithAddons",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Ödeme şekli",
      "INTERNAL_BILLING_ID":"Faturalandırma Referans Kimliği:",
      "INVALID_ATTEMPT_DECREASE":"Azalan miktara izin verilmez",
      "INVALID_ATTEMPT_REACTIVATE":"Ürünün yeniden etkinleştirilmesine izin verilmiyor",
      "INVALID_ATTEMPT_SUSPEND":"Ürünün askıya alınmasına izin verilmiyor",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Davete Geri Dön",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Davet Ekle",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Daveti Düzenle",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Hepsini temizle",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Seçilen öğeler",
      "INVITES_ADD_FORM_LABEL_OFFER":"Teklifler",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Fiyat",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Açıklama",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"ad",
      "INVITES_ADD_FORM_URL_INVITE_URL":"URL davet et",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Davet etmek",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Yeni davet ekle",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"Davet etmek",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Düzenle",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Davetiye Açıklaması",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Davetiye adı",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "INVITES_SAVE_SUCCESS":"Davetiye başarıyla kaydedildi",
      "INVITES_SELECT_OFFERS_PROMPT":"Lütfen davetiyeye en az bir teklif ekleyin",
      "INVITES_UPDATE_SUCCESS":"Davetiye başarıyla güncellendi",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Bitiş tarihi",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Başlangıç ​​tarihi",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"Bunun bu faturada bir düzenleme olarak yapılmasını istiyor musunuz?",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Gösterge Paneli",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Faturalar",
      "INVOICE_COMMENTS":"Fatura",
      "INVOICE_COUPON_APPLIED_MESSAGE":"İndirim kuponu <strong> {{CouponCode}} * </strong> uygulandı",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Hepsini seç",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"E-posta başarıyla kullanıcı listesine gönderilir",
      "INVOICE_FOOTER_LINE_DISP":"Fatura Altbilgisi Satırı",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Fatura Altbilgisi Satırı",
      "INVOICE_GENERATION_STATUS_TITLE":"Fatura Üretim Durumu",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gelir ve Maliyet Özetine Git",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Faturaları / Ödemeleri İndirin",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gelir ve Maliyet Özetine Geri Dön",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Faturaları / Ödemeleri İndirin",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Müşterilerin faturaları başarıyla içe aktarıldı",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Lütfen devam etmeden önce en az bir müşteri faturası seçin.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Müşteriler için indirilecek faturalar",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Para Birimi Kodu",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Nitelikli Ad",
      "INVOICE_INVOICE_APPROVE":"onaylamak",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Fatura satır öğelerine dön",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Faturayı İndir",
      "INVOICE_INVOICE_CAPTION_TEXT":"İçin fatura",
      "INVOICE_INVOICE_FINALIZE":"Sonuçlandırmak",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Hesap özeti",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adres",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Müşteri",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Fatura özeti",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"Fatura donemi",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Fatura tarihi",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Bitis tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Fatura Ayrıntısı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Lisans Tabanlı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Kullanım Bazlı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Şarj türü",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"İş Ortağı Maliyeti",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Kupon Kodu",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"İndirim",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"İndirim tutarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Teklif fiyatı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Genel Toplam",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Fatura Satırı Tutarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Ayrıntılar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Vergi Sonrası Tutar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Ara Toplam Miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Kiraci",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Vergi miktarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Birim fiyat",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Fatura bulunamadı. Lütfen c3 desteğine başvurun.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Fatura Ödemeleri",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Miktar",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"tarih",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Arıza Nedeni",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Genel Toplam",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Uyarılar",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"durum",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Bitis tarihi",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Fatura tarihi",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"durum",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Kalan miktar",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Kullanılan Miktar",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Düzeltmeler",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Döngü Örneği Oranı",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Geçmiş Faturalar Aidatları",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Oran Ücreti",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Satın Alma Sırasında Prorate Ücretleri",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Satın Alma Ücreti",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"İade Tutarı",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Yenileme ücreti",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Vergiler",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Kullanım Miktarı",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Kredi",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b> {{sağlayıcıAdı}} Kiracı </b> {{sağlayıcıTanrıAdı}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Dönüşüm oranı",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Aksiyon",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"E-posta",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"İptal etmek",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Faturalar",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Ödemeler",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Bitis tarihi",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Tarihinde fatura oluşturuldu",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Arıza Nedeni",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Kalan miktar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Ödenen miktar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Ödeme tarihi",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"İşlem Kimliği",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Uyarılar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Ödeme Durumu",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Kullanılan Miktar",
      "INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Müşteriler ödemeleri başarıyla içe aktarırken faturalandırıyor",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Müşteriler için indirilecek fatura ödemeleri",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"Başlangıç ​​Tarihi Bitiş Tarihinden büyük olmamalıdır",
      "INVOICE_SENT_SUCCESSFULLY":"Fatura başarıyla gönderildi",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Müşteri incelemesi için onaylandı",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Faturayı kesinleştirdi",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"Devam etmekte",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Oluşturulan Fatura",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Fatura Yayımlandı",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Ücretli tam tutar",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Ücretli kısmi tutar",
      "INVOICE_TAB_NAME_COMMENTS":"Yorumlar",
      "INVOICE_TAB_NAME_DETAILS":"ayrıntılar",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Bildirimler",
      "INVOICE_WILLBE_MAILED":"Fatura, fatura adresinize gönderilecektir. Teşekkür ederim.",
      "INVOICES_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Fatura donemi",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Fatura ödeme tarihi",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Yalnızca İş Ortağı Tarafından Yönetilebilir",
      "IS_PRODUCT_BEING_UPDATED_WITH_ADDONS":"Ürün Eklentilerle güncelleniyor mu?",
      "İSİM GİRİN":"ad",
      "kararlı":"kararlı",
      "KASIM":"Kasım",
      "LABEL_ASSIGNED_RG":"Atanan Kaynak Grupları",
      "LABEL_BANNER_TEXT":"İleti",
      "LABEL_BILLING_ADDRESS":"Fatura Adresi",
      "LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "LABEL_EFFECTIVE_TO":"Tarihinde sona eriyor",
      "LABEL_IS_ACTIVE":"Aktif",
      "LABEL_NOTIFICATION_RECIPIENTS":"Bildirim Alıcıları",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Kullanıcıları virgülle ayrılmış değerler olarak girin",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Şunun için Politika Ata:",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Politika Atamaları",
      "LABEL_POLICY_CUSTOMER":"Müşteri",
      "LABEL_POLICY_DEFINITION":"Politika Tanımı",
      "LABEL_POLICY_DESCRIPTION":"Politika açıklaması",
      "LABEL_POLICY_NAME":"Poliçe ismi",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "LABEL_POLICY_SUBSCRIPTION":"abone",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"özel rol ekle",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Özel Rollere Geri Dön",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Özel Rol ekle",
      "LABEL_ROLE_CUSTOMER":"Müşteri",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "LABEL_ROLE_ROLE_DEFINITION":"Rol Tanımı",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Rol Tanımı",
      "LABEL_ROLE_ROLE_NAME":"Rol ismi",
      "LABEL_ROLE_SUBSCRIPTION":"abone",
      "LABEL_SHIPPING_ADDRESS":"teslimat adresi",
      "LABEL_TEXT_ADDONS":"Eklentileri",
      "LABEL_TEXT_ADDRESS_1":"Adres 1",
      "LABEL_TEXT_ADDRESS_2":"Adres 2",
      "LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "LABEL_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "LABEL_TEXT_CATEGORY":"Kategori",
      "LABEL_TEXT_DISCOUNT":"İndirim",
      "LABEL_TEXT_LINKED_PRODUCT":"Bağlantılı Ürün",
      "LABEL_TEXT_PRICE":"Fiyat",
      "LABEL_TEXT_RETAIL_PRICE":"Perakende fiyatı",
      "LABEL_TEXT_STATE_PROVINCE":"Eyalet Eyaleti",
      "LABEL_TEXT_STATUS":"durum",
      "LABEL_TEXT_TAX_CODE":"Vergi kodu",
      "LABEL_TEXT_TAX_TYPE":"Vergi Türü",
      "LABEL_TEXT_TENANT_NAME":"Kiracı adı",
      "LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "LABEL_TEXT_ZIP_CODE":"Posta kodu",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Yeni",
      "LABEL_UNASSIGNED_RG":"Atanmamış Kaynak Grupları",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Ek ücretler",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Müşteri Yöneticisi",
      "LABLE_CUSTOMER_READER_ROLE":"Müşteri Okuyucu",
      "LABLE_DISCOUNTS_TOTAL":"Toplam indirim",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Toplam Abonelik Sayısı",
      "LABLE_TAXES_TOTAL":"Toplam Vergi",
      "LABLE_TEXT_COST_PRICE":"Maliyet fiyatı",
      "LABLE_TEXT_COST_TO_PARTNER":"Is ortagi maliyeti",
      "LABLE_TEXT_DISPLAY_NAME":"Ekran adı",
      "LABLE_TEXT_EVENTS":"Etkinlikler",
      "LABLE_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "LABLE_TEXT_MAXVALUE":"Maksimum değer :",
      "LABLE_TEXT_MINVALUE":"Minimum Değer:",
      "LABLE_TEXT_OFFER_NAME":"Teklif Adı",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Tedarikçi Satış Fiyatı",
      "LABLE_TEXT_SALE_PRICE":"Satış ücreti",
      "LABLE_TEXT_SALEPRICE":"Satış ücreti :",
      "LABLE_TEXT_SLAB":"Döşeme Detayları",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Is ortagi maliyeti",
      "LABLE_TEXT_TYPE":"Faturalandırma Türü",
      "LABLE_WAIVER":"Feragat",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Oturum aç",
      "LAYOUTLOGIN_HEADER_TEXT":"Oturum aç",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Devam etmek için lütfen giriş yapınız",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Spektra Sistemleri. Tüm hakları Saklıdır.",
      "LBL_COUPON_CODE_TEXT":"Kupon Kodu",
      "LBL_DISCOUNT_TEXT":"indirim",
      "LBL_TEXT_NOT_PROVIDED":"Sağlanmadı",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Lütfen aşağıdaki hataları bulun.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Önkoşullar karşılanmadığından bu işlem tamamlanamaz. Bir eklentinin atanması için bir temel teklif gerekir ve bir temel teklifi iptal etmek için eklentilerin yayınlanması gerekir.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Önkoşullar karşılanmadığından bu işlem tamamlanamaz. Bir eklentinin atanması için bir temel teklif gerekir ve bir temel teklifi iptal etmek için eklentilerin yayınlanması gerekir.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Teklif",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Ön Koşul Teklifleri",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"müşteri adı",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Yeni statü",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Eski Durum",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Ürün",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Bayi Adı",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilmiş",
      "LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"seçmek",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Müşteri",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Kiraci",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Lütfen müşteri seçin",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Lütfen abonelik adını girin",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Lütfen kiracı seçin",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filtreler",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Atanan Miktar Farkı",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"C3 olarak mevcut",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"PC'de Mevcut",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"C3'te Atanan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"C3'teki Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Müşteri",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"E",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Toplam Miktar Farkı",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Haritalı Ürünler",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Sağlayıcıda Atanan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Sağlayıcıdaki Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Sağlayıcıda Kullanılmayan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"abone",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Kiraci",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Detayları göster",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Lisans Tüketim Raporu",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"İhracat Lisansı Tüketim Raporu CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Kiracılar",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Lisans Özetini Alma",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Lisans Özet Raporunu İndirin",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Lisans Özeti",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Bayi Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Üst Abonelik Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Saglayici Adi",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Sağlayıcı Kiracı Kimliği",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"miktar",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"ad",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Lisans Tüketimi",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Kullanılmayan koltukları serbest bırakın",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Kullanılmayan Koltukları Serbest Bırak",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Kullanılmayan koltukların serbest bırakılması. Lütfen bekle...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"atanan",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Haritalı Ürünler",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Toplam",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adları",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"kullanılmamış",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"atanan",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Geçerli",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Mevcut",
      "LICENSES_TOOLTIP_MS_USER":"Lisanslar",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Kullanıcısının Fatura Profiline Bağlantı",
      "LINK_CUSTOMER_HEADER_TEXT":"Sağlayıcısına bağlantı",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Mevcut tüm Sağlayıcılara bağlısınız",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"müşteri tipi",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"müşteri tipi",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Kiracı Kimliği",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Kiracı adı",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Microsoft csp olmayan müşteriyi c3'e başarıyla bağladı.",
      "LINK_RESELLER_CAPTION_TEXT":"Bağlantı Satıcısı",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Sağlayıcı",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Bayi Kimliği (sağlayıcıdaki gibi)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Sil",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Kartvizit",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Saglayici Adi",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Sağlayıcı Satıcı Kimliği",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Tüm sağlayıcı profillerinize bağlısınız.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"{{SağlayıcıAdı}} bağlantısı kaldırıldı.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"{{SağlayıcıAdı}} ile başarıyla bağlantılı.",
      "LINK_TEXT_CLICK_HERE":"Buraya Tıkla",
      "linkCustomerBillingProfile":"Fatura Profiline Bağlantı",
      "LINKED_PRODUCT_INFO_SHOPE":"Bu ürün ile bağlantılı",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"seçmek",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Bir ürüne bağlantı",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Alan kullanılabilirliğini doğrulama",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Kategori",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Sağlayıcı",
      "LOADING_INDICATOR_MESSAGE":"İşleme...",
      "LOADING_MESSAGE":"Yükleniyor...",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Kimliğe bürünme işleminizi tamamlamak için bize biraz zaman verin",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Selamlar,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Şimdi giriş yaptınız. Uygulamayı erişebilmeniz için bize bir dakika verin ...",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Tedarikçi Satış Fiyatı",
      "MAIL_SENT_SUCCESSFULY":"Posta başarıyla gönderildi",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Hayır",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Evet",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Lisans atama talebiniz onaylandı. Güncellemeler için lütfen bir süre sonra bu sayfayı ziyaret edin.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Lütfen kiracıdaki kullanıcıların geçerli e-posta adreslerini girin",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"{{Username}} adlı kullanıcının {{Subscriptionname}} için zaten bir lisansı var",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Lütfen en az bir dosya seçin",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Hataları yok saymak istediğinizden emin misiniz?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Bir aboneliğe karşı minimum koltuk sayısı 1 olmalıdır. Kullanılabilir koltuk sayısı 1 ise koltuk serbest bırakılamaz.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Kullanılmayan koltukları serbest bırakmak istediğinizden emin misiniz?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Kullanılmayan lisanslar başarıyla serbest bırakıldı",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Lütfen kaldırılacak Lisanslar grubuna en az bir kullanıcı ekleyin",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Ekle düğmesine tıklamadan önce lütfen önerilen listeden kullanıcı seçin",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"Lisans ataması başarıyla tamamlandı",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Dosya işlenirken bir hata oluştu, lütfen tekrar deneyin",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Lisans ayrıntısı başarıyla yüklendi. Durumu kontrol etmek için lütfen bir süre sonra bu ekrana dönün.",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Teklifleri Görüntüle)",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Evet",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Liste fiyatını satış fiyatına kopyalayın - satış fiyatı Liste fiyatı ile aynı olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Liste fiyatına% X ekle - satış fiyatı Liste Fiyatı + Liste fiyatı% X olacak",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Fiyatı Y sent artırın - satış fiyatı Liste Fiyatı + Y sent olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Seçilen tüm teklifleri temizle fiyatı",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"İşaretlemeye% X ekleyin - satış fiyatı Liste Fiyatı + (ERPFiyat-Liste Fiyatı) * X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"MS fiyatına% X ekle",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"satış fiyatı MS Fiyatı + MS fiyatının% X'i olacak",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"MS fiyatını satış fiyatına kopyalayın",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"satış fiyatı MS fiyatı ile aynı olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"İşaretlemeye% X ekle",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"satış fiyatı MS Price + (ERPPrice-MS Price) olacak * X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Fiyatı Y sent artırın",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"satış fiyatı MS Fiyat + Y sent olacak",
      "MANAGE_PLAN_LBL_1":"Liste fiyatını satış fiyatına kopyala",
      "MANAGE_PLAN_LBL_2":"Liste fiyatına% X ekle",
      "MANAGE_PLAN_LBL_3":"Fiyatı Y sent artırın",
      "MANAGE_PLAN_LBL_4":"Tüm fiyatları temizle",
      "MANAGE_PLAN_LBL_5":"İşaretlemeye% X ekleyin",
      "MANAGE_PLAN_LBL_6":"ERP fiyatını satış fiyatına kopyalayın",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Kapat",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Dahil Olan Teklifler",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Kapat",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"ad",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Tüm eklentileri silmek istediğinizden emin misiniz?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Bu ürünü silmek istediğinizden emin misiniz?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Eklentiler plana eklenir, lütfen işleminizi tamamlamak için planı kaydedin.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"plana eklendi!",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"eklentilerle plana eklendi",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Eşleşen eklenti bulunamadı",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Teklifi kaldırmak istiyor musunuz?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"Eklemeye çalıştığınız eklenti zaten dahil edildi",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Tüketim türleri",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Abonelik durumu",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Satış Fiyatı Liste Fiyatından daha az olduğu için lütfen aşağıdaki teklifleri kontrol edin.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Plan değişikliklerini göndermek için 'Tamam'ı tıklayın. 'İPTAL' plan tekliflerini yeniden kontrol etmek için.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"Seçilen teklif zaten dahil edildi",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Plana dahil olan teklifler için lütfen Kolay Ad ve Etkili Fiyat verin",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Lütfen plana teklifler ekleyin",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Daha Fazla Eklenti Ekle",
      "MANAGE_PRODUCT_OWNERSHIP":"Ürün sahipliğini yönetme",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Ürün Sahipliğini Yönetme Başarısız",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Ürünlerden biri veya eklentileri için lisans ataması devam ettiği için ürün sahipliğini yönetmeye izin verilmiyor. Lütfen daha sonra tekrar deneyin.",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Temiz Fiyatlar",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"MS fiyatını bayi fiyatına kopyalayın - bayi fiyatı MS fiyatıyla aynı olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"MS fiyatına% X ekleyin - Bayi fiyatı MS Fiyat + MS fiyatının% X'i olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Fiyatı Y sent artırın - bayi fiyatı MS Fiyat + Y sent olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Seçilen tüm teklifleri temizle fiyatı",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"İşaretlemeye% X ekleyin - bayi fiyatı MS Price + (ERPPrice-MS Price) * X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Bayi tekliflerini eklemek istiyorsanız aşağıdaki talimatları uygulamanız gerekir",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Seçilen öğeler",
      "MANAGE_RELLER_OFFERS_LBL_1":"MS fiyatını bayi fiyatına kopyalayın",
      "MANAGE_RELLER_OFFERS_LBL_2":"MS fiyatına% X ekle",
      "MANAGE_RELLER_OFFERS_LBL_3":"Fiyatı Y sent artırın",
      "MANAGE_RELLER_OFFERS_LBL_4":"Tüm fiyatları temizle",
      "MANAGE_RELLER_OFFERS_LBL_5":"İşaretlemeye% X ekleyin",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Filtrelenen tekliflerin tümünü seçilen tekliflerden kaldırmak istiyor musunuz?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Seçilen tekliflerin tümünü kaldırmak istiyor musunuz?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Filtrelenen tüm teklifleri seçmek ister misiniz?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Tüm teklifleri seçmek ister misiniz?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Kadar geçerli",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Lisans türü",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Bayi Fiyatı",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"İptal etmek",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Sunmak",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Bitiş tarihi",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"miktar",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Teklifler",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Bayiler",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Bayi Teklifleri Ekleyin",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Teklifler",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Lütfen seçilen teklifler için Etkili Fiyat verin",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Teklifler başarıyla kaydedildi",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Lütfen satıcıya teklif ekleyin",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Tüm Teklifler Seçildi. Herhangi bir plan teklifiyle ilgili bir şey düzenlemek istiyorsanız, düzenleme planını gözden geçirmeniz gerekir!",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Bayi Planları Listesi",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Plan durumu başarıyla güncellendi",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Atamak",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"ad",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Tarihinde sona eriyor",
      "manageplans":"Planları Yönet",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"Kaynak Grubunu kaldırmak istiyor musunuz?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Müşteri'ye Ödeme Ayrıntılarını güncelleme yetkisi verin.",
      "MANUAL_SYNC_PUPUP_TITLE":"Manuel Senkronizasyon",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Düzeltme başarıyla uygulandı",
      "Markup":"Biçimlendirme",
      "MART":"Mart",
      "MAYIS":"Mayıs",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Bildirimler",
      "MENU_AZURE_BILLING_REPORT":"Azure Faturalandırma Raporu",
      "MENU_AZURE_CUSTOM_POLICY":"Özel Politikalar",
      "MENU_AZURE_CUSTOM_ROLE":"Özel Roller",
      "MENU_AZURE_MARGIN":"C3 Desteği",
      "MENU_AZURE_SUBACRIPTION":"Azure Aboneliği",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft,",
      "MENU_CONTACT_LOG":"Bildirimler",
      "MENU_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "MENU_CUSTOMER_ANALYTICS":"Müşteri Analizi",
      "MENU_CUSTOMER_COMMENTS":"Yorumlar",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lisans Tüketimi",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Power BI Raporu",
      "MENU_CUSTOMER_PROFILE":"Profil",
      "MENU_DASHBOARD":"Gösterge Paneli",
      "MENU_HELP":"Yardım",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Lisans Tüketimi Özet Raporu",
      "MENU_LICENSE_SUMMARY_REPORT":"Lisans Özet Raporu",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"İş Ortağı Teklifleri Sağlama",
      "MENU_PARTNER_AUDIT_LOG":"Denetim Günlüğü",
      "MENU_PARTNER_COUPON":"Kuponlar",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "MENU_PARTNER_SUPPORT_LOG":"Destek",
      "MENU_PARTNER_UPLOAD":"Yükleme",
      "MENU_PRODUCT_SEQUENCE":"Ürün siparisi",
      "MENU_REPORT_USAGE":"kullanım",
      "MENU_REPORTS_FOR_CUSTOMER":"Raporlar",
      "MENU_RESELLER_PLAN":"Bayi Planı",
      "MENU_RESELLER_PROFILE":"Organizasyon Profili",
      "MENU_TOPBAR_SETTINGS":"Ayarlar",
      "MENU_USAGE_REPORT":"Kullanım Raporu",
      "MENU_USERS":"Kullanıcılar",
      "MENUS_ANALYTICS":"analitik",
      "MENUS_ANALYZE":"çözümlemek",
      "MENUS_AZURE_ESTIMATE":"Azure Tahmini",
      "MENUS_AZURE_GROUPS":"Azure Grupları",
      "MENUS_AZURE_MANGE":"Azure Yönetimi",
      "MENUS_AZURE_REPORTS":"Azure Raporları",
      "MENUS_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "MENUS_CART":"Araba",
      "MENUS_COUPONS":"Kuponlar",
      "MENUS_CUSTOM_OFFERS":"Ortak Teklifleri",
      "MENUS_CUSTOMER_CART":"Araba",
      "MENUS_CUSTOMER_DASHBOARD":"Gösterge Paneli",
      "MENUS_CUSTOMER_MICROSOFT":"Microsoft,",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Kullanıcılar",
      "MENUS_CUSTOMER_ONBOARDING":"Müşteri Katılımı",
      "MENUS_CUSTOMER_ORDERS":"Emirler",
      "MENUS_CUSTOMER_PRODUCTS":"Ürün",
      "MENUS_CUSTOMER_PROFILE":"Profil",
      "MENUS_CUSTOMER_SHOP":"Dükkan",
      "MENUS_DAAS":"DaaS <sup class = 'metin tehlikesi'> BETA </sup>",
      "MENUS_DOCUMENTATION":"<Sup class = 'text-danger'> BETA </sup> belgeleri",
      "MENUS_ENTITY_INVOICES":"Faturalar",
      "MENUS_FAQ":"SSS",
      "MENUS_INVITES":"Davet etmek",
      "MENUS_INVOICES":"Faturalar",
      "MENUS_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "MENUS_LICENSE_LIST":"Lisans Listesi",
      "MENUS_MS_OFFERS":"MS Teklifleri",
      "MENUS_OPERATING_ENTITIES":"İşletme Kuruluşları",
      "MENUS_ORDERS":"Emirler",
      "MENUS_PARTNER_BUNDLES":"Paketler",
      "MENUS_PARTNER_CUSTOMERS":"Müşteriler",
      "MENUS_PARTNER_DASHBOARD":"Gösterge Paneli",
      "MENUS_PARTNER_PLANS":"Planlar",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"iş",
      "MENUS_PARTNER_SETTINGS":"Ayarlar",
      "MENUS_POWER_BI_REPORTS":"Power BI Raporları",
      "MENUS_PRODUCTS":"Ürün",
      "MENUS_PROFILE":"Profil",
      "MENUS_RESELLER_DASHBOARD":"Gösterge Paneli",
      "MENUS_RESELLER_INVOICES":"fatura",
      "MENUS_RESELLER_PAYMENTS":"Ödemeler",
      "MENUS_RESELLER_PLANS":"Planlar",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Fiyatlandırma ve teklifler",
      "MENUS_RESELLER_PROFILE":"Organizasyon Profili",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"iş",
      "MENUS_RESELLER_SETTINGS":"Ayarlar",
      "MENUS_RESELLERS":"Bayiler",
      "MENUS_SELL":"Satmak",
      "MENUS_SELL_DIRECT":"Doğrudan Satış",
      "MENUS_SELL_INDIRECT":"Dolayli Satis",
      "MENUS_SELLER_INDIRECT":"Dolayli Satis",
      "MENUS_SHOP":"Dükkan",
      "MENUS_SUBSCRIPTIONS":"Abonelikler",
      "MENUS_SUPPORT":"Destek",
      "MENUS_TAXES":"Vergiler",
      "MENUS_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "MESSAGE_NO_RECORDS_FOUND":"<img src='../../../img/no result @ 32.png' /> Kayıt bulunamadı!",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"Geçerli faturalandırma döneminde kullanılmadığından veya Microsoft'un faturasında henüz sunulmayan veriler mevcut değil. Lütfen kısa süre sonra tekrar kontrol edin.",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Düzenle",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Aksiyon",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Tarihinde sona eriyor",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"Kayıt bulunamadı!",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Maalesef, yapılandırılmış siteleriniz ve bölümleriniz yok. Profil Sayfasından yeni işletme varlıkları ekleyebilirsiniz",
      "MEVCUT":"Mevcut",
      "Microsoft":"Microsoft,",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Kategori",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"İndirim",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategori",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"İndirim",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Biçimlendirme",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Yüzde",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Bağlantılı Ürün",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Kabul Eden",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA Kabul Tarihi",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Microsoft müşteri sözleşmesi başarıyla kaydedildi.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Kullanıcı adı",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} of {{applicationName}}, Microsoft Cloud hesabınız için Microsoft Cloud Çözüm Sağlayıcınız olmak istiyor.<br/><br/>Daveti kabul etmek için aşağıdaki 2 adımı uygulayın:<br/><br/>Adım 1: Müşteri profilinin burada eksiksiz olduğundan emin olun ve kontrol edin: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Adım 2: Müşteri profili tamamlandıktan sonra (profil güncellendikten sonra yenilenmesi en fazla 5 dakika sürebilir), bu daveti kabul etmek ve {{applicationName}}'i Microsoft Cloud Çözüm Sağlayıcınız olmaya yetkilendirmek ve Microsoft Müşteri Sözleşmesini kabul etmek için aşağıdaki bağlantıya tıklayın Sözleşme.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Not: İlişkiyi kabul etmek için Global Admin iznine sahip kullanıcı gereklidir.<br/><br/><br/>Ek ortak bilgileri:<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Değerli müşterimiz,<br/><br/>Size bulut çözümleri, hizmetleri ve desteğinin tam yelpazesini sunmak için {{resellerName}}, {{partnerCompanyName}} ile yakın bir şekilde çalışmaktadır. {{resellerName}} ve {{partnerCompanyName}}'in hesabınıza hizmet vermesini yetkilendirmek için aşağıdaki 2 adımı uygulayın: <br/><br/>1. Adım: Müşteri profilinin burada eksiksiz olduğundan emin olun: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>2. Adım: Müşteri profili tamamlandıktan sonra (profil güncellendikten sonra yenilenmesi en fazla 5 dakika sürebilir), bu bağlantıyı tıklayarak kabul edin davet.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Not: İlişkiyi kabul etmek için genel yönetici iznine sahip bir kullanıcı gereklidir.<br/><br/>{{resellerName}} ürünler, abonelikler, lisanslar ve destek için yerel başvuru uzmanınız olmaya devam edecektir. Sizinle olan iş ilişkilerinin aynı kalacağından emin olabilirsiniz.<br/><br/>Sorularınız varsa lütfen bizimle iletişime geçmekten çekinmeyin. Biz ve {{resellerName}} sizinle çalışmaktan mutluluk duyuyoruz!<br/><br/>Saygılarımızla,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Mevcut tüm lisanslar ve portala erişim kaldırılacaktır. Devam etmek istediğine emin misin?",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Taşıma hesabı kullanıcı adıyla başarıyla oluşturuldu:",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Taşıma Hesapları",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Müşteri",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Taşıma Hesabı",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Oluşturmak",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Taşıma Hesapları",
      "MIGRATION_USER_CREATE_SUCCESS":"{{Username}} ile taşıma hesabı başarıyla oluşturuldu",
      "MISSING_PLAN_OFFER_ADD_FAILED":"Mutabakat başarısız oldu, lütfen yardım için desteğe başvurun",
      "MISSING_PLAN_OFFER_ADDED":"Seçilen teklif kullanılarak mutabakat tamamlandı",
      "MODAL_HEADER_TEXT_DESC_FILTER":"filtre",
      "Month(s)":"Ay (lar)",
      "MULTIPLE_COMMENT_STATIC":"Yorumlar",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"seçmek",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Talimatlar",
      "Name":"ad",
      "NCE_PROMOTIONS_POPUP_CANCEL":"İptal etmek",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Kapat",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"İndirim",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Bitiş tarihi",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Uygulamak",
      "NEW_COMMENT_SEND":"Gönder",
      "NEW_PRODUCT_STATUS":"Yeni statü",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Arama ...",
      "NİSAN":"Nisan",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Sepette {cartLineItemId} kimliğine sahip öğe yok",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Mevcut plan yok",
      "NO_PAYMENT_TYPES_ENABLED":"Hiçbir ödeme türü etkinleştirilmedi. Lütfen desteğe başvurun.",
      "NO_PRODUCT_SELECTED_PROMPT":"Lütfen en az bir ürün seçin.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Seçilen fatura dönemi veya sağlayıcı için fatura ayrıntısı bulunamadı",
      "NO_RESOURCE_GROUP_LEFT":"Atanacak kaynak grubu kalmadı / Kayıt bulunamadı!",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"CSP Olmayan Ürün Detayları",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Hizmet Asıl Kimliği",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Hizmet Asıl Anahtarı",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Abonelik Adı",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"Bayi kullanıcısı bayi planı kapsamında iş ortağı ürünü ekleyemez.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Aşağıdaki sağlayıcı ürünleri plan ürünlerle eşleştirilmez, devam etmek istiyorsanız devam et'i tıklayın iptal tıklayın ve bu ürünleri eşleyin",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Sağlayıcı Ürünleri",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Bu değer kısa süre içinde güncellenecek",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Bir e-posta adresi girin ve seçilen fatura dönemi için fatura PDF'lerinin listesini içeren bir zip dosyası almak için Tamam'ı tıklayın.",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"{{EmailAddress}} adresine kısa bir süre içinde fatura PDF'lerinin (Zip) bir listesini içeren bir e-posta gönderilecektir.",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
      "OCAK":"Ocak",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Teklif Kolay Adı {{FriendlyName}} benzersiz olmalıdır",
      "offers":"Teklifler",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonelikler",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Abonelik Ekle",
      "OFFERS_HEADER_TEXT_OFFERS":"Teklifler",
      "OFFERS_NO_MATCH_MESSAGE":"İsim için eşleşen sonuç bulunamadı:",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Seçilen öğeler",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"İndirim Yüzdesi",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Miktar (Lisans Sayısı)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Koltuk başına ücret (Aylık)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Toplam",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Toplam tutar",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Bir müşteriye toplu olarak yerleşik olarak yerleştirme başarısız oldu",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Bir müşteriye, toplu olarak yerleşik başlatıldı",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Bir müşteriye toplu yerleşik başarı altında katılım",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/> <p> Faturalandırma Sisteminde Kimlik: <strong> {{billingProviderReferenceID}} </strong> ile bir eşleşme bulundu. <br/> <span> Dosyadaki Fatura Adresi <strong> {{formattedAddress}} < / strong>. </span> <br/> Müşteri faturalandırma bilgileri başarıyla eklendiğinde bu ayrıntıyı değiştirmek için lütfen kimliğine bürünün ve <strong> Profil </strong> seçeneğini kullanın.",
      "onboarding":"Onboarding",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Kapat",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Bayiler",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Sıfırla",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Arama",
      "ONBOARDING_DURATION_UNIT_MONTH":"Ay",
      "ONBOARDING_DURATION_UNIT_YEAR":"Yıl",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"MicrosoftNonCSP müşterisine alışmak desteklenmez. Lütfen müşteriyi mevcut bir C3 Müşterisine bağlayın.",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Sağlayıcı müşteri ürünlerini plan ürünleriyle eşleme ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Planlar yükleniyor ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Sağlayıcı müşterileri yükleniyor ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Müşteri detaylarını c3'te aramak ...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Tüm Müşteriler",
      "ÖNCE":"Önce",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Silmek istediğine emin misin?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Listeden bir ürün seçin veya ürün adına göre arayın",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"Kullanıcılardan lisans kaldırma isteği kabul edilir ve işlenir.",
      "Open":"Açık",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "OPERATING_ENTITY_CUSTOMER":"Müşteri",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Atamak",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Serbest bırakmak",
      "ORDER_DETAILS_ENTITY_NAME":"Varlık adı",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"Sipariş verildi",
      "ORDER_ID":"Sipariş Kimliği",
      "ORDER_LIST_STATUS_SUCCESS":"başarı",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Müşteri incelemesi için onaylandı",
      "ORDER_STATUS_DELETED":"silindi",
      "ORDER_STATUS_DESC_ACCEPTED":"Kabul edilmiş",
      "ORDER_STATUS_DESC_FAILED":"Başarısız oldu",
      "ORDER_STATUS_DESC_IGNORE":"Göz ardı etmek",
      "ORDER_STATUS_DESC_INPROGRESS":"Devam etmekte",
      "ORDER_STATUS_DESC_ORDERED":"Siparis verildi",
      "ORDER_STATUS_DESC_PROCESSING":"İşleme",
      "ORDER_STATUS_DESC_REJECTED":"Reddedildi",
      "ORDER_STATUS_DESC_SUCCESS":"başarı",
      "ORDER_STATUS_ERRORS_IGNORED":"Başarısız <br/> (hatalar gözden geçirildi ve yoksayıldı)",
      "ORDER_STATUS_FAILED":"Başarısız oldu",
      "ORDER_STATUS_INPROGRESS":"Üzerinde çalışıyor",
      "ORDER_STATUS_ORDERED":"Siparis verildi",
      "ORDER_STATUS_PROCESSING":"Üzerinde çalışıyor",
      "ORDER_STATUS_PROVISIONING":"Üzerinde çalışıyor",
      "ORDER_STATUS_REJECTED":"Reddedildi",
      "ORDER_STATUS_SUCCESS":"Sipariş yerine getirildi",
      "ORDER_STATUS_SUSPENDED":"Askıya alındı",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"Üst teklif etkin değilse Eklenti etkinleştirilemez.",
      "PARENT_PRODUCT_NOT_EXISTS":"Ebeveynsiz bir ürüne binemezsiniz!",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Bu sağlayıcı için kullanılabilir anahtar yok",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Yapılandırılmış sağlayıcı yok, Lütfen Destek ile İrtibata Geçin",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Değişikliklerinizi Kaydedin",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Fatura Para Birimi",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makro Değeri",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"ad",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Satın Alma Para Birimi",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"seçmek",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Atamak",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"ad",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Eklenti bulunamadı",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Planı Sil",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Plan Tekliflerini Gözden Geçirin",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Planı Kaydet",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"miktar",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"kullanım",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"pakete eklendi",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"eklentilerle pakete eklendi",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"zaten pakete eklendi ve tekrar eklenemez",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filtreler",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Sıfırla",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Arama",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Geçerlilik",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Faturalandırma türleri",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Döşeme verileri",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":"- - -",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Geçerlilik türü",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Lütfen en az bir ürün seçin.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Lütfen birlikte kulüplere katılmak için en az 2 teklif seçin",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Lütfen satış fiyatını belirtin",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Paket Ürünler",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Paketler",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Paket Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Paket Ayrıntılarına Dön",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Sil",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Düzenle",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Uygulanan Arama:",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Sonraki",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Paket Ürünleri İnceleyin",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Paketi Kaydet",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Teklifleri Göster",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"İş Ortağı Paketlerine Geri Dön",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Paket Ekle",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Paketi Görüntüle",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Önerilen Fiyat:",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Şarj Süresi",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Şarj Süresi",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim Türü",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Yardım Metni",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Açıklama",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Feed Kaynağı",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"ad",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Ücretsiz iptal için gün sayısı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Geçerlilik",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Ürün Arama",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"miktar",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Açıklama",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"ad",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Teklif Ayrıntıları",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Görünüm",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Kategoriler",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Sağlayıcıları",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Hizmet türleri",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Is ortagi maliyeti",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Müsteriye maliyet",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Ödemeler",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Geçmiş bulunamadı!",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_COMMENTS_DETAILS":"Yorumlar",
      "PARTNER_COMMENTS_TAB_INVOICE":"Fatura",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Ürün",
      "PARTNER_CONSENT_HELP_TEXT":"Yukarıdaki düğmeyi tıklayarak bağlantıyı kopyalayın ve yeni gizli pencerede açın ve onay akışını tamamlayın.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Lütfen döşeme için Fatura Türü'nü seçin",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Ekle",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Sil",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"tamam",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"E-posta",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"ad",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefon numarası",
      "PARTNER_CUSTOMER_FILTER_NAME":"ad",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Herhangi bir sağlayıcı kiracısına bağlı müşteri yok.",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Atamak",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Müşterilere geri dön",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Plan değiştir",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Şunun İçin Planları Yönet:",
      "PARTNER_CUSTOMER_REPORTS":"Raporlar",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"müşteri etiketi başarıyla güncellendi",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Plan durumunu güncellemek istediğinizden emin misiniz?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Üzgünüz, müşteri bulunamadı",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Alacak hesapları",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Geçmiş Ücretler",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Karlılık",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Aktif müşterileriniz",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Müşteriler",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"ResellerId: {{resellerId}}, billingPeriodId: {{billingPeriodId}} için bayi faturası ayrıntıları bulunamadı.",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Tarihinde fatura oluşturuldu",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Net kazanç",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Diğer ücretler",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Kar",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Fatura Tutarı",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"MSFT Faturası",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"İşletme gelirinize hızlı bir bakış",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Karlılık",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Alacak hesaplarınızın özeti",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Alacak hesapları",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Henüz tahsil edilmeyen ödemeler",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Geçmiş Ücretler",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Faturalar hazır olana kadar görüntülenecek veri yok",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Aktif Ortaklar sizinle çalışıyor",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Bayiler",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Maliyet",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Müşteriler",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonelikler",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Faturalar",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"Bayiler",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_LOGOS_LABEL_TEXT":"Logo Seçin",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Önizleme resmi",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"İndirim",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"ad",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Teklifi Kaldır",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Pozitif bir değer girin",
      "PARTNER_OFFER_SLAB_DATA":"Döşeme verileri",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Aksiyon",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"E-posta",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"İptal etmek",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"onaylamak",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Kategoriler",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Hizmet Türleri",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"durum",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Birim fiyat",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"reddetmek",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Durum başarıyla güncellendi",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategoriler",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Klon planı başarıyla tamamlandı",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Makro Değeri",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Planı yeni para biriminde tanımla",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"ad",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Para Birimi Kodunu Planlama",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Ürün adı",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Hedef para birimi yok, lütfen tanımlamak için iş ortağınızla bağlantı kurun.",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makroyu Tanımla ve Tüm Tekliflerle Kaydet",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"İptal etmek",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klon Planı",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Tüm Tekliflerle Planı Kaydet",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Klon Planı",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATEGORYID":"Kimlik kategorisi",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "PARTNER_PRODUCT_DESCRIPTION":"Açıklama",
      "PARTNER_PRODUCT_NAME":"ad",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"PriceforPartner",
      "PARTNER_PRODUCT_ProviderId":"ProviderId",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "PARTNER_PRODUCT_SALETYPE":"SaleType",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Para birimi",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Açıklama",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Ürün adı",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Serbest bırakmak",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Bu alan gereklidir.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Kategori",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Maliyet",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Açıklama",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"notlar",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Fiyat",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Müşterilere geri dön",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_RESELLER_FILTER_NAME":"ad",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Değişikliklerinizi Kaydedin",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"ad",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"seçmek",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aksiyon",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"İptal etmek",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Sil",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Düzenle",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"E-posta",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Alıcı Türü",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Alıcılar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"TO",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Alıcılar",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Alıcılar",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aksiyon",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Raporlar",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"tarih",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"ayrıntılar",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"durum",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Lütfen {{taxType}} için en az bir vergi adı ve yüzde girin",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Lütfen geçerli vergi yüzdesini girin",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Lütfen alanların vergi adını doldurun",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Kaydedilmemiş alt vergileriniz var, devam etmek istiyor musunuz?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimi",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"iş ortağı etiketi başarıyla silindi",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"iş ortağı etiketi başarıyla güncellendi",
      "PARTNER_TABLE_HEADER_NAME":"ad",
      "PARTNER_TABLE_HEADER_VALUE":"değer",
      "PARTNER_TERMS_OF_USE":"İş ortağı kullanım şartları",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Para birimi",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Açıklama",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Sunmak",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Ay",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Toplam tutar",
      "PARTNER_USER_ALREADY_EXECUTE":"İş ortağı kullanıcısı bu e-postada zaten var.",
      "PARTNER_USER_REMOVED":"Ortak kullanıcı başarıyla kaldırıldı",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Sil",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Etiket Anahtarı",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Etiket Değerleri",
      "PARTNER_USERS_ADD_SUCCESS":"{{Username}} kullanıcısına başarıyla erişim verildi",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"{{Useremail}} e-posta adresine sahip bir kullanıcı sistemde zaten var",
      "PARTNER_USERS_REMOVE_SUCCESS":"İş ortağı kullanıcısı {{username}} başarıyla kaldırıldı",
      "PARTNER_USERS_SAVE_SUCCESS":"İş ortağı kullanıcısı {{username}} başarıyla güncellendi",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Lütfen devam etmek için bir rol atayın",
      "partnerauditlog":"Denetim Günlüğü",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Ortak kullanıcı başarıyla güncellendi",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Ortak Kullanıcılara Geri Dön",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Ekle",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Düzenle",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Ortak Kullanıcı",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"E-posta",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Etiketi Yönet",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Etiket Anahtarı",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Etiket Değerleri",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Parola en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter içermelidir.",
      "PAYMENT_LABEL_FILTER":"Tarafından filtre",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Ödeme profili Varsayılan olarak ayarla",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Ödeme profili silindi",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Kart numarası",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Ödeme ayrıntıları kaydedilemedi",
      "PAYMENT_PROFILE_SAVE_SUCCESS":"Ödeme ayrıntıları kaydedildi",
      "PAYMENT_PROFILE_TEXT_FAILURE":"başarısızlık",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"başarı",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Kabul edilmiş",
      "PAYMENT_STATUS_DESC_CHARGED":"yüklü",
      "PAYMENT_STATUS_DESC_DECLINED":"Reddedilen",
      "PAYMENT_STATUS_DESC_FAILED":"Başarısız oldu",
      "PAYMENT_STATUS_DESC_INPROGRESS":"Devam etmekte",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Kısmen ödenmiş",
      "PAYMENT_STATUS_DESC_SUCCESS":"Başarılı",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Teknik hata",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Güncelleme",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Bu alan gereklidir.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Hesap numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Takma ad",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"İşletme Hesabı mı?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"şirket",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"bireysel",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Hayır",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Evet",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Kontrol etme",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Sona Erme Ayı",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Sona Erme Yılı",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUINT":"Hesaptaki İsim",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"Tasarruf",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Lütfen sayfa yüklenirken bekleyin",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Fatura tutari",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Ödeme Durumu",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Uyarılar",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Tarafından ödenen",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Arıza İşlemleri",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Bekleyen ödemeler",
      "PAYMENTS_STATUS_DESC_FAILED":"Başarısız oldu",
      "PAYMENTS_STATUS_DESC_SUCCESS":"başarı",
      "PAYMENTS_TABLE_HEADER_AMONT":"Ödenen miktar",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Fatura tutari",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Arıza Nedeni",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Fatura donemi",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Teslim Tarihi",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Fatura Bazlı mı?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Tarafından sahip olunan",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Ödenen miktar",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Ödenebilir miktar",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Ödeme Kimliği",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Ödeme şekli",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Bekleyen miktar",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Bekleyen miktar",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Uyarılar",
      "PAYMENTS_TABLE_HEADER_STATUS":"durum",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Kısmi ödeme",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"İşlem günü",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"İşlem Kimliği",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Aksiyon",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Varlık adı",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Detayları göster",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Sipariş Kimliği",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Sipariş verildi",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Uyarılar",
      "Percentage":"Yüzde",
      "PHONE_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Telefon numarası",
      "PLACEHOLDER_ENTER_TEXT":"Metin girin",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"İtibaren geçerli",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Metin girin",
      "PLACEHOLDER_FOR_PRICE":"Fiyat",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"seçmek",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Arama...",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Düzenle",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Kayıt etmek",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Bu alan gereklidir",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Kapat",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Ürün adı",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Ödeme miktarı",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Uyarılar",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Gelecekte bu planda bileşik teklifler planlıyorsanız bu kutuyu işaretleyin.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"Durum başarıyla güncellendi",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"Planlar",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Yeni Plan Ekle",
      "PLAN_CAPTION_TEXT_PLANS":"Planlar",
      "PLAN_CHANGE_FOR_CUSTOMER":"Müşterinin değişim planı",
      "PLAN_CHANGE_TEXT":"Plan Değişikliği",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Kompozit Plan Teklifleri",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Planlar",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Kompozit Tekliflere Dön",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Kompozit Teklif Ekle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Kompozit Teklifi Düzenle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Teklifler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Seçilen öğeler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Önerilen Fiyat:",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Eklenti (ler)",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Eklenti",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Kaldırmak",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Açıklama",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Teklif Adı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Fiyat",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Kompozit Teklif Ekle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Plan Ayrıntılarına Dön",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Kompozit Teklifler -",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Düzenle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Dahil Olan Teklifler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"ad",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b> altında {b> {{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Kaydol",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"her ay",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Tahmini Perakende Fiyat",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"yıl başına",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Değişiklikleriniz başarıyla gönderildi",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"miktar",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Makro Değeri",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Planlara Dön",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Kompozit Teklifleri Yönetme",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Ayrıntılar Plan -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Yeni Plan ekle",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"İptal etmek",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Sunmak",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Özel mi",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"CSP Aboneliklerinin plandaki tekliflerle paylaşılmasına izin verilsin mi?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Teklifler",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Devralınan bir teklifi geçersiz kılma adımları:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Geçersiz kılmak istediğiniz teklife karşı kutuyu işaretleyin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Yeni bir kolay ad girin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Yeni satış fiyatını belirtin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Yeni plan, devralınmış ancak geçersiz kılınmamış tekliflerle birlikte geçersiz kılınan teklifleri listelemeye başlayacaktı.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Not:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Üst Plandan Geçersiz Kılındı",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"seçilmiş",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Öğeler",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Eklenti (ler)",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Satınalınabilir",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Teklif Adı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"AYRİYETEN",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lisans türü",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Kaldırmak",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Satış ücreti",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Güncelleme Fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Plan Adı:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Fiyat:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Yeni Fiyat Ayarla",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lisans türü",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Teklif Geçersiz Kılındı",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Teklif Seçildi",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Açıklama",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"URL paylaşılıyor",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Planlar",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"yönetme",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"Fatura Bazlı mı?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"İçin yeni fiyat belirle",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Fiyat değişikliği etkili olur",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Yeni fiyat",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Mevcut fiyat",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Plan teklif fiyatı başarıyla güncellendi",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Planı Yönet",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Planı Geçersiz Kıl",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Planlar",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Planlara Dön",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Planı Düzenle",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Planı Geçersiz Kıl",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"CSP Aboneliklerinin plandaki tekliflerle paylaşılmasına izin verilsin mi?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Özel mi",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Açıklama",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Not:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Devralınan bir teklifi geçersiz kılma adımları:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Geçersiz kılmak istediğiniz teklife karşı kutuyu işaretleyin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Yeni bir kolay ad girin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Yeni satış fiyatını belirtin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"Yeni plan, devralınmış ancak geçersiz kılınmamış tekliflerle birlikte geçersiz kılınan teklifleri listelemeye başlayacaktı.",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Satınalınabilir",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Teklif Geçersiz Kılındı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Satış ücreti",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Geçersiz kılmak için seçilen öğeler",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Teklifler",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"URL paylaşılıyor",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Para Birimi Kodunu Planlama",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"seçmek",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Kaynak Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Yeni para birimi tanımla",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Döşemeler başarıyla kaydedildi",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Düzenle",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Geçersiz Kıl",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "PLAN_TABLE_HEADER_TEXT_NAME":"ad",
      "PlanProductId":"PlanProductId",
      "plans":"Planlar",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Bileşik teklif oluşturmak için lütfen en az iki teklif seçin",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Bileşik teklif başarıyla kaydedildi",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Kompozit teklif başarıyla güncellendi",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Kapat",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Sunmak",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Tüm ürünler",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"kayıt bulunamadı",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Lütfen değerinden büyük bir değer girin\" + minValue :  \"Lütfen arasında bir değer girin\"  + minValue + \" ve\" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Sözleşme Detayları",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Faturalandırma Türü",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Is ortagi maliyeti",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Levhalar",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Satış ücreti",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"maksimum değer",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Minimum değer",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"uygulanabilirlik",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Planınızda zaten bir sözleşme teklifiniz var. Yenisini eklemeden önce teklifi silin / devre dışı bırakın.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"sözleşme",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Geçersiz kılınan plan başarıyla güncellendi",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan geçersiz kılındı ​​başarıyla",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Lütfen temel plandan geçersiz kılınacak teklifleri seçin",
      "PLANS_SAVE_SUCCESS":"Plan başarıyla kaydedildi",
      "PLANS_UPDATE_SUCCESS":"Plan başarıyla güncellendi",
      "PO NUMARASI":"PO #",
      "PO_NUMBER":"PO #",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Politika atama Kapsamları",
      "PONUMBER_BUTTON_CLOSE":"Kapat",
      "PONUMBER_BUTTON_SKIP":"Atla",
      "PONUMBER_BUTTON_SUBMIT":"Sunmak",
      "POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "POPUP_BUTTON_TEXT_CLOSE":"Kapat",
      "POPUP_BUTTON_TEXT_CONFIRM":"Onaylamak",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Seçilen faturalandırma döngüsünde seçilen tüm teklifler kaybolacak. Devam etmek istiyor musun?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Bu müşteri zaten bir kupon içeriyor: <b> {{CouponName}} </b>, değiştirmek istiyor musunuz ?.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"{{CustomerName}} adlı kullanıcıyı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} başarıyla silindi.",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"SİL",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"{{CustomOfferName}} 'ı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} başarıyla silindi.",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"{{PlanName}} 'ı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"Silmek istediğine emin misin?",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"{{ProductName}} hizmetini devre dışı bırakmak istediğinizden emin misiniz?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"{{ProductName}} 'ı etkinleştirmek istediğinizden emin misiniz?",
      "POPUP_PLAN_DELETED":"{{PlanName}} planı silindi.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"REVERT",
      "POPUP_REVERT_SUB_HEADER_TEXT":"Geri dönmek istediğinizden emin misiniz?",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Geçerli faturalandırma döngüsüne sahip seçtiğiniz teklifler kaybolacak, yine de devam etmek istiyor musunuz?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Kaydedilmemiş değişiklikleriniz var, hala devam etmek istiyor musunuz?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Müşteri adını güncellemek istediğinizden emin misiniz?",
      "PORTAL_ACCESS_GRANTED_TO_USER":"{{UserName}} kullanıcısına portal erişimi verildi",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Fiyat değişikliği, abonelik mevcut ücret döngüsünün başından itibaren uygulanacaktır",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Hemen",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"Bir sonraki faturalandırmadan",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"Bir sonraki yenilemeden",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Kapat",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Kapat",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"E-posta Bildirimini Önizle",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"E-posta Bildirimini Önizle",
      "Price":"Fiyat",
      "PRICE_CHANGE_CURRENCY":"Para birimi",
      "PRICE_CHANGE_NEW_PRICE":"Yeni fiyat",
      "PRICE_CHANGE_OLD_PRICE":"Eski fiyat",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Ayarlar -> İş Ortağı Merkezi ile ilgili talimatları uygulayın.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Bağlantıyı gizli bir oturumda açın ve (İş Ortağı) kiracınızda Global Yönetici olan ve İş Ortağı Merkezi'nde Yönetici Aracısı rolü atanmış bir hesap kullanarak onay deneyimini tamamlayın.",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Faturalandırma Türü",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Maliyet fiyatı",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Ekran adı",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"maksimum değer",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"Minimum değer",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Faturalandırma Türü",
      "PRIVACY_POLICY_BUTTON_TEXT":"GİZLİLİK POLİTİKASI",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Toplu Katılım müşterilerinin işlemleri tamamlandı",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Toplu Katılım müşterilerinin süreci başladı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Quickbooks'a Fatura yükleme işlemi tamamlandı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Faturaları Quickbooks'a yükleme işlemi başlatıldı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Faturaları Xero'ya yükleme işlemi tamamlandı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Xero'ya Fatura yükleme işlemi başlatıldı",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Quickbooks'a Fatura yükleme işlemi başarısız oldu",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Quickbooks'un başarısına Fatura yükleme işlemi",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Faturaları Xero'ya yükleme işlemi başarısız oldu",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Faturaları Xero'ya başarıyla yükleme işlemi",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Seçtiğiniz öğeler kaldırıldı.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Bu alanın değerinin değiştirilmesi seçilen öğelerin kaybolmasına neden olacaktır",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Maliyet iptali olmadan izin verilen maksimum gün sayısı.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Satış Tipi",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Geçerlilik",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Şu anda bu üründe bazı değişiklikler yapıyoruz. Lütfen yeni bir istekte bulunmadan önce bu işlemlerin tamamlanmasını bekleyin.",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Uygulanan Arama:",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Bitiş tarihi",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Başlangıç ​​tarihi",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Alan adı",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Talimatlar",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"onaylamak",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"reddetmek",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Az oku",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Daha fazla oku",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Kategori",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Saglayici Adi",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Devam etmeden önce en az bir aktif ürün seçin.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Listeden en az bir kullanıcı seçin.",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Yüzde",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"miktar",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Kapsam Adı",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Miktarı, mevcut miktarın üzerindeki bir değere güncelleyemezsiniz. Bunu yapmak istiyorsanız, lütfen temel ayrıntılar görünümünden miktarı artırın.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Miktarı satır içi düzenleme yoluyla güncelleyebilir ve kaydetmek için gönder düğmesini tıklayabilirsiniz.",
      "PRODUCT_OWNERSHIP_SUCCESS":"Sahiplik başarıyla güncellendi",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Ekle",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Açıklama",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Açıklama",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Etiket Anahtarı",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Gösterge Paneli",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Müşteri profili",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Banka detayları",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Kredi kartı",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Ödeme şekli",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Lütfen bir ödeme şekli seçin",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Ödeme profili başarıyla güncellendi",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Kullanıcı detayları",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Kent",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"ülke",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"E-posta",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"İsim",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Soyadı",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Satır 1",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Hat 2",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Durum",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Zip",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"E-posta",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Müşteri yapılandırması",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Bayi Yapılandırması",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Telefon numarası",
      "PROFILE_TAB_USER_DETAILS":"Kullanıcı detayları",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Sunmak",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Kayıt etmek",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Kayıt etmek",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Kent",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"ülke",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"E-posta",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"İsim",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Soyadı",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Satır 1",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Hat 2",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Telefon numarası",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Durum",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Zip",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Fatura Adresi",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Fatura Detayları",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Ödeme yöntemi ekle",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Not:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Yeni ödeme profili sonraki tüm işlemleriniz için kullanılacak",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Ödeme yöntemini değiştir",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Ödeme Profili Ayrıntıları",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Lütfen bir ödeme yöntemi ekleyin.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Kayıtlı bir ödeme profiliniz var.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Ödeme yönteminizden son 4 basamak",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Ödeme şekli",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Kullanıcı detayları",
      "PROFİLİ GÜNCELLE":"Profil başarıyla güncellendi",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Hesabınız bu Sağlayıcıya bağlı olmadığından bu öğeyi satın alamazsınız!",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"CSP Kiracılarına karşı yapılan satın almalar, Müşteri İzninin olmaması nedeniyle başarısız olabilir. <a ui-sref=\"home.profile.provider({providername:'Microsoft' )\"> <i class = \"red bold\"> Kiracı Profiline yönlendirmek ve MCA eklemek için burayı tıklayın </i> </a>",
      "PROVIDER_CUSTOMER_NAME":"Sağlayıcı Müşteri Adı",
      "PROVIDER_DESC_MICROSOFT":"Microsoft,",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Saglayici Adi",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"İş Ortağı Maliyeti",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Ödenen miktar",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Ödenen tarih",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Sağlayıcı",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Ülke kodu",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Hata detayları",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"seçmek",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft,",
      "providers":"Sağlayıcıları",
      "PROVISIONING_LABLE_TEXT":"Sağlama ...",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Lütfen yüklenecek bir dosya seçin",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Müşteri Seçin",
      "PUBLIC_SIGNUP_ADD_TO_CART":"Sepete ekle",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Ürün Arama",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"her ay",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"yıl başına",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Onaylamak",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Bize Ulaşın",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Alışverişe devam",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"En son kabul ediyorum",
      "PUBLIC_SIGNUP_NEXT":"Sonraki",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Ödeme şekli",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Sipariş Vermek",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Araba",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Dükkan",
      "PUBLIC_SIGNUP_SIDE_CART":"Araba",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Kaldırmak",
      "PUBLIC_SIGNUP_SUPPORT":"Destek",
      "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Eklentileri",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Tarihinde sona eriyor",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Sipariş verildi",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Ürün adı",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"miktar",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"durum",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"Farkı uzlaştırma girişimi başarısız oldu. Yardım için lütfen destekle iletişime geçin",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"Miktar mutabakatı başarıyla tamamlandı",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Hızlı Kimliğe Bürünme",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Aşağıdaki kullanıcılar kimliğe bürünme için kullanılabilir, devam etmek için herhangi birini seçin",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"impersonate",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Aksiyon",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"rol",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Kullanıcılar)",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"faaliyetler",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Uyarılar",
      "QUICK_SIDEBAR_LABEL_MORE":"Daha",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Bildirimler",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"Ayarlar",
      "QUICK_SIDEBAR_LABEL_USERS":"Kullanıcılar",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"QuickBooks için onayınızı verdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek QuickBooks'a erişecek şekilde yapılandırılabilir. Müşterilerinizin faturalarını QuickBooks'a gönderebilirsiniz.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> içindeki Faturaları QuickBooks'a Yükleme talimatlarını uygulayın.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Lütfen QuickBooks onayınızı işleme koyarken bekleyin",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Aşağıdaki bağlantı yeni bir sekmede açılır. Lütfen QuickBooks kimlik bilgilerinizle onay verin. Onay verirken bir hatayla karşılaşırsanız, lütfen destek ekibimizle iletişime geçin.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"QuickBooks onayı sağlamak için",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Onayı önceden verdiyseniz, lütfen QuickBooks ile bağlantıyı doğrulamak için <b> Jetonu Doğrula </b> düğmesini tıklayın.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Mevcut QuickBooks Yenileme Jetonunun geçerliliğini doğrulamak için bu düğmeyi tıklayın.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"İş Ortağının müşteri faturalarını <b> Çevrimiçi Hızlı Kitaplar </b> 'a göndermesi için önkoşullar",
      "QUICKBOOKS_POST_BATCH_ID":"Quickbooks sonrası Toplu İş Kimliği",
      "QUICKBOOKS_POST_LOGS_STATUS":"Quickbooks Kayıt Günlükleri Durumu",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Var olan QuickBooks Yenileme Simgesi geçersiz. Lütfen rızanızı tekrar sağlayın",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Mevcut QuickBooks Yenileme Jetonu geçerlidir. Faturaları yüklemeye devam edebilirsiniz",
      "QUOTE_ALL_SELECTION":"Hepsini seç",
      "QUOTE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "QUOTE_BUTTON_TEXT_CLOSE":"Kapat",
      "QUOTE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "QUOTE_COMMENTS_TO_BUYERS":"Yorumlar",
      "QUOTE_COMPANY":"şirket",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Kayıt etmek",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"fatura",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Fatura donemi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Açıklama",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Bitis tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Bitiş tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Fatura tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"ad",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Fiyat",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Satış Tipi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Başlangıç ​​tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Birim fiyat",
      "QUOTE_DATE":"tarih",
      "QUOTE_EVENT_ERROR":"Hata",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Yorumlar",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "QUOTE_LABEL_TEXT_EMAIL":"E-posta",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"İsim",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Soyadı",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Müşteri Ekle",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Toplam indirim",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"miktar",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Vergi Türü",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Toplam indirim",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Maliyet fiyatı",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"miktar",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"özet",
      "QUOTE_LIST_STATUS_PUBLISHED":"Fatura Yayımlandı",
      "QUOTE_MOVE_TO_STATE":"Sonraki",
      "QUOTE_PRICE_SUMMARY":"özet",
      "QUOTE_PRICE_TOTAL":"Toplam",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Plan Adı",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Satış ücreti",
      "QUOTE_STATUS":"durum",
      "QUOTE_STATUS_APPROVED":"Müşteri incelemesi için onaylandı",
      "QUOTE_STATUS_DELETED":"silindi",
      "QUOTE_STATUS_PUBLISHED":"Fatura Yayımlandı",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"ad",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Miktar",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"durum",
      "QUOTE_TABLE_LABELS_USER_ROLES":"rol",
      "QUOTE_TABLE_PRICE":"Birim fiyat",
      "QUOTE_TABLE_QUANTITY":"miktar",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"RD Dağıtımlarına Geri Dön",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Yeni RD Dağıt",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Dağıtmak",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"ayrıntılar",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"RD Ayarları",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Alan Adı Ekle",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Yönetici şifresi",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Yönetici Kullanıcı Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Ağ Geçidi DNS Etiketi Öneki",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Ağ geçidi genel IP Adresi Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"Görüntü SKU'su",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Görüntü SKU'sunu seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"yer",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Konum Seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Rd Örnekleri Sayısı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Kaynak Grubu",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"abone",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Abonelik Seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"VM Boyutu",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Görüntü SKU'sunu seçin",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"RD Dağıtımları",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Yeni RD Dağıt",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Dağıtım Durumunu Yenile",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"RD Dağıtımları",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"yönetme",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Dağıtım Tarihi",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"yer",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Kaynak Grubu",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"durum",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"RD Dağıtımlarına Geri Dön",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Dağıtım Ayrıntıları",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"ayrıntılar",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"çevre",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Dağıtım Tarihi",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"RD Dağıtımı başarıyla başlatıldı",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Mutabakat raporunun yeniden yüklenmesi başarısız oldu",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Mutabakat raporu başarıyla yeniden yüklendi",
      "RECON_REPORT_RELOAD":"Yeniden yüklenen mutabakat raporu",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Mutabakat raporu yeniden yükleme başlatılamadı",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Mutabakat raporunun yeniden yüklenmesi başladı",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Arama",
      "RECON_TABLE_TEXT_BILLING_CYCLE":"Fatura döngüsü :",
      "RECONCILIATION_CUSTOMER_NAME":"müşteri adı",
      "RECONCILIATION_NOTES":"notlar",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"<Span class = 'red'> red </span> içindeki öğeler miktar uyuşmazlığını gösterir",
      "RECONCILIATION_NOTES_SUSPENDED":"<Span class = 'strike-through'> tıklanan </span> öğeleri askıya alınmış abonelikleri gösterir",
      "RECONCILIATION_PROVIDER":"Sağlayıcı",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Ödeme miktarı",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Para Birimi Kodu",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Müşteri Seçin",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Müşteriler",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Avans Ödemesini Kaydedin",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Uyarılar",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Müşteri Seçin",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Ödeme başarıyla kaydedildi",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Ödeme tarihi",
      "RECORD_ID":"Kayıt Kimliği",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Harici hizmetten Kayıt Kimliği alındı",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Varlık adı",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"ad",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Hata",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"başarı",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Ödeme miktarı",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Ödeme tarihi",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"ad",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Uyarılar",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Hata",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"başarı",
      "RECORD_PAYMENT_PAID_DATE":"Ödeme tarihi",
      "REFRESH_TOKEN":"Jetonu Yenile",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Seçtiğiniz için teşekkürler <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Yeni aboneliğinizi işlemek için lütfen aşağıdaki bilgileri doldurun.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Bizimle bulunmayan mevcut bir Microsoft Hesabınız varsa, lütfen <a href=\"{{LinktoContact= {\" target=\"_blank\" class=\"ng-binding\"> bize ulaşın </a> yardım için.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Lütfen geçerli bir kupon girin",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok.",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"E-posta",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Lütfen geçerli bir e-posta kimliği girin. Kayıt tamamlandıktan sonra kullanıcı adı ve şifre içeren bir e-posta göndereceğiz",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"İsim",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Soyadı",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Telefon numarası",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Kent",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Şirket Adı",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Satır 1",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Hat 2",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Birincil alan adı",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"Başlamak için şirket adınızı ve ardından .onmicrosoft.com'u kullanarak bir hesap oluşturmanız gerekir. Kuruluşunuzun .onmicrosoft.com olmadan kullanmak istediğiniz kendi özel etki alanı varsa, bunu daha sonra Office 365 Yönetici Portalı'ndan değiştirebilirsiniz.",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Bu nedir",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Durum",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Zip",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Sözleşme Tarihi",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"En son kabul ediyorum",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Planınızı Yapılandırma",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Eklemek istediğiniz plandaki her teklif için en az 1 miktar seçiniz.",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Birincil iletişim",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"şirket Profili",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Banka hesabı",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Kredi kartı",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Kuponu Kaldır",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Lütfen geçerli bir kupon girin",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Kupon kodu uygulandı: <b> {{CouponCode}} </b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Lütfen bir ödeme şekli seçin",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Plan Özeti",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Yinelenen Ücret (her 1 ayda bir)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"ardından {span nextBillingDate}} tarihinde ilk yenilemede <span class = 'bold'> $ {{totalCount}} </span>",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Toplam",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Not:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Satın alma ücretleri, geçerli faturalandırma döngüsünde kalan günler için orantılı olarak uygulanır.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Lisanslar",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"ACH hesabının bir işletme hesabı olup olmadığını belirtmelisiniz!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Kullanıcı başarıyla oluşturuldu",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Alan adı kullanılabilirliği kontrol edilemiyor",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok.",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"Kayıt Durumu",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Kaydınız başarıyla işlendi. Lütfen aşağıdaki kimlik bilgilerini kullanarak portalda oturum açın:",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Kullanıcı adı",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Parola",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Buradan kopyalamayı kaçırmanız durumunda size yukarıdaki ayrıntıyı içeren bir e-posta gönderildi",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Siparişimi İşleme",
      "REJECT_PRODUCT_POPUP_BUTTON":"reddetmek",
      "RELEASE_PRODUCT_OWNERSHIP":"Ürün sahipliğini serbest bırak",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Ürün sahipliğinin serbest bırakılması başarısız oldu",
      "RELEASE_SEED_ERROR":"Miktarı yalnızca bırakırken azaltabilirsiniz, lütfen tekrar deneyin",
      "RELEASE_UNUSED_SEATS":"Kullanılmayan koltukları serbest bırakın",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class = 'red'> <b> {{firstName}} </b> için veriler işlenirken bir hata oluştu. Hata: {{errorMessage}} </span> <br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class = 'success'> <b> {{firstName}} </b> </span> <br/> için kayıtları başarıyla işledi",
      "RELOAD_RECONCILIATION_STATUS":"Mutabakat Durumunu Yeniden Yükle",
      "REMOVE_ALL_SELECTED_RGS":"Seçili Kaynak Gruplarının tümünü kaldırmak istiyor musunuz?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Kaldırmak",
      "REMOVE_PARTNER_PRODUCT_ICON":"Kaldırmak",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Bu kullanıcı için erişimi iptal etmek istediğinizden emin misiniz?",
      "RENEWAL_ALL_DAYS":"Herşey",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"İndir",
      "REPORT_ACTIONS_TEXT":"Aksiyon",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Tüm Müşteriler",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Bu alan gereklidir",
      "REPORT_POPUP_ACTIONS_TEXT":"Aksiyon",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "REPORT_POPUP_EMAIL_TEXT":"E-posta",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Hepsini seç",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Müşteri Seçin",
      "REPORT_SEND_EMAIL_TEXT":"E-posta",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"İndir",
      "REPORT_USAGE_NOTE":"Not :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Is ortagi maliyeti",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Para birimi",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Bir müşteriyle bayilik ilişkisi talep etmek için, URL dahil olmak üzere aşağıdaki metni kopyalayıp bir e-postaya yapıştırın. Gerekirse metni düzenleyin ve e-postayı müşterinize gönderin",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"E-posta Metni",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Bayilik ilişkisi talebi",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Teknede Bulk Onboard isteği",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Tarafından başlatılan Quickbooks'a Fatura yükleme isteği",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"Xero'ya fatura yükleme isteği,",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Faturaları QuickBooks'a yükleme isteği kabul edildi",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Faturaları Xero'ya yükleme isteği kabul edildi",
      "REQUIRED_FIELD_TEXT":"Bu alan gereklidir",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Bayi fatura satır öğeleri dışa aktarılırken hata oluştu",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"ayarlama",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Vergilendirilebilir mi",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Planı Sil",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Plan Tekliflerini Gözden Geçirin",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Planı Kaydet",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"başarı",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Fiyat başarıyla güncellendi",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"miktar",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"kullanım",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Makro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":"Fiyat",
      "RESELLER_AZURE_MACRO_VALUE":"Makro Değeri",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Bayiler",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Listeye geri dön",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Yeni Sağlayıcıyı Bağla",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Bayi Ekle",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Yeni",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Bayi Ekle",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"İletişim detayları",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "RESELLER_CAPTION_TEXT_RESELLER":"Bayiler",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Bayi Fatura Profili",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Bayi Detayları",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Bayi Profili",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Bu bayrağın işaretlenmesi müşterilerin abonelik miktarını azaltmasını önler",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Müşterilerin Azure aboneliklerini yönetmesini sağlama",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Müşterilerin Azure Abonelikleri Üzerinden Gruplar tanımlamasını sağlama",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Bayi masmavi kullanımına uygulanacak işaretleyin (%). Örneğin: İşaretleme% 15 ise 1.15 girin",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Dahili referans için. Bu kimlik, müşteriyi dahili muhasebe sisteminizdeki bir kayıtla ilişkilendirmek için kullanılabilir",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Faturaların otomatik olarak onaylandığı ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Ödemelerin otomatik olarak işlendiği ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Ödemelerin otomatik olarak işlendiği ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Faturaların son ödeme tarihi",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Faturaların otomatik olarak sonlandırıldığı ayın günü",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Bayi için satınalma siparişi numarasını tanımlayın",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Sistemin ödemeleri işleme koymasını istiyorsanız bu işareti işaretleyin (uygunsa CC / ACH)",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Bayiler için Vergi kodunu tanımlayın",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Müşterinin koltukları azaltmasına izin ver",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Azure etkinleştirilsin mi?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Kurumsal için Azure etkinleştirilsin mi?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Azure kullanımı için işaretleme",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Faturalandırma Referans Kimliği",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Bayilerin faturaları görebileceği gün",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Otomatik ödeme günü",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Otomatik ödeme günü",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Faturaların son ödeme tarihi",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Faturaların kesinleştiği gün",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"PO #",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Otomatik ödeme faturaları?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Vergi kodu",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Bayi Yapılandırması başarıyla geri döndürüldü",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Bayi yapılandırması başarıyla kaydedildi",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Düzenleme erişiminiz yok",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Bayi yapılandırması başarıyla güncellendi",
      "RESELLER_CONFIGURATION_OF":"Bayi Yapılandırması",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"İşletme gelirinize hızlı bir bakış",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Karlılık",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Alacak hesaplarınızın özeti",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Alacak hesapları",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Henüz tahsil edilmeyen ödemeler",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Geçmiş Ücretler",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Aktif müşterileriniz",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Müşteriler",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Bekleyen bir faturada uyarı",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Fatura",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Verilen mpn kimliğine sahip sistemde bir satıcı zaten var. Lütfen verilen mpn kimliğini kontrol edin.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Lütfen Ödeme Türünü Seçiniz",
      "RESELLER_IMPERSONATE":"impersonate",
      "RESELLER_IMPERSONATE_USER_ROLE":"impersonate",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"kendi rolünü taklit etmek",
      "RESELLER_INVOICE":"Fatura",
      "RESELLER_INVOICE_TEXT_LINE":"Fatura, fatura adresinize gönderilecektir. Teşekkürler!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Fatura durumu başarıyla güncellendi",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Ödeme başarıyla kaydedildi",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Adres satırı 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Adres Satırı 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Banka adı",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "RESELLER_LABEL_TEXT_CITY":"Kent",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "RESELLER_LABEL_TEXT_COUNTRY":"ülke",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"E-posta",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"İsim",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Soyadı",
      "RESELLER_LABEL_TEXT_MONTH":"Ay",
      "RESELLER_LABEL_TEXT_MPN_ID":"MPN Kimliği",
      "RESELLER_LABEL_TEXT_NAME":"ad",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "RESELLER_LABEL_TEXT_STATE":"Durum",
      "RESELLER_LABEL_TEXT_YEAR":"Yıl",
      "RESELLER_LABEL_TEXT_ZIP":"Zip",
      "RESELLER_LIST_PAYMENT_METHOD":"Ödeme şekli",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"CSP'de bayi bulunamadı. Lütfen verilen mpn kimliğini kontrol edin.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Yüklenen verilerde tespit edilen hatalar. Hataları indirin, düzeltin ve tekrar yüklemeyi deneyin.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"ACH ayrıntılarının bir işletme hesabıyla ilgili olup olmadığını lütfen belirtin",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Faturalandırma E-postası",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"ülke",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"ad",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"başarı",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Ödeme ayrıntıları kaydedildi",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Ödeme profili silindi",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Ödeme profili Varsayılan olarak ayarla",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Bu Ödeme seçeneğini silmek istediğinizden emin misiniz?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makroyu Tanımla ve Tüm Tekliflerle Kaydet",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Tüm Tekliflerle Planı Kaydet",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategoriler",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makro Değeri",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Sağlayıcıları",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"seçmek",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Değişiklikleriniz başarıyla gönderildi",
      "RESELLER_PLAN_DISABLE_BUTTON":"Planı devre dışı bırak",
      "RESELLER_PLAN_ENABLE_BUTTON":"Planı etkinleştir",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"Teklif başarıyla durduruldu",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Teklifi durdur",
      "RESELLER_PLAN_SELECTED_OFFERS":"Seçilen Teklifler",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Planlar",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"İptal etmek",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klon Planı",
      "RESELLER_PLANS_CAPTION_1":"Yeni Plan ekle",
      "RESELLER_PLANS_CAPTION_2":"Ayrıntılar Plan -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Planlar",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Görünüm",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Fiyatlandırma ve Teklifler",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Fiyatlandırma ve Teklifler Listesini İndir",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Fiyatlandırma ve Teklifler",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Lisans Sözleşmesi Türü",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Kadar geçerli",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Bayi fiyatlandırması ve teklifler listesi dışa aktarılırken hata oluştu",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Organizasyon Profili",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Müşteri profili",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Adres Ekle",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"E-posta Ekle",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Telefon Ekle",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Adresler",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Temel Detaylar",
      "RESELLER_PROFILE_PARTNER_CANCEL":"İptal etmek",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Şirket Adı",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"E-mail adresleri",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"İsim",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Soyadı",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Temel ayrıntılar başarıyla güncellendi",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Kayıt bulunamadı!",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Telefon numaraları",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Bu kaydı silmek istediğinizden emin misiniz?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"Sunmak",
      "RESELLER_PROFILE_PAYMENT_ADD":"Ekle",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Banka hesabı",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Kredi kartı",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Profil bulunamadı. EKLE düğmesine tıklayarak yeni bir ödeme profili ekleyin",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Ödeme Profilleri",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Hesap Oluşturma Tarihi:",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Fatura Adresi",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"C3 Başlangıç ​​Tarihi",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Bayi Profili",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"teslimat adresi",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Kullanıcı hesapları",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Bayi Adı",
      "RESELLER_PROFIT_PRODUCT_NAME":"Ürün adı",
      "RESELLER_PROFIT_RESELLER_NAME":"Bayi Adı",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Herşey",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"HAYIR",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"EVET",
      "RESELLER_REGISTRATION_SUCCESS":"Bayi kaydı başarıyla tamamlandı.",
      "RESELLER_RELATIONSHIP_LBL":"Bayi ilişkisi kurmak için lütfen aşağıdaki içeriği müşteriyle paylaşın",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Görüntüleme ölçütü",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Lütfen geçerli vergi yüzdesini girin",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Lütfen alanların vergi adını doldurun",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Yeni Etkinlik E-posta Bildirimi Ekle",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"iş ortağı etiketi başarıyla silindi",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"iş ortağı etiketi başarıyla güncellendi",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Yapılandırılmış sağlayıcı yok, Lütfen Destek ile İrtibata Geçin",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Bu sağlayıcı için kullanılabilir anahtar yok",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Bayi Yapılandırması",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Bayi Ayarı",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Teklif ata",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Düzenle",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"impersonate",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Sağlayıcı Bağlantılarını Yönet",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Oluşturma Tarihi",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"ad",
      "RESELLER_TABLE_SIGNUP_DATE":"Kayıt Tarihi",
      "resellers":"Bayiler",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Fatura",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Mutabakat",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Ücreti Satıcısı",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Bayi başarıyla eklendi.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Bayi Ödemeleri",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Planları Yönet",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"E-postalar",
      "RESEND_INVOICE":"Faturayı tekrar gönder",
      "RESEND_INVOICE_HEADER_TEXT":"Faturayı yeniden göndermek için lütfen E-posta Kimliklerini girin",
      "RESEND_INVOICE_NOTE":"<b> Not: </b> Faturayı birden fazla E-posta Kimliğine yeniden göndermek isterseniz, lütfen virgülle ayırarak kimliği girin. Örn: abc @ mail.com, yeni @ mail.com",
      "RESEND_INVOICE_USERS_LIST":"Fatura kullanıcı listesini tekrar gönder",
      "RESET_VALUE":"Değeri sıfırla",
      "Resolved":"kararlı",
      "RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Ödeme başarıyla başlatıldı. İşleme biraz zaman alacaktır. Lütfen bir süre sonra durumu kontrol edin.",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Fatura tutari",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Tüm faturaları indir",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Kategori",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"İş Ortağı Maliyeti",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Müşteri",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Distribütör Faturası",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Sağlayıcı",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"SaleType",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Aidat",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Faturalar ve Aidatlar",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Ödemeler",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Hataları",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Gecikmeler",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Görüntüleme ölçütü",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Fatura Üretimi Tamamlandı",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Müşteri faturaları oluşturulurken hata oluştu",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Müşteri faturaları başarıyla oluşturuldu",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları Yükleniyor ...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Faturayı yeniden oluştur",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Faturaları görüntüle",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Abonelik geçmişini görüntüle",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Miktar",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Fatura donemi",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Müşteri",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Is ortagi maliyeti",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"kâr",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Tüm faturaları e-posta ile alın",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Kategori",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Müşteri",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Ödevi iptal etmek istediğinizden emin misiniz?",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Lütfen en az bir üst seviye plan teklifi için miktarı girin.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Kullanıcı ayrıntılarını doldurun",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Rol atama Kapsamları",
      "ROLE_NAME_C3_SUPPORT":"C3 Desteği",
      "ROLE_NAME_CUSTOMER_ADMIN":"Müşteri Yöneticisi",
      "ROLE_NAME_CUSTOMER_READER":"Müşteri Okuyucu",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Bölüm Yöneticisi",
      "ROLE_NAME_DEPARTMENT_READER":"Bölüm Okuyucu",
      "ROLE_NAME_FINANCE_ADMIN":"Finans Yöneticisi",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Finans ile ilgili sayfaları yönetebilir.",
      "ROLE_NAME_GROUP_ADMIN":"Grup Yöneticisi",
      "ROLE_NAME_HELP_DESK":"Yardım Masası",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Yardım Masası",
      "ROLE_NAME_SITE_ADMIN":"Site yöneticisi",
      "ROLE_NAME_SITE_READER":"Site Okuyucu",
      "ROLE_NAME_SYSTEM_ADMIN":"Sistem yöneticisi",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Portaldaki tüm sayfalara erişebilir / bunları değiştirebilir.",
      "ROLE_NAME_SYSTEM_READER":"Sistem Okuyucu",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Tüm sayfaları ziyaret edebilir ancak değişiklik yapamaz.",
      "ROLE_NAME_USER":"kullanıcı",
      "ROLE_NOTE_C3_SUPPORT":"C3 Desteği miktar uyuşmazlığı durumlarını çözebilir ve süper bir izin kümesidir",
      "ROLE_NOTE_CUSTOMER_ADMIN":"Müşteri yöneticisi, kiracı bağlamında tüm verileri görüntüleyebilir ve tüm işlemleri gerçekleştirebilir",
      "ROLE_NOTE_CUSTOMER_READER":"Müşteri ile ilgili tüm verilere salt okunur erişime sahip müşteri okuyucuları.",
      "ROLE_NOTE_FINANCE_ADMIN":"Finans yöneticileri müşteri gelirine ve maliyet özetine erişebilir, vergi yüzdesini tanımlayabilir, mutabakat raporunu görüntüleyebilir",
      "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins, kendi grubu altındaki tüm Azure aboneliklerini yönetebilir.",
      "ROLE_NOTE_HELP_DESK":"Yardım Masası kullanıcıları, yeni müşterilerdeki tüm müşteri ayrıntılarını görüntüleyebilir ve destek sağlayabilir. Yardım Masası kullanıcıları müşteri verilerinin kimliğine bürünebilir ve bunlara erişebilir",
      "ROLE_NOTE_SYSTEM_ADMIN":"Sistem yöneticisi, tüm verileri görüntüleyebilir ve tüm eylemleri hem iş ortağı hem de müşteriler bağlamında gerçekleştirebilir",
      "ROLE_NOTE_SYSTEM_READER":"İş ortağıyla ilgili tüm verilere salt okunur erişime sahip sistem okuyucuları. Okuyucular taklit edemez",
      "SAATLER":"Saatler)",
      "SALE_TYPE_DESC_PRODUCT":"Ürün",
      "ŞARTLAR VE KOŞULLAR":"Şartlar ve koşullar",
      "SATIŞ":"Satış",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodunu kaydetmeye çalışıyorum",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan kimlik doğrulama kodunu kaydetmeye çalışılıyor",
      "SAVE_CART_EXCEPTION":"Alışveriş sepetine ürün eklenirken hata oluştu",
      "SAVE_COUPON_FAILURE":"Kupon kaydedilemedi",
      "SAVE_COUPON_SUCCESS":"Kupon başarıyla kaydedildi",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü kaydedilirken hata oluştu",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"E-posta bildirimi alıcıları listesi kaydedilirken veya güncellenirken hata oluştu",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları kaydedilirken hata oluştu",
      "SAVE_PLAN_PRODUCT_SLAB":"Kayıtlı sözleşme teklif levhaları",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Sözleşme teklif levhaları kaydedilemedi",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Sağlayıcı ödeme bilgileri başarıyla kaydedildi.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Bayi Ödeme Profilini Kaydet",
      "SAVE_RESELLER_PLAN":"Bayi Planını Kaydet",
      "SAVE_RESELLER_PLAN_FAILURE":"Bayi Planı Kaydedilemedi",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Bayi Planı Ürün Para Birimi Oranını Tanımlama",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Bayi Planı Ürün Para Birimi Oranı Tanımlanamadı",
      "SAVE_SUB_TAX":"Alt Verileri Kaydet",
      "SAVE_SUB_TAX_FAILURE":"Alt Verileri Kaydetme Başarısız",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Yeni Bilet başarıyla oluşturuldu",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Bilet başarıyla güncellendi",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"durum",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Sil",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Düzenle",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Aktif",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Başarısız oldu",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Fatura döngüsü",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"miktar",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"durum",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilmiş",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Fatura özeti",
      "SCHEDULED_REPORT_PROFITABILTY":"Kârlilik Raporu",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Alıcılar",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Vergi Satir Ögeleri",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Vergi Özeti",
      "SELECT_ALL_CUSTOMER":"Tüm Müşteriler",
      "SELECT_ALL_RG":"Tüm Kaynak Gruplarını seçmek istiyor musunuz?",
      "SELECT_COUPON":"Kupon Seçin",
      "SELECT_CUSTOMER":"Müşteri Seçin",
      "SELECT_DEFAULT_OPTION_SELECT":"seçmek",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Müşteri Seçin",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Etkinlik Seçin",
      "SELECT_EVENT":"Etkinlik Seçin",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Lütfen en az bir fatura seçin",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Satış fiyatınızı tanımlamak için hızlı seçenekler",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Etkinlik Seçin",
      "SELECT_PLAN_TOOLTIP_TEXT":"Planlar",
      "SELECT_PRIORITY":"Öncelik Seç",
      "SELECT_PRODUCT_AREA":"Ürün Alanını Seçin",
      "SELECT_STATUS":"Durum seçin",
      "SELECT_TYPE":"Tür Seçin",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Quickbooks'a fatura yüklemek için seçilen müşteriler",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Xero'ya Faturaları yüklemek için seçilen müşteriler",
      "SELECTED_FILTER_ALL":"Hepsini seç",
      "sell-direct":"Doğrudan Satış",
      "sell-indirect":"Dolayli Satis",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Faturayı Tekrar Gönder",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Gönder",
      "SEND_MAIL_BUTTON":"Posta göndermek",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
      "SERVICE_PROVIDER_TENANT":"Tedarikçi Kiracıları",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Varsayılan Kiracı",
      "SERVICE_PROVIDER_TENANT_HEADER":"Sağlayıcı Kiracıları",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Aksiyon",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Kiracı Kimliği",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Kiracı adı",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"<b> {{customerName}} </b> 'ı varsayılan kiracı olarak işaretlemek istediğinizden emin misiniz?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{CustomerName}}, varsayılan kiracı olarak güncellendi",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Oturumunuz sona erdi. Devam etmek için lütfen tekrar oturum açın. Maalesef kaydedilmemiş değişiklikler kaybedilecek.",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Yerleşik Müşteriler toplu iş durumu Tamamlandı olarak ayarlandı",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Harici hizmet sonrası günlüklerinin durumunu Errorignored olarak ayarlamaya çalışılıyor",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Harici hizmet yayını günlüklerinin durumu ErrorIgnored olarak ayarlanamadı",
      "settings":"Ayarlar",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimleri",
      "SETTINGS_HEADING_TEXT_BILLING":"fatura",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Para birimi dönüştürme",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Müşteri Varsayılanları",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Planı Sil",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"E-posta Bildirimleri",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Müşteri Yapılandırmaları (Global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"İş Ortağı Varsayılanları",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "SETTINGS_PASSWORD_HELP_TEXT":"Parolayı güncellemek istemiyorsanız, alanı boş bırakın.",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Genel",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"logolar",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Ortak Merkezi",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Sağlayıcıları",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Herhangi bir vergi adı belirleyebilirsiniz: GST.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Vergi yüzdesini burada belirleyebilirsiniz. Örnek:% 15 için 15",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Sepete ekle",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Daha fazla oku..",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Daha fazla oku..",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Azure faturalandırma verileri hakkında rapor oluşturma",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Kullanıma dayalı abonelikler",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Kiracı tarafından tüketilen lisansların listesini görüntüleme",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"AZURE kullanımını açıklayın",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Kuruluş profilinizi, adreslerinizi, ödeme profillerinizi ve daha fazlasını yönetin ...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Lisans tahsisine ve ücretsiz koltuklara hızlıca bakın.",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"İndirimleri tanımlayın",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"C3 ile verileriniz hakkında birkaç rapor sunar.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Satmak istediğiniz tekliflerle planları tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Azure kullanımı hakkında Power BI tabanlı rapor",
      "SIDEBAR_CONTENT_MENU_USERS":"Kullanıcılara erişimi yönetin",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Kârlılığınızı analiz edin",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Azure Tahminlerini Görüntüleyin",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Bir veya daha fazla aboneliği gruplayın ve bütçeleri ve eşikleri tanımlayın",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Özel katma değerli hizmetlerinizi ve tekliflerinizi tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"İş Ortağınızdan faturalarınıza erişin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Müşterilerinize bağlı Microsoft CSP kiracılarıyla ilgili özelliklere erişmenizi sağlar",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Azure AD kullanıcılarını listeleyin ve yönetin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Siparişlerinizi takip edin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Satın alınan ürünleri görüntüle",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Katalogdan alışveriş ve satın alma teklifleri",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Faturaları ve ödemeleri yönetme",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Birden fazla teklifi bir araya getirmenize ve müşterilerinize satın alma için sunmanıza olanak tanır",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Aktif müşteriler listesine ve verilerini yönetme seçeneklerine yönlendirir",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Sizi gösterge tablosu sayfasına götürür",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Satmak istediğiniz tekliflerle planları tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Kârlılığınız hakkında genel bir bakış sunar ve fatura ayrıntılarına da bakar",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Kârlılığınız hakkında genel bir bakış ve fatura ayrıntılarına da göz atmanızı sağlar",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Etkin satıcılar listesine ve verilerini yönetme seçeneklerine yönlendirir",
      "SIDEBAR_CONTENT_MENUS_SELL":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Yorumlar",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Azure Faturalandırma Raporu",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Azure Abonelikleri",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"analitik",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lisans Tüketimi",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Microsoft Azure Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Lisans Tüketimi Özet Raporu",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Lisans Tüketim Raporu",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Kuponlar",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Yükleme",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"kullanım",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Raporlar",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Planlar",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_USERS":"Kullanıcılar",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"çözümlemek",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Azure Tahminleri",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Azure Grupları",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Ortak teklifleri",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Faturalar",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft,",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Microsoft Kullanıcıları",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Emirler",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Ürün",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Dükkan",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Faturalar",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Paketler",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Müşteriler",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Planlar",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"Bayiler",
      "SIDEBAR_TITLE_MENUS_SELL":"Satmak",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Doğrudan Satış",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Dolaylı Satış",
      "SIGN_UP_DATE":"Kayıt Tarihi",
      "SIGNUP_CART_COUPON_APPLY":"Uygulamak",
      "SİPARİŞ KİMLİĞİ":"Sipariş Kimliği",
      "SİTE":"(Şantiye)",
      "SİTE KİMLİĞİ":"C3SiteId",
      "SITE_DEPARTMENT_ID":"SiteDepartmentId",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Aksiyon",
      "SLAB_TYPE_SELECT_OPTION":"seçmek",
      "SMTP_SETTINGS_TEST":"SMTP ayarlarını test etmek için lütfen düğmeyi tıklayın",
      "SMTP_TEST_BUTTON":"SMTP'yi test et",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Kaynak Para Birimi",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Kaynak Para Birimi",
      "STATUS":"durum",
      "STATUS_ALL":"Herşey",
      "STATUS_DUE":"Aidat",
      "STATUS_OVER_DUE":"Aşırı aidat",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Durum başarıyla güncellendi",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Lütfen alt vergiler için en az bir vergi adı ve yüzde girin.",
      "ŞUBAT":"Şubat",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Bu alan gereklidir",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Sunmak",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Fatura donemi",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Fatura döngüsü",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Fatura donemi",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Kategori",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Müşteriler",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Bitiş tarihi",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Abonelik Adi",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Sağlayıcı",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Başlangıç ​​tarihi",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Kategoriler",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Sağlayıcıları",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Kapat",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Arama",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Sıfırla",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"tarih",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Bölüm Adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Yeni fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Ayriyeten",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Ürün adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Eski fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Site adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"durum",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Bitiş tarihi",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Başlangıç ​​tarihi",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Lisans Atamalarını Yönetme",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonelikler",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Kullanılmayan Koltukları Serbest Bırak",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Kullanılmayan koltukların serbest bırakılması. Lütfen bekle...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"İçin Lisans Atamalarını Yönetin",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Lisans Ata",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Lisansları Kaldır",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Kullanıcı ata",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Talimatlar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Talimatlar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Görüntüleme ve arama",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Kaldırılacak lisanslar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Doğrulama Hataları",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Geçerli kullanıcı e-posta adreslerini virgülle ayırarak yazın.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Bu Portal girişi doğrular ve atamadan önce ek lisansların satın alınması gerekip gerekmediğini belirler.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Bu abonelikteki değişiklikler üzerinde çalışırken hiçbir işlem gerçekleştirilemez.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Girişin doğrulanması sırasında bulunan hatalar görüntülenecektir.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Gönderme sırasında Lisans Ata veya Kaldır (etkin sekme) dikkate alınır.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Kaldırılacak lisanslar listesine eklemek için soldaki tabloda yer alan bir kullanıcıya karşı <i class = 'fa fa-remove red'> </i> seçeneğini tıklayın.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Lisansları kaldırmak için Gönder'i tıklayın",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Gönderme sırasında Lisans Ata veya Kaldır (etkin sekme) dikkate alınır",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Maliyet",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Orijinal Miktar.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Koltuk Başına Ücret",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Çoklu Kullanıcı",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Kaldırmak",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Dosya yükleme",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"ad",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"yeni kullanıcı e-posta adresi ekle",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Bir .txt dosyasına virgülle ayrılmış geçerli kullanıcı e-postaları girin ve buraya yükleyin.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":".Txt dosyasına virgülle ayrılmış geçerli kullanıcı e-postaları girin ve yükleyin. Örnek: abc @ xyz.com, bcd @ xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Hataları Yoksay",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Hataları düzeltmek",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"durum",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Lütfen isteğinizi işleme koyarken bekleyin, bu işlem birkaç dakika sürebilir. Abonelikler sayfasındaki 'Yönet' düğmesini tıklayarak da tekrar kontrol edebilirsiniz.",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Sipariş İptal Edildi",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Sipariş Tamamlandı",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonelik Satın Alındı",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonelik Kaldırıldı",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"Seçilen istek başarıyla onaylandı.",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Abonelik Sağlama",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"Seçilen istek başarıyla iptal edildi.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Tüm Müşteriler",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Abonelik Sağlama",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"etkinleştirme",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Varlık adı",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Başlık",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Lütfen bekleyin earlier önceki satın alımınızı işleme koyarken bu işlem birkaç dakika sürebilir.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Lütfen bekleyin earlier önceki satın alımınızı işleme koyarken bu işlem birkaç dakika sürebilir. 'Yeni Abonelik Ekle' düğmesini tıklayarak da durumu kontrol edebilirsiniz.",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Listeye geri dön",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Aksiyon",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"ad",
      "SUBSCRIPTION_STATUS_ACTIVE":"Aktif",
      "SUBSCRIPTION_STATUS_DELETED":"silindi",
      "SUBSCRIPTION_STATUS_FAILED":"Başarısız oldu",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Karşılığı",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Başarılı",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Askıya alındı",
      "SUBSCRIPTION_UPDATED":"Abonelik başarıyla güncellendi.",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuel",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Abonelik Adı",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Bu alan gereklidir",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Bu müşteri zaten atandı.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Aşağıdaki kullanıcıların bu teklifte zaten atanmış bir lisansı var. Lütfen bunları kaldırın ve kaydetmeyi deneyin.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Hataları Yoksaymak istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Lisans Atamaları başarıyla güncellendi",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"Bu aboneliği yeniden etkinleştirmek istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"Seçilen abonelik başarıyla yeniden etkinleştirildi",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Kullanılmayan koltukları bırakmak istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Lisansları Atanmış Lisanslar tablosundan kaldırmak için lütfen en az bir kullanıcıyı kaldırın!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Ekle düğmesine tıklamadan önce lütfen önerilen listeden kullanıcı seçin.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"Bu aboneliği askıya almak istediğinizden emin misiniz? İlgili etkin eklenti aboneliklerinin de (varsa) askıya alınacağını lütfen unutmayın",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"Abonelik (uygulanabilir eklentiler dahil) başarıyla askıya alındı",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Aboneliğin kullanılmayan koltukları başarıyla yayınlandı.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Toplu atama isteğiniz sırada",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Başarıyla yüklendi",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Lütfen metin kutusuna lisans atamak için geçerli kullanıcı e-postalarını girin.",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonelikler",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Düzenle",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"yönetme",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Toplam",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"ad",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Maliyet",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Toplam",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Yeni Azure Aboneliği",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"ad",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"masmavi",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Azure Abonelikleri",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Tahmini Fiyat",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonelikler",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Yeni Miktar Toplamı",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"Minimum Değer 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"Giriş geçerli bir tamsayı olmalıdır",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Teklif",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"<strong> Yeni Miktar Toplamı </strong> 'nı artırarak ek koltuk satın alın",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Kullanıcılardan Lisansları <strong> Kullanıcılar - & gt; Buradan koltuk sayısını azaltmak istediğinizde lisanslar </strong>",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Abonelik satın alma isteğiniz sırada",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Yeniden",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonelik başarıyla kaydedildi",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Lütfen sipariş vermeden önce en az bir teklif seçin",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"askıya almak",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Genel ayarlar başarıyla güncellendi",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"İş ortağı SMTP ayarları başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"{{Logo}} ortağı başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"İş ortağı ayarları başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Sağlayıcı ayarları başarıyla güncellendi.",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Miktar uyuşmazlığını düzeltin",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Lütfen bu müşterinin planını alakalı teklifleri içerecek şekilde güncelleyin ve sorunu düzeltmek için buraya geri dönün",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"CSP Teklif Adı",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Eşleşen teklif bulunamadı",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Mevcut planda eşleşen teklif",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Fiyat",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"miktar",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Teklif eşleme",
      "SUPPLIER_DETAILS":"Tedarikçi Detayları",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Yeni Destek Bileti",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"İletişim Telefonu",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Açıklama",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":"öncelik",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Ürün Alanı",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"konu",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"tip",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Destek",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET #":"Destek bileti #",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Yorum ekle",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Yorumlar",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"İletişim Telefonu",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Kümülatif Çaba",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Müşteri",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Açıklama",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Çaba (Saat Olarak)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"öncelik",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Ürün Alanı",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"durum",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"konu",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Destek Temsilcisi",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Destek Aracısını Seçin",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"tip",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Yorum eklenmedi.",
      "SUPPORT_FOR_TEXT":"İçin destek",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Destek Bileti Ekle",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"destek biletleri",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Müşteri",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Arama",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"durum",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Destek Temsilcisi",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Bilet Kimliği",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Aksiyon",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"ayrıntılar",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Müşteri",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"öncelik",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Ürün Alanı",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"durum",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"konu",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Bilet Kimliği",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"tip",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Yeni destek bileti başarıyla oluşturuldu",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Arşivlenen",
      "SUPPORT_TICKET_STATUS_CLOSED":"Kapalı",
      "SUPPORT_TICKET_STATUS_IN İLERLEME":"Devam etmekte",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"Devam etmekte",
      "SUPPORT_TICKET_STATUS_OPEN":"Açık",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Yeniden aç",
      "SUPPORT_TICKET_STATUS_RESOLVED":"kararlı",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"Düzeltilmeyecek",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Erişim sorunları",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"analitik",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Profil Yönetimi",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Abonelik Yönetimi",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Destek bileti bilgileri başarıyla güncellendi",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Para birimi",
      "SWAL_OK_TEXT":"tamam",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Yeni güvenlik modeline geç",
      "TAB_HEADING_PROVISIONING_STATUS":"Temel hazırlık durumu",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"{{Total}} kayıttan {{StartIndex}} - {{EndIndex}} gösteriliyor",
      "TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "TABLE_HEADER_TEXT_DATE":"tarih",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Arıza Nedeni",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Büyük Miktar",
      "TABLE_HEADER_TEXT_KEY":"anahtar",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Ödenen miktar",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Ödeme Durumu",
      "TABLE_HEADER_TEXT_REMARKS":"Uyarılar",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Vergi miktarı",
      "TABLE_HEADER_TEXT_VALUE":"değer",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Seçilen Fatura (lar) Toplamı",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Arama...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Etiket başarıyla silindi",
      "TAG_SAVED_SUCCESS_MESSAGE":"Etiket başarıyla kaydedildi",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Plan Adı",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Ürün adı",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Etiketlere Geri Dön",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Ekle",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Düzenle",
      "TAGS_CAPTION_TEXT_TAG_FOR":"Etiketi",
      "TAGS_CAPTION_TEXT_TAGS_FOR":"İçin etiketler",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Hedeflenen Para Birimi",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Hedeflenen Para Birimi",
      "TARGET_PLAN_DISPLAY_NAME":"Hedef Planı",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Vergi Yüzdelerine Geri Dön",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Ekle",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Düzenle",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Vergi kuralları {Ülke, Eyalet Eyaleti ve Vergi Kodu}, {Ülke, Zip} veya {Ülke, Eyalet Eyaleti ve Zip} kombinasyonlarıyla tanımlanır",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Örn: ABD gibi bir ülke için eyalet, il ve posta kodunu boş bırakarak vergi kuralı tanımını değerlendirin, vergi oranının ABD'deki tüm müşterilere uygulandığı anlamına gelir. Belirli bir eyalet için kural tanımlamak istiyorsanız, kuralı tanımlarken Ülke ve Eyalet değerlerini belirtin",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Vergi Adı",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "TAX_PERCENTAGES_ADD_SUCCESS":"Vergi Yüzdesi başarıyla eklendi.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Müşteriler",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Vergi Yüzdesi başarıyla silindi.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Vergi Yüzdesi Ekleyin",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Sil",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Aksiyon",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"İtibaren geçerli",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"Vergi %",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Vergi Yüzdesi başarıyla güncellendi.",
      "TAX_RULES_ADD_SUCCESS":"Vergi kuralı başarıyla eklendi",
      "TAX_RULES_DELETE_SUCCESS":"Vergi kuralı başarıyla silindi",
      "TAX_RULES_UPDATE_SUCCESS":"Vergi kuralı başarıyla güncellendi",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Kümülatif Vergi",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Çocuk vergi kuralları, hesaplamadaki her adımdan elde edilen miktara sırayla uygulanır.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Genel",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Toplam tutara uygulanan tek vergi yüzdesi.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Alt vergiler",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Bu tanımın vergi yüzdesi, çocuk vergi kurallarından toplam (toplam) olacaktır.",
      "TEMMUZ":"Temmuz",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Bu bayrağın işaretlenmesi müşterilerin abonelik miktarını azaltmasını önler",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Müşterilerin Azure aboneliklerini yönetmesini sağlama",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Müşterilerin Azure Abonelikleri Üzerinden Gruplar tanımlamasını sağlama",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Müşteri masmavi kullanımına uygulanacak işaretleyin (%). Örneğin: İşaretleme% 15 ise 1.15 girin",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Müşteri Para Birimi",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Destek Müşteri Ürünü Otomatik Fiyat Güncelleme Etkinlik Türü",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Bu alan işaretlenirse, müşteri onayını dikkate alırız.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Müşterilerden en az biri MCA'yı kabul ettiğinde bu alan güncellenir. Müşterilerden kabulü tekrar zorunlu kılmak için bu kutunun işaretini kaldırın.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilen son şartlar ve koşullar?",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Dahili referans için. Bu kimlik, müşteriyi dahili muhasebe sisteminizdeki bir kayıtla ilişkilendirmek için kullanılabilir",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Faturaların otomatik olarak onaylandığı ayın günü",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Otomatik ödemelerin işleme koyulduğu gün",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Faturaların son ödeme tarihi",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Faturaların otomatik olarak sonlandırıldığı ayın günü",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Şartlar ve koşullar bağlantısı",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Müşteri için satınalma siparişi numarasını tanımlayın",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Sistemin ödemeleri işleme koymasını istiyorsanız bu işareti işaretleyin (uygunsa CC / ACH)",
      "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Müşteri RI kullanımına uygulanacak işaretleyin (%).",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Müşteriyi Bildirmeli",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Müşteri Ürün Otomatik Fiyat Güncellemesini Destekleyin",
      "TENANT_CONFIG_DESC_TAX_CODE":"Vergi kodu, geçerli vergi yüzdesini belirlemek için kullanılır",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Şartlar ve Koşulları göster Kabul Kiracıya Giriş",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Müşterinin koltukları azaltmasına izin ver",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Azure etkinleştirilsin mi?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Kurumsal için Azure etkinleştirilsin mi?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Azure kullanımı için işaretleme",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Müşteri Para Birimi",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Müşteri Ürünü Otomatik Fiyat Güncelleme Etkinliği",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Etkin Müşteri Onayı?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Microsoft Müşteri Sözleşmesi kabul edildi mi?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul Edilen İş Ortağı Şartları ve Koşulları?",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Faturalandırma Referans Kimliği",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Müşterinin faturaları görebileceği gün",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Otomatik ödeme günü",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Faturaların son ödeme tarihi",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Faturaların kesinleştiği gün",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"Şartlar ve koşul URL'si",
      "TENANT_CONFIG_DISP_PO_NUMBER":"PO #",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Otomatik ödeme faturaları?",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Şartlar ve Koşulları Göster Kabul Günlüğü",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Müşteri Ürünü Otomatik Fiyat Güncellemesi",
      "TENANT_CONFIG_DISP_TAX_CODE":"Vergi kodu",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Vergi Türü",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Lütfen {{tenantConfigName}} değerini girin",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Lütfen geçerli bir {{tenantConfigName}} sayısı girin",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Müşteri Yapılandırması başarıyla geri döndürüldü",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Müşteri yapılandırması başarıyla kaydedildi",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"{{Customername}} için müşteri yapılandırması başarıyla güncellendi",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Bölüm",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Alan adı",
      "TENANT_MANAGEMENT_LABEL_SITE":"yer",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"ad",
      "TERM_AND_CONDITIONS_LINK_TEXT":"İş ortağı kullanım şartları",
      "TERM_DURATION_DESC_DAY(S)":"Gün (ler)",
      "TERM_DURATION_DESC_MONTH":"Ay",
      "TERM_DURATION_DESC_MONTH(S)":"Ay (lar)",
      "TERM_DURATION_DESC_YEAR":"Yıl",
      "TERM_DURATION_DESC_YEAR(S)":"Yıl (lar)",
      "TERMS_AND_CONDITIONS_ACCEPT":"Lütfen şartlar ve koşulları kabul edin",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Şartlar ve koşullar başarıyla kabul edildi.",
      "TERMS_AND_CONDITIONS_BACK":"Şartlar ve Koşullara Geri Dön",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Lütfen aşağıdaki şartlar ve koşulları okuyun </b>. </br> </br> <ul> <li> <a ng-click='vm.PartnerAgreementClicked()'> İş ortağı kullanım koşulları </a> </li> </ul> </br> Tıklayarak \"Kabul\" düğmesine basarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Lütfen Microsoft tarafından sağlanan aşağıdaki şartlar ve koşulları okuyun. </br> \"Kabul Et\" düğmesini tıklayarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Lütfen aşağıda verilen sözleşme, şartlar ve koşulları <b> {{partnerName}} </b> ve Microsoft'tan okuyun. </br> </br> <ul> <li> <a ng-click='vm.PartnerAgreementClicked()'> {{hyperlinkContent}} </a> </li> </ul> </br> Tarafından \"Kabul Et\" düğmesini tıklayarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Ezidebit Anlaşmasının tamamını okumak için <a id='linkEzidebit' ng-click='vm.EzidebitAgreementClicked()'> buraya </a> tıklayın.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Ezidebit Anlaşması",
      "TERMS_AND_CONDITIONS_READ":"Şartlar ve koşulları okudum",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"İsteğiniz işleme koyulamıyor.",
      "TEST_EMAIL_LABEL":"Test EmailId",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Ölçek",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Ölçek",
      "TEST_SMTP_SETTING_TITLE":"SMTP ayarlarını test etme",
      "TEXT_ACH":"ACH",
      "TEXT_ACTIONS":"Aksiyon",
      "TEXT_BILL_FROM":"Fatura Gönderen",
      "TEXT_BILLING_PERIOD":"Fatura donemi",
      "TEXT_EMAIL_ID":"Email kimliği",
      "TEXT_GROUP_NAME":"Grup ismi",
      "TEXT_INVOICE_NUMBER":"Fatura numarası",
      "TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "TEXT_SIGN_IN_REQUIRED":"Oturum açmanız gerekiyor",
      "TEXT_TENANT_ID":"Kiracı Kimliği",
      "TEXT_THRESHOLDS":"Eşik",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"anlaşma",
      "TIKLAYIN":"Tıklayın",
      "TITLE_COUPON_CODE_TEXT":"Kupon Kodları",
      "TITLE_CUMULATIVE_TAXES":"Kümülatif Vergiler",
      "TITLE_ROLE_SELECT":"Rol Seçin",
      "TITLE_SUB_TAXES":"Alt Vergiler",
      "TITLE_TAGS":"Etiketler",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"Azure Aboneliklerine giriş",
      "TOOLTIP_COUPON_CODE_TEXT":"Kupon Kodu",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Ürünü Devre Dışı Bırak",
      "TOOLTIP_TEXT_EDIT_NAME":"Adı Düzenle",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Ürünü Etkinleştir",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Ürünü Bağla",
      "TOOLTIP_TEXT_QUANTITY":"miktar",
      "TOUR_POPUP_CONTENT":"<b> Yerleşik hoş geldiniz! </b> </br> Uygulamadaki özelliklerin kısa bir turuna göz atın.",
      "TOUR_POPUP_DISMISS_BUTTON":"Reddet",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Bir tur at",
      "TOUR_POPUP_TITLE":"Hoşgeldiniz",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Aksiyon",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Miktar",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Kategori",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"İndirim",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"miktar",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Faturalandırma Türü",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Kategori",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Tüketim türleri",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Ürün adı",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Sağlayıcı",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Satış Tipi",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Talimatlar",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Lütfen geçerli bir sayı girin",
      "UNIQUE_NAME_ERROR":"Bu ad zaten var, lütfen farklı bir şey seçin.",
      "UPDATE_CART_LINE_ITEM":"Güncellenmiş Sepet Öğesi",
      "UPDATE_CART_LINE_ITEM_FAILURE":"Alışveriş sepeti öğesi güncellenemedi",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Müşteri genel yapılandırmaları başarıyla güncellendi",
      "UPDATE_DEFAULT_VALUE":"Varsayılan olarak işaretle",
      "UPDATE_INVOICE_PONUMBER":"PO #",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"İş ortağı ayarları güncellenirken hata oluştu",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları güncellenirken hata oluştu",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ederken, ürünlerin güncellenmesine izin verilmez. Lütfen daha sonra tekrar deneyin.",
      "UPDATE_PROFILE_FAILURE":"Profil güncellenemedi",
      "UPDATE_RESELLER_CONFIGURATION":"Bayi Yapılandırmasını Güncelle",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Bayi Yapılandırması güncellenemedi",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Bayi Varsayılan Ödeme Seçeneğini Güncelle",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Bayi Varsayılan Ödeme Seçeneği Güncelleme Hatası",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Güncellenmiş Bayi Ödeme Profili",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Satıcı Ödeme Profili güncellenemedi",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Bayi Planı Ürün Fiyatı Güncellendi",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Bayi planı ürün fiyatı güncellenemedi",
      "UPDATE_RESELLER_PROFILE":"Bayi Profilini Güncelle",
      "UPDATE_RESELLER_PROFILE_FAILURE":"Bayi Profili Güncelleme Başarısız",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Sağlayıcı müşterisinin güncellenmiş varsayılan değeri",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Sağlayıcı müşterisinin varsayılan değeri güncellenemedi",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Faturaları QuickBooks Online Toplu Kimliğine Yükleme",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Faturaları Quickbooks'a yükle hata mesajı",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Faturaları Xero Batch Id'ye yükleyin",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Faturaları Xero hata mesajına yükleme",
      "UPLOAD_TO_PSA_SELECT_ALL":"Hepsini seç",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Kullanım Raporu Alma",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Azure Abonelik Kimliği",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Abonelik Adi",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Müşteriye Fatura",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Ödeme Bitiş Tarihi",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Şarj Başlangıç ​​Tarihi",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"İş Ortağı Maliyeti (Vergi Sonrası)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"İş Ortağı Maliyeti (Vergi Öncesi)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"müşteri adı",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Kullanım Raporunu İndir",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Kiracı adı",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Kullanım Raporu",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Tehlike Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Hata Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Grup ismi",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Grup Seç",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Bilgi Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Müşteriden Gizle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Aylık bütçe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Uyarı Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Abonelik Kullanıcılarına Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonelik Kullanıcısını Yönet",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"E-posta",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"rol",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"iştirakçi",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Sahip",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Okuyucu",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"İhracat Kullanım Ayrıntıları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Faturalandırma Özeti",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Kullanım Detayları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Harcama",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Tüketilen Birimler",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Kaynak tipi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adı",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"İçin Azure Abonelikleri",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Tüm Abonelikler",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Fatura donemi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"grup",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Kullanıcılar",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Aksiyon",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Tahmini maliyeti",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"grup",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Eşik",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Aboneliğe Kullanıcı Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonelik kullanıcıları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"E-posta",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"ad",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"rol",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Kullanıcıyı sil",
      "USER_CREATED_POPUP":"Kullanıcı Oluşturuldu",
      "USER_CREATION_SUCCESS_MESSAGE":"{{UserName}} kullanıcı başarıyla oluşturuldu",
      "USER_DETAILS_NOT_FOUND_BODY":"Giriş yapan kullanıcı ayrıntıları bulunamadı. Lütfen yöneticiye başvurun!",
      "USER_DETAILS_NOT_FOUND_HEADER":"Ayrıntılar bulunamadı",
      "USER_HAS_BEEN_CREATED":"Kullanıcı aşağıdaki ayrıntılarla oluşturuldu",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"Kullanıcı Lisans Ataması BatchId",
      "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Kullanıcı Lisans Ataması Toplu Durumu",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Bitti",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Lisans Atama Durumu",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"müşteri adı",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Kullanıcılar",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Kiracı adı",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Kiracı Kimliği",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Ayriyeten",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Paketi",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Bağlantılı",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Kullanıcı Kaldırma Durumu",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Kullanılmayan koltukları serbest bırakın",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Eklentilere değişiklik uygulama",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Ürün Kullanıcıları",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Koltuklar bırakılırken hata oluştu. Lütfen desteğe başvurun.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"En az bir aktif lisans olmalıdır. Artık kullanmak istemiyorsanız lütfen ürünü askıya alın.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Koltukların serbest bırakılması talebi işleniyor.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Başarıyla yüklendi",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"Lisans ekleme talebi kabul edilir ve işlenir.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"Lisansların toplu tahsisi talebi kabul edilir ve işlenir.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"atanan",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Toplam Koltuk Sayısı",
      "USER_LISTING_FILTER_LABEL":"Email kimliği",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Adı, Soyadı, E-posta Kimliği ile Arama",
      "USER_LISTING_SEARCH_BUTTON":"Arama",
      "USER_REMOVED":"Kullanıcı başarıyla kaldırıldı",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Rol ismi",
      "USER_ROLE_LIST_LABEL_SCOPE":"Kapsam Adı",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Kullanıcı adı",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Kullanıcı başarıyla eklendi",
      "USERCONTROLLER_USERUPDATESUCCESS":"Kullanıcı bilgileri başarıyla güncellendi",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Kullanıcının erişimi iptal edildi",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ekle",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Düzenle",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Altında Kullanıcı",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"mevcut değil",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"gecerli",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Kullanıcı bilgisi",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Sırasıyla lisans atama / bırakma tekliflerinin yanındaki onay kutularını işaretleyin / işaretlerini kaldırın",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Bir teklife karşı yeterli ücretsiz lisans yoksa, atama yapılmadan önce bir satın alma işlemi yapılır",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"C3 tarafından bir abonelik sağlandığını gösterir. Lütfen işlem tamamlanana kadar bekleyin.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Ekran adı",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"E-posta",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"İsim",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Talimatlar",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Soyadı",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Kullanım Yeri",
      "USERS_ADD_EDIT_FORM_SEATS":"Koltuklar",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Kullanıcılara Geri Dön",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Ekle",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Düzenle",
      "USERS_ADD_OR_EDIT_CAPTION_3":"kullanıcı",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"E-posta",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"İsim",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Soyadı",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Kullanıcı oluşturmak için son toplu yükleme tamamlandı!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Harici kullanıcılara müşteri yöneticisi ayrıcalığı verilemez",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION":"Müşteriye yönetici ayrıcalığı vermek istediğinizden emin misiniz?",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Kullanıcılar",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Kullanıcı oluşturma isteğiniz kabul edildi. Güncellemeler için lütfen bir süre sonra bu sayfaya geri dönün. İşlemin tamamlanması genellikle birkaç dakika sürer.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Hata ayrıntıları dışa aktarılırken hata oluştu",
      "USERS_BULK_CREATION_FIX_ERRORS":"Lütfen indirilen dosyada bildirilen hataları düzeltin ve tekrar deneyin.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Kullanıcı oluşturma isteğiniz başarıyla tamamlandı",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Yüklenen kullanıcıların ayrıntılarını alın",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"{{TotalUnits}} dışında kalan {{AvailableUnits}}",
      "USERS_BUY_MORE":"Daha fazla satın al",
      "USERS_CREATE_SUCCESS":"{{Username}} kullanıcı başarıyla oluşturuldu",
      "USERS_DELETE_CONFIRM":"Atanan tüm lisanslar kaldırılacak ve {{userName}} silinecek. Devam etmek istiyor musun?",
      "USERS_DELETE_EXISTENCE_CONFIRM":"Bu kullanıcının C3'te silinecek bir erişimi var. Devam etmek istiyor musun?",
      "USERS_DELETE_SUCCESS":"{{Username}} adlı kullanıcının silinmesi başarılı oldu",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Veri bulunamadı. Lütfen daha sonra tekrar deneyin",
      "USERS_GRANT_ACCESS_CONFIRM":"{{Username}} için müşteriye yönetici ayrıcalığı vermek istediğinizden emin misiniz?",
      "USERS_GRANT_ACCESS_SUCCESS":"{{Username}} kullanıcısına başarıyla erişim verildi",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Harici kullanıcılara erişim izni verilmiyor",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"Devam etmek istediğinizden emin misiniz?",
      "USERS_LICENSE_FREE_OUT_OF":"ücretsiz lisanslar",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"{{Username}} kullanıcısı için lisans atama üzerinde çalışıyor. Bu işlemin tamamlanması birkaç dakika sürebilir.",
      "USERS_LICENSES_REVOKE_SUCCESS":"{{Username}} alan adından alınan lisanslar başarıyla iptal edildi",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"{{Username}} alan adından alınan lisanslar başarıyla iptal edildi. Yeni lisans atama üzerinde çalışıyor. Bu işlemin tamamlanması birkaç dakika sürecektir.",
      "USERS_LICENSES_UPDATE_SUCCESS":"{{Username}} kullanıcısı için lisanslar başarıyla güncellendi",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Yeni Kullanıcı Ekle",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Çoklu Kullanıcı Yükleme",
      "USERS_LIST_CAPTION_TEXT_USERS":"Kullanıcılar",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Kullanıcı Ayrıntılarını Düzenle",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Portala erişim izni verme",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Şifreyi yenile",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Lisansları Görüntüle",
      "USERS_LIST_TABLE_HEADER_ACTION":"Aksiyon",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Email kimliği",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Aktif",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Hiçbir Kullanıcı rolü atanmadı.",
      "USERS_LIST_TABLE_HEADER_ROLE":"CSP Rolleri",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Kullanıcı adı",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Rolleri Göster",
      "USERS_PASSWORD_RESET_SUCCESS":"{{Username}} şifresi başarıyla sıfırlandı",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Şifre belirtilen alıcılara başarıyla gönderildi",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Şifre sıfırlama başarılı",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"Şifre sıfırlama başarılı, Şifre: <a ngclipboard='' data-clipboard-text='{{Password {} 'ng-click='vm.confirmCopy()'> <b> {{Şifre}} </ b > </a> <br/> <br/> <b> Bu sayfadan ayrıldıktan sonra, bu geçici şifreyi tekrar görüntüleyemezsiniz. </b> <br/> <br/> Göndermek istiyorsanız E-posta yoluyla şifre lütfen aşağıdaki metin kutusuna virgülle ayrılmış alıcılar sağlayın, aksi takdirde iptal düğmesine basın. <br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"{{UserRoleDescription | translate}} {{userName}} alanından ayrıcalık",
      "USERS_REVOKE_ACCESS_SUCCESS":"{{Username}} alan adından erişim iptal edildi",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Yüklenen kullanıcıların ayrıntılarını alın",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Yeni bir yükleme deneyin!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Hataları Görüntüleme ve Düzeltme",
      "USERS_STATUS_CAPTION_STATUS":"durum",
      "USERS_STATUS_WARNING_MESSAGE":"Son yüklemeden kullanıcıların oluşturulması hala devam ediyor. Lütfen Kullanıcılar sayfasındaki 'BulkUserUpload' düğmesini tıklayarak tekrar kontrol edin.",
      "USERS_TABLE_HEADER_EMAIL":"E-posta",
      "USERS_TABLE_HEADER_NAME":"ad",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "USERS_UPDATE_LICENCES_QUEUED":"Lisanslar güncelleme için sıraya alındı",
      "USERS_UPDATE_SUCCESS":"{{Username}} için kullanıcı bilgileri başarıyla güncellendi",
      "USERS_UPDATELICENCES_SUCCESS":"Lisanslar başarıyla güncellendi",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"{{UserName}} kullanıcı ayrıntısı başarıyla güncellendi",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Yükleme",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"Yüklenen verilerde tespit edilen hatalar. Hataları indirin, düzeltin ve tekrar yüklemeyi deneyin.",
      "USERS_UPLOAD_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Kullanıcı bilgisi",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"Talimatlar",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Birden çok kullanıcı için hesap verilerini .csv biçiminde yükleyin. Referans için örnek bir .csv <strong> <a ng-click='vm.DownloadTemplate()'> Örnek bir .csv </a> </strong> dosyası indirin.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Bu dosya, bir müşteri için birden çok kullanıcı hesabı oluşturmak için kullanılabilir. Bu dosyadaki mevcut metin veya verileri güncellemeyin veya silmeyin. Kullanıcı hesapları Kullanıcı (yönetici değil) izinleriyle oluşturulacak",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Ad, sıfır ila 50 karakteri destekleyen isteğe bağlı bir alandır. Bir örnek: Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Soyadı, sıfır ila 50 karakteri destekleyen isteğe bağlı bir alandır. Bir örnek: Yeşil.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Görünen ad, bir ila 50 karakteri destekleyen zorunlu bir alandır. Bir örnek: Chris Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"E-posta zorunlu bir alandır. Her kullanıcı kaydı için bu alana geçerli bir e-posta adresi girin. Bir örnek: chris@contoso.com.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Durum güncelleme alanı, doğrulama durumunu iletmek için otomatik olarak doldurulur. Bu alanı boş bırakın.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Dosyayı yükleyin ve tıklayın <strong class = \"text-uppercase\"> Sunmak </strong>",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Dosya yükleme",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Kullanım Yeri",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Lisanslara Geri Dön",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Kullanıcılara dön",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Lisansları Düzenle",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Atanan Lisanslar",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Lisans Atanma Tarihi",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Ürün",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"özet",
      "VALIDATE_PRODUCT_QUANTITY":"{{product}} miktar {{quantity}}, {{minQuantity}} ile {{maxQuantity}} arasında olmalıdır",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Zaten müşteri bu kiracıya bağlandı.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Bu kupon kodu zaten var",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Kupon Kodunda sadece alfa sayısallarına izin verilir!",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Bu '{{emailAddress}}' e-postası zaten kullanılıyor.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Lütfen geçerli bir sayı girin",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Lütfen geçerli bir URL girin veya '/ # / contact /' olarak varsayılan URL'yi girin",
      "VALIDATION_MESSAGE_FOR_URL":"Lütfen geçerli bir URL girin.",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Lütfen geçerli bir URL girin veya 'home.support' olarak varsayılan URL'yi girin",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Lütfen geçerli bir Posta kodu girin",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Bu alan en fazla 50 karakter kabul eder.",
      "VALIDATION_MESSAGE_MINIMUM_0":"Minimum değer 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"Minimum değer 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"lütfen geçerli eposta adresini giriniz",
      "VALIDATION_MESSAGE_REQUIRED":"Bu alan gereklidir",
      "VALIDITY_TYPE_DESC_DAYS":"Gün (ler)",
      "VALIDITY_TYPE_DESC_MONTHS":"Ay (lar)",
      "VALIDITY_TYPE_DESC_YEARS":"Yıl (lar)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Geçerlilik türü",
      "VALIDITY_TYPE_NAME_DAYS":"Gün (ler)",
      "VALIDITY_TYPE_NAME_MONTHS":"Ay (lar)",
      "VALIDITY_TYPE_NAME_YEARS":"Yıl (lar)",
      "VALUE_SHOULD_BE_GREATER_THAN":"Değer 0'dan büyük olmalıdır",
      "VE":"ve",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Jetonu Doğrula",
      "VIEW_ALL_SELECTION":"Hepsini seç",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Kullanım Satır Öğelerini Görüntüle",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Kullanım Satır Öğelerini Görüntüle",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Fatura Oluştur",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Kârlılık Raporunu Yeniden Yükle",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Kullanıcı Ekle",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Kullanıcıyı sil",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"ad",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Etiket Ata",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rolleri Düzenle",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Ortak Kullanıcı Ekle",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Etiket Ata",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Ortağı Sil",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rolleri Düzenle",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Ortak Kullanıcı Yönetimi",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Roller",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"Roller",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "WEBHHOOK_LABEL_EVENT":"Etkinlik",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "WEBHOOK_CANCEL_NOTIFICATION":"İptal etmek",
      "WEBHOOK_END_DATE_PLACEHOLER":"Bitiş tarihi",
      "WEBHOOK_HEADER_ACTIONS":"Aksiyon",
      "WEBHOOK_HEADER_DESCRIPTION":"Açıklama",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "WEBHOOK_HEADER_EVENTNAME":"Etkinlik",
      "WEBHOOK_HEADER_TITLE":"Başlık",
      "WEBHOOK_LABEL_DESCRIPTION":"Açıklama",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Etkili",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Kategori",
      "WEBHOOK_LABEL_EVENT_TEXT":"Etkinlik",
      "WEBHOOK_LABEL_TITLE_TEXT":"Başlık",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Açıklama",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Ürün adı",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Başlık",
      "WEBHOOK_SAVE_NOTIFICATION":"Sunmak",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"seçmek",
      "WEBHOOK_START_DATE_PLACEHOLER":"Başlangıç ​​tarihi",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "WELCOME_HEADER_1_PART_1":"Bizimle sonsuz olasılıklarınız var.",
      "WELCOME_HEADER_1_PART_2":"Ve şimdi başlıyor.",
      "WELCOME_PORTAL_FEATUR_1":"Self Servis Portalı",
      "WELCOME_PORTAL_FEATUR_2":"Abonelikleri hızlı ve kolay bir şekilde ekleyin / kaldırın",
      "WELCOME_PORTAL_FEATUR_3":"Otomatik ödemeler",
      "WELCOME_PORTAL_FEATUR_4":"Faturaları otomatik olarak ödeme",
      "WELCOME_PORTAL_FEATUR_5":"Güvenli",
      "WELCOME_PORTAL_FEATUR_6":"Microsoft Azure Active Directory ve Çok Faktörlü Kimlik Doğrulama ile güvence altına alındı",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Oturum aç",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Hoşgeldiniz",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Çıkış Yap",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"İletişim",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Gösterge Tablosuna Git",
      "Won''t Fix":"Düzeltilmeyecek",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Xero'ya rıza gösterdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek Xero'ya erişecek şekilde yapılandırılabilir. Müşterilerinizin faturalarını Xero'ya gönderebilirsiniz.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Faturaları Xero'ya Yükleme talimatlarını uygulayın.",
      "XERO_CONSENT_DESCRIPTION":"Faturaları Xero'ya kaydetmek için onayınızı vermeniz gerekir.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Lütfen Xero onayınızı işleme koyarken bekleyin",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Aşağıdaki bağlantı yeni bir sekmede açılır. Lütfen Xero kimlik bilgilerinizi onaylayın. Onay verirken bir hatayla karşılaşırsanız, lütfen destek ekibimizle iletişime geçin.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"Xero onayı vermek",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Onayı önceden verdiyseniz, Xero ile bağlantıyı doğrulamak için lütfen <b> Jetonu Doğrula </b> düğmesini tıklayın.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Mevcut Xero Yenileme Jetonunun geçerliliğini doğrulamak için bu düğmeyi tıklayın.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"İş Ortağı'nın müşteri faturalarını <b> XERO </b> 'ya kaydetmesi için önkoşullar",
      "XERO_POST_BATCH_ID":"Xero Post Toplu İş Kimliği",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Xero'ya fatura gönderme talebiniz kabul edilir ve işleme koyulur. Devam eden güncellemeler için lütfen <strong> Yazı Günlüklerini Görüntüle </strong> düğmesini tıklayın.",
      "XERO_POST_LOGS_STATUS":"Xero Post Günlükleri Durumu",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Mevcut Xero Yenileme Simgesi geçersiz. Lütfen rızanızı tekrar sağlayın",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Mevcut Xero Yenileme Simgesi geçerlidir. Faturaları yüklemeye devam edebilirsiniz",
      "XERO_TENANT_ID":"Sıfır Kiracı Kimliği",
      "YA FATURA EDİLECEK":"Ya fatura edilecek",
      "Year(s)":"Yıl (lar)",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Bu işlemi gerçekleştirme izniniz yok",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Abonelik oluşturmak için bir veya daha fazla teklif seçmelisiniz"
    },  
      "ACCEPT_COOKIES_BUTTON_TEXT":"tamam",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"yeni güvenlik ayarlarını kullanarak Kitap Ortağı Merkezi'ne erişim.",
      "ACCESS_TOKEN":"Erişim Simgesi",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"E",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Telefon numarası",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Sıfırla",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Arama",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"E-posta",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Telefon numarası",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"E",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"İsim",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Soyadı",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Telefon numarası",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Hesap numarası",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"hesap tipi",
      "ACH_FORM_LABEL_BANK_NAME":"Banka adı",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"İşletme Hesabı",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "ACH_FORM_LABEL_NO":"Hayır",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Yönlendirme numarası",
      "ACH_FORM_LABEL_YES":"Evet",
      "Açık":"Açık",
      "ACTIONS_TO_FIX":"Lütfen destek için yöneticiye başvurun",
      "ACTIONS_TO_FIX_500":"Sorunu çözmek için çalışıyoruz, lütfen bir süre sonra tekrar kontrol edin",
      "ACTIVATE_CONFORMATION":"Etkinleştirmek istediğinden emin misin",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Müşteri Seçin",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Tüm Müşteriler",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Bildirimler",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Bitiş tarihi",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Aksiyon",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Etkinlik adı",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"durum",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"konu",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Etkinlik",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Başlangıç ​​tarihi",
      "ACTIVITY_LOGS_TEXT_FAILED":"Başarısız oldu",
      "ACTIVITY_LOGS_TEXT_STATUS":"durum",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"başarı",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Bildirimler",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Ayarlama Ekle",
      "ADD_CUSTOM_ROLE":"özel rol ekle",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Müşteri en son kabul etti",
      "ADD_CUSTOMER_IN_QUOTE":"Müşteri Ekle",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"ülke",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Müşteri Ekle",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"İptal etmek",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Sunmak",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Tamamlayınız",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Birincil kişiye erişim izni verilsin mi (Müşteri Yöneticisi olarak)?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Uygulamaya erişmek için davetiye gönderilsin mi?",
      "ADD_NEW_USER_IN_QUOTE":"Yeni Kullanıcı Ekle",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"seçmek",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Plan Temel Ayrıntıları Ekle",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Fatura Para Birimi",
      "ADD_PLAN_LABEL_TEXT_NAME":"ad",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Satın Alma Para Birimi",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Sözleşme Ayrıntılarını Görüntüle",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Aksiyon",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Makro",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"tamam",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Döşeme kırıcı",
      "ADD_TO_CART_INFO":"Bu ürün, <b> {{LinkedProductName}} </b> ile bağlantılıdır. Bu ürünü eklerseniz, bağlantı ayrıca",
      "addCustomer":"Müşteri Ekle",
      "ADDONS_LEBEL_TEXT":"Eklentileri",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ADDRESS_TABLE_HEADER_CITY":"Kent",
      "ADDRESS_TABLE_HEADER_COUNTRY":"ülke",
      "ADDRESS_TABLE_HEADER_STATE":"Durum",
      "ADDRESS_TABLE_HEADER_ZIP":"Zip",
      "ADDRESS_TYPE_BILLING":"fatura",
      "ADDRESS_TYPE_COMPANY":"şirket",
      "ADDRESS_TYPE_INVOICE":"Fatura",
      "ADI":"Kuruluşun adı",
      "AĞUSTOS":"Ağustos",
      "ALAN GEREKLİ":"{{atribute}} gerekli",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Panoya kopyalandı",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"Fatura başarıyla onaylandı.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"Fatura başarıyla sonuçlandırıldı.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Lütfen en az bir fatura seçin",
      "ALICI":"Alıcı",
      "ALL_COMMENTS":"Yorumlar",
      "ALL_PRODUCT_COMMENTS":"Ürün",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Lütfen lisansların kaldırılması için yöneticinize başvurun.",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Azure Faturalandırma Geçmişi",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Azure Faturalandırma Özeti",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Müşteri Azure Kullanımı",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Müşterilerin Geliri",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Müşterilerin Abonelikleri",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Planlara Göre Abonelikler",
      "APPCONFIG_AUTOTASK_PASSWORD":"Parola",
      "APPCONFIG_AUTOTASK_USERNAME":"Kullanıcı adı",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Fatura görünümünde ve fatura pdf'inde görünen 1 adresi.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Fatura görünümünde ve fatura pdf'inde görünen adres 2.",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Lisans İzlemeyi Etkinleştir",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Analytics'i göster",
      "APPCONFIG_DESC_APPLICATION_NAME":"Uygulama Adı",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"İş Ortağı Merkezi'ndeki iş ortağı için faturalandırma döngüsünün sonu",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"Azure CSP Teklif Kimliği",
      "APPCONFIG_DESC_BILLING_CYCLE":"İş Ortağı Merkezi veya Özel ile uyumlu",
      "APPCONFIG_DESC_BILLING_DATE":"Fatura Döngüsü Özel olduğunda uygulanabilir",
      "APPCONFIG_DESC_BILLING_PERIOD":"Faturalandırma Süresi (diğer ayarlar kullanılarak belirlenir)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"C3 Portalı için Faturalandırma Sağlayıcısı",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Faturalandırma Sağlayıcısı Yapılandırması",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Faturalandırma döngüsünün başlangıcı. Fatura Döngüsü için uygulanabilir Özel",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Bölümden faturada fatura görünümünde ve fatura pdf'sinde görünür",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Bölümden faturada fatura görünümünde ve fatura pdf'sinde görünür",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"0365'e Karşı Rolleri Kontrol Et",
      "APPCONFIG_DESC_CITY":"Fatura görünümü ve fatura pdf'inde görünen şehir.",
      "APPCONFIG_DESC_COMPANY_NAME":"Fatura görünümünde ve fatura pdf'inde görünen Şirket Adı.",
      "APPCONFIG_DESC_COMPANY_URL":"Web sitenizin URL'si",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Açılış sayfasında görünmesi gereken ad ve kullanıcı giriş yaptıktan sonra altbilgi",
      "APPCONFIG_DESC_CONTACT_EMAIL":"İletişim E-postası (genellikle bilgileriniz veya destek e-postanız)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"C3 başvurunuz için ilgili kişinin adı",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"C3 başvurunuz için irtibat kişisinin soyadı",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"İlgili kişinin telefon numarası",
      "APPCONFIG_DESC_COUNTRY":"Fatura görünümünde ve fatura pdf'inde görünen ülke.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Ülke kodu",
      "APPCONFIG_DESC_CURRENCY_CODE":"Para Birimi Kodu",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"Müşteri Onayı URL'si",
      "APPCONFIG_DESC_DATE_FORMAT":"Tarih formatı",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Tarih Saat Biçimi",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"Özelleştirilmiş bir açılış sayfanız olmasını istiyorsanız, sayfanın hedef URL'si. Not: Açılış sayfasında bir düğmeniz olmalı ve bu düğmenin hedef URL'si <C3Site URL'si> / # / login olmalıdır.",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Web sitesi için varsayılan dil",
      "APPCONFIG_DESC_EMAIL_LOGO":"C3 tarafından gönderilen e-postalarda görünen logo. Tercih edilen boyut 300x100'dür.",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Fatura görünümünde ve fatura pdf'sinde altbilgi onaylı satırı olarak görünür",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Fatura görünümünde ve fatura pdf'sinde altbilgi satırı olarak görünür",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Fatura Oluşturma Günleri",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Bize Ulaşın Bağlantı sayfası (eğer yoksa / / # / contact yazın)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Müşteriye SSS Bağlantısı (varsayılanı kullanmak istiyorsanız boş bırakın)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Portal Bağlantısı",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Destek Bağlantısı sayfası (eğer yoksa, home.support yazın)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Kullanıcılar C3'te oturum açtıktan sonra sol üstte görünen logo. Tercih edilen boyut 170x40'tır. Sayfanın siyah arka planı vardır, bu nedenle grafik Siyah ile kontrast teşkil edecek bir renkte olmalıdır.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Portal'a Giriş",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Excel'e dışa aktarma için maksimum giriş sayısı",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft güvenlik onayı günler içinde hatırlatıyor",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Test Ödemeleri için Minimum Ücret Tutarı",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Faturalandırma’da zaten mevcut olan müşteriyi arama işlevini etkinleştirir",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"İş ortağı merkezinizdeki yerel bir uygulamadan Uygulama Kimliği. Bunu Uygulama Yönetimi -> Yerel Uygulama aracılığıyla alabilirsiniz",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"İş Ortağı Para Birimi",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Yönetici Aracısı Parolası",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"İş Ortağı Tercih Dilleri",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"Ortak Kiracı Kimliği",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Ajan Yöneticisi rolü atanan hesabın kullanıcı adı",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"Microsoft bulut satıcı sözleşmesi URL'si",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"İş ortağı şartlar ve koşulları URL'si",
      "APPCONFIG_DESC_PBI_GROUP_ID":"Power BI Grup Kimliği",
      "APPCONFIG_DESC_PBI_PASSWORD":"Power BI Parolası",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Power BI Rapor Kimliği",
      "APPCONFIG_DESC_PBI_USER_NAME":"Power BI Kullanıcı Adı",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"Power BI İstemci Kimliği",
      "APPCONFIG_DESC_POSTAL_CODE":"Fatura görünümü ve fatura pdf'inde görünen posta kodu.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Açılış sayfasında görünmesi gereken ad ve kullanıcı giriş yaptıktan sonra altbilgi",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"Web sitenizin URL'si",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Mutabakat raporu görünümünde Microsoft logosunun sağ tarafında görünen logo. Tercih edilen boyut 170x40'tır. Sayfanın beyaz bir arka planı vardır, bu nedenle metin veya grafik Beyaz ile kontrast teşkil eden herhangi bir renkte olmalıdır.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Alıcıları virgülle ayrılmış değerler olarak ekleyin",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Müşteriyi Bildirmeli",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Tehlike eşiği karşılandığında aboneliği askıya alma",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Power BI Rapor Menüsünü Göster",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Sonraki Adımı Göster",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP SSL'yi etkinleştir",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"E-postadan SMTP",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"Görünen Addan",
      "APPCONFIG_DESC_SMTP_HOST":"SMTP Ana Bilgisayarı",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Parola",
      "APPCONFIG_DESC_SMTP_PORT":"Liman",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"SMTP Konu Öneki",
      "APPCONFIG_DESC_SMTP_USERNAME":"SMTP Kullanıcı Adı",
      "APPCONFIG_DESC_STATE":"Fatura görünümünde ve fatura pdf'inde görünen durum.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DESC_WELCOME_LOGO":"C3 uygulamanızın açılış sayfasında görünmesi gereken logo. Tercih edilen boyut 186x32'dir. Sayfanın beyaz bir arka planı vardır, bu nedenle metin veya grafik Beyaz ile kontrast teşkil eden herhangi bir renkte olmalıdır.",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Hesap numarası",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Adres 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Adres 2",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Lisans İzlemeyi Etkinleştir",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Analytics'i göster",
      "APPCONFIG_DISP_APPLICATION_NAME":"Uygulama Adı",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Microsoft fatura oluşturma tarihi",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"Azure CSP Teklif Kimliği",
      "APPCONFIG_DISP_BILLING_CYCLE":"Fatura döngüsü",
      "APPCONFIG_DISP_BILLING_DATE":"C3'te Faturaların oluşturulduğu tarih",
      "APPCONFIG_DISP_BILLING_PERIOD":"Fatura donemi",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Faturalandırma Sağlayıcısı",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Faturalandırma Sağlayıcısı Yapılandırması",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Faturalandırma Başlangıç ​​Günü",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"İş numarası",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"İşletme Numarası Anahtarı",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"0365'e Karşı Rolleri Kontrol Et",
      "APPCONFIG_DISP_CITY":"Kent",
      "APPCONFIG_DISP_COMPANY_NAME":"Şirket Adı",
      "APPCONFIG_DISP_COMPANY_URL":"şirket url'si",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Şirket Adı",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"İletişim Firma Adı",
      "APPCONFIG_DISP_CONTACT_EMAIL":"İletişim E-posta",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"İletişim Adı",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"İletişim Soyadı",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"İletişim Telefon Numarası",
      "APPCONFIG_DISP_COUNTRY":"ülke",
      "APPCONFIG_DISP_COUNTRY_CODE":"Ülke kodu",
      "APPCONFIG_DISP_CURRENCY_CODE":"Para Birimi Kodu",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"Müşteri Onayı URL'si",
      "APPCONFIG_DISP_DATE_FORMAT":"Tarih formatı",
      "APPCONFIG_DISP_DATE_TIME_FORMAT":"Tarih Saat Biçimi",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"Özelleştirilmiş Açılış Sayfası URL'si",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Varsayılan dil",
      "APPCONFIG_DISP_EMAIL_LOGO":"E-posta Logosu",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Fatura Altbilgisi Yetkili Hattı",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Fatura Altbilgisi Satırı",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Fatura Oluşturma Günleri",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Bize Bağlanın sayfası",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Müşteriye Bağlantı SSS",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Portal Bağlantısı",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Destek Bağlantısı",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Giriş Logo",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Portal'a Giriş",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"İhracat İçin Maksimum Giriş",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft güvenlik onayı günler içinde hatırlatıyor",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Minimum Şarj Tutarı",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Faturalamayla Dahili Müşteri Arama",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Yerel Uygulama Kimliği",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"İş Ortağı Para Birimi",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Yönetici Aracısı Parolası",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Dil Paketleri",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"Ortak Kiracı Kimliği",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Yönetici Aracısı Kullanıcı Adı",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"Microsoft Bulut Satıcı Sözleşmesi URL'si",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"İş Ortağı Şartlar ve Koşulları URL'si",
      "APPCONFIG_DISP_PBI_GROUP_ID":"Power BI Grup Kimliği",
      "APPCONFIG_DISP_PBI_PASSWORD":"Power BI Parolası",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Power BI Rapor Kimliği",
      "APPCONFIG_DISP_PBI_USER_NAME":"Power BI Kullanıcı Adı",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"Power BI İstemci Kimliği",
      "APPCONFIG_DISP_POSTAL_CODE":"Zip",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Şirket Adına Göre",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Powered by Şirket URL'si",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Mutabakat Raporu Logosu",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Mutabakat Raporu Alıcıya Bildir",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Tehlike eşiği karşılandığında aboneliği askıya alma",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Power BI Rapor Menüsünü Göster",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Sonraki Adımı Göster",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"SSL'yi etkinleştir",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"E-postadan",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"Görünen Addan",
      "APPCONFIG_DISP_SMTP_HOST":"evsahibi",
      "APPCONFIG_DISP_SMTP_PASSWORD":"SMTP Parolası",
      "APPCONFIG_DISP_SMTP_PORT":"SMTP Bağlantı Noktası",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Konu Öneki",
      "APPCONFIG_DISP_SMTP_USERNAME":"Kullanıcı adı",
      "APPCONFIG_DISP_STATE":"Durum",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Vergi Türü",
      "APPCONFIG_DISP_WELCOME_LOGO":"Hoşgeldiniz Logo",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"E-postadan SMTP",
      "ARALIK":"Aralık",
      "ASSIGN_BUNDLE_PRODUCTS":"Paket Ürünleri Atama",
      "ASSIGN_CUSTOMER_PLAN":"Müşteri Planı Atama",
      "ASSIGN_USAGE_PRODUCTS":"Kullanım Ürünleri Atama",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Kapat",
      "AUDIT_LOG_DETAIL":"ayrıntılar",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Gizli",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Denetim Günlüğü",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Arama",
      "AUDITLOG_TEXT_ACTION_NAME":"Aksiyon",
      "AUDITLOG_TEXT_AUDITLOG":"Denetim Günlüğü",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"müşteri adı",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"İtibaren geçerli",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Etkili",
      "AUDITLOG_TEXT_FROM":"itibaren",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Müşteri Alan Adı",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Aksiyon",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Etkilenen Müşteri",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"tarih",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"öncü",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Detayları göster",
      "AUDITLOG_TEXT_TO":"Etkili",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodu",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan Kimlik Doğrulama Kodu",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"Ödeme başarıyla başlatıldı. İşleme biraz zaman alacaktır. Lütfen bir süre sonra durumu kontrol edin.",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Kategori",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Açıklama",
      "AZURE_BILLING_CYCLE":"Fatura döngüsü",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Fatura donemi",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Yetkilendirme Kimliği",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Yetkilendirme Adı",
      "AZURE_ESTIMATE_REPORT":"Azure Tahmin Raporu",
      "AZURE_ESTIMATE_TAGS":"Etiketler",
      "AZURE_ESTIMATED_COST":"Tahmini maliyeti",
      "AZURE_ESTIMATES":"Azure Tahminleri",
      "AZURE_GROUP_DETAILS":"Grup Ayrıntıları",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Azure Grupları",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Gruba Yönetici Ekle",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Grup ekle",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Grup Kullanıcılarına Dön",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Gruplara Dön",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Grubu Düzenle",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Grup Kullanıcıları",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Grup Kullanıcısı Ekle",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Azure Grupları",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"{{Groupname}} kullanıcısı",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"yönetici",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Faturalandırma Erişimi",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Tehlike Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"E-posta",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Hata Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Grup ismi",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Bilgi Eşiği",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Bildirim Sıklığı",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Uyarı Eşiği",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"yönetici",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Faturalandırma Erişimi",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"ad",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Bildirim Sıklığı",
      "AZURE_LOADING":"Azure'dan Veri yüklemeye çalışırken lütfen bekleyin ...",
      "AZURE_LOADING_MESSAGE":"Microsoft Azure'u sorgulamak ve yüklemek bir dakika sürebilir ...",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Talimatlar",
      "AZURE_RECOMMENDATION_ALL_COST":"Maliyet",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Bu alan gereklidir",
      "AZURE_RECOMMENDATION_SUBMIT":"Sunmak",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Kaynak Grubuna Göre Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Hizmete Göre Ayrıntılar",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Ayrıntıları Al",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Azure Raporları",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Faturalandırma Özeti",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"tarih",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"yetki verme",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"grup",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Ay",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Grup Seç",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"abone",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Tüm Hak Sahipleri",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Tüm Gruplar",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Tüm Abonelikler",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Tarih seç",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Atanmayanlar",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Maliyet",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Kaynak Adı",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"hizmet adı",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Denetim",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"ayrıntılar",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Kaynak Grubu",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Kaynak Adı",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"hizmet adı",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Toplam tutar",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Tarih zamanlı",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Etkinlik Kategorisi",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Tarafından Başlatılan Etkinlik",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"İşlem Adı",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"durum",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Denetim",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"ayrıntılar",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Maliyet",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"tarih",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"miktar",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Toplam tutar",
      "AZURE_REPPORT_CURRENCY":"Para birimi",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Raporu almak için bir para birimi seçmelisiniz",
      "AZURE_REPPORT_CUSTOMERS":"Müşteriler",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Müşteri Seçin",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Bir müşteri seçin",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"iştirakçi",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Özel Rol",
      "AZURE_ROLE_NAME_OWNER":"Sahip",
      "AZURE_ROLE_NAME_READER":"Okuyucu",
      "AZURE_SUBSCRIPTION":"Azure Aboneliği",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Düzenle",
      "AZURE_SUBSCRIPTION_DETAILS":"Abonelik Ayrıntıları",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"seçmek",
      "AZURE_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Azure aboneliği Güncelleme Başarısız",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"Azure aboneliği başarıyla güncellendi",
      "AZURE_THRESHOLD_DETAILS":"Eşik Ayrıntıları",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Bu abonelik için aylık bir bütçe belirleyin ve Bilgi, Uyar, Hata ve Tehlike gibi çeşitli önem derecelerindeki eşikleri tanımlayın.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Müşteri Tarafından Yönetilen aboneliklerde, kullanım belirli bir eşiği karşıladığında veya aştığında tüm grup yöneticilerine bildirim gönderilir.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Bildirimler her faturalandırma döngüsünde bir kez gönderilir.",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Azure Kullanımı",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Kullanım Detayları",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Tahmini Fiyat",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"ad",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}}, {{groupname}} Grubuna başarıyla eklendi",
      "AZUREGROUPS_SAVE_SUCCESS":"{{Username}} grubu başarıyla kaydedildi",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Grup kullanıcısı başarıyla eklendi",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Grup başarıyla kaydedildi",
      "BACK_TO_POLICIES":"Özel politikalara dön",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Sağlayıcı kiracılarına geri dön",
      "BAYİ":"(Bayi)",
      "BILL_ACTION_DESC_FULL_CHARGE":"Tüm ay boyunca tam şarj.",
      "BILL_ACTION_DESC_NO_REFUND":"Para iadesi yapılmayacak",
      "BILL_ACTION_DESC_PRORATE":"Oranlama faturada uygulanacaktır.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Tam şarj",
      "BILL_ACTION_NAME_NO_REFUND":"Para iadesi yok",
      "BILL_ACTION_NAME_PRORATE":"Prorate",
      "BILL_CYCLE_DESC_ANNUAL":"Yıllık",
      "BILL_CYCLE_DESC_MONTHLY":"Aylık",
      "BILL_CYCLE_NAME_ANNUAL":"Yıllık",
      "BILL_CYCLE_NAME_MONTHLY":"Aylık",
      "BILL_FROM":"Fatura Gönderen",
      "Billing":"fatura",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ettiği için ürünün faturalandırma döngüsü değişikliğine izin verilmiyor. Lütfen daha sonra tekrar deneyin.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Mevcut Ürün Adı",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Siparişin faturalandırma döngüsü başarıyla değiştirildi",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"seçmek",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Fatura döngüsü",
      "BILLING_PERIOD":"Fatura donemi",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"seçmek",
      "BILLING_TYPE_DESC_MARK_UP":"Biçimlendirme",
      "BILLING_TYPE_DESC_PERCENTAGE":"Yüzde",
      "BILLING_TYPE_DESC_PRICE":"Fiyat",
      "BILLING_TYPE_TOOLTIP":"Faturalandırma Türü",
      "BİR HATA OLUŞTU":"İsteğiniz işlenirken bir hata oluştu",
      "BÖLÜM":"(Bölüm)",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Azure Tahminleri",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Azure Aboneliği",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Lisanslar",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Power BI Raporları",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "BTN_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "BTN_INVOICE_PAYMENTS":"Fatura Ödemeleri",
      "BTN_TEXT_BEGIN_UPLOAD":"Yükleme",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"Panoya kopyala",
      "BTN_TEXT_FILTER":"filtre",
      "BTN_TEXT_FIX":"Fix",
      "BTN_TEXT_IGNORE":"Göz ardı etmek",
      "BTN_TEXT_INCLUDE":"Dahil etmek",
      "BTN_TEXT_OPEN_IN_EMAIL":"E-postada aç",
      "BTN_TEXT_PROCEED":"İlerlemek",
      "BTN_TEXT_REMOVE":"Kaldırmak",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Bayi ilişkisi isteyin",
      "BTN_TEXT_SIGN_IN":"oturum aç",
      "BTN_TEXT_SKIP":"Atla",
      "BTN_TEXT_TAKE_ON":"Dahil etmek",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"İptal etmek",
      "BULK_INVOICE_DOWNLOAD_HEADER":"Tüm faturaları indir",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-postalar",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Tüm faturaları e-posta ile alın",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Kapat",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Sahip",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"durum",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"kullanıcı",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"Sunmak",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Bu kaydı silmek istediğinizden emin misiniz?",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-postalar",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"öncü",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"durum",
      "BULK_ONBOARD_BATCH_ID":"Yerleşik Toplu Toplu İş Kimliği",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"Yerleşik Toplu Toplu İş Kimliği",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Müşterilerin Teknede Toplu Yığın İsteği",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"Toplu Katılım MicrosoftNonCSP müşterileri desteklenmez.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"durum",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Bitti",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Seçilmiş Müşteriler",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"En az bir müşteri seçin / bir plan seçin",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Müşteriyi kaldırmak istiyor musunuz?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"Birden fazla müşteri ekleme isteği kabul edildi",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Bir örnek .csv indirin",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"başvuru için dosya.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Talimatlar",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Hata",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"başarı",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Aşağıdaki aboneliklerin planda eşdeğer teklifler yoktur. Lütfen planı güncelleyin ve tekrar deneyin.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"CSP'de kimliğine sahip bir müşteri bulunamadı: {{CSPCustomerId}}",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Aşağıdaki teklif (ler), C3'te desteklenmeyen tek bir seviyede birden fazla kez satın alınmıştır. Lütfen verileri doğrulayın.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Son güncelleme işleminizde bazı hatalar var gibi görünüyor, lütfen yardım için destek ekibiyle iletişime geçin",
      "bundles":"Paketler",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Lütfen bekle...",
      "BUTTON_ACTIVATE":"etkinleştirme",
      "BUTTON_APPLY_COUPON_TEXT":"kuponu onayla",
      "BUTTON_CLOSE":"Kapat",
      "BUTTON_DEACTIVATE":"Devre dışı bırakmak",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"İptal etmek",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"İndir",
      "BUTTON_LANG_CN":"Çince",
      "BUTTON_LANG_EN":"ingilizce",
      "BUTTON_LANG_ES":"İspanyol",
      "BUTTON_LANG_FR":"Fransızca",
      "BUTTON_LANG_TH":"Tayland",
      "BUTTON_LANG_TW":"Tayvanlı",
      "BUTTON_LANGUAGE":"Dil",
      "BUTTON_MANAGE_PRODUCT":"yönetme",
      "BUTTON_PROCESSING":"İşleme",
      "BUTTON_RELEASE":"Serbest bırakmak",
      "BUTTON_REVOKE":"İptal Et",
      "BUTTON_START_TOUR":"Bir tur at",
      "BUTTON_TEXT_ACCEPT":"Kabul etmek",
      "BUTTON_TEXT_ACTIONS":"Aksiyon",
      "BUTTON_TEXT_ADD":"Ekle",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Yeni Para Birimi Dönüştürme Ekle",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"özel politika ekle",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Yeni Etkinlik E-posta Bildirimi Ekle",
      "BUTTON_TEXT_ADD_SUB_TAX":"Alt Vergi Ekle",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Eklentiler ile ekleyin",
      "BUTTON_TEXT_APPLY":"Uygulamak",
      "BUTTON_TEXT_APPROVE":"onaylamak",
      "BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "BUTTON_TEXT_CANCEL":"İptal etmek",
      "BUTTON_TEXT_CLEAR":"Açık",
      "BUTTON_TEXT_CLOSE":"Kapat",
      "BUTTON_TEXT_DELETE":"Sil",
      "BUTTON_TEXT_DOWNLOAD":"İndir",
      "BUTTON_TEXT_EDIT":"Düzenle",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Excel'e aktar",
      "BUTTON_TEXT_FILTER":"filtre",
      "BUTTON_TEXT_LOAD_OFFERS":"Teklifleri Yükle",
      "BUTTON_TEXT_OK":"tamam",
      "BUTTON_TEXT_OVERRIDE":"Geçersiz Kıl",
      "BUTTON_TEXT_PAY_INVOICES":"Bekleyen Faturaları Öde",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Avans Ödemesini Kaydedin",
      "BUTTON_TEXT_REJECT":"reddetmek",
      "BUTTON_TEXT_REMOVE":"Kaldırmak",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Alt Vergiyi Kaldır",
      "BUTTON_TEXT_REPORTS":"Raporlar",
      "BUTTON_TEXT_RESET":"Sıfırla",
      "BUTTON_TEXT_REVERT":"Geri Al",
      "BUTTON_TEXT_SAVE":"Kayıt etmek",
      "BUTTON_TEXT_SEARCH":"Arama",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"İşletme Varlığını Ayarla",
      "BUTTON_TEXT_SUBMIT":"Sunmak",
      "BUTTON_TEXT_UPDATE":"Güncelleme",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"ONLINE QUICKBOOKS'A YÜKLE",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"XERO'YA YÜKLE",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"POST GÜNLÜKLERİNİ GÖRÜNTÜLE",
      "BUTTON_TEXT_VIEW_OFFERS":"Teklifleri Görüntüle",
      "BUTTON_VIEW_PRODUCT":"Görünüm",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Etiket Ekle",
      "C3_CART":"Araba",
      "CALENDAR_CLOSE_BUTTON":"Kapat",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Bu alan gereklidir",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Eski bir para birimi dönüştürme tanımı silinemiyor",
      "CANNOT_INITIATE_PAYMENT":"Bekleyen bir işlem nedeniyle ödeme başlatılamıyor",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Bayi ilişkisi talebi",
      "CARD_CARD_DELETE_ADDON":"Sil",
      "CART_CARD_COMMENT_ACTION":"Yorumlar",
      "CART_CARD_DELETE_PRODUCT":"Sil",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Seçilen teklifin kiracısını değiştirmek istediğinizden emin misiniz?",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Seçilen teklifte kiracı güncellenirken hata oluştu. Lütfen Destek ekibiyle iletişime geçin",
      "CART_ITEM_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Kiraci",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Sepet ödeme için uygun değil veya zaten teslim alınmış!",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Bağlantılı Ürün",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"İndirim",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Uygulamak",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Tüketim türleri",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong> Merhaba! </strong> Sepetiniz boş veya arama öğesi bulunamadı <a ng-click='vm.GotoCart()' class='alert-link'> Alışveriş yapmak için burayı tıklayın. <span class = 'fa fa-cart-plus'> </span> </a>",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Alışveriş sepeti kontrol edilirken hata oluştu",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Sipariş başarıyla verildi. OrderId: {{orderId}}",
      "CART_PRODUCT_DELETE_CONFIRMATION":"Bu öğeyi alışveriş sepetinden silmek istediğinizden emin misiniz?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Ürün alışveriş sepetinden kaldırılırken hata oluştu!",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"İstenen öğe başarıyla kaldırıldı!",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Miktar başarıyla güncellendi",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Seçilen teklife karşı kiracı başarıyla güncellendi!",
      "CATEGORY_DESC_ALL":"Herşey",
      "CATEGORY_DESC_AZURE":"masmavi",
      "CATEGORY_DESC_AZURE_PLAN":"Azure Planı",
      "CATEGORY_DESC_BUNDLES":"Paketler",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Bu kuponu devre dışı bırakmak istediğinizden emin misiniz?",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Bu kuponu etkinleştirmek istediğinden emin misin?",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Hedef Planı",
      "CHANGE_PLAN_HELP":"Yardım",
      "CHANGE_PLAN_STATUS_FAILED":"Başarısız oldu",
      "CHANGE_PLAN_STATUS_SUCCESS":"başarı",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Kategori",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":"Fiyat",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Sağlayıcı",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"miktar",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Geçerlilik",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Satın Alınan ürün durumu Güncellenirken Hata Oluştu:",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Emin misin, bu teklifi durdurmak istiyor musun",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Emin misiniz, bu planı devre dışı bırakmak istiyor musunuz?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Bu planı etkinleştirmek istediğinden emin misin?",
      "CHOOSE_AN_OPTION":"Bir seçenek belirleyin",
      "CHOOSE_ONE_RG":"En az bir Kaynak Grubu seçin",
      "CHOOSE_RESOURCE_GROUP":"Politika Seçin",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Tıklayın",
      "CLONE_PLAN_FAILURE":"Plan klonlanamadı",
      "CLONE_PLAN_SUCCESS":"Başarıyla klonlanmış plan",
      "COLLAPSE_ALL_PAYMENTS":"Collapse Ödemeler",
      "COMMISION_REPORT_SEARCH":"Arama",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Kapat",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Sıfırla",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Arama",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Alan adı",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Müşteri",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Emin misiniz, bu faturayı onaylamak istiyor musunuz?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Emin misiniz, bu faturayı tamamlamak istiyor musunuz?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Kaydedilmemiş değişiklikleriniz mevcut. Kaydetmeden çıkmak için Tamam'ı veya sayfada kalmak için İptal'i tıklayın",
      "CONFIRMATION_POPUP_MESSAGE":"Bu aboneliği etkinleştirmek istediğinizden emin misiniz?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Bu abonelikle ilgili isteği iptal etmek istediğinizden emin misiniz?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"etkinleştirme",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"İptal edildi",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Bu düzenlemeyi silmek istiyor musunuz?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"İptal etmek istediğinden emin misin?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Devam etmek istiyor musun?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Geçerli seçime devam etmek istediğinizden emin misiniz?",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Miktar",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Ürün",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"miktar",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"sözleşme",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"miktar",
      "CONSUMPTION_TYPES_DESC_USAGE":"kullanım",
      "CONTACT_CANCEL":"İptal etmek ister misiniz?",
      "CONTACT_HEADER_TEXT":"Bir sorunuz mu var?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Bağlantılarımız",
      "CONTACT_LABEL_TEXT_EMAIL":"E-posta",
      "CONTACT_LABEL_TEXT_MESSAGE":"İleti",
      "CONTACT_LABEL_TEXT_NAME":"ad",
      "CONTACT_PARAGRAPH_TEXT":"Aşağıdaki formu doldurarak bize ulaşın, kısa süre içinde size geri dönelim.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Bize görüşlerinizi bildirdiğiniz için teşekkür ederiz. Ekibimiz kısa süre içinde sizinle iletişime geçecektir.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Geri bildiriminiz başarıyla gönderildi",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Ekle",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Sil",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Bu levhayı silmek istediğinizden emin misiniz?",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Bu sözleşme teklifini devre dışı bırakmak istediğinizden emin misiniz?",
      "COOKIE_CONSENT_MESSAGE":"Uygulama size daha iyi bir deneyim sunmak için çerezleri kullanır. Uygulamaya erişmeye devam ederek çerezleri kullanmamızı kabul edersiniz.",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Sıfırla",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Arama",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Müşteriler",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Planlar",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Bayiler",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Müşteri Seçiniz",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Planları Seçin",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Bayileri Seçin",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"filtre",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"miktar",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Is ortagi maliyeti",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Ayriyeten",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Paketi",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Bağlantılı",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "coupon":"Kupon",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Kupon Ataması başarıyla kaydedildi",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Kupon",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Müşteriler",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Aktif",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Aktif",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Yeni Kupon Ataması Ekle",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Düzenle",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Atandı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"durum",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Kupon Kodu",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Kupon Açıklaması",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Kupon Adı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"durum",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Aktif",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Lütfen geçerli bir kupon seçin",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Seçilen kupon bu müşteri tarafından kullanılabilir.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Kupon Ekle",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Kuponlara Geri Dön",
      "COUPON_CAPTION_TEXT_ADD":"Kupon Ekle",
      "COUPON_CAPTION_TEXT_EDIT":"Kuponu Düzenle",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Tüm teklifleri seç",
      "COUPON_DISABLE_BUTTON":"Kuponu devre dışı bırak",
      "COUPON_EDIT_BUTTON":"Kuponu Düzenle",
      "COUPON_ENABLE_BUTTON":"Kuponu etkinleştir",
      "COUPON_HEADER_ACTION":"Aksiyon",
      "COUPON_OWNERSHIPS_CATEGORY":"Kategori",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"uygulanabilirlik",
      "COUPON_OWNERSHIPS_PLAN":"Plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Teklifleri Planla",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Etkili Fiyat",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Seçildi",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Teklif Adı",
      "COUPON_OWNERSHIPS_SALETYPE":"SaleType",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Uygulanabilir",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"En az bir plan ürünü seçmelisiniz.",
      "COUPON_SAVE_SUCCESS":"{{Coupon}} kuponu başarıyla kaydedildi",
      "COUPON_STATUS_FLAG_ACTIVE":"Aktif",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Kupon Kodu",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Kupon Adı",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Son kullanım tarihi",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Adı",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Yeni Müşterilere Uygulanabilir",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Eski Müşterilere Uygulanabilir",
      "COUPON_TABLE_HEADER_TEXT_CODE":"kod",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Oluşturma Tarihi",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"İndirim",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Tarihinde sona eriyor",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Aktif",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"IsPercentage",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Herkese Açık mı",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Maksimum İndirim",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Maks. Geri Ödeme",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Minimum Tutar",
      "COUPON_TABLE_HEADER_TEXT_NAME":"ad",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Reküranslar",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Plan Adı",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Kadar geçerli",
      "COUPON_UPDATED_SUCCESS":"{{Coupon}} kuponu başarıyla güncellendi",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"GeçerliTarih tarihi, Sona Erme tarihinden sonra olmalıdır",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Yeni Kupon Ataması Ekle",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Kupon Atamaları",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Kupon Atamasını Düzenle",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Kuponun satın alındığı tarihte uygulanabileceği tarih",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Kupon Durumu",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Kuponlar",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Kuponun uygulandıktan sonra faturalandırma sırasında dikkate alındığı tarih",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Onay deneyimini başlatma bağlantısı panonuza kopyalanır",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Onayı yakalayan bağlantıyı kopyalamak için <b> <a ngclipboard data-clipboard-text='{{consentURL} {'ng-click='confirmCopy()'> </b> Bağlantıyı panoya kopyala </a>' yı tıklayın. , yeni güvenlik modelini izleyerek bu uygulamaya İş Ortağı Merkezi'ne erişim izni vermek için",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Bağlantıyı gizli bir oturumda açın ve (İş Ortağı) kiracınızda Global Yönetici olan ve İş Ortağı Merkezi'nde Yönetici Aracısı rolü atanmış bir hesap kullanarak onay deneyimini tamamlayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Uygulamanın yeni güvenlik modeliyle çalışıp çalışmadığını kontrol etmek için Test düğmesine tıklayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Uygulamanın eski kimlik bilgilerinizi temizlemesine ve yeni güvenlik modeline geçmesine izin vermek için <b> Yeni güvenlik modeline geç </b> 'i (test başarılı olduktan sonra etkinleştirilir) tıklayın.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Yeni güvenlik modeline geçme talimatları",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Talimatlar",
      "CPV_LOADING_MESSAGE":"Lütfen onayınızı işleme koyarken bekleyin ...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Onayınızı verdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek İş Ortağı Merkezi'ne erişecek şekilde yapılandırılabilir.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Ayarlar -> İş Ortağı Merkezi ile ilgili talimatları uygulayın.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Yeni güvenlik ayarlarını kullanarak Kitap Ortağı Merkezi'ne erişme girişimi başarılı.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Kart numarası",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Ay",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"4-Nisan",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"8-Ağustos",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12-Aralık",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"2-Şubat",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"1-Ocak",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"7-Temmuz",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"6-Haziran",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"3-Mart",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"5-Mayıs",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11-Kasım",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Ekim",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"9-Eylül",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Karttaki İsim",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Yıl",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"Kupon oluşturulamadı",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Kupon başarıyla oluşturuldu",
      "CREATE_CUSTOMER_EXCEPTION":"Müşteri oluşturulurken hata oluştu",
      "CREATE_CUSTOMER_USER_ROLE":"Müşteri Kullanıcıları Oluşturun",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Müşteri kullanıcıları oluşturulamadı",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Fatura oluşturmak",
      "CREATED_SUCCESSFULLY":"başarıyla oluşturuldu",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Lütfen kümülatif vergiler için en az bir vergi adı ve yüzde girin.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Para birimi dönüştürme kuralı başarıyla eklendi",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeye Geri Dön",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Ekle",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Para birimi dönüştürme",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Düzenle",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"Para birimi dönüştürme kuralı başarıyla silindi",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Herşey",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Dönüşüm oranı",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Müşteri",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Dönüşüm oranı",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Müşteri",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"durum",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Para birimi dönüştürme kuralı başarıyla güncellendi",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Kullanım ayrıntıları henüz mevcut değil, lütfen bir süre sonra tekrar deneyin.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"miktar",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Başlık",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"İptal etmek",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Plan Adı",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Planları Seçin",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Bitiş tarihi",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Ürün sahipliğini yönetme",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Yerleşik Müşteri",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Aksiyon",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Açıklama",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Başlık",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Açıklama",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Etkinlik",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Başlık",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Sıfırla",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Sunmak",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"seçmek",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Arama",
      "CUSTOM_NOTIFICATION_SELECT":"seçmek",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Sağlayıcıları",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Başlangıç ​​tarihi",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Aksiyon",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Etkinlik",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Güncelleme",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Tüketim türleri",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Kategori",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Ortak Teklifleri",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Sözleşme Teklifi Ekle",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"İş Ortağı Teklifi Ekle",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"İş Ortağı Tekliflerine Geri Dön",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"uygulanabilirlik",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Ekle",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Sözleşme Teklifleri",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ortak Teklifleri",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Düzenle",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"'Varsayılan' olarak 'ChargePeriods', 'Koltuk satın almak için faturalandırma kuralı' ve 'Koltuklar için' koltuk azaltma için faturalandırma kuralı 'seçeneğini belirleyin.",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Kompozit Teklife İş Ortağı Teklifi eklerken aklınızda bulundurmanız gereken bazı noktalar",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Kategori",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Faturalandırma Dönemi Türü",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim türleri",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "CUSTOM_OFFERS_LABEL_TEXT_Description":"Açıklama",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Feed Kaynağı",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Talimatlar",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"Teklif bir AddOn mu?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"ad",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Ücretsiz iptal için gün sayısı",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Satış ücreti",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Geçerlilik",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Abonelikler için faturalandırma dönemi olarak ne istiyorsunuz?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Aboneliklerin hangi Tüketim türüne sahip olmasını istiyorsunuz?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Feed kaynağı",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Maliyet iptali olmadan izin verilen maksimum gün sayısı.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya tam şarjlı olmalıdır",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Döşeme",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Teklifin geçerliliği.",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"{{Customoffer}} iş ortağı teklifi başarıyla kaydedildi",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Maliyet fiyatı",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Teklif Ayrıntıları",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Satış ücreti",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Görünüm",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(Silindi)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Bu bileşeni aldığınız fiyattır. Bunu bir plana dahil ettiğinizde bu teklifin Satış Fiyatı'nı belirleyebilirsiniz.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Bu bileşeni sattığınız fiyattır.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"{{Customoffer}} iş ortağı teklifi başarıyla güncellendi",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Ücret başlangıç ​​ve bitiş tarihleri ​​bir ayın başlangıç ​​ve bitiş tarihleri ​​olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Ücret başlangıç ​​ve bitiş tarihleri, MS faturalandırma başlangıç ​​ve bitiş tarihleriyle uyumlu olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Ücret başlangıç ​​ve bitiş tarihleri, İş Ortağı faturalandırma başlangıç ​​ve bitiş tarihleriyle uyumlu olacaktır.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Takvim ayına göre hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"MS Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"İş Ortağı Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Tedarikçi Faturalandırması ile Hizala",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Varsayılan (satın alma tarihinden itibaren)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Yeni şifre belirle",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Parolayı sıfırlamak istediğinizden emin misiniz?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Yeni bir şifre oluşturulacak ve kullanıcının geçerli şifresi artık çalışmaz.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Yeni Şifre Girin",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Şifreyi göster",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Otomatik Şifre Oluştur",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Özel Şifre",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"En az 8 karakter. En az bir büyük harf, bir küçük harf, bir özel karakter, bir sayısal değer içermelidir",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Lütfen en az 8 karakterden oluşan bir şifre seçin.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Özel Parola kullanıcının adını içermemelidir.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Politika başarıyla etkinleştirildi",
      "CUSTOM_POLICY_ADD_TITLE":"Özel Politika ekle",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Atanan Politikalar",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Politika Başarıyla Atandı",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Politika ata",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"Azure PolicyId",
      "CUSTOM_POLICY_CUSTOMER_NAME":"müşteri adı",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Politika başarıyla devre dışı bırakıldı",
      "CUSTOM_POLICY_DESCRIPTION":"Açıklama",
      "CUSTOM_POLICY_INSTRUCTIONS":"Özel bir politika oluşturmak için aşağıdaki adımları izleyin",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Özel politika için bir ad ve açıklama girin",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Uygun tanım sağlayın",
      "CUSTOM_POLICY_LABEL_SCOPE":"Dürbün",
      "CUSTOM_POLICY_NAME":"ad",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Politika Ataması",
      "CUSTOM_POLICY_POLICYDEFINITION":"Tanım",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Kaynak Grubu Adı",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Politika Başarıyla İptal Edildi",
      "CUSTOM_POLICY_SAVE_SUCCESS":"{{Policy}} politikası başarıyla kaydedildi",
      "CUSTOM_POLICY_SUBSCRIPTION":"Abonelik Adı",
      "CUSTOM_POLICY_TITLE":"Özel Politikalar",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Politikayı abonelik düzeyine atamak için lütfen tüm Kaynak Gruplarını seçin.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rol başarıyla etkinleştirildi",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"Azure Rolü",
      "CUSTOM_ROLE_CUSTOMER_NAME":"müşteri adı",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rol başarıyla devre dışı bırakıldı",
      "CUSTOM_ROLE_DESCRIPTION":"Açıklama",
      "CUSTOM_ROLE_INSTRUCTIONS":"Özel bir rol oluşturmak için aşağıdaki adımları izleyin",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Özel rol için bir ad ve açıklama girin",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Rol tanımı için yalnızca “Eylem” ve “Eylem Yok” bölümlerini girin.",
      "CUSTOM_ROLE_NAME":"ad",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Kaynak Grubu Adı",
      "CUSTOM_ROLE_ROLEDEFINITION":"Rol Tanımı",
      "CUSTOM_ROLE_SAVE_SUCCESS":"{{Role}} rolü başarıyla kaydedildi",
      "CUSTOM_ROLE_SUBSCRIPTION":"Abonelik Adı",
      "Customer":"Müşteri",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Vergilendirilebilir mi",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Sepeti Güncelle",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Kategori",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Tohum Başına Fiyat",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Ezidebit Pty Ltd ACN 096 902 813'e (Kullanıcı Kimliği No 165969, 303909, 301203, 234040, 234072, 428198) yukarıda / bu Otomatik Ödeme Talebi uyarınca ve Ezidebit DDR Hizmet Sözleşmesi uyarınca. <br> I / Bu ödemelerin BeCloudSmart sözleşmesinin Şartları ve Koşulları ile müteakip sözleşmelere göre BeCloudSmart tarafından belirtilen aralıklarla ve tutarlarda borçlandırılmasına izin veriyoruz.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"anlaşma",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Ezidebit Anlaşması",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Microsoft Bulut Satıcı Sözleşmesi",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"İş ortağı kullanım şartları",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Ezidebit Anlaşmasını Okuyun",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Lütfen okuyun",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Ödeme başarıyla kaydedildi.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Müşterinin fatura ayrıntılarını işe alma süreci devam ediyor",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Dahili'ye devam et",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Müşteri faturalandırma bilgileri başarıyla tamamlandı.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Müşteri faturalandırma sisteminde zaten var",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/> <p> Faturalandırma Sisteminde Kimlik: <strong> {{billingProviderReferenceID}} </strong> ile bir eşleşme bulundu. <br/> <span> Dosyadaki Fatura Adresi <strong> {{formattedAddress}} < / strong>. </span> <br/> Müşteri faturalandırma bilgileri başarıyla eklendiğinde bu ayrıntıyı değiştirmek için lütfen kimliğine bürünün ve <strong> Profil </strong> seçeneğini kullanın.",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Hata",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"Devam etmekte",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"başarı",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Daha fazla oku",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Yeniden Yükleme Müşteri Onayı",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Yeniden Yükleme Başlatılıyor",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Fatura Satır Öğeleri ...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Fatura özeti",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Kârlilik Raporu",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Kârlilik Raporu",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Vergi Satir Ögeleri",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Vergi Satir Ögeleri",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Vergi Özeti",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Vergi Özeti",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Müşteri Onayını Yeniden Yükle",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Mutabakat Raporunu Yeniden Yükle",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Kaldırmak",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Müşteri Sepeti",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Alışverişe devam",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Sipariş Vermek",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Geçerlilik",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Ödenecek tutar",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Para birimi:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Toplam tutar",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Toplam sayım:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Toplam indirim",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Fiyat Detayları",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Operasyonel Varlık",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Ekle",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Güncelleme",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Müşteri onayı",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"Sözleşme URL'si",
      "CUSTOMER_CONSENT_HELP_TEXT":"Lütfen müşterinin kuruluşundaki <a ng-click='vm.CustomerConsentClick()'> Microsoft Müşteri Sözleşmesi'ni </a> kabul eden kişinin ayrıntılarını belirtin.",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"E",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"İsim",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Soyadı",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Microsoft Müşteri Sözleşmesi'ni okumak için <a id='customerConsentLink' ng-click='vm.MicrosoftCloudAgreementClicked()'> buraya </a> tıklayın.",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Microsoft Müşteri Sözleşmesi",
      "CUSTOMER_CONSENT_TEXT_DATE":"tarih",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"E-posta",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Zaman",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Şimdiye kadar satın alınan ürün sayısı",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Ürün",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Bilgilerinize erişimi olan kullanıcı sayısı",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Kullanıcılar",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Bekleyen bir faturada uyarı",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Fatura",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Office 365 portalına hızlı bağlantı",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Office Portalı",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Şartlar ve koşullar kabul günlüğü",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Şartlar ve koşullar",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Fatura satır öğesi başarıyla silindi",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Departman Ekle",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Departman başarıyla silindi",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Bölümü Düzenle",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Bölümler",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Departman başarıyla kaydedildi",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Atanan Siteler",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"yer",
      "CUSTOMER_EXPORTINVOICE_WARNING":"{{Customername}} için fatura henüz oluşturulmadı. Lütfen bir süre sonra tekrar kontrol edin.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Yeniden Yükleme Sağlayıcıları Müşteri Onayı",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"İptal etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Değişikliklerinizi geri almak istediğinizden emin misiniz?",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Müşteri detayları",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Ara toplam",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adres",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"ülke",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Bitiş tarihi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Başlangıç ​​tarihi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Satış Tipi",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Kredi",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Ek ücret",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Ayarlama Tutarı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Ayarlama Ayrıntısı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Sağlayıcı Adı",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Faturaya Geri Dön",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Ekle",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"ayarlama",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Düzenle",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Faturalar",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Faturalar",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":"Müşteri Etiketi",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"değer",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filtreler",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Ürünü Yönet",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Azure Plan sayfasına geri dön",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Grup ismi",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Eşik",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Hiçbir yetki bulunamadı",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Sahip henüz atanmadı",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Sahip",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Roller ve amp; Politika içeriği buraya gelir ....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"fatura",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Yorumlar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Tahmin",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Gruplar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Bilgi",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Sahibini Yönet",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Operasyonel Varlıklar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Ödemeler",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Roller ve Politikalar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"kullanım",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"abone",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Bir örnek .csv indirin",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Birden çok kullanıcı için hesap verilerini .csv biçiminde yükleyin. Bir örnek .csv indirin",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"başvuru için dosya.",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Lütfen yeni adı girin",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Müşteri Adını Değiştir",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"Müşterinin adı başarıyla değiştirildi",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Müşteri Adı Güncelleme başarısız",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Müşteri Adı Güncelleme başarılı",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Herhangi bir Microsoft Kiracısına bağlı değilsiniz.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"Bağlı sağlayıcı kiracısı olmadığından Sağlayıcı ürünlerinin satın alınması başarısız olabilir",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"atama",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"düzenlendi",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"silindi",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Hata",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Onboarded",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"Faturalandırmada Arama!",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Bir müşterinin Faturalandırma sistemi kimliğini almak için lütfen aşağıdaki adımları izleyin",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Faturalandırma Sisteminde oturum açın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Müşterilere Git",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Açmak için müşteri kaydını tıklayın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Kayıt anahtarından sonraki dizeyi alın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Url'de recordkey = öğesinden sonra gelen dizeyi alın",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"Örneğin:",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"değeri-için kopyalanabilir",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Authorize.Net'e giriş yapın",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Müşteri Bilgisi Yöneticisi'nden Profil Kimliği Alma",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Müşteri Bilgi Yöneticisi için kök: Ana Sayfa-> Müşteri Bilgi Yöneticisi",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"URL'den dizeyi al",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Faturalandırma Sistemi Kimliği",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Toplu Müşteri Katılımı",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Müşteri Katılımı",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Müşteri Ayrıntılarını Toplayın",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Bitti",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"buraya",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Yukarı Bak!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Bu Müşteri için Yerleşik Sürüme Geçin",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Kaldırmak",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Atla",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Başka bir tane dene",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Toplu Müşteri katılımı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Müşteri katılımı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Harita Lisansları",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Yerleşik Başarı",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Lütfen bir ödeme şekli seçin",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Yeni katılım tamamlanmadı. İlerlemek için 'Bu Müşteriye Devam Et' düğmesine tıklayın.",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"Faturalandırmada Arama!",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"EziDebit Kimliği",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Bir müşterinin EziDebit kimliğini almak için lütfen aşağıdaki adımları izleyin",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"EziDebit oturumu aç",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Ödeme Yapanlara Git",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Açmak için ödeme yapan kaydı tıklayın",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"ContractId'den sonraki dizeyi al",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"URL'de contractId = öğesinden sonra gelen dizeyi al",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"Örneğin:",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"değeri-için kopyalanabilir",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Müşteri detayları",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"İş Ortağı Merkezi'nden eklenebilecek müşteriler",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"başarıyla oluşturuldu",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"başarıyla dahil edildi",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Sonraki adımlar",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Plan ata",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Banka adı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Müşteri Para Birimi Kodu",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Müşteri Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Ürün Paylaşılabilir mi?",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Satır 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Hat 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Microsoft Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Microsoft kiracısını seçin.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Ay",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Sonraki Fatura Tarihi",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"NOT:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Yukarıdaki örnekte verildiği gibi dosyadaki kullanıcı ayrıntısı dışında bir içeriği güncellemeyin.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"isteğe bağlı:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Aboneliklerine karşı kullanıcılara atanmış olan lisanslarla ilgili ayrıntılara sahipseniz, aşağıdaki adımları izleyerek bunu bize sağlayabilirsiniz:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Bilgileri, şu adresten indirilebilecek biçimde doldurun:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Kullanıcılar'ı tıklayın ve müşteri kiracısındaki kullanıcılara bu portala erişim izni verin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Abonelik ayrıntısının kimliğine bürünme ve doğrulama",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Lütfen katılım işlemini tamamlamak için güncellenmiş bir dosyayı lisans ayrıntılarıyla yeniden yükleyerek hataları düzeltin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Bu örneği izleyerek köşeli parantez ([]) arasında kullanıcı e-posta adreslerini sağlamanız yeterlidir:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Dosyayı yükleyin ve tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Yeni giriş işlemini tamamlamak için SKIP'i tıklayarak lisans ayrıntılarını sağlamayı atlayabilirsiniz.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Müşteri listesine geri dönmek için Bitti'yi tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Şu anda yerleşik olan müşteriyi bulun",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Yüklediğiniz lisans ayrıntılarını işlerken hata olması durumunda <i class = \"fa fa-star red \"> </i> bir işlem düğmesi görünecektir",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Hataları görüntülemek için <i class = \"fa fa-star red \"> </i> düğmesini tıklayın",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Lütfen hataları düzeltin ve tekrar yüklemeyi deneyin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Bir plan seçin",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Kiracılar",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Dosya yükleme",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"seçmek",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Yıl",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"silindi",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Askıya alındı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Adı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Para Birimi Kodu",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Sağlayıcı",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Bu Müşteri için Yerleşik Sürüme Geçin",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Hayır",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Evet",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Müşteri ve harita aboneliklerini arayın",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Harita Lisansları",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Tamamlayınız",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Tamamlayınız",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Faturalandırma E-postası",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Faturalandırma Sistemi Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Microsoft Kimliği",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Biçimlendirme",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Eşleşen Ürünler",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Eşleşen Ürün Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Yüzde",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Sağlayıcı Ürün Adı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Tedarikçi Miktarı",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Eşleştirilecek sağlayıcı ürünü aboneliği bulunamadı, Müşteri Ayrıntılarını Topla düğmesini tıklayarak devam edebilirsiniz.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"ÖZEL",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Profil seçeneğini kullanarak diğer ödeme yöntemlerine geçebilirsiniz. Faturalar size İş Ortağı tarafından e-posta yoluyla gönderilir",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Kullanım abonelikleri başarıyla yüklendi.",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Düzenle",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"yönetme",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adresler",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Varlık türü",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"rol",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Yeni Kullanıcı Ekle",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Fatura Adresi",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"kullanıcıyı düzenle",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"teslimat adresi",
      "CUSTOMER_OPTION_ALL_PERIODS":"Tüm Dönemler",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Faturayı Görüntüle",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Müşteri siparişleri",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Detayları göster",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"durum",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Müşteri siparişleri",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Aksiyon",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Bölüm Adı",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Sipariş Kimliği",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Sıralama:",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Sipariş verildi",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Site adı",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"durum",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Son yenilendi",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Daha fazla oku",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Müşteri Ödemelerine Dön",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"İçin Bekleyen Faturalar",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Mevcut Kupon Kodu",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"Hedef planın sahip olmadığı için <strong> {{PlanName}} </strong> 'den <strong> Hata! </strong> Müşteri <strong> {{targetPlanName}} </strong>' e taşınamaz. şu anda etkin olan aboneliklerle eşleşen teklifler.",
      "CUSTOMER_PLAN_CHANGE_INFO":"Mevcut plandaki bileşik teklife karşı en az bir etkin abonelik varsa, plan değişikliği henüz desteklenmemektedir.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Mevcut Plan",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Müşteri Abonelikleri",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Etkili Fiyat",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Eşleşme bulundu",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Hedef Adı",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Hedef fiyat",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Bu kupon müşteriye zaten atanmış.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Başarılı! </strong> Müşteri, <strong> {{PlanName}} </strong> 'den <strong> {{targetPlanName}} </strong>' a taşınabilir. Devam etmek için <strong> Gönder </strong> 'i tıklayın.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Hedef Planı",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Geçerli kupon kodunu kullan",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(Silindi)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Eklentileri",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Değişiklik",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Aboneliği Yeniden Etkinleştir",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Eklentilerle Aboneliği Yeniden Etkinleştirin",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Aboneliği Askıya Al",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Paketi",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Operasyonel Varlık",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Sunmak",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Göz ardı etmek",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Hata detayları",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Talimatlar",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Bu ürünün faturalandırma döngüsünü {{targetBillingCycle}} olarak değiştirmek istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"{{ProductName}} {{ile Eklentileri yeniden etkinleştirmek istediğinizden emin misiniz? 'eklentilerle': ''}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"{{ProductName}} adlı kullanıcıyı askıya almak istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Adı başarıyla değiştirdi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"{{ProductName}} faturalandırma döngüsünü {{targetBillingCycle}} olarak değiştirdi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"satın alınan ürün için miktarı sıfıra güncelleyemiyorum",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"Abonelik güncelleme talebi başarıyla gönderildi",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"{{ProductName}} başarıyla {{Adddons? 'eklentilerle': ''}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Mülkiyet başarıyla onaylandı",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{ProductName}} başarıyla askıya alındı",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Aboneliği iptal et",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"tarih",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Müşteri Ürünleri",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Atamak",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"miktar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"durum",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"Miktar daha büyük olamaz",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Miktar (Mevcut)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"İçin miktar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Bağlantılı Abonelik",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Atamak",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Eklenti",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Miktar (Mevcut)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"Miktar daha büyük olamaz",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"İçin miktar",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Tarihinde sona eriyor",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Dürbün",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Ürün siparisi",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Ürün siparisi",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Ayriyeten",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Bağlantılı",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Değişikliklerinizi geri almak istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Ürün sırasını güncellemek istediğinizden emin misiniz?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Müşteri Ürün sırası güncellendi",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"İndirim",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Aktif",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"silindi",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Başarısız oldu",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Başarılı",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Askıya alındı",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"ülke",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Sağlayıcı",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Aboneliği Yeniden Etkinleştir",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Eklentilerle Aboneliği Yeniden Etkinleştirin",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Dürbün",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"durum",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Aboneliği Askıya Al",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Yenileme Tarihi",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Tarafından sahip olunan",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Bağlantılı Abonelik",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"durum",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"yer",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Kiraci",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Aboneliği iptal et",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Temel ayrıntılar",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Yorumlar",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Bildirimler",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Sahipliği Yönetme",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Kullanıcılar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Bağlantıyı kopyala",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Bağlantıyı kopyala",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"İndir",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Anahtarları ve indirmeleri alın",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Bağlantı panoya kopyalandı",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Dosya türünü seçin",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Dil Seçin",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Ürünü seç",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Aksiyon",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Dosya boyutu",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"İD",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"anahtar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"ad",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"miktar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Siparis verildi",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"yönetme",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"İşleme",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Uygulamak",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Kapat",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Ürünü Yönet",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Miktar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Tarafından sahip olunan",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Toplam tutar",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Birim fiyat",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Birim fiyat",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Bölüm",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"yer",
      "CUSTOMER_PRODUCTS_VALIDITY":"Geçerlilik",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"E-posta",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Temel ayrıntılar başarıyla güncellendi",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"teslimat adresi",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Müşteri profili",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Bölümler",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Siteler",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Adres Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"E-posta Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Telefon Ekle",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adresler",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"E-mail adresleri",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Telefon numaraları",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"ad",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Siteler",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Kayıt bulunamadı!",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Ekle",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Ödeme Profilleri",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Profil bulunamadı. EKLE düğmesine tıklayarak yeni bir ödeme profili ekleyin",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"teslimat adresi",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Kullanıcı hesapları",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Hesap Oluşturma Tarihi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Fatura Adresi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"C3 Başlangıç ​​Tarihi",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Kanonik İsim",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Dükkan",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Onaylamak",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Bitti",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Sunmak",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Adres 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adres 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"ülke",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Alan adı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Birincil kişiye erişim izni verilsin mi (Müşteri Yöneticisi olarak)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Uygulamaya erişmek için davetiye gönderilsin mi?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Kiracı Kimliği",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"gecerli",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"mevcut değil",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Alan kullanılabilirliğini doğrulama",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Aksiyon",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"anlaşma",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"sözleşme",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Kiraci",
      "CUSTOMER_RECONCILIATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_REPORT":"Müşteri Raporları",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Ürünleri Filtrele",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Maksimum Sipariş Tarihi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Maksimum Miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Minimum Sipariş Tarihi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Minimum Miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Gün İçinde Sona Erer",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Arasında Sipariş Verildi",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"miktar",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Ürün Raporu",
      "CUSTOMER_REPORTS":"Raporlar",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Ayarlama başarıyla kaydedildi.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Geçerli faturaya düzenleme olarak geçmiş aidatlar eklendi",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Ödeme başarıyla kaydedildi",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Varsayılan Ödeme Şekli",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"ad",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Ödeme şekli",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Etiketler",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"seçmek",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Alan adı",
      "CUSTOMER_SEARCH_FILTER":"Müşterileri Filtrele",
      "CUSTOMER_SEARCH_PROVIDER":"Sağlayıcı",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "CUSTOMER_SEARCH_RESET_OPTION":"Sıfırla",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Arama",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Sepete ekle",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Tüm Eklentileri Seç",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Kapat",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Dükkan",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Fatura döngüsü",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Faturalandırma Türü",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Geçerlilik",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"AYRİYETEN",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filtreler",
      "CUSTOMER_SITE_ADD_SITE":"Site Ekle",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site başarıyla silindi",
      "CUSTOMER_SITE_EDIT_SITE":"Siteyi Düzenle",
      "CUSTOMER_SITE_HEADER_TEXT":"Siteler",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Site başarıyla kaydedildi",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Azure Planı",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Azure Planı'nı seçin.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Yetkiler'i seçin.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Devam etmek istediğine emin misin?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Kabul etmek",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"reddetmek",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Aksiyon",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Ürünlere Geri Dön",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Aksiyon",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Fiyat",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Ürün adı",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"miktar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Kullanıcı adı",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Fiyat",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Ürün",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"miktar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"ad",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Lütfen en az bir dosya seçin.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Dosya başarıyla yüklendi",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"onaylamak",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Mutabakat Raporunu Yükleme",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Müşterilerin Faturalandırma Ayrıntılarını Yeniden Yükleme ...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Müşteri Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Aboneliğe Kullanıcı Ekle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Abonelik Kullanıcılarına Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Mevcut (çoklu)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Toplu Müşteri Katılımı",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Mevcut (tekli)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Mevcut müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Faturalandırma Özetini Dışa Aktar",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"İhracat Kullanım Ayrıntıları",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Rapora git",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"buraya",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Yeni",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Yeni müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Yerleşik Müşteri",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Fatura Ayrıntılarını Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Geçerli Ayı Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonelik Özetini Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Kullanım Ayrıntılarını Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"seçmek",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Müşteri Ekle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"İçin Abonelik Ekle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"İçin Azure Abonelikleri",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonelik Kullanıcısını Yönet",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Teklifler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Plan ve teklifler",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"İçin Mutabakat Raporu",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonelik kullanıcıları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Müşteri Yapılandırması",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"değer",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Kullanım Detayları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Kullanıcıları",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"İçin Başlangıç ​​Hatalarını Doğrula",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Sonuçlandırmak",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"fatura",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"şirket Profili",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Lütfen bir ödeme şekli seçin",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Birincil iletişim",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"Fatura Onaylandı!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Bu fatura kesilmeden ve müşteri borçlandırılmadan önce yine de bu faturaya ayarlamalar (ek masraflar veya krediler) ekleyebilirsiniz. Müşteri artık bu faturayı görebiliyor ve sonlandırıldıktan sonra bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"Fatura Kesinleşti!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Kullanıcı artık bu faturayı görebiliyor ve bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"Fatura oluşturuldu!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Sonlandırılmadan ve müşteri borçlandırılmadan önce bu faturaya ayarlamalar (ek masraflar veya krediler) ekleyebilirsiniz.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"Fatura, fatura adresinize gönderilecektir. Teşekkür ederim.",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Autopay",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"fatura",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Ödeme şekli",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Banka adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Kent",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Tehlike Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"E-posta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Hata Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Grup ismi",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Bilgi Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Müşteriden Gizle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Seçilen öğeler",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Soyadı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Satır 1",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Hat 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Ay",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Birincil alan adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"rol",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Durum",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonelik Adı",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Uyarı Eşiği",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Yıl",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Zip",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Planlar",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"miktar",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuel",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Hayır",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Evet",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Etkin olmayan abonelik",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Miktar uyuşmazlığı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Erişimi Kaldır",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Aboneliği Etkinleştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Kullanıcılar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"CSP'de etkin",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Bileşen Adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Email kimliği",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"yetkileri",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Hata",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Tahmini maliyeti",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Müşteriden Gizle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Abonelikler müşteriler tarafından görülemez",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Son yenilendi",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"MSFT Faturası",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Başlangıç ​​Durumu",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"kâr",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"rol",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Eşik",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Kullanıcı adı",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Henüz abonelik eklenmedi.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Müşteri Planı Atama",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Fatura Döngüsünü Değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"İsmini değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Etiketleri Tanımla",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Vergi Tanımlama%",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"İş Ortağı Merkezi'ndeki etkinleştirme durumu uyuşmazlığını düzeltin",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Uyumsuzluğu düzelt ...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Uyumsuzluğu düzelt",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Ödeme Sağlayıcıları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Plan değiştir",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Sağlayıcıları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Hızlı Kimliğe Bürünme",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Mutabakat raporu",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonelik Özetini Yeniden Yükle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Müşteri yapılandırması",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Otomatik Ödemeyi Test Et",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Kullanıcılar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Doğrulama Hataları",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"ÖZEL",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Toplam",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Aylık Bütçe-Bilgi-Uyarı-Hata-Tehlike",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"gecerli",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"mevcut değil",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Lütfen şablonu şuradan indirin:",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Kullanıcıyı sil",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Portala erişim izni verme",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"impersonate",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Portaldan erişimi iptal et",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"Değer 0 ile 100 arasında olmalıdır",
      "CUSTOMER_TAGS_EXAMPLE":"Kullanıcılar için erişim kontrolü tanımlanırken kullanılacak müşteri için etiket ve değer çiftlerini tanımlayın. <br/> Örnek: Değer olarak SupportAgent adında bir etiket ve bir ajanın takma adı (örneğin, Agent001) tanımlayabilirsiniz. Bir Yardım Masası aracısı, SupportAgent olarak ayarlanmış etiketlerden birine ve Agent001 olarak bu özel etiket için değerlerden birine sahipse, aracı, {SupportAgent, Agent001} etiket değeri çiftine sahip müşterileri görebilir.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"ad",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"değer",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Sil",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"değer",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Bekleyen Faturaları Görüntüle",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"İçin ödemeler",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Müşteri",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Müşteri",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Kullanıcı bilgileri başarıyla kaydedildi",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Yeni Kullanıcı Ekle",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Erişim İzni Ver",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Kullanıcıyı Yönet",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Erişimi İptal Et",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Bölüm",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"E",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"rol",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Rol Türü",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"yer",
      "CUSTOMER_USERS_HEADING":"Kullanıcılar",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"E",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"rol",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Faturaları / Ödemeleri İndirin",
      "customerconfiguration":"Müşteri yapılandırması",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"ACH ayrıntılarının bir işletme hesabıyla ilgili olup olmadığını lütfen belirtin",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Bu müşteri zaten seçildi",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Müşteri sistemde zaten var",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Müşteri başarıyla oluşturuldu",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Müşterinin işe alım süreci devam ediyor",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Lütfen en az bir dosya seçin.",
      "CUSTOMERONBOARDING_SUCCESS":"Müşteri başarıyla dahil edildi.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Dosya yüklenirken bir hata oluştu",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} zaten {offername} için bir lisansa sahip",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Sağlanan kullanıcı sayısı mevcut lisans sayısından fazla. Lütfen kullanıcı ayrıntılarını düzeltin ve tekrar deneyin.",
      "customers":"Müşteriler",
      "Customers":"Müşteriler",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Aksiyon",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"iş",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Fatura Profiline Bağlantı",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Sağlayıcı Bağlantısı",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Faturalar Oluşturma",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Müşterilerin Kârlılık Raporunu Yeniden Yükleme ...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Ayarlama Ekle",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Teslim tarihini düzeltme olarak ekleyin!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Faturayı İndir",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Rapora git",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Tarihe Göre Tarih",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Teklife Göre Tarih",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Ödemeyi Başlat",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Ücreti Müşteri",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"İçin fatura",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"İçin aboneliklerle kârlılık",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"İçin Abonelik Değişiklik Geçmişi",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"Alan adı kullanılabilirliği kontrol edilemiyor",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"{{Username}} sitesine erişim vermek istediğinizden emin misiniz?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Müşteriler",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Fatura bulunamadı. Lütfen desteğe başvurun.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adres",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Müşteri",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Hesap özeti",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Fatura donemi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Bitis tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Fatura tutarı",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Fatura tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Fatura ödeme tarihi",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Oluşturulan Fatura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Fatura Durumu",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"Fatura oluşturuldu!",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Bu işleme koyulmadan ve müşteri borçlandırılmadan önce bu faturaya düzenleme (ek masraf veya kredi) ekleyebilirsiniz.",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"Fatura işleme koyuldu!",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Müşteri artık bu faturayı görebiliyor ve bu faturaya yapılan ödemeleri kaydedebilirsiniz.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Geçmiş faturalardan alınan aidatlar",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Geçmiş faturalardan aidat varmış gibi görünüyor. Müşteriden yapılan tüm ödemeleri kaydettiğinizden eminseniz,",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"mevcut faturaya düzenleme olarak <strong> {{InvoiceDueAmount}} </strong> eklemek için.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Miktar aidatları var gibi görünüyor",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"toplamda.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Düzenle",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Sil",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Fatura Ayrıntısı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Lisans Tabanlı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Kullanım Bazlı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Hareketler",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Şarj türü",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"İndirim tutarı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Teklif fiyatı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Büyük Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Ara Toplam Miktar",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Abonelik Adi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Vergi miktarı",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Birim fiyat",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Kayıt Ödeme",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Ödeme miktarı",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Arıza Nedeni",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Ödenen miktar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Ödeme Kimliği",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Fatura Ödemeleri",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"İşlem Kimliği",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Bu faturaya yapılan ödemeleri kaydedin.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"Örneğin:",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Taksitli kısmi ödeme vb.",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Genel Toplam",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Ara toplam",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Arıza Nedeni",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Miktar",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"tarih",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Uyarılar",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Ödeme Durumu",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Geçmiş dahil fatura bilgileri başarıyla yenilendi",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Geçmiş dahil olmak üzere kullanım ayrıntıları başarıyla yenilendi",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Faturalandırma E-postası",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Planlar",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"ad",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Faturalandırma sisteminde eşleşen bir kayıt mı arıyorsunuz?",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Faturalandırma Sisteminde {{ID}} Referans Kimliği ile bir eşleşme bulundu.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Tedarikçi Müşteri Kimliği ile bir eşleşme için C3'teki müşterileri arama",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Adlı bir müşteri buldum - {{customername}}",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"{{SağlayıcıAdı}} içindeki Sağlayıcı Müşteri Kimliği ile eşleşecek müşterileri arama",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"{{SağlayıcıAdı}} alanında {{customername}} adında bir müşteri buldum",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Kompozit olarak sunulan çocuk miktarları aynı olmalıdır",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"CSP'deki bir veya daha fazla teklif, faturalandırmada aynı teklifle eşleştirilir. Lütfen eşlemenizi ayarlayın",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"Plandaki {{Name}} teklifinde birden fazla eşleşen CSP Aboneliği var.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"Faturalandırmadaki {{nameinbilling}} aboneliği eşleştirilemedi",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"{{Name}} teklifinin İş Ortağı Merkezi'nde eşleşen CSP abonelikleri yok",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"Kitap Ortağı Merkezi'ndeki aboneliğin - {{name}} eşleme planında eşleşen bir teklifi yok",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Aşağıdaki teklifler CSP abonelikleriyle eşleştirilmelidir -",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"{{Name}} teklifinde, sahip olduğu koltuklardan daha fazla kullanıcı belirtildi",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Aboneliklerin miktarları İş Ortağı Merkezi ile eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"{{Subscriptionname}} aboneliğinin miktarları İş Ortağı Merkezi ile Faturalandırma arasında eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Aboneliklerin miktarları İş Ortağı Merkezi ile Faturalandırma arasında eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"{{Cspsubscription}} CSP aboneliğine karşı koltuk sayısı, plandaki ilgili tekliflere karşı sağlanan tüm sayıların toplamıyla eşleşmelidir",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Bir veya daha fazla CSP teklifi eşlenmiyor veya CSP'de eksik abonelik var",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"{{CustomerName}} için abonelikler yenilenirken bir hata oluştu",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Müşteri için yenilenen abonelikler - {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"Müşteri kaydı başarıyla tamamlandı. Portala giriş yapma talimatlarını içeren {{kayıtlı posta}} adresine bir e-posta gönderilir.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"Müşteri kaydı başarıyla tamamlandı.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"Müşteri kaydı başarıyla tamamlandı. Portalda oturum açın.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Lütfen düzenleme için bir teklif ilişkilendirin",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Müşteri Ödemeleri",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Fatura tutari",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"tarih",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Bölüm Adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"ad",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Yeni fiyat",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Eski fiyat",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Diğer maliyet",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Vergi Öncesi Fatura Tutarı",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Vergi Öncesi MSFT Faturası",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"kâr",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Kâr%",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Site adı",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Henüz abonelik eklenmedi.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Müşteriyi Sil",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Planları Yönet",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Aksiyon",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"ad",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Kullanıma dayalı satır öğelerini indir",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Aboneliklerle karlılık",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Kârlılık Raporunu Yeniden Yükle",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Abonelik Değişiklik Geçmişi",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Faturayı Görüntüle",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Kullanım Satır Öğelerini Görüntüle",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"Azure aboneliğine {{username}} başarıyla eklendi",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"Azure aboneliğine {{azuresubscription}} {{username}} başarıyla eklendi",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} Azure aboneliğinden başarıyla kaldırıldı",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Kullanıcıyı silmek istediğinizden emin misiniz?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}}, {{azuresubscription}} 'dan başarıyla kaldırıldı",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Azure aboneliği başarıyla kaydedildi",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Fatura durumu başarıyla güncellendi",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Sipariş başarıyla verildi. OrderId: {{orderId}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Şifre Alıcıları",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Alıcıların E-postası",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Virgülle ayrılmış E-posta alıcılarını girin",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Yeni varlık ekle",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"yönetme",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Sunmak",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Yeni Varlık Ekle",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"İşletme Kuruluşları",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Varlık türü",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"ad",
      "DAKİKA":"Dakika (lar)",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Şartlar ve Koşullar Kabul Günlüğü",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Faturayı Görüntüle",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"<b> {{BillingStartDate}} </b> 'den <b> {{BillingEndDate}} </b>' e kadar <b> {{InvoiceGeneratedDate}} </b> fatura dönemi için oluşturulan yeni fatura > {{INVOICEAMOUNT}} </ b>.",
      "DASHBOAR_TEXT_DATE":"tarih",
      "DASHBOAR_TEXT_EMAIL":"E-posta",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"Ezidebit Anlaşma URL'si",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"Microsoft Müşteri Sözleşmesi URL'si",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"İş Ortağı Şartlar ve Koşulları URL'si",
      "DASHBOAR_TEXT_TIME":"Zaman",
      "Dashboard":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Müşteri Analizi",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Raporlar",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Lisans Özet Raporu",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Kullanım Raporu",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Profili Güncelle",
      "DASHBOARD_CAPTION_PARAGRAPH":"Bulutun gücü ile başarılı bir iş yürütmek için kaynaklar sağlayın.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Cloud Market Place'e Hoş Geldiniz",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft,",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Herşey",
      "DASHBOARD_COST_TEXT":"Maliyet",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Bölüm Sayısı",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Bölümler",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Bölümler",
      "DASHBOARD_HEADER_TEXT_WARNING":"Uyarı!",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Lisanslama portalınıza hoş geldiniz",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Aboneliklerinizi ve kullanıcılarınızı yönetmek için aşağıdaki kutucukları hızlı bağlantılar olarak kullanın.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Bu portal, kiracınıza yeni kullanıcılar eklemenize, lisans atamanıza ve şifrenizi sıfırlamanıza olanak tanır.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Ek lisanslar ve abonelikler satın alabilirsiniz.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Profilinizi güncelleyin (Fatura adresi ve Ödeme yöntemi)",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Site sayısı",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Siteler",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Siteler",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Azure Abonelikleri",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"{{StartDate}} 'dan Mevcut Azure Kullanımı",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Ürün",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Lisans Abonelikleri",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Daha fazla göster",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Ofis 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Portal'da oturum açın",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Kullanıcılar",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Daha fazla göster",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Başarısız oldu",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"Devam etmekte",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Son senkronizasyon {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"<b> {{BillingStartDate}} </b> 'den <b> {{BillingEndDate}} </b>' e kadar <b> {{InvoiceGeneratedDate}} </b> fatura dönemi için oluşturulan yeni Fatura > {{INVOICEAMOUNT}} </ b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"faturayı görüntülemek için. Zaten ödenmişse yoksay.",
      "DASHBOARD_WARNING_MESSAGE":"C3'ü kullanmadan önce ödeme profilinizi güncellemelisiniz. Güncellemek için lütfen aşağıdaki düğmeyi tıklayın.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Ürün Açıklaması",
      "DEACTIVATE_CONFORMATION":"Devre dışı bırakmak istediğinizden emin misiniz?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"Müşteri Ürünü Devre Dışı Bırakılamadı",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Devre Dışı Bırakılan Müşteri Ürünü",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Bu kullanıcının bu teklifi kaldırma izni yok",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü silinirken hata oluştu",
      "DELETE_CUSTOMER_FAILURE":"Müşteri Adı Güncelleme başarısız",
      "DELETE_CUSTOMER_SUCCESS":"Müşteri Adı Güncelleme başarılı",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"E-posta bildirimi alıcıları silinirken hata oluştu",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Seçilen fatura düzenlemesi silinecek. Devam etmek istiyor musun?",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları silinirken hata oluştu",
      "DELETE_PARTNER_USER":"İş Ortağı Kullanıcısını Sil",
      "DELETE_PARTNER_USER_FAILURE":"Ortak kullanıcı silinemedi",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"Bu kaydı silmek istediğinizden emin misiniz?",
      "DELETE_USER_EXCEPTION":"Bu kullanıcının C3'e biraz erişimi var, bu yüzden lütfen bunları kaldırın",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Müşteri Para Birimi",
      "DESC_PLAN_CURRENCY_OPTION":"Para Birimi Planla",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Bize Ulaşın",
      "Description":"Açıklama",
      "DESCRIPTION_FOR":"Açıklaması",
      "Devam etmekte":"Devam etmekte",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Engelli Eski ve Etkin Yeni Güvenli Model",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Onay için teşekkürler. Uygulama, yeni güvenlik yönergelerini izleyerek İş Ortağı Merkezinize erişecektir.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Eski Devre Dışı Bırakma ve Yeni Güvenli Modeli Etkinleştirme Başarısız Oldu",
      "DISP_CATEGORY_COUPON_ENTITY":"Kategori",
      "DISP_PROVIDER_COUPON_ENTITY":"Sağlayıcı",
      "DISP_SALETYPE_COUPON_ENTITY":"SaleType",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Sıfırla",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Pozitif bir değer girin",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Kategori",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Aksiyon",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Faturalandırma türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Kategori",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Faturalandırma Dönemi Türü",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"ad",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"ad",
      "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"İptal etmek istiyor musun?",
      "DOCUMETATION_HEADING":"belgeleme",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Para Birimi Kodu",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Fatura tutari",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Nitelikli Ad",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Satış Tipi",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"durum",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"uygulanabilirlik",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Atandı",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Kupon Kodu",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"İndirim",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Tarihinde sona eriyor",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"ad",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Ödeme şekli",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Etiketler",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Kiracılar",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Dönüşüm oranı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Fatura numarası",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"ad",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"miktar",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Abonelik Adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Vergi kodu",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Birim fiyat",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Koltuk alımı için faturalandırma kuralı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Koltukların azaltılması için faturalandırma kuralı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Faturalandırma Türü",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Tüketim türleri",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Maliyet fiyatı",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"ad",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Satış ücreti",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Satış Tipi",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"durum",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Açıklama",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Makro Değeri",
      "DOWNLOAD_COLUMN_PLAN_NAME":"ad",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Faturalandırma Türü",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Kategori",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Makro Değeri",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Plan Adı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Plan Adı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Hizmet Türleri",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"durum",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Kategori",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Tüketim Türü",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Bölüm Adı",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"İndirim",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Alan adı",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Ürün siparisi",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"miktar",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Site adı",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"durum",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Sağlayıcı Kiracı Kimliği",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Kiracı adı",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Birim fiyat",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Kategori",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Is ortagi maliyeti",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Para Birimi Kodu",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Ürün adı",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Saglayici Adi",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Perakende fiyatı",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Geçerlilik",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Geçerlilik türü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Fatura döngüsü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Para Birimi Kodu",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"müşteri adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Alan adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Yeni fiyat",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Yeni Miktar",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Yeni statü",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Eski fiyat",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Eski Miktar",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Eski Durum",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Ürün adı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Sağlayıcı",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Azure Abonelik Kimliği",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Kiracı Kimliği",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Geçerlilik",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Geçerlilik türü",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"İndir",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Fatura Satır Öğeleri",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Fatura Ödemeleri",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Birden Çok Bağlantı",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Bağlantı Tek",
      "DROPDOWN_PLACEHOLDER_SELECT":"seçmek",
      "DUE_STATUS_DUE":"nedeniyle",
      "DUE_STATUS_FINALIZED":"Faturayı kesinleştirdi",
      "DUE_STATUS_OVERDUE":"Süresi Sonu",
      "DUE_STATUS_PAID":"ödenmiş",
      "DUE_STATUS_PARTIAL_PAID":"Kısmen ödenmiş",
      "DURUM: BEKLEMEDE":"Bekliyor",
      "Düzeltilmeyecek":"Düzeltilmeyecek",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Grubu Düzenle",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Ürün",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"özet",
      "EKİM":"Ekim",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"Müşteri profilinde faturalandırma ilgili kişisi olarak belirtilen e-posta adresi",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"E-posta ile bildirilmesi gereken kişilerin e-posta adreslerinin listesi",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"HelpDesk dışındaki roller",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"BillingContacts",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"İnsanlar",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"rol",
      "EMAIL_TABLE_HEADER_ACTIONS":"Aksiyon",
      "EMAIL_TABLE_HEADER_EMAIL":"E-posta",
      "EMAIL_TEMPLATE_SUBMIT":"Sunmak",
      "EMAIL_TYPE_BILLING":"fatura",
      "EMAIL_TYPE_COMPANY":"şirket",
      "EMAIL_TYPE_INVOICE":"Fatura",
      "EmailConfigurations":"SMTP",
      "ENABLE_RESELLER_PLAN":"Bayiye Plan Ata",
      "ENABLE_RESELLER_PLAN_FAILURE":"Bayiye Plan Atanamadı",
      "ENGAGE_EFFECTIVE_FROM":"İtibaren geçerli",
      "ENGAGE_EXPIRES_ON":"Tarihinde sona eriyor",
      "ENGAGE_HEADER_ACTIONS":"Aksiyon",
      "ENGAGE_HEADER_ACTIONS_BACK":"Listeye geri dön",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "ENGAGE_HEADER_EXPIRES_ON":"Tarihinde sona eriyor",
      "ENGAGE_HEADER_MESSAGE":"İleti",
      "ENGAGE_HEADER_TITLE":"Başlık",
      "ENGAGE_SUBMIT":"Sunmak",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Lütfen QuickBooks'a göndermek için en az bir fatura seçin.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"QuickBooks'a fatura gönderme isteğiniz kabul edilir ve işleme koyulur. Devam eden güncellemeler için lütfen <strong> Yazı Günlüklerini Görüntüle </strong> düğmesini tıklayın.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Faturaları çevrimiçi QuickBooks'a yükleme isteği sıralanamadı",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Faturaları Xero'ya yükleme isteği sıralanamadı",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Talimatlar",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Aksiyon",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Tamamlayınız",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Oluşturma Tarihi",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Bölüm",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Bölüm Adı",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"ad",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Satış ücreti",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"yer",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Site adı",
      "ENTITY_DESC_CART":"Araba",
      "ENTITY_DESC_COUPON":"Kupon",
      "ENTITY_DESC_CURRENCY":"Para birimi",
      "ENTITY_DESC_CUSTOMER":"Müşteri",
      "ENTITY_DESC_DEPARTMENT":"Bölüm",
      "ENTITY_DESC_INVOICE":"Fatura",
      "ENTITY_DESC_PLAN":"Plan",
      "ENTITY_DESC_PRODUCT":"Ürün",
      "ENTITY_DESC_SHOP":"Dükkan",
      "ENTITY_DESC_SITE":"yer",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Şartlar ve koşullar",
      "ENTITY_NAME":"Varlık adı",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"ülke",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"E-posta",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"durum",
      "ERROR__DATA_CONTACT_TYPE":"İletişim Türü",
      "ERROR__DATA_PAYMENT_TYPE":"Ödeme şekli",
      "ERROR__DATA_PROFILE_TYPE":"Profil Tipi",
      "ERROR__DATA_URL":"URL",
      "ERROR_BUTTON_CONTINUE":"Devam et",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Müşteri Ürün Kimliği",
      "ERROR_C3_ENTITY_NAME":"Varlık adı",
      "ERROR_C3_RECORD_ID":"Kayıt Kimliği",
      "ERROR_C3_USER_ID":"Kullanıcı kimliği",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"Faturalandırma Müşteri Kimliği",
      "ERROR_DATA_BILLING_REFERENCE_ID":"Faturalandırma Referans Kimliği",
      "ERROR_DATA_C3_CUSTOMER_ID":"Müşteri C3 Kimliği",
      "ERROR_DATA_C3_RECORD_ID":"Kayıt Kimliği",
      "ERROR_DATA_C3_RESELLER_ID":"Bayi C3 Kimliği",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"C3 Abonelik Kimliği",
      "ERROR_DATA_COUNTRY_CODE":"Ülke kodu",
      "ERROR_DATA_CUSTOMER_NAME":"müşteri adı",
      "ERROR_DATA_DOMAIN_PREFIX":"Alan adı",
      "ERROR_DATA_EMAIL_ID":"Email kimliği",
      "ERROR_DATA_ENTITY_NAME":"Varlık adı",
      "ERROR_DATA_EVENT_NAME":"Etkinlik adı",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "ERROR_DATA_PROVIDER_NAME":"Sağlayıcı Adı",
      "ERROR_DATA_RESELLER_ID":"Referans Kimliği",
      "ERROR_DATA_RESELLER_MPNID":"Bayi Sağlayıcı Kimliği",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"Bayi Sağlayıcı Kimliği",
      "ERROR_DATA_SEARCH_VALUE":"Arama Değeri",
      "ERROR_DATA_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "ERROR_DATA_TAX_CODE":"Vergi kodu",
      "ERROR_DATA_TRANSACTION_REFERENCE":"İşlem referansı",
      "ERROR_DATA_USER_ID":"Kullanıcı kimliği",
      "ERROR_DELETE_DEPARTMENT":"Bu departman ilişkili kayıtları içerdiğinden bu kaydı silemezsiniz.",
      "ERROR_DELETE_SITE":"Bu site ilişkili kayıtları içerdiğinden bu kaydı silemezsiniz.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Sağlayıcıda kiracı oluşturulamıyor.",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Authorize.net kişisinden yanıt yok",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Müşteri bulunamadı.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Bu ürün abonelikleriniz arasında bulunamadı",
      "ERROR_DESC_BAD_INPUT":"Girdilerinizde bir sorun var gibi görünüyor",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"Kötü yönetim nedeniyle müşteri yönetimi başarısız oldu.",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu indirilirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"Kötü giriş nedeniyle müşteri getirilemedi.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Hatalı girdi nedeniyle ödeme profillerinin alınamadı.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Hatalı giriş nedeniyle ödeme durumu alınamadı.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu alınırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"Ödeme profili eksik olduğu için işlem başarısız oldu.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"Ödeme profili yapılandırması eksik olduğu için işlem başarısız oldu",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Ödeme profiliniz kaydedilirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Ödeme profillerinizle ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Faturalandırma sağlayıcınızla ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Ödeme profilinizi kaydetmeyle ilgili bir sorun var, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Lisans tüketim raporu alınırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Sağlayıcı bulunamadı",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Hizmet uygulanmadı. Lütfen desteğe başvurun.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Faturalandırma sağlayıcısı için desteklenen ödeme türlerinizle ilgili bir sorun var gibi görünüyor, lütfen destekle iletişim kurun.",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Faturalandırma müşterisi oluşturulurken / güncellenirken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Bu müşteri tarafından zaten kullanılan bu abonelik",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Müşteri silinirken bir hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Resminizi yüklerken bir hata oluştu. Lütfen resim boyutlarını bir kez daha kontrol edin.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Hatalı istek nedeniyle başarısız oldu. Lütfen tekrar deneyin.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Bir çakışma nedeniyle başarısız oldu. Lütfen kimlik bilgilerinizi tekrar kontrol edin",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Hizmet kullanılamıyor. Lütfen yapılandırmayı kontrol edin",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Ödeme başlatılırken hata oluştu, lütfen destek ile iletişime geçin.",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"Kontrol paneli satıcısı AD APP Kimliği veya iş ortağı kiracı kimliği mevcut değil.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Resim yüklenemedi. Lütfen İnternet bağlantınızı kontrol edin ve görüntü boyutunun verilen teknik özelliklere uygun olduğundan emin olun.",
      "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Geçersiz Müşteri",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"İş Ortağı Merkezi istisnası, abonelik durumunu değiştirmeye çalışırken ortaya çıktı. Lütfen desteğe başvurun.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"MPN ID doğrulanırken iş ortağı merkezinden hata. Lütfen girişinizi tekrar kontrol edin.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"İş Ortağı Merkezi'ne bağlanırken bir sorunla karşı karşıyayız. Lütfen yöneticinin belirteci yenilemesine izin verin ve tekrar deneyin. Daha fazla yardım için desteğe başvurun.",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Kullanıcı oluşturulurken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Kullanıcı silinirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Tüm müşterileri almaya çalışırken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Ülke doğrulama kurallarını almaya çalışırken Ortak Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Müşteri almaya çalışırken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS İş Ortağı Merkezi, profilinizi almaya çalışırken bir hata verdi.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Yenileme belirteci getirilemedi.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Kiracı kullanıcısı alınırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Kullanıcıya atanan lisanslar alınırken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Kullanıcı ayrıntılarını alırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Kullanıcı rolleri alınırken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Kullanıcıları alırken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Müşteri onayı kaydedilirken İş Ortağı Merkezi'nden hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Kullanıcı güncellenirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Kullanıcı lisansı atamaları güncellenirken iş ortağı merkezinden gelen hata",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Kullanıcı şifresi güncellenirken iş ortağı merkezinden hata",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Müşteri alırken iş ortağı merkezinden gelen hata.",
      "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Alan adı mevcut değil",
      "ERROR_DESC_NOT_FOUND_ERROR":"İstek bulunamadı",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"İsteğiniz işlenirken bir hata oluştu",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"Onboading Geçersiz kiracı nedeniyle başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"Sağlayıcı istisnası nedeniyle müşteri yönetimi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Sağlayıcı istisnası nedeniyle ödeme profili yönetimi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"Ödeme sağlayıcısı portalındaki bir hata nedeniyle kullanıcı silme işlemi başarısız oldu.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Kimliğine sahip bir müşteri bulunamadı",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Sağlayıcı istisnası nedeniyle ödeme profillerinin alınamadı.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Sağlayıcı istisnası nedeniyle ödeme durumu alınamadı.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Faturalandırma müşterisi yönetilirken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Ödeme profili yönetilirken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"İşlem oluşturulurken hata oluştu. Lütfen desteğe başvurun.",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Sahiplik operasyonel varlıklarla paylaşıldığı için ürün durumunu değiştirme izniniz yok",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"Ürün durumunun güncellenmesi tamamen başarılı olmadı, lütfen yardım için destek ekibimizle iletişime geçin",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Vergi kodu zaten var, Lütfen benzersiz bir vergi kodu seçin.",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"İsteğiniz işleme koyulamıyor, lütfen destek ile iletişim kurun",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Bu özelliğe erişme yetkiniz yok",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"Kullanım yeri geçersiz",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Veritabanına erişilirken hata oluştu",
      "ERROR_DETAILS":"Hata detayları",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Bu departman adı bu müşteri ve sitede zaten var.",
      "ERROR_DUPLICATE_SITE_NMAE":"Bu site adı bu müşterinin altında zaten var.",
      "ERROR_FIELD_REQUIRED":"Bu alan gereklidir",
      "ERROR_GETTING_HOSTED_TOKEN":"Barındırılan jeton alınırken hata oluştu",
      "ERROR_GETTING_STRIPE_DETAILS":"Şerit ayrıntıları alınırken hata oluştu",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Bu işlemi gerçekleştirmek için yeterli izniniz yok gibi görünüyor",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Yasak!",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ettiği için ürünün durumu güncellenemiyor. Lütfen daha sonra tekrar deneyin.",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Koltukları azaltma izniniz yok",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Geçersiz Müşteri",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"Kiracı kullanıcı geçerli değil",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"Durum değişikliği geçerli değil.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Bayi ilişkisi talep etmek için mesaj bulunamadı, lütfen C3 desteğine başvurun",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Bu e-postaya sahip kullanıcı zaten var veya bu kullanıcıyı ekleme izniniz yok",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Bu müşteri Microsoft csp ile bağlantılı değil",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"İsteğiniz işleme koyulamıyor.",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Fatura satır öğeleri raporu dışa aktarılırken hata oluştu. Lütfen yöneticiye başvurun",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Fatura özeti raporu dışa aktarılırken hata oluştu.Lütfen yöneticiye başvurun",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Dosya yüklenirken bir hata oluştu",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Resim yüklenirken hata oluştu. Lütfen başka bir resimle tekrar deneyin.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Lütfen ör. .Jpg, .png vb. Resim uzantılarını seçin.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"MPN ID doğrulanırken iş ortağı merkezinden hata. Lütfen girişinizi tekrar kontrol edin.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Kullanıcı oluşturulurken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Kullanıcı silinirken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Kiracı kullanıcısı alınırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Kullanıcı ayrıntılarını alırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Kullanıcı rolleri alınırken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_GET_USERS":"Kullanıcıları alırken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Kullanıcı güncellenirken iş ortağı merkezinden hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Kullanıcı lisansı atamaları güncellenirken iş ortağı merkezinden gelen hata",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Kullanıcı şifresi güncellenirken iş ortağı merkezinden hata",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"İsteğiniz işlenirken bir hata oluştu",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Lütfen müşterinin Microsoft Müşteri Sözleşmesi'ni kabul ettiğini onaylayın",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Teknede / linkte kiraladığınız kiracı müşterilerinizden birinde var. Lütfen başka bir kiracı deneyin.",
      "ERROR_RESELLER_PROVIDER_ID":"Bayi Sağlayıcı Kimliği",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Sağlayıcı kiracısı bulunamadı.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Veritabanına erişilirken hata oluştu",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Lütfen Xero'ya göndermek için en az bir fatura seçin.",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"tamam",
      "ERRORMESSAGEPOPUP_HEADER":"Lütfen aşağıdaki listeye eklentiler için en az ön koşullardan birini ekleyin",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Ayriyeten",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Önkoşul Teklif",
      "ERRORS_FROM_PREVIOUS_ACTION":"Son işleminiz hatalarla sonuçlandı, ayrıntılar için tıklayın ...",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"İşleminiz gerçekleştirilirken bir hata oluştu. Lütfen Destek ile iletişime geçin.",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"E-posta",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"İsim",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Soyadı",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefon numarası",
      "EVENT_DATA_ACCOUNT_NUMBER":"Hesap numarası",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Aksiyon",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Hata",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"durum",
      "EVENT_DATA_ADDRESS_CITY":"Kent",
      "EVENT_DATA_ADDRESS_COUNTRY":"ülke",
      "EVENT_DATA_ADDRESS_STATE":"Durum",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Zip",
      "EVENT_DATA_AGREEMENT":"anlaşma",
      "EVENT_DATA_AGREEMENT_DATE":"Sözleşme Tarihi",
      "EVENT_DATA_AGREEMENT_LINK":"Sözleşme Bağlantısı",
      "EVENT_DATA_AGREEMENT_NAME":"anlaşma",
      "EVENT_DATA_AGREEMENT_TYPE":"Sözleşme Türü",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Aksiyon",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Hata",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"durum",
      "EVENT_DATA_BCC_RECIPIENTS":"BCC Alıcıları",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Fatura Döngüsü Adı",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Ödeme Takma Adı",
      "EVENT_DATA_BILLING_PERIOD_ID":"Faturalandırma Dönemi Kimliği",
      "EVENT_DATA_BILLING_PROVIDER":"Faturalandırma Sağlayıcısı",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Faturalandırma sağlayıcısı müşteri kimliği",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Faturalandırma sağlayıcısı müşteri adı",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Faturalandırma sağlayıcısı satıcı kimliği",
      "EVENT_DATA_BILLING_PROVIDER_USER":"Kullanıcı kimliği",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"Kullanıcı kimliği",
      "EVENT_DATA_CARD_TYPE":"Kart tipi",
      "EVENT_DATA_CART_ID":"Sepet Kimliği",
      "EVENT_DATA_CATEGORY_ID":"Kimlik kategorisi",
      "EVENT_DATA_CATEGORY_NAME":"Kategori adı",
      "EVENT_DATA_CC_RECIPIENTS":"CC Alıcıları",
      "EVENT_DATA_CHANGE_TYPE":"Değişiklik Türü",
      "EVENT_DATA_CLONED_FROM_PLAN":"Klonlandı",
      "EVENT_DATA_COMPANY_NAME":"Şirket Adı",
      "EVENT_DATA_CONSUMPTION_TYPE":"Tüketim Türü",
      "EVENT_DATA_CONTRACT":"sözleşme",
      "EVENT_DATA_CONVERSION_RATE":"Dönüşüm oranı",
      "EVENT_DATA_COUNTRY_CODE":"Ülke kodu",
      "EVENT_DATA_COUPON_CODE":"Kupon Kodu",
      "EVENT_DATA_COUPON_DESCRIPTION":"Kupon Açıklaması",
      "EVENT_DATA_COUPON_DISCOUNT":"İndirim",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Tarihinde sona eriyor",
      "EVENT_DATA_COUPON_VALID_TILL":"Kadar geçerli",
      "EVENT_DATA_CURRENCY_CODE":"Para Birimi Kodu",
      "EVENT_DATA_CUSTOMER_C3_ID":"Müşteri C3 Kimliği",
      "EVENT_DATA_CUSTOMER_NAME":"müşteri adı",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Müşterinin Yeni Adı",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Müşterinin Eski Adı",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Müşteri Referans Kimliği",
      "EVENT_DATA_DEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Fatura döngüsü",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Açıklama",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"ad",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Maliyet fiyatı",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Geçerlilik",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Geçerlilik türü",
      "EVENT_DATA_DOMAIN_NAME":"Alan adı",
      "EVENT_DATA_EFFECTIVE_FROM":"İtibaren geçerli",
      "EVENT_DATA_EMAIL":"E-posta",
      "EVENT_DATA_EMAIL_ADDRESS":"E-posta",
      "EVENT_DATA_END_DATE":"Bitiş tarihi",
      "EVENT_DATA_ENTITY_NAME":"Varlık adı",
      "EVENT_DATA_ERROR":"Hata",
      "EVENT_DATA_EVENT":"Etkinlik",
      "EVENT_DATA_EVENT_ID":"Etkinlik Kimliği",
      "EVENT_DATA_EVENT_NAME":"Etkinlik adı",
      "EVENT_DATA_EXCEPTION_MESSAGE":"İstisna Mesajı",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Müşteri Kimliği",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Abonelik Kimliği",
      "EVENT_DATA_GROUP_DESCRIPTION":"Grup Açıklaması",
      "EVENT_DATA_GROUP_NAME":"Grup ismi",
      "EVENT_DATA_IMPERSONATOR":"Taklitçi",
      "EVENT_DATA_INPUT_DATA":"Ayrıntı isteyin",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Dahili Müşteri Kimliği",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Dahili Müşteri Ürün Kimliği",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"C3 Ürün Kimliği",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Dahili Abonelik Kimliği",
      "EVENT_DATA_INVOICE_NUMBER":"Fatura numarası",
      "EVENT_DATA_IS_ACCEPTED":"Kabul edildi",
      "EVENT_DATA_IS_ENABLED":"Etkin",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"IsImmediateProvisioning",
      "EVENT_DATA_LINE_ITEM":"Satır Öğesi",
      "EVENT_DATA_LOGGED_IN_USER":"Giriş Yapan Kullanıcı",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Kullanıcı Lisansı Ayrıntılarını Yönetme",
      "EVENT_DATA_MESSAGE":"İleti",
      "EVENT_DATA_Name":"ad",
      "EVENT_DATA_NAME":"ad",
      "EVENT_DATA_NEW_ENTITY_NAME":"Yeni Varlık",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transfer edildi",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"İş Ortağı Teklifi Ekle",
      "EVENT_DATA_NEW_PRICE":"Yeni fiyat",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Bildirim Alıcıları",
      "EVENT_DATA_OFFER_NAME":"Teklif Adı",
      "EVENT_DATA_OLD_ENTITY_ID":"Eski Varlık",
      "EVENT_DATA_OLD_ENTITY_NAME":"Eski Varlık",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Gönderen",
      "EVENT_DATA_OLD_PRICE":"Eski fiyat",
      "EVENT_DATA_PAID_AMOUNT":"Ödenen miktar",
      "EVENT_DATA_PAID_DATE":"Ödenen tarih",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Ana Ürün Adı",
      "EVENT_DATA_PARTNER_NAME":"Ortak adı",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Ödeme Profili Takma Adı",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"Ödeme Profili Kimliği",
      "EVENT_DATA_PAYMENT_TYPE":"Ödeme şekli",
      "EVENT_DATA_PHONE_NUMBER":"Telefon numarası",
      "EVENT_DATA_PLAN_DESCRIPTION":"Açıklama",
      "EVENT_DATA_PLAN_NAME":"Plan Adı",
      "EVENT_DATA_PLAN_TYPE":"Plan Türü",
      "EVENT_DATA_PRICE":"Fiyat",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Ürün C3 Abonelik Kimliği",
      "EVENT_DATA_PRODUCT_NAME":"Ürün adı",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Ürünün Yeni Adı",
      "EVENT_DATA_PRODUCT_PRICE":"Ürün fiyatı",
      "EVENT_DATA_PROVIDER":"Sağlayıcı",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"Sağlayıcı İşletme Kimliği",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Sağlayıcı Müşteri Adı",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Sağlayıcı Varlık Adı",
      "EVENT_DATA_PROVIDER_ID":"Sağlayıcı Kimliği",
      "EVENT_DATA_PROVIDER_NAME":"Sağlayıcı Adı",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Sağlayıcı Ödeme Profili Kimliği",
      "EVENT_DATA_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Azure Abonelik Kimliği",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"Sağlayıcı Satıcı Kimliği",
      "EVENT_DATA_QUANTITY":"miktar",
      "EVENT_DATA_RECORD_ID":"Kayıt Kimliği",
      "EVENT_DATA_RESELLER_NAME":"Bayi Adı",
      "EVENT_DATA_ROLE":"rol",
      "EVENT_DATA_SALE_PRICE":"Satış ücreti",
      "EVENT_DATA_SITE_NAME":"Site adı",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DATA_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "EVENT_DATA_START_DATE":"Başlangıç ​​tarihi",
      "EVENT_DATA_STATUS":"durum",
      "EVENT_DATA_SUB_TAXES":"Alt Vergiler",
      "EVENT_DATA_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Abonelik Adı",
      "EVENT_DATA_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "EVENT_DATA_TAX_CODE":"Vergi kodu",
      "EVENT_DATA_TAX_NAME":"Vergi Adı",
      "EVENT_DATA_TENANT_ID":"Kiracı Kimliği",
      "EVENT_DATA_TENANT_NAME":"Kiracı adı",
      "EVENT_DATA_TO_RECIPIENTS":"Alıcılara",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Kullanım Yeri",
      "EVENT_DATA_USER_DISPLAY_NAME":"Ekran adı",
      "EVENT_DATA_USER_EMAIL":"Kullanıcı e-postası",
      "EVENT_DATA_USER_TYPE":"Kullanıcı tipi",
      "EVENT_DATA_USERNAME":"Kullanıcı adı",
      "EVENT_DATA_VALIDITY":"Geçerlilik",
      "EVENT_DATA_VALIDITY_TYPE":"Geçerlilik türü",
      "EVENT_DATA_VALUE":"değer",
      "EVENT_DATE":"tarih",
      "EVENT_DEPARTMENT_DESCRIPTION":"Bölüm Tanımı",
      "EVENT_DEPARTMENT_ID":"Departman Kimliği",
      "EVENT_DEPARTMENT_NAME":"Bölüm Adı",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Kabul Edilen Şartlar ve Koşullar",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Kabul Edilen Şartlar ve Koşullar Hatası",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Sağlama Özel Teklifini Etkinleştir",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Sağlama Özel Teklif Hatası'nı Etkinleştirin",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Müşteri Oluşturulamadı",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"C3 Müşteri Hatası Ekleme",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"C3 Müşteri Başarısı Ekleyin",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Grup Yöneticisi Ekle",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Grup Yöneticisi Hatası Ekleme",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Otomatik İşlenen Müşteri Ürün Abonelikleri",
      "EVENT_DESC_BULK_USER_UPLOAD":"Toplu Kullanıcı Yükleme",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Toplu Kullanıcı Yükleme Hatası",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Paket Ürün Satın Alma Siparişi",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"İptal Edilen Abonelik Satın Alma İsteği",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"İptal Edilen Abonelik Satın Alma İsteği Hatası",
      "EVENT_DESC_CART_CHECKOUT":"Alışveriş Sepeti Ödeme",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Sepet Ödeme Başarısız Oldu",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Müşteri ürün durumu başarıyla güncellendi",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Ürün durumunu değiştirme başarısız oldu",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"Fatura Durumunu Değiştir",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Fatura Durumunu Değiştirme Hatası",
      "EVENT_DESC_CLONE_PLAN":"Başarıyla klonlanmış plan",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"Plan klonlanamadı",
      "EVENT_DESC_COUPON_NAME":"Kupon Adı",
      "EVENT_DESC_COUPON_STATUS":"Kupon Durumu",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Azure Grupları Oluşturun",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Azure Grupları Hatası Oluşturma",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Azure Aboneliği Oluşturun",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Azure Abonelik Hatası Oluşturma",
      "EVENT_DESC_CREATE_CART":"Sepet Oluştur",
      "EVENT_DESC_CREATE_CART_FAILURE":"Sepet Hatası Yarat",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Müşteri Hatası Yaratın",
      "EVENT_DESC_CREATE_INVOICE":"Fatura oluşturmak",
      "EVENT_DESC_CREATE_ORDER":"Sipariş Yarat",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Sipariş Hatası Yarat",
      "EVENT_DESC_CREATE_TAX_RULE":"Vergi Kuralı Oluştur",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Vergi Kuralı Oluştur",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısı Oluşturun",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"UsageSubscription Kullanıcı Hatası Oluştur",
      "EVENT_DESC_CREATE_USER":"Kullanıcı oluştur",
      "EVENT_DESC_CREATE_USER_FAILURE":"Kullanıcı Hatası Oluştur",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Müşteri silme Hatası",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Müşteri silme Başarısı",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Müşteri Faturası Oluşturuldu",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Müşteri Adı Güncelleme Hatası",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Müşteri Adı Güncelleme Başarısı",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeyi Tanımlama",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Para Birimi Dönüştürme Hatasını Tanımlama",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Plan Fiyatlarını Yeni Para Biriminde Tanımlayın",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Yeni Para Birimi Arızasında Plan Fiyatlarını Tanımlayın",
      "EVENT_DESC_DELETE_CART_ITEM":"Silinmiş Sepet Öğesi",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Alışveriş Sepeti Öğesi Silinemedi",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Para Birimi Dönüştürmeyi Sil",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Para Birimi Dönüştürme Hatasını Sil",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Para Birimi Dönüştürme Silinemedi",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Müşteri ödemelerini sil",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Müşteri ödemesi silinemedi",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Departman Hatasını Sil",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Departmanın Başarısını Sil",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Sil",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimi Silme Hatası",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Fatura Ayarlamasını Sil",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Fatura Ayarlama Hatasını Sil",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Faturaları Sil LineItem",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Faturaları Sil LineItemFailure",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"İş Ortağı Kullanıcı Hatasını Sil",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Ödeme Seçeneğini Sil",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Ödeme Seçeneği Silme Hatası",
      "EVENT_DESC_DELETE_PLAN":"Planı Sil",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Site Hatasını Sil",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Site Başarısını Sil",
      "EVENT_DESC_DELETE_TAX_RULE":"Vergi Kuralını Sil",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Vergi Kuralını Silme Hatası",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısını Sil",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Kullanım Aboneliğini Sil Kullanıcı Hatası",
      "EVENT_DESC_DELETE_USER":"Kullanıcıyı sil",
      "EVENT_DESC_DELETE_USER_FAILURE":"Kullanıcı Arızasını Sil",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Devre Dışı Bırak",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Etkinleştir",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Müşteriye Plan Ata",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Müşteriye Plan Atanamadı",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Toplu Katılım müşterileri için istek kuyruğa alınamadı",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Hata Günlüğü Bildirim Raporu",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Yerleşik Toplu toplu işlem durumu Tamamlandı olarak ayarlanamadı",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Kullanıcı Lisans Ataması toplu durumu Tamamlandı olarak ayarlanamadı",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Lisans Tüketimi Tutarsızlığı",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Portal Arızasına Erişim Verme",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Portala Erişim İzni Ver",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Toplu Kullanıcı Yükleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Toplu Kullanıcı Yükleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Abonelik Satın Alma Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Abonelik Güncelleme Hatalarını Yoksay",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Abonelik Güncelleme Hataları Yoksayma Hatası",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Abonelik Satın Alma Başarısındaki Hatayı Yoksay",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Fatura Ödemesi Başlatıldı",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Bağlantı Faturalandırma Sağlayıcısı Müşteri Hatası",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Bağlantı Faturalandırma Sağlayıcısı Müşteri Başarısı",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Bağlantı Servis Sağlayıcısı Müşteri Hatası",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Bağlantı Servis Sağlayıcı Müşteri Başarısı",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Bağlantı servis sağlayıcısı csp olmayan müşteri hatası",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Bağlantı servis sağlayıcı csp olmayan müşteri başarısı",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Faturalandırma sağlayıcısının müşteri başarısını bağlayın",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Bağlantı faturalandırma sağlayıcısı müşteri hatası",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Bağlantı servis sağlayıcısı müşteri hatası",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Servis sağlayıcı müşteri başarısını bağlayın",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Kullanıcı lisanslarının yönetimi başarısız oldu.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"Kullanıcı lisanslarının yönetimi başarılı oldu.",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"NI Partner ürün satın alma talebi kabul edildi",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"NI Partner ürün satın alma isteği iptal edildi",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"NI Partner ürünü satın alındı",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"NI Partner ürün miktarı güncellendi",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"NI Hazırlama Ürün Satın Alma Etkinleştirme Durumunu Bildir",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"NI Sağlama Ürünü Satın Alma İptal Edildi Durumu Bildir",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Teklifler satın alındı",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Yerleşik müşteri hatası",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Yerleşik Müşteri Hatası",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Yerleşik Müşteri Başarısı",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"İş Ortağı Kullanıcısı Eklendi Hatası",
      "EVENT_DESC_PARTNER_USERADDED":"Ortak Kullanıcı Eklendi",
      "EVENT_DESC_PASSWORD_EXPIRED":"Şifrenin zamanı doldu",
      "EVENT_DESC_PAYMENT_FAILURE":"Ödeme Hatası",
      "EVENT_DESC_PAYMENT_SUCCESS":"Başarılı ödeme",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"İş Ortağı Merkezi güvenlik izni alınamadı",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Kitap Ortağı Merkezi güvenlik onayı başarıyla alındı",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Ürün Atama",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Ürün Atama Ağı Hatası",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Sağlayıcı Ürünleri Fiyatı Güncelleme",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Yeni İş Ortağı Teklifi Satın Alın",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Yeni Özel Teklif Satın Alma Hatası",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"NI Hazırlama Ürünü Satın Alın",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Abonelik Satın Alın",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Abonelik Satın Alma Hatası",
      "EVENT_DESC_RECORD_PAYMENT":"Kayıt Ödeme",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Reddedilen Şartlar ve Koşullar",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Reddedilen Şartlar ve Koşullar Hatası",
      "EVENT_DESC_RESET_USER_PASSWORD":"Kullanıcı Parolasını Sıfırla",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Kullanıcı Parolasını Sıfırlama Hatası",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Kiracıyı Geri Yapılandır",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Kiracıyı Geri Yapılandırma Hatası",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Portala Erişimi İptal Et",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Portal Arızasına Erişimi İptal Et",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Özel Teklifi Kaydet",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Özel Teklif Kaydetme Hatası",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Müşteri Faturalandırma Profili Kaydedilemedi",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Müşteri Faturalandırma Profilini Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Müşteriyi Faturaya Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Faturalandırma Başarısızlığında Müşteriyi Tasarruf Edin",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Müşteri Faturalandırmada Kaydedilemedi",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Müşteri Ödeme Profilini Kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Müşteri Ödeme Profilini Kaydetme Hatası",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Müşteri kullanıcısını kaydet",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Müşteri kullanıcı hatasını kaydetme",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Departman Hatasını Kaydet",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Departman Başarısını Kaydet",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Kaydet",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimini Kaydetme Hatası",
      "EVENT_DESC_SAVE_INVITES":"Davetleri Kaydet",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Davet Kaydetme Hatası",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Fatura Ayarlarını Kaydet",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Fatura Ayarlamalarını Kaydetme Hatası",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"İş Ortağı Paketini Kaydet",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"İş Ortağı Paketi Hatasını Kaydet",
      "EVENT_DESC_SAVE_PARTNER_USER":"Ortak Kullanıcıyı Kaydet",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"İş Ortağı Kullanıcı Hatasını Kaydet",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Ödeme Profilini Kaydet",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Ödeme profili kaydedilemedi",
      "EVENT_DESC_SAVE_PLAN":"Planı Kaydet",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Plan Hatasını Kaydet",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Plan Ürünlerini Hedef Para Biriminde Kaydetme",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Plan Ürünlerini Hedef Para Birimi Arızasına Kaydetme",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Kayıtlı sağlayıcı müşteri onayı",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Sağlayıcı müşteri onayı kaydedilemedi",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Sağlayıcı Ödemesini Kaydet",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Sağlayıcıyı Kaydetme Ödeme Hatası",
      "EVENT_DESC_SAVE_RESELLER":"Satıcıyı Kaydet",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"Bayi Kaydedilemedi",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Site Hatasını Kaydet",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Site Başarısını Kaydet",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Şartlar ve koşul kabulü başarısız oldu",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Şartlar ve koşullar başarıyla kabul edildi",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"Eklenecek / kaldırılacak lisansları hazırlama denemesi başarısız oldu.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Başarılı bir şekilde eklenecek / kaldırılacak lisansları hazırladı.",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Abonelik kullanım eşiği uyarıları gönderme",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Kullanıcı lisans ataması toplu durumunu tamamlanacak şekilde ayarlama",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Abonelik Senkronizasyonu",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Abonelik Senkronizasyon Hatası",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonelik güncellendi",
      "EVENT_DESC_SUCCESS":"başarı",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"sağlayıcı müşteri onayı senkronize edilemedi",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Senkronize sağlayıcı müşteri onayı",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Abonelikleri Bilgisayar Arızasından Senkronize Edin",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Abonelikleri Bilgisayardan Senkronize Etme Başarılı",
      "EVENT_DESC_TAGS_ADDED":"Etiketler Eklendi",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Eklenen Etiketler Hatası",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Etiketler Silinemedi Hatası",
      "EVENT_DESC_TAGS_DELETED":"Etiketler Silindi",
      "EVENT_DESC_TAGS_EDITED":"Etiketler Düzenlendi",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Etiketler Düzenleme Hatası",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Azure Gruplarını Güncelle",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Azure Grupları Hatasını Güncelleme",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Azure Aboneliğini Güncelle",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Azure Abonelik Hatası Güncelleme",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Özel Teklif Miktarını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Özel Teklif Miktarı Arızasını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Güncellenmiş varsayılan ödeme seçeneği",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Varsayılan ödeme seçeneği güncellenemedi",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"CustomerPayment Profilini Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Müşteri Ödeme Profili Hatasını Güncelle",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Güncellenmiş müşteri profili",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Müşteri profili güncellenemedi",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Varsayılan Ödeme Seçeneğini Güncelle",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Varsayılan Ödeme Seçeneği Arızasını Güncelleme",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimini Güncelle",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Etkinlik E-posta Bildirimi Güncelleme Hatası",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Grup Yöneticisini Güncelle",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Grup Yöneticisi Hatasını Güncelle",
      "EVENT_DESC_UPDATE_LICENSE":"Lisansı Güncelle",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Güncelleme Lisans Hatası",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"İş Ortağı Merkezi Ayarlarında Güncelleme Hatası",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"İş Ortağı Genel Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"İş Ortağı Genel Ayarlarını Güncelleme Hatası",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"İş Ortağı Logosunu Güncelleyin",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"İş Ortağı Logo Hatasını Güncelle",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"İş Ortağı Merkezi Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Güncelleme Planı Teklif Fiyatı",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Güncelleme Planı Teklif Fiyat Hatası",
      "EVENT_DESC_UPDATE_PROFILE":"Profili Güncelle",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Profil Hatası Güncelleme",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Sağlayıcı Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Sağlayıcı Ayarları güncellenemedi",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Güncellenmiş Ürün Özellikleri",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Ürün Özellikleri Güncellenemedi",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Miktarı Güncelle NI Hazırlama Ürünü",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Güncellenmiş Servis Sağlayıcı Müşteri Varsayılan Değeri",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Servis Sağlayıcı Müşteri Varsayılan Değeri güncellenemedi",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"SMTP Ayarlarını Güncelle",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"SMTP Ayarlarını Güncelleme Hatası",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Abonelik Hatasını Güncelle",
      "EVENT_DESC_UPDATE_TAX_RULE":"Vergi Kuralını Güncelle",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Vergi Kuralı Arızasını Güncelleme",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Kiracı Yapılandırmasını Güncelle",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"TenantConfiguration Hatasını Güncelle",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Kullanım Aboneliği Kullanıcısını Güncelle",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Güncelleme Kullanım Aboneliği Kullanıcı Hatası",
      "EVENT_DESC_UPDATE_USER":"Kullanıcıyı Güncelle",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Kullanıcı Arızasını Güncelle",
      "EVENT_ENTITLEMENT_NAME":"Yetkilendirme Adı",
      "EVENT_IMPERSONATOR":"Taklitçi",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Bağlantı Satıcısı",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Bayi Bağlanamadı",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Lütfen en az bir alıcı türü seçin.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"E-postayı Önizle",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"E-posta bildirimi başarıyla silindi.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Etkin",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Alıcı Türü",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Alıcılar",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"TO",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"E-posta bildirim ayarları başarıyla kaydedildi.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Lütfen tıklayın",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"e-posta şablonu içeriğini görüntülemek için.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"BCC Alıcıları",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"CC Alıcıları",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Etkinlik",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Etkinlik adı",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Etkin mi?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"Alıcılara",
      "EVENT_PURCHASE_PRODUCT_NAME":"Ürün adı",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"miktar",
      "EVENT_SITE_DESCRIPTION":"Site Açıklaması",
      "EVENT_SITE_ID":"Site kimliği",
      "EVENT_SITE_NAME":"Site adı",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Bayinin Bağlantısını Kes",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Bayi bağlantısı kaldırılamadı",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Faturalandırma döngüsü değişikliği için eşlenmiş ürün yok. Lütfen destek ekibiyle iletişime geçin.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Sağlayıcı Müşteri Sözleşmesi Kabulü İş Ortağı tarafından onaylanmadığı için yeni bir sağlayıcı müşteri oluşturulurken istisna oluştu",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Kullanıcı tarafından kabul edilen iş ortağı şart ve koşulları kontrol edilirken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"İş ortağı şartlar ve koşulları içeriği alınırken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Sağlayıcı müşteri onayı kabul ayrıntısı alınırken hata oluştu",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Sağlayıcı müşteri onayı sağlamayan sağlayıcı müşteriler alınırken hata oluştu",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"İş ortağı şartlar ve koşulları kabul ayrıntıları kaydedilirken hata oluştu",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Sağlayıcı müşteri onayı kaydedilirken hata oluştu",
      "EXISTING_REFRESH_TOKEN":"Mevcut Yenileme Simgesi",
      "EXPAND_ALL_PAYMENTS":"Expand Ödemeler",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Bu kaydı silmek istediğinizden emin misiniz?",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"müşteri adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Müşteri Seçin",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"durum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Sıfırla",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Arama",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Hata",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Başarısız oldu",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"Devam etmekte",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"başarı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Müşteri",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"müşteri adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Fiyat",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Tedarikçi Fiyatı",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"miktar",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"durum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Bölüm",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"yer",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"özet",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Başarısız oldu",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"başarı",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"silindi",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Harici Hizmet posta günlüğü durumu",
      "EXTERNAL_SERVICE_NAME":"Harici Hizmet Adı",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"Harici hizmet sonrası toplu iş kimliği",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Hata başarıyla yoksayıldı",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Harici hizmet Kayıt günlükleri Durum",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Harici Hizmet posta günlükleri",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Talimatlar",
      "EYLÜL":"Eylül",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Not :",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Siparişin faturalandırma döngüsü değiştirilemedi",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Quickbooks'tan alınan kimlik doğrulama koduyla Erişim Jetonları alınamadı",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Xero'dan alınan kimlik doğrulama koduyla Erişim Jetonları alınamadı",
      "FAILED_TO_RESEND_INVOICE":"Fatura yeniden gönderilemedi",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodu kaydedilemedi",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan kimlik doğrulama kodu kaydedilemedi",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"Genel",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Abonelikler altındaki düzenleme işlevini kullanarak tohumları kolayca güncelleyebilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Kullanıcı görünümü altında portal işlevine erişim izni vererek erişim verebilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Evet, portalımızı kullanarak güncelleme yapabilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Evet, c3 kullanarak aboneliği askıya alabilirsiniz.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Abonelik tohumlarını nasıl artırabilirim?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Normal bir kullanıcıya portala nasıl erişim verilir?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Ödeme yöntemini C3 kullanarak güncelleme seçeneği var mı?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. C3'ün abonelikleri askıya alma seçeneği var mı?",
      "FAQ_HEADING":"Sıkça Sorulan Sorular",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Uygulama Genel",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"Şu an itibariyle C3, İngilizce (en-us), İspanyolca (es) ve Taiwanese (tw) tarafından desteklenmektedir. Bunun yanı sıra C3, İş Ortağı Merkezi bölgelerine ve Faturalandırmaya bağlı olarak para birimlerini destekler",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Evet, bu Etiketleri tanımlayarak C3'te gerçekleştirilebilir. Etiketleri tanımlamanız (değer çifti) ve müşterilere atamanız gerekir. Örneğin, değer olarak SupportAgent adlı bir etiketi ve bir aracının (örneğin, Agent001) takma adını tanımlayabilirsiniz. Bir Yardım Masası aracısı, SupportAgent olarak ayarlanmış etiketlerden birine ve Agent001 olarak bu özel etiket için değerlerden birine sahipse, aracı, {SupportAgent, Agent001} etiket değeri çiftine sahip müşterileri görebilir.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"C3'ü etkinlik tabanlı e-postalar gönderecek şekilde yapılandırabileceğiniz Ayar >> E-posta Bildirimi'ne gitmeniz gerekir. C3, e-postaları yapılandırabileceğiniz çeşitli etkinlikler sağlar. Etkinliğe göre önizleme e-postasını da görebilirsiniz. \\ N Herhangi bir teklifin müşteri tarafından satın alındığı bir etkinlik için e-posta bildirimlerini yapılandırabilirsiniz. Rolü veya Faturalandırma kişilerini temel alarak alıcı seçebilirsiniz. Ayrıca, CC listesini ve BCC'yi de seçebilirsiniz. Bu yapıldıktan sonra, böyle bir olay meydana geldiğinde C3, E-posta bildirim ayarında yapılandırılan tüm alıcılara e-posta bildirimleri gönderir.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Vergi Özeti ve Vergi Satır Öğeleri raporlarını İş Ortağı Portalı'nda bulunan Gelir ve Maliyet Özeti kutucuğundan indirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Evet, C3 indirimleri destekler. İndirim kuponları oluşturabilir ve belirli bir plana veya müşterilere de başvurabilirsiniz. Bir kupon için, maksimum itfa, tarihten itibaren geçerli, tarihe kadar geçerli olan ve indirim yüzdesi gibi farklı parametreler tanımlayabilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Markalama için Logoları kullanarak C3'ü özelleştirebilirsiniz. C3, hoş geldiniz sayfasındaki Hoş Geldiniz logosunu ve Oturum Açılan Logosunu biriyle değiştirmenizi sağlar. Marka etkisini daha da artırmak için logonuzu kullanarak bildirim e-postalarını özelleştirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Evet, müşterinin C3 karşılama sayfası yerine giriş sayfasına gelmesi için gerekli değişiklikleri yapabiliriz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Hayır, henüz sertifika almadık. Ancak, verilerinizin güvenliğini sağlamak için aynı düzeydeki yapılandırmayı kullanıyoruz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Evet bunu yapabiliriz. Size bir IP adresi vereceğiz, DNS'inizin yönetildiği yerde bir kayıt oluşturmanız gerekir. DNS sağlayıcınız bunu yapmanıza yardımcı olacaktır.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Hayır, herhangi bir değişiklik durumunda doğrudan İş Ortağı Merkezi veya SMTP kimlik bilgilerini ayarlama ve güncelleme sayfasına gidebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. C3 tarafından hangi tüm diller ve para birimleri desteklenir?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Yanımda kullanıcılar sadece bir dizi müşteriyle ilgilenebilir miyim?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Müşteri tarafından bir satın alma işlemi gerçekleştirildiğinde C3'ü e-posta gönderecek şekilde nasıl yapılandırabilirim? Bu tür e-postaların alıcılarını da nasıl yapılandırabilirim?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Ne tür vergi raporları indirilebilir?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. C3 indirimleri destekliyor mu?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. C3 ne tür özelleştirme sunar wrt görünüm ve his (beyaz etiketleme / markalaşma)",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Müşterilerimin portalımdan C3 karşılama sayfası yerine giriş sayfasına götürecek bir bağlantıyı tıklatmasına izin vermenin bir yolu var mı?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. C3 PCI uyumlu mu?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Tercih edilen alan adımı C3'e bağlayabilir miyim? Bunu yapmak için ne gerekiyor?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. İş Ortağı Merkezim veya SMTP kimlik bilgileri değiştiğinde veya süresi dolduğunda C3 desteğiyle iletişime geçmeli miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING":"fatura",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Şu anda C3, Manuel, Kredi Kartı, ACH (yalnızca ABD müşterileri için) ve Banka hesaplarını (yalnızca Avustralya ve Yeni Zelanda müşterileri için) desteklemektedir.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Evet, müşteriler fatura fatura ortağı tarafından onaylandığında bildirim e-postasında faturayı pdf olarak alır.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"Bu gibi durumlarda, belirli bir faturaya yapılan ödemeyi kaydederek C3'ün müşterileri ücretlendirmesini engelleyebilirsiniz. Gelir ve Maliyet Özeti >> Müşteri Seç >> Faturalar bölümüne gitmeniz gerekir. Buradan, ödemeyi tahsil ettiğiniz faturayı seçmeniz gerekir. Burada Kayıt Ödeme olarak bir seçenek bulacaksınız.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Birden çok faturalandırma döngüsünü desteklemiyoruz. Ancak bu durumda, tüm müşterileri belirli bir faturalandırma döngüsüne hizalayabiliriz. Bu manuel bir işlemdir. \\ N Örneğin, bir müşteri için mevcut faturalandırma döngünüz bu ayın 10'undan sonraki ayın 9'una ve C3'te, faturalandırma döngüsünün bu ayın 1'inden 1'ine kadar. Tüm müşteriler bu ayın 9'una kadar tüm ödemeleri yaptı. 1'den 9'a kadar ilgili tutarı hesaplayacağız ve sorunu çözecek bir sonraki faturada aynı tutarı ayarlayacağız. \\ N Benzer şekilde, diğer faturalandırma döngüsü için de aynısını yapabiliriz. Önümüzdeki aydan itibaren tüm müşteriler aynı fatura döngüsüne göre düzenlenecektir.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Evet, ilgili faturadaki ödemelerle ilgili ayarlamalar yapabiliriz. Şimdilik otomatik bir sürüm yok. Bununla ilgilenebiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Şimdi Bill & Pay, Stripe, EziDebit, Authorize.net, Cybersource'u destekliyoruz. Bunlardan başka birini kullanıyorsanız, ödeme ağ geçidinizin C3 ile entegrasyonunu kesinlikle arayabiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3, şu ana kadar Bill & Pay, Stripe, EziDebit, Authorize.net, CyberSource gibi çeşitli Payments ağ geçitlerini desteklemek üzere tasarlanmıştır. Bunlardan herhangi birini mevcut ödeme ağ geçidiniz olarak kullanıyorsanız harika olur, aksi takdirde Ödeme ağ geçitlerinizi desteklemek için C3 tasarlamaya çalışabiliriz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"C3 tekliflerini her ay Microsoft'tan en son teklifle güncelliyoruz. Fiyat değişikliği durumunda, yeni bir plan oluşturmanız veya mevcut planı yeni teklif fiyatları ile güncellemeniz gerekir. \\ N Varsayılan olarak C3, yeni müşteri için yeni fiyat alır. Ancak mevcut müşteri için, şu anda abone olunan teklif için yeni bir fiyat belirleyebilirsiniz. Ayrıca, fiyat değişikliği, bir sonraki faturalandırma döngüsü veya bir sonraki yenileme tarihi gibi ani etkiler gibi yeni fiyatların geçerlilik tarihini seçebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"C3'te, Vergi kuralları {Ülke, Eyalet Eyaleti ve Vergi Kodu}, {Ülke, Zip} veya {Ülke, Eyalet Eyaleti ve Zip} kombinasyonlarıyla tanımlanır. Örneğin, Ülke - ABD çıkış eyaleti eyaleti ve posta kodu için boş olarak tanımlanan vergi kuralı, vergi oranının ABD'deki tüm müşterilere uygulandığı anlamına gelir. Belirli bir eyalet için kural tanımlamak istiyorsanız, kuralı tanımlarken Ülke ve Eyalet değerlerini belirtin.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Evet, bunu yapabilirsiniz. Ayar >> Vergiler bölümüne gitmeniz ve Vergi yüzdesi% 0 olan bir Vergi kuralı oluşturmanız gerekir. Vergi kuralından muaf tutmak istediğiniz müşteriye uygulayın (diğer müşteri grupları için geçerlidir).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Fatura oluşturma tarihine karar verebilir ve bize bildirebilirsiniz. Bunu sizin için yapılandıracağız ve İş Ortağı Ayarı >> Faturalandırma bölümüne giderek doğrulayabilirsiniz. Burada Billings ile ilgili tüm tarihler var. Hatta müşteri yapılandırmasına giderek belirli bir müşteri için bu tarihi değiştirebilirsiniz.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3, müşterinizin faturayı görebileceği tarihlere karar vermenizi sağlar. Fatura oluşturulduktan sonra, iş ortağının faturayı onaylaması gerekir (gerekirse herhangi bir düzenleme yapmış olabilir).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Evet, C3 size fatura oluşturulduktan sonra faturadaki satır öğelerinde değişiklik yapma seçeneği sunar. Fatura kesinleştirme tarihinden önce gereksiniminize göre gerekli değişikliği (örneğin, bir kerelik ücret / feragat eklemek istiyorsanız) yapmanız gerekir. Sonlandırıldıktan sonra faturada herhangi bir değişiklik yapamazsınız.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"C3'te, bu tür etkinlikler için e-posta bildirimi yapılandırabiliriz. Herhangi bir ödeme başarısı veya başarısızlığı durumunda, C3 bunun için bir e-posta gönderecektir. Bu tür etkinlikler için yapılandırılan tüm alıcılara bildirim e-postaları gönderilir. \\ n Ayrıca C3, Gelir ve Maliyet Özeti altında, İş Ortağının herhangi bir ödeme hatası olayı için kayıtları izleyebileceği bir Ödeme Hatası özelliğine sahiptir.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. C3 tarafından desteklenen ödeme yöntemleri nelerdir?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Müşteri, fatura hazır olduğunda faturanın bir kopyasını pdf olarak alıyor mu?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Ödemeyi çek veya nakit yoluyla topladıysam ve müşterinin ödeme yöntemi kredi kartıysa ne olur? C3'ün müşteriyi mevcut fatura için ücretlendirmesini nasıl önleyebilirim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Kendi faturalandırma sistemlerimizi kullanıyoruz ve bugüne kadar müşterilerimizden ücret aldık. Müşterilerimiz farklı faturalandırma döngülerine sahipti. C3'e taşınırken ne tür bir destek alırız?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Faturaya karşı peşin ödemelerle başa çıkmanın bir yolu var mı?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. C3'ün kullanıma hazır olarak desteklediği ödeme ağ geçitleri nelerdir?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Mevcut ödeme ağ geçidi sağlayıcımı kullanabilir miyim veya C3 ile birlikte gelenlere mi gitmeliyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Fiyatlar Microsoft'tan değiştiğinde ve fiyatlandırmamızı müşterilerimize güncellemek istediğimizde ne olur?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. C3 vergilerle nasıl başa çıkıyor?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Belirli bir müşteriyi, diğer müşteri grupları için geçerli olan vergi kuralından muaf tutabilir miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. C3'ün ne zaman fatura oluşturması gerektiğini nasıl tanımlarım?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Müşterim faturalar oluşturulduktan hemen sonra görecek mi?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Oluşturulduktan sonra faturadaki satır öğelerinde değişiklik yapma seçeneğim var mı? Faturaya bir kerelik ücret ekleyebilir / ilgili değişikliklerden feragat edebilir miyim?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Bir ödemenin ne zaman başarılı veya başarısız olduğunu nasıl anlarım (Ortak)?",
      "FAQ_PARTNER_CATEGORY_SELL":"Satmak",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Plan, müşterinize kendi seçeceğiniz bir fiyattan satabileceğiniz teklifler grubudur. Yerleşik veya müşteri oluşturmak için bir planınız olmalıdır.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Evet, C3 size iş ortağı teklifleri oluşturma özelliği sunar. Örneğin, müşterilerinize 24 * 7 destek vermek istersiniz. Ardından, iş ortağı teklifi 24 * 7 Office365 Desteği oluşturabilir ve kendi seçtiğiniz bir fiyattan satabilirsiniz.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Evet, İş Ortağı Merkezi'nde mevcut herhangi bir müşteriyi C3'e taşıyabilirsiniz. Microsoft Kimliğinizin (İş Ortağı Merkezi'nden alın) & PC'deki abone teklifine karşılık gelen bir teklif almayı planlamanız gerekir. Bunun yanı sıra, C3 size doğrudan C3'ten yeni bir müşteri oluşturma özelliği de sunar (Müşteri oluşturmak için PC'ye gitmeye gerek yoktur).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Bunu başarmak için, böyle bir müşteriyi herhangi bir planla birleştirmemiz gerekir. Teklif miktarını Sıfır olarak koyabilirsiniz. Müşteri başarılı bir şekilde devreye alındığında, söz konusu müşteri için Kullanım Tabanlı Abonelikleri otomatik olarak senkronize eder.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"Bu gibi durumlarda PC ve C3 arasında miktar uyuşmazlığı olacaktır. Ekibimiz bu tür uyumsuzlukları düzeltebilir.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Faturalarda ayarlamalar yapabiliriz, ancak şu andan itibaren C3 geri ödemeleri desteklememektedir.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Evet, C3 müşterilerden kullanımı hesaplar ve veri tüketimine dayanarak faturayı oluşturur.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Evet, gerekli indirim yüzdesi ve maks. Yineleme 3 olarak ayarlanmış bir indirim kuponu oluşturabilirsiniz. Şimdi yeni oluşturulan kuponu belirli bir müşteriye atayın. Bu yapıldıktan sonra müşteri sonraki üç fatura için geçerli indirimi alacaktır.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Plan nedir?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Özel hizmetler gibi Microsoft dışındaki teklifleri satabilir miyim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Mevcut bir müşteriye katılabilir miyim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Yalnızca kullanıma dayalı abonelikleri olan bir müşteriye nasıl katılabilirim?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Ekibim yanlışlıkla PC üzerinden lisans satın aldı, bu tür eylemlerin sonuçları nelerdir? Bu tür tutarsızlıkları gidermek için bir süreç var mı?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. C3 geri ödemeleri destekliyor mu?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. C3, müşterilerimden kullanım için Azure faturalandırmasını ele alıyor mu?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. C3'e katılan bir müşterim var ve sonraki üç fatura için indirim sağlamak istiyorum, bunu yapabilir miyim?",
      "FATURA DONEMİ":"Fatura donemi",
      "FATURA NUMARASI":"Fatura numarası",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"seçmek",
      "FILTER_SELECT_A_TARGET_CURRENCY":"seçmek",
      "FIX_QUANTITY_MISMATCH":"Miktar uyuşmazlığını düzeltin",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Tüm hakları Saklıdır.",
      "FOOTER_TEXT_1_POWERED_BY":"Tarafından desteklenmektedir",
      "GEÇERSİZ DEĞER":"{{atribute}} geçersiz",
      "GEÇERSİZ GİRİŞ":"Geçersiz Giriş",
      "GENEL TOPLAM":"Genel Toplam",
      "General":"Genel",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Quickbooks'tan alınan kimlik doğrulama koduyla oluşturulan Quickbooks Erişim Jetonları",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Xero'dan alınan kimlik doğrulama koduyla oluşturulan Xero Erişim Jetonları",
      "GET_CURRENCY_CODES_EXCEPTION":"Tedavi kodları alınırken hata oluştu",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü alınırken hata oluştu",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Para birimi dönüşümleri listesi alınırken hata oluştu",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Para birimi seçenekleri alınırken hata oluştu",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"E-posta bildirimleri için müşteri etkinlikleri alınırken hata oluştu",
      "GET_CUSTOMER_LIST_EXCEPTION":"Müşteri alınırken hata oluştu",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"E-posta bildirimi alıcıları listesi alınırken hata oluştu",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"E-posta bildirimleri için etkinlikler alınırken hata oluştu",
      "GET_PARTNER_LOGO_EXCEPTION":"İş ortağı logosu alınırken hata oluştu",
      "GET_PARTNER_SETTINGS_EXCEPTION":"İş ortağı ayarları alınırken hata oluştu",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları alınırken hata oluştu",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Vergi türleri alınırken hata oluştu",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Hedef para birimleri alınırken hata oluştu",
      "GOT_RESELLER_DETAILS":"{{ResellerName}} adında CSP satıcısını aldım",
      "GRAND_ACCESS_USER_EXCEPTION":"Kullanılan bu zaten erişim var başka bir kontrol",
      "GRAND_TOTAL":"Genel Toplam",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Ürüne Göre Tüketim",
      "GRAPH_DATA_BILLED_AMOUNT":"Fatura Tutarı",
      "GRAPH_DATA_QUANTITY":"miktar",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Müşteri Geliri",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Bu e-postaya sahip bir grup yöneticisi bu grupta zaten var",
      "GROUP_USERS_OF":"Grup Kullanıcıları",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Grup Kullanıcıları",
      "GÜN":"Gün (ler)",
      "HATA MESAJI":"Bir şeyler yanlış gitti!",
      "HAZİRAN":"Haziran",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Uygulamayı, uygulamanın desteklediği bir dilde görüntüleme",
      "HEADER_CONTENT_HELP_MENU":"Sağ kenar çubuğunda sunulan içeriğe duyarlı yardım içeriği",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Sistemdeki etkinliği izleme",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Oturum açan kullanıcının temel profil ayrıntıları",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Ayarları yönetmenizi veya etkinlik günlüğüne erişmenizi sağlar",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Uygulama düzeyi ayarlarını, e-posta bildirimlerini yönetmenizi, vergileri tanımlamanızı ve daha fazlasını yapmanızı sağlar ...",
      "HEADER_CONTENT_SIGNIN_USER":"Oturum açan kullanıcının temel profil ayrıntıları",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Bize Ulaşın",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Çıkış Yap",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Kullanıcı yetkilendirilmedi",
      "HEADER_GO_TO_PROFILE":"Profile Git",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Bize Ulaşın",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Kimliğe Bürünmeyi Durdur",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Kullanıcı yetkilendirilmedi",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Dil",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Dil",
      "HEADER_TITLE_HELP_MENU":"Yardım",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Bildirimler",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Denetim Günlüğü",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Ayarlar",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Ayarlar",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Ayarlar",
      "HEADER_TITLE_SIGNIN_USER":"Oturum açan kullanıcı",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Test e-postasını almak için e-posta adresini girin.",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"buraya",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Mevcut ayrıcalığınızla birlikte",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Mevcut ayrıcalığınız olmadan",
      "In Progress":"Devam etmekte",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Kayıtlı para birimi yok",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Kayıtlı durum yok",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Lütfen bir ülke seçin",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Lütfen önce bir kaynak para birimi seçin",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Seçilen tüm faturalar ödeme ağları için sıraya alınır. Devam etmek istiyor musun?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"Fatura durumu <% = invoiceStatus%> olarak değişecektir. Devam etmek istiyor musun?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Müşteriye $ <% = miktar%> tahsil edilecektir. Devam etmek istiyor musun?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Satıcıdan $ <% = miktar%> tahsil edilecektir. Devam etmek istiyor musun?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
      "INPUT_CATEGORYID":"Kimlik kategorisi",
      "INPUT_CHANGE_TYPE":"changetype",
      "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "INPUT_CUSTOMERC3ID":"CustomerC3Id",
      "INPUT_CUSTOMERID":"Müşteri Kimliği",
      "INPUT_CUSTOMERNAME":"Müşteri adı",
      "INPUT_DATA":"Kullanıcı girişi",
      "INPUT_DESCRIPTION":"Açıklama",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"InternalProductId",
      "INPUT_INVOICEID":"InvoiceId",
      "INPUT_NAME":"ad",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"Sayfa sayısı",
      "INPUT_PAGEINDEX":"PageIndex",
      "INPUT_PAGESIZE":"Sayfa boyutu",
      "INPUT_PLANC3ID":"kareli",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"PriceforPartner",
      "INPUT_PRODUCTID":"Ürün kimliği",
      "INPUT_PROVIDERID":"ProviderId",
      "INPUT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "INPUT_QUANTITY":"miktar",
      "INPUT_REACTIVATE":"yeniden etkinleştirmek",
      "INPUT_SALETYPE":"SaleType",
      "INPUT_STARTIND":"StartInd",
      "INPUT_SUSPEND":"askıya almak",
      "INPUT_TENANTID":"TenantId",
      "INPUT_WITHADDONS":"WithAddons",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Ödeme şekli",
      "INTERNAL_BILLING_ID":"Faturalandırma Referans Kimliği:",
      "INVALID_ATTEMPT_DECREASE":"Azalan miktara izin verilmez",
      "INVALID_ATTEMPT_REACTIVATE":"Ürünün yeniden etkinleştirilmesine izin verilmiyor",
      "INVALID_ATTEMPT_SUSPEND":"Ürünün askıya alınmasına izin verilmiyor",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Davete Geri Dön",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Davet Ekle",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Daveti Düzenle",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Hepsini temizle",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Seçilen öğeler",
      "INVITES_ADD_FORM_LABEL_OFFER":"Teklifler",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Fiyat",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Açıklama",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"ad",
      "INVITES_ADD_FORM_URL_INVITE_URL":"URL davet et",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Davet etmek",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Yeni davet ekle",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"Davet etmek",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Düzenle",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Davetiye Açıklaması",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Davetiye adı",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "INVITES_SAVE_SUCCESS":"Davetiye başarıyla kaydedildi",
      "INVITES_SELECT_OFFERS_PROMPT":"Lütfen davetiyeye en az bir teklif ekleyin",
      "INVITES_UPDATE_SUCCESS":"Davetiye başarıyla güncellendi",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Bitiş tarihi",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Başlangıç ​​tarihi",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"Bunun bu faturada bir düzenleme olarak yapılmasını istiyor musunuz?",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Gösterge Paneli",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Faturalar",
      "INVOICE_COMMENTS":"Fatura",
      "INVOICE_COUPON_APPLIED_MESSAGE":"İndirim kuponu <strong> {{CouponCode}} * </strong> uygulandı",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Hepsini seç",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"E-posta başarıyla kullanıcı listesine gönderilir",
      "INVOICE_FOOTER_LINE_DISP":"Fatura Altbilgisi Satırı",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Fatura Altbilgisi Satırı",
      "INVOICE_GENERATION_STATUS_TITLE":"Fatura Üretim Durumu",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gelir ve Maliyet Özetine Git",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Faturaları / Ödemeleri İndirin",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gelir ve Maliyet Özetine Geri Dön",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Faturaları / Ödemeleri İndirin",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Müşterilerin faturaları başarıyla içe aktarıldı",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Lütfen devam etmeden önce en az bir müşteri faturası seçin.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Müşteriler için indirilecek faturalar",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Para Birimi Kodu",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Nitelikli Ad",
      "INVOICE_INVOICE_APPROVE":"onaylamak",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Fatura satır öğelerine dön",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Faturalara Geri Dön",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Faturayı İndir",
      "INVOICE_INVOICE_CAPTION_TEXT":"İçin fatura",
      "INVOICE_INVOICE_FINALIZE":"Sonuçlandırmak",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Hesap özeti",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adres",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Müşteri",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Fatura özeti",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"Fatura donemi",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Fatura tarihi",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Bitis tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Fatura Ayrıntısı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Lisans Tabanlı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Kullanım Bazlı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Şarj türü",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"İş Ortağı Maliyeti",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Kupon Kodu",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"İndirim",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"İndirim tutarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Teklif fiyatı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Bitiş tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Genel Toplam",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Fatura Satırı Tutarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Ayrıntılar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Vergi Sonrası Tutar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Ara Toplam Miktar",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Kiraci",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Başlangıç ​​tarihi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Vergi miktarı",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Birim fiyat",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Fatura bulunamadı. Lütfen c3 desteğine başvurun.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Fatura Ödemeleri",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Miktar",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"tarih",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Arıza Nedeni",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Genel Toplam",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Uyarılar",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"durum",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Bitis tarihi",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Fatura tarihi",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"durum",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Kalan miktar",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Kullanılan Miktar",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Düzeltmeler",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Döngü Örneği Oranı",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Geçmiş Faturalar Aidatları",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Oran Ücreti",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Satın Alma Sırasında Prorate Ücretleri",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Satın Alma Ücreti",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"İade Tutarı",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Yenileme ücreti",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Vergiler",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Kullanım Miktarı",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Kredi",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b> {{sağlayıcıAdı}} Kiracı </b> {{sağlayıcıTanrıAdı}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Dönüşüm oranı",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Aksiyon",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"E-posta",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"İptal etmek",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Faturalar",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Ödemeler",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Bitis tarihi",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Tarihinde fatura oluşturuldu",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Arıza Nedeni",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Kalan miktar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Ödenen miktar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Ödeme tarihi",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"İşlem Kimliği",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Uyarılar",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Ödeme Durumu",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Kullanılan Miktar",
      "INVOICE_NUMBER":"Fatura numarası",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Müşteriler ödemeleri başarıyla içe aktarırken faturalandırıyor",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Müşteriler için indirilecek fatura ödemeleri",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"Başlangıç ​​Tarihi Bitiş Tarihinden büyük olmamalıdır",
      "INVOICE_SENT_SUCCESSFULLY":"Fatura başarıyla gönderildi",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Müşteri incelemesi için onaylandı",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Faturayı kesinleştirdi",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"Devam etmekte",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Oluşturulan Fatura",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Fatura Yayımlandı",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Ücretli tam tutar",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Ücretli kısmi tutar",
      "INVOICE_TAB_NAME_COMMENTS":"Yorumlar",
      "INVOICE_TAB_NAME_DETAILS":"ayrıntılar",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Bildirimler",
      "INVOICE_WILLBE_MAILED":"Fatura, fatura adresinize gönderilecektir. Teşekkür ederim.",
      "INVOICES_LBL_OWNED_BY":"<b> Sahip olunan </b> {{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Fatura donemi",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Fatura tutarı",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Fatura ödeme tarihi",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Yalnızca İş Ortağı Tarafından Yönetilebilir",
      "IS_PRODUCT_BEING_UPDATED_WITH_ADDONS":"Ürün Eklentilerle güncelleniyor mu?",
      "İSİM GİRİN":"ad",
      "kararlı":"kararlı",
      "KASIM":"Kasım",
      "LABEL_ASSIGNED_RG":"Atanan Kaynak Grupları",
      "LABEL_BANNER_TEXT":"İleti",
      "LABEL_BILLING_ADDRESS":"Fatura Adresi",
      "LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "LABEL_EFFECTIVE_TO":"Tarihinde sona eriyor",
      "LABEL_IS_ACTIVE":"Aktif",
      "LABEL_NOTIFICATION_RECIPIENTS":"Bildirim Alıcıları",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Kullanıcıları virgülle ayrılmış değerler olarak girin",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Şunun için Politika Ata:",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Politika Atamaları",
      "LABEL_POLICY_CUSTOMER":"Müşteri",
      "LABEL_POLICY_DEFINITION":"Politika Tanımı",
      "LABEL_POLICY_DESCRIPTION":"Politika açıklaması",
      "LABEL_POLICY_NAME":"Poliçe ismi",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "LABEL_POLICY_SUBSCRIPTION":"abone",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"özel rol ekle",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Özel Rollere Geri Dön",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Özel Rol ekle",
      "LABEL_ROLE_CUSTOMER":"Müşteri",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "LABEL_ROLE_ROLE_DEFINITION":"Rol Tanımı",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Rol Tanımı",
      "LABEL_ROLE_ROLE_NAME":"Rol ismi",
      "LABEL_ROLE_SUBSCRIPTION":"abone",
      "LABEL_SHIPPING_ADDRESS":"teslimat adresi",
      "LABEL_TEXT_ADDONS":"Eklentileri",
      "LABEL_TEXT_ADDRESS_1":"Adres 1",
      "LABEL_TEXT_ADDRESS_2":"Adres 2",
      "LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "LABEL_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "LABEL_TEXT_CATEGORY":"Kategori",
      "LABEL_TEXT_DISCOUNT":"İndirim",
      "LABEL_TEXT_LINKED_PRODUCT":"Bağlantılı Ürün",
      "LABEL_TEXT_PRICE":"Fiyat",
      "LABEL_TEXT_RETAIL_PRICE":"Perakende fiyatı",
      "LABEL_TEXT_STATE_PROVINCE":"Eyalet Eyaleti",
      "LABEL_TEXT_STATUS":"durum",
      "LABEL_TEXT_TAX_CODE":"Vergi kodu",
      "LABEL_TEXT_TAX_TYPE":"Vergi Türü",
      "LABEL_TEXT_TENANT_NAME":"Kiracı adı",
      "LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "LABEL_TEXT_ZIP_CODE":"Posta kodu",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Yeni",
      "LABEL_UNASSIGNED_RG":"Atanmamış Kaynak Grupları",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Ek ücretler",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Müşteri Yöneticisi",
      "LABLE_CUSTOMER_READER_ROLE":"Müşteri Okuyucu",
      "LABLE_DISCOUNTS_TOTAL":"Toplam indirim",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Toplam Abonelik Sayısı",
      "LABLE_TAXES_TOTAL":"Toplam Vergi",
      "LABLE_TEXT_COST_PRICE":"Maliyet fiyatı",
      "LABLE_TEXT_COST_TO_PARTNER":"Is ortagi maliyeti",
      "LABLE_TEXT_DISPLAY_NAME":"Ekran adı",
      "LABLE_TEXT_EVENTS":"Etkinlikler",
      "LABLE_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "LABLE_TEXT_MAXVALUE":"Maksimum değer :",
      "LABLE_TEXT_MINVALUE":"Minimum Değer:",
      "LABLE_TEXT_OFFER_NAME":"Teklif Adı",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Tedarikçi Satış Fiyatı",
      "LABLE_TEXT_SALE_PRICE":"Satış ücreti",
      "LABLE_TEXT_SALEPRICE":"Satış ücreti :",
      "LABLE_TEXT_SLAB":"Döşeme Detayları",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Is ortagi maliyeti",
      "LABLE_TEXT_TYPE":"Faturalandırma Türü",
      "LABLE_WAIVER":"Feragat",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Oturum aç",
      "LAYOUTLOGIN_HEADER_TEXT":"Oturum aç",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Devam etmek için lütfen giriş yapınız",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Spektra Sistemleri. Tüm hakları Saklıdır.",
      "LBL_COUPON_CODE_TEXT":"Kupon Kodu",
      "LBL_DISCOUNT_TEXT":"indirim",
      "LBL_TEXT_NOT_PROVIDED":"Sağlanmadı",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Lütfen aşağıdaki hataları bulun.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Önkoşullar karşılanmadığından bu işlem tamamlanamaz. Bir eklentinin atanması için bir temel teklif gerekir ve bir temel teklifi iptal etmek için eklentilerin yayınlanması gerekir.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Önkoşullar karşılanmadığından bu işlem tamamlanamaz. Bir eklentinin atanması için bir temel teklif gerekir ve bir temel teklifi iptal etmek için eklentilerin yayınlanması gerekir.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Teklif",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Ön Koşul Teklifleri",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"müşteri adı",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Yeni statü",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Eski Durum",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Ürün",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Bayi Adı",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilmiş",
      "LICENSE_CONSUMPTION_REPORT":"Lisans tüketim raporu",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"seçmek",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Müşteri",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Kiraci",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Lütfen müşteri seçin",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Lütfen abonelik adını girin",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Lütfen kiracı seçin",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filtreler",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Atanan Miktar Farkı",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"C3 olarak mevcut",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"PC'de Mevcut",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"C3'te Atanan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"C3'teki Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Müşteri",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"E",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Toplam Miktar Farkı",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Haritalı Ürünler",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Sağlayıcıda Atanan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Sağlayıcıdaki Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Sağlayıcıda Kullanılmayan Lisanslar",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"abone",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Kiraci",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Detayları göster",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Lisans Tüketim Raporu",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"İhracat Lisansı Tüketim Raporu CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Kiracılar",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Lisans Özetini Alma",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Lisans Özet Raporunu İndirin",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Lisans Özeti",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Bayi Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Ana Ürün Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Üst Abonelik Adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Saglayici Adi",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Sağlayıcı Kiracı Kimliği",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"miktar",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"ad",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Lisans Tüketimi",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Kullanılmayan koltukları serbest bırakın",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Kullanılmayan Koltukları Serbest Bırak",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Kullanılmayan koltukların serbest bırakılması. Lütfen bekle...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"atanan",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Haritalı Ürünler",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Toplam",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adları",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"kullanılmamış",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"atanan",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Geçerli",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Mevcut",
      "LICENSES_TOOLTIP_MS_USER":"Lisanslar",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Kullanıcısının Fatura Profiline Bağlantı",
      "LINK_CUSTOMER_HEADER_TEXT":"Sağlayıcısına bağlantı",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Mevcut tüm Sağlayıcılara bağlısınız",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"müşteri tipi",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"müşteri tipi",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Kiracı Kimliği",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Kiracı adı",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Microsoft csp olmayan müşteriyi c3'e başarıyla bağladı.",
      "LINK_RESELLER_CAPTION_TEXT":"Bağlantı Satıcısı",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Sağlayıcı",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Bayi Kimliği (sağlayıcıdaki gibi)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Sil",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Aksiyon",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Kartvizit",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Saglayici Adi",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Sağlayıcı Satıcı Kimliği",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Tüm sağlayıcı profillerinize bağlısınız.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"{{SağlayıcıAdı}} bağlantısı kaldırıldı.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"{{SağlayıcıAdı}} ile başarıyla bağlantılı.",
      "LINK_TEXT_CLICK_HERE":"Buraya Tıkla",
      "linkCustomerBillingProfile":"Fatura Profiline Bağlantı",
      "LINKED_PRODUCT_INFO_SHOPE":"Bu ürün ile bağlantılı",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"seçmek",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Bir ürüne bağlantı",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Alan kullanılabilirliğini doğrulama",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Kategori",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Sağlayıcı",
      "LOADING_INDICATOR_MESSAGE":"İşleme...",
      "LOADING_MESSAGE":"Yükleniyor...",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Kimliğe bürünme işleminizi tamamlamak için bize biraz zaman verin",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Selamlar,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Şimdi giriş yaptınız. Uygulamayı erişebilmeniz için bize bir dakika verin ...",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Tedarikçi Satış Fiyatı",
      "MAIL_SENT_SUCCESSFULY":"Posta başarıyla gönderildi",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Hayır",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Evet",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Lisans atama talebiniz onaylandı. Güncellemeler için lütfen bir süre sonra bu sayfayı ziyaret edin.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Lütfen kiracıdaki kullanıcıların geçerli e-posta adreslerini girin",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"{{Username}} adlı kullanıcının {{Subscriptionname}} için zaten bir lisansı var",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Lütfen en az bir dosya seçin",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Hataları yok saymak istediğinizden emin misiniz?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Bir aboneliğe karşı minimum koltuk sayısı 1 olmalıdır. Kullanılabilir koltuk sayısı 1 ise koltuk serbest bırakılamaz.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Kullanılmayan koltukları serbest bırakmak istediğinizden emin misiniz?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Kullanılmayan lisanslar başarıyla serbest bırakıldı",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Lütfen kaldırılacak Lisanslar grubuna en az bir kullanıcı ekleyin",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Ekle düğmesine tıklamadan önce lütfen önerilen listeden kullanıcı seçin",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"Lisans ataması başarıyla tamamlandı",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Dosya işlenirken bir hata oluştu, lütfen tekrar deneyin",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Lisans ayrıntısı başarıyla yüklendi. Durumu kontrol etmek için lütfen bir süre sonra bu ekrana dönün.",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Teklifleri Görüntüle)",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Evet",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Liste fiyatını satış fiyatına kopyalayın - satış fiyatı Liste fiyatı ile aynı olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Liste fiyatına% X ekle - satış fiyatı Liste Fiyatı + Liste fiyatı% X olacak",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Fiyatı Y sent artırın - satış fiyatı Liste Fiyatı + Y sent olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Seçilen tüm teklifleri temizle fiyatı",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"İşaretlemeye% X ekleyin - satış fiyatı Liste Fiyatı + (ERPFiyat-Liste Fiyatı) * X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"MS fiyatına% X ekle",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"satış fiyatı MS Fiyatı + MS fiyatının% X'i olacak",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"MS fiyatını satış fiyatına kopyalayın",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"satış fiyatı MS fiyatı ile aynı olacaktır",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"İşaretlemeye% X ekle",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"satış fiyatı MS Price + (ERPPrice-MS Price) olacak * X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Fiyatı Y sent artırın",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"satış fiyatı MS Fiyat + Y sent olacak",
      "MANAGE_PLAN_LBL_1":"Liste fiyatını satış fiyatına kopyala",
      "MANAGE_PLAN_LBL_2":"Liste fiyatına% X ekle",
      "MANAGE_PLAN_LBL_3":"Fiyatı Y sent artırın",
      "MANAGE_PLAN_LBL_4":"Tüm fiyatları temizle",
      "MANAGE_PLAN_LBL_5":"İşaretlemeye% X ekleyin",
      "MANAGE_PLAN_LBL_6":"ERP fiyatını satış fiyatına kopyalayın",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Kapat",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Dahil Olan Teklifler",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Kapat",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"ad",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Tüm eklentileri silmek istediğinizden emin misiniz?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Bu ürünü silmek istediğinizden emin misiniz?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Eklentiler plana eklenir, lütfen işleminizi tamamlamak için planı kaydedin.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"plana eklendi!",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"eklentilerle plana eklendi",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Eşleşen eklenti bulunamadı",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Teklifi kaldırmak istiyor musunuz?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"Eklemeye çalıştığınız eklenti zaten dahil edildi",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Tüketim türleri",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Abonelik durumu",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Satış Fiyatı Liste Fiyatından daha az olduğu için lütfen aşağıdaki teklifleri kontrol edin.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Plan değişikliklerini göndermek için 'Tamam'ı tıklayın. 'İPTAL' plan tekliflerini yeniden kontrol etmek için.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"Seçilen teklif zaten dahil edildi",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Plana dahil olan teklifler için lütfen Kolay Ad ve Etkili Fiyat verin",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Lütfen plana teklifler ekleyin",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Daha Fazla Eklenti Ekle",
      "MANAGE_PRODUCT_OWNERSHIP":"Ürün sahipliğini yönetme",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Ürün Sahipliğini Yönetme Başarısız",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Ürünlerden biri veya eklentileri için lisans ataması devam ettiği için ürün sahipliğini yönetmeye izin verilmiyor. Lütfen daha sonra tekrar deneyin.",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"İsteğiniz işlenirken hata oluştu",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Temiz Fiyatlar",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"MS fiyatını bayi fiyatına kopyalayın - bayi fiyatı MS fiyatıyla aynı olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"MS fiyatına% X ekleyin - Bayi fiyatı MS Fiyat + MS fiyatının% X'i olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Fiyatı Y sent artırın - bayi fiyatı MS Fiyat + Y sent olacaktır",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Seçilen tüm teklifleri temizle fiyatı",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"İşaretlemeye% X ekleyin - bayi fiyatı MS Price + (ERPPrice-MS Price) * X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Bayi tekliflerini eklemek istiyorsanız aşağıdaki talimatları uygulamanız gerekir",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Seçilen öğeler",
      "MANAGE_RELLER_OFFERS_LBL_1":"MS fiyatını bayi fiyatına kopyalayın",
      "MANAGE_RELLER_OFFERS_LBL_2":"MS fiyatına% X ekle",
      "MANAGE_RELLER_OFFERS_LBL_3":"Fiyatı Y sent artırın",
      "MANAGE_RELLER_OFFERS_LBL_4":"Tüm fiyatları temizle",
      "MANAGE_RELLER_OFFERS_LBL_5":"İşaretlemeye% X ekleyin",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Filtrelenen tekliflerin tümünü seçilen tekliflerden kaldırmak istiyor musunuz?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Seçilen tekliflerin tümünü kaldırmak istiyor musunuz?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Filtrelenen tüm teklifleri seçmek ister misiniz?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Tüm teklifleri seçmek ister misiniz?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Kadar geçerli",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Lisans türü",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Bayi Fiyatı",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"İptal etmek",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Sunmak",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Bitiş tarihi",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"miktar",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Teklifler",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Bayiler",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Bayi Teklifleri Ekleyin",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Teklifler",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Lütfen seçilen teklifler için Etkili Fiyat verin",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Teklifler başarıyla kaydedildi",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Lütfen satıcıya teklif ekleyin",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Tüm Teklifler Seçildi. Herhangi bir plan teklifiyle ilgili bir şey düzenlemek istiyorsanız, düzenleme planını gözden geçirmeniz gerekir!",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Bayi Planları Listesi",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Plan durumu başarıyla güncellendi",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Atamak",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"ad",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Tarihinde sona eriyor",
      "manageplans":"Planları Yönet",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"Kaynak Grubunu kaldırmak istiyor musunuz?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Müşteri'ye Ödeme Ayrıntılarını güncelleme yetkisi verin.",
      "MANUAL_SYNC_PUPUP_TITLE":"Manuel Senkronizasyon",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Düzeltme başarıyla uygulandı",
      "Markup":"Biçimlendirme",
      "MART":"Mart",
      "MAYIS":"Mayıs",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Bildirimler",
      "MENU_AZURE_BILLING_REPORT":"Azure Faturalandırma Raporu",
      "MENU_AZURE_CUSTOM_POLICY":"Özel Politikalar",
      "MENU_AZURE_CUSTOM_ROLE":"Özel Roller",
      "MENU_AZURE_MARGIN":"C3 Desteği",
      "MENU_AZURE_SUBACRIPTION":"Azure Aboneliği",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft,",
      "MENU_CONTACT_LOG":"Bildirimler",
      "MENU_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "MENU_CUSTOMER_ANALYTICS":"Müşteri Analizi",
      "MENU_CUSTOMER_COMMENTS":"Yorumlar",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lisans Tüketimi",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Power BI Raporu",
      "MENU_CUSTOMER_PROFILE":"Profil",
      "MENU_DASHBOARD":"Gösterge Paneli",
      "MENU_HELP":"Yardım",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Lisans Tüketimi Özet Raporu",
      "MENU_LICENSE_SUMMARY_REPORT":"Lisans Özet Raporu",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"İş Ortağı Teklifleri Sağlama",
      "MENU_PARTNER_AUDIT_LOG":"Denetim Günlüğü",
      "MENU_PARTNER_COUPON":"Kuponlar",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "MENU_PARTNER_SUPPORT_LOG":"Destek",
      "MENU_PARTNER_UPLOAD":"Yükleme",
      "MENU_PRODUCT_SEQUENCE":"Ürün siparisi",
      "MENU_REPORT_USAGE":"kullanım",
      "MENU_REPORTS_FOR_CUSTOMER":"Raporlar",
      "MENU_RESELLER_PLAN":"Bayi Planı",
      "MENU_RESELLER_PROFILE":"Organizasyon Profili",
      "MENU_TOPBAR_SETTINGS":"Ayarlar",
      "MENU_USAGE_REPORT":"Kullanım Raporu",
      "MENU_USERS":"Kullanıcılar",
      "MENUS_ANALYTICS":"analitik",
      "MENUS_ANALYZE":"çözümlemek",
      "MENUS_AZURE_ESTIMATE":"Azure Tahmini",
      "MENUS_AZURE_GROUPS":"Azure Grupları",
      "MENUS_AZURE_MANGE":"Azure Yönetimi",
      "MENUS_AZURE_REPORTS":"Azure Raporları",
      "MENUS_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "MENUS_CART":"Araba",
      "MENUS_COUPONS":"Kuponlar",
      "MENUS_CUSTOM_OFFERS":"Ortak Teklifleri",
      "MENUS_CUSTOMER_CART":"Araba",
      "MENUS_CUSTOMER_DASHBOARD":"Gösterge Paneli",
      "MENUS_CUSTOMER_MICROSOFT":"Microsoft,",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Kullanıcılar",
      "MENUS_CUSTOMER_ONBOARDING":"Müşteri Katılımı",
      "MENUS_CUSTOMER_ORDERS":"Emirler",
      "MENUS_CUSTOMER_PRODUCTS":"Ürün",
      "MENUS_CUSTOMER_PROFILE":"Profil",
      "MENUS_CUSTOMER_SHOP":"Dükkan",
      "MENUS_DAAS":"DaaS <sup class = 'metin tehlikesi'> BETA </sup>",
      "MENUS_DOCUMENTATION":"<Sup class = 'text-danger'> BETA </sup> belgeleri",
      "MENUS_ENTITY_INVOICES":"Faturalar",
      "MENUS_FAQ":"SSS",
      "MENUS_INVITES":"Davet etmek",
      "MENUS_INVOICES":"Faturalar",
      "MENUS_LICENSE_CONSUMPTION":"Lisans Tüketimi",
      "MENUS_LICENSE_LIST":"Lisans Listesi",
      "MENUS_MS_OFFERS":"MS Teklifleri",
      "MENUS_OPERATING_ENTITIES":"İşletme Kuruluşları",
      "MENUS_ORDERS":"Emirler",
      "MENUS_PARTNER_BUNDLES":"Paketler",
      "MENUS_PARTNER_CUSTOMERS":"Müşteriler",
      "MENUS_PARTNER_DASHBOARD":"Gösterge Paneli",
      "MENUS_PARTNER_PLANS":"Planlar",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"iş",
      "MENUS_PARTNER_SETTINGS":"Ayarlar",
      "MENUS_POWER_BI_REPORTS":"Power BI Raporları",
      "MENUS_PRODUCTS":"Ürün",
      "MENUS_PROFILE":"Profil",
      "MENUS_RESELLER_DASHBOARD":"Gösterge Paneli",
      "MENUS_RESELLER_INVOICES":"fatura",
      "MENUS_RESELLER_PAYMENTS":"Ödemeler",
      "MENUS_RESELLER_PLANS":"Planlar",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Fiyatlandırma ve teklifler",
      "MENUS_RESELLER_PROFILE":"Organizasyon Profili",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"iş",
      "MENUS_RESELLER_SETTINGS":"Ayarlar",
      "MENUS_RESELLERS":"Bayiler",
      "MENUS_SELL":"Satmak",
      "MENUS_SELL_DIRECT":"Doğrudan Satış",
      "MENUS_SELL_INDIRECT":"Dolayli Satis",
      "MENUS_SELLER_INDIRECT":"Dolayli Satis",
      "MENUS_SHOP":"Dükkan",
      "MENUS_SUBSCRIPTIONS":"Abonelikler",
      "MENUS_SUPPORT":"Destek",
      "MENUS_TAXES":"Vergiler",
      "MENUS_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "MESSAGE_NO_RECORDS_FOUND":"<img src='../../../img/no result @ 32.png' /> Kayıt bulunamadı!",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"Geçerli faturalandırma döneminde kullanılmadığından veya Microsoft'un faturasında henüz sunulmayan veriler mevcut değil. Lütfen kısa süre sonra tekrar kontrol edin.",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Düzenle",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Aksiyon",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Tarihinde sona eriyor",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"Kayıt bulunamadı!",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Maalesef, yapılandırılmış siteleriniz ve bölümleriniz yok. Profil Sayfasından yeni işletme varlıkları ekleyebilirsiniz",
      "MEVCUT":"Mevcut",
      "Microsoft":"Microsoft,",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Kategori",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"İndirim",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategori",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"İndirim",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Biçimlendirme",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Yüzde",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Bağlantılı Ürün",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Kabul Eden",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA Kabul Tarihi",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Microsoft müşteri sözleşmesi başarıyla kaydedildi.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Kullanıcı adı",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} of {{applicationName}}, Microsoft Cloud hesabınız için Microsoft Cloud Çözüm Sağlayıcınız olmak istiyor.<br/><br/>Daveti kabul etmek için aşağıdaki 2 adımı uygulayın:<br/><br/>Adım 1: Müşteri profilinin burada eksiksiz olduğundan emin olun ve kontrol edin: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Adım 2: Müşteri profili tamamlandıktan sonra (profil güncellendikten sonra yenilenmesi en fazla 5 dakika sürebilir), bu daveti kabul etmek ve {{applicationName}}'i Microsoft Cloud Çözüm Sağlayıcınız olmaya yetkilendirmek ve Microsoft Müşteri Sözleşmesini kabul etmek için aşağıdaki bağlantıya tıklayın Sözleşme.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Not: İlişkiyi kabul etmek için Global Admin iznine sahip kullanıcı gereklidir.<br/><br/><br/>Ek ortak bilgileri:<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Değerli müşterimiz,<br/><br/>Size bulut çözümleri, hizmetleri ve desteğinin tam yelpazesini sunmak için {{resellerName}}, {{partnerCompanyName}} ile yakın bir şekilde çalışmaktadır. {{resellerName}} ve {{partnerCompanyName}}'in hesabınıza hizmet vermesini yetkilendirmek için aşağıdaki 2 adımı uygulayın: <br/><br/>1. Adım: Müşteri profilinin burada eksiksiz olduğundan emin olun: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>2. Adım: Müşteri profili tamamlandıktan sonra (profil güncellendikten sonra yenilenmesi en fazla 5 dakika sürebilir), bu bağlantıyı tıklayarak kabul edin davet.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Not: İlişkiyi kabul etmek için genel yönetici iznine sahip bir kullanıcı gereklidir.<br/><br/>{{resellerName}} ürünler, abonelikler, lisanslar ve destek için yerel başvuru uzmanınız olmaya devam edecektir. Sizinle olan iş ilişkilerinin aynı kalacağından emin olabilirsiniz.<br/><br/>Sorularınız varsa lütfen bizimle iletişime geçmekten çekinmeyin. Biz ve {{resellerName}} sizinle çalışmaktan mutluluk duyuyoruz!<br/><br/>Saygılarımızla,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Mevcut tüm lisanslar ve portala erişim kaldırılacaktır. Devam etmek istediğine emin misin?",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Taşıma hesabı kullanıcı adıyla başarıyla oluşturuldu:",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Taşıma Hesapları",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Müşteri",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Taşıma Hesabı",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Oluşturmak",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Taşıma Hesapları",
      "MIGRATION_USER_CREATE_SUCCESS":"{{Username}} ile taşıma hesabı başarıyla oluşturuldu",
      "MISSING_PLAN_OFFER_ADD_FAILED":"Mutabakat başarısız oldu, lütfen yardım için desteğe başvurun",
      "MISSING_PLAN_OFFER_ADDED":"Seçilen teklif kullanılarak mutabakat tamamlandı",
      "MODAL_HEADER_TEXT_DESC_FILTER":"filtre",
      "Month(s)":"Ay (lar)",
      "MULTIPLE_COMMENT_STATIC":"Yorumlar",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"seçmek",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Talimatlar",
      "Name":"ad",
      "NCE_PROMOTIONS_POPUP_CANCEL":"İptal etmek",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Kapat",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"İndirim",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Bitiş tarihi",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Uygulamak",
      "NEW_COMMENT_SEND":"Gönder",
      "NEW_PRODUCT_STATUS":"Yeni statü",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Arama ...",
      "NİSAN":"Nisan",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Sepette {cartLineItemId} kimliğine sahip öğe yok",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Mevcut plan yok",
      "NO_PAYMENT_TYPES_ENABLED":"Hiçbir ödeme türü etkinleştirilmedi. Lütfen desteğe başvurun.",
      "NO_PRODUCT_SELECTED_PROMPT":"Lütfen en az bir ürün seçin.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Seçilen fatura dönemi veya sağlayıcı için fatura ayrıntısı bulunamadı",
      "NO_RESOURCE_GROUP_LEFT":"Atanacak kaynak grubu kalmadı / Kayıt bulunamadı!",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"CSP Olmayan Ürün Detayları",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Hizmet Asıl Kimliği",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Hizmet Asıl Anahtarı",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Abonelik Adı",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"Bayi kullanıcısı bayi planı kapsamında iş ortağı ürünü ekleyemez.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Aşağıdaki sağlayıcı ürünleri plan ürünlerle eşleştirilmez, devam etmek istiyorsanız devam et'i tıklayın iptal tıklayın ve bu ürünleri eşleyin",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Sağlayıcı Ürünleri",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Bu değer kısa süre içinde güncellenecek",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Bir e-posta adresi girin ve seçilen fatura dönemi için fatura PDF'lerinin listesini içeren bir zip dosyası almak için Tamam'ı tıklayın.",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"{{EmailAddress}} adresine kısa bir süre içinde fatura PDF'lerinin (Zip) bir listesini içeren bir e-posta gönderilecektir.",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
      "OCAK":"Ocak",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Teklif Kolay Adı {{FriendlyName}} benzersiz olmalıdır",
      "offers":"Teklifler",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonelikler",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Abonelik Ekle",
      "OFFERS_HEADER_TEXT_OFFERS":"Teklifler",
      "OFFERS_NO_MATCH_MESSAGE":"İsim için eşleşen sonuç bulunamadı:",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Seçilen öğeler",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"İndirim Yüzdesi",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Miktar (Lisans Sayısı)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Koltuk başına ücret (Aylık)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Toplam",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Toplam tutar",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Bir müşteriye toplu olarak yerleşik olarak yerleştirme başarısız oldu",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Bir müşteriye, toplu olarak yerleşik başlatıldı",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Bir müşteriye toplu yerleşik başarı altında katılım",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/> <p> Faturalandırma Sisteminde Kimlik: <strong> {{billingProviderReferenceID}} </strong> ile bir eşleşme bulundu. <br/> <span> Dosyadaki Fatura Adresi <strong> {{formattedAddress}} < / strong>. </span> <br/> Müşteri faturalandırma bilgileri başarıyla eklendiğinde bu ayrıntıyı değiştirmek için lütfen kimliğine bürünün ve <strong> Profil </strong> seçeneğini kullanın.",
      "onboarding":"Onboarding",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Kapat",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Bayiler",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Sıfırla",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Arama",
      "ONBOARDING_DURATION_UNIT_MONTH":"Ay",
      "ONBOARDING_DURATION_UNIT_YEAR":"Yıl",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"MicrosoftNonCSP müşterisine alışmak desteklenmez. Lütfen müşteriyi mevcut bir C3 Müşterisine bağlayın.",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Sağlayıcı müşteri ürünlerini plan ürünleriyle eşleme ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Planlar yükleniyor ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Sağlayıcı müşterileri yükleniyor ...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Müşteri detaylarını c3'te aramak ...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Tüm Müşteriler",
      "ÖNCE":"Önce",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Silmek istediğine emin misin?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Listeden bir ürün seçin veya ürün adına göre arayın",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"Kullanıcılardan lisans kaldırma isteği kabul edilir ve işlenir.",
      "Open":"Açık",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Plan Adı",
      "OPERATING_ENTITY_CUSTOMER":"Müşteri",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Atamak",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Serbest bırakmak",
      "ORDER_DETAILS_ENTITY_NAME":"Varlık adı",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"Sipariş verildi",
      "ORDER_ID":"Sipariş Kimliği",
      "ORDER_LIST_STATUS_SUCCESS":"başarı",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Müşteri incelemesi için onaylandı",
      "ORDER_STATUS_DELETED":"silindi",
      "ORDER_STATUS_DESC_ACCEPTED":"Kabul edilmiş",
      "ORDER_STATUS_DESC_FAILED":"Başarısız oldu",
      "ORDER_STATUS_DESC_IGNORE":"Göz ardı etmek",
      "ORDER_STATUS_DESC_INPROGRESS":"Devam etmekte",
      "ORDER_STATUS_DESC_ORDERED":"Siparis verildi",
      "ORDER_STATUS_DESC_PROCESSING":"İşleme",
      "ORDER_STATUS_DESC_REJECTED":"Reddedildi",
      "ORDER_STATUS_DESC_SUCCESS":"başarı",
      "ORDER_STATUS_ERRORS_IGNORED":"Başarısız <br/> (hatalar gözden geçirildi ve yoksayıldı)",
      "ORDER_STATUS_FAILED":"Başarısız oldu",
      "ORDER_STATUS_INPROGRESS":"Üzerinde çalışıyor",
      "ORDER_STATUS_ORDERED":"Siparis verildi",
      "ORDER_STATUS_PROCESSING":"Üzerinde çalışıyor",
      "ORDER_STATUS_PROVISIONING":"Üzerinde çalışıyor",
      "ORDER_STATUS_REJECTED":"Reddedildi",
      "ORDER_STATUS_SUCCESS":"Sipariş yerine getirildi",
      "ORDER_STATUS_SUSPENDED":"Askıya alındı",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"Üst teklif etkin değilse Eklenti etkinleştirilemez.",
      "PARENT_PRODUCT_NOT_EXISTS":"Ebeveynsiz bir ürüne binemezsiniz!",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Bu sağlayıcı için kullanılabilir anahtar yok",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Yapılandırılmış sağlayıcı yok, Lütfen Destek ile İrtibata Geçin",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Değişikliklerinizi Kaydedin",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Fatura Para Birimi",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makro Değeri",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"ad",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Satın Alma Para Birimi",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"seçmek",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Atamak",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"ad",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Eklenti bulunamadı",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Planı Sil",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Plan Tekliflerini Gözden Geçirin",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Planı Kaydet",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"miktar",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"kullanım",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"pakete eklendi",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"eklentilerle pakete eklendi",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"zaten pakete eklendi ve tekrar eklenemez",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filtreler",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Sıfırla",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Arama",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Geçerlilik",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Faturalandırma türleri",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Döşeme verileri",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":"- - -",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Geçerlilik türü",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Lütfen en az bir ürün seçin.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Lütfen birlikte kulüplere katılmak için en az 2 teklif seçin",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Lütfen satış fiyatını belirtin",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı!",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Paket Ürünler",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Paketler",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Paket Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Paket Ayrıntılarına Dön",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Sil",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Düzenle",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Uygulanan Arama:",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Sonraki",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Paket Ürünleri İnceleyin",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Paketi Kaydet",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Teklifleri Göster",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"İş Ortağı Paketlerine Geri Dön",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Paket Ekle",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Paketi Görüntüle",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Önerilen Fiyat:",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Otomatik Yenilenebilir mi?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Faturalandırma Türü",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Paketlemeye hazır mısınız?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Şarj Süresi",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Şarj Süresi",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Tüketim Türü",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Yardım Metni",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Maliyet fiyatı",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Açıklama",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Feed Kaynağı",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Hemen tüketime hazır mı?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"ad",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Ücretsiz iptal için gün sayısı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Koltuk alımı için faturalandırma kuralı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Koltukların azaltılması için faturalandırma kuralı",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Miktar değişiklikleri orantılı veya yeni miktar için tam ücret",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Sağlayıcı",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Satış ücreti",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Satış Tipi",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Geçerlilik",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Ürün Arama",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"miktar",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Açıklama",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"ad",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Teklif Ayrıntıları",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Görünüm",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Kategoriler",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Sağlayıcıları",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Hizmet türleri",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Is ortagi maliyeti",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Müsteriye maliyet",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Ödemeler",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Geçmiş bulunamadı!",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "PARTNER_COMMENTS_DETAILS":"Yorumlar",
      "PARTNER_COMMENTS_TAB_INVOICE":"Fatura",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Ürün",
      "PARTNER_CONSENT_HELP_TEXT":"Yukarıdaki düğmeyi tıklayarak bağlantıyı kopyalayın ve yeni gizli pencerede açın ve onay akışını tamamlayın.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Lütfen döşeme için Fatura Türü'nü seçin",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Ekle",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Sil",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"tamam",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"E-posta",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"ad",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefon numarası",
      "PARTNER_CUSTOMER_FILTER_NAME":"ad",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Sağlayıcı Müşteri Kimliği",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Herhangi bir sağlayıcı kiracısına bağlı müşteri yok.",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Atamak",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Devre Dışı",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"etkinleştirme",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Müşterilere geri dön",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Plan değiştir",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Şunun İçin Planları Yönet:",
      "PARTNER_CUSTOMER_REPORTS":"Raporlar",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"müşteri etiketi başarıyla güncellendi",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Plan durumunu güncellemek istediğinizden emin misiniz?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Üzgünüz, müşteri bulunamadı",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Alacak hesapları",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Geçmiş Ücretler",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Karlılık",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Aktif müşterileriniz",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Müşteriler",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"ResellerId: {{resellerId}}, billingPeriodId: {{billingPeriodId}} için bayi faturası ayrıntıları bulunamadı.",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Tarihinde fatura oluşturuldu",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Net kazanç",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Diğer ücretler",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Kar",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Fatura Tutarı",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"MSFT Faturası",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"İşletme gelirinize hızlı bir bakış",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Karlılık",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Alacak hesaplarınızın özeti",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Alacak hesapları",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Henüz tahsil edilmeyen ödemeler",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Geçmiş Ücretler",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Faturalar hazır olana kadar görüntülenecek veri yok",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Aktif Ortaklar sizinle çalışıyor",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Bayiler",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Maliyet",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Müşteriler",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonelikler",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Faturalar",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"Bayiler",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_LOGOS_LABEL_TEXT":"Logo Seçin",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Önizleme resmi",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Güncelleme miktarı",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"İndirim",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Kategori",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"İndirim",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"ad",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Birim fiyat",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Eklenti",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Teklifi Kaldır",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Pozitif bir değer girin",
      "PARTNER_OFFER_SLAB_DATA":"Döşeme verileri",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Aksiyon",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"E-posta",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"İptal etmek",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Sunmak",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"onaylamak",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Kategoriler",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Hizmet Türleri",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"durum",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Birim fiyat",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"reddetmek",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Değişiklikleri Gönder",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Durum başarıyla güncellendi",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategoriler",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Klon planı başarıyla tamamlandı",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Makro Değeri",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Planı yeni para biriminde tanımla",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"ad",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Para Birimi Kodunu Planlama",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Ürün adı",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Kaynak Para Birimi",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Hedeflenen Para Birimi",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Hedef para birimi yok, lütfen tanımlamak için iş ortağınızla bağlantı kurun.",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makroyu Tanımla ve Tüm Tekliflerle Kaydet",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"İptal etmek",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klon Planı",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Tüm Tekliflerle Planı Kaydet",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Klon Planı",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATEGORYID":"Kimlik kategorisi",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "PARTNER_PRODUCT_DESCRIPTION":"Açıklama",
      "PARTNER_PRODUCT_NAME":"ad",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"PriceforPartner",
      "PARTNER_PRODUCT_ProviderId":"ProviderId",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "PARTNER_PRODUCT_SALETYPE":"SaleType",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Para birimi",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"müşteri adı",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Açıklama",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Ürün adı",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Serbest bırakmak",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Bu alan gereklidir.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Kategori",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Maliyet",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Açıklama",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"notlar",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Fiyat",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Müşterilere geri dön",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "PARTNER_RESELLER_FILTER_NAME":"ad",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Değişikliklerinizi Kaydedin",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Temel Detaylar",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Açıklama",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"ad",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"seçmek",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aksiyon",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"İptal etmek",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Sil",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Düzenle",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"E-posta",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Alıcı Türü",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Alıcılar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Kayıt etmek",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"TO",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Alıcılar",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Alıcılar",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aksiyon",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Raporlar",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"tarih",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"ayrıntılar",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"durum",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Lütfen {{taxType}} için en az bir vergi adı ve yüzde girin",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Lütfen geçerli vergi yüzdesini girin",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Lütfen alanların vergi adını doldurun",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Kaydedilmemiş alt vergileriniz var, devam etmek istiyor musunuz?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimi",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"iş ortağı etiketi başarıyla silindi",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"iş ortağı etiketi başarıyla güncellendi",
      "PARTNER_TABLE_HEADER_NAME":"ad",
      "PARTNER_TABLE_HEADER_VALUE":"değer",
      "PARTNER_TERMS_OF_USE":"İş ortağı kullanım şartları",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Para birimi",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Açıklama",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Sunmak",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Ay",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Toplam tutar",
      "PARTNER_USER_ALREADY_EXECUTE":"İş ortağı kullanıcısı bu e-postada zaten var.",
      "PARTNER_USER_REMOVED":"Ortak kullanıcı başarıyla kaldırıldı",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Sil",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Etiket Anahtarı",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Etiket Değerleri",
      "PARTNER_USERS_ADD_SUCCESS":"{{Username}} kullanıcısına başarıyla erişim verildi",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"{{Useremail}} e-posta adresine sahip bir kullanıcı sistemde zaten var",
      "PARTNER_USERS_REMOVE_SUCCESS":"İş ortağı kullanıcısı {{username}} başarıyla kaldırıldı",
      "PARTNER_USERS_SAVE_SUCCESS":"İş ortağı kullanıcısı {{username}} başarıyla güncellendi",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Lütfen devam etmek için bir rol atayın",
      "partnerauditlog":"Denetim Günlüğü",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Ortak kullanıcı başarıyla güncellendi",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Ortak Kullanıcılara Geri Dön",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Ekle",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Düzenle",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Ortak Kullanıcı",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"E-posta",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Etiketi Yönet",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Etiket Anahtarı",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Etiket Değerleri",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Parola en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter içermelidir.",
      "PAYMENT_LABEL_FILTER":"Tarafından filtre",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Ödeme profili Varsayılan olarak ayarla",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Ödeme profili silindi",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Kart numarası",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Ödeme ayrıntıları kaydedilemedi",
      "PAYMENT_PROFILE_SAVE_SUCCESS":"Ödeme ayrıntıları kaydedildi",
      "PAYMENT_PROFILE_TEXT_FAILURE":"başarısızlık",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"başarı",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Kabul edilmiş",
      "PAYMENT_STATUS_DESC_CHARGED":"yüklü",
      "PAYMENT_STATUS_DESC_DECLINED":"Reddedilen",
      "PAYMENT_STATUS_DESC_FAILED":"Başarısız oldu",
      "PAYMENT_STATUS_DESC_INPROGRESS":"Devam etmekte",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Kısmen ödenmiş",
      "PAYMENT_STATUS_DESC_SUCCESS":"Başarılı",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Teknik hata",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Güncelleme",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Bu alan gereklidir.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Hesap numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Takma ad",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"İşletme Hesabı mı?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"şirket",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"bireysel",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Hayır",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Evet",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Kontrol etme",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Sona Erme Ayı",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Sona Erme Yılı",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUINT":"Hesaptaki İsim",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"Tasarruf",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kredi kartı",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Lütfen sayfa yüklenirken bekleyin",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Fatura tutari",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Şirket Adı",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Ödeme Durumu",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Uyarılar",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Tarafından ödenen",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Arıza İşlemleri",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Bekleyen ödemeler",
      "PAYMENTS_STATUS_DESC_FAILED":"Başarısız oldu",
      "PAYMENTS_STATUS_DESC_SUCCESS":"başarı",
      "PAYMENTS_TABLE_HEADER_AMONT":"Ödenen miktar",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Fatura tutari",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Arıza Nedeni",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Fatura donemi",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Teslim Tarihi",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Fatura Bazlı mı?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Tarafından sahip olunan",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Ödenen miktar",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Ödenebilir miktar",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Ödeme Kimliği",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Ödeme şekli",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Bekleyen miktar",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Bekleyen miktar",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Uyarılar",
      "PAYMENTS_TABLE_HEADER_STATUS":"durum",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Kısmi ödeme",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"İşlem günü",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"İşlem Kimliği",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Aksiyon",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Varlık adı",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Fatura numarası",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Ödenen miktar",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Detayları göster",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Sipariş Kimliği",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Sipariş verildi",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Uyarılar",
      "Percentage":"Yüzde",
      "PHONE_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Telefon numarası",
      "PLACEHOLDER_ENTER_TEXT":"Metin girin",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"İtibaren geçerli",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Metin girin",
      "PLACEHOLDER_FOR_PRICE":"Fiyat",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"seçmek",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Arama...",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Düzenle",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Kayıt etmek",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Bu alan gereklidir",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Kapat",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Ürün adı",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Ödeme miktarı",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Uyarılar",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Gelecekte bu planda bileşik teklifler planlıyorsanız bu kutuyu işaretleyin.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"Durum başarıyla güncellendi",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"Planlar",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Yeni Plan Ekle",
      "PLAN_CAPTION_TEXT_PLANS":"Planlar",
      "PLAN_CHANGE_FOR_CUSTOMER":"Müşterinin değişim planı",
      "PLAN_CHANGE_TEXT":"Plan Değişikliği",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Kompozit Plan Teklifleri",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Planlar",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Kompozit Tekliflere Dön",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Kompozit Teklif Ekle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Kompozit Teklifi Düzenle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Teklifler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Seçilen öğeler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Önerilen Fiyat:",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Eklenti (ler)",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Eklenti",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Kaldırmak",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Açıklama",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Teklif Adı",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Fiyat",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Kompozit Teklif Ekle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Plan Ayrıntılarına Dön",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Kompozit Teklifler -",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Düzenle",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Dahil Olan Teklifler",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"ad",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b> altında {b> {{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Kaydol",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"her ay",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Tahmini Perakende Fiyat",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"yıl başına",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Değişiklikleriniz başarıyla gönderildi",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"miktar",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Makro Değeri",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Planlara Dön",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Kompozit Teklifleri Yönetme",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Ayrıntılar Plan -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Yeni Plan ekle",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"İptal etmek",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Sunmak",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Özel mi",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"CSP Aboneliklerinin plandaki tekliflerle paylaşılmasına izin verilsin mi?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Teklifler",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Devralınan bir teklifi geçersiz kılma adımları:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Geçersiz kılmak istediğiniz teklife karşı kutuyu işaretleyin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Yeni bir kolay ad girin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Yeni satış fiyatını belirtin",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Yeni plan, devralınmış ancak geçersiz kılınmamış tekliflerle birlikte geçersiz kılınan teklifleri listelemeye başlayacaktı.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Not:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Üst Plandan Geçersiz Kılındı",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"seçilmiş",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Öğeler",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Eklenti (ler)",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Satınalınabilir",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Teklif Adı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"AYRİYETEN",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lisans türü",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Kaldırmak",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Satış ücreti",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Güncelleme Fiyatı",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Plan Adı:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Fiyat:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Yeni Fiyat Ayarla",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lisans türü",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Teklif Geçersiz Kılındı",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Teklif Seçildi",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Açıklama",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"URL paylaşılıyor",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Planlar",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"yönetme",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"Fatura Bazlı mı?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"İçin yeni fiyat belirle",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Fiyat değişikliği etkili olur",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Yeni fiyat",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Mevcut fiyat",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Plan teklif fiyatı başarıyla güncellendi",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Planı Yönet",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Planı Geçersiz Kıl",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Planlar",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Planlara Dön",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Planı Düzenle",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Planı Geçersiz Kıl",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"CSP Aboneliklerinin plandaki tekliflerle paylaşılmasına izin verilsin mi?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Özel mi",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Açıklama",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Faturalandırma sisteminizde mevcut bir bileşeni kullanmak istemeniz durumunda faturalandırma bileşeni kimliğini girin. 0 değerinde bırakmak, faturalandırma sisteminizde yeni bir bileşen oluşturur.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Not:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Faturalandırma sisteminizdeki bileşeninizle eşleşen fiyatı belirttiğinizden emin olun. Sistem şu anda fiyat ve bileşen kombinasyonunu faturalandırma sistemine göre doğrulamamaktadır.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Devralınan bir teklifi geçersiz kılma adımları:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Geçersiz kılmak istediğiniz teklife karşı kutuyu işaretleyin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Yeni bir kolay ad girin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Yeni satış fiyatını belirtin",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"Yeni plan, devralınmış ancak geçersiz kılınmamış tekliflerle birlikte geçersiz kılınan teklifleri listelemeye başlayacaktı.",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Satınalınabilir",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Faturalandırma Bileşeni Kimliği",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Kolay Ad",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Teklif Geçersiz Kılındı",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Satış ücreti",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Geçersiz kılmak için seçilen öğeler",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Teklifler",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"URL paylaşılıyor",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Para Birimi Kodunu Planlama",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Plan Adı",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"seçmek",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Kaynak Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Hedef Para Birimi Kodu",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Yeni para birimi tanımla",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Döşemeler başarıyla kaydedildi",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Düzenle",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Geçersiz Kıl",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "PLAN_TABLE_HEADER_TEXT_NAME":"ad",
      "PlanProductId":"PlanProductId",
      "plans":"Planlar",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Bileşik teklif oluşturmak için lütfen en az iki teklif seçin",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Bileşik teklif başarıyla kaydedildi",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Kompozit teklif başarıyla güncellendi",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"İptal etmek",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Kapat",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Sunmak",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Tüm ürünler",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"kayıt bulunamadı",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Lütfen değerinden büyük bir değer girin\" + minValue :  \"Lütfen arasında bir değer girin\"  + minValue + \" ve\" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Sözleşme Detayları",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Faturalandırma Türü",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Is ortagi maliyeti",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Levhalar",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Satış ücreti",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"maksimum değer",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Minimum değer",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"uygulanabilirlik",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Planınızda zaten bir sözleşme teklifiniz var. Yenisini eklemeden önce teklifi silin / devre dışı bırakın.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"sözleşme",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Geçersiz kılınan plan başarıyla güncellendi",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan geçersiz kılındı ​​başarıyla",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Lütfen temel plandan geçersiz kılınacak teklifleri seçin",
      "PLANS_SAVE_SUCCESS":"Plan başarıyla kaydedildi",
      "PLANS_UPDATE_SUCCESS":"Plan başarıyla güncellendi",
      "PO NUMARASI":"PO #",
      "PO_NUMBER":"PO #",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Politika atama Kapsamları",
      "PONUMBER_BUTTON_CLOSE":"Kapat",
      "PONUMBER_BUTTON_SKIP":"Atla",
      "PONUMBER_BUTTON_SUBMIT":"Sunmak",
      "POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "POPUP_BUTTON_TEXT_CLOSE":"Kapat",
      "POPUP_BUTTON_TEXT_CONFIRM":"Onaylamak",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Seçilen faturalandırma döngüsünde seçilen tüm teklifler kaybolacak. Devam etmek istiyor musun?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Bu müşteri zaten bir kupon içeriyor: <b> {{CouponName}} </b>, değiştirmek istiyor musunuz ?.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"{{CustomerName}} adlı kullanıcıyı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} başarıyla silindi.",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"SİL",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"{{CustomOfferName}} 'ı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} başarıyla silindi.",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"{{PlanName}} 'ı silmek istediğinizden emin misiniz?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"Silmek istediğine emin misin?",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"{{ProductName}} hizmetini devre dışı bırakmak istediğinizden emin misiniz?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"{{ProductName}} 'ı etkinleştirmek istediğinizden emin misiniz?",
      "POPUP_PLAN_DELETED":"{{PlanName}} planı silindi.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"REVERT",
      "POPUP_REVERT_SUB_HEADER_TEXT":"Geri dönmek istediğinizden emin misiniz?",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Geçerli faturalandırma döngüsüne sahip seçtiğiniz teklifler kaybolacak, yine de devam etmek istiyor musunuz?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Kaydedilmemiş değişiklikleriniz var, hala devam etmek istiyor musunuz?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Müşteri adını güncellemek istediğinizden emin misiniz?",
      "PORTAL_ACCESS_GRANTED_TO_USER":"{{UserName}} kullanıcısına portal erişimi verildi",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Fiyat değişikliği, abonelik mevcut ücret döngüsünün başından itibaren uygulanacaktır",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Hemen",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"Bir sonraki faturalandırmadan",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"Bir sonraki yenilemeden",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Kapat",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Kapat",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"E-posta Bildirimini Önizle",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"E-posta Bildirimini Önizle",
      "Price":"Fiyat",
      "PRICE_CHANGE_CURRENCY":"Para birimi",
      "PRICE_CHANGE_NEW_PRICE":"Yeni fiyat",
      "PRICE_CHANGE_OLD_PRICE":"Eski fiyat",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Ayarlar -> İş Ortağı Merkezi ile ilgili talimatları uygulayın.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Bağlantıyı gizli bir oturumda açın ve (İş Ortağı) kiracınızda Global Yönetici olan ve İş Ortağı Merkezi'nde Yönetici Aracısı rolü atanmış bir hesap kullanarak onay deneyimini tamamlayın.",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Faturalandırma Türü",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Maliyet fiyatı",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Ekran adı",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"maksimum değer",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"Minimum değer",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Faturalandırma Türü",
      "PRIVACY_POLICY_BUTTON_TEXT":"GİZLİLİK POLİTİKASI",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Toplu Katılım müşterilerinin işlemleri tamamlandı",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Toplu Katılım müşterilerinin süreci başladı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Quickbooks'a Fatura yükleme işlemi tamamlandı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Faturaları Quickbooks'a yükleme işlemi başlatıldı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Faturaları Xero'ya yükleme işlemi tamamlandı",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Xero'ya Fatura yükleme işlemi başlatıldı",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Quickbooks'a Fatura yükleme işlemi başarısız oldu",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Quickbooks'un başarısına Fatura yükleme işlemi",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Faturaları Xero'ya yükleme işlemi başarısız oldu",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Faturaları Xero'ya başarıyla yükleme işlemi",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Seçtiğiniz öğeler kaldırıldı.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Bu alanın değerinin değiştirilmesi seçilen öğelerin kaybolmasına neden olacaktır",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Faturalandırma Türü",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Maliyet iptali olmadan izin verilen maksimum gün sayısı.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Satış Tipi",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Geçerlilik",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Şu anda bu üründe bazı değişiklikler yapıyoruz. Lütfen yeni bir istekte bulunmadan önce bu işlemlerin tamamlanmasını bekleyin.",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Uygulanan Arama:",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Bitiş tarihi",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Başlangıç ​​tarihi",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Ürün adı",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Alan adı",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Talimatlar",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"onaylamak",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"reddetmek",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Az oku",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Daha fazla oku",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Fatura döngüsü",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Kategori",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Saglayici Adi",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Devam etmeden önce en az bir aktif ürün seçin.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Listeden en az bir kullanıcı seçin.",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Yüzde",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"miktar",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Kapsam Adı",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Miktarı, mevcut miktarın üzerindeki bir değere güncelleyemezsiniz. Bunu yapmak istiyorsanız, lütfen temel ayrıntılar görünümünden miktarı artırın.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Miktarı satır içi düzenleme yoluyla güncelleyebilir ve kaydetmek için gönder düğmesini tıklayabilirsiniz.",
      "PRODUCT_OWNERSHIP_SUCCESS":"Sahiplik başarıyla güncellendi",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Ekle",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Ürünlere Geri Dön",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Açıklama",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Açıklama",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Etiket Anahtarı",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Kategori",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Gösterge Paneli",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Müşteri profili",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Banka detayları",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Kredi kartı",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Banka hesabı",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Ödeme şekli",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Lütfen bir ödeme şekli seçin",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Ödeme profili başarıyla güncellendi",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Kullanıcı detayları",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Kent",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"ülke",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"E-posta",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"İsim",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Soyadı",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Satır 1",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Hat 2",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Durum",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Zip",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"E-posta",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Müşteri yapılandırması",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Bayi Yapılandırması",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Telefon numarası",
      "PROFILE_TAB_USER_DETAILS":"Kullanıcı detayları",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Sunmak",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Kayıt etmek",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Kayıt etmek",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Kent",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"ülke",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"E-posta",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"İsim",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Soyadı",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Satır 1",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Hat 2",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Telefon numarası",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Durum",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Zip",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Fatura Adresi",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Fatura Detayları",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Ödeme yöntemi ekle",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Not:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Yeni ödeme profili sonraki tüm işlemleriniz için kullanılacak",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Ödeme yöntemini değiştir",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Ödeme Profili Ayrıntıları",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Lütfen bir ödeme yöntemi ekleyin.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Kayıtlı bir ödeme profiliniz var.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Ödeme yönteminizden son 4 basamak",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Ödeme şekli",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Kullanıcı detayları",
      "PROFİLİ GÜNCELLE":"Profil başarıyla güncellendi",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Hesabınız bu Sağlayıcıya bağlı olmadığından bu öğeyi satın alamazsınız!",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"CSP Kiracılarına karşı yapılan satın almalar, Müşteri İzninin olmaması nedeniyle başarısız olabilir. <a ui-sref=\"home.profile.provider({providername:'Microsoft' )\"> <i class = \"red bold\"> Kiracı Profiline yönlendirmek ve MCA eklemek için burayı tıklayın </i> </a>",
      "PROVIDER_CUSTOMER_NAME":"Sağlayıcı Müşteri Adı",
      "PROVIDER_DESC_MICROSOFT":"Microsoft,",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Saglayici Adi",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"İş Ortağı Maliyeti",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Ödenen miktar",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Ödenen tarih",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Sağlayıcı",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Faturalandırma E-postası",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Ülke kodu",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Hata detayları",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"seçmek",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft,",
      "providers":"Sağlayıcıları",
      "PROVISIONING_LABLE_TEXT":"Sağlama ...",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Lütfen yüklenecek bir dosya seçin",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Müşteri Seçin",
      "PUBLIC_SIGNUP_ADD_TO_CART":"Sepete ekle",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Ürün Arama",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"her ay",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"yıl başına",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Onaylamak",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Bize Ulaşın",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Alışverişe devam",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"En son kabul ediyorum",
      "PUBLIC_SIGNUP_NEXT":"Sonraki",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Ödeme şekli",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Sipariş Vermek",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Araba",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Dükkan",
      "PUBLIC_SIGNUP_SIDE_CART":"Araba",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Kaldırmak",
      "PUBLIC_SIGNUP_SUPPORT":"Destek",
      "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Eklentileri",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Tarihinde sona eriyor",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Sipariş verildi",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Ürün adı",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"miktar",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"durum",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"Farkı uzlaştırma girişimi başarısız oldu. Yardım için lütfen destekle iletişime geçin",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"Miktar mutabakatı başarıyla tamamlandı",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Hızlı Kimliğe Bürünme",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Aşağıdaki kullanıcılar kimliğe bürünme için kullanılabilir, devam etmek için herhangi birini seçin",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"impersonate",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Aksiyon",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"rol",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Kullanıcılar)",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"faaliyetler",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Uyarılar",
      "QUICK_SIDEBAR_LABEL_MORE":"Daha",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Bildirimler",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"Ayarlar",
      "QUICK_SIDEBAR_LABEL_USERS":"Kullanıcılar",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"QuickBooks için onayınızı verdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek QuickBooks'a erişecek şekilde yapılandırılabilir. Müşterilerinizin faturalarını QuickBooks'a gönderebilirsiniz.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> içindeki Faturaları QuickBooks'a Yükleme talimatlarını uygulayın.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Lütfen QuickBooks onayınızı işleme koyarken bekleyin",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Aşağıdaki bağlantı yeni bir sekmede açılır. Lütfen QuickBooks kimlik bilgilerinizle onay verin. Onay verirken bir hatayla karşılaşırsanız, lütfen destek ekibimizle iletişime geçin.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"QuickBooks onayı sağlamak için",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Onayı önceden verdiyseniz, lütfen QuickBooks ile bağlantıyı doğrulamak için <b> Jetonu Doğrula </b> düğmesini tıklayın.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Mevcut QuickBooks Yenileme Jetonunun geçerliliğini doğrulamak için bu düğmeyi tıklayın.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"İş Ortağının müşteri faturalarını <b> Çevrimiçi Hızlı Kitaplar </b> 'a göndermesi için önkoşullar",
      "QUICKBOOKS_POST_BATCH_ID":"Quickbooks sonrası Toplu İş Kimliği",
      "QUICKBOOKS_POST_LOGS_STATUS":"Quickbooks Kayıt Günlükleri Durumu",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Var olan QuickBooks Yenileme Simgesi geçersiz. Lütfen rızanızı tekrar sağlayın",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Mevcut QuickBooks Yenileme Jetonu geçerlidir. Faturaları yüklemeye devam edebilirsiniz",
      "QUOTE_ALL_SELECTION":"Hepsini seç",
      "QUOTE_BUTTON_TEXT_CANCEL":"İptal etmek",
      "QUOTE_BUTTON_TEXT_CLOSE":"Kapat",
      "QUOTE_BUTTON_TEXT_SUBMIT":"Sunmak",
      "QUOTE_COMMENTS_TO_BUYERS":"Yorumlar",
      "QUOTE_COMPANY":"şirket",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Kayıt etmek",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"fatura",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Fatura donemi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Açıklama",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Bitis tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Bitiş tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Fatura tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Fatura numarası",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"ad",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Fiyat",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Satış Tipi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Başlangıç ​​tarihi",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Birim fiyat",
      "QUOTE_DATE":"tarih",
      "QUOTE_EVENT_ERROR":"Hata",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Yorumlar",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "QUOTE_LABEL_TEXT_EMAIL":"E-posta",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"İsim",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Soyadı",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Müşteri Ekle",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Toplam indirim",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"miktar",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Vergi Türü",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Toplam indirim",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Maliyet fiyatı",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"miktar",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Satış ücreti",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"özet",
      "QUOTE_LIST_STATUS_PUBLISHED":"Fatura Yayımlandı",
      "QUOTE_MOVE_TO_STATE":"Sonraki",
      "QUOTE_PRICE_SUMMARY":"özet",
      "QUOTE_PRICE_TOTAL":"Toplam",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Plan Adı",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Satış ücreti",
      "QUOTE_STATUS":"durum",
      "QUOTE_STATUS_APPROVED":"Müşteri incelemesi için onaylandı",
      "QUOTE_STATUS_DELETED":"silindi",
      "QUOTE_STATUS_PUBLISHED":"Fatura Yayımlandı",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"ad",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Miktar",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"durum",
      "QUOTE_TABLE_LABELS_USER_ROLES":"rol",
      "QUOTE_TABLE_PRICE":"Birim fiyat",
      "QUOTE_TABLE_QUANTITY":"miktar",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"RD Dağıtımlarına Geri Dön",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Yeni RD Dağıt",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Dağıtmak",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"ayrıntılar",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"RD Ayarları",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Alan Adı Ekle",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Yönetici şifresi",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Yönetici Kullanıcı Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Ağ Geçidi DNS Etiketi Öneki",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Ağ geçidi genel IP Adresi Adı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"Görüntü SKU'su",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Görüntü SKU'sunu seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"yer",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Konum Seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Rd Örnekleri Sayısı",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Kaynak Grubu",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"abone",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Abonelik Seçin",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"VM Boyutu",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Görüntü SKU'sunu seçin",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"RD Dağıtımları",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Yeni RD Dağıt",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Dağıtım Durumunu Yenile",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"RD Dağıtımları",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"yönetme",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Dağıtım Tarihi",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"yer",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Kaynak Grubu",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"durum",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"abone",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"RD Dağıtımlarına Geri Dön",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Dağıtım Ayrıntıları",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"ayrıntılar",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Dağıtım Adı",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"çevre",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Dağıtım Tarihi",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"RD Dağıtımı başarıyla başlatıldı",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Mutabakat raporunun yeniden yüklenmesi başarısız oldu",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Mutabakat raporu başarıyla yeniden yüklendi",
      "RECON_REPORT_RELOAD":"Yeniden yüklenen mutabakat raporu",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Mutabakat raporu yeniden yükleme başlatılamadı",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Mutabakat raporunun yeniden yüklenmesi başladı",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Arama",
      "RECON_TABLE_TEXT_BILLING_CYCLE":"Fatura döngüsü :",
      "RECONCILIATION_CUSTOMER_NAME":"müşteri adı",
      "RECONCILIATION_NOTES":"notlar",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"<Span class = 'red'> red </span> içindeki öğeler miktar uyuşmazlığını gösterir",
      "RECONCILIATION_NOTES_SUSPENDED":"<Span class = 'strike-through'> tıklanan </span> öğeleri askıya alınmış abonelikleri gösterir",
      "RECONCILIATION_PROVIDER":"Sağlayıcı",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Ödeme miktarı",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Para Birimi Kodu",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Müşteri Seçin",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Müşteriler",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Avans Ödemesini Kaydedin",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Uyarılar",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Müşteri Seçin",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Ödeme başarıyla kaydedildi",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Ödeme tarihi",
      "RECORD_ID":"Kayıt Kimliği",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Harici hizmetten Kayıt Kimliği alındı",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Varlık adı",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"ad",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Hata",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"başarı",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Para Birimi Kodu",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Ödeme miktarı",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Ödeme tarihi",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"ad",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Uyarılar",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Hata",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"başarı",
      "RECORD_PAYMENT_PAID_DATE":"Ödeme tarihi",
      "REFRESH_TOKEN":"Jetonu Yenile",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Seçtiğiniz için teşekkürler <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Yeni aboneliğinizi işlemek için lütfen aşağıdaki bilgileri doldurun.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Bizimle bulunmayan mevcut bir Microsoft Hesabınız varsa, lütfen <a href=\"{{LinktoContact= {\" target=\"_blank\" class=\"ng-binding\"> bize ulaşın </a> yardım için.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Lütfen geçerli bir kupon girin",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok.",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"E-posta",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Lütfen geçerli bir e-posta kimliği girin. Kayıt tamamlandıktan sonra kullanıcı adı ve şifre içeren bir e-posta göndereceğiz",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"İsim",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Soyadı",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Telefon numarası",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Kent",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Şirket Adı",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Satır 1",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Hat 2",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Birincil alan adı",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"Başlamak için şirket adınızı ve ardından .onmicrosoft.com'u kullanarak bir hesap oluşturmanız gerekir. Kuruluşunuzun .onmicrosoft.com olmadan kullanmak istediğiniz kendi özel etki alanı varsa, bunu daha sonra Office 365 Yönetici Portalı'ndan değiştirebilirsiniz.",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Bu nedir",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Durum",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Zip",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Sözleşme Tarihi",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"En son kabul ediyorum",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Planınızı Yapılandırma",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Eklemek istediğiniz plandaki her teklif için en az 1 miktar seçiniz.",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Birincil iletişim",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"şirket Profili",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft Müşteri Sözleşmesi",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Banka hesabı",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Kredi kartı",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Kuponu Kaldır",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Lütfen geçerli bir kupon girin",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Kupon kodu uygulandı: <b> {{CouponCode}} </b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Lütfen bir ödeme şekli seçin",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Plan Özeti",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Yinelenen Ücret (her 1 ayda bir)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"ardından {span nextBillingDate}} tarihinde ilk yenilemede <span class = 'bold'> $ {{totalCount}} </span>",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Toplam",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Not:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Satın alma ücretleri, geçerli faturalandırma döngüsünde kalan günler için orantılı olarak uygulanır.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Lisanslar",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"ACH hesabının bir işletme hesabı olup olmadığını belirtmelisiniz!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Kullanıcı başarıyla oluşturuldu",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Alan adı kullanılabilirliği kontrol edilemiyor",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Lütfen geçerli bir alan adı girin. Alan adları yalnızca harf ve rakam içerebilir; tire, nokta veya alt çizgi yok.",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"Kayıt Durumu",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Kaydınız başarıyla işlendi. Lütfen aşağıdaki kimlik bilgilerini kullanarak portalda oturum açın:",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Kullanıcı adı",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Parola",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Buradan kopyalamayı kaçırmanız durumunda size yukarıdaki ayrıntıyı içeren bir e-posta gönderildi",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Siparişimi İşleme",
      "REJECT_PRODUCT_POPUP_BUTTON":"reddetmek",
      "RELEASE_PRODUCT_OWNERSHIP":"Ürün sahipliğini serbest bırak",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Ürün sahipliğinin serbest bırakılması başarısız oldu",
      "RELEASE_SEED_ERROR":"Miktarı yalnızca bırakırken azaltabilirsiniz, lütfen tekrar deneyin",
      "RELEASE_UNUSED_SEATS":"Kullanılmayan koltukları serbest bırakın",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class = 'red'> <b> {{firstName}} </b> için veriler işlenirken bir hata oluştu. Hata: {{errorMessage}} </span> <br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class = 'success'> <b> {{firstName}} </b> </span> <br/> için kayıtları başarıyla işledi",
      "RELOAD_RECONCILIATION_STATUS":"Mutabakat Durumunu Yeniden Yükle",
      "REMOVE_ALL_SELECTED_RGS":"Seçili Kaynak Gruplarının tümünü kaldırmak istiyor musunuz?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Kaldırmak",
      "REMOVE_PARTNER_PRODUCT_ICON":"Kaldırmak",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Bu kullanıcı için erişimi iptal etmek istediğinizden emin misiniz?",
      "RENEWAL_ALL_DAYS":"Herşey",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"İndir",
      "REPORT_ACTIONS_TEXT":"Aksiyon",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Tüm Müşteriler",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Bu alan gereklidir",
      "REPORT_POPUP_ACTIONS_TEXT":"Aksiyon",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"İndir",
      "REPORT_POPUP_EMAIL_TEXT":"E-posta",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Hepsini seç",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Müşteri Seçin",
      "REPORT_SEND_EMAIL_TEXT":"E-posta",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"İndir",
      "REPORT_USAGE_NOTE":"Not :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Hata",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"başarı",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Is ortagi maliyeti",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Para birimi",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"müşteri adı",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Bir müşteriyle bayilik ilişkisi talep etmek için, URL dahil olmak üzere aşağıdaki metni kopyalayıp bir e-postaya yapıştırın. Gerekirse metni düzenleyin ve e-postayı müşterinize gönderin",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"E-posta Metni",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Bayilik ilişkisi talebi",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Teknede Bulk Onboard isteği",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Tarafından başlatılan Quickbooks'a Fatura yükleme isteği",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"Xero'ya fatura yükleme isteği,",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Faturaları QuickBooks'a yükleme isteği kabul edildi",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Faturaları Xero'ya yükleme isteği kabul edildi",
      "REQUIRED_FIELD_TEXT":"Bu alan gereklidir",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Bayi fatura satır öğeleri dışa aktarılırken hata oluştu",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"ayarlama",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Vergilendirilebilir mi",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Ekle",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"İptal etmek",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Hepsini seç",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Eklentiler Listesi",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Daha Fazla Ürün Ekle",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Planı Sil",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Plan Tekliflerini Gözden Geçirin",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Planı Kaydet",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Arama Uygulandı",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Hızlı arama",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategori",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Sağlayıcıları",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Hizmet Türleri",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"başarı",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Fiyat başarıyla güncellendi",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"miktar",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"kullanım",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Ürün bulunamadı",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Makro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":"Fiyat",
      "RESELLER_AZURE_MACRO_VALUE":"Makro Değeri",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Bayiler",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Listeye geri dön",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Bayilere Geri Dön",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Yeni Sağlayıcıyı Bağla",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Bayi Ekle",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Yeni",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Bayi Ekle",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"İletişim detayları",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Ödeme şekli",
      "RESELLER_CAPTION_TEXT_RESELLER":"Bayiler",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Bayi Fatura Profili",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Bayi Detayları",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Bayi Profili",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Bu bayrağın işaretlenmesi müşterilerin abonelik miktarını azaltmasını önler",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Müşterilerin Azure aboneliklerini yönetmesini sağlama",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Müşterilerin Azure Abonelikleri Üzerinden Gruplar tanımlamasını sağlama",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Bayi masmavi kullanımına uygulanacak işaretleyin (%). Örneğin: İşaretleme% 15 ise 1.15 girin",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Dahili referans için. Bu kimlik, müşteriyi dahili muhasebe sisteminizdeki bir kayıtla ilişkilendirmek için kullanılabilir",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Faturaların otomatik olarak onaylandığı ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Ödemelerin otomatik olarak işlendiği ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Ödemelerin otomatik olarak işlendiği ayın günü",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Faturaların son ödeme tarihi",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Faturaların otomatik olarak sonlandırıldığı ayın günü",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Bayi için satınalma siparişi numarasını tanımlayın",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Sistemin ödemeleri işleme koymasını istiyorsanız bu işareti işaretleyin (uygunsa CC / ACH)",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Bayiler için Vergi kodunu tanımlayın",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Müşterinin koltukları azaltmasına izin ver",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Azure etkinleştirilsin mi?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Kurumsal için Azure etkinleştirilsin mi?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Azure kullanımı için işaretleme",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Faturalandırma Referans Kimliği",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Bayilerin faturaları görebileceği gün",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Otomatik ödeme günü",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Otomatik ödeme günü",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Faturaların son ödeme tarihi",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Faturaların kesinleştiği gün",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"PO #",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Otomatik ödeme faturaları?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Vergi kodu",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Bayi Yapılandırması başarıyla geri döndürüldü",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Bayi yapılandırması başarıyla kaydedildi",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Düzenleme erişiminiz yok",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Bayi yapılandırması başarıyla güncellendi",
      "RESELLER_CONFIGURATION_OF":"Bayi Yapılandırması",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"İşletme gelirinize hızlı bir bakış",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Karlılık",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Alacak hesaplarınızın özeti",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Alacak hesapları",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Henüz tahsil edilmeyen ödemeler",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Geçmiş Ücretler",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Aktif müşterileriniz",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Müşteriler",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Bekleyen bir faturada uyarı",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Fatura",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Verilen mpn kimliğine sahip sistemde bir satıcı zaten var. Lütfen verilen mpn kimliğini kontrol edin.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Banka detayları",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kredi kartı detayları",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Lütfen Ödeme Türünü Seçiniz",
      "RESELLER_IMPERSONATE":"impersonate",
      "RESELLER_IMPERSONATE_USER_ROLE":"impersonate",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"kendi rolünü taklit etmek",
      "RESELLER_INVOICE":"Fatura",
      "RESELLER_INVOICE_TEXT_LINE":"Fatura, fatura adresinize gönderilecektir. Teşekkürler!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Fatura durumu başarıyla güncellendi",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Ödeme başarıyla kaydedildi",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özetine Geri Dön",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Faturalar",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Fatura Satır Öğeleri",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Hesap numarası",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"hesap tipi",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Adres satırı 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Adres Satırı 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Banka adı",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Kart numarası",
      "RESELLER_LABEL_TEXT_CITY":"Kent",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Şirket Adı",
      "RESELLER_LABEL_TEXT_COUNTRY":"ülke",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"kredi kart tipi",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"E-posta",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"İsim",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"İşletme Hesabı",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Soyadı",
      "RESELLER_LABEL_TEXT_MONTH":"Ay",
      "RESELLER_LABEL_TEXT_MPN_ID":"MPN Kimliği",
      "RESELLER_LABEL_TEXT_NAME":"ad",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Hesaptaki İsim",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Karttaki İsim",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Telefon numarası",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Yönlendirme numarası",
      "RESELLER_LABEL_TEXT_STATE":"Durum",
      "RESELLER_LABEL_TEXT_YEAR":"Yıl",
      "RESELLER_LABEL_TEXT_ZIP":"Zip",
      "RESELLER_LIST_PAYMENT_METHOD":"Ödeme şekli",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"CSP'de bayi bulunamadı. Lütfen verilen mpn kimliğini kontrol edin.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Yüklenen verilerde tespit edilen hatalar. Hataları indirin, düzeltin ve tekrar yüklemeyi deneyin.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"ACH ayrıntılarının bir işletme hesabıyla ilgili olup olmadığını lütfen belirtin",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Faturalandırma E-postası",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"ülke",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"ad",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"başarı",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Ödeme ayrıntıları kaydedildi",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Ödeme profili silindi",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Ödeme profili Varsayılan olarak ayarla",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Bu Ödeme seçeneğini silmek istediğinizden emin misiniz?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makroyu Tanımla ve Tüm Tekliflerle Kaydet",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Tüm Tekliflerle Planı Kaydet",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategoriler",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makro Değeri",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Sağlayıcıları",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Hizmet Türleri",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"seçmek",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Değişiklikleriniz başarıyla gönderildi",
      "RESELLER_PLAN_DISABLE_BUTTON":"Planı devre dışı bırak",
      "RESELLER_PLAN_ENABLE_BUTTON":"Planı etkinleştir",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"Teklif başarıyla durduruldu",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Teklifi durdur",
      "RESELLER_PLAN_SELECTED_OFFERS":"Seçilen Teklifler",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Planlar",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"İptal etmek",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klon Planı",
      "RESELLER_PLANS_CAPTION_1":"Yeni Plan ekle",
      "RESELLER_PLANS_CAPTION_2":"Ayrıntılar Plan -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Planlar",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Görünüm",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Fiyatlandırma ve Teklifler",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Fiyatlandırma ve Teklifler Listesini İndir",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Fiyatlandırma ve Teklifler",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"ERP Fiyatı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Lisans Sözleşmesi Türü",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Liste fiyatı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Teklif Adı",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Kadar geçerli",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Bayi fiyatlandırması ve teklifler listesi dışa aktarılırken hata oluştu",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Organizasyon Profili",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"İptal etmek",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Sil",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Düzenle",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Kayıt etmek",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Müşteri profili",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Adres Ekle",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"E-posta Ekle",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Telefon Ekle",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Adresler",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Temel Detaylar",
      "RESELLER_PROFILE_PARTNER_CANCEL":"İptal etmek",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Şirket Adı",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"E-mail adresleri",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"İsim",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Soyadı",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Temel ayrıntılar başarıyla güncellendi",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Kayıt bulunamadı!",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Telefon numaraları",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Bu kaydı silmek istediğinizden emin misiniz?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"Sunmak",
      "RESELLER_PROFILE_PAYMENT_ADD":"Ekle",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Banka hesabı",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Kredi kartı",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Profil bulunamadı. EKLE düğmesine tıklayarak yeni bir ödeme profili ekleyin",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Ödeme Profilleri",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Hesap Oluşturma Tarihi:",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Fatura Adresi",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"C3 Başlangıç ​​Tarihi",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Bayi Profili",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"teslimat adresi",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Kullanıcı hesapları",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Bayi Adı",
      "RESELLER_PROFIT_PRODUCT_NAME":"Ürün adı",
      "RESELLER_PROFIT_RESELLER_NAME":"Bayi Adı",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Herşey",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"HAYIR",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"EVET",
      "RESELLER_REGISTRATION_SUCCESS":"Bayi kaydı başarıyla tamamlandı.",
      "RESELLER_RELATIONSHIP_LBL":"Bayi ilişkisi kurmak için lütfen aşağıdaki içeriği müşteriyle paylaşın",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Görüntüleme ölçütü",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Lütfen geçerli vergi yüzdesini girin",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Lütfen alanların vergi adını doldurun",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Yeni Etkinlik E-posta Bildirimi Ekle",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"iş ortağı etiketi başarıyla silindi",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"iş ortağı etiketi başarıyla güncellendi",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Yapılandırılmış sağlayıcı yok, Lütfen Destek ile İrtibata Geçin",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Bu sağlayıcı için kullanılabilir anahtar yok",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Bayi Yapılandırması",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Bayi Ayarı",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Teklif ata",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Düzenle",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"impersonate",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Sağlayıcı Bağlantılarını Yönet",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Oluşturma Tarihi",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"ad",
      "RESELLER_TABLE_SIGNUP_DATE":"Kayıt Tarihi",
      "resellers":"Bayiler",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Fatura",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Mutabakat",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Ücreti Satıcısı",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Bayi başarıyla eklendi.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Bayi Ödemeleri",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Planları Yönet",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"E-postalar",
      "RESEND_INVOICE":"Faturayı tekrar gönder",
      "RESEND_INVOICE_HEADER_TEXT":"Faturayı yeniden göndermek için lütfen E-posta Kimliklerini girin",
      "RESEND_INVOICE_NOTE":"<b> Not: </b> Faturayı birden fazla E-posta Kimliğine yeniden göndermek isterseniz, lütfen virgülle ayırarak kimliği girin. Örn: abc @ mail.com, yeni @ mail.com",
      "RESEND_INVOICE_USERS_LIST":"Fatura kullanıcı listesini tekrar gönder",
      "RESET_VALUE":"Değeri sıfırla",
      "Resolved":"kararlı",
      "RESOURCE_GROUP_NAME":"Kaynak Grubu Adı",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Ödeme başarıyla başlatıldı. İşleme biraz zaman alacaktır. Lütfen bir süre sonra durumu kontrol edin.",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Fatura tutari",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Tüm faturaları indir",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Kategori",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"İş Ortağı Maliyeti",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Müşteri",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Distribütör Faturası",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Sağlayıcı",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"SaleType",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Aidat",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Faturalar ve Aidatlar",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Ödemeler",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Hataları",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Gecikmeler",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Görüntüleme ölçütü",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Fatura Üretimi Tamamlandı",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Müşteri faturaları oluşturulurken hata oluştu",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Müşteri faturaları başarıyla oluşturuldu",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları Yükleniyor ...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Faturayı yeniden oluştur",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Faturaları görüntüle",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Abonelik geçmişini görüntüle",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Miktar",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Fatura donemi",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Müşteri",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Is ortagi maliyeti",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"kâr",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Tüm faturaları e-posta ile alın",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Sağlayıcı Ödeme Ayrıntıları",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Kategori",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Müşteri",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Ödevi iptal etmek istediğinizden emin misiniz?",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Lütfen en az bir üst seviye plan teklifi için miktarı girin.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Kullanıcı ayrıntılarını doldurun",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Rol atama Kapsamları",
      "ROLE_NAME_C3_SUPPORT":"C3 Desteği",
      "ROLE_NAME_CUSTOMER_ADMIN":"Müşteri Yöneticisi",
      "ROLE_NAME_CUSTOMER_READER":"Müşteri Okuyucu",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Bölüm Yöneticisi",
      "ROLE_NAME_DEPARTMENT_READER":"Bölüm Okuyucu",
      "ROLE_NAME_FINANCE_ADMIN":"Finans Yöneticisi",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Finans ile ilgili sayfaları yönetebilir.",
      "ROLE_NAME_GROUP_ADMIN":"Grup Yöneticisi",
      "ROLE_NAME_HELP_DESK":"Yardım Masası",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Yardım Masası",
      "ROLE_NAME_SITE_ADMIN":"Site yöneticisi",
      "ROLE_NAME_SITE_READER":"Site Okuyucu",
      "ROLE_NAME_SYSTEM_ADMIN":"Sistem yöneticisi",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Portaldaki tüm sayfalara erişebilir / bunları değiştirebilir.",
      "ROLE_NAME_SYSTEM_READER":"Sistem Okuyucu",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Tüm sayfaları ziyaret edebilir ancak değişiklik yapamaz.",
      "ROLE_NAME_USER":"kullanıcı",
      "ROLE_NOTE_C3_SUPPORT":"C3 Desteği miktar uyuşmazlığı durumlarını çözebilir ve süper bir izin kümesidir",
      "ROLE_NOTE_CUSTOMER_ADMIN":"Müşteri yöneticisi, kiracı bağlamında tüm verileri görüntüleyebilir ve tüm işlemleri gerçekleştirebilir",
      "ROLE_NOTE_CUSTOMER_READER":"Müşteri ile ilgili tüm verilere salt okunur erişime sahip müşteri okuyucuları.",
      "ROLE_NOTE_FINANCE_ADMIN":"Finans yöneticileri müşteri gelirine ve maliyet özetine erişebilir, vergi yüzdesini tanımlayabilir, mutabakat raporunu görüntüleyebilir",
      "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins, kendi grubu altındaki tüm Azure aboneliklerini yönetebilir.",
      "ROLE_NOTE_HELP_DESK":"Yardım Masası kullanıcıları, yeni müşterilerdeki tüm müşteri ayrıntılarını görüntüleyebilir ve destek sağlayabilir. Yardım Masası kullanıcıları müşteri verilerinin kimliğine bürünebilir ve bunlara erişebilir",
      "ROLE_NOTE_SYSTEM_ADMIN":"Sistem yöneticisi, tüm verileri görüntüleyebilir ve tüm eylemleri hem iş ortağı hem de müşteriler bağlamında gerçekleştirebilir",
      "ROLE_NOTE_SYSTEM_READER":"İş ortağıyla ilgili tüm verilere salt okunur erişime sahip sistem okuyucuları. Okuyucular taklit edemez",
      "SAATLER":"Saatler)",
      "SALE_TYPE_DESC_PRODUCT":"Ürün",
      "ŞARTLAR VE KOŞULLAR":"Şartlar ve koşullar",
      "SATIŞ":"Satış",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Quickbooks'tan alınan kimlik doğrulama kodunu kaydetmeye çalışıyorum",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Xero'dan alınan kimlik doğrulama kodunu kaydetmeye çalışılıyor",
      "SAVE_CART_EXCEPTION":"Alışveriş sepetine ürün eklenirken hata oluştu",
      "SAVE_COUPON_FAILURE":"Kupon kaydedilemedi",
      "SAVE_COUPON_SUCCESS":"Kupon başarıyla kaydedildi",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Para birimi dönüşümü kaydedilirken hata oluştu",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"E-posta bildirimi alıcıları listesi kaydedilirken veya güncellenirken hata oluştu",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları kaydedilirken hata oluştu",
      "SAVE_PLAN_PRODUCT_SLAB":"Kayıtlı sözleşme teklif levhaları",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Sözleşme teklif levhaları kaydedilemedi",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Sağlayıcı ödeme bilgileri başarıyla kaydedildi.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Bayi Ödeme Profilini Kaydet",
      "SAVE_RESELLER_PLAN":"Bayi Planını Kaydet",
      "SAVE_RESELLER_PLAN_FAILURE":"Bayi Planı Kaydedilemedi",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Bayi Planı Ürün Para Birimi Oranını Tanımlama",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Bayi Planı Ürün Para Birimi Oranı Tanımlanamadı",
      "SAVE_SUB_TAX":"Alt Verileri Kaydet",
      "SAVE_SUB_TAX_FAILURE":"Alt Verileri Kaydetme Başarısız",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Yeni Bilet başarıyla oluşturuldu",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Bilet başarıyla güncellendi",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"durum",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Sil",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Düzenle",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Aktif",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Başarısız oldu",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Aksiyon",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Fatura döngüsü",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"miktar",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"durum",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilmiş",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Fatura özeti",
      "SCHEDULED_REPORT_PROFITABILTY":"Kârlilik Raporu",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Alıcılar",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Vergi Satir Ögeleri",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Vergi Özeti",
      "SELECT_ALL_CUSTOMER":"Tüm Müşteriler",
      "SELECT_ALL_RG":"Tüm Kaynak Gruplarını seçmek istiyor musunuz?",
      "SELECT_COUPON":"Kupon Seçin",
      "SELECT_CUSTOMER":"Müşteri Seçin",
      "SELECT_DEFAULT_OPTION_SELECT":"seçmek",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Müşteri Seçin",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Etkinlik Seçin",
      "SELECT_EVENT":"Etkinlik Seçin",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Lütfen en az bir fatura seçin",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Satış fiyatınızı tanımlamak için hızlı seçenekler",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Etkinlik Seçin",
      "SELECT_PLAN_TOOLTIP_TEXT":"Planlar",
      "SELECT_PRIORITY":"Öncelik Seç",
      "SELECT_PRODUCT_AREA":"Ürün Alanını Seçin",
      "SELECT_STATUS":"Durum seçin",
      "SELECT_TYPE":"Tür Seçin",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Quickbooks'a fatura yüklemek için seçilen müşteriler",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Xero'ya Faturaları yüklemek için seçilen müşteriler",
      "SELECTED_FILTER_ALL":"Hepsini seç",
      "sell-direct":"Doğrudan Satış",
      "sell-indirect":"Dolayli Satis",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Faturayı Tekrar Gönder",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Gönder",
      "SEND_MAIL_BUTTON":"Posta göndermek",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
      "SERVICE_PROVIDER_TENANT":"Tedarikçi Kiracıları",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Varsayılan Kiracı",
      "SERVICE_PROVIDER_TENANT_HEADER":"Sağlayıcı Kiracıları",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Aksiyon",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Kiracı Kimliği",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Kiracı adı",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"<b> {{customerName}} </b> 'ı varsayılan kiracı olarak işaretlemek istediğinizden emin misiniz?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{CustomerName}}, varsayılan kiracı olarak güncellendi",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Oturumunuz sona erdi. Devam etmek için lütfen tekrar oturum açın. Maalesef kaydedilmemiş değişiklikler kaybedilecek.",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Yerleşik Müşteriler toplu iş durumu Tamamlandı olarak ayarlandı",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Harici hizmet sonrası günlüklerinin durumunu Errorignored olarak ayarlamaya çalışılıyor",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Harici hizmet yayını günlüklerinin durumu ErrorIgnored olarak ayarlanamadı",
      "settings":"Ayarlar",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Etkinlik E-posta Bildirimleri",
      "SETTINGS_HEADING_TEXT_BILLING":"fatura",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Para birimi dönüştürme",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Müşteri Varsayılanları",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Planı Sil",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"E-posta Bildirimleri",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Müşteri Yapılandırmaları (Global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"İş Ortağı Varsayılanları",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "SETTINGS_PASSWORD_HELP_TEXT":"Parolayı güncellemek istemiyorsanız, alanı boş bırakın.",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Genel",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"logolar",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Ortak Merkezi",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Sağlayıcıları",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Herhangi bir vergi adı belirleyebilirsiniz: GST.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Vergi yüzdesini burada belirleyebilirsiniz. Örnek:% 15 için 15",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Sepete ekle",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Ürün bulunamadı!",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Daha fazla oku..",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Daha fazla oku..",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Azure faturalandırma verileri hakkında rapor oluşturma",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Kullanıma dayalı abonelikler",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Kiracı tarafından tüketilen lisansların listesini görüntüleme",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"AZURE kullanımını açıklayın",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Kuruluş profilinizi, adreslerinizi, ödeme profillerinizi ve daha fazlasını yönetin ...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Lisans tahsisine ve ücretsiz koltuklara hızlıca bakın.",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"İndirimleri tanımlayın",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"C3 ile verileriniz hakkında birkaç rapor sunar.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Satmak istediğiniz tekliflerle planları tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Azure kullanımı hakkında Power BI tabanlı rapor",
      "SIDEBAR_CONTENT_MENU_USERS":"Kullanıcılara erişimi yönetin",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Kârlılığınızı analiz edin",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Azure Tahminlerini Görüntüleyin",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Bir veya daha fazla aboneliği gruplayın ve bütçeleri ve eşikleri tanımlayın",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Özel katma değerli hizmetlerinizi ve tekliflerinizi tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"İş Ortağınızdan faturalarınıza erişin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Müşterilerinize bağlı Microsoft CSP kiracılarıyla ilgili özelliklere erişmenizi sağlar",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Azure AD kullanıcılarını listeleyin ve yönetin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Siparişlerinizi takip edin",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Satın alınan ürünleri görüntüle",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Katalogdan alışveriş ve satın alma teklifleri",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Faturaları ve ödemeleri yönetme",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Birden fazla teklifi bir araya getirmenize ve müşterilerinize satın alma için sunmanıza olanak tanır",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Aktif müşteriler listesine ve verilerini yönetme seçeneklerine yönlendirir",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Sizi gösterge tablosu sayfasına götürür",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Satmak istediğiniz tekliflerle planları tanımlamanıza yardımcı olur",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Kârlılığınız hakkında genel bir bakış sunar ve fatura ayrıntılarına da bakar",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Gösterge tablosuna yönlendirir",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Kârlılığınız hakkında genel bir bakış ve fatura ayrıntılarına da göz atmanızı sağlar",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Etkin satıcılar listesine ve verilerini yönetme seçeneklerine yönlendirir",
      "SIDEBAR_CONTENT_MENUS_SELL":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"Satış tekliflerinizi tanımlamanıza yardımcı olan özellikleri listeler",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Yorumlar",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Azure Faturalandırma Raporu",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Azure Abonelikleri",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Maliyet Özeti Raporu",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"analitik",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lisans Tüketimi",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Microsoft Azure Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Lisans Tüketimi Özet Raporu",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Lisans Tüketim Raporu",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Kuponlar",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Yükleme",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"kullanım",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Raporlar",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Planlar",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Kullanım Raporu",
      "SIDEBAR_TITLE_MENU_USERS":"Kullanıcılar",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"çözümlemek",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Azure Tahminleri",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Azure Grupları",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Ortak teklifleri",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Faturalar",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft,",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Microsoft Kullanıcıları",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Emirler",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Ürün",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Dükkan",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Faturalar",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Paketler",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Müşteriler",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Planlar",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Gösterge Paneli",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Gelir ve Maliyet Özeti",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"Bayiler",
      "SIDEBAR_TITLE_MENUS_SELL":"Satmak",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Doğrudan Satış",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Dolaylı Satış",
      "SIGN_UP_DATE":"Kayıt Tarihi",
      "SIGNUP_CART_COUPON_APPLY":"Uygulamak",
      "SİPARİŞ KİMLİĞİ":"Sipariş Kimliği",
      "SİTE":"(Şantiye)",
      "SİTE KİMLİĞİ":"C3SiteId",
      "SITE_DEPARTMENT_ID":"SiteDepartmentId",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Aksiyon",
      "SLAB_TYPE_SELECT_OPTION":"seçmek",
      "SMTP_SETTINGS_TEST":"SMTP ayarlarını test etmek için lütfen düğmeyi tıklayın",
      "SMTP_TEST_BUTTON":"SMTP'yi test et",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Kaynak Para Birimi",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Kaynak Para Birimi",
      "STATUS":"durum",
      "STATUS_ALL":"Herşey",
      "STATUS_DUE":"Aidat",
      "STATUS_OVER_DUE":"Aşırı aidat",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Durum başarıyla güncellendi",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Lütfen alt vergiler için en az bir vergi adı ve yüzde girin.",
      "ŞUBAT":"Şubat",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Bu alan gereklidir",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Sunmak",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Fatura donemi",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Fatura döngüsü",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Fatura donemi",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Kategori",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Müşteriler",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Bitiş tarihi",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Abonelik Adi",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Sağlayıcı",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Başlangıç ​​tarihi",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Faturalandirma döngüleri",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Kategoriler",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Tüketim türleri",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Sağlayıcıları",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Kapat",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Arama",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Sıfırla",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"müşteri adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"tarih",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Bölüm Adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Yeni fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Ayriyeten",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Ürün adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Eski fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Fiyat",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Site adı",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"durum",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"kullanıcı",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Bitiş tarihi",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Başlangıç ​​tarihi",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Lisans Atamalarını Yönetme",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonelikler",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Kullanılmayan Koltukları Serbest Bırak",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Kullanılmayan koltukların serbest bırakılması. Lütfen bekle...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"İçin Lisans Atamalarını Yönetin",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Lisans Ata",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Lisansları Kaldır",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Kullanıcı ata",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Talimatlar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Talimatlar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Görüntüleme ve arama",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Kaldırılacak lisanslar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Doğrulama Hataları",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Geçerli kullanıcı e-posta adreslerini virgülle ayırarak yazın.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Bu Portal girişi doğrular ve atamadan önce ek lisansların satın alınması gerekip gerekmediğini belirler.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Bu abonelikteki değişiklikler üzerinde çalışırken hiçbir işlem gerçekleştirilemez.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Girişin doğrulanması sırasında bulunan hatalar görüntülenecektir.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Gönderme sırasında Lisans Ata veya Kaldır (etkin sekme) dikkate alınır.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Kaldırılacak lisanslar listesine eklemek için soldaki tabloda yer alan bir kullanıcıya karşı <i class = 'fa fa-remove red'> </i> seçeneğini tıklayın.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Lisansları kaldırmak için Gönder'i tıklayın",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Gönderme sırasında Lisans Ata veya Kaldır (etkin sekme) dikkate alınır",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Maliyet",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Orijinal Miktar.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Koltuk Başına Ücret",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Çoklu Kullanıcı",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Kaldırmak",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Dosya yükleme",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"ad",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"yeni kullanıcı e-posta adresi ekle",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Bir .txt dosyasına virgülle ayrılmış geçerli kullanıcı e-postaları girin ve buraya yükleyin.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":".Txt dosyasına virgülle ayrılmış geçerli kullanıcı e-postaları girin ve yükleyin. Örnek: abc @ xyz.com, bcd @ xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Hataları Yoksay",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Hataları düzeltmek",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"durum",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Lütfen isteğinizi işleme koyarken bekleyin, bu işlem birkaç dakika sürebilir. Abonelikler sayfasındaki 'Yönet' düğmesini tıklayarak da tekrar kontrol edebilirsiniz.",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Sipariş İptal Edildi",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Sipariş Tamamlandı",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonelik Satın Alındı",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonelik Kaldırıldı",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"Seçilen istek başarıyla onaylandı.",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Abonelik Sağlama",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"Seçilen istek başarıyla iptal edildi.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Tüm Müşteriler",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Abonelik Sağlama",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"etkinleştirme",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Varlık adı",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Başlık",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Yeni Miktar",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Yeni statü",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Eski Miktar",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Eski Durum",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonelik Adi",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Lütfen bekleyin earlier önceki satın alımınızı işleme koyarken bu işlem birkaç dakika sürebilir.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Lütfen bekleyin earlier önceki satın alımınızı işleme koyarken bu işlem birkaç dakika sürebilir. 'Yeni Abonelik Ekle' düğmesini tıklayarak da durumu kontrol edebilirsiniz.",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Listeye geri dön",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Aksiyon",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"ad",
      "SUBSCRIPTION_STATUS_ACTIVE":"Aktif",
      "SUBSCRIPTION_STATUS_DELETED":"silindi",
      "SUBSCRIPTION_STATUS_FAILED":"Başarısız oldu",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Karşılığı",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Başarılı",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Askıya alındı",
      "SUBSCRIPTION_UPDATED":"Abonelik başarıyla güncellendi.",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuel",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Abonelik Adı",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Bu alan gereklidir",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Bu müşteri zaten atandı.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Aşağıdaki kullanıcıların bu teklifte zaten atanmış bir lisansı var. Lütfen bunları kaldırın ve kaydetmeyi deneyin.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Hataları Yoksaymak istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Lisans Atamaları başarıyla güncellendi",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"Bu aboneliği yeniden etkinleştirmek istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"Seçilen abonelik başarıyla yeniden etkinleştirildi",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Kullanılmayan koltukları bırakmak istediğinizden emin misiniz?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Lisansları Atanmış Lisanslar tablosundan kaldırmak için lütfen en az bir kullanıcıyı kaldırın!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Ekle düğmesine tıklamadan önce lütfen önerilen listeden kullanıcı seçin.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"Bu aboneliği askıya almak istediğinizden emin misiniz? İlgili etkin eklenti aboneliklerinin de (varsa) askıya alınacağını lütfen unutmayın",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"Abonelik (uygulanabilir eklentiler dahil) başarıyla askıya alındı",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Aboneliğin kullanılmayan koltukları başarıyla yayınlandı.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Toplu atama isteğiniz sırada",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Başarıyla yüklendi",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Lütfen metin kutusuna lisans atamak için geçerli kullanıcı e-postalarını girin.",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonelikler",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Düzenle",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"yönetme",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Toplam",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"ad",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"miktar",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Maliyet",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Toplam",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Yeni Azure Aboneliği",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"ad",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"masmavi",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Yeni Abonelik Ekle",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Azure Abonelikleri",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Tahmini Fiyat",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Teklif Adı",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonelikler",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Aboneliği Güncelle",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Yeni Miktar Toplamı",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"Minimum Değer 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"Giriş geçerli bir tamsayı olmalıdır",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Teklif",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"<strong> Yeni Miktar Toplamı </strong> 'nı artırarak ek koltuk satın alın",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Kullanıcılardan Lisansları <strong> Kullanıcılar - & gt; Buradan koltuk sayısını azaltmak istediğinizde lisanslar </strong>",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Abonelik satın alma isteğiniz sırada",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Yeniden",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonelik başarıyla kaydedildi",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Lütfen sipariş vermeden önce en az bir teklif seçin",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"askıya almak",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Genel ayarlar başarıyla güncellendi",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"İş ortağı SMTP ayarları başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"{{Logo}} ortağı başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"İş ortağı ayarları başarıyla güncellendi.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Sağlayıcı ayarları başarıyla güncellendi.",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Miktar uyuşmazlığını düzeltin",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Lütfen bu müşterinin planını alakalı teklifleri içerecek şekilde güncelleyin ve sorunu düzeltmek için buraya geri dönün",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"CSP Teklif Adı",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Eşleşen teklif bulunamadı",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Mevcut planda eşleşen teklif",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Fiyat",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"miktar",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Teklif eşleme",
      "SUPPLIER_DETAILS":"Tedarikçi Detayları",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Yeni Destek Bileti",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"İletişim Telefonu",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Açıklama",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":"öncelik",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Ürün Alanı",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"konu",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"tip",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Destek",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET #":"Destek bileti #",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Yorum ekle",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Yorumlar",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"İletişim Telefonu",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Kümülatif Çaba",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Müşteri",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Açıklama",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Çaba (Saat Olarak)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"öncelik",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Ürün Alanı",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"durum",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"konu",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Destek Temsilcisi",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Destek Aracısını Seçin",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"tip",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Yorum eklenmedi.",
      "SUPPORT_FOR_TEXT":"İçin destek",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Destek Bileti Ekle",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"destek biletleri",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Müşteri",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Arama",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"durum",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Destek Temsilcisi",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Bilet Kimliği",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Aksiyon",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"ayrıntılar",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"İletişim E-posta",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Oluşturma Tarihi",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Müşteri",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"öncelik",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Ürün Alanı",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"durum",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"konu",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Bilet Kimliği",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"tip",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Yeni destek bileti başarıyla oluşturuldu",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Arşivlenen",
      "SUPPORT_TICKET_STATUS_CLOSED":"Kapalı",
      "SUPPORT_TICKET_STATUS_IN İLERLEME":"Devam etmekte",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"Devam etmekte",
      "SUPPORT_TICKET_STATUS_OPEN":"Açık",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Yeniden aç",
      "SUPPORT_TICKET_STATUS_RESOLVED":"kararlı",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"Düzeltilmeyecek",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Erişim sorunları",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"analitik",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Profil Yönetimi",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Abonelik Yönetimi",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Destek bileti bilgileri başarıyla güncellendi",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Para birimi",
      "SWAL_OK_TEXT":"tamam",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Yeni güvenlik modeline geç",
      "TAB_HEADING_PROVISIONING_STATUS":"Temel hazırlık durumu",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"{{Total}} kayıttan {{StartIndex}} - {{EndIndex}} gösteriliyor",
      "TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "TABLE_HEADER_TEXT_DATE":"tarih",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Açıklama",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Arıza Nedeni",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Büyük Miktar",
      "TABLE_HEADER_TEXT_KEY":"anahtar",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Ödenen miktar",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Ödeme Durumu",
      "TABLE_HEADER_TEXT_REMARKS":"Uyarılar",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Vergi miktarı",
      "TABLE_HEADER_TEXT_VALUE":"değer",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Seçilen Fatura (lar) Toplamı",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Arama...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Etiket başarıyla silindi",
      "TAG_SAVED_SUCCESS_MESSAGE":"Etiket başarıyla kaydedildi",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Plan Adı",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Ürün adı",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Etiketlere Geri Dön",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Ekle",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Düzenle",
      "TAGS_CAPTION_TEXT_TAG_FOR":"Etiketi",
      "TAGS_CAPTION_TEXT_TAGS_FOR":"İçin etiketler",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Hedeflenen Para Birimi",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Hedeflenen Para Birimi",
      "TARGET_PLAN_DISPLAY_NAME":"Hedef Planı",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Vergi Yüzdelerine Geri Dön",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Ekle",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Düzenle",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Vergi kuralları {Ülke, Eyalet Eyaleti ve Vergi Kodu}, {Ülke, Zip} veya {Ülke, Eyalet Eyaleti ve Zip} kombinasyonlarıyla tanımlanır",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Örn: ABD gibi bir ülke için eyalet, il ve posta kodunu boş bırakarak vergi kuralı tanımını değerlendirin, vergi oranının ABD'deki tüm müşterilere uygulandığı anlamına gelir. Belirli bir eyalet için kural tanımlamak istiyorsanız, kuralı tanımlarken Ülke ve Eyalet değerlerini belirtin",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Vergi Adı",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Vergi Yüzdesi",
      "TAX_PERCENTAGES_ADD_SUCCESS":"Vergi Yüzdesi başarıyla eklendi.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Müşteriler",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Vergi Yüzdesi başarıyla silindi.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Vergi Yüzdesi Ekleyin",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Listeye geri dön",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Vergi Yüzdeleri",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Sil",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Düzenle",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Aksiyon",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"İtibaren geçerli",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"Vergi %",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Vergi Yüzdesi başarıyla güncellendi.",
      "TAX_RULES_ADD_SUCCESS":"Vergi kuralı başarıyla eklendi",
      "TAX_RULES_DELETE_SUCCESS":"Vergi kuralı başarıyla silindi",
      "TAX_RULES_UPDATE_SUCCESS":"Vergi kuralı başarıyla güncellendi",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Kümülatif Vergi",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Çocuk vergi kuralları, hesaplamadaki her adımdan elde edilen miktara sırayla uygulanır.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Genel",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Toplam tutara uygulanan tek vergi yüzdesi.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Alt vergiler",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Bu tanımın vergi yüzdesi, çocuk vergi kurallarından toplam (toplam) olacaktır.",
      "TEMMUZ":"Temmuz",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Bu bayrağın işaretlenmesi müşterilerin abonelik miktarını azaltmasını önler",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Müşterilerin Azure aboneliklerini yönetmesini sağlama",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Müşterilerin Azure Abonelikleri Üzerinden Gruplar tanımlamasını sağlama",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Müşteri masmavi kullanımına uygulanacak işaretleyin (%). Örneğin: İşaretleme% 15 ise 1.15 girin",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Müşteri Para Birimi",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Destek Müşteri Ürünü Otomatik Fiyat Güncelleme Etkinlik Türü",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Bu alan işaretlenirse, müşteri onayını dikkate alırız.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Müşterilerden en az biri MCA'yı kabul ettiğinde bu alan güncellenir. Müşterilerden kabulü tekrar zorunlu kılmak için bu kutunun işaretini kaldırın.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul edilen son şartlar ve koşullar?",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Dahili referans için. Bu kimlik, müşteriyi dahili muhasebe sisteminizdeki bir kayıtla ilişkilendirmek için kullanılabilir",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Faturaların otomatik olarak onaylandığı ayın günü",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Otomatik ödemelerin işleme koyulduğu gün",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Faturaların son ödeme tarihi",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Faturaların otomatik olarak sonlandırıldığı ayın günü",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Şartlar ve koşullar bağlantısı",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Müşteri için satınalma siparişi numarasını tanımlayın",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Sistemin ödemeleri işleme koymasını istiyorsanız bu işareti işaretleyin (uygunsa CC / ACH)",
      "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Müşteri RI kullanımına uygulanacak işaretleyin (%).",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Müşteriyi Bildirmeli",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Müşteri Ürün Otomatik Fiyat Güncellemesini Destekleyin",
      "TENANT_CONFIG_DESC_TAX_CODE":"Vergi kodu, geçerli vergi yüzdesini belirlemek için kullanılır",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Şartlar ve Koşulları göster Kabul Kiracıya Giriş",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Müşterinin koltukları azaltmasına izin ver",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Azure etkinleştirilsin mi?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Kurumsal için Azure etkinleştirilsin mi?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Azure kullanımı için işaretleme",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Müşteri Para Birimi",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Müşteri Ürünü Otomatik Fiyat Güncelleme Etkinliği",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Etkin Müşteri Onayı?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Microsoft Müşteri Sözleşmesi kabul edildi mi?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Kabul Edilen İş Ortağı Şartları ve Koşulları?",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Faturalandırma Referans Kimliği",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Müşterinin faturaları görebileceği gün",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Otomatik ödeme günü",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Faturaların son ödeme tarihi",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Faturaların kesinleştiği gün",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"Şartlar ve koşul URL'si",
      "TENANT_CONFIG_DISP_PO_NUMBER":"PO #",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Otomatik ödeme faturaları?",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"RI için işaretleyin",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Şartlar ve Koşulları Göster Kabul Günlüğü",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Müşteri Ürünü Otomatik Fiyat Güncellemesi",
      "TENANT_CONFIG_DISP_TAX_CODE":"Vergi kodu",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Vergi Türü",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Lütfen {{tenantConfigName}} değerini girin",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Lütfen geçerli bir {{tenantConfigName}} sayısı girin",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Müşteri Yapılandırması başarıyla geri döndürüldü",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Müşteri yapılandırması başarıyla kaydedildi",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"{{Customername}} için müşteri yapılandırması başarıyla güncellendi",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Bölüm",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Alan adı",
      "TENANT_MANAGEMENT_LABEL_SITE":"yer",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"ad",
      "TERM_AND_CONDITIONS_LINK_TEXT":"İş ortağı kullanım şartları",
      "TERM_DURATION_DESC_DAY(S)":"Gün (ler)",
      "TERM_DURATION_DESC_MONTH":"Ay",
      "TERM_DURATION_DESC_MONTH(S)":"Ay (lar)",
      "TERM_DURATION_DESC_YEAR":"Yıl",
      "TERM_DURATION_DESC_YEAR(S)":"Yıl (lar)",
      "TERMS_AND_CONDITIONS_ACCEPT":"Lütfen şartlar ve koşulları kabul edin",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Şartlar ve koşullar başarıyla kabul edildi.",
      "TERMS_AND_CONDITIONS_BACK":"Şartlar ve Koşullara Geri Dön",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Lütfen aşağıdaki şartlar ve koşulları okuyun </b>. </br> </br> <ul> <li> <a ng-click='vm.PartnerAgreementClicked()'> İş ortağı kullanım koşulları </a> </li> </ul> </br> Tıklayarak \"Kabul\" düğmesine basarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Lütfen Microsoft tarafından sağlanan aşağıdaki şartlar ve koşulları okuyun. </br> \"Kabul Et\" düğmesini tıklayarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Lütfen aşağıda verilen sözleşme, şartlar ve koşulları <b> {{partnerName}} </b> ve Microsoft'tan okuyun. </br> </br> <ul> <li> <a ng-click='vm.PartnerAgreementClicked()'> {{hyperlinkContent}} </a> </li> </ul> </br> Tarafından \"Kabul Et\" düğmesini tıklayarak yukarıdaki kullanım şartlarını kabul etmiş olursunuz.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Ezidebit Anlaşmasının tamamını okumak için <a id='linkEzidebit' ng-click='vm.EzidebitAgreementClicked()'> buraya </a> tıklayın.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Ezidebit Anlaşması",
      "TERMS_AND_CONDITIONS_READ":"Şartlar ve koşulları okudum",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"İsteğiniz işleme koyulamıyor.",
      "TEST_EMAIL_LABEL":"Test EmailId",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Ölçek",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Ölçek",
      "TEST_SMTP_SETTING_TITLE":"SMTP ayarlarını test etme",
      "TEXT_ACH":"ACH",
      "TEXT_ACTIONS":"Aksiyon",
      "TEXT_BILL_FROM":"Fatura Gönderen",
      "TEXT_BILLING_PERIOD":"Fatura donemi",
      "TEXT_EMAIL_ID":"Email kimliği",
      "TEXT_GROUP_NAME":"Grup ismi",
      "TEXT_INVOICE_NUMBER":"Fatura numarası",
      "TEXT_INVOICE_SUMMARY":"Fatura özeti",
      "TEXT_MONTHLY_BUDGET":"Aylık bütçe",
      "TEXT_SIGN_IN_REQUIRED":"Oturum açmanız gerekiyor",
      "TEXT_TENANT_ID":"Kiracı Kimliği",
      "TEXT_THRESHOLDS":"Eşik",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Aksiyon",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"anlaşma",
      "TIKLAYIN":"Tıklayın",
      "TITLE_COUPON_CODE_TEXT":"Kupon Kodları",
      "TITLE_CUMULATIVE_TAXES":"Kümülatif Vergiler",
      "TITLE_ROLE_SELECT":"Rol Seçin",
      "TITLE_SUB_TAXES":"Alt Vergiler",
      "TITLE_TAGS":"Etiketler",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"Azure Aboneliklerine giriş",
      "TOOLTIP_COUPON_CODE_TEXT":"Kupon Kodu",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Ürünü Devre Dışı Bırak",
      "TOOLTIP_TEXT_EDIT_NAME":"Adı Düzenle",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Ürünü Etkinleştir",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Ürünü Bağla",
      "TOOLTIP_TEXT_QUANTITY":"miktar",
      "TOUR_POPUP_CONTENT":"<b> Yerleşik hoş geldiniz! </b> </br> Uygulamadaki özelliklerin kısa bir turuna göz atın.",
      "TOUR_POPUP_DISMISS_BUTTON":"Reddet",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Bir tur at",
      "TOUR_POPUP_TITLE":"Hoşgeldiniz",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Aksiyon",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Miktar",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Kategori",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Açıklama",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"İndirim",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Ürün adı",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"miktar",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Fatura döngüsü",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Faturalandırma Türü",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Kategori",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Tüketim türleri",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Ürün adı",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Sağlayıcı",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Satış Tipi",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Talimatlar",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Lütfen geçerli bir sayı girin",
      "UNIQUE_NAME_ERROR":"Bu ad zaten var, lütfen farklı bir şey seçin.",
      "UPDATE_CART_LINE_ITEM":"Güncellenmiş Sepet Öğesi",
      "UPDATE_CART_LINE_ITEM_FAILURE":"Alışveriş sepeti öğesi güncellenemedi",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Müşteri genel yapılandırmaları başarıyla güncellendi",
      "UPDATE_DEFAULT_VALUE":"Varsayılan olarak işaretle",
      "UPDATE_INVOICE_PONUMBER":"PO #",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"İş ortağı ayarları güncellenirken hata oluştu",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Vergi ayrıntıları güncellenirken hata oluştu",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"Ürün veya eklentilerinden biri için lisans ataması devam ederken, ürünlerin güncellenmesine izin verilmez. Lütfen daha sonra tekrar deneyin.",
      "UPDATE_PROFILE_FAILURE":"Profil güncellenemedi",
      "UPDATE_RESELLER_CONFIGURATION":"Bayi Yapılandırmasını Güncelle",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Bayi Yapılandırması güncellenemedi",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Bayi Varsayılan Ödeme Seçeneğini Güncelle",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Bayi Varsayılan Ödeme Seçeneği Güncelleme Hatası",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Güncellenmiş Bayi Ödeme Profili",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Satıcı Ödeme Profili güncellenemedi",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Bayi Planı Ürün Fiyatı Güncellendi",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Bayi planı ürün fiyatı güncellenemedi",
      "UPDATE_RESELLER_PROFILE":"Bayi Profilini Güncelle",
      "UPDATE_RESELLER_PROFILE_FAILURE":"Bayi Profili Güncelleme Başarısız",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Sağlayıcı müşterisinin güncellenmiş varsayılan değeri",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Sağlayıcı müşterisinin varsayılan değeri güncellenemedi",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Faturaları QuickBooks Online Toplu Kimliğine Yükleme",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Faturaları Quickbooks'a yükle hata mesajı",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Faturaları Xero Batch Id'ye yükleyin",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Faturaları Xero hata mesajına yükleme",
      "UPLOAD_TO_PSA_SELECT_ALL":"Hepsini seç",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Kullanım Raporu Alma",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Azure Abonelik Kimliği",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Abonelik Adi",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Müşteriye Fatura",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Ödeme Bitiş Tarihi",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Şarj Başlangıç ​​Tarihi",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Müsteriye maliyet",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"İş Ortağı Maliyeti (Vergi Sonrası)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"İş Ortağı Maliyeti (Vergi Öncesi)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Para Birimi Kodu",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"müşteri adı",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Kullanım Raporunu İndir",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Kiracı adı",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Kullanım Raporu",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Azure Abonelikleri",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Tehlike Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Hata Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Grup ismi",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Grup Seç",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Bilgi Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Müşteriden Gizle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Aylık bütçe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Abonelik Adı",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Uyarı Eşiği",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Abonelik Kullanıcılarına Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonelik Kullanıcısını Yönet",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"E-posta",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"rol",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"iştirakçi",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Sahip",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Okuyucu",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"İhracat Kullanım Ayrıntıları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Faturalandırma Özeti",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Kullanım Detayları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Harcama",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Tüketilen Birimler",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Kaynak tipi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adı",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonelik Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Müşterilere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"İçin Azure Abonelikleri",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Tüm Abonelikler",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Fatura donemi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"grup",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Aboneliği Düzenle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Kullanıcılar",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Kullanım Ayrıntılarını Görüntüle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Aksiyon",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Tahmini maliyeti",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"grup",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Abonelik Kimliği",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonelik Adi",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Eşik",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Aboneliğe Kullanıcı Ekle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Aboneliklere Geri Dön",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonelik kullanıcıları",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"E-posta",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"ad",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"rol",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Kullanıcıyı sil",
      "USER_CREATED_POPUP":"Kullanıcı Oluşturuldu",
      "USER_CREATION_SUCCESS_MESSAGE":"{{UserName}} kullanıcı başarıyla oluşturuldu",
      "USER_DETAILS_NOT_FOUND_BODY":"Giriş yapan kullanıcı ayrıntıları bulunamadı. Lütfen yöneticiye başvurun!",
      "USER_DETAILS_NOT_FOUND_HEADER":"Ayrıntılar bulunamadı",
      "USER_HAS_BEEN_CREATED":"Kullanıcı aşağıdaki ayrıntılarla oluşturuldu",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"Kullanıcı Lisans Ataması BatchId",
      "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Kullanıcı Lisans Ataması Toplu Durumu",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Bitti",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Lisans Atama Durumu",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"müşteri adı",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Kullanıcılar",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Kiracı adı",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Kiracı Kimliği",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Ayriyeten",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Paketi",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Bağlantılı",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Kullanıcı Kaldırma Durumu",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Kullanılmayan koltukları serbest bırakın",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Eklentilere değişiklik uygulama",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Ürün Kullanıcıları",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Koltuklar bırakılırken hata oluştu. Lütfen desteğe başvurun.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"En az bir aktif lisans olmalıdır. Artık kullanmak istemiyorsanız lütfen ürünü askıya alın.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Koltukların serbest bırakılması talebi işleniyor.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Başarıyla yüklendi",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"Lisans ekleme talebi kabul edilir ve işlenir.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"Lisansların toplu tahsisi talebi kabul edilir ve işlenir.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"atanan",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Toplam Koltuk Sayısı",
      "USER_LISTING_FILTER_LABEL":"Email kimliği",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Adı, Soyadı, E-posta Kimliği ile Arama",
      "USER_LISTING_SEARCH_BUTTON":"Arama",
      "USER_REMOVED":"Kullanıcı başarıyla kaldırıldı",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Rol ismi",
      "USER_ROLE_LIST_LABEL_SCOPE":"Kapsam Adı",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Kullanıcı adı",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Kullanıcı başarıyla eklendi",
      "USERCONTROLLER_USERUPDATESUCCESS":"Kullanıcı bilgileri başarıyla güncellendi",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Kullanıcının erişimi iptal edildi",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Ekle",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Düzenle",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Altında Kullanıcı",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"mevcut değil",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"gecerli",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Kullanıcı bilgisi",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Sırasıyla lisans atama / bırakma tekliflerinin yanındaki onay kutularını işaretleyin / işaretlerini kaldırın",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Bir teklife karşı yeterli ücretsiz lisans yoksa, atama yapılmadan önce bir satın alma işlemi yapılır",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"C3 tarafından bir abonelik sağlandığını gösterir. Lütfen işlem tamamlanana kadar bekleyin.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Ekran adı",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"E-posta",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"İsim",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Talimatlar",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Soyadı",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Kullanım Yeri",
      "USERS_ADD_EDIT_FORM_SEATS":"Koltuklar",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Kullanıcılara Geri Dön",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Ekle",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Düzenle",
      "USERS_ADD_OR_EDIT_CAPTION_3":"kullanıcı",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"E-posta",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"İsim",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Soyadı",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Kullanıcı oluşturmak için son toplu yükleme tamamlandı!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Harici kullanıcılara müşteri yöneticisi ayrıcalığı verilemez",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION":"Müşteriye yönetici ayrıcalığı vermek istediğinizden emin misiniz?",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Gösterge Paneli",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Kullanıcılar",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Kullanıcı oluşturma isteğiniz kabul edildi. Güncellemeler için lütfen bir süre sonra bu sayfaya geri dönün. İşlemin tamamlanması genellikle birkaç dakika sürer.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Hata ayrıntıları dışa aktarılırken hata oluştu",
      "USERS_BULK_CREATION_FIX_ERRORS":"Lütfen indirilen dosyada bildirilen hataları düzeltin ve tekrar deneyin.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Kullanıcı oluşturma isteğiniz başarıyla tamamlandı",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Yüklenen kullanıcıların ayrıntılarını alın",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"{{TotalUnits}} dışında kalan {{AvailableUnits}}",
      "USERS_BUY_MORE":"Daha fazla satın al",
      "USERS_CREATE_SUCCESS":"{{Username}} kullanıcı başarıyla oluşturuldu",
      "USERS_DELETE_CONFIRM":"Atanan tüm lisanslar kaldırılacak ve {{userName}} silinecek. Devam etmek istiyor musun?",
      "USERS_DELETE_EXISTENCE_CONFIRM":"Bu kullanıcının C3'te silinecek bir erişimi var. Devam etmek istiyor musun?",
      "USERS_DELETE_SUCCESS":"{{Username}} adlı kullanıcının silinmesi başarılı oldu",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Veri bulunamadı. Lütfen daha sonra tekrar deneyin",
      "USERS_GRANT_ACCESS_CONFIRM":"{{Username}} için müşteriye yönetici ayrıcalığı vermek istediğinizden emin misiniz?",
      "USERS_GRANT_ACCESS_SUCCESS":"{{Username}} kullanıcısına başarıyla erişim verildi",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Harici kullanıcılara erişim izni verilmiyor",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"Devam etmek istediğinizden emin misiniz?",
      "USERS_LICENSE_FREE_OUT_OF":"ücretsiz lisanslar",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"{{Username}} kullanıcısı için lisans atama üzerinde çalışıyor. Bu işlemin tamamlanması birkaç dakika sürebilir.",
      "USERS_LICENSES_REVOKE_SUCCESS":"{{Username}} alan adından alınan lisanslar başarıyla iptal edildi",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"{{Username}} alan adından alınan lisanslar başarıyla iptal edildi. Yeni lisans atama üzerinde çalışıyor. Bu işlemin tamamlanması birkaç dakika sürecektir.",
      "USERS_LICENSES_UPDATE_SUCCESS":"{{Username}} kullanıcısı için lisanslar başarıyla güncellendi",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Yeni Kullanıcı Ekle",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Çoklu Kullanıcı Yükleme",
      "USERS_LIST_CAPTION_TEXT_USERS":"Kullanıcılar",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Kullanıcı Ayrıntılarını Düzenle",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Portala erişim izni verme",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Şifreyi yenile",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Lisansları Görüntüle",
      "USERS_LIST_TABLE_HEADER_ACTION":"Aksiyon",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Email kimliği",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Aktif",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Hiçbir Kullanıcı rolü atanmadı.",
      "USERS_LIST_TABLE_HEADER_ROLE":"CSP Rolleri",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Kullanıcı adı",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Rolleri Göster",
      "USERS_PASSWORD_RESET_SUCCESS":"{{Username}} şifresi başarıyla sıfırlandı",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Şifre belirtilen alıcılara başarıyla gönderildi",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Şifre sıfırlama başarılı",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"Şifre sıfırlama başarılı, Şifre: <a ngclipboard='' data-clipboard-text='{{Password {} 'ng-click='vm.confirmCopy()'> <b> {{Şifre}} </ b > </a> <br/> <br/> <b> Bu sayfadan ayrıldıktan sonra, bu geçici şifreyi tekrar görüntüleyemezsiniz. </b> <br/> <br/> Göndermek istiyorsanız E-posta yoluyla şifre lütfen aşağıdaki metin kutusuna virgülle ayrılmış alıcılar sağlayın, aksi takdirde iptal düğmesine basın. <br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"{{UserRoleDescription | translate}} {{userName}} alanından ayrıcalık",
      "USERS_REVOKE_ACCESS_SUCCESS":"{{Username}} alan adından erişim iptal edildi",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Yüklenen kullanıcıların ayrıntılarını alın",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Yeni bir yükleme deneyin!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Hataları Görüntüleme ve Düzeltme",
      "USERS_STATUS_CAPTION_STATUS":"durum",
      "USERS_STATUS_WARNING_MESSAGE":"Son yüklemeden kullanıcıların oluşturulması hala devam ediyor. Lütfen Kullanıcılar sayfasındaki 'BulkUserUpload' düğmesini tıklayarak tekrar kontrol edin.",
      "USERS_TABLE_HEADER_EMAIL":"E-posta",
      "USERS_TABLE_HEADER_NAME":"ad",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Plan Açıklaması",
      "USERS_UPDATE_LICENCES_QUEUED":"Lisanslar güncelleme için sıraya alındı",
      "USERS_UPDATE_SUCCESS":"{{Username}} için kullanıcı bilgileri başarıyla güncellendi",
      "USERS_UPDATELICENCES_SUCCESS":"Lisanslar başarıyla güncellendi",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"{{UserName}} kullanıcı ayrıntısı başarıyla güncellendi",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Yükleme",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"Yüklenen verilerde tespit edilen hatalar. Hataları indirin, düzeltin ve tekrar yüklemeyi deneyin.",
      "USERS_UPLOAD_ERROR":"Yüklenen dosya ayrıştırılırken bir hata oluştu",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Lütfen yüklenecek bir dosya seçin",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Kaldırmak",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Kullanıcı bilgisi",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"Talimatlar",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Birden çok kullanıcı için hesap verilerini .csv biçiminde yükleyin. Referans için örnek bir .csv <strong> <a ng-click='vm.DownloadTemplate()'> Örnek bir .csv </a> </strong> dosyası indirin.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Bu dosya, bir müşteri için birden çok kullanıcı hesabı oluşturmak için kullanılabilir. Bu dosyadaki mevcut metin veya verileri güncellemeyin veya silmeyin. Kullanıcı hesapları Kullanıcı (yönetici değil) izinleriyle oluşturulacak",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Ad, sıfır ila 50 karakteri destekleyen isteğe bağlı bir alandır. Bir örnek: Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Soyadı, sıfır ila 50 karakteri destekleyen isteğe bağlı bir alandır. Bir örnek: Yeşil.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Görünen ad, bir ila 50 karakteri destekleyen zorunlu bir alandır. Bir örnek: Chris Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"E-posta zorunlu bir alandır. Her kullanıcı kaydı için bu alana geçerli bir e-posta adresi girin. Bir örnek: chris@contoso.com.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Durum güncelleme alanı, doğrulama durumunu iletmek için otomatik olarak doldurulur. Bu alanı boş bırakın.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Dosyayı yükleyin ve tıklayın <strong class = \"text-uppercase\"> Sunmak </strong>",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Dosya yükleme",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Kullanım Yeri",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Lisanslara Geri Dön",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Kullanıcılara dön",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Lisansları Düzenle",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Atanan Lisanslar",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Lisans Atanma Tarihi",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Ürün",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"özet",
      "VALIDATE_PRODUCT_QUANTITY":"{{product}} miktar {{quantity}}, {{minQuantity}} ile {{maxQuantity}} arasında olmalıdır",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Zaten müşteri bu kiracıya bağlandı.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Bu kupon kodu zaten var",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Kupon Kodunda sadece alfa sayısallarına izin verilir!",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Bu '{{emailAddress}}' e-postası zaten kullanılıyor.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Lütfen geçerli bir sayı girin",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Lütfen geçerli bir URL girin veya '/ # / contact /' olarak varsayılan URL'yi girin",
      "VALIDATION_MESSAGE_FOR_URL":"Lütfen geçerli bir URL girin.",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Lütfen geçerli bir URL girin veya 'home.support' olarak varsayılan URL'yi girin",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Lütfen geçerli bir Posta kodu girin",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Bu alan en fazla 50 karakter kabul eder.",
      "VALIDATION_MESSAGE_MINIMUM_0":"Minimum değer 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"Minimum değer 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"lütfen geçerli eposta adresini giriniz",
      "VALIDATION_MESSAGE_REQUIRED":"Bu alan gereklidir",
      "VALIDITY_TYPE_DESC_DAYS":"Gün (ler)",
      "VALIDITY_TYPE_DESC_MONTHS":"Ay (lar)",
      "VALIDITY_TYPE_DESC_YEARS":"Yıl (lar)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Geçerlilik türü",
      "VALIDITY_TYPE_NAME_DAYS":"Gün (ler)",
      "VALIDITY_TYPE_NAME_MONTHS":"Ay (lar)",
      "VALIDITY_TYPE_NAME_YEARS":"Yıl (lar)",
      "VALUE_SHOULD_BE_GREATER_THAN":"Değer 0'dan büyük olmalıdır",
      "VE":"ve",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Jetonu Doğrula",
      "VIEW_ALL_SELECTION":"Hepsini seç",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Kullanım Satır Öğelerini Görüntüle",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Kullanım Satır Öğelerini Görüntüle",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Fatura Oluştur",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Kârlılık Raporunu Yeniden Yükle",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Kullanıcı Ekle",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Kullanıcıyı sil",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"ad",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Etiket Ata",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rolleri Düzenle",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Ortak Kullanıcı Ekle",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Etiket Ata",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Ortağı Sil",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rolleri Düzenle",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Ortak Kullanıcı Yönetimi",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Kullanıcı yönetimi",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Aksiyon",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"E-posta",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Roller",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"Roller",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"İtibaren geçerli",
      "WEBHHOOK_LABEL_EVENT":"Etkinlik",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Listeye geri dön",
      "WEBHOOK_CANCEL_NOTIFICATION":"İptal etmek",
      "WEBHOOK_END_DATE_PLACEHOLER":"Bitiş tarihi",
      "WEBHOOK_HEADER_ACTIONS":"Aksiyon",
      "WEBHOOK_HEADER_DESCRIPTION":"Açıklama",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"İtibaren geçerli",
      "WEBHOOK_HEADER_EVENTNAME":"Etkinlik",
      "WEBHOOK_HEADER_TITLE":"Başlık",
      "WEBHOOK_LABEL_DESCRIPTION":"Açıklama",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Etkili",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Kategori",
      "WEBHOOK_LABEL_EVENT_TEXT":"Etkinlik",
      "WEBHOOK_LABEL_TITLE_TEXT":"Başlık",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Açıklama",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Ürün adı",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Başlık",
      "WEBHOOK_SAVE_NOTIFICATION":"Sunmak",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"seçmek",
      "WEBHOOK_START_DATE_PLACEHOLER":"Başlangıç ​​tarihi",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Bu alan gereklidir",
      "WELCOME_HEADER_1_PART_1":"Bizimle sonsuz olasılıklarınız var.",
      "WELCOME_HEADER_1_PART_2":"Ve şimdi başlıyor.",
      "WELCOME_PORTAL_FEATUR_1":"Self Servis Portalı",
      "WELCOME_PORTAL_FEATUR_2":"Abonelikleri hızlı ve kolay bir şekilde ekleyin / kaldırın",
      "WELCOME_PORTAL_FEATUR_3":"Otomatik ödemeler",
      "WELCOME_PORTAL_FEATUR_4":"Faturaları otomatik olarak ödeme",
      "WELCOME_PORTAL_FEATUR_5":"Güvenli",
      "WELCOME_PORTAL_FEATUR_6":"Microsoft Azure Active Directory ve Çok Faktörlü Kimlik Doğrulama ile güvence altına alındı",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Oturum aç",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Hoşgeldiniz",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Çıkış Yap",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"İletişim",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Gösterge Tablosuna Git",
      "Won''t Fix":"Düzeltilmeyecek",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Xero'ya rıza gösterdiğiniz için teşekkür ederiz. Uygulama, yeni güvenlik yönergesini izleyerek Xero'ya erişecek şekilde yapılandırılabilir. Müşterilerinizin faturalarını Xero'ya gönderebilirsiniz.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Lütfen <b> {{applicationName}} </b> 'da Faturaları Xero'ya Yükleme talimatlarını uygulayın.",
      "XERO_CONSENT_DESCRIPTION":"Faturaları Xero'ya kaydetmek için onayınızı vermeniz gerekir.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Lütfen Xero onayınızı işleme koyarken bekleyin",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Aşağıdaki bağlantı yeni bir sekmede açılır. Lütfen Xero kimlik bilgilerinizi onaylayın. Onay verirken bir hatayla karşılaşırsanız, lütfen destek ekibimizle iletişime geçin.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"Xero onayı vermek",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Onayı önceden verdiyseniz, Xero ile bağlantıyı doğrulamak için lütfen <b> Jetonu Doğrula </b> düğmesini tıklayın.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Mevcut Xero Yenileme Jetonunun geçerliliğini doğrulamak için bu düğmeyi tıklayın.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"İş Ortağı'nın müşteri faturalarını <b> XERO </b> 'ya kaydetmesi için önkoşullar",
      "XERO_POST_BATCH_ID":"Xero Post Toplu İş Kimliği",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Xero'ya fatura gönderme talebiniz kabul edilir ve işleme koyulur. Devam eden güncellemeler için lütfen <strong> Yazı Günlüklerini Görüntüle </strong> düğmesini tıklayın.",
      "XERO_POST_LOGS_STATUS":"Xero Post Günlükleri Durumu",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Mevcut Xero Yenileme Simgesi geçersiz. Lütfen rızanızı tekrar sağlayın",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Mevcut Xero Yenileme Simgesi geçerlidir. Faturaları yüklemeye devam edebilirsiniz",
      "XERO_TENANT_ID":"Sıfır Kiracı Kimliği",
      "YA FATURA EDİLECEK":"Ya fatura edilecek",
      "Year(s)":"Yıl (lar)",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Bu işlemi gerçekleştirme izniniz yok",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Abonelik oluşturmak için bir veya daha fazla teklif seçmelisiniz" 
  },

};
