import {PipeTransform, Pipe} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import { debug } from 'console';

@Pipe({
    name: 'CurrencyFilter',
    standalone:true
})
export class CurrencyPipe implements PipeTransform{

    transform(input:any, curSymbol:any='$', decPlaces:any='2', thouSep:any=',', decSep:any='.') {
        // right hand side should have configurations
        curSymbol = curSymbol || "$";
        decPlaces = decPlaces || "2";
        thouSep = thouSep || ",";
        decSep = decSep || ".";

        // Check for invalid inputs
        var out = isNaN(input) || input === '' || input === null ? 0.0 : input;

        //  Deal with the minus (negative numbers)
        var minus = input < 0;
        out = Math.abs(out);

        // need to take certain settings from application configuration

        if(decPlaces !=null && decPlaces != '' && decPlaces != undefined){

            decPlaces =   `0.${decPlaces}-${decPlaces}` 
        }

        // min number of integers(dot)min number of decimal(dot)max number of decimals
        out = new DecimalPipe("en-US").transform(out, decPlaces);
        
        //$filter('number')(out, decPlaces);

        // Replace the thousand and decimal separators.  
        // This is a two step process to avoid overlaps between the two
        if (thouSep !== ",") out = out.replace(/\,/g, "T");
        if (decSep !== ".") out = out.replace(/\./g, "D");
        out = out.replace(/T/g, thouSep);
        out = out.replace(/D/g, decSep);

        // Add the minus and the symbol
        if (minus) {
            return "-" + curSymbol + out;
        } else {
            return curSymbol + out;
        }




    }

}