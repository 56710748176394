// USA
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    TRANSLATE: {
      "academic":"Académico",
      "Academic":"Académico",
      "ACCEPT_BUTTON_INFO":"Al hacer clic en el botón Aceptar, acepta los términos de uso anteriores.",
      "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
      "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Aceptar o rechazar el consentimiento de renovación de la oferta del socio",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"Acceda al centro de socios utilizando la nueva configuración de seguridad.",
      "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Acceso a las API de administración de Microsoft Azure.",
      "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Acceso a la API de precios de Microsoft.",
      "ACCEPTANCE_STATUS":"Estado de aceptación",
      "ACCESS_TOKEN":"Token de acceso",
      "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"El administrador de cuentas se creó con éxito",
      "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Administrador de cuentas eliminado con éxito",
      "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"¿Está seguro, desea eliminar el administrador de la cuenta?",
      "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"El administrador de cuentas está asignado al cliente/revendedor. ¿Está seguro? ¿Desea eliminar el administrador de cuentas?",
      "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Buscar por ID de administrador de cuenta",
      "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Buscar por dirección de correo electrónico",
      "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Buscar por nombre",
      "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Buscar por apellido",
      "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Buscar por número de teléfono",
      "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Identificación del administrador de cuenta",
      "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Cerrar filtros",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Nombre de pila",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Apellido",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Número de teléfono",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Reiniciar",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Búsqueda",
      "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Los detalles del administrador de cuentas se actualizaron correctamente",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar administrador de cuenta",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar administrador de cuenta",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Identificación del administrador de la cuenta",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Correo electrónico",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Nombre de pila",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Apellido",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Número de teléfono",
      "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Agregar administrador de cuenta",
      "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Gerente de Cuentas",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"IDENTIFICACIÓN",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Nombre de pila",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Apellido",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Número de teléfono",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Eliminar administrador de cuenta",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Editar detalles",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Ver clientes asignados",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Número de cuenta",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"Tipo de cuenta",
      "ACH_FORM_LABEL_BANK_NAME":"Nombre del banco",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"es cuenta comercial",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Nombre en la cuenta",
      "ACH_FORM_LABEL_NO":"No",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Número de ruta",
      "ACH_FORM_LABEL_YES":" Sí",
      "ACTIONS_TO_FIX":"Por favor contacte al administrador para soporte",
      "ACTIONS_TO_FIX_500":"Estamos trabajando para solucionar el problema. Vuelve a consultar en un momento.",
      "ACTIVATE_CONFORMATION":"¿Estás seguro de que quieres activar?",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Seleccionar cliente",
      "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Todas las acciones--",
      "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Mostrando registros de",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"todos los clientes",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Todos los dominios.",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Notificaciones",
      "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Rever",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Fecha final",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Comportamiento",
      "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Metodo de contacto",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Nombre del evento",
      "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Fecha enviada",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Estado",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Tema",
      "ACTIVITY_LOGS_HEADER_TITLE_TO":"A",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Evento",
      "ACTIVITY_LOGS_TEXT_BCC":"CCO",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Fecha de inicio",
      "ACTIVITY_LOGS_TEXT_FAILED":"Ha fallado",
      "ACTIVITY_LOGS_TEXT_FROM":"De",
      "ACTIVITY_LOGS_TEXT_ON":"En",
      "ACTIVITY_LOGS_TEXT_STATUS":"Estado",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"Éxito",
      "ACTIVITY_LOGS_TEXT_TO":"A",
      "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"Ver correo electrónico",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Notificaciones",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Agregar ajuste",
      "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"El nombre de dominio no está disponible",
      "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"El nombre de dominio no está disponible",
      "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Añadir ofertas de socios",
      "ADD_CUSTOM_ROLE":"agregar rol personalizado",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"El cliente ha aceptado la última",
      "ADD_CUSTOMER_IN_QUOTE":"Agregar cliente",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"País",
      "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Por favor introduzca un codigo postal.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Por favor ingrese la dirección 1.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":" Ingrese la dirección 2.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Por favor ingrese la ciudad.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Por favor, introduzca el nombre de la empresa.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Por favor, introduzca la dirección de correo electrónico.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Por favor ingrese el primer nombre.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Por favor ingrese el apellido.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Por favor, introduzca el número de teléfono.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Por favor seleccione un país.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Seleccione un código de moneda.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Seleccione un plan.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Seleccione un estado.",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Agregar clientes",
      "ADD_DUE_DATE":"Añadir fecha de vencimiento",
      "ADD_ENTITLEMENT":"Agregar derecho",
      "ADD_ENTITLEMENT_DESCRIPTION":"Proporcione un nombre descriptivo para la suscripción.",
      "ADD_ENTITLEMENT_FAILED":"No se pudo agregar el derecho",
      "ADD_ENTITLEMENT_SUCCESS":"El derecho se agregó con éxito en Parter Ceneter, espere 15 minutos para el aprovisionamiento en C3",
      "ADD_MACRO_VALUE_ERROR":"Se requiere valor macro",
      "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Crear un nuevo nivel",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!valor máximo) ? \"Ingrese un valor mayor que \" + minValue : \"Ingrese un valor entre \" + minValue + \" y \" + maxValue}}",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Introduzca un valor entre {{Min}} y {{Max}}",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Cancelar",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Entregar",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Completo",
      "ADD_MISSING_OFFERS_IN_PROGRESS":"El plan de actualización con las últimas ofertas ya está en marcha.",
      "ADD_MISSING_OFFERS_INITIAT_FAIL":"Error al actualizar el plan con el inicio de las últimas ofertas",
      "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Se ha iniciado el plan de actualización con las últimas ofertas.",
      "ADD_MISSING_OFFERS_STATUS":"Progreso de la actualización del plan",
      "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Agregar ofertas faltantes al plan existente",
      "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Agregar ofertas faltantes a varios planes",
      "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Agregar ofertas faltantes al plan único",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"¿Otorgar acceso al contacto principal (como administrador del cliente)?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"¿Enviar invitación para acceder a la aplicación?",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
      "ADD_NEW_QUOTE":"Crear cotización",
      "ADD_NEW_USER_IN_QUOTE":"Añadir nuevo usuario",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Seleccione",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Agregar detalles básicos del plan",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Descripción",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Moneda de la factura",
      "ADD_PLAN_LABEL_TEXT_NAME":"Nombre",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Compra de moneda",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Ver detalles del contrato",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Comportamiento",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Macro",
      "ADD_PO_NUMBER":"Agregar número de orden de compra",
      "ADD_PONUMBER":"Agregar número de orden de compra",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Se recomienda que utilice la opción <b>Proveedor de enlace</b> para proporcionar el ID de MPN del revendedor y ayudar al revendedor a incorporar clientes eventualmente.",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"¡Recomendado! Establecer ID de MPN después de agregar el revendedor",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Rompedor de losas",
      "ADD_TO_CART_INFO":" Este producto está vinculado con <b>{{linkedProductName}}</b> si agrega este producto, el enlace también agregará",
      "addCustomer":"Agregar cliente",
      "ADDONS_LEBEL_TEXT":"Complementos",
      "ADDONS_PRODUCT_LEBEL_TEXT":"Producto adicional",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Dirección Línea 1",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Línea de dirección 2",
      "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Tipo de dirección",
      "ADDRESS_TABLE_HEADER_CITY":"Ciudad",
      "ADDRESS_TABLE_HEADER_COUNTRY":"País",
      "ADDRESS_TABLE_HEADER_STATE":"Estado",
      "ADDRESS_TABLE_HEADER_ZIP":"Cremallera",
      "ADDRESS_TYPE_BILLING":"Facturación",
      "ADDRESS_TYPE_COMPANY":"Compañía",
      "ADDRESS_TYPE_INVOICE":"Factura",
      "ADDRESS_TYPE_SHIPPING":"Envío",
      "AGO":"Atrás",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Copiado al portapapeles",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"La factura se aprueba correctamente.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"La factura se finaliza con éxito.",
      "ALERT_MESSAGE_MISSING_MPNID":"Se produjo un problema al copiar la URL. Conéctese con el socio.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Por favor seleccione al menos una factura",
      "ALL_CART_ITEM_COMMENT":"Artículo del carrito",
      "ALL_COMMENTS":"Comentarios",
      "ALL_PRODUCT_COMMENTS":"Producto",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Comuníquese con su administrador para eliminar las licencias.",
      "AN_ERROR_OCCURED":"Se produjo un error al procesar su solicitud.",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Historial de facturación de Azure",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Resumen de facturación de Azure",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Uso de Azure por parte del cliente",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Ingresos por clientes",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Suscripciones de Clientes",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Suscripciones por Planes",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"Historial de suscripciones",
      "AND":"y",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentimiento de la aplicación de precios de Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"Id. de aplicación de precios de Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de recurso de la aplicación de precios de Azure AD",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Clave secreta de la aplicación de precios de Azure AD",
      "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Ingrese el color hexadecimal del botón de la página de bienvenida",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentimiento de la aplicación de precios de Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"Id. de aplicación de precios de Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de recurso de la aplicación de precios de Azure AD",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Clave secreta de la aplicación de precios de Azure AD",
      "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Color del botón de la página de bienvenida",
      "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Banner de registro público",
      "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Logotipo de registro público",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Permitir carga de uso",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Cuando está habilitada, esta configuración impulsará los costos de uso mensuales incurridos para los productos que tienen consumo de uso. Los tipos de productos incluirán Azure-Plan y Custom-Usage.",
      "APPCONFIG_AUTOTASK_BILLING_CODE":"Código de facturación",
      "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Este código de facturación se utilizará para crear productos en Autotask",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Es Mapeo Automático (Autotask)",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Si desea mapear manualmente entre las entidades C3 y Autotask, deje esto sin marcar. Sin embargo, si la asignación automática está habilitada, las entidades se comparan comparando los nombres de las entidades en C3 con las entidades en Autotask.",
      "APPCONFIG_AUTOTASK_PASSWORD":"Clave",
      "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Contraseña que pertenece al usuario de la API",
      "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Prorrateo",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Frecuencia de carga automática",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Establecer la frecuencia de carga automática",
      "APPCONFIG_AUTOTASK_USERNAME":"Nombre de usuario",
      "APPCONFIG_AUTOTASK_USERNAME_DESC":"Nombre de usuario del usuario de API creado en Autotask",
      "APPCONFIG_DESC_ACCOUNT_NAME":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_ACCOUNT_NUMBER":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Dirección 1 que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Dirección 2 que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_ADVANCE_TEXT":"Texto personalizado de línea avanzada",
      "APPCONFIG_DESC_API_URL":"Url API base para ConnectWise Manage",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Habilitar seguimiento de licencias",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Mostrar análisis",
      "APPCONFIG_DESC_APPLICATION_NAME":"Nombre de la aplicación",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Fin del ciclo de facturación para el socio en el Centro de socios",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"ID de oferta de CSP de Azure",
      "APPCONFIG_DESC_BANK_NAME":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_BILLING_CYCLE":"Alineado con el Centro de socios o personalizado",
      "APPCONFIG_DESC_BILLING_DATE":"Aplicable cuando el ciclo de facturación es personalizado",
      "APPCONFIG_DESC_BILLING_PERIOD":"Período de facturación (determinado mediante otras configuraciones)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"Proveedor de facturación para el Portal C3",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Configuración del proveedor de facturación",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Inicio del ciclo de facturación. Aplicable al ciclo de facturación es personalizado",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Aparece en la vista de factura y en el pdf de factura en la sección factura de",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Aparece en la vista de factura y en el pdf de factura en la sección factura de",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Verifique los roles con 0365",
      "APPCONFIG_DESC_CIN_NUMBER":"CIN (Número de Identificación Corporativo) Que aparece en el pdf de la factura",
      "APPCONFIG_DESC_CITY":"Ciudad que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_CLIENT_ID":"ID de cliente para ConnectWise Manage",
      "APPCONFIG_DESC_COMPANY_NAME":"Nombre de la empresa que aparece en la vista de factura y en el pdf de la factura.",
      "APPCONFIG_DESC_COMPANY_URL":"URL de tu sitio web",
      "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Nombre de la empresa para ConnectWise Manage",
      "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Considere la fecha de generación en el informe de facturación",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Nombre que debe aparecer en la página de destino y en el pie de página después de que un usuario inicia sesión",
      "APPCONFIG_DESC_CONTACT_EMAIL":"Correo electrónico de contacto (normalmente su información o correo electrónico de soporte)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Nombre de la persona de contacto para su solicitud C3",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"Apellido de la persona de contacto para su solicitud C3",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Número de teléfono de la persona de contacto",
      "APPCONFIG_DESC_COUNTRY":"País que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Código de país",
      "APPCONFIG_DESC_CURRENCY_CODE":"Código de moneda",
      "APPCONFIG_DESC_CUSTOM_BILLING":"Aplicar facturación personalizada",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL de consentimiento del cliente",
      "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Restringir al cliente durante la incorporación bajo múltiples entidades",
      "APPCONFIG_DESC_DATE_FORMAT":"Formato de fecha",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Formato de fecha y hora",
      "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Úselo para establecer un formato de fecha para el valor del tipo de fecha en las API C3",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"URL de destino para la página, si desea tener una página de destino personalizada. Nota: En la página de destino, debe tener un botón y la URL de destino de ese botón debe ser <C3Site URL>/#/login",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Idioma predeterminado para el sitio web",
      "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Establece el recuento de páginas predeterminado para cada tabla del sistema.",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Términos y condiciones para pedir productos",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"Términos y condiciones URL (texto)",
      "APPCONFIG_DESC_EMAIL_LOGO":"Logotipo que aparece en los correos electrónicos enviados por C3. La dimensión preferida es 300x100.",
      "APPCONFIG_DESC_ENABLE_TOUR":"Habilite el botón 'Hacer un recorrido' en el encabezado del sitio.",
      "APPCONFIG_DESC_FAVICON_LOGO":"Un favicon es un pequeño icono visible en la pestaña del navegador web, justo antes del título de la página. Cargue un archivo .ico de 16x16",
      "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"¿Debería considerar la identificación de MPN de revendedores para clientes y compras?",
      "APPCONFIG_DESC_IFSC_CODE":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Aparece en la vista de factura y en el pdf de la factura como línea autorizada al pie de página",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Días de generación de facturas",
      "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cadena de conexión de almacenamiento en la nube",
      "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Factura zip días válidos",
      "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Cuando esta función está activada, el socio recibirá las solicitudes aprobadas por el revendedor para su aprobación.",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Enlace a la página Contáctenos (simplemente escriba /#/contact si no tiene uno)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Enlace a preguntas frecuentes del cliente (simplemente déjelo vacío si desea utilizar el predeterminado)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Enlace al Portal",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Enlace a la página de soporte (simplemente escriba home.support si no tiene uno)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Enlace al logotipo de bienvenida",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Logotipo que aparece en la parte superior izquierda después de que los usuarios inician sesión en C3. La dimensión preferida es 170x40. La página tiene un fondo negro, por lo que el gráfico debe ser de un color que contraste con el negro.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Iniciar sesión en el portal",
      "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"ID de cliente obligatorio en llamadas de API",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Número máximo de entradas para exportar a Excel",
      "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Recuento máximo de creación de derechos",
      "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Recuento máximo de creación de inquilinos",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Recordatorio de consentimiento de seguridad de Microsoft en días",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Monto mínimo de cargo para pagos de prueba",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"Términos y condiciones de NCE para pedir productos de Microsoft NCE",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL de términos y condiciones de NCE (texto)",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Habilita la funcionalidad de buscar clientes ya presentes en Facturación.",
      "APPCONFIG_DESC_PAN_NUMBER":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Id. de aplicación de una aplicación nativa en su centro asociado. Puede obtener esto a través de Administración de aplicaciones -> Aplicación nativa",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"Moneda del socio",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Contraseña del agente administrador",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Idiomas de preferencia de socios",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"ID de inquilino socio",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Nombre de usuario de la cuenta que tiene asignado el rol de administrador del agente",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL del acuerdo de revendedor de la nube de Microsoft",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL de términos y condiciones para socios",
      "APPCONFIG_DESC_PBI_GROUP_ID":"ID de grupo de Power BI",
      "APPCONFIG_DESC_PBI_PASSWORD":"Contraseña de Power BI",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Identificador de informe de Power BI",
      "APPCONFIG_DESC_PBI_USER_NAME":"Nombre de usuario de Power BI",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"ID de cliente de Power BI",
      "APPCONFIG_DESC_PDF_LOGO":"Logotipo que debe aparecer en el pdf de su aplicación C3. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o el gráfico deben estar en cualquier color que contraste con el blanco.",
      "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logotipo que debería aparecer en el pdf del lado derecho. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o el gráfico deben estar en cualquier color que contraste con el blanco.",
      "APPCONFIG_DESC_POSTAL_CODE":"Código postal que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Nombre que debe aparecer en la página de destino y en el pie de página después de que un usuario inicia sesión",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"URL de tu sitio web",
      "APPCONFIG_DESC_PRIVATE_KEY":"Clave privada para ConnectWise Manage",
      "APPCONFIG_DESC_PUBLIC_KEY":"Clave pública para ConnectWise Manage",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"ID de cliente de la aplicación QuickBooks",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"Secreto del cliente de la aplicación QuickBooks",
      "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Discrepancia de reconocimiento en los destinatarios de correo electrónico de generación de facturas por adelantado",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logotipo que aparece en la vista del informe de conciliación, al lado derecho del logotipo de Microsoft. La dimensión preferida es 170x40. La página tiene un fondo blanco, por lo que el texto o gráfico debe ser de cualquier color que contraste con el blanco.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Agregue los destinatarios como valores separados por comas",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Debe notificar al cliente",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Suspender la suscripción cuando se alcance el umbral de peligro",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Mostrar menú de informe de Power BI",
      "APPCONFIG_DESC_SHOW_FOOTER":"Al habilitar esta configuración, se mostrará el pie de página en todas las páginas.",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Mostrar siguiente paso",
      "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Habilite esta opción para mostrar los términos y condiciones para la administración de suscripciones.",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP habilita SSL",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"Correo electrónico desde el que el usuario recibirá notificaciones",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"Nombre para mostrar del remitente que aparecería en el correo electrónico",
      "APPCONFIG_DESC_SMTP_HOST":"Dirección del servidor de correo electrónico SMTP",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Clave",
      "APPCONFIG_DESC_SMTP_PORT":"número de puerto SMTP",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"Texto que debe ir precedido del asunto de la notificación por correo electrónico",
      "APPCONFIG_DESC_SMTP_USERNAME":"Nombre de usuario SMTP",
      "APPCONFIG_DESC_STATE":"Estado que aparece en la vista de factura y en pdf de factura.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"EtiquetaMPNIDParaclientesyrevendedores",
      "APPCONFIG_DESC_TAX_TYPE_KEY":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_TAX_TYPE_VALUE":"Aparece en el pdf de la factura",
      "APPCONFIG_DESC_WELCOME_LOGO":"Logotipo que debería aparecer en la página de inicio de su aplicación C3. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o gráfico debe ser de cualquier color que contraste con el blanco.",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Código de cuenta de la aplicación Xero para elementos de línea de ajuste",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Código de cuenta de la aplicación Xero para Azure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Código de cuenta de la aplicación Xero para ofertas personalizadas",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Código de cuenta de la aplicación Xero para licencias de Microsoft",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Código de cuenta de la aplicación Xero para elementos de línea únicos",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Código de cuenta de la aplicación Xero para elementos de línea de instancias reservadas",
      "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Nombre del tema de marca de la aplicación Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"ID de cliente de la aplicación Xero",
      "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Secreto del cliente de la aplicación Xero",
      "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"El valor de puntos decimales al que se redondean los importes contabilizados en Xero",
      "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Descripción adicional y formato de descripción de factura",
      "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Cultura de fechas para ConnectWise Administrar fechas",
      "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Considere los precios de ConnectWise Manage en lugar de los precios C3",
      "APPCONFIG_DISC_MAPPING_TYPE":"Seleccione el tipo de mapeo para mapear las entidades entre C3 y el servicio externo",
      "APPCONFIG_DISP_ACCOUNT_NAME":"Nombre de la cuenta",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Número de cuenta",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Dirección 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Dirección 2",
      "APPCONFIG_DISP_ADVANCE_TEXT":"Texto personalizado de línea avanzada",
      "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Texto de avance de visualización de Appconfig para anual",
      "APPCONFIG_DISP_API_URL":"URL de API",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Habilitar seguimiento de licencias",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Mostrar análisis",
      "APPCONFIG_DISP_APPLICATION_NAME":"Nombre de la aplicación",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Fecha de generación de la factura de Microsoft",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"ID de oferta de CSP de Azure",
      "APPCONFIG_DISP_BANK_NAME":"Nombre del banco",
      "APPCONFIG_DISP_BILLING_CYCLE":"Ciclo de facturación",
      "APPCONFIG_DISP_BILLING_DATE":"Fecha en que se generan las facturas en C3",
      "APPCONFIG_DISP_BILLING_PERIOD":"Período de facturación",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Proveedor de facturación",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Configuración del proveedor de facturación",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Día de inicio de facturación",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"Numero de negocios",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Clave del número comercial",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Verifique los roles con 0365",
      "APPCONFIG_DISP_CIN_NUMBER":"número NIC",
      "APPCONFIG_DISP_CITY":"Ciudad",
      "APPCONFIG_DISP_CLIENT_ID":"Identificación del cliente",
      "APPCONFIG_DISP_COMPANY_NAME":"Nombre de empresa",
      "APPCONFIG_DISP_COMPANY_URL":"URL de la compañía",
      "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Descripción adicional",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"nombre de empresa",
      "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Fecha Cultura",
      "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Considere ConnectWise Administrar Precios",
      "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Considere la fecha de generación en el informe de facturación",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Nombre de la empresa de contacto",
      "APPCONFIG_DISP_CONTACT_EMAIL":"Email de contacto",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Nombre de contacto",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"Apellido de contacto",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Teléfono de contacto",
      "APPCONFIG_DISP_COUNTRY":"País",
      "APPCONFIG_DISP_COUNTRY_CODE":"Código de país",
      "APPCONFIG_DISP_CURRENCY_CODE":"Código de moneda",
      "APPCONFIG_DISP_CUSTOM_BILLING":"Aplicar facturación personalizada",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL de consentimiento del cliente",
      "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Restringir al cliente durante la incorporación bajo múltiples entidades",
      "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Formato de fecha y hora para las API C3",
      "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Días para enviar la notificación de estado del carrito",
      "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Número de días para almacenar el archivo de factura masiva en el almacenamiento en la nube",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"URL de página de destino personalizada",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Idioma predeterminado",
      "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Recuento de páginas predeterminado",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"Términos y condiciones URL (texto)",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL de términos y condiciones",
      "APPCONFIG_DISP_EMAIL_LOGO":"Logotipo de correo electrónico",
      "APPCONFIG_DISP_ENABLE_TOUR":"Habilitar recorrido",
      "APPCONFIG_DISP_FAVICON_LOGO":"logotipo de favicon",
      "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"¿Debería considerar la identificación de MPN de revendedores para clientes y compras?",
      "APPCONFIG_DISP_IFSC_CODE":"Código IFSC",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Línea autorizada del pie de página de la factura",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Línea de pie de página de factura",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Días de generación de facturas",
      "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cadena de conexión de almacenamiento en la nube",
      "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Factura zip días válidos",
      "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Se requiere aprobación del socio",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Catálogo público respaldado por Azure Search",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Enlace a la página Contáctenos",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Enlace a las preguntas frecuentes del cliente",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Enlace al Portal",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Enlace al soporte",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Enlace al logotipo de bienvenida",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Logotipo de sesión iniciada",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Iniciar sesión en el portal",
      "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":" ID de cliente obligatorio en llamadas de API",
      "APPCONFIG_DISP_MAPPING_TYPE":"Tipo de mapeo",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Entradas máximas para exportación",
      "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Recuento máximo de creación de derechos",
      "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Recuento máximo de creación de inquilinos",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Recordatorio de consentimiento de seguridad de Microsoft en días",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Monto mínimo de cargo",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL de términos y condiciones de NCE (texto)",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL de términos y condiciones de NCE",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Búsqueda de clientes integrada con facturación",
      "APPCONFIG_DISP_PAN_NUMBER":"Número de bandeja",
      "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"tecla numérica PAN",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"ID de aplicación nativa",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"Moneda del socio",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Contraseña del agente administrador",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Paquetes de idiomas",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"ID de inquilino socio",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Nombre de usuario del agente administrador",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL del acuerdo de revendedor de Microsoft Cloud",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL de términos y condiciones para socios",
      "APPCONFIG_DISP_PBI_GROUP_ID":"ID de grupo de Power BI",
      "APPCONFIG_DISP_PBI_PASSWORD":"Contraseña de Power BI",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Identificador de informe de Power BI",
      "APPCONFIG_DISP_PBI_USER_NAME":"Nombre de usuario de Power BI",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"ID de cliente de Power BI",
      "APPCONFIG_DISP_PDF_LOGO":"Logotipo PDF",
      "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"Logotipo del lado derecho del PDF",
      "APPCONFIG_DISP_POSTAL_CODE":"Cremallera",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Desarrollado por el nombre de la empresa",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Desarrollado por la URL de la empresa",
      "APPCONFIG_DISP_PRIVATE_KEY":"Llave privada",
      "APPCONFIG_DISP_PUBLIC_KEY":"Llave pública",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"ID de cliente de QuickBooks",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"Secreto del cliente de QuickBooks",
      "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Discrepancia de reconocimiento en los destinatarios de correo electrónico de generación de facturas por adelantado",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Logotipo del informe de conciliación",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Informe de conciliación Notificar al destinatario",
      "APPCONFIG_DISP_REFUND_TEXT":"Texto de reembolso de visualización de Appconfig",
      "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Texto de reembolso de Appconfig disp para anual",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Suspender la suscripción cuando se alcance el umbral de peligro",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Mostrar menú de informe de Power BI",
      "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Habilitar para obtener el número de factura externa en el informe de resumen de factura",
      "APPCONFIG_DISP_SHOW_FOOTER":"Mostrar pie de página",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Mostrar siguiente paso",
      "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Habilitar términos y condiciones para administrar suscripciones",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"Habilitar SSL",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"Desde el e-mail",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"Del nombre para mostrar",
      "APPCONFIG_DISP_SMTP_HOST":"Anfitrión",
      "APPCONFIG_DISP_SMTP_PASSWORD":"contraseña SMTP",
      "APPCONFIG_DISP_SMTP_PORT":"Puerto SMTP",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Prefijo de asunto",
      "APPCONFIG_DISP_SMTP_USERNAME":"Nombre de usuario",
      "APPCONFIG_DISP_STATE":"Estado",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"EtiquetaMPNIDParaclientesyrevendedores",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Tipo de impuesto",
      "APPCONFIG_DISP_TAX_TYPE_VALUE":"valor fiscal",
      "APPCONFIG_DISP_WELCOME_LOGO":"Logotipo de bienvenida",
      "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Pagina de bienvenida",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Código de cuenta Xero para ajustes",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Código de cuenta de la aplicación Xero para Azure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Código de cuenta de la aplicación Xero para Custom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Código de cuenta de la aplicación Xero para MSLicense",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Código de cuenta de Xero para OneTime",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Código de cuenta Xero para instancias reservadas",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"Nombre del tema de marca de la aplicación Xero",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"ID de cliente de Xero",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"secreto de cliente Xero",
      "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xero puntos decimales",
      "APPCONFIG_SELECTED_SMTP_OPTION":"Seleccione el tipo SMTP",
      "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Seleccione la opción SMTP",
      "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Mostrar el número de factura externa en el informe de resumen de facturas",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"ID de aplicación de autenticación moderna",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"ID de cliente de la aplicación Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Contraseña de la aplicación de autenticación moderna",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Secreto de cliente de la aplicación Azure AD",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Autenticación moderna desde el correo electrónico",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"Correo electrónico desde el que el usuario recibirá notificaciones",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"ID de inquilino de autenticación moderna",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"ID de inquilino de la cuenta de usuario que se utilizará para enviar correos electrónicos",
      "APPEND_CUSTOM_TRIAL_DAYS":"(Periodo de prueba)",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":" El límite restante después de aprobar esta solicitud será",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" El límite restante actual se actualizará después de una renovación exitosa.",
      "approvals":"Aprobaciones",
      "APPROVED_AND_INITIATED":"Aprobado e iniciado",
      "APPROVED_BY":"por distribuidor",
      "APRIL":"Abril",
      "ASSIGN_BUNDLE_PRODUCTS":"Asignar productos de paquete",
      "ASSIGN_CUSTOMER_PLAN":"Asignar plan de cliente",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"¿Está seguro de que desea asignar {{customer}} a {{accountManager}}?",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El cliente se asignó con éxito",
      "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El revendedor se asignó correctamente",
      "ASSIGN_USAGE_PRODUCTS":"Asignar productos de uso",
      "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Cliente asignado al administrador de cuentas con éxito",
      "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Distribuidor asignado al administrador de cuentas con éxito",
      "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Entiendo que cada persona que utilice Windows 365 Business con Windows Hybrid Benefit también debe tener una copia válida de Windows 10/11 Pro instalada en su dispositivo de trabajo principal.",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Cerca",
      "AUDIT_LOG_DETAIL":"Detalles",
      "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Ocultar detalles",
      "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Más detalles",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Oculto",
      "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Todas las acciones--",
      "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Todos los clientes--",
      "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Todos los dominios--",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Registro de auditoría",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Búsqueda",
      "AUDITLOG_TEXT_ACTION_NAME":"Comportamiento",
      "AUDITLOG_TEXT_AUDITLOG":"Registro de auditoría",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"Válido desde",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Efectivo para",
      "AUDITLOG_TEXT_FROM":"De",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Nombre de dominio del cliente",
      "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Mostrar registros de auditoría ocultos",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Comportamiento",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Cliente afectado",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Fecha",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Iniciador",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Ver detalles",
      "AUDITLOG_TEXT_TO":"A",
      "AUGUST":"Agosto",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Código de autenticación recibido de Quickbooks",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Código de autenticación recibido de Xero",
      "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"El lote ya está cerrado.",
      "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Solicitud completada con éxito",
      "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"La tarjeta de crédito ha caducado.",
      "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"El lote de dispositivos está lleno. Cierre el lote.",
      "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"El dispositivo ha sido deshabilitado.",
      "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Una transacción duplicada ha sido enviado.",
      "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"El identificador del dispositivo no está registrado o no está habilitado.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"El ID de lote del dispositivo no es válido.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"El ID del dispositivo no es válido.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"La bandera de inversión no es válida.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"El código de seguridad especificado no es válido.",
      "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Se produjo un error de validación en el procesador.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Los datos de la solicitud no pasaron la verificación de campos obligatorios para esta aplicación.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Los campos de solicitud no son válidos o faltan.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"Este comerciante no acepta transacciones de V.me.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"No se puede encontrar la transacción.",
      "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"La solicitud de anulación falló. El tipo de transacción original no admite la anulación o la transacción está en proceso de liquidación.",
      "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"El comerciante se activó con éxito.",
      "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"El comerciante está configurado para el cierre automático.",
      "AUTHORIZE_NET_CODE_NEED_CONSET":"Necesita el consentimiento del pagador.",
      "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Este pedido ya se ha liberado o anulado, por lo que no se pueden aceptar nuevas asociaciones de transacciones.",
      "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Tu orden ha sido recibida. ¡Gracias por hacer negocios!",
      "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"La transacción original está en un lote cerrado.",
      "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"El servicio de Cuenta de Pasarela de Pago (id=8) ya ha sido aceptado.",
      "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Se solicitó un nuevo código de seguridad.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Esta transacción ya ha sido autorizada.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal ya capturó esta transacción.",
      "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"La reversión se procesó con éxito.",
      "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"El dispositivo móvil se insertó con éxito en la base de datos.",
      "AUTHORIZE_NET_CODE_SUCCESS":"La solicitud fue procesada con éxito.",
      "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"El dispositivo móvil fue registrado y aprobado con éxito por el administrador de la cuenta.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Esta transacción ya ha sido anulada.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Esta transacción ya ha sido capturada.",
      "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"No se encontró la transacción original para la reversión.",
      "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal ha rechazado la transacción. x_paypal_hdrimg supera la longitud máxima permitida.",
      "AUTHORIZE_NET_CODE_USE_XMETHOD":"Use x_method para especificar el método o envíe solo x_call_id o información de tarjeta/cuenta.",
      "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"Este es un trabajo en progreso. Este mensaje no se lanzará a producción. Es solo un marcador de posición de desarrollo.",
      "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"La tasa de conversión para esta tarjeta está disponible.",
      "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"El código de la tarjeta no es válido.",
      "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Esta transacción no puede ser aceptada.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Esta operación no se puede procesar.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Esta cuenta no ha recibido los permisos necesarios para esta solicitud.",
      "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"El nombre dado en la cuenta y/o el tipo de cuenta no coincide con la cuenta real.",
      "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"Este sistema no puede aceptar transacciones ACH.",
      "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"El indicador de autenticación no es válido.",
      "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"El código de autorización no es válido.",
      "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"El tipo de cuenta bancaria no es válido.",
      "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"La facturación recurrente no está permitida para este tipo de eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"El valor de autenticación del titular de la tarjeta no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"El código ABA no es válido",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"El token de acceso proporcionado ha caducado.",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"El token de acceso utilizado para validar la solicitud es insuficiente para hacerlo.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"El método API solicitado no se puede ejecutar mientras la cuenta de la pasarela de pago está en modo de prueba.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Este comerciante no está asociado con este revendedor.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"La cuenta de comerciante debe estar configurada para aceptar pagos con tarjeta de crédito, pagos con cheque electrónico o ambos.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"La pasarela de pago, el revendedor o la cuenta de usuario no están activos actualmente.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"La cuenta de la pasarela de pago no está configurada para procesar suscripciones con tarjeta de crédito.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"La cuenta de la pasarela de pago no está configurada para procesar suscripciones de eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"El número de cuenta no es válido",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"El monto de la suscripción es obligatorio al crear una suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"Este comerciante no acepta transacciones ACH.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"La suma de todos los créditos contra esta transacción es mayor que el monto de la transacción original.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Todos los campos estaban vacíos o faltaban.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Todos los campos estaban vacíos o faltaban.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Valor no válido.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Valor demasiado largo.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"La suscripción ya ha sido cancelada.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"El registro ya ha sido eliminado.",
      "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"No se devolvió una aprobación para la transacción.",
      "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"No se enviaron uno o más valores de subasta requeridos.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"El tipo de autenticación no está permitido para la llamada al método solicitado.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Se requiere un código de autorización pero no está presente.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Este procesador no acepta autorizaciones de cero dólares.",
      "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"La transacción ha sido rechazada debido a un desajuste de AVS. La dirección proporcionada no coincide con la dirección de facturación del titular de la tarjeta.",
      "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"El método de pago bancario no se acepta para el país comercial seleccionado.",
      "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s no se puede dejar en blanco.",
      "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Ocurrió un error durante el procesamiento. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"Error de devolución del servicio web de llamada.",
      "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"Cancelar URL es obligatorio.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Esta transacción está pendiente de liquidación y no se puede reembolsar.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"El número de ocurrencias totales no puede extender la duración de la suscripción más allá de tres años desde la fecha de inicio.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"La tarjeta de crédito ha caducado.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"El número de la tarjeta de crédito es inválido.",
      "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Los totales de los montos de los artículos del carrito no coinciden con los montos de los pedidos. Asegúrese de que el total de los parámetros del artículo de detalle de pago sume el total del pedido.",
      "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"La cuenta de la pasarela de pago no está habilitada para el servicio de facturación o CIM.",
      "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Autorización de cliente denegada.",
      "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Los únicos tipos de contenido admitidos son texto/xml y aplicación/xml.",
      "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"No se pudieron descifrar los blobs DUKPT y se devolvió el error.",
      "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"El país no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"La tarjeta de crédito no es válida a partir de la fecha de inicio de la suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"El número de la tarjeta de crédito es inválido.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"El método de pago con tarjeta de crédito no se acepta para el país comercial seleccionado.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Este comerciante no acepta transacciones con tarjeta de crédito.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId y los datos del cliente son mutuamente excluyentes, solo se puede proporcionar uno de ellos para una sola solicitud.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Error al crear un perfil de cliente a partir de la transacción.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Se requiere la identificación fiscal del cliente o la información de la licencia de conducir (número de licencia de conducir, estado de la licencia de conducir, fecha de nacimiento de la licencia de conducir) para la suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Al usar el perfil del cliente, se debe incluir CustomerPaymentProfileId.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Error al crear un perfil de pago del cliente a partir de la transacción.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de cliente especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
      "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"Se agotó el tiempo de espera de la operación de la base de datos antes de que pudiera completarse.",
      "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"La suma de los créditos contra la transacción a la que se hace referencia excedería el monto del débito original.",
      "AUTHORIZE_NET_ERROR_CODE_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Se produjo un error al descifrar los datos EMV.",
      "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"El perfil de cliente no tiene un perfil de pago/envío predeterminado.",
      "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"La fecha de vencimiento está vencida o no está especificada.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Ya se envió un duplicado de la suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Una transacción duplicada ha sido enviado.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"KSN está duplicado.",
      "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Se requiere ID de perfil de cliente válido o correo electrónico",
      "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"El ID de empleado debe tener de 1 a %x caracteres de longitud.",
      "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"Los datos EMV no son compatibles con este tipo de mercado.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"La fecha de vencimiento de la tarjeta de crédito no es válida.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"No se pudo enviar el correo electrónico.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Validación de clave compartida fallida.",
      "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Error fatal al llamar al servicio web.",
      "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"La identificación del comerciante de FDC o la identificación de la terminal es incorrecta. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"El tipo de campo no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"Uno de los valores del campo no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"Uno de los campos tiene una longitud no válida.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"Uno de los campos obligatorios no estaba presente.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"No se puede generar la huella digital.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"El valor de la huella dactilar no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Error genérico InsertNewMerchant.",
      "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Los números de identificación del Sistema de Pago Global son incorrectos. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_HASH":"Hash no coincide.",
      "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"La página de pago alojada capturará la información de pago (banco/tarjeta), por lo que esta información no debe incluirse con esta solicitud.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"La clave API de suplantación no es válida o no está presente.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"El ID de inicio de sesión del socio de suplantación no es válido o no está presente.",
      "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Una vez que se crea una suscripción, el intervalo no se puede cambiar.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"El nombre de usuario de API no es válido y/o la clave de transacción o la clave de API no es válida.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"El ID del comprador de la subasta no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"El tipo de transacción no es válido para transacciones ACH.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"Falta información o no es válida en un campo obligatorio.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"La duración del intervalo debe ser de 7 a 365 días o de 1 a 12 meses.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal ha rechazado la transacción. ID de pagador no válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"La combinación del ID de la plataforma de subastas y el nombre de la plataforma de subastas no es válida.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"El valor del modo de entrada de POS no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"El Id. de perfil de cliente, el Id. de perfil de pago, el Id. de dirección de envío o el Id. de transacción de esta solicitud no son válidos para este comerciante.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"El nombre del nodo raíz de la solicitud XML es el método API al que se llama. No es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"El ID del vendedor de la subasta no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"El monto solicitado para la liquidación no puede ser diferente al monto original autorizado. Anule la transacción si es necesario",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"La transacción de V.me está en un estado no válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"La autenticación de usuario requiere un valor válido para la clave de transacción o la clave API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"La autenticación de usuario requiere un valor válido para el nombre de usuario de la API.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Valor no válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"La versión EMV no es válida.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"El valor x_call_id no se encuentra o no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"El valor x_descuento no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"El valor x_giftwrap no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"El valor x_misc no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal ha rechazado la transacción. x_paypal_payflowcolor debe ser un valor hexadecimal de 6 caracteres.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"El valor x_subtotal no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Esta factura ha sido cancelada por el remitente. Póngase en contacto directamente con el remitente si tiene preguntas.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Número de factura no encontró ningún registro.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"El monto de la transacción enviada fue mayor que el monto máximo permitido.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Se ha alcanzado el número máximo de elementos para una matriz.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Se ha alcanzado el número máximo de perfiles de pago para el perfil de cliente.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Se ha alcanzado el número máximo de direcciones de envío para el perfil de cliente.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"La cuenta de revendedor no está habilitada para Merchant Boarding API.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"El comerciante no acepta este tipo de tarjeta de crédito.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"El comerciante ha rechazado la autorización del recurso.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"El comerciante no acepta este tipo de tarjeta de crédito.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"El ID de inicio de sesión del comerciante o la contraseña no son válidos o la cuenta está inactiva.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"El comerciante tiene demasiados dispositivos en estado pendiente.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"El comerciante no está activado.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"La contraseña del comerciante no es válida o no está presente.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"El comerciante se configuró incorrectamente en el procesador. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Los números de identificación de Paymentech son incorrectos. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"No se pudo analizar MerchantId a entero",
      "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"Falta el valor x_call_id.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"El identificador del dispositivo móvil no está asociado con la cuenta del comerciante.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"El dispositivo móvil existe pero está en estado pendiente.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"El dispositivo móvil existe pero tiene un estado deshabilitado.",
      "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"El país debe ser un valor válido de dos o tres caracteres si se especifica.",
      "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"El comerciante autenticado no tiene acceso al token OTS especificado.",
      "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"No se han encontrado registros que coincidan con su consulta.",
      "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"No hay suscripciones disponibles para la cuenta de comerciante para el tipo de suscripciones solicitadas.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"El token de acceso no tiene permiso para llamar al método API.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"La transacción fue autorizada pero el cliente no pudo ser notificado; no se resolverá.",
      "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me de Visa no admite anulaciones en transacciones capturadas o de crédito. Permita que la transacción se liquide y luego procese un reembolso por la transacción capturada.",
      "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"Uno de los valores del campo no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Solo se permite tarjeta de crédito o banco.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile y profileIds son mutuamente excluyentes, solo se puede proporcionar uno de ellos a la vez.",
      "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"La creación del perfil de pago con este descriptor OpaqueData requiere que el modo de transacción se establezca en modo en vivo.",
      "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Si se incluye el ID de perfil de cliente, debe coincidir con el ID de perfil de cliente utilizado para la suscripción original.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Los datos del token OTS especificados no son válidos.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"El token OTS especificado ha caducado.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"El token OTS especificado no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"El Servicio OTS no puede completar la solicitud debido a un error de validación o configuración.",
      "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"El desplazamiento de paginación supera el valor permitido. Comprobar y bajar el valor.",
      "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Se produjo un error al analizar los datos de EMV.",
      "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Este procesador no acepta reversiones parciales.",
      "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"La cuenta de socio no está autorizada para suplantar la cuenta de inicio de sesión.",
      "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal ha rechazado la transacción. Este ID de pagador pertenece a un cliente diferente.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"El servicio de Cuenta de Pasarela de Pago (id=8) ya ha sido aceptado. No se permite el rechazo.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"El servicio de la cuenta de pasarela de pago (id=8) ya ha sido rechazado.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"La cuenta de la pasarela de pago no está habilitada para la facturación recurrente automatizada.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"La cuenta de la pasarela de pago no está habilitada para el Administrador de información del cliente (CIM).",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Se requiere información de pago al crear una suscripción o un perfil de pago.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"La creación del perfil del cliente falló. Este método de pago no admite la creación de perfiles.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de pago especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Se requiere un cronograma de pago al crear una suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"Actualmente no se admite cambiar el tipo de pago de suscripción entre tarjeta de crédito y eCheck.Net.",
      "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Estado Pendiente (no completado).",
      "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"Requisito previo fallido.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"La cuenta del procesador no se ha configurado para establecer el indicador de modo de prueba en falso.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"El comerciante aún no ha proporcionado la información del procesador para establecer el indicador de modo de prueba en falso.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"La configuración con procesador no es válida. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"La creación del perfil del cliente falló. Este ID de transacción no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Los ID de perfil de pago y envío no se pueden especificar al crear nuevos perfiles.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Los ID de perfil de pago y envío no se pueden especificar al solicitar una página de pago alojada.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Si se incluyen el ID del perfil del cliente, el ID del perfil de pago y el ID de la dirección de envío, deben coincidir con la transacción original.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"La búsqueda del perfil de envío utilizando la identificación del perfil del cliente y la identificación del perfil de envío no encontró ningún registro.",
      "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Ya se envió un duplicado del perfil del cliente, el perfil de pago del cliente o la dirección del cliente.",
      "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"La configuración de facturación recurrente es un campo obligatorio para las transacciones de pago tokenizadas recurrentes.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"ID de referencia no encontrado.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"La transacción referenciada no cumple con los criterios para emitir un crédito.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"La acción solicitada no está permitida debido al estado actual del objeto.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Al solicitar CreateProfile los datos de pago no pueden ser nulos.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Pago no autorizado. El pago no ha sido autorizado por el usuario.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"Se requiere la versión EMV.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"El número de terminal/carril debe ser numérico.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Se requiere un descriptor opaco.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"Se requiere identificación del pagador.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"La firma debe ser datos con formato PNG.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"Las transacciones de PayPal requieren una URL válida para cancel_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Las transacciones de PayPal requieren una URL válida para success_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Falta el nodo requerido.",
      "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Se devolvió un error de validación de V.me.",
      "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Actualmente estamos reteniendo la última transacción para su revisión. Antes de reactivar la suscripción, revise la transacción.",
      "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"El nodo raíz no hace referencia a un espacio de nombres XML válido.",
      "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"No se puede establecer una tasa de compra inferior a la tasa de venta",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"El servidor está demasiado ocupado en este momento, inténtelo de nuevo más tarde.",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Error del Servidor",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"El servidor está en mantenimiento, por lo que el método solicitado no está disponible. Por favor, inténtelo de nuevo más tarde.",
      "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Su sesión ha caducado o no existe. Debe iniciar sesión nuevamente para continuar trabajando.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"El monto solicitado para liquidación no puede ser mayor al monto original autorizado.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"La autorización fue aprobada pero fracasó la liquidación.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"El ID de dirección de envío y los datos de envío son mutuamente excluyentes, solo se puede proporcionar uno de ellos para una sola solicitud.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de envío especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Al usar el perfil del cliente con la información de la tarjeta de crédito para especificar el perfil de envío, se debe incluir la identificación del perfil de envío.",
      "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Falta la clave de firma.",
      "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"No se pueden generar informes para fechas futuras, por lo que la fecha especificada no es válida.",
      "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Una vez que se crea una suscripción, la fecha de inicio no se puede cambiar.",
      "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"El estado no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"La fecha de inicio de la suscripción no puede ser anterior a la fecha de envío de la suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Las suscripciones vencidas o rescindidas no se pueden cancelar.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Las suscripciones vencidas, canceladas o rescindidas no se pueden actualizar.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"El ID de suscripción de esta solicitud no es válido para este comerciante.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"La fecha de inicio de la suscripción es necesaria para crear una suscripción.",
      "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"El código de moneda proporcionado no es válido, no es compatible, no está permitido para este comerciante o no tiene una tasa de cambio.",
      "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"El número de banco de Elavon o el ID de la terminal no son correctos. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Los datos de la firma son demasiado grandes.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Esta transacción ha sido aprobada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"La transacción se envió desde una dirección IP bloqueada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"La transacción a la que se hace referencia no cumple con los criterios para emitir una Autorización continua.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"La transacción a la que se hace referencia no cumple con los criterios para emitir una autorización continua con captura automática.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"La creación del perfil del cliente falló. Este tipo de transacción no admite la creación de perfiles.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Esta transacción debe estar encriptada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"El ID de la transacción no es válido o no está presente.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Esta transacción no se puede aceptar en este momento debido al mantenimiento del sistema. Por favor, inténtelo de nuevo más tarde.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"No se puede encontrar la transacción.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"Actualmente, las transacciones basadas en EMV no son compatibles con este procesador y tipo de tarjeta.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"Los datos EMV no son compatibles con este tipo de transacción.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"Este comerciante no acepta transacciones de PayPal.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"Las transacciones de PayPal requieren x_version de al menos 3.1.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"El tipo de transacción no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"El número de ocurrencias de prueba especificado debe ser menor que el número total de ocurrencias especificado.",
      "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Se produjo un error de sistema inesperado al procesar esta solicitud.",
      "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Error desconocido.",
      "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Factura creada pero no se pudo enviar el correo electrónico y actualizar el estado",
      "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"El remitente, la respuesta de retransmisión o la URL del enlace del recibo no son válidos.",
      "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"El APIUser ya existe.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"El usuario no tiene suficientes permisos para usar un dispositivo móvil con esta cuenta de comerciante.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"El usuario no tiene permiso para llamar a la API.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"El usuario no tiene permiso para llamar al método API.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Se requiere una cantidad válida.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"El número de instancias de prueba no puede ser cero si se envía un monto de prueba válido.",
      "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Si se especifica un monto de prueba o un número de instancias de prueba, se deben enviar valores para ambos.",
      "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Los números de identificación VITAL son incorrectos. Llame al proveedor de servicios comerciales.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Este es el resultado de un error del analizador XML.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Este es el resultado de un error del analizador XML. El nodo no es válido.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Este es el resultado de un error del analizador XML. Campo(s) faltante(s).",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Este es el resultado de un error del analizador XML. Se han especificado demasiados nodos.",
      "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La combinación de tipo de tarjeta, indicador de autenticación y valor de autenticación del titular de la tarjeta no es válida.",
      "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"El tipo de tarjeta comercial no es válido.",
      "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Este país no está autorizado para compradores.",
      "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"El criptograma no es válido o no se puede utilizar en combinación con otros parámetros.",
      "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
      "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"El tipo de dispositivo no es válido o falta.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"La fecha de nacimiento de la licencia de conducir no es válida.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"El número de licencia de conducir no es válido.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"El estado de la licencia de conducir no es válido.",
      "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"El importe del impuesto no es válido.",
      "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Este tipo de eCheck.Net no está permitido cuando se utiliza el formulario de pago alojado en la pasarela de pago.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Este tipo de eCheck.Net no está permitido.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Este tipo de eCheck.Net no está permitido para este tipo de cuenta bancaria.",
      "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"El parámetro de tipo de eCheck no es válido.",
      "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"El monto del flete no es válido.",
      "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"La puerta de enlace ya no admite el método de integración solicitado.",
      "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"El número de cheque no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"El número de la tarjeta de crédito es inválido.",
      "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"La fecha de vencimiento de la tarjeta de crédito no es válida.",
      "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"El artículo de línea %1 no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"El ID de listado de la subasta no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"El tipo de anuncio de la subasta no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"El ID de la plataforma de subastas no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Faltan uno o más campos obligatorios o no son válidos.",
      "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"El SplitTenderID especificado no es válido.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Longitud de ECI del solicitante del token no válida.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Longitud de ID de solicitante de token no válida.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Nombre de solicitante de token no válido.",
      "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"El nombre de la plataforma de subastas no es válido.",
      "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"No se permite el número de líneas de pedido enviadas. Se puede enviar un máximo de %1 elementos de línea.",
      "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"El tipo de mercado no es válido",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Esta cuenta de comerciante ha sido cerrada.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"La cuenta de comerciante no está activa. Este pago automatizado no será procesado.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"La cuenta de comerciante está en modo de prueba. Este pago automatizado no será procesado.",
      "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Este procesador no acepta la autorización de cero dólares para este tipo de tarjeta.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Ocurrió un error durante el procesamiento. Póngase en contacto con el comerciante.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Uno o más de los campos de configuración de tipo HTML no parecen ser seguros.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Faltan uno o más de los valores obligatorios del subcomerciante.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Uno o más de los valores del comerciante secundario no son válidos.",
      "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"PayformMask no es válido.",
      "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Hubo un error al procesar los datos de pago.",
      "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"El procesamiento de Apple Payments no está habilitado para esta cuenta de comerciante.",
      "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Este procesador no acepta transacciones recurrentes.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Este procesador no permite la anulación de créditos.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Este procesador no admite este método de envío de datos de pago.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Esta solicitud no puede ser aceptada.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"El ID de transacción y el ID de pago dividido no se pueden usar en la misma solicitud.",
      "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"El tipo de formulario solicitado no es válido.",
      "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"El monto de esta solicitud solo se aprobó parcialmente en la tarjeta prepaga proporcionada. Se requiere un pago adicional para cumplir con el saldo de esta transacción.",
      "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"Se requiere la URL de éxito.",
      "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"El formato de respuesta no es válido",
      "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"La reversión no es compatible con este tipo de transacción.",
      "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"El script solicitado no es válido o ya no es compatible.",
      "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Los scripts solo se admiten en la versión 2.5.",
      "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"El estado o país de envío no es válido.",
      "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"El SSN o el número de identificación fiscal no es válido.",
      "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"El importe del recargo no es válido.",
      "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"El importe del impuesto no es válido.",
      "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"El monto de la propina no es válido.",
      "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Los datos de Track1 no están en un formato válido.",
      "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Los datos de Track2 no están en un formato válido.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"La transacción fue autorizada pero el cliente no pudo ser notificado; no se resolverá.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Esta transacción no puede ser aceptada en este momento.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Esta operación no se puede procesar.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Las transacciones que tienen valores de autenticación del titular de la tarjeta no se pueden marcar como recurrentes.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Las transacciones de este tipo de mercado no se pueden procesar en este sistema.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"El método de transacción no es válido.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Esta transacción no puede ser aceptada.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Esta transacción no se puede aceptar para su procesamiento.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"La transacción a la que se hace referencia no fue aprobada.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"El tipo de transacción dado no es compatible con este comerciante.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"La transacción resultó en un desajuste de AVS. La dirección proporcionada no coincide con la dirección de facturación del titular de la tarjeta.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"El tipo de transacción no es válido",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"El tipo de transacción no es válido. Póngase en contacto con el comerciante.",
      "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Se requiere un país de facturación válido.",
      "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Se requiere un país válido.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Se requiere un estado/provincia de facturación válido.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Se requiere un estado válido.",
      "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"La versión 2.5 ya no es compatible.",
      "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"El parámetro de versión no es válido",
      "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Permitir el acceso a los administradores globales de CSP",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"El pago se ha iniciado con éxito. Tomará algún tiempo para procesar. Por favor, compruebe el estado después de algún tiempo.",
      "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"El indicador de renovación automática solo se aplica a las ofertas de NCE",
      "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"El producto del plan de creación no tiene éxito durante el proceso de sincronización automática",
      "AUTO_SYNC_MACRO_NOT_FOUND":"Macro no encontrada",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Múltiples ofertas mapeadas encontradas",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Múltiples ofertas mapeadas encontradas con promoción",
      "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Varios planes encontrados",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"No se han encontrado ofertas mapeadas",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"No se han encontrado ofertas mapeadas con promoción",
      "AUTO_SYNC_NO_PLAN_FOUND":"No se encontró ningún plan",
      "Autotask":"Autotarea",
      "AUTOTASK_API_USER_NAME_IS_MISSING":"Falta el nombre de usuario de la API. Actualice el nombre de usuario en la pantalla de configuración.",
      "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"La cantidad de tarea automática no coincide con la cantidad anterior de C3",
      "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Seleccione un derecho",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Categoría",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Descripción",
      "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Impacto",
      "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Recurso impactado",
      "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Última actualización",
      "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Beneficios potenciales",
      "AZURE_BILLING":"facturación azul",
      "AZURE_BILLING_CYCLE":"Ciclo de facturación",
      "AZURE_ENTITLEMENT_MANAGEMENT":"Derecho de Azure",
      "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Administración de derechos de Azure",
      "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"¡Al arrendatario le falta un derecho de Azure!",
      "AZURE_ERROR_RECOMMENDATION_TEXT":"Error al obtener recomendaciones de Azure Advisor",
      "AZURE_ESTIMATE":"Estimación azul",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Período de facturación",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"ID de derecho",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Nombre del derecho",
      "AZURE_ESTIMATE_LABEL_DEPARTMENT":" Departamento :",
      "AZURE_ESTIMATE_LABEL_SITE":"Sitio :",
      "AZURE_ESTIMATE_REPORT":"Informe de estimación de Azure",
      "AZURE_ESTIMATE_TAGS":"Etiquetas",
      "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Seleccione al menos una etiqueta para descargar la estimación de Azure",
      "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Etiquetas azules",
      "AZURE_ESTIMATE_TAGS_TOOLTIP":"Etiquetas de recursos definidas en Azure Portal",
      "AZURE_ESTIMATED_COST":"Costo estimado",
      "AZURE_ESTIMATES":"Estimaciones de Azure",
      "AZURE_GROUP_DETAILS":"Detalles del grupo",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Grupos de Azure",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Agregar administrador al grupo",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Añadir grupo",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Volver a usuarios del grupo",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Volver a grupos",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Editar grupo",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Usuarios del grupo",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Agregar usuario de grupo",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Grupos de Azure",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Usuarios de {{groupname}}",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrador",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Acceso a facturación",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Umbral de peligro",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Descripción",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Correo electrónico",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Umbral de error",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Nombre del grupo",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Umbral de información",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Frecuencia de notificación",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Umbral de advertencia",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrador",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Acceso a facturación",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Frecuencia de notificación",
      "AZURE_LOADING":"Intentando cargar datos desde Azure, espere...",
      "AZURE_LOADING_MESSAGE":"Consultando Microsoft Azure y la carga podría tardar un minuto...",
      "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"Valor de margen de Azure actualizado correctamente",
      "AZURE_NEW_MARKUP":"Nuevo marcado",
      "AZURE_OLD_MARKUP":"marcado actual",
      "AZURE_PLAN_CREATION_FAILED":"Falló la creación del plan de Azure",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"El monto de la factura se calculará para el cliente, así como para sus respectivos sitios o departamentos, en función del porcentaje asignado.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"La asignación del plan de Azure y sus derechos se pueden compartir entre diferentes entidades de sitio y departamento asignando un porcentaje.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Instrucciones",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Pasos para asignar: <br /> a. Amplíe la suscripción deseada. <br /> b. Haga clic en el número de porcentaje asociado con una entidad. <br />c. Ingrese el porcentaje deseado. <br /> d. Haga clic en <strong> Enviar <strong/> para guardar la asignación.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Para asignar completamente el plan de Azure o el derecho a un sitio/departamento, ingrese 100.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Para liberar una asignación, ingrese 0.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Las asignaciones liberadas se asignarán a la entidad matriz inmediata.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Estas asignaciones no tendrán ningún impacto en los informes y el informe de facturación y estimación de Azure seguirá mostrando todos los datos del cliente.",
      "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Puntuación por categoría",
      "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Todas las categorias",
      "AZURE_RECOMMENDATION_ALL_COST":"Costo",
      "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"Alta disponibilidad",
      "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"Excelencia operativa",
      "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Actuación",
      "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Fiabilidad",
      "AZURE_RECOMMENDATION_ALL_SECURITY":"Seguridad",
      "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Descargar detalles de la recomendación",
      "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Detalles de la recomendación",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Este campo es obligatorio",
      "AZURE_RECOMMENDATION_SUBMIT":"Entregar",
      "AZURE_RECOMMENDATION_TEXT":"Recomendación de Azure",
      "AZURE_RECOMMENDATIONS_TEXT":"Recomendaciones",
      "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Seleccione un código de moneda",
      "AZURE_REPORT_DISTRIBUTOR_LIST":"Distribuidor",
      "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Seleccione el socio o uno de los revendedores.",
      "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Seleccione una entidad",
      "AZURE_REPORT_ENTITY_LIST":"Entidad",
      "AZURE_REPORT_ENTITY_TOOLTIP":"Seleccione el socio o uno de los revendedores.",
      "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Seleccione un revendedor",
      "AZURE_REPORT_RESELLERS_LIST":"Elige un revendedor",
      "AZURE_REPORTS_BITTON_TEXT":"Informe de estimación de Azure",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Detalles por",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Detalles por grupo de recursos",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Detalles por servicio",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Obtén detalles",
      "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sin etiquetas",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Informes de Azure",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Con etiquetas",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Resumen de facturación",
      "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Precio del plan Azure",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"Fecha",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Derecho",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"Grupo",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Mes",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Selecciona grupo",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Suscripción",
      "AZURE_REPORTS_OPTION_SELECT_TAG":"Seleccione una etiqueta para obtener datos de etiquetas inteligentes",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Todos los derechos",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Todos los grupos",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Todas las suscripciones",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Seleccione fecha",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"No asignado",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Costo",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Nombre del recurso",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Nombre del Servicio",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Auditoría",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Detalles",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Grupo de recursos",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Nombre del recurso",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Nombre del Servicio",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Coste total",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Fecha y hora",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Categoría de evento",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Evento iniciado por",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Nombre de la operación",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Estado",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Auditoría",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Detalles",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Costo",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Fecha",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Coste total",
      "AZURE_REPPORT_CURRENCY":"Divisa",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Debes seleccionar una moneda para obtener el informe.",
      "AZURE_REPPORT_CUSTOMERS":"Clientes",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Seleccionar cliente",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Seleccionar cliente",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"Contribuyente",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Rol personalizado",
      "AZURE_ROLE_NAME_OWNER":"Dueño",
      "AZURE_ROLE_NAME_READER":"Lector",
      "AZURE_SUBSCRIPTION":"Suscripción Azure",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Editar",
      "AZURE_SUBSCRIPTION_DETAILS":"Detalles de suscripción",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Seleccione",
      "AZURE_SUBSCRIPTION_ID":"Identificación de suscripción",
      "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"¡Al inquilino le falta un plan de Azure!",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Error de actualización de la suscripción de Azure",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"La suscripción de Azure se actualizó correctamente",
      "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"¿Está seguro de que desea actualizar?",
      "AZURE_THRESHOLD_DETAILS":"Detalles del umbral",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Establezca un presupuesto mensual para esta suscripción y defina los umbrales en diversas gravedades, como Información, Advertencia, Error y Peligro.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Para las suscripciones administradas por el cliente, todos los administradores del grupo recibirán una notificación cuando el uso alcance o supere un umbral determinado.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Las notificaciones se envían una vez por ciclo de facturación.",
      "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Plan azul",
      "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Espere, estamos verificando la elegibilidad para la actualización",
      "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"La suscripción no es elegible para la actualización",
      "AZURE_UPGRADE_WAIT_MESSAGE":"Por favor espere Estamos procesando la actualización...",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Volver a la lista",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Uso de Azure",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Detalles de uso",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Ver detalles de uso",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Precio estimado",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Nombre",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"ID de suscripción",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} agregado al grupo {{groupname}} exitosamente",
      "AZUREGROUPS_SAVE_SUCCESS":"Grupo {{username}} guardado exitosamente",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Usuario del grupo agregado exitosamente",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Grupo guardado exitosamente",
      "BACK_TO_AZURE_ADVISOR":"Volver al asesor azul",
      "BACK_TO_POLICIES":"Volver a Políticas personalizadas",
      "BACK_TO_PUBLIC_SIGNUP_SHOP":"volver a la tienda",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Volver a inquilinos proveedores",
      "BACKGROUND_DROPDOWN_TEMPLATE_1":"Plantilla 1",
      "BACKGROUND_DROPDOWN_TEMPLATE_10":"Plantilla 10",
      "BACKGROUND_DROPDOWN_TEMPLATE_2":"Plantilla 2",
      "BACKGROUND_DROPDOWN_TEMPLATE_3":"Plantilla 3",
      "BACKGROUND_DROPDOWN_TEMPLATE_4":"Plantilla 4",
      "BACKGROUND_DROPDOWN_TEMPLATE_5":"Plantilla 5",
      "BACKGROUND_DROPDOWN_TEMPLATE_6":"Plantilla 6",
      "BACKGROUND_DROPDOWN_TEMPLATE_7":"Plantilla 7",
      "BACKGROUND_DROPDOWN_TEMPLATE_8":"Plantilla 8",
      "BACKGROUND_DROPDOWN_TEMPLATE_9":"Plantilla 9",
      "BAD_INPUT_INVALID_PRODUCTID":"ID de producto no válido",
      "BAD_INPUT_INVALID_SEATS":"Asientos inválidos",
      "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entidades involucradas en la notificación del banner",
      "BILL_ACTION_DESC_FULL_CHARGE":"Carga completa durante todo el mes.",
      "BILL_ACTION_DESC_NO_REFUND":"No se proporcionará ningún reembolso",
      "BILL_ACTION_DESC_PRORATE":"El prorrateo se aplicará en la factura.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Carga completa",
      "BILL_ACTION_NAME_NO_REFUND":"No hay reembolso",
      "BILL_ACTION_NAME_PRORATE":"Prorratear",
      "BILL_CYCLE_DESC_ANNUAL":"Anual",
      "BILL_CYCLE_DESC_MONTHLY":"Mensual",
      "BILL_CYCLE_DESC_ONETIME":"Una vez",
      "BILL_CYCLE_DESC_TRIENNIAL":"Trienal",
      "BILL_CYCLE_NAME_ANNUAL":"Anual",
      "BILL_CYCLE_NAME_MONTHLY":"Mensual",
      "BILL_CYCLE_NAME_TRIENNIAL":"Trienal",
      "BILL_FROM":"Factura de",
      "BILL_TO":"Cobrar a",
      "Billing":"Facturación",
      "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"No se ha podido eliminar el enlace del cliente de facturación.",
      "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"El enlace del cliente de facturación se eliminó correctamente.",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"No se permite el cambio del ciclo de facturación del producto ya que la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Nombre del producto actual",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Ciclo de facturación del producto actualizado correctamente",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Seleccione",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Ciclo de facturación",
      "BILLING_FORECAST_REPORT":"Pronóstico de facturación",
      "BILLING_PERIOD":"Período de facturación",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Seleccione",
      "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Seleccione al menos un período de facturación",
      "BILLING_TYPE_DESC_MARK_UP":"Margen",
      "BILLING_TYPE_DESC_METERED_BILLING":"facturación medida",
      "BILLING_TYPE_DESC_PERCENTAGE":"Porcentaje",
      "BILLING_TYPE_DESC_PRICE":" Precio",
      "BILLING_TYPE_DESC_UNIT":"Unidad",
      "BILLING_TYPE_TOOLTIP":"Tipo de facturación",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Estimaciones de Azure",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Suscripción a Azure",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Licencias",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Uso de Azure",
      "BREADCRUMB_HEADER_TEST_PAYMENT":"Pago de prueba",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
      "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Detalles del estado de pago",
      "BTN_AZURE_BILLING_COMPARISON_REPORT":"Informe de comparación de facturación de Azure",
      "BTN_BILLING_FORECAST_REPORT_LOADING":"Pronóstico de facturación...",
      "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Eliminar el registro",
      "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Descargar factura zip",
      "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Ver detalles de la factura",
      "BTN_INVOICE_LINE_ITEMS":"Partidas de factura",
      "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Partidas de factura con impuestos...",
      "BTN_INVOICE_PAYMENTS":"Pagos de facturas",
      "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Volver a pagos",
      "BTN_QUOTE_COPY_URL":"Copiar URL",
      "BTN_QUOTE_PUBLISH":"Publicar cotización",
      "BTN_QUOTE_REPUBLISH":"Republicar cita",
      "BTN_QUOTE_SAVE_DRAFT":"Guardar borrador",
      "BTN_TEXT_ADD_TO_CART":"Añadir a la cesta",
      "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"volver a la historia",
      "BTN_TEXT_BEGIN_UPLOAD":"Subir",
      "BTN_TEXT_BEGIN_VALIDATE_DATA":"Validar",
      "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Ver los registros",
      "BTN_TEXT_CANCEL_ORDER":"Cancelar orden",
      "BTN_TEXT_COMMENT":"Comentario",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"Copiar al portapapeles",
      "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Descargar todos los cupones",
      "BTN_TEXT_FILTER":"Filtrar",
      "BTN_TEXT_FIX":"Arreglar",
      "BTN_TEXT_IGNORE":"Ignorar",
      "BTN_TEXT_INCLUDE":"Incluir",
      "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Promoción mapa",
      "BTN_TEXT_MAPPING_OF_PRODUCTS":"Mapeo de productos",
      "BTN_TEXT_OPEN_IN_EMAIL":"Abrir en correo electrónico",
      "BTN_TEXT_PROCEED":"Proceder",
      "BTN_TEXT_QUICK_VIEW":"Vista rápida",
      "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Pagos múltiples",
      "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Pago unico",
      "BTN_TEXT_REMOVE":"Remover",
      "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Eliminar promoción",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Solicitar una relación de revendedor",
      "BTN_TEXT_SHOW_FILTERS":"Mostrar filtros",
      "BTN_TEXT_SIGN_IN":"Iniciar sesión",
      "BTN_TEXT_SKIP":"Saltar",
      "BTN_TEXT_TAKE_ON":"Incluir",
      "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Administrar cargas de tareas automáticas",
      "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Administrar cargas de Connectwise",
      "BTN_TEXT_VIEW_PRODUCT":"Ver el producto",
      "BULK_ADD_ENTITLEMENT_BATCH_ID":"ID del lote",
      "BULK_ADD_ENTITLEMENT_DESCRIPTION":" El nombre del último derecho creado es",
      "BULK_ADD_TENANT_DESCRIPTION":" El último inquilino creado es",
      "BULK_ADD_TENANTS_BATCH_ID":"ID del lote",
      "BULK_DATA_MAPPING_CSV_UPLOAD":"Cargar hoja mapeada",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Volver al resumen de ingresos y costos",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"Cancelar",
      "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Seleccione al menos un cliente",
      "BULK_INVOICE_DOWNLOAD_HEADER":"Descargar facturas masivas",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"correo electrónico",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Recibe todas las facturas por correo electrónico",
      "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Ingrese la dirección de correo electrónico a la que desea enviar un correo electrónico, ingréselo con coma (,) o punto y coma (;) y haga clic en Enviar para recibir un archivo zip que contiene la lista de facturas en PDF para el Período de facturación seleccionado",
      "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"¡Le notificaremos a los correos electrónicos proporcionados una vez que el archivo zip esté listo para descargar! Por lo general, toma una hora para completar",
      "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Seleccionar Plan",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Cerca",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"La factura masiva se elimina con éxito.",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Número de factura",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Dueño",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Estado",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Usuario",
      "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Seleccionar Plan",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"Entregar",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"¿Está seguro de que desea eliminar este registro?",
      "BULK_INVOICE_PROGRESS_POPUP_HEADER":"Estado de descarga de factura",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Completado en",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"Correos electrónicos",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Iniciador",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Enlace disponible hasta la fecha",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Fecha de inicio",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Estado",
      "BULK_ONBOARD_BATCH_ID":"BatchId a bordo a granel",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"BatchId a bordo a granel",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Descargar detalles de mapeo",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Descargar asignación de suscripción",
      "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"Se asignó correctamente <b>{{ TotalSubscriptionsOnboarded }}</b> de <b>{{ TotalSubscriptionsAtProvider }}</b> del Centro de partners.",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"¿Desea eliminar todos los clientes seleccionados?",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quiere seleccionar todos los clientes?",
      "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":" <b>{{selectedCustomers}}</b> seleccionados de <b>{{totalCustomers}}</b> clientes para la incorporación",
      "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Revendedores existentes a bordo",
      "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Por favor proporcione un correo electrónico válido",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Solicitud en cola para incorporación masiva de clientes",
      "BULK_ONBOARD_RESELLERS_BATCH_ID":"ID del lote",
      "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"El lote se completa con éxito. Será redirigido a revendedores.",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Seleccionar de la lista",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"Subir CSV",
      "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Seleccione al menos 1 revendedor para incorporar.",
      "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Resumen de resultados a bordo",
      "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Total seleccionado: {{totalResellersInBatch}}, Total incorporado: {{resellersOnboarded}}, Total fallido: {{resellersFailedToOnboard}}",
      "BULK_ONBOARD_RESELLERS_LBL_OPTION":"¿Cómo quieres embarcar?",
      "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"¿Desea eliminar todos los revendedores seleccionados?",
      "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quiere seleccionar todos los revendedores?",
      "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"La solicitud de revendedores masivos a bordo está en cola.<br /> Se le redirigirá a la pantalla de estado que muestra el progreso.",
      "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"<b>{{selectedResellers}}</b> seleccionados de <b>{{totalResellers}}</b> revendedores para la incorporación",
      "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"errores",
      "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":" Id. MPN -",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"RevendedoresError",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"RevendedoresCon éxito",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"TotalRevendedoresSeleccionados",
      "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Introduzca un correo electrónico válido",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Parece que falta el detalle del ID de MPN para el revendedor. Comuníquese con el socio para actualizar los detalles.",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"¡La incorporación no es compatible!",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"No se admite la incorporación masiva de clientes de Microsoft que no sean CSP.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
      "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Revendedores a bordo a granel",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Proveedor",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Elija el proveedor de soluciones de la lista desplegable",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Estado",
      "BULK_ONBOARDING_STATUS_INFO_HEADER":"¡Consejo! Pasos para corregir la incorporación parcial",
      "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Si hay problemas de asignación de suscripción informados por el sistema durante la incorporación de un cliente, haga clic en el hipervínculo para descargar y revisar los detalles. Arregle sus planes y trabaje con Soporte para sincronizar los datos en C3.",
      "BULK_PRODUCT_MAPPING":"Mapeo de productos a granel",
      "BULK_PURCHASED_PRODUCT_MAPPING":"Mapeo de productos de compra al por mayor",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Descargue el catálogo para obtener clientes y sus identificaciones.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Complete el código de moneda, el monto del pago y la fecha de pago para el C3Id correspondiente.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Asegúrese de que los datos cumplan con las siguientes reglas:",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> Id. de cliente C3 (o Id. de revendedor C3) del catálogo contra el cual el cliente desea registrar el pago.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode - Texto (código de moneda estándar ISO):</strong> en qué moneda se desea registrar el pago",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount - Decimal –</strong> Monto a registrar",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Observaciones - Texto –</strong> Comentarios (opcional)",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Fecha (AAAA-MM-DD) –</strong> ¡En qué fecha se debe registrar el pago! ¡Solo fechas pasadas!",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Haga clic en <strong>Seleccionar</strong> y busque el archivo.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Haga clic en <strong>Validar catálogo</strong> para que el sistema valide la entrada.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Revise los errores en la tabla después de completar la validación.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Corrija los errores en el archivo y vuelva a cargarlo.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"Si no hay errores de validación en la entrada, se habilita el botón <strong>Importar pagos</strong>. Haga clic en el botón para enviar sus cambios.",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Hecho",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Clientes seleccionados",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Elija al menos un cliente",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"¿Quieres eliminar al cliente?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"Se acepta la solicitud para agregar varios clientes.",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Se produjo un error al analizar el archivo cargado",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Éxito",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Se produjo un error al analizar el archivo cargado",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Descarga un .csv de muestra",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Descargue el catálogo para obtener los códigos y la secuencia más recientes.",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"archivo de referencia.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Éxito",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Instrucciones",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Complete el código o la secuencia o ambos para los productos deseados y elimine las líneas que no desea cambiar. El código de producto es opcional y admite 100 caracteres. La secuencia de visualización es un número y es opcional.<br /><strong>Nota:</strong> si deja los dos en blanco, el código y la secuencia existentes se borrarán.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Haga clic en <strong class=\"text-uppercase\">Validar catálogo</strong> para que el sistema valide la entrada.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Revise los errores en la tabla después de completar la validación.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Corrija los errores en el archivo y vuelva a cargarlo.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"¡Importación completada!",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"¡Validación completada!",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Catálogo de importación",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Validar Catálogo",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Éxito",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar todos los clientes",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar departamento",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Se produjo un error al analizar el archivo cargado",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Descargue el catálogo para obtener los últimos detalles de las comisiones de productos.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Éxito",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Complete el nombre del agente, el código SP, el porcentaje de comisión, la fecha de inicio de la comisión, la fecha de finalización de la comisión para los productos deseados y elimine las líneas que no desea cambiar. Todas estas columnas son opcionales. <br /> El nombre del agente es un texto que admite 100 caracteres. <br />SPCode es un texto que admite 100 caracteres. <br />El porcentaje de comisión es un número que también admite decimales. <br />La fecha de inicio de la comisión es una fecha que debe definirse en formato <strong>{{dateFormat}}</strong>.<br />La fecha de finalización de la comisión es una fecha que debe definirse en <strong>{ Formato {dateFormat}}</strong>.<br /><strong>Nota:</strong> si actualiza los valores existentes a un espacio en blanco, los valores existentes se borrarán una vez que se importe el catálogo.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Haga clic en <strong class=\"text-uppercase\">Validar catálogo</strong> para que el sistema valide la entrada.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Revise los errores en la tabla después de completar la validación.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Corrija los errores en el archivo y vuelva a cargarlo.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar sitio",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Éxito",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"¿Quieres eliminar el revendedor?",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Las siguientes suscripciones no tienen ofertas equivalentes en el plan. Actualice el plan e inténtelo de nuevo.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"No se puede encontrar un cliente en CSP con ID: {{CSPCustomerId}}",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Las siguientes ofertas se compraron más de una vez en un solo nivel, lo cual no es compatible con C3. Por favor verifique los datos.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Remover",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Complementos",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Su última operación de actualización parece tener algunos errores; comuníquese con el equipo de soporte para obtener ayuda.",
      "BUNDLE_PRODUCTS_PLACEHOLDER":"Paquete de productos",
      "BUNDLE_REPORT_FILE_TYPES_HEADER":"Informe de detalles del producto del paquete",
      "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de detalles del producto Bundle",
      "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista del informe de detalles del producto Bundle.",
      "BUNDLE_SAVE_SUCCESS":"El paquete {{customoffer}} se guardó correctamente",
      "BUNDLE_UPDATE_SUCCESS":"El paquete {{customoffer}} se actualizó correctamente",
      "bundles":"manojos",
      "BUNDLES_BILLING_CYCLE_TOOLTIP":"Frecuencia de facturación a la que se facturaría el paquete",
      "BUNDLES_BILLING_TYPE_TOOLTIP":"Categoría de facturación en la que se facturaría el paquete",
      "BUNDLES_CANNOT_BE_DELETED":"Los paquetes no se pueden eliminar ya que están asignados a un plan",
      "BUNDLES_SALETYPE_TOOLTIP":"Tipo de categoría para la oferta",
      "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"La importación está en curso",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Espere por favor...",
      "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"La validación está en progreso",
      "BUTTON_ACTIVATE":"Activar",
      "BUTTON_APPLY_COUPON_TEXT":"Aplicar cupón",
      "BUTTON_CLOSE":"Cerca",
      "BUTTON_DEACTIVATE":"Desactivar",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Cancelar",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Descargar",
      "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Actualizar",
      "BUTTON_LANG_CN":"Chino",
      "BUTTON_LANG_DE":"Alemán",
      "BUTTON_LANG_EN":"inglés",
      "BUTTON_LANG_ES":"español",
      "BUTTON_LANG_FR":"Francés",
      "BUTTON_LANG_IT":"italiano",
      "BUTTON_LANG_SK":"eslovaco",
      "BUTTON_LANG_TH":"tailandés",
      "BUTTON_LANG_TR":"turco",
      "BUTTON_LANG_TW":"taiwanés",
      "BUTTON_LANGUAGE":"Idioma",
      "BUTTON_MANAGE_PRODUCT":"Administrar",
      "BUTTON_ONHOLD":"En espera",
      "BUTTON_PROCESSING":"Procesando",
      "BUTTON_RELEASE":"Liberar",
      "BUTTON_REVOKE":"Revocar",
      "BUTTON_START_TOUR":"hacer un recorrido",
      "BUTTON_TEXT_ACCEPT":"Aceptar",
      "BUTTON_TEXT_ACTIONS":"Comportamiento",
      "BUTTON_TEXT_ADD":"Agregar",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Agregar nueva conversión de moneda",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"agregar política personalizada",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Agregar notificación por correo electrónico de nuevo evento",
      "BUTTON_TEXT_ADD_SUB_TAX":"Agregar subimpuesto",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Agregar con complementos",
      "BUTTON_TEXT_APPLY":"Aplicar",
      "BUTTON_TEXT_APPROVE":"Aprobar",
      "BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
      "BUTTON_TEXT_CANCEL":"Cancelar",
      "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Comprobar todo el estado",
      "BUTTON_TEXT_CLEAR":"Claro",
      "BUTTON_TEXT_CLOSE":"Cerca",
      "BUTTON_TEXT_DELETE":"Borrar",
      "BUTTON_TEXT_DOWNLOAD":"Descargar",
      "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Descargar administradores de cuentas",
      "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Descargar catálogo",
      "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Descargar clientes",
      "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Descargar resumen de registro de publicación de facturas",
      "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Descargar partida de factura",
      "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Descargar factura de cuotas",
      "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Descargar ofertas de socios",
      "BUTTON_TEXT_DOWNLOAD_PLAN":"Descargar plano",
      "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Descargar oferta de planes",
      "BUTTON_TEXT_DOWNLOAD_REPORT":"Descargar informe",
      "BUTTON_TEXT_EDIT":"Editar",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Exportar a Excel",
      "BUTTON_TEXT_FILTER":"Filtrar",
      "BUTTON_TEXT_LOAD_OFFERS":"Cargar ofertas",
      "BUTTON_TEXT_OK":"OK",
      "BUTTON_TEXT_OK_CLOSE":"Ok, cerca",
      "BUTTON_TEXT_OVERRIDE":"Anular",
      "BUTTON_TEXT_PAY_INVOICES":"Pagar facturas pendientes",
      "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Proceder a comprar",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Pago anticipado récord",
      "BUTTON_TEXT_REJECT":"Rechazar",
      "BUTTON_TEXT_REMOVE":"Remover",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Eliminar subimpuestos",
      "BUTTON_TEXT_REPORTS":"Informes",
      "BUTTON_TEXT_RESET":"Reiniciar",
      "BUTTON_TEXT_REVERT":"Revertir",
      "BUTTON_TEXT_SAVE":"Ahorrar",
      "BUTTON_TEXT_SEARCH":"Búsqueda",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Establecer entidad operativa",
      "BUTTON_TEXT_SUBMIT":"Entregar",
      "BUTTON_TEXT_UPDATE":"Actualizar",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"Subir a Quickbooks en línea",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"Subir a Xero",
      "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Ver padres",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"Ver registros de publicaciones",
      "BUTTON_TEXT_VIEW_OFFERS":"Ver ofertas",
      "BUTTON_VIEW_ERRORS":"Ver errores",
      "BUTTON_VIEW_PRODUCT":"Vista",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Añadir etiqueta",
      "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Editar etiqueta",
      "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"para",
      "C3_CART":"Carro",
      "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Categoría de proveedor",
      "C3_TEXT_ONHOLD_STATUS":"En espera",
      "c3supportAzuremargin":"margen azul",
      "CALENDAR_CLEAR_BUTTON":"Claro",
      "CALENDAR_CLOSE_BUTTON":"Cerca",
      "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Por favor ingrese un motivo para cancelar el pedido",
      "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Se requiere motivo para cancelar el pedido programado.",
      "CANCEL_SCHEDULED_ORDER_SUCCESS":"El pedido se canceló con éxito",
      "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"Cancelar orden",
      "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Cancelar pedido programado",
      "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Se requiere motivo para cancelar el producto programado.",
      "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Se requiere motivo para cancelar el pedido",
      "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"El producto se canceló exitosamente de este pedido.",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Renovación programada cancelada debido a un cambio en el estado de renovación automática",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Renovación programada cancelada debido a actualización de cantidad",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Renovación programada cancelada por rechazo de solicitud de renovación",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Se canceló la renovación programada porque la propiedad del cronograma se cambió desde el centro de socios",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Renovación programada cancelada debido a un cambio de estado de suscripción",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Renovación programada cancelada debido a actualización de suscripción",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Renovación programada cancelada debido a una actualización de suscripción",
      "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"No se pudo cancelar la renovación programada",
      "CANCEL_SCHEDULED_RENEWAL_REASON":"Razón",
      "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Proporcione una razón",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Este campo es obligatorio",
      "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Canceló con éxito la renovación programada",
      "CANCELLED_REASON":"Motivo de cancelación",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"No se puede eliminar una definición de conversión de moneda anterior",
      "CANNOT_INITIATE_PAYMENT":"No se puede iniciar un pago debido a una transacción pendiente",
      "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Este producto ya ha sido comprado por el cliente. No es posible vincular el producto.",
      "CanPriceLag":"¿Puede el precio retrasarse?",
      "CanPriceLead":"¿Puede el precio liderar?",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Solicitud de relación revendedor",
      "CARD_CARD_DELETE_ADDON":"Borrar",
      "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Centro de intercambio",
      "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Datos de uso del producto",
      "CART_CARD_COMMENT_ACTION":"Comentarios",
      "CART_CARD_DELETE_PRODUCT":"Borrar",
      "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Este producto ya está disponible en su carrito. ¿Todavía quieres continuar?",
      "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Su inquilino no es elegible para comprar el Plan Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3 o hay una suscripción de Azure activa en el Centro de partners y necesita una actualización. Póngase en contacto con el soporte para obtener más ayuda.",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"¿Está seguro de que desea cambiar el inquilino de la oferta seleccionada?",
      "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Fecha de finalización personalizada guardada correctamente",
      "CART_DISABLE_PROMOTION":"Deshabilitar Promoción del producto.",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Se produjo un error al actualizar el inquilino en la oferta seleccionada. Por favor contacte al equipo de soporte",
      "CART_GENERIC_ERROR_MESSAGE":"Algo salió mal al completar el pedido. Consulte con el socio antes de volver a intentarlo.",
      "CART_ITEM_EFFECTIVE_PRICE":"Precio efectivo",
      "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Orden por Sitio/Departamento",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Inquilino",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"¡El carrito no está disponible para pagar o ya está pagado!",
      "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"No puedes programar este carrito porque no está disponible o ya está programado.",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Producto vinculado",
      "CART_PAGE_AUTORELEASE":"Liberación automática",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":" {{descuento}}% de descuento",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Descuento",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Remover",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Aplicar",
      "CART_PAGE_DISCOUNT_INFORMATION":"{{discount}}% de descuento)",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Tipos de consumo",
      "CART_PAGE_TEMPLATE_ADD_COMMENT":"Agregar comentario",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<span class='font-weight-boldest'>¡Hola!</span> Tu carrito está vacío. <a ng-click='vm.GotoCart()' class='text-primary' role='button'> Haga clic aquí para comprar. <span class='fa fa-cart-plus'></span> </a>",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>¡Hola!</span> Su carrito está vacío.",
      "CART_PAGE_TITLE_PRICE":"Precio minorista estimado",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Fecha de finalización personalizada",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Seleccione el tipo de fecha de finalización personalizada",
      "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Seleccione..",
      "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Seleccione..",
      "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Liberación automática actualizada con éxito",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Se produjo un error al retirar el carrito",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Pedido realizado con éxito. ID de pedido: {{ID de pedido}}",
      "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Se produce un error al procesar su solicitud para convertir la oferta de prueba de {{productName}} en suscripción",
      "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Solicitud para convertir la oferta de prueba de {{productName}} en suscripción enviada correctamente",
      "CART_PRODUCT_DELETE_CONFIRMATION":"¿Está seguro de que desea eliminar este artículo del carrito?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"¡Ocurrió un error al retirar el artículo del carrito!",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"¡Se eliminó el artículo solicitado con éxito!",
      "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Se produjo un error al verificar la elegibilidad de la promoción",
      "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"El ID de promoción disponible es válido en la promoción.",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Cantidad actualizada correctamente",
      "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Se produjo un error al programar el pedido.",
      "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"El pedido se ha programado correctamente.<br/>El ID del pedido es: {{orderId}}",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Este producto ya se compró hace {{days}} días. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Este producto ya se compró hace {{hours}} horas. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Este producto ya se compró hace {{minutos}} minutos. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Este producto ya se ordenó hace {{days}} días. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Este producto ya se ordenó hace {{hours}} horas. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Este producto ya se ordenó hace {{minutos}} minutos. ¿Todavía quieres continuar?",
      "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Esta oferta de prueba ya está comprada.",
      "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"En este producto, el pedido programado está presente. ¿Aún quieres continuar?",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"¡Actualicé el inquilino con la oferta seleccionada con éxito!",
      "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Ocurrió un error al actualizar el campo de pago instantáneo en el carrito",
      "CATEGORY_DESC_ALL":"Todo",
      "CATEGORY_DESC_AZURE":"Azur",
      "CATEGORY_DESC_AZURE_PLAN":"plan azul",
      "CATEGORY_DESC_BUNDLES":"manojos",
      "CATEGORY_DESC_CUSTOM":"Disfraz",
      "CATEGORY_DESC_CUSTOM_TRIAL":"Prueba personalizada",
      "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
      "CATEGORY_DESC_LICENSE_SUPPORTED":"Licencia compatible",
      "CATEGORY_DESC_NCE":"NCE",
      "CATEGORY_DESC_NON_CSP_AZURE":"Azure sin CSP",
      "CATEGORY_DESC_ONE_TIME":"Una vez",
      "CATEGORY_DESC_ONLINCE_SERVICES":"Servicios en línea",
      "CATEGORY_DESC_ONLINE_SERVICES_NCE":"Servicios en líneaNCE",
      "CATEGORY_DESC_PERPETUAL_SOFTWARE":"software perpetuo",
      "CATEGORY_DESC_RESERVED_INSTANCES":"Rhode Island",
      "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Suscripciones de software",
      "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Licencia compatible",
      "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Software perpetuo",
      "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Instancia reservada",
      "CATEGORY_FILTER_DESC_NCE":"NCE",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"¿Estás seguro, quieres desactivar este cupón?",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"¿Estás seguro, quieres habilitar este cupón?",
      "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Plano de origen",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Plan objetivo",
      "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":" Desasignar planes después de cambiar a un nuevo plan",
      "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Ocurrió un error al cambiar de plan.",
      "CHANGE_PLAN_HELP":" Ayuda",
      "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} no está asignado con el producto secundario derecho {{secondProduct}}",
      "CHANGE_PLAN_INSTRUCTIONS_1":"Siga estas instrucciones para mover los productos del cliente de un plan existente a un plan recién seleccionado.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Plan de origen</b>: enumera los planes asociados con los productos adquiridos del cliente. Seleccione uno o más planes si desea operar en los productos relacionados.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Plan objetivo</b>: seleccione el nuevo plan de precios.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Desasignar planes después de cambiar a un nuevo plan</b>: esto es útil ya que el sistema desasociaría al cliente de un plan existente si no quedan productos en el plan de origen. Ayuda a resolver la confusión de los usuarios de los clientes en la página de la tienda.",
      "CHANGE_PLAN_INSTRUCTIONS_2":"La página se actualiza para enumerar los productos comprados del plan de origen a la izquierda y la opción de asignar a un equivalente del plan de destino, a la derecha.",
      "CHANGE_PLAN_INSTRUCTIONS_3":"Mapee los productos y envíe la solicitud para que el sistema ejecute el cambio.",
      "CHANGE_PLAN_INSTRUCTIONS_4":"Los registros de auditoría lo ayudan con los detalles de la actividad como referencia en un momento posterior.",
      "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Nota: el cambio de precio debido al cambio de plan entraría en vigencia de inmediato.</b>",
      "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Instrucciones para cambiar al plan",
      "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Si tiene que mapear {{firstProduct}}, también debe mapear {{secondProduct}}",
      "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"La cantidad compartida debe coincidir con {{firstProduct}}",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Seleccione los planes de origen que desea cambiar",
      "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Seleccionar plan objetivo",
      "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"No hay productos coincidentes en el plan de destino",
      "CHANGE_PLAN_STATUS_FAILED":"Ha fallado",
      "CHANGE_PLAN_STATUS_SUCCESS":"Éxito",
      "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"El plan cambió con éxito.",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Cambiar a un producto en el plan de destino",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"productos comprados",
      "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":" Ciclo de vida",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Categoría",
      "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":" Producto vinculado",
      "CHANGE_PLAN_TABLE_TEXT_OFFER":"Oferta :",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":" Precio",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Proveedor",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Cantidad comprada",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Validez",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Los planes seleccionados tienen una configuración de moneda diferente.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"El producto del plan está en estado de provisión.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Seleccione el producto.",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Se produjo un error al actualizar el estado del producto comprado:",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"¿Estás seguro? ¿Quieres descontinuar esta oferta?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"¿Estás seguro? ¿Quieres desactivar este plan?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"¿Estás seguro? ¿Quieres habilitar este plan?",
      "Charge":"Cargar",
      "charity":"Caridad",
      "Charity":"Caridad",
      "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Habilitar todas las promociones",
      "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Elija un ciclo de facturación",
      "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Elija un tipo de facturación",
      "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Elige un tipo de consumo",
      "CHOOSE_AN_OPTION":"Elige una opcion",
      "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Elige Cliente",
      "CHOOSE_INVOICE_PDF_LANGUAGE":"Elija el idioma del PDF de la factura",
      "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"El PDF de la factura estará disponible en el idioma seleccionado.",
      "CHOOSE_ONE_RG":"Elija al menos un grupo de recursos",
      "CHOOSE_RESOURCE_GROUP":"Elija política",
      "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"¿Está seguro de que desea borrar el icono de este producto? El producto obtendría el ícono por su categoría cuando se borrara aquí.",
      "CLICK":"hacer clic",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Hacer clic",
      "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Clonar conversión de moneda",
      "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Por favor seleccione una fecha",
      "CLONE_PLAN_FAILURE":"No se pudo clonar el plan",
      "CLONE_PLAN_SUCCESS":"Plan clonado con éxito",
      "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Ahora no",
      "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Ahora no",
      "COLLAPSE_ALL_PAYMENTS":"Colapso de pagos",
      "COMMENT_AUTHOR":"Autor",
      "COMMENTS_LATEST_COMMENT":"Último comentario",
      "commercial":"Comercial",
      "Commercial":"Comercial",
      "COMMISION_REPORT_SEARCH":"Búsqueda",
      "COMMISION_REPORTS":"Informe de la Comisión",
      "Commission Report":"Informe de la Comisión",
      "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Todos los periodos",
      "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar todos los clientes",
      "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar departamento",
      "COMMISSION_REPORT_HEADER_TEXT":"Informe de la Comisión",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Cerca",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Reiniciar",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Búsqueda",
      "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar sitio",
      "COMMISSION_REPORTS":"Informe de la Comisión",
      "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Informe de la Comisión",
      "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Informe de ganancias",
      "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Fecha de finalización de la comisión",
      "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Fecha de inicio de la comisión",
      "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Comisión(%)",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Nombre de dominio",
      "COMMISSION_TABLE_HEADER_ENTITY_ID":"ID de entidad",
      "COMMISSION_TABLE_HEADER_ORDERED_DATE":"fecha ordenada",
      "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"PlanProductId",
      "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agente",
      "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Importe de la comisión",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Cliente",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"Identificación del producto del proveedor",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"Identificación del producto del proveedor",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"ID de suscripción del producto",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Nombre de suscripción del producto",
      "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Nombre del producto de compra",
      "COMMISSION_TABLE_HEADER_RECORD_ID":"ID de registro",
      "COMMISSION_TABLE_HEADER_SP_CODE":"código SP",
      "COMMON_LOADING_MESSAGE":"Cargando ...",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"¿Estás seguro? ¿Quieres aprobar esta factura?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"¿Estás seguro? ¿Quieres finalizar esta factura?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Tiene cambios sin guardar. Haga clic en Aceptar para salir sin guardar o haga clic en Cancelar para permanecer en la página.",
      "CONFIRMATION_POPUP_MESSAGE":"¿Estás seguro de que quieres activar esta suscripción?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"¿Está seguro de que desea cancelar la solicitud relacionada con esta suscripción?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"Activar",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"Cancelado",
      "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"Al hacer clic en Aprobar, se comprarán los artículos de la línea de cotización. <br/> <b>Nota</b>: No se aplicará el límite de asientos/transacciones.",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"¿Quieres eliminar este ajuste?",
      "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>Al hacer clic en aprobar, el estado de la cotización se actualizará a aprobado.</p></li><li><p style='text-align:left;'>El propietario de la cotización debe realizar manualmente la compra en nombre del cliente.</p></li></ul>",
      "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"¿Desea actualizar el nombre de la suscripción?",
      "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"¿Está seguro de que desea actualizar el número de pedido?",
      "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"¿Está seguro de que desea actualizar el nombre del producto?",
      "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":" ¿Desea actualizar el nombre de la suscripción si se comparte entre entidades secundarias en C3?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"¿Estas seguro que quieres cancelar?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"¿Quieres continuar?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"¿Estás seguro de que quieres continuar con la selección actual?",
      "CONSENT_CREATION_FAILED":"La creación del consentimiento falló",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Habilite esta opción para aplicar tarifas de procesamiento a los pagos con tarjeta de crédito.",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"¿Incluye tarifa de procesamiento de tarjeta de crédito?",
      "CONSTANT_EMPTY_STRING":"--Seleccione--",
      "CONSTANT_EVERY_DAY":"Todos los días",
      "CONSTANT_EVERY_MONTH":"Cada mes",
      "CONSTANT_EVERY_TWO_WEEKS":"Cada dos semanas",
      "CONSTANT_EVERY_WEEK":"Cada semana",
      "CONSTANT_FULL_AUTOMATIC_MAPPING":"Mapeo automático (Obsoleto)",
      "CONSTANT_FULL_MANUAL_MAPPING":"Mapeo manual",
      "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Mapeo manual con creación de servicios",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Monto",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Producto",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Cantidad comprada",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Asiento",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Asientos de cierre",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Diferencia",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Asientos de apertura",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"Contrato",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"Cantidad comprada",
      "CONSUMPTION_TYPES_DESC_SERVICE":"Servicio",
      "CONSUMPTION_TYPES_DESC_USAGE":"Uso",
      "CONTACT_CANCEL":"¿Quieres cancelar?",
      "CONTACT_HEADER_TEXT":"¿Tengo una pregunta?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Nuestros Contactos",
      "CONTACT_LABEL_TEXT_EMAIL":"Correo electrónico",
      "CONTACT_LABEL_TEXT_MESSAGE":"Mensaje",
      "CONTACT_LABEL_TEXT_NAME":"Nombre",
      "CONTACT_PARAGRAPH_TEXT":"Comuníquese con nosotros completando el siguiente formulario y le responderemos en breve.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Gracias por brindarnos sus comentarios. Nuestro equipo se pondrá en contacto con usted en breve.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Tus comentarios se han enviado correctamente.",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Agregar",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Borrar",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Editar",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Seleccione un tipo de facturación",
      "CONTRACT_OFFER_BREADCRUMB":"oferta de contrato",
      "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Fuente en la que se crearía el contrato.",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar esta losa?",
      "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Rango de uso sobre el que se aplicaría el contrato",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"¿Estás seguro de que quieres desactivar esta oferta de contrato?",
      "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Servicio de contrato eliminado de Autotask",
      "COOKIE_CONSENT_MESSAGE":"La aplicación utiliza cookies para brindarte una mejor experiencia. Al proceder a acceder a la aplicación, acepta nuestro uso de cookies.",
      "COOKIE_POLICY_BUTTON_TEXT":"Política de cookies",
      "corporate":"Corporativo",
      "Corporate":"Corporativo",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Reiniciar",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Búsqueda",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Clientes",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"planes",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"revendedores",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Seleccionar clientes",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Seleccionar planes",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Seleccionar revendedores",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filtrar",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Nombre del producto principal",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"precio del proveedor",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Cantidad comprada",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Precio de venta",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"costo para el cliente",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Costo para el socio",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Añadir",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Manojo",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":" Vinculado",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Informe resumido de costos",
      "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"No incorporado",
      "coupon":"Cupón",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"El cupón se aplicará a todos los clientes.",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"El cupón no se aplicará a los clientes aunque estén etiquetados con el plan seleccionado",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"El cupón seleccionado es un cupón directo (sin plan).",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Los clientes solo pueden tener un cupón directo a la vez.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Tenga en cuenta que si algún cliente seleccionado tiene asignaciones de cupones existentes, se eliminarán.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Cupón Directo",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"El cupón seleccionado es un cupón del plan.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"A los clientes etiquetados con este plan solo se les puede asignar este cupón.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Cupón del Plan",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"La asignación de cupón se guardó correctamente",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Cupón",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Clientes",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Activo",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inactivo",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Activo",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inactivo",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Agregar nueva asignación de cupón",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Editar",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Acción",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Asignado el",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Aplicable hasta",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Estado",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"Código de cupón",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Código promocional",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Descripción del cupón",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"nombre del cupón",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Nombre del cupón",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Estado",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Está activo",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Por favor seleccione un cupón válido",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"El cupón seleccionado está disponible para ese cliente.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Añadir cupón",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Volver a cupones",
      "COUPON_CAPTION_TEXT_ADD":"Añadir cupón",
      "COUPON_CAPTION_TEXT_EDIT":"Editar cupón",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Seleccionar todas las ofertas",
      "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Colapsar",
      "COUPON_DETAILS_TOOLTIP_EXPAND":"Expandir",
      "COUPON_DISABLE_BUTTON":"Desactivar el cupón",
      "COUPON_DISCOUNT_TOOLTIP":"Porcentaje de descuento a aplicar al cupón",
      "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Seleccione un valor de descuento entre 1 y 100.",
      "COUPON_EDIT_BUTTON":"Editar cupón",
      "COUPON_ENABLE_BUTTON":"Habilitar el cupón",
      "COUPON_HEADER_ACTION":"Acción",
      "COUPON_OWNERSHIPS_CATEGORY":"Categoría",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Aplicar a todos los clientes etiquetados",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"Al seleccionar esta casilla de verificación, se asignará el cupón a todos los clientes que estén etiquetados con el plan seleccionado. Sin embargo, puede administrar las asignaciones en la pantalla de asignaciones de cupones.",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"Aplicabilidad",
      "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Promoción disponible",
      "COUPON_OWNERSHIPS_PLAN":"Plan",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Ofertas de planes",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Precio efectivo",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"es seleccionado",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Nombre de la oferta",
      "COUPON_OWNERSHIPS_PROVIDER":" proveedores",
      "COUPON_OWNERSHIPS_SALETYPE":"Tipo de venta",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Aplicable a",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Debe seleccionar al menos un producto del plan.",
      "COUPON_RECORDS_VALIDATION_MESSAGE":"Debe seleccionar al menos una {{entity}}.",
      "COUPON_SAVE_SUCCESS":"Cupón {{coupon}} guardado correctamente",
      "COUPON_STATUS_FLAG_ACTIVE":"Activo",
      "COUPON_STATUS_FLAG_EXPIRED":"Venció",
      "COUPON_STATUS_FLAG_INACTIVE":"Inactivo",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Código promocional",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Nombre del cupón",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Vence en",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Nombre del plan",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Aplicable a nuevos clientes",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Aplicable a clientes antiguos",
      "COUPON_TABLE_HEADER_TEXT_code":"código",
      "COUPON_TABLE_HEADER_TEXT_CODE":"Código",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Creado en",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Descuento",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Expira el",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Está activo",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"es porcentaje",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"es publico",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Descuento máximo",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Canjes máximos",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Cantidad mínima",
      "COUPON_TABLE_HEADER_TEXT_NAME":"Nombre",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Recurrencias",
      "COUPON_TABLE_HEADER_TEXT_planname":"nombre del plan",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Nombre del plan",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Válida hasta",
      "COUPON_UPDATED_SUCCESS":"Cupón {{coupon}} actualizado correctamente",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"La fecha de vencimiento debe ser mayor que la fecha de validez hasta la fecha.",
      "couponassignment":"Asignación de cupón",
      "coupondetails":"Detalles del cupón",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Agregar nueva asignación de cupón",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Asignaciones de cupones",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Editar asignación de cupón",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Fecha hasta la cual se puede aplicar el cupón al momento de la compra",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Estado del cupón",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Cupones",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Fecha hasta la cual el cupón se considera durante la facturación después de su aplicación",
      "couponstatus":"Estado del cupón",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"El enlace para iniciar la experiencia de consentimiento se copia en su portapapeles",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":" para copiar el enlace que captura el consentimiento del socio para otorgar acceso a esta aplicación al Centro de socios siguiendo el nuevo modelo de seguridad.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Abra el enlace en una sesión de incógnito y complete la experiencia de consentimiento con una cuenta que sea un administrador global en su arrendatario (socio) y tenga asignada la función de agente administrador en el Centro de partners.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Haga clic en el botón Probar para comprobar si la aplicación funciona con el nuevo modelo de seguridad.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en <b>Cambiar al nuevo modelo de seguridad</b> (habilitado después de que la prueba sea exitosa) para permitir que la aplicación borre sus credenciales anteriores y cambie al nuevo modelo de seguridad.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Copiar enlace al portapapeles< /b></a></b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":" Para otorgar acceso a esta aplicación a las API de administración de Azure, este enlace capturará su consentimiento como administrador del cliente.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Después de copiar el enlace, ábrelo en una nueva sesión de incógnito. Luego, proceda a brindar consentimiento para la función de administrador del cliente. Este paso es crucial para que podamos avanzar con el proceso.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Haga clic en el botón Probar para comprobar si la aplicación funciona con las API de administración de Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instrucciones para cambiar al nuevo modelo de seguridad",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Instrucción para proporcionar consentimiento de administrador a la API de administración de Azure",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Instrucción para proporcionar consentimiento de administrador del cliente por parte del usuario del cliente",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"INSTRUCCIONES",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Copiar enlace al portapapeles</a> </b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" para copiar el vínculo que captura el consentimiento del administrador del cliente para otorgar acceso a esta aplicación a las API de administración de Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Una vez que haya copiado el enlace, compártalo con el cliente a través de su canal de comunicación preferido, como el correo electrónico. Luego, solicite su aprobación para el rol de administrador de clientes.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Haga clic en el botón Probar para comprobar si la aplicación funciona con las API de administración de Azure.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"puede informar a su cliente que inicie sesión en el portal C3 -> perfil -> Microsoft En la sección proporcione el consentimiento necesario. Obtener el consentimiento del cliente es fundamental para que podamos seguir adelante.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Aquí hay una lista de informes que se pueden preparar y ejecutar en el momento programado.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Utilice el icono Destinatarios para agregar los destinatarios al informe.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"También puede verificar la cantidad de veces que se ejecutó el informe en el pasado y quién lo recibió.",
      "CPV_LOADING_MESSAGE":"Espere mientras procesamos su consentimiento...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Gracias por brindar su consentimiento. La aplicación se puede configurar para acceder al Centro de socios siguiendo la nueva pauta de seguridad.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones en Configuración -> Centro de socios en <b>{{applicationName}}</b>.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"El intento de acceder al Centro de socios utilizando la nueva configuración de seguridad es exitoso.",
      "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"El intento de acceder a la API de administración de Microsoft Azure se realizó correctamente.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Número de tarjeta",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Mes",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"04 abril 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"08 agosto 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12 diciembre 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"02 febrero 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"01 enero 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"07 julio 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"06 junio 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"03 marzo 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"05 mayo 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11 noviembre 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10 octubre 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"09 septiembre 2024",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Nombre en la tarjeta",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Año",
      "CREATE_BANNER_NOTIFICATIONS":" Crear notificación de banner",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"No se pudo crear la asignación de cupón",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupón creada exitosamente",
      "CREATE_CUSTOMER_EXCEPTION":"Se produjo un error al crear el cliente.",
      "CREATE_CUSTOMER_USER_ROLE":"Crear usuarios de clientes",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"No se pudieron crear usuarios de clientes",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Crear factura",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Crear cliente proveedor bajo revendedor lógico",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Permitir que el revendedor lógico cree un cliente proveedor",
      "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Las ofertas de uso añadidas al plan de revendedor serán del tipo de facturación <b>Marcado</b>. Defina el margen de beneficio para las ofertas de uso una vez que el plan se haya creado correctamente.",
      "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Administrador de cuenta creado con éxito",
      "CREATED_SUCCESSFULLY":"se crea exitosamente",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Parece que falta el detalle del ID de MPN para el revendedor. Comuníquese con el socio para actualizar los detalles.",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"¡No se admite la creación de un nuevo arrendatario de proveedor de servicios!",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"El cliente se creará en el centro de socios en la cuenta de socio.",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Porcentaje de cargo por procesamiento de tarjetas de crédito.",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Tarifa de procesamiento de tarjetas de crédito %",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Ingrese al menos un nombre de impuesto y un porcentaje para los impuestos acumulativos.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Regla de conversión de moneda agregada exitosamente",
      "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Clonar moneda",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Volver a conversión de moneda",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Agregar",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Conversión de Moneda",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"conversión de moneda enviada con éxito",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Editar",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"La regla de conversión de moneda se eliminó correctamente",
      "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Mes de facturación actual",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Todo",
      "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Último mes de facturación",
      "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Próximo mes de facturación",
      "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Selecciona una fecha",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Tasa de conversión",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Cliente",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Moneda de origen",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Moneda objetivo",
      "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Periodo de tiempo",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Tasa de conversión",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Cliente",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"Válido desde",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Moneda de origen",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Estado",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Moneda objetivo",
      "CURRENCY_CONVERSION_RATE_SUCCESS":"Introduzca una tasa de conversión válida.",
      "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"La regla de conversión de moneda se creó correctamente.",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Regla de conversión de moneda actualizada exitosamente",
      "CurrencyCode":"Código de moneda",
      "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Icono actual",
      "CURRENT_PARTNER_PRODUCT_ICON":"Icono actual",
      "CURRENT_SUBSCRIPTION_DETAILS":"Detalles de la suscripción actual",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Los detalles de uso aún no están disponibles, inténtelo después de un tiempo.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Cantidad comprada",
      "Custom Notifications":"Notificaciones personalizadas",
      "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Si elige programar un pedido para una fecha futura y ha especificado una fecha de finalización personalizada, tenga en cuenta que la fecha de finalización personalizada se restablecerá automáticamente a la predeterminada después de seleccionar su pedido programado para el futuro.",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOM_ICON_HELP_TEXT":"Tamaño recomendado: 150 x 175. Solo se admiten archivos de tipo jpg, svg, png, gif, bmp",
      "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"El nombre del elemento de línea debe tener menos de 150 caracteres.",
      "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Agregar entidades etiquetadas",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Cuerpo de texto",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"Válido desde",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Efectivo para",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"ID de notificación",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Plantilla",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Título",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Ofrecer descuentos para su tienda en línea puede ser un arma poderosa para generar lealtad.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Impulsar el marketing y confianza del producto de venta.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Ofrecer descuentos para mejorar en línea una tienda puede fidelizar armas en la conducción.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Comience con un diseño de sitio moderno y personalícelo con su contenido de marca y características. Todos los blogs Premium incluyen CSS personalizado.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Crear notificación personalizada",
      "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Crear notificación personalizada",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Cancelar",
      "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Cerrar filtros",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Nombre del plan",
      "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Introduce la descripción",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Seleccionar planes",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Elija la fecha de vigencia a partir",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Elija efectivo hasta la fecha",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Fecha final",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Asignar licencia de usuario",
      "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Cambiar el estado del producto del cliente",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Gestionar la propiedad del producto",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Administrar licencia de usuario",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Cliente a bordo",
      "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Comprar productos",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Disminución de cantidad",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Aumento de cantidad",
      "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Quitar licencia de usuario",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Actualizar licencia",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Suscripción de actualización",
      "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Se produjo un error durante los eventos de notificación personalizados",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Comportamiento",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Descripción",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"Válido desde",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Efectivo para",
      "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Entidades etiquetadas",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Título",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Descripción",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"Válido desde",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Efectivo para",
      "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Entidad",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Evento",
      "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Plantilla",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Título",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Cerca",
      "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Notificación personalizada",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Reiniciar",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Entregar",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación personalizada guardada correctamente",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Seleccione",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Búsqueda",
      "CUSTOM_NOTIFICATION_SELECT":"Seleccione",
      "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Seleccionar productos",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Tipos de consumo",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"proveedores",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Fecha de inicio",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Entidades etiquetadas",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Elementos de entidad etiquetados",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Comportamiento",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Elementos",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Entidad",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Evento",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Ver entidades etiquetadas",
      "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Elegir la plantilla",
      "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Esto excede la longitud máxima de 30",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Empieza aqui",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Únase a SAP ahora para obtener un 35% de descuento",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Confianza del usuario",
      "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Seleccionar título",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Actualizar",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación personalizada actualizada correctamente",
      "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"Notificación de interfaz de usuario",
      "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"¿Es oferta de prueba?",
      "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"¿Es auto renovable?",
      "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Cantidad predeterminada",
      "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Productos para oferta de prueba",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Categoría",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Tipo de consumo",
      "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Duración del término",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Categoría",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Proveedor",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Ofertas de socios",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Nueva oferta de contrato",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Nueva oferta de socios",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Volver a ofertas de socios",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Aplicabilidad",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Añadir ofertas de contrato",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Ofertas de contrato",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ofertas de socios",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Editar",
      "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"Duración del ensayo",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Seleccione 'ChargePeriods' como 'Predeterminado', 'Regla de facturación para la compra de asientos' y 'Regla de facturación para la reducción de asientos' como 'Prorrateada'",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"A continuación se detallan algunas cosas que debe tener en cuenta al agregar una oferta de socio a una oferta compuesta",
      "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Cantidad de aprobación",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"¿Es autorenovable?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Categoría",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Tipo de período de facturación",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Precio de coste",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Fuente de alimentación",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Instrucciones",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"¿La oferta es un complemento?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Nombre",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Número de días para cancelación gratuita",
      "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"ID de referencia de oferta",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
      "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icono",
      "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Propiedades",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Proveedor",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Precio de venta",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Validez",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Categoría",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Lo que quieres como período de facturación de las suscripciones.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"¿Qué tipo de Consumo quieres que tengan las suscripciones?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Fuente de alimentación",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Número de días máximos permitidos para cancelación sin coste.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad deben prorratearse o cobrarse por completo",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Proveedor",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Tipo de venta",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Losa",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validez de la oferta.",
      "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Seleccione una imagen",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"Oferta de socio {{customoffer}} guardada correctamente",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Incluir ofertas eliminadas",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Ofertas de prueba",
      "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Seleccionar ciclo de facturación",
      "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Seleccione la duración del término",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Precio de coste",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Detalles de la oferta",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Ir a la pantalla de etiquetas de productos",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Vista",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(eliminado)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Este es el precio al que obtuvo este componente. Puede especificar el precio de venta para esta oferta cuando la incluye en un plan.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Este es el precio al que vende este componente.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"Oferta de socio {{customoffer}} actualizada correctamente",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Las fechas de inicio y finalización del cargo serán las fechas de inicio y finalización de un mes.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Las fechas de inicio y finalización del cargo se alinearán con las fechas de inicio y finalización de la facturación de MS.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Las fechas de inicio y finalización del cargo se alinearán con las fechas de inicio y finalización de la facturación del socio.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Alinear con el mes del calendario",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"Alinearse con la facturación de MS",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"Alinearse con la facturación de socios",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Alinearse con la facturación del proveedor",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Predeterminado (desde la fecha de compra)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Establecer nueva contraseña",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"¿Está seguro de que desea restablecer la contraseña?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Se generará una nueva contraseña y la contraseña actual del usuario ya no funcionará.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Ingrese nueva clave",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Mostrar contraseña",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Generar contraseña automáticamente",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Contraseña personalizada",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Mínimo 8 caracteres. Debe contener al menos un carácter en mayúscula, un carácter en minúscula, un carácter especial y un valor numérico.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Elija una contraseña con al menos 8 caracteres.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"La contraseña personalizada no debe contener el nombre del usuario.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Política activada exitosamente",
      "CUSTOM_POLICY_ADD_TITLE":"Agregar política personalizada",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Políticas asignadas",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Política asignada exitosamente",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Asignar política",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"Id. de política de Azure",
      "CUSTOM_POLICY_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Política desactivada exitosamente",
      "CUSTOM_POLICY_DESCRIPTION":"Descripción",
      "CUSTOM_POLICY_INSTRUCTIONS":"Para crear una política personalizada, siga los pasos a continuación",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":" Proporcione un nombre y una descripción para la política personalizada.",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Proporcionar una definición adecuada",
      "CUSTOM_POLICY_LABEL_SCOPE":"Alcance",
      "CUSTOM_POLICY_NAME":"Nombre",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Asignación de políticas",
      "CUSTOM_POLICY_POLICYDEFINITION":"Definición",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Nombre del grupo de recursos",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Política revocada exitosamente",
      "CUSTOM_POLICY_SAVE_SUCCESS":"Política {{policy}} guardada correctamente",
      "CUSTOM_POLICY_SUBSCRIPTION":"Nombre de suscripción",
      "CUSTOM_POLICY_TITLE":"Políticas personalizadas",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Para asignar la política al nivel de suscripción, seleccione todos los grupos de recursos.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rol activado exitosamente",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"ID de rol de Azure",
      "CUSTOM_ROLE_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rol desactivado exitosamente",
      "CUSTOM_ROLE_DESCRIPTION":"Descripción",
      "CUSTOM_ROLE_INSTRUCTIONS":"Para crear un rol personalizado, siga los pasos a continuación",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":" Proporcione un nombre y una descripción para el rol personalizado.",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Ingrese solo las secciones \"Acción\" y \"No acción\" para la definición de roles.",
      "CUSTOM_ROLE_NAME":"Nombre",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Nombre del grupo de recursos",
      "CUSTOM_ROLE_ROLEDEFINITION":"Definición de roles",
      "CUSTOM_ROLE_SAVE_SUCCESS":"Rol {{role}} guardado correctamente",
      "CUSTOM_ROLE_SUBSCRIPTION":"Nombre de suscripción",
      "Customer":"Cliente",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"está sujeto a impuestos",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Actualización de la compra",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Categoría",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Precio por semilla",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Complementos",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Yo/autorizamos a Ezidebit Pty Ltd ACN 096 902 813 (ID de usuario No 165969, 303909, 301203, 234040, 234072, 428198) a debitar mi/nuestra cuenta en la Institución Financiera identificada anteriormente a través del Sistema de Compensación Electrónica Masiva (BECS), en de acuerdo con esta Solicitud de Débito Directo y según el Acuerdo de Servicio DDR de Ezidebit. <br> Yo/nosotros autorizamos que estos pagos se debiten en intervalos y montos según lo indique BeCloudSmart según los Términos y condiciones del acuerdo BeCloudSmart y acuerdos posteriores.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Convenio",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Acuerdo Ezidebit",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Acuerdo de revendedor de la nube de Microsoft",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Condiciones de uso para socios",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Leer Acuerdo Ezidebit",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Antes de utilizar el sistema, lea el",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Pago registrado exitosamente.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"El proceso de incorporación de los datos de facturación del cliente está en curso.",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Continuar a bordo",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Los detalles de facturación del cliente se incorporaron correctamente.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"El cliente ya existe en el sistema de facturación.",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Encontré una coincidencia en el ID: <strong> {{billingProviderReferenceID}} </strong> en el sistema de facturación.<br/><span>La dirección de facturación registrada es <strong> {{formattedAddress}} < /strong>.</span> <br/>Suplante su identidad y utilice la opción <strong>Perfil</strong> para cambiar este detalle después de que los detalles de facturación del cliente se hayan incorporado correctamente.",
      "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Facturación Mensual...",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Error",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"En curso",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Éxito",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Leer más",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Recargar el consentimiento del cliente",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Informe de red de recarga",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Sincronización de perfiles de clientes de proveedores",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Iniciando recarga",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Partidas de factura",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Partidas de factura...",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Elementos de línea de factura con comisiones",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Partidas de factura con comisiones...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Resumen de factura",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Resumen de factura...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Informe de Rentabilidad",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Informe de Rentabilidad por Producto",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Informe de Rentabilidad por Producto...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Informe de rentabilidad por revendedor",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Informe de Rentabilidad por Inquilino",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Informe de Rentabilidad por Inquilino",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Informe de Rentabilidad...",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Partidas de impuestos",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Partidas de línea de impuestos...",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Resumen de impuestos",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Resumen de impuestos...",
      "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Informe de consentimiento",
      "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Descargar informe del cliente",
      "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Exportar informe de conciliación",
      "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Licencia de usuario de exportación",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Recargar el consentimiento del cliente",
      "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Perfiles de clientes de proveedores de sincronización",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Recargar informe de conciliación",
      "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Informe de términos y condiciones",
      "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Informe de límite de monto de transacción",
      "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Las nuevas compras están deshabilitadas a través de la plataforma. Póngase en contacto con su administrador de cuenta para realizar un pedido.",
      "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Póngase en contacto con su gerente de cuenta para comprar un nuevo producto",
      "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Las nuevas compras y los cambios en el recuento de licencias están actualmente en espera. Póngase en contacto con su administrador de cuenta para obtener más información.",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Remover",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Carrito del cliente",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Seguir comprando",
      "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"estoy listo para pagar",
      "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Pagar ahora",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Realizar pedido",
      "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
      "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Verifique la fecha de finalización personalizada en los artículos de la línea del carrito, antes de proceder a la compra.",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Alinear la fecha de finalización con el mes calendario",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Por defecto",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Fecha de finalización de las suscripciones existentes",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Fecha de finalización de las suscripciones existentes",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"ID de suscripción",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Validez",
      "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"promocion aplicada",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Cantidad pagable",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":" Divisa:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Nombre del impuesto:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Coste total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":" Cuenta total:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Descuento total",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Total impuestos:",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Resumen del pedido",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Ya hay una oferta de contrato en el carrito. Elimine la oferta de contrato existente para agregar una nueva.",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Ya tienes una oferta de contrato comprada. Elimine la oferta de contrato existente para comprar una nueva.",
      "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Ya se envió un pedido de este producto al proveedor. Póngase en contacto con su administrador de cuenta para obtener más información.",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Este artículo requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El número de asientos que se compran supera el límite permitido de {{SeatLimit}}.",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Este artículo requiere la aprobación para su cumplimiento por parte del socio después de realizar el pedido.",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Seleccione un sitio/departamento",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"AÑADIR",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Su inquilino no es elegible para comprar Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Su inquilino no es elegible para comprar el Plan Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3 o hay una suscripción de Azure activa en el Centro de partners y necesita una actualización o tiene más de un elemento de línea del carro del plan de Azure en el mismo inquilino.",
      "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Este pedido requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El monto de la transacción supera el límite permitido de <b> {{TransactionAmountLimit}}</b>.",
      "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
      "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Mostrar impuestos en el carrito del cliente",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Los números de factura comenzarán con este texto",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texto del número de factura",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permite al usuario ver las cuotas impagas hasta la factura actual.",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Mostrar cuotas impagas en la factura",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Entidad Operativa",
      "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"¿Está seguro de que desea {{Consent}} el consentimiento de renovación para {{ProductName}}?",
      "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Su consentimiento se acepta con éxito",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Agregar",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Actualizar",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Consentimiento del cliente",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"URL del acuerdo",
      "CUSTOMER_CONSENT_HELP_TEXT":"Especifique los detalles de la persona de la organización del cliente que aceptó el <a href='#' ng-click='vm.CustomerConsentClick()'>Acuerdo de cliente de Microsoft.</a>",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Apellido",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Número de teléfono",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Haga clic <a id='customerConsentLink' href='#' ng-click='vm.MicrosoftCloudAgreementClicked()'> aquí </a>para leer el Acuerdo de cliente de Microsoft.",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Acuerdo de cliente de Microsoft",
      "CUSTOMER_CONSENT_REJECT_POPUP":"¿Está seguro de que desea rechazar el<br>{{ProductName}}?",
      "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Su consentimiento es rechazado exitosamente",
      "CUSTOMER_CONSENT_SAVED_MESSAGE":"Su consentimiento se guarda con éxito",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Consentimiento de renovación de oferta de socio",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Error en el consentimiento de renovación de oferta de socio",
      "CUSTOMER_CONSENT_TEXT_DATE":"Fecha",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Tiempo",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Vaya al menú de perfil y actualice el perfil de pago",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Por favor complete el perfil de pago",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Vínculo rápido a Azure Portal",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Portal azur",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Número de productos comprados hasta el momento",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Productos",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Número de usuarios que tienen acceso a su información",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Usuarios",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Alerta sobre una factura pendiente",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Factura",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Enlace rápido al portal de Office 365",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Portal de oficina",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Enlace rápido a Informes de uso de Office 365",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Informes de uso de Office 365",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Los 10 SKU principales",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Recuentos de productos por SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Cuenta por SKU",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Rango de filtro",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 meses",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 meses",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 año",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Compra de Asientos",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Compra de Asientos",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Compra de Asientos",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Asientos comprados",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Asientos del cliente",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Asientos de clientes",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Registro de aceptación de términos y condiciones",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Términos y condiciones",
      "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Asientos",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Partida de factura eliminada correctamente",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Agregar departamento",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Departamento eliminado exitosamente",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Editar departamento",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Departamentos",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Departamento guardado exitosamente",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Sitios asignados",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Sitio",
      "CUSTOMER_EXPORTINVOICE_WARNING":"La factura para {{customername}} aún no se ha generado. Vuelve a consultar después de un tiempo.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Proveedores de recarga Consentimiento del cliente",
      "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Sincronización de perfiles de clientes de proveedores",
      "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Texto generado de factura de cliente",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Volver a Facturas",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Agregar elemento de línea",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Cancelar",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Guardar factura",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"¿Está seguro de que desea deshacer los cambios?",
      "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"La fecha de inicio del cargo de los elementos de línea no debe ser posterior a la fecha de finalización del cargo",
      "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"¿Está seguro de que desea eliminar la línea de pedido?",
      "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"La fecha de vencimiento de la factura debe ser posterior a la fecha de la factura",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Resumen de factura",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Artículos de línea",
      "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Los impuestos se aplicarán después de guardar la factura.",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Período de facturación",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Monto de la factura",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Fecha de la factura",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Fecha de vencimiento",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Número de factura",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Subtotal",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Dirección",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Ciudad",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"País",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Seleccionar país",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"entidad operativa",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Estado",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Cremallera",
      "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Proporcione al menos un elemento de línea para guardar la factura",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Fecha final",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Fecha de inicio",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"es imponible",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Nombre del árticulo",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"monto de la línea",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Tipo de venta",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Impuesto de venta",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Crédito",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Cargos adicionales",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":" Monto del ajuste",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Detalle de ajuste",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Nombre del proveedor",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Volver a factura",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Agregar",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Ajustamiento",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Editar",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Facturas",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
      "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Crear factura",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Facturas",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":" Etiqueta de cliente",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Valor",
      "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Se envía informe de Licencia de Cliente al correo respectivo",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"filtros",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Administrar producto",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Volver al plan azul",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Nombre del grupo",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Umbrales",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Agregar suscripción de Azure",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"No se encontraron derechos",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Ahorrar",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"El propietario aún no ha sido asignado",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Nombre",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Dueño",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Roles y funciones El contenido de las políticas va aquí....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Facturación",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Comentarios",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Derechos",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Estimar",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Grupos",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Información",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Administrar propietario",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Entidades Operativas",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Pagos",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Funciones y políticas",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Gestionar renovación",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Uso",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Usuarios",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"Grupo de recursos",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Suscripción",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Complementos",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Seleccionar código de mercado",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Descarga un .csv de muestra",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Cargue datos de cuenta para varios usuarios en formato .csv. Descargue un archivo .csv de muestra",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"archivo de referencia.",
      "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Informe de facturación",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Por favor ingrese el nuevo nombre",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Cambiar nombre de cliente",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Nombre",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"El nombre del cliente cambió exitosamente",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Error al actualizar el nombre del cliente",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Actualización exitosa del nombre del cliente",
      "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"No se ha asignado ningún administrador de cuenta actualmente",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"No está vinculado a ningún inquilino de Microsoft.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"La compra de productos del proveedor puede fallar debido a que no hay inquilinos del proveedor vinculados",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Asignar",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"Creado",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"Eliminado",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Error",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Incorporado",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"¡Busca en Facturación!",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Siga estos pasos para obtener el ID del sistema de facturación de un cliente",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Inicie sesión en el sistema de facturación",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Ir a Clientes",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Haga clic en el registro del cliente para abrir",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Coge la cadena que está después de recordkey",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":" Tome la cadena que está después de recordkey= en la URL",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"p.ej:",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"valor a copiar",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Inicie sesión en Authorize.Net",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":" Tome la identificación del perfil del Administrador de información del cliente",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":" Root para el Administrador de información del cliente: Inicio->Administrador de información del cliente",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":" Toma la cadena de la URL.",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"ID del sistema de facturación",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Incorporación masiva de clientes",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Incorporación de clientes",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Recopilar datos del cliente",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Hecho",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"aquí",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"¡Buscar!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Proceder a incorporar a este cliente",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Remover",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Saltar",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Prueba otra",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Incorporación masiva de clientes",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Incorporación de clientes",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Licencias de mapas",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Éxito a bordo",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Tipo de pago",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Por favor seleccione Tipo de Pago",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"La incorporación no está completa. Haga clic en el botón \"Continuar con la incorporación de este cliente\" para continuar.",
      "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"Por favor ingrese su dirección de correo electrónico válida",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"¡Busca en Facturación!",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"ID de débito Ezi",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Siga estos pasos para obtener el ID de EziDebit de un cliente",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Inicie sesión en EziDebit",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Ir a Pagadores",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Haga clic en el registro del pagador para abrir",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Toma la cadena que está después de contractId",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":" Tome la cadena que está después de contractId= en la URL",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"p.ej:",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"valor a copiar",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Clientes disponibles para agregar desde el Centro de socios",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"se crea exitosamente",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"incorporado exitosamente",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Próximos pasos",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Asignar plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Nombre del banco",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Ciudad",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Código de moneda del cliente",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Identificación del cliente",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"¿Se puede compartir el producto?",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Apellido",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Línea 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Línea 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Identificación de Microsoft",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Identificador único de Microsoft asignado a una organización.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Mes",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Siguiente Fecha de facturación",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"NOTA:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"No actualice ningún contenido del archivo que no sean los detalles del usuario como se muestra en el ejemplo anterior.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"opcional:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Si ya tiene los detalles sobre las licencias asignadas a los usuarios según su suscripción, puede proporcionárnoslos siguiendo estos pasos:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Complete la información en el formato que está disponible para descargar desde",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Haga clic en Usuarios y otorgue acceso a este portal a los usuarios del inquilino del cliente.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Suplantar y validar el detalle de la suscripción",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Corrija los errores volviendo a cargar un archivo actualizado con detalles de la licencia para completar el proceso de incorporación.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Simplemente proporcione las direcciones de correo electrónico del usuario entre corchetes ([]) siguiendo este ejemplo:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Sube el archivo y haz clic",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Puede omitir proporcionar los detalles de la licencia haciendo clic en OMITIR para completar el proceso de incorporación.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Haga clic en Listo para volver a la lista de clientes",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Localice al cliente que acaba de incorporarse",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Aparecerá un botón de acción <i class=\"fa fa-star red\"></i> en caso de que haya errores al procesar los detalles de la licencia que cargó.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Haga clic en el botón <i class=\"fa fa-star red\"></i> para ver los errores",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Corrija los errores e intente cargar nuevamente.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Selecciona un plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Proveedor",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Categoría de proveedor",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Estado",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Inquilinos",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Subir archivo",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Seleccione",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Año",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Cremallera",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"Eliminado",
      "CUSTOMER_ONBOARDING_LBL_DISABLED":"Desactivado",
      "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Venció",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendido",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"No hay ningún plan asignado al cliente. Cree un nuevo plan, asígnelo al cliente y luego corrija la discrepancia.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Para los productos de instancias reservadas, consideramos el precio de venta en porcentajes, por lo que el valor predeterminado es 0% de porcentaje. Si desea proporcionar otro valor, modifique el precio de venta.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Nombre del plan",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Código de moneda",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Precio de venta (en porcentaje)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta (en porcentaje)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Este valor se aplicará al precio de facturación de Microsoft para generar la factura correspondiente.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Por favor ingrese el valor hasta dos decimales.",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"planes",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Proveedor",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Proceda a incorporar a este cliente",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"CCA",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manual",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"No",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Sí",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Buscar suscripciones de clientes y mapas",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Licencias de mapas",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Completo",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Completo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":" Ciclo de facturación :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Cantidad del sistema de facturación",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Nombre de empresa",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"ID de Microsoft",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Dominio",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Precio efectivo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Margen",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Productos a juego",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Cantidad de productos a juego",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Porcentaje",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":" Precio",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Nombre del producto del proveedor",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Cantidad de proveedor",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"No se encontraron suscripciones de productos de proveedores para asignar; puede continuar haciendo clic en el botón Recopilar detalles del cliente.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"COSTUMBRE",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Puede cambiar a otros métodos de pago a través de la opción Perfil. El socio le envía las facturas por correo electrónico",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Las suscripciones de uso se cargaron correctamente.",
      "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Cargando....",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Desactivar",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Editar",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Permitir",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Administrar",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Direcciones",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Tipo de entidad",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Apellido",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nombre",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Role",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Perfil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Usuarios",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Añadir nuevo usuario",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Dirección de Envio",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"editar usuario",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Dirección de envío",
      "CUSTOMER_OPTION_ALL_PERIODS":"Todos los periodos",
      "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Términos y condiciones.",
      "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Seleccione la casilla de verificación de los términos y condiciones de NCE para pedir productos de Microsoft NCE.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Seleccione la casilla de verificación de términos y condiciones para solicitar productos de Microsoft.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":" <a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">Términos y condiciones de NCE.</a>",
      "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"El monto a pagar debe ser la suma del producto más el impuesto total.",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Reintente el pago",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Mirar la factura",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Ver productos",
      "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Volver a Pedidos",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"AÑADIR",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"filtros",
      "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Nueva compra",
      "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Actualización de cantidad",
      "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Cambio de estado",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Pedidos de los clientes",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Ver detalles",
      "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Estado",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Pedidos de los clientes",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"En espera",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Comportamiento",
      "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Operación",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Nombre de Departamento",
      "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Operación",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Solicitar ID",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"Hasta la fecha",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"Partir de la fecha",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"funcionamiento de la orden",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"estado del pedido",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Ordenado por",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"pedido el",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"pedidos de",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Pedidos a",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Nombre del sitio",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Estado",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Última actualización",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Leer más",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Volver a pagos de clientes",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Facturas pendientes de",
      "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Seleccionar identificador único",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"volver a la lista",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Código de cupón actual",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong>¡Error!</strong> No se puede mover al cliente a <strong>{{targetPlanName}}</strong> desde <strong>{{PlanName}}</strong> porque el plan objetivo no tiene ofertas que coincidan con las suscripciones actualmente activas.",
      "CUSTOMER_PLAN_CHANGE_INFO":"El cambio de plan aún no se admite si hay al menos una suscripción activa con una oferta compuesta en el plan actual.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Plan actual",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Suscripciones de clientes",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Precio efectivo",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Coincidencia encontrada",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Nombre de destino",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Precio objetivo",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Este cupón ya está asignado al cliente.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"planes",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong>¡Éxito!</strong> El cliente puede ser trasladado a <strong>{{targetPlanName}}</strong> desde <strong>{{PlanName}}</strong>. Haga clic en <strong>Enviar</strong> para continuar.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Plan objetivo",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Utilice el código de cupón actual",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(eliminado)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Cerca",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Complementos",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Complementos",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Cambiar",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Reactivar suscripción",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Reactivar suscripción con complementos",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Suspender suscripción",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Complementos",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Manojo",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Entidad Operativa",
      "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Añadir más vales",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Entregar",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Continuar suscripción</b> <br/> ¿Está seguro de que desea continuar con la suscripción <b>{{productName}}</b> después de la prueba?",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" La oferta de prueba {{productName}} se convertirá en una suscripción una vez que se alcance la fecha de finalización efectiva del proveedor.",
      "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"¿Está seguro de que desea cambiar el ciclo de facturación de {{billingCycle}} a {{c3billingCycle}}?<br> Después de generar la factura, el ciclo de facturación no se puede revertir.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Cancelar suscripción</b> <br/> ¿Estás seguro de que deseas cancelar tu suscripción de prueba de <b>{{productName}}</b>?",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Se produjo un error al cancelar la suscripción a {{productName}}.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"¡Oferta de prueba cancelada para {{productName}} exitosamente!",
      "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"apagado",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorar",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Error de detalles",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Cerca",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
      "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Periodo de prueba",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Introduzca valores separados por comas.",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Instrucciones",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Perpetuo",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"software perpetuo",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Suscripciones de software",
      "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Confirmar actualización de nombre en C3",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar el ciclo de facturación de este producto a {{targetBillingCycle}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar la Oferta de este producto a {{targetOffer}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar el alcance de este producto a {{targetScope}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar la duración del plazo de este producto a {{targetTermDuration}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea reactivar {{productName}} {{withAddons? 'con complementos': '}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea suspender {{productName}}?",
      "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Falta la suscripción principal para {{Name}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Cambió el nombre con éxito",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"Cambió el número de pedido con éxito",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Se cambió el ciclo de facturación de {{productName}} a {{targetBillingCycle}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Cantidad de producto no válida",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"No se puede actualizar la cantidad a cero para un producto ya comprado.",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"La solicitud de suscripción de actualización se ha enviado correctamente",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Solicitud para reactivar {{productName}} {{withAddons? 'con complementos': ''}} se ha enviado correctamente",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"¿Se reactivó {{productName}} con éxito {{withAddons? 'con complementos': '}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Propiedad liberada exitosamente",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"La solicitud para suspender el producto, {{productName}} se ha enviado correctamente",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"Suspendido {{productName}} exitosamente",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Auto renovación",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"se renueva el",
      "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Cancelación permitida hasta",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"Cambiar el ciclo de facturación actualizará todas las suscripciones que forman parte del \"pedido\". Esto creará un nuevo plazo anual para todas las suscripciones al precio actual basado en la lista de precios. Las siguientes suscripciones se actualizarán a la facturación \"Anual\". Para completar esta solicitud, haga clic en \"Aceptar\" y luego en Enviar.",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Cambiar ciclo de facturación",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Cancelar suscripción</b> </br> <br/> <b> Nota: </b> Su ventana de cancelación de 7 días se ha cerrado. No podemos cancelar su suscripción.</br>Si desea cancelar esta suscripción en el próximo ciclo de facturación, actualice el estado de renovación automática a falso.",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Cancelar suscripción",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Establecer renovación automática</b> </br> <br/> <b> Nota: </b> No podemos cambiar el estado de renovación automática.",
      "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Expira el",
      "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Administrar la frecuencia de facturación",
      "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Pausar el acceso del cliente al servicio",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"Reanudar una suscripción suspendida restaura el acceso del cliente al servicio. No tendrá ningún impacto en su factura. Se le seguirá facturando con normalidad. Si tenía la renovación automática activada y los cambios programados antes de pausar el acceso, puede volver a activarlos.",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Reanudar el acceso del cliente al servicio",
      "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Presentar cambios",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Cancelar suscripción",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Cancelar suscripción</b> <br/> ¿Está seguro de que desea cancelar {{productName}}? <p style='text-align:left;' > Para recibir un reembolso, debe cancelar las suscripciones dentro de los 7 días calendario posteriores a la compra o renovación.<br/> <b>Tenga en cuenta:</b> El cliente perderá el acceso a esta suscripción inmediatamente después de la cancelación y el acceso no podrá ser restaurado después de la cancelación. Si cancela la suscripción dentro de los 7 días calendario, se le reembolsará el tiempo que no haya utilizado. Aparecerá un crédito en su próxima factura mensual. <a target='_blank' href=\"{{cancelNewCommerceSubscriptionGuidLineURL}}\"> Más información</a> </p>",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"¡Cancelar con éxito!",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Establecer renovación automática</b> <br/> ¿Está seguro de que desea {{autoRenewStatus}} estado de renovación automática para {{productName}}?",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Caduca",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Estado de renovación automática actualizado con éxito para {{productName}}",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"¡Actualizado con éxito!",
      "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea suspender {{productName}}? <br/> <b> Nota: </b> Aunque pausar el acceso del cliente al servicio bloquea inmediatamente la capacidad del cliente para usar los servicios, esto no afecta su factura. Se te seguirá facturando normalmente. Al pausar, se apagará y deshabilitará la renovación automática y se eliminarán los cambios programados.",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Actividad",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Estado de actividad",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Fecha",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Cantidad de origen",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Actualizar de {{sourceProductName}} a {{targetProductName}}",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Cantidad objetivo",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Transición",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Productos del cliente",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Asignar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Volver a los detalles básicos",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Ahorrar",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Cantidad comprada",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Estado",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Complementos",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"La cantidad no puede ser mayor que",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Ciclo de facturación personalizado",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Descuento",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Cantidad disponible)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Cantidad para",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Suscripción vinculada",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Asignar",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Ahorrar",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Complementos",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Cantidad disponible)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"La cantidad no puede ser mayor que",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Cantidad para",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Descuento",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Comprar suscripción</b> <br/> ¿Está seguro de que desea convertir su oferta de prueba de {{productName}} en suscripción?",
      "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar la cantidad de <b>{{oldQuantity}}</b> a <b>{{newQuantity}}</b> para el producto actual?",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"¿Es auto renovable?",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Expira el",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"ID de suscripción de Azure",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"ID de reserva",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"ID de orden de reserva",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Región de reserva",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Alcance",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"se renueva el",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Este artículo requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El número de puestos que se compran supera el límite permitido de <b> {{SeatLimit}}</b> para <b>{{productName}}</b>.",
      "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Filtrar entidades",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Secuencia de productos",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Secuencia de productos",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":" Añadir",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":" Vinculado",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Departamento",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":" Para",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"¿Está seguro de que desea deshacer los cambios?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"¿Está seguro de que desea actualizar la secuencia del producto?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Secuencia de producto del cliente actualizada",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"La última solicitud de actualización tiene algunos errores Leer más...",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Descuento",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Activo",
      "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Cancelado",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Cancelar prueba",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Continuar suscripción después de la prueba",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Comprar suscripción",
      "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Terminado",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"Eliminado",
      "CUSTOMER_PRODUCT_STATUS_DISABLED":"Desactivado",
      "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Venció",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Ha fallado",
      "CUSTOMER_PRODUCT_STATUS_INPROVISION":"En Provisión",
      "CUSTOMER_PRODUCT_STATUS_PENDING":"Pendiente",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Tuvo éxito",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendido",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"filtros",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"País",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Dominio",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Precio del producto vinculado",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Proveedor",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Reactivar suscripción",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Reactivar suscripción con complementos",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Alcance",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Tipos de servicio",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Estado",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Suspender suscripción",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Duración del mandato",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Periodo de prueba",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud.",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"La oferta de {{productName}} se convertirá en una suscripción al final del período de prueba.",
      "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Oferta de prueba Padres",
      "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Duración del mandato",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Volver a Pedidos",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"Incluir 0 cantidades",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Renovación",
      "CUSTOMER_PRODUCTS_IN_ACTIVE":"La suscripción está inactiva.",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Propiedad de",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Suscripción vinculada",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"Estado",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Departamento",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Dominio",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Sitio",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Identificación de suscripción",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Inquilino",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Cancelar suscripción",
      "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Cupón copiado al portapapeles",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Detalles básicos",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Comentarios",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Notificaciones",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Administrar propiedad",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Usuarios",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Cerca",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Copiar link",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Copiar link",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Descargar",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Obtener claves y descargas",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Enlace copiado al portapapeles",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Seleccionar tipo de archivo",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Seleccione el idioma",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Seleccionar producto",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Comportamiento",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Tamaño del archivo",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Identificación",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Llave",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Nombre",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Cantidad comprada",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Derechos",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Fecha de cancelación",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Fecha de renovación",
      "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"promocion aplicada",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Ordenado",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Administrar",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"Procesando",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Aplicar",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"Claro",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Cerca",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Incluir entidades secundarias",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Dominio",
      "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Opciones de filtro",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Administrar producto",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Monto",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"pedido el",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Propiedad de",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"se renueva el",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Cantidad total",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Precio unitario",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Precio unitario",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Departamento",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Sitio",
      "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Los cambios programados están presentes en esta suscripción.",
      "CUSTOMER_PRODUCTS_VALIDITY":"Validez",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Asignado al administrador el",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Correo electrónico",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Número de teléfono",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Nombre del gerente",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Detalles del administrador de cuentas",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Detalles básicos actualizados exitosamente",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Dirección de envío",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Perfil",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Departamentos",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Sitios",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Añadir dirección",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"Agregar correo electrónico",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Añadir teléfono",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Direcciones",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"Correos electrónicos",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Números de teléfono",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Apellido",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Nombre",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Sitios",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"¡No se encontraron registros!",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Agregar",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Perfiles de pago",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Cuenta bancaria",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Tarjeta de crédito",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"No se encontraron perfiles. Agregue un nuevo perfil de pago haciendo clic en el botón Agregar.",
      "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"La adición del perfil de pago está en curso. Espere un momento mientras se completa el proceso.",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Dirección de envío",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Cuentas de usuario",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Fecha de creación de la cuenta",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Dirección de Envio",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":" Fecha de incorporación C3",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Nombre Canonico",
      "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Gracias por escoger",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Configuracion de cuenta",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":" Comercio",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Orden de revisión",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Confirmar",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Hecho",
      "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"La dirección de correo electrónico ya está en uso. Inicie sesión en C3 utilizando sus credenciales o cree una nueva cuenta con una dirección de correo electrónico diferente",
      "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Por favor complete los campos requeridos para continuar",
      "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"Realizó correctamente la solicitud de registro",
      "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Sus datos se han enviado correctamente. Nos comunicaremos contigo en breve.",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Entregar",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Comprar productos",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"Se debe confirmar la aceptación del acuerdo del cliente del proveedor",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existe",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"no existe",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Seleccionar país",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Contacto primario",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Dirección 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Dirección 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Ciudad",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"País",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Nombre de dominio",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"Dirección de correo electrónico del administrador global",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"¿Otorgar acceso al contacto principal (como administrador del cliente)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Apellido",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"El correo electrónico proporcionado tendrá acceso de CustomerAdmin al portal.",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"¿Enviar invitación para acceder a la aplicación?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Estado",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Identificación del inquilino",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Cremallera",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Este nombre de dominio no es válido",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"está disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"no está disponible",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Validación de la disponibilidad del dominio",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Seleccione estado",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Ingresar estado",
      "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Para mantener los detalles del dominio del inquilino",
      "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Para comunicar cosas relacionadas con la facturación.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Envíe los detalles a continuación y el socio se comunicará con usted para brindarle más ayuda.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Haga clic en copiar enlace al portapapeles para copiar el enlace de la relación de revendedor y seguir el modelo de revendedor.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Abra el enlace en una sesión de incógnito y complete la relación de revendedor utilizando una cuenta que sea un administrador global.<br>Después de completar, proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Si no desea aceptar la relación de revendedor, simplemente proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Instrucciones para aceptar la relación de revendedor",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Instrucciones para aceptar la relación de revendedor",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"Mientras tanto, puede autorizar a un socio como su revendedor de CSP brindando su consentimiento en el enlace de relación de revendedor utilizando su Usuario administrador global.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Haga clic en copiar enlace al portapapeles",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":"para copiar el enlace de relación de revendedor y seguir el modelo de revendedor.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Abra el enlace en una sesión de incógnito y complete la relación de revendedor utilizando una cuenta que sea un administrador global.<br>Después de completar, proporcione los detalles a continuación y haga clic en enviar, luego ese socio se comunicará con usted.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Si no desea aceptar la relación de revendedor, simplemente proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"Id. de lote de registro",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"El procesamiento puede tardar un tiempo y el estado se mostrará en esta página. Si abandona el navegador o lo cierra por accidente, le informaremos por correo electrónico.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"registro en curso",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Espere mientras configuramos su cuenta. Puede continuar usando su dispositivo como siempre. Le enviaremos una notificación por correo electrónico una vez que se complete la configuración.",
      "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Para obtener la información y las suscripciones del inquilino",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Carro",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Detalles del pago",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Comprar productos",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente externo",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Acción",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"productos C3",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Producto externo",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Secuencia de números",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Convenio",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Nombre de la categoría",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Contrato",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente de PSA",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Utilice más filtros si desea establecer restricciones adicionales en la categoría, el inquilino, etc.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"¿Es el contrato por defecto?",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Los contratos predeterminados se utilizarán para cargar servicios de contrato asociados con servicios que se crean y asignan automáticamente.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Inquilino",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":" Departamento :",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Sitio :",
      "CUSTOMER_RECONCILIATION_REPORT":"Reporte de conciliación",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Esta función está disponible para ofertas personalizadas y tiene soporte por un período de un año.",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"La Suscripción Vinculada no será compatible con esta funcionalidad.",
      "CUSTOMER_REPORT":"Informes de clientes",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filtrar productos",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Fecha máxima de pedido",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Cantidad máxima",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Fecha mínima de pedido",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Cantidad mínima",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Caduca en días",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Ordenado entre",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Cantidad comprada",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Informe de productos",
      "CUSTOMER_REPORTS":"Informes",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"El ajuste se guardó correctamente.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Se agregaron cuotas vencidas como ajuste a la factura actual.",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Pago registrado exitosamente",
      "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"ID de referencia de facturación",
      "CUSTOMER_SEARCH_CLOSE_OPTION":"Cerrar filtros",
      "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Incluir cliente eliminado",
      "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"ID de oportunidad de CRM",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Método de pago por defecto",
      "CUSTOMER_SEARCH_CUSTOMER_name":"nombre",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"Nombre",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Método de pago",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Etiquetas",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"Seleccione",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Nombre de dominio",
      "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"identificación del cliente ERP",
      "CUSTOMER_SEARCH_FILTER":"Filtrar clientes",
      "CUSTOMER_SEARCH_INVOICE_NOTES":"notas de factura",
      "CUSTOMER_SEARCH_MARKET":"Código de mercado",
      "CUSTOMER_SEARCH_PROVIDER":"Proveedor",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
      "CUSTOMER_SEARCH_RESET_OPTION":"Reiniciar",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Búsqueda",
      "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Identificador único",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Añadir a la cesta",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Seleccionar todos los complementos",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Suscripción vinculada",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Cerca",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":" Comercio",
      "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Para filtrar o incluir productos de nivel infantil",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Tipos de facturación",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Validez",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Tipo de validez",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"Duración del ensayo",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"AÑADIR",
      "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"OFERTA DE PRUEBA",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"filtros",
      "CUSTOMER_SIGNUP_FAILED":"Registro de cliente fallido",
      "CUSTOMER_SIGNUP_SUCCESS":"Éxito en el registro del cliente",
      "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Registro exitoso. Puede iniciar sesión en el Portal C3 con las credenciales que se envían a:",
      "CUSTOMER_SITE_ADD_SITE":"Agregar sitio",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Sitio eliminado exitosamente",
      "CUSTOMER_SITE_EDIT_SITE":"Editar sitio",
      "CUSTOMER_SITE_HEADER_TEXT":"Sitios",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Sitio guardado exitosamente",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"No es posible ignorar los errores en esta suscripción. Comuníquese con el servicio de asistencia de C3 para obtener más ayuda.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"plan azul",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Derechos",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Seleccione el plan Azure.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Seleccione derechos.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Estás seguro de que quieres continuar?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"¿Aplicar promoción al cliente?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" El precio no puede ser otro que <strong>{{price}}</strong> porque este ReservationOrderID {{reservationorderID}} ya tiene un producto de reserva con precio.<br>¿Quieres sincronizar con el precio <strong>{{price} }</strong> ?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Aceptar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Rechazar",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Comportamiento",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"volver a productos",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Gerente de renovación",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Gerente de Renovación",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Acción",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Volver al gestor de renovaciones",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Historial del gerente de renovación",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Actualizado en",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Descargar el historial del gestor de renovaciones",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Historial del gerente de renovación",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":" Precio",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Nombre del producto",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Cantidad comprada",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nombre de usuario",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":" Precio",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Productos",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Cantidad comprada",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Renueva el",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Nombre",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Seleccione al menos un archivo.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Subir archivo exitosamente",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Aprobar",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Cargando el informe de conciliación",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Recargando los detalles de facturación de los clientes...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Agregar cliente",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Agregar suscripción",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Agregar usuario a la suscripción",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Volver a usuarios de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Volver a suscripciones",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Existente (múltiples)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Incorporación masiva de clientes",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Existente (único)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Cliente existente",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Resumen de facturación de exportación",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exportar detalles de uso",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Ir al informe",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"aquí",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Nuevo",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Nuevo cliente",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Cliente a bordo",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Reporte de conciliación",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Recargar datos de facturación",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Recargar mes actual",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"recargar completo",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recargar resumen de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Recargar detalles de uso",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Remover",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Seleccione",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Agregar cliente",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":" Agregar suscripciones para",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":" Suscripciones de Azure de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Clientes",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Editar suscripción",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":" Administrar usuario de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Ofertas",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Planes y ofertas",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Informe de conciliación para",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Usuarios de suscripción de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Configuración del cliente de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Valor",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Detalles de uso de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":" Usuarios de",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Validar errores de incorporación para",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finalizar",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Facturación",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Período de facturación",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Perfil de la empresa",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Tipo de pago",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Por favor seleccione Tipo de Pago",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Contacto primario",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"logo",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"¡La factura ha sido aprobada!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Aún puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que finalice y se le cobre al cliente. El cliente ahora puede ver esta factura y usted puede registrar los pagos de esta factura una vez finalizada.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"¡La factura ha sido finalizada!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"El usuario ahora puede ver esta factura y puede registrar los pagos contra esta factura.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"¡Se ha generado la factura!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que finalice y se le cobre al cliente.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"La factura se enviará por correo a su dirección de facturación. Gracias.",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":" Pago automático",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Pago automático: DESACTIVADO",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Pago automático: ON",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Facturación",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organización IIN",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Método de pago",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Nombre del banco",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Ciudad",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Umbral de peligro",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Umbral de error",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Nombre del grupo",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Umbral de información",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Ocultar del cliente",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Artículos seleccionados",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Apellido",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Línea 1",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Línea 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Mes",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Role",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Estado",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Umbral de advertencia",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Año",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Cremallera",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"planes",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Cantidad comprada",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"CCA",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manual",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"No",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Sí",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Suscripción inactiva",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"La cantidad no coincide",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Eliminar acceso",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Editar suscripción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Habilitar suscripción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Usuarios",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Ver detalles de uso",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Activo en CSP",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Nombre del componente",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Identificación de correo",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Derechos",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Error",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Costo estimado",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Ocultar del cliente",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Las suscripciones no son visibles para los clientes.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Última actualización",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"factura MSFT",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Estado de incorporación",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":" Precio",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Lucro",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Role",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"ID de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Umbrales",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Usuario",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nombre de usuario",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Aún no se han agregado suscripciones.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Asignar plan de cliente",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":" Cambiar ciclo de facturación",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Cambiar nombre",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Definir etiquetas",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Definir % de impuesto",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Solucionar la discrepancia en el estado de activación en el Centro de socios",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Solucionar el desajuste del estado de renovación automática en el Centro de partners",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Corregir discrepancias...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Corregir discrepancias",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Cambiar oferta",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Proveedores de pago",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":" Actualizar el estado del producto perpetuo",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Cambio de plan",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Corregir promoción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"proveedores",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Cliente : Suplantación rápida",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Reporte de conciliación",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recargar resumen de suscripción",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Arreglar alcance",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Configuración del cliente",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":" Cambiar término",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Prueba de pago automático",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Usuarios",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Errores de validación",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"COSTUMBRE",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Total",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Presupuesto mensual-Información-Advertencia-Error-Peligro",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"está disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"no está disponible",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Descargue la plantilla desde",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Borrar usuario",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Conceder acceso al portal",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Personificar",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Revocar el acceso desde el portal",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"El valor debe estar entre 0 y 100.",
      "CUSTOMER_SUP_TEXT_DELETED":"(eliminado)",
      "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Sincronizar perfiles",
      "CUSTOMER_TAGS_EXAMPLE":"Defina pares de etiquetas y valores para el cliente que se utilizarían al definir el control de acceso para los usuarios. <br/> Ejemplo: puede definir una etiqueta denominada SupportAgent y un apodo de un agente (por ejemplo, Agent001) como valor. Si un agente de la mesa de ayuda tiene una de las etiquetas configuradas como SupportAgent y uno de los valores para esta etiqueta en particular como Agent001, el agente podrá ver aquellos clientes que tienen el par de valores de etiqueta {SupportAgent, Agent001} configurado.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Nombre",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Valor",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Borrar",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Editar",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Valor",
      "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"La fecha de finalización del plazo personalizado especificada en las instrucciones del próximo plazo programado solo es válida para los productos OnlineServicesNCE.",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Ver facturas pendientes",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":" Pagos por",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Cliente",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Cliente",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Detalles del usuario guardados exitosamente",
      "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"El informe de asignación de licencia de usuario se enviará al correo electrónico proporcionado.",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Añadir nuevo usuario",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"volver a la lista",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Autorizará el acceso",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Administrar usuario",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Revocar el acceso",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Departamento",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"Role",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Tipo de rol",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"Sitio",
      "CUSTOMER_USERS_HEADING":"Usuarios",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Role",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Descargar facturas/Pagos",
      "customerconfiguration":"Configuración del cliente",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Mencione si los detalles de ACH están relacionados con una cuenta comercial",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Este cliente ya ha sido seleccionado",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"El cliente ya existe en el sistema.",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Cliente creado exitosamente",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"El proceso de incorporación del cliente está en curso.",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Seleccione al menos un archivo.",
      "CUSTOMERONBOARDING_SUCCESS":"El cliente se incorporó correctamente.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Se produjo un error al cargar el archivo.",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} ya tiene una licencia para {offername}",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"La cantidad de usuarios proporcionados es mayor que la cantidad de licencias disponibles. Corrija los detalles del usuario y vuelva a intentarlo.",
      "customers":"Clientes",
      "Customers":"Clientes",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Comportamiento",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Negocio",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clientes",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Enlace al perfil de facturación",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Enlace al proveedor",
      "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Eliminar enlace al perfil de facturación",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Creando facturas…",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Recargando el Informe de Rentabilidad de Clientes...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Volver a Facturas",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Agregar ajuste",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"¡Agregue el monto adeudado como ajuste!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Descargar factura",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Ir al informe",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Historia por fecha",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Historia por oferta",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Iniciar pago",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Cliente de carga de prueba",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Factura para",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Rentabilidad por suscripciones para",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Cambio en el historial de suscripciones para",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"No se puede comprobar la disponibilidad del dominio",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"¿Estás seguro de que deseas otorgar acceso a {{username}}?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Clientes",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"No se encontraron facturas. Por favor contacte al soporte.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Dirección",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Cliente",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"número de factura",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Resumen de la cuenta",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Resumen de factura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Período de facturación",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Fecha de vencimiento",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Monto de la factura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Fecha de la factura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Fecha de vencimiento de la factura",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Generado en",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Estado de la factura",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"¡Se ha generado la factura!",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que se procese y se le cobre al cliente.",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"¡La factura ha sido procesada!",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"El cliente ahora puede ver esta factura y usted puede registrar los pagos contra esta factura.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Cuotas de facturas pasadas",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Parece que hay cuotas de facturas anteriores. Si está seguro de haber registrado todos los pagos del cliente, haga clic en el siguiente botón para",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"para agregar <strong>{{InvoiceDueAmount}}</strong> como ajuste a la factura actual.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Parece que hay cuotas de cantidad.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":" en total.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Editar",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Borrar",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Detalle de factura",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Basado en licencia",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Basado en el uso",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":" Comportamiento",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Monto",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Tipo de carga",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":" Descripción",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Importe de descuento",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Precio de oferta",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":" Cantidad",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Fecha final",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"gran cantidad",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Monto subtotal",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Fecha de inicio",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"nombre de suscripción",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Importe del impuesto",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Porcentaje de impuesto",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Precio unitario",
      "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"elementos de la línea de factura",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Registro de pago",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Monto del pago",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Observaciones",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Razón del fracaso",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Monto de pago",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Identificación de pago",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Pagos de facturas",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Observaciones",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"ID de transacción",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Instrucción para registrar el pago.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"Registrar un pago (parcial o total) de una transacción que ocurrió fuera del sistema",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"El exceso de los pagos se trataría como saldo y se ajustaría para una factura posterior.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Comuníquese con el soporte en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"Tarifa de procesamiento de CC ({{CCFeePercentage}}% sobre {{CCPaidAmount}})",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Gran total",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Subtotal",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Razón del fracaso",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Monto",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Fecha",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Observaciones",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Estado de pago",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Los detalles de facturación, incluido el historial, se actualizaron correctamente",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Los detalles de uso, incluido el historial, se actualizan correctamente",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"incorporado en",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"planes",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"Identificación C3",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Nombre",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Clientes incorporados",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Buscando un registro coincidente en el sistema de facturación",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Encontré una coincidencia en el ID de referencia {{ID}} en el sistema de facturación.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Buscar clientes en C3 para una coincidencia por ID de cliente del proveedor",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Encontré un cliente con nombre: {{customername}}",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Buscando clientes en {{providerName}} para una coincidencia por ID de cliente del proveedor",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Encontré un cliente con nombre: {{customername}} en {{providerName}}",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Las cantidades de ofertas para niños en un compuesto deben ser las mismas.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Una o más ofertas en CSP se asignan a la misma oferta en facturación. Por favor ajusta tu mapeo",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"La oferta {{Name}} del plan tiene varias suscripciones de CSP coincidentes.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"No se pudo asignar la suscripción {{nameinbilling}} en facturación",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"La oferta {{name}} no tiene suscripciones de CSP coincidentes en el Centro de socios",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"La suscripción en el Centro de socios: {{name}} no tiene ninguna oferta coincidente en el plan para asignar",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Las siguientes ofertas deben asignarse a suscripciones de CSP:",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"La oferta {{name}} tiene más usuarios especificados que puestos que tiene",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Las cantidades de las suscripciones deben coincidir con las del Centro de partners.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Las cantidades de la suscripción {{subscriptionname}} deben coincidir entre el Centro de partners y la facturación.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Las cantidades de las suscripciones deben coincidir entre el Centro de socios y la Facturación.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"La cantidad de puestos de una suscripción de CSP {{cspsubscription}} debe coincidir con la suma de todos los números proporcionados en ofertas relacionadas en el plan.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Una o más ofertas de CSP no están asignadas o faltan suscripciones en CSP",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Se produjo un error al actualizar las suscripciones de {{customerName}}",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Suscripciones actualizadas para el cliente: {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"El registro del cliente se completó con éxito. Se envía un correo electrónico a {{registeredemail}} con instrucciones para iniciar sesión en el portal.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"El registro del cliente se completó con éxito.<br/>Las credenciales del usuario administrador son:<br/><b>UserId -</b> {{userId}},<br/><b>Contraseña temporal -</b> {{password }}",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"El registro del cliente se completó correctamente.<br/>Las credenciales del usuario administrador son:<br/><b>Id. de usuario -</b> {{userId}},<br/><b>Contraseña temporal:</b> {{contraseña }} <span class =\"text-danger\"> <br/><br/> <b>No se pudieron asignar los grupos de seguridad a la solicitud GDAP. Complete esto en el Centro de socios; de lo contrario, fallarán otras acciones.</ b> </span>",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"El registro del cliente se completó con éxito.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"El registro del cliente se completó con éxito. Inicie sesión en el portal.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Por favor asocie una oferta para el ajuste.",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"Clientes seleccionados para a bordo",
      "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Sincronizar perfiles",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Pagos de clientes",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Cantidad facturada",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Fecha",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nombre de Departamento",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Nuevo precio",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Precio anterior",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Otro precio",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Importe facturado antes de impuestos",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Factura MSFT antes de impuestos",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Lucro",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Ganancia %",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Nombre del sitio",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Usuario",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Aún no se han agregado suscripciones.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Eliminar cliente",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Configuración del cliente",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Administrar planes",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Acción",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Nombre",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Descargar líneas de pedido basadas en el uso",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Rentabilidad por suscripciones",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Recargar informe de rentabilidad",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Historial de cambios de suscripción",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Mirar la factura",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Ver líneas de pedido de uso",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"Se agregó {{username}} a la suscripción de Azure con éxito",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"Se agregó {{username}} a la suscripción de Azure {{azuresubscription}} con éxito",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} se eliminó correctamente de la suscripción de Azure",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"¿Estás seguro de que deseas eliminar al usuario?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} se eliminó correctamente de {{azuresubscription}}",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"La suscripción de Azure se guardó correctamente",
      "customNotifications":"Notificaciones personalizadas",
      "customoffer":"Oferta personalizada",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Estado de la factura actualizado correctamente",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Pedido realizado con éxito. ID de pedido: {{ID de pedido}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Destinatarios de contraseñas",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Correo electrónico de los destinatarios",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Introduzca los destinatarios del correo electrónico separados por comas",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Agregar nueva entidad",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Cancelar",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Administrar",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Entregar",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Agregar nueva entidad",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Entidades Operadoras",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Descripción",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Tipo de entidad",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nombre",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Registro de aceptación de términos y condiciones",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Mirar la factura",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Nueva factura generada el <b>{{InvoiceGeneratedDate}}</b> para el período de facturación del <b>{{BillingStartDate}}</b> al <b>{{BillingEndDate}}</b> con importe <b >{{ImporteFactura}}</b>.",
      "DASHBOAR_TEXT_DATE":"Fecha",
      "DASHBOAR_TEXT_EMAIL":"Correo electrónico",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL del acuerdo de Ezidebit",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"URL del acuerdo de cliente de Microsoft",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL de términos y condiciones para socios",
      "DASHBOAR_TEXT_TIME":"Tiempo",
      "Dashboard":"Panel",
      "DASHBOARD_BREADCRUMB":"Panel",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Análisis de clientes",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Informes",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Informe de cambio de licencia",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Informe resumido de licencia",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Informe de uso",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Actualización del perfil",
      "DASHBOARD_CAPTION_PARAGRAPH":"Proporcione recursos para administrar un negocio exitoso con el poder de la nube.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Bienvenido al mercado de la nube",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Todos los SKU en CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"Descargar CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"Descargar PNG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"Descargar SVG",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Los 10 principales SKU en CSV",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"microsoft",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"Microsoft sin CSP",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Compañero",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Todo",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Último período de facturación",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 períodos de facturación",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 períodos de facturación",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 períodos de facturación",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 meses",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 meses",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 meses",
      "DASHBOARD_COMPLAINCE_TEXT":"Centro de Cumplimiento",
      "DASHBOARD_COST_TEXT":"Costo",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Número de departamentos",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Departamentos",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Departamentos",
      "DASHBOARD_HEADER_TEXT_WARNING":"¡Advertencia!",
      "DASHBOARD_ICON_TITLE":"Iconos-de-Estocolmo / Diseño / Capas",
      "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Mes actual",
      "DASHBOARD_LBL_TEXT_NET_SEATS":"Red",
      "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Mes anterior",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Bienvenido a su portal de licencias Dashboard",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Utilice los mosaicos a continuación como enlaces rápidos para administrar sus suscripciones y usuarios.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Este portal le permite agregar nuevos usuarios en su inquilino, asignar licencias y restablecer su contraseña.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Puede adquirir licencias y suscripciones adicionales.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Actualiza tu perfil (Dirección de facturación y método de pago)",
      "DASHBOARD_REVENUE_TEXT":"Ingresos",
      "DASHBOARD_SEAT_PURCHASED_TEXT":"Asiento comprado",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Número de sitios",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Sitios",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Sitios",
      "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Iniciar sesión en el portal",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Portal azur",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Iniciar sesión en Portal",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Suscripciones de Azure",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Uso actual de Azure desde {{startDate}}",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Productos",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Suscripciones de licencia",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Ver más",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Oficina 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Iniciar sesión en Portal",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Informes de Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Iniciar sesión en Portal",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Usuarios",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Ver más",
      "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TENDENCIA ESTE MES",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Ha fallado",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"En progreso",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"No recibido",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Recibió",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":" Fecha:",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":" Asientos:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":" Fecha:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":" Productos:",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Última sincronización {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Nueva factura generada el <b>{{InvoiceGeneratedDate}}</b> para el período de facturación del <b>{{BillingStartDate}}</b> al <b>{{BillingEndDate}}</b> con monto <b >{{ImporteFactura}}</b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"para ver la factura. Ignorar si ya pagó.",
      "DASHBOARD_WARNING_MESSAGE":"Debe actualizar su perfil de pago antes de usar C3. Por favor haga clic en el siguiente botón para actualizar.",
      "DATA_PUSHED_DATES":"Algunos o todos los datos se enviaron previamente, verifique los registros anteriores.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Descripción del Artículo",
      "DAYS":" Días)",
      "DEACTIVATE_CONFORMATION":"¿Estás seguro de que deseas desactivarlo?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"No se pudo desactivar el producto del cliente",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Producto de cliente desactivado",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Este usuario no tiene permiso para eliminar esta oferta.",
      "DECEMBER":"Diciembre",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Habilite esta configuración para establecer la cuadrícula/lista como vista predeterminada en la página de productos para los clientes",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Vista predeterminada de productos del cliente",
      "DEFAULT_COTERMINOSITY_TYPE_DESC":"Al seleccionar un valor aquí, se establecerá como predeterminado para la función de coterminosidad durante las compras de suscripción.",
      "DEFAULT_COTERMINOSITY_TYPE_DISP":"Tipo de coterminosidad",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Lista de tipos de contraminiosidad",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Tipo de contraminiosidad",
      "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Eliminar todos los complementos",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al eliminar la conversión de moneda.",
      "DELETE_CUSTOMER_FAILURE":"Error al actualizar el nombre del cliente",
      "DELETE_CUSTOMER_SUCCESS":"El cliente se elimina correctamente.",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Se produjo un error al eliminar los destinatarios de las notificaciones por correo electrónico",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Se eliminará el ajuste de factura seleccionado. ¿Quieres continuar?",
      "DELETE_PARTNER_BUNDLE_TOOLTIP":"Eliminar paquete de producto",
      "DELETE_PARTNER_OFFERS":"Eliminar ofertas de socios",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al eliminar los detalles de impuestos.",
      "DELETE_PARTNER_USER":"Eliminar usuario asociado",
      "DELETE_PARTNER_USER_FAILURE":"No se pudo eliminar el usuario asociado",
      "DELETE_PONUMBER":"Eliminar número de orden de compra",
      "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Eliminar producto",
      "DELETE_QUOTE_SUCCESSFULLY":"Cita eliminada exitosamente",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"¿Está seguro de que desea eliminar este registro?",
      "DELETE_USER_EXCEPTION":"Este usuario tiene cierto acceso a C3, así que elimínelos.",
      "DEPARTMENT":"(Departamento)",
      "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Encabezado principal del banner de registro público",
      "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Encabezado de párrafo de banner de registro público",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Moneda del cliente",
      "DESC_CUSTOMER_DASHBOARD":"Panel de control del cliente",
      "DESC_PARTNER_DASHBOARD":"Panel de socios",
      "DESC_PLAN_CURRENCY_OPTION":"Moneda del plan",
      "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"URL del sitio web para el hipervínculo de administrador en la barra de navegación de registro público",
      "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Encabezado principal en el banner de registro público",
      "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"El párrafo que aparece en el banner de registro público debajo del encabezado del banner.",
      "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"URL del sitio web para el hipervínculo del sitio web en la barra de navegación de registro público",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Contáctenos",
      "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":" URL del sitio web para el hipervínculo del Portal de aprendizaje en la barra de navegación de registro público",
      "DESC_PUBLIC_SIGNUP_SUPPORT":"URL del sitio web para el hipervínculo de soporte en la barra de navegación de registro público",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Términos y condiciones de registro público 1",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Términos y condiciones de registro público 2",
      "DESC_SHOW_INVOICES_TO_CUSTOMER":"Habilite esta opción para mostrar facturas al cliente",
      "Description":"Descripción",
      "DESCRIPTION_FOR":"Descripción para",
      "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Desactivar producto",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Modelo seguro antiguo deshabilitado y nuevo habilitado",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Gracias por la confirmación. La aplicación accederá a su Centro de socios siguiendo las nuevas pautas de seguridad.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Error al deshabilitar el modelo seguro antiguo y habilitar el nuevo",
      "DISCOUNT_MAX_ERROR":"El valor debe ser menor o igual a 100",
      "DISCOUNT_MIN_ERROR":"El valor debe ser mayor o igual a 0",
      "DISP_CATEGORY_COUPON_ENTITY":"Categoría",
      "DISP_PLANOFFER_COUPON_ENTITY":"Oferta de plan",
      "DISP_PROVIDER_COUPON_ENTITY":"Proveedor",
      "DISP_SALETYPE_COUPON_ENTITY":"Tipo de venta",
      "DISP_SHOW_INVOICES_TO_CUSTOMER":"Mostrar facturas al cliente",
      "DisplayCurrency":"Mostrar moneda",
      "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Duración del término",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Reiniciar",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Introduzca un valor positivo",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
      "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
      "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Nueva oferta de distribuidor",
      "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Volver a la oferta del distribuidor",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ofertas de distribuidores",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Categoría",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Ofertas de distribuidores",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Acción",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"¿Es autorenovable?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Tipos de facturación",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Categoría",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Tipo de período de facturación",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Precio de coste",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Precio de costo (desde)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Precio de costo (a)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Descripción",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Nombre",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Precio de coste antiguo",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Precio de venta antiguo",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icono",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Proveedor",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Precio de venta",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Precio de venta (desde)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Precio de venta (a)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Término",
      "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Oferta de distribuidor {{distributorOffer}} guardada correctamente",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"nombre",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Oferta de distribuidor {{distributorOffer}} actualizada correctamente",
      "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Eliminar administrador de cuenta con éxito",
      "DOCUMETATION_HEADING":"Documentación",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Código de moneda",
      "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Entidad",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Cantidad facturada",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"nombre calificado",
      "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Descargar progreso y enlace",
      "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Descargar facturas",
      "DOWNLOAD_COLUMN_BUNDLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Regla de facturación para la compra de asientos",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Normas de facturación para la reducción de plazas",
      "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Precio de costo",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Tipo de venta",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Descripción",
      "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"¿Es autorenovable?",
      "DOWNLOAD_COLUMN_BUNDLE_NAME":"Nombre del paquete",
      "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Nombre del producto",
      "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Precio de venta",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Estado",
      "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Mercado soportado",
      "DOWNLOAD_COLUMN_BUNDLE_TERM":"Término",
      "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Informe de productos en paquete",
      "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Nombre del cupón activo",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Aplicabilidad",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Asignado el",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Código promocional",
      "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Descripción del cupón",
      "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Nombre del cliente",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Descuento",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Expira el",
      "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"Pago automático",
      "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"ID de referencia de facturación",
      "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Código de mercado",
      "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Código de moneda",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Nombre de dominio",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Nombre",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Método de pago",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
      "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Es oferta de prueba",
      "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Fecha de registro",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Etiquetas",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Identificación del inquilino",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Nombre del inquilino",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Inquilinos",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Es oferta de prueba",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Número de factura del proveedor",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Número de referencia de facturación",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Fecha de finalización del cargo",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Fecha de inicio del cargo",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Tasa de conversión",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Costo en socio",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Código de moneda",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"Identificación del cliente de ERP",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Importe de descuento",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Nombre de dominio",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"ID de oferta duradera",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Precio unitario efectivo por MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Fecha de la factura",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Notas de factura",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Número de factura",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Detalle de línea de factura",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Código del objeto",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Nombre",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Nombre de la oferta",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"numero postal",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Importe después del impuesto",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Monto antes de impuestos",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Identificación de la promoción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Costo unitario prorrateado",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Proveedor",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Cantidad comprada",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Fecha de finalización efectiva de la suscripción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Suscripción efectiva",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Identificación de suscripción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Plazo de suscripción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Tipo de suscripción",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Número de suscripción del socio de distribución",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Importe del impuesto",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Código de impuestos",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Identificación del inquilino",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Nombre del inquilino",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Cantidad total",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Monto total para socio por MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Costo unitario por producto",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Costo unitario del socio",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Precio unitario",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Precio unitario por MS",
      "DOWNLOAD_COLUMN_MARKET_REGION":"Mercados admitidos",
      "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Es oferta de prueba",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Disponible para agrupar",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Disponible para consumo inmediato.",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Norma de facturación para la compra de asientos",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Norma de facturación para la reducción de plazas",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Tipo de facturación",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Tipo de consumo",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Precio de coste",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Descripción",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Nombre",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Precio de venta",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Tipo de venta",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Estado",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Término",
      "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Código de moneda",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Descripción",
      "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Moneda de la factura",
      "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"es público",
      "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Detalles de macros",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Valor macro",
      "DOWNLOAD_COLUMN_PLAN_NAME":"Nombre",
      "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Disponible para el cliente",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Categoría de ciclo de facturación",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Tipo de facturación",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Categoría",
      "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Costo para el socio",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Código de moneda",
      "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Eficacia",
      "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Nombre de la oferta amistosa",
      "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"El último cambio de precio ocurre el",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Nombre de la macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Valor macro",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Nombre del plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"ID de oferta",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Nombre de la oferta",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Nombre del plan",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Proveedor",
      "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Precio al por menor",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Precio de venta",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Tipo de servicio",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Estado",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Mercado soportado",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Término",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Duración del mandato",
      "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Moneda de compra",
      "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"Compartir URL",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Está disponible para compra",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Es oferta de prueba",
      "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Disponible para liberación automática",
      "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Categoría",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Tipo de consumo",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Nombre de Departamento",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Descuento",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Nombre de dominio",
      "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Producto Precio Efectivo",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Nombre del producto",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Secuencia de productos",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Nombre de categoría de proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Fecha de creación del proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Fecha de finalización del proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Fecha de inicio del proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"ID de producto del proveedor",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Cantidad comprada",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Nombre del sitio",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Estado",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"ID de inquilino",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Nombre del inquilino",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Precio unitario",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Categoría",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Costo para el socio",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Código de moneda",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Es oferta de prueba",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Identificación de producto",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Nombre del producto",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Categoría de proveedor",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Nombre del proveedor",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Región",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Precio al por menor",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Informe del catálogo de productos.",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Validez",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Tipo de validez",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Ciclo de facturación",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Nombre de la categoría",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Creado por",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Fecha de Creación",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Código de moneda",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Nombre del cliente",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Nombre de dominio",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Entidad",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"es complemento",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Nombre de la entidad",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Nuevo precio",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Nueva cantidad",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Nuevo estado",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Precio anterior",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Cantidad antigua",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"antiguo estado",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Nombre del producto",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Proveedor",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Categoría de proveedor",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Revendedor nuevo precio",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Precio anterior del revendedor",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Identificación de suscripción",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"identificación del inquilino",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"tipo de cambio",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Validez",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Tipo de validez",
      "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Seleccione al menos una columna",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Descargar",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Descargar Cupones",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Descargar Clientes",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Descargar Productos",
      "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Descargar Planes",
      "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Descargar Ofertas de Planes",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Partidas de factura",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Pagos de facturas",
      "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Seleccione al menos una columna",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"Descargar CSV",
      "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Descargar clientes no incorporados",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "DOWNLOAD_POPUP_GRID_HEADER":"Descargar informe de cuadrícula",
      "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"No hay datos disponibles para descargar CSV",
      "DOWNLOAD_PRODUCTS_GRID":"Descargar productos",
      "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Descargar software y claves",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Agregar múltiples",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Enlace múltiple",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"enlace único",
      "DROPDOWN_PLACEHOLDER_SELECT":"Seleccione",
      "DUE_STATUS_DUE":"Pendiente",
      "DUE_STATUS_FINALIZED":"Finalizado",
      "DUE_STATUS_OVERDUE":"Atrasado",
      "DUE_STATUS_PAID":"Pagado",
      "DUE_STATUS_PARTIAL_PAID":"Parcialmente pagado",
      "earningsreport":"Informe de ganancias",
      "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Editar información de facturación",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Editar grupo",
      "EDIT_INVOICE_BUTTON_TEXT":"Editar factura",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Producto",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Resumen",
      "EDIT_PONUMBER":"Editar número de orden de compra",
      "EffectivenessTypeId":"Identificación del tipo de eficacia",
      "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Proporcione una imagen con una resolución máxima de 300 x 100 para el logotipo de correo electrónico",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"La dirección de correo electrónico especificada como contacto de facturación en el perfil del cliente.",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Lista de direcciones de correo electrónico de las personas a las que se debe notificar por correo electrónico",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Funciones distintas a la de HelpDesk",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"Contactos de facturación",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Gente",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Role",
      "EMAIL_TABLE_HEADER_ACTIONS":"Comportamiento",
      "EMAIL_TABLE_HEADER_EMAIL":"Correo electrónico",
      "EMAIL_TABLE_HEADER_EMAIL_TYPE":"Tipo de correo electrónico",
      "EMAIL_TEMPLATE":"Avance",
      "EMAIL_TEMPLATE_LABEL":"Plantillas",
      "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Seleccionar plantilla",
      "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"Plantilla de correo electrónico actualizada correctamente",
      "EMAIL_TEMPLATE_SUBMIT":"Entregar",
      "EMAIL_TYPE_BILLING":"Facturación",
      "EMAIL_TYPE_COMPANY":"Compañía",
      "EMAIL_TYPE_INVOICE":"Factura",
      "EMAIL_TYPE_PERSONAL":"Personal",
      "EMAIL_TYPE_SHIPPING":"Envío",
      "EmailConfigurations":"SMTP",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"La configuración está diseñada para habilitar la fijación de precios por nivel de derechos. Una vez activada, generaremos facturas en función de los niveles de derechos, independientemente del tipo de factura. Confirme que esto es aceptable para usted.",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Habilitar precios a nivel de derechos",
      "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Habilitar producto",
      "ENABLE_RESELLER_PLAN":"Asignar plan al revendedor",
      "ENABLE_RESELLER_PLAN_FAILURE":"No se pudo asignar el plan al revendedor",
      "END_BILLING_PERIOD":"Finalizar período de facturación",
      "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"La fecha de finalización debe ser posterior a la fecha de inicio",
      "END_DATE_REQUIRED_MESSAGE":"La fecha de finalización es obligatoria",
      "ENGAGE_BREADCRUMB_ADD":"Crear compromiso",
      "ENGAGE_BREADCRUMB_LIST":"Lista de participación",
      "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Color del botón de llamada a la acción",
      "ENGAGE_BUTTON_TEXT_COLOR":"Color del texto del botón",
      "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Color del texto del botón",
      "ENGAGE_BUTTON_URL_PLACEHOLDER":"Hipervínculo del botón de llamada a la acción",
      "ENGAGE_CTA_BUTTON_COLOR":"Color del botón CTA",
      "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Texto del botón de llamada a la acción",
      "ENGAGE_CUSTOMER_PAGENAME":"Panel de clientes",
      "ENGAGE_EFFECTIVE_FROM":"Válido desde",
      "ENGAGE_EXPIRES_ON":"Expira el",
      "ENGAGE_HEADER_ACTIONS":"Comportamiento",
      "ENGAGE_HEADER_ACTIONS_ADD":"Crear compromiso",
      "ENGAGE_HEADER_ACTIONS_BACK":"volver a la lista",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"Válido desde",
      "ENGAGE_HEADER_ENTITES":"Entidades",
      "ENGAGE_HEADER_EXPIRES_ON":"Expira el",
      "ENGAGE_HEADER_MESSAGE":"Mensaje",
      "ENGAGE_HEADER_PAGE":"Página",
      "ENGAGE_HEADER_TITLE":"Título",
      "ENGAGE_NOTIFICATION_BACKGROUND":"Fondo",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Ofrecer descuentos para su tienda en línea puede ser un arma poderosa para generar lealtad.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Impulsar el marketing y confianza del producto de venta.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Ofrecer descuentos para mejorar en línea una tienda puede fidelizar armas en la conducción.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Comience con un diseño de sitio moderno y personalícelo con su contenido de marca y características. Todos los blogs Premium incluyen CSS personalizado.",
      "ENGAGE_NOTIFICATION_BODY_TEXT":"Cuerpo de texto",
      "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"Notificación de participación creada con éxito",
      "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Esto excede la longitud mínima de 30",
      "ENGAGE_NOTIFICATION_PORTAL_PAGE":"página del portal",
      "ENGAGE_NOTIFICATION_TITLE_TEXT":"Texto del título",
      "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"Notificación de participación actualizada con éxito",
      "ENGAGE_PARTNER_PAGENAME":"Panel de socios",
      "ENGAGE_POPUP_BUTTON_URL":"URL del botón",
      "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Texto del botón CTA",
      "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Seleccionar todos los clientes",
      "ENGAGE_SUBMIT":"Entregar",
      "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":" El texto excede la longitud máxima de 15",
      "ENGAGE_THE_MAX_LENGTH":"La longitud máxima es de 250",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Seleccione al menos una factura para publicar en QuickBooks.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Su solicitud para publicar facturas en QuickBooks se acepta y se pone en cola para su procesamiento. Haga clic en el botón <strong>Ver registros de publicaciones</strong> para obtener actualizaciones sobre el progreso.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"No se pudo poner en cola la solicitud para cargar facturas en QuickBooks en línea",
      "ENQUEUED_REQUEST_TO_CLONE_PLAN":"Solicitud en cola para clonar plan",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Solicitud en cola para crear un nuevo plan con todas las ofertas",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Solicitud en cola para crear un nuevo plan de revendedor con todas las ofertas",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"No se pudo poner en cola la solicitud para cargar facturas en Xero",
      "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":" Ingrese un correo electrónico después de la coma o elimine la coma adicional",
      "ENTER_AZURE_NEW_MARKUP_VALUE":"Introduzca un nuevo valor de marcado",
      "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Ingrese el motivo del nuevo valor de marcado",
      "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Ingrese el motivo para actualizar el valor de marcado",
      "enterAddress1":"Introducir dirección 1",
      "enterAddress2":"Introducir dirección 2",
      "enterCity":"Introducir ciudad",
      "enterEmail":"Introducir correo electrónico",
      "enterPhoneNumber":"Introduzca el número de teléfono",
      "enterState":"Ingresar estado",
      "enterZip":"Ingresar código postal",
      "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Número de derechos que se crearán, sin exceder {{Count}}",
      "ENTITLEMENT_MANAGEMENT":"Administración de derechos de Azure",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":" El número de derechos a crear no puede exceder",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Los nombres de derechos comenzarán con esto.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"INSTRUCCIONES",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Cuando se anula el precio del derecho, este precio se aplicará tanto a los cálculos de factura como a las estimaciones de Azure.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Nota:</b>",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Utilice el botón <b>Anular</b> para modificar el precio del derecho.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Utilice el botón <b>Revertir</b> para restablecer el precio al precio predeterminado del plan de Azure para el derecho.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Utilice <b>Exportar</b> para descargar datos de derechos para un nivel específico de cliente e inquilino.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Recupere datos de derechos de todos los clientes activos a la vez seleccionando <b>Exportar todo</b>.",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Acción",
      "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Agregar derecho",
      "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Agregar derechos",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Completo",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Fecha de creación",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Estado de creación",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Departamento",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Nombre de Departamento",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Número de derechos",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"IDENTIFICACIÓN",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Lista de derechos",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Nombre",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Establecer nuevo precio para el derecho",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Exportar",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Exportar todo",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Exportar",
      "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Este precio es aplicable a partir del momento en que se genera la siguiente factura y continúa a partir de entonces.",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Precio de venta",
      "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Secuencia de inicio",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Sitio",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Nombre del sitio",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":" Proceso completo. Consulte el registro de auditoría para obtener más detalles.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Se acepta la solicitud para agregar derechos y se está procesando",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La solicitud para agregar derechos falló.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Proporcione una secuencia numérica",
      "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"¿Está seguro de que desea revertir el precio de venta de este derecho?",
      "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"El precio del derecho se modificó correctamente.",
      "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"El precio del derecho se revirtió exitosamente.",
      "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"El incremento secuencial se utilizará como sufijo en los nombres de derechos.",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Estado de creación de derechos",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Espere mientras se crean los derechos.",
      "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"El valor debe ser mayor o igual a -100 y menor o igual a 100.",
      "ENTITY_DESC_AZURE_GROUP":"grupo azul",
      "ENTITY_DESC_BILLING_PERIODS":"Periodos de facturación",
      "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor de facturación",
      "ENTITY_DESC_BILLING_PROVIDER_USER":"Usuario del proveedor de facturación",
      "ENTITY_DESC_CART":"Carro",
      "ENTITY_DESC_CART_LINE_ITEM":"Línea de pedido del carrito",
      "ENTITY_DESC_COUPON":"Cupón",
      "ENTITY_DESC_CURRENCY":"Divisa",
      "ENTITY_DESC_CUSTOM_OFFER":"oferta personalizada",
      "ENTITY_DESC_CUSTOMER":"Cliente",
      "ENTITY_DESC_CUSTOMER_PRODUCT":"Producto del cliente",
      "ENTITY_DESC_CUSTOMER_USER":"Usuario cliente",
      "ENTITY_DESC_DEPARTMENT":"Departamento",
      "ENTITY_DESC_INVOICE":"Factura",
      "ENTITY_DESC_JOB_LOGS":"Registros de trabajo",
      "ENTITY_DESC_JOBS":"Trabajos",
      "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Cliente de tienda local",
      "ENTITY_DESC_LOCAL_STORE_RESELLER":"Revendedor de tienda local",
      "ENTITY_DESC_PARTNER":"Compañero",
      "ENTITY_DESC_PARTNER_SETTINGS":"Configuración de socios",
      "ENTITY_DESC_PARTNER_USER":"Usuario socio",
      "ENTITY_DESC_PAYMENT_PROFILE":"Perfil de pago",
      "ENTITY_DESC_PLAN":"Plan",
      "ENTITY_DESC_PLAN_PRODUCT":"Planificar producto",
      "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Planificar bono de producto",
      "ENTITY_DESC_PRODUCT":"Producto",
      "ENTITY_DESC_PRODUCT_VARIANT":"Variante de producto",
      "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Vale de variante de producto",
      "ENTITY_DESC_PROVIDER_CUSTOMER":"Cliente proveedor",
      "ENTITY_DESC_RESELLER":"Revendedores",
      "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Perfil de pago de revendedor",
      "ENTITY_DESC_RESELLER_PLAN":"plan revendedor",
      "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Cliente proveedor de servicios",
      "ENTITY_DESC_SHOP":" Comercio",
      "ENTITY_DESC_SITE":"Sitio",
      "ENTITY_DESC_SITE_DEPARTMENT":"departamento de sitio",
      "ENTITY_DESC_TAG":"Etiqueta",
      "ENTITY_DESC_TAX":"Impuesto",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Términos y condiciones",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Actualizar la configuración del proveedor",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Error al actualizar la configuración del proveedor",
      "ENTITY_MAPPING":"Mapeo de entidades",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"¿Está seguro de que desea liberar la asignación?",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación para <b> {{customerName}}</b>?",
      "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Al menos mapa 1 producto",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Estos contratos ya no son predeterminados: {{ContractNames}}",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"No se permite el número de secuencia duplicado",
      "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Asignaciones guardadas con éxito",
      "ENTITY_NAME":"Nombre de la entidad",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"País",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Correo electrónico",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"Estado",
      "ERROR__DATA_CONTACT_TYPE":"Tipo de Contacto",
      "ERROR__DATA_PAYMENT_TYPE":"Tipo de pago",
      "ERROR__DATA_PROFILE_TYPE":"Tipo de perfil",
      "ERROR__DATA_URL":"URL",
      "ERROR_AVAILABLE_IN_BATCH":"El error está disponible en el lote.",
      "ERROR_BILLING_PROVIDER_NAME":"Nombre del proveedor de facturación",
      "ERROR_BUTTON_CONTINUE":"Continuar",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Identificación del producto del cliente",
      "ERROR_C3_ENTITY_NAME":"Nombre de la entidad",
      "ERROR_C3_RECORD_ID":"Identificación de registro",
      "ERROR_C3_USER_ID":"ID de usuario",
      "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Cantidad de carrito no válida",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"ID de cliente de facturación",
      "ERROR_DATA_BILLING_REFERENCE_ID":"ID de referencia de facturación",
      "ERROR_DATA_C3_CUSTOMER_ID":"Identificación del cliente C3",
      "ERROR_DATA_C3_RECORD_ID":"Identificación de registro",
      "ERROR_DATA_C3_RESELLER_ID":"ID del revendedor C3",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"Identificación de suscripción C3",
      "ERROR_DATA_COUNTRY_CODE":"Código de país",
      "ERROR_DATA_CUSTOMER_NAME":"Nombre del cliente",
      "ERROR_DATA_DOMAIN_PREFIX":"Nombre de dominio",
      "ERROR_DATA_EMAIL_ID":"Identificación de correo",
      "ERROR_DATA_ENTITY_NAME":"Nombre de la entidad",
      "ERROR_DATA_EVENT_NAME":"Nombre del evento",
      "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Tiene soporte para revendedores con ID de MPN",
      "ERROR_DATA_INPUT_JSON":"Datos de entrada",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
      "ERROR_DATA_PROVIDER_NAME":"Nombre del proveedor",
      "ERROR_DATA_RESELLER_ID":"Identificación de referencia",
      "ERROR_DATA_RESELLER_MPNID":"ID del proveedor revendedor",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"ID del proveedor revendedor",
      "ERROR_DATA_SEARCH_VALUE":"Valor de búsqueda",
      "ERROR_DATA_SUBSCRIPTION_ID":"Identificación de suscripción",
      "ERROR_DATA_TAX_CODE":"Código de impuestos",
      "ERROR_DATA_TRANSACTION_REFERENCE":"Referencia de transacción",
      "ERROR_DATA_USER_ID":"Identificación de usuario",
      "ERROR_DELETE_DEPARTMENT":"Este departamento contiene registros asociados por lo que no se puede eliminar este registro.",
      "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Este departamento contiene productos comprados asociados por lo que no se puede eliminar este registro.",
      "ERROR_DELETE_SITE":"Este sitio contiene registros asociados, por lo que no se puede eliminar este registro.",
      "ERROR_DELETE_SITE_PRODUCT":"Este sitio contiene productos comprados asociados, por lo que no se puede eliminar este registro.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"No se puede crear un inquilino en el proveedor.",
      "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"El nombre del agente no puede ser nulo",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"No hay respuesta de Authorize.net",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"No se puede encontrar al cliente.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"No se puede encontrar este producto entre sus suscripciones",
      "ERROR_DESC_BAD_INPUT":"Parece que hay algún problema con tu entrada.",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una mala entrada.",
      "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Se produjo un error al guardar los detalles de la notificación personalizada",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Se produjo un error al descargar el informe de consumo de licencia; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"La recuperación del cliente falló debido a una entrada incorrecta.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"La obtención de perfiles de pago falló debido a una mala entrada.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Error al obtener el estado de pago debido a una entrada incorrecta.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Ocurrió un error al obtener el informe de consumo de licencias, comuníquese con soporte.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"La acción falló porque falta el perfil de pago.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"La acción falló porque falta la configuración del perfil de pago",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Se produjo un error al guardar su perfil de pago; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Parece que hay algún problema con sus perfiles de pago; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Parece que hay algún problema con su proveedor de facturación; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Hay algún problema al guardar su perfil de pago; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Ocurrió un error al obtener el informe de consumo de licencias, comuníquese con soporte.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"No se pudo encontrar el proveedor",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"El servicio no está implementado. Por favor contacte al soporte.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Parece haber algún problema con los tipos de pago admitidos por el proveedor de facturación; comuníquese con el soporte.",
      "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Se produjo un error al guardar los detalles de notificación del webhook",
      "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Solicitud incorrecta: hay otro trabajo en curso",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Se produjo un error al crear/actualizar el cliente de facturación; comuníquese con el soporte.",
      "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Los revendedores integrados masivos no admitidos tienen un socio que no admite revendedores con ID de MPN",
      "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"El carrito tiene elementos de línea con errores. Corrígelos para continuar con esta compra.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Esta suscripción ya utilizada por este cliente",
      "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Ocurrió un error al cambiar el plan del cliente, comuníquese con soporte.",
      "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Se produjo un error al obtener los planes de origen para la función de cambio de plan, comuníquese con soporte.",
      "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Se produjo un error al obtener los planes de destino para la función de cambio de plan, comuníquese con soporte.",
      "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"La fecha de finalización de la comisión no puede ser inferior a la fecha de inicio de la comisión",
      "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"La fecha de finalización de la comisión no puede ser nula",
      "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"El porcentaje de comisión no puede ser nulo",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Error de ConnectWise Manage al intentar eliminar la adición por identificación",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Error de ConnectWise Manage al intentar obtener una adición por ID y ID de acuerdo",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Error de ConnectWise Manage al intentar obtener adiciones por ID de acuerdo",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener detalles del acuerdo por ID",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Error de ConnectWise Manage al intentar obtener acuerdos",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener detalles del acuerdo por ID",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Error de ConnectWise Manage al intentar obtener acuerdos",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Error de ConnectWise Manage al intentar obtener un elemento del catálogo por ID",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Error de ConnectWise Manage al intentar obtener artículos del catálogo",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener los detalles del cliente por ID",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Error de ConnectWise Manage al intentar conseguir clientes",
      "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Entradas duplicadas en pagos importados",
      "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entradas duplicadas en el catálogo",
      "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Entradas duplicadas en el catálogo",
      "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"Existe una entrada duplicada en la entrada",
      "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Hay errores en el formulario.",
      "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Se produjo un error al iniciar la referencia de datos de la entidad desde un servicio externo",
      "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId no es válido. registre su aplicación como aplicación nativa en https://dev.powerbi.com/apps y póngase en contacto con el soporte.",
      "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"GroupId no es válido, comuníquese con el equipo de soporte.",
      "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"La contraseña no es válida, comuníquese con el equipo de soporte.",
      "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"El informe no es válido, comuníquese con el equipo de soporte.",
      "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"El nombre de usuario no es válido, comuníquese con el equipo de soporte.",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Se produjo un error al eliminar al cliente. Por favor contacte al soporte.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Se produjo un error al cargar tu imagen. Verifique las dimensiones de la imagen una vez más.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Error debido a una mala solicitud. Inténtalo de nuevo.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Falló debido a un conflicto. Por favor vuelva a verificar sus credenciales",
      "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Tiempo de espera de la puerta de enlace. Inténtalo de nuevo.",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Servicio No Disponible. Por favor revisa la configuración",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Se produjo un error al iniciar el pago, comuníquese con el soporte.",
      "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Ocurrió un error al descifrar el valor",
      "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Ocurrió un error al cifrar el valor",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"El ID de la aplicación AD del proveedor del panel de control o el ID del inquilino del socio no existen.",
      "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"El ID de aplicación de AD de API de precios de Microsoft o el ID de inquilino de socio no existen.",
      "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Se produjo un error al intentar importar varios pagos",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Ocurrió un error al intentar importar la entrada del catálogo",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Ocurrió un error al intentar importar Comisiones de productos",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Error al importar los detalles de la extensión del producto",
      "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"No se pudo incorporar al revendedor con la incorporación masiva debido a errores de validación",
      "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Ocurrió un error al realizar la solicitud de registro",
      "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"No se pudo enviar el correo electrónico a la lista de usuarios dada. Revisar sus configuraciones de SMTP puede ayudar a resolver este problema.",
      "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Se produjo un error al intentar importar la entrada del registro de pago",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Ocurrió un error al preparar la entrada del catálogo de productos",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Ocurrió un error al preparar los detalles de las comisiones del producto",
      "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"No se pudo sincronizar la suscripción de instancias reservadas debido a la falta de disponibilidad del producto. Por favor contacte al equipo de soporte.",
      "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"No puede actualizar el porcentaje decimal a un valor del porcentaje disponible.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"No se pudo cargar la imagen. Verifique su conectividad a Internet y asegúrese de que el tamaño de la imagen coincida con las especificaciones proporcionadas.",
      "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Se produjo un error al obtener el estado del lote a bordo de los revendedores de proveedores de forma masiva",
      "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Se produjo un error al verificar el estado de los datos de la entidad remitidos desde un servicio externo",
      "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Ocurrió un error al recuperar los pagos pendientes. Póngase en contacto con el soporte.",
      "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id faltante o no válido en la entrada",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El costo para el socio debe ser + cinco números de Entrada",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El costo para el socio debe ser decimal de Entrada",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Código de moneda faltante o no válido en la entrada",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Código de moneda faltante o no válido",
      "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El código de moneda debe coincidir con la moneda del producto de Entrada",
      "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Código de moneda no válido en la entrada",
      "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ID de cliente faltante o no válido en la entrada",
      "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"No puede cargar el uso para este GUID (cliente)",
      "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto interno no válido en el catálogo",
      "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Secuencia no válida en el catálogo",
      "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"El código de moneda debe coincidir con la entidad relevante",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Entradas no válidas en el catálogo",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entradas no válidas en el catálogo",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Entradas no válidas en el catálogo",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Monto de pago faltante o no válido",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"El monto del pago debe ser positivo.",
      "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Formato no válido de fecha de pago.",
      "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Fecha de pago no válida",
      "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ID de producto faltante o no válido en la entrada",
      "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"No puede cargar el uso del producto porque el producto no existe o no es de su propiedad.",
      "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto de proveedor no válido en el catálogo",
      "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La unidad de medida no debe ser un número entero/decimal de la entrada",
      "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Las unidades deben ser números enteros de Entrada",
      "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Las unidades deben ser + cinco números de Entrada",
      "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe tener el formato AAAA-MM-DD de Entrada",
      "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe ser el tipo de fecha de Entrada",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe ser posterior a la fecha de entrada",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"La fecha de pago debe ser pasada.",
      "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"No se genera factura por los productos comprados",
      "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"El artículo no se puede comprar",
      "ERROR_DESC_MATCHING_PLAN_OFFER":"Ocurrió un error al hacer coincidir las ofertas del plan para cambiar el plan, comuníquese con soporte.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"El cliente se creó correctamente, pero no pudo asignar los grupos de seguridad a la solicitud GDAP. Complete esto en el Centro de socios; de lo contrario, fallarán otras acciones.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Algo salió mal con la solicitud GDAP. Por favor, trabaje con su socio.",
      "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Discrepancia entre ProviderResellerId especificado y ResellerId en el proveedor",
      "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"Nombre de agente faltante en el catálogo",
      "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"Falta C3Id en la entrada",
      "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta la fecha de finalización de la comisión en el catálogo",
      "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Porcentaje de comisión faltante en el catálogo",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta CostToPartner en la entrada",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner es obligatorio para el porcentaje del tipo de facturación",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta el código de moneda en la entrada",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Falta código de moneda en la entrada",
      "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta CustomerId en la entrada",
      "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta el ID de producto interno en el catálogo",
      "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"Falta OfferId en el catálogo",
      "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Ciclo de facturación faltante o no válido en el catálogo",
      "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Categoría faltante o no válida en el catálogo",
      "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Proveedor faltante o no válido en el catálogo",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
      "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Monto de pago faltante o no válido en la entrada",
      "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Falta fecha de pago en Entrada",
      "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta ProductId en la entrada",
      "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto de proveedor faltante en el catálogo",
      "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta el código SP en el catálogo",
      "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unidad de medida faltante en la entrada",
      "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unidades faltantes en la entrada",
      "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fecha de uso faltante en la entrada",
      "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"No se pudo conectar la API de gestión de Microsoft Azure.",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Error del centro de socios de Microsoft al actualizar el nombre del producto del cliente",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Se produjo una excepción en el Centro de socios al intentar cambiar el estado de la suscripción. Por favor contacte al soporte.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Error del centro de socios al verificar MPNID. Por favor revise su entrada nuevamente.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Estamos enfrentando un problema al conectarnos al Centro de socios. Deje que el administrador actualice el token y vuelva a intentarlo. Póngase en contacto con el soporte para obtener más ayuda.",
      "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Se produjo un error al asignar grupos de seguridad al acceso de relación de administrador delegado del cliente",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Error del centro de socios al crear usuario",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Error del centro de socios al eliminar usuario",
      "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Se produjo un error al obtener los grupos de seguridad del agente administrativo de Microsoft",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Error del Centro de socios al intentar obtener todos los clientes",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Error del Centro de socios de Microsoft al intentar obtener todos los revendedores",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Error del Centro de socios al intentar obtener reglas de validación de país",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Error del Centro de socios al intentar conseguir clientes",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Se produjo un error al obtener los detalles de la relación de administrador delegado del cliente del proveedor del centro de socios",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS Partner Center arrojó un error al intentar obtener su perfil.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"No se pudo recuperar el token de actualización.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Error del centro de socios al obtener el usuario inquilino",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Error del centro de socios al obtener licencias asignadas al usuario",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Error del centro de socios al obtener detalles del usuario",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Error del centro de socios al obtener roles de usuario",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Error del centro de socios al obtener usuarios",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Error del Centro de socios al registrar el consentimiento del cliente",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Error del centro de socios al actualizar el usuario",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Error del centro de socios al actualizar las asignaciones de licencias de usuario",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Error del centro de socios al actualizar la contraseña del usuario",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Error del centro de socios al obtener clientes.",
      "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"No se pudo obtener el acceso de la API de precios de Microsoft",
      "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Por favor revise de nuevo. Falta el archivo en la solicitud de validación de datos",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"No hay datos disponibles para preparar para Autotask",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"No hay datos disponibles para organizar ConnectWise Manage",
      "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"No se encontraron detalles de revendedores de proveedores en el centro de socios de Microsoft",
      "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"No se encuentra ningún revendedor con ID de MPN en C3",
      "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"El socio no es elegible para comprar el artículo",
      "ERROR_DESC_NOT_FOUND_ERROR":"No se puede encontrar la solicitud",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Ha ocurrido un error al procesar su solicitud",
      "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Se produjo un error al programar el pedido.",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"La incorporación falló debido a un inquilino no válido.",
      "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"La oferta para padres no está disponible para esta oferta de prueba.",
      "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Falta el perfil de pago. Proporcione el perfil de pago y vuelva a intentarlo.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una excepción del proveedor.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestión del perfil de pago falló debido a una excepción del proveedor.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"La eliminación del usuario falló debido a un error en el portal del proveedor de pagos.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"No se puede encontrar un cliente con identificación",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Error al obtener perfiles de pago debido a una excepción del proveedor.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"No se pudo obtener el estado de pago debido a una excepción del proveedor.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Se produjo un error al administrar la facturación al cliente. Por favor contacte al soporte.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Se produjo un error al administrar el perfil de pago. Por favor contacte al soporte.",
      "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Ocurrió un error al actualizar el estado del pago. Póngase en contacto con el soporte.",
      "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Mapeo de producto con cliente equivocado",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de inquilinos y categorías no admite productos ya asignados",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de categoría no admite productos ya asignados",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de inquilinos no admite productos ya asignados",
      "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso cae en el período de suspensión del producto",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Proveedor BusinessId ya existe en el sistema",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"Proveedor BusinessId no puede ser nulo",
      "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"El correo electrónico de facturación no es válido",
      "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"El país del revendedor del proveedor no puede ser nulo",
      "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Provider ResellerId ya existe en el sistema",
      "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"Provider ResellerId no puede ser nulo",
      "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"El nombre del revendedor del proveedor no puede ser nulo",
      "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Detalles del revendedor del proveedor no vinculados a c3, comuníquese con su socio o equipo de soporte",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Se produjo un error al analizar el archivo cargado",
      "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"La longitud de los caracteres de los comentarios debe ser inferior a 200.",
      "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Por favor revise de nuevo. Falta el archivo en la solicitud de validación de datos",
      "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"El revendedor ya existe en el sistema",
      "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Seleccione al menos un producto",
      "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"El pago falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"La recuperación del pago pendiente falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"La configuración del proveedor de facturación falló debido a una excepción de la base de datos.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"La asignación del cupón falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"La gestión de cupones falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una excepción de la base de datos.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestión del perfil de pago falló debido a una excepción de la base de datos.",
      "ERROR_DESC_SQL_DELETE_CUSTOMER":"La eliminación del cliente falló debido a un error en la base de datos.",
      "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al agregar ofertas al plan. Póngase en contacto con soporte",
      "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al clonar el plan. Póngase en contacto con soporte",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al intentar realizar la solicitud del plan de clonación. Póngase en contacto con soporte",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Lamentamos informarle que se produjo una excepción en la base de datos al intentar realizar una solicitud para crear un nuevo plan con todas las ofertas. Póngase en contacto con soporte",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Error de la base de datos al guardar la identificación de MPN",
      "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
      "ERROR_DESC_SQL_GET_CUSTOMER":"La obtención del cliente falló debido a una excepción de la base de datos.",
      "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"La obtención de los perfiles de pago falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"La obtención del estado de pago falló debido a una excepción en la base de datos.",
      "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Error de la base de datos al obtener clientes.",
      "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"El número deseado de suscripciones 6 superó el número máximo de suscripciones permitidas por cliente 5",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"No tienes permiso para cambiar el estado del producto ya que la propiedad se comparte con entidades operativas.",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"La actualización del estado del producto no fue completamente exitosa; comuníquese con nuestro equipo de soporte para obtener ayuda.",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"El código impositivo ya existe. Seleccione un código impositivo único.",
      "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"La configuración SMTP proporcionada es incorrecta",
      "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"No podemos procesar esta solicitud porque las transacciones están deshabilitadas.",
      "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"La oferta de prueba no se puede comprar con otras categorías.",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"No se puede procesar su solicitud, comuníquese con el soporte",
      "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"No se puede procesar este pedido",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"No estás autorizado a acceder a esta función",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"La ubicación de uso no es válida",
      "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Ocurrió un error al validar el plan del cliente, comuníquese con soporte.",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Error al acceder a la base de datos. Inténtelo de nuevo después de un tiempo. Si el error aún persiste, comuníquese con el soporte para obtener más ayuda.",
      "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Ocurrió un error al agregar el derecho",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Ocurrió un error al agregar el inquilino",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Se produjo un error al proporcionar el consentimiento para el inquilino",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Se creó el arrendatario, pero se produjo un error al agregar la suscripción",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Ocurrió un error al asignar el rol",
      "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Se produjo un error al cancelar la renovación programada",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"El ID de catálogo proporcionado no es válido",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Se produjo un error al verificar la elegibilidad de la promoción de Microsoft NCE",
      "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Se produjo un error al crear una suscripción de instancias reservadas mediante sincronización",
      "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Se produjo un error al crear la renovación programada",
      "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Error al poner en cola el mensaje para cargar el historial de suscripciones en el servicio PSA",
      "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Ocurrió un error al generar la factura masiva",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Se produjo un error al generar CSV para el informe de resumen de partidas de factura",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Ocurrió un error al generar CSV para elementos de línea de factura con informe de detalles de comisión",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Ocurrió un error al generar CSV para el informe de rentabilidad",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Ocurrió un error al generar CSV para el informe de resumen de montos de impuestos",
      "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Error al obtener los detalles del servicio PSA activo",
      "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Se produjo un error al intentar obtener categorías para comparar ingresos versus costos.",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Se produjo un error al obtener los detalles de la suscripción.",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Error al obtener la asignación de suscripciones de clientes a PSA",
      "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Ocurrió un error al obtener el conteo de clientes",
      "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Se produjo un error al intentar obtener detalles de ingresos versus costos por categoría",
      "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Se produjo un error al obtener los detalles de la suscripción al servicio externo mediante la identificación de la suscripción c3, la identificación del servicio del contrato y la identificación del contrato",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al obtener la lista de configuraciones administradas por el cliente",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al obtener la lista de configuraciones administradas por el revendedor",
      "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Se produjo un error al obtener la lista de fechas de finalización de la suscripción",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Error al obtener las entidades mapeadas y contratar servicios para Autotask",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Error al obtener las entidades y productos asignados para ConnectWise Manage Manage",
      "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Ocurrió un error al obtener revendedores de proveedores no incorporados",
      "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Ocurrió un error al obtener los parámetros del portlet",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Ocurrió un error al obtener el conteo de productos",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Ocurrió un error al hacer que el producto cuente como en la fecha",
      "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Ocurrió un error al obtener el detalle de la promoción",
      "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Error al obtener el resumen del registro de publicación de PSA",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Ocurrió un error al obtener los asientos comprados por ID de SKU",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Ocurrió un error al obtener los asientos comprados en un período determinado",
      "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Ocurrió un error al obtener los ingresos frente al costo",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Ocurrió un error al obtener el conteo de asientos",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Ocurrió un error al hacer que los asientos contaran como en la fecha",
      "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Error al obtener la configuración de Autotask",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Error al obtener el estado del registro del servicio PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Error al obtener los registros de publicación del servicio PSA",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Error al obtener los registros almacenados de PSA",
      "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Ocurrió un error al obtener los clientes valiosos",
      "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Ocurrió un error al obtener los productos comprados valorados por ingresos",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Error al administrar las entidades mapeadas y contratar servicios para Autotask",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Error al administrar las entidades y productos mapeados para ConnectWise Manage Manage",
      "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Se produjo un error al intentar incorporar al revendedor del proveedor",
      "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Se produjo un error al procesar la solicitud de revendedores de proveedores integrados al por mayor",
      "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Se produjo un error al hacer cola para los revendedores de proveedores integrados al por mayor",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al revertir las configuraciones administradas por el cliente a las predeterminadas",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al revertir las configuraciones administradas por el revendedor a las predeterminadas",
      "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Se produjo un error al guardar o actualizar la fecha de finalización personalizada de la línea de carrito",
      "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Error al guardar los datos del historial de suscripción de los clientes seleccionados en la etapa de preparación",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al actualizar las configuraciones administradas por el cliente",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al actualizar las configuraciones administradas por el revendedor",
      "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Se produjo un error al actualizar la renovación programada",
      "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Error al actualizar el estado de los registros por etapas",
      "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Ocurrió un error al actualizar la suscripción",
      "ERROR_DETAILS":"Error de detalles",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"El nombre de este departamento ya existe en este cliente y sitio.",
      "ERROR_DUPLICATE_SITE_NMAE":"El nombre de este sitio ya existe bajo este cliente.",
      "ERROR_EMPTY_COMMENTS_SUBMITTED":"no se pueden enviar comentarios vacíos",
      "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"No se pudieron importar los detalles de pago",
      "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Error al importar los detalles de la Comisión del producto",
      "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"No se pudo agregar por etapas la entrada masiva de inquilinos",
      "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Error al generar detalles en detalles de entidad de factura masiva",
      "ERROR_FIELD_REQUIRED":"Este campo es obligatorio",
      "ERROR_FORM_INVALID":"El formulario no es válido.",
      "ERROR_GETTING_HOSTED_TOKEN":"Error al obtener el token alojado",
      "ERROR_GETTING_STRIPE_DETAILS":"Se produjo un error al obtener los detalles de la franja",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Parece que no tienes los permisos adecuados para realizar esta acción.",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"¡Prohibido!",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"No se puede actualizar el estado del producto porque la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
      "ERROR_MESSAGE":"¡Algo salió mal!",
      "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"La aprobación no fue exitosa, comuníquese con el soporte",
      "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Se debe seleccionar al menos un cliente",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"No tienes permiso para reducir asientos.",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Ocurrió un error al crear el plan. Póngase en contacto con nuestro equipo de soporte.",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Ocurrió un error al crear el plan de revendedor. Póngase en contacto con nuestro equipo de soporte.",
      "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Lamentamos que la conectividad parezca rota entre su cuenta C3 y Autotask. Esto puede provocar fallas al realizar todas las transacciones de Autotask a través de C3. Establezca la conectividad visitando <b>Configuración -> Autotask</b>",
      "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Lamentamos que la conectividad parezca interrumpida entre su cuenta C3 y Connectwise. Esto puede provocar fallas al realizar todas las transacciones de Connectwise a través de C3. Establezca la conectividad visitando <b>Configuración -> Administrar Connectwise</b>",
      "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"La configuración de SMTP no es válida",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Lo sentimos, pero parece que la conectividad entre su cuenta C3 y el Centro de socios de Microsoft está interrumpida. Esto puede provocar un error al realizar todas las transacciones del Centro de partners a través de C3. Establezca la conectividad visitando <b>Configuración -> Proveedores</b>",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Lo sentimos, pero parece que la conectividad entre su cuenta C3 y la API de precios para socios de Microsoft está rota. Esto puede provocar un error al obtener detalles de la API de precios de Microsoft a través de C3. Establezca la conectividad visitando <b>Configuración -> Proveedores</b>",
      "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Lamentamos que la conectividad parezca interrumpida entre su cuenta C3 y Quickbooks. Esto puede provocar fallas al realizar todas las transacciones de Quickbooks a través de C3. Establezca la conectividad visitando <b>Analizar -> Negocios -> Descargar facturas/Pagos</b>",
      "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Lamentamos que la conectividad parezca rota entre su cuenta C3 y Xero. Esto puede provocar fallas al realizar todas las transacciones de Xero a través de C3. Establezca la conectividad visitando <b>Analizar -> Negocios -> Descargar facturas/Pagos</b>",
      "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Número de carrito no válido",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Cliente no válido",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"El usuario inquilino no es válido",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"El cambio de estado no es válido.",
      "ERROR_MESSAGE_MANAGE_OWNERSHIP":"El campo 'Cantidad' es obligatorio y no puede ser nulo ni un número decimal. Ingrese un número entero válido.",
      "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Se pueden seleccionar hasta 3 mercados.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"No se encontró ningún mensaje para solicitar una relación de revendedor, comuníquese con el soporte de C3",
      "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"El carrito contiene ofertas que no son de socios: ofertas compatibles con licencia, elimínelas para continuar.",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"El usuario con este correo electrónico ya existe o no tienes permiso para agregar este usuario",
      "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Las compras pueden fallar. Por favor, haga clic para saber más.",
      "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"No se permite la disminución de cantidad",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Este cliente no está vinculado con Microsoft csp",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"No se puede procesar su solicitud.",
      "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Ha introducido más comprobantes de los necesarios",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Se produjo un error al exportar el informe de partidas individuales de la factura. Comuníquese con el administrador.",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Se produjo un error al exportar el informe resumido de la factura. Comuníquese con el administrador.",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Se produjo un error al cargar el archivo.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Error al subir la imagen. Inténtelo de nuevo con otra imagen.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Seleccione la extensión de la imagen, por ejemplo: .jpg, .png, etc.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Error del centro de socios al verificar MPNID. Por favor revise su entrada nuevamente.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Error del centro de socios al crear usuario",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Error del centro de socios al eliminar usuario",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Error del centro de socios al obtener el usuario inquilino",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Error del centro de socios al obtener detalles del usuario",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Error del centro de socios al obtener roles de usuario",
      "ERROR_MS_FAILED_TO_GET_USERS":"Error del centro de socios al obtener usuarios",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Error del centro de socios al actualizar el usuario",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Error del centro de socios al actualizar las asignaciones de licencias de usuario",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Error del centro de socios al actualizar la contraseña del usuario",
      "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Se produjo un error al intentar poner en cola una solicitud para actualizar las últimas ofertas al plan.",
      "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Ocurrió un error al verificar la elegibilidad de las promociones.",
      "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Ocurrió un error al contar las promociones en el mes actual.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Ocurrió un error al obtener el detalle de las promociones.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Ocurrió un error al obtener los detalles de las promociones del proveedor.",
      "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Ocurrió un error al cargar las promociones.",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Ha ocurrido un error al procesar su solicitud",
      "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"Se produjo un error al guardar los detalles de la actualización de la suscripción en la tabla de preparación",
      "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Se produjo un error al programar el pedido. Por favor contacte con soporte",
      "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"Se produjo un error al intentar obtener los productos elegibles para la actualización de la suscripción",
      "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Ocurrió un error al actualizar la identificación de la promoción en cartlineItem.",
      "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Falta el producto al importar los datos de uso diario de la oferta del socio",
      "ERROR_PLAN_ID":"identificación del plan",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Confirme la aceptación del cliente del Acuerdo de cliente de Microsoft.",
      "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Error al importar datos de uso diario de ofertas de socios",
      "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Ocurrió un error al intentar importar Comisiones de productos",
      "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La secuencia de productos tiene valores no válidos o negativos. Proporcione una secuencia válida.",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"El inquilino que está intentando incorporar/vincular ya existe en el portal; comuníquese con el soporte técnico.",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"El inquilino que está intentando incorporar/vincular existe con uno de sus clientes. Pruebe con otro inquilino.",
      "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"El elemento de la línea de cotización tiene valores no válidos o negativos. Indique una cantidad válida entre 1 y 9999.",
      "ERROR_RESELLER_PROVIDER_ID":"ID del proveedor revendedor",
      "ERROR_SAVING_CUSTOMER":"Error al guardar el cliente",
      "ERROR_SAVING_PARTNER_OFFER":"Ocurrió un error al guardar la oferta del socio",
      "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"El paquete debe contener ofertas de MS de la misma categoría.",
      "ERROR_SAVING_PAYMENT_ACCOUNT":"Error al guardar la cuenta de pago",
      "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
      "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Error de la base de datos al guardar la identificación de MPN",
      "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
      "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Sólo las ofertas personalizadas son elegibles para la oferta de prueba.",
      "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Sólo las ofertas basadas en cantidad son elegibles para la oferta de prueba.",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"No se puede encontrar un inquilino proveedor.",
      "ERROR_UPDATING_AUTO_RENEW_STATUS":"La actualización de renovación automática falló para {{productName}}.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Error al acceder a la base de datos",
      "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Ocurrió un error al intentar verificar que el revendedor se haya vinculado con el proveedor",
      "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Se produjo un error al descargar el archivo de plantilla de carga masiva de muestra .csv",
      "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Error al generar el informe del catálogo de productos",
      "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Se produjo un error al obtener el historial de consentimiento de renovación",
      "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Se produjo un error al obtener las fechas de finalización del centro de socios. Por favor contacte al soporte.",
      "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Se produjo un error al intentar obtener las cuotas de la factura.",
      "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Ocurrió un error al obtener los puestos de producto valorados",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Seleccione al menos una factura para publicarla en Xero.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Error: Abandono de la carga debido a un error al crear entidades",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Error: La adición está activa en ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del acuerdo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Error: La facturación no está habilitada para este acuerdo en ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Error: El acuerdo asignado no está activo en ConnectWise Manage con este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Error: El acuerdo no está presente en ConnectWise Manage con este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Error: Los acuerdos no están asignados para este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Error: Los acuerdos no están presentes en ConnectWise Manage bajo este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Error: Hay adiciones ambiguas, no es posible realizar el mapeo.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del contrato",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Error: La facturación no está habilitada para este contrato en Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Error: El contrato asignado no está activo en Autotask bajo este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Error: El contrato no está presente en Autotask bajo este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: El contrato no está presente en el servicio externo de este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: El servicio contratado no está asignado a este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Error: El servicio de contrato asignado no está presente en Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: Los servicios del contrato no están asignados a este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Error: Los contratos no están asignados a este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Error: Los contratos no están presentes en Autotask bajo este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: Los contratos no están presentes en el servicio externo bajo este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Error: El cliente no está activo en el servicio externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Error: El cliente externo no está asignado al cliente c3",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: El cliente no está presente en el servicio externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Error: Falta la asignación de entidad o producto.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Error: Se intentó exportar anteriormente. El estado de esta operación es",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Error: El contrato no está activo en el servicio externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID de cliente externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Error: Los clientes no están presentes en el servicio externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Error: Se produjo un error al crear una adición en ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Error: Se produjo un error al crear un ajuste:",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Error: Se produjo un error al crear el ajuste para el cambio de precio",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Error: Se produjo un error al crear el servicio",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Error: No se pudo crear el cargo del contrato para un producto de uso único",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Error: Se produjo un error al crear el servicio de contrato:",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Error: Se produjo un error al actualizar la adición en ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Error: No se puede crear una asignación porque se encontraron varios servicios coincidentes en Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Error: No se pudieron encontrar contratos coincidentes para asignar automáticamente.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Error: No se pudo encontrar ningún cliente externo coincidente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Error: No se encontró ningún producto externo que coincida",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: El producto no está asignado a este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Error: El producto asignado no está presente en ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: Los productos no están asignados a este cliente",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Error: Los productos C3 no tienen servicio externo asignado",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del servicio",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del acuerdo.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del acuerdo.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del contrato.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del contrato.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del acuerdo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del acuerdo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de la facturación del contrato",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del contrato",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Error: La suscripción está en Autotask activa",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Error: La suscripción está inactiva en el servicio externo",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Error: No se puede completar la asignación automática ya que se encontraron varios contratos coincidentes",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Error: No se puede completar la asignación automática porque se emparejaron varios clientes externos",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Error: No se puede completar la asignación automática porque se combinaron varios productos externos",
      "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Error: La validación no fue exitosa",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
      "ERRORMESSAGEPOPUP_HEADER":"Incluya al menos uno de los requisitos previos para los complementos en la siguiente lista",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Añadir",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Oferta de requisito previo",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Error: El acuerdo PSA no está asignado al cliente c3",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Error: El producto externo no está asignado al producto c3",
      "ERRORS_FROM_PREVIOUS_ACTION":"Su última acción resultó en errores, haga clic para obtener más detalles...",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Se produjo un error al procesar su solicitud. Por favor contacte a Soporte.",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"ID de suscripción del plan de Azure",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Nombre del plan de Azure",
      "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"Id. de actualización",
      "EVENT_BATCH_ID":"ID del lote",
      "EVENT_BATCH_STEP_ID":"ID de paso de lote",
      "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Resumen de lotes de revendedores integrados en masa",
      "EVENT_CAN_PRICE_LAG":"Retraso en el precio de OldCan",
      "EVENT_CAN_PRICE_LEAD":"NuevoCanPriceLead",
      "EVENT_CONSTANT_TRUE":"VERDAD",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Continuar con la oferta de prueba hasta que falle la suscripción",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Continuar con la oferta de prueba hasta lograr el éxito de la suscripción",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Convertir oferta de prueba en error de suscripción",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Convierta la oferta de prueba en una suscripción exitosa",
      "EVENT_CREATED_BY":"Evento creado por",
      "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Identificación interna",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Correo electrónico",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Nombre de pila",
      "EVENT_DATA_ACCOUNT_MANAGER_ID":"Identificación del administrador de cuenta",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Apellido",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Número de teléfono",
      "EVENT_DATA_ACCOUNT_NUMBER":"Número de cuenta",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Acción",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Error",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Estado",
      "EVENT_DATA_ADDRESS_CITY":"Ciudad",
      "EVENT_DATA_ADDRESS_COUNTRY":"País",
      "EVENT_DATA_ADDRESS_LINE_1":"Dirección Línea 1",
      "EVENT_DATA_ADDRESS_LINE_2":"Línea de dirección 2",
      "EVENT_DATA_ADDRESS_STATE":"Estado",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Cremallera",
      "EVENT_DATA_AGREEMENT":"Convenio",
      "EVENT_DATA_AGREEMENT_DATE":"Fecha de acuerdo",
      "EVENT_DATA_AGREEMENT_LINK":"Enlace del acuerdo",
      "EVENT_DATA_AGREEMENT_NAME":"Convenio",
      "EVENT_DATA_AGREEMENT_TYPE":"Tipo de acuerdo",
      "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"¿La asignación de grupos de seguridad de Microsoft a la solicitud GDAP se realizó correctamente?",
      "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Cupón asignado a",
      "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":" Nuevo valor",
      "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Valor antiguo",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Comportamiento",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Error",
      "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Razón",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Estado",
      "EVENT_DATA_BCC_RECIPIENTS":"Destinatarios BCC",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Nombre del ciclo de facturación",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Alias de pago",
      "EVENT_DATA_BILLING_PERIOD_ID":"ID del período de facturación",
      "EVENT_DATA_BILLING_PROVIDER":"Proveedor de facturación",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor de facturación",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor de facturación",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor de facturación",
      "EVENT_DATA_BILLING_PROVIDER_USER":"ID de usuario",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"ID de usuario",
      "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Nombre del paquete",
      "EVENT_DATA_C3_CUSTOMER_NAME":"Cliente C3",
      "EVENT_DATA_C3_PRODUCT_NAME":"Producto C3",
      "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Nombre de suscripción C3",
      "EVENT_DATA_CARD_TYPE":"Tipo de tarjeta",
      "EVENT_DATA_CART_ID":"ID del carrito",
      "EVENT_DATA_CART_ITEMS":"Artículos del carrito",
      "EVENT_DATA_CATEGORY_ID":"Categoria ID",
      "EVENT_DATA_CATEGORY_NAME":"nombre de la categoría",
      "EVENT_DATA_CC_RECIPIENTS":"Destinatarios de CC",
      "EVENT_DATA_CHANGE_TYPE":"Tipo de cambio",
      "EVENT_DATA_CLONED_FROM_PLAN":"Clonado de",
      "EVENT_DATA_COMPANY_NAME":"nombre de empresa",
      "EVENT_DATA_CONSUMED_SEATS":"Asientos consumidos",
      "EVENT_DATA_CONSUMPTION_TYPE":"Tipo de consumo",
      "EVENT_DATA_CONTRACT":"Contrato",
      "EVENT_DATA_CONTRACT_NAME":"Nombre del contrato",
      "EVENT_DATA_CONTRACT_SERVICE_NAME":"Nombre del servicio del contrato",
      "EVENT_DATA_CONVERSION_RATE":"Tasa de conversión",
      "EVENT_DATA_CORRELATION_ID":"ID de correlación",
      "EVENT_DATA_COUNTRY_CODE":"Código de país",
      "EVENT_DATA_COUPON_CODE":"Código promocional",
      "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Número de clientes con nuevas asignaciones",
      "EVENT_DATA_COUPON_DESCRIPTION":"Descripción del cupón",
      "EVENT_DATA_COUPON_DETAILS":"Detalles del cupón",
      "EVENT_DATA_COUPON_DISCOUNT":"Descuento",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Expira el",
      "EVENT_DATA_COUPON_NOOFRECURRENCES":"Número de recurrencias",
      "EVENT_DATA_COUPON_VALID_TILL":"Válida hasta",
      "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Creado por en proveedor",
      "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Fecha de creación en el proveedor",
      "EVENT_DATA_CURRENCY_CODE":"Código de moneda",
      "EVENT_DATA_CURRENT_QUANTITY":"Cantidad actual",
      "EVENT_DATA_CURRENT_STATUS":"Estado actual",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Nuevo ciclo de facturación personalizado",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Antiguo ciclo de facturación personalizado",
      "EVENT_DATA_CUSTOMER_C3_ID":"Identificación del cliente C3",
      "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Consentir",
      "EVENT_DATA_CUSTOMER_NAME":"Nombre del cliente",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Nuevo nombre del cliente",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Nombre antiguo del cliente",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"ID de referencia del cliente",
      "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"ID de lote de registro de cliente",
      "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"planid interno",
      "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"¿Se están desactivando todos los registros de PSA?",
      "EVENT_DATA_DEPARTMENT_NAME":"Nombre de Departamento",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Ciclo de facturación",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Nombre",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Precio de coste",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Precio de venta",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Validez",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Tipo de validez",
      "EVENT_DATA_DOMAIN":"Dominio",
      "EVENT_DATA_DOMAIN_NAME":"Nombre de dominio",
      "EVENT_DATA_EFFECTIVE_FROM":"Válido desde",
      "EVENT_DATA_EMAIL":"Correo electrónico",
      "EVENT_DATA_EMAIL_ADDRESS":"Correo electrónico",
      "EVENT_DATA_END_BILLING_PERIOD_ID":"ID del período de finalización de la facturación",
      "EVENT_DATA_END_DATE":"Fecha final",
      "EVENT_DATA_ENTITY_ID":"identificación de la entidad",
      "EVENT_DATA_ENTITY_NAME":"Nombre de la entidad",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entidad de usuario",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entidad de usuario",
      "EVENT_DATA_ERROR":"Error",
      "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Mapeo de productos con error",
      "EVENT_DATA_EVENT":"Evento",
      "EVENT_DATA_EVENT_ID":"ID de evento",
      "EVENT_DATA_EVENT_NAME":"Nombre del evento",
      "EVENT_DATA_EXCEPTION_MESSAGE":"Mensaje de excepción",
      "EVENT_DATA_EXPIRES_ON":"Expira el",
      "EVENT_DATA_EXPORT_ERROR":"Error de exportación",
      "EVENT_DATA_EXPORT_STATUS":"Estado de exportación",
      "EVENT_DATA_EXTERNAL_AGREEMENTS":"Acuerdos externos",
      "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Servicios de contrato externo",
      "EVENT_DATA_EXTERNAL_CONTRACTS":"Contratos externos",
      "EVENT_DATA_EXTERNAL_CUSTOMERS":"Cliente de PSA",
      "EVENT_DATA_EXTERNAL_PRODUCTS":"Producto PSA",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Identificación del cliente",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"ID del historial de suscripciones",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Identificación de suscripción",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Identificación del contrato",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"ID de servicio de contrato",
      "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"ID de cliente externo",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Error de exportación",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Estado de exportación",
      "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Tipo de mapeo de productos",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Error de validacion",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Estado de validación",
      "EVENT_DATA_FAILED_TENANT_ID":"ID de inquilino fallido",
      "EVENT_DATA_GROUP_DESCRIPTION":"Descripción del Grupo",
      "EVENT_DATA_GROUP_NAME":"Nombre del grupo",
      "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"Tiene detalle adicional",
      "EVENT_DATA_IMPERSONATOR":"Imitador",
      "EVENT_DATA_INPUT_DATA":"Detalle de la solicitud",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"ID de cliente interno",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"ID de producto del cliente interno",
      "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Identificación interna para nueva renovación programada",
      "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Identificación interna para la antigua renovación programada",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId del plan de origen",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId del plan de destino",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"Identificación del producto C3",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"ID de suscripción interna",
      "EVENT_DATA_INVOICE_LINEITEM":"Nombre de la partida de la factura",
      "EVENT_DATA_INVOICE_NUMBER":"Número de factura",
      "EVENT_DATA_INVOICE_PROPERTY":"Nombre de la propiedad",
      "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Valor antiguo",
      "EVENT_DATA_INVOICE_PROPERTY_VALUE":" Nuevo valor",
      "EVENT_DATA_IS_ACCEPTED":"¿Se acepta la solicitud?",
      "EVENT_DATA_IS_ENABLED":"Está habilitado",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"¿Este producto está disponible para su uso inmediato?",
      "EVENT_DATA_IS_SHOW_ON_UI":"Se muestra en la pantalla",
      "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
      "EVENT_DATA_LINE_ITEM":"Elemento en linea",
      "EVENT_DATA_LINKED_PRODUCT_NAME":"Nombre del producto vinculado",
      "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Nombre del producto vinculado",
      "EVENT_DATA_LOGGED_IN_USER":"Usuario registrado",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Administrar detalles de licencia de usuario",
      "EVENT_DATA_MESSAGE":"Mensaje",
      "EVENT_DATA_Name":"Nombre",
      "EVENT_DATA_NAME":"Nombre",
      "EVENT_DATA_NAME_OF_ENTITY":"Nombre de la entidad",
      "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Nuevo valor",
      "EVENT_DATA_NEW_COUPON_CODE":"Nuevo código de cupón",
      "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Nueva descripción del cupón",
      "EVENT_DATA_NEW_COUPON_DISCOUNT":"Nuevo cupón de descuento",
      "EVENT_DATA_NEW_COUPON_EXPIRESON":"El nuevo cupón caduca el",
      "EVENT_DATA_NEW_COUPON_NAME":"Nuevo nombre de cupón",
      "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Nuevo cupón no de recurrencias",
      "EVENT_DATA_NEW_COUPON_VALIDTILL":"Nuevo cupón válido hasta",
      "EVENT_DATA_NEW_DESCRIPTION":"Descripción del nuevo plan",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción de la oferta del nuevo distribuidor",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Nueva oferta de distribuidor",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Nueva oferta de distribuidor",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Nuevo nombre de oferta de distribuidor",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Nueva oferta de distribuidor",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Nueva oferta de distribuidor",
      "EVENT_DATA_NEW_END_DATE_TYPE":"Nuevo tipo de fecha de finalización",
      "EVENT_DATA_NEW_ENTITY_NAME":"Nueva entidad",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transferido a",
      "EVENT_DATA_NEW_ISPUBLIC":"Nuevo plan es público",
      "EVENT_DATA_NEW_MARKUP":"Nuevo marcado",
      "EVENT_DATA_NEW_NAME":"Nuevo nombre del plan",
      "EVENT_DATA_NEW_OFFER":"Nueva oferta",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Descripción de la oferta de nuevo socio",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Nueva oferta de socios",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Nueva oferta de socios",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Nuevo nombre de oferta de socio",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Nueva oferta de socios",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Nueva oferta de socios",
      "EVENT_DATA_NEW_PLAN":"Nuevo plan",
      "EVENT_DATA_NEW_PRICE":"Nuevo precio",
      "EVENT_DATA_NEW_PRODUCT_MAPPING":"Mapeo de nuevos productos",
      "EVENT_DATA_NEW_PROMOTION_ID":"Nueva identificación de promoción",
      "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Nueva fecha de finalización efectiva",
      "EVENT_DATA_NEW_QUANTITY":"Nueva cantidad",
      "EVENT_DATA_NEW_SEATS":"Asientos nuevos",
      "EVENT_DATA_NEW_STATUS":"Nuevo estado",
      "EVENT_DATA_NEW_VALIDITY":"Nueva Vigencia",
      "EVENT_DATA_NEW_VALIDITY_TERM":"Nueva Vigencia",
      "EVENT_DATA_NEW_VALIDITYTYPE":"Nuevo tipo de validez",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Destinatarios de la notificación",
      "EVENT_DATA_OFFER_NAME":"Nombre de la oferta",
      "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Precio de oferta después del descuento",
      "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Valor antiguo",
      "EVENT_DATA_OLD_COUPON_CODE":"Código de cupón antiguo",
      "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Descripción del cupón antiguo",
      "EVENT_DATA_OLD_COUPON_DISCOUNT":"Antiguo cupón de descuento",
      "EVENT_DATA_OLD_COUPON_EXPIRESON":"El cupón anterior caduca el",
      "EVENT_DATA_OLD_COUPON_NAME":"Nombre antiguo del cupón",
      "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Número de cupón antiguo de recurrencias",
      "EVENT_DATA_OLD_COUPON_VALIDTILL":"Cupón antiguo válido hasta",
      "EVENT_DATA_OLD_DESCRIPTION":"Descripción del plan antiguo",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción de la oferta del distribuidor anterior",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Oferta de distribuidor antigua",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Oferta de distribuidor antigua",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Nombre de oferta de distribuidor anterior",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Oferta de distribuidor antigua",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Oferta de distribuidor antigua",
      "EVENT_DATA_OLD_ENTITY_ID":"Entidad antigua",
      "EVENT_DATA_OLD_ENTITY_NAME":"Entidad antigua",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transferido desde",
      "EVENT_DATA_OLD_ISPUBLIC":"El plan antiguo es público",
      "EVENT_DATA_OLD_NAME":"Nombre del plan antiguo",
      "EVENT_DATA_OLD_OFFER":"Oferta anterior",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Descripción antigua de la oferta de socios",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Oferta de antiguo socio",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Oferta de antiguo socio",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Antiguo nombre de oferta de socio",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Oferta de antiguo socio",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Oferta de antiguo socio",
      "EVENT_DATA_OLD_PLAN":"plano antiguo",
      "EVENT_DATA_OLD_PRICE":"Precio anterior",
      "EVENT_DATA_OLD_QUANTITY":"Cantidad antigua",
      "EVENT_DATA_OLD_SEATS":"Asientos antiguos",
      "EVENT_DATA_OLD_STATUS":"Estado antiguo",
      "EVENT_DATA_OLD_VALIDITY":"Vigencia antigua",
      "EVENT_DATA_OLD_VALIDITY_TERM":"Vigencia antigua",
      "EVENT_DATA_OLD_VALIDITYTYPE":"Tipo de validez antiguo",
      "EVENT_DATA_PAID_AMOUNT":"Monto de pago",
      "EVENT_DATA_PAID_DATE":"Fecha de pago",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Nombre del producto principal",
      "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Id. de suscripción al plan de Azure",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Nombre del producto principal",
      "EVENT_DATA_PARTNER_NAME":"Nombre del socio",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Alias del perfil de pago",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"ID del perfil de pago",
      "EVENT_DATA_PAYMENT_TYPE":"Tipo de pago",
      "EVENT_DATA_PHONE_NUMBER":"Número de teléfono",
      "EVENT_DATA_PLAN_COUNT":"No. de planes",
      "EVENT_DATA_PLAN_DESCRIPTION":"Descripción",
      "EVENT_DATA_PLAN_ID":"identificación del plan",
      "EVENT_DATA_PLAN_NAME":"Nombre del plan",
      "EVENT_DATA_PLAN_PRODUCT_ID":"ID del producto del plan",
      "EVENT_DATA_PLAN_TYPE":"Tipo de plan",
      "EVENT_DATA_PRICE":" Precio",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"ID de suscripción del producto C3",
      "EVENT_DATA_PRODUCT_MAPPING":"Mapeo de productos",
      "EVENT_DATA_PRODUCT_NAME":"Nombre del producto",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Nuevo nombre del producto",
      "EVENT_DATA_PRODUCT_PRICE":"Precio del producto",
      "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"ID de suscripción del producto",
      "EVENT_DATA_PROMOTION_ID":"Identificación de la promoción",
      "EVENT_DATA_PROVIDER":"Proveedor",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"ID de empresa del proveedor",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Cliente proveedor d",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Nombre de la entidad proveedora",
      "EVENT_DATA_PROVIDER_ID":"Identificación del proveedor",
      "EVENT_DATA_PROVIDER_NAME":"Nombre del proveedor",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"ID del perfil de pago del proveedor",
      "EVENT_DATA_PROVIDER_PRICE":"precio del proveedor",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Identificación de suscripción",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
      "EVENT_DATA_PROVIDER_RESELLER_NAME":"Nombre del revendedor del proveedor",
      "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
      "EVENT_DATA_PSA_CUSTOMER_NAME":"Cliente de PSA",
      "EVENT_DATA_PSA_LOG_ID":"ID del lote",
      "EVENT_DATA_PSA_NAME":"Nombre del anuncio de servicio público",
      "EVENT_DATA_PSA_PRODUCT_NAME":"Producto PSA",
      "EVENT_DATA_QUANTITY":"Cantidad comprada",
      "EVENT_DATA_RECORD_ID":"Identificación de registro",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"ID de registro del usuario",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"ID de registro del usuario",
      "EVENT_DATA_RECORD_INT_ID":"Registro de identificación interna",
      "EVENT_DATA_RECORD_NAME":"Nombre de registro",
      "EVENT_DATA_RELEASED_SEATS":"Asientos Liberados",
      "EVENT_DATA_RENEWS_EXPIRES_ON":"Se renueva/expira el",
      "EVENT_DATA_RENEWS_ON":"se renueva el",
      "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Estado de renovación automática del producto C3",
      "EVENT_DATA_REQUESTED_QUANTITY":"Cantidad requerida",
      "EVENT_DATA_REQUESTED_STATUS":"Estado solicitado",
      "EVENT_DATA_RESELLER_NAME":"Nombre del revendedor",
      "EVENT_DATA_RESELLER_NEW_NAME":"Nuevo nombre del revendedor",
      "EVENT_DATA_RESELLER_OLD_NAME":"Nombre antiguo del revendedor",
      "EVENT_DATA_RESERVATION_ORDER_ID":"ID de orden de reserva",
      "EVENT_DATA_ROLE":"Role",
      "EVENT_DATA_SALE_PRICE":"Precio de venta",
      "EVENT_DATA_SEAT_LIMIT":"Límite de asientos",
      "EVENT_DATA_SITE_NAME":"Nombre del sitio",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Nombre de Departamento",
      "EVENT_DATA_SOURCE_CURRENCY":"Moneda de origen",
      "EVENT_DATA_SOURCE_OFFER_ID":"ID de oferta de origen",
      "EVENT_DATA_SOURCE_PRODUCT_NAME":"Nombre del producto de origen",
      "EVENT_DATA_SOURCE_QUANTITY":"Cantidad de origen",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Ciclo de facturación de origen",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"ID de suscripción de origen",
      "EVENT_DATA_SOURCE_VALIDITY":"Validez de la fuente",
      "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Tipo de validez de fuente",
      "EVENT_DATA_START_BILLING_PERIOD_ID":"Empezar a facturar periodId",
      "EVENT_DATA_START_DATE":"Fecha de inicio",
      "EVENT_DATA_STATUS":"Estado",
      "EVENT_DATA_SUB_TAXES":"Subimpuestos",
      "EVENT_DATA_SUBSCRIPTION_ID":"Identificación de suscripción",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "EVENT_DATA_SUBSCRIPTIONID":"Identificación de suscripción",
      "EVENT_DATA_SUPPORTED_MARKET_CODE":"Código de mercado soportado",
      "EVENT_DATA_SUPPORTED_MARKETS":"Mercados admitidos",
      "EVENT_DATA_SYNCED_PRODUCT":"Número de productos sincronizados",
      "EVENT_DATA_TARGET_CURRENCY":"Moneda objetivo",
      "EVENT_DATA_TARGET_OFFER_ID":"ID de oferta objetivo",
      "EVENT_DATA_TARGET_PRODUCT_NAME":"Nombre del producto de destino",
      "EVENT_DATA_TARGET_QUANTITY":"Cantidad objetivo",
      "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Ciclo de facturación objetivo",
      "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"ID de suscripción de destino",
      "EVENT_DATA_TARGET_VALIDITY":"Validez del objetivo",
      "EVENT_DATA_TARGET_VALIDITY_TYPE":" Tipo de validez de destino",
      "EVENT_DATA_TAX_CODE":"Código de impuestos",
      "EVENT_DATA_TAX_NAME":"Nombre del impuesto",
      "EVENT_DATA_TAX_PERCENTAGE":"Porcentaje de impuestos",
      "EVENT_DATA_TENANT_ID":"Identificación del inquilino",
      "EVENT_DATA_TENANT_NAME":"Nombre del inquilino",
      "EVENT_DATA_TO_RECIPIENTS":"A los destinatarios",
      "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Número de productos afectados",
      "EVENT_DATA_TOTAL_RELEASED_SEATS":"Número de asientos afectados",
      "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Importe total de la transacción",
      "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"límite de cantidad de transacción",
      "EVENT_DATA_TRANSITION_TYPE":"Tipo de transición",
      "EVENT_DATA_UNSYNCED_PRODUCT":"Número de productos no sincronizados",
      "EVENT_DATA_UPDATED_BUNDLE_NAME":"Nombre del paquete actualizado",
      "EVENT_DATA_UPDATED_COUPON_NAME":"Nombre del cupón actualizado",
      "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Nombre de oferta de distribuidor actualizado",
      "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Nombre de oferta de socio actualizado",
      "EVENT_DATA_UPDATED_PLAN_NAME":"Nombre del plan actualizado",
      "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Cambio de mapeo de producto",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Ubicación de uso",
      "EVENT_DATA_USER_DISPLAY_NAME":"Nombre para mostrar",
      "EVENT_DATA_USER_EMAIL":"Correo electrónico del usuario",
      "EVENT_DATA_USER_TYPE":"Tipo de usuario",
      "EVENT_DATA_USERNAME":"Nombre de usuario",
      "EVENT_DATA_VALIDATION_ERROR":"Error de validacion",
      "EVENT_DATA_VALIDATION_STATUS":"Estado de validación",
      "EVENT_DATA_VALIDITY":"Validez",
      "EVENT_DATA_VALIDITY_TYPE":"Tipo de validez",
      "EVENT_DATA_VALUE":"Valor",
      "EVENT_DATA_VOUCHER_ENTITY":"Entidad de comprobante",
      "EVENT_DATA_VOUCHER_IDS":"ID de cupón",
      "EVENT_DATA_VOUCHERS":"vales",
      "EVENT_DATE":"Fecha",
      "EVENT_DEPARTMENT_DESCRIPTION":"Descripción del departamento",
      "EVENT_DEPARTMENT_ID":"ID de departamento",
      "EVENT_DEPARTMENT_NAME":"Nombre de Departamento",
      "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Aceptar o rechazar el consentimiento de renovación de la oferta del socio",
      "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Se aceptaron los términos y condiciones de NCE para pedir productos de Microsoft NCE",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Términos y condiciones aceptados",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Términos y condiciones aceptados Incumplimiento",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Activar la suscripción DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Activar la suscripción DangerThreshold Met",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Activar el umbral de peligro cumplido Error de suscripción",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Oferta personalizada de aprovisionamiento",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Fallo en el aprovisionamiento de la oferta personalizada",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Activar fallo de suscripción",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Activar éxito de suscripción",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"No se pudo crear el cliente",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"Agregar falla del cliente C3",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Agregue el éxito del cliente C3",
      "EVENT_DESC_ADD_ENTITLEMENTS":"Agregar derecho",
      "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Agregar error de derecho",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Agregar administrador de grupo",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Agregar error de administrador de grupo",
      "EVENT_DESC_ADJUST_AZURE_MARGIN":"Ajustar el margen de Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"No se pudo ajustar el margen de Azure",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Ajustar el margen de Azure",
      "EVENT_DESC_APPROVED_BY":"Aprobado por",
      "EVENT_DESC_APPROVED_ON":"Aprobado el",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Aprobados o Rechazados próximos cambios de renovación de cronograma",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Aprobados o Rechazados próximos cambios de renovación de cronograma",
      "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Solicitud de compra aprobada o rechazada",
      "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Solicitud de compra aprobada",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Asignar plan a cliente",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"No se pudo asignar el plan al cliente",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Error al asignar cupones perdidos",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Cupones perdidos asignados con éxito",
      "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin tiene acceso a los clientes automáticamente",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"suscripción de cliente Precio automático actualizado",
      "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Liberación automática de asientos no utilizados",
      "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Desajustes corregidos debido a la sincronización automática",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Cambió el ciclo de facturación con éxito (sincronización automática)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"Cambio de ciclo de facturación fallido (sincronización automática)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Cambió el ciclo de facturación con éxito (sincronización manual)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Cambio de ciclo de facturación fallido (sincronización manual)",
      "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Resumen de lotes integrados en masa",
      "EVENT_DESC_BULK_USER_UPLOAD":"Carga masiva de usuarios",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Error en la carga masiva de usuarios",
      "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Se compran ofertas de paquetes",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Paquete de producto comprado exitosamente",
      "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Cancelar la renovación programada con éxito.",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Solicitud de compra de suscripción cancelada",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Error en la solicitud de compra de suscripción cancelada",
      "EVENT_DESC_CART_AVAILABILITY_STATUS":"Estado de disponibilidad del carrito",
      "EVENT_DESC_CART_CHECKOUT":"Carrito de compra",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Error en el pago del carrito",
      "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Notificar que se excedió el límite de asientos",
      "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Notificar que se excedió el límite de transacción",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Estado de renovación automática del producto del cliente cambiado",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"El estado de renovación automática del producto del cliente no se pudo cambiar",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Estado del producto actualizado exitosamente",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Error al actualizar el estado del producto",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"Cambiar estado de factura",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Error al cambiar el estado de la factura",
      "EVENT_DESC_CHANGE_PLAN_FAILURE":"Fallo en el plan de cambios",
      "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Éxito del plan de cambio",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Error en la conversión de moneda al clonar",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Clonar la conversión de moneda con éxito",
      "EVENT_DESC_CLONE_PLAN":"Plan clonado con éxito",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"No se pudo clonar el plan",
      "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"¿El cupón se aplica a todos los clientes etiquetados?",
      "EVENT_DESC_COUPON_NAME":"Nombre del cupón",
      "EVENT_DESC_COUPON_STATUS":"Estado del cupón",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Crear grupos de Azure",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Error al crear grupos de Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Crear suscripción a Azure",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Crear error en la suscripción de Azure",
      "EVENT_DESC_CREATE_CART":"Crear carrito",
      "EVENT_DESC_CREATE_CART_FAILURE":"Crear error en el carrito",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Crear fracaso del cliente",
      "EVENT_DESC_CREATE_INVOICE":"Crear factura",
      "EVENT_DESC_CREATE_ORDER":"Productos comprados exitosamente",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Falló la compra de productos",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Crear perfil de pago",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Fallo en la creación del perfil de pago",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"No se pudo crear la suscripción de instancias reservadas mediante sincronización",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Suscripción de instancias reservadas creada correctamente mediante sincronización",
      "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Crear renovación programada exitosa",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Crear comprobación de vencimiento de suscripción",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Falló la creación de la suscripción",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Suscripción creada con éxito (sincronización automática)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Error al crear la suscripción (sincronización automática)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Se omitió crear suscripción desde sincronización automática",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Suscripción creada con éxito (sincronización manual)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"Error al crear la suscripción (sincronización manual)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Se omitió la creación de la suscripción desde la sincronización manual",
      "EVENT_DESC_CREATE_TAX_RULE":"Crear regla fiscal",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Error al crear regla fiscal",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Crear usuario de suscripción de uso",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Crear error de usuario de UsageSubscription",
      "EVENT_DESC_CREATE_USER":"Crear usuario",
      "EVENT_DESC_CREATE_USER_FAILURE":"Crear error de usuario",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Consentimiento de renovación de oferta de socio",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Error en el consentimiento de renovación de oferta de socio",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Creación de clientes a través de API",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Creación de clientes a través de falla de API",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Error de eliminación del cliente",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Eliminación de clientes exitosa",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Factura de cliente generada",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Nombre del cliente Error de actualización",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Nombre del cliente Actualización exitosa",
      "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"El límite de asientos del cliente supera Notfication",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"Desactivar registro de PSA",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"No se pudo desactivar el registro de PSA",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Definir conversión de moneda",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Definir error de conversión de moneda",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Definir precios del plan en nueva moneda",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Definir precios del plan en falla de nueva moneda",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Desvincular cliente de facturación",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Fallo del cliente de facturación de desvinculación",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Eliminar administrador de cuenta con éxito",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Error al eliminar el administrador de la cuenta",
      "EVENT_DESC_DELETE_CART_ITEM":"Artículo eliminado del carrito",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Error al eliminar el artículo del carrito",
      "EVENT_DESC_DELETE_CONTRACT_OFFER":"Oferta de contrato eliminada con éxito",
      "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Error al eliminar la oferta de contrato",
      "EVENT_DESC_DELETE_COUPON":"Cupón deshabilitado con éxito",
      "EVENT_DESC_DELETE_COUPON_FAILED":"Error al eliminar el cupón.",
      "EVENT_DESC_DELETE_COUPON_FAILURE":"No se pudo desactivar el cupón",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Eliminar conversión de moneda",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Eliminar error de conversión de moneda",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"No se pudo eliminar la conversión de moneda",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Eliminar error de notificación personalizada",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Eliminar notificación personalizada exitosa",
      "EVENT_DESC_DELETE_CUSTOM_OFFER":"Oferta personalizada eliminada con éxito",
      "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Error al eliminar la oferta personalizada",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Eliminar pago del cliente",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"No se pudo eliminar el pago del cliente",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Eliminar falla del departamento",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Eliminar departamento correctamente",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Oferta de distribuidor eliminada con éxito",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Error al eliminar la oferta del distribuidor",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Eliminar notificación por correo electrónico del evento",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Eliminar error de notificación por correo electrónico de evento",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Eliminar ajuste de factura",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Eliminar error de ajuste de factura",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Eliminar línea de pedido de facturas",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Eliminar facturas LineItemFailure",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Eliminar error de usuario asociado",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Eliminar opción de pago",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Error al eliminar la opción de pago",
      "EVENT_DESC_DELETE_PLAN":"Eliminar plan",
      "EVENT_DESC_DELETE_PLAN_FAILURE":"Eliminar falla del plan",
      "EVENT_DESC_DELETE_QUOTE_FAILED":"Error al eliminar la cita",
      "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Eliminar cita exitosamente",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Eliminar error del sitio",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Eliminar sitio correctamente",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Eliminar comprobación de vencimiento de suscripción",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Error al eliminar la suscripción por vencimiento",
      "EVENT_DESC_DELETE_TAX_RULE":"Eliminar regla fiscal",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Eliminar error en la regla fiscal",
      "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Actualizar error de suscripción de Azure",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Eliminar usuario de suscripción de uso",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Eliminar error de usuario de suscripción de uso",
      "EVENT_DESC_DELETE_USER":"Borrar usuario",
      "EVENT_DESC_DELETE_USER_FAILURE":"Eliminar error de usuario",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Eliminar error de notificación de webhook",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Eliminar la notificación de webhook con éxito",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Deshabilitar la suscripción a Azure",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Error al deshabilitar la asignación de cupones",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupones deshabilitada",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Deshabilitar plan para cliente",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"No se pudo deshabilitar el plan para el cliente",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Informe de partidas individuales de facturas programadas",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Partidas de factura programadas con informe de comisiones",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Informe resumido de factura programada",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Informe de rentabilidad programado",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Informe de partidas de impuestos programadas",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Informe resumido de impuestos programado",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Habilitar la suscripción a Azure",
      "EVENT_DESC_ENABLE_COUPON":"Cupón habilitado con éxito",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Error al habilitar la asignación de cupones",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupones habilitada",
      "EVENT_DESC_ENABLE_COUPON_FAILED":"Error al habilitar el cupón.",
      "EVENT_DESC_ENABLE_COUPON_FAILURE":"No se pudo habilitar el cupón",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Habilitar plan para el cliente",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"No se pudo habilitar el plan para el cliente",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Habilite la notificación cuando se renueve una suscripción anual",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Habilite la notificación cuando se renueve una suscripción mensual",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"No se pudo poner en cola la solicitud para los clientes de incorporación masiva",
      "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Solicitud en cola para clonar plan de revendedor",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Informe de notificación de registro de errores",
      "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Error en la renovación programada de la actualización",
      "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Exportar informe de licencia de cliente.",
      "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Exportar informe de asignación de licencia de usuario",
      "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"No se pudo aprobar o rechazar la siguiente solicitud de renovación programada",
      "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Cancelar la renovación programada falló",
      "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"No se pudo clonar el plan de revendedor",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"No se pudo conectar al software de contabilidad",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"No se pudo conectar con el Centro de socios de Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"No se pudo conectar con la API de precios de Microsoft",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"No se pudo conectar a PSA",
      "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"No se pudo crear la factura a pedido",
      "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Error al crear la renovación programada",
      "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"No se pudo poner en cola la solicitud para clonar el plan de revendedor",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"No se pudo establecer el estado del lote de incorporación masiva en Completo",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"No se pudo establecer el estado del lote de Asignación de licencia de usuario en Completo",
      "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"No se pudo sincronizar el perfil del cliente del proveedor",
      "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"No se pudo actualizar la factura a pedido",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Arreglar la falta de coincidencia del estado de renovación automática automáticamente",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Arreglar la falta de coincidencia del estado de renovación automática falló automáticamente",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Arreglar la falta de coincidencia del estado de renovación automática manualmente",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Solucionar error de coincidencia de estado de renovación automática falló manualmente",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Se corrigió el desajuste de promoción con éxito (sincronización automática)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Error al corregir el desajuste de la promoción (sincronización automática)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Solucionar el desajuste de la promoción manualmente",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Arreglar el desajuste de la promoción falló manualmente",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Error de coincidencia de cantidad fija con éxito (sincronización automática)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Error al corregir el desajuste de cantidad (sincronización automática)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Error de coincidencia de cantidad fija con éxito (sincronización manual)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Error al corregir el desajuste de cantidad (sincronización manual)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Error de coincidencia de estado fijo con éxito (sincronización automática)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"La corrección de la falta de coincidencia de estado falló (sincronización automática)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Error de coincidencia de estado fijo con éxito (sincronización manual)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"La corrección de la falta de coincidencia de estado falló (sincronización manual)",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Discrepancia en el consumo de licencias",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Conceder acceso al fallo del portal",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Conceder acceso al portal",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Ignorar errores de carga masiva de usuarios",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Ignorar errores de carga masiva de usuarios",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Ignorar errores de compra de suscripciones",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Ignorar errores de actualización de suscripción",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Ignorar errores de actualización de suscripción Error",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Ignore el error en la compra de la suscripción",
      "EVENT_DESC_INVOICE_AND_REPORT":"Factura e informe",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Pago de factura iniciado",
      "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Informe de facturas y cuotas.",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Error del cliente del proveedor de facturación del enlace",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Vincular el éxito del cliente del proveedor de facturación",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Error del cliente del proveedor de servicios de enlace",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Éxito del cliente del proveedor de servicios de enlace",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Error de cliente que no es csp del proveedor de servicios de enlace",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Éxito del cliente sin CSP del proveedor de servicios de enlace",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Éxito del cliente del proveedor de facturación de enlaces",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Error del cliente del proveedor de facturación del enlace",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Fallo del cliente del proveedor de servicios de enlace",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Éxito del cliente del proveedor de servicios de enlace",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Error en la gestión de licencias de usuario.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"La gestión de licencias de usuario fue exitosa.",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Programar próximos cambios de renovación",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"No se pudieron programar los próximos cambios de renovación",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Solicitud de aprovisionamiento de producto de NI Partner cumplida",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Solicitud de aprovisionamiento de producto de NI Partner cancelada",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"Producto NI Partner adquirido",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"Cantidad de producto NI Partner actualizada",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Notificar el estado de activación de la compra del producto de NI Provisioning",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Notificar el estado de cancelación de la compra del producto de aprovisionamiento de NI",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Oferta modificada con éxito (sincronización automática)",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Cambio de oferta fallido (sincronización automática)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Oferta modificada con éxito (sincronización manual)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Cambio de oferta fallido (sincronización manual)",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Productos comprados exitosamente",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Plan azul integrado con éxito (sincronización automática)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Falló la incorporación del plan Azure (sincronización automática)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Plan azul integrado con éxito (sincronización manual)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Falló la incorporación del plan Azure (sincronización manual)",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Fallo del cliente a bordo",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Fallo del cliente a bordo",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Éxito del cliente integrado",
      "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Socio ofrece informe exportable",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Error añadido del usuario asociado",
      "EVENT_DESC_PARTNER_USERADDED":"Usuario socio agregado",
      "EVENT_DESC_PASSWORD_EXPIRED":"La contraseña expiró",
      "EVENT_DESC_PAYMENT_FAILURE":"Fallo de pago",
      "EVENT_DESC_PAYMENT_SUCCESS":"Pago exitoso",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Error en la captura del consentimiento de seguridad del Centro de partners",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"El consentimiento de seguridad del Centro de partners se capturó correctamente",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip creado con éxito para todas las facturas.",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Ocurrió un error al crear Zip para todas las facturas.",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Asignación de producto",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Fallo en la asignación del producto",
      "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Promociones cargadas con éxito",
      "EVENT_DESC_PROMOTION_LOADING_FAILED":"No se han podido cargar las promociones",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Precio de productos del proveedor actualizado",
      "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Solicitud de asistencia para el registro público",
      "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"La compra del paquete falló",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Comprar oferta para nuevos socios",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Fallo en la compra de una nueva oferta personalizada",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Comprar producto de aprovisionamiento de NI",
      "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Solicitud de compra pendiente de aprobación",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Productos comprados exitosamente",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Error al comprar productos",
      "EVENT_DESC_QUOTATION_CUSTOMER":"Cotización de clientes",
      "EVENT_DESC_QUOTATION_NAME":"Nombre de la cita",
      "EVENT_DESC_QUOTATION_OWNER":"Propietario de la cita",
      "EVENT_DESC_QUOTE_APPROVAL":"Aprobación de cotización",
      "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Conversión de cotizaciones exitosa",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Solicitud planteada para actualizar el producto comprado",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"La solicitud para actualizar el producto de compra falló",
      "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Discrepancia de reconocimiento en la generación de facturas por adelantado",
      "EVENT_DESC_RECORD_PAYMENT":"Registro de pago",
      "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Incumplimiento de registro de pago",
      "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Solicitud de compra rechazada",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Términos y condiciones rechazados",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Incumplimiento de términos y condiciones rechazados",
      "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Nombre del revendedor Error de actualización",
      "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Nombre del revendedor Actualización exitosa",
      "EVENT_DESC_RESET_USER_PASSWORD":"Restablecer contraseña de usuario",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Error al restablecer la contraseña del usuario",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Éxito en el precio de reversión de derechos",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Revertir falla de precio de derecho de autor",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Revertir la configuración del cliente",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Revertir el error de configuración del cliente",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Revocar el acceso al portal",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Revocar el acceso al fallo del portal",
      "EVENT_DESC_SAVE_COMMENT":"Nuevo comentario añadido",
      "EVENT_DESC_SAVE_CONTRACT_OFFER":"Oferta de contrato guardada con éxito",
      "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"No se ha podido guardar la oferta de contrato",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Guardar falla de notificación personalizada",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Guardar notificación personalizada exitosa",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Guardar oferta personalizada",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Error al guardar oferta personalizada",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"No se pudo guardar el perfil de facturación del cliente",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Guardar perfil de facturación del cliente",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Guardar cliente en facturación",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Salvar al cliente en caso de error de facturación",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"No se pudo guardar al cliente en facturación",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Guardar perfil de pago del cliente",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Error al guardar el perfil de pago del cliente",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Guardar usuario del cliente",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Guardar falla del usuario del cliente",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Guardar fallo del departamento",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Guardar el éxito del departamento",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Guardar oferta de distribuidor",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Guardar error en la oferta del distribuidor",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Ahorrar derecho precio éxito",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Falla en el precio de los derechos de ahorro",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Guardar notificación por correo electrónico del evento",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Error al guardar la notificación por correo electrónico del evento",
      "EVENT_DESC_SAVE_INVITES":"Guardar invitaciones",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Guardar invitaciones fallidas",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Guardar ajustes de factura",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Error al guardar ajustes de factura",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Guardar paquete de socio",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Error al guardar el paquete de socios",
      "EVENT_DESC_SAVE_PARTNER_USER":"Guardar usuario asociado",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Guardar error de usuario asociado",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Perfil de pago guardado",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"No se pudo guardar el perfil de pago",
      "EVENT_DESC_SAVE_PLAN":"Guardar plan",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Guardar fracaso del plan",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Guardar productos del plan en moneda objetivo",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Guardar productos del plan en caso de fallo de la moneda objetivo",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Consentimiento del cliente del proveedor guardado",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"No se pudo guardar el consentimiento del cliente del proveedor",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Guardar pago del proveedor",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Guardar fallo de pago del proveedor",
      "EVENT_DESC_SAVE_QUOTE_FAILED":"No se pudo guardar la cotización",
      "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Cotización guardada exitosamente",
      "EVENT_DESC_SAVE_RESELLER":"Guardar revendedor",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"No se pudo guardar el revendedor",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Guardar error en el sitio",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Guardar el sitio correctamente",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Error al guardar la configuración del usuario",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Guardar la configuración del usuario con éxito",
      "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Guardar notificación de mensaje web",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Error al guardar la notificación del webhook",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Guardar la notificación del webhook con éxito",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Error de aceptación de términos y condiciones",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Términos y condiciones aceptados exitosamente.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"El intento de preparar las licencias para agregarlas o eliminarlas falló.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Organizó las licencias para agregarlas o eliminarlas correctamente.",
      "EVENT_DESC_SCHEDULE_ORDER":"Orden de programación",
      "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Orden de programación fallida",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Informe de partidas individuales de facturas programadas",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Partidas de factura programadas con informe de comisiones",
      "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Informe resumido de factura programada",
      "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Informe de rentabilidad programado",
      "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Informe de partidas de impuestos programadas",
      "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Informe resumido de impuestos programado",
      "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Enviar actualización de oferta de distribuidor",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Enviar factura en pdf al cliente desde la interfaz de usuario",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Enviar factura en pdf a los clientes con transparencia",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Alertas de umbral de uso de suscripción",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Alertas de umbral de uso de suscripción fallida",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Establecer el estado del lote de asignación de licencias de usuario para completar",
      "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"La conectividad SMTP falló",
      "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notificación de vencimiento de suscripción para oferta de prueba",
      "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Informe del historial de suscripciones",
      "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Suspender cuando se alcance el umbral de peligro",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Sincronización de suscripción",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Error de sincronización de suscripción",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Productos actualizados exitosamente",
      "EVENT_DESC_SUCCESS":"Éxito",
      "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"Se aprobó o rechazó con éxito la siguiente solicitud de renovación programada",
      "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Plan de revendedor clonado con éxito",
      "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"Factura bajo demanda creada con éxito",
      "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Perfil de cliente del proveedor sincronizado con éxito",
      "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"Factura bajo demanda actualizada con éxito",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Suspender error de suscripción",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Suspender suscripción exitosa",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Suspender cuando se alcance el umbral de peligro",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Suspender cuando se alcance el umbral de peligro",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"No se pudo sincronizar el consentimiento del cliente del proveedor.",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Consentimiento del cliente del proveedor sincronizado",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Sincronizar suscripciones desde fallas de PC",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Sincronización de suscripciones desde PC exitosa",
      "EVENT_DESC_TAGS_ADDED":"Etiquetas agregadas",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Etiquetas agregadas Fallo",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Error de etiquetas eliminadas",
      "EVENT_DESC_TAGS_DELETED":"Etiquetas eliminadas",
      "EVENT_DESC_TAGS_EDITED":"Etiquetas editadas",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Etiquetas Editadas Fallo",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"Cambió la duración del término con éxito (sincronización automática)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Error al cambiar la duración del término (sincronización automática)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"Cambió la duración del término con éxito (sincronización manual)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Error al cambiar la duración del término (sincronización manual)",
      "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Detalles del producto no sincronizados",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Actualizar grupos de Azure",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Error al actualizar grupos de Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Actualizar la suscripción de Azure",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Error al actualizar la suscripción de Azure",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Oferta de contrato actualizada con éxito",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"Error al actualizar la oferta de contrato",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Actualizar ciclo de facturación personalizado",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"No se pudo actualizar el ciclo de facturación personalizado",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Actualización de error de notificación personalizada",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Actualizar la notificación personalizada con éxito",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Actualizar cantidad de oferta de socios",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Error al actualizar la cantidad de la oferta del socio",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Opción de pago predeterminada actualizada",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"No se pudo actualizar la opción de pago predeterminada",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Actualizar perfil de pago del cliente",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Error al actualizar el perfil de pago del cliente",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Perfil de cliente actualizado",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"No se pudo actualizar el perfil del cliente",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Actualizar la opción de pago predeterminada",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Error en la actualización de la opción de pago predeterminada",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Actualizar notificación por correo electrónico del evento",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Error en la notificación por correo electrónico del evento de actualización",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Actualizar administrador de grupo",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Error al actualizar el administrador del grupo",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":" Error de propiedades de factura actualizadas",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Propiedades de factura actualizadas con éxito",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Actualizar bandera de liberación automática",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"No se pudo actualizar el indicador de lanzamiento automático",
      "EVENT_DESC_UPDATE_LICENSE":"Actualizar licencia",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Error al actualizar la licencia",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Error al actualizar la configuración del Centro de socios",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Actualizar la configuración general del socio",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Error al actualizar la configuración general del socio",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"Actualizar logotipo de socio",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Error al actualizar el logotipo del socio",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Actualizar la configuración del Centro de socios",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Actualizar precio de oferta del plan",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Fallo en el precio de la oferta del plan de actualización",
      "EVENT_DESC_UPDATE_PROFILE":"Actualización del perfil",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Error al actualizar el perfil",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Actualizar la configuración del proveedor",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"No se pudo actualizar la configuración del proveedor",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Atributos de producto actualizados",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"No se pudieron actualizar los atributos del producto",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Actualizar cantidad Producto de aprovisionamiento de NI",
      "EVENT_DESC_UPDATE_QUOTE_FAILED":"No se pudo actualizar la cotización",
      "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Cotización actualizada con éxito",
      "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Actualizar la renovación programada con éxito",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valor predeterminado del cliente del proveedor de servicios actualizado",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"No se pudo actualizar el valor predeterminado del cliente del proveedor de servicios",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Actualizar la configuración SMTP",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Error al actualizar la configuración SMTP",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Productos actualizados exitosamente",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Actualizar comprobación de vencimiento de suscripción",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Error en la comprobación de vencimiento de la actualización de suscripción",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Error al actualizar productos",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":" Suscripción de actualización de API",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Error de suscripción de actualización de API",
      "EVENT_DESC_UPDATE_SUBTAX":"Actualizar Subimpuesto",
      "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Error al actualizar subimpuestos",
      "EVENT_DESC_UPDATE_TAX_RULE":"Actualizar regla fiscal",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Error al actualizar la regla fiscal",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Actualizar la configuración del cliente",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Error en la actualización de la configuración del cliente",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Actualizar usuario de suscripción de uso",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Error de usuario de suscripción de uso de actualización",
      "EVENT_DESC_UPDATE_USER":"Actualizar usuario",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Actualizar error de usuario",
      "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Actualizar notificación de WebMessage",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Error en la notificación de actualización del webhook",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Actualización exitosa de la notificación del webhook",
      "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Actualizar suscripción de Azure",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"La asignación del cupón falló",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Cupón asignado con éxito",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Error en la asignación masiva de productos",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"El mapeo de productos masivos fue exitoso",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Se inicia proceso de Refresh para entidades de servicios externos en C3",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" El proceso de actualización para entidades de servicios externos en C3 ha fallado",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" El proceso de actualización para entidades de servicios externos en C3 ha sido exitoso",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Actualización de suscripción",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Error al actualizar la suscripción",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"No se pudo descargar el catálogo de clientes.",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Catálogo de clientes descargado correctamente.",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Descargar catálogo de productos Éxito",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Error al descargar el catálogo de productos",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Descarga del catálogo de comisiones de productos Fallo",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Descargar catálogo de comisiones de productos Éxito",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"No se pudo descargar el catálogo de uso del informe",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Descargue el catálogo de uso del informe con éxito",
      "EVENT_ENTITLEMENT_ID":"Identificación de titularidad",
      "EVENT_ENTITLEMENT_NAME":"Nombre de la entidad",
      "EVENT_FILE_NAME":"Nombre del archivo",
      "EVENT_IMPERSONATOR":"Imitador",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Error al importar pagos múltiples",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Importar múltiples pagos con éxito",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Error al importar datos validados",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Datos validados importados con éxito",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Fallo en las comisiones de productos importados",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Éxito en las comisiones de productos importados",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Fallo en la importación de extensiones de productos",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Importación de extensiones de producto correctas",
      "EVENT_IMPORT_VOUCHER_FAILURE":"No se pudieron importar los comprobantes.",
      "EVENT_IMPORT_VOUCHER_SUCCESS":" Se importaron exitosamente los comprobantes.",
      "EVENT_IS_LICENSE_ASSIGNED":"¿Está asignada la licencia?",
      "EVENT_IS_LICENSE_REMOVED":"¿Se ha quitado la licencia?",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Revendedor de enlaces",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"No se pudo vincular al revendedor",
      "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"Error en la asignación de entidades PSA",
      "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"La entidad C3 se asignó a la entidad en PSA con éxito",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"Error al eliminar la asignación con la entidad PSA",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Se eliminó con éxito la asignación con la entidad PSA",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"El mapeo de productos de PSA falló",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Productos C3 asignados a productos en PSA con éxito",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"No se pudo eliminar la asignación con la entidad PSA",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Se eliminó la asignación con la entidad PSA con éxito",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Se eliminó correctamente el mapeo con el producto PSA",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"Error al eliminar la asignación con el producto PSA",
      "EVENT_NEW_CAN_PRICE_LAG":"NuevoCanPriceLag",
      "EVENT_NEW_CAN_PRICE_LEAD":"NuevoCanPriceLead",
      "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Nuevo límite de asientos a nivel del plan",
      "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Notificador de error de búsqueda de próxima ejecución",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Seleccione al menos un tipo de destinatario.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Vista previa del correo electrónico",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"La notificación por correo electrónico se eliminó correctamente.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Activado",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Tipo de destinatario",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Destinatarios",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"A",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"La configuración de notificación por correo electrónico se guardó correctamente.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Por favor haga clic en Vista previa del correo electrónico",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"para ver el contenido de la plantilla de correo electrónico.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"Destinatarios CCO",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"Destinatarios de CC",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Evento",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Nombre del evento",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"¿Activado?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"A los destinatarios",
      "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Notificar la creación del cliente mediante el registro del cliente",
      "EVENT_Notify_Purchase_Limit_Error":"Notificar error de límite de compra",
      "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Notificar error de límite de compra",
      "EVENT_OLD_CAN_PRICE_LAG":"Retraso en el precio de OldCan",
      "EVENT_OLD_CAN_PRICE_LEAD":"Precio de OldCanLead",
      "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Límite de asientos del nivel del plan anterior",
      "EVENT_PRODUCT_NAME":"Nombre del producto",
      "EVENT_PURCHASE_APPROVE_REASON":"Razón",
      "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Razón, si falla",
      "EVENT_PURCHASE_PRODUCT_ID":"Identificación de producto",
      "EVENT_PURCHASE_PRODUCT_NAME":"Nombre del producto",
      "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Identificación del producto del plan",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"Cantidad comprada",
      "EVENT_PURCHASE_REASON_ONHOLD":"Motivo de la espera",
      "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Enviar facturas en PDF al socio",
      "EVENT_SITE_DESCRIPTION":"Descripción del lugar",
      "EVENT_SITE_ID":"Identificación del sitio",
      "EVENT_SITE_NAME":"Nombre del sitio",
      "EVENT_SMTP_TEST_EMAIL":"correo electrónico de prueba smtp",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Efectuar y validar el error en los pagos de importación",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Efectuar y validar el éxito de los pagos de importación",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Error al preparar y validar el informe de uso de socios",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Organizar y validar el informe de uso de socios",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Fase y validación de fallas en el catálogo de productos",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Preparar y validar el éxito del catálogo de productos",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Fase y validación de errores de comisiones de productos",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Programar y validar el éxito de las comisiones de productos",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"No se pudo preparar y validar el catálogo de vales.",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Catálogo de bonos preparado y validado con éxito.",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Notificación de vencimiento de la suscripción",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Notificación de vencimiento de suscripción para el cliente",
      "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notificación de vencimiento de suscripción para oferta de prueba",
      "EVENT_TOTAL_RECORD_COUNT":"Número de registros",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":" Desvincular revendedor",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"No se pudo desvincular al revendedor",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Detalles actualizados del producto de uso de socios",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Error al actualizar los detalles del producto de uso del socio",
      "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Notificación de oferta de plan de actualización para revendedor",
      "EVENT_USER_CONFIGURATION_NAME":"Nombre de configuración de usuario",
      "EVENT_USER_CONFIGURATION_VALUE":"Valor de configuración del usuario",
      "EVENT_USER_EMAILID ":"ID de correo electrónico del usuario",
      "EVENT_VOUCHER_ASSIGNMENT":"Asignación de vales",
      "EVENT_WEBJOB_STATUS_NOTIFIER":"Notificador web del estado del trabajo",
      "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Nueva descripción del paquete",
      "EVNET_DATA_NEW_BUNDLE_NAME":"Nuevo nombre de paquete",
      "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Descripción del paquete anterior",
      "EVNET_DATA_OLD_BUNDLE_NAME":"Nombre del paquete anterior",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Se acepta la solicitud de revendedores masivos a bordo",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"Error al aceptar la solicitud de revendedores integrados al por mayor",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"Falló la incorporación del revendedor mediante la incorporación masiva",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"La incorporación del revendedor mediante la incorporación masiva se completó con éxito",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"Error en la incorporación masiva de revendedores",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Incorporación masiva de revendedores exitosa",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Se completaron los derechos de adición masiva",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Falló la adición masiva de derechos",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Se aceptó la solicitud de agregar derechos de forma masiva",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"No se ha podido realizar la solicitud de asignación masiva de derechos",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Proceso de adición masiva de inquilinos completado",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"El proceso de agregar inquilinos en bloque falló",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Solicitud de agregado masivo de inquilinos aceptada",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"La solicitud de agregar inquilinos en bloque falló",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Proceso de incorporación masiva de revendedores completado",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Falló el proceso de incorporación masiva de revendedores",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Se inició el proceso de incorporación masiva de revendedores",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Eliminar relación de revendedor completada",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Error al eliminar la relación de revendedor",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Error en la solicitud de eliminación de relación de revendedor",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Se aceptó la solicitud de eliminación de relación de revendedor y de inquilino",
      "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Se produjo un error al volver a intentar enviar una notificación de webhook",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Se produjo un error al guardar o actualizar los detalles de la notificación personalizada",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Error al guardar los detalles de la notificación del webhook",
      "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Se encontró un error al intentar crear un carrito para la oferta principal a partir de la oferta de prueba.",
      "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Error al actualizar datos preparados para obtener detalles de notificación",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Se produjo un error al enviar la notificación del webhook",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"La URL del webhook proporcionada no es válida",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"No hay productos asignados para cambio de ciclo de facturación. Por favor contacte al equipo de soporte.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Se produjo una excepción al crear un nuevo cliente proveedor, ya que el socio no confirma la aceptación del acuerdo de cliente proveedor.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"Se debe confirmar la aceptación del acuerdo del cliente del proveedor",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Se produjo un error al verificar si el usuario aceptó los términos y condiciones del socio.",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Se produjo un error al obtener el contenido de los términos y condiciones del socio",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Se produjo un error al obtener los detalles de aceptación del consentimiento del cliente del proveedor",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Se produjo un error al obtener clientes del proveedor que no proporcionaron el consentimiento del cliente del proveedor",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Se produjo un error al guardar los detalles de aceptación de los términos y condiciones del socio",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Se produjo un error al guardar el consentimiento del cliente del proveedor",
      "EXISTING":"Existente",
      "EXISTING_REFRESH_TOKEN":"Token de actualización existente",
      "EXPAND_ALL_PAYMENTS":"Expandir pagos",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"¿Está seguro de que desea eliminar este registro?",
      "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"El cheque de vencimiento se eliminó exitosamente",
      "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Los cheques de caducidad se han guardado correctamente.",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Cerrar filtros",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Nombre del cliente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccione Cliente o Revendedor",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Buscar por cliente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Seleccionar cliente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Seleccione un estado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Estado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Reiniciar",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Búsqueda",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Buscar por estado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Abandonado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Desactivado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Error",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Ha fallado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"En progreso",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"Puesto en cola",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Detenido",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Éxito",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Advertencia",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Ciclo de facturación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Cliente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Fecha final",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Error de detalles",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Error de exportación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Estado de exportación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"Mensaje de registro",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Nombre de la operación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":" Precio",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"precio del proveedor",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Fecha de inicio",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Estado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Recuento de estado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Acción",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Historial de suscripciones Fecha",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Estado de validación",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Error de validacion",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Departamento",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Sitio",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Detalle",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Resumen",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Ha fallado",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Éxito",
      "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Inició la actualización de datos locales desde un servicio externo.",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Eliminado",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"No se pudo encontrar ningún detalle del contrato localmente para crear el mapeo.",
      "EXTERNAL_SERVICE_IN_ACTIVE":"Inactivo",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Estado del registro de publicaciones del servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abandonando la carga debido a un error al crear entidades",
      "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"La adición (sin adición de fecha de finalización) no está disponible en ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Falta el ID del acuerdo",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"La facturación no está habilitada para este acuerdo en ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"El acuerdo asignado no está activo en ConnectWise Manage bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"El acuerdo no está presente en ConnectWise Manage bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Los acuerdos no están asignados para este cliente",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Los acuerdos no están presentes en ConnectWise Manage bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Hay adiciones ambiguas, el mapeo no es posible.",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Falta la identificación del contrato",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"La facturación no está habilitada para este contrato en Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"El contrato asignado no está activo en Autotask con este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"El contrato no está presente en Autotask bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"El contrato no está presente en el servicio externo bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"El servicio de contrato no está asignado a este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"El servicio de contrato asignado no está presente en Autotask",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Los servicios de contrato no están mapeados bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Los contratos no están asignados para este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Los contratos no están presentes en Autotask bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Los contratos no están presentes en el servicio externo bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"El cliente no está activo en el servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"El cliente externo no está asignado al cliente c3",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"El cliente no está presente en el servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Falta la asignación de entidad o producto.",
      "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Anteriormente se intentó exportar. El estado de esta operación es",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"El contrato no está activo en el servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Falta el ID de cliente externo",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Los clientes no están presentes en el servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Ocurrió un error al crear una adición en ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Ocurrió un error al crear un ajuste:",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Ocurrió un error al crear el ajuste por cambio de precio",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Ocurrió un error al crear el servicio",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"No se pudo crear el cargo de contrato para un producto de una sola vez",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Ocurrió un error al crear el servicio de contrato:",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Ocurrió un error al actualizar la adición en ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"No se puede crear una asignación porque se encontraron varios servicios coincidentes en Autotask",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"No se pudieron encontrar contratos coincidentes que intentaran mapear automáticamente",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"No se pudo encontrar ningún cliente externo coincidente",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"No se pudo encontrar ningún producto externo coincidente",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"El producto no está asignado a este cliente",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"El producto asignado no está presente en ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Los productos no están mapeados bajo este cliente",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Los productos C3 no tienen servicio externo mapeado",
      "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Falta la identificación del servicio",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del acuerdo.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del acuerdo.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del contrato.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del contrato.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del acuerdo",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del acuerdo",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del contrato",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del contrato",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"La suscripción está en Autotask activo",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"La suscripción está inactiva en el servicio externo",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"No se pudo completar el mapeo automático porque se encontraron múltiples contratos coincidentes",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"No se pudo completar el mapeo automático porque se emparejaron varios clientes externos",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"No se pudo completar el mapeo automático porque se emparejaron varios productos externos",
      "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"La validación no es exitosa",
      "EXTERNAL_SERVICE_NAME":"Nombre del servicio externo",
      "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Seleccionar clientes para validar y cargar",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"ID de lote de publicación de servicio externo",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"volver a la lista",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Error ignorado exitosamente",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Exportar",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validación",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Servicio externo Publicar registros Estado",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Registros de publicaciones de servicios externos",
      "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Prueba de conectividad",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"INSTRUCCIONES",
      "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Haga clic en este botón para probar la conectividad con PSA una vez completada la configuración.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Conectividad establecida con éxito. Puede proceder a cargar registros de suscripciones ahora.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Seleccione al menos un cliente",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"No se pudo conectar con Datto Autotask. Vuelva a verificar sus configuraciones en la página de configuración",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"No se pudo conectar a ConnectWise Manage. Vuelva a verificar sus configuraciones en la página de configuración",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"La fecha de finalización debe ser mayor que la fecha de inicio.",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Seleccione la fecha de finalización",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"La fecha de inicio no puede ser mayor que la fecha de finalización",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Seleccione la fecha de inicio",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Sus datos se están cargando. Puede volver a visitar esta página para obtener más actualizaciones.",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"La validación ha comenzado",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Nota :",
      "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"La clave digital es incorrecta o se le niega el acceso a esta función. La validación de la clave digital ha fallado. Comprueba que estás utilizando la clave digital correcta",
      "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Ya existe un cliente con la referencia de carga que ha proporcionado.",
      "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Reembolso no permitido. Ya se ha procesado un reembolso para esta transacción.",
      "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Su estado es xxxxx y no le permite usar la API.",
      "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"El número de cuenta bancaria no puede estar en blanco",
      "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"No tienes un producto BPay activo",
      "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"No puede cambiar el estado de un cliente que está cancelado.",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Debe proporcionar un valor para el parámetro 'ChangeFromDate' o 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Debe proporcionar un valor para el parámetro 'ChangeFromDate' o 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"El cliente NO está activo.",
      "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"El año/mes de la tarjeta de crédito ya ha vencido.",
      "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"No se pudo encontrar un cliente con los detalles proporcionados.",
      "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Debe proporcionar un valor para el parámetro 'DateField' cuando se proporcionen los valores 'DateFrom' o 'DateTo'.",
      "EZIDEBIT_ERROR_CODE_DATE_FROM":"Debe proporcionar un valor para los parámetros 'DateFrom' o 'DateTo' cuando se proporciona el valor 'DateField'.",
      "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom no puede ser más de xx meses antes de \"DateTo\"",
      "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Debe proporcionar un valor para el parámetro 'DebitDate'",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"La clave digital es incorrecta o se le niega el acceso a esta función. La validación de la clave digital ha fallado. Comprueba que estás utilizando la clave digital correcta",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Simbolo no valido. El token de cliente no es válido para esta clave digital",
      "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Debe proporcionar un valor para el parámetro 'EziDebitCustomerID' o el parámetro 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Esta función no está disponible actualmente ya que se está realizando el procesamiento de Ezidebit. Por favor, inténtelo de nuevo más tarde...",
      "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Cliente no activo (Estado: <Código de estado>). El estado del cliente no es A (Activo) o N (Nuevo)",
      "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Ingresó un número de tarjeta de crédito no válido: su gama de productos no incluye xxxxxxx",
      "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"El Cliente no tiene Fondos suficientes para cubrir este Monto de Ajuste.",
      "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Longitud del número de cuenta no válida",
      "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Valor no válido proporcionado para el parámetro 'ApplyToAllFuturePayments'. Los valores válidos son: 'SI' o 'NO'",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Formato no válido proporcionado para el parámetro 'BankAccountBSB'. Los valores válidos deben tener solo seis dígitos",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Ingresó un número BSB de cuenta bancaria no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Número de cuenta bancaria ingresado no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Se ingresó un nombre de cuenta bancaria no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"BankReceiptId' no se pudo determinar",
      "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Número de tarjeta de crédito ingresado no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Valor no válido proporcionado para el parámetro 'ChangeFromPaymentNumber'. Los valores válidos deben ser numéricos y mayores o iguales a uno.",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Valor no válido proporcionado para el parámetro 'ChangeFromDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Valor no válido proporcionado para el parámetro 'ChangeToDate'. Los valores válidos son cualquier fecha con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Valor no válido proporcionado para el parámetro 'ContractStartDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Valor no válido proporcionado para el parámetro 'DateField'. Los valores válidos son: 'PAGO' o 'LIQUIDACIÓN'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Valor no válido proporcionado para el parámetro 'DateFrom'. Los valores válidos son cualquier fecha con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Valor no válido proporcionado para el parámetro 'DateTo'. Los valores válidos son cualquier fecha con el formato 'AAAAMM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Valor no válido proporcionado para el parámetro 'DayOfMonth'. Los valores válidos deben estar entre 1 y 31.",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Valor no válido proporcionado para el parámetro 'DayOfWeek'. Los valores válidos son: 'LUN', 'MAR', 'MIÉ', 'JUE' o 'VIE'.",
      "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Valor no válido proporcionado para el parámetro 'DebitDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Clave digital no válida.",
      "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Se han introducido datos de domiciliación no válidos: su gama de productos no incluye domiciliaciones.",
      "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Dirección de correo electrónico no válida ingresada",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Se ingresó un mes de vencimiento de la tarjeta de crédito no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"Se ingresó un año de vencimiento de la tarjeta de crédito no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Valor no válido proporcionado para el parámetro 'EzidebitCustomerID'. Los valores válidos deben tener 50 caracteres o menos",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Se proporcionó un valor no válido para el parámetro 'SchedulePeriodType'. Los valores válidos son: '4', 'F', 'H', 'M', 'N', 'Q', 'W' o 'Y'",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Valor no válido proporcionado para el parámetro 'FirstWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"El nombre contiene caracteres no estándar que no están permitidos",
      "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Se proporcionó un valor no válido para el parámetro \"Cuarta semana del mes\". Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'EziDebitCustomerID' y 'YourSystemReference'",
      "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"El apellido contiene caracteres no estándar que no están permitidos",
      "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"La longitud del parámetro 'YourPayerNumber' es mayor que la longitud máxima.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Valor no válido proporcionado para el parámetro 'LimitToNumberOfPayments'. Los valores válidos deben ser numéricos y mayores o iguales a cero.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Se proporcionó un valor no válido para el parámetro 'LimitToTotalAmountInCents'. Los valores válidos deben ser numéricos y mayores o iguales a cero.",
      "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Valor no válido proporcionado para el parámetro 'KeepManualPayments'. Los valores válidos son: 'SI' o 'NO'",
      "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Nombre inválido en la tarjeta de crédito ingresado",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Se proporcionó un valor no válido para el parámetro 'NewPaymentAmountInCents'. Los valores válidos deben ser mayores o iguales a 200 ($2 dólares).",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Valor no válido proporcionado para el parámetro 'NewStatus'. Los valores válidos son: 'A', 'H' o 'C'",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Valor no válido proporcionado para el parámetro 'NewYourSystemReference'. Los valores válidos deben tener 50 caracteres o menos",
      "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Conflicto de parámetros. No puede proporcionar un número mayor que cero para 'LimitToNumberOfPayments' y 'LimitToTotalAmountInCents'",
      "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"El monto del reembolso parcial no puede estar entre el monto del débito programado y el monto del pago total",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Valor no válido proporcionado para el parámetro 'PaymentAmountInCents'. Los valores válidos deben ser mayores o iguales a 200 ($2 dólares).",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Valor no válido proporcionado para el parámetro 'PaymentMethod'. Los valores válidos son: 'ALL', 'DR' o 'CR'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Se ingresó una referencia de pago no válida",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Valor no válido proporcionado para el parámetro 'PaymentSource'. Los valores válidos son: 'TODO', 'PROGRAMADO', 'WEB', 'TELÉFONO' o 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Valor no válido proporcionado para el parámetro 'PaymentType'. Los valores válidos son: 'TODO', 'PENDIENTE', 'EXITOSO' o 'FALLIDO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Número de teléfono móvil ingresado no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Código postal ingresado no válido",
      "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"No se pudo encontrar el pago con la referencia 'xxxxxxx'.",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Valor no válido proporcionado para el parámetro 'RefundAmountInCents'. Los valores válidos son un monto de reembolso entre 100 y el monto de la transacción original",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"El instrumento de pago del reembolso no coincide con el instrumento de pago original",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Valor no válido proporcionado para el parámetro 'RefundAmountInCents'. Los valores válidos son un monto de reembolso entre 100 y el monto de la transacción original",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"El monto del reembolso excede el monto total del pago",
      "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Valor no válido proporcionado para el parámetro 'ScheduleStartDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Valor no válido proporcionado para el parámetro 'SecondWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Valor no válido proporcionado para el parámetro 'SmsExpiredCard'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Valor no válido proporcionado para el parámetro 'SmsFailedNotification'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Valor no válido proporcionado para el parámetro 'SmsPaymentReminder'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Se proporcionó un valor no válido para el parámetro 'ThirdWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Simbolo no valido. El token no es numérico.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Se ingresó un número CCV de tarjeta de crédito no válido: el número CCV debe tener 3 o 4 dígitos",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Valor no válido proporcionado para el parámetro 'CustomerName'. El valor debe tener menos de 80 caracteres.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Valor no válido proporcionado para el parámetro 'CustomerStatus'. Los valores válidos son: 'TODO', 'EN ESPERA', 'PENDIENTE', 'CANCELADO', 'ACTIVO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Valor no válido proporcionado para el parámetro Pedido. Los valores válidos son: 'ASC', 'DESC'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Valor no válido proporcionado para el parámetro OrderBy. Los valores válidos son: 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Valor no válido proporcionado para el parámetro PageNumber. Los valores válidos deben ser numéricos y mayores que cero.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Se ingresó un valor no válido para el parámetro 'Reactivar'. Los valores válidos son 'SÍ' o 'NO'",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Se proporcionó un valor no válido para el parámetro 'YourPayerNumber'. Los valores válidos son enteros positivos.",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Valor no válido proporcionado para el parámetro 'YourGeneralReference'. Los valores válidos deben tener 50 caracteres o menos",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Valor no válido proporcionado para el parámetro 'YourSystemReference'. Los valores válidos deben tener 50 caracteres o menos",
      "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Valor no válido proporcionado para el parámetro 'DebitDate'. El valor válido es cualquier fecha posterior a los treinta y un (31) días anteriores, con el formato 'AAAA-MM-DD'",
      "EZIDEBIT_ERROR_CODE_LAST_NAME":"Debe proporcionar un valor para el parámetro 'LastName'",
      "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Debe proporcionar un valor para el parámetro 'DigitalKey'",
      "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Debe proporcionar un valor para el parámetro 'CustomerStatus'",
      "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Debe proporcionar un valor para el parámetro 'PaymentID' o 'BankReceiptID'. Proporcione un valor para PaymentID o BankReceiptID.",
      "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"No ha proporcionado ningún dato de tarjeta de crédito o cuenta bancaria. No se han facilitado datos obligatorios para la actualización de los datos de facturación",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Debe proporcionar un valor para el parámetro 'customerName'",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Debe proporcionar un valor para el parámetro 'Pedido' cuando se proporciona el valor 'Ordenar por'.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Debe proporcionar un valor para el parámetro 'OrderBy' cuando se proporcione el valor 'Order'.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Debe proporcionar un valor para el parámetro 'YourPayerNumber'",
      "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Debe proporcionar un valor válido para el parámetro 'MobileNumber' si desea que el cliente reciba notificaciones por SMS",
      "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Debe proporcionar un día de la semana al crear un horario Semanal, Quincenal, 4 semanas o Día de la semana en el mes",
      "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Ningún dato coincidió con los parámetros de selección.",
      "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Valor nulo detectado, pase la cadena vacía en lugar de nulo para los parámetros para los que no desea proporcionar un valor",
      "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Debe proporcionar un valor para el parámetro 'PaymentID' o 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'ChangeFromDate' y 'ChangeFromPaymentNumber'",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Conflicto de parámetros. 'ScheduleStartDate' y 'DayOfMonth' no están alineados.",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Conflicto de parámetros. 'ScheduleStartDate' y 'DayOfWeek' no están alineados.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Agregar pago denegado: solo los clientes activos pueden tener pagos agregados a su programación",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Agregar pago denegado: ya tiene un pago registrado con ref xxxxxxx. Una referencia de pago debe identificar de forma única un solo pago.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Agregar pago denegado: este cliente ya tiene xx pagos en esta fecha.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Agregar pago denegado: este cliente está en espera debido a que los detalles de la cuenta bancaria no son válidos y estos detalles de la cuenta no se han cambiado.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Agregar pago denegado: este cliente está en espera debido a que los detalles de la tarjeta de crédito no son válidos y estos detalles de la tarjeta no se han cambiado.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Agregar pago denegado: el estado xx de este cliente no permite agregar nuevos pagos.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Agregar pago denegado: el estado de este cliente de 'xxxxx' no permite agregar nuevos pagos.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"No configurado para pagos con tarjeta de crédito. La cuenta del cliente no está configurada para procesar pagos con tarjeta de crédito en tiempo real para el tipo de tarjeta del cliente",
      "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Debe proporcionar un valor para el parámetro 'PaymentReference'",
      "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"El pago ha sido procesado y no se puede eliminar",
      "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"El pago original aún se está procesando. No se puede reembolsar en esta etapa.",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"No se encontró ningún registro de pago para PaymentId",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"No se encontró ningún registro coincidente para 'PaymentId' o 'BankReceiptId'",
      "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"El monto del reembolso excede el monto total del pago",
      "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"No se permiten devoluciones para el cliente.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Ha pasado tanto la información de la tarjeta de crédito como la de la cuenta bancaria. Solo se pueden pasar los datos de la tarjeta de crédito o de la cuenta bancaria, no ambos.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'PaymentID' y 'BankReceiptID'",
      "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"No se proporcionaron todos los parámetros requeridos",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'FirstWeekOfMonth' no están alineados.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'FourthWeekOfMonth' no están alineados.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'SecondWeekOfMonth' no están alineados.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'ThirdWeekOfMonth' no están alineados.",
      "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"No se pudo encontrar el pago seleccionado para su eliminación.",
      "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"No utiliza un código de facturador Ezidebit BPay compartido.",
      "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Actualización de estado denegada: solo los clientes con un estado de 'A', 'N' o 'H' pueden cambiar usando este servicio.",
      "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"No se puede procesar la actualización - Se ingresó un número CCV de tarjeta de crédito no válido - El número CCV debe tener 4 dígitos para AMEX (WSvc)",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Los datos del informe no están disponibles actualmente ya que se está realizando el procesamiento de pagos. Por favor, inténtelo de nuevo más tarde",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Los datos del informe no están disponibles actualmente ya que el procesamiento de pagos se está realizando actualmente (PT). Por favor, inténtelo de nuevo más tarde...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Los datos del informe no están disponibles actualmente ya que el procesamiento de pagos se está realizando actualmente (SPS). Por favor, inténtelo de nuevo más tarde...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"El sistema no está disponible actualmente. Por favor, inténtelo de nuevo más tarde. No se puede abrir una conexión a la base de datos por alguna razón. Póngase en contacto con partner@ezidebit.com.au para obtener ayuda",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"El sistema no está disponible actualmente. Por favor, inténtelo de nuevo más tarde. Se produjo un error no controlado al extraer los detalles de la base de datos. Póngase en contacto con partner@ezidebit.com.au para obtener ayuda",
      "EZIDEBIT_ERROR_CODE_YES":"Debe proporcionar un valor 'SÍ' para al menos uno de los parámetros de la semana del mes al crear un horario de Día de la semana en el mes.",
      "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Debe proporcionar un valor para el parámetro 'YourGeneralReference'",
      "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Debe proporcionar un valor para el parámetro 'YourSystemReference'",
      "EZIDEBIT_NO_ERROR_DETECTED":"No se detectaron errores",
      "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Error al asignar el cliente al administrador de cuentas",
      "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"No se pudo asignar el revendedor al administrador de cuentas",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Error al actualizar el ciclo de facturación del producto",
      "FAILED_TO_CREATE_ACCOUNT_MANAGER":"No se pudo crear el administrador de la cuenta",
      "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Error al crear un nuevo plan con todas las ofertas",
      "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"No se pudo crear un nuevo plan de revendedor con todas las ofertas",
      "FAILED_TO_DELETE_ACCOUNT_MANAGER":"No se pudo eliminar el administrador de cuentas",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Error al poner en cola la solicitud del plan de clonación",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"No se pudo poner en cola la solicitud para crear un nuevo plan con todas las ofertas",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"No se pudo poner en cola la solicitud para crear un nuevo plan de revendedor con todas las ofertas",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"No se pudieron obtener tokens de acceso mediante el código de autenticación recibido de Quickbooks",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"No se pudieron obtener tokens de acceso mediante el código de autenticación recibido de Xero",
      "FAILED_TO_RESEND_INVOICE":"No se pudo reenviar la factura",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"No se pudo guardar el código de autenticación recibido de Quickbooks",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"No se pudo guardar el código de autenticación recibido de Xero",
      "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Error al cancelar la asignación del cliente de un administrador de cuentas",
      "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"No se pudo desasignar el revendedor de un administrador de cuentas",
      "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Error al actualizar los detalles del administrador de la cuenta",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"General",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Puede actualizar fácilmente las semillas utilizando la función de edición en suscripciones.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Puede otorgar acceso otorgando acceso a la funcionalidad del portal en la vista de usuario.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Sí, puedes actualizar usando nuestro portal.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Sí, puedes suspender la suscripción usando c3.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. ¿Cómo aumentar las semillas de suscripción?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. ¿Cómo dar acceso al portal a un usuario normal?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. ¿Existe alguna opción para actualizar el método de pago usando C3?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. ¿Tiene C3 alguna opción para suspender las suscripciones?",
      "FAQ_HEADING":"Preguntas frecuentes",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Aplicación general",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":" A partir de ahora, C3 está disponible en inglés (en-us), español(es) y taiwanés(tw). Además de esto, C3 admite monedas según las regiones del Centro de socios y la facturación.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Sí, esto se puede lograr en C3 definiendo etiquetas. Debe definir etiquetas (par de valores) y asignarlas a los clientes. Por ejemplo, puede definir una etiqueta denominada SupportAgent y un apodo de un agente (por ejemplo, Agent001) como valor. Si un agente de la mesa de ayuda tiene una de las etiquetas configuradas como SupportAgent y uno de los valores para esta etiqueta en particular como Agent001, el agente podrá ver aquellos clientes que tienen el par de valores de etiqueta {SupportAgent, Agent001} configurado.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Debe ir a Configuración >> Notificación por correo electrónico donde puede configurar C3 para enviar correos electrónicos basados en eventos. C3 le proporciona varios eventos para los cuales puede configurar correos electrónicos. También puede ver el correo electrónico de vista previa según el evento. Puede configurar la notificación por correo electrónico para un evento en el que el cliente compra cualquier oferta. Puede seleccionar el destinatario según su función o sus contactos de facturación. Además, también puede seleccionar la lista CC y BCC. Una vez hecho esto, siempre que ocurra dicho evento, C3 enviará notificaciones por correo electrónico a todos los destinatarios configurados en la configuración de Notificación por correo electrónico.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":" Puede descargar informes de Resumen de impuestos y partidas de impuestos desde el mosaico Resumen de ingresos y costos disponible en el Portal de socios.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Sí, descuentos de soporte C3. Puede crear cupones de descuento y aplicarlos a planes o clientes específicos también. Puede definir diferentes parámetros para un cupón, como el canje máximo, la fecha de inicio de validez, la fecha de inicio de validez y el porcentaje de descuento.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Puede personalizar C3 usando Logos for Branding. C3 le ofrece reemplazar el logotipo de bienvenida en la página de bienvenida y el logotipo de inicio de sesión por el suyo. Incluso puedes personalizar los correos electrónicos de notificación con tu logotipo para mejorar el impacto de la marca.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Sí, podemos realizar los cambios necesarios por nuestra parte para que el cliente acceda a la página de inicio de sesión en lugar de a la página de bienvenida de C3.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"No, aún no estamos certificados. Pero estamos utilizando la configuración del mismo nivel para mantener sus datos seguros y protegidos.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Sí, podemos hacer eso. Le proporcionaremos una dirección IP; deberá crear un registro con la misma donde se administra su DNS. Su proveedor de DNS le ayudará a hacerlo.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"No, puede ir directamente a Configuración y actualizar las credenciales para el Centro de socios o SMTP en caso de algún cambio.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. ¿Cuáles son todos los idiomas y monedas compatibles con C3?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. ¿Puedo hacer que los usuarios de mi lado traten solo con un conjunto de clientes?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":" 2. ¿Cómo configuro C3 para enviar correos electrónicos cuando un cliente realiza una compra? ¿Cómo configuro también los destinatarios de dichos correos electrónicos?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. ¿Qué tipo de informes fiscales están disponibles para descargar?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. ¿C3 admite descuentos?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. ¿Qué tipo de personalización ofrece C3 en términos de apariencia (etiquetado blanco/marca)?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. ¿Existe alguna manera de permitir que mis clientes hagan clic en un enlace de mi portal que los lleve a la página de inicio de sesión en lugar de a la página de bienvenida de C3?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. ¿Cumple C3 PCI?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":" 8. ¿Puedo vincular mi nombre de dominio preferido a C3? ¿Qué se necesita para hacer eso?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. ¿Debo comunicarme con el soporte de C3 cuando las credenciales de mi Centro de socios o SMTP cambiaron o expiraron?",
      "FAQ_PARTNER_CATEGORY_BILLING":"Facturación",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Actualmente, C3 admite cuentas manuales, de tarjeta de crédito, ACH (solo para clientes de EE. UU.) y bancarias (solo para clientes de Australia y Nueva Zelanda).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Sí, los clientes reciben una copia de la factura en formato PDF en un correo electrónico de notificación cuando el socio aprueba la factura.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"En tales casos, puede evitar que C3 cobre a los clientes registrando el pago en la factura en particular. Debe ir a Resumen de ingresos y costos >> Seleccionar cliente >> Facturas. Desde aquí, deberás seleccionar la factura por la que has cobrado el pago. Aquí encontrará una opción como Pago Registrado.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"No admitimos múltiples ciclos de facturación. Pero en este caso, podemos alinear a todos los clientes con un ciclo de facturación particular. Este es un proceso manual. Por ejemplo, su ciclo de facturación actual para un cliente es del 10 de este mes al 9 del próximo mes y en C3, ciclo de facturación del 1 de este mes al 1 del próximo mes. Dado que todos los clientes han realizado todos los pagos hasta el 9 de este mes. Calcularemos el monto correspondiente del 1 al 9 y lo ajustaremos en la próxima factura, lo que resolverá el problema. De manera similar, también podemos hacer lo mismo para otros ciclos de facturación. A partir del próximo mes, todos los clientes estarán alineados al mismo ciclo de facturación.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Sí, podemos realizar ajustes respecto a los pagos en la factura correspondiente. Por el momento no existe una versión automatizada. Podemos encargarnos de esto.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Ahora admitimos Bill&Pay, Stripe, EziDebit, Authorize.net y Cybersource. Si está utilizando otros que no sean estos, definitivamente podemos buscar la integración de su pasarela de pago con C3.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 está diseñado para admitir varias pasarelas de pagos como Bill&Pay, Stripe, EziDebit, Authorize.net y CyberSource hasta el momento. Sería fantástico si utilizara cualquiera de ellos como su pasarela de pago existente; de lo contrario, podemos intentar diseñar C3 para admitir sus pasarelas de pago.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Actualizamos las ofertas de C3 con la última oferta de Microsoft cada mes. En caso de cambio de precio, deberá crear un nuevo plan o actualizar el plan existente con nuevos precios de oferta. De forma predeterminada, C3 aceptará un nuevo precio para un nuevo cliente. Pero para los clientes existentes, puede establecer un nuevo precio para la oferta suscrita actualmente. Además, puede elegir la fecha de vigencia de los nuevos precios, como con efecto inmediato en el cambio de precio, el próximo ciclo de facturación o la próxima fecha de renovación.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"En C3, las reglas fiscales se definen mediante combinaciones de {País, Provincia estatal y Código fiscal}, {País, Código postal} o {País, Provincia estatal y Código postal}. Por ejemplo, la regla fiscal definida para País: EE. UU. y dejar la provincia y el código postal en blanco significa que la tasa impositiva se aplica a todos los clientes de EE. UU. Si desea definir una regla para un estado en particular, proporcione los valores de País y Estado al definir la regla.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Sí, usted puede hacer esto. Debe ir a Configuración >> Impuestos y crear una regla de impuestos con un porcentaje de impuestos del 0%. Aplíquelo al cliente al que desea eximir de la regla fiscal (aplicable a otro conjunto de clientes).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Puede decidir e informarnos la fecha de generación de la factura. Lo configuraremos por usted y podrá verificarlo yendo a Configuración de socio >> Facturación. Aquí puedes ver todas las fechas relacionadas con Billings. Incluso usted puede cambiar dicha fecha para un cliente específico yendo a la configuración del cliente.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3 le permite decidir las fechas en las que su cliente debería poder ver la factura. Después de generar la factura, el socio debe aprobarla (tras realizar cualquier ajuste si es necesario) para que esté disponible para los clientes.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Sí, C3 le brinda la opción de realizar cambios en las líneas de pedido de la factura después de generarla. Debe realizar el cambio requerido (por ejemplo, desea agregar una tarifa única/exención) según sus requisitos antes de la fecha de finalización de la factura. No puede realizar ningún cambio en la factura una vez finalizada.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"En C3, podemos configurar la notificación por correo electrónico para dichos eventos. En caso de éxito o fracaso del pago, C3 enviará un correo electrónico para informarle. Todos los destinatarios configurados para dichos eventos recibirán correos electrónicos de notificación. Además, C3 tiene una función de incumplimiento de pago en Resumen de ingresos y costos donde el socio puede realizar un seguimiento de los registros de cualquier incidente de incumplimiento de pago.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. ¿Cuáles son los métodos de pago admitidos por C3?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. ¿Recibe el cliente una copia de la factura en formato pdf cuando la factura esté lista?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. ¿Qué pasa si cobré un pago mediante cheque o efectivo y el método de pago del cliente es tarjeta de crédito? ¿Cómo evito que C3 le cobre al cliente la factura actual?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Hemos estado utilizando nuestros propios sistemas de facturación y hemos cobrado a nuestros clientes hasta la fecha. Nuestros clientes tenían diferentes ciclos de facturación. ¿Qué tipo de soporte obtenemos cuando migramos a C3?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. ¿Existe alguna forma de gestionar los pagos por adelantado contra una factura?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. ¿Cuáles son las pasarelas de pago que C3 admite de forma inmediata?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. ¿Puedo utilizar mi proveedor de pasarela de pago actual o debería utilizar el que viene con C3?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. ¿Qué sucede cuando los precios de Microsoft cambian y queremos actualizar nuestros precios para nuestros clientes?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. ¿Cómo aborda C3 los impuestos?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. ¿Puedo eximir a un cliente en particular de una norma fiscal que se aplica a otro grupo de clientes?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. ¿Cómo defino cuándo C3 debe generar facturas?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. ¿Mi cliente podrá ver las facturas inmediatamente después de que se generen?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. ¿Tengo la opción de realizar cambios en las líneas de la factura después de generarla? ¿Puedo agregar cambios relacionados con una tarifa única/exención a una factura?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. ¿Cómo sé yo (socio) si un pago se realizó correctamente o no?",
      "FAQ_PARTNER_CATEGORY_SELL":"Vender",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":" Un plan es un grupo de ofertas que puede vender a su cliente al precio que usted elija. Debe tener un plan para incorporar o crear un cliente.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":" Sí, C3 le ofrece la función de crear ofertas de socios. Por ejemplo, desea brindar soporte 24 horas al día, 7 días a la semana a sus clientes. Luego, puede crear una oferta para socios de soporte Office365 24 horas al día, 7 días a la semana y venderla al precio que elija.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Sí, puede incorporar cualquier cliente existente en el Centro de socios a C3. Debe tener un ID de Microsoft (obtenerlo en el Centro de socios) y un plan que tenga la oferta correspondiente a la oferta suscrita en PC. Aparte de esto, C3 también le ofrece la función de crear un nuevo cliente directamente desde C3 (no es necesario ir a la PC para crear un cliente).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Para lograr esto, debemos incorporar a dicho cliente a cualquier plan. Puede poner la cantidad de oferta como Cero. Una vez que el cliente se haya incorporado correctamente, se sincronizarán automáticamente las suscripciones basadas en el uso para ese cliente en particular.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":" En tales casos, habrá una discrepancia en la cantidad entre PC y C3. Nuestro equipo puede solucionar estos desajustes.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Podemos hacer ajustes en las facturas, pero a partir de ahora, C3 no admite reembolsos.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Sí, C3 calcula el uso de los clientes y, en función del consumo de datos, genera la factura.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Sí, puede crear un cupón de descuento con el porcentaje de descuento requerido y las recurrencias máximas establecidas en 3. Ahora asigne el cupón recién creado a un cliente específico. Una vez hecho esto, el cliente obtendrá el descuento aplicable para las siguientes tres facturas.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. ¿Qué es un plan?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. ¿Puedo vender ofertas que no estén basadas en Microsoft, como servicios personalizados?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. ¿Puedo incorporar a un cliente existente?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. ¿Cómo puedo incorporar a un cliente que solo tiene suscripciones basadas en el uso?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mi equipo compró licencias accidentalmente a través de una PC, ¿cuáles son las repercusiones de tales acciones? ¿Existe un proceso para solucionar tales discrepancias?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. ¿C3 admite reembolsos?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. ¿C3 maneja la facturación de Azure por el uso de mis clientes?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. Tengo un cliente incorporado a C3 y quiero ofrecer un descuento para las próximas tres facturas, ¿puedo hacerlo?",
      "FEATURE_COMMENTS_ORDER_NO":" N° de pedido:",
      "FEBRUARY":"Febrero",
      "FIELD_REQUIRED":"{{atributo}} es obligatorio",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"Seleccione",
      "FILTER_SELECT_A_TARGET_CURRENCY":"Seleccione",
      "FIX_AUTO_RENEW_STATUS_MISMATCH":"Arreglar el desajuste de estado de renovación automática",
      "FIX_AZURE_MISSING_OFFER":"Arreglar la oferta faltante de Azure",
      "FIX_BILLING_CYCLE_MISMATCH":"Solucionar el desajuste del ciclo de facturación",
      "FIX_MISSING_OFFER":"Arreglar oferta faltante",
      "FIX_OFFER_MISMATCH":"Solucionar el desajuste de la oferta",
      "FIX_QUANTITY_MISMATCH":"Solucionar discrepancia de cantidad",
      "FIX_SCOPE_MISMATCH":"Corregir discrepancia en el alcance",
      "FIX_STATUS_MISMATCH":"Solucionar el desajuste de estado",
      "FIX_TERM_DURATION_MISMATCH":"Corregir el desajuste de la duración del plazo",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Reservados todos los derechos.",
      "FOOTER_TEXT_1_POWERED_BY":" Energizado por",
      "FROM":"De",
      "General":"General",
      "GENERAL_SETTINGS_MAIN_ERROR":"Por favor corrija los errores en el formulario antes de enviarlo.",
      "GENERATE_INVOICE_REASON":"Razón",
      "GENERATE_INVOICE_REASON_PLACEHOLDER":"Proporcione una razón",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Tokens de acceso a Quickbooks generados mediante el código de autenticación recibido de Quickbooks",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Tokens de acceso de Xero generados mediante el código de autenticación recibido de Xero",
      "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Ocurrió un error al recuperar los detalles del proveedor de facturación.",
      "GET_CURRENCY_CODES_EXCEPTION":"Se produjo un error al obtener los códigos de moneda.",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al realizar la conversión de moneda.",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Se produjo un error al obtener la lista de conversiones de moneda.",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Se produjo un error al obtener las opciones de moneda.",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Se produjo un error al obtener los eventos del cliente para las notificaciones por correo electrónico",
      "GET_CUSTOMER_LIST_EXCEPTION":"Se produjo un error al obtener clientes.",
      "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Ocurrió un error al obtener los planes del cliente con la promoción activa",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Se produjo un error al obtener la lista de destinatarios de notificaciones por correo electrónico",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Se produjo un error al recibir los eventos para las notificaciones por correo electrónico",
      "GET_PARTNER_LOGO_EXCEPTION":"Se produjo un error al obtener el logotipo del socio",
      "GET_PARTNER_SETTINGS_EXCEPTION":"Se produjo un error al obtener la configuración del socio",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al obtener los detalles de impuestos.",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Se produjo un error al obtener los tipos de impuestos.",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Se produjo un error al obtener las monedas de destino",
      "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Ocurrió un error al obtener la solicitud de relación con el revendedor.",
      "GOT_RESELLER_DETAILS":"Obtuve el revendedor de CSP con nombre: {{resellerName}}",
      "government":"Gobierno",
      "Government":"Gobierno",
      "GRAND_ACCESS_USER_EXCEPTION":"Este usuario ya tiene acceso por favor consulte con otro",
      "GRAND_TOTAL":"Gran total",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Consumo por producto",
      "GRAPH_CUSTOMERS_ONBOARDED":"Clientes incorporados",
      "GRAPH_DATA_BILLED_AMOUNT":"Cantidad facturada",
      "GRAPH_DATA_QUANTITY":"Cantidad comprada",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Ingresos por cliente",
      "GRAPH_TOP_SELLING_PRODUCT":"Los 10 productos más vendidos",
      "GREATER_THAN_ERP":"mayor que el ERP",
      "GRID_DOWNLOAD_COUPON_GRID":"Descargar cupones",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Ya existe un administrador de grupo con este correo electrónico en este grupo.",
      "GROUP_USERS_OF":" Usuarios del grupo de",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Usuarios del grupo",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Ver la aplicación en un idioma compatible con la aplicación.",
      "HEADER_CONTENT_HELP_MENU":"Contenido de ayuda sensible al contexto presentado en la barra lateral derecha",
      "HEADER_CONTENT_MENU_CONTACT_LOG":"Permite al socio ver el estado de todas las notificaciones y el contenido del correo electrónico.",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Seguimiento de la actividad operativa del usuario.",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Detalle básico del perfil del usuario que ha iniciado sesión",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Administre la configuración de su cuenta C3 y acceda a los registros de actividad.",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
      "HEADER_CONTENT_SIGNIN_USER":"Detalle básico del perfil del usuario que ha iniciado sesión",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Contáctenos",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Cerrar sesión",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Usuario no autorizado",
      "HEADER_GO_TO_PROFILE":"Ir al perfil",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Contáctenos",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Detener la suplantación",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Usuario no autorizado",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Idioma",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Idioma",
      "HEADER_TITLE_HELP_MENU":" Ayuda",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Notificaciones",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Registro de auditoría",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Perfil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Ajustes",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Ajustes",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Ajustes",
      "HEADER_TITLE_SIGNIN_USER":"Usuario registrado",
      "HEADING_ENTITY_LIST":"{{EntityName}} lista",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Proporcione la dirección de correo electrónico para recibir el correo electrónico de prueba.",
      "HIGH":"Alto",
      "HOURS":"Horas)",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"aquí",
      "I have read and agreed to":"He leído y acepto",
      "IF_ALREADY_EXISTS":" ya existe",
      "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Se usa para habilitar/deshabilitar los filtros en la página de inicio de registro público",
      "IGNORE_FROM_PSA_TEXT_DISP":"Ignorar al cliente del servicio externo",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Junto con su privilegio actual",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Sin tu privilegio actual",
      "IMPORT_LIST":"Importar lista",
      "In Progress":"En progreso",
      "IN_ACTIVE":"Inactivo",
      "INDEX_LOGOUT_APOLOGY_MESSAGE":"Este programa estuvo inactivo durante demasiado tiempo. Así que nos disculpamos por desconectarte, pero no teníamos otra opción.",
      "INDEX_LOGOUT_MESSAGE":"Lo sentimos, has cerrado sesión",
      "INDEX_MSG_LOGOUT_SOON_MESSAGE":"El modo inactivo está activado porque usted está inactivo durante demasiado tiempo. Como resultado, cerrará sesión en unos momentos.",
      "INDXE_LOGOUT_TEXT":"¡HAZ ALGO RÁPIDO! Tu sesión se cerrará en {{countdown}} segundo(s).",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"No hay monedas disponibles registradas",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"No hay estados disponibles registrados",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Por favor seleccione un país",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Seleccione primero la moneda de origen",
      "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"¿Está seguro de que desea iniciar el cargo de la factura para <%= currencySymbol%><%= factureAmount%> usando el método de pago: <br> <b><%= PaymentType%> | <%= NúmeroCuenta%></b> ? <br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o que se registraron por error. </pequeño>",
      "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"No hay cantidad pendiente de pago.",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Todas las facturas seleccionadas estarán en cola para pagos. ¿Quieres continuar?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"El estado de la factura cambiará a <%= factureStatus%>. ¿Quieres continuar?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Al cliente se le cobrará $<%= importe%>. ¿Quieres continuar?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Al revendedor se le cobrará $<%= monto%>. ¿Quieres continuar?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodTypeBillingPeriodType",
      "INPUT_CATEGORYID":"Categoria ID",
      "INPUT_CHANGE_AUTO_RENEW_STATUS":"Estado de renovación automática",
      "INPUT_CHANGE_TYPE":"Tipo de cambio",
      "INPUT_CONSUMPTIONTYPEID":"ID de tipo de consumo",
      "INPUT_CUSTOMERC3ID":"ClienteC3Id",
      "INPUT_CUSTOMERID":"Identificación del cliente",
      "INPUT_CUSTOMERNAME":"Nombre del cliente",
      "INPUT_DATA":"Entrada del usuario",
      "INPUT_DESCRIPTION":"Descripción",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"ID de producto interno",
      "INPUT_INVOICEID":"ID de factura",
      "INPUT_LABEL_PURCHASE_QUANTITY":"Cantidad de compra",
      "INPUT_NAME":"Nombre",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"Número de páginas",
      "INPUT_PAGEINDEX":"Índice de página",
      "INPUT_PAGESIZE":"Tamaño de página",
      "INPUT_PLANC3ID":"Tartán",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"PrecioparaSocio",
      "INPUT_PRODUCTID":"ID del Producto",
      "INPUT_PROVIDERID":"ID de proveedor",
      "INPUT_PROVIDERSELLINGPRICE":"ProveedorPrecio De Venta",
      "INPUT_QUANTITY":"Cantidad comprada",
      "INPUT_REACTIVATE":"Reactivar",
      "INPUT_SALETYPE":"Tipo de venta",
      "INPUT_STARTIND":"InicioInd",
      "INPUT_SUSPEND":"Suspender",
      "INPUT_TENANTID":"ID de inquilino",
      "INPUT_WITHADDONS":"ConComplementos",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"volver al carrito",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Volver a Pedidos",
      "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Perfil de pago",
      "INSTANT_PAY_BUTTON_TEXT_PAY":"Pagar",
      "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"La pasarela de pago actual aún no es compatible con el pago instantáneo. Póngase en contacto con el soporte para obtener ayuda.",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Método de pago",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Pago seguro",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Cargue la hoja mapeada para mapear en formato .csv. Descargue el archivo <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para cargarlo.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Este archivo se utiliza para asignar las entidades entre C3 y AutoTask Manage. No elimine ningún texto o datos existentes en este archivo.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Los únicos campos que necesitan actualización son \"AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId y ContractServiceName\". El resto de los campos están ahí para ayudar a identificar las contrapartes C3 para el mapeo.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Si los campos mencionados anteriormente están completos en la hoja, el mapeo está disponible, si los campos mencionados anteriormente están vacíos, entonces el mapeo es obligatorio.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Para completar la hoja de mapeo, puede consultar <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"El nombre y el ID entero (numérico) necesarios para completar la <b> hoja de mapeo</b> están disponibles en la <b>hoja de entidad AutoTask.</b>",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"La eliminación del mapeo no es posible a través de la hoja de mapeo. Para cambiar la asignación de acuerdo y producto, utilice la hoja de asignación. La reasignación de clientes solo es posible a través de la pantalla de asignación del producto comprado.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
      "INSTRUNCTION_PSA_BULK_BUTTON":"Instrucciones para la carga masiva de mapeo de productos comprados:",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Cargue la hoja mapeada para mapear en formato .csv. Descargue el archivo <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para cargarlo.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Este archivo se utiliza para asignar las entidades entre C3 y ConnectWise Manage. No elimine ningún texto o datos existentes en este archivo.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Los únicos campos que necesitan actualización son \"ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId y ConnectwiseProductname\". El resto de los campos están ahí para ayudar a identificar las contrapartes C3 para el mapeo.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Si los campos mencionados anteriormente están completos en la hoja, el mapeo está disponible, si los campos mencionados anteriormente están vacíos, entonces el mapeo es obligatorio.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Para completar la hoja de mapeo, puede consultar la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"El nombre y el ID entero (numérico) necesarios para completar la <b> Hoja de mapeo</b> están disponibles en la <b>Hoja de entidad de Connectwise.</b>",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"La eliminación del mapeo no es posible a través de la hoja de mapeo. Para cambiar la asignación de acuerdo y producto, utilice la hoja de asignación. La reasignación de clientes solo es posible a través de la pantalla de asignación del producto comprado.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Para facilitar la asignación entre los productos C3 y ConnectWise Manage, cargue la hoja de asignación en formato .csv. Puede descargar el archivo <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para actualizarlo.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"El archivo sirve para establecer asignaciones de productos entre C3 y ConnectWise Manage. Asegúrese de no eliminar ningún texto o dato existente dentro del archivo.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Solo es necesario actualizar los campos ConnectwiseProductId y ConnectwiseProductname. Otros campos ayudan a identificar las contrapartes de C3 para fines de mapeo.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Si los campos mencionados están completos, el mapeo está completo. Si están vacíos, es necesario realizar el mapeo.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Consulte la <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Hoja de entidades de Connectwise.xlsx.</a> para obtener instrucciones sobre cómo completar la hoja de mapeo. Los nombres y los identificadores enteros (numéricos) requeridos están disponibles en la Hoja de entidades de ConnectWise.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Tenga en cuenta que no es posible eliminar asignaciones mediante la hoja de asignación. Para modificar las asignaciones de productos, utilice la hoja de asignación proporcionada.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Sube el archivo y haz clic en ENVIAR para finalizar el proceso.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON":"Instrucciones para usar el botón Actualizar:",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Haga clic en el botón Actualizar para obtener los datos más recientes de PSA a C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"De forma predeterminada, al principio no habrá datos disponibles en Product Mapping. Haga clic en el botón Actualizar para obtener todos los datos disponibles de PSA a C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Cuando \"Actualizar\" está en progreso, el sistema desactivará otras pestañas. Si tiene éxito, las pestañas deshabilitadas volverán a ser visibles. En caso de error, esta pantalla proporcionará una opción para ignorar el error. Puede hacer clic en el botón ignorar error para habilitar las pestañas deshabilitadas.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Cuando \"Actualizar\" está en progreso, el sistema eliminará los datos de PSA existentes de C3 e insertará datos nuevos de PSA.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Si se realiza algún cambio en PSA, por ejemplo, adición o eliminación de empresa, acuerdo, productos o cambio en el estado de estas entidades, el usuario debe actualizar la asignación de productos para reflejar esos cambios en C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Haga clic en el botón </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\"> Actualizar</a> para obtener los datos más recientes. de PSA a C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" Botón para obtener los últimos datos de PSA a C3.",
      "INTERNAL_BILLING_ID":" ID de referencia de facturación:",
      "INTERNAL_SCHEDULE_RENEWAL_ID":"ID de renovación programada interna",
      "INTERNAL_SCHEDULED_RENEWAL_ID":"ID de renovación interna programada",
      "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"No se permite el cambio de estado de renovación automática",
      "INVALID_ATTEMPT_DECREASE":"No se permite disminuir la cantidad",
      "INVALID_ATTEMPT_REACTIVATE":"No se permite reactivar el producto.",
      "INVALID_ATTEMPT_SUSPEND":"No se permite suspender el producto.",
      "INVALID_COUNT":"El total de asientos no puede ser inferior a 1",
      "INVALID_COUPON":"Este vale no es válido",
      "INVALID_EXPIRY_DATE":"Fecha de caducidad no válida",
      "INVALID_INPUT":"Entrada inválida",
      "INVALID_MESSAGE_ENTITY":"Entidad de mensaje no válida",
      "INVALID_MESSAGE_TYPE":"Tipo de mensaje no válido",
      "INVALID_PORTAL_PAGE":"Página del portal no válida",
      "INVALID_PRODUCT_ID":"Un producto con el identificador dado no se encuentra en el sistema.",
      "INVALID_QUANTITY":"Cantidad no válida",
      "INVALID_RECIPIENTS":"Destinatarios no válidos",
      "INVALID_SEATS":"Detalle faltante o inválido. Los asientos deben ser un número positivo o negativo, limitado por las restricciones del proveedor",
      "INVALID_VALUE":"{{atributo}} no es válido",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Volver a invitaciones",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Agregar invitación",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Editar invitación",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Limpiar todo",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Artículos seleccionados",
      "INVITES_ADD_FORM_LABEL_OFFER":"Ofertas",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":" Precio",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Descripción",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Nombre",
      "INVITES_ADD_FORM_URL_INVITE_URL":"URL de invitación",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"invita",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Agregar nueva invitación",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"invita",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Editar",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Descripción de la invitación",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Nombre de la invitación",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
      "INVITES_SAVE_SUCCESS":"Invitación guardada exitosamente",
      "INVITES_SELECT_OFFERS_PROMPT":"Por favor incluya al menos una oferta en la invitación.",
      "INVITES_UPDATE_SUCCESS":"Invitación actualizada con éxito",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Fecha final",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Fecha de inicio",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"¿Quiere que esto se adeude como un ajuste en esta factura?",
      "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de facturas y cuotas.",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Panel",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Facturas",
      "INVOICE_COMMENTS":"Factura",
      "INVOICE_COUPON_APPLIED_MESSAGE":"Se aplica el cupón de descuento <strong>{{CouponCode}}*</strong>",
      "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Saldo adeudado",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Seleccionar todo",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"El correo electrónico se envía correctamente a la lista de usuarios.",
      "INVOICE_FOOTER_LINE_DESC":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
      "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
      "INVOICE_FOOTER_LINE_DISP":"Línea de pie de página de factura",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Línea de pie de página de factura",
      "INVOICE_GENERATION_REASON":"Motivo de generación de la factura",
      "INVOICE_GENERATION_STATUS_TITLE":"Estado de generación de factura",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Ir al resumen de ingresos y costos",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Descargar facturas/Pagos",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Volver al resumen de ingresos y costos",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Descargar facturas/Pagos",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importación de facturas de clientes exitosa",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Seleccione al menos una factura de cliente antes de continuar.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Facturas para descargar para los clientes.",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Código de moneda",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Monto de la factura",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"ID de Microsoft",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"nombre calificado",
      "INVOICE_INVOICE_APPROVE":"Aprobar",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Volver a partidas de factura",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Volver a Facturas",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Descargar factura",
      "INVOICE_INVOICE_CAPTION_TEXT":"Factura para",
      "INVOICE_INVOICE_FINALIZE":"Finalizar",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Resumen de la cuenta",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Dirección",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Cliente",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Resumen de factura",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":" Período de facturación",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":" Fecha de la factura",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"Número de valor agregado",
      "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Factura fiscal",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Detalle de factura",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Cuotas de factura",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Saldo de pagos pasados",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Basado en licencia",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Basado en uso",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":" Cantidad",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Tipo de carga",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Costo en socio",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Código promocional",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Descripción",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Descuento",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Importe de descuento",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Precio de oferta",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Cantidad comprada",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Fecha final",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"gran total",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Importe de la línea de factura",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Informe detallado",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":" Importe después del impuesto",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Monto subtotal",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Inquilino",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Fecha de inicio",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Importe del impuesto",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Porcentaje de impuesto",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Precio unitario",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"No se encontraron facturas. Por favor contacte al soporte de c3.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Pagos de facturas",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Monto",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Fecha",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Motivo del fracaso",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"gran total",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Observaciones",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Estado",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Cantidad debida",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Fecha de vencimiento",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Fecha de la factura",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Estado",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Monto de pago",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Cantidad restante",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Cantidad utilizada",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Ajustes",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Prorrateo de instancia de ciclo",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Cuotas de facturas pasadas",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Tarifa prorrateada",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Tarifas prorrateadas al comprar",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Tarifa de compra",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"cantidad devuelta",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Cuota de renovación",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Impuestos",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Cantidad de uso",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Crédito",
      "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--vinculado a {{linkedProductName}}",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>{{providerName}} Inquilino </b> {{providerTenantName}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Tasa de conversión",
      "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Total parcial",
      "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Elementos de línea de factura con impuestos",
      "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Descuento promocional",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Comportamiento",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Correo electrónico",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Informe de partidas individuales de facturas",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de partidas individuales de la factura",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Ingrese la dirección de correo electrónico a quién desea enviar el correo electrónico, ingrese con coma (,) o punto y coma (;) y haga clic en enviar para recibir un archivo que contiene una lista del informe de partidas individuales de la factura.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de partidas individuales de facturas.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Cancelar",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Guardar favoritos",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Facturas",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Pagos",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Generado en",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Razón del fracaso",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Cantidad restante",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Monto de pago",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Fecha de pago",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"ID de transacción",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Observaciones",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Estado de pago",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Cantidad utilizada",
      "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Ver registros de contactos",
      "INVOICE_NUMBER":"Número de factura",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importación exitosa de pagos de facturas de clientes",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Pagos de facturas para descargar para los clientes.",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
      "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"No se puede actualizar la fecha de vencimiento con un valor nulo",
      "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"Propiedad de la factura actualizada con éxito",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"La fecha de inicio no debe ser mayor que la fecha de finalización",
      "INVOICE_SENT_SUCCESSFULLY":"Factura enviada exitosamente",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Aprobado",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Finalizado",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"En progreso",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Generado",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Publicado",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Pagado",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Parcialmente pagado",
      "INVOICE_TAB_NAME_COMMENTS":"Comentarios",
      "INVOICE_TAB_NAME_DETAILS":"Detalles",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Notificaciones",
      "INVOICE_TRANSACTION_REPORT_HEADER":"Informe de facturas y cuotas",
      "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
      "INVOICE_WILLBE_MAILED":"La factura se enviará por correo a su dirección de facturación. Gracias.",
      "InvoiceCurrency":"Moneda de factura",
      "INVOICES_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Período de facturación",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento de la factura",
      "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Fecha de generación de la factura",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Es manejable sólo por socio.",
      "IS_MASTER":"Maestro",
      "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"¿Se requiere la aprobación del socio para los clientes revendedores?",
      "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"¿Se está reactivando el producto con Addons?",
      "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"¿Está disponible el filtro para el cliente?",
      "IS_TRIAL_OFFER":"oferta de prueba",
      "IsCustomPrice":"¿Es precio personalizado?",
      "JANUARY":"Enero",
      "JULY":"Julio",
      "JUNE":"Junio",
      "LABEL_ASSIGNED_RG":"Grupos de recursos asignados",
      "LABEL_BANNER_TEXT":"Mensaje",
      "LABEL_BANNER_TEXT_TOOL_TIP":"Escribe el mensaje para el banner.",
      "LABEL_BILLING_ADDRESS":"Dirección de Envio",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"La oferta de prueba solo se puede comprar una vez.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Esta oferta de prueba ya está disponible en su carrito.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"oferta de prueba para",
      "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Oferta de prueba para padres no disponible",
      "LABEL_Customer_Name":"Nombre del cliente",
      "LABEL_CUSTOMER_NAME":"Nombre del cliente",
      "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"Se seleccionaron productos del plan.",
      "LABEL_DESC_SELECTED_PLANS":"se eligieron los planes",
      "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"Se seleccionaron variantes del producto.",
      "LABEL_EFFECTIVE_ENTITY":"Entidad Relevante",
      "LABEL_EFFECTIVE_FROM":"Válido desde",
      "LABEL_EFFECTIVE_RECORD_NAME":"Registro Efectivo",
      "LABEL_EFFECTIVE_TO":"Expira el",
      "LABEL_IS_ACTIVE":"Está activo",
      "LABEL_NOTIFICATION_RECIPIENTS":"Destinatarios de la notificación",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Introduzca los usuarios como valores separados por comas",
      "LABEL_NOTIFICATION_TABLE":"Notificaciones de pancartas",
      "LABEL_NOTIFICATION_TYPE":"Estilo de mensaje",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":" Asignar política para",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":" Asignaciones de políticas de",
      "LABEL_POLICY_CUSTOMER":"Cliente",
      "LABEL_POLICY_DEFINITION":"Definición de política",
      "LABEL_POLICY_DESCRIPTION":"Descripción de la política",
      "LABEL_POLICY_NAME":"Nombre de directiva",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
      "LABEL_POLICY_SUBSCRIPTION":"Suscripción",
      "LABEL_PORTAL_PAGE":"página del portal",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"agregar rol personalizado",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Volver a roles personalizados",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Agregar rol personalizado",
      "LABEL_ROLE_CUSTOMER":"Cliente",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
      "LABEL_ROLE_ROLE_DEFINITION":" Definición de rol",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Descripción del rol",
      "LABEL_ROLE_ROLE_NAME":"Nombre de rol",
      "LABEL_ROLE_SUBSCRIPTION":"Suscripción",
      "LABEL_SHIPPING_ADDRESS":"Dirección de envío",
      "LABEL_TEXT_ADDONS":"Complementos",
      "LABEL_TEXT_ADDRESS_1":"Dirección 1",
      "LABEL_TEXT_ADDRESS_2":"Dirección 2",
      "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Disponible para el cliente",
      "LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "LABEL_TEXT_BILLING_TYPE":"Tipo de facturación",
      "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" El plazo de cancelación vence en",
      "LABEL_TEXT_CATEGORY":"Categoría",
      "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Crear cuenta de Microsoft",
      "LABEL_TEXT_DISCOUNT":"Descuento",
      "LABEL_TEXT_DISTRIBUTER_OFFER":"Ofertas de distribuidores",
      "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Habilitar todas las promociones",
      "LABEL_TEXT_LINKED_PRODUCT":"Producto vinculado",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Categoría :",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"software perpetuo",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Suscripciones de software",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Término",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":" Término -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Término :",
      "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"No disponible para el cliente",
      "LABEL_TEXT_OWNER_NAME":"Nombre del dueño",
      "LABEL_TEXT_PRICE":" Precio",
      "LABEL_TEXT_RESERVED_INSTANCES":"Rhode Island",
      "LABEL_TEXT_RETAIL_PRICE":"Precio al por menor",
      "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Suscripción de origen",
      "LABEL_TEXT_STATE_PROVINCE":"Provincia del estado",
      "LABEL_TEXT_STATUS":"Estado",
      "LABEL_TEXT_TARGET_SUBSCRIPTION":"Suscripción objetivo",
      "LABEL_TEXT_TAX_CODE":"Código de impuestos",
      "LABEL_TEXT_TAX_TYPE":"Tipo de impuesto",
      "LABEL_TEXT_TENANT_NAME":"Nombre del inquilino",
      "LABEL_TEXT_TYPE_IN_NAME":"Escribe un nombre",
      "LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "LABEL_TEXT_ZIP_CODE":"Código postal",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Nuevo",
      "LABEL_UNASSIGNED_RG":"Grupos de recursos no asignados",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Cargos adicionales",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Administrador de clientes",
      "LABLE_CUSTOMER_READER_ROLE":"Lector de clientes",
      "LABLE_DISCOUNTS_TOTAL":"Descuento total",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Total de suscripciones",
      "LABLE_TAXES_TOTAL":"Impuestos Totales",
      "LABLE_TEXT_COST_PRICE":"Precio de coste",
      "LABLE_TEXT_COST_TO_PARTNER":"Costo para el socio",
      "LABLE_TEXT_DISPLAY_NAME":"Nombre para mostrar",
      "LABLE_TEXT_EVENTS":"Eventos",
      "LABLE_TEXT_FRIENDLY_NAME":"Nombre amigable",
      "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"¿Disponible para el cliente?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"¿Promoción disponible para el cliente?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"¿Promoción disponible para revendedores?",
      "LABLE_TEXT_MAXVALUE":" Valor máximo :",
      "LABLE_TEXT_MINVALUE":" Valor mínimo:",
      "LABLE_TEXT_OFFER_NAME":"Nombre de la oferta",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Precio de venta del proveedor",
      "LABLE_TEXT_SALE_PRICE":"Precio de venta",
      "LABLE_TEXT_SALEPRICE":" Precio de venta :",
      "LABLE_TEXT_SLAB":"Detalles de losa",
      "LABLE_TEXT_SLAB_SALE_PRICE":"Precio de venta (UMN)",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Costo para el socio",
      "LABLE_TEXT_TYPE":"Tipo de facturación",
      "LABLE_TEXT_UNIT_OF_MEASURE":"(UMN)",
      "LABLE_WAIVER":"Exención",
      "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"El último intento de cargar los datos resultó en un error. Haga clic en actualizar para sincronizar los últimos cambios de PSA a C3.",
      "LATEST_COMMENTS":"Último comentario",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Acceso",
      "LAYOUTLOGIN_HEADER_TEXT":"Acceso",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Por favor inicie sesión para continuar",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Sistemas Spektra. Reservados todos los derechos.",
      "LBL_COUPON_CODE_TEXT":"Código promocional",
      "LBL_DISCOUNT_TEXT":"descuento",
      "LBL_OFF":"apagado",
      "LBL_TEXT_NOT_PROVIDED":"No provisto",
      "LESS_THAN_LIST_PRICE":"precio de lista menor que",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Encuentre los errores a continuación.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Esta operación no se puede completar porque no se cumplen los requisitos previos. La asignación de un complemento requiere una oferta básica y la revocación de una oferta básica requiere que se publiquen los complementos.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Esta operación no se puede completar porque no se cumplen los requisitos previos. La asignación de un complemento requiere una oferta básica y la revocación de una oferta básica requiere que se publiquen los complementos.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Oferta",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Ofertas de requisitos previos",
      "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Solicitud rechazada",
      "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Descargar informe de cambio de licencia",
      "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Obtener informe de cambio de licencia",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"cantidad de cierre",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Creado por",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Fecha de cambio",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Informe de cambio de licencia",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Nueva cantidad",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Nuevo estado",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Cantidad antigua",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"antiguo estado",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Cantidad de apertura",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Producto",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nombre del revendedor",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nombre del arrendatario vinculado",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"Estado de los términos y condiciones",
      "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"La fecha 'desde' del informe no debe ser posterior a la fecha 'hasta'",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Aceptado",
      "LICENSE_CONSUMPTION_REPORT":"Informe de consumo de licencia",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Seleccione",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Cliente",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Inquilino",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Por favor seleccione cliente",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Por favor ingrese el nombre de la suscripción",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Por favor seleccione inquilino",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"filtros",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Diferencia de cantidad asignada",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Disponible en C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Disponible en PC",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Licencias asignadas en C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Licencias en C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Cliente",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"Dirección de correo electrónico",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Diferencia de cantidad total",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Productos mapeados",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Licencias asignadas en proveedor",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Licencias en proveedor",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Licencias no utilizadas en el proveedor",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Suscripción",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Inquilino",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Ver detalles",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Informe de consumo de licencia",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Informe de ganancias",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Informe de consumo de licencias de exportación CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Inquilinos",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Obtener resumen de licencia",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Descargar informe resumido de licencia",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Resumen de licencia",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nombre del revendedor",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Ciclo de facturación",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Nombre del producto principal",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Nombre de la suscripción principal",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Nombre del proveedor",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"ID de inquilino",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Cantidad comprada",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Renovación – (En días)",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"se renueva el",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Fecha de caducidad",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Nombre",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"clave de cupón",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Consumo de licencia",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Liberar asientos no utilizados",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consumo de licencia",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Liberar asientos no utilizados",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Liberación de asientos no utilizados. Espere por favor...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Asignado",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Productos mapeados",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Total",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre(s) de suscripción",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":" No usado",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Consumo de licencia",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consumo de Licencia",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Asignado",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Válido",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
      "LICENSES_TOOLTIP_MS_USER":"Licencias",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":" Enlace al perfil de facturación de",
      "LINK_CUSTOMER_HEADER_TEXT":" Enlace al proveedor de",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Estás vinculado a todos los proveedores presentes.",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"tipo de cliente",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"tipo de cliente",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Identificación del inquilino",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Nombre del inquilino",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Se vinculó exitosamente un cliente de Microsoft que no es CSP a C3.",
      "LINK_RESELLER_CAPTION_TEXT":"Revendedor de enlaces",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Proveedor",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"ID de revendedor (como en proveedor)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Borrar",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Identificación del negocio",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Nombre del proveedor",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Estás vinculado a todos tus perfiles de proveedor.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Se ha eliminado el enlace a {{providerName}}.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Vinculado a {{providerName}} exitosamente.",
      "LINK_TEXT_CLICK_HERE":"haga clic aquí",
      "linkCustomerBillingProfile":"Enlace al perfil de facturación",
      "LINKED_PRODUCT_INFO_SHOPE":" Este producto está vinculado con",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Seleccione",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Enlace a un producto",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Validación de la disponibilidad del dominio",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Categoría",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Proveedor",
      "LOADING_INDICATOR_MESSAGE":" Procesando...",
      "LOADING_MESSAGE":"Cargando...",
      "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>ya se cargaron las promociones del mes actual</p> <p> ¿quieres recargar las promociones?</p>",
      "LOADPROMOTION_CATEGORY_TOOLTIP":"Elija la categoría de producto de la lista desplegable",
      "LOADPROMOTION_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Danos un momento para completar tu personificación como",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":" Saludos,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Ya has iniciado sesión. Danos un momento para preparar la aplicación para que puedas acceder...",
      "LOW":"Bajo",
      "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Aplicar X% en el margen de beneficio",
      "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Aplicar X% sobre el precio del socio",
      "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Aplicar X% en el margen de beneficio",
      "MACRO_COPY_PARTNERT_PRICE":"Copiar precio de socio",
      "MACRO_COPY_PROVIDER_SELLING_PRICE":"Copiar precio de venta del proveedor",
      "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"El precio de venta del producto del plan será la suma del precio del socio + X% sobre el margen entre el precio del socio y el precio de venta del proveedor.",
      "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será precio de socio + X% (precio de socio)",
      "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será el precio de venta del proveedor + X% (precio de venta del proveedor)",
      "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será igual al precio del socio.",
      "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será igual al precio de venta del proveedor.",
      "MACRO_DET_EXAMPLE":"Ejemplo:",
      "MACRO_DET_PARTNER_PRICE":"Precio socio",
      "MACRO_DET_PERCENTAGE":"Porcentaje(X)",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Precio de venta del proveedor",
      "MACRO_DET_SALE_PRICE":"Precio de venta:",
      "MACRO_PERCENTAGE_TOOLTIP_TEXT":"% de macros",
      "MAIL_SENT_SUCCESSFULY":"El correo enviado con éxito",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
      "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Considerar en liberación automática",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"No",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Sí",
      "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Se considera en liberación automática",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Considerado en el lanzamiento automático.",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Gestionable exclusivamente por el socio",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Su solicitud de asignación de licencias está en cola. Visite esta página después de un tiempo para obtener actualizaciones.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Introduzca direcciones de correo electrónico válidas de los usuarios del inquilino.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"El usuario {{username}} ya tiene una licencia para {{subscriptionname}}",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Por favor seleccione al menos un archivo",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"¿Está seguro de que desea ignorar los errores?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"El número mínimo de puestos para una suscripción debe ser 1. No se pueden liberar puestos si el número de puestos disponibles es 1.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"¿Está seguro de que desea liberar los asientos no utilizados?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Licencias no utilizadas liberadas exitosamente",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Agregue al menos un usuario al grupo Licencias para eliminar",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Seleccione el usuario de la lista sugerida antes de hacer clic en el botón Agregar",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"La asignación de licencia se completó con éxito",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Se produjo un error al procesar el archivo, inténtalo de nuevo.",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Detalles de la licencia cargados correctamente. Vuelva a esta pantalla después de un tiempo para verificar el estado.",
      "MANAGE_NAME_CHANGE_ERROR":"El nombre no puede estar vacío.",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Ver Ofertas)",
      "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Editar macro",
      "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Los productos no se pueden vincular a esta oferta.",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Mostrar sólo promoción",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Sí",
      "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Escribe un nombre",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Copie el precio de lista al precio de venta: el precio de venta será el mismo que el precio de lista",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Agregue X% al precio de lista: el precio de venta será el X% del precio de lista + precio de lista",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Aumentar el precio en Y centavos: el precio de venta será Precio de lista + Y centavos",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Borrar todas las ofertas seleccionadas precio",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"Agregue X% al margen: el precio de venta será el precio de lista + (ERPPrice-Precio de lista)*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":" Agregue X% al precio de MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":" el precio de venta será el X% del precio MS + precio MS",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":" Copie el precio de MS al precio de venta.",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":" El precio de venta será el mismo que el precio de MS.",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Agregue X% al margen",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":" el precio de venta será Precio MS + (Precio ERP-Precio MS)*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Aumentar el precio en Y centavos",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":" el precio de venta será MS Price + Y centavos",
      "MANAGE_PLAN_LBL_1":"Copie el precio de lista al precio de venta",
      "MANAGE_PLAN_LBL_2":"Añadir X% al precio de lista",
      "MANAGE_PLAN_LBL_3":"Aumentar el precio en Y centavos",
      "MANAGE_PLAN_LBL_4":"Borrar todos los precios",
      "MANAGE_PLAN_LBL_5":"Agregue X% al margen",
      "MANAGE_PLAN_LBL_6":"Copie el precio del ERP al precio de venta",
      "MANAGE_PLAN_MACRO_APPLIED":"Macro aplicada",
      "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"La macro recién seleccionada se aplicará a las ofertas que se agreguen al plan en el futuro y no afectará la macro existente en las ofertas que ya se hayan agregado.",
      "MANAGE_PLAN_NO_MACRO_APPLIED":"No se aplicó ninguna macro",
      "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"No disponible para compra",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Cerca",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Ofertas incluidas",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Cerca",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Nombre",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Nombres de los padres",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"¿Estás seguro de que deseas eliminar todos los complementos?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"¿Estás seguro de que deseas eliminar este producto?",
      "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}}</b> es una oferta principal para un producto de prueba. <br/> Eliminar la oferta premium también resultará en la eliminación de la oferta de prueba asociada.<br/> ¿Está seguro de que desea eliminar <b>{{productName}}<b/>?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Se agregan complementos al plan; guarde el plan para finalizar la operación.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":" agregado al plan!",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":" agregado al plan con complementos",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" agregado al plan con una oferta para padres",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"No se encontraron complementos coincidentes",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"¿Quieres eliminar la oferta?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"El complemento que estás intentando agregar ya está incluido",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Tipos de consumo",
      "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"Es oferta de sendero",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Estado de suscripción",
      "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Mercados admitidos",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Consulte las siguientes ofertas ya que el precio de venta es inferior al precio de lista.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Haga clic en \"Aceptar\" para enviar los cambios del plan. 'CANCELAR' para volver a verificar las ofertas del plan.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"La oferta seleccionada ya estaba incluida.",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Proporcione un nombre descriptivo y un precio efectivo para las ofertas incluidas en el plan.",
      "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Consulte las siguientes ofertas ya que el precio de venta es mayor que el precio ERP.",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Por favor agregue ofertas al plan",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Agregar más complementos",
      "MANAGE_PONUMBER_CHANGE_ERROR":"El número PON no puede estar vacío",
      "MANAGE_PRODUCT_OWNERSHIP":"Gestionar la propiedad del producto",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Error en la gestión de propiedad del producto",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"No se permite administrar la propiedad del producto ya que la asignación de licencia está en curso para uno de los productos o sus complementos. Por favor intente mas tarde.",
      "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Se agregaron nuevos cupones con éxito",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Precios claros",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Copie el precio de MS al precio de revendedor: el precio de revendedor será el mismo que el precio de MS",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"Agregue X% al precio de MS: el precio de revendedor será el X% del precio de MS + precio de MS",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Aumentar el precio en Y centavos: el precio de revendedor será MS Price + Y centavos",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Borrar todas las ofertas seleccionadas precio",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"Agregue un X% al margen de beneficio: el precio de revendedor será Precio MS + (Precio ERP-Precio MS)*X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Si desea agregar las ofertas de revendedor, debe seguir las instrucciones a continuación",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Artículos seleccionados",
      "MANAGE_RELLER_OFFERS_LBL_1":"Copie el precio de MS al precio de revendedor",
      "MANAGE_RELLER_OFFERS_LBL_2":" Agregue X% al precio de MS",
      "MANAGE_RELLER_OFFERS_LBL_3":"Aumentar el precio en Y centavos",
      "MANAGE_RELLER_OFFERS_LBL_4":"Borrar todos los precios",
      "MANAGE_RELLER_OFFERS_LBL_5":"Agregue X% al margen",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"¿Quiere eliminar todas las ofertas filtradas de las ofertas seleccionadas?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"¿Quieres eliminar todas las ofertas seleccionadas?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"¿Quieres seleccionar todas las ofertas filtradas?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quieres seleccionar todas las ofertas?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Válida desde",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"precio del ERP",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Tipo de licencia",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Precio de lista",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Precio de revendedor",
      "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Si desea reducir la cantidad, comuníquese con el equipo de soporte.",
      "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Volver a renovaciones programadas",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Cancelar",
      "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Crear nuevo horario",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Entregar",
      "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Cantidad acumulada",
      "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Suscripción actual",
      "MANAGE_RENEWAL_DESCRIPTION_1":"Los cambios entran en vigor en la fecha de renovación de la suscripción: <b>{{renewalDate}}</b>. Para las actualizaciones de SKU, si la cantidad no cambia, las licencias se asignarán automáticamente. De lo contrario, deberá asignar licencias manualmente.",
      "MANAGE_RENEWAL_HEADER_TEXT":"Gestionar renovación",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Actual: {{currentEnddate}}",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Elija entre diferentes SKU:",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Elija un ciclo de facturación diferente:",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Elija un término diferente:",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Fecha final",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Alineación de fecha de finalización",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Tipo de fecha de finalización",
      "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Planificar producto",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Cantidad comprada",
      "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Elija un producto de plan elegible para su suscripción (use esta opción si desea cambiar el SKU/ciclo de facturación/Término junto con la programación de los cambios)",
      "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Término",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Producto del plan previamente seleccionado para renovación programada para cambio de SKU/Plazo/Ciclo de facturación:",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Nombre del producto del plan",
      "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Puede cancelar su suscripción con una suscripción existente de OnlineServicesNCE que no sea de prueba o alinear la fecha de finalización con el mes calendario eligiendo una fecha de finalización adecuada según la duración del plazo.",
      "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Calendario de actualización",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Volver a revendedores",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Ofertas",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"revendedores",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Agregar ofertas de revendedores",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Ofertas",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Proporcione el precio efectivo para las ofertas seleccionadas.",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Ofertas guardadas exitosamente",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Por favor agregue ofertas al revendedor",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Todas las ofertas seleccionadas. Si desea editar algo relacionado con cualquier oferta de plan, ¡debe pasar por el plan de edición!",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Lista de planes de revendedor",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Estado del plan actualizado correctamente",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Asignar",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Desactivar",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Permitir",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Nombre",
      "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Mercados admitidos",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Expira el",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Renovación automática activada",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"identificación de producto",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Si la renovación automática está desactivada, esta suscripción caduca el",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":" Si la renovación automática está desactivada, esta suscripción caduca al final del período de vigencia",
      "manageplans":"Administrar planes",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"¿Quiere eliminar el grupo de recursos?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Solicitar al Cliente que actualice los Detalles de pago.",
      "MANUAL_SYNC_PUPUP_TITLE":"Sincronización manual",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Aplicó correctamente su solución",
      "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Seleccione un acuerdo PSA",
      "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Por favor seleccione un contrato",
      "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Por favor seleccione un Cliente",
      "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Por favor seleccione un cliente de PSA",
      "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Seleccione un producto PSA",
      "MARCH":"Marzo",
      "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar esta dirección como predeterminada?",
      "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar este correo electrónico como predeterminado?",
      "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar este número de teléfono como predeterminado?",
      "Markup":"Margen",
      "MATCHING_PRODUCT_PLAN_TEXT":"Plan:",
      "MAY":"Puede",
      "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Creación de pagos recurrentes. Es posible que vea un cargo de $ 1.00 en su tarjeta que se ajustará como crédito en su próxima factura.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"El banco del cliente (emisor de la tarjeta) ha rechazado la transacción. El cliente debe comunicarse con su banco y volver a intentar la transacción.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"El banco del cliente ha rechazado la transacción y solicita al comerciante que se comunique con el banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"La transacción fue exitosa.",
      "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"La transacción fue exitosa.",
      "MCB_ERROR_CODE_APPROVED_VIP":"La transacción fue exitosa. (Este código no se usa en Australia).",
      "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que no permite transacciones que se originen a través de correo/teléfono, fax, correo electrónico o pedidos por Internet. Este error está asociado a clientes que intentan utilizar una tarjeta Discover. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"El banco del cliente ha rechazado la transacción y solicita al cliente que se comunique con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_CAPTURE_CARD":"El banco del cliente ha rechazado la transacción porque se sospecha que la tarjeta es falsa. El banco del cliente (Emisor de la tarjeta) ha solicitado que se retenga la tarjeta de crédito de su cliente. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción y ha solicitado que se retenga la tarjeta de crédito del cliente (tarjeta reportada como extraviada o robada). El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"No se puede contactar con el banco del cliente para autorizar la transacción. El cliente debe intentar procesar esta transacción nuevamente.",
      "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"El banco del cliente no puede procesar temporalmente la tarjeta de crédito de este cliente. El cliente debe intentar procesar esta transacción nuevamente.",
      "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"El banco del cliente ha rechazado la transacción porque el CVV es incorrecto. El cliente debe verificar los detalles del CVV y volver a intentarlo. Si no tiene éxito, el cliente debe usar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_DO_NOT_HONOUR":"El banco del cliente ha rechazado la transacción porque el número de la tarjeta de crédito no ha superado una comprobación de seguridad o los fondos se han congelado o agotado. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"El banco del cliente ha rechazado la transacción porque parece ser una transmisión duplicada. No se requiere accion.",
      "MCB_ERROR_CODE_ERROR":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"El banco del cliente ha rechazado la transacción porque el cliente ha excedido el límite de frecuencia de retiro. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"El banco del cliente ha rechazado la transacción porque excederá el límite de la tarjeta del cliente. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_EXPIRED_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito parece haber caducado. El cliente debe verificar la fecha de vencimiento ingresada e intentar nuevamente, o usar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito ha caducado o la fecha es incorrecta. Verifique la fecha de vencimiento en la transacción e intente procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_FORMAT_ERROR":"El banco del cliente (Emisor de la tarjeta) no reconoce los detalles de la transacción. El cliente debe verificar la información de la transacción e intentar procesar la transacción nuevamente. Generalmente debido a un TID incorrecto.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"El banco del Cliente ha rechazado la transacción ya que esta tarjeta de crédito no se puede utilizar para este tipo de transacción. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"El banco del Cliente ha rechazado la transacción ya que esta tarjeta de crédito no se puede utilizar para este tipo de transacción. Esto puede estar asociado con un número de tarjeta de crédito de prueba. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que no permite este tipo de transacciones. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"La transacción fue exitosa. Algunos bancos usan este código en lugar de '00'.",
      "MCB_ERROR_CODE_INCORRECT_PIN":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción porque el cliente ingresó un PIN incorrecto. El cliente debe volver a ingresar su PIN; de lo contrario, use una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito no tiene fondos suficientes. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_INVALID_AMOUNT":"Es posible que se esté pasando a la puerta de enlace un carácter no válido (por ejemplo, un signo de dólar o un espacio). Verifique el código de su sitio web.",
      "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el número de la Tarjeta de crédito no existe. Verifique la información de la tarjeta de crédito e intente procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_INVALID_TRANSACTION":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción debido a un formato o campo no válido. Verifique la información de la transacción e intente procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_LOST_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta ha sido reportada como extraviada. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_CARD_RECORD":"El banco del Cliente ha rechazado la transacción porque el número de tarjeta de crédito no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que el número de la tarjeta de crédito está asociado a una cuenta corriente que no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"El banco del cliente ha rechazado la transacción porque el número de tarjeta de crédito utilizado no es una cuenta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el tipo de cuenta seleccionado no es válido para este número de tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_ISSUER":"El banco del cliente (Emisor de la tarjeta) no existe. Verifique la información de la tarjeta de crédito e intente procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_NO_MERCHANT":"La identificación del comerciante no es válida, debe comunicarse con su banco y asegurarse de haber proporcionado el número de cuenta del comerciante correcto.",
      "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que el número de tarjeta de crédito está asociado a una cuenta de ahorros que no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el tipo de cuenta seleccionado no es válido para este número de tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"El banco del cliente ha rechazado la transacción debido al monto que se intenta procesar. El cliente debe verificar el monto de la transacción y volver a intentarlo.",
      "MCB_ERROR_CODE_PICK_UP_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada). El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"El banco del cliente rechazó la transacción porque el cliente ingresó el PIN incorrecto más de tres veces. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción porque el cliente ingresó el PIN incorrecto tres veces. El banco del cliente (Emisor de la tarjeta) le ha solicitado que conserve la tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa y ponerse en contacto con su banco.",
      "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"La transacción no ha sido procesada y el cliente debe intentar procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_RESTRICTED_CARD":"El banco del cliente ha rechazado la transacción porque la tarjeta de crédito tiene algunas restricciones. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito del cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_SECURITY_VIOLATION":"El banco del cliente ha rechazado la transacción. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_STOLEN_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta ha sido denunciada como robada. El cliente debe utilizar una tarjeta de crédito alternativa.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD":"El banco del cliente ha rechazado esta transacción porque la tarjeta de crédito parece ser fraudulenta.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción debido a que existe una sospecha de fraude en este número de tarjeta de crédito.",
      "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"No se puede contactar al banco del cliente (Emisor de la tarjeta) durante la transacción. El cliente debe verificar la información de la tarjeta de crédito e intentar procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_SYSTEM_ERROR":"El banco del cliente no pudo procesar la transacción. El cliente debe intentar procesar esta transacción nuevamente.",
      "MCB_ERROR_CODE_TRANSACTION_APPROVED":"La transacción fue exitosa.",
      "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"El banco del cliente (Emisor de la tarjeta) no reconoce los datos de la tarjeta de crédito. El cliente debe verificar la información de la tarjeta de crédito e intentar procesar la transacción nuevamente.",
      "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"No se puede encontrar el banco del cliente para el enrutamiento. Este código de respuesta suele devolverse cuando el cliente utiliza un número de tarjeta de crédito de prueba. El cliente debe intentar procesar esta transacción nuevamente.",
      "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Se ha producido un error no especificado.",
      "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Número de tarjeta inválido o faltante.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Falta el mes de caducidad o no es válido.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Falta el año de caducidad o no es válido.",
      "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Código de seguridad no válido.",
      "MEDIUM":"Medio",
      "MENU_ACCOUNT_MANAGERS":"Gerente de Cuentas",
      "MENU_ADMINISTRATION":"Administración",
      "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notificaciones personalizadas",
      "MENU_ADMINISTRATION_ENGAGE":"Participar <sup class='text-warning'> Beta</sup>",
      "MENU_ADMINISTRATION_MANAGE":"Notificaciones de pancartas",
      "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Notificaciones de pancartas",
      "MENU_ADMINISTRATION_MANAGE_HEADING":"Administración",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Notificaciones",
      "MENU_ADMINISTRATION_WEBHOOK":"gancho web",
      "MENU_AZURE_ADVISOR":"Asesor de Azure",
      "MENU_AZURE_BILLING_REPORT":"Informe de facturación de Azure",
      "MENU_AZURE_CUSTOM_POLICY":"Políticas personalizadas",
      "MENU_AZURE_CUSTOM_ROLE":"Roles personalizados",
      "MENU_AZURE_MARGIN":"Soporte C3",
      "MENU_AZURE_SUBACRIPTION":"Suscripción Azure",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"microsoft",
      "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Gerente de Cuentas",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Comisiones",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Atributos del producto",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Citas",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Detalles de las cotizaciones",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Lista de citas",
      "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Informe de uso",
      "MENU_CONTACT_LOG":"Notificaciones",
      "MENU_COST_SUMMARY_REPORT":"Informe resumido de costos",
      "MENU_CUSTOMER_ANALYTICS":"Análisis de clientes",
      "MENU_CUSTOMER_COMMENTS":"Comentarios",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consumo de Licencia",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Uso de Azure",
      "MENU_CUSTOMER_PROFILE":"Perfil",
      "MENU_DASHBOARD":"Panel",
      "MENU_EXISTING_ACCOUNT_MANAGERS":"Gerentes existentes",
      "MENU_HELP":" Ayuda",
      "MENU_LICENSE_CHANGE":"Informe de cambio de licencia",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Informe resumido del consumo de licencias",
      "MENU_LICENSE_SUMMARY_REPORT":"Informe resumido de licencia",
      "MENU_MANAGE_AZURE_ENTITLEMENTS":"Administrar derechos de Azure",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Aprovisionamiento de ofertas de socios",
      "MENU_ONBOARDED_CUSTOMERS_REPORT":"Análisis de incorporación",
      "MENU_PARTNER_AUDIT_LOG":"Registro de auditoría",
      "MENU_PARTNER_COMMISSION_REPORT":"Informe de la Comisión",
      "MENU_PARTNER_COUPON":"Cupones",
      "MENU_PARTNER_DOCUMENTATION":"Documentación",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
      "MENU_PARTNER_SUPPORT_LOG":"Apoyo",
      "MENU_PARTNER_UPLOAD":"Subir",
      "MENU_PENDING_PURCHASE_REQUESTS":"Aprobaciones",
      "MENU_PRODUCT_COMMISSIONS":"Comisiones",
      "MENU_PRODUCT_EXTENSIONS":"Atributos del producto",
      "MENU_PRODUCT_SEQUENCE":"Secuencia de productos",
      "MENU_PURCHSED_PRODUCT_MAPPING":"Mapeo de productos comprados",
      "MENU_RENEWAL_MANAGER":"Gerente de Renovación",
      "MENU_REPORT_USAGE":"Uso",
      "MENU_REPORTS_FOR_CUSTOMER":"Informes",
      "MENU_RESELLER_PLAN":"Plan de revendedor",
      "MENU_RESELLER_PROFILE":"Perfil de la organización",
      "MENU_SCHEDULED_REPORTS":"Informes programados",
      "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Destinatarios de",
      "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Ejecutar historial para",
      "MENU_TOPBAR_SETTINGS":"Ajustes",
      "MENU_USAGE_REPORT":"Informe de uso de Azure",
      "MENU_USERS":"Usuarios del portal",
      "MENUS_ANALYTICS":"Analítica",
      "MENUS_ANALYZE":"Analizar",
      "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Administrar los precios de los niveles de derechos de Azure",
      "MENUS_AZURE_ESTIMATE":"Estimación de Azure",
      "MENUS_AZURE_GROUPS":"Grupos de Azure",
      "MENUS_AZURE_MANGE":" Gestión de Azure",
      "MENUS_AZURE_REPORTS":"Informes de Azure",
      "MENUS_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
      "MENUS_CART":"Carro",
      "MENUS_COUPONS":"Cupones",
      "MENUS_CUSTOM_OFFERS":"Ofertas de socios",
      "MENUS_CUSTOMER_CART":"Carro",
      "MENUS_CUSTOMER_DASHBOARD":"Panel",
      "MENUS_CUSTOMER_MICROSOFT":"microsoft",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Usuarios",
      "MENUS_CUSTOMER_ONBOARDING":"Incorporación de clientes",
      "MENUS_CUSTOMER_ORDERS":"Pedidos",
      "MENUS_CUSTOMER_PRODUCTS":"Productos",
      "MENUS_CUSTOMER_PROFILE":"Perfil",
      "MENUS_CUSTOMER_SHOP":" Comercio",
      "MENUS_DAAS":"DaaS<sup class='text-danger'>BETA</sup>",
      "MENUS_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
      "MENUS_DOCUMENTATION":"Documentación<sup class='text-danger'>BETA</sup>",
      "MENUS_ENTITY_INVOICES":"Facturas",
      "MENUS_FAQ":"Preguntas más frecuentes",
      "MENUS_INVITES":"invita",
      "MENUS_INVOICES":"Facturas",
      "MENUS_LICENSE_CONSUMPTION":"Consumo de Licencia",
      "MENUS_LICENSE_LIST":"Lista de licencias",
      "MENUS_MS_OFFERS":"Ofertas de EM",
      "MENUS_OPERATING_ENTITIES":"Entidades Operadoras",
      "MENUS_ORDERS":"Pedidos",
      "MENUS_PARTNER_BUNDLES":"manojos",
      "MENUS_PARTNER_CUSTOMERS":"Clientes",
      "MENUS_PARTNER_DASHBOARD":"Panel",
      "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Estado de pago pendiente",
      "MENUS_PARTNER_PLANS":"planes",
      "MENUS_PARTNER_QUOTES":"Cita",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Negocio",
      "MENUS_PARTNER_SETTINGS":"Ajustes",
      "MENUS_POWER_BI_REPORTS":"Uso de Azure",
      "MENUS_PRODUCTS":"Productos",
      "MENUS_PROFILE":"Perfil",
      "MENUS_RESELLER_DASHBOARD":"Panel",
      "MENUS_RESELLER_INVOICES":"Facturación",
      "MENUS_RESELLER_PAYMENTS":"Pagos",
      "MENUS_RESELLER_PLANS":"planes",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Precios y ofertas",
      "MENUS_RESELLER_PROFILE":"Perfil de la organización",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Negocio",
      "MENUS_RESELLER_SETTINGS":"Ajustes",
      "MENUS_RESELLERS":"revendedores",
      "MENUS_SELL":"Vender",
      "MENUS_SELL_DIRECT":"Vender Directo",
      "MENUS_SELL_INDIRECT":"Vender Indirecto",
      "MENUS_SELLER_INDIRECT":"Vender Indirecto",
      "MENUS_SHOP":" Comercio",
      "MENUS_SUBSCRIPTIONS":"Suscripciones",
      "MENUS_SUPPORT":"Apoyo",
      "MENUS_TAXES":"Impuestos",
      "MENUS_USER_MANAGEMENT":"Gestión de usuarios",
      "merge":"rama",
      "MESSAGE_ALREADY_EXISTING":"El mensaje ya existe para las entidades relevantes",
      "MESSAGE_BODY":"Cuerpo del mensaje",
      "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Por favor verifique todos los campos requeridos e intente enviar nuevamente",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Para la entidad Variante de producto/Cliente, no se puede volver a insertar la misma combinación.",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Por favor seleccione todos los campos",
      "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND":"<div ng-include=\"'app/templates/no-data-message.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"No hay datos para presentar debido a que no se ha utilizado durante el período de facturación actual o porque la factura de Microsoft aún no está disponible. Vuelva a consultar en breve.",
      "MESSAGE_NOTIFCATION_SAVED":"Notificación de mensaje guardada con éxito",
      "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Resuelva todos los errores de validación e intente enviar de nuevo",
      "MESSAGE_NOTIFICATION_EDIT_MODE":"Editar notificaciones de mensajes",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Editar",
      "MESSAGE_NOTIFICATION_ID":"IDENTIFICACIÓN",
      "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Seleccione al menos un destinatario para la notificación del mensaje",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Comportamiento",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Fecha efectiva",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Expira el",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Cuerpo del mensaje",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Estilo de mensaje",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Nombre de la página",
      "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Entidades involucradas",
      "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Se ha seleccionado un producto.",
      "MESSAGE_RECORDS_POP_UP":"lista de registros",
      "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Se ha producido un error",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"¡No se encontraron registros!",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Lo sentimos, no tienes sitios ni departamentos configurados. Puede agregar nuevas entidades operativas desde la página de perfil",
      "MESSAGE_TEXT_PLEASE_WAIT":"Espere por favor..",
      "MESSAGE_TOOLTIP_AZUREGROUP":"Información, Advertencia, Error, Peligro",
      "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Seleccione tipo de mensaje",
      "MESSAGE_TYPE_NAME":"Nombre del tipo de mensaje",
      "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Por favor seleccione todos los campos",
      "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"URL invalida. Por favor introduzca un URL válido.",
      "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Por favor verifique todos los campos requeridos e intente enviar nuevamente",
      "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"¡La URL es válida!",
      "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" No se permiten valores decimales.",
      "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Nuevo nivel",
      "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Introduzca un número válido.",
      "METERED_BILLING_TEXT_MODAL_HEADER":"Niveles de uso",
      "Microsoft":"microsoft",
      "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Tipo de atestación:",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Remover",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Categoría",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Descuento",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Complementos",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Remover",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Categoría",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Descuento",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Margen",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Porcentaje",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Complementos",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Producto vinculado",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Aceptado por",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA aceptado el",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Estado de aceptación",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"No provisto",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Proporcionó",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Aceptación del cliente confirmada por el socio",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Aceptación del cliente a través de microsoft.",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"El acuerdo de cliente de Microsoft se guardó correctamente.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Contraseña temporal",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nombre de usuario",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"El usuario se ha creado con éxito y debajo están las credenciales del usuario.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Detalles de usuario",
      "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Después de cerrar esta ventana emergente, no podrá volver a ver esta contraseña temporal.",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"El consentimiento de la API de precios de Microsoft capturado falló",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Consentimiento de la API de precios de Microsoft capturado con éxito",
      "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"El intento de acceder a la API de precios de Microsoft es exitoso.",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} de {{applicationName}} desea ser su proveedor de soluciones de nube de Microsoft para su cuenta de nube de Microsoft.<br/><br/>Para aceptar la invitación, siga los 2 pasos a continuación:<br/><br/>Paso 1: Verifique y asegúrese de que el perfil del cliente esté completo aquí: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Paso 2: Una vez que el perfil del cliente esté completo (puede demorar hasta 5 minutos en actualizarse después de que se actualice el perfil), haga clic en el siguiente vínculo para aceptar esta invitación y autorizar a {{applicationName}} a ser su proveedor de soluciones de nube de Microsoft y aceptar la invitación de cliente de Microsoft. Acuerdo.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Nota: Se requiere un usuario con permiso de administrador global para aceptar la relación.<br/><br/><br/>Información adicional del socio:<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Estimado cliente:<br/><br/>Para brindarle una gama completa de soluciones, servicios y soporte en la nube, {{resellerName}} trabaja en estrecha colaboración con {{partnerCompanyName}}. Para autorizar a {{resellerName}} y {{partnerCompanyName}} a brindar servicio a su cuenta, realice los siguientes 2 pasos: <br/><br/>Paso 1: Verifique y asegúrese de que el perfil del cliente esté completo aquí: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Paso 2: Una vez que el perfil del cliente esté completo (puede demorar hasta 5 minutos en actualizarse después de que se actualice el perfil), haga clic en el siguiente vínculo para aceptarlo invitación.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Nota: Se requiere un usuario con permiso de administrador global para aceptar la relación.<br/><br/>{{resellerName}} seguirá siendo su experto local de referencia para productos, suscripciones, licencias y soporte. Tenga la seguridad de que su relación comercial con usted sigue siendo la misma.<br/><br/>No dude en comunicarse con nosotros si tiene preguntas. ¡Nosotros y {{resellerName}} esperamos trabajar con usted!<br/><br/>Atentamente,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de software",
      "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de terceros",
      "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
      "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de terceros.",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Se eliminarán todas las licencias existentes y el acceso al portal. Estás seguro de que quieres continuar?",
      "MicrosoftNonCSP":"Microsoft no CSP",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Cuenta de migración creada exitosamente con el nombre de usuario:",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Cuentas de migración",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Cliente",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Cuenta de migración",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Crear",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Cuentas de migración",
      "MIGRATION_USER_CREATE_SUCCESS":"Cuenta de migración creada correctamente con {{username}}",
      "MINUTES":" Minutos)",
      "MISSING_PLAN_OFFER_ADD_FAILED":"La conciliación falló; comuníquese con el soporte para obtener ayuda.",
      "MISSING_PLAN_OFFER_ADDED":"Conciliación completada usando la oferta elegida",
      "MNUAL_SYNC_PURCHASED_DATE":" Fecha de compra:",
      "MODAL_HEADER_TEXT_DESC_FILTER":"Filtrar",
      "Month(s)":"Meses)",
      "MULTIPLE_COMMENT_STATIC":"Comentarios",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Seleccione",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{ validationProcessedCount }}<br />Errores: {{ validationErrorCount }}<br />Éxito: {{ validationSuccessCount }}",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Instrucciones",
      "Name":"Nombre",
      "NAME":"Nombre de la entidad",
      "NCE_ACRONYM":"Servicios en línea NCE",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Política de cancelación",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 horas: Reembolso completo",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 horas: 1er día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 horas: 2do día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 horas: 3er día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 horas: 4to día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 horas: 5to día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 horas: 6to día prorrateado",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Después de 7 días naturales: No se permite la cancelación",
      "NCE_INSTRUCTIONS_HEADING":"Instrucciones NCE",
      "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Precio de venta estimado del producto vinculado",
      "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>El siguiente producto no es elegible para la promoción. Póngase en contacto con soporte para aplicar la promoción. si no desea la promoción, continúe con el proceso de pedido.</p>",
      "NCE_PROMOTIONS_APPLIED":"Esta promoción se aplica",
      "NCE_PROMOTIONS_AVAILABLE_TEXT":"Promoción disponible",
      "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Verifique la elegibilidad de la promoción",
      "NCE_PROMOTIONS_NOT_APPLIED":"La promoción aún no se aplica",
      "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"promociones disponibles",
      "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Frecuencia de facturación",
      "NCE_PROMOTIONS_POPUP_CANCEL":"Cancelar",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Cerca",
      "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"La elegibilidad del cliente se validará al revisar el carrito",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"Descuento",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Fecha final",
      "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Descuento promocional lanzamiento nuevo comercio",
      "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Detalles de la promoción",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":" IDENTIFICACIÓN:",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":" Nombre:",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Aplicar",
      "NCE_PROMOTIONS_POPUP_TERM":"Término",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"La cantidad/asientos se puede aumentar en cualquier momento.",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"La cantidad/asientos se puede disminuir dentro de los 7 días calendario, a su cantidad/asientos anteriores oa la suma de los anteriores.",
      "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Cambio de cantidad",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Cambio de renovación de horario",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"El programa actual se eliminará si se cambia Cantidad/Estado de renovación automática/Ciclo de facturación/Término.",
      "NCE_SKU_NOT_PRESENT_IN_PLAN":"Producto no disponible en Plan Cliente",
      "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Por favor, acepte todos los términos y condiciones.",
      "NCE_TEXT":"nuevo comercio...",
      "NEW_BILLING_CYCLE":"Nuevo ciclo de facturación",
      "NEW_COMMENT_SEND":"Enviar",
      "NEW_COMMENTS_RECIEVED":"Nuevo comentario recibido",
      "NEW_CUSTOMER_ONBOARDING_ERRORS":"los productos de compra/actualización pueden fallar",
      "NEW_DISTRIBUTOR_PRODUCT_ICON":"Nuevo icono",
      "NEW_PARTNER_PRODUCT_ICON":"Nuevo icono",
      "NewPrice":"Nuevo precio",
      "NEXT_SCHEDULE_RENEWAL_CHANGE":"Próximo cambio de renovación de horario",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Buscar ...",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"No existe ningún artículo con ID: {cartLineItemId} en el carrito",
      "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"No hay ningún administrador de clientes disponible para este inquilino.",
      "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"No hay clientes registrados",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Ningún plan existente",
      "NO_PAYMENT_TYPES_ENABLED":"No hay tipos de pago habilitados. Por favor contacte al soporte.",
      "NO_PLAN_WITH_OFFER_AVAILABLE":"No hay ningún plan con todas las ofertas disponibles. Por favor crea uno",
      "NO_PRODUCT_SELECTED_PROMPT":"Por favor seleccione mínimo un producto.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"No se encontraron detalles de factura para el período de facturación o proveedor seleccionado",
      "NO_RESOURCE_GROUP_LEFT":"¡No queda ningún grupo de recursos para asignar/No se encontraron registros!",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Detalles del producto sin CSP",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"ID de entidad de servicio",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Clave principal de servicio",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Identificación de suscripción",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"No tiene permiso para realizar este cambio; comuníquese con su socio para obtener más información.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Los siguientes productos del proveedor no están asignados a los productos del plan. Si desea continuar, haga clic en continuar; de lo contrario, haga clic en cancelar y asigne estos productos.",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Productos del proveedor",
      "NOT_VALID_MESSAGE":" no es un correo valido",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Crear notificación de banner",
      "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"Etiqueta de cliente eliminada exitosamente",
      "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"El nombre de la suscripción cambió con éxito",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Este valor se actualizará en breve.",
      "notifications":"Notificación",
      "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"La longitud de los caracteres debe estar entre 2 y 200 caracteres",
      "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"La longitud de los caracteres debe estar entre 2 y 50 caracteres.",
      "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Fecha de finalización personalizada guardada correctamente",
      "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"URL de webhook no válida",
      "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"URL de webhook válida",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Ingrese las direcciones de correo electrónico separadas por coma (,) o punto y coma (;) y haga clic en Aceptar para recibir un archivo zip que contiene una lista de facturas en formato PDF para el período de facturación seleccionado.",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"En breve se enviará a {{emailsList}} un correo electrónico con una lista de archivos PDF (Zip) de facturas como archivo adjunto.",
      "NOVEMBER":"Noviembre",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"Los clientes no pudieron incorporarse",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"Clientes incorporados con éxito",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"Clientes seleccionados en lote",
      "NUMBER_OF_PROMOTIONS_LOADED":"Número de promociones cargadas",
      "OCTOBER":"Octubre",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"El nombre descriptivo de la oferta {{FriendlyName}} debe ser único",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Mostrar ofertas con suscripción comprada",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"ofertaConCompradoSuscripción",
      "offers":"Ofertas",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Suscripciones",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Volver a Suscripciones",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Agregar suscripciones",
      "OFFERS_HEADER_TEXT_OFFERS":"Ofertas",
      "OFFERS_NO_MATCH_MESSAGE":" No se encontraron resultados para Nombre :",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Artículos seleccionados",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Porcentaje de descuento",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad (Nº de licencias)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Tarifa por asiento (Por mes)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Total",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Cantidad total",
      "OLD_BILLING_CYCLE":"Antiguo ciclo de facturación",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Error al incorporar a un cliente mediante la incorporación masiva",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Incorporación de un cliente mediante incorporación masiva iniciada",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Incorporación de un cliente con éxito en la incorporación masiva",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Encontré una coincidencia en el ID: <strong> {{billingProviderReferenceID}} </strong> en el sistema de facturación.<br/><span>La dirección de facturación registrada es <strong> {{formattedAddress}} < /strong>.</span> <br/>Suplante su identidad y utilice la opción <strong>Perfil</strong> para cambiar este detalle después de que los detalles de facturación del cliente se hayan incorporado correctamente.",
      "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si se debe mapear {{firstProduct}}, también debe mapear {{secondProduct}}.",
      "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} tiene que ser mapeado, no debe ignorar {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si se tiene que mapear {{firstProduct}}, debe incluir {{secondProduct}}",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si tiene que mapear {{firstProduct}}, también debe mapear {{secondProduct}}, es decir; La cantidad mínima debe ser 1.",
      "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"La cantidad del proveedor y la cantidad compartida deben coincidir para {{product}}",
      "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"La cantidad debe ser igual para {{firstProduct}} y {{secondProduct}}",
      "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Seleccione CSPCustomer para OnBoard",
      "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Detalles de la cuenta principal",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":" Llenar",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Descarga una plantilla .csv",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":" agregando filas después del encabezado. Cada fila debe corresponder al detalle de un revendedor.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"El procesamiento tomará un tiempo y el estado se informará en esta página. Si se aleja de la página o ha cerrado el navegador por accidente, podría volver a esta página nuevamente y el sistema mostraría el estado de su carga anterior.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Haga clic en Seleccionar y busque el archivo que desea cargar.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"La entrada se valida para garantizar que se cumplan las siguientes reglas:",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"El nombre del revendedor es obligatorio y no debe exceder los 50 caracteres de longitud.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Se requiere ID de revendedor y no debe exceder los 50 caracteres de longitud. Este debe ser el GUID de relación del revendedor del Centro de partners.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Se requiere ID de MPN de revendedor y no debe exceder los 50 caracteres de longitud. Debe ser el identificador de Microsoft Partner Network del revendedor.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"La ubicación es obligatoria y debe ser el código de país de dos letras del revendedor. Por ejemplo: EE. UU., Reino Unido, etc.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"El correo electrónico es opcional, pero debe ser una dirección de correo electrónico válida si se ingresa una.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Haga clic en <strong class=\"text-uppercase\">enviar</strong> para que se procesen los datos.",
      "ONBOARDING":"Inducción",
      "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Descargar informe",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Seleccionar tipo de cliente",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Seleccionar unidad de duración",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Cerca",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"revendedores",
      "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Seleccionar revendedor",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Reiniciar",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Búsqueda",
      "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"tipo de cliente",
      "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Unidad de duración",
      "ONBOARDING_DURATION_UNIT_MONTH":"Mes",
      "ONBOARDING_DURATION_UNIT_WEEK":"Semana",
      "ONBOARDING_DURATION_UNIT_YEAR":"Año",
      "ONBOARDING_GRAPH_COUNT_TEXT":" Contar:",
      "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"No se permite la incorporación de clientes ya que no existe un vínculo con el revendedor del proveedor. Póngase en contacto con su socio.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"No se admite la incorporación de un cliente Microsoft NonCSP. Vincule el cliente con un cliente C3 existente.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"¡No se admite la incorporación de un cliente MicrosoftNonCSP!",
      "ONBOARDING_STATUS":"Estado de incorporación",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Mapeo de los productos del cliente proveedor con los productos del plan...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Cargando planos...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Cargando los clientes del proveedor...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Buscando los datos del cliente en c3...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"todos los clientes",
      "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Clientes directos",
      "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Clientes revendedores",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"¿Estas seguro que quieres borrarlo?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Seleccione un producto en la lista o busque por nombre de producto",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"La solicitud para eliminar licencias de los usuarios se acepta y se está procesando.",
      "OnlineServicesNCE":"Servicio en línea NCE",
      "Open":"Abierto",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
      "OPERATING_ENTITY_CUSTOMER":"Cliente",
      "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Configuración de la organización",
      "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Sitios y Departamentos",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Asignar",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Liberar",
      "ORDER_BACK_TO_ORDER_DETAILS":"volver a Productos",
      "ORDER_DETAILS_ENTITY_NAME":"Nombre de la entidad",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"pedido el",
      "ORDER_DETAILS_SCHEDULED_DATE":"Cita agendada",
      "ORDER_ID":"Solicitar ID",
      "ORDER_LBL_TEXT_PROMOTIONID":"ID de promoción",
      "ORDER_LIST_STATUS_SUCCESS":"Éxito",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Aprobado",
      "ORDER_STATUS_APPROVAL_REQUIRED":"Aprobación requerida",
      "ORDER_STATUS_COMMENTS":"Ver comentarios",
      "ORDER_STATUS_DELETED":"Eliminado",
      "ORDER_STATUS_DESC_ACCEPTED":"Aceptado",
      "ORDER_STATUS_DESC_FAILED":"Ha fallado",
      "ORDER_STATUS_DESC_IGNORE":"Ignorar",
      "ORDER_STATUS_DESC_INPROGRESS":"En curso",
      "ORDER_STATUS_DESC_ONHOLD":"En espera",
      "ORDER_STATUS_DESC_ORDERED":"Ordenado",
      "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Cumplido parcialmente",
      "ORDER_STATUS_DESC_PROCESSING":"Procesando",
      "ORDER_STATUS_DESC_PROVISIONING":"Aprovisionamiento",
      "ORDER_STATUS_DESC_REJECTED":"Rechazado",
      "ORDER_STATUS_DESC_SCHEDULED":"Programado",
      "ORDER_STATUS_DESC_SUCCESS":"Éxito",
      "ORDER_STATUS_ERRORS_IGNORED":"Error <br/>(errores revisados e ignorados)",
      "ORDER_STATUS_FAILED":"Ha fallado",
      "ORDER_STATUS_INPROGRESS":"Trabajando en ello",
      "ORDER_STATUS_ONHOLD":"En espera",
      "ORDER_STATUS_ORDERED":"Ordenado",
      "ORDER_STATUS_PROCESSING":"Trabajando en ello",
      "ORDER_STATUS_PROVISIONING":"Trabajando en ello",
      "ORDER_STATUS_REACTIVATED":"Reactivado",
      "ORDER_STATUS_REJECTED":"Rechazado",
      "ORDER_STATUS_SCHEDULED":"Programado",
      "ORDER_STATUS_SUCCESS":"Pedido cumplido",
      "ORDER_STATUS_SUSPENDED":"Suspendido",
      "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Cotización aprobada y pedido realizado exitosamente.<br/> El ID del pedido es <b>{{OrderNumber}}</b>",
      "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Cotización aprobada exitosamente.",
      "OWNER_ENTITY_NAME":"Nombre de la entidad propietaria",
      "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Detalles del estado de pago",
      "PaidAmount":"Monto pagado",
      "PARENT_ENTITY_NAME":"Nombre de la entidad principal",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"El complemento no se puede activar si la oferta principal no está activa.",
      "PARENT_PRODUCT_NOT_EXISTS":"¡No se puede incorporar un producto sin padre!",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"No hay claves disponibles para este proveedor",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"No hay proveedores configurados, comuníquese con el soporte",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":" Guarda tus cambios",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Descripción",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Mostrar moneda",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Moneda de la factura",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valor macro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Nombre",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Compra de moneda",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Seleccione",
      "partner":"Compañero",
      "Partner":"Compañero",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Asignar",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Desasignar",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Nombre del cliente",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Volver a los administradores de cuentas",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Clientes del administrador de cuentas",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Clientes del administrador de cuentas <span class='text-primary'> {{FirstName}} </span>",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nombre",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"ID de C3",
      "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Nombre del revendedor",
      "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Actualiza todos los planes con las últimas ofertas.",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"No se encontraron complementos",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Eliminar plan",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Revisar ofertas de planes",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Guardar plan",
      "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Para la oferta de prueba, no se encontró ninguna oferta principal. Incluya la oferta para padres e inténtelo de nuevo.",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Uso",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Ingrese la fecha efectiva desde",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Ingrese la fecha efectiva para",
      "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Introducir mensaje",
      "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Seleccionar tipo de mensaje",
      "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Seleccionar portal de página",
      "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Iconos-de-estocolmo / Comunicación / Chat grupal",
      "PARTNER_APPROVAL_PENDING":" Aprobación del socio -",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"añadido al paquete",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":" agregado al paquete con complementos",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":" Ya está agregado al paquete y no se puede agregar nuevamente.",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"filtros",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Reiniciar",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Búsqueda",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Validez",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Duración del término",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Duración del término",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Tipos de facturación",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Precio de coste",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Precio de costo (desde)",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Precio de costo (a)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Precio de venta",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Precio de venta (desde)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Precio de venta (a)",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Datos de losa",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":" - a -",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Tipo de validez",
      "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Incluir paquetes eliminados",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Por favor seleccione mínimo un producto.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Seleccione un mínimo de 2 ofertas para combinarlas",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Por favor proporcione precio de venta",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Productos agrupados",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"manojos",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Agregar",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Nuevo paquete",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Volver a los detalles del paquete",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Cancelar",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Borrar",
      "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Descargar paquetes",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Editar",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":" Búsqueda aplicada:",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"próximo",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Revisar productos de paquete",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Ahorrar",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Guardar paquete",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Mostrar ofertas",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Búsqueda rápida",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Volver a paquetes de socios",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Agregar paquete",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Ver paquete",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Precio sugerido:",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"¿El automóvil es renovable?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Categoría",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Período de carga",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Período de carga",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"texto de ayuda",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Precio de coste",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Descripción",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Fuente de alimentación",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Nombre",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nº de días para cancelación gratuita",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Proveedor",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Precio de venta",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Validez",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Buscar productos",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Cantidad comprada",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Descripción",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Nombre",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Detalles de la oferta",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Vista",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Categorías",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"proveedores",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Tipos de servicio",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Costo para el socio",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"costo para el cliente",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Pagos múltiples",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Pagos múltiples",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Pagos",
      "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Actualice el informe de ingresos haciendo clic en el botón de recarga antes de continuar.",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"¡No se encontró ningún historial!",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Búsqueda aplicada",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Tipos de servicio",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "PARTNER_COMMENTS_DETAILS":"Comentarios",
      "PARTNER_COMMENTS_TAB_CART_ITEM":"Artículo del carrito",
      "PARTNER_COMMENTS_TAB_INVOICE":"Factura",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Producto",
      "PARTNER_COMMISIONS":"Comisiones de socios",
      "PARTNER_CONSENT_HELP_TEXT":"Copie el enlace haciendo clic en el botón de arriba y abra en una nueva ventana de incógnito y complete el flujo de consentimiento.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Seleccione el tipo de facturación para la losa",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Agregar",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Borrar",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Editar",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Seleccione la aplicabilidad de la oferta",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Correo electrónico",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Nombre",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Número de teléfono",
      "PARTNER_CUSTOMER_FILTER_NAME":"Nombre",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
      "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} no está asignado a ningún administrador de cuentas",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"No hay clientes vinculados a ningún inquilino proveedor.",
      "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"La asignación de este plan incluirá las ofertas con márgenes definidos en el plan. ¿Está seguro de que desea actualizar el estado del Plan?",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Asignar",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Desactivar",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Permitir",
      "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"Al inhabilitarlo, se eliminará este plan, incluidas sus ofertas. Los productos comprados existentes tendrán el mismo margen de beneficio de este plan. ¿Está seguro de que desea actualizar el estado del Plan?",
      "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"Habilitar asignará este plan incluyendo las ofertas con márgenes definidos en el plan. ¿Está seguro de que desea actualizar el estado del Plan?",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Volver a planes de clientes",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Cambio de plan",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":" Gestionar planes para:",
      "PARTNER_CUSTOMER_REPORTS":"Informes",
      "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"Etiqueta de cliente agregada exitosamente",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"etiqueta de cliente actualizada correctamente",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"¿Está seguro de que desea actualizar el estado del Plan?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Lo sentimos, no se encontraron clientes",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Cuentas por cobrar",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Cuotas pasadas",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilidad",
      "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"10 principales clientes",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Tus clientes activos",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clientes",
      "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Asientos de clientes",
      "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Asientos de clientes directos",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Suscripciones de clientes directos",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Clientes Suscripciones",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"No se encuentran los detalles de la factura del revendedor para resellerId: {{resellerId}}, billingPeriodId: {{billingPeriodId}}",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Factura generada el",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Beneficio neto",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Otros cargos",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Ganancia",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Cantidad facturada",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"Factura MSFT",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Un vistazo rápido a la rentabilidad de su CSP",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilidad",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Resumen de sus cuentas por cobrar",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Cuentas por cobrar",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Pagos aún por cobrar",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Cuotas vencidas",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"Pendiente",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Vuelva a consultar después de que las facturas estén listas.",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Los 10 SKU principales",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Recuentos de productos por los 10 SKU principales",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Cuenta por SKU",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Rango de filtro",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 meses",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 meses",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 año",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Compra de Asientos",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Tendencia de compra de asientos a lo largo de los meses",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Compra de Asientos",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Asientos comprados",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Asientos comprados",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Tus revendedores activos",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"revendedores",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Por categoria",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Ingresos vs Costo",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Un gráfico para ver los detalles de ingresos versus costos por categoría",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Ingresos vs Costo por Categoría",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Costo",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Ingresos",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Seleccionar proveedor",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Ingresos vs Costo",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Ingresos frente a costes",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Ingresos frente a costes",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Clientes",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Asientos",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Suscripciones",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Facturas",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"revendedores",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Por fecha de renovación",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Ingresos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Los 5 mejores productos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Por ingresos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Los 5 productos principales por ingresos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Los 5 productos principales por ingresos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Asientos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Los 5 mejores productos",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Por asientos comprados",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Los 5 mejores productos por asientos comprados",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Los 5 mejores productos por asientos comprados",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Próximas renovaciones",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Ingresos",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"10 principales clientes",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Por ingresos",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Los 10 principales clientes por ingresos",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Los 10 principales clientes por ingresos",
      "PARTNER_LOGOS_LABEL_TEXT":"Seleccionar logotipo",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Imagen de vista previa",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Remover",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Categoría",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Remover",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Eliminar complemento",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Complementos",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Remover",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Tipo de facturación",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Categoría",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Descuento",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Nombre",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Complementos",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Eliminar oferta",
      "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Agregar oferta de socio",
      "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Ingrese la cantidad que desea comprar.",
      "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Editar oferta de contrato",
      "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Editar oferta de socio",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Introduzca un valor positivo",
      "PARTNER_OFFER_HYPERLINK_READ_LESS":"Leer menos",
      "PARTNER_OFFER_HYPERLINK_READ_MORE":"...Leer más",
      "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Habilite la oferta de prueba marcando esta casilla",
      "PARTNER_OFFER_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
      "PARTNER_OFFER_SLAB_DATA":"Datos de losa",
      "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":" Duración del período de prueba",
      "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Todos los clientes",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Comportamiento",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Correo electrónico",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de ofertas de socios.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Informe de ofertas de socios",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de ofertas de socios",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Ingrese la dirección de correo electrónico a quién desea enviar el correo electrónico, ingrese con coma (,) o punto y coma (;) y haga clic en enviar para recibir un informe.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Cancelar",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"¡El producto principal no está aprobado!",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"¡El producto infantil no se rechaza!",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Todos los artículos del carrito se aprueban independientemente de las infracciones de umbral en cada artículo de línea. ¿Estás seguro de que quieres aprobar el carrito?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"¿Está seguro de que desea aprobar el elemento?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Aprobado con éxito la línea de pedido del carrito",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Aprobó con éxito el carrito",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Aprobar todo",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Aprobar",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Aprobar solicitud",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Volver a Solicitudes",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Categorías",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Tipos de servicio",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"proveedores",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Estado",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"pedido el",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Precio unitario",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"rechazar todo",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Rechazar",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Rechazar solicitud",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Presentar cambios",
      "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Asegúrese de aprobar o rechazar al menos un producto antes de enviarlo",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"¿Está seguro de que desea rechazar todo el carrito?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"¿Está seguro de que desea rechazar el artículo?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Se rechazó con éxito la línea de pedido del carrito",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Rechazado con éxito el carrito",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"El estado se actualizó con éxito",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Aprobaciones",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Las siguientes solicitudes de compra de clientes están esperando su aprobación.",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Aprobaciones para",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Solicitudes de compra",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ciclos de facturación seleccionados",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Categorías seleccionadas",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Categorías de proveedores seleccionados",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Proveedores seleccionados",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Aplicar promoción a toda la oferta",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Categorías",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Seleccionar tipo de macro",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Límite de asientos del plan:",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Categorías de proveedores",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"proveedores",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Límite de asientos",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Tipos de servicio",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Plan de clonación completado con éxito",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Se acepta la solicitud del plan de clonación y se está procesando",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Se acepta la solicitud para crear un nuevo plan con todas las ofertas y se está procesando",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Valor macro",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"¿Puede el precio de venta ser menor que el precio del socio?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Se permite que el precio de venta sea menor que el precio del socio",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"¿Puede el precio de venta superar el precio de venta del proveedor?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio de venta supere el precio de venta del proveedor.",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Definir plan en nueva moneda",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Nombre",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Código de moneda del plan",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Nombre del producto",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Moneda de origen",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Moneda objetivo",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Código de moneda de destino",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"No hay una moneda de destino; comuníquese con el socio para definirla.",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"¿Cómo?",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Establezca el límite en -1 para requerir aprobación para todas las compras/actualización de asientos",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Use esta pantalla para configurar el umbral por conteo de asientos",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Establezca el límite en 0 para permitir la compra de asientos ilimitados",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Establezca un número positivo distinto de cero para definir el umbral",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Cualquier compra que exceda el umbral requiere la aprobación del Socio",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Haga clic en el botón enviar para guardar los cambios<b>",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Configurar límites de asientos para {{PlanName}}",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Definir macro y guardar con todas las ofertas",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Agregar ofertas faltantes",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Cancelar",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"plan de clonación",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Guardar plan con todas las ofertas",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Plan de clonación",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Plan de Clonación",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Creación de planes",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"El plan está en progreso. Haga clic para ver el estado",
      "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Límites de asientos",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodTypeBillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Catálogo de productos",
      "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Catálogo de productos",
      "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Catálogo de productos",
      "PARTNER_PRODUCT_CATEGORYID":"Categoria ID",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ID de tipo de consumo",
      "PARTNER_PRODUCT_DESCRIPTION":"Descripción",
      "PARTNER_PRODUCT_NAME":"Nombre",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"PrecioparaSocio",
      "PARTNER_PRODUCT_ProviderId":"ID de proveedor",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProveedorPrecio De Venta",
      "PARTNER_PRODUCT_SALETYPE":"Tipo de venta",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"costo para el cliente",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Divisa",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Identificación del cliente",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Nombre del cliente",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Descripción",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Identificación de producto",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Nombre del producto",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Unidad de medida",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Unidades",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Fecha de uso",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea aceptar esta solicitud?",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"¿Está seguro de que desea rechazar esta solicitud?",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Volver al historial de suscripciones",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Nuevo producto de PSA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Liberar",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Mapeo del acuerdo de liberación",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Mapeo del contrato de liberación",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Liberar mapeo de clientes",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Crear nuevo producto",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Este campo es obligatorio.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Categoría",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Costo",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Descripción del cliente",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Descripción",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Envío directo",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Identificador",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Referencia cruzada de integración",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Notas",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":" Precio",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Clase de producto",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"Acuerdo de nivel de servicio",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Pedido especial",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Subcategoría",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Impuesto sobre las ventas",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Tipo de producto",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Unidad de medida (UOM)",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Introduzca el costo",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Introduzca la descripción del cliente",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Introducir identificador",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Introducir notas",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Introducir precio",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Introducir descripción del producto",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Introducir referencia cruzada de integración",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Seleccione el tipo de producto",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Se creó un nuevo producto PSA con éxito",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "PARTNER_RESELLER_FILTER_NAME":"Nombre",
      "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{revendedor}} no está asignado a ningún administrador de cuentas",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Guarda tus cambios",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Descripción",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Nombre",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Seleccione",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"¿Puede el nuevo socio ofrecer un precio menor que el del socio?",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio del nuevo socio sea menor que el precio del socio",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"¿Puede el precio del socio superar el precio de venta del proveedor?",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio del socio supere el precio de venta del proveedor.",
      "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Agregar informe",
      "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"¿Estás seguro que quieres eliminar esto?",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Comportamiento",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Agregar destinatario",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Volver a informes",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Volver al destinatario",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Cancelar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Borrar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Editar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Correo electrónico",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Tipo de destinatario",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Destinatarios",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Ahorrar",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"A",
      "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Método de entrega",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Destinatarios",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Tipo de destinatario",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Destinatarios",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Historial de ejecución",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Comportamiento",
      "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Última ejecución el (hora local)",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Informes",
      "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Programado el",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Fecha",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Detalles",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Estado",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Ingrese al menos un nombre de impuesto y un porcentaje para {{taxType}}",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Por favor ingrese el porcentaje de impuesto válido",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Por favor complete los campos nombre del impuesto",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Tienes subimpuestos no guardados, ¿quieres continuar?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificación por correo electrónico del evento",
      "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Seleccione una vista",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"etiqueta de socio eliminada exitosamente",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"etiqueta de socio actualizada correctamente",
      "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Estilos",
      "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Plantillas",
      "PARTNER_STATUS":"Estado de socio",
      "PARTNER_TABLE_HEADER_NAME":"Nombre",
      "PARTNER_TABLE_HEADER_VALUE":"Valor",
      "PARTNER_TERMS_OF_USE":"Condiciones de uso para socios",
      "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Días de prueba",
      "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Ofertas para padres asociadas con la oferta de prueba",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"costo para el cliente",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Divisa",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Descripción",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Entregar",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Mes",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Coste total",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Fecha de uso",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Unidad de medida",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Unidades",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Fecha de uso",
      "PARTNER_USER_ALREADY_EXECUTE":"El usuario socio ya existe con este correo electrónico.",
      "PARTNER_USER_REMOVED":"El usuario asociado se eliminó correctamente",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Borrar",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Editar",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Clave de etiqueta",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Valores de etiqueta",
      "PARTNER_USERS_ADD_SUCCESS":"Acceso concedido a {{username}} correctamente",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Ya existe un usuario con el correo electrónico {{useremail}} en el sistema",
      "PARTNER_USERS_REMOVE_SUCCESS":"El usuario asociado eliminó {{username}} correctamente",
      "PARTNER_USERS_SAVE_SUCCESS":"El usuario socio actualizó {{username}} correctamente",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Por favor asigna un rol para continuar",
      "partnerauditlog":"Registro de auditoría",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Usuario socio actualizado exitosamente",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Volver a usuarios asociados",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Agregar",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Editar",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Usuario socio",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Correo electrónico",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Administrar etiqueta",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Clave de etiqueta",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Valores de etiqueta",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un carácter especial.",
      "PAYMENT_LABEL_FILTER":"Filtrado por",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Perfil de pago Establecido como predeterminado",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Perfil de pago eliminado",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Nombre del titular de la tarjeta",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Número de tarjeta",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Meses de vencimiento",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Año de caducidad",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Código de seguridad",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Nombre en la tarjeta de crédito",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Número de Tarjeta de Crédito",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Número de mes de dos dígitos",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Año de cuatro dígitos",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Número CVV de tres dígitos en la tarjeta de crédito",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Error al guardar los detalles de pago",
      "PAYMENT_PROFILE_SAVE_SUCCESS":" Detalles de pago guardados",
      "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Los detalles de pago se actualizarán en breve. Por favor, NO VUELVA A INTENTARLO y vuelva a comprobarlo después de algún tiempo.",
      "PAYMENT_PROFILE_TEXT_FAILURE":" Falla",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"Éxito",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Aceptado",
      "PAYMENT_STATUS_DESC_CHARGED":"Cargado",
      "PAYMENT_STATUS_DESC_DECLINED":"Rechazado",
      "PAYMENT_STATUS_DESC_FAILED":"Ha fallado",
      "PAYMENT_STATUS_DESC_FINALIZED":"Pendiente del mes actual",
      "PAYMENT_STATUS_DESC_INPROGRESS":"En progreso",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Parcialmente pagado",
      "PAYMENT_STATUS_DESC_SUCCESS":"Tuvo éxito",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Error tecnico",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Cancelar",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Entregar",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Actualizar",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Este campo es obligatorio.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Número de cuenta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":" Tipo de cuenta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Apodo",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"¿Es cuenta comercial?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Compañía",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individual",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"No",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Sí",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":" Comprobación",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Mes de caducidad",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Año de caducidad",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":" Ahorro",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Por favor espera mientras se carga la página",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Cantidad facturada",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Monto de pago",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Estado de pago",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Motivo del fracaso",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Observaciones",
      "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Pagado por",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Transacciones fallidas",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Pagos pendientes",
      "PAYMENTS_STATUS_DESC_FAILED":"Ha fallado",
      "PAYMENTS_STATUS_DESC_SUCCESS":"Éxito",
      "PAYMENTS_TABLE_HEADER_AMONT":"Monto de pago",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Cantidad facturada",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Motivo del fracaso",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Período de facturación",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Estado de la factura",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"¿Se basa en factura?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Propiedad de",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Monto de pago",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Cantidad a pagar",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"ID de pago",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Método de pago",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Monto pendiente",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Monto pendiente",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Observaciones",
      "PAYMENTS_TABLE_HEADER_STATUS":"Estado",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Pago parcial",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"ID de transacción",
      "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Ha superado el período de 7 días naturales para reducir los puestos de esta suscripción.",
      "PC_ACTIVATION_ERROR":"No se puede habilitar una suscripción secundaria cuando la suscripción principal no está activa",
      "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"El complemento no se puede comprar sin un producto base compatible",
      "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":" No se encontró la información del socio asesor para completar esta solicitud.",
      "PC_ADVISOR_QUOTE_NOT_FOUND":" No se encontró la cotización del asesor. Por favor, compruebe la información antes de volver a intentarlo.",
      "PC_ALREADY_ACTIVATE_ITEM":" Este artículo ya ha sido activado.",
      "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":" Se requiere un plan de Azure para comprar una suscripción de SaaS con un plan de facturación medido. Póngase en contacto con el socio para obtener más información.",
      "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Este método API no es compatible con la suscripción heredada",
      "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Disponibilidad a la que hace referencia la línea de pedido no encontrada",
      "PC_AVAILIBILTY_QUANTITY":" La disponibilidad tiene más de una opción de cantidad incluida. Póngase en contacto con el socio para obtener más información.",
      "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":" El plan de Azure no se puede mover al estado suspendido ya que tiene una o más suscripciones de Azure en estado activo.",
      "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"No se encontró el ID del plan de Azure especificado o no hay suscripciones de Azure activas en él. Se requiere un plan de Azure con suscripciones activas para comprar un producto SaaS con un plan de facturación medido",
      "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"No se encuentra la suscripción de Azure con el identificador de derecho proporcionado.",
      "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"El ciclo de facturación no puede ser más largo que el plazo.",
      "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"El ciclo de facturación de las suscripciones no coincide con el ciclo de facturación del pedido original",
      "PC_BILLING_CYCLE_IS_INVALID":"El ciclo de facturación no es válido.",
      "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Esta solicitud de transferencia de propiedad de facturación no se puede completar ya que los productos del mercado de terceros no se transfieren con las suscripciones. Elimine los productos del mercado de terceros de su selección y vuelva a intentarlo.",
      "PC_C3_CONNECTION_ERROR":"Error al conectar con el Centro de partners. Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.",
      "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"No se puede cambiar el ciclo de facturación si la suscripción no está asociada con una cuenta Mint",
      "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"No se puede procesar la actualización de Azure para Azure Partner Shared Services",
      "PC_CANCEL_PURCHASE_NON_TENANT":"Los inquilinos que no son de Sandbox no pueden cancelar compras que no sean suscripciones de software y software perpetuo.",
      "PC_CANCELLATION_NOT_ALLOWED":"¡La cancelación no está permitida en este momento!",
      "PC_CANNOT_CHECKOUT":"¡Un carrito ya enviado no se puede retirar!",
      "PC_CANNOT_PROCESS_AZURE_UPGRADE":" No se puede procesar la actualización de Azure. Póngase en contacto con el soporte para obtener más información.",
      "PC_CANNOT_PROCESS_REQUEST":"No se puede procesar la solicitud de transferencia para {1} transferencia {0}",
      "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Tiene una o más suscripciones de Azure en estado suspendido. Estos no se pueden transferir al plan de Azure.",
      "PC_CANNOT_UPDATE_BILLING_CYCLE":"Los desafíos técnicos impiden las actualizaciones de la suscripción. Póngase en contacto con el soporte.",
      "PC_CART_IN_PROCESS":"El carrito ya se está procesando, espere y vuelva a consultar después de un tiempo.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":" El artículo del catálogo no es elegible para la compra. Póngase en contacto con el soporte para obtener más información.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":" El artículo del catálogo no es elegible para agregar o quitar cantidad. Póngase en contacto con el socio para obtener más información.",
      "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"El artículo del catálogo requiere la aceptación de la atestación.",
      "PC_CREATE_ORDER_ERROR":"Crear orden no es exitoso",
      "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"La cuenta del cliente está bajo revisión. No se permiten transacciones hasta que se apruebe la cuenta.",
      "PC_CUSTOMER_MIGRATION_FAILED":" La migración de la licencia del cliente falló. Póngase en contacto con el soporte.",
      "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Falta el número de teléfono del cliente. Este error se produce si la empresa/organización del cliente se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia (MD) ), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA), pero no se pasa billingProfile.defaultAddress.phoneNumber.",
      "PC_DISCONTINUED_OFFER":" Esta oferta no está disponible actualmente para su compra, ya que puede haber sido descontinuada. Póngase en contacto con el socio para obtener más información.",
      "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Uno o más de los artículos del pedido no admiten la moneda dada, USD. La moneda de entrada debe ser la moneda del socio o del cliente (mercado).",
      "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"La fecha de finalización del período personalizado especificada en las instrucciones programadas para el próximo período debe estar dentro de la duración del primer período después de la renovación. Además, los productos de plazo mensual no pueden alinearse con una suscripción que finaliza el día 28, 29 o 30 del mes, a menos que esa fecha sea el último día del mes.",
      "PC_GENERIC_ERROR":" Algo salió mal. Póngase en contacto con el soporte para obtener más información o intente nuevamente después de verificar el estado de la operación realizada.",
      "PC_INACTIVE_RESELLER_PROGRAM":" El estado del programa de revendedor no está activo. Póngase en contacto con el soporte para obtener más información.",
      "PC_INCORRECT_CUSTOMER_ID":" Identificación de cliente incorrecta. Verifique la información y vuelva a intentarlo.",
      "PC_INITIATE_TRANSFER_REQUEST_FAILED":" No se pudo iniciar la solicitud de transferencia. Vuelva a intentarlo más tarde después de algún tiempo.",
      "PC_INVALID_ADD_ON":"El complemento no está relacionado con la suscripción principal especificada",
      "PC_INVALID_AZURE_SUBSCRIPTION":"Esta suscripción no es una suscripción de Azure válida",
      "PC_INVALID_CUSTOMER_INFORMATION":" La información proporcionada no coincide con la información actual del cliente. Por favor, compruebe la información y vuelva a intentarlo.",
      "PC_INVALID_DOMAIN":"Dominio inválido. Proporcione la dirección de dominio correcta y vuelva a intentarlo.",
      "PC_INVALID_ETAG":"Etag ya no es válido",
      "PC_INVALID_LINE_ITEM":"La secuencia de elementos de línea no está configurada correctamente, comuníquese con soporte.",
      "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Metadatos no válidos. (Si el precio del cliente, falta la moneda).",
      "PC_INVALID_MPN":"MPN no válido, póngase en contacto con el soporte para una mayor investigación",
      "PC_INVALID_OPERATION":" No se admite esta operación. Póngase en contacto con el socio para obtener más información.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID":" El valor de ID de registro de la organización no es compatible. Compruebe amablemente el valor de entrada antes de volver a intentarlo.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":" No se admite el valor o el formato del ID de registro de la organización. Compruebe amablemente el valor de entrada antes de volver a intentarlo.",
      "PC_INVALID_PROMOTION_CODE":" El código de promoción no es válido. Compruébalo e inténtalo de nuevo.",
      "PC_INVALID_QTY_FOR_SUBSCRIPTION":"La cantidad de suscripción no está dentro de la cantidad mínima y máxima permitida",
      "PC_INVALID_REQUEST":" Solicitud no válida. Compruebe amablemente los atributos de la solicitud antes de volver a intentarlo.",
      "PC_INVALID_SUBSCRIPTION":"No es una suscripción válida, póngase en contacto con soporte.",
      "PC_INVALID_SUBSCRIPTION_TRANSER":" La transferencia no se puede crear con una suscripción no válida. Por favor, compruebe la suscripción antes de volver a intentarlo.",
      "PC_ISSUE_ASSET_STATUS_CHANGE":"No se pudo completar la operación de cambio de estado del activo. Póngase en contacto con el socio para obtener más información.",
      "PC_ISSUE_OFFER_TYPE":" No se pudo completar la compra debido a un problema con el tipo de oferta. Póngase en contacto con el soporte.",
      "PC_ITEM_IS_NOT_AVAILABLE":"Este artículo no está disponible",
      "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":" El número solicitado de superó el límite restante permitido por suscripción para la oferta. Ajuste amablemente la cantidad antes de volver a intentarlo.",
      "PC_LIMIT_EXCEEDED":"Se ha superado el límite de uso para el ID de la oferta",
      "PC_LINE_ITEM_ISSUE":" Hay un problema con la línea de pedido. Póngase en contacto con el soporte para obtener más información.",
      "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"No se puede realizar la migración porque se especificaron dos o más migraciones complementarias con el mismo ID de suscripción actual",
      "PC_MISSING_ASSET_ID":" No se pudo completar la operación debido a que falta la identificación del activo. Póngase en contacto con el soporte para obtener más información.",
      "PC_MISSING_AZURE_SUBSCRIPTION":" No se puede procesar la actualización de Azure porque no se encontró la información de suscripción de Azure.",
      "PC_MISSING_MCA":"Por favor acepte MCA antes de proceder con la compra.",
      "PC_MISSING_REQUIRED_TAGS_IN OFFER":" La cuenta no tiene las etiquetas requeridas para la oferta. Póngase en contacto con el socio para obtener más información.",
      "PC_MISSING_TRANSFER_REQUEST":" No se encontró ninguna solicitud de transferencia.",
      "PC_MISSING_UPGRADE_REQUEST":"No se encontró ninguna solicitud de actualización.",
      "PC_MPN_NOT_FOUND":"No se encuentra el ID de MPN",
      "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"No se permiten múltiples solicitudes de transferencia. Otra solicitud de transferencia en curso.",
      "PC_NCE_MIGRATION_COULD_NOT_FOUND":"No se pudo encontrar la migración de New-Commerce.",
      "PC_NO_AZURE SUBSCRIPTION":"No se puede procesar la actualización de Azure porque el cliente no tiene suscripciones de Azure.",
      "PC_NOT_ELIGIBLE_AUTO_RENEWED":" La suscripción no es elegible para ser renovada automáticamente. Póngase en contacto con el socio para obtener más información.",
      "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"El socio no es elegible para esta transacción, comuníquese con soporte.",
      "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"La API de ofertas no admite elementos. Intente usar las API de Productos y/o SKU",
      "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Oferta no válida en el país especificado",
      "PC_OFFER_PURCHASE_ERROR":"ID de oferta no válido",
      "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":" Recientemente se compraron una o más suscripciones de Azure; estas suscripciones no se pueden cambiar en este momento. Por favor, inténtelo de nuevo más tarde.",
      "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":" No se admite la cancelación de SaaS basado en licencias a través de un pedido de parches. Use la suscripción de parche en su lugar",
      "PC_ORDER_CANCELLATION":" El pedido no se puede cancelar. Póngase en contacto con el soporte para obtener ayuda.",
      "PC_ORDER_CANCELLATION_ERROR":"El pedido no se puede cancelar.",
      "PC_ORDER_IN_PROGRESS":" Tu pedido está en proceso. Consulte su historial de pedidos para ver los pedidos recientes en unos minutos.",
      "PC_ORDER_NOT_FOUND":"¡Pedido no encontrado!",
      "PC_ORDER_VENDOR_POLICY":"No se pudo realizar el pedido debido a una política de proveedor vigente. Póngase en contacto con el soporte para saber más.",
      "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Falta la información de identificación de registro de la organización. Este error se produce si la empresa/organización del cliente se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia (MD) ), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA) pero no se pasa el número de registro de organización.",
      "PC_ORGANISATION_REGESTRATION_ID_MISSING":" Falta la información de identificación de registro de la organización. Ingrese amablemente una identificación de registro válida antes de volver a intentarlo.",
      "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"El valor de ID de registro de la organización no es compatible. Este error se produce si la empresa/organización del cliente no se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia ( MD), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA), pero intentaron especificar el número de registro de la organización.",
      "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":" La información de identificación de registro de la organización no es válida. Compruebe amablemente la entrada y vuelva a intentarlo.",
      "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Esta transferencia no está permitida ya que la transferencia original {0} está en curso",
      "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"El excedente ya está asignado a otro inquilino. Póngase en contacto con su cliente para resolver dudas sobre la propiedad",
      "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"El excedente no se puede establecer porque el cliente tiene suscripciones heredadas de Azure.",
      "PC_PARTNER_ATTESTATION_MISSING":"Falta la atestación de socio",
      "PC_PARTNER_INFORMATION_MISSING":" No se pudo completar la solicitud porque falta la información del socio. Póngase en contacto con el soporte para obtener más información.",
      "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"El ID de inquilino del socio aún no puede realizar migraciones a New Commerce en sus suscripciones",
      "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"No se puede actualizar el socio registrado para esta suscripción. Póngase en contacto con el soporte de Microsoft para obtener ayuda.",
      "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Hay un ajuste pendiente solicitado para este pedido. Póngase en contacto con el socio para obtener más información.",
      "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Hay una operación pendiente en esta suscripción. Vuelva a intentarlo después de 24 horas.",
      "PC_PHONE_NUMBER_MISSING":" Falta el número de teléfono del cliente. Proporcione amablemente el número de teléfono del cliente antes de volver a intentarlo.",
      "PC_PRICING_INFORMATION_NOT_PERMITTED":" No se permite el acceso a la información de precios. Póngase en contacto con el soporte.",
      "PC_PRODUCT_NOT_FOUND":"No se encontró el producto",
      "PC_PROPERT_NOT_SUPPORTED":" Esta propiedad no es compatible. Por favor revise y vuelva a intentarlo.",
      "PC_PURCHASE_NOT_SUPPORTED":"Sistema de compra no soportado. Póngase en contacto con el socio para obtener más información.",
      "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Se requiere documento de orden de compra.",
      "PC_PURCHASE_PRE_REQUISTIES_MISSING":" No se cumplen uno o más requisitos previos de compra. Póngase en contacto con el socio para obtener más información.",
      "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"La cantidad de suscripción no se puede aumentar o disminuir mientras la suscripción está suspendida",
      "PC_QUANTITY_CANNOT_DECREASED":" La cantidad de suscripción no se puede disminuir. Póngase en contacto con el socio para obtener más información.",
      "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":" La cantidad no está disponible para este artículo del catálogo. Ajuste amablemente la cantidad antes de realizar un pedido o comuníquese con un socio para obtener más información.",
      "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":" No se admiten cambios de cantidad en la suscripción con estado suspendido y eliminado. Póngase en contacto con el socio para obtener más información.",
      "PC_REQUEST_INFORMATION_NOT_AVAILABLE":" La operación no se pudo completar porque la información solicitada no está disponible actualmente. Vuelva a intentarlo o póngase en contacto con el soporte.",
      "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"La cantidad solicitada no está dentro de la cantidad permitida para esta oferta.",
      "PC_RESELLER_RELATIONSHIP_MISSING":" No existe una relación de revendedor con el cliente. Por favor, establezca una relación de revendedor antes de intentar esta acción nuevamente.",
      "PC_RI_PURCHASE_NOT_ENABLED":"Esta suscripción no está habilitada para la compra de RI. Póngase en contacto con el socio para obtener más información.",
      "PC_SANDBOX_LIMIT":"Se ha alcanzado el límite de sandbox. Ajuste amablemente el límite antes de realizar un pedido.",
      "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":" No se admite la venta en el país del cliente. Consulte la lista de mercados de CSP de Microsoft para obtener más información.",
      "PC_SKU_NOT_FOUND":"SKU no encontrado",
      "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Algo salió mal. Vuelva a intentarlo después de un tiempo.",
      "PC_SUBCRIPTION_ACTIVE_ISSUE":" Esta suscripción no es una suscripción activa. Póngase en contacto con el socio para obtener más información.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"La suscripción no se puede migrar a New Commerce porque tiene complementos.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"La suscripción no se puede migrar a New Commerce porque la combinación de duración del plazo y ciclo de facturación no es compatible con el producto New-Commerce.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"La suscripción no se puede migrar a New Commerce porque todavía no hay una ruta de migración válida para esta oferta.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"La suscripción no se puede migrar a New Commerce debido a una o más razones (posibles razones: la suscripción no está en estado Activo - la suscripción tiene promociones aplicadas - la suscripción tiene complementos adjuntos - la suscripción está demasiado cerca del final del plazo - la oferta de suscripción es aún no disponible en New Commerce)",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"La suscripción no se puede migrar a New Commerce porque tiene promociones aplicadas.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"La suscripción no se puede migrar a New Commerce porque la suscripción se está procesando actualmente.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"La suscripción no se puede migrar a New Commerce porque faltan menos de 24 horas desde el final del plazo.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"La suscripción no se puede migrar a New Commerce porque ha estado activa durante 1 mes o menos. Por favor, inténtelo de nuevo más tarde.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"La suscripción no se puede migrar a New-Commerce porque una o más suscripciones complementarias especificadas en la colección AddOnMigrations no existen o no están activas.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"La suscripción no se puede migrar a New Commerce porque el ID de socio registrado en la suscripción actual no es válido.",
      "PC_SUBSCRIPTION_HISTORY_ISSUE":" No se pudo recuperar el historial de suscripciones. Póngase en contacto con el soporte para obtener más información.",
      "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Su suscripción está en proceso de caducar. No se permiten más actualizaciones en esta suscripción.",
      "PC_SUBSCRIPTION_NOT_FOUND":"La suscripción que intenta usar ya no está disponible con Microsoft.",
      "PC_SUBSCRIPTION_NOT_REGISTERED":" Esta suscripción no está registrada. Póngase en contacto con el socio para obtener más información.",
      "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":" La oferta de suscripción no está disponible para la compra. Póngase en contacto con el socio para obtener más información.",
      "PC_SUBSCRIPTION_UPDATE":" La suscripción no está lista para ser actualizada. Vuelva a intentarlo después de un tiempo o póngase en contacto con el soporte si el problema persiste.",
      "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"El número deseado de suscripciones excedió el número máximo de suscripciones permitidas por cliente",
      "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"La suscripción no se puede migrar a New Commerce porque el estado no está activo.",
      "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"La fecha de finalización del compromiso de suscripción de destino debe ser posterior a la suscripción de origen",
      "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"La transición de destino no puede tener una duración de plazo inferior a la de la suscripción de origen.",
      "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"El campo ToSubscriptionId de la transición de destino no es válido. La suscripción de destino no está lista para actualizarse. Vuelva a intentarlo después de un tiempo.",
      "PC_TENANT_ALREADY_USED":"El dominio especificado durante la creación del arrendatario ya lo usa otro arrendatario. Elija otro dominio y vuelva a intentarlo",
      "PC_TENANT_NOT_EXISTS":"El nombre de dominio no existe, verifique el nombre de dominio.",
      "PC_TENANT_NOT_FOUND":"Inquilino no encontrado.",
      "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"La duración del término en la transición de destino no es válida.",
      "PC_TERM_EXCEEDED":"El plazo de compra inicial ya no está disponible para la compra/actualización",
      "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":" El ID del artículo del catálogo no está presente. Póngase en contacto con el soporte para obtener ayuda.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"La fecha de finalización del período personalizado especificada en las instrucciones programadas para el próximo período no coincide con la fecha de finalización de ninguna suscripción activa de OnlineServicesNCE que no sea de prueba ni se alinea con el final del mes calendario.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"La fecha de finalización del plazo personalizado especificada en las instrucciones programadas para el próximo plazo debe estar dentro de la duración del primer plazo después de la renovación. Los productos de plazo mensual no pueden alinearse con una suscripción que finaliza el 28, 29 o 30 del mes, a menos que esa fecha sea el último día del mes.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"La solicitud no pudo recuperar toda la información de recursos de disponibilidad. Compruebe amablemente después de algún tiempo antes de volver a intentarlo.",
      "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":" El programa solicitado no está activo. Póngase en contacto con el soporte para saber más.",
      "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":" El rol proporcionado no tiene los derechos para realizar la operación solicitada. Póngase en contacto con el soporte para obtener más información.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED":" No se admite esta operación. Póngase en contacto con el soporte para obtener más información.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":" Esta operación no se admite en este entorno. Póngase en contacto con el socio para obtener más información.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Esta solicitud de transferencia no se puede iniciar porque el cliente no ha comprado el plan de Azure.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":" Esta solicitud de transferencia no se puede iniciar porque el plan de Azure no se puede comprar. Póngase en contacto con el socio para obtener más información.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":" Esta solicitud de transferencia no se puede iniciar porque el plan de Azure no está activo, habilite el plan de Azure y vuelva a intentarlo.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":" Esta solicitud de transferencia no se puede iniciar porque el cliente tiene suscripciones heredadas de Azure.",
      "PC_TOKEN_MISSING":"Falta el token de socio en el contexto de la solicitud.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"El campo ToSubscriptionId de la transición de destino no es válido. Suscripción de destino CommitmentEndDate debe ser posterior a la suscripción de origen",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"El campo ToSubscriptionId de la transición de destino no es válido. La suscripción de destino debe estar activa.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"El campo ToSubscriptionId de la transición de destino no es válido. El ID de suscripción de destino no puede ser el mismo que el ID de suscripción de origen.",
      "PC_TRANSACTION_ELIGIBLE":"Usted puede ser elegible para esta transacción. Póngase en contacto con Soporte para obtener ayuda.",
      "PC_TRANSACTION_NOT_ELIGIBLE":" Usted no es elegible para esta transacción. Reduzca la cantidad o comuníquese con Soporte para obtener ayuda.",
      "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"La transferencia no se puede crear como socio: {0} nube nacional {1} no es compatible",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":" La transferencia no se puede iniciar porque faltan detalles de dominio/correo electrónico del socio de origen en la solicitud. Ingrese amablemente la información solicitada antes de volver a intentarlo.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":" La creación de la transferencia falló debido a que los detalles del socio de origen coincidieron con el socio solicitante. Póngase en contacto con el soporte de Microsoft para obtener más información.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Esta solicitud de transferencia de la propiedad de facturación no se puede completar porque las reservas de Azure no se transfieren con las suscripciones. Cancele las reservas de Azure asociadas con las suscripciones de su selección y vuelva a intentarlo.",
      "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"No se puede aceptar la solicitud de transferencia. Solicite al socio que cree una transferencia sin suscripciones de Azure.",
      "PC_TRANSFER_REQUEST_DISABLE":"La transferencia no se puede crear como socio: {0} no habilitado para esta función",
      "PC_UNABLE_TO_COMPLETE_THE_REQUEST":" No se puede completar la solicitud. Póngase en contacto con el soporte de Microsoft para obtener más información.",
      "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"No se puede crear el pedido. Hay un límite para la cantidad de planes de Azure que se pueden crear en cuentas de espacio aislado",
      "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":" No se pudo encontrar el perfil del cliente para completar la solicitud. Póngase en contacto con el soporte para obtener más información.",
      "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"No se pueden obtener suscripciones de Azure Active Directory para un cliente con ID de arrendatario {0} e ID de aprovisionamiento de fuente {1}",
      "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"No se puede procesar el pedido debido a un límite de crédito insuficiente, comuníquese con ucmwrcsp@microsoft.com para obtener más ayuda",
      "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Hubo un error inesperado al procesar la migración de New-Commerce.",
      "PC_UNSUCCESSFUL_TRANSFER_ORDER":" La orden de transferencia no tiene éxito. Póngase en contacto con el soporte para obtener más información.",
      "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Las operaciones de actualización actualmente no están permitidas para esta suscripción.",
      "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Las operaciones de actualización ya no están permitidas para esta suscripción.",
      "PC_UPDATES_NOT_ALLOWED":"No se puede actualizar una suscripción antes de activarla'",
      "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":" La solicitud de actualización no se puede procesar para una suscripción ya actualizada.",
      "PC_UPGRADE_REQUEST_IN_PROGRESS":" La solicitud de actualización no se ha completado porque hay otra solicitud de actualización en curso.",
      "PC_USER_LIMIT_EXCEEDED":"El número de asientos deseado superó el número máximo de asientos permitido por suscripción",
      "PC_USER_NOT_FOUND":"Usuario no encontrado en el arrendatario de destino.",
      "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Cuando sea parte de la oferta, se requiere un enlace de licitación o un documento de licitación.",
      "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"No es elegible para administrar el excedente para este cliente.",
      "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Ha pasado su ventana de cancelación. No podemos cancelar tu compra",
      "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Ha pasado su ventana de día para reducir asientos para esta suscripción.",
      "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Su empresa no ha aceptado el Acuerdo de socio de Microsoft (MPA). El administrador global de la cuenta CSP debe aceptar el MPA para reanudar las capacidades transaccionales completas.",
      "PCA_MISSING_MPA":"Falta el contrato de socio de Microsoft del socio.",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Obtener estado de pago pendiente de facturación",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Actualizar estado de pago en c3",
      "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Se actualizó el estado de pago de {{recordName}}",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Comportamiento",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"Estado de pago de facturación",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"Estado de pago C3",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Nombre de la entidad",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"número de factura",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Monto de pago",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Nombre de registro",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Ver detalles",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Solicitar ID",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"pedido el",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Observaciones",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Tipo de solicitud",
      "Percentage":"Porcentaje",
      "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Claves de software",
      "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Descargar software y claves",
      "PHONE_TABLE_HEADER_ACTIONS":"Comportamiento",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Número de teléfono",
      "PHONE_TABLE_HEADER_PHONE_TYPE":"Tipo de telefono",
      "PHONE_TYPE_HOME":"Hogar",
      "PHONE_TYPE_HOME_FAX":"Fax a domicilio",
      "PHONE_TYPE_MAIN":"Principal",
      "PHONE_TYPE_MOBILE":"Móvil",
      "PHONE_TYPE_OFFICE":"Oficina",
      "PHONE_TYPE_OTHER":"Otro",
      "PHONE_TYPE_WORK":"Trabajar",
      "PHONE_TYPE_WORK_FAX":"Fax de trabajo",
      "PLACE_HOLDER_SEARCH_CUSTOMER":"Buscar cliente",
      "PLACEHOLDER_ENTER_TEXT":"Ingrese texto",
      "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Seleccionar ciclo de facturación...",
      "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Seleccione el tipo de facturación...",
      "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Seleccionar categorías",
      "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Seleccionar tipo de categoría...",
      "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Seleccionar tipo de consumo...",
      "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Buscar código de moneda",
      "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Buscar cliente",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Seleccione un cliente proveedor en la lista o busque el nombre o la identificación del arrendatario...",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"Válido desde",
      "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Efectivo para",
      "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"milímetro",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Ingrese texto",
      "PLACEHOLDER_FOR_PLAN_NAME":"Ingrese el nombre del plan",
      "PLACEHOLDER_FOR_PRICE":" Precio",
      "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Seleccionar proveedores",
      "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Buscar cliente",
      "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Seleccione Funciones..",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Seleccione",
      "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Seleccione Valores de etiqueta..",
      "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Seleccione término-duración...",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Buscar...",
      "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Seleccione la duración de la prueba...",
      "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Seleccione el tipo de validez...",
      "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"AAAA",
      "PLACEHOLDER_SPECIAL_QUALIFICATION":"Cualificaciones especiales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Editar",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Editar cualificaciones especiales",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"El máximo de caracteres permitidos es 50",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"Debería tener al menos un carácter",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Ahorrar",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Ninguno",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Este campo es obligatorio",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Cerca",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Educación",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Segmento de educación",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Educación superior",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Sitio web",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Ninguno",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Entidad de propiedad estatal",
      "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"¿Seleccionar {{EntityName}} específico? Haga clic aquí...",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Escriba para buscar una etiqueta",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Seleccione un Inquilino en la lista o busque el nombre/id-inquilino...",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Plan de búsqueda",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Nombre del producto",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Identificación de producto",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Buscar con nombre de producto o ID de producto",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Nombre del producto, identificación",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Buscar suscripción",
      "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Ingrese identificaciones de correo electrónico válidas",
      "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"máx. precio de coste",
      "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"máx. precio de venta",
      "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"máx. validez",
      "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"mín. precio de coste",
      "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"mín. precio de venta",
      "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"mín. validez",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Monto del pago",
      "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Escriba aquí...",
      "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Elija un cliente existente de la lista o busque utilizando el nombre del cliente/ID del arrendatario/dominio",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Observaciones",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Buscar por cliente",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Buscar por estado",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Marque esta casilla si planea tener ofertas compuestas en este plan en el futuro.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"El estado se actualizó con éxito",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"planes",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Agregar nuevo plan",
      "PLAN_CAPTION_TEXT_PLANS":"planes",
      "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PLAN_CHANGE_FOR_CUSTOMER":" Cambiar plan del cliente",
      "PLAN_CHANGE_TEXT":"Cambio de plan",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Ofertas de planes compuestos",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"planes",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Volver a ofertas compuestas",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Agregar oferta compuesta",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Editar oferta compuesta",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Ofertas",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Artículos seleccionados",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":" Precio sugerido :",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Complementos",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Complementos",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Precio de lista",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Remover",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Descripción",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"ID del componente de facturación",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Nombre de la oferta",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":" Precio",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Agregar oferta compuesta",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Volver a los detalles del plan",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":" Ofertas compuestas para -",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Editar",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Ofertas incluidas",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Nombre",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":" Precio",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>en</b> {{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Inscribirse",
      "PLAN_DETAILS_IS_PUBLIC":"es público",
      "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Mercados admitidos",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"por mes",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Precio de venta estimado",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"por año",
      "PLAN_DETAILS_PARAGRAPH_4_TEXT":"una vez",
      "PLAN_DETAILS_SHARING_URL":"Compartir URL",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Envió exitosamente sus cambios",
      "PLAN_IS_IN_PROGRESS_EXCEPTION":"Plan aún en progreso. Por favor verifique el estado",
      "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"El valor mínimo es -1",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Establezca el límite en -1 para requerir aprobación para todas las compras/actualizaciones de puestos.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Establezca el límite en 0 para permitir la compra de asientos ilimitados.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Establezca un número positivo distinto de cero para definir el umbral.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_4":" Cualquier compra que supere el umbral requiere la aprobación del Socio.",
      "PLAN_LEVEL_SEATLIMIT_EDIT":"Establecer el límite de asientos del producto del plan",
      "PLAN_LEVEL_SEATLIMIT_REVERT":"Volver al límite de asientos del nivel del plan",
      "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Cargue las últimas promociones de Microsoft NCE disponibles en los planes.",
      "PLAN_MACRO_DETAILS_LABEL_TEXT":"detalles de macros",
      "PLAN_MACRO_NAME_LABEL_TEXT":"Nombre de la macro",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Cantidad comprada",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Valor macro",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Volver a los planes",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Gestionar ofertas compuestas",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Detalles del plano -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Nuevo plan",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Cargar promociones",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Cancelar",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Cargar promociones desde proveedor",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Entregar",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Es privado",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"¿Permitir que las suscripciones de CSP se compartan con las ofertas del plan?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Ofertas",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Estos son los pasos para anular una oferta heredada:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Marque la casilla junto a la oferta que desea anular",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Proporcionar un nuevo nombre descriptivo",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Especificar el nuevo precio de venta",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"El nuevo plan comenzaría a enumerar las ofertas anuladas junto con aquellas que se heredan pero no se anulan.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Nota:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Anulado del plan principal",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"seleccionado",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Elementos",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Complementos",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible para compra",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"ID del componente de facturación",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Precio de lista",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Nombre de la oferta",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"AÑADIR",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Tipo de licencia",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Remover",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Precio de venta",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Actualizar precio",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":" Nombre del plan:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":" Precio:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Establecer nuevo precio",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Tipo de licencia",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Oferta anulada",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Oferta seleccionada",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Descripción",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Nombre del plan",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"Compartir URL",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"planes",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Administrar",
      "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"El nombre descriptivo no puede estar vacío",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Mostrar ofertas con promoción.",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"ofertaConPromoción",
      "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"OFERTA DE PRUEBA",
      "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Nombres de los padres",
      "PLAN_NAME_IS_INVALID":"El nombre del plan no es válido",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"¿Se basa en facturas?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":" Establecer nuevo precio para",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"El cambio de precio sería efectivo.",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Nuevo precio",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Precio actual",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Se actualizó exitosamente el precio de oferta del plan.",
      "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Mostrar ofertas con margen <= 0",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Mostrar ofertas con margen > 0 y < 10",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Mostrar ofertas con margen >= 30",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Mostrar ofertas con margen >= 10 y < 20",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Mostrar ofertas con margen >= 20 y < 30",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Gestionar plan",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Plan de anulación",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"planes",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Volver a los planes",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Editar plan",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Plan de anulación",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"¿Permitir que las suscripciones de CSP se compartan con las ofertas del plan?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Es privado",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Descripción",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Nombre del plan",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":" Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Nota:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Estos son los pasos para anular una oferta heredada:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Marque la casilla junto a la oferta que desea anular",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Proporcionar un nuevo nombre descriptivo",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Especificar el nuevo precio de venta",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"El nuevo plan comenzaría a enumerar las ofertas anuladas junto con aquellas que se heredan pero no se anulan.",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible para compra",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"ID del componente de facturación",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"precio del ERP",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Precio de lista",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Oferta anulada",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":" Elementos seleccionados para anular",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Ofertas",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":" Compartir URL",
      "PLAN_POPUP_GRID_HEADER":"Descargar planilla cuadriculada",
      "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Este producto ya está disponible en plan, ¿quieres agregar el producto nuevamente?",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Código de moneda del plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Nombre del plan",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Código de moneda de destino",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Seleccione",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Código de moneda fuente",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Código de moneda de destino",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Definir nueva moneda",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Número de asientos permitidos",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Precio de venta",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Numero de asientos",
      "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Valor de asiento no válido para el producto {{PlanProductName}}",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Ocurrió un error al actualizar los asientos",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Asientos actualizados con éxito",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Losas guardadas con éxito",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Editar",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Anular",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "PLAN_TABLE_HEADER_TEXT_NAME":"Nombre",
      "plandetails":"Detalles del plan",
      "planproductcurrencyconversion":"Conversión de moneda",
      "PlanProductId":"PlanProductId",
      "plans":"planes",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Seleccione al menos dos ofertas para crear una oferta compuesta",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Oferta compuesta guardada correctamente",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Oferta compuesta actualizada correctamente",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Cancelar",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Cerca",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Entregar",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Todos los productos",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"No se encontraron registros",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Guarde o cierre la fila nueva o de edición existente.",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Ingrese un valor mayor que \" + minValue : \"Ingrese un valor entre \" + minValue + \" y \" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Ingrese un valor entre {{minValue}} y {{maxValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Por favor ingrese un valor mayor a {{minValue}}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Detalles del contrato",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Tipo de facturación",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Costo para el socio",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Losas",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Precio de venta",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"valor máximo",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Valor mínimo",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Aplicabilidad",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Ya tienes una oferta de contrato en tu plan. Elimina/desactiva la oferta antes de agregar una nueva.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Contrato",
      "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Reserva",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Plan anulado actualizado correctamente",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan anulado con éxito",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Seleccione las ofertas que desea anular del plan base.",
      "PLANS_SAVE_SUCCESS":"Plan guardado exitosamente",
      "PLANS_UPDATE_SUCCESS":"Plan actualizado exitosamente",
      "PLEASE_ENTER_THE_REASON":"Por favor proporcione una razón.",
      "PO_NUMBER":"Número de orden de compra",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Asignación de políticas Alcances",
      "PONUMBER_BUTTON_CLOSE":"Cerca",
      "PONUMBER_BUTTON_SKIP":"Saltar",
      "PONUMBER_BUTTON_SUBMIT":"Entregar",
      "PONUMBER_DELETE_CONFIRMATION":"¿Está seguro de que desea eliminar este número de pedido del carrito?",
      "PONUMBER_DELETE_ERROR_MESSAGE":"¡Ocurrió un error al eliminar el número de pedido del carrito!",
      "PONUMBER_DELETE_SUCCESS_MESSAGE":"¡Se eliminó el número de orden de compra solicitado con éxito!",
      "PONUMBER_PLACEHOLDER":"Ingrese el número de orden de compra",
      "PONUMBER_POPUP_TITLE":"Agregar número de orden de compra",
      "PONUMBER_TITLE":"numero postal",
      "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"¿Está seguro de que desea poner en cola todos los planes?",
      "POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "POPUP_BUTTON_TEXT_CLOSE":"Cerca",
      "POPUP_BUTTON_TEXT_CONFIRM":"Confirmar",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Se perderán todas las ofertas seleccionadas según el ciclo de facturación seleccionado. ¿Quieres continuar?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Este cliente ya contiene un cupón: <b>{{CouponName}}</b>, ¿quieres reemplazarlo?.",
      "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"La eliminación del cliente de facturación también eliminará los perfiles de pago asociados. ¿Está seguro de que desea eliminar el enlace del cliente de facturación para {{CustomerName}}?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la notificación personalizada?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Notificación personalizada eliminada correctamente.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{CustomerName}}?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"Eliminar aquí resultará en la eliminación de la oferta de los planes de revendedor agregados y sus clientes no podrán comprarla más. ¿Está seguro de que desea eliminar la oferta {{distributorOffer}}?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"Se eliminó {{customOfferName}} con éxito.",
      "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"¿Está seguro de que desea eliminar esta notificación?",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"BORRAR",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{customOfferName}}?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"Se eliminó {{customOfferName}} con éxito.",
      "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>OFERTA DE PRUEBA</sup>",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{planName}}?",
      "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"¿Estás seguro que deseas eliminar la cita?",
      "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la línea de artículo de cotización <b>{{ProductName}}</b>?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"¿Estas seguro que quieres borrarlo?",
      "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar esta entidad etiquetada?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar el mensaje {{MessageBody | límite a: 30}}?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"¿Seguro que quieres borrar este mensaje?",
      "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la notificación del webhook?",
      "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"Notificación de webhook eliminada correctamente",
      "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"Notificación de webhook eliminada correctamente.",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"¿Está seguro de que desea desactivar {{productName}}?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> es la oferta principal para el producto de prueba.<br/> Si esta oferta está deshabilitada, la oferta de prueba también se desactivará.<br/> ¿Está seguro de que desea deshabilitar < b>{{nombre del producto}}<b/>?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> es la oferta principal para el producto de prueba.<br/> Deshabilitar este producto afectará la conversión del producto de prueba en suscripción<br/> ¿Está seguro de que desea deshabilitar <b>{ {nombre del producto}}<b/>?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"¿Está seguro de que desea habilitar {{productName}}?",
      "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> la oferta principal no está activa.<br/> Al habilitar la oferta de prueba también se activará la oferta principal.<br/> ¿Está seguro de que desea habilitar <b>{{productName}} <b/>?",
      "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Se elimina la notificación de participación.",
      "POPUP_PLAN_DELETED":"Se elimina el plan {{planName}}.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"REVERTIR",
      "POPUP_REVERT_SUB_HEADER_TEXT":"¿Estás seguro de que quieres revertir?",
      "POPUP_TEXT_DEACTIVATE_PSA_LOG":"¿Está seguro de que desea desactivar los registros? No eliminamos registros en el PSA, esta operación le permitirá volver a cargar los datos más de una vez desde C3 a PSA. Tenga en cuenta que volver a cargar podría crear duplicados en PSA.",
      "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"Registros de PSA desactivados con éxito",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Se perderán las ofertas seleccionadas con el ciclo de facturación actual. ¿Aún deseas continuar?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Tienes cambios sin guardar. ¿Aún quieres continuar?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar el nombre del cliente?",
      "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar el nombre del revendedor?",
      "POPUP_WEB_NOTIFICATION_DELETED":"Se eliminó la notificación web {{MessageBody}}.",
      "POPUP_WEB_NOTIFICATION_DELETED2":"Se elimina la notificación web.",
      "PORTAL_ACCESS_GRANTED_TO_USER":"El acceso al portal se otorga a {{UserName}}",
      "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Seleccionar página del portal",
      "PORTAL_PAGE_NAME":"Nombre de la página del portal",
      "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Datos de uso",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"El cambio de precio se aplicará desde el inicio del ciclo de carga actual de la suscripción.",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Inmediatamente",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"De la próxima facturación",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"De la próxima renovación",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Cerca",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Cerca",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Vista previa de notificación por correo electrónico",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Vista previa de notificación por correo electrónico",
      "Price":" Precio",
      "PRICE_CHANGE_CURRENCY":"Divisa",
      "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"El valor ingresado no se ajusta a la configuración de bloqueo de precio elegida",
      "PRICE_CHANGE_NEW_PRICE":"Nuevo precio",
      "PRICE_CHANGE_OLD_PRICE":"Precio anterior",
      "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instrucciones para la API de precios de Microsoft",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Gracias por brindar su consentimiento. La aplicación se puede configurar para acceder a los precios de Microsoft.",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones en Configuración -> Centro de socios en <b>{{applicationName}}</b>.",
      "PRICING_API_VIEW_INSTRUCTIONS_1":" para copiar el vínculo que captura el consentimiento del socio para otorgar a esta aplicación acceso al Centro de socios siguiendo la API de precios de Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Abra el enlace en una sesión de incógnito y complete la experiencia de consentimiento con una cuenta que sea un administrador global en su arrendatario (socio) y tenga asignada la función de agente administrador en el Centro de partners.",
      "PRICING_API_VIEW_INSTRUCTIONS_3":"Haga clic en el botón Probar para verificar si la aplicación funciona con la API de precios de Microsoft.",
      "PRICING_API_VIEW_INSTRUCTIONS_5":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Tipo de facturación",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Precio de coste",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":" Nombre para mostrar",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"valor máximo",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"Valor mínimo",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Precio de venta",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Precio de venta",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Tipo de facturación",
      "PRIVACY_POLICY_BUTTON_TEXT":"POLÍTICA DE PRIVACIDAD",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Proceso de incorporación masiva de clientes completado",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Inició proceso de Incorporación Masiva de clientes",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Proceso para cargar facturas en Quickbooks completado",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Se inició el proceso para cargar facturas en Quickbooks",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Proceso para cargar facturas a Xero completado",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Iniciado proceso de carga de Facturas a Xero",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Error en el proceso de carga de facturas en Quickbooks",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Proceso de carga de Facturas a Quickbooks con éxito",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Error en el proceso de carga de facturas en Xero",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Proceso de carga de Facturas a Xero con éxito",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Los elementos seleccionados han sido eliminados.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Cambiar el valor de este campo resultará en la pérdida de los elementos seleccionados.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Número de días máximos permitidos para cancelación sin coste.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Tipo de venta",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Validez",
      "PRODUCT_CANCELLATION_TEXT":"La cancelación está disponible",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Actualmente estamos realizando algunos cambios en este producto. Espere a que finalicen estos procesos antes de realizar una nueva solicitud.",
      "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Seleccione una forma de obtener el informe del catálogo de productos",
      "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"Correo electrónico enviado correctamente",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":" Búsqueda aplicada:",
      "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nombre del agente",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Fecha final",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Comisión(%)",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Fecha de inicio",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"ID de producto interno",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"código SP",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nombre del agente",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"monto de la comisión",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Código de moneda",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"código SP",
      "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"La comisión por los productos de un Cliente se calculará una vez finalizadas las facturas. Para calcular las comisiones de un Cliente, solo se tendrán en cuenta los detalles de la comisión especificados por el usuario en el Período de Facturación respectivo. Si no se especifican los detalles de la comisión en un período de facturación, se tendrán en cuenta los detalles de la comisión definidos en el período de facturación anterior y que tienen validez hasta el período de facturación de la Factura.",
      "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
      "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
      "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Catálogo de importación",
      "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Validar catálogo",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Nombre del agente",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Fecha de finalización de la comisión",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Comisión(%)",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Fecha de inicio de la comisión",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Nombre de dominio",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"ID de producto interno",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"código SP",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Instrucciones",
      "PRODUCT_COULD_NOT_FOUND_TEXT":"No se pudo encontrar el tipo de producto.",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Aprobar",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Ciclo de facturación",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Cantidad actual",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Comentario del cliente",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Rechazar",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Cantidad requerida",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Límite de asientos",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Cantidad de origen",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Leer menos",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Leer más",
      "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
      "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Atributos del producto",
      "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
      "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importar catálogo",
      "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Validar catálogo",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Ciclo de facturación",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Categoría",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Secuencia de visualización",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"ID de oferta",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Código de producto",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Nombre del proveedor",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
      "PRODUCT_LIST_ADDON_LABEL":"Añadir",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Identificación de suscripción",
      "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Cancelamos esta solicitud debido a que se actualizaron algunos cambios.",
      "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"Esperando la aprobación.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Seleccione al menos un producto activo antes de continuar.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Seleccione al menos un usuario de la lista.",
      "PRODUCT_MAPPING":"Mapeo de productos",
      "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación de los productos seleccionados?",
      "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Este producto no está disponible para su compra.",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Producto no asignado porque el arrendatario está vinculado a varios clientes",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Producto no asignado porque el arrendatario está vinculado a varios clientes o el producto ya se ignoró",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"El producto ya está mapeado",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Porcentaje",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Cantidad comprada",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Nombre del alcance",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"No puede actualizar la cantidad a un valor superior a la cantidad disponible. Si desea hacer esto, aumente la cantidad desde la vista de detalles básicos.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Puede actualizar la cantidad mediante la edición en línea y hacer clic en el botón Enviar para guardar.",
      "PRODUCT_OWNERSHIP_SUCCESS":" Propiedad actualizada exitosamente",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Agregar",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
      "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Guardar cambios",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Introduzca un valor de etiqueta único",
      "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Etiquetas de productos guardadas con éxito",
      "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Etiquetas de productos",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Descripción",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Agregar clave de etiqueta",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Agregar valor de etiqueta",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Descripción",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Clave de etiqueta",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Valor de la etiqueta",
      "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Etiquetas de productos",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Categoría",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Código de moneda",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Código de mercado compatible",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Duración del mandato",
      "PRODUCT_VIEW_GRID":"Red",
      "PRODUCT_VIEW_LIST":"Lista",
      "productcatalogue":"Catálogo de productos",
      "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Se renueva/caduca (en días)",
      "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Fecha máxima de pedido",
      "PRODUCTS_REPORT_MAX_QUANTITY":"Cantidad máxima",
      "PRODUCTS_REPORT_MIN_QUANTITY":"Cantidad mínima",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Panel",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Perfil de cliente",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Perfil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Detalles del banco",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":" Tarjeta de crédito",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"CCA",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Cuenta bancaria",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":" Tipo de pago",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Por favor seleccione Tipo de Pago",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Perfil de pago actualizado exitosamente",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Detalles de usuario",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Ciudad",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"País",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Correo electrónico",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Nombre de pila",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Apellido",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Línea 1",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Línea 2",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Estado",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Cremallera",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Correo electrónico",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Configuración del cliente",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Configuración de revendedor",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Borrar",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Editar",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Eliminar perfil de pago",
      "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Establecer como perfil de pago predeterminado",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Número de teléfono",
      "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
      "PROFILE_TAB_CONFIGURATION_SETTING":"Ajustes de configuración",
      "PROFILE_TAB_ORGANIZATION_SETUP":"Configuración de la organización",
      "PROFILE_TAB_PAYMENT_PROFILE":"Perfil de pago",
      "PROFILE_TAB_USER_DETAILS":"Detalles de usuario",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Hacer por defecto",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Entregar",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Ahorrar",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Ahorrar",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Ciudad",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"País",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Correo electrónico",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Nombre de pila",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Apellido",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Línea 1",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Línea 2",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Número de teléfono",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Estado",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Cremallera",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Dirección de Envio",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Detalles de facturación",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Añadir método de pago",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Nota:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"El nuevo perfil de pago se utilizará para todas sus transacciones posteriores.",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Cambiar método de pago",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Detalles del perfil de pago",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Por favor agregue un método de pago.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Ya tienes un perfil de pago registrado.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Últimos 4 dígitos de tu método de pago",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Método de pago",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Detalles de usuario",
      "PROMOTION_ERRORS_ALERT_TEXT":"Errores de promoción",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"¡No puedes comprar este artículo porque tu cuenta no está vinculada a este proveedor!",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"No se pudo generar la contraseña para el usuario administrador del cliente del proveedor",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Contraseña generada con éxito para el usuario administrador del cliente del proveedor",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Las compras contra inquilinos de CSP pueden fracasar debido a la falta de consentimiento del cliente. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><b class=\"red \"> Haga clic aquí para redirigir al perfil de inquilino y agregar MCA </b></a>",
      "PROVIDER_CUSTOMER_DOMAIN":"Dominio del cliente del proveedor",
      "PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
      "PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor",
      "PROVIDER_DESC_MICROSOFT":"microsoft",
      "PROVIDER_DESC_MICROSOFT_NON_CSP":"Microsoft sin CSP",
      "PROVIDER_DESC_PARTNER":"Compañero",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nombre del proveedor",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Costo para el socio",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Monto de pago",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Fecha de pago",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Proveedor",
      "PROVIDER_PRODUCT_ID":" Identificación del producto del proveedor:",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Código de país",
      "PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
      "PROVIDER_RESELLER_MPNID":"ID de MPN del revendedor del proveedor",
      "PROVIDER_RESELLER_NAME":"Nombre del revendedor del proveedor",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Error de detalles",
      "PROVIDER_RESELLER_ONBOARDING_STATUS":"Estado de incorporación",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"Seleccione",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"microsoft",
      "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Cliente existente",
      "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Nuevo cliente",
      "providers":"proveedores",
      "provisioning":"Aprovisionamiento",
      "PROVISIONING_LABLE_TEXT":"Aprovisionamiento...",
      "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Maestro",
      "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Acuerdo Marco",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Seleccione un archivo para cargar",
      "PSA_BULK_UPLOAD_FAILED_STATUS":"Error en la asignación masiva de entidades actualizada en C3.",
      "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"El mapeo de productos de compra masiva se cargó parcialmente con éxito en C3. Verifique la hoja de mapeo de entidades no válidas descargada para volver a trabajarla.",
      "PSA_BULK_UPLOAD_SUCCESS_STATUS":"El mapeo de productos de compra masiva se cargó exitosamente en C3.",
      "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Prueba de conectividad",
      "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Contrato de búsqueda",
      "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Seleccionar contrato",
      "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"Buscar servicio PSA",
      "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"Seleccionar producto PSA",
      "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"No hay acuerdo asignado para este ciclo de facturación",
      "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"No pudimos obtener la fecha de inicio del contrato de PSA, por lo tanto, no pudimos alinear",
      "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"No hay contratos asignados para este ciclo de facturación",
      "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Estos registros no caen en el período seleccionado",
      "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"Los informes de fecha de inicio, fecha de finalización y hora requieren horas de inicio y finalización o falta el valor de la tarifa de configuración. Estos son valores necesarios para crear un contrato. Actualice estos valores en las configuraciones del cliente.",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"La fecha del historial de suscripciones es anterior a la nueva fecha que se calcula después de realizar la operación de alineación con la fecha de inicio del contrato",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Al intentar alinear, no se encontraron suscripciones activas para realizar la operación de alineación.",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Seleccionar categoría",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Seleccionar clase de producto",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"Seleccionar SLA",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Seleccione subcategoría",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Seleccione UOM",
      "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inactivo",
      "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Búsqueda de clientes de PSA",
      "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Seleccionar Acuerdo PSA",
      "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Seleccionar categoría",
      "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Seleccione {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK? 'acuerdo de contrato' }}",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Seleccionar cliente",
      "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Seleccionar cliente de PSA",
      "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"Seleccionar producto PSA",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Seleccione {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK? 'Servicio PSA' : 'Producto PSA' }}",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Seleccionar inquilino",
      "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"La actualización de datos está en proceso; se podrá acceder a otras opciones una vez que se complete el último proceso de datos.",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Advertencia",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Los datos de fechas anteriores no estarán disponibles después de usar la fecha filtrada para los clientes seleccionados.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Nota",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Tenga en cuenta que para cargar facturas regeneradas necesita soporte del equipo de soporte de C3.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"Establecer la fecha de inicio y la fecha de finalización aquí se reflejaría en las adiciones creadas en connectwise como fecha de inicio adicional y fecha de cancelación para todas las partidas de factura aplicables.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Si no se establece ninguna fecha de inicio y fecha de finalización, se considerarán las fechas del período de facturación de la factura.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"La lista de clientes a continuación solo contendrá los clientes que tengan facturas con estado \"Finalizado\" para el período seleccionado.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Si se selecciona la casilla de verificación <span class=\"font-italic\">(Alinear con la fecha de inicio del contrato)</span>, calcularemos el próximo período de contrato reciente para cargar. Tenga en cuenta que el próximo período de contrato reciente se calcula solo mensualmente, incluso si el período de contrato es <span class=\"font-italic\">Anual</span>.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"La fecha de inicio restringirá la consideración de los registros anteriores para la carga.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Utilice los filtros solo si está tratando de enviar datos por primera vez para un cliente. Por ejemplo: <ul><li>Registro de socios, es decir, primer paso de C3 a Autotask</li><li>Incorporación de nuevos clientes</li></ul>",
      "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Puede comenzar el proceso de carga seleccionando los clientes y haciendo clic en el botón <span class=\"bold\">Validar</span> para comenzar el proceso de validación donde se valida el historial de suscripción de los clientes seleccionados.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Sincronice con el Centro de afiliados antes de cargar",
      "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Tenga en cuenta que todas las fechas (incluidos los filtros) se considerarán en UTC.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Puede utilizar los registros haciendo clic en la página <span class=\"bold\">Ver registros</span> para seguir el progreso de la validación.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_3":"En caso de errores de validación, realice las correcciones necesarias y vuelva a intentarlo.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Una vez que la validación sea exitosa, puede proceder a <span class=\"bold\">Subir</span> los datos a Autotask.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Si la validación es exitosa, puede proceder a <span class=\"bold\">Subir</span> los datos a ConnectWise.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"Todos los datos del historial de suscripciones en C3 se enviarán a Autotask una vez. Si ocurre un error durante el proceso de carga/validación, permitimos volver a intentar la carga; de lo contrario, los datos se cargan solo una vez.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"La opción de repetir la carga solo se da en caso de error.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Haga clic en este botón para probar la conectividad a PSA antes de proceder a validar.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Solo se considerarán para la carga los nuevos registros del historial de suscripciones de clientes seleccionados, si los hay. Los clientes seleccionados pueden ser excluidos si no hay nuevos registros.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_8":"Al hacer clic en la casilla de verificación junto al título, puede validar los registros de suscripción para todos los clientes activos en el sistema.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Seleccione los clientes que desea cargar de la siguiente lista",
      "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Marque esta casilla solo si desea validar los registros de suscripción para todos los clientes activos en el sistema.",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"El acuerdo de PSA no está asignado al cliente c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"El cliente externo no está asignado al cliente c3",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"El producto externo no está asignado al producto c3",
      "PUBLIC_SIGNUP_ADD_TO_CART":"Añadir a la cesta",
      "PUBLIC_SIGNUP_ADMIN_PORTAL":"Portal de administración",
      "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"aplicar promociones",
      "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Solicitud de asistencia para registro público",
      "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"La pasarela de pago de autorización aún no es compatible. Póngase en contacto con el soporte para obtener ayuda.",
      "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Volver a la lista de productos",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Buscar producto",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Buscar productos",
      "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Las mejores características de su clase para ayudarlo a tener éxito.",
      "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Encabezado de párrafo de banner de registro público",
      "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Información de la cuenta",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"una vez",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"por 3 años",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"por mes",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"por año",
      "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Ver carrito",
      "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Contiene complementos",
      "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Contiene producto vinculado",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Seleccione al menos una oferta de Microsoft para comprobar",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Aplica el cupón antes de realizar el pedido.",
      "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Términos y condiciones.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Habilite los términos y condiciones de NCE para pedir productos de Microsoft NCE.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":" <a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">Términos y condiciones de NCE.</a>",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"¡Tu carrito de compras está vacío!",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"No dude en explorar nuestra selección para encontrar los productos que necesita. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Regrese a nuestra tienda web</strong></a> y comience a agregar artículos a su carrito.",
      "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Sitio web",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Confirmar",
      "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Soporte de contacto",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Contáctenos",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Seguir comprando",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Acepto lo último",
      "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"No cierre este navegador hasta que se complete el proceso",
      "PUBLIC_SIGNUP_EDIT_TEXT":"Editar orden",
      "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"La pasarela de pago Ezidebit aún no es compatible. Póngase en contacto con el soporte para obtener ayuda.",
      "PUBLIC_SIGNUP_FAILED_WARNING":" Disculpas. El sistema backend no pudo configurar la cuenta debido a un error desconocido. Póngase en contacto con el soporte con el BatchID",
      "PUBLIC_SIGNUP_FAILED_WARNING1":" para ayudarte más.",
      "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Mercados admitidos",
      "PUBLIC_SIGNUP_GO_TO_CART":"Ir al carrito",
      "PUBLIC_SIGNUP_GOTO_HOME":"Ir a casa",
      "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"Inscribirse",
      "PUBLIC_SIGNUP_INVALID_QUANTITY":"Los productos en el carrito tienen cantidades no válidas",
      "PUBLIC_SIGNUP_LEARNER_PORTAL":"Portal del alumno",
      "PUBLIC_SIGNUP_LOGS_WARNING":"El procesamiento puede demorar un tiempo y el estado se informará en esta página. Si te alejas de la página o has cerrado el navegador por accidente, te mantendremos informado a través de un correo electrónico.",
      "PUBLIC_SIGNUP_NEXT":"próximo",
      "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"No hay descripción disponible",
      "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Hemos creado el carrito con éxito",
      "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"La oferta ya está disponible en el carrito",
      "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Proporcione al menos 1 cantidad",
      "PUBLIC_SIGNUP_ORGANIZATION_ID":"Identificación de la organización",
      "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"No se admite el registro ya que la pasarela de pago aún no está configurada. Póngase en contacto con el soporte para obtener ayuda.",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Método de pago",
      "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Pagar ahora",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Realizar pedido",
      "PUBLIC_SIGNUP_PORTLET_HEADER":"Inscribirse",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Carro",
      "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Revisar carro",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":" Comercio",
      "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Detalle",
      "PUBLIC_SIGNUP_QTY":"CANTIDAD",
      "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"La cantidad debe ser mayor que 0",
      "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"Se requiere cantidad",
      "PUBLIC_SIGNUP_QUICK_CART":"Cerrar carro",
      "PUBLIC_SIGNUP_QUICK_VIEW":"Vista rápida",
      "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"Al hacer clic en Aceptar, los productos NCE agregados anteriormente se eliminarán, ya que los productos NCE de diferentes regiones del mercado no se pueden comprar al mismo tiempo.",
      "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Volver a Detalles",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Aplicar filtros",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Limpiar filtros",
      "PUBLIC_SIGNUP_SIDE_CART":"Carro",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"complementos",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"complementos",
      "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Precio estimado",
      "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":" Producto vinculado",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Remover",
      "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"Regístrese y compre",
      "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"¿Ya tiene un inquilino de MS existente?",
      "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Espere mientras configuramos su cuenta. Puede continuar usando su dispositivo con normalidad. Le notificaremos por correo electrónico una vez que se complete la configuración.",
      "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Saltar y continuar",
      "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Pagar ahora",
      "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"¡Registro exitoso! Ahora puede iniciar sesión en el portal C3 con las credenciales enviadas a",
      "PUBLIC_SIGNUP_SUPPORT":"Apoyo",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Términos y condiciones de registro público 1",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Términos y condiciones de registro público 2",
      "PUBLIC_SIGNUP_TERMS_OF_USE":"Términos de Uso",
      "PUBLIC_SIGNUP_TEXT_ACH":"CCA",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Ordenar A - Z",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Ordenar Z - A",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Ordenar por",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Precio: de mayor a menor",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Precios de barato a caro",
      "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Introduce el código de cupón",
      "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Las ofertas seleccionadas no son compatibles para el país seleccionado, así que vuelva a verificar las ofertas seleccionadas.",
      "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Se renueva.",
      "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Expira el.",
      "PURCHASE_PRODUCT_MAPPING":"Comprar mapeo de productos",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Comprar los artículos de la línea de cotización automáticamente.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Si desmarcamos la casilla de verificación después de aprobar la cotización, el socio o distribuidor debe comprar los productos manualmente en nombre del cliente.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Si desmarcamos la casilla de verificación después de aprobar la cotización, el socio o distribuidor debe comprar los productos manualmente en nombre del cliente.",
      "PurchaseCurrency":"Compra de moneda",
      "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Mapeo de productos comprados",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Complementos",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Expira el",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"pedido el",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Nombre del producto",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Cantidad comprada",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"se renueva el",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Estado",
      "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Editar cantidad",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"El intento de reconciliar la diferencia fracasó. Por favor, póngase en contacto con el soporte para obtener ayuda.",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"La conciliación de cantidades se completó correctamente",
      "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Recuento esperado",
      "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Es aplicable",
      "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Recuento de mensajes",
      "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"nombre de la cola",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Suplantación rápida",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Los siguientes usuarios están disponibles para suplantación, elija cualquiera para continuar",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Personificar",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Comportamiento",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Role",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Usuario(s)",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Actividades",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Alertas",
      "QUICK_SIDEBAR_LABEL_MORE":"Más",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Notificaciones",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"Ajustes",
      "QUICK_SIDEBAR_LABEL_USERS":"Usuarios",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Gracias por brindar su consentimiento para QuickBooks. La aplicación se puede configurar para acceder a QuickBooks siguiendo la nueva pauta de seguridad. Puede publicar las facturas de sus clientes en QuickBooks.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones para cargar facturas en QuickBooks en <b>{{applicationName}}</b>.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Espere mientras procesamos su consentimiento de QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"El siguiente enlace se abre en una nueva pestaña. Proporcione su consentimiento con sus credenciales de QuickBooks. Si recibe un error al brindar su consentimiento, comuníquese con nuestro equipo de soporte.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":" para proporcionar el consentimiento de QuickBooks",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Si ya ha proporcionado su consentimiento, haga clic en el botón <b> Validar token </b> para verificar la conectividad con QuickBooks.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en este botón para verificar la validez del token de actualización de QuickBooks existente.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Requisitos previos para que el socio publique facturas de clientes en <b> QuickBooks Online </b>",
      "QUICKBOOKS_POST_BATCH_ID":"ID de lote de publicación de Quickbooks",
      "QUICKBOOKS_POST_LOGS_STATUS":"Estado de los registros de publicaciones de Quickbooks",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"El token de actualización de QuickBooks existente no es válido. Por favor proporcione su consentimiento nuevamente",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"El token de actualización de QuickBooks existente es válido. Puedes continuar subiendo Facturas",
      "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Detalles de la cotización",
      "QUOTE_ALL_SELECTION":"Seleccionar todo",
      "QUOTE_APPROVAL_CONFIRMATION":"¿Está seguro que desea aprobar la cotización?",
      "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Cotización aprobada y pedido realizado exitosamente.<br/> El ID del pedido es <b>{{OrderNumber}}</b>",
      "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Cotización aprobada exitosamente.",
      "QUOTE_AUDIT_LOG_DELETED_BY":"Eliminado por",
      "QUOTE_AUDIT_LOG_ID":"Identificación de cita",
      "QUOTE_AUDIT_LOG_NAME":"Nombre de la cita",
      "QUOTE_AUDIT_LOG_QUOTE_NAME":"Nombre de la cita",
      "QUOTE_BREADCRUMB_QUOTE_ADD":"Crear cotizaciones",
      "QUOTE_BREADCRUMB_QUOTE_EDIT":"Editar citas",
      "QUOTE_BREADCRUMB_QUOTE_LIST":"Citas",
      "QUOTE_BUTTON_TEXT_AGE":"edad",
      "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Volver a la lista de cotizaciones",
      "QUOTE_BUTTON_TEXT_CANCEL":"Cancelar",
      "QUOTE_BUTTON_TEXT_CLOSE":"Cerca",
      "QUOTE_BUTTON_TEXT_COPY":"Copiar",
      "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"Escribe un correo electrónico con cita",
      "QUOTE_BUTTON_TEXT_SUBMIT":"Entregar",
      "QUOTE_BUYER_INFO":"Información del comprador",
      "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Información para compradores",
      "QUOTE_CHOOSE_BUYER_DETAILS":"Seleccione los datos de contacto del comprador que desea incluir en la cotización.",
      "QUOTE_CHOOSE_SELLER_DETAILS":"Verifique los detalles sobre usted y su empresa que se mostrarán en la cotización.",
      "QUOTE_COMMENTS_TO_BUYERS":"Comentarios",
      "QUOTE_COMPANY":"Compañía",
      "QUOTE_CONTACT":"Contacto",
      "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Se pueden agregar hasta tres contactos de cotización.",
      "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Error al copiar el texto:",
      "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL copiada exitosamente",
      "QUOTE_COUNTERSIGNATURE":" Contrafirma",
      "QUOTE_CREATE_A_LINE_ITEM":"Crear una línea de artículo",
      "QUOTE_CREATED":"Cita creada el",
      "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Información de la partida",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Ahorrar",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Facturación",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Frecuencia de facturación",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Período de facturación",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Descripción",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Fecha de vencimiento",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Fecha final",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Fecha de la factura",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"número de factura",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"es imponible",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Nombre",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":" Precio",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Tipo de venta",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Fecha de inicio",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Precio unitario",
      "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Introduzca un valor con hasta dos decimales.",
      "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"No se puede aprobar la cotización porque el cliente ha sido eliminado.",
      "QUOTE_DATE":"Fecha",
      "QUOTE_DOWNLOAD_AS_PDF":" Descargar como PDF",
      "QUOTE_EMAIL_ERROR":"Error en el correo electrónico de cotización",
      "QUOTE_EMAIL_MODAL_HEADER":"Texto del correo electrónico de cotización",
      "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Se produjo un error al guardar el nombre de la cotización y el cliente porque ya hay una cotización en estado de borrador o publicada.",
      "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Se produjo un error al guardar el cliente que seleccionó. Ya tiene una cotización en estado borrador o publicada.",
      "QUOTE_ERROR_NAME_NOT_UNIQUE":"Se produjo un error al guardar la cita porque el nombre de la cita no es único; ya existe otra cita con el mismo nombre en estado publicado o de borrador.",
      "QUOTE_EVENT_ERROR":"Error",
      "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Por favor, introduzca la fecha de caducidad",
      "QUOTE_EXPIRES":"La cotización vence el",
      "QUOTE_INCLUDE":"Incluido en la cotización",
      "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Contactos",
      "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Seleccione cliente",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Comentarios",
      "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Contrafirma",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
      "QUOTE_LABEL_TEXT_EMAIL":"Correo electrónico",
      "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Fecha de expiración",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Incluir espacio para una firma escrita",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Apellido",
      "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Sin firma",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
      "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Condiciones de compra",
      "QUOTE_LABEL_TEXT_QUOTE_NAME":"Nombre de la cita",
      "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Sus cambios han sido guardados",
      "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Opciones de firma",
      "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Incluir espacio para una contrafirma",
      "QUOTE_LABEL_TEXT_TEMPLATE":"Plantilla",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Agregar cliente",
      "QUOTE_LABEL_TEXT_WEB_LINK":"Copie el enlace de la página web a continuación para compartirlo con posibles clientes o elija escribir un correo electrónico con la cotización incluida.",
      "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Por favor, rellene todos los campos obligatorios antes de continuar.",
      "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Agregar un artículo de línea personalizado",
      "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Agregar producto al plan",
      "QUOTE_LINE_ITEM_HEADER":"Artículo de línea",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Descuento total",
      "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Total parcial",
      "QUOTE_LINE_ITEM_LABEL_TAX":"Impuesto total",
      "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Valor total de la cotización",
      "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Se permite agregar un máximo de 50 productos.",
      "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Se pueden añadir un máximo de diez cantidades.",
      "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Se pueden agregar hasta 9999 artículos de línea",
      "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Se requiere cantidad mínima de uno.",
      "QUOTE_LINE_ITEM_SUB_HEADER":"Revise la partida que se mostrará en su cotización",
      "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Total parcial",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Cantidad comprada",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Los impuestos mostrados son aproximados y se finalizarán al momento de generar la factura.",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Tipo de impuesto",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Descuento total",
      "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Valor total de la cotización",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Añade un mínimo de un artículo.",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Guarde los cambios en la línea de cotización antes de continuar.",
      "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Productos para cotizar",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Precio de coste",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Precio de venta",
      "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Elementos de línea",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Resumen",
      "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Planes para clientes",
      "QUOTE_LIST_BREADCRUMB_QUOTE":"Lista de cotizaciones",
      "QUOTE_LIST_STATUS_PUBLISHED":"Publicado",
      "QUOTE_MOVE_BACK_TO_STATE":"Anterior",
      "QUOTE_MOVE_TO_STATE":"próximo",
      "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"El nombre de la cita debe tener menos de 150 caracteres.",
      "QUOTE_NAME_REQUIRED_ERROR":"Por favor ingrese el nombre de la cotización",
      "QUOTE_ONE_TIME_SUBTOTAL":"Subtotal único:",
      "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Aprobar cotización",
      "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Eliminar cita",
      "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Editar cita",
      "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Ver cotización",
      "QUOTE_OWNER":"Propietario de la cita",
      "QUOTE_PDF_PURCHASE_TERM":"Condiciones de compra",
      "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Planes asignados al cliente",
      "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Por favor, introduzca la dirección de correo electrónico.",
      "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Por favor ingrese el primer nombre.",
      "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Por favor ingrese el apellido.",
      "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Por favor, introduzca el número de teléfono.",
      "QUOTE_PREPARED_BY":"Preparado por:",
      "QUOTE_PRICE_SUMMARY":"Resumen",
      "QUOTE_PRICE_TOTAL":"Total",
      "QUOTE_PRINTED_NAME":"Nombre impreso",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Nombre del plan",
      "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Nombre del producto",
      "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Propiedades",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Precio de venta",
      "QUOTE_READY_FOR_REVIEW":"Cotización lista para revisión",
      "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Revisar y publicar",
      "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Información del vendedor",
      "QUOTE_SENDER":"Remitente de la cita",
      "QUOTE_SIGNATURE":"Firma",
      "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Firma",
      "QUOTE_STATUS":"Estado",
      "QUOTE_STATUS_APPROVED":"Aprobado",
      "QUOTE_STATUS_DELETED":"Eliminado",
      "QUOTE_STATUS_DRAFT":"Borrador",
      "QUOTE_STATUS_EXPIRED":"Venció",
      "QUOTE_STATUS_OOPS_ERROR":"¡Ups!",
      "QUOTE_STATUS_ORDERPROCESSED":"Pedido procesado",
      "QUOTE_STATUS_PUBLISHED":"Publicado",
      "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"No podemos encontrar esa página.",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Acción",
      "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Fecha de Creación",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Fecha de expiración",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"Nombre",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Monto",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Propietario de la cita",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"Estado",
      "QUOTE_TABLE_LABELS_USER_ROLES":"Role",
      "QUOTE_TABLE_LABELS_USERS":"Clientes)",
      "QUOTE_TABLE_PRICE":"Precio unitario",
      "QUOTE_TABLE_PRODUCT_SERVICE":"Producto y servicio",
      "QUOTE_TABLE_QUANTITY":"Cantidad comprada",
      "QUOTE_TEXT_EMAIL_DETAILS":"Hola, <br/><br/>Su cotización está lista para su revisión.<br/><br/>Detalles clave:<br/><br/>Nombre de la cotización: {{quoteName}}<br/><br/>Válida hasta: {{expirationDate}} <br/><br/>Puede acceder y revisar su cotización utilizando el siguiente enlace<br/><br/>{{quoteURL}}<br/><br/>",
      "RCON_APPLY_PROMOTIONS_NCE":"Aplicar promoción",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":" Volver a implementaciones de RD",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Implementar nuevo RD",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Desplegar",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Detalles",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"Configuración de RD",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Agregar nombre de dominio",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Clave de administrador",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Nombre de usuario administrador",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Nombre de implementación",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Prefijo de etiqueta DNS de puerta de enlace",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Nombre de la dirección IP pública de la puerta de enlace",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"SKU de imagen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Seleccionar SKU de imagen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Ubicación",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Seleccionar Ubicación",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Número de instancias Rd",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Grupo de recursos",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Suscripción",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Seleccionar suscripción",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"Tamaño de máquina virtual",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Seleccionar SKU de imagen",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"Implementaciones de RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Implementar nuevo RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":" Actualizar el estado de implementación",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"Implementaciones de RD",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Administrar",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Implementado en",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Nombre de implementación",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Ubicación",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Grupo de recursos",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Estado",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":" Volver a Implementaciones de RD",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Detalles de implementación",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Detalles",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Nombre de implementación",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Ambiente",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Implementado en",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"La implementación de RD comenzó exitosamente",
      "READ_THROUGH":"Lea atentamente los términos y condiciones que se proporcionan a continuación.",
      "REASON_FOR_FAILURE":"Motivo del fracaso",
      "REASON_FOR_UPDATE":"Motivo de la actualización",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Error al recargar el informe de conciliación",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":" El informe de conciliación se recargó correctamente",
      "RECON_CUSTOMER_REPORT_RELOADING":"Recargando informe de conciliación",
      "RECON_PRODUCT_INPROVISION_TOOLTIP":"El producto está en estado de provisión",
      "RECON_PRODUCT_ONHOLD_TOOLTIP":"El producto está esperando aprobación",
      "RECON_REPORT_COLLAPSE_ALL":"Desplegar todo",
      "RECON_REPORT_EXPAND_ALL":"Expandir todo",
      "RECON_REPORT_MISMATCH_COUNT_BADGE":"Desajustes",
      "RECON_REPORT_RELOAD":"Informe de conciliación recargado",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Error al iniciar la recarga del informe de conciliación",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Se ha iniciado la recarga del informe de conciliación.",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Búsqueda",
      "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Buscar por oferta, ID de suscripción",
      "RECON_REPORT_SHOW_FIXED_INACTIVE":"Mostrar fijo Inactivo",
      "RECON_REPORT_SHOW_MISMATCH":"Mostrar solo discrepancias",
      "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" ID de suscripción de Azure:",
      "RECON_TABLE_TEXT_BILLING_CYCLE":" Ciclo de facturación :",
      "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Suscripción de proveedor",
      "RECON_TABLE_TEXT_REGION":" Región :",
      "RECON_TABLE_TEXT_RESERVATION_ID":" Identificación de la reserva:",
      "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" ID de orden de reserva:",
      "RECON_TABLE_TEXT_SUBSCRIPTION_ID":" Suscripción:",
      "RECON_TABLE_TEXT_TERM_DURATION":" Validez :",
      "RECON_TABLE_TEXT_TRIAL_OFFER":" Oferta de prueba",
      "RECONCILIATION_CUSTOMER_NAME":"Nombre del cliente",
      "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"PROVEEDOR",
      "RECONCILIATION_MISSMATCH":"Error de coincidencia de conciliación",
      "RECONCILIATION_NOTES":"Notas",
      "RECONCILIATION_NOTES_MISMATCH":" Los elementos en <span class='text-danger'>rojo</span> indican una discrepancia",
      "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Los artículos en <span class='text-mediumvioletred'>rojo violeta</span> ​indican una discrepancia relacionada con la promoción; póngase en contacto con el equipo de soporte para transmitir el beneficio promocional al cliente final.",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":" Los artículos en <span class='text-danger'>rojo</span> indican una discrepancia en la cantidad",
      "RECONCILIATION_NOTES_RI_INVOICE":"Luego de la incorporación de una instancia reservada, todas las reservas adicionales que incluyan la misma oferta dentro del ID de pedido de reserva incorporado se facturarán al precio incorporado.",
      "RECONCILIATION_NOTES_SUSPENDED":" Los elementos <span class='strike-through'>tachados</span> indican suscripciones suspendidas",
      "RECONCILIATION_PROVIDER":"Proveedor",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Monto del pago",
      "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"¿Está seguro de que desea registrar un pago de <%= amount%> (<%= currencyCode%>) contra el cliente?<br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error.</small>",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Código de moneda",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Seleccionar cliente",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Clientes",
      "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Se requiere que el cliente registre el pago.",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Pago anticipado récord",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Observaciones",
      "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Código de moneda",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Seleccionar cliente",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Pago registrado exitosamente",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Fecha de pago",
      "RECORD_ID":"Identificación de registro",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"ID de registro recibido de un servicio externo",
      "RECORD_MANUAL_PAYMENT_CONFIRMATION":"¿Está seguro de que desea registrar un pago por <%= currencySymbol%> <%= amount%> en esta factura?<br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error.</small>",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Nombre de la entidad",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Nombre",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importar",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Pagos de importación",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Plantilla",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catálogo",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Descargar plantilla",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Pagos de importación",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Error",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Éxito",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"Identificación C3",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Monto del pago",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Fecha de pago",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Nombre",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Observaciones",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Validar catálogo",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Error",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Éxito",
      "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"El monto del pago debe ser mayor a 0.00.",
      "RECORD_PAYMENT_PAID_DATE":"Fecha de pago",
      "RECURRENCES_MAX_ERROR":"El valor debe ser menor o igual a 11",
      "RECURRENCES_MIN_ERROR":"El valor debe ser mayor o igual a 0",
      "RECURRENCES_TOOLTIP":"Número de veces que se puede aplicar el cupón de descuento",
      "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"ACTUALIZAR LISTA DE ENTIDADES EXTERNAS EN LOCAL",
      "REFRESH_TOKEN":"Actualizar ficha",
      "REFRESH_TOOLTIP_TEST_PAYMENT":"Actualizar",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Gracias por elegir <span class=\"bold lowercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Complete la siguiente información para procesar su nueva suscripción.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Si tiene una cuenta de Microsoft existente que no es nuestra, <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">contáctenos</a> para obtener ayuda.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Por favor ingresa un cupón válido",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos.",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Correo electrónico",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Proporcione una identificación de correo electrónico válida. Le enviaremos un correo electrónico con nombre de usuario y contraseña una vez completado el registro.",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Nombre de pila",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Apellido",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Número de teléfono",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Ciudad",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Nombre de empresa",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Línea 1",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Línea 2",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":" Para comenzar, debe crear una cuenta con el nombre de su empresa seguido de .onmicrosoft.com. Si su organización tiene su propio dominio personalizado que desea usar sin .onmicrosoft.com, puede cambiarlo más adelante en el Portal de administración de Office 365.",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Qué es esto",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Estado",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Cremallera",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Fecha de acuerdo",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Acepto lo último",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Configura tu plan",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Para cada oferta en el plan que desea agregar, seleccione una cantidad mínima de 1",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Contacto primario",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Perfil de la empresa",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"CCA",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Cuenta bancaria",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Tarjeta de crédito",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Eliminar cupón",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Por favor ingresa un cupón válido",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Código de cupón aplicado: <b>{{CouponCode}}</b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Tipo de pago",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Por favor seleccione Tipo de Pago",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Resumen del plan",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Tarifa recurrente (cada 1 mes)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"luego <span class='bold'>${{totalCount}} </span>en la primera renovación el {{nextBillingDate}}",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Total",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Nota:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Los cargos por la compra se prorratean para el resto de los días del ciclo de facturación actual.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Licencias",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"¡Debe especificar si la cuenta ACH es una cuenta comercial o no!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Usuario creado exitosamente",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"No se puede comprobar la disponibilidad del dominio",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos.",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"estado de registro",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Su registro ha sido procesado exitosamente. Inicie sesión en el portal utilizando las siguientes credenciales:",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Nombre de usuario",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Clave",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Se le envió un correo electrónico con los detalles anteriores en caso de que no los haya copiado desde aquí.",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Procesar mi pedido",
      "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Por favor proporcione el motivo del rechazo.",
      "REJECT_ALL_PRODUCT_POPUP_BUTTON":"rechazar todo",
      "REJECT_ALL_PRODUCT_POPUP_HEADER":"Rechazar todos los productos",
      "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Rechazar todos los productos",
      "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Por favor explique el motivo del rechazo de la solicitud.",
      "REJECT_PRODUCT_COMMENT_HEADER":"Rechazar producto",
      "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Por favor indique las razones por las que este producto debería ser rechazado.",
      "REJECT_PRODUCT_POPUP_BUTTON":"Rechazar",
      "REJECTED_BY":" Rechazado por",
      "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Por favor ingrese un motivo para rechazar este producto",
      "RELEASE_PRODUCT_OWNERSHIP":"Liberar propiedad del producto",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Error en la liberación de propiedad del producto",
      "RELEASE_SEED_ERROR":" Solo puedes reducir la cantidad mientras liberas, inténtalo de nuevo.",
      "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"Intenté actualizar más de la cantidad disponible para \"{{childName}}\"",
      "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"Intenté actualizar más de la cantidad disponible al padre \"{{parentName}}\" para \"{{childName}}\"",
      "RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'>Se produjo un error al procesar los datos de <b> {{firstName}} </b>. El error es: {{errorMessage}} </span><br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'>Registros procesados correctamente para <b> {{firstName}} </b></span><br/>",
      "RELOAD_RECONCILIATION_STATUS":"Recargar estado de conciliación",
      "RELOAD_TOOLTIP_REVENUE":"Recargar",
      "reLoadingCustomersProfileData":"Sincronización de perfiles de clientes de proveedores",
      "REMOVE_ALL_SELECTED_RGS":"¿Quiere eliminar todos los grupos de recursos seleccionados?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Remover",
      "REMOVE_PARTNER_PRODUCT_ICON":"Remover",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Quitar producto",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"¿Estás seguro de que deseas eliminar el producto?",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"¿Está seguro de que desea revocar el acceso de este usuario?",
      "RENEWAL_ALL_DAYS":"Todo",
      "RENEWAL_DAYS_TEXT":"Días",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"Descargar",
      "REPORT_ACTIONS_SEND_EMAIL_TEXT":"Enviar correo electrónico",
      "REPORT_ACTIONS_TEXT":"Comportamiento",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"todos los clientes",
      "REPORT_FILE_INVALID_EMAILS_ERROR":"Por favor introduzca un correo electrónico válido",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Este campo es obligatorio",
      "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
      "REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
      "REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Seleccione Periodo",
      "REPORT_INTERVAL_TEXT_CUSTOME":"Disfraz",
      "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"últimos 12 meses",
      "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"últimos 3 meses",
      "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"últimos 6 meses",
      "REPORT_INTERVAL_TEXT_TILL_DATE":"Hasta la fecha",
      "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de facturas y cuotas",
      "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Volver al informe de uso",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Ver historial de carga",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Ver historial de carga",
      "REPORT_POPUP_ACTIONS_TEXT":"Comportamiento",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
      "REPORT_POPUP_EMAIL_TEXT":"Correo electrónico",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Seleccionar todo",
      "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
      "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe del historial de suscripciones",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista del informe del historial de suscripciones.",
      "REPORT_SELECT_CUSTOMER_All":"todos los clientes",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Seleccionar cliente",
      "REPORT_SELECT_RESELLER_ALL":"Todos los revendedores",
      "REPORT_SEND_EMAIL_TEXT":"Correo electrónico",
      "REPORT_TEXT_FROM":"De",
      "REPORT_TEXT_TO":"A",
      "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
      "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"El costo para el socio es obligatorio si el tipo de facturación es porcentual y opcional para el tipo de facturación unitario.",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catálogo",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importar",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Plantilla",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Descargar",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Se produjo un error al descargar el archivo.",
      "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Descargar plantilla",
      "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Descargar historial de carga",
      "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importar catálogo",
      "REPORT_USAGE_INSTRUCTIONS_PART_1":"Descargue el catálogo para obtener ID de productos y clientes de ofertas de socios basadas en el uso.",
      "REPORT_USAGE_INSTRUCTIONS_PART_2":"Descargue la plantilla y complete el detalle de uso de los productos en el catálogo.",
      "REPORT_USAGE_INSTRUCTIONS_PART_3":"Asegúrese de que los datos cumplan con las siguientes reglas: <br />&nbsp;&nbsp <b>Unidades</b> : Número <br />&nbsp;&nbsp <b>Unidad de medida</b> : Texto <br />&nbsp; &nbsp <b>CostToPartner</b> : Decimal <br />&nbsp;&nbsp <b>CurrencyCode</b> : Texto (código de moneda estándar ISO.) <br />&nbsp;&nbsp <b>UsageDate</b> : Fecha (AAAA-MM-DD)",
      "REPORT_USAGE_INSTRUCTIONS_PART_4":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
      "REPORT_USAGE_INSTRUCTIONS_PART_5":"Haga clic en <strong class=\"text-uppercase\">Validar entrada</strong> para que el sistema valide la entrada.",
      "REPORT_USAGE_INSTRUCTIONS_PART_6":"Revise los errores en la tabla después de completar la validación.",
      "REPORT_USAGE_INSTRUCTIONS_PART_7":"Corrija los errores en el archivo y vuelva a cargarlo.",
      "REPORT_USAGE_INSTRUCTIONS_PART_8":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
      "REPORT_USAGE_NOTE":"Nota :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Costo en socio",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Divisa",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Identificación del cliente",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"identificación de producto",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Unidad de medida",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Unidades",
      "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Fecha de uso",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
      "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Validar entrada",
      "REPORT_USER_LICENSE_ASSIGNMENT":"El informe de asignación de licencia de usuario se enviará al correo electrónico proporcionado.",
      "reportusage":"Informe de uso",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Para solicitar una relación de revendedor con un cliente, copie y pegue el siguiente texto, incluida la URL, en un correo electrónico. Edite el texto si es necesario y envíe el correo electrónico a su cliente.",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Incluya privilegios de administración delegada para Azure Active Directory y Office 365.",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"Texto de correo electrónico",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Solicitud de relación de revendedor",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Solicitud de incorporación masiva iniciada por",
      "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"La solicitud de carga de promociones está en cola",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Solicitud para cargar facturas en Quickbooks iniciada por",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"La solicitud para cargar facturas en Xero la realiza",
      "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Actualización de estado solicitada de la oferta de NI",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Solicitud para cargar facturas en QuickBooks aceptada",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Solicitud para cargar facturas en Xero aceptada",
      "REQUESTED_PRODUCT_STATUS":"Estado solicitado",
      "requestForResellerRelationship":"Solicitud de relación de revendedor",
      "REQUIRED_FIELD_TEXT":"Este campo es obligatorio",
      "REQUIRED_PATTERN_PHONE":"Solo se permiten números y +",
      "REQUIRED_PATTERN_TEXT":"No se permiten caracteres especiales como `~!@#{}|/<>'",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Se produjo un error al exportar las líneas de pedido de la factura del revendedor",
      "RESELLER":"(Revendedores)",
      "Reseller":"Revendedores",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Ajustamiento",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"está sujeto a impuestos",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Eliminar plan",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Revisar ofertas de planes",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Guardar plan",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Éxito",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Precio actualizado exitosamente",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Uso",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"No se encontraron productos",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Macro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":" Precio",
      "RESELLER_AZURE_MACRO_VALUE":"Valor macro",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"revendedores",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos del revendedor",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"volver a la lista",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Vincular nuevo proveedor",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Agregar revendedor",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Nuevo",
      "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Existente (múltiple)",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Agregar revendedor",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Detalles de contacto",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Tipo de pago",
      "RESELLER_CAPTION_TEXT_RESELLER":"revendedores",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Perfil de facturación de revendedor",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Detalles del revendedor",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Perfil de revendedor",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Habilite esta opción para aplicar tarifas de procesamiento a los pagos con tarjeta de crédito.",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"¿Incluye tarifa de procesamiento de tarjeta de crédito?",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Porcentaje de cargo por procesamiento de tarjetas de crédito.",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Tarifa de procesamiento de tarjetas de crédito %",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Marcar esta bandera evita que los clientes reduzcan la cantidad de suscripción",
      "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Permitir que el revendedor inicie la transacción de su(s) factura(s)",
      "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Seleccione si desea aplicar la promoción a revendedor o no",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Permitir a los clientes administrar suscripciones de Azure",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permita que los clientes definan grupos a través de suscripciones de Azure",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Margen (%) que se aplicará al uso de Azure del revendedor. Por ejemplo: ingrese 1.15 si el margen de beneficio debe ser del 15%.",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Para referencia interna. Esta identificación se puede utilizar para relacionar al cliente con un registro en su sistema de contabilidad interno.",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Día del mes en que las facturas se aprueban automáticamente",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Día del mes en que los pagos se procesan automáticamente",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Día del mes en que los pagos se procesan automáticamente",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Fecha de vencimiento de las facturas",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"No de días vencidos para el pago de la factura",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Día del mes en el que las facturas se finalizan automáticamente",
      "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente la dirección recién agregada como la dirección predeterminada para el revendedor.",
      "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el correo electrónico recién agregado como el correo electrónico predeterminado para el revendedor.",
      "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el número de teléfono recién agregado como el número de teléfono predeterminado para el revendedor.",
      "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará en las partidas individuales de la factura única del revendedor.",
      "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones perpetuas de software del revendedor",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Definir número de orden de compra para revendedor",
      "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Habilitar la función para transmitir facturas al servicio de contabilidad externo",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Marque esta bandera si desea que el sistema procese pagos (CC/ACH según corresponda)",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Recargo (%) que se aplicará en la reserva de Azure del revendedor.",
      "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"El revendedor recibirá el correo electrónico con los detalles de la factura una vez que se apruebe la factura y si las notificaciones por correo electrónico están habilitadas",
      "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"El revendedor recibirá una notificación por correo electrónico con respecto a las facturas que tienen montos cero",
      "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones de software de revendedor.",
      "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"No podemos procesar esta solicitud porque las transacciones están deshabilitadas.",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Definir código fiscal para revendedores",
      "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones de terceros revendedores.",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Permitir al cliente reducir asientos",
      "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Permitir que el revendedor inicie la transacción",
      "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Aplicar promoción al revendedor",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"¿Habilitar Azure?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"¿Habilitar Azure para empresas?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Marcado para uso de Azure",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"ID de referencia de facturación",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Día en que los revendedores podrán ver las facturas",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Día para pago automático",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Día para pago automático",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Fecha de vencimiento de las facturas",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Días de vencimiento de facturas",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Día en que se finalizan las facturas.",
      "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Asignar automáticamente una nueva dirección como predeterminada",
      "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Asignar automáticamente un nuevo correo electrónico como predeterminado",
      "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Asignar automáticamente un nuevo número de teléfono como predeterminado",
      "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Marcado para partidas individuales de facturas únicas de Microsoft",
      "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Marcado para software perpetuo",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"Número de orden de compra",
      "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Registrar facturas en servicio de contabilidad externo",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"¿Pago automático de facturas?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
      "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Enviar correos electrónicos de facturas automáticamente",
      "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Enviar facturas de importe cero",
      "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de software",
      "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Detener todas las transacciones",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Código de impuestos",
      "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para terceros",
      "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorar al revendedor del servicio externo",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Los números de factura comenzarán con este texto",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texto del número de factura",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permite al usuario ver las cuotas impagas hasta la factura actual.",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Mostrar cuotas impagas en la factura",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"La configuración del revendedor se revirtió exitosamente",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"La configuración del revendedor se guardó correctamente",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"No tienes acceso para editar",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Configuración de revendedor actualizada exitosamente",
      "RESELLER_CONFIGURATION_OF":"Configuración de revendedor de",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Vista rápida de los ingresos de su negocio",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilidad",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Resumen de sus cuentas por cobrar",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Cuenta por cobrar",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Pagos aún por cobrar",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Cuotas pasadas",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Tus clientes activos",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clientes",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Alerta sobre una factura pendiente",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Factura",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Ya existe un revendedor en el sistema con una identificación de mpn determinada. Por favor verifique la identificación de mpn proporcionada.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Por favor seleccione el tipo de pago",
      "RESELLER_IMPERSONATE":"Personificar",
      "RESELLER_IMPERSONATE_USER_ROLE":"personificar",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"hacerse pasar por su propio rol",
      "RESELLER_INVOICE":"Factura",
      "RESELLER_INVOICE_TEXT_LINE":"La factura se enviará por correo a su dirección de facturación. ¡Gracias!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Estado de la factura actualizado correctamente",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Pago registrado exitosamente",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Facturas",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Facturas",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Facturas",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Partidas de factura",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Dirección Línea 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Línea de dirección 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Nombre del banco",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
      "RESELLER_LABEL_TEXT_CITY":"Ciudad",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
      "RESELLER_LABEL_TEXT_COUNTRY":"País",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Tipo de tarjeta de crédito",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"Correo electrónico",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Apellido",
      "RESELLER_LABEL_TEXT_MONTH":"Mes",
      "RESELLER_LABEL_TEXT_MPN_ID":"Identificación del MPN",
      "RESELLER_LABEL_TEXT_NAME":"Nombre",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
      "RESELLER_LABEL_TEXT_STATE":"Estado",
      "RESELLER_LABEL_TEXT_YEAR":"Año",
      "RESELLER_LABEL_TEXT_ZIP":"Cremallera",
      "RESELLER_LIST_PAYMENT_METHOD":"Método de pago",
      "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
      "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de terceros.",
      "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de software",
      "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de terceros",
      "RESELLER_NAME_UPDATE_FAILURE":"Error al actualizar el nombre del revendedor",
      "RESELLER_NAME_UPDATE_SUCCESS":"Actualización exitosa del nombre del revendedor",
      "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"El revendedor no está asignado a ningún administrador de cuentas",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"No se encontró ningún revendedor en CSP. Por favor verifique la identificación de mpn proporcionada.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Errores detectados en los datos subidos. Descargue los errores, corríjalos e intente cargarlos nuevamente.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Se produjo un error al analizar el archivo cargado",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Mencione si los detalles de ACH están relacionados con una cuenta comercial",
      "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"ID de MPN del revendedor",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Correo Electrónico de Facturas",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"País",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"IDENTIFICACIÓN",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"identificador MPN",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Nombre",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Éxito",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":" Detalles de pago guardados",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Perfil de pago eliminado",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Perfil de pago Establecido como predeterminado",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"¿Está seguro de que desea eliminar esta opción de pago?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Definir macro y guardar con todas las ofertas",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Agregar ofertas faltantes",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Guardar plan con todas las ofertas",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ciclos de facturación seleccionados",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Categorías seleccionadas",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Categorías de proveedores seleccionados",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Proveedores seleccionados",
      "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Mercados admitidos",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Categorías",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Uso de macros",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Valor macro de uso",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valor macro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Categorías de proveedores",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"proveedores",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Tipos de servicio",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Se acepta la solicitud para clonar el plan de revendedor y se está procesando",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Se acepta la solicitud para crear un nuevo plan de revendedor con todas las ofertas y se está procesando",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Ajuste de precios",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Seleccione",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Aplicar macro para definir los nuevos precios",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Copie los precios del plan de revendedor de origen",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Envió exitosamente sus cambios",
      "RESELLER_PLAN_DISABLE_BUTTON":"Desactivar el plan",
      "RESELLER_PLAN_ENABLE_BUTTON":"Habilitar el plan",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"La oferta se suspendió con éxito",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Descontinuar la oferta",
      "RESELLER_PLAN_SELECTED_OFFERS":" Ofertas seleccionadas",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"planes",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Cancelar",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Plan de clonación",
      "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Creación de planes",
      "RESELLER_PLANS_CAPTION_1":"Agregar nuevo plan",
      "RESELLER_PLANS_CAPTION_2":"Detalles del plano -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"planes",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Vista",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Precios y ofertas",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Descargar lista de precios y ofertas",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Precios y ofertas",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"Precio ERP",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Tipo de acuerdo de licencia",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Precio de lista",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Válida desde",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Se produjo un error al exportar la lista de ofertas y precios de revendedor",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Perfil de la organización",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Perfil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Perfil de cliente",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Añadir dirección",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"Agregar correo electrónico",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Añadir teléfono",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Direcciones",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Detalles básicos",
      "RESELLER_PROFILE_PARTNER_CANCEL":"Cancelar",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"nombre de empresa",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"Correos electrónicos",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Nombre de pila",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Apellido",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Detalles básicos actualizados exitosamente",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"¡No se encontraron registros!",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Números de teléfono",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"¿Está seguro de que desea eliminar este registro?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"Entregar",
      "RESELLER_PROFILE_PAYMENT_ADD":"Agregar",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Cuenta bancaria",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Tarjeta de crédito",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"No se encontraron perfiles. Agregue un nuevo perfil de pago haciendo clic en el botón AGREGAR.",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Perfiles de pago",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Fecha de creación de la cuenta:",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Dirección de Envio",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"Fecha de incorporación C3",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Perfil de revendedor",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Dirección de envío",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Cuentas de usuario",
      "RESELLER_PROFIT_BILLED_AMOUNT":"Monto facturado al revendedor",
      "RESELLER_PROFIT_COST_ON_PARTNER":"Costo para el distribuidor",
      "RESELLER_PROFIT_COST_ON_RESELLER":"Costo para el revendedor",
      "RESELLER_PROFIT_CUSTOMER_NAME":"Monto del cliente",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Nombre del revendedor",
      "RESELLER_PROFIT_PRODUCT_NAME":"Nombre del producto",
      "RESELLER_PROFIT_PROFIT_AMOUNT":"Beneficio del distribuidor",
      "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Monto facturado por revendedor",
      "RESELLER_PROFIT_RESELLER_NAME":"Nombre del revendedor",
      "RESELLER_PROFIT_SUMMARY_REPORT":"Descargar informe",
      "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"No hay datos disponibles para descargar",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Todo",
      "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Ver por revendedor",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Beneficio del revendedor",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"% de beneficio del distribuidor",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"% de beneficio del revendedor",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"NO",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"SÍ",
      "RESELLER_REGISTRATION_SUCCESS":"El registro de revendedor se completó exitosamente.",
      "RESELLER_RELATIONSHIP_LBL":"Comparta el siguiente contenido con el cliente para establecer una relación de revendedor.",
      "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Seleccionar revendedor",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Vista por",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Por favor ingrese el porcentaje de impuesto válido",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Por favor complete los campos nombre del impuesto",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Agregar notificación por correo electrónico de nuevo evento",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"etiqueta de socio eliminada exitosamente",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"etiqueta de socio actualizada correctamente",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"No hay proveedores configurados, comuníquese con el soporte",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"No hay claves disponibles para este proveedor",
      "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Revendedor: suplantación rápida",
      "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"No se ha encontrado ningún mercado para el código de moneda elegido",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Configuración de revendedor",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Configuración de revendedor",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Asignar ofertas",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Editar",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Personificar",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Administrar enlaces de proveedores",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Creado en",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"Nombre",
      "RESELLER_TABLE_SIGNUP_DATE":"fecha de registro",
      "resellers":"revendedores",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Factura",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Reconciliación",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Revendedor de carga de prueba",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Revendedor agregado exitosamente.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Pagos de revendedor",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de la cuenta",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Administrar planes",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"Correos electrónicos",
      "RESEND_INVOICE":"Reenviar factura",
      "RESEND_INVOICE_HEADER_TEXT":"Ingrese los ID de correo electrónico para reenviar la factura.",
      "RESEND_INVOICE_NOTE":"<b>Nota:</b> Si desea reenviar la factura a varios ID de correo electrónico, proporcione los ID separándolos con una coma. Ej: abc@mail.com,nuevo@mail.com",
      "RESEND_INVOICE_USERS_LIST":"Reenviar lista de usuarios de facturas",
      "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Precio al por menor (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Costo para el socio (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Precio de venta (%)",
      "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Ingrese el porcentaje de RI válido",
      "RESET_VALUE":"Restablecer valor",
      "Resolved":"Resuelto",
      "RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"El pago se ha iniciado con éxito. Tomará algún tiempo para procesar. Por favor, compruebe el estado después de algún tiempo.",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Cantidad facturada",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Descargar facturas masivas",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Categoría",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Costo en socio",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Cliente",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Factura del distribuidor",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Proveedor",
      "REVENUE_AND_COST_SUMMARY_RESELLER":"Revendedores",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"Tipo de venta",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Deudas",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"facturas y cuotas",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Pagos",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Fallos",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Vencidos",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"historial de suscripciones",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Vista por",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Generación de factura completada",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Se produjo un error al generar las facturas de los clientes.",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Facturas de clientes generadas exitosamente",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Detalles de pago del proveedor",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Cargando detalles de pago del proveedor...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Regenerar factura",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Ver facturas",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Ver historial de suscripción",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Monto",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Período de facturación",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Cliente",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Costo en socio",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Importe de beneficio",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"costo para el cliente",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Costo para el socio",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Lucro",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Recibe todas las facturas por correo electrónico",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Detalles de pago del proveedor",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Categoría",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Cliente",
      "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Duración",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Último período de facturación",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 períodos de facturación",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 períodos de facturación",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 períodos de facturación",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":" ¿Estás seguro de que quieres revocar la asignación?",
      "RFRESH_DATA_FROM_PSA":"Actualizar datos de PSA",
      "RIBBON_TITLE_DEPARTMENT":"departamento",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Ingrese la cantidad para al menos una oferta de plan de nivel superior.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Complete los datos del usuario",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Asignación de roles Ámbitos",
      "ROLE_NAME_C3_SUPPORT":"Soporte C3",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Gerente de cuenta de cliente",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"El usuario podrá acceder a tarjetas y menús que incluyan productos.",
      "ROLE_NAME_CUSTOMER_ADMIN":"Administrador de clientes",
      "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"El usuario puede ver y realizar todas las acciones disponibles en el portal del cliente",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Administrador de clientes (para ventas)",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Administrador de clientes (para ventas)",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Administrador de clientes Lite",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"El usuario tiene todos los permisos de administrador de clientes, pero no podrá comprar nuevas suscripciones",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Administración de clientes Lite Plus",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"El usuario tiene todos los permisos de Customer Admin Lite, pero no podrá administrar productos con un plazo de 1 año o más",
      "ROLE_NAME_CUSTOMER_MAINTENANCE":"Mantenimiento del cliente",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Mantenimiento del cliente",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Un rol para mantener la aplicación por cliente",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Un rol para mantener la aplicación por cliente",
      "ROLE_NAME_CUSTOMER_READER":"Lector de clientes",
      "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"El usuario solo puede leer los datos disponibles en el portal del cliente",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Superadministrador de clientes",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Superadministrador de clientes",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Administrador del departamento",
      "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Quién puede administrar el Departamento y solo puede administrar productos bajo los Departamentos Específicos.",
      "ROLE_NAME_DEPARTMENT_READER":"Lector de departamento",
      "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Quien sólo puede leer los datos disponibles en los Departamentos Específicos.",
      "ROLE_NAME_DIRECT_ADMIN":"Administrador directo",
      "ROLE_NAME_FINANCE_ADMIN":"Administrador de finanzas",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Puede gestionar páginas relacionadas con las finanzas.",
      "ROLE_NAME_GROUP_ADMIN":"Administrador del grupo",
      "ROLE_NAME_HELP_DESK":"Mesa de ayuda",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Mesa de ayuda",
      "ROLE_NAME_INDIRECT_ADMIN":"Administrador indirecto",
      "ROLE_NAME_PARTNER_MAINTENANCE":"Mantenimiento de socios",
      "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Un rol para mantener la aplicación por socio",
      "ROLE_NAME_SITE_ADMIN":"Administrador del sitio",
      "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Quién puede administrar el Sitio y solo puede administrar productos en los Sitios Específicos.",
      "ROLE_NAME_SITE_READER":"Lector del sitio",
      "ROLE_NAME_SITE_READER_DESCRIPTION":"Quien solo puede leer los datos disponibles en el Sitio.",
      "ROLE_NAME_SYSTEM_ADMIN":"Administrador del sistema",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Puede acceder/cambiar todas las páginas del portal.",
      "ROLE_NAME_SYSTEM_READER":"Lector del sistema",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Puede visitar todas las páginas pero no puede realizar ningún cambio.",
      "ROLE_NAME_USER":"Usuario",
      "ROLE_NAME_USER_ADMIN":"Administrador de usuarios",
      "ROLE_NOTE_C3_SUPPORT":"El soporte C3 puede resolver situaciones de discrepancia de cantidades y es un súper conjunto de permisos",
      "ROLE_NOTE_CUSTOMER_ADMIN":"El administrador del cliente puede ver todos los datos y realizar todas las acciones en el contexto del inquilino",
      "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"El administrador de clientes (para ventas) puede ver y realizar acciones en los datos, pero no en las suscripciones de socios.",
      "ROLE_NOTE_CUSTOMER_READER":"Lectores de clientes que tienen acceso de solo lectura a todos los datos relacionados con el cliente.",
      "ROLE_NOTE_DIRECT_ADMIN":"El administrador directo podría hacer cualquier cosa que un administrador del sistema pueda hacer, pero solo para los clientes directos, por lo que no hay acceso a las partes de Venta indirecta.",
      "ROLE_NOTE_FINANCE_ADMIN":"Los administradores de finanzas pueden acceder al resumen de ingresos y costos del cliente, definir el porcentaje de impuestos y ver el informe de conciliación.",
      "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins puede administrar todas las cosas relacionadas con las suscripciones de Azure en su grupo.",
      "ROLE_NOTE_HELP_DESK":"Los usuarios de la mesa de ayuda pueden ver todos los detalles del cliente, incorporar nuevos clientes y brindar soporte. Los usuarios de la mesa de ayuda pueden hacerse pasar por los datos de los clientes y acceder a ellos.",
      "ROLE_NOTE_INDIRECT_ADMIN":"El administrador indirecto podría hacer todo lo que un administrador del sistema puede hacer, pero solo para los revendedores (y sus clientes), por lo que no hay acceso a las partes de Venta directa.",
      "ROLE_NOTE_SYSTEM_ADMIN":"El administrador del sistema puede ver todos los datos y realizar todas las acciones en el contexto de socios y clientes.",
      "ROLE_NOTE_SYSTEM_READER":"Lectores del sistema que tienen acceso de solo lectura a todos los datos relacionados con el socio. Los lectores no pueden hacerse pasar por",
      "ROLE_NOTE_USER_ADMIN":"El administrador de usuarios puede ver y realizar algunas acciones en los datos, pero no en las suscripciones.",
      "RowNum":"Número de fila",
      "SALE":"Venta",
      "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Editar precio de venta",
      "SALE_PRICE_EMPTY_ERROR":"Por favor, introduzca un precio válido",
      "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Precio de venta mayor que ERP Pice",
      "SALE_PRICE_LESS_THAN_LIST_PRICE":"Precio de venta inferior al precio de lista",
      "SALE_TYPE_DESC_PRODUCT":"Producto",
      "SALE_TYPE_DESC_SERVICE":"Servicio",
      "SalePrice":"Precio de venta",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Intentando guardar el código de autenticación recibido de Quickbooks",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Intentando guardar el código de autenticación recibido de Xero",
      "SAVE_CART_EXCEPTION":"Se produjo un error al agregar el producto al carrito",
      "SAVE_COUPON_FAILURE":"No se pudo guardar el cupón",
      "SAVE_COUPON_SUCCESS":"Cupón guardado exitosamente",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al guardar la conversión de moneda.",
      "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Las columnas seleccionadas se guardaron correctamente como favoritas.",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Se produjo un error al guardar o actualizar la lista de destinatarios de notificaciones por correo electrónico",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al guardar los detalles del impuesto.",
      "SAVE_PLAN_PRODUCT_SLAB":"Losas de oferta de contrato guardadas",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"No se pudieron guardar losas de oferta de contrato",
      "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan creado con éxito",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Los detalles de pago del proveedor se guardaron correctamente.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Guardar perfil de pago de revendedor",
      "SAVE_RESELLER_PLAN":"Guardar plan de revendedor",
      "SAVE_RESELLER_PLAN_FAILURE":"No se pudo guardar el plan de revendedor",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Definición del tipo de cambio del producto del plan de revendedor",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"No se pudo definir el tipo de cambio del producto del plan de revendedor",
      "SAVE_SUB_TAX":"Ahorre subimpuestos",
      "SAVE_SUB_TAX_FAILURE":"Error al guardar subimpuestos",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Nuevo ticket creado exitosamente",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Boleto actualizado exitosamente",
      "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Programar un pedido",
      "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Por favor seleccione una fecha",
      "SCHEDULE_ORDER_CHECKBOX_TEXT":"Acepto programar un pedido para una fecha futura",
      "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Selecciona una fecha",
      "SCHEDULE_ORDER_EVENT":"Orden de programación",
      "SCHEDULE_ORDER_FAILED_EVENT":"Orden de programación fallida",
      "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"Fecha de Creación",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Tipo de destinatario",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Valor del destinatario",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Recetantes",
      "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"ID de registro",
      "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Reportar nombre",
      "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Resultado",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Estado",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Borrar",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Editar",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Informe_artículo_línea_factura_programada",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Entrega de informe de artículo de línea de factura programada",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Elementos_de_línea_de_factura_programados_con_informe_de_comision",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Artículos de línea de factura programada con entrega de informe de comisión",
      "SCHEDULED_INVOICE_SUMMARY_REPORT":"Informe_resumen_de_factura_programado",
      "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"ProgramadaFacturaResumenInformeEntrega",
      "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
      "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Borrar",
      "SCHEDULED_PROFITABILITY_REPORT":"Informe_de_rentabilidad_programado",
      "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Entrega programada del informe de rentabilidad",
      "SCHEDULED_RENEWAL_CHANGES":"Cambios de renovación programados",
      "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"¿Quieres aplicar la promoción a este producto?",
      "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"A la espera de la aprobación",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Activo",
      "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Cancelado",
      "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Terminado",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Ha fallado",
      "SCHEDULED_RENEWAL_STATUS_ONHOLD":"En espera",
      "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Volver a los detalles básicos",
      "SCHEDULED_RENEWALS_HEADER_TEXT":"Renovaciones programadas",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Comportamiento",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Alinear fecha de finalización",
      "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Razon de cancelacion",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Ciclo de facturación",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Nuevo SKU",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
      "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Cita agendada",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Estado",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Término",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"Términos y condiciones",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Aceptado",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Partida de factura",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Partidas de factura con comisión",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Resumen de factura",
      "SCHEDULED_REPORT_PROFITABILTY":"Informe de Rentabilidad",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Tipo de destinatario",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Destinatarios",
      "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Informe",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Partidas de impuestos",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Resumen de impuestos",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Informe_de_artículos_de_línea_de_impuestos_programados",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Entrega de informe de artículos de línea de impuestos programados",
      "SCHEDULED_TAX_SUMMARY_REPORT":"Informe_de_resumen_de_impuestos_programado",
      "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Entrega de informe de resumen de impuestos programado",
      "SCHEDULNG_LODING_MESSAGE_TEXT":"El proceso de carga de la oferta elegible desde el Centro de socios suele tardar unos 30 segundos. Por favor no actualice la página.",
      "SCORE":"Puntaje",
      "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Buscar por evento",
      "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Acciones de búsqueda",
      "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Buscar por nombre de cliente",
      "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Buscar por nombre de dominio",
      "SEAT_LIMIT_EXCEED":"Límite de asientos excedido",
      "SELECT_A_SOURCE_CURRENCY":"Seleccione la moneda de origen",
      "SELECT_A_SUBSCRIPTION_TEXT":"Seleccione una suscripción",
      "SELECT_AGENT":"Agente",
      "SELECT_ALL_CUSTOMER":"Todos los clientes",
      "SELECT_ALL_RESELLER":"Todos los revendedores",
      "SELECT_ALL_RG":"¿Quiere seleccionar todos los grupos de recursos?",
      "SELECT_COUPON":"Seleccionar cupón",
      "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Seleccionar código de moneda",
      "SELECT_CUSTOMER":"Seleccionar cliente",
      "SELECT_DATE_RANGE":"Seleccione el rango de fechas",
      "SELECT_DATE_VALUE":"Seleccione fecha",
      "SELECT_DEFAULT_OPTION_SELECT":"Seleccione",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Seleccionar cliente",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Seleccionar evento",
      "SELECT_DEPARTMENT":"Seleccionar departamento",
      "SELECT_DEPARTMENTS":"Seleccionar departamento",
      "SELECT_EDU_SEGMENT":"Seleccionar segmento educativo",
      "SELECT_EVENT":"Seleccionar evento",
      "SELECT_MESSAGE_TYPE":"Seleccionar tipo de mensaje",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Por favor seleccione al menos una factura para pagar",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Opciones rápidas para definir tu precio de venta",
      "SELECT_PARTNER_INVOICE":"Facturas de socios",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Seleccionar evento",
      "SELECT_PLAN_TOOLTIP_TEXT":"Seleccionar plan",
      "SELECT_PORTAL_PAGE":"Seleccione la página del portal",
      "SELECT_RESELLER_AZURE_MACRO":"Seleccione una macro",
      "SELECT_RESELLER_AZURE_MACRO_TYPE":"Seleccionar tipo de macro",
      "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Seleccione el tipo de precio de venta",
      "SELECT_SITE":"Seleccionar sitio",
      "SELECT_SITES":"Seleccionar sitio",
      "SELECT_STATUS":"Seleccionar estado",
      "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Seleccione la moneda de destino",
      "SELECT_TYPE":"Seleccione tipo",
      "selectAddressType":"Seleccione el tipo de dirección",
      "selectCountry":"Seleccione país",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Clientes seleccionados para cargar facturas en Quickbooks",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Clientes seleccionados para cargar facturas a Xero",
      "SELECTED_FILTER":"Seleccionar filtro",
      "SELECTED_FILTER_ALL":"Seleccionar todo",
      "selectEmailType":"Seleccione el tipo de correo electrónico",
      "selectPhoneType":"Seleccione el tipo de teléfono",
      "selectState":"Seleccione estado",
      "SELL_DIRECT_ICON_TITLE":"Iconos de Estocolmo / Compras / Box2",
      "sell-direct":"Vender Directo",
      "sell-indirect":"Vender Indirecto",
      "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Ingrese la dirección de correo electrónico a quien desea enviar un correo electrónico, ingrese con una coma (,) o punto y coma (;) y haga clic en Enviar para recibir un archivo que contiene una lista del catálogo de productos.",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Reenviar factura",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Enviar",
      "SEND_MAIL_BUTTON":"Enviar correo",
      "SEPTEMBER":"Septiembre",
      "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Dominio del cliente del proveedor",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ID de cliente del proveedor de servicios",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nombre del cliente del proveedor de servicios",
      "SERVICE_PROVIDER_TENANT":"Inquilinos proveedores",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Inquilino predeterminado",
      "SERVICE_PROVIDER_TENANT_HEADER":" Inquilinos proveedores de",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Comportamiento",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"identificación del inquilino",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Nombre del inquilino",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"¿Está seguro de que desea marcar <b> {{customerName}}</b> como inquilino predeterminado?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"Actualizado {{customerName}} como inquilino predeterminado",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Su sesión ha caducado. Inicie sesión nuevamente para continuar. Desafortunadamente, todos los cambios no guardados se perderán.",
      "SESSION_TIMEOUT_WARNING":"Advertencia de tiempo de espera de sesión",
      "SESSION_WARNING_DURATION":"Su sesión expirará en {{secondsLeft}} segundos.",
      "SET_NEW_MACRO":"Establecer nueva macro",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"El estado del lote de clientes a bordo en masa está establecido en Completo",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Intentando establecer el estado de los registros de publicaciones de servicios externos en Errorignored",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"No se pudo establecer el estado de los registros de publicaciones de servicios externos en ErrorIgnored",
      "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"Plantilla de correo electrónico",
      "settings":"Ajustes",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificaciones de eventos por correo electrónico",
      "SETTINGS_HEADING_TEXT_BILLING":"Facturación",
      "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"Configuración de administración de ConnectWise",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Conversión de Moneda",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Valores predeterminados del cliente",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Eliminar plan",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificaciónes de Correo Electrónico",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Configuraciones del cliente (global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Valores predeterminados del socio",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Gestión de usuarios",
      "SETTINGS_LOGO_SELECT_A_LOGO":"Seleccione un logotipo",
      "SETTINGS_PASSWORD_HELP_TEXT":"Deje el campo vacío si no desea actualizar la contraseña.",
      "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Autotarea",
      "SETTINGS_TAB_HEADING_CONNECTWISE":"Administrar ConnectWise",
      "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Vistas personalizadas",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"General",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logotipos",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Centro de socios",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"proveedores",
      "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Registro público",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Puede especificar cualquier nombre de impuesto, por ejemplo: GST.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Puede especificar el porcentaje de impuestos aquí. Por ejemplo: 15 por 15%",
      "SHIP_TO":"Envie a",
      "SHOP_ADDED_TO_CART_TEXT":" añadido al carrito",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Añadir a la cesta",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Leer más..",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Leer más..",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Mostrar la configuración del cliente en el portal del cliente",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Permitir al cliente gestionar configuraciones.",
      "SHOW_NON_ACTIVE_OFFERS":"Mostrar ofertas deshabilitadas",
      "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
      "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Mostrar ofertas que no están disponibles para el cliente.",
      "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Mostrar las ofertas que no tienen macro",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Mostrar la configuración del revendedor en el portal del revendedor",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Permitir que el revendedor administre las configuraciones",
      "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Iconos de Estocolmo / Comunicación / Agregar usuario",
      "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Iconos-de-estocolmo / Comunicación / Grupo",
      "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo-/-General-/-Notificación2",
      "SIDEBAR_ANALYZE_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Calculadora",
      "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"margen azul",
      "SIDEBAR_CONTENT_ALL_COMMENTS":" Permite al socio revisar los comentarios proporcionados para varias entidades (artículo del carrito, facturas, pedidos) por parte de los clientes y responderlos.",
      "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Lista de administradores de cuentas y opciones para administrar los clientes asociados a ellos.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Administrar avisos web a nivel de entidad",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Cree y administre notificaciones personalizadas para diversos eventos de administración de productos/licencias e incorporación de clientes.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Comparta información en tiempo real con socios y clientes a través de transmisiones interactivas en sus respectivos paneles.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Cree y administre avisos web en varios niveles de entidad para informar sobre el estado del sistema, facturas y otras notificaciones importantes",
      "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor es una característica basada en recomendaciones que ayuda a los socios a adoptar las mejores recomendaciones de optimización de costos.",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Informe sobre los datos de facturación de Azure",
      "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Le permite actualizar el margen de beneficio del Plan Azure de los últimos meses y cargar datos de facturación con nuevos valores de margen.",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Revise los detalles de consumo de su suscripción de Azure.",
      "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Vistazo rápido al informe de resumen de costos",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Vistazo rápido a los análisis de clientes",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Mostrar lista de licencias consumidas por el inquilino",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Informe basado en PowerBI para ver el uso de Azure en múltiples dimensiones",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Administre el perfil de su organización, direcciones, perfiles de pago y más...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Proporciona a los socios un conocimiento profundo de los clientes y su negocio en diversas dimensiones.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Revise los cambios de su licencia en fechas personalizadas.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Vistazo rápido al resumen de consumo de licencias",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Descripción rápida de la asignación de licencias y los asientos no asignados.",
      "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Administre y cree múltiples derechos de Azure (suscripciones) bajo el plan de Azure de forma masiva",
      "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Vistazo rápido a los análisis de incorporación de clientes de sus revendedores indirectos.",
      "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Informes detallados de la comisión de ventas a nivel de agente y la cantidad ganada",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Defina cupones de descuento que se pueden aplicar a nivel de plan/cliente/servicio.",
      "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Guía práctica del producto para socios y clientes finales",
      "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":" Permite que el socio vea y descargue los detalles de uso de los productos registrados fuera de la solución C3.",
      "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Permite que el socio cargue códigos/atributos de productos que se desean en los informes de elementos de línea de factura.",
      "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Permite que el socio apruebe/rechace las solicitudes de compra de productos de los clientes.",
      "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Cargue valores de nombre de agente, productos asociados con ventas, porcentaje de comisión y varios otros atributos de comisión",
      "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Le ayuda a crear columnas personalizadas para agregarlas a su informe de elementos de línea de factura.",
      "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Permite que el socio cargue detalles de uso registrados fuera de C3 para productos en el catálogo para la generación de facturas.",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Proporciona varios informes sobre sus datos que se encuentran en C3.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Le ayuda a administrar (crear/editar) planes con ofertas que desea vender a revendedores indirectos estableciendo sus márgenes personalizados.",
      "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Esta función ayuda a los socios a programar varios informes con diferentes frecuencias.",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Revise el uso de su suscripción de Azure con los costos asociados.",
      "SIDEBAR_CONTENT_MENU_USERS":"Administrar el acceso a los usuarios",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analice la rentabilidad de su negocio en múltiples dimensiones.",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Vea el consumo de Azure a nivel de grupo de recursos/recursos/etiquetas.",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Agrupe una o más suscripciones y defina presupuestos y umbrales",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Le ayuda a administrar (crear/editar) productos/servicios de terceros y sus propios servicios y ofertas de consultoría/asesoramiento/soporte.",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Conduce al tablero",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Accede a tus facturas desde el Partner",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Le permite acceder a funciones relevantes para los inquilinos de Microsoft CSP vinculados a sus clientes.",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Enumere todos los usuarios de Azure AD de los clientes y asígnelos como roles RBAC del lado del cliente.",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Seguimiento de tus pedidos",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Ver los productos que fueron comprados",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Compra y compra ofertas del catálogo.",
      "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Función que permite a los proveedores indirectos (distribuidores) crear y vender sus soluciones IP a través de revendedores indirectos a clientes finales.",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Gestionar facturas y pagos",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Le permite combinar dos o más ofertas (Microsoft y/o ofertas de socios) y ponerlas a disposición de sus clientes para su compra.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Conduce a la lista de clientes activos y opciones para administrar su configuración.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Te lleva a la página del panel",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Consultar el estado de los pagos activados por el sistema.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Le ayuda a crear un catálogo de ofertas (productos/servicios) y le permite definir sus márgenes personalizados como precio de venta para sus clientes.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Le brinda una visión general de su rentabilidad y revisa las facturas en detalle.",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Conduce al tablero",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Le brinda una descripción general de su rentabilidad y también un vistazo a los detalles de la factura.",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Conduce a la lista de revendedores activos y opciones para administrar su cartera de CSP.",
      "SIDEBAR_CONTENT_MENUS_SELL":"Enumera las características que te ayudan a definir tus ofertas de venta.",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":" <b>Socios de facturación directa (Nivel 1):</b> enumera todas las funciones que le ayudan a gestionar las ofertas y los precios de sus clientes.",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"<b>Proveedor indirecto (Nivel 2):</b> enumera las funciones que ayudan a los proveedores indirectos a administrar sus revendedores indirectos.",
      "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Acceda a funciones exclusivas del rol de soporte C3.",
      "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Iconos-de-Estocolmo-/-Diseño-/-Unión",
      "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Iconos-de-Estocolmo-/-Comunicación-/-Grupo",
      "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Iconos-de-Estocolmo-/-Comunicación-/-Grupo",
      "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
      "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Iconos-de-Estocolmo-/-Diseño-/-Unión",
      "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
      "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo / Compras / Línea-gráfico1",
      "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Iconos-de-Estocolmo-/-Archivos-/-Carpetas-de-grupo",
      "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Boletos",
      "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Regalo",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Iconos-de-Estocolmo-/-Inicio-/-Clave",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Iconos de Estocolmo / Compras / Dinero",
      "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Bolsa1",
      "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
      "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Iconos-de-Estocolmo-/-Navegación-/-Verificación doble",
      "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Iconos de Estocolmo / Compras / Dinero",
      "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo-/-Diseño-/-Componente",
      "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Iconos-de-estocolmo / Comunicación / Grupo",
      "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Bolsa1",
      "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Iconos-de-Estocolmo / Compras / Box3",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Comentarios",
      "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Gerente de Cuentas",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Administración",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notificaciones personalizadas",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"Comprometer",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Notificaciones de pancartas",
      "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Asesor de Azure",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Informe de facturación de Azure",
      "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Margen",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Suscripciones de Azure",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Informe resumido de costos",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Análisis de clientes",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consumo de Licencia",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Informe de uso de Microsoft Azure",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Perfil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Panel",
      "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Informe de cambio de licencia.",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Informe resumido del consumo de licencias",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Informe de consumo de licencia",
      "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Administrar derechos de Azure",
      "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Análisis de incorporación",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Comisión",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Informes de la Comisión",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Cupones",
      "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Documentación del producto",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
      "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Citas",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Subir",
      "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Aprobaciones",
      "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Comisiones",
      "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Atributos del producto",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Uso",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Informes",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"planes",
      "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Informes programados",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Informe de uso",
      "SIDEBAR_TITLE_MENU_USERS":"Usuarios",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"Analizar",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Estimaciones de Azure",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Grupos de Azure",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Ofertas de socios",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Panel",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Facturas",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Usuarios de Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Pedidos",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Productos",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":" Comercio",
      "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Facturas",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"manojos",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Clientes",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Panel",
      "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Estado de pago pendiente.",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"planes",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Panel",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"revendedores",
      "SIDEBAR_TITLE_MENUS_SELL":"Vender",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Vender Directo",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Vender Indirecto",
      "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"soporte C3",
      "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Iconos-de-Estocolmo / Archivos / Subir",
      "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
      "SIGN_UP_DATE":"fecha de registro",
      "SIGNUP_CART_COUPON_APPLY":"Aplicar",
      "SIGNUP_CART_COUPON_CODE":"Aplicar cupón",
      "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Quitar vale",
      "simplereport":"Informe de la Comisión",
      "SINGLE_COMMENT_STATIC":"Comentario",
      "SITE":"(Sitio)",
      "SITE_DEPARTMENT_ID":"SiteDepartmentId",
      "SITE_ID":"ID del sitio C3",
      "SLAB_BACKWARD_CONFIRMATION_POPUP":"¿De verdad quieres volver? Tienes algunos datos de losa guardados.",
      "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Complete y guarde los datos de la losa.",
      "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Por favor complete los datos de la losa.",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Comportamiento",
      "SLAB_DATA_TABLE_HEADER_COST_RATE":"Tasa de costo/unidad",
      "SLAB_DATA_TABLE_HEADER_RANGE":"Rango",
      "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Tasa de venta/unidad",
      "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"La longitud del texto es demasiado larga.",
      "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Por favor complete los datos de la fila.",
      "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Los datos de losa no pueden ser más de 10.",
      "SLAB_TYPE_SELECT_OPTION":"Seleccione",
      "SMTP_SETTINGS_TEST":"Haga clic en el botón para probar la configuración SMTP",
      "SMTP_TEST_BUTTON":"Prueba SMTP",
      "SMTPTYPE_LEGACY_SMTP":"SMTP heredado",
      "SMTPTYPE_MODERN_0365":"Moderno 0365",
      "SMTPTYPE_SENDGRID":"enviargrid",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Política de cancelación",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 horas: Reembolso completo",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 horas: 1er día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 horas: 2do día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 horas: 3er día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 horas: 4to día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 horas: 5to día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 horas: 6to día prorrateado",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Después de 7 días naturales: No se permite la cancelación",
      "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Instrucciones para suscripciones de software",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"La cantidad/asientos se puede aumentar en cualquier momento.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"La cantidad/asientos se puede disminuir dentro de los 7 días calendario, a su cantidad/asientos anteriores oa la suma de los anteriores.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Cambio de cantidad",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Cambio de renovación de horario",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"El programa actual se eliminará si se cambia Cantidad/Estado de renovación automática/Ciclo de facturación/Término.",
      "SoftwareSubscriptions":"Suscripciones de software",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Moneda de origen",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Moneda de origen",
      "SOURCE_PLAN_DISPLAY_NAME":"Plano de origen",
      "SOURCE_PRODUCT_DISPLAY_NAME":"Producto de origen",
      "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"El perfil del cliente se ha sincronizado",
      "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Editar detalles de calificación especial",
      "SQL_ERROR_DESC":"Parece que hay un error técnico al procesar su solicitud, comuníquese con soporte para obtener más ayuda.",
      "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Error de la base de datos al intentar obtener las cuotas de la factura",
      "START_BILLING_PERIOD":"Iniciar período de facturación",
      "START_DATE_REQUIRED_MESSAGE":"La fecha de inicio es obligatoria",
      "STATUS":"Estado",
      "STATUS_ALL":"Todo",
      "STATUS_DUE":"Deudas",
      "STATUS_OVER_DUE":"Cuotas vencidas",
      "STATUS_PENDING":"Pendiente",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Estado actualizado exitosamente",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Ingrese al menos un nombre de impuesto y un porcentaje para los subimpuestos.",
      "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"La cantidad máxima que se puede permitir es",
      "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"La cantidad mínima que se puede permitir es 1",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Este campo es obligatorio",
      "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Eliminar promoción",
      "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"La actualización está en progreso, haga clic en transición para ver el progreso",
      "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Debido a que la cantidad de licencias de actualización es menor que la actual, debe reasignar asientos manualmente",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Esta suscripción de cliente se comparte entre el cliente asociado y el cliente revendedor, por lo que el producto no se puede actualizar",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Esta suscripción es compartida por varias entidades, por lo tanto, después de la actualización, se necesita una reasignación manual",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Agregar verificación de vencimiento de suscripción",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Agregar verificación de vencimiento de suscripción",
      "SUBSCRIPTION_CHECK_ID":"Verificación de Id. de suscripción",
      "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Requerido",
      "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"el número máximo de días debe ser menor o igual a {{MaxDate}}",
      "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"el número mínimo de días debe ser mayor o igual a 1",
      "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Has alcanzado tu límite máximo de 50 caracteres permitidos",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Notificación de expiración de suscripción fallida",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"Notificación de vencimiento de suscripción para clientes fallidos",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Notificación de vencimiento de suscripción para el éxito del cliente",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Notificación de expiración de suscripción exitosa",
      "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Seleccione un término",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Entregar",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Período de facturación",
      "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"historial de suscripciones",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Ciclo de facturación",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Período de facturación",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Categoría",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Clientes",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Fecha final",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"nombre de suscripción",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Proveedor",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Fecha de inicio",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Ciclos de facturación",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Categorías",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"proveedores",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Categorías de proveedores",
      "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"historial de suscripciones",
      "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Descargar historial de suscripción",
      "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Informe del historial de suscripciones",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Cerca",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Búsqueda",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Reiniciar",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Fecha",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nombre de Departamento",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Nuevo precio",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Añadir",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre del producto",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Precio anterior",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":" Precio",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Nombre del sitio",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Estado",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Usuario",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Fecha final",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Fecha de inicio",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":" Gestionar asignaciones de licencias",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Suscripciones",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Volver a Suscripciones",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Liberación de asientos no utilizados. Espere por favor...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":" Administrar asignaciones de licencia para",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Asignar licencias",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Quitar licencias",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":" Asignar usuarios",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Instrucciones",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Instrucciones",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Ver y buscar",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Licencias que se eliminarán",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Errores de validación",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Escriba direcciones de correo electrónico de usuario válidas separadas por una coma.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Este Portal valida la entrada y determina si es necesario comprar licencias adicionales antes de la asignación.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"No se puede realizar ninguna operación mientras trabajamos en cambios en esta suscripción.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Se mostrará cualquier error encontrado durante la validación de la entrada.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Durante el envío se considera Asignar o Quitar licencias (pestaña activa).",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Haga clic en <i class='fa fa-remove red'></i> frente a un usuario en la tabla de la izquierda para agregarlo a la lista de licencias que se eliminarán.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Haga clic en Enviar para eliminar las licencias.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Durante el envío se tiene en cuenta Asignar o Quitar licencias (pestaña activa)",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Costo",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Cantidad original.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Tarifa por asiento",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Usuario múltiple",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Remover",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Subir archivo",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Nombre",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"agregar nueva dirección de correo electrónico de usuario",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Ingrese los correos electrónicos de usuario válidos separados por una coma en un archivo .txt y cárguelo aquí.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Ingrese los correos electrónicos de usuario válidos separados por una coma en un archivo .txt y cárguelos. Ejemplo: abc@xyz.com,bcd@xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"ignorar errores",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Corregir errores",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Estado",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Espere mientras procesamos su solicitud; esto puede demorar un par de minutos. También puede volver a consultar haciendo clic en el botón \"Administrar\" en la página de suscripciones.",
      "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"¿Desea continuar con los próximos cambios de renovación de programación como {{isSeatLimitExceeded && isTransactionLimitExceeded? 'límite de asientos y límite de transacciones': (isSeatLimitExceeded? 'límite de asientos es': 'límite de transacciones')}} excedido",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Orden cancelada",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Pedido completado",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Suscripción comprada",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Suscripción eliminada",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"La solicitud seleccionada se aprobó con éxito.",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Aprovisionamiento de suscripciones",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"La solicitud seleccionada se canceló exitosamente.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"todos los clientes",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Aprovisionamiento de suscripciones",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"Activar",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Nombre de la entidad",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Título",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Espere mientras procesamos su compra anterior; esto puede demorar un par de minutos.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Espere mientras procesamos su compra anterior; esto puede demorar un par de minutos. También puede verificar el estado haciendo clic en el botón \"Agregar nueva suscripción\".",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Agregar cheque",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"volver a la lista",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Comprobaciones de vencimiento de suscripción",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Acción",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Días",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Nombre",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Término",
      "SUBSCRIPTION_STATUS_ACTIVE":"Activo",
      "SUBSCRIPTION_STATUS_CANCELLED":"Cancelado",
      "SUBSCRIPTION_STATUS_COMPLETED":"Terminado",
      "SUBSCRIPTION_STATUS_DELETED":"Eliminado",
      "SUBSCRIPTION_STATUS_DISABLED":"Desactivado",
      "SUBSCRIPTION_STATUS_EXPIRED":"Venció",
      "SUBSCRIPTION_STATUS_FAILED":"Ha fallado",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"En provisión",
      "SUBSCRIPTION_STATUS_PENDING":"Pendiente",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Tuvo éxito",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendido",
      "SUBSCRIPTION_UGRADE_TITLE_2":"Importante",
      "SUBSCRIPTION_UPDATED":"Suscripción actualizada correctamente.",
      "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automático",
      "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Elija una actualización elegible para su suscripción",
      "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Cantidad acumulada",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Suscripción actual",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Suscripción actual",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Seleccione una nueva oferta para actualizar la suscripción actual. Esto actualizará su suscripción y aprovisionará los servicios asociados con la nueva oferta.",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Durante la actualización, evite cambiar cualquier parte de la suscripción original o las cuentas del cliente. Esto creará una nueva suscripción, aprovisionará servicios y reasignará licencias de usuario a la nueva suscripción. Estos cambios no se pueden revertir.",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Asegúrese de realizar una copia de seguridad de los datos antes de actualizar la suscripción.",
      "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Ignorar error",
      "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Ignorar advertencia",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manual",
      "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"No había productos del plan elegibles para la suscripción",
      "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"No se encontraron transiciones",
      "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"¿Desea continuar con la actualización de la suscripción como {{isSeatLimitExceeded && isTransactionLimitExceeded? 'límite de asientos y límite de transacciones': (isSeatLimitExceeded? 'límite de asientos es': 'límite de transacciones')}} excedido",
      "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Reasignación de asientos",
      "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Asientos que deben actualizarse (Suscripción de origen)",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Seleccione una suscripciones de destino",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Este campo es obligatorio",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Suscripciones objetivo",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Nueva suscripción",
      "SUBSCRIPTION_UPGRADE_TITLE":"Elija una actualización elegible",
      "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Actividad de transición",
      "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"La actualización está en progreso",
      "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Mejora",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Este cliente ya ha sido asignado.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Los siguientes usuarios ya tienen una licencia asignada en esta oferta. Elimínelos e intente guardarlos.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"¿Estás seguro de que quieres ignorar los errores?",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Asignaciones de licencia actualizadas correctamente",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"¿Estás seguro de que quieres reactivar esta suscripción?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"La suscripción seleccionada se reactivó exitosamente",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"¿Está seguro de que desea liberar los asientos no utilizados?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"¡Elimine al menos un usuario para revocar la licencia de la tabla Licencias asignadas!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Seleccione el usuario de la lista sugerida antes de hacer clic en el botón Agregar.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"¿Estás seguro de que deseas suspender esta suscripción? Tenga en cuenta que las suscripciones complementarias activas relacionadas también se suspenderán (si corresponde)",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"La suscripción (incluidos los complementos, según corresponda) se suspendió correctamente",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Los asientos no utilizados de la suscripción se liberaron correctamente.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Su solicitud de asignación masiva está en cola",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Cargado con éxito",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Ingrese los correos electrónicos de usuario válidos para asignar las licencias en el cuadro de texto.",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nueva suscripción",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Suscripciones",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":" Editar",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Administrar",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Total",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Costo",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Total",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Nueva suscripción a Azure",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Nombre",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nueva suscripción",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azur",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nuevas suscripciones",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Suscripciones de Azure",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Precio estimado",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Suscripciones",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Suscripción de actualización",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Nueva cantidad total",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"El valor mínimo es 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"La entrada debe ser un número entero válido.",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Oferta",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Compre asientos adicionales aumentando la <strong>nueva cantidad total</strong>",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Asegúrese de haber liberado las licencias de los usuarios a través de <strong>Usuarios -&gt; Licencias</strong> por si quieres reducir el número de puestos desde aquí",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Su solicitud de compra de suscripciones está en cola",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Reactivar",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Suscripción guardada exitosamente",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Seleccione al menos una oferta antes de realizar un pedido",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Suspender",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Configuración general actualizada con éxito",
      "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"La configuración de ConnectWise Manage se actualizó correctamente.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"La configuración SMTP del socio se actualizó correctamente.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"El socio {{logo}} se actualizó correctamente.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"La configuración del socio se actualizó correctamente.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"La configuración del proveedor se actualizó correctamente.",
      "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"La configuración de registro público se actualizó correctamente.",
      "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Importación de múltiples pagos completada exitosamente",
      "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Datos de uso diario de ofertas de socios importados con éxito",
      "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Importación de comisiones de productos completada con éxito",
      "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Creó con éxito un nuevo plan con todas las ofertas",
      "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Creó con éxito un nuevo plan de revendedor con todas las ofertas",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Solucionar discrepancia de cantidad",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Actualice el plan de este cliente para incluir ofertas relevantes y regrese aquí para solucionar el problema.",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"Nombre de la oferta CSP",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"No se encontraron ofertas coincidentes",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Oferta equivalente en el plan actual",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":" Precio",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Cantidad comprada",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapeo de ofertas",
      "SUPPLIER_DETAILS":"Detalles del proveedor",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Nuevo ticket de soporte",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Email de contacto",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"teléfono de contacto",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Descripción",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":" Prioridad",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":" Área de productos",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"Tema",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"Tipo",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Apoyo",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Número de ticket de soporte",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Agregar comentario",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Comentarios",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Email de contacto",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"teléfono de contacto",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":" Esfuerzo acumulativo",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Cliente",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Descripción",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":" Esfuerzo (en horas)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Prioridad",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Área de productos",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Estado",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Tema",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Agente de soporte",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Seleccionar agente de soporte",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Tipo",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"No se agregaron comentarios.",
      "SUPPORT_FOR_TEXT":"Apoyo para",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Agregar ticket de soporte",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Boletos de soporte",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Cliente",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Búsqueda",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"Estado",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Agente de soporte",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Identificación de entradas",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Comportamiento",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Detalles",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Email de contacto",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Cliente",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Prioridad",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Área de productos",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Estado",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Tema",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Identificación de entradas",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Tipo",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Nuevo ticket de soporte creado exitosamente",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Archivado",
      "SUPPORT_TICKET_STATUS_CLOSED":"Cerrado",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"En progreso",
      "SUPPORT_TICKET_STATUS_OPEN":"Abierto",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Reabrir",
      "SUPPORT_TICKET_STATUS_RESOLVED":"Resuelto",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"No se arreglará",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Problemas de acceso",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"Analítica",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"microsoft azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Gestión de perfiles",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Gestión de suscripciones",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Gestión de usuarios",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Detalles del ticket de soporte actualizados exitosamente",
      "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"No se ha encontrado ningún mercado para el código de moneda elegido",
      "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Los siguientes mercados no son compatibles ya que no se ha encontrado ninguna conversión de moneda para sus respectivas monedas.",
      "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"El mercado admitido solo se aplica a las ofertas de NCE",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Mercado",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Código de mercado",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Divisa",
      "SupportedMarkets":"Mercados admitidos",
      "SWAL_OK_TEXT":"OK",
      "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Cambiar a la API de precios de Microsoft",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Cambiar al nuevo modelo de seguridad",
      "SYNC_CUSTOMER_PROFILE":"Sincronizar perfil de cliente",
      "TAB_HEADING_PROVISIONING_STATUS":"Estado de aprovisionamiento",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Mostrando {{StartIndex}} - {{EndIndex}} de {{Total}} registros",
      "TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "TABLE_HEADER_TEXT_DATE":"Fecha",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Motivo del fracaso",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"gran cantidad",
      "TABLE_HEADER_TEXT_KEY":"Llave",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Monto de pago",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Estado de pago",
      "TABLE_HEADER_TEXT_REMARKS":"Observaciones",
      "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Cantidad subtotal",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Importe del impuesto",
      "TABLE_HEADER_TEXT_VALUE":"Valor",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Total de facturas seleccionadas",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Buscar...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Etiqueta eliminada correctamente",
      "TAG_NAME_PLACEHOLDER":"Seleccione una etiqueta",
      "TAG_SAVED_SUCCESS_MESSAGE":"Etiqueta guardada exitosamente",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Nombre del plan",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Nombre del producto",
      "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Propiedades",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Volver a Etiquetas",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar",
      "TAGS_CAPTION_TEXT_TAG_FOR":" Etiqueta para",
      "TAGS_CAPTION_TEXT_TAGS_FOR":" Etiquetas para",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Moneda objetivo",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Moneda objetivo",
      "TARGET_PLAN_DISPLAY_NAME":"Plan objetivo",
      "TARGET_PRODUCT_DISPLAY_NAME":"Producto objetivo",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Volver a porcentajes de impuestos",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Porcentajes de impuestos",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Agregar",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Editar",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Las reglas impositivas se definen mediante combinaciones de {País, Provincia estatal y Código fiscal}, {País, Código postal} o {País, Provincia estatal y Código postal}.",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Por ejemplo: considere la definición de la regla fiscal para un país como EE. UU., dejar el estado, la provincia y el código postal en blanco significa que la tasa impositiva se aplica a todos los clientes de EE. UU. Si desea definir una regla para un estado en particular, proporcione los valores de País y Estado mientras define la regla.",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"Válido desde",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":" Nombre del impuesto",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":" Porcentaje de impuestos",
      "TAX_PERCENTAGES_ADD_SUCCESS":"El porcentaje de impuestos se agregó correctamente.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Clientes",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Porcentaje de impuestos eliminado correctamente.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Agregar porcentaje de impuestos",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"volver a la lista",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Porcentajes de impuestos",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":" Porcentajes de impuestos para",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Borrar",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Editar",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Comportamiento",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"Válido desde",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":" % de impuesto",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Porcentaje de impuestos actualizado correctamente.",
      "TAX_RULES_ADD_SUCCESS":"Regla fiscal agregada exitosamente",
      "TAX_RULES_DELETE_SUCCESS":"Regla fiscal eliminada correctamente",
      "TAX_RULES_UPDATE_SUCCESS":"Regla fiscal actualizada exitosamente",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Impuestos acumulativos",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Las reglas del impuesto por hijos se aplican en secuencia sobre el monto resultante de cada paso del cálculo.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"General",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Porcentaje de impuesto único aplicado sobre el importe total.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Subimpuestos",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"El porcentaje de impuesto para esta definición será el total (suma) de las reglas del impuesto por hijos.",
      "TENANT TOOLTIP":"Seleccionar inquilino para cargar usuarios",
      "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activar la suscripción de umbral de peligro alcanzado",
      "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Habilite la liberación automática para asientos no asignados",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permite al cliente cambiar la cantidad de producto desde la cuadrícula o la vista de lista en la pantalla de productos.",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Permitir que el cliente inicie la transacción de su(s) factura(s)",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Marcar esta bandera evita que los clientes reduzcan la cantidad de suscripción",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Permitir la sincronización de nombres de productos para ofertas basadas en el uso",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Permitir la sincronización de nombres de productos para ofertas basadas en cantidad",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Permite la sincronización del nombre descriptivo de suscripción para ofertas basadas en cantidad",
      "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Opción de aplicar descuentos del nivel 2 del plan Azure al cliente. El valor predeterminado es No. Si se establece en Sí, excepto los descuentos PEC, todos los demás descuentos también se aplicarán al cliente.",
      "TENANT_CONFIG_DESC_AUTO_SYNC":"Esto se usa para habilitar/deshabilitar el proceso de sincronización automática en la conciliación.",
      "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Duración del contrato anual de Autotask (años)",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Fecha de finalización del contrato de Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Este valor de fecha se utilizará como fecha de finalización para crear contratos automáticamente para el cliente",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Tarifa de configuración del contrato Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Ingrese las tarifas de configuración del contrato, si corresponde",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Fecha de inicio del contrato de Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Este valor de fecha se utilizará como fecha de inicio para crear contratos automáticamente para el cliente",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Día de inicio del contrato de Autotask",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Active la casilla de verificación si el informe de tiempo del contrato requiere horas de inicio y finalización",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Informes de tiempo de contrato de Autotask requeridos tiempos de inicio y finalización",
      "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Duración del contrato mensual de Autotask (meses)",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Permitir a los clientes administrar suscripciones de Azure",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permita que los clientes definan grupos a través de suscripciones de Azure",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará al uso de Azure del cliente. Por ejemplo: ingrese 1.15 si el margen de beneficio debe ser del 15%.",
      "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitada, la suscripción NCE se establece en renovación automática.",
      "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Habilitar la consideración del precio del ERP en la renovación sin macro",
      "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Después de la compra o aumento de cantidad, el producto no será considerado en autoliberación hasta este período (en días).",
      "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"Id. de oportunidad de CRM",
      "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Tipo de facturación del cliente",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Moneda del cliente",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Tipo de efectividad de actualización automática de precios de productos de soporte al cliente. ¡Esta configuración entrará en vigencia si y solo si la actualización automática del precio del producto del cliente está habilitada!",
      "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Umbral de peligro predeterminado en porcentaje para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Umbral de error predeterminado en porcentaje para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Umbral de información predeterminado en porcentaje para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Presupuesto mensual predeterminado para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinatario de correo electrónico de umbral predeterminado para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Umbral de advertencia predeterminado en porcentaje para la suscripción de Azure.",
      "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Habilitar términos y condiciones para ordenar productos",
      "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Habilitar términos y condiciones de NCE",
      "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Habilitar informes de Power BI",
      "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"Habilitar esta opción permitirá a los usuarios programar un pedido",
      "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ID de cliente de ERP",
      "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Cliente exento para exportación de PSA",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Si este campo está marcado, tomamos en consideración el consentimiento del cliente.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Este campo se actualiza si al menos uno de los clientes ha aceptado el MCA. Desmarque esta casilla para exigir la aceptación por parte del cliente nuevamente.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"¿Aceptó los últimos términos y condiciones?",
      "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Límite de transacción",
      "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Constante entera",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Para referencia interna. Esta identificación se puede utilizar para relacionar al cliente con un registro en su sistema de contabilidad interno.",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Día del mes en que las facturas se aprueban automáticamente",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Día en que se procesan los pagos automáticos",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Fecha de vencimiento de las facturas",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"No de días vencidos para el pago de la factura",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Día del mes en el que las facturas se finalizan automáticamente",
      "TENANT_CONFIG_DESC_INVOICE_NOTE":"Aparece en el pdf de la factura",
      "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Ordene la disponibilidad del perfil de pago antes de pagar el carrito",
      "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Se requiere liberación automática para productos de sitio y departamento",
      "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Obligar al usuario a enviar el método de pago",
      "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente la dirección recién agregada como la dirección predeterminada para el cliente.",
      "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el correo electrónico recién agregado como el correo electrónico predeterminado para el cliente.",
      "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el número de teléfono recién agregado como el número de teléfono predeterminado para el cliente.",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Propietario de factura única (tipo)",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Propietario de factura única (identificador)",
      "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a los artículos de línea únicos del cliente.",
      "TENANT_CONFIG_DESC_PAN_NUMBER":"Aparece en el pdf de la factura en la sección Facturar a.",
      "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Aparece en el pdf de la factura en la sección Facturar a.",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Enlace de términos y condiciones",
      "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones perpetuas de software.",
      "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Aparece en el pdf de la factura en la sección Facturar a.",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Definir el número de orden de compra para el cliente.",
      "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Si esta configuración está habilitada, el correo electrónico de facturación se publicará en servicios de contabilidad externos",
      "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Habilitar la función para transmitir facturas al servicio de contabilidad externo",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Marque esta bandera si desea que el sistema procese pagos (CC/ACH según corresponda)",
      "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Cuando está habilitado, mostrará el nombre de la suscripción dado en el centro de socios",
      "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"La facturación perpetua del software se incluirá en las facturas de este Sitio.",
      "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"Aplicar un límite en la cantidad de asientos que el cliente puede comprar",
      "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Las condiciones de pago que se marcarán en las facturas que se publican en QuickBooks",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Ingrese la cantidad de días antes de la fecha de renovación cuando se enviarán los correos electrónicos de consentimiento a los clientes.",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Especifique la cantidad de días después de la notificación de consentimiento cuando se enviará la notificación de recordatorio a los clientes.",
      "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"La facturación de reservas de Azure se incluirá en las facturas de este Sitio.",
      "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará en la reserva azul del cliente.",
      "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"Modelo de precios de RI",
      "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"El cliente recibirá el correo electrónico con los detalles de la factura una vez que se apruebe la factura y si las notificaciones por correo electrónico están habilitadas",
      "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"El cliente recibirá una notificación por correo electrónico con respecto a las facturas que tienen montos cero",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Aparece en el pdf de la factura en la sección Enviar a.",
      "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Debería habilitarse por defecto para el cliente.",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Debe notificar al cliente",
      "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Debería eximir al cliente del pago instantáneo.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede cancelar la suscripción a NCE dentro de la ventana de cancelación de 7 días calendario.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Cuando está habilitado, el cliente puede cancelar las suscripciones de software del producto dentro de un período de cancelación de 7 días calendario.",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Permitir al cliente alinear las fechas de finalización de la suscripción",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Si está habilitado, el Cliente puede alinear las fechas de finalización de la suscripción para lograr la coterminosidad",
      "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Mostrar siguiente menú de renovación de programación",
      "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Habilite esta opción para mostrar las suscripciones no incorporadas para la selección de fecha de finalización personalizada durante la compra.",
      "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede reactivar una suscripción NCE suspendida.",
      "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Botón para mostrar calificaciones especiales",
      "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede suspender la suscripción a NCE.",
      "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Cuando está habilitado, el cliente puede actualizar el producto NCE a un SKU superior",
      "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Suscripción que se omitirá durante una factura única",
      "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
      "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"La facturación de la suscripción de software se incluirá en las facturas de este Sitio.",
      "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Establecer este valor en verdadero detendrá la capacidad del usuario para realizar compras.",
      "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Constante de cadena para el cliente",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Soporte Actualización automática de precios de productos para clientes",
      "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspender cuando se alcance el umbral de peligro",
      "TENANT_CONFIG_DESC_TAX_CODE":"El código impositivo se utiliza para determinar el porcentaje de impuesto aplicable.",
      "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Aparece en el pdf de la factura en la sección Facturar a.",
      "TENANT_CONFIG_DESC_TAX_VALUE":"Aparece en el pdf de la factura en la sección Facturar a.",
      "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para suscripciones de terceros.",
      "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"La facturación de suscripciones de terceros se incluirá en las facturas de este Sitio.",
      "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"Hacer cumplir un límite en el valor monetario de la compra de productos basada en la cantidad (en la moneda del cliente)",
      "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Tipo de adición de ConnectWise",
      "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Facturación Anticipada",
      "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Según el formato seleccionado, las adiciones se crearán en connectwise",
      "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Colocar la facturación del mes actual en el mes especificado en el futuro para la facturación",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Mostrar el registro de aceptación de términos y condiciones al inquilino",
      "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activar la suscripción de umbral de peligro alcanzado",
      "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Permitir la liberación automática de asientos no utilizados",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permitir al cliente cambiar la cantidad de producto de la lista",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Permitir que el cliente inicie la transacción",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Permitir al cliente reducir asientos",
      "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Aplicar descuentos del nivel 2 del plan Azure al cliente",
      "TENANT_CONFIG_DISP_AUTO_SYNC":"Sincronización automática",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"¿Habilitar Azure?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"¿Habilitar Azure para empresas?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Marcar el uso de Azure",
      "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Administrar productos NCE es renovación automática",
      "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Considere el precio del ERP en el momento de la renovación sin macro",
      "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Período de enfriamiento de liberación automática",
      "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"ID de oportunidad de CRM",
      "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Tipo de facturación del cliente",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Moneda del cliente",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Eficacia de la actualización automática del precio del producto del cliente",
      "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Umbral de peligro por defecto (%)",
      "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Umbral de error predeterminado (%)",
      "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Umbral de información predeterminado (%)",
      "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Presupuesto mensual predeterminado",
      "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinatario de correo electrónico de umbral predeterminado",
      "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Umbral de advertencia predeterminado (%)",
      "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Habilitar términos y condiciones para ordenar productos",
      "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Habilite los términos y condiciones de NCE para pedir productos de Microsoft NCE",
      "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Habilitar orden de programación",
      "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ID de cliente de ERP",
      "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Cliente exento para exportación de PSA",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"¿Consentimiento del cliente habilitado?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"¿Se acepta el acuerdo de cliente de Microsoft?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"¿Términos y condiciones de socio aceptados?",
      "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Límite de transacción",
      "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"ID de referencia de facturación",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Día en que el cliente puede ver las facturas.",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Día para pago automático",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Fecha de vencimiento de las facturas",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Días de vencimiento de facturas",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Día en que se finalizan las facturas.",
      "TENANT_CONFIG_DISP_INVOICE_NOTE":"notas de factura",
      "TENANT_CONFIG_DISP_INVOICE_TYPE":"Tipo de factura",
      "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mandato Perfil de pago Carro Caja",
      "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Se requiere liberación automática para productos de sitio y departamento",
      "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Perfil de mandato",
      "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Asignar automáticamente una nueva dirección como predeterminada",
      "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Asignar automáticamente un nuevo correo electrónico como predeterminado",
      "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Asignar automáticamente un nuevo número de teléfono como predeterminado",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Propietario de factura única (tipo)",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Propietario de factura única (identificador)",
      "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Margen para líneas de pedido únicas",
      "TENANT_CONFIG_DISP_PAN_NUMBER":"número de PAN",
      "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"tecla numérica PAN",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"URL de términos y condiciones",
      "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Marcado para suscripción perpetua de software",
      "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Lugar de suministro",
      "TENANT_CONFIG_DISP_PO_NUMBER":"Número de orden de compra",
      "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Publicar correo electrónico de facturación en servicios de contabilidad externos",
      "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Registrar facturas en servicio de contabilidad externo",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"¿Pago automático de facturas?",
      "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Mostrar nombre de suscripción del proveedor",
      "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Propietario de la factura de software perpetua (identificador)",
      "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Límite de asientos aplicable",
      "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Condiciones de pago de QuickBooks",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Periodo de inicio del consentimiento de renovación",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Días de notificación de recordatorio de consentimiento de renovación",
      "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Propietario de la factura de reserva de Azure (identificador)",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
      "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"Modelo de precios de RI",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
      "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Enviar correos electrónicos de facturas automáticamente",
      "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Enviar facturas de importe cero",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Enviar a nombre de contacto",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Enviar al número de contacto",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Enviar a número PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Enviar a clave numérica PAN",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Enviar a tipo de impuesto",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Enviar al valor fiscal",
      "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Debería habilitarse por defecto para el cliente",
      "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Pago de pago instantáneo exento",
      "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Botón para mostrar calificaciones especiales",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar botón Cancelar producto NCE",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Mostrar botón Cancelar producto de suscripciones de software",
      "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Mostrar suscripciones no incorporadas para una fecha de finalización personalizada",
      "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar el botón de reactivación del producto NCE",
      "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar botón de suspensión del producto NCE",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Mostrar registro de aceptación de términos y condiciones",
      "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Mostrar el botón de actualización del producto NCE",
      "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de software",
      "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Propietario de la factura de suscripción de software (identificador)",
      "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Detener todas las transacciones",
      "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Identificador único del cliente",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Actualización automática del precio del producto del cliente",
      "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspender cuando se alcance el umbral de peligro",
      "TENANT_CONFIG_DISP_TAX_CODE":"Código de impuestos",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Tipo de impuesto",
      "TENANT_CONFIG_DISP_TAX_VALUE":"valor fiscal",
      "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de terceros",
      "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Propietario de la factura de suscripción de terceros (identificador)",
      "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"límite de cantidad de transacción",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Introduzca el valor de {{tenantConfigName}}",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Introduzca un valor válido de {{tenantConfigName}}",
      "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"Administrar la configuración de renovación automática no está habilitado para este inquilino.",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"La configuración del cliente se revirtió correctamente",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"La configuración del cliente se guardó correctamente",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"La configuración del cliente para {{customername}} se actualizó correctamente",
      "TENANT_COUNTS":"Contar",
      "TENANT_CREATION_FAILED":"No se pudo crear el inquilino",
      "TENANT_EXCEEDED_COUNT_ERROR":"Número de inquilinos que se crearán, sin exceder {{Count}}",
      "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":" Si desea crear la suscripción de Azure con el arrendatario, márquelo.",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Agregue el prefijo 'OTU' en el nombre",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Agregue 'OTU' como prefijo en el nombre del inquilino",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Asignar administrador de inquilinos como propietario",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Asigne al usuario administrador como propietario de la suscripción creada",
      "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Agregar inquilinos",
      "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Crear suscripciones",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Departamento",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Nombre de dominio",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Los nombres de dominio comenzarán con esto.",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Seleccione la oferta del plan de Azure",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Use esto para seleccionar el producto del plan correcto para asignar el plan de Azure",
      "TENANT_MANAGEMENT_LABEL_PREFIX":"Prefijo",
      "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Agregar prefijo en el nombre del inquilino",
      "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Secuencia de inicio",
      "TENANT_MANAGEMENT_LABEL_SITE":"Sitio",
      "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Número de inquilinos",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Nombre",
      "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Proceso completo. Consulte el registro de auditoría para obtener más detalles.",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Se acepta la solicitud para agregar inquilinos y se está procesando",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La solicitud para agregar inquilinos falló.",
      "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Proporcione una secuencia numérica",
      "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"El incremento secuencial se utilizará como sufijo en los nombres de inquilinos.",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Estado de creación del inquilino",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Espere mientras se crean los inquilinos.",
      "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Número de inquilinos a crear.",
      "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Los nombres de los inquilinos comenzarán con esto.",
      "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Volver a Inquilinos",
      "TENANT_SEQUENCE":"Secuencia",
      "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"Al hacer clic en el botón \"Aceptar\", usted acepta los términos de uso anteriores.",
      "TERM_AND_CONDITIONS_LINK_TEXT":"Condiciones de uso para socios",
      "TERM_AND_CONDITIONS_READ_TEXT":"Lea atentamente los términos y condiciones que se proporcionan a continuación.",
      "TERM_DURATION_DESC_DAY(S)":"Días)",
      "TERM_DURATION_DESC_DAYS":"Día",
      "TERM_DURATION_DESC_MONTH":"Mes",
      "TERM_DURATION_DESC_MONTH(S)":"Meses)",
      "TERM_DURATION_DESC_YEAR":"Año",
      "TERM_DURATION_DESC_YEAR(S)":"Años)",
      "TERM_DURATION_DESC_YEARS":"Años",
      "TERM_DURATION_FILTER_DESC_DAYS":"días",
      "TERMS_AND_CONDITIONS":"Términos y condiciones",
      "TERMS_AND_CONDITIONS_ACCEPT":"Por favor acepte términos y condiciones",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Términos y condiciones aceptados exitosamente.",
      "TERMS_AND_CONDITIONS_BACK":"Volver a Términos y condiciones",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Lea los términos y condiciones que se proporcionan a continuación</b>. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>Términos de uso del socio</a></li> </ul> < /br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Lea los términos y condiciones de Microsoft que se proporcionan a continuación. </br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Lea detenidamente el contrato, los términos y condiciones de <b>{{partnerName}}</b> y Microsoft que se proporcionan a continuación. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li> </ul> </br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Haga clic <a id='linkEzidebit' href='#' ng-click='vm.EzidebitAgreementClicked()'> aquí </a>para leer el Acuerdo Ezidebit completo",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Acuerdo Ezidebit",
      "TERMS_AND_CONDITIONS_READ":"He leído los términos y condiciones",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"No se puede procesar su solicitud.",
      "TEST_EMAIL_LABEL":"ID de correo electrónico de prueba",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Prueba",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Prueba",
      "TEST_SMTP_SETTING_TITLE":"Probar la configuración SMTP",
      "TEXT_ACH":"CCA",
      "TEXT_ACTIONS":"Comportamiento",
      "TEXT_BILL_FROM":"Factura de",
      "TEXT_BILL_TO":"Cobrar a",
      "TEXT_BILLING_PERIOD":"Período de facturación",
      "TEXT_EMAIL_ID":"Identificación de correo",
      "TEXT_GROUP_NAME":"Nombre del grupo",
      "TEXT_INVOICE_AMOUNT":"Monto de la factura",
      "TEXT_INVOICE_DATE":"Fecha de la factura",
      "TEXT_INVOICE_NUMBER":"Número de factura",
      "TEXT_INVOICE_SUMMARY":"Resumen de factura",
      "TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
      "TEXT_OFF":"APAGADO)",
      "TEXT_PLAN_ID":"identificación del plan",
      "TEXT_RESPONSIBLE_OFFICIAL":"Oficial Responsable",
      "TEXT_SAAS_SUBSCRIPTION_STATUS":"Estado de suscripción de SaaS",
      "TEXT_SIGN_IN_REQUIRED":"Inicio de sesión requerido",
      "TEXT_TENANT_ID":"Identificación del inquilino",
      "TEXT_THRESHOLDS":"Umbrales",
      "TEXT_VAT_NUMBER":"Número de valor agregado",
      "THE_SEQUENCE_LABEL_THE_STARTING_NAME":" El nombre de la secuencia comenzará desde",
      "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Seleccionar Acuerdo PSA",
      "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Completar el mapeo para la entidad C3",
      "THIRD_PARTY_ENTITY_MAPPING":"Mapeo de entidades de terceros",
      "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación para el cliente: <b>{{customerName}}</b>?",
      "THIRD_PARTY_PRODUCT_MAPPING":"Mapeo de productos de terceros",
      "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar los productos de terceros seleccionados?",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Acción",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Convenio",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"Cliente C3",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"Cliente de PSA",
      "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"Seleccione el servicio PSA",
      "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Seleccionar cliente de PSA",
      "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Mapeo de suscripciones de terceros",
      "TIME_DESC_DAY":"día",
      "TIMER_DESC_DAYS":"días",
      "TITLE_COUPON_CODE_TEXT":"Códigos de cupón",
      "TITLE_CUMULATIVE_TAXES":"Impuestos acumulativos",
      "TITLE_ROLE_SELECT":"Seleccionar rol",
      "TITLE_SUB_TAXES":"Subimpuestos",
      "TITLE_TAGS":"Etiquetas",
      "TITLE_TEXT":"Bienvenido al Centro de Control CSP",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"para incorporar suscripciones de Azure",
      "TOOL_TIP_TEXT_HIDE":"Esconder",
      "TOOL_TIP_TEXT_SHOW":"Espectáculo",
      "TOOLTIP_COUPON_CODE_TEXT":"Código promocional",
      "TOOLTIP_TEXT_DELETE_PSA_LOG":"Desactivar registro",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Desactivar producto",
      "TOOLTIP_TEXT_EDIT_NAME":"Editar nombre",
      "TOOLTIP_TEXT_EDIT_QUANTITY":"Editar cantidad",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Habilitar producto",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Enlace de producto",
      "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"El producto vinculado no es compatible",
      "TOOLTIP_TEXT_QUANTITY":"Cantidad comprada",
      "TOUR_POPUP_CONTENT":"<b>¡Bienvenido a bordo!</b></br> Vea un breve recorrido por las funciones de la aplicación.",
      "TOUR_POPUP_DISMISS_BUTTON":"Despedir",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"hacer un recorrido",
      "TOUR_POPUP_TITLE":" Bienvenido a",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Comportamiento",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Monto",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Categoría",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Velocidad",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Descripción",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Descuento",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Cantidad comprada",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Velocidad",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"cantidad acumulada",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"gravado",
      "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Cantidad de transacción excedida",
      "TRANSACTION_LIMIT_MESSAGE":"Límite restante {{RemainingAmount}} de {{TotalLimit}}",
      "TRANSACTION_TYPE_PURCHASE":"Nueva compra",
      "TRANSACTION_TYPE_UPDATE":"Actualización de cantidad",
      "TRANSACTION_TYPE_UPGRADE":"Actualización de suscripción",
      "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Actualmente, existe una solicitud para convertir la oferta de prueba en una suscripción; No se permite realizar ninguna operación en este producto.",
      "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Los dos valores válidos más cercanos son {{lower}} y {{upper}}.",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Ciclo de facturación",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Tipo de facturación",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Categoría",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Tipo de consumo",
      "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"¿Es auto renovable?",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Nombre del producto",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Proveedor",
      "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Precio de venta",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Tipo de venta",
      "TRIAL_OFFER_PARENT_TERM_DURATION":"Duración del término",
      "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Continuar la suscripción después de la prueba",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Instrucciones",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Nota",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"El período de prueba tiene una duración de un mes y el producto se cancelará posteriormente.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"días, y el producto será cancelado posteriormente.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" El período de prueba tiene una duración de",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"Al hacer clic en \"Continuar suscripción\", el producto se comprará después del período de prueba y la facturación comenzará en consecuencia.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Tiene la flexibilidad de extender su suscripción más allá del período de prueba seleccionando el botón \"Comprar suscripción\".",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"La oferta de prueba compartida entre el sitio/departamento no se convertirá en suscripción.",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Se comprará una suscripción de una cantidad en la entidad operativa del cliente.",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Por favor ingrese una cifra válida",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"La cantidad máxima es 10000",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"La cantidad mínima es 1",
      "TRIAL_PERIOD_DAYS_TEXT":"Días",
      "TROUE_BTN_TEXT_END_TOUR":"Fin del recorrido",
      "TROUE_BTN_TEXT_NEXT":"Siguiente &raquo;",
      "TROUE_BTN_TEXT_PREV":"&laquo; Anterior",
      "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"No se puede encontrar ningún plan de Azure desde la PC",
      "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El cliente se desasignó con éxito",
      "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"¿Está seguro de que desea anular la asignación de {{customer}}?",
      "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El revendedor se desasignó con éxito",
      "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Cliente no asignado de un administrador de cuentas con éxito",
      "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Revendedor no asignado de un administrador de cuentas con éxito",
      "UNIQUE_NAME_ERROR":"Este nombre ya existe, elija algo diferente.",
      "UNSUPPORTED_FILE_TYPE_SELECTED":"Solo se admiten archivos de tipo jpg, svg, png, gif, bmp",
      "UP_GRADE_AZURE_SUBSCRIPTION":"Actualice la suscripción de Azure al plan de Azure.",
      "UPDATE_CART_LINE_ITEM":"Artículo de carrito actualizado",
      "UPDATE_CART_LINE_ITEM_FAILURE":"No se pudo actualizar el artículo del carrito",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Configuraciones globales del cliente actualizadas correctamente",
      "UPDATE_DEFAULT_VALUE":"Marcar como predeterminada",
      "UPDATE_DUE_DATE_NAME":"Fecha de vencimiento",
      "UPDATE_INVOICE_LINEITEM_PONUMBER":"Línea de pedido de la factura N.º de orden de compra",
      "UPDATE_INVOICE_PONUMBER":"Número de orden de compra",
      "UPDATE_INVOICE_PROPERTIES":"Actualizar propiedad de factura",
      "UPDATE_MANAGE_RENEWAL_TITLE":"Renovación de actualización",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Se produjo un error al actualizar la configuración del socio",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al actualizar los detalles de impuestos.",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"No se permite actualizar los productos ya que la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
      "UPDATE_PROFILE":"Perfil actualizado exitosamente",
      "UPDATE_PROFILE_FAILURE":"No se pudo actualizar el perfil",
      "UPDATE_RESELLER_CONFIGURATION":"Actualizar la configuración del revendedor",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"No se pudo actualizar la configuración del revendedor",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Actualizar la opción de pago predeterminada del revendedor",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Error en la opción de pago predeterminada del revendedor",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Perfil de pago de revendedor actualizado",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"No se pudo actualizar el perfil de pago del revendedor",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Precio del producto del plan de revendedor actualizado",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"No se pudo actualizar el precio del producto del plan de revendedor",
      "UPDATE_RESELLER_PROFILE":"Actualizar perfil de revendedor",
      "UPDATE_RESELLER_PROFILE_FAILURE":"No se pudo actualizar el perfil de revendedor",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valor predeterminado actualizado del cliente proveedor",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"No se pudo actualizar el valor predeterminado del cliente proveedor",
      "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Detalles del administrador de cuenta actualizados con éxito",
      "UPDATED_FAILED_C3_PSA_DATA_FAIL":"Error en la actualización de datos de PSA",
      "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"La actualización de datos falló; se podrá acceder a otras opciones una vez que haga clic en Aceptar.",
      "UPDATED_PAGE_MODE_TO":"Actualizado a la vista {{PageMode}} correctamente",
      "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"Se actualizaron correctamente los datos de C3 PSA con los datos más recientes del sistema externo de PSA",
      "UPGRADE_ELIGIBILITY_CHECK":"Verifique la elegibilidad de actualización",
      "UPGRADE_FAILED_MSG":"La actualización de Azure falló",
      "UPGRADE_SUCCESS_MSG":"Se acepta la solicitud de actualización y se está procesando.",
      "UPGRADE_SYNC_PUPUP_TITLE":"Actualizar Azure",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Alinear con la fecha de inicio del contrato",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Alinear la fecha de vigencia del producto con el próximo período de contrato reciente. Tenga en cuenta que la siguiente fecha reciente se calcula solo mensualmente, incluso si el período del contrato es anual.",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Cargar facturas en QuickBooks Online ID de lote",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Mensaje de error al cargar facturas en Quickbooks",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea cargar las facturas seleccionadas en <b>QuickBooks Online</b>?",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Cargar facturas en Xero Batch Id.",
      "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea cargar las facturas seleccionadas en <b>Xero</b>?",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Mensaje de error al cargar facturas en Xero",
      "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Subir detalles de revendedores",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"No se pudo cargar el historial de suscripciones en el servicio externo",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Cargue correctamente el historial de suscripciones en un servicio externo",
      "UPLOAD_TO_PSA_SELECT_ALL":"Seleccionar todo",
      "Usage":"Informe de uso",
      "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"No puede actualizar el porcentaje -ve a un valor del porcentaje disponible.",
      "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"No puede actualizar el porcentaje a un valor superior al porcentaje disponible.",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Obtener informe de uso",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Identificación de suscripción",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"nombre de suscripción",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Facturar al cliente",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Fecha de finalización del cargo",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Fecha de inicio de carga",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"costo para el cliente",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Costo para el socio (después de impuestos)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Costo para el socio (antes de impuestos)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Código de moneda",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nombre del cliente",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Descargar informe de uso",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nombre del inquilino",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Informe de uso de Azure",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Volver a suscripciones",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Editar suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Agregar suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":" Umbral de peligro",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Umbral de error",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Nombre del grupo",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Selecciona grupo",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Umbral de información",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Ocultar del cliente",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Presupuesto mensual",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Umbral de advertencia",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Volver a usuarios de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":" Administrar usuario de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Correo electrónico",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Role",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Contribuyente",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Dueño",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Lector",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":" Volver a suscripciones",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":" Exportar detalles de uso",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Resumen de facturación",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Detalles de uso de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Gastar",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Unidades consumidas",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Tipo de recurso",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Agregar suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":" Volver a clientes",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":" Suscripciones de Azure para",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Todas las suscripciones",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Período de facturación",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Grupo",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Editar suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Usuarios",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Ver detalles de uso",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Comportamiento",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Costo estimado",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Grupo",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"ID de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Umbrales",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Agregar usuario a la suscripción",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Volver a suscripciones",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Usuarios de suscripción de",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Correo electrónico",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Nombre",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Role",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Borrar usuario",
      "USER_CREATED_POPUP":"Usuario creado",
      "USER_CREATION_SUCCESS_MESSAGE":"Usuario {{UserName}} creado exitosamente",
      "USER_DETAILS_NOT_FOUND_BODY":"No se pueden encontrar los detalles del usuario que inició sesión. ¡Por favor contacte al administrador!",
      "USER_DETAILS_NOT_FOUND_HEADER":"Detalles no encontrados",
      "USER_HAS_BEEN_CREATED":"El usuario ha sido creado con los siguientes detalles",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"ID de lote de asignación de licencia de usuario",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Hecho",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Estado de asignación de licencia",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Nombre del cliente",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Usuarios",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"correo electrónico",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Nombre del inquilino",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Identificación del inquilino",
      "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"No se puede actualizar el estado, comuníquese con el soporte",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Añadir",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Manojo",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":" Vinculado",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Estado de eliminación del usuario",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Aplicar cambios a complementos",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Usuarios del producto",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Se produjo un error al liberar los asientos. Por favor contacte al soporte.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Debe haber al menos una licencia activa. Suspenda el producto si no desea utilizarlo más.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Se está procesando la solicitud para liberar asientos.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Cargado con éxito",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"La solicitud para agregar licencias se acepta y se está procesando.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"La solicitud de asignación masiva de licencias está aceptada y en proceso.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Asignado",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Asientos totales",
      "USER_LISTING_FILTER_LABEL":"Identificación de correo",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Buscar por nombre para mostrar o ID de correo electrónico",
      "USER_LISTING_SEARCH_BUTTON":"Búsqueda",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Exportar informe de asignación de usuarios",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entidades",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Seleccione al menos una entidad",
      "USER_REMOVED":"Usuario eliminado exitosamente",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Nombre de rol",
      "USER_ROLE_LIST_LABEL_SCOPE":"Nombre del ámbito",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Nombre de usuario",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Usuario agregado exitosamente",
      "USERCONTROLLER_USERUPDATESUCCESS":"Detalles del usuario actualizados exitosamente",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Acceso del usuario revocado",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":" Usuario bajo",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"no está disponible",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"está disponible",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Información de usuario",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Marque/desmarque las casillas de verificación junto a las ofertas para asignar/liberar licencias respectivamente",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Si no hay suficientes licencias gratuitas disponibles para una oferta, se realizará una compra antes de realizar la asignación.",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"indica que C3 está aprovisionando una suscripción. Espere hasta que se complete la operación.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":" Nombre para mostrar",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Correo electrónico",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Nombre de pila",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Instrucciones",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Apellido",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":" Ubicación del usuario",
      "USERS_ADD_EDIT_FORM_SEATS":"asientos",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Volver a usuarios",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Agregar",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Editar",
      "USERS_ADD_OR_EDIT_CAPTION_3":" Usuario",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Correo electrónico",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Nombre de pila",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Apellido",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"¡Se completó la última carga masiva para crear usuarios!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"No se pueden otorgar privilegios de administrador del cliente a usuarios externos",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"¿Está seguro de que desea otorgar privilegios de administrador al cliente para",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Usuarios",
      "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Análisis de incorporación",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Su solicitud para crear usuarios está en cola. Vuelva a esta página después de un tiempo para obtener actualizaciones. El proceso suele tardar unos minutos en completarse.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Se produjo un error al exportar los detalles del error.",
      "USERS_BULK_CREATION_FIX_ERRORS":"Corrija los errores informados en el archivo que acaba de descargar y vuelva a intentarlo.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Su solicitud para crear usuarios se completó con éxito",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Obtener detalles de usuarios cargados",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"Quedan {{AvailableUnits}} de {{TotalUnits}}",
      "USERS_BUY_MORE":"Comprar más",
      "USERS_CREATE_SUCCESS":"Usuario {{username}} creado exitosamente",
      "USERS_DELETE_CONFIRM":"Se eliminarán todas las licencias asignadas y se eliminará {{userName}}. ¿Quieres continuar?",
      "USERS_DELETE_EXISTENCE_CONFIRM":" Este usuario tiene algún acceso en C3, que será eliminado. ¿Quieres continuar?",
      "USERS_DELETE_SUCCESS":"La eliminación del {{username}} se realizó correctamente",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Datos no encontrados. Por favor intente mas tarde",
      "USERS_GRANT_ACCESS_CONFIRM":"¿Está seguro de que desea otorgar privilegios de administrador de clientes a {{username}}?",
      "USERS_GRANT_ACCESS_SUCCESS":"Acceso concedido a {{username}} correctamente",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"No se permite otorgar acceso a usuarios externos",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"¿Estas seguro que deseas continuar?",
      "USERS_LICENSE_FREE_OUT_OF":"licencias gratis fuera de",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Trabajando en la asignación de licencias para el usuario {{username}}. Este proceso tardaría unos minutos en completarse.",
      "USERS_LICENSES_REVOKE_SUCCESS":"Licencias revocadas de {{username}} exitosamente",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Licencias revocadas de {{username}} exitosamente. Trabajando en la asignación de nuevas licencias. Este proceso tardará unos minutos en completarse.",
      "USERS_LICENSES_UPDATE_SUCCESS":"Licencias para el usuario {{username}} actualizadas correctamente",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Añadir nuevo usuario",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Carga de varios usuarios",
      "USERS_LIST_CAPTION_TEXT_USERS":"Usuarios",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Editar detalles de usuario",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Conceder acceso al portal",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Restablecer la contraseña",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Ver licencias",
      "USERS_LIST_TABLE_HEADER_ACTION":"Comportamiento",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Identificación de correo",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Está activo",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"No hay roles de usuarios asignados.",
      "USERS_LIST_TABLE_HEADER_ROLE":"Funciones del CSP",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Nombre de usuario",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Mostrar roles",
      "USERS_PASSWORD_RESET_SUCCESS":"La contraseña de {{username}} se restableció correctamente",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"La contraseña se envió correctamente a los destinatarios especificados",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Restablecimiento de contraseña exitoso",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"El restablecimiento de la contraseña se realizó correctamente. La contraseña es: <a href='#' ngclipboard=' data-clipboard-text='{{Contraseña}}' ng-click='vm.confirmCopy()'><b>{{Contraseña }}</b></a> <br/><br/><b>Después de salir de esta página, no podrá volver a ver esta contraseña temporal.</b><br/><br/>Si Si desea enviar la contraseña por correo electrónico, proporcione los destinatarios separados por comas en el cuadro de texto a continuación; de lo contrario, presione cancelar.<br/>",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"El restablecimiento de contraseña se realizó correctamente, la contraseña es:",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Después de salir de esta página, no podrá volver a ver esta contraseña temporal.</b><br/><br/>Si desea enviar la contraseña por correo electrónico, proporcione los destinatarios separados por comas en el cuadro de texto a continuación; de lo contrario, presione cancelar.<br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"¿Está seguro de que desea revocar {{UserRoleDescription | traducir}} privilegio de {{userName}}",
      "USERS_REVOKE_ACCESS_SUCCESS":"Acceso revocado de {{username}} exitosamente",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Obtener detalles de usuarios cargados",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"¡Pruebe una nueva carga!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Ver y corregir errores",
      "USERS_STATUS_CAPTION_STATUS":"Estado",
      "USERS_STATUS_WARNING_MESSAGE":"La creación de usuarios a partir de la última carga aún está en progreso. Vuelva a consultar haciendo clic en el botón 'BulkUserUpload' en la página Usuarios.",
      "USERS_TABLE_HEADER_EMAIL":"Correo electrónico",
      "USERS_TABLE_HEADER_NAME":"Nombre",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
      "USERS_UPDATE_LICENCES_QUEUED":"Las licencias están en cola para su actualización",
      "USERS_UPDATE_SUCCESS":"Detalles de usuario para {{username}} actualizados exitosamente",
      "USERS_UPDATELICENCES_SUCCESS":"Licencias actualizadas exitosamente",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Detalles de usuario para {{UserName}} actualizados exitosamente",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Subir",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"Errores detectados en los datos subidos. Descargue los errores, corríjalos e intente cargarlos nuevamente.",
      "USERS_UPLOAD_ERROR":"Se produjo un error al analizar el archivo cargado",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Remover",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Información de usuario",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"INSTRUCCIONES",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Cargue datos de cuenta para varios usuarios en formato .csv. Descargue un archivo .csv de muestra <strong><a href='#' ng-click='vm.DownloadTemplate()'>Descargue un archivo .csv de muestra</a></strong> como referencia.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":" Este archivo se puede utilizar para crear varias cuentas de usuario para un cliente. No actualice ni elimine ningún texto o dato existente en este archivo. Las cuentas de usuario se crearán con permisos de usuario (no de administrador).",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"El nombre es un campo opcional que admite de cero a 50 caracteres. Un ejemplo: Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":" El apellido es un campo opcional que admite de cero a 50 caracteres. Un ejemplo: Verde.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":" El nombre para mostrar es un campo obligatorio que admite entre uno y 50 caracteres. Un ejemplo: Chris Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":" El correo electrónico es un campo obligatorio. Ingrese una dirección de correo electrónico válida en este campo para cada registro de usuario. Un ejemplo: chris@contoso.com.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"El campo de actualización de estado se completa automáticamente para transmitir el estado de validación. Deje este campo vacío.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Sube el archivo y haz clic en <strong class=\"text-uppercase\">enviar</strong>.",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Subir archivo",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Ubicación de uso",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Volver a licencias",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Volver a usuarios",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Editar licencias",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Licencias asignadas para",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Fecha de asignación de licencia",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Producto",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Resumen",
      "VALIDATE_LIST":"Validar lista",
      "VALIDATE_PRODUCT_QUANTITY":"La cantidad de {{product}} {{quantity}} debe estar entre {{minQuantity}} y {{maxQuantity}}",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"No se pudo validar el historial de suscripción para el servicio externo",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Historial de suscripción validado con éxito para el servicio externo",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Cliente ya vinculado a este inquilino.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Este código de cupón ya existe",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"¡Solo se permiten números alfabéticos en el código de cupón!",
      "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Estamos permitiendo descuentos solo entre 0 y 100",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Este correo electrónico '{{emailAddress}}' ya está en uso.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"por favor ingrese un número valido",
      "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Por favor, rellene todos los campos obligatorios antes de enviar.",
      "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"La fecha de la factura debe ser anterior a la fecha de vencimiento.",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Ingrese una URL válida o ingrese la URL predeterminada como '/#/contacto/'",
      "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"La fecha de inicio debe ser anterior a la fecha de finalización.",
      "VALIDATION_MESSAGE_FOR_URL":"Por favor ingrese una URL válida.",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Ingrese una URL válida o ingrese la URL predeterminada como 'home.support'",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Por favor ingresa un código postal válido",
      "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"La longitud del IIN de la empresa debe ser menor o igual a 22",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Este campo acepta un máximo de 50 caracteres.",
      "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"La longitud del IIN de la empresa debe ser superior a 4",
      "VALIDATION_MESSAGE_MINIMUM_0":"El valor mínimo es 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"El valor mínimo es 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Por favor introduzca una dirección de correo electrónico válida",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Por favor, introduzca un número de teléfono válido",
      "VALIDATION_MESSAGE_REQUIRED":"Este campo es obligatorio",
      "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Introduzca una tasa de conversión válida",
      "VALIDITY_TEXT_FROM":"De",
      "VALIDITY_TEXT_TO":"A",
      "VALIDITY_TYPE_DESC_DAYS":"Días)",
      "VALIDITY_TYPE_DESC_MONTHS":"Meses)",
      "VALIDITY_TYPE_DESC_YEARS":"Años)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Tipo de validez",
      "VALIDITY_TYPE_NAME_DAYS":"Días)",
      "VALIDITY_TYPE_NAME_MONTHS":"Meses)",
      "VALIDITY_TYPE_NAME_YEARS":"Años)",
      "VALUE_SHOULD_BE_GREATER_THAN":"El valor debe ser mayor que 0.",
      "VAT_NUMBER_DESC":"Número de IVA para el cliente",
      "VAT_NUMBER_DESC_FOR_RESELLER":"Número de IVA para revendedor",
      "VAT_NUMBER_DISP":"Número de valor agregado",
      "VAT_NUMBER_DISP_FOR_RESELLER":"Número de valor agregado",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Validar token",
      "VIEW_ALL_SELECTION":"Seleccionar todo",
      "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Ver más detalles",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Ver líneas de pedido de uso",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Ver líneas de pedido de uso",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Generar factura",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Recargar informe de rentabilidad",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Agregar usuario",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Borrar usuario",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Nombre",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Asignar etiquetas",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Editar roles",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Agregar usuario socio",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Asignar etiquetas",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Eliminar socio",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Editar roles",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Gestión de usuarios de socios",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Gestión de usuarios",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"roles",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"roles",
      "viewuploadhistory":"Informe de uso",
      "WAITING_FOR_APPROVAL":"A la espera de la aprobación",
      "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":" Has deseleccionado todos los",
      "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":" Has seleccionado todos los",
      "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Introduzca un nombre de entidad",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"Válido desde",
      "WEBHHOOK_LABEL_EFFECTIVE_TO":"Efectivo para",
      "WEBHHOOK_LABEL_ENTITY":"Entidad",
      "WEBHHOOK_LABEL_EVENT":"Evento",
      "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Entidades etiquetadas",
      "WEBHOOK_BREADCRUMB_ADD":"Crear webhook",
      "WEBHOOK_BUTTON_ACTIONS_ADD":"Crear notificación de webhook",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
      "WEBHOOK_CANCEL_NOTIFICATION":"Cancelar",
      "WEBHOOK_DESCRIPTION_TOOLTIP":"Introduzca la descripción",
      "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Elija efectivo hasta la fecha",
      "WEBHOOK_END_DATE_PLACEHOLER":"Fecha final",
      "WEBHOOK_ENTITY_TOOLTIP":"Seleccionar categoría",
      "WEBHOOK_EVENT_TOOLTIP":"Elegir evento",
      "WEBHOOK_HEADER_ACTIONS":"Comportamiento",
      "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Entidades asociadas",
      "WEBHOOK_HEADER_CREATET_DATE":"Fecha de Creación",
      "WEBHOOK_HEADER_DESCRIPTION":"Descripción",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"Válido desde",
      "WEBHOOK_HEADER_EFFECTIVE_TO":"Efectivo para",
      "WEBHOOK_HEADER_EVENTNAME":"Evento",
      "WEBHOOK_HEADER_TITLE":"Título",
      "WEBHOOK_LABEL_DESCRIPTION":"Descripción",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Efectivo para",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Categoría",
      "WEBHOOK_LABEL_EVENT_TEXT":"Evento",
      "WEBHOOK_LABEL_TITLE_TEXT":"Título",
      "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"método de webhook",
      "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Recuento de reintentos",
      "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"URL de webhook",
      "WEBHOOK_METHOD_METHOD_TOOLTIP":" Método a utilizar al enviar datos a un sistema externo a través de un webhook.",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
      "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Se produjo un error al enviar la notificación del webhook",
      "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Notificación de webhook enviada correctamente",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Descripción",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"IDENTIFICACIÓN",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Antiguo recuento de reintentos",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"URL del webhook antiguo",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Nombre del producto",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Recuento de reintentos",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Título",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"URL de webhook",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"método de webhook",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Sólo se puede seleccionar un producto.",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Error al seleccionar producto",
      "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación de webhook creada correctamente",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Todos los productos han sido seleccionados.",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"El producto ha sido seleccionado.",
      "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Elementos de entidad asociados",
      "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación de webhook actualizada correctamente",
      "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Todos los productos han sido seleccionados.",
      "WEBHOOK_SAVE_NOTIFICATION":"Entregar",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"Seleccione",
      "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Seleccionar elemento de entidad asociado",
      "WEBHOOK_START_DATE_PLACEHOLER":"Fecha de inicio",
      "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Notificación de webhook",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Esto excede la longitud máxima de 30",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Asegúrese de que el valor esté entre 0 y 3",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
      "WEBHOOK_TITLE_TOOLTIP":"Ingrese el título",
      "WEBHOOK_URL_TOOLTIP":" Ingrese la URL de destino donde el sistema enviará solicitudes HTTP para notificar a un servicio externo sobre eventos o actualizaciones específicas. Esta URL debe ser capaz de recibir y procesar cargas útiles de webhook.",
      "WEBHOOK_VALIDATE_BUTTON":"Validar",
      "WEBHOOK_VALIDATE_URL":"Por favor valide la URL",
      "WEBHOOK_VALIDATE_URL_BUTTON":"Validar URL",
      "WEBHOOK_VALIDATION_ERROR_MESSAGE":"URL de webhook no válida",
      "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"La URL del webhook es válida",
      "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Número de intentos que hará el sistema para realizar una acción específica en caso de falla.",
      "WEBHOOK_WEBHOOK_URL_TOOLTIP":"URL de webhook",
      "webhookNotifications":"gancho web",
      "WELCOME_HEADER_1_PART_1":"Con nosotros tienes infinitas posibilidades.",
      "WELCOME_HEADER_1_PART_2":"Y comienza ahora.",
      "WELCOME_PORTAL_FEATUR_1":"Portal de autoservicio",
      "WELCOME_PORTAL_FEATUR_2":"Agregar/eliminar suscripciones de forma rápida y sencilla",
      "WELCOME_PORTAL_FEATUR_3":"Pagos automatizados",
      "WELCOME_PORTAL_FEATUR_4":"Pagar facturas automáticamente",
      "WELCOME_PORTAL_FEATUR_5":"Seguro",
      "WELCOME_PORTAL_FEATUR_6":"Protegido con Microsoft Azure Active Directory y autenticación multifactor",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Acceso",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Bienvenido",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Cerrar sesión",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Contáctenos",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Ir al panel de control",
      "Won''t Fix":"No se arreglará",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Gracias por brindar su consentimiento a Xero. La aplicación se puede configurar para acceder a Xero siguiendo la nueva pauta de seguridad. Puede publicar las facturas de sus clientes en Xero.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones para cargar facturas en Xero en <b>{{applicationName}}</b>.",
      "XERO_CONSENT_DESCRIPTION":"Para publicar facturas en Xero, debe proporcionar su consentimiento.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Espere mientras procesamos su consentimiento de Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"El siguiente enlace se abre en una nueva pestaña. Proporcione su consentimiento con sus credenciales de Xero. Si recibe un error al brindar su consentimiento, comuníquese con nuestro equipo de soporte.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":" para proporcionar el consentimiento de Xero",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Si ya ha proporcionado su consentimiento, haga clic en el botón <b> Validar token </b> para verificar la conectividad con Xero.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en este botón para verificar la validez del Xero Refresh Token existente.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Requisitos previos para que el socio publique facturas de clientes en <b> XERO </b>",
      "XERO_POST_BATCH_ID":"ID de lote de publicación de Xero",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Su solicitud para publicar facturas en Xero se acepta y se pone en cola para su procesamiento. Haga clic en el botón <strong>Ver registros de publicaciones</strong> para obtener actualizaciones sobre el progreso.",
      "XERO_POST_LOGS_STATUS":"Estado de registros de publicaciones de Xero",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"El token de actualización de Xero existente no es válido. Por favor proporcione su consentimiento nuevamente",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"El token de actualización de Xero existente es válido. Puedes continuar subiendo Facturas",
      "XERO_TENANT_ID":"ID de inquilino de Xero",
      "Year(s)":"Años)",
      "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Puede especificar el porcentaje de impuestos para el cliente. Por ejemplo: 15 por 15%",
      "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Puede especificar el nombre del impuesto como GST..",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"No tienes permiso para realizar esta acción",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Debes seleccionar una o más ofertas para crear una suscripción."
   }, 
    "academic":"Académico",
    "Academic":"Académico",
    "ACCEPT_BUTTON_INFO":"Al hacer clic en el botón Aceptar, acepta los términos de uso anteriores.",
    "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
    "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Aceptar o rechazar el consentimiento de renovación de la oferta del socio",
    "ACCEPT_THE_CONSENT_BTN_TEXT":"Acceda al centro de socios utilizando la nueva configuración de seguridad.",
    "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Acceso a las API de administración de Microsoft Azure.",
    "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Acceso a la API de precios de Microsoft.",
    "ACCEPTANCE_STATUS":"Estado de aceptación",
    "ACCESS_TOKEN":"Token de acceso",
    "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"El administrador de cuentas se creó con éxito",
    "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Administrador de cuentas eliminado con éxito",
    "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"¿Está seguro, desea eliminar el administrador de la cuenta?",
    "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"El administrador de cuentas está asignado al cliente/revendedor. ¿Está seguro? ¿Desea eliminar el administrador de cuentas?",
    "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Buscar por ID de administrador de cuenta",
    "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Buscar por dirección de correo electrónico",
    "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Buscar por nombre",
    "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Buscar por apellido",
    "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Buscar por número de teléfono",
    "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Identificación del administrador de cuenta",
    "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Cerrar filtros",
    "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Nombre de pila",
    "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Apellido",
    "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Número de teléfono",
    "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Reiniciar",
    "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Búsqueda",
    "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Los detalles del administrador de cuentas se actualizaron correctamente",
    "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar administrador de cuenta",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar administrador de cuenta",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Identificación del administrador de la cuenta",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Correo electrónico",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Nombre de pila",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Apellido",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Número de teléfono",
    "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Agregar administrador de cuenta",
    "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Gerente de Cuentas",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"IDENTIFICACIÓN",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
    "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Nombre de pila",
    "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Apellido",
    "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Número de teléfono",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Eliminar administrador de cuenta",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Editar detalles",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Ver clientes asignados",
    "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Número de cuenta",
    "ACH_FORM_LABEL_ACCOUNT_TYPE":"Tipo de cuenta",
    "ACH_FORM_LABEL_BANK_NAME":"Nombre del banco",
    "ACH_FORM_LABEL_BSB":"BSB",
    "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"es cuenta comercial",
    "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Nombre en la cuenta",
    "ACH_FORM_LABEL_NO":"No",
    "ACH_FORM_LABEL_ROUTING_NUMBER":"Número de ruta",
    "ACH_FORM_LABEL_YES":" Sí",
    "ACTIONS_TO_FIX":"Por favor contacte al administrador para soporte",
    "ACTIONS_TO_FIX_500":"Estamos trabajando para solucionar el problema. Vuelve a consultar en un momento.",
    "ACTIVATE_CONFORMATION":"¿Estás seguro de que quieres activar?",
    "ACTIVE_CUSTOMER_PLACEHOLDER":"Seleccionar cliente",
    "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Todas las acciones--",
    "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Mostrando registros de",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"todos los clientes",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Todos los dominios.",
    "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Notificaciones",
    "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Rever",
    "ACTIVITY_LOGS_EFFECTIVE_TO":"Fecha final",
    "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Comportamiento",
    "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Metodo de contacto",
    "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Nombre del evento",
    "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Fecha enviada",
    "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Estado",
    "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Tema",
    "ACTIVITY_LOGS_HEADER_TITLE_TO":"A",
    "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Evento",
    "ACTIVITY_LOGS_TEXT_BCC":"CCO",
    "ACTIVITY_LOGS_TEXT_CC":"CC",
    "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Fecha de inicio",
    "ACTIVITY_LOGS_TEXT_FAILED":"Ha fallado",
    "ACTIVITY_LOGS_TEXT_FROM":"De",
    "ACTIVITY_LOGS_TEXT_ON":"En",
    "ACTIVITY_LOGS_TEXT_STATUS":"Estado",
    "ACTIVITY_LOGS_TEXT_SUCCESS":"Éxito",
    "ACTIVITY_LOGS_TEXT_TO":"A",
    "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"Ver correo electrónico",
    "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Notificaciones",
    "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Agregar ajuste",
    "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"El nombre de dominio no está disponible",
    "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"El nombre de dominio no está disponible",
    "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Añadir ofertas de socios",
    "ADD_CUSTOM_ROLE":"agregar rol personalizado",
    "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"El cliente ha aceptado la última",
    "ADD_CUSTOMER_IN_QUOTE":"Agregar cliente",
    "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"País",
    "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Por favor introduzca un codigo postal.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Por favor ingrese la dirección 1.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":" Ingrese la dirección 2.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Por favor ingrese la ciudad.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Por favor, introduzca el nombre de la empresa.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Por favor, introduzca la dirección de correo electrónico.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Por favor ingrese el primer nombre.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Por favor ingrese el apellido.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Por favor, introduzca el número de teléfono.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Por favor seleccione un país.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Seleccione un código de moneda.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Seleccione un plan.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Seleccione un estado.",
    "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Agregar clientes",
    "ADD_DUE_DATE":"Añadir fecha de vencimiento",
    "ADD_ENTITLEMENT":"Agregar derecho",
    "ADD_ENTITLEMENT_DESCRIPTION":"Proporcione un nombre descriptivo para la suscripción.",
    "ADD_ENTITLEMENT_FAILED":"No se pudo agregar el derecho",
    "ADD_ENTITLEMENT_SUCCESS":"El derecho se agregó con éxito en Parter Ceneter, espere 15 minutos para el aprovisionamiento en C3",
    "ADD_MACRO_VALUE_ERROR":"Se requiere valor macro",
    "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Crear un nuevo nivel",
    "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!valor máximo) ? \"Ingrese un valor mayor que \" + minValue : \"Ingrese un valor entre \" + minValue + \" y \" + maxValue}}",
    "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Introduzca un valor entre {{Min}} y {{Max}}",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Cancelar",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"Entregar",
    "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Completo",
    "ADD_MISSING_OFFERS_IN_PROGRESS":"El plan de actualización con las últimas ofertas ya está en marcha.",
    "ADD_MISSING_OFFERS_INITIAT_FAIL":"Error al actualizar el plan con el inicio de las últimas ofertas",
    "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Se ha iniciado el plan de actualización con las últimas ofertas.",
    "ADD_MISSING_OFFERS_STATUS":"Progreso de la actualización del plan",
    "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Agregar ofertas faltantes al plan existente",
    "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Agregar ofertas faltantes a varios planes",
    "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Agregar ofertas faltantes al plan único",
    "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"¿Otorgar acceso al contacto principal (como administrador del cliente)?",
    "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"¿Enviar invitación para acceder a la aplicación?",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
    "ADD_NEW_QUOTE":"Crear cotización",
    "ADD_NEW_USER_IN_QUOTE":"Añadir nuevo usuario",
    "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Seleccione",
    "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Agregar detalles básicos del plan",
    "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
    "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Descripción",
    "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Moneda de la factura",
    "ADD_PLAN_LABEL_TEXT_NAME":"Nombre",
    "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Compra de moneda",
    "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Ver detalles del contrato",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Comportamiento",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Macro",
    "ADD_PO_NUMBER":"Agregar número de orden de compra",
    "ADD_PONUMBER":"Agregar número de orden de compra",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Se recomienda que utilice la opción <b>Proveedor de enlace</b> para proporcionar el ID de MPN del revendedor y ayudar al revendedor a incorporar clientes eventualmente.",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"¡Recomendado! Establecer ID de MPN después de agregar el revendedor",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
    "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Rompedor de losas",
    "ADD_TO_CART_INFO":" Este producto está vinculado con <b>{{linkedProductName}}</b> si agrega este producto, el enlace también agregará",
    "addCustomer":"Agregar cliente",
    "ADDONS_LEBEL_TEXT":"Complementos",
    "ADDONS_PRODUCT_LEBEL_TEXT":"Producto adicional",
    "ADDRESS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Dirección Línea 1",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Línea de dirección 2",
    "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Tipo de dirección",
    "ADDRESS_TABLE_HEADER_CITY":"Ciudad",
    "ADDRESS_TABLE_HEADER_COUNTRY":"País",
    "ADDRESS_TABLE_HEADER_STATE":"Estado",
    "ADDRESS_TABLE_HEADER_ZIP":"Cremallera",
    "ADDRESS_TYPE_BILLING":"Facturación",
    "ADDRESS_TYPE_COMPANY":"Compañía",
    "ADDRESS_TYPE_INVOICE":"Factura",
    "ADDRESS_TYPE_SHIPPING":"Envío",
    "AGO":"Atrás",
    "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"Copiado al portapapeles",
    "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"La factura se aprueba correctamente.",
    "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"La factura se finaliza con éxito.",
    "ALERT_MESSAGE_MISSING_MPNID":"Se produjo un problema al copiar la URL. Conéctese con el socio.",
    "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Por favor seleccione al menos una factura",
    "ALL_CART_ITEM_COMMENT":"Artículo del carrito",
    "ALL_COMMENTS":"Comentarios",
    "ALL_PRODUCT_COMMENTS":"Producto",
    "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Comuníquese con su administrador para eliminar las licencias.",
    "AN_ERROR_OCCURED":"Se produjo un error al procesar su solicitud.",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Historial de facturación de Azure",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Resumen de facturación de Azure",
    "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Uso de Azure por parte del cliente",
    "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Ingresos por clientes",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Suscripciones de Clientes",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Suscripciones por Planes",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"Historial de suscripciones",
    "AND":"y",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentimiento de la aplicación de precios de Azure AD",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"Id. de aplicación de precios de Azure AD",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de recurso de la aplicación de precios de Azure AD",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Clave secreta de la aplicación de precios de Azure AD",
    "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Ingrese el color hexadecimal del botón de la página de bienvenida",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"URL de consentimiento de la aplicación de precios de Azure AD",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"Id. de aplicación de precios de Azure AD",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"URL de recurso de la aplicación de precios de Azure AD",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Clave secreta de la aplicación de precios de Azure AD",
    "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Color del botón de la página de bienvenida",
    "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Banner de registro público",
    "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Logotipo de registro público",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Permitir carga de uso",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Cuando está habilitada, esta configuración impulsará los costos de uso mensuales incurridos para los productos que tienen consumo de uso. Los tipos de productos incluirán Azure-Plan y Custom-Usage.",
    "APPCONFIG_AUTOTASK_BILLING_CODE":"Código de facturación",
    "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Este código de facturación se utilizará para crear productos en Autotask",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Es Mapeo Automático (Autotask)",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Si desea mapear manualmente entre las entidades C3 y Autotask, deje esto sin marcar. Sin embargo, si la asignación automática está habilitada, las entidades se comparan comparando los nombres de las entidades en C3 con las entidades en Autotask.",
    "APPCONFIG_AUTOTASK_PASSWORD":"Clave",
    "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Contraseña que pertenece al usuario de la API",
    "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Prorrateo",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Frecuencia de carga automática",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Establecer la frecuencia de carga automática",
    "APPCONFIG_AUTOTASK_USERNAME":"Nombre de usuario",
    "APPCONFIG_AUTOTASK_USERNAME_DESC":"Nombre de usuario del usuario de API creado en Autotask",
    "APPCONFIG_DESC_ACCOUNT_NAME":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_ACCOUNT_NUMBER":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_ADDRESS_LINE1":"Dirección 1 que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_ADDRESS_LINE2":"Dirección 2 que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_ADVANCE_TEXT":"Texto personalizado de línea avanzada",
    "APPCONFIG_DESC_API_URL":"Url API base para ConnectWise Manage",
    "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Habilitar seguimiento de licencias",
    "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Mostrar análisis",
    "APPCONFIG_DESC_APPLICATION_NAME":"Nombre de la aplicación",
    "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Fin del ciclo de facturación para el socio en el Centro de socios",
    "APPCONFIG_DESC_AZURE_CSPOFFERID":"ID de oferta de CSP de Azure",
    "APPCONFIG_DESC_BANK_NAME":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_BILLING_CYCLE":"Alineado con el Centro de socios o personalizado",
    "APPCONFIG_DESC_BILLING_DATE":"Aplicable cuando el ciclo de facturación es personalizado",
    "APPCONFIG_DESC_BILLING_PERIOD":"Período de facturación (determinado mediante otras configuraciones)",
    "APPCONFIG_DESC_BILLING_PROVIDER":"Proveedor de facturación para el Portal C3",
    "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Configuración del proveedor de facturación",
    "APPCONFIG_DESC_BILLING_STARTDAY":"Inicio del ciclo de facturación. Aplicable al ciclo de facturación es personalizado",
    "APPCONFIG_DESC_BUSINESS_NUMBER":"Aparece en la vista de factura y en el pdf de factura en la sección factura de",
    "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Aparece en la vista de factura y en el pdf de factura en la sección factura de",
    "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Verifique los roles con 0365",
    "APPCONFIG_DESC_CIN_NUMBER":"CIN (Número de Identificación Corporativo) Que aparece en el pdf de la factura",
    "APPCONFIG_DESC_CITY":"Ciudad que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_CLIENT_ID":"ID de cliente para ConnectWise Manage",
    "APPCONFIG_DESC_COMPANY_NAME":"Nombre de la empresa que aparece en la vista de factura y en el pdf de la factura.",
    "APPCONFIG_DESC_COMPANY_URL":"URL de tu sitio web",
    "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Nombre de la empresa para ConnectWise Manage",
    "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Considere la fecha de generación en el informe de facturación",
    "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Nombre que debe aparecer en la página de destino y en el pie de página después de que un usuario inicia sesión",
    "APPCONFIG_DESC_CONTACT_EMAIL":"Correo electrónico de contacto (normalmente su información o correo electrónico de soporte)",
    "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Nombre de la persona de contacto para su solicitud C3",
    "APPCONFIG_DESC_CONTACT_LAST_NAME":"Apellido de la persona de contacto para su solicitud C3",
    "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Número de teléfono de la persona de contacto",
    "APPCONFIG_DESC_COUNTRY":"País que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_COUNTRY_CODE":"Código de país",
    "APPCONFIG_DESC_CURRENCY_CODE":"Código de moneda",
    "APPCONFIG_DESC_CUSTOM_BILLING":"Aplicar facturación personalizada",
    "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL de consentimiento del cliente",
    "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Restringir al cliente durante la incorporación bajo múltiples entidades",
    "APPCONFIG_DESC_DATE_FORMAT":"Formato de fecha",
    "APPCONFIG_DESC_DATE_TIME_FORMAT":"Formato de fecha y hora",
    "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Úselo para establecer un formato de fecha para el valor del tipo de fecha en las API C3",
    "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"URL de destino para la página, si desea tener una página de destino personalizada. Nota: En la página de destino, debe tener un botón y la URL de destino de ese botón debe ser <C3Site URL>/#/login",
    "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Idioma predeterminado para el sitio web",
    "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Establece el recuento de páginas predeterminado para cada tabla del sistema.",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Términos y condiciones para pedir productos",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"Términos y condiciones URL (texto)",
    "APPCONFIG_DESC_EMAIL_LOGO":"Logotipo que aparece en los correos electrónicos enviados por C3. La dimensión preferida es 300x100.",
    "APPCONFIG_DESC_ENABLE_TOUR":"Habilite el botón 'Hacer un recorrido' en el encabezado del sitio.",
    "APPCONFIG_DESC_FAVICON_LOGO":"Un favicon es un pequeño icono visible en la pestaña del navegador web, justo antes del título de la página. Cargue un archivo .ico de 16x16",
    "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"¿Debería considerar la identificación de MPN de revendedores para clientes y compras?",
    "APPCONFIG_DESC_IFSC_CODE":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Aparece en la vista de factura y en el pdf de la factura como línea autorizada al pie de página",
    "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
    "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Días de generación de facturas",
    "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cadena de conexión de almacenamiento en la nube",
    "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Factura zip días válidos",
    "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Cuando esta función está activada, el socio recibirá las solicitudes aprobadas por el revendedor para su aprobación.",
    "APPCONFIG_DESC_LINK_TO_CONTACT":"Enlace a la página Contáctenos (simplemente escriba /#/contact si no tiene uno)",
    "APPCONFIG_DESC_LINK_TO_FAQ":"Enlace a preguntas frecuentes del cliente (simplemente déjelo vacío si desea utilizar el predeterminado)",
    "APPCONFIG_DESC_LINK_TO_PORTAL":"Enlace al Portal",
    "APPCONFIG_DESC_LINK_TO_SUPPORT":"Enlace a la página de soporte (simplemente escriba home.support si no tiene uno)",
    "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Enlace al logotipo de bienvenida",
    "APPCONFIG_DESC_LOGGEDIN_LOGO":"Logotipo que aparece en la parte superior izquierda después de que los usuarios inician sesión en C3. La dimensión preferida es 170x40. La página tiene un fondo negro, por lo que el gráfico debe ser de un color que contraste con el negro.",
    "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Iniciar sesión en el portal",
    "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"ID de cliente obligatorio en llamadas de API",
    "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Número máximo de entradas para exportar a Excel",
    "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Recuento máximo de creación de derechos",
    "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Recuento máximo de creación de inquilinos",
    "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Recordatorio de consentimiento de seguridad de Microsoft en días",
    "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Monto mínimo de cargo para pagos de prueba",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"Términos y condiciones de NCE para pedir productos de Microsoft NCE",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL de términos y condiciones de NCE (texto)",
    "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Habilita la funcionalidad de buscar clientes ya presentes en Facturación.",
    "APPCONFIG_DESC_PAN_NUMBER":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Id. de aplicación de una aplicación nativa en su centro asociado. Puede obtener esto a través de Administración de aplicaciones -> Aplicación nativa",
    "APPCONFIG_DESC_PARTNER_CURRENCY":"Moneda del socio",
    "APPCONFIG_DESC_PARTNER_PASSWORD":"Contraseña del agente administrador",
    "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Idiomas de preferencia de socios",
    "APPCONFIG_DESC_PARTNER_TENANT_ID":"ID de inquilino socio",
    "APPCONFIG_DESC_PARTNER_USERNAME":"Nombre de usuario de la cuenta que tiene asignado el rol de administrador del agente",
    "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL del acuerdo de revendedor de la nube de Microsoft",
    "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL de términos y condiciones para socios",
    "APPCONFIG_DESC_PBI_GROUP_ID":"ID de grupo de Power BI",
    "APPCONFIG_DESC_PBI_PASSWORD":"Contraseña de Power BI",
    "APPCONFIG_DESC_PBI_REPORT_ID":"Identificador de informe de Power BI",
    "APPCONFIG_DESC_PBI_USER_NAME":"Nombre de usuario de Power BI",
    "APPCONFIG_DESC_PBIC_LIENT_ID":"ID de cliente de Power BI",
    "APPCONFIG_DESC_PDF_LOGO":"Logotipo que debe aparecer en el pdf de su aplicación C3. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o el gráfico deben estar en cualquier color que contraste con el blanco.",
    "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logotipo que debería aparecer en el pdf del lado derecho. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o el gráfico deben estar en cualquier color que contraste con el blanco.",
    "APPCONFIG_DESC_POSTAL_CODE":"Código postal que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Nombre que debe aparecer en la página de destino y en el pie de página después de que un usuario inicia sesión",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"URL de tu sitio web",
    "APPCONFIG_DESC_PRIVATE_KEY":"Clave privada para ConnectWise Manage",
    "APPCONFIG_DESC_PUBLIC_KEY":"Clave pública para ConnectWise Manage",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"ID de cliente de la aplicación QuickBooks",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"Secreto del cliente de la aplicación QuickBooks",
    "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Discrepancia de reconocimiento en los destinatarios de correo electrónico de generación de facturas por adelantado",
    "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logotipo que aparece en la vista del informe de conciliación, al lado derecho del logotipo de Microsoft. La dimensión preferida es 170x40. La página tiene un fondo blanco, por lo que el texto o gráfico debe ser de cualquier color que contraste con el blanco.",
    "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Agregue los destinatarios como valores separados por comas",
    "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Debe notificar al cliente",
    "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Suspender la suscripción cuando se alcance el umbral de peligro",
    "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Mostrar menú de informe de Power BI",
    "APPCONFIG_DESC_SHOW_FOOTER":"Al habilitar esta configuración, se mostrará el pie de página en todas las páginas.",
    "APPCONFIG_DESC_SHOW_NEXT_STEP":"Mostrar siguiente paso",
    "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Habilite esta opción para mostrar los términos y condiciones para la administración de suscripciones.",
    "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP habilita SSL",
    "APPCONFIG_DESC_SMTP_FROM_MAIL":"Correo electrónico desde el que el usuario recibirá notificaciones",
    "APPCONFIG_DESC_SMTP_FROM_NAME":"Nombre para mostrar del remitente que aparecería en el correo electrónico",
    "APPCONFIG_DESC_SMTP_HOST":"Dirección del servidor de correo electrónico SMTP",
    "APPCONFIG_DESC_SMTP_PASSWORD":"Clave",
    "APPCONFIG_DESC_SMTP_PORT":"número de puerto SMTP",
    "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"Texto que debe ir precedido del asunto de la notificación por correo electrónico",
    "APPCONFIG_DESC_SMTP_USERNAME":"Nombre de usuario SMTP",
    "APPCONFIG_DESC_STATE":"Estado que aparece en la vista de factura y en pdf de factura.",
    "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"EtiquetaMPNIDParaclientesyrevendedores",
    "APPCONFIG_DESC_TAX_TYPE_KEY":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_TAX_TYPE_VALUE":"Aparece en el pdf de la factura",
    "APPCONFIG_DESC_WELCOME_LOGO":"Logotipo que debería aparecer en la página de inicio de su aplicación C3. La dimensión preferida es 186x32. La página tiene un fondo blanco, por lo que el texto o gráfico debe ser de cualquier color que contraste con el blanco.",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Código de cuenta de la aplicación Xero para elementos de línea de ajuste",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Código de cuenta de la aplicación Xero para Azure",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Código de cuenta de la aplicación Xero para ofertas personalizadas",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Código de cuenta de la aplicación Xero para licencias de Microsoft",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Código de cuenta de la aplicación Xero para elementos de línea únicos",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Código de cuenta de la aplicación Xero para elementos de línea de instancias reservadas",
    "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Nombre del tema de marca de la aplicación Xero",
    "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"ID de cliente de la aplicación Xero",
    "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Secreto del cliente de la aplicación Xero",
    "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"El valor de puntos decimales al que se redondean los importes contabilizados en Xero",
    "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Descripción adicional y formato de descripción de factura",
    "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Cultura de fechas para ConnectWise Administrar fechas",
    "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Considere los precios de ConnectWise Manage en lugar de los precios C3",
    "APPCONFIG_DISC_MAPPING_TYPE":"Seleccione el tipo de mapeo para mapear las entidades entre C3 y el servicio externo",
    "APPCONFIG_DISP_ACCOUNT_NAME":"Nombre de la cuenta",
    "APPCONFIG_DISP_ACCOUNT_NUMBER":"Número de cuenta",
    "APPCONFIG_DISP_ADDRESS_LINE1":"Dirección 1",
    "APPCONFIG_DISP_ADDRESS_LINE2":"Dirección 2",
    "APPCONFIG_DISP_ADVANCE_TEXT":"Texto personalizado de línea avanzada",
    "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Texto de avance de visualización de Appconfig para anual",
    "APPCONFIG_DISP_API_URL":"URL de API",
    "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Habilitar seguimiento de licencias",
    "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Mostrar análisis",
    "APPCONFIG_DISP_APPLICATION_NAME":"Nombre de la aplicación",
    "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Fecha de generación de la factura de Microsoft",
    "APPCONFIG_DISP_AZURE_CSPOFFERID":"ID de oferta de CSP de Azure",
    "APPCONFIG_DISP_BANK_NAME":"Nombre del banco",
    "APPCONFIG_DISP_BILLING_CYCLE":"Ciclo de facturación",
    "APPCONFIG_DISP_BILLING_DATE":"Fecha en que se generan las facturas en C3",
    "APPCONFIG_DISP_BILLING_PERIOD":"Período de facturación",
    "APPCONFIG_DISP_BILLING_PROVIDER":"Proveedor de facturación",
    "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Configuración del proveedor de facturación",
    "APPCONFIG_DISP_BILLING_STARTDAY":"Día de inicio de facturación",
    "APPCONFIG_DISP_BUSINESS_NUMBER":"Numero de negocios",
    "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Clave del número comercial",
    "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Verifique los roles con 0365",
    "APPCONFIG_DISP_CIN_NUMBER":"número NIC",
    "APPCONFIG_DISP_CITY":"Ciudad",
    "APPCONFIG_DISP_CLIENT_ID":"Identificación del cliente",
    "APPCONFIG_DISP_COMPANY_NAME":"Nombre de empresa",
    "APPCONFIG_DISP_COMPANY_URL":"URL de la compañía",
    "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Descripción adicional",
    "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"nombre de empresa",
    "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Fecha Cultura",
    "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Considere ConnectWise Administrar Precios",
    "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Considere la fecha de generación en el informe de facturación",
    "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Nombre de la empresa de contacto",
    "APPCONFIG_DISP_CONTACT_EMAIL":"Email de contacto",
    "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Nombre de contacto",
    "APPCONFIG_DISP_CONTACT_LAST_NAME":"Apellido de contacto",
    "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Teléfono de contacto",
    "APPCONFIG_DISP_COUNTRY":"País",
    "APPCONFIG_DISP_COUNTRY_CODE":"Código de país",
    "APPCONFIG_DISP_CURRENCY_CODE":"Código de moneda",
    "APPCONFIG_DISP_CUSTOM_BILLING":"Aplicar facturación personalizada",
    "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL de consentimiento del cliente",
    "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Restringir al cliente durante la incorporación bajo múltiples entidades",
    "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Formato de fecha y hora para las API C3",
    "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Días para enviar la notificación de estado del carrito",
    "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Número de días para almacenar el archivo de factura masiva en el almacenamiento en la nube",
    "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"URL de página de destino personalizada",
    "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Idioma predeterminado",
    "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Recuento de páginas predeterminado",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"Términos y condiciones URL (texto)",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL de términos y condiciones",
    "APPCONFIG_DISP_EMAIL_LOGO":"Logotipo de correo electrónico",
    "APPCONFIG_DISP_ENABLE_TOUR":"Habilitar recorrido",
    "APPCONFIG_DISP_FAVICON_LOGO":"logotipo de favicon",
    "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"¿Debería considerar la identificación de MPN de revendedores para clientes y compras?",
    "APPCONFIG_DISP_IFSC_CODE":"Código IFSC",
    "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Línea autorizada del pie de página de la factura",
    "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Línea de pie de página de factura",
    "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Días de generación de facturas",
    "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cadena de conexión de almacenamiento en la nube",
    "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Factura zip días válidos",
    "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Se requiere aprobación del socio",
    "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Catálogo público respaldado por Azure Search",
    "APPCONFIG_DISP_LINK_TO_CONTACT":"Enlace a la página Contáctenos",
    "APPCONFIG_DISP_LINK_TO_FAQ":"Enlace a las preguntas frecuentes del cliente",
    "APPCONFIG_DISP_LINK_TO_PORTAL":"Enlace al Portal",
    "APPCONFIG_DISP_LINK_TO_SUPPORT":"Enlace al soporte",
    "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Enlace al logotipo de bienvenida",
    "APPCONFIG_DISP_LOGGEDIN_LOGO":"Logotipo de sesión iniciada",
    "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Iniciar sesión en el portal",
    "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":" ID de cliente obligatorio en llamadas de API",
    "APPCONFIG_DISP_MAPPING_TYPE":"Tipo de mapeo",
    "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Entradas máximas para exportación",
    "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Recuento máximo de creación de derechos",
    "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Recuento máximo de creación de inquilinos",
    "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Recordatorio de consentimiento de seguridad de Microsoft en días",
    "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Monto mínimo de cargo",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL de términos y condiciones de NCE (texto)",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL de términos y condiciones de NCE",
    "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Búsqueda de clientes integrada con facturación",
    "APPCONFIG_DISP_PAN_NUMBER":"Número de bandeja",
    "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"tecla numérica PAN",
    "APPCONFIG_DISP_PARTNER_APPLICATIONID":"ID de aplicación nativa",
    "APPCONFIG_DISP_PARTNER_CURRENCY":"Moneda del socio",
    "APPCONFIG_DISP_PARTNER_PASSWORD":"Contraseña del agente administrador",
    "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Paquetes de idiomas",
    "APPCONFIG_DISP_PARTNER_TENANT_ID":"ID de inquilino socio",
    "APPCONFIG_DISP_PARTNER_USERNAME":"Nombre de usuario del agente administrador",
    "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL del acuerdo de revendedor de Microsoft Cloud",
    "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL de términos y condiciones para socios",
    "APPCONFIG_DISP_PBI_GROUP_ID":"ID de grupo de Power BI",
    "APPCONFIG_DISP_PBI_PASSWORD":"Contraseña de Power BI",
    "APPCONFIG_DISP_PBI_REPORT_ID":"Identificador de informe de Power BI",
    "APPCONFIG_DISP_PBI_USER_NAME":"Nombre de usuario de Power BI",
    "APPCONFIG_DISP_PBIC_LIENT_ID":"ID de cliente de Power BI",
    "APPCONFIG_DISP_PDF_LOGO":"Logotipo PDF",
    "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"Logotipo del lado derecho del PDF",
    "APPCONFIG_DISP_POSTAL_CODE":"Cremallera",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Desarrollado por el nombre de la empresa",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Desarrollado por la URL de la empresa",
    "APPCONFIG_DISP_PRIVATE_KEY":"Llave privada",
    "APPCONFIG_DISP_PUBLIC_KEY":"Llave pública",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"ID de cliente de QuickBooks",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"Secreto del cliente de QuickBooks",
    "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Discrepancia de reconocimiento en los destinatarios de correo electrónico de generación de facturas por adelantado",
    "APPCONFIG_DISP_RECON_REPORT_LOGO":"Logotipo del informe de conciliación",
    "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Informe de conciliación Notificar al destinatario",
    "APPCONFIG_DISP_REFUND_TEXT":"Texto de reembolso de visualización de Appconfig",
    "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Texto de reembolso de Appconfig disp para anual",
    "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Suspender la suscripción cuando se alcance el umbral de peligro",
    "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Mostrar menú de informe de Power BI",
    "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Habilitar para obtener el número de factura externa en el informe de resumen de factura",
    "APPCONFIG_DISP_SHOW_FOOTER":"Mostrar pie de página",
    "APPCONFIG_DISP_SHOW_NEXT_STEP":"Mostrar siguiente paso",
    "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Habilitar términos y condiciones para administrar suscripciones",
    "APPCONFIG_DISP_SMTP_ENABLESSL":"Habilitar SSL",
    "APPCONFIG_DISP_SMTP_FROM_MAIL":"Desde el e-mail",
    "APPCONFIG_DISP_SMTP_FROM_NAME":"Del nombre para mostrar",
    "APPCONFIG_DISP_SMTP_HOST":"Anfitrión",
    "APPCONFIG_DISP_SMTP_PASSWORD":"contraseña SMTP",
    "APPCONFIG_DISP_SMTP_PORT":"Puerto SMTP",
    "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Prefijo de asunto",
    "APPCONFIG_DISP_SMTP_USERNAME":"Nombre de usuario",
    "APPCONFIG_DISP_STATE":"Estado",
    "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"EtiquetaMPNIDParaclientesyrevendedores",
    "APPCONFIG_DISP_TAX_TYPE_KEY":"Tipo de impuesto",
    "APPCONFIG_DISP_TAX_TYPE_VALUE":"valor fiscal",
    "APPCONFIG_DISP_WELCOME_LOGO":"Logotipo de bienvenida",
    "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Pagina de bienvenida",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Código de cuenta Xero para ajustes",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"Código de cuenta de la aplicación Xero para Azure",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Código de cuenta de la aplicación Xero para Custom",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Código de cuenta de la aplicación Xero para MSLicense",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Código de cuenta de Xero para OneTime",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Código de cuenta Xero para instancias reservadas",
    "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"Nombre del tema de marca de la aplicación Xero",
    "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"ID de cliente de Xero",
    "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"secreto de cliente Xero",
    "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xero puntos decimales",
    "APPCONFIG_SELECTED_SMTP_OPTION":"Seleccione el tipo SMTP",
    "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Seleccione la opción SMTP",
    "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Mostrar el número de factura externa en el informe de resumen de facturas",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"ID de aplicación de autenticación moderna",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"ID de cliente de la aplicación Azure AD",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Contraseña de la aplicación de autenticación moderna",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Secreto de cliente de la aplicación Azure AD",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Autenticación moderna desde el correo electrónico",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"Correo electrónico desde el que el usuario recibirá notificaciones",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"ID de inquilino de autenticación moderna",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"ID de inquilino de la cuenta de usuario que se utilizará para enviar correos electrónicos",
    "APPEND_CUSTOM_TRIAL_DAYS":"(Periodo de prueba)",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":" El límite restante después de aprobar esta solicitud será",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" El límite restante actual se actualizará después de una renovación exitosa.",
    "approvals":"Aprobaciones",
    "APPROVED_AND_INITIATED":"Aprobado e iniciado",
    "APPROVED_BY":"por distribuidor",
    "APRIL":"Abril",
    "ASSIGN_BUNDLE_PRODUCTS":"Asignar productos de paquete",
    "ASSIGN_CUSTOMER_PLAN":"Asignar plan de cliente",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"¿Está seguro de que desea asignar {{customer}} a {{accountManager}}?",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El cliente se asignó con éxito",
    "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El revendedor se asignó correctamente",
    "ASSIGN_USAGE_PRODUCTS":"Asignar productos de uso",
    "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Cliente asignado al administrador de cuentas con éxito",
    "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Distribuidor asignado al administrador de cuentas con éxito",
    "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Entiendo que cada persona que utilice Windows 365 Business con Windows Hybrid Benefit también debe tener una copia válida de Windows 10/11 Pro instalada en su dispositivo de trabajo principal.",
    "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Cerca",
    "AUDIT_LOG_DETAIL":"Detalles",
    "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Ocultar detalles",
    "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Más detalles",
    "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Oculto",
    "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Todas las acciones--",
    "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Todos los clientes--",
    "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Todos los dominios--",
    "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Registro de auditoría",
    "AUDITLOG_BUTTON_LABEL_SEARCH":"Búsqueda",
    "AUDITLOG_TEXT_ACTION_NAME":"Comportamiento",
    "AUDITLOG_TEXT_AUDITLOG":"Registro de auditoría",
    "AUDITLOG_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "AUDITLOG_TEXT_EFFECTIVE_FROM":"Válido desde",
    "AUDITLOG_TEXT_EFFECTIVE_TO":"Efectivo para",
    "AUDITLOG_TEXT_FROM":"De",
    "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Nombre de dominio del cliente",
    "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Mostrar registros de auditoría ocultos",
    "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Comportamiento",
    "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Cliente afectado",
    "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Fecha",
    "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Iniciador",
    "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Ver detalles",
    "AUDITLOG_TEXT_TO":"A",
    "AUGUST":"Agosto",
    "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Código de autenticación recibido de Quickbooks",
    "AUTH_CODE_RECIEVED_FROM_XERO":"Código de autenticación recibido de Xero",
    "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"El lote ya está cerrado.",
    "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Solicitud completada con éxito",
    "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"La tarjeta de crédito ha caducado.",
    "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"El lote de dispositivos está lleno. Cierre el lote.",
    "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"El dispositivo ha sido deshabilitado.",
    "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Una transacción duplicada ha sido enviado.",
    "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"El identificador del dispositivo no está registrado o no está habilitado.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"El ID de lote del dispositivo no es válido.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"El ID del dispositivo no es válido.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"La bandera de inversión no es válida.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"El código de seguridad especificado no es válido.",
    "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Se produjo un error de validación en el procesador.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Los datos de la solicitud no pasaron la verificación de campos obligatorios para esta aplicación.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Los campos de solicitud no son válidos o faltan.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"Este comerciante no acepta transacciones de V.me.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"No se puede encontrar la transacción.",
    "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"La solicitud de anulación falló. El tipo de transacción original no admite la anulación o la transacción está en proceso de liquidación.",
    "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"El comerciante se activó con éxito.",
    "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"El comerciante está configurado para el cierre automático.",
    "AUTHORIZE_NET_CODE_NEED_CONSET":"Necesita el consentimiento del pagador.",
    "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Este pedido ya se ha liberado o anulado, por lo que no se pueden aceptar nuevas asociaciones de transacciones.",
    "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Tu orden ha sido recibida. ¡Gracias por hacer negocios!",
    "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"La transacción original está en un lote cerrado.",
    "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"El servicio de Cuenta de Pasarela de Pago (id=8) ya ha sido aceptado.",
    "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Se solicitó un nuevo código de seguridad.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Esta transacción ya ha sido autorizada.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal ya capturó esta transacción.",
    "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"La reversión se procesó con éxito.",
    "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"El dispositivo móvil se insertó con éxito en la base de datos.",
    "AUTHORIZE_NET_CODE_SUCCESS":"La solicitud fue procesada con éxito.",
    "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"El dispositivo móvil fue registrado y aprobado con éxito por el administrador de la cuenta.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Esta transacción ya ha sido anulada.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Esta transacción ya ha sido capturada.",
    "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"No se encontró la transacción original para la reversión.",
    "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal ha rechazado la transacción. x_paypal_hdrimg supera la longitud máxima permitida.",
    "AUTHORIZE_NET_CODE_USE_XMETHOD":"Use x_method para especificar el método o envíe solo x_call_id o información de tarjeta/cuenta.",
    "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"Este es un trabajo en progreso. Este mensaje no se lanzará a producción. Es solo un marcador de posición de desarrollo.",
    "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"La tasa de conversión para esta tarjeta está disponible.",
    "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"El código de la tarjeta no es válido.",
    "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Esta transacción no puede ser aceptada.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Esta operación no se puede procesar.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Esta cuenta no ha recibido los permisos necesarios para esta solicitud.",
    "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"El nombre dado en la cuenta y/o el tipo de cuenta no coincide con la cuenta real.",
    "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"Este sistema no puede aceptar transacciones ACH.",
    "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"El indicador de autenticación no es válido.",
    "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"El código de autorización no es válido.",
    "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"El tipo de cuenta bancaria no es válido.",
    "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"La facturación recurrente no está permitida para este tipo de eCheck.Net.",
    "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"El valor de autenticación del titular de la tarjeta no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"El código ABA no es válido",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"El token de acceso proporcionado ha caducado.",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"El token de acceso utilizado para validar la solicitud es insuficiente para hacerlo.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"El método API solicitado no se puede ejecutar mientras la cuenta de la pasarela de pago está en modo de prueba.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Este comerciante no está asociado con este revendedor.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"La cuenta de comerciante debe estar configurada para aceptar pagos con tarjeta de crédito, pagos con cheque electrónico o ambos.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"La pasarela de pago, el revendedor o la cuenta de usuario no están activos actualmente.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"La cuenta de la pasarela de pago no está configurada para procesar suscripciones con tarjeta de crédito.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"La cuenta de la pasarela de pago no está configurada para procesar suscripciones de eCheck.Net.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"El número de cuenta no es válido",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"El monto de la suscripción es obligatorio al crear una suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"Este comerciante no acepta transacciones ACH.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"La suma de todos los créditos contra esta transacción es mayor que el monto de la transacción original.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Todos los campos estaban vacíos o faltaban.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Todos los campos estaban vacíos o faltaban.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Valor no válido.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Valor demasiado largo.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"La suscripción ya ha sido cancelada.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"El registro ya ha sido eliminado.",
    "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"No se devolvió una aprobación para la transacción.",
    "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"No se enviaron uno o más valores de subasta requeridos.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"El tipo de autenticación no está permitido para la llamada al método solicitado.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Se requiere un código de autorización pero no está presente.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Este procesador no acepta autorizaciones de cero dólares.",
    "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"La transacción ha sido rechazada debido a un desajuste de AVS. La dirección proporcionada no coincide con la dirección de facturación del titular de la tarjeta.",
    "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"El método de pago bancario no se acepta para el país comercial seleccionado.",
    "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s no se puede dejar en blanco.",
    "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Ocurrió un error durante el procesamiento. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"Error de devolución del servicio web de llamada.",
    "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"Cancelar URL es obligatorio.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Esta transacción está pendiente de liquidación y no se puede reembolsar.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"El número de ocurrencias totales no puede extender la duración de la suscripción más allá de tres años desde la fecha de inicio.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"La tarjeta de crédito ha caducado.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"El número de la tarjeta de crédito es inválido.",
    "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Los totales de los montos de los artículos del carrito no coinciden con los montos de los pedidos. Asegúrese de que el total de los parámetros del artículo de detalle de pago sume el total del pedido.",
    "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"La cuenta de la pasarela de pago no está habilitada para el servicio de facturación o CIM.",
    "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Autorización de cliente denegada.",
    "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Los únicos tipos de contenido admitidos son texto/xml y aplicación/xml.",
    "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"No se pudieron descifrar los blobs DUKPT y se devolvió el error.",
    "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"El país no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"La tarjeta de crédito no es válida a partir de la fecha de inicio de la suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"El número de la tarjeta de crédito es inválido.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"El método de pago con tarjeta de crédito no se acepta para el país comercial seleccionado.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Este comerciante no acepta transacciones con tarjeta de crédito.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId y los datos del cliente son mutuamente excluyentes, solo se puede proporcionar uno de ellos para una sola solicitud.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Error al crear un perfil de cliente a partir de la transacción.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Se requiere la identificación fiscal del cliente o la información de la licencia de conducir (número de licencia de conducir, estado de la licencia de conducir, fecha de nacimiento de la licencia de conducir) para la suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Al usar el perfil del cliente, se debe incluir CustomerPaymentProfileId.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Error al crear un perfil de pago del cliente a partir de la transacción.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de cliente especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
    "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"Se agotó el tiempo de espera de la operación de la base de datos antes de que pudiera completarse.",
    "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"La suma de los créditos contra la transacción a la que se hace referencia excedería el monto del débito original.",
    "AUTHORIZE_NET_ERROR_CODE_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Se produjo un error al descifrar los datos EMV.",
    "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"El perfil de cliente no tiene un perfil de pago/envío predeterminado.",
    "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"La fecha de vencimiento está vencida o no está especificada.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Ya se envió un duplicado de la suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Una transacción duplicada ha sido enviado.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"KSN está duplicado.",
    "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Se requiere ID de perfil de cliente válido o correo electrónico",
    "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"El ID de empleado debe tener de 1 a %x caracteres de longitud.",
    "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"Los datos EMV no son compatibles con este tipo de mercado.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"La fecha de vencimiento de la tarjeta de crédito no es válida.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"No se pudo enviar el correo electrónico.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Validación de clave compartida fallida.",
    "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Error fatal al llamar al servicio web.",
    "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"La identificación del comerciante de FDC o la identificación de la terminal es incorrecta. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"El tipo de campo no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"Uno de los valores del campo no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"Uno de los campos tiene una longitud no válida.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"Uno de los campos obligatorios no estaba presente.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"No se puede generar la huella digital.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"El valor de la huella dactilar no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Error genérico InsertNewMerchant.",
    "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Los números de identificación del Sistema de Pago Global son incorrectos. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_HASH":"Hash no coincide.",
    "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"La página de pago alojada capturará la información de pago (banco/tarjeta), por lo que esta información no debe incluirse con esta solicitud.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"La clave API de suplantación no es válida o no está presente.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"El ID de inicio de sesión del socio de suplantación no es válido o no está presente.",
    "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Una vez que se crea una suscripción, el intervalo no se puede cambiar.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"El nombre de usuario de API no es válido y/o la clave de transacción o la clave de API no es válida.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"El ID del comprador de la subasta no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"El tipo de transacción no es válido para transacciones ACH.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"Falta información o no es válida en un campo obligatorio.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"La duración del intervalo debe ser de 7 a 365 días o de 1 a 12 meses.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal ha rechazado la transacción. ID de pagador no válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"La combinación del ID de la plataforma de subastas y el nombre de la plataforma de subastas no es válida.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"El valor del modo de entrada de POS no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"El Id. de perfil de cliente, el Id. de perfil de pago, el Id. de dirección de envío o el Id. de transacción de esta solicitud no son válidos para este comerciante.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"El nombre del nodo raíz de la solicitud XML es el método API al que se llama. No es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"El ID del vendedor de la subasta no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"El monto solicitado para la liquidación no puede ser diferente al monto original autorizado. Anule la transacción si es necesario",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"La transacción de V.me está en un estado no válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"La autenticación de usuario requiere un valor válido para la clave de transacción o la clave API.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"La autenticación de usuario requiere un valor válido para el nombre de usuario de la API.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Valor no válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"La versión EMV no es válida.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"El valor x_call_id no se encuentra o no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"El valor x_descuento no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"El valor x_giftwrap no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"El valor x_misc no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal ha rechazado la transacción. x_paypal_payflowcolor debe ser un valor hexadecimal de 6 caracteres.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"El valor x_subtotal no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Esta factura ha sido cancelada por el remitente. Póngase en contacto directamente con el remitente si tiene preguntas.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Número de factura no encontró ningún registro.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"El monto de la transacción enviada fue mayor que el monto máximo permitido.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Se ha alcanzado el número máximo de elementos para una matriz.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Se ha alcanzado el número máximo de perfiles de pago para el perfil de cliente.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Se ha alcanzado el número máximo de direcciones de envío para el perfil de cliente.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"La cuenta de revendedor no está habilitada para Merchant Boarding API.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"El comerciante no acepta este tipo de tarjeta de crédito.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"El comerciante ha rechazado la autorización del recurso.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"El comerciante no acepta este tipo de tarjeta de crédito.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"El ID de inicio de sesión del comerciante o la contraseña no son válidos o la cuenta está inactiva.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"El comerciante tiene demasiados dispositivos en estado pendiente.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"El comerciante no está activado.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"La contraseña del comerciante no es válida o no está presente.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"El comerciante se configuró incorrectamente en el procesador. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Los números de identificación de Paymentech son incorrectos. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"No se pudo analizar MerchantId a entero",
    "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"Falta el valor x_call_id.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"El identificador del dispositivo móvil no está asociado con la cuenta del comerciante.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"El dispositivo móvil existe pero está en estado pendiente.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"El dispositivo móvil existe pero tiene un estado deshabilitado.",
    "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"El país debe ser un valor válido de dos o tres caracteres si se especifica.",
    "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"El comerciante autenticado no tiene acceso al token OTS especificado.",
    "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"No se han encontrado registros que coincidan con su consulta.",
    "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"No hay suscripciones disponibles para la cuenta de comerciante para el tipo de suscripciones solicitadas.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"El token de acceso no tiene permiso para llamar al método API.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"La transacción fue autorizada pero el cliente no pudo ser notificado; no se resolverá.",
    "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me de Visa no admite anulaciones en transacciones capturadas o de crédito. Permita que la transacción se liquide y luego procese un reembolso por la transacción capturada.",
    "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"Uno de los valores del campo no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Solo se permite tarjeta de crédito o banco.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile y profileIds son mutuamente excluyentes, solo se puede proporcionar uno de ellos a la vez.",
    "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"La creación del perfil de pago con este descriptor OpaqueData requiere que el modo de transacción se establezca en modo en vivo.",
    "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Si se incluye el ID de perfil de cliente, debe coincidir con el ID de perfil de cliente utilizado para la suscripción original.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Los datos del token OTS especificados no son válidos.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"El token OTS especificado ha caducado.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"El token OTS especificado no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"El Servicio OTS no puede completar la solicitud debido a un error de validación o configuración.",
    "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"El desplazamiento de paginación supera el valor permitido. Comprobar y bajar el valor.",
    "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Se produjo un error al analizar los datos de EMV.",
    "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Este procesador no acepta reversiones parciales.",
    "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"La cuenta de socio no está autorizada para suplantar la cuenta de inicio de sesión.",
    "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal ha rechazado la transacción. Este ID de pagador pertenece a un cliente diferente.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"El servicio de Cuenta de Pasarela de Pago (id=8) ya ha sido aceptado. No se permite el rechazo.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"El servicio de la cuenta de pasarela de pago (id=8) ya ha sido rechazado.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"La cuenta de la pasarela de pago no está habilitada para la facturación recurrente automatizada.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"La cuenta de la pasarela de pago no está habilitada para el Administrador de información del cliente (CIM).",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Se requiere información de pago al crear una suscripción o un perfil de pago.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"La creación del perfil del cliente falló. Este método de pago no admite la creación de perfiles.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de pago especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Se requiere un cronograma de pago al crear una suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"Actualmente no se admite cambiar el tipo de pago de suscripción entre tarjeta de crédito y eCheck.Net.",
    "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Estado Pendiente (no completado).",
    "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"Requisito previo fallido.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"La cuenta del procesador no se ha configurado para establecer el indicador de modo de prueba en falso.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"El comerciante aún no ha proporcionado la información del procesador para establecer el indicador de modo de prueba en falso.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"La configuración con procesador no es válida. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"La creación del perfil del cliente falló. Este ID de transacción no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Los ID de perfil de pago y envío no se pueden especificar al crear nuevos perfiles.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Los ID de perfil de pago y envío no se pueden especificar al solicitar una página de pago alojada.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Si se incluyen el ID del perfil del cliente, el ID del perfil de pago y el ID de la dirección de envío, deben coincidir con la transacción original.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"La búsqueda del perfil de envío utilizando la identificación del perfil del cliente y la identificación del perfil de envío no encontró ningún registro.",
    "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Ya se envió un duplicado del perfil del cliente, el perfil de pago del cliente o la dirección del cliente.",
    "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"La configuración de facturación recurrente es un campo obligatorio para las transacciones de pago tokenizadas recurrentes.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"ID de referencia no encontrado.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"La transacción referenciada no cumple con los criterios para emitir un crédito.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"La acción solicitada no está permitida debido al estado actual del objeto.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Al solicitar CreateProfile los datos de pago no pueden ser nulos.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Pago no autorizado. El pago no ha sido autorizado por el usuario.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"Se requiere la versión EMV.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"El número de terminal/carril debe ser numérico.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Se requiere un descriptor opaco.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"Se requiere identificación del pagador.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"La firma debe ser datos con formato PNG.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"Las transacciones de PayPal requieren una URL válida para cancel_url",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Las transacciones de PayPal requieren una URL válida para success_url",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Falta el nodo requerido.",
    "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Se devolvió un error de validación de V.me.",
    "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Actualmente estamos reteniendo la última transacción para su revisión. Antes de reactivar la suscripción, revise la transacción.",
    "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"El nodo raíz no hace referencia a un espacio de nombres XML válido.",
    "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"No se puede establecer una tasa de compra inferior a la tasa de venta",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"El servidor está demasiado ocupado en este momento, inténtelo de nuevo más tarde.",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Error del Servidor",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"El servidor está en mantenimiento, por lo que el método solicitado no está disponible. Por favor, inténtelo de nuevo más tarde.",
    "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Su sesión ha caducado o no existe. Debe iniciar sesión nuevamente para continuar trabajando.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"El monto solicitado para liquidación no puede ser mayor al monto original autorizado.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"La autorización fue aprobada pero fracasó la liquidación.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"El ID de dirección de envío y los datos de envío son mutuamente excluyentes, solo se puede proporcionar uno de ellos para una sola solicitud.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"El perfil de envío especificado está asociado con una suscripción activa o suspendida y no se puede eliminar.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Al usar el perfil del cliente con la información de la tarjeta de crédito para especificar el perfil de envío, se debe incluir la identificación del perfil de envío.",
    "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Falta la clave de firma.",
    "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"No se pueden generar informes para fechas futuras, por lo que la fecha especificada no es válida.",
    "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Una vez que se crea una suscripción, la fecha de inicio no se puede cambiar.",
    "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"El estado no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"La fecha de inicio de la suscripción no puede ser anterior a la fecha de envío de la suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Las suscripciones vencidas o rescindidas no se pueden cancelar.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Las suscripciones vencidas, canceladas o rescindidas no se pueden actualizar.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"El ID de suscripción de esta solicitud no es válido para este comerciante.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"La fecha de inicio de la suscripción es necesaria para crear una suscripción.",
    "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"El código de moneda proporcionado no es válido, no es compatible, no está permitido para este comerciante o no tiene una tasa de cambio.",
    "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"El número de banco de Elavon o el ID de la terminal no son correctos. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Los datos de la firma son demasiado grandes.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Esta transacción ha sido aprobada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"La transacción se envió desde una dirección IP bloqueada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"La transacción a la que se hace referencia no cumple con los criterios para emitir una Autorización continua.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"La transacción a la que se hace referencia no cumple con los criterios para emitir una autorización continua con captura automática.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"La creación del perfil del cliente falló. Este tipo de transacción no admite la creación de perfiles.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Esta transacción debe estar encriptada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"El ID de la transacción no es válido o no está presente.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Esta transacción no se puede aceptar en este momento debido al mantenimiento del sistema. Por favor, inténtelo de nuevo más tarde.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"No se puede encontrar la transacción.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"Actualmente, las transacciones basadas en EMV no son compatibles con este procesador y tipo de tarjeta.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"Los datos EMV no son compatibles con este tipo de transacción.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"Este comerciante no acepta transacciones de PayPal.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"Las transacciones de PayPal requieren x_version de al menos 3.1.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"El tipo de transacción no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"El número de ocurrencias de prueba especificado debe ser menor que el número total de ocurrencias especificado.",
    "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Se produjo un error de sistema inesperado al procesar esta solicitud.",
    "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Error desconocido.",
    "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Factura creada pero no se pudo enviar el correo electrónico y actualizar el estado",
    "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"El remitente, la respuesta de retransmisión o la URL del enlace del recibo no son válidos.",
    "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"El APIUser ya existe.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"El usuario no tiene suficientes permisos para usar un dispositivo móvil con esta cuenta de comerciante.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"El usuario no tiene permiso para llamar a la API.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"El usuario no tiene permiso para llamar al método API.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Se requiere una cantidad válida.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"El número de instancias de prueba no puede ser cero si se envía un monto de prueba válido.",
    "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Si se especifica un monto de prueba o un número de instancias de prueba, se deben enviar valores para ambos.",
    "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Los números de identificación VITAL son incorrectos. Llame al proveedor de servicios comerciales.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Este es el resultado de un error del analizador XML.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Este es el resultado de un error del analizador XML. El nodo no es válido.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Este es el resultado de un error del analizador XML. Campo(s) faltante(s).",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Este es el resultado de un error del analizador XML. Se han especificado demasiados nodos.",
    "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"La combinación de tipo de tarjeta, indicador de autenticación y valor de autenticación del titular de la tarjeta no es válida.",
    "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"El tipo de tarjeta comercial no es válido.",
    "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Este país no está autorizado para compradores.",
    "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"El criptograma no es válido o no se puede utilizar en combinación con otros parámetros.",
    "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
    "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"El tipo de dispositivo no es válido o falta.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"La fecha de nacimiento de la licencia de conducir no es válida.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"El número de licencia de conducir no es válido.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"El estado de la licencia de conducir no es válido.",
    "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"El importe del impuesto no es válido.",
    "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Este tipo de eCheck.Net no está permitido cuando se utiliza el formulario de pago alojado en la pasarela de pago.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Este tipo de eCheck.Net no está permitido.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Este tipo de eCheck.Net no está permitido para este tipo de cuenta bancaria.",
    "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"El parámetro de tipo de eCheck no es válido.",
    "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"El monto del flete no es válido.",
    "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"La puerta de enlace ya no admite el método de integración solicitado.",
    "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"El número de cheque no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"El número de la tarjeta de crédito es inválido.",
    "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"La fecha de vencimiento de la tarjeta de crédito no es válida.",
    "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"El artículo de línea %1 no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"El ID de listado de la subasta no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"El tipo de anuncio de la subasta no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"El ID de la plataforma de subastas no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Faltan uno o más campos obligatorios o no son válidos.",
    "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"El SplitTenderID especificado no es válido.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Longitud de ECI del solicitante del token no válida.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Longitud de ID de solicitante de token no válida.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Nombre de solicitante de token no válido.",
    "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"El nombre de la plataforma de subastas no es válido.",
    "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"No se permite el número de líneas de pedido enviadas. Se puede enviar un máximo de %1 elementos de línea.",
    "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"El tipo de mercado no es válido",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Esta cuenta de comerciante ha sido cerrada.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"La cuenta de comerciante no está activa. Este pago automatizado no será procesado.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"La cuenta de comerciante está en modo de prueba. Este pago automatizado no será procesado.",
    "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Este procesador no acepta la autorización de cero dólares para este tipo de tarjeta.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Ocurrió un error durante el procesamiento. Póngase en contacto con el comerciante.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Uno o más de los campos de configuración de tipo HTML no parecen ser seguros.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Faltan uno o más de los valores obligatorios del subcomerciante.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Uno o más de los valores del comerciante secundario no son válidos.",
    "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"PayformMask no es válido.",
    "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Hubo un error al procesar los datos de pago.",
    "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"El procesamiento de Apple Payments no está habilitado para esta cuenta de comerciante.",
    "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Ocurrió un error durante el procesamiento. Inténtalo de nuevo.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Este procesador no acepta transacciones recurrentes.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Este procesador no permite la anulación de créditos.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Este procesador no admite este método de envío de datos de pago.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Esta solicitud no puede ser aceptada.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"El ID de transacción y el ID de pago dividido no se pueden usar en la misma solicitud.",
    "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"El tipo de formulario solicitado no es válido.",
    "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"El monto de esta solicitud solo se aprobó parcialmente en la tarjeta prepaga proporcionada. Se requiere un pago adicional para cumplir con el saldo de esta transacción.",
    "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"Se requiere la URL de éxito.",
    "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"El formato de respuesta no es válido",
    "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"La reversión no es compatible con este tipo de transacción.",
    "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"El script solicitado no es válido o ya no es compatible.",
    "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Los scripts solo se admiten en la versión 2.5.",
    "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"El estado o país de envío no es válido.",
    "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"El SSN o el número de identificación fiscal no es válido.",
    "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"El importe del recargo no es válido.",
    "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"El importe del impuesto no es válido.",
    "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"El monto de la propina no es válido.",
    "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Los datos de Track1 no están en un formato válido.",
    "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Los datos de Track2 no están en un formato válido.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"La transacción fue autorizada pero el cliente no pudo ser notificado; no se resolverá.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Esta transacción no puede ser aceptada en este momento.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Esta operación no se puede procesar.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Las transacciones que tienen valores de autenticación del titular de la tarjeta no se pueden marcar como recurrentes.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"La transacción está actualmente bajo revisión.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"La transacción ha sido rechazada.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Las transacciones de este tipo de mercado no se pueden procesar en este sistema.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"El método de transacción no es válido.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Esta transacción no puede ser aceptada.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Esta transacción no se puede aceptar para su procesamiento.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"La transacción a la que se hace referencia no fue aprobada.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"El tipo de transacción dado no es compatible con este comerciante.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"La transacción resultó en un desajuste de AVS. La dirección proporcionada no coincide con la dirección de facturación del titular de la tarjeta.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"El tipo de transacción no es válido",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"El tipo de transacción no es válido. Póngase en contacto con el comerciante.",
    "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Se requiere un país de facturación válido.",
    "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Se requiere un país válido.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Se requiere un estado/provincia de facturación válido.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Se requiere un estado válido.",
    "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"La versión 2.5 ya no es compatible.",
    "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"El parámetro de versión no es válido",
    "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Permitir el acceso a los administradores globales de CSP",
    "AUTO_PAYMENT_REQUEST_SUCCESS":"El pago se ha iniciado con éxito. Tomará algún tiempo para procesar. Por favor, compruebe el estado después de algún tiempo.",
    "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"El indicador de renovación automática solo se aplica a las ofertas de NCE",
    "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"El producto del plan de creación no tiene éxito durante el proceso de sincronización automática",
    "AUTO_SYNC_MACRO_NOT_FOUND":"Macro no encontrada",
    "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Múltiples ofertas mapeadas encontradas",
    "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Múltiples ofertas mapeadas encontradas con promoción",
    "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Varios planes encontrados",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"No se han encontrado ofertas mapeadas",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"No se han encontrado ofertas mapeadas con promoción",
    "AUTO_SYNC_NO_PLAN_FOUND":"No se encontró ningún plan",
    "Autotask":"Autotarea",
    "AUTOTASK_API_USER_NAME_IS_MISSING":"Falta el nombre de usuario de la API. Actualice el nombre de usuario en la pantalla de configuración.",
    "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"La cantidad de tarea automática no coincide con la cantidad anterior de C3",
    "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Seleccione un derecho",
    "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Categoría",
    "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Descripción",
    "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Impacto",
    "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Recurso impactado",
    "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Última actualización",
    "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Beneficios potenciales",
    "AZURE_BILLING":"facturación azul",
    "AZURE_BILLING_CYCLE":"Ciclo de facturación",
    "AZURE_ENTITLEMENT_MANAGEMENT":"Derecho de Azure",
    "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Administración de derechos de Azure",
    "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"¡Al arrendatario le falta un derecho de Azure!",
    "AZURE_ERROR_RECOMMENDATION_TEXT":"Error al obtener recomendaciones de Azure Advisor",
    "AZURE_ESTIMATE":"Estimación azul",
    "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Período de facturación",
    "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"ID de derecho",
    "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Nombre del derecho",
    "AZURE_ESTIMATE_LABEL_DEPARTMENT":" Departamento :",
    "AZURE_ESTIMATE_LABEL_SITE":"Sitio :",
    "AZURE_ESTIMATE_REPORT":"Informe de estimación de Azure",
    "AZURE_ESTIMATE_TAGS":"Etiquetas",
    "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Seleccione al menos una etiqueta para descargar la estimación de Azure",
    "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Etiquetas azules",
    "AZURE_ESTIMATE_TAGS_TOOLTIP":"Etiquetas de recursos definidas en Azure Portal",
    "AZURE_ESTIMATED_COST":"Costo estimado",
    "AZURE_ESTIMATES":"Estimaciones de Azure",
    "AZURE_GROUP_DETAILS":"Detalles del grupo",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Grupos de Azure",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Agregar administrador al grupo",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Añadir grupo",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Volver a usuarios del grupo",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Volver a grupos",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Editar grupo",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Usuarios del grupo",
    "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Agregar usuario de grupo",
    "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Grupos de Azure",
    "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Usuarios de {{groupname}}",
    "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrador",
    "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Acceso a facturación",
    "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Umbral de peligro",
    "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Descripción",
    "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Correo electrónico",
    "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Umbral de error",
    "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Nombre del grupo",
    "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Umbral de información",
    "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
    "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Frecuencia de notificación",
    "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Umbral de advertencia",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrador",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Acceso a facturación",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Frecuencia de notificación",
    "AZURE_LOADING":"Intentando cargar datos desde Azure, espere...",
    "AZURE_LOADING_MESSAGE":"Consultando Microsoft Azure y la carga podría tardar un minuto...",
    "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"Valor de margen de Azure actualizado correctamente",
    "AZURE_NEW_MARKUP":"Nuevo marcado",
    "AZURE_OLD_MARKUP":"marcado actual",
    "AZURE_PLAN_CREATION_FAILED":"Falló la creación del plan de Azure",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"El monto de la factura se calculará para el cliente, así como para sus respectivos sitios o departamentos, en función del porcentaje asignado.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"La asignación del plan de Azure y sus derechos se pueden compartir entre diferentes entidades de sitio y departamento asignando un porcentaje.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Instrucciones",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Pasos para asignar: <br /> a. Amplíe la suscripción deseada. <br /> b. Haga clic en el número de porcentaje asociado con una entidad. <br />c. Ingrese el porcentaje deseado. <br /> d. Haga clic en <strong> Enviar <strong/> para guardar la asignación.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Para asignar completamente el plan de Azure o el derecho a un sitio/departamento, ingrese 100.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Para liberar una asignación, ingrese 0.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Las asignaciones liberadas se asignarán a la entidad matriz inmediata.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Estas asignaciones no tendrán ningún impacto en los informes y el informe de facturación y estimación de Azure seguirá mostrando todos los datos del cliente.",
    "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Puntuación por categoría",
    "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Todas las categorias",
    "AZURE_RECOMMENDATION_ALL_COST":"Costo",
    "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"Alta disponibilidad",
    "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"Excelencia operativa",
    "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Actuación",
    "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Fiabilidad",
    "AZURE_RECOMMENDATION_ALL_SECURITY":"Seguridad",
    "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Descargar detalles de la recomendación",
    "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Detalles de la recomendación",
    "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Este campo es obligatorio",
    "AZURE_RECOMMENDATION_SUBMIT":"Entregar",
    "AZURE_RECOMMENDATION_TEXT":"Recomendación de Azure",
    "AZURE_RECOMMENDATIONS_TEXT":"Recomendaciones",
    "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Seleccione un código de moneda",
    "AZURE_REPORT_DISTRIBUTOR_LIST":"Distribuidor",
    "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Seleccione el socio o uno de los revendedores.",
    "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Seleccione una entidad",
    "AZURE_REPORT_ENTITY_LIST":"Entidad",
    "AZURE_REPORT_ENTITY_TOOLTIP":"Seleccione el socio o uno de los revendedores.",
    "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Seleccione un revendedor",
    "AZURE_REPORT_RESELLERS_LIST":"Elige un revendedor",
    "AZURE_REPORTS_BITTON_TEXT":"Informe de estimación de Azure",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Detalles por",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Detalles por grupo de recursos",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Detalles por servicio",
    "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Obtén detalles",
    "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Sin etiquetas",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Informes de Azure",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Con etiquetas",
    "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Resumen de facturación",
    "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Precio del plan Azure",
    "AZURE_REPORTS_LABEL_TEXT_DATE":"Fecha",
    "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Derecho",
    "AZURE_REPORTS_LABEL_TEXT_GROUP":"Grupo",
    "AZURE_REPORTS_LABEL_TEXT_MONTH":"Mes",
    "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Selecciona grupo",
    "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Suscripción",
    "AZURE_REPORTS_OPTION_SELECT_TAG":"Seleccione una etiqueta para obtener datos de etiquetas inteligentes",
    "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Todos los derechos",
    "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Todos los grupos",
    "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Todas las suscripciones",
    "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Seleccione fecha",
    "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"No asignado",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Costo",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Nombre del recurso",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Nombre del Servicio",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Auditoría",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Detalles",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Grupo de recursos",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Nombre del recurso",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Nombre del Servicio",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Coste total",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Fecha y hora",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Categoría de evento",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Evento iniciado por",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Nombre de la operación",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Estado",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Auditoría",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Detalles",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Costo",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Fecha",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Coste total",
    "AZURE_REPPORT_CURRENCY":"Divisa",
    "AZURE_REPPORT_CURRENCY_TOOLTIP":"Debes seleccionar una moneda para obtener el informe.",
    "AZURE_REPPORT_CUSTOMERS":"Clientes",
    "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Seleccionar cliente",
    "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Seleccionar cliente",
    "AZURE_ROLE_NAME_CONTRIBUTOR":"Contribuyente",
    "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Rol personalizado",
    "AZURE_ROLE_NAME_OWNER":"Dueño",
    "AZURE_ROLE_NAME_READER":"Lector",
    "AZURE_SUBSCRIPTION":"Suscripción Azure",
    "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Editar",
    "AZURE_SUBSCRIPTION_DETAILS":"Detalles de suscripción",
    "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Seleccione",
    "AZURE_SUBSCRIPTION_ID":"Identificación de suscripción",
    "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"¡Al inquilino le falta un plan de Azure!",
    "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Error de actualización de la suscripción de Azure",
    "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"La suscripción de Azure se actualizó correctamente",
    "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"¿Está seguro de que desea actualizar?",
    "AZURE_THRESHOLD_DETAILS":"Detalles del umbral",
    "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Establezca un presupuesto mensual para esta suscripción y defina los umbrales en diversas gravedades, como Información, Advertencia, Error y Peligro.",
    "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Para las suscripciones administradas por el cliente, todos los administradores del grupo recibirán una notificación cuando el uso alcance o supere un umbral determinado.",
    "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Las notificaciones se envían una vez por ciclo de facturación.",
    "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Plan azul",
    "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Espere, estamos verificando la elegibilidad para la actualización",
    "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"La suscripción no es elegible para la actualización",
    "AZURE_UPGRADE_WAIT_MESSAGE":"Por favor espere Estamos procesando la actualización...",
    "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Volver a la lista",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Uso de Azure",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
    "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Detalles de uso",
    "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Ver detalles de uso",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Precio estimado",
    "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Nombre",
    "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"ID de suscripción",
    "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} agregado al grupo {{groupname}} exitosamente",
    "AZUREGROUPS_SAVE_SUCCESS":"Grupo {{username}} guardado exitosamente",
    "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Usuario del grupo agregado exitosamente",
    "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Grupo guardado exitosamente",
    "BACK_TO_AZURE_ADVISOR":"Volver al asesor azul",
    "BACK_TO_POLICIES":"Volver a Políticas personalizadas",
    "BACK_TO_PUBLIC_SIGNUP_SHOP":"volver a la tienda",
    "BACK_TO_SERVICE_PROVIDER_TENANT":"Volver a inquilinos proveedores",
    "BACKGROUND_DROPDOWN_TEMPLATE_1":"Plantilla 1",
    "BACKGROUND_DROPDOWN_TEMPLATE_10":"Plantilla 10",
    "BACKGROUND_DROPDOWN_TEMPLATE_2":"Plantilla 2",
    "BACKGROUND_DROPDOWN_TEMPLATE_3":"Plantilla 3",
    "BACKGROUND_DROPDOWN_TEMPLATE_4":"Plantilla 4",
    "BACKGROUND_DROPDOWN_TEMPLATE_5":"Plantilla 5",
    "BACKGROUND_DROPDOWN_TEMPLATE_6":"Plantilla 6",
    "BACKGROUND_DROPDOWN_TEMPLATE_7":"Plantilla 7",
    "BACKGROUND_DROPDOWN_TEMPLATE_8":"Plantilla 8",
    "BACKGROUND_DROPDOWN_TEMPLATE_9":"Plantilla 9",
    "BAD_INPUT_INVALID_PRODUCTID":"ID de producto no válido",
    "BAD_INPUT_INVALID_SEATS":"Asientos inválidos",
    "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entidades involucradas en la notificación del banner",
    "BILL_ACTION_DESC_FULL_CHARGE":"Carga completa durante todo el mes.",
    "BILL_ACTION_DESC_NO_REFUND":"No se proporcionará ningún reembolso",
    "BILL_ACTION_DESC_PRORATE":"El prorrateo se aplicará en la factura.",
    "BILL_ACTION_NAME_FULL_CHARGE":"Carga completa",
    "BILL_ACTION_NAME_NO_REFUND":"No hay reembolso",
    "BILL_ACTION_NAME_PRORATE":"Prorratear",
    "BILL_CYCLE_DESC_ANNUAL":"Anual",
    "BILL_CYCLE_DESC_MONTHLY":"Mensual",
    "BILL_CYCLE_DESC_ONETIME":"Una vez",
    "BILL_CYCLE_DESC_TRIENNIAL":"Trienal",
    "BILL_CYCLE_NAME_ANNUAL":"Anual",
    "BILL_CYCLE_NAME_MONTHLY":"Mensual",
    "BILL_CYCLE_NAME_TRIENNIAL":"Trienal",
    "BILL_FROM":"Factura de",
    "BILL_TO":"Cobrar a",
    "Billing":"Facturación",
    "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"No se ha podido eliminar el enlace del cliente de facturación.",
    "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"El enlace del cliente de facturación se eliminó correctamente.",
    "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"No se permite el cambio del ciclo de facturación del producto ya que la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
    "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Nombre del producto actual",
    "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Ciclo de facturación del producto actualizado correctamente",
    "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Seleccione",
    "BILLING_CYCLE_TOOLTIP_BUNDLE":"Ciclo de facturación",
    "BILLING_FORECAST_REPORT":"Pronóstico de facturación",
    "BILLING_PERIOD":"Período de facturación",
    "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Seleccione",
    "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Seleccione al menos un período de facturación",
    "BILLING_TYPE_DESC_MARK_UP":"Margen",
    "BILLING_TYPE_DESC_METERED_BILLING":"facturación medida",
    "BILLING_TYPE_DESC_PERCENTAGE":"Porcentaje",
    "BILLING_TYPE_DESC_PRICE":" Precio",
    "BILLING_TYPE_DESC_UNIT":"Unidad",
    "BILLING_TYPE_TOOLTIP":"Tipo de facturación",
    "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Estimaciones de Azure",
    "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Suscripción a Azure",
    "BREADCRUM_BUTTON_TEXT_LICENSES":"Licencias",
    "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Uso de Azure",
    "BREADCRUMB_HEADER_TEST_PAYMENT":"Pago de prueba",
    "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
    "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Detalles del estado de pago",
    "BTN_AZURE_BILLING_COMPARISON_REPORT":"Informe de comparación de facturación de Azure",
    "BTN_BILLING_FORECAST_REPORT_LOADING":"Pronóstico de facturación...",
    "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Eliminar el registro",
    "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Descargar factura zip",
    "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Ver detalles de la factura",
    "BTN_INVOICE_LINE_ITEMS":"Partidas de factura",
    "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Partidas de factura con impuestos...",
    "BTN_INVOICE_PAYMENTS":"Pagos de facturas",
    "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Volver a pagos",
    "BTN_QUOTE_COPY_URL":"Copiar URL",
    "BTN_QUOTE_PUBLISH":"Publicar cotización",
    "BTN_QUOTE_REPUBLISH":"Republicar cita",
    "BTN_QUOTE_SAVE_DRAFT":"Guardar borrador",
    "BTN_TEXT_ADD_TO_CART":"Añadir a la cesta",
    "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"volver a la historia",
    "BTN_TEXT_BEGIN_UPLOAD":"Subir",
    "BTN_TEXT_BEGIN_VALIDATE_DATA":"Validar",
    "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Ver los registros",
    "BTN_TEXT_CANCEL_ORDER":"Cancelar orden",
    "BTN_TEXT_COMMENT":"Comentario",
    "BTN_TEXT_COPY_TO_CLIPBOARD":"Copiar al portapapeles",
    "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Descargar todos los cupones",
    "BTN_TEXT_FILTER":"Filtrar",
    "BTN_TEXT_FIX":"Arreglar",
    "BTN_TEXT_IGNORE":"Ignorar",
    "BTN_TEXT_INCLUDE":"Incluir",
    "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Promoción mapa",
    "BTN_TEXT_MAPPING_OF_PRODUCTS":"Mapeo de productos",
    "BTN_TEXT_OPEN_IN_EMAIL":"Abrir en correo electrónico",
    "BTN_TEXT_PROCEED":"Proceder",
    "BTN_TEXT_QUICK_VIEW":"Vista rápida",
    "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Pagos múltiples",
    "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Pago unico",
    "BTN_TEXT_REMOVE":"Remover",
    "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Eliminar promoción",
    "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Solicitar una relación de revendedor",
    "BTN_TEXT_SHOW_FILTERS":"Mostrar filtros",
    "BTN_TEXT_SIGN_IN":"Iniciar sesión",
    "BTN_TEXT_SKIP":"Saltar",
    "BTN_TEXT_TAKE_ON":"Incluir",
    "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Administrar cargas de tareas automáticas",
    "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Administrar cargas de Connectwise",
    "BTN_TEXT_VIEW_PRODUCT":"Ver el producto",
    "BULK_ADD_ENTITLEMENT_BATCH_ID":"ID del lote",
    "BULK_ADD_ENTITLEMENT_DESCRIPTION":" El nombre del último derecho creado es",
    "BULK_ADD_TENANT_DESCRIPTION":" El último inquilino creado es",
    "BULK_ADD_TENANTS_BATCH_ID":"ID del lote",
    "BULK_DATA_MAPPING_CSV_UPLOAD":"Cargar hoja mapeada",
    "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Volver al resumen de ingresos y costos",
    "BULK_INVOICE_DOWNLOAD_CANCEL":"Cancelar",
    "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Seleccione al menos un cliente",
    "BULK_INVOICE_DOWNLOAD_HEADER":"Descargar facturas masivas",
    "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"correo electrónico",
    "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Recibe todas las facturas por correo electrónico",
    "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Ingrese la dirección de correo electrónico a la que desea enviar un correo electrónico, ingréselo con coma (,) o punto y coma (;) y haga clic en Enviar para recibir un archivo zip que contiene la lista de facturas en PDF para el Período de facturación seleccionado",
    "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"¡Le notificaremos a los correos electrónicos proporcionados una vez que el archivo zip esté listo para descargar! Por lo general, toma una hora para completar",
    "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Seleccionar Plan",
    "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Cerca",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"La factura masiva se elimina con éxito.",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Número de factura",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Dueño",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Estado",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Usuario",
    "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Seleccionar Plan",
    "BULK_INVOICE_DOWNLOAD_SUBMIT":"Entregar",
    "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"¿Está seguro de que desea eliminar este registro?",
    "BULK_INVOICE_PROGRESS_POPUP_HEADER":"Estado de descarga de factura",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Completado en",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"Correos electrónicos",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Iniciador",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Enlace disponible hasta la fecha",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Fecha de inicio",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Estado",
    "BULK_ONBOARD_BATCH_ID":"BatchId a bordo a granel",
    "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"BatchId a bordo a granel",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Descargar detalles de mapeo",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Descargar asignación de suscripción",
    "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"Se asignó correctamente <b>{{ TotalSubscriptionsOnboarded }}</b> de <b>{{ TotalSubscriptionsAtProvider }}</b> del Centro de partners.",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"¿Desea eliminar todos los clientes seleccionados?",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quiere seleccionar todos los clientes?",
    "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":" <b>{{selectedCustomers}}</b> seleccionados de <b>{{totalCustomers}}</b> clientes para la incorporación",
    "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Revendedores existentes a bordo",
    "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Por favor proporcione un correo electrónico válido",
    "BULK_ONBOARD_REQUEST_ACCEPTED":"Solicitud en cola para incorporación masiva de clientes",
    "BULK_ONBOARD_RESELLERS_BATCH_ID":"ID del lote",
    "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"El lote se completa con éxito. Será redirigido a revendedores.",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Seleccionar de la lista",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"Subir CSV",
    "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Seleccione al menos 1 revendedor para incorporar.",
    "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Resumen de resultados a bordo",
    "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Total seleccionado: {{totalResellersInBatch}}, Total incorporado: {{resellersOnboarded}}, Total fallido: {{resellersFailedToOnboard}}",
    "BULK_ONBOARD_RESELLERS_LBL_OPTION":"¿Cómo quieres embarcar?",
    "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"¿Desea eliminar todos los revendedores seleccionados?",
    "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quiere seleccionar todos los revendedores?",
    "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"La solicitud de revendedores masivos a bordo está en cola.<br /> Se le redirigirá a la pantalla de estado que muestra el progreso.",
    "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"<b>{{selectedResellers}}</b> seleccionados de <b>{{totalResellers}}</b> revendedores para la incorporación",
    "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"errores",
    "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":" Id. MPN -",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"RevendedoresError",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"RevendedoresCon éxito",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"TotalRevendedoresSeleccionados",
    "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Introduzca un correo electrónico válido",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Parece que falta el detalle del ID de MPN para el revendedor. Comuníquese con el socio para actualizar los detalles.",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"¡La incorporación no es compatible!",
    "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"No se admite la incorporación masiva de clientes de Microsoft que no sean CSP.",
    "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
    "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Revendedores a bordo a granel",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Proveedor",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Elija el proveedor de soluciones de la lista desplegable",
    "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Estado",
    "BULK_ONBOARDING_STATUS_INFO_HEADER":"¡Consejo! Pasos para corregir la incorporación parcial",
    "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Si hay problemas de asignación de suscripción informados por el sistema durante la incorporación de un cliente, haga clic en el hipervínculo para descargar y revisar los detalles. Arregle sus planes y trabaje con Soporte para sincronizar los datos en C3.",
    "BULK_PRODUCT_MAPPING":"Mapeo de productos a granel",
    "BULK_PURCHASED_PRODUCT_MAPPING":"Mapeo de productos de compra al por mayor",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Descargue el catálogo para obtener clientes y sus identificaciones.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Complete el código de moneda, el monto del pago y la fecha de pago para el C3Id correspondiente.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Asegúrese de que los datos cumplan con las siguientes reglas:",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> Id. de cliente C3 (o Id. de revendedor C3) del catálogo contra el cual el cliente desea registrar el pago.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode - Texto (código de moneda estándar ISO):</strong> en qué moneda se desea registrar el pago",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount - Decimal –</strong> Monto a registrar",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Observaciones - Texto –</strong> Comentarios (opcional)",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Fecha (AAAA-MM-DD) –</strong> ¡En qué fecha se debe registrar el pago! ¡Solo fechas pasadas!",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Haga clic en <strong>Seleccionar</strong> y busque el archivo.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Haga clic en <strong>Validar catálogo</strong> para que el sistema valide la entrada.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Revise los errores en la tabla después de completar la validación.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Corrija los errores en el archivo y vuelva a cargarlo.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"Si no hay errores de validación en la entrada, se habilita el botón <strong>Importar pagos</strong>. Haga clic en el botón para enviar sus cambios.",
    "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Hecho",
    "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Clientes seleccionados",
    "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Elija al menos un cliente",
    "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"¿Quieres eliminar al cliente?",
    "BULK_UPLOAD_CUSTOMER_SUCCESS":"Se acepta la solicitud para agregar varios clientes.",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Se produjo un error al analizar el archivo cargado",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Éxito",
    "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Se produjo un error al analizar el archivo cargado",
    "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Descarga un .csv de muestra",
    "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Descargue el catálogo para obtener los códigos y la secuencia más recientes.",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"archivo de referencia.",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Éxito",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Instrucciones",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Complete el código o la secuencia o ambos para los productos deseados y elimine las líneas que no desea cambiar. El código de producto es opcional y admite 100 caracteres. La secuencia de visualización es un número y es opcional.<br /><strong>Nota:</strong> si deja los dos en blanco, el código y la secuencia existentes se borrarán.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Haga clic en <strong class=\"text-uppercase\">Validar catálogo</strong> para que el sistema valide la entrada.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Revise los errores en la tabla después de completar la validación.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Corrija los errores en el archivo y vuelva a cargarlo.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"¡Importación completada!",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"¡Validación completada!",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Catálogo de importación",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Validar Catálogo",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Éxito",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar todos los clientes",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar departamento",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Se produjo un error al analizar el archivo cargado",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Descargue el catálogo para obtener los últimos detalles de las comisiones de productos.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Éxito",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Complete el nombre del agente, el código SP, el porcentaje de comisión, la fecha de inicio de la comisión, la fecha de finalización de la comisión para los productos deseados y elimine las líneas que no desea cambiar. Todas estas columnas son opcionales. <br /> El nombre del agente es un texto que admite 100 caracteres. <br />SPCode es un texto que admite 100 caracteres. <br />El porcentaje de comisión es un número que también admite decimales. <br />La fecha de inicio de la comisión es una fecha que debe definirse en formato <strong>{{dateFormat}}</strong>.<br />La fecha de finalización de la comisión es una fecha que debe definirse en <strong>{ Formato {dateFormat}}</strong>.<br /><strong>Nota:</strong> si actualiza los valores existentes a un espacio en blanco, los valores existentes se borrarán una vez que se importe el catálogo.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Haga clic en <strong class=\"text-uppercase\">Validar catálogo</strong> para que el sistema valide la entrada.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Revise los errores en la tabla después de completar la validación.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Corrija los errores en el archivo y vuelva a cargarlo.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{validationProcessedCount}}<br />Errores: {{validationErrorCount}}<br />Éxito: {{validationSuccessCount}}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar sitio",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Éxito",
    "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"¿Quieres eliminar el revendedor?",
    "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Las siguientes suscripciones no tienen ofertas equivalentes en el plan. Actualice el plan e inténtelo de nuevo.",
    "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"No se puede encontrar un cliente en CSP con ID: {{CSPCustomerId}}",
    "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Las siguientes ofertas se compraron más de una vez en un solo nivel, lo cual no es compatible con C3. Por favor verifique los datos.",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Remover",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Complementos",
    "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Su última operación de actualización parece tener algunos errores; comuníquese con el equipo de soporte para obtener ayuda.",
    "BUNDLE_PRODUCTS_PLACEHOLDER":"Paquete de productos",
    "BUNDLE_REPORT_FILE_TYPES_HEADER":"Informe de detalles del producto del paquete",
    "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de detalles del producto Bundle",
    "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista del informe de detalles del producto Bundle.",
    "BUNDLE_SAVE_SUCCESS":"El paquete {{customoffer}} se guardó correctamente",
    "BUNDLE_UPDATE_SUCCESS":"El paquete {{customoffer}} se actualizó correctamente",
    "bundles":"manojos",
    "BUNDLES_BILLING_CYCLE_TOOLTIP":"Frecuencia de facturación a la que se facturaría el paquete",
    "BUNDLES_BILLING_TYPE_TOOLTIP":"Categoría de facturación en la que se facturaría el paquete",
    "BUNDLES_CANNOT_BE_DELETED":"Los paquetes no se pueden eliminar ya que están asignados a un plan",
    "BUNDLES_SALETYPE_TOOLTIP":"Tipo de categoría para la oferta",
    "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"La importación está en curso",
    "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Espere por favor...",
    "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"La validación está en progreso",
    "BUTTON_ACTIVATE":"Activar",
    "BUTTON_APPLY_COUPON_TEXT":"Aplicar cupón",
    "BUTTON_CLOSE":"Cerca",
    "BUTTON_DEACTIVATE":"Desactivar",
    "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Cancelar",
    "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Descargar",
    "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Actualizar",
    "BUTTON_LANG_CN":"Chino",
    "BUTTON_LANG_DE":"Alemán",
    "BUTTON_LANG_EN":"inglés",
    "BUTTON_LANG_ES":"español",
    "BUTTON_LANG_FR":"Francés",
    "BUTTON_LANG_IT":"italiano",
    "BUTTON_LANG_SK":"eslovaco",
    "BUTTON_LANG_TH":"tailandés",
    "BUTTON_LANG_TR":"turco",
    "BUTTON_LANG_TW":"taiwanés",
    "BUTTON_LANGUAGE":"Idioma",
    "BUTTON_MANAGE_PRODUCT":"Administrar",
    "BUTTON_ONHOLD":"En espera",
    "BUTTON_PROCESSING":"Procesando",
    "BUTTON_RELEASE":"Liberar",
    "BUTTON_REVOKE":"Revocar",
    "BUTTON_START_TOUR":"hacer un recorrido",
    "BUTTON_TEXT_ACCEPT":"Aceptar",
    "BUTTON_TEXT_ACTIONS":"Comportamiento",
    "BUTTON_TEXT_ADD":"Agregar",
    "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Agregar nueva conversión de moneda",
    "BUTTON_TEXT_ADD_CUSTOM_POLICY":"agregar política personalizada",
    "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Agregar notificación por correo electrónico de nuevo evento",
    "BUTTON_TEXT_ADD_SUB_TAX":"Agregar subimpuesto",
    "BUTTON_TEXT_ADD_WITH_ADDONS":"Agregar con complementos",
    "BUTTON_TEXT_APPLY":"Aplicar",
    "BUTTON_TEXT_APPROVE":"Aprobar",
    "BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
    "BUTTON_TEXT_CANCEL":"Cancelar",
    "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Comprobar todo el estado",
    "BUTTON_TEXT_CLEAR":"Claro",
    "BUTTON_TEXT_CLOSE":"Cerca",
    "BUTTON_TEXT_DELETE":"Borrar",
    "BUTTON_TEXT_DOWNLOAD":"Descargar",
    "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Descargar administradores de cuentas",
    "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Descargar catálogo",
    "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Descargar clientes",
    "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Descargar resumen de registro de publicación de facturas",
    "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Descargar partida de factura",
    "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Descargar factura de cuotas",
    "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Descargar ofertas de socios",
    "BUTTON_TEXT_DOWNLOAD_PLAN":"Descargar plano",
    "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Descargar oferta de planes",
    "BUTTON_TEXT_DOWNLOAD_REPORT":"Descargar informe",
    "BUTTON_TEXT_EDIT":"Editar",
    "BUTTON_TEXT_EXPORT_TO_EXCEL":"Exportar a Excel",
    "BUTTON_TEXT_FILTER":"Filtrar",
    "BUTTON_TEXT_LOAD_OFFERS":"Cargar ofertas",
    "BUTTON_TEXT_OK":"OK",
    "BUTTON_TEXT_OK_CLOSE":"Ok, cerca",
    "BUTTON_TEXT_OVERRIDE":"Anular",
    "BUTTON_TEXT_PAY_INVOICES":"Pagar facturas pendientes",
    "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Proceder a comprar",
    "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Pago anticipado récord",
    "BUTTON_TEXT_REJECT":"Rechazar",
    "BUTTON_TEXT_REMOVE":"Remover",
    "BUTTON_TEXT_REMOVE_SUB_TAX":"Eliminar subimpuestos",
    "BUTTON_TEXT_REPORTS":"Informes",
    "BUTTON_TEXT_RESET":"Reiniciar",
    "BUTTON_TEXT_REVERT":"Revertir",
    "BUTTON_TEXT_SAVE":"Ahorrar",
    "BUTTON_TEXT_SEARCH":"Búsqueda",
    "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Establecer entidad operativa",
    "BUTTON_TEXT_SUBMIT":"Entregar",
    "BUTTON_TEXT_UPDATE":"Actualizar",
    "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"Subir a Quickbooks en línea",
    "BUTTON_TEXT_UPLOAD_TO_XERO":"Subir a Xero",
    "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Ver padres",
    "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"Ver registros de publicaciones",
    "BUTTON_TEXT_VIEW_OFFERS":"Ver ofertas",
    "BUTTON_VIEW_ERRORS":"Ver errores",
    "BUTTON_VIEW_PRODUCT":"Vista",
    "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Añadir etiqueta",
    "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Editar etiqueta",
    "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"para",
    "C3_CART":"Carro",
    "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Categoría de proveedor",
    "C3_TEXT_ONHOLD_STATUS":"En espera",
    "c3supportAzuremargin":"margen azul",
    "CALENDAR_CLEAR_BUTTON":"Claro",
    "CALENDAR_CLOSE_BUTTON":"Cerca",
    "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Por favor ingrese un motivo para cancelar el pedido",
    "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Se requiere motivo para cancelar el pedido programado.",
    "CANCEL_SCHEDULED_ORDER_SUCCESS":"El pedido se canceló con éxito",
    "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"Cancelar orden",
    "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Cancelar pedido programado",
    "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Se requiere motivo para cancelar el producto programado.",
    "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Se requiere motivo para cancelar el pedido",
    "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"El producto se canceló exitosamente de este pedido.",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Renovación programada cancelada debido a un cambio en el estado de renovación automática",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Renovación programada cancelada debido a actualización de cantidad",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Renovación programada cancelada por rechazo de solicitud de renovación",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Se canceló la renovación programada porque la propiedad del cronograma se cambió desde el centro de socios",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Renovación programada cancelada debido a un cambio de estado de suscripción",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Renovación programada cancelada debido a actualización de suscripción",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Renovación programada cancelada debido a una actualización de suscripción",
    "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"No se pudo cancelar la renovación programada",
    "CANCEL_SCHEDULED_RENEWAL_REASON":"Razón",
    "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Proporcione una razón",
    "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Este campo es obligatorio",
    "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Canceló con éxito la renovación programada",
    "CANCELLED_REASON":"Motivo de cancelación",
    "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"No se puede eliminar una definición de conversión de moneda anterior",
    "CANNOT_INITIATE_PAYMENT":"No se puede iniciar un pago debido a una transacción pendiente",
    "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Este producto ya ha sido comprado por el cliente. No es posible vincular el producto.",
    "CanPriceLag":"¿Puede el precio retrasarse?",
    "CanPriceLead":"¿Puede el precio liderar?",
    "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Solicitud de relación revendedor",
    "CARD_CARD_DELETE_ADDON":"Borrar",
    "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Centro de intercambio",
    "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Datos de uso del producto",
    "CART_CARD_COMMENT_ACTION":"Comentarios",
    "CART_CARD_DELETE_PRODUCT":"Borrar",
    "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Este producto ya está disponible en su carrito. ¿Todavía quieres continuar?",
    "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Su inquilino no es elegible para comprar el Plan Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3 o hay una suscripción de Azure activa en el Centro de partners y necesita una actualización. Póngase en contacto con el soporte para obtener más ayuda.",
    "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"¿Está seguro de que desea cambiar el inquilino de la oferta seleccionada?",
    "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Fecha de finalización personalizada guardada correctamente",
    "CART_DISABLE_PROMOTION":"Deshabilitar Promoción del producto.",
    "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Se produjo un error al actualizar el inquilino en la oferta seleccionada. Por favor contacte al equipo de soporte",
    "CART_GENERIC_ERROR_MESSAGE":"Algo salió mal al completar el pedido. Consulte con el socio antes de volver a intentarlo.",
    "CART_ITEM_EFFECTIVE_PRICE":"Precio efectivo",
    "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Orden por Sitio/Departamento",
    "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Inquilino",
    "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"¡El carrito no está disponible para pagar o ya está pagado!",
    "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"No puedes programar este carrito porque no está disponible o ya está programado.",
    "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Producto vinculado",
    "CART_PAGE_AUTORELEASE":"Liberación automática",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":" {{descuento}}% de descuento",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Descuento",
    "CART_PAGE_BUTTON_TEXT_REMOVE":"Remover",
    "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Aplicar",
    "CART_PAGE_DISCOUNT_INFORMATION":"{{discount}}% de descuento)",
    "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Tipos de consumo",
    "CART_PAGE_TEMPLATE_ADD_COMMENT":"Agregar comentario",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<span class='font-weight-boldest'>¡Hola!</span> Tu carrito está vacío. <a ng-click='vm.GotoCart()' class='text-primary' role='button'> Haga clic aquí para comprar. <span class='fa fa-cart-plus'></span> </a>",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>¡Hola!</span> Su carrito está vacío.",
    "CART_PAGE_TITLE_PRICE":"Precio minorista estimado",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Fecha de finalización personalizada",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Seleccione el tipo de fecha de finalización personalizada",
    "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Seleccione..",
    "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Seleccione..",
    "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Liberación automática actualizada con éxito",
    "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Se produjo un error al retirar el carrito",
    "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Pedido realizado con éxito. ID de pedido: {{ID de pedido}}",
    "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Se produce un error al procesar su solicitud para convertir la oferta de prueba de {{productName}} en suscripción",
    "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Solicitud para convertir la oferta de prueba de {{productName}} en suscripción enviada correctamente",
    "CART_PRODUCT_DELETE_CONFIRMATION":"¿Está seguro de que desea eliminar este artículo del carrito?",
    "CART_PRODUCT_DELETE_ERROR_MESSAGE":"¡Ocurrió un error al retirar el artículo del carrito!",
    "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"¡Se eliminó el artículo solicitado con éxito!",
    "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Se produjo un error al verificar la elegibilidad de la promoción",
    "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"El ID de promoción disponible es válido en la promoción.",
    "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Cantidad actualizada correctamente",
    "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Se produjo un error al programar el pedido.",
    "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"El pedido se ha programado correctamente.<br/>El ID del pedido es: {{orderId}}",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Este producto ya se compró hace {{days}} días. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Este producto ya se compró hace {{hours}} horas. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Este producto ya se compró hace {{minutos}} minutos. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Este producto ya se ordenó hace {{days}} días. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Este producto ya se ordenó hace {{hours}} horas. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Este producto ya se ordenó hace {{minutos}} minutos. ¿Todavía quieres continuar?",
    "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Esta oferta de prueba ya está comprada.",
    "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"En este producto, el pedido programado está presente. ¿Aún quieres continuar?",
    "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"¡Actualicé el inquilino con la oferta seleccionada con éxito!",
    "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Ocurrió un error al actualizar el campo de pago instantáneo en el carrito",
    "CATEGORY_DESC_ALL":"Todo",
    "CATEGORY_DESC_AZURE":"Azur",
    "CATEGORY_DESC_AZURE_PLAN":"plan azul",
    "CATEGORY_DESC_BUNDLES":"manojos",
    "CATEGORY_DESC_CUSTOM":"Disfraz",
    "CATEGORY_DESC_CUSTOM_TRIAL":"Prueba personalizada",
    "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
    "CATEGORY_DESC_LICENSE_SUPPORTED":"Licencia compatible",
    "CATEGORY_DESC_NCE":"NCE",
    "CATEGORY_DESC_NON_CSP_AZURE":"Azure sin CSP",
    "CATEGORY_DESC_ONE_TIME":"Una vez",
    "CATEGORY_DESC_ONLINCE_SERVICES":"Servicios en línea",
    "CATEGORY_DESC_ONLINE_SERVICES_NCE":"Servicios en líneaNCE",
    "CATEGORY_DESC_PERPETUAL_SOFTWARE":"software perpetuo",
    "CATEGORY_DESC_RESERVED_INSTANCES":"Rhode Island",
    "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Suscripciones de software",
    "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Licencia compatible",
    "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Software perpetuo",
    "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Instancia reservada",
    "CATEGORY_FILTER_DESC_NCE":"NCE",
    "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"¿Estás seguro, quieres desactivar este cupón?",
    "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"¿Estás seguro, quieres habilitar este cupón?",
    "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Plano de origen",
    "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Plan objetivo",
    "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":" Desasignar planes después de cambiar a un nuevo plan",
    "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Ocurrió un error al cambiar de plan.",
    "CHANGE_PLAN_HELP":" Ayuda",
    "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} no está asignado con el producto secundario derecho {{secondProduct}}",
    "CHANGE_PLAN_INSTRUCTIONS_1":"Siga estas instrucciones para mover los productos del cliente de un plan existente a un plan recién seleccionado.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Plan de origen</b>: enumera los planes asociados con los productos adquiridos del cliente. Seleccione uno o más planes si desea operar en los productos relacionados.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Plan objetivo</b>: seleccione el nuevo plan de precios.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Desasignar planes después de cambiar a un nuevo plan</b>: esto es útil ya que el sistema desasociaría al cliente de un plan existente si no quedan productos en el plan de origen. Ayuda a resolver la confusión de los usuarios de los clientes en la página de la tienda.",
    "CHANGE_PLAN_INSTRUCTIONS_2":"La página se actualiza para enumerar los productos comprados del plan de origen a la izquierda y la opción de asignar a un equivalente del plan de destino, a la derecha.",
    "CHANGE_PLAN_INSTRUCTIONS_3":"Mapee los productos y envíe la solicitud para que el sistema ejecute el cambio.",
    "CHANGE_PLAN_INSTRUCTIONS_4":"Los registros de auditoría lo ayudan con los detalles de la actividad como referencia en un momento posterior.",
    "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Nota: el cambio de precio debido al cambio de plan entraría en vigencia de inmediato.</b>",
    "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Instrucciones para cambiar al plan",
    "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Si tiene que mapear {{firstProduct}}, también debe mapear {{secondProduct}}",
    "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"La cantidad compartida debe coincidir con {{firstProduct}}",
    "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Seleccione los planes de origen que desea cambiar",
    "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Seleccionar plan objetivo",
    "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"No hay productos coincidentes en el plan de destino",
    "CHANGE_PLAN_STATUS_FAILED":"Ha fallado",
    "CHANGE_PLAN_STATUS_SUCCESS":"Éxito",
    "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"El plan cambió con éxito.",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Cambiar a un producto en el plan de destino",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"productos comprados",
    "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":" Ciclo de vida",
    "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Categoría",
    "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":" Producto vinculado",
    "CHANGE_PLAN_TABLE_TEXT_OFFER":"Oferta :",
    "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Plan",
    "CHANGE_PLAN_TABLE_TEXT_PRICE":" Precio",
    "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Proveedor",
    "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Cantidad comprada",
    "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Validez",
    "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Los planes seleccionados tienen una configuración de moneda diferente.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"El producto del plan está en estado de provisión.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Seleccione el producto.",
    "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Se produjo un error al actualizar el estado del producto comprado:",
    "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"¿Estás seguro? ¿Quieres descontinuar esta oferta?",
    "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"¿Estás seguro? ¿Quieres desactivar este plan?",
    "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"¿Estás seguro? ¿Quieres habilitar este plan?",
    "Charge":"Cargar",
    "charity":"Caridad",
    "Charity":"Caridad",
    "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Habilitar todas las promociones",
    "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Elija un ciclo de facturación",
    "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Elija un tipo de facturación",
    "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Elige un tipo de consumo",
    "CHOOSE_AN_OPTION":"Elige una opcion",
    "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Elige Cliente",
    "CHOOSE_INVOICE_PDF_LANGUAGE":"Elija el idioma del PDF de la factura",
    "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"El PDF de la factura estará disponible en el idioma seleccionado.",
    "CHOOSE_ONE_RG":"Elija al menos un grupo de recursos",
    "CHOOSE_RESOURCE_GROUP":"Elija política",
    "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"¿Está seguro de que desea borrar el icono de este producto? El producto obtendría el ícono por su categoría cuando se borrara aquí.",
    "CLICK":"hacer clic",
    "CLICK_TEXT_FOR_EXTERNAL_LINK":"Hacer clic",
    "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Clonar conversión de moneda",
    "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Por favor seleccione una fecha",
    "CLONE_PLAN_FAILURE":"No se pudo clonar el plan",
    "CLONE_PLAN_SUCCESS":"Plan clonado con éxito",
    "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Ahora no",
    "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Ahora no",
    "COLLAPSE_ALL_PAYMENTS":"Colapso de pagos",
    "COMMENT_AUTHOR":"Autor",
    "COMMENTS_LATEST_COMMENT":"Último comentario",
    "commercial":"Comercial",
    "Commercial":"Comercial",
    "COMMISION_REPORT_SEARCH":"Búsqueda",
    "COMMISION_REPORTS":"Informe de la Comisión",
    "Commission Report":"Informe de la Comisión",
    "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Todos los periodos",
    "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar todos los clientes",
    "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar departamento",
    "COMMISSION_REPORT_HEADER_TEXT":"Informe de la Comisión",
    "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Cerca",
    "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Reiniciar",
    "COMMISSION_REPORT_SEARCH_SUBMIT":"Búsqueda",
    "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccionar sitio",
    "COMMISSION_REPORTS":"Informe de la Comisión",
    "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Informe de la Comisión",
    "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Informe de ganancias",
    "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Fecha de finalización de la comisión",
    "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Fecha de inicio de la comisión",
    "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Comisión(%)",
    "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Nombre de dominio",
    "COMMISSION_TABLE_HEADER_ENTITY_ID":"ID de entidad",
    "COMMISSION_TABLE_HEADER_ORDERED_DATE":"fecha ordenada",
    "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"PlanProductId",
    "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agente",
    "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Importe de la comisión",
    "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Cliente",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"Identificación del producto del proveedor",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"Identificación del producto del proveedor",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"ID de suscripción del producto",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Nombre de suscripción del producto",
    "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Nombre del producto de compra",
    "COMMISSION_TABLE_HEADER_RECORD_ID":"ID de registro",
    "COMMISSION_TABLE_HEADER_SP_CODE":"código SP",
    "COMMON_LOADING_MESSAGE":"Cargando ...",
    "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"¿Estás seguro? ¿Quieres aprobar esta factura?",
    "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"¿Estás seguro? ¿Quieres finalizar esta factura?",
    "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Tiene cambios sin guardar. Haga clic en Aceptar para salir sin guardar o haga clic en Cancelar para permanecer en la página.",
    "CONFIRMATION_POPUP_MESSAGE":"¿Estás seguro de que quieres activar esta suscripción?",
    "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"¿Está seguro de que desea cancelar la solicitud relacionada con esta suscripción?",
    "CONFIRMATION_POPUP_TITLE_ACTIVATE":"Activar",
    "CONFIRMATION_POPUP_TITLE_CANCELLED":"Cancelado",
    "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"Al hacer clic en Aprobar, se comprarán los artículos de la línea de cotización. <br/> <b>Nota</b>: No se aplicará el límite de asientos/transacciones.",
    "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"¿Quieres eliminar este ajuste?",
    "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>Al hacer clic en aprobar, el estado de la cotización se actualizará a aprobado.</p></li><li><p style='text-align:left;'>El propietario de la cotización debe realizar manualmente la compra en nombre del cliente.</p></li></ul>",
    "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"¿Desea actualizar el nombre de la suscripción?",
    "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"¿Está seguro de que desea actualizar el número de pedido?",
    "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"¿Está seguro de que desea actualizar el nombre del producto?",
    "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":" ¿Desea actualizar el nombre de la suscripción si se comparte entre entidades secundarias en C3?",
    "CONFIRMATION_TEXT_WANT_TO_CANCEL":"¿Estas seguro que quieres cancelar?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"¿Quieres continuar?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"¿Estás seguro de que quieres continuar con la selección actual?",
    "CONSENT_CREATION_FAILED":"La creación del consentimiento falló",
    "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Habilite esta opción para aplicar tarifas de procesamiento a los pagos con tarjeta de crédito.",
    "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"¿Incluye tarifa de procesamiento de tarjeta de crédito?",
    "CONSTANT_EMPTY_STRING":"--Seleccione--",
    "CONSTANT_EVERY_DAY":"Todos los días",
    "CONSTANT_EVERY_MONTH":"Cada mes",
    "CONSTANT_EVERY_TWO_WEEKS":"Cada dos semanas",
    "CONSTANT_EVERY_WEEK":"Cada semana",
    "CONSTANT_FULL_AUTOMATIC_MAPPING":"Mapeo automático (Obsoleto)",
    "CONSTANT_FULL_MANUAL_MAPPING":"Mapeo manual",
    "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Mapeo manual con creación de servicios",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Monto",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Producto",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Cantidad comprada",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Asiento",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Asientos de cierre",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Diferencia",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Asientos de apertura",
    "CONSUMPTION_TYPES_DESC_CONTRACT":"Contrato",
    "CONSUMPTION_TYPES_DESC_QUANTITY":"Cantidad comprada",
    "CONSUMPTION_TYPES_DESC_SERVICE":"Servicio",
    "CONSUMPTION_TYPES_DESC_USAGE":"Uso",
    "CONTACT_CANCEL":"¿Quieres cancelar?",
    "CONTACT_HEADER_TEXT":"¿Tengo una pregunta?",
    "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Nuestros Contactos",
    "CONTACT_LABEL_TEXT_EMAIL":"Correo electrónico",
    "CONTACT_LABEL_TEXT_MESSAGE":"Mensaje",
    "CONTACT_LABEL_TEXT_NAME":"Nombre",
    "CONTACT_PARAGRAPH_TEXT":"Comuníquese con nosotros completando el siguiente formulario y le responderemos en breve.",
    "CONTACT_SENDFEEDBACK_SUCCESS":"Gracias por brindarnos sus comentarios. Nuestro equipo se pondrá en contacto con usted en breve.",
    "CONTACTCONTROLLER_SENDFEEDBACK":"Tus comentarios se han enviado correctamente.",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Agregar",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Borrar",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Editar",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Seleccione un tipo de facturación",
    "CONTRACT_OFFER_BREADCRUMB":"oferta de contrato",
    "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Fuente en la que se crearía el contrato.",
    "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar esta losa?",
    "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Rango de uso sobre el que se aplicaría el contrato",
    "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"¿Estás seguro de que quieres desactivar esta oferta de contrato?",
    "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Servicio de contrato eliminado de Autotask",
    "COOKIE_CONSENT_MESSAGE":"La aplicación utiliza cookies para brindarte una mejor experiencia. Al proceder a acceder a la aplicación, acepta nuestro uso de cookies.",
    "COOKIE_POLICY_BUTTON_TEXT":"Política de cookies",
    "corporate":"Corporativo",
    "Corporate":"Corporativo",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Reiniciar",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Búsqueda",
    "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Clientes",
    "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"planes",
    "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"revendedores",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Seleccionar clientes",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Seleccionar planes",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Seleccionar revendedores",
    "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filtrar",
    "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Nombre del producto principal",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"precio del proveedor",
    "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Cantidad comprada",
    "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Precio de venta",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"costo para el cliente",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Costo para el socio",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Añadir",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Manojo",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":" Vinculado",
    "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Informe resumido de costos",
    "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"No incorporado",
    "coupon":"Cupón",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"El cupón se aplicará a todos los clientes.",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"El cupón no se aplicará a los clientes aunque estén etiquetados con el plan seleccionado",
    "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"El cupón seleccionado es un cupón directo (sin plan).",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Los clientes solo pueden tener un cupón directo a la vez.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Tenga en cuenta que si algún cliente seleccionado tiene asignaciones de cupones existentes, se eliminarán.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Cupón Directo",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"El cupón seleccionado es un cupón del plan.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"A los clientes etiquetados con este plan solo se les puede asignar este cupón.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Cupón del Plan",
    "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"La asignación de cupón se guardó correctamente",
    "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Cupón",
    "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Clientes",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Activo",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inactivo",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Activo",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inactivo",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Agregar nueva asignación de cupón",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Editar",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Acción",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Asignado el",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Aplicable hasta",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Estado",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"Código de cupón",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Código promocional",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Descripción del cupón",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"nombre del cupón",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Nombre del cupón",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Estado",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Está activo",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Por favor seleccione un cupón válido",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"El cupón seleccionado está disponible para ese cliente.",
    "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Añadir cupón",
    "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Volver a cupones",
    "COUPON_CAPTION_TEXT_ADD":"Añadir cupón",
    "COUPON_CAPTION_TEXT_EDIT":"Editar cupón",
    "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Seleccionar todas las ofertas",
    "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Colapsar",
    "COUPON_DETAILS_TOOLTIP_EXPAND":"Expandir",
    "COUPON_DISABLE_BUTTON":"Desactivar el cupón",
    "COUPON_DISCOUNT_TOOLTIP":"Porcentaje de descuento a aplicar al cupón",
    "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Seleccione un valor de descuento entre 1 y 100.",
    "COUPON_EDIT_BUTTON":"Editar cupón",
    "COUPON_ENABLE_BUTTON":"Habilitar el cupón",
    "COUPON_HEADER_ACTION":"Acción",
    "COUPON_OWNERSHIPS_CATEGORY":"Categoría",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Aplicar a todos los clientes etiquetados",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"Al seleccionar esta casilla de verificación, se asignará el cupón a todos los clientes que estén etiquetados con el plan seleccionado. Sin embargo, puede administrar las asignaciones en la pantalla de asignaciones de cupones.",
    "COUPON_OWNERSHIPS_COUPON_ENTITY":"Aplicabilidad",
    "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Promoción disponible",
    "COUPON_OWNERSHIPS_PLAN":"Plan",
    "COUPON_OWNERSHIPS_PLAN_OFFERS":"Ofertas de planes",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Precio efectivo",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"es seleccionado",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Nombre de la oferta",
    "COUPON_OWNERSHIPS_PROVIDER":" proveedores",
    "COUPON_OWNERSHIPS_SALETYPE":"Tipo de venta",
    "COUPON_PLAN_COUPON_OWNERSHIPS":"Aplicable a",
    "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Debe seleccionar al menos un producto del plan.",
    "COUPON_RECORDS_VALIDATION_MESSAGE":"Debe seleccionar al menos una {{entity}}.",
    "COUPON_SAVE_SUCCESS":"Cupón {{coupon}} guardado correctamente",
    "COUPON_STATUS_FLAG_ACTIVE":"Activo",
    "COUPON_STATUS_FLAG_EXPIRED":"Venció",
    "COUPON_STATUS_FLAG_INACTIVE":"Inactivo",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Código promocional",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Nombre del cupón",
    "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Vence en",
    "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Nombre del plan",
    "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Aplicable a nuevos clientes",
    "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Aplicable a clientes antiguos",
    "COUPON_TABLE_HEADER_TEXT_code":"código",
    "COUPON_TABLE_HEADER_TEXT_CODE":"Código",
    "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Creado en",
    "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Descuento",
    "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Expira el",
    "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Está activo",
    "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"es porcentaje",
    "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"es publico",
    "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Descuento máximo",
    "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Canjes máximos",
    "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Cantidad mínima",
    "COUPON_TABLE_HEADER_TEXT_NAME":"Nombre",
    "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Recurrencias",
    "COUPON_TABLE_HEADER_TEXT_planname":"nombre del plan",
    "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Nombre del plan",
    "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Válida hasta",
    "COUPON_UPDATED_SUCCESS":"Cupón {{coupon}} actualizado correctamente",
    "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"La fecha de vencimiento debe ser mayor que la fecha de validez hasta la fecha.",
    "couponassignment":"Asignación de cupón",
    "coupondetails":"Detalles del cupón",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Agregar nueva asignación de cupón",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Asignaciones de cupones",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Editar asignación de cupón",
    "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Fecha hasta la cual se puede aplicar el cupón al momento de la compra",
    "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Estado del cupón",
    "COUPONS_TAB_HEADING_TEXT_COUPONS":"Cupones",
    "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Fecha hasta la cual el cupón se considera durante la facturación después de su aplicación",
    "couponstatus":"Estado del cupón",
    "CPV_CLIPBOARD_SUCCESS_MESSAGE":"El enlace para iniciar la experiencia de consentimiento se copia en su portapapeles",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_1":" para copiar el enlace que captura el consentimiento del socio para otorgar acceso a esta aplicación al Centro de socios siguiendo el nuevo modelo de seguridad.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Abra el enlace en una sesión de incógnito y complete la experiencia de consentimiento con una cuenta que sea un administrador global en su arrendatario (socio) y tenga asignada la función de agente administrador en el Centro de partners.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Haga clic en el botón Probar para comprobar si la aplicación funciona con el nuevo modelo de seguridad.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en <b>Cambiar al nuevo modelo de seguridad</b> (habilitado después de que la prueba sea exitosa) para permitir que la aplicación borre sus credenciales anteriores y cambie al nuevo modelo de seguridad.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Copiar enlace al portapapeles< /b></a></b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":" Para otorgar acceso a esta aplicación a las API de administración de Azure, este enlace capturará su consentimiento como administrador del cliente.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Después de copiar el enlace, ábrelo en una nueva sesión de incógnito. Luego, proceda a brindar consentimiento para la función de administrador del cliente. Este paso es crucial para que podamos avanzar con el proceso.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Haga clic en el botón Probar para comprobar si la aplicación funciona con las API de administración de Azure.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instrucciones para cambiar al nuevo modelo de seguridad",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Instrucción para proporcionar consentimiento de administrador a la API de administración de Azure",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Instrucción para proporcionar consentimiento de administrador del cliente por parte del usuario del cliente",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"INSTRUCCIONES",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Copiar enlace al portapapeles</a> </b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" para copiar el vínculo que captura el consentimiento del administrador del cliente para otorgar acceso a esta aplicación a las API de administración de Azure.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Una vez que haya copiado el enlace, compártalo con el cliente a través de su canal de comunicación preferido, como el correo electrónico. Luego, solicite su aprobación para el rol de administrador de clientes.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Haga clic en el botón Probar para comprobar si la aplicación funciona con las API de administración de Azure.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"puede informar a su cliente que inicie sesión en el portal C3 -> perfil -> Microsoft En la sección proporcione el consentimiento necesario. Obtener el consentimiento del cliente es fundamental para que podamos seguir adelante.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Aquí hay una lista de informes que se pueden preparar y ejecutar en el momento programado.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Utilice el icono Destinatarios para agregar los destinatarios al informe.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"También puede verificar la cantidad de veces que se ejecutó el informe en el pasado y quién lo recibió.",
    "CPV_LOADING_MESSAGE":"Espere mientras procesamos su consentimiento...",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Gracias por brindar su consentimiento. La aplicación se puede configurar para acceder al Centro de socios siguiendo la nueva pauta de seguridad.",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones en Configuración -> Centro de socios en <b>{{applicationName}}</b>.",
    "CPV_PC_TEST_CONFIRMATION_MESSAGE":"El intento de acceder al Centro de socios utilizando la nueva configuración de seguridad es exitoso.",
    "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"El intento de acceder a la API de administración de Microsoft Azure se realizó correctamente.",
    "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Número de tarjeta",
    "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
    "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Mes",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"04 abril 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"08 agosto 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"12 diciembre 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"02 febrero 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"01 enero 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"07 julio 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"06 junio 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"03 marzo 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"05 mayo 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"11 noviembre 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10 octubre 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"09 septiembre 2024",
    "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Nombre en la tarjeta",
    "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Año",
    "CREATE_BANNER_NOTIFICATIONS":" Crear notificación de banner",
    "CREATE_COUPON_ASSIGNMENT_FAILURE":"No se pudo crear la asignación de cupón",
    "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupón creada exitosamente",
    "CREATE_CUSTOMER_EXCEPTION":"Se produjo un error al crear el cliente.",
    "CREATE_CUSTOMER_USER_ROLE":"Crear usuarios de clientes",
    "CREATE_CUSTOMER_USER_ROLE_FAILURE":"No se pudieron crear usuarios de clientes",
    "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Crear factura",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Crear cliente proveedor bajo revendedor lógico",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Permitir que el revendedor lógico cree un cliente proveedor",
    "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Las ofertas de uso añadidas al plan de revendedor serán del tipo de facturación <b>Marcado</b>. Defina el margen de beneficio para las ofertas de uso una vez que el plan se haya creado correctamente.",
    "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Administrador de cuenta creado con éxito",
    "CREATED_SUCCESSFULLY":"se crea exitosamente",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Parece que falta el detalle del ID de MPN para el revendedor. Comuníquese con el socio para actualizar los detalles.",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"¡No se admite la creación de un nuevo arrendatario de proveedor de servicios!",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"El cliente se creará en el centro de socios en la cuenta de socio.",
    "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Porcentaje de cargo por procesamiento de tarjetas de crédito.",
    "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Tarifa de procesamiento de tarjetas de crédito %",
    "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Ingrese al menos un nombre de impuesto y un porcentaje para los impuestos acumulativos.",
    "CURRENCY_CONVERSION_ADD_SUCCESS":"Regla de conversión de moneda agregada exitosamente",
    "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Clonar moneda",
    "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Volver a conversión de moneda",
    "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Agregar",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Conversión de Moneda",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"conversión de moneda enviada con éxito",
    "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Editar",
    "CURRENCY_CONVERSION_DELETE_SUCCESS":"La regla de conversión de moneda se eliminó correctamente",
    "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Mes de facturación actual",
    "CURRENCY_CONVERSION_LABEL_DEFAULT":"Todo",
    "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Último mes de facturación",
    "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Próximo mes de facturación",
    "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Selecciona una fecha",
    "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Tasa de conversión",
    "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Cliente",
    "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Moneda de origen",
    "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Moneda objetivo",
    "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Periodo de tiempo",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Tasa de conversión",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Cliente",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"Válido desde",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Moneda de origen",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Estado",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Moneda objetivo",
    "CURRENCY_CONVERSION_RATE_SUCCESS":"Introduzca una tasa de conversión válida.",
    "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"La regla de conversión de moneda se creó correctamente.",
    "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Regla de conversión de moneda actualizada exitosamente",
    "CurrencyCode":"Código de moneda",
    "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Icono actual",
    "CURRENT_PARTNER_PRODUCT_ICON":"Icono actual",
    "CURRENT_SUBSCRIPTION_DETAILS":"Detalles de la suscripción actual",
    "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Los detalles de uso aún no están disponibles, inténtelo después de un tiempo.",
    "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Cantidad comprada",
    "Custom Notifications":"Notificaciones personalizadas",
    "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Si elige programar un pedido para una fecha futura y ha especificado una fecha de finalización personalizada, tenga en cuenta que la fecha de finalización personalizada se restablecerá automáticamente a la predeterminada después de seleccionar su pedido programado para el futuro.",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOM_ICON_HELP_TEXT":"Tamaño recomendado: 150 x 175. Solo se admiten archivos de tipo jpg, svg, png, gif, bmp",
    "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"El nombre del elemento de línea debe tener menos de 150 caracteres.",
    "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Agregar entidades etiquetadas",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Cuerpo de texto",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"Válido desde",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Efectivo para",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"ID de notificación",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Plantilla",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Título",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Ofrecer descuentos para su tienda en línea puede ser un arma poderosa para generar lealtad.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Impulsar el marketing y confianza del producto de venta.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Ofrecer descuentos para mejorar en línea una tienda puede fidelizar armas en la conducción.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Comience con un diseño de sitio moderno y personalícelo con su contenido de marca y características. Todos los blogs Premium incluyen CSS personalizado.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Crear notificación personalizada",
    "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Crear notificación personalizada",
    "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Cancelar",
    "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Cerrar filtros",
    "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Nombre del plan",
    "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Introduce la descripción",
    "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Seleccionar planes",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Elija la fecha de vigencia a partir",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Elija efectivo hasta la fecha",
    "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Fecha final",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Asignar licencia de usuario",
    "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Cambiar el estado del producto del cliente",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Gestionar la propiedad del producto",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Administrar licencia de usuario",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Cliente a bordo",
    "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Comprar productos",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Disminución de cantidad",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Aumento de cantidad",
    "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Quitar licencia de usuario",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Actualizar licencia",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Suscripción de actualización",
    "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Se produjo un error durante los eventos de notificación personalizados",
    "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Comportamiento",
    "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Descripción",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"Válido desde",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Efectivo para",
    "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Entidades etiquetadas",
    "CUSTOM_NOTIFICATION_HEADER_TITLE":"Título",
    "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Descripción",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"Válido desde",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Efectivo para",
    "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Entidad",
    "CUSTOM_NOTIFICATION_LABEL_EVENT":"Evento",
    "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Plantilla",
    "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Título",
    "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Cerca",
    "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Notificación personalizada",
    "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
    "CUSTOM_NOTIFICATION_RESET_OPTION":"Reiniciar",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"Entregar",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación personalizada guardada correctamente",
    "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Seleccione",
    "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Búsqueda",
    "CUSTOM_NOTIFICATION_SELECT":"Seleccione",
    "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Seleccionar productos",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Tipos de consumo",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"proveedores",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
    "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Fecha de inicio",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Entidades etiquetadas",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Elementos de entidad etiquetados",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Comportamiento",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Elementos",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Entidad",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Evento",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Ver entidades etiquetadas",
    "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Elegir la plantilla",
    "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Esto excede la longitud máxima de 30",
    "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Empieza aqui",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Únase a SAP ahora para obtener un 35% de descuento",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Confianza del usuario",
    "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Seleccionar título",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Actualizar",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación personalizada actualizada correctamente",
    "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"Notificación de interfaz de usuario",
    "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"¿Es oferta de prueba?",
    "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"¿Es auto renovable?",
    "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Cantidad predeterminada",
    "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Productos para oferta de prueba",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Categoría",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Tipo de consumo",
    "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Duración del término",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Categoría",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Proveedor",
    "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Ofertas de socios",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Nueva oferta de contrato",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Nueva oferta de socios",
    "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Volver a ofertas de socios",
    "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Aplicabilidad",
    "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Añadir ofertas de contrato",
    "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Ofertas de contrato",
    "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ofertas de socios",
    "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Editar",
    "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"Duración del ensayo",
    "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Seleccione 'ChargePeriods' como 'Predeterminado', 'Regla de facturación para la compra de asientos' y 'Regla de facturación para la reducción de asientos' como 'Prorrateada'",
    "CUSTOM_OFFERS_HELP_TEXT_HEADER":"A continuación se detallan algunas cosas que debe tener en cuenta al agregar una oferta de socio a una oferta compuesta",
    "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Cantidad de aprobación",
    "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"¿Es autorenovable?",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
    "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Categoría",
    "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Tipo de período de facturación",
    "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
    "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Precio de coste",
    "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Fuente de alimentación",
    "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
    "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Instrucciones",
    "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"¿La oferta es un complemento?",
    "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Nombre",
    "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Número de días para cancelación gratuita",
    "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"ID de referencia de oferta",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
    "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icono",
    "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Propiedades",
    "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Proveedor",
    "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Precio de venta",
    "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
    "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Validez",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Categoría",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Lo que quieres como período de facturación de las suscripciones.",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"¿Qué tipo de Consumo quieres que tengan las suscripciones?",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Fuente de alimentación",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Número de días máximos permitidos para cancelación sin coste.",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad deben prorratearse o cobrarse por completo",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Proveedor",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Tipo de venta",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Losa",
    "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validez de la oferta.",
    "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Seleccione una imagen",
    "CUSTOM_OFFERS_SAVE_SUCCESS":"Oferta de socio {{customoffer}} guardada correctamente",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Incluir ofertas eliminadas",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Ofertas de prueba",
    "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Seleccionar ciclo de facturación",
    "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Seleccione la duración del término",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Precio de coste",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Detalles de la oferta",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Ir a la pantalla de etiquetas de productos",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Vista",
    "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(eliminado)",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Este es el precio al que obtuvo este componente. Puede especificar el precio de venta para esta oferta cuando la incluye en un plan.",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Este es el precio al que vende este componente.",
    "CUSTOM_OFFERS_UPDATE_SUCCESS":"Oferta de socio {{customoffer}} actualizada correctamente",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Las fechas de inicio y finalización del cargo serán las fechas de inicio y finalización de un mes.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Las fechas de inicio y finalización del cargo se alinearán con las fechas de inicio y finalización de la facturación de MS.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Las fechas de inicio y finalización del cargo se alinearán con las fechas de inicio y finalización de la facturación del socio.",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Alinear con el mes del calendario",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"Alinearse con la facturación de MS",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"Alinearse con la facturación de socios",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"Alinearse con la facturación del proveedor",
    "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Predeterminado (desde la fecha de compra)",
    "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Establecer nueva contraseña",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"¿Está seguro de que desea restablecer la contraseña?",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Se generará una nueva contraseña y la contraseña actual del usuario ya no funcionará.",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Ingrese nueva clave",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Mostrar contraseña",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Generar contraseña automáticamente",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Contraseña personalizada",
    "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Mínimo 8 caracteres. Debe contener al menos un carácter en mayúscula, un carácter en minúscula, un carácter especial y un valor numérico.",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Elija una contraseña con al menos 8 caracteres.",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"La contraseña personalizada no debe contener el nombre del usuario.",
    "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Política activada exitosamente",
    "CUSTOM_POLICY_ADD_TITLE":"Agregar política personalizada",
    "CUSTOM_POLICY_ASSIGNED_POLICIES":"Políticas asignadas",
    "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Política asignada exitosamente",
    "CUSTOM_POLICY_ASSIGNMENT_ADD":"Asignar política",
    "CUSTOM_POLICY_AZURE_POLICY_ID":"Id. de política de Azure",
    "CUSTOM_POLICY_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Política desactivada exitosamente",
    "CUSTOM_POLICY_DESCRIPTION":"Descripción",
    "CUSTOM_POLICY_INSTRUCTIONS":"Para crear una política personalizada, siga los pasos a continuación",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP1":" Proporcione un nombre y una descripción para la política personalizada.",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Proporcionar una definición adecuada",
    "CUSTOM_POLICY_LABEL_SCOPE":"Alcance",
    "CUSTOM_POLICY_NAME":"Nombre",
    "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Asignación de políticas",
    "CUSTOM_POLICY_POLICYDEFINITION":"Definición",
    "CUSTOM_POLICY_RESOURCEGROUPNAME":"Nombre del grupo de recursos",
    "CUSTOM_POLICY_REVOKE_SUCCESS":"Política revocada exitosamente",
    "CUSTOM_POLICY_SAVE_SUCCESS":"Política {{policy}} guardada correctamente",
    "CUSTOM_POLICY_SUBSCRIPTION":"Nombre de suscripción",
    "CUSTOM_POLICY_TITLE":"Políticas personalizadas",
    "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Para asignar la política al nivel de suscripción, seleccione todos los grupos de recursos.",
    "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rol activado exitosamente",
    "CUSTOM_ROLE_AZURE_ROLE_ID":"ID de rol de Azure",
    "CUSTOM_ROLE_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rol desactivado exitosamente",
    "CUSTOM_ROLE_DESCRIPTION":"Descripción",
    "CUSTOM_ROLE_INSTRUCTIONS":"Para crear un rol personalizado, siga los pasos a continuación",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP1":" Proporcione un nombre y una descripción para el rol personalizado.",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Ingrese solo las secciones \"Acción\" y \"No acción\" para la definición de roles.",
    "CUSTOM_ROLE_NAME":"Nombre",
    "CUSTOM_ROLE_RESOURCEGROUPNAME":"Nombre del grupo de recursos",
    "CUSTOM_ROLE_ROLEDEFINITION":"Definición de roles",
    "CUSTOM_ROLE_SAVE_SUCCESS":"Rol {{role}} guardado correctamente",
    "CUSTOM_ROLE_SUBSCRIPTION":"Nombre de suscripción",
    "Customer":"Cliente",
    "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"está sujeto a impuestos",
    "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Actualización de la compra",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Categoría",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Precio por semilla",
    "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Complementos",
    "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Yo/autorizamos a Ezidebit Pty Ltd ACN 096 902 813 (ID de usuario No 165969, 303909, 301203, 234040, 234072, 428198) a debitar mi/nuestra cuenta en la Institución Financiera identificada anteriormente a través del Sistema de Compensación Electrónica Masiva (BECS), en de acuerdo con esta Solicitud de Débito Directo y según el Acuerdo de Servicio DDR de Ezidebit. <br> Yo/nosotros autorizamos que estos pagos se debiten en intervalos y montos según lo indique BeCloudSmart según los Términos y condiciones del acuerdo BeCloudSmart y acuerdos posteriores.",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Convenio",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Acuerdo Ezidebit",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Acuerdo de revendedor de la nube de Microsoft",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Condiciones de uso para socios",
    "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Leer Acuerdo Ezidebit",
    "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Antes de utilizar el sistema, lea el",
    "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Pago registrado exitosamente.",
    "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"El proceso de incorporación de los datos de facturación del cliente está en curso.",
    "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Continuar a bordo",
    "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Los detalles de facturación del cliente se incorporaron correctamente.",
    "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"El cliente ya existe en el sistema de facturación.",
    "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Encontré una coincidencia en el ID: <strong> {{billingProviderReferenceID}} </strong> en el sistema de facturación.<br/><span>La dirección de facturación registrada es <strong> {{formattedAddress}} < /strong>.</span> <br/>Suplante su identidad y utilice la opción <strong>Perfil</strong> para cambiar este detalle después de que los detalles de facturación del cliente se hayan incorporado correctamente.",
    "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Facturación Mensual...",
    "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Error",
    "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"En curso",
    "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Éxito",
    "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Leer más",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Recargar el consentimiento del cliente",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Informe de red de recarga",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Sincronización de perfiles de clientes de proveedores",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Iniciando recarga",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Partidas de factura",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Partidas de factura...",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Elementos de línea de factura con comisiones",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Partidas de factura con comisiones...",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Resumen de factura",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Resumen de factura...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Informe de Rentabilidad",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Informe de Rentabilidad por Producto",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Informe de Rentabilidad por Producto...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Informe de rentabilidad por revendedor",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Informe de Rentabilidad por Inquilino",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Informe de Rentabilidad por Inquilino",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Informe de Rentabilidad...",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Partidas de impuestos",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Partidas de línea de impuestos...",
    "CUSTOMER_BUTTON_TAX_SUMMARY":"Resumen de impuestos",
    "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Resumen de impuestos...",
    "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Informe de consentimiento",
    "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Descargar informe del cliente",
    "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Exportar informe de conciliación",
    "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Licencia de usuario de exportación",
    "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Recargar el consentimiento del cliente",
    "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Perfiles de clientes de proveedores de sincronización",
    "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Recargar informe de conciliación",
    "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Informe de términos y condiciones",
    "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Informe de límite de monto de transacción",
    "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Las nuevas compras están deshabilitadas a través de la plataforma. Póngase en contacto con su administrador de cuenta para realizar un pedido.",
    "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Póngase en contacto con su gerente de cuenta para comprar un nuevo producto",
    "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Las nuevas compras y los cambios en el recuento de licencias están actualmente en espera. Póngase en contacto con su administrador de cuenta para obtener más información.",
    "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Remover",
    "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Carrito del cliente",
    "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Seguir comprando",
    "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"estoy listo para pagar",
    "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Pagar ahora",
    "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Realizar pedido",
    "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
    "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Verifique la fecha de finalización personalizada en los artículos de la línea del carrito, antes de proceder a la compra.",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Alinear la fecha de finalización con el mes calendario",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Por defecto",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Fecha de finalización de las suscripciones existentes",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Fecha de finalización de las suscripciones existentes",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"ID de suscripción",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Validez",
    "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"promocion aplicada",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Cantidad pagable",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":" Divisa:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Nombre del impuesto:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Coste total",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":" Cuenta total:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Descuento total",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Total impuestos:",
    "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Resumen del pedido",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Ya hay una oferta de contrato en el carrito. Elimine la oferta de contrato existente para agregar una nueva.",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Ya tienes una oferta de contrato comprada. Elimine la oferta de contrato existente para comprar una nueva.",
    "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Ya se envió un pedido de este producto al proveedor. Póngase en contacto con su administrador de cuenta para obtener más información.",
    "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Este artículo requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El número de asientos que se compran supera el límite permitido de {{SeatLimit}}.",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Este artículo requiere la aprobación para su cumplimiento por parte del socio después de realizar el pedido.",
    "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Seleccione un sitio/departamento",
    "CUSTOMER_CART_SUP_TEXT_ADD_ON":"AÑADIR",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Su inquilino no es elegible para comprar Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3.",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Su inquilino no es elegible para comprar el Plan Azure. Posibles motivos: ya tiene un plan de Azure y aún no está sincronizado con C3 o hay una suscripción de Azure activa en el Centro de partners y necesita una actualización o tiene más de un elemento de línea del carro del plan de Azure en el mismo inquilino.",
    "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Este pedido requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El monto de la transacción supera el límite permitido de <b> {{TransactionAmountLimit}}</b>.",
    "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
    "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Mostrar impuestos en el carrito del cliente",
    "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Los números de factura comenzarán con este texto",
    "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texto del número de factura",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permite al usuario ver las cuotas impagas hasta la factura actual.",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Mostrar cuotas impagas en la factura",
    "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Entidad Operativa",
    "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"¿Está seguro de que desea {{Consent}} el consentimiento de renovación para {{ProductName}}?",
    "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Su consentimiento se acepta con éxito",
    "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Agregar",
    "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Actualizar",
    "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Consentimiento del cliente",
    "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"URL del acuerdo",
    "CUSTOMER_CONSENT_HELP_TEXT":"Especifique los detalles de la persona de la organización del cliente que aceptó el <a href='#' ng-click='vm.CustomerConsentClick()'>Acuerdo de cliente de Microsoft.</a>",
    "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Apellido",
    "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Número de teléfono",
    "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Haga clic <a id='customerConsentLink' href='#' ng-click='vm.MicrosoftCloudAgreementClicked()'> aquí </a>para leer el Acuerdo de cliente de Microsoft.",
    "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Acuerdo de cliente de Microsoft",
    "CUSTOMER_CONSENT_REJECT_POPUP":"¿Está seguro de que desea rechazar el<br>{{ProductName}}?",
    "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Su consentimiento es rechazado exitosamente",
    "CUSTOMER_CONSENT_SAVED_MESSAGE":"Su consentimiento se guarda con éxito",
    "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Consentimiento de renovación de oferta de socio",
    "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Error en el consentimiento de renovación de oferta de socio",
    "CUSTOMER_CONSENT_TEXT_DATE":"Fecha",
    "CUSTOMER_CONSENT_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_CONSENT_TEXT_IP":"IP",
    "CUSTOMER_CONSENT_TEXT_TIME":"Tiempo",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Vaya al menú de perfil y actualice el perfil de pago",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Por favor complete el perfil de pago",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Vínculo rápido a Azure Portal",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Portal azur",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Número de productos comprados hasta el momento",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Productos",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Número de usuarios que tienen acceso a su información",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Usuarios",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Alerta sobre una factura pendiente",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Factura",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Enlace rápido al portal de Office 365",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Portal de oficina",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Enlace rápido a Informes de uso de Office 365",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Informes de uso de Office 365",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Los 10 SKU principales",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Recuentos de productos por SKU",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Cuenta por SKU",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Rango de filtro",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 meses",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 meses",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 año",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Compra de Asientos",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Compra de Asientos",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Compra de Asientos",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Asientos comprados",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Asientos del cliente",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Asientos de clientes",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Registro de aceptación de términos y condiciones",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Términos y condiciones",
    "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Asientos",
    "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Partida de factura eliminada correctamente",
    "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Agregar departamento",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Departamento eliminado exitosamente",
    "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Editar departamento",
    "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Departamentos",
    "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Departamento guardado exitosamente",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Sitios asignados",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Sitio",
    "CUSTOMER_EXPORTINVOICE_WARNING":"La factura para {{customername}} aún no se ha generado. Vuelve a consultar después de un tiempo.",
    "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Proveedores de recarga Consentimiento del cliente",
    "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Sincronización de perfiles de clientes de proveedores",
    "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Texto generado de factura de cliente",
    "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Volver a Facturas",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Agregar elemento de línea",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Cancelar",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Guardar factura",
    "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"¿Está seguro de que desea deshacer los cambios?",
    "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"La fecha de inicio del cargo de los elementos de línea no debe ser posterior a la fecha de finalización del cargo",
    "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"¿Está seguro de que desea eliminar la línea de pedido?",
    "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"La fecha de vencimiento de la factura debe ser posterior a la fecha de la factura",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Resumen de factura",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Artículos de línea",
    "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Los impuestos se aplicarán después de guardar la factura.",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Período de facturación",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Monto de la factura",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Fecha de la factura",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Fecha de vencimiento",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Número de factura",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Subtotal",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Dirección",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Ciudad",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"País",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Seleccionar país",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"entidad operativa",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Estado",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Cremallera",
    "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Proporcione al menos un elemento de línea para guardar la factura",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Fecha final",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Fecha de inicio",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"es imponible",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Nombre del árticulo",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"monto de la línea",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Tipo de venta",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Impuesto de venta",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Crédito",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Cargos adicionales",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":" Monto del ajuste",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Detalle de ajuste",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Nombre del proveedor",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Volver a factura",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Agregar",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Ajustamiento",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Editar",
    "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Facturas",
    "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
    "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Crear factura",
    "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Facturas",
    "CUSTOMER_LABLE_TEXT_TAG_KEY":" Etiqueta de cliente",
    "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Valor",
    "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Se envía informe de Licencia de Cliente al correo respectivo",
    "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"filtros",
    "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Administrar producto",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Volver al plan azul",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Nombre del grupo",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Umbrales",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Agregar suscripción de Azure",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"No se encontraron derechos",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"Ahorrar",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"El propietario aún no ha sido asignado",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Nombre",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Dueño",
    "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Roles y funciones El contenido de las políticas va aquí....",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Facturación",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Comentarios",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Derechos",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Estimar",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Grupos",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Información",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Administrar propietario",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Entidades Operativas",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Pagos",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Funciones y políticas",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Gestionar renovación",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Uso",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Usuarios",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"Grupo de recursos",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Suscripción",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Complementos",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Seleccionar código de mercado",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Descarga un .csv de muestra",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Cargue datos de cuenta para varios usuarios en formato .csv. Descargue un archivo .csv de muestra",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"archivo de referencia.",
    "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Informe de facturación",
    "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Por favor ingrese el nuevo nombre",
    "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Cambiar nombre de cliente",
    "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Nombre",
    "CUSTOMER_NAME_CHANGE_SUCCESS":"El nombre del cliente cambió exitosamente",
    "CUSTOMER_NAME_UPDATE_FAILURE":"Error al actualizar el nombre del cliente",
    "CUSTOMER_NAME_UPDATE_SUCCESS":"Actualización exitosa del nombre del cliente",
    "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"No se ha asignado ningún administrador de cuenta actualmente",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"No está vinculado a ningún inquilino de Microsoft.",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"La compra de productos del proveedor puede fallar debido a que no hay inquilinos del proveedor vinculados",
    "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Asignar",
    "CUSTOMER_ONB_STATUS_DESC_CREATED":"Creado",
    "CUSTOMER_ONB_STATUS_DESC_DELETED":"Eliminado",
    "CUSTOMER_ONB_STATUS_DESC_ERROR":"Error",
    "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"Incorporado",
    "CUSTOMER_ONBOARDING_BILLANDPAY_":"¡Busca en Facturación!",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Siga estos pasos para obtener el ID del sistema de facturación de un cliente",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Inicie sesión en el sistema de facturación",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Ir a Clientes",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Haga clic en el registro del cliente para abrir",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Coge la cadena que está después de recordkey",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":" Tome la cadena que está después de recordkey= en la URL",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"p.ej:",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"valor a copiar",
    "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Inicie sesión en Authorize.Net",
    "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":" Tome la identificación del perfil del Administrador de información del cliente",
    "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":" Root para el Administrador de información del cliente: Inicio->Administrador de información del cliente",
    "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":" Toma la cadena de la URL.",
    "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"ID del sistema de facturación",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Incorporación masiva de clientes",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Incorporación de clientes",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Recopilar datos del cliente",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Hecho",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"aquí",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"¡Buscar!",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Proceder a incorporar a este cliente",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Remover",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Saltar",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Prueba otra",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Incorporación masiva de clientes",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Incorporación de clientes",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Licencias de mapas",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Éxito a bordo",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Tipo de pago",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Por favor seleccione Tipo de Pago",
    "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"La incorporación no está completa. Haga clic en el botón \"Continuar con la incorporación de este cliente\" para continuar.",
    "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"Por favor ingrese su dirección de correo electrónico válida",
    "CUSTOMER_ONBOARDING_EZIDEBIT_":"¡Busca en Facturación!",
    "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"ID de débito Ezi",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Siga estos pasos para obtener el ID de EziDebit de un cliente",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Inicie sesión en EziDebit",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Ir a Pagadores",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Haga clic en el registro del pagador para abrir",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Toma la cadena que está después de contractId",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":" Tome la cadena que está después de contractId= en la URL",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"p.ej:",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"valor a copiar",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Clientes disponibles para agregar desde el Centro de socios",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"se crea exitosamente",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"incorporado exitosamente",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Próximos pasos",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Asignar plan",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Nombre del banco",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Ciudad",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Código de moneda del cliente",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Identificación del cliente",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"¿Se puede compartir el producto?",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Apellido",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Línea 1",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Línea 2",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Identificación de Microsoft",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Identificador único de Microsoft asignado a una organización.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Mes",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Siguiente Fecha de facturación",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"NOTA:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"No actualice ningún contenido del archivo que no sean los detalles del usuario como se muestra en el ejemplo anterior.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"opcional:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Si ya tiene los detalles sobre las licencias asignadas a los usuarios según su suscripción, puede proporcionárnoslos siguiendo estos pasos:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Complete la información en el formato que está disponible para descargar desde",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Haga clic en Usuarios y otorgue acceso a este portal a los usuarios del inquilino del cliente.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Suplantar y validar el detalle de la suscripción",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Corrija los errores volviendo a cargar un archivo actualizado con detalles de la licencia para completar el proceso de incorporación.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Simplemente proporcione las direcciones de correo electrónico del usuario entre corchetes ([]) siguiendo este ejemplo:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Sube el archivo y haz clic",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Puede omitir proporcionar los detalles de la licencia haciendo clic en OMITIR para completar el proceso de incorporación.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Haga clic en Listo para volver a la lista de clientes",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Localice al cliente que acaba de incorporarse",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Aparecerá un botón de acción <i class=\"fa fa-star red\"></i> en caso de que haya errores al procesar los detalles de la licencia que cargó.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Haga clic en el botón <i class=\"fa fa-star red\"></i> para ver los errores",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Corrija los errores e intente cargar nuevamente.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Selecciona un plan",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Proveedor",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Categoría de proveedor",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Estado",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Inquilinos",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Subir archivo",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Seleccione",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Año",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Cremallera",
    "CUSTOMER_ONBOARDING_LBL_DELETED":"Eliminado",
    "CUSTOMER_ONBOARDING_LBL_DISABLED":"Desactivado",
    "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Venció",
    "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendido",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"No hay ningún plan asignado al cliente. Cree un nuevo plan, asígnelo al cliente y luego corrija la discrepancia.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Para los productos de instancias reservadas, consideramos el precio de venta en porcentajes, por lo que el valor predeterminado es 0% de porcentaje. Si desea proporcionar otro valor, modifique el precio de venta.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Nombre del plan",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Código de moneda",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Precio de venta (en porcentaje)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta (en porcentaje)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Este valor se aplicará al precio de facturación de Microsoft para generar la factura correspondiente.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Por favor ingrese el valor hasta dos decimales.",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"planes",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Proveedor",
    "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Proceda a incorporar a este cliente",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"CCA",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manual",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"No",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Sí",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Buscar suscripciones de clientes y mapas",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Licencias de mapas",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Completo",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Completo",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"logo",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":" Ciclo de facturación :",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Cantidad del sistema de facturación",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Nombre de empresa",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"ID de Microsoft",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Dominio",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Precio efectivo",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Margen",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Productos a juego",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Cantidad de productos a juego",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Porcentaje",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":" Precio",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Nombre del producto del proveedor",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Cantidad de proveedor",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"No se encontraron suscripciones de productos de proveedores para asignar; puede continuar haciendo clic en el botón Recopilar detalles del cliente.",
    "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"COSTUMBRE",
    "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Puede cambiar a otros métodos de pago a través de la opción Perfil. El socio le envía las facturas por correo electrónico",
    "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Las suscripciones de uso se cargaron correctamente.",
    "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Cargando....",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Desactivar",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Editar",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Permitir",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Administrar",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Direcciones",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Tipo de entidad",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Apellido",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nombre",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Role",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Perfil",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Usuarios",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Añadir nuevo usuario",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Dirección de Envio",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"editar usuario",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Dirección de envío",
    "CUSTOMER_OPTION_ALL_PERIODS":"Todos los periodos",
    "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Términos y condiciones.",
    "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Seleccione la casilla de verificación de los términos y condiciones de NCE para pedir productos de Microsoft NCE.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Seleccione la casilla de verificación de términos y condiciones para solicitar productos de Microsoft.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":" <a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">Términos y condiciones de NCE.</a>",
    "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"El monto a pagar debe ser la suma del producto más el impuesto total.",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Reintente el pago",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Mirar la factura",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Ver productos",
    "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Volver a Pedidos",
    "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"AÑADIR",
    "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"filtros",
    "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Nueva compra",
    "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Actualización de cantidad",
    "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Cambio de estado",
    "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Pedidos de los clientes",
    "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Ver detalles",
    "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
    "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
    "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Estado",
    "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Pedidos de los clientes",
    "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"En espera",
    "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Comportamiento",
    "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Operación",
    "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Nombre de Departamento",
    "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Operación",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Solicitar ID",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"Hasta la fecha",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"Partir de la fecha",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"funcionamiento de la orden",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"estado del pedido",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Ordenado por",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"pedido el",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"pedidos de",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Pedidos a",
    "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Nombre del sitio",
    "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Estado",
    "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Última actualización",
    "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Leer más",
    "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Volver a pagos de clientes",
    "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Facturas pendientes de",
    "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Seleccionar identificador único",
    "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"volver a la lista",
    "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Código de cupón actual",
    "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong>¡Error!</strong> No se puede mover al cliente a <strong>{{targetPlanName}}</strong> desde <strong>{{PlanName}}</strong> porque el plan objetivo no tiene ofertas que coincidan con las suscripciones actualmente activas.",
    "CUSTOMER_PLAN_CHANGE_INFO":"El cambio de plan aún no se admite si hay al menos una suscripción activa con una oferta compuesta en el plan actual.",
    "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Plan actual",
    "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Suscripciones de clientes",
    "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Precio efectivo",
    "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Coincidencia encontrada",
    "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Nombre de destino",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Precio objetivo",
    "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Este cupón ya está asignado al cliente.",
    "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"planes",
    "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong>¡Éxito!</strong> El cliente puede ser trasladado a <strong>{{targetPlanName}}</strong> desde <strong>{{PlanName}}</strong>. Haga clic en <strong>Enviar</strong> para continuar.",
    "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Plan objetivo",
    "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Utilice el código de cupón actual",
    "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(eliminado)",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
    "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Cerca",
    "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Complementos",
    "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Complementos",
    "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Cambiar",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Reactivar suscripción",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Reactivar suscripción con complementos",
    "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Suspender suscripción",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
    "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Complementos",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Manojo",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Entidad Operativa",
    "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Añadir más vales",
    "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"Entregar",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Continuar suscripción</b> <br/> ¿Está seguro de que desea continuar con la suscripción <b>{{productName}}</b> después de la prueba?",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" La oferta de prueba {{productName}} se convertirá en una suscripción una vez que se alcance la fecha de finalización efectiva del proveedor.",
    "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"¿Está seguro de que desea cambiar el ciclo de facturación de {{billingCycle}} a {{c3billingCycle}}?<br> Después de generar la factura, el ciclo de facturación no se puede revertir.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Cancelar suscripción</b> <br/> ¿Estás seguro de que deseas cancelar tu suscripción de prueba de <b>{{productName}}</b>?",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Se produjo un error al cancelar la suscripción a {{productName}}.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"¡Oferta de prueba cancelada para {{productName}} exitosamente!",
    "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"apagado",
    "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorar",
    "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Error de detalles",
    "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Cerca",
    "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Productos del cliente",
    "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Periodo de prueba",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Introduzca valores separados por comas.",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Instrucciones",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Perpetuo",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"software perpetuo",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Suscripciones de software",
    "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Confirmar actualización de nombre en C3",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar el ciclo de facturación de este producto a {{targetBillingCycle}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar la Oferta de este producto a {{targetOffer}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar el alcance de este producto a {{targetScope}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"¿Está seguro de que desea cambiar la duración del plazo de este producto a {{targetTermDuration}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea reactivar {{productName}} {{withAddons? 'con complementos': '}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea suspender {{productName}}?",
    "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Falta la suscripción principal para {{Name}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Cambió el nombre con éxito",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"Cambió el número de pedido con éxito",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Se cambió el ciclo de facturación de {{productName}} a {{targetBillingCycle}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Cantidad de producto no válida",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"No se puede actualizar la cantidad a cero para un producto ya comprado.",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"La solicitud de suscripción de actualización se ha enviado correctamente",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Solicitud para reactivar {{productName}} {{withAddons? 'con complementos': ''}} se ha enviado correctamente",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"¿Se reactivó {{productName}} con éxito {{withAddons? 'con complementos': '}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Propiedad liberada exitosamente",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"La solicitud para suspender el producto, {{productName}} se ha enviado correctamente",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"Suspendido {{productName}} exitosamente",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Auto renovación",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"se renueva el",
    "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Cancelación permitida hasta",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"Cambiar el ciclo de facturación actualizará todas las suscripciones que forman parte del \"pedido\". Esto creará un nuevo plazo anual para todas las suscripciones al precio actual basado en la lista de precios. Las siguientes suscripciones se actualizarán a la facturación \"Anual\". Para completar esta solicitud, haga clic en \"Aceptar\" y luego en Enviar.",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Cambiar ciclo de facturación",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Cancelar suscripción</b> </br> <br/> <b> Nota: </b> Su ventana de cancelación de 7 días se ha cerrado. No podemos cancelar su suscripción.</br>Si desea cancelar esta suscripción en el próximo ciclo de facturación, actualice el estado de renovación automática a falso.",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Cancelar suscripción",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Establecer renovación automática</b> </br> <br/> <b> Nota: </b> No podemos cambiar el estado de renovación automática.",
    "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Expira el",
    "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Administrar la frecuencia de facturación",
    "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Pausar el acceso del cliente al servicio",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"Reanudar una suscripción suspendida restaura el acceso del cliente al servicio. No tendrá ningún impacto en su factura. Se le seguirá facturando con normalidad. Si tenía la renovación automática activada y los cambios programados antes de pausar el acceso, puede volver a activarlos.",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Reanudar el acceso del cliente al servicio",
    "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Presentar cambios",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Cancelar suscripción",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Cancelar suscripción</b> <br/> ¿Está seguro de que desea cancelar {{productName}}? <p style='text-align:left;' > Para recibir un reembolso, debe cancelar las suscripciones dentro de los 7 días calendario posteriores a la compra o renovación.<br/> <b>Tenga en cuenta:</b> El cliente perderá el acceso a esta suscripción inmediatamente después de la cancelación y el acceso no podrá ser restaurado después de la cancelación. Si cancela la suscripción dentro de los 7 días calendario, se le reembolsará el tiempo que no haya utilizado. Aparecerá un crédito en su próxima factura mensual. <a target='_blank' href=\"{{cancelNewCommerceSubscriptionGuidLineURL}}\"> Más información</a> </p>",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"¡Cancelar con éxito!",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Establecer renovación automática</b> <br/> ¿Está seguro de que desea {{autoRenewStatus}} estado de renovación automática para {{productName}}?",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Caduca",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Estado de renovación automática actualizado con éxito para {{productName}}",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"¡Actualizado con éxito!",
    "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"¿Está seguro de que desea suspender {{productName}}? <br/> <b> Nota: </b> Aunque pausar el acceso del cliente al servicio bloquea inmediatamente la capacidad del cliente para usar los servicios, esto no afecta su factura. Se te seguirá facturando normalmente. Al pausar, se apagará y deshabilitará la renovación automática y se eliminarán los cambios programados.",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Actividad",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Estado de actividad",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Fecha",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Cantidad de origen",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Actualizar de {{sourceProductName}} a {{targetProductName}}",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Cantidad objetivo",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Transición",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Productos del cliente",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Asignar",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Volver a los detalles básicos",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"Ahorrar",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Cantidad comprada",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Estado",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Complementos",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"La cantidad no puede ser mayor que",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Ciclo de facturación personalizado",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Descuento",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Cantidad disponible)",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Cantidad para",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Suscripción vinculada",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Asignar",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"Ahorrar",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Presentar cambios",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Complementos",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Cantidad disponible)",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"La cantidad no puede ser mayor que",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Cantidad para",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Descuento",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Comprar suscripción</b> <br/> ¿Está seguro de que desea convertir su oferta de prueba de {{productName}} en suscripción?",
    "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar la cantidad de <b>{{oldQuantity}}</b> a <b>{{newQuantity}}</b> para el producto actual?",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"¿Es auto renovable?",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Expira el",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"ID de suscripción de Azure",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"ID de reserva",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"ID de orden de reserva",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Región de reserva",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Alcance",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"se renueva el",
    "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Este artículo requiere la aprobación para su cumplimiento por parte del socio, después de realizar el pedido. El número de puestos que se compran supera el límite permitido de <b> {{SeatLimit}}</b> para <b>{{productName}}</b>.",
    "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Filtrar entidades",
    "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Secuencia de productos",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Secuencia de productos",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":" Añadir",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":" Vinculado",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Departamento",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":" Para",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"¿Está seguro de que desea deshacer los cambios?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"¿Está seguro de que desea actualizar la secuencia del producto?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Secuencia de producto del cliente actualizada",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"La última solicitud de actualización tiene algunos errores Leer más...",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Descuento",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Activo",
    "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Cancelado",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Cancelar prueba",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Continuar suscripción después de la prueba",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Comprar suscripción",
    "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Terminado",
    "CUSTOMER_PRODUCT_STATUS_DELETED":"Eliminado",
    "CUSTOMER_PRODUCT_STATUS_DISABLED":"Desactivado",
    "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Venció",
    "CUSTOMER_PRODUCT_STATUS_FAILED":"Ha fallado",
    "CUSTOMER_PRODUCT_STATUS_INPROVISION":"En Provisión",
    "CUSTOMER_PRODUCT_STATUS_PENDING":"Pendiente",
    "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Tuvo éxito",
    "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendido",
    "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"filtros",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"País",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Dominio",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Precio del producto vinculado",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Proveedor",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Reactivar suscripción",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Reactivar suscripción con complementos",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Alcance",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Tipos de servicio",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Estado",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Suspender suscripción",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Duración del mandato",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Periodo de prueba",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud.",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"La oferta de {{productName}} se convertirá en una suscripción al final del período de prueba.",
    "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Oferta de prueba Padres",
    "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Duración del mandato",
    "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Volver a Pedidos",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"Incluir 0 cantidades",
    "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Renovación",
    "CUSTOMER_PRODUCTS_IN_ACTIVE":"La suscripción está inactiva.",
    "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Propiedad de",
    "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Suscripción vinculada",
    "CUSTOMER_PRODUCTS_LIST_STATUS":"Estado",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Departamento",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Dominio",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Sitio",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Identificación de suscripción",
    "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Inquilino",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Cancelar suscripción",
    "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Cupón copiado al portapapeles",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Detalles básicos",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Comentarios",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Notificaciones",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Administrar propiedad",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Usuarios",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Cerca",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Copiar link",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Copiar link",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Descargar",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Obtener claves y descargas",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Enlace copiado al portapapeles",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Seleccionar tipo de archivo",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Seleccione el idioma",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Seleccionar producto",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Comportamiento",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Tamaño del archivo",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Identificación",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Llave",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Nombre",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Cantidad comprada",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Derechos",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Fecha de cancelación",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Fecha de renovación",
    "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"promocion aplicada",
    "CUSTOMER_PRODUCTS_ORDERED_ON":"Ordenado",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Administrar",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"Procesando",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Aplicar",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"Claro",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Cerca",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Incluir entidades secundarias",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Dominio",
    "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Opciones de filtro",
    "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Administrar producto",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Monto",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"pedido el",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Propiedad de",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"se renueva el",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Cantidad total",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Precio unitario",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Precio unitario",
    "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Departamento",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Sitio",
    "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Los cambios programados están presentes en esta suscripción.",
    "CUSTOMER_PRODUCTS_VALIDITY":"Validez",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Asignado al administrador el",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Correo electrónico",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Número de teléfono",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Nombre del gerente",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Detalles del administrador de cuentas",
    "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Detalles básicos actualizados exitosamente",
    "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Dirección de envío",
    "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Perfil",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Departamentos",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Sitios",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Añadir dirección",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"Agregar correo electrónico",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Añadir teléfono",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Direcciones",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"Correos electrónicos",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Números de teléfono",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Apellido",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Nombre",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Sitios",
    "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"¡No se encontraron registros!",
    "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Agregar",
    "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Perfiles de pago",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Cuenta bancaria",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Tarjeta de crédito",
    "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"No se encontraron perfiles. Agregue un nuevo perfil de pago haciendo clic en el botón Agregar.",
    "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"La adición del perfil de pago está en curso. Espere un momento mientras se completa el proceso.",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Dirección de envío",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Cuentas de usuario",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Fecha de creación de la cuenta",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Dirección de Envio",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":" Fecha de incorporación C3",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Nombre Canonico",
    "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Gracias por escoger",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Configuracion de cuenta",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":" Comercio",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Orden de revisión",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Confirmar",
    "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Hecho",
    "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"La dirección de correo electrónico ya está en uso. Inicie sesión en C3 utilizando sus credenciales o cree una nueva cuenta con una dirección de correo electrónico diferente",
    "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Por favor complete los campos requeridos para continuar",
    "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"Realizó correctamente la solicitud de registro",
    "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Sus datos se han enviado correctamente. Nos comunicaremos contigo en breve.",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"Entregar",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Comprar productos",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"Se debe confirmar la aceptación del acuerdo del cliente del proveedor",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existe",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"no existe",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Seleccionar país",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Contacto primario",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Dirección 1",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Dirección 2",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Ciudad",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"País",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Nombre de dominio",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"Dirección de correo electrónico del administrador global",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"¿Otorgar acceso al contacto principal (como administrador del cliente)?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Apellido",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Segundo nombre",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Número de registro de la organización",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"El correo electrónico proporcionado tendrá acceso de CustomerAdmin al portal.",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"¿Enviar invitación para acceder a la aplicación?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Estado",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Identificación del inquilino",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Cremallera",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Este nombre de dominio no es válido",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"está disponible",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"no está disponible",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Validación de la disponibilidad del dominio",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Seleccione estado",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Ingresar estado",
    "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Para mantener los detalles del dominio del inquilino",
    "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Para comunicar cosas relacionadas con la facturación.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Envíe los detalles a continuación y el socio se comunicará con usted para brindarle más ayuda.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Haga clic en copiar enlace al portapapeles para copiar el enlace de la relación de revendedor y seguir el modelo de revendedor.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Abra el enlace en una sesión de incógnito y complete la relación de revendedor utilizando una cuenta que sea un administrador global.<br>Después de completar, proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Si no desea aceptar la relación de revendedor, simplemente proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Instrucciones para aceptar la relación de revendedor",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Instrucciones para aceptar la relación de revendedor",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"Mientras tanto, puede autorizar a un socio como su revendedor de CSP brindando su consentimiento en el enlace de relación de revendedor utilizando su Usuario administrador global.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Haga clic en copiar enlace al portapapeles",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":"para copiar el enlace de relación de revendedor y seguir el modelo de revendedor.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Abra el enlace en una sesión de incógnito y complete la relación de revendedor utilizando una cuenta que sea un administrador global.<br>Después de completar, proporcione los detalles a continuación y haga clic en enviar, luego ese socio se comunicará con usted.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Si no desea aceptar la relación de revendedor, simplemente proporcione los detalles a continuación y haga clic en enviar después de que ese socio se comunique con usted.",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"Id. de lote de registro",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"El procesamiento puede tardar un tiempo y el estado se mostrará en esta página. Si abandona el navegador o lo cierra por accidente, le informaremos por correo electrónico.",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"registro en curso",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Espere mientras configuramos su cuenta. Puede continuar usando su dispositivo como siempre. Le enviaremos una notificación por correo electrónico una vez que se complete la configuración.",
    "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Para obtener la información y las suscripciones del inquilino",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Carro",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Detalles del cliente",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Detalles del pago",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Comprar productos",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente externo",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Acción",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"productos C3",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Producto externo",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Secuencia de números",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Convenio",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Nombre de la categoría",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Contrato",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente de PSA",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Utilice más filtros si desea establecer restricciones adicionales en la categoría, el inquilino, etc.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"¿Es el contrato por defecto?",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Los contratos predeterminados se utilizarán para cargar servicios de contrato asociados con servicios que se crean y asignan automáticamente.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Inquilino",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":" Departamento :",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Sitio :",
    "CUSTOMER_RECONCILIATION_REPORT":"Reporte de conciliación",
    "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Esta función está disponible para ofertas personalizadas y tiene soporte por un período de un año.",
    "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"La Suscripción Vinculada no será compatible con esta funcionalidad.",
    "CUSTOMER_REPORT":"Informes de clientes",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filtrar productos",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Fecha máxima de pedido",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Cantidad máxima",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Fecha mínima de pedido",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Cantidad mínima",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Caduca en días",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Ordenado entre",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Cantidad comprada",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Informe de productos",
    "CUSTOMER_REPORTS":"Informes",
    "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"El ajuste se guardó correctamente.",
    "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Se agregaron cuotas vencidas como ajuste a la factura actual.",
    "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Pago registrado exitosamente",
    "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"ID de referencia de facturación",
    "CUSTOMER_SEARCH_CLOSE_OPTION":"Cerrar filtros",
    "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Incluir cliente eliminado",
    "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"ID de oportunidad de CRM",
    "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Método de pago por defecto",
    "CUSTOMER_SEARCH_CUSTOMER_name":"nombre",
    "CUSTOMER_SEARCH_CUSTOMER_NAME":"Nombre",
    "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Método de pago",
    "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Plan",
    "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Etiquetas",
    "CUSTOMER_SEARCH_DEFAULT_OPTION":"Seleccione",
    "CUSTOMER_SEARCH_DOMAIN_NAME":"Nombre de dominio",
    "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"identificación del cliente ERP",
    "CUSTOMER_SEARCH_FILTER":"Filtrar clientes",
    "CUSTOMER_SEARCH_INVOICE_NOTES":"notas de factura",
    "CUSTOMER_SEARCH_MARKET":"Código de mercado",
    "CUSTOMER_SEARCH_PROVIDER":"Proveedor",
    "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
    "CUSTOMER_SEARCH_RESET_OPTION":"Reiniciar",
    "CUSTOMER_SEARCH_SEARCH_OPTION":"Búsqueda",
    "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Identificador único",
    "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"Añadir a la cesta",
    "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Seleccionar todos los complementos",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Suscripción vinculada",
    "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Cerca",
    "CUSTOMER_SHOP_HEADER_TEXT_SHOP":" Comercio",
    "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Para filtrar o incluir productos de nivel infantil",
    "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Tipos de facturación",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Validez",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Tipo de validez",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"Duración del ensayo",
    "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"AÑADIR",
    "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"OFERTA DE PRUEBA",
    "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"filtros",
    "CUSTOMER_SIGNUP_FAILED":"Registro de cliente fallido",
    "CUSTOMER_SIGNUP_SUCCESS":"Éxito en el registro del cliente",
    "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Registro exitoso. Puede iniciar sesión en el Portal C3 con las credenciales que se envían a:",
    "CUSTOMER_SITE_ADD_SITE":"Agregar sitio",
    "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Sitio eliminado exitosamente",
    "CUSTOMER_SITE_EDIT_SITE":"Editar sitio",
    "CUSTOMER_SITE_HEADER_TEXT":"Sitios",
    "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Sitio guardado exitosamente",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"No es posible ignorar los errores en esta suscripción. Comuníquese con el servicio de asistencia de C3 para obtener más ayuda.",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"plan azul",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Derechos",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Seleccione el plan Azure.",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Seleccione derechos.",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Estás seguro de que quieres continuar?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"¿Aplicar promoción al cliente?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" El precio no puede ser otro que <strong>{{price}}</strong> porque este ReservationOrderID {{reservationorderID}} ya tiene un producto de reserva con precio.<br>¿Quieres sincronizar con el precio <strong>{{price} }</strong> ?",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Aceptar",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Rechazar",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Comportamiento",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"volver a productos",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Gerente de renovación",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Gerente de Renovación",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Acción",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Volver al gestor de renovaciones",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Historial del gerente de renovación",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Actualizado en",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Descargar el historial del gestor de renovaciones",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Historial del gerente de renovación",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":" Precio",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Nombre del producto",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Cantidad comprada",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nombre de usuario",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":" Precio",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Productos",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Cantidad comprada",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Renueva el",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Nombre",
    "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Seleccione al menos un archivo.",
    "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Subir archivo exitosamente",
    "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Aprobar",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Cargando el informe de conciliación",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Recargando los detalles de facturación de los clientes...",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Agregar cliente",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Agregar suscripción",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Agregar usuario a la suscripción",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Volver a usuarios de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Volver a suscripciones",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Existente (múltiples)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Incorporación masiva de clientes",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Existente (único)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Cliente existente",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Resumen de facturación de exportación",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Exportar detalles de uso",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Ir al informe",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"aquí",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Nuevo",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Nuevo cliente",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Cliente a bordo",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Reporte de conciliación",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Recargar datos de facturación",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Recargar mes actual",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"recargar completo",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recargar resumen de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Recargar detalles de uso",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Remover",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Seleccione",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Agregar cliente",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":" Agregar suscripciones para",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":" Suscripciones de Azure de",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Clientes",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Editar suscripción",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":" Administrar usuario de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Ofertas",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Planes y ofertas",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Informe de conciliación para",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Usuarios de suscripción de",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Configuración del cliente de",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Valor",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Detalles de uso de",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":" Usuarios de",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Validar errores de incorporación para",
    "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finalizar",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Facturación",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Período de facturación",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Perfil de la empresa",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Tipo de pago",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Por favor seleccione Tipo de Pago",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Contacto primario",
    "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"logo",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"¡La factura ha sido aprobada!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Aún puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que finalice y se le cobre al cliente. El cliente ahora puede ver esta factura y usted puede registrar los pagos de esta factura una vez finalizada.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"¡La factura ha sido finalizada!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"El usuario ahora puede ver esta factura y puede registrar los pagos contra esta factura.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"¡Se ha generado la factura!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que finalice y se le cobre al cliente.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"La factura se enviará por correo a su dirección de facturación. Gracias.",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":" Pago automático",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Pago automático: DESACTIVADO",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Pago automático: ON",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Facturación",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organización IIN",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Método de pago",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Nombre del banco",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Ciudad",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"tipo de tarjeta de crédito",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Umbral de peligro",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Umbral de error",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Nombre del grupo",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Umbral de información",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Ocultar del cliente",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Artículos seleccionados",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Apellido",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Línea 1",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Línea 2",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Mes",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Plan",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Role",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Estado",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Umbral de advertencia",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Año",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Cremallera",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"planes",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Cantidad comprada",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"CCA",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manual",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"No",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Sí",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Suscripción inactiva",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"La cantidad no coincide",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Eliminar acceso",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Editar suscripción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Habilitar suscripción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Usuarios",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Ver detalles de uso",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Activo en CSP",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Nombre del componente",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"Identificación de correo",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Derechos",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Error",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Costo estimado",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Ocultar del cliente",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Las suscripciones no son visibles para los clientes.",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Última actualización",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"factura MSFT",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Estado de incorporación",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":" Precio",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Lucro",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Role",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"ID de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Umbrales",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Usuario",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nombre de usuario",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Aún no se han agregado suscripciones.",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Asignar plan de cliente",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":" Cambiar ciclo de facturación",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Cambiar nombre",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Definir etiquetas",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Definir % de impuesto",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Solucionar la discrepancia en el estado de activación en el Centro de socios",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Solucionar el desajuste del estado de renovación automática en el Centro de partners",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Corregir discrepancias...",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Corregir discrepancias",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Cambiar oferta",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Proveedores de pago",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":" Actualizar el estado del producto perpetuo",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Cambio de plan",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Corregir promoción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"proveedores",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Cliente : Suplantación rápida",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Reporte de conciliación",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Recargar resumen de suscripción",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Arreglar alcance",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Configuración del cliente",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":" Cambiar término",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Prueba de pago automático",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Usuarios",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Errores de validación",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"COSTUMBRE",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Total",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Presupuesto mensual-Información-Advertencia-Error-Peligro",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"está disponible",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"no está disponible",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Descargue la plantilla desde",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Borrar usuario",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Conceder acceso al portal",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Personificar",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Revocar el acceso desde el portal",
    "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"El valor debe estar entre 0 y 100.",
    "CUSTOMER_SUP_TEXT_DELETED":"(eliminado)",
    "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Sincronizar perfiles",
    "CUSTOMER_TAGS_EXAMPLE":"Defina pares de etiquetas y valores para el cliente que se utilizarían al definir el control de acceso para los usuarios. <br/> Ejemplo: puede definir una etiqueta denominada SupportAgent y un apodo de un agente (por ejemplo, Agent001) como valor. Si un agente de la mesa de ayuda tiene una de las etiquetas configuradas como SupportAgent y uno de los valores para esta etiqueta en particular como Agent001, el agente podrá ver aquellos clientes que tienen el par de valores de etiqueta {SupportAgent, Agent001} configurado.",
    "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Nombre",
    "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Valor",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Borrar",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Editar",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Valor",
    "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"La fecha de finalización del plazo personalizado especificada en las instrucciones del próximo plazo programado solo es válida para los productos OnlineServicesNCE.",
    "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Ver facturas pendientes",
    "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":" Pagos por",
    "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Cliente",
    "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Cliente",
    "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Detalles del usuario guardados exitosamente",
    "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"El informe de asignación de licencia de usuario se enviará al correo electrónico proporcionado.",
    "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Añadir nuevo usuario",
    "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"volver a la lista",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Autorizará el acceso",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Administrar usuario",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Revocar el acceso",
    "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Departamento",
    "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "CUSTOMER_USERS_FORM_LABEL_ROLE":"Role",
    "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Tipo de rol",
    "CUSTOMER_USERS_FORM_LABEL_SITE":"Sitio",
    "CUSTOMER_USERS_HEADING":"Usuarios",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Role",
    "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Descargar facturas/Pagos",
    "customerconfiguration":"Configuración del cliente",
    "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Mencione si los detalles de ACH están relacionados con una cuenta comercial",
    "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Este cliente ya ha sido seleccionado",
    "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"El cliente ya existe en el sistema.",
    "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Cliente creado exitosamente",
    "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"El proceso de incorporación del cliente está en curso.",
    "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Seleccione al menos un archivo.",
    "CUSTOMERONBOARDING_SUCCESS":"El cliente se incorporó correctamente.",
    "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Se produjo un error al cargar el archivo.",
    "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} ya tiene una licencia para {offername}",
    "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"La cantidad de usuarios proporcionados es mayor que la cantidad de licencias disponibles. Corrija los detalles del usuario y vuelva a intentarlo.",
    "customers":"Clientes",
    "Customers":"Clientes",
    "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Comportamiento",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Negocio",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Clientes",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
    "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Enlace al perfil de facturación",
    "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Enlace al proveedor",
    "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Eliminar enlace al perfil de facturación",
    "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Creando facturas…",
    "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Recargando el Informe de Rentabilidad de Clientes...",
    "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Volver a Facturas",
    "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Agregar ajuste",
    "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"¡Agregue el monto adeudado como ajuste!",
    "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
    "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Descargar factura",
    "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Ir al informe",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Historia por fecha",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Historia por oferta",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Iniciar pago",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Cliente de carga de prueba",
    "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
    "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Factura para",
    "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Rentabilidad por suscripciones para",
    "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
    "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Cambio en el historial de suscripciones para",
    "CUSTOMERS_DOMAIN_CHECK_ERROR":"No se puede comprobar la disponibilidad del dominio",
    "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos",
    "CUSTOMERS_GRANT_ACCESS_CONFIRM":"¿Estás seguro de que deseas otorgar acceso a {{username}}?",
    "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Clientes",
    "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"No se encontraron facturas. Por favor contacte al soporte.",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Dirección",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Cliente",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"número de factura",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Resumen de la cuenta",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Resumen de factura",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Período de facturación",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Fecha de vencimiento",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Monto de la factura",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Fecha de la factura",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Fecha de vencimiento de la factura",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Generado en",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Estado de la factura",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"¡Se ha generado la factura!",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Puede agregar ajustes (cargos o créditos adicionales) a esta factura antes de que se procese y se le cobre al cliente.",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"¡La factura ha sido procesada!",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"El cliente ahora puede ver esta factura y usted puede registrar los pagos contra esta factura.",
    "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Cuotas de facturas pasadas",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Parece que hay cuotas de facturas anteriores. Si está seguro de haber registrado todos los pagos del cliente, haga clic en el siguiente botón para",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"para agregar <strong>{{InvoiceDueAmount}}</strong> como ajuste a la factura actual.",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Parece que hay cuotas de cantidad.",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":" en total.",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Editar",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Borrar",
    "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Detalle de factura",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Basado en licencia",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Basado en el uso",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":" Comportamiento",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Monto",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Tipo de carga",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":" Descripción",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Importe de descuento",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Precio de oferta",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":" Cantidad",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Fecha final",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"gran cantidad",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Monto subtotal",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Fecha de inicio",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"nombre de suscripción",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Importe del impuesto",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Porcentaje de impuesto",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Precio unitario",
    "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"elementos de la línea de factura",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Registro de pago",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Monto del pago",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Observaciones",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Razón del fracaso",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Monto de pago",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Identificación de pago",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Pagos de facturas",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Observaciones",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"ID de transacción",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Instrucción para registrar el pago.",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"Registrar un pago (parcial o total) de una transacción que ocurrió fuera del sistema",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"El exceso de los pagos se trataría como saldo y se ajustaría para una factura posterior.",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Comuníquese con el soporte en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"Tarifa de procesamiento de CC ({{CCFeePercentage}}% sobre {{CCPaidAmount}})",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Gran total",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Subtotal",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Razón del fracaso",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Monto",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Fecha",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Observaciones",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Estado de pago",
    "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Los detalles de facturación, incluido el historial, se actualizaron correctamente",
    "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Los detalles de uso, incluido el historial, se actualizan correctamente",
    "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"incorporado en",
    "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"planes",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"Identificación C3",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Nombre",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Clientes incorporados",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Buscando un registro coincidente en el sistema de facturación",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Encontré una coincidencia en el ID de referencia {{ID}} en el sistema de facturación.",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Buscar clientes en C3 para una coincidencia por ID de cliente del proveedor",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Encontré un cliente con nombre: {{customername}}",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Buscando clientes en {{providerName}} para una coincidencia por ID de cliente del proveedor",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Encontré un cliente con nombre: {{customername}} en {{providerName}}",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Las cantidades de ofertas para niños en un compuesto deben ser las mismas.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Una o más ofertas en CSP se asignan a la misma oferta en facturación. Por favor ajusta tu mapeo",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"La oferta {{Name}} del plan tiene varias suscripciones de CSP coincidentes.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"No se pudo asignar la suscripción {{nameinbilling}} en facturación",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"La oferta {{name}} no tiene suscripciones de CSP coincidentes en el Centro de socios",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"La suscripción en el Centro de socios: {{name}} no tiene ninguna oferta coincidente en el plan para asignar",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Las siguientes ofertas deben asignarse a suscripciones de CSP:",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"La oferta {{name}} tiene más usuarios especificados que puestos que tiene",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Las cantidades de las suscripciones deben coincidir con las del Centro de partners.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Las cantidades de la suscripción {{subscriptionname}} deben coincidir entre el Centro de partners y la facturación.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Las cantidades de las suscripciones deben coincidir entre el Centro de socios y la Facturación.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"La cantidad de puestos de una suscripción de CSP {{cspsubscription}} debe coincidir con la suma de todos los números proporcionados en ofertas relacionadas en el plan.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Una o más ofertas de CSP no están asignadas o faltan suscripciones en CSP",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Se produjo un error al actualizar las suscripciones de {{customerName}}",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Suscripciones actualizadas para el cliente: {{customername}}",
    "CUSTOMERS_REGISTRATION_SUCCESS":"El registro del cliente se completó con éxito. Se envía un correo electrónico a {{registeredemail}} con instrucciones para iniciar sesión en el portal.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"El registro del cliente se completó con éxito.<br/>Las credenciales del usuario administrador son:<br/><b>UserId -</b> {{userId}},<br/><b>Contraseña temporal -</b> {{password }}",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"El registro del cliente se completó correctamente.<br/>Las credenciales del usuario administrador son:<br/><b>Id. de usuario -</b> {{userId}},<br/><b>Contraseña temporal:</b> {{contraseña }} <span class =\"text-danger\"> <br/><br/> <b>No se pudieron asignar los grupos de seguridad a la solicitud GDAP. Complete esto en el Centro de socios; de lo contrario, fallarán otras acciones.</ b> </span>",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"El registro del cliente se completó con éxito.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"El registro del cliente se completó con éxito. Inicie sesión en el portal.",
    "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Por favor asocie una oferta para el ajuste.",
    "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"Clientes seleccionados para a bordo",
    "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Sincronizar perfiles",
    "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Pagos de clientes",
    "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Cantidad facturada",
    "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Fecha",
    "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nombre de Departamento",
    "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Nuevo precio",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
    "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Precio anterior",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
    "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Otro precio",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Importe facturado antes de impuestos",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"Factura MSFT antes de impuestos",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Lucro",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Ganancia %",
    "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Nombre del sitio",
    "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
    "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Usuario",
    "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Aún no se han agregado suscripciones.",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Eliminar cliente",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Configuración del cliente",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Administrar planes",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Acción",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Nombre",
    "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Descargar líneas de pedido basadas en el uso",
    "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Rentabilidad por suscripciones",
    "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Recargar informe de rentabilidad",
    "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Historial de cambios de suscripción",
    "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Mirar la factura",
    "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Ver líneas de pedido de uso",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"Se agregó {{username}} a la suscripción de Azure con éxito",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"Se agregó {{username}} a la suscripción de Azure {{azuresubscription}} con éxito",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} se eliminó correctamente de la suscripción de Azure",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"¿Estás seguro de que deseas eliminar al usuario?",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} se eliminó correctamente de {{azuresubscription}}",
    "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"La suscripción de Azure se guardó correctamente",
    "customNotifications":"Notificaciones personalizadas",
    "customoffer":"Oferta personalizada",
    "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Estado de la factura actualizado correctamente",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Pedido realizado con éxito. ID de pedido: {{ID de pedido}}",
    "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Destinatarios de contraseñas",
    "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Correo electrónico de los destinatarios",
    "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Introduzca los destinatarios del correo electrónico separados por comas",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Agregar nueva entidad",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Cancelar",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Administrar",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"Entregar",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Agregar nueva entidad",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Entidades Operadoras",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Descripción",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Tipo de entidad",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Nombre",
    "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Registro de aceptación de términos y condiciones",
    "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Mirar la factura",
    "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Nueva factura generada el <b>{{InvoiceGeneratedDate}}</b> para el período de facturación del <b>{{BillingStartDate}}</b> al <b>{{BillingEndDate}}</b> con importe <b >{{ImporteFactura}}</b>.",
    "DASHBOAR_TEXT_DATE":"Fecha",
    "DASHBOAR_TEXT_EMAIL":"Correo electrónico",
    "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL del acuerdo de Ezidebit",
    "DASHBOAR_TEXT_IP":"IP",
    "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"URL del acuerdo de cliente de Microsoft",
    "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL de términos y condiciones para socios",
    "DASHBOAR_TEXT_TIME":"Tiempo",
    "Dashboard":"Panel",
    "DASHBOARD_BREADCRUMB":"Panel",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Análisis de clientes",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Informes",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Informe de cambio de licencia",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Informe resumido de licencia",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Informe de uso",
    "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
    "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Actualización del perfil",
    "DASHBOARD_CAPTION_PARAGRAPH":"Proporcione recursos para administrar un negocio exitoso con el poder de la nube.",
    "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Bienvenido al mercado de la nube",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Todos los SKU en CSV",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"Descargar CSV",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"Descargar PNG",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"Descargar SVG",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Los 10 principales SKU en CSV",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"microsoft",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"Microsoft sin CSP",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Compañero",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Todo",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Último período de facturación",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 períodos de facturación",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 períodos de facturación",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 períodos de facturación",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 meses",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 meses",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 meses",
    "DASHBOARD_COMPLAINCE_TEXT":"Centro de Cumplimiento",
    "DASHBOARD_COST_TEXT":"Costo",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Número de departamentos",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Departamentos",
    "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Departamentos",
    "DASHBOARD_HEADER_TEXT_WARNING":"¡Advertencia!",
    "DASHBOARD_ICON_TITLE":"Iconos-de-Estocolmo / Diseño / Capas",
    "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Mes actual",
    "DASHBOARD_LBL_TEXT_NET_SEATS":"Red",
    "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Mes anterior",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Bienvenido a su portal de licencias Dashboard",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Utilice los mosaicos a continuación como enlaces rápidos para administrar sus suscripciones y usuarios.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"Este portal le permite agregar nuevos usuarios en su inquilino, asignar licencias y restablecer su contraseña.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Puede adquirir licencias y suscripciones adicionales.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Actualiza tu perfil (Dirección de facturación y método de pago)",
    "DASHBOARD_REVENUE_TEXT":"Ingresos",
    "DASHBOARD_SEAT_PURCHASED_TEXT":"Asiento comprado",
    "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Número de sitios",
    "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Sitios",
    "DASHBOARD_SITES_COUNT_TEXT_SITES":"Sitios",
    "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Iniciar sesión en el portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Portal azur",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Iniciar sesión en Portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Suscripciones de Azure",
    "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Uso actual de Azure desde {{startDate}}",
    "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Productos",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Suscripciones de licencia",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Ver más",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Oficina 365",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Iniciar sesión en Portal",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Informes de Office 365",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Iniciar sesión en Portal",
    "DASHBOARD_TAIL_1_TEXT_USERS":"Usuarios",
    "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Ver más",
    "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TENDENCIA ESTE MES",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Ha fallado",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"En progreso",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"No recibido",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Recibió",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":" Fecha:",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":" Asientos:",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":" Fecha:",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":" Productos:",
    "DASHBOARD_TOOLTIP_LAST_SYNC":"Última sincronización {{LastSyncMessage}}",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Nueva factura generada el <b>{{InvoiceGeneratedDate}}</b> para el período de facturación del <b>{{BillingStartDate}}</b> al <b>{{BillingEndDate}}</b> con monto <b >{{ImporteFactura}}</b>.",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"para ver la factura. Ignorar si ya pagó.",
    "DASHBOARD_WARNING_MESSAGE":"Debe actualizar su perfil de pago antes de usar C3. Por favor haga clic en el siguiente botón para actualizar.",
    "DATA_PUSHED_DATES":"Algunos o todos los datos se enviaron previamente, verifique los registros anteriores.",
    "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Descripción del Artículo",
    "DAYS":" Días)",
    "DEACTIVATE_CONFORMATION":"¿Estás seguro de que deseas desactivarlo?",
    "DEACTIVATE_CUSTOMER_PRODUCT":"No se pudo desactivar el producto del cliente",
    "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Producto de cliente desactivado",
    "DEACTIVATE_PRODUCT_EXCEPTION":"Este usuario no tiene permiso para eliminar esta oferta.",
    "DECEMBER":"Diciembre",
    "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Habilite esta configuración para establecer la cuadrícula/lista como vista predeterminada en la página de productos para los clientes",
    "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Vista predeterminada de productos del cliente",
    "DEFAULT_COTERMINOSITY_TYPE_DESC":"Al seleccionar un valor aquí, se establecerá como predeterminado para la función de coterminosidad durante las compras de suscripción.",
    "DEFAULT_COTERMINOSITY_TYPE_DISP":"Tipo de coterminosidad",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Lista de tipos de contraminiosidad",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Tipo de contraminiosidad",
    "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Eliminar todos los complementos",
    "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al eliminar la conversión de moneda.",
    "DELETE_CUSTOMER_FAILURE":"Error al actualizar el nombre del cliente",
    "DELETE_CUSTOMER_SUCCESS":"El cliente se elimina correctamente.",
    "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Se produjo un error al eliminar los destinatarios de las notificaciones por correo electrónico",
    "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Se eliminará el ajuste de factura seleccionado. ¿Quieres continuar?",
    "DELETE_PARTNER_BUNDLE_TOOLTIP":"Eliminar paquete de producto",
    "DELETE_PARTNER_OFFERS":"Eliminar ofertas de socios",
    "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al eliminar los detalles de impuestos.",
    "DELETE_PARTNER_USER":"Eliminar usuario asociado",
    "DELETE_PARTNER_USER_FAILURE":"No se pudo eliminar el usuario asociado",
    "DELETE_PONUMBER":"Eliminar número de orden de compra",
    "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Eliminar producto",
    "DELETE_QUOTE_SUCCESSFULLY":"Cita eliminada exitosamente",
    "DELETE_RECORD_CONFIRMATION_PROMPT":"¿Está seguro de que desea eliminar este registro?",
    "DELETE_USER_EXCEPTION":"Este usuario tiene cierto acceso a C3, así que elimínelos.",
    "DEPARTMENT":"(Departamento)",
    "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Encabezado principal del banner de registro público",
    "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Encabezado de párrafo de banner de registro público",
    "DESC_CUSTOMER_CURRENCY_OPTION":"Moneda del cliente",
    "DESC_CUSTOMER_DASHBOARD":"Panel de control del cliente",
    "DESC_PARTNER_DASHBOARD":"Panel de socios",
    "DESC_PLAN_CURRENCY_OPTION":"Moneda del plan",
    "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"URL del sitio web para el hipervínculo de administrador en la barra de navegación de registro público",
    "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Encabezado principal en el banner de registro público",
    "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"El párrafo que aparece en el banner de registro público debajo del encabezado del banner.",
    "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"URL del sitio web para el hipervínculo del sitio web en la barra de navegación de registro público",
    "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Contáctenos",
    "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":" URL del sitio web para el hipervínculo del Portal de aprendizaje en la barra de navegación de registro público",
    "DESC_PUBLIC_SIGNUP_SUPPORT":"URL del sitio web para el hipervínculo de soporte en la barra de navegación de registro público",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Términos y condiciones de registro público 1",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Términos y condiciones de registro público 2",
    "DESC_SHOW_INVOICES_TO_CUSTOMER":"Habilite esta opción para mostrar facturas al cliente",
    "Description":"Descripción",
    "DESCRIPTION_FOR":"Descripción para",
    "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Desactivar producto",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Modelo seguro antiguo deshabilitado y nuevo habilitado",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Gracias por la confirmación. La aplicación accederá a su Centro de socios siguiendo las nuevas pautas de seguridad.",
    "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Error al deshabilitar el modelo seguro antiguo y habilitar el nuevo",
    "DISCOUNT_MAX_ERROR":"El valor debe ser menor o igual a 100",
    "DISCOUNT_MIN_ERROR":"El valor debe ser mayor o igual a 0",
    "DISP_CATEGORY_COUPON_ENTITY":"Categoría",
    "DISP_PLANOFFER_COUPON_ENTITY":"Oferta de plan",
    "DISP_PROVIDER_COUPON_ENTITY":"Proveedor",
    "DISP_SALETYPE_COUPON_ENTITY":"Tipo de venta",
    "DISP_SHOW_INVOICES_TO_CUSTOMER":"Mostrar facturas al cliente",
    "DisplayCurrency":"Mostrar moneda",
    "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Duración del término",
    "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Reiniciar",
    "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Introduzca un valor positivo",
    "DISTRIBUTOR_OFFER_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
    "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
    "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Nueva oferta de distribuidor",
    "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Volver a la oferta del distribuidor",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Ofertas de distribuidores",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Categoría",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Ofertas de distribuidores",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Acción",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"¿Es autorenovable?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Tipos de facturación",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Categoría",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Tipo de período de facturación",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Precio de coste",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Precio de costo (desde)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Precio de costo (a)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Descripción",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Nombre",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Precio de coste antiguo",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Precio de venta antiguo",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Icono",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Proveedor",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Precio de venta",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Precio de venta (desde)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Precio de venta (a)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Término",
    "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Oferta de distribuidor {{distributorOffer}} guardada correctamente",
    "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"nombre",
    "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Oferta de distribuidor {{distributorOffer}} actualizada correctamente",
    "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Eliminar administrador de cuenta con éxito",
    "DOCUMETATION_HEADING":"Documentación",
    "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Código de moneda",
    "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Entidad",
    "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Cantidad facturada",
    "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"nombre calificado",
    "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Descargar progreso y enlace",
    "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Descargar facturas",
    "DOWNLOAD_COLUMN_BUNDLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Regla de facturación para la compra de asientos",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Normas de facturación para la reducción de plazas",
    "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Precio de costo",
    "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Tipo de venta",
    "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Descripción",
    "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"¿Es autorenovable?",
    "DOWNLOAD_COLUMN_BUNDLE_NAME":"Nombre del paquete",
    "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Nombre del producto",
    "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Precio de venta",
    "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Estado",
    "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Mercado soportado",
    "DOWNLOAD_COLUMN_BUNDLE_TERM":"Término",
    "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Informe de productos en paquete",
    "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Nombre del cupón activo",
    "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Aplicabilidad",
    "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Asignado el",
    "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Código promocional",
    "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Descripción del cupón",
    "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Nombre del cliente",
    "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Descuento",
    "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Expira el",
    "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"Pago automático",
    "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"ID de referencia de facturación",
    "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Código de mercado",
    "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Código de moneda",
    "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Nombre de dominio",
    "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Nombre",
    "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Método de pago",
    "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Plan",
    "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Es oferta de prueba",
    "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Fecha de registro",
    "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Etiquetas",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Identificación del inquilino",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Nombre del inquilino",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Inquilinos",
    "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Es oferta de prueba",
    "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Número de factura del proveedor",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Número de referencia de facturación",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Fecha de finalización del cargo",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Fecha de inicio del cargo",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Tasa de conversión",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Costo en socio",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Código de moneda",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"Identificación del cliente de ERP",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Importe de descuento",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Nombre de dominio",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"ID de oferta duradera",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Precio unitario efectivo por MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Fecha de la factura",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Notas de factura",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Número de factura",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Detalle de línea de factura",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Código del objeto",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Nombre",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Nombre de la oferta",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"numero postal",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Importe después del impuesto",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Monto antes de impuestos",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Identificación de la promoción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Costo unitario prorrateado",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Proveedor",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Cantidad comprada",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Fecha de finalización efectiva de la suscripción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Suscripción efectiva",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Identificación de suscripción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Plazo de suscripción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Tipo de suscripción",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Número de suscripción del socio de distribución",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Importe del impuesto",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Código de impuestos",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Identificación del inquilino",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Nombre del inquilino",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Cantidad total",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Monto total para socio por MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Costo unitario por producto",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Costo unitario del socio",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Precio unitario",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Precio unitario por MS",
    "DOWNLOAD_COLUMN_MARKET_REGION":"Mercados admitidos",
    "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Es oferta de prueba",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Disponible para agrupar",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Disponible para consumo inmediato.",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Norma de facturación para la compra de asientos",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Norma de facturación para la reducción de plazas",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Tipo de facturación",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Tipo de consumo",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Precio de coste",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Descripción",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Nombre",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Precio de venta",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Tipo de venta",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Estado",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Término",
    "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Código de moneda",
    "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Descripción",
    "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Moneda de la factura",
    "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"es público",
    "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Detalles de macros",
    "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Valor macro",
    "DOWNLOAD_COLUMN_PLAN_NAME":"Nombre",
    "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Disponible para el cliente",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Categoría de ciclo de facturación",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Tipo de facturación",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Categoría",
    "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Costo para el socio",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Código de moneda",
    "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Eficacia",
    "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Nombre de la oferta amistosa",
    "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"El último cambio de precio ocurre el",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Nombre de la macro",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Valor macro",
    "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Nombre del plan",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"ID de oferta",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Nombre de la oferta",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Nombre del plan",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Proveedor",
    "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Precio al por menor",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Precio de venta",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Tipo de servicio",
    "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Estado",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Mercado soportado",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Término",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Duración del mandato",
    "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Moneda de compra",
    "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"Compartir URL",
    "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Está disponible para compra",
    "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Es oferta de prueba",
    "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Disponible para liberación automática",
    "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Categoría",
    "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Tipo de consumo",
    "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Nombre de Departamento",
    "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Descuento",
    "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Nombre de dominio",
    "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Producto Precio Efectivo",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Nombre del producto",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Secuencia de productos",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Nombre de categoría de proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Fecha de creación del proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Fecha de finalización del proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Fecha de inicio del proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"ID de producto del proveedor",
    "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Cantidad comprada",
    "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Nombre del sitio",
    "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Estado",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"ID de inquilino",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Nombre del inquilino",
    "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Precio unitario",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Categoría",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Costo para el socio",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Código de moneda",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Es oferta de prueba",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Identificación de producto",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Nombre del producto",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Categoría de proveedor",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Nombre del proveedor",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Región",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Precio al por menor",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Informe del catálogo de productos.",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Validez",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Tipo de validez",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Ciclo de facturación",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Nombre de la categoría",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Creado por",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Fecha de Creación",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Código de moneda",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Nombre del cliente",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Nombre de dominio",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Entidad",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"es complemento",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Nombre de la entidad",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Nuevo precio",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Nueva cantidad",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Nuevo estado",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Precio anterior",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Cantidad antigua",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"antiguo estado",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Nombre del producto",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Proveedor",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Categoría de proveedor",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Revendedor nuevo precio",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Precio anterior del revendedor",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Identificación de suscripción",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"identificación del inquilino",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"tipo de cambio",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Validez",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Tipo de validez",
    "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Seleccione al menos una columna",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Descargar",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Descargar Cupones",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Descargar Clientes",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Descargar Productos",
    "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Descargar Planes",
    "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Descargar Ofertas de Planes",
    "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Partidas de factura",
    "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Pagos de facturas",
    "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Seleccione al menos una columna",
    "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"Descargar CSV",
    "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Descargar clientes no incorporados",
    "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "DOWNLOAD_POPUP_GRID_HEADER":"Descargar informe de cuadrícula",
    "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"No hay datos disponibles para descargar CSV",
    "DOWNLOAD_PRODUCTS_GRID":"Descargar productos",
    "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Descargar software y claves",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Agregar múltiples",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Enlace múltiple",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"enlace único",
    "DROPDOWN_PLACEHOLDER_SELECT":"Seleccione",
    "DUE_STATUS_DUE":"Pendiente",
    "DUE_STATUS_FINALIZED":"Finalizado",
    "DUE_STATUS_OVERDUE":"Atrasado",
    "DUE_STATUS_PAID":"Pagado",
    "DUE_STATUS_PARTIAL_PAID":"Parcialmente pagado",
    "earningsreport":"Informe de ganancias",
    "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Editar información de facturación",
    "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Editar grupo",
    "EDIT_INVOICE_BUTTON_TEXT":"Editar factura",
    "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Producto",
    "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Resumen",
    "EDIT_PONUMBER":"Editar número de orden de compra",
    "EffectivenessTypeId":"Identificación del tipo de eficacia",
    "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Proporcione una imagen con una resolución máxima de 300 x 100 para el logotipo de correo electrónico",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"La dirección de correo electrónico especificada como contacto de facturación en el perfil del cliente.",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Lista de direcciones de correo electrónico de las personas a las que se debe notificar por correo electrónico",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Funciones distintas a la de HelpDesk",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"Contactos de facturación",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Gente",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Role",
    "EMAIL_TABLE_HEADER_ACTIONS":"Comportamiento",
    "EMAIL_TABLE_HEADER_EMAIL":"Correo electrónico",
    "EMAIL_TABLE_HEADER_EMAIL_TYPE":"Tipo de correo electrónico",
    "EMAIL_TEMPLATE":"Avance",
    "EMAIL_TEMPLATE_LABEL":"Plantillas",
    "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Seleccionar plantilla",
    "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"Plantilla de correo electrónico actualizada correctamente",
    "EMAIL_TEMPLATE_SUBMIT":"Entregar",
    "EMAIL_TYPE_BILLING":"Facturación",
    "EMAIL_TYPE_COMPANY":"Compañía",
    "EMAIL_TYPE_INVOICE":"Factura",
    "EMAIL_TYPE_PERSONAL":"Personal",
    "EMAIL_TYPE_SHIPPING":"Envío",
    "EmailConfigurations":"SMTP",
    "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"La configuración está diseñada para habilitar la fijación de precios por nivel de derechos. Una vez activada, generaremos facturas en función de los niveles de derechos, independientemente del tipo de factura. Confirme que esto es aceptable para usted.",
    "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Habilitar precios a nivel de derechos",
    "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Habilitar producto",
    "ENABLE_RESELLER_PLAN":"Asignar plan al revendedor",
    "ENABLE_RESELLER_PLAN_FAILURE":"No se pudo asignar el plan al revendedor",
    "END_BILLING_PERIOD":"Finalizar período de facturación",
    "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"La fecha de finalización debe ser posterior a la fecha de inicio",
    "END_DATE_REQUIRED_MESSAGE":"La fecha de finalización es obligatoria",
    "ENGAGE_BREADCRUMB_ADD":"Crear compromiso",
    "ENGAGE_BREADCRUMB_LIST":"Lista de participación",
    "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Color del botón de llamada a la acción",
    "ENGAGE_BUTTON_TEXT_COLOR":"Color del texto del botón",
    "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Color del texto del botón",
    "ENGAGE_BUTTON_URL_PLACEHOLDER":"Hipervínculo del botón de llamada a la acción",
    "ENGAGE_CTA_BUTTON_COLOR":"Color del botón CTA",
    "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Texto del botón de llamada a la acción",
    "ENGAGE_CUSTOMER_PAGENAME":"Panel de clientes",
    "ENGAGE_EFFECTIVE_FROM":"Válido desde",
    "ENGAGE_EXPIRES_ON":"Expira el",
    "ENGAGE_HEADER_ACTIONS":"Comportamiento",
    "ENGAGE_HEADER_ACTIONS_ADD":"Crear compromiso",
    "ENGAGE_HEADER_ACTIONS_BACK":"volver a la lista",
    "ENGAGE_HEADER_EFFECTIVE_FROM":"Válido desde",
    "ENGAGE_HEADER_ENTITES":"Entidades",
    "ENGAGE_HEADER_EXPIRES_ON":"Expira el",
    "ENGAGE_HEADER_MESSAGE":"Mensaje",
    "ENGAGE_HEADER_PAGE":"Página",
    "ENGAGE_HEADER_TITLE":"Título",
    "ENGAGE_NOTIFICATION_BACKGROUND":"Fondo",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el año 1500, cuando un impresor desconocido tomó una galera de tipos y la codificó para hacer un libro de muestras tipográficas.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Ofrecer descuentos para su tienda en línea puede ser un arma poderosa para generar lealtad.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Impulsar el marketing y confianza del producto de venta.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Ofrecer descuentos para mejorar en línea una tienda puede fidelizar armas en la conducción.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Comience con un diseño de sitio de marca, creación de contenido moderno.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Comience con un diseño de sitio moderno y personalícelo con su contenido de marca y características. Todos los blogs Premium incluyen CSS personalizado.",
    "ENGAGE_NOTIFICATION_BODY_TEXT":"Cuerpo de texto",
    "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"Notificación de participación creada con éxito",
    "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Esto excede la longitud mínima de 30",
    "ENGAGE_NOTIFICATION_PORTAL_PAGE":"página del portal",
    "ENGAGE_NOTIFICATION_TITLE_TEXT":"Texto del título",
    "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"Notificación de participación actualizada con éxito",
    "ENGAGE_PARTNER_PAGENAME":"Panel de socios",
    "ENGAGE_POPUP_BUTTON_URL":"URL del botón",
    "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Texto del botón CTA",
    "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Seleccionar todos los clientes",
    "ENGAGE_SUBMIT":"Entregar",
    "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":" El texto excede la longitud máxima de 15",
    "ENGAGE_THE_MAX_LENGTH":"La longitud máxima es de 250",
    "ENGAGE_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Seleccione al menos una factura para publicar en QuickBooks.",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Su solicitud para publicar facturas en QuickBooks se acepta y se pone en cola para su procesamiento. Haga clic en el botón <strong>Ver registros de publicaciones</strong> para obtener actualizaciones sobre el progreso.",
    "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"No se pudo poner en cola la solicitud para cargar facturas en QuickBooks en línea",
    "ENQUEUED_REQUEST_TO_CLONE_PLAN":"Solicitud en cola para clonar plan",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Solicitud en cola para crear un nuevo plan con todas las ofertas",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Solicitud en cola para crear un nuevo plan de revendedor con todas las ofertas",
    "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"No se pudo poner en cola la solicitud para cargar facturas en Xero",
    "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":" Ingrese un correo electrónico después de la coma o elimine la coma adicional",
    "ENTER_AZURE_NEW_MARKUP_VALUE":"Introduzca un nuevo valor de marcado",
    "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Ingrese el motivo del nuevo valor de marcado",
    "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Ingrese el motivo para actualizar el valor de marcado",
    "enterAddress1":"Introducir dirección 1",
    "enterAddress2":"Introducir dirección 2",
    "enterCity":"Introducir ciudad",
    "enterEmail":"Introducir correo electrónico",
    "enterPhoneNumber":"Introduzca el número de teléfono",
    "enterState":"Ingresar estado",
    "enterZip":"Ingresar código postal",
    "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Número de derechos que se crearán, sin exceder {{Count}}",
    "ENTITLEMENT_MANAGEMENT":"Administración de derechos de Azure",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":" El número de derechos a crear no puede exceder",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Los nombres de derechos comenzarán con esto.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"INSTRUCCIONES",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Cuando se anula el precio del derecho, este precio se aplicará tanto a los cálculos de factura como a las estimaciones de Azure.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Nota:</b>",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Utilice el botón <b>Anular</b> para modificar el precio del derecho.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Utilice el botón <b>Revertir</b> para restablecer el precio al precio predeterminado del plan de Azure para el derecho.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Utilice <b>Exportar</b> para descargar datos de derechos para un nivel específico de cliente e inquilino.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Recupere datos de derechos de todos los clientes activos a la vez seleccionando <b>Exportar todo</b>.",
    "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Acción",
    "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Agregar derecho",
    "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Agregar derechos",
    "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Completo",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Fecha de creación",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Estado de creación",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Departamento",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Nombre de Departamento",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Número de derechos",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"IDENTIFICACIÓN",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Lista de derechos",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Nombre",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Establecer nuevo precio para el derecho",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Exportar",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Exportar todo",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Exportar",
    "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Este precio es aplicable a partir del momento en que se genera la siguiente factura y continúa a partir de entonces.",
    "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Precio de venta",
    "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Secuencia de inicio",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Sitio",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Nombre del sitio",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":" Proceso completo. Consulte el registro de auditoría para obtener más detalles.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Se acepta la solicitud para agregar derechos y se está procesando",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La solicitud para agregar derechos falló.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Proporcione una secuencia numérica",
    "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"¿Está seguro de que desea revertir el precio de venta de este derecho?",
    "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"El precio del derecho se modificó correctamente.",
    "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"El precio del derecho se revirtió exitosamente.",
    "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"El incremento secuencial se utilizará como sufijo en los nombres de derechos.",
    "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Estado de creación de derechos",
    "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Espere mientras se crean los derechos.",
    "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"El valor debe ser mayor o igual a -100 y menor o igual a 100.",
    "ENTITY_DESC_AZURE_GROUP":"grupo azul",
    "ENTITY_DESC_BILLING_PERIODS":"Periodos de facturación",
    "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor de facturación",
    "ENTITY_DESC_BILLING_PROVIDER_USER":"Usuario del proveedor de facturación",
    "ENTITY_DESC_CART":"Carro",
    "ENTITY_DESC_CART_LINE_ITEM":"Línea de pedido del carrito",
    "ENTITY_DESC_COUPON":"Cupón",
    "ENTITY_DESC_CURRENCY":"Divisa",
    "ENTITY_DESC_CUSTOM_OFFER":"oferta personalizada",
    "ENTITY_DESC_CUSTOMER":"Cliente",
    "ENTITY_DESC_CUSTOMER_PRODUCT":"Producto del cliente",
    "ENTITY_DESC_CUSTOMER_USER":"Usuario cliente",
    "ENTITY_DESC_DEPARTMENT":"Departamento",
    "ENTITY_DESC_INVOICE":"Factura",
    "ENTITY_DESC_JOB_LOGS":"Registros de trabajo",
    "ENTITY_DESC_JOBS":"Trabajos",
    "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Cliente de tienda local",
    "ENTITY_DESC_LOCAL_STORE_RESELLER":"Revendedor de tienda local",
    "ENTITY_DESC_PARTNER":"Compañero",
    "ENTITY_DESC_PARTNER_SETTINGS":"Configuración de socios",
    "ENTITY_DESC_PARTNER_USER":"Usuario socio",
    "ENTITY_DESC_PAYMENT_PROFILE":"Perfil de pago",
    "ENTITY_DESC_PLAN":"Plan",
    "ENTITY_DESC_PLAN_PRODUCT":"Planificar producto",
    "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Planificar bono de producto",
    "ENTITY_DESC_PRODUCT":"Producto",
    "ENTITY_DESC_PRODUCT_VARIANT":"Variante de producto",
    "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Vale de variante de producto",
    "ENTITY_DESC_PROVIDER_CUSTOMER":"Cliente proveedor",
    "ENTITY_DESC_RESELLER":"Revendedores",
    "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Perfil de pago de revendedor",
    "ENTITY_DESC_RESELLER_PLAN":"plan revendedor",
    "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Cliente proveedor de servicios",
    "ENTITY_DESC_SHOP":" Comercio",
    "ENTITY_DESC_SITE":"Sitio",
    "ENTITY_DESC_SITE_DEPARTMENT":"departamento de sitio",
    "ENTITY_DESC_TAG":"Etiqueta",
    "ENTITY_DESC_TAX":"Impuesto",
    "ENTITY_DESC_TERMS_AND_CONDITIONS":"Términos y condiciones",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Actualizar la configuración del proveedor",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Error al actualizar la configuración del proveedor",
    "ENTITY_MAPPING":"Mapeo de entidades",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"¿Está seguro de que desea liberar la asignación?",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación para <b> {{customerName}}</b>?",
    "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Al menos mapa 1 producto",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Estos contratos ya no son predeterminados: {{ContractNames}}",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"No se permite el número de secuencia duplicado",
    "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Asignaciones guardadas con éxito",
    "ENTITY_NAME":"Nombre de la entidad",
    "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
    "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"País",
    "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Correo electrónico",
    "ENVIRONMENTS_TABLE_HEADER_STATUS":"Estado",
    "ERROR__DATA_CONTACT_TYPE":"Tipo de Contacto",
    "ERROR__DATA_PAYMENT_TYPE":"Tipo de pago",
    "ERROR__DATA_PROFILE_TYPE":"Tipo de perfil",
    "ERROR__DATA_URL":"URL",
    "ERROR_AVAILABLE_IN_BATCH":"El error está disponible en el lote.",
    "ERROR_BILLING_PROVIDER_NAME":"Nombre del proveedor de facturación",
    "ERROR_BUTTON_CONTINUE":"Continuar",
    "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Identificación del producto del cliente",
    "ERROR_C3_ENTITY_NAME":"Nombre de la entidad",
    "ERROR_C3_RECORD_ID":"Identificación de registro",
    "ERROR_C3_USER_ID":"ID de usuario",
    "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Cantidad de carrito no válida",
    "ERROR_DATA_BILLING_CUSTOMER_ID":"ID de cliente de facturación",
    "ERROR_DATA_BILLING_REFERENCE_ID":"ID de referencia de facturación",
    "ERROR_DATA_C3_CUSTOMER_ID":"Identificación del cliente C3",
    "ERROR_DATA_C3_RECORD_ID":"Identificación de registro",
    "ERROR_DATA_C3_RESELLER_ID":"ID del revendedor C3",
    "ERROR_DATA_C3_SUBSCRIPTION_ID":"Identificación de suscripción C3",
    "ERROR_DATA_COUNTRY_CODE":"Código de país",
    "ERROR_DATA_CUSTOMER_NAME":"Nombre del cliente",
    "ERROR_DATA_DOMAIN_PREFIX":"Nombre de dominio",
    "ERROR_DATA_EMAIL_ID":"Identificación de correo",
    "ERROR_DATA_ENTITY_NAME":"Nombre de la entidad",
    "ERROR_DATA_EVENT_NAME":"Nombre del evento",
    "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Tiene soporte para revendedores con ID de MPN",
    "ERROR_DATA_INPUT_JSON":"Datos de entrada",
    "ERROR_DATA_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
    "ERROR_DATA_PROVIDER_NAME":"Nombre del proveedor",
    "ERROR_DATA_RESELLER_ID":"Identificación de referencia",
    "ERROR_DATA_RESELLER_MPNID":"ID del proveedor revendedor",
    "ERROR_DATA_RESELLER_PROVIDER_ID":"ID del proveedor revendedor",
    "ERROR_DATA_SEARCH_VALUE":"Valor de búsqueda",
    "ERROR_DATA_SUBSCRIPTION_ID":"Identificación de suscripción",
    "ERROR_DATA_TAX_CODE":"Código de impuestos",
    "ERROR_DATA_TRANSACTION_REFERENCE":"Referencia de transacción",
    "ERROR_DATA_USER_ID":"Identificación de usuario",
    "ERROR_DELETE_DEPARTMENT":"Este departamento contiene registros asociados por lo que no se puede eliminar este registro.",
    "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Este departamento contiene productos comprados asociados por lo que no se puede eliminar este registro.",
    "ERROR_DELETE_SITE":"Este sitio contiene registros asociados, por lo que no se puede eliminar este registro.",
    "ERROR_DELETE_SITE_PRODUCT":"Este sitio contiene productos comprados asociados, por lo que no se puede eliminar este registro.",
    "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"No se puede crear un inquilino en el proveedor.",
    "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"El nombre del agente no puede ser nulo",
    "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"No hay respuesta de Authorize.net",
    "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"No se puede encontrar al cliente.",
    "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"No se puede encontrar este producto entre sus suscripciones",
    "ERROR_DESC_BAD_INPUT":"Parece que hay algún problema con tu entrada.",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
    "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una mala entrada.",
    "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Se produjo un error al guardar los detalles de la notificación personalizada",
    "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Se produjo un error al descargar el informe de consumo de licencia; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"La recuperación del cliente falló debido a una entrada incorrecta.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"La obtención de perfiles de pago falló debido a una mala entrada.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Error al obtener el estado de pago debido a una entrada incorrecta.",
    "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Ocurrió un error al obtener el informe de consumo de licencias, comuníquese con soporte.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"La acción falló porque falta el perfil de pago.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"La acción falló porque falta la configuración del perfil de pago",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Se produjo un error al guardar su perfil de pago; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Parece que hay algún problema con sus perfiles de pago; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Parece que hay algún problema con su proveedor de facturación; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Hay algún problema al guardar su perfil de pago; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Ocurrió un error al obtener el informe de consumo de licencias, comuníquese con soporte.",
    "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"No se pudo encontrar el proveedor",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"El archivo está vacío",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"El formato de archivo no es compatible",
    "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"El servicio no está implementado. Por favor contacte al soporte.",
    "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Parece haber algún problema con los tipos de pago admitidos por el proveedor de facturación; comuníquese con el soporte.",
    "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Se produjo un error al guardar los detalles de notificación del webhook",
    "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Solicitud incorrecta: hay otro trabajo en curso",
    "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Se produjo un error al crear/actualizar el cliente de facturación; comuníquese con el soporte.",
    "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Los revendedores integrados masivos no admitidos tienen un socio que no admite revendedores con ID de MPN",
    "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"El carrito tiene elementos de línea con errores. Corrígelos para continuar con esta compra.",
    "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Esta suscripción ya utilizada por este cliente",
    "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Ocurrió un error al cambiar el plan del cliente, comuníquese con soporte.",
    "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Se produjo un error al obtener los planes de origen para la función de cambio de plan, comuníquese con soporte.",
    "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Se produjo un error al obtener los planes de destino para la función de cambio de plan, comuníquese con soporte.",
    "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"La fecha de finalización de la comisión no puede ser inferior a la fecha de inicio de la comisión",
    "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"La fecha de finalización de la comisión no puede ser nula",
    "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"El porcentaje de comisión no puede ser nulo",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Error de ConnectWise Manage al intentar eliminar la adición por identificación",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Error de ConnectWise Manage al intentar obtener una adición por ID y ID de acuerdo",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Error de ConnectWise Manage al intentar obtener adiciones por ID de acuerdo",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener detalles del acuerdo por ID",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Error de ConnectWise Manage al intentar obtener acuerdos",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener detalles del acuerdo por ID",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Error de ConnectWise Manage al intentar obtener acuerdos",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Error de ConnectWise Manage al intentar obtener un elemento del catálogo por ID",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Error de ConnectWise Manage al intentar obtener artículos del catálogo",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Error de ConnectWise Manage al intentar obtener los detalles del cliente por ID",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Error de ConnectWise Manage al intentar conseguir clientes",
    "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Entradas duplicadas en pagos importados",
    "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entradas duplicadas en el catálogo",
    "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Entradas duplicadas en el catálogo",
    "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"Existe una entrada duplicada en la entrada",
    "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Hay errores en el formulario.",
    "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Se produjo un error al iniciar la referencia de datos de la entidad desde un servicio externo",
    "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId no es válido. registre su aplicación como aplicación nativa en https://dev.powerbi.com/apps y póngase en contacto con el soporte.",
    "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"GroupId no es válido, comuníquese con el equipo de soporte.",
    "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"La contraseña no es válida, comuníquese con el equipo de soporte.",
    "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"El informe no es válido, comuníquese con el equipo de soporte.",
    "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"El nombre de usuario no es válido, comuníquese con el equipo de soporte.",
    "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Se produjo un error al eliminar al cliente. Por favor contacte al soporte.",
    "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Se produjo un error al cargar tu imagen. Verifique las dimensiones de la imagen una vez más.",
    "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Error debido a una mala solicitud. Inténtalo de nuevo.",
    "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Falló debido a un conflicto. Por favor vuelva a verificar sus credenciales",
    "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Tiempo de espera de la puerta de enlace. Inténtalo de nuevo.",
    "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Servicio No Disponible. Por favor revisa la configuración",
    "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Se produjo un error al iniciar el pago, comuníquese con el soporte.",
    "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Ocurrió un error al descifrar el valor",
    "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Ocurrió un error al cifrar el valor",
    "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"El ID de la aplicación AD del proveedor del panel de control o el ID del inquilino del socio no existen.",
    "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"El ID de aplicación de AD de API de precios de Microsoft o el ID de inquilino de socio no existen.",
    "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Se produjo un error al intentar importar varios pagos",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Ocurrió un error al intentar importar la entrada del catálogo",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Ocurrió un error al intentar importar Comisiones de productos",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Error al importar los detalles de la extensión del producto",
    "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"No se pudo incorporar al revendedor con la incorporación masiva debido a errores de validación",
    "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Ocurrió un error al realizar la solicitud de registro",
    "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"No se pudo enviar el correo electrónico a la lista de usuarios dada. Revisar sus configuraciones de SMTP puede ayudar a resolver este problema.",
    "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Se produjo un error al intentar importar la entrada del registro de pago",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Ocurrió un error al preparar la entrada del catálogo de productos",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Ocurrió un error al preparar los detalles de las comisiones del producto",
    "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"No se pudo sincronizar la suscripción de instancias reservadas debido a la falta de disponibilidad del producto. Por favor contacte al equipo de soporte.",
    "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"No puede actualizar el porcentaje decimal a un valor del porcentaje disponible.",
    "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"No se pudo cargar la imagen. Verifique su conectividad a Internet y asegúrese de que el tamaño de la imagen coincida con las especificaciones proporcionadas.",
    "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Se produjo un error al obtener el estado del lote a bordo de los revendedores de proveedores de forma masiva",
    "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Se produjo un error al verificar el estado de los datos de la entidad remitidos desde un servicio externo",
    "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Ocurrió un error al recuperar los pagos pendientes. Póngase en contacto con el soporte.",
    "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"C3Id faltante o no válido en la entrada",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El costo para el socio debe ser + cinco números de Entrada",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El costo para el socio debe ser decimal de Entrada",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Código de moneda faltante o no válido en la entrada",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Código de moneda faltante o no válido",
    "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"El código de moneda debe coincidir con la moneda del producto de Entrada",
    "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Código de moneda no válido en la entrada",
    "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ID de cliente faltante o no válido en la entrada",
    "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"No puede cargar el uso para este GUID (cliente)",
    "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto interno no válido en el catálogo",
    "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Secuencia no válida en el catálogo",
    "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"El código de moneda debe coincidir con la entidad relevante",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Entradas no válidas en el catálogo",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Entradas no válidas en el catálogo",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Entradas no válidas en el catálogo",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Monto de pago faltante o no válido",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"El monto del pago debe ser positivo.",
    "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Formato no válido de fecha de pago.",
    "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Fecha de pago no válida",
    "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"ID de producto faltante o no válido en la entrada",
    "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"No puede cargar el uso del producto porque el producto no existe o no es de su propiedad.",
    "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto de proveedor no válido en el catálogo",
    "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La unidad de medida no debe ser un número entero/decimal de la entrada",
    "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Las unidades deben ser números enteros de Entrada",
    "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Las unidades deben ser + cinco números de Entrada",
    "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe tener el formato AAAA-MM-DD de Entrada",
    "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe ser el tipo de fecha de Entrada",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso debe ser posterior a la fecha de entrada",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"La fecha de pago debe ser pasada.",
    "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"No se genera factura por los productos comprados",
    "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"El artículo no se puede comprar",
    "ERROR_DESC_MATCHING_PLAN_OFFER":"Ocurrió un error al hacer coincidir las ofertas del plan para cambiar el plan, comuníquese con soporte.",
    "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"El cliente se creó correctamente, pero no pudo asignar los grupos de seguridad a la solicitud GDAP. Complete esto en el Centro de socios; de lo contrario, fallarán otras acciones.",
    "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Algo salió mal con la solicitud GDAP. Por favor, trabaje con su socio.",
    "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Discrepancia entre ProviderResellerId especificado y ResellerId en el proveedor",
    "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"Nombre de agente faltante en el catálogo",
    "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"Falta C3Id en la entrada",
    "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta la fecha de finalización de la comisión en el catálogo",
    "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Porcentaje de comisión faltante en el catálogo",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta CostToPartner en la entrada",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner es obligatorio para el porcentaje del tipo de facturación",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta el código de moneda en la entrada",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Falta código de moneda en la entrada",
    "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta CustomerId en la entrada",
    "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta el ID de producto interno en el catálogo",
    "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"Falta OfferId en el catálogo",
    "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Ciclo de facturación faltante o no válido en el catálogo",
    "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Categoría faltante o no válida en el catálogo",
    "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Proveedor faltante o no válido en el catálogo",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Verifique el archivo que cargó, ya que no parece coincidir con la plantilla recomendada.",
    "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Monto de pago faltante o no válido en la entrada",
    "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Falta fecha de pago en Entrada",
    "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Falta ProductId en la entrada",
    "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"ID de producto de proveedor faltante en el catálogo",
    "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"Falta el código SP en el catálogo",
    "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unidad de medida faltante en la entrada",
    "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Unidades faltantes en la entrada",
    "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fecha de uso faltante en la entrada",
    "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"No se pudo conectar la API de gestión de Microsoft Azure.",
    "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Error del centro de socios de Microsoft al actualizar el nombre del producto del cliente",
    "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Se produjo una excepción en el Centro de socios al intentar cambiar el estado de la suscripción. Por favor contacte al soporte.",
    "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Error del centro de socios al verificar MPNID. Por favor revise su entrada nuevamente.",
    "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Estamos enfrentando un problema al conectarnos al Centro de socios. Deje que el administrador actualice el token y vuelva a intentarlo. Póngase en contacto con el soporte para obtener más ayuda.",
    "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Se produjo un error al asignar grupos de seguridad al acceso de relación de administrador delegado del cliente",
    "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Error del centro de socios al crear usuario",
    "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Error del centro de socios al eliminar usuario",
    "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Se produjo un error al obtener los grupos de seguridad del agente administrativo de Microsoft",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Error del Centro de socios al intentar obtener todos los clientes",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Error del Centro de socios de Microsoft al intentar obtener todos los revendedores",
    "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Error del Centro de socios al intentar obtener reglas de validación de país",
    "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Error del Centro de socios al intentar conseguir clientes",
    "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Se produjo un error al obtener los detalles de la relación de administrador delegado del cliente del proveedor del centro de socios",
    "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS Partner Center arrojó un error al intentar obtener su perfil.",
    "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"No se pudo recuperar el token de actualización.",
    "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Error del centro de socios al obtener el usuario inquilino",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Error del centro de socios al obtener licencias asignadas al usuario",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Error del centro de socios al obtener detalles del usuario",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Error del centro de socios al obtener roles de usuario",
    "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Error del centro de socios al obtener usuarios",
    "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Error del Centro de socios al registrar el consentimiento del cliente",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Error del centro de socios al actualizar el usuario",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Error del centro de socios al actualizar las asignaciones de licencias de usuario",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Error del centro de socios al actualizar la contraseña del usuario",
    "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Error del centro de socios al obtener clientes.",
    "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"No se pudo obtener el acceso de la API de precios de Microsoft",
    "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Por favor revise de nuevo. Falta el archivo en la solicitud de validación de datos",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"No hay datos disponibles para preparar para Autotask",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"No hay datos disponibles para organizar ConnectWise Manage",
    "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"No se encontraron detalles de revendedores de proveedores en el centro de socios de Microsoft",
    "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"No se encuentra ningún revendedor con ID de MPN en C3",
    "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"El socio no es elegible para comprar el artículo",
    "ERROR_DESC_NOT_FOUND_ERROR":"No se puede encontrar la solicitud",
    "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Ha ocurrido un error al procesar su solicitud",
    "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Se produjo un error al programar el pedido.",
    "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"La incorporación falló debido a un inquilino no válido.",
    "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"La oferta para padres no está disponible para esta oferta de prueba.",
    "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Falta el perfil de pago. Proporcione el perfil de pago y vuelva a intentarlo.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una excepción del proveedor.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestión del perfil de pago falló debido a una excepción del proveedor.",
    "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"La eliminación del usuario falló debido a un error en el portal del proveedor de pagos.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"No se puede encontrar un cliente con identificación",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Error al obtener perfiles de pago debido a una excepción del proveedor.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"No se pudo obtener el estado de pago debido a una excepción del proveedor.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Se produjo un error al administrar la facturación al cliente. Por favor contacte al soporte.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Se produjo un error al administrar el perfil de pago. Por favor contacte al soporte.",
    "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Ocurrió un error al actualizar el estado del pago. Póngase en contacto con el soporte.",
    "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Mapeo de producto con cliente equivocado",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de inquilinos y categorías no admite productos ya asignados",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de categoría no admite productos ya asignados",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"La selección de inquilinos no admite productos ya asignados",
    "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"La fecha de uso cae en el período de suspensión del producto",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Proveedor BusinessId ya existe en el sistema",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"Proveedor BusinessId no puede ser nulo",
    "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"El correo electrónico de facturación no es válido",
    "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"El país del revendedor del proveedor no puede ser nulo",
    "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Provider ResellerId ya existe en el sistema",
    "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"Provider ResellerId no puede ser nulo",
    "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"El nombre del revendedor del proveedor no puede ser nulo",
    "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Detalles del revendedor del proveedor no vinculados a c3, comuníquese con su socio o equipo de soporte",
    "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Se produjo un error al analizar el archivo cargado",
    "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"La longitud de los caracteres de los comentarios debe ser inferior a 200.",
    "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Por favor revise de nuevo. Falta el archivo en la solicitud de validación de datos",
    "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"El revendedor ya existe en el sistema",
    "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Seleccione al menos un producto",
    "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"El pago falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"La recuperación del pago pendiente falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"La configuración del proveedor de facturación falló debido a una excepción de la base de datos.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"La asignación del cupón falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"La gestión de cupones falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"La gestión de clientes falló debido a una excepción de la base de datos.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"La gestión del perfil de pago falló debido a una excepción de la base de datos.",
    "ERROR_DESC_SQL_DELETE_CUSTOMER":"La eliminación del cliente falló debido a un error en la base de datos.",
    "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al agregar ofertas al plan. Póngase en contacto con soporte",
    "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al clonar el plan. Póngase en contacto con soporte",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Lamentamos informarle que se produjo una excepción en la base de datos al intentar realizar la solicitud del plan de clonación. Póngase en contacto con soporte",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Lamentamos informarle que se produjo una excepción en la base de datos al intentar realizar una solicitud para crear un nuevo plan con todas las ofertas. Póngase en contacto con soporte",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Error de la base de datos al guardar la identificación de MPN",
    "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
    "ERROR_DESC_SQL_GET_CUSTOMER":"La obtención del cliente falló debido a una excepción de la base de datos.",
    "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"La obtención de los perfiles de pago falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"La obtención del estado de pago falló debido a una excepción en la base de datos.",
    "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Error de la base de datos al obtener clientes.",
    "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"El número deseado de suscripciones 6 superó el número máximo de suscripciones permitidas por cliente 5",
    "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"No tienes permiso para cambiar el estado del producto ya que la propiedad se comparte con entidades operativas.",
    "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"La actualización del estado del producto no fue completamente exitosa; comuníquese con nuestro equipo de soporte para obtener ayuda.",
    "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"El código impositivo ya existe. Seleccione un código impositivo único.",
    "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"La configuración SMTP proporcionada es incorrecta",
    "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"No podemos procesar esta solicitud porque las transacciones están deshabilitadas.",
    "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"La oferta de prueba no se puede comprar con otras categorías.",
    "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"No se puede procesar su solicitud, comuníquese con el soporte",
    "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"No se puede procesar este pedido",
    "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"No estás autorizado a acceder a esta función",
    "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"La ubicación de uso no es válida",
    "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Ocurrió un error al validar el plan del cliente, comuníquese con soporte.",
    "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Error al acceder a la base de datos. Inténtelo de nuevo después de un tiempo. Si el error aún persiste, comuníquese con el soporte para obtener más ayuda.",
    "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Ocurrió un error al agregar el derecho",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Ocurrió un error al agregar el inquilino",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Se produjo un error al proporcionar el consentimiento para el inquilino",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Se creó el arrendatario, pero se produjo un error al agregar la suscripción",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Ocurrió un error al asignar el rol",
    "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Se produjo un error al cancelar la renovación programada",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"El ID de catálogo proporcionado no es válido",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Se produjo un error al verificar la elegibilidad de la promoción de Microsoft NCE",
    "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Se produjo un error al crear una suscripción de instancias reservadas mediante sincronización",
    "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Se produjo un error al crear la renovación programada",
    "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Error al poner en cola el mensaje para cargar el historial de suscripciones en el servicio PSA",
    "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Ocurrió un error al generar la factura masiva",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Se produjo un error al generar CSV para el informe de resumen de partidas de factura",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Ocurrió un error al generar CSV para elementos de línea de factura con informe de detalles de comisión",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Ocurrió un error al generar CSV para el informe de rentabilidad",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Ocurrió un error al generar CSV para el informe de resumen de montos de impuestos",
    "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Error al obtener los detalles del servicio PSA activo",
    "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Se produjo un error al intentar obtener categorías para comparar ingresos versus costos.",
    "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Se produjo un error al obtener los detalles de la suscripción.",
    "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Error al obtener la asignación de suscripciones de clientes a PSA",
    "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Ocurrió un error al obtener el conteo de clientes",
    "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Se produjo un error al intentar obtener detalles de ingresos versus costos por categoría",
    "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Se produjo un error al obtener los detalles de la suscripción al servicio externo mediante la identificación de la suscripción c3, la identificación del servicio del contrato y la identificación del contrato",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al obtener la lista de configuraciones administradas por el cliente",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al obtener la lista de configuraciones administradas por el revendedor",
    "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Se produjo un error al obtener la lista de fechas de finalización de la suscripción",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Error al obtener las entidades mapeadas y contratar servicios para Autotask",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Error al obtener las entidades y productos asignados para ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Ocurrió un error al obtener revendedores de proveedores no incorporados",
    "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Ocurrió un error al obtener los parámetros del portlet",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Ocurrió un error al obtener el conteo de productos",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Ocurrió un error al hacer que el producto cuente como en la fecha",
    "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Ocurrió un error al obtener el detalle de la promoción",
    "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Error al obtener el resumen del registro de publicación de PSA",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Ocurrió un error al obtener los asientos comprados por ID de SKU",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Ocurrió un error al obtener los asientos comprados en un período determinado",
    "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Ocurrió un error al obtener los ingresos frente al costo",
    "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Ocurrió un error al obtener el conteo de asientos",
    "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Ocurrió un error al hacer que los asientos contaran como en la fecha",
    "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Error al obtener la configuración de Autotask",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Error al obtener el estado del registro del servicio PSA",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Error al obtener los registros de publicación del servicio PSA",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Error al obtener los registros almacenados de PSA",
    "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Ocurrió un error al obtener los clientes valiosos",
    "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Ocurrió un error al obtener los productos comprados valorados por ingresos",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Error al administrar las entidades mapeadas y contratar servicios para Autotask",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Error al administrar las entidades y productos mapeados para ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Se produjo un error al intentar incorporar al revendedor del proveedor",
    "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Se produjo un error al procesar la solicitud de revendedores de proveedores integrados al por mayor",
    "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Se produjo un error al hacer cola para los revendedores de proveedores integrados al por mayor",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al revertir las configuraciones administradas por el cliente a las predeterminadas",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al revertir las configuraciones administradas por el revendedor a las predeterminadas",
    "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Se produjo un error al guardar o actualizar la fecha de finalización personalizada de la línea de carrito",
    "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Error al guardar los datos del historial de suscripción de los clientes seleccionados en la etapa de preparación",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Se produjo un error al actualizar las configuraciones administradas por el cliente",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Se produjo un error al actualizar las configuraciones administradas por el revendedor",
    "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Se produjo un error al actualizar la renovación programada",
    "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Error al actualizar el estado de los registros por etapas",
    "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Ocurrió un error al actualizar la suscripción",
    "ERROR_DETAILS":"Error de detalles",
    "ERROR_DUPLICATE_DEPARTMENT_NMAE":"El nombre de este departamento ya existe en este cliente y sitio.",
    "ERROR_DUPLICATE_SITE_NMAE":"El nombre de este sitio ya existe bajo este cliente.",
    "ERROR_EMPTY_COMMENTS_SUBMITTED":"no se pueden enviar comentarios vacíos",
    "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"No se pudieron importar los detalles de pago",
    "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Error al importar los detalles de la Comisión del producto",
    "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"No se pudo agregar por etapas la entrada masiva de inquilinos",
    "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Error al generar detalles en detalles de entidad de factura masiva",
    "ERROR_FIELD_REQUIRED":"Este campo es obligatorio",
    "ERROR_FORM_INVALID":"El formulario no es válido.",
    "ERROR_GETTING_HOSTED_TOKEN":"Error al obtener el token alojado",
    "ERROR_GETTING_STRIPE_DETAILS":"Se produjo un error al obtener los detalles de la franja",
    "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Parece que no tienes los permisos adecuados para realizar esta acción.",
    "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"¡Prohibido!",
    "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"No se puede actualizar el estado del producto porque la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
    "ERROR_MESSAGE":"¡Algo salió mal!",
    "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"La aprobación no fue exitosa, comuníquese con el soporte",
    "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Se debe seleccionar al menos un cliente",
    "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"No tienes permiso para reducir asientos.",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Ocurrió un error al crear el plan. Póngase en contacto con nuestro equipo de soporte.",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Ocurrió un error al crear el plan de revendedor. Póngase en contacto con nuestro equipo de soporte.",
    "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Lamentamos que la conectividad parezca rota entre su cuenta C3 y Autotask. Esto puede provocar fallas al realizar todas las transacciones de Autotask a través de C3. Establezca la conectividad visitando <b>Configuración -> Autotask</b>",
    "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Lamentamos que la conectividad parezca interrumpida entre su cuenta C3 y Connectwise. Esto puede provocar fallas al realizar todas las transacciones de Connectwise a través de C3. Establezca la conectividad visitando <b>Configuración -> Administrar Connectwise</b>",
    "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"La configuración de SMTP no es válida",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Lo sentimos, pero parece que la conectividad entre su cuenta C3 y el Centro de socios de Microsoft está interrumpida. Esto puede provocar un error al realizar todas las transacciones del Centro de partners a través de C3. Establezca la conectividad visitando <b>Configuración -> Proveedores</b>",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Lo sentimos, pero parece que la conectividad entre su cuenta C3 y la API de precios para socios de Microsoft está rota. Esto puede provocar un error al obtener detalles de la API de precios de Microsoft a través de C3. Establezca la conectividad visitando <b>Configuración -> Proveedores</b>",
    "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Lamentamos que la conectividad parezca interrumpida entre su cuenta C3 y Quickbooks. Esto puede provocar fallas al realizar todas las transacciones de Quickbooks a través de C3. Establezca la conectividad visitando <b>Analizar -> Negocios -> Descargar facturas/Pagos</b>",
    "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Lamentamos que la conectividad parezca rota entre su cuenta C3 y Xero. Esto puede provocar fallas al realizar todas las transacciones de Xero a través de C3. Establezca la conectividad visitando <b>Analizar -> Negocios -> Descargar facturas/Pagos</b>",
    "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Número de carrito no válido",
    "ERROR_MESSAGE_INVALID_CUSTOMER":"Cliente no válido",
    "ERROR_MESSAGE_INVALID_TENANT_USER":"El usuario inquilino no es válido",
    "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"El cambio de estado no es válido.",
    "ERROR_MESSAGE_MANAGE_OWNERSHIP":"El campo 'Cantidad' es obligatorio y no puede ser nulo ni un número decimal. Ingrese un número entero válido.",
    "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Se pueden seleccionar hasta 3 mercados.",
    "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"No se encontró ningún mensaje para solicitar una relación de revendedor, comuníquese con el soporte de C3",
    "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"El carrito contiene ofertas que no son de socios: ofertas compatibles con licencia, elimínelas para continuar.",
    "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"El usuario con este correo electrónico ya existe o no tienes permiso para agregar este usuario",
    "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Las compras pueden fallar. Por favor, haga clic para saber más.",
    "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"No se permite la disminución de cantidad",
    "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Este cliente no está vinculado con Microsoft csp",
    "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"No se puede procesar su solicitud.",
    "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Ha introducido más comprobantes de los necesarios",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Se produjo un error al exportar el informe de partidas individuales de la factura. Comuníquese con el administrador.",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Se produjo un error al exportar el informe resumido de la factura. Comuníquese con el administrador.",
    "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Se produjo un error al cargar el archivo.",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Error al subir la imagen. Inténtelo de nuevo con otra imagen.",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Seleccione la extensión de la imagen, por ejemplo: .jpg, .png, etc.",
    "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Error del centro de socios al verificar MPNID. Por favor revise su entrada nuevamente.",
    "ERROR_MS_FAILED_TO_CREATE_USER":"Error del centro de socios al crear usuario",
    "ERROR_MS_FAILED_TO_DELETE_USER":"Error del centro de socios al eliminar usuario",
    "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Error del centro de socios al obtener el usuario inquilino",
    "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Error del centro de socios al obtener detalles del usuario",
    "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Error del centro de socios al obtener roles de usuario",
    "ERROR_MS_FAILED_TO_GET_USERS":"Error del centro de socios al obtener usuarios",
    "ERROR_MS_FAILED_TO_UPDATE_USER":"Error del centro de socios al actualizar el usuario",
    "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Error del centro de socios al actualizar las asignaciones de licencias de usuario",
    "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Error del centro de socios al actualizar la contraseña del usuario",
    "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Se produjo un error al intentar poner en cola una solicitud para actualizar las últimas ofertas al plan.",
    "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Ocurrió un error al verificar la elegibilidad de las promociones.",
    "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Ocurrió un error al contar las promociones en el mes actual.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Ocurrió un error al obtener el detalle de las promociones.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Ocurrió un error al obtener los detalles de las promociones del proveedor.",
    "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Ocurrió un error al cargar las promociones.",
    "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Ha ocurrido un error al procesar su solicitud",
    "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"Se produjo un error al guardar los detalles de la actualización de la suscripción en la tabla de preparación",
    "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Se produjo un error al programar el pedido. Por favor contacte con soporte",
    "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"Se produjo un error al intentar obtener los productos elegibles para la actualización de la suscripción",
    "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Ocurrió un error al actualizar la identificación de la promoción en cartlineItem.",
    "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Falta el producto al importar los datos de uso diario de la oferta del socio",
    "ERROR_PLAN_ID":"identificación del plan",
    "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Confirme la aceptación del cliente del Acuerdo de cliente de Microsoft.",
    "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Error al importar datos de uso diario de ofertas de socios",
    "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Ocurrió un error al intentar importar Comisiones de productos",
    "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"La secuencia de productos tiene valores no válidos o negativos. Proporcione una secuencia válida.",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"El inquilino que está intentando incorporar/vincular ya existe en el portal; comuníquese con el soporte técnico.",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"El inquilino que está intentando incorporar/vincular existe con uno de sus clientes. Pruebe con otro inquilino.",
    "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"El elemento de la línea de cotización tiene valores no válidos o negativos. Indique una cantidad válida entre 1 y 9999.",
    "ERROR_RESELLER_PROVIDER_ID":"ID del proveedor revendedor",
    "ERROR_SAVING_CUSTOMER":"Error al guardar el cliente",
    "ERROR_SAVING_PARTNER_OFFER":"Ocurrió un error al guardar la oferta del socio",
    "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"El paquete debe contener ofertas de MS de la misma categoría.",
    "ERROR_SAVING_PAYMENT_ACCOUNT":"Error al guardar la cuenta de pago",
    "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
    "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Error de la base de datos al guardar la identificación de MPN",
    "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Error de la base de datos al intentar guardar el usuario del cliente",
    "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Sólo las ofertas personalizadas son elegibles para la oferta de prueba.",
    "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Sólo las ofertas basadas en cantidad son elegibles para la oferta de prueba.",
    "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"No se puede encontrar un inquilino proveedor.",
    "ERROR_UPDATING_AUTO_RENEW_STATUS":"La actualización de renovación automática falló para {{productName}}.",
    "ERROR_WHILE_ACCESSING_DATABASE":"Error al acceder a la base de datos",
    "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Ocurrió un error al intentar verificar que el revendedor se haya vinculado con el proveedor",
    "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Se produjo un error al descargar el archivo de plantilla de carga masiva de muestra .csv",
    "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Error al generar el informe del catálogo de productos",
    "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Se produjo un error al obtener el historial de consentimiento de renovación",
    "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Se produjo un error al obtener las fechas de finalización del centro de socios. Por favor contacte al soporte.",
    "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Se produjo un error al intentar obtener las cuotas de la factura.",
    "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Ocurrió un error al obtener los puestos de producto valorados",
    "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Seleccione al menos una factura para publicarla en Xero.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Error: Abandono de la carga debido a un error al crear entidades",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Error: La adición está activa en ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del acuerdo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Error: La facturación no está habilitada para este acuerdo en ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Error: El acuerdo asignado no está activo en ConnectWise Manage con este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Error: El acuerdo no está presente en ConnectWise Manage con este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Error: Los acuerdos no están asignados para este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Error: Los acuerdos no están presentes en ConnectWise Manage bajo este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Error: Hay adiciones ambiguas, no es posible realizar el mapeo.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del contrato",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Error: La facturación no está habilitada para este contrato en Autotask",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Error: El contrato asignado no está activo en Autotask bajo este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Error: El contrato no está presente en Autotask bajo este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: El contrato no está presente en el servicio externo de este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: El servicio contratado no está asignado a este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Error: El servicio de contrato asignado no está presente en Autotask",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: Los servicios del contrato no están asignados a este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Error: Los contratos no están asignados a este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Error: Los contratos no están presentes en Autotask bajo este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: Los contratos no están presentes en el servicio externo bajo este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Error: El cliente no está activo en el servicio externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Error: El cliente externo no está asignado al cliente c3",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Error: El cliente no está presente en el servicio externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Error: Falta la asignación de entidad o producto.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Error: Se intentó exportar anteriormente. El estado de esta operación es",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Error: El contrato no está activo en el servicio externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID de cliente externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Error: Los clientes no están presentes en el servicio externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Error: Se produjo un error al crear una adición en ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Error: Se produjo un error al crear un ajuste:",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Error: Se produjo un error al crear el ajuste para el cambio de precio",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Error: Se produjo un error al crear el servicio",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Error: No se pudo crear el cargo del contrato para un producto de uso único",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Error: Se produjo un error al crear el servicio de contrato:",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Error: Se produjo un error al actualizar la adición en ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Error: No se puede crear una asignación porque se encontraron varios servicios coincidentes en Autotask",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Error: No se pudieron encontrar contratos coincidentes para asignar automáticamente.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Error: No se pudo encontrar ningún cliente externo coincidente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Error: No se encontró ningún producto externo que coincida",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: El producto no está asignado a este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Error: El producto asignado no está presente en ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Error: Los productos no están asignados a este cliente",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Error: Los productos C3 no tienen servicio externo asignado",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Error: Falta el ID del servicio",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del acuerdo.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del acuerdo.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del contrato.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del contrato.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del acuerdo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del acuerdo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de la facturación del contrato",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Error: La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del contrato",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Error: La suscripción está en Autotask activa",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Error: La suscripción está inactiva en el servicio externo",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Error: No se puede completar la asignación automática ya que se encontraron varios contratos coincidentes",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Error: No se puede completar la asignación automática porque se emparejaron varios clientes externos",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Error: No se puede completar la asignación automática porque se combinaron varios productos externos",
    "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Error: La validación no fue exitosa",
    "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
    "ERRORMESSAGEPOPUP_HEADER":"Incluya al menos uno de los requisitos previos para los complementos en la siguiente lista",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Añadir",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Oferta de requisito previo",
    "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Error: El acuerdo PSA no está asignado al cliente c3",
    "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Error: El producto externo no está asignado al producto c3",
    "ERRORS_FROM_PREVIOUS_ACTION":"Su última acción resultó en errores, haga clic para obtener más detalles...",
    "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Se produjo un error al procesar su solicitud. Por favor contacte a Soporte.",
    "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"ID de suscripción del plan de Azure",
    "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Nombre del plan de Azure",
    "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"Id. de actualización",
    "EVENT_BATCH_ID":"ID del lote",
    "EVENT_BATCH_STEP_ID":"ID de paso de lote",
    "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Resumen de lotes de revendedores integrados en masa",
    "EVENT_CAN_PRICE_LAG":"Retraso en el precio de OldCan",
    "EVENT_CAN_PRICE_LEAD":"NuevoCanPriceLead",
    "EVENT_CONSTANT_TRUE":"VERDAD",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Continuar con la oferta de prueba hasta que falle la suscripción",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Continuar con la oferta de prueba hasta lograr el éxito de la suscripción",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Convertir oferta de prueba en error de suscripción",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Convierta la oferta de prueba en una suscripción exitosa",
    "EVENT_CREATED_BY":"Evento creado por",
    "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Identificación interna",
    "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Correo electrónico",
    "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Nombre de pila",
    "EVENT_DATA_ACCOUNT_MANAGER_ID":"Identificación del administrador de cuenta",
    "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Apellido",
    "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Número de teléfono",
    "EVENT_DATA_ACCOUNT_NUMBER":"Número de cuenta",
    "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Acción",
    "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Error",
    "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Estado",
    "EVENT_DATA_ADDRESS_CITY":"Ciudad",
    "EVENT_DATA_ADDRESS_COUNTRY":"País",
    "EVENT_DATA_ADDRESS_LINE_1":"Dirección Línea 1",
    "EVENT_DATA_ADDRESS_LINE_2":"Línea de dirección 2",
    "EVENT_DATA_ADDRESS_STATE":"Estado",
    "EVENT_DATA_ADDRESS_ZIP_CODE":"Cremallera",
    "EVENT_DATA_AGREEMENT":"Convenio",
    "EVENT_DATA_AGREEMENT_DATE":"Fecha de acuerdo",
    "EVENT_DATA_AGREEMENT_LINK":"Enlace del acuerdo",
    "EVENT_DATA_AGREEMENT_NAME":"Convenio",
    "EVENT_DATA_AGREEMENT_TYPE":"Tipo de acuerdo",
    "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"¿La asignación de grupos de seguridad de Microsoft a la solicitud GDAP se realizó correctamente?",
    "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Cupón asignado a",
    "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":" Nuevo valor",
    "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Valor antiguo",
    "EVENT_DATA_AUTO_SYNC_ACTION":"Comportamiento",
    "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Error",
    "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Razón",
    "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Estado",
    "EVENT_DATA_BCC_RECIPIENTS":"Destinatarios BCC",
    "EVENT_DATA_BILLING_CYCLE_NAME":"Nombre del ciclo de facturación",
    "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Alias de pago",
    "EVENT_DATA_BILLING_PERIOD_ID":"ID del período de facturación",
    "EVENT_DATA_BILLING_PROVIDER":"Proveedor de facturación",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor de facturación",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor de facturación",
    "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor de facturación",
    "EVENT_DATA_BILLING_PROVIDER_USER":"ID de usuario",
    "EVENT_DATA_BILLING_PROVIDER_USER_ID":"ID de usuario",
    "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Nombre del paquete",
    "EVENT_DATA_C3_CUSTOMER_NAME":"Cliente C3",
    "EVENT_DATA_C3_PRODUCT_NAME":"Producto C3",
    "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Nombre de suscripción C3",
    "EVENT_DATA_CARD_TYPE":"Tipo de tarjeta",
    "EVENT_DATA_CART_ID":"ID del carrito",
    "EVENT_DATA_CART_ITEMS":"Artículos del carrito",
    "EVENT_DATA_CATEGORY_ID":"Categoria ID",
    "EVENT_DATA_CATEGORY_NAME":"nombre de la categoría",
    "EVENT_DATA_CC_RECIPIENTS":"Destinatarios de CC",
    "EVENT_DATA_CHANGE_TYPE":"Tipo de cambio",
    "EVENT_DATA_CLONED_FROM_PLAN":"Clonado de",
    "EVENT_DATA_COMPANY_NAME":"nombre de empresa",
    "EVENT_DATA_CONSUMED_SEATS":"Asientos consumidos",
    "EVENT_DATA_CONSUMPTION_TYPE":"Tipo de consumo",
    "EVENT_DATA_CONTRACT":"Contrato",
    "EVENT_DATA_CONTRACT_NAME":"Nombre del contrato",
    "EVENT_DATA_CONTRACT_SERVICE_NAME":"Nombre del servicio del contrato",
    "EVENT_DATA_CONVERSION_RATE":"Tasa de conversión",
    "EVENT_DATA_CORRELATION_ID":"ID de correlación",
    "EVENT_DATA_COUNTRY_CODE":"Código de país",
    "EVENT_DATA_COUPON_CODE":"Código promocional",
    "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Número de clientes con nuevas asignaciones",
    "EVENT_DATA_COUPON_DESCRIPTION":"Descripción del cupón",
    "EVENT_DATA_COUPON_DETAILS":"Detalles del cupón",
    "EVENT_DATA_COUPON_DISCOUNT":"Descuento",
    "EVENT_DATA_COUPON_EXPIRES_ON":"Expira el",
    "EVENT_DATA_COUPON_NOOFRECURRENCES":"Número de recurrencias",
    "EVENT_DATA_COUPON_VALID_TILL":"Válida hasta",
    "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Creado por en proveedor",
    "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Fecha de creación en el proveedor",
    "EVENT_DATA_CURRENCY_CODE":"Código de moneda",
    "EVENT_DATA_CURRENT_QUANTITY":"Cantidad actual",
    "EVENT_DATA_CURRENT_STATUS":"Estado actual",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Nuevo ciclo de facturación personalizado",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Antiguo ciclo de facturación personalizado",
    "EVENT_DATA_CUSTOMER_C3_ID":"Identificación del cliente C3",
    "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Consentir",
    "EVENT_DATA_CUSTOMER_NAME":"Nombre del cliente",
    "EVENT_DATA_CUSTOMER_NEW_NAME":"Nuevo nombre del cliente",
    "EVENT_DATA_CUSTOMER_OLD_NAME":"Nombre antiguo del cliente",
    "EVENT_DATA_CUSTOMER_REFERENCE_ID":"ID de referencia del cliente",
    "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"ID de lote de registro de cliente",
    "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"planid interno",
    "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"¿Se están desactivando todos los registros de PSA?",
    "EVENT_DATA_DEPARTMENT_NAME":"Nombre de Departamento",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Ciclo de facturación",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Nombre",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Precio de coste",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Precio de venta",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Validez",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Tipo de validez",
    "EVENT_DATA_DOMAIN":"Dominio",
    "EVENT_DATA_DOMAIN_NAME":"Nombre de dominio",
    "EVENT_DATA_EFFECTIVE_FROM":"Válido desde",
    "EVENT_DATA_EMAIL":"Correo electrónico",
    "EVENT_DATA_EMAIL_ADDRESS":"Correo electrónico",
    "EVENT_DATA_END_BILLING_PERIOD_ID":"ID del período de finalización de la facturación",
    "EVENT_DATA_END_DATE":"Fecha final",
    "EVENT_DATA_ENTITY_ID":"identificación de la entidad",
    "EVENT_DATA_ENTITY_NAME":"Nombre de la entidad",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entidad de usuario",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entidad de usuario",
    "EVENT_DATA_ERROR":"Error",
    "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Mapeo de productos con error",
    "EVENT_DATA_EVENT":"Evento",
    "EVENT_DATA_EVENT_ID":"ID de evento",
    "EVENT_DATA_EVENT_NAME":"Nombre del evento",
    "EVENT_DATA_EXCEPTION_MESSAGE":"Mensaje de excepción",
    "EVENT_DATA_EXPIRES_ON":"Expira el",
    "EVENT_DATA_EXPORT_ERROR":"Error de exportación",
    "EVENT_DATA_EXPORT_STATUS":"Estado de exportación",
    "EVENT_DATA_EXTERNAL_AGREEMENTS":"Acuerdos externos",
    "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Servicios de contrato externo",
    "EVENT_DATA_EXTERNAL_CONTRACTS":"Contratos externos",
    "EVENT_DATA_EXTERNAL_CUSTOMERS":"Cliente de PSA",
    "EVENT_DATA_EXTERNAL_PRODUCTS":"Producto PSA",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Identificación del cliente",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"ID del historial de suscripciones",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Identificación de suscripción",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Identificación del contrato",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"ID de servicio de contrato",
    "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"ID de cliente externo",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Error de exportación",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Estado de exportación",
    "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Tipo de mapeo de productos",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Error de validacion",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Estado de validación",
    "EVENT_DATA_FAILED_TENANT_ID":"ID de inquilino fallido",
    "EVENT_DATA_GROUP_DESCRIPTION":"Descripción del Grupo",
    "EVENT_DATA_GROUP_NAME":"Nombre del grupo",
    "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"Tiene detalle adicional",
    "EVENT_DATA_IMPERSONATOR":"Imitador",
    "EVENT_DATA_INPUT_DATA":"Detalle de la solicitud",
    "EVENT_DATA_INTERNAL_CUSTOMER_ID":"ID de cliente interno",
    "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"ID de producto del cliente interno",
    "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Identificación interna para nueva renovación programada",
    "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Identificación interna para la antigua renovación programada",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId del plan de origen",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId del plan de destino",
    "EVENT_DATA_INTERNAL_PRODUCT_ID":"Identificación del producto C3",
    "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"ID de suscripción interna",
    "EVENT_DATA_INVOICE_LINEITEM":"Nombre de la partida de la factura",
    "EVENT_DATA_INVOICE_NUMBER":"Número de factura",
    "EVENT_DATA_INVOICE_PROPERTY":"Nombre de la propiedad",
    "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Valor antiguo",
    "EVENT_DATA_INVOICE_PROPERTY_VALUE":" Nuevo valor",
    "EVENT_DATA_IS_ACCEPTED":"¿Se acepta la solicitud?",
    "EVENT_DATA_IS_ENABLED":"Está habilitado",
    "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"¿Este producto está disponible para su uso inmediato?",
    "EVENT_DATA_IS_SHOW_ON_UI":"Se muestra en la pantalla",
    "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
    "EVENT_DATA_LINE_ITEM":"Elemento en linea",
    "EVENT_DATA_LINKED_PRODUCT_NAME":"Nombre del producto vinculado",
    "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Nombre del producto vinculado",
    "EVENT_DATA_LOGGED_IN_USER":"Usuario registrado",
    "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Administrar detalles de licencia de usuario",
    "EVENT_DATA_MESSAGE":"Mensaje",
    "EVENT_DATA_Name":"Nombre",
    "EVENT_DATA_NAME":"Nombre",
    "EVENT_DATA_NAME_OF_ENTITY":"Nombre de la entidad",
    "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Nuevo valor",
    "EVENT_DATA_NEW_COUPON_CODE":"Nuevo código de cupón",
    "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Nueva descripción del cupón",
    "EVENT_DATA_NEW_COUPON_DISCOUNT":"Nuevo cupón de descuento",
    "EVENT_DATA_NEW_COUPON_EXPIRESON":"El nuevo cupón caduca el",
    "EVENT_DATA_NEW_COUPON_NAME":"Nuevo nombre de cupón",
    "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Nuevo cupón no de recurrencias",
    "EVENT_DATA_NEW_COUPON_VALIDTILL":"Nuevo cupón válido hasta",
    "EVENT_DATA_NEW_DESCRIPTION":"Descripción del nuevo plan",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción de la oferta del nuevo distribuidor",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Nueva oferta de distribuidor",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Nueva oferta de distribuidor",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Nuevo nombre de oferta de distribuidor",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Nueva oferta de distribuidor",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Nueva oferta de distribuidor",
    "EVENT_DATA_NEW_END_DATE_TYPE":"Nuevo tipo de fecha de finalización",
    "EVENT_DATA_NEW_ENTITY_NAME":"Nueva entidad",
    "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Transferido a",
    "EVENT_DATA_NEW_ISPUBLIC":"Nuevo plan es público",
    "EVENT_DATA_NEW_MARKUP":"Nuevo marcado",
    "EVENT_DATA_NEW_NAME":"Nuevo nombre del plan",
    "EVENT_DATA_NEW_OFFER":"Nueva oferta",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Descripción de la oferta de nuevo socio",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Nueva oferta de socios",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Nueva oferta de socios",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Nuevo nombre de oferta de socio",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Nueva oferta de socios",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Nueva oferta de socios",
    "EVENT_DATA_NEW_PLAN":"Nuevo plan",
    "EVENT_DATA_NEW_PRICE":"Nuevo precio",
    "EVENT_DATA_NEW_PRODUCT_MAPPING":"Mapeo de nuevos productos",
    "EVENT_DATA_NEW_PROMOTION_ID":"Nueva identificación de promoción",
    "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Nueva fecha de finalización efectiva",
    "EVENT_DATA_NEW_QUANTITY":"Nueva cantidad",
    "EVENT_DATA_NEW_SEATS":"Asientos nuevos",
    "EVENT_DATA_NEW_STATUS":"Nuevo estado",
    "EVENT_DATA_NEW_VALIDITY":"Nueva Vigencia",
    "EVENT_DATA_NEW_VALIDITY_TERM":"Nueva Vigencia",
    "EVENT_DATA_NEW_VALIDITYTYPE":"Nuevo tipo de validez",
    "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Destinatarios de la notificación",
    "EVENT_DATA_OFFER_NAME":"Nombre de la oferta",
    "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Precio de oferta después del descuento",
    "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Valor antiguo",
    "EVENT_DATA_OLD_COUPON_CODE":"Código de cupón antiguo",
    "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Descripción del cupón antiguo",
    "EVENT_DATA_OLD_COUPON_DISCOUNT":"Antiguo cupón de descuento",
    "EVENT_DATA_OLD_COUPON_EXPIRESON":"El cupón anterior caduca el",
    "EVENT_DATA_OLD_COUPON_NAME":"Nombre antiguo del cupón",
    "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Número de cupón antiguo de recurrencias",
    "EVENT_DATA_OLD_COUPON_VALIDTILL":"Cupón antiguo válido hasta",
    "EVENT_DATA_OLD_DESCRIPTION":"Descripción del plan antiguo",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Descripción de la oferta del distribuidor anterior",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Oferta de distribuidor antigua",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Oferta de distribuidor antigua",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Nombre de oferta de distribuidor anterior",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Oferta de distribuidor antigua",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Oferta de distribuidor antigua",
    "EVENT_DATA_OLD_ENTITY_ID":"Entidad antigua",
    "EVENT_DATA_OLD_ENTITY_NAME":"Entidad antigua",
    "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transferido desde",
    "EVENT_DATA_OLD_ISPUBLIC":"El plan antiguo es público",
    "EVENT_DATA_OLD_NAME":"Nombre del plan antiguo",
    "EVENT_DATA_OLD_OFFER":"Oferta anterior",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Descripción antigua de la oferta de socios",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Oferta de antiguo socio",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Oferta de antiguo socio",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Antiguo nombre de oferta de socio",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Oferta de antiguo socio",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Oferta de antiguo socio",
    "EVENT_DATA_OLD_PLAN":"plano antiguo",
    "EVENT_DATA_OLD_PRICE":"Precio anterior",
    "EVENT_DATA_OLD_QUANTITY":"Cantidad antigua",
    "EVENT_DATA_OLD_SEATS":"Asientos antiguos",
    "EVENT_DATA_OLD_STATUS":"Estado antiguo",
    "EVENT_DATA_OLD_VALIDITY":"Vigencia antigua",
    "EVENT_DATA_OLD_VALIDITY_TERM":"Vigencia antigua",
    "EVENT_DATA_OLD_VALIDITYTYPE":"Tipo de validez antiguo",
    "EVENT_DATA_PAID_AMOUNT":"Monto de pago",
    "EVENT_DATA_PAID_DATE":"Fecha de pago",
    "EVENT_DATA_PARENT_PRODUCT_NAME":"Nombre del producto principal",
    "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Id. de suscripción al plan de Azure",
    "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Nombre del producto principal",
    "EVENT_DATA_PARTNER_NAME":"Nombre del socio",
    "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Alias del perfil de pago",
    "EVENT_DATA_PAYMENT_PROFILE_ID":"ID del perfil de pago",
    "EVENT_DATA_PAYMENT_TYPE":"Tipo de pago",
    "EVENT_DATA_PHONE_NUMBER":"Número de teléfono",
    "EVENT_DATA_PLAN_COUNT":"No. de planes",
    "EVENT_DATA_PLAN_DESCRIPTION":"Descripción",
    "EVENT_DATA_PLAN_ID":"identificación del plan",
    "EVENT_DATA_PLAN_NAME":"Nombre del plan",
    "EVENT_DATA_PLAN_PRODUCT_ID":"ID del producto del plan",
    "EVENT_DATA_PLAN_TYPE":"Tipo de plan",
    "EVENT_DATA_PRICE":" Precio",
    "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"ID de suscripción del producto C3",
    "EVENT_DATA_PRODUCT_MAPPING":"Mapeo de productos",
    "EVENT_DATA_PRODUCT_NAME":"Nombre del producto",
    "EVENT_DATA_PRODUCT_NEW_NAME":"Nuevo nombre del producto",
    "EVENT_DATA_PRODUCT_PRICE":"Precio del producto",
    "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"ID de suscripción del producto",
    "EVENT_DATA_PROMOTION_ID":"Identificación de la promoción",
    "EVENT_DATA_PROVIDER":"Proveedor",
    "EVENT_DATA_PROVIDER_BUSINESS_ID":"ID de empresa del proveedor",
    "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Cliente proveedor d",
    "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor",
    "EVENT_DATA_PROVIDER_ENTITY_NAME":"Nombre de la entidad proveedora",
    "EVENT_DATA_PROVIDER_ID":"Identificación del proveedor",
    "EVENT_DATA_PROVIDER_NAME":"Nombre del proveedor",
    "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"ID del perfil de pago del proveedor",
    "EVENT_DATA_PROVIDER_PRICE":"precio del proveedor",
    "EVENT_DATA_PROVIDER_PRODUCT_ID":"Identificación de suscripción",
    "EVENT_DATA_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
    "EVENT_DATA_PROVIDER_RESELLER_NAME":"Nombre del revendedor del proveedor",
    "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
    "EVENT_DATA_PSA_CUSTOMER_NAME":"Cliente de PSA",
    "EVENT_DATA_PSA_LOG_ID":"ID del lote",
    "EVENT_DATA_PSA_NAME":"Nombre del anuncio de servicio público",
    "EVENT_DATA_PSA_PRODUCT_NAME":"Producto PSA",
    "EVENT_DATA_QUANTITY":"Cantidad comprada",
    "EVENT_DATA_RECORD_ID":"Identificación de registro",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"ID de registro del usuario",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"ID de registro del usuario",
    "EVENT_DATA_RECORD_INT_ID":"Registro de identificación interna",
    "EVENT_DATA_RECORD_NAME":"Nombre de registro",
    "EVENT_DATA_RELEASED_SEATS":"Asientos Liberados",
    "EVENT_DATA_RENEWS_EXPIRES_ON":"Se renueva/expira el",
    "EVENT_DATA_RENEWS_ON":"se renueva el",
    "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Estado de renovación automática del producto C3",
    "EVENT_DATA_REQUESTED_QUANTITY":"Cantidad requerida",
    "EVENT_DATA_REQUESTED_STATUS":"Estado solicitado",
    "EVENT_DATA_RESELLER_NAME":"Nombre del revendedor",
    "EVENT_DATA_RESELLER_NEW_NAME":"Nuevo nombre del revendedor",
    "EVENT_DATA_RESELLER_OLD_NAME":"Nombre antiguo del revendedor",
    "EVENT_DATA_RESERVATION_ORDER_ID":"ID de orden de reserva",
    "EVENT_DATA_ROLE":"Role",
    "EVENT_DATA_SALE_PRICE":"Precio de venta",
    "EVENT_DATA_SEAT_LIMIT":"Límite de asientos",
    "EVENT_DATA_SITE_NAME":"Nombre del sitio",
    "EVENT_DATA_SITEDEPARTMENT_NAME":"Nombre de Departamento",
    "EVENT_DATA_SOURCE_CURRENCY":"Moneda de origen",
    "EVENT_DATA_SOURCE_OFFER_ID":"ID de oferta de origen",
    "EVENT_DATA_SOURCE_PRODUCT_NAME":"Nombre del producto de origen",
    "EVENT_DATA_SOURCE_QUANTITY":"Cantidad de origen",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Ciclo de facturación de origen",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"ID de suscripción de origen",
    "EVENT_DATA_SOURCE_VALIDITY":"Validez de la fuente",
    "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Tipo de validez de fuente",
    "EVENT_DATA_START_BILLING_PERIOD_ID":"Empezar a facturar periodId",
    "EVENT_DATA_START_DATE":"Fecha de inicio",
    "EVENT_DATA_STATUS":"Estado",
    "EVENT_DATA_SUB_TAXES":"Subimpuestos",
    "EVENT_DATA_SUBSCRIPTION_ID":"Identificación de suscripción",
    "EVENT_DATA_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "EVENT_DATA_SUBSCRIPTIONID":"Identificación de suscripción",
    "EVENT_DATA_SUPPORTED_MARKET_CODE":"Código de mercado soportado",
    "EVENT_DATA_SUPPORTED_MARKETS":"Mercados admitidos",
    "EVENT_DATA_SYNCED_PRODUCT":"Número de productos sincronizados",
    "EVENT_DATA_TARGET_CURRENCY":"Moneda objetivo",
    "EVENT_DATA_TARGET_OFFER_ID":"ID de oferta objetivo",
    "EVENT_DATA_TARGET_PRODUCT_NAME":"Nombre del producto de destino",
    "EVENT_DATA_TARGET_QUANTITY":"Cantidad objetivo",
    "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Ciclo de facturación objetivo",
    "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"ID de suscripción de destino",
    "EVENT_DATA_TARGET_VALIDITY":"Validez del objetivo",
    "EVENT_DATA_TARGET_VALIDITY_TYPE":" Tipo de validez de destino",
    "EVENT_DATA_TAX_CODE":"Código de impuestos",
    "EVENT_DATA_TAX_NAME":"Nombre del impuesto",
    "EVENT_DATA_TAX_PERCENTAGE":"Porcentaje de impuestos",
    "EVENT_DATA_TENANT_ID":"Identificación del inquilino",
    "EVENT_DATA_TENANT_NAME":"Nombre del inquilino",
    "EVENT_DATA_TO_RECIPIENTS":"A los destinatarios",
    "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Número de productos afectados",
    "EVENT_DATA_TOTAL_RELEASED_SEATS":"Número de asientos afectados",
    "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Importe total de la transacción",
    "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"límite de cantidad de transacción",
    "EVENT_DATA_TRANSITION_TYPE":"Tipo de transición",
    "EVENT_DATA_UNSYNCED_PRODUCT":"Número de productos no sincronizados",
    "EVENT_DATA_UPDATED_BUNDLE_NAME":"Nombre del paquete actualizado",
    "EVENT_DATA_UPDATED_COUPON_NAME":"Nombre del cupón actualizado",
    "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Nombre de oferta de distribuidor actualizado",
    "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Nombre de oferta de socio actualizado",
    "EVENT_DATA_UPDATED_PLAN_NAME":"Nombre del plan actualizado",
    "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Cambio de mapeo de producto",
    "EVENT_DATA_URL":"URL",
    "EVENT_DATA_USAGE_LOCATION":"Ubicación de uso",
    "EVENT_DATA_USER_DISPLAY_NAME":"Nombre para mostrar",
    "EVENT_DATA_USER_EMAIL":"Correo electrónico del usuario",
    "EVENT_DATA_USER_TYPE":"Tipo de usuario",
    "EVENT_DATA_USERNAME":"Nombre de usuario",
    "EVENT_DATA_VALIDATION_ERROR":"Error de validacion",
    "EVENT_DATA_VALIDATION_STATUS":"Estado de validación",
    "EVENT_DATA_VALIDITY":"Validez",
    "EVENT_DATA_VALIDITY_TYPE":"Tipo de validez",
    "EVENT_DATA_VALUE":"Valor",
    "EVENT_DATA_VOUCHER_ENTITY":"Entidad de comprobante",
    "EVENT_DATA_VOUCHER_IDS":"ID de cupón",
    "EVENT_DATA_VOUCHERS":"vales",
    "EVENT_DATE":"Fecha",
    "EVENT_DEPARTMENT_DESCRIPTION":"Descripción del departamento",
    "EVENT_DEPARTMENT_ID":"ID de departamento",
    "EVENT_DEPARTMENT_NAME":"Nombre de Departamento",
    "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Aceptar o rechazar el consentimiento de renovación de la oferta del socio",
    "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Se aceptaron los términos y condiciones de NCE para pedir productos de Microsoft NCE",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Términos y condiciones aceptados",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Términos y condiciones aceptados Incumplimiento",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Activar la suscripción DangerThreshold Met",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Activar la suscripción DangerThreshold Met",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Activar el umbral de peligro cumplido Error de suscripción",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Oferta personalizada de aprovisionamiento",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Fallo en el aprovisionamiento de la oferta personalizada",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Activar fallo de suscripción",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Activar éxito de suscripción",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"No se pudo crear el cliente",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"Agregar falla del cliente C3",
    "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Agregue el éxito del cliente C3",
    "EVENT_DESC_ADD_ENTITLEMENTS":"Agregar derecho",
    "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Agregar error de derecho",
    "EVENT_DESC_ADD_GROUP_ADMIN":"Agregar administrador de grupo",
    "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Agregar error de administrador de grupo",
    "EVENT_DESC_ADJUST_AZURE_MARGIN":"Ajustar el margen de Azure",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"No se pudo ajustar el margen de Azure",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Ajustar el margen de Azure",
    "EVENT_DESC_APPROVED_BY":"Aprobado por",
    "EVENT_DESC_APPROVED_ON":"Aprobado el",
    "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Aprobados o Rechazados próximos cambios de renovación de cronograma",
    "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Aprobados o Rechazados próximos cambios de renovación de cronograma",
    "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Solicitud de compra aprobada o rechazada",
    "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Solicitud de compra aprobada",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Asignar plan a cliente",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"No se pudo asignar el plan al cliente",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Error al asignar cupones perdidos",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Cupones perdidos asignados con éxito",
    "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin tiene acceso a los clientes automáticamente",
    "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"suscripción de cliente Precio automático actualizado",
    "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Liberación automática de asientos no utilizados",
    "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Desajustes corregidos debido a la sincronización automática",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Cambió el ciclo de facturación con éxito (sincronización automática)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"Cambio de ciclo de facturación fallido (sincronización automática)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Cambió el ciclo de facturación con éxito (sincronización manual)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Cambio de ciclo de facturación fallido (sincronización manual)",
    "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Resumen de lotes integrados en masa",
    "EVENT_DESC_BULK_USER_UPLOAD":"Carga masiva de usuarios",
    "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Error en la carga masiva de usuarios",
    "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Se compran ofertas de paquetes",
    "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Paquete de producto comprado exitosamente",
    "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Cancelar la renovación programada con éxito.",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Solicitud de compra de suscripción cancelada",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Error en la solicitud de compra de suscripción cancelada",
    "EVENT_DESC_CART_AVAILABILITY_STATUS":"Estado de disponibilidad del carrito",
    "EVENT_DESC_CART_CHECKOUT":"Carrito de compra",
    "EVENT_DESC_CART_CHECKOUT_FAILURE":"Error en el pago del carrito",
    "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Notificar que se excedió el límite de asientos",
    "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Notificar que se excedió el límite de transacción",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Estado de renovación automática del producto del cliente cambiado",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"El estado de renovación automática del producto del cliente no se pudo cambiar",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Estado del producto actualizado exitosamente",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Error al actualizar el estado del producto",
    "EVENT_DESC_CHANGE_INVOICE_STATUS":"Cambiar estado de factura",
    "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Error al cambiar el estado de la factura",
    "EVENT_DESC_CHANGE_PLAN_FAILURE":"Fallo en el plan de cambios",
    "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Éxito del plan de cambio",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Error en la conversión de moneda al clonar",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Clonar la conversión de moneda con éxito",
    "EVENT_DESC_CLONE_PLAN":"Plan clonado con éxito",
    "EVENT_DESC_CLONE_PLAN_FAILURE":"No se pudo clonar el plan",
    "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"¿El cupón se aplica a todos los clientes etiquetados?",
    "EVENT_DESC_COUPON_NAME":"Nombre del cupón",
    "EVENT_DESC_COUPON_STATUS":"Estado del cupón",
    "EVENT_DESC_CREATE_AZURE_GROUPS":"Crear grupos de Azure",
    "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Error al crear grupos de Azure",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Crear suscripción a Azure",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Crear error en la suscripción de Azure",
    "EVENT_DESC_CREATE_CART":"Crear carrito",
    "EVENT_DESC_CREATE_CART_FAILURE":"Crear error en el carrito",
    "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Crear fracaso del cliente",
    "EVENT_DESC_CREATE_INVOICE":"Crear factura",
    "EVENT_DESC_CREATE_ORDER":"Productos comprados exitosamente",
    "EVENT_DESC_CREATE_ORDER_FAILURE":"Falló la compra de productos",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Crear perfil de pago",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Fallo en la creación del perfil de pago",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"No se pudo crear la suscripción de instancias reservadas mediante sincronización",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Suscripción de instancias reservadas creada correctamente mediante sincronización",
    "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Crear renovación programada exitosa",
    "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Crear comprobación de vencimiento de suscripción",
    "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Falló la creación de la suscripción",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Suscripción creada con éxito (sincronización automática)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Error al crear la suscripción (sincronización automática)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Se omitió crear suscripción desde sincronización automática",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Suscripción creada con éxito (sincronización manual)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"Error al crear la suscripción (sincronización manual)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Se omitió la creación de la suscripción desde la sincronización manual",
    "EVENT_DESC_CREATE_TAX_RULE":"Crear regla fiscal",
    "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Error al crear regla fiscal",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Crear usuario de suscripción de uso",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Crear error de usuario de UsageSubscription",
    "EVENT_DESC_CREATE_USER":"Crear usuario",
    "EVENT_DESC_CREATE_USER_FAILURE":"Crear error de usuario",
    "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Consentimiento de renovación de oferta de socio",
    "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Error en el consentimiento de renovación de oferta de socio",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Creación de clientes a través de API",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Creación de clientes a través de falla de API",
    "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Error de eliminación del cliente",
    "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Eliminación de clientes exitosa",
    "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Factura de cliente generada",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Nombre del cliente Error de actualización",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Nombre del cliente Actualización exitosa",
    "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"El límite de asientos del cliente supera Notfication",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"Desactivar registro de PSA",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"No se pudo desactivar el registro de PSA",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Definir conversión de moneda",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Definir error de conversión de moneda",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Definir precios del plan en nueva moneda",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Definir precios del plan en falla de nueva moneda",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Desvincular cliente de facturación",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Fallo del cliente de facturación de desvinculación",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Eliminar administrador de cuenta con éxito",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Error al eliminar el administrador de la cuenta",
    "EVENT_DESC_DELETE_CART_ITEM":"Artículo eliminado del carrito",
    "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Error al eliminar el artículo del carrito",
    "EVENT_DESC_DELETE_CONTRACT_OFFER":"Oferta de contrato eliminada con éxito",
    "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Error al eliminar la oferta de contrato",
    "EVENT_DESC_DELETE_COUPON":"Cupón deshabilitado con éxito",
    "EVENT_DESC_DELETE_COUPON_FAILED":"Error al eliminar el cupón.",
    "EVENT_DESC_DELETE_COUPON_FAILURE":"No se pudo desactivar el cupón",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Eliminar conversión de moneda",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Eliminar error de conversión de moneda",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"No se pudo eliminar la conversión de moneda",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Eliminar error de notificación personalizada",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Eliminar notificación personalizada exitosa",
    "EVENT_DESC_DELETE_CUSTOM_OFFER":"Oferta personalizada eliminada con éxito",
    "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Error al eliminar la oferta personalizada",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Eliminar pago del cliente",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"No se pudo eliminar el pago del cliente",
    "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Eliminar falla del departamento",
    "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Eliminar departamento correctamente",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Oferta de distribuidor eliminada con éxito",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Error al eliminar la oferta del distribuidor",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Eliminar notificación por correo electrónico del evento",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Eliminar error de notificación por correo electrónico de evento",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Eliminar ajuste de factura",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Eliminar error de ajuste de factura",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Eliminar línea de pedido de facturas",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Eliminar facturas LineItemFailure",
    "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Eliminar error de usuario asociado",
    "EVENT_DESC_DELETE_PAYMENT_OPTION":"Eliminar opción de pago",
    "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Error al eliminar la opción de pago",
    "EVENT_DESC_DELETE_PLAN":"Eliminar plan",
    "EVENT_DESC_DELETE_PLAN_FAILURE":"Eliminar falla del plan",
    "EVENT_DESC_DELETE_QUOTE_FAILED":"Error al eliminar la cita",
    "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Eliminar cita exitosamente",
    "EVENT_DESC_DELETE_SITE_FAILURE":"Eliminar error del sitio",
    "EVENT_DESC_DELETE_SITE_SUCCESS":"Eliminar sitio correctamente",
    "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Eliminar comprobación de vencimiento de suscripción",
    "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Error al eliminar la suscripción por vencimiento",
    "EVENT_DESC_DELETE_TAX_RULE":"Eliminar regla fiscal",
    "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Eliminar error en la regla fiscal",
    "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Actualizar error de suscripción de Azure",
    "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Eliminar usuario de suscripción de uso",
    "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Eliminar error de usuario de suscripción de uso",
    "EVENT_DESC_DELETE_USER":"Borrar usuario",
    "EVENT_DESC_DELETE_USER_FAILURE":"Eliminar error de usuario",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Eliminar error de notificación de webhook",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Eliminar la notificación de webhook con éxito",
    "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Deshabilitar la suscripción a Azure",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Error al deshabilitar la asignación de cupones",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupones deshabilitada",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Deshabilitar plan para cliente",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"No se pudo deshabilitar el plan para el cliente",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Informe de partidas individuales de facturas programadas",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Partidas de factura programadas con informe de comisiones",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Informe resumido de factura programada",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Informe de rentabilidad programado",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Informe de partidas de impuestos programadas",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Informe resumido de impuestos programado",
    "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Habilitar la suscripción a Azure",
    "EVENT_DESC_ENABLE_COUPON":"Cupón habilitado con éxito",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Error al habilitar la asignación de cupones",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Asignación de cupones habilitada",
    "EVENT_DESC_ENABLE_COUPON_FAILED":"Error al habilitar el cupón.",
    "EVENT_DESC_ENABLE_COUPON_FAILURE":"No se pudo habilitar el cupón",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Habilitar plan para el cliente",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"No se pudo habilitar el plan para el cliente",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Habilite la notificación cuando se renueve una suscripción anual",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Habilite la notificación cuando se renueve una suscripción mensual",
    "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"No se pudo poner en cola la solicitud para los clientes de incorporación masiva",
    "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Solicitud en cola para clonar plan de revendedor",
    "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Informe de notificación de registro de errores",
    "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Error en la renovación programada de la actualización",
    "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Exportar informe de licencia de cliente.",
    "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Exportar informe de asignación de licencia de usuario",
    "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"No se pudo aprobar o rechazar la siguiente solicitud de renovación programada",
    "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Cancelar la renovación programada falló",
    "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"No se pudo clonar el plan de revendedor",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"No se pudo conectar al software de contabilidad",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"No se pudo conectar con el Centro de socios de Microsoft",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"No se pudo conectar con la API de precios de Microsoft",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"No se pudo conectar a PSA",
    "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"No se pudo crear la factura a pedido",
    "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Error al crear la renovación programada",
    "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"No se pudo poner en cola la solicitud para clonar el plan de revendedor",
    "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"No se pudo establecer el estado del lote de incorporación masiva en Completo",
    "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"No se pudo establecer el estado del lote de Asignación de licencia de usuario en Completo",
    "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"No se pudo sincronizar el perfil del cliente del proveedor",
    "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"No se pudo actualizar la factura a pedido",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Arreglar la falta de coincidencia del estado de renovación automática automáticamente",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Arreglar la falta de coincidencia del estado de renovación automática falló automáticamente",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Arreglar la falta de coincidencia del estado de renovación automática manualmente",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Solucionar error de coincidencia de estado de renovación automática falló manualmente",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Se corrigió el desajuste de promoción con éxito (sincronización automática)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Error al corregir el desajuste de la promoción (sincronización automática)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Solucionar el desajuste de la promoción manualmente",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Arreglar el desajuste de la promoción falló manualmente",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Error de coincidencia de cantidad fija con éxito (sincronización automática)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Error al corregir el desajuste de cantidad (sincronización automática)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Error de coincidencia de cantidad fija con éxito (sincronización manual)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Error al corregir el desajuste de cantidad (sincronización manual)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Error de coincidencia de estado fijo con éxito (sincronización automática)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"La corrección de la falta de coincidencia de estado falló (sincronización automática)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Error de coincidencia de estado fijo con éxito (sincronización manual)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"La corrección de la falta de coincidencia de estado falló (sincronización manual)",
    "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Discrepancia en el consumo de licencias",
    "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Conceder acceso al fallo del portal",
    "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Conceder acceso al portal",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Ignorar errores de carga masiva de usuarios",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Ignorar errores de carga masiva de usuarios",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Ignorar errores de compra de suscripciones",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Ignorar errores de actualización de suscripción",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Ignorar errores de actualización de suscripción Error",
    "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Ignore el error en la compra de la suscripción",
    "EVENT_DESC_INVOICE_AND_REPORT":"Factura e informe",
    "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Pago de factura iniciado",
    "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Informe de facturas y cuotas.",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Error del cliente del proveedor de facturación del enlace",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Vincular el éxito del cliente del proveedor de facturación",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Error del cliente del proveedor de servicios de enlace",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Éxito del cliente del proveedor de servicios de enlace",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Error de cliente que no es csp del proveedor de servicios de enlace",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Éxito del cliente sin CSP del proveedor de servicios de enlace",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Éxito del cliente del proveedor de facturación de enlaces",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Error del cliente del proveedor de facturación del enlace",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Fallo del cliente del proveedor de servicios de enlace",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Éxito del cliente del proveedor de servicios de enlace",
    "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Error en la gestión de licencias de usuario.",
    "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"La gestión de licencias de usuario fue exitosa.",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Programar próximos cambios de renovación",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"No se pudieron programar los próximos cambios de renovación",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Solicitud de aprovisionamiento de producto de NI Partner cumplida",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Solicitud de aprovisionamiento de producto de NI Partner cancelada",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"Producto NI Partner adquirido",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"Cantidad de producto NI Partner actualizada",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Notificar el estado de activación de la compra del producto de NI Provisioning",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Notificar el estado de cancelación de la compra del producto de aprovisionamiento de NI",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Oferta modificada con éxito (sincronización automática)",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Cambio de oferta fallido (sincronización automática)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Oferta modificada con éxito (sincronización manual)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Cambio de oferta fallido (sincronización manual)",
    "EVENT_DESC_OFFERS_ARE_PURCHASED":"Productos comprados exitosamente",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Plan azul integrado con éxito (sincronización automática)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Falló la incorporación del plan Azure (sincronización automática)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Plan azul integrado con éxito (sincronización manual)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Falló la incorporación del plan Azure (sincronización manual)",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Fallo del cliente a bordo",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Fallo del cliente a bordo",
    "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Éxito del cliente integrado",
    "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Socio ofrece informe exportable",
    "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Error añadido del usuario asociado",
    "EVENT_DESC_PARTNER_USERADDED":"Usuario socio agregado",
    "EVENT_DESC_PASSWORD_EXPIRED":"La contraseña expiró",
    "EVENT_DESC_PAYMENT_FAILURE":"Fallo de pago",
    "EVENT_DESC_PAYMENT_SUCCESS":"Pago exitoso",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Error en la captura del consentimiento de seguridad del Centro de partners",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"El consentimiento de seguridad del Centro de partners se capturó correctamente",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip creado con éxito para todas las facturas.",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Ocurrió un error al crear Zip para todas las facturas.",
    "EVENT_DESC_PRODUCT_ASSIGNMENT":"Asignación de producto",
    "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Fallo en la asignación del producto",
    "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Promociones cargadas con éxito",
    "EVENT_DESC_PROMOTION_LOADING_FAILED":"No se han podido cargar las promociones",
    "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Precio de productos del proveedor actualizado",
    "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Solicitud de asistencia para el registro público",
    "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"La compra del paquete falló",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Comprar oferta para nuevos socios",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Fallo en la compra de una nueva oferta personalizada",
    "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Comprar producto de aprovisionamiento de NI",
    "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Solicitud de compra pendiente de aprobación",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Productos comprados exitosamente",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Error al comprar productos",
    "EVENT_DESC_QUOTATION_CUSTOMER":"Cotización de clientes",
    "EVENT_DESC_QUOTATION_NAME":"Nombre de la cita",
    "EVENT_DESC_QUOTATION_OWNER":"Propietario de la cita",
    "EVENT_DESC_QUOTE_APPROVAL":"Aprobación de cotización",
    "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Conversión de cotizaciones exitosa",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Solicitud planteada para actualizar el producto comprado",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"La solicitud para actualizar el producto de compra falló",
    "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Discrepancia de reconocimiento en la generación de facturas por adelantado",
    "EVENT_DESC_RECORD_PAYMENT":"Registro de pago",
    "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Incumplimiento de registro de pago",
    "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Solicitud de compra rechazada",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Términos y condiciones rechazados",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Incumplimiento de términos y condiciones rechazados",
    "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Nombre del revendedor Error de actualización",
    "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Nombre del revendedor Actualización exitosa",
    "EVENT_DESC_RESET_USER_PASSWORD":"Restablecer contraseña de usuario",
    "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Error al restablecer la contraseña del usuario",
    "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Éxito en el precio de reversión de derechos",
    "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Revertir falla de precio de derecho de autor",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION":"Revertir la configuración del cliente",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"Revertir el error de configuración del cliente",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Revocar el acceso al portal",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Revocar el acceso al fallo del portal",
    "EVENT_DESC_SAVE_COMMENT":"Nuevo comentario añadido",
    "EVENT_DESC_SAVE_CONTRACT_OFFER":"Oferta de contrato guardada con éxito",
    "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"No se ha podido guardar la oferta de contrato",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Guardar falla de notificación personalizada",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Guardar notificación personalizada exitosa",
    "EVENT_DESC_SAVE_CUSTOM_OFFER":"Guardar oferta personalizada",
    "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Error al guardar oferta personalizada",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"No se pudo guardar el perfil de facturación del cliente",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Guardar perfil de facturación del cliente",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Guardar cliente en facturación",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Salvar al cliente en caso de error de facturación",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"No se pudo guardar al cliente en facturación",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Guardar perfil de pago del cliente",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Error al guardar el perfil de pago del cliente",
    "EVENT_DESC_SAVE_CUSTOMER_USER":"Guardar usuario del cliente",
    "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Guardar falla del usuario del cliente",
    "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Guardar fallo del departamento",
    "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Guardar el éxito del departamento",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Guardar oferta de distribuidor",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Guardar error en la oferta del distribuidor",
    "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Ahorrar derecho precio éxito",
    "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Falla en el precio de los derechos de ahorro",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Guardar notificación por correo electrónico del evento",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Error al guardar la notificación por correo electrónico del evento",
    "EVENT_DESC_SAVE_INVITES":"Guardar invitaciones",
    "EVENT_DESC_SAVE_INVITES_FAILURE":"Guardar invitaciones fallidas",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Guardar ajustes de factura",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Error al guardar ajustes de factura",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Guardar paquete de socio",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Error al guardar el paquete de socios",
    "EVENT_DESC_SAVE_PARTNER_USER":"Guardar usuario asociado",
    "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Guardar error de usuario asociado",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Perfil de pago guardado",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"No se pudo guardar el perfil de pago",
    "EVENT_DESC_SAVE_PLAN":"Guardar plan",
    "EVENT_DESC_SAVE_PLAN_FAILURE":"Guardar fracaso del plan",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Guardar productos del plan en moneda objetivo",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Guardar productos del plan en caso de fallo de la moneda objetivo",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Consentimiento del cliente del proveedor guardado",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"No se pudo guardar el consentimiento del cliente del proveedor",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Guardar pago del proveedor",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Guardar fallo de pago del proveedor",
    "EVENT_DESC_SAVE_QUOTE_FAILED":"No se pudo guardar la cotización",
    "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Cotización guardada exitosamente",
    "EVENT_DESC_SAVE_RESELLER":"Guardar revendedor",
    "EVENT_DESC_SAVE_RESELLER_FAILURE":"No se pudo guardar el revendedor",
    "EVENT_DESC_SAVE_SITE_FAILURE":"Guardar error en el sitio",
    "EVENT_DESC_SAVE_SITE_SUCCESS":"Guardar el sitio correctamente",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Error al guardar la configuración del usuario",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Guardar la configuración del usuario con éxito",
    "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Guardar notificación de mensaje web",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Error al guardar la notificación del webhook",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Guardar la notificación del webhook con éxito",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Error de aceptación de términos y condiciones",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Términos y condiciones aceptados exitosamente.",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"El intento de preparar las licencias para agregarlas o eliminarlas falló.",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Organizó las licencias para agregarlas o eliminarlas correctamente.",
    "EVENT_DESC_SCHEDULE_ORDER":"Orden de programación",
    "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Orden de programación fallida",
    "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Informe de partidas individuales de facturas programadas",
    "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Partidas de factura programadas con informe de comisiones",
    "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Informe resumido de factura programada",
    "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Informe de rentabilidad programado",
    "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Informe de partidas de impuestos programadas",
    "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Informe resumido de impuestos programado",
    "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Enviar actualización de oferta de distribuidor",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Enviar factura en pdf al cliente desde la interfaz de usuario",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Enviar factura en pdf a los clientes con transparencia",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Alertas de umbral de uso de suscripción",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Alertas de umbral de uso de suscripción fallida",
    "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Establecer el estado del lote de asignación de licencias de usuario para completar",
    "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"La conectividad SMTP falló",
    "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notificación de vencimiento de suscripción para oferta de prueba",
    "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Informe del historial de suscripciones",
    "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Suspender cuando se alcance el umbral de peligro",
    "EVENT_DESC_SUBSCRIPTION_SYNC":"Sincronización de suscripción",
    "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Error de sincronización de suscripción",
    "EVENT_DESC_SUBSCRIPTION_UPDATED":"Productos actualizados exitosamente",
    "EVENT_DESC_SUCCESS":"Éxito",
    "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"Se aprobó o rechazó con éxito la siguiente solicitud de renovación programada",
    "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Plan de revendedor clonado con éxito",
    "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"Factura bajo demanda creada con éxito",
    "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Perfil de cliente del proveedor sincronizado con éxito",
    "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"Factura bajo demanda actualizada con éxito",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Suspender error de suscripción",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Suspender suscripción exitosa",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Suspender cuando se alcance el umbral de peligro",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Suspender cuando se alcance el umbral de peligro",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"No se pudo sincronizar el consentimiento del cliente del proveedor.",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Consentimiento del cliente del proveedor sincronizado",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Sincronizar suscripciones desde fallas de PC",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Sincronización de suscripciones desde PC exitosa",
    "EVENT_DESC_TAGS_ADDED":"Etiquetas agregadas",
    "EVENT_DESC_TAGS_ADDED_FAILURE":"Etiquetas agregadas Fallo",
    "EVENT_DESC_TAGS_DELETE_DFAILURE":"Error de etiquetas eliminadas",
    "EVENT_DESC_TAGS_DELETED":"Etiquetas eliminadas",
    "EVENT_DESC_TAGS_EDITED":"Etiquetas editadas",
    "EVENT_DESC_TAGS_EDITED_FAILURE":"Etiquetas Editadas Fallo",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"Cambió la duración del término con éxito (sincronización automática)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Error al cambiar la duración del término (sincronización automática)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"Cambió la duración del término con éxito (sincronización manual)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Error al cambiar la duración del término (sincronización manual)",
    "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Detalles del producto no sincronizados",
    "EVENT_DESC_UPDATE_AZURE_GROUPS":"Actualizar grupos de Azure",
    "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Error al actualizar grupos de Azure",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Actualizar la suscripción de Azure",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Error al actualizar la suscripción de Azure",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Oferta de contrato actualizada con éxito",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"Error al actualizar la oferta de contrato",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Actualizar ciclo de facturación personalizado",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"No se pudo actualizar el ciclo de facturación personalizado",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Actualización de error de notificación personalizada",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Actualizar la notificación personalizada con éxito",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Actualizar cantidad de oferta de socios",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Error al actualizar la cantidad de la oferta del socio",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Opción de pago predeterminada actualizada",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"No se pudo actualizar la opción de pago predeterminada",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Actualizar perfil de pago del cliente",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Error al actualizar el perfil de pago del cliente",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Perfil de cliente actualizado",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"No se pudo actualizar el perfil del cliente",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Actualizar la opción de pago predeterminada",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Error en la actualización de la opción de pago predeterminada",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Actualizar notificación por correo electrónico del evento",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Error en la notificación por correo electrónico del evento de actualización",
    "EVENT_DESC_UPDATE_GROUP_ADMIN":"Actualizar administrador de grupo",
    "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Error al actualizar el administrador del grupo",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":" Error de propiedades de factura actualizadas",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Propiedades de factura actualizadas con éxito",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Actualizar bandera de liberación automática",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"No se pudo actualizar el indicador de lanzamiento automático",
    "EVENT_DESC_UPDATE_LICENSE":"Actualizar licencia",
    "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Error al actualizar la licencia",
    "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Error al actualizar la configuración del Centro de socios",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Actualizar la configuración general del socio",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Error al actualizar la configuración general del socio",
    "EVENT_DESC_UPDATE_PARTNER_LOGO":"Actualizar logotipo de socio",
    "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Error al actualizar el logotipo del socio",
    "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Actualizar la configuración del Centro de socios",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Actualizar precio de oferta del plan",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Fallo en el precio de la oferta del plan de actualización",
    "EVENT_DESC_UPDATE_PROFILE":"Actualización del perfil",
    "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Error al actualizar el perfil",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Actualizar la configuración del proveedor",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"No se pudo actualizar la configuración del proveedor",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Atributos de producto actualizados",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"No se pudieron actualizar los atributos del producto",
    "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Actualizar cantidad Producto de aprovisionamiento de NI",
    "EVENT_DESC_UPDATE_QUOTE_FAILED":"No se pudo actualizar la cotización",
    "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Cotización actualizada con éxito",
    "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Actualizar la renovación programada con éxito",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valor predeterminado del cliente del proveedor de servicios actualizado",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"No se pudo actualizar el valor predeterminado del cliente del proveedor de servicios",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Actualizar la configuración SMTP",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Error al actualizar la configuración SMTP",
    "EVENT_DESC_UPDATE_SUBSCRIPTION":"Productos actualizados exitosamente",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Actualizar comprobación de vencimiento de suscripción",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Error en la comprobación de vencimiento de la actualización de suscripción",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Error al actualizar productos",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":" Suscripción de actualización de API",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Error de suscripción de actualización de API",
    "EVENT_DESC_UPDATE_SUBTAX":"Actualizar Subimpuesto",
    "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Error al actualizar subimpuestos",
    "EVENT_DESC_UPDATE_TAX_RULE":"Actualizar regla fiscal",
    "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Error al actualizar la regla fiscal",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Actualizar la configuración del cliente",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Error en la actualización de la configuración del cliente",
    "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Actualizar usuario de suscripción de uso",
    "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Error de usuario de suscripción de uso de actualización",
    "EVENT_DESC_UPDATE_USER":"Actualizar usuario",
    "EVENT_DESC_UPDATE_USER_FAILURE":"Actualizar error de usuario",
    "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Actualizar notificación de WebMessage",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Error en la notificación de actualización del webhook",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Actualización exitosa de la notificación del webhook",
    "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Actualizar suscripción de Azure",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"La asignación del cupón falló",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Cupón asignado con éxito",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Error en la asignación masiva de productos",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"El mapeo de productos masivos fue exitoso",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Se inicia proceso de Refresh para entidades de servicios externos en C3",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" El proceso de actualización para entidades de servicios externos en C3 ha fallado",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" El proceso de actualización para entidades de servicios externos en C3 ha sido exitoso",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Actualización de suscripción",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Error al actualizar la suscripción",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"No se pudo descargar el catálogo de clientes.",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Catálogo de clientes descargado correctamente.",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Descargar catálogo de productos Éxito",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Error al descargar el catálogo de productos",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Descarga del catálogo de comisiones de productos Fallo",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Descargar catálogo de comisiones de productos Éxito",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"No se pudo descargar el catálogo de uso del informe",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Descargue el catálogo de uso del informe con éxito",
    "EVENT_ENTITLEMENT_ID":"Identificación de titularidad",
    "EVENT_ENTITLEMENT_NAME":"Nombre de la entidad",
    "EVENT_FILE_NAME":"Nombre del archivo",
    "EVENT_IMPERSONATOR":"Imitador",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Error al importar pagos múltiples",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Importar múltiples pagos con éxito",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Error al importar datos validados",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Datos validados importados con éxito",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Fallo en las comisiones de productos importados",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Éxito en las comisiones de productos importados",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Fallo en la importación de extensiones de productos",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Importación de extensiones de producto correctas",
    "EVENT_IMPORT_VOUCHER_FAILURE":"No se pudieron importar los comprobantes.",
    "EVENT_IMPORT_VOUCHER_SUCCESS":" Se importaron exitosamente los comprobantes.",
    "EVENT_IS_LICENSE_ASSIGNED":"¿Está asignada la licencia?",
    "EVENT_IS_LICENSE_REMOVED":"¿Se ha quitado la licencia?",
    "EVENT_LINK_RESELLER_TO_PROVIDER":"Revendedor de enlaces",
    "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"No se pudo vincular al revendedor",
    "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"Error en la asignación de entidades PSA",
    "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"La entidad C3 se asignó a la entidad en PSA con éxito",
    "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"Error al eliminar la asignación con la entidad PSA",
    "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Se eliminó con éxito la asignación con la entidad PSA",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"El mapeo de productos de PSA falló",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Productos C3 asignados a productos en PSA con éxito",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"No se pudo eliminar la asignación con la entidad PSA",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Se eliminó la asignación con la entidad PSA con éxito",
    "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Se eliminó correctamente el mapeo con el producto PSA",
    "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"Error al eliminar la asignación con el producto PSA",
    "EVENT_NEW_CAN_PRICE_LAG":"NuevoCanPriceLag",
    "EVENT_NEW_CAN_PRICE_LEAD":"NuevoCanPriceLead",
    "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Nuevo límite de asientos a nivel del plan",
    "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Notificador de error de búsqueda de próxima ejecución",
    "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Seleccione al menos un tipo de destinatario.",
    "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Vista previa del correo electrónico",
    "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"La notificación por correo electrónico se eliminó correctamente.",
    "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
    "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
    "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"Activado",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Tipo de destinatario",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Destinatarios",
    "EVENT_NOTIFICATION_LABLE_TEXT_TO":"A",
    "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"La configuración de notificación por correo electrónico se guardó correctamente.",
    "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Por favor haga clic en Vista previa del correo electrónico",
    "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"para ver el contenido de la plantilla de correo electrónico.",
    "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"Destinatarios CCO",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"Destinatarios de CC",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Evento",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Nombre del evento",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"¿Activado?",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"A los destinatarios",
    "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Notificar la creación del cliente mediante el registro del cliente",
    "EVENT_Notify_Purchase_Limit_Error":"Notificar error de límite de compra",
    "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Notificar error de límite de compra",
    "EVENT_OLD_CAN_PRICE_LAG":"Retraso en el precio de OldCan",
    "EVENT_OLD_CAN_PRICE_LEAD":"Precio de OldCanLead",
    "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Límite de asientos del nivel del plan anterior",
    "EVENT_PRODUCT_NAME":"Nombre del producto",
    "EVENT_PURCHASE_APPROVE_REASON":"Razón",
    "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Razón, si falla",
    "EVENT_PURCHASE_PRODUCT_ID":"Identificación de producto",
    "EVENT_PURCHASE_PRODUCT_NAME":"Nombre del producto",
    "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Identificación del producto del plan",
    "EVENT_PURCHASE_PRODUCT_QUANTITY":"Cantidad comprada",
    "EVENT_PURCHASE_REASON_ONHOLD":"Motivo de la espera",
    "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Enviar facturas en PDF al socio",
    "EVENT_SITE_DESCRIPTION":"Descripción del lugar",
    "EVENT_SITE_ID":"Identificación del sitio",
    "EVENT_SITE_NAME":"Nombre del sitio",
    "EVENT_SMTP_TEST_EMAIL":"correo electrónico de prueba smtp",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Efectuar y validar el error en los pagos de importación",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Efectuar y validar el éxito de los pagos de importación",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Error al preparar y validar el informe de uso de socios",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Organizar y validar el informe de uso de socios",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Fase y validación de fallas en el catálogo de productos",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Preparar y validar el éxito del catálogo de productos",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Fase y validación de errores de comisiones de productos",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Programar y validar el éxito de las comisiones de productos",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"No se pudo preparar y validar el catálogo de vales.",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Catálogo de bonos preparado y validado con éxito.",
    "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Notificación de vencimiento de la suscripción",
    "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Notificación de vencimiento de suscripción para el cliente",
    "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Notificación de vencimiento de suscripción para oferta de prueba",
    "EVENT_TOTAL_RECORD_COUNT":"Número de registros",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER":" Desvincular revendedor",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"No se pudo desvincular al revendedor",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Detalles actualizados del producto de uso de socios",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Error al actualizar los detalles del producto de uso del socio",
    "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Notificación de oferta de plan de actualización para revendedor",
    "EVENT_USER_CONFIGURATION_NAME":"Nombre de configuración de usuario",
    "EVENT_USER_CONFIGURATION_VALUE":"Valor de configuración del usuario",
    "EVENT_USER_EMAILID ":"ID de correo electrónico del usuario",
    "EVENT_VOUCHER_ASSIGNMENT":"Asignación de vales",
    "EVENT_WEBJOB_STATUS_NOTIFIER":"Notificador web del estado del trabajo",
    "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Nueva descripción del paquete",
    "EVNET_DATA_NEW_BUNDLE_NAME":"Nuevo nombre de paquete",
    "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Descripción del paquete anterior",
    "EVNET_DATA_OLD_BUNDLE_NAME":"Nombre del paquete anterior",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Se acepta la solicitud de revendedores masivos a bordo",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"Error al aceptar la solicitud de revendedores integrados al por mayor",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"Falló la incorporación del revendedor mediante la incorporación masiva",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"La incorporación del revendedor mediante la incorporación masiva se completó con éxito",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"Error en la incorporación masiva de revendedores",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Incorporación masiva de revendedores exitosa",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Se completaron los derechos de adición masiva",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Falló la adición masiva de derechos",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Se aceptó la solicitud de agregar derechos de forma masiva",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"No se ha podido realizar la solicitud de asignación masiva de derechos",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Proceso de adición masiva de inquilinos completado",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"El proceso de agregar inquilinos en bloque falló",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Solicitud de agregado masivo de inquilinos aceptada",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"La solicitud de agregar inquilinos en bloque falló",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Proceso de incorporación masiva de revendedores completado",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Falló el proceso de incorporación masiva de revendedores",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Se inició el proceso de incorporación masiva de revendedores",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Eliminar relación de revendedor completada",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Error al eliminar la relación de revendedor",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Error en la solicitud de eliminación de relación de revendedor",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Se aceptó la solicitud de eliminación de relación de revendedor y de inquilino",
    "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Se produjo un error al volver a intentar enviar una notificación de webhook",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Se produjo un error al guardar o actualizar los detalles de la notificación personalizada",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Error al guardar los detalles de la notificación del webhook",
    "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Se encontró un error al intentar crear un carrito para la oferta principal a partir de la oferta de prueba.",
    "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Error al actualizar datos preparados para obtener detalles de notificación",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Se produjo un error al enviar la notificación del webhook",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"La URL del webhook proporcionada no es válida",
    "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"No hay productos asignados para cambio de ciclo de facturación. Por favor contacte al equipo de soporte.",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Se produjo una excepción al crear un nuevo cliente proveedor, ya que el socio no confirma la aceptación del acuerdo de cliente proveedor.",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"Se debe confirmar la aceptación del acuerdo del cliente del proveedor",
    "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Se produjo un error al verificar si el usuario aceptó los términos y condiciones del socio.",
    "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Se produjo un error al obtener el contenido de los términos y condiciones del socio",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Se produjo un error al obtener los detalles de aceptación del consentimiento del cliente del proveedor",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Se produjo un error al obtener clientes del proveedor que no proporcionaron el consentimiento del cliente del proveedor",
    "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Se produjo un error al guardar los detalles de aceptación de los términos y condiciones del socio",
    "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Se produjo un error al guardar el consentimiento del cliente del proveedor",
    "EXISTING":"Existente",
    "EXISTING_REFRESH_TOKEN":"Token de actualización existente",
    "EXPAND_ALL_PAYMENTS":"Expandir pagos",
    "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"¿Está seguro de que desea eliminar este registro?",
    "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"El cheque de vencimiento se eliminó exitosamente",
    "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Los cheques de caducidad se han guardado correctamente.",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Cerrar filtros",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Nombre del cliente",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Seleccione Cliente o Revendedor",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Buscar por cliente",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Seleccionar cliente",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Seleccione un estado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Estado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Reiniciar",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Búsqueda",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Buscar por estado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Abandonado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Desactivado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Error",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Ha fallado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"En progreso",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"Puesto en cola",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Detenido",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Éxito",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Advertencia",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Ciclo de facturación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Cliente",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Fecha final",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Error de detalles",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Error de exportación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Estado de exportación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Nombre del cliente",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"Mensaje de registro",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Nombre de la operación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":" Precio",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"precio del proveedor",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Fecha de inicio",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Estado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Recuento de estado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Acción",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Historial de suscripciones Fecha",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Estado de validación",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Error de validacion",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Departamento",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Sitio",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Detalle",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Resumen",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Ha fallado",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Éxito",
    "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Inició la actualización de datos locales desde un servicio externo.",
    "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Eliminado",
    "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"No se pudo encontrar ningún detalle del contrato localmente para crear el mapeo.",
    "EXTERNAL_SERVICE_IN_ACTIVE":"Inactivo",
    "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Estado del registro de publicaciones del servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abandonando la carga debido a un error al crear entidades",
    "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"La adición (sin adición de fecha de finalización) no está disponible en ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Falta el ID del acuerdo",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"La facturación no está habilitada para este acuerdo en ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"El acuerdo asignado no está activo en ConnectWise Manage bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"El acuerdo no está presente en ConnectWise Manage bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Los acuerdos no están asignados para este cliente",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Los acuerdos no están presentes en ConnectWise Manage bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Hay adiciones ambiguas, el mapeo no es posible.",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Falta la identificación del contrato",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"La facturación no está habilitada para este contrato en Autotask",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"El contrato asignado no está activo en Autotask con este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"El contrato no está presente en Autotask bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"El contrato no está presente en el servicio externo bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"El servicio de contrato no está asignado a este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"El servicio de contrato asignado no está presente en Autotask",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Los servicios de contrato no están mapeados bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Los contratos no están asignados para este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Los contratos no están presentes en Autotask bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Los contratos no están presentes en el servicio externo bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"El cliente no está activo en el servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"El cliente externo no está asignado al cliente c3",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"El cliente no está presente en el servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Falta la asignación de entidad o producto.",
    "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Anteriormente se intentó exportar. El estado de esta operación es",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"El contrato no está activo en el servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Falta el ID de cliente externo",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Los clientes no están presentes en el servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Ocurrió un error al crear una adición en ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Ocurrió un error al crear un ajuste:",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Ocurrió un error al crear el ajuste por cambio de precio",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Ocurrió un error al crear el servicio",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"No se pudo crear el cargo de contrato para un producto de una sola vez",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Ocurrió un error al crear el servicio de contrato:",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Ocurrió un error al actualizar la adición en ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"No se puede crear una asignación porque se encontraron varios servicios coincidentes en Autotask",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"No se pudieron encontrar contratos coincidentes que intentaran mapear automáticamente",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"No se pudo encontrar ningún cliente externo coincidente",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"No se pudo encontrar ningún producto externo coincidente",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"El producto no está asignado a este cliente",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"El producto asignado no está presente en ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Los productos no están mapeados bajo este cliente",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Los productos C3 no tienen servicio externo mapeado",
    "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Falta la identificación del servicio",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del acuerdo.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del acuerdo.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de cancelación del contrato.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"La fecha de creación del historial de suscripciones no puede ser posterior a la fecha de finalización del contrato.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del acuerdo",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del acuerdo",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio de facturación del contrato",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"La fecha de creación del historial de suscripciones no puede ser anterior a la fecha de inicio del contrato",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"La suscripción está en Autotask activo",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"La suscripción está inactiva en el servicio externo",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"No se pudo completar el mapeo automático porque se encontraron múltiples contratos coincidentes",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"No se pudo completar el mapeo automático porque se emparejaron varios clientes externos",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"No se pudo completar el mapeo automático porque se emparejaron varios productos externos",
    "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"La validación no es exitosa",
    "EXTERNAL_SERVICE_NAME":"Nombre del servicio externo",
    "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Seleccionar clientes para validar y cargar",
    "EXTERNAL_SERVICE_POST_BATCH_ID":"ID de lote de publicación de servicio externo",
    "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"volver a la lista",
    "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Error ignorado exitosamente",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Exportar",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validación",
    "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Servicio externo Publicar registros Estado",
    "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Registros de publicaciones de servicios externos",
    "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Prueba de conectividad",
    "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"INSTRUCCIONES",
    "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Haga clic en este botón para probar la conectividad con PSA una vez completada la configuración.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Conectividad establecida con éxito. Puede proceder a cargar registros de suscripciones ahora.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Seleccione al menos un cliente",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"No se pudo conectar con Datto Autotask. Vuelva a verificar sus configuraciones en la página de configuración",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"No se pudo conectar a ConnectWise Manage. Vuelva a verificar sus configuraciones en la página de configuración",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"La fecha de finalización debe ser mayor que la fecha de inicio.",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Seleccione la fecha de finalización",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"La fecha de inicio no puede ser mayor que la fecha de finalización",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Seleccione la fecha de inicio",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Sus datos se están cargando. Puede volver a visitar esta página para obtener más actualizaciones.",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"La validación ha comenzado",
    "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Nota :",
    "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"La clave digital es incorrecta o se le niega el acceso a esta función. La validación de la clave digital ha fallado. Comprueba que estás utilizando la clave digital correcta",
    "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Ya existe un cliente con la referencia de carga que ha proporcionado.",
    "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Reembolso no permitido. Ya se ha procesado un reembolso para esta transacción.",
    "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Su estado es xxxxx y no le permite usar la API.",
    "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"El número de cuenta bancaria no puede estar en blanco",
    "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"No tienes un producto BPay activo",
    "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"No puede cambiar el estado de un cliente que está cancelado.",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Debe proporcionar un valor para el parámetro 'ChangeFromDate' o 'PaymentReference'",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Debe proporcionar un valor para el parámetro 'ChangeFromDate' o 'ChangeFromPaymentNumber'",
    "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"El cliente NO está activo.",
    "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"El año/mes de la tarjeta de crédito ya ha vencido.",
    "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"No se pudo encontrar un cliente con los detalles proporcionados.",
    "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Debe proporcionar un valor para el parámetro 'DateField' cuando se proporcionen los valores 'DateFrom' o 'DateTo'.",
    "EZIDEBIT_ERROR_CODE_DATE_FROM":"Debe proporcionar un valor para los parámetros 'DateFrom' o 'DateTo' cuando se proporciona el valor 'DateField'.",
    "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom no puede ser más de xx meses antes de \"DateTo\"",
    "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Debe proporcionar un valor para el parámetro 'DebitDate'",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"La clave digital es incorrecta o se le niega el acceso a esta función. La validación de la clave digital ha fallado. Comprueba que estás utilizando la clave digital correcta",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Simbolo no valido. El token de cliente no es válido para esta clave digital",
    "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Debe proporcionar un valor para el parámetro 'EziDebitCustomerID' o el parámetro 'YourSystemReference'",
    "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Esta función no está disponible actualmente ya que se está realizando el procesamiento de Ezidebit. Por favor, inténtelo de nuevo más tarde...",
    "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Cliente no activo (Estado: <Código de estado>). El estado del cliente no es A (Activo) o N (Nuevo)",
    "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Ingresó un número de tarjeta de crédito no válido: su gama de productos no incluye xxxxxxx",
    "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"El Cliente no tiene Fondos suficientes para cubrir este Monto de Ajuste.",
    "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Longitud del número de cuenta no válida",
    "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Valor no válido proporcionado para el parámetro 'ApplyToAllFuturePayments'. Los valores válidos son: 'SI' o 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Formato no válido proporcionado para el parámetro 'BankAccountBSB'. Los valores válidos deben tener solo seis dígitos",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Ingresó un número BSB de cuenta bancaria no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Número de cuenta bancaria ingresado no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Se ingresó un nombre de cuenta bancaria no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"BankReceiptId' no se pudo determinar",
    "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Número de tarjeta de crédito ingresado no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Valor no válido proporcionado para el parámetro 'ChangeFromPaymentNumber'. Los valores válidos deben ser numéricos y mayores o iguales a uno.",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Valor no válido proporcionado para el parámetro 'ChangeFromDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Valor no válido proporcionado para el parámetro 'ChangeToDate'. Los valores válidos son cualquier fecha con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Valor no válido proporcionado para el parámetro 'ContractStartDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Valor no válido proporcionado para el parámetro 'DateField'. Los valores válidos son: 'PAGO' o 'LIQUIDACIÓN'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Valor no válido proporcionado para el parámetro 'DateFrom'. Los valores válidos son cualquier fecha con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Valor no válido proporcionado para el parámetro 'DateTo'. Los valores válidos son cualquier fecha con el formato 'AAAAMM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Valor no válido proporcionado para el parámetro 'DayOfMonth'. Los valores válidos deben estar entre 1 y 31.",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Valor no válido proporcionado para el parámetro 'DayOfWeek'. Los valores válidos son: 'LUN', 'MAR', 'MIÉ', 'JUE' o 'VIE'.",
    "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Valor no válido proporcionado para el parámetro 'DebitDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Clave digital no válida.",
    "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Se han introducido datos de domiciliación no válidos: su gama de productos no incluye domiciliaciones.",
    "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Dirección de correo electrónico no válida ingresada",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Se ingresó un mes de vencimiento de la tarjeta de crédito no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"Se ingresó un año de vencimiento de la tarjeta de crédito no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Valor no válido proporcionado para el parámetro 'EzidebitCustomerID'. Los valores válidos deben tener 50 caracteres o menos",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Se proporcionó un valor no válido para el parámetro 'SchedulePeriodType'. Los valores válidos son: '4', 'F', 'H', 'M', 'N', 'Q', 'W' o 'Y'",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Valor no válido proporcionado para el parámetro 'FirstWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"El nombre contiene caracteres no estándar que no están permitidos",
    "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Se proporcionó un valor no válido para el parámetro \"Cuarta semana del mes\". Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'EziDebitCustomerID' y 'YourSystemReference'",
    "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"El apellido contiene caracteres no estándar que no están permitidos",
    "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"La longitud del parámetro 'YourPayerNumber' es mayor que la longitud máxima.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Valor no válido proporcionado para el parámetro 'LimitToNumberOfPayments'. Los valores válidos deben ser numéricos y mayores o iguales a cero.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Se proporcionó un valor no válido para el parámetro 'LimitToTotalAmountInCents'. Los valores válidos deben ser numéricos y mayores o iguales a cero.",
    "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Valor no válido proporcionado para el parámetro 'KeepManualPayments'. Los valores válidos son: 'SI' o 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Nombre inválido en la tarjeta de crédito ingresado",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Se proporcionó un valor no válido para el parámetro 'NewPaymentAmountInCents'. Los valores válidos deben ser mayores o iguales a 200 ($2 dólares).",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Valor no válido proporcionado para el parámetro 'NewStatus'. Los valores válidos son: 'A', 'H' o 'C'",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Valor no válido proporcionado para el parámetro 'NewYourSystemReference'. Los valores válidos deben tener 50 caracteres o menos",
    "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Conflicto de parámetros. No puede proporcionar un número mayor que cero para 'LimitToNumberOfPayments' y 'LimitToTotalAmountInCents'",
    "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"El monto del reembolso parcial no puede estar entre el monto del débito programado y el monto del pago total",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Valor no válido proporcionado para el parámetro 'PaymentAmountInCents'. Los valores válidos deben ser mayores o iguales a 200 ($2 dólares).",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Valor no válido proporcionado para el parámetro 'PaymentMethod'. Los valores válidos son: 'ALL', 'DR' o 'CR'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Se ingresó una referencia de pago no válida",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Valor no válido proporcionado para el parámetro 'PaymentSource'. Los valores válidos son: 'TODO', 'PROGRAMADO', 'WEB', 'TELÉFONO' o 'BPAY'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Valor no válido proporcionado para el parámetro 'PaymentType'. Los valores válidos son: 'TODO', 'PENDIENTE', 'EXITOSO' o 'FALLIDO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Número de teléfono móvil ingresado no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Código postal ingresado no válido",
    "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"No se pudo encontrar el pago con la referencia 'xxxxxxx'.",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Valor no válido proporcionado para el parámetro 'RefundAmountInCents'. Los valores válidos son un monto de reembolso entre 100 y el monto de la transacción original",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"El instrumento de pago del reembolso no coincide con el instrumento de pago original",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Valor no válido proporcionado para el parámetro 'RefundAmountInCents'. Los valores válidos son un monto de reembolso entre 100 y el monto de la transacción original",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"El monto del reembolso excede el monto total del pago",
    "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Valor no válido proporcionado para el parámetro 'ScheduleStartDate'. Los valores válidos son cualquier fecha futura con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Valor no válido proporcionado para el parámetro 'SecondWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Valor no válido proporcionado para el parámetro 'SmsExpiredCard'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Valor no válido proporcionado para el parámetro 'SmsFailedNotification'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Valor no válido proporcionado para el parámetro 'SmsPaymentReminder'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Se proporcionó un valor no válido para el parámetro 'ThirdWeekOfMonth'. Los valores válidos son: 'SI' o 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Simbolo no valido. El token no es numérico.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Se ingresó un número CCV de tarjeta de crédito no válido: el número CCV debe tener 3 o 4 dígitos",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Valor no válido proporcionado para el parámetro 'CustomerName'. El valor debe tener menos de 80 caracteres.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Valor no válido proporcionado para el parámetro 'CustomerStatus'. Los valores válidos son: 'TODO', 'EN ESPERA', 'PENDIENTE', 'CANCELADO', 'ACTIVO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Valor no válido proporcionado para el parámetro Pedido. Los valores válidos son: 'ASC', 'DESC'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Valor no válido proporcionado para el parámetro OrderBy. Los valores válidos son: 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Valor no válido proporcionado para el parámetro PageNumber. Los valores válidos deben ser numéricos y mayores que cero.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Se ingresó un valor no válido para el parámetro 'Reactivar'. Los valores válidos son 'SÍ' o 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Se proporcionó un valor no válido para el parámetro 'YourPayerNumber'. Los valores válidos son enteros positivos.",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Valor no válido proporcionado para el parámetro 'YourGeneralReference'. Los valores válidos deben tener 50 caracteres o menos",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Valor no válido proporcionado para el parámetro 'YourSystemReference'. Los valores válidos deben tener 50 caracteres o menos",
    "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Valor no válido proporcionado para el parámetro 'DebitDate'. El valor válido es cualquier fecha posterior a los treinta y un (31) días anteriores, con el formato 'AAAA-MM-DD'",
    "EZIDEBIT_ERROR_CODE_LAST_NAME":"Debe proporcionar un valor para el parámetro 'LastName'",
    "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Debe proporcionar un valor para el parámetro 'DigitalKey'",
    "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Debe proporcionar un valor para el parámetro 'CustomerStatus'",
    "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Debe proporcionar un valor para el parámetro 'PaymentID' o 'BankReceiptID'. Proporcione un valor para PaymentID o BankReceiptID.",
    "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"No ha proporcionado ningún dato de tarjeta de crédito o cuenta bancaria. No se han facilitado datos obligatorios para la actualización de los datos de facturación",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Debe proporcionar un valor para el parámetro 'customerName'",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Debe proporcionar un valor para el parámetro 'Pedido' cuando se proporciona el valor 'Ordenar por'.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Debe proporcionar un valor para el parámetro 'OrderBy' cuando se proporcione el valor 'Order'.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Debe proporcionar un valor para el parámetro 'YourPayerNumber'",
    "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Debe proporcionar un valor válido para el parámetro 'MobileNumber' si desea que el cliente reciba notificaciones por SMS",
    "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Debe proporcionar un día de la semana al crear un horario Semanal, Quincenal, 4 semanas o Día de la semana en el mes",
    "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Ningún dato coincidió con los parámetros de selección.",
    "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Valor nulo detectado, pase la cadena vacía en lugar de nulo para los parámetros para los que no desea proporcionar un valor",
    "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Debe proporcionar un valor para el parámetro 'PaymentID' o 'BankReceiptID'",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'ChangeFromDate' y 'ChangeFromPaymentNumber'",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Conflicto de parámetros. 'ScheduleStartDate' y 'DayOfMonth' no están alineados.",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Conflicto de parámetros. 'ScheduleStartDate' y 'DayOfWeek' no están alineados.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Agregar pago denegado: solo los clientes activos pueden tener pagos agregados a su programación",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Agregar pago denegado: ya tiene un pago registrado con ref xxxxxxx. Una referencia de pago debe identificar de forma única un solo pago.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Agregar pago denegado: este cliente ya tiene xx pagos en esta fecha.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Agregar pago denegado: este cliente está en espera debido a que los detalles de la cuenta bancaria no son válidos y estos detalles de la cuenta no se han cambiado.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Agregar pago denegado: este cliente está en espera debido a que los detalles de la tarjeta de crédito no son válidos y estos detalles de la tarjeta no se han cambiado.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Agregar pago denegado: el estado xx de este cliente no permite agregar nuevos pagos.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Agregar pago denegado: el estado de este cliente de 'xxxxx' no permite agregar nuevos pagos.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"No configurado para pagos con tarjeta de crédito. La cuenta del cliente no está configurada para procesar pagos con tarjeta de crédito en tiempo real para el tipo de tarjeta del cliente",
    "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Debe proporcionar un valor para el parámetro 'PaymentReference'",
    "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"El pago ha sido procesado y no se puede eliminar",
    "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"El pago original aún se está procesando. No se puede reembolsar en esta etapa.",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"No se encontró ningún registro de pago para PaymentId",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"No se encontró ningún registro coincidente para 'PaymentId' o 'BankReceiptId'",
    "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"El monto del reembolso excede el monto total del pago",
    "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"No se permiten devoluciones para el cliente.",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Ha pasado tanto la información de la tarjeta de crédito como la de la cuenta bancaria. Solo se pueden pasar los datos de la tarjeta de crédito o de la cuenta bancaria, no ambos.",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Conflicto de parámetros. No puede ingresar un valor para los parámetros 'PaymentID' y 'BankReceiptID'",
    "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"No se proporcionaron todos los parámetros requeridos",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'FirstWeekOfMonth' no están alineados.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'FourthWeekOfMonth' no están alineados.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'SecondWeekOfMonth' no están alineados.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Conflicto de parámetros. 'ScheduleStartDate' y 'ThirdWeekOfMonth' no están alineados.",
    "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"No se pudo encontrar el pago seleccionado para su eliminación.",
    "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"No utiliza un código de facturador Ezidebit BPay compartido.",
    "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Actualización de estado denegada: solo los clientes con un estado de 'A', 'N' o 'H' pueden cambiar usando este servicio.",
    "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"No se puede procesar la actualización - Se ingresó un número CCV de tarjeta de crédito no válido - El número CCV debe tener 4 dígitos para AMEX (WSvc)",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Los datos del informe no están disponibles actualmente ya que se está realizando el procesamiento de pagos. Por favor, inténtelo de nuevo más tarde",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Los datos del informe no están disponibles actualmente ya que el procesamiento de pagos se está realizando actualmente (PT). Por favor, inténtelo de nuevo más tarde...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Los datos del informe no están disponibles actualmente ya que el procesamiento de pagos se está realizando actualmente (SPS). Por favor, inténtelo de nuevo más tarde...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"El sistema no está disponible actualmente. Por favor, inténtelo de nuevo más tarde. No se puede abrir una conexión a la base de datos por alguna razón. Póngase en contacto con partner@ezidebit.com.au para obtener ayuda",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"El sistema no está disponible actualmente. Por favor, inténtelo de nuevo más tarde. Se produjo un error no controlado al extraer los detalles de la base de datos. Póngase en contacto con partner@ezidebit.com.au para obtener ayuda",
    "EZIDEBIT_ERROR_CODE_YES":"Debe proporcionar un valor 'SÍ' para al menos uno de los parámetros de la semana del mes al crear un horario de Día de la semana en el mes.",
    "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Debe proporcionar un valor para el parámetro 'YourGeneralReference'",
    "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Debe proporcionar un valor para el parámetro 'YourSystemReference'",
    "EZIDEBIT_NO_ERROR_DETECTED":"No se detectaron errores",
    "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Error al asignar el cliente al administrador de cuentas",
    "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"No se pudo asignar el revendedor al administrador de cuentas",
    "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Error al actualizar el ciclo de facturación del producto",
    "FAILED_TO_CREATE_ACCOUNT_MANAGER":"No se pudo crear el administrador de la cuenta",
    "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Error al crear un nuevo plan con todas las ofertas",
    "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"No se pudo crear un nuevo plan de revendedor con todas las ofertas",
    "FAILED_TO_DELETE_ACCOUNT_MANAGER":"No se pudo eliminar el administrador de cuentas",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Error al poner en cola la solicitud del plan de clonación",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"No se pudo poner en cola la solicitud para crear un nuevo plan con todas las ofertas",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"No se pudo poner en cola la solicitud para crear un nuevo plan de revendedor con todas las ofertas",
    "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"No se pudieron obtener tokens de acceso mediante el código de autenticación recibido de Quickbooks",
    "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"No se pudieron obtener tokens de acceso mediante el código de autenticación recibido de Xero",
    "FAILED_TO_RESEND_INVOICE":"No se pudo reenviar la factura",
    "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"No se pudo guardar el código de autenticación recibido de Quickbooks",
    "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"No se pudo guardar el código de autenticación recibido de Xero",
    "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Error al cancelar la asignación del cliente de un administrador de cuentas",
    "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"No se pudo desasignar el revendedor de un administrador de cuentas",
    "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Error al actualizar los detalles del administrador de la cuenta",
    "FAQ_CUSTOMER_CATEGORY_GENERAL":"General",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Puede actualizar fácilmente las semillas utilizando la función de edición en suscripciones.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Puede otorgar acceso otorgando acceso a la funcionalidad del portal en la vista de usuario.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Sí, puedes actualizar usando nuestro portal.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Sí, puedes suspender la suscripción usando c3.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. ¿Cómo aumentar las semillas de suscripción?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. ¿Cómo dar acceso al portal a un usuario normal?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. ¿Existe alguna opción para actualizar el método de pago usando C3?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. ¿Tiene C3 alguna opción para suspender las suscripciones?",
    "FAQ_HEADING":"Preguntas frecuentes",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Aplicación general",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":" A partir de ahora, C3 está disponible en inglés (en-us), español(es) y taiwanés(tw). Además de esto, C3 admite monedas según las regiones del Centro de socios y la facturación.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Sí, esto se puede lograr en C3 definiendo etiquetas. Debe definir etiquetas (par de valores) y asignarlas a los clientes. Por ejemplo, puede definir una etiqueta denominada SupportAgent y un apodo de un agente (por ejemplo, Agent001) como valor. Si un agente de la mesa de ayuda tiene una de las etiquetas configuradas como SupportAgent y uno de los valores para esta etiqueta en particular como Agent001, el agente podrá ver aquellos clientes que tienen el par de valores de etiqueta {SupportAgent, Agent001} configurado.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Debe ir a Configuración >> Notificación por correo electrónico donde puede configurar C3 para enviar correos electrónicos basados en eventos. C3 le proporciona varios eventos para los cuales puede configurar correos electrónicos. También puede ver el correo electrónico de vista previa según el evento. Puede configurar la notificación por correo electrónico para un evento en el que el cliente compra cualquier oferta. Puede seleccionar el destinatario según su función o sus contactos de facturación. Además, también puede seleccionar la lista CC y BCC. Una vez hecho esto, siempre que ocurra dicho evento, C3 enviará notificaciones por correo electrónico a todos los destinatarios configurados en la configuración de Notificación por correo electrónico.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":" Puede descargar informes de Resumen de impuestos y partidas de impuestos desde el mosaico Resumen de ingresos y costos disponible en el Portal de socios.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Sí, descuentos de soporte C3. Puede crear cupones de descuento y aplicarlos a planes o clientes específicos también. Puede definir diferentes parámetros para un cupón, como el canje máximo, la fecha de inicio de validez, la fecha de inicio de validez y el porcentaje de descuento.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Puede personalizar C3 usando Logos for Branding. C3 le ofrece reemplazar el logotipo de bienvenida en la página de bienvenida y el logotipo de inicio de sesión por el suyo. Incluso puedes personalizar los correos electrónicos de notificación con tu logotipo para mejorar el impacto de la marca.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Sí, podemos realizar los cambios necesarios por nuestra parte para que el cliente acceda a la página de inicio de sesión en lugar de a la página de bienvenida de C3.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"No, aún no estamos certificados. Pero estamos utilizando la configuración del mismo nivel para mantener sus datos seguros y protegidos.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Sí, podemos hacer eso. Le proporcionaremos una dirección IP; deberá crear un registro con la misma donde se administra su DNS. Su proveedor de DNS le ayudará a hacerlo.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"No, puede ir directamente a Configuración y actualizar las credenciales para el Centro de socios o SMTP en caso de algún cambio.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. ¿Cuáles son todos los idiomas y monedas compatibles con C3?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. ¿Puedo hacer que los usuarios de mi lado traten solo con un conjunto de clientes?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":" 2. ¿Cómo configuro C3 para enviar correos electrónicos cuando un cliente realiza una compra? ¿Cómo configuro también los destinatarios de dichos correos electrónicos?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. ¿Qué tipo de informes fiscales están disponibles para descargar?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. ¿C3 admite descuentos?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. ¿Qué tipo de personalización ofrece C3 en términos de apariencia (etiquetado blanco/marca)?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. ¿Existe alguna manera de permitir que mis clientes hagan clic en un enlace de mi portal que los lleve a la página de inicio de sesión en lugar de a la página de bienvenida de C3?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. ¿Cumple C3 PCI?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":" 8. ¿Puedo vincular mi nombre de dominio preferido a C3? ¿Qué se necesita para hacer eso?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. ¿Debo comunicarme con el soporte de C3 cuando las credenciales de mi Centro de socios o SMTP cambiaron o expiraron?",
    "FAQ_PARTNER_CATEGORY_BILLING":"Facturación",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Actualmente, C3 admite cuentas manuales, de tarjeta de crédito, ACH (solo para clientes de EE. UU.) y bancarias (solo para clientes de Australia y Nueva Zelanda).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Sí, los clientes reciben una copia de la factura en formato PDF en un correo electrónico de notificación cuando el socio aprueba la factura.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"En tales casos, puede evitar que C3 cobre a los clientes registrando el pago en la factura en particular. Debe ir a Resumen de ingresos y costos >> Seleccionar cliente >> Facturas. Desde aquí, deberás seleccionar la factura por la que has cobrado el pago. Aquí encontrará una opción como Pago Registrado.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"No admitimos múltiples ciclos de facturación. Pero en este caso, podemos alinear a todos los clientes con un ciclo de facturación particular. Este es un proceso manual. Por ejemplo, su ciclo de facturación actual para un cliente es del 10 de este mes al 9 del próximo mes y en C3, ciclo de facturación del 1 de este mes al 1 del próximo mes. Dado que todos los clientes han realizado todos los pagos hasta el 9 de este mes. Calcularemos el monto correspondiente del 1 al 9 y lo ajustaremos en la próxima factura, lo que resolverá el problema. De manera similar, también podemos hacer lo mismo para otros ciclos de facturación. A partir del próximo mes, todos los clientes estarán alineados al mismo ciclo de facturación.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Sí, podemos realizar ajustes respecto a los pagos en la factura correspondiente. Por el momento no existe una versión automatizada. Podemos encargarnos de esto.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Ahora admitimos Bill&Pay, Stripe, EziDebit, Authorize.net y Cybersource. Si está utilizando otros que no sean estos, definitivamente podemos buscar la integración de su pasarela de pago con C3.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 está diseñado para admitir varias pasarelas de pagos como Bill&Pay, Stripe, EziDebit, Authorize.net y CyberSource hasta el momento. Sería fantástico si utilizara cualquiera de ellos como su pasarela de pago existente; de lo contrario, podemos intentar diseñar C3 para admitir sus pasarelas de pago.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Actualizamos las ofertas de C3 con la última oferta de Microsoft cada mes. En caso de cambio de precio, deberá crear un nuevo plan o actualizar el plan existente con nuevos precios de oferta. De forma predeterminada, C3 aceptará un nuevo precio para un nuevo cliente. Pero para los clientes existentes, puede establecer un nuevo precio para la oferta suscrita actualmente. Además, puede elegir la fecha de vigencia de los nuevos precios, como con efecto inmediato en el cambio de precio, el próximo ciclo de facturación o la próxima fecha de renovación.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"En C3, las reglas fiscales se definen mediante combinaciones de {País, Provincia estatal y Código fiscal}, {País, Código postal} o {País, Provincia estatal y Código postal}. Por ejemplo, la regla fiscal definida para País: EE. UU. y dejar la provincia y el código postal en blanco significa que la tasa impositiva se aplica a todos los clientes de EE. UU. Si desea definir una regla para un estado en particular, proporcione los valores de País y Estado al definir la regla.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Sí, usted puede hacer esto. Debe ir a Configuración >> Impuestos y crear una regla de impuestos con un porcentaje de impuestos del 0%. Aplíquelo al cliente al que desea eximir de la regla fiscal (aplicable a otro conjunto de clientes).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Puede decidir e informarnos la fecha de generación de la factura. Lo configuraremos por usted y podrá verificarlo yendo a Configuración de socio >> Facturación. Aquí puedes ver todas las fechas relacionadas con Billings. Incluso usted puede cambiar dicha fecha para un cliente específico yendo a la configuración del cliente.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"C3 le permite decidir las fechas en las que su cliente debería poder ver la factura. Después de generar la factura, el socio debe aprobarla (tras realizar cualquier ajuste si es necesario) para que esté disponible para los clientes.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Sí, C3 le brinda la opción de realizar cambios en las líneas de pedido de la factura después de generarla. Debe realizar el cambio requerido (por ejemplo, desea agregar una tarifa única/exención) según sus requisitos antes de la fecha de finalización de la factura. No puede realizar ningún cambio en la factura una vez finalizada.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"En C3, podemos configurar la notificación por correo electrónico para dichos eventos. En caso de éxito o fracaso del pago, C3 enviará un correo electrónico para informarle. Todos los destinatarios configurados para dichos eventos recibirán correos electrónicos de notificación. Además, C3 tiene una función de incumplimiento de pago en Resumen de ingresos y costos donde el socio puede realizar un seguimiento de los registros de cualquier incidente de incumplimiento de pago.",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. ¿Cuáles son los métodos de pago admitidos por C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. ¿Recibe el cliente una copia de la factura en formato pdf cuando la factura esté lista?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. ¿Qué pasa si cobré un pago mediante cheque o efectivo y el método de pago del cliente es tarjeta de crédito? ¿Cómo evito que C3 le cobre al cliente la factura actual?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Hemos estado utilizando nuestros propios sistemas de facturación y hemos cobrado a nuestros clientes hasta la fecha. Nuestros clientes tenían diferentes ciclos de facturación. ¿Qué tipo de soporte obtenemos cuando migramos a C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. ¿Existe alguna forma de gestionar los pagos por adelantado contra una factura?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. ¿Cuáles son las pasarelas de pago que C3 admite de forma inmediata?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. ¿Puedo utilizar mi proveedor de pasarela de pago actual o debería utilizar el que viene con C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. ¿Qué sucede cuando los precios de Microsoft cambian y queremos actualizar nuestros precios para nuestros clientes?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. ¿Cómo aborda C3 los impuestos?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. ¿Puedo eximir a un cliente en particular de una norma fiscal que se aplica a otro grupo de clientes?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. ¿Cómo defino cuándo C3 debe generar facturas?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. ¿Mi cliente podrá ver las facturas inmediatamente después de que se generen?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. ¿Tengo la opción de realizar cambios en las líneas de la factura después de generarla? ¿Puedo agregar cambios relacionados con una tarifa única/exención a una factura?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. ¿Cómo sé yo (socio) si un pago se realizó correctamente o no?",
    "FAQ_PARTNER_CATEGORY_SELL":"Vender",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_1":" Un plan es un grupo de ofertas que puede vender a su cliente al precio que usted elija. Debe tener un plan para incorporar o crear un cliente.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_2":" Sí, C3 le ofrece la función de crear ofertas de socios. Por ejemplo, desea brindar soporte 24 horas al día, 7 días a la semana a sus clientes. Luego, puede crear una oferta para socios de soporte Office365 24 horas al día, 7 días a la semana y venderla al precio que elija.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Sí, puede incorporar cualquier cliente existente en el Centro de socios a C3. Debe tener un ID de Microsoft (obtenerlo en el Centro de socios) y un plan que tenga la oferta correspondiente a la oferta suscrita en PC. Aparte de esto, C3 también le ofrece la función de crear un nuevo cliente directamente desde C3 (no es necesario ir a la PC para crear un cliente).",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Para lograr esto, debemos incorporar a dicho cliente a cualquier plan. Puede poner la cantidad de oferta como Cero. Una vez que el cliente se haya incorporado correctamente, se sincronizarán automáticamente las suscripciones basadas en el uso para ese cliente en particular.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_5":" En tales casos, habrá una discrepancia en la cantidad entre PC y C3. Nuestro equipo puede solucionar estos desajustes.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Podemos hacer ajustes en las facturas, pero a partir de ahora, C3 no admite reembolsos.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Sí, C3 calcula el uso de los clientes y, en función del consumo de datos, genera la factura.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Sí, puede crear un cupón de descuento con el porcentaje de descuento requerido y las recurrencias máximas establecidas en 3. Ahora asigne el cupón recién creado a un cliente específico. Una vez hecho esto, el cliente obtendrá el descuento aplicable para las siguientes tres facturas.",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. ¿Qué es un plan?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. ¿Puedo vender ofertas que no estén basadas en Microsoft, como servicios personalizados?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. ¿Puedo incorporar a un cliente existente?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. ¿Cómo puedo incorporar a un cliente que solo tiene suscripciones basadas en el uso?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mi equipo compró licencias accidentalmente a través de una PC, ¿cuáles son las repercusiones de tales acciones? ¿Existe un proceso para solucionar tales discrepancias?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. ¿C3 admite reembolsos?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. ¿C3 maneja la facturación de Azure por el uso de mis clientes?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. Tengo un cliente incorporado a C3 y quiero ofrecer un descuento para las próximas tres facturas, ¿puedo hacerlo?",
    "FEATURE_COMMENTS_ORDER_NO":" N° de pedido:",
    "FEBRUARY":"Febrero",
    "FIELD_REQUIRED":"{{atributo}} es obligatorio",
    "FILTER_SELECT_A_SOURCE_CURRENCY":"Seleccione",
    "FILTER_SELECT_A_TARGET_CURRENCY":"Seleccione",
    "FIX_AUTO_RENEW_STATUS_MISMATCH":"Arreglar el desajuste de estado de renovación automática",
    "FIX_AZURE_MISSING_OFFER":"Arreglar la oferta faltante de Azure",
    "FIX_BILLING_CYCLE_MISMATCH":"Solucionar el desajuste del ciclo de facturación",
    "FIX_MISSING_OFFER":"Arreglar oferta faltante",
    "FIX_OFFER_MISMATCH":"Solucionar el desajuste de la oferta",
    "FIX_QUANTITY_MISMATCH":"Solucionar discrepancia de cantidad",
    "FIX_SCOPE_MISMATCH":"Corregir discrepancia en el alcance",
    "FIX_STATUS_MISMATCH":"Solucionar el desajuste de estado",
    "FIX_TERM_DURATION_MISMATCH":"Corregir el desajuste de la duración del plazo",
    "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Reservados todos los derechos.",
    "FOOTER_TEXT_1_POWERED_BY":" Energizado por",
    "FROM":"De",
    "General":"General",
    "GENERAL_SETTINGS_MAIN_ERROR":"Por favor corrija los errores en el formulario antes de enviarlo.",
    "GENERATE_INVOICE_REASON":"Razón",
    "GENERATE_INVOICE_REASON_PLACEHOLDER":"Proporcione una razón",
    "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Tokens de acceso a Quickbooks generados mediante el código de autenticación recibido de Quickbooks",
    "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Tokens de acceso de Xero generados mediante el código de autenticación recibido de Xero",
    "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Ocurrió un error al recuperar los detalles del proveedor de facturación.",
    "GET_CURRENCY_CODES_EXCEPTION":"Se produjo un error al obtener los códigos de moneda.",
    "GET_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al realizar la conversión de moneda.",
    "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Se produjo un error al obtener la lista de conversiones de moneda.",
    "GET_CURRENCY_OPTIONS_EXCEPTION":"Se produjo un error al obtener las opciones de moneda.",
    "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Se produjo un error al obtener los eventos del cliente para las notificaciones por correo electrónico",
    "GET_CUSTOMER_LIST_EXCEPTION":"Se produjo un error al obtener clientes.",
    "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Ocurrió un error al obtener los planes del cliente con la promoción activa",
    "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Se produjo un error al obtener la lista de destinatarios de notificaciones por correo electrónico",
    "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Se produjo un error al recibir los eventos para las notificaciones por correo electrónico",
    "GET_PARTNER_LOGO_EXCEPTION":"Se produjo un error al obtener el logotipo del socio",
    "GET_PARTNER_SETTINGS_EXCEPTION":"Se produjo un error al obtener la configuración del socio",
    "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al obtener los detalles de impuestos.",
    "GET_PARTNER_TAX_TYPES_EXCEPTION":"Se produjo un error al obtener los tipos de impuestos.",
    "GET_TARGET_CURRENCIES_EXCEPTION":"Se produjo un error al obtener las monedas de destino",
    "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Ocurrió un error al obtener la solicitud de relación con el revendedor.",
    "GOT_RESELLER_DETAILS":"Obtuve el revendedor de CSP con nombre: {{resellerName}}",
    "government":"Gobierno",
    "Government":"Gobierno",
    "GRAND_ACCESS_USER_EXCEPTION":"Este usuario ya tiene acceso por favor consulte con otro",
    "GRAND_TOTAL":"Gran total",
    "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Consumo por producto",
    "GRAPH_CUSTOMERS_ONBOARDED":"Clientes incorporados",
    "GRAPH_DATA_BILLED_AMOUNT":"Cantidad facturada",
    "GRAPH_DATA_QUANTITY":"Cantidad comprada",
    "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Ingresos por cliente",
    "GRAPH_TOP_SELLING_PRODUCT":"Los 10 productos más vendidos",
    "GREATER_THAN_ERP":"mayor que el ERP",
    "GRID_DOWNLOAD_COUPON_GRID":"Descargar cupones",
    "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"Ya existe un administrador de grupo con este correo electrónico en este grupo.",
    "GROUP_USERS_OF":" Usuarios del grupo de",
    "GROUP_USERS_TOOLTIP_AZUREGROUP":"Usuarios del grupo",
    "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Ver la aplicación en un idioma compatible con la aplicación.",
    "HEADER_CONTENT_HELP_MENU":"Contenido de ayuda sensible al contexto presentado en la barra lateral derecha",
    "HEADER_CONTENT_MENU_CONTACT_LOG":"Permite al socio ver el estado de todas las notificaciones y el contenido del correo electrónico.",
    "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Seguimiento de la actividad operativa del usuario.",
    "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Detalle básico del perfil del usuario que ha iniciado sesión",
    "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Administre la configuración de su cuenta C3 y acceda a los registros de actividad.",
    "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
    "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
    "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Le permite administrar la configuración a nivel de aplicación, notificaciones por correo electrónico, definir impuestos y más...",
    "HEADER_CONTENT_SIGNIN_USER":"Detalle básico del perfil del usuario que ha iniciado sesión",
    "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Contáctenos",
    "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Cerrar sesión",
    "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Usuario no autorizado",
    "HEADER_GO_TO_PROFILE":"Ir al perfil",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Contáctenos",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Detener la suplantación",
    "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Usuario no autorizado",
    "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Idioma",
    "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Idioma",
    "HEADER_TITLE_HELP_MENU":" Ayuda",
    "HEADER_TITLE_MENU_CONTACT_LOG":"Notificaciones",
    "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Registro de auditoría",
    "HEADER_TITLE_MENU_RESELLER_PROFILE":"Perfil",
    "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"Ajustes",
    "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"Ajustes",
    "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"Ajustes",
    "HEADER_TITLE_SIGNIN_USER":"Usuario registrado",
    "HEADING_ENTITY_LIST":"{{EntityName}} lista",
    "HELP_TEXT_SMTP_TEST_USERNAME":"Proporcione la dirección de correo electrónico para recibir el correo electrónico de prueba.",
    "HIGH":"Alto",
    "HOURS":"Horas)",
    "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"aquí",
    "I have read and agreed to":"He leído y acepto",
    "IF_ALREADY_EXISTS":" ya existe",
    "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Se usa para habilitar/deshabilitar los filtros en la página de inicio de registro público",
    "IGNORE_FROM_PSA_TEXT_DISP":"Ignorar al cliente del servicio externo",
    "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Junto con su privilegio actual",
    "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Sin tu privilegio actual",
    "IMPORT_LIST":"Importar lista",
    "In Progress":"En progreso",
    "IN_ACTIVE":"Inactivo",
    "INDEX_LOGOUT_APOLOGY_MESSAGE":"Este programa estuvo inactivo durante demasiado tiempo. Así que nos disculpamos por desconectarte, pero no teníamos otra opción.",
    "INDEX_LOGOUT_MESSAGE":"Lo sentimos, has cerrado sesión",
    "INDEX_MSG_LOGOUT_SOON_MESSAGE":"El modo inactivo está activado porque usted está inactivo durante demasiado tiempo. Como resultado, cerrará sesión en unos momentos.",
    "INDXE_LOGOUT_TEXT":"¡HAZ ALGO RÁPIDO! Tu sesión se cerrará en {{countdown}} segundo(s).",
    "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"No hay monedas disponibles registradas",
    "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"No hay estados disponibles registrados",
    "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Por favor seleccione un país",
    "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Seleccione primero la moneda de origen",
    "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"¿Está seguro de que desea iniciar el cargo de la factura para <%= currencySymbol%><%= factureAmount%> usando el método de pago: <br> <b><%= PaymentType%> | <%= NúmeroCuenta%></b> ? <br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o que se registraron por error. </pequeño>",
    "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"No hay cantidad pendiente de pago.",
    "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Todas las facturas seleccionadas estarán en cola para pagos. ¿Quieres continuar?",
    "INITIATE_STATUS_UPDATE_CONFIRMATION":"El estado de la factura cambiará a <%= factureStatus%>. ¿Quieres continuar?",
    "INITIATE_TEST_PAYMENT_CONFIRMATION":"Al cliente se le cobrará $<%= importe%>. ¿Quieres continuar?",
    "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Al revendedor se le cobrará $<%= monto%>. ¿Quieres continuar?",
    "INPUT_BILLINGPERIODID":"BillingPeriodId",
    "INPUT_BILLINGPERIODTYPE":"BillingPeriodTypeBillingPeriodType",
    "INPUT_CATEGORYID":"Categoria ID",
    "INPUT_CHANGE_AUTO_RENEW_STATUS":"Estado de renovación automática",
    "INPUT_CHANGE_TYPE":"Tipo de cambio",
    "INPUT_CONSUMPTIONTYPEID":"ID de tipo de consumo",
    "INPUT_CUSTOMERC3ID":"ClienteC3Id",
    "INPUT_CUSTOMERID":"Identificación del cliente",
    "INPUT_CUSTOMERNAME":"Nombre del cliente",
    "INPUT_DATA":"Entrada del usuario",
    "INPUT_DESCRIPTION":"Descripción",
    "INPUT_ENDIND":"EndInd",
    "INPUT_INTERNALPRODUCTID":"ID de producto interno",
    "INPUT_INVOICEID":"ID de factura",
    "INPUT_LABEL_PURCHASE_QUANTITY":"Cantidad de compra",
    "INPUT_NAME":"Nombre",
    "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
    "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
    "INPUT_PAGECOUNT":"Número de páginas",
    "INPUT_PAGEINDEX":"Índice de página",
    "INPUT_PAGESIZE":"Tamaño de página",
    "INPUT_PLANC3ID":"Tartán",
    "INPUT_PLANPRODUCTID":"PlanProductId",
    "INPUT_PRICEFORPARTNER":"PrecioparaSocio",
    "INPUT_PRODUCTID":"ID del Producto",
    "INPUT_PROVIDERID":"ID de proveedor",
    "INPUT_PROVIDERSELLINGPRICE":"ProveedorPrecio De Venta",
    "INPUT_QUANTITY":"Cantidad comprada",
    "INPUT_REACTIVATE":"Reactivar",
    "INPUT_SALETYPE":"Tipo de venta",
    "INPUT_STARTIND":"InicioInd",
    "INPUT_SUSPEND":"Suspender",
    "INPUT_TENANTID":"ID de inquilino",
    "INPUT_WITHADDONS":"ConComplementos",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"volver al carrito",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Volver a Pedidos",
    "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Perfil de pago",
    "INSTANT_PAY_BUTTON_TEXT_PAY":"Pagar",
    "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"La pasarela de pago actual aún no es compatible con el pago instantáneo. Póngase en contacto con el soporte para obtener ayuda.",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Método de pago",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Pago seguro",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Cargue la hoja mapeada para mapear en formato .csv. Descargue el archivo <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para cargarlo.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Este archivo se utiliza para asignar las entidades entre C3 y AutoTask Manage. No elimine ningún texto o datos existentes en este archivo.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Los únicos campos que necesitan actualización son \"AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId y ContractServiceName\". El resto de los campos están ahí para ayudar a identificar las contrapartes C3 para el mapeo.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Si los campos mencionados anteriormente están completos en la hoja, el mapeo está disponible, si los campos mencionados anteriormente están vacíos, entonces el mapeo es obligatorio.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Para completar la hoja de mapeo, puede consultar <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a>",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"El nombre y el ID entero (numérico) necesarios para completar la <b> hoja de mapeo</b> están disponibles en la <b>hoja de entidad AutoTask.</b>",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"La eliminación del mapeo no es posible a través de la hoja de mapeo. Para cambiar la asignación de acuerdo y producto, utilice la hoja de asignación. La reasignación de clientes solo es posible a través de la pantalla de asignación del producto comprado.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
    "INSTRUNCTION_PSA_BULK_BUTTON":"Instrucciones para la carga masiva de mapeo de productos comprados:",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Cargue la hoja mapeada para mapear en formato .csv. Descargue el archivo <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para cargarlo.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Este archivo se utiliza para asignar las entidades entre C3 y ConnectWise Manage. No elimine ningún texto o datos existentes en este archivo.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Los únicos campos que necesitan actualización son \"ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId y ConnectwiseProductname\". El resto de los campos están ahí para ayudar a identificar las contrapartes C3 para el mapeo.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Si los campos mencionados anteriormente están completos en la hoja, el mapeo está disponible, si los campos mencionados anteriormente están vacíos, entonces el mapeo es obligatorio.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Para completar la hoja de mapeo, puede consultar la <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a>",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"El nombre y el ID entero (numérico) necesarios para completar la <b> Hoja de mapeo</b> están disponibles en la <b>Hoja de entidad de Connectwise.</b>",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"La eliminación del mapeo no es posible a través de la hoja de mapeo. Para cambiar la asignación de acuerdo y producto, utilice la hoja de asignación. La reasignación de clientes solo es posible a través de la pantalla de asignación del producto comprado.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Para facilitar la asignación entre los productos C3 y ConnectWise Manage, cargue la hoja de asignación en formato .csv. Puede descargar el archivo <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> para actualizarlo.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"El archivo sirve para establecer asignaciones de productos entre C3 y ConnectWise Manage. Asegúrese de no eliminar ningún texto o dato existente dentro del archivo.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Solo es necesario actualizar los campos ConnectwiseProductId y ConnectwiseProductname. Otros campos ayudan a identificar las contrapartes de C3 para fines de mapeo.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Si los campos mencionados están completos, el mapeo está completo. Si están vacíos, es necesario realizar el mapeo.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Consulte la <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Hoja de entidades de Connectwise.xlsx.</a> para obtener instrucciones sobre cómo completar la hoja de mapeo. Los nombres y los identificadores enteros (numéricos) requeridos están disponibles en la Hoja de entidades de ConnectWise.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Tenga en cuenta que no es posible eliminar asignaciones mediante la hoja de asignación. Para modificar las asignaciones de productos, utilice la hoja de asignación proporcionada.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Sube el archivo y haz clic en ENVIAR para finalizar el proceso.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Cargue el archivo y haga clic en ENVIAR.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON":"Instrucciones para usar el botón Actualizar:",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Haga clic en el botón Actualizar para obtener los datos más recientes de PSA a C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"De forma predeterminada, al principio no habrá datos disponibles en Product Mapping. Haga clic en el botón Actualizar para obtener todos los datos disponibles de PSA a C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Cuando \"Actualizar\" está en progreso, el sistema desactivará otras pestañas. Si tiene éxito, las pestañas deshabilitadas volverán a ser visibles. En caso de error, esta pantalla proporcionará una opción para ignorar el error. Puede hacer clic en el botón ignorar error para habilitar las pestañas deshabilitadas.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Cuando \"Actualizar\" está en progreso, el sistema eliminará los datos de PSA existentes de C3 e insertará datos nuevos de PSA.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Si se realiza algún cambio en PSA, por ejemplo, adición o eliminación de empresa, acuerdo, productos o cambio en el estado de estas entidades, el usuario debe actualizar la asignación de productos para reflejar esos cambios en C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Haga clic en el botón </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\"> Actualizar</a> para obtener los datos más recientes. de PSA a C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" Botón para obtener los últimos datos de PSA a C3.",
    "INTERNAL_BILLING_ID":" ID de referencia de facturación:",
    "INTERNAL_SCHEDULE_RENEWAL_ID":"ID de renovación programada interna",
    "INTERNAL_SCHEDULED_RENEWAL_ID":"ID de renovación interna programada",
    "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"No se permite el cambio de estado de renovación automática",
    "INVALID_ATTEMPT_DECREASE":"No se permite disminuir la cantidad",
    "INVALID_ATTEMPT_REACTIVATE":"No se permite reactivar el producto.",
    "INVALID_ATTEMPT_SUSPEND":"No se permite suspender el producto.",
    "INVALID_COUNT":"El total de asientos no puede ser inferior a 1",
    "INVALID_COUPON":"Este vale no es válido",
    "INVALID_EXPIRY_DATE":"Fecha de caducidad no válida",
    "INVALID_INPUT":"Entrada inválida",
    "INVALID_MESSAGE_ENTITY":"Entidad de mensaje no válida",
    "INVALID_MESSAGE_TYPE":"Tipo de mensaje no válido",
    "INVALID_PORTAL_PAGE":"Página del portal no válida",
    "INVALID_PRODUCT_ID":"Un producto con el identificador dado no se encuentra en el sistema.",
    "INVALID_QUANTITY":"Cantidad no válida",
    "INVALID_RECIPIENTS":"Destinatarios no válidos",
    "INVALID_SEATS":"Detalle faltante o inválido. Los asientos deben ser un número positivo o negativo, limitado por las restricciones del proveedor",
    "INVALID_VALUE":"{{atributo}} no es válido",
    "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Volver a invitaciones",
    "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Agregar invitación",
    "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Editar invitación",
    "INVITES_ADD_FORM_BUTTON_CLEAR":"Limpiar todo",
    "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Plan",
    "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Artículos seleccionados",
    "INVITES_ADD_FORM_LABEL_OFFER":"Ofertas",
    "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
    "INVITES_ADD_FORM_TABLE_HEADER_PRICE":" Precio",
    "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Descripción",
    "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Nombre",
    "INVITES_ADD_FORM_URL_INVITE_URL":"URL de invitación",
    "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"invita",
    "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Agregar nueva invitación",
    "INVITES_LIST_CAPTION_TEXT_INVITES":"invita",
    "INVITES_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
    "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Editar",
    "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Descripción de la invitación",
    "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Nombre de la invitación",
    "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
    "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
    "INVITES_SAVE_SUCCESS":"Invitación guardada exitosamente",
    "INVITES_SELECT_OFFERS_PROMPT":"Por favor incluya al menos una oferta en la invitación.",
    "INVITES_UPDATE_SUCCESS":"Invitación actualizada con éxito",
    "INVOICE_ADD_ADJUSTMENT_END_DATE":"Fecha final",
    "INVOICE_ADD_ADJUSTMENT_START_DATE":"Fecha de inicio",
    "INVOICE_ADJUSTMENT_CONFIRMATION":"¿Quiere que esto se adeude como un ajuste en esta factura?",
    "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de facturas y cuotas.",
    "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Panel",
    "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Facturas",
    "INVOICE_COMMENTS":"Factura",
    "INVOICE_COUPON_APPLIED_MESSAGE":"Se aplica el cupón de descuento <strong>{{CouponCode}}*</strong>",
    "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Saldo adeudado",
    "INVOICE_DOWNLOAD_SELECT_ALL":"Seleccionar todo",
    "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"El correo electrónico se envía correctamente a la lista de usuarios.",
    "INVOICE_FOOTER_LINE_DESC":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
    "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Aparece en la vista de factura y en el PDF de la factura como línea de pie de página",
    "INVOICE_FOOTER_LINE_DISP":"Línea de pie de página de factura",
    "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Línea de pie de página de factura",
    "INVOICE_GENERATION_REASON":"Motivo de generación de la factura",
    "INVOICE_GENERATION_STATUS_TITLE":"Estado de generación de factura",
    "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Ir al resumen de ingresos y costos",
    "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Descargar facturas/Pagos",
    "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Volver al resumen de ingresos y costos",
    "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Descargar facturas/Pagos",
    "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importación de facturas de clientes exitosa",
    "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Seleccione al menos una factura de cliente antes de continuar.",
    "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Facturas para descargar para los clientes.",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Código de moneda",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Monto de la factura",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"ID de Microsoft",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"nombre calificado",
    "INVOICE_INVOICE_APPROVE":"Aprobar",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Volver a partidas de factura",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Volver a Facturas",
    "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Descargar factura",
    "INVOICE_INVOICE_CAPTION_TEXT":"Factura para",
    "INVOICE_INVOICE_FINALIZE":"Finalizar",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Resumen de la cuenta",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Dirección",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Cliente",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Resumen de factura",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":" Período de facturación",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":" Fecha de la factura",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"Número de valor agregado",
    "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Factura fiscal",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Detalle de factura",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Cuotas de factura",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Saldo de pagos pasados",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Basado en licencia",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Basado en uso",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":" Cantidad",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Tipo de carga",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Costo en socio",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Código promocional",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Descripción",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Descuento",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Importe de descuento",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Precio de oferta",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Cantidad comprada",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Fecha final",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"gran total",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Importe de la línea de factura",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Informe detallado",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":" Importe después del impuesto",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Monto subtotal",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Inquilino",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Fecha de inicio",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Importe del impuesto",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Porcentaje de impuesto",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Precio unitario",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"No se encontraron facturas. Por favor contacte al soporte de c3.",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Pagos de facturas",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Monto",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Fecha",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Motivo del fracaso",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"gran total",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Observaciones",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Estado",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Cantidad debida",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Fecha de vencimiento",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Fecha de la factura",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Estado",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Monto de pago",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Cantidad restante",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Cantidad utilizada",
    "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Ajustes",
    "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Prorrateo de instancia de ciclo",
    "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Cuotas de facturas pasadas",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Tarifa prorrateada",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Tarifas prorrateadas al comprar",
    "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Tarifa de compra",
    "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"cantidad devuelta",
    "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Cuota de renovación",
    "INVOICE_LINE_ITEM_TYPE_TAXES":"Impuestos",
    "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Cantidad de uso",
    "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Crédito",
    "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--vinculado a {{linkedProductName}}",
    "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
    "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>{{providerName}} Inquilino </b> {{providerTenantName}}",
    "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Tasa de conversión",
    "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Total parcial",
    "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Elementos de línea de factura con impuestos",
    "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Descuento promocional",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Comportamiento",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Correo electrónico",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Informe de partidas individuales de facturas",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de partidas individuales de la factura",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Ingrese la dirección de correo electrónico a quién desea enviar el correo electrónico, ingrese con coma (,) o punto y coma (;) y haga clic en enviar para recibir un archivo que contiene una lista del informe de partidas individuales de la factura.",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de partidas individuales de facturas.",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Cancelar",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Guardar favoritos",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Facturas",
    "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Pagos",
    "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
    "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Generado en",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Razón del fracaso",
    "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Cantidad restante",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Monto de pago",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Fecha de pago",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"ID de transacción",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Observaciones",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Estado de pago",
    "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Cantidad utilizada",
    "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Ver registros de contactos",
    "INVOICE_NUMBER":"Número de factura",
    "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Importación exitosa de pagos de facturas de clientes",
    "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Pagos de facturas para descargar para los clientes.",
    "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
    "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"No se puede actualizar la fecha de vencimiento con un valor nulo",
    "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"Propiedad de la factura actualizada con éxito",
    "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"La fecha de inicio no debe ser mayor que la fecha de finalización",
    "INVOICE_SENT_SUCCESSFULLY":"Factura enviada exitosamente",
    "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Aprobado",
    "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Finalizado",
    "INVOICE_STATUS_NAME_IN_PROGRESS":"En progreso",
    "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Generado",
    "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Publicado",
    "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Pagado",
    "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Parcialmente pagado",
    "INVOICE_TAB_NAME_COMMENTS":"Comentarios",
    "INVOICE_TAB_NAME_DETAILS":"Detalles",
    "INVOICE_TAB_NAME_NOTIFICATIONS":"Notificaciones",
    "INVOICE_TRANSACTION_REPORT_HEADER":"Informe de facturas y cuotas",
    "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Escribe un mensaje",
    "INVOICE_WILLBE_MAILED":"La factura se enviará por correo a su dirección de facturación. Gracias.",
    "InvoiceCurrency":"Moneda de factura",
    "INVOICES_LBL_OWNED_BY":"<b>Propiedad de </b> {{ownedBy}}",
    "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Período de facturación",
    "INVOICES_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
    "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Monto de la factura",
    "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Fecha de vencimiento de la factura",
    "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Fecha de generación de la factura",
    "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "IS_MANAGEABLE_ONLY_BY_PARTNER":"Es manejable sólo por socio.",
    "IS_MASTER":"Maestro",
    "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"¿Se requiere la aprobación del socio para los clientes revendedores?",
    "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"¿Se está reactivando el producto con Addons?",
    "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"¿Está disponible el filtro para el cliente?",
    "IS_TRIAL_OFFER":"oferta de prueba",
    "IsCustomPrice":"¿Es precio personalizado?",
    "JANUARY":"Enero",
    "JULY":"Julio",
    "JUNE":"Junio",
    "LABEL_ASSIGNED_RG":"Grupos de recursos asignados",
    "LABEL_BANNER_TEXT":"Mensaje",
    "LABEL_BANNER_TEXT_TOOL_TIP":"Escribe el mensaje para el banner.",
    "LABEL_BILLING_ADDRESS":"Dirección de Envio",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"La oferta de prueba solo se puede comprar una vez.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Esta oferta de prueba ya está disponible en su carrito.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"oferta de prueba para",
    "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Oferta de prueba para padres no disponible",
    "LABEL_Customer_Name":"Nombre del cliente",
    "LABEL_CUSTOMER_NAME":"Nombre del cliente",
    "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"Se seleccionaron productos del plan.",
    "LABEL_DESC_SELECTED_PLANS":"se eligieron los planes",
    "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"Se seleccionaron variantes del producto.",
    "LABEL_EFFECTIVE_ENTITY":"Entidad Relevante",
    "LABEL_EFFECTIVE_FROM":"Válido desde",
    "LABEL_EFFECTIVE_RECORD_NAME":"Registro Efectivo",
    "LABEL_EFFECTIVE_TO":"Expira el",
    "LABEL_IS_ACTIVE":"Está activo",
    "LABEL_NOTIFICATION_RECIPIENTS":"Destinatarios de la notificación",
    "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Introduzca los usuarios como valores separados por comas",
    "LABEL_NOTIFICATION_TABLE":"Notificaciones de pancartas",
    "LABEL_NOTIFICATION_TYPE":"Estilo de mensaje",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":" Asignar política para",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":" Asignaciones de políticas de",
    "LABEL_POLICY_CUSTOMER":"Cliente",
    "LABEL_POLICY_DEFINITION":"Definición de política",
    "LABEL_POLICY_DESCRIPTION":"Descripción de la política",
    "LABEL_POLICY_NAME":"Nombre de directiva",
    "LABEL_POLICY_RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
    "LABEL_POLICY_SUBSCRIPTION":"Suscripción",
    "LABEL_PORTAL_PAGE":"página del portal",
    "LABEL_ROLE_ADD_CUSTOM_ROLE":"agregar rol personalizado",
    "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Volver a roles personalizados",
    "LABEL_ROLE_CUSTOM_ROLE_ADD":"Agregar rol personalizado",
    "LABEL_ROLE_CUSTOMER":"Cliente",
    "LABEL_ROLE_RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
    "LABEL_ROLE_ROLE_DEFINITION":" Definición de rol",
    "LABEL_ROLE_ROLE_DESCRIPTION":"Descripción del rol",
    "LABEL_ROLE_ROLE_NAME":"Nombre de rol",
    "LABEL_ROLE_SUBSCRIPTION":"Suscripción",
    "LABEL_SHIPPING_ADDRESS":"Dirección de envío",
    "LABEL_TEXT_ADDONS":"Complementos",
    "LABEL_TEXT_ADDRESS_1":"Dirección 1",
    "LABEL_TEXT_ADDRESS_2":"Dirección 2",
    "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Disponible para el cliente",
    "LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "LABEL_TEXT_BILLING_TYPE":"Tipo de facturación",
    "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" El plazo de cancelación vence en",
    "LABEL_TEXT_CATEGORY":"Categoría",
    "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Crear cuenta de Microsoft",
    "LABEL_TEXT_DISCOUNT":"Descuento",
    "LABEL_TEXT_DISTRIBUTER_OFFER":"Ofertas de distribuidores",
    "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Habilitar todas las promociones",
    "LABEL_TEXT_LINKED_PRODUCT":"Producto vinculado",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Categoría :",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"software perpetuo",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Suscripciones de software",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Término",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":" Término -",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Término :",
    "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"No disponible para el cliente",
    "LABEL_TEXT_OWNER_NAME":"Nombre del dueño",
    "LABEL_TEXT_PRICE":" Precio",
    "LABEL_TEXT_RESERVED_INSTANCES":"Rhode Island",
    "LABEL_TEXT_RETAIL_PRICE":"Precio al por menor",
    "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Suscripción de origen",
    "LABEL_TEXT_STATE_PROVINCE":"Provincia del estado",
    "LABEL_TEXT_STATUS":"Estado",
    "LABEL_TEXT_TARGET_SUBSCRIPTION":"Suscripción objetivo",
    "LABEL_TEXT_TAX_CODE":"Código de impuestos",
    "LABEL_TEXT_TAX_TYPE":"Tipo de impuesto",
    "LABEL_TEXT_TENANT_NAME":"Nombre del inquilino",
    "LABEL_TEXT_TYPE_IN_NAME":"Escribe un nombre",
    "LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "LABEL_TEXT_ZIP_CODE":"Código postal",
    "LABEL_TO_INDICATE_A_NEW_OFFER":"Nuevo",
    "LABEL_UNASSIGNED_RG":"Grupos de recursos no asignados",
    "LABLE_ADDITIONAL_CHARGES_TOTAL":"Cargos adicionales",
    "LABLE_CUSTOMER_ADMIN_ROLE":"Administrador de clientes",
    "LABLE_CUSTOMER_READER_ROLE":"Lector de clientes",
    "LABLE_DISCOUNTS_TOTAL":"Descuento total",
    "LABLE_SUBSCRIPTIONS_TOTAL":"Total de suscripciones",
    "LABLE_TAXES_TOTAL":"Impuestos Totales",
    "LABLE_TEXT_COST_PRICE":"Precio de coste",
    "LABLE_TEXT_COST_TO_PARTNER":"Costo para el socio",
    "LABLE_TEXT_DISPLAY_NAME":"Nombre para mostrar",
    "LABLE_TEXT_EVENTS":"Eventos",
    "LABLE_TEXT_FRIENDLY_NAME":"Nombre amigable",
    "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"¿Disponible para el cliente?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"¿Promoción disponible para el cliente?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"¿Promoción disponible para revendedores?",
    "LABLE_TEXT_MAXVALUE":" Valor máximo :",
    "LABLE_TEXT_MINVALUE":" Valor mínimo:",
    "LABLE_TEXT_OFFER_NAME":"Nombre de la oferta",
    "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Precio de venta del proveedor",
    "LABLE_TEXT_SALE_PRICE":"Precio de venta",
    "LABLE_TEXT_SALEPRICE":" Precio de venta :",
    "LABLE_TEXT_SLAB":"Detalles de losa",
    "LABLE_TEXT_SLAB_SALE_PRICE":"Precio de venta (UMN)",
    "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Costo para el socio",
    "LABLE_TEXT_TYPE":"Tipo de facturación",
    "LABLE_TEXT_UNIT_OF_MEASURE":"(UMN)",
    "LABLE_WAIVER":"Exención",
    "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"El último intento de cargar los datos resultó en un error. Haga clic en actualizar para sincronizar los últimos cambios de PSA a C3.",
    "LATEST_COMMENTS":"Último comentario",
    "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Acceso",
    "LAYOUTLOGIN_HEADER_TEXT":"Acceso",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Por favor inicie sesión para continuar",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Sistemas Spektra. Reservados todos los derechos.",
    "LBL_COUPON_CODE_TEXT":"Código promocional",
    "LBL_DISCOUNT_TEXT":"descuento",
    "LBL_OFF":"apagado",
    "LBL_TEXT_NOT_PROVIDED":"No provisto",
    "LESS_THAN_LIST_PRICE":"precio de lista menor que",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Encuentre los errores a continuación.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Esta operación no se puede completar porque no se cumplen los requisitos previos. La asignación de un complemento requiere una oferta básica y la revocación de una oferta básica requiere que se publiquen los complementos.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Esta operación no se puede completar porque no se cumplen los requisitos previos. La asignación de un complemento requiere una oferta básica y la revocación de una oferta básica requiere que se publiquen los complementos.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Oferta",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Ofertas de requisitos previos",
    "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Solicitud rechazada",
    "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Descargar informe de cambio de licencia",
    "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Obtener informe de cambio de licencia",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"cantidad de cierre",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Creado por",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Fecha de cambio",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Informe de cambio de licencia",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Nueva cantidad",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Nuevo estado",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Cantidad antigua",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"antiguo estado",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Cantidad de apertura",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Producto",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nombre del revendedor",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nombre del arrendatario vinculado",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"Estado de los términos y condiciones",
    "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"La fecha 'desde' del informe no debe ser posterior a la fecha 'hasta'",
    "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Aceptado",
    "LICENSE_CONSUMPTION_REPORT":"Informe de consumo de licencia",
    "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Seleccione",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Cliente",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Inquilino",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Por favor seleccione cliente",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Por favor ingrese el nombre de la suscripción",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Por favor seleccione inquilino",
    "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"filtros",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Diferencia de cantidad asignada",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Disponible en C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Disponible en PC",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Licencias asignadas en C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Licencias en C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Cliente",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"Dirección de correo electrónico",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Diferencia de cantidad total",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Productos mapeados",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Licencias asignadas en proveedor",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Licencias en proveedor",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Licencias no utilizadas en el proveedor",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Suscripción",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Inquilino",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Ver detalles",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Informe de consumo de licencia",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Informe de ganancias",
    "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Informe de consumo de licencias de exportación CSV",
    "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Inquilinos",
    "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Obtener resumen de licencia",
    "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Descargar informe resumido de licencia",
    "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Resumen de licencia",
    "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Nombre del revendedor",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Ciclo de facturación",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Nombre del producto principal",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Nombre de la suscripción principal",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Nombre del proveedor",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"ID de inquilino",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Cantidad comprada",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Renovación – (En días)",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"se renueva el",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Fecha de caducidad",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Nombre",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"clave de cupón",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Consumo de licencia",
    "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Liberar asientos no utilizados",
    "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consumo de licencia",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Liberar asientos no utilizados",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Liberación de asientos no utilizados. Espere por favor...",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Asignado",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Productos mapeados",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Total",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre(s) de suscripción",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":" No usado",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Consumo de licencia",
    "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Consumo de Licencia",
    "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Asignado",
    "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Válido",
    "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
    "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Disponible",
    "LICENSES_TOOLTIP_MS_USER":"Licencias",
    "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":" Enlace al perfil de facturación de",
    "LINK_CUSTOMER_HEADER_TEXT":" Enlace al proveedor de",
    "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Estás vinculado a todos los proveedores presentes.",
    "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"tipo de cliente",
    "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"tipo de cliente",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Identificación del inquilino",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Nombre del inquilino",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Se vinculó exitosamente un cliente de Microsoft que no es CSP a C3.",
    "LINK_RESELLER_CAPTION_TEXT":"Revendedor de enlaces",
    "LINK_RESELLER_FORM_LABEL_PROVIDER":"Proveedor",
    "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"ID de revendedor (como en proveedor)",
    "LINK_RESELLER_LIST_BUTTON_DELETE":"Borrar",
    "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Comportamiento",
    "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Identificación del negocio",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Nombre del proveedor",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
    "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Estás vinculado a todos tus perfiles de proveedor.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Se ha eliminado el enlace a {{providerName}}.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Vinculado a {{providerName}} exitosamente.",
    "LINK_TEXT_CLICK_HERE":"haga clic aquí",
    "linkCustomerBillingProfile":"Enlace al perfil de facturación",
    "LINKED_PRODUCT_INFO_SHOPE":" Este producto está vinculado con",
    "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Seleccione",
    "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Enlace a un producto",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Validación de la disponibilidad del dominio",
    "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Categoría",
    "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Proveedor",
    "LOADING_INDICATOR_MESSAGE":" Procesando...",
    "LOADING_MESSAGE":"Cargando...",
    "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>ya se cargaron las promociones del mes actual</p> <p> ¿quieres recargar las promociones?</p>",
    "LOADPROMOTION_CATEGORY_TOOLTIP":"Elija la categoría de producto de la lista desplegable",
    "LOADPROMOTION_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
    "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Danos un momento para completar tu personificación como",
    "LOGGED_IN_GETTING_DETAILS_GREETINGS":" Saludos,",
    "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Ya has iniciado sesión. Danos un momento para preparar la aplicación para que puedas acceder...",
    "LOW":"Bajo",
    "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Aplicar X% en el margen de beneficio",
    "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Aplicar X% sobre el precio del socio",
    "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Aplicar X% en el margen de beneficio",
    "MACRO_COPY_PARTNERT_PRICE":"Copiar precio de socio",
    "MACRO_COPY_PROVIDER_SELLING_PRICE":"Copiar precio de venta del proveedor",
    "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"El precio de venta del producto del plan será la suma del precio del socio + X% sobre el margen entre el precio del socio y el precio de venta del proveedor.",
    "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será precio de socio + X% (precio de socio)",
    "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será el precio de venta del proveedor + X% (precio de venta del proveedor)",
    "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será igual al precio del socio.",
    "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"El precio de venta del producto del plan será igual al precio de venta del proveedor.",
    "MACRO_DET_EXAMPLE":"Ejemplo:",
    "MACRO_DET_PARTNER_PRICE":"Precio socio",
    "MACRO_DET_PERCENTAGE":"Porcentaje(X)",
    "MACRO_DET_PROVIDER_SELLING_PRICE":"Precio de venta del proveedor",
    "MACRO_DET_SALE_PRICE":"Precio de venta:",
    "MACRO_PERCENTAGE_TOOLTIP_TEXT":"% de macros",
    "MAIL_SENT_SUCCESSFULY":"El correo enviado con éxito",
    "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
    "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Considerar en liberación automática",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"No",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Sí",
    "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Se considera en liberación automática",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Considerado en el lanzamiento automático.",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Gestionable exclusivamente por el socio",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Su solicitud de asignación de licencias está en cola. Visite esta página después de un tiempo para obtener actualizaciones.",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Introduzca direcciones de correo electrónico válidas de los usuarios del inquilino.",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"El usuario {{username}} ya tiene una licencia para {{subscriptionname}}",
    "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Por favor seleccione al menos un archivo",
    "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"¿Está seguro de que desea ignorar los errores?",
    "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"El número mínimo de puestos para una suscripción debe ser 1. No se pueden liberar puestos si el número de puestos disponibles es 1.",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"¿Está seguro de que desea liberar los asientos no utilizados?",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Licencias no utilizadas liberadas exitosamente",
    "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Agregue al menos un usuario al grupo Licencias para eliminar",
    "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Seleccione el usuario de la lista sugerida antes de hacer clic en el botón Agregar",
    "MANAGE_LICENSES_UPDATE_SUCCESS":"La asignación de licencia se completó con éxito",
    "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Se produjo un error al procesar el archivo, inténtalo de nuevo.",
    "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Detalles de la licencia cargados correctamente. Vuelva a esta pantalla después de un tiempo para verificar el estado.",
    "MANAGE_NAME_CHANGE_ERROR":"El nombre no puede estar vacío.",
    "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
    "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Ver Ofertas)",
    "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Editar macro",
    "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Los productos no se pueden vincular a esta oferta.",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Mostrar sólo promoción",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Sí",
    "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Escribe un nombre",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Copie el precio de lista al precio de venta: el precio de venta será el mismo que el precio de lista",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"Agregue X% al precio de lista: el precio de venta será el X% del precio de lista + precio de lista",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Aumentar el precio en Y centavos: el precio de venta será Precio de lista + Y centavos",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Borrar todas las ofertas seleccionadas precio",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"Agregue X% al margen: el precio de venta será el precio de lista + (ERPPrice-Precio de lista)*X%",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":" Agregue X% al precio de MS",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":" el precio de venta será el X% del precio MS + precio MS",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":" Copie el precio de MS al precio de venta.",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":" El precio de venta será el mismo que el precio de MS.",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Agregue X% al margen",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":" el precio de venta será Precio MS + (Precio ERP-Precio MS)*X%",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Aumentar el precio en Y centavos",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":" el precio de venta será MS Price + Y centavos",
    "MANAGE_PLAN_LBL_1":"Copie el precio de lista al precio de venta",
    "MANAGE_PLAN_LBL_2":"Añadir X% al precio de lista",
    "MANAGE_PLAN_LBL_3":"Aumentar el precio en Y centavos",
    "MANAGE_PLAN_LBL_4":"Borrar todos los precios",
    "MANAGE_PLAN_LBL_5":"Agregue X% al margen",
    "MANAGE_PLAN_LBL_6":"Copie el precio del ERP al precio de venta",
    "MANAGE_PLAN_MACRO_APPLIED":"Macro aplicada",
    "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"La macro recién seleccionada se aplicará a las ofertas que se agreguen al plan en el futuro y no afectará la macro existente en las ofertas que ya se hayan agregado.",
    "MANAGE_PLAN_NO_MACRO_APPLIED":"No se aplicó ninguna macro",
    "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"No disponible para compra",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Cerca",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Ofertas incluidas",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Cerca",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Nombre",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Nombres de los padres",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"¿Estás seguro de que deseas eliminar todos los complementos?",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"¿Estás seguro de que deseas eliminar este producto?",
    "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}}</b> es una oferta principal para un producto de prueba. <br/> Eliminar la oferta premium también resultará en la eliminación de la oferta de prueba asociada.<br/> ¿Está seguro de que desea eliminar <b>{{productName}}<b/>?",
    "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Se agregan complementos al plan; guarde el plan para finalizar la operación.",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":" agregado al plan!",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":" agregado al plan con complementos",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" agregado al plan con una oferta para padres",
    "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"No se encontraron complementos coincidentes",
    "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"¿Quieres eliminar la oferta?",
    "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"El complemento que estás intentando agregar ya está incluido",
    "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Tipos de consumo",
    "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"Es oferta de sendero",
    "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Estado de suscripción",
    "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Mercados admitidos",
    "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Consulte las siguientes ofertas ya que el precio de venta es inferior al precio de lista.",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Haga clic en \"Aceptar\" para enviar los cambios del plan. 'CANCELAR' para volver a verificar las ofertas del plan.",
    "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"La oferta seleccionada ya estaba incluida.",
    "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Proporcione un nombre descriptivo y un precio efectivo para las ofertas incluidas en el plan.",
    "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Consulte las siguientes ofertas ya que el precio de venta es mayor que el precio ERP.",
    "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Por favor agregue ofertas al plan",
    "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Agregar más complementos",
    "MANAGE_PONUMBER_CHANGE_ERROR":"El número PON no puede estar vacío",
    "MANAGE_PRODUCT_OWNERSHIP":"Gestionar la propiedad del producto",
    "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Error en la gestión de propiedad del producto",
    "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"No se permite administrar la propiedad del producto ya que la asignación de licencia está en curso para uno de los productos o sus complementos. Por favor intente mas tarde.",
    "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Se agregaron nuevos cupones con éxito",
    "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Se produjo un error al procesar su solicitud",
    "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Precios claros",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Copie el precio de MS al precio de revendedor: el precio de revendedor será el mismo que el precio de MS",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"Agregue X% al precio de MS: el precio de revendedor será el X% del precio de MS + precio de MS",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Aumentar el precio en Y centavos: el precio de revendedor será MS Price + Y centavos",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Borrar todas las ofertas seleccionadas precio",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"Agregue un X% al margen de beneficio: el precio de revendedor será Precio MS + (Precio ERP-Precio MS)*X%",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Si desea agregar las ofertas de revendedor, debe seguir las instrucciones a continuación",
    "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Artículos seleccionados",
    "MANAGE_RELLER_OFFERS_LBL_1":"Copie el precio de MS al precio de revendedor",
    "MANAGE_RELLER_OFFERS_LBL_2":" Agregue X% al precio de MS",
    "MANAGE_RELLER_OFFERS_LBL_3":"Aumentar el precio en Y centavos",
    "MANAGE_RELLER_OFFERS_LBL_4":"Borrar todos los precios",
    "MANAGE_RELLER_OFFERS_LBL_5":"Agregue X% al margen",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"¿Quiere eliminar todas las ofertas filtradas de las ofertas seleccionadas?",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"¿Quieres eliminar todas las ofertas seleccionadas?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"¿Quieres seleccionar todas las ofertas filtradas?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"¿Quieres seleccionar todas las ofertas?",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Válida desde",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"precio del ERP",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Tipo de licencia",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Precio de lista",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Precio de revendedor",
    "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Si desea reducir la cantidad, comuníquese con el equipo de soporte.",
    "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Volver a renovaciones programadas",
    "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Cancelar",
    "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Crear nuevo horario",
    "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"Entregar",
    "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Cantidad acumulada",
    "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Suscripción actual",
    "MANAGE_RENEWAL_DESCRIPTION_1":"Los cambios entran en vigor en la fecha de renovación de la suscripción: <b>{{renewalDate}}</b>. Para las actualizaciones de SKU, si la cantidad no cambia, las licencias se asignarán automáticamente. De lo contrario, deberá asignar licencias manualmente.",
    "MANAGE_RENEWAL_HEADER_TEXT":"Gestionar renovación",
    "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Actual: {{currentEnddate}}",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Elija entre diferentes SKU:",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Elija un ciclo de facturación diferente:",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Elija un término diferente:",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Fecha final",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Alineación de fecha de finalización",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Tipo de fecha de finalización",
    "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Planificar producto",
    "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Cantidad comprada",
    "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Elija un producto de plan elegible para su suscripción (use esta opción si desea cambiar el SKU/ciclo de facturación/Término junto con la programación de los cambios)",
    "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Término",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Producto del plan previamente seleccionado para renovación programada para cambio de SKU/Plazo/Ciclo de facturación:",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Nombre del producto del plan",
    "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Puede cancelar su suscripción con una suscripción existente de OnlineServicesNCE que no sea de prueba o alinear la fecha de finalización con el mes calendario eligiendo una fecha de finalización adecuada según la duración del plazo.",
    "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Calendario de actualización",
    "MANAGE_RESELLER_BACK_TO_RESELLERS":"Volver a revendedores",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Ofertas",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"revendedores",
    "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Agregar ofertas de revendedores",
    "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Ofertas",
    "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Proporcione el precio efectivo para las ofertas seleccionadas.",
    "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Ofertas guardadas exitosamente",
    "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Por favor agregue ofertas al revendedor",
    "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Todas las ofertas seleccionadas. Si desea editar algo relacionado con cualquier oferta de plan, ¡debe pasar por el plan de edición!",
    "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Lista de planes de revendedor",
    "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Estado del plan actualizado correctamente",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Asignar",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Desactivar",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Permitir",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Nombre",
    "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Mercados admitidos",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Expira el",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Renovación automática activada",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"identificación de producto",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Si la renovación automática está desactivada, esta suscripción caduca el",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":" Si la renovación automática está desactivada, esta suscripción caduca al final del período de vigencia",
    "manageplans":"Administrar planes",
    "MANAGERG_REMOVE_OFFERS_PROMPT":"¿Quiere eliminar el grupo de recursos?",
    "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Solicitar al Cliente que actualice los Detalles de pago.",
    "MANUAL_SYNC_PUPUP_TITLE":"Sincronización manual",
    "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Aplicó correctamente su solución",
    "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Seleccione un acuerdo PSA",
    "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Por favor seleccione un contrato",
    "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Por favor seleccione un Cliente",
    "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Por favor seleccione un cliente de PSA",
    "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Seleccione un producto PSA",
    "MARCH":"Marzo",
    "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar esta dirección como predeterminada?",
    "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar este correo electrónico como predeterminado?",
    "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"¿Está seguro de que desea marcar este número de teléfono como predeterminado?",
    "Markup":"Margen",
    "MATCHING_PRODUCT_PLAN_TEXT":"Plan:",
    "MAY":"Puede",
    "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Creación de pagos recurrentes. Es posible que vea un cargo de $ 1.00 en su tarjeta que se ajustará como crédito en su próxima factura.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"El banco del cliente (emisor de la tarjeta) ha rechazado la transacción. El cliente debe comunicarse con su banco y volver a intentar la transacción.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"El banco del cliente ha rechazado la transacción y solicita al comerciante que se comunique con el banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"La transacción fue exitosa.",
    "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"La transacción fue exitosa.",
    "MCB_ERROR_CODE_APPROVED_VIP":"La transacción fue exitosa. (Este código no se usa en Australia).",
    "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que no permite transacciones que se originen a través de correo/teléfono, fax, correo electrónico o pedidos por Internet. Este error está asociado a clientes que intentan utilizar una tarjeta Discover. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"El banco del cliente ha rechazado la transacción y solicita al cliente que se comunique con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_CAPTURE_CARD":"El banco del cliente ha rechazado la transacción porque se sospecha que la tarjeta es falsa. El banco del cliente (Emisor de la tarjeta) ha solicitado que se retenga la tarjeta de crédito de su cliente. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción y ha solicitado que se retenga la tarjeta de crédito del cliente (tarjeta reportada como extraviada o robada). El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"No se puede contactar con el banco del cliente para autorizar la transacción. El cliente debe intentar procesar esta transacción nuevamente.",
    "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"El banco del cliente no puede procesar temporalmente la tarjeta de crédito de este cliente. El cliente debe intentar procesar esta transacción nuevamente.",
    "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"El banco del cliente ha rechazado la transacción porque el CVV es incorrecto. El cliente debe verificar los detalles del CVV y volver a intentarlo. Si no tiene éxito, el cliente debe usar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_DO_NOT_HONOUR":"El banco del cliente ha rechazado la transacción porque el número de la tarjeta de crédito no ha superado una comprobación de seguridad o los fondos se han congelado o agotado. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"El banco del cliente ha rechazado la transacción porque parece ser una transmisión duplicada. No se requiere accion.",
    "MCB_ERROR_CODE_ERROR":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"El banco del cliente ha rechazado la transacción porque el cliente ha excedido el límite de frecuencia de retiro. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"El banco del cliente ha rechazado la transacción porque excederá el límite de la tarjeta del cliente. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_EXPIRED_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito parece haber caducado. El cliente debe verificar la fecha de vencimiento ingresada e intentar nuevamente, o usar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito ha caducado o la fecha es incorrecta. Verifique la fecha de vencimiento en la transacción e intente procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_FORMAT_ERROR":"El banco del cliente (Emisor de la tarjeta) no reconoce los detalles de la transacción. El cliente debe verificar la información de la transacción e intentar procesar la transacción nuevamente. Generalmente debido a un TID incorrecto.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"El banco del Cliente ha rechazado la transacción ya que esta tarjeta de crédito no se puede utilizar para este tipo de transacción. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"El banco del Cliente ha rechazado la transacción ya que esta tarjeta de crédito no se puede utilizar para este tipo de transacción. Esto puede estar asociado con un número de tarjeta de crédito de prueba. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que no permite este tipo de transacciones. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"La transacción fue exitosa. Algunos bancos usan este código en lugar de '00'.",
    "MCB_ERROR_CODE_INCORRECT_PIN":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción porque el cliente ingresó un PIN incorrecto. El cliente debe volver a ingresar su PIN; de lo contrario, use una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta de crédito no tiene fondos suficientes. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_INVALID_AMOUNT":"Es posible que se esté pasando a la puerta de enlace un carácter no válido (por ejemplo, un signo de dólar o un espacio). Verifique el código de su sitio web.",
    "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el número de la Tarjeta de crédito no existe. Verifique la información de la tarjeta de crédito e intente procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_INVALID_TRANSACTION":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción debido a un formato o campo no válido. Verifique la información de la transacción e intente procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_LOST_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta ha sido reportada como extraviada. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_CARD_RECORD":"El banco del Cliente ha rechazado la transacción porque el número de tarjeta de crédito no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que el número de la tarjeta de crédito está asociado a una cuenta corriente que no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"El banco del cliente ha rechazado la transacción porque el número de tarjeta de crédito utilizado no es una cuenta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el tipo de cuenta seleccionado no es válido para este número de tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_ISSUER":"El banco del cliente (Emisor de la tarjeta) no existe. Verifique la información de la tarjeta de crédito e intente procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_NO_MERCHANT":"La identificación del comerciante no es válida, debe comunicarse con su banco y asegurarse de haber proporcionado el número de cuenta del comerciante correcto.",
    "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción ya que el número de tarjeta de crédito está asociado a una cuenta de ahorros que no existe. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque el tipo de cuenta seleccionado no es válido para este número de tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"El banco del cliente ha rechazado la transacción debido al monto que se intenta procesar. El cliente debe verificar el monto de la transacción y volver a intentarlo.",
    "MCB_ERROR_CODE_PICK_UP_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada). El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito de su cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"El banco del cliente rechazó la transacción porque el cliente ingresó el PIN incorrecto más de tres veces. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción porque el cliente ingresó el PIN incorrecto tres veces. El banco del cliente (Emisor de la tarjeta) le ha solicitado que conserve la tarjeta de crédito. El cliente debe utilizar una tarjeta de crédito alternativa y ponerse en contacto con su banco.",
    "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"La transacción no ha sido procesada y el cliente debe intentar procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"El banco del cliente (Emisor de la tarjeta) ha indicado que hay un problema con el número de la tarjeta de crédito. El cliente debe ponerse en contacto con su banco. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_RESTRICTED_CARD":"El banco del cliente ha rechazado la transacción porque la tarjeta de crédito tiene algunas restricciones. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"El banco del cliente (Emisor de la tarjeta) rechazó la transacción y solicitó que se retuviera la tarjeta de crédito del cliente. (tarjeta reportada perdida o robada) El cliente debe usar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_SECURITY_VIOLATION":"El banco del cliente ha rechazado la transacción. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_STOLEN_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción porque la tarjeta ha sido denunciada como robada. El cliente debe utilizar una tarjeta de crédito alternativa.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD":"El banco del cliente ha rechazado esta transacción porque la tarjeta de crédito parece ser fraudulenta.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"El banco del cliente (Emisor de la tarjeta) ha rechazado la transacción debido a que existe una sospecha de fraude en este número de tarjeta de crédito.",
    "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"No se puede contactar al banco del cliente (Emisor de la tarjeta) durante la transacción. El cliente debe verificar la información de la tarjeta de crédito e intentar procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_SYSTEM_ERROR":"El banco del cliente no pudo procesar la transacción. El cliente debe intentar procesar esta transacción nuevamente.",
    "MCB_ERROR_CODE_TRANSACTION_APPROVED":"La transacción fue exitosa.",
    "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"El banco del cliente (Emisor de la tarjeta) no reconoce los datos de la tarjeta de crédito. El cliente debe verificar la información de la tarjeta de crédito e intentar procesar la transacción nuevamente.",
    "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"No se puede encontrar el banco del cliente para el enrutamiento. Este código de respuesta suele devolverse cuando el cliente utiliza un número de tarjeta de crédito de prueba. El cliente debe intentar procesar esta transacción nuevamente.",
    "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Se ha producido un error no especificado.",
    "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Número de tarjeta inválido o faltante.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Falta el mes de caducidad o no es válido.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Falta el año de caducidad o no es válido.",
    "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Código de seguridad no válido.",
    "MEDIUM":"Medio",
    "MENU_ACCOUNT_MANAGERS":"Gerente de Cuentas",
    "MENU_ADMINISTRATION":"Administración",
    "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notificaciones personalizadas",
    "MENU_ADMINISTRATION_ENGAGE":"Participar <sup class='text-warning'> Beta</sup>",
    "MENU_ADMINISTRATION_MANAGE":"Notificaciones de pancartas",
    "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Notificaciones de pancartas",
    "MENU_ADMINISTRATION_MANAGE_HEADING":"Administración",
    "MENU_ADMINISTRATION_NOTIFICATIONS":"Notificaciones",
    "MENU_ADMINISTRATION_WEBHOOK":"gancho web",
    "MENU_AZURE_ADVISOR":"Asesor de Azure",
    "MENU_AZURE_BILLING_REPORT":"Informe de facturación de Azure",
    "MENU_AZURE_CUSTOM_POLICY":"Políticas personalizadas",
    "MENU_AZURE_CUSTOM_ROLE":"Roles personalizados",
    "MENU_AZURE_MARGIN":"Soporte C3",
    "MENU_AZURE_SUBACRIPTION":"Suscripción Azure",
    "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"microsoft",
    "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Gerente de Cuentas",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Comisiones",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Atributos del producto",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Citas",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Detalles de las cotizaciones",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Lista de citas",
    "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Informe de uso",
    "MENU_CONTACT_LOG":"Notificaciones",
    "MENU_COST_SUMMARY_REPORT":"Informe resumido de costos",
    "MENU_CUSTOMER_ANALYTICS":"Análisis de clientes",
    "MENU_CUSTOMER_COMMENTS":"Comentarios",
    "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consumo de Licencia",
    "MENU_CUSTOMER_MICROSOFT_REPORT":"Uso de Azure",
    "MENU_CUSTOMER_PROFILE":"Perfil",
    "MENU_DASHBOARD":"Panel",
    "MENU_EXISTING_ACCOUNT_MANAGERS":"Gerentes existentes",
    "MENU_HELP":" Ayuda",
    "MENU_LICENSE_CHANGE":"Informe de cambio de licencia",
    "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Informe resumido del consumo de licencias",
    "MENU_LICENSE_SUMMARY_REPORT":"Informe resumido de licencia",
    "MENU_MANAGE_AZURE_ENTITLEMENTS":"Administrar derechos de Azure",
    "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Aprovisionamiento de ofertas de socios",
    "MENU_ONBOARDED_CUSTOMERS_REPORT":"Análisis de incorporación",
    "MENU_PARTNER_AUDIT_LOG":"Registro de auditoría",
    "MENU_PARTNER_COMMISSION_REPORT":"Informe de la Comisión",
    "MENU_PARTNER_COUPON":"Cupones",
    "MENU_PARTNER_DOCUMENTATION":"Documentación",
    "MENU_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
    "MENU_PARTNER_SUPPORT_LOG":"Apoyo",
    "MENU_PARTNER_UPLOAD":"Subir",
    "MENU_PENDING_PURCHASE_REQUESTS":"Aprobaciones",
    "MENU_PRODUCT_COMMISSIONS":"Comisiones",
    "MENU_PRODUCT_EXTENSIONS":"Atributos del producto",
    "MENU_PRODUCT_SEQUENCE":"Secuencia de productos",
    "MENU_PURCHSED_PRODUCT_MAPPING":"Mapeo de productos comprados",
    "MENU_RENEWAL_MANAGER":"Gerente de Renovación",
    "MENU_REPORT_USAGE":"Uso",
    "MENU_REPORTS_FOR_CUSTOMER":"Informes",
    "MENU_RESELLER_PLAN":"Plan de revendedor",
    "MENU_RESELLER_PROFILE":"Perfil de la organización",
    "MENU_SCHEDULED_REPORTS":"Informes programados",
    "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Destinatarios de",
    "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Ejecutar historial para",
    "MENU_TOPBAR_SETTINGS":"Ajustes",
    "MENU_USAGE_REPORT":"Informe de uso de Azure",
    "MENU_USERS":"Usuarios del portal",
    "MENUS_ANALYTICS":"Analítica",
    "MENUS_ANALYZE":"Analizar",
    "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Administrar los precios de los niveles de derechos de Azure",
    "MENUS_AZURE_ESTIMATE":"Estimación de Azure",
    "MENUS_AZURE_GROUPS":"Grupos de Azure",
    "MENUS_AZURE_MANGE":" Gestión de Azure",
    "MENUS_AZURE_REPORTS":"Informes de Azure",
    "MENUS_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
    "MENUS_CART":"Carro",
    "MENUS_COUPONS":"Cupones",
    "MENUS_CUSTOM_OFFERS":"Ofertas de socios",
    "MENUS_CUSTOMER_CART":"Carro",
    "MENUS_CUSTOMER_DASHBOARD":"Panel",
    "MENUS_CUSTOMER_MICROSOFT":"microsoft",
    "MENUS_CUSTOMER_MICROSOFT_USERS":"Usuarios",
    "MENUS_CUSTOMER_ONBOARDING":"Incorporación de clientes",
    "MENUS_CUSTOMER_ORDERS":"Pedidos",
    "MENUS_CUSTOMER_PRODUCTS":"Productos",
    "MENUS_CUSTOMER_PROFILE":"Perfil",
    "MENUS_CUSTOMER_SHOP":" Comercio",
    "MENUS_DAAS":"DaaS<sup class='text-danger'>BETA</sup>",
    "MENUS_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
    "MENUS_DOCUMENTATION":"Documentación<sup class='text-danger'>BETA</sup>",
    "MENUS_ENTITY_INVOICES":"Facturas",
    "MENUS_FAQ":"Preguntas más frecuentes",
    "MENUS_INVITES":"invita",
    "MENUS_INVOICES":"Facturas",
    "MENUS_LICENSE_CONSUMPTION":"Consumo de Licencia",
    "MENUS_LICENSE_LIST":"Lista de licencias",
    "MENUS_MS_OFFERS":"Ofertas de EM",
    "MENUS_OPERATING_ENTITIES":"Entidades Operadoras",
    "MENUS_ORDERS":"Pedidos",
    "MENUS_PARTNER_BUNDLES":"manojos",
    "MENUS_PARTNER_CUSTOMERS":"Clientes",
    "MENUS_PARTNER_DASHBOARD":"Panel",
    "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Estado de pago pendiente",
    "MENUS_PARTNER_PLANS":"planes",
    "MENUS_PARTNER_QUOTES":"Cita",
    "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Negocio",
    "MENUS_PARTNER_SETTINGS":"Ajustes",
    "MENUS_POWER_BI_REPORTS":"Uso de Azure",
    "MENUS_PRODUCTS":"Productos",
    "MENUS_PROFILE":"Perfil",
    "MENUS_RESELLER_DASHBOARD":"Panel",
    "MENUS_RESELLER_INVOICES":"Facturación",
    "MENUS_RESELLER_PAYMENTS":"Pagos",
    "MENUS_RESELLER_PLANS":"planes",
    "MENUS_RESELLER_PRICE_AND_OFFERS":"Precios y ofertas",
    "MENUS_RESELLER_PROFILE":"Perfil de la organización",
    "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Negocio",
    "MENUS_RESELLER_SETTINGS":"Ajustes",
    "MENUS_RESELLERS":"revendedores",
    "MENUS_SELL":"Vender",
    "MENUS_SELL_DIRECT":"Vender Directo",
    "MENUS_SELL_INDIRECT":"Vender Indirecto",
    "MENUS_SELLER_INDIRECT":"Vender Indirecto",
    "MENUS_SHOP":" Comercio",
    "MENUS_SUBSCRIPTIONS":"Suscripciones",
    "MENUS_SUPPORT":"Apoyo",
    "MENUS_TAXES":"Impuestos",
    "MENUS_USER_MANAGEMENT":"Gestión de usuarios",
    "merge":"rama",
    "MESSAGE_ALREADY_EXISTING":"El mensaje ya existe para las entidades relevantes",
    "MESSAGE_BODY":"Cuerpo del mensaje",
    "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Por favor verifique todos los campos requeridos e intente enviar nuevamente",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Para la entidad Variante de producto/Cliente, no se puede volver a insertar la misma combinación.",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Por favor seleccione todos los campos",
    "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND":"<div ng-include=\"'app/templates/no-data-message.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND_USAGE":"No hay datos para presentar debido a que no se ha utilizado durante el período de facturación actual o porque la factura de Microsoft aún no está disponible. Vuelva a consultar en breve.",
    "MESSAGE_NOTIFCATION_SAVED":"Notificación de mensaje guardada con éxito",
    "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Resuelva todos los errores de validación e intente enviar de nuevo",
    "MESSAGE_NOTIFICATION_EDIT_MODE":"Editar notificaciones de mensajes",
    "MESSAGE_NOTIFICATION_EDIT_TEXT":"Editar",
    "MESSAGE_NOTIFICATION_ID":"IDENTIFICACIÓN",
    "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Seleccione al menos un destinatario para la notificación del mensaje",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Comportamiento",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Fecha efectiva",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Expira el",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Cuerpo del mensaje",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Estilo de mensaje",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Nombre de la página",
    "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Entidades involucradas",
    "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Se ha seleccionado un producto.",
    "MESSAGE_RECORDS_POP_UP":"lista de registros",
    "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Se ha producido un error",
    "MESSAGE_TEXT_NO_RECORDS_FOUND":"¡No se encontraron registros!",
    "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Lo sentimos, no tienes sitios ni departamentos configurados. Puede agregar nuevas entidades operativas desde la página de perfil",
    "MESSAGE_TEXT_PLEASE_WAIT":"Espere por favor..",
    "MESSAGE_TOOLTIP_AZUREGROUP":"Información, Advertencia, Error, Peligro",
    "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Seleccione tipo de mensaje",
    "MESSAGE_TYPE_NAME":"Nombre del tipo de mensaje",
    "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Por favor seleccione todos los campos",
    "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"URL invalida. Por favor introduzca un URL válido.",
    "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Por favor verifique todos los campos requeridos e intente enviar nuevamente",
    "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"¡La URL es válida!",
    "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" No se permiten valores decimales.",
    "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Nuevo nivel",
    "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Introduzca un número válido.",
    "METERED_BILLING_TEXT_MODAL_HEADER":"Niveles de uso",
    "Microsoft":"microsoft",
    "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Tipo de atestación:",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Remover",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Categoría",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Descuento",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Complementos",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Remover",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Categoría",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Descuento",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Margen",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Porcentaje",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Complementos",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Producto vinculado",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Aceptado por",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA aceptado el",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Estado de aceptación",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"No provisto",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Proporcionó",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Aceptación del cliente confirmada por el socio",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Aceptación del cliente a través de microsoft.",
    "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"El acuerdo de cliente de Microsoft se guardó correctamente.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Contraseña temporal",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nombre de usuario",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"El usuario se ha creado con éxito y debajo están las credenciales del usuario.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Detalles de usuario",
    "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Después de cerrar esta ventana emergente, no podrá volver a ver esta contraseña temporal.",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"El consentimiento de la API de precios de Microsoft capturado falló",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Consentimiento de la API de precios de Microsoft capturado con éxito",
    "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"El intento de acceder a la API de precios de Microsoft es exitoso.",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} de {{applicationName}} desea ser su proveedor de soluciones de nube de Microsoft para su cuenta de nube de Microsoft.<br/><br/>Para aceptar la invitación, siga los 2 pasos a continuación:<br/><br/>Paso 1: Verifique y asegúrese de que el perfil del cliente esté completo aquí: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Paso 2: Una vez que el perfil del cliente esté completo (puede demorar hasta 5 minutos en actualizarse después de que se actualice el perfil), haga clic en el siguiente vínculo para aceptar esta invitación y autorizar a {{applicationName}} a ser su proveedor de soluciones de nube de Microsoft y aceptar la invitación de cliente de Microsoft. Acuerdo.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Nota: Se requiere un usuario con permiso de administrador global para aceptar la relación.<br/><br/><br/>Información adicional del socio:<br/>{{contactEmail}}<br/>{{contactNumber}}",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Estimado cliente:<br/><br/>Para brindarle una gama completa de soluciones, servicios y soporte en la nube, {{resellerName}} trabaja en estrecha colaboración con {{partnerCompanyName}}. Para autorizar a {{resellerName}} y {{partnerCompanyName}} a brindar servicio a su cuenta, realice los siguientes 2 pasos: <br/><br/>Paso 1: Verifique y asegúrese de que el perfil del cliente esté completo aquí: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Paso 2: Una vez que el perfil del cliente esté completo (puede demorar hasta 5 minutos en actualizarse después de que se actualice el perfil), haga clic en el siguiente vínculo para aceptarlo invitación.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Nota: Se requiere un usuario con permiso de administrador global para aceptar la relación.<br/><br/>{{resellerName}} seguirá siendo su experto local de referencia para productos, suscripciones, licencias y soporte. Tenga la seguridad de que su relación comercial con usted sigue siendo la misma.<br/><br/>No dude en comunicarse con nosotros si tiene preguntas. ¡Nosotros y {{resellerName}} esperamos trabajar con usted!<br/><br/>Atentamente,<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
    "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de software",
    "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de terceros",
    "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
    "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de terceros.",
    "MICROSOFT_USERS_DELETE_CONFIRM":"Se eliminarán todas las licencias existentes y el acceso al portal. Estás seguro de que quieres continuar?",
    "MicrosoftNonCSP":"Microsoft no CSP",
    "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Cuenta de migración creada exitosamente con el nombre de usuario:",
    "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Cuentas de migración",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Cliente",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Cuenta de migración",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Crear",
    "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Cuentas de migración",
    "MIGRATION_USER_CREATE_SUCCESS":"Cuenta de migración creada correctamente con {{username}}",
    "MINUTES":" Minutos)",
    "MISSING_PLAN_OFFER_ADD_FAILED":"La conciliación falló; comuníquese con el soporte para obtener ayuda.",
    "MISSING_PLAN_OFFER_ADDED":"Conciliación completada usando la oferta elegida",
    "MNUAL_SYNC_PURCHASED_DATE":" Fecha de compra:",
    "MODAL_HEADER_TEXT_DESC_FILTER":"Filtrar",
    "Month(s)":"Meses)",
    "MULTIPLE_COMMENT_STATIC":"Comentarios",
    "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Seleccione",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"¡Importación completa!<br />Total: {{ importProcessedCount }}<br />Errores: {{ importErrorCount }}<br />Éxito: {{ importSuccessCount }}",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"¡Validación completada!<br />Total: {{ validationProcessedCount }}<br />Errores: {{ validationErrorCount }}<br />Éxito: {{ validationSuccessCount }}",
    "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Instrucciones",
    "Name":"Nombre",
    "NAME":"Nombre de la entidad",
    "NCE_ACRONYM":"Servicios en línea NCE",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Política de cancelación",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 horas: Reembolso completo",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 horas: 1er día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 horas: 2do día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 horas: 3er día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 horas: 4to día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 horas: 5to día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 horas: 6to día prorrateado",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Después de 7 días naturales: No se permite la cancelación",
    "NCE_INSTRUCTIONS_HEADING":"Instrucciones NCE",
    "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Precio de venta estimado del producto vinculado",
    "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>El siguiente producto no es elegible para la promoción. Póngase en contacto con soporte para aplicar la promoción. si no desea la promoción, continúe con el proceso de pedido.</p>",
    "NCE_PROMOTIONS_APPLIED":"Esta promoción se aplica",
    "NCE_PROMOTIONS_AVAILABLE_TEXT":"Promoción disponible",
    "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Verifique la elegibilidad de la promoción",
    "NCE_PROMOTIONS_NOT_APPLIED":"La promoción aún no se aplica",
    "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"promociones disponibles",
    "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Frecuencia de facturación",
    "NCE_PROMOTIONS_POPUP_CANCEL":"Cancelar",
    "NCE_PROMOTIONS_POPUP_CLOSE":"Cerca",
    "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"La elegibilidad del cliente se validará al revisar el carrito",
    "NCE_PROMOTIONS_POPUP_DISCOUNT":"Descuento",
    "NCE_PROMOTIONS_POPUP_END_DATE":"Fecha final",
    "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Descuento promocional lanzamiento nuevo comercio",
    "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Detalles de la promoción",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":" IDENTIFICACIÓN:",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":" Nombre:",
    "NCE_PROMOTIONS_POPUP_SUBMIT":"Aplicar",
    "NCE_PROMOTIONS_POPUP_TERM":"Término",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"La cantidad/asientos se puede aumentar en cualquier momento.",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"La cantidad/asientos se puede disminuir dentro de los 7 días calendario, a su cantidad/asientos anteriores oa la suma de los anteriores.",
    "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Cambio de cantidad",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Cambio de renovación de horario",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"El programa actual se eliminará si se cambia Cantidad/Estado de renovación automática/Ciclo de facturación/Término.",
    "NCE_SKU_NOT_PRESENT_IN_PLAN":"Producto no disponible en Plan Cliente",
    "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Por favor, acepte todos los términos y condiciones.",
    "NCE_TEXT":"nuevo comercio...",
    "NEW_BILLING_CYCLE":"Nuevo ciclo de facturación",
    "NEW_COMMENT_SEND":"Enviar",
    "NEW_COMMENTS_RECIEVED":"Nuevo comentario recibido",
    "NEW_CUSTOMER_ONBOARDING_ERRORS":"los productos de compra/actualización pueden fallar",
    "NEW_DISTRIBUTOR_PRODUCT_ICON":"Nuevo icono",
    "NEW_PARTNER_PRODUCT_ICON":"Nuevo icono",
    "NewPrice":"Nuevo precio",
    "NEXT_SCHEDULE_RENEWAL_CHANGE":"Próximo cambio de renovación de horario",
    "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Buscar ...",
    "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"No existe ningún artículo con ID: {cartLineItemId} en el carrito",
    "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"No hay ningún administrador de clientes disponible para este inquilino.",
    "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"No hay clientes registrados",
    "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Ningún plan existente",
    "NO_PAYMENT_TYPES_ENABLED":"No hay tipos de pago habilitados. Por favor contacte al soporte.",
    "NO_PLAN_WITH_OFFER_AVAILABLE":"No hay ningún plan con todas las ofertas disponibles. Por favor crea uno",
    "NO_PRODUCT_SELECTED_PROMPT":"Por favor seleccione mínimo un producto.",
    "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"No se encontraron detalles de factura para el período de facturación o proveedor seleccionado",
    "NO_RESOURCE_GROUP_LEFT":"¡No queda ningún grupo de recursos para asignar/No se encontraron registros!",
    "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Detalles del producto sin CSP",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"ID de entidad de servicio",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Clave principal de servicio",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Identificación de suscripción",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"No tiene permiso para realizar este cambio; comuníquese con su socio para obtener más información.",
    "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Los siguientes productos del proveedor no están asignados a los productos del plan. Si desea continuar, haga clic en continuar; de lo contrario, haga clic en cancelar y asigne estos productos.",
    "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Productos del proveedor",
    "NOT_VALID_MESSAGE":" no es un correo valido",
    "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Crear notificación de banner",
    "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"Etiqueta de cliente eliminada exitosamente",
    "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"El nombre de la suscripción cambió con éxito",
    "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Este valor se actualizará en breve.",
    "notifications":"Notificación",
    "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"La longitud de los caracteres debe estar entre 2 y 200 caracteres",
    "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"La longitud de los caracteres debe estar entre 2 y 50 caracteres.",
    "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Fecha de finalización personalizada guardada correctamente",
    "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"URL de webhook no válida",
    "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"URL de webhook válida",
    "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Ingrese las direcciones de correo electrónico separadas por coma (,) o punto y coma (;) y haga clic en Aceptar para recibir un archivo zip que contiene una lista de facturas en formato PDF para el período de facturación seleccionado.",
    "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"En breve se enviará a {{emailsList}} un correo electrónico con una lista de archivos PDF (Zip) de facturas como archivo adjunto.",
    "NOVEMBER":"Noviembre",
    "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"Los clientes no pudieron incorporarse",
    "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"Clientes incorporados con éxito",
    "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"Clientes seleccionados en lote",
    "NUMBER_OF_PROMOTIONS_LOADED":"Número de promociones cargadas",
    "OCTOBER":"Octubre",
    "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"El nombre descriptivo de la oferta {{FriendlyName}} debe ser único",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Mostrar ofertas con suscripción comprada",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"ofertaConCompradoSuscripción",
    "offers":"Ofertas",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Panel",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Suscripciones",
    "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Volver a Suscripciones",
    "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Agregar suscripciones",
    "OFFERS_HEADER_TEXT_OFFERS":"Ofertas",
    "OFFERS_NO_MATCH_MESSAGE":" No se encontraron resultados para Nombre :",
    "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Artículos seleccionados",
    "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Porcentaje de descuento",
    "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Cantidad (Nº de licencias)",
    "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Tarifa por asiento (Por mes)",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Total",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Cantidad total",
    "OLD_BILLING_CYCLE":"Antiguo ciclo de facturación",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Error al incorporar a un cliente mediante la incorporación masiva",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Incorporación de un cliente mediante incorporación masiva iniciada",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Incorporación de un cliente con éxito en la incorporación masiva",
    "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Encontré una coincidencia en el ID: <strong> {{billingProviderReferenceID}} </strong> en el sistema de facturación.<br/><span>La dirección de facturación registrada es <strong> {{formattedAddress}} < /strong>.</span> <br/>Suplante su identidad y utilice la opción <strong>Perfil</strong> para cambiar este detalle después de que los detalles de facturación del cliente se hayan incorporado correctamente.",
    "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si se debe mapear {{firstProduct}}, también debe mapear {{secondProduct}}.",
    "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si {{firstProduct}} tiene que ser mapeado, no debe ignorar {{secondProduct}}",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Si se tiene que mapear {{firstProduct}}, debe incluir {{secondProduct}}",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Si tiene que mapear {{firstProduct}}, también debe mapear {{secondProduct}}, es decir; La cantidad mínima debe ser 1.",
    "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"La cantidad del proveedor y la cantidad compartida deben coincidir para {{product}}",
    "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"La cantidad debe ser igual para {{firstProduct}} y {{secondProduct}}",
    "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Seleccione CSPCustomer para OnBoard",
    "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Detalles de la cuenta principal",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":" Llenar",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Descarga una plantilla .csv",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":" agregando filas después del encabezado. Cada fila debe corresponder al detalle de un revendedor.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"El procesamiento tomará un tiempo y el estado se informará en esta página. Si se aleja de la página o ha cerrado el navegador por accidente, podría volver a esta página nuevamente y el sistema mostraría el estado de su carga anterior.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Haga clic en Seleccionar y busque el archivo que desea cargar.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"La entrada se valida para garantizar que se cumplan las siguientes reglas:",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"El nombre del revendedor es obligatorio y no debe exceder los 50 caracteres de longitud.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Se requiere ID de revendedor y no debe exceder los 50 caracteres de longitud. Este debe ser el GUID de relación del revendedor del Centro de partners.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Se requiere ID de MPN de revendedor y no debe exceder los 50 caracteres de longitud. Debe ser el identificador de Microsoft Partner Network del revendedor.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"La ubicación es obligatoria y debe ser el código de país de dos letras del revendedor. Por ejemplo: EE. UU., Reino Unido, etc.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"El correo electrónico es opcional, pero debe ser una dirección de correo electrónico válida si se ingresa una.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Haga clic en <strong class=\"text-uppercase\">enviar</strong> para que se procesen los datos.",
    "ONBOARDING":"Inducción",
    "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Descargar informe",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Seleccionar tipo de cliente",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Seleccionar unidad de duración",
    "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Cerca",
    "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"revendedores",
    "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Seleccionar revendedor",
    "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Reiniciar",
    "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Búsqueda",
    "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"tipo de cliente",
    "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Unidad de duración",
    "ONBOARDING_DURATION_UNIT_MONTH":"Mes",
    "ONBOARDING_DURATION_UNIT_WEEK":"Semana",
    "ONBOARDING_DURATION_UNIT_YEAR":"Año",
    "ONBOARDING_GRAPH_COUNT_TEXT":" Contar:",
    "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"No se permite la incorporación de clientes ya que no existe un vínculo con el revendedor del proveedor. Póngase en contacto con su socio.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"No se admite la incorporación de un cliente Microsoft NonCSP. Vincule el cliente con un cliente C3 existente.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"¡No se admite la incorporación de un cliente MicrosoftNonCSP!",
    "ONBOARDING_STATUS":"Estado de incorporación",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Mapeo de los productos del cliente proveedor con los productos del plan...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Cargando planos...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Cargando los clientes del proveedor...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Buscando los datos del cliente en c3...",
    "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"todos los clientes",
    "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Clientes directos",
    "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Clientes revendedores",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"¿Estas seguro que quieres borrarlo?",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Seleccione un producto en la lista o busque por nombre de producto",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"La solicitud para eliminar licencias de los usuarios se acepta y se está procesando.",
    "OnlineServicesNCE":"Servicio en línea NCE",
    "Open":"Abierto",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Nombre del plan",
    "OPERATING_ENTITY_CUSTOMER":"Cliente",
    "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Configuración de la organización",
    "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Sitios y Departamentos",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Asignar",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Liberar",
    "ORDER_BACK_TO_ORDER_DETAILS":"volver a Productos",
    "ORDER_DETAILS_ENTITY_NAME":"Nombre de la entidad",
    "ORDER_DETAILS_FIELD_ORDERED_ON":"pedido el",
    "ORDER_DETAILS_SCHEDULED_DATE":"Cita agendada",
    "ORDER_ID":"Solicitar ID",
    "ORDER_LBL_TEXT_PROMOTIONID":"ID de promoción",
    "ORDER_LIST_STATUS_SUCCESS":"Éxito",
    "ORDER_STATUS_APPROVAL_ACCEPTED":"Aprobado",
    "ORDER_STATUS_APPROVAL_REQUIRED":"Aprobación requerida",
    "ORDER_STATUS_COMMENTS":"Ver comentarios",
    "ORDER_STATUS_DELETED":"Eliminado",
    "ORDER_STATUS_DESC_ACCEPTED":"Aceptado",
    "ORDER_STATUS_DESC_FAILED":"Ha fallado",
    "ORDER_STATUS_DESC_IGNORE":"Ignorar",
    "ORDER_STATUS_DESC_INPROGRESS":"En curso",
    "ORDER_STATUS_DESC_ONHOLD":"En espera",
    "ORDER_STATUS_DESC_ORDERED":"Ordenado",
    "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Cumplido parcialmente",
    "ORDER_STATUS_DESC_PROCESSING":"Procesando",
    "ORDER_STATUS_DESC_PROVISIONING":"Aprovisionamiento",
    "ORDER_STATUS_DESC_REJECTED":"Rechazado",
    "ORDER_STATUS_DESC_SCHEDULED":"Programado",
    "ORDER_STATUS_DESC_SUCCESS":"Éxito",
    "ORDER_STATUS_ERRORS_IGNORED":"Error <br/>(errores revisados e ignorados)",
    "ORDER_STATUS_FAILED":"Ha fallado",
    "ORDER_STATUS_INPROGRESS":"Trabajando en ello",
    "ORDER_STATUS_ONHOLD":"En espera",
    "ORDER_STATUS_ORDERED":"Ordenado",
    "ORDER_STATUS_PROCESSING":"Trabajando en ello",
    "ORDER_STATUS_PROVISIONING":"Trabajando en ello",
    "ORDER_STATUS_REACTIVATED":"Reactivado",
    "ORDER_STATUS_REJECTED":"Rechazado",
    "ORDER_STATUS_SCHEDULED":"Programado",
    "ORDER_STATUS_SUCCESS":"Pedido cumplido",
    "ORDER_STATUS_SUSPENDED":"Suspendido",
    "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Cotización aprobada y pedido realizado exitosamente.<br/> El ID del pedido es <b>{{OrderNumber}}</b>",
    "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Cotización aprobada exitosamente.",
    "OWNER_ENTITY_NAME":"Nombre de la entidad propietaria",
    "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Detalles del estado de pago",
    "PaidAmount":"Monto pagado",
    "PARENT_ENTITY_NAME":"Nombre de la entidad principal",
    "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"El complemento no se puede activar si la oferta principal no está activa.",
    "PARENT_PRODUCT_NOT_EXISTS":"¡No se puede incorporar un producto sin padre!",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"No hay claves disponibles para este proveedor",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"No hay proveedores configurados, comuníquese con el soporte",
    "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":" Guarda tus cambios",
    "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Descripción",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Mostrar moneda",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Moneda de la factura",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valor macro",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Nombre",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Compra de moneda",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Seleccione",
    "partner":"Compañero",
    "Partner":"Compañero",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Asignar",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Desasignar",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Nombre del cliente",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Volver a los administradores de cuentas",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Clientes del administrador de cuentas",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Clientes del administrador de cuentas <span class='text-primary'> {{FirstName}} </span>",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nombre",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"ID de C3",
    "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Nombre del revendedor",
    "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Actualiza todos los planes con las últimas ofertas.",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"No se encontraron complementos",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Eliminar plan",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Revisar ofertas de planes",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Guardar plan",
    "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Para la oferta de prueba, no se encontró ninguna oferta principal. Incluya la oferta para padres e inténtelo de nuevo.",
    "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Uso",
    "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Ingrese la fecha efectiva desde",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Ingrese la fecha efectiva para",
    "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Introducir mensaje",
    "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Seleccionar tipo de mensaje",
    "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Seleccionar portal de página",
    "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Iconos-de-estocolmo / Comunicación / Chat grupal",
    "PARTNER_APPROVAL_PENDING":" Aprobación del socio -",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"añadido al paquete",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":" agregado al paquete con complementos",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
    "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":" Ya está agregado al paquete y no se puede agregar nuevamente.",
    "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"filtros",
    "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Reiniciar",
    "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Búsqueda",
    "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Validez",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Duración del término",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Duración del término",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Tipos de facturación",
    "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Precio de coste",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Precio de costo (desde)",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Precio de costo (a)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Precio de venta",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Precio de venta (desde)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Precio de venta (a)",
    "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Datos de losa",
    "PARTNER_BUNDLE_LABEL_TEXT_TO":" - a -",
    "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Tipo de validez",
    "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Incluir paquetes eliminados",
    "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Por favor seleccione mínimo un producto.",
    "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Seleccione un mínimo de 2 ofertas para combinarlas",
    "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Por favor proporcione precio de venta",
    "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"¡No se encontraron productos!",
    "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Productos agrupados",
    "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"manojos",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Agregar",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Nuevo paquete",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
    "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Volver a los detalles del paquete",
    "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Cancelar",
    "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Borrar",
    "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Descargar paquetes",
    "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Editar",
    "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":" Búsqueda aplicada:",
    "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"próximo",
    "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Revisar productos de paquete",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"Ahorrar",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Guardar paquete",
    "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Mostrar ofertas",
    "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Búsqueda rápida",
    "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Volver a paquetes de socios",
    "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Agregar paquete",
    "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Ver paquete",
    "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
    "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Precio sugerido:",
    "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"¿El automóvil es renovable?",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Tipo de facturación",
    "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"¿Disponible para agrupar?",
    "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Categoría",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Período de carga",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Período de carga",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Tipo de consumo",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"texto de ayuda",
    "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Precio de coste",
    "PARTNER_BUNDLES_LABEL_TEXT_Description":"Descripción",
    "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Fuente de alimentación",
    "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"¿Disponible para consumo inmediato?",
    "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Nombre",
    "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Nº de días para cancelación gratuita",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Norma de facturación para la compra de asientos",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Norma de facturación para la reducción de plazas",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Los cambios de cantidad se prorratean o se cobran por completo la nueva cantidad",
    "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Proveedor",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Precio de venta",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Tipo de venta",
    "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Validez",
    "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Buscar productos",
    "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Cantidad comprada",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Descripción",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Nombre",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Detalles de la oferta",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Vista",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Categorías",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"proveedores",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Tipos de servicio",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Costo para el socio",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"costo para el cliente",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Pagos múltiples",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Pagos múltiples",
    "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Pagos",
    "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Actualice el informe de ingresos haciendo clic en el botón de recarga antes de continuar.",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"¡No se encontró ningún historial!",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Búsqueda aplicada",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Tipos de servicio",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "PARTNER_COMMENTS_DETAILS":"Comentarios",
    "PARTNER_COMMENTS_TAB_CART_ITEM":"Artículo del carrito",
    "PARTNER_COMMENTS_TAB_INVOICE":"Factura",
    "PARTNER_COMMENTS_TAB_PRODUCT":"Producto",
    "PARTNER_COMMISIONS":"Comisiones de socios",
    "PARTNER_CONSENT_HELP_TEXT":"Copie el enlace haciendo clic en el botón de arriba y abra en una nueva ventana de incógnito y complete el flujo de consentimiento.",
    "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Seleccione el tipo de facturación para la losa",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Agregar",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Borrar",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Editar",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Seleccione la aplicabilidad de la oferta",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Correo electrónico",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Nombre",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Número de teléfono",
    "PARTNER_CUSTOMER_FILTER_NAME":"Nombre",
    "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
    "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} no está asignado a ningún administrador de cuentas",
    "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"No hay clientes vinculados a ningún inquilino proveedor.",
    "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"La asignación de este plan incluirá las ofertas con márgenes definidos en el plan. ¿Está seguro de que desea actualizar el estado del Plan?",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Asignar",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Desactivar",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Permitir",
    "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"Al inhabilitarlo, se eliminará este plan, incluidas sus ofertas. Los productos comprados existentes tendrán el mismo margen de beneficio de este plan. ¿Está seguro de que desea actualizar el estado del Plan?",
    "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"Habilitar asignará este plan incluyendo las ofertas con márgenes definidos en el plan. ¿Está seguro de que desea actualizar el estado del Plan?",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Volver a planes de clientes",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
    "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Cambio de plan",
    "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":" Gestionar planes para:",
    "PARTNER_CUSTOMER_REPORTS":"Informes",
    "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"Etiqueta de cliente agregada exitosamente",
    "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"etiqueta de cliente actualizada correctamente",
    "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"¿Está seguro de que desea actualizar el estado del Plan?",
    "PARTNER_CUSTOMERS_TEXT_INFO":"Lo sentimos, no se encontraron clientes",
    "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Cuentas por cobrar",
    "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Cuotas pasadas",
    "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilidad",
    "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"10 principales clientes",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Tus clientes activos",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clientes",
    "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Asientos de clientes",
    "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Asientos de clientes directos",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Suscripciones de clientes directos",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Clientes Suscripciones",
    "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"No se encuentran los detalles de la factura del revendedor para resellerId: {{resellerId}}, billingPeriodId: {{billingPeriodId}}",
    "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Factura generada el",
    "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Beneficio neto",
    "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Otros cargos",
    "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Ganancia",
    "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Cantidad facturada",
    "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"Factura MSFT",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Un vistazo rápido a la rentabilidad de su CSP",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilidad",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Resumen de sus cuentas por cobrar",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Cuentas por cobrar",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Pagos aún por cobrar",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Cuotas vencidas",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"Pendiente",
    "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Vuelva a consultar después de que las facturas estén listas.",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Los 10 SKU principales",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Recuentos de productos por los 10 SKU principales",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Cuenta por SKU",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Rango de filtro",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 meses",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 meses",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 año",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Compra de Asientos",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Tendencia de compra de asientos a lo largo de los meses",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Compra de Asientos",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Asientos comprados",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Asientos comprados",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Tus revendedores activos",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"revendedores",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Por categoria",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Ingresos vs Costo",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Un gráfico para ver los detalles de ingresos versus costos por categoría",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Ingresos vs Costo por Categoría",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Costo",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Ingresos",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Seleccionar proveedor",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Ingresos vs Costo",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Ingresos frente a costes",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Ingresos frente a costes",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Clientes",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Asientos",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Suscripciones",
    "PARTNER_DASHBOARD_TILE_INVOICES":"Facturas",
    "PARTNER_DASHBOARD_TILE_RESELLERS":"revendedores",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Por fecha de renovación",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Ingresos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Los 5 mejores productos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Por ingresos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Los 5 productos principales por ingresos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Los 5 productos principales por ingresos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Asientos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Los 5 mejores productos",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Por asientos comprados",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Los 5 mejores productos por asientos comprados",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Los 5 mejores productos por asientos comprados",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Próximas renovaciones",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Ingresos",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"10 principales clientes",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Por ingresos",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Los 10 principales clientes por ingresos",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Los 10 principales clientes por ingresos",
    "PARTNER_LOGOS_LABEL_TEXT":"Seleccionar logotipo",
    "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Imagen de vista previa",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Remover",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Categoría",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Remover",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Cantidad de actualización",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Categoría",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Descuento",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Eliminar complemento",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Complementos",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Remover",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Tipo de facturación",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Categoría",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Descuento",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Nombre",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Precio unitario",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Complementos",
    "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Eliminar oferta",
    "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Agregar oferta de socio",
    "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Ingrese la cantidad que desea comprar.",
    "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Editar oferta de contrato",
    "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Editar oferta de socio",
    "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Introduzca un valor positivo",
    "PARTNER_OFFER_HYPERLINK_READ_LESS":"Leer menos",
    "PARTNER_OFFER_HYPERLINK_READ_MORE":"...Leer más",
    "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Habilite la oferta de prueba marcando esta casilla",
    "PARTNER_OFFER_PROVIDER_TOOLTIP":"Elija el proveedor de soluciones de la lista desplegable",
    "PARTNER_OFFER_SLAB_DATA":"Datos de losa",
    "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":" Duración del período de prueba",
    "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Todos los clientes",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Comportamiento",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Correo electrónico",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista de informes de ofertas de socios.",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Informe de ofertas de socios",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de ofertas de socios",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Ingrese la dirección de correo electrónico a quién desea enviar el correo electrónico, ingrese con coma (,) o punto y coma (;) y haga clic en enviar para recibir un informe.",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Cancelar",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"Entregar",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"¡El producto principal no está aprobado!",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"¡El producto infantil no se rechaza!",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Todos los artículos del carrito se aprueban independientemente de las infracciones de umbral en cada artículo de línea. ¿Estás seguro de que quieres aprobar el carrito?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"¿Está seguro de que desea aprobar el elemento?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Aprobado con éxito la línea de pedido del carrito",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Aprobó con éxito el carrito",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Aprobar todo",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Aprobar",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Aprobar solicitud",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Volver a Solicitudes",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Categorías",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Tipos de servicio",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"proveedores",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Estado",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"pedido el",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Precio unitario",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"rechazar todo",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Rechazar",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Rechazar solicitud",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Presentar cambios",
    "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Asegúrese de aprobar o rechazar al menos un producto antes de enviarlo",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"¿Está seguro de que desea rechazar todo el carrito?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"¿Está seguro de que desea rechazar el artículo?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Se rechazó con éxito la línea de pedido del carrito",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Rechazado con éxito el carrito",
    "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"El estado se actualizó con éxito",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Aprobaciones",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Las siguientes solicitudes de compra de clientes están esperando su aprobación.",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Aprobaciones para",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Solicitudes de compra",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ciclos de facturación seleccionados",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Categorías seleccionadas",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Categorías de proveedores seleccionados",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Proveedores seleccionados",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Aplicar promoción a toda la oferta",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Categorías",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Seleccionar tipo de macro",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Límite de asientos del plan:",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Categorías de proveedores",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"proveedores",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Límite de asientos",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Tipos de servicio",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Plan de clonación completado con éxito",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Se acepta la solicitud del plan de clonación y se está procesando",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Se acepta la solicitud para crear un nuevo plan con todas las ofertas y se está procesando",
    "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Valor macro",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"¿Puede el precio de venta ser menor que el precio del socio?",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Se permite que el precio de venta sea menor que el precio del socio",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"¿Puede el precio de venta superar el precio de venta del proveedor?",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio de venta supere el precio de venta del proveedor.",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Definir plan en nueva moneda",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Nombre",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Código de moneda del plan",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Nombre del producto",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Moneda de origen",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Moneda objetivo",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Código de moneda de destino",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"No hay una moneda de destino; comuníquese con el socio para definirla.",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"¿Cómo?",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Establezca el límite en -1 para requerir aprobación para todas las compras/actualización de asientos",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Use esta pantalla para configurar el umbral por conteo de asientos",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Establezca el límite en 0 para permitir la compra de asientos ilimitados",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Establezca un número positivo distinto de cero para definir el umbral",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Cualquier compra que exceda el umbral requiere la aprobación del Socio",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Haga clic en el botón enviar para guardar los cambios<b>",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Configurar límites de asientos para {{PlanName}}",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Definir macro y guardar con todas las ofertas",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Agregar ofertas faltantes",
    "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Cancelar",
    "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"plan de clonación",
    "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Guardar plan con todas las ofertas",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Plan de clonación",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Plan de Clonación",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Creación de planes",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"El plan está en progreso. Haga clic para ver el estado",
    "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Límites de asientos",
    "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
    "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodTypeBillingPeriodType",
    "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
    "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Catálogo de productos",
    "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Catálogo de productos",
    "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Catálogo de productos",
    "PARTNER_PRODUCT_CATEGORYID":"Categoria ID",
    "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ID de tipo de consumo",
    "PARTNER_PRODUCT_DESCRIPTION":"Descripción",
    "PARTNER_PRODUCT_NAME":"Nombre",
    "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
    "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
    "PARTNER_PRODUCT_PRICEFORPARTNER":"PrecioparaSocio",
    "PARTNER_PRODUCT_ProviderId":"ID de proveedor",
    "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProveedorPrecio De Venta",
    "PARTNER_PRODUCT_SALETYPE":"Tipo de venta",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"costo para el cliente",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Divisa",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Identificación del cliente",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Nombre del cliente",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Descripción",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Identificación de producto",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Nombre del producto",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Unidad de medida",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Unidades",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Fecha de uso",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea aceptar esta solicitud?",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"¿Está seguro de que desea rechazar esta solicitud?",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Volver al historial de suscripciones",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Nuevo producto de PSA",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Liberar",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Mapeo del acuerdo de liberación",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Mapeo del contrato de liberación",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Liberar mapeo de clientes",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Crear nuevo producto",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Este campo es obligatorio.",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Categoría",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Costo",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Descripción del cliente",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Descripción",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Envío directo",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Identificador",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Referencia cruzada de integración",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Notas",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":" Precio",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Clase de producto",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"Acuerdo de nivel de servicio",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Pedido especial",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Subcategoría",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Impuesto sobre las ventas",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Tipo de producto",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Unidad de medida (UOM)",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Introduzca el costo",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Introduzca la descripción del cliente",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Introducir identificador",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Introducir notas",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Introducir precio",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Introducir descripción del producto",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Introducir referencia cruzada de integración",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Seleccione el tipo de producto",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Se creó un nuevo producto PSA con éxito",
    "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Volver a clientes",
    "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "PARTNER_RESELLER_FILTER_NAME":"Nombre",
    "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{revendedor}} no está asignado a ningún administrador de cuentas",
    "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Guarda tus cambios",
    "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Detalles básicos",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Descripción",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Nombre",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Seleccione",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"¿Puede el nuevo socio ofrecer un precio menor que el del socio?",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio del nuevo socio sea menor que el precio del socio",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"¿Puede el precio del socio superar el precio de venta del proveedor?",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Habilite esta opción para permitir que el precio del socio supere el precio de venta del proveedor.",
    "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Agregar informe",
    "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"¿Estás seguro que quieres eliminar esto?",
    "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Comportamiento",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Agregar destinatario",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Volver a informes",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Volver al destinatario",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Cancelar",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Borrar",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Editar",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Correo electrónico",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Tipo de destinatario",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Destinatarios",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"Ahorrar",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"A",
    "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Método de entrega",
    "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Destinatarios",
    "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Tipo de destinatario",
    "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Destinatarios",
    "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Historial de ejecución",
    "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Comportamiento",
    "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Última ejecución el (hora local)",
    "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Informes",
    "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Programado el",
    "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Fecha",
    "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Detalles",
    "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Estado",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Ingrese al menos un nombre de impuesto y un porcentaje para {{taxType}}",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Por favor ingrese el porcentaje de impuesto válido",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Por favor complete los campos nombre del impuesto",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Tienes subimpuestos no guardados, ¿quieres continuar?",
    "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificación por correo electrónico del evento",
    "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Seleccione una vista",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"etiqueta de socio eliminada exitosamente",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"etiqueta de socio actualizada correctamente",
    "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Estilos",
    "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Plantillas",
    "PARTNER_STATUS":"Estado de socio",
    "PARTNER_TABLE_HEADER_NAME":"Nombre",
    "PARTNER_TABLE_HEADER_VALUE":"Valor",
    "PARTNER_TERMS_OF_USE":"Condiciones de uso para socios",
    "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Días de prueba",
    "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Ofertas para padres asociadas con la oferta de prueba",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"costo para el cliente",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Divisa",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Descripción",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"Entregar",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Mes",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Coste total",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Fecha de uso",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Unidad de medida",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Unidades",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Fecha de uso",
    "PARTNER_USER_ALREADY_EXECUTE":"El usuario socio ya existe con este correo electrónico.",
    "PARTNER_USER_REMOVED":"El usuario asociado se eliminó correctamente",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Borrar",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Editar",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Clave de etiqueta",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Valores de etiqueta",
    "PARTNER_USERS_ADD_SUCCESS":"Acceso concedido a {{username}} correctamente",
    "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Ya existe un usuario con el correo electrónico {{useremail}} en el sistema",
    "PARTNER_USERS_REMOVE_SUCCESS":"El usuario asociado eliminó {{username}} correctamente",
    "PARTNER_USERS_SAVE_SUCCESS":"El usuario socio actualizó {{username}} correctamente",
    "PARTNER_USERS_SELECT_ROLE_PROMPT":"Por favor asigna un rol para continuar",
    "partnerauditlog":"Registro de auditoría",
    "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Usuario socio actualizado exitosamente",
    "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Volver a usuarios asociados",
    "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Agregar",
    "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Editar",
    "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Usuario socio",
    "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Correo electrónico",
    "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Administrar etiqueta",
    "PARTNERS_LABLE_TEXT_TAG_KEY":"Clave de etiqueta",
    "PARTNERS_LABLE_TEXT_TAG_VALUES":"Valores de etiqueta",
    "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un carácter especial.",
    "PAYMENT_LABEL_FILTER":"Filtrado por",
    "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Perfil de pago Establecido como predeterminado",
    "PAYMENT_PROFILE_DELETE_SUCCESS":"Perfil de pago eliminado",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Nombre del titular de la tarjeta",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Número de tarjeta",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Meses de vencimiento",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Año de caducidad",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Código de seguridad",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Nombre en la tarjeta de crédito",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Número de Tarjeta de Crédito",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Número de mes de dos dígitos",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Año de cuatro dígitos",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Número CVV de tres dígitos en la tarjeta de crédito",
    "PAYMENT_PROFILE_SAVE_FAILURE":"Error al guardar los detalles de pago",
    "PAYMENT_PROFILE_SAVE_SUCCESS":" Detalles de pago guardados",
    "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Los detalles de pago se actualizarán en breve. Por favor, NO VUELVA A INTENTARLO y vuelva a comprobarlo después de algún tiempo.",
    "PAYMENT_PROFILE_TEXT_FAILURE":" Falla",
    "PAYMENT_PROFILE_TEXT_SUCCESS":"Éxito",
    "PAYMENT_STATUS_DESC_ACCEPTED":"Aceptado",
    "PAYMENT_STATUS_DESC_CHARGED":"Cargado",
    "PAYMENT_STATUS_DESC_DECLINED":"Rechazado",
    "PAYMENT_STATUS_DESC_FAILED":"Ha fallado",
    "PAYMENT_STATUS_DESC_FINALIZED":"Pendiente del mes actual",
    "PAYMENT_STATUS_DESC_INPROGRESS":"En progreso",
    "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Parcialmente pagado",
    "PAYMENT_STATUS_DESC_SUCCESS":"Tuvo éxito",
    "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Error tecnico",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Cancelar",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Entregar",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Actualizar",
    "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Este campo es obligatorio.",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Número de cuenta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":" Tipo de cuenta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Apodo",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"¿Es cuenta comercial?",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Compañía",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individual",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"No",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Sí",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":" Comprobación",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Mes de caducidad",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Año de caducidad",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
    "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":" Ahorro",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"cuenta bancaria",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Tarjeta de crédito",
    "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Por favor espera mientras se carga la página",
    "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Cantidad facturada",
    "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Nombre de empresa",
    "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Monto de pago",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Estado de pago",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Motivo del fracaso",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Observaciones",
    "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
    "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Pagado por",
    "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Transacciones fallidas",
    "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Pagos pendientes",
    "PAYMENTS_STATUS_DESC_FAILED":"Ha fallado",
    "PAYMENTS_STATUS_DESC_SUCCESS":"Éxito",
    "PAYMENTS_TABLE_HEADER_AMONT":"Monto de pago",
    "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Cantidad facturada",
    "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Motivo del fracaso",
    "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Período de facturación",
    "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Estado de la factura",
    "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"número de factura",
    "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"¿Se basa en factura?",
    "PAYMENTS_TABLE_HEADER_OWNED_BY":"Propiedad de",
    "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Monto de pago",
    "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Cantidad a pagar",
    "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"ID de pago",
    "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Método de pago",
    "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Monto pendiente",
    "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Monto pendiente",
    "PAYMENTS_TABLE_HEADER_REMARKS":"Observaciones",
    "PAYMENTS_TABLE_HEADER_STATUS":"Estado",
    "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Pago parcial",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Fecha de Transacción",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"ID de transacción",
    "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Ha superado el período de 7 días naturales para reducir los puestos de esta suscripción.",
    "PC_ACTIVATION_ERROR":"No se puede habilitar una suscripción secundaria cuando la suscripción principal no está activa",
    "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"El complemento no se puede comprar sin un producto base compatible",
    "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":" No se encontró la información del socio asesor para completar esta solicitud.",
    "PC_ADVISOR_QUOTE_NOT_FOUND":" No se encontró la cotización del asesor. Por favor, compruebe la información antes de volver a intentarlo.",
    "PC_ALREADY_ACTIVATE_ITEM":" Este artículo ya ha sido activado.",
    "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":" Se requiere un plan de Azure para comprar una suscripción de SaaS con un plan de facturación medido. Póngase en contacto con el socio para obtener más información.",
    "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Este método API no es compatible con la suscripción heredada",
    "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Disponibilidad a la que hace referencia la línea de pedido no encontrada",
    "PC_AVAILIBILTY_QUANTITY":" La disponibilidad tiene más de una opción de cantidad incluida. Póngase en contacto con el socio para obtener más información.",
    "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":" El plan de Azure no se puede mover al estado suspendido ya que tiene una o más suscripciones de Azure en estado activo.",
    "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"No se encontró el ID del plan de Azure especificado o no hay suscripciones de Azure activas en él. Se requiere un plan de Azure con suscripciones activas para comprar un producto SaaS con un plan de facturación medido",
    "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"No se encuentra la suscripción de Azure con el identificador de derecho proporcionado.",
    "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"El ciclo de facturación no puede ser más largo que el plazo.",
    "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"El ciclo de facturación de las suscripciones no coincide con el ciclo de facturación del pedido original",
    "PC_BILLING_CYCLE_IS_INVALID":"El ciclo de facturación no es válido.",
    "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Esta solicitud de transferencia de propiedad de facturación no se puede completar ya que los productos del mercado de terceros no se transfieren con las suscripciones. Elimine los productos del mercado de terceros de su selección y vuelva a intentarlo.",
    "PC_C3_CONNECTION_ERROR":"Error al conectar con el Centro de partners. Vuelve a intentarlo o ponte en contacto con el servicio de asistencia.",
    "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"No se puede cambiar el ciclo de facturación si la suscripción no está asociada con una cuenta Mint",
    "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"No se puede procesar la actualización de Azure para Azure Partner Shared Services",
    "PC_CANCEL_PURCHASE_NON_TENANT":"Los inquilinos que no son de Sandbox no pueden cancelar compras que no sean suscripciones de software y software perpetuo.",
    "PC_CANCELLATION_NOT_ALLOWED":"¡La cancelación no está permitida en este momento!",
    "PC_CANNOT_CHECKOUT":"¡Un carrito ya enviado no se puede retirar!",
    "PC_CANNOT_PROCESS_AZURE_UPGRADE":" No se puede procesar la actualización de Azure. Póngase en contacto con el soporte para obtener más información.",
    "PC_CANNOT_PROCESS_REQUEST":"No se puede procesar la solicitud de transferencia para {1} transferencia {0}",
    "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Tiene una o más suscripciones de Azure en estado suspendido. Estos no se pueden transferir al plan de Azure.",
    "PC_CANNOT_UPDATE_BILLING_CYCLE":"Los desafíos técnicos impiden las actualizaciones de la suscripción. Póngase en contacto con el soporte.",
    "PC_CART_IN_PROCESS":"El carrito ya se está procesando, espere y vuelva a consultar después de un tiempo.",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":" El artículo del catálogo no es elegible para la compra. Póngase en contacto con el soporte para obtener más información.",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":" El artículo del catálogo no es elegible para agregar o quitar cantidad. Póngase en contacto con el socio para obtener más información.",
    "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"El artículo del catálogo requiere la aceptación de la atestación.",
    "PC_CREATE_ORDER_ERROR":"Crear orden no es exitoso",
    "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"La cuenta del cliente está bajo revisión. No se permiten transacciones hasta que se apruebe la cuenta.",
    "PC_CUSTOMER_MIGRATION_FAILED":" La migración de la licencia del cliente falló. Póngase en contacto con el soporte.",
    "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Falta el número de teléfono del cliente. Este error se produce si la empresa/organización del cliente se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia (MD) ), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA), pero no se pasa billingProfile.defaultAddress.phoneNumber.",
    "PC_DISCONTINUED_OFFER":" Esta oferta no está disponible actualmente para su compra, ya que puede haber sido descontinuada. Póngase en contacto con el socio para obtener más información.",
    "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Uno o más de los artículos del pedido no admiten la moneda dada, USD. La moneda de entrada debe ser la moneda del socio o del cliente (mercado).",
    "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"La fecha de finalización del período personalizado especificada en las instrucciones programadas para el próximo período debe estar dentro de la duración del primer período después de la renovación. Además, los productos de plazo mensual no pueden alinearse con una suscripción que finaliza el día 28, 29 o 30 del mes, a menos que esa fecha sea el último día del mes.",
    "PC_GENERIC_ERROR":" Algo salió mal. Póngase en contacto con el soporte para obtener más información o intente nuevamente después de verificar el estado de la operación realizada.",
    "PC_INACTIVE_RESELLER_PROGRAM":" El estado del programa de revendedor no está activo. Póngase en contacto con el soporte para obtener más información.",
    "PC_INCORRECT_CUSTOMER_ID":" Identificación de cliente incorrecta. Verifique la información y vuelva a intentarlo.",
    "PC_INITIATE_TRANSFER_REQUEST_FAILED":" No se pudo iniciar la solicitud de transferencia. Vuelva a intentarlo más tarde después de algún tiempo.",
    "PC_INVALID_ADD_ON":"El complemento no está relacionado con la suscripción principal especificada",
    "PC_INVALID_AZURE_SUBSCRIPTION":"Esta suscripción no es una suscripción de Azure válida",
    "PC_INVALID_CUSTOMER_INFORMATION":" La información proporcionada no coincide con la información actual del cliente. Por favor, compruebe la información y vuelva a intentarlo.",
    "PC_INVALID_DOMAIN":"Dominio inválido. Proporcione la dirección de dominio correcta y vuelva a intentarlo.",
    "PC_INVALID_ETAG":"Etag ya no es válido",
    "PC_INVALID_LINE_ITEM":"La secuencia de elementos de línea no está configurada correctamente, comuníquese con soporte.",
    "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Metadatos no válidos. (Si el precio del cliente, falta la moneda).",
    "PC_INVALID_MPN":"MPN no válido, póngase en contacto con el soporte para una mayor investigación",
    "PC_INVALID_OPERATION":" No se admite esta operación. Póngase en contacto con el socio para obtener más información.",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID":" El valor de ID de registro de la organización no es compatible. Compruebe amablemente el valor de entrada antes de volver a intentarlo.",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":" No se admite el valor o el formato del ID de registro de la organización. Compruebe amablemente el valor de entrada antes de volver a intentarlo.",
    "PC_INVALID_PROMOTION_CODE":" El código de promoción no es válido. Compruébalo e inténtalo de nuevo.",
    "PC_INVALID_QTY_FOR_SUBSCRIPTION":"La cantidad de suscripción no está dentro de la cantidad mínima y máxima permitida",
    "PC_INVALID_REQUEST":" Solicitud no válida. Compruebe amablemente los atributos de la solicitud antes de volver a intentarlo.",
    "PC_INVALID_SUBSCRIPTION":"No es una suscripción válida, póngase en contacto con soporte.",
    "PC_INVALID_SUBSCRIPTION_TRANSER":" La transferencia no se puede crear con una suscripción no válida. Por favor, compruebe la suscripción antes de volver a intentarlo.",
    "PC_ISSUE_ASSET_STATUS_CHANGE":"No se pudo completar la operación de cambio de estado del activo. Póngase en contacto con el socio para obtener más información.",
    "PC_ISSUE_OFFER_TYPE":" No se pudo completar la compra debido a un problema con el tipo de oferta. Póngase en contacto con el soporte.",
    "PC_ITEM_IS_NOT_AVAILABLE":"Este artículo no está disponible",
    "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":" El número solicitado de superó el límite restante permitido por suscripción para la oferta. Ajuste amablemente la cantidad antes de volver a intentarlo.",
    "PC_LIMIT_EXCEEDED":"Se ha superado el límite de uso para el ID de la oferta",
    "PC_LINE_ITEM_ISSUE":" Hay un problema con la línea de pedido. Póngase en contacto con el soporte para obtener más información.",
    "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"No se puede realizar la migración porque se especificaron dos o más migraciones complementarias con el mismo ID de suscripción actual",
    "PC_MISSING_ASSET_ID":" No se pudo completar la operación debido a que falta la identificación del activo. Póngase en contacto con el soporte para obtener más información.",
    "PC_MISSING_AZURE_SUBSCRIPTION":" No se puede procesar la actualización de Azure porque no se encontró la información de suscripción de Azure.",
    "PC_MISSING_MCA":"Por favor acepte MCA antes de proceder con la compra.",
    "PC_MISSING_REQUIRED_TAGS_IN OFFER":" La cuenta no tiene las etiquetas requeridas para la oferta. Póngase en contacto con el socio para obtener más información.",
    "PC_MISSING_TRANSFER_REQUEST":" No se encontró ninguna solicitud de transferencia.",
    "PC_MISSING_UPGRADE_REQUEST":"No se encontró ninguna solicitud de actualización.",
    "PC_MPN_NOT_FOUND":"No se encuentra el ID de MPN",
    "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"No se permiten múltiples solicitudes de transferencia. Otra solicitud de transferencia en curso.",
    "PC_NCE_MIGRATION_COULD_NOT_FOUND":"No se pudo encontrar la migración de New-Commerce.",
    "PC_NO_AZURE SUBSCRIPTION":"No se puede procesar la actualización de Azure porque el cliente no tiene suscripciones de Azure.",
    "PC_NOT_ELIGIBLE_AUTO_RENEWED":" La suscripción no es elegible para ser renovada automáticamente. Póngase en contacto con el socio para obtener más información.",
    "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"El socio no es elegible para esta transacción, comuníquese con soporte.",
    "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"La API de ofertas no admite elementos. Intente usar las API de Productos y/o SKU",
    "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Oferta no válida en el país especificado",
    "PC_OFFER_PURCHASE_ERROR":"ID de oferta no válido",
    "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":" Recientemente se compraron una o más suscripciones de Azure; estas suscripciones no se pueden cambiar en este momento. Por favor, inténtelo de nuevo más tarde.",
    "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":" No se admite la cancelación de SaaS basado en licencias a través de un pedido de parches. Use la suscripción de parche en su lugar",
    "PC_ORDER_CANCELLATION":" El pedido no se puede cancelar. Póngase en contacto con el soporte para obtener ayuda.",
    "PC_ORDER_CANCELLATION_ERROR":"El pedido no se puede cancelar.",
    "PC_ORDER_IN_PROGRESS":" Tu pedido está en proceso. Consulte su historial de pedidos para ver los pedidos recientes en unos minutos.",
    "PC_ORDER_NOT_FOUND":"¡Pedido no encontrado!",
    "PC_ORDER_VENDOR_POLICY":"No se pudo realizar el pedido debido a una política de proveedor vigente. Póngase en contacto con el soporte para saber más.",
    "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Falta la información de identificación de registro de la organización. Este error se produce si la empresa/organización del cliente se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia (MD) ), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA) pero no se pasa el número de registro de organización.",
    "PC_ORGANISATION_REGESTRATION_ID_MISSING":" Falta la información de identificación de registro de la organización. Ingrese amablemente una identificación de registro válida antes de volver a intentarlo.",
    "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"El valor de ID de registro de la organización no es compatible. Este error se produce si la empresa/organización del cliente no se encuentra en los siguientes países: Armenia (AM), Azerbaiyán (AZ), Bielorrusia (BY), Hungría (HU), Kazajstán (KZ), Kirguistán (KG), Moldavia ( MD), Rusia (RU), Tayikistán (TJ), Uzbekistán (UZ), Ucrania (UA), pero intentaron especificar el número de registro de la organización.",
    "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":" La información de identificación de registro de la organización no es válida. Compruebe amablemente la entrada y vuelva a intentarlo.",
    "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Esta transferencia no está permitida ya que la transferencia original {0} está en curso",
    "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"El excedente ya está asignado a otro inquilino. Póngase en contacto con su cliente para resolver dudas sobre la propiedad",
    "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"El excedente no se puede establecer porque el cliente tiene suscripciones heredadas de Azure.",
    "PC_PARTNER_ATTESTATION_MISSING":"Falta la atestación de socio",
    "PC_PARTNER_INFORMATION_MISSING":" No se pudo completar la solicitud porque falta la información del socio. Póngase en contacto con el soporte para obtener más información.",
    "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"El ID de inquilino del socio aún no puede realizar migraciones a New Commerce en sus suscripciones",
    "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"No se puede actualizar el socio registrado para esta suscripción. Póngase en contacto con el soporte de Microsoft para obtener ayuda.",
    "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Hay un ajuste pendiente solicitado para este pedido. Póngase en contacto con el socio para obtener más información.",
    "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Hay una operación pendiente en esta suscripción. Vuelva a intentarlo después de 24 horas.",
    "PC_PHONE_NUMBER_MISSING":" Falta el número de teléfono del cliente. Proporcione amablemente el número de teléfono del cliente antes de volver a intentarlo.",
    "PC_PRICING_INFORMATION_NOT_PERMITTED":" No se permite el acceso a la información de precios. Póngase en contacto con el soporte.",
    "PC_PRODUCT_NOT_FOUND":"No se encontró el producto",
    "PC_PROPERT_NOT_SUPPORTED":" Esta propiedad no es compatible. Por favor revise y vuelva a intentarlo.",
    "PC_PURCHASE_NOT_SUPPORTED":"Sistema de compra no soportado. Póngase en contacto con el socio para obtener más información.",
    "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Se requiere documento de orden de compra.",
    "PC_PURCHASE_PRE_REQUISTIES_MISSING":" No se cumplen uno o más requisitos previos de compra. Póngase en contacto con el socio para obtener más información.",
    "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"La cantidad de suscripción no se puede aumentar o disminuir mientras la suscripción está suspendida",
    "PC_QUANTITY_CANNOT_DECREASED":" La cantidad de suscripción no se puede disminuir. Póngase en contacto con el socio para obtener más información.",
    "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":" La cantidad no está disponible para este artículo del catálogo. Ajuste amablemente la cantidad antes de realizar un pedido o comuníquese con un socio para obtener más información.",
    "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":" No se admiten cambios de cantidad en la suscripción con estado suspendido y eliminado. Póngase en contacto con el socio para obtener más información.",
    "PC_REQUEST_INFORMATION_NOT_AVAILABLE":" La operación no se pudo completar porque la información solicitada no está disponible actualmente. Vuelva a intentarlo o póngase en contacto con el soporte.",
    "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"La cantidad solicitada no está dentro de la cantidad permitida para esta oferta.",
    "PC_RESELLER_RELATIONSHIP_MISSING":" No existe una relación de revendedor con el cliente. Por favor, establezca una relación de revendedor antes de intentar esta acción nuevamente.",
    "PC_RI_PURCHASE_NOT_ENABLED":"Esta suscripción no está habilitada para la compra de RI. Póngase en contacto con el socio para obtener más información.",
    "PC_SANDBOX_LIMIT":"Se ha alcanzado el límite de sandbox. Ajuste amablemente el límite antes de realizar un pedido.",
    "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":" No se admite la venta en el país del cliente. Consulte la lista de mercados de CSP de Microsoft para obtener más información.",
    "PC_SKU_NOT_FOUND":"SKU no encontrado",
    "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Algo salió mal. Vuelva a intentarlo después de un tiempo.",
    "PC_SUBCRIPTION_ACTIVE_ISSUE":" Esta suscripción no es una suscripción activa. Póngase en contacto con el socio para obtener más información.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"La suscripción no se puede migrar a New Commerce porque tiene complementos.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"La suscripción no se puede migrar a New Commerce porque la combinación de duración del plazo y ciclo de facturación no es compatible con el producto New-Commerce.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"La suscripción no se puede migrar a New Commerce porque todavía no hay una ruta de migración válida para esta oferta.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"La suscripción no se puede migrar a New Commerce debido a una o más razones (posibles razones: la suscripción no está en estado Activo - la suscripción tiene promociones aplicadas - la suscripción tiene complementos adjuntos - la suscripción está demasiado cerca del final del plazo - la oferta de suscripción es aún no disponible en New Commerce)",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"La suscripción no se puede migrar a New Commerce porque tiene promociones aplicadas.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"La suscripción no se puede migrar a New Commerce porque la suscripción se está procesando actualmente.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"La suscripción no se puede migrar a New Commerce porque faltan menos de 24 horas desde el final del plazo.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"La suscripción no se puede migrar a New Commerce porque ha estado activa durante 1 mes o menos. Por favor, inténtelo de nuevo más tarde.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"La suscripción no se puede migrar a New-Commerce porque una o más suscripciones complementarias especificadas en la colección AddOnMigrations no existen o no están activas.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"La suscripción no se puede migrar a New Commerce porque el ID de socio registrado en la suscripción actual no es válido.",
    "PC_SUBSCRIPTION_HISTORY_ISSUE":" No se pudo recuperar el historial de suscripciones. Póngase en contacto con el soporte para obtener más información.",
    "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Su suscripción está en proceso de caducar. No se permiten más actualizaciones en esta suscripción.",
    "PC_SUBSCRIPTION_NOT_FOUND":"La suscripción que intenta usar ya no está disponible con Microsoft.",
    "PC_SUBSCRIPTION_NOT_REGISTERED":" Esta suscripción no está registrada. Póngase en contacto con el socio para obtener más información.",
    "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":" La oferta de suscripción no está disponible para la compra. Póngase en contacto con el socio para obtener más información.",
    "PC_SUBSCRIPTION_UPDATE":" La suscripción no está lista para ser actualizada. Vuelva a intentarlo después de un tiempo o póngase en contacto con el soporte si el problema persiste.",
    "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"El número deseado de suscripciones excedió el número máximo de suscripciones permitidas por cliente",
    "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"La suscripción no se puede migrar a New Commerce porque el estado no está activo.",
    "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"La fecha de finalización del compromiso de suscripción de destino debe ser posterior a la suscripción de origen",
    "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"La transición de destino no puede tener una duración de plazo inferior a la de la suscripción de origen.",
    "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"El campo ToSubscriptionId de la transición de destino no es válido. La suscripción de destino no está lista para actualizarse. Vuelva a intentarlo después de un tiempo.",
    "PC_TENANT_ALREADY_USED":"El dominio especificado durante la creación del arrendatario ya lo usa otro arrendatario. Elija otro dominio y vuelva a intentarlo",
    "PC_TENANT_NOT_EXISTS":"El nombre de dominio no existe, verifique el nombre de dominio.",
    "PC_TENANT_NOT_FOUND":"Inquilino no encontrado.",
    "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"La duración del término en la transición de destino no es válida.",
    "PC_TERM_EXCEEDED":"El plazo de compra inicial ya no está disponible para la compra/actualización",
    "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":" El ID del artículo del catálogo no está presente. Póngase en contacto con el soporte para obtener ayuda.",
    "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"La fecha de finalización del período personalizado especificada en las instrucciones programadas para el próximo período no coincide con la fecha de finalización de ninguna suscripción activa de OnlineServicesNCE que no sea de prueba ni se alinea con el final del mes calendario.",
    "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"La fecha de finalización del plazo personalizado especificada en las instrucciones programadas para el próximo plazo debe estar dentro de la duración del primer plazo después de la renovación. Los productos de plazo mensual no pueden alinearse con una suscripción que finaliza el 28, 29 o 30 del mes, a menos que esa fecha sea el último día del mes.",
    "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"La solicitud no pudo recuperar toda la información de recursos de disponibilidad. Compruebe amablemente después de algún tiempo antes de volver a intentarlo.",
    "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":" El programa solicitado no está activo. Póngase en contacto con el soporte para saber más.",
    "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":" El rol proporcionado no tiene los derechos para realizar la operación solicitada. Póngase en contacto con el soporte para obtener más información.",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED":" No se admite esta operación. Póngase en contacto con el soporte para obtener más información.",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":" Esta operación no se admite en este entorno. Póngase en contacto con el socio para obtener más información.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Esta solicitud de transferencia no se puede iniciar porque el cliente no ha comprado el plan de Azure.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":" Esta solicitud de transferencia no se puede iniciar porque el plan de Azure no se puede comprar. Póngase en contacto con el socio para obtener más información.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":" Esta solicitud de transferencia no se puede iniciar porque el plan de Azure no está activo, habilite el plan de Azure y vuelva a intentarlo.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":" Esta solicitud de transferencia no se puede iniciar porque el cliente tiene suscripciones heredadas de Azure.",
    "PC_TOKEN_MISSING":"Falta el token de socio en el contexto de la solicitud.",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"El campo ToSubscriptionId de la transición de destino no es válido. Suscripción de destino CommitmentEndDate debe ser posterior a la suscripción de origen",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"El campo ToSubscriptionId de la transición de destino no es válido. La suscripción de destino debe estar activa.",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"El campo ToSubscriptionId de la transición de destino no es válido. El ID de suscripción de destino no puede ser el mismo que el ID de suscripción de origen.",
    "PC_TRANSACTION_ELIGIBLE":"Usted puede ser elegible para esta transacción. Póngase en contacto con Soporte para obtener ayuda.",
    "PC_TRANSACTION_NOT_ELIGIBLE":" Usted no es elegible para esta transacción. Reduzca la cantidad o comuníquese con Soporte para obtener ayuda.",
    "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"La transferencia no se puede crear como socio: {0} nube nacional {1} no es compatible",
    "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":" La transferencia no se puede iniciar porque faltan detalles de dominio/correo electrónico del socio de origen en la solicitud. Ingrese amablemente la información solicitada antes de volver a intentarlo.",
    "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":" La creación de la transferencia falló debido a que los detalles del socio de origen coincidieron con el socio solicitante. Póngase en contacto con el soporte de Microsoft para obtener más información.",
    "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Esta solicitud de transferencia de la propiedad de facturación no se puede completar porque las reservas de Azure no se transfieren con las suscripciones. Cancele las reservas de Azure asociadas con las suscripciones de su selección y vuelva a intentarlo.",
    "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"No se puede aceptar la solicitud de transferencia. Solicite al socio que cree una transferencia sin suscripciones de Azure.",
    "PC_TRANSFER_REQUEST_DISABLE":"La transferencia no se puede crear como socio: {0} no habilitado para esta función",
    "PC_UNABLE_TO_COMPLETE_THE_REQUEST":" No se puede completar la solicitud. Póngase en contacto con el soporte de Microsoft para obtener más información.",
    "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"No se puede crear el pedido. Hay un límite para la cantidad de planes de Azure que se pueden crear en cuentas de espacio aislado",
    "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":" No se pudo encontrar el perfil del cliente para completar la solicitud. Póngase en contacto con el soporte para obtener más información.",
    "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"No se pueden obtener suscripciones de Azure Active Directory para un cliente con ID de arrendatario {0} e ID de aprovisionamiento de fuente {1}",
    "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"No se puede procesar el pedido debido a un límite de crédito insuficiente, comuníquese con ucmwrcsp@microsoft.com para obtener más ayuda",
    "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Hubo un error inesperado al procesar la migración de New-Commerce.",
    "PC_UNSUCCESSFUL_TRANSFER_ORDER":" La orden de transferencia no tiene éxito. Póngase en contacto con el soporte para obtener más información.",
    "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Las operaciones de actualización actualmente no están permitidas para esta suscripción.",
    "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Las operaciones de actualización ya no están permitidas para esta suscripción.",
    "PC_UPDATES_NOT_ALLOWED":"No se puede actualizar una suscripción antes de activarla'",
    "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":" La solicitud de actualización no se puede procesar para una suscripción ya actualizada.",
    "PC_UPGRADE_REQUEST_IN_PROGRESS":" La solicitud de actualización no se ha completado porque hay otra solicitud de actualización en curso.",
    "PC_USER_LIMIT_EXCEEDED":"El número de asientos deseado superó el número máximo de asientos permitido por suscripción",
    "PC_USER_NOT_FOUND":"Usuario no encontrado en el arrendatario de destino.",
    "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Cuando sea parte de la oferta, se requiere un enlace de licitación o un documento de licitación.",
    "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"No es elegible para administrar el excedente para este cliente.",
    "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Ha pasado su ventana de cancelación. No podemos cancelar tu compra",
    "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Ha pasado su ventana de día para reducir asientos para esta suscripción.",
    "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Su empresa no ha aceptado el Acuerdo de socio de Microsoft (MPA). El administrador global de la cuenta CSP debe aceptar el MPA para reanudar las capacidades transaccionales completas.",
    "PCA_MISSING_MPA":"Falta el contrato de socio de Microsoft del socio.",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Obtener estado de pago pendiente de facturación",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Actualizar estado de pago en c3",
    "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Se actualizó el estado de pago de {{recordName}}",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Comportamiento",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"Estado de pago de facturación",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"Estado de pago C3",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Nombre de la entidad",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"número de factura",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Monto de pago",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Nombre de registro",
    "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Ver detalles",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Solicitar ID",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"pedido el",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Observaciones",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Tipo de solicitud",
    "Percentage":"Porcentaje",
    "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Claves de software",
    "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Descargar software y claves",
    "PHONE_TABLE_HEADER_ACTIONS":"Comportamiento",
    "PHONE_TABLE_HEADER_PHONE_NUMBER":"Número de teléfono",
    "PHONE_TABLE_HEADER_PHONE_TYPE":"Tipo de telefono",
    "PHONE_TYPE_HOME":"Hogar",
    "PHONE_TYPE_HOME_FAX":"Fax a domicilio",
    "PHONE_TYPE_MAIN":"Principal",
    "PHONE_TYPE_MOBILE":"Móvil",
    "PHONE_TYPE_OFFICE":"Oficina",
    "PHONE_TYPE_OTHER":"Otro",
    "PHONE_TYPE_WORK":"Trabajar",
    "PHONE_TYPE_WORK_FAX":"Fax de trabajo",
    "PLACE_HOLDER_SEARCH_CUSTOMER":"Buscar cliente",
    "PLACEHOLDER_ENTER_TEXT":"Ingrese texto",
    "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Seleccionar ciclo de facturación...",
    "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Seleccione el tipo de facturación...",
    "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Seleccionar categorías",
    "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Seleccionar tipo de categoría...",
    "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Seleccionar tipo de consumo...",
    "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Buscar código de moneda",
    "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Buscar cliente",
    "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
    "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Seleccione un cliente proveedor en la lista o busque el nombre o la identificación del arrendatario...",
    "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"Válido desde",
    "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Efectivo para",
    "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"milímetro",
    "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Ingrese texto",
    "PLACEHOLDER_FOR_PLAN_NAME":"Ingrese el nombre del plan",
    "PLACEHOLDER_FOR_PRICE":" Precio",
    "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Seleccionar proveedores",
    "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Buscar cliente",
    "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Seleccione Funciones..",
    "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Seleccione",
    "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Seleccione Valores de etiqueta..",
    "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Seleccione término-duración...",
    "PLACEHOLDER_FOR_TEXT_SEARCH":"Buscar...",
    "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Seleccione la duración de la prueba...",
    "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Seleccione el tipo de validez...",
    "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"AAAA",
    "PLACEHOLDER_SPECIAL_QUALIFICATION":"Cualificaciones especiales",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Editar",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Editar cualificaciones especiales",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"El máximo de caracteres permitidos es 50",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"Debería tener al menos un carácter",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"Ahorrar",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Ninguno",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Este campo es obligatorio",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Cerca",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Educación",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Segmento de educación",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Educación superior",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Sitio web",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Ninguno",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Entidad de propiedad estatal",
    "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"¿Seleccionar {{EntityName}} específico? Haga clic aquí...",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Escriba para buscar una etiqueta",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Seleccione un Inquilino en la lista o busque el nombre/id-inquilino...",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Plan de búsqueda",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Nombre del producto",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Identificación de producto",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Buscar con nombre de producto o ID de producto",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Nombre del producto, identificación",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Buscar suscripción",
    "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Ingrese identificaciones de correo electrónico válidas",
    "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"máx. precio de coste",
    "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"máx. precio de venta",
    "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"máx. validez",
    "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"mín. precio de coste",
    "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"mín. precio de venta",
    "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"mín. validez",
    "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Monto del pago",
    "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Escriba aquí...",
    "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Elija un cliente existente de la lista o busque utilizando el nombre del cliente/ID del arrendatario/dominio",
    "PLACEHOLDER_TEXT_FOR_REMARKS":"Observaciones",
    "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Buscar por cliente",
    "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Buscar por estado",
    "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Marque esta casilla si planea tener ofertas compuestas en este plan en el futuro.",
    "PLAN_ASSIGN_STATUS_SUCCESS":"El estado se actualizó con éxito",
    "PLAN_BREADCRUMB_BUTTON_PLAN":"planes",
    "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Agregar nuevo plan",
    "PLAN_CAPTION_TEXT_PLANS":"planes",
    "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
    "PLAN_CHANGE_FOR_CUSTOMER":" Cambiar plan del cliente",
    "PLAN_CHANGE_TEXT":"Cambio de plan",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Ofertas de planes compuestos",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"planes",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Volver a ofertas compuestas",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Agregar oferta compuesta",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Editar oferta compuesta",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Ofertas",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Artículos seleccionados",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":" Precio sugerido :",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Complementos",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Complementos",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Precio de lista",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Remover",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Descripción",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"ID del componente de facturación",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Nombre de la oferta",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":" Precio",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Agregar oferta compuesta",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Volver a los detalles del plan",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":" Ofertas compuestas para -",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Editar",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Ofertas incluidas",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Nombre",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":" Precio",
    "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>en</b> {{ParentOfferName}}",
    "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Inscribirse",
    "PLAN_DETAILS_IS_PUBLIC":"es público",
    "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Mercados admitidos",
    "PLAN_DETAILS_PARAGRAPH_1_TEXT":"por mes",
    "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Precio de venta estimado",
    "PLAN_DETAILS_PARAGRAPH_3_TEXT":"por año",
    "PLAN_DETAILS_PARAGRAPH_4_TEXT":"una vez",
    "PLAN_DETAILS_SHARING_URL":"Compartir URL",
    "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Envió exitosamente sus cambios",
    "PLAN_IS_IN_PROGRESS_EXCEPTION":"Plan aún en progreso. Por favor verifique el estado",
    "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"El valor mínimo es -1",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Establezca el límite en -1 para requerir aprobación para todas las compras/actualizaciones de puestos.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Establezca el límite en 0 para permitir la compra de asientos ilimitados.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Establezca un número positivo distinto de cero para definir el umbral.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_4":" Cualquier compra que supere el umbral requiere la aprobación del Socio.",
    "PLAN_LEVEL_SEATLIMIT_EDIT":"Establecer el límite de asientos del producto del plan",
    "PLAN_LEVEL_SEATLIMIT_REVERT":"Volver al límite de asientos del nivel del plan",
    "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Cargue las últimas promociones de Microsoft NCE disponibles en los planes.",
    "PLAN_MACRO_DETAILS_LABEL_TEXT":"detalles de macros",
    "PLAN_MACRO_NAME_LABEL_TEXT":"Nombre de la macro",
    "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Cantidad comprada",
    "PLAN_MACRO_VALUE_LABEL_TEXT":"Valor macro",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Volver a los planes",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Gestionar ofertas compuestas",
    "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Detalles del plano -",
    "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Nuevo plan",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Cargar promociones",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Cancelar",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Cargar promociones desde proveedor",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"Entregar",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Es privado",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"¿Permitir que las suscripciones de CSP se compartan con las ofertas del plan?",
    "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Ofertas",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Estos son los pasos para anular una oferta heredada:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Marque la casilla junto a la oferta que desea anular",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Proporcionar un nuevo nombre descriptivo",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Especificar el nuevo precio de venta",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"El nuevo plan comenzaría a enumerar las ofertas anuladas junto con aquellas que se heredan pero no se anulan.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Nota:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Anulado del plan principal",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"seleccionado",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Elementos",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Complementos",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible para compra",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"ID del componente de facturación",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"Precio ERP",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Precio de lista",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Nombre de la oferta",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"AÑADIR",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Tipo de licencia",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Remover",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Precio de venta",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Actualizar precio",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":" Nombre del plan:",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":" Precio:",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Establecer nuevo precio",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Tipo de licencia",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Oferta anulada",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Oferta seleccionada",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Descripción",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Nombre del plan",
    "PLAN_MANAGE_ADD_FORM_URL_LABEL":"Compartir URL",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"planes",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Administrar",
    "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"El nombre descriptivo no puede estar vacío",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Mostrar ofertas con promoción.",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"ofertaConPromoción",
    "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"OFERTA DE PRUEBA",
    "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Nombres de los padres",
    "PLAN_NAME_IS_INVALID":"El nombre del plan no es válido",
    "PLAN_OFFER_PRICE_CHANGE_HEDER":"¿Se basa en facturas?",
    "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":" Establecer nuevo precio para",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"El cambio de precio sería efectivo.",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Nuevo precio",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Precio actual",
    "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Se actualizó exitosamente el precio de oferta del plan.",
    "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Mostrar ofertas con margen <= 0",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Mostrar ofertas con margen > 0 y < 10",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Mostrar ofertas con margen >= 30",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Mostrar ofertas con margen >= 10 y < 20",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Mostrar ofertas con margen >= 20 y < 30",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Gestionar plan",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Plan de anulación",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"planes",
    "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Volver a los planes",
    "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Editar plan",
    "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Plan de anulación",
    "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"¿Permitir que las suscripciones de CSP se compartan con las ofertas del plan?",
    "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Es privado",
    "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Descripción",
    "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Nombre del plan",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":" Proporcione el ID del componente de facturación en caso de que desee utilizar un componente existente en su sistema de facturación. Dejarlo en 0 crea un nuevo componente en su sistema de facturación.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Nota:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Asegúrese de especificar el precio que coincida con su componente en su sistema de facturación. El sistema no valida la combinación de precio y componente con el sistema de facturación por el momento.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Estos son los pasos para anular una oferta heredada:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Marque la casilla junto a la oferta que desea anular",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Proporcionar un nuevo nombre descriptivo",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Especificar el nuevo precio de venta",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"El nuevo plan comenzaría a enumerar las ofertas anuladas junto con aquellas que se heredan pero no se anulan.",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Disponible para compra",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"ID del componente de facturación",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"precio del ERP",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Nombre amigable",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Precio de lista",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Oferta anulada",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Precio de venta",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":" Elementos seleccionados para anular",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Ofertas",
    "PLAN_OVERRIDE_FORM_URL_SHARING_URL":" Compartir URL",
    "PLAN_POPUP_GRID_HEADER":"Descargar planilla cuadriculada",
    "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Este producto ya está disponible en plan, ¿quieres agregar el producto nuevamente?",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Código de moneda del plan",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Nombre del plan",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Código de moneda de destino",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Seleccione",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Código de moneda fuente",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Código de moneda de destino",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Definir nueva moneda",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Número de asientos permitidos",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Precio de venta",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Numero de asientos",
    "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Valor de asiento no válido para el producto {{PlanProductName}}",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Ocurrió un error al actualizar los asientos",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Asientos actualizados con éxito",
    "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Losas guardadas con éxito",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Editar",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Anular",
    "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "PLAN_TABLE_HEADER_TEXT_NAME":"Nombre",
    "plandetails":"Detalles del plan",
    "planproductcurrencyconversion":"Conversión de moneda",
    "PlanProductId":"PlanProductId",
    "plans":"planes",
    "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Seleccione al menos dos ofertas para crear una oferta compuesta",
    "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Oferta compuesta guardada correctamente",
    "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Oferta compuesta actualizada correctamente",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Cancelar",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Cerca",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"Entregar",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Todos los productos",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"No se encontraron registros",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Guarde o cierre la fila nueva o de edición existente.",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Ingrese un valor mayor que \" + minValue : \"Ingrese un valor entre \" + minValue + \" y \" + maxValue }}",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Ingrese un valor entre {{minValue}} y {{maxValue}}",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Por favor ingrese un valor mayor a {{minValue}}",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Detalles del contrato",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Tipo de facturación",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Costo para el socio",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Losas",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Precio de venta",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"valor máximo",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Valor mínimo",
    "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Aplicabilidad",
    "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Ya tienes una oferta de contrato en tu plan. Elimina/desactiva la oferta antes de agregar una nueva.",
    "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Contrato",
    "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Reserva",
    "PLANS_OVERRIDE_EDIT_SUCCESS":"Plan anulado actualizado correctamente",
    "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan anulado con éxito",
    "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Seleccione las ofertas que desea anular del plan base.",
    "PLANS_SAVE_SUCCESS":"Plan guardado exitosamente",
    "PLANS_UPDATE_SUCCESS":"Plan actualizado exitosamente",
    "PLEASE_ENTER_THE_REASON":"Por favor proporcione una razón.",
    "PO_NUMBER":"Número de orden de compra",
    "POLICY_ASSIGNMENT_SCOPE_TITLE":"Asignación de políticas Alcances",
    "PONUMBER_BUTTON_CLOSE":"Cerca",
    "PONUMBER_BUTTON_SKIP":"Saltar",
    "PONUMBER_BUTTON_SUBMIT":"Entregar",
    "PONUMBER_DELETE_CONFIRMATION":"¿Está seguro de que desea eliminar este número de pedido del carrito?",
    "PONUMBER_DELETE_ERROR_MESSAGE":"¡Ocurrió un error al eliminar el número de pedido del carrito!",
    "PONUMBER_DELETE_SUCCESS_MESSAGE":"¡Se eliminó el número de orden de compra solicitado con éxito!",
    "PONUMBER_PLACEHOLDER":"Ingrese el número de orden de compra",
    "PONUMBER_POPUP_TITLE":"Agregar número de orden de compra",
    "PONUMBER_TITLE":"numero postal",
    "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"¿Está seguro de que desea poner en cola todos los planes?",
    "POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "POPUP_BUTTON_TEXT_CLOSE":"Cerca",
    "POPUP_BUTTON_TEXT_CONFIRM":"Confirmar",
    "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Se perderán todas las ofertas seleccionadas según el ciclo de facturación seleccionado. ¿Quieres continuar?",
    "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Este cliente ya contiene un cupón: <b>{{CouponName}}</b>, ¿quieres reemplazarlo?.",
    "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"La eliminación del cliente de facturación también eliminará los perfiles de pago asociados. ¿Está seguro de que desea eliminar el enlace del cliente de facturación para {{CustomerName}}?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la notificación personalizada?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Notificación personalizada eliminada correctamente.",
    "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{CustomerName}}?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"Eliminar aquí resultará en la eliminación de la oferta de los planes de revendedor agregados y sus clientes no podrán comprarla más. ¿Está seguro de que desea eliminar la oferta {{distributorOffer}}?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"Se eliminó {{customOfferName}} con éxito.",
    "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"¿Está seguro de que desea eliminar esta notificación?",
    "POPUP_DELETE_HEADER_TEXT_DELETE":"BORRAR",
    "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{customOfferName}}?",
    "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"Se eliminó {{customOfferName}} con éxito.",
    "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>OFERTA DE PRUEBA</sup>",
    "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar {{planName}}?",
    "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"¿Estás seguro que deseas eliminar la cita?",
    "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la línea de artículo de cotización <b>{{ProductName}}</b>?",
    "POPUP_DELETE_SUB_HEADER_TEXT":"¿Estas seguro que quieres borrarlo?",
    "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar esta entidad etiquetada?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar el mensaje {{MessageBody | límite a: 30}}?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"¿Seguro que quieres borrar este mensaje?",
    "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"¿Está seguro de que desea eliminar la notificación del webhook?",
    "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"Notificación de webhook eliminada correctamente",
    "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"Notificación de webhook eliminada correctamente.",
    "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"¿Está seguro de que desea desactivar {{productName}}?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> es la oferta principal para el producto de prueba.<br/> Si esta oferta está deshabilitada, la oferta de prueba también se desactivará.<br/> ¿Está seguro de que desea deshabilitar < b>{{nombre del producto}}<b/>?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> es la oferta principal para el producto de prueba.<br/> Deshabilitar este producto afectará la conversión del producto de prueba en suscripción<br/> ¿Está seguro de que desea deshabilitar <b>{ {nombre del producto}}<b/>?",
    "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"¿Está seguro de que desea habilitar {{productName}}?",
    "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> la oferta principal no está activa.<br/> Al habilitar la oferta de prueba también se activará la oferta principal.<br/> ¿Está seguro de que desea habilitar <b>{{productName}} <b/>?",
    "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Se elimina la notificación de participación.",
    "POPUP_PLAN_DELETED":"Se elimina el plan {{planName}}.",
    "POPUP_REVERT_HEADER_TEXT_DELETE":"REVERTIR",
    "POPUP_REVERT_SUB_HEADER_TEXT":"¿Estás seguro de que quieres revertir?",
    "POPUP_TEXT_DEACTIVATE_PSA_LOG":"¿Está seguro de que desea desactivar los registros? No eliminamos registros en el PSA, esta operación le permitirá volver a cargar los datos más de una vez desde C3 a PSA. Tenga en cuenta que volver a cargar podría crear duplicados en PSA.",
    "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"Registros de PSA desactivados con éxito",
    "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Se perderán las ofertas seleccionadas con el ciclo de facturación actual. ¿Aún deseas continuar?",
    "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Tienes cambios sin guardar. ¿Aún quieres continuar?",
    "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar el nombre del cliente?",
    "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"¿Está seguro de que desea actualizar el nombre del revendedor?",
    "POPUP_WEB_NOTIFICATION_DELETED":"Se eliminó la notificación web {{MessageBody}}.",
    "POPUP_WEB_NOTIFICATION_DELETED2":"Se elimina la notificación web.",
    "PORTAL_ACCESS_GRANTED_TO_USER":"El acceso al portal se otorga a {{UserName}}",
    "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Seleccionar página del portal",
    "PORTAL_PAGE_NAME":"Nombre de la página del portal",
    "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Datos de uso",
    "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"El cambio de precio se aplicará desde el inicio del ciclo de carga actual de la suscripción.",
    "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Inmediatamente",
    "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"De la próxima facturación",
    "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"De la próxima renovación",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Cerca",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Cerca",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Vista previa de notificación por correo electrónico",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Vista previa de notificación por correo electrónico",
    "Price":" Precio",
    "PRICE_CHANGE_CURRENCY":"Divisa",
    "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"El valor ingresado no se ajusta a la configuración de bloqueo de precio elegida",
    "PRICE_CHANGE_NEW_PRICE":"Nuevo precio",
    "PRICE_CHANGE_OLD_PRICE":"Precio anterior",
    "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Instrucciones para la API de precios de Microsoft",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Gracias por brindar su consentimiento. La aplicación se puede configurar para acceder a los precios de Microsoft.",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones en Configuración -> Centro de socios en <b>{{applicationName}}</b>.",
    "PRICING_API_VIEW_INSTRUCTIONS_1":" para copiar el vínculo que captura el consentimiento del socio para otorgar a esta aplicación acceso al Centro de socios siguiendo la API de precios de Microsoft.",
    "PRICING_API_VIEW_INSTRUCTIONS_2":"Abra el enlace en una sesión de incógnito y complete la experiencia de consentimiento con una cuenta que sea un administrador global en su arrendatario (socio) y tenga asignada la función de agente administrador en el Centro de partners.",
    "PRICING_API_VIEW_INSTRUCTIONS_3":"Haga clic en el botón Probar para verificar si la aplicación funciona con la API de precios de Microsoft.",
    "PRICING_API_VIEW_INSTRUCTIONS_5":"Haga clic en <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Copiar enlace al portapapeles </a>",
    "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Tipo de facturación",
    "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Precio de coste",
    "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":" Nombre para mostrar",
    "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"valor máximo",
    "PRICING_SLABS_TABLE_HEADER_MINVALUE":"Valor mínimo",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Precio de venta",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Precio de venta",
    "PRICING_SLABS_TABLE_HEADER_TYPE":"Tipo de facturación",
    "PRIVACY_POLICY_BUTTON_TEXT":"POLÍTICA DE PRIVACIDAD",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Proceso de incorporación masiva de clientes completado",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Inició proceso de Incorporación Masiva de clientes",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Proceso para cargar facturas en Quickbooks completado",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Se inició el proceso para cargar facturas en Quickbooks",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Proceso para cargar facturas a Xero completado",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Iniciado proceso de carga de Facturas a Xero",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Error en el proceso de carga de facturas en Quickbooks",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Proceso de carga de Facturas a Quickbooks con éxito",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Error en el proceso de carga de facturas en Xero",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Proceso de carga de Facturas a Xero con éxito",
    "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Los elementos seleccionados han sido eliminados.",
    "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Cambiar el valor de este campo resultará en la pérdida de los elementos seleccionados.",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Tipo de facturación",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Número de días máximos permitidos para cancelación sin coste.",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Tipo de venta",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Validez",
    "PRODUCT_CANCELLATION_TEXT":"La cancelación está disponible",
    "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Actualmente estamos realizando algunos cambios en este producto. Espere a que finalicen estos procesos antes de realizar una nueva solicitud.",
    "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Seleccione una forma de obtener el informe del catálogo de productos",
    "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"Correo electrónico enviado correctamente",
    "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":" Búsqueda aplicada:",
    "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nombre del agente",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Fecha final",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Comisión(%)",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Fecha de inicio",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"ID de producto interno",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Nombre del producto",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"código SP",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Nombre del agente",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"monto de la comisión",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Código de moneda",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"código SP",
    "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"La comisión por los productos de un Cliente se calculará una vez finalizadas las facturas. Para calcular las comisiones de un Cliente, solo se tendrán en cuenta los detalles de la comisión especificados por el usuario en el Período de Facturación respectivo. Si no se especifican los detalles de la comisión en un período de facturación, se tendrán en cuenta los detalles de la comisión definidos en el período de facturación anterior y que tienen validez hasta el período de facturación de la Factura.",
    "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
    "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
    "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Catálogo de importación",
    "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Validar catálogo",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Nombre del agente",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Fecha de finalización de la comisión",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Comisión(%)",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Fecha de inicio de la comisión",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Nombre de dominio",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"ID de producto interno",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"ID de suscripción del proveedor",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"código SP",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
    "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Instrucciones",
    "PRODUCT_COULD_NOT_FOUND_TEXT":"No se pudo encontrar el tipo de producto.",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Aprobar",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Ciclo de facturación",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Cantidad actual",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Comentario del cliente",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Rechazar",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Cantidad requerida",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Límite de asientos",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Cantidad de origen",
    "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Leer menos",
    "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Leer más",
    "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
    "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Atributos del producto",
    "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
    "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importar catálogo",
    "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Validar catálogo",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Ciclo de facturación",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Categoría",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Secuencia de visualización",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"ID de oferta",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Código de producto",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Nombre del proveedor",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
    "PRODUCT_LIST_ADDON_LABEL":"Añadir",
    "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Identificación de suscripción",
    "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Cancelamos esta solicitud debido a que se actualizaron algunos cambios.",
    "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"Esperando la aprobación.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Seleccione al menos un producto activo antes de continuar.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Seleccione al menos un usuario de la lista.",
    "PRODUCT_MAPPING":"Mapeo de productos",
    "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación de los productos seleccionados?",
    "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Este producto no está disponible para su compra.",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Producto no asignado porque el arrendatario está vinculado a varios clientes",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Producto no asignado porque el arrendatario está vinculado a varios clientes o el producto ya se ignoró",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"El producto ya está mapeado",
    "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Porcentaje",
    "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Cantidad comprada",
    "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Nombre del alcance",
    "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"No puede actualizar la cantidad a un valor superior a la cantidad disponible. Si desea hacer esto, aumente la cantidad desde la vista de detalles básicos.",
    "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Puede actualizar la cantidad mediante la edición en línea y hacer clic en el botón Enviar para guardar.",
    "PRODUCT_OWNERSHIP_SUCCESS":" Propiedad actualizada exitosamente",
    "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Agregar",
    "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"volver a productos",
    "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Guardar cambios",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Introduzca un valor de etiqueta único",
    "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Etiquetas de productos guardadas con éxito",
    "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Etiquetas de productos",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Descripción",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Agregar clave de etiqueta",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Agregar valor de etiqueta",
    "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Descripción",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Clave de etiqueta",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Valor de la etiqueta",
    "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Etiquetas de productos",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Ciclo de facturación",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Categoría",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Código de moneda",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Código de mercado compatible",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Duración del mandato",
    "PRODUCT_VIEW_GRID":"Red",
    "PRODUCT_VIEW_LIST":"Lista",
    "productcatalogue":"Catálogo de productos",
    "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Se renueva/caduca (en días)",
    "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Fecha máxima de pedido",
    "PRODUCTS_REPORT_MAX_QUANTITY":"Cantidad máxima",
    "PRODUCTS_REPORT_MIN_QUANTITY":"Cantidad mínima",
    "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Panel",
    "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Perfil de cliente",
    "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Perfil",
    "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Detalles del banco",
    "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":" Tarjeta de crédito",
    "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"CCA",
    "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Cuenta bancaria",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT":" Tipo de pago",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Por favor seleccione Tipo de Pago",
    "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
    "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Perfil de pago actualizado exitosamente",
    "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Detalles de usuario",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Ciudad",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"País",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Correo electrónico",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Nombre de pila",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Apellido",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Línea 1",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Línea 2",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Estado",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Cremallera",
    "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Correo electrónico",
    "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Configuración del cliente",
    "PROFILE_HEADER_RESELLER_CONFIGURATION":"Configuración de revendedor",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Borrar",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Editar",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Eliminar perfil de pago",
    "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Establecer como perfil de pago predeterminado",
    "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Número de teléfono",
    "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de cuentas",
    "PROFILE_TAB_CONFIGURATION_SETTING":"Ajustes de configuración",
    "PROFILE_TAB_ORGANIZATION_SETUP":"Configuración de la organización",
    "PROFILE_TAB_PAYMENT_PROFILE":"Perfil de pago",
    "PROFILE_TAB_USER_DETAILS":"Detalles de usuario",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Hacer por defecto",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"Entregar",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"Ahorrar",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"Ahorrar",
    "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Ciudad",
    "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"País",
    "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Correo electrónico",
    "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Nombre de pila",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Apellido",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Línea 1",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Línea 2",
    "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Número de teléfono",
    "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Estado",
    "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Cremallera",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Dirección de Envio",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Detalles de facturación",
    "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Añadir método de pago",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Nota:",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"El nuevo perfil de pago se utilizará para todas sus transacciones posteriores.",
    "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Cambiar método de pago",
    "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Detalles del perfil de pago",
    "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Por favor agregue un método de pago.",
    "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Ya tienes un perfil de pago registrado.",
    "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Últimos 4 dígitos de tu método de pago",
    "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Método de pago",
    "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Detalles de usuario",
    "PROMOTION_ERRORS_ALERT_TEXT":"Errores de promoción",
    "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"¡No puedes comprar este artículo porque tu cuenta no está vinculada a este proveedor!",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"No se pudo generar la contraseña para el usuario administrador del cliente del proveedor",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Contraseña generada con éxito para el usuario administrador del cliente del proveedor",
    "PROVIDER_CUSTOMER_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Las compras contra inquilinos de CSP pueden fracasar debido a la falta de consentimiento del cliente. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><b class=\"red \"> Haga clic aquí para redirigir al perfil de inquilino y agregar MCA </b></a>",
    "PROVIDER_CUSTOMER_DOMAIN":"Dominio del cliente del proveedor",
    "PROVIDER_CUSTOMER_ID":"ID de cliente del proveedor",
    "PROVIDER_CUSTOMER_NAME":"Nombre del cliente del proveedor",
    "PROVIDER_DESC_MICROSOFT":"microsoft",
    "PROVIDER_DESC_MICROSOFT_NON_CSP":"Microsoft sin CSP",
    "PROVIDER_DESC_PARTNER":"Compañero",
    "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nombre del proveedor",
    "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Costo para el socio",
    "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Monto de pago",
    "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Fecha de pago",
    "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Proveedor",
    "PROVIDER_PRODUCT_ID":" Identificación del producto del proveedor:",
    "PROVIDER_RESELLER_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "PROVIDER_RESELLER_COUNTRY_CODE":"Código de país",
    "PROVIDER_RESELLER_ID":"ID de revendedor del proveedor",
    "PROVIDER_RESELLER_MPNID":"ID de MPN del revendedor del proveedor",
    "PROVIDER_RESELLER_NAME":"Nombre del revendedor del proveedor",
    "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Error de detalles",
    "PROVIDER_RESELLER_ONBOARDING_STATUS":"Estado de incorporación",
    "PROVIDER_SEARCH_DEFAULT_OPTION":"Seleccione",
    "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"microsoft",
    "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Cliente existente",
    "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Nuevo cliente",
    "providers":"proveedores",
    "provisioning":"Aprovisionamiento",
    "PROVISIONING_LABLE_TEXT":"Aprovisionamiento...",
    "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Maestro",
    "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Acuerdo Marco",
    "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Seleccione un archivo para cargar",
    "PSA_BULK_UPLOAD_FAILED_STATUS":"Error en la asignación masiva de entidades actualizada en C3.",
    "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"El mapeo de productos de compra masiva se cargó parcialmente con éxito en C3. Verifique la hoja de mapeo de entidades no válidas descargada para volver a trabajarla.",
    "PSA_BULK_UPLOAD_SUCCESS_STATUS":"El mapeo de productos de compra masiva se cargó exitosamente en C3.",
    "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Prueba de conectividad",
    "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Contrato de búsqueda",
    "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Seleccionar contrato",
    "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"Buscar servicio PSA",
    "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"Seleccionar producto PSA",
    "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"No hay acuerdo asignado para este ciclo de facturación",
    "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"No pudimos obtener la fecha de inicio del contrato de PSA, por lo tanto, no pudimos alinear",
    "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"No hay contratos asignados para este ciclo de facturación",
    "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Estos registros no caen en el período seleccionado",
    "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"Los informes de fecha de inicio, fecha de finalización y hora requieren horas de inicio y finalización o falta el valor de la tarifa de configuración. Estos son valores necesarios para crear un contrato. Actualice estos valores en las configuraciones del cliente.",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"La fecha del historial de suscripciones es anterior a la nueva fecha que se calcula después de realizar la operación de alineación con la fecha de inicio del contrato",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Al intentar alinear, no se encontraron suscripciones activas para realizar la operación de alineación.",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Seleccionar categoría",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Seleccionar clase de producto",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"Seleccionar SLA",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Seleccione subcategoría",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Seleccione UOM",
    "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inactivo",
    "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Búsqueda de clientes de PSA",
    "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Seleccionar Acuerdo PSA",
    "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Seleccionar categoría",
    "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Seleccione {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK? 'acuerdo de contrato' }}",
    "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Seleccionar cliente",
    "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Seleccionar cliente de PSA",
    "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"Seleccionar producto PSA",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Seleccione {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK? 'Servicio PSA' : 'Producto PSA' }}",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Seleccionar inquilino",
    "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"La actualización de datos está en proceso; se podrá acceder a otras opciones una vez que se complete el último proceso de datos.",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Advertencia",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Los datos de fechas anteriores no estarán disponibles después de usar la fecha filtrada para los clientes seleccionados.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Nota",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Tenga en cuenta que para cargar facturas regeneradas necesita soporte del equipo de soporte de C3.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"Establecer la fecha de inicio y la fecha de finalización aquí se reflejaría en las adiciones creadas en connectwise como fecha de inicio adicional y fecha de cancelación para todas las partidas de factura aplicables.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Si no se establece ninguna fecha de inicio y fecha de finalización, se considerarán las fechas del período de facturación de la factura.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"La lista de clientes a continuación solo contendrá los clientes que tengan facturas con estado \"Finalizado\" para el período seleccionado.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Si se selecciona la casilla de verificación <span class=\"font-italic\">(Alinear con la fecha de inicio del contrato)</span>, calcularemos el próximo período de contrato reciente para cargar. Tenga en cuenta que el próximo período de contrato reciente se calcula solo mensualmente, incluso si el período de contrato es <span class=\"font-italic\">Anual</span>.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"La fecha de inicio restringirá la consideración de los registros anteriores para la carga.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Utilice los filtros solo si está tratando de enviar datos por primera vez para un cliente. Por ejemplo: <ul><li>Registro de socios, es decir, primer paso de C3 a Autotask</li><li>Incorporación de nuevos clientes</li></ul>",
    "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Puede comenzar el proceso de carga seleccionando los clientes y haciendo clic en el botón <span class=\"bold\">Validar</span> para comenzar el proceso de validación donde se valida el historial de suscripción de los clientes seleccionados.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Sincronice con el Centro de afiliados antes de cargar",
    "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Tenga en cuenta que todas las fechas (incluidos los filtros) se considerarán en UTC.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Puede utilizar los registros haciendo clic en la página <span class=\"bold\">Ver registros</span> para seguir el progreso de la validación.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_3":"En caso de errores de validación, realice las correcciones necesarias y vuelva a intentarlo.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Una vez que la validación sea exitosa, puede proceder a <span class=\"bold\">Subir</span> los datos a Autotask.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Si la validación es exitosa, puede proceder a <span class=\"bold\">Subir</span> los datos a ConnectWise.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"Todos los datos del historial de suscripciones en C3 se enviarán a Autotask una vez. Si ocurre un error durante el proceso de carga/validación, permitimos volver a intentar la carga; de lo contrario, los datos se cargan solo una vez.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"La opción de repetir la carga solo se da en caso de error.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Haga clic en este botón para probar la conectividad a PSA antes de proceder a validar.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Solo se considerarán para la carga los nuevos registros del historial de suscripciones de clientes seleccionados, si los hay. Los clientes seleccionados pueden ser excluidos si no hay nuevos registros.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_8":"Al hacer clic en la casilla de verificación junto al título, puede validar los registros de suscripción para todos los clientes activos en el sistema.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Seleccione los clientes que desea cargar de la siguiente lista",
    "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Marque esta casilla solo si desea validar los registros de suscripción para todos los clientes activos en el sistema.",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"El acuerdo de PSA no está asignado al cliente c3",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"El cliente externo no está asignado al cliente c3",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"El producto externo no está asignado al producto c3",
    "PUBLIC_SIGNUP_ADD_TO_CART":"Añadir a la cesta",
    "PUBLIC_SIGNUP_ADMIN_PORTAL":"Portal de administración",
    "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"aplicar promociones",
    "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Solicitud de asistencia para registro público",
    "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"La pasarela de pago de autorización aún no es compatible. Póngase en contacto con el soporte para obtener ayuda.",
    "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Volver a la lista de productos",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Buscar producto",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Buscar productos",
    "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Las mejores características de su clase para ayudarlo a tener éxito.",
    "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Encabezado de párrafo de banner de registro público",
    "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Información de la cuenta",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"una vez",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"por 3 años",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"por mes",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"por año",
    "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Ver carrito",
    "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Contiene complementos",
    "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Contiene producto vinculado",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Seleccione al menos una oferta de Microsoft para comprobar",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Aplica el cupón antes de realizar el pedido.",
    "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Términos y condiciones.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Habilite los términos y condiciones de NCE para pedir productos de Microsoft NCE.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":" <a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">Términos y condiciones de NCE.</a>",
    "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"¡Tu carrito de compras está vacío!",
    "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"No dude en explorar nuestra selección para encontrar los productos que necesita. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Regrese a nuestra tienda web</strong></a> y comience a agregar artículos a su carrito.",
    "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Sitio web",
    "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Confirmar",
    "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Soporte de contacto",
    "PUBLIC_SIGNUP_CONTACTUS_URL":"Contáctenos",
    "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Seguir comprando",
    "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Acepto lo último",
    "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"No cierre este navegador hasta que se complete el proceso",
    "PUBLIC_SIGNUP_EDIT_TEXT":"Editar orden",
    "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"La pasarela de pago Ezidebit aún no es compatible. Póngase en contacto con el soporte para obtener ayuda.",
    "PUBLIC_SIGNUP_FAILED_WARNING":" Disculpas. El sistema backend no pudo configurar la cuenta debido a un error desconocido. Póngase en contacto con el soporte con el BatchID",
    "PUBLIC_SIGNUP_FAILED_WARNING1":" para ayudarte más.",
    "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Mercados admitidos",
    "PUBLIC_SIGNUP_GO_TO_CART":"Ir al carrito",
    "PUBLIC_SIGNUP_GOTO_HOME":"Ir a casa",
    "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"Inscribirse",
    "PUBLIC_SIGNUP_INVALID_QUANTITY":"Los productos en el carrito tienen cantidades no válidas",
    "PUBLIC_SIGNUP_LEARNER_PORTAL":"Portal del alumno",
    "PUBLIC_SIGNUP_LOGS_WARNING":"El procesamiento puede demorar un tiempo y el estado se informará en esta página. Si te alejas de la página o has cerrado el navegador por accidente, te mantendremos informado a través de un correo electrónico.",
    "PUBLIC_SIGNUP_NEXT":"próximo",
    "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"No hay descripción disponible",
    "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Hemos creado el carrito con éxito",
    "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"La oferta ya está disponible en el carrito",
    "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Proporcione al menos 1 cantidad",
    "PUBLIC_SIGNUP_ORGANIZATION_ID":"Identificación de la organización",
    "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"No se admite el registro ya que la pasarela de pago aún no está configurada. Póngase en contacto con el soporte para obtener ayuda.",
    "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Método de pago",
    "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Pagar ahora",
    "PUBLIC_SIGNUP_PLACE_ORDER":"Realizar pedido",
    "PUBLIC_SIGNUP_PORTLET_HEADER":"Inscribirse",
    "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Carro",
    "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Revisar carro",
    "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":" Comercio",
    "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Detalle",
    "PUBLIC_SIGNUP_QTY":"CANTIDAD",
    "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"La cantidad debe ser mayor que 0",
    "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"Se requiere cantidad",
    "PUBLIC_SIGNUP_QUICK_CART":"Cerrar carro",
    "PUBLIC_SIGNUP_QUICK_VIEW":"Vista rápida",
    "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"Al hacer clic en Aceptar, los productos NCE agregados anteriormente se eliminarán, ya que los productos NCE de diferentes regiones del mercado no se pueden comprar al mismo tiempo.",
    "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Volver a Detalles",
    "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Aplicar filtros",
    "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Limpiar filtros",
    "PUBLIC_SIGNUP_SIDE_CART":"Carro",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"complementos",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"complementos",
    "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Precio estimado",
    "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":" Producto vinculado",
    "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Remover",
    "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"Regístrese y compre",
    "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"¿Ya tiene un inquilino de MS existente?",
    "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Espere mientras configuramos su cuenta. Puede continuar usando su dispositivo con normalidad. Le notificaremos por correo electrónico una vez que se complete la configuración.",
    "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Saltar y continuar",
    "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Pagar ahora",
    "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"¡Registro exitoso! Ahora puede iniciar sesión en el portal C3 con las credenciales enviadas a",
    "PUBLIC_SIGNUP_SUPPORT":"Apoyo",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Términos y condiciones de registro público 1",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Términos y condiciones de registro público 2",
    "PUBLIC_SIGNUP_TERMS_OF_USE":"Términos de Uso",
    "PUBLIC_SIGNUP_TEXT_ACH":"CCA",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Ordenar A - Z",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Ordenar Z - A",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Ordenar por",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Precio: de mayor a menor",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Precios de barato a caro",
    "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Introduce el código de cupón",
    "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Las ofertas seleccionadas no son compatibles para el país seleccionado, así que vuelva a verificar las ofertas seleccionadas.",
    "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Se renueva.",
    "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Expira el.",
    "PURCHASE_PRODUCT_MAPPING":"Comprar mapeo de productos",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Comprar los artículos de la línea de cotización automáticamente.",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Si desmarcamos la casilla de verificación después de aprobar la cotización, el socio o distribuidor debe comprar los productos manualmente en nombre del cliente.",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Si desmarcamos la casilla de verificación después de aprobar la cotización, el socio o distribuidor debe comprar los productos manualmente en nombre del cliente.",
    "PurchaseCurrency":"Compra de moneda",
    "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Mapeo de productos comprados",
    "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Complementos",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Expira el",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"pedido el",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Nombre del producto",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Cantidad comprada",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"se renueva el",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Estado",
    "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Editar cantidad",
    "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"El intento de reconciliar la diferencia fracasó. Por favor, póngase en contacto con el soporte para obtener ayuda.",
    "QUANTITY_MISMATCH_ISSUE_FIXED":"La conciliación de cantidades se completó correctamente",
    "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Recuento esperado",
    "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Es aplicable",
    "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Recuento de mensajes",
    "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"nombre de la cola",
    "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Suplantación rápida",
    "QUICK_IMPERSONATION_HEADER_TEXT":"Los siguientes usuarios están disponibles para suplantación, elija cualquiera para continuar",
    "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Personificar",
    "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Comportamiento",
    "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Role",
    "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Usuario(s)",
    "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Actividades",
    "QUICK_SIDEBAR_LABEL_ALERTS":"Alertas",
    "QUICK_SIDEBAR_LABEL_MORE":"Más",
    "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Notificaciones",
    "QUICK_SIDEBAR_LABEL_SETTINGS":"Ajustes",
    "QUICK_SIDEBAR_LABEL_USERS":"Usuarios",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Gracias por brindar su consentimiento para QuickBooks. La aplicación se puede configurar para acceder a QuickBooks siguiendo la nueva pauta de seguridad. Puede publicar las facturas de sus clientes en QuickBooks.",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones para cargar facturas en QuickBooks en <b>{{applicationName}}</b>.",
    "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Espere mientras procesamos su consentimiento de QuickBooks",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"El siguiente enlace se abre en una nueva pestaña. Proporcione su consentimiento con sus credenciales de QuickBooks. Si recibe un error al brindar su consentimiento, comuníquese con nuestro equipo de soporte.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":" para proporcionar el consentimiento de QuickBooks",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Si ya ha proporcionado su consentimiento, haga clic en el botón <b> Validar token </b> para verificar la conectividad con QuickBooks.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en este botón para verificar la validez del token de actualización de QuickBooks existente.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Requisitos previos para que el socio publique facturas de clientes en <b> QuickBooks Online </b>",
    "QUICKBOOKS_POST_BATCH_ID":"ID de lote de publicación de Quickbooks",
    "QUICKBOOKS_POST_LOGS_STATUS":"Estado de los registros de publicaciones de Quickbooks",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"El token de actualización de QuickBooks existente no es válido. Por favor proporcione su consentimiento nuevamente",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"El token de actualización de QuickBooks existente es válido. Puedes continuar subiendo Facturas",
    "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Detalles de la cotización",
    "QUOTE_ALL_SELECTION":"Seleccionar todo",
    "QUOTE_APPROVAL_CONFIRMATION":"¿Está seguro que desea aprobar la cotización?",
    "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Cotización aprobada y pedido realizado exitosamente.<br/> El ID del pedido es <b>{{OrderNumber}}</b>",
    "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Cotización aprobada exitosamente.",
    "QUOTE_AUDIT_LOG_DELETED_BY":"Eliminado por",
    "QUOTE_AUDIT_LOG_ID":"Identificación de cita",
    "QUOTE_AUDIT_LOG_NAME":"Nombre de la cita",
    "QUOTE_AUDIT_LOG_QUOTE_NAME":"Nombre de la cita",
    "QUOTE_BREADCRUMB_QUOTE_ADD":"Crear cotizaciones",
    "QUOTE_BREADCRUMB_QUOTE_EDIT":"Editar citas",
    "QUOTE_BREADCRUMB_QUOTE_LIST":"Citas",
    "QUOTE_BUTTON_TEXT_AGE":"edad",
    "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Volver a la lista de cotizaciones",
    "QUOTE_BUTTON_TEXT_CANCEL":"Cancelar",
    "QUOTE_BUTTON_TEXT_CLOSE":"Cerca",
    "QUOTE_BUTTON_TEXT_COPY":"Copiar",
    "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"Escribe un correo electrónico con cita",
    "QUOTE_BUTTON_TEXT_SUBMIT":"Entregar",
    "QUOTE_BUYER_INFO":"Información del comprador",
    "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Información para compradores",
    "QUOTE_CHOOSE_BUYER_DETAILS":"Seleccione los datos de contacto del comprador que desea incluir en la cotización.",
    "QUOTE_CHOOSE_SELLER_DETAILS":"Verifique los detalles sobre usted y su empresa que se mostrarán en la cotización.",
    "QUOTE_COMMENTS_TO_BUYERS":"Comentarios",
    "QUOTE_COMPANY":"Compañía",
    "QUOTE_CONTACT":"Contacto",
    "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Se pueden agregar hasta tres contactos de cotización.",
    "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Error al copiar el texto:",
    "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL copiada exitosamente",
    "QUOTE_COUNTERSIGNATURE":" Contrafirma",
    "QUOTE_CREATE_A_LINE_ITEM":"Crear una línea de artículo",
    "QUOTE_CREATED":"Cita creada el",
    "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Información de la partida",
    "QUOTE_CUSTOM_LINE_ITEM_SAVE":"Ahorrar",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Facturación",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Frecuencia de facturación",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Período de facturación",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Descripción",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Fecha de vencimiento",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Fecha final",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Fecha de la factura",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"número de factura",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"es imponible",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Nombre",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":" Precio",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Tipo de venta",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Fecha de inicio",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Precio unitario",
    "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Introduzca un valor con hasta dos decimales.",
    "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"No se puede aprobar la cotización porque el cliente ha sido eliminado.",
    "QUOTE_DATE":"Fecha",
    "QUOTE_DOWNLOAD_AS_PDF":" Descargar como PDF",
    "QUOTE_EMAIL_ERROR":"Error en el correo electrónico de cotización",
    "QUOTE_EMAIL_MODAL_HEADER":"Texto del correo electrónico de cotización",
    "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Se produjo un error al guardar el nombre de la cotización y el cliente porque ya hay una cotización en estado de borrador o publicada.",
    "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Se produjo un error al guardar el cliente que seleccionó. Ya tiene una cotización en estado borrador o publicada.",
    "QUOTE_ERROR_NAME_NOT_UNIQUE":"Se produjo un error al guardar la cita porque el nombre de la cita no es único; ya existe otra cita con el mismo nombre en estado publicado o de borrador.",
    "QUOTE_EVENT_ERROR":"Error",
    "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Por favor, introduzca la fecha de caducidad",
    "QUOTE_EXPIRES":"La cotización vence el",
    "QUOTE_INCLUDE":"Incluido en la cotización",
    "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Contactos",
    "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Seleccione cliente",
    "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Comentarios",
    "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Contrafirma",
    "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Código de moneda",
    "QUOTE_LABEL_TEXT_EMAIL":"Correo electrónico",
    "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Fecha de expiración",
    "QUOTE_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Incluir espacio para una firma escrita",
    "QUOTE_LABEL_TEXT_LAST_NAME":"Apellido",
    "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Sin firma",
    "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
    "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Condiciones de compra",
    "QUOTE_LABEL_TEXT_QUOTE_NAME":"Nombre de la cita",
    "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Sus cambios han sido guardados",
    "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Opciones de firma",
    "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Incluir espacio para una contrafirma",
    "QUOTE_LABEL_TEXT_TEMPLATE":"Plantilla",
    "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Agregar cliente",
    "QUOTE_LABEL_TEXT_WEB_LINK":"Copie el enlace de la página web a continuación para compartirlo con posibles clientes o elija escribir un correo electrónico con la cotización incluida.",
    "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Por favor, rellene todos los campos obligatorios antes de continuar.",
    "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Agregar un artículo de línea personalizado",
    "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Agregar producto al plan",
    "QUOTE_LINE_ITEM_HEADER":"Artículo de línea",
    "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Descuento total",
    "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Total parcial",
    "QUOTE_LINE_ITEM_LABEL_TAX":"Impuesto total",
    "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Valor total de la cotización",
    "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Se permite agregar un máximo de 50 productos.",
    "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Se pueden añadir un máximo de diez cantidades.",
    "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Se pueden agregar hasta 9999 artículos de línea",
    "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Se requiere cantidad mínima de uno.",
    "QUOTE_LINE_ITEM_SUB_HEADER":"Revise la partida que se mostrará en su cotización",
    "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Total parcial",
    "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Cantidad comprada",
    "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Los impuestos mostrados son aproximados y se finalizarán al momento de generar la factura.",
    "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Tipo de impuesto",
    "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Descuento total",
    "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Valor total de la cotización",
    "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Añade un mínimo de un artículo.",
    "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Guarde los cambios en la línea de cotización antes de continuar.",
    "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Productos para cotizar",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Precio de coste",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Precio de venta",
    "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Elementos de línea",
    "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Resumen",
    "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Planes para clientes",
    "QUOTE_LIST_BREADCRUMB_QUOTE":"Lista de cotizaciones",
    "QUOTE_LIST_STATUS_PUBLISHED":"Publicado",
    "QUOTE_MOVE_BACK_TO_STATE":"Anterior",
    "QUOTE_MOVE_TO_STATE":"próximo",
    "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"El nombre de la cita debe tener menos de 150 caracteres.",
    "QUOTE_NAME_REQUIRED_ERROR":"Por favor ingrese el nombre de la cotización",
    "QUOTE_ONE_TIME_SUBTOTAL":"Subtotal único:",
    "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Aprobar cotización",
    "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Eliminar cita",
    "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Editar cita",
    "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Ver cotización",
    "QUOTE_OWNER":"Propietario de la cita",
    "QUOTE_PDF_PURCHASE_TERM":"Condiciones de compra",
    "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Planes asignados al cliente",
    "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Por favor, introduzca la dirección de correo electrónico.",
    "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Por favor ingrese el primer nombre.",
    "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Por favor ingrese el apellido.",
    "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Por favor, introduzca el número de teléfono.",
    "QUOTE_PREPARED_BY":"Preparado por:",
    "QUOTE_PRICE_SUMMARY":"Resumen",
    "QUOTE_PRICE_TOTAL":"Total",
    "QUOTE_PRINTED_NAME":"Nombre impreso",
    "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Nombre del plan",
    "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Nombre del producto",
    "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Propiedades",
    "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Precio de venta",
    "QUOTE_READY_FOR_REVIEW":"Cotización lista para revisión",
    "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Revisar y publicar",
    "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Información del vendedor",
    "QUOTE_SENDER":"Remitente de la cita",
    "QUOTE_SIGNATURE":"Firma",
    "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Firma",
    "QUOTE_STATUS":"Estado",
    "QUOTE_STATUS_APPROVED":"Aprobado",
    "QUOTE_STATUS_DELETED":"Eliminado",
    "QUOTE_STATUS_DRAFT":"Borrador",
    "QUOTE_STATUS_EXPIRED":"Venció",
    "QUOTE_STATUS_OOPS_ERROR":"¡Ups!",
    "QUOTE_STATUS_ORDERPROCESSED":"Pedido procesado",
    "QUOTE_STATUS_PUBLISHED":"Publicado",
    "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"No podemos encontrar esa página.",
    "QUOTE_TABLE_HEADER_TEXT_ACTION":"Acción",
    "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Fecha de Creación",
    "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Fecha de expiración",
    "QUOTE_TABLE_HEADER_TEXT_NAME":"Nombre",
    "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Monto",
    "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Propietario de la cita",
    "QUOTE_TABLE_HEADER_TEXT_STATUS":"Estado",
    "QUOTE_TABLE_LABELS_USER_ROLES":"Role",
    "QUOTE_TABLE_LABELS_USERS":"Clientes)",
    "QUOTE_TABLE_PRICE":"Precio unitario",
    "QUOTE_TABLE_PRODUCT_SERVICE":"Producto y servicio",
    "QUOTE_TABLE_QUANTITY":"Cantidad comprada",
    "QUOTE_TEXT_EMAIL_DETAILS":"Hola, <br/><br/>Su cotización está lista para su revisión.<br/><br/>Detalles clave:<br/><br/>Nombre de la cotización: {{quoteName}}<br/><br/>Válida hasta: {{expirationDate}} <br/><br/>Puede acceder y revisar su cotización utilizando el siguiente enlace<br/><br/>{{quoteURL}}<br/><br/>",
    "RCON_APPLY_PROMOTIONS_NCE":"Aplicar promoción",
    "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":" Volver a implementaciones de RD",
    "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Implementar nuevo RD",
    "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Desplegar",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Detalles",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"Configuración de RD",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Agregar nombre de dominio",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Clave de administrador",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Nombre de usuario administrador",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Nombre de implementación",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Prefijo de etiqueta DNS de puerta de enlace",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Nombre de la dirección IP pública de la puerta de enlace",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"SKU de imagen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Seleccionar SKU de imagen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Ubicación",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Seleccionar Ubicación",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Número de instancias Rd",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Grupo de recursos",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Suscripción",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Seleccionar suscripción",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"Tamaño de máquina virtual",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Seleccionar SKU de imagen",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"Implementaciones de RD",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Implementar nuevo RD",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":" Actualizar el estado de implementación",
    "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"Implementaciones de RD",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Administrar",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Implementado en",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Nombre de implementación",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Ubicación",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Grupo de recursos",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Estado",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Suscripción",
    "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":" Volver a Implementaciones de RD",
    "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Detalles de implementación",
    "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Detalles",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Nombre de implementación",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Ambiente",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Implementado en",
    "RDS_DEPLOYMENT_STARTED_SUCCESS":"La implementación de RD comenzó exitosamente",
    "READ_THROUGH":"Lea atentamente los términos y condiciones que se proporcionan a continuación.",
    "REASON_FOR_FAILURE":"Motivo del fracaso",
    "REASON_FOR_UPDATE":"Motivo de la actualización",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Error al recargar el informe de conciliación",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":" El informe de conciliación se recargó correctamente",
    "RECON_CUSTOMER_REPORT_RELOADING":"Recargando informe de conciliación",
    "RECON_PRODUCT_INPROVISION_TOOLTIP":"El producto está en estado de provisión",
    "RECON_PRODUCT_ONHOLD_TOOLTIP":"El producto está esperando aprobación",
    "RECON_REPORT_COLLAPSE_ALL":"Desplegar todo",
    "RECON_REPORT_EXPAND_ALL":"Expandir todo",
    "RECON_REPORT_MISMATCH_COUNT_BADGE":"Desajustes",
    "RECON_REPORT_RELOAD":"Informe de conciliación recargado",
    "RECON_REPORT_RELOAD_INITIAT_FAIL":"Error al iniciar la recarga del informe de conciliación",
    "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Se ha iniciado la recarga del informe de conciliación.",
    "RECON_REPORT_SEARCH_BUTTON_TEXT":"Búsqueda",
    "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Buscar por oferta, ID de suscripción",
    "RECON_REPORT_SHOW_FIXED_INACTIVE":"Mostrar fijo Inactivo",
    "RECON_REPORT_SHOW_MISMATCH":"Mostrar solo discrepancias",
    "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" ID de suscripción de Azure:",
    "RECON_TABLE_TEXT_BILLING_CYCLE":" Ciclo de facturación :",
    "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Suscripción de proveedor",
    "RECON_TABLE_TEXT_REGION":" Región :",
    "RECON_TABLE_TEXT_RESERVATION_ID":" Identificación de la reserva:",
    "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" ID de orden de reserva:",
    "RECON_TABLE_TEXT_SUBSCRIPTION_ID":" Suscripción:",
    "RECON_TABLE_TEXT_TERM_DURATION":" Validez :",
    "RECON_TABLE_TEXT_TRIAL_OFFER":" Oferta de prueba",
    "RECONCILIATION_CUSTOMER_NAME":"Nombre del cliente",
    "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"PROVEEDOR",
    "RECONCILIATION_MISSMATCH":"Error de coincidencia de conciliación",
    "RECONCILIATION_NOTES":"Notas",
    "RECONCILIATION_NOTES_MISMATCH":" Los elementos en <span class='text-danger'>rojo</span> indican una discrepancia",
    "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Los artículos en <span class='text-mediumvioletred'>rojo violeta</span> ​indican una discrepancia relacionada con la promoción; póngase en contacto con el equipo de soporte para transmitir el beneficio promocional al cliente final.",
    "RECONCILIATION_NOTES_QUANTITY_MISMATCH":" Los artículos en <span class='text-danger'>rojo</span> indican una discrepancia en la cantidad",
    "RECONCILIATION_NOTES_RI_INVOICE":"Luego de la incorporación de una instancia reservada, todas las reservas adicionales que incluyan la misma oferta dentro del ID de pedido de reserva incorporado se facturarán al precio incorporado.",
    "RECONCILIATION_NOTES_SUSPENDED":" Los elementos <span class='strike-through'>tachados</span> indican suscripciones suspendidas",
    "RECONCILIATION_PROVIDER":"Proveedor",
    "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Monto del pago",
    "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"¿Está seguro de que desea registrar un pago de <%= amount%> (<%= currencyCode%>) contra el cliente?<br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error.</small>",
    "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Código de moneda",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Seleccionar cliente",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Clientes",
    "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Se requiere que el cliente registre el pago.",
    "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Pago anticipado récord",
    "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Observaciones",
    "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Código de moneda",
    "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Seleccionar cliente",
    "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Pago registrado exitosamente",
    "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Fecha de pago",
    "RECORD_ID":"Identificación de registro",
    "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"ID de registro recibido de un servicio externo",
    "RECORD_MANUAL_PAYMENT_CONFIRMATION":"¿Está seguro de que desea registrar un pago por <%= currencySymbol%> <%= amount%> en esta factura?<br/><small> Póngase en contacto con el servicio de asistencia en caso de que necesite más ayuda con los pagos que no aparecen o se registraron por error.</small>",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Nombre de la entidad",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Nombre",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importar",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Pagos de importación",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Plantilla",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catálogo",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Descargar plantilla",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Pagos de importación",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Error",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Éxito",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"Identificación C3",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Código de moneda",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Monto del pago",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Fecha de pago",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Nombre",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Observaciones",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Validar catálogo",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Error",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Éxito",
    "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"El monto del pago debe ser mayor a 0.00.",
    "RECORD_PAYMENT_PAID_DATE":"Fecha de pago",
    "RECURRENCES_MAX_ERROR":"El valor debe ser menor o igual a 11",
    "RECURRENCES_MIN_ERROR":"El valor debe ser mayor o igual a 0",
    "RECURRENCES_TOOLTIP":"Número de veces que se puede aplicar el cupón de descuento",
    "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"ACTUALIZAR LISTA DE ENTIDADES EXTERNAS EN LOCAL",
    "REFRESH_TOKEN":"Actualizar ficha",
    "REFRESH_TOOLTIP_TEST_PAYMENT":"Actualizar",
    "REGISTER_CAPTION_TEXT_THANK_YOU":"Gracias por elegir <span class=\"bold lowercase font-blue\" style=\"cursor:pointer\" >{{PortalName}}.</span> Complete la siguiente información para procesar su nueva suscripción.",
    "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Si tiene una cuenta de Microsoft existente que no es nuestra, <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">contáctenos</a> para obtener ayuda.",
    "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Por favor ingresa un cupón válido",
    "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos.",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Correo electrónico",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Proporcione una identificación de correo electrónico válida. Le enviaremos un correo electrónico con nombre de usuario y contraseña una vez completado el registro.",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Nombre de pila",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Apellido",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Número de teléfono",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Ciudad",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Nombre de empresa",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Línea 1",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Línea 2",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Nombre de dominio principal",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":" Para comenzar, debe crear una cuenta con el nombre de su empresa seguido de .onmicrosoft.com. Si su organización tiene su propio dominio personalizado que desea usar sin .onmicrosoft.com, puede cambiarlo más adelante en el Portal de administración de Office 365.",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Qué es esto",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Estado",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Cremallera",
    "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Fecha de acuerdo",
    "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
    "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Acepto lo último",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Configura tu plan",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Para cada oferta en el plan que desea agregar, seleccione una cantidad mínima de 1",
    "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Contacto primario",
    "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Perfil de la empresa",
    "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Acuerdo de cliente de Microsoft",
    "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"CCA",
    "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Cuenta bancaria",
    "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Tarjeta de crédito",
    "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Eliminar cupón",
    "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Por favor ingresa un cupón válido",
    "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Código de cupón aplicado: <b>{{CouponCode}}</b>",
    "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Tipo de pago",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Por favor seleccione Tipo de Pago",
    "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Resumen del plan",
    "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Tarifa recurrente (cada 1 mes)",
    "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"luego <span class='bold'>${{totalCount}} </span>en la primera renovación el {{nextBillingDate}}",
    "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Total",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Nota:",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Los cargos por la compra se prorratean para el resto de los días del ciclo de facturación actual.",
    "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Licencias",
    "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"¡Debe especificar si la cuenta ACH es una cuenta comercial o no!",
    "REGISTER_REGISTERUSER_CREATED_USER":"Usuario creado exitosamente",
    "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"No se puede comprobar la disponibilidad del dominio",
    "REGISTER_REGISTERUSER_VALID_DOMAIN":"Por favor ingrese un nombre de dominio válido. Los nombres de dominio sólo pueden contener letras y números; sin guiones, puntos ni guiones bajos.",
    "REGISTER_REGISTRATION_STATUS_CAPTION":"estado de registro",
    "REGISTER_REGISTRATION_STATUS_HEADER":"Su registro ha sido procesado exitosamente. Inicie sesión en el portal utilizando las siguientes credenciales:",
    "REGISTER_REGISTRATION_STATUS_LINE_1":"Nombre de usuario",
    "REGISTER_REGISTRATION_STATUS_LINE_2":"Clave",
    "REGISTER_REGISTRATION_STATUS_LINE_3":"Se le envió un correo electrónico con los detalles anteriores en caso de que no los haya copiado desde aquí.",
    "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Procesar mi pedido",
    "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Por favor proporcione el motivo del rechazo.",
    "REJECT_ALL_PRODUCT_POPUP_BUTTON":"rechazar todo",
    "REJECT_ALL_PRODUCT_POPUP_HEADER":"Rechazar todos los productos",
    "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Rechazar todos los productos",
    "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Por favor explique el motivo del rechazo de la solicitud.",
    "REJECT_PRODUCT_COMMENT_HEADER":"Rechazar producto",
    "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Por favor indique las razones por las que este producto debería ser rechazado.",
    "REJECT_PRODUCT_POPUP_BUTTON":"Rechazar",
    "REJECTED_BY":" Rechazado por",
    "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Por favor ingrese un motivo para rechazar este producto",
    "RELEASE_PRODUCT_OWNERSHIP":"Liberar propiedad del producto",
    "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Error en la liberación de propiedad del producto",
    "RELEASE_SEED_ERROR":" Solo puedes reducir la cantidad mientras liberas, inténtalo de nuevo.",
    "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"Intenté actualizar más de la cantidad disponible para \"{{childName}}\"",
    "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"Intenté actualizar más de la cantidad disponible al padre \"{{parentName}}\" para \"{{childName}}\"",
    "RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
    "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'>Se produjo un error al procesar los datos de <b> {{firstName}} </b>. El error es: {{errorMessage}} </span><br/>",
    "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'>Registros procesados correctamente para <b> {{firstName}} </b></span><br/>",
    "RELOAD_RECONCILIATION_STATUS":"Recargar estado de conciliación",
    "RELOAD_TOOLTIP_REVENUE":"Recargar",
    "reLoadingCustomersProfileData":"Sincronización de perfiles de clientes de proveedores",
    "REMOVE_ALL_SELECTED_RGS":"¿Quiere eliminar todos los grupos de recursos seleccionados?",
    "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Remover",
    "REMOVE_PARTNER_PRODUCT_ICON":"Remover",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Quitar producto",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"¿Estás seguro de que deseas eliminar el producto?",
    "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"¿Está seguro de que desea revocar el acceso de este usuario?",
    "RENEWAL_ALL_DAYS":"Todo",
    "RENEWAL_DAYS_TEXT":"Días",
    "REPORT_ACTIONS_DOWNLOAD_TEXT":"Descargar",
    "REPORT_ACTIONS_SEND_EMAIL_TEXT":"Enviar correo electrónico",
    "REPORT_ACTIONS_TEXT":"Comportamiento",
    "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"todos los clientes",
    "REPORT_FILE_INVALID_EMAILS_ERROR":"Por favor introduzca un correo electrónico válido",
    "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Este campo es obligatorio",
    "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
    "REPORT_FILE_TYPES_SUB_HEADER":"Seleccione un formato de archivo",
    "REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Seleccione Periodo",
    "REPORT_INTERVAL_TEXT_CUSTOME":"Disfraz",
    "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"últimos 12 meses",
    "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"últimos 3 meses",
    "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"últimos 6 meses",
    "REPORT_INTERVAL_TEXT_TILL_DATE":"Hasta la fecha",
    "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe de facturas y cuotas",
    "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Volver al informe de uso",
    "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Ver historial de carga",
    "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Ver historial de carga",
    "REPORT_POPUP_ACTIONS_TEXT":"Comportamiento",
    "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Descargar",
    "REPORT_POPUP_EMAIL_TEXT":"Correo electrónico",
    "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Seleccionar todo",
    "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"Enviar correo electrónico",
    "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Seleccione una forma de obtener el informe del historial de suscripciones",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Ingrese las direcciones de correo electrónico de los destinatarios, separadas por comas (,) o punto y coma (;) y haga clic en \"Enviar\" para recibir un archivo que contiene la lista del informe del historial de suscripciones.",
    "REPORT_SELECT_CUSTOMER_All":"todos los clientes",
    "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Seleccionar cliente",
    "REPORT_SELECT_RESELLER_ALL":"Todos los revendedores",
    "REPORT_SEND_EMAIL_TEXT":"Correo electrónico",
    "REPORT_TEXT_FROM":"De",
    "REPORT_TEXT_TO":"A",
    "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtrado por :",
    "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"El costo para el socio es obligatorio si el tipo de facturación es porcentual y opcional para el tipo de facturación unitario.",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Descargar catálogo",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Catálogo",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importar",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Plantilla",
    "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Descargar",
    "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Se produjo un error al descargar el archivo.",
    "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Descargar plantilla",
    "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Descargar historial de carga",
    "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Importar catálogo",
    "REPORT_USAGE_INSTRUCTIONS_PART_1":"Descargue el catálogo para obtener ID de productos y clientes de ofertas de socios basadas en el uso.",
    "REPORT_USAGE_INSTRUCTIONS_PART_2":"Descargue la plantilla y complete el detalle de uso de los productos en el catálogo.",
    "REPORT_USAGE_INSTRUCTIONS_PART_3":"Asegúrese de que los datos cumplan con las siguientes reglas: <br />&nbsp;&nbsp <b>Unidades</b> : Número <br />&nbsp;&nbsp <b>Unidad de medida</b> : Texto <br />&nbsp; &nbsp <b>CostToPartner</b> : Decimal <br />&nbsp;&nbsp <b>CurrencyCode</b> : Texto (código de moneda estándar ISO.) <br />&nbsp;&nbsp <b>UsageDate</b> : Fecha (AAAA-MM-DD)",
    "REPORT_USAGE_INSTRUCTIONS_PART_4":"Haz clic en <strong class=\"text-uppercase\">Seleccionar</strong> y busca el archivo.",
    "REPORT_USAGE_INSTRUCTIONS_PART_5":"Haga clic en <strong class=\"text-uppercase\">Validar entrada</strong> para que el sistema valide la entrada.",
    "REPORT_USAGE_INSTRUCTIONS_PART_6":"Revise los errores en la tabla después de completar la validación.",
    "REPORT_USAGE_INSTRUCTIONS_PART_7":"Corrija los errores en el archivo y vuelva a cargarlo.",
    "REPORT_USAGE_INSTRUCTIONS_PART_8":"Si no hay errores de validación en la entrada, se habilita el botón <strong class=\"text-uppercase\">Importar catálogo</strong>. Haga clic en el botón para enviar sus cambios.",
    "REPORT_USAGE_NOTE":"Nota :",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Éxito",
    "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Costo en socio",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Divisa",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Identificación del cliente",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Nombre del cliente",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"error de importación",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"Estado de importación",
    "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"identificación de producto",
    "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Unidad de medida",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Unidades",
    "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Fecha de uso",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Error de validacion",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Estado de validación",
    "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Validar entrada",
    "REPORT_USER_LICENSE_ASSIGNMENT":"El informe de asignación de licencia de usuario se enviará al correo electrónico proporcionado.",
    "reportusage":"Informe de uso",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Para solicitar una relación de revendedor con un cliente, copie y pegue el siguiente texto, incluida la URL, en un correo electrónico. Edite el texto si es necesario y envíe el correo electrónico a su cliente.",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Incluya privilegios de administración delegada para Azure Active Directory y Office 365.",
    "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"Texto de correo electrónico",
    "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Solicitud de relación de revendedor",
    "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Solicitud de incorporación masiva iniciada por",
    "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"La solicitud de carga de promociones está en cola",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Solicitud para cargar facturas en Quickbooks iniciada por",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"La solicitud para cargar facturas en Xero la realiza",
    "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Actualización de estado solicitada de la oferta de NI",
    "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Solicitud para cargar facturas en QuickBooks aceptada",
    "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Solicitud para cargar facturas en Xero aceptada",
    "REQUESTED_PRODUCT_STATUS":"Estado solicitado",
    "requestForResellerRelationship":"Solicitud de relación de revendedor",
    "REQUIRED_FIELD_TEXT":"Este campo es obligatorio",
    "REQUIRED_PATTERN_PHONE":"Solo se permiten números y +",
    "REQUIRED_PATTERN_TEXT":"No se permiten caracteres especiales como `~!@#{}|/<>'",
    "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Se produjo un error al exportar las líneas de pedido de la factura del revendedor",
    "RESELLER":"(Revendedores)",
    "Reseller":"Revendedores",
    "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Ajustamiento",
    "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"está sujeto a impuestos",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Agregar",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Seleccionar todo",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Lista de complementos",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Añadir más productos",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Eliminar plan",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Revisar ofertas de planes",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Guardar plan",
    "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Búsqueda aplicada",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Búsqueda rápida",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Categoría",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"proveedores",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Tipos de servicio",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Duración del mandato",
    "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Éxito",
    "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Precio actualizado exitosamente",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Uso",
    "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"No se encontraron productos",
    "RESELLER_AZURE_MACRO_MACRO_TYPE":"Macro",
    "RESELLER_AZURE_MACRO_PRICE_TYPE":" Precio",
    "RESELLER_AZURE_MACRO_VALUE":"Valor macro",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"revendedores",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos del revendedor",
    "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"volver a la lista",
    "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"volver a revendedores",
    "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Vincular nuevo proveedor",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Agregar revendedor",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Nuevo",
    "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Existente (múltiple)",
    "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Agregar revendedor",
    "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Detalles de contacto",
    "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Tipo de pago",
    "RESELLER_CAPTION_TEXT_RESELLER":"revendedores",
    "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Perfil de facturación de revendedor",
    "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Detalles del revendedor",
    "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Perfil de revendedor",
    "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Habilite esta opción para aplicar tarifas de procesamiento a los pagos con tarjeta de crédito.",
    "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"¿Incluye tarifa de procesamiento de tarjeta de crédito?",
    "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Porcentaje de cargo por procesamiento de tarjetas de crédito.",
    "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Tarifa de procesamiento de tarjetas de crédito %",
    "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Marcar esta bandera evita que los clientes reduzcan la cantidad de suscripción",
    "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Permitir que el revendedor inicie la transacción de su(s) factura(s)",
    "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Seleccione si desea aplicar la promoción a revendedor o no",
    "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Permitir a los clientes administrar suscripciones de Azure",
    "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permita que los clientes definan grupos a través de suscripciones de Azure",
    "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Margen (%) que se aplicará al uso de Azure del revendedor. Por ejemplo: ingrese 1.15 si el margen de beneficio debe ser del 15%.",
    "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Para referencia interna. Esta identificación se puede utilizar para relacionar al cliente con un registro en su sistema de contabilidad interno.",
    "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Día del mes en que las facturas se aprueban automáticamente",
    "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Día del mes en que los pagos se procesan automáticamente",
    "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Día del mes en que los pagos se procesan automáticamente",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Fecha de vencimiento de las facturas",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"No de días vencidos para el pago de la factura",
    "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Día del mes en el que las facturas se finalizan automáticamente",
    "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente la dirección recién agregada como la dirección predeterminada para el revendedor.",
    "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el correo electrónico recién agregado como el correo electrónico predeterminado para el revendedor.",
    "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el número de teléfono recién agregado como el número de teléfono predeterminado para el revendedor.",
    "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará en las partidas individuales de la factura única del revendedor.",
    "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones perpetuas de software del revendedor",
    "RESELLER_CONFIG_DESC_PO_NUMBER":"Definir número de orden de compra para revendedor",
    "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Habilitar la función para transmitir facturas al servicio de contabilidad externo",
    "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Marque esta bandera si desea que el sistema procese pagos (CC/ACH según corresponda)",
    "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Recargo (%) que se aplicará en la reserva de Azure del revendedor.",
    "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"El revendedor recibirá el correo electrónico con los detalles de la factura una vez que se apruebe la factura y si las notificaciones por correo electrónico están habilitadas",
    "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"El revendedor recibirá una notificación por correo electrónico con respecto a las facturas que tienen montos cero",
    "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones de software de revendedor.",
    "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"No podemos procesar esta solicitud porque las transacciones están deshabilitadas.",
    "RESELLER_CONFIG_DESC_TAX_CODE":"Definir código fiscal para revendedores",
    "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a las suscripciones de terceros revendedores.",
    "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Permitir al cliente reducir asientos",
    "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Permitir que el revendedor inicie la transacción",
    "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Aplicar promoción al revendedor",
    "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"¿Habilitar Azure?",
    "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"¿Habilitar Azure para empresas?",
    "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Marcado para uso de Azure",
    "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"ID de referencia de facturación",
    "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Día en que los revendedores podrán ver las facturas",
    "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Día para pago automático",
    "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Día para pago automático",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Fecha de vencimiento de las facturas",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Días de vencimiento de facturas",
    "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Día en que se finalizan las facturas.",
    "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Asignar automáticamente una nueva dirección como predeterminada",
    "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Asignar automáticamente un nuevo correo electrónico como predeterminado",
    "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Asignar automáticamente un nuevo número de teléfono como predeterminado",
    "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Marcado para partidas individuales de facturas únicas de Microsoft",
    "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Marcado para software perpetuo",
    "RESELLER_CONFIG_DISP_PO_NUMBER":"Número de orden de compra",
    "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Registrar facturas en servicio de contabilidad externo",
    "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"¿Pago automático de facturas?",
    "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
    "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Enviar correos electrónicos de facturas automáticamente",
    "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Enviar facturas de importe cero",
    "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de software",
    "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Detener todas las transacciones",
    "RESELLER_CONFIG_DISP_TAX_CODE":"Código de impuestos",
    "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para terceros",
    "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorar al revendedor del servicio externo",
    "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Los números de factura comenzarán con este texto",
    "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Texto del número de factura",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Permite al usuario ver las cuotas impagas hasta la factura actual.",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Mostrar cuotas impagas en la factura",
    "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"La configuración del revendedor se revirtió exitosamente",
    "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"La configuración del revendedor se guardó correctamente",
    "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"No tienes acceso para editar",
    "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Configuración de revendedor actualizada exitosamente",
    "RESELLER_CONFIGURATION_OF":"Configuración de revendedor de",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Vista rápida de los ingresos de su negocio",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilidad",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Resumen de sus cuentas por cobrar",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Cuenta por cobrar",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Pagos aún por cobrar",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Cuotas pasadas",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Tus clientes activos",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Clientes",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Alerta sobre una factura pendiente",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Factura",
    "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Ya existe un revendedor en el sistema con una identificación de mpn determinada. Por favor verifique la identificación de mpn proporcionada.",
    "RESELLER_HEADER_TEXT_BANK_DETAILS":"Detalles del banco",
    "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Detalles de la tarjeta de crédito",
    "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Por favor seleccione el tipo de pago",
    "RESELLER_IMPERSONATE":"Personificar",
    "RESELLER_IMPERSONATE_USER_ROLE":"personificar",
    "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"hacerse pasar por su propio rol",
    "RESELLER_INVOICE":"Factura",
    "RESELLER_INVOICE_TEXT_LINE":"La factura se enviará por correo a su dirección de facturación. ¡Gracias!",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Estado de la factura actualizado correctamente",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Pago registrado exitosamente",
    "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
    "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Facturas",
    "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Facturas",
    "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Volver al resumen de ingresos y costos",
    "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Facturas",
    "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Partidas de factura",
    "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Número de cuenta",
    "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Tipo de cuenta",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Dirección Línea 1",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Línea de dirección 2",
    "RESELLER_LABEL_TEXT_BANK_NAME":"Nombre del banco",
    "RESELLER_LABEL_TEXT_CARD_NUMBER":"Número de tarjeta",
    "RESELLER_LABEL_TEXT_CITY":"Ciudad",
    "RESELLER_LABEL_TEXT_COMPANY_NAME":"Nombre de empresa",
    "RESELLER_LABEL_TEXT_COUNTRY":"País",
    "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Tipo de tarjeta de crédito",
    "RESELLER_LABEL_TEXT_CVV":"CVV",
    "RESELLER_LABEL_TEXT_EMAIL":"Correo electrónico",
    "RESELLER_LABEL_TEXT_FIRST_NAME":"Nombre de pila",
    "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"es cuenta comercial",
    "RESELLER_LABEL_TEXT_LAST_NAME":"Apellido",
    "RESELLER_LABEL_TEXT_MONTH":"Mes",
    "RESELLER_LABEL_TEXT_MPN_ID":"Identificación del MPN",
    "RESELLER_LABEL_TEXT_NAME":"Nombre",
    "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Nombre en la cuenta",
    "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Nombre en la tarjeta",
    "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Número de teléfono",
    "RESELLER_LABEL_TEXT_PLAN_ID":"Plan",
    "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Número de ruta",
    "RESELLER_LABEL_TEXT_STATE":"Estado",
    "RESELLER_LABEL_TEXT_YEAR":"Año",
    "RESELLER_LABEL_TEXT_ZIP":"Cremallera",
    "RESELLER_LIST_PAYMENT_METHOD":"Método de pago",
    "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
    "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Recargo total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de terceros.",
    "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de software",
    "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Marcado para suscripción de terceros",
    "RESELLER_NAME_UPDATE_FAILURE":"Error al actualizar el nombre del revendedor",
    "RESELLER_NAME_UPDATE_SUCCESS":"Actualización exitosa del nombre del revendedor",
    "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"El revendedor no está asignado a ningún administrador de cuentas",
    "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"No se encontró ningún revendedor en CSP. Por favor verifique la identificación de mpn proporcionada.",
    "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"Errores detectados en los datos subidos. Descargue los errores, corríjalos e intente cargarlos nuevamente.",
    "RESELLER_ONBOARD_UPLOAD_ERROR":"Se produjo un error al analizar el archivo cargado",
    "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Mencione si los detalles de ACH están relacionados con una cuenta comercial",
    "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"ID de MPN del revendedor",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Correo Electrónico de Facturas",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"País",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"IDENTIFICACIÓN",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"identificador MPN",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Nombre",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Éxito",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":" Detalles de pago guardados",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Perfil de pago eliminado",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Perfil de pago Establecido como predeterminado",
    "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"¿Está seguro de que desea eliminar esta opción de pago?",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Definir macro y guardar con todas las ofertas",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Agregar ofertas faltantes",
    "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Guardar plan con todas las ofertas",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ciclos de facturación seleccionados",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Categorías seleccionadas",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Categorías de proveedores seleccionados",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Proveedores seleccionados",
    "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Mercados admitidos",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Categorías",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Uso de macros",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Macro",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Valor macro de uso",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Valor macro",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Categorías de proveedores",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"proveedores",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Tipos de servicio",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Se acepta la solicitud para clonar el plan de revendedor y se está procesando",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Se acepta la solicitud para crear un nuevo plan de revendedor con todas las ofertas y se está procesando",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Ajuste de precios",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Seleccione",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Aplicar macro para definir los nuevos precios",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Copie los precios del plan de revendedor de origen",
    "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Envió exitosamente sus cambios",
    "RESELLER_PLAN_DISABLE_BUTTON":"Desactivar el plan",
    "RESELLER_PLAN_ENABLE_BUTTON":"Habilitar el plan",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"La oferta se suspendió con éxito",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Descontinuar la oferta",
    "RESELLER_PLAN_SELECTED_OFFERS":" Ofertas seleccionadas",
    "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"planes",
    "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Cancelar",
    "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Plan de clonación",
    "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Creación de planes",
    "RESELLER_PLANS_CAPTION_1":"Agregar nuevo plan",
    "RESELLER_PLANS_CAPTION_2":"Detalles del plano -",
    "RESELLER_PLANS_CAPTION_TEXT_PLANS":"planes",
    "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Vista",
    "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Precios y ofertas",
    "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Descargar lista de precios y ofertas",
    "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Precios y ofertas",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"Precio ERP",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Tipo de acuerdo de licencia",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Precio de lista",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Nombre de la oferta",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Válida desde",
    "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Se produjo un error al exportar la lista de ofertas y precios de revendedor",
    "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Perfil de la organización",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Cancelar",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Borrar",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Editar",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"Ahorrar",
    "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Perfil",
    "RESELLER_PROFILE_CUSTOMER_PROFILE":"Perfil de cliente",
    "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Añadir dirección",
    "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"Agregar correo electrónico",
    "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Añadir teléfono",
    "RESELLER_PROFILE_PARTNER_ADDRESS":"Direcciones",
    "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Detalles básicos",
    "RESELLER_PROFILE_PARTNER_CANCEL":"Cancelar",
    "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"nombre de empresa",
    "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"Correos electrónicos",
    "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Nombre de pila",
    "RESELLER_PROFILE_PARTNER_LAST_NAME":"Apellido",
    "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Detalles básicos actualizados exitosamente",
    "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"¡No se encontraron registros!",
    "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Números de teléfono",
    "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"¿Está seguro de que desea eliminar este registro?",
    "RESELLER_PROFILE_PARTNER_SUBMIT":"Entregar",
    "RESELLER_PROFILE_PAYMENT_ADD":"Agregar",
    "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Cuenta bancaria",
    "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Tarjeta de crédito",
    "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"No se encontraron perfiles. Agregue un nuevo perfil de pago haciendo clic en el botón AGREGAR.",
    "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Perfiles de pago",
    "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Fecha de creación de la cuenta:",
    "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Dirección de Envio",
    "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"Fecha de incorporación C3",
    "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Perfil de revendedor",
    "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Dirección de envío",
    "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Cuentas de usuario",
    "RESELLER_PROFIT_BILLED_AMOUNT":"Monto facturado al revendedor",
    "RESELLER_PROFIT_COST_ON_PARTNER":"Costo para el distribuidor",
    "RESELLER_PROFIT_COST_ON_RESELLER":"Costo para el revendedor",
    "RESELLER_PROFIT_CUSTOMER_NAME":"Monto del cliente",
    "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Nombre del revendedor",
    "RESELLER_PROFIT_PRODUCT_NAME":"Nombre del producto",
    "RESELLER_PROFIT_PROFIT_AMOUNT":"Beneficio del distribuidor",
    "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Monto facturado por revendedor",
    "RESELLER_PROFIT_RESELLER_NAME":"Nombre del revendedor",
    "RESELLER_PROFIT_SUMMARY_REPORT":"Descargar informe",
    "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"No hay datos disponibles para descargar",
    "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Todo",
    "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Ver por revendedor",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Beneficio del revendedor",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"% de beneficio del distribuidor",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"% de beneficio del revendedor",
    "RESELLER_RADIO_BUTTON_TEXT_NO":"NO",
    "RESELLER_RADIO_BUTTON_TEXT_YES":"SÍ",
    "RESELLER_REGISTRATION_SUCCESS":"El registro de revendedor se completó exitosamente.",
    "RESELLER_RELATIONSHIP_LBL":"Comparta el siguiente contenido con el cliente para establecer una relación de revendedor.",
    "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Seleccionar revendedor",
    "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Vista por",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Por favor ingrese el porcentaje de impuesto válido",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Por favor complete los campos nombre del impuesto",
    "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Agregar notificación por correo electrónico de nuevo evento",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"etiqueta de socio eliminada exitosamente",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"etiqueta de socio actualizada correctamente",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"No hay proveedores configurados, comuníquese con el soporte",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"No hay claves disponibles para este proveedor",
    "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Revendedor: suplantación rápida",
    "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"No se ha encontrado ningún mercado para el código de moneda elegido",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Configuración de revendedor",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Configuración de revendedor",
    "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Asignar ofertas",
    "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Editar",
    "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Personificar",
    "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Administrar enlaces de proveedores",
    "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Creado en",
    "RESELLER_TABLE_HEADER_TEXT_NAME":"Nombre",
    "RESELLER_TABLE_SIGNUP_DATE":"fecha de registro",
    "resellers":"revendedores",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Factura",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Reconciliación",
    "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Revendedor de carga de prueba",
    "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Revendedor agregado exitosamente.",
    "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Pagos de revendedor",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Detalles del administrador de la cuenta",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Administrar planes",
    "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"Correos electrónicos",
    "RESEND_INVOICE":"Reenviar factura",
    "RESEND_INVOICE_HEADER_TEXT":"Ingrese los ID de correo electrónico para reenviar la factura.",
    "RESEND_INVOICE_NOTE":"<b>Nota:</b> Si desea reenviar la factura a varios ID de correo electrónico, proporcione los ID separándolos con una coma. Ej: abc@mail.com,nuevo@mail.com",
    "RESEND_INVOICE_USERS_LIST":"Reenviar lista de usuarios de facturas",
    "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Precio al por menor (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Costo para el socio (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Precio de venta (%)",
    "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Ingrese el porcentaje de RI válido",
    "RESET_VALUE":"Restablecer valor",
    "Resolved":"Resuelto",
    "RESOURCE_GROUP_NAME":"Nombre del grupo de recursos",
    "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"El pago se ha iniciado con éxito. Tomará algún tiempo para procesar. Por favor, compruebe el estado después de algún tiempo.",
    "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Cantidad facturada",
    "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"Descargar facturas masivas",
    "REVENUE_AND_COST_SUMMARY_CATEGORY":"Categoría",
    "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Costo en socio",
    "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Cliente",
    "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Factura del distribuidor",
    "REVENUE_AND_COST_SUMMARY_PROVIDER":"Proveedor",
    "REVENUE_AND_COST_SUMMARY_RESELLER":"Revendedores",
    "REVENUE_AND_COST_SUMMARY_SALETYPE":"Tipo de venta",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Deudas",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"facturas y cuotas",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Pagos",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Fallos",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Vencidos",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"historial de suscripciones",
    "REVENUE_AND_COST_SUMMARY_VIEWBY":"Vista por",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Generación de factura completada",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Se produjo un error al generar las facturas de los clientes.",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Facturas de clientes generadas exitosamente",
    "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Detalles de pago del proveedor",
    "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Cargando detalles de pago del proveedor...",
    "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Regenerar factura",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Ver facturas",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Ver historial de suscripción",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Monto",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Período de facturación",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Cliente",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Costo en socio",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Importe de beneficio",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"costo para el cliente",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Costo para el socio",
    "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Lucro",
    "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Recibe todas las facturas por correo electrónico",
    "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Detalles de pago del proveedor",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Categoría",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Cliente",
    "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Duración",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Último período de facturación",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 períodos de facturación",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 períodos de facturación",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 períodos de facturación",
    "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":" ¿Estás seguro de que quieres revocar la asignación?",
    "RFRESH_DATA_FROM_PSA":"Actualizar datos de PSA",
    "RIBBON_TITLE_DEPARTMENT":"departamento",
    "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Ingrese la cantidad para al menos una oferta de plan de nivel superior.",
    "ROLE_ASSIGNMENT_EXCEPTION":"Complete los datos del usuario",
    "ROLE_ASSIGNMENT_SCOPE_TITLE":"Asignación de roles Ámbitos",
    "ROLE_NAME_C3_SUPPORT":"Soporte C3",
    "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Gerente de cuenta de cliente",
    "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"El usuario podrá acceder a tarjetas y menús que incluyan productos.",
    "ROLE_NAME_CUSTOMER_ADMIN":"Administrador de clientes",
    "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"El usuario puede ver y realizar todas las acciones disponibles en el portal del cliente",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Administrador de clientes (para ventas)",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Administrador de clientes (para ventas)",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Administrador de clientes Lite",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"El usuario tiene todos los permisos de administrador de clientes, pero no podrá comprar nuevas suscripciones",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Administración de clientes Lite Plus",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"El usuario tiene todos los permisos de Customer Admin Lite, pero no podrá administrar productos con un plazo de 1 año o más",
    "ROLE_NAME_CUSTOMER_MAINTENANCE":"Mantenimiento del cliente",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Mantenimiento del cliente",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Un rol para mantener la aplicación por cliente",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Un rol para mantener la aplicación por cliente",
    "ROLE_NAME_CUSTOMER_READER":"Lector de clientes",
    "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"El usuario solo puede leer los datos disponibles en el portal del cliente",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Superadministrador de clientes",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Superadministrador de clientes",
    "ROLE_NAME_DEPARTMENT_ADMIN":"Administrador del departamento",
    "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Quién puede administrar el Departamento y solo puede administrar productos bajo los Departamentos Específicos.",
    "ROLE_NAME_DEPARTMENT_READER":"Lector de departamento",
    "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Quien sólo puede leer los datos disponibles en los Departamentos Específicos.",
    "ROLE_NAME_DIRECT_ADMIN":"Administrador directo",
    "ROLE_NAME_FINANCE_ADMIN":"Administrador de finanzas",
    "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Puede gestionar páginas relacionadas con las finanzas.",
    "ROLE_NAME_GROUP_ADMIN":"Administrador del grupo",
    "ROLE_NAME_HELP_DESK":"Mesa de ayuda",
    "ROLE_NAME_HELP_DESK_DESCRIPTION":"Mesa de ayuda",
    "ROLE_NAME_INDIRECT_ADMIN":"Administrador indirecto",
    "ROLE_NAME_PARTNER_MAINTENANCE":"Mantenimiento de socios",
    "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Un rol para mantener la aplicación por socio",
    "ROLE_NAME_SITE_ADMIN":"Administrador del sitio",
    "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Quién puede administrar el Sitio y solo puede administrar productos en los Sitios Específicos.",
    "ROLE_NAME_SITE_READER":"Lector del sitio",
    "ROLE_NAME_SITE_READER_DESCRIPTION":"Quien solo puede leer los datos disponibles en el Sitio.",
    "ROLE_NAME_SYSTEM_ADMIN":"Administrador del sistema",
    "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Puede acceder/cambiar todas las páginas del portal.",
    "ROLE_NAME_SYSTEM_READER":"Lector del sistema",
    "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Puede visitar todas las páginas pero no puede realizar ningún cambio.",
    "ROLE_NAME_USER":"Usuario",
    "ROLE_NAME_USER_ADMIN":"Administrador de usuarios",
    "ROLE_NOTE_C3_SUPPORT":"El soporte C3 puede resolver situaciones de discrepancia de cantidades y es un súper conjunto de permisos",
    "ROLE_NOTE_CUSTOMER_ADMIN":"El administrador del cliente puede ver todos los datos y realizar todas las acciones en el contexto del inquilino",
    "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"El administrador de clientes (para ventas) puede ver y realizar acciones en los datos, pero no en las suscripciones de socios.",
    "ROLE_NOTE_CUSTOMER_READER":"Lectores de clientes que tienen acceso de solo lectura a todos los datos relacionados con el cliente.",
    "ROLE_NOTE_DIRECT_ADMIN":"El administrador directo podría hacer cualquier cosa que un administrador del sistema pueda hacer, pero solo para los clientes directos, por lo que no hay acceso a las partes de Venta indirecta.",
    "ROLE_NOTE_FINANCE_ADMIN":"Los administradores de finanzas pueden acceder al resumen de ingresos y costos del cliente, definir el porcentaje de impuestos y ver el informe de conciliación.",
    "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins puede administrar todas las cosas relacionadas con las suscripciones de Azure en su grupo.",
    "ROLE_NOTE_HELP_DESK":"Los usuarios de la mesa de ayuda pueden ver todos los detalles del cliente, incorporar nuevos clientes y brindar soporte. Los usuarios de la mesa de ayuda pueden hacerse pasar por los datos de los clientes y acceder a ellos.",
    "ROLE_NOTE_INDIRECT_ADMIN":"El administrador indirecto podría hacer todo lo que un administrador del sistema puede hacer, pero solo para los revendedores (y sus clientes), por lo que no hay acceso a las partes de Venta directa.",
    "ROLE_NOTE_SYSTEM_ADMIN":"El administrador del sistema puede ver todos los datos y realizar todas las acciones en el contexto de socios y clientes.",
    "ROLE_NOTE_SYSTEM_READER":"Lectores del sistema que tienen acceso de solo lectura a todos los datos relacionados con el socio. Los lectores no pueden hacerse pasar por",
    "ROLE_NOTE_USER_ADMIN":"El administrador de usuarios puede ver y realizar algunas acciones en los datos, pero no en las suscripciones.",
    "RowNum":"Número de fila",
    "SALE":"Venta",
    "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Editar precio de venta",
    "SALE_PRICE_EMPTY_ERROR":"Por favor, introduzca un precio válido",
    "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Precio de venta mayor que ERP Pice",
    "SALE_PRICE_LESS_THAN_LIST_PRICE":"Precio de venta inferior al precio de lista",
    "SALE_TYPE_DESC_PRODUCT":"Producto",
    "SALE_TYPE_DESC_SERVICE":"Servicio",
    "SalePrice":"Precio de venta",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Intentando guardar el código de autenticación recibido de Quickbooks",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Intentando guardar el código de autenticación recibido de Xero",
    "SAVE_CART_EXCEPTION":"Se produjo un error al agregar el producto al carrito",
    "SAVE_COUPON_FAILURE":"No se pudo guardar el cupón",
    "SAVE_COUPON_SUCCESS":"Cupón guardado exitosamente",
    "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Se produjo un error al guardar la conversión de moneda.",
    "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Las columnas seleccionadas se guardaron correctamente como favoritas.",
    "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Se produjo un error al guardar o actualizar la lista de destinatarios de notificaciones por correo electrónico",
    "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al guardar los detalles del impuesto.",
    "SAVE_PLAN_PRODUCT_SLAB":"Losas de oferta de contrato guardadas",
    "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"No se pudieron guardar losas de oferta de contrato",
    "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan creado con éxito",
    "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Los detalles de pago del proveedor se guardaron correctamente.",
    "SAVE_RESELLER_PAYMENT_PROFILE":"Guardar perfil de pago de revendedor",
    "SAVE_RESELLER_PLAN":"Guardar plan de revendedor",
    "SAVE_RESELLER_PLAN_FAILURE":"No se pudo guardar el plan de revendedor",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Definición del tipo de cambio del producto del plan de revendedor",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"No se pudo definir el tipo de cambio del producto del plan de revendedor",
    "SAVE_SUB_TAX":"Ahorre subimpuestos",
    "SAVE_SUB_TAX_FAILURE":"Error al guardar subimpuestos",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Nuevo ticket creado exitosamente",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Boleto actualizado exitosamente",
    "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Programar un pedido",
    "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Por favor seleccione una fecha",
    "SCHEDULE_ORDER_CHECKBOX_TEXT":"Acepto programar un pedido para una fecha futura",
    "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Selecciona una fecha",
    "SCHEDULE_ORDER_EVENT":"Orden de programación",
    "SCHEDULE_ORDER_FAILED_EVENT":"Orden de programación fallida",
    "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"Fecha de Creación",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Tipo de destinatario",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Valor del destinatario",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Recetantes",
    "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"ID de registro",
    "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Reportar nombre",
    "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Resultado",
    "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Estado",
    "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Borrar",
    "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Editar",
    "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Informe_artículo_línea_factura_programada",
    "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Entrega de informe de artículo de línea de factura programada",
    "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Elementos_de_línea_de_factura_programados_con_informe_de_comision",
    "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Artículos de línea de factura programada con entrega de informe de comisión",
    "SCHEDULED_INVOICE_SUMMARY_REPORT":"Informe_resumen_de_factura_programado",
    "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"ProgramadaFacturaResumenInformeEntrega",
    "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Cancelar",
    "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Borrar",
    "SCHEDULED_PROFITABILITY_REPORT":"Informe_de_rentabilidad_programado",
    "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Entrega programada del informe de rentabilidad",
    "SCHEDULED_RENEWAL_CHANGES":"Cambios de renovación programados",
    "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"¿Quieres aplicar la promoción a este producto?",
    "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"A la espera de la aprobación",
    "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Activo",
    "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Cancelado",
    "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Terminado",
    "SCHEDULED_RENEWAL_STATUS_FAILED":"Ha fallado",
    "SCHEDULED_RENEWAL_STATUS_ONHOLD":"En espera",
    "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Volver a los detalles básicos",
    "SCHEDULED_RENEWALS_HEADER_TEXT":"Renovaciones programadas",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Comportamiento",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Alinear fecha de finalización",
    "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Razon de cancelacion",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Ciclo de facturación",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Nuevo SKU",
    "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Cantidad comprada",
    "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Cita agendada",
    "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Estado",
    "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Término",
    "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"Términos y condiciones",
    "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Aceptado",
    "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Partida de factura",
    "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Partidas de factura con comisión",
    "SCHEDULED_REPORT_INVOICE_SUMMARY":"Resumen de factura",
    "SCHEDULED_REPORT_PROFITABILTY":"Informe de Rentabilidad",
    "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Tipo de destinatario",
    "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Destinatarios",
    "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Informe",
    "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Partidas de impuestos",
    "SCHEDULED_REPORT_TAX_SUMMARY":"Resumen de impuestos",
    "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Informe_de_artículos_de_línea_de_impuestos_programados",
    "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Entrega de informe de artículos de línea de impuestos programados",
    "SCHEDULED_TAX_SUMMARY_REPORT":"Informe_de_resumen_de_impuestos_programado",
    "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Entrega de informe de resumen de impuestos programado",
    "SCHEDULNG_LODING_MESSAGE_TEXT":"El proceso de carga de la oferta elegible desde el Centro de socios suele tardar unos 30 segundos. Por favor no actualice la página.",
    "SCORE":"Puntaje",
    "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Buscar por evento",
    "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Acciones de búsqueda",
    "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Buscar por nombre de cliente",
    "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Buscar por nombre de dominio",
    "SEAT_LIMIT_EXCEED":"Límite de asientos excedido",
    "SELECT_A_SOURCE_CURRENCY":"Seleccione la moneda de origen",
    "SELECT_A_SUBSCRIPTION_TEXT":"Seleccione una suscripción",
    "SELECT_AGENT":"Agente",
    "SELECT_ALL_CUSTOMER":"Todos los clientes",
    "SELECT_ALL_RESELLER":"Todos los revendedores",
    "SELECT_ALL_RG":"¿Quiere seleccionar todos los grupos de recursos?",
    "SELECT_COUPON":"Seleccionar cupón",
    "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Seleccionar código de moneda",
    "SELECT_CUSTOMER":"Seleccionar cliente",
    "SELECT_DATE_RANGE":"Seleccione el rango de fechas",
    "SELECT_DATE_VALUE":"Seleccione fecha",
    "SELECT_DEFAULT_OPTION_SELECT":"Seleccione",
    "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Seleccionar cliente",
    "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Seleccionar evento",
    "SELECT_DEPARTMENT":"Seleccionar departamento",
    "SELECT_DEPARTMENTS":"Seleccionar departamento",
    "SELECT_EDU_SEGMENT":"Seleccionar segmento educativo",
    "SELECT_EVENT":"Seleccionar evento",
    "SELECT_MESSAGE_TYPE":"Seleccionar tipo de mensaje",
    "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Por favor seleccione al menos una factura para pagar",
    "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Opciones rápidas para definir tu precio de venta",
    "SELECT_PARTNER_INVOICE":"Facturas de socios",
    "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Seleccionar evento",
    "SELECT_PLAN_TOOLTIP_TEXT":"Seleccionar plan",
    "SELECT_PORTAL_PAGE":"Seleccione la página del portal",
    "SELECT_RESELLER_AZURE_MACRO":"Seleccione una macro",
    "SELECT_RESELLER_AZURE_MACRO_TYPE":"Seleccionar tipo de macro",
    "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Seleccione el tipo de precio de venta",
    "SELECT_SITE":"Seleccionar sitio",
    "SELECT_SITES":"Seleccionar sitio",
    "SELECT_STATUS":"Seleccionar estado",
    "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Seleccione la moneda de destino",
    "SELECT_TYPE":"Seleccione tipo",
    "selectAddressType":"Seleccione el tipo de dirección",
    "selectCountry":"Seleccione país",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Clientes seleccionados para cargar facturas en Quickbooks",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Clientes seleccionados para cargar facturas a Xero",
    "SELECTED_FILTER":"Seleccionar filtro",
    "SELECTED_FILTER_ALL":"Seleccionar todo",
    "selectEmailType":"Seleccione el tipo de correo electrónico",
    "selectPhoneType":"Seleccione el tipo de teléfono",
    "selectState":"Seleccione estado",
    "SELL_DIRECT_ICON_TITLE":"Iconos de Estocolmo / Compras / Box2",
    "sell-direct":"Vender Directo",
    "sell-indirect":"Vender Indirecto",
    "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Ingrese la dirección de correo electrónico a quien desea enviar un correo electrónico, ingrese con una coma (,) o punto y coma (;) y haga clic en Enviar para recibir un archivo que contiene una lista del catálogo de productos.",
    "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Reenviar factura",
    "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Enviar",
    "SEND_MAIL_BUTTON":"Enviar correo",
    "SEPTEMBER":"Septiembre",
    "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Dominio del cliente del proveedor",
    "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ID de cliente del proveedor de servicios",
    "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Nombre del cliente del proveedor de servicios",
    "SERVICE_PROVIDER_TENANT":"Inquilinos proveedores",
    "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Inquilino predeterminado",
    "SERVICE_PROVIDER_TENANT_HEADER":" Inquilinos proveedores de",
    "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Comportamiento",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"identificación del inquilino",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Nombre del inquilino",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"¿Está seguro de que desea marcar <b> {{customerName}}</b> como inquilino predeterminado?",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"Actualizado {{customerName}} como inquilino predeterminado",
    "SESSION_EXPIRED_ERROR_MESSAGE":"Su sesión ha caducado. Inicie sesión nuevamente para continuar. Desafortunadamente, todos los cambios no guardados se perderán.",
    "SESSION_TIMEOUT_WARNING":"Advertencia de tiempo de espera de sesión",
    "SESSION_WARNING_DURATION":"Su sesión expirará en {{secondsLeft}} segundos.",
    "SET_NEW_MACRO":"Establecer nueva macro",
    "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"El estado del lote de clientes a bordo en masa está establecido en Completo",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Intentando establecer el estado de los registros de publicaciones de servicios externos en Errorignored",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"No se pudo establecer el estado de los registros de publicaciones de servicios externos en ErrorIgnored",
    "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"Plantilla de correo electrónico",
    "settings":"Ajustes",
    "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificaciones de eventos por correo electrónico",
    "SETTINGS_HEADING_TEXT_BILLING":"Facturación",
    "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"Configuración de administración de ConnectWise",
    "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Conversión de Moneda",
    "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Valores predeterminados del cliente",
    "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Eliminar plan",
    "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"Notificaciónes de Correo Electrónico",
    "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Configuraciones del cliente (global)",
    "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Valores predeterminados del socio",
    "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Gestión de usuarios",
    "SETTINGS_LOGO_SELECT_A_LOGO":"Seleccione un logotipo",
    "SETTINGS_PASSWORD_HELP_TEXT":"Deje el campo vacío si no desea actualizar la contraseña.",
    "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Autotarea",
    "SETTINGS_TAB_HEADING_CONNECTWISE":"Administrar ConnectWise",
    "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Vistas personalizadas",
    "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"General",
    "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logotipos",
    "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Centro de socios",
    "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"proveedores",
    "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Registro público",
    "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
    "SETTINGS_TAX_NAME_TOOLTIP":"Puede especificar cualquier nombre de impuesto, por ejemplo: GST.",
    "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Puede especificar el porcentaje de impuestos aquí. Por ejemplo: 15 por 15%",
    "SHIP_TO":"Envie a",
    "SHOP_ADDED_TO_CART_TEXT":" añadido al carrito",
    "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"Añadir a la cesta",
    "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"¡No se encontraron productos!",
    "SHOP_PAGE_LINK_TEXT_READ_MORE":"Leer más..",
    "SHOP_TILE_HYPERLINK_READ_MORE":"Leer más..",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Mostrar la configuración del cliente en el portal del cliente",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Permitir al cliente gestionar configuraciones.",
    "SHOW_NON_ACTIVE_OFFERS":"Mostrar ofertas deshabilitadas",
    "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
    "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Mostrar ofertas que no están disponibles para el cliente.",
    "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Mostrar las ofertas que no tienen macro",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Mostrar la configuración del revendedor en el portal del revendedor",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Permitir que el revendedor administre las configuraciones",
    "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Iconos de Estocolmo / Comunicación / Agregar usuario",
    "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Iconos-de-estocolmo / Comunicación / Grupo",
    "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo-/-General-/-Notificación2",
    "SIDEBAR_ANALYZE_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Calculadora",
    "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"margen azul",
    "SIDEBAR_CONTENT_ALL_COMMENTS":" Permite al socio revisar los comentarios proporcionados para varias entidades (artículo del carrito, facturas, pedidos) por parte de los clientes y responderlos.",
    "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Lista de administradores de cuentas y opciones para administrar los clientes asociados a ellos.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Administrar avisos web a nivel de entidad",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Cree y administre notificaciones personalizadas para diversos eventos de administración de productos/licencias e incorporación de clientes.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Comparta información en tiempo real con socios y clientes a través de transmisiones interactivas en sus respectivos paneles.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Cree y administre avisos web en varios niveles de entidad para informar sobre el estado del sistema, facturas y otras notificaciones importantes",
    "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor es una característica basada en recomendaciones que ayuda a los socios a adoptar las mejores recomendaciones de optimización de costos.",
    "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Informe sobre los datos de facturación de Azure",
    "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Le permite actualizar el margen de beneficio del Plan Azure de los últimos meses y cargar datos de facturación con nuevos valores de margen.",
    "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Revise los detalles de consumo de su suscripción de Azure.",
    "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Vistazo rápido al informe de resumen de costos",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Vistazo rápido a los análisis de clientes",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Mostrar lista de licencias consumidas por el inquilino",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Informe basado en PowerBI para ver el uso de Azure en múltiples dimensiones",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Administre el perfil de su organización, direcciones, perfiles de pago y más...",
    "SIDEBAR_CONTENT_MENU_DASHBOARD":"Proporciona a los socios un conocimiento profundo de los clientes y su negocio en diversas dimensiones.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Revise los cambios de su licencia en fechas personalizadas.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Vistazo rápido al resumen de consumo de licencias",
    "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Descripción rápida de la asignación de licencias y los asientos no asignados.",
    "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Administre y cree múltiples derechos de Azure (suscripciones) bajo el plan de Azure de forma masiva",
    "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Vistazo rápido a los análisis de incorporación de clientes de sus revendedores indirectos.",
    "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Informes detallados de la comisión de ventas a nivel de agente y la cantidad ganada",
    "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Defina cupones de descuento que se pueden aplicar a nivel de plan/cliente/servicio.",
    "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Guía práctica del producto para socios y clientes finales",
    "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":" Permite que el socio vea y descargue los detalles de uso de los productos registrados fuera de la solución C3.",
    "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Permite que el socio cargue códigos/atributos de productos que se desean en los informes de elementos de línea de factura.",
    "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Permite que el socio apruebe/rechace las solicitudes de compra de productos de los clientes.",
    "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Cargue valores de nombre de agente, productos asociados con ventas, porcentaje de comisión y varios otros atributos de comisión",
    "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Le ayuda a crear columnas personalizadas para agregarlas a su informe de elementos de línea de factura.",
    "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Permite que el socio cargue detalles de uso registrados fuera de C3 para productos en el catálogo para la generación de facturas.",
    "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Proporciona varios informes sobre sus datos que se encuentran en C3.",
    "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Le ayuda a administrar (crear/editar) planes con ofertas que desea vender a revendedores indirectos estableciendo sus márgenes personalizados.",
    "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Esta función ayuda a los socios a programar varios informes con diferentes frecuencias.",
    "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Revise el uso de su suscripción de Azure con los costos asociados.",
    "SIDEBAR_CONTENT_MENU_USERS":"Administrar el acceso a los usuarios",
    "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analice la rentabilidad de su negocio en múltiples dimensiones.",
    "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Vea el consumo de Azure a nivel de grupo de recursos/recursos/etiquetas.",
    "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Agrupe una o más suscripciones y defina presupuestos y umbrales",
    "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Le ayuda a administrar (crear/editar) productos/servicios de terceros y sus propios servicios y ofertas de consultoría/asesoramiento/soporte.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Conduce al tablero",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Accede a tus facturas desde el Partner",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Le permite acceder a funciones relevantes para los inquilinos de Microsoft CSP vinculados a sus clientes.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Enumere todos los usuarios de Azure AD de los clientes y asígnelos como roles RBAC del lado del cliente.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Seguimiento de tus pedidos",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Ver los productos que fueron comprados",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Compra y compra ofertas del catálogo.",
    "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Función que permite a los proveedores indirectos (distribuidores) crear y vender sus soluciones IP a través de revendedores indirectos a clientes finales.",
    "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Gestionar facturas y pagos",
    "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Le permite combinar dos o más ofertas (Microsoft y/o ofertas de socios) y ponerlas a disposición de sus clientes para su compra.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Conduce a la lista de clientes activos y opciones para administrar su configuración.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Te lleva a la página del panel",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Consultar el estado de los pagos activados por el sistema.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Le ayuda a crear un catálogo de ofertas (productos/servicios) y le permite definir sus márgenes personalizados como precio de venta para sus clientes.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Le brinda una visión general de su rentabilidad y revisa las facturas en detalle.",
    "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Conduce al tablero",
    "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Le brinda una descripción general de su rentabilidad y también un vistazo a los detalles de la factura.",
    "SIDEBAR_CONTENT_MENUS_RESELLERS":"Conduce a la lista de revendedores activos y opciones para administrar su cartera de CSP.",
    "SIDEBAR_CONTENT_MENUS_SELL":"Enumera las características que te ayudan a definir tus ofertas de venta.",
    "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":" <b>Socios de facturación directa (Nivel 1):</b> enumera todas las funciones que le ayudan a gestionar las ofertas y los precios de sus clientes.",
    "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"<b>Proveedor indirecto (Nivel 2):</b> enumera las funciones que ayudan a los proveedores indirectos a administrar sus revendedores indirectos.",
    "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Acceda a funciones exclusivas del rol de soporte C3.",
    "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Iconos-de-Estocolmo-/-Diseño-/-Unión",
    "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Iconos-de-Estocolmo-/-Comunicación-/-Grupo",
    "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Iconos-de-Estocolmo-/-Comunicación-/-Grupo",
    "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
    "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Iconos-de-Estocolmo-/-Diseño-/-Unión",
    "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
    "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo / Compras / Línea-gráfico1",
    "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Iconos-de-Estocolmo-/-Archivos-/-Carpetas-de-grupo",
    "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Boletos",
    "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Iconos-de-Estocolmo-/-Compras-/-Regalo",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Iconos-de-Estocolmo-/-Inicio-/-Clave",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Iconos de Estocolmo / Compras / Dinero",
    "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Bolsa1",
    "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Dinero",
    "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Iconos-de-Estocolmo-/-Navegación-/-Verificación doble",
    "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Iconos de Estocolmo / Compras / Dinero",
    "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Iconos-de-Estocolmo-/-Diseño-/-Componente",
    "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Iconos-de-estocolmo / Comunicación / Grupo",
    "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Iconos-de-estocolmo-/-Compras-/-Bolsa1",
    "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Iconos-de-Estocolmo / Compras / Box3",
    "SIDEBAR_TITLE_ALL_COMMENTS":"Comentarios",
    "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Gerente de Cuentas",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Administración",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Notificaciones personalizadas",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"Comprometer",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Notificaciones de pancartas",
    "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Asesor de Azure",
    "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Informe de facturación de Azure",
    "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Margen",
    "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Suscripciones de Azure",
    "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Informe resumido de costos",
    "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Análisis de clientes",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Consumo de Licencia",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Informe de uso de Microsoft Azure",
    "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Perfil",
    "SIDEBAR_TITLE_MENU_DASHBOARD":"Panel",
    "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Informe de cambio de licencia.",
    "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Informe resumido del consumo de licencias",
    "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Informe de consumo de licencia",
    "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Administrar derechos de Azure",
    "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Análisis de incorporación",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Comisión",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Informes de la Comisión",
    "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Cupones",
    "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Documentación del producto",
    "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Informe de uso",
    "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Citas",
    "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Subir",
    "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Aprobaciones",
    "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Comisiones",
    "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Atributos del producto",
    "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Uso",
    "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Informes",
    "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"planes",
    "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Informes programados",
    "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Informe de uso",
    "SIDEBAR_TITLE_MENU_USERS":"Usuarios",
    "SIDEBAR_TITLE_MENUS_ANALYZE":"Analizar",
    "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Estimaciones de Azure",
    "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Grupos de Azure",
    "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Ofertas de socios",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Panel",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Facturas",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"microsoft",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Usuarios de Microsoft",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Pedidos",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Productos",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":" Comercio",
    "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Ofertas de distribuidores",
    "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Facturas",
    "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"manojos",
    "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Clientes",
    "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Panel",
    "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Estado de pago pendiente.",
    "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"planes",
    "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
    "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Panel",
    "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Resumen de ingresos y costos",
    "SIDEBAR_TITLE_MENUS_RESELLERS":"revendedores",
    "SIDEBAR_TITLE_MENUS_SELL":"Vender",
    "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Vender Directo",
    "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Vender Indirecto",
    "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"soporte C3",
    "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Iconos-de-Estocolmo / Archivos / Subir",
    "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Iconos-de-Estocolmo / Comunicación / Lista-portapapeles",
    "SIGN_UP_DATE":"fecha de registro",
    "SIGNUP_CART_COUPON_APPLY":"Aplicar",
    "SIGNUP_CART_COUPON_CODE":"Aplicar cupón",
    "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Quitar vale",
    "simplereport":"Informe de la Comisión",
    "SINGLE_COMMENT_STATIC":"Comentario",
    "SITE":"(Sitio)",
    "SITE_DEPARTMENT_ID":"SiteDepartmentId",
    "SITE_ID":"ID del sitio C3",
    "SLAB_BACKWARD_CONFIRMATION_POPUP":"¿De verdad quieres volver? Tienes algunos datos de losa guardados.",
    "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Complete y guarde los datos de la losa.",
    "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Por favor complete los datos de la losa.",
    "SLAB_DATA_TABLE_HEADER_ACTIONS":"Comportamiento",
    "SLAB_DATA_TABLE_HEADER_COST_RATE":"Tasa de costo/unidad",
    "SLAB_DATA_TABLE_HEADER_RANGE":"Rango",
    "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Tasa de venta/unidad",
    "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"La longitud del texto es demasiado larga.",
    "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Por favor complete los datos de la fila.",
    "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Los datos de losa no pueden ser más de 10.",
    "SLAB_TYPE_SELECT_OPTION":"Seleccione",
    "SMTP_SETTINGS_TEST":"Haga clic en el botón para probar la configuración SMTP",
    "SMTP_TEST_BUTTON":"Prueba SMTP",
    "SMTPTYPE_LEGACY_SMTP":"SMTP heredado",
    "SMTPTYPE_MODERN_0365":"Moderno 0365",
    "SMTPTYPE_SENDGRID":"enviargrid",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Política de cancelación",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 horas: Reembolso completo",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 horas: 1er día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 horas: 2do día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 horas: 3er día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 horas: 4to día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 horas: 5to día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 horas: 6to día prorrateado",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Después de 7 días naturales: No se permite la cancelación",
    "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Instrucciones para suscripciones de software",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"La cantidad/asientos se puede aumentar en cualquier momento.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"La cantidad/asientos se puede disminuir dentro de los 7 días calendario, a su cantidad/asientos anteriores oa la suma de los anteriores.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Cambio de cantidad",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Cambio de renovación de horario",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"El programa actual se eliminará si se cambia Cantidad/Estado de renovación automática/Ciclo de facturación/Término.",
    "SoftwareSubscriptions":"Suscripciones de software",
    "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Moneda de origen",
    "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Moneda de origen",
    "SOURCE_PLAN_DISPLAY_NAME":"Plano de origen",
    "SOURCE_PRODUCT_DISPLAY_NAME":"Producto de origen",
    "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"El perfil del cliente se ha sincronizado",
    "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Editar detalles de calificación especial",
    "SQL_ERROR_DESC":"Parece que hay un error técnico al procesar su solicitud, comuníquese con soporte para obtener más ayuda.",
    "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Error de la base de datos al intentar obtener las cuotas de la factura",
    "START_BILLING_PERIOD":"Iniciar período de facturación",
    "START_DATE_REQUIRED_MESSAGE":"La fecha de inicio es obligatoria",
    "STATUS":"Estado",
    "STATUS_ALL":"Todo",
    "STATUS_DUE":"Deudas",
    "STATUS_OVER_DUE":"Cuotas vencidas",
    "STATUS_PENDING":"Pendiente",
    "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Estado actualizado exitosamente",
    "SUB_TAXES_NOT_BOND_EXCEPTION":"Ingrese al menos un nombre de impuesto y un porcentaje para los subimpuestos.",
    "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"La cantidad máxima que se puede permitir es",
    "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"La cantidad mínima que se puede permitir es 1",
    "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Este campo es obligatorio",
    "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Eliminar promoción",
    "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"La actualización está en progreso, haga clic en transición para ver el progreso",
    "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Debido a que la cantidad de licencias de actualización es menor que la actual, debe reasignar asientos manualmente",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Esta suscripción de cliente se comparte entre el cliente asociado y el cliente revendedor, por lo que el producto no se puede actualizar",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Esta suscripción es compartida por varias entidades, por lo tanto, después de la actualización, se necesita una reasignación manual",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Agregar verificación de vencimiento de suscripción",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Agregar verificación de vencimiento de suscripción",
    "SUBSCRIPTION_CHECK_ID":"Verificación de Id. de suscripción",
    "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Requerido",
    "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"el número máximo de días debe ser menor o igual a {{MaxDate}}",
    "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"el número mínimo de días debe ser mayor o igual a 1",
    "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Has alcanzado tu límite máximo de 50 caracteres permitidos",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Notificación de expiración de suscripción fallida",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"Notificación de vencimiento de suscripción para clientes fallidos",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Notificación de vencimiento de suscripción para el éxito del cliente",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Notificación de expiración de suscripción exitosa",
    "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Seleccione un término",
    "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"Entregar",
    "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Período de facturación",
    "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"historial de suscripciones",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Ciclo de facturación",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Período de facturación",
    "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Categoría",
    "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Clientes",
    "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Fecha final",
    "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"nombre de suscripción",
    "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Proveedor",
    "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Fecha de inicio",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Ciclos de facturación",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Categorías",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Tipos de consumo",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"proveedores",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Categorías de proveedores",
    "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"historial de suscripciones",
    "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Descargar historial de suscripción",
    "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Informe del historial de suscripciones",
    "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Cerca",
    "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Búsqueda",
    "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Reiniciar",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Fecha",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Nombre de Departamento",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Nuevo precio",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Añadir",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre del producto",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Precio anterior",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":" Precio",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Nombre del sitio",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Estado",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Usuario",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Fecha final",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Fecha de inicio",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":" Gestionar asignaciones de licencias",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Suscripciones",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Volver a Suscripciones",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Liberación de asientos no utilizados. Espere por favor...",
    "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":" Administrar asignaciones de licencia para",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Asignar licencias",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Quitar licencias",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":" Asignar usuarios",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Instrucciones",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Instrucciones",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Ver y buscar",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Licencias que se eliminarán",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Errores de validación",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Escriba direcciones de correo electrónico de usuario válidas separadas por una coma.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Este Portal valida la entrada y determina si es necesario comprar licencias adicionales antes de la asignación.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"No se puede realizar ninguna operación mientras trabajamos en cambios en esta suscripción.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Se mostrará cualquier error encontrado durante la validación de la entrada.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Durante el envío se considera Asignar o Quitar licencias (pestaña activa).",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Haga clic en <i class='fa fa-remove red'></i> frente a un usuario en la tabla de la izquierda para agregarlo a la lista de licencias que se eliminarán.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Haga clic en Enviar para eliminar las licencias.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Durante el envío se tiene en cuenta Asignar o Quitar licencias (pestaña activa)",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Costo",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Cantidad original.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Tarifa por asiento",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Usuario múltiple",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Remover",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Subir archivo",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Nombre",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"agregar nueva dirección de correo electrónico de usuario",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Ingrese los correos electrónicos de usuario válidos separados por una coma en un archivo .txt y cárguelo aquí.",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Ingrese los correos electrónicos de usuario válidos separados por una coma en un archivo .txt y cárguelos. Ejemplo: abc@xyz.com,bcd@xyz.com",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"ignorar errores",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Corregir errores",
    "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Estado",
    "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Espere mientras procesamos su solicitud; esto puede demorar un par de minutos. También puede volver a consultar haciendo clic en el botón \"Administrar\" en la página de suscripciones.",
    "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"¿Desea continuar con los próximos cambios de renovación de programación como {{isSeatLimitExceeded && isTransactionLimitExceeded? 'límite de asientos y límite de transacciones': (isSeatLimitExceeded? 'límite de asientos es': 'límite de transacciones')}} excedido",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Orden cancelada",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Pedido completado",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Suscripción comprada",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Suscripción eliminada",
    "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"La solicitud seleccionada se aprobó con éxito.",
    "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Aprovisionamiento de suscripciones",
    "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"La solicitud seleccionada se canceló exitosamente.",
    "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"todos los clientes",
    "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Aprovisionamiento de suscripciones",
    "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"Activar",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Nombre de la entidad",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Título",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Nueva cantidad",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Nuevo estado",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Cantidad antigua",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"antiguo estado",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"nombre de suscripción",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Espere mientras procesamos su compra anterior; esto puede demorar un par de minutos.",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Espere mientras procesamos su compra anterior; esto puede demorar un par de minutos. También puede verificar el estado haciendo clic en el botón \"Agregar nueva suscripción\".",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Agregar cheque",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"volver a la lista",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Comprobaciones de vencimiento de suscripción",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Acción",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Días",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Nombre",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Término",
    "SUBSCRIPTION_STATUS_ACTIVE":"Activo",
    "SUBSCRIPTION_STATUS_CANCELLED":"Cancelado",
    "SUBSCRIPTION_STATUS_COMPLETED":"Terminado",
    "SUBSCRIPTION_STATUS_DELETED":"Eliminado",
    "SUBSCRIPTION_STATUS_DISABLED":"Desactivado",
    "SUBSCRIPTION_STATUS_EXPIRED":"Venció",
    "SUBSCRIPTION_STATUS_FAILED":"Ha fallado",
    "SUBSCRIPTION_STATUS_IN_PROVISION":"En provisión",
    "SUBSCRIPTION_STATUS_PENDING":"Pendiente",
    "SUBSCRIPTION_STATUS_SUCCEEDED":"Tuvo éxito",
    "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendido",
    "SUBSCRIPTION_UGRADE_TITLE_2":"Importante",
    "SUBSCRIPTION_UPDATED":"Suscripción actualizada correctamente.",
    "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automático",
    "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Elija una actualización elegible para su suscripción",
    "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Cantidad acumulada",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Suscripción actual",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Suscripción actual",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Seleccione una nueva oferta para actualizar la suscripción actual. Esto actualizará su suscripción y aprovisionará los servicios asociados con la nueva oferta.",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Durante la actualización, evite cambiar cualquier parte de la suscripción original o las cuentas del cliente. Esto creará una nueva suscripción, aprovisionará servicios y reasignará licencias de usuario a la nueva suscripción. Estos cambios no se pueden revertir.",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Asegúrese de realizar una copia de seguridad de los datos antes de actualizar la suscripción.",
    "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Ignorar error",
    "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Ignorar advertencia",
    "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manual",
    "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"No había productos del plan elegibles para la suscripción",
    "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"No se encontraron transiciones",
    "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"¿Desea continuar con la actualización de la suscripción como {{isSeatLimitExceeded && isTransactionLimitExceeded? 'límite de asientos y límite de transacciones': (isSeatLimitExceeded? 'límite de asientos es': 'límite de transacciones')}} excedido",
    "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Reasignación de asientos",
    "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Asientos que deben actualizarse (Suscripción de origen)",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Seleccione una suscripciones de destino",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Este campo es obligatorio",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Suscripciones objetivo",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Nueva suscripción",
    "SUBSCRIPTION_UPGRADE_TITLE":"Elija una actualización elegible",
    "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Actividad de transición",
    "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"La actualización está en progreso",
    "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Mejora",
    "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Este cliente ya ha sido asignado.",
    "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Los siguientes usuarios ya tienen una licencia asignada en esta oferta. Elimínelos e intente guardarlos.",
    "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"¿Estás seguro de que quieres ignorar los errores?",
    "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Asignaciones de licencia actualizadas correctamente",
    "SUBSCRIPTIONMANAGE_REACTIVATE":"¿Estás seguro de que quieres reactivar esta suscripción?",
    "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"La suscripción seleccionada se reactivó exitosamente",
    "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"¿Está seguro de que desea liberar los asientos no utilizados?",
    "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"¡Elimine al menos un usuario para revocar la licencia de la tabla Licencias asignadas!",
    "SUBSCRIPTIONMANAGE_SELECT_USER":"Seleccione el usuario de la lista sugerida antes de hacer clic en el botón Agregar.",
    "SUBSCRIPTIONMANAGE_SUSPEND":"¿Estás seguro de que deseas suspender esta suscripción? Tenga en cuenta que las suscripciones complementarias activas relacionadas también se suspenderán (si corresponde)",
    "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"La suscripción (incluidos los complementos, según corresponda) se suspendió correctamente",
    "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Los asientos no utilizados de la suscripción se liberaron correctamente.",
    "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Su solicitud de asignación masiva está en cola",
    "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Cargado con éxito",
    "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Ingrese los correos electrónicos de usuario válidos para asignar las licencias en el cuadro de texto.",
    "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nueva suscripción",
    "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Suscripciones",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":" Editar",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Administrar",
    "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Total",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Comportamiento",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Nombre",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Cantidad comprada",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Costo",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Total",
    "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Nueva suscripción a Azure",
    "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Nombre",
    "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nueva suscripción",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azur",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Agregar nuevas suscripciones",
    "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Suscripciones de Azure",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Precio estimado",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Nombre de la oferta",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Suscripciones",
    "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Suscripción de actualización",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Nueva cantidad total",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"El valor mínimo es 1",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"La entrada debe ser un número entero válido.",
    "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Oferta",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Compre asientos adicionales aumentando la <strong>nueva cantidad total</strong>",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Asegúrese de haber liberado las licencias de los usuarios a través de <strong>Usuarios -&gt; Licencias</strong> por si quieres reducir el número de puestos desde aquí",
    "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Su solicitud de compra de suscripciones está en cola",
    "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Reactivar",
    "SUBSCRIPTIONS_SAVE_SUCCESS":"Suscripción guardada exitosamente",
    "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Seleccione al menos una oferta antes de realizar un pedido",
    "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Suspender",
    "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Configuración general actualizada con éxito",
    "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"La configuración de ConnectWise Manage se actualizó correctamente.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"La configuración SMTP del socio se actualizó correctamente.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"El socio {{logo}} se actualizó correctamente.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"La configuración del socio se actualizó correctamente.",
    "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"La configuración del proveedor se actualizó correctamente.",
    "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"La configuración de registro público se actualizó correctamente.",
    "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Importación de múltiples pagos completada exitosamente",
    "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Datos de uso diario de ofertas de socios importados con éxito",
    "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Importación de comisiones de productos completada con éxito",
    "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Creó con éxito un nuevo plan con todas las ofertas",
    "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Creó con éxito un nuevo plan de revendedor con todas las ofertas",
    "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Solucionar discrepancia de cantidad",
    "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Actualice el plan de este cliente para incluir ofertas relevantes y regrese aquí para solucionar el problema.",
    "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"Nombre de la oferta CSP",
    "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"No se encontraron ofertas coincidentes",
    "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Oferta equivalente en el plan actual",
    "SUPER_USER_POPUP_LABLE_TEXT_PRICE":" Precio",
    "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Cantidad comprada",
    "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapeo de ofertas",
    "SUPPLIER_DETAILS":"Detalles del proveedor",
    "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Nuevo ticket de soporte",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Email de contacto",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"teléfono de contacto",
    "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Descripción",
    "SUPPORT_ADD_FORM_LABELS_PRIORITY":" Prioridad",
    "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":" Área de productos",
    "SUPPORT_ADD_FORM_LABELS_SUJECT":"Tema",
    "SUPPORT_ADD_FORM_LABELS_TYPE":"Tipo",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Apoyo",
    "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Número de ticket de soporte",
    "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Agregar comentario",
    "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Comentarios",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Email de contacto",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"teléfono de contacto",
    "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":" Esfuerzo acumulativo",
    "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Cliente",
    "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Descripción",
    "SUPPORT_DETAILS_FORM_LABELS_EFFORT":" Esfuerzo (en horas)",
    "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Prioridad",
    "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Área de productos",
    "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Estado",
    "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Tema",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Agente de soporte",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Seleccionar agente de soporte",
    "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Tipo",
    "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"No se agregaron comentarios.",
    "SUPPORT_FOR_TEXT":"Apoyo para",
    "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Agregar ticket de soporte",
    "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Boletos de soporte",
    "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Cliente",
    "SUPPORT_LIST_FORM_LABEL_SEARCH":"Búsqueda",
    "SUPPORT_LIST_FORM_LABEL_STATUS":"Estado",
    "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Agente de soporte",
    "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Identificación de entradas",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Comportamiento",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Detalles",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Email de contacto",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Fecha de creación",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Cliente",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Prioridad",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Área de productos",
    "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Estado",
    "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Tema",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Identificación de entradas",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Tipo",
    "SUPPORT_TICKET_CREATE_SUCCESS":"Nuevo ticket de soporte creado exitosamente",
    "SUPPORT_TICKET_STATUS_ARCHIVED":"Archivado",
    "SUPPORT_TICKET_STATUS_CLOSED":"Cerrado",
    "SUPPORT_TICKET_STATUS_IN PROGRESS":"En progreso",
    "SUPPORT_TICKET_STATUS_OPEN":"Abierto",
    "SUPPORT_TICKET_STATUS_RE_OPEN":"Reabrir",
    "SUPPORT_TICKET_STATUS_RESOLVED":"Resuelto",
    "SUPPORT_TICKET_STATUS_WONT_FIX":"No se arreglará",
    "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Problemas de acceso",
    "SUPPORT_TICKET_TYPE_ANALYTICS":"Analítica",
    "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"microsoft azure",
    "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Gestión de perfiles",
    "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Gestión de suscripciones",
    "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Gestión de usuarios",
    "SUPPORT_TICKET_UPDATE_SUCCESS":"Detalles del ticket de soporte actualizados exitosamente",
    "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"No se ha encontrado ningún mercado para el código de moneda elegido",
    "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Los siguientes mercados no son compatibles ya que no se ha encontrado ninguna conversión de moneda para sus respectivas monedas.",
    "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"El mercado admitido solo se aplica a las ofertas de NCE",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Mercado",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Código de mercado",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Divisa",
    "SupportedMarkets":"Mercados admitidos",
    "SWAL_OK_TEXT":"OK",
    "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Cambiar a la API de precios de Microsoft",
    "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Cambiar al nuevo modelo de seguridad",
    "SYNC_CUSTOMER_PROFILE":"Sincronizar perfil de cliente",
    "TAB_HEADING_PROVISIONING_STATUS":"Estado de aprovisionamiento",
    "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Mostrando {{StartIndex}} - {{EndIndex}} de {{Total}} registros",
    "TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "TABLE_HEADER_TEXT_DATE":"Fecha",
    "TABLE_HEADER_TEXT_DESCRIPTION":"Descripción",
    "TABLE_HEADER_TEXT_FAILURE_REASON":"Motivo del fracaso",
    "TABLE_HEADER_TEXT_GRAND_AMOUNT":"gran cantidad",
    "TABLE_HEADER_TEXT_KEY":"Llave",
    "TABLE_HEADER_TEXT_PAID_AMOUNT":"Monto de pago",
    "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Estado de pago",
    "TABLE_HEADER_TEXT_REMARKS":"Observaciones",
    "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Cantidad subtotal",
    "TABLE_HEADER_TEXT_TAX_AMOUNT":"Importe del impuesto",
    "TABLE_HEADER_TEXT_VALUE":"Valor",
    "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Total de facturas seleccionadas",
    "TABLE_PLACEHOLDER_TEXT_SEARCH":"Buscar...",
    "TAG_DEELTE_SUCCESS_MESSAGE":"Etiqueta eliminada correctamente",
    "TAG_NAME_PLACEHOLDER":"Seleccione una etiqueta",
    "TAG_SAVED_SUCCESS_MESSAGE":"Etiqueta guardada exitosamente",
    "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Nombre del plan",
    "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Nombre del producto",
    "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Propiedades",
    "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Volver a Etiquetas",
    "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar",
    "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar",
    "TAGS_CAPTION_TEXT_TAG_FOR":" Etiqueta para",
    "TAGS_CAPTION_TEXT_TAGS_FOR":" Etiquetas para",
    "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Moneda objetivo",
    "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Moneda objetivo",
    "TARGET_PLAN_DISPLAY_NAME":"Plan objetivo",
    "TARGET_PRODUCT_DISPLAY_NAME":"Producto objetivo",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Volver a porcentajes de impuestos",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Porcentajes de impuestos",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Agregar",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Editar",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Las reglas impositivas se definen mediante combinaciones de {País, Provincia estatal y Código fiscal}, {País, Código postal} o {País, Provincia estatal y Código postal}.",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Por ejemplo: considere la definición de la regla fiscal para un país como EE. UU., dejar el estado, la provincia y el código postal en blanco significa que la tasa impositiva se aplica a todos los clientes de EE. UU. Si desea definir una regla para un estado en particular, proporcione los valores de País y Estado mientras define la regla.",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"Válido desde",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":" Nombre del impuesto",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":" Porcentaje de impuestos",
    "TAX_PERCENTAGES_ADD_SUCCESS":"El porcentaje de impuestos se agregó correctamente.",
    "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Clientes",
    "TAX_PERCENTAGES_DELETE_SUCCESS":"Porcentaje de impuestos eliminado correctamente.",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Agregar porcentaje de impuestos",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"volver a la lista",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Porcentajes de impuestos",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":" Porcentajes de impuestos para",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Borrar",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Editar",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Comportamiento",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"Válido desde",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":" % de impuesto",
    "TAX_PERCENTAGES_UPDATE_SUCCESS":"Porcentaje de impuestos actualizado correctamente.",
    "TAX_RULES_ADD_SUCCESS":"Regla fiscal agregada exitosamente",
    "TAX_RULES_DELETE_SUCCESS":"Regla fiscal eliminada correctamente",
    "TAX_RULES_UPDATE_SUCCESS":"Regla fiscal actualizada exitosamente",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Impuestos acumulativos",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Las reglas del impuesto por hijos se aplican en secuencia sobre el monto resultante de cada paso del cálculo.",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"General",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Porcentaje de impuesto único aplicado sobre el importe total.",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Subimpuestos",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"El porcentaje de impuesto para esta definición será el total (suma) de las reglas del impuesto por hijos.",
    "TENANT TOOLTIP":"Seleccionar inquilino para cargar usuarios",
    "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activar la suscripción de umbral de peligro alcanzado",
    "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Habilite la liberación automática para asientos no asignados",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permite al cliente cambiar la cantidad de producto desde la cuadrícula o la vista de lista en la pantalla de productos.",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Permitir que el cliente inicie la transacción de su(s) factura(s)",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Marcar esta bandera evita que los clientes reduzcan la cantidad de suscripción",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Permitir la sincronización de nombres de productos para ofertas basadas en el uso",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Permitir la sincronización de nombres de productos para ofertas basadas en cantidad",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Permite la sincronización del nombre descriptivo de suscripción para ofertas basadas en cantidad",
    "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Opción de aplicar descuentos del nivel 2 del plan Azure al cliente. El valor predeterminado es No. Si se establece en Sí, excepto los descuentos PEC, todos los demás descuentos también se aplicarán al cliente.",
    "TENANT_CONFIG_DESC_AUTO_SYNC":"Esto se usa para habilitar/deshabilitar el proceso de sincronización automática en la conciliación.",
    "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Duración del contrato anual de Autotask (años)",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Fecha de finalización del contrato de Autotask",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Este valor de fecha se utilizará como fecha de finalización para crear contratos automáticamente para el cliente",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Tarifa de configuración del contrato Autotask",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Ingrese las tarifas de configuración del contrato, si corresponde",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Fecha de inicio del contrato de Autotask",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Este valor de fecha se utilizará como fecha de inicio para crear contratos automáticamente para el cliente",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Día de inicio del contrato de Autotask",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Active la casilla de verificación si el informe de tiempo del contrato requiere horas de inicio y finalización",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Informes de tiempo de contrato de Autotask requeridos tiempos de inicio y finalización",
    "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Duración del contrato mensual de Autotask (meses)",
    "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Permitir a los clientes administrar suscripciones de Azure",
    "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Permita que los clientes definan grupos a través de suscripciones de Azure",
    "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará al uso de Azure del cliente. Por ejemplo: ingrese 1.15 si el margen de beneficio debe ser del 15%.",
    "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitada, la suscripción NCE se establece en renovación automática.",
    "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Habilitar la consideración del precio del ERP en la renovación sin macro",
    "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Después de la compra o aumento de cantidad, el producto no será considerado en autoliberación hasta este período (en días).",
    "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"Id. de oportunidad de CRM",
    "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Tipo de facturación del cliente",
    "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Moneda del cliente",
    "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Tipo de efectividad de actualización automática de precios de productos de soporte al cliente. ¡Esta configuración entrará en vigencia si y solo si la actualización automática del precio del producto del cliente está habilitada!",
    "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Umbral de peligro predeterminado en porcentaje para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Umbral de error predeterminado en porcentaje para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Umbral de información predeterminado en porcentaje para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Presupuesto mensual predeterminado para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinatario de correo electrónico de umbral predeterminado para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Umbral de advertencia predeterminado en porcentaje para la suscripción de Azure.",
    "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Habilitar términos y condiciones para ordenar productos",
    "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Habilitar términos y condiciones de NCE",
    "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Habilitar informes de Power BI",
    "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"Habilitar esta opción permitirá a los usuarios programar un pedido",
    "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ID de cliente de ERP",
    "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Cliente exento para exportación de PSA",
    "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Si este campo está marcado, tomamos en consideración el consentimiento del cliente.",
    "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Este campo se actualiza si al menos uno de los clientes ha aceptado el MCA. Desmarque esta casilla para exigir la aceptación por parte del cliente nuevamente.",
    "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"¿Aceptó los últimos términos y condiciones?",
    "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Límite de transacción",
    "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Constante entera",
    "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Para referencia interna. Esta identificación se puede utilizar para relacionar al cliente con un registro en su sistema de contabilidad interno.",
    "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Día del mes en que las facturas se aprueban automáticamente",
    "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Día en que se procesan los pagos automáticos",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Fecha de vencimiento de las facturas",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"No de días vencidos para el pago de la factura",
    "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Día del mes en el que las facturas se finalizan automáticamente",
    "TENANT_CONFIG_DESC_INVOICE_NOTE":"Aparece en el pdf de la factura",
    "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Ordene la disponibilidad del perfil de pago antes de pagar el carrito",
    "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Se requiere liberación automática para productos de sitio y departamento",
    "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Obligar al usuario a enviar el método de pago",
    "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente la dirección recién agregada como la dirección predeterminada para el cliente.",
    "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el correo electrónico recién agregado como el correo electrónico predeterminado para el cliente.",
    "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Al habilitar esta opción, se designará automáticamente el número de teléfono recién agregado como el número de teléfono predeterminado para el cliente.",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Propietario de factura única (tipo)",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Propietario de factura única (identificador)",
    "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará a los artículos de línea únicos del cliente.",
    "TENANT_CONFIG_DESC_PAN_NUMBER":"Aparece en el pdf de la factura en la sección Facturar a.",
    "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Aparece en el pdf de la factura en la sección Facturar a.",
    "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Enlace de términos y condiciones",
    "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones perpetuas de software.",
    "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Aparece en el pdf de la factura en la sección Facturar a.",
    "TENANT_CONFIG_DESC_PO_NUMBER":"Definir el número de orden de compra para el cliente.",
    "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Si esta configuración está habilitada, el correo electrónico de facturación se publicará en servicios de contabilidad externos",
    "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Habilitar la función para transmitir facturas al servicio de contabilidad externo",
    "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Marque esta bandera si desea que el sistema procese pagos (CC/ACH según corresponda)",
    "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Cuando está habilitado, mostrará el nombre de la suscripción dado en el centro de socios",
    "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"La facturación perpetua del software se incluirá en las facturas de este Sitio.",
    "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"Aplicar un límite en la cantidad de asientos que el cliente puede comprar",
    "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Las condiciones de pago que se marcarán en las facturas que se publican en QuickBooks",
    "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Ingrese la cantidad de días antes de la fecha de renovación cuando se enviarán los correos electrónicos de consentimiento a los clientes.",
    "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Especifique la cantidad de días después de la notificación de consentimiento cuando se enviará la notificación de recordatorio a los clientes.",
    "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"La facturación de reservas de Azure se incluirá en las facturas de este Sitio.",
    "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Margen (%) que se aplicará en la reserva azul del cliente.",
    "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"Modelo de precios de RI",
    "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"El cliente recibirá el correo electrónico con los detalles de la factura una vez que se apruebe la factura y si las notificaciones por correo electrónico están habilitadas",
    "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"El cliente recibirá una notificación por correo electrónico con respecto a las facturas que tienen montos cero",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Aparece en el pdf de la factura en la sección Enviar a.",
    "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Debería habilitarse por defecto para el cliente.",
    "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Debe notificar al cliente",
    "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Debería eximir al cliente del pago instantáneo.",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede cancelar la suscripción a NCE dentro de la ventana de cancelación de 7 días calendario.",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Cuando está habilitado, el cliente puede cancelar las suscripciones de software del producto dentro de un período de cancelación de 7 días calendario.",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Permitir al cliente alinear las fechas de finalización de la suscripción",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Si está habilitado, el Cliente puede alinear las fechas de finalización de la suscripción para lograr la coterminosidad",
    "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Mostrar siguiente menú de renovación de programación",
    "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Habilite esta opción para mostrar las suscripciones no incorporadas para la selección de fecha de finalización personalizada durante la compra.",
    "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede reactivar una suscripción NCE suspendida.",
    "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Botón para mostrar calificaciones especiales",
    "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Cuando está habilitado, el cliente puede suspender la suscripción a NCE.",
    "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Cuando está habilitado, el cliente puede actualizar el producto NCE a un SKU superior",
    "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Suscripción que se omitirá durante una factura única",
    "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para las suscripciones de software.",
    "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"La facturación de la suscripción de software se incluirá en las facturas de este Sitio.",
    "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Establecer este valor en verdadero detendrá la capacidad del usuario para realizar compras.",
    "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Constante de cadena para el cliente",
    "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Soporte Actualización automática de precios de productos para clientes",
    "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspender cuando se alcance el umbral de peligro",
    "TENANT_CONFIG_DESC_TAX_CODE":"El código impositivo se utiliza para determinar el porcentaje de impuesto aplicable.",
    "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Aparece en el pdf de la factura en la sección Facturar a.",
    "TENANT_CONFIG_DESC_TAX_VALUE":"Aparece en el pdf de la factura en la sección Facturar a.",
    "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Margen total (%) que se aplicará al precio de costo para alcanzar el precio de venta deseado para suscripciones de terceros.",
    "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"La facturación de suscripciones de terceros se incluirá en las facturas de este Sitio.",
    "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"Hacer cumplir un límite en el valor monetario de la compra de productos basada en la cantidad (en la moneda del cliente)",
    "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Tipo de adición de ConnectWise",
    "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Facturación Anticipada",
    "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Según el formato seleccionado, las adiciones se crearán en connectwise",
    "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Colocar la facturación del mes actual en el mes especificado en el futuro para la facturación",
    "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Mostrar el registro de aceptación de términos y condiciones al inquilino",
    "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Activar la suscripción de umbral de peligro alcanzado",
    "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Permitir la liberación automática de asientos no utilizados",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Permitir al cliente cambiar la cantidad de producto de la lista",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Permitir que el cliente inicie la transacción",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Permitir al cliente reducir asientos",
    "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Aplicar descuentos del nivel 2 del plan Azure al cliente",
    "TENANT_CONFIG_DISP_AUTO_SYNC":"Sincronización automática",
    "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"¿Habilitar Azure?",
    "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"¿Habilitar Azure para empresas?",
    "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Marcar el uso de Azure",
    "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Administrar productos NCE es renovación automática",
    "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Considere el precio del ERP en el momento de la renovación sin macro",
    "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Período de enfriamiento de liberación automática",
    "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"ID de oportunidad de CRM",
    "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Tipo de facturación del cliente",
    "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Moneda del cliente",
    "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Eficacia de la actualización automática del precio del producto del cliente",
    "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Umbral de peligro por defecto (%)",
    "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Umbral de error predeterminado (%)",
    "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Umbral de información predeterminado (%)",
    "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Presupuesto mensual predeterminado",
    "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Destinatario de correo electrónico de umbral predeterminado",
    "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Umbral de advertencia predeterminado (%)",
    "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Habilitar términos y condiciones para ordenar productos",
    "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Habilite los términos y condiciones de NCE para pedir productos de Microsoft NCE",
    "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Habilitar orden de programación",
    "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ID de cliente de ERP",
    "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Cliente exento para exportación de PSA",
    "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"¿Consentimiento del cliente habilitado?",
    "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"¿Se acepta el acuerdo de cliente de Microsoft?",
    "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"¿Términos y condiciones de socio aceptados?",
    "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Límite de transacción",
    "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
    "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"ID de referencia de facturación",
    "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Día en que el cliente puede ver las facturas.",
    "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Día para pago automático",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Fecha de vencimiento de las facturas",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Días de vencimiento de facturas",
    "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Día en que se finalizan las facturas.",
    "TENANT_CONFIG_DISP_INVOICE_NOTE":"notas de factura",
    "TENANT_CONFIG_DISP_INVOICE_TYPE":"Tipo de factura",
    "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mandato Perfil de pago Carro Caja",
    "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Se requiere liberación automática para productos de sitio y departamento",
    "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Perfil de mandato",
    "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Asignar automáticamente una nueva dirección como predeterminada",
    "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Asignar automáticamente un nuevo correo electrónico como predeterminado",
    "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Asignar automáticamente un nuevo número de teléfono como predeterminado",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Propietario de factura única (tipo)",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Propietario de factura única (identificador)",
    "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Margen para líneas de pedido únicas",
    "TENANT_CONFIG_DISP_PAN_NUMBER":"número de PAN",
    "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"tecla numérica PAN",
    "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"URL de términos y condiciones",
    "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Marcado para suscripción perpetua de software",
    "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Lugar de suministro",
    "TENANT_CONFIG_DISP_PO_NUMBER":"Número de orden de compra",
    "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Publicar correo electrónico de facturación en servicios de contabilidad externos",
    "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Registrar facturas en servicio de contabilidad externo",
    "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"¿Pago automático de facturas?",
    "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Mostrar nombre de suscripción del proveedor",
    "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Propietario de la factura de software perpetua (identificador)",
    "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Límite de asientos aplicable",
    "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Condiciones de pago de QuickBooks",
    "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Periodo de inicio del consentimiento de renovación",
    "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Días de notificación de recordatorio de consentimiento de renovación",
    "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Propietario de la factura de reserva de Azure (identificador)",
    "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
    "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"Modelo de precios de RI",
    "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Marcado para la reserva de Azure",
    "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Enviar correos electrónicos de facturas automáticamente",
    "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Enviar facturas de importe cero",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Enviar a nombre de contacto",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Enviar al número de contacto",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Enviar a número PAN",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Enviar a clave numérica PAN",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Enviar a tipo de impuesto",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Enviar al valor fiscal",
    "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Debería habilitarse por defecto para el cliente",
    "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Pago de pago instantáneo exento",
    "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Botón para mostrar calificaciones especiales",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar botón Cancelar producto NCE",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Mostrar botón Cancelar producto de suscripciones de software",
    "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Mostrar suscripciones no incorporadas para una fecha de finalización personalizada",
    "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar el botón de reactivación del producto NCE",
    "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Mostrar botón de suspensión del producto NCE",
    "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Mostrar registro de aceptación de términos y condiciones",
    "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Mostrar el botón de actualización del producto NCE",
    "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de software",
    "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Propietario de la factura de suscripción de software (identificador)",
    "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Detener todas las transacciones",
    "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Identificador único del cliente",
    "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Actualización automática del precio del producto del cliente",
    "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Suspender cuando se alcance el umbral de peligro",
    "TENANT_CONFIG_DISP_TAX_CODE":"Código de impuestos",
    "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Tipo de impuesto",
    "TENANT_CONFIG_DISP_TAX_VALUE":"valor fiscal",
    "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Marcado para suscripción de terceros",
    "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Propietario de la factura de suscripción de terceros (identificador)",
    "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"límite de cantidad de transacción",
    "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Introduzca el valor de {{tenantConfigName}}",
    "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Introduzca un valor válido de {{tenantConfigName}}",
    "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"Administrar la configuración de renovación automática no está habilitado para este inquilino.",
    "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"La configuración del cliente se revirtió correctamente",
    "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"La configuración del cliente se guardó correctamente",
    "TENANT_CONFIGURATION_SAVE_SUCCESS":"La configuración del cliente para {{customername}} se actualizó correctamente",
    "TENANT_COUNTS":"Contar",
    "TENANT_CREATION_FAILED":"No se pudo crear el inquilino",
    "TENANT_EXCEEDED_COUNT_ERROR":"Número de inquilinos que se crearán, sin exceder {{Count}}",
    "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":" Si desea crear la suscripción de Azure con el arrendatario, márquelo.",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Agregue el prefijo 'OTU' en el nombre",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Agregue 'OTU' como prefijo en el nombre del inquilino",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Asignar administrador de inquilinos como propietario",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Asigne al usuario administrador como propietario de la suscripción creada",
    "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Agregar inquilinos",
    "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Crear suscripciones",
    "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Departamento",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Nombre de dominio",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Los nombres de dominio comenzarán con esto.",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Seleccione la oferta del plan de Azure",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Use esto para seleccionar el producto del plan correcto para asignar el plan de Azure",
    "TENANT_MANAGEMENT_LABEL_PREFIX":"Prefijo",
    "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Agregar prefijo en el nombre del inquilino",
    "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Secuencia de inicio",
    "TENANT_MANAGEMENT_LABEL_SITE":"Sitio",
    "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Número de inquilinos",
    "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Nombre",
    "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Proceso completo. Consulte el registro de auditoría para obtener más detalles.",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Se acepta la solicitud para agregar inquilinos y se está procesando",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"La solicitud para agregar inquilinos falló.",
    "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Proporcione una secuencia numérica",
    "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"El incremento secuencial se utilizará como sufijo en los nombres de inquilinos.",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Estado de creación del inquilino",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Espere mientras se crean los inquilinos.",
    "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Número de inquilinos a crear.",
    "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Los nombres de los inquilinos comenzarán con esto.",
    "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Volver a Inquilinos",
    "TENANT_SEQUENCE":"Secuencia",
    "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"Al hacer clic en el botón \"Aceptar\", usted acepta los términos de uso anteriores.",
    "TERM_AND_CONDITIONS_LINK_TEXT":"Condiciones de uso para socios",
    "TERM_AND_CONDITIONS_READ_TEXT":"Lea atentamente los términos y condiciones que se proporcionan a continuación.",
    "TERM_DURATION_DESC_DAY(S)":"Días)",
    "TERM_DURATION_DESC_DAYS":"Día",
    "TERM_DURATION_DESC_MONTH":"Mes",
    "TERM_DURATION_DESC_MONTH(S)":"Meses)",
    "TERM_DURATION_DESC_YEAR":"Año",
    "TERM_DURATION_DESC_YEAR(S)":"Años)",
    "TERM_DURATION_DESC_YEARS":"Años",
    "TERM_DURATION_FILTER_DESC_DAYS":"días",
    "TERMS_AND_CONDITIONS":"Términos y condiciones",
    "TERMS_AND_CONDITIONS_ACCEPT":"Por favor acepte términos y condiciones",
    "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Términos y condiciones aceptados exitosamente.",
    "TERMS_AND_CONDITIONS_BACK":"Volver a Términos y condiciones",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Lea los términos y condiciones que se proporcionan a continuación</b>. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>Términos de uso del socio</a></li> </ul> < /br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Lea los términos y condiciones de Microsoft que se proporcionan a continuación. </br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
    "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Lea detenidamente el contrato, los términos y condiciones de <b>{{partnerName}}</b> y Microsoft que se proporcionan a continuación. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li> </ul> </br> Al hacer clic en el botón \"Aceptar\", acepta los términos de uso anteriores.",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Haga clic <a id='linkEzidebit' href='#' ng-click='vm.EzidebitAgreementClicked()'> aquí </a>para leer el Acuerdo Ezidebit completo",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Acuerdo Ezidebit",
    "TERMS_AND_CONDITIONS_READ":"He leído los términos y condiciones",
    "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"No se puede procesar su solicitud.",
    "TEST_EMAIL_LABEL":"ID de correo electrónico de prueba",
    "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Prueba",
    "TEST_PARTNER_ACCESS_BTN_TEXT":"Prueba",
    "TEST_SMTP_SETTING_TITLE":"Probar la configuración SMTP",
    "TEXT_ACH":"CCA",
    "TEXT_ACTIONS":"Comportamiento",
    "TEXT_BILL_FROM":"Factura de",
    "TEXT_BILL_TO":"Cobrar a",
    "TEXT_BILLING_PERIOD":"Período de facturación",
    "TEXT_EMAIL_ID":"Identificación de correo",
    "TEXT_GROUP_NAME":"Nombre del grupo",
    "TEXT_INVOICE_AMOUNT":"Monto de la factura",
    "TEXT_INVOICE_DATE":"Fecha de la factura",
    "TEXT_INVOICE_NUMBER":"Número de factura",
    "TEXT_INVOICE_SUMMARY":"Resumen de factura",
    "TEXT_MONTHLY_BUDGET":"Presupuesto mensual",
    "TEXT_OFF":"APAGADO)",
    "TEXT_PLAN_ID":"identificación del plan",
    "TEXT_RESPONSIBLE_OFFICIAL":"Oficial Responsable",
    "TEXT_SAAS_SUBSCRIPTION_STATUS":"Estado de suscripción de SaaS",
    "TEXT_SIGN_IN_REQUIRED":"Inicio de sesión requerido",
    "TEXT_TENANT_ID":"Identificación del inquilino",
    "TEXT_THRESHOLDS":"Umbrales",
    "TEXT_VAT_NUMBER":"Número de valor agregado",
    "THE_SEQUENCE_LABEL_THE_STARTING_NAME":" El nombre de la secuencia comenzará desde",
    "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Seleccionar Acuerdo PSA",
    "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Completar el mapeo para la entidad C3",
    "THIRD_PARTY_ENTITY_MAPPING":"Mapeo de entidades de terceros",
    "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar la asignación para el cliente: <b>{{customerName}}</b>?",
    "THIRD_PARTY_PRODUCT_MAPPING":"Mapeo de productos de terceros",
    "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"¿Está seguro de que desea guardar los productos de terceros seleccionados?",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Acción",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Convenio",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"Cliente C3",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"Cliente de PSA",
    "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"Seleccione el servicio PSA",
    "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Seleccionar cliente de PSA",
    "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Mapeo de suscripciones de terceros",
    "TIME_DESC_DAY":"día",
    "TIMER_DESC_DAYS":"días",
    "TITLE_COUPON_CODE_TEXT":"Códigos de cupón",
    "TITLE_CUMULATIVE_TAXES":"Impuestos acumulativos",
    "TITLE_ROLE_SELECT":"Seleccionar rol",
    "TITLE_SUB_TAXES":"Subimpuestos",
    "TITLE_TAGS":"Etiquetas",
    "TITLE_TEXT":"Bienvenido al Centro de Control CSP",
    "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"para incorporar suscripciones de Azure",
    "TOOL_TIP_TEXT_HIDE":"Esconder",
    "TOOL_TIP_TEXT_SHOW":"Espectáculo",
    "TOOLTIP_COUPON_CODE_TEXT":"Código promocional",
    "TOOLTIP_TEXT_DELETE_PSA_LOG":"Desactivar registro",
    "TOOLTIP_TEXT_DISABLE_PRODUCT":"Desactivar producto",
    "TOOLTIP_TEXT_EDIT_NAME":"Editar nombre",
    "TOOLTIP_TEXT_EDIT_QUANTITY":"Editar cantidad",
    "TOOLTIP_TEXT_ENABLE_PRODUCT":"Habilitar producto",
    "TOOLTIP_TEXT_LINK_PRODUCT":"Enlace de producto",
    "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"El producto vinculado no es compatible",
    "TOOLTIP_TEXT_QUANTITY":"Cantidad comprada",
    "TOUR_POPUP_CONTENT":"<b>¡Bienvenido a bordo!</b></br> Vea un breve recorrido por las funciones de la aplicación.",
    "TOUR_POPUP_DISMISS_BUTTON":"Despedir",
    "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"hacer un recorrido",
    "TOUR_POPUP_TITLE":" Bienvenido a",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Comportamiento",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Monto",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Categoría",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Velocidad",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Descripción",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Descuento",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Nombre del producto",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Cantidad comprada",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Velocidad",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"cantidad acumulada",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"gravado",
    "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Cantidad de transacción excedida",
    "TRANSACTION_LIMIT_MESSAGE":"Límite restante {{RemainingAmount}} de {{TotalLimit}}",
    "TRANSACTION_TYPE_PURCHASE":"Nueva compra",
    "TRANSACTION_TYPE_UPDATE":"Actualización de cantidad",
    "TRANSACTION_TYPE_UPGRADE":"Actualización de suscripción",
    "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Actualmente, existe una solicitud para convertir la oferta de prueba en una suscripción; No se permite realizar ninguna operación en este producto.",
    "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Los dos valores válidos más cercanos son {{lower}} y {{upper}}.",
    "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Ciclo de facturación",
    "TRIAL_OFFER_PARENT_BILLING_TYPE":"Tipo de facturación",
    "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Categoría",
    "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Tipo de consumo",
    "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"¿Es auto renovable?",
    "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Nombre del producto",
    "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Proveedor",
    "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Precio de venta",
    "TRIAL_OFFER_PARENT_SALE_TYPE":"Tipo de venta",
    "TRIAL_OFFER_PARENT_TERM_DURATION":"Duración del término",
    "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Continuar la suscripción después de la prueba",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Instrucciones",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Nota",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"El período de prueba tiene una duración de un mes y el producto se cancelará posteriormente.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"días, y el producto será cancelado posteriormente.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" El período de prueba tiene una duración de",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"Al hacer clic en \"Continuar suscripción\", el producto se comprará después del período de prueba y la facturación comenzará en consecuencia.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Tiene la flexibilidad de extender su suscripción más allá del período de prueba seleccionando el botón \"Comprar suscripción\".",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"La oferta de prueba compartida entre el sitio/departamento no se convertirá en suscripción.",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Se comprará una suscripción de una cantidad en la entidad operativa del cliente.",
    "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Por favor ingrese una cifra válida",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"La cantidad máxima es 10000",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"La cantidad mínima es 1",
    "TRIAL_PERIOD_DAYS_TEXT":"Días",
    "TROUE_BTN_TEXT_END_TOUR":"Fin del recorrido",
    "TROUE_BTN_TEXT_NEXT":"Siguiente &raquo;",
    "TROUE_BTN_TEXT_PREV":"&laquo; Anterior",
    "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"No se puede encontrar ningún plan de Azure desde la PC",
    "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El cliente se desasignó con éxito",
    "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"¿Está seguro de que desea anular la asignación de {{customer}}?",
    "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"El revendedor se desasignó con éxito",
    "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Cliente no asignado de un administrador de cuentas con éxito",
    "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Revendedor no asignado de un administrador de cuentas con éxito",
    "UNIQUE_NAME_ERROR":"Este nombre ya existe, elija algo diferente.",
    "UNSUPPORTED_FILE_TYPE_SELECTED":"Solo se admiten archivos de tipo jpg, svg, png, gif, bmp",
    "UP_GRADE_AZURE_SUBSCRIPTION":"Actualice la suscripción de Azure al plan de Azure.",
    "UPDATE_CART_LINE_ITEM":"Artículo de carrito actualizado",
    "UPDATE_CART_LINE_ITEM_FAILURE":"No se pudo actualizar el artículo del carrito",
    "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Configuraciones globales del cliente actualizadas correctamente",
    "UPDATE_DEFAULT_VALUE":"Marcar como predeterminada",
    "UPDATE_DUE_DATE_NAME":"Fecha de vencimiento",
    "UPDATE_INVOICE_LINEITEM_PONUMBER":"Línea de pedido de la factura N.º de orden de compra",
    "UPDATE_INVOICE_PONUMBER":"Número de orden de compra",
    "UPDATE_INVOICE_PROPERTIES":"Actualizar propiedad de factura",
    "UPDATE_MANAGE_RENEWAL_TITLE":"Renovación de actualización",
    "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Se produjo un error al actualizar la configuración del socio",
    "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Se produjo un error al actualizar los detalles de impuestos.",
    "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"No se permite actualizar los productos ya que la asignación de licencia está en curso para el producto o uno de sus complementos. Por favor intente mas tarde.",
    "UPDATE_PROFILE":"Perfil actualizado exitosamente",
    "UPDATE_PROFILE_FAILURE":"No se pudo actualizar el perfil",
    "UPDATE_RESELLER_CONFIGURATION":"Actualizar la configuración del revendedor",
    "UPDATE_RESELLER_CONFIGURATION_FAILURE":"No se pudo actualizar la configuración del revendedor",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Actualizar la opción de pago predeterminada del revendedor",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Error en la opción de pago predeterminada del revendedor",
    "UPDATE_RESELLER_PAYMENT_PROFILE":"Perfil de pago de revendedor actualizado",
    "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"No se pudo actualizar el perfil de pago del revendedor",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Precio del producto del plan de revendedor actualizado",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"No se pudo actualizar el precio del producto del plan de revendedor",
    "UPDATE_RESELLER_PROFILE":"Actualizar perfil de revendedor",
    "UPDATE_RESELLER_PROFILE_FAILURE":"No se pudo actualizar el perfil de revendedor",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Valor predeterminado actualizado del cliente proveedor",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"No se pudo actualizar el valor predeterminado del cliente proveedor",
    "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Detalles del administrador de cuenta actualizados con éxito",
    "UPDATED_FAILED_C3_PSA_DATA_FAIL":"Error en la actualización de datos de PSA",
    "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"La actualización de datos falló; se podrá acceder a otras opciones una vez que haga clic en Aceptar.",
    "UPDATED_PAGE_MODE_TO":"Actualizado a la vista {{PageMode}} correctamente",
    "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"Se actualizaron correctamente los datos de C3 PSA con los datos más recientes del sistema externo de PSA",
    "UPGRADE_ELIGIBILITY_CHECK":"Verifique la elegibilidad de actualización",
    "UPGRADE_FAILED_MSG":"La actualización de Azure falló",
    "UPGRADE_SUCCESS_MSG":"Se acepta la solicitud de actualización y se está procesando.",
    "UPGRADE_SYNC_PUPUP_TITLE":"Actualizar Azure",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Alinear con la fecha de inicio del contrato",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Alinear la fecha de vigencia del producto con el próximo período de contrato reciente. Tenga en cuenta que la siguiente fecha reciente se calcula solo mensualmente, incluso si el período del contrato es anual.",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Cargar facturas en QuickBooks Online ID de lote",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Mensaje de error al cargar facturas en Quickbooks",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea cargar las facturas seleccionadas en <b>QuickBooks Online</b>?",
    "UPLOAD_INVOICES_TO_XERO_BATCHID":"Cargar facturas en Xero Batch Id.",
    "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"¿Está seguro de que desea cargar las facturas seleccionadas en <b>Xero</b>?",
    "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Mensaje de error al cargar facturas en Xero",
    "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Subir detalles de revendedores",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"No se pudo cargar el historial de suscripciones en el servicio externo",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Cargue correctamente el historial de suscripciones en un servicio externo",
    "UPLOAD_TO_PSA_SELECT_ALL":"Seleccionar todo",
    "Usage":"Informe de uso",
    "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"No puede actualizar el porcentaje -ve a un valor del porcentaje disponible.",
    "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"No puede actualizar el porcentaje a un valor superior al porcentaje disponible.",
    "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Obtener informe de uso",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Identificación de suscripción",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"nombre de suscripción",
    "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Facturar al cliente",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Fecha de finalización del cargo",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Fecha de inicio de carga",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"costo para el cliente",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Costo para el socio (después de impuestos)",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Costo para el socio (antes de impuestos)",
    "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Código de moneda",
    "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Nombre del cliente",
    "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Descargar informe de uso",
    "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Nombre del inquilino",
    "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Informe de uso de Azure",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Suscripciones de Azure",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Volver a suscripciones",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Editar suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Agregar suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":" Umbral de peligro",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Umbral de error",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Nombre del grupo",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Selecciona grupo",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Umbral de información",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Ocultar del cliente",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Presupuesto mensual",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Umbral de advertencia",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Volver a usuarios de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":" Administrar usuario de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Correo electrónico",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Role",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Contribuyente",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Dueño",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Lector",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":" Volver a suscripciones",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":" Exportar detalles de uso",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Resumen de facturación",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Detalles de uso de",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Gastar",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Unidades consumidas",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Tipo de recurso",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Nombre de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Agregar suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":" Volver a clientes",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":" Suscripciones de Azure para",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Todas las suscripciones",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Período de facturación",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Grupo",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Editar suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Usuarios",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Ver detalles de uso",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Comportamiento",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Costo estimado",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Grupo",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"ID de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"nombre de suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Umbrales",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Agregar usuario a la suscripción",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Volver a suscripciones",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Usuarios de suscripción de",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Correo electrónico",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Nombre",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Role",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Borrar usuario",
    "USER_CREATED_POPUP":"Usuario creado",
    "USER_CREATION_SUCCESS_MESSAGE":"Usuario {{UserName}} creado exitosamente",
    "USER_DETAILS_NOT_FOUND_BODY":"No se pueden encontrar los detalles del usuario que inició sesión. ¡Por favor contacte al administrador!",
    "USER_DETAILS_NOT_FOUND_HEADER":"Detalles no encontrados",
    "USER_HAS_BEEN_CREATED":"El usuario ha sido creado con los siguientes detalles",
    "USER_LICENSE_ASSIGNMENT_BATCH_ID":"ID de lote de asignación de licencia de usuario",
    "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Hecho",
    "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Estado de asignación de licencia",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Nombre del cliente",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Usuarios",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"correo electrónico",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Nombre del inquilino",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Identificación del inquilino",
    "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"No se puede actualizar el estado, comuníquese con el soporte",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Añadir",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Manojo",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":" Vinculado",
    "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Estado de eliminación del usuario",
    "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Liberar asientos no utilizados",
    "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Aplicar cambios a complementos",
    "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Usuarios del producto",
    "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Se produjo un error al liberar los asientos. Por favor contacte al soporte.",
    "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Debe haber al menos una licencia activa. Suspenda el producto si no desea utilizarlo más.",
    "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Se está procesando la solicitud para liberar asientos.",
    "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Cargado con éxito",
    "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"La solicitud para agregar licencias se acepta y se está procesando.",
    "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"La solicitud de asignación masiva de licencias está aceptada y en proceso.",
    "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Asignado",
    "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Asientos totales",
    "USER_LISTING_FILTER_LABEL":"Identificación de correo",
    "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Buscar por nombre para mostrar o ID de correo electrónico",
    "USER_LISTING_SEARCH_BUTTON":"Búsqueda",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Exportar informe de asignación de usuarios",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entidades",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Seleccione al menos una entidad",
    "USER_REMOVED":"Usuario eliminado exitosamente",
    "USER_ROLE_LIST_LABEL_ROLE_NAME":"Nombre de rol",
    "USER_ROLE_LIST_LABEL_SCOPE":"Nombre del ámbito",
    "USER_ROLE_LIST_LABEL_USER_NAME":"Nombre de usuario",
    "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Usuario agregado exitosamente",
    "USERCONTROLLER_USERUPDATESUCCESS":"Detalles del usuario actualizados exitosamente",
    "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Acceso del usuario revocado",
    "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Agregar",
    "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Editar",
    "USERS_ADD_EDIT_CAPTION_TEXT_USER":" Usuario bajo",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"no está disponible",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"está disponible",
    "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Información de usuario",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Marque/desmarque las casillas de verificación junto a las ofertas para asignar/liberar licencias respectivamente",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Si no hay suficientes licencias gratuitas disponibles para una oferta, se realizará una compra antes de realizar la asignación.",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"indica que C3 está aprovisionando una suscripción. Espere hasta que se complete la operación.",
    "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":" Nombre para mostrar",
    "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Correo electrónico",
    "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Nombre de pila",
    "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Instrucciones",
    "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Apellido",
    "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":" Ubicación del usuario",
    "USERS_ADD_EDIT_FORM_SEATS":"asientos",
    "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Volver a usuarios",
    "USERS_ADD_OR_EDIT_CAPTION_1":"Agregar",
    "USERS_ADD_OR_EDIT_CAPTION_2":"Editar",
    "USERS_ADD_OR_EDIT_CAPTION_3":" Usuario",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Correo electrónico",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Nombre de pila",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Apellido",
    "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"¡Se completó la última carga masiva para crear usuarios!",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"No se pueden otorgar privilegios de administrador del cliente a usuarios externos",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"¿Está seguro de que desea otorgar privilegios de administrador al cliente para",
    "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Panel",
    "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Usuarios",
    "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Análisis de incorporación",
    "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Su solicitud para crear usuarios está en cola. Vuelva a esta página después de un tiempo para obtener actualizaciones. El proceso suele tardar unos minutos en completarse.",
    "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Se produjo un error al exportar los detalles del error.",
    "USERS_BULK_CREATION_FIX_ERRORS":"Corrija los errores informados en el archivo que acaba de descargar y vuelva a intentarlo.",
    "USERS_BULK_CREATION_STATUS_SUCCESS":"Su solicitud para crear usuarios se completó con éxito",
    "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Obtener detalles de usuarios cargados",
    "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"Quedan {{AvailableUnits}} de {{TotalUnits}}",
    "USERS_BUY_MORE":"Comprar más",
    "USERS_CREATE_SUCCESS":"Usuario {{username}} creado exitosamente",
    "USERS_DELETE_CONFIRM":"Se eliminarán todas las licencias asignadas y se eliminará {{userName}}. ¿Quieres continuar?",
    "USERS_DELETE_EXISTENCE_CONFIRM":" Este usuario tiene algún acceso en C3, que será eliminado. ¿Quieres continuar?",
    "USERS_DELETE_SUCCESS":"La eliminación del {{username}} se realizó correctamente",
    "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Datos no encontrados. Por favor intente mas tarde",
    "USERS_GRANT_ACCESS_CONFIRM":"¿Está seguro de que desea otorgar privilegios de administrador de clientes a {{username}}?",
    "USERS_GRANT_ACCESS_SUCCESS":"Acceso concedido a {{username}} correctamente",
    "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"No se permite otorgar acceso a usuarios externos",
    "USERS_IGNOREERRORS_IGNOR_ERRORS":"¿Estas seguro que deseas continuar?",
    "USERS_LICENSE_FREE_OUT_OF":"licencias gratis fuera de",
    "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Trabajando en la asignación de licencias para el usuario {{username}}. Este proceso tardaría unos minutos en completarse.",
    "USERS_LICENSES_REVOKE_SUCCESS":"Licencias revocadas de {{username}} exitosamente",
    "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Licencias revocadas de {{username}} exitosamente. Trabajando en la asignación de nuevas licencias. Este proceso tardará unos minutos en completarse.",
    "USERS_LICENSES_UPDATE_SUCCESS":"Licencias para el usuario {{username}} actualizadas correctamente",
    "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Añadir nuevo usuario",
    "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Carga de varios usuarios",
    "USERS_LIST_CAPTION_TEXT_USERS":"Usuarios",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Editar detalles de usuario",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Conceder acceso al portal",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Restablecer la contraseña",
    "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Ver licencias",
    "USERS_LIST_TABLE_HEADER_ACTION":"Comportamiento",
    "USERS_LIST_TABLE_HEADER_EMAIL_ID":"Identificación de correo",
    "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Está activo",
    "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"No hay roles de usuarios asignados.",
    "USERS_LIST_TABLE_HEADER_ROLE":"Funciones del CSP",
    "USERS_LIST_TABLE_HEADER_USER_NAME":"Nombre de usuario",
    "USERS_LIST_TABLE_ROLE_TOOLTIP":"Mostrar roles",
    "USERS_PASSWORD_RESET_SUCCESS":"La contraseña de {{username}} se restableció correctamente",
    "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"La contraseña se envió correctamente a los destinatarios especificados",
    "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Restablecimiento de contraseña exitoso",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"El restablecimiento de la contraseña se realizó correctamente. La contraseña es: <a href='#' ngclipboard=' data-clipboard-text='{{Contraseña}}' ng-click='vm.confirmCopy()'><b>{{Contraseña }}</b></a> <br/><br/><b>Después de salir de esta página, no podrá volver a ver esta contraseña temporal.</b><br/><br/>Si Si desea enviar la contraseña por correo electrónico, proporcione los destinatarios separados por comas en el cuadro de texto a continuación; de lo contrario, presione cancelar.<br/>",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"El restablecimiento de contraseña se realizó correctamente, la contraseña es:",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Después de salir de esta página, no podrá volver a ver esta contraseña temporal.</b><br/><br/>Si desea enviar la contraseña por correo electrónico, proporcione los destinatarios separados por comas en el cuadro de texto a continuación; de lo contrario, presione cancelar.<br/>",
    "USERS_REVOKE_ACCESS_CONFIRM":"¿Está seguro de que desea revocar {{UserRoleDescription | traducir}} privilegio de {{userName}}",
    "USERS_REVOKE_ACCESS_SUCCESS":"Acceso revocado de {{username}} exitosamente",
    "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Obtener detalles de usuarios cargados",
    "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"¡Pruebe una nueva carga!",
    "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Ver y corregir errores",
    "USERS_STATUS_CAPTION_STATUS":"Estado",
    "USERS_STATUS_WARNING_MESSAGE":"La creación de usuarios a partir de la última carga aún está en progreso. Vuelva a consultar haciendo clic en el botón 'BulkUserUpload' en la página Usuarios.",
    "USERS_TABLE_HEADER_EMAIL":"Correo electrónico",
    "USERS_TABLE_HEADER_NAME":"Nombre",
    "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Descripción del plano",
    "USERS_UPDATE_LICENCES_QUEUED":"Las licencias están en cola para su actualización",
    "USERS_UPDATE_SUCCESS":"Detalles de usuario para {{username}} actualizados exitosamente",
    "USERS_UPDATELICENCES_SUCCESS":"Licencias actualizadas exitosamente",
    "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Detalles de usuario para {{UserName}} actualizados exitosamente",
    "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Subir",
    "USERS_UPLOAD_DATA_INVALID_ERROR":"Errores detectados en los datos subidos. Descargue los errores, corríjalos e intente cargarlos nuevamente.",
    "USERS_UPLOAD_ERROR":"Se produjo un error al analizar el archivo cargado",
    "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Seleccione un archivo para cargar",
    "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Remover",
    "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Información de usuario",
    "USERS_UPLOAD_FORM_INSTRUCTIONS":"INSTRUCCIONES",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Cargue datos de cuenta para varios usuarios en formato .csv. Descargue un archivo .csv de muestra <strong><a href='#' ng-click='vm.DownloadTemplate()'>Descargue un archivo .csv de muestra</a></strong> como referencia.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":" Este archivo se puede utilizar para crear varias cuentas de usuario para un cliente. No actualice ni elimine ningún texto o dato existente en este archivo. Las cuentas de usuario se crearán con permisos de usuario (no de administrador).",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"El nombre es un campo opcional que admite de cero a 50 caracteres. Un ejemplo: Chris.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":" El apellido es un campo opcional que admite de cero a 50 caracteres. Un ejemplo: Verde.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":" El nombre para mostrar es un campo obligatorio que admite entre uno y 50 caracteres. Un ejemplo: Chris Green.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":" El correo electrónico es un campo obligatorio. Ingrese una dirección de correo electrónico válida en este campo para cada registro de usuario. Un ejemplo: chris@contoso.com.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"El campo de actualización de estado se completa automáticamente para transmitir el estado de validación. Deje este campo vacío.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Sube el archivo y haz clic en <strong class=\"text-uppercase\">enviar</strong>.",
    "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Subir archivo",
    "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Ubicación de uso",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Volver a licencias",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Volver a usuarios",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Editar licencias",
    "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Licencias asignadas para",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Fecha de asignación de licencia",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Producto",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Resumen",
    "VALIDATE_LIST":"Validar lista",
    "VALIDATE_PRODUCT_QUANTITY":"La cantidad de {{product}} {{quantity}} debe estar entre {{minQuantity}} y {{maxQuantity}}",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"No se pudo validar el historial de suscripción para el servicio externo",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Historial de suscripción validado con éxito para el servicio externo",
    "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Cliente ya vinculado a este inquilino.",
    "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Este código de cupón ya existe",
    "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"¡Solo se permiten números alfabéticos en el código de cupón!",
    "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Estamos permitiendo descuentos solo entre 0 y 100",
    "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Este correo electrónico '{{emailAddress}}' ya está en uso.",
    "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"por favor ingrese un número valido",
    "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Por favor, rellene todos los campos obligatorios antes de enviar.",
    "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"La fecha de la factura debe ser anterior a la fecha de vencimiento.",
    "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Ingrese una URL válida o ingrese la URL predeterminada como '/#/contacto/'",
    "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"La fecha de inicio debe ser anterior a la fecha de finalización.",
    "VALIDATION_MESSAGE_FOR_URL":"Por favor ingrese una URL válida.",
    "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Ingrese una URL válida o ingrese la URL predeterminada como 'home.support'",
    "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Por favor ingresa un código postal válido",
    "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"La longitud del IIN de la empresa debe ser menor o igual a 22",
    "VALIDATION_MESSAGE_MAX_LENGTH_50":"Este campo acepta un máximo de 50 caracteres.",
    "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"La longitud del IIN de la empresa debe ser superior a 4",
    "VALIDATION_MESSAGE_MINIMUM_0":"El valor mínimo es 0",
    "VALIDATION_MESSAGE_MINIMUM_1":"El valor mínimo es 1",
    "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Por favor introduzca una dirección de correo electrónico válida",
    "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Por favor, introduzca un número de teléfono válido",
    "VALIDATION_MESSAGE_REQUIRED":"Este campo es obligatorio",
    "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Introduzca una tasa de conversión válida",
    "VALIDITY_TEXT_FROM":"De",
    "VALIDITY_TEXT_TO":"A",
    "VALIDITY_TYPE_DESC_DAYS":"Días)",
    "VALIDITY_TYPE_DESC_MONTHS":"Meses)",
    "VALIDITY_TYPE_DESC_YEARS":"Años)",
    "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Tipo de validez",
    "VALIDITY_TYPE_NAME_DAYS":"Días)",
    "VALIDITY_TYPE_NAME_MONTHS":"Meses)",
    "VALIDITY_TYPE_NAME_YEARS":"Años)",
    "VALUE_SHOULD_BE_GREATER_THAN":"El valor debe ser mayor que 0.",
    "VAT_NUMBER_DESC":"Número de IVA para el cliente",
    "VAT_NUMBER_DESC_FOR_RESELLER":"Número de IVA para revendedor",
    "VAT_NUMBER_DISP":"Número de valor agregado",
    "VAT_NUMBER_DISP_FOR_RESELLER":"Número de valor agregado",
    "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Validar token",
    "VIEW_ALL_SELECTION":"Seleccionar todo",
    "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Ver más detalles",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Ver líneas de pedido de uso",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Ver líneas de pedido de uso",
    "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Generar factura",
    "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Recargar informe de rentabilidad",
    "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Agregar usuario",
    "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Borrar usuario",
    "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
    "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Nombre",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Asignar etiquetas",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Editar roles",
    "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Agregar usuario socio",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Asignar etiquetas",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Eliminar socio",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Editar roles",
    "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Gestión de usuarios de socios",
    "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Gestión de usuarios",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Comportamiento",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Correo electrónico",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"roles",
    "VIEWS_TABLE_HEADER_TEXT_ROLES":"roles",
    "viewuploadhistory":"Informe de uso",
    "WAITING_FOR_APPROVAL":"A la espera de la aprobación",
    "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":" Has deseleccionado todos los",
    "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":" Has seleccionado todos los",
    "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Introduzca un nombre de entidad",
    "WEBHHOOK_LABEL_EFFECTIVE_FROM":"Válido desde",
    "WEBHHOOK_LABEL_EFFECTIVE_TO":"Efectivo para",
    "WEBHHOOK_LABEL_ENTITY":"Entidad",
    "WEBHHOOK_LABEL_EVENT":"Evento",
    "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Entidades etiquetadas",
    "WEBHOOK_BREADCRUMB_ADD":"Crear webhook",
    "WEBHOOK_BUTTON_ACTIONS_ADD":"Crear notificación de webhook",
    "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"volver a la lista",
    "WEBHOOK_CANCEL_NOTIFICATION":"Cancelar",
    "WEBHOOK_DESCRIPTION_TOOLTIP":"Introduzca la descripción",
    "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Elija efectivo hasta la fecha",
    "WEBHOOK_END_DATE_PLACEHOLER":"Fecha final",
    "WEBHOOK_ENTITY_TOOLTIP":"Seleccionar categoría",
    "WEBHOOK_EVENT_TOOLTIP":"Elegir evento",
    "WEBHOOK_HEADER_ACTIONS":"Comportamiento",
    "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Entidades asociadas",
    "WEBHOOK_HEADER_CREATET_DATE":"Fecha de Creación",
    "WEBHOOK_HEADER_DESCRIPTION":"Descripción",
    "WEBHOOK_HEADER_EFFECTIVE_FROM":"Válido desde",
    "WEBHOOK_HEADER_EFFECTIVE_TO":"Efectivo para",
    "WEBHOOK_HEADER_EVENTNAME":"Evento",
    "WEBHOOK_HEADER_TITLE":"Título",
    "WEBHOOK_LABEL_DESCRIPTION":"Descripción",
    "WEBHOOK_LABEL_EFFECTIVE_TO":"Efectivo para",
    "WEBHOOK_LABEL_ENTITY_TEXT":"Categoría",
    "WEBHOOK_LABEL_EVENT_TEXT":"Evento",
    "WEBHOOK_LABEL_TITLE_TEXT":"Título",
    "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"método de webhook",
    "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Recuento de reintentos",
    "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"URL de webhook",
    "WEBHOOK_METHOD_METHOD_TOOLTIP":" Método a utilizar al enviar datos a un sistema externo a través de un webhook.",
    "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
    "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Se produjo un error al enviar la notificación del webhook",
    "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Notificación de webhook enviada correctamente",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Descripción",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"IDENTIFICACIÓN",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Antiguo recuento de reintentos",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"URL del webhook antiguo",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Nombre del producto",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Recuento de reintentos",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Título",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"URL de webhook",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"método de webhook",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Sólo se puede seleccionar un producto.",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Error al seleccionar producto",
    "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación de webhook creada correctamente",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Todos los productos han sido seleccionados.",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"El producto ha sido seleccionado.",
    "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Elementos de entidad asociados",
    "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Notificación de webhook actualizada correctamente",
    "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Todos los productos han sido seleccionados.",
    "WEBHOOK_SAVE_NOTIFICATION":"Entregar",
    "WEBHOOK_SELECT_PRODUCT_BUTTON":"Seleccione",
    "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Seleccionar elemento de entidad asociado",
    "WEBHOOK_START_DATE_PLACEHOLER":"Fecha de inicio",
    "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Notificación de webhook",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Esto excede la longitud máxima de 30",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Asegúrese de que el valor esté entre 0 y 3",
    "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Este campo es obligatorio",
    "WEBHOOK_TITLE_TOOLTIP":"Ingrese el título",
    "WEBHOOK_URL_TOOLTIP":" Ingrese la URL de destino donde el sistema enviará solicitudes HTTP para notificar a un servicio externo sobre eventos o actualizaciones específicas. Esta URL debe ser capaz de recibir y procesar cargas útiles de webhook.",
    "WEBHOOK_VALIDATE_BUTTON":"Validar",
    "WEBHOOK_VALIDATE_URL":"Por favor valide la URL",
    "WEBHOOK_VALIDATE_URL_BUTTON":"Validar URL",
    "WEBHOOK_VALIDATION_ERROR_MESSAGE":"URL de webhook no válida",
    "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"La URL del webhook es válida",
    "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Número de intentos que hará el sistema para realizar una acción específica en caso de falla.",
    "WEBHOOK_WEBHOOK_URL_TOOLTIP":"URL de webhook",
    "webhookNotifications":"gancho web",
    "WELCOME_HEADER_1_PART_1":"Con nosotros tienes infinitas posibilidades.",
    "WELCOME_HEADER_1_PART_2":"Y comienza ahora.",
    "WELCOME_PORTAL_FEATUR_1":"Portal de autoservicio",
    "WELCOME_PORTAL_FEATUR_2":"Agregar/eliminar suscripciones de forma rápida y sencilla",
    "WELCOME_PORTAL_FEATUR_3":"Pagos automatizados",
    "WELCOME_PORTAL_FEATUR_4":"Pagar facturas automáticamente",
    "WELCOME_PORTAL_FEATUR_5":"Seguro",
    "WELCOME_PORTAL_FEATUR_6":"Protegido con Microsoft Azure Active Directory y autenticación multifactor",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Acceso",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Bienvenido",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Cerrar sesión",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Contáctenos",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Ir al panel de control",
    "Won''t Fix":"No se arreglará",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Gracias por brindar su consentimiento a Xero. La aplicación se puede configurar para acceder a Xero siguiendo la nueva pauta de seguridad. Puede publicar las facturas de sus clientes en Xero.",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Siga las instrucciones para cargar facturas en Xero en <b>{{applicationName}}</b>.",
    "XERO_CONSENT_DESCRIPTION":"Para publicar facturas en Xero, debe proporcionar su consentimiento.",
    "XERO_CONSENT_PROCESSING_MESSAGE":"Espere mientras procesamos su consentimiento de Xero",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"El siguiente enlace se abre en una nueva pestaña. Proporcione su consentimiento con sus credenciales de Xero. Si recibe un error al brindar su consentimiento, comuníquese con nuestro equipo de soporte.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_2":" para proporcionar el consentimiento de Xero",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Si ya ha proporcionado su consentimiento, haga clic en el botón <b> Validar token </b> para verificar la conectividad con Xero.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Haga clic en este botón para verificar la validez del Xero Refresh Token existente.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Requisitos previos para que el socio publique facturas de clientes en <b> XERO </b>",
    "XERO_POST_BATCH_ID":"ID de lote de publicación de Xero",
    "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Su solicitud para publicar facturas en Xero se acepta y se pone en cola para su procesamiento. Haga clic en el botón <strong>Ver registros de publicaciones</strong> para obtener actualizaciones sobre el progreso.",
    "XERO_POST_LOGS_STATUS":"Estado de registros de publicaciones de Xero",
    "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"El token de actualización de Xero existente no es válido. Por favor proporcione su consentimiento nuevamente",
    "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"El token de actualización de Xero existente es válido. Puedes continuar subiendo Facturas",
    "XERO_TENANT_ID":"ID de inquilino de Xero",
    "Year(s)":"Años)",
    "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Puede especificar el porcentaje de impuestos para el cliente. Por ejemplo: 15 por 15%",
    "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Puede especificar el nombre del impuesto como GST..",
    "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"No tienes permiso para realizar esta acción",
    "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Debes seleccionar una o más ofertas para crear una suscripción." 
  },
};
