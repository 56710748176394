export class PartnerCustomOfferDetails {
    Id?: number;
    ID?: number | null;
    ProductId?: number | null;
    ProductVariantId?: number | null;
    Name?: string;
    Description?: string;
    ProviderId?: number | null;
    ProviderName?: string;
    ConsumptionTypeId?: number | null;
    ConsumptionType?: string;
    ConsumptionDescription?: string;
    ProviderSettings?: string;
    Settings?: string;
    BillingCycleId?: number | null;
    BillingCycleName?: string;
    BillingCycleDescription?: string;
    CurrencyCode?: string;
    CurrencySymbol?: any;
    PriceforPartner?: number | null;
    ProviderSellingPrice?: number | null;
    CategoryId?: number | null;
    CategoryName?: string;
    CategoryDescriptionKey?: string;
    IsImmediateProvisioning?: boolean | null;
    OnPurchaseBillingAction?: number | null;
    OnReleaseBillingAction?: number | null;
    BillingPeriodType?: number | null;
    IsActive?: boolean | null;
    ProviderReferenceId?: string;
    PlanProductId?: number | null;
    ParentPlanProductId?: number | null;
    ParentProductName?: string;
    EnabledForImmediateProvisioning?: boolean | null;
    FeedSource?: number | null;
    SaleType?: number | null;
    SaleTypeDescription?: string;
    IsAutoRenewal?: boolean | null;
    NoOfDaysForFreeCancelation?: number | null;
    Validity?: number | null;
    ValidityType?: string;
    BillingTypeId?: number | null;
    BillingTypeName?: string;
    BillingTypeDescription?: string;
    IsAvailableForBundling?: boolean | null;
    CurrencyDecimalPlaces?: string;
    CurrencyDecimalSeperator?: string;
    CurrencyThousandSeperator?: string;
    TotalPartnerProductCount?: number | null;
    OnPurchaseBillingActionName?: string;
    OnReleaseBillingActionName?: string;
    SaleTypeName?: string;
    Status?: string;
    ProductForTrial?: number | null;
    IsTrialOffer?: boolean;
    OfferRefId?:string;
    Instructions?:string;
    ImageUrl?:string;
    Slabs?:any;
    IsAddOn?:boolean;
    Availability?:any;
    ApprovalQuantity?:number;
    TrailQuantity?:number;
    SupportedMarketId?:number;
    ProductProviderPricingId?:number;
    Region?:number;
    MarketCode?:any;
    SugestedSellingPrice?:any;
    ValidityData?:any;
    BundledOffers?:any;
    TrialPeriodOption?:any;
    CustomOfferBillingPeriodType?:any
}

export class PartnerContractOfferDetails {
    ID: number;
    ProductId: number | null;
    ProductVariantId: number | null;
    Name: string = '';
    Description: string = '';
    ProviderId: number | null;
    ProviderName: string = '';
    ConsumptionTypeId: number | null;
    ConsumptionType: string = '';
    ConsumptionDescription: string = '';
    ProviderSettings: string = '';
    Settings: string = '';
    BillingCycleId: number | null;
    BillingCycleName: string = '';
    BillingCycleDescription: string = '';
    CurrencyCode: string = '';
    CurrencySymbol: any;
    PriceforPartner: number | null;
    ProviderSellingPrice: number | null;
    CategoryId: number | null;
    CategoryName: string = '';
    CategoryDescriptionKey: string = '';
    ImageUrl:any;
    IsImmediateProvisioning: boolean | null;
    OnPurchaseBillingAction: number | null;
    OnReleaseBillingAction: number | null;
    BillingPeriodType: number | null;
    IsActive: boolean | null;
    ProviderReferenceId: string = '';
    PlanProductId: number | null;
    ParentPlanProductId: number | null;
    ParentProductName: string;
    EnabledForImmediateProvisioning: boolean | null;
    FeedSource: number | null;
    SaleType: number | null;
    SaleTypeDescription: string = '';
    IsAutoRenewal: boolean | null;
    NoOfDaysForFreeCancelation: number | null;
    Validity:any;
    ValidityType: string = '';
    BillingTypeId: number | null;
    BillingTypeName: string = '';
    BillingTypeDescription: string = '';
    IsAvailableForBundling: boolean | null;
    CurrencyDecimalPlaces: string = '';
    CurrencyDecimalSeperator: string = '';
    CurrencyThousandSeperator: string = '';
    TotalPartnerProductCount: number | null;
    OnPurchaseBillingActionName: string = '';
    OnReleaseBillingActionName: string = '';
    SaleTypeName: string = '';
    Status: string = '';
    ProductForTrial: number | null;
    IsTrialOffer: string;
    OfferRefId:string;
    Instructions:string;
    Slabs:any;
    IsAddOn:boolean;
    Availability:any;
    ApprovalQuantity:number;
    TrailQuantity:number;
    SupportedMarketId:number;
    ProductProviderPricingId:number;
    Region:number;
}
