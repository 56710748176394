@if(showSideMenu){
<!--begin::take a tour-->
<div class="app-navbar-item take-tour" [ngClass]="itemClass">
  <!--begin::Search-->
  <div id="kt_header_search" class="header-search d-flex align-items-stretch" data-kt-search-keypress="true"
       data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu"
       data-kt-menu-trigger="{default: 'hover'}" data-kt-menu-overflow="false" data-kt-menu-permanent="true"
       data-kt-menu-placement="bottom-end" data-kt-search="true">
    <!--begin::Search toggle-->

    <div class="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
      @if(impersonated){
      <button class="btn btn-outline-danger  mr-2 " (click)="stopImpersonation()">
        <span translate="TRANSLATE.HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION"></span>
      </button>
      }@else {
      <!-- <button class="btn btn-sm fw-bold btn-warning me-2" (click)="openTable()" > <span
          translate="TRANSLATE.BUTTON_START_TOUR"></span></button> -->
      }

    </div>
    <!--end::Search toggle-->
  </div>

</div>
<!--end::take a tour-->
<!--begin::Environments-->
@if( !impersonated && availableEnvironments?.length>1){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       class="btn btn-icon btn-active-light-primary btn-lg me-1 p-4 w-auto" data-bs-toggle="dropdown" aria-expanded="false">
    <span class="svg-icon svg-icon-primary svg-icon-xl svg-icon-sm svg-icon-lg me-md-2 h-20px w-20px mb-3">
      <span class="svg-icon svg-icon-primary svg-icon-2x">
        <!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo1\dist/../src/media/svg/icons\Home\Earth.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#3699FF" opacity="0.3" cx="12" cy="12" r="9"></circle>
                <path d="M11.7357634,20.9961946 C6.88740052,20.8563914 3,16.8821712 3,12 C3,11.9168367 3.00112797,11.8339369 3.00336944,11.751315 C3.66233009,11.8143341 4.85636818,11.9573854 4.91262842,12.4204038 C4.9904938,13.0609191 4.91262842,13.8615942 5.45804656,14.101772 C6.00346469,14.3419498 6.15931561,13.1409372 6.6267482,13.4612567 C7.09418079,13.7815761 8.34086797,14.0899175 8.34086797,14.6562185 C8.34086797,15.222396 8.10715168,16.1034596 8.34086797,16.2636193 C8.57458427,16.423779 9.5089688,17.54465 9.50920913,17.7048097 C9.50956962,17.8649694 9.83857487,18.6793513 9.74040201,18.9906563 C9.65905192,19.2487394 9.24857641,20.0501554 8.85059781,20.4145589 C9.75315358,20.7620621 10.7235846,20.9657742 11.7357634,20.9960544 L11.7357634,20.9961946 Z M8.28272988,3.80112099 C9.4158415,3.28656421 10.6744554,3 12,3 C15.5114513,3 18.5532143,5.01097452 20.0364482,7.94408274 C20.069657,8.72412177 20.0638332,9.39135321 20.2361262,9.6327358 C21.1131932,10.8600506 18.0995147,11.7043158 18.5573343,13.5605384 C18.7589671,14.3794892 16.5527814,14.1196773 16.0139722,14.886394 C15.4748026,15.6527403 14.1574598,15.137809 13.8520064,14.9904917 C13.546553,14.8431744 12.3766497,15.3341497 12.4789081,14.4995164 C12.5805657,13.664636 13.2922889,13.6156126 14.0555619,13.2719546 C14.8184743,12.928667 15.9189236,11.7871741 15.3781918,11.6380045 C12.8323064,10.9362407 11.963771,8.47852395 11.963771,8.47852395 C11.8110443,8.44901109 11.8493762,6.74109366 11.1883616,6.69207022 C10.5267462,6.64279981 10.170464,6.88841096 9.20435656,6.69207022 C8.23764828,6.49572949 8.44144409,5.85743687 8.2887174,4.48255778 C8.25453994,4.17415686 8.25619136,3.95717082 8.28272988,3.80112099 Z M20.9991771,11.8770357 C20.9997251,11.9179585 21,11.9589471 21,12 C21,16.9406923 17.0188468,20.9515364 12.0895088,20.9995641 C16.970233,20.9503326 20.9337111,16.888438 20.9991771,11.8770357 Z" fill="#3699FF" opacity="0.3"></path>
            </g>
        </svg><!--end::Svg Icon-->
    </span>
    </span>
    <span class="user-detail">{{isSelectedEnvironment}}</span>
    <i class="ki-duotone ki-down ms-2 fs-6 profile-toggle-button"></i>
  </div>
  <div class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
       data-kt-menu='true'>
    @for(environment of availableEnvironments; track $index){
    <div class='menu-item my-0' (click)="setEnvironment(environment)">
      <a class="menu-link px-3 py-2 d-flex align-items-center gap-3">
        <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
          <span class="navi-text">{{environment?.AppName}}</span>
        </span>
      </a>
    </div>
    }
  </div>
</div>
}
<!--end::Environments-->
<!--begin::User context-->
@if (userContextList?.length > 1 && !impersonated){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       class="btn btn-icon btn-active-light-primary btn-lg me-1 p-4 w-auto" data-bs-toggle="dropdown" aria-expanded="false">
    <span class="user-detail">{{selectedUserContext}}</span>
    <i class="ki-duotone ki-down ms-2 fs-6 profile-toggle-button"></i>
  </div>
  <div class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold  fs-base w-325px p-5 mb-1'
       data-kt-menu='true'>
    <div class='menu-item'>
      <!-- <select2 [data]="userContextData" (update)="onContextChange($event)"
        placeholder="{{'TRANSLATE.PLACE_HOLDER_SEARCH_CUSTOMER' | translate}}">
      </select2> -->
      <input type="text" class="form-control form-control-sm active-light-primary mb-2"
             placeholder="{{'TRANSLATE.PLACE_HOLDER_SEARCH_CUSTOMER' | translate}}" [(ngModel)]="userSearch" (input)="filterUsers()">
    </div>
    <div *ngFor="let user of filterUserContextData" (click)="onContextChange(user)">
      <div class='menu-item my-2 px-1 cursor-pointer btn btn-sm btn-active-light-primary w-267px text-start'>
        <span class="">{{ user.label }}</span>
      </div>
    </div>
  </div>
</div>
}

<!--end::user context-->
<!--begin::language dropdown-->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent"
       data-kt-menu-placement="bottom-end" class="btn btn-icon  btn-active-light-primary btn-dropdown btn-lg me-1"
       data-bs-toggle="dropdown" aria-expanded="false">
    <img class="h-20px w-20px rounded" [src]="getFlagImgPath(language)">
    <i class="ki-duotone ki-down ms-2 fs-6"></i>
  </div>
  <div class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
       data-kt-menu='true'>
    @for(item of langs; track $index){
    <div class='menu-item my-0' (click)="selectLanguage(item)">
      <a *ngIf="item === 'en-us' || item === 'en'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
        <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
          <img class="h-20px w-20px rounded" src="./assets/media/flags/united-states.svg">
          <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_EN"></span>
        </span>
    </a>
    
    <a *ngIf="item === 'es'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/spain.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_ES"></span>
      </span>
    </a>
    <a *ngIf="item === 'cn' || item === 'ch'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/china.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_CN"></span>
      </span>
    </a>
    <a *ngIf="item === 'tw'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/taiwan.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_TW"></span>
      </span>
    </a>
    <a *ngIf="item === 'fr'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/france.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_FR"></span>
      </span>
    </a>
    <a *ngIf="item === 'th'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/thailand.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_TH"></span>
      </span>
    </a>
    <a *ngIf="item === 'tr'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/turkey.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_TR"></span>
      </span>
    </a>
    <a *ngIf="item === 'de'" (click)="selectLanguage(item)" class="menu-link px-3 py-2 d-flex align-items-center gap-3">
      <span class="menu-icon d-flex gap-3 w-100 justify-content-start">
        <img class="h-20px w-20px rounded" src="./assets/media/flags/germany.svg">
        <span class="navi-text" translate="TRANSLATE.BUTTON_LANG_DE"></span>
      </span>
    </a>
    </div>
    }
  </div>
</div>
<!--end::language dropdow-->
<!--begin::activities-->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass" *ngIf="false">
  <!-- begin::Drawer toggle -->
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" id="kt_activities_toggle">
    <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
      <i class="ki-outline ki-chart-simple-2 text-primary">
        <!-- <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span> -->
      </i>
    </span>
  </div>
  <!-- end::Drawer toggle -->
</div>
<!--end::activities-->
<!--begin::support-->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()" ngbTooltip="{{'TRANSLATE.MENU_PARTNER_SUPPORT_LOG' | translate}}" #tooltip="ngbTooltip">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a  href="{{supportLink}}" target="_blank" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i class="ki-outline ki-delivery-24 fs-2 text-primary">
            <!-- <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span> -->
          </i>
        </span>
      </a>
    </div>
  </div>
</div>
<!--end::support-->
<!--begin::audit-->
@if (this.permissions.HasGetAuditLogsForPartner.toLowerCase() === cloudHubConstants.ACCESS_TYPE_ALLOWED.toLowerCase() || this.permissions.HasGetAuditLogsForCustomer.toLowerCase() === cloudHubConstants.ACCESS_TYPE_ALLOWED.toLowerCase() || this.permissions.HasGetAuditLogsForReseller.toLowerCase() === cloudHubConstants.ACCESS_TYPE_ALLOWED.toLowerCase()) {
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.MENU_PARTNER_AUDIT_LOG' | translate}}"  #tooltip="ngbTooltip"
  (mouseenter)="tooltip.open()"
  (mouseleave)="tooltip.close()">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a routerLink="/partner/partnerauditlog" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span [ngClass]="toolbarButtonIconSizeClass" class="svg-icon theme-light-hide text-primary">
          <i class="ki-outline ki-book text-primary">
            <!-- <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span> -->
          </i>
        </span>
      </a>
    </div>
  </div>
</div>
}
<!--end::audit-->
<!--begin::notifications-->
@if (this.permissions.HasGetContactLogs.toLowerCase() === cloudHubConstants.ACCESS_TYPE_ALLOWED) {
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.MENU_CONTACT_LOG' | translate}}" #tooltip="ngbTooltip" (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a routerLink="/home/notifications" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i class="ki-outline ki-notification text-primary"></i>
        </span>
      </a>
    </div>
  </div>
</div>
}
<!--end::notifications-->
<!--begin::comments-->
@if (['Customer','Site', 'SiteDepartment'].includes(entityName)){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.MENU_CUSTOMER_COMMENTS' | translate}}" #tooltip="ngbTooltip" (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a routerLink="/customer/comments" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i class="ki-outline ki-message-text-2 text-primary"></i>
        </span>
      </a>
    </div>
  </div>
</div>
}

<!--end::comments-->
<!--begin::Cart-->
@if(['Customer','Site', 'SiteDepartment'].includes(entityName) && permissions.HasViewCartAllowed.toLowerCase() === cloudHubConstants.ACCESS_TYPE_ALLOWED){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.PUBLIC_SIGNUP_PORTLET_HEADER_CART' | translate}}" #tooltip="ngbTooltip" (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()" >
    <div [routerLink]="['/customer/cart']" rel="noopener noreferrer" class="link-to-support" class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass">
      <a tabindex="0">
        @if (cartCount > 0){
        <span class="position-absolute top-0 translate-middle badge badge-primary rounded-circle">
          {{cartCount}}
        </span>
        <span class="pulse-ring"></span>
        }
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i class="ki-outline ki-handcart text-primary"></i>
        </span>
      </a>
    </div>
  </div>
</div>
}
<!--end::Cart-->
<!--begin::settings-->
@if (!['Customer'].includes(entityName)){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  @for(data of navMenu; track $index){
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.MENU_TOPBAR_SETTINGS' | translate}}" #tooltip="ngbTooltip" (mouseenter)="tooltip.open()"(mouseleave)="tooltip.close()">
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a routerLink="/partner/settings" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i [ngClass]="data.Icon" class="ki-outline ki-setting-2 text-primary"></i>
        </span>
      </a>
    </div>
  </div>
  }
</div>
}

<!--end::settings-->
<!--begin::organizational-profile-->
@if (['Reseller',].includes(entityName)){
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="topbar-item" ngbTooltip="{{'TRANSLATE.MENU_RESELLER_PROFILE' | translate}}"#tooltip="ngbTooltip" (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()" >
    <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass">
      <a routerLink="/home/profile/user" rel="noopener noreferrer" class="link-to-support" tabindex="0">
        <span class="svg-icon" [ngClass]="toolbarButtonIconSizeClass">
          <i class="ki-outline ki-people text-primary"></i>
        </span>
      </a>
    </div>
  </div>
</div>
}

<!--end::organizational-profile-->
<!--begin::Theme mode-->
<div class="app-navbar-item" [ngClass]="itemClass" *ngIf="false">
  <app-theme-mode-switcher [toggleBtnClass]="btnClass"
                           toggleBtnClass="{`btn-active-light-primary btn-custom ${toolbarButtonHeightClass}`}"></app-theme-mode-switcher>
</div>
<!--end::Theme mode-->
<!--begin::user-profile-dropdown-->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div data-kt-menu-trigger="{default: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
       class="btn btn-icon btn-active-light-primary btn-lg me-1 w-auto" data-bs-toggle="dropdown" aria-expanded="false">
    <span class="svg-icon svg-icon-primary svg-icon-xl svg-icon-sm svg-icon-lg me-md-2 h-20px w-20px">
      <i class="ki-outline ki-user text-primary">
        <!-- <span class="path1"></span>
        <span class="path2"></span> -->
      </i>
    </span>
    <span class="user-detail">{{userName}}</span>
    <i class="ki-duotone ki-down ms-2 fs-6 profile-toggle-button"></i>
  </div>
  <div class='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
       data-kt-menu='true'>
    <div class='menu-item my-0' (click)="goToContactUs()">
      <a class="menu-link px-3 py-2 d-flex align-items-center gap-2">
        <span class="svg-icon m-0 profile-info">
          <i class="ki-outline ki-address-book text-primary">
            <!-- <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span> -->
          </i>
        </span>
        <span class="ml-2 ng-scope" translate="TRANSLATE.HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US"></span>
      </a>
    </div>
    @if(!impersonated){
    <div class='menu-item my-0' (click)="logOut()">
      <a class="menu-link px-3 py-2 d-flex align-items-center gap-2">
        <span class="svg-icon m-0 profile-info">
          <i class="ki-outline ki-entrance-left text-primary">
            <!-- <span class="path1"></span>
            <span class="path2"></span> -->
          </i>
        </span>
        <span class="ml-2 ng-scope" translate="TRANSLATE.HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT"></span>
      </a>
    </div>
    }

  </div>
</div>
<!--end::user-profile-dropdown-->
}
@else{
<div class="d-flex justify-content-end align-items-center">
  @if(IsAuthenticated()){
  <a class="text-warning">
    <span translate="TRANSLATE.WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME"></span>
    &nbsp;
    <span>{{userName}}</span>
  </a>
  &nbsp;&nbsp;
  <a class="text-warning">
    <span translate="TRANSLATE.WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD"></span>
  </a>
  &nbsp;&nbsp;
  <a class="text-warning" (click)="logOut()">
    <span translate="TRANSLATE.WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT"></span>
  </a>
  }
</div>
}
