import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataSharingModel } from '../shared/models/common';

@Injectable({
  providedIn: 'root'
})
export class CommonEventTrigerredService {

  private subject = new Subject<any>();
  private cartCount = new Subject<any>();

  constructor() { } 

  setDataForParentFromChild(message: DataSharingModel) {
    this.subject.next(message);
  }

  receiveDataInParent(): Observable<any> {
    return this.subject.asObservable();
  }

  setDataForCartCount(message: any) { 
    this.cartCount.next(message);
  }

  receiveDataInParentCartCount(): Observable<any> {
    return this.cartCount.asObservable();
  }
}
