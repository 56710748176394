// loader.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private ___loadingSubject = new BehaviorSubject<boolean>(false);
    public $commonLoadingSubject = new BehaviorSubject<boolean>(false); 

    private count:number=0;

    startLoading(): void { 
        this.___loadingSubject.next(true); 
    }

    commonStartLoading(): void {
        this.count++; 
        this.$commonLoadingSubject.next(true);
    }

    commonStopLoading(): void {
        this.count--;
        if(this.count < 1){
            this.$commonLoadingSubject.next(false);
        } 
    }

    resetCommonLoader(): void {
        this.count=0;
        this.$commonLoadingSubject.next(false); 
    }

    stopLoading(): void {
        this.___loadingSubject.next(false);
    }

    isLoading(): Observable<boolean> {
        return this.___loadingSubject.asObservable();
    }

    isCommonLoading(): Observable<boolean> {
        return this.$commonLoadingSubject.asObservable();
    }
}
