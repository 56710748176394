import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { DynamicTemplateService } from 'src/app/services/dynamic-template.service';
import { FileService } from 'src/app/services/file.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ToastService } from 'src/app/services/toast.service';
import { C3BaseComponent } from 'src/app/shared/models/c3BaseComponent';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { mapParamsWithApi } from 'src/app/modules/standalones/c3-table/c3-table-utils';
import { catchError, interval, of, Subscription, switchMap } from 'rxjs';
import { PageInfoService } from 'src/app/_c3-lib/layout';
import { QuoteService } from '../quotes.service';
import moment from 'moment';
import { CloudHubConstants } from 'src/app/shared/models/constants/cloudHubConstants';
import { NgClass } from '@angular/common';
import { IndividualConfig } from 'ngx-toastr';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { C3DatePipe } from 'src/app/shared/pipes/dateTimeFilter.pipe';


@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrl: './quote-list.component.scss'
})
export class QuoteListComponent extends C3BaseComponent implements OnInit, OnDestroy {
  datatableConfig: ADTSettings | any;
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  name: string = null;
  @ViewChild('actions') actions: TemplateRef<any>;
  @ViewChild('nameTemplate') nameTemplate: TemplateRef<any>;
  @ViewChild('QuoteStatus') QuoteStatus: TemplateRef<any>;
  @ViewChild('amount') amount: TemplateRef<any>;
  activeCustomers: any[] = [];
    // Reload emitter inside datatable
    reloadEvent: EventEmitter<boolean> = new EventEmitter();
    PageMode = "list";
    allSelected = true;
    statusFilter = [];
    owners = [];
    allOwnerSelected = true;
    selectStatus = [];
    selectQuote = [];
    selectStatuslist : any [];
    selectQuoteOwnerlist: any[];
    entityName: any;
    public toastrConfig: Partial<IndividualConfig> = {
      enableHtml: true
    };



  constructor(
    private cdRef: ChangeDetectorRef,
    public _router: Router,
    public _permissionService: PermissionService,
    public _dynamicTemplateService: DynamicTemplateService,
    private _translateService: TranslateService,
    private _notifierService: NotifierService,
    private _toastService: ToastService,
    private pageInfo: PageInfoService,
    private _fileService: FileService,
    private _common: CommonService,
    private renderer: Renderer2,
    private _cdRef: ChangeDetectorRef,
    private _quotesService: QuoteService,
    private _appService: AppSettingsService,
    

  ) {
    super(_permissionService, _dynamicTemplateService, _router, _appService)
    this._quotesService = _quotesService;
    this._notifierService = _notifierService;
  }

  get cloudHubConstants() {
    return CloudHubConstants;
  }

  permissions =
    {
      ApproveQuote: "Denied",
      DeleteQuote: "Denied",
      SaveQuote: "Denied"
    };

  HasPermissions() {
    this.permissions.ApproveQuote = this._permissionService.hasPermission(('APPROVE_QUOTE'));
    this.permissions.DeleteQuote = this._permissionService.hasPermission(('DELETE_QUOTE'));
    this.permissions.SaveQuote = this._permissionService.hasPermission(('SAVE_QUOTE'));
  }
  


  ngOnInit(): void {
    this.HasPermissions();
    this.handleTableConfig();
    this.getStatus();
    this.getQuoteOwner();
    this.getActiveCustomers();
    this.entityName = this._common.entityName;

    if(this._common.entityName === 'Partner' ) {
      this.pageInfo.updateTitle(this._translateService.instant("TRANSLATE.MENU_BREADCRUMB_BUTTON_TEXT_QUOTES"), true);
      this.pageInfo.updateBreadcrumbs(['SIDEBAR_TITLE_MENUS_SELL_DIRECT','MENU_BREADCRUMB_BUTTON_TEXT_QUOTES']);
    }
    else if(this._common.entityName === 'Reseller'){
      this.pageInfo.updateTitle(this._translateService.instant("TRANSLATE.MENU_BREADCRUMB_BUTTON_TEXT_QUOTES"), true);
      this.pageInfo.updateBreadcrumbs(['SIDEBAR_TITLE_MENUS_SELL','MENU_BREADCRUMB_BUTTON_TEXT_QUOTES']);
    }
  }

  
  handleTableConfig() { 
    setTimeout(() => {
      const self = this;
      this.datatableConfig = {
        serverSide: true,
        pageLength: (this._appService.$rootScope.DefaultPageCount || 10),
        ajax: (dataTablesParameters: any, callback: any) => {
          const { StartInd, SortColumn, SortOrder, length, QuotationName, CustomerName } =
            mapParamsWithApi(dataTablesParameters);
          const searchParams = {
            SortColumn,
            SortOrder,
            PageCount: length -1,
            PageIndex:  (StartInd - 1) * length + 1,
            EntityName: this._common.entityName,
            RecordId: this._common.recordId,
            SearchKeyWord: QuotationName,
            CustomerName: CustomerName,
            StatusIds: Array.isArray(this.selectStatuslist) ? this.selectStatuslist.join(',') : undefined,
            QuoteOwnerIds: Array.isArray(this.selectQuoteOwnerlist) ? this.selectQuoteOwnerlist.join(',') : undefined,
          }
          this._subscription && this._subscription?.unsubscribe();
          this._subscription = this._quotesService.getquotesList(searchParams).subscribe(({ Data }: any) => {
              let recordsTotal = 0;
 
              if (Data.length > 0) {
                [{ TotalQuoteCount: recordsTotal }] = Data; 
              }
              callback({
                data: Data,
                recordsTotal: recordsTotal || 0,
                recordsFiltered: recordsTotal || 0,
              });
            });
        },

        columns: [
          {
            searchable: true,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_NAME'),
            data: 'QuotationName', 
            className: 'col-md-2',
            ngTemplateRef: {
              ref: this.nameTemplate,
              context: {
                // needed for capturing events inside <ng-template>
                captureEvents: self.onCaptureEvent.bind(self),
              },
            },
          },
          {
            orderable:false,
            searchable: true,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME'),
            data: 'CustomerName',
            className: 'col-md-2'
          },
          {
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_CREATE_DATE'),
            data: 'CreateDate',
            className: 'col-md-2',
            render: (data: string) => {
              var datePipe = new C3DatePipe(this._appService);
              return datePipe.transform(data);
            },
          },
          {
            orderable:false,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE'),
            data: 'ExpirationDate',
            className: 'col-md-2',
            render: (data: string) => {
              var datePipe = new C3DatePipe(this._appService);
              return datePipe.transform(data);
            },
          },
          {
            orderable:false,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_STATUS'),
            data: 'QuoteStatus',
            className: 'col-md-1',
            defaultContent: '',
            ngTemplateRef: {
              ref: this.QuoteStatus,
              context: {
                // needed for capturing events inside <ng-template>
                captureEvents: self.onCaptureEvent.bind(self),
              },
            },
          },
          {
            orderable:false,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER'),
            data: 'QuoteOwner',
            className: 'col-md-2',
          },
          {
            orderable:false,
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT'),
            data: 'Amount',
            className: 'col-md-2 text-end',
            defaultContent: '',
            ngTemplateRef: {
              ref: this.amount,
              context: {
                // needed for capturing events inside <ng-template>
                captureEvents: self.onCaptureEvent.bind(self),
              },
            },
          },
          {
            title: this._translateService.instant('TRANSLATE.QUOTE_TABLE_HEADER_TEXT_ACTION'),
            className: 'col-md-2 text-end',
            defaultContent: '',
            orderable: false,
            ngTemplateRef: {
              ref: this.actions,
              context: {
                // needed for capturing events inside <ng-template>
                captureEvents: self.onCaptureEvent.bind(self),
              },
            },
          },
        ],
        
      };
      this._cdRef.detectChanges();
    });
  }
  onCaptureEvent(event: Event) { }

  getStatus() {
    this._quotesService.getStatus().subscribe(response => {
      this.statusFilter = response.Data;
      console.log(this.statusFilter);
      this.statusFilter.forEach((v) =>{v.selected = true})
      this.selectStatus = this.statusFilter;
    });
  }

  getQuoteOwner(){
    this._quotesService.getQuoteOwner().subscribe(response => {
      this.owners = response.Data;
      console.log(this.owners);
      this.owners.forEach((v) =>{v.selected = true})
      this.selectQuote = this.owners;
    });
  }

  updatePageMode(pageMode) {
    this.PageMode = pageMode;
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    this.statusFilter.forEach((status) => {
      status.selected = this.allSelected;
    });
    this.filterQuoteByStatus();
    this._cdRef.detectChanges();
    this.reloadEvent.emit(true);
  }
  
  toggleStatusSelection(selectedStatus: any) {
    selectedStatus.selected = !selectedStatus.selected;
    this.allSelected = this.statusFilter.every((status) => status.selected);
    this.filterQuoteByStatus();
    this._cdRef.detectChanges();
    this.reloadEvent.emit(true);
  }
  

  filterQuoteByStatus() {
    this.selectStatuslist = [];
    this.selectStatus.forEach((item) => {
      if (item.selected == true) {
        this.selectStatuslist.push(item.ID);
      }
    })
    this.FilterQuote();
  }


  ToggleAllOwnerSelection() {
    this.allOwnerSelected = !this.allOwnerSelected;
    this.owners.forEach((owner) => {
      owner.selected = this.allOwnerSelected;
    });
    this.selectQuote = this.allOwnerSelected ? [...this.owners] : [];
    this.filterQuoteByQuoteOwner();
    this._cdRef.detectChanges();
    this.reloadEvent.emit(true);
  }
  
  ToggleQuoteOwnerSelection(owner) {
    owner.selected = !owner.selected;
    this.allOwnerSelected = this.owners.every((status) => status.selected);
    this.filterQuoteByQuoteOwner();
    this._cdRef.detectChanges();
    this.reloadEvent.emit(true);
  }

  filterQuoteByQuoteOwner() {
    this.selectQuoteOwnerlist = [];
    this.owners.forEach((item) => {
      if (item.selected === true) {
        this.selectQuoteOwnerlist.push(item.QuoteOwnerId);
      }
    });
    this.FilterQuote();
  }

  FilterQuote() {
    this.reloadEvent.emit(true);
  }

  getActiveCustomers() {
    this._common.getCustomers().subscribe(function (response: any) {
        this.activeCustomers = response.Data;
    });
  }

  QuoteApproval(quotationId, quoteVersionId, isQuoteLineItemsDirectPurchaseAllowed, currencyCode, CustomerIdInt){
    let isActiveCustomer = false;
    this._common.getCustomers().subscribe((response: any) => {
        this.activeCustomers = response.Data;
        //Check if customer exists then only process the quote
        if (this.activeCustomers) {
          this.activeCustomers.forEach((customer: any) => {
              if (customer.ID == CustomerIdInt) {
                  isActiveCustomer = true;
              }
          })
      }
      if (isActiveCustomer) {
          var confirmationText = null;
          if (isQuoteLineItemsDirectPurchaseAllowed) {
              confirmationText = 'CONFIRMATION_TEXT_AUTOMATIC_PURCHASE'
          }
          else {
              confirmationText = 'CONFIRMATION_TEXT_MANUAL_PURCHASE'
          }
          this._notifierService.aprrove({ title: this._translateService.instant('TRANSLATE.'+confirmationText), confirmButtonColor: "green" }).then((result) => {
            if (result.isConfirmed ) {
              var reqBody = {
                QuoteId: quotationId,
                QuoteVersionId: quoteVersionId,
                RecordId: this._common.recordId,
                EntityName: this._common.entityName,
                CurrencyCode: currencyCode,
                IsQuoteLineItemsDirectPurchaseAllowed: isQuoteLineItemsDirectPurchaseAllowed
              };

            this._quotesService.approveQuote(reqBody).pipe(
              catchError((err) => {
                let errmsg: string = this._translateService.instant('TRANSLATE.' + err.error.ErrorMessage);
                this._toastService.error(errmsg, {
                  timeOut: 5000
                });
                return of(null);
              })
            )
            .subscribe((response: any) => {
                if (response?.Data != null) {
                    var orderNumber = response.Data;
                    if (orderNumber != null && orderNumber != "NO_ORDER_PLACED") {
                      this._notifierService.success({title:this._translateService.instant('TRANSLATE.OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE', { OrderNumber: orderNumber })});
                    }
                    else if (orderNumber != null && orderNumber == "NO_ORDER_PLACED") {
                        this._notifierService.success({title:this._translateService.instant('TRANSLATE.OUOTE_APPROVAL_SUCCESS_MESSAGE')});
                    }
                    this.reloadEvent.emit(true);
                }
            })
          }
          });
      }
      else {
          this._toastService.error(this._translateService.instant("TRANSLATE.QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR"));
      }
    });
  }

  editQuote(quoteVersionId, quoteStatus){
    this._router.navigate(['partner/createquotes'], {state:{ QuoteVersionId: quoteVersionId, pageType: 'Edit', QuotesStatus: quoteStatus }});
  }

  deleteQuote(quoteId) {
    const reqBody = {
      QuoteId: quoteId,
      QuoteStatus: CloudHubConstants.QUOTE_LIST_STATUS_DELETED,
      EntityName: this._common.entityName,
      RecordId: this._common.recordId,
      LoggedInUser: this._common.loggedInUserName
    }
    const confirmationMessage = this._translateService.instant('TRANSLATE.POPUP_DELETE_QUOTE_CONFIRMATION_TEXT');
    this._notifierService.confirm({ title: confirmationMessage }).then((result: { isConfirmed: any; isDenied: any; }) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this._quotesService.deleteQuote(reqBody).subscribe(response => {
          this._toastService.success(this._translateService.instant('TRANSLATE.DELETE_QUOTE_SUCCESSFULLY'));
          this.reloadEvent.emit(true);
        });
      }
    });
  }

  addQuote() {
    this._router.navigate(['partner/createquotes'], {state:{ QuoteVersionId: 0, pageType: 'Add' }});
  }

  viewQuote(quoteVersionId) {
    this._router.navigate(['partner/createquotes'], {state:{ QuoteVersionId: quoteVersionId, pageType: 'View' }});
  }

  copyToClipboard(quoteVersionId: string): void {
    let env: any = localStorage.getItem('AvailableEnvironments');
    env = JSON.parse(env);
    let envid = env[0]?.Id;
  
    this._quotesService.getQuoteDetails(quoteVersionId).subscribe((response: any) => {
      const quoteDetails = response.Data;
      const quoteURL = quoteDetails.QuoteDetails.QuoteURL;
  
      const shareableUrl = `${window.location.protocol}//${window.location.host}/quote/${envid}/${quoteURL}`;
  
      navigator.clipboard.writeText(shareableUrl).then(() => {
        this._notifierService.success({
          title: this._translateService.instant('TRANSLATE.QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE')
        });
      }).catch(() => {
        this._notifierService.alert({
          title: this._translateService.instant('TRANSLATE.QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE')
        });
      });
    });
  }
  

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
 
}
