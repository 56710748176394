
<div id="pdf" class="p-4">
  <div class="brand flex-column-auto d-flex justify-content-center align-items-center bg-white pl-7" id="kt_brand">
    <a *ngIf="PDFlogobase64">
      <img [src]="PDFlogobase64" alt="logo" class="img-fluid" style="max-width: 65px !important" />
    </a>
  </div>
  <br>

  <!-- <pre>{{selectedCustomerDetails | json}}</pre> -->

  <h3 class="text-center mt-1 pr-6" *ngIf="frmAddQuote_QuoteName && selectedCustomerDetails?.CustomerName">
    {{selectedCustomerDetails?.CustomerName}} - {{frmAddQuote_QuoteName}}
  </h3>
  <br>
  <div class="row">
    <div class="col-md-6 pr-3">
      <h5 class="text-break">
        <span *ngIf="selectedCustomerDetails?.CustomerName">{{ selectedCustomerDetails?.CustomerName }}</span>
      </h5>
      <div class="text-muted d-flex flex-wrap align-items-center mb-10" *ngFor="let row of addressDetails">
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
          <!-- <span>
              <span>{{ PoweredByCompanyName }}</span>
          </span> -->
          <span *ngIf="row.IsChecked">
            {{ row.Line1 }}
            {{ row.Line2 }}
            {{ row.City }}
            {{ row.State }}
            {{ row.Country }}
            {{ row.Zip }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6 pl-5" *ngIf="selectedCustomerDetails?.CustomerName">
      <h5 class="text-break">
        <span>{{ ContactCompanyName }}</span>
      </h5>
      <p class="text-muted">
        <span class="text-break">{{ AddressLine1 }}</span><br />
        <span>{{ City }},</span>
        <span>{{State }}</span>
        <span>{{ZipCode }}</span>
        <span>{{Country }}</span>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pr-3">
      <div *ngFor="let contact of QuoteContact">
        <div *ngIf="contact.IsChecked">

          <h4 class="text-break">{{contact.FullName}}</h4>
          <p class="text-muted text-break">{{contact.EmailId}}</p>
        </div>
      </div>
    </div>

    <div class="col-md-6 " *ngIf="selectedCustomerDetails?.CustomerName">
      <h5 class="text-break">{{'QUOTE_PREPARED_BY' | translate }} {{users[0].Name}}</h5>
      <p class="text-muted pr-2 text-break">{{users[0].EmailAddress}}</p>
      <span class="text-muted" *ngIf="frmAddQuote_ExpiresOn">
        {{'TRANSLATE.QUOTE_CREATED' | translate}} {{currentDate1 | date:'MMMM d, yyyy':'UTC'}}
      </span><br />
      <span class="text-muted" *ngIf="frmAddQuote_ExpiresOn">
        {{'TRANSLATE.QUOTE_EXPIRES' | translate}} {{frmAddQuote_ExpiresOn }}
      </span>
    </div>


    <div class="container pt-2" *ngIf="quoteLineItemsData.length > 0">
      <div class="row pr-3 mb-1">
        <div class="col-md-6 p-0">
        </div>
        <table class="table table-bordered pr-3 ">
          <thead>
            <tr class="bg-light">
              <th class="font-weight-600 font-size-h6">{{ 'TRANSLATE.QUOTE_TABLE_PRODUCT_SERVICE' | translate }}</th>
              <th class="text-end font-weight-600 font-size-h6">{{ 'TRANSLATE.QUOTE_TABLE_QUANTITY' | translate }}</th>
              <th class="text-end font-weight-600 font-size-h6">{{ 'TRANSLATE.QUOTE_TABLE_PRICE' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of quoteLineItemsData; trackBy: trackByIndex" class="row-break-inside">
              <tr>
                <td class="font-weight-normal">{{ row.Name }}</td>
                <td class="text-end font-weight-normal">{{ row.Quantity }}</td>
                <td class="text-end font-weight-normal">
                  {{ row.DisplayFinalSalePrice | CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}
                </td>
              </tr>
              <ng-container *ngIf="row.LinkedProduct" class="row-break-inside">
                <tr>
                  <td class="font-weight-normal">
                    <i class="ki-duotone ki-paper-clip text-warning small"></i> {{ row.LinkedProduct.Name }}
                  </td>
                  <td class="text-center font-weight-normal">{{ row.Quantity }}</td>
                  <td class="text-right font-weight-normal">
                    {{ row.LinkedProduct.DisplayFinalSalePrice | CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <tr class="bg-light fw-semibold row-break-inside">
              <td>{{ 'TRANSLATE.QUOTE_PRICE_SUMMARY' | translate }}</td>
              <td></td>
              <td></td>
            </tr>

            <tr class="row-break-inside fw-semibold ">
              <td>{{ 'TRANSLATE.QUOTE_ONE_TIME_SUBTOTAL' | translate }}</td>
              <td></td>
              <td class="text-end ">
                {{ DisplayTotalFinalSalePrice | CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}
              </td>
            </tr>

            <tr class="row-break-inside fw-semibold " *ngIf="selectedCustomerDetails?.TaxName">
              <td>{{ 'TRANSLATE.QUOTE_LINE_ITEM_LABEL_TAX' | translate }}</td>
              <td></td>
              <td class="text-end">
                {{ totalTaxAmount | CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>

  <div class="row row-break-inside mt-4" *ngIf="quoteLineItemsData.length > 0">
    <div class="col-md-7 pr-5 mt-1"></div>
    <div class="row col-md-5 mt-2 mb-4 pr-3 text-right rounded background-white border-color" style="--border-style: 2px solid black;">
      <div class="col-md-6 pt-3 border-right-black">
        <h5 class="text-center">{{'TRANSLATE.QUOTE_PRICE_TOTAL' | translate}}</h5>
      </div>
      <div class="col-md-6 pt-3 ">
        <h5 class="text-center">
          {{totalQuoteValue | CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}
        </h5>
      </div>
    </div>
  </div>

  <div class="col-md-12 pr-7 rounded row-break-inside">
    <br>
    <div class="row pb-3 rounded background-white border-color" *ngIf="CommentsToBuyer" style="--border-style: 1px solid #B2B1B1;">
      <div class="col-md-12 pt-4">
        <h6>{{'TRANSLATE.QUOTE_COMMENTS_TO_BUYERS' | translate}}</h6>
        <p [innerHTML]="CommentsToBuyer"></p>
      </div>
    </div>
    <br>
    <div class="row row-break-inside rounded background-white border-color" *ngIf="PurchaseTerms" style="--border-style: 1px solid #B2B1B1;">
      <div class="col-md-12 pt-4">
        <h6>{{'TRANSLATE.QUOTE_PDF_PURCHASE_TERM' | translate}}</h6>
        <p [innerHTML]="PurchaseTerms"></p>
      </div>
    </div>
  </div>

  <!-- Signature Section -->
  <div class="row row-break-inside" *ngIf="IsShowCounterSign">
    <div class="col-md-6 pr-5 mt-1">
      <br />
      <h5>{{ 'TRANSLATE.QUOTE_SIGNATURE' | translate }}</h5>
      <br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_SIGNATURE' | translate }}</p>
      <br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_PRINTED_NAME' | translate }}</p>
      <br />
    </div>
    <div class="col-md-6 mt-9 pr-7">
      <br /> <br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_DATE' | translate }}</p>
    </div>
  </div>

  <!-- Countersignature Section -->
  <div class="row row-break-inside" *ngIf="IsCounterSign && IsShowCounterSign">
    <div class="col-md-6 pr-5 mt-1">
      <h5>{{ 'TRANSLATE.QUOTE_COUNTERSIGNATURE' | translate }}</h5>
      <br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_COUNTERSIGNATURE' | translate }}</p>
      <br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_PRINTED_NAME' | translate }}</p>
      <br />
    </div>
    <div class="col-md-6 mt-2 pr-7">
      <br /><br />
      <div style="border-top: 1px solid #ccc; margin: 10px 0;"></div>
      <p class="text-muted">{{ 'TRANSLATE.QUOTE_DATE' | translate }}</p>
    </div>
  </div>
</div>
