import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/tr';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { Subject, Subscription, filter, interval, map, takeUntil } from 'rxjs';
import { ClientSettingsService } from './services/client-settings.service';
import { ThemeModeService } from './_c3-lib/partials/layout/theme-mode-switcher/theme-mode.service';
import { SessionTimeoutService } from './services/session-timeout.service';
import Swal from 'sweetalert2';
import { UnsavedChangesService } from './services/unsaved-changes.service';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, IPublicClientApplication, InteractionStatus } from '@azure/msal-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PermissionService } from './services/permission.service';
import { AppSettingsService } from './services/app-settings.service';
import { ClientSettings } from './shared/models/appsettings.model';
import { CustomAnalyticsService } from './services/custom-analytics.service';
import { LoaderService } from './services/loader.service';
import { UserContextService } from './services/user-context.service';
import { BannerService } from './services/banner.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector:  'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private _unsubscribe: Subscription[] = [];
  faviconIcon: string ='';
  sessionExpired: boolean = false;
  sessionWarning: number = -1;
  private countdownSubscription: Subscription | undefined;
  isOpened: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;

  constructor( 
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private clientService: ClientSettingsService,
    private sessionTimeoutService: SessionTimeoutService,
    private unsavedChangesService: UnsavedChangesService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private _router: Router,
    private _userContext:UserContextService,
    private customAnalyticsService: CustomAnalyticsService,
    private _loaderService:LoaderService,
    private bannerService: BannerService,
     private viewContainerRef: ViewContainerRef,
     private renderer: Renderer2,
     private cdref: ChangeDetectorRef,
     private translateService: TranslateService,
     private titleService:Title

  ) {
    // Register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
    this.bannerService.viewContainerRef = viewContainerRef;
    this.bannerService.renderer = renderer;
    this.bannerService.cdref = cdref;
    this.bannerService.translateService = translateService; 
    this.titleService.setTitle(`| ${this.translateService.instant('TRANSLATE.TITLE_TEXT')}`);
  }

  ngOnInit() {
    // Initialize authentication service
    this.authService.initialize();
    this.customAnalyticsService.init();
    const msalData = sessionStorage?.getItem('msal.176f2190-8cdf-48ba-9728-23324049e0f0.request.params');
    if(!!msalData){
     this._userContext.setLoading(true)
      }
  let subscription = this.authService.handleRedirectObservable().subscribe({ 
    next: (result: AuthenticationResult) => {
       if (!this.authService.instance.getActiveAccount() && this.authService.instance.getAllAccounts().length > 0) {
          this.authService.instance.setActiveAccount(result.account);
          this._router.navigate(['loggedin']);
          setTimeout(() => {
            this._userContext.setLoading(false);
          }, 1000);
       }
      }
    })

   this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((_) => {
        this._loaderService.resetCommonLoader(); 
      });

    // Subscribe to session expiration events
    subscription = this.sessionTimeoutService.getSessionExpiration().subscribe((expired) => {
      if (expired) {
        this.handleSessionExpired();
      }
    });

    // Subscribe to session warning events
    subscription=this.sessionTimeoutService.getSessionWarning().subscribe((seconds) => {
      this.handleSessionWarning(seconds);

    });

    // Initialize mode service
    this.modeService.init();

    // Get data from client service

    // subscription = this.clientService.getData().subscribe((data: Partial<ClientSettings | any>) => {
    //   if (data?.Data?.FaviconLogoPath) {
    //     this.faviconIcon = data?.Data?.FaviconLogoPath;
    //     this.updateFavicon(this.faviconIcon);
    //   }
    // });

    // Confirm navigation on window unload
    window.onbeforeunload = () => {
      return this.unsavedChangesService.confirmNavigation();
    };
    this._unsubscribe.push(subscription)
  }


  private handleSessionExpired() {
    this.authService.logout();
    localStorage.clear();
    this.sessionTimeoutService.resetSessionTimeout();
  }

   handleSessionWarning(seconds: number) {
    if (seconds > 0) {
      this.sessionWarning = seconds;
      this.startCountdownAndShowWarning();
    } else {
      this.sessionWarning = -1;
      this.stopCountdown();
    }
  }

   startCountdownAndShowWarning() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubscription = this.startCountdown(this.sessionWarning);
    this.showSessionWarning();
  }

   stopCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

   startCountdown(seconds: number): Subscription {
    return interval(1000).subscribe(() => {
      this.sessionWarning--;
      if (this.sessionWarning <= 0) {
        this.sessionExpired = true;
        this.sessionTimeoutService.resetSessionTimeout();
        this.sessionExpired = false;
        this.sessionWarning = -1;
        this.countdownSubscription?.unsubscribe();
      }
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


  onExtendSession(): void {
    this.sessionTimeoutService.setLastActivityTime();
    this.sessionWarning = -1;
  }

  showSessionWarning(): void {
    if (this.isOpened) {
      return;
    }
    this.isOpened = true;

    let secondsLeft = this.sessionWarning;

    const dialog = Swal.fire({
      title: this.translateService.instant('TRANSLATE.SESSION_TIMEOUT_WARNING'),
      text: this.translateService.instant('TRANSLATE.SESSION_WARNING_DURATION',{secondsLeft:this.sessionWarning}),
      icon: 'warning',
      timer: secondsLeft * 1000,
      showConfirmButton: false,
      showCancelButton: true,
      didOpen: () => {
        const timerInterval = setInterval(() => {
          secondsLeft--;
          if (secondsLeft > 0) {
            Swal.update({
              text: this.translateService.instant('TRANSLATE.SESSION_WARNING_DURATION',{secondsLeft:secondsLeft}),
            });
          } else {
          }
        }, 1000);
      },
    });

    dialog.then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
      this.isOpened = false;
    });
  }

   ngOnDestroy(): void {
    this._userContext.setLoading(false);
    this._unsubscribe.forEach((sb) => sb.unsubscribe());
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this._userContext.stopRefreshAccessToken();
  }
}
