import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import moment from 'moment';
import { NotifierService } from 'src/app/services/notifier.service';
import { QuoteService } from '../quotes.service';
import { ToastService } from 'src/app/services/toast.service';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { UnsavedChangesService } from 'src/app/services/unsaved-changes.service';
import { ClientSettingsService } from 'src/app/services/client-settings.service';

@Component({
  selector: 'app-quote-custom-line-item',
  templateUrl: './quote-custom-line-item.component.html',
  styleUrls: ['./quote-custom-line-item.component.scss']
})
export class QuoteCustomLineItemComponent implements OnInit,OnDestroy {
  customLineItemForm: FormGroup;
  saleTypes: any[] = [];
  billingPeriods: any[] = [];
  formSubmit: boolean=false;

  @Input() customLineItemDetails: any; // Use @Input to receive data from parent

  billingPeriodId: any;
  billingPeriodValue: string | null = null;
  QuoteLineItemId: number = 0;
  private destroy$ = new Subject<void>;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private _commonService: CommonService,
    private _toastService: ToastService,
    private _quotesService: QuoteService,
    private notifier: NotifierService,
    private _clientSettingsService: ClientSettingsService,
    private _unsavedChangesService: UnsavedChangesService
  ) {
    this.customLineItemForm = this.fb.group({
      Name: ['', [Validators.required, Validators.maxLength(150)]],
      Description: ['', Validators.required],
      SaleType: ['', Validators.required],
      UnitPrice: [null, [Validators.required, Validators.min(0)]],
      IsTaxable: [false],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      InvoiceNumber: ['', Validators.required],
      InvoiceDueDate: ['', Validators.required],
      InvoiceDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getSaleTypes();
    this.getCurrentBillingPeriod();

    if (this.customLineItemDetails) {
      this.getCustomLineItemDetails(this.customLineItemDetails);
    }
  }

  getSaleTypes(): void {
    this._commonService.getSaleTypes().subscribe(data => {
      this.saleTypes = data;
    });
  }

  getCurrentBillingPeriod(): void {
    this._commonService.getBillingPeriodsForSubscription().subscribe((response: any) => {
      const billingPeriods = response.Data;
      if (billingPeriods && billingPeriods.length > 0) {
        this.billingPeriods = billingPeriods;
        this.billingPeriodId = billingPeriods[billingPeriods.length - 1].BillingPeriodId;
        this.updateBillingPeriodValue();
      }
    });
  }

  updateBillingPeriodValue(): void {
    const currentBillingPeriod = this.billingPeriods.find(e => e.BillingPeriodId === this.billingPeriodId);
    if (currentBillingPeriod) {
      this.billingPeriodValue = `${moment(currentBillingPeriod.BillingStartDate).format('LL')} - ${moment(currentBillingPeriod.BillingEndDate).format('LL')}`;
    }
  }

  getCustomLineItemDetails(customLineItem: any): void {
    if (customLineItem) {
      this.customLineItemForm.patchValue({
        Name: customLineItem.CustomLineItemDetails.Name,
        Description: customLineItem.CustomLineItemDetails.Description,
        SaleType: customLineItem.CustomLineItemDetails.SaleTypeId,
        UnitPrice: customLineItem.CustomLineItemDetails.FinalSalePrice,
        StartDate: this.convertDateToNgbDateStruct(new Date(customLineItem.CustomLineItemDetails.StartDate)),
        EndDate: this.convertDateToNgbDateStruct(new Date(customLineItem.CustomLineItemDetails.EndDate)),
        InvoiceNumber: customLineItem.CustomLineItemDetails.InvoiceNumber,
        InvoiceDate: this.convertDateToNgbDateStruct(new Date(customLineItem.CustomLineItemDetails.InvoiceDate)),
        InvoiceDueDate: this.convertDateToNgbDateStruct(new Date(customLineItem.CustomLineItemDetails.DueDate)),
        BillingPeriod: customLineItem.CustomLineItemDetails.BillingPeriodId,
        IsTaxable: customLineItem.CustomLineItemDetails.IsTaxable,
      });
      this.QuoteLineItemId = customLineItem.CustomLineItemDetails.QuoteLineItemId;
    }
  }
  
  convertDateToNgbDateStruct(date: Date): NgbDateStruct {
  
      if(date){
        date =new Date(date);
        return {
          year: date.getFullYear(),
          month: date.getMonth()+1,
          day: date.getDate()
        }
      }
      return null;
    }

  isValueNAN(value:any){
    return isNaN(value)
  }


  submit(): void {
    this.formSubmit = true    
    if (this.customLineItemForm.invalid) {
      // Extract form values
      const startDate = this.customLineItemForm.get('StartDate')?.value;
      const endDate = this.customLineItemForm.get('EndDate')?.value;
      const invoiceDate = this.customLineItemForm.get('InvoiceDate')?.value;
      const dueDate = this.customLineItemForm.get('InvoiceDueDate')?.value;
    
      var startDateObj = new Date(startDate.year, startDate.month , startDate.day);
      var endDateObj = new Date(endDate.year, endDate.month , endDate.day);
      var invoiceDateObj = new Date(invoiceDate.year, invoiceDate.month , invoiceDate.day);
      var dueDateObj = new Date(dueDate.year, dueDate.month , dueDate.day);

      // Date validation logic
      if (startDateObj >= endDateObj) {
        this._toastService.error(this.translate.instant('TRANSLATE.VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE'));
        return;
      }
    
      if (invoiceDateObj >= dueDateObj) {
        this._toastService.error(this.translate.instant('TRANSLATE.VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE'));
        return;
      }
    
      // Mark form fields as touched to show validation errors
      this.customLineItemForm.markAllAsTouched();  
      return;
    }
   

    const formValues = this.customLineItemForm.value;
    const decimalCheck = (formValues.UnitPrice * 100) % 1 !== 0;
    if (decimalCheck) {
            this._toastService.error(this.translate.instant('TRANSLATE.QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR'));
      return;
    }
    if (formValues.Name && formValues.Name.length > 150) {
      this._toastService.error(this.translate.instant('TRANSLATE.CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE'));
      return;
  }
    let startdate={year:formValues.StartDate.year,month:formValues.StartDate.month-1,day:formValues.StartDate.day}
    let EndDate={year:formValues.EndDate.year,month:formValues.EndDate.month-1,day:formValues.EndDate.day}
    let InvoiceDate={year:formValues.InvoiceDate.year,month:formValues.InvoiceDate.month-1,day:formValues.InvoiceDate.day}
    let DueDate={year:formValues.InvoiceDueDate.year,month:formValues.InvoiceDueDate.month-1,day:formValues.InvoiceDueDate.day}
    const product = {
      QuoteLineItemId: this.QuoteLineItemId,
      PlanProductId: 0,
      Name: formValues.Name,
      Description: formValues.Description,
      Quantity: 1,
      OriginlaSalePrice: formValues.UnitPrice,
      FinalSalePrice: formValues.UnitPrice,
      ProviderPrice: 0,
      DisplayFinalSalePrice: formValues.UnitPrice,
      Discount: 0.00,
      Tax: 0.00,
      SaleTypeId: formValues.SaleType,
      BillingPeriodId: this.billingPeriodId,
      StartDate: moment(startdate).format('LL'),
      EndDate: moment(EndDate).format('LL'),
      InvoiceNumber: formValues.InvoiceNumber,
      InvoiceDate: moment(InvoiceDate).format('LL'),
      DueDate: moment(DueDate).format('LL'),
      CurrencyThousandSeperator: this.customLineItemDetails?.CurrencyThousandSeperator || ',',
      CurrencyDecimalSeperator: this.customLineItemDetails?.CurrencyDecimalSeperator || '.',
      CurrencyCode: this.customLineItemDetails?.CurrencyCode || 'USD',
      CurrencySymbol: this.customLineItemDetails?.CurrencySymbol || '$',
      CurrencyDecimalPlaces: this.customLineItemDetails?.CurrencyDecimalPlaces || 2,
      IsTaxable: formValues.IsTaxable,
      BillingCycleName: 'Monthly',
      IsActive: 1
    };

    this.activeModal.close(product);
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._unsavedChangesService.setUnsavedChanges(false);
  }
}
