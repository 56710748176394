@if(showSideMenu){
<div id="kt_app_sidebar_menu_scroll" class="scroll-y my-5 mx-3" data-kt-scroll="true" data-kt-scroll-activate="true"
  data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
  <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold" id="kt_app_sidebar_menu"
    data-kt-menu="true" data-kt-menu-expand="false">
    <ng-container *ngFor="let menu of sideMenuData">
      <ng-container *ngIf="!(menu.children && menu.children.length > 0)">
        <div class="menu-item">
          <a class="menu-link without-sub" [routerLink]="menu.parent.Sref" routerLinkActive="active">
            <span class="menu-icon">
              <!-- <div [innerHTML]="menu?.parent?.svg"></div> -->
              <i [ngClass]="menu?.parent?.Icon"></i>  
            </span>
            <span class="menu-title" translate="TRANSLATE.{{menu.parent.Text}}"></span>
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="menu.children && menu.children.length > 0">
        <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="active">
          <span class="menu-link">
            <span class="svg-icon menu-icon" id="menuIcon{{menu.parent.Id}}">
              <!-- <div [innerHTML]="menu?.parent?.svg"></div> -->
              <i [ngClass]="menu?.parent?.Icon"></i>  
            </span>
            <span class="menu-title" translate="TRANSLATE.{{menu.parent.Text}}"></span>
            <span class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion">
            <ng-container *ngFor="let child of menu.children">
              <div class="menu-item">
                <a class="menu-link without-sub" [routerLink]="child.Sref" routerLinkActive="active">
                  <span class="svg-icon menu-icon">
                    <!-- <div [innerHTML]="child?.svg"></div>   -->
                    <i [ngClass]="child?.Icon"></i> 
                  </span>
                  <span class="menu-title" [innerHTML]="'TRANSLATE.' + child.Text | translate"></span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
}