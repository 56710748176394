// USA
export const locale = {
  lang: 'de',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    TRANSLATE: {
      "academic":"Akademisch",
      "Academic":"Akademisch",
      "ACCEPT_BUTTON_INFO":"Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, erklären Sie sich mit den oben genannten Nutzungsbedingungen einverstanden.",
      "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
      "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Zustimmung zur Verlängerung des Partnerangebots annehmen oder ablehnen",
      "ACCEPT_THE_CONSENT_BTN_TEXT":"Zugriff auf das Partner Center über die neuen Sicherheitseinstellungen.",
      "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Zugriff auf Microsoft Azure-Verwaltungs-APIs.",
      "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Zugriff auf die Microsoft-Preis-API.",
      "ACCEPTANCE_STATUS":"Akzeptanzstatus",
      "ACCESS_TOKEN":"Zugangstoken",
      "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"Der Account Manager wurde erfolgreich erstellt",
      "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Account Manager erfolgreich gelöscht",
      "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"Sind Sie sicher, dass Sie den Account Manager löschen möchten?",
      "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"Der Account Manager ist einem Kunden/Wiederverkäufer zugeordnet. Sind Sie sicher, dass Sie den Account Manager löschen möchten?",
      "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Suche nach Account Manager ID",
      "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Suche nach E-Mail-Adresse",
      "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Suche nach Vorname",
      "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Suche nach Nachname",
      "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Suche nach Telefonnummer",
      "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Account Manager ID",
      "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Filter schließen",
      "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"E-Mail-Addresse",
      "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Vorname",
      "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Nachname",
      "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Telefonnummer",
      "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Zurücksetzen",
      "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Suche",
      "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Die Account Manager-Details wurden erfolgreich aktualisiert",
      "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Account Manager hinzufügen",
      "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Account Manager bearbeiten",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Account Manager ID",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Email",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Vorname",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nachname",
      "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Telefonnummer",
      "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Account Manager hinzufügen",
      "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Kundenbetreuer",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"Ich würde",
      "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Aktionen",
      "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
      "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"E-Mail-Addresse",
      "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Vorname",
      "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Nachname",
      "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Telefonnummer",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Kontomanager löschen",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Details bearbeiten",
      "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Zugewiesene Kunden anzeigen",
      "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Kontonummer",
      "ACH_FORM_LABEL_ACCOUNT_TYPE":"Konto Typ",
      "ACH_FORM_LABEL_BANK_NAME":"Bank Name",
      "ACH_FORM_LABEL_BSB":"BSB",
      "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"Ist Geschäftskonto",
      "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Name auf Konto",
      "ACH_FORM_LABEL_NO":"Nein",
      "ACH_FORM_LABEL_ROUTING_NUMBER":"Routing-Nummer",
      "ACH_FORM_LABEL_YES":"Ja",
      "ACTIONS_TO_FIX":"Bitte wenden Sie sich an den Administrator, um Unterstützung zu erhalten",
      "ACTIONS_TO_FIX_500":"Wir arbeiten an der Behebung des Problems. Bitte versuchen Sie es in einer Weile erneut",
      "ACTIVATE_CONFORMATION":"Sind Sie sicher, dass Sie aktivieren möchten?",
      "ACTIVE_CUSTOMER_PLACEHOLDER":"Wählen Sie Kunde",
      "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Alle Aktionen--",
      "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Zeigt Protokolle von",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Alle Kunden",
      "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Alle Domänen.",
      "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Benachrichtigungen",
      "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Wiederholen",
      "ACTIVITY_LOGS_EFFECTIVE_TO":"Endtermin",
      "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Aktionen",
      "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Kontaktmöglichkeit",
      "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Veranstaltungsname",
      "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Gesendetes Datum",
      "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Status",
      "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Gegenstand",
      "ACTIVITY_LOGS_HEADER_TITLE_TO":"Zu",
      "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Veranstaltung",
      "ACTIVITY_LOGS_TEXT_BCC":"BCC",
      "ACTIVITY_LOGS_TEXT_CC":"CC",
      "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Anfangsdatum",
      "ACTIVITY_LOGS_TEXT_FAILED":"Gescheitert",
      "ACTIVITY_LOGS_TEXT_FROM":"Von",
      "ACTIVITY_LOGS_TEXT_ON":"Auf",
      "ACTIVITY_LOGS_TEXT_STATUS":"Status",
      "ACTIVITY_LOGS_TEXT_SUCCESS":"Erfolg",
      "ACTIVITY_LOGS_TEXT_TO":"Zu",
      "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"E-Mail anzeigen",
      "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Benachrichtigungen",
      "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Anpassung hinzufügen",
      "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"Domain Name ist nicht verfügbar",
      "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"Domain Name ist nicht verfügbar",
      "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Partnerangebote hinzufügen",
      "ADD_CUSTOM_ROLE":"Benutzerdefinierte Rolle hinzufügen",
      "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Der Kunde hat spätestens akzeptiert",
      "ADD_CUSTOMER_IN_QUOTE":"Kunden hinzufügen",
      "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"Land",
      "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Bitte geben Sie eine Postleitzahl ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Bitte geben Sie die Adresse ein 1.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":"Geben Sie die Adresse ein 2.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Bitte geben Sie die Stadt ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Bitte geben Sie den Firmennamen ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Bitte geben Sie die E-Mail Adresse ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Bitte geben Sie den Vornamen ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Bitte geben Sie den Nachnamen ein.",
      "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Bitte geben Sie die Telefonnummer ein.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Bitte wählen Sie ein Land aus.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Bitte wählen Sie einen Währungscode.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Bitte wählen Sie einen Tarif.",
      "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Bitte wählen Sie ein Bundesland.",
      "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Kunden hinzufügen",
      "ADD_DUE_DATE":"Fälligkeitsdatum hinzufügen",
      "ADD_ENTITLEMENT":"Berechtigung hinzufügen",
      "ADD_ENTITLEMENT_DESCRIPTION":"Bitte geben Sie einen freundlichen Namen für das Abonnement ein.",
      "ADD_ENTITLEMENT_FAILED":"Berechtigung hinzufügen fehlgeschlagen",
      "ADD_ENTITLEMENT_SUCCESS":"Berechtigung wurde erfolgreich in Parter Center hinzugefügt, bitte warten Sie 15 Minuten bis zur Bereitstellung in C3",
      "ADD_MACRO_VALUE_ERROR":"Makrowert ist erforderlich",
      "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Erstellen Sie eine neue Ebene",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!maxValue) ? „Bitte geben Sie einen Wert ein, der größer als „ + minValue“ ist: „Bitte geben Sie einen Wert zwischen „ + minValue + „ und „ + maxValue“ ein}}",
      "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Bitte geben Sie einen Wert zwischen {{Min}} und {{Max}} ein.",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Stornieren",
      "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"einreichen",
      "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Komplett",
      "ADD_MISSING_OFFERS_IN_PROGRESS":"Der Aktualisierungsplan mit den neuesten Angeboten ist bereits in Bearbeitung",
      "ADD_MISSING_OFFERS_INITIAT_FAIL":"Beim Aktualisieren des Plans mit der Initiierung der neuesten Angebote ist ein Fehler aufgetreten",
      "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Der Aktualisierungsplan mit den neuesten Angeboten wurde gestartet",
      "ADD_MISSING_OFFERS_STATUS":"Planen Sie den Aktualisierungsfortschritt",
      "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Fehlende Angebote zum bestehenden Plan hinzufügen",
      "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Fehlende Angebote zu mehreren Tarifen hinzufügen",
      "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Fehlende Angebote zum Einzelplan hinzufügen",
      "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Zugriff auf den primären Kontakt gewähren (als Kundenadministrator)?",
      "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Einladung zum Zugriff auf die Anwendung senden?",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
      "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
      "ADD_NEW_QUOTE":"Angebot erstellen",
      "ADD_NEW_USER_IN_QUOTE":"Neuen Benutzer hinzufügen",
      "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Wählen",
      "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Grundlegende Details zum Plan hinzufügen",
      "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
      "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Rechnungswährung",
      "ADD_PLAN_LABEL_TEXT_NAME":"Name",
      "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Kaufwährung",
      "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Vertragsdetails anzeigen",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Aktionen",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
      "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Makro",
      "ADD_PO_NUMBER":"Bestellnummer hinzufügen",
      "ADD_PONUMBER":"Bestellnummer hinzufügen",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Es wird empfohlen, dass Sie die Option <b>Link Provider</b> verwenden, um die MPN-ID des Wiederverkäufers anzugeben und dem Wiederverkäufer schließlich beim Onboarding von Kunden zu helfen.",
      "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"Empfohlen! Legen Sie die MPN-ID fest, nachdem Sie den Reseller hinzugefügt haben",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
      "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Plattenbrecher",
      "ADD_TO_CART_INFO":"Dieses Produkt ist mit <b>{{linkedProductName}}</b>verknüpft. Wenn Sie dieses Produkt hinzufügen, wird der Link ebenfalls hinzugefügt",
      "addCustomer":"Kunden hinzufügen",
      "ADDONS_LEBEL_TEXT":"Addons",
      "ADDONS_PRODUCT_LEBEL_TEXT":"Zusatzprodukt",
      "ADDRESS_TABLE_HEADER_ACTIONS":"Aktionen",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Anschrift Zeile 1",
      "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Adresszeile 2",
      "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Adresstyp",
      "ADDRESS_TABLE_HEADER_CITY":"Stadt",
      "ADDRESS_TABLE_HEADER_COUNTRY":"Land",
      "ADDRESS_TABLE_HEADER_STATE":"Zustand",
      "ADDRESS_TABLE_HEADER_ZIP":"Postleitzahl",
      "ADDRESS_TYPE_BILLING":"Abrechnung",
      "ADDRESS_TYPE_COMPANY":"Unternehmen",
      "ADDRESS_TYPE_INVOICE":"Rechnung",
      "ADDRESS_TYPE_SHIPPING":"Versand",
      "AGO":"Vor",
      "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"In die Zwischenablage kopiert",
      "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"Die Rechnung wurde erfolgreich genehmigt.",
      "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"Die Rechnung wurde erfolgreich abgeschlossen.",
      "ALERT_MESSAGE_MISSING_MPNID":"Beim Kopieren der URL ist ein Problem aufgetreten. Bitte stellen Sie eine Verbindung zum Partner her.",
      "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Bitte wählen Sie mindestens eine Rechnung aus",
      "ALL_CART_ITEM_COMMENT":"Warenkorb Artikel",
      "ALL_COMMENTS":"Bemerkungen",
      "ALL_PRODUCT_COMMENTS":"Produkt",
      "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Bitte wenden Sie sich an Ihren Administrator, um Lizenzen zu entfernen.",
      "AN_ERROR_OCCURED":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Azure-Abrechnungsverlauf",
      "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Azure-Abrechnungszusammenfassung",
      "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Customer Azure-Verwendung",
      "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Einnahmen von Kunden",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Abonnements von Kunden",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Abonnements nach Plänen",
      "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"Abonnementhistorie",
      "AND":"und",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"Einwilligungs-URL der Azure AD-Preisanwendung",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"Azure AD-Preisanwendungs-ID",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"Ressourcen-URL der Azure AD-Preisanwendung",
      "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Geheimer Schlüssel der Azure AD-Preisanwendung",
      "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Bitte geben Sie die Hexadezimalfarbe der Schaltfläche für die Willkommensseite ein",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"Einwilligungs-URL der Azure AD-Preisanwendung",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"Azure AD-Preisanwendungs-ID",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"Ressourcen-URL der Azure AD-Preisanwendung",
      "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Geheimer Schlüssel der Azure AD-Preisanwendung",
      "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Farbe der Schaltfläche \"Willkommensseite\".",
      "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Öffentliches Anmeldebanner",
      "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Öffentliches Anmeldelogo",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Nutzungs-Upload zulassen",
      "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Wenn diese Konfiguration aktiviert ist, erhöht sie die monatlichen Nutzungskosten, die für Produkte mit Nutzungsverbrauch anfallen. Zu den Produkttypen gehören Azure-Plan und Custom-Usage.",
      "APPCONFIG_AUTOTASK_BILLING_CODE":"Abrechnungscode",
      "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Dieser Abrechnungscode wird verwendet, um Produkte in Autotask zu erstellen",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Ist automatisches Mapping (Autotask)",
      "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Wenn Sie manuell zwischen C3- und Autotask-Entitäten zuordnen möchten, lassen Sie dies deaktiviert. Wenn jedoch die automatische Zuordnung aktiviert ist, werden Entitäten abgeglichen, indem Namen von Entitäten in C3 mit Entitäten in Autotask verglichen werden",
      "APPCONFIG_AUTOTASK_PASSWORD":"Passwort",
      "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Passwort, das dem API-Benutzer gehört",
      "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Anteil",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Automatische Upload-Frequenz",
      "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Legen Sie die Häufigkeit des automatischen Hochladens fest",
      "APPCONFIG_AUTOTASK_USERNAME":"Nutzername",
      "APPCONFIG_AUTOTASK_USERNAME_DESC":"Benutzername des in Autotask erstellten API-Benutzers",
      "APPCONFIG_DESC_ACCOUNT_NAME":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_ACCOUNT_NUMBER":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_ADDRESS_LINE1":"Adresse 1, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_ADDRESS_LINE2":"Adresse 2, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_ADVANCE_TEXT":"Erweiterter Zeilenbenutzerdefinierter Text",
      "APPCONFIG_DESC_API_URL":"Basis-API-URL für connectwise manage",
      "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Aktivieren Sie die Lizenzverfolgung",
      "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Analytics anzeigen",
      "APPCONFIG_DESC_APPLICATION_NAME":"Anwendungsname",
      "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Ende des Abrechnungszyklus für den Partner im Partner Center",
      "APPCONFIG_DESC_AZURE_CSPOFFERID":"Azure CSP-Angebots-ID",
      "APPCONFIG_DESC_BANK_NAME":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_BILLING_CYCLE":"Ausgerichtet mit Partner Center oder Custom",
      "APPCONFIG_DESC_BILLING_DATE":"Anwendbar, wenn der Abrechnungszyklus benutzerdefiniert ist",
      "APPCONFIG_DESC_BILLING_PERIOD":"Abrechnungszeitraum (bestimmt mit anderen Einstellungen)",
      "APPCONFIG_DESC_BILLING_PROVIDER":"Abrechnungsanbieter für das C3-Portal",
      "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Konfiguration des Abrechnungsanbieters",
      "APPCONFIG_DESC_BILLING_STARTDAY":"Beginn des Abrechnungszyklus. Für den Abrechnungszyklus gilt \\''Benutzerdefiniert\\''",
      "APPCONFIG_DESC_BUSINESS_NUMBER":"Erscheint in der Rechnungsansicht und Rechnungs-PDF in Rechnung aus Abschnitt",
      "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Erscheint in der Rechnungsansicht und Rechnungs-PDF in Rechnung aus Abschnitt",
      "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Überprüfen Sie die Rollen gegen 0365",
      "APPCONFIG_DESC_CIN_NUMBER":"CIN (Corporate Identification Number), die auf der Rechnung im PDF-Format erscheint",
      "APPCONFIG_DESC_CITY":"Stadt, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_CLIENT_ID":"Client-ID für ConnectWise Manage",
      "APPCONFIG_DESC_COMPANY_NAME":"Firmenname, der in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_COMPANY_URL":"Deine Website URL",
      "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Firmenname für connectwise manage",
      "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Erstellungsdatum im Umsatzreport berücksichtigen",
      "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Name, der auf der Zielseite sowie in der Fußzeile angezeigt werden soll, nachdem ein Benutzer angemeldet wurde",
      "APPCONFIG_DESC_CONTACT_EMAIL":"Kontakt-E-Mail (normalerweise Ihre Informationen oder Support-E-Mail)",
      "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Vorname der Kontaktperson für Ihre C3-Anwendung",
      "APPCONFIG_DESC_CONTACT_LAST_NAME":"Nachname der Kontaktperson für Ihre C3-Anwendung",
      "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Telefonnummer der Kontaktperson",
      "APPCONFIG_DESC_COUNTRY":"Land, das in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_COUNTRY_CODE":"Landesvorwahl",
      "APPCONFIG_DESC_CURRENCY_CODE":"Währungscode",
      "APPCONFIG_DESC_CUSTOM_BILLING":"Benutzerdefinierte Abrechnung anwenden",
      "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL der Kundeneinwilligung",
      "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Beschränkung des Kunden beim Onboarding unter mehreren Entitäten",
      "APPCONFIG_DESC_DATE_FORMAT":"Datumsformat",
      "APPCONFIG_DESC_DATE_TIME_FORMAT":"Datum Uhrzeit Format",
      "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Wird verwendet, um ein Datumsformat für den Datumstypwert in C3-APIs festzulegen",
      "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"Ziel-URL für die Seite, wenn Sie eine angepasste Zielseite haben möchten. Hinweis: Auf der Zielseite sollten Sie eine Schaltfläche haben und die Ziel-URL dieser Schaltfläche sollte <C3Site URL>/#/login sein",
      "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Standardsprache für die Website",
      "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Legt die Standardseitenzahl für jede Tabelle im System fest.",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen für die Bestellung von Produkten",
      "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"AGB-URL (Text)",
      "APPCONFIG_DESC_EMAIL_LOGO":"Das Logo, das in von C3 versendeten E-Mails angezeigt wird, ist 300x100.",
      "APPCONFIG_DESC_ENABLE_TOUR":"Aktivieren Sie die Schaltfläche „Tour machen“ in der Kopfzeile der Website.",
      "APPCONFIG_DESC_FAVICON_LOGO":"Ein Favicon ist ein kleines Symbol, das auf der Registerkarte des Webbrowsers direkt vor dem Seitentitel angezeigt wird. Bitte laden Sie eine ICO-Datei im Format 16x16 hoch",
      "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Sollte die MPN-ID von Resellern für Kunden und Käufe berücksichtigt werden?",
      "APPCONFIG_DESC_IFSC_CODE":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als von der Fußzeile autorisierte Zeile",
      "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
      "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Tage der Rechnungserstellung",
      "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cloud-Speicher-Verbindungszeichenfolge",
      "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Gültige Tage für Rechnungs-Zip",
      "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Wenn diese Funktion aktiviert ist, erhält der Partner die vom Wiederverkäufer genehmigten Anfragen zur Genehmigung.",
      "APPCONFIG_DESC_LINK_TO_CONTACT":"Seite \\''Link zu Kontakt\\'' (geben Sie einfach/#/contact ein, falls Sie noch keine haben)",
      "APPCONFIG_DESC_LINK_TO_FAQ":"Link zu Kunden-FAQ (lassen Sie es einfach leer, wenn Sie die Standard-FAQ verwenden möchten)",
      "APPCONFIG_DESC_LINK_TO_PORTAL":"Link zum Portal",
      "APPCONFIG_DESC_LINK_TO_SUPPORT":"Link zur Support-Seite (geben Sie einfach home.support ein, falls Sie noch keine haben)",
      "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Link zum Willkommenslogo",
      "APPCONFIG_DESC_LOGGEDIN_LOGO":"Das Logo wird oben links angezeigt, nachdem Benutzer bei C3 angemeldet sind. Die bevorzugte Größe beträgt 170 x 40. Die Seite hat einen schwarzen Hintergrund, daher sollte die Grafik eine Farbe haben, die im Gegensatz zu Schwarz steht.",
      "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Melden Sie sich bei Portal an",
      "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"Obligatorische Kunden-ID in API-Aufrufen",
      "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Maximale Anzahl von Einträgen für den Export nach Excel",
      "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Maximum Entitlement Creation Count",
      "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Maximale Mandantenerstellungsanzahl",
      "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft Sicherheitseinwilligung in Tagen erinnern",
      "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Mindestgebühr für Testzahlungen",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"NCE-Bedingungen zum Bestellen von Microsoft NCE-Produkten",
      "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL der NCE-Nutzungsbedingungen (Text)",
      "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Aktiviert die Funktion zum Suchen bereits vorhandener Kunden in der Abrechnung",
      "APPCONFIG_DESC_PAN_NUMBER":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Anwendungs-ID aus einer nativen Anwendung in Ihrem Partnercenter. Sie können dies über App Management -> Native Application erhalten",
      "APPCONFIG_DESC_PARTNER_CURRENCY":"Partnerwährung",
      "APPCONFIG_DESC_PARTNER_PASSWORD":"Admin Agent Passwort",
      "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Partnerpräferenzsprachen",
      "APPCONFIG_DESC_PARTNER_TENANT_ID":"Partner-Mieter-ID",
      "APPCONFIG_DESC_PARTNER_USERNAME":"Benutzername des Kontos, dem die Agentenadministratorrolle zugewiesen ist",
      "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL der Microsoft Cloud Reseller-Vereinbarung",
      "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL der Allgemeinen Geschäftsbedingungen des Partners",
      "APPCONFIG_DESC_PBI_GROUP_ID":"Power BI-Gruppen-ID",
      "APPCONFIG_DESC_PBI_PASSWORD":"Power BI-Passwort",
      "APPCONFIG_DESC_PBI_REPORT_ID":"Power BI-Berichts-ID",
      "APPCONFIG_DESC_PBI_USER_NAME":"Power BI-Benutzername",
      "APPCONFIG_DESC_PBIC_LIENT_ID":"Power BI-Client-ID",
      "APPCONFIG_DESC_PDF_LOGO":"Logo, das auf dem PDF Ihrer C3-Bewerbung erscheinen sollte. Die bevorzugte Größe ist 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik in einer beliebigen Farbe sein, die im Kontrast zu Weiß steht.",
      "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logo, das auf der rechten Seite des PDFs erscheinen sollte. Die bevorzugte Größe ist 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik in einer beliebigen Farbe sein, die im Kontrast zu Weiß steht.",
      "APPCONFIG_DESC_POSTAL_CODE":"Postleitzahl, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Name, der auf der Zielseite sowie in der Fußzeile angezeigt werden soll, nachdem ein Benutzer angemeldet wurde",
      "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"Deine Website URL",
      "APPCONFIG_DESC_PRIVATE_KEY":"Privater Schlüssel für ConnectWise Manage",
      "APPCONFIG_DESC_PUBLIC_KEY":"Öffentlicher Schlüssel für ConnectWise Manage",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks App Client-Kennung",
      "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks App Kundengeheimnis",
      "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Untersuchen Sie Diskrepanzen bei E-Mail-Empfängern für die Erstellung von Vorausrechnungen",
      "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logo, das in der Ansicht des Abstimmungsberichts rechts neben dem Microsoft-Logo angezeigt wird. Die bevorzugte Abmessung ist 170 x 40. Die Seite hat einen weißen Hintergrund, sodass der Text oder die Grafik in einer beliebigen Farbe vorliegen sollte, die im Gegensatz zu Weiß steht.",
      "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Fügen Sie die Empfänger als durch Kommas getrennte Werte hinzu",
      "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Sollte den Kunden benachrichtigen",
      "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Abonnement aussetzen, wenn die Gefahrenschwelle erreicht ist",
      "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Power BI-Berichtsmenü anzeigen",
      "APPCONFIG_DESC_SHOW_FOOTER":"Wenn Sie diese Einstellung aktivieren, wird auf allen Seiten eine Fußzeile angezeigt.",
      "APPCONFIG_DESC_SHOW_NEXT_STEP":"Nächsten Schritt anzeigen",
      "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Aktivieren Sie diese Option, um die Allgemeinen Geschäftsbedingungen für die Abonnementverwaltung anzuzeigen.",
      "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP SSL aktivieren",
      "APPCONFIG_DESC_SMTP_FROM_MAIL":"SMTP aus E-Mail",
      "APPCONFIG_DESC_SMTP_FROM_NAME":"Vom Anzeigenamen",
      "APPCONFIG_DESC_SMTP_HOST":"SMTP-Host",
      "APPCONFIG_DESC_SMTP_PASSWORD":"Passwort",
      "APPCONFIG_DESC_SMTP_PORT":"Hafen",
      "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"SMTP-Betreff-Präfix",
      "APPCONFIG_DESC_SMTP_USERNAME":"SMTP-Benutzername",
      "APPCONFIG_DESC_STATE":"Status, der in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
      "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DESC_TAX_TYPE_KEY":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_TAX_TYPE_VALUE":"Erscheint auf der Rechnung pdf",
      "APPCONFIG_DESC_WELCOME_LOGO":"Logo, das auf der Zielseite Ihrer C3-Anwendung angezeigt werden soll. Die bevorzugte Abmessung beträgt 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik eine beliebige Farbe haben, die im Gegensatz zu Weiß steht.",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Xero App Kontocode für Anpassungspositionen",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Xero App Kontocode für individuelle Angebote",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Xero App Kontocode für Microsoft-Lizenzen",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Xero App Kontocode für einmalige Positionen",
      "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Xero-App-Kontocode für reservierte Instanzen – Einzelposten",
      "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Xero App Branding Themenname",
      "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"Xero App Kunden-ID",
      "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Xero App Kundengeheimnis",
      "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"Der Dezimalpunktwert, auf den die in Xero gebuchten Beträge gerundet werden",
      "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Zusatzbeschreibung und Rechnungsbeschreibungsformat",
      "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Datumskultur für ConnectWise Manage Daten",
      "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Berücksichtigen Sie ConnectWise Manage Preise anstelle von C3 Preisen",
      "APPCONFIG_DISC_MAPPING_TYPE":"Wählen Sie den Zuordnungstyp für die Zuordnung der Entitäten zwischen C3 und dem externen Dienst aus",
      "APPCONFIG_DISP_ACCOUNT_NAME":"Konto Name",
      "APPCONFIG_DISP_ACCOUNT_NUMBER":"Kontonummer",
      "APPCONFIG_DISP_ADDRESS_LINE1":"Adresse 1",
      "APPCONFIG_DISP_ADDRESS_LINE2":"Adresse 2",
      "APPCONFIG_DISP_ADVANCE_TEXT":"Erweiterter Zeilenbenutzerdefinierter Text",
      "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Appconfig disp Vorabtext für jährlich",
      "APPCONFIG_DISP_API_URL":"Api Url",
      "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Aktivieren Sie die Lizenzverfolgung",
      "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Analytics anzeigen",
      "APPCONFIG_DISP_APPLICATION_NAME":"Anwendungsname",
      "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Datum der Microsoft-Rechnungserstellung",
      "APPCONFIG_DISP_AZURE_CSPOFFERID":"Azure CSP-Angebots-ID",
      "APPCONFIG_DISP_BANK_NAME":"Bank Name",
      "APPCONFIG_DISP_BILLING_CYCLE":"Rechnungskreislauf",
      "APPCONFIG_DISP_BILLING_DATE":"Datum, an dem Rechnungen in C3 erstellt werden",
      "APPCONFIG_DISP_BILLING_PERIOD":"Abrechnungszeitraum",
      "APPCONFIG_DISP_BILLING_PROVIDER":"Abrechnungsanbieter",
      "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Konfiguration des Abrechnungsanbieters",
      "APPCONFIG_DISP_BILLING_STARTDAY":"Abrechnungsstarttag",
      "APPCONFIG_DISP_BUSINESS_NUMBER":"Geschäftsnummer",
      "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Geschäftsnummernschlüssel",
      "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Überprüfen Sie die Rollen gegen 0365",
      "APPCONFIG_DISP_CIN_NUMBER":"CIN-Nummer",
      "APPCONFIG_DISP_CITY":"Stadt",
      "APPCONFIG_DISP_CLIENT_ID":"Kundenkennung",
      "APPCONFIG_DISP_COMPANY_NAME":"Name der Firma",
      "APPCONFIG_DISP_COMPANY_URL":"Firmen-URL",
      "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Zusatzbeschreibung",
      "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Name der Firma",
      "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Datum Kultur",
      "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Berücksichtigen Sie ConnectWise Manage Prices",
      "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Erstellungsdatum im Umsatzreport berücksichtigen",
      "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Wenden Sie sich an den Firmennamen",
      "APPCONFIG_DISP_CONTACT_EMAIL":"Kontakt E-mail",
      "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Kontakt Vorname",
      "APPCONFIG_DISP_CONTACT_LAST_NAME":"Kontakt Nachname",
      "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Kontakt Telefonnummer",
      "APPCONFIG_DISP_COUNTRY":"Land",
      "APPCONFIG_DISP_COUNTRY_CODE":"Landesvorwahl",
      "APPCONFIG_DISP_CURRENCY_CODE":"Währungscode",
      "APPCONFIG_DISP_CUSTOM_BILLING":"Benutzerdefinierte Abrechnung anwenden",
      "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL der Kundeneinwilligung",
      "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Beschränkung des Kunden beim Onboarding unter mehreren Entitäten",
      "APPCONFIG_DISP_DATE_FORMAT":"Datumsformat",
      "APPCONFIG_DISP_DATE_TIME_FORMAT":"Datum Uhrzeit Format",
      "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Datum-Uhrzeit-Format für C3-APIs",
      "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Tage, um eine Benachrichtigung über den Warenkorbstatus zu senden",
      "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Anzahl der Tage zum Speichern der Massenrechnungsdatei im Cloud-Speicher",
      "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"Benutzerdefinierte Landing Page URL",
      "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Standardsprache",
      "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Standard-Seitenzahl",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"AGB-URL (Text)",
      "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL der Allgemeinen Geschäftsbedingungen",
      "APPCONFIG_DISP_EMAIL_LOGO":"E-Mail-Logo",
      "APPCONFIG_DISP_ENABLE_TOUR":"Tour aktivieren",
      "APPCONFIG_DISP_FAVICON_LOGO":"Favicon-Logo",
      "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Sollte die MPN-ID von Resellern für Kunden und Käufe berücksichtigt werden?",
      "APPCONFIG_DISP_IFSC_CODE":"IFSC-Code",
      "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Rechnungszeile Autorisierte Zeile",
      "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Rechnungsfußzeile",
      "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Tage der Rechnungserstellung",
      "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cloud-Speicher-Verbindungszeichenfolge",
      "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Gültige Tage für Rechnungs-Zip",
      "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Zustimmung des Partners erforderlich",
      "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Öffentlicher Katalog, unterstützt durch Azure Search",
      "APPCONFIG_DISP_LINK_TO_CONTACT":"Link zur Kontaktseite",
      "APPCONFIG_DISP_LINK_TO_FAQ":"Link zu Kunden-FAQ",
      "APPCONFIG_DISP_LINK_TO_PORTAL":"Link zum Portal",
      "APPCONFIG_DISP_LINK_TO_SUPPORT":"Link zum Support",
      "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Link zum Willkommenslogo",
      "APPCONFIG_DISP_LOGGEDIN_LOGO":"Eingeloggtes Logo",
      "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Melden Sie sich bei Portal an",
      "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":"Obligatorische CustomerId in Api-Aufrufen",
      "APPCONFIG_DISP_MAPPING_TYPE":"Zuordnungstyp",
      "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Maximale Einträge für den Export",
      "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Maximum Entitlement Creation Count",
      "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Maximale Mandantenerstellungsanzahl",
      "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft Sicherheitseinwilligung in Tagen erinnern",
      "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Mindestgebühr",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL der NCE-Nutzungsbedingungen (Text)",
      "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL der NCE-Nutzungsbedingungen",
      "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Onboard-Kundensuche mit Abrechnung",
      "APPCONFIG_DISP_PAN_NUMBER":"Pan-Nummer",
      "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"PAN-Nummerntaste",
      "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Native Anwendungs-ID",
      "APPCONFIG_DISP_PARTNER_CURRENCY":"Partnerwährung",
      "APPCONFIG_DISP_PARTNER_PASSWORD":"Admin Agent Passwort",
      "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Sprachpakete",
      "APPCONFIG_DISP_PARTNER_TENANT_ID":"Partner-Mieter-ID",
      "APPCONFIG_DISP_PARTNER_USERNAME":"Benutzername des Admin-Agenten",
      "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL der Microsoft Cloud Reseller-Vereinbarung",
      "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL der Allgemeinen Geschäftsbedingungen des Partners",
      "APPCONFIG_DISP_PBI_GROUP_ID":"Power BI-Gruppen-ID",
      "APPCONFIG_DISP_PBI_PASSWORD":"Power BI-Passwort",
      "APPCONFIG_DISP_PBI_REPORT_ID":"Power BI-Berichts-ID",
      "APPCONFIG_DISP_PBI_USER_NAME":"Power BI-Benutzername",
      "APPCONFIG_DISP_PBIC_LIENT_ID":"Power BI-Client-ID",
      "APPCONFIG_DISP_PDF_LOGO":"PDF-Logo",
      "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"PDF-Logo auf der rechten Seite",
      "APPCONFIG_DISP_POSTAL_CODE":"Postleitzahl",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Powered By Firmenname",
      "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Powered By Firmen-URL",
      "APPCONFIG_DISP_PRIVATE_KEY":"Privater Schlüssel",
      "APPCONFIG_DISP_PUBLIC_KEY":"Öffentlicher Schlüssel",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks KundenId",
      "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks Kundengeheimnis",
      "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Untersuchen Sie Diskrepanzen bei E-Mail-Empfängern für die Erstellung von Vorausrechnungen",
      "APPCONFIG_DISP_RECON_REPORT_LOGO":"Abstimmungsbericht Logo",
      "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Abstimmungsbericht Empfänger benachrichtigen",
      "APPCONFIG_DISP_REFUND_TEXT":"Appconfig disp Rückerstattungstext",
      "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Appconfig disp-Rückerstattungstext für jährlich",
      "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Abonnement aussetzen, wenn die Gefahrenschwelle erreicht ist",
      "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Power BI-Berichtsmenü anzeigen",
      "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Aktivieren, um die externe Rechnungsnummer im Rechnungszusammenfassungsbericht abzurufen",
      "APPCONFIG_DISP_SHOW_FOOTER":"Footer anzeigen",
      "APPCONFIG_DISP_SHOW_NEXT_STEP":"Nächsten Schritt anzeigen",
      "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Allgemeine Geschäftsbedingungen für die Abonnementverwaltung aktivieren",
      "APPCONFIG_DISP_SMTP_ENABLESSL":"Aktivieren Sie SSL",
      "APPCONFIG_DISP_SMTP_FROM_MAIL":"Aus der Email",
      "APPCONFIG_DISP_SMTP_FROM_NAME":"Vom Anzeigenamen",
      "APPCONFIG_DISP_SMTP_HOST":"Gastgeber",
      "APPCONFIG_DISP_SMTP_PASSWORD":"SMTP-Passwort",
      "APPCONFIG_DISP_SMTP_PORT":"SMTP-Port",
      "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Betreff Präfix",
      "APPCONFIG_DISP_SMTP_USERNAME":"Nutzername",
      "APPCONFIG_DISP_STATE":"Zustand",
      "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
      "APPCONFIG_DISP_TAX_TYPE_KEY":"Steuertyp",
      "APPCONFIG_DISP_TAX_TYPE_VALUE":"Steuerwert",
      "APPCONFIG_DISP_WELCOME_LOGO":"Willkommens-Logo",
      "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Startseite",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Xero-Kontocode für Anpassungen",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Xero-Kontocode für OneTime",
      "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Xero-Kontocode für Reserved Instances",
      "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
      "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
      "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
      "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xero Dezimalstellen",
      "APPCONFIG_SELECTED_SMTP_OPTION":"Wählen Sie den SMTP-Typ aus",
      "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Wählen Sie die SMTP-Option",
      "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Externe Rechnungsnummer im Rechnungszusammenfassungsbericht anzeigen",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"Moderne Authentifizierungsanwendungs-ID",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"Client-ID der Azure AD-App",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Modernes Passwort für die Authentifizierungsanwendung",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Client-Geheimnis der Azure AD-App",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Moderne Authentifizierung per E-Mail",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"SMTP aus E-Mail",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"Moderne Authentifizierungs-Mandanten-ID",
      "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"Mandanten-ID des Benutzerkontos, das zum Senden von E-Mails verwendet wird",
      "APPEND_CUSTOM_TRIAL_DAYS":"(Testzeit)",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":"Das verbleibende Limit nach Genehmigung dieser Anfrage beträgt",
      "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" Das aktuelle verbleibende Limit wird nach einer erfolgreichen Verlängerung aktualisiert.",
      "approvals":"Zulassungen",
      "APPROVED_AND_INITIATED":"Genehmigt und initiiert",
      "APPROVED_BY":"durch den Händler",
      "APRIL":"April",
      "ASSIGN_BUNDLE_PRODUCTS":"Bundle-Produkte zuweisen",
      "ASSIGN_CUSTOMER_PLAN":"Kundenplan zuweisen",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Möchten Sie {{accountManager}} sicher {{customer}} zuweisen?",
      "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Kunde ist erfolgreich zugeordnet",
      "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Reseller ist erfolgreich zugeordnet",
      "ASSIGN_USAGE_PRODUCTS":"Verwendungsprodukte zuweisen",
      "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Kunden erfolgreich dem Account Manager zugewiesen",
      "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Reseller erfolgreich dem Account Manager zugewiesen",
      "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Ich verstehe, dass jede Person, die Windows 365 Business mit Windows Hybrid Benefit nutzt, auch eine gültige Kopie von Windows 10/11 Pro auf ihrem primären Arbeitsgerät installiert haben muss.",
      "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Schließen",
      "AUDIT_LOG_DETAIL":"Einzelheiten",
      "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Details verbergen",
      "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Mehr Details",
      "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Versteckt",
      "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Aktionen--",
      "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Kunden--",
      "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Domänen--",
      "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Audit-Log",
      "AUDITLOG_BUTTON_LABEL_SEARCH":"Suche",
      "AUDITLOG_TEXT_ACTION_NAME":"Aktionen",
      "AUDITLOG_TEXT_AUDITLOG":"Audit-Log",
      "AUDITLOG_TEXT_CUSTOMER_NAME":"Kundenname",
      "AUDITLOG_TEXT_EFFECTIVE_FROM":"Gültig ab",
      "AUDITLOG_TEXT_EFFECTIVE_TO":"Wirksam zu",
      "AUDITLOG_TEXT_FROM":"Von",
      "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Domainname des Kunden",
      "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Versteckte Audit-Protokolle anzeigen",
      "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Aktionen",
      "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Betroffener Kunde",
      "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Datum",
      "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Initiator",
      "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Details anzeigen",
      "AUDITLOG_TEXT_TO":"Zu",
      "AUGUST":"August",
      "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Von Quickbooks empfangener Authentifizierungscode",
      "AUTH_CODE_RECIEVED_FROM_XERO":"Authentifizierungscode von Xero erhalten",
      "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"Der Stapel ist bereits geschlossen.",
      "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Anfrage erfolgreich abgeschlossen",
      "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"Die Kreditkarte ist abgelaufen.",
      "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"Der Gerätestapel ist voll. Bitte schließen Sie den Stapel.",
      "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"Das Gerät wurde deaktiviert.",
      "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Es wurde eine doppelte Transaktion eingereicht.",
      "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"Die Gerätekennung ist entweder nicht registriert oder nicht aktiviert.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"Die Gerätechargenkennung ist ungültig.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"Die Gerätekennung ist ungültig.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"Das Stornierungskennzeichen ist ungültig.",
      "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"Der angegebene Sicherheitscode war ungültig.",
      "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Beim Prozessor ist ein Validierungsfehler aufgetreten.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Die Anfragedaten haben die Prüfung der erforderlichen Felder für diese Anwendung nicht bestanden.",
      "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Das/die Anforderungsfeld(er) sind entweder ungültig oder fehlen.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"V.me-Transaktionen werden von diesem Händler nicht akzeptiert.",
      "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"Die Transaktion kann nicht gefunden werden.",
      "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"Die Stornoanforderung ist fehlgeschlagen. Entweder unterstützt die ursprüngliche Transaktionsart die Stornierung nicht, oder die Transaktion wird gerade abgewickelt.",
      "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"Der Händler wurde erfolgreich aktiviert.",
      "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"Der Händler ist für die automatische Schließung konfiguriert.",
      "AUTHORIZE_NET_CODE_NEED_CONSET":"Zustimmung des Zahlers erforderlich.",
      "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Diese Bestellung wurde bereits freigegeben oder storniert, daher können keine neuen Transaktionszuordnungen akzeptiert werden.",
      "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Ihre Bestellung ist eingegangen. Vielen Dank für Ihren Auftrag!",
      "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"Die ursprüngliche Transaktion befindet sich in einem geschlossenen Stapel.",
      "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Der Payment Gateway Account Service (id=8) wurde bereits akzeptiert.",
      "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Ein neuer Sicherheitscode wurde angefordert.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Diese Transaktion wurde bereits autorisiert.",
      "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal hat diese Transaktion bereits erfasst.",
      "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"Die Stornierung wurde erfolgreich verarbeitet.",
      "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"Das mobile Gerät wurde erfolgreich in die Datenbank eingefügt.",
      "AUTHORIZE_NET_CODE_SUCCESS":"Die Anfrage wurde erfolgreich bearbeitet.",
      "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"Das mobile Gerät wurde erfolgreich registriert und vom Kontoverwalter genehmigt.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Diese Transaktion wurde bereits storniert.",
      "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Diese Transaktion wurde bereits erfasst.",
      "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"Originaltransaktion für die Stornierung wurde nicht gefunden.",
      "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal hat die Transaktion abgelehnt. x_paypal_hdrimg überschreitet die maximal zulässige Länge.",
      "AUTHORIZE_NET_CODE_USE_XMETHOD":"Verwenden Sie x_method zur Angabe der Methode oder senden Sie nur x_call_id oder Karten-/Kontoinformationen.",
      "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"Dies ist eine laufende Entwicklung. Diese Nachricht wird nicht für die Produktion freigegeben. Sie ist nur ein Platzhalter für Entwickler.",
      "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"Der Umrechnungskurs für diese Karte ist verfügbar.",
      "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"Der Kartencode ist ungültig.",
      "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Diese Transaktion kann nicht akzeptiert werden.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Diese Transaktion kann nicht verarbeitet werden.",
      "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Für dieses Konto wurde(n) nicht die erforderliche(n) Genehmigung(en) für diese Anfrage erteilt.",
      "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"Der angegebene Name des Kontos und/oder die Kontoart stimmen nicht mit dem tatsächlichen Konto überein.",
      "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"ACH-Transaktionen können von diesem System nicht akzeptiert werden.",
      "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"Das Authentifizierungskennzeichen ist ungültig.",
      "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"Der Autorisierungscode ist ungültig.",
      "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"Die Art des Bankkontos ist ungültig.",
      "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"Wiederkehrende Rechnungen sind für diesen eCheck.Net-Typ nicht zulässig.",
      "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"Der Karteninhaber-Authentifizierungswert ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"Der ABA-Code ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"Das angegebene Zugriffstoken ist abgelaufen.",
      "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"Das für die Validierung der Anforderung verwendete Zugriffstoken ist nicht ausreichend für die Validierung.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"Die angeforderte API-Methode kann nicht ausgeführt werden, solange sich das Zahlungsgateway-Konto im Testmodus befindet.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Dieser Händler ist nicht mit diesem Wiederverkäufer verbunden.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"Das Händlerkonto muss für die Annahme von Kreditkartenzahlungen, eCheck-Zahlungen oder beidem eingerichtet sein.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"Das Zahlungsgateway-, Wiederverkäufer- oder Benutzerkonto ist derzeit nicht aktiv.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"Das Zahlungsgateway-Konto ist nicht für die Verarbeitung von Kreditkartenabonnements eingerichtet.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"Das Zahlungsgateway-Konto ist nicht für die Verarbeitung von eCheck.Net-Abonnements eingerichtet.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"Die Kontonummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"Der Abonnementbetrag ist bei der Erstellung eines Abonnements erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"ACH-Transaktionen werden von diesem Händler nicht akzeptiert.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"Die Summe aller Gutschriften für diese Transaktion ist größer als der ursprüngliche Transaktionsbetrag.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Alle Felder waren leer oder fehlten.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Alle Felder waren leer oder fehlten.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Ungültiger Wert.",
      "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Der Wert ist zu lang.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"Das Abonnement wurde bereits gekündigt.",
      "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"Der Datensatz wurde bereits gelöscht.",
      "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"Es wurde keine Genehmigung für die Transaktion zurückgegeben.",
      "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"Ein oder mehrere erforderliche Auktionswerte wurden nicht übermittelt.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"Der Authentifizierungstyp ist für den angeforderten Methodenaufruf nicht zulässig.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Ein Autorisierungscode ist erforderlich, aber nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Dieser Prozessor akzeptiert keine Null-Dollar-Autorisierungen.",
      "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"Die Transaktion wurde aufgrund einer AVS-Fehlanpassung abgelehnt. Die angegebene Adresse stimmt nicht mit der Rechnungsadresse des Karteninhabers überein.",
      "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Die Bankzahlungsmethode wird für das ausgewählte Geschäftsland nicht akzeptiert.",
      "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s kann nicht leer gelassen werden.",
      "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Bei der Verarbeitung ist ein Fehler aufgetreten. Rufen Sie den Merchant Service Provider an.",
      "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"Der aufrufende Webservice hat einen Fehler zurückgegeben.",
      "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"Storno-URL ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Diese Transaktion wartet auf die Abrechnung und kann nicht zurückerstattet werden.",
      "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"Die Gesamtzahl der Vorgänge kann die Laufzeit des Abonnements nicht über drei Jahre ab dem Startdatum hinaus verlängern.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"Die Kreditkarte ist abgelaufen.",
      "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"Die Kreditkartennummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Die Summen der Warenkorbartikel stimmen nicht mit den Bestellbeträgen überein. Vergewissern Sie sich, dass die Summe der Parameter der Zahlungsdetails mit der Bestellsumme übereinstimmt.",
      "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"Das Zahlungsgateway-Konto ist nicht für den Fakturierungs- oder CIM-Service aktiviert.",
      "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Client-Autorisierung verweigert.",
      "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Die einzigen unterstützten Inhaltstypen sind text/xml und application/xml.",
      "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"DUKPT-Blobs konnten nicht entschlüsselt werden und haben einen Fehler zurückgegeben.",
      "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"Das Land ist nicht gültig.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"Die Kreditkarte ist zum Startdatum des Abonnements nicht gültig.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"Die Kreditkartennummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Die Zahlungsmethode Kreditkarte wird für das ausgewählte Land nicht akzeptiert.",
      "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Kreditkartentransaktionen werden von diesem Händler nicht akzeptiert.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId und Kundendaten schließen sich gegenseitig aus; für eine einzelne Anfrage kann nur eine der beiden Angaben gemacht werden.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Fehler beim Erstellen eines Kundenprofils aus einer Transaktion.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Die Steuer-ID des Kunden oder Führerscheininformationen (Führerscheinnummer, Führerscheinstaat, Geburtsdatum des Führerscheins) sind für das Abonnement erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Bei Verwendung des Kundenprofils muss die CustomerPaymentProfileId angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Fehler beim Erstellen eines Kundenzahlungsprofils aus einer Transaktion.",
      "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Kundenprofil ist mit einem aktiven oder ausgesetzten Abonnement verknüpft und kann nicht gelöscht werden.",
      "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"Der Datenbankvorgang konnte nicht abgeschlossen werden, da die Zeit abgelaufen ist.",
      "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"Die Summe der Gutschriften für die referenzierte Transaktion würde den ursprünglichen Belastungsbetrag übersteigen.",
      "AUTHORIZE_NET_ERROR_CODE_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Beim Entschlüsseln der EMV-Daten ist ein Fehler aufgetreten.",
      "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"Das Kundenprofil verfügt nicht über ein Standard-Zahlungs-/Versandprofil.",
      "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"Das Fälligkeitsdatum ist überfällig oder nicht angegeben.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Es wurde bereits ein Duplikat des Abonnements eingereicht.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Es wurde eine doppelte Transaktion eingereicht.",
      "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"Die KSN ist dupliziert.",
      "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Eine gültige Kundenprofil-ID oder E-Mail ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"Die Mitarbeiter-ID muss zwischen 1 und %x Zeichen lang sein.",
      "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"EMV-Daten werden bei diesem Markttyp nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"Das Ablaufdatum der Kreditkarte ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"E-Mail-Versand fehlgeschlagen.",
      "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Validierung des gemeinsamen Schlüssels fehlgeschlagen.",
      "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Schwerwiegender Fehler beim Aufruf des Webdienstes.",
      "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"Die FDC-Händler-ID oder Terminal-ID ist falsch. Rufen Sie den Merchant Service Provider an.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"Der Feldtyp ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"Einer der Feldwerte ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"Eines der Felder hat eine ungültige Länge.",
      "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"Eines der erforderlichen Felder war nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"Der Fingerabdruck kann nicht erstellt werden.",
      "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"Der Wert für den Fingerabdruck ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Generischer InsertNewMerchant-Fehler.",
      "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Die Identifikationsnummern des globalen Zahlungssystems sind falsch. Rufen Sie den Merchant Service Provider an.",
      "AUTHORIZE_NET_ERROR_CODE_HASH":"Hash stimmt nicht überein.",
      "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"Die Hosted Payment Page erfasst die Zahlungsinformationen (Bank/Karte), daher sollten diese Informationen nicht in diese Anfrage aufgenommen werden.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"Der Impersonation-API-Schlüssel ist ungültig oder nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"Die Impersonation-Partner-Login-ID ist ungültig oder nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Sobald ein Abonnement erstellt wurde, kann das Intervall nicht mehr geändert werden.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"Der API-Benutzername ist ungültig und/oder der Transaktionsschlüssel oder API-Schlüssel ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"Die ID des Auktionskäufers ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"Die Transaktionsart ist für ACH-Transaktionen ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"In einem erforderlichen Feld fehlen Informationen oder sind ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"Die Intervalllänge muss 7 bis 365 Tage oder 1 bis 12 Monate betragen.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal hat die Transaktion abgelehnt.Ungültige Zahler-ID.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"Die Kombination aus Auktionsplattform-ID und Auktionsplattformname ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"Der Wert für den POS-Eingabemodus ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"Die Kundenprofil-ID, Zahlungsprofil-ID, Lieferadressen-ID oder Transaktions-ID für diese Anfrage ist für diesen Händler nicht gültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"Der Name des Stammknotens der XML-Anforderung ist die aufgerufene API-Methode. Sie ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"Die ID des Auktionsverkäufers ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"Der für die Abrechnung angeforderte Betrag darf sich nicht von dem ursprünglich genehmigten Betrag unterscheiden. Bitte stornieren Sie die Transaktion, falls erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"Die V.me-Transaktion befindet sich in einem ungültigen Zustand.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"Für die Benutzerauthentifizierung ist ein gültiger Wert für den Transaktions- oder API-Schlüssel erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"Für die Benutzerauthentifizierung ist ein gültiger Wert für den API-Benutzernamen erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Ungültiger Wert.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"Die EMV-Version ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"Der Wert für x_call_id ist nicht gefunden oder ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"Der Wert für x_discount ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"Der Wert für x_giftwrap ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"Der Wert x_misc ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal hat die Transaktion abgelehnt. x_paypal_payflowcolor muss ein 6-stelliger hexadezimaler Wert sein.",
      "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"Der Wert für x_subtotal ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Diese Rechnung wurde vom Absender storniert. Bitte kontaktieren Sie den Absender direkt, wenn Sie Fragen haben.",
      "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Für die Rechnungsnummer wurden keine Datensätze gefunden.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"Der eingereichte Transaktionsbetrag war größer als der maximal zulässige Betrag.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Die maximale Anzahl von Elementen für ein Array wurde erreicht.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Die maximale Anzahl der Zahlungsprofile für das Kundenprofil wurde erreicht.",
      "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Die maximale Anzahl der Versandadressen für das Kundenprofil wurde erreicht.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"Das Wiederverkäuferkonto ist für Merchant Boarding API nicht aktiviert.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"Der Händler akzeptiert diese Art von Kreditkarte nicht.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"Der Händler hat die Autorisierung für die Ressource abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"Der Händler akzeptiert diese Art von Kreditkarte nicht.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"Die Login-ID oder das Passwort des Händlers ist ungültig oder das Konto ist inaktiv.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"Der Händler hat zu viele Geräte in einem ausstehenden Status.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"Der Händler ist nicht aktiviert.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"Das Passwort des Händlers ist ungültig oder nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"Der Händler wurde beim Prozessor falsch eingerichtet. Rufen Sie den Händlerdienstleister an.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Die Paymentech-Identifikationsnummern sind falsch. Rufen Sie den Merchant Service Provider an.",
      "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"Die MerchantId konnte nicht in eine ganze Zahl umgewandelt werden.",
      "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"Der Wert x_call_id fehlt.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"Die Kennung des mobilen Geräts ist nicht mit dem Händlerkonto verknüpft.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"Das mobile Gerät ist vorhanden, befindet sich aber in einem ausstehenden Status.",
      "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"Das mobile Gerät ist vorhanden, hat aber den Status \"deaktiviert\".",
      "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"Land muss ein gültiger zwei- oder dreistelliger Wert sein, falls angegeben.",
      "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"Der authentifizierte Händler hat keinen Zugriff auf das angegebene OTS-Token.",
      "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"Es wurden keine Datensätze gefunden, die Ihrer Abfrage entsprechen.",
      "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"Für das Händlerkonto sind keine Abonnements für die angeforderte Art von Abonnements verfügbar.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"Das Access-Token hat keine Berechtigung zum Aufruf der API-Methode.",
      "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"Die Transaktion wurde autorisiert, aber der Kunde konnte nicht benachrichtigt werden; sie wird nicht abgerechnet.",
      "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me by Visa unterstützt keine Stornierungen von erfassten oder Kredit-Transaktionen. Bitte lassen Sie die Transaktion abrechnen und verarbeiten Sie dann eine Erstattung für die erfasste Transaktion.",
      "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"Einer der Feldwerte ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Es ist nur entweder CreditCard oder Bank erlaubt.",
      "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile und profileIds schließen sich gegenseitig aus, es kann immer nur einer von beiden angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"Für die Erstellung eines Zahlungsprofils mit diesem OpaqueData-Deskriptor muss transactionMode auf liveMode gesetzt werden.",
      "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Wenn die Kundenprofil-ID angegeben wird, muss sie mit der für das ursprüngliche Abonnement verwendeten Kundenprofil-ID übereinstimmen.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Die angegebenen OTS-Token-Daten sind ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"Das angegebene OTS-Token ist abgelaufen.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"Das angegebene OTS-Token ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"Der OTS-Dienst kann die Anfrage aufgrund eines Validierungs- oder Konfigurationsfehlers nicht abschließen.",
      "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"Der Paging-Offset überschreitet den zulässigen Wert. Überprüfen und verringern Sie den Wert.",
      "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Beim Parsen der EMV-Daten ist ein Fehler aufgetreten.",
      "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Dieser Prozessor akzeptiert keine Teilrückbuchungen.",
      "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"Das Partnerkonto ist nicht berechtigt, sich für das Anmeldekonto auszugeben.",
      "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal hat die Transaktion abgelehnt. Diese Zahler-ID gehört zu einem anderen Kunden.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Der Payment Gateway Account Service (id=8) wurde bereits akzeptiert. Eine Ablehnung ist nicht zulässig.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"Der Zahlungsgateway-Kontodienst (id=8) wurde bereits abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"Das Zahlungsgateway-Konto ist nicht für die automatisierte wiederkehrende Rechnungsstellung aktiviert.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"Das Zahlungsgateway-Konto ist nicht für den Customer Information Manager (CIM) aktiviert.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Beim Erstellen eines Abonnements oder Zahlungsprofils sind Zahlungsinformationen erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"Die Erstellung eines Kundenprofils ist fehlgeschlagen. Diese Zahlungsmethode unterstützt die Profilerstellung nicht.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Zahlungsprofil ist mit einem aktiven oder ausgesetzten Abonnement verknüpft und kann nicht gelöscht werden.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Bei der Erstellung eines Abonnements ist ein Zahlungsplan erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"Das Ändern der Zahlungsart für das Abonnement zwischen Kreditkarte und eCheck.Net wird derzeit nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Status \"Ausstehend\" (nicht abgeschlossen).",
      "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"Voraussetzung fehlgeschlagen.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"Das Verarbeiterkonto wurde nicht so eingerichtet, dass das Kennzeichen für den Testmodus auf false gesetzt werden kann.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"Der Händler hat noch keine Prozessorinformationen bereitgestellt, um das Testmodus-Flag auf \"false\" zu setzen.",
      "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"Die Konfiguration mit dem Prozessor ist ungültig. Rufen Sie den Händlerdienstleister an.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"Die Erstellung des Kundenprofils ist fehlgeschlagen. Diese Transaktions-ID ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Zahlungs- und Versandprofil-IDs können bei der Erstellung neuer Profile nicht angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Zahlungs- und Versandprofil-IDs können nicht angegeben werden, wenn eine gehostete Zahlungsseite angefordert wird.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Wenn die ID des Kundenprofils, die ID des Zahlungsprofils und die ID der Versandadresse angegeben werden, müssen sie mit der ursprünglichen Transaktion übereinstimmen.",
      "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"Die Suche nach dem Versandprofil anhand der Kundenprofil-ID und der Versandprofil-ID hat keine Datensätze gefunden.",
      "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Es wurde bereits ein Duplikat des Kundenprofils, des Kundenzahlungsprofils oder der Kundenadresse übermittelt.",
      "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"Die Einstellung RecurringBilling ist ein Pflichtfeld für wiederkehrende tokenisierte Zahlungstransaktionen.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"Referenz-Id nicht gefunden.",
      "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Ausstellung einer Gutschrift.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"Die angeforderte Aktion ist aufgrund des aktuellen Status des Objekts nicht zulässig.",
      "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Bei der Anforderung von CreateProfile können die Zahlungsdaten nicht null sein.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Zahlung nicht autorisiert. Die Zahlung wurde vom Benutzer nicht autorisiert.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"Die EMV-Version ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"Terminal/Spur-Nummer muss numerisch sein.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Opaker Deskriptor ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"Zahler-ID ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"Die Unterschrift muss als PNG-formatierte Daten vorliegen.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"PayPal-Transaktionen erfordern eine gültige URL für cancel_url",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Für PayPal-Transaktionen ist eine gültige URL für success_url erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Erforderlicher Knoten fehlt.",
      "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Es wurde ein Validierungsfehler von V.me zurückgegeben.",
      "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Wir halten derzeit die letzte Transaktion zur Überprüfung zurück. Bevor Sie das Abonnement reaktivieren, überprüfen Sie die Transaktion.",
      "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"Der Stammknoten verweist nicht auf einen gültigen XML-Namensraum.",
      "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"Die Buyrate kann nicht auf einen niedrigeren Wert als die Sellrate gesetzt werden.",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"Der Server ist derzeit überlastet. Bitte versuchen Sie es später noch einmal.",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Server-Fehler",
      "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"Der Server wird gerade gewartet, daher ist die angeforderte Methode nicht verfügbar. Bitte versuchen Sie es später noch einmal.",
      "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Ihre Sitzung ist abgelaufen oder existiert nicht. Sie müssen sich erneut anmelden, um weiterarbeiten zu können.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"Der für die Abrechnung angeforderte Betrag kann nicht größer sein als der ursprünglich genehmigte Betrag.",
      "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"Die Autorisierung wurde genehmigt, aber die Abrechnung ist fehlgeschlagen.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"Versandadressen-ID und Versanddaten schließen sich gegenseitig aus, es kann nur eine von ihnen für eine einzelne Anfrage angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Versandprofil ist mit einem aktiven oder ausgesetzten Abonnement verbunden und kann nicht gelöscht werden.",
      "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Wenn Sie das Kundenprofil mit Kreditkarteninformationen zur Angabe des Versandprofils verwenden, muss die Versandprofil-ID angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Signaturschlüssel fehlt.",
      "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"Berichte können nicht für zukünftige Daten erstellt werden, daher ist das angegebene Datum ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Sobald ein Abonnement erstellt wurde, kann das Startdatum nicht mehr geändert werden.",
      "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"Der Status ist nicht gültig.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"Das Startdatum des Abonnements kann nicht vor dem Einreichungsdatum des Abonnements liegen.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Abgelaufene oder beendete Abonnements können nicht gekündigt werden.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Abgelaufene, gekündigte oder beendete Abonnements können nicht aktualisiert werden.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"Die Abonnement-ID für diese Anfrage ist für diesen Händler nicht gültig.",
      "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"Das Startdatum des Abonnements ist erforderlich, um ein Abonnement zu erstellen.",
      "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"Der angegebene Währungscode ist entweder ungültig, wird nicht unterstützt, ist für diesen Händler nicht zulässig oder hat keinen Wechselkurs.",
      "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"Die Elavon-Banknummer oder Terminal-ID ist falsch. Rufen Sie den Händlerdienstanbieter an.",
      "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Die Signaturdaten sind zu groß.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Diese Transaktion wurde bereits genehmigt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"Die Transaktion wurde von einer gesperrten IP-Adresse eingereicht.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Erteilung einer fortgesetzten Autorisierung.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Erteilung einer fortgesetzten Autorisierung mit Auto Capture.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"Die Erstellung eines Kundenprofils ist fehlgeschlagen. Diese Transaktionsart unterstützt die Profilerstellung nicht.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Diese Transaktion muss verschlüsselt werden.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"Die Transaktions-ID ist ungültig oder nicht vorhanden.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Diese Transaktion kann derzeit aufgrund von Systemwartungsarbeiten nicht akzeptiert werden. Bitte versuchen Sie es später erneut.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"Die Transaktion kann nicht gefunden werden.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"EMV-basierte Transaktionen werden derzeit für diesen Prozessor und Kartentyp nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"EMV-Daten werden für diese Transaktionsart nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"PayPal-Transaktionen werden von diesem Händler nicht akzeptiert.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"PayPal-Transaktionen erfordern eine x_version von mindestens 3.1.",
      "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"Die Transaktionsart ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"Die Anzahl der angegebenen Versuche muss geringer sein als die Anzahl der insgesamt angegebenen Versuche.",
      "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Bei der Verarbeitung dieser Anfrage ist ein unerwarteter Systemfehler aufgetreten.",
      "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Unbekannter Fehler.",
      "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Rechnung erstellt, aber E-Mail nicht gesendet und Status nicht aktualisiert",
      "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"Der Referrer, die Relay-Antwort oder der Quittungslink-URL ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"Der APIUser existiert bereits.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"Der Benutzer verfügt nicht über ausreichende Berechtigungen, um ein mobiles Gerät mit diesem Händlerkonto zu verwenden.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"Der Benutzer hat nicht die Berechtigung, die API aufzurufen.",
      "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"Der Benutzer verfügt nicht über die Berechtigung zum Aufrufen der API-Methode.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Ein gültiger Betrag ist erforderlich.",
      "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"Die Anzahl der Versuche kann nicht Null sein, wenn ein gültiger Versuchsbetrag übermittelt wird.",
      "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Wenn entweder ein Versuchsbetrag oder eine Anzahl von Versuchen angegeben wird, müssen Werte für beide angegeben werden.",
      "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Die VITAL-Identifikationsnummern sind falsch. Rufen Sie den Merchant Service Provider an.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Dies ist das Ergebnis eines XML-Parser-Fehlers.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Der Knoten ist ungültig.",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Fehlende(s) Feld(er).",
      "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Zu viele Knoten angegeben.",
      "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"Die Kombination aus Kartentyp, Authentifizierungskennzeichen und Karteninhaber-Authentifizierungswert ist ungültig.",
      "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"Der kommerzielle Kartentyp ist ungültig.",
      "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Dieses Land ist für Käufer nicht zugelassen.",
      "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"Das Kryptogramm ist entweder ungültig oder kann nicht in Kombination mit anderen Parametern verwendet werden.",
      "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
      "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"Der Gerätetyp ist ungültig oder fehlt.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"Das Geburtsdatum des Führerscheins ist ungültig.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"Die Führerscheinnummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"Der Zustand des Führerscheins ist ungültig.",
      "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"Der Steuerbetrag ist ungültig.",
      "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Dieser eCheck.Net-Typ ist bei Verwendung des vom Zahlungsgateway gehosteten Zahlungsformulars nicht zulässig.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Dieser eCheck.Net-Typ ist nicht zulässig.",
      "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Dieser eCheck.Net-Typ ist für diesen Bankkontotyp nicht zulässig.",
      "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"Der Parameter eCheck-Typ ist ungültig.",
      "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"Der Frachtbetrag ist ungültig.",
      "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"Das Gateway unterstützt die angeforderte Integrationsmethode nicht mehr.",
      "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"Die Schecknummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"Die Kreditkartennummer ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"Das Ablaufdatum der Kreditkarte ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"Die Belegposition %1 ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"Die ID des Auktionsangebots ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"Der Typ des Auktionsangebots ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"Die ID der Auktionsplattform ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Ein oder mehrere Pflichtfelder fehlen oder sind ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"Die angegebene SplitTenderID ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Ungültige ECI-Länge des Token-Antragstellers.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Die Länge der Token-Antragsteller-ID ist ungültig.",
      "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Ungültiger Token-Antragsteller-Name.",
      "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"Der Name der Auktionsplattform ist ungültig.",
      "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"Die Anzahl der eingereichten Positionen ist nicht zulässig. Es können maximal %1 Positionen eingereicht werden.",
      "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"Der Markttyp ist ungültig.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Dieses Händlerkonto wurde geschlossen.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Das Händlerkonto ist nicht aktiv. Diese automatische Zahlung wird nicht verarbeitet.",
      "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Das Händlerkonto befindet sich im Testmodus. Diese automatisierte Zahlung wird nicht verarbeitet.",
      "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Dieser Prozessor akzeptiert keine Null-Dollar-Autorisierung für diesen Kartentyp.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Händler.",
      "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Ein oder mehrere der HTML-Typ-Konfigurationsfelder scheinen nicht sicher zu sein.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Einer oder mehrere der erforderlichen Werte für den Sub-Händler fehlen.",
      "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Einer oder mehrere der Werte des Sub-Händlers sind ungültig.",
      "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"Die PayformMask ist ungültig.",
      "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Es ist ein Fehler bei der Verarbeitung der Zahlungsdaten aufgetreten.",
      "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"Die Verarbeitung von Apple Payments ist für dieses Händlerkonto nicht aktiviert.",
      "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Dieser Prozessor akzeptiert keine wiederkehrenden Transaktionen.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Dieser Anbieter erlaubt keine Stornierung von Gutschriften.",
      "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Dieser Verarbeiter unterstützt diese Methode der Übermittlung von Zahlungsdaten nicht.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Diese Anfrage kann nicht akzeptiert werden.",
      "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"Transaktions-ID und Split-Tender-ID können nicht beide in derselben Anfrage verwendet werden.",
      "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"Der angeforderte Formulartyp ist ungültig.",
      "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"Der Betrag dieser Anforderung wurde nur teilweise auf der angegebenen Prepaid-Karte genehmigt. Eine zusätzliche Zahlung ist erforderlich, um den Restbetrag dieser Transaktion zu erfüllen.",
      "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"Erfolgs-URL ist erforderlich.",
      "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"Das Antwortformat ist ungültig",
      "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"Storno wird für diese Transaktionsart nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"Das angeforderte Skript ist entweder ungültig oder wird nicht mehr unterstützt.",
      "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Skripte werden nur in Version 2.5 unterstützt.",
      "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"Der Versandstaat oder das Land ist ungültig.",
      "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"Die SSN oder Steuer-ID ist ungültig.",
      "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"Der Zuschlagsbetrag ist ungültig.",
      "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"Der Steuerbetrag ist ungültig.",
      "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"Der Trinkgeldbetrag ist ungültig.",
      "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Die Track1-Daten haben kein gültiges Format.",
      "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Track2-Daten haben ein ungültiges Format.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"Die Transaktion wurde autorisiert, aber der Kunde konnte nicht benachrichtigt werden; sie wird nicht abgerechnet.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Diese Transaktion kann zur Zeit nicht akzeptiert werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Diese Transaktion kann nicht verarbeitet werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Transaktionen mit Karteninhaber-Authentifizierungswerten können nicht als wiederkehrend markiert werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Transaktionen dieses Markttyps können in diesem System nicht verarbeitet werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"Die Transaktionsmethode ist ungültig.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Diese Transaktion kann nicht akzeptiert werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Diese Transaktion kann nicht zur Verarbeitung akzeptiert werden.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"Die referenzierte Transaktion wurde nicht genehmigt.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"Die angegebene Transaktionsart wird für diesen Händler nicht unterstützt.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"Die Transaktion führte zu einer AVS-Fehlanpassung. Die angegebene Adresse stimmt nicht mit der Rechnungsadresse des Karteninhabers überein.",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"Die Transaktionsart ist ungültig",
      "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"Die Transaktionsart ist ungültig. Bitte kontaktieren Sie den Händler.",
      "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Ein gültiges Rechnungsland ist erforderlich.",
      "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Ein gültiges Land ist erforderlich.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Ein gültiges Bundesland/Provinz ist erforderlich.",
      "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Ein gültiges Bundesland ist erforderlich.",
      "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"Die Version 2.5 wird nicht mehr unterstützt.",
      "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"Der Parameter Version ist ungültig.",
      "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Zugriff auf globale CSP-Administratoren zulassen",
      "AUTO_PAYMENT_REQUEST_SUCCESS":"Die Zahlung wurde erfolgreich eingeleitet. Die Verarbeitung wird einige Zeit dauern. Bitte überprüfen Sie den Status nach einiger Zeit.",
      "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"Die Markierung für die automatische Verlängerung gilt nur für NCE-Angebote",
      "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"Planprodukt erstellen ist während des autoSync-Prozesses nicht erfolgreich",
      "AUTO_SYNC_MACRO_NOT_FOUND":"Makro nicht gefunden",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Mehrere gemappte Angebote gefunden",
      "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Mehrere gemappte Angebote mit Promotion gefunden",
      "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Mehrere Pläne gefunden",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"Keine gemappten Angebote gefunden",
      "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Keine zugeordneten Angebote mit Aktion gefunden",
      "AUTO_SYNC_NO_PLAN_FOUND":"Kein Plan gefunden",
      "Autotask":"Automatische Aufgabe",
      "AUTOTASK_API_USER_NAME_IS_MISSING":"API-Benutzername fehlt. Bitte aktualisieren Sie den Benutzernamen im Einstellungsbildschirm.",
      "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"Autotask-Menge stimmt nicht mit alter C3-Menge überein",
      "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Wählen Sie eine Berechtigung aus",
      "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Kategorie",
      "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Beschreibung",
      "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Auswirkungen",
      "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Betroffene Ressource",
      "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Letzte Aktualisierung",
      "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Mögliche Vorteile",
      "AZURE_BILLING":"Azure-Abrechnung",
      "AZURE_BILLING_CYCLE":"Rechnungskreislauf",
      "AZURE_ENTITLEMENT_MANAGEMENT":"Azure-Berechtigung",
      "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Azure-Berechtigungsverwaltung",
      "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"Dem Mandanten fehlt eine Azure-Berechtigung!",
      "AZURE_ERROR_RECOMMENDATION_TEXT":"Fehler beim Abrufen der Azure Advisor-Empfehlungen",
      "AZURE_ESTIMATE":"Azure-Schätzung",
      "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Abrechnungszeitraum",
      "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Berechtigungs-ID",
      "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Berechtigungsname",
      "AZURE_ESTIMATE_LABEL_DEPARTMENT":"Abteilung :",
      "AZURE_ESTIMATE_LABEL_SITE":"Seite? ˅ :",
      "AZURE_ESTIMATE_REPORT":"Azure-Schätzbericht",
      "AZURE_ESTIMATE_TAGS":"Stichworte",
      "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Bitte wählen Sie mindestens ein Tag zum Herunterladen der Azure-Schätzung aus",
      "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Azure-Tags",
      "AZURE_ESTIMATE_TAGS_TOOLTIP":"Im Azure-Portal definierte Ressourcentags",
      "AZURE_ESTIMATED_COST":"Geschätzte Kosten",
      "AZURE_ESTIMATES":"Azure-Schätzungen",
      "AZURE_GROUP_DETAILS":"Gruppendetails",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Azure-Gruppen",
      "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Admin zur Gruppe hinzufügen",
      "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Gruppe hinzufügen",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Zurück zu Gruppenbenutzer",
      "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Zurück zu Gruppen",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Gruppe bearbeiten",
      "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Benutzer gruppieren",
      "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Gruppenbenutzer hinzufügen",
      "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Azure-Gruppen",
      "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Benutzer von {{groupname}}",
      "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrator",
      "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Abrechnungszugriff",
      "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Gefahrenschwelle",
      "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Email",
      "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Fehlerschwelle",
      "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Gruppenname",
      "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Info-Schwelle",
      "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
      "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Benachrichtigungshäufigkeit",
      "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Warnschwelle",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrator",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Abrechnungszugriff",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Name",
      "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Benachrichtigungshäufigkeit",
      "AZURE_LOADING":"Wenn Sie versuchen, Daten aus Azure zu laden, warten Sie bitte...",
      "AZURE_LOADING_MESSAGE":"Wenn Sie Microsoft Azure abfragen, kann das Laden eine Minute dauern...",
      "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"Azure-Margenwert erfolgreich aktualisiert",
      "AZURE_NEW_MARKUP":"Neues Markup",
      "AZURE_OLD_MARKUP":"Aktuelle Auszeichnung",
      "AZURE_PLAN_CREATION_FAILED":"Erstellung des Azure-Plans fehlgeschlagen",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"Der Rechnungsbetrag wird für den Kunden sowie seine jeweiligen Standorte oder Abteilungen auf der Grundlage des zugewiesenen Prozentsatzes berechnet.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"Die Zuteilung des Azure-Plans und seiner Berechtigungen kann durch Zuweisung eines Prozentsatzes auf verschiedene Standort- und Abteilungseinheiten aufgeteilt werden.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Anleitung",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Zuweisungsschritte: <br /> a. Erweitern Sie das gewünschte Abonnement. <br /> b. Klicken Sie auf die Prozentzahl, die einer Entität zugeordnet ist. <br /> c. Geben Sie den gewünschten Prozentsatz ein. <br /> d. Klicken Sie auf <strong>Senden <strong/>, um die Zuordnung zu speichern.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Um den Azure-Plan oder die Berechtigung vollständig einem Standort/einer Abteilung zuzuweisen, geben Sie 100 ein.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Um eine Zuordnung freizugeben, geben Sie 0 ein.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Freigegebene Zuteilungen werden der unmittelbaren Muttergesellschaft zugewiesen.",
      "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Diese Zuweisungen haben keine Auswirkungen auf Berichte und der Azure-Kostenvoranschlags- und Abrechnungsbericht zeigt weiterhin alle Daten des Kunden an.",
      "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Bewerten Sie nach Kategorie",
      "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Alle Kategorien",
      "AZURE_RECOMMENDATION_ALL_COST":"Kosten",
      "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"Hohe Verfügbarkeit",
      "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"Operative Exzellenz",
      "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Leistung",
      "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Zuverlässigkeit",
      "AZURE_RECOMMENDATION_ALL_SECURITY":"Sicherheit",
      "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Laden Sie die Empfehlungsdetails herunter",
      "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Empfehlungsdetails",
      "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Dieses Feld wird benötigt",
      "AZURE_RECOMMENDATION_SUBMIT":"einreichen",
      "AZURE_RECOMMENDATION_TEXT":"Azure-Empfehlung",
      "AZURE_RECOMMENDATIONS_TEXT":"Empfehlungen",
      "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Wählen Sie einen Währungscode aus",
      "AZURE_REPORT_DISTRIBUTOR_LIST":"Verteiler",
      "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Bitte wählen Sie entweder den Partner oder einen der Reseller aus.",
      "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Wählen Sie eine Entität aus",
      "AZURE_REPORT_ENTITY_LIST":"Einheit",
      "AZURE_REPORT_ENTITY_TOOLTIP":"Bitte wählen Sie entweder den Partner oder einen der Reseller aus.",
      "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Wählen Sie einen Wiederverkäufer aus",
      "AZURE_REPORT_RESELLERS_LIST":"Wählen Sie einen Wiederverkäufer",
      "AZURE_REPORTS_BITTON_TEXT":"Azure-Schätzbericht",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Details von",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Details nach Ressourcengruppe",
      "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Details nach Service",
      "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Beschaffe die Einzelheiten",
      "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Ohne Etiketten",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Azure-Berichte",
      "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Mit Anhängen",
      "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Rechnungsübersicht",
      "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Preis des Azure-Plans",
      "AZURE_REPORTS_LABEL_TEXT_DATE":"Datum",
      "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Anspruch",
      "AZURE_REPORTS_LABEL_TEXT_GROUP":"Gruppe",
      "AZURE_REPORTS_LABEL_TEXT_MONTH":"Monat",
      "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Wähle die Gruppe",
      "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Abonnement",
      "AZURE_REPORTS_OPTION_SELECT_TAG":"Wählen Sie ein Tag aus, um tagweise Daten zu erhalten",
      "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Alle Ansprüche",
      "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Alle Gruppen",
      "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Alle Abonnements",
      "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Datum auswählen",
      "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Nicht zugewiesen",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Kosten",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Menge",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Ressourcenname",
      "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Dienstname",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Prüfung",
      "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Einzelheiten",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Ressourcengruppe",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Ressourcenname",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Dienstname",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Gesamtkosten",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Terminzeit",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Ereigniskategorie",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Ereignis initiiert von",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Operationsname",
      "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Status",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Prüfung",
      "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Einzelheiten",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Kosten",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Datum",
      "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Menge",
      "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Gesamtkosten",
      "AZURE_REPPORT_CURRENCY":"Währung",
      "AZURE_REPPORT_CURRENCY_TOOLTIP":"Sie müssen eine Währung auswählen, um den Bericht zu erhalten",
      "AZURE_REPPORT_CUSTOMERS":"Kunden",
      "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Wählen Sie Kunde",
      "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Wählen Sie einen Kunden aus",
      "AZURE_ROLE_NAME_CONTRIBUTOR":"Mitwirkender",
      "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Benutzerdefinierte Rolle",
      "AZURE_ROLE_NAME_OWNER":"Inhaber",
      "AZURE_ROLE_NAME_READER":"Leser",
      "AZURE_SUBSCRIPTION":"Azure-Abonnement",
      "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Bearbeiten",
      "AZURE_SUBSCRIPTION_DETAILS":"Abonnementdetails",
      "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Wählen",
      "AZURE_SUBSCRIPTION_ID":"Abonnement-ID",
      "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Dem Mandanten fehlt ein Azure-Plan!",
      "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Das Azure-Abonnement lautet \\''Update fehlgeschlagen\\''",
      "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"Das Azure-Abonnement wurde erfolgreich aktualisiert",
      "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Sind Sie sicher, dass Sie ein Upgrade durchführen möchten?",
      "AZURE_THRESHOLD_DETAILS":"Schwellendetails",
      "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Legen Sie ein monatliches Budget für dieses Abonnement fest und definieren Sie die Schwellenwerte für verschiedene Schweregrade wie Info, Warnung, Fehler und Gefahr.",
      "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Bei vom Kunden verwalteten Abonnements werden alle Gruppenadministratoren benachrichtigt, wenn die Nutzung einen bestimmten Schwellenwert erreicht oder überschreitet.",
      "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Benachrichtigungen werden einmal pro Abrechnungszyklus gesendet.",
      "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Azure-Plan",
      "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Bitte warten Sie, wir prüfen die Berechtigung für das Upgrade",
      "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"Das Abonnement ist nicht für ein Upgrade berechtigt",
      "AZURE_UPGRADE_WAIT_MESSAGE":"Bitte warten Wir führen ein Upgrade durch...",
      "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Azure-Verwendung",
      "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
      "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Verwendungsdetails",
      "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Schätzpreis",
      "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Name",
      "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
      "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} erfolgreich zur Gruppe {{groupname}} hinzugefügt",
      "AZUREGROUPS_SAVE_SUCCESS":"Gruppe {{username}} erfolgreich gespeichert",
      "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Gruppenbenutzer erfolgreich hinzugefügt",
      "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Gruppe erfolgreich gespeichert",
      "BACK_TO_AZURE_ADVISOR":"Zurück zum Azure Advisor",
      "BACK_TO_POLICIES":"Zurück zu Benutzerdefinierte Richtlinien",
      "BACK_TO_PUBLIC_SIGNUP_SHOP":"Zurück zum Shop",
      "BACK_TO_SERVICE_PROVIDER_TENANT":"Zurück zu Provider-Mietern",
      "BACKGROUND_DROPDOWN_TEMPLATE_1":"Vorlage 1",
      "BACKGROUND_DROPDOWN_TEMPLATE_10":"Vorlage 10",
      "BACKGROUND_DROPDOWN_TEMPLATE_2":"Vorlage 2",
      "BACKGROUND_DROPDOWN_TEMPLATE_3":"Vorlage 3",
      "BACKGROUND_DROPDOWN_TEMPLATE_4":"Vorlage 4",
      "BACKGROUND_DROPDOWN_TEMPLATE_5":"Vorlage 5",
      "BACKGROUND_DROPDOWN_TEMPLATE_6":"Vorlage 6",
      "BACKGROUND_DROPDOWN_TEMPLATE_7":"Vorlage 7",
      "BACKGROUND_DROPDOWN_TEMPLATE_8":"Vorlage 8",
      "BACKGROUND_DROPDOWN_TEMPLATE_9":"Vorlage 9",
      "BAD_INPUT_INVALID_PRODUCTID":"Ungültige Produkt-ID",
      "BAD_INPUT_INVALID_SEATS":"Ungültige Plätze",
      "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entitäten, die an der Bannerbenachrichtigung beteiligt sind",
      "BILL_ACTION_DESC_FULL_CHARGE":"Volle Ladung für den ganzen Monat.",
      "BILL_ACTION_DESC_NO_REFUND":"Es wird keine Rückerstattung gewährt",
      "BILL_ACTION_DESC_PRORATE":"Proration wird in Rechnung gestellt.",
      "BILL_ACTION_NAME_FULL_CHARGE":"Volle Ladung",
      "BILL_ACTION_NAME_NO_REFUND":"Keine Rückerstattung",
      "BILL_ACTION_NAME_PRORATE":"Prorate",
      "BILL_CYCLE_DESC_ANNUAL":"Jährlich",
      "BILL_CYCLE_DESC_MONTHLY":"Monatlich",
      "BILL_CYCLE_DESC_ONETIME":"Einmal",
      "BILL_CYCLE_DESC_TRIENNIAL":"Alle drei Jahre",
      "BILL_CYCLE_NAME_ANNUAL":"Jährlich",
      "BILL_CYCLE_NAME_MONTHLY":"Monatlich",
      "BILL_CYCLE_NAME_TRIENNIAL":"Alle drei Jahre",
      "BILL_FROM":"Bill From",
      "BILL_TO":"Gesetzesentwurf für",
      "Billing":"Abrechnung",
      "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"Entfernung des Rechnungskundenlinks fehlgeschlagen.",
      "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"Rechnungskundenlink erfolgreich entfernt.",
      "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Eine Änderung des Abrechnungszyklus des Produkts ist nicht zulässig, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung durchgeführt wird. Bitte versuch es später.",
      "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Aktueller Produktname",
      "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Abrechnungszyklus der Bestellung erfolgreich geändert",
      "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Wählen",
      "BILLING_CYCLE_TOOLTIP_BUNDLE":"Rechnungskreislauf",
      "BILLING_FORECAST_REPORT":"Abrechnungsprognose",
      "BILLING_PERIOD":"Abrechnungszeitraum",
      "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Wählen",
      "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Wählen Sie mindestens einen Abrechnungszeitraum aus",
      "BILLING_TYPE_DESC_MARK_UP":"Markup",
      "BILLING_TYPE_DESC_METERED_BILLING":"Gemessene Abrechnung",
      "BILLING_TYPE_DESC_PERCENTAGE":"Prozentsatz",
      "BILLING_TYPE_DESC_PRICE":"Preis",
      "BILLING_TYPE_DESC_UNIT":"Einheit",
      "BILLING_TYPE_TOOLTIP":"Abrechnungsart",
      "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Azure-Schätzungen",
      "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Azure-Abonnement",
      "BREADCRUM_BUTTON_TEXT_LICENSES":"Lizenzen",
      "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Azure-Verwendung",
      "BREADCRUMB_HEADER_TEST_PAYMENT":"Testzahlung",
      "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
      "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Details zum Zahlungsstatus",
      "BTN_AZURE_BILLING_COMPARISON_REPORT":"Azure-Abrechnungsvergleichsbericht",
      "BTN_BILLING_FORECAST_REPORT_LOADING":"Rechnungsprognose...",
      "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Aufzeichnung löschen",
      "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Rechnungs-ZIP herunterladen",
      "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Rechnungsdetails anzeigen",
      "BTN_INVOICE_LINE_ITEMS":"Rechnungsposten",
      "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Rechnungsposten mit Steuern...",
      "BTN_INVOICE_PAYMENTS":"Rechnungszahlungen",
      "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Zurück zu den Zahlungen",
      "BTN_QUOTE_COPY_URL":"URL kopieren",
      "BTN_QUOTE_PUBLISH":"Zitat veröffentlichen",
      "BTN_QUOTE_REPUBLISH":"Zitat erneut veröffentlichen",
      "BTN_QUOTE_SAVE_DRAFT":"Entwurf speichern",
      "BTN_TEXT_ADD_TO_CART":"in den Warenkorb legen",
      "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Zurück zur Geschichte",
      "BTN_TEXT_BEGIN_UPLOAD":"Hochladen",
      "BTN_TEXT_BEGIN_VALIDATE_DATA":"Bestätigen",
      "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Protokolle anzeigen",
      "BTN_TEXT_CANCEL_ORDER":"Bestellung stornieren",
      "BTN_TEXT_COMMENT":"Kommentar",
      "BTN_TEXT_COPY_TO_CLIPBOARD":"In die Zwischenablage kopieren",
      "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Laden Sie alle Gutscheine herunter",
      "BTN_TEXT_FILTER":"Filter",
      "BTN_TEXT_FIX":"Fix",
      "BTN_TEXT_IGNORE":"Ignorieren",
      "BTN_TEXT_INCLUDE":"Einschließen",
      "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Kartenförderung",
      "BTN_TEXT_MAPPING_OF_PRODUCTS":"Produktzuordnung",
      "BTN_TEXT_OPEN_IN_EMAIL":"In E-Mail öffnen",
      "BTN_TEXT_PROCEED":"Vorgehen",
      "BTN_TEXT_QUICK_VIEW":"Schnellansicht",
      "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Mehrere Zahlungen",
      "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Einmalzahlung",
      "BTN_TEXT_REMOVE":"Entfernen",
      "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Werbung entfernen",
      "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Fordern Sie eine Reseller-Beziehung an",
      "BTN_TEXT_SHOW_FILTERS":"Filter anzeigen",
      "BTN_TEXT_SIGN_IN":"Einloggen",
      "BTN_TEXT_SKIP":"Überspringen",
      "BTN_TEXT_TAKE_ON":"Einschließen",
      "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Autotask-Uploads verwalten",
      "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Connectwise-Uploads verwalten",
      "BTN_TEXT_VIEW_PRODUCT":"Produkt anzeigen",
      "BULK_ADD_ENTITLEMENT_BATCH_ID":"Batch-ID",
      "BULK_ADD_ENTITLEMENT_DESCRIPTION":"Zuletzt erstellter Berechtigungsname ist",
      "BULK_ADD_TENANT_DESCRIPTION":"Name des zuletzt erstellten Mieters ist",
      "BULK_ADD_TENANTS_BATCH_ID":"Batch-ID",
      "BULK_DATA_MAPPING_CSV_UPLOAD":"Kartiertes Blatt hochladen",
      "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
      "BULK_INVOICE_DOWNLOAD_CANCEL":"Stornieren",
      "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Wählen Sie mindestens einen Kunden aus",
      "BULK_INVOICE_DOWNLOAD_HEADER":"E-Mail alle Rechnungen",
      "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-Mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Erhalten Sie alle Rechnungen per E-Mail",
      "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie als E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf Senden, um eine ZIP-Datei mit einer Liste von Rechnungen im PDF-Format für den ausgewählten Abrechnungszeitraum zu erhalten",
      "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"Wir benachrichtigen die bereitgestellten E-Mails, sobald die ZIP-Datei zum Download bereit ist! Es dauert normalerweise eine Stunde, bis es fertig ist",
      "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Plan auswählen",
      "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Schließen",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"Sammelrechnung wurde erfolgreich gelöscht.",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Inhaber",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Status",
      "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Benutzer",
      "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Plan auswählen",
      "BULK_INVOICE_DOWNLOAD_SUBMIT":"einreichen",
      "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Möchten Sie diesen Datensatz wirklich löschen?",
      "BULK_INVOICE_PROGRESS_POPUP_HEADER":"Download-Status der Rechnung",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Aktionen",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Abgeschlossen um",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-Mails",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Initiator",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Link bis heute verfügbar",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Anfangsdatum",
      "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Status",
      "BULK_ONBOARD_BATCH_ID":"Bulk Onboard BatchId",
      "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"Bulk Onboard BatchId",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Kartierungsdetails herunterladen",
      "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Abonnementzuordnung herunterladen",
      "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"<b>{{ TotalSubscriptionsOnboarded }}</b> von <b>{{ TotalSubscriptionsAtProvider }}</b> Abonnements vom Partner Center erfolgreich zugeordnet.",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Kunden entfernen?",
      "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Kunden auswählen?",
      "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":"Ausgewählte <b>{{s selectedCustomers}}</b> von <b>{{totalCustomers}}</b> Kunden für das Onboarding",
      "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Integrieren Sie bestehende Wiederverkäufer",
      "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Bitte geben Sie eine gültige E-Mail-Adresse ein",
      "BULK_ONBOARD_REQUEST_ACCEPTED":"Enqueued Request for Bulk Onboard von Kunden",
      "BULK_ONBOARD_RESELLERS_BATCH_ID":"Batch-ID",
      "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"Der Stapel wird erfolgreich abgeschlossen. Sie werden zu Wiederverkäufern weitergeleitet.",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Aus Liste auswählen",
      "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"CSV-Datei hochladen",
      "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Bitte wählen Sie mindestens 1 Reseller für das Onboarding aus.",
      "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Zusammenfassung der Onboard-Ergebnisse",
      "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Insgesamt ausgewählt: {{totalResellersInBatch}}, Insgesamt integriert: {{resellersOnboarded}}, Insgesamt fehlgeschlagen: {{resellersFailedToOnboard}}",
      "BULK_ONBOARD_RESELLERS_LBL_OPTION":"Wie möchten Sie einsteigen?",
      "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Reseller entfernen?",
      "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Wiederverkäufer auswählen?",
      "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"Die Anfrage für Bulk Onboard Reseller befindet sich in der Warteschlange.<br /> Sie werden zum Statusbildschirm weitergeleitet, der den Fortschritt anzeigt.",
      "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"Ausgewählte <b>{{ausgewählteReseller}}</b> von <b>{{totalReseller}}</b> Resellern für das Onboarding",
      "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"Fehler",
      "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":"MPN-ID -",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"ResellerFehlgeschlagen",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"WiederverkäuferErfolgreich",
      "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"TotalResellerSelected",
      "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Geben Sie eine gültige E-Mail-Adresse ein",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Anscheinend fehlen die MPN-ID-Details für den Reseller. Bitte wenden Sie sich an den Partner, um die Details zu aktualisieren.",
      "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"Onboarding wird nicht unterstützt !",
      "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"Bulk-Onboarding MicrosoftNonCSP-Kunden werden nicht unterstützt.",
      "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
      "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Bulk-Onboard-Wiederverkäufer",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Anbieter",
      "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
      "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Status",
      "BULK_ONBOARDING_STATUS_INFO_HEADER":"Spitze! Schritte zum Beheben des teilweisen Onboardings",
      "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Wenn das System während des Onboardings eines Kunden Probleme mit der Abonnementzuordnung meldet, klicken Sie auf den Hyperlink, um die Details herunterzuladen und zu überprüfen. Fixieren Sie Ihre Pläne und arbeiten Sie mit dem Support zusammen, um die Daten in C3 zu synchronisieren.",
      "BULK_PRODUCT_MAPPING":"Massenproduktzuordnung",
      "BULK_PURCHASED_PRODUCT_MAPPING":"Produktzuordnung für Massenkäufe",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Laden Sie den Katalog herunter, um Kunden und deren IDs zu erhalten.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Geben Sie den Währungscode, den Zahlungsbetrag und das Zahlungsdatum für die entsprechende C3Id ein.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Stellen Sie sicher, dass die Daten den folgenden Regeln entsprechen:",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> C3-Kunden-ID (oder die C3-Wiederverkäufer-ID) aus dem Katalog, für den der Kunde die Zahlung erfasst werden möchte",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode – Text (ISO-Standard-Währungscode) –</strong> In welcher Währung die Zahlung erfasst werden soll",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount – Dezimal –</strong> Zu erfassender Betrag",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Bemerkungen – Text –</strong> Kommentare (optional)",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Datum (JJJJ-MM-TT) –</strong> An welchem Datum muss die Zahlung verbucht werden! Nur vergangene Termine!",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Klicken Sie auf <strong>Auswählen</strong> und navigieren Sie zur Datei.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Klicken Sie auf <strong>Katalog validieren</strong>, damit das System die Eingabe validiert.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
      "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"Wenn in der Eingabe keine Validierungsfehler vorliegen, wird die Schaltfläche <strong>Zahlungen importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
      "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Getan",
      "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Ausgewählte Kunden",
      "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Wählen Sie mindestens einen Kunden/einen Plan",
      "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Möchten Sie den Kunden entfernen?",
      "BULK_UPLOAD_CUSTOMER_SUCCESS":"Die Anfrage, mehrere Kunden hinzuzufügen, wird angenommen",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Erfolg",
      "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Laden Sie eine Z.B-CSV herunter",
      "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Laden Sie den Katalog herunter, um die neuesten Codes und Sequenzen zu erhalten.",
      "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"Datei als Referenz.",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Erfolg",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Anleitung",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Füllen Sie den Code oder die Sequenz oder beides für die gewünschten Produkte aus und entfernen Sie die Zeilen, die Sie nicht ändern möchten. Der Produktcode ist optional und unterstützt 100 Zeichen. Die Anzeigesequenz ist eine Zahl und optional.<br /><strong>Hinweis:</strong> Wenn Sie die beiden leer lassen, werden der vorhandene Code und die Sequenz gelöscht.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Klicken Sie auf <strong class=\"text-uppercase\">Katalog validieren</strong>, damit das System die Eingabe validiert.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
      "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"Import abgeschlossen!",
      "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"Validierung abgeschlossen!",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Katalog importieren",
      "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Katalog validieren",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Erfolg",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie alle Kunden aus",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Abteilung auswählen",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Laden Sie den Katalog herunter, um die neuesten Produktprovisionsdetails zu erhalten.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Erfolg",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Füllen Sie den Namen des Agenten, den SPCode, den Provisionsprozentsatz, das Startdatum der Provision und das Enddatum der Provision für die gewünschten Produkte aus und entfernen Sie die Zeilen, die Sie nicht ändern möchten. Alle diese Spalten sind optional. <br /> Agentenname ist ein Text mit 100 Zeichen. <br />SPCode ist ein Text mit 100 Zeichen. <br />Provisionsprozentsatz ist eine Zahl, die auch Dezimalzahlen unterstützt. <br />Das Startdatum der Provision ist ein Datum, das im Format <strong>{{dateFormat}}</strong> definiert werden muss.<br />Das Enddatum der Provision ist ein Datum, das im Format <strong>{ definiert werden muss. {dateFormat}}</strong>-Format.<br /><strong>Hinweis:</strong> Wenn Sie die vorhandenen Werte auf leer aktualisieren, werden die vorhandenen Werte gelöscht, sobald der Katalog importiert wird.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Klicken Sie auf <strong class=\"text-uppercase\">Katalog validieren</strong>, damit das System die Eingabe validiert.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie Standort aus",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Error",
      "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Erfolg",
      "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"Möchten Sie den Reseller entfernen?",
      "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Die folgenden Abonnements enthalten keine entsprechenden Angebote im Plan. Bitte aktualisieren Sie den Plan und versuchen Sie es erneut.",
      "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"In CSP konnte kein Kunde mit der ID {{CSPCustomerId}} gefunden werden.",
      "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Die folgenden Angebote wurden mehrmals in einem Level gekauft, was in C3 nicht unterstützt wird. Bitte überprüfen Sie die Daten.",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Entfernen",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
      "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Ihr letzter Update-Vorgang scheint einige Fehler zu haben. Wenden Sie sich an das Support-Team, um Unterstützung zu erhalten",
      "BUNDLE_PRODUCTS_PLACEHOLDER":"Produkte bündeln",
      "BUNDLE_REPORT_FILE_TYPES_HEADER":"Bericht zu Produktdetails im Bundle",
      "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit zum Abrufen des Bundle-Produktdetailberichts aus",
      "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Geben Sie bitte die E-Mail-Adressen der Empfänger ein, getrennt durch Kommas (,) oder Semikolon (;), und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste mit den Bundle-Produktdetails zu erhalten.",
      "BUNDLE_SAVE_SUCCESS":"Bundle {{customoffer}} erfolgreich gespeichert",
      "BUNDLE_UPDATE_SUCCESS":"Bundle {{customoffer}} erfolgreich aktualisiert",
      "bundles":"Bündel",
      "BUNDLES_BILLING_CYCLE_TOOLTIP":"Abrechnungshäufigkeit, mit der das Paket abgerechnet wird",
      "BUNDLES_BILLING_TYPE_TOOLTIP":"Abrechnungskategorie, auf der das Paket abgerechnet wird",
      "BUNDLES_CANNOT_BE_DELETED":"Bundles können nicht gelöscht werden, da sie einem Plan zugewiesen sind",
      "BUNDLES_SALETYPE_TOOLTIP":"Kategorietyp für das Angebot",
      "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"Der Import wird ausgeführt",
      "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Bitte warten...",
      "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"Die Validierung ist in Bearbeitung",
      "BUTTON_ACTIVATE":"aktivieren Sie",
      "BUTTON_APPLY_COUPON_TEXT":"Gutschein einlösen",
      "BUTTON_CLOSE":"Schließen",
      "BUTTON_DEACTIVATE":"Deaktivieren",
      "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Stornieren",
      "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Herunterladen",
      "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Aktualisierung",
      "BUTTON_LANG_CN":"Chinesisch",
      "BUTTON_LANG_DE":"Deutsch",
      "BUTTON_LANG_EN":"Englisch",
      "BUTTON_LANG_ES":"Spanisch",
      "BUTTON_LANG_FR":"Französisch",
      "BUTTON_LANG_IT":"Italienisch",
      "BUTTON_LANG_SK":"slowakisch",
      "BUTTON_LANG_TH":"Thai",
      "BUTTON_LANG_TR":"Türkisch",
      "BUTTON_LANG_TW":"Taiwanese",
      "BUTTON_LANGUAGE":"Sprache",
      "BUTTON_MANAGE_PRODUCT":"Verwalten",
      "BUTTON_ONHOLD":"In Wartestellung",
      "BUTTON_PROCESSING":"wird bearbeitet",
      "BUTTON_RELEASE":"Freisetzung",
      "BUTTON_REVOKE":"Widerrufen",
      "BUTTON_START_TOUR":"Machen Sie eine Tour",
      "BUTTON_TEXT_ACCEPT":"Akzeptieren",
      "BUTTON_TEXT_ACTIONS":"Aktionen",
      "BUTTON_TEXT_ADD":"Hinzufügen",
      "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Neue Währungsumrechnung hinzufügen",
      "BUTTON_TEXT_ADD_CUSTOM_POLICY":"Benutzerdefinierte Richtlinie hinzufügen",
      "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Neue Ereignis-E-Mail-Benachrichtigung hinzufügen",
      "BUTTON_TEXT_ADD_SUB_TAX":"Substeuer hinzufügen",
      "BUTTON_TEXT_ADD_WITH_ADDONS":"Mit Add-Ons hinzufügen",
      "BUTTON_TEXT_APPLY":"Anwenden",
      "BUTTON_TEXT_APPROVE":"Genehmigen",
      "BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
      "BUTTON_TEXT_CANCEL":"Stornieren",
      "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Überprüfen Sie alle Status",
      "BUTTON_TEXT_CLEAR":"klar",
      "BUTTON_TEXT_CLOSE":"Schließen",
      "BUTTON_TEXT_DELETE":"Löschen",
      "BUTTON_TEXT_DOWNLOAD":"Herunterladen",
      "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Account Manager herunterladen",
      "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Katalog herunterladen",
      "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Kunden herunterladen",
      "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Rechnungen nach Protokollzusammenfassung herunterladen",
      "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Laden Sie den Rechnungsposten herunter",
      "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Rechnungsgebühren herunterladen",
      "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Partnerangebote herunterladen",
      "BUTTON_TEXT_DOWNLOAD_PLAN":"Plan herunterladen",
      "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Planangebot herunterladen",
      "BUTTON_TEXT_DOWNLOAD_REPORT":"Bericht herunterladen",
      "BUTTON_TEXT_EDIT":"Bearbeiten",
      "BUTTON_TEXT_EXPORT_TO_EXCEL":"Nach Excel exportieren",
      "BUTTON_TEXT_FILTER":"Filter",
      "BUTTON_TEXT_LOAD_OFFERS":"Angebote laden",
      "BUTTON_TEXT_OK":"OK",
      "BUTTON_TEXT_OK_CLOSE":"Ok, nah dran",
      "BUTTON_TEXT_OVERRIDE":"Überschreiben",
      "BUTTON_TEXT_PAY_INVOICES":"Ausstehende Rechnungen bezahlen",
      "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Fahren Sie mit dem Kauf fort",
      "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Vorauszahlung aufzeichnen",
      "BUTTON_TEXT_REJECT":"Ablehnen",
      "BUTTON_TEXT_REMOVE":"Entfernen",
      "BUTTON_TEXT_REMOVE_SUB_TAX":"Substeuer entfernen",
      "BUTTON_TEXT_REPORTS":"Berichte",
      "BUTTON_TEXT_RESET":"Zurücksetzen",
      "BUTTON_TEXT_REVERT":"Zurückkehren",
      "BUTTON_TEXT_SAVE":"speichern",
      "BUTTON_TEXT_SEARCH":"Suche",
      "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Betriebseinheit festlegen",
      "BUTTON_TEXT_SUBMIT":"einreichen",
      "BUTTON_TEXT_UPDATE":"Aktualisieren",
      "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"HOCHLADEN AUF QUICKBOOKS ONLINE",
      "BUTTON_TEXT_UPLOAD_TO_XERO":"HOCHLADEN AUF XERO",
      "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Eltern ansehen",
      "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"POST-LOGS ANZEIGEN",
      "BUTTON_TEXT_VIEW_OFFERS":"Angebote anzeigen",
      "BUTTON_VIEW_ERRORS":"Fehler anzeigen",
      "BUTTON_VIEW_PRODUCT":"Aussicht",
      "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Tag hinzufügen",
      "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Tag bearbeiten",
      "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"für",
      "C3_CART":"Wagen",
      "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Anbieterkategorie",
      "C3_TEXT_ONHOLD_STATUS":"In Wartestellung",
      "c3supportAzuremargin":"Azurblauer Rand",
      "CALENDAR_CLEAR_BUTTON":"klar",
      "CALENDAR_CLOSE_BUTTON":"Schließen",
      "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Bitte geben Sie einen Grund für die Stornierung der Bestellung ein",
      "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Für die Stornierung der geplanten Bestellung ist ein Grund erforderlich",
      "CANCEL_SCHEDULED_ORDER_SUCCESS":"Bestellung wurde erfolgreich storniert",
      "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"Bestellung stornieren",
      "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Geplante Bestellung stornieren",
      "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Für die Stornierung des geplanten Produkts ist ein Grund erforderlich",
      "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Für die Stornierung der Bestellung ist ein Grund erforderlich.",
      "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"Das Produkt wurde erfolgreich aus dieser Bestellung storniert",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Die geplante Verlängerung wurde aufgrund einer Änderung des Status der automatischen Verlängerung abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Geplante Verlängerung wegen Mengenaktualisierung abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Die geplante Verlängerung wurde aufgrund der Ablehnung des Verlängerungsantrags abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Die geplante Verlängerung wurde abgebrochen, da die Zeitplaneigenschaft vom Partnercenter geändert wurde",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Die geplante Verlängerung wurde aufgrund einer Änderung des Abonnementstatus abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Geplante Verlängerung aufgrund einer Abonnementaktualisierung abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Geplante Verlängerung aufgrund eines Abonnement-Upgrades abgesagt",
      "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"Die geplante Verlängerung konnte nicht abgebrochen werden",
      "CANCEL_SCHEDULED_RENEWAL_REASON":"Grund",
      "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Bitte geben Sie einen Grund an",
      "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Dieses Feld wird benötigt",
      "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Die geplante Verlängerung wurde erfolgreich abgebrochen",
      "CANCELLED_REASON":"Grund für den Abbruch",
      "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Eine ältere Währungsumrechnungsdefinition kann nicht gelöscht werden",
      "CANNOT_INITIATE_PAYMENT":"Eine Zahlung kann aufgrund einer ausstehenden Transaktion nicht initiiert werden",
      "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Dieses Produkt wurde bereits vom Kunden gekauft. Eine Verlinkung des Produkts ist nicht möglich.",
      "CanPriceLag":"KannPreisVerzögerung",
      "CanPriceLead":"KannPreisBlei",
      "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Anfrage für Reseller-Beziehung",
      "CARD_CARD_DELETE_ADDON":"Löschen",
      "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Austauschzentrum",
      "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Produktnutzungsdaten",
      "CART_CARD_COMMENT_ACTION":"Bemerkungen",
      "CART_CARD_DELETE_PRODUCT":"Löschen",
      "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Dieses Produkt ist bereits in Ihrem Warenkorb verfügbar. Möchten Sie trotzdem fortfahren?",
      "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Ihr Mandant ist nicht berechtigt, den Azure-Plan zu erwerben. Mögliche Gründe: Entweder haben Sie bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert, oder im Partner Center ist ein aktives Azure-Abonnement vorhanden, das aktualisiert werden muss. Bitte kontaktieren Sie den Support für weitere Unterstützung.",
      "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Möchten Sie den Mieter für das ausgewählte Angebot wirklich ändern?",
      "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Benutzerdefiniertes Enddatum erfolgreich gespeichert",
      "CART_DISABLE_PROMOTION":"Deaktivieren Sie die Werbung für das Produkt.",
      "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Beim Aktualisieren des Mandanten für das ausgewählte Angebot ist ein Fehler aufgetreten. Bitte wenden Sie sich an das Support-Team",
      "CART_GENERIC_ERROR_MESSAGE":"Beim Abschließen der Bestellung ist etwas schief gelaufen. Bitte wenden Sie sich an den Partner, bevor Sie es erneut versuchen",
      "CART_ITEM_EFFECTIVE_PRICE":"Effektiver Preis",
      "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Bestellung für Standort/Abteilung",
      "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Mieter",
      "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Warenkorb nicht zum Auschecken verfügbar oder bereits ausgecheckt!",
      "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"Sie können diesen Warenkorb nicht planen, da er entweder nicht verfügbar oder bereits geplant ist",
      "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Verknüpftes Produkt",
      "CART_PAGE_AUTORELEASE":"Automatische Freigabe",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":"{{discount}} % Rabatt",
      "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Rabatt",
      "CART_PAGE_BUTTON_TEXT_REMOVE":"Entfernen",
      "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Anwenden",
      "CART_PAGE_DISCOUNT_INFORMATION":"({{discount}}% reduziert)",
      "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Verbrauchsarten",
      "CART_PAGE_TEMPLATE_ADD_COMMENT":"Einen Kommentar hinzufügen",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong> Hallo! </strong> Ihr Warenkorb ist leer. <a ng-click='vm.GotoCart()' class='alert-link'> Klicken Sie hier, um einzukaufen. <span class='fa fa-cart-plus'></span></a>",
      "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>Hallo!</span> Ihr Warenkorb ist leer.",
      "CART_PAGE_TITLE_PRICE":"Preis",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Benutzerdefiniertes Enddatum",
      "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Wählen Sie einen benutzerdefinierten Enddatumstyp aus",
      "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Wählen..",
      "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Wählen..",
      "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Auto-Release erfolgreich aktualisiert",
      "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Beim Auschecken des Warenkorbs ist ein Fehler aufgetreten",
      "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Bestellung erfolgreich aufgegeben. OrderId: {{orderId}}",
      "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Bei der Verarbeitung Ihrer Anfrage zur Umwandlung des Testangebots für {{productName}} in ein Abonnement ist ein Fehler aufgetreten",
      "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Die Anfrage zur Umwandlung des Testangebots für {{productName}} in ein Abonnement wurde erfolgreich gesendet",
      "CART_PRODUCT_DELETE_CONFIRMATION":"Möchten Sie diesen Artikel wirklich aus dem Warenkorb löschen?",
      "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Beim Entfernen des Artikels aus dem Warenkorb ist ein Fehler aufgetreten!",
      "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"Das angeforderte Element wurde erfolgreich entfernt!",
      "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Beim Überprüfen der Aktionsberechtigung ist ein Fehler aufgetreten",
      "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"Die verfügbare Aktions-ID ist für die Aktion gültig.",
      "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Menge erfolgreich aktualisiert",
      "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Beim Planen der Bestellung ist ein Fehler aufgetreten",
      "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"Die Bestellung wurde erfolgreich geplant.<br/>OrderId ist: {{orderId}}",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Dieses Produkt wurde bereits vor {{days}} Tagen gekauft. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Dieses Produkt wurde bereits vor {{hours}} Stunden gekauft. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Dieses Produkt wurde bereits vor {{minutes}} Minuten gekauft. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Dieses Produkt wurde bereits vor {{days}} Tagen bestellt. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Dieses Produkt wurde bereits vor {{hours}} Stunden bestellt. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Dieses Produkt wurde bereits vor {{minutes}} Minuten bestellt. Möchten Sie trotzdem fortfahren?",
      "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Dieses Testangebot ist bereits gekauft.",
      "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"Für dieses Produkt liegt eine geplante Bestellung vor. Möchten Sie trotzdem fortfahren?",
      "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Der Mieter wurde erfolgreich gegen das ausgewählte Angebot aktualisiert!",
      "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Beim Aktualisieren des Sofortzahlungsfelds im Warenkorb ist ein Fehler aufgetreten",
      "CATEGORY_DESC_ALL":"Alles",
      "CATEGORY_DESC_AZURE":"Azurblau",
      "CATEGORY_DESC_AZURE_PLAN":"Azure-Plan",
      "CATEGORY_DESC_BUNDLES":"Bündel",
      "CATEGORY_DESC_CUSTOM":"BENUTZERDEFINIERT",
      "CATEGORY_DESC_CUSTOM_TRIAL":"Benutzerdefinierte Testversion",
      "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Händlerangebote",
      "CATEGORY_DESC_LICENSE_SUPPORTED":"Lizenz unterstützt",
      "CATEGORY_DESC_NCE":"NCE",
      "CATEGORY_DESC_NON_CSP_AZURE":"Nicht-CSP-Azure",
      "CATEGORY_DESC_ONE_TIME":"Einmal",
      "CATEGORY_DESC_ONLINCE_SERVICES":"Online Dienste",
      "CATEGORY_DESC_ONLINE_SERVICES_NCE":"OnlineServicesNCE",
      "CATEGORY_DESC_PERPETUAL_SOFTWARE":"Unbefristete Software",
      "CATEGORY_DESC_RESERVED_INSTANCES":"RI",
      "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Software-Abonnements",
      "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Lizenz unterstützt",
      "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Dauerhafte Software",
      "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Reservierte Instanz",
      "CATEGORY_FILTER_DESC_NCE":"NCE",
      "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Gutschein deaktivieren?",
      "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Gutschein aktivieren?",
      "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Quellenplan",
      "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Zielplan",
      "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":"Heben Sie die Zuweisung von Plänen auf, nachdem Sie zu einem neuen Plan gewechselt haben",
      "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Beim Ändern des Plans ist ein Fehler aufgetreten.",
      "CHANGE_PLAN_HELP":"Hilfe",
      "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} ist nicht dem richtigen untergeordneten Produkt {{secondProduct}} zugeordnet",
      "CHANGE_PLAN_INSTRUCTIONS_1":"Befolgen Sie diese Anweisungen, um Produkte des Kunden von einem bestehenden Plan in einen neu ausgewählten Plan zu verschieben.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Quellplan </b> – Listet die Pläne auf, die den gekauften Produkten des Kunden zugeordnet sind. Wählen Sie einen oder mehrere Pläne aus, wenn Sie die zugehörigen Produkte verwenden möchten.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Zielplan </b> – Wählen Sie den neuen Preisplan aus.",
      "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Pläne nach dem Wechsel zu einem neuen Plan aufheben </b> – Dies ist praktisch, da das System den Kunden von einem bestehenden Plan trennen würde, wenn im Quellplan keine Produkte mehr vorhanden sind. Hilft, Verwirrung für die Kundenbenutzer auf der Shop-Seite zu beseitigen.",
      "CHANGE_PLAN_INSTRUCTIONS_2":"Die Seite wird aktualisiert, um die gekauften Produkte aus dem Quellplan auf der linken Seite und die Option zur Zuordnung zu einem Äquivalent aus dem Zielplan auf der rechten Seite aufzulisten.",
      "CHANGE_PLAN_INSTRUCTIONS_3":"Ordnen Sie die Produkte zu und senden Sie die Anforderung an das System, die Änderung auszuführen.",
      "CHANGE_PLAN_INSTRUCTIONS_4":"Audit-Protokolle helfen Ihnen mit den Details zur Aktivität, um zu einem späteren Zeitpunkt darauf zurückgreifen zu können.",
      "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Hinweis: Preisänderungen aufgrund von Planänderungen treten sofort in Kraft.</b>",
      "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Anweisungen zum Wechseln zum Plan",
      "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie auch {{secondProduct}} zuordnen",
      "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"Die freigegebene Menge sollte für {{firstProduct}} übereinstimmen",
      "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Wählen Sie die Quellpläne aus, die Sie ändern möchten",
      "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Zielplan auswählen",
      "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"Keine übereinstimmenden Produkte im Zielplan",
      "CHANGE_PLAN_STATUS_FAILED":"Gescheitert",
      "CHANGE_PLAN_STATUS_SUCCESS":"Erfolg",
      "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan erfolgreich geändert.",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Wechseln Sie zu einem Produkt im Zielplan",
      "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"Gekaufte Produkte",
      "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":"Biiling-Zyklus",
      "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Kategorie",
      "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":"Verknüpftes Produkt",
      "CHANGE_PLAN_TABLE_TEXT_OFFER":"Angebot :",
      "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Planen",
      "CHANGE_PLAN_TABLE_TEXT_PRICE":"Preis",
      "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Anbieter",
      "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Menge",
      "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Gültigkeit",
      "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Ausgewählte Pläne haben eine andere Währungskonfiguration.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"Das Planprodukt befindet sich im Bereitstellungsstatus.",
      "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Bitte Produkt auswählen.",
      "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Beim Aktualisieren des Status des gekauften Produkts ist ein Fehler aufgetreten:",
      "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Sind Sie sicher, möchten Sie dieses Angebot einstellen?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Plan deaktivieren?",
      "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Plan aktivieren?",
      "Charge":"Aufladung",
      "charity":"Wohltätigkeit",
      "Charity":"Wohltätigkeit",
      "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Aktivieren Sie alle Werbeaktionen",
      "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Wählen Sie einen Abrechnungszeitraum",
      "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Wählen Sie eine Abrechnungsart",
      "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Wählen Sie eine Verbrauchsart",
      "CHOOSE_AN_OPTION":"Wähle eine Option",
      "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Wählen Sie Kunde",
      "CHOOSE_INVOICE_PDF_LANGUAGE":"Wählen Sie die Sprache der Rechnungs-PDF",
      "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"Das Rechnungs-PDF wird in der ausgewählten Sprache verfügbar sein",
      "CHOOSE_ONE_RG":"Wählen Sie mindestens eine Ressourcengruppe aus",
      "CHOOSE_RESOURCE_GROUP":"Wählen Sie Richtlinie",
      "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"Möchten Sie das Symbol für dieses Produkt wirklich löschen? Das Produkt würde das Symbol nach seiner Kategorie erhalten, wenn es hier gelöscht wird.",
      "CLICK":"Klicken",
      "CLICK_TEXT_FOR_EXTERNAL_LINK":"Klicken",
      "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Währungsumrechnung klonen",
      "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Bitte wählen Sie ein Datum",
      "CLONE_PLAN_FAILURE":"Plan konnte nicht geklont werden",
      "CLONE_PLAN_SUCCESS":"Erfolgreich geklonter Plan",
      "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Nicht jetzt",
      "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Nicht jetzt",
      "COLLAPSE_ALL_PAYMENTS":"Zahlungen reduzieren",
      "COMMENT_AUTHOR":"Autor",
      "COMMENTS_LATEST_COMMENT":"Letzter Kommentar",
      "commercial":"Kommerziell",
      "Commercial":"Kommerziell",
      "COMMISION_REPORT_SEARCH":"Suche",
      "COMMISION_REPORTS":"Kommissionsbericht",
      "Commission Report":"Kommissionsbericht",
      "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Alle Perioden",
      "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie alle Kunden aus",
      "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Abteilung auswählen",
      "COMMISSION_REPORT_HEADER_TEXT":"Kommissionsbericht",
      "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Schließen",
      "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Zurücksetzen",
      "COMMISSION_REPORT_SEARCH_SUBMIT":"Suche",
      "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Standort auswählen",
      "COMMISSION_REPORTS":"Kommissionsbericht",
      "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Kommissionsbericht",
      "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Ergebnisbericht",
      "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Enddatum der Provision",
      "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Startdatum der Provision",
      "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Provision(%)",
      "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Domain Name",
      "COMMISSION_TABLE_HEADER_ENTITY_ID":"Entitäts-ID",
      "COMMISSION_TABLE_HEADER_ORDERED_DATE":"Bestelldatum",
      "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"Produkt-ID planen",
      "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agent",
      "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Provisionsbetrag",
      "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Kunde",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"Produkt-ID des Anbieters",
      "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"Produkt-ID des Anbieters",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"Produktabonnement-ID",
      "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Name des Produktabonnements",
      "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Produktname kaufen",
      "COMMISSION_TABLE_HEADER_RECORD_ID":"Datensatz-ID",
      "COMMISSION_TABLE_HEADER_SP_CODE":"SP-Code",
      "COMMON_LOADING_MESSAGE":"Wird geladen ...",
      "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Sind Sie sicher, möchten Sie diese Rechnung genehmigen?",
      "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Sind Sie sicher, dass Sie diese Rechnung abschließen möchten?",
      "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Du hast nicht gespeicherte Änderungen. Klicken Sie auf OK, um ohne Speichern zu bleiben, oder auf Abbrechen, um auf der Seite zu bleiben",
      "CONFIRMATION_POPUP_MESSAGE":"Möchten Sie dieses Abonnement wirklich aktivieren?",
      "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Möchten Sie die Anfrage zu diesem Abonnement wirklich stornieren?",
      "CONFIRMATION_POPUP_TITLE_ACTIVATE":"aktivieren Sie",
      "CONFIRMATION_POPUP_TITLE_CANCELLED":"Abgesagt",
      "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"Durch Klicken auf „Angebot genehmigen“ werden Einzelposten gekauft. <br/> <b>Hinweis</b>: Das Sitzplatz-/Transaktionslimit findet keine Anwendung.",
      "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Möchten Sie diese Anpassung löschen?",
      "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>Durch Klicken auf „Genehmigen“ wird der Angebotsstatus auf „Genehmigt“ aktualisiert.</p></li><li><p style='text-align:left;'>Der Angebotsinhaber muss den Kauf manuell im Namen des Kunden tätigen.</p></li></ul>",
      "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"Möchten Sie den Abonnementnamen aktualisieren?",
      "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"Möchten Sie die Bestellnummer wirklich aktualisieren?",
      "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"Möchten Sie den Produktnamen wirklich aktualisieren?",
      "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":"Möchten Sie den Abonnementnamen aktualisieren, wenn er von untergeordneten Entitäten in C3 geteilt wird?",
      "CONFIRMATION_TEXT_WANT_TO_CANCEL":"Bist du sicher, dass du abbrechen möchtest?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Wollen Sie fortfahren?",
      "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Möchten Sie mit der aktuellen Auswahl fortfahren?",
      "CONSENT_CREATION_FAILED":"Einwilligungserstellung fehlgeschlagen",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Aktivieren Sie diese Option, um Bearbeitungsgebühren auf Kreditkartenzahlungen anzuwenden.",
      "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"Kreditkartenbearbeitungsgebühr einschließen?",
      "CONSTANT_EMPTY_STRING":"--Auswählen--",
      "CONSTANT_EVERY_DAY":"Jeden Tag",
      "CONSTANT_EVERY_MONTH":"Jeden Monat",
      "CONSTANT_EVERY_TWO_WEEKS":"Alle zwei Wochen",
      "CONSTANT_EVERY_WEEK":"Jede Woche",
      "CONSTANT_FULL_AUTOMATIC_MAPPING":"Automatische Zuordnung",
      "CONSTANT_FULL_MANUAL_MAPPING":"Manuelle Zuordnung",
      "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Manuelles Mapping mit Serviceerstellung",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Menge",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Produkt",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Menge",
      "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Sitz",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Sitze schließen",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Unterschied",
      "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Sitze öffnen",
      "CONSUMPTION_TYPES_DESC_CONTRACT":"Vertrag",
      "CONSUMPTION_TYPES_DESC_QUANTITY":"Menge",
      "CONSUMPTION_TYPES_DESC_SERVICE":"Service",
      "CONSUMPTION_TYPES_DESC_USAGE":"Verwendung",
      "CONTACT_CANCEL":"Möchten Sie stornieren?",
      "CONTACT_HEADER_TEXT":"Eine Frage haben?",
      "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Unsere Kontakte",
      "CONTACT_LABEL_TEXT_EMAIL":"Email",
      "CONTACT_LABEL_TEXT_MESSAGE":"Botschaft",
      "CONTACT_LABEL_TEXT_NAME":"Name",
      "CONTACT_PARAGRAPH_TEXT":"Kontaktieren Sie uns, indem Sie das folgende Formular ausfüllen. Wir werden uns in Kürze bei Ihnen melden.",
      "CONTACT_SENDFEEDBACK_SUCCESS":"Vielen Dank für Ihr Feedback. Unser Team wird sich in Kürze mit Ihnen in Verbindung setzen.",
      "CONTACTCONTROLLER_SENDFEEDBACK":"Ihr Feedback wurde erfolgreich übermittelt",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Hinzufügen",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Löschen",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"speichern",
      "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Wählen Sie eine Abrechnungsart aus",
      "CONTRACT_OFFER_BREADCRUMB":"Vertragsangebot",
      "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Quelle, auf der der Vertrag erstellt würde",
      "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Möchten Sie diese Platte wirklich löschen?",
      "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Nutzungsbereich, für den der Vertrag gelten würde",
      "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Möchten Sie dieses Vertragsangebot wirklich deaktivieren?",
      "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Vertragsdienst aus Autotask gelöscht",
      "COOKIE_CONSENT_MESSAGE":"Die Anwendung verwendet Cookies, um Ihnen eine bessere Erfahrung zu bieten. Durch den Zugriff auf die Anwendung stimmen Sie der Verwendung von Cookies zu.",
      "COOKIE_POLICY_BUTTON_TEXT":"COOKIE-POLITIK",
      "corporate":"Unternehmen",
      "Corporate":"Unternehmen",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Zurücksetzen",
      "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Suche",
      "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Kunden",
      "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Pläne",
      "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Wiederverkäufer",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Wählen Sie Kunden",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Wählen Sie Pläne",
      "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Wählen Sie Reseller",
      "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filter",
      "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Plan Name",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Anbieterpreis",
      "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Menge",
      "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Kosten für den Kunden",
      "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Kosten für den Partner",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Erweiterung",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Bündeln",
      "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Verknüpft",
      "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Kostenübersicht",
      "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"Nicht an Bord",
      "coupon":"Coupon",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"Der Gutschein wird auf alle Kunden angewendet",
      "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"Der Gutschein wird nicht auf Kunden angewendet, obwohl sie mit dem ausgewählten Plan gekennzeichnet sind",
      "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"Der ausgewählte Coupon ist ein direkter Coupon (ohne Plan).",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Kunden können jeweils nur einen direkten Coupon haben.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Bitte beachten Sie, dass ausgewählte Kunden mit bestehenden Gutscheinzuweisungen entfernt werden.",
      "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Direkter Gutschein",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"Der ausgewählte Coupon ist ein Plan-Coupon.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"Kunden, die mit diesem Plan gekennzeichnet sind, können nur mit diesem Coupon zugewiesen werden.",
      "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Gutschein planen",
      "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Couponzuordnung erfolgreich gespeichert",
      "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Coupon",
      "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Kunden",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Aktiv",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inaktiv",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Aktiv",
      "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inaktiv",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Neue Couponzuordnung hinzufügen",
      "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Bearbeiten",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Aktion",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Zugewiesen am",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Gilt bis",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Status",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"Gutscheincode",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Gutscheincode",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Coupon Beschreibung",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Coupon Name",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"Gutscheinname",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Status",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Ist aktiv",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Bitte wählen Sie einen gültigen Gutschein aus",
      "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Der ausgewählte Gutschein steht diesem Kunden zur Verfügung.",
      "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Gutschein hinzufügen",
      "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Zurück zu den Gutscheinen",
      "COUPON_CAPTION_TEXT_ADD":"Gutschein hinzufügen",
      "COUPON_CAPTION_TEXT_EDIT":"Gutschein bearbeiten",
      "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Wählen Sie alle Angebote aus",
      "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Zusammenbruch",
      "COUPON_DETAILS_TOOLTIP_EXPAND":"Erweitern",
      "COUPON_DISABLE_BUTTON":"Deaktivieren Sie den Gutschein",
      "COUPON_DISCOUNT_TOOLTIP":"Rabattprozentsatz, der auf den Coupon angewendet werden soll",
      "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Bitte wählen Sie einen Rabattwert zwischen 1 und 100.",
      "COUPON_EDIT_BUTTON":"Gutschein bearbeiten",
      "COUPON_ENABLE_BUTTON":"Aktivieren Sie den Gutschein",
      "COUPON_HEADER_ACTION":"Aktion",
      "COUPON_OWNERSHIPS_CATEGORY":"Kategorie",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Bewerben Sie sich für alle markierten Kunden",
      "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"Wenn Sie dieses Kontrollkästchen aktivieren, wird der Gutschein allen Kunden zugewiesen, die mit dem ausgewählten Plan gekennzeichnet sind. Sie können die Zuweisungen jedoch im Coupon-Zuweisungsbildschirm verwalten.",
      "COUPON_OWNERSHIPS_COUPON_ENTITY":"Anwendbarkeit",
      "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Aktion verfügbar",
      "COUPON_OWNERSHIPS_PLAN":"Planen",
      "COUPON_OWNERSHIPS_PLAN_OFFERS":"Angebote planen",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Effektiver Preis",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Ist ausgewählt",
      "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Angebotsname",
      "COUPON_OWNERSHIPS_PROVIDER":"Anbieter",
      "COUPON_OWNERSHIPS_SALETYPE":"SaleType",
      "COUPON_PLAN_COUPON_OWNERSHIPS":"Anwendbar auf",
      "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Sie sollten mindestens ein Planprodukt auswählen.",
      "COUPON_RECORDS_VALIDATION_MESSAGE":"Sie sollten mindestens eine {{entity}} auswählen.",
      "COUPON_SAVE_SUCCESS":"Gutschein {{coupon}} erfolgreich gespeichert",
      "COUPON_STATUS_FLAG_ACTIVE":"Aktiv",
      "COUPON_STATUS_FLAG_EXPIRED":"Abgelaufen",
      "COUPON_STATUS_FLAG_INACTIVE":"Inaktiv",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Gutscheincode",
      "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Coupon Name",
      "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Endet am",
      "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Name",
      "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Anwendbar auf neue Kunden",
      "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Anwendbar auf alte Kunden",
      "COUPON_TABLE_HEADER_TEXT_code":"Code",
      "COUPON_TABLE_HEADER_TEXT_CODE":"Code",
      "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Erstellt am",
      "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Rabatt",
      "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Läuft aus am",
      "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Ist aktiv",
      "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"Ist Prozentsatz",
      "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Ist öffentlich",
      "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Maximaler Rabatt",
      "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Max. Einlösungen",
      "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Mindestbetrag",
      "COUPON_TABLE_HEADER_TEXT_NAME":"Name",
      "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Wiederholungen",
      "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Plan Name",
      "COUPON_TABLE_HEADER_TEXT_planname":"Planname",
      "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Gültig bis",
      "COUPON_UPDATED_SUCCESS":"Gutschein {{coupon}} erfolgreich aktualisiert",
      "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"Das ValidTill-Datum sollte größer als das ExpiresOn-Datum sein",
      "couponassignment":"Gutscheinzuordnung",
      "coupondetails":"Gutscheindetails",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Neue Couponzuordnung hinzufügen",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Couponzuweisungen",
      "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Couponzuordnung bearbeiten",
      "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Datum, bis zu dem der Gutschein zum Zeitpunkt des Kaufs angewendet werden kann",
      "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Gutscheinstatus",
      "COUPONS_TAB_HEADING_TEXT_COUPONS":"Gutscheine",
      "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Datum, bis zu dem der Gutschein bei der Abrechnung berücksichtigt wird, nachdem er angewendet wurde",
      "couponstatus":"Gutscheinstatus",
      "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Der Link zum Initiieren der Einwilligungserfahrung wird in Ihre Zwischenablage kopiert",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Klicken Sie auf <b><a ngclipboard data-clipboard-text='{{consentURL}}'ng-click='confirmCopy()'></b>Link in Zwischenablage kopieren </a>, um den Link zu kopieren, der die Zustimmung erfasst vom Partner, um dieser Anwendung nach dem neuen Sicherheitsmodell Zugriff auf das Partner Center zu gewähren.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Einwilligungserfahrung mit einem Konto ab, das ein globaler Administrator in Ihrem (Partner-) Mandanten ist und dem die Partner-Agent-Rolle im Partnercenter zugewiesen ist.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Klicken Sie auf die Schaltfläche Test, um zu überprüfen, ob die Anwendung mit dem neuen Sicherheitsmodell funktioniert.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf <b>Zu neuem Sicherheitsmodell wechseln </b>(aktiviert nach erfolgreichem Test), damit die Anwendung Ihre älteren Anmeldeinformationen löscht und zum neuen Sicherheitsmodell wechselt.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Link in die Zwischenablage kopieren< /b></a></b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":" Um dieser Anwendung Zugriff auf die Azure-Verwaltungs-APIs zu gewähren, wird über diesen Link Ihre Zustimmung als Kundenadministrator erfasst.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Nachdem Sie den Link kopiert haben, öffnen Sie ihn in einer neuen Inkognito-Sitzung. Fahren Sie dann mit der Einwilligung für die Kundenadministratorrolle fort. Dieser Schritt ist für uns von entscheidender Bedeutung, um den Prozess voranzutreiben.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Klicken Sie auf die Schaltfläche „Testen“, um zu prüfen, ob die Anwendung mit den Azure-Verwaltungs-APIs funktioniert",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Anweisungen zum Umschalten auf ein neues Sicherheitsmodell",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Anweisung zur Erteilung der Administratoreinwilligung für die Azure Management API",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Anweisung, die Zustimmung des Kundenadministrators durch den Kundenbenutzer zu erteilen",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Anleitung",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Link in die Zwischenablage kopieren</a> </b>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" um den Link zu kopieren, der die Zustimmung des Kundenadministrators erfasst, dieser Anwendung Zugriff auf Azure-Verwaltungs-APIs zu gewähren.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Sobald Sie den Link kopiert haben, teilen Sie ihn dem Kunden über seinen bevorzugten Kommunikationskanal mit, beispielsweise per E-Mail. Fordern Sie dann ihre Genehmigung für die Rolle des Kundenadministrators an.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Klicken Sie auf die Schaltfläche „Testen“, um zu prüfen, ob die Anwendung mit den Azure-Verwaltungs-APIs funktioniert.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"ODER Sie können Ihren Kunden auffordern, sich beim C3-Portal anzumelden -> Profil -> Microsoft. Geben Sie im Abschnitt „Erteilen Sie die erforderliche Einwilligung“ ein. Die Einholung der Zustimmung des Kunden ist für unser weiteres Vorgehen von entscheidender Bedeutung.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Hier ist eine Liste der Berichte, die zum geplanten Zeitpunkt vorbereitet und ausgeführt werden können.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Bitte verwenden Sie das Empfängersymbol, um die Empfänger zum Bericht hinzuzufügen.",
      "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"Sie können auch überprüfen, wie oft der Bericht in der Vergangenheit ausgeführt wurde und wer ihn erhalten hat.",
      "CPV_LOADING_MESSAGE":"Bitte warten Sie, während wir Ihre Einwilligung bearbeiten...",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Vielen Dank für Ihre Einwilligung. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf das Partner Center zugreift.",
      "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen unter Einstellungen -> Partnercenter in <b>{{applicationName}} </b>.",
      "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Der Versuch, mit den neuen Sicherheitseinstellungen auf das Partner Center zuzugreifen, ist erfolgreich.",
      "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"Der Versuch, auf die Microsoft Azure-Verwaltungs-API zuzugreifen, war erfolgreich.",
      "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Kartennummer",
      "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
      "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Monat",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"Thursday, April 4, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"Thursday, August 8, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"Thursday, December 12, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"Friday, February 2, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"Monday, January 1, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"Sunday, July 7, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"Thursday, June 6, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"Sunday, March 3, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"Sunday, May 5, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"Monday, November 11, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Okt",
      "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"Monday, September 9, 2024",
      "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Name auf der Karte",
      "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Jahr",
      "CREATE_BANNER_NOTIFICATIONS":"Bannerbenachrichtigung erstellen",
      "CREATE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht erstellt werden",
      "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Gutscheinzuweisung erfolgreich erstellt",
      "CREATE_CUSTOMER_EXCEPTION":"Beim Erstellen des Kunden ist ein Fehler aufgetreten",
      "CREATE_CUSTOMER_USER_ROLE":"Erstellen Sie Kundenbenutzer",
      "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Kundenbenutzer konnten nicht erstellt werden",
      "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Rechnung erstellen",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Provider-Kunde unter Logischer Reseller anlegen",
      "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Erlauben Sie dem logischen Reseller, einen Provider-Kunden zu erstellen",
      "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Die dem Reseller-Tarif hinzugefügten Nutzungsangebote haben den Abrechnungstyp <b>Markup</b>. Bitte definieren Sie das Markup für Nutzungsangebote, sobald der Plan erfolgreich erstellt wurde.",
      "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Account Manager erfolgreich erstellt",
      "CREATED_SUCCESSFULLY":"wird erfolgreich erstellt",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Anscheinend fehlen die MPN-ID-Details für den Reseller. Bitte wenden Sie sich an den Partner, um die Details zu aktualisieren.",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"Das Anlegen eines neuen Service Provider Tenants wird nicht unterstützt !",
      "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"Der Kunde wird im Partnercenter unter dem Partnerkonto angelegt",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Prozentuale Gebühr für die Kreditkartenabwicklung.",
      "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Kreditkartenbearbeitungsgebühr %",
      "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für die kumulierten Steuern ein.",
      "CURRENCY_CONVERSION_ADD_SUCCESS":"Währungsumrechnungsregel erfolgreich hinzugefügt",
      "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Währung klonen",
      "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Zurück zur Währungsumrechnung",
      "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Hinzufügen",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Währungsumrechnung",
      "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"Währungsumrechnung erfolgreich übermittelt",
      "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Bearbeiten",
      "CURRENCY_CONVERSION_DELETE_SUCCESS":"Währungsumrechnungsregel erfolgreich gelöscht",
      "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Aktueller Abrechnungsmonat",
      "CURRENCY_CONVERSION_LABEL_DEFAULT":"Alles",
      "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Letzter Abrechnungsmonat",
      "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Nächster Abrechnungsmonat",
      "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Wählen Sie ein Datum aus",
      "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Wechselkurs",
      "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Kunde",
      "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Quellwährung",
      "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Zielwährung",
      "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Zeitraum",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Wechselkurs",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Kunde",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"Gültig ab",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Quellwährung",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Status",
      "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Zielwährung",
      "CURRENCY_CONVERSION_RATE_SUCCESS":"Geben Sie einen gültigen Umrechnungskurs ein.",
      "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"Die Währungsumrechnungsregel wurde erfolgreich erstellt.",
      "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Währungsumrechnungsregel erfolgreich aktualisiert",
      "CurrencyCode":"Währungscode",
      "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Aktuelles Symbol",
      "CURRENT_PARTNER_PRODUCT_ICON":"Aktuelles Symbol",
      "CURRENT_SUBSCRIPTION_DETAILS":"Aktuelle Abonnementdetails",
      "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Verwendungsdetails noch nicht verfügbar, bitte versuchen Sie es nach einiger Zeit.",
      "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Menge",
      "Custom Notifications":"Benutzerdefinierte Benachrichtigungen",
      "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Wenn Sie eine Bestellung für ein zukünftiges Datum planen und ein benutzerdefiniertes Enddatum angegeben haben, beachten Sie bitte, dass das benutzerdefinierte Enddatum automatisch auf die Standardeinstellung zurückgesetzt wird, nachdem Sie Ihre geplante Bestellung für die Zukunft ausgewählt haben.",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOM_ICON_HELP_TEXT":"Empfohlene Größe: 150 x 175. Es werden nur Dateien des Typs jpg, svg, png, gif, bmp unterstützt",
      "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"Der Positionsname sollte weniger als 150 Zeichen lang sein.",
      "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Markierte Entitäten hinzufügen",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Nachrichtentext",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"Gültig ab",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Wirksam für",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"Benachrichtigungs-ID",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Schablone",
      "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Titel",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Marketing &amp; Verkaufsprodukt Vertrauen.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Beginnen Sie mit einem modernen Website-Design und passen Sie es mit Ihren Branding-Inhalten und Funktionen an. Alle Premium-Blogs enthalten benutzerdefiniertes CSS.",
      "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Erstellen Sie eine benutzerdefinierte Benachrichtigung",
      "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Erstellen Sie eine benutzerdefinierte Benachrichtigung",
      "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Stornieren",
      "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Filter schließen",
      "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Plan Name",
      "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Beschreibung eingeben",
      "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Wählen Sie Pläne",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Wählen Sie das Datum „Gültig ab“ aus",
      "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Wählen Sie „Gültig bis heute“.",
      "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Endtermin",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Benutzerlizenz zuweisen",
      "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Ändern Sie den Produktstatus des Kunden",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Produktbesitz verwalten",
      "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Benutzerlizenz verwalten",
      "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Kunden an Bord",
      "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Produkte kaufen",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Mengenabnahme",
      "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Mengenerhöhung",
      "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Benutzerlizenz entfernen",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Lizenz aktualisieren",
      "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
      "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Bei benutzerdefinierten Benachrichtigungsereignissen ist ein Fehler aufgetreten",
      "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Aktionen",
      "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Beschreibung",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"Gültig ab",
      "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Wirksam für",
      "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Getaggte Entitäten",
      "CUSTOM_NOTIFICATION_HEADER_TITLE":"Titel",
      "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Beschreibung",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"Gültig ab",
      "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Wirksam für",
      "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Einheit",
      "CUSTOM_NOTIFICATION_LABEL_EVENT":"Veranstaltung",
      "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Schablone",
      "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Titel",
      "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Schließen",
      "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Benutzerdefinierte Benachrichtigung",
      "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
      "CUSTOM_NOTIFICATION_RESET_OPTION":"Zurücksetzen",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"einreichen",
      "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Benutzerdefinierte Benachrichtigung erfolgreich gespeichert",
      "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Wählen",
      "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Suche",
      "CUSTOM_NOTIFICATION_SELECT":"Wählen",
      "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Produkte auswählen",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Verbrauchsarten",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Anbieter",
      "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
      "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Anfangsdatum",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Getaggte Entitäten",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Mit Tags versehene Entitätselemente",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Aktionen",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Elemente",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Einheit",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Veranstaltung",
      "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Markierte Entitäten anzeigen",
      "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Vorlage wählen",
      "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Dies überschreitet die maximale Länge von 30",
      "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Fang hier an",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Melden Sie sich jetzt bei SAP an und erhalten Sie 35 % Rabatt",
      "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Benutzervertrauen",
      "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Titel auswählen",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Aktualisieren",
      "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Benutzerdefinierte Benachrichtigung erfolgreich aktualisiert",
      "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"UI-Benachrichtigung",
      "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"Ist das Probeangebot?",
      "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"Ist automatisch erneuerbar",
      "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Standardmenge",
      "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Produkte zum Testen anbieten",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Kategorie",
      "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Verbrauchsart",
      "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Kategorie",
      "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Anbieter",
      "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Partnerangebote",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Neues Vertragsangebot",
      "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Neues Partnerangebot",
      "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Zurück zu den Partnerangeboten",
      "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Anwendbarkeit",
      "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Hinzufügen",
      "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Vertragsangebote",
      "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Partnerangebote",
      "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Bearbeiten",
      "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"TESTDAUER",
      "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Wählen Sie 'ChargePeriods' als 'Default', 'Billing rule for purchase of seats’ und Billing rule for reduction of seats’ all ‘Prorate’.",
      "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Beachten Sie beim Hinzufügen eines Partnerangebots zu einem zusammengesetzten Angebot die folgenden Punkte",
      "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Genehmigungsmenge",
      "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
      "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Kategorie",
      "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Art des Abrechnungszeitraums",
      "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
      "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
      "CUSTOM_OFFERS_LABEL_TEXT_Description":"Beschreibung",
      "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Futterquelle",
      "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
      "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Anleitung",
      "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"Ist das Angebot ein AddOn?",
      "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Name",
      "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Anzahl der Tage für die kostenlose Stornierung",
      "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"Angebots-Ref.-ID",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
      "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
      "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Symbol",
      "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Eigenschaften",
      "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Anbieter",
      "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Verkaufspreis",
      "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
      "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Gültigkeit",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Kategorie",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Was möchten Sie als Abrechnungszeitraum für die Abonnements",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Welchen Verbrauchstyp sollen die Abonnements haben?",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Futterquelle",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Anzahl der maximal zulässigen Tage ohne Stornierung.",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen sollten anteilig oder voll aufgeladen sein",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Anbieter",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Verkaufsart",
      "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Platte",
      "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validität des Angebots.",
      "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Wählen Sie ein Bild aus",
      "CUSTOM_OFFERS_SAVE_SUCCESS":"Partnerangebot {{customoffer}} erfolgreich gespeichert",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Gelöschte Angebote einbeziehen",
      "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Probeangebote",
      "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Abrechnungszeitraum auswählen",
      "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Laufzeit auswählen",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Selbstkostenpreis",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Angebotsdetails",
      "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Gehen Sie zum Produkttags-Bildschirm",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Aussicht",
      "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(gelöscht)",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Dies ist der Preis, zu dem Sie diese Komponente erhalten haben. Sie können den Verkaufspreis für dieses Angebot angeben, wenn Sie diesen in einen Plan aufnehmen.",
      "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Dies ist der Preis, zu dem Sie diese Komponente verkaufen.",
      "CUSTOM_OFFERS_UPDATE_SUCCESS":"Partnerangebot {{customoffer}} erfolgreich aktualisiert",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Start- und Enddatum der Gebühr beginnen und enden am Ende eines Monats.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Die Start- und Enddaten der Gebühren stimmen mit den Start- und Enddaten der MS-Abrechnung überein.",
      "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Die Start- und Enddaten der Gebühren stimmen mit den Start- und Enddaten der Partnerabrechnung überein.",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Mit Kalendermonat ausrichten",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"An MS Billing ausrichten",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"An Partnerabrechnung ausrichten",
      "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"An der Anbieterabrechnung ausrichten",
      "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Standard (ab Kaufdatum)",
      "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Legen Sie ein neues Passwort fest",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Möchten Sie das Passwort wirklich zurücksetzen?",
      "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Ein neues Passwort wird generiert und das aktuelle Passwort des Benutzers funktioniert nicht mehr.",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Neues Passwort eingeben",
      "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Passwort anzeigen",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Passwort automatisch generieren",
      "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Benutzerdefiniertes Passwort",
      "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Mindestens 8 Zeichen. Muss mindestens ein Großbuchstaben, ein Kleinbuchstaben, ein Sonderzeichen und einen numerischen Wert enthalten",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen.",
      "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Benutzerdefiniertes Passwort sollte nicht den Vornamen des Benutzers enthalten.",
      "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Richtlinie erfolgreich aktiviert",
      "CUSTOM_POLICY_ADD_TITLE":"Benutzerdefinierte Richtlinie hinzufügen",
      "CUSTOM_POLICY_ASSIGNED_POLICIES":"Zugewiesene Richtlinien",
      "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Richtlinie erfolgreich zugewiesen",
      "CUSTOM_POLICY_ASSIGNMENT_ADD":"Richtlinie zuweisen",
      "CUSTOM_POLICY_AZURE_POLICY_ID":"Azure PolicyId",
      "CUSTOM_POLICY_CUSTOMER_NAME":"Kundenname",
      "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Richtlinie erfolgreich deaktiviert",
      "CUSTOM_POLICY_DESCRIPTION":"Beschreibung",
      "CUSTOM_POLICY_INSTRUCTIONS":"Führen Sie die folgenden Schritte aus, um eine benutzerdefinierte Richtlinie zu erstellen",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Geben Sie einen Namen und eine Beschreibung für die benutzerdefinierte Richtlinie ein",
      "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Geben Sie die richtige Definition an",
      "CUSTOM_POLICY_LABEL_SCOPE":"Umfang",
      "CUSTOM_POLICY_NAME":"Name",
      "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Richtlinienzuweisung",
      "CUSTOM_POLICY_POLICYDEFINITION":"Definition",
      "CUSTOM_POLICY_RESOURCEGROUPNAME":"Name der Ressourcengruppe",
      "CUSTOM_POLICY_REVOKE_SUCCESS":"Richtlinie erfolgreich widerrufen",
      "CUSTOM_POLICY_SAVE_SUCCESS":"Richtlinie {{policy}} erfolgreich gespeichert",
      "CUSTOM_POLICY_SUBSCRIPTION":"Abonnementname",
      "CUSTOM_POLICY_TITLE":"Benutzerdefinierte Richtlinien",
      "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Um die Richtlinie der Abonnementstufe zuzuweisen, wählen Sie bitte alle Ressourcengruppen aus.",
      "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rolle erfolgreich aktiviert",
      "CUSTOM_ROLE_AZURE_ROLE_ID":"Azure RoleId",
      "CUSTOM_ROLE_CUSTOMER_NAME":"Kundenname",
      "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rolle erfolgreich deaktiviert",
      "CUSTOM_ROLE_DESCRIPTION":"Beschreibung",
      "CUSTOM_ROLE_INSTRUCTIONS":"Führen Sie die folgenden Schritte aus, um eine benutzerdefinierte Rolle zu erstellen",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Geben Sie einen Namen und eine Beschreibung für die benutzerdefinierte Rolle ein",
      "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Geben Sie nur die Abschnitte \\''Aktion\\'' und \\''Nicht Aktion\\'' für die Rollendefinition ein.",
      "CUSTOM_ROLE_NAME":"Name",
      "CUSTOM_ROLE_RESOURCEGROUPNAME":"Name der Ressourcengruppe",
      "CUSTOM_ROLE_ROLEDEFINITION":"Rollendefinition",
      "CUSTOM_ROLE_SAVE_SUCCESS":"Rolle {{role}} erfolgreich gespeichert",
      "CUSTOM_ROLE_SUBSCRIPTION":"Abonnementname",
      "Customer":"Kunde",
      "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Ist steuerpflichtig",
      "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Warenkorb aktualisieren",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Kategorie",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Preis pro Samen",
      "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Ich/Wir ermächtigen Ezidebit Pty Ltd ACN 096 902 813 (Benutzer-ID Nr. 165969, 303909, 301203, 234040, 234072, 428198), mein/unser Konto bei dem oben genannten Finanzinstitut über das Bulk Electronic Clearing System (BECS) in gemäß dieser Lastschriftanforderung und gemäß dem Ezidebit DDR-Servicevertrag. <br> Ich/Wir genehmige, dass diese Zahlungen in Intervallen und Beträgen gemäß den Bestimmungen der BeCloudSmart-Vereinbarung und nachfolgender Vereinbarungen von BeCloudSmart abgebucht werden.",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Zustimmung",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Ezidebit-Vereinbarung",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Microsoft Cloud Reseller-Vereinbarung",
      "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Nutzungsbedingungen des Partners",
      "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Lesen Sie die Ezidebit-Vereinbarung",
      "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Bitte lesen Sie die",
      "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Zahlung erfolgreich erfasst.",
      "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Der Prozess der Integration der Rechnungsdetails des Kunden ist im Gange",
      "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Fahren Sie mit Onboard fort",
      "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Kundenabrechnungsdetails erfolgreich eingebunden.",
      "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Der Kunde existiert bereits im Abrechnungssystem",
      "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Es wurde eine Übereinstimmung für die ID <strong>{{billingProviderReferenceID}}</strong> im Abrechnungssystem gefunden.<br/><span> Die Abrechnungsadresse in der Datei lautet <strong> {{formattedAddress}} </strong>.</span><br/> Bitte geben Sie sich aus und verwenden Sie die Option <strong>Profil</strong>, um dieses Detail zu ändern, nachdem die Rechnungsdetails des Kunden erfolgreich eingebunden wurden.",
      "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Monatlicher Umsatz...",
      "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Error",
      "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"In Bearbeitung",
      "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Erfolg",
      "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Weiterlesen",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Kundeneinwilligung neu laden",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Gitterbericht wird neu geladen",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Neuladen von Provider-Kundenprofilen",
      "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Neuladen einleiten",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Rechnungsposten",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Rechnungsposten...",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Rechnungsposten mit Provisionen",
      "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Rechnungsposten mit Provisionen...",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Rechnungszusammenfassung",
      "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Rechnungszusammenfassung...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Rentabilitätsbericht",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Rentabilitätsbericht nach Produkt",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Rentabilitätsbericht nach Produkt...",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Rentabilitätsbericht des Wiederverkäufers",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Rentabilitätsbericht des Mieters",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Rentabilitätsbericht des Mieters",
      "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Rentabilitätsbericht...",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Steuerposten",
      "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Steuerposten...",
      "CUSTOMER_BUTTON_TAX_SUMMARY":"Steuerübersicht",
      "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Steuerübersicht...",
      "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Einwilligungsbericht",
      "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Kundenbericht herunterladen",
      "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Abgleichbericht exportieren",
      "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Benutzerlizenz exportieren",
      "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Kundeneinwilligung neu laden",
      "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Anbieter-Kundenprofile neu laden",
      "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Abstimmungsbericht neu laden",
      "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Bericht zu den Allgemeinen Geschäftsbedingungen",
      "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Bericht zum Transaktionsbetragslimit",
      "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Neue Käufe werden über die Plattform deaktiviert. Bitte wenden Sie sich an Ihren Kundenbetreuer, um eine Bestellung aufzugeben.",
      "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Wenden Sie sich an Ihren Account Manager, um ein neues Produkt zu erwerben",
      "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Neukäufe und Änderungen der Lizenzanzahl werden derzeit ausgesetzt. Wenden Sie sich für weitere Informationen bitte an Ihren Account Manager.",
      "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Entfernen",
      "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Kundenwagen",
      "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Mit dem Einkaufen fortfahren",
      "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"Ich bin bereit zu zahlen",
      "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Jetzt bezahlen",
      "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Bestellung aufgeben",
      "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
      "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Überprüfen Sie das benutzerdefinierte Enddatum der Warenkorbpositionen, bevor Sie mit dem Kauf fortfahren.",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Richten Sie das Enddatum am Kalendermonat aus",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Standard",
      "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Enddatum bestehender Abonnements",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Enddatum bestehender Abonnements",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Abonnement-ID",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Gültigkeit",
      "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Aktion angewendet",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Bezahlbarer Betrag",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Währung:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Steuername:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Gesamtkosten",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Komplette Anzahl:",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Totaler Rabatt",
      "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Gesamtsteuer:",
      "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Bestellübersicht",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Im Warenkorb befindet sich bereits ein Vertragsangebot. Bitte entfernen Sie das vorhandene Vertragsangebot, um ein neues hinzuzufügen.",
      "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Sie haben bereits ein Vertragsangebot erworben. Bitte entfernen Sie das bestehende Vertragsangebot, um ein neues zu erwerben.",
      "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Eine Bestellung für dieses Produkt wurde bereits an den Anbieter gesendet. Wenden Sie sich für weitere Informationen bitte an Ihren Account Manager",
      "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Die Anzahl der gekauften Plätze überschreitet das zulässige Limit von {{SeatLimit}}.",
      "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde.",
      "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Wählen Sie einen Standort/eine Abteilung aus",
      "CUSTOMER_CART_SUP_TEXT_ADD_ON":"Erweiterung",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Ihr Mandant ist nicht berechtigt, Azure zu erwerben. Mögliche Gründe: Sie haben bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert.",
      "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Ihr Mandant ist nicht berechtigt, den Azure-Plan zu erwerben. Mögliche Gründe: Entweder haben Sie bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert, oder es ist ein Azure-Abonnement im Partner Center aktiv, und es muss aktualisiert werden, oder Sie haben mehr als einen Azure-Plan-Einkaufswagenposten unter demselben Mandanten.",
      "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Diese Bestellung erfordert die Zustimmung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Der Transaktionsbetrag überschreitet das zulässige Limit von <b> {{TransactionAmountLimit}}</b>.",
      "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
      "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Mehrwertsteuer im Warenkorb anzeigen",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Rechnungsnummern beginnen mit diesem Text",
      "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Rechnungsnummerntext",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Ermöglicht dem Benutzer, unbezahlte Beiträge bis zur aktuellen Rechnung anzuzeigen",
      "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Zeigen Sie unbezahlte Beiträge in der Rechnung an",
      "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
      "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"Möchten Sie der Erneuerungszustimmung für {{ProductName}} wirklich {{Consent}}?",
      "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Ihre Zustimmung wurde erfolgreich akzeptiert",
      "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Hinzufügen",
      "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Aktualisieren",
      "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Zustimmung des Kunden",
      "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"Vereinbarung URL",
      "CUSTOMER_CONSENT_HELP_TEXT":"Bitte geben Sie die Details der Person in der Organisation des Kunden an, die die Microsoft-Kundenvereinbarung <a ng-click='vm.CustomerConsentClick()'> akzeptiert hat. </a>",
      "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"E-Mail-Addresse",
      "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Vorname",
      "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Nachname",
      "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Telefonnummer",
      "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Klicken Sie hier auf <a id='customerConsentLink'ng-click='vm.MicrosoftCloudAgreementClicked()'></a>, um die Microsoft-Kundenvereinbarung zu lesen",
      "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Microsoft-Kundenvereinbarung",
      "CUSTOMER_CONSENT_REJECT_POPUP":"Möchten Sie<br>{{ProductName}} wirklich ablehnen?",
      "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Ihre Einwilligung wurde erfolgreich abgelehnt",
      "CUSTOMER_CONSENT_SAVED_MESSAGE":"Ihre Einwilligung wurde erfolgreich gespeichert",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Zustimmung zur Verlängerung des Partnerangebots",
      "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Zustimmung zur Verlängerung des Partnerangebots ist fehlgeschlagen",
      "CUSTOMER_CONSENT_TEXT_DATE":"Datum",
      "CUSTOMER_CONSENT_TEXT_EMAIL":"Email",
      "CUSTOMER_CONSENT_TEXT_IP":"IP",
      "CUSTOMER_CONSENT_TEXT_TIME":"Zeit",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Bitte gehen Sie zum Profilmenü und aktualisieren Sie das Zahlungsprofil",
      "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Bitte füllen Sie das Zahlungsprofil aus",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Schnelllink zum Azure-Portal",
      "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Azure-Portal",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Anzahl der bisher gekauften Produkte",
      "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Produkte",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Anzahl der Benutzer, die Zugriff auf Ihre Informationen haben",
      "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Benutzer",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Benachrichtigung über eine ausstehende Rechnung",
      "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Rechnung",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Schnelllink zum Office 365-Portal",
      "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Office-Portal",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Schnelllink zu Office 365-Nutzungsberichten",
      "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Office 365-Nutzungsberichte",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 SKUs",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Produkt zählt nach SKU",
      "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Zählt nach SKU",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Filterbereich",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 Monate",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 Monate",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 Jahr",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Kauf von Sitzplätzen",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Kauf von Sitzplätzen",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Kauf von Sitzplätzen",
      "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sitze gekauft",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Kundensitze",
      "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Kundenplätze",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen",
      "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Geschäftsbedingungen",
      "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Sitze",
      "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Rechnungsposition erfolgreich gelöscht",
      "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Abteilung hinzufügen",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Abteilung erfolgreich gelöscht",
      "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Abteilung bearbeiten",
      "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Abteilungen",
      "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Abteilung erfolgreich gespeichert",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Zugewiesene Sites",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Seite ",
      "CUSTOMER_EXPORTINVOICE_WARNING":"Die Rechnung für {{customername}} wurde noch nicht generiert. Bitte versuchen Sie es nach einiger Zeit erneut.",
      "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Neuladen der Kunden Zustimmung des Anbieters",
      "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Neuladen von Provider-Kundenprofilen",
      "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Von der Kundenrechnung generierter Text",
      "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Zurück zu Rechnungen",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Position hinzufügen",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Stornieren",
      "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Rechnung speichern",
      "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Möchten Sie Ihre Änderungen wirklich rückgängig machen?",
      "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"Das Startdatum der Belastung von Einzelposten darf nicht nach dem Enddatum der Belastung liegen",
      "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"Möchten Sie die Werbebuchung wirklich löschen?",
      "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"Das Fälligkeitsdatum der Rechnung muss nach dem Rechnungsdatum liegen",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Kundendetails",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
      "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Werbebuchungen",
      "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Steuern werden angewendet, nachdem die Rechnung gespeichert wurde.",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Rechnungsdatum",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Geburtstermin",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
      "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Zwischensumme",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adresse",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Stadt",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"Land",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Land auswählen",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Kundenname",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Zustand",
      "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Postleitzahl",
      "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Bitte geben Sie mindestens einen Einzelposten an, um die Rechnung zu speichern",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Endtermin",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Anfangsdatum",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"Ist steuerpflichtig",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Artikelname",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"Zeilenbetrag",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Verkaufsart",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Mehrwertsteuer",
      "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Anerkennung",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Zuschlag",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Anpassungsbetrag",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Anpassungsdetail",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Anbietername",
      "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Zurück zur Rechnung",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Hinzufügen",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Einstellung",
      "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Bearbeiten",
      "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Rechnungen",
      "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
      "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Rechnung erstellen",
      "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Rechnungen",
      "CUSTOMER_LABLE_TEXT_TAG_KEY":"Kunden-Tag",
      "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Wert",
      "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Der Kundenlizenzbericht wird an die entsprechende E-Mail gesendet",
      "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filter",
      "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Produkt verwalten",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Zurück zum Azure-Plan",
      "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Gruppenname",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
      "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Schwellenwerte",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Azure-Abonnement hinzufügen",
      "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Keine Berechtigungen gefunden",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"speichern",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Besitzer wurde noch nicht zugewiesen",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Name",
      "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Inhaber",
      "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Rollen &amp; Richtlinieninhalt geht hier....",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Abrechnung",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Bemerkungen",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Ansprüche",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Schätzen",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Gruppen",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Die Info",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Eigentümer verwalten",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Operative Einheiten",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Zahlungen",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Rollen & Richtlinien",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Erneuerung verwalten",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Verwendung",
      "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Benutzer",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
      "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Abonnement",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Marktcode auswählen",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Laden Sie eine Z.B-CSV herunter",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Laden Sie Kontodaten für mehrere Benutzer im CSV-Format hoch. Laden Sie eine Z.B-CSV herunter",
      "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"Datei als Referenz.",
      "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Umsatzbericht",
      "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Bitte geben Sie den neuen Namen ein",
      "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Ändern Sie den Kundennamen",
      "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Name",
      "CUSTOMER_NAME_CHANGE_SUCCESS":"Name des Kunden erfolgreich geändert",
      "CUSTOMER_NAME_UPDATE_FAILURE":"Aktualisierung des Kundennamens fehlgeschlagen",
      "CUSTOMER_NAME_UPDATE_SUCCESS":"Erfolg beim Aktualisieren des Kundennamens",
      "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Der Kunde ist keinem Account Manager zugeordnet",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Sie sind mit keinem Microsoft-Mandanten verknüpft.",
      "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"Der Kauf von Anbieterprodukten kann fehlschlagen, da keine verknüpften Anbietermieter vorhanden sind",
      "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Zuweisen",
      "CUSTOMER_ONB_STATUS_DESC_CREATED":"Erstellt",
      "CUSTOMER_ONB_STATUS_DESC_DELETED":"Gelöscht",
      "CUSTOMER_ONB_STATUS_DESC_ERROR":"Error",
      "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"An Bord",
      "CUSTOMER_ONBOARDING_BILLANDPAY_":"Suche in der Abrechnung!",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Befolgen Sie diese Schritte, um die Abrechnungssystem-ID eines Kunden zu erhalten",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Melden Sie sich bei Billing System an",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Gehen Sie zu Kunden",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Klicken Sie zum Öffnen auf den Kundendatensatz",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Nehmen Sie die Zeichenfolge nach recordkey",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Holen Sie sich die Zeichenfolge nach recordkey=in der URL",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"z.B:",
      "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"Wert, der kopiert werden soll",
      "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Melden Sie sich bei Authorize.Net an",
      "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Nehmen Sie die Profil-ID vom Kundeninformationsmanager",
      "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Stammverzeichnis für Kundeninformationsmanager: Home-> Kundeninformationsmanager",
      "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"Nimm den String aus der URL",
      "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Abrechnungssystem-ID",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Massenkunden-Onboarding",
      "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Kundendaten sammeln",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Getan",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"Hier",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Sieh nach oben!",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Fahren Sie mit Onboard dieses Kunden fort",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Entfernen",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Überspringen",
      "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Versuche einen anderen",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Massenkunden-Onboarding",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Kartenlizenzen",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Erfolg an Bord",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Zahlungsart",
      "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
      "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Onboarding ist nicht abgeschlossen. Klicken Sie auf die Schaltfläche \\''Weiter mit Onboard dieses Kunden\\'', um fortzufahren.",
      "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"bitte geben Sie eine gültige E-Mail Adresse an",
      "CUSTOMER_ONBOARDING_EZIDEBIT_":"Suche in der Abrechnung!",
      "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"EziDebit Id",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Befolgen Sie diese Schritte, um die EziDebit-ID eines Kunden zu erhalten",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Melden Sie sich bei EziDebit an",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Gehe zu den Zahlern",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Klicken Sie zum Öffnen auf den Zahlerdatensatz",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Nehmen Sie die Zeichenfolge, die sich nach contractId befindet",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"Holen Sie sich die Zeichenfolge nach contractId=in der URL",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"z.B:",
      "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"Wert, der kopiert werden soll",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Kundendetails",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Kunden können im Partner Center hinzugefügt werden",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"wird erfolgreich erstellt",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"erfolgreich eingebunden",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Nächste Schritte",
      "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Plan zuweisen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Bank Name",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Stadt",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Kundenwährungscode",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Kundennummer",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Email",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Vorname",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Ist das Produkt gemeinsam nutzbar",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Nachname",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Linie 1",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Zeile 2",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Microsoft ID",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Wählen Sie den Microsoft-Mandanten aus.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Monat",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Nächstes Abrechnungsdatum",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"Hinweis:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Aktualisieren Sie keinen anderen Inhalt in der Datei als die Benutzerdetails, wie im obigen Z.B angegeben.",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"Optional:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Wenn Sie bereits über die Details zu den Lizenzen verfügen, die Benutzern für ihr Abonnement zugewiesen wurden, können Sie uns diese wie folgt bereitstellen:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Geben Sie die Informationen in dem Format ein, das zum Herunterladen verfügbar ist",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Klicken Sie auf Benutzer und gewähren Sie den Benutzern im Kundenmandanten Zugriff auf dieses Portal",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Identifizieren und validieren Sie die Abonnementdetails",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Beheben Sie die Fehler, indem Sie eine aktualisierte Datei mit Lizenzdetails erneut hochladen, um den Onboarding-Vorgang abzuschließen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Geben Sie einfach die Benutzer-E-Mail-Adressen in den eckigen Klammern ([]) nach diesem Z.B ein:",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Laden Sie die Datei hoch und klicken Sie auf",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Sie können die Angabe der Lizenzdetails überspringen, indem Sie auf SKIP klicken, um den Onboarding-Vorgang abzuschließen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Klicken Sie auf Fertig, um zur Kundenliste zurückzukehren",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Suchen Sie den Kunden, der gerade an Bord war",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Eine Aktionsschaltfläche <i class=\"fa fa-star red\"></i> wird angezeigt, falls bei der Verarbeitung der von Ihnen hochgeladenen Lizenzdetails Fehler auftreten",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Klicken Sie auf die Schaltfläche <i class=\"fa fa-star red\"></i>, um die Fehler anzuzeigen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Bitte korrigieren Sie die Fehler und versuchen Sie es erneut",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Wählen Sie einen Plan",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Anbieter",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Anbieterkategorie",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Zustand",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Mieter",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Datei hochladen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Wählen",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Jahr",
      "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Postleitzahl",
      "CUSTOMER_ONBOARDING_LBL_DELETED":"Gelöscht",
      "CUSTOMER_ONBOARDING_LBL_DISABLED":"Deaktiviert",
      "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Abgelaufen",
      "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendiert",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"Dem Kunden ist kein Plan zugewiesen. Bitte erstellen Sie einen neuen Plan und weisen Sie ihn dem Kunden zu. Beheben Sie dann die Nichtübereinstimmung.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Für Produkte mit reservierter Instanz berücksichtigen wir den Verkaufspreis in Prozent, daher ist der Standardwert 0 % Prozent. Wenn Sie einen anderen Wert angeben möchten, ändern Sie bitte den Verkaufspreis",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Name",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Währungscode",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Verkaufspreis (in Prozent)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis (in Prozent)",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Dieser Wert wird auf den Microsoft-Abrechnungspreis angewendet, um die Rechnung entsprechend zu erstellen.",
      "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Bitte geben Sie den Wert mit bis zu zwei Dezimalstellen ein.",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Pläne",
      "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Anbieter",
      "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Fahren Sie mit Onboard dieses Kunden fort",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuelle",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Nein",
      "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Ja",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Suchen Sie nach Kunden- und Kartenabonnements",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Kartenlizenzen",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Komplett",
      "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Komplett",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"Logo",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf :",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Abrechnung per E-Mail",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Abrechnungssystem Menge",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Name der Firma",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Microsoft ID",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Domain",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Effektiver Preis",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Markup",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Passende Produkte",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Passende Produktmenge",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Prozentsatz",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Preis",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Produktname des Anbieters",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Anbietermenge",
      "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Es wurden keine Anbieterproduktabonnements für die Zuordnung gefunden. Klicken Sie dazu auf die Schaltfläche Kundendaten erfassen.",
      "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"BENUTZERDEFINIERT",
      "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Sie können über die Option Profil zu anderen Zahlungsmethoden wechseln. Rechnungen werden Ihnen vom Partner per E-Mail zugesandt",
      "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Nutzungsabonnements wurden erfolgreich geladen.",
      "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Wird geladen....",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Deaktivieren",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Bearbeiten",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Aktivieren",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Verwalten",
      "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adressen",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Email",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Entitätstyp",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Vorname",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Nachname",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Name",
      "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Rolle",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
      "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Benutzer",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Neuen Benutzer hinzufügen",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Rechnungsadresse",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"Benutzer bearbeiten",
      "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Lieferanschrift",
      "CUSTOMER_OPTION_ALL_PERIODS":"Alle Perioden",
      "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Geschäftsbedingungen.",
      "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie das Kontrollkästchen NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie das Kontrollkästchen „Allgemeine Geschäftsbedingungen“, um Microsoft-Produkte zu bestellen.",
      "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">NCE-Geschäftsbedingungen.</a>",
      "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"Der zu zahlende Betrag sollte die Summe aus Produkt und Gesamtsteuer sein",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Zahlung erneut versuchen",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Rechnung anzeigen",
      "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Produkte ansehen",
      "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Zurück zu Bestellungen",
      "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"Erweiterung",
      "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filter",
      "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Neuer Einkauf",
      "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Mengenaktualisierung",
      "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Status Änderung",
      "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Kundenbestellungen",
      "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Details anzeigen",
      "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
      "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
      "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Status",
      "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Kundenbestellungen",
      "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"In Wartestellung",
      "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Aktionen",
      "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Betrieb",
      "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Abteilungsname",
      "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Betrieb",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Auftragsnummer",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"Miteinander ausgehen",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"Ab Datum",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"Betrieb der Bestellung",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"Auftragsstatus",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Bestellt durch",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Bestellt am",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"Bestellungen ab",
      "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Bestellungen an",
      "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Seite-Name",
      "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Status",
      "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Zuletzt aktualisiert",
      "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Weiterlesen",
      "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Zurück zu Kundenzahlungen",
      "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Ausstehende Rechnungen für",
      "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Eindeutige Kennung auswählen",
      "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
      "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Aktueller Gutscheincode",
      "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong> Fehler! </strong> Der Kunde kann nicht von <strong> {{PlanName}} </strong> zu <strong> {{targetPlanName}} </strong> verschoben werden, da der Zielplan keine Angebote enthält die mit den aktuell aktiven Abonnements übereinstimmen.",
      "CUSTOMER_PLAN_CHANGE_INFO":"Eine Planänderung wird noch nicht unterstützt, wenn im aktuellen Plan mindestens ein aktives Abonnement für ein zusammengesetztes Angebot vorhanden ist.",
      "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Derzeitiger Plan",
      "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Kundenabonnements",
      "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Effektiver Preis",
      "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Übereinstimmung gefunden",
      "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Zielname",
      "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Zielpreis",
      "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Dieser Gutschein ist dem Kunden bereits zugeordnet.",
      "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Pläne",
      "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Erfolg! </strong> Der Kunde kann von <strong> {{PlanName}} </strong> zu <strong> {{targetPlanName}} </strong> verschoben werden. Klicken Sie auf <strong> Senden </strong>, um fortzufahren.",
      "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Zielplan",
      "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Verwenden Sie den aktuellen Gutscheincode",
      "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(gelöscht)",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
      "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Plan Name",
      "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Schließen",
      "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Addons",
      "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Veränderung",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Abonnement reaktivieren",
      "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Abonnement mit Addons reaktivieren",
      "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Abonnement aussetzen",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
      "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Bündeln",
      "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
      "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Fügen Sie weitere Gutscheine hinzu",
      "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"einreichen",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Abonnement fortsetzen</b> <br/> Sind Sie sicher, dass Sie das Abonnement für <b>{{productName}}</b> nach der Testversion fortsetzen möchten?",
      "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" Das Testangebot für {{productName}} wird in ein Abonnement umgewandelt, sobald das effektive Enddatum des Anbieters erreicht ist.",
      "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"Möchten Sie den Abrechnungszyklus von {{billingCycle}} in {{c3billingCycle}} ?<br> Nach der Rechnungsgenerierung kann der Abrechnungszyklus nicht rückgängig gemacht werden.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Abonnement kündigen</b> <br/> Sind Sie sicher, dass Sie Ihr <b>{{productName}}</b>-Testabonnement kündigen möchten?",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Beim Kündigen des {{productName}}-Abonnements ist ein Fehler aufgetreten.",
      "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Testangebot für {{productName}} erfolgreich storniert!",
      "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"aus",
      "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorieren",
      "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Fehlerdetails",
      "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Schließen",
      "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
      "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Testzeit",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Bitte geben Sie kommagetrennte Werte ein.",
      "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Anleitung",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Ewig",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"Unbefristete Software",
      "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Software-Abonnements",
      "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Bestätigen Sie die Namensaktualisierung in C3",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Möchten Sie den Abrechnungszyklus dieses Produkts wirklich in {{targetBillingCycle}} ändern?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"Möchten Sie das Angebot dieses Produkts wirklich in {{targetOffer}} ändern?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie den Umfang dieses Produkts auf {{targetScope}} ändern möchten?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"Möchten Sie die Laufzeit dieses Produkts wirklich auf {{targetTermDuration}} ändern?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie {{productName}} {{withAddons reaktivieren möchten? 'mit Addons': ''}}?",
      "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie {{productName}} wirklich aussetzen?",
      "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Übergeordnetes Abonnement fehlt für {{Name}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Der Name wurde erfolgreich geändert",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"Die Bestellnummer wurde erfolgreich geändert",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Der Abrechnungszyklus von {{productName}} wurde in {{targetBillingCycle}} geändert.",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Ungültige Produktmenge",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"Die Menge für bereits gekaufte Produkte kann nicht auf Null aktualisiert werden",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"Antrag auf Aktualisierung des Abonnements wurde erfolgreich gesendet",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Anfrage zur Reaktivierung von {{productName}} {{withAddons ? 'with addons' : ''}} wurde erfolgreich übermittelt",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"{{productname}} erfolgreich reaktiviert {{withAddons? 'with addons': ''}}",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Eigentum erfolgreich freigegeben",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"Anfrage zum Aussetzen des Produkts {{productName}} wurde erfolgreich gesendet",
      "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{productName}} erfolgreich ausgesetzt",
      "CUSTOMER_PRODUCT_NCE_AUTO_MANAGE_LABEL":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Automatische Verlängerung",
      "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"Erneuert weiter",
      "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Stornierung möglich bis",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"Wenn Sie den Abrechnungszeitraum ändern, werden alle Abonnements aktualisiert, die Teil der \"Bestellung\" sind. Dadurch wird eine neue Jahreslaufzeit für alle Abonnements zum aktuellen Preis gemäß Preisliste erstellt. Die unten stehenden Abonnements werden auf \"jährliche\" Abrechnung aktualisiert. Um diese Anfrage abzuschließen, klicken Sie bitte auf „OK“ und dann auf „Senden“.",
      "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Abrechnungszeitraum ändern",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Abonnement kündigen</b> </br> <br/> <b> Hinweis: </b> Ihre 7-tägige Kündigungsfrist ist abgelaufen. Wir können Ihr Abonnement nicht kündigen.</br>Wenn Sie dieses Abonnement im nächsten Abrechnungszeitraum kündigen möchten, aktualisieren Sie den Status der automatischen Verlängerung auf \"false\".",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Abonnement kündigen",
      "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Automatische Verlängerung festlegen</b> </br> <br/> <b> Hinweis: </b> Wir können den Status der automatischen Verlängerung nicht ändern.",
      "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Läuft aus am",
      "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Rechnungshäufigkeit verwalten",
      "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Unterbrechen Sie den Zugriff des Kunden auf den Dienst",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"Durch die Wiederaufnahme eines ausgesetzten Abonnements wird der Zugriff des Kunden auf den Dienst wiederhergestellt. Dies hat keine Auswirkungen auf Ihre Rechnung. Sie werden weiterhin wie gewohnt abgerechnet. Wenn Sie die automatische Verlängerung aktiviert und Änderungen geplant hatten, bevor Sie den Zugriff pausierten, können Sie sie wieder aktivieren.",
      "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Nehmen Sie den Zugriff des Kunden auf den Service wieder auf",
      "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Änderungen übermitteln",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Abonnement kündigen",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Abonnement kündigen</b> <br/> Möchten Sie {{productName}} wirklich kündigen? <p style='text-align:left;' > Um eine Rückerstattung zu erhalten, müssen Sie Abonnements innerhalb von 7 Kalendertagen nach dem Kauf oder der Verlängerung kündigen. <br/> <b> Hinweis:</b> Der Kunde verliert den Zugriff auf dieses Abonnement sofort nach der Kündigung und der Zugriff kann nach der Kündigung nicht wiederhergestellt werden. Wenn Sie das Abonnement innerhalb von 7 Kalendertagen kündigen, wird Ihnen die nicht genutzte Zeit erstattet. In Ihrer nächsten monatlichen Rechnung erscheint eine Gutschrift. <a target='_blank' href=\"{{cancelNewCommerceSubscriptionGuidLineURL}}\"> Weitere Informationen.</a> </p>",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Erfolgreich kündigen!",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Automatische Verlängerung festlegen</b> <br/> Sind Sie sicher, dass Sie den {{autoRenewStatus}}-Status für {{productName}} automatisch verlängern möchten?",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Läuft ab",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Status der automatischen Verlängerung für {{productName}} erfolgreich aktualisiert",
      "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"Erfolgreich geupdated !",
      "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie {{productName}} wirklich sperren? <br/> <b> Hinweis: </b> Obwohl das Pausieren des Kundenzugriffs auf den Dienst die Möglichkeit des Kunden, Dienste zu nutzen, sofort blockiert, wirkt sich dies nicht auf Ihre Rechnung aus. Sie werden weiterhin wie gewohnt abgerechnet. Durch das Pausieren wird die automatische Verlängerung deaktiviert und deaktiviert und alle geplanten Änderungen werden gelöscht.",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Aktivität",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Aktivitätsstatus",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Datum",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Quellmenge",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Upgrade von {{sourceProductName}} auf {{targetProductName}}",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Zielmenge",
      "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Übergang",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Kundenprodukte",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Zurück zu den grundlegenden Details",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"speichern",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Menge",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Status",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"Menge kann nicht größer sein als",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Benutzerdefinierter Abrechnungszeitraum",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabatt",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Menge (verfügbar)",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Menge für",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Verknüpftes Abonnement",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"speichern",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Add-Ons",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Menge (verfügbar)",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"Menge kann nicht größer sein als",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Menge für",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Rabatt",
      "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Abonnement kaufen</b> <br/> Sind Sie sicher, dass Sie Ihr Testangebot für {{productName}} in ein Abonnement umwandeln möchten?",
      "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"Möchten Sie die Menge für das aktuelle Produkt wirklich von <b>{{oldQuantity}}</b> auf <b>{{newQuantity}}</b> aktualisieren?",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"Ist automatisch erneuerbar",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Läuft aus am",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"Azure-Abonnement-ID",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"Reservierungs-ID",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"Reservierungsauftrags-ID",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Reservierungsregion",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Umfang",
      "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"Erneuert weiter",
      "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Die Anzahl der gekauften Plätze überschreitet das zulässige Limit von <b> {{SeatLimit}}</b> für <b>{{productName}}</b>.",
      "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Entitäten filtern",
      "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Produktsequenz",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Produktsequenz",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Erweiterung",
      "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Verknüpft",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Abteilung",
      "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":"Für",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Möchten Sie Ihre Änderungen wirklich rückgängig machen?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Möchten Sie die Produktsequenz wirklich aktualisieren?",
      "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Kunden Produktsequenz aktualisiert",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"Letzte Update-Anfrage haben einige Fehler Lesen Sie mehr...",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Rabatt",
      "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Aktiv",
      "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Abgesagt",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Testversion abbrechen",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Abonnement nach der Testphase fortsetzen",
      "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Abonnement kaufen",
      "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Vollendet",
      "CUSTOMER_PRODUCT_STATUS_DELETED":"Gelöscht",
      "CUSTOMER_PRODUCT_STATUS_DISABLED":"Deaktiviert",
      "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Abgelaufen",
      "CUSTOMER_PRODUCT_STATUS_FAILED":"Gescheitert",
      "CUSTOMER_PRODUCT_STATUS_INPROVISION":"InProvision",
      "CUSTOMER_PRODUCT_STATUS_PENDING":"steht aus",
      "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Erfolgreich",
      "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendiert",
      "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filter",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"Land",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Domain",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Verlinkter Produktpreis",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Anbieter",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Abonnement reaktivieren",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Abonnement mit Addons reaktivieren",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Umfang",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Servicetypen",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Status",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Abonnement aussetzen",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
      "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Testzeit",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"Das Angebot von {{productName}} wird am Ende des Testzeitraums in ein Abonnement umgewandelt.",
      "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Probeangebot für Eltern",
      "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Laufzeit",
      "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Zurück zu Bestellungen",
      "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"0 Mengen einschließen",
      "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Erneuerung",
      "CUSTOMER_PRODUCTS_IN_ACTIVE":"Das Abonnement ist inaktiv",
      "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Gehört",
      "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Verknüpftes Abonnement",
      "CUSTOMER_PRODUCTS_LIST_STATUS":"Status",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Abteilung",
      "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Domain",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Seite ",
      "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Abonnement-ID",
      "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Mieter",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Abonnement kündigen",
      "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Gutschein in die Zwischenablage kopiert",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Grundlegende Details",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Bemerkungen",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Benachrichtigungen",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Eigentümer verwalten",
      "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Benutzer",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Schließen",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Link kopieren",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Link kopieren",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Herunterladen",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Holen Sie sich Schlüssel und Downloads",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Link in die Zwischenablage kopiert",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Wählen Sie den Dateityp",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Sprache auswählen",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Ausgewähltes Produkt",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Aktionen",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Dateigröße",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Ich würde",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Schlüssel",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Name",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Menge",
      "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Ansprüche",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Stornierungsdatum",
      "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Verlängerungsdatum",
      "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Aktion angewendet",
      "CUSTOMER_PRODUCTS_ORDERED_ON":"Bestellt",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Verwalten",
      "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"wird bearbeitet",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Anwenden",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"klar",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Schließen",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Untergeordnete Entitäten einschließen",
      "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Domain",
      "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Filteroptionen",
      "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Produkt verwalten",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Menge",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"Bestellt am",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Gehört",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"Erneuert weiter",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Gesamtmenge",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Stückpreis",
      "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Stückpreis",
      "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Abteilung",
      "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Seite ",
      "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Für dieses Abonnement sind geplante Änderungen vorhanden",
      "CUSTOMER_PRODUCTS_VALIDITY":"Gültigkeit",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Zugewiesen an Manager Ein",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Email",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Telefonnummer",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Der Name des Managers",
      "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Account Manager Details",
      "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Grundlegende Details erfolgreich aktualisiert",
      "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Lieferanschrift",
      "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Kundenprofil",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Abteilungen",
      "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Websites",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Adresse hinzufügen",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"E-Mail hinzufügen",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Telefon hinzufügen",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adressen",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"E-mailadressen",
      "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Telefonnummern",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Vorname",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Nachname",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Name",
      "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Websites",
      "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Keine Aufzeichnungen gefunden!",
      "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Hinzufügen",
      "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Zahlungsprofile",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Bankkonto",
      "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Kreditkarte",
      "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Keine Profile gefunden. Fügen Sie ein neues Zahlungsprofil hinzu, indem Sie auf die Schaltfläche HINZUFÜGEN klicken",
      "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"Zahlungsprofil wird hinzugefügt. Bitte warten Sie einen Moment, während der Vorgang abgeschlossen ist.",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Lieferanschrift",
      "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Benutzerkonten",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Erstellungsdatum des Kontos",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Rechnungsadresse",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"C3 Onboarding-Datum",
      "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Eindeutige Bezeichnung",
      "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Vielen Dank für Ihre Wahl",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Kontoeinrichtung",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Shop",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Bestellung überprüfen",
      "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Bestätigen",
      "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Getan",
      "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"Die E-Mail-Adresse ist bereits vergeben. Bitte melden Sie sich mit Ihren Zugangsdaten bei C3 an oder erstellen Sie ein neues Konto mit einer anderen E-Mail-Adresse",
      "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Bitte füllen Sie die erforderlichen Felder aus, um fortzufahren",
      "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"Die Anmeldeanfrage wurde erfolgreich platziert",
      "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Ihre Daten wurden erfolgreich übermittelt. Wir werden uns in Kürze mit Ihnen in Verbindung setzen",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"einreichen",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Produkte einkaufen",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"Die Annahme der Anbieter-Kunden-Vereinbarung sollte bestätigt werden",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existiert",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"ist nicht vorhanden",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Land auswählen",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Hauptansprechpartner",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Adresse 1",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Stadt",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"Land",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Domain Name",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Email",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Vorname",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"E-Mail-Adresse des globalen Administrators",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Zugriff auf den primären Kontakt gewähren (als Kundenadministrator)?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Nachname",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"Die bereitgestellte E-Mail hat den CustomerAdmin-Zugriff auf das Portal.",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Einladung zum Zugriff auf die Anwendung senden?",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Zustand",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Mieter-ID",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Postleitzahl",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Dieser Domänenname ist ungültig",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"ist verfügbar",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"ist nicht verfügbar",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Überprüfung der Domainverfügbarkeit",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Staat wählen",
      "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Staat eingeben",
      "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Zur Pflege der Mandantendomänendetails",
      "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Um abrechnungsbezogene Dinge zu kommunizieren",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Bitte geben Sie unten die Details ein. Der Partner wird sich dann mit Ihnen in Verbindung setzen, um weitere Unterstützung zu leisten.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Klicken Sie auf „Link in die Zwischenablage kopieren“, um den Link der Reseller-Beziehung zu kopieren und dem Reseller-Modell zu folgen.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Reseller-Beziehung mit einem Konto ab, das ein globaler Administrator ist.<br>Geben Sie nach Abschluss die folgenden Details ein und klicken Sie auf „Senden“, nachdem der Partner mit Ihnen kommuniziert hat.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Wenn Sie die Reseller-Beziehung nicht akzeptieren möchten, geben Sie einfach die folgenden Details ein und klicken Sie auf „Senden“, nachdem dieser Partner mit Ihnen kommuniziert hat.",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Anweisungen zur Annahme einer Reseller-Beziehung",
      "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Anweisungen zur Annahme einer Reseller-Beziehung",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"In der Zwischenzeit können Sie den Partner als Ihren CSP-Reseller autorisieren, indem Sie über Ihren globalen Admin-Benutzer die Zustimmung zum Reseller-Beziehungslink erteilen.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Klicken Sie auf „Link in die Zwischenablage kopieren“.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":"um den Link der Reseller-Beziehung zu kopieren und dem Reseller-Modell zu folgen.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Reseller-Beziehung mit einem Konto ab, das ein globaler Administrator ist.<br>Geben Sie nach Abschluss die folgenden Details ein und klicken Sie auf „Senden“, woraufhin dieser Partner mit Ihnen kommuniziert.",
      "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Wenn Sie die Reseller-Beziehung nicht akzeptieren möchten, geben Sie einfach die folgenden Details ein und klicken Sie auf „Senden“, nachdem dieser Partner mit Ihnen kommuniziert hat.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"Anmelde-BatchId",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"Die Verarbeitung kann einige Zeit in Anspruch nehmen. Der Status wird auf dieser Seite angezeigt. Wenn Sie den Browser verlassen oder versehentlich schließen, informieren wir Sie per E-Mail.",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"Anmeldung läuft",
      "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Bitte warten Sie, während wir Ihr Konto einrichten. Sie können Ihr Gerät wie gewohnt weiter verwenden. Sobald die Einrichtung abgeschlossen ist, senden wir Ihnen eine E-Mail-Benachrichtigung.",
      "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Um die Mieterinformationen und Abonnements abzurufen",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Wagen",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Kundendetails",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Zahlungsdetails",
      "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Shop-Produkte",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Externer Kundenname",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Aktion",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"C3-Produkte",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Externes Produkt",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Sequenznummer",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Zustimmung",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Kategoriename",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Vertrag",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Kundenname",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"PSA-Kundenname",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Bitte verwenden Sie weitere Filter, wenn Sie zusätzliche Einschränkungen für Kategorie, Mieter usw. festlegen möchten.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"Ist Standardvertrag?",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Standardverträge werden verwendet, um Vertragsservices hochzuladen, die mit Services verknüpft sind, die automatisch erstellt und zugeordnet werden.",
      "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Mieter",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":"Abteilung :",
      "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Seite? ˅ :",
      "CUSTOMER_RECONCILIATION_REPORT":"Abstimmungsbericht",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Diese Funktion ist für individuelle Angebote verfügbar und wird für eine Laufzeit von einem Jahr unterstützt.",
      "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"Das verknüpfte Abonnement wird von dieser Funktion nicht unterstützt.",
      "CUSTOMER_REPORT":"Kundenberichte",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filterprodukte",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Max. Bestelldatum",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Maximale Menge",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Mindestbestelldatum",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Min. Menge",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Läuft in Tagen ab",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Bestellt zwischen",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Menge",
      "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Produktbericht",
      "CUSTOMER_REPORTS":"Berichte",
      "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Anpassung erfolgreich gespeichert.",
      "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Vergangene Gebühren als Anpassung an die aktuelle Rechnung hinzugefügt",
      "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Zahlung erfolgreich erfasst",
      "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"Rechnungsreferenz-ID",
      "CUSTOMER_SEARCH_CLOSE_OPTION":"Filter schließen",
      "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Gelöschten Kunden einschließen",
      "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"CRM-Gelegenheits-ID",
      "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Standardzahlungsmethode",
      "CUSTOMER_SEARCH_CUSTOMER_name":"Name",
      "CUSTOMER_SEARCH_CUSTOMER_NAME":"Name",
      "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Bezahlverfahren",
      "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Planen",
      "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Stichworte",
      "CUSTOMER_SEARCH_DEFAULT_OPTION":"Wählen",
      "CUSTOMER_SEARCH_DOMAIN_NAME":"Domain Name",
      "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
      "CUSTOMER_SEARCH_FILTER":"Kunden filtern",
      "CUSTOMER_SEARCH_INVOICE_NOTES":"Rechnungsnotizen",
      "CUSTOMER_SEARCH_MARKET":"Marktcode",
      "CUSTOMER_SEARCH_PROVIDER":"Anbieter",
      "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "CUSTOMER_SEARCH_RESET_OPTION":"Zurücksetzen",
      "CUSTOMER_SEARCH_SEARCH_OPTION":"Suche",
      "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Eindeutige Kennung",
      "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"in den Warenkorb legen",
      "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Wählen Sie Alle Add-Ons",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Verknüpftes Abonnement",
      "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Schließen",
      "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Shop",
      "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Zum Filtern oder Einschließen von Produkten aus der Kinderebene",
      "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Abrechnungsarten",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Gültigkeit",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Gültigkeitstyp",
      "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"TESTDAUER",
      "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"Erweiterung",
      "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"TESTANGEBOT",
      "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filter",
      "CUSTOMER_SIGNUP_FAILED":"Kundenregistrierung fehlgeschlagen",
      "CUSTOMER_SIGNUP_SUCCESS":"Erfolg bei der Kundenanmeldung",
      "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Anmeldung erfolgreich. Sie können sich beim C3-Portal mit Zugangsdaten anmelden, die an folgende Adresse gesendet werden:",
      "CUSTOMER_SITE_ADD_SITE":"Site hinzufügen",
      "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site erfolgreich gelöscht",
      "CUSTOMER_SITE_EDIT_SITE":"Site bearbeiten",
      "CUSTOMER_SITE_HEADER_TEXT":"Websites",
      "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Seite erfolgreich gespeichert",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"Das Ignorieren von Fehlern ist für dieses Abonnement nicht möglich. Bitte wenden Sie sich für weitere Unterstützung an den C3-Support.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Azure-Plan",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Ansprüche",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Wählen Sie Azure Plan.",
      "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Wählen Sie Berechtigungen.",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Sind Sie sicher, dass Sie fortfahren möchten?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"Angebot anwenden Für Kunden?",
      "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" Der Preis darf nicht anders sein als <strong>{{price}}</strong>, da für diese Reservierungsbestell-ID {{reservationorderID}} bereits ein Reservierungsprodukt mit Preis vorhanden ist.<br>Möchten Sie mit dem Preis <strong>{{preis} synchronisieren? </strong> ?",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Akzeptieren",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Ablehnen",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Aktionen",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Zurück zu den Produkten",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Erneuerungsmanager",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Erneuerungsmanager",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Aktion",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Zurück zum Erneuerungsmanager",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Verlauf des Erneuerungsmanagers",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Aktualisiert am",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Verlauf des Erneuerungsmanagers herunterladen",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Verlauf des Renewal Managers",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Preis",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Produktname",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Menge",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nutzername",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Preis",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Produkte",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Menge",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Erneuert am",
      "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Name",
      "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Bitte wählen Sie mindestens eine Datei aus.",
      "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Datei erfolgreich hochladen",
      "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Genehmigen",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Laden des Abstimmungsberichts",
      "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Neuladen der Kunden Rechnungsdetails...",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Kunden hinzufügen",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Benutzer zum Abonnement hinzufügen",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Zurück zu Abonnementbenutzern",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Bestehend (mehrfach)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Massenkunden-Onboarding",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Bestehende (Single)",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Bestehender Kunde",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Export Billing Summary",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Nutzungsdetails exportieren",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Zum Bericht gehen",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"Hier",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Neu",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Neukunde",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Kunden an Bord",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Abstimmungsbericht",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Rechnungsdetails neu laden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Aktuellen Monat neu laden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Voll neu laden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonnementzusammenfassung neu laden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Nutzungsdetails neu laden",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Entfernen",
      "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Wählen",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Kunden hinzufügen",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"Abonnements hinzufügen für",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"Azure-Abonnements von",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Kunden",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonnementbenutzer verwalten",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Bietet an",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Planen & Angebote",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Abstimmungsbericht für",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonnementbenutzer von",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Kundenkonfiguration von",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Wert",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Verwendungsdetails von",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Benutzer von",
      "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Überprüfen Sie Onboarding-Fehler für",
      "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finalisieren",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Abrechnung",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Firmenprofil",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Zahlungsart",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
      "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Hauptansprechpartner",
      "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"Logo",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"Rechnung wurde genehmigt!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Sie können dieser Rechnung noch Anpassungen (zusätzliche Gebühren oder Gutschriften) hinzufügen, bevor diese abgeschlossen ist und der Kunde belastet wird. Der Kunde kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen, sobald sie abgeschlossen ist.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"Rechnung wurde abgeschlossen!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Der Benutzer kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen.",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"Rechnung wurde erstellt!",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Sie können Anpassungen (zusätzliche Gebühren oder Gutschriften) zu dieser Rechnung hinzufügen, bevor diese abgeschlossen ist und der Kunde belastet wird",
      "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Danke dir.",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Autopay",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Auto-Pay: AUS",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Automatische Zahlung: EIN",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Abrechnung",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organisation IIN",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Bezahlverfahren",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Bank Name",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Stadt",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Gefahrenschwelle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Email",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Fehlerschwelle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Vorname",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Gruppenname",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Info-Schwelle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Vor Kunden verstecken",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Ausgewählte Elemente",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Nachname",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Linie 1",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Zeile 2",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Monat",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Planen",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Rolle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Zustand",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Warnschwelle",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Jahr",
      "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Postleitzahl",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Pläne",
      "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Menge",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuelle",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Nein",
      "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Ja",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Inaktives Abonnement",
      "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Mengeninkongruenz",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Zugriff entfernen",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Abonnement aktivieren",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Benutzer",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Aktiv in CSP",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Komponentenname",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Email",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"E-Mail-ID",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Ansprüche",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Error",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Geschätzte Kosten",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Vor Kunden verstecken",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Abonnements sind für Kunden nicht sichtbar",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Zuletzt aktualisiert",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"MSFT-Rechnung",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Onboarding-Status",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Preis",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Profitieren",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Rolle",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Schwellenwerte",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Benutzer",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nutzername",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Es wurden noch keine Abonnements hinzugefügt.",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Kundenplan zuweisen",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Abrechnungszyklus ändern",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Namen ändern",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Tags definieren",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Steuern definieren%",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Behebung der Nichtübereinstimmung des Aktivierungsstatus im Partner Center",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Korrigieren Sie den Zustandskonflikt bei der automatischen Verlängerung im Partner Center",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Fehlanpassung beheben...",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Fehlanpassung beheben",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Angebot ändern",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Zahlungsanbieter",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":"Aktualisieren Sie den unbefristeten Produktstatus",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Plan ändern",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Förderung fixieren",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Anbieter",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Kunde: Schneller Identitätswechsel",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Abstimmungsbericht",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonnementzusammenfassung neu laden",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Umfang korrigieren",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Kundenkonfiguration",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":"Begriff ändern",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Testen Sie die automatische Zahlung",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Benutzer",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Validierungsfehler",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"BENUTZERDEFINIERT",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Gesamt",
      "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Monatliche Budget-Info-Warn-Error-Danger",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"ist verfügbar",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"ist nicht verfügbar",
      "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Bitte laden Sie die Vorlage von herunter",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Benutzer löschen",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Gewähren Sie Zugriff auf das Portal",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Imitieren",
      "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Zugriff über das Portal widerrufen",
      "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"Der Wert sollte zwischen 0 und 100 liegen",
      "CUSTOMER_SUP_TEXT_DELETED":"(gelöscht)",
      "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Profile synchronisieren",
      "CUSTOMER_TAGS_EXAMPLE":"Definieren Sie Tag- und Wertepaare für den Kunden, die beim Definieren der Zugriffssteuerung für Benutzer verwendet werden sollen. <br/> Beispiel: Sie können ein Tag mit dem Namen SupportAgent und einen Spitznamen eines Agenten (z. B. Agent001) als Wert definieren. Wenn für einen Helpdesk-Agenten eines der Tags als SupportAgent und einer der Werte für dieses bestimmte Tag als Agent001 festgelegt ist, kann der Agent die Kunden sehen, für die das Tag-Wert-Paar {SupportAgent, Agent001} festgelegt ist.",
      "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Name",
      "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Wert",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Löschen",
      "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Wert",
      "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"Das benutzerdefinierte Enddatum der Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, gilt nur für OnlineServicesNCE-Produkte.",
      "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Ausstehende Rechnungen anzeigen",
      "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"Zahlungen für",
      "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Kunde",
      "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Kunde",
      "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Benutzerdaten erfolgreich gespeichert",
      "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"Der Bericht zur Benutzerlizenzzuweisung wird an die angegebene E-Mail-Adresse gesendet.",
      "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Neuen Benutzer hinzufügen",
      "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Zugriff gewähren",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Benutzer verwalten",
      "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Zugriffsrechte entziehen",
      "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Abteilung",
      "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"E-Mail-Addresse",
      "CUSTOMER_USERS_FORM_LABEL_ROLE":"Rolle",
      "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Rollentyp",
      "CUSTOMER_USERS_FORM_LABEL_SITE":"Seite ",
      "CUSTOMER_USERS_HEADING":"Benutzer",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"E-Mail-Addresse",
      "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Rolle",
      "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Laden Sie Rechnungen/Zahlungen herunter",
      "customerconfiguration":"Kundenkonfiguration",
      "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Bitte geben Sie an, ob sich die ACH-Details auf ein Geschäftskonto beziehen",
      "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Dieser Kunde wurde bereits ausgewählt",
      "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Der Kunde ist bereits im System vorhanden",
      "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Kunde erfolgreich erstellt",
      "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Der Onboarding-Prozess des Kunden ist im Gange",
      "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie mindestens eine Datei aus.",
      "CUSTOMERONBOARDING_SUCCESS":"Kunde erfolgreich eingebunden.",
      "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Beim Hochladen der Datei ist ein Fehler aufgetreten",
      "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} hat bereits eine Lizenz für {offername}",
      "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Die Anzahl der bereitgestellten Benutzer ist höher als die Anzahl der verfügbaren Lizenzen. Bitte korrigieren Sie die Benutzerdetails und versuchen Sie es erneut.",
      "customers":"Kunden",
      "Customers":"Kunden",
      "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Aktionen",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Unternehmen",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Kunden",
      "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
      "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Link zum Abrechnungsprofil",
      "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Link zum Anbieter",
      "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Link zum Abrechnungsprofil entfernen",
      "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Rechnungen erstellen…",
      "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Neuladen des Kundenprofitabilitätsberichts...",
      "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Zurück zu Rechnungen",
      "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Anpassung hinzufügen",
      "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Fügen Sie den fälligen Betrag als Anpassung hinzu!",
      "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
      "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Download Rechnung",
      "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Zum Bericht gehen",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Verlauf nach Datum",
      "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Geschichte durch Angebot",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Zahlung einleiten",
      "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Testgebühr Kunde",
      "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
      "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Rechnung für",
      "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Rentabilität durch Abonnements für",
      "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
      "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Abonnementänderungsverlauf für",
      "CUSTOMERS_DOMAIN_CHECK_ERROR":"Die Domainverfügbarkeit kann nicht überprüft werden",
      "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche",
      "CUSTOMERS_GRANT_ACCESS_CONFIRM":"Möchten Sie wirklich Zugriff auf {{username}} gewähren?",
      "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Kunden",
      "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Keine Rechnungen gefunden. Bitte wenden Sie sich an den Support.",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adresse",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Kunde",
      "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Kontoübersicht",
      "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Rechnungsdatum",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Rechnung generiert am",
      "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Rechnungsstatus",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"Rechnung wurde erstellt!",
      "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Sie können dieser Rechnung Anpassungen (zusätzliche Gebühren oder Gutschriften) hinzufügen, bevor diese verarbeitet werden und der Kunde belastet wird",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"Rechnung wurde bearbeitet!",
      "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Der Kunde kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen.",
      "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Gebühren aus früheren Rechnungen",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Es sieht so aus, als ob es Gebühren aus früheren Rechnungen gibt. Wenn Sie sicher sind, dass Sie alle Zahlungen des Kunden erfasst haben, klicken Sie auf die folgende Schaltfläche, um",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"Hinzufügen von <strong> {{InvoiceDueAmount}}</strong> als Anpassung zur aktuellen Rechnung.",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Es sieht so aus, als ob es Gebühren gibt",
      "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"insgesamt.",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Bearbeiten",
      "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Löschen",
      "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Rechnungsdetail",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Lizenzbasiert",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Nutzungsabhängig",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Menge",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Ladungsart",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Beschreibung",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Rabattbetrag",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Angebotspreis",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Menge",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Endtermin",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Großer Betrag",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Zwischensummenbetrag",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Anfangsdatum",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Abonnementname",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Steuerbetrag",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Steuerprozentsatz",
      "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Stückpreis",
      "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"Rechnungspositionen",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Rekordzahlung",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Zahlungsbetrag",
      "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Bemerkungen",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Fehlergrund",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Bezahlte Menge",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Zahlungs-ID",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Rechnungszahlungen",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Bemerkungen",
      "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"Transaktions-ID",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Erfassen Sie die Zahlungen auf dieser Rechnung.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"z.B:",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Teilzahlung als Rate usw.",
      "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Wenden Sie sich an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder irrtümlich erfasst wurden",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"CC-Bearbeitungsgebühr ({{CCFeePercentage}} % von {{CCPaidAmount}})",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Gesamtsumme",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Zwischensumme",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Fehlergrund",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Menge",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Datum",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Bemerkungen",
      "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Zahlungsstatus",
      "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Rechnungsdetails einschließlich Verlauf werden erfolgreich aktualisiert",
      "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Verwendungsdetails einschließlich des Verlaufs werden erfolgreich aktualisiert",
      "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Abrechnung per E-Mail",
      "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"Onboarded On",
      "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Pläne",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"C3Id",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Name",
      "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Kunden an Bord",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Suchen Sie nach einem passenden Datensatz im Abrechnungssystem",
      "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Es wurde eine Übereinstimmung mit der Referenz-ID {{ID}} im Abrechnungssystem gefunden.",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Suchen von Kunden in C3 nach einer Übereinstimmung anhand der Provider-Kunden-ID",
      "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Einen Kunden mit dem Namen {{customername}} gefunden",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Suchen von Kunden in {{providerName}} nach einer Übereinstimmung mit der Provider-Kunden-ID",
      "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Einen Kunden mit dem Namen {{customername}} in {{providerName}} gefunden",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Die Mengen für Kinderangebote in einem Komposit sollten gleich sein",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Ein oder mehrere Angebote in CSP werden demselben Angebot in der Abrechnung zugeordnet. Bitte passen Sie Ihre Zuordnung an",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"Das Angebot {{Name}} im Plan enthält mehrere übereinstimmende CSP-Abonnements.",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"Das Abonnement {{nameinbilling}} in der Abrechnung konnte nicht zugeordnet werden",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"Das Angebot {{name}} enthält keine passenden CSP-Abonnements im Partner Center",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"Das Abonnement im Partner Center - {{name}} enthält kein entsprechendes Angebot im Kartenplan",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Die folgenden Angebote sollten CSP-Abonnements zugeordnet werden:",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"Im Angebot {{name}} sind mehr Benutzer angegeben als in den Sitzplätzen",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Die Mengen für die Abonnements sollten mit dem Partner Center übereinstimmen",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Die Mengen für das Abonnement {{subscriptionname}} sollten zwischen Partner Center und Abrechnung übereinstimmen",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Die Mengen für die Abonnements sollten zwischen Partner Center und Billing übereinstimmen",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"Die Anzahl der Sitzplätze für ein CSP-Abonnement {{cspsubscription}} sollte mit der Summe aller Nummern übereinstimmen, die für verwandte Angebote im Plan angegeben wurden",
      "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Ein oder mehrere CSP-Angebote werden nicht zugeordnet oder es fehlen Abonnements in CSP",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Beim Aktualisieren der Abonnements für {{customerName}} ist ein Fehler aufgetreten.",
      "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Aktualisierte Abonnements für Kunden - {{customername}}",
      "CUSTOMERS_REGISTRATION_SUCCESS":"Kundenregistrierung erfolgreich abgeschlossen. An {{registeredemail}} wird eine E-Mail mit Anweisungen zum Anmelden am Portal gesendet.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"Kundenregistrierung erfolgreich abgeschlossen.<br/>Anmeldedaten des Administratorbenutzers lauten:<br/><b>UserId -</b> {{userId}},<br/><b>Temporary Password -</b> {{password }}",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"Kundenregistrierung erfolgreich abgeschlossen.<br/>Die Anmeldeinformationen des Administratorbenutzers lauten:<br/><b>Benutzer-ID -</b> {{userId}},<br/><b>Temporäres Kennwort -</b> {{password}} <span class =\"text-danger\"> <br/><br/> <b>Die Sicherheitsgruppen konnten der GDAP-Anforderung nicht zugewiesen werden. Bitte schließen Sie dies im Partner Center ab, da weitere Aktionen andernfalls fehlschlagen.</b> </span>",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"Kundenregistrierung erfolgreich abgeschlossen.",
      "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"Kundenregistrierung erfolgreich abgeschlossen. Melden Sie sich beim Portal an.",
      "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Bitte fügen Sie ein Angebot für die Anpassung bei",
      "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
      "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Profile synchronisieren",
      "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Kundenzahlungen",
      "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Abgerechneter Betrag",
      "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Datum",
      "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Abteilungsname",
      "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Name",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Neuer Preis",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
      "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
      "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Alter Preis",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
      "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
      "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Andere Kosten",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Abgerechneter Betrag vor Steuern",
      "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"MSFT-Rechnung vor Steuern",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Profitieren",
      "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Gewinn%",
      "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Seite-Name",
      "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Benutzer",
      "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Es wurden noch keine Abonnements hinzugefügt.",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Kunden löschen",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Kundeneinstellungen",
      "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Pläne verwalten",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Aktion",
      "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Name",
      "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Laden Sie nutzungsbasierte Werbebuchungen herunter",
      "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Rentabilität durch Abonnements",
      "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Profitability Report neu laden",
      "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Änderungsverlauf des Abonnements",
      "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Rechnung anzeigen",
      "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Verwendungsposten anzeigen",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"{{username}} erfolgreich zum Azure-Abonnement hinzugefügt",
      "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"{{username}} erfolgreich zum Azure-Abonnement {{Azuresubscription}} hinzugefügt",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} erfolgreich aus dem Azure-Abonnement entfernt",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Möchten Sie den Benutzer wirklich löschen?",
      "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} erfolgreich aus {{azuresubscription}} entfernt",
      "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Azure-Abonnement erfolgreich gespeichert",
      "customNotifications":"Benutzerdefinierte Benachrichtigungen",
      "customoffer":"Individuelles Angebot",
      "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Rechnungsstatus erfolgreich aktualisiert",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Bestellung erfolgreich aufgegeben. OrderId: {{orderId}}",
      "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Passwortempfänger",
      "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Empfänger E-Mail",
      "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Geben Sie durch Kommas getrennte E-Mail-Empfänger ein",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Neue Entität hinzufügen",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Stornieren",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Verwalten",
      "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"einreichen",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Neue Entität hinzufügen",
      "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Betriebseinheiten",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Entitätstyp",
      "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Name",
      "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen",
      "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Rechnung anzeigen",
      "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Neue Rechnung generiert am <b>{{InvoiceGeneratedDate}} </b>für den Abrechnungszeitraum von <b>{{BillingStartDate}} </b>bis <b>{{BillingEndDate}} </b>mit dem Betrag <b > {{InvoiceAmount}} </b>.",
      "DASHBOAR_TEXT_DATE":"Datum",
      "DASHBOAR_TEXT_EMAIL":"Email",
      "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL der Ezidebit-Vereinbarung",
      "DASHBOAR_TEXT_IP":"IP",
      "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"Microsoft-Kundenvereinbarungs-URL",
      "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL der Allgemeinen Geschäftsbedingungen des Partners",
      "DASHBOAR_TEXT_TIME":"Zeit",
      "Dashboard":"Dashboard",
      "DASHBOARD_BREADCRUMB":"Dashboard",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Kundenanalyse",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Berichte",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Lizenzänderungsbericht",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Lizenzzusammenfassungsbericht",
      "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Nutzungsbericht",
      "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
      "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Profil aktualisieren",
      "DASHBOARD_CAPTION_PARAGRAPH":"Bereitstellung von Ressourcen für ein erfolgreiches Shop mit der Leistung der Cloud.",
      "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Willkommen auf dem Cloud Market Place",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Alle SKUs im CSV",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"CSV-Datei herunterladen",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"PNG herunterladen",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"SVG herunterladen",
      "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Top 10 SKUs in CSV",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Partner",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Alles",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Letzter Abrechnungszeitraum",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 Abrechnungszeiträume",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 Abrechnungszeiträume",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 Abrechnungszeiträume",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 Monate",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 Monate",
      "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 Monate",
      "DASHBOARD_COMPLAINCE_TEXT":"Compliance-Center",
      "DASHBOARD_COST_TEXT":"Kosten",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Anzahl der Abteilungen",
      "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Abteilungen",
      "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Abteilungen",
      "DASHBOARD_HEADER_TEXT_WARNING":"Warnung!",
      "DASHBOARD_ICON_TITLE":"Stockholm-Symbole / Design / Ebenen",
      "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Aktueller Monat",
      "DASHBOARD_LBL_TEXT_NET_SEATS":"Netz",
      "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Vorheriger Monat",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Willkommen in Ihrem Lizenzportal Dashboard",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Verwenden Sie die folgenden Kacheln als Quicklinks, um Ihre Abonnements und Benutzer zu verwalten.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"In diesem Portal können Sie Ihrem Mandanten neue Benutzer hinzufügen, Lizenzen zuweisen und Ihr Kennwort zurücksetzen.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Sie können zusätzliche Lizenzen und Abonnements erwerben.",
      "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Aktualisieren Sie Ihr Profil (Rechnungsadresse und Zahlungsmethode)",
      "DASHBOARD_REVENUE_TEXT":"Einnahmen",
      "DASHBOARD_SEAT_PURCHASED_TEXT":"Sitzplatz gekauft",
      "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Anzahl der Standorte",
      "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Websites",
      "DASHBOARD_SITES_COUNT_TEXT_SITES":"Websites",
      "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Melden Sie sich am Portal an",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Azure-Portal",
      "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Melden Sie sich bei Portal an",
      "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Azure-Abonnements",
      "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Aktuelle Azure-Nutzung ab {{startDate}}",
      "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Produkte",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Lizenzabonnements",
      "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Mehr sehen",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Office 365",
      "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Melden Sie sich bei Portal an",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Office 365-Berichte",
      "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Melden Sie sich bei Portal an",
      "DASHBOARD_TAIL_1_TEXT_USERS":"Benutzer",
      "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Mehr sehen",
      "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TREND DIESEN MONAT",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Gescheitert",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"In Bearbeitung",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"Nicht erhalten",
      "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Empfangen",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":"Datum:",
      "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":"Sitzplätze:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":"Datum:",
      "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":"Produkte:",
      "DASHBOARD_TOOLTIP_LAST_SYNC":"Letzte Synchronisierung {{LastSyncMessage}}",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Neue Rechnung generiert am <b>{{InvoiceGeneratedDate}} </b>für den Abrechnungszeitraum von <b>{{BillingStartDate}} </b>bis <b>{{BillingEndDate}} </b>mit dem Betrag <b > {{InvoiceAmount}} </b>.",
      "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"um die Rechnung anzuzeigen. Ignorieren, wenn bereits bezahlt.",
      "DASHBOARD_WARNING_MESSAGE":"Sie müssen Ihr Zahlungsprofil aktualisieren, bevor Sie C3 verwenden können. Bitte klicken Sie zum Aktualisieren auf die folgende Schaltfläche.",
      "DATA_PUSHED_DATES":"Einige oder alle Daten wurden zuvor übertragen, bitte überprüfen Sie die vorherigen Protokolle.",
      "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Artikelbeschreibung",
      "DAYS":"Tag(e)",
      "DEACTIVATE_CONFORMATION":"Sind Sie sicher, dass Sie deaktivieren möchten?",
      "DEACTIVATE_CUSTOMER_PRODUCT":"Kundenprodukt konnte nicht deaktiviert werden",
      "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Kundenprodukt deaktiviert",
      "DEACTIVATE_PRODUCT_EXCEPTION":"Dieser Benutzer hat keine Berechtigung, dieses Angebot zu entfernen",
      "DECEMBER":"Dezember",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Aktivieren Sie diese Konfiguration, um ein Raster/eine Liste als Standardansicht auf der Produktseite für Kunden festzulegen",
      "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Standardansicht für Kundenprodukte",
      "DEFAULT_COTERMINOSITY_TYPE_DESC":"Wenn Sie hier einen Wert auswählen, wird dieser als Standard für die Coterminosity-Funktion beim Abonnementkauf festgelegt.",
      "DEFAULT_COTERMINOSITY_TYPE_DISP":"Coterminositätstyp",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Liste der Gegenminiositätstypen",
      "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Gegenminiositätstyp",
      "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Löschen Sie alle Add-Ons",
      "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Beim Löschen der Währungsumrechnung ist ein Fehler aufgetreten",
      "DELETE_CUSTOMER_FAILURE":"Aktualisierung des Kundennamens fehlgeschlagen",
      "DELETE_CUSTOMER_SUCCESS":"Kunde wird erfolgreich gelöscht.",
      "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Beim Löschen der E-Mail-Benachrichtigungsempfänger ist ein Fehler aufgetreten",
      "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Die ausgewählte Rechnungsanpassung wird gelöscht. Wollen Sie fortfahren?",
      "DELETE_PARTNER_BUNDLE_TOOLTIP":"Bündelprodukt löschen",
      "DELETE_PARTNER_OFFERS":"Partnerangebote löschen",
      "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Löschen der Steuerdetails ist ein Fehler aufgetreten",
      "DELETE_PARTNER_USER":"Partnerbenutzer löschen",
      "DELETE_PARTNER_USER_FAILURE":"Fehler beim Löschen des Partnerbenutzers",
      "DELETE_PONUMBER":"Bestellnummer löschen",
      "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt löschen",
      "DELETE_QUOTE_SUCCESSFULLY":"Angebot erfolgreich gelöscht",
      "DELETE_RECORD_CONFIRMATION_PROMPT":"Möchten Sie diesen Datensatz wirklich löschen?",
      "DELETE_USER_EXCEPTION":"Dieser Benutzer hat Zugriff auf C3. Entfernen Sie diese",
      "DEPARTMENT":"(Abteilung)",
      "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Hauptüberschrift des öffentlichen Anmeldebanners",
      "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Überschrift des öffentlichen Anmeldebanners",
      "DESC_CUSTOMER_CURRENCY_OPTION":"Kundenwährung",
      "DESC_CUSTOMER_DASHBOARD":"Kunden-Dashboard",
      "DESC_PARTNER_DASHBOARD":"Partner-Dashboard",
      "DESC_PLAN_CURRENCY_OPTION":"Währung planen",
      "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"Website-URL für den Admin-Hyperlink in der öffentlichen Anmeldenavigationsleiste",
      "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Hauptüberschrift im öffentlichen Anmeldebanner",
      "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Der Absatz, der auf dem öffentlichen Anmeldebanner unter der Bannerüberschrift erscheint",
      "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Website-URL für den Website-Hyperlink in der öffentlichen Anmelde-Navigationsleiste",
      "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Kontaktiere uns",
      "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":"Website-URL für den Hyperlink des Lernportals in der öffentlichen Anmeldenavigationsleiste",
      "DESC_PUBLIC_SIGNUP_SUPPORT":"Website-URL für den Support-Hyperlink in der öffentlichen Anmeldenavigationsleiste",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Öffentliche Registrierungsbedingungen 1",
      "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Allgemeine Geschäftsbedingungen für die Anmeldung 2",
      "DESC_SHOW_INVOICES_TO_CUSTOMER":"Aktivieren Sie diese Option, um dem Kunden Rechnungen anzuzeigen",
      "Description":"Beschreibung",
      "DESCRIPTION_FOR":"Beschreibung für",
      "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt deaktivieren",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Altes und neues sicheres Modell deaktiviert",
      "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Danke für die Bestätigung. Die Anwendung greift gemäß den neuen Sicherheitsrichtlinien auf Ihr Partner Center zu.",
      "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Deaktivieren des alten und Aktivieren des neuen sicheren Modells fehlgeschlagen",
      "DISCOUNT_MAX_ERROR":"Der Wert muss kleiner oder gleich 100 sein.",
      "DISCOUNT_MIN_ERROR":"Der Wert muss größer oder gleich 0 sein",
      "DISP_CATEGORY_COUPON_ENTITY":"Kategorie",
      "DISP_PLANOFFER_COUPON_ENTITY":"Planangebot",
      "DISP_PROVIDER_COUPON_ENTITY":"Anbieter",
      "DISP_SALETYPE_COUPON_ENTITY":"SaleType",
      "DISP_SHOW_INVOICES_TO_CUSTOMER":"Rechnungen dem Kunden zeigen",
      "DisplayCurrency":"Anzeigewährung",
      "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Laufzeit",
      "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Zurücksetzen",
      "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Geben Sie einen positiven Wert ein",
      "DISTRIBUTOR_OFFER_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
      "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
      "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Neues Vertriebsangebot",
      "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Zurück zum Händlerangebot",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Händlerangebote",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Kategorie",
      "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Händlerangebote",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Aktion",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Abrechnungsarten",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Kategorie",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Art des Abrechnungszeitraums",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Selbstkostenpreis (ab)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Selbstkostenpreis (bis)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Name",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Alter Selbstkostenpreis",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Alter Verkaufspreis",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Symbol",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Anbieter",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Verkaufspreis (ab)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Verkaufspreis (bis)",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
      "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Begriff",
      "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Händlerangebot {{distributorOffer}} erfolgreich gespeichert",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"Name",
      "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Name",
      "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Das Händlerangebot {{distributorOffer}} wurde erfolgreich aktualisiert",
      "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Account Manager erfolgreich löschen",
      "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"Möchten Sie stornieren?",
      "DOCUMETATION_HEADING":"Dokumentation",
      "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Währungscode",
      "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Einheit",
      "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Abgerechneter Betrag",
      "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Qualifizierter Name",
      "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Fortschritt & Link herunterladen",
      "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Rechnungen herunterladen",
      "DOWNLOAD_COLUMN_BUNDLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Abrechnungsregel für den Kauf von Sitzplätzen",
      "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Abrechnungsregel für Sitzplatzreduzierung",
      "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Selbstkostenpreis",
      "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Verkaufsart",
      "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Beschreibung",
      "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"Ist Autor erneuerbar",
      "DOWNLOAD_COLUMN_BUNDLE_NAME":"Bündelname",
      "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Produktname",
      "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Verkaufspreis",
      "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Status",
      "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Unterstützter Markt",
      "DOWNLOAD_COLUMN_BUNDLE_TERM":"Begriff",
      "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Bericht „Bundle-Produkte“",
      "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Name des aktiven Gutscheins",
      "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Anwendbarkeit",
      "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Zugewiesen am",
      "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Gutscheincode",
      "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Gutscheinbeschreibung",
      "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Kundenname",
      "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Rabatt",
      "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Läuft aus am",
      "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"Automatische Zahlung",
      "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"Rechnungsreferenz-ID",
      "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Marktcode",
      "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Währungscode",
      "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Domain Name",
      "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Name",
      "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Bezahlverfahren",
      "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Planen",
      "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Ist Testangebot",
      "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Anmeldedatum",
      "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Stichworte",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Mieter-ID",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Name des Mieters",
      "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Mieter",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Ist ein Testangebot",
      "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Rechnungsnummer des Anbieters",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Rechnungsreferenznummer",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Enddatum der Gebühr",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Startdatum der Gebühr",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Wechselkurs",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Kosten für Partner",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Währungscode",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"ERP-Kunden-ID",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Rabattbetrag",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Domain Name",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"Dauerhafte Angebots-ID",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Effektiver Stückpreis nach MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Rechnungsdatum",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Rechnungsnotizen",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Rechnungsnummer",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Details zur Rechnungszeile",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Produktcode",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Name",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Angebotsname",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"Bestellnummer",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Steuerbetrag buchen",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Vorsteuerbetrag",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Angebots-ID",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Anteilige Stückkosten",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Anbieter",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Menge",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Enddatum des Abonnements",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Abonnement gültig",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Abonnement-ID",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Abonnementname",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Abonnementlaufzeit",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Abonnementtyp",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Abonnementnummer des Syndication-Partners",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Steuerbetrag",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Steuer-Code",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Mieter-ID",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Name des Mieters",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Gesamtmenge",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Gesamtbetrag für Partner nach MS",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Stückkosten pro Produkt",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Kosten pro Einheit für Partner",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Stückpreis",
      "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Stückpreis nach MS",
      "DOWNLOAD_COLUMN_MARKET_REGION":"Unterstützte Märkte",
      "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Ist Testangebot",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Zur Bündelung verfügbar",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Sofort zum Verzehr verfügbar",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Abrechnungsregel für den Kauf von Sitzplätzen",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Abrechnungsregel für Sitzplatzreduzierung",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Abrechnungsart",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Verbrauchsart",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Selbstkostenpreis",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Beschreibung",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Name",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Verkaufspreis",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Verkaufsart",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Status",
      "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Begriff",
      "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Währungscode",
      "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Beschreibung",
      "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Rechnungswährung",
      "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"Ist öffentlich",
      "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Makrodetails",
      "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Makrowert",
      "DOWNLOAD_COLUMN_PLAN_NAME":"Name",
      "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Kategorie Abrechnungszeitraum",
      "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Abrechnungsart",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Kategorie",
      "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Kosten für den Partner",
      "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Währungscode",
      "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Wirksamkeit",
      "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Freundlicher Angebotsname",
      "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"Die letzte Preisänderung erfolgt am",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Makroname",
      "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Makrowert",
      "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Plan Name",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"Angebots-ID",
      "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Angebotsname",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Plan Name",
      "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Anbieter",
      "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Endverbraucherpreis",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Verkaufspreis",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Servicetyp",
      "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Status",
      "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Unterstützter Markt",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Begriff",
      "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Laufzeit",
      "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Währung kaufen",
      "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"URL teilen",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Ist zum Kauf verfügbar",
      "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Ist Testangebot",
      "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Verfügbar für automatische Freigabe",
      "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Kategorie",
      "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Verbrauchsart",
      "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Abteilungsname",
      "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Rabatt",
      "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Domain Name",
      "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Effektiver Produktpreis",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Produktname",
      "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Produktsequenz",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Anbieter",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Name der Anbieterkategorie",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Erstellungsdatum des Anbieters",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Enddatum des Anbieters",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Startdatum des Anbieters",
      "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"Produkt-ID des Anbieters",
      "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Menge",
      "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Seite-Name",
      "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Status",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Provider Mieter ID",
      "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Name des Mieters",
      "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Stückpreis",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Kategorie",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Kosten für den Partner",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Währungscode",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Ist Testangebot",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Produkt-ID",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Produktname",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Anbieterkategorie",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Anbietername",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Region",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Verkaufspreis",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Produktkatalogbericht",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Gültigkeit",
      "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Gültigkeitsart",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Rechnungskreislauf",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Kategoriename",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Erstellen von",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Erstellungsdatum",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Währungscode",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Kundenname",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Domain Name",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Einheit",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"Ist Addon",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Name des Unternehmens",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Neuer Preis",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Neue Menge",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Neuer Status",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Alter Preis",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Alte Menge",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Alter Status",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Produktname",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Anbieter",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Anbieterkategorie",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Wiederverkäufer neuer Preis",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Wiederverkäufer alter Preis",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Abonnement-ID",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Mieter-ID",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"Art der Änderung",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Gültigkeit",
      "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Gültigkeitsart",
      "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Wählen Sie mindestens eine Spalte aus",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Herunterladen",
      "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Gutscheine herunterladen",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Kunden herunterladen",
      "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Produkte herunterladen",
      "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Pläne herunterladen",
      "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Planangebote herunterladen",
      "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Rechnungsposten",
      "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Rechnungszahlungen",
      "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Wählen Sie mindestens eine Spalte aus",
      "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"CSV-Datei herunterladen",
      "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Laden Sie nicht eingebundene Kunden herunter",
      "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "DOWNLOAD_POPUP_GRID_HEADER":"Rasterbericht herunterladen",
      "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"Es sind keine Daten zum Herunterladen im CSV-Format verfügbar",
      "DOWNLOAD_PRODUCTS_GRID":"Produkte herunterladen",
      "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Laden Sie Software und Schlüssel herunter",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Mehrere hinzufügen",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Mehrere verknüpfen",
      "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Link Single",
      "DROPDOWN_PLACEHOLDER_SELECT":"Wählen",
      "DUE_STATUS_DUE":"Fällig",
      "DUE_STATUS_FINALIZED":"Die Rechnung wurde fertiggestellt",
      "DUE_STATUS_OVERDUE":"Überfällig",
      "DUE_STATUS_PAID":"Bezahlt",
      "DUE_STATUS_PARTIAL_PAID":"Teilweise bezahlt",
      "earningsreport":"Ergebnisbericht",
      "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Rechnungsinformationen bearbeiten",
      "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Gruppe bearbeiten",
      "EDIT_INVOICE_BUTTON_TEXT":"Rechnung bearbeiten",
      "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Produkt",
      "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Zusammenfassung",
      "EDIT_PONUMBER":"Bestellnummer bearbeiten",
      "EffectivenessTypeId":"Wirksamkeitstyp-ID",
      "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Bitte stellen Sie ein Bild mit einer maximalen Auflösung von 300 x 100 für das E-Mail-Logo bereit",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"Die im Kundenprofil als Rechnungskontakt angegebene E-Mail-Adresse",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Liste der E-Mail-Adressen der Personen, die per E-Mail benachrichtigt werden sollen",
      "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Andere Rollen als HelpDesk",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"Abrechnungskontakte",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Menschen",
      "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Rolle",
      "EMAIL_TABLE_HEADER_ACTIONS":"Aktionen",
      "EMAIL_TABLE_HEADER_EMAIL":"Email",
      "EMAIL_TABLE_HEADER_EMAIL_TYPE":"E-Mail-Typ",
      "EMAIL_TEMPLATE":"Vorschau",
      "EMAIL_TEMPLATE_LABEL":"Vorlagen",
      "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Vorlage auswählen",
      "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"E-Mail-Vorlage erfolgreich aktualisiert",
      "EMAIL_TEMPLATE_SUBMIT":"einreichen",
      "EMAIL_TYPE_BILLING":"Abrechnung",
      "EMAIL_TYPE_COMPANY":"Unternehmen",
      "EMAIL_TYPE_INVOICE":"Rechnung",
      "EMAIL_TYPE_PERSONAL":"persönlich",
      "EMAIL_TYPE_SHIPPING":"Versand",
      "EmailConfigurations":"SMTP",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"Die Konfiguration ist so konzipiert, dass die Preisgestaltung auf Berechtigungsebene möglich ist. Nach der Aktivierung erstellen wir Rechnungen auf Grundlage der Berechtigungsebenen, unabhängig vom Rechnungstyp. Bitte bestätigen Sie, dass dies für Sie akzeptabel ist.",
      "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Aktivieren Sie die Preisgestaltung auf Berechtigungsebene",
      "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt aktivieren",
      "ENABLE_RESELLER_PLAN":"Plan dem Reseller zuweisen",
      "ENABLE_RESELLER_PLAN_FAILURE":"Plan konnte dem Reseller nicht zugewiesen werden",
      "END_BILLING_PERIOD":"Abrechnungszeitraum beenden",
      "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"Das Enddatum sollte nach dem Startdatum liegen",
      "END_DATE_REQUIRED_MESSAGE":"Enddatum ist erforderlich",
      "ENGAGE_BREADCRUMB_ADD":"Engagement schaffen",
      "ENGAGE_BREADCRUMB_LIST":"Engagement-Liste",
      "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Farbe des Call-to-Action-Schaltfläche",
      "ENGAGE_BUTTON_TEXT_COLOR":"Textfarbe der Schaltfläche",
      "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Farbe des Schaltflächentextes",
      "ENGAGE_BUTTON_URL_PLACEHOLDER":"Call-to-Action-Button-Hyperlink",
      "ENGAGE_CTA_BUTTON_COLOR":"Farbe der CTA-Schaltfläche",
      "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Text der Call-to-Action-Schaltfläche",
      "ENGAGE_CUSTOMER_PAGENAME":"KundenDashboard",
      "ENGAGE_EFFECTIVE_FROM":"Gültig ab",
      "ENGAGE_EXPIRES_ON":"Läuft aus am",
      "ENGAGE_HEADER_ACTIONS":"Aktionen",
      "ENGAGE_HEADER_ACTIONS_ADD":"Engagement schaffen",
      "ENGAGE_HEADER_ACTIONS_BACK":"Zurück zur Liste",
      "ENGAGE_HEADER_EFFECTIVE_FROM":"Gültig ab",
      "ENGAGE_HEADER_ENTITES":"Entitäten",
      "ENGAGE_HEADER_EXPIRES_ON":"Läuft aus am",
      "ENGAGE_HEADER_MESSAGE":"Botschaft",
      "ENGAGE_HEADER_PAGE":"Buchseite",
      "ENGAGE_HEADER_TITLE":"Titel",
      "ENGAGE_NOTIFICATION_BACKGROUND":"Hintergrund",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Marketing &amp; Verkaufsprodukt Vertrauen.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
      "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Beginnen Sie mit einem modernen Website-Design und passen Sie es mit Ihren Branding-Inhalten und Funktionen an. Alle Premium-Blogs enthalten benutzerdefiniertes CSS.",
      "ENGAGE_NOTIFICATION_BODY_TEXT":"Nachrichtentext",
      "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"Engage-Benachrichtigung erfolgreich erstellt",
      "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Dies überschreitet die Mindestlänge von 30",
      "ENGAGE_NOTIFICATION_PORTAL_PAGE":"Portalseite",
      "ENGAGE_NOTIFICATION_TITLE_TEXT":"Titeltext",
      "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"Engage-Benachrichtigung erfolgreich aktualisiert",
      "ENGAGE_PARTNER_PAGENAME":"Partner-Dashboard",
      "ENGAGE_POPUP_BUTTON_URL":"Schaltflächen-URL",
      "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Text der CTA-Schaltfläche",
      "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Wählen Sie alle Kunden aus",
      "ENGAGE_SUBMIT":"einreichen",
      "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":"Der Text überschreitet die maximale Länge von 15",
      "ENGAGE_THE_MAX_LENGTH":"Die maximale Länge beträgt 250",
      "ENGAGE_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Bitte wählen Sie mindestens eine Rechnung für die Veröffentlichung in QuickBooks aus.",
      "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Ihre Anfrage, Rechnungen an QuickBooks zu senden, wird angenommen und zur Verarbeitung in die Warteschlange gestellt. Klicken Sie auf die Schaltfläche <strong> Beitragsprotokolle anzeigen </strong>, um Informationen zum Fortschritt zu erhalten.",
      "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Die Anforderung zum Hochladen von Rechnungen in QuickBooks online konnte nicht in die Warteschlange gestellt werden",
      "ENQUEUED_REQUEST_TO_CLONE_PLAN":"In die Warteschlange gestellte Anforderung zum Klonen des Plans",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Enqueued Anfrage, einen neuen Plan mit allen Angeboten zu erstellen",
      "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Anfrage in die Warteschlange gestellt, um einen neuen Reseller-Plan mit allen Angeboten zu erstellen",
      "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Die Anforderung zum Hochladen von Rechnungen zu Xero konnte nicht in die Warteschlange gestellt werden",
      "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":"Geben Sie nach dem Komma eine E-Mail ein oder entfernen Sie das zusätzliche Komma",
      "ENTER_AZURE_NEW_MARKUP_VALUE":"Geben Sie einen neuen Markup-Wert ein",
      "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Geben Sie den Grund für den neuen Markup-Wert ein",
      "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Geben Sie den Grund für die Aktualisierung des Markup-Werts ein",
      "enterAddress1":"Adresse 1 eingeben",
      "enterAddress2":"Adresse 2 eingeben",
      "enterCity":"Stadt eingeben",
      "enterEmail":"E-Mail eingeben",
      "enterPhoneNumber":"Telefonnummer eingeben",
      "enterState":"Bundesland eingeben",
      "enterZip":"PLZ eingeben",
      "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Anzahl der zu erstellenden Berechtigungen, die {{Count}} nicht überschreitet",
      "ENTITLEMENT_MANAGEMENT":"Azure-Berechtigungsverwaltung",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":"Die Anzahl der zu erstellenden Berechtigungen darf nicht überschritten werden",
      "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Berechtigungsnamen beginnen damit.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Anleitung",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Wenn der Berechtigungspreis überschrieben wird, gilt dieser Preis sowohl für Rechnungsberechnungen als auch für Azure-Schätzungen.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Hinweis:</b>",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Verwenden Sie die Schaltfläche <b>Überschreiben</b>, um den Berechtigungspreis zu ändern.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Verwenden Sie die Schaltfläche <b>Zurücksetzen</b>, um den Preis für die Berechtigung auf den Standardpreis des Azure-Plans zurückzusetzen.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Verwenden Sie <b>Exportieren</b>, um Berechtigungsdaten für eine bestimmte Kunden- und Mandantenebene herunterzuladen.",
      "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Rufen Sie Berechtigungsdaten für alle aktiven Kunden gleichzeitig ab, indem Sie <b>Alle exportieren</b> wählen.",
      "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Aktion",
      "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Berechtigung hinzufügen",
      "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Berechtigungen hinzufügen",
      "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Komplett",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Erstellungsdatum",
      "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Erstellungsstatus",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Abteilung",
      "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Abteilungsname",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Anzahl der Berechtigungen",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"Ich würde",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Berechtigungsliste",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Name",
      "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Neuen Preis für Anspruch festlegen",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Export",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Alles exportieren",
      "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Export",
      "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Dieser Preis ist ab dem Zeitpunkt der nächsten Rechnungserstellung gültig und bleibt danach gültig.",
      "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Verkaufspreis",
      "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Sequenz starten",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Seite ",
      "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Seite-Name",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":"Vorgang abgeschlossen. Weitere Einzelheiten finden Sie im Überwachungsprotokoll.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Anfrage zum Hinzufügen von Berechtigungen wird angenommen und verarbeitet",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"Anfrage zum Hinzufügen von Berechtigungen fehlgeschlagen.",
      "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Bitte geben Sie eine Zahlenfolge an",
      "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"Möchten Sie den Verkaufspreis für diesen Anspruch wirklich zurücksetzen?",
      "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"Anspruchspreis erfolgreich geändert.",
      "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"Der Berechtigungspreis wurde erfolgreich zurückgesetzt.",
      "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"Das fortlaufende Inkrement wird als Suffix in Berechtigungsnamen verwendet.",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Status der Berechtigungserstellung",
      "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Bitte warten Sie, während die Berechtigungen erstellt werden.",
      "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"Der Wert muss größer oder gleich -100 und kleiner oder gleich 100 sein.",
      "ENTITY_DESC_AZURE_GROUP":"Azure-Gruppe",
      "ENTITY_DESC_BILLING_PERIODS":"Abrechnungszeiträume",
      "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"Reseller-ID des Abrechnungsanbieters",
      "ENTITY_DESC_BILLING_PROVIDER_USER":"Benutzer des Abrechnungsanbieters",
      "ENTITY_DESC_CART":"Wagen",
      "ENTITY_DESC_CART_LINE_ITEM":"Warenkorb-Werbebuchung",
      "ENTITY_DESC_COUPON":"Coupon",
      "ENTITY_DESC_CURRENCY":"Währung",
      "ENTITY_DESC_CUSTOM_OFFER":"Individuelles Angebot",
      "ENTITY_DESC_CUSTOMER":"Kunde",
      "ENTITY_DESC_CUSTOMER_PRODUCT":"Kundenprodukt",
      "ENTITY_DESC_CUSTOMER_USER":"Kundenbenutzer",
      "ENTITY_DESC_DEPARTMENT":"Abteilung",
      "ENTITY_DESC_INVOICE":"Rechnung",
      "ENTITY_DESC_JOB_LOGS":"Jobprotokolle",
      "ENTITY_DESC_JOBS":"Arbeitsplätze",
      "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Kunde im örtlichen Geschäft",
      "ENTITY_DESC_LOCAL_STORE_RESELLER":"Wiederverkäufer im örtlichen Geschäft",
      "ENTITY_DESC_PARTNER":"Partner",
      "ENTITY_DESC_PARTNER_SETTINGS":"Partnereinstellungen",
      "ENTITY_DESC_PARTNER_USER":"Partnerbenutzer",
      "ENTITY_DESC_PAYMENT_PROFILE":"Zahlungsprofil",
      "ENTITY_DESC_PLAN":"Planen",
      "ENTITY_DESC_PLAN_PRODUCT":"Produkt planen",
      "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Produktgutschein planen",
      "ENTITY_DESC_PRODUCT":"Produkt",
      "ENTITY_DESC_PRODUCT_VARIANT":"Produktvariante",
      "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Produktvarianten-Gutschein",
      "ENTITY_DESC_PROVIDER_CUSTOMER":"Anbieterkunde",
      "ENTITY_DESC_RESELLER":"Wiederverkäufer",
      "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Zahlungsprofil des Wiederverkäufers",
      "ENTITY_DESC_RESELLER_PLAN":"Reseller-Plan",
      "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Kunde des Dienstleisters",
      "ENTITY_DESC_SHOP":"Shop",
      "ENTITY_DESC_SITE":"Seite ",
      "ENTITY_DESC_SITE_DEPARTMENT":"Standortabteilung",
      "ENTITY_DESC_TAG":"Etikett",
      "ENTITY_DESC_TAX":"Steuer",
      "ENTITY_DESC_TERMS_AND_CONDITIONS":"Geschäftsbedingungen",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Aktualisieren Sie die Anbietereinstellungen",
      "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Fehler beim Aktualisieren der Anbietereinstellungen",
      "ENTITY_MAPPING":"Entitätszuordnung",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"Möchten Sie die Zuordnung wirklich freigeben?",
      "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für <b> {{customerName}}</b> wirklich speichern?",
      "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Mindestens 1 Produkt zuordnen",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Diese Verträge sind nicht mehr Standard: {{ContractNames}}",
      "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"Eine doppelte Sequenznummer ist nicht zulässig",
      "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Zuordnungen erfolgreich gespeichert",
      "ENTITY_NAME":"Entitätsname",
      "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Aktionen",
      "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
      "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"Land",
      "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Email",
      "ENVIRONMENTS_TABLE_HEADER_STATUS":"Status",
      "ERROR__DATA_CONTACT_TYPE":"kontaktart",
      "ERROR__DATA_PAYMENT_TYPE":"Zahlungsart",
      "ERROR__DATA_PROFILE_TYPE":"Profiltyp",
      "ERROR__DATA_URL":"URL",
      "ERROR_AVAILABLE_IN_BATCH":"Fehler ist im Stapel verfügbar.",
      "ERROR_BILLING_PROVIDER_NAME":"Name des Abrechnungsanbieters",
      "ERROR_BUTTON_CONTINUE":"Fortsetzen",
      "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Kundenprodukt-ID",
      "ERROR_C3_ENTITY_NAME":"Entitätsname",
      "ERROR_C3_RECORD_ID":"Datensatz-ID",
      "ERROR_C3_USER_ID":"Benutzeridentifikation",
      "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Ungültige Warenkorbmenge",
      "ERROR_DATA_BILLING_CUSTOMER_ID":"Kunden-ID abrechnen",
      "ERROR_DATA_BILLING_REFERENCE_ID":"Rechnungsreferenz-ID",
      "ERROR_DATA_C3_CUSTOMER_ID":"Kunde C3 Id",
      "ERROR_DATA_C3_RECORD_ID":"Datensatz-ID",
      "ERROR_DATA_C3_RESELLER_ID":"Reseller C3 Id",
      "ERROR_DATA_C3_SUBSCRIPTION_ID":"C3 Abonnement-ID",
      "ERROR_DATA_COUNTRY_CODE":"Landesvorwahl",
      "ERROR_DATA_CUSTOMER_NAME":"Kundenname",
      "ERROR_DATA_DOMAIN_PREFIX":"Domain Name",
      "ERROR_DATA_EMAIL_ID":"E-Mail-ID",
      "ERROR_DATA_ENTITY_NAME":"Entitätsname",
      "ERROR_DATA_EVENT_NAME":"Veranstaltungsname",
      "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Unterstützt Wiederverkäufer mit MPN-ID",
      "ERROR_DATA_INPUT_JSON":"Eingabedaten",
      "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "ERROR_DATA_PROVIDER_NAME":"Anbietername",
      "ERROR_DATA_RESELLER_ID":"Referenz ID",
      "ERROR_DATA_RESELLER_MPNID":"Reseller Provider Id",
      "ERROR_DATA_RESELLER_PROVIDER_ID":"Reseller Provider Id",
      "ERROR_DATA_SEARCH_VALUE":"Suchwert",
      "ERROR_DATA_SUBSCRIPTION_ID":"Abonnement-ID",
      "ERROR_DATA_TAX_CODE":"Steuer-Code",
      "ERROR_DATA_TRANSACTION_REFERENCE":"Transaktionsreferenz",
      "ERROR_DATA_USER_ID":"Benutzeridentifikation",
      "ERROR_DELETE_DEPARTMENT":"Diese Abteilung enthält zugehörige Datensätze, sodass dieser Datensatz nicht gelöscht werden kann.",
      "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Diese Abteilung enthält zugehörige gekaufte Produkte, daher kann dieser Datensatz nicht gelöscht werden.",
      "ERROR_DELETE_SITE":"Diese Site enthält zugehörige Datensätze, sodass dieser Datensatz nicht gelöscht werden kann.",
      "ERROR_DELETE_SITE_PRODUCT":"Diese Website enthält zugehörige gekaufte Produkte, daher kann dieser Datensatz nicht gelöscht werden.",
      "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Mandant im Anbieter kann nicht erstellt werden.",
      "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Agentenname darf nicht null sein",
      "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Keine Antwort von Authorize.net",
      "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Kunde konnte nicht gefunden werden.",
      "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Dieses Produkt kann in Ihren Abonnements nicht gefunden werden",
      "ERROR_DESC_BAD_INPUT":"Es scheint etwas mit Ihrer Eingabe nicht zu stimmen",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
      "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
      "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
      "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Beim Speichern benutzerdefinierter Benachrichtigungsdetails ist ein Fehler aufgetreten",
      "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Beim Herunterladen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"Der Kundenabruf ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
      "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
      "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Beim Abrufen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"Aktion fehlgeschlagen, da das Zahlungsprofil fehlt.",
      "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"Aktion fehlgeschlagen, da die Konfiguration des Zahlungsprofils fehlt",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
      "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Beim Speichern Ihres Zahlungsprofils ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Es scheint ein Problem mit Ihren Zahlungsprofilen zu geben. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Es scheint ein Problem mit Ihrem Abrechnungsanbieter zu geben. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Es gibt ein Problem beim Speichern Ihres Zahlungsprofils. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Beim Abrufen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Anbieter konnte nicht gefunden werden",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
      "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
      "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Service ist nicht implementiert. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Es scheint ein Problem mit Ihren unterstützten Zahlungsarten für den Abrechnungsanbieter zu geben. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Beim Speichern der Webhook-Benachrichtigungsdetails ist ein Fehler aufgetreten",
      "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Ungültige Anfrage - ein anderer Auftrag wird ausgeführt",
      "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Beim Erstellen/Aktualisieren des Rechnungskunden ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
      "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Bulk Onboard Reseller nicht unterstützt hat Partner, die Reseller mit MPN-ID nicht unterstützen",
      "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"Der Warenkorb enthält fehlerhafte Werbebuchungen. Bitte reparieren Sie sie, um mit diesem Kauf fortzufahren.",
      "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Dieses Abonnement wird bereits von diesem Kunden verwendet",
      "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Beim Ändern des Kundenplans ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Beim Abrufen von Quellplänen für die Änderungsplanfunktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Beim Abrufen von Zielplänen für die Änderungsplanfunktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"Das Enddatum der Provision darf nicht vor dem Startdatum der Provision liegen",
      "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Enddatum der Provision darf nicht null sein",
      "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Provisionsprozentsatz darf nicht null sein",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Fehler von ConnectWise Manage beim Versuch, den Löschzusatz nach ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Fehler von ConnectWise Manage beim Versuch, das Hinzufügen nach ID und Vereinbarungs-ID zu erhalten",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Fehler von ConnectWise Manage beim Versuch, Ergänzungen nach Vereinbarungs-ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Vertragsdetails nach ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Fehler von ConnectWise Manage beim Versuch, Vereinbarungen zu erhalten",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Vertragsdetails nach ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Fehler von ConnectWise Manage beim Versuch, Vereinbarungen zu erhalten",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Katalogelement nach ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Fehler von ConnectWise Manage beim Versuch, Katalogelemente abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Kundendaten nach ID abzurufen",
      "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Fehler von ConnectWise Manage beim Versuch, Kunden zu gewinnen",
      "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Doppelte Einträge in importierten Zahlungen",
      "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Doppelte Einträge im Katalog",
      "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Doppelte Einträge im Katalog",
      "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"In der Eingabe ist ein doppelter Eintrag vorhanden",
      "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Das Formular enthält Fehler",
      "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Beim Initiieren der Entitätsdatenreferenzierung von einem externen Dienst ist ein Fehler aufgetreten",
      "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId ist ungültig. Bitte registrieren Sie Ihre Anwendung als native App unter https://dev.powerbi.com/apps und wenden Sie sich an den Support.",
      "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"Die Gruppen-ID ist ungültig. Bitte wenden Sie sich an das Support-Team.",
      "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"Das Passwort ist ungültig. Bitte wenden Sie sich an das Support-Team.",
      "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"Der Bericht ist ungültig. Bitte wenden Sie sich an das Support-Team.",
      "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"Der Benutzername ist ungültig. Bitte wenden Sie sich an das Support-Team.",
      "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Beim Löschen des Kunden ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Beim Hochladen Ihres Bildes ist ein Fehler aufgetreten. Bitte überprüfen Sie die Bildabmessungen noch einmal.",
      "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Fehlgeschlagen aufgrund einer schlechten Anfrage. Bitte versuche es erneut.",
      "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Fehlgeschlagen aufgrund eines Konflikts. Bitte überprüfen Sie Ihre Anmeldeinformationen erneut",
      "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Gateway-Zeitüberschreitung. Bitte versuche es erneut.",
      "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Dienst nicht verfügbar. Bitte überprüfen Sie die Konfiguration",
      "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Beim Einleiten der Zahlung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Beim Entschlüsseln des Werts ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Beim Verschlüsseln des Werts ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"AD APP-ID oder Partner-Mandanten-ID des Anbieters der Systemsteuerung sind nicht vorhanden.",
      "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"Microsoft-Preis-API-AD-APP-ID oder Partner tenant-ID sind nicht vorhanden.",
      "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Beim Versuch, mehrere Zahlungen zu importieren, ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Beim Versuch, die Katalogeingabe zu importieren, ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Beim Versuch, Produktprovisionen zu importieren, ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Details zur Produkterweiterung konnten nicht importiert werden",
      "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"Reseller konnte aufgrund von Validierungsfehlern nicht unter Bulk-Onboarding aufgenommen werden",
      "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Beim Platzieren der Registrierungsanfrage ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"E-Mail konnte nicht an die angegebene Benutzerliste gesendet werden. Eine Überarbeitung Ihrer SMTP-Konfigurationen kann helfen, dieses Problem zu lösen.",
      "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Beim Versuch, die Zahlungsdatensatzeingabe zu importieren, ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Beim Staging der Produktkatalogeingabe ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Beim Bereitstellen der Produktprovisionsdetails ist ein Fehler aufgetreten",
      "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"Das Abonnement für reservierte Instanzen konnte aufgrund der Nichtverfügbarkeit des Produkts nicht synchronisiert werden. Bitte wenden Sie sich an das Support-Team.",
      "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"Sie können den Dezimalprozentsatz nicht auf einen Wert des verfügbaren Prozentsatzes aktualisieren.",
      "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Bild konnte nicht hochgeladen werden. Bitte überprüfen Sie Ihre Internetverbindung und stellen Sie sicher, dass die Bildgröße den angegebenen Spezifikationen entspricht.",
      "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Beim Abrufen des Bulk-Onboard-Batch-Status von Provider-Resellern ist ein Fehler aufgetreten",
      "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Beim Überprüfen des Status für Entitätsdaten, die von einem externen Dienst stammen, ist ein Fehler aufgetreten",
      "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Beim Abrufen ausstehender Zahlungen ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"Fehlende oder ungültige C3Id in der Eingabe",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Kosten für den Partner sollten +ve Zahl aus der Eingabe sein",
      "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Kosten für den Partner sollten aus der Eingabe dezimal sein",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlender oder ungültiger Währungscode in der Eingabe",
      "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Währungscode",
      "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Der Währungscode sollte mit der Produktwährung aus der Eingabe übereinstimmen",
      "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Ungültiger Währungscode in der Eingabe",
      "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende oder ungültige Kunden-ID in der Eingabe",
      "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Sie können die Nutzung für diese Anleitung nicht hochladen (Kunde)",
      "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige interne Produkt-ID im Katalog",
      "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Ungültige Sequenz im Katalog",
      "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Der Währungscode sollte mit der relevanten Entität übereinstimmen",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Ungültige Einträge im Katalog",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Ungültige Einträge im Katalog",
      "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige Einträge im Katalog",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Zahlungsbetrag",
      "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"Der Zahlungsbetrag sollte positiv sein",
      "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Ungültiges Format des Zahlungsdatums",
      "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Ungültiges Zahlungsdatum",
      "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende oder ungültige Produkt-ID in der Eingabe",
      "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Sie können die Nutzung für das Produkt nicht hochladen, da das Produkt entweder nicht existiert oder Ihnen nicht gehört.",
      "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige Anbieterprodukt-ID im Katalog",
      "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Maßeinheit sollte nicht ganzzahlig/dezimal aus der Eingabe sein",
      "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Einheiten sollten ganzzahlig von Input sein",
      "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Einheiten sollten +ve Zahl von Input sein",
      "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte das Format JJJJ-MM-TT aus der Eingabe haben",
      "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte der Datumstyp von Input sein",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte nach dem Eingabedatum liegen",
      "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"Das Zahlungsdatum sollte in der Vergangenheit liegen",
      "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"Für gekaufte Produkte wird keine Rechnung erstellt",
      "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"Artikel ist nicht käuflich",
      "ERROR_DESC_MATCHING_PLAN_OFFER":"Beim Abgleich der Planangebote für Planänderungen ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"Der Kunde wurde erfolgreich erstellt, aber die Sicherheitsgruppen konnten der GDAP-Anforderung nicht zugewiesen werden. Schließen Sie dies bitte im Partner Center ab, da weitere Aktionen andernfalls fehlschlagen.",
      "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Bei der GDAP-Anfrage ist ein Fehler aufgetreten. Arbeiten Sie bitte mit Ihrem Partner zusammen.",
      "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Ungültiger Kunde",
      "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Nichtübereinstimmung zwischen der angegebenen ProviderResellerId und der ResellerId im Anbieter",
      "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender Agentenname im Katalog",
      "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"Fehlende C3Id in der Eingabe",
      "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlendes Enddatum der Provision im Katalog",
      "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender Provisionsprozentsatz im Katalog",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende CostToPartner in der Eingabe",
      "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner ist für den Prozentsatz der Abrechnungsart erforderlich",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlender CurrencyCode in der Eingabe",
      "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Fehlender Währungscode in der Eingabe",
      "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Kunden-ID in der Eingabe",
      "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlende interne Produkt-ID im Katalog",
      "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"Fehlende Angebots-ID im Katalog",
      "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Fehlender oder ungültiger Abrechnungszeitraum im Katalog",
      "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Fehlende oder ungültige Kategorie im Katalog",
      "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Fehlender oder ungültiger Anbieter im Katalog",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Bitte überprüfen Sie die von Ihnen hochgeladene Datei, da sie offenbar nicht mit der empfohlenen Vorlage übereinstimmt",
      "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
      "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Zahlungsbetrag in der Eingabe",
      "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Fehlendes Zahlungsdatum in der Eingabe",
      "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Produkt-ID in der Eingabe",
      "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlende Anbieterprodukt-ID im Katalog",
      "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender SPCode im Katalog",
      "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Maßeinheit in der Eingabe",
      "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Einheiten in der Eingabe",
      "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlendes UsageDate in der Eingabe",
      "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"Es konnte keine Verbindung zur Microsoft Azure Management-API hergestellt werden.",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Fehler vom Microsoft Partner Center beim Aktualisieren des Kundenproduktnamens",
      "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Beim Versuch, den Abonnementstatus zu ändern, ist eine Partner Center-Ausnahme aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Fehler vom Partnercenter beim Überprüfen der MPNID. Bitte überprüfen Sie Ihre Eingabe erneut.",
      "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Beim Herstellen einer Verbindung zum Partner Center tritt ein Problem auf. Bitte lassen Sie den Administrator das Token aktualisieren und versuchen Sie es erneut. Wenden Sie sich an den Support, um weitere Unterstützung zu erhalten.",
      "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Beim Zuweisen von Sicherheitsgruppen zum delegierten Administratorzugriff des Kunden ist ein Fehler aufgetreten",
      "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Fehler vom Partnercenter beim Erstellen des Benutzers",
      "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Fehler vom Partnercenter beim Löschen des Benutzers",
      "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Beim Abrufen der Sicherheitsgruppen des Microsoft-Administrator-Agents ist ein Fehler aufgetreten",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Fehler vom Partner Center beim Versuch, alle Kunden zu gewinnen",
      "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Fehler vom Microsoft Partner Center beim Versuch, alle Reseller abzurufen",
      "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Fehler vom Partner Center beim Versuch, Ländervalidierungsregeln abzurufen",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Fehler vom Partner Center beim Versuch, Kunden zu gewinnen",
      "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Beim Abrufen der Details zur delegierten Administratorbeziehung zwischen Anbieter und Kunde vom Partner Center ist ein Fehler aufgetreten.",
      "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS Partner Center hat beim Versuch, Ihr Profil abzurufen, einen Fehler ausgegeben.",
      "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Fehler beim Abrufen des Aktualisierungstokens.",
      "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Fehler vom Partnercenter beim Abrufen des Mandantenbenutzers",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Fehler vom Partnercenter beim Abrufen der vom Benutzer zugewiesenen Lizenzen",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Fehler vom Partnercenter beim Abrufen der Benutzerdetails",
      "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Fehler vom Partnercenter beim Abrufen von Benutzerrollen",
      "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Fehler vom Partnercenter beim Abrufen von Benutzern",
      "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Fehler vom Partner Center bei der Registrierung der Kundeneinwilligung",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Fehler vom Partnercenter beim Aktualisieren des Benutzers",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Fehler vom Partnercenter beim Aktualisieren der Benutzerlizenzzuweisungen",
      "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Fehler vom Partnercenter beim Aktualisieren des Benutzerkennworts",
      "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Fehler vom Partnercenter beim Erhalten von Kunden.",
      "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Domainname ist nicht verfügbar",
      "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"Fehler beim Abrufen des Zugriffs von der Microsoft-Preis-API",
      "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Bitte überprüfen Sie noch einmal. Datei fehlt in der Anforderung zur Datenvalidierung",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"Keine Daten zum Staging für Autotask verfügbar",
      "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"Keine Daten zum Staging für ConnectWise Manage verfügbar",
      "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"Im Microsoft Partner Center wurden keine Anbieter-Reseller-Details gefunden",
      "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"In C3 wurde kein Wiederverkäufer mit MPN-ID gefunden",
      "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"Der Partner ist nicht berechtigt, einen Artikel zu kaufen",
      "ERROR_DESC_NOT_FOUND_ERROR":"Die Anfrage konnte nicht gefunden werden",
      "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Beim Planen der Bestellung ist ein Fehler aufgetreten.",
      "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"Onboading fehlgeschlagen aufgrund eines ungültigen Mandanten.",
      "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"Das Elternangebot ist für dieses Testangebot nicht verfügbar",
      "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Zahlungsprofil fehlt. Bitte geben Sie Ihr Zahlungsprofil an und versuchen Sie es erneut.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
      "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Die Verwaltung des Zahlungsprofils ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
      "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"Das Löschen des Benutzers ist aufgrund eines Fehlers im Zahlungsanbieterportal fehlgeschlagen.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Kunde mit ID konnte nicht gefunden werden",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
      "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Beim Verwalten des Rechnungskunden ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Beim Verwalten des Zahlungsprofils ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"Beim Erstellen der Transaktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Beim Aktualisieren des Zahlungsstatus ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Produktzuordnung mit falschem Kunden",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Mandanten- und Kategorieauswahl unterstützt keine bereits zugeordneten Produkte",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Kategorieauswahl unterstützt keine bereits zugeordneten Produkte",
      "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Mandantenauswahl unterstützt keine bereits zugeordneten Produkte",
      "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"Das Nutzungsdatum fällt in den Zeitraum der Produktunterbrechung",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Anbieter BusinessId ist bereits im System vorhanden",
      "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"Die BusinessId des Anbieters darf nicht null sein",
      "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"Rechnungs-E-Mail ist ungültig",
      "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"Provider Reseller Country darf nicht null sein",
      "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Die Reseller-ID des Anbieters ist bereits im System vorhanden",
      "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"Die Anbieter-Reseller-ID darf nicht null sein",
      "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"Der Reseller-Name des Anbieters darf nicht null sein",
      "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Provider-Reseller-Details sind nicht mit c3 verknüpft, bitte wenden Sie sich an Ihren Partner oder Ihr Support-Team",
      "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"Bemerkungen: Die Zeichenlänge sollte weniger als 200 betragen",
      "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Bitte überprüfen Sie noch einmal. Datei fehlt in der Anforderung zur Datenvalidierung",
      "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"Reseller ist bereits im System vorhanden",
      "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Wählen Sie mindestens ein Produkt aus",
      "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"Zahlung aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"Das Abrufen der ausstehenden Zahlung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"Die Konfiguration des Abrechnungsanbieters ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"Die Couponzuweisung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"Die Couponverwaltung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Die Verwaltung des Zahlungsprofils ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_DELETE_CUSTOMER":"Das Löschen des Kunden ist aufgrund eines Datenbankfehlers fehlgeschlagen.",
      "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Hinzufügen von Angeboten zum Plan eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
      "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Klonen des Plans eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Versuch, eine Anfrage für den Klonplan zu stellen, eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
      "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Versuch, eine Anfrage zum Erstellen eines neuen Plans mit allen Angeboten anzufordern, eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
      "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Fehler aus der Datenbank beim Speichern der MPN-ID",
      "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
      "ERROR_DESC_SQL_GET_CUSTOMER":"Der Kundenabruf ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
      "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Fehler aus der Datenbank beim Abrufen von Kunden.",
      "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"Die gewünschte Anzahl von Abonnements 6 hat die maximal zulässige Anzahl von Abonnements pro Kunde 5 überschritten",
      "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Sie haben keine Berechtigung zum Ändern des Produktstatus, da das Eigentum mit den operativen Einheiten geteilt wird",
      "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"Die Aktualisierung des Produktstatus war nicht vollständig erfolgreich. Bitte wenden Sie sich an unser Support-Team, um Unterstützung zu erhalten",
      "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Steuerkennzeichen existiert bereits. Bitte wählen Sie ein eindeutiges Steuerkennzeichen aus.",
      "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"Die bereitgestellten SMTP-Einstellungen sind falsch",
      "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"Wir können diese Anfrage nicht bearbeiten, da Transaktionen deaktiviert sind.",
      "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"Das Testangebot kann nicht zusammen mit anderen Kategorien erworben werden",
      "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"Bitte wenden Sie sich an den Support, da Ihre Anfrage nicht bearbeitet werden kann",
      "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"Diese Bestellung kann nicht bearbeitet werden",
      "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Sie sind nicht berechtigt, auf diese Funktion zuzugreifen",
      "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"Verwendungsort ist ungültig",
      "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Beim Validieren des Kundenplans ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Fehler beim Zugriff auf die Datenbank",
      "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Beim Hinzufügen der Berechtigung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Beim Hinzufügen des Mieters ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Beim Erteilen der Zustimmung für den Mieter ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Mandant wurde erstellt, aber beim Hinzufügen des Abonnements ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Beim Zuweisen der Rolle ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Beim Abbrechen der geplanten Verlängerung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"Die angegebene Katalog-ID ist ungültig",
      "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Beim Prüfen der Berechtigung für die Microsoft NCE-Promotion ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Beim Erstellen eines Abonnements für reservierte Instanzen durch Synchronisierung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Beim Erstellen der geplanten Verlängerung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Fehler beim Einreihen der Nachricht zum Hochladen des Abonnementverlaufs in den PSA-Dienst",
      "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Beim Generieren einer Sammelrechnung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Beim Generieren einer CSV-Datei für den Zusammenfassungsbericht der Rechnungsposten ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Beim Generieren einer CSV-Datei für Rechnungsposten mit Provisionsdetailbericht ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Beim Generieren der CSV für den Rentabilitätsbericht ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Beim Generieren einer CSV-Datei für den Zusammenfassungsbericht zu Steuerbeträgen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Fehler beim Abrufen der Details des aktiven PSA-Dienstes",
      "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Beim Versuch, Kategorien für den Umsatz-Kosten-Vergleich abzurufen, ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Beim Abrufen der Abonnementdetails ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Fehler beim Abrufen der Zuordnung der Kundenabonnements zu PSA",
      "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Beim Abrufen der Kundenanzahl ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Beim Versuch, Details zum Umsatz im Vergleich zu den Kosten nach Kategorie abzurufen, ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Beim Abrufen der Details des externen Dienstabonnements nach c3-Abonnement-ID, Vertragsdienst-ID und Vertrags-ID ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_EXTERNAL_SEVICE_MAPPED_ENTITIES_AND_CONTRACT_SERVICES_BY_SEVICESNAME":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Abrufen der Liste der vom Kunden verwalteten Konfigurationen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Abrufen der Liste der vom Reseller verwalteten Konfigurationen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Beim Abrufen der Liste der Abonnement-Enddaten ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Fehler beim Abrufen der zugeordneten Entitäten und Vertragsdienste für Autotask",
      "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Fehler beim Abrufen der zugeordneten Entitäten und Produkte für ConnectWise Manage Manage",
      "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Beim Abrufen von Resellern von nicht eingebundenen Anbietern ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Beim Abrufen der Portletparameter ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Beim Abrufen der Produktanzahl ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Beim Abrufen der Produktzähler ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Beim Abrufen der Aktionsdetails ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Fehler beim Abrufen der Zusammenfassung des PSA-Post-Protokolls",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Beim Abrufen der gekauften Plätze nach SKU-ID ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Beim Abrufen der gekauften Plätze im angegebenen Zeitraum ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Beim Abrufen des Umsatzes im Vergleich zu den Kosten ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Beim Abrufen der Anzahl der Plätze ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Beim Abrufen der Anzahl der Sitzplätze ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Fehler beim Abrufen der Autotask-Einstellungen",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Fehler beim Abrufen des Status des PSA-Dienstprotokolls",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Fehler beim Abrufen der Post-Protokolle des PSA-Dienstes",
      "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Fehler beim Abrufen der gestaffelten PSA-Datensätze",
      "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Beim Abrufen der geschätzten Kunden ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Beim Abrufen der geschätzten gekauften Produkte nach Umsatz ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Fehler beim Verwalten der zugeordneten Entitäten und Vertragsdienste für Autotask",
      "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Fehler beim Verwalten der zugeordneten Entitäten und Produkte für ConnectWise Manage Manage",
      "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Beim Onboarding des Reseller-Anbieters ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Beim Verarbeiten der Anfrage für Reseller von Bulk-Onboard-Anbietern ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Beim Einreihen in die Warteschlange für Wiederverkäufer von Onboard-Anbietern ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Zurücksetzen der vom Kunden verwalteten Konfigurationen auf die Standardeinstellungen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Zurücksetzen der vom Reseller verwalteten Konfigurationen auf die Standardeinstellungen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Beim Speichern oder Aktualisieren des benutzerdefinierten Enddatums der Warenkorbposition ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Fehler beim Speichern der Abonnementverlaufsdaten der ausgewählten Kunden in Staging",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Aktualisieren der vom Kunden verwalteten Konfigurationen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Aktualisieren der vom Reseller verwalteten Konfigurationen ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Beim Aktualisieren der geplanten Verlängerung ist ein Fehler aufgetreten",
      "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Fehler beim Aktualisieren des Status von gestaffelten Datensätzen",
      "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Beim Upgrade des Abonnements ist ein Fehler aufgetreten",
      "ERROR_DETAILS":"Fehlerdetails",
      "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Dieser Abteilungsname existiert bereits unter diesem Kunden und Standort.",
      "ERROR_DUPLICATE_SITE_NMAE":"Dieser Site-Name existiert bereits unter diesem Kunden.",
      "ERROR_EMPTY_COMMENTS_SUBMITTED":"Leere Kommentare können nicht eingereicht werden",
      "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"Zahlungsdetails konnten nicht importiert werden",
      "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Details zur Produktprovision konnten nicht importiert werden",
      "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"Fehler beim Hinzufügen von Massenmandanteneingaben",
      "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Fehler beim Generieren von Details in Entitätsdetails für Massenrechnungen",
      "ERROR_FIELD_REQUIRED":"Dieses Feld wird benötigt",
      "ERROR_FORM_INVALID":"Das Formular ist ungültig.",
      "ERROR_GETTING_HOSTED_TOKEN":"Fehler beim Abrufen des gehosteten Tokens",
      "ERROR_GETTING_STRIPE_DETAILS":"Beim Abrufen der Streifendetails ist ein Fehler aufgetreten",
      "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Sie scheinen nicht über ausreichende Berechtigungen zu verfügen, um diese Aktion auszuführen",
      "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Verboten!",
      "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Der Status des Produkts kann nicht aktualisiert werden, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
      "ERROR_MESSAGE":"Etwas ist schief gelaufen!",
      "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"Die Genehmigung war nicht erfolgreich. Bitte wenden Sie sich an den Support",
      "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Mindestens ein Kunde sollte ausgewählt werden",
      "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Sie haben keine Erlaubnis, Sitzplätze zu reduzieren",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Beim Erstellen des Plans ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Support-Team.",
      "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Beim Erstellen des Reseller-Plans ist ein Fehler aufgetreten. Bitte wenden Sie sich an unser Support-Team.",
      "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Autotask unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Ausführung aller Autotask-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Einstellungen -> Autotask</b> aufrufen.",
      "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Connectwise unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Connectwise-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Einstellungen -> Connectwise verwalten</b> aufrufen.",
      "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"Die SMTP-Einstellungen sind ungültig",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Es tut uns leid, aber die Konnektivität scheint zwischen Ihrem C3-Konto und dem Microsoft Partner Center unterbrochen zu sein. Dies kann zu Fehlern beim Ausführen aller Partner Center-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie zu <b>Einstellungen -> Anbieter</b> gehen",
      "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Es tut uns leid, aber die Konnektivität scheint zwischen Ihrem C3-Konto und der Microsoft Partner Pricing API unterbrochen zu sein. Dies kann zu Fehlern beim Abrufen von Details aus der Microsoft-Preisgestaltungs-API über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie zu <b>Einstellungen -> Anbieter</b> gehen",
      "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Quickbooks unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Quickbooks-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Analysieren -> Geschäft -> Rechnungen/Zahlungen herunterladen</b> aufrufen.",
      "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Xero unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Xero-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Analysieren -> Geschäft -> Rechnungen/Zahlungen herunterladen</b> aufrufen.",
      "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Ungültige Warenkorbnummer",
      "ERROR_MESSAGE_INVALID_CUSTOMER":"Ungültiger Kunde",
      "ERROR_MESSAGE_INVALID_TENANT_USER":"Der Mandantenbenutzer ist ungültig",
      "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"Statusänderung ist ungültig.",
      "ERROR_MESSAGE_MANAGE_OWNERSHIP":"Das Feld „Menge“ ist erforderlich und darf weder null noch eine Dezimalzahl sein. Bitte geben Sie eine gültige ganze Zahl ein.",
      "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Es können bis zu 3 Märkte ausgewählt werden.",
      "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Es wurde keine Nachricht gefunden, um eine Reseller-Beziehung anzufordern. Bitte wenden Sie sich an den C3-Support",
      "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"Der Einkaufswagen enthält andere Angebote als Partner - LicenseSupported-Angebote. Bitte entfernen Sie diese, um fortzufahren.",
      "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Benutzer mit dieser E-Mail existiert bereits oder Sie haben keine Berechtigung, diesen Benutzer hinzuzufügen",
      "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Käufe können fehlschlagen. Bitte klicken Sie, um mehr zu erfahren.",
      "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"Mengenreduzierung ist nicht erlaubt",
      "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Dieser Kunde ist nicht mit Microsoft csp verbunden",
      "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"Ihre Anfrage kann nicht bearbeitet werden.",
      "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Sie haben mehr Gutscheine als nötig eingegeben",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Beim Exportieren des Berichts über Rechnungsposten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Administrator",
      "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Beim Exportieren des Rechnungszusammenfassungsberichts ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Administrator",
      "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Beim Hochladen der Datei ist ein Fehler aufgetreten",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut mit einem anderen Bild.",
      "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Bitte wählen Sie die Bilderweiterung aus, z. B .jpg, .png usw.",
      "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Fehler vom Partnercenter beim Überprüfen der MPNID. Bitte überprüfen Sie Ihre Eingabe erneut.",
      "ERROR_MS_FAILED_TO_CREATE_USER":"Fehler vom Partnercenter beim Erstellen des Benutzers",
      "ERROR_MS_FAILED_TO_DELETE_USER":"Fehler vom Partnercenter beim Löschen des Benutzers",
      "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Fehler vom Partnercenter beim Abrufen des Mandantenbenutzers",
      "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Fehler vom Partnercenter beim Abrufen der Benutzerdetails",
      "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Fehler vom Partnercenter beim Abrufen von Benutzerrollen",
      "ERROR_MS_FAILED_TO_GET_USERS":"Fehler vom Partnercenter beim Abrufen von Benutzern",
      "ERROR_MS_FAILED_TO_UPDATE_USER":"Fehler vom Partnercenter beim Aktualisieren des Benutzers",
      "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Fehler vom Partnercenter beim Aktualisieren der Benutzerlizenzzuweisungen",
      "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Fehler vom Partnercenter beim Aktualisieren des Benutzerkennworts",
      "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Beim Versuch, eine Anfrage zur Aktualisierung der neuesten Angebote für den Plan in die Warteschlange zu stellen, ist ein Fehler aufgetreten",
      "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Beim Prüfen der Berechtigung für Werbeaktionen ist ein Fehler aufgetreten.",
      "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Beim Zählen der Werbeaktionen im aktuellen Monat ist ein Fehler aufgetreten.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Beim Abrufen der Aktionsdetails ist ein Fehler aufgetreten.",
      "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Beim Abrufen der Aktionsdetails vom Anbieter ist ein Fehler aufgetreten.",
      "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Beim Laden der Angebote ist ein Fehler aufgetreten.",
      "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"Der Fehler ist beim Speichern der Abonnement-Upgrade-Details in der Staging-Tabelle aufgetreten",
      "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Beim Planen der Bestellung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support",
      "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"Der Fehler ist beim Versuch aufgetreten, die berechtigten Produkte für das Abonnement-Upgrade abzurufen",
      "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Beim Aktualisieren der Angebots-ID in cartlineItem ist ein Fehler aufgetreten.",
      "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Das Produkt fehlt, während die täglichen Nutzungsdaten des Partnerangebots importiert werden",
      "ERROR_PLAN_ID":"Plan-ID",
      "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Bitte bestätigen Sie die Akzeptanz der Microsoft-Kundenvereinbarung durch den Kunden",
      "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Fehler beim Importieren der täglichen Nutzungsdaten des Partnerangebots",
      "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Beim Versuch, Produktprovisionen zu importieren, ist ein Fehler aufgetreten",
      "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"Die Produktsequenz hat ungültige oder negative Werte. Bitte geben Sie eine gültige Reihenfolge an.",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"Der Mieter, den Sie einbinden/verknüpfen möchten, ist bereits im Portal vorhanden. Bitte wenden Sie sich an den Support",
      "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Der Mieter, den Sie an Bord/Link suchen, ist bei einem Ihrer Kunden vorhanden. Bitte versuchen Sie es mit einem anderen Mieter.",
      "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"Die Angebotsposition enthält ungültige oder negative Werte. Bitte geben Sie eine gültige Menge zwischen 1 und 9999 an.",
      "ERROR_RESELLER_PROVIDER_ID":"Reseller Provider Id",
      "ERROR_SAVING_CUSTOMER":"Fehler beim Speichern des Kunden",
      "ERROR_SAVING_PARTNER_OFFER":"Beim Speichern des Partnerangebots ist ein Fehler aufgetreten",
      "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"Das Paket sollte MS-Angebote der gleichen Kategorie enthalten.",
      "ERROR_SAVING_PAYMENT_ACCOUNT":"Fehler beim Speichern des Zahlungskontos",
      "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
      "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Fehler aus der Datenbank beim Speichern der MPN-ID",
      "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
      "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Für das Testangebot kommen nur benutzerdefinierte Angebote in Frage.",
      "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Für das Testangebot kommen nur mengenbasierte Angebote in Frage.",
      "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Es kann kein Provider-Mieter gefunden werden.",
      "ERROR_UPDATING_AUTO_RENEW_STATUS":"Das automatische Verlängerungsupdate für {{productName}} ist fehlgeschlagen.",
      "ERROR_WHILE_ACCESSING_DATABASE":"Fehler beim Zugriff auf die Datenbank",
      "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Beim Versuch zu überprüfen, ob der Reseller mit dem Anbieter verknüpft ist, ist ein Fehler aufgetreten",
      "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Beim Herunterladen der .csv-Vorlagendatei für den Bulk-Upload ist ein Fehler aufgetreten",
      "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Fehler beim Generieren des Produktkatalogberichts",
      "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Beim Abrufen des Verlängerungszustimmungsverlaufs ist ein Fehler aufgetreten",
      "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Beim Abrufen der Enddaten vom Partnercenter ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Beim Versuch, die Rechnungsbeträge abzurufen, ist ein Fehler aufgetreten",
      "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Beim Abrufen der geschätzten Produktplätze ist ein Fehler aufgetreten",
      "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Bitte wählen Sie mindestens eine Rechnung für die Buchung bei Xero aus.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Fehler: Abbruch des Uploads aufgrund eines Fehlers beim Erstellen von Entitäten",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Fehler: Ergänzung befindet sich im aktiven ConnectWise Manage",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Fehler: Die Vereinbarungs-ID fehlt",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Fehler: Die Rechnungsstellung ist für diese Vereinbarung in ConnectWise Manage nicht aktiviert",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Fehler: Zugeordnete Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht aktiv",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Fehler: Die Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Fehler: Für diesen Kunden sind keine Vereinbarungen zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Fehler: In ConnectWise Manage sind unter diesem Kunden keine Vereinbarungen vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Es liegen mehrdeutige Zusätze vor, eine Zuordnung ist nicht möglich.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Fehler: Vertrags-ID fehlt",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Fehler: Die Rechnungsstellung ist für diesen Vertrag in Autotask nicht aktiviert",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Fehler: Zugeordneter Vertrag ist in Autotask unter diesem Kunden nicht aktiv",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Fehler: Der Vertrag ist unter diesem Kunden nicht in Autotask vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Vertrag ist im externen Service unter diesem Kunden nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Vertragsservice ist diesem Kunden nicht zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Fehler: Zugeordneter Vertragsservice ist in Autotask nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Vertragsleistungen sind diesem Kunden nicht zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Fehler: Für diesen Kunden sind keine Verträge zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Fehler: In Autotask sind unter diesem Kunden keine Verträge vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Es sind keine Verträge im externen Service unter diesem Kunden vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Fehler: Kunde ist im externen Dienst nicht aktiv",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Fehler: Externer Kunde ist nicht mit C3-Kunden verknüpft",
      "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Kunde ist im externen Dienst nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Fehler: Entweder fehlt die Entitäts- oder Produktzuordnung.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Fehler: Es wurde zuvor ein Exportversuch unternommen. Der Status dieser Operation ist",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Fehler: Vertrag ist im externen Dienst nicht aktiv",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Fehler: Externe Kunden-ID fehlt",
      "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Fehler: Kunden sind im externen Service nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Fehler: Beim Erstellen der Ergänzung in ConnectWise Manage ist ein Fehler aufgetreten",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Fehler: Beim Erstellen einer Anpassung ist ein Fehler aufgetreten:",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Fehler: Beim Erstellen der Anpassung für die Preisänderung ist ein Fehler aufgetreten",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Fehler: Beim Erstellen des Dienstes ist ein Fehler aufgetreten",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Fehler: Vertragsgebühr für einmaliges Produkt konnte nicht erstellt werden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Fehler: Beim Erstellen des Vertragsdienstes ist ein Fehler aufgetreten:",
      "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Fehler: Beim Aktualisieren des Zusatzes in ConnectWise Manage ist ein Fehler aufgetreten",
      "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Fehler: Eine Zuordnung konnte nicht erstellt werden, da in Autotask mehrere übereinstimmende Dienste gefunden wurden.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Fehler: Es konnten keine passenden Verträge zum automatischen Zuordnen gefunden werden.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Fehler: Es konnte kein passender externer Kunde gefunden werden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Fehler: Es konnte kein passendes externes Produkt gefunden werden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Produkt ist diesem Kunden nicht zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Fehler: Zugeordnetes Produkt ist in ConnectWise Manage nicht vorhanden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Produkte sind diesem Kunden nicht zugeordnet",
      "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Fehler: C3-Produkte haben keinen zugeordneten externen Dienst",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Fehler: Service-ID fehlt",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Kündigungsdatum der Vereinbarung liegen.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Vertragsenddatum liegen.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Kündigungsdatum des Vertrags liegen.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Vertragsende liegen.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Abrechnungsstartdatum der Vereinbarung liegen",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Startdatum der Vereinbarung liegen",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Abrechnungsbeginn des Vertrags liegen.",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Vertragsbeginn liegen",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Fehler: Abonnement ist im aktiven Autotask",
      "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Fehler: Das Abonnement ist im externen Dienst inaktiv",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere übereinstimmende Verträge gefunden wurden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere externe Kunden zugeordnet wurden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere externe Produkte zugeordnet wurden",
      "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Fehler: Die Validierung war nicht erfolgreich",
      "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
      "ERRORMESSAGEPOPUP_HEADER":"Bitte geben Sie mindestens eine der Voraussetzungen für die Add-Ons in der folgenden Liste an",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Erweiterung",
      "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Voraussetzung Angebot",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Fehler: PSA-Vereinbarung ist nicht mit C3-Kunden verknüpft",
      "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Fehler: Externes Produkt ist nicht mit C3-Produkt verknüpft",
      "ERRORS_FROM_PREVIOUS_ACTION":"Ihre letzte Aktion hat zu Fehlern geführt. Klicken Sie hier, um weitere Informationen zu erhalten.",
      "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Während Ihrer Anfrage ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"Subskriptions-ID des Azure-Plans",
      "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Name des Azure-Plans",
      "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"Upgrade-ID",
      "EVENT_BATCH_ID":"Batch-ID",
      "EVENT_BATCH_STEP_ID":"Batch-Schritt-ID",
      "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Zusammenfassung der Bulk-Onboard-Reseller-Charge",
      "EVENT_CAN_PRICE_LAG":"OldCanPriceLag",
      "EVENT_CAN_PRICE_LEAD":"NeuDosePreisBlei",
      "EVENT_CONSTANT_TRUE":"TRUE",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Setzen Sie das Testangebot fort, bis das Abonnement fehlschlägt",
      "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Setzen Sie das Testangebot fort, bis das Abonnement erfolgreich ist",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Testangebot in Abonnementfehler umwandeln",
      "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Wandeln Sie das Testangebot in einen Abonnementerfolg um",
      "EVENT_CREATED_BY":"Ereignis erstellt von",
      "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Interne ID",
      "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Email",
      "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Vorname",
      "EVENT_DATA_ACCOUNT_MANAGER_ID":"Account Manager ID",
      "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Nachname",
      "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefonnummer",
      "EVENT_DATA_ACCOUNT_NUMBER":"Kontonummer",
      "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Aktion",
      "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Error",
      "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Status",
      "EVENT_DATA_ADDRESS_CITY":"Stadt",
      "EVENT_DATA_ADDRESS_COUNTRY":"Land",
      "EVENT_DATA_ADDRESS_LINE_1":"Anschrift Zeile 1",
      "EVENT_DATA_ADDRESS_LINE_2":"Adresszeile 2",
      "EVENT_DATA_ADDRESS_STATE":"Zustand",
      "EVENT_DATA_ADDRESS_ZIP_CODE":"Postleitzahl",
      "EVENT_DATA_AGREEMENT":"Zustimmung",
      "EVENT_DATA_AGREEMENT_DATE":"Vereinbarungstermin",
      "EVENT_DATA_AGREEMENT_LINK":"Vereinbarung Link",
      "EVENT_DATA_AGREEMENT_NAME":"Zustimmung",
      "EVENT_DATA_AGREEMENT_TYPE":"Vertragsart",
      "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"War die Zuweisung der Microsoft-Sicherheitsgruppen zur GDAP-Anforderung erfolgreich?",
      "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Gutschein zugeordnet",
      "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":"Neuer Wert",
      "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Alter Wert",
      "EVENT_DATA_AUTO_SYNC_ACTION":"Aktionen",
      "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Error",
      "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Grund",
      "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Status",
      "EVENT_DATA_BCC_RECIPIENTS":"BCC-Empfänger",
      "EVENT_DATA_BILLING_CYCLE_NAME":"Name des Abrechnungszyklus",
      "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Zahlungsalias",
      "EVENT_DATA_BILLING_PERIOD_ID":"Abrechnungszeitraum-ID",
      "EVENT_DATA_BILLING_PROVIDER":"Abrechnungsanbieter",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Kunden-ID des Abrechnungsanbieters",
      "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Kundenname des Abrechnungsanbieters",
      "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Reseller-ID des Abrechnungsanbieters",
      "EVENT_DATA_BILLING_PROVIDER_USER":"Benutzeridentifikation",
      "EVENT_DATA_BILLING_PROVIDER_USER_ID":"Benutzeridentifikation",
      "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Bündelname",
      "EVENT_DATA_C3_CUSTOMER_NAME":"C3-Kunde",
      "EVENT_DATA_C3_PRODUCT_NAME":"C3-Produkt",
      "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Name des C3-Abonnements",
      "EVENT_DATA_CARD_TYPE":"Speicherkarten-Typ",
      "EVENT_DATA_CART_ID":"Warenkorb-ID",
      "EVENT_DATA_CART_ITEMS":"Warenkorbartikel",
      "EVENT_DATA_CATEGORY_ID":"Kategorie ID",
      "EVENT_DATA_CATEGORY_NAME":"Kategoriename",
      "EVENT_DATA_CC_RECIPIENTS":"CC-Empfänger",
      "EVENT_DATA_CHANGE_TYPE":"Typ ändern",
      "EVENT_DATA_CLONED_FROM_PLAN":"Geklont von",
      "EVENT_DATA_COMPANY_NAME":"Name der Firma",
      "EVENT_DATA_CONSUMED_SEATS":"Verbrauchte Plätze",
      "EVENT_DATA_CONSUMPTION_TYPE":"Verbrauchsart",
      "EVENT_DATA_CONTRACT":"Vertrag",
      "EVENT_DATA_CONTRACT_NAME":"Vertragsname",
      "EVENT_DATA_CONTRACT_SERVICE_NAME":"Vertragsdienstname",
      "EVENT_DATA_CONVERSION_RATE":"Wechselkurs",
      "EVENT_DATA_CORRELATION_ID":"Korrelations-ID",
      "EVENT_DATA_COUNTRY_CODE":"Landesvorwahl",
      "EVENT_DATA_COUPON_CODE":"Gutscheincode",
      "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Anzahl der Kunden mit neuen Aufträgen",
      "EVENT_DATA_COUPON_DESCRIPTION":"Coupon Beschreibung",
      "EVENT_DATA_COUPON_DETAILS":"Gutscheindetails",
      "EVENT_DATA_COUPON_DISCOUNT":"Rabatt",
      "EVENT_DATA_COUPON_EXPIRES_ON":"Läuft aus am",
      "EVENT_DATA_COUPON_NOOFRECURRENCES":"Anzahl der Wiederholungen",
      "EVENT_DATA_COUPON_VALID_TILL":"Gültig bis",
      "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Erstellt von in Anbieter",
      "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Erstellungsdatum im Anbieter",
      "EVENT_DATA_CURRENCY_CODE":"Währungscode",
      "EVENT_DATA_CURRENT_QUANTITY":"Alte Menge",
      "EVENT_DATA_CURRENT_STATUS":"Alter Status",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Neuer benutzerdefinierter Abrechnungszeitraum",
      "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Alter benutzerdefinierter Abrechnungszeitraum",
      "EVENT_DATA_CUSTOMER_C3_ID":"Kunde C3 Id",
      "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Zustimmung",
      "EVENT_DATA_CUSTOMER_NAME":"Kundenname",
      "EVENT_DATA_CUSTOMER_NEW_NAME":"Neuer Name des Kunden",
      "EVENT_DATA_CUSTOMER_OLD_NAME":"Alter Name des Kunden",
      "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Kundenreferenz-ID",
      "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"Kundenregistrierung batchid",
      "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"Interner Planid",
      "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"Werden alle PSA-Protokolle deaktiviert?",
      "EVENT_DATA_DEPARTMENT_NAME":"Abteilungsname",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Rechnungskreislauf",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Name",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Selbstkostenpreis",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Verkaufspreis",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Gültigkeit",
      "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Gültigkeitsart",
      "EVENT_DATA_DOMAIN":"Domain",
      "EVENT_DATA_DOMAIN_NAME":"Domain Name",
      "EVENT_DATA_EFFECTIVE_FROM":"Gültig ab",
      "EVENT_DATA_EMAIL":"Email",
      "EVENT_DATA_EMAIL_ADDRESS":"Email",
      "EVENT_DATA_END_BILLING_PERIOD_ID":"ID des Abrechnungszeitraums beenden",
      "EVENT_DATA_END_DATE":"Endtermin",
      "EVENT_DATA_ENTITY_ID":"Entitäts-ID",
      "EVENT_DATA_ENTITY_NAME":"Entitätsname",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entität des Benutzers",
      "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entität des Benutzers",
      "EVENT_DATA_ERROR":"Error",
      "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Produktzuordnung mit Fehler",
      "EVENT_DATA_EVENT":"Veranstaltung",
      "EVENT_DATA_EVENT_ID":"Ereignis-ID",
      "EVENT_DATA_EVENT_NAME":"Veranstaltungsname",
      "EVENT_DATA_EXCEPTION_MESSAGE":"Ausnahmemeldung",
      "EVENT_DATA_EXPIRES_ON":"Läuft aus am",
      "EVENT_DATA_EXPORT_ERROR":"Exportfehler",
      "EVENT_DATA_EXPORT_STATUS":"Exportstatus",
      "EVENT_DATA_EXTERNAL_AGREEMENTS":"Externe Vereinbarungen",
      "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Externe Vertragsdienstleistungen",
      "EVENT_DATA_EXTERNAL_CONTRACTS":"Externe Verträge",
      "EVENT_DATA_EXTERNAL_CUSTOMERS":"PSA-Kunde",
      "EVENT_DATA_EXTERNAL_PRODUCTS":"PSA-Produkt",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Kundennummer",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"Abonnementverlaufs-ID",
      "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Abonnement-ID",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Vertrags-ID",
      "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"Vertragsdienst-ID",
      "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"Externe Kunden-ID",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Exportfehler",
      "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Exportstatus",
      "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Produktzuordnungstyp",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Validierungsfehler",
      "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Validierungsstatus",
      "EVENT_DATA_FAILED_TENANT_ID":"Mandanten-ID fehlgeschlagen",
      "EVENT_DATA_GROUP_DESCRIPTION":"Gruppenbeschreibung",
      "EVENT_DATA_GROUP_NAME":"Gruppenname",
      "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"Hat zusätzliche Details",
      "EVENT_DATA_IMPERSONATOR":"Imitator",
      "EVENT_DATA_INPUT_DATA":"Detail anfordern",
      "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Interne Kunden-ID",
      "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Interne Kundenprodukt-ID",
      "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Interne ID für neue geplante Verlängerung",
      "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Interne ID für die alte geplante Verlängerung",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId des Quellplans",
      "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId des Zielplans",
      "EVENT_DATA_INTERNAL_PRODUCT_ID":"C3 Produkt-ID",
      "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Interne Abonnement-ID",
      "EVENT_DATA_INVOICE_LINEITEM":"Name des Rechnungspostens",
      "EVENT_DATA_INVOICE_NUMBER":"Rechnungsnummer",
      "EVENT_DATA_INVOICE_PROPERTY":"Name des Anwesens",
      "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Alter Wert",
      "EVENT_DATA_INVOICE_PROPERTY_VALUE":"Neuer Wert",
      "EVENT_DATA_IS_ACCEPTED":"Ist akzeptiert",
      "EVENT_DATA_IS_ENABLED":"Ist aktiviert",
      "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"Ist sofortige Bereitstellung",
      "EVENT_DATA_IS_SHOW_ON_UI":"Wird auf dem Bildschirm angezeigt",
      "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
      "EVENT_DATA_LINE_ITEM":"Werbebuchung",
      "EVENT_DATA_LINKED_PRODUCT_NAME":"Verknüpfter Produktname",
      "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Verknüpfter Produktname",
      "EVENT_DATA_LOGGED_IN_USER":"Angemeldeter Benutzer",
      "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Benutzerlizenzdetails verwalten",
      "EVENT_DATA_MESSAGE":"Botschaft",
      "EVENT_DATA_Name":"Name",
      "EVENT_DATA_NAME":"Name",
      "EVENT_DATA_NAME_OF_ENTITY":"Name des Unternehmens",
      "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Neuer Wert",
      "EVENT_DATA_NEW_COUPON_CODE":"Neuer Gutscheincode",
      "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Neue Gutscheinbeschreibung",
      "EVENT_DATA_NEW_COUPON_DISCOUNT":"Neuer Coupon-Rabatt",
      "EVENT_DATA_NEW_COUPON_EXPIRESON":"Neuer Gutschein läuft ab am",
      "EVENT_DATA_NEW_COUPON_NAME":"Neuer Gutscheinname",
      "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Neue Coupon-Anzahl von Wiederholungen",
      "EVENT_DATA_NEW_COUPON_VALIDTILL":"Neuer Gutschein gültig bis",
      "EVENT_DATA_NEW_DESCRIPTION":"Neue Planbeschreibung",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung des neuen Vertriebsangebots",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Neues Vertriebsangebot",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Neues Vertriebsangebot",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Neuer Name des Vertriebspartnerangebots",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Neues Vertriebsangebot",
      "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Neues Vertriebsangebot",
      "EVENT_DATA_NEW_END_DATE_TYPE":"Neuer Enddatumstyp",
      "EVENT_DATA_NEW_ENTITY_NAME":"Neue Entität",
      "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Übertragen auf",
      "EVENT_DATA_NEW_ISPUBLIC":"Neuer Plan ist öffentlich",
      "EVENT_DATA_NEW_MARKUP":"Neues Markup",
      "EVENT_DATA_NEW_NAME":"Neuer Planname",
      "EVENT_DATA_NEW_OFFER":"Neues Angebot",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Beschreibung des neuen Partnerangebots",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Neues Partnerangebot",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Neues Partnerangebot",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Name des neuen Partnerangebots",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Neues Partnerangebot",
      "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Neues Partnerangebot",
      "EVENT_DATA_NEW_PLAN":"Neuer Plan",
      "EVENT_DATA_NEW_PRICE":"Neuer Preis",
      "EVENT_DATA_NEW_PRODUCT_MAPPING":"Neue Produktzuordnung",
      "EVENT_DATA_NEW_PROMOTION_ID":"Neue Promotion-ID",
      "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Neues effektives Enddatum",
      "EVENT_DATA_NEW_QUANTITY":"Neue Menge",
      "EVENT_DATA_NEW_SEATS":"Neue Sitze",
      "EVENT_DATA_NEW_STATUS":"Neuer Status",
      "EVENT_DATA_NEW_VALIDITY":"Neue Gültigkeit",
      "EVENT_DATA_NEW_VALIDITY_TERM":"Neue Gültigkeit",
      "EVENT_DATA_NEW_VALIDITYTYPE":"Neuer Gültigkeitstyp",
      "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Benachrichtigungsempfänger",
      "EVENT_DATA_OFFER_NAME":"Angebotsname",
      "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Angebotspreis nach Rabatt",
      "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Alter Wert",
      "EVENT_DATA_OLD_COUPON_CODE":"Alter Gutscheincode",
      "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Alte Gutscheinbeschreibung",
      "EVENT_DATA_OLD_COUPON_DISCOUNT":"Alter Coupon-Rabatt",
      "EVENT_DATA_OLD_COUPON_EXPIRESON":"Alter Coupon verfällt am",
      "EVENT_DATA_OLD_COUPON_NAME":"Alter Gutscheinname",
      "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Alter Coupon Nr. der Wiederholungen",
      "EVENT_DATA_OLD_COUPON_VALIDTILL":"Alter Gutschein gültig bis",
      "EVENT_DATA_OLD_DESCRIPTION":"Alte Planbeschreibung",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung des alten Händlerangebots",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Altes Händlerangebot",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Altes Händlerangebot",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Alter Angebotsname des Händlers",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Altes Händlerangebot",
      "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Altes Händlerangebot",
      "EVENT_DATA_OLD_ENTITY_ID":"Altes Wesen",
      "EVENT_DATA_OLD_ENTITY_NAME":"Altes Wesen",
      "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transferiert von",
      "EVENT_DATA_OLD_ISPUBLIC":"Alter Plan ist öffentlich",
      "EVENT_DATA_OLD_NAME":"Alter Planname",
      "EVENT_DATA_OLD_OFFER":"Altes Angebot",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Beschreibung des alten Partnerangebots",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Altes Partnerangebot",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Altes Partnerangebot",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Name des alten Partnerangebots",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Altes Partnerangebot",
      "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Altes Partnerangebot",
      "EVENT_DATA_OLD_PLAN":"Alter Plan",
      "EVENT_DATA_OLD_PRICE":"Alter Preis",
      "EVENT_DATA_OLD_QUANTITY":"Alte Menge",
      "EVENT_DATA_OLD_SEATS":"Alte Sitze",
      "EVENT_DATA_OLD_STATUS":"Alter Status",
      "EVENT_DATA_OLD_VALIDITY":"Alte Gültigkeit",
      "EVENT_DATA_OLD_VALIDITY_TERM":"Alte Gültigkeit",
      "EVENT_DATA_OLD_VALIDITYTYPE":"Alter Gültigkeitstyp",
      "EVENT_DATA_PAID_AMOUNT":"Bezahlte Menge",
      "EVENT_DATA_PAID_DATE":"Bezahltes Datum",
      "EVENT_DATA_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
      "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Abonnement-ID des Azure-Plans",
      "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Übergeordneter Produktname",
      "EVENT_DATA_PARTNER_NAME":"Partnername",
      "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Zahlungsprofil Alias",
      "EVENT_DATA_PAYMENT_PROFILE_ID":"Zahlungsprofil-ID",
      "EVENT_DATA_PAYMENT_TYPE":"Zahlungsart",
      "EVENT_DATA_PHONE_NUMBER":"Telefonnummer",
      "EVENT_DATA_PLAN_COUNT":"Anzahl der Pläne",
      "EVENT_DATA_PLAN_DESCRIPTION":"Beschreibung",
      "EVENT_DATA_PLAN_ID":"Plan-ID",
      "EVENT_DATA_PLAN_NAME":"Plan Name",
      "EVENT_DATA_PLAN_PRODUCT_ID":"PlanProduct Id",
      "EVENT_DATA_PLAN_TYPE":"Planart",
      "EVENT_DATA_PRICE":"Preis",
      "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Produkt C3 Abonnement-ID",
      "EVENT_DATA_PRODUCT_MAPPING":"Produktzuordnung",
      "EVENT_DATA_PRODUCT_NAME":"Produktname",
      "EVENT_DATA_PRODUCT_NEW_NAME":"Produkt Neuer Name",
      "EVENT_DATA_PRODUCT_PRICE":"Produktpreis",
      "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"Produktabonnement-ID",
      "EVENT_DATA_PROMOTION_ID":"Angebots-ID",
      "EVENT_DATA_PROVIDER":"Anbieter",
      "EVENT_DATA_PROVIDER_BUSINESS_ID":"Geschäfts-ID des Anbieters",
      "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Name des Anbieterkunden",
      "EVENT_DATA_PROVIDER_ENTITY_NAME":"Name der Anbieterentität",
      "EVENT_DATA_PROVIDER_ID":"Anbieter-ID",
      "EVENT_DATA_PROVIDER_NAME":"Anbietername",
      "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Zahlungsprofil-ID des Anbieters",
      "EVENT_DATA_PROVIDER_PRICE":"Anbieterpreis",
      "EVENT_DATA_PROVIDER_PRODUCT_ID":"Abonnement-ID",
      "EVENT_DATA_PROVIDER_RESELLER_ID":"Provider Reseller Id",
      "EVENT_DATA_PROVIDER_RESELLER_NAME":"Name des Wiederverkäufers des Anbieters",
      "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"Anbieter-Abonnement-ID",
      "EVENT_DATA_PSA_CUSTOMER_NAME":"PSA-Kunde",
      "EVENT_DATA_PSA_LOG_ID":"Batch-ID",
      "EVENT_DATA_PSA_NAME":"PSA-Name",
      "EVENT_DATA_PSA_PRODUCT_NAME":"PSA-Produkt",
      "EVENT_DATA_QUANTITY":"Menge",
      "EVENT_DATA_RECORD_ID":"Datensatz-ID",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"RecordId des Benutzers",
      "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"RecordId des Benutzers",
      "EVENT_DATA_RECORD_INT_ID":"Int",
      "EVENT_DATA_RECORD_NAME":"Datensatzname",
      "EVENT_DATA_RELEASED_SEATS":"Freigegebene Plätze",
      "EVENT_DATA_RENEWS_EXPIRES_ON":"Erneuert sich/läuft ab am",
      "EVENT_DATA_RENEWS_ON":"Erneuert weiter",
      "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung des C3-Produkts",
      "EVENT_DATA_REQUESTED_QUANTITY":"Neue Menge",
      "EVENT_DATA_REQUESTED_STATUS":"Neuer Status",
      "EVENT_DATA_RESELLER_NAME":"Verkäufer Name",
      "EVENT_DATA_RESELLER_NEW_NAME":"Neuer Name des Wiederverkäufers",
      "EVENT_DATA_RESELLER_OLD_NAME":"Alter Name des Wiederverkäufers",
      "EVENT_DATA_RESERVATION_ORDER_ID":"Reservierungsauftrags-ID",
      "EVENT_DATA_ROLE":"Rolle",
      "EVENT_DATA_SALE_PRICE":"Verkaufspreis",
      "EVENT_DATA_SEAT_LIMIT":"Sitzplatz-Limit",
      "EVENT_DATA_SITE_NAME":"Seite-Name",
      "EVENT_DATA_SITEDEPARTMENT_NAME":"Abteilungsname",
      "EVENT_DATA_SOURCE_CURRENCY":"Quellwährung",
      "EVENT_DATA_SOURCE_OFFER_ID":"Quellangebots-ID",
      "EVENT_DATA_SOURCE_PRODUCT_NAME":"Produktname der Quelle",
      "EVENT_DATA_SOURCE_QUANTITY":"Quellmenge",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Abrechnungszeitraum der Quelle",
      "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"Quellabonnement-ID",
      "EVENT_DATA_SOURCE_VALIDITY":"Quellengültigkeit",
      "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Quellgültigkeitstyp",
      "EVENT_DATA_START_BILLING_PERIOD_ID":"ID des Abrechnungszeitraums starten",
      "EVENT_DATA_START_DATE":"Anfangsdatum",
      "EVENT_DATA_STATUS":"Status",
      "EVENT_DATA_SUB_TAXES":"Untersteuern",
      "EVENT_DATA_SUBSCRIPTION_ID":"Abonnement-ID",
      "EVENT_DATA_SUBSCRIPTION_NAME":"Abonnementname",
      "EVENT_DATA_SUBSCRIPTIONID":"Abonnement-ID",
      "EVENT_DATA_SUPPORTED_MARKET_CODE":"Unterstützter Marktcode",
      "EVENT_DATA_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "EVENT_DATA_SYNCED_PRODUCT":"Anzahl der synchronisierten Produkte",
      "EVENT_DATA_TARGET_CURRENCY":"Zielwährung",
      "EVENT_DATA_TARGET_OFFER_ID":"Zielangebots-ID",
      "EVENT_DATA_TARGET_PRODUCT_NAME":"Name des Zielprodukts",
      "EVENT_DATA_TARGET_QUANTITY":"Zielmenge",
      "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Angestrebter Abrechnungszeitraum",
      "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"Zielabonnement-ID",
      "EVENT_DATA_TARGET_VALIDITY":"Zielgültigkeit",
      "EVENT_DATA_TARGET_VALIDITY_TYPE":"Zielgültigkeitstyp",
      "EVENT_DATA_TAX_CODE":"Steuer-Code",
      "EVENT_DATA_TAX_NAME":"Steuername",
      "EVENT_DATA_TAX_PERCENTAGE":"Steuerprozentsatz",
      "EVENT_DATA_TENANT_ID":"Mieter-ID",
      "EVENT_DATA_TENANT_NAME":"Name des Mieters",
      "EVENT_DATA_TO_RECIPIENTS":"An die Empfänger",
      "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Anzahl der betroffenen Produkte",
      "EVENT_DATA_TOTAL_RELEASED_SEATS":"Anzahl der betroffenen Plätze",
      "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Gesamtbetrag der Transaktion",
      "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"Transaktionsbetragslimit",
      "EVENT_DATA_TRANSITION_TYPE":"Übergangstyp",
      "EVENT_DATA_UNSYNCED_PRODUCT":"Anzahl nicht synchronisierter Produkte",
      "EVENT_DATA_UPDATED_BUNDLE_NAME":"Bundle-Name aktualisiert",
      "EVENT_DATA_UPDATED_COUPON_NAME":"Gutscheinname aktualisiert",
      "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Name des Händlerangebots aktualisiert",
      "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Name des Partnerangebots aktualisiert",
      "EVENT_DATA_UPDATED_PLAN_NAME":"Planname aktualisiert",
      "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Änderung der Produktzuordnung",
      "EVENT_DATA_URL":"URL",
      "EVENT_DATA_USAGE_LOCATION":"Verwendungsort",
      "EVENT_DATA_USER_DISPLAY_NAME":"Anzeigename",
      "EVENT_DATA_USER_EMAIL":"Benutzer Email",
      "EVENT_DATA_USER_TYPE":"Benutzertyp",
      "EVENT_DATA_USERNAME":"Nutzername",
      "EVENT_DATA_VALIDATION_ERROR":"Validierungsfehler",
      "EVENT_DATA_VALIDATION_STATUS":"Validierungsstatus",
      "EVENT_DATA_VALIDITY":"Gültigkeit",
      "EVENT_DATA_VALIDITY_TYPE":"Gültigkeitsart",
      "EVENT_DATA_VALUE":"Wert",
      "EVENT_DATA_VOUCHER_ENTITY":"Gutschein-Einheit",
      "EVENT_DATA_VOUCHER_IDS":"Gutschein-IDs",
      "EVENT_DATA_VOUCHERS":"Gutscheine",
      "EVENT_DATE":"Datum",
      "EVENT_DEPARTMENT_DESCRIPTION":"Abteilungsbeschreibung",
      "EVENT_DEPARTMENT_ID":"Abteilungs-ID",
      "EVENT_DEPARTMENT_NAME":"Abteilungsname",
      "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Zustimmung zur Verlängerung des Partnerangebots annehmen oder ablehnen",
      "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Akzeptierte NCE-Geschäftsbedingungen für die Bestellung von Microsoft NCE-Produkten",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Akzeptierte Bedingungen und Konditionen",
      "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Akzeptierte Geschäftsbedingungen scheitern",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Aktivieren Sie das DangerThreshold Met-Abonnement",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Aktivieren Sie das DangerThreshold Met-Abonnement",
      "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Aktivieren Sie den Abonnementfehler bei Erreichen des Gefahrenschwellenwerts",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Aktivieren Sie das benutzerdefinierte Bereitstellungsangebot",
      "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Aktivieren Sie Provisioning Custom Offer Failure",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Fehler beim Aktivieren des Abonnements",
      "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Abo-Erfolg aktivieren",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Fehler beim Erstellen des Kunden",
      "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"C3-Kundenfehler hinzufügen",
      "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Fügen Sie den C3-Kundenerfolg hinzu",
      "EVENT_DESC_ADD_ENTITLEMENTS":"Berechtigung hinzufügen",
      "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Berechtigungsfehler hinzufügen",
      "EVENT_DESC_ADD_GROUP_ADMIN":"Gruppenadministrator hinzufügen",
      "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Gruppenadministrationsfehler hinzufügen",
      "EVENT_DESC_ADJUST_AZURE_MARGIN":"Passen Sie die Azure-Marge an",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"Anpassen der Azure-Marge fehlgeschlagen",
      "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Passen Sie die Azure-Marge an",
      "EVENT_DESC_APPROVED_BY":"Genehmigt von",
      "EVENT_DESC_APPROVED_ON":"Genehmigt am",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Genehmigte oder abgelehnte nächste Planverlängerungsänderungen",
      "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Genehmigte oder abgelehnte nächste Planverlängerungsänderungen",
      "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Genehmigte oder abgelehnte Kaufanfrage",
      "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Genehmigte Kaufanfrage",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Plan dem Kunden zuweisen",
      "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"Plan konnte dem Kunden nicht zugewiesen werden",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Zuweisen verpasster Gutscheine fehlgeschlagen",
      "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Versäumte Gutscheine erfolgreich zugewiesen",
      "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin erhält automatisch Zugriff auf Kunden",
      "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Automatisch verarbeitete Kundenproduktabonnements",
      "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Unbenutzte Plätze automatisch freigeben",
      "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Diskrepanzen aufgrund von Auto-Sync behoben",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Abrechnungszeitraum erfolgreich geändert (Auto Sync)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"Änderung des Abrechnungszeitraums fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Abrechnungszeitraum erfolgreich geändert (manuelle Synchronisierung)",
      "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Änderung des Abrechnungszeitraums fehlgeschlagen (manuelle Synchronisierung)",
      "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Massen-Batch-Zusammenfassung an Bord",
      "EVENT_DESC_BULK_USER_UPLOAD":"Massenbenutzer-Upload",
      "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Fehler beim Hochladen von Massenbenutzern",
      "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Bundle-Angebote werden gekauft",
      "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Produktbestellung bündeln",
      "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Erfolgreiche geplante Verlängerung abbrechen.",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Stornierte Abonnement-Kaufanfrage",
      "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Fehler bei stornierter Abonnement-Kaufanfrage",
      "EVENT_DESC_CART_AVAILABILITY_STATUS":"Warenkorb-Verfügbarkeitsstatus",
      "EVENT_DESC_CART_CHECKOUT":"Warenkorb Kasse",
      "EVENT_DESC_CART_CHECKOUT_FAILURE":"Warenkorbprüfung fehlgeschlagen",
      "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Benachrichtigen, dass das Sitzplatzlimit überschritten wurde",
      "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Benachrichtigen Sie, dass das Transaktionslimit überschritten wurde",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung des Kundenprodukts geändert",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"Status der automatischen Verlängerung des Kundenprodukts konnte nicht geändert werden",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Der Produktstatus des Kunden wurde erfolgreich aktualisiert",
      "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Das Ändern des Produktstatus ist fehlgeschlagen",
      "EVENT_DESC_CHANGE_INVOICE_STATUS":"ChangeInvoice Status",
      "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Fehler beim Ändern des Rechnungsstatus",
      "EVENT_DESC_CHANGE_PLAN_FAILURE":"Planversagen ändern",
      "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Planerfolg ändern",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Fehler bei der Währungsumrechnung beim Klonen",
      "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Erfolgreiche Währungsumrechnung klonen",
      "EVENT_DESC_CLONE_PLAN":"Erfolgreich geklonter Plan",
      "EVENT_DESC_CLONE_PLAN_FAILURE":"Plan konnte nicht geklont werden",
      "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"Coupon gilt für alle markierten Kunden?",
      "EVENT_DESC_COUPON_NAME":"Coupon Name",
      "EVENT_DESC_COUPON_STATUS":"Gutscheinstatus",
      "EVENT_DESC_CREATE_AZURE_GROUPS":"Erstellen Sie Azure-Gruppen",
      "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Fehler beim Erstellen von Azure-Gruppen",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Erstellen Sie ein Azure-Abonnement",
      "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Erstellen eines Azure-Abonnementfehlers",
      "EVENT_DESC_CREATE_CART":"Warenkorb erstellen",
      "EVENT_DESC_CREATE_CART_FAILURE":"Fehler beim Erstellen des Warenkorbs",
      "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Kundenfehler erstellen",
      "EVENT_DESC_CREATE_INVOICE":"Rechnung erstellen",
      "EVENT_DESC_CREATE_ORDER":"Bestellung anlegen",
      "EVENT_DESC_CREATE_ORDER_FAILURE":"Bestellfehler erstellen",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Zahlungsprofil erstellen",
      "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Fehler beim Erstellen des Zahlungsprofils",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"Das Erstellen eines Abonnements für reservierte Instanzen durch Synchronisierung ist fehlgeschlagen",
      "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Das Abonnement für reservierte Instanzen wurde durch Synchronisierung erfolgreich erstellt",
      "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Schaffen Sie eine erfolgreiche geplante Verlängerung",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Abonnementablaufprüfung erstellen",
      "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Ablauf des Abonnements erstellen fehlgeschlagen",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Abonnement erfolgreich erstellt (Auto Sync)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Erstellung des Abonnements fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Erstellen eines Abonnements von Auto Sync übersprungen",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Abonnement erfolgreich erstellt (manuelle Synchronisierung)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"Erstellen des Abonnements fehlgeschlagen (manuelle Synchronisierung)",
      "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Das Erstellen des Abonnements aus der manuellen Synchronisierung wurde übersprungen",
      "EVENT_DESC_CREATE_TAX_RULE":"Steuerregel erstellen",
      "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Erstellen Sie Tax RuleFailure",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Benutzer für Nutzungsabonnement erstellen",
      "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Erstellen Sie einen UsageSubscription-Benutzerfehler",
      "EVENT_DESC_CREATE_USER":"Benutzer erstellen",
      "EVENT_DESC_CREATE_USER_FAILURE":"Benutzerfehler erstellen",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Zustimmung zur Verlängerung des Partnerangebots",
      "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Zustimmung zur Verlängerung des Partnerangebots ist fehlgeschlagen",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Kundenerstellung über API",
      "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Kundenerstellung durch API-Fehler",
      "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Fehler beim Löschen des Kunden",
      "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Kundenlöschung erfolgreich",
      "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Kundenrechnung generiert",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Fehler beim Aktualisieren des Kundennamens",
      "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Erfolg beim Aktualisieren des Kundennamens",
      "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"Das Limit für Kundenlizenzen überschreitet die Benachrichtigung",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"PSA-Protokoll deaktivieren",
      "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"PSA-Protokoll deaktivieren fehlgeschlagen",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Währungsumrechnung definieren",
      "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Währungsumrechnungsfehler definieren",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Planpreise in neuer Währung definieren",
      "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Planpreise bei Ausfall einer neuen Währung definieren",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Verknüpfung zum Rechnungskunden aufheben",
      "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Fehler beim Abrechnungskunden aufheben",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Account Manager-Erfolg löschen",
      "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Account Manager-Fehler löschen",
      "EVENT_DESC_DELETE_CART_ITEM":"Artikel im Warenkorb gelöscht",
      "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Löschen des Warenkorbartikels fehlgeschlagen",
      "EVENT_DESC_DELETE_CONTRACT_OFFER":"Vertragsangebot erfolgreich gelöscht",
      "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Löschen des Vertragsangebots fehlgeschlagen",
      "EVENT_DESC_DELETE_COUPON":"Coupon erfolgreich deaktiviert",
      "EVENT_DESC_DELETE_COUPON_FAILED":"Das Löschen des Gutscheins ist fehlgeschlagen.",
      "EVENT_DESC_DELETE_COUPON_FAILURE":"Coupon konnte nicht deaktiviert werden",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Währungsumrechnung löschen",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Währungsumrechnungsfehler löschen",
      "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Währungsumrechnung konnte nicht gelöscht werden",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Löschen der benutzerdefinierten Benachrichtigung",
      "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Benutzerdefinierte Benachrichtigung erfolgreich löschen",
      "EVENT_DESC_DELETE_CUSTOM_OFFER":"Benutzerdefiniertes Angebot erfolgreich gelöscht",
      "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Löschen des benutzerdefinierten Angebots fehlgeschlagen",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Kundenzahlung löschen",
      "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Fehler beim Löschen der Kundenzahlung",
      "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Abteilungsfehler löschen",
      "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Abteilungserfolg löschen",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Händlerangebot erfolgreich gelöscht",
      "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Das Löschen des Händlerangebots ist fehlgeschlagen",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung löschen",
      "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Löschen der Ereignis-E-Mail-Benachrichtigung",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Rechnungsanpassung löschen",
      "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Fehler bei der Rechnungsanpassung löschen",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Rechnungen löschen LineItem",
      "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Rechnungen löschen LineItemFailure",
      "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Partnerbenutzerfehler löschen",
      "EVENT_DESC_DELETE_PAYMENT_OPTION":"Zahlungsoption löschen",
      "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Zahlungsoptionsfehler löschen",
      "EVENT_DESC_DELETE_PLAN":"Plan löschen",
      "EVENT_DESC_DELETE_PLAN_FAILURE":"Planfehler löschen",
      "EVENT_DESC_DELETE_QUOTE_FAILED":"Löschen des Angebots fehlgeschlagen",
      "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Angebot löschen erfolgreich",
      "EVENT_DESC_DELETE_SITE_FAILURE":"Site-Fehler löschen",
      "EVENT_DESC_DELETE_SITE_SUCCESS":"Site-Erfolg löschen",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Löschen der Abonnementablaufprüfung",
      "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Löschen des Abonnements nach Ablauf fehlgeschlagen",
      "EVENT_DESC_DELETE_TAX_RULE":"Steuerregel löschen",
      "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Steuerregelfehler löschen",
      "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Upgrade des Azure-Abonnements fehlgeschlagen",
      "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Benutzer für Nutzungsabonnement löschen",
      "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Benutzerfehler beim Abonnement löschen",
      "EVENT_DESC_DELETE_USER":"Benutzer löschen",
      "EVENT_DESC_DELETE_USER_FAILURE":"Benutzerfehler löschen",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Fehler beim Löschen der Webhook-Benachrichtigung",
      "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Webhook-Benachrichtigung erfolgreich löschen",
      "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Deaktivieren Sie das Azure-Abonnement",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht deaktiviert werden",
      "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Gutscheinzuweisung deaktiviert",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Deaktivieren Sie den Plan für den Kunden",
      "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"Plan für Kunden konnte nicht deaktiviert werden",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Bericht zu geplanten Rechnungspositionen",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Geplante Rechnungspositionen mit Provisionsbericht",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Geplanter Rechnungszusammenfassungsbericht",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Geplanter Rentabilitätsbericht",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Bericht zu geplanten Steuerpositionen",
      "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Geplanter Steuerzusammenfassungsbericht",
      "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Aktivieren Sie das Azure-Abonnement",
      "EVENT_DESC_ENABLE_COUPON":"Coupon erfolgreich aktiviert",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht aktiviert werden",
      "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Couponzuweisung aktiviert",
      "EVENT_DESC_ENABLE_COUPON_FAILED":"Die Couponaktivierung ist fehlgeschlagen.",
      "EVENT_DESC_ENABLE_COUPON_FAILURE":"Coupon konnte nicht aktiviert werden",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Plan dem Kunden zuweisen",
      "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Fehler beim Zuweisen des Plans zum Kunden",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Aktivieren Sie die Benachrichtigung für den Ablauf des Jahresabonnements",
      "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Aktivieren Sie die Benachrichtigung, wenn ein monatliches Abonnement verlängert wird",
      "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Anforderung für Bulk Onboarding-Kunden konnte nicht in die Warteschlange gestellt werden",
      "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Anfrage zum Klonen des Reseller-Plans in die Warteschlange gestellt",
      "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Fehlerprotokoll Benachrichtigungsbericht",
      "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Die geplante Erneuerung des Updates ist fehlgeschlagen",
      "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Kundenlizenzbericht exportieren.",
      "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Benutzerlizenzzuweisungsbericht exportieren",
      "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"Die nächste geplante Verlängerungsanfrage konnte nicht genehmigt oder abgelehnt werden",
      "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Das Abbrechen der geplanten Verlängerung ist fehlgeschlagen",
      "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"Reseller-Plan konnte nicht geklont werden",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"Verbindung zur Buchhaltungssoftware konnte nicht hergestellt werden",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"Verbindung zum Microsoft Partner Center konnte nicht hergestellt werden",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"Fehler beim Herstellen einer Verbindung mit der Microsoft-Preis-API",
      "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"Verbindung zu PSA konnte nicht hergestellt werden",
      "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"On-Demand-Rechnung konnte nicht erstellt werden",
      "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Die Erstellung einer geplanten Verlängerung ist fehlgeschlagen",
      "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"Anfrage zum Klonen des Reseller-Plans konnte nicht in die Warteschlange gestellt werden",
      "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Fehler beim Festlegen des Batch-Onboard-Batch-Status auf \\''Abgeschlossen\\''",
      "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Fehler beim Setzen des Stapelstatus der Benutzerlizenzzuweisung auf \\''Abgeschlossen\\''",
      "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"Anbieter-Kundenprofil konnte nicht synchronisiert werden",
      "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"On-Demand-Rechnung konnte nicht aktualisiert werden",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung automatisch",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Fix Auto Renew status Mismatch Automatically Failed",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung manuell",
      "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung manuell fehlgeschlagen",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Angebotskonflikt erfolgreich behoben (Auto Sync)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Fehler bei der Behebung von Angebotskonflikten (Auto Sync)",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Beheben Sie den Angebotskonflikt manuell",
      "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Angebotskonflikt manuell beheben fehlgeschlagen",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Mengenabweichungen erfolgreich behoben (Auto Sync)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Behebung der Mengenabweichung fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Mengenabweichung erfolgreich behoben (manuelle Sync)",
      "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Behebung der Mengenabweichung fehlgeschlagen (manuelle Sync)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Statuskonflikt erfolgreich behoben (Auto Sync)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Behebung von Statuskonflikten fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Statuskonflikt erfolgreich behoben (manuelle Sync)",
      "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"Beheben von Statuskonflikten fehlgeschlagen (manuelle Sync)",
      "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Diskrepanz beim Lizenzverbrauch",
      "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Gewähren Sie Zugriff auf Portalfehler",
      "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Gewähren Sie Zugriff auf das Portal",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Fehler beim Hochladen von Massenbenutzern ignorieren",
      "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Fehler beim Hochladen von Massenbenutzern ignorieren",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Fehler beim Kauf von Abonnements ignorieren",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Fehler beim Abonnement-Update ignorieren",
      "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Fehler bei Abonnementaktualisierungsfehlern ignorieren",
      "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Ignorieren Sie den Fehler beim Kauf eines Abonnements",
      "EVENT_DESC_INVOICE_AND_REPORT":"Rechnung und Bericht",
      "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Rechnungszahlung eingeleitet",
      "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Rechnungs- und Gebührenbericht",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Linkabrechnungsanbieter Kundenfehler",
      "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Link Billing Provider Kundenerfolg",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Kundenfehler des Verbindungsdienstanbieters",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Link Service Provider Kundenerfolg",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Nicht-csp-Kundenfehler des Verbindungsdienstanbieters",
      "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Link Service Provider nicht-csp Kundenerfolg",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Link Billing Provider Kundenerfolg",
      "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Linkabrechnungsanbieter Kundenfehler",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Kundenfehler des Verbindungsdienstanbieters",
      "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Link Service Provider Kundenerfolg",
      "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Verwaltung von Benutzerlizenzen fehlgeschlagen.",
      "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"Die Verwaltung der Benutzerlizenzen war erfolgreich.",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Planen Sie die nächsten Verlängerungsänderungen",
      "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"Die nächsten Verlängerungsänderungen konnten nicht geplant werden",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Kaufanfrage für NI Partner-Produkte akzeptiert",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Kaufanfrage für NI Partner-Produkte storniert",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"NI Partner Produkt gekauft",
      "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"NI Partner Produktmenge aktualisiert",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Benachrichtigen Sie den Aktivierungsstatus des NI Provisioning-Produktkaufs",
      "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Benachrichtigen Sie den Status von NI Provisioning Product Purchase Cancelled",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Angebot erfolgreich geändert (Auto Sync)",
      "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Angebotsänderung fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Angebot erfolgreich geändert (manuelle Sync)",
      "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Angebotsänderung fehlgeschlagen (manuelle Sync)",
      "EVENT_DESC_OFFERS_ARE_PURCHASED":"Bestellung anlegen",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Integrierter Azure-Plan erfolgreich (Auto Sync)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Onboarding des Azure-Plans fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Integrierter Azure-Plan erfolgreich (manuelle Sync)",
      "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Onboarding des Azure-Plans fehlgeschlagen (manuelle Sync)",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Kundenversagen an Bord",
      "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Kundenversagen an Bord",
      "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Kundenerfolg an Bord",
      "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Der Partner bietet exportierbare Berichte an",
      "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Fehler des Partnerbenutzers hinzugefügt",
      "EVENT_DESC_PARTNER_USERADDED":"Partnerbenutzer hinzugefügt",
      "EVENT_DESC_PASSWORD_EXPIRED":"Passwort abgelaufen",
      "EVENT_DESC_PAYMENT_FAILURE":"Zahlungsfehler",
      "EVENT_DESC_PAYMENT_SUCCESS":"Zahlungserfolg",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Die erfasste Sicherheitseinwilligung des Partner Centers ist fehlgeschlagen",
      "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Partner Center-Sicherheitsgenehmigung erfolgreich erfasst",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip für alle Rechnungen erfolgreich erstellt.",
      "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Beim Erstellen von Zip für alle Rechnungen ist ein Fehler aufgetreten.",
      "EVENT_DESC_PRODUCT_ASSIGNMENT":"Produktzuweisung",
      "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Produktzuweisungsfehler",
      "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Angebote erfolgreich geladen",
      "EVENT_DESC_PROMOTION_LOADING_FAILED":"Angebote konnten nicht geladen werden",
      "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Preis für Anbieterprodukte aktualisiert",
      "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Bitte um Unterstützung bei der öffentlichen Anmeldung",
      "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"Bundle-Kauf fehlgeschlagen",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Kauf eines neuen Partnerangebots",
      "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Kauf eines neuen benutzerdefinierten Angebots fehlgeschlagen",
      "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Kaufen Sie das NI Provisioning-Produkt",
      "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Kaufanfrage ausstehende Genehmigung",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Bestellung anlegen",
      "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Bestellfehler erstellen",
      "EVENT_DESC_QUOTATION_CUSTOMER":"Angebot Kunde",
      "EVENT_DESC_QUOTATION_NAME":"Zitatname",
      "EVENT_DESC_QUOTATION_OWNER":"Zitatbesitzer",
      "EVENT_DESC_QUOTE_APPROVAL":"Angebotsfreigabe",
      "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Angebotskonvertierung erfolgreich",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Erhöhte Anfrage zur Aktualisierung des gekauften Produkts",
      "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"Anfrage zum Aktualisieren des Kaufprodukts fehlgeschlagen",
      "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Untersuchen Sie Diskrepanzen bei der Erstellung von Vorausrechnungen",
      "EVENT_DESC_RECORD_PAYMENT":"Rekordzahlung",
      "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Zahlungsausfall aufzeichnen",
      "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Kaufanfrage abgelehnt",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Abgelehnte Geschäftsbedingungen",
      "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Abgelehnte Geschäftsbedingungen scheitern",
      "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Aktualisierung des Reseller-Namens fehlgeschlagen",
      "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Reseller-Name erfolgreich aktualisiert",
      "EVENT_DESC_RESET_USER_PASSWORD":"Benutzerkennwort zurücksetzen",
      "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Fehler beim Zurücksetzen des Benutzerkennworts",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Anspruch auf Preisrücknahme erfolgreich",
      "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Fehler beim Zurücksetzen des Anspruchspreises",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION":"TenantConfiguration zurücksetzen",
      "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"TenantConfiguration-Fehler zurücksetzen",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Zugriff auf Portal widerrufen",
      "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Zugriff auf Portalfehler widerrufen",
      "EVENT_DESC_SAVE_COMMENT":"Neuer Kommentar hinzugefügt",
      "EVENT_DESC_SAVE_CONTRACT_OFFER":"Vertragsangebot erfolgreich gespeichert",
      "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"Speichern des Vertragsangebots fehlgeschlagen",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Speichern der benutzerdefinierten Benachrichtigung",
      "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Speichern Sie den Erfolg der benutzerdefinierten Benachrichtigung",
      "EVENT_DESC_SAVE_CUSTOM_OFFER":"Benutzerdefiniertes Angebot speichern",
      "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Benutzerdefinierten Angebotsfehler speichern",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Fehler beim Speichern des Kundenabrechnungsprofils",
      "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Kundenabrechnungsprofil speichern",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Kunden in Abrechnung speichern",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Kunden bei Abrechnungsfehler speichern",
      "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Fehler beim Speichern des Kunden bei der Abrechnung",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Kundenzahlungsprofil speichern",
      "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Fehler beim Speichern des Kundenzahlungsprofils",
      "EVENT_DESC_SAVE_CUSTOMER_USER":"Kundenbenutzer speichern",
      "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Speichern Sie Kundenfehler",
      "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Abteilungsfehler speichern",
      "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Abteilungserfolg speichern",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Händlerangebot speichern",
      "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Fehler beim Speichern des Händlerangebots",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Sparen Anspruch Preis Erfolg",
      "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Fehler beim Speichern des Anspruchspreises",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung speichern",
      "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Speichern der E-Mail-Benachrichtigung des Ereignisses",
      "EVENT_DESC_SAVE_INVITES":"Einladungen speichern",
      "EVENT_DESC_SAVE_INVITES_FAILURE":"Speichern lädt Fehler ein",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Rechnungsanpassungen speichern",
      "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Fehler beim Speichern von Rechnungsanpassungen",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Partnerpaket speichern",
      "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Save Partner Bundle Failure",
      "EVENT_DESC_SAVE_PARTNER_USER":"Partnerbenutzer speichern",
      "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Partner-Benutzerfehler speichern",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Zahlungsprofil speichern",
      "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Zahlungsprofil konnte nicht gespeichert werden",
      "EVENT_DESC_SAVE_PLAN":"Plan speichern",
      "EVENT_DESC_SAVE_PLAN_FAILURE":"Planfehler speichern",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Planprodukte in Zielwährung speichern",
      "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Speichern Sie Planprodukte in Zielwährungsfehler",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Einwilligung des gespeicherten Anbieterkunden",
      "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Fehler beim Speichern der Kundeneinwilligung des Anbieters",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Provider-Zahlung speichern",
      "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Zahlungsfehler des Anbieters speichern",
      "EVENT_DESC_SAVE_QUOTE_FAILED":"Angebot konnte nicht gespeichert werden",
      "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Angebot erfolgreich gespeichert",
      "EVENT_DESC_SAVE_RESELLER":"Reseller speichern",
      "EVENT_DESC_SAVE_RESELLER_FAILURE":"Reseller konnte nicht gespeichert werden",
      "EVENT_DESC_SAVE_SITE_FAILURE":"Site-Fehler speichern",
      "EVENT_DESC_SAVE_SITE_SUCCESS":"Speichern Sie den Site-Erfolg",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Fehler beim Speichern der Benutzerkonfiguration",
      "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Erfolgreiche Benutzerkonfiguration speichern",
      "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Webnachrichtenbenachrichtigung speichern",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Fehler beim Speichern der Webhook-Benachrichtigung",
      "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Erfolgreiche Webhook-Benachrichtigung speichern",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Die Annahme der Allgemeinen Geschäftsbedingungen ist fehlgeschlagen",
      "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Allgemeine Geschäftsbedingungen erfolgreich akzeptiert",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"Der Versuch, die hinzuzufügenden/zu entfernenden Lizenzen bereitzustellen, ist fehlgeschlagen.",
      "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Die Lizenzen, die hinzugefügt/entfernt werden sollen, wurden erfolgreich bereitgestellt.",
      "EVENT_DESC_SCHEDULE_ORDER":"Reihenfolge einplanen",
      "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Der Zeitplanauftrag ist fehlgeschlagen",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Bericht zu geplanten Rechnungspositionen",
      "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Geplante Rechnungspositionen mit Provisionsbericht",
      "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Geplanter Rechnungszusammenfassungsbericht",
      "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Geplanter Rentabilitätsbericht",
      "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Bericht zu geplanten Steuerpositionen",
      "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Geplanter Steuerzusammenfassungsbericht",
      "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Aktualisierung des Händlerangebots senden",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Senden Sie die Rechnung im PDF-Format über die Benutzeroberfläche an den Kunden",
      "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Senden Sie die Rechnung im PDF-Format transparent an den Kunden",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Senden Sie Warnungen zu Nutzungsschwellenwerten für Abonnements",
      "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Schwellenwertwarnungen für Abonnementnutzung fehlgeschlagen",
      "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Setzen Sie den Stapelstatus der Benutzerlizenzzuweisung auf \\''Abschließen\\''",
      "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"SMTP-Verbindung fehlgeschlagen",
      "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Benachrichtigung über den Ablauf des Abonnements für das Testangebot",
      "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Bericht zum Abonnementverlauf",
      "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Aussetzen, wenn die Gefahrenschwelle erreicht ist",
      "EVENT_DESC_SUBSCRIPTION_SYNC":"Abonnementsynchronisierung",
      "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Abonnement-Synchronisierungsfehler",
      "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonnement aktualisieren",
      "EVENT_DESC_SUCCESS":"Erfolg",
      "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"Der nächste geplante Verlängerungsantrag wurde erfolgreich genehmigt oder abgelehnt",
      "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Reseller-Plan erfolgreich geklont",
      "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"On-Demand-Rechnung erfolgreich erstellt",
      "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Anbieter-Kundenprofil erfolgreich synchronisiert",
      "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"On-Demand-Rechnung erfolgreich aktualisiert",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Fehler beim Anhalten des Abonnements",
      "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Abonnement erfolgreich aussetzen",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Aussetzen, wenn die Gefahrenschwelle erreicht ist",
      "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Unterbrechen, wenn der Gefahrenschwellenwert erreicht ist. Fehler",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"Die Zustimmung des Synchronisierungsanbieters konnte nicht synchronisiert werden",
      "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Zustimmung des synchronisierten Anbieterkunden",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Abonnements nach PC-Fehler synchronisieren",
      "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Abonnements vom PC synchronisieren erfolgreich",
      "EVENT_DESC_TAGS_ADDED":"Tags hinzugefügt",
      "EVENT_DESC_TAGS_ADDED_FAILURE":"Tags Fehler hinzugefügt",
      "EVENT_DESC_TAGS_DELETE_DFAILURE":"Tags Gelöschter Fehler",
      "EVENT_DESC_TAGS_DELETED":"Tags gelöscht",
      "EVENT_DESC_TAGS_EDITED":"Tags bearbeitet",
      "EVENT_DESC_TAGS_EDITED_FAILURE":"Tags bearbeitet Fehler",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"Laufzeit erfolgreich geändert (Auto Sync)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Ändern der Laufzeit fehlgeschlagen (Auto Sync)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"Vertragsdauer erfolgreich geändert (manuelle Sync)",
      "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Ändern der Laufzeit fehlgeschlagen (manuelle Sync)",
      "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Nicht synchronisierte Produktdetails",
      "EVENT_DESC_UPDATE_AZURE_GROUPS":"Aktualisieren Sie Azure-Gruppen",
      "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Aktualisieren Sie den Azure-Gruppenfehler",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement",
      "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Aktualisieren Sie den Azure-Abonnementfehler",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Vertragsangebot erfolgreich aktualisiert",
      "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"Aktualisierung des Vertragsangebots fehlgeschlagen",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Aktualisieren Sie den benutzerdefinierten Abrechnungszeitraum",
      "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"Aktualisieren des benutzerdefinierten Abrechnungszeitraums fehlgeschlagen",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Aktualisieren der benutzerdefinierten Benachrichtigung",
      "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Benutzerdefinierte Benachrichtigung erfolgreich aktualisieren",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Aktualisieren Sie die Angebotsmenge des Partners",
      "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Fehler bei der Aktualisierung der Partnerangebotsmenge",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Die Standardzahlungsoption wurde aktualisiert",
      "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Fehler beim Aktualisieren der Standardzahlungsoption",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Aktualisieren Sie das Kundenzahlungsprofil",
      "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Fehler beim Aktualisieren des Kundenzahlungsprofils",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Kundenprofil aktualisiert",
      "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Aktualisierung des Kundenprofils fehlgeschlagen",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Aktualisieren Sie die Standardzahlungsoption",
      "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Fehler beim Aktualisieren der Standardzahlungsoption",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung aktualisieren",
      "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Aktualisieren der Ereignis-E-Mail-Benachrichtigung",
      "EVENT_DESC_UPDATE_GROUP_ADMIN":"Gruppenadministrator aktualisieren",
      "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Update Group Admin Failure",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":"Fehler bei aktualisierten Rechnungseigenschaften",
      "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Rechnungseigenschaften erfolgreich aktualisiert",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Aktualisieren Sie das Auto-Release-Flag",
      "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"Flag für automatische Freigabe aktualisieren fehlgeschlagen",
      "EVENT_DESC_UPDATE_LICENSE":"Lizenz aktualisieren",
      "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Lizenzfehler aktualisieren",
      "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Fehler beim Aktualisieren der Partnercenter-Einstellungen",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Aktualisieren Sie die allgemeinen Partnereinstellungen",
      "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Fehler beim Aktualisieren der allgemeinen Einstellungen des Partners",
      "EVENT_DESC_UPDATE_PARTNER_LOGO":"Partnerlogo aktualisieren",
      "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Fehler beim Aktualisieren des Partnerlogos",
      "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Aktualisieren Sie die Partner Center-Einstellungen",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Plan Angebotspreis aktualisieren",
      "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Aktualisieren des Planangebotspreises",
      "EVENT_DESC_UPDATE_PROFILE":"Profil aktualisieren",
      "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Profilfehler aktualisieren",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Anbietereinstellungen aktualisieren",
      "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Fehler beim Aktualisieren der Anbietereinstellungen",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Produktattribute aktualisiert",
      "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Produktattribute konnten nicht aktualisiert werden",
      "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Aktualisieren Sie das NI-Bereitstellungsprodukt",
      "EVENT_DESC_UPDATE_QUOTE_FAILED":"Angebot konnte nicht aktualisiert werden",
      "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Angebot erfolgreich aktualisiert",
      "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Geplante Erneuerung erfolgreich aktualisieren",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Der Standardwert für den Kunden des Dienstanbieters wurde aktualisiert",
      "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Fehler beim Aktualisieren des Standardwerts des Service Provider-Kunden",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Aktualisieren Sie die SMTP-Einstellungen",
      "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Fehler beim Aktualisieren der SMTP-Einstellungen",
      "EVENT_DESC_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Überprüfung des Ablaufs des Update-Abonnements",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Überprüfung des Ablaufdatums des Update-Abonnements fehlgeschlagen",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Abonnementfehler aktualisieren",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":"API-Update-Abonnement",
      "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Fehler beim API-Aktualisierungsabonnement",
      "EVENT_DESC_UPDATE_SUBTAX":"Subtax aktualisieren",
      "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Subtax aktualisieren fehlgeschlagen",
      "EVENT_DESC_UPDATE_TAX_RULE":"Steuerregel aktualisieren",
      "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Steuerregelfehler aktualisieren",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Aktualisieren Sie die Mandantenkonfiguration",
      "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Update TenantConfiguration Failure",
      "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Benutzer des Nutzungsabonnements aktualisieren",
      "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Benutzerfehler beim Aktualisieren des Nutzungsabonnements",
      "EVENT_DESC_UPDATE_USER":"Benutzer aktualisieren",
      "EVENT_DESC_UPDATE_USER_FAILURE":"Benutzerfehler aktualisieren",
      "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Aktualisieren Sie die WebMessage-Benachrichtigung",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Fehler bei der Aktualisierung der Webhook-Benachrichtigung",
      "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Webhook-Benachrichtigung erfolgreich aktualisieren",
      "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"Gutscheinzuweisung fehlgeschlagen",
      "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Gutschein erfolgreich zugewiesen",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Die Massenproduktzuordnung ist fehlgeschlagen",
      "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"Die Massenproduktzuordnung war erfolgreich",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Der Aktualisierungsprozess für externe Serviceeinheiten in C3 wird eingeleitet",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" Der Aktualisierungsprozess für externe Serviceentitäten in C3 ist fehlgeschlagen",
      "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" Der Aktualisierungsprozess für externe Serviceeinheiten in C3 war erfolgreich",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Abonnement-Upgrade",
      "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Abonnement-Upgrade fehlgeschlagen",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"Der Kundenkatalog konnte nicht heruntergeladen werden.",
      "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Kundenkatalog erfolgreich heruntergeladen.",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Download Produktkatalog Erfolg",
      "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Fehler beim Herunterladen des Produktkatalogs",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Fehler im Produktprovisionskatalog herunterladen",
      "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Laden Sie den Produktprovisionskatalog herunter Erfolg",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"Fehler beim Herunterladen des Berichtsnutzungskatalogs",
      "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Laden Sie den Berichtsnutzungskatalog erfolgreich herunter",
      "EVENT_ENTITLEMENT_ID":"Berechtigungs-ID",
      "EVENT_ENTITLEMENT_NAME":"Entitätsname",
      "EVENT_FILE_NAME":"Dateinamen",
      "EVENT_IMPERSONATOR":"Imitator",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Fehler beim Importieren mehrerer Zahlungen",
      "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Importieren Sie den Erfolg mehrerer Zahlungen",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Validierte Daten konnten nicht importiert werden",
      "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Validierte Daten erfolgreich importiert",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Fehler beim Importieren von Produktprovisionen",
      "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Importieren Sie Produktprovisionen erfolgreich",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Fehler beim Importieren von Produkterweiterungen",
      "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Produkterweiterungen erfolgreich importieren",
      "EVENT_IMPORT_VOUCHER_FAILURE":"Die Gutscheine konnten nicht importiert werden.",
      "EVENT_IMPORT_VOUCHER_SUCCESS":" Die Gutscheine wurden erfolgreich importiert.",
      "EVENT_IS_LICENSE_ASSIGNED":"Lizenz zugewiesen ist",
      "EVENT_IS_LICENSE_REMOVED":"Ist die Lizenz entfernt",
      "EVENT_LINK_RESELLER_TO_PROVIDER":"Link Reseller",
      "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Reseller konnte nicht verknüpft werden",
      "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"PSA-Entitätszuordnung ist fehlgeschlagen",
      "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"C3-Entität erfolgreich einer Entität in PSA zugeordnet",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"Das Entfernen der Zuordnung mit der PSA-Entität ist fehlgeschlagen",
      "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Zuordnung zur PSA-Entität erfolgreich entfernt",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"PSA-Produktzuordnung fehlgeschlagen",
      "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Zuordnung von C3-Produkten zu Produkten in PSA erfolgreich",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"Entfernen der Zuordnung mit PSA-Entität fehlgeschlagen",
      "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Zuordnung mit PSA-Entität erfolgreich entfernt",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Zuordnung zum PSA-Produkt erfolgreich entfernt",
      "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"Das Entfernen der Zuordnung mit dem PSA-Produkt ist fehlgeschlagen",
      "EVENT_NEW_CAN_PRICE_LAG":"NeuDosePreisVerzögerung",
      "EVENT_NEW_CAN_PRICE_LEAD":"NeuDosePreisBlei",
      "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Neues Sitzplatzlimit auf Planebene",
      "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Führen Sie als Nächstes den Enque-Fehlermelder aus",
      "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Bitte wählen Sie mindestens einen Empfängertyp aus.",
      "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Vorschau der E-Mail",
      "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"E-Mail-Benachrichtigung erfolgreich gelöscht.",
      "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
      "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
      "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"aktiviert",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Empfängertyp",
      "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Empfänger",
      "EVENT_NOTIFICATION_LABLE_TEXT_TO":"Zu",
      "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"E-Mail-Benachrichtigungseinstellungen erfolgreich gespeichert.",
      "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Bitte klicken Sie auf die",
      "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"um den Inhalt der E-Mail-Vorlage anzuzeigen.",
      "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"BCC-Empfänger",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"CC-Empfänger",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Veranstaltung",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Veranstaltungsname",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Aktiviert?",
      "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"An die Empfänger",
      "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Benachrichtigen Sie die Kundenerstellung durch die Anmeldung beim Kunden",
      "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Benachrichtigung über Kauflimitfehler",
      "EVENT_Notify_Purchase_Limit_Error":"Fehler beim Kauflimit benachrichtigen",
      "EVENT_OLD_CAN_PRICE_LAG":"OldCanPriceLag",
      "EVENT_OLD_CAN_PRICE_LEAD":"AltDosePreisBlei",
      "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Sitzplatzbegrenzung auf alter Planebene",
      "EVENT_PRODUCT_NAME":"Produktname",
      "EVENT_PURCHASE_APPROVE_REASON":"Grund",
      "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Grund, falls fehlgeschlagen",
      "EVENT_PURCHASE_PRODUCT_ID":"Produkt ID",
      "EVENT_PURCHASE_PRODUCT_NAME":"Produktname",
      "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Planprodukt-ID",
      "EVENT_PURCHASE_PRODUCT_QUANTITY":"Menge",
      "EVENT_PURCHASE_REASON_ONHOLD":"Grund fürs Anhalten",
      "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Senden Sie Rechnungen im PDF-Format an den Partner",
      "EVENT_SITE_DESCRIPTION":"Seitenbeschreibung",
      "EVENT_SITE_ID":"Webseitenadresse",
      "EVENT_SITE_NAME":"Seite-Name",
      "EVENT_SMTP_TEST_EMAIL":"SMTP-Test-E-Mail",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Fehler bei Importzahlungen erkennen und validieren",
      "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Inszenieren und validieren Sie den Erfolg von Importzahlungen",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Fehler beim Bereitstellen und Validieren des Partnernutzungsberichts",
      "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Bereitstellen und Validieren des Partnernutzungsberichts",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Produktkatalogfehler inszenieren und validieren",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Inszenieren und validieren Sie den Erfolg des Produktkatalogs",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Stage und validieren Sie das Scheitern von Produktprovisionen",
      "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Inszenieren und validieren Sie den Erfolg von Produktprovisionen",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"Gutscheinkatalog konnte nicht bereitgestellt und validiert werden.",
      "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Erfolgreich erstellter und validierter Gutscheinkatalog.",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Benachrichtigung über den Ablauf des Abonnements",
      "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Benachrichtigung für Kunden über Ablauf des Abonnements",
      "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Benachrichtigung über den Ablauf des Abonnements für das Testangebot",
      "EVENT_TOTAL_RECORD_COUNT":"Rekordzahl",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Reslink zum Reseller aufheben",
      "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Reseller konnte nicht freigegeben werden",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Produktdetails zur Partnernutzung aktualisiert",
      "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Produktdetails zur Partnernutzung konnten nicht aktualisiert werden",
      "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Benachrichtigung zum Aktualisierungsplanangebot für Wiederverkäufer",
      "EVENT_USER_CONFIGURATION_NAME":"Benutzerkonfigurationsname",
      "EVENT_USER_CONFIGURATION_VALUE":"Benutzerkonfigurationswert",
      "EVENT_USER_EMAILID ":"Benutzer-E-Mail-ID",
      "EVENT_VOUCHER_ASSIGNMENT":"Gutscheinzuordnung",
      "EVENT_WEBJOB_STATUS_NOTIFIER":"Web-Job-Statusbenachrichtigung",
      "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Neue Bündel-Beschreibung",
      "EVNET_DATA_NEW_BUNDLE_NAME":"Neue Bündel-name",
      "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Alte Bündelbeschreibung",
      "EVNET_DATA_OLD_BUNDLE_NAME":"Alter Bündelname",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Bulk-Onboard-Reseller-Anfrage akzeptiert",
      "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"Akzeptieren der Bulk-Onboard-Reseller-Anfrage fehlgeschlagen",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"Das Onboarding des Wiederverkäufers im Rahmen des Massen-Onboardings ist fehlgeschlagen",
      "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"Das Onboarding des Wiederverkäufers im Rahmen des Massen-Onboardings wurde erfolgreich abgeschlossen",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"Das Massen-Onboarding des Wiederverkäufers ist fehlgeschlagen",
      "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Massen-Onboarding für Wiederverkäufer erfolgreich",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Bulk-Add-Berechtigungen abgeschlossen",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Bulk-Hinzufügen von Berechtigungen fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Anfrage zum Massenhinzufügen von Berechtigungen akzeptiert",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"Anforderung zum Massenhinzufügen von Berechtigungen fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Prozess zum Massenhinzufügen von Mandanten abgeschlossen",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"Prozess zum Massenhinzufügen von Mandanten fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Anfrage zum Massenhinzufügen von Mietern akzeptiert",
      "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"Anfrage zum Massenhinzufügen von Mandanten fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Prozess für das Massen-Onboarding von Resellern abgeschlossen",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Der Prozess für das Bulk-Onboarding von Resellern ist fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Prozess für Massen-Onboarding von Wiederverkäufern eingeleitet",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Entfernen der Reseller-Beziehung abgeschlossen",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Das Entfernen der Reseller-Beziehung ist fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Die Anfrage zum Entfernen einer Reseller-Beziehung ist fehlgeschlagen",
      "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Antrag auf Entfernung des Reseller-Beziehungsmieters angenommen",
      "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Beim erneuten Versuch, die Webhook-Benachrichtigung zu senden, ist ein Fehler aufgetreten",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Beim Speichern oder Aktualisieren benutzerdefinierter Benachrichtigungsdetails ist ein Fehler aufgetreten",
      "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Fehler beim Speichern der Webhook-Benachrichtigungsdetails",
      "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Beim Versuch, aus dem Testangebot einen Warenkorb für das Hauptangebot zu erstellen, ist ein Fehler aufgetreten.",
      "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Fehler beim Aktualisieren der bereitgestellten Daten für Benachrichtigungsdetails",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Beim Senden der Webhook-Benachrichtigung ist ein Fehler aufgetreten",
      "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"Die angegebene Webhook-URL ist ungültig",
      "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Es gibt keine zugeordneten Produkte für die Änderung des Abrechnungszyklus. Bitte wenden Sie sich an das Support-Team.",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Beim Erstellen eines neuen Anbieterkunden ist eine Ausnahme aufgetreten, da die Annahme der Anbieterkundenvereinbarung vom Partner nicht bestätigt wird",
      "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"Die Annahme der Anbieter-Kunden-Vereinbarung sollte bestätigt werden",
      "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Beim Überprüfen, ob der Benutzer die Allgemeinen Geschäftsbedingungen des Partners akzeptiert hat, ist ein Fehler aufgetreten",
      "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Beim Abrufen des Inhalts der Partnerbedingungen ist ein Fehler aufgetreten",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Beim Abrufen der Akzeptanzdetails für die Zustimmung des Anbieters ist ein Fehler aufgetreten",
      "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Beim Abrufen von Provider-Kunden, die keine Provider-Kunden-Zustimmung erteilt haben, ist ein Fehler aufgetreten",
      "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Beim Speichern der Akzeptanzdetails der Partnerbedingungen ist ein Fehler aufgetreten",
      "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Beim Speichern der Zustimmung des Anbieterkunden ist ein Fehler aufgetreten",
      "EXISTING":"Bestehende",
      "EXISTING_REFRESH_TOKEN":"Vorhandenes Aktualisierungstoken",
      "EXPAND_ALL_PAYMENTS":"Erweitern Sie Zahlungen",
      "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Möchten Sie diesen Datensatz wirklich löschen?",
      "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"Die Ablaufprüfung wurde erfolgreich gelöscht",
      "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Die Ablaufprüfungen wurden erfolgreich gespeichert",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Filter schließen",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Kundenname",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie Kunde oder Wiederverkäufer aus",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Suche nach Kunde",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Wählen Sie Kunde",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Wählen Sie einen Status aus",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Status",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Zurücksetzen",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Suche",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Suche nach Status",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Verlassen",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Deaktiviert",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Error",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Gescheitert",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"In Bearbeitung",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"In Warteschlange",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Gestoppt",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Erfolg",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Warnung",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Rechnungskreislauf",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Kunde",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Endtermin",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Fehlerdetails",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Exportfehler",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Exportstatus",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Kundenname",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"Protokollnachricht",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Vorgangsname",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Preis",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Anbieterpreis",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Menge",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Anfangsdatum",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Status",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Statuszählung",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Aktion",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Datum des Abonnementverlaufs",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Validierungsstatus",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Validierungsfehler",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Abteilung",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Seite ",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Detail",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Zusammenfassung",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Gescheitert",
      "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Erfolg",
      "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Die lokale Datenaktualisierung wurde von einem externen Dienst initiiert",
      "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Gelöscht",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_ACTIVE_OFFER_IN_C3_CATALOGUE":"Angebot in C3 kann nicht gefunden werden",
      "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"Es konnten keine Vertragsdetails lokal gefunden werden, um eine Zuordnung zu erstellen.",
      "EXTERNAL_SERVICE_IN_ACTIVE":"Inaktiv",
      "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Post-Log-Status des externen Dienstes",
      "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abbruch des Uploads aufgrund eines Fehlers beim Erstellen von Entitäten",
      "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Hinzu kommt aktives ConnectWise Manage",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Vereinbarungs-ID fehlt",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Die Rechnungsstellung ist für diese Vereinbarung in ConnectWise Manage nicht aktiviert",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Die zugeordnete Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht aktiv",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Die Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Vereinbarungen werden für diesen Kunden nicht zugeordnet",
      "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Vereinbarungen sind in ConnectWise Manage unter diesem Kunden nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Es liegen mehrdeutige Zusätze vor, eine Zuordnung ist nicht möglich.",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Vertrags-ID fehlt",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Die Rechnungsstellung ist für diesen Vertrag in Autotask nicht aktiviert",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Der zugeordnete Vertrag ist in Autotask unter diesem Kunden nicht aktiv",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Der Vertrag ist in Autotask unter diesem Kunden nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Bei diesem Kunden liegt kein Vertrag im Außendienst vor",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Vertragsservice wird diesem Kunden nicht zugeordnet",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Der zugeordnete Vertragsdienst ist in Autotask nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Vertragsleistungen werden diesem Kunden nicht zugeordnet",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Für diesen Kunden werden keine Verträge abgebildet",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Unter diesem Kunden sind in Autotask keine Verträge vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Verträge sind im externen Service unter diesem Kunden nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Der Kunde ist im externen Service nicht aktiv",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Externer Kunde ist nicht mit c3-Kunde verknüpft",
      "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Kunde ist im externen Service nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Entweder fehlt die Entitäts- oder Produktzuordnung.",
      "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":"Es wurde bereits ein Exportversuch unternommen. Der Status dieses Vorgangs ist",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Vertrag ist im externen Dienst nicht aktiv",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Externe Kunden-ID fehlt",
      "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Kunden sind im externen Dienst nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Bei der Erstellung eines Zusatzes in ConnectWise Manage ist ein Fehler aufgetreten",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":"Bei der Erstellung einer Anpassung ist ein Fehler aufgetreten:",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Fehler beim Erstellen einer Anpassung für eine Preisänderung aufgetreten",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Fehler beim Erstellen einer Dienstleistung",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Fehler bei der Erstellung einer Vertragsgebühr für ein einmaliges Produkt",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":"Fehler beim Erstellen einer Vertragsdienstleistung :",
      "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Fehler beim Aktualisieren des Zusatzes in ConnectWise Manage aufgetreten",
      "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Es konnte keine Zuordnung erstellt werden, da mehrere übereinstimmende Dienste in Autotask gefunden wurden",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Ein passender Vertrag ist für die automatische Zuordnung nicht verfügbar.",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Es konnte kein passender externer Kunde gefunden werden",
      "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Es konnte kein passendes externes Produkt gefunden werden",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Das Produkt ist nicht unter diesem Kunden zugeordnet",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Das zugeordnete Produkt ist in ConnectWise Manage nicht vorhanden",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Produkte sind unter diesem Kunden nicht zugeordnet",
      "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"C3 Produkte haben keinen zugeordneten externen Dienst",
      "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Service-ID fehlt",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Das Erstellungsdatum des Abonnementverlaufs darf nicht nach dem Datum der Vertragsauflösung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Das Erstellungsdatum des Abonnementverlaufs darf nicht nach dem Enddatum der Vereinbarung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht nach dem Datum der Vertragskündigung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht nach dem Vertragsenddatum liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Startdatum der Vertragsabrechnung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Startdatum der Vereinbarung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Beginn der Vertragsabrechnung liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Vertragsstartdatum liegen.",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Abonnement ist in aktiver Autotask",
      "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Das Abonnement ist im externen Service inaktiv",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Automatisches Mapping kann nicht abgeschlossen werden, da mehrere passende Verträge gefunden wurden",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Automatisches Mapping kann nicht abgeschlossen werden, da mehrere externe Kunden gefunden wurden",
      "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Das automatische Mapping kann nicht abgeschlossen werden, da mehrere externe Produkte gefunden wurden.",
      "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Validierung ist nicht erfolgreich",
      "EXTERNAL_SERVICE_NAME":"Name des externen Dienstes",
      "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Wählen Sie Kunden zum Validieren und Hochladen",
      "EXTERNAL_SERVICE_POST_BATCH_ID":"Externe Service-Post-Batch-ID",
      "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Fehler erfolgreich ignoriert",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Export",
      "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validierung",
      "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Externer Dienst Post protokolliert Status",
      "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Postprotokolle des externen Dienstes",
      "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Konnektivität testen",
      "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Anleitung",
      "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Klicken Sie bitte auf diese Schaltfläche, um die Verbindung zu PSA nach Abschluss der Konfiguration zu testen.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Die Verbindung wurde erfolgreich hergestellt. Sie können jetzt mit dem Hochladen der Abonnementprotokolle fortfahren.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Bitte wählen Sie mindestens einen Kunden aus",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"Die Verbindung zu Datto Autotask konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Konfigurationen auf der Einstellungsseite erneut.",
      "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"Die Verbindung zu ConnectWise Manage ist fehlgeschlagen. Bitte überprüfen Sie noch einmal Ihre Konfigurationen auf der Einstellungsseite",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"Das Enddatum sollte größer als das Startdatum sein",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Bitte wählen Sie das Enddatum",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"Das Startdatum darf nicht größer als das Enddatum sein",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Bitte wählen Sie das Startdatum",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Ihre Daten werden gerade hochgeladen. Sie können diese Seite für weitere Aktualisierungen erneut besuchen",
      "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"Die Validierung hat begonnen",
      "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Hinweis :",
      "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"Der digitale Schlüssel ist falsch oder verweigert den Zugriff auf diese Funktion. Die Validierung des digitalen Schlüssels ist fehlgeschlagen. Überprüfen Sie, ob Sie den richtigen digitalen Schlüssel verwenden.",
      "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Es gibt bereits einen Kunden mit der von Ihnen angegebenen Upload-Referenz.",
      "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Erstattung nicht zulässig. Für diese Transaktion wurde bereits eine Erstattung bearbeitet.",
      "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Ihr Status ist xxxxx und erlaubt es Ihnen nicht, die API zu verwenden.",
      "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"Bankkontonummer kann nicht leer sein",
      "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"Sie haben kein aktives BPay-Produkt",
      "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"Sie können den Status eines Kunden, der storniert wurde, nicht ändern.",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Sie müssen einen Wert für den Parameter \"ChangeFromDate\" oder \"PaymentReference\" angeben.",
      "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Sie müssen entweder für den Parameter \"ChangeFromDate\" oder \"ChangeFromPaymentNumber\" einen Wert angeben.",
      "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"Der Kunde ist NICHT aktiv.",
      "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"Das Jahr/Monat der Kreditkarte ist bereits abgelaufen.",
      "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"Es konnte kein Kunde mit den angegebenen Details gefunden werden.",
      "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Sie müssen einen Wert für den Parameter \"DateField\" angeben, wenn die Werte \"DateFrom\" oder \"DateTo\" angegeben werden.",
      "EZIDEBIT_ERROR_CODE_DATE_FROM":"Sie müssen einen Wert für die Parameter \"DateFrom\" oder \"DateTo\" angeben, wenn der Wert \"DateField\" angegeben wird.",
      "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom\" darf nicht mehr als xx Monate vor \"DateTo\" liegen.",
      "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Sie müssen einen Wert für den Parameter \"DebitDate\" angeben.",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"Der digitale Schlüssel ist falsch oder der Zugriff auf diese Funktion wird verweigert. Die Validierung des digitalen Schlüssels ist fehlgeschlagen. Überprüfen Sie, ob Sie den richtigen digitalen Schlüssel verwenden.",
      "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Ungültiges Token. Das Kunden-Token ist für diesen digitalen Schlüssel nicht gültig.",
      "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Sie müssen entweder einen Wert für den Parameter \"EziDebitCustomerID\" oder für den Parameter \"YourSystemReference\" angeben",
      "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Diese Funktion ist derzeit nicht verfügbar, da die Ezidebit-Verarbeitung gerade durchgeführt wird. Bitte versuchen Sie es später noch einmal...",
      "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Kunde nicht aktiv(Status:<Statuscode>).Kundenstatus ist nicht A (Aktiv) oder N (Neu)",
      "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Ungültige Kreditkartennummer eingegeben - Ihre Produktpalette enthält nicht xxxxxxx",
      "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"Der Kunde verfügt nicht über ausreichende Mittel zur Deckung dieses Anpassungsbetrags.",
      "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Ungültige Länge der Kontonummer",
      "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Unzulässiger Wert für den Parameter \"ApplyToAllFuturePayments\" angegeben. Gültige Werte sind: 'YES' oder 'NO'",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Ungültiges Format für den Parameter \"BankAccountBSB\". Gültige Werte dürfen nur sechs Ziffern sein",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Ungültige BSB-Nummer des Bankkontos eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Ungültige Bankkontonummer eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Unzulässiger Bankkontoname eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"BankReceiptId' konnte nicht ermittelt werden",
      "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Ungültige Kreditkartennummer eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Unzulässiger Wert für den Parameter 'ChangeFromPaymentNumber' angegeben. Gültige Werte müssen numerisch und größer oder gleich eins sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Ungültiger Wert für den Parameter \"ChangeFromDate\". Gültige Werte sind alle zukünftigen Daten im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Unzulässiger Wert für den Parameter \"ChangeToDate\". Gültige Werte sind ein beliebiges Datum im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Unzulässiger Wert für den Parameter \"ContractStartDate\". Gültige Werte sind alle zukünftigen Datumsangaben im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Unzulässiger Wert für den Parameter \"DateField\". Gültige Werte sind: 'PAYMENT' oder 'SETTLEMENT'",
      "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Ungültiger Wert für den Parameter \"DateFrom\". Gültige Werte sind jedes Datum im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Unzulässiger Wert für den Parameter \"DateTo\". Gültige Werte sind alle Datumsangaben im Format 'JJJJ-MM-TT'.",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Ungültiger Wert für den Parameter \"DayOfMonth\". Gültige Werte müssen zwischen 1 und 31 liegen.",
      "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Unzulässiger Wert für den Parameter \"DayOfWeek\". Gültige Werte sind: MON\", \"TUE\", \"WED\", \"THU\" oder \"FRI\".",
      "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Ungültiger Wert für den Parameter 'DebitDate'. Gültige Werte sind jedes zukünftige Datum im Format 'JJJJ-MM-TT'.",
      "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Ungültiger DigitalKey.",
      "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Ungültige Angaben zum Lastschrifteinzug - Ihr Produktangebot umfasst keine Lastschriften.",
      "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Ungültige E-Mail Adresse eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Ungültiger Gültigkeitsmonat der Kreditkarte eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"Ungültiges Jahr des Ablaufs der Kreditkarte eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Ungültiger Wert für den Parameter \"EzidebitCustomerID\" eingegeben. Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Ungültiger Wert für den Parameter \"SchedulePeriodType\". Gültige Werte sind: '4', 'F', 'H', 'M', 'N', 'Q', 'W' oder 'Y'",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Ungültiger Wert für den Parameter \"FirstWeekOfMonth\". Gültige Werte sind: 'YES' oder 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"Der Vorname enthält nicht standardisierte Zeichen, was nicht zulässig ist.",
      "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Unzulässiger Wert für den Parameter \"FourthWeekOfMonth\" (Vierte Woche des Monats). Gültige Werte sind: 'YES' oder 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter 'EziDebitCustomerID' und 'YourSystemReference' eingeben",
      "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"Der Nachname enthält Nicht-Standard-Zeichen, was nicht zulässig ist.",
      "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"Die Länge für den Parameter 'YourPayerNumber' ist größer als die maximale Länge.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Für den Parameter \"LimitToNumberOfPayments\" wurde ein ungültiger Wert angegeben. Gültige Werte müssen numerisch und größer oder gleich Null sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Ungültiger Wert für den Parameter \"LimitToTotalAmountInCents\". Gültige Werte müssen numerisch und größer oder gleich Null sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Unzulässiger Wert für den Parameter \"KeepManualPayments\". Gültige Werte sind: 'YES' oder 'NO'",
      "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Ungültiger Name auf der eingegebenen Kreditkarte",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Unzulässiger Wert für den Parameter \"NewPaymentAmountInCents\". Gültige Werte müssen größer oder gleich 200 ($2 Dollar) sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Unzulässiger Wert für den Parameter \"NewStatus\". Gültige Werte sind: 'A', 'H' oder 'C'.",
      "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Ungültiger Wert für den Parameter \"NewYourSystemReference\". Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Parameterkonflikt. Sie können für 'LimitToNumberOfPayments' und 'LimitToTotalAmountInCents' nicht gleichzeitig eine Zahl größer als Null angeben",
      "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"Der Teilerstattungsbetrag kann nicht zwischen dem geplanten Abbuchungsbetrag und dem Gesamtzahlungsbetrag liegen.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Ungültiger Wert für den Parameter \"PaymentAmountInCents\". Gültige Werte müssen größer oder gleich 200 ($2 Dollar) sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Unzulässiger Wert für den Parameter \"PaymentMethod\". Gültige Werte sind: ALL\", \"DR\" oder \"CR\".",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Ungültige Zahlungsreferenz eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Unzulässiger Wert für den Parameter \"PaymentSource\". Gültige Werte sind: 'ALL', 'SCHEDULED', 'WEB', 'PHONE' oder 'BPAY'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Unzulässiger Wert für den Parameter 'PaymentType' angegeben. Gültige Werte sind: 'ALL', 'PENDING', 'SUCCESSFUL' oder 'FAILED'.",
      "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Ungültige Handynummer eingegeben",
      "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Eingabe einer ungültigen Postleitzahl",
      "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"Die Zahlung mit der Referenz 'xxxxxxx' konnte nicht gefunden werden.",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Ungültiger Wert für den Parameter 'RefundAmountInCents' angegeben. Gültige Werte sind ein Erstattungsbetrag zwischen 100 und dem Betrag der ursprünglichen Transaktion",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"Das Zahlungsinstrument für die Erstattung stimmt nicht mit dem ursprünglichen Zahlungsinstrument überein",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Für den Parameter \"RefundAmountInCents\" wurde ein ungültiger Wert angegeben. Gültige Werte sind ein Erstattungsbetrag zwischen 100 und dem Betrag der Originaltransaktion",
      "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"Erstattungsbetrag übersteigt den Gesamtzahlungsbetrag",
      "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Ungültiger Wert für den Parameter \"ScheduleStartDate\". Gültige Werte sind jedes zukünftige Datum im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Für den Parameter \"SecondWeekOfMonth\" wurde ein ungültiger Wert angegeben. Gültige Werte sind: YES\" oder \"NO\".",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Ungültiger Wert für den Parameter \"SmsExpiredCard\". Gültige Werte sind: YES\" oder \"NO\".",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Ungültiger Wert für den Parameter \"SmsFailedNotification\". Gültige Werte sind: YES\" oder \"NO\".",
      "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Ungültiger Wert für den Parameter \"SmsPaymentReminder\". Gültige Werte sind: 'YES' oder 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Ungültiger Wert für den Parameter \"ThirdWeekOfMonth\". Gültige Werte sind: 'YES' oder 'NO'.",
      "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Ungültiges Token. Das Token ist nicht numerisch.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Ungültige CCV-Kreditkartennummer eingegeben - CCV-Nummer muss entweder 3- oder 4-stellig sein",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Ungültiger Wert für den Parameter 'CustomerName'. Der Wert muss weniger als 80 Zeichen lang sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Unzulässiger Wert für den Parameter \"CustomerStatus\". Gültige Werte sind: 'ALL', 'HOLD', 'PENDING', 'CANCELLED', 'ACTIVE'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Ungültiger Wert für den Parameter Order. Gültige Werte sind: 'ASC', 'DESC'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Ungültiger Wert für den OrderBy-Parameter. Gültige Werte sind: 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Ungültiger Wert für den Parameter PageNumber. Gültige Werte müssen numerisch und größer als Null sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Ungültiger Wert für den Parameter 'Reactivate' eingegeben. Gültige Werte sind \"YES\" oder \"NO\".",
      "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Für den Parameter 'YourPayerNumber' wurde ein ungültiger Wert eingegeben. Gültige Werte sind positive Ganzzahlen.",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Für den Parameter \"YourGeneralReference\" wurde ein ungültiger Wert angegeben. Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
      "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Ungültiger Wert für den Parameter \"YourSystemReference\". Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
      "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Unzulässiger Wert für den Parameter \"DebitDate\". Gültiger Wert ist ein beliebiges Datum, das einunddreißig (31) Tage in der Vergangenheit liegt, im Format \"JJJJ-MM-TT\".",
      "EZIDEBIT_ERROR_CODE_LAST_NAME":"Sie müssen einen Wert für den Parameter \"LastName\" angeben.",
      "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Sie müssen einen Wert für den Parameter \"DigitalKey\" angeben",
      "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Sie müssen einen Wert für den Parameter \"CustomerStatus\" angeben.",
      "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Sie müssen entweder für den Parameter \"PaymentID\" oder \"BankReceiptID\" einen Wert angeben. Geben Sie einen Wert für PaymentID oder BankReceiptID an.",
      "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"Sie haben keine Kreditkarten- oder Bankkontodaten angegeben. Es wurden keine obligatorischen Daten für die Aktualisierung der Rechnungsdaten angegeben.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Sie müssen einen Wert für den Parameter \"customerName\" angeben.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Sie müssen einen Wert für den Parameter \"Order\" angeben, wenn der Wert \"OrderBy\" angegeben wird.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Sie müssen einen Wert für den Parameter \"OrderBy\" angeben, wenn der Wert \"Order\" angegeben wird.",
      "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Sie müssen einen Wert für den Parameter \"YourPayerNumber\" angeben.",
      "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Sie müssen einen gültigen Wert für den Parameter \"MobileNumber\" angeben, wenn Sie möchten, dass der Kunde SMS-Benachrichtigungen erhält.",
      "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Sie müssen einen Wochentag angeben, wenn Sie einen wöchentlichen, zweiwöchentlichen, vierwöchentlichen oder einen Wochentag im Monat-Zeitplan erstellen.",
      "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Es wurden keine Daten in den Auswahlparametern gefunden.",
      "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Nullwert erkannt, bitte geben Sie bei Parametern, für die Sie keinen Wert angeben möchten, den leeren String anstelle von Null ein",
      "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Sie müssen entweder für den Parameter \"PaymentID\" oder \"BankReceiptID\" einen Wert angeben",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter 'ChangeFromDate' und 'ChangeFromPaymentNumber' angeben",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Parameterkonflikt. 'ScheduleStartDate' und 'DayOfMonth' stimmen nicht überein.",
      "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Parameterkonflikt. Die Parameter 'ScheduleStartDate' und 'DayOfWeek' stimmen nicht überein.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Zahlung hinzufügen verweigert - Nur aktive Kunden können Zahlungen zu ihrem Zeitplan hinzufügen",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Zahlung hinzufügen verweigert - Sie haben bereits eine Zahlung mit der Referenz xxxxxxx registriert. Eine Zahlungsreferenz muss eine einzelne Zahlung eindeutig identifizieren.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Zahlung hinzufügen verweigert - Dieser Kunde hat bereits xx Zahlungen an diesem Datum.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Zahlung hinzufügen verweigert - Dieser Kunde befindet sich in der Warteschleife, da seine Bankverbindung ungültig ist, und die Kontodaten wurden nicht geändert.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Zahlung hinzufügen verweigert - Dieser Kunde ist in der Warteschleife wegen ungültiger Kreditkartendaten, und diese Kartendaten wurden nicht geändert.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Zahlung hinzufügen verweigert - Der Status xx dieses Kunden erlaubt es nicht, neue Zahlungen hinzuzufügen.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Zahlung hinzufügen verweigert - Der Status \"xxxxx\" dieses Kunden lässt keine neuen Zahlungen zu.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"Nicht für Kreditkartenzahlungen konfiguriert. Das Kundenkonto ist nicht für die Verarbeitung von Echtzeit-Kreditkartenzahlungen für den Kartentyp des Kunden konfiguriert.",
      "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Sie müssen einen Wert für den Parameter 'PaymentReference' angeben",
      "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"Die Zahlung wurde verarbeitet und kann nicht gelöscht werden",
      "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"Die ursprüngliche Zahlung ist noch in Bearbeitung. Sie kann in diesem Stadium nicht zurückerstattet werden.",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"Kein Zahlungsdatensatz für PaymentId gefunden",
      "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"Kein übereinstimmender Datensatz für die 'PaymentId' oder 'BankReceiptId' gefunden",
      "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"Erstattungsbetrag übersteigt den Gesamtzahlungsbetrag",
      "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"Rückerstattungen sind für den Kunden nicht zulässig",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Sie haben sowohl Kreditkarten- als auch Bankkontodaten eingegeben. Es dürfen nur Kreditkarten- oder Bankkontodaten eingegeben werden, nicht beide.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter \"PaymentID\" und \"BankReceiptID\" eingeben.",
      "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"Es wurden nicht alle erforderlichen Parameter angegeben.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'FirstWeekOfMonth' stimmen nicht überein.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'FourthWeekOfMonth' stimmen nicht überein.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'SecondWeekOfMonth' sind nicht aufeinander abgestimmt.",
      "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'ThirdWeekOfMonth' stimmen nicht überein.",
      "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"Die zur Löschung ausgewählte Zahlung konnte nicht gefunden werden.",
      "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"Sie verwenden keinen gemeinsamen Ezidebit BPay-Rechnungssteller-Code.",
      "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Statusaktualisierung verweigert - Nur Kunden mit einem Status von 'A', 'N' oder 'H' können über diesen Dienst geändert werden.",
      "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"Aktualisierung kann nicht verarbeitet werden - Ungültige CCV-Kreditkartennummer eingegeben - CCV-Nummer muss für AMEX (WSvc) 4-stellig sein",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Die Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird. Bitte versuchen Sie es später erneut",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird (PT). Bitte versuchen Sie es später noch einmal...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Die Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird (SPS). Bitte versuchen Sie es später noch einmal...",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"Das System ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal. Eine Verbindung zur Datenbank kann aus irgendeinem Grund nicht geöffnet werden. Bitte kontaktieren Sie partner@ezidebit.com.au für Hilfe.",
      "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"Das System ist zur Zeit nicht verfügbar. Bitte versuchen Sie es später noch einmal. Beim Extrahieren der Details aus der Datenbank ist ein unbehandelter Fehler aufgetreten. Bitte kontaktieren Sie partner@ezidebit.com.au für Hilfe.",
      "EZIDEBIT_ERROR_CODE_YES":"Sie müssen für mindestens einen der Parameter \"Woche des Monats\" einen \"JA\"-Wert angeben, wenn Sie einen Zeitplan \"Wochentag im Monat\" erstellen.",
      "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Sie müssen einen Wert für den Parameter \"YourGeneralReference\" angeben.",
      "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Sie müssen einen Wert für den Parameter \"YourSystemReference\" angeben.",
      "EZIDEBIT_NO_ERROR_DETECTED":"Keine Fehler gefunden",
      "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Fehler beim Zuweisen des Kunden zum Account Manager",
      "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"Reseller konnte dem Account Manager nicht zugewiesen werden",
      "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Abrechnungszyklus der Bestellung konnte nicht geändert werden",
      "FAILED_TO_CREATE_ACCOUNT_MANAGER":"Account Manager konnte nicht erstellt werden",
      "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Fehler beim Erstellen eines neuen Plans mit allen Angeboten",
      "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Erstellen eines neuen Wiederverkäuferplans mit allen Angeboten fehlgeschlagen",
      "FAILED_TO_DELETE_ACCOUNT_MANAGER":"Löschen des Account Managers fehlgeschlagen",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Anforderung für Klonplan konnte nicht in die Warteschlange gestellt werden",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"Anforderung zum Erstellen eines neuen Plans mit allen Angeboten konnte nicht in die Warteschlange gestellt werden",
      "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Die Anfrage zum Erstellen eines neuen Reseller-Tarifs mit allen Angeboten konnte nicht ausgeführt werden",
      "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Fehler beim Abrufen der Zugriffstoken durch den aus Quickbooks empfangenen Authentifizierungscode",
      "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Fehler beim Abrufen der Zugriffstoken durch den von Xero empfangenen Authentifizierungscode",
      "FAILED_TO_RESEND_INVOICE":"Rechnung konnte nicht erneut gesendet werden",
      "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Fehler beim Speichern des von Quickbooks empfangenen Authentifizierungscodes",
      "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Fehler beim Speichern des von Xero empfangenen Authentifizierungscodes",
      "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Fehler beim Aufheben der Zuordnung des Kunden eines Account Managers",
      "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"Fehler beim Aufheben der Zuordnung des Resellers eines Account Managers",
      "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Fehler beim Aktualisieren der Account Manager-Details",
      "FAQ_CUSTOMER_CATEGORY_GENERAL":"Allgemeines",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Sie können die Seeds mithilfe der Bearbeitungsfunktion unter Abonnements einfach aktualisieren.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Sie können den Zugriff gewähren, indem Sie in der Benutzeransicht Zugriff auf die Portalfunktionen gewähren.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Ja, Sie können über unser Portal aktualisieren.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Ja, Sie können das Abonnement mit c3 aussetzen.",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Wie kann ich das Abonnement erhöhen?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Wie kann ein normaler Benutzer auf das Portal zugreifen?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Gibt es eine Möglichkeit, die Zahlungsmethode mit C3 zu aktualisieren?",
      "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. Kann C3 Abonnements aussetzen?",
      "FAQ_HEADING":"Häufig gestellte Fragen",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Anwendung Allgemein",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"Ab sofort wird C3 von Englisch (en-us), Spanisch (es) und Taiwanesisch (tw) unterstützt. Darüber hinaus unterstützt C3 Währungen in Abhängigkeit von den Partner Center-Regionen und der Abrechnung",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Ja, dies ist in C3 durch Definieren von Tags möglich. Sie müssen Tags (Wertepaar) definieren und Kunden zuweisen. Z.Bsweise können Sie ein Tag mit dem Namen SupportAgent und einen Spitznamen eines Agenten (z. B. Agent001) als Wert definieren. Wenn für einen Helpdesk-Agenten eines der Tags als SupportAgent und einer der Werte für dieses bestimmte Tag als Agent001 festgelegt ist, kann der Agent die Kunden sehen, für die das Tag-Wert-Paar {SupportAgent, Agent001} festgelegt ist.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Gehen Sie zu Einstellungen>>E-Mail-Benachrichtigung, wo Sie C3 so konfigurieren können, dass es ereignisbasierte E-Mails versendet. C3 bietet Ihnen verschiedene Ereignisse, für die Sie E-Mails konfigurieren können. Sie können auch die E-Mail-Vorschau für das jeweilige Ereignis sehen.  Sie können eine E-Mail-Benachrichtigung für ein Ereignis konfigurieren, bei dem ein Angebot von einem Kunden gekauft wird. Sie können den Empfänger auf Basis der Rolle oder der Rechnungskontakte auswählen. Zusätzlich können Sie auch die CC- und BCC-Liste auswählen. Sobald dies geschehen ist, sendet C3 jedes Mal, wenn ein solches Ereignis eintritt, E-Mail-Benachrichtigungen an alle in den E-Mail-Benachrichtigungseinstellungen konfigurierten Empfänger.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Sie können Berichte zu Steuerzusammenfassungen und Steuerpositionen von der Kachel Umsatz- und Kostenübersicht herunterladen, die im Partnerportal verfügbar ist.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Ja, C3-Support-Rabatte. Sie können Rabattcoupons erstellen und diese auch auf bestimmte Pläne oder Kunden anwenden. Sie können verschiedene Parameter für einen Gutschein definieren, z. B. maximale Einlösung, gültig ab Datum, gültig bis Datum und Rabattprozentsatz.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Sie können C3 mithilfe von Logos für das Branding anpassen. C3 bietet Ihnen an, das Begrüßungslogo auf der Begrüßungsseite und das angemeldete Logo durch Ihr Logo zu ersetzen. Sie können Benachrichtigungs-E-Mails sogar mithilfe Ihres Logos anpassen, um die Wirkung des Brandings zu verbessern.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Ja, wir können die erforderlichen Änderungen an unserem Ende vornehmen, damit der Kunde auf der Anmeldeseite anstelle der C3-Begrüßungsseite landet.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Nein, wir sind noch nicht zertifiziert. Wir verwenden jedoch die Konfiguration derselben Ebene, um Ihre Daten sicher zu verwahren.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Ja das können wir tun. Wir geben Ihnen eine IP-Adresse. Sie müssen einen Eintrag mit derselben erstellen, in der Ihr DNS verwaltet wird. Ihr DNS-Anbieter hilft Ihnen dabei.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Nein, Sie können im Falle einer Änderung direkt zu Festlegen und Aktualisieren der Anmeldeinformationen für Partner Center oder SMTP gehen.",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. Welche Sprachen und Währungen werden von C3 unterstützt?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Kann ich Benutzer auf meiner Seite haben, die sich nur mit einer Gruppe von Kunden befassen?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Wie konfiguriere ich C3 so, dass E-Mails versendet werden, wenn ein Kunde einen Kauf tätigt? Wie konfiguriere ich auch die Empfänger solcher E-Mails?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Welche Art von Steuerberichten a3. Welche Steuerberichte stehen zum Download zur Verfügung?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. Unterstützt C3 Rabatte?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. Welche Art von Anpassung bietet C3 ohne Look-and-Feel (weiße Beschriftung/Branding)?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Gibt es eine Möglichkeit, meine Kunden auf einen Link in meinem Portal klicken zu lassen, der sie zur Anmeldeseite anstelle der C3-Begrüßungsseite führt?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. Ist C3 PCI kompatibel?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Kann ich meinen bevorzugten Domainnamen mit C3 verknüpfen? Was braucht es, um das zu tun?",
      "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. Soll ich mich an den C3-Support wenden, wenn sich die Anmeldeinformationen für mein Partner Center oder SMTP geändert haben oder abgelaufen sind?",
      "FAQ_PARTNER_CATEGORY_BILLING":"Abrechnung",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Derzeit unterstützt C3 Manual, Credit Card, ACH (nur für US-Kunden) und Bankkonten (nur für australische und neuseeländische Kunden).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Ja, Kunden erhalten eine Kopie der Rechnung als PDF in der Benachrichtigungs-E-Mail, wenn die Rechnung vom Partner genehmigt wird.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"In solchen Fällen können Sie verhindern, dass C3 die Kunden belastet, indem Sie die Zahlung auf der jeweiligen Rechnung erfassen. Sie müssen zu Umsatz- und Kostenübersicht >> Kunde auswählen >> Rechnungen gehen. Von hier aus müssen Sie die Rechnung auswählen, für die Sie die Zahlung eingezogen haben. Hier finden Sie eine Option als Rekordzahlung.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Wir unterstützen nicht mehrere Abrechnungszyklen. Aber in diesem Fall können wir alle Kunden auf einen bestimmten Abrechnungszyklus ausrichten. Dies ist ein manueller Prozess.  Ein Beispiel: Ihr aktueller Abrechnungszyklus für einen Kunden ist der 10. dieses Monats bis zum 9. des nächsten Monats und in C3 ist der Abrechnungszyklus der 1. dieses Monats bis zum 1. des nächsten Monats. Da alle Kunden alle Zahlungen bis zum 9. dieses Monats geleistet haben. Wir berechnen den entsprechenden Betrag für den 1. bis 9. und passen ihn in der nächsten Rechnung an, wodurch das Problem gelöst wird.  In ähnlicher Weise können wir das auch für andere Abrechnungszyklen tun. Ab dem nächsten Monat werden alle Kunden auf denselben Abrechnungszyklus ausgerichtet sein.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Ja, wir können Anpassungen bezüglich der Zahlungen in der entsprechenden Rechnung vornehmen. Derzeit gibt es keine automatisierte Version. Wir können uns darum kümmern.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Jetzt unterstützen wir Bill & Pay, Stripe, EziDebit, Authorize.net, Cybersource. Wenn Sie eine andere als diese verwenden, können wir auf jeden Fall auf die Integration Ihres Zahlungsgateways in C3 achten.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 unterstützt bisher mehrere Zahlungsgateways wie Bill & Pay, Stripe, EziDebit, Authorize.net und CyberSource. Es wäre großartig, wenn Sie eines davon als vorhandenes Zahlungsgateway verwenden würden, oder wir könnten versuchen, C3 so zu gestalten, dass es Ihre Zahlungsgateways unterstützt.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Wir aktualisieren die C3-Angebote jeden Monat mit dem neuesten Angebot von Microsoft. Im Falle einer Preisänderung müssen Sie einen neuen Plan erstellen oder den bestehenden Plan mit den neuen Angebotspreisen aktualisieren.  Standardmäßig wird C3 den neuen Preis für neue Kunden übernehmen. Für bestehende Kunden können Sie jedoch einen neuen Preis für das aktuell abonnierte Angebot festlegen. Zusätzlich können Sie das Datum des Inkrafttretens der neuen Preise wählen, z.B. mit sofortiger Wirkung bei Preisänderung, nächstem Abrechnungszyklus oder nächstem Verlängerungsdatum.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"In C3 werden Steuerregeln durch Kombinationen aus  { Country,State Province and Tax Code }, { Country, Zip } oder { Country, State Province and Zip } definiert. Z.Bsweise bedeutet die für Land - USA definierte Steuerregel, dass die Provinz und die Postleitzahl leer bleiben, dass der Steuersatz auf alle Kunden aus den USA angewendet wird. Wenn Sie eine Regel für einen bestimmten Staat definieren möchten, geben Sie beim Definieren der Regel die Werte für Land und Staat an.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Ja, das kannst du machen. Sie müssen zu Einstellung >> Steuern gehen und eine Steuerregel mit einem Steuerprozentsatz von 0% erstellen. Wenden Sie es auf Kunden an, die Sie von der Steuerregel befreien möchten (gilt für andere Kundengruppen).",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Sie können das Datum für die Rechnungserstellung festlegen und uns mitteilen. Wir konfigurieren es für Sie und Sie können es überprüfen, indem Sie auf Partnereinstellung >> Abrechnung gehen. Hier sehen Sie alle Daten zu Abrechnungen. Sie können dieses Datum auch für einen bestimmten Kunden ändern, indem Sie zur Kundenkonfiguration wechseln.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"Mit C3 können Sie festlegen, wann Ihr Kunde die Rechnung sehen soll. Nach der Rechnungserstellung muss der Partner die Rechnung genehmigen (gegebenenfalls Anpassungen vornehmen), um sie den Kunden zur Verfügung zu stellen.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Ja, C3 bietet Ihnen die Möglichkeit, Änderungen an Werbebuchungen in der Rechnung nach der Rechnungserstellung vorzunehmen. Sie müssen die erforderlichen Änderungen vornehmen (z. B. - Sie möchten eine einmalige Gebühr hinzufügen/verzichten), basierend auf Ihren Anforderungen vor dem Rechnungsabschlussdatum. Sie können keine Änderungen an der Rechnung vornehmen, sobald diese abgeschlossen ist.",
      "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"In C3 können wir E-Mail-Benachrichtigungen für solche Ereignisse konfigurieren. Im Falle einer erfolgreichen oder fehlgeschlagenen Zahlung schickt C3 eine entsprechende E-Mail. Alle Empfänger, die für solche Ereignisse konfiguriert sind, erhalten Benachrichtigungs-E-Mails.   Außerdem verfügt C3 über eine Funktion für Zahlungsausfälle in der Zusammenfassung der Einnahmen und Kosten, in der Partner Aufzeichnungen für jeden Zahlungsausfall verfolgen können.",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. Welche Zahlungsmethoden werden von C3 unterstützt?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Erhält der Kunde eine Kopie der Rechnung als PDF, wenn die Rechnung fertig ist?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Was passiert, wenn ich eine Zahlung per Scheck oder Bargeld eingezogen habe und die Zahlungsmethode des Kunden Kreditkarte ist? Wie verhindere ich, dass C3 dem Kunden die aktuelle Rechnung in Rechnung stellt?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Wir haben unsere eigenen Abrechnungssysteme verwendet und unsere Kunden bis heute belastet. Unsere Kunden hatten unterschiedliche Abrechnungszyklen. Welche Unterstützung erhalten wir bei der Migration auf C3?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Gibt es eine Möglichkeit, Vorauszahlungen gegen eine Rechnung zu bearbeiten?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. Welche Zahlungsgateways unterstützt C3 sofort?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Kann ich meinen vorhandenen Zahlungsgateway-Anbieter verwenden oder sollte ich mich für C3 entscheiden?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Was passiert, wenn sich die Preise von Microsoft ändern und wir unsere Preise für unsere Kunden aktualisieren möchten?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. Wie geht C3 mit Steuern um?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Kann ich einen bestimmten Kunden von einer Steuerregel befreien, die für andere Kundengruppen gilt?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. Wie definiere ich, wann C3 Rechnungen generieren soll?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Kann mein Kunde die Rechnungen direkt nach ihrer Erstellung einsehen?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Habe ich die Möglichkeit, Änderungen an den Positionen in der Rechnung vorzunehmen, nachdem diese generiert wurde? Kann ich einer Rechnung eine einmalige Gebühr hinzufügen/auf entsprechende Änderungen verzichten?",
      "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Woher weiß ich (Partner), wann eine Zahlung erfolgreich war oder fehlgeschlagen ist?",
      "FAQ_PARTNER_CATEGORY_SELL":"Verkaufen",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Ein Plan ist eine Gruppe von Angeboten, die Sie zu einem Preis Ihrer Wahl an Ihren Kunden verkaufen können. Sie müssen einen Plan haben, um einen Kunden zu integrieren oder zu erstellen.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Ja, C3 bietet Ihnen die Möglichkeit, Partnerangebote zu erstellen. Sie möchten Ihren Kunden z.Bsweise rund um die Uhr Support bieten. Anschließend können Sie ein Partnerangebot rund um die Uhr für Office365-Support erstellen und zu einem Preis Ihrer Wahl verkaufen.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Ja, Sie können jeden bestehenden Kunden im Partner Center in C3 integrieren. Sie benötigen eine Microsoft-ID (vom Partner Center erhalten) und ein Angebot, das dem abonnierten Angebot auf dem PC entspricht. Abgesehen davon bietet Ihnen C3 auch die Möglichkeit, einen neuen Kunden direkt aus C3 heraus zu erstellen (Sie müssen zur Kundenerstellung nicht zum PC gehen).",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Um dies zu erreichen, müssen wir diesen Kunden mit jedem Plan einbeziehen. Sie können die Angebotsmenge auf Null setzen. Sobald der Kunde erfolgreich eingebunden ist, werden nutzungsbasierte Abonnements für diesen bestimmten Kunden automatisch synchronisiert.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"In solchen Fällen besteht eine Mengenfehlanpassung zwischen PC und C3. Unser Team kann solche Fehlanpassungen beheben.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Wir können Anpassungen in Rechnungen vornehmen, aber C3 unterstützt derzeit keine Rückerstattungen.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Ja, C3 berechnet die Nutzung von Kunden und generiert basierend auf dem Datenverbrauch die Rechnung.",
      "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Ja, Sie können einen Rabattcoupon mit dem erforderlichen Rabattprozentsatz und den maximalen Wiederholungen auf 3 erstellen. Weisen Sie nun den neu erstellten Coupon einem bestimmten Kunden zu. Sobald dies erledigt ist, erhält der Kunde den entsprechenden Rabatt für die nächsten drei Rechnungen.",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Was ist ein Plan?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Kann ich nicht auf Microsoft basierende Angebote wie benutzerdefinierte Dienste verkaufen?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Kann ich einen bestehenden Kunden an Bord nehmen?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Wie kann ich einen Kunden an Bord nehmen, der nur nutzungsbasierte Abonnements hat?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mein Team hat versehentlich Lizenzen über den PC gekauft. Welche Auswirkungen haben solche Aktionen? Gibt es ein Verfahren, um solche Unstimmigkeiten zu beheben?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. Unterstützt C3 Rückerstattungen?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. Übernimmt C3 die Azure-Abrechnung für die Nutzung durch meine Kunden?",
      "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. Ich habe einen Kunden an Bord von C3 und möchte einen Rabatt für die nächsten drei Rechnungen gewähren. Kann ich das tun?",
      "FEATURE_COMMENTS_ORDER_NO":" Bestell-Nr.:",
      "FEBRUARY":"Februar",
      "FIELD_REQUIRED":"{{atribute}} ist erforderlich",
      "FILTER_SELECT_A_SOURCE_CURRENCY":"Wählen",
      "FILTER_SELECT_A_TARGET_CURRENCY":"Wählen",
      "FIX_AUTO_RENEW_STATUS_MISMATCH":"Behebung von Fehlern beim Status der automatischen Verlängerung",
      "FIX_AZURE_MISSING_OFFER":"Behebung eines fehlenden Azure-Angebots",
      "FIX_BILLING_CYCLE_MISMATCH":"Fehler im Abrechnungszyklus beheben",
      "FIX_MISSING_OFFER":"Fehlendes Angebot beheben",
      "FIX_OFFER_MISMATCH":"Fehlendes Angebot beheben",
      "FIX_QUANTITY_MISMATCH":"Mengeninkongruenz beheben",
      "FIX_SCOPE_MISMATCH":"Bereichskonflikt beheben",
      "FIX_STATUS_MISMATCH":"Statusabweichung beheben",
      "FIX_TERM_DURATION_MISMATCH":"Unstimmigkeit der Laufzeit beheben",
      "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Alle Rechte vorbehalten.",
      "FOOTER_TEXT_1_POWERED_BY":"Unterstützt von",
      "FROM":"Von",
      "General":"Allgemeines",
      "GENERAL_SETTINGS_MAIN_ERROR":"Bitte korrigieren Sie die Fehler im Formular, bevor Sie es absenden",
      "GENERATE_INVOICE_REASON":"Grund",
      "GENERATE_INVOICE_REASON_PLACEHOLDER":"Bitte geben Sie einen Grund an",
      "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Generierte Quickbooks-Zugriffstoken durch Authentifizierungscode, der von Quickbooks erhalten wurde",
      "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Generierte Xero-Zugriffstoken durch den von Xero erhaltenen Authentifizierungscode",
      "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Beim Abrufen der Details des Abrechnungsanbieters ist ein Fehler aufgetreten.",
      "GET_CURRENCY_CODES_EXCEPTION":"Beim Abrufen der Cuurency-Codes ist ein Fehler aufgetreten",
      "GET_CURRENCY_CONVERSION_EXCEPTION":"Beim Abrufen der Währungsumrechnung ist ein Fehler aufgetreten",
      "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Beim Abrufen der Währungsumrechnungsliste ist ein Fehler aufgetreten",
      "GET_CURRENCY_OPTIONS_EXCEPTION":"Beim Abrufen der Währungsoptionen ist ein Fehler aufgetreten",
      "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Beim Abrufen der Kundenereignisse für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
      "GET_CUSTOMER_LIST_EXCEPTION":"Beim Abrufen der Kunden ist ein Fehler aufgetreten",
      "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Beim Abrufen der Kundenpläne mit aktiver Aktion ist ein Fehler aufgetreten",
      "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Beim Abrufen der Empfängerliste für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
      "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Beim Abrufen der Ereignisse für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
      "GET_PARTNER_LOGO_EXCEPTION":"Beim Abrufen des Partnerlogos ist ein Fehler aufgetreten",
      "GET_PARTNER_SETTINGS_EXCEPTION":"Beim Abrufen der Partnereinstellungen ist ein Fehler aufgetreten",
      "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Abrufen der Steuerdetails ist ein Fehler aufgetreten",
      "GET_PARTNER_TAX_TYPES_EXCEPTION":"Beim Abrufen der Steuertypen ist ein Fehler aufgetreten",
      "GET_TARGET_CURRENCIES_EXCEPTION":"Beim Abrufen der Zielwährungen ist ein Fehler aufgetreten",
      "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Beim Abrufen der Anfrage für eine Wiederverkäuferbeziehung ist ein Fehler aufgetreten.",
      "GOT_RESELLER_DETAILS":"Erhielt den Reseller von CSP mit dem Namen: {{resellerName}}",
      "government":"Regierung",
      "Government":"Regierung",
      "GRAND_ACCESS_USER_EXCEPTION":"Diese verwendet haben bereits Zugriff bitte mit einem anderen überprüfen",
      "GRAND_TOTAL":"Gesamtsumme",
      "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Verbrauch nach Produkt",
      "GRAPH_CUSTOMERS_ONBOARDED":"Kunden an Bord",
      "GRAPH_DATA_BILLED_AMOUNT":"Abgerechneter Betrag",
      "GRAPH_DATA_QUANTITY":"Menge",
      "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Umsatz nach Kunden",
      "GRAPH_TOP_SELLING_PRODUCT":"Die 10 meistverkauften Produkte",
      "GREATER_THAN_ERP":"größer als ERP",
      "GRID_DOWNLOAD_COUPON_GRID":"Gutscheine herunterladen",
      "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"In dieser Gruppe ist bereits ein Gruppenadministrator mit dieser E-Mail vorhanden",
      "GROUP_USERS_OF":"Gruppenbenutzer von",
      "GROUP_USERS_TOOLTIP_AZUREGROUP":"Benutzer gruppieren",
      "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Zeigen Sie die Anwendung in einer von der Anwendung unterstützten Sprache an",
      "HEADER_CONTENT_HELP_MENU":"Kontextsensitive Hilfeinhalte in der rechten Seitenleiste",
      "HEADER_CONTENT_MENU_CONTACT_LOG":"Ermöglicht dem Partner, den Status aller Benachrichtigungen und den Inhalt der E-Mail anzuzeigen.",
      "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Verfolgen Sie die Benutzeraktivität.",
      "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Grundlegendes Profildetail des angemeldeten Benutzers",
      "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Verwalten Sie Ihre C3-Kontoeinstellungen und greifen Sie auf Aktivitätsprotokolle zu.",
      "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
      "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
      "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
      "HEADER_CONTENT_SIGNIN_USER":"Grundlegendes Profildetail des angemeldeten Benutzers",
      "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Kontaktiere uns",
      "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Ausloggen",
      "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Benutzer nicht autorisiert",
      "HEADER_GO_TO_PROFILE":"Zum Profil gehen",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Kontaktiere uns",
      "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Stoppen Sie den Identitätswechsel",
      "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Benutzer nicht autorisiert",
      "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Sprache",
      "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Sprache",
      "HEADER_TITLE_HELP_MENU":"Hilfe",
      "HEADER_TITLE_MENU_CONTACT_LOG":"Benachrichtigungen",
      "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Audit-Log",
      "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
      "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"die Einstellungen",
      "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"die Einstellungen",
      "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"die Einstellungen",
      "HEADER_TITLE_SIGNIN_USER":"Benutzer angemeldet",
      "HEADING_ENTITY_LIST":"{{EntityName}}-Liste",
      "HELP_TEXT_SMTP_TEST_USERNAME":"Geben Sie die E-Mail-Adresse an, um die Test-E-Mail zu erhalten.",
      "HIGH":"Hign",
      "HOURS":"Stunde(n)",
      "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"Hier",
      "I have read and agreed to":"Ich habe gelesen und stimme zu",
      "IF_ALREADY_EXISTS":"ist bereits vorhanden",
      "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Dieser Wert entscheidet, ob die Suchfilter im öffentlichen Registrierungs-Shop-Bildschirm aktiviert/deaktiviert werden sollen.",
      "IGNORE_FROM_PSA_TEXT_DISP":"Kunden aus dem externen Service ignorieren",
      "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Zusammen mit Ihrem aktuellen Privileg",
      "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Ohne dein aktuelles Privileg",
      "IMPORT_LIST":"Liste importieren",
      "In Progress":"In Bearbeitung",
      "IN_ACTIVE":"Inaktiv",
      "INDEX_LOGOUT_APOLOGY_MESSAGE":"Dieses Programm war viel zu lange untätig. Es tut uns leid, dass wir Sie abgemeldet haben, aber wir hatten keine andere Wahl.",
      "INDEX_LOGOUT_MESSAGE":"Entschuldigung, Sie wurden abgemeldet",
      "INDEX_MSG_LOGOUT_SOON_MESSAGE":"Der Idle-Modus ist aktiviert, da man viel zu lange untätig ist. Das hat zur Folge, dass Sie sich nach wenigen Augenblicken abmelden.",
      "INDXE_LOGOUT_TEXT":"SCHNELL ETWAS TUN! Sie werden in {{countdown}} Sekunde(n) abgemeldet.",
      "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Es sind keine Währungen registriert",
      "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Es sind keine Zustände registriert",
      "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Bitte wähle ein Land",
      "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Bitte wählen Sie zuerst eine Quellwährung aus",
      "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"Sind Sie sicher, dass Sie die Rechnungsbelastung für <%= currencySymbol%><%= invoiceAmount%> mit der Zahlungsmethode einleiten möchten: <br> <b><%= PaymentType%> | <%= AccountNumber%></b> ? <br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder irrtümlich erfasst wurden.</small>",
      "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Es ist kein ausstehender Betrag zu zahlen.",
      "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Alle ausgewählten Rechnungen werden für Paymnets in die Warteschlange gestellt. Wollen Sie fortfahren?",
      "INITIATE_STATUS_UPDATE_CONFIRMATION":"Der Rechnungsstatus ändert sich in <%=invoiceStatus%>. Wollen Sie fortfahren?",
      "INITIATE_TEST_PAYMENT_CONFIRMATION":"Dem Kunden werden $<%= amount%> berechnet. Wollen Sie fortfahren?",
      "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Dem Reseller werden $<%= amount%> berechnet. Wollen Sie fortfahren?",
      "INPUT_BILLINGPERIODID":"BillingPeriodId",
      "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
      "INPUT_CATEGORYID":"Kategorie ID",
      "INPUT_CHANGE_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung",
      "INPUT_CHANGE_TYPE":"Typ ändern",
      "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "INPUT_CUSTOMERC3ID":"CustomerC3Id",
      "INPUT_CUSTOMERID":"Kundennummer",
      "INPUT_CUSTOMERNAME":"Kundenname",
      "INPUT_DATA":"Benutzereingabe",
      "INPUT_DESCRIPTION":"Beschreibung",
      "INPUT_ENDIND":"EndInd",
      "INPUT_INTERNALPRODUCTID":"InternalProductId",
      "INPUT_INVOICEID":"InvoiceId",
      "INPUT_LABEL_PURCHASE_QUANTITY":"Kaufmenge",
      "INPUT_NAME":"Name",
      "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "INPUT_PAGECOUNT":"Seitenzahl",
      "INPUT_PAGEINDEX":"PageIndex",
      "INPUT_PAGESIZE":"Seitengröße",
      "INPUT_PLANC3ID":"Plaid",
      "INPUT_PLANPRODUCTID":"PlanProductId",
      "INPUT_PRICEFORPARTNER":"Preis für Partner",
      "INPUT_PRODUCTID":"Produkt ID",
      "INPUT_PROVIDERID":"ProviderId",
      "INPUT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "INPUT_QUANTITY":"Menge",
      "INPUT_REACTIVATE":"Reaktivieren",
      "INPUT_SALETYPE":"SaleType",
      "INPUT_STARTIND":"StartInd",
      "INPUT_SUSPEND":"Aussetzen",
      "INPUT_TENANTID":"TenantId",
      "INPUT_WITHADDONS":"WithAddons",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"Zurück zum Warenkorb",
      "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Zurück zu Bestellungen",
      "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Zahlungsprofil",
      "INSTANT_PAY_BUTTON_TEXT_PAY":"Zahlen",
      "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"Das aktuelle Zahlungsgateway wird noch nicht mit Instant Pay unterstützt. Bitte wenden Sie sich an den Support, um Unterstützung zu erhalten.",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Bezahlverfahren",
      "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Sichere Bezahlung",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Laden Sie das kartierte Blatt für die Kartierung im CSV-Format hoch. Laden Sie die Datei <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Hochladen herunter.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Diese Datei wird verwendet, um die Entitäten zwischen C3 und AutoTask Manage abzubilden. Löschen Sie keine vorhandenen Texte oder Daten in dieser Datei.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Die einzigen Felder, die aktualisiert werden müssen, sind „AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId und ContractServiceName“. Die restlichen Felder dienen dazu, die C3-Gegenstücke für die Zuordnung zu identifizieren.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Wenn die oben genannten Felder im Blatt ausgefüllt sind, ist die Zuordnung verfügbar. Wenn die oben genannten Felder leer sind, ist eine Zuordnung erforderlich.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Zum Ausfüllen des Zuordnungsblatts können Sie sich auf <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a> beziehen",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"Name und ganzzahlige (numerische) ID, die zum Ausfüllen des <b>Zuordnungsblatts</b> erforderlich sind, sind im <b>AutoTask-Entitätsblatt</b> verfügbar",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"Das Entfernen der Zuordnung ist über das Zuordnungsblatt nicht möglich. Um die Zuordnung von Vereinbarung und Produkt zu ändern, verwenden Sie bitte das Zuordnungsblatt. Eine Neuzuordnung von Kunden ist nur über den Bildschirm zur Zuordnung gekaufter Produkte möglich.",
      "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
      "INSTRUNCTION_PSA_BULK_BUTTON":"Anweisungen zum Massen-Upload der Zuordnung gekaufter Produkte:",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Laden Sie das kartierte Blatt für die Kartierung im CSV-Format hoch. Laden Sie die Datei <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Hochladen herunter.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Diese Datei wird verwendet, um die Entitäten zwischen C3 und ConnectWise Manage abzubilden. Löschen Sie keine vorhandenen Texte oder Daten in dieser Datei.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Die einzigen Felder, die aktualisiert werden müssen, sind „ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId und ConnectwiseProductname“. Die restlichen Felder dienen dazu, die C3-Gegenstücke für die Zuordnung zu identifizieren.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Wenn die oben genannten Felder im Blatt ausgefüllt sind, ist die Zuordnung verfügbar. Wenn die oben genannten Felder leer sind, ist eine Zuordnung erforderlich.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Zum Ausfüllen des Zuordnungsblatts können Sie sich auf <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a> beziehen",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"Name und ganzzahlige (numerische) ID, die zum Ausfüllen des <b>Zuordnungsblatts</b> erforderlich sind, sind im <b>Connectwise-Entitätsblatt</b> verfügbar",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"Das Entfernen der Zuordnung ist über das Zuordnungsblatt nicht möglich. Um die Zuordnung von Vereinbarung und Produkt zu ändern, verwenden Sie bitte das Zuordnungsblatt. Eine Neuzuordnung von Kunden ist nur über den Bildschirm zur Zuordnung gekaufter Produkte möglich.",
      "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Um die Zuordnung zwischen C3- und ConnectWise Manage-Produkten zu erleichtern, laden Sie das Zuordnungsblatt im CSV-Format hoch. Sie können die Datei <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Aktualisieren herunterladen.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"Die Datei dient zum Erstellen von Produktzuordnungen zwischen C3 und ConnectWise Manage. Achten Sie darauf, keinen vorhandenen Text oder keine vorhandenen Daten in der Datei zu löschen.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Nur die Felder ConnectwiseProductId und ConnectwiseProductname müssen aktualisiert werden. Andere Felder helfen bei der Identifizierung von C3-Gegenstücken für Zuordnungszwecke.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Wenn die genannten Felder ausgefüllt sind, ist das Mapping abgeschlossen. Wenn sie leer sind, ist ein Mapping notwendig.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Anleitungen zum Ausfüllen des Zuordnungsblatts finden Sie im <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Connectwise Entity Sheet.xlsx.</a>. Der erforderliche Name und die ganzzahligen (numerischen) IDs sind im ConnectWise Entity Sheet verfügbar.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Bitte beachten Sie, dass das Entfernen von Zuordnungen nicht über das Zuordnungsblatt möglich ist. Um Produktzuordnungen zu ändern, verwenden Sie das bereitgestellte Zuordnungsblatt.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Laden Sie die Datei hoch und klicken Sie auf SENDEN, um den Vorgang abzuschließen.",
      "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON":"Anweisungen zur Verwendung der Schaltfläche „Aktualisieren“:",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Klicken Sie auf die Schaltfläche „Aktualisieren“, um die neuesten Daten von PSA bis C3 zu erhalten.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"Standardmäßig sind in der Produktzuordnung zunächst keine Daten verfügbar. Klicken Sie auf die Schaltfläche „Aktualisieren“, um alle verfügbaren Daten von PSA bis C3 zu erhalten.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Wenn „Aktualisieren“ ausgeführt wird, deaktiviert das System andere Registerkarten. Bei Erfolg werden deaktivierte Tabs wieder sichtbar sein. Im Fehlerfall bietet dieser Bildschirm die Möglichkeit, den Fehler zu ignorieren. Sie können auf die Schaltfläche „Fehler ignorieren“ klicken, um die deaktivierten Registerkarten zu aktivieren.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Wenn „Aktualisieren“ ausgeführt wird, löscht das System vorhandene PSA-Daten aus C3 und fügt neue Daten aus PSA ein.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Wenn in PSA Änderungen vorgenommen werden, beispielsweise das Hinzufügen oder Entfernen von Unternehmen, Vereinbarungen, Produkten oder Statusänderungen für diese Entitäten, muss der Benutzer die Produktzuordnung aktualisieren, um diese Änderungen in C3 widerzuspiegeln.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Bitte klicken Sie auf die Schaltfläche </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\">Aktualisieren</a>, um die neuesten Daten zu erhalten von PSA bis C3.",
      "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" Schaltfläche, um die neuesten Daten von PSA an C3 zu erhalten.",
      "INTERNAL_BILLING_ID":"Rechnungsreferenz-ID:",
      "INTERNAL_SCHEDULE_RENEWAL_ID":"Interne geplante Verlängerungs-ID",
      "INTERNAL_SCHEDULED_RENEWAL_ID":"Interne geplante Verlängerungs-ID",
      "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"Statusänderungen bei automatischer Verlängerung sind nicht zulässig",
      "INVALID_ATTEMPT_DECREASE":"Eine Verringerung der Menge ist nicht zulässig",
      "INVALID_ATTEMPT_REACTIVATE":"Eine erneute Aktivierung des Produkts ist nicht zulässig",
      "INVALID_ATTEMPT_SUSPEND":"Das Aussetzen des Produkts ist nicht zulässig",
      "INVALID_COUNT":"Die Anzahl der Sitzplätze darf nicht kleiner als 1 sein",
      "INVALID_COUPON":"Dieser Gutschein ist nicht gültig",
      "INVALID_EXPIRY_DATE":"Ungültiges Ablaufdatum",
      "INVALID_INPUT":"Ungültige Eingabe",
      "INVALID_MESSAGE_ENTITY":"Ungültige Nachrichtenentität",
      "INVALID_MESSAGE_TYPE":"Ungültiger Nachrichtentyp",
      "INVALID_PORTAL_PAGE":"Ungültige Portalseite",
      "INVALID_PRODUCT_ID":"Ein Produkt mit der angegebenen Kennung wurde im System nicht gefunden.",
      "INVALID_QUANTITY":"Ungültige Menge",
      "INVALID_RECIPIENTS":"Ungültige Empfänger",
      "INVALID_SEATS":"Fehlende oder ungültige Details. Plätze sollten entweder eine positive oder eine negative Zahl sein, die durch die Einschränkungen des Anbieters begrenzt ist",
      "INVALID_VALUE":"{{atribute}} ist ungültig",
      "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Zurück zu Einladungen",
      "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Einladung hinzufügen",
      "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Einladen bearbeiten",
      "INVITES_ADD_FORM_BUTTON_CLEAR":"Alles löschen",
      "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Planen",
      "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Ausgewählte Elemente",
      "INVITES_ADD_FORM_LABEL_OFFER":"Bietet an",
      "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Angebotsname",
      "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Preis",
      "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Beschreibung",
      "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Name",
      "INVITES_ADD_FORM_URL_INVITE_URL":"URL einladen",
      "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Lädt ein",
      "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Neue Einladung hinzufügen",
      "INVITES_LIST_CAPTION_TEXT_INVITES":"Lädt ein",
      "INVITES_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
      "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Bearbeiten",
      "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Beschreibung der Einladung",
      "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Name der Einladung",
      "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
      "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Plan Name",
      "INVITES_SAVE_SUCCESS":"Einladung erfolgreich gespeichert",
      "INVITES_SELECT_OFFERS_PROMPT":"Bitte fügen Sie der Einladung mindestens ein Angebot bei",
      "INVITES_UPDATE_SUCCESS":"Einladung erfolgreich aktualisiert",
      "INVOICE_ADD_ADJUSTMENT_END_DATE":"Endtermin",
      "INVOICE_ADD_ADJUSTMENT_START_DATE":"Anfangsdatum",
      "INVOICE_ADJUSTMENT_CONFIRMATION":"Möchten Sie dies als Anpassung in dieser Rechnung?",
      "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Liste der Rechnungen und Gebührenberichte zu erhalten.",
      "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Dashboard",
      "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Rechnungen",
      "INVOICE_COMMENTS":"Rechnung",
      "INVOICE_COUPON_APPLIED_MESSAGE":"Der Rabattcoupon <strong> {{CouponCode}}*</strong> wird angewendet",
      "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Restbetrag fällig",
      "INVOICE_DOWNLOAD_SELECT_ALL":"Wählen Sie Alle",
      "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"E-Mail wird erfolgreich an die Benutzerliste gesendet",
      "INVOICE_FOOTER_LINE_DESC":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
      "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
      "INVOICE_FOOTER_LINE_DISP":"Rechnungsfußzeile",
      "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Rechnungsfußzeile",
      "INVOICE_GENERATION_REASON":"Grund der Rechnungserstellung",
      "INVOICE_GENERATION_STATUS_TITLE":"Rechnungserstellungsstatus",
      "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gehen Sie zu Umsatz- und Kostenübersicht",
      "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Laden Sie Rechnungen/Zahlungen herunter",
      "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Zurück zur Umsatz- und Kostenübersicht",
      "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Laden Sie Rechnungen/Zahlungen herunter",
      "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Kundenrechnungen erfolgreich importiert",
      "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Bitte wählen Sie mindestens eine Kundenrechnung aus, bevor Sie fortfahren.",
      "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Rechnungen, die für die Kunden heruntergeladen werden sollen",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Währungscode",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
      "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Qualifizierter Name",
      "INVOICE_INVOICE_APPROVE":"Genehmigen",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Zurück zu Rechnungsposten",
      "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Zurück zu Rechnungen",
      "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Download Rechnung",
      "INVOICE_INVOICE_CAPTION_TEXT":"Rechnung für",
      "INVOICE_INVOICE_FINALIZE":"Finalisieren",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Kontoübersicht",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adresse",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Kunde",
      "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Rechnungszusammenfassung",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"Abrechnungszeitraum",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Rechnungsdatum",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"Umsatzsteuer-Identifikationsnummer",
      "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Steuerrechnung",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Rechnungsdetail",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Rechnungsgebühren",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Saldo aus vergangenen Zahlungen",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Lizenzbasiert",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Nutzungsabhängig",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Menge",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Ladungsart",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Kosten für Partner",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Gutscheincode",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Beschreibung",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Rabatt",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Rabattbetrag",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Angebotspreis",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Menge",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Endtermin",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Gesamtsumme",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Rechnungspostenbetrag",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Einzelheiten",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Betrag nach Steuern",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Zwischensummenbetrag",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Mieter",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Anfangsdatum",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Steuerbetrag",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Steuerprozentsatz",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Stückpreis",
      "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Keine Rechnungen gefunden. Bitte wenden Sie sich an den c3-Support.",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Rechnungszahlungen",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Menge",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Datum",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Fehlergrund",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Gesamtsumme",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Bemerkungen",
      "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Status",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Fälliger Betrag",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Rechnungsdatum",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Status",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Bezahlte Menge",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Restbetrag",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
      "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Verwendeter Betrag",
      "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Anpassungen",
      "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Cycle Instance Prorate",
      "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Frühere Rechnungsgebühren",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Prorate Gebühr",
      "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Anteilige Gebühren beim Kauf",
      "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Kaufgebühr",
      "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"Rückerstattungsbetrag",
      "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Verlängerungsgebühr",
      "INVOICE_LINE_ITEM_TYPE_TAXES":"Steuern",
      "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Nutzungsbetrag",
      "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Anerkennung",
      "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--verknüpft mit {{linkedProductName}}",
      "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
      "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>{{providerName}} Mandant </b>{{providerTenantName}}",
      "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Wechselkurs",
      "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Zwischensumme",
      "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Rechnungsposten mit Steuern",
      "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Aktionsrabatt",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Aktionen",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Email",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Bericht über Rechnungseinzelposten",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Rechnungseinzelpostenbericht zu erhalten",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf „Senden“, um eine Datei mit einer Liste des Rechnungspostenberichts zu erhalten",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Liste der Rechnungspostenberichte zu erhalten.",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Stornieren",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Favoriten speichern",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"E-Mail senden",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
      "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Rechnungen",
      "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Zahlungen",
      "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
      "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Rechnung generiert am",
      "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Fehlergrund",
      "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Restbetrag",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Bezahlte Menge",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Zahlungsdatum",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"Transaktions-ID",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Bemerkungen",
      "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Zahlungsstatus",
      "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Verwendeter Betrag",
      "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Kontaktprotokolle anzeigen",
      "INVOICE_NUMBER":"Rechnungsnummer",
      "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Kunden stellen Zahlungen für den erfolgreichen Import in Rechnung",
      "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Rechnungszahlungen müssen für die Kunden heruntergeladen werden",
      "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
      "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"Das Fälligkeitsdatum kann nicht mit einem Nullwert aktualisiert werden",
      "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"Rechnungseigenschaft erfolgreich aktualisiert",
      "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"Das Startdatum darf nicht größer als das Enddatum sein",
      "INVOICE_SENT_SUCCESSFULLY":"Rechnung erfolgreich gesendet",
      "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Zur Kundenbewertung zugelassen",
      "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Die Rechnung wurde fertiggestellt",
      "INVOICE_STATUS_NAME_IN_PROGRESS":"In Bearbeitung",
      "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Rechnung generiert",
      "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Rechnung veröffentlicht",
      "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Voller Betrag bezahlt",
      "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Teilbetrag bezahlt",
      "INVOICE_TAB_NAME_COMMENTS":"Bemerkungen",
      "INVOICE_TAB_NAME_DETAILS":"Einzelheiten",
      "INVOICE_TAB_NAME_NOTIFICATIONS":"Benachrichtigungen",
      "INVOICE_TRANSACTION_REPORT_HEADER":"Rechnungen und Gebührenbericht",
      "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
      "INVOICE_WILLBE_MAILED":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Danke dir.",
      "InvoiceCurrency":"Rechnungswährung",
      "INVOICES_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
      "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Abrechnungszeitraum",
      "INVOICES_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
      "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
      "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Rechnungserstellungsdatum",
      "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "IS_MANAGEABLE_ONLY_BY_PARTNER":"Ist nur vom Partner verwaltbar",
      "IS_MASTER":"Meister",
      "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Ist für Reseller-Kunden eine Partnergenehmigung erforderlich?",
      "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"Wird das Produkt mit Addons reaktiviert",
      "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"Ist ein Filter für den Kunden verfügbar",
      "IS_TRIAL_OFFER":"Probeangebot",
      "IsCustomPrice":"Ist ein benutzerdefinierter Preis",
      "JANUARY":"Januar",
      "JULY":"Juli",
      "JUNE":"Juni",
      "LABEL_ASSIGNED_RG":"Zugewiesene Ressourcengruppen",
      "LABEL_BANNER_TEXT":"Botschaft",
      "LABEL_BANNER_TEXT_TOOL_TIP":"Schreiben Sie die Nachricht für das Banner.",
      "LABEL_BILLING_ADDRESS":"Rechnungsadresse",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"Das Testangebot kann nur einmal erworben werden.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Dieses Testangebot ist bereits in Ihrem Warenkorb verfügbar.",
      "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"Probeangebot für",
      "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Testangebot für Eltern nicht verfügbar",
      "LABEL_Customer_Name":"Kundenname",
      "LABEL_CUSTOMER_NAME":"Kundenname",
      "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"Planprodukt(e) wurden ausgewählt",
      "LABEL_DESC_SELECTED_PLANS":"Pläne wurden ausgewählt",
      "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"Produktvariante(n) wurden ausgewählt.",
      "LABEL_EFFECTIVE_ENTITY":"Relevante Entität",
      "LABEL_EFFECTIVE_FROM":"Gültig ab",
      "LABEL_EFFECTIVE_RECORD_NAME":"Effektive Aufzeichnung",
      "LABEL_EFFECTIVE_TO":"Läuft aus am",
      "LABEL_IS_ACTIVE":"Ist aktiv",
      "LABEL_NOTIFICATION_RECIPIENTS":"Benachrichtigungsempfänger",
      "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Geben Sie die Benutzer als durch Kommas getrennte Werte ein",
      "LABEL_NOTIFICATION_TABLE":"Bannerbenachrichtigungen",
      "LABEL_NOTIFICATION_TYPE":"Nachrichtenstil",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Richtlinie zuweisen für",
      "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Richtlinienzuweisungen von",
      "LABEL_POLICY_CUSTOMER":"Kunde",
      "LABEL_POLICY_DEFINITION":"Richtliniendefinition",
      "LABEL_POLICY_DESCRIPTION":"Richtlinienbeschreibung",
      "LABEL_POLICY_NAME":"Versicherungsname",
      "LABEL_POLICY_RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
      "LABEL_POLICY_SUBSCRIPTION":"Abonnement",
      "LABEL_PORTAL_PAGE":"Portalseite",
      "LABEL_ROLE_ADD_CUSTOM_ROLE":"Benutzerdefinierte Rolle hinzufügen",
      "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Zurück zu benutzerdefinierten Rollen",
      "LABEL_ROLE_CUSTOM_ROLE_ADD":"Benutzerdefinierte Rolle hinzufügen",
      "LABEL_ROLE_CUSTOMER":"Kunde",
      "LABEL_ROLE_RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
      "LABEL_ROLE_ROLE_DEFINITION":"Rollendefinition",
      "LABEL_ROLE_ROLE_DESCRIPTION":"Rollenbeschreibung",
      "LABEL_ROLE_ROLE_NAME":"Rollenname",
      "LABEL_ROLE_SUBSCRIPTION":"Abonnement",
      "LABEL_SHIPPING_ADDRESS":"Lieferanschrift",
      "LABEL_TEXT_ADDONS":"Addons",
      "LABEL_TEXT_ADDRESS_1":"Adresse 1",
      "LABEL_TEXT_ADDRESS_2":"Adresse 2",
      "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden",
      "LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "LABEL_TEXT_BILLING_TYPE":"Abrechnungsart",
      "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" Die Stornierungsfrist läuft ab",
      "LABEL_TEXT_CATEGORY":"Kategorie",
      "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Erstellen Sie ein Microsoft-Konto",
      "LABEL_TEXT_DISCOUNT":"Rabatt",
      "LABEL_TEXT_DISTRIBUTER_OFFER":"Händlerangebote",
      "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Aktivieren Sie alle Werbeaktionen",
      "LABEL_TEXT_LINKED_PRODUCT":"Verknüpftes Produkt",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Kategorie :",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"Unbefristete Software",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Software-Abonnements",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Begriff",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":"Begriff -",
      "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Begriff :",
      "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"Für den Kunden nicht verfügbar",
      "LABEL_TEXT_OWNER_NAME":"Besitzername",
      "LABEL_TEXT_PRICE":"Preis",
      "LABEL_TEXT_RESERVED_INSTANCES":"RI",
      "LABEL_TEXT_RETAIL_PRICE":"Verkaufspreis",
      "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Quellabonnement",
      "LABEL_TEXT_STATE_PROVINCE":"Landesprovinz",
      "LABEL_TEXT_STATUS":"Status",
      "LABEL_TEXT_TARGET_SUBSCRIPTION":"Zielabonnement",
      "LABEL_TEXT_TAX_CODE":"Steuer-Code",
      "LABEL_TEXT_TAX_TYPE":"Steuertyp",
      "LABEL_TEXT_TENANT_NAME":"Name des Mieters",
      "LABEL_TEXT_TYPE_IN_NAME":"Geben Sie einen Namen ein",
      "LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "LABEL_TEXT_ZIP_CODE":"Postleitzahl",
      "LABEL_TO_INDICATE_A_NEW_OFFER":"Neu",
      "LABEL_UNASSIGNED_RG":"Nicht zugewiesene Ressourcengruppen",
      "LABLE_ADDITIONAL_CHARGES_TOTAL":"Zusätzliche Kosten",
      "LABLE_CUSTOMER_ADMIN_ROLE":"Kundenadministrator",
      "LABLE_CUSTOMER_READER_ROLE":"Kundenleser",
      "LABLE_DISCOUNTS_TOTAL":"Totaler Rabatt",
      "LABLE_SUBSCRIPTIONS_TOTAL":"Abonnements insgesamt",
      "LABLE_TAXES_TOTAL":"Steuern insgesamt",
      "LABLE_TEXT_COST_PRICE":"Selbstkostenpreis",
      "LABLE_TEXT_COST_TO_PARTNER":"Kosten für den Partner",
      "LABLE_TEXT_DISPLAY_NAME":"Anzeigename",
      "LABLE_TEXT_EVENTS":"Veranstaltungen",
      "LABLE_TEXT_FRIENDLY_NAME":"Freundlicher Name",
      "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"Werbeaktion für Kunden verfügbar?",
      "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"Werbeaktion für Reseller verfügbar?",
      "LABLE_TEXT_MAXVALUE":"MaxValue:",
      "LABLE_TEXT_MINVALUE":"MinValue:",
      "LABLE_TEXT_OFFER_NAME":"Angebotsname",
      "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Anbieter Verkaufspreis",
      "LABLE_TEXT_SALE_PRICE":"Verkaufspreis",
      "LABLE_TEXT_SALEPRICE":"Verkaufspreis:",
      "LABLE_TEXT_SLAB":"Plattendetails",
      "LABLE_TEXT_SLAB_SALE_PRICE":"Verkaufspreis (UOM)",
      "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Kosten für den Partner",
      "LABLE_TEXT_TYPE":"Abrechnungsart",
      "LABLE_TEXT_UNIT_OF_MEASURE":"(UOM)",
      "LABLE_WAIVER":"Verzicht",
      "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"Der letzte Versuch, die Daten zu laden, schlug fehl. Klicken Sie bitte auf „Aktualisieren“, um die neuesten Änderungen von PSA zu C3 zu synchronisieren.",
      "LATEST_COMMENTS":"Letzter Kommentar",
      "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Anmeldung",
      "LAYOUTLOGIN_HEADER_TEXT":"Anmeldung",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Bitte einloggen zum Fortfahren",
      "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Spektra Systems. Alle Rechte vorbehalten.",
      "LBL_COUPON_CODE_TEXT":"Gutscheincode",
      "LBL_DISCOUNT_TEXT":"Rabatt",
      "LBL_OFF":"aus",
      "LBL_TEXT_NOT_PROVIDED":"Nicht vorgesehen",
      "LESS_THAN_LIST_PRICE":"LessThanListPrice",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Bitte finden Sie die Fehler unten.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Dieser Vorgang kann nicht abgeschlossen werden, da die Voraussetzungen nicht erfüllt sind. Für die Zuweisung eines Add-Ons ist ein Basisangebot erforderlich, und für den Widerruf eines Basisangebots müssen die Add-Ons freigegeben werden.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Dieser Vorgang kann nicht abgeschlossen werden, da die Voraussetzungen nicht erfüllt sind. Für die Zuweisung eines Add-Ons ist ein Basisangebot erforderlich, und für den Widerruf eines Basisangebots müssen die Add-Ons freigegeben werden.",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Angebot",
      "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Vorausgesetzte Angebote",
      "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Antrag abgelehnt",
      "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Lizenzänderungsbericht herunterladen",
      "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Abrufen des Lizenzänderungsberichts",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"Schlussmenge",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Erstellt von",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Kundenname",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Änderungsdatum",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Lizenzänderungsbericht",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Neue Menge",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Neuer Status",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Alte Menge",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Alter Status",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Öffnungsmenge",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Produkt",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Verkäufer Name",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Name des verknüpften Mandanten",
      "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"AGB-Status",
      "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"Das „Von“-Datum des Berichts darf nicht größer sein als das „Bis“-Datum",
      "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptiert",
      "LICENSE_CONSUMPTION_REPORT":"Lizenzverbrauchsbericht",
      "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Wählen",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Kunde",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
      "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Mieter",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Bitte wählen Sie den Kunden aus",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Bitte geben Sie den Abonnementnamen ein",
      "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Bitte Mieter auswählen",
      "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filter",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Zugewiesener Mengenunterschied",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Verfügbar in C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Verfügbar im PC",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Zugewiesene Lizenzen In C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Lizenzen in C3",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Kunde",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"E-Mail-Addresse",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Gesamtmengenunterschied",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Zugeordnete Produkte",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Zugewiesene Lizenzen im Anbieter",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Lizenzen im Anbieter",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Nicht verwendete Lizenzen im Anbieter",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Abonnement",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Mieter",
      "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Details anzeigen",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Lizenzverbrauchsbericht",
      "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Ergebnisbericht",
      "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Export License Consumption Report CSV",
      "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Mieter",
      "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Lizenzübersicht abrufen",
      "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Lizenzzusammenfassungsbericht herunterladen",
      "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Lizenzübersicht",
      "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Verkäufer Name",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Rechnungskreislauf",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Name des übergeordneten Abonnements",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Anbietername",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Provider Mieter ID",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Menge",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Erneuerung – (in Tagen)",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"Erneuert weiter",
      "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Aktionen",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Verfallsdatum",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Name",
      "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"Gutscheinschlüssel",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Lizenzverbrauch",
      "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Lassen Sie nicht verwendete Sitze frei",
      "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Geben Sie nicht verwendete Sitze frei",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Nicht genutzte Sitze freigeben. Warten Sie mal...",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Zugewiesen",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Zugeordnete Produkte",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Gesamt",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname (n)",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Ungebraucht",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
      "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
      "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Zugewiesen",
      "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Gültig",
      "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
      "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Verfügbar",
      "LICENSES_TOOLTIP_MS_USER":"Lizenzen",
      "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Link zum Abrechnungsprofil von",
      "LINK_CUSTOMER_HEADER_TEXT":"Link zum Anbieter von",
      "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Sie sind mit allen anwesenden Anbietern verbunden",
      "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"Kundentyp",
      "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"Kundentyp",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Mieter-ID",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Name des Mieters",
      "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Microsoft-Nicht-CSP-Kunde erfolgreich mit c3 verknüpft.",
      "LINK_RESELLER_CAPTION_TEXT":"Link Reseller",
      "LINK_RESELLER_FORM_LABEL_PROVIDER":"Anbieter",
      "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Reseller-ID (wie im Anbieter)",
      "LINK_RESELLER_LIST_BUTTON_DELETE":"Löschen",
      "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
      "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Geschäfts ID",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Anbietername",
      "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Provider Reseller Id",
      "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Sie sind mit all Ihren Anbieterprofilen verknüpft.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Der Link zu {{providerName}} wurde entfernt.",
      "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Erfolgreich mit {{providerName}} verknüpft.",
      "LINK_TEXT_CLICK_HERE":"Klicke hier",
      "linkCustomerBillingProfile":"Link zum Abrechnungsprofil",
      "LINKED_PRODUCT_INFO_SHOPE":"Dieses Produkt ist verknüpft mit",
      "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Wählen",
      "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Link zu einem Produkt",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Überprüfung der Domainverfügbarkeit",
      "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Kategorie",
      "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Anbieter",
      "LOADING_INDICATOR_MESSAGE":"Wird bearbeitet...",
      "LOADING_MESSAGE":"Wird geladen...",
      "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>Aktionen des aktuellen Monats werden bereits geladen</p> <p>Möchten Sie die Aktionen neu laden?</p>",
      "LOADPROMOTION_CATEGORY_TOOLTIP":"Bitte wählen Sie die Produktkategorie aus der Dropdown-Liste aus",
      "LOADPROMOTION_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
      "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Geben Sie uns einen Moment Zeit, um Ihren Identitätswechsel als abzuschließen",
      "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Schöne Grüße,",
      "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Sie sind jetzt angemeldet. Geben Sie uns einen Moment Zeit, um die Anwendung für den Zugriff vorzubereiten...",
      "LOW":"Niedrig",
      "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Wenden Sie X % auf den Aufschlag an",
      "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Wenden Sie X % auf den Partnerpreis an",
      "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Wenden Sie X % auf den Aufschlag an",
      "MACRO_COPY_PARTNERT_PRICE":"Partnerpreis kopieren",
      "MACRO_COPY_PROVIDER_SELLING_PRICE":"Verkaufspreis des Kopieranbieters",
      "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts ist die Summe aus Partnerpreis + X % der Marge zwischen Partnerpreis und Anbieterverkaufspreis",
      "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts beträgt Partnerpreis + X % (Partnerpreis).",
      "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts ist der Verkaufspreis des Anbieters + X % (Verkaufspreis des Anbieters).",
      "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts entspricht dem Partnerpreis",
      "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts entspricht dem Verkaufspreis des Anbieters",
      "MACRO_DET_EXAMPLE":"Beispiel:",
      "MACRO_DET_PARTNER_PRICE":"Partnerpreis",
      "MACRO_DET_PERCENTAGE":"Prozentsatz(X)",
      "MACRO_DET_PROVIDER_SELLING_PRICE":"Anbieter Verkaufspreis",
      "MACRO_DET_SALE_PRICE":"Verkaufspreis:",
      "MACRO_PERCENTAGE_TOOLTIP_TEXT":"Makro %",
      "MAIL_SENT_SUCCESSFULY":"Mail erfolgreich gesendet",
      "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Berücksichtigen Sie dies bei der automatischen Freigabe",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Nein",
      "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Ja",
      "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Wird in Auto-Release berücksichtigt",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Wird bei der automatischen Veröffentlichung berücksichtigt",
      "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Ausschließlich durch den Partner verwaltbar",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Ihre Anfrage für Lizenzzuweisungen wird in die Warteschlange gestellt. Bitte besuchen Sie diese Seite nach einiger Zeit für Updates.",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Bitte geben Sie im Mieter gültige E-Mail-Adressen der Benutzer ein",
      "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"Benutzer {{username}} besitzt bereits eine Lizenz für {{Abonnementname}}",
      "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie mindestens eine Datei aus",
      "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Möchten Sie die Fehler wirklich ignorieren?",
      "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Die Mindestanzahl der Sitzplätze für ein Abonnement sollte 1 betragen. Sitzplätze können nicht freigegeben werden, wenn die verfügbare Anzahl der Sitzplätze 1 beträgt.",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Sind Sie sicher, dass Sie nicht genutzte Sitze freigeben möchten?",
      "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Nicht verwendete Lizenzen wurden erfolgreich freigegeben",
      "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Bitte fügen Sie mindestens einen Benutzer zum Eimer mit den zu entfernenden Lizenzen hinzu",
      "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Bitte wählen Sie den Benutzer aus der vorgeschlagenen Liste aus, bevor Sie auf die Schaltfläche Hinzufügen klicken",
      "MANAGE_LICENSES_UPDATE_SUCCESS":"Lizenzzuweisung erfolgreich abgeschlossen",
      "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Bei der Verarbeitung der Datei ist ein Fehler aufgetreten. Versuchen Sie es erneut",
      "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Lizenzdetails erfolgreich hochgeladen. Bitte kehren Sie nach einiger Zeit zu diesem Bildschirm zurück, um den Status zu überprüfen.",
      "MANAGE_NAME_CHANGE_ERROR":"Der Name darf nicht leer sein",
      "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Angebote anzeigen)",
      "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Makro bearbeiten",
      "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Produkte können nicht mit diesem Angebot verknüpft werden",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Nur Werbung anzeigen",
      "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Ja",
      "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Geben Sie einen Namen ein",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Kopieren Sie den Listenpreis in den Verkaufspreis - der Verkaufspreis entspricht dem Listenpreis",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"X% zum Listenpreis hinzufügen - Der Verkaufspreis beträgt X% des Listenpreises + Listenpreis",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Erhöhen Sie den Preis um Y Cent - der Verkaufspreis ist Listenpreis + Y Cent",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Löschen Sie alle ausgewählten Angebotspreise",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"X% auf Aufschlag hinzufügen - Verkaufspreis ist Listenpreis + (ERPPreis-Listenpreis)*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"Addiere X% zum MS-Preis",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"Der Verkaufspreis beträgt X% des MS-Preises + des MS-Preises",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"Kopieren Sie den MS-Preis in den Verkaufspreis",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"Der Verkaufspreis entspricht dem MS-Preis",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Addiere X% zum Aufschlag",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"Der Verkaufspreis beträgt MS Price + (ERPPreis-MS Preis*X%",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Erhöhen Sie den Preis um Y Cent",
      "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"Der Verkaufspreis beträgt MS Price + Y Cent",
      "MANAGE_PLAN_LBL_1":"Kopieren Sie den Listenpreis in den Verkaufspreis",
      "MANAGE_PLAN_LBL_2":"X% zum Listenpreis hinzufügen",
      "MANAGE_PLAN_LBL_3":"Erhöhen Sie den Preis um Y Cent",
      "MANAGE_PLAN_LBL_4":"Löschen Sie alle Preise",
      "MANAGE_PLAN_LBL_5":"Addiere X% zum Aufschlag",
      "MANAGE_PLAN_LBL_6":"Kopieren Sie den ERP-Preis in den Verkaufspreis",
      "MANAGE_PLAN_MACRO_APPLIED":"Makro angewendet",
      "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"Das neu ausgewählte Makro gilt für Angebote, die künftig zum Plan hinzugefügt werden, und hat keine Auswirkungen auf das vorhandene Makro bei Angeboten, die bereits hinzugefügt wurden.",
      "MANAGE_PLAN_NO_MACRO_APPLIED":"Kein Makro angewendet",
      "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"Nicht käuflich zu erwerben",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Schließen",
      "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Inbegriffene Angebote",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Schließen",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Name",
      "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Elternnamen",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Möchten Sie wirklich alle Add-Ons löschen?",
      "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie dieses Produkt wirklich löschen?",
      "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}}</b> ist ein übergeordnetes Angebot für ein Testprodukt. <br/> Durch das Löschen des Premium-Angebots wird auch das zugehörige Testangebot gelöscht.<br/> Sind Sie sicher, dass Sie <b>{{productName}}<b/> löschen möchten?",
      "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Addons werden dem Plan hinzugefügt. Bitte speichern Sie den Plan, um Ihren Vorgang abzuschließen.",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"zum Plan hinzugefügt!",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"hinzugefügt, um mit Add-Ons zu planen",
      "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" mit einem Elternangebot zum Plan hinzugefügt",
      "MANAGE_PLAN_TOOLTIP_ON_SELECTING_MARKUP_OR_PERCENTAGE_VALUE":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Es wurden keine passenden Add-Ons gefunden",
      "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Möchten Sie das Angebot entfernen?",
      "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"Das Add-On, das Sie hinzufügen möchten, ist bereits enthalten",
      "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Verbrauchsarten",
      "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"Ist Wanderangebot",
      "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Abonnementstatus",
      "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Bitte überprüfen Sie die folgenden Angebote, da der Verkaufspreis unter dem Listenpreis liegt.",
      "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Klicken Sie auf \\''OK\\'', um die Planänderungen zu übermitteln. 'CANCEL', um die Planangebote erneut zu überprüfen.",
      "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"Das ausgewählte Angebot war bereits enthalten",
      "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Bitte geben Sie den freundlichen Namen und den effektiven Preis für die im Plan enthaltenen Angebote an",
      "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Bitte überprüfen Sie die folgenden Angebote, da der Verkaufspreis höher ist als der ERP-Preis.",
      "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Bitte fügen Sie dem Plan Angebote hinzu",
      "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Weitere Addons hinzufügen",
      "MANAGE_PONUMBER_CHANGE_ERROR":"Die PONumber darf nicht leer sein",
      "MANAGE_PRODUCT_OWNERSHIP":"Produktbesitz verwalten",
      "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Verwalten des Produktbesitzes fehlgeschlagen",
      "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Das Verwalten des Produktbesitzes ist nicht zulässig, da für eines der Produkte oder deren Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
      "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Neue Gutscheine erfolgreich hinzugefügt",
      "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
      "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Klare Preise",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Kopieren Sie den MS-Preis in den Reseller-Preis. Der Reseller-Preis entspricht dem MS-Preis",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"X% zum MS-Preis hinzufügen - Der Reseller-Preis beträgt X% des MS-Preises + MS-Preis",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Erhöhen Sie den Preis um Y Cent - der Reseller-Preis beträgt MS Price + Y Cent",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Löschen Sie alle ausgewählten Angebotspreise",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"X% auf Aufschlag hinzufügen - Wiederverkäuferpreis ist MS-Preis + (ERPPreis-MS-Preis)*X%",
      "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Wenn Sie die Reseller-Angebote hinzufügen möchten, müssen Sie die folgenden Anweisungen befolgen",
      "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Ausgewählte Elemente",
      "MANAGE_RELLER_OFFERS_LBL_1":"Kopieren Sie den MS-Preis in den Reseller-Preis",
      "MANAGE_RELLER_OFFERS_LBL_2":"Addiere X% zum MS-Preis",
      "MANAGE_RELLER_OFFERS_LBL_3":"Erhöhen Sie den Preis um Y Cent",
      "MANAGE_RELLER_OFFERS_LBL_4":"Löschen Sie alle Preise",
      "MANAGE_RELLER_OFFERS_LBL_5":"Addiere X% zum Aufschlag",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Möchten Sie alle gefilterten Angebote aus ausgewählten Angeboten entfernen?",
      "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Angebote entfernen?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Möchten Sie alle gefilterten Angebote auswählen?",
      "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Angebote auswählen?",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Gültig ab",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Lizenz-Typ",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Listenpreis",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Wiederverkäuferpreis",
      "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Wenn Sie die Menge reduzieren möchten, wenden Sie sich bitte an das Support-Team.",
      "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Zurück zu den geplanten Verlängerungen",
      "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Stornieren",
      "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Erstellen Sie einen neuen Zeitplan",
      "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"einreichen",
      "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Kumulierte Menge",
      "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Aktuelles Abonnement",
      "MANAGE_RENEWAL_DESCRIPTION_1":"Änderungen treten am Verlängerungsdatum des Abonnements in Kraft: <b>{{renewalDate}}</b>. Bei SKU-Upgrades werden Lizenzen automatisch zugewiesen, wenn sich die Menge nicht ändert. Andernfalls müssen Sie die Lizenzen manuell zuweisen.",
      "MANAGE_RENEWAL_HEADER_TEXT":"Erneuerung verwalten",
      "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Aktuell: {{currentEnddate}}",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Wählen Sie aus verschiedenen SKUs:",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Wählen Sie einen anderen Abrechnungszeitraum:",
      "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Wählen Sie einen anderen Begriff:",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Endtermin",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Ausrichtung des Enddatums",
      "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Enddatumstyp",
      "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Produkt planen",
      "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Menge",
      "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Wählen Sie ein berechtigtes Planprodukt für Ihr Abonnement (verwenden Sie diese Option, wenn Sie SKU/Abrechnungszeitraum/Laufzeit ändern und die Änderungen planen möchten).",
      "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Begriff",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Zuvor ausgewähltes Planprodukt zur geplanten Verlängerung für SKU-/Laufzeit-/Abrechnungszykluswechsel:",
      "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Produktname planen",
      "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Sie können Ihr Abonnement gleichzeitig mit einem bestehenden OnlineServicesNCE-Abonnement ohne Testversion kündigen oder das Enddatum an den Kalendermonat anpassen, indem Sie abhängig von der Laufzeit ein geeignetes Enddatum auswählen.",
      "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Zeitplan aktualisieren",
      "MANAGE_RESELLER_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Bietet an",
      "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Wiederverkäufer",
      "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Reseller-Angebote hinzufügen",
      "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Bietet an",
      "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Bitte geben Sie den effektiven Preis für die ausgewählten Angebote an",
      "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Angebote erfolgreich gespeichert",
      "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Bitte fügen Sie dem Reseller Angebote hinzu",
      "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Alle Angebote ausgewählt. Wenn Sie etwas bearbeiten möchten, das mit einem Planangebot zusammenhängt, müssen Sie den Plan bearbeiten!",
      "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Liste der Wiederverkäuferpläne",
      "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Planstatus erfolgreich aktualisiert",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Deaktivieren",
      "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Aktivieren",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Name",
      "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Läuft aus am",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Automatische Verlängerung aktiviert",
      "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"Produkt ID",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Wenn die automatische Verlängerung deaktiviert ist, läuft dieses Abonnement am ab",
      "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":"Wenn die automatische Verlängerung deaktiviert ist, läuft dieses Abonnement am Ende der Laufzeit ab",
      "manageplans":"Pläne verwalten",
      "MANAGERG_REMOVE_OFFERS_PROMPT":"Möchten Sie die Ressourcengruppe entfernen?",
      "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Beauftragen Sie den Kunden, die Zahlungsdetails zu aktualisieren.",
      "MANUAL_SYNC_PUPUP_TITLE":"Manuelle Synchronisierung",
      "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Ihr Fix wurde erfolgreich angewendet",
      "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Bitte wählen Sie eine PSA-Vereinbarung aus",
      "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Bitte wählen Sie einen Vertrag",
      "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Bitte wählen Sie einen Kunden aus",
      "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Bitte wählen Sie einen PSA-Kunden aus",
      "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Bitte wählen Sie ein PSA-Produkt",
      "MARCH":"März",
      "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese Adresse als Standardadresse markieren möchten?",
      "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese E-Mail als Standard-E-Mail markieren möchten?",
      "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese Telefonnummer als Standardnummer markieren möchten?",
      "Markup":"Markup",
      "MATCHING_PRODUCT_PLAN_TEXT":"Planen:",
      "MAY":"Kann",
      "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Wiederkehrende Zahlungserstellung. Möglicherweise wird auf Ihrer Karte eine Belastung in Höhe von 1,00 $ angezeigt, die in Ihrer nächsten Rechnung als Gutschrift verrechnet wird.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt. Der Kunde sollte sich an seine Bank wenden und die Transaktion erneut versuchen.",
      "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"Die Bank des Kunden hat die Transaktion abgelehnt und den Händler aufgefordert, sich mit der Bank in Verbindung zu setzen. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"Die Transaktion war erfolgreich.",
      "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"Die Transaktion war erfolgreich.",
      "MCB_ERROR_CODE_APPROVED_VIP":"Die Transaktion war erfolgreich. (Dieser Code wird in Australien nicht verwendet.)",
      "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da sie keine Transaktionen zulässt, die per Post/Telefon, Fax, E-Mail oder Internetbestellungen erfolgen. Dieser Fehler bezieht sich auf Kunden, die versuchen, eine Discover Card zu verwenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"Die Bank des Kunden hat die Transaktion abgelehnt und den Kunden aufgefordert, sich an seine Bank zu wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_CAPTURE_CARD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Karte im Verdacht steht, gefälscht zu sein. Die Bank des Kunden (Kartenherausgeber) hat verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte des Kunden einbehalten wird (Karte als verloren oder gestohlen gemeldet). Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"Die Bank des Kunden kann nicht kontaktiert werden, um die Transaktion zu autorisieren. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
      "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"Die Bank des Kunden kann die Kreditkarte dieses Kunden vorübergehend nicht verarbeiten. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"Die Bank des Kunden hat die Transaktion abgelehnt, da der CVV falsch ist. Der Kunde sollte die CVV-Details überprüfen und es erneut versuchen. Wenn dies nicht gelingt, sollte der Kunde eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_DO_NOT_HONOUR":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkartennummer eine Sicherheitsprüfung nicht bestanden hat oder das Guthaben eingefroren oder aufgebraucht wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"Die Bank des Kunden hat die Transaktion abgelehnt, da es sich anscheinend um eine doppelte Übertragung handelt. Keine Aktion erforderlich.",
      "MCB_ERROR_CODE_ERROR":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"Die Bank des Kunden hat die Transaktion abgelehnt, da der Kunde das Auszahlungshäufigkeitslimit überschritten hat. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"Die Bank des Kunden hat die Transaktion abgelehnt, da sie das Kartenlimit des Kunden überschreiten wird. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_EXPIRED_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkarte abgelaufen zu sein scheint. Der Kunde sollte das eingegebene Ablaufdatum überprüfen und es erneut versuchen oder eine andere Kreditkarte verwenden.",
      "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkarte abgelaufen ist oder das Datum falsch ist. Überprüfen Sie das Ablaufdatum in der Transaktion und versuchen Sie erneut, die Transaktion zu verarbeiten.",
      "MCB_ERROR_CODE_FORMAT_ERROR":"Die Bank des Kunden (Kartenherausgeber) kennt die Transaktionsdetails nicht. Der Kunde sollte die Transaktionsinformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten. Normalerweise aufgrund einer falschen TID.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"Die Bank des Kunden hat die Transaktion abgelehnt, da diese Kreditkarte für diese Art von Transaktion nicht verwendet werden kann. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"Die Bank des Kunden hat die Transaktion abgelehnt, da diese Kreditkarte für diese Art von Transaktion nicht verwendet werden kann. Diese kann einer Testkreditkartennummer zugeordnet sein. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da sie diese Art von Transaktion nicht zulässt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"Die Transaktion war erfolgreich. Einige Banken verwenden diesen Code anstelle von „00“.",
      "MCB_ERROR_CODE_INCORRECT_PIN":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der Kunde eine falsche PIN eingegeben hat. Der Kunde sollte seine PIN erneut eingeben, andernfalls eine andere Kreditkarte verwenden.",
      "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkarte nicht ausreichend gedeckt ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_INVALID_AMOUNT":"Möglicherweise wird ein ungültiges Zeichen (z. B. ein Dollarzeichen oder ein Leerzeichen) an das Gateway übergeben. Überprüfen Sie den Code Ihrer Website.",
      "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkartennummer nicht existiert. Überprüfen Sie die Kreditkarteninformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
      "MCB_ERROR_CODE_INVALID_TRANSACTION":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion aufgrund eines ungültigen Formats oder Felds abgelehnt. Überprüfen Sie die Transaktionsinformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
      "MCB_ERROR_CODE_LOST_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Karte als verloren gemeldet wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_CARD_RECORD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkartennummer nicht existiert. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkartennummer einem nicht existierenden Girokonto zugeordnet ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"Die Bank des Kunden hat die Transaktion abgelehnt, da die verwendete Kreditkartennummer kein Kreditkonto ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der ausgewählte Kontotyp für diese Kreditkartennummer nicht gültig ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_ISSUER":"Die Bank des Kunden (Kartenherausgeber) existiert nicht. Überprüfen Sie die Kreditkarteninformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
      "MCB_ERROR_CODE_NO_MERCHANT":"Die Händler-ID ist ungültig. Wenden Sie sich an Ihre Bank und vergewissern Sie sich, dass Sie die richtige Händlerkontonummer angegeben haben.",
      "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkartennummer einem nicht existierenden Sparkonto zugeordnet ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der ausgewählte Kontotyp für diese Kreditkartennummer nicht gültig ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"Die Bank des Kunden hat die Transaktion abgelehnt, da versucht wird, den Betrag zu verarbeiten. Der Kunde sollte den Transaktionsbetrag überprüfen und es erneut versuchen.",
      "MCB_ERROR_CODE_PICK_UP_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet). Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"Die Bank des Kunden hat die Transaktion abgelehnt, da der Kunde mehr als dreimal die falsche PIN eingegeben hat. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der Kunde dreimal die falsche PIN eingegeben hat. Die Bank des Kunden (Kartenherausgeber) hat Sie aufgefordert, die Kreditkarte einzubehalten. Der Kunde sollte eine alternative Kreditkarte verwenden und sich an seine Bank wenden.",
      "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"Die Transaktion wurde nicht verarbeitet und der Kunde sollte versuchen, die Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_RESTRICTED_CARD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkarte einigen Einschränkungen unterliegt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte des Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
      "MCB_ERROR_CODE_SECURITY_VIOLATION":"Die Bank des Kunden hat die Transaktion abgelehnt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_STOLEN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Karte als gestohlen gemeldet wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD":"Die Bank des Kunden hat diese Transaktion abgelehnt, da die Kreditkarte betrügerisch zu sein scheint.",
      "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da ein Betrugsverdacht mit dieser Kreditkartennummer vorliegt.",
      "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"Die Bank des Kunden (Kartenherausgeber) kann während der Transaktion nicht kontaktiert werden. Der Kunde sollte die Kreditkarteninformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_SYSTEM_ERROR":"Die Bank des Kunden konnte die Transaktion nicht verarbeiten. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_TRANSACTION_APPROVED":"Die Transaktion war erfolgreich.",
      "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"Die Bank des Kunden (Kartenherausgeber) erkennt die Kreditkartendaten nicht an. Der Kunde sollte die Kreditkarteninformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"Die Bank des Kunden kann für das Routing nicht gefunden werden. Dieser Antwortcode wird häufig zurückgegeben, wenn der Kunde eine Testkreditkartennummer verwendet. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
      "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Ein nicht näher bezeichneter Fehler ist aufgetreten.",
      "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Kartennummer ungültig oder fehlt.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Ablaufmonat ungültig oder fehlt.",
      "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Ablaufjahr ungültig oder fehlt.",
      "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Sicherheitscode ungültig.",
      "MEDIUM":"Medium",
      "MENU_ACCOUNT_MANAGERS":"Kundenbetreuer",
      "MENU_ADMINISTRATION":"Verwaltung",
      "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Benutzerdefinierte Benachrichtigungen",
      "MENU_ADMINISTRATION_ENGAGE":"Engage <sup class='text-warning'> Beta</sup>",
      "MENU_ADMINISTRATION_MANAGE":"Banner-Benachrichtigungen",
      "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Bannerbenachrichtigungen",
      "MENU_ADMINISTRATION_MANAGE_HEADING":"Verwaltung",
      "MENU_ADMINISTRATION_NOTIFICATIONS":"Benachrichtigungen",
      "MENU_ADMINISTRATION_WEBHOOK":"Webhook",
      "MENU_AZURE_ADVISOR":"Azure Advisor",
      "MENU_AZURE_BILLING_REPORT":"Azure-Abrechnungsbericht",
      "MENU_AZURE_CUSTOM_POLICY":"Benutzerdefinierte Richtlinien",
      "MENU_AZURE_CUSTOM_ROLE":"Benutzerdefinierte Rollen",
      "MENU_AZURE_MARGIN":"C3-Unterstützung",
      "MENU_AZURE_SUBACRIPTION":"Azure-Abonnement",
      "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft",
      "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Kundenbetreuer",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Provisionen",
      "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Produkteigenschaften",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Zitate",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Zitatedetails",
      "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Zitateliste",
      "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Nutzung melden",
      "MENU_CONTACT_LOG":"Benachrichtigungen",
      "MENU_COST_SUMMARY_REPORT":"Kostenübersicht",
      "MENU_CUSTOMER_ANALYTICS":"Kundenanalyse",
      "MENU_CUSTOMER_COMMENTS":"Bemerkungen",
      "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lizenzverbrauch",
      "MENU_CUSTOMER_MICROSOFT_REPORT":"Azure-Verwendung",
      "MENU_CUSTOMER_PROFILE":"Profil",
      "MENU_DASHBOARD":"Dashboard",
      "MENU_EXISTING_ACCOUNT_MANAGERS":"Bestehende Manager",
      "MENU_HELP":"Hilfe",
      "MENU_LICENSE_CHANGE":"Lizenzänderungsbericht",
      "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Zusammenfassender Bericht zum Lizenzverbrauch",
      "MENU_LICENSE_SUMMARY_REPORT":"Lizenzzusammenfassungsbericht",
      "MENU_MANAGE_AZURE_ENTITLEMENTS":"Azure-Berechtigungen verwalten",
      "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Partner bietet Provisioning an",
      "MENU_ONBOARDED_CUSTOMERS_REPORT":"Onboarding Analytics",
      "MENU_PARTNER_AUDIT_LOG":"Audit-Log",
      "MENU_PARTNER_COMMISSION_REPORT":"Kommissionsbericht",
      "MENU_PARTNER_COUPON":"Gutscheine",
      "MENU_PARTNER_DOCUMENTATION":"Dokumentation",
      "MENU_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
      "MENU_PARTNER_SUPPORT_LOG":"Unterstützung",
      "MENU_PARTNER_UPLOAD":"Hochladen",
      "MENU_PENDING_PURCHASE_REQUESTS":"Zulassungen",
      "MENU_PRODUCT_COMMISSIONS":"Provisionen",
      "MENU_PRODUCT_EXTENSIONS":"Produkteigenschaften",
      "MENU_PRODUCT_SEQUENCE":"Produktsequenz",
      "MENU_PURCHSED_PRODUCT_MAPPING":"Gekaufte Produktzuordnung",
      "MENU_RENEWAL_MANAGER":"Erneuerungsmanager",
      "MENU_REPORT_USAGE":"Verwendung",
      "MENU_REPORTS_FOR_CUSTOMER":"Berichte",
      "MENU_RESELLER_PLAN":"Reseller Plan",
      "MENU_RESELLER_PROFILE":"Organisationsprofil",
      "MENU_SCHEDULED_REPORTS":"Geplante Berichte",
      "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Empfänger für",
      "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Ausführungsverlauf für",
      "MENU_TOPBAR_SETTINGS":"die Einstellungen",
      "MENU_USAGE_REPORT":"Azure-Nutzungsbericht",
      "MENU_USERS":"Benutzer",
      "MENUS_ANALYTICS":"Analytik",
      "MENUS_ANALYZE":"Analysieren",
      "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Preise für Azure-Berechtigungsstufen verwalten",
      "MENUS_AZURE_ESTIMATE":"Azure",
      "MENUS_AZURE_GROUPS":"Azure-Gruppen",
      "MENUS_AZURE_MANGE":"Azure-Verwaltung",
      "MENUS_AZURE_REPORTS":"Azure-Berichte",
      "MENUS_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
      "MENUS_CART":"Wagen",
      "MENUS_COUPONS":"Gutscheine",
      "MENUS_CUSTOM_OFFERS":"Partnerangebote",
      "MENUS_CUSTOMER_CART":"Wagen",
      "MENUS_CUSTOMER_DASHBOARD":"Dashboard",
      "MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "MENUS_CUSTOMER_MICROSOFT_USERS":"Benutzer",
      "MENUS_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
      "MENUS_CUSTOMER_ORDERS":"Aufträge",
      "MENUS_CUSTOMER_PRODUCTS":"Produkte",
      "MENUS_CUSTOMER_PROFILE":"Profil",
      "MENUS_CUSTOMER_SHOP":"Shop",
      "MENUS_DAAS":"DaaS<sup class=‘text-danger'>BETA</sup>",
      "MENUS_DISTRIBUTOR_OFFERS":"Händlerangebote",
      "MENUS_DOCUMENTATION":"Dokumentation <sup class='text-risk'>BETA</sup>",
      "MENUS_ENTITY_INVOICES":"Rechnungen",
      "MENUS_FAQ":"FAQ",
      "MENUS_INVITES":"Lädt ein",
      "MENUS_INVOICES":"Rechnungen",
      "MENUS_LICENSE_CONSUMPTION":"Lizenzverbrauch",
      "MENUS_LICENSE_LIST":"Lizenzliste",
      "MENUS_MS_OFFERS":"MS Angebote",
      "MENUS_OPERATING_ENTITIES":"Betriebseinheiten",
      "MENUS_ORDERS":"Aufträge",
      "MENUS_PARTNER_BUNDLES":"Bündel",
      "MENUS_PARTNER_CUSTOMERS":"Kunden",
      "MENUS_PARTNER_DASHBOARD":"Dashboard",
      "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus",
      "MENUS_PARTNER_PLANS":"Pläne",
      "MENUS_PARTNER_QUOTES":"Zitat",
      "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Unternehmen",
      "MENUS_PARTNER_SETTINGS":"die Einstellungen",
      "MENUS_POWER_BI_REPORTS":"Azure-Verwendung",
      "MENUS_PRODUCTS":"Produkte",
      "MENUS_PROFILE":"Profil",
      "MENUS_RESELLER_DASHBOARD":"Dashboard",
      "MENUS_RESELLER_INVOICES":"Abrechnung",
      "MENUS_RESELLER_PAYMENTS":"Zahlungen",
      "MENUS_RESELLER_PLANS":"Pläne",
      "MENUS_RESELLER_PRICE_AND_OFFERS":"Preise und Angebote",
      "MENUS_RESELLER_PROFILE":"Organisationsprofil",
      "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Unternehmen",
      "MENUS_RESELLER_SETTINGS":"die Einstellungen",
      "MENUS_RESELLERS":"Wiederverkäufer",
      "MENUS_SELL":"Verkaufen",
      "MENUS_SELL_DIRECT":"Kunden",
      "MENUS_SELL_INDIRECT":"Indirekt verkaufen",
      "MENUS_SELLER_INDIRECT":"Indirekt verkaufen",
      "MENUS_SHOP":"Shop",
      "MENUS_SUBSCRIPTIONS":"Abonnements",
      "MENUS_SUPPORT":"Unterstützung",
      "MENUS_TAXES":"Steuern",
      "MENUS_USER_MANAGEMENT":"Benutzerverwaltung",
      "merge":"Zweig",
      "MESSAGE_ALREADY_EXISTING":"Die Nachricht ist für die relevanten Entitäten bereits vorhanden",
      "MESSAGE_BODY":"Nachrichtentext",
      "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Bitte überprüfen Sie alle erforderlichen Felder und versuchen Sie es erneut",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Für die Entität Produktvariante/Kunde kann dieselbe Kombination nicht noch einmal eingefügt werden.",
      "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Bitte wählen Sie alle Felder aus",
      "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND":"<div ng-include=\"'app/templates/no-data-message.html'\"></div>",
      "MESSAGE_NO_RECORDS_FOUND_USAGE":"Es sind noch keine Daten zu präsentieren, da sie im aktuellen Abrechnungszeitraum nicht verwendet wurden oder die Rechnung von Microsoft noch nicht verfügbar ist. Bitte versuchen Sie es in Kürze erneut.",
      "MESSAGE_NOTIFCATION_SAVED":"Nachrichtenbenachrichtigung erfolgreich gespeichert",
      "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Bitte beheben Sie alle Validierungsfehler und versuchen Sie es erneut",
      "MESSAGE_NOTIFICATION_EDIT_MODE":"Nachrichtenbenachrichtigungen bearbeiten",
      "MESSAGE_NOTIFICATION_EDIT_TEXT":"Bearbeiten",
      "MESSAGE_NOTIFICATION_ID":"Ich würde",
      "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Bitte wählen Sie mindestens einen Empfänger für die Nachrichtenbenachrichtigung aus",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Aktionen",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Datum des Inkrafttretens",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Läuft aus am",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Nachrichtentext",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Nachrichtenstil",
      "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Seitenname",
      "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Beteiligte Entitäten",
      "MESSAGE_ONE_APPLICABLE_FOR_ALL_CUSTOM_OFFERS":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Ein Produkt wurde ausgewählt",
      "MESSAGE_RECORDS_POP_UP":"Rekordliste",
      "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Ein Fehler ist aufgetreten",
      "MESSAGE_TEXT_NO_RECORDS_FOUND":"Keine Aufzeichnungen gefunden!",
      "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Leider sind keine Websites und Abteilungen konfiguriert. Sie können auf der Profilseite neue operative Entitäten hinzufügen",
      "MESSAGE_TEXT_PLEASE_WAIT":"Bitte warten…",
      "MESSAGE_TOOLTIP_AZUREGROUP":"Info, Warnung, Fehler, Gefahr",
      "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Wählen Sie den Nachrichtentyp aus",
      "MESSAGE_TYPE_NAME":"Name des Nachrichtentyps",
      "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Bitte wählen Sie alle Felder aus",
      "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"Ungültige URL. Bitte geben Sie eine gültige URL ein.",
      "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Bitte überprüfen Sie alle erforderlichen Felder und versuchen Sie es erneut",
      "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"URL ist gültig!",
      "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" Dezimalwerte sind nicht zulässig.",
      "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Neue Stufe",
      "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Geben Sie eine gültige Nummer ein.",
      "METERED_BILLING_TEXT_MODAL_HEADER":"Nutzungsstufen",
      "Microsoft":"Microsoft",
      "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Art der Bescheinigung:",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Entfernen",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Kategorie",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Rabatt",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Entfernen",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategorie",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabatt",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Markup",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Prozentsatz",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
      "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Verknüpftes Produkt",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Akzeptiert von",
      "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA akzeptiert am",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Akzeptanzstatus",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"Nicht bereitgestellt",
      "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Bereitgestellt",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Kundenakzeptanz durch Partner bestätigt",
      "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Kundenakzeptanz durch Microsoft",
      "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Microsoft-Kundenvereinbarung erfolgreich gespeichert.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Temporäres Passwort",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nutzername",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"Der Benutzer wurde erfolgreich erstellt und unten sind die Benutzeranmeldeinformationen aufgeführt.",
      "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Nutzerdetails",
      "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Nachdem Sie dieses Popup geschlossen haben, können Sie dieses temporäre Passwort nicht mehr sehen.",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"Erfassung der Microsoft Pricing API-Zustimmung fehlgeschlagen",
      "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Zustimmung zur Microsoft Pricing API erfolgreich erfasst",
      "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"Der Versuch, auf die Microsoft-Preis-API zuzugreifen, war erfolgreich.",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} von {{applicationName}} möchte Ihr Microsoft Cloud Solution Provider für Ihr Microsoft Cloud-Konto sein.<br/><br/>Um die Einladung anzunehmen, führen Sie die folgenden 2 Schritte aus:<br/><br/>Schritt 1: Überprüfen Sie hier, ob das Kundenprofil vollständig ist: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Schritt 2: Nachdem das Kundenprofil vollständig ist (nach der Aktualisierung des Profils kann die Aktualisierung bis zu 5 Minuten dauern), klicken Sie auf den folgenden Link, um diese Einladung anzunehmen und {{applicationName}} zu autorisieren, Ihr Microsoft Cloud Solution Provider zu sein und die Microsoft Customer-Einladung anzunehmen. Vereinbarung.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Hinweis: Um die Beziehung zu akzeptieren, ist ein Benutzer mit globaler Administratorberechtigung erforderlich.<br/><br/><br/>Zusätzliche Partnerinformationen:<br/>{{contactEmail}}<br/>{{contactNumber}}",
      "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Sehr geehrter Kunde,<br/><br/>Um Ihnen eine umfassende Palette an Cloud-Lösungen, -Diensten und -Support bieten zu können, arbeitet {{resellerName}} eng mit {{partnerCompanyName}} zusammen. Um {{resellerName}} und {{partnerCompanyName}} zu autorisieren, Ihr Konto zu bedienen, führen Sie die folgenden 2 Schritte aus: <br/><br/>Schritt 1: Überprüfen Sie hier, ob das Kundenprofil vollständig ist: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Schritt 2: Nachdem das Kundenprofil vollständig ist (die Aktualisierung kann nach der Aktualisierung maximal 5 Minuten dauern), klicken Sie auf den folgenden Link, um dies zu akzeptieren Einladung.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Hinweis: Um die Beziehung zu akzeptieren, ist ein Benutzer mit globaler Administratorberechtigung erforderlich.<br/><br/>{{resellerName}} bleibt weiterhin Ihr lokaler Experte für Produkte, Abonnements, Lizenzen und Support. Seien Sie versichert, dass die Geschäftsbeziehung mit Ihnen unverändert bleibt.<br/><br/>Bitte zögern Sie nicht, uns zu kontaktieren, wenn Sie Fragen haben. Wir und {{resellerName}} freuen uns auf die Zusammenarbeit mit Ihnen!<br/><br/>Mit freundlichen Grüßen<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
      "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Software-Abonnement",
      "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Drittanbieterabonnements",
      "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
      "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
      "MICROSOFT_USERS_DELETE_CONFIRM":"Bestehende Lizenzen und der Zugriff auf das Portal werden entfernt. Sind Sie sicher, dass Sie fortfahren möchten?",
      "MicrosoftNonCSP":"Microsoft Nicht-CSP",
      "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Migrationskonto erfolgreich mit dem Benutzernamen erstellt:",
      "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Migrationskonten",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Kunde",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Migrationskonto",
      "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Erstellen",
      "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Migrationskonten",
      "MIGRATION_USER_CREATE_SUCCESS":"Migrationskonto erfolgreich mit dem {{username}} erstellt",
      "MINUTES":"Minute(n)",
      "MISSING_PLAN_OFFER_ADD_FAILED":"Die Abstimmung ist fehlgeschlagen. Wenden Sie sich an den Support, um Unterstützung zu erhalten",
      "MISSING_PLAN_OFFER_ADDED":"Die Abstimmung wurde mit dem ausgewählten Angebot abgeschlossen",
      "MNUAL_SYNC_PURCHASED_DATE":" Gekauftes Datum:",
      "MODAL_HEADER_TEXT_DESC_FILTER":"Filter",
      "Month(s)":"Monat(e)",
      "MULTIPLE_COMMENT_STATIC":"Bemerkungen",
      "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Wählen",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
      "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{ validationProcessedCount }}<br />Fehler: {{ validationErrorCount }}<br />Erfolg: {{ validationSuccessCount }}",
      "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Anleitung",
      "Name":"Name",
      "NAME":"Name des Unternehmens",
      "NCE_ACRONYM":"Online-Dienste NCE",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Stornierungsbedingungen",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 Stunden: Volle Rückerstattung",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 Stunden: 1. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 Stunden: 2. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 Stunden: 3. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 Stunden: 4. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 Stunden: 5. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 Stunden: 6. Tag anteilig",
      "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Nach 7 Kalendertagen: Eine Stornierung ist nicht zulässig",
      "NCE_INSTRUCTIONS_HEADING":"NCE-Anweisungen",
      "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Geschätzter Verkaufspreis des verknüpften Produkts",
      "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>Dieses folgende Produkt ist nicht für Werbeaktionen berechtigt. Bitte wenden Sie sich an den Support, um die Aktion zu beantragen. Wenn Sie dann keine Beförderung wünschen, fahren Sie mit Ihrem Prozessauftrag fort.</p>",
      "NCE_PROMOTIONS_APPLIED":"Diese Aktion wird angewendet",
      "NCE_PROMOTIONS_AVAILABLE_TEXT":"Aktion verfügbar",
      "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Überprüfen Sie die Förderberechtigung",
      "NCE_PROMOTIONS_NOT_APPLIED":"Die Aktion wird noch nicht angewendet",
      "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"Verfügbare Aktionen",
      "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Abrechnungshäufigkeit",
      "NCE_PROMOTIONS_POPUP_CANCEL":"Stornieren",
      "NCE_PROMOTIONS_POPUP_CLOSE":"Schließen",
      "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"Die Berechtigung des Kunden wird bei der Überprüfung des Einkaufswagens validiert",
      "NCE_PROMOTIONS_POPUP_DISCOUNT":"Rabatt",
      "NCE_PROMOTIONS_POPUP_END_DATE":"Endtermin",
      "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Aktionsrabatt zum Start des neuen Handels",
      "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Aktionsdetails",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":"ICH WÜRDE:",
      "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":"Name:",
      "NCE_PROMOTIONS_POPUP_SUBMIT":"Anwenden",
      "NCE_PROMOTIONS_POPUP_TERM":"Begriff",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"Anzahl/Plätze können jederzeit erhöht werden.",
      "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"Die Anzahl/Plätze können innerhalb von 7 Kalendertagen auf die vorherige oder die Summe der vorherigen Anzahl/Plätze reduziert werden.",
      "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Mengenänderung",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Planen Sie eine Verlängerungsänderung",
      "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Der aktuelle Zeitplan wird gelöscht, wenn Menge/Status der automatischen Verlängerung/Abrechnungszeitraum/Laufzeit geändert wird.",
      "NCE_SKU_NOT_PRESENT_IN_PLAN":"Produkt nicht im Kundenplan verfügbar",
      "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Bitte stimmen Sie allen Bedingungen zu",
      "NCE_TEXT":"Neuer Handel ...",
      "NEW_BILLING_CYCLE":"Neuer Abrechnungszeitraum",
      "NEW_COMMENT_SEND":"Senden",
      "NEW_COMMENTS_RECIEVED":"Neuer Kommentar erhalten",
      "NEW_CUSTOMER_ONBOARDING_ERRORS":"Kauf-/Aktualisierungsprodukte können fehlschlagen",
      "NEW_DISTRIBUTOR_PRODUCT_ICON":"Neues Symbol",
      "NEW_PARTNER_PRODUCT_ICON":"Neues Symbol",
      "NewPrice":"NeuPreis",
      "NEXT_SCHEDULE_RENEWAL_CHANGE":"Nächste Änderung des Fahrplans",
      "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Suche...",
      "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Es ist kein Artikel mit der ID: {cartLineItemId} im Warenkorb vorhanden",
      "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"Für diesen Mandanten ist kein Kundenadministrator verfügbar.",
      "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Keine Kunden registriert",
      "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Kein bestehender Plan",
      "NO_PAYMENT_TYPES_ENABLED":"Es sind keine Zahlungsarten aktiviert. Bitte wenden Sie sich an den Support.",
      "NO_PLAN_WITH_OFFER_AVAILABLE":"Es gibt keinen Plan mit allen verfügbaren Angeboten. Bitte erstellen Sie eines",
      "NO_PRODUCT_SELECTED_PROMPT":"Bitte wählen Sie mindestens ein Produkt aus.",
      "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Für den ausgewählten Abrechnungszeitraum oder Anbieter wurden keine Rechnungsdetails gefunden",
      "NO_RESOURCE_GROUP_LEFT":"Keine Ressourcengruppe mehr zuzuweisen/Keine Datensätze gefunden!",
      "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Nicht CSP Produktdetails",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Service Principal Id",
      "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Service-Hauptschlüssel",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Abonnement-ID",
      "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Abonnementname",
      "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"Reseller-Benutzer können im Reseller-Plan kein Partnerprodukt hinzufügen.",
      "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Folgende Anbieterprodukte werden nicht mit Planprodukten verknüpft. Wenn Sie fortfahren möchten, klicken Sie auf Weiter. Andernfalls klicken Sie auf Abbrechen und ordnen Sie diese Produkte zu",
      "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Anbieterprodukte",
      "NOT_VALID_MESSAGE":"ist keine gültige E-Mail",
      "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Bannerbenachrichtigung erstellen",
      "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"Kundentag erfolgreich gelöscht",
      "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"Der Abonnementname wurde erfolgreich geändert",
      "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Dieser Wert wird in Kürze aktualisiert",
      "notifications":"Benachrichtigung",
      "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"Die Zeichenlänge muss zwischen 2 und 200 Zeichen liegen",
      "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"Die Zeichenlänge muss zwischen 2 und 50 Zeichen liegen",
      "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Benutzerdefiniertes Enddatum erfolgreich gespeichert",
      "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"Ungültige Webhook-URL",
      "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"Gültige Webhook-URL",
      "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Geben Sie eine E-Mail-Adresse ein und klicken Sie auf OK, um eine Zip-Datei mit einer Liste der PDF-Rechnungen für den ausgewählten Abrechnungszeitraum zu erhalten",
      "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"Eine E-Mail mit einer Liste von Rechnungs-PDFs (Zip) als Anhang wird in Kürze an {{emailAddress}} gesendet.",
      "NOVEMBER":"November",
      "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
      "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
      "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
      "NUMBER_OF_PROMOTIONS_LOADED":"Anzahl der geladenen Angebote",
      "OCTOBER":"Oktober",
      "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Angebot Freundlicher Name {{FriendlyName}} muss eindeutig sein",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Angebote mit erworbenem Abonnement anzeigen",
      "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"offerWithPurchasedSubscription",
      "offers":"Bietet an",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
      "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Abonnements hinzufügen",
      "OFFERS_HEADER_TEXT_OFFERS":"Bietet an",
      "OFFERS_NO_MATCH_MESSAGE":"Keine passenden Ergebnisse für Name gefunden:",
      "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Ausgewählte Elemente",
      "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Rabattprozentsatz",
      "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Menge (Anzahl der Lizenzen)",
      "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Preis pro Sitzplatz (pro Monat)",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Gesamt",
      "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Gesamtmenge",
      "OLD_BILLING_CYCLE":"Alter Abrechnungszeitraum",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Das Onboarding eines Kunden unter Bulk Onboard ist fehlgeschlagen",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Onboarding eines Kunden unter Bulk Onboard initiiert",
      "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Onboarding eines Kunden unter großem Erfolg an Bord",
      "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Es wurde eine Übereinstimmung für die ID <strong>{{billingProviderReferenceID}}</strong> im Abrechnungssystem gefunden.<br/><span> Die Abrechnungsadresse in der Datei lautet <strong> {{formattedAddress}} </strong>.</span><br/> Bitte geben Sie sich aus und verwenden Sie die Option <strong>Profil</strong>, um dieses Detail zu ändern, nachdem die Rechnungsdetails des Kunden erfolgreich eingebunden wurden.",
      "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie auch {{secondProduct}} zuordnen.",
      "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Wenn {{firstProduct}} gemappt werden muss, sollten Sie {{secondProduct}} nicht ignorieren",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie {{secondProduct}} einschließen",
      "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Wenn {{firstProduct}} gemappt werden muss, sollten Sie auch {{secondProduct}} mappen, d.h.; Mindestmenge sollte 1 sein.",
      "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"Anbietermenge und freigegebene Menge sollten für {{product}} übereinstimmen",
      "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"Menge sollte für {{firstProduct}} und {{secondProduct}} gleich sein",
      "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Wählen Sie CSPCustomer to OnBoard",
      "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Hauptkontodetails",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":"Ausfüllen",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Laden Sie eine CSV-Vorlage herunter",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":"durch Hinzufügen von Zeilen nach der Kopfzeile. Jede Zeile sollte dem Detail eines Wiederverkäufers entsprechen.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"Die Bearbeitung würde eine Weile dauern und der Status wird auf dieser Seite gemeldet. Wenn Sie sich von der Seite entfernen oder den Browser versehentlich geschlossen haben, könnten Sie wieder auf diese Seite zurückkehren und das System würde den Status Ihres vorherigen Uploads anzeigen.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Klicken Sie auf Auswählen und navigieren Sie zu der Datei, die Sie hochladen möchten.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Die Eingabe wird validiert, um sicherzustellen, dass die folgenden Regeln erfüllt sind:",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Der Reseller-Name ist erforderlich und sollte nicht länger als 50 Zeichen sein.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Die Reseller-ID ist erforderlich und sollte nicht länger als 50 Zeichen sein. Dies sollte die Beziehungs-GUID des Handelspartners von Partner Center sein.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Die Reseller-MPN-ID ist erforderlich und sollte nicht länger als 50 Zeichen sein. Dies sollte die Microsoft Partner Network-ID des Wiederverkäufers sein.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Der Standort ist erforderlich und sollte der aus zwei Buchstaben bestehende Ländercode des Wiederverkäufers sein. Beispiel: USA, Großbritannien usw.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"E-Mail ist optional, sollte aber eine gültige E-Mail-Adresse sein, falls eine eingegeben wird.",
      "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Klicken Sie auf <strong class=\"text-uppercase\">Senden</strong>, damit die Daten verarbeitet werden.",
      "ONBOARDING":"Onboarding",
      "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Bericht herunterladen",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Wählen Sie den Kundentyp",
      "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Dauereinheit auswählen",
      "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Schließen",
      "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Wiederverkäufer",
      "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Reseller auswählen",
      "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Zurücksetzen",
      "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Suche",
      "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"Kundentyp",
      "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Einheit der Dauer",
      "ONBOARDING_DURATION_UNIT_MONTH":"Monat",
      "ONBOARDING_DURATION_UNIT_WEEK":"Woche",
      "ONBOARDING_DURATION_UNIT_YEAR":"Jahr",
      "ONBOARDING_GRAPH_COUNT_TEXT":" Zählen:",
      "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Das Onboarding von Kunden ist nicht zulässig, da keine Verbindung zum Provider Reseller besteht. Bitte wenden Sie sich an Ihren Partner.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"Das Onboarding eines MicrosoftNonCSP-Kunden wird nicht unterstützt. Bitte verknüpfen Sie den Kunden mit einem bestehenden C3-Kunden.",
      "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"Das Onboarding eines MicrosoftNonCSP-Kunden wird nicht unterstützt!",
      "ONBOARDING_STATUS":"Onboarding-Status",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Zuordnung der Kundenprodukte des Anbieters zu den Planprodukten...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Ladepläne...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Laden der Provider-Kunden...",
      "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Nachschlagen der Kundendaten in c3...",
      "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Alle Kunden",
      "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Direktkunden",
      "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Reseller-Kunden",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Sind Sie sicher, dass Sie löschen möchten?",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Wählen Sie ein Produkt in der Liste aus oder suchen Sie nach Produktnamen",
      "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"Die Anforderung, Lizenzen von Benutzern zu entfernen, wird akzeptiert und verarbeitet.",
      "OnlineServicesNCE":"Online-Dienst NCE",
      "Open":"Öffnen",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
      "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Plan Name",
      "OPERATING_ENTITY_CUSTOMER":"Kunde",
      "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Einrichtung der Organisation",
      "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Standorte & Abteilungen",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Freisetzung",
      "ORDER_BACK_TO_ORDER_DETAILS":"Zurück zu den Produkten",
      "ORDER_DETAILS_ENTITY_NAME":"Entitätsname",
      "ORDER_DETAILS_FIELD_ORDERED_ON":"Bestellt",
      "ORDER_DETAILS_SCHEDULED_DATE":"Geplantes Datum",
      "ORDER_ID":"Auftragsnummer",
      "ORDER_LBL_TEXT_PROMOTIONID":"Angebots-ID",
      "ORDER_LIST_STATUS_SUCCESS":"Erfolg",
      "ORDER_STATUS_APPROVAL_ACCEPTED":"Zur Kundenbewertung zugelassen",
      "ORDER_STATUS_APPROVAL_REQUIRED":"Genehmigung erforderlich",
      "ORDER_STATUS_COMMENTS":"Kommentare ansehen",
      "ORDER_STATUS_DELETED":"Gelöscht",
      "ORDER_STATUS_DESC_ACCEPTED":"Akzeptiert",
      "ORDER_STATUS_DESC_FAILED":"Gescheitert",
      "ORDER_STATUS_DESC_IGNORE":"Ignorieren",
      "ORDER_STATUS_DESC_INPROGRESS":"In Bearbeitung",
      "ORDER_STATUS_DESC_ONHOLD":"In Wartestellung",
      "ORDER_STATUS_DESC_ORDERED":"Bestellt",
      "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Teilweise erfüllt",
      "ORDER_STATUS_DESC_PROCESSING":"wird bearbeitet",
      "ORDER_STATUS_DESC_PROVISIONING":"Bereitstellung",
      "ORDER_STATUS_DESC_REJECTED":"Abgelehnt",
      "ORDER_STATUS_DESC_SCHEDULED":"Geplant",
      "ORDER_STATUS_DESC_SUCCESS":"Erfolg",
      "ORDER_STATUS_ERRORS_IGNORED":"Fehlgeschlagen <br/> (Fehler überprüft und ignoriert)",
      "ORDER_STATUS_FAILED":"Gescheitert",
      "ORDER_STATUS_INPROGRESS":"Arbeiten daran",
      "ORDER_STATUS_ONHOLD":"In Wartestellung",
      "ORDER_STATUS_ORDERED":"Bestellt",
      "ORDER_STATUS_PROCESSING":"Arbeiten daran",
      "ORDER_STATUS_PROVISIONING":"Arbeiten daran",
      "ORDER_STATUS_REACTIVATED":"Reaktiviert",
      "ORDER_STATUS_REJECTED":"Abgelehnt",
      "ORDER_STATUS_SCHEDULED":"Geplant",
      "ORDER_STATUS_SUCCESS":"Bestellung erfüllt",
      "ORDER_STATUS_SUSPENDED":"Suspendiert",
      "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Angebot genehmigt und Bestellung erfolgreich aufgegeben.<br/> Bestell-ID ist <b>{{OrderNumber}}</b>",
      "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Angebot erfolgreich genehmigt.",
      "OWNER_ENTITY_NAME":"Name der Eigentümereinheit",
      "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Details zum Zahlungsstatus",
      "PaidAmount":"Bezahlter Betrag",
      "PARENT_ENTITY_NAME":"Name der übergeordneten Entität",
      "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"Das Add-On kann nicht aktiviert werden, wenn das übergeordnete Angebot nicht aktiv ist.",
      "PARENT_PRODUCT_NOT_EXISTS":"Ohne Eltern kann kein Produkt an Bord gehen!",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Für diesen Anbieter sind keine Schlüssel verfügbar",
      "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Es sind keine Anbieter konfiguriert. Bitte wenden Sie sich an den Support",
      "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Speichern Sie Ihre Änderungen",
      "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Währung anzeigen",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Rechnungswährung",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makrowert",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Name",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Kaufwährung",
      "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Wählen",
      "partner":"Partner",
      "Partner":"Partner",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Zuordnung aufheben",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Kundenname",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Zurück zu den Account Managern",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Kunden von Account Manager",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Account-Manager Kunden <span class='text-primary'> {{FirstName}} </span>",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Aktionen",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Name",
      "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"C3Id",
      "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Verkäufer Name",
      "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Aktualisieren Sie alle Pläne mit den neuesten Angeboten",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
      "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Es wurden keine Addons gefunden",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Plan löschen",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Planangebote überprüfen",
      "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Plan speichern",
      "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Für das Testangebot wurde kein übergeordnetes Angebot gefunden. Fügen Sie das Elternangebot hinzu und versuchen Sie es erneut",
      "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Menge",
      "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Verwendung",
      "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Geben Sie das Gültigkeitsdatum ein von",
      "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Geben Sie das Gültigkeitsdatum bis ein",
      "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Nachricht eingeben",
      "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Nachrichtentyp auswählen",
      "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Seitenportal auswählen",
      "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Symbole / Kommunikation / Gruppenchat",
      "PARTNER_APPROVAL_PENDING":" Partnergenehmigung -",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"zum Bündel hinzugefügt",
      "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"hinzugefügt, um mit Add-Ons zu bündeln",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
      "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
      "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"ist bereits zum Bundle hinzugefügt und kann nicht erneut hinzugefügt werden",
      "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filter",
      "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Zurücksetzen",
      "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Suche",
      "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Gültigkeit",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Laufzeit",
      "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Laufzeit",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Abrechnungsarten",
      "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Selbstkostenpreis (ab)",
      "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Selbstkostenpreis (bis)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Verkaufspreis (ab)",
      "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Verkaufspreis (bis)",
      "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Plattendaten",
      "PARTNER_BUNDLE_LABEL_TEXT_TO":"- zu -",
      "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Gültigkeitsart",
      "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Gelöschte Pakete einbeziehen",
      "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Bitte wählen Sie mindestens ein Produkt aus.",
      "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Bitte wählen Sie mindestens 2 Angebote aus, die zusammengelegt werden sollen",
      "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Bitte Verkaufspreis angeben",
      "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
      "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Produkte bündeln",
      "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Bündel",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Hinzufügen",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Neues Bundle",
      "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
      "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Zurück zu den Bundle-Details",
      "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Stornieren",
      "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Löschen",
      "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Pakete herunterladen",
      "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Bearbeiten",
      "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Suche angewendet:",
      "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Nächster",
      "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Überprüfen Sie die Bundle-Produkte",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"speichern",
      "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Bundle speichern",
      "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Angebote anzeigen",
      "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Schnelle Suche",
      "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Zurück zu den Partnerpaketen",
      "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Bundle hinzufügen",
      "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Bundle anzeigen",
      "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
      "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Vorgeschlagener Preis:",
      "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
      "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
      "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Kategorie",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Ladezeitraum",
      "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Ladezeitraum",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
      "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Hilfstext",
      "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
      "PARTNER_BUNDLES_LABEL_TEXT_Description":"Beschreibung",
      "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Futterquelle",
      "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
      "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Name",
      "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Anzahl der Tage für eine kostenlose Stornierung",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
      "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
      "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Anbieter",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
      "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
      "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Gültigkeit",
      "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Suche nach Produkten",
      "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Menge",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Beschreibung",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Name",
      "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Angebotsdetails",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Aussicht",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Kategorien",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Anbieter",
      "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Servicetypen",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Kosten für den Partner",
      "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Verkaufspreis",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Mehrere Zahlungen",
      "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Mehrere Zahlungen",
      "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Zahlungen",
      "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Bitte aktualisieren Sie den Umsatzbericht, indem Sie auf die Schaltfläche „Neu laden“ klicken, bevor Sie fortfahren.",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Keine Geschichte gefunden!",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Suche angewendet",
      "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Servicetypen",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "PARTNER_COMMENTS_DETAILS":"Bemerkungen",
      "PARTNER_COMMENTS_TAB_CART_ITEM":"Warenkorb Artikel",
      "PARTNER_COMMENTS_TAB_INVOICE":"Rechnung",
      "PARTNER_COMMENTS_TAB_PRODUCT":"Produkt",
      "PARTNER_COMMISIONS":"Partnerprovisionen",
      "PARTNER_CONSENT_HELP_TEXT":"Kopieren Sie den Link, indem Sie auf die obige Schaltfläche klicken, das Inkognito-Fenster neu öffnen und den Zustimmungsablauf abschließen.",
      "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Bitte wählen Sie Abrechnungstyp für die Platte",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Hinzufügen",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Löschen",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"speichern",
      "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Bitte wählen Sie die Anwendbarkeit für das Angebot aus",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Email",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Name",
      "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefonnummer",
      "PARTNER_CUSTOMER_FILTER_NAME":"Name",
      "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} ist keinem Account Manager zugeordnet",
      "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Es sind keine Kunden mit einem Anbietermieter verbunden.",
      "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"Durch die Zuweisung dieses Plans werden die Angebote mit den im Plan definierten Markups eingeschlossen. Möchten Sie den Planstatus wirklich aktualisieren?",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Zuordnen",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Deaktivieren",
      "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Aktivieren",
      "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"Durch das Deaktivieren wird dieser Plan einschließlich seiner Angebote entfernt. Vorhandene gekaufte Produkte haben das gleiche Markup dieses Plans. Möchten Sie den Planstatus wirklich aktualisieren?",
      "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"Durch die Aktivierung wird dieser Plan einschließlich der Angebote mit im Plan definierten Markups zugewiesen. Möchten Sie den Planstatus wirklich aktualisieren?",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Zurück zu den Kundenplänen",
      "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
      "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Plan ändern",
      "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Pläne verwalten für:",
      "PARTNER_CUSTOMER_REPORTS":"Berichte",
      "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"Kunden-Tag erfolgreich hinzugefügt",
      "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"Kunden-Tag erfolgreich aktualisiert",
      "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Möchten Sie den Planstatus wirklich aktualisieren?",
      "PARTNER_CUSTOMERS_TEXT_INFO":"Leider wurden keine Kunden gefunden",
      "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Accounts erhaltbar",
      "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Vergangene Gebühren",
      "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilität",
      "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"Top 10 Kunden",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Ihre aktiven Kunden",
      "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Kunden",
      "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Kundensitze",
      "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Direkte Kundenplätze",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Abonnements von Direktkunden",
      "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Kundenabonnements",
      "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"Reseller-Rechnungsdetails wurden für resellerId nicht gefunden: {{resellerId}}, billingPeriodId: {{billingPeriodId}}",
      "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Rechnung generiert am",
      "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Reingewinn",
      "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Zusatzgebühren",
      "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Gewinn",
      "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Abgerechneter Betrag",
      "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"MSFT-Rechnung",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Schneller Blick auf die Rentabilität Ihres CSP",
      "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilität",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Zusammenfassung Ihrer Forderungen",
      "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Accounts erhaltbar",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Zahlungen müssen noch eingezogen werden",
      "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Vergangene Gebühren",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"steht aus",
      "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Bitte versuchen Sie es erneut, nachdem die Rechnungen fertig sind",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 SKUs",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Produkt zählt nach Top-10-SKUs",
      "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Zählt nach SKU",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Filterbereich",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 Monate",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 Monate",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 Jahr",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Kauf von Sitzplätzen",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Trend zum Kauf von Plätzen über Monate hinweg",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Kauf von Sitzplätzen",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Sitze gekauft",
      "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sitze gekauft",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Ihre aktiven Wiederverkäufer",
      "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Wiederverkäufer",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Nach Kategorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Einnahmen vs. Kosten",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Ein Diagramm zur Anzeige von Umsatz- und Kostendetails nach Kategorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Umsatz vs. Kosten nach Kategorie",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Kosten",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Einnahmen",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Anbieter auswählen",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Einnahmen vs. Kosten",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Einnahmen versus Kosten",
      "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Einnahmen versus Kosten",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Kunden",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Sitze",
      "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonnements",
      "PARTNER_DASHBOARD_TILE_INVOICES":"Rechnungen",
      "PARTNER_DASHBOARD_TILE_RESELLERS":"Wiederverkäufer",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Nach Verlängerungsdatum",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Einnahmen",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Top 5 Produkte",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Nach Einnahmen",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Top 5 Produkte nach Umsatz",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Top 5 Produkte nach Umsatz",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Sitze",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Top 5 Produkte",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Nach gekauften Plätzen",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Top 5 Produkte nach gekauften Plätzen",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Top 5 Produkte nach gekauften Plätzen",
      "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Bevorstehende Erneuerungen",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Einnahmen",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"Top 10 Kunden",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Nach Einnahmen",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Top 10 Kunden nach Umsatz",
      "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Top 10 Kunden nach Umsatz",
      "PARTNER_LOGOS_LABEL_TEXT":"Wählen Sie Logo",
      "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Vorschaubild",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Entfernen",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Kategorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Entfernen",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Add-on löschen",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Entfernen",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Abrechnungsart",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Kategorie",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Rabatt",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Name",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
      "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
      "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Angebot entfernen",
      "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Partnerangebot hinzufügen",
      "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Geben Sie die Menge ein, die Sie kaufen möchten.",
      "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Vertragsangebot bearbeiten",
      "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Partnerangebot bearbeiten",
      "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Geben Sie einen positiven Wert ein",
      "PARTNER_OFFER_HYPERLINK_READ_LESS":"Lese weniger",
      "PARTNER_OFFER_HYPERLINK_READ_MORE":"...Mehr lesen",
      "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Aktivieren Sie das Testangebot, indem Sie dieses Kontrollkästchen aktivieren",
      "PARTNER_OFFER_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
      "PARTNER_OFFER_SLAB_DATA":"Plattendaten",
      "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":"Dauer der Testzeit",
      "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Alle Kunden",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Aktionen",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Email",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste der Partnerangebote zu erhalten.",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Bericht über Partnerangebote",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Bericht über Partnerangebote zu erhalten",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf „Senden“, um einen Bericht zu erhalten",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Stornieren",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"E-Mail senden",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
      "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"Mutterprodukt ist nicht zugelassen!",
      "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"Kinderprodukte werden nicht abgelehnt!",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Alle Artikel im Einkaufswagen werden genehmigt, unabhängig von den Schwellenwertverletzungen bei jedem Einzelposten. Möchten Sie den Warenkorb wirklich genehmigen?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"Möchten Sie das Element wirklich genehmigen?",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Warenkorb-Werbebuchung erfolgreich genehmigt",
      "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Der Einkaufswagen wurde erfolgreich genehmigt",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Genehmigen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Genehmigen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Anfrage genehmigen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Zurück zu Anfragen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Kategorien",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Servicetypen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Anbieter",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Status",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"Bestellt am",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Stückpreis",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"Ablehnen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Ablehnen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Anfrage ablehnen",
      "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Änderungen einreichen",
      "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Bitte stellen Sie sicher, dass Sie mindestens ein Produkt genehmigen oder ablehnen, bevor Sie es einreichen",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"Möchten Sie wirklich den gesamten Einkaufswagen ablehnen?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"Möchten Sie den Artikel wirklich ablehnen?",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Die Einkaufswagen-Werbebuchung wurde erfolgreich abgelehnt",
      "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Der Einkaufswagen wurde erfolgreich abgelehnt",
      "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Der Status wurde erfolgreich aktualisiert",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Zulassungen",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Die folgenden Kaufanfragen von Kunden warten auf Ihre Genehmigung.",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Zulassungen für",
      "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Kaufanfragen",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ausgewählte Abrechnungszeiträume",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Ausgewählte Kategorien",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Ausgewählte Anbieterkategorien",
      "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Ausgewählte Anbieter",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Werbeaktion auf alle Angebote anwenden",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategorien",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Makrotyp auswählen",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Sitzplatzbegrenzung planen:",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Anbieterkategorien",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Anbieter",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Sitzplatz-Limit",
      "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Servicetypen",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Klonplan erfolgreich abgeschlossen",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Die Anforderung zum Klonen des Plans wird akzeptiert und bearbeitet",
      "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Die Anforderung, einen neuen Plan mit allen Angeboten zu erstellen, wird angenommen und bearbeitet",
      "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Makrowert",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"Kann der Verkaufspreis unter dem <br/> Partnerpreis liegen?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Der Verkaufspreis darf niedriger sein als der Partnerpreis",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"Kann der Verkaufspreis den Verkaufspreis des Anbieters übersteigen?",
      "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der Verkaufspreis den Verkaufspreis des Anbieters übersteigt.",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Plan in neuer Währung definieren",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Name",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Währungscode planen",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Produktname",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Quellwährung",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Zielwährung",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Zielwährungscode",
      "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Es gibt keine Zielwährung, bitte kontaktieren Sie den Partner, um dies zu definieren.",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"Wie man?",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Legen Sie das Limit auf -1 fest, um eine Genehmigung für alle Käufe/Aktualisierungslizenzen zu erfordern",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Verwenden Sie diesen Bildschirm, um den Schwellenwert nach Sitzanzahl zu konfigurieren",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Legen Sie das Limit auf 0 fest, um den Kauf unbegrenzter Plätze zu ermöglichen",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Legen Sie eine positive Zahl ungleich Null fest, um den Schwellenwert zu definieren",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Jeder Kauf, der den Schwellenwert überschreitet, muss vom Partner genehmigt werden",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Klicken Sie auf die Schaltfläche „Senden“, um die Änderungen zu speichern<b>",
      "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Platzlimits für {{PlanName}} konfigurieren",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makro definieren und mit allen Angeboten speichern",
      "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Fehlende Angebote ergänzen",
      "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Stornieren",
      "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klonplan",
      "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Plan mit allen Angeboten speichern",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Klonplan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Klonplan",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Plan erstellen",
      "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"Der Plan ist in Bearbeitung. Klicken Sie hier, um den Status anzuzeigen",
      "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Sitzplatzbeschränkungen",
      "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
      "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
      "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
      "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Produktkatalog",
      "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Produktkatalog",
      "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Produktkatalog",
      "PARTNER_PRODUCT_CATEGORYID":"Kategorie ID",
      "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
      "PARTNER_PRODUCT_DESCRIPTION":"Beschreibung",
      "PARTNER_PRODUCT_NAME":"Name",
      "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
      "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
      "PARTNER_PRODUCT_PRICEFORPARTNER":"Preis für Partner",
      "PARTNER_PRODUCT_ProviderId":"ProviderId",
      "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
      "PARTNER_PRODUCT_SALETYPE":"SaleType",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Kosten für den Kunden",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Währung",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Kundennummer",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Kundenname",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Beschreibung",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Produkt-ID",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Produktname",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Maßeinheit",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Einheiten",
      "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Verwendungsdatum",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie diese Anfrage wirklich annehmen?",
      "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Möchten Sie diese Anfrage wirklich ablehnen?",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Zurück zum Abonnementverlauf",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Neues PSA-Produkt",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Freisetzung",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Zuordnung von Freigabevereinbarungen",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Vertragszuordnung freigeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Kundenzuordnung freigeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Neues Produkt erstellen",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Dieses Feld wird benötigt.",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Kategorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Kosten",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Kundenbeschreibung",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Beschreibung",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Dropshipping",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Kennung",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Integrationsquerverweis",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Anmerkungen",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Preis",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Produktklasse",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"SLA",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Sonderbestellung",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Unterkategorie",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Mehrwertsteuer",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Produkttyp",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Maßeinheit (UOM)",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Kosten eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Geben Sie die Kundenbeschreibung ein",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Kennung eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Notizen eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Preis eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Produktbeschreibung eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Integrationsquerverweis eingeben",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Produkttyp auswählen",
      "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Neues PSA-Produkt erfolgreich erstellt",
      "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
      "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "PARTNER_RESELLER_FILTER_NAME":"Name",
      "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{reseller}} ist keinem Buchhalter zugeordnet",
      "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Speichern Sie Ihre Änderungen",
      "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Name",
      "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Wählen",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"Kann der neue Partnerpreis niedriger sein als der<br/>Partnerpreis",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der neue Partnerpreis niedriger ist als der Partnerpreis",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"Kann der Partnerpreis den Verkaufspreis des Anbieters überschreiten?",
      "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der Partnerpreis den Verkaufspreis des Anbieters übersteigt.",
      "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Bericht hinzufügen",
      "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"Möchten Sie dies wirklich löschen?",
      "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aktionen",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Empfänger hinzufügen",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Zurück zu den Berichten",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Zurück zum Empfänger",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Stornieren",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Löschen",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Bearbeiten",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Email",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Empfängertyp",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Empfänger",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"speichern",
      "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"Zu",
      "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Versandart",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Empfänger",
      "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Empfängertyp",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Empfänger",
      "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Ausführungsverlauf",
      "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aktionen",
      "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Letzter Lauf am (Ortszeit)",
      "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Berichte",
      "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Geplant am",
      "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Datum",
      "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Einzelheiten",
      "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Status",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für den {{taxType}} ein",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Bitte geben Sie den gültigen Steuerprozentsatz ein",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Bitte füllen Sie die Felder Steuername aus",
      "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Sie haben nicht gespeicherte Untersteuern, möchten fortfahren?",
      "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung",
      "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Wählen Sie eine Ansicht",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"Partner-Tag erfolgreich gelöscht",
      "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"Partner-Tag erfolgreich aktualisiert",
      "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Stile",
      "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Vorlagen",
      "PARTNER_STATUS":"Partnerstatus",
      "PARTNER_TABLE_HEADER_NAME":"Name",
      "PARTNER_TABLE_HEADER_VALUE":"Wert",
      "PARTNER_TERMS_OF_USE":"Nutzungsbedingungen des Partners",
      "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Probetage",
      "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Elternangebote im Zusammenhang mit dem Testangebot",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Kosten für den Kunden",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Währung",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Beschreibung",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"einreichen",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Monat",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Gesamtkosten",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Verwendungsdatum",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Maßeinheit",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Einheiten",
      "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Verwendungsdatum",
      "PARTNER_USER_ALREADY_EXECUTE":"Mit dieser E-Mail existiert bereits ein Partnerbenutzer.",
      "PARTNER_USER_REMOVED":"Partnerbenutzer erfolgreich entfernt",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Löschen",
      "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Tag-Schlüssel",
      "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Tag-Werte",
      "PARTNER_USERS_ADD_SUCCESS":"Gewährt erfolgreich Zugriff auf {{username}}",
      "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Ein Benutzer mit der E-Mail {{useremail}} ist bereits im System vorhanden",
      "PARTNER_USERS_REMOVE_SUCCESS":"Der Partnerbenutzer hat {{username}} erfolgreich entfernt",
      "PARTNER_USERS_SAVE_SUCCESS":"Der Partnerbenutzer hat {{username}} erfolgreich aktualisiert",
      "PARTNER_USERS_SELECT_ROLE_PROMPT":"Bitte weisen Sie eine Rolle zu, um fortzufahren",
      "partnerauditlog":"Audit-Log",
      "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Partnerbenutzer erfolgreich aktualisiert",
      "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Zurück zu Partnerbenutzern",
      "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Hinzufügen",
      "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Bearbeiten",
      "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Partnerbenutzer",
      "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Email",
      "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Tag verwalten",
      "PARTNERS_LABLE_TEXT_TAG_KEY":"Tag-Schlüssel",
      "PARTNERS_LABLE_TEXT_TAG_VALUES":"Tag-Werte",
      "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Das Passwort sollte mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
      "PAYMENT_LABEL_FILTER":"Filtern nach",
      "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Zahlungsprofil Auf Standard setzen",
      "PAYMENT_PROFILE_DELETE_SUCCESS":"Zahlungsprofil gelöscht",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Name des Karteninhabers",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Kartennummer",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Ablaufmonat",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Ablaufjahr",
      "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Sicherheitscode",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Name auf der Kreditkarte",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Kreditkartennummer",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Zweistellige Monatszahl",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Vierstellige Jahreszahl",
      "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Dreistellige CVV-Nummer auf der Kreditkarte",
      "PAYMENT_PROFILE_SAVE_FAILURE":"Speichern der Zahlungsdetails fehlgeschlagen",
      "PAYMENT_PROFILE_SAVE_SUCCESS":"Zahlungsdetails gespeichert",
      "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Die Zahlungsdetails werden in Kürze aktualisiert. Bitte melden Sie sich NICHT ZURÜCK und schauen Sie nach einiger Zeit wieder vorbei.",
      "PAYMENT_PROFILE_TEXT_FAILURE":"Fehler",
      "PAYMENT_PROFILE_TEXT_SUCCESS":"Erfolg",
      "PAYMENT_STATUS_DESC_ACCEPTED":"Akzeptiert",
      "PAYMENT_STATUS_DESC_CHARGED":"Berechnet",
      "PAYMENT_STATUS_DESC_DECLINED":"Abgelehnt",
      "PAYMENT_STATUS_DESC_FAILED":"Gescheitert",
      "PAYMENT_STATUS_DESC_FINALIZED":"Aktueller Monat ausstehend",
      "PAYMENT_STATUS_DESC_INPROGRESS":"In Bearbeitung",
      "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Teilweise bezahlt",
      "PAYMENT_STATUS_DESC_SUCCESS":"Erfolgreich",
      "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Technischer Fehler",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Stornieren",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"einreichen",
      "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Aktualisieren",
      "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Dieses Feld wird benötigt.",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Kontonummer",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Pseudonym",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"Ist Geschäftskonto?",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Unternehmen",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individuell",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Nein",
      "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Ja",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Überprüfung",
      "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Monat ablaufen",
      "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Ablaufjahr",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
      "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
      "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
      "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"speichern",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
      "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
      "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Bitte warten Sie, während die Seite geladen wird",
      "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Abgerechneter Betrag",
      "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
      "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Bezahlte Menge",
      "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Zahlungsstatus",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Grund für das Scheitern",
      "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Bemerkungen",
      "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
      "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Bezahlt von",
      "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Fehlertransaktionen",
      "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Ausstehende Zahlungen",
      "PAYMENTS_STATUS_DESC_FAILED":"Gescheitert",
      "PAYMENTS_STATUS_DESC_SUCCESS":"Erfolg",
      "PAYMENTS_TABLE_HEADER_AMONT":"Bezahlte Menge",
      "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Abgerechneter Betrag",
      "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Abrechnung per E-Mail",
      "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Fehlergrund",
      "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Abrechnungszeitraum",
      "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Fälliger Status",
      "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Ist rechnungsbasiert?",
      "PAYMENTS_TABLE_HEADER_OWNED_BY":"Gehört",
      "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Bezahlte Menge",
      "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Bezahlbarer Betrag",
      "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Zahlungs-ID",
      "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Bezahlverfahren",
      "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Ausstehende Summe",
      "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Ausstehende Summe",
      "PAYMENTS_TABLE_HEADER_REMARKS":"Bemerkungen",
      "PAYMENTS_TABLE_HEADER_STATUS":"Status",
      "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Teilzahlung",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
      "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"Transaktions-ID",
      "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Sie haben Ihr 7-Tage-Fenster für die Reduzierung von Plätzen für dieses Abonnement überschritten.",
      "PC_ACTIVATION_ERROR":"Ein Kind-Abonnement kann nicht aktiviert werden, wenn das Eltern-Abonnement nicht aktiv ist",
      "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"Das Addon ist nicht ohne ein kompatibles Basisprodukt erhältlich",
      "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":"Es wurden keine Beraterpartnerinformationen gefunden, um diese Anfrage zu vervollständigen.",
      "PC_ADVISOR_QUOTE_NOT_FOUND":"Beraterangebot nicht gefunden. Bitte überprüfen Sie die Informationen, bevor Sie es erneut versuchen.",
      "PC_ALREADY_ACTIVATE_ITEM":"Dieser Artikel wurde bereits aktiviert.",
      "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Ein Azure-Plan ist erforderlich, um ein SaaS-Abonnement mit einem Abrechnungstarif zu erwerben. Wenden Sie sich bitte an den Partner für weitere Informationen.",
      "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Diese API-Methode wird für Legacy-Abonnements nicht unterstützt",
      "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Die von der Werbebuchung referenzierte Verfügbarkeit wurde nicht gefunden",
      "PC_AVAILIBILTY_QUANTITY":"Die Verfügbarkeit umfasst mehr als eine Mengenoption. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Der Azure-Plan kann nicht in den angehaltenen Status verschoben werden, da Sie eine oder mehrere Azure-Abonnements im aktiven Status haben.",
      "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"Die angegebene Azure-Plan-ID wurde nicht gefunden oder es gibt keine aktiven Azure-Abonnements unter ihr. Ein Azure-Plan mit aktiver(n) Subscription(s) ist erforderlich, um ein SaaS-Produkt mit einem Abrechnungstarif zu erwerben",
      "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"Das Azure-Abonnement mit der angegebenen Berechtigungs-ID wurde nicht gefunden.",
      "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"Der Abrechnungszeitraum darf nicht länger sein als die Laufzeit.",
      "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"Der Abrechnungszeitraum für Abonnements stimmt nicht mit dem Abrechnungszeitraum der ursprünglichen Bestellung überein",
      "PC_BILLING_CYCLE_IS_INVALID":"Der Abrechnungszeitraum ist ungültig.",
      "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Dieser Antrag auf Übertragung des Eigentums an der Fakturierung kann nicht abgeschlossen werden, da Produkte von Drittanbietern nicht mit Abonnements übertragen werden können. Entfernen Sie die Marktplatzprodukte von Drittanbietern aus Ihrer Auswahl und versuchen Sie es erneut.",
      "PC_C3_CONNECTION_ERROR":"Fehler bei der Verbindung zum Partner Center, bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",
      "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"Der Abrechnungszeitraum kann nicht geändert werden, wenn das Abonnement keinem Mint-Konto zugeordnet ist",
      "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"Azure-Upgrade für gemeinsame Azure-Partnerdienste kann nicht verarbeitet werden",
      "PC_CANCEL_PURCHASE_NON_TENANT":"Nicht-Sandbox-Mieter können keine anderen Käufe als Software-Abonnements und unbefristete Software stornieren",
      "PC_CANCELLATION_NOT_ALLOWED":"Eine Stornierung ist derzeit nicht möglich!",
      "PC_CANNOT_CHECKOUT":"Ein bereits eingereichter Warenkorb kann nicht ausgecheckt werden!",
      "PC_CANNOT_PROCESS_AZURE_UPGRADE":"Azure-Upgrade kann nicht durchgeführt werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_CANNOT_PROCESS_REQUEST":"Die Transferanfrage für {1} Transfer {0} kann nicht bearbeitet werden.",
      "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Sie haben ein oder mehrere Azure-Abonnements im suspendierten Zustand. Diese können nicht auf den Azure-Plan übertragen werden.",
      "PC_CANNOT_UPDATE_BILLING_CYCLE":"Technische Probleme verhindern die Aktualisierung der Abonnements. Bitte kontaktieren Sie den Support.",
      "PC_CART_IN_PROCESS":"Der Warenkorb wird bereits bearbeitet, bitte warten Sie und schauen Sie nach einiger Zeit wieder vorbei.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":"Der Katalogartikel ist nicht zum Kauf berechtigt. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":"Der Katalogartikel ist nicht für das Hinzufügen oder Entfernen von Mengen geeignet. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
      "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"Katalogartikel erfordert eine Bestätigungsbestätigung.",
      "PC_CREATE_ORDER_ERROR":"Bestellung erstellen ist nicht erfolgreich",
      "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"Das Kundenkonto wird gerade überprüft. Transaktionen sind nicht erlaubt, bis das Konto genehmigt ist.",
      "PC_CUSTOMER_MIGRATION_FAILED":"Die Migration der Kundenlizenz ist fehlgeschlagen. Bitte kontaktieren Sie den Support.",
      "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Die Telefonnummer des Kunden fehlt. Dieser Fehler wird ausgelöst, wenn das Unternehmen/die Organisation des Kunden in den folgenden Ländern ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA), aber billingProfile.defaultAddress.phoneNumber nicht übergeben wird.",
      "PC_DISCONTINUED_OFFER":"Dieses Angebot steht derzeit nicht zum Kauf zur Verfügung, da es möglicherweise eingestellt worden ist. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
      "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Mindestens eine der Bestellpositionen unterstützt die angegebene Währung USD nicht. Die Eingabewährung sollte entweder Partner- oder Kunden-(Markt-)Währung sein.",
      "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"Das in den Anweisungen für die geplante nächste Laufzeit angegebene Enddatum der benutzerdefinierten Laufzeit muss innerhalb der ersten Laufzeit nach der Verlängerung liegen. Außerdem können Produkte mit monatlicher Laufzeit nicht mit einem Abonnement kombiniert werden, das am 28., 29. oder 30. des Monats endet, es sei denn, dieses Datum ist der letzte Tag des Monats.",
      "PC_GENERIC_ERROR":"Ein Fehler ist aufgetreten. Bitte kontaktieren Sie den Support für weitere Informationen oder versuchen Sie es erneut, nachdem Sie den Status der durchgeführten Operation überprüft haben.",
      "PC_INACTIVE_RESELLER_PROGRAM":"Der Status des Wiederverkäuferprogramms ist nicht aktiv. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_INCORRECT_CUSTOMER_ID":"Falsche Kunden-ID. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.",
      "PC_INITIATE_TRANSFER_REQUEST_FAILED":"Überweisungsanforderung fehlgeschlagen. Bitte versuchen Sie es nach einiger Zeit erneut.",
      "PC_INVALID_ADD_ON":"Das Add-on ist nicht mit dem angegebenen übergeordneten Abonnement verbunden",
      "PC_INVALID_AZURE_SUBSCRIPTION":"Dieses Abonnement ist kein gültiges Azure-Abonnement",
      "PC_INVALID_CUSTOMER_INFORMATION":"Die angegebenen Informationen stimmen nicht mit den aktuellen Kundeninformationen überein. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.",
      "PC_INVALID_DOMAIN":"Ungültige Domain. Bitte geben Sie die korrekte Domainadresse an und versuchen Sie es erneut.",
      "PC_INVALID_ETAG":"Etag ist nicht mehr gültig",
      "PC_INVALID_LINE_ITEM":"Die Positionsreihenfolge ist nicht richtig eingestellt, bitte kontaktieren Sie den Support.",
      "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Ungültige Metadaten. (Wenn der Kundenpreis, die Währung fehlt).",
      "PC_INVALID_MPN":"Ungültige MPN, bitte kontaktieren Sie den Support für weitere Untersuchungen.",
      "PC_INVALID_OPERATION":"Dieser Vorgang wird nicht unterstützt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID":"Der Wert der Organisationsregistrierungs-ID wird nicht unterstützt. Bitte überprüfen Sie den eingegebenen Wert, bevor Sie es erneut versuchen.",
      "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":"Der Wert oder das Format der Registrierungs-ID der Organisation wird nicht unterstützt. Überprüfen Sie bitte den eingegebenen Wert, bevor Sie es noch einmal versuchen.",
      "PC_INVALID_PROMOTION_CODE":"Der Aktionscode ist nicht gültig. Bitte überprüfen Sie dies und versuchen Sie es erneut.",
      "PC_INVALID_QTY_FOR_SUBSCRIPTION":"Die Abonnementmenge liegt nicht innerhalb der zulässigen Mindest- und Höchstmenge.",
      "PC_INVALID_REQUEST":"Ungültige Anfrage. Überprüfen Sie bitte die Attribute der Anfrage, bevor Sie es erneut versuchen.",
      "PC_INVALID_SUBSCRIPTION":"Kein gültiges Abonnement, bitte kontaktieren Sie den Support.",
      "PC_INVALID_SUBSCRIPTION_TRANSER":"Übertragung kann nicht mit ungültigem Abonnement erstellt werden. Bitte überprüfen Sie das Abonnement, bevor Sie es erneut versuchen.",
      "PC_ISSUE_ASSET_STATUS_CHANGE":"Asset-Status-Änderungsvorgang konnte nicht abgeschlossen werden. Wenden Sie sich bitte an den Partner für weitere Informationen.",
      "PC_ISSUE_OFFER_TYPE":"Der Kauf konnte aufgrund eines Problems mit dem Angebotstyp nicht abgeschlossen werden. Bitte kontaktieren Sie den Support.",
      "PC_ITEM_IS_NOT_AVAILABLE":"Dieser Artikel ist nicht verfügbar",
      "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":"Die angeforderte Anzahl hat die für das Angebot zulässige Restmenge pro Abonnement überschritten. Bitte passen Sie die Anzahl an, bevor Sie es erneut versuchen.",
      "PC_LIMIT_EXCEEDED":"Das Nutzungslimit für die Angebots-ID wurde überschritten.",
      "PC_LINE_ITEM_ISSUE":"Es liegt ein Problem mit der Position vor. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"Die Migration kann nicht durchgeführt werden, weil zwei oder mehr Add-On-Migrationen mit derselben aktuellen Abonnement-ID angegeben wurden",
      "PC_MISSING_ASSET_ID":"Der Vorgang konnte nicht abgeschlossen werden, weil eine Asset-ID fehlt. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_MISSING_AZURE_SUBSCRIPTION":"Das Azure-Upgrade kann nicht durchgeführt werden, da keine Azure-Abonnementinformationen gefunden wurden.",
      "PC_MISSING_MCA":"Bitte akzeptieren Sie MCA, bevor Sie mit dem Kauf fortfahren.",
      "PC_MISSING_REQUIRED_TAGS_IN OFFER":"Das Konto verfügt nicht über die erforderlichen Tags für das Angebot. Wenden Sie sich bitte an den Partner für weitere Informationen.",
      "PC_MISSING_TRANSFER_REQUEST":"Es wurde keine Übertragungsanfrage gefunden.",
      "PC_MISSING_UPGRADE_REQUEST":"Es wurde keine Upgrade-Anfrage gefunden.",
      "PC_MPN_NOT_FOUND":"MPN Id wurde nicht gefunden.",
      "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"Mehrere Überweisungsanfragen sind nicht erlaubt. Eine weitere Übertragungsanfrage ist in Bearbeitung.",
      "PC_NCE_MIGRATION_COULD_NOT_FOUND":"New-Commerce-Migration konnte nicht gefunden werden.",
      "PC_NO_AZURE SUBSCRIPTION":"Azure-Upgrade kann nicht durchgeführt werden, da der Kunde keine Azure-Abonnements hat.",
      "PC_NOT_ELIGIBLE_AUTO_RENEWED":"Das Abonnement kann nicht automatisch verlängert werden. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"Der Partner ist für diese Transaktion nicht berechtigt, bitte kontaktieren Sie den Support.",
      "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"Artikel wird von der Offers-API nicht unterstützt. Versuchen Sie, die APIs für Produkte und/oder SKUs zu verwenden",
      "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Das Angebot gilt nicht für das angegebene Land",
      "PC_OFFER_PURCHASE_ERROR":"Ungültige Angebots-ID",
      "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Ein oder mehrere Azure-Abonnements wurden kürzlich erworben. Diese Abonnements können derzeit nicht übertragen werden. Bitte versuchen Sie es später erneut.",
      "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":"Das Stornieren von platzbasiertem SaaS wird nicht über die Patch-Bestellung unterstützt. Verwenden Sie stattdessen das Patch-Abonnement",
      "PC_ORDER_CANCELLATION":"Die Bestellung kann nicht storniert werden. Bitte wenden Sie sich an den Support, um Hilfe zu erhalten.",
      "PC_ORDER_CANCELLATION_ERROR":"Die Bestellung kann nicht storniert werden.",
      "PC_ORDER_IN_PROGRESS":"Ihre Bestellung ist in Bearbeitung. Bitte prüfen Sie in wenigen Minuten Ihre Bestellhistorie auf neue Bestellungen.",
      "PC_ORDER_NOT_FOUND":"Bestellung nicht gefunden!",
      "PC_ORDER_VENDOR_POLICY":"Die Bestellung konnte aufgrund von Herstellerrichtlinien nicht aufgegeben werden. Bitte kontaktieren Sie den Support, um mehr zu erfahren.",
      "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Die Registrierungsdaten der Organisation fehlen. Dieser Fehler tritt auf, wenn die Firma/Organisation des Kunden in einem der folgenden Länder ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA)aber organizationRegistrationNumber wird nicht übergeben.",
      "PC_ORGANISATION_REGESTRATION_ID_MISSING":"Die Registrierungsnummer der Organisation ist nicht angegeben. Bitte geben Sie eine gültige Registrierungsnummer ein, bevor Sie es erneut versuchen.",
      "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"Der Wert der Registrierungsnummer der Organisation wird nicht unterstützt. Diese Fehlermeldung wird ausgegeben, wenn die Firma/Organisation des Kunden nicht in einem der folgenden Länder ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA), aber sie haben versucht, organizationRegistrationNumber anzugeben.",
      "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":"Die Angabe der Organisationsregistrierungsnummer ist ungültig. Bitte überprüfen Sie die Eingabe und versuchen Sie es erneut.",
      "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Diese Übertragung ist nicht zulässig, da die ursprüngliche Übertragung {0} im Gange ist.",
      "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"Überschuss ist bereits einem anderen Mieter zugewiesen. Wenden Sie sich an Ihren Kunden, um Eigentumsfragen zu klären",
      "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"Die Überschreitung kann nicht festgelegt werden, da der Kunde über Legacy-Azure-Abonnements verfügt.",
      "PC_PARTNER_ATTESTATION_MISSING":"Partnerbescheinigung fehlt",
      "PC_PARTNER_INFORMATION_MISSING":"Die Anfrage konnte nicht abgeschlossen werden, da die Partnerinformationen fehlen. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"Die Partnermandanten-ID darf noch keine Migrationen zu New Commerce für ihre Abonnements durchführen",
      "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"Der eingetragene Partner für dieses Abonnement kann nicht aktualisiert werden. Wenden Sie sich bitte an den Microsoft-Support, um Hilfe zu erhalten.",
      "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Für diese Bestellung wurde eine ausstehende Anpassung angefordert. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Es gibt einen ausstehenden Vorgang für dieses Abonnement. Bitte versuchen Sie es nach 24 Stunden erneut.",
      "PC_PHONE_NUMBER_MISSING":"Die Kundentelefonnummer fehlt. Bitte geben Sie die Kundentelefonnummer an, bevor Sie es erneut versuchen.",
      "PC_PRICING_INFORMATION_NOT_PERMITTED":"Der Zugriff auf Preisinformationen ist nicht erlaubt. Bitte kontaktieren Sie den Support.",
      "PC_PRODUCT_NOT_FOUND":"Produkt wurde nicht gefunden",
      "PC_PROPERT_NOT_SUPPORTED":"Diese Eigenschaft wird nicht unterstützt. Bitte überprüfen Sie dies und versuchen Sie es erneut.",
      "PC_PURCHASE_NOT_SUPPORTED":"Das Einkaufssystem wird nicht unterstützt. Wenden Sie sich bitte an den Partner für weitere Informationen.",
      "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Bestelldokument ist erforderlich.",
      "PC_PURCHASE_PRE_REQUISTIES_MISSING":"Eine oder mehrere Kaufvoraussetzungen sind nicht erfüllt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"Die Abonnementmenge kann nicht erhöht oder verringert werden, während das Abonnement ausgesetzt ist.",
      "PC_QUANTITY_CANNOT_DECREASED":"Die Abonnementmenge kann nicht verringert werden. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
      "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":"Die Menge ist für diesen Katalogartikel nicht verfügbar. Bitte passen Sie die Menge an, bevor Sie eine Bestellung aufgeben, oder kontaktieren Sie den Partner für weitere Informationen.",
      "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":"Mengenänderungen bei Abonnements mit dem Status \"ausgesetzt\" oder \"gelöscht\" werden nicht unterstützt. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
      "PC_REQUEST_INFORMATION_NOT_AVAILABLE":"Der Vorgang konnte nicht abgeschlossen werden, da die angeforderten Informationen derzeit nicht verfügbar sind. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
      "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"Die angeforderte Menge liegt nicht innerhalb der zulässigen Menge für dieses Angebot.",
      "PC_RESELLER_RELATIONSHIP_MISSING":"Es besteht keine Wiederverkäuferbeziehung mit dem Kunden. Bauen Sie bitte eine Reseller-Beziehung auf, bevor Sie diese Aktion erneut versuchen.",
      "PC_RI_PURCHASE_NOT_ENABLED":"Dieses Abonnement ist nicht für den RI-Kauf freigegeben. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_SANDBOX_LIMIT":"Das Sandbox-Limit wurde erreicht. Bitte passen Sie das Limit an, bevor Sie eine Bestellung aufgeben.",
      "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":"Der Verkauf im Land des Kunden wird nicht unterstützt. Bitte prüfen Sie die Liste der Microsoft CSP-Märkte, um weitere zu finden.",
      "PC_SKU_NOT_FOUND":"SKU nicht gefunden",
      "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Etwas ist schief gelaufen. Versuchen Sie es nach einiger Zeit erneut.",
      "PC_SUBCRIPTION_ACTIVE_ISSUE":"Dieses Abonnement ist kein aktives Abonnement. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"Das Abonnement kann nicht zu New Commerce migriert werden, da es Add-Ons enthält.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"Das Abonnement kann nicht zu New Commerce migriert werden, da die Kombination aus Laufzeit und Abrechnungszeitraum für das New-Commerce-Produkt nicht unterstützt wird.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"Das Abonnement kann nicht zu New Commerce migriert werden, da es noch keinen gültigen Migrationspfad für dieses Angebot gibt.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"Das Abonnement kann aus einem oder mehreren Gründen nicht zu New Commerce migriert werden (mögliche Gründe: das Abonnement befindet sich nicht im Status „Aktiv“ – das Abonnement enthält Werbeaktionen – das Abonnement enthält Add-Ons – das Abonnement steht kurz vor dem Ende der Laufzeit – das Abonnementangebot ist noch nicht in New Commerce verfügbar)",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"Das Abonnement kann nicht zu New Commerce migriert werden, da Werbeaktionen darauf angewendet wurden",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"Das Abonnement kann nicht zu New Commerce migriert werden, da das Abonnement derzeit verarbeitet wird.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"Das Abonnement kann nicht zu New Commerce migriert werden, da es weniger als 24 Stunden vor dem Ende der Laufzeit ist.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"Das Abonnement kann nicht zu New Commerce migriert werden, da es 1 Monat oder weniger aktiv war. Bitte versuchen Sie es später erneut.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"Das Abonnement kann nicht zu New-Commerce migriert werden, da mindestens ein Add-on-Abonnement, das in der AddOnMigrations-Sammlung angegeben ist, nicht vorhanden oder nicht aktiv ist.",
      "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"Das Abonnement kann nicht zu New Commerce migriert werden, da die im aktuellen Abonnement eingetragene Partner-ID ungültig ist.",
      "PC_SUBSCRIPTION_HISTORY_ISSUE":"Der Abonnementverlauf konnte nicht wiederhergestellt werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Ihr Abonnement läuft gerade ab. Für dieses Abonnement sind keine weiteren Updates zulässig.",
      "PC_SUBSCRIPTION_NOT_FOUND":"Das Abonnement, das Sie zu verwenden versuchen, ist bei Microsoft nicht mehr verfügbar.",
      "PC_SUBSCRIPTION_NOT_REGISTERED":"Dieses Abonnement ist nicht registriert. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":"Das Abonnementangebot ist nicht zum Kauf verfügbar. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
      "PC_SUBSCRIPTION_UPDATE":"Das Abonnement ist nicht bereit für eine Aktualisierung. Bitte versuchen Sie es nach einiger Zeit erneut oder kontaktieren Sie den Support, wenn das Problem weiterhin besteht.",
      "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"Die gewünschte Anzahl von Abonnements hat die maximal zulässige Anzahl von Abonnements pro Kunde überschritten",
      "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"Das Abonnement kann nicht zu New Commerce migriert werden, da der Status nicht aktiv ist.",
      "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"Das Enddatum der Zielabonnementverpflichtung muss nach dem Quellabonnement liegen",
      "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"Der Zielübergang darf keine kürzere Laufzeit als das Quellabonnement haben.",
      "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Das Zielabonnement kann nicht aktualisiert werden. Bitte versuchen Sie es nach einiger Zeit erneut.",
      "PC_TENANT_ALREADY_USED":"Die bei der Erstellung des Tenants angegebene Domain wird bereits von einem anderen Tenant verwendet. Wählen Sie eine andere Domain und versuchen Sie es erneut.",
      "PC_TENANT_NOT_EXISTS":"Der Domänenname existiert nicht. Bitte überprüfen Sie den Domänennamen.",
      "PC_TENANT_NOT_FOUND":"Tenant nicht gefunden.",
      "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"Die Begriffsdauer für den Zielübergang ist ungültig.",
      "PC_TERM_EXCEEDED":"Die ursprüngliche Kaufbedingung ist nicht mehr zum Kauf/Aktualisieren verfügbar.",
      "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":"Die Katalogartikel-ID ist nicht vorhanden. Wenden Sie sich bitte an den Support, um Hilfe zu erhalten.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"Das Enddatum der benutzerdefinierten Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, stimmt nicht mit dem Enddatum eines aktiven OnlineServicesNCE-Abonnements ohne Testversion überein oder stimmt nicht mit dem Ende des Kalendermonats überein.",
      "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"Das Enddatum der benutzerdefinierten Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, muss innerhalb der ersten Laufzeit nach der Verlängerung liegen. Produkte mit monatlicher Laufzeit können nicht mit einem Abonnement übereinstimmen, das am 28., 29. oder 30. des Monats endet, es sei denn, dieses Datum ist der letzte Tag des Monats.",
      "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"Die Anfrage konnte nicht alle Informationen über verfügbare Ressourcen abrufen. Bitte überprüfen Sie dies nach einiger Zeit, bevor Sie es erneut versuchen.",
      "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":"Das angeforderte Programm ist nicht aktiv. Wenden Sie sich bitte an den Support, um mehr zu erfahren.",
      "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":"Die angegebene Rolle hat nicht die Rechte, um die angeforderte Operation durchzuführen. Wenden Sie sich bitte an den Support, um weitere Informationen zu erhalten.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED":"Dieser Vorgang wird nicht unterstützt. Wenden Sie sich bitte an den Support, um weitere Informationen zu erhalten.",
      "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":"Dieser Vorgang wird in dieser Umgebung nicht unterstützt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Kunde keinen Azure-Plan erworben hat.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":"Diese Übertragungsanforderung kann nicht initiiert werden, da der Azure-Plan nicht gekauft werden kann. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Azure-Plan nicht aktiv ist. Bitte aktivieren Sie den Azure-Plan und versuchen Sie es erneut.",
      "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Kunde über ältere Azure-Abonnements verfügt.",
      "PC_TOKEN_MISSING":"Partner Token fehlt im Anfragekontext.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. CommitmentEndDate des Zielabonnements muss nach dem Quellabonnement liegen",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Zielabonnement muss aktiv sein.",
      "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Die Zielabonnement-ID darf nicht mit der Quellabonnement-ID identisch sein.",
      "PC_TRANSACTION_ELIGIBLE":"Sie sind möglicherweise für diese Transaktion berechtigt. Bitte kontaktieren Sie den Support für Hilfe.",
      "PC_TRANSACTION_NOT_ELIGIBLE":"Sie sind für diese Transaktion nicht berechtigt. Bitte reduzieren Sie die Menge oder wenden Sie sich an den Support, um Hilfe zu erhalten.",
      "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"Der Transfer kann nicht als Partner erstellt werden: {0} nationale Cloud {1} wird nicht unterstützt",
      "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Die Übertragung kann nicht eingeleitet werden, da die E-Mail/Domain-Details des Quellpartners in der Anfrage fehlen. Bitte geben Sie die erforderlichen Informationen ein, bevor Sie es erneut versuchen.",
      "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"Die Erstellung der Übertragung ist fehlgeschlagen, da die Details des Quellpartners mit denen des anfordernden Partners übereinstimmen. Bitte kontaktieren Sie den Microsoft-Support für weitere Informationen.",
      "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Diese Anforderung für die Übertragung des Rechnungseigentums kann nicht abgeschlossen werden, da Azure-Reservierungen nicht mit Abonnements übertragen werden. Stornieren Sie die Azure-Reservierungen, die mit den Abonnements in Ihrer Auswahl verbunden sind, und versuchen Sie es erneut.",
      "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"Die Übertragungsanforderung kann nicht akzeptiert werden. Bitte fordern Sie den Partner auf, eine Übertragung ohne Azure-Abonnement(e) zu erstellen",
      "PC_TRANSFER_REQUEST_DISABLE":"Übertragung kann nicht als Partner erstellt werden: {0} nicht für diese Funktion aktiviert",
      "PC_UNABLE_TO_COMPLETE_THE_REQUEST":"Die Anfrage kann nicht abgeschlossen werden. Wenden Sie sich bitte an den Microsoft-Support für weitere Informationen.",
      "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"Auftrag kann nicht erstellt werden. Die Anzahl der Azure-Pläne, die unter Sandbox-Konten erstellt werden können, ist begrenzt",
      "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":"Das Kundenprofil zum Abschließen der Anfrage kann nicht gefunden werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Es können keine Azure Active Directory-Abonnements für einen Kunden mit der Tenant-ID {0} und der Source Provisioning-ID {1} abgerufen werden.",
      "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"Bestellung kann wegen unzureichendem Kreditlimit nicht bearbeitet werden, bitte wenden Sie sich an ucmwrcsp@microsoft.com für weitere Hilfe.",
      "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Bei der Verarbeitung der New-Commerce-Migration ist ein unerwarteter Fehler aufgetreten.",
      "PC_UNSUCCESSFUL_TRANSFER_ORDER":"Die Übertragung der Bestellung war nicht erfolgreich. Bitte kontaktieren Sie den Support für weitere Informationen.",
      "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Aktualisierungsvorgänge sind derzeit für dieses Abonnement nicht zulässig.",
      "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Aktualisierungsvorgänge sind für dieses Abonnement nicht mehr zulässig.",
      "PC_UPDATES_NOT_ALLOWED":"Kann ein Abonnement nicht aktualisieren, bevor es aktiviert wurde'",
      "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":"Die Upgrade-Anforderung kann für ein bereits aktualisiertes Abonnement nicht bearbeitet werden.",
      "PC_UPGRADE_REQUEST_IN_PROGRESS":"Die Upgrade-Anforderung wurde nicht abgeschlossen, da eine andere Upgrade-Anforderung in Bearbeitung ist.",
      "PC_USER_LIMIT_EXCEEDED":"Die gewünschte Anzahl von Plätzen hat die maximal zulässige Anzahl von Plätzen pro Abonnement überschritten",
      "PC_USER_NOT_FOUND":"Der Benutzer wurde im Zielmandanten nicht gefunden.",
      "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Wenn Teil der Ausschreibung, ist entweder Ausschreibungslink oder Ausschreibungsdokument erforderlich.",
      "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"Sie sind nicht berechtigt, den Überschuss für diesen Kunden zu verwalten.",
      "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Sie haben Ihr Kündigungsfenster überschritten. Wir können Ihren Kauf nicht stornieren",
      "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Sie haben Ihr Tagesfenster zum Reduzieren von Plätzen für dieses Abonnement überschritten.",
      "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Ihr Unternehmen hat das Microsoft Partner Agreement (MPA) nicht akzeptiert. Der globale Administrator des CSP-Kontos muss das MPA akzeptieren, um die vollen Transaktionsfunktionen wieder aufzunehmen.",
      "PCA_MISSING_MPA":"Fehlende Microsoft-Partnervereinbarung vom Partner.",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus aus der Abrechnung abrufen",
      "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Zahlungsstatus aktualisieren in c3",
      "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Der Zahlungsstatus für {{Dateiname}} wurde aktualisiert.",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Aktionen",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"Zahlungsstatus für die Abrechnung",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"C3-Zahlungsstatus",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Entitätsname",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Bezahlte Menge",
      "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Datensatzname",
      "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Details anzeigen",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Aktionen",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Auftragsnummer",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Bestellt am",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Bemerkungen",
      "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Anfragetyp",
      "Percentage":"Prozentsatz",
      "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Software-Schlüssel",
      "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Laden Sie Software und Schlüssel herunter",
      "PHONE_TABLE_HEADER_ACTIONS":"Aktionen",
      "PHONE_TABLE_HEADER_PHONE_NUMBER":"Telefonnummer",
      "PHONE_TABLE_HEADER_PHONE_TYPE":"Telefon-Typ",
      "PHONE_TYPE_HOME":"Heim",
      "PHONE_TYPE_HOME_FAX":"Privatfax",
      "PHONE_TYPE_MAIN":"Hauptsächlich",
      "PHONE_TYPE_MOBILE":"Handy, Mobiltelefon",
      "PHONE_TYPE_OFFICE":"Büro",
      "PHONE_TYPE_OTHER":"Andere",
      "PHONE_TYPE_WORK":"Arbeiten",
      "PHONE_TYPE_WORK_FAX":"Arbeitsfax",
      "PLACE_HOLDER_SEARCH_CUSTOMER":"Kunde suchen",
      "PLACEHOLDER_ENTER_TEXT":"Text eingeben",
      "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Abrechnungszyklus auswählen...",
      "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Abrechnungsart auswählen...",
      "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Wählen Sie Kategorien",
      "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Kategorie-Typ wählen...",
      "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Verbrauchsart auswählen...",
      "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Währungscode suchen",
      "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Kunden suchen",
      "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
      "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Wählen Sie einen Provider-Kunden in der Liste aus oder suchen Sie den Namen oder die Mieter-ID...",
      "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"Gültig ab",
      "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Wirksam für",
      "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"MM",
      "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Text eingeben",
      "PLACEHOLDER_FOR_PLAN_NAME":"Geben Sie den Plannamen ein",
      "PLACEHOLDER_FOR_PRICE":"Preis",
      "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Wählen Sie Anbieter",
      "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Kunden suchen",
      "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Rollen auswählen ..",
      "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Wählen",
      "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Tag-Werte auswählen.",
      "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Laufzeit auswählen...",
      "PLACEHOLDER_FOR_TEXT_SEARCH":"Suche...",
      "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Testdauer auswählen...",
      "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Gültigkeitsart auswählen...",
      "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"JJJJ",
      "PLACEHOLDER_SPECIAL_QUALIFICATION":"Besondere Qualifikationen",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Bearbeiten",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Besondere Qualifikationen bearbeiten",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"Die maximal zulässigen Zeichen sind 50",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"sollte mindestens ein Zeichen haben",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"speichern",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Keiner",
      "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Dieses Feld wird benötigt",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Schließen",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Ausbildung",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Segment Bildung",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Höhere Bildung",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Webseite",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Keiner",
      "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Staatliches Unternehmen",
      "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"Spezifische {{EntityName}} auswählen? Klicken Sie hier...",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Geben Sie ein, um nach einem Tag zu suchen",
      "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Wählen Sie einen Mandanten in der Liste aus oder suchen Sie den Namen/die Mandanten-ID...",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Suchplan",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Produktname",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Produkt-ID",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Suchen Sie mit dem Produktnamen oder der Produkt-ID",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Produktname, ID",
      "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Nach Abonnement suchen",
      "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Geben Sie gültige E-Mail-IDs ein",
      "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"Max. Selbstkostenpreis",
      "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"Max. Verkaufspreis",
      "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"Max. Gültigkeit",
      "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"Mindest. Selbstkostenpreis",
      "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"Mindest. Verkaufspreis",
      "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"Mindest. Gültigkeit",
      "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Zahlungsbetrag",
      "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Geben Sie hier ein...",
      "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Wählen Sie einen vorhandenen Kunden aus der Liste aus oder suchen Sie anhand des Kundennamens/der Mandanten-ID",
      "PLACEHOLDER_TEXT_FOR_REMARKS":"Bemerkungen",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Suche nach Kunde",
      "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Suche nach Status",
      "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Aktivieren Sie dieses Kontrollkästchen, wenn Sie in Zukunft zusammengesetzte Angebote in diesem Plan haben möchten.",
      "PLAN_ASSIGN_STATUS_SUCCESS":"Der Status wurde erfolgreich aktualisiert",
      "PLAN_BREADCRUMB_BUTTON_PLAN":"Pläne",
      "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Neuen Plan hinzufügen",
      "PLAN_CAPTION_TEXT_PLANS":"Pläne",
      "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PLAN_CHANGE_FOR_CUSTOMER":"Plan des Kunden ändern",
      "PLAN_CHANGE_TEXT":"Planänderung",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Composite Plan Angebote",
      "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Pläne",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Zurück zu zusammengesetzten Angeboten",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Zusammengesetztes Angebot hinzufügen",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Zusammengesetztes Angebot bearbeiten",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Bietet an",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Ausgewählte Elemente",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Vorgeschlagener Preis :",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Add-on(s)",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Add-Ons",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Listenpreis",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Entfernen",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Beschreibung",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Abrechnungskomponenten-ID",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Angebotsname",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Preis",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Zusammengesetztes Angebot hinzufügen",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Zurück zu den Planungsdetails",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Zusammengesetzte Angebote für -",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Bearbeiten",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Inbegriffene Angebote",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Name",
      "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Preis",
      "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>unter</b>{{ParentOfferName}}",
      "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Anmelden",
      "PLAN_DETAILS_IS_PUBLIC":"Ist öffentlich",
      "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "PLAN_DETAILS_PARAGRAPH_1_TEXT":"pro Monat",
      "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Geschätzter Verkaufspreis",
      "PLAN_DETAILS_PARAGRAPH_3_TEXT":"pro Jahr",
      "PLAN_DETAILS_PARAGRAPH_4_TEXT":"Einmalig",
      "PLAN_DETAILS_SHARING_URL":"URL teilen",
      "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Ihre Änderungen wurden erfolgreich übermittelt",
      "PLAN_IS_IN_PROGRESS_EXCEPTION":"Planung noch in Bearbeitung. Bitte überprüfen Sie den Status",
      "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"Der Mindestwert ist -1",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Legen Sie den Grenzwert auf -1 fest, um eine Genehmigung für alle Käufe/Aktualisierungsplätze zu erfordern.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Legen Sie das Limit auf 0 fest, um den Kauf unbegrenzter Sitzplätze zu ermöglichen.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Legen Sie eine positive Zahl ungleich Null fest, um den Schwellenwert zu definieren.",
      "PLAN_LEVEL_SEATLIMIT_DETAILS_4":"Jeder Kauf, der den Schwellenwert überschreitet, erfordert die Genehmigung des Partners.",
      "PLAN_LEVEL_SEATLIMIT_EDIT":"Legen Sie das Sitzplatzlimit für das Planprodukt fest",
      "PLAN_LEVEL_SEATLIMIT_REVERT":"Kehren Sie zum Sitzplatzlimit auf Planebene zurück",
      "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Laden Sie die neuesten verfügbaren Microsoft NCE-Promotions in die Pläne.",
      "PLAN_MACRO_DETAILS_LABEL_TEXT":"Makrodetails",
      "PLAN_MACRO_NAME_LABEL_TEXT":"Makroname",
      "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Menge",
      "PLAN_MACRO_VALUE_LABEL_TEXT":"Makrowert",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Zurück zu den Plänen",
      "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Zusammengesetzte Angebote verwalten",
      "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Details zum Plan -",
      "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Neuer Plan",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Promotionen laden",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Stornieren",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Promotionen vom Anbieter laden",
      "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"einreichen",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Ist privat",
      "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"CSP-Abonnements für Angebote im Plan freigeben lassen?",
      "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Bietet an",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Hier sind die Schritte zum Überschreiben eines geerbten Angebots:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Aktivieren Sie das Kontrollkästchen für das Angebot, das Sie überschreiben möchten",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Geben Sie einen neuen freundlichen Namen ein",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Geben Sie den neuen Verkaufspreis an",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Der neue Plan würde beginnen, die überschriebenen Angebote zusammen mit denen aufzulisten, die geerbt, aber nicht überschrieben wurden.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Hinweis:",
      "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Vom übergeordneten Plan überschrieben",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"ausgewählt",
      "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Artikel",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Add-on(s)",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Verfügbar zum Kauf",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Abrechnungskomponenten-ID",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Listenpreis",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Angebotsname",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"Erweiterung",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lizenz-Typ",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Entfernen",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Verkaufspreis",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Preis aktualisieren",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Plan Name:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Preis:",
      "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Neuen Preis festlegen",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lizenz-Typ",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Angebot überschrieben",
      "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Angebot ausgewählt",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Beschreibung",
      "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Plan Name",
      "PLAN_MANAGE_ADD_FORM_URL_LABEL":"URL teilen",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Pläne",
      "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Verwalten",
      "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"Freundlicher Name darf nicht leer sein",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Angebote mit Werbung anzeigen",
      "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"offerWithPromotion",
      "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"TESTANGEBOT",
      "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Elternnamen",
      "PLAN_NAME_IS_INVALID":"PlanName ist ungültig",
      "PLAN_OFFER_PRICE_CHANGE_HEDER":"Ist rechnungsbasiert?",
      "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"Neuen Preis festlegen für",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Preisänderung wäre effektiv",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Neuer Preis",
      "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Derzeitiger Preis",
      "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Der Planangebotspreis wurde erfolgreich aktualisiert",
      "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Angebote mit Marge <= 0 anzeigen",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Angebote mit Marge > 0 und < 10 anzeigen",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Angebote mit Marge >= 30 anzeigen",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Angebote mit Marge >= 10 und < 20 anzeigen",
      "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Angebote mit Marge >= 20 und < 30 anzeigen",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Plan verwalten",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Plan überschreiben",
      "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Pläne",
      "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Zurück zu den Plänen",
      "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Plan bearbeiten",
      "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Plan überschreiben",
      "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"CSP-Abonnements für Angebote im Plan freigeben lassen?",
      "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Ist privat",
      "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Beschreibung",
      "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Plan Name",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Hinweis:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Hier sind die Schritte zum Überschreiben eines geerbten Angebots:",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Aktivieren Sie das Kontrollkästchen für das Angebot, das Sie überschreiben möchten",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Geben Sie einen neuen freundlichen Namen ein",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Geben Sie den neuen Verkaufspreis an",
      "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"Der neue Plan würde beginnen, die überschriebenen Angebote zusammen mit denen aufzulisten, die geerbt, aber nicht überschrieben wurden.",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Verfügbar zum Kauf",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Abrechnungskomponenten-ID",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Listenpreis",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Angebot überschrieben",
      "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Zum Überschreiben ausgewählte Elemente",
      "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Bietet an",
      "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"URL teilen",
      "PLAN_POPUP_GRID_HEADER":"Planraster herunterladen",
      "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Dieses Produkt ist bereits im Plan verfügbar. Möchten Sie das Produkt erneut hinzufügen?",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Währungscode planen",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Plan Name",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Zielwährungscode",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Wählen",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Quellwährungscode",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Zielwährungscode",
      "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Neue Währung definieren",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Erlaubte Anzahl von Plätzen",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
      "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Anzahl der Sitze",
      "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Ungültiger Sitzplatzwert für Produkt {{PlanProduktName}}",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Fehler beim Aktualisieren der Plätze aufgetreten",
      "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Sitzplätze erfolgreich aktualisiert",
      "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Platten erfolgreich gespeichert",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Bearbeiten",
      "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Überschreiben",
      "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "PLAN_TABLE_HEADER_TEXT_NAME":"Name",
      "plandetails":"Plandetails",
      "planproductcurrencyconversion":"Währungsumrechnung",
      "PlanProductId":"PlanProductId",
      "plans":"Pläne",
      "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Bitte wählen Sie mindestens zwei Angebote aus, um ein zusammengesetztes Angebot zu erstellen",
      "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Zusammengesetztes Angebot erfolgreich gespeichert",
      "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Zusammengesetztes Angebot erfolgreich aktualisiert",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Stornieren",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Schließen",
      "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"einreichen",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Alle Produkte",
      "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"Keine Aufzeichnungen gefunden",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Bitte speichern bzw. schließen Sie die bestehende neue bzw. Bearbeitungszeile.",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Bitte geben Sie einen Wert größer als \" + minValue : \"Bitte geben Sie einen Wert zwischen \" + minValue + \" und \" + maxValue }}",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Bitte geben Sie einen Wert zwischen {{minValue}} und {{maxValue}} ein",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Bitte geben Sie einen Wert größer als {{minValue}} ein",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Vertragsdetails",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Abrechnungsart",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Kosten für den Partner",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Platten",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Verkaufspreis",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"Maximaler Wert",
      "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Min. Wert",
      "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Anwendbarkeit",
      "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Sie haben bereits ein Vertragsangebot in Ihrem Plan. Löschen/deaktivieren Sie das Angebot, bevor Sie ein neues hinzufügen.",
      "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Vertrag",
      "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Reservierung",
      "PLANS_OVERRIDE_EDIT_SUCCESS":"Überschriebener Plan erfolgreich aktualisiert",
      "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan erfolgreich überschrieben",
      "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Bitte wählen Sie Angebote aus, die aus dem Basisplan überschrieben werden sollen",
      "PLANS_SAVE_SUCCESS":"Plan erfolgreich gespeichert",
      "PLANS_UPDATE_SUCCESS":"Plan erfolgreich aktualisiert",
      "PLEASE_ENTER_THE_REASON":"Bitte geben Sie einen Grund an.",
      "PO_NUMBER":"PO #",
      "POLICY_ASSIGNMENT_SCOPE_TITLE":"Richtlinienzuweisungsbereiche",
      "PONUMBER_BUTTON_CLOSE":"Schließen",
      "PONUMBER_BUTTON_SKIP":"Überspringen",
      "PONUMBER_BUTTON_SUBMIT":"einreichen",
      "PONUMBER_DELETE_CONFIRMATION":"Sind Sie sicher, dass Sie diese Bestellnummer aus dem Warenkorb löschen möchten?",
      "PONUMBER_DELETE_ERROR_MESSAGE":"Beim Entfernen der Bestellnummer aus dem Warenkorb ist ein Fehler aufgetreten!",
      "PONUMBER_DELETE_SUCCESS_MESSAGE":"Die angeforderte Bestellnummer wurde erfolgreich entfernt!",
      "PONUMBER_PLACEHOLDER":"Geben Sie die Bestellnummer ein",
      "PONUMBER_POPUP_TITLE":"Bestellnummer hinzufügen",
      "PONUMBER_TITLE":"Bestellnummer",
      "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie alle Pläne in die Warteschlange stellen möchten?",
      "POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "POPUP_BUTTON_TEXT_CLOSE":"Schließen",
      "POPUP_BUTTON_TEXT_CONFIRM":"Bestätigen",
      "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Alle ausgewählten Angebote im ausgewählten Abrechnungszyklus gehen verloren. Wollen Sie fortfahren?",
      "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Dieser Kunde enthält bereits einen Gutschein: <b>{{CouponName}} </b>, möchten Sie ihn ersetzen?.",
      "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"Wenn Sie einen Rechnungskunden entfernen, werden auch die zugehörigen Zahlungsprofile entfernt. Sind Sie sicher, dass Sie den Link zum Rechnungskunden für {{Kundenname}} entfernen möchten?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die benutzerdefinierte Benachrichtigung löschen möchten?",
      "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Benutzerdefinierte Benachrichtigung erfolgreich gelöscht.",
      "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"Möchten Sie {{CustomerName}} wirklich löschen?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"Das Löschen hier führt dazu, dass das Angebot aus den hinzugefügten Reseller-Plänen entfernt wird und deren Kunden es nicht weiter kaufen können! Möchten Sie das Angebot von {{distributorOffer}} wirklich löschen?",
      "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} erfolgreich gelöscht.",
      "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"Möchten Sie diese Benachrichtigung wirklich löschen?",
      "POPUP_DELETE_HEADER_TEXT_DELETE":"Löschen",
      "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"Möchten Sie {{customOfferName}} wirklich löschen?",
      "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} erfolgreich gelöscht.",
      "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>TESTANGEBOT</sup>",
      "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"Möchten Sie {{planName}} wirklich löschen?",
      "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"Möchten Sie das Angebot wirklich löschen?",
      "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"Möchten Sie die Angebotsposition <b>{{ProductName}}</b> wirklich löschen?",
      "POPUP_DELETE_SUB_HEADER_TEXT":"Sind Sie sicher, dass Sie löschen möchten?",
      "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie diese markierte Entität löschen möchten?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die Nachricht {{MessageBody | limitTo:30}} löschen wollen?",
      "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"Sind Sie sicher, dass Sie diese Nachricht löschen möchten",
      "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die Webhook-Benachrichtigung löschen möchten?",
      "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"Webhook-Benachrichtigung erfolgreich gelöscht",
      "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"Webhook-Benachrichtigung erfolgreich gelöscht.",
      "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"Möchten Sie {{productName}} wirklich deaktivieren?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> ist das übergeordnete Angebot für das Testprodukt.<br/> Wenn dieses Angebot deaktiviert ist, wird auch das Testangebot deaktiviert.<br/> Sind Sie sicher, dass Sie < deaktivieren möchten? b>{{productName}}<b/>?",
      "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> ist ein übergeordnetes Angebot für das Testprodukt.<br/> Die Deaktivierung dieses Produkts wirkt sich auf die Umwandlung des Testprodukts in ein Abonnement aus.<br/> Sind Sie sicher, dass Sie <b>{ deaktivieren möchten? {productName}}<b/>?",
      "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"Möchten Sie {{productName}} wirklich aktivieren?",
      "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> übergeordnetes Angebot ist nicht aktiv.<br/> Durch die Aktivierung des Testangebots wird auch übergeordnetes Angebot aktiviert.<br/> Sind Sie sicher, dass Sie <b>{{productName}} aktivieren möchten? <b/>?",
      "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Engage-Benachrichtigung wird gelöscht.",
      "POPUP_PLAN_DELETED":"Plan {{planName}} wird gelöscht.",
      "POPUP_REVERT_HEADER_TEXT_DELETE":"Zurückkehren",
      "POPUP_REVERT_SUB_HEADER_TEXT":"Sind Sie sicher, dass Sie zurückkehren möchten?",
      "POPUP_TEXT_DEACTIVATE_PSA_LOG":"Möchten Sie die Protokolle wirklich deaktivieren? Wir löschen keine Protokolle im PSA, dieser Vorgang ermöglicht es Ihnen, die Daten mehr als einmal von C3 auf PSA hochzuladen. Bitte beachten Sie, dass durch das erneute Hochladen möglicherweise Duplikate in PSA entstehen können.",
      "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"PSA-Protokolle erfolgreich deaktiviert",
      "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Ihre ausgewählten Angebote mit dem aktuellen Abrechnungszyklus gehen verloren. Möchten Sie trotzdem fortfahren?",
      "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Sie haben nicht gespeicherte Änderungen. Möchten Sie trotzdem fortfahren?",
      "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Möchten Sie den Kundennamen wirklich aktualisieren?",
      "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie den Resellernamen aktualisieren möchten?",
      "POPUP_WEB_NOTIFICATION_DELETED":"Web-Benachrichtigung {{MessageBody}} ist gelöscht.",
      "POPUP_WEB_NOTIFICATION_DELETED2":"Die Web-Benachrichtigung wurde gelöscht.",
      "PORTAL_ACCESS_GRANTED_TO_USER":"Der Portalzugriff wird auf {{UserName}} gewährt",
      "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Portalseite auswählen",
      "PORTAL_PAGE_NAME":"Name der Portalseite",
      "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Verwendungsdaten",
      "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Die Preisänderung wird ab dem Beginn des aktuellen Gebührenzyklus des Abonnements angewendet",
      "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Sofort",
      "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"Ab der nächsten Abrechnung",
      "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"Ab der nächsten Erneuerung",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Schließen",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Schließen",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Vorschau der E-Mail-Benachrichtigung",
      "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Vorschau der E-Mail-Benachrichtigung",
      "Price":"Preis",
      "PRICE_CHANGE_CURRENCY":"Währung",
      "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"Der eingegebene Wert entspricht nicht der gewählten Preissperrkonfiguration.",
      "PRICE_CHANGE_NEW_PRICE":"Neuer Preis",
      "PRICE_CHANGE_OLD_PRICE":"Alter Preis",
      "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Anweisungen zur Microsoft-Preis-API",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Vielen Dank für Ihre Zustimmung. Die Anwendung kann so konfiguriert werden, dass sie auf die Microsoft-Preise zugreift.",
      "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen unter Einstellungen -> Partnercenter in <b>{{applicationName}} </b>.",
      "PRICING_API_VIEW_INSTRUCTIONS_1":", um den Link zu kopieren, der die Zustimmung des Partners einholt, dieser Anwendung gemäß der Microsoft-Preis-API Zugriff auf das Partner Center zu gewähren.",
      "PRICING_API_VIEW_INSTRUCTIONS_2":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Einwilligungserfahrung mit einem Konto ab, das ein globaler Administrator in Ihrem (Partner-) Mandanten ist und dem die Partner-Agent-Rolle im Partnercenter zugewiesen ist.",
      "PRICING_API_VIEW_INSTRUCTIONS_3":"Klicken Sie auf die Schaltfläche Test, um zu überprüfen, ob die Anwendung mit der Microsoft-Preis-API funktioniert.",
      "PRICING_API_VIEW_INSTRUCTIONS_5":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
      "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Aktionen",
      "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Abrechnungsart",
      "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Selbstkostenpreis",
      "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Anzeigename",
      "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"MaxValue",
      "PRICING_SLABS_TABLE_HEADER_MINVALUE":"MinValue",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Verkaufspreis",
      "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Verkaufspreis",
      "PRICING_SLABS_TABLE_HEADER_TYPE":"Abrechnungsart",
      "PRIVACY_POLICY_BUTTON_TEXT":"DATENSCHUTZ-BESTIMMUNGEN",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Prozess des Bulk Onboarding Kunden abgeschlossen",
      "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Prozess des Bulk Onboarding Kunden gestartet",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Vorgang zum Hochladen von Rechnungen in Quickbooks abgeschlossen",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Prozess zum Hochladen von Rechnungen in Quickbooks eingeleitet",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Vorgang zum Hochladen von Rechnungen zu Xero abgeschlossen",
      "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Prozess zum Hochladen von Rechnungen zu Xero eingeleitet",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Das Hochladen von Rechnungen in Quickbooks ist fehlgeschlagen",
      "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Prozess des Hochladens von Rechnungen in Quickbooks erfolgreich",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Das Hochladen von Rechnungen zu Xero ist fehlgeschlagen",
      "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Prozess des Hochladens von Rechnungen zu Xero Erfolg",
      "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Ihre ausgewählten Elemente wurden entfernt.",
      "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Das Ändern des Werts dieses Feldes führt zum Verlust ausgewählter Elemente",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Anzahl der maximal zulässigen Tage ohne Stornierung.",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Verkaufsart",
      "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Gültigkeit",
      "PRODUCT_CANCELLATION_TEXT":"Stornierung möglich",
      "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Wir nehmen derzeit einige Änderungen an diesem Produkt vor. Bitte warten Sie, bis diese Vorgänge abgeschlossen sind, bevor Sie eine neue Anfrage stellen.",
      "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Wählen Sie eine Möglichkeit aus, um den Produktkatalogbericht zu erhalten",
      "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"Email wurde erfolgreich Versendet",
      "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet:",
      "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Agentenname",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Endtermin",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Provision(%)",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Anfangsdatum",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"Interne Produkt-ID",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"Abonnement-ID des Anbieters",
      "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"SP-Code",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Agentenname",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"Provisionsbetrag",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Währungscode",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"SP-Code",
      "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"Die Provision für die Produkte eines Kunden wird berechnet, sobald die Rechnungen abgeschlossen sind. Bei der Berechnung der Provisionen für einen Kunden werden nur die vom Benutzer im jeweiligen Abrechnungszeitraum angegebenen Provisionsdetails berücksichtigt. Wenn in einem Abrechnungszeitraum keine Provisionsdetails angegeben sind, werden die Provisionsdetails berücksichtigt, die im vorherigen Abrechnungszeitraum definiert wurden und bis zum Abrechnungszeitraum der Rechnung Gültigkeit haben.",
      "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtern nach :",
      "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
      "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
      "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog validieren",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Agentenname",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Enddatum der Provision",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Provision(%)",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Startdatum der Provision",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Domain Name",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"Interne Produkt-ID",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"Abonnement-ID des Anbieters",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"SP-Code",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
      "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
      "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Anleitung",
      "PRODUCT_COULD_NOT_FOUND_TEXT":"Der Produkttyp konnte nicht gefunden werden.",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Genehmigen",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Rechnungskreislauf",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Aktuelle Menge",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Kunde Bemerkung",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Ablehnen",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Neue Menge",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Sitzplatz-Limit",
      "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Quellmenge",
      "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Lese weniger",
      "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Weiterlesen",
      "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtern nach  :",
      "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Produkteigenschaften",
      "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
      "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
      "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog validieren",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Rechnungskreislauf",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Kategorie",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Reihenfolge anzeigen",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"Angebots-ID",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Angebotsname",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Produkt-Code",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Anbietername",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
      "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
      "PRODUCT_LIST_ADDON_LABEL":"Zusatzangebot",
      "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
      "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Wir haben diese Anfrage storniert, da einige Änderungen aktualisiert wurden.",
      "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"Warten auf die Genehmigung.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Wählen Sie mindestens ein aktives Produkt aus, bevor Sie fortfahren.",
      "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Wählen Sie mindestens einen Benutzer aus der Liste aus.",
      "PRODUCT_MAPPING":"Produktzuordnung",
      "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für ausgewählte Produkte wirklich speichern?",
      "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Dieses Produkt kann nicht gekauft werden.",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Produkt nicht zugeordnet, da der Mieter mit mehreren Kunden verbunden ist",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Produkt nicht zugeordnet, da der Mandant mit mehreren Kunden verknüpft ist oder das Produkt bereits ignoriert wurde",
      "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"Produkt ist bereits zugeordnet",
      "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Prozentsatz",
      "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Menge",
      "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Bereichsname",
      "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Sie können die Menge nicht auf einen Wert über der verfügbaren Menge aktualisieren. Wenn Sie dies tun möchten, erhöhen Sie bitte die Menge in der grundlegenden Detailansicht.",
      "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Sie können die Menge durch Inline-Bearbeitung aktualisieren und zum Speichern auf die Schaltfläche \\''Senden\\'' klicken",
      "PRODUCT_OWNERSHIP_SUCCESS":"Eigentümer erfolgreich aktualisiert",
      "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Hinzufügen",
      "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
      "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Änderungen speichern",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
      "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Geben Sie einen eindeutigen Tag-Wert ein",
      "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Produkt-Tags erfolgreich gespeichert",
      "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Produkt Tags",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Beschreibung",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Tag-Schlüssel hinzufügen",
      "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Tag-Wert hinzufügen",
      "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Aktionen",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Beschreibung",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Tag-Schlüssel",
      "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Tag-Wert",
      "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Produkt Tags",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Kategorie",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Währungscode",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Unterstützter Marktcode",
      "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
      "PRODUCT_VIEW_GRID":"Netz",
      "PRODUCT_VIEW_LIST":"Aufführen",
      "productcatalogue":"Produktkatalog",
      "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Erneuert sich/läuft ab (in Tagen)",
      "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Maximales Bestelldatum",
      "PRODUCTS_REPORT_MAX_QUANTITY":"Maximale Menge",
      "PRODUCTS_REPORT_MIN_QUANTITY":"Mindestmenge",
      "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Dashboard",
      "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Kundenprofil",
      "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
      "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Bankdaten",
      "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Kreditkarte",
      "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
      "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Bankkonto",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Zahlungsart",
      "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Bitte wählen Sie Zahlungsart",
      "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
      "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Zahlungsprofil erfolgreich aktualisiert",
      "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Nutzerdetails",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Stadt",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"Land",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Email",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Vorname",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Nachname",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Linie 1",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Zeile 2",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Zustand",
      "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Postleitzahl",
      "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Email",
      "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Kundenkonfiguration",
      "PROFILE_HEADER_RESELLER_CONFIGURATION":"Reseller-Konfiguration",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Löschen",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"speichern",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
      "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Zahlungsprofil löschen",
      "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Als Standardzahlungsprofil festlegen",
      "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Telefonnummer",
      "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
      "PROFILE_TAB_CONFIGURATION_SETTING":"Konfiguration/Einstellungen",
      "PROFILE_TAB_ORGANIZATION_SETUP":"Einrichtung der Organisation",
      "PROFILE_TAB_PAYMENT_PROFILE":"Zahlungsprofil",
      "PROFILE_TAB_USER_DETAILS":"Nutzerdetails",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Machen Standard",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"einreichen",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"speichern",
      "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"speichern",
      "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Stadt",
      "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"Land",
      "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Email",
      "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Vorname",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Nachname",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Linie 1",
      "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Zeile 2",
      "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Telefonnummer",
      "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Zustand",
      "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Postleitzahl",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Rechnungsadresse",
      "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Rechnungsdetails",
      "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Zahlungsmethode hinzufügen",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Hinweis:",
      "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Das neue Zahlungsprofil wird für alle nachfolgenden Transaktionen verwendet",
      "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Bezahlungsmethode ändern",
      "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Details zum Zahlungsprofil",
      "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Bitte fügen Sie eine Zahlungsmethode hinzu.",
      "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Sie haben bereits ein Zahlungsprofil hinterlegt.",
      "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Die letzten 4 Ziffern Ihrer Zahlungsmethode",
      "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Bezahlverfahren",
      "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Nutzerdetails",
      "PROMOTION_ERRORS_ALERT_TEXT":"Werbefehler",
      "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Sie können diesen Artikel nicht kaufen, da Ihr Konto nicht mit diesem Anbieter verknüpft ist!",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"Fehler beim Generieren des Passworts für den Admin-Benutzer des Anbieterkunden",
      "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Passwort für Anbieter-Kunden-Admin-Benutzer erfolgreich generiert",
      "PROVIDER_CUSTOMER_BILLING_EMAIL":"Abrechnung per E-Mail",
      "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Käufe gegen CSP-Mandanten können aufgrund fehlender Zustimmung des Kunden fehlschlagen. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><b class=\"red \"> Klicken Sie hier, um zum Mieterprofil umzuleiten und MCA hinzuzufügen </b></a>",
      "PROVIDER_CUSTOMER_DOMAIN":"Provider-Kundendomäne",
      "PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
      "PROVIDER_CUSTOMER_NAME":"Name des Anbieterkunden",
      "PROVIDER_DESC_MICROSOFT":"Microsoft",
      "PROVIDER_DESC_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
      "PROVIDER_DESC_PARTNER":"Partner",
      "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Anbietername",
      "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Kosten für den Partner",
      "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Bezahlte Menge",
      "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Bezahltes Datum",
      "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Anbieter",
      "PROVIDER_PRODUCT_ID":" Produkt-ID des Anbieters:",
      "PROVIDER_RESELLER_BILLING_EMAIL":"Abrechnung per E-Mail",
      "PROVIDER_RESELLER_COUNTRY_CODE":"Landesvorwahl",
      "PROVIDER_RESELLER_ID":"Reseller-ID des Anbieters",
      "PROVIDER_RESELLER_MPNID":"Anbieter Reseller MPN-ID",
      "PROVIDER_RESELLER_NAME":"Name des Wiederverkäufers des Anbieters",
      "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Fehlerdetails",
      "PROVIDER_RESELLER_ONBOARDING_STATUS":"Onboarding-Status",
      "PROVIDER_SEARCH_DEFAULT_OPTION":"Wählen",
      "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft",
      "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Bestehender Kunde",
      "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Neukunde",
      "providers":"Anbieter",
      "provisioning":"Bereitstellung",
      "PROVISIONING_LABLE_TEXT":"Bereitstellung...",
      "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Meister",
      "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Rahmenvertrag",
      "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "PSA_BULK_UPLOAD_FAILED_STATUS":"Die Aktualisierung der Massenentitätszuordnung in C3 ist fehlgeschlagen.",
      "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"Die Produktzuordnung für Großkäufe wurde teilweise erfolgreich in C3 hochgeladen. Bitte überprüfen Sie das heruntergeladene ungültige Entitätszuordnungsblatt auf Überarbeitung.",
      "PSA_BULK_UPLOAD_SUCCESS_STATUS":"Die Produktzuordnung für den Massenkauf wurde erfolgreich in C3 hochgeladen.",
      "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Konnektivität testen",
      "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Suche Vertrag",
      "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Vertrag auswählen",
      "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"PSA-Dienst durchsuchen",
      "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"PSA-Produkt auswählen",
      "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Für diesen Abrechnungszeitraum ist keine Vereinbarung zugeordnet",
      "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"Wir konnten kein Vertragsbeginndatum von PSA erhalten und konnten uns daher nicht anpassen",
      "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Für diesen Abrechnungszeitraum sind keine Verträge zugeordnet",
      "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Diese Protokolle fallen nicht in den ausgewählten Zeitraum",
      "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"Startdatum, Enddatum, Zeitberichte erfordern Start- und Endzeiten oder der Wert der Einrichtungsgebühr fehlt. Dies sind erforderliche Werte, um einen Vertrag zu erstellen. Bitte aktualisieren Sie diese Werte in den Kundenkonfigurationen.",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"Das Datum des Abonnementverlaufs liegt vor dem neuen Datum, das nach dem Ausführen des Vorgangs zum Abgleichen mit dem Startdatum des Vertrags berechnet wird",
      "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Beim Alignment-Versuch konnten keine aktiven Abonnements gefunden werden, um den Alignment-Vorgang auszuführen.",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Kategorie wählen",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Produktklasse auswählen",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"SLA auswählen",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Unterkategorie auswählen",
      "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Maßeinheit auswählen",
      "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inaktiv",
      "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Suche PSA-Kunden",
      "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Vereinbarung auswählen",
      "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Kategorie wählen",
      "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Wählen Sie {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'Vertragsvereinbarung' }}",
      "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Wählen Sie Kunde",
      "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Wählen Sie PSA-Kunde aus",
      "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"PSA-Produkt auswählen",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Wählen Sie {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'PSA-Dienst' : 'PSA-Produkt' }}",
      "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Mieter auswählen",
      "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"Die Datenaktualisierung wird gerade durchgeführt. Weitere Optionen stehen zur Verfügung, sobald der letzte Datenprozess abgeschlossen ist.",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Warnung",
      "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Daten für frühere Daten sind nach Verwendung des gefilterten Datums für ausgewählte Kunden nicht verfügbar.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Notiz",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Bitte beachten Sie, dass für das Hochladen neu generierter Rechnungen Unterstützung vom C3-Supportteam erforderlich ist.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"Wenn Sie hier das Startdatum und das Enddatum festlegen, wird dies in den in connectwise erstellten Ergänzungen als Startdatum und Stornierungsdatum für alle anwendbaren Rechnungsposten berücksichtigt.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Wenn kein Startdatum und kein Enddatum festgelegt sind, werden die Daten des Rechnungsabrechnungszeitraums berücksichtigt.",
      "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"Die nachstehende Kundenliste enthält nur die Kunden, deren Rechnung(en) für den ausgewählten Zeitraum den Status „Abgeschlossen“ haben.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Wenn das Kontrollkästchen <span class=\"font-italic\">(Am Vertragsbeginn ausrichten)</span> aktiviert ist, berechnen wir die nächsthöhere Vertragslaufzeit zum Hochladen. Bitte beachten Sie, dass die nächste aktuelle Vertragslaufzeit nur auf monatlicher Basis berechnet wird, auch wenn die Vertragslaufzeit <span class=\"font-italic\">jährlich</span> ist.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"Das Startdatum verhindert, dass frühere Protokolle für den Upload berücksichtigt werden.",
      "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Bitte verwenden Sie die Filter nur, wenn Sie zum ersten Mal versuchen, Daten für einen Kunden zu übertragen. Zum Beispiel: <ul><li>Partnerregistrierung, d. h. zuerst Push von C3 zu Autotask</li><li>Onboarding neuer Kunden</li></ul>",
      "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Beginnen Sie den Upload-Prozess, indem Sie Kunden auswählen und auf die Schaltfläche <span class=\"bold\">Validieren</span> klicken, um den Validierungsprozess zu starten",
      "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Bitte synchronisieren Sie vor dem Hochladen mit Partner Center",
      "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Bitte beachten Sie, dass alle Daten (einschließlich Filter) in UTC berücksichtigt werden.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Verfolgen Sie den Validierungsfortschritt, indem Sie auf die Schaltfläche <span class=\"bold\">Protokolle anzeigen</span> klicken.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_3":"Nehmen Sie im Falle von Validierungsfehlern die erforderlichen Korrekturen vor und versuchen Sie es erneut.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Wenn die Validierung erfolgreich ist, können Sie mit dem <span class=\"bold\">Hochladen</span> der Daten in Autotask fortfahren.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Wenn die Validierung erfolgreich ist, können Sie mit dem <span class=\"bold\">Hochladen</span> der Daten zu ConnectWise fortfahren.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"Eine Wiederholung des Uploads ist nur im Fehlerfall möglich.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"Eine Wiederholung des Uploads ist nur im Fehlerfall möglich.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Bitte klicken Sie auf diese Schaltfläche, um die Konnektivität mit PSA zu testen, bevor Sie mit der Validierung fortfahren.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Nur neue Abonnementverlaufsprotokolle ausgewählter Kunden, falls vorhanden, werden für den Upload berücksichtigt. Ausgewählte Kunden können ausgeschlossen werden, wenn keine neuen Protokolle vorhanden sind.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_8":"Durch Klicken auf das Kontrollkästchen neben dem Titel können Sie Abonnementprotokolle für alle aktiven Kunden im System validieren.",
      "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Bitte wählen Sie Kunden aus der folgenden Liste aus, die Sie hochladen möchten",
      "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Aktivieren Sie dieses Kontrollkästchen nur, wenn Sie Abonnementprotokolle für alle aktiven Kunden im System validieren möchten.",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"PSA-Vereinbarung ist nicht mit C3-Kunden verknüpft",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"Externer Kunde ist nicht mit c3-Kunde verknüpft",
      "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Externes Produkt ist nicht mit C3-Produkt verknüpft",
      "PUBLIC_SIGNUP_ADD_TO_CART":"in den Warenkorb legen",
      "PUBLIC_SIGNUP_ADMIN_PORTAL":"Admin-Portal",
      "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"Werbeaktionen anwenden",
      "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Bitte um Unterstützung bei der öffentlichen Registrierung",
      "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"Autorisierungs-Zahlungsgateway wird noch nicht unterstützt. Bitte kontaktieren Sie den Support für Hilfe.",
      "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Zurück zur Produktliste",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Suche nach Produkt",
      "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Suche nach Produkten",
      "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Erstklassige Funktionen, die Ihnen zum Erfolg verhelfen.",
      "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Überschrift des öffentlichen Anmeldebanners",
      "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Informationen zur Rechnungsstellung",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"Einmalig",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"pro 3 Jahr",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"pro Monat",
      "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"pro Jahr",
      "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Warenkorb anzeigen",
      "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Enthält Addons",
      "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Enthält verknüpfte Produkte",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Bitte wählen Sie mindestens ein Microsoft-Angebot zum Auschecken",
      "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Bitte wenden Sie den Gutschein an, bevor Sie die Bestellung aufgeben",
      "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Geschäftsbedingungen.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie die NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen.",
      "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">NCE-Geschäftsbedingungen.</a>",
      "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"Stöbern Sie in unserem Sortiment und finden Sie die Produkte, die Sie brauchen. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Kehren Sie zu unserem Webshop zurück</strong></a> und legen Sie Artikel in Ihren Warenkorb.",
      "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Webseite",
      "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Bestätigen",
      "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Kontakt zum Support",
      "PUBLIC_SIGNUP_CONTACTUS_URL":"Kontaktiere uns",
      "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Mit dem Einkaufen fortfahren",
      "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Ich akzeptiere das Neueste",
      "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"Schließen Sie diesen Browser nicht, bevor der Vorgang abgeschlossen ist",
      "PUBLIC_SIGNUP_EDIT_TEXT":"Bestellung bearbeiten",
      "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"Ezidebit-Zahlungsgateway wird noch nicht unterstützt. Bitte kontaktieren Sie den Support für Hilfe.",
      "PUBLIC_SIGNUP_FAILED_WARNING":"Bitte entschuldigen Sie. Das Backend-System konnte das Konto aufgrund eines unbekannten Fehlers nicht einrichten. Bitte kontaktieren Sie den Support mit der BatchID",
      "PUBLIC_SIGNUP_FAILED_WARNING1":"um Ihnen weiter zu helfen.",
      "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "PUBLIC_SIGNUP_GO_TO_CART":"Zum Warenkorb gehen",
      "PUBLIC_SIGNUP_GOTO_HOME":"Zur Startseite gehen",
      "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"Anmeldung",
      "PUBLIC_SIGNUP_INVALID_QUANTITY":"Produkte im Warenkorb haben ungültige Mengenangaben",
      "PUBLIC_SIGNUP_LEARNER_PORTAL":"Lernenden-Portal",
      "PUBLIC_SIGNUP_LOGS_WARNING":"Die Bearbeitung kann eine Weile dauern und der Status wird auf dieser Seite angezeigt. Wenn Sie sich von der Seite entfernen oder den Browser-Benutzer geschlossen haben, halten wir Sie per E-Mail auf dem Laufenden.",
      "PUBLIC_SIGNUP_NEXT":"Nächster",
      "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"Keine Beschreibung vorhanden",
      "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Wir haben den Warenkorb erfolgreich erstellt",
      "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"Das Angebot ist bereits im Warenkorb vorhanden",
      "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Bitte geben Sie mindestens 1 Menge an",
      "PUBLIC_SIGNUP_ORGANIZATION_ID":"Organisations-ID",
      "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"Die Anmeldung wird nicht unterstützt, da das Zahlungsgateway noch nicht konfiguriert ist. Bitte kontaktieren Sie den Support für Hilfe.",
      "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Bezahlverfahren",
      "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Jetzt bezahlen",
      "PUBLIC_SIGNUP_PLACE_ORDER":"Bestellung aufgeben",
      "PUBLIC_SIGNUP_PORTLET_HEADER":"Anmelden",
      "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Wagen",
      "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Warenkorb überprüfen",
      "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Shop",
      "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Detail",
      "PUBLIC_SIGNUP_QTY":"ANZAHL",
      "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"Die Menge muss größer als 0 sein",
      "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"Menge ist erforderlich",
      "PUBLIC_SIGNUP_QUICK_CART":"Einkaufswagen schließen",
      "PUBLIC_SIGNUP_QUICK_VIEW":"Schnellansicht",
      "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"Durch Klicken auf „OK“ werden die zuvor hinzugefügten NCE-Produkte entfernt, da NCE-Produkte aus verschiedenen Marktregionen nicht gleichzeitig gekauft werden können.",
      "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Zurück zu Einzelheiten",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Hotels filtern",
      "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Filter löschen",
      "PUBLIC_SIGNUP_SIDE_CART":"Wagen",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"Aufsätze",
      "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"Aufsätze",
      "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Geschätzter Preis",
      "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":"Verknüpftes Produkt",
      "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Entfernen",
      "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"Anmelden & Kaufen",
      "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"Sie haben bereits einen MS-Mieter?",
      "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Bitte warten Sie, während wir Ihr Konto einrichten. Sie können Ihr Gerät wie gewohnt weiter benutzen. Wir werden Sie per E-Mail benachrichtigen, sobald die Einrichtung abgeschlossen ist.",
      "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Überspringen und fortfahren",
      "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Jetzt bezahlen",
      "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"Anmeldung erfolgreich! Sie können sich jetzt mit den an uns gesendeten Anmeldeinformationen beim C3-Portal anmelden.",
      "PUBLIC_SIGNUP_SUPPORT":"Unterstützung",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITION_1":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITION_2":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Öffentliche Registrierungsbedingungen 1",
      "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Allgemeine Geschäftsbedingungen für die Anmeldung 2",
      "PUBLIC_SIGNUP_TERMS_OF_USE":"Nutzungsbedingungen",
      "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Sortieren von A - Z",
      "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Sortieren Z - A",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Sortieren nach",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Preis: Hoch bis Niedrig",
      "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Preis niedrig bis hoch",
      "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Gutschein-Code eingeben",
      "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Die ausgewählten Angebote werden für das ausgewählte Land nicht unterstützt. Überprüfen Sie daher die ausgewählten Angebote noch einmal",
      "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Verlängert sich am.",
      "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Läuft aus am.",
      "PURCHASE_PRODUCT_MAPPING":"Produktzuordnung kaufen",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Kaufen Sie die Angebotspositionen automatisch.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Wenn wir das Kontrollkästchen deaktivieren, nachdem wir das Angebot genehmigt haben, muss der Partner/Wiederverkäufer die Produkte manuell im Namen des Kunden kaufen.",
      "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Wenn wir das Kontrollkästchen deaktivieren, nachdem wir das Angebot genehmigt haben, muss der Partner/Wiederverkäufer die Produkte manuell im Namen des Kunden kaufen.",
      "PurchaseCurrency":"Kaufwährung",
      "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Gekaufte Produktzuordnung",
      "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Addons",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Läuft aus am",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Bestellt am",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Produktname",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Menge",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"Erneuert weiter",
      "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Status",
      "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Menge bearbeiten",
      "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"Der Versuch, den Unterschied auszugleichen, ist fehlgeschlagen. Bitte wenden Sie sich an den Support, um Unterstützung zu erhalten",
      "QUANTITY_MISMATCH_ISSUE_FIXED":"Mengenabgleich erfolgreich abgeschlossen",
      "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Erwartete Anzahl",
      "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Anwendbar",
      "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Anzahl der Nachrichten",
      "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"Name der Warteschlange",
      "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Schneller Identitätswechsel",
      "QUICK_IMPERSONATION_HEADER_TEXT":"Wenn folgende Benutzer für den Identitätswechsel verfügbar sind, wählen Sie eine aus, um fortzufahren",
      "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Imitieren",
      "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Aktionen",
      "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Rolle",
      "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Benutzer",
      "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Aktivitäten",
      "QUICK_SIDEBAR_LABEL_ALERTS":"Warnungen",
      "QUICK_SIDEBAR_LABEL_MORE":"Mehr",
      "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Benachrichtigungen",
      "QUICK_SIDEBAR_LABEL_SETTINGS":"die Einstellungen",
      "QUICK_SIDEBAR_LABEL_USERS":"Benutzer",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Vielen Dank für Ihre Zustimmung zu QuickBooks. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf QuickBooks zugreift. Sie können die Rechnungen Ihrer Kunden in QuickBooks buchen.",
      "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen zum Hochladen von Rechnungen in QuickBooks in <b>{{applicationName}} </b>.",
      "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Bitte warten Sie, während wir Ihre QuickBooks-Einwilligung bearbeiten",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Der folgende Link wird in einer neuen Registerkarte geöffnet. Bitte stimmen Sie Ihren QuickBooks-Anmeldeinformationen zu. Wenn Sie bei der Erteilung der Einwilligung einen Fehler haben, wenden Sie sich bitte an unser Support-Team.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"QuickBooks Zustimmung zu erteilen",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Wenn Sie die Einwilligung bereits erteilt haben, klicken Sie auf die Schaltfläche <b>Token validieren </b>, um die Konnektivität mit QuickBooks zu überprüfen.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf diese Schaltfläche, um die Gültigkeit des vorhandenen QuickBooks-Aktualisierungstokens zu überprüfen.",
      "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Voraussetzungen für den Partner, um Kundenrechnungen an <b>QuickBooks Online </b>zu senden",
      "QUICKBOOKS_POST_BATCH_ID":"Quickbooks post Batch Id",
      "QUICKBOOKS_POST_LOGS_STATUS":"Quickbooks Post Logs Status",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Das vorhandene QuickBooks-Aktualisierungstoken ist ungültig. Bitte geben Sie Ihre Zustimmung erneut",
      "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Das vorhandene QuickBooks-Aktualisierungstoken ist gültig. Sie können mit dem Hochladen von Rechnungen fortfahren",
      "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Angebotsdetails",
      "QUOTE_ALL_SELECTION":"Wählen Sie Alle",
      "QUOTE_APPROVAL_CONFIRMATION":"Möchten Sie das Angebot wirklich genehmigen?",
      "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Angebot genehmigt und Bestellung erfolgreich aufgegeben.<br/> Bestell-ID ist <b>{{OrderNumber}}</b>",
      "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Angebot erfolgreich genehmigt.",
      "QUOTE_AUDIT_LOG_DELETED_BY":"Gelöscht von",
      "QUOTE_AUDIT_LOG_ID":"Angebots-ID",
      "QUOTE_AUDIT_LOG_NAME":"Zitatname",
      "QUOTE_AUDIT_LOG_QUOTE_NAME":"Zitatname",
      "QUOTE_BREADCRUMB_QUOTE_ADD":"Angebote erstellen",
      "QUOTE_BREADCRUMB_QUOTE_EDIT":"Zitate bearbeiten",
      "QUOTE_BREADCRUMB_QUOTE_LIST":"Zitate",
      "QUOTE_BUTTON_TEXT_AGE":"Alter",
      "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Zurück zur Angebotsliste",
      "QUOTE_BUTTON_TEXT_CANCEL":"Stornieren",
      "QUOTE_BUTTON_TEXT_CLOSE":"Schließen",
      "QUOTE_BUTTON_TEXT_COPY":"Kopie",
      "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"E-Mail mit Zitat schreiben",
      "QUOTE_BUTTON_TEXT_SUBMIT":"einreichen",
      "QUOTE_BUYER_INFO":"Käuferinformationen",
      "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Käuferinfo",
      "QUOTE_CHOOSE_BUYER_DETAILS":"Wählen Sie die Kontaktdaten des Käufers aus, die Sie in das Angebot aufnehmen möchten.",
      "QUOTE_CHOOSE_SELLER_DETAILS":"Überprüfen Sie die Angaben zu Ihrer Person und Ihrem Unternehmen, die im Angebot angezeigt werden.",
      "QUOTE_COMMENTS_TO_BUYERS":"Bemerkungen",
      "QUOTE_COMPANY":"Unternehmen",
      "QUOTE_CONTACT":"Kontakt",
      "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Es können bis zu drei Angebotskontakte hinzugefügt werden.",
      "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Fehler beim Kopieren des Textes:",
      "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL erfolgreich kopiert.",
      "QUOTE_COUNTERSIGNATURE":" Gegenzeichnung",
      "QUOTE_CREATE_A_LINE_ITEM":"Erstellen einer Position",
      "QUOTE_CREATED":"Angebot erstellt am",
      "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Positionsinformationen",
      "QUOTE_CUSTOM_LINE_ITEM_SAVE":"speichern",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Abrechnung",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Abrechnungshäufigkeit",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Beschreibung",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Rechnungsfälligkeitsdatum",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Endtermin",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Rechnungsdatum",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"Ist steuerpflichtig",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Name",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Preis",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Verkaufsart",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Anfangsdatum",
      "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Stückpreis",
      "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Bitte geben Sie einen Wert mit bis zu zwei Dezimalstellen ein.",
      "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"Das Angebot kann nicht genehmigt werden, da der Kunde gelöscht wurde.",
      "QUOTE_DATE":"Datum",
      "QUOTE_DOWNLOAD_AS_PDF":" Download als PDF",
      "QUOTE_EMAIL_ERROR":"Angebots-E-Mail-Fehler",
      "QUOTE_EMAIL_MODAL_HEADER":"Zitat E-Mail-Text",
      "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Beim Speichern des Angebotsnamens und des Kunden ist ein Fehler aufgetreten, da bereits ein Angebot im Entwurfs- oder veröffentlichten Zustand vorliegt.",
      "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Beim Speichern ist ein Fehler aufgetreten. Für den ausgewählten Kunden liegt bereits ein Angebot im Entwurfs- oder veröffentlichten Status vor.",
      "QUOTE_ERROR_NAME_NOT_UNIQUE":"Beim Speichern des Angebots ist ein Fehler aufgetreten, da der Angebotsname nicht eindeutig ist. Es gibt bereits ein anderes Angebot mit demselben Namen, das sich entweder im veröffentlichten Status oder im Entwurfsstatus befindet.",
      "QUOTE_EVENT_ERROR":"Error",
      "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Bitte geben Sie das Ablaufdatum ein",
      "QUOTE_EXPIRES":"Angebot läuft ab am",
      "QUOTE_INCLUDE":"Im Angebot enthalten",
      "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Kontakte",
      "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Kunden auswählen",
      "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Bemerkungen",
      "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Gegenzeichnung",
      "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
      "QUOTE_LABEL_TEXT_EMAIL":"Email",
      "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Verfallsdatum",
      "QUOTE_LABEL_TEXT_FIRST_NAME":"Vorname",
      "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Fügen Sie Platz für eine handschriftliche Unterschrift hinzu",
      "QUOTE_LABEL_TEXT_LAST_NAME":"Nachname",
      "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Keine Signatur",
      "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
      "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Einkaufsbedingungen",
      "QUOTE_LABEL_TEXT_QUOTE_NAME":"Zitatname",
      "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Ihre Änderungen wurden gespeichert",
      "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Signaturoptionen",
      "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Fügen Sie Platz für eine Gegenunterschrift ein",
      "QUOTE_LABEL_TEXT_TEMPLATE":"Schablone",
      "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Kunden hinzufügen",
      "QUOTE_LABEL_TEXT_WEB_LINK":"Kopieren Sie den unten stehenden Webseiten-Link, um ihn mit potenziellen Kunden zu teilen, oder schreiben Sie eine E-Mail mit dem beigefügten Angebot.",
      "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Bitte füllen Sie alle erforderlichen Felder aus, bevor Sie fortfahren.",
      "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Benutzerdefinierten Einzelposten hinzufügen",
      "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Planprodukt hinzufügen",
      "QUOTE_LINE_ITEM_HEADER":"Einzelposten",
      "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Totaler Rabatt",
      "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Zwischensumme",
      "QUOTE_LINE_ITEM_LABEL_TAX":"Gesamtsteuer",
      "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Gesamtwert des Angebots",
      "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Es dürfen maximal 50 Produkte hinzugefügt werden.",
      "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Es können maximal zehn Mengen hinzugefügt werden.",
      "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Bis zu 9999 Positionen können hinzugefügt werden",
      "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Es ist eine Mindestbestellmenge von einem erforderlich.",
      "QUOTE_LINE_ITEM_SUB_HEADER":"Überprüfen Sie die Position, die in Ihrem Angebot angezeigt werden soll",
      "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Zwischensumme",
      "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Menge",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Die angezeigten Steuern sind Richtwerte und werden zum Zeitpunkt der Rechnungserstellung endgültig festgelegt.",
      "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Steuertyp",
      "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Totaler Rabatt",
      "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Gesamtwert des Angebots",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Fügen Sie mindestens ein Element hinzu.",
      "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Speichern Sie die Änderungen an den Angebotspositionen, bevor Sie fortfahren.",
      "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Produkte für Angebot",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Selbstkostenpreis",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Angebotsname",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Menge",
      "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
      "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Einzelposten",
      "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Zusammenfassung",
      "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Kundenpläne",
      "QUOTE_LIST_BREADCRUMB_QUOTE":"Angebotsliste",
      "QUOTE_LIST_STATUS_PUBLISHED":"Rechnung veröffentlicht",
      "QUOTE_MOVE_BACK_TO_STATE":"Vorherige",
      "QUOTE_MOVE_TO_STATE":"Nächster",
      "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"Der Zitatname sollte weniger als 150 Zeichen lang sein.",
      "QUOTE_NAME_REQUIRED_ERROR":"Bitte geben Sie den Angebotsnamen ein",
      "QUOTE_ONE_TIME_SUBTOTAL":"Einmalige Zwischensumme:",
      "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Angebot genehmigen",
      "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Zitat löschen",
      "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Zitat bearbeiten",
      "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Zitat anzeigen",
      "QUOTE_OWNER":"Zitatbesitzer",
      "QUOTE_PDF_PURCHASE_TERM":"Einkaufsbedingungen",
      "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Dem Kunden zugewiesene Pläne",
      "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Bitte geben Sie die E-Mail Adresse ein.",
      "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Bitte geben Sie den Vornamen ein.",
      "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Bitte geben Sie den Nachnamen ein.",
      "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Bitte geben Sie die Telefonnummer ein.",
      "QUOTE_PREPARED_BY":"Hergestellt von:",
      "QUOTE_PRICE_SUMMARY":"Zusammenfassung",
      "QUOTE_PRICE_TOTAL":"Gesamt",
      "QUOTE_PRINTED_NAME":"Gedruckter Name",
      "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Plan Name",
      "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Produktname",
      "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Eigenschaften",
      "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Verkaufspreis",
      "QUOTE_READY_FOR_REVIEW":"Angebot zur Überprüfung bereit",
      "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Überprüfen und veröffentlichen",
      "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Verkäuferinformationen",
      "QUOTE_SENDER":"Angebotsabsender",
      "QUOTE_SIGNATURE":"Unterschrift",
      "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Unterschrift",
      "QUOTE_STATUS":"Status",
      "QUOTE_STATUS_APPROVED":"Zur Kundenbewertung zugelassen",
      "QUOTE_STATUS_DELETED":"Gelöscht",
      "QUOTE_STATUS_DRAFT":"Entwurf",
      "QUOTE_STATUS_EXPIRED":"Abgelaufen",
      "QUOTE_STATUS_OOPS_ERROR":"Hoppla!",
      "QUOTE_STATUS_ORDERPROCESSED":"Bestellung bearbeitet",
      "QUOTE_STATUS_PUBLISHED":"Rechnung veröffentlicht",
      "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"Wir können diese Seite nicht finden.",
      "QUOTE_TABLE_HEADER_TEXT_ACTION":"Aktion",
      "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Erstellungsdatum",
      "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Verfallsdatum",
      "QUOTE_TABLE_HEADER_TEXT_NAME":"Name",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Menge",
      "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Zitatbesitzer",
      "QUOTE_TABLE_HEADER_TEXT_STATUS":"Status",
      "QUOTE_TABLE_LABELS_USER_ROLES":"Rolle",
      "QUOTE_TABLE_LABELS_USERS":"Kunde(n)",
      "QUOTE_TABLE_PRICE":"Stückpreis",
      "QUOTE_TABLE_PRODUCT_SERVICE":"Produkt und Service",
      "QUOTE_TABLE_QUANTITY":"Menge",
      "QUOTE_TEXT_EMAIL_DETAILS":"Hallo, <br/><br/>Ihr Angebot steht zur Überprüfung bereit.<br/><br/>Wichtige Angaben:<br/><br/>Angebotsname: {{quoteName}}<br/><br/>Gültig bis: {{expirationDate}} <br/><br/> Sie können über den folgenden Link auf Ihr Angebot zugreifen und es überprüfen.<br/><br/>{{quoteURL}}<br/><br/>",
      "RCON_APPLY_PROMOTIONS_NCE":"Förderung anwenden",
      "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"Zurück zu RD-Bereitstellungen",
      "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Stellen Sie eine neue RD bereit",
      "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Bereitstellen",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Einzelheiten",
      "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"RD-Einstellungen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Domainnamen hinzufügen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Administrator-Passwort",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Administrator-Benutzername",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Bereitstellungsname",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Gateway-DNS-Label-Präfix",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Name der öffentlichen IP-Adresse des Gateways",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"Bild-SKU",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Wählen Sie Bild-SKU",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Ort",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Ort auswählen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Anzahl der Rd-Instanzen",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Ressourcengruppe",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Wählen Sie Abonnement",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"VM-Größe",
      "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Wählen Sie Bild-SKU",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"RD-Bereitstellungen",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Stellen Sie eine neue RD bereit",
      "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Bereitstellungsstatus aktualisieren",
      "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"RD-Bereitstellungen",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Verwalten",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Bereitgestellt am",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Bereitstellungsname",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Ort",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Ressourcengruppe",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Status",
      "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
      "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"Zurück zu RD-Bereitstellungen",
      "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Bereitstellungsdetails",
      "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Einzelheiten",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Bereitstellungsname",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Umgebung",
      "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Bereitgestellt am",
      "RDS_DEPLOYMENT_STARTED_SUCCESS":"Die RD-Bereitstellung wurde erfolgreich gestartet",
      "READ_THROUGH":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen durch",
      "REASON_FOR_FAILURE":"Grund für das Scheitern",
      "REASON_FOR_UPDATE":"Grund für die Aktualisierung",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Das erneute Laden des Abstimmungsberichts ist fehlgeschlagen",
      "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Neustart des Abstimmungsberichts erfolgreich",
      "RECON_CUSTOMER_REPORT_RELOADING":"Abgleichsbericht wird neu geladen",
      "RECON_PRODUCT_INPROVISION_TOOLTIP":"Das Produkt hat den Status In Bereitstellung",
      "RECON_PRODUCT_ONHOLD_TOOLTIP":"Produkt wartet auf Zulassung",
      "RECON_REPORT_COLLAPSE_ALL":"Alles einklappen",
      "RECON_REPORT_EXPAND_ALL":"Alle erweitern",
      "RECON_REPORT_MISMATCH_COUNT_BADGE":"Nichtübereinstimmungen",
      "RECON_REPORT_RELOAD":"Neu geladener Abstimmungsbericht",
      "RECON_REPORT_RELOAD_INITIAT_FAIL":"Die Initiierung des erneuten Ladens des Abstimmungsberichts ist fehlgeschlagen",
      "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Das Neuladen des Abstimmungsberichts wurde eingeleitet",
      "RECON_REPORT_SEARCH_BUTTON_TEXT":"Suche",
      "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Suche nach Angebot, Abonnement-ID",
      "RECON_REPORT_SHOW_FIXED_INACTIVE":"Behoben inaktiv anzeigen",
      "RECON_REPORT_SHOW_MISMATCH":"Nur Diskrepanzen anzeigen",
      "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" Azure-Abonnement-ID:",
      "RECON_TABLE_TEXT_BILLING_CYCLE":"Rechnungskreislauf :",
      "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Anbieterabonnement",
      "RECON_TABLE_TEXT_REGION":" Region:",
      "RECON_TABLE_TEXT_RESERVATION_ID":" Reservierungs-ID:",
      "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" Reservierungsauftrags-ID:",
      "RECON_TABLE_TEXT_SUBSCRIPTION_ID":"Abonnement :",
      "RECON_TABLE_TEXT_TERM_DURATION":"Gültigkeit :",
      "RECON_TABLE_TEXT_TRIAL_OFFER":"Schnupperangebot",
      "RECONCILIATION_CUSTOMER_NAME":"Kundenname",
      "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"ANBIETER",
      "RECONCILIATION_MISSMATCH":"Versöhnungsfehler",
      "RECONCILIATION_NOTES":"Anmerkungen",
      "RECONCILIATION_NOTES_MISMATCH":" Elemente in <span class='text-danger'>rot</span> weisen auf eine Nichtübereinstimmung hin",
      "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Artikel in <span class='text-mediumvioletred'>Violettrot</span> weisen auf eine Diskrepanz im Zusammenhang mit der Werbeaktion hin. Wenden Sie sich an das Support-Team, um den Werbevorteil an den Endkunden weiterzuleiten",
      "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"Artikel in <span class='red'>rot/span> weisen auf eine Mengeninkongruenz hin",
      "RECONCILIATION_NOTES_RI_INVOICE":"Nach dem Onboarding eines RI werden alle zusätzlichen Reservierungen mit demselben Angebot innerhalb der integrierten Reservierungs-Bestell-ID zum integrierten Preis in Rechnung gestellt.",
      "RECONCILIATION_NOTES_SUSPENDED":"Artikel <span class='strike-through'>durchgestrichen</span> weisen auf gesperrte Abonnements hin",
      "RECONCILIATION_PROVIDER":"Anbieter",
      "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Zahlungsbetrag",
      "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"Sind Sie sicher, dass Sie eine Zahlung über <%= Betrag%> (<%= CurrencyCode%>) für den Kunden verbuchen möchten?<br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Unterstützung bei Zahlungen benötigen, bei denen dies nicht der Fall ist auftauchen oder irrtümlich aufgezeichnet wurden.</small>",
      "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Währungscode",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Wählen Sie Kunde",
      "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Kunden",
      "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Der Kunde muss die Zahlung verbuchen.",
      "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Vorauszahlung aufzeichnen",
      "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Bemerkungen",
      "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Währungscode",
      "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Wählen Sie Kunde",
      "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Zahlung erfolgreich erfasst",
      "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Zahlungsdatum",
      "RECORD_ID":"Datensatz-ID",
      "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Datensatz-ID vom externen Dienst erhalten",
      "RECORD_MANUAL_PAYMENT_CONFIRMATION":"Möchten Sie wirklich eine Zahlung über <%= currencySymbol%> <%= Betrag%> auf dieser Rechnung verbuchen?<br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder wurden irrtümlich aufgezeichnet.</small>",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Entitätsname",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Name",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importieren",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Zahlungen importieren",
      "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Schablone",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Katalog",
      "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Vorlage herunterladen",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Zahlungen importieren",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Error",
      "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Erfolg",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"C3Id",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Zahlungsbetrag",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Zahlungsdatum",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Name",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Bemerkungen",
      "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
      "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Katalog validieren",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Error",
      "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Erfolg",
      "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Der Zahlungsbetrag sollte größer als 0,00 sein.",
      "RECORD_PAYMENT_PAID_DATE":"Zahlungsdatum",
      "RECURRENCES_MAX_ERROR":"Der Wert muss kleiner oder gleich 11 sein.",
      "RECURRENCES_MIN_ERROR":"Der Wert muss größer oder gleich 0 sein",
      "RECURRENCES_TOOLTIP":"Wie oft der Rabattgutschein angewendet werden kann",
      "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL",
      "REFRESH_TOKEN":"Token aktualisieren",
      "REFRESH_TOOLTIP_TEST_PAYMENT":"Aktualisierung",
      "REGISTER_CAPTION_TEXT_THANK_YOU":"Vielen Dank, dass Sie sich für <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}} entschieden haben.</span> Bitte füllen Sie die folgenden Informationen aus, um Ihr neues Abonnement zu bearbeiten.",
      "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Wenn Sie ein vorhandenes Microsoft-Konto haben, das nicht bei uns ist, <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">wenden Sie sich bitte an uns</a>, um Unterstützung zu erhalten.",
      "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Bitte geben Sie einen gültigen Gutschein ein",
      "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche.",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Email",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Bitte geben Sie eine gültige E-Mail-ID an. Nach Abschluss der Registrierung senden wir eine E-Mail mit Benutzername und Passwort",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Vorname",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Nachname",
      "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Telefonnummer",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Stadt",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Name der Firma",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Linie 1",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Zeile 2",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"Um zu beginnen, müssen Sie ein Konto mit Ihrem Firmennamen gefolgt von .onmicrosoft.com erstellen. Wenn Ihre Organisation über eine eigene benutzerdefinierte Domäne verfügt, die Sie ohne .onmicrosoft.com verwenden möchten, können Sie dies später in Office 365 Admin Portal ändern.",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Was ist das",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Zustand",
      "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Postleitzahl",
      "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Vereinbarungstermin",
      "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
      "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Ich akzeptiere das Neueste",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Konfigurieren Sie Ihren Plan",
      "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Wählen Sie für jedes Angebot im Plan, das Sie hinzufügen möchten, eine Mindestmenge von 1 aus",
      "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Hauptansprechpartner",
      "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Firmenprofil",
      "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
      "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
      "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Bankkonto",
      "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Kreditkarte",
      "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Gutschein entfernen",
      "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Bitte geben Sie einen gültigen Gutschein ein",
      "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Angewendeter Gutscheincode: <b>{{CouponCode}} </b>",
      "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Kreditkartendetails",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Zahlungsart",
      "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Bitte wählen Sie Zahlungsart",
      "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Planübersicht",
      "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Wiederkehrende Gebühr (alle 1 Monat)",
      "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"dann <span class='fett'>${{totalCount}} </span> bei der ersten Erneuerung am {{nextBillingDate}}",
      "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Gesamt",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Hinweis:",
      "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Die Gebühren für den Kauf werden für den Rest der Tage im aktuellen Abrechnungszyklus anteilig berechnet.",
      "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Lizenzen",
      "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"Sie müssen angeben, ob das ACH-Konto ein Geschäftskonto ist oder nicht!",
      "REGISTER_REGISTERUSER_CREATED_USER":"Benutzer erfolgreich erstellt",
      "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Die Domainverfügbarkeit kann nicht überprüft werden",
      "REGISTER_REGISTERUSER_VALID_DOMAIN":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche.",
      "REGISTER_REGISTRATION_STATUS_CAPTION":"Registrationsstatus",
      "REGISTER_REGISTRATION_STATUS_HEADER":"Ihre Registrierung wurde erfolgreich bearbeitet. Bitte melden Sie sich mit den folgenden Anmeldeinformationen beim Portal an:",
      "REGISTER_REGISTRATION_STATUS_LINE_1":"Nutzername",
      "REGISTER_REGISTRATION_STATUS_LINE_2":"Passwort",
      "REGISTER_REGISTRATION_STATUS_LINE_3":"Für den Fall, dass Sie das Kopieren von hier verpasst haben, wurde Ihnen eine E-Mail mit den oben genannten Details gesendet",
      "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Verarbeiten Sie meine Bestellung",
      "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Bitte geben Sie den Grund für die Ablehnung an",
      "REJECT_ALL_PRODUCT_POPUP_BUTTON":"Ablehnen",
      "REJECT_ALL_PRODUCT_POPUP_HEADER":"Alle Produkte ablehnen",
      "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Alle Produkte ablehnen",
      "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Bitte erläutern Sie den Grund für die Ablehnung der Anfrage",
      "REJECT_PRODUCT_COMMENT_HEADER":"Produkt ablehnen",
      "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Bitte geben Sie die Gründe an, warum dieses Produkt abgelehnt werden sollte",
      "REJECT_PRODUCT_POPUP_BUTTON":"Ablehnen",
      "REJECTED_BY":" Zurückgewiesen von",
      "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Bitte geben Sie einen Grund für die Ablehnung dieses Produkts ein",
      "RELEASE_PRODUCT_OWNERSHIP":"Produktbesitz freigeben",
      "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Freigabe des Produktbesitzes fehlgeschlagen",
      "RELEASE_SEED_ERROR":"Sie können die Menge nur während der Freigabe reduzieren. Bitte versuchen Sie es erneut",
      "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"Es wurde versucht, mehr als die verfügbare Menge für \"{{childName}}\" zu aktualisieren",
      "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"Es wurde versucht, mehr als die verfügbare Menge für das übergeordnete Element „{{parentName}}“ für „{{childName}}“ zu aktualisieren",
      "RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
      "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'> Bei der Verarbeitung der Daten für <b>{{firstName}} </b>ist ein Fehler aufgetreten. Der Fehler lautet: {{errorMessage}} </span><br/>",
      "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'> Datensätze wurden erfolgreich für <b>{{firstName}} </b></span><br/> verarbeitet",
      "RELOAD_RECONCILIATION_STATUS":"Versöhnungsstatus neu laden",
      "RELOAD_TOOLTIP_REVENUE":"Neu laden",
      "reLoadingCustomersProfileData":"Neuladen von Provider-Kundenprofilen",
      "REMOVE_ALL_SELECTED_RGS":"Möchten Sie alle ausgewählten Ressourcengruppen entfernen?",
      "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Entfernen",
      "REMOVE_PARTNER_PRODUCT_ICON":"Entfernen",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Produkt entfernen",
      "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"Sind Sie sicher, dass Sie das Produkt entfernen möchten?",
      "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Sind Sie sicher, dass Sie den Zugriff für diesen Benutzer widerrufen möchten?",
      "RENEWAL_ALL_DAYS":"Alles",
      "RENEWAL_DAYS_TEXT":"Tage",
      "REPORT_ACTIONS_DOWNLOAD_TEXT":"Herunterladen",
      "REPORT_ACTIONS_SEND_EMAIL_TEXT":"E-Mail senden",
      "REPORT_ACTIONS_TEXT":"Aktionen",
      "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Alle Kunden",
      "REPORT_FILE_INVALID_EMAILS_ERROR":"Bitte eine gültige Email eingeben",
      "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Dieses Feld wird benötigt",
      "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
      "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
      "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"E-Mail senden",
      "REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
      "REPORT_FILE_TYPES_XML_FORMAT":"XML",
      "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Zeitraum auswählen",
      "REPORT_INTERVAL_TEXT_CUSTOME":"BENUTZERDEFINIERT",
      "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"Letzte 12 Monate",
      "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"Letzte 3 Monate",
      "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"Letzte 6 Monate",
      "REPORT_INTERVAL_TEXT_TILL_DATE":"Bis zum Datum",
      "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Rechnungs- und Gebührenbericht zu erhalten",
      "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Zurück zum Berichtsverbrauch",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Upload-Verlauf anzeigen",
      "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Upload-Verlauf anzeigen",
      "REPORT_POPUP_ACTIONS_TEXT":"Aktionen",
      "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
      "REPORT_POPUP_EMAIL_TEXT":"Email",
      "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Wählen Sie Alle",
      "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"E-Mail senden",
      "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Abonnementverlaufsbericht abzurufen",
      "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste zum Abonnementverlauf zu erhalten.",
      "REPORT_SELECT_CUSTOMER_All":"Alle Kunden",
      "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Wählen Sie Kunde",
      "REPORT_SELECT_RESELLER_ALL":"Alle Wiederverkäufer",
      "REPORT_SEND_EMAIL_TEXT":"Email",
      "REPORT_TEXT_FROM":"Von",
      "REPORT_TEXT_TO":"Zu",
      "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtern nach  :",
      "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"Kosten für den Partner sind erforderlich, wenn die Abrechnungsart „Prozentsatz“ lautet, und optional für die Abrechnungsart „Einheit“.",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Katalog",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importieren",
      "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Schablone",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Herunterladen",
      "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Beim Herunterladen der Datei ist ein Fehler aufgetreten",
      "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Vorlage herunterladen",
      "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Upload-Verlauf herunterladen",
      "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
      "REPORT_USAGE_INSTRUCTIONS_PART_1":"Laden Sie den Katalog herunter, um Kunden- und Produkt-IDs von nutzungsbasierten Partnerangeboten zu erhalten.",
      "REPORT_USAGE_INSTRUCTIONS_PART_2":"Laden Sie die Vorlage herunter und füllen Sie die Verwendungsdetails für Produkte im Katalog aus.",
      "REPORT_USAGE_INSTRUCTIONS_PART_3":"Stellen Sie sicher, dass die Daten die folgenden Regeln einhalten: <br />&nbsp;&nbsp <b>Einheiten</b> : Zahl <br />&nbsp;&nbsp <b>Maßeinheit</b> : Text <br />&nbsp; &nbsp <b>CostToPartner</b> : Dezimalzahl <br />&nbsp;&nbsp <b>CurrencyCode</b> : Text (ISO-Standard-Währungscode.) <br />&nbsp;&nbsp <b>UsageDate</b> : Datum (JJJJ-MM-TT)",
      "REPORT_USAGE_INSTRUCTIONS_PART_4":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
      "REPORT_USAGE_INSTRUCTIONS_PART_5":"Klicken Sie auf <strong class=\"text-uppercase\">Eingabe validieren</strong>, damit das System die Eingabe validiert.",
      "REPORT_USAGE_INSTRUCTIONS_PART_6":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
      "REPORT_USAGE_INSTRUCTIONS_PART_7":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
      "REPORT_USAGE_INSTRUCTIONS_PART_8":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
      "REPORT_USAGE_NOTE":"Hinweis :",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
      "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
      "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Kosten für Partner",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Währung",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Kundennummer",
      "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
      "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"Produkt ID",
      "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Maßeinheit",
      "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Einheiten",
      "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Verwendungsdatum",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
      "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
      "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Eingabe validieren",
      "REPORT_USER_LICENSE_ASSIGNMENT":"Der Bericht zur Benutzerlizenzzuweisung wird an die angegebene E-Mail-Adresse gesendet.",
      "reportusage":"Nutzung melden",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Um eine Reseller-Beziehung mit einem Kunden anzufordern, kopieren Sie den folgenden Text, einschließlich der URL, und fügen Sie ihn in eine E-Mail ein. Bearbeiten Sie den Text bei Bedarf und senden Sie die E-Mail an Ihren Kunden.",
      "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Schließen Sie delegierte Verwaltungsrechte für Azure Active Directory und Office 365 ein.",
      "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"E-Mail-Text",
      "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Anfrage für Reseller-Beziehung",
      "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Anfrage für Bulk Onboard initiiert von",
      "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"Die Anfrage zum Laden von Werbeaktionen befindet sich in der Warteschlange",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Anforderung zum Hochladen von Rechnungen in Quickbooks, initiiert von",
      "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"Die Anfrage zum Hochladen von Rechnungen zu Xero wird von gestellt",
      "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Angeforderte Statusaktualisierung des NI-Angebots",
      "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Anfrage zum Hochladen von Rechnungen in QuickBooks akzeptiert",
      "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Anfrage zum Hochladen von Rechnungen zu Xero akzeptiert",
      "REQUESTED_PRODUCT_STATUS":"Neuer Status",
      "requestForResellerRelationship":"Anfrage für Reseller-Beziehung",
      "REQUIRED_FIELD_TEXT":"Dieses Feld wird benötigt",
      "REQUIRED_PATTERN_PHONE":"Erlaubt sind nur Zahlen und +",
      "REQUIRED_PATTERN_TEXT":"Sonderzeichen wie `~!@#{}|/<>' sind nicht erlaubt",
      "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Beim Exportieren der Rechnungspositionen des Wiederverkäufers ist ein Fehler aufgetreten",
      "RESELLER":"(Wiederverkäufer)",
      "Reseller":"Wiederverkäufer",
      "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Einstellung",
      "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Ist steuerpflichtig",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
      "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Plan löschen",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Planangebote überprüfen",
      "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Plan speichern",
      "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
      "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
      "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Erfolg",
      "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Preis erfolgreich aktualisiert",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Menge",
      "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Verwendung",
      "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden",
      "RESELLER_AZURE_MACRO_MACRO_TYPE":"Makro",
      "RESELLER_AZURE_MACRO_PRICE_TYPE":"Preis",
      "RESELLER_AZURE_MACRO_VALUE":"Makrowert",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Wiederverkäufer",
      "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Zusammenfassung der Einnahmen und Kosten des Wiederverkäufers",
      "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Zurück zur Liste",
      "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
      "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Neuen Anbieter verknüpfen",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Reseller hinzufügen",
      "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Neu",
      "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Bestehend (mehrfach)",
      "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Reseller hinzufügen",
      "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Kontaktdetails",
      "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Zahlungsart",
      "RESELLER_CAPTION_TEXT_RESELLER":"Wiederverkäufer",
      "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Reseller-Abrechnungsprofil",
      "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Reseller Details",
      "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Reseller-Profil",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Aktivieren Sie diese Option, um Bearbeitungsgebühren auf Kreditkartenzahlungen anzuwenden.",
      "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"Kreditkartenbearbeitungsgebühr einschließen?",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Prozentuale Gebühr für die Kreditkartenabwicklung.",
      "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Kreditkartenbearbeitungsgebühr %",
      "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Durch Aktivieren dieses Flags wird verhindert, dass Kunden die Abonnementmenge reduzieren",
      "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Dem Wiederverkäufer erlauben, die Transaktion für seine Rechnung(en) zu initiieren",
      "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Wählen Sie aus, ob die Werbeaktion für den Wiederverkäufer gelten soll oder nicht",
      "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Ermöglichen Sie Kunden die Verwaltung von Azure-Abonnements",
      "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Ermöglichen Sie Kunden, Gruppen über Azure-Abonnements zu definieren",
      "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Markieren Sie (%), um es auf die Azure-Nutzung des Resellers anzuwenden. z.B: Geben Sie 1,15 ein, wenn der Aufschlag 15% betragen soll",
      "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Zur internen Bezugnahme. Diese ID kann verwendet werden, um den Kunden mit einem Datensatz in Ihrem internen Buchhaltungssystem zu verknüpfen",
      "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Tag des Monats, an dem Rechnungen automatisch genehmigt werden",
      "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Tag des Monats, an dem Zahlungen automatisch verarbeitet werden",
      "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Tag des Monats, an dem Zahlungen automatisch verarbeitet werden",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Fälligkeitsdatum für Rechnungen",
      "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"Anzahl der für die Rechnungszahlung fälligen Tage",
      "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Tag des Monats, an dem Rechnungen automatisch abgeschlossen werden",
      "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Adresse automatisch als Standardadresse für den Wiederverkäufer festgelegt.",
      "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte E-Mail-Adresse automatisch als Standard-E-Mail-Adresse für den Wiederverkäufer festgelegt.",
      "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Telefonnummer automatisch als Standardtelefonnummer für den Wiederverkäufer festgelegt.",
      "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die einmaligen Rechnungsposten des Wiederverkäufers angewendet werden soll.",
      "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die unbefristeten Softwareabonnements des Wiederverkäufers angewendet werden soll",
      "RESELLER_CONFIG_DESC_PO_NUMBER":"Bestellnummer für Reseller definieren",
      "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Aktivieren Sie die Funktion zum Übertragen von Rechnungen an externe Buchhaltungsdienste.",
      "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Aktivieren Sie dieses Flag, wenn das System Zahlungen verarbeiten soll (CC/ACH, falls zutreffend).",
      "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Reseller-Azure-Reservierung angewendet werden soll.",
      "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Der Reseller erhält die E-Mail mit den Rechnungsdetails, sobald die Rechnung genehmigt wurde und wenn E-Mail-Benachrichtigungen aktiviert sind",
      "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Der Reseller erhält eine E-Mail-Benachrichtigung über Rechnungen mit Nullbeträgen",
      "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Reseller-Softwareabonnements angewendet werden soll.",
      "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Wir können diese Anfrage nicht bearbeiten, da Transaktionen deaktiviert sind.",
      "RESELLER_CONFIG_DESC_TAX_CODE":"Definieren Sie das Steuerkennzeichen für Wiederverkäufer",
      "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Drittanbieter-Abonnements des Wiederverkäufers anzuwenden ist.",
      "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Ermöglichen Sie dem Kunden, Sitzplätze zu reduzieren",
      "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Dem Wiederverkäufer erlauben, die Transaktion zu initiieren",
      "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Bewerben Sie die Werbeaktion auf den Wiederverkäufer",
      "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Azure aktivieren?",
      "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Azure für Unternehmen aktivieren?",
      "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Markieren Sie sich für die Azure-Verwendung",
      "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Rechnungsreferenz-ID",
      "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Tag, an dem Wiederverkäufer Rechnungen sehen können",
      "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Tag für die automatische Zahlung",
      "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Tag für die automatische Zahlung",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Fälligkeitsdatum für Rechnungen",
      "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Fälligkeitstage für Rechnungen",
      "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Tag, an dem die Rechnungen abgeschlossen sind",
      "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Weisen Sie automatisch eine neue Adresse als Standard zu",
      "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Weisen Sie automatisch eine neue E-Mail als Standard zu",
      "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Weisen Sie automatisch eine neue Telefonnummer als Standard zu",
      "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Aufschlag für einmalige Rechnungsposten von Microsoft",
      "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag für unbefristete Software",
      "RESELLER_CONFIG_DISP_PO_NUMBER":"PO #",
      "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Buchen Sie Rechnungen an einen externen Buchhaltungsdienst",
      "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Rechnungen automatisch bezahlen?",
      "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
      "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Rechnungs-E-Mails automatisch senden",
      "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Senden Sie Nullbetragsrechnungen",
      "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Software-Abonnement",
      "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Stoppen Sie alle Transaktionen",
      "RESELLER_CONFIG_DISP_TAX_CODE":"Steuer-Code",
      "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Dritte",
      "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorieren Sie den Reseller vom externen Dienst",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Rechnungsnummern beginnen mit diesem Text",
      "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Rechnungsnummerntext",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Ermöglicht dem Benutzer, unbezahlte Beiträge bis zur aktuellen Rechnung anzuzeigen",
      "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Zeigen Sie unbezahlte Beiträge in der Rechnung an",
      "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Die Reseller-Konfiguration wurde erfolgreich zurückgesetzt",
      "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Reseller-Konfiguration erfolgreich gespeichert",
      "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Sie haben keinen Zugriff zum Bearbeiten",
      "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Reseller-Konfiguration erfolgreich aktualisiert",
      "RESELLER_CONFIGURATION_OF":"Reseller-Konfiguration von",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Sehen Sie sich schnell Ihre Geschäftserlöse an",
      "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilität",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Zusammenfassung Ihrer Forderungen",
      "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Debitorenbuchhaltung",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Zahlungen müssen noch eingezogen werden",
      "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Vergangene Gebühren",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Ihre aktiven Kunden",
      "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Kunden",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Benachrichtigung über eine ausstehende Rechnung",
      "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Rechnung",
      "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Im System ist bereits ein Reseller mit der angegebenen mpn-ID vorhanden. Bitte überprüfen Sie die angegebene mpn-ID.",
      "RESELLER_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
      "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
      "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
      "RESELLER_IMPERSONATE":"Imitieren",
      "RESELLER_IMPERSONATE_USER_ROLE":"Imitieren",
      "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"sich mit eigener Rolle ausgeben",
      "RESELLER_INVOICE":"Rechnung",
      "RESELLER_INVOICE_TEXT_LINE":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Vielen Dank!",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Rechnungsstatus erfolgreich aktualisiert",
      "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Zahlung erfolgreich erfasst",
      "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
      "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Rechnungen",
      "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Rechnungen",
      "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
      "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Rechnungen",
      "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Rechnungsposten",
      "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
      "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Anschrift Zeile 1",
      "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Adresszeile 2",
      "RESELLER_LABEL_TEXT_BANK_NAME":"Bank Name",
      "RESELLER_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
      "RESELLER_LABEL_TEXT_CITY":"Stadt",
      "RESELLER_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
      "RESELLER_LABEL_TEXT_COUNTRY":"Land",
      "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
      "RESELLER_LABEL_TEXT_CVV":"CVV",
      "RESELLER_LABEL_TEXT_EMAIL":"Email",
      "RESELLER_LABEL_TEXT_FIRST_NAME":"Vorname",
      "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
      "RESELLER_LABEL_TEXT_LAST_NAME":"Nachname",
      "RESELLER_LABEL_TEXT_MONTH":"Monat",
      "RESELLER_LABEL_TEXT_MPN_ID":"MPN-ID",
      "RESELLER_LABEL_TEXT_NAME":"Name",
      "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
      "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
      "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
      "RESELLER_LABEL_TEXT_PLAN_ID":"Planen",
      "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
      "RESELLER_LABEL_TEXT_STATE":"Zustand",
      "RESELLER_LABEL_TEXT_YEAR":"Jahr",
      "RESELLER_LABEL_TEXT_ZIP":"Postleitzahl",
      "RESELLER_LIST_PAYMENT_METHOD":"Bezahlverfahren",
      "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
      "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
      "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Software-Abonnement",
      "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Drittanbieterabonnements",
      "RESELLER_NAME_UPDATE_FAILURE":"Die Aktualisierung des Resellernamens ist fehlgeschlagen",
      "RESELLER_NAME_UPDATE_SUCCESS":"Erfolgreiche Aktualisierung des Resellernamens",
      "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Der Reseller ist keinem Account Manager zugeordnet",
      "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"Kein Reseller in CSP gefunden. Bitte überprüfen Sie die angegebene mpn-ID.",
      "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"In den hochgeladenen Daten wurden Fehler festgestellt. Laden Sie die Fehler herunter, beheben Sie sie und versuchen Sie es erneut.",
      "RESELLER_ONBOARD_UPLOAD_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Bitte geben Sie an, ob sich die ACH-Details auf ein Geschäftskonto beziehen",
      "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"MPN-ID des Wiederverkäufers",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Abrechnung per E-Mail",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"Land",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"Ich würde",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"MPN-ID",
      "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Name",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Erfolg",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Zahlungsdetails gespeichert",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Zahlungsprofil gelöscht",
      "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Zahlungsprofil Auf Standard setzen",
      "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Möchten Sie diese Zahlungsoption wirklich löschen?",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makro definieren und mit allen Angeboten speichern",
      "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Fehlende Angebote ergänzen",
      "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Plan mit allen Angeboten speichern",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ausgewählte Abrechnungszeiträume",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Ausgewählte Kategorien",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Ausgewählte Anbieterkategorien",
      "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Ausgewählte Anbieter",
      "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Unterstützte Märkte",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategorien",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Verwendungsmakro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Verwendungsmakrowert",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makrowert",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Anbieterkategorien",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Anbieter",
      "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Servicetypen",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Anfrage zum Klonen des Reseller-Plans wird angenommen und bearbeitet",
      "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Die Anfrage zur Erstellung eines neuen Reseller-Plans mit allen Angeboten wird angenommen und bearbeitet",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Preisfestlegung",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Wählen",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Makro anwenden, um die neuen Preise zu definieren",
      "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Kopieren Sie die Preise aus dem Source Reseller Plan",
      "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Ihre Änderungen wurden erfolgreich übermittelt",
      "RESELLER_PLAN_DISABLE_BUTTON":"Deaktivieren Sie den Plan",
      "RESELLER_PLAN_ENABLE_BUTTON":"Aktivieren Sie den Plan",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"Das Angebot wurde erfolgreich eingestellt",
      "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Stellen Sie das Angebot ein",
      "RESELLER_PLAN_SELECTED_OFFERS":"Ausgewählte Angebote",
      "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Pläne",
      "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Stornieren",
      "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klonplan",
      "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Plan erstellen",
      "RESELLER_PLANS_CAPTION_1":"Neuen Plan hinzufügen",
      "RESELLER_PLANS_CAPTION_2":"Details zum Plan -",
      "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Pläne",
      "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Aussicht",
      "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Preise und Angebote",
      "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Laden Sie die Preis- und Angebotsliste herunter",
      "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Preise und Angebote",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Art der Lizenzvereinbarung",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Listenpreis",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Angebotsname",
      "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Gültig ab",
      "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Beim Exportieren der Preisliste und der Angebotsliste für Wiederverkäufer ist ein Fehler aufgetreten",
      "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Organisationsprofil",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
      "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
      "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
      "RESELLER_PROFILE_CUSTOMER_PROFILE":"Kundenprofil",
      "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Adresse hinzufügen",
      "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"E-Mail hinzufügen",
      "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Telefon hinzufügen",
      "RESELLER_PROFILE_PARTNER_ADDRESS":"Adressen",
      "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Grundlegende Details",
      "RESELLER_PROFILE_PARTNER_CANCEL":"Stornieren",
      "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Name der Firma",
      "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"E-mailadressen",
      "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Vorname",
      "RESELLER_PROFILE_PARTNER_LAST_NAME":"Nachname",
      "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Grundlegende Details erfolgreich aktualisiert",
      "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Keine Aufzeichnungen gefunden!",
      "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Telefonnummern",
      "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Möchten Sie diesen Datensatz wirklich löschen?",
      "RESELLER_PROFILE_PARTNER_SUBMIT":"einreichen",
      "RESELLER_PROFILE_PAYMENT_ADD":"Hinzufügen",
      "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Bankkonto",
      "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Kreditkarte",
      "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Keine Profile gefunden. Fügen Sie ein neues Zahlungsprofil hinzu, indem Sie auf die Schaltfläche HINZUFÜGEN klicken",
      "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Zahlungsprofile",
      "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Erstellungsdatum des Kontos:",
      "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Rechnungsadresse",
      "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"C3 Onboarding-Datum",
      "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Reseller-Profil",
      "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Lieferanschrift",
      "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Benutzerkonten",
      "RESELLER_PROFIT_BILLED_AMOUNT":"Dem Wiederverkäufer in Rechnung gestellter Betrag",
      "RESELLER_PROFIT_COST_ON_PARTNER":"Kosten für den Händler",
      "RESELLER_PROFIT_COST_ON_RESELLER":"Kosten für den Wiederverkäufer",
      "RESELLER_PROFIT_CUSTOMER_NAME":"Kundenbetrag",
      "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Verkäufer Name",
      "RESELLER_PROFIT_PRODUCT_NAME":"Produktname",
      "RESELLER_PROFIT_PROFIT_AMOUNT":"Vertriebsgewinn",
      "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Vom Wiederverkäufer in Rechnung gestellter Betrag",
      "RESELLER_PROFIT_RESELLER_NAME":"Verkäufer Name",
      "RESELLER_PROFIT_SUMMARY_REPORT":"Bericht herunterladen",
      "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"Keine Daten zum Download verfügbar",
      "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Alles",
      "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Ansicht durch Wiederverkäufer",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Wiederverkäufergewinn",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Vertriebsgewinn %",
      "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"Reseller-Gewinn %",
      "RESELLER_RADIO_BUTTON_TEXT_NO":"Nein",
      "RESELLER_RADIO_BUTTON_TEXT_YES":"Ja",
      "RESELLER_REGISTRATION_SUCCESS":"Reseller-Registrierung erfolgreich abgeschlossen.",
      "RESELLER_RELATIONSHIP_LBL":"Bitte teilen Sie dem Kunden den folgenden Inhalt mit, um eine Reseller-Beziehung aufzubauen",
      "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Reseller auswählen",
      "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Gesehen von",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Bitte geben Sie den gültigen Steuerprozentsatz ein",
      "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Bitte füllen Sie die Felder Steuername aus",
      "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Neue Ereignis-E-Mail-Benachrichtigung hinzufügen",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"Partner-Tag erfolgreich gelöscht",
      "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"Partner-Tag erfolgreich aktualisiert",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Es sind keine Anbieter konfiguriert. Bitte wenden Sie sich an den Support",
      "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Für diesen Anbieter sind keine Schlüssel verfügbar",
      "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Wiederverkäufer: Schneller Identitätswechsel",
      "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Für den gewählten Währungscode wurde kein Markt gefunden",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Reseller-Konfiguration",
      "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Reseller-Einstellung",
      "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Angebote zuweisen",
      "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Bearbeiten",
      "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Imitieren",
      "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Provider-Links verwalten",
      "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Erstellt am",
      "RESELLER_TABLE_HEADER_TEXT_NAME":"Name",
      "RESELLER_TABLE_SIGNUP_DATE":"Anmeldedatum",
      "resellers":"Wiederverkäufer",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Rechnung",
      "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Versöhnung",
      "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Charge Reseller",
      "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Reseller erfolgreich hinzugefügt.",
      "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Reseller-Zahlungen",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
      "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Pläne verwalten",
      "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"E-Mails",
      "RESEND_INVOICE":"Rechnung erneut senden",
      "RESEND_INVOICE_HEADER_TEXT":"Bitte geben Sie E-Mail-IDs ein, um die Rechnung erneut zu senden",
      "RESEND_INVOICE_NOTE":"<b>Hinweis:</b>Wenn Sie die Rechnung erneut an mehrere E-Mail-IDs senden möchten, geben Sie diese bitte durch Komma an. Z.B: abc@mail.com, new@mail.com",
      "RESEND_INVOICE_USERS_LIST":"Liste der Rechnungsbenutzer erneut senden",
      "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Endverbraucherpreis (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Kosten für Partner (%)",
      "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Verkaufspreis (%)",
      "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Geben Sie einen gültigen RI-Prozentsatz ein",
      "RESET_VALUE":"Wert zurücksetzen",
      "Resolved":"Aufgelöst",
      "RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
      "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Die Zahlung wurde erfolgreich eingeleitet. Die Verarbeitung wird einige Zeit dauern. Bitte überprüfen Sie den Status nach einiger Zeit.",
      "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Abgerechneter Betrag",
      "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"E-Mail alle Rechnungen",
      "REVENUE_AND_COST_SUMMARY_CATEGORY":"Kategorie",
      "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Kosten für Partner",
      "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Kunde",
      "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Händlerrechnung",
      "REVENUE_AND_COST_SUMMARY_PROVIDER":"Anbieter",
      "REVENUE_AND_COST_SUMMARY_RESELLER":"Wiederverkäufer",
      "REVENUE_AND_COST_SUMMARY_SALETYPE":"SaleType",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Gebühren",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Rechnungen und Gebühren",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Zahlungen",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Fehler",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Überfällig",
      "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
      "REVENUE_AND_COST_SUMMARY_VIEWBY":"Gesehen von",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Rechnungserstellung abgeschlossen",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Beim Generieren der Kundenrechnungen ist ein Fehler aufgetreten",
      "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Kundenrechnungen erfolgreich generiert",
      "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters",
      "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters werden geladen...",
      "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Rechnung neu generieren",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Rechnungen anzeigen",
      "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Abonnementverlauf anzeigen",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Menge",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Abrechnungszeitraum",
      "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Kunde",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Kosten für Partner",
      "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Gewinnbetrag",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Kosten für den Kunden",
      "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Kosten für den Partner",
      "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Profitieren",
      "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Erhalten Sie alle Rechnungen per E-Mail",
      "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Kategorie",
      "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Kunde",
      "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Dauer",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Letzter Abrechnungszeitraum",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 Abrechnungszeiträume",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 Abrechnungszeiträume",
      "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 Abrechnungszeiträume",
      "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Sind Sie sicher, dass Sie die Zuordnung widerrufen möchten?",
      "RFRESH_DATA_FROM_PSA":"Daten aus PSA aktualisieren",
      "RIBBON_TITLE_DEPARTMENT":"Abt",
      "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Bitte geben Sie die Menge für mindestens ein Top-Level-Planangebot ein.",
      "ROLE_ASSIGNMENT_EXCEPTION":"Füllen Sie die Benutzerdetails aus",
      "ROLE_ASSIGNMENT_SCOPE_TITLE":"Rollenzuweisungsbereiche",
      "ROLE_NAME_C3_SUPPORT":"C3-Unterstützung",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Kunden-Account-Manager",
      "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"Der Benutzer kann auf Karten und Menüs mit Produkten zugreifen",
      "ROLE_NAME_CUSTOMER_ADMIN":"Kundenadministrator",
      "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"Der Benutzer kann alle im Kundenportal verfügbaren Aktionen anzeigen und ausführen",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Kundenverwaltung (für den Verkauf)",
      "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Kundenverwaltung (für den Verkauf)",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Kundenverwaltung Lite",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"Der Benutzer hat alle Berechtigungen des Kundenadministrators, kann jedoch keine neuen Abonnements erwerben",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Kundenadministrator Lite Plus",
      "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"Der Benutzer hat alle Berechtigungen von Customer Admin Lite, kann jedoch keine Produkte mit einer Laufzeit von 1 Jahr oder mehr verwalten.",
      "ROLE_NAME_CUSTOMER_MAINTENANCE":"Kundenwartung",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Kundenwartung",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Eine Rolle zum Verwalten der App durch den Kunden",
      "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Eine Rolle zum Verwalten der App durch den Kunden",
      "ROLE_NAME_CUSTOMER_READER":"Kundenleser",
      "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"Der Benutzer kann nur die im Kundenportal verfügbaren Daten lesen",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Kunden-Super-Admin",
      "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Kunden-Super-Admin",
      "ROLE_NAME_DEPARTMENT_ADMIN":"Abteilungsadministrator",
      "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Wer kann die Abteilung verwalten und darf nur Produkte verwalten, die den spezifischen Abteilungen zugeordnet sind.",
      "ROLE_NAME_DEPARTMENT_READER":"Abteilungsleser",
      "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Wer kann nur die unter den spezifischen Abteilungen verfügbaren Daten lesen?",
      "ROLE_NAME_DIRECT_ADMIN":"Direkter Administrator",
      "ROLE_NAME_FINANCE_ADMIN":"Finanzadministrator",
      "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Kann Seiten im Zusammenhang mit Finanzen verwalten.",
      "ROLE_NAME_GROUP_ADMIN":"Gruppenadministrator",
      "ROLE_NAME_HELP_DESK":"Beratungsstelle",
      "ROLE_NAME_HELP_DESK_DESCRIPTION":"Beratungsstelle",
      "ROLE_NAME_INDIRECT_ADMIN":"Indirekter Administrator",
      "ROLE_NAME_PARTNER_MAINTENANCE":"Partnerpflege",
      "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Eine Rolle zum Verwalten der App durch den Partner",
      "ROLE_NAME_SITE_ADMIN":"Seite Admin",
      "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Wer kann die Site verwalten und kann nur Produkte unter den spezifischen Sites verwalten?",
      "ROLE_NAME_SITE_READER":"Seite Reader",
      "ROLE_NAME_SITE_READER_DESCRIPTION":"Wer kann nur die auf der Website verfügbaren Daten lesen?",
      "ROLE_NAME_SYSTEM_ADMIN":"System Administrator",
      "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Kann auf alle Seiten im Portal zugreifen/diese ändern.",
      "ROLE_NAME_SYSTEM_READER":"Systemleser",
      "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Kann alle Seiten besuchen, aber er kann keine Änderungen vornehmen.",
      "ROLE_NAME_USER":"Benutzer",
      "ROLE_NAME_USER_ADMIN":"Benutzerverwaltung",
      "ROLE_NOTE_C3_SUPPORT":"Der C3-Support kann Mengeninkongruenzsituationen beheben und ist eine Reihe von Berechtigungen",
      "ROLE_NOTE_CUSTOMER_ADMIN":"Der Kundenadministrator kann alle Daten anzeigen und alle Aktionen im Kontext des Mandanten ausführen",
      "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"Der Kundenadministrator (für den Verkauf) kann Aktionen für Daten sehen und ausführen, aber nicht für Partnerabonnements",
      "ROLE_NOTE_CUSTOMER_READER":"Kundenleser, die nur Lesezugriff auf alle mit dem Kunden verbundenen Daten haben.",
      "ROLE_NOTE_DIRECT_ADMIN":"Direkter Administrator, wäre in der Lage, alles zu tun, was ein Systemadministrator tun kann, aber nur für die direkten Kunden, also kein Zugriff auf die Sell Indirect-Teile",
      "ROLE_NOTE_FINANCE_ADMIN":"Finanzadministratoren können auf die Umsatz- und Kostenübersicht der Kunden zugreifen, Steuer% definieren und den Abstimmungsbericht anzeigen",
      "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins kann alle mit Azure-Abonnements verbundenen Dinge in seiner Gruppe verwalten.",
      "ROLE_NOTE_HELP_DESK":"Helpdesk-Benutzer können alle Kundendaten anzeigen, neue Kunden einbeziehen und Support leisten. Helpdesk-Benutzer können sich als Kundendaten ausgeben und darauf zugreifen",
      "ROLE_NOTE_INDIRECT_ADMIN":"Indirekter Administrator, wäre in der Lage, alles zu tun, was ein Systemadministrator tun kann, aber nur für die Wiederverkäufer (und ihre Kunden), also kein Zugriff auf die Sell Direct-Teile.",
      "ROLE_NOTE_SYSTEM_ADMIN":"Der Systemadministrator kann alle Daten anzeigen und alle Aktionen sowohl im Kontext des Partners als auch des Kunden ausführen",
      "ROLE_NOTE_SYSTEM_READER":"Systemleser, die nur Lesezugriff auf alle Daten des Partners haben. Leser können sich nicht ausgeben",
      "ROLE_NOTE_USER_ADMIN":"Der Benutzeradministrator kann einige Aktionen für Daten sehen und ausführen, aber nicht für Abonnements",
      "RowNum":"Zeilennummer",
      "SALE":"Verkauf",
      "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Verkaufspreis bearbeiten",
      "SALE_PRICE_EMPTY_ERROR":"Bitte geben Sie einen gültigen Preis ein",
      "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Verkaufspreis höher als ERP-Preis",
      "SALE_PRICE_LESS_THAN_LIST_PRICE":"Verkaufspreis kleiner als der Listenpreis",
      "SALE_TYPE_DESC_PRODUCT":"Produkt",
      "SALE_TYPE_DESC_SERVICE":"Service",
      "SalePrice":"Verkaufspreis",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Es wird versucht, den von Quickbooks erhaltenen Authentifizierungscode zu speichern",
      "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Versuch, den von Xero erhaltenen Authentifizierungscode zu speichern",
      "SAVE_CART_EXCEPTION":"Beim Hinzufügen des Produkts zum Warenkorb ist ein Fehler aufgetreten",
      "SAVE_COUPON_FAILURE":"Gutschein konnte nicht gespeichert werden",
      "SAVE_COUPON_SUCCESS":"Gutschein erfolgreich gespeichert",
      "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Beim Speichern der Währungsumrechnung ist ein Fehler aufgetreten",
      "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Ausgewählte Spalten erfolgreich als Favoriten gespeichert.",
      "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Beim Speichern oder Aktualisieren der Empfängerliste für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
      "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Speichern der Steuerdetails ist ein Fehler aufgetreten",
      "SAVE_PLAN_PRODUCT_SLAB":"Gespeicherte Vertragsangebotsplatten",
      "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Vertragsangebotsplatten konnten nicht gespeichert werden",
      "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan erfolgreich erstellt",
      "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Zahlungsdetails des Anbieters erfolgreich gespeichert.",
      "SAVE_RESELLER_PAYMENT_PROFILE":"Speichern Sie das Reseller-Zahlungsprofil",
      "SAVE_RESELLER_PLAN":"Reseller-Plan speichern",
      "SAVE_RESELLER_PLAN_FAILURE":"Reseller-Plan konnte nicht gespeichert werden",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Definieren des Produktwährungskurses des Resellerplans",
      "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Fehler beim Definieren des Produktwährungskurses des Resellerplans",
      "SAVE_SUB_TAX":"Untersteuer speichern",
      "SAVE_SUB_TAX_FAILURE":"Speichern der Untersteuer fehlgeschlagen",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Neues Ticket erfolgreich erstellt",
      "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Ticket erfolgreich aktualisiert",
      "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Planen Sie eine Bestellung",
      "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Bitte wählen Sie ein Datum",
      "SCHEDULE_ORDER_CHECKBOX_TEXT":"Ich bin damit einverstanden, eine Bestellung für einen späteren Zeitpunkt zu planen",
      "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Wählen Sie ein Datum aus",
      "SCHEDULE_ORDER_EVENT":"Reihenfolge einplanen",
      "SCHEDULE_ORDER_FAILED_EVENT":"Der Zeitplanauftrag ist fehlgeschlagen",
      "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"Erstellungsdatum",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Empfängertyp",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Empfängerwert",
      "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Empfänger",
      "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"Datensatz-ID",
      "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Berichtsname",
      "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Ergebnis",
      "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Status",
      "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Löschen",
      "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Bearbeiten",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Geplanter Rechnungspositionsbericht",
      "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Geplante Rechnungszeilenberichtszustellung",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Bericht zu geplanten Rechnungspositionen mit Provision",
      "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Geplante Rechnungspositionen mit Provisionsberichtszustellung",
      "SCHEDULED_INVOICE_SUMMARY_REPORT":"Geplanter Rechnungszusammenfassungsbericht",
      "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Geplante Rechnungszusammenfassungsberichtszustellung",
      "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
      "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Löschen",
      "SCHEDULED_PROFITABILITY_REPORT":"Geplanter Rentabilitätsbericht",
      "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Geplante Zustellung von Rentabilitätsberichten",
      "SCHEDULED_RENEWAL_CHANGES":"Geplante Verlängerungsänderungen",
      "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"Möchten Sie die Aktion auf dieses Produkt anwenden?",
      "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"warten auf die Bestätigung",
      "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Aktiv",
      "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Abgesagt",
      "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Vollendet",
      "SCHEDULED_RENEWAL_STATUS_FAILED":"Gescheitert",
      "SCHEDULED_RENEWAL_STATUS_ONHOLD":"In Wartestellung",
      "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Zurück zu den grundlegenden Details",
      "SCHEDULED_RENEWALS_HEADER_TEXT":"Geplante Verlängerungen",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Aktionen",
      "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Enddatum ausrichten",
      "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Stornierungsgrund",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Rechnungskreislauf",
      "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Neue Artikelnummer",
      "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Menge",
      "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Geplantes Datum",
      "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Status",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Begriff",
      "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"AGB",
      "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptiert",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Rechnungsposition",
      "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Rechnungspositionen mit Provision",
      "SCHEDULED_REPORT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
      "SCHEDULED_REPORT_PROFITABILTY":"Rentabilitätsbericht",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Empfängertyp",
      "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Empfänger",
      "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Bericht",
      "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Steuerposten",
      "SCHEDULED_REPORT_TAX_SUMMARY":"Steuerübersicht",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Bericht zu geplanten Steuerpositionen",
      "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"GeplanteSteuerpositionenBerichtLieferung",
      "SCHEDULED_TAX_SUMMARY_REPORT":"Geplanter Steuerzusammenfassungsbericht",
      "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Geplante Zustellung des zusammenfassenden Steuerberichts",
      "SCHEDULNG_LODING_MESSAGE_TEXT":"Das Laden des berechtigten Angebots aus dem Partner Center dauert normalerweise etwa 30 Sekunden. Bitte aktualisieren Sie die Seite nicht.",
      "SCORE":"Punktzahl",
      "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Suche nach Ereignis",
      "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Suchaktionen",
      "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Suche nach Kundennamen",
      "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Suche nach Domainnamen",
      "SEAT_LIMIT_EXCEED":"Sitzplatzlimit überschritten",
      "SELECT_A_SOURCE_CURRENCY":"Quellwährung auswählen",
      "SELECT_A_SUBSCRIPTION_TEXT":"Wählen Sie ein Abonnement aus",
      "SELECT_AGENT":"Agent",
      "SELECT_ALL_CUSTOMER":"Alle Kunden",
      "SELECT_ALL_RESELLER":"Alle Wiederverkäufer",
      "SELECT_ALL_RG":"Möchten Sie alle Ressourcengruppen auswählen?",
      "SELECT_COUPON":"Gutschein auswählen",
      "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Währungscode auswählen",
      "SELECT_CUSTOMER":"Wählen Sie Kunde",
      "SELECT_DATE_RANGE":"Datumsbereich auswählen",
      "SELECT_DATE_VALUE":"Datum auswählen",
      "SELECT_DEFAULT_OPTION_SELECT":"Wählen",
      "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Wählen Sie Kunde",
      "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Wählen Sie Ereignis",
      "SELECT_DEPARTMENT":"Abteilung auswählen",
      "SELECT_DEPARTMENTS":"Abteilung auswählen",
      "SELECT_EDU_SEGMENT":"Wählen Sie das Bildungssegment",
      "SELECT_EVENT":"Wählen Sie Ereignis",
      "SELECT_MESSAGE_TYPE":"Nachrichtentyp auswählen",
      "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Bitte wählen Sie mindestens eine zu zahlende Rechnung aus",
      "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Schnelle Optionen zum Definieren Ihres Verkaufspreises",
      "SELECT_PARTNER_INVOICE":"Partnerrechnungen",
      "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Wählen Sie Ereignis",
      "SELECT_PLAN_TOOLTIP_TEXT":"Plan auswählen",
      "SELECT_PORTAL_PAGE":"Portalseite auswählen",
      "SELECT_PRIORITY":"Wählen Sie Priorität",
      "SELECT_PRODUCT_AREA":"Wählen Sie Produktbereich",
      "SELECT_RESELLER_AZURE_MACRO":"Auswählen eines Makros",
      "SELECT_RESELLER_AZURE_MACRO_TYPE":"Makrotyp auswählen",
      "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Verkaufspreistyp auswählen",
      "SELECT_SITE":"Standort auswählen",
      "SELECT_SITES":"Wählen Sie Standort aus",
      "SELECT_STATUS":"Status auswählen",
      "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Zielwährung auswählen",
      "SELECT_TYPE":"Art auswählen",
      "selectAddressType":"Adresstyp auswählen",
      "selectCountry":"Land auswählen",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Ausgewählte Kunden zum Hochladen von Rechnungen in Quickbooks",
      "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Ausgewählte Kunden zum Hochladen von Rechnungen auf Xero",
      "SELECTED_FILTER":"Filter auswählen",
      "SELECTED_FILTER_ALL":"Wählen Sie Alle",
      "selectEmailType":"E-Mail-Typ auswählen",
      "selectPhoneType":"Telefontyp auswählen",
      "selectState":"Bundesland auswählen",
      "SELL_DIRECT_ICON_TITLE":"Stockholm-icons / Einkaufen / Box2",
      "sell-direct":"Kunden",
      "sell-indirect":"Indirekt verkaufen",
      "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, fügen Sie ein Komma (,) oder ein Semikolon (;) ein und klicken Sie auf „Senden“, um eine Datei mit einer Produktkatalogliste zu erhalten",
      "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Rechnung erneut senden",
      "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Senden",
      "SEND_MAIL_BUTTON":"Mail senden",
      "SEPTEMBER":"September",
      "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Kundendomäne des Anbieters",
      "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
      "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
      "SERVICE_PROVIDER_TENANT":"Anbieter Mieter",
      "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Standardmieter",
      "SERVICE_PROVIDER_TENANT_HEADER":"Anbieter Mieter von",
      "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Aktionen",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Mieter-ID",
      "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Name des Mieters",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"Möchten Sie <b>{{customerName}} </b>als Standardmandanten markieren?",
      "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{customername}} als Standardmandant aktualisiert",
      "SESSION_EXPIRED_ERROR_MESSAGE":"Deine Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, um fortzufahren. Leider gehen alle nicht gespeicherten Änderungen verloren.",
      "SESSION_TIMEOUT_WARNING":"Warnung zur Sitzungsüberschreitung",
      "SESSION_WARNING_DURATION":"Ihre Sitzung läuft in {{secondsLeft}} Sekunden ab.",
      "SET_NEW_MACRO":"Neues Makro setzen",
      "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Der Stapelstatus von Bulk Onboard-Kunden wird auf \\''Abgeschlossen\\'' gesetzt",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Es wurde versucht, den Status der externen Servicepost-Protokolle auf \\''Fehlerhaft\\'' zu setzen",
      "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Fehler beim Festlegen des Status externer Service-Post-Protokolle auf ErrorIgnored",
      "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"E-Mail-Vorlage",
      "settings":"die Einstellungen",
      "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"E-Mail-Benachrichtigungen für Ereignisse",
      "SETTINGS_HEADING_TEXT_BILLING":"Abrechnung",
      "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"ConnectWise-Verwaltungseinstellungen",
      "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Währungsumrechnung",
      "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Kundenvorgaben",
      "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Plan löschen",
      "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"E-Mail Benachrichtigungen",
      "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Kundenkonfigurationen (global)",
      "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Partner-Standardeinstellungen",
      "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Benutzerverwaltung",
      "SETTINGS_LOGO_SELECT_A_LOGO":"Wählen Sie ein Logo",
      "SETTINGS_PASSWORD_HELP_TEXT":"Lassen Sie das Feld leer, wenn Sie das Passwort nicht aktualisieren möchten.",
      "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Automatische Aufgabe",
      "SETTINGS_TAB_HEADING_CONNECTWISE":"ConnectWise-Verwaltung",
      "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Benutzerdefinierte Ansichten",
      "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Allgemeines",
      "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logos",
      "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Partner Center",
      "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Anbieter",
      "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Öffentliche Anmeldung",
      "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
      "SETTINGS_TAX_NAME_TOOLTIP":"Sie können einen beliebigen Steuernamen angeben, z. B.: GST.",
      "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Hier können Sie den Steuerprozentsatz angeben. ZB: 15 für 15%",
      "SHIP_TO":"Ausliefern",
      "SHOP_ADDED_TO_CART_TEXT":" Zum Warenkorb hinzugefügt",
      "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"in den Warenkorb legen",
      "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
      "SHOP_PAGE_LINK_TEXT_READ_MORE":"Weiterlesen..",
      "SHOP_TILE_HYPERLINK_READ_MORE":"Weiterlesen..",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Kundenkonfigurationseinstellung im Kundenportal anzeigen",
      "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Ermöglichen Sie dem Kunden, Konfigurationen zu verwalten",
      "SHOW_NON_ACTIVE_OFFERS":"Behinderte Angebote anzeigen",
      "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
      "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Angebote anzeigen, die für den Kunden nicht verfügbar sind",
      "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Angebote anzeigen, die kein Makro haben",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Reseller-Konfigurationseinstellung im Reseller-Portal anzeigen",
      "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Ermöglichen Sie dem Reseller, Konfigurationen zu verwalten",
      "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Benutzer hinzufügen",
      "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Stockholm-Ikonen / Kommunikation / Gruppe",
      "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Stockholm-icons-/-Allgemein-/-Benachrichtigung2",
      "SIDEBAR_ANALYZE_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Rechner",
      "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Azurblauer Rand",
      "SIDEBAR_CONTENT_ALL_COMMENTS":"Ermöglicht dem Partner, die von Kunden für verschiedene Entitäten (Warenkorbartikel, Rechnungen, Bestellungen) bereitgestellten Kommentare zu überprüfen und darauf zu antworten",
      "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Liste der Kundenbetreuer und Optionen zur Verwaltung der ihnen zugeordneten Kunden.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Verwalten Sie Web-Benachrichtigungen auf Entitätsebene",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Erstellen und verwalten Sie benutzerdefinierte Benachrichtigungen für verschiedene Produkt-/Lizenzverwaltungsereignisse und Kunden-Onboarding.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Teilen Sie Echtzeitinformationen mit Partnern und Kunden durch interaktive Übertragungen auf ihren jeweiligen Dashboards.",
      "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Erstellen und verwalten Sie Web-Benachrichtigungen auf verschiedenen Entitätsebenen, um über den Systemzustand, Rechnungen und andere wichtige Benachrichtigungen zu informieren",
      "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor ist eine auf Empfehlungen basierende Funktion, die Partnern dabei hilft, die besten Empfehlungen zur Kostenoptimierung zu übernehmen.",
      "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Bericht über die Azure-Rechnungsdaten",
      "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Damit können Sie den Azure-Plan-Markup für vergangene Monate aktualisieren und Abrechnungsdaten mit neuen Markup-Werten laden.",
      "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Überprüfen Sie die Verbrauchsdetails Ihres Azure-Abonnements.",
      "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Kurzer Blick auf den Kostenübersichtsbericht",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Schneller Blick auf Kundenanalysen",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Liste der vom Mandanten verbrauchten Lizenzen anzeigen",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Beschreiben Sie die Verwendung von AZURE",
      "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Verwalten Sie Ihr Organisationsprofil, Adressen, Zahlungsprofile und mehr...",
      "SIDEBAR_CONTENT_MENU_DASHBOARD":"Es vermittelt den Partnern ein umfassendes Verständnis der Kunden und ihres Geschäfts in verschiedenen Dimensionen.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Überprüfen Sie Ihre Lizenzänderungen über benutzerdefinierte Daten hinweg.",
      "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Kurzer Überblick über die Zusammenfassung des Lizenzverbrauchs",
      "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Schneller Überblick über die Lizenzvergabe und die nicht zugewiesenen Plätze.",
      "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Verwalten und erstellen Sie mehrere Azure-Berechtigungen (Abonnements) im Rahmen des Azure-Plans in großen Mengen",
      "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Schneller Blick auf die Kunden-Onboarding-Analysen Ihrer indirekten Wiederverkäufer.",
      "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Detaillierte Berichterstattung über die Verkaufsprovision auf Agentenebene und den verdienten Betrag",
      "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Definieren Sie Rabattgutscheine, die auf Plan-/Kunden-/Serviceebene angewendet werden können.",
      "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Praktischer Produktleitfaden für Partner und Endkunden",
      "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":"Ermöglicht Partnern das Anzeigen und Herunterladen von Nutzungsdetails für Produkte, die außerhalb von C3 Solution aufgezeichnet wurden.",
      "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Ermöglicht Partnern das Hochladen von Produktcodes/-attributen, die in Rechnungspostenberichten erwünscht sind.",
      "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Ermöglicht dem Partner, Produktkaufanfragen von Kunden zu genehmigen/abzulehnen.",
      "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Laden Sie Werte für Agentennamen, mit Verkäufen verbundene Produkte, Provisionsprozentsätze und verschiedene andere Provisionsattribute hoch",
      "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Hilft Ihnen beim Erstellen benutzerdefinierter Spalten, die Ihrem Rechnungspostenbericht hinzugefügt werden.",
      "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Ermöglicht dem Partner das Hochladen von außerhalb von C3 aufgezeichneten Nutzungsdetails für Produkte im Katalog zur Rechnungserstellung.",
      "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Bietet mehrere Berichte über Ihre Daten in C3.",
      "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Hilft Ihnen beim Verwalten (Erstellen/Bearbeiten) von Plänen mit Angeboten, die Sie an indirekte Wiederverkäufer verkaufen möchten, indem Sie Ihre benutzerdefinierten Margen festlegen.",
      "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Mithilfe dieser Funktion können Partner verschiedene Berichte in unterschiedlichen Abständen planen.",
      "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Überprüfen Sie die Nutzung Ihres Azure-Abonnements mit den damit verbundenen Kosten.",
      "SIDEBAR_CONTENT_MENU_USERS":"Verwalten Sie den Zugriff auf Benutzer",
      "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analysieren Sie die Rentabilität Ihres Unternehmens in mehreren Dimensionen.",
      "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Zeigen Sie den Azure-Verbrauch auf Ressourcengruppen-/Ressourcen-/Tag-Ebene an.",
      "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Gruppieren Sie ein oder mehrere Abonnements und definieren Sie Budgets und Schwellenwerte",
      "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Hilft Ihnen bei der Verwaltung (Erstellung/Bearbeitung) von Produkten/Diensten von Drittanbietern und Ihren eigenen Beratungs-/Beratungs-/Unterstützungsdiensten und -angeboten.",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Führt zum Dashboard",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Greifen Sie über den Partner auf Ihre Rechnungen zu",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Ermöglicht Ihnen den Zugriff auf Funktionen, die für Microsoft CSP-Mandanten relevant sind, die mit Ihren Kunden verknüpft sind.",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Listen Sie alle Azure AD-Benutzer von Kunden auf und weisen Sie ihnen kundenseitige RBAC-Rollen zu",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Verfolgen Sie Ihre Bestellungen",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Zeigen Sie die gekauften Produkte an",
      "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Kaufen und kaufen Sie Angebote aus dem Katalog",
      "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Funktion, die es indirekten Anbietern (Distributoren) ermöglicht, ihre IP-Lösungen zu erstellen und über indirekte Wiederverkäufer an Endkunden zu verkaufen.",
      "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Rechnungen und Zahlungen verwalten",
      "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Ermöglicht es Ihnen, zwei oder mehr Angebote (Microsoft- und/oder Partnerangebote) zu kombinieren und Ihren Kunden zum Kauf zur Verfügung zu stellen",
      "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Führt zur Liste der aktiven Kunden und Optionen zum Verwalten ihrer Einstellungen.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Bringt Sie zur Dashboard-Seite",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Überprüfen Sie den Status der vom System ausgelösten Zahlungen.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Hilft Ihnen bei der Erstellung eines Angebotskatalogs (Produkte/Dienstleistungen) und lässt Sie Ihre benutzerdefinierten Margen als Verkaufspreis für Ihre Kunden definieren.",
      "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Gibt Ihnen einen Überblick über Ihre Rentabilität und überprüft die Rechnungen im Detail.",
      "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Führt zum Dashboard",
      "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Gibt Ihnen einen Überblick über Ihre Rentabilität und einen Blick auf die Rechnungsdetails",
      "SIDEBAR_CONTENT_MENUS_RESELLERS":"Führt zur Liste der aktiven Reseller und Optionen zur Verwaltung ihres CSP-Portfolios.",
      "SIDEBAR_CONTENT_MENUS_SELL":"Listet die Funktionen auf, mit denen Sie Ihre Verkaufsangebote definieren können",
      "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"<b>Direktabrechnungspartner (Ebene 1)</b> – Listet alle Funktionen auf, die Ihnen bei der Verwaltung Ihrer Kundenangebote und Preise helfen.",
      "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"<b>Indirekter Anbieter (Ebene 2)</b> – Listet die Funktionen auf, die indirekten Anbietern helfen, ihre indirekten Wiederverkäufer zu verwalten.",
      "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Greifen Sie auf Funktionen zu, die nur für die C3-Support-Rolle gelten.",
      "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Stockholm-icons-/-Design-/-Union",
      "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Stockholm-icons-/-Communication-/-Group",
      "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Stockholm-icons-/-Communication-/-Group",
      "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
      "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Stockholm-icons-/-Design-/-Union",
      "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
      "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Stockholm-icons / Einkaufen / Chart-line1",
      "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Stockholm-Symbole-/-Dateien-/-Gruppen-Ordner",
      "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Ticket",
      "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Stockholm-Icons-/-Shopping-/-Geschenk",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
      "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Stockholm-Symbole-/-Home-/-Taste",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
      "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Ikonen / Einkaufen / Geld",
      "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
      "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Stockholm-Symbole-/-Navigation-/-Doppelcheck",
      "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Stockholm-Ikonen / Einkaufen / Geld",
      "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Stockholm-Icons-/-Design-/-Komponente",
      "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Stockholm-Ikonen / Kommunikation / Gruppe",
      "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
      "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Stockholm-icons / Einkaufen / Box3",
      "SIDEBAR_TITLE_ALL_COMMENTS":"Bemerkungen",
      "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Kundenbetreuer",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Verwaltung",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Benutzerdefinierte Benachrichtigungen",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"Engagieren",
      "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Bannerbenachrichtigungen",
      "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Azure Advisor",
      "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Azure-Abrechnungsbericht",
      "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Marge",
      "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Azure-Abonnements",
      "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Kostenübersicht",
      "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Kundenanalyse",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lizenzverbrauch",
      "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Microsoft Azure-Verwendungsbericht",
      "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
      "SIDEBAR_TITLE_MENU_DASHBOARD":"Dashboard",
      "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Lizenzänderungsbericht.",
      "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Zusammenfassender Bericht zum Lizenzverbrauch",
      "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Lizenzverbrauchsbericht",
      "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Azure-Berechtigungen verwalten",
      "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Onboarding Analytics",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Kommission",
      "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Kommissionsberichte",
      "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Gutscheine",
      "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Produktdokumentation",
      "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
      "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Zitate",
      "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Hochladen",
      "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Zulassungen",
      "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Provisionen",
      "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Produkteigenschaften",
      "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Verwendung",
      "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Berichte",
      "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Pläne",
      "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Geplante Berichte",
      "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Nutzungsbericht",
      "SIDEBAR_TITLE_MENU_USERS":"Benutzer",
      "SIDEBAR_TITLE_MENUS_ANALYZE":"Analysieren",
      "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Azure-Schätzungen",
      "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Azure-Gruppen",
      "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Partnerangebote",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Dashboard",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Rechnungen",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Microsoft-Benutzer",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Aufträge",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Produkte",
      "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Shop",
      "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Angebote des Händlers",
      "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Rechnungen",
      "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Bündel",
      "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Kunden",
      "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Dashboard",
      "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus.",
      "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Pläne",
      "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
      "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Dashboard",
      "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
      "SIDEBAR_TITLE_MENUS_RESELLERS":"Wiederverkäufer",
      "SIDEBAR_TITLE_MENUS_SELL":"Verkaufen",
      "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Kunden",
      "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Indirekt verkaufen",
      "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"C3-Unterstützung",
      "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Symbole / Dateien / Hochladen",
      "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
      "SIGN_UP_DATE":"Anmeldedatum",
      "SIGNUP_CART_COUPON_APPLY":"Anwenden",
      "SIGNUP_CART_COUPON_CODE":"Gutscheincode",
      "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Gutschein entfernen",
      "simplereport":"Kommissionsbericht",
      "SINGLE_COMMENT_STATIC":"Kommentar",
      "SITE":"(Seite)",
      "SITE_DEPARTMENT_ID":"SeiteDepartmentId",
      "SITE_ID":"C3SiteId",
      "SLAB_BACKWARD_CONFIRMATION_POPUP":"Willst du wirklich zurück? Sie haben einige Plattendaten gespeichert.",
      "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Bitte füllen Sie die Plattendaten aus und speichern Sie sie.",
      "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Bitte geben Sie die Plattendaten ein.",
      "SLAB_DATA_TABLE_HEADER_ACTIONS":"Aktionen",
      "SLAB_DATA_TABLE_HEADER_COST_RATE":"Kostensatz/Einheit",
      "SLAB_DATA_TABLE_HEADER_RANGE":"Angebot",
      "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Verkaufspreis/Einheit",
      "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Die Textlänge ist zu lang",
      "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Bitte füllen Sie die Zeilendaten aus.",
      "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Die Plattendaten dürfen nicht mehr als 10 betragen",
      "SLAB_TYPE_SELECT_OPTION":"Wählen",
      "SMTP_SETTINGS_TEST":"Klicken Sie auf die Schaltfläche, um die SMTP-Einstellungen zu testen",
      "SMTP_TEST_BUTTON":"Testen Sie SMTP",
      "SMTPTYPE_LEGACY_SMTP":"Legacy-SMTP",
      "SMTPTYPE_MODERN_0365":"Moderne 0365",
      "SMTPTYPE_SENDGRID":"Sendgrid",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Stornierungsbedingungen",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 Stunden: Volle Rückerstattung",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 Stunden: 1. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 Stunden: 2. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 Stunden: 3. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 Stunden: 4. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 Stunden: 5. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 Stunden: 6. Tag anteilig",
      "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Nach 7 Kalendertagen: Eine Stornierung ist nicht zulässig",
      "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Anweisungen für Software-Abonnements",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"Anzahl/Plätze können jederzeit erhöht werden.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"Die Anzahl/Plätze können innerhalb von 7 Kalendertagen auf die vorherige oder die Summe der vorherigen Anzahl/Plätze reduziert werden.",
      "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Mengenänderung",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Planen Sie eine Verlängerungsänderung",
      "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Der aktuelle Zeitplan wird gelöscht, wenn Menge/Status der automatischen Verlängerung/Abrechnungszeitraum/Laufzeit geändert wird.",
      "SoftwareSubscriptions":"Software-Abonnements",
      "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Quellwährung",
      "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Quellwährung",
      "SOURCE_PLAN_DISPLAY_NAME":"Quellenplan",
      "SOURCE_PRODUCT_DISPLAY_NAME":"Quellprodukt",
      "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"Kundenprofil wurde synchronisiert",
      "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Details zur Sonderqualifikation bearbeiten",
      "SQL_ERROR_DESC":"Bei der Bearbeitung Ihrer Anfrage scheint ein technischer Fehler aufgetreten zu sein. Bitte wenden Sie sich an den Support, um weitere Unterstützung zu erhalten.",
      "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Fehler in der Datenbank beim Versuch, Rechnungsgebühren abzurufen",
      "START_BILLING_PERIOD":"Abrechnungszeitraum starten",
      "START_DATE_REQUIRED_MESSAGE":"Startdatum ist erforderlich",
      "STATUS":"Status",
      "STATUS_ALL":"Alles",
      "STATUS_DUE":"Gebühren",
      "STATUS_OVER_DUE":"Übergebühren",
      "STATUS_PENDING":"steht aus",
      "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Status erfolgreich aktualisiert",
      "SUB_TAXES_NOT_BOND_EXCEPTION":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für die Untersteuern ein.",
      "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"Die maximal zulässige Menge ist",
      "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"Die zulässige Mindestmenge beträgt 1",
      "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Dieses Feld wird benötigt",
      "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Werbung entfernen",
      "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"Das Upgrade ist im Gange, bitte klicken Sie auf Übergang, um den Fortschritt zu sehen",
      "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Da die Anzahl der Upgrade-Lizenzen geringer ist als die aktuelle, müssen Sie Arbeitsplätze manuell neu zuweisen",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Dieses Kundenabonnement wird zwischen Partnerkunden und Wiederverkäuferkunden geteilt, daher kann das Produkt nicht aktualisiert werden",
      "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Dieses Abonnement wird von mehreren Einheiten gemeinsam genutzt, daher ist nach dem Upgrade eine manuelle Neuzuordnung erforderlich",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Abonnementablaufprüfung hinzufügen",
      "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Abonnementablaufprüfung hinzufügen",
      "SUBSCRIPTION_CHECK_ID":"Abonnement-Check-ID",
      "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Erforderlich",
      "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"Die maximale Anzahl an Tagen sollte kleiner oder gleich {{MaxDate}} sein.",
      "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"Die Mindestanzahl an Tagen sollte größer oder gleich 1 sein",
      "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Sie haben Ihr maximales Limit von 50 zulässigen Zeichen erreicht",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Benachrichtigung über Ablauf des Abonnements fehlgeschlagen",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"Benachrichtigung über Ablauf des Abonnements für Kunden fehlgeschlagen",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Benachrichtigung über Ablauf des Abonnements für den Kundenerfolg",
      "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Benachrichtigung über Ablauf des Abonnements erfolgreich",
      "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Wählen Sie einen Begriff aus",
      "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"einreichen",
      "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Abrechnungszeitraum",
      "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Rechnungskreislauf",
      "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Abrechnungszeitraum",
      "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Kategorie",
      "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Kunden",
      "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Endtermin",
      "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Abonnementname",
      "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Anbieter",
      "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Anfangsdatum",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Kategorien",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Anbieter",
      "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Anbieterkategorien",
      "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
      "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Abonnementverlauf herunterladen",
      "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Bericht zum Abonnementverlauf",
      "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Schließen",
      "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Suche",
      "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Zurücksetzen",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Datum",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Abteilungsname",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Neuer Preis",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Erweiterung",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Produktname",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Alter Preis",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Preis",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Seite-Name",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Status",
      "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Benutzer",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Endtermin",
      "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Anfangsdatum",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Lizenzzuweisungen verwalten",
      "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
      "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Nicht genutzte Sitze freigeben. Warten Sie mal...",
      "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"Lizenzzuweisungen verwalten für",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Lizenzen zuweisen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Lizenzen entfernen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Benutzer zuweisen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Anleitung",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Anleitung",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Anzeigen und suchen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Zu entfernende Lizenzen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Validierungsfehler",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Geben Sie gültige Benutzer-E-Mail-Adressen ein, die durch ein Komma getrennt sind.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Dieses Portal überprüft die Eingabe und bestimmt, ob vor der Zuweisung zusätzliche Lizenzen erworben werden müssen.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Während wir an Änderungen an diesem Abonnement arbeiten, kann kein Vorgang ausgeführt werden.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Alle Fehler, die während der Validierung der Eingabe festgestellt wurden, werden angezeigt.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Beim Senden wird entweder Lizenzen zuweisen oder entfernen (aktive Registerkarte) berücksichtigt.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Klicken Sie in der Tabelle links auf <i class='fa fa-remove red'></i> für einen Benutzer, um ihn zur Liste der zu entfernenden Lizenzen hinzuzufügen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Klicken Sie auf Senden, um die Lizenzen zu entfernen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Beim Senden wird entweder Lizenzen zuweisen oder entfernen (aktive Registerkarte) berücksichtigt",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Kosten",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Originalmenge.",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Preis pro Sitzplatz",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Mehrere Benutzer",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Entfernen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Datei hochladen",
      "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Name",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"Neue Benutzer-E-Mail-Adresse hinzufügen",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Geben Sie gültige Benutzer-E-Mails, die durch ein Komma getrennt sind, in eine TXT-Datei ein und laden Sie sie hier hoch.",
      "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Geben Sie gültige Benutzer-E-Mails, die durch ein Komma getrennt sind, in eine TXT-Datei ein und laden Sie sie hoch. Z.B: abc @ xyz.com, bcd @ xyz.com",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Fehler ignorieren",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Fehler beheben",
      "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Status",
      "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Bitte warten Sie, während wir Ihre Anfrage bearbeiten. Dies kann einige Minuten dauern. Sie können es auch erneut versuchen, indem Sie auf der Abonnementseite auf die Schaltfläche \\''Verwalten\\'' klicken",
      "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"Möchten Sie mit den nächsten Änderungen der Terminverlängerung als {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'Sitzplatzlimit und Transaktionslimit': (isSeatLimitExceeded? 'Sitzplatzlimit ist': 'Transaktionslimit ist')}} überschritten",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Bestellung storniert",
      "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Bestellung abgeschlossen",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonnement gekauft",
      "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonnement entfernt",
      "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"Die ausgewählte Anforderung wurde erfolgreich genehmigt.",
      "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Bereitstellung von Abonnements",
      "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"Die ausgewählte Anforderung wurde erfolgreich abgebrochen.",
      "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Alle Kunden",
      "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Bereitstellung von Abonnements",
      "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"aktivieren Sie",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Entitätsname",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Titel",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
      "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Bitte warten Sie, während wir Ihren früheren Kauf bearbeiten. Dies kann einige Minuten dauern.",
      "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Bitte warten Sie, während wir Ihren früheren Kauf bearbeiten. Dies kann einige Minuten dauern. Sie können den Status auch überprüfen, indem Sie auf die Schaltfläche \\''Neues Abonnement hinzufügen\\'' klicken.",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Scheck hinzufügen",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Zurück zur Liste",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Überprüfung des Abonnementablaufs",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Aktion",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Tage",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Name",
      "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Begriff",
      "SUBSCRIPTION_STATUS_ACTIVE":"Aktiv",
      "SUBSCRIPTION_STATUS_CANCELLED":"Abgesagt",
      "SUBSCRIPTION_STATUS_COMPLETED":"Vollendet",
      "SUBSCRIPTION_STATUS_DELETED":"Gelöscht",
      "SUBSCRIPTION_STATUS_DISABLED":"Deaktiviert",
      "SUBSCRIPTION_STATUS_EXPIRED":"Abgelaufen",
      "SUBSCRIPTION_STATUS_FAILED":"Gescheitert",
      "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Provision",
      "SUBSCRIPTION_STATUS_PENDING":"steht aus",
      "SUBSCRIPTION_STATUS_SUCCEEDED":"Erfolgreich",
      "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendiert",
      "SUBSCRIPTION_UGRADE_TITLE_2":"Wichtig",
      "SUBSCRIPTION_UPDATED":"Abonnement erfolgreich aktualisiert.",
      "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automatisch",
      "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Wählen Sie ein geeignetes Upgrade für Ihr Abonnement aus",
      "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Kumulierte Menge",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Aktuelles Abonnement",
      "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Aktuelles Abonnement",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Wählen Sie ein neues Angebot aus, um das aktuelle Abonnement zu aktualisieren. Dadurch werden Ihre Abonnement- und Bereitstellungsdienste aktualisiert, die mit dem neuen Angebot verbunden sind",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Vermeiden Sie es während des Upgrades, Teile des ursprünglichen Abonnements oder die Konten des Kunden zu ändern. Dadurch werden ein neues Abonnement erstellt, Dienste bereitgestellt und Benutzerlizenzen dem neuen Abonnement neu zugewiesen. Diese Änderungen können nicht rückgängig gemacht werden.",
      "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Bitte stellen Sie sicher, dass Sie eine Datensicherung durchführen, bevor Sie das Abonnement aktualisieren.",
      "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Fehler ignorieren",
      "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Warnung ignorieren",
      "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuelle",
      "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"Für das Abonnement waren keine berechtigten Planprodukte vorhanden",
      "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"Keine Übergänge gefunden",
      "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"Möchten Sie mit dem Abonnement-Upgrade fortfahren als {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'Sitzplatzlimit und Transaktionslimit': (isSeatLimitExceeded? 'Sitzplatzlimit ist': 'Transaktionslimit ist')}} überschritten",
      "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Neuzuweisung von Sitzplätzen",
      "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Arbeitsplätze, die aktualisiert werden müssen (Quellabonnement)",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Wählen Sie ein Zielabonnement aus",
      "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Abonnementname",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Dieses Feld wird benötigt",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Zielabonnements",
      "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Neues Abonnement",
      "SUBSCRIPTION_UPGRADE_TITLE":"Wählen Sie ein geeignetes Upgrade aus",
      "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Übergangsaktivität",
      "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"Die Aktualisierung wird durchgeführt",
      "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Aktualisierung",
      "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Dieser Kunde wurde bereits zugewiesen.",
      "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Die folgenden Benutzer haben bereits eine Lizenz für dieses Angebot zugewiesen. Bitte entfernen Sie sie und versuchen Sie zu speichern.",
      "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Sind Sie sicher, dass Sie die Fehler ignorieren möchten?",
      "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Lizenzzuweisungen erfolgreich aktualisiert",
      "SUBSCRIPTIONMANAGE_REACTIVATE":"Möchten Sie dieses Abonnement wirklich wieder aktivieren?",
      "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"Das ausgewählte Abonnement wurde erfolgreich reaktiviert",
      "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Sind Sie sicher, dass Sie die nicht verwendeten Sitze freigeben möchten?",
      "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Bitte entfernen Sie mindestens einen Benutzer, um die Lizenz aus der Tabelle \\''Zugewiesene Lizenzen\\'' zu widerrufen!",
      "SUBSCRIPTIONMANAGE_SELECT_USER":"Bitte wählen Sie den Benutzer aus der vorgeschlagenen Liste aus, bevor Sie auf die Schaltfläche Hinzufügen klicken.",
      "SUBSCRIPTIONMANAGE_SUSPEND":"Möchten Sie dieses Abonnement wirklich aussetzen? Bitte beachten Sie, dass verwandte aktive Add-On-Abonnements ebenfalls ausgesetzt werden (falls vorhanden).",
      "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"Das Abonnement (gegebenenfalls einschließlich Add-Ons) wird erfolgreich ausgesetzt",
      "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Nicht genutzte Plätze des Abonnements wurden erfolgreich freigegeben.",
      "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Ihre Massenzuweisungsanforderung befindet sich in der Warteschlange",
      "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Erfolgreich hochgeladen",
      "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Bitte geben Sie die gültigen Benutzer-E-Mails ein, um die Lizenzen in das Textfeld zuzuweisen.",
      "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Neues Abonnement hinzufügen",
      "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Bearbeiten",
      "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Verwalten",
      "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Gesamt",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Aktionen",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Name",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Menge",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Kosten",
      "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Gesamt",
      "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Neues Azure-Abonnement",
      "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Name",
      "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Neues Abonnement hinzufügen",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azurblau",
      "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Neue Abonnements hinzufügen",
      "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Azure-Abonnements",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Schätzpreis",
      "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Angebotsname",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
      "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Neue Menge insgesamt",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"Der Mindestwert ist 1",
      "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"Die Eingabe sollte eine gültige Ganzzahl sein",
      "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Angebot",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Kaufen Sie zusätzliche Sitzplätze, indem Sie die <strong> Gesamtzahl neuer Mengen </strong> erhöhen",
      "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Stellen Sie sicher, dass Sie Lizenzen von Benutzern über <strong>Benutzer &-gt; Lizenzen</strong> für den Fall, dass Sie die Anzahl der Sitzplätze von hier aus reduzieren möchten",
      "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Ihre Kaufanfrage für Abonnements befindet sich in der Warteschlange",
      "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Reaktivieren",
      "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonnement erfolgreich gespeichert",
      "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Bitte wählen Sie mindestens ein Angebot aus, bevor Sie eine Bestellung aufgeben",
      "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Aussetzen",
      "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Allgemeine Einstellungen erfolgreich aktualisiert",
      "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"ConnectWise Manage-Einstellungen erfolgreich aktualisiert.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"Die SMTP-Einstellungen der Partner wurden erfolgreich aktualisiert.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"Partner {{logo}} wurde erfolgreich aktualisiert.",
      "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"Partnereinstellungen erfolgreich aktualisiert.",
      "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Anbietereinstellungen erfolgreich aktualisiert.",
      "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"Öffentliche Registrierungseinstellungen erfolgreich aktualisiert.",
      "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Der Import mehrerer Zahlungen wurde erfolgreich abgeschlossen",
      "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Erfolgreich importierte tägliche Nutzungsdaten des Partnerangebots",
      "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Import von Produktprovisionen erfolgreich abgeschlossen",
      "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Erfolgreich einen neuen Plan mit allen Angeboten erstellt",
      "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Es wurde erfolgreich ein neuer Reseller-Plan mit allen Angeboten erstellt",
      "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Mengeninkongruenz beheben",
      "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Bitte aktualisieren Sie den Plan für diesen Kunden, um relevante Angebote aufzunehmen, und kehren Sie hierher zurück, um das Problem zu beheben",
      "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"CSP-Angebotsname",
      "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Keine passenden Angebote gefunden",
      "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Passendes Angebot im aktuellen Plan",
      "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Preis",
      "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Menge",
      "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapping anbieten",
      "SUPPLIER_DETAILS":"Lieferantendetails",
      "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Neues Support Ticket",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Kontakt E-mail",
      "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"Kontakt-Telefon",
      "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Beschreibung",
      "SUPPORT_ADD_FORM_LABELS_PRIORITY":"Priorität",
      "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Produktbereich",
      "SUPPORT_ADD_FORM_LABELS_SUJECT":"Gegenstand",
      "SUPPORT_ADD_FORM_LABELS_TYPE":"Art",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Unterstützung",
      "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Unterstüzungsticket #",
      "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Einen Kommentar hinzufügen",
      "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Bemerkungen",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Kontakt E-mail",
      "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"Kontakt-Telefon",
      "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Kumulativer Aufwand",
      "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Kunde",
      "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Beschreibung",
      "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Aufwand (in Stunden)",
      "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Priorität",
      "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Produktbereich",
      "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Status",
      "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Gegenstand",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Support-Agent",
      "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Wählen Sie Support Agent",
      "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Art",
      "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Keine Kommentare hinzugefügt.",
      "SUPPORT_FOR_TEXT":"Unterstützung für",
      "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Support-Ticket hinzufügen",
      "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Support-Tickets",
      "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Kunde",
      "SUPPORT_LIST_FORM_LABEL_SEARCH":"Suche",
      "SUPPORT_LIST_FORM_LABEL_STATUS":"Status",
      "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Support-Agent",
      "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Ticketnummer",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Aktionen",
      "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Einzelheiten",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Kontakt E-mail",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
      "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Kunde",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Priorität",
      "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Produktbereich",
      "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Status",
      "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Gegenstand",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Ticketnummer",
      "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Art",
      "SUPPORT_TICKET_CREATE_SUCCESS":"Neues Support-Ticket erfolgreich erstellt",
      "SUPPORT_TICKET_STATUS_ARCHIVED":"Archiviert",
      "SUPPORT_TICKET_STATUS_CLOSED":"Geschlossen",
      "SUPPORT_TICKET_STATUS_IN PROGRESS":"In Bearbeitung",
      "SUPPORT_TICKET_STATUS_OPEN":"Öffnen",
      "SUPPORT_TICKET_STATUS_RE_OPEN":"Wieder öffnen",
      "SUPPORT_TICKET_STATUS_RESOLVED":"Aufgelöst",
      "SUPPORT_TICKET_STATUS_WONT_FIX":"Wird nicht repariert",
      "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Zugriffsprobleme",
      "SUPPORT_TICKET_TYPE_ANALYTICS":"Analytik",
      "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
      "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Profilverwaltung",
      "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Abonnementverwaltung",
      "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Benutzerverwaltung",
      "SUPPORT_TICKET_UPDATE_SUCCESS":"Details zum Support-Ticket erfolgreich aktualisiert",
      "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Für den gewählten Währungscode wurde kein Markt gefunden",
      "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Die folgenden Märkte werden nicht unterstützt, da für ihre jeweiligen Währungen keine Währungsumrechnung gefunden wurde.",
      "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"Der unterstützte Markt gilt nur für NCE-Angebote",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Markt",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Marktcode",
      "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Währung",
      "SupportedMarkets":"Unterstützte Märkte",
      "SWAL_OK_TEXT":"OK",
      "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Wechseln Sie zu Microsoft Pricing API",
      "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Wechseln Sie zu einem neuen Sicherheitsmodell",
      "SYNC_CUSTOMER_PROFILE":"Kundenprofil synchronisieren",
      "TAB_HEADING_PROVISIONING_STATUS":"Bereitstellungsstatus",
      "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Anzeigen von {{StartIndex}} - {{EndIndex}} von {{Total}} Datensätzen",
      "TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "TABLE_HEADER_TEXT_DATE":"Datum",
      "TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
      "TABLE_HEADER_TEXT_FAILURE_REASON":"Fehlergrund",
      "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Großer Betrag",
      "TABLE_HEADER_TEXT_KEY":"Schlüssel",
      "TABLE_HEADER_TEXT_PAID_AMOUNT":"Bezahlte Menge",
      "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Zahlungsstatus",
      "TABLE_HEADER_TEXT_REMARKS":"Bemerkungen",
      "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Zwischensumme",
      "TABLE_HEADER_TEXT_TAX_AMOUNT":"Steuerbetrag",
      "TABLE_HEADER_TEXT_VALUE":"Wert",
      "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Ausgewählte Rechnung (en) insgesamt",
      "TABLE_PLACEHOLDER_TEXT_SEARCH":"Suche...",
      "TAG_DEELTE_SUCCESS_MESSAGE":"Tag erfolgreich gelöscht",
      "TAG_NAME_PLACEHOLDER":"Wählen Sie ein Schlagwort aus",
      "TAG_SAVED_SUCCESS_MESSAGE":"Tag erfolgreich gespeichert",
      "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Plan Name",
      "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Produktname",
      "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Eigenschaften",
      "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Zurück zu Tags",
      "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Hinzufügen",
      "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Bearbeiten",
      "TAGS_CAPTION_TEXT_TAG_FOR":"Tag für",
      "TAGS_CAPTION_TEXT_TAGS_FOR":"Tags für",
      "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Zielwährung",
      "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Zielwährung",
      "TARGET_PLAN_DISPLAY_NAME":"Zielplan",
      "TARGET_PRODUCT_DISPLAY_NAME":"Zielprodukt",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Zurück zu den Steuerprozentsätzen",
      "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Steuerliche Prozentsätze",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Hinzufügen",
      "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Bearbeiten",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Steuerregeln werden durch Kombinationen aus { Country,State Province and Tax Code }, { Country, Zip } oder { Country, State Province and Zip } definiert",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Z.B: Betrachten Sie die Definition von Steuerregeln für ein Land wie die USA. Wenn Sie Bundesstaat, Provinz und Postleitzahl leer lassen, wird der Steuersatz auf alle Kunden aus den USA angewendet. Wenn Sie eine Regel für einen bestimmten Staat definieren möchten, geben Sie beim Definieren der Regel die Werte für Land und Staat an",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"Gültig ab",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Steuername",
      "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Steuerprozentsatz",
      "TAX_PERCENTAGES_ADD_SUCCESS":"Steuerprozentsatz erfolgreich hinzugefügt.",
      "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Kunden",
      "TAX_PERCENTAGES_DELETE_SUCCESS":"Steuerprozentsatz erfolgreich gelöscht.",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Steuerprozentsatz hinzufügen",
      "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Steuerliche Prozentsätze",
      "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Steuerliche Prozentsätze für",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Löschen",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Aktionen",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"Gültig ab",
      "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"MwSt %",
      "TAX_PERCENTAGES_UPDATE_SUCCESS":"Steuerprozentsatz erfolgreich aktualisiert.",
      "TAX_RULES_ADD_SUCCESS":"Steuerregel erfolgreich hinzugefügt",
      "TAX_RULES_DELETE_SUCCESS":"Steuerregel erfolgreich gelöscht",
      "TAX_RULES_UPDATE_SUCCESS":"Steuerregel erfolgreich aktualisiert",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Kumulierte Steuer",
      "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Die Kindersteuerregeln werden nacheinander auf den resultierenden Betrag aus jedem Schritt der Berechnung angewendet.",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Allgemeines",
      "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Auf den Gesamtbetrag wird ein einheitlicher Steuerprozentsatz angewendet.",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Untersteuern",
      "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Der Steuerprozentsatz für diese Definition ist die Summe (sum) aus den Kindersteuerregeln.",
      "TENANT TOOLTIP":"Mandanten zum Laden von Benutzern auswählen",
      "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Aktivieren Sie das Abonnement für die Einhaltung der Gefahrenschwelle",
      "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Aktivieren Sie die automatische Freigabe für nicht zugewiesene Plätze",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Ermöglicht dem Kunden, die Produktmenge in der Raster- oder Listenansicht im Produktbildschirm zu ändern.",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Erlauben Sie dem Kunden, die Transaktion für seine Rechnung(en) zu initiieren",
      "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Durch Aktivieren dieses Flags wird verhindert, dass Kunden die Abonnementmenge reduzieren",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Produktnamensynchronisierung für nutzungsbasierte Angebote zulassen",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Produktnamensynchronisierung für mengenbasierte Angebote zulassen",
      "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Ermöglicht die Synchronisierung des Anzeigenamens des Abonnements für mengenbasierte Angebote",
      "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Option zur Anwendung von Azure-Plan-Rabatten der Stufe 2 auf den Kunden. Der Standardwert ist „Nein“. Wenn er auf „Ja“ gesetzt ist, werden außer den PEC-Rabatten auch alle anderen Rabatte auf den Kunden angewendet.",
      "TENANT_CONFIG_DESC_AUTO_SYNC":"Dies wird verwendet, um den automatischen Synchronisierungsprozess in Reconciliation zu aktivieren/deaktivieren.",
      "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Jährliche Autotask-Vertragslaufzeit (Jahre)",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Enddatum des Autotask-Vertrags",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Dieser Datumswert wird als Enddatum verwendet, um Verträge automatisch für Kunden zu erstellen",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Einrichtungsgebühr für Autotask-Vertrag",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Bitte geben Sie ggf. Vertragseinrichtungsgebühren ein",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Startdatum des Autotask-Vertrags",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Dieser Datumswert wird als Startdatum verwendet, um Verträge automatisch für Kunden zu erstellen",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Starttag des Autotask-Vertrags",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Aktivieren Sie das Kontrollkästchen, wenn die Vertragszeitberichterstattung Start- und Stoppzeiten erfordert",
      "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Autotask-Vertragszeitberichte für erforderliche Start- und Stoppzeiten",
      "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Monatliche Autotask-Vertragslaufzeit (Monate)",
      "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Ermöglichen Sie Kunden die Verwaltung von Azure-Abonnements",
      "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Ermöglichen Sie Kunden, Gruppen über Azure-Abonnements zu definieren",
      "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie (%), um es auf die Azure-Nutzung des Kunden anzuwenden. z.B: Geben Sie 1,15 ein, wenn der Aufschlag 15% betragen soll",
      "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, wird das NCE-Abonnement auf automatische Verlängerung eingestellt.",
      "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Aktivieren, um den ERP-Preis bei der Erneuerung ohne Makro zu berücksichtigen",
      "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Nach Kauf oder Mengenerhöhung wird das Produkt bis zu diesem Zeitraum (in Tagen) nicht im Autorelease berücksichtigt.",
      "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"CRM-Opportunity-ID",
      "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Kundenabrechnungstyp",
      "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Kundenwährung",
      "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Art der Wirksamkeit der automatischen Preisaktualisierung des Support-Kundenprodukts. Diese Einstellung wird wirksam, wenn und nur Sollte Kunden benachrichtigen aktiviert sein!",
      "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Standard-Gefahrenschwellenwert in Prozent für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Standardfehlerschwellenwert in Prozent für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Standard-Infoschwellenwert in Prozent für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Monatliches Standardbudget für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Standardschwellenwert-E-Mail-Empfänger für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Standardwarnschwellenwert in Prozent für das Azure-Abonnement.",
      "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Aktivieren Sie die Allgemeinen Geschäftsbedingungen, um Produkte zu bestellen",
      "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Aktivieren Sie die NCE-Nutzungsbedingungen",
      "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Aktivieren Sie Power BI-Berichte",
      "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"Wenn Sie diese Option aktivieren, können Benutzer eine Bestellung planen",
      "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
      "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Befreiter Kunde für PSA-Export",
      "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Wenn dieses Feld aktiviert ist, berücksichtigen wir die Zustimmung des Kunden.",
      "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Dieses Feld wird aktualisiert, wenn mindestens einer der Kunden den MCA akzeptiert hat. Deaktivieren Sie dieses Kontrollkästchen, um die Annahme durch den/die Kunden erneut zu erzwingen.",
      "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Neueste Geschäftsbedingungen akzeptiert?",
      "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Transaktionslimit",
      "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Ganzzahlige Konstante",
      "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Zur internen Bezugnahme. Diese ID kann verwendet werden, um den Kunden mit einem Datensatz in Ihrem internen Buchhaltungssystem zu verknüpfen",
      "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Tag des Monats, an dem die Rechnungen automatisch genehmigt werden",
      "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Tag, an dem automatische Zahlungen verarbeitet werden",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Fälligkeitsdatum für Rechnungen",
      "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"Anzahl der für die Rechnungszahlung fälligen Tage",
      "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Tag des Monats, an dem Rechnungen automatisch abgeschlossen werden",
      "TENANT_CONFIG_DESC_INVOICE_NOTE":"Erscheint auf der Rechnung pdf",
      "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Stellen Sie die Verfügbarkeit des Zahlungsprofils vor der Kasse des Einkaufswagens fest",
      "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Erforderliche automatische Freigabe für Standort- und Abteilungsprodukte",
      "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Beauftragen Sie den Benutzer, die Zahlungsmethode zu übermitteln",
      "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Durch die Aktivierung dieser Option wird die neu hinzugefügte Adresse automatisch als Standardadresse für den Kunden festgelegt.",
      "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Durch die Aktivierung dieser Option wird die neu hinzugefügte E-Mail automatisch als Standard-E-Mail für den Kunden festgelegt.",
      "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Telefonnummer automatisch als Standardtelefonnummer für den Kunden festgelegt.",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Einmaliger Rechnungseigentümer (Typ)",
      "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Einmaliger Rechnungseigentümer (Kennung)",
      "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die einmaligen Einzelposten des Kunden angewendet werden soll.",
      "TENANT_CONFIG_DESC_PAN_NUMBER":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
      "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
      "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen Link",
      "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis anzuwenden ist, um den gewünschten Verkaufspreis für unbefristete Softwareabonnements zu erzielen.",
      "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
      "TENANT_CONFIG_DESC_PO_NUMBER":"Bestellnummer für Kunden definieren",
      "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Rechnungs-E-Mail an externe Buchhaltungsdienste senden",
      "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Aktivieren Sie die Funktion zum Übertragen von Rechnungen an externe Buchhaltungsdienste.",
      "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Aktivieren Sie dieses Flag, wenn das System Zahlungen verarbeiten soll (CC/ACH, falls zutreffend).",
      "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Wenn aktiviert, wird der im Partner Center angegebene Abonnementname angezeigt",
      "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"Die unbefristete Softwareabrechnung ist in den Rechnungen dieser Website enthalten.",
      "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"Setzen Sie ein Limit für die Anzahl der Lizenzen durch, die der Kunde erwerben kann",
      "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Die Zahlungsbedingungen, die auf Rechnungen markiert werden sollen, die an QuickBooks gebucht werden",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Geben Sie die Anzahl der Tage vor dem Verlängerungsdatum ein, nach denen Zustimmungs-E-Mails an Kunden gesendet werden.",
      "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Geben Sie die Anzahl der Tage nach der Zustimmungsbenachrichtigung an, nach denen die Erinnerungsbenachrichtigung an die Kunden gesendet werden soll.",
      "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"Die Abrechnung der Azure-Reservierung ist in den Rechnungen dieser Site enthalten.",
      "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Azure-Reservierung des Kunden angewendet werden soll.",
      "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"RI-Preismodell",
      "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie (%), um auf die RI-Nutzung des Kunden angewendet zu werden.",
      "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Der Kunde erhält die E-Mail mit den Rechnungsdetails, sobald die Rechnung genehmigt wurde und wenn E-Mail-Benachrichtigungen aktiviert sind",
      "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Der Kunde erhält eine E-Mail-Benachrichtigung über Rechnungen mit Nullbeträgen",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
      "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Sollte Standard für Kunden aktivieren.",
      "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Sollte den Kunden benachrichtigen",
      "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Sollte den Kunden von Instant Pay-Zahlungen befreien.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Abonnement innerhalb eines Kündigungsfensters von 7 Kalendertagen kündigen.",
      "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Wenn diese Option aktiviert ist, kann der Kunde das Software-Abonnementprodukt innerhalb einer Kündigungsfrist von 7 Kalendertagen kündigen.",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Ermöglichen Sie dem Kunden, die Enddaten des Abonnements anzupassen",
      "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Wenn diese Option aktiviert ist, kann der Kunde die Enddaten des Abonnements anpassen, um Gleichberechtigung zu erreichen",
      "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Nächstes Zeitplanverlängerungsmenü anzeigen",
      "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Aktivieren Sie diese Option, um nicht integrierte Abonnements für die Auswahl eines benutzerdefinierten Enddatums während des Kaufs anzuzeigen.",
      "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde ein ausgesetztes NCE-Abonnement reaktivieren.",
      "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Button „Besondere Qualifikationen anzeigen“",
      "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Abonnement aussetzen.",
      "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Produkt auf eine höhere SKU upgraden",
      "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Das Abonnement wird während einer einmaligen Rechnung übersprungen",
      "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis angewendet werden soll, um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
      "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"Die Abrechnung des Software-Abonnements ist in den Rechnungen dieser Website enthalten.",
      "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Wenn Sie diesen Wert auf true setzen, kann der Benutzer keine Einkäufe mehr tätigen.",
      "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Zeichenfolgenkonstante für den Kunden",
      "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Automatische Preisaktualisierung des Kundenprodukts unterstützen",
      "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Unterbrechen, wenn die Gefahrenschwelle erreicht ist",
      "TENANT_CONFIG_DESC_TAX_CODE":"Das Steuerkennzeichen wird verwendet, um den anwendbaren Steuerprozentsatz zu bestimmen",
      "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
      "TENANT_CONFIG_DESC_TAX_VALUE":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
      "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis angewendet werden soll, um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
      "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"Die Abrechnung von Abonnements Dritter ist in den Rechnungen dieser Website enthalten.",
      "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"Erzwingen Sie eine Begrenzung des Geldwerts des mengenbasierten Produktkaufs (in Kundenwährung).",
      "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"ConnectWise-Zusatztyp",
      "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Vorausrechnung",
      "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Basierend auf dem ausgewählten Format werden Ergänzungen in connectwise erstellt",
      "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Die Abrechnung des aktuellen Monats wird für die Rechnungsstellung auf einen bestimmten Monat in der Zukunft verschoben",
      "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen für den Mieter anzeigen",
      "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Aktivieren Sie das Abonnement für die Einhaltung der Gefahrenschwelle",
      "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Lassen Sie die automatische Freigabe unbenutzter Plätze zu",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Ermöglichen Sie dem Kunden, die Produktmenge aus der Liste zu ändern",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Dem Kunden erlauben, eine Transaktion zu initiieren",
      "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Ermöglichen Sie dem Kunden, Sitzplätze zu reduzieren",
      "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Wenden Sie Rabatte der Azure-Planstufe 2 auf den Kunden an",
      "TENANT_CONFIG_DISP_AUTO_SYNC":"Automatische Synchronisation",
      "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Azure aktivieren?",
      "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Azure für Unternehmen aktivieren?",
      "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie sich für die Azure-Verwendung",
      "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Verwalten von NCE-Produkten wird automatisch verlängert",
      "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Berücksichtigen Sie den ERP-Preis bei der Erneuerung ohne Makro",
      "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Auto-Release-Abkühlzeit",
      "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"CRM-Opportunity-ID",
      "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Kundenabrechnungstyp",
      "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Kundenwährung",
      "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Effektivität der automatischen Preisaktualisierung des Kundenprodukts",
      "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Standard-Gefahrenschwelle (%)",
      "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Standardfehlerschwelle (%)",
      "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Standard-Info-Schwellenwert (%)",
      "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Standard-Monatsbudget",
      "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Standardschwellenwert-E-Mail-Empfänger",
      "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Standardwarnschwelle (%)",
      "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Aktivieren Sie die Allgemeinen Geschäftsbedingungen, um Produkte zu bestellen",
      "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Aktivieren Sie die NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen",
      "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Zeitplanreihenfolge aktivieren",
      "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
      "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Befreiter Kunde für PSA-Export",
      "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Kundeneinwilligung aktiviert?",
      "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Microsoft-Kundenvereinbarung akzeptiert?",
      "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptierte Partner-Geschäftsbedingungen?",
      "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Transaktionslimit",
      "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
      "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Rechnungsreferenz-ID",
      "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Tag, an dem der Kunde Rechnungen sehen kann",
      "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Tag für die automatische Zahlung",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Fälligkeitsdatum für Rechnungen",
      "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Fälligkeitstage für Rechnungen",
      "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Tag, an dem die Rechnungen abgeschlossen sind",
      "TENANT_CONFIG_DISP_INVOICE_NOTE":"Rechnungsnotizen",
      "TENANT_CONFIG_DISP_INVOICE_TYPE":"Rechnungsart",
      "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendate Zahlungsprofil Warenkorb Kasse",
      "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Erforderliche automatische Freigabe für Standort- und Abteilungsprodukte",
      "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Mandatsprofil",
      "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Weisen Sie automatisch eine neue Adresse als Standard zu",
      "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Weisen Sie automatisch eine neue E-Mail als Standard zu",
      "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Weisen Sie automatisch eine neue Telefonnummer als Standard zu",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Einmaliger Rechnungseigentümer (Typ)",
      "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Einmaliger Rechnungseigentümer (Kennung)",
      "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag für einmalige Werbebuchungen",
      "TENANT_CONFIG_DISP_PAN_NUMBER":"PAN-Nummer",
      "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"PAN-Nummerntaste",
      "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen URL",
      "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag für unbefristetes Softwareabonnement",
      "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Ort der Lieferung",
      "TENANT_CONFIG_DISP_PO_NUMBER":"PO #",
      "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Rechnungs-E-Mail an externe Buchhaltungsdienste senden",
      "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Buchen Sie Rechnungen an einen externen Buchhaltungsdienst",
      "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Rechnungen automatisch bezahlen?",
      "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Abonnementname des Anbieters anzeigen",
      "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Unbefristeter Software-Rechnungseigentümer (Identifikator)",
      "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Sitzplatzlimit anwendbar",
      "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Zahlungsbedingungen für QuickBooks",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Erneuerung der Einwilligungs-Initialisierungsfrist",
      "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Benachrichtigungstage für die Erinnerung an die Erneuerung der Einwilligung",
      "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Besitzer der Azure-Reservierungsrechnung (Identifikator)",
      "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
      "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"RI-Preismodell",
      "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
      "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Rechnungs-E-Mails automatisch senden",
      "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Senden Sie Nullbetragsrechnungen",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Versand an Kontaktname",
      "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Versand an Kontaktnummer",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Versand an PAN-Nummer",
      "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Liefern an PAN Nummerntaste",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Versand an Steuerart",
      "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Versand zum Steuerwert",
      "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Sollte Standard für Kunden aktivieren",
      "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Befreite Instant Pay-Zahlung",
      "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Button „Besondere Qualifikationen anzeigen“",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Schaltfläche zum Abbrechen des NCE-Produkts anzeigen",
      "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Schaltfläche zum Abbrechen des Software-Abonnementprodukts anzeigen",
      "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Nicht integrierte Abonnements für benutzerdefiniertes Enddatum anzeigen",
      "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"NCE-Produkt-Reaktivierungsschaltfläche anzeigen",
      "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Schaltfläche zum Anhalten des NCE-Produkts anzeigen",
      "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen anzeigen",
      "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Schaltfläche NCE-Produkt-Upgrade anzeigen",
      "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Software-Abonnement",
      "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Rechnungsinhaber des Software-Abonnements (Identifikator)",
      "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Stoppen Sie alle Transaktionen",
      "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Eindeutige Kennung des Kunden",
      "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Automatische Preisaktualisierung des Kundenprodukts",
      "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Unterbrechen, wenn die Gefahrenschwelle erreicht ist",
      "TENANT_CONFIG_DISP_TAX_CODE":"Steuer-Code",
      "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Steuertyp",
      "TENANT_CONFIG_DISP_TAX_VALUE":"Steuerwert",
      "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Markup für Drittanbieterabonnements",
      "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Rechnungsinhaber des Drittanbieter-Abonnements (Kennung)",
      "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"Transaktionsbetragslimit",
      "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Bitte geben Sie den Wert {{tenantConfigName}} ein",
      "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Bitte geben Sie eine gültige Anzahl von {{tenantConfigName}} ein",
      "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"Die Konfiguration der automatischen Verlängerung verwalten ist für diesen Mandanten nicht aktiviert.",
      "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Die Kundenkonfiguration wurde erfolgreich zurückgesetzt",
      "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Kundenkonfiguration erfolgreich gespeichert",
      "TENANT_CONFIGURATION_SAVE_SUCCESS":"Kundenkonfiguration für {{customername}} erfolgreich aktualisiert",
      "TENANT_COUNTS":"Anzahl",
      "TENANT_CREATION_FAILED":"Mandantenerstellung fehlgeschlagen",
      "TENANT_EXCEEDED_COUNT_ERROR":"Anzahl der zu erstellenden Mandanten, die {{Count}} nicht überschreitet",
      "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":"Wenn Sie das Azure-Abonnement mit dem Mandanten erstellen möchten, kreuzen Sie es bitte an",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Fügen Sie dem Namen das Präfix „OTU“ hinzu",
      "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Fügen Sie „OTU“ als Präfix im Mandantennamen hinzu",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Weisen Sie den Mandantenadministrator als Eigentümer zu",
      "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Weisen Sie den Admin-Benutzer als Eigentümer des erstellten Abonnements zu",
      "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Mieter hinzufügen",
      "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Abonnements erstellen",
      "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Abteilung",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Domain Name",
      "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Domänennamen beginnen damit.",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Wählen Sie Azure-Planangebot aus",
      "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Verwenden Sie dies, um das richtige Planprodukt auszuwählen, um den Azure-Plan zuzuordnen",
      "TENANT_MANAGEMENT_LABEL_PREFIX":"Präfix",
      "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Fügen Sie dem Mandantennamen ein Präfix hinzu",
      "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Sequenz starten",
      "TENANT_MANAGEMENT_LABEL_SITE":"Seite ",
      "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Anzahl der Mieter",
      "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Name",
      "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Vorgang abgeschlossen. Weitere Einzelheiten finden Sie im Überwachungsprotokoll.",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Anfrage zum Hinzufügen von Mietern wird angenommen und bearbeitet",
      "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"Anfrage zum Hinzufügen von Mandanten fehlgeschlagen.",
      "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Bitte geben Sie eine Zahlenfolge an",
      "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"Das sequenzielle Inkrement wird als Suffix in Mandantennamen verwendet.",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Mandantenerstellungsstatus",
      "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Bitte warten Sie, während die Mandanten erstellt werden.",
      "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Anzahl der zu erstellenden Mandanten.",
      "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Mieternamen beginnen damit.",
      "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Zurück zu den Mietern",
      "TENANT_SEQUENCE":"Reihenfolge",
      "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, erklären Sie sich mit den oben genannten Nutzungsbedingungen einverstanden.",
      "TERM_AND_CONDITIONS_LINK_TEXT":"Nutzungsbedingungen des Partners",
      "TERM_AND_CONDITIONS_READ_TEXT":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen durch.",
      "TERM_DURATION_DESC_DAY(S)":"Tage)",
      "TERM_DURATION_DESC_DAYS":"Tag",
      "TERM_DURATION_DESC_MONTH":"Monat",
      "TERM_DURATION_DESC_MONTH(S)":"Monat(e)",
      "TERM_DURATION_DESC_YEAR":"Jahr",
      "TERM_DURATION_DESC_YEAR(S)":"Jahre)",
      "TERM_DURATION_DESC_YEARS":"Jahre",
      "TERM_DURATION_FILTER_DESC_DAYS":"Tage",
      "TERMS_AND_CONDITIONS":"Geschäftsbedingungen",
      "TERMS_AND_CONDITIONS_ACCEPT":"Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen",
      "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Allgemeine Geschäftsbedingungen erfolgreich akzeptiert.",
      "TERMS_AND_CONDITIONS_BACK":"Zurück zu den Allgemeinen Geschäftsbedingungen",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen</b>. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>Partner-Nutzungsbedingungen</a></li> </ul> < /br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
      "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen von Microsoft durch. </br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
      "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Bitte lesen Sie den unten bereitgestellten Vertrag, die Allgemeinen Geschäftsbedingungen von <b>{{partnerName}}</b> und Microsoft durch. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li> </ul> </br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Klicken Sie hier <a id='linkEzidebit'ng-click='vm.EzidebitAgreementClicked()'></a>, um die vollständige Ezidebit-Vereinbarung zu lesen",
      "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Ezidebit-Vereinbarung",
      "TERMS_AND_CONDITIONS_READ":"Ich habe die Allgemeinen Geschäftsbedingungen gelesen",
      "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"Ihre Anfrage kann nicht bearbeitet werden.",
      "TEST_EMAIL_LABEL":"Testen Sie die EmailId",
      "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Prüfung",
      "TEST_PARTNER_ACCESS_BTN_TEXT":"Prüfung",
      "TEST_SMTP_SETTING_TITLE":"Testen Sie die SMTP-Einstellungen",
      "TEXT_ACH":"ACH",
      "TEXT_ACTIONS":"Aktionen",
      "TEXT_BILL_FROM":"Bill From",
      "TEXT_BILL_TO":"Gesetzesentwurf für",
      "TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
      "TEXT_EMAIL_ID":"E-Mail-ID",
      "TEXT_GROUP_NAME":"Gruppenname",
      "TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
      "TEXT_INVOICE_DATE":"Rechnungsdatum",
      "TEXT_INVOICE_NUMBER":"Rechnungsnummer",
      "TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
      "TEXT_MONTHLY_BUDGET":"Monatliches Budget",
      "TEXT_OFF":"AUS)",
      "TEXT_PLAN_ID":"Plan-ID",
      "TEXT_RESPONSIBLE_OFFICIAL":"Verantwortlicher Beamter",
      "TEXT_SAAS_SUBSCRIPTION_STATUS":"Saas-Abonnementstatus",
      "TEXT_SIGN_IN_REQUIRED":"Anmelden erforderlich",
      "TEXT_TENANT_ID":"Mieter-ID",
      "TEXT_THRESHOLDS":"Schwellenwerte",
      "TEXT_VAT_NUMBER":"Umsatzsteuer-Identifikationsnummer",
      "THE_SEQUENCE_LABEL_THE_STARTING_NAME":"Der Sequenzname beginnt mit",
      "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Vereinbarung auswählen",
      "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Vervollständigen Sie die Zuordnung für die C3-Entität",
      "THIRD_PARTY_ENTITY_MAPPING":"Zuordnung von Drittparteien",
      "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für den Kunden <b>{{customerName}}</b> wirklich speichern?",
      "THIRD_PARTY_PRODUCT_MAPPING":"Produktzuordnung von Drittanbietern",
      "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die ausgewählten Drittanbieterprodukte wirklich speichern?",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Aktion",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Zustimmung",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"C3-Kunde",
      "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"PSA-Kunde",
      "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"PSA-Dienst auswählen",
      "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Wählen Sie PSA-Kunde aus",
      "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Zuordnung von Drittanbieterabonnements",
      "TIME_DESC_DAY":"Tag",
      "TIMER_DESC_DAYS":"Tage",
      "TITLE_COUPON_CODE_TEXT":"Gutscheincodes",
      "TITLE_CUMULATIVE_TAXES":"Kumulierte Steuern",
      "TITLE_ROLE_SELECT":"Wählen Sie Rolle",
      "TITLE_SUB_TAXES":"Untersteuern",
      "TITLE_TAGS":"Stichworte",
      "TITLE_TEXT":"Willkommen im CSP Control Center",
      "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"um Azure-Abonnements zu integrieren",
      "TOOL_TIP_TEXT_HIDE":"Verstecken",
      "TOOL_TIP_TEXT_SHOW":"Zeigen",
      "TOOLTIP_COUPON_CODE_TEXT":"Gutscheincode",
      "TOOLTIP_TEXT_DELETE_PSA_LOG":"Protokoll deaktivieren",
      "TOOLTIP_TEXT_DISABLE_PRODUCT":"Produkt deaktivieren",
      "TOOLTIP_TEXT_EDIT_NAME":"Namen bearbeiten",
      "TOOLTIP_TEXT_EDIT_QUANTITY":"Menge bearbeiten",
      "TOOLTIP_TEXT_ENABLE_PRODUCT":"Produkt aktivieren",
      "TOOLTIP_TEXT_LINK_PRODUCT":"Produkt verknüpfen",
      "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"Linkprodukt wird nicht unterstützt",
      "TOOLTIP_TEXT_QUANTITY":"Menge",
      "TOUR_POPUP_CONTENT":"<b>Willkommen an Bord! </b></br> Sehen Sie sich eine kurze Tour durch die Funktionen in der Anwendung an.",
      "TOUR_POPUP_DISMISS_BUTTON":"Entlassen",
      "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Machen Sie eine Tour",
      "TOUR_POPUP_TITLE":"Willkommen zu",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Aktionen",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Menge",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Kategorie",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Rate",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Beschreibung",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Rabatt",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Produktname",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Menge",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Rate",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"Aufgerollter Betrag",
      "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"Besteuert",
      "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Transaktionsbetrag überschritten",
      "TRANSACTION_LIMIT_MESSAGE":"Verbleibendes Limit {{RemainingAmount}} von {{TotalLimit}}",
      "TRANSACTION_TYPE_PURCHASE":"Neuer Einkauf",
      "TRANSACTION_TYPE_UPDATE":"Mengenaktualisierung",
      "TRANSACTION_TYPE_UPGRADE":"Abonnement-Upgrade",
      "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Derzeit besteht der Antrag, das Testangebot in ein Abonnement umzuwandeln; An diesem Produkt dürfen keine Eingriffe vorgenommen werden.",
      "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Die beiden nächstgelegenen gültigen Werte sind {{lower}} und {{upper}}.",
      "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Rechnungskreislauf",
      "TRIAL_OFFER_PARENT_BILLING_TYPE":"Abrechnungsart",
      "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Kategorie",
      "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Verbrauchsart",
      "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"Ist automatisch erneuerbar",
      "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Produktname",
      "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Anbieter",
      "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Verkaufspreis",
      "TRIAL_OFFER_PARENT_SALE_TYPE":"Verkaufsart",
      "TRIAL_OFFER_PARENT_TERM_DURATION":"Laufzeit",
      "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Setzen Sie das Abonnement nach der Testphase fort",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Anleitung",
      "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Notiz",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"Die Testphase dauert einen Monat, danach wird das Produkt gekündigt.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"Tage, und das Produkt wird danach storniert.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" Die Testzeit dauert",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"Durch Klicken auf „Abonnement fortsetzen“ wird das Produkt nach Ablauf der Testphase gekauft und die Abrechnung beginnt entsprechend.",
      "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Sie haben die Flexibilität, Ihr Abonnement über den Testzeitraum hinaus zu verlängern, indem Sie auf die Schaltfläche „Abonnement kaufen“ klicken.",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"Das standort-/abteilungsübergreifende Testangebot wird nicht in ein Abonnement umgewandelt.",
      "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Ein Abonnement für eine Menge wird bei der Betriebseinheit des Kunden erworben.",
      "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Bitte geben Sie einen gültigen Wert ein",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"Die maximale Menge beträgt 10.000",
      "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"Die Mindestmenge beträgt 1",
      "TRIAL_PERIOD_DAYS_TEXT":"Tage",
      "TROUE_BTN_TEXT_END_TOUR":"Ende der Tour",
      "TROUE_BTN_TEXT_NEXT":"Weiter &raquo;",
      "TROUE_BTN_TEXT_PREV":"&laquo; Vorher",
      "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"Auf dem PC kann kein Azure-Plan gefunden werden",
      "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Der Kunde wurde nicht erfolgreich zugewiesen",
      "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Möchten Sie die Zuordnung von {{customer}} aufheben?",
      "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Reseller ist nicht erfolgreich zugeordnet",
      "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Nicht zugewiesener Kunde eines Account Managers erfolgreich",
      "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Nicht zugewiesener Reseller eines Account Managers erfolgreich",
      "UNIQUE_NAME_ERROR":"Dieser Name existiert bereits, bitte wählen Sie etwas anderes.",
      "UNSUPPORTED_FILE_TYPE_SELECTED":"Es werden nur Dateien des Typs jpg, svg, png, gif, bmp unterstützt",
      "UP_GRADE_AZURE_SUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement auf den Azure-Plan.",
      "UPDATE_CART_LINE_ITEM":"Aktualisierter Warenkorbartikel",
      "UPDATE_CART_LINE_ITEM_FAILURE":"Fehler beim Aktualisieren des Warenkorbartikels",
      "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Globale Kundenkonfigurationen erfolgreich aktualisiert",
      "UPDATE_DEFAULT_VALUE":"Als Standard markieren",
      "UPDATE_DUE_DATE_NAME":"Geburtstermin",
      "UPDATE_INVOICE_LINEITEM_PONUMBER":"Rechnungsposition Bestellnummer",
      "UPDATE_INVOICE_PONUMBER":"PO #",
      "UPDATE_INVOICE_PROPERTIES":"Rechnungseigenschaft aktualisieren",
      "UPDATE_MANAGE_RENEWAL_TITLE":"Update-Erneuerung",
      "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Beim Aktualisieren der Partnereinstellungen ist ein Fehler aufgetreten",
      "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Aktualisieren der Steuerdetails ist ein Fehler aufgetreten",
      "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"Das Aktualisieren der Produkte ist nicht zulässig, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
      "UPDATE_PROFILE":"Profil erfolgreich aktualisiert",
      "UPDATE_PROFILE_FAILURE":"Profil konnte nicht aktualisiert werden",
      "UPDATE_RESELLER_CONFIGURATION":"Aktualisieren Sie die Reseller-Konfiguration",
      "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Reseller-Konfiguration konnte nicht aktualisiert werden",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Aktualisieren Sie die Standardzahlungsoption des Wiederverkäufers",
      "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Update Reseller Standard Zahlungsoption Fehler",
      "UPDATE_RESELLER_PAYMENT_PROFILE":"Aktualisiertes Reseller-Zahlungsprofil",
      "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Reseller-Zahlungsprofil konnte nicht aktualisiert werden",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Reseller Plan Produktpreis aktualisiert",
      "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Fehler beim Aktualisieren des Produktpreises des Resellerplans",
      "UPDATE_RESELLER_PROFILE":"Reseller-Profil aktualisieren",
      "UPDATE_RESELLER_PROFILE_FAILURE":"Reseller-Profil konnte nicht aktualisiert werden",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Der Standardwert des Anbieterkunden wurde aktualisiert",
      "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Fehler beim Aktualisieren des Standardwerts des Provider-Kunden",
      "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Account Manager Details erfolgreich aktualisiert",
      "UPDATED_FAILED_C3_PSA_DATA_FAIL":"PSA-Datenaktualisierung fehlgeschlagen",
      "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"Die Datenaktualisierung ist fehlgeschlagen. Andere Optionen sind verfügbar, sobald Sie auf „OK“ klicken.",
      "UPDATED_PAGE_MODE_TO":"Die Ansicht wurde erfolgreich auf {{PageMode}} aktualisiert",
      "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"C3-PSA-Daten wurden erfolgreich mit den neuesten Daten aus dem externen PSA-System aktualisiert",
      "UPGRADE_ELIGIBILITY_CHECK":"Überprüfen Sie die Upgrade-Berechtigung",
      "UPGRADE_FAILED_MSG":"Das Azure-Upgrade ist fehlgeschlagen",
      "UPGRADE_SUCCESS_MSG":"Upgrade-Anfrage wird angenommen und bearbeitet.",
      "UPGRADE_SYNC_PUPUP_TITLE":"Aktualisieren Sie Azure",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Richten Sie sich an das Vertragsstartdatum aus",
      "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Richten Sie das Wirksamkeitsdatum des Produkts an die nächste aktuelle Vertragslaufzeit aus.Bitte beachten Sie, dass das nächste aktuelle Datum nur auf monatlicher Basis berechnet wird, auch wenn die Vertragslaufzeit jährlich ist.",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Laden Sie Rechnungen in die QuickBooks Online-Stapel-ID hoch",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Laden Sie die Fehlermeldung \\''Rechnungen in Quickbooks hochladen\\'' hoch",
      "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie die ausgewählten Rechnungen wirklich in <b>QuickBooks Online</b> hochladen?",
      "UPLOAD_INVOICES_TO_XERO_BATCHID":"Laden Sie Rechnungen in die Xero-Stapel-ID hoch",
      "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie die ausgewählten Rechnungen wirklich auf <b>Xero</b> hochladen?",
      "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Hochladen von Rechnungen in die Xero-Fehlermeldung",
      "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Reseller-Details hochladen",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"Der Abonnementverlauf konnte nicht in den externen Dienst hochgeladen werden",
      "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Laden Sie den Abonnementverlauf erfolgreich in den externen Dienst hoch",
      "UPLOAD_TO_PSA_SELECT_ALL":"Wählen Sie Alle",
      "Usage":"Nutzung melden",
      "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"Sie können den -ve-Prozentsatz nicht auf einen Wert des verfügbaren Prozentsatzes aktualisieren.",
      "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Sie können den Prozentsatz nicht auf einen Wert aktualisieren, der über dem verfügbaren Prozentsatz liegt.",
      "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Nutzungsbericht abrufen",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Abonnement-ID",
      "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Abonnementname",
      "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Rechnung an den Kunden",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Gebühr END Datum",
      "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Startdatum der Ladung",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Kosten für den Kunden",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Kosten für den Partner (nach Steuern)",
      "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Kosten für Partner (vor Steuern)",
      "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Währungscode",
      "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Kundenname",
      "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Nutzungsbericht herunterladen",
      "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Name des Mieters",
      "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Nutzungsbericht",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Gefahrenschwelle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Fehlerschwelle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Gruppenname",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Wähle die Gruppe",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Info-Schwelle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Vor Kunden verstecken",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Monatliches Budget",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Warnschwelle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Zurück zu Abonnementbenutzern",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonnementbenutzer verwalten",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Email",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Rolle",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Mitwirkender",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Inhaber",
      "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Leser",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Nutzungsdetails exportieren",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Rechnungsübersicht",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Verwendungsdetails von",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Verbringen",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Verbrauchte Einheiten",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Ressourcentyp",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"Azure-Abonnements für",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Alle Abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Abrechnungszeitraum",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Gruppe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Benutzer",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Aktionen",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Geschätzte Kosten",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Gruppe",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Abonnement-ID",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
      "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Schwellenwerte",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Benutzer zum Abonnement hinzufügen",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonnementbenutzer von",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Email",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Name",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Rolle",
      "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Benutzer löschen",
      "USER_CREATED_POPUP":"Benutzer erstellt",
      "USER_CREATION_SUCCESS_MESSAGE":"Benutzer {{username}} erfolgreich erstellt",
      "USER_DETAILS_NOT_FOUND_BODY":"Die angemeldeten Benutzerdetails konnten nicht gefunden werden. Bitte wenden Sie sich an den Administrator!",
      "USER_DETAILS_NOT_FOUND_HEADER":"Details nicht gefunden",
      "USER_HAS_BEEN_CREATED":"Der Benutzer wurde mit den folgenden Details erstellt",
      "USER_LICENSE_ASSIGNMENT_BATCH_ID":"BatchId für die Zuweisung von Benutzerlizenzen",
      "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Stapelstatus der Benutzerlizenzzuweisung",
      "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Getan",
      "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Lizenzzuweisungsstatus",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Kundenname",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Benutzer",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"E-Mail",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Name des Mieters",
      "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Mieter-ID",
      "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"Der Status kann nicht aktualisiert werden. Bitte wenden Sie sich an den Support.",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Erweiterung",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Bündeln",
      "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Verknüpft",
      "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Benutzerentfernungsstatus",
      "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
      "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Übernehmen Sie Änderungen auf Addons",
      "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Benutzer des Produkts",
      "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Beim Freigeben der Sitze ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
      "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Es sollte mindestens eine aktive Lizenz vorhanden sein. Bitte setzen Sie das Produkt aus, wenn Sie es nicht mehr verwenden möchten.",
      "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Antrag auf Sitzfreigabe wird bearbeitet.",
      "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Erfolgreich hochgeladen",
      "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"Die Anforderung zum Hinzufügen von Lizenzen wird akzeptiert und bearbeitet.",
      "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"Der Antrag auf Massenzuweisung von Lizenzen wird angenommen und bearbeitet.",
      "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Zugewiesen",
      "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Gesamtzahl der Sitzplätze",
      "USER_LISTING_FILTER_LABEL":"E-Mail-ID",
      "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Suche nach Vorname, Nachname, E-Mail-ID",
      "USER_LISTING_SEARCH_BUTTON":"Suche",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Benutzerzuweisungsbericht exportieren",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entitäten",
      "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Wählen Sie mindestens eine Entität aus",
      "USER_REMOVED":"Benutzer erfolgreich entfernt",
      "USER_ROLE_LIST_LABEL_ROLE_NAME":"Rollenname",
      "USER_ROLE_LIST_LABEL_SCOPE":"Bereichsname",
      "USER_ROLE_LIST_LABEL_USER_NAME":"Nutzername",
      "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Benutzer erfolgreich hinzugefügt",
      "USERCONTROLLER_USERUPDATESUCCESS":"Benutzerdetails erfolgreich aktualisiert",
      "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Benutzerzugriff widerrufen",
      "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Hinzufügen",
      "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Bearbeiten",
      "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Benutzer unter",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"ist nicht verfügbar",
      "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"ist verfügbar",
      "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Benutzerinformation",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Aktivieren/deaktivieren Sie die Kontrollkästchen neben den Angeboten zum Zuweisen bzw. Freigeben von Lizenzen",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Wenn für ein Angebot nicht genügend kostenlose Lizenzen verfügbar sind, wird ein Kauf getätigt, bevor der Auftrag ausgeführt wird",
      "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"zeigt an, dass ein Abonnement von C3 bereitgestellt wird. Bitte warten Sie, bis der Vorgang abgeschlossen ist.",
      "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Anzeigename",
      "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Email",
      "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Vorname",
      "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Anleitung",
      "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nachname",
      "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Verwendungsort",
      "USERS_ADD_EDIT_FORM_SEATS":"Sitze",
      "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Zurück zu den Benutzern",
      "USERS_ADD_OR_EDIT_CAPTION_1":"Hinzufügen",
      "USERS_ADD_OR_EDIT_CAPTION_2":"Bearbeiten",
      "USERS_ADD_OR_EDIT_CAPTION_3":"Benutzer",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Email",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Vorname",
      "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Nachname",
      "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Der letzte Massen-Upload zum Erstellen von Benutzern ist abgeschlossen!",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Externen Benutzern können keine Kundenadministrationsrechte gewährt werden",
      "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"Sind Sie sicher, dass Sie dem Kunden Administratorrechte gewähren möchten?",
      "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
      "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Benutzer",
      "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Onboarding Analytics",
      "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Ihre Anfrage zum Erstellen von Benutzern wird in die Warteschlange gestellt. Bitte kehren Sie nach einiger Zeit zu dieser Seite zurück, um Updates zu erhalten. Der Vorgang dauert in der Regel einige Minuten.",
      "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Beim Exportieren der Fehlerdetails ist ein Fehler aufgetreten",
      "USERS_BULK_CREATION_FIX_ERRORS":"Bitte korrigieren Sie die in der gerade heruntergeladenen Datei gemeldeten Fehler und versuchen Sie es erneut.",
      "USERS_BULK_CREATION_STATUS_SUCCESS":"Ihre Anforderung zum Erstellen von Benutzern wurde erfolgreich abgeschlossen",
      "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Erhalten Sie hochgeladene Benutzerdetails",
      "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"{{AvailableUnits}} verbleibt außerhalb von {{TotalUnits}}",
      "USERS_BUY_MORE":"Kauf mehr",
      "USERS_CREATE_SUCCESS":"Benutzer {{username}} erfolgreich erstellt",
      "USERS_DELETE_CONFIRM":"Alle zugewiesenen Lizenzen werden entfernt und {{userName}} wird gelöscht. Wollen Sie fortfahren?",
      "USERS_DELETE_EXISTENCE_CONFIRM":"Dieser Benutzer hat in C3 Zugriff, der gelöscht wird. Wollen Sie fortfahren?",
      "USERS_DELETE_SUCCESS":"Das Löschen des {{username}} war erfolgreich",
      "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Daten nicht gefunden. Bitte versuch es später",
      "USERS_GRANT_ACCESS_CONFIRM":"Sind Sie sicher, dass Sie {{username}} das Administratorrecht für Kunden gewähren möchten?",
      "USERS_GRANT_ACCESS_SUCCESS":"Gewährt erfolgreich Zugriff auf {{username}}",
      "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Das Gewähren des Zugriffs auf externe Benutzer ist nicht zulässig",
      "USERS_IGNOREERRORS_IGNOR_ERRORS":"Sind Sie sicher, dass Sie fortfahren möchten?",
      "USERS_LICENSE_FREE_OUT_OF":"Lizenzen frei von",
      "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Arbeiten zum Zuweisen von Lizenzen für den Benutzer {{username}}. Dieser Vorgang würde einige Minuten dauern.",
      "USERS_LICENSES_REVOKE_SUCCESS":"Lizenzen von {{username}} erfolgreich widerrufen",
      "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Lizenzen von {{username}} erfolgreich widerrufen. Arbeiten an der Zuweisung neuer Lizenzen. Dieser Vorgang dauert einige Minuten.",
      "USERS_LICENSES_UPDATE_SUCCESS":"Lizenzen für Benutzer {{username}} wurden erfolgreich aktualisiert",
      "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Neuen Benutzer hinzufügen",
      "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Upload mehrerer Benutzer",
      "USERS_LIST_CAPTION_TEXT_USERS":"Benutzer",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Benutzerdetails bearbeiten",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Gewähren Sie Zugriff auf das Portal",
      "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Passwort zurücksetzen",
      "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Lizenzen anzeigen",
      "USERS_LIST_TABLE_HEADER_ACTION":"Aktionen",
      "USERS_LIST_TABLE_HEADER_EMAIL_ID":"E-Mail-ID",
      "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Ist aktiv",
      "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Keine Benutzerrollen zugewiesen.",
      "USERS_LIST_TABLE_HEADER_ROLE":"CSP-Rolle (n)",
      "USERS_LIST_TABLE_HEADER_USER_NAME":"Nutzername",
      "USERS_LIST_TABLE_ROLE_TOOLTIP":"Rollen anzeigen",
      "USERS_PASSWORD_RESET_SUCCESS":"Das Passwort für {{username}} wurde erfolgreich zurückgesetzt",
      "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Passwort erfolgreich an die angegebenen Empfänger gesendet",
      "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Passwort zurückgesetzt erfolgreich",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"Das Zurücksetzen des Passworts ist erfolgreich. Das Passwort lautet: <a ngclipboard='' data-clipboard-text='{{Password}}' ng-click='vm.confirmCopy()'><b>{{Password}}</b></a><br/><br/><b> Nachdem Sie diese Seite verlassen haben, können Sie dieses temporäre Passwort nicht mehr anzeigen. </ b> <br/> <br/> Wenn Sie das Passwort per E-Mail senden möchten, geben Sie dies bitte an Die durch Kommas getrennten Empfänger im Textfeld unten drücken andernfalls auf Abbrechen. <br/>",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"Das Zurücksetzen des Passworts war erfolgreich. Das Passwort lautet:",
      "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Nachdem Sie diese Seite verlassen haben, können Sie dieses temporäre Passwort nicht mehr anzeigen.</b><br/><br/>Wenn Sie das Passwort per E-Mail versenden möchten, geben Sie die durch Kommas getrennten Empfänger bitte in das Textfeld unten ein. Andernfalls drücken Sie auf „Abbrechen“.<br/>",
      "USERS_REVOKE_ACCESS_CONFIRM":"Sind Sie sicher, dass Sie {{UserRoleDescription | translate}} Privileg von {{username}}",
      "USERS_REVOKE_ACCESS_SUCCESS":"Zugriff von {{username}} erfolgreich widerrufen",
      "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Erhalten Sie hochgeladene Benutzerdetails",
      "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Versuchen Sie einen neuen Upload!",
      "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Fehler anzeigen und beheben",
      "USERS_STATUS_CAPTION_STATUS":"Status",
      "USERS_STATUS_WARNING_MESSAGE":"Die Erstellung von Benutzern aus dem letzten Upload ist noch nicht abgeschlossen. Bitte versuchen Sie es erneut, indem Sie auf der Benutzerseite auf die Schaltfläche \\''BulkUserUpload\\'' klicken.",
      "USERS_TABLE_HEADER_EMAIL":"Email",
      "USERS_TABLE_HEADER_NAME":"Name",
      "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
      "USERS_UPDATE_LICENCES_QUEUED":"Lizenzen werden zur Aktualisierung in die Warteschlange gestellt",
      "USERS_UPDATE_SUCCESS":"Benutzerdetails für {{username}} wurden erfolgreich aktualisiert",
      "USERS_UPDATELICENCES_SUCCESS":"Lizenzen erfolgreich aktualisiert",
      "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Benutzerdetails für {{username}} wurden erfolgreich aktualisiert",
      "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Hochladen",
      "USERS_UPLOAD_DATA_INVALID_ERROR":"In den hochgeladenen Daten wurden Fehler festgestellt. Laden Sie die Fehler herunter, beheben Sie sie und versuchen Sie es erneut.",
      "USERS_UPLOAD_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
      "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
      "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Entfernen",
      "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Benutzerinformation",
      "USERS_UPLOAD_FORM_INSTRUCTIONS":"Anleitung",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Laden Sie Kontodaten für mehrere Benutzer im CSV-Format hoch. Laden Sie eine Z.Bdatei .csv <strong><a ng-click='vm.DownloadTemplate()'> herunter. Laden Sie eine Z.Bdatei .csv </a></strong> als Referenz herunter.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Diese Datei kann verwendet werden, um mehrere Benutzerkonten für einen Kunden zu erstellen. Aktualisieren oder löschen Sie keine vorhandenen Texte oder Daten in dieser Datei. Benutzerkonten werden mit Benutzerberechtigungen (kein Administrator) erstellt",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Der Vorname ist ein optionales Feld, das null bis 50 Zeichen unterstützt. Ein Z.B: Chris.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Nachname ist ein optionales Feld, das null bis 50 Zeichen unterstützt. Ein Z.B: Grün.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Der Anzeigename ist ein Pflichtfeld, das ein bis 50 Zeichen unterstützt. Ein Z.B: Chris Green.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"E-Mail ist ein Pflichtfeld. Geben Sie in dieses Feld für jeden Benutzerdatensatz eine gültige E-Mail-Adresse ein. Ein Beispiel: chris@contoso.com.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Das Feld für die Statusaktualisierung wird automatisch ausgefüllt, um den Validierungsstatus anzuzeigen. Lassen Sie dieses Feld leer.",
      "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Laden Sie die Datei hoch und klicken Sie auf <strong class=\"text-uppercase\">Senden</strong>.",
      "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Datei hochladen",
      "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Verwendungsort",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Zurück zu den Lizenzen",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Zurück zu den Benutzern",
      "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Lizenzen bearbeiten",
      "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Zugewiesene Lizenzen für",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Zuweisungsdatum der Lizenz",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Produkt",
      "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Zusammenfassung",
      "VALIDATE_LIST":"Liste validieren",
      "VALIDATE_PRODUCT_QUANTITY":"{{product}} Menge {{quantity}} sollte zwischen {{minQuantity}} und {{maxQuantity}} liegen",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"Der Abonnementverlauf für den externen Dienst konnte nicht validiert werden",
      "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Abonnementverlauf für externen Dienst erfolgreich validiert",
      "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Bereits mit diesem Mieter verbundener Kunde.",
      "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Dieser Gutscheincode existiert bereits",
      "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Im Gutscheincode sind nur alphanumerische Zahlen erlaubt!",
      "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Wir gewähren Rabatte nur zwischen 0 und 100",
      "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Diese E-Mail '{{emailAddress}}' wird bereits verwendet.",
      "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Bitte geben Sie eine gültige Nummer ein",
      "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Füllen Sie vor dem Absenden bitte alle erforderlichen Felder aus.",
      "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"Das Rechnungsdatum muss vor dem Fälligkeitsdatum liegen.",
      "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Bitte geben Sie eine gültige URL ein oder geben Sie die Standard-URL als '/#/contact/' ein.",
      "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"Das Startdatum muss vor dem Enddatum liegen.",
      "VALIDATION_MESSAGE_FOR_URL":"Bitte geben Sie eine gültige URL ein.",
      "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Bitte geben Sie eine gültige URL ein oder geben Sie die Standard-URL als \\''home.support\\'' ein.",
      "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Bitte geben Sie eine gültige Postleitzahl ein",
      "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"Die IIN-Länge des Unternehmens sollte kleiner oder gleich 22 sein",
      "VALIDATION_MESSAGE_MAX_LENGTH_50":"Dieses Feld akzeptiert maximal 50 Zeichen.",
      "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"Die IIN-Länge des Unternehmens sollte größer als 4 sein",
      "VALIDATION_MESSAGE_MINIMUM_0":"Mindestwert ist 0",
      "VALIDATION_MESSAGE_MINIMUM_1":"Mindestwert ist 1",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Bitte geben Sie eine gültige Email-Adresse ein",
      "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Bitte geben Sie eine gültige Telefonnummer ein",
      "VALIDATION_MESSAGE_REQUIRED":"Dieses Feld wird benötigt",
      "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Geben Sie einen gültigen Umrechnungskurs ein",
      "VALIDITY_TEXT_FROM":"Von",
      "VALIDITY_TEXT_TO":"Zu",
      "VALIDITY_TYPE_DESC_DAYS":"Tage)",
      "VALIDITY_TYPE_DESC_MONTHS":"Monat(e)",
      "VALIDITY_TYPE_DESC_YEARS":"Jahre)",
      "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Gültigkeitsart",
      "VALIDITY_TYPE_NAME_DAYS":"Tage)",
      "VALIDITY_TYPE_NAME_MONTHS":"Monat(e)",
      "VALIDITY_TYPE_NAME_YEARS":"Jahre)",
      "VALUE_SHOULD_BE_GREATER_THAN":"Der Wert sollte größer als 0 sein",
      "VAT_NUMBER_DESC":"Umsatzsteuer-Identifikationsnummer für den Kunden",
      "VAT_NUMBER_DESC_FOR_RESELLER":"Umsatzsteuer-Identifikationsnummer für Wiederverkäufer",
      "VAT_NUMBER_DISP":"Umsatzsteuer-Identifikationsnummer",
      "VAT_NUMBER_DISP_FOR_RESELLER":"Umsatzsteuer-Identifikationsnummer",
      "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Token validieren",
      "VIEW_ALL_SELECTION":"Wählen Sie Alle",
      "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Weitere Details anzeigen",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Verwendungsposten anzeigen",
      "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Verwendungsposten anzeigen",
      "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Rechnung erstellen",
      "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Profitability Report neu laden",
      "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Nutzer hinzufügen",
      "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Benutzer löschen",
      "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Email",
      "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Name",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Tags zuweisen",
      "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rollen bearbeiten",
      "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Partnerbenutzer hinzufügen",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Tags zuweisen",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Partner löschen",
      "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rollen bearbeiten",
      "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Partner User Management",
      "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Benutzerverwaltung",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Email",
      "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Rollen",
      "VIEWS_TABLE_HEADER_TEXT_ROLES":"Rollen",
      "viewuploadhistory":"Nutzung melden",
      "WAITING_FOR_APPROVAL":"warten auf die Bestätigung",
      "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":"Sie haben alle abgewählt",
      "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":"Sie haben alle ausgewählt",
      "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Geben Sie einen Entitätsnamen ein",
      "WEBHHOOK_LABEL_EFFECTIVE_FROM":"Gültig ab",
      "WEBHHOOK_LABEL_EFFECTIVE_TO":"Wirksam für",
      "WEBHHOOK_LABEL_ENTITY":"Einheit",
      "WEBHHOOK_LABEL_EVENT":"Veranstaltung",
      "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Getaggte Entitäten",
      "WEBHOOK_BREADCRUMB_ADD":"Webhook erstellen",
      "WEBHOOK_BUTTON_ACTIONS_ADD":"Erstellen Sie eine Webhook-Benachrichtigung",
      "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
      "WEBHOOK_CANCEL_NOTIFICATION":"Stornieren",
      "WEBHOOK_DESCRIPTION_TOOLTIP":"Beschreibung eingeben",
      "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Wählen Sie „Gültig bis heute“.",
      "WEBHOOK_END_DATE_PLACEHOLER":"Endtermin",
      "WEBHOOK_ENTITY_TOOLTIP":"Kategorie wählen",
      "WEBHOOK_EVENT_TOOLTIP":"Wählen Sie Ereignis",
      "WEBHOOK_HEADER_ACTIONS":"Aktionen",
      "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Assoziierte Einheiten",
      "WEBHOOK_HEADER_CREATET_DATE":"Erstellungsdatum",
      "WEBHOOK_HEADER_DESCRIPTION":"Beschreibung",
      "WEBHOOK_HEADER_EFFECTIVE_FROM":"Gültig ab",
      "WEBHOOK_HEADER_EFFECTIVE_TO":"Wirksam für",
      "WEBHOOK_HEADER_EVENTNAME":"Veranstaltung",
      "WEBHOOK_HEADER_TITLE":"Titel",
      "WEBHOOK_LABEL_DESCRIPTION":"Beschreibung",
      "WEBHOOK_LABEL_EFFECTIVE_TO":"Wirksam zu",
      "WEBHOOK_LABEL_ENTITY_TEXT":"Kategorie",
      "WEBHOOK_LABEL_EVENT_TEXT":"Veranstaltung",
      "WEBHOOK_LABEL_TITLE_TEXT":"Titel",
      "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"Webhook-Methode",
      "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Anzahl der Wiederholungen",
      "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"Webhook-URL",
      "WEBHOOK_METHOD_METHOD_TOOLTIP":" Methode, die beim Senden von Daten an ein externes System über einen Webhook verwendet werden soll.",
      "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
      "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Beim Senden der Webhook-Benachrichtigung ist ein Fehler aufgetreten",
      "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich gesendet",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Beschreibung",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"Ich würde",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Alte Wiederholungsanzahl",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"Alte Webhook-URL",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Produktname",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Anzahl der Wiederholungen",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Titel",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"Webhook-URL",
      "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"Webhook-Methode",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Es kann nur ein Produkt ausgewählt werden.",
      "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Fehler bei der Produktauswahl",
      "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich erstellt",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Alle Produkte wurden ausgewählt",
      "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"Produkt wurde ausgewählt.",
      "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Zugehörige Entitätselemente",
      "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich aktualisiert",
      "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Alle Produkte wurden ausgewählt.",
      "WEBHOOK_SAVE_NOTIFICATION":"einreichen",
      "WEBHOOK_SELECT_PRODUCT_BUTTON":"Wählen",
      "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Wählen Sie das zugehörige Entitätselement aus",
      "WEBHOOK_START_DATE_PLACEHOLER":"Anfangsdatum",
      "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Webhook-Benachrichtigung",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Dies überschreitet die maximale Länge von 30",
      "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Bitte stellen Sie sicher, dass der Wert zwischen 0 und 3 liegt",
      "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
      "WEBHOOK_TITLE_TOOLTIP":"Geben Sie den Titel ein",
      "WEBHOOK_URL_TOOLTIP":" Geben Sie die Ziel-URL ein, an die das System HTTP-Anfragen sendet, um einen externen Dienst über bestimmte Ereignisse oder Aktualisierungen zu benachrichtigen. Diese URL sollte in der Lage sein, Webhook-Nutzlasten zu empfangen und zu verarbeiten.",
      "WEBHOOK_VALIDATE_BUTTON":"Bestätigen",
      "WEBHOOK_VALIDATE_URL":"Bitte validieren Sie die URL",
      "WEBHOOK_VALIDATE_URL_BUTTON":"URL validieren",
      "WEBHOOK_VALIDATION_ERROR_MESSAGE":"Ungültige Webhook-URL",
      "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"Die Webhook-URL ist gültig",
      "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Anzahl der Versuche, die das System unternimmt, um im Fehlerfall eine bestimmte Aktion auszuführen.",
      "WEBHOOK_WEBHOOK_URL_TOOLTIP":"Webhook-URL",
      "webhookNotifications":"Webhook",
      "WELCOME_HEADER_1_PART_1":"Bei uns haben Sie unendlich viele Möglichkeiten.",
      "WELCOME_HEADER_1_PART_2":"Und es beginnt jetzt.",
      "WELCOME_PORTAL_FEATUR_1":"Self-Service-Portal",
      "WELCOME_PORTAL_FEATUR_2":"Abonnements schnell und einfach hinzufügen/entfernen",
      "WELCOME_PORTAL_FEATUR_3":"Automatisierte Zahlungen",
      "WELCOME_PORTAL_FEATUR_4":"Rechnungen automatisch bezahlen",
      "WELCOME_PORTAL_FEATUR_5":"Sichern",
      "WELCOME_PORTAL_FEATUR_6":"Gesichert mit Microsoft Azure Active Directory und Multi-Faktor-Authentifizierung",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Anmeldung",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Herzlich willkommen",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Ausloggen",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Kontaktiere uns",
      "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Zum Dashboard gehen",
      "Won''t Fix":"Wird nicht repariert",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Vielen Dank für Ihre Zustimmung zu Xero. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf Xero zugreift. Sie können die Rechnungen Ihrer Kunden an Xero senden.",
      "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen zum Hochladen von Rechnungen zu Xero in <b>{{applicationName}} </b>.",
      "XERO_CONSENT_DESCRIPTION":"Um Rechnungen an Xero zu senden, müssen Sie Ihre Zustimmung geben.",
      "XERO_CONSENT_PROCESSING_MESSAGE":"Bitte warten Sie, während wir Ihre Xero-Einwilligung bearbeiten",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Der folgende Link wird in einer neuen Registerkarte geöffnet. Bitte geben Sie Ihre Xero-Anmeldeinformationen an. Wenn Sie bei der Erteilung der Einwilligung einen Fehler haben, wenden Sie sich bitte an unser Support-Team.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"Xero Zustimmung zu geben",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Wenn Sie die Einwilligung bereits erteilt haben, klicken Sie auf die Schaltfläche <b>Token validieren </b>, um die Konnektivität mit Xero zu überprüfen.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf diese Schaltfläche, um die Gültigkeit des vorhandenen Xero-Aktualisierungstokens zu überprüfen.",
      "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Voraussetzungen für den Partner, um Kundenrechnungen an <b>XERO </b>zu senden",
      "XERO_POST_BATCH_ID":"Xero Post Batch Id",
      "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Ihre Anfrage, Rechnungen an Xero zu senden, wird angenommen und zur Bearbeitung in die Warteschlange gestellt. Klicken Sie auf die Schaltfläche <strong> Beitragsprotokolle anzeigen </strong>, um Informationen zum Fortschritt zu erhalten.",
      "XERO_POST_LOGS_STATUS":"Xero Post Logs Status",
      "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Das vorhandene Xero-Aktualisierungstoken ist ungültig. Bitte geben Sie Ihre Zustimmung erneut",
      "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Das vorhandene Xero-Aktualisierungstoken ist gültig. Sie können mit dem Hochladen von Rechnungen fortfahren",
      "XERO_TENANT_ID":"Xero Mieter ID",
      "Year(s)":"Jahre)",
      "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Sie können den Steuerprozentsatz für den Kunden angeben. ZB: 15 für 15%",
      "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Sie können den Steuernamen wie GST angeben.",
      "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Sie haben keine Berechtigung, diese Aktion auszuführen",
      "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Sie müssen ein oder mehrere Angebote auswählen, um ein Abonnement zu erstellen"
   },
   
    "academic":"Akademisch",
    "Academic":"Akademisch",
    "ACCEPT_BUTTON_INFO":"Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, erklären Sie sich mit den oben genannten Nutzungsbedingungen einverstanden.",
    "ACCEPT_COOKIES_BUTTON_TEXT":"OK",
    "ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Zustimmung zur Verlängerung des Partnerangebots annehmen oder ablehnen",
    "ACCEPT_THE_CONSENT_BTN_TEXT":"Zugriff auf das Partner Center über die neuen Sicherheitseinstellungen.",
    "ACCEPT_THE_MICROSOFT_AZURE_API_CONSENT_BTN_TEXT":"Zugriff auf Microsoft Azure-Verwaltungs-APIs.",
    "ACCEPT_THE_MICROSOFT_PRICING_API_CONSENT_BTN_TEXT":"Zugriff auf die Microsoft-Preis-API.",
    "ACCEPTANCE_STATUS":"Akzeptanzstatus",
    "ACCESS_TOKEN":"Zugangstoken",
    "ACCOUNT_MANAGER_CREATION_SUCCESS_MESSAGE":"Der Account Manager wurde erfolgreich erstellt",
    "ACCOUNT_MANAGER_DELETED_SUCCESS_MESSAGE":"Account Manager erfolgreich gelöscht",
    "ACCOUNT_MANAGER_DELETION_DEFAULT_MESSAGE":"Sind Sie sicher, dass Sie den Account Manager löschen möchten?",
    "ACCOUNT_MANAGER_DELETION_MESSAGE_TO_NOTIFY_THE_ASSIGHNMENT":"Der Account Manager ist einem Kunden/Wiederverkäufer zugeordnet. Sind Sie sicher, dass Sie den Account Manager löschen möchten?",
    "ACCOUNT_MANAGER_FILTER_ACCOUNT_MANAGER_ID":"Suche nach Account Manager ID",
    "ACCOUNT_MANAGER_FILTER_EMAIL_ADDRESS":"Suche nach E-Mail-Adresse",
    "ACCOUNT_MANAGER_FILTER_FIRST_NAME":"Suche nach Vorname",
    "ACCOUNT_MANAGER_FILTER_LAST_NAME":"Suche nach Nachname",
    "ACCOUNT_MANAGER_FILTER_PHONE_NUMBER":"Suche nach Telefonnummer",
    "ACCOUNT_MANAGER_SEARCH_ACCOUNT_MANAGER_ID":"Account Manager ID",
    "ACCOUNT_MANAGER_SEARCH_CLOSE_OPTION":"Filter schließen",
    "ACCOUNT_MANAGER_SEARCH_EMAIL_ADDRESS":"E-Mail-Addresse",
    "ACCOUNT_MANAGER_SEARCH_FIRST_NAME":"Vorname",
    "ACCOUNT_MANAGER_SEARCH_LAST_NAME":"Nachname",
    "ACCOUNT_MANAGER_SEARCH_PHONE_NUMBER":"Telefonnummer",
    "ACCOUNT_MANAGER_SEARCH_RESET_OPTION":"Zurücksetzen",
    "ACCOUNT_MANAGER_SEARCH_SEARCH_OPTION":"Suche",
    "ACCOUNT_MANAGER_UPDATE_SUCCESS_MESSAGE":"Die Account Manager-Details wurden erfolgreich aktualisiert",
    "ACCOUNT_MANAGERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_ADD":"Account Manager hinzufügen",
    "ACCOUNT_MANAGERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Account Manager bearbeiten",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_ACCOUNT_MANAGER_ID":"Account Manager ID",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_EMAIL":"Email",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Vorname",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nachname",
    "ACCOUNT_MANAGERS_ADD_EDIT_FORM_LABELS_PHONE_NUMBER":"Telefonnummer",
    "ACCOUNT_MANAGERS_BUTTON_TEXT_ADD_ACCOUNT_MANAGER":"Account Manager hinzufügen",
    "ACCOUNT_MANAGERS_LIST_CAPTION_TEXT":"Kundenbetreuer",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACCOUNT_MANAGER_ID":"Ich würde",
    "ACCOUNT_MANAGERS_TABLE_HEADER_ACTIONS":"Aktionen",
    "ACCOUNT_MANAGERS_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
    "ACCOUNT_MANAGERS_TABLE_HEADER_EMAIL_ADDRESS":"E-Mail-Addresse",
    "ACCOUNT_MANAGERS_TABLE_HEADER_FIRST_NAME":"Vorname",
    "ACCOUNT_MANAGERS_TABLE_HEADER_LAST_NAME":"Nachname",
    "ACCOUNT_MANAGERS_TABLE_HEADER_PHONE_NUMBER":"Telefonnummer",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Kontomanager löschen",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT_DETAILS":"Details bearbeiten",
    "ACCOUNT_MANAGERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW_CUSTOMERS_ASSIGNED":"Zugewiesene Kunden anzeigen",
    "ACH_FORM_LABEL_ACCOUNT_NUMBER":"Kontonummer",
    "ACH_FORM_LABEL_ACCOUNT_TYPE":"Konto Typ",
    "ACH_FORM_LABEL_BANK_NAME":"Bank Name",
    "ACH_FORM_LABEL_BSB":"BSB",
    "ACH_FORM_LABEL_IS_BUSSINESS_ACCOUNT":"Ist Geschäftskonto",
    "ACH_FORM_LABEL_NAME_ON_ACCOUNT":"Name auf Konto",
    "ACH_FORM_LABEL_NO":"Nein",
    "ACH_FORM_LABEL_ROUTING_NUMBER":"Routing-Nummer",
    "ACH_FORM_LABEL_YES":"Ja",
    "ACTIONS_TO_FIX":"Bitte wenden Sie sich an den Administrator, um Unterstützung zu erhalten",
    "ACTIONS_TO_FIX_500":"Wir arbeiten an der Behebung des Problems. Bitte versuchen Sie es in einer Weile erneut",
    "ACTIVATE_CONFORMATION":"Sind Sie sicher, dass Sie aktivieren möchten?",
    "ACTIVE_CUSTOMER_PLACEHOLDER":"Wählen Sie Kunde",
    "ACTIVITY_LOG_EVENT_ALL_ACTIONS_TEXT":"--Alle Aktionen--",
    "ACTIVITY_LOG_INFORMATION_TEXT_SHOWING_LOGS_FROM":"Zeigt Protokolle von",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_CUSTOMERS":"Alle Kunden",
    "ACTIVITY_LOG_PLACEHOLDER_ALL_DOMAINS":"Alle Domänen.",
    "ACTIVITY_LOGS_BREADCRUMB_BUTTON_TEXT_ACTIVITY_LOGS":"Benachrichtigungen",
    "ACTIVITY_LOGS_BUTTON_TEXT_RETRY":"Wiederholen",
    "ACTIVITY_LOGS_EFFECTIVE_TO":"Endtermin",
    "ACTIVITY_LOGS_HEADER_TITLE_ACTIONS":"Aktionen",
    "ACTIVITY_LOGS_HEADER_TITLE_CONTACT_METHOD":"Kontaktmöglichkeit",
    "ACTIVITY_LOGS_HEADER_TITLE_EVENT_NAME":"Veranstaltungsname",
    "ACTIVITY_LOGS_HEADER_TITLE_SENT_DATE":"Gesendetes Datum",
    "ACTIVITY_LOGS_HEADER_TITLE_STATUS":"Status",
    "ACTIVITY_LOGS_HEADER_TITLE_SUBJECT":"Gegenstand",
    "ACTIVITY_LOGS_HEADER_TITLE_TO":"Zu",
    "ACTIVITY_LOGS_TEXT_ACTION_NAME":"Veranstaltung",
    "ACTIVITY_LOGS_TEXT_BCC":"BCC",
    "ACTIVITY_LOGS_TEXT_CC":"CC",
    "ACTIVITY_LOGS_TEXT_EFFECTIVE_FROM":"Anfangsdatum",
    "ACTIVITY_LOGS_TEXT_FAILED":"Gescheitert",
    "ACTIVITY_LOGS_TEXT_FROM":"Von",
    "ACTIVITY_LOGS_TEXT_ON":"Auf",
    "ACTIVITY_LOGS_TEXT_STATUS":"Status",
    "ACTIVITY_LOGS_TEXT_SUCCESS":"Erfolg",
    "ACTIVITY_LOGS_TEXT_TO":"Zu",
    "ACTIVITY_LOGS_TEXT_VIEW_EMAIL":"E-Mail anzeigen",
    "ACTIVITY_LOGS_TITLE_CONTACT_LOGS":"Benachrichtigungen",
    "ADD_ADJUSTMENT_HEADER_TEXT_ADD_ADJUSTMENT":"Anpassung hinzufügen",
    "ADD_BULK_TENANT_DOMIAN_NOT_AVAILABLE":"Domain Name ist nicht verfügbar",
    "ADD_BULK_TENANT_FIRST_DOMIAN_NOT_AVAILABLE":"Domain Name ist nicht verfügbar",
    "ADD_CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Partnerangebote hinzufügen",
    "ADD_CUSTOM_ROLE":"Benutzerdefinierte Rolle hinzufügen",
    "ADD_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Der Kunde hat spätestens akzeptiert",
    "ADD_CUSTOMER_IN_QUOTE":"Kunden hinzufügen",
    "ADD_CUSTOMER_LABEL_TEXT_COUNTRY":"Land",
    "ADD_CUSTOMER_PLEASE_ENTER_A_ZIP_CODE":"Bitte geben Sie eine Postleitzahl ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS1":"Bitte geben Sie die Adresse ein 1.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_ADDRESS2":"Geben Sie die Adresse ein 2.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_CITY":"Bitte geben Sie die Stadt ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_COMPANY_NAME":"Bitte geben Sie den Firmennamen ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Bitte geben Sie die E-Mail Adresse ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_FIRST_NAME":"Bitte geben Sie den Vornamen ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_LAST_NAME":"Bitte geben Sie den Nachnamen ein.",
    "ADD_CUSTOMER_PLEASE_ENTER_THE_PHONE_NUMBER":"Bitte geben Sie die Telefonnummer ein.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_COUNTRY":"Bitte wählen Sie ein Land aus.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_CURRENCY_CODE":"Bitte wählen Sie einen Währungscode.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_PLAN":"Bitte wählen Sie einen Tarif.",
    "ADD_CUSTOMER_PLEASE_SELECT_A_STATE":"Bitte wählen Sie ein Bundesland.",
    "ADD_CUSTOMERS_TO_PLAN_BUTTON_TEXT":"Kunden hinzufügen",
    "ADD_DUE_DATE":"Fälligkeitsdatum hinzufügen",
    "ADD_ENTITLEMENT":"Berechtigung hinzufügen",
    "ADD_ENTITLEMENT_DESCRIPTION":"Bitte geben Sie einen freundlichen Namen für das Abonnement ein.",
    "ADD_ENTITLEMENT_FAILED":"Berechtigung hinzufügen fehlgeschlagen",
    "ADD_ENTITLEMENT_SUCCESS":"Berechtigung wurde erfolgreich in Parter Center hinzugefügt, bitte warten Sie 15 Minuten bis zur Bereitstellung in C3",
    "ADD_MACRO_VALUE_ERROR":"Makrowert ist erforderlich",
    "ADD_METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Erstellen Sie eine neue Ebene",
    "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_1":"{{(!maxValue) ? „Bitte geben Sie einen Wert ein, der größer als „ + minValue“ ist: „Bitte geben Sie einen Wert zwischen „ + minValue + „ und „ + maxValue“ ein}}",
    "ADD_METERED_BILLING_DATA_POPUP_INSTRUCTION_2":"Bitte geben Sie einen Wert zwischen {{Min}} und {{Max}} ein.",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_CANCEL":"Stornieren",
    "ADD_METERED_BILLING_DATA_POPUP_TEXT_SUBMIT":"einreichen",
    "ADD_MISSING_OFFERS_COMPLETE_BUTTON":"Komplett",
    "ADD_MISSING_OFFERS_IN_PROGRESS":"Der Aktualisierungsplan mit den neuesten Angeboten ist bereits in Bearbeitung",
    "ADD_MISSING_OFFERS_INITIAT_FAIL":"Beim Aktualisieren des Plans mit der Initiierung der neuesten Angebote ist ein Fehler aufgetreten",
    "ADD_MISSING_OFFERS_INITIAT_SUCCESS":"Der Aktualisierungsplan mit den neuesten Angeboten wurde gestartet",
    "ADD_MISSING_OFFERS_STATUS":"Planen Sie den Aktualisierungsfortschritt",
    "ADD_MISSING_OFFERS_TO_EXISTING_PLAN":"Fehlende Angebote zum bestehenden Plan hinzufügen",
    "ADD_MISSING_OFFERS_TO_MULTIPLE_PLAN":"Fehlende Angebote zu mehreren Tarifen hinzufügen",
    "ADD_MISSING_OFFERS_TO_SINGLE_PLAN":"Fehlende Angebote zum Einzelplan hinzufügen",
    "ADD_NEW_CUSTOMER_LBL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Zugriff auf den primären Kontakt gewähren (als Kundenadministrator)?",
    "ADD_NEW_CUSTOMER_LBL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Einladung zum Zugriff auf die Anwendung senden?",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
    "ADD_NEW_PROVIDER_CUSTOMER_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
    "ADD_NEW_QUOTE":"Angebot erstellen",
    "ADD_NEW_USER_IN_QUOTE":"Neuen Benutzer hinzufügen",
    "ADD_PLAN_DROPDOWN_TEXT_SELECT":"Wählen",
    "ADD_PLAN_HEADER_TEXT_ADD_PLAN_BASIC_DETAILS":"Grundlegende Details zum Plan hinzufügen",
    "ADD_PLAN_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
    "ADD_PLAN_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "ADD_PLAN_LABEL_TEXT_INVOICE_CURRENCY":"Rechnungswährung",
    "ADD_PLAN_LABEL_TEXT_NAME":"Name",
    "ADD_PLAN_LABEL_TEXT_PURCHASE_CURRENCY":"Kaufwährung",
    "ADD_PLAN_PRODUCT_CONTRACT_TEMPLATE_TOOLTIP_VIEW_DETAILS":"Vertragsdetails anzeigen",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_ACTIONS":"Aktionen",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
    "ADD_PLAN_USAGE_TOOLTIP_TEXT_MACRO_TYPE":"Makro",
    "ADD_PO_NUMBER":"Bestellnummer hinzufügen",
    "ADD_PONUMBER":"Bestellnummer hinzufügen",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_HELP_TEXT":"Es wird empfohlen, dass Sie die Option <b>Link Provider</b> verwenden, um die MPN-ID des Wiederverkäufers anzugeben und dem Wiederverkäufer schließlich beim Onboarding von Kunden zu helfen.",
    "ADD_RESELLER_LINK_PROVIDER_RESELLER_TITLE":"Empfohlen! Legen Sie die MPN-ID fest, nachdem Sie den Reseller hinzugefügt haben",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "ADD_SLAB_DATA_POPUP_BUTTON_TEXT_OK":"OK",
    "ADD_SLAB_DATA_POPUP_HEADER_TEXT_SLAB_BREAKER":"Plattenbrecher",
    "ADD_TO_CART_INFO":"Dieses Produkt ist mit <b>{{linkedProductName}}</b>verknüpft. Wenn Sie dieses Produkt hinzufügen, wird der Link ebenfalls hinzugefügt",
    "addCustomer":"Kunden hinzufügen",
    "ADDONS_LEBEL_TEXT":"Addons",
    "ADDONS_PRODUCT_LEBEL_TEXT":"Zusatzprodukt",
    "ADDRESS_TABLE_HEADER_ACTIONS":"Aktionen",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_1":"Anschrift Zeile 1",
    "ADDRESS_TABLE_HEADER_ADDRESS_LINE_2":"Adresszeile 2",
    "ADDRESS_TABLE_HEADER_ADDRESS_TYPE":"Adresstyp",
    "ADDRESS_TABLE_HEADER_CITY":"Stadt",
    "ADDRESS_TABLE_HEADER_COUNTRY":"Land",
    "ADDRESS_TABLE_HEADER_STATE":"Zustand",
    "ADDRESS_TABLE_HEADER_ZIP":"Postleitzahl",
    "ADDRESS_TYPE_BILLING":"Abrechnung",
    "ADDRESS_TYPE_COMPANY":"Unternehmen",
    "ADDRESS_TYPE_INVOICE":"Rechnung",
    "ADDRESS_TYPE_SHIPPING":"Versand",
    "AGO":"Vor",
    "ALERT_MESSAGE_COPIED_TO_CLIPBOARD":"In die Zwischenablage kopiert",
    "ALERT_MESSAGE_INVOICE_IS_APPROVED_SUCCESSFULLY":"Die Rechnung wurde erfolgreich genehmigt.",
    "ALERT_MESSAGE_INVOICE_IS_FINALIZED_SUCCESSFULLY":"Die Rechnung wurde erfolgreich abgeschlossen.",
    "ALERT_MESSAGE_MISSING_MPNID":"Beim Kopieren der URL ist ein Problem aufgetreten. Bitte stellen Sie eine Verbindung zum Partner her.",
    "ALERT_MESSAGE_PLEASE_SELECT_AT_LEAST_ONE_INVOICE":"Bitte wählen Sie mindestens eine Rechnung aus",
    "ALL_CART_ITEM_COMMENT":"Warenkorb Artikel",
    "ALL_COMMENTS":"Bemerkungen",
    "ALL_PRODUCT_COMMENTS":"Produkt",
    "ALLOW_REMOVAL_OF_LICENSES_WARNING_MESSAGE":"Bitte wenden Sie sich an Ihren Administrator, um Lizenzen zu entfernen.",
    "AN_ERROR_OCCURED":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_HISTORY":"Azure-Abrechnungsverlauf",
    "ANALYTICS_CAPTION_TEXT_AZURE_BILLING_SUMMARY":"Azure-Abrechnungszusammenfassung",
    "ANALYTICS_CAPTION_TEXT_CUSTOMER_AZURE_USAGE":"Customer Azure-Verwendung",
    "ANALYTICS_CAPTION_TEXT_REVENUE_BY_CUSTOMERS":"Einnahmen von Kunden",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_CUSTOMERS":"Abonnements von Kunden",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_BY_PLANS":"Abonnements nach Plänen",
    "ANALYTICS_CAPTION_TEXT_SUBSCRIPTIONS_HISTORY":"Abonnementhistorie",
    "AND":"und",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"Einwilligungs-URL der Azure AD-Preisanwendung",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_ID":"Azure AD-Preisanwendungs-ID",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"Ressourcen-URL der Azure AD-Preisanwendung",
    "APP_CONFIG_AZUREAD_PRICING_APPLICATION_SECRET":"Geheimer Schlüssel der Azure AD-Preisanwendung",
    "APP_CONFIG_DESC_WELCOME_PAGE_BUTTON_STYLE":"Bitte geben Sie die Hexadezimalfarbe der Schaltfläche für die Willkommensseite ein",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_CONSENT_URL":"Einwilligungs-URL der Azure AD-Preisanwendung",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_ID":"Azure AD-Preisanwendungs-ID",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_RESOURCE_URL":"Ressourcen-URL der Azure AD-Preisanwendung",
    "APP_CONFIG_DISP_AZUREAD_PRICING_APPLICATION_SECRET":"Geheimer Schlüssel der Azure AD-Preisanwendung",
    "APP_CONFIG_DISP_WELCOME_PAGE_BUTTON_STYLE":"Farbe der Schaltfläche \"Willkommensseite\".",
    "APP_CONFIG_PUBLIC_SIGNNUP_BANNER":"Öffentliches Anmeldebanner",
    "APP_CONFIG_PUBLIC_SIGNNUP_LOGO":"Öffentliches Anmeldelogo",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD":"Nutzungs-Upload zulassen",
    "APPCONFIG_AUTOTASK_ALLOW_AUTOTASK_USAGE_UPLOAD_DESCRIPTION":"Wenn diese Konfiguration aktiviert ist, erhöht sie die monatlichen Nutzungskosten, die für Produkte mit Nutzungsverbrauch anfallen. Zu den Produkttypen gehören Azure-Plan und Custom-Usage.",
    "APPCONFIG_AUTOTASK_BILLING_CODE":"Abrechnungscode",
    "APPCONFIG_AUTOTASK_BILLING_CODE_DESC":"Dieser Abrechnungscode wird verwendet, um Produkte in Autotask zu erstellen",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE":"Ist automatisches Mapping (Autotask)",
    "APPCONFIG_AUTOTASK_CONTRACT_MAPPING_TYPE_DESC":"Wenn Sie manuell zwischen C3- und Autotask-Entitäten zuordnen möchten, lassen Sie dies deaktiviert. Wenn jedoch die automatische Zuordnung aktiviert ist, werden Entitäten abgeglichen, indem Namen von Entitäten in C3 mit Entitäten in Autotask verglichen werden",
    "APPCONFIG_AUTOTASK_PASSWORD":"Passwort",
    "APPCONFIG_AUTOTASK_PASSWORD_DESC":"Passwort, das dem API-Benutzer gehört",
    "APPCONFIG_AUTOTASK_PRORATION_TYPE":"Anteil",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY":"Automatische Upload-Frequenz",
    "APPCONFIG_AUTOTASK_UPLOAD_FREQUENCY_DESC":"Legen Sie die Häufigkeit des automatischen Hochladens fest",
    "APPCONFIG_AUTOTASK_USERNAME":"Nutzername",
    "APPCONFIG_AUTOTASK_USERNAME_DESC":"Benutzername des in Autotask erstellten API-Benutzers",
    "APPCONFIG_DESC_ACCOUNT_NAME":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_ACCOUNT_NUMBER":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_ADDRESS_LINE1":"Adresse 1, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_ADDRESS_LINE2":"Adresse 2, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_ADVANCE_TEXT":"Erweiterter Zeilenbenutzerdefinierter Text",
    "APPCONFIG_DESC_API_URL":"Basis-API-URL für connectwise manage",
    "APPCONFIG_DESC_APP_ENABLE_LICENSE_TRACKING":"Aktivieren Sie die Lizenzverfolgung",
    "APPCONFIG_DESC_APP_SHOW_ANALYTICS":"Analytics anzeigen",
    "APPCONFIG_DESC_APPLICATION_NAME":"Anwendungsname",
    "APPCONFIG_DESC_AZURE_BILLGENERATION_DATE":"Ende des Abrechnungszyklus für den Partner im Partner Center",
    "APPCONFIG_DESC_AZURE_CSPOFFERID":"Azure CSP-Angebots-ID",
    "APPCONFIG_DESC_BANK_NAME":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_BILLING_CYCLE":"Ausgerichtet mit Partner Center oder Custom",
    "APPCONFIG_DESC_BILLING_DATE":"Anwendbar, wenn der Abrechnungszyklus benutzerdefiniert ist",
    "APPCONFIG_DESC_BILLING_PERIOD":"Abrechnungszeitraum (bestimmt mit anderen Einstellungen)",
    "APPCONFIG_DESC_BILLING_PROVIDER":"Abrechnungsanbieter für das C3-Portal",
    "APPCONFIG_DESC_BILLING_PROVIDER_CONFIGURATION":"Konfiguration des Abrechnungsanbieters",
    "APPCONFIG_DESC_BILLING_STARTDAY":"Beginn des Abrechnungszyklus. Für den Abrechnungszyklus gilt \\''Benutzerdefiniert\\''",
    "APPCONFIG_DESC_BUSINESS_NUMBER":"Erscheint in der Rechnungsansicht und Rechnungs-PDF in Rechnung aus Abschnitt",
    "APPCONFIG_DESC_BUSINESS_NUMBER_KEY":"Erscheint in der Rechnungsansicht und Rechnungs-PDF in Rechnung aus Abschnitt",
    "APPCONFIG_DESC_CHECK_ROLES_AGAINST0365":"Überprüfen Sie die Rollen gegen 0365",
    "APPCONFIG_DESC_CIN_NUMBER":"CIN (Corporate Identification Number), die auf der Rechnung im PDF-Format erscheint",
    "APPCONFIG_DESC_CITY":"Stadt, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_CLIENT_ID":"Client-ID für ConnectWise Manage",
    "APPCONFIG_DESC_COMPANY_NAME":"Firmenname, der in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_COMPANY_URL":"Deine Website URL",
    "APPCONFIG_DESC_CONNECTWISE_COMPANY_NAME":"Firmenname für connectwise manage",
    "APPCONFIG_DESC_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Erstellungsdatum im Umsatzreport berücksichtigen",
    "APPCONFIG_DESC_CONTACT_COMPANY_NAME":"Name, der auf der Zielseite sowie in der Fußzeile angezeigt werden soll, nachdem ein Benutzer angemeldet wurde",
    "APPCONFIG_DESC_CONTACT_EMAIL":"Kontakt-E-Mail (normalerweise Ihre Informationen oder Support-E-Mail)",
    "APPCONFIG_DESC_CONTACT_FIRST_NAME":"Vorname der Kontaktperson für Ihre C3-Anwendung",
    "APPCONFIG_DESC_CONTACT_LAST_NAME":"Nachname der Kontaktperson für Ihre C3-Anwendung",
    "APPCONFIG_DESC_CONTACT_PHONE_NUMBER":"Telefonnummer der Kontaktperson",
    "APPCONFIG_DESC_COUNTRY":"Land, das in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_COUNTRY_CODE":"Landesvorwahl",
    "APPCONFIG_DESC_CURRENCY_CODE":"Währungscode",
    "APPCONFIG_DESC_CUSTOM_BILLING":"Benutzerdefinierte Abrechnung anwenden",
    "APPCONFIG_DESC_CUSTOMER_CONSENT_URL":"URL der Kundeneinwilligung",
    "APPCONFIG_DESC_CUSTOMER_ONBOARDING_RESTICTIONS":"Beschränkung des Kunden beim Onboarding unter mehreren Entitäten",
    "APPCONFIG_DESC_DATE_FORMAT":"Datumsformat",
    "APPCONFIG_DESC_DATE_TIME_FORMAT":"Datum Uhrzeit Format",
    "APPCONFIG_DESC_DATETIME_FORMAT_FOR_API":"Wird verwendet, um ein Datumsformat für den Datumstypwert in C3-APIs festzulegen",
    "APPCONFIG_DESC_DEFAULT_LANDING_PAGE_URL":"Ziel-URL für die Seite, wenn Sie eine angepasste Zielseite haben möchten. Hinweis: Auf der Zielseite sollten Sie eine Schaltfläche haben und die Ziel-URL dieser Schaltfläche sollte <C3Site URL>/#/login sein",
    "APPCONFIG_DESC_DEFAULT_LANGUAGE":"Standardsprache für die Website",
    "APPCONFIG_DESC_DEFAULT_PAGE_COUNT":"Legt die Standardseitenzahl für jede Tabelle im System fest.",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen für die Bestellung von Produkten",
    "APPCONFIG_DESC_DEFAULT_TERMS_AND_CONDITIONS_TEXT":"AGB-URL (Text)",
    "APPCONFIG_DESC_EMAIL_LOGO":"Das Logo, das in von C3 versendeten E-Mails angezeigt wird, ist 300x100.",
    "APPCONFIG_DESC_ENABLE_TOUR":"Aktivieren Sie die Schaltfläche „Tour machen“ in der Kopfzeile der Website.",
    "APPCONFIG_DESC_FAVICON_LOGO":"Ein Favicon ist ein kleines Symbol, das auf der Registerkarte des Webbrowsers direkt vor dem Seitentitel angezeigt wird. Bitte laden Sie eine ICO-Datei im Format 16x16 hoch",
    "APPCONFIG_DESC_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Sollte die MPN-ID von Resellern für Kunden und Käufe berücksichtigt werden?",
    "APPCONFIG_DESC_IFSC_CODE":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_INVOICE_FOOTER_AUTHORISED_LINE":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als von der Fußzeile autorisierte Zeile",
    "APPCONFIG_DESC_INVOICE_FOOTER_LINE":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
    "APPCONFIG_DESC_INVOICE_GENARATION_DAYS":"Tage der Rechnungserstellung",
    "APPCONFIG_DESC_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cloud-Speicher-Verbindungszeichenfolge",
    "APPCONFIG_DESC_INVOICE_ZIP_VALID_DAYS":"Gültige Tage für Rechnungs-Zip",
    "APPCONFIG_DESC_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Wenn diese Funktion aktiviert ist, erhält der Partner die vom Wiederverkäufer genehmigten Anfragen zur Genehmigung.",
    "APPCONFIG_DESC_LINK_TO_CONTACT":"Seite \\''Link zu Kontakt\\'' (geben Sie einfach/#/contact ein, falls Sie noch keine haben)",
    "APPCONFIG_DESC_LINK_TO_FAQ":"Link zu Kunden-FAQ (lassen Sie es einfach leer, wenn Sie die Standard-FAQ verwenden möchten)",
    "APPCONFIG_DESC_LINK_TO_PORTAL":"Link zum Portal",
    "APPCONFIG_DESC_LINK_TO_SUPPORT":"Link zur Support-Seite (geben Sie einfach home.support ein, falls Sie noch keine haben)",
    "APPCONFIG_DESC_LINK_TO_WELCOME_LOGO":"Link zum Willkommenslogo",
    "APPCONFIG_DESC_LOGGEDIN_LOGO":"Das Logo wird oben links angezeigt, nachdem Benutzer bei C3 angemeldet sind. Die bevorzugte Größe beträgt 170 x 40. Die Seite hat einen schwarzen Hintergrund, daher sollte die Grafik eine Farbe haben, die im Gegensatz zu Schwarz steht.",
    "APPCONFIG_DESC_LOGIN_TO_PORTAL":"Melden Sie sich bei Portal an",
    "APPCONFIG_DESC_MANDATE_CUSTOMERID_IN_API_CALLS":"Obligatorische Kunden-ID in API-Aufrufen",
    "APPCONFIG_DESC_MAX_AUDITLOG_INDEX":"Maximale Anzahl von Einträgen für den Export nach Excel",
    "APPCONFIG_DESC_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Maximum Entitlement Creation Count",
    "APPCONFIG_DESC_MAXIMUM_TENANT_CREATION_COUNT":"Maximale Mandantenerstellungsanzahl",
    "APPCONFIG_DESC_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft Sicherheitseinwilligung in Tagen erinnern",
    "APPCONFIG_DESC_MINIMUM_CHARGE_AMOUNT":"Mindestgebühr für Testzahlungen",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITION":"NCE-Bedingungen zum Bestellen von Microsoft NCE-Produkten",
    "APPCONFIG_DESC_NCE_TERMS_AND_CONDITIONS_TEXT":"URL der NCE-Nutzungsbedingungen (Text)",
    "APPCONFIG_DESC_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Aktiviert die Funktion zum Suchen bereits vorhandener Kunden in der Abrechnung",
    "APPCONFIG_DESC_PAN_NUMBER":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_PAN_NUMBER_DISPLAY_TEXT":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_PARTNER_APPLICATIONID":"Anwendungs-ID aus einer nativen Anwendung in Ihrem Partnercenter. Sie können dies über App Management -> Native Application erhalten",
    "APPCONFIG_DESC_PARTNER_CURRENCY":"Partnerwährung",
    "APPCONFIG_DESC_PARTNER_PASSWORD":"Admin Agent Passwort",
    "APPCONFIG_DESC_PARTNER_PREFERENCE_LANGUAGES":"Partnerpräferenzsprachen",
    "APPCONFIG_DESC_PARTNER_TENANT_ID":"Partner-Mieter-ID",
    "APPCONFIG_DESC_PARTNER_USERNAME":"Benutzername des Kontos, dem die Agentenadministratorrolle zugewiesen ist",
    "APPCONFIG_DESC_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL der Microsoft Cloud Reseller-Vereinbarung",
    "APPCONFIG_DESC_PATH_TO_PARTNER_AGREEMENT":"URL der Allgemeinen Geschäftsbedingungen des Partners",
    "APPCONFIG_DESC_PBI_GROUP_ID":"Power BI-Gruppen-ID",
    "APPCONFIG_DESC_PBI_PASSWORD":"Power BI-Passwort",
    "APPCONFIG_DESC_PBI_REPORT_ID":"Power BI-Berichts-ID",
    "APPCONFIG_DESC_PBI_USER_NAME":"Power BI-Benutzername",
    "APPCONFIG_DESC_PBIC_LIENT_ID":"Power BI-Client-ID",
    "APPCONFIG_DESC_PDF_LOGO":"Logo, das auf dem PDF Ihrer C3-Bewerbung erscheinen sollte. Die bevorzugte Größe ist 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik in einer beliebigen Farbe sein, die im Kontrast zu Weiß steht.",
    "APPCONFIG_DESC_PDF_RIGHT_SIDE_LOGO":"Logo, das auf der rechten Seite des PDFs erscheinen sollte. Die bevorzugte Größe ist 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik in einer beliebigen Farbe sein, die im Kontrast zu Weiß steht.",
    "APPCONFIG_DESC_POSTAL_CODE":"Postleitzahl, die in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_NAME":"Name, der auf der Zielseite sowie in der Fußzeile angezeigt werden soll, nachdem ein Benutzer angemeldet wurde",
    "APPCONFIG_DESC_POWEREDBY_COMPANY_URL":"Deine Website URL",
    "APPCONFIG_DESC_PRIVATE_KEY":"Privater Schlüssel für ConnectWise Manage",
    "APPCONFIG_DESC_PUBLIC_KEY":"Öffentlicher Schlüssel für ConnectWise Manage",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks App Client-Kennung",
    "APPCONFIG_DESC_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks App Kundengeheimnis",
    "APPCONFIG_DESC_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Untersuchen Sie Diskrepanzen bei E-Mail-Empfängern für die Erstellung von Vorausrechnungen",
    "APPCONFIG_DESC_RECON_REPORT_LOGO":"Logo, das in der Ansicht des Abstimmungsberichts rechts neben dem Microsoft-Logo angezeigt wird. Die bevorzugte Abmessung ist 170 x 40. Die Seite hat einen weißen Hintergrund, sodass der Text oder die Grafik in einer beliebigen Farbe vorliegen sollte, die im Gegensatz zu Weiß steht.",
    "APPCONFIG_DESC_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Fügen Sie die Empfänger als durch Kommas getrennte Werte hinzu",
    "APPCONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Sollte den Kunden benachrichtigen",
    "APPCONFIG_DESC_SHOULD_SHOW_DANGER_THRESHOLD":"Abonnement aussetzen, wenn die Gefahrenschwelle erreicht ist",
    "APPCONFIG_DESC_SHOULD_SHOW_POWERBI_REPORTS":"Power BI-Berichtsmenü anzeigen",
    "APPCONFIG_DESC_SHOW_FOOTER":"Wenn Sie diese Einstellung aktivieren, wird auf allen Seiten eine Fußzeile angezeigt.",
    "APPCONFIG_DESC_SHOW_NEXT_STEP":"Nächsten Schritt anzeigen",
    "APPCONFIG_DESC_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Aktivieren Sie diese Option, um die Allgemeinen Geschäftsbedingungen für die Abonnementverwaltung anzuzeigen.",
    "APPCONFIG_DESC_SMTP_ENABLESSL":"SMTP SSL aktivieren",
    "APPCONFIG_DESC_SMTP_FROM_MAIL":"SMTP aus E-Mail",
    "APPCONFIG_DESC_SMTP_FROM_NAME":"Vom Anzeigenamen",
    "APPCONFIG_DESC_SMTP_HOST":"SMTP-Host",
    "APPCONFIG_DESC_SMTP_PASSWORD":"Passwort",
    "APPCONFIG_DESC_SMTP_PORT":"Hafen",
    "APPCONFIG_DESC_SMTP_SUBJECT_PREFIX":"SMTP-Betreff-Präfix",
    "APPCONFIG_DESC_SMTP_USERNAME":"SMTP-Benutzername",
    "APPCONFIG_DESC_STATE":"Status, der in der Rechnungsansicht und im Rechnungs-PDF angezeigt wird.",
    "APPCONFIG_DESC_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
    "APPCONFIG_DESC_TAX_TYPE_KEY":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_TAX_TYPE_VALUE":"Erscheint auf der Rechnung pdf",
    "APPCONFIG_DESC_WELCOME_LOGO":"Logo, das auf der Zielseite Ihrer C3-Anwendung angezeigt werden soll. Die bevorzugte Abmessung beträgt 186 x 32. Die Seite hat einen weißen Hintergrund, daher sollte der Text oder die Grafik eine beliebige Farbe haben, die im Gegensatz zu Weiß steht.",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Xero App Kontocode für Anpassungspositionen",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"Xero App Kontocode für individuelle Angebote",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"Xero App Kontocode für Microsoft-Lizenzen",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Xero App Kontocode für einmalige Positionen",
    "APPCONFIG_DESC_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Xero-App-Kontocode für reservierte Instanzen – Einzelposten",
    "APPCONFIG_DESC_XERO_APP_BRANDING_THEME_NAME":"Xero App Branding Themenname",
    "APPCONFIG_DESC_XERO_APP_CLIENT_ID":"Xero App Kunden-ID",
    "APPCONFIG_DESC_XERO_APP_CLIENT_SECRET":"Xero App Kundengeheimnis",
    "APPCONFIG_DESC_XERO_DECIMAL_POINTS":"Der Dezimalpunktwert, auf den die in Xero gebuchten Beträge gerundet werden",
    "APPCONFIG_DISC_CONNECTWISE_ADDITION_DESCRITPION":"Zusatzbeschreibung und Rechnungsbeschreibungsformat",
    "APPCONFIG_DISC_CONNECTWISE_DATE_CULTURE":"Datumskultur für ConnectWise Manage Daten",
    "APPCONFIG_DISC_CONSIDER_CONNECTWISE_PRICES":"Berücksichtigen Sie ConnectWise Manage Preise anstelle von C3 Preisen",
    "APPCONFIG_DISC_MAPPING_TYPE":"Wählen Sie den Zuordnungstyp für die Zuordnung der Entitäten zwischen C3 und dem externen Dienst aus",
    "APPCONFIG_DISP_ACCOUNT_NAME":"Konto Name",
    "APPCONFIG_DISP_ACCOUNT_NUMBER":"Kontonummer",
    "APPCONFIG_DISP_ADDRESS_LINE1":"Adresse 1",
    "APPCONFIG_DISP_ADDRESS_LINE2":"Adresse 2",
    "APPCONFIG_DISP_ADVANCE_TEXT":"Erweiterter Zeilenbenutzerdefinierter Text",
    "APPCONFIG_DISP_ADVANCE_TEXT_FOR_ANNUAL":"Appconfig disp Vorabtext für jährlich",
    "APPCONFIG_DISP_API_URL":"Api Url",
    "APPCONFIG_DISP_APP_ENABLE_LICENSE_TRACKING":"Aktivieren Sie die Lizenzverfolgung",
    "APPCONFIG_DISP_APP_SHOW_ANALYTICS":"Analytics anzeigen",
    "APPCONFIG_DISP_APPLICATION_NAME":"Anwendungsname",
    "APPCONFIG_DISP_AZURE_BILLGENERATION_DATE":"Datum der Microsoft-Rechnungserstellung",
    "APPCONFIG_DISP_AZURE_CSPOFFERID":"Azure CSP-Angebots-ID",
    "APPCONFIG_DISP_BANK_NAME":"Bank Name",
    "APPCONFIG_DISP_BILLING_CYCLE":"Rechnungskreislauf",
    "APPCONFIG_DISP_BILLING_DATE":"Datum, an dem Rechnungen in C3 erstellt werden",
    "APPCONFIG_DISP_BILLING_PERIOD":"Abrechnungszeitraum",
    "APPCONFIG_DISP_BILLING_PROVIDER":"Abrechnungsanbieter",
    "APPCONFIG_DISP_BILLING_PROVIDER_CONFIGURATION":"Konfiguration des Abrechnungsanbieters",
    "APPCONFIG_DISP_BILLING_STARTDAY":"Abrechnungsstarttag",
    "APPCONFIG_DISP_BUSINESS_NUMBER":"Geschäftsnummer",
    "APPCONFIG_DISP_BUSINESS_NUMBER_KEY":"Geschäftsnummernschlüssel",
    "APPCONFIG_DISP_CHECK_ROLES_AGAINST0365":"Überprüfen Sie die Rollen gegen 0365",
    "APPCONFIG_DISP_CIN_NUMBER":"CIN-Nummer",
    "APPCONFIG_DISP_CITY":"Stadt",
    "APPCONFIG_DISP_CLIENT_ID":"Kundenkennung",
    "APPCONFIG_DISP_COMPANY_NAME":"Name der Firma",
    "APPCONFIG_DISP_COMPANY_URL":"Firmen-URL",
    "APPCONFIG_DISP_CONNECTWISE_ADDITION_DESCRITPION":"Zusatzbeschreibung",
    "APPCONFIG_DISP_CONNECTWISE_COMPANY_NAME":"Name der Firma",
    "APPCONFIG_DISP_CONNECTWISE_DATE_CULTURE":"Datum Kultur",
    "APPCONFIG_DISP_CONSIDER_CONNECTWISE_PRICES":"Berücksichtigen Sie ConnectWise Manage Prices",
    "APPCONFIG_DISP_CONSIDER_GENERATION_DATE_IN_TURNOVER_REPORT":"Erstellungsdatum im Umsatzreport berücksichtigen",
    "APPCONFIG_DISP_CONTACT_COMPANY_NAME":"Wenden Sie sich an den Firmennamen",
    "APPCONFIG_DISP_CONTACT_EMAIL":"Kontakt E-mail",
    "APPCONFIG_DISP_CONTACT_FIRST_NAME":"Kontakt Vorname",
    "APPCONFIG_DISP_CONTACT_LAST_NAME":"Kontakt Nachname",
    "APPCONFIG_DISP_CONTACT_PHONE_NUMBER":"Kontakt Telefonnummer",
    "APPCONFIG_DISP_COUNTRY":"Land",
    "APPCONFIG_DISP_COUNTRY_CODE":"Landesvorwahl",
    "APPCONFIG_DISP_CURRENCY_CODE":"Währungscode",
    "APPCONFIG_DISP_CUSTOM_BILLING":"Benutzerdefinierte Abrechnung anwenden",
    "APPCONFIG_DISP_CUSTOMER_CONSENT_URL":"URL der Kundeneinwilligung",
    "APPCONFIG_DISP_CUSTOMER_ONBOARDING_RESTICTIONS":"Beschränkung des Kunden beim Onboarding unter mehreren Entitäten",
    "APPCONFIG_DISP_DATE_FORMAT":"Datumsformat",
    "APPCONFIG_DISP_DATE_TIME_FORMAT":"Datum Uhrzeit Format",
    "APPCONFIG_DISP_DATETIME_FORMAT_FOR_API":"Datum-Uhrzeit-Format für C3-APIs",
    "APPCONFIG_DISP_DAYS_TO_SEND_CART_STATUS_NOTIFICATION":"Tage, um eine Benachrichtigung über den Warenkorbstatus zu senden",
    "APPCONFIG_DISP_DAYS_TO_STORE_BULK_INVOICES_FILE":"Anzahl der Tage zum Speichern der Massenrechnungsdatei im Cloud-Speicher",
    "APPCONFIG_DISP_DEFAULT_LANDING_PAGE_URL":"Benutzerdefinierte Landing Page URL",
    "APPCONFIG_DISP_DEFAULT_LANGUAGE":"Standardsprache",
    "APPCONFIG_DISP_DEFAULT_PAGEE_COUNT":"Standard-Seitenzahl",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_TEXT":"AGB-URL (Text)",
    "APPCONFIG_DISP_DEFAULT_TERMS_AND_CONDITION_URL":"URL der Allgemeinen Geschäftsbedingungen",
    "APPCONFIG_DISP_EMAIL_LOGO":"E-Mail-Logo",
    "APPCONFIG_DISP_ENABLE_TOUR":"Tour aktivieren",
    "APPCONFIG_DISP_FAVICON_LOGO":"Favicon-Logo",
    "APPCONFIG_DISP_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Sollte die MPN-ID von Resellern für Kunden und Käufe berücksichtigt werden?",
    "APPCONFIG_DISP_IFSC_CODE":"IFSC-Code",
    "APPCONFIG_DISP_INVOICE_FOOTER_AUTHORISED_LINE":"Rechnungszeile Autorisierte Zeile",
    "APPCONFIG_DISP_INVOICE_FOOTER_LINE":"Rechnungsfußzeile",
    "APPCONFIG_DISP_INVOICE_GENARATION_DAYS":"Tage der Rechnungserstellung",
    "APPCONFIG_DISP_INVOICE_ZIP_STORAGE_CONNECTION_STRING":"Cloud-Speicher-Verbindungszeichenfolge",
    "APPCONFIG_DISP_INVOICE_ZIP_VALID_DAYS":"Gültige Tage für Rechnungs-Zip",
    "APPCONFIG_DISP_IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Zustimmung des Partners erforderlich",
    "APPCONFIG_DISP_IS_PUBLIC_CATALOGUE_BACKED_BY_AZURESEARCH":"Öffentlicher Katalog, unterstützt durch Azure Search",
    "APPCONFIG_DISP_LINK_TO_CONTACT":"Link zur Kontaktseite",
    "APPCONFIG_DISP_LINK_TO_FAQ":"Link zu Kunden-FAQ",
    "APPCONFIG_DISP_LINK_TO_PORTAL":"Link zum Portal",
    "APPCONFIG_DISP_LINK_TO_SUPPORT":"Link zum Support",
    "APPCONFIG_DISP_LINK_TO_WELCOME_LOGO":"Link zum Willkommenslogo",
    "APPCONFIG_DISP_LOGGEDIN_LOGO":"Eingeloggtes Logo",
    "APPCONFIG_DISP_LOGIN_TO_PORTAL":"Melden Sie sich bei Portal an",
    "APPCONFIG_DISP_MANDATE_CUSTOMERID_IN_API_CALLS":"Obligatorische CustomerId in Api-Aufrufen",
    "APPCONFIG_DISP_MAPPING_TYPE":"Zuordnungstyp",
    "APPCONFIG_DISP_MAX_AUDITLOG_INDEX":"Maximale Einträge für den Export",
    "APPCONFIG_DISP_MAXIMUM_ENTITLEMENT_CREATION_COUNT":"Maximum Entitlement Creation Count",
    "APPCONFIG_DISP_MAXIMUM_TENANT_CREATION_COUNT":"Maximale Mandantenerstellungsanzahl",
    "APPCONFIG_DISP_MICROSOFT_REMIND_FOR_SECURITY_CONSENT_IN_DAYS":"Microsoft Sicherheitseinwilligung in Tagen erinnern",
    "APPCONFIG_DISP_MINIMUM_CHARGE_AMOUNT":"Mindestgebühr",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_TEXT":"URL der NCE-Nutzungsbedingungen (Text)",
    "APPCONFIG_DISP_NCE_TERMS_AND_CONDITION_URL":"URL der NCE-Nutzungsbedingungen",
    "APPCONFIG_DISP_ONBOARD_CUSTOMER_SEARCH_BILLAND_PAY":"Onboard-Kundensuche mit Abrechnung",
    "APPCONFIG_DISP_PAN_NUMBER":"Pan-Nummer",
    "APPCONFIG_DISP_PAN_NUMBER_DISPLAY_TEXT":"PAN-Nummerntaste",
    "APPCONFIG_DISP_PARTNER_APPLICATIONID":"Native Anwendungs-ID",
    "APPCONFIG_DISP_PARTNER_CURRENCY":"Partnerwährung",
    "APPCONFIG_DISP_PARTNER_PASSWORD":"Admin Agent Passwort",
    "APPCONFIG_DISP_PARTNER_PREFERENCE_LANGUAGES":"Sprachpakete",
    "APPCONFIG_DISP_PARTNER_TENANT_ID":"Partner-Mieter-ID",
    "APPCONFIG_DISP_PARTNER_USERNAME":"Benutzername des Admin-Agenten",
    "APPCONFIG_DISP_PATH_TO_MS_CLOUD_RESELLER_AGREEMENT":"URL der Microsoft Cloud Reseller-Vereinbarung",
    "APPCONFIG_DISP_PATH_TO_PARTNER_AGREEMENT":"URL der Allgemeinen Geschäftsbedingungen des Partners",
    "APPCONFIG_DISP_PBI_GROUP_ID":"Power BI-Gruppen-ID",
    "APPCONFIG_DISP_PBI_PASSWORD":"Power BI-Passwort",
    "APPCONFIG_DISP_PBI_REPORT_ID":"Power BI-Berichts-ID",
    "APPCONFIG_DISP_PBI_USER_NAME":"Power BI-Benutzername",
    "APPCONFIG_DISP_PBIC_LIENT_ID":"Power BI-Client-ID",
    "APPCONFIG_DISP_PDF_LOGO":"PDF-Logo",
    "APPCONFIG_DISP_PDF_RIGHT_SIDE_LOGO":"PDF-Logo auf der rechten Seite",
    "APPCONFIG_DISP_POSTAL_CODE":"Postleitzahl",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_NAME":"Powered By Firmenname",
    "APPCONFIG_DISP_POWEREDBY_COMPANY_URL":"Powered By Firmen-URL",
    "APPCONFIG_DISP_PRIVATE_KEY":"Privater Schlüssel",
    "APPCONFIG_DISP_PUBLIC_KEY":"Öffentlicher Schlüssel",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_ID":"QuickBooks KundenId",
    "APPCONFIG_DISP_QUICKBOOKS_APP_CLIENT_SECRET":"QuickBooks Kundengeheimnis",
    "APPCONFIG_DISP_RECON_MISSMATCH_ON_UPFRONT_INVOICE_GENERATION_EMAIL_RECEIPIENT":"Untersuchen Sie Diskrepanzen bei E-Mail-Empfängern für die Erstellung von Vorausrechnungen",
    "APPCONFIG_DISP_RECON_REPORT_LOGO":"Abstimmungsbericht Logo",
    "APPCONFIG_DISP_RECONCILIATION_REPORT_NOTIFY_RECIPIENCE":"Abstimmungsbericht Empfänger benachrichtigen",
    "APPCONFIG_DISP_REFUND_TEXT":"Appconfig disp Rückerstattungstext",
    "APPCONFIG_DISP_REFUND_TEXT_FOR_ANNUAL":"Appconfig disp-Rückerstattungstext für jährlich",
    "APPCONFIG_DISP_SHOULD_SHOW_DANGER_THRESHOLD":"Abonnement aussetzen, wenn die Gefahrenschwelle erreicht ist",
    "APPCONFIG_DISP_SHOULD_SHOW_POWERBI_REPORTS":"Power BI-Berichtsmenü anzeigen",
    "APPCONFIG_DISP_SHOW_EXTERNAL_INVOICE_NUMBER":"Aktivieren, um die externe Rechnungsnummer im Rechnungszusammenfassungsbericht abzurufen",
    "APPCONFIG_DISP_SHOW_FOOTER":"Footer anzeigen",
    "APPCONFIG_DISP_SHOW_NEXT_STEP":"Nächsten Schritt anzeigen",
    "APPCONFIG_DISP_SHOW_TERMS_AND_CONDITIONS_FOR_SUBSCRIPTION_UPDATE":"Allgemeine Geschäftsbedingungen für die Abonnementverwaltung aktivieren",
    "APPCONFIG_DISP_SMTP_ENABLESSL":"Aktivieren Sie SSL",
    "APPCONFIG_DISP_SMTP_FROM_MAIL":"Aus der Email",
    "APPCONFIG_DISP_SMTP_FROM_NAME":"Vom Anzeigenamen",
    "APPCONFIG_DISP_SMTP_HOST":"Gastgeber",
    "APPCONFIG_DISP_SMTP_PASSWORD":"SMTP-Passwort",
    "APPCONFIG_DISP_SMTP_PORT":"SMTP-Port",
    "APPCONFIG_DISP_SMTP_SUBJECT_PREFIX":"Betreff Präfix",
    "APPCONFIG_DISP_SMTP_USERNAME":"Nutzername",
    "APPCONFIG_DISP_STATE":"Zustand",
    "APPCONFIG_DISP_TAG_MPNID_FOR_CUSTOMERS_AND_RESELLERS":"TagMPNIDForCustomersAndResellers",
    "APPCONFIG_DISP_TAX_TYPE_KEY":"Steuertyp",
    "APPCONFIG_DISP_TAX_TYPE_VALUE":"Steuerwert",
    "APPCONFIG_DISP_WELCOME_LOGO":"Willkommens-Logo",
    "APPCONFIG_DISP_WELCOME_PAGE_VIEW":"Startseite",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ADJUSTMENTS":"Xero-Kontocode für Anpassungen",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_AZURE":"XeroAppAccountCodeForAzure",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_CUSTOM":"XeroAppAccountCodeForCustom",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_MS_LICENSE":"XeroAppAccountCodeForMSLicense",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_ONETIME":"Xero-Kontocode für OneTime",
    "APPCONFIG_DISP_XERO_APP_ACCOUNT_CODE_FOR_RESERVED_INSTANCES":"Xero-Kontocode für Reserved Instances",
    "APPCONFIG_DISP_XERO_APP_BRANDING_THEME_NAME":"XeroAppBrandingThemeName",
    "APPCONFIG_DISP_XERO_APP_CLIENT_ID":"XeroClientId",
    "APPCONFIG_DISP_XERO_APP_CLIENT_SECRET":"XeroClientSecret",
    "APPCONFIG_DISP_XERO_DECIMAL_POINTS":"Xero Dezimalstellen",
    "APPCONFIG_SELECTED_SMTP_OPTION":"Wählen Sie den SMTP-Typ aus",
    "APPCONFIG_SELECTED_SMTP_OPTION_DESCRIPTION":"Wählen Sie die SMTP-Option",
    "APPCONFIG_SHOW_EXTERNAL_INVOICE_NUMBER":"Externe Rechnungsnummer im Rechnungszusammenfassungsbericht anzeigen",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID":"Moderne Authentifizierungsanwendungs-ID",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_ID_DESCRIPTION":"Client-ID der Azure AD-App",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET":"Modernes Passwort für die Authentifizierungsanwendung",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_APPLICATION_SECRET_DESCRIPTION":"Client-Geheimnis der Azure AD-App",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM":"Moderne Authentifizierung per E-Mail",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_EMAIL_FROM_DESCRIPTION":"SMTP aus E-Mail",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID":"Moderne Authentifizierungs-Mandanten-ID",
    "APPCONFIG_SMTP_MODERN_AUTHENTICATION_TENANT_ID_DESCRIPTION":"Mandanten-ID des Benutzerkontos, das zum Senden von E-Mails verwendet wird",
    "APPEND_CUSTOM_TRIAL_DAYS":"(Testzeit)",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY":"Das verbleibende Limit nach Genehmigung dieser Anfrage beträgt",
    "APPROVAL_SCREEN_CURRENT_TRANSACTION_LIMIT_INCREASE_BY_SCHEDUAL_RENEWAL":" Das aktuelle verbleibende Limit wird nach einer erfolgreichen Verlängerung aktualisiert.",
    "approvals":"Zulassungen",
    "APPROVED_AND_INITIATED":"Genehmigt und initiiert",
    "APPROVED_BY":"durch den Händler",
    "APRIL":"April",
    "ASSIGN_BUNDLE_PRODUCTS":"Bundle-Produkte zuweisen",
    "ASSIGN_CUSTOMER_PLAN":"Kundenplan zuweisen",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Möchten Sie {{accountManager}} sicher {{customer}} zuweisen?",
    "ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Kunde ist erfolgreich zugeordnet",
    "ASSIGN_RESELLER_TO_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Reseller ist erfolgreich zugeordnet",
    "ASSIGN_USAGE_PRODUCTS":"Verwendungsprodukte zuweisen",
    "ASSIGNED_CUSTOMER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Kunden erfolgreich dem Account Manager zugewiesen",
    "ASSIGNED_RESELLER_TO_ACCOUNT_MANAGER_SUCCESSFULLY":"Reseller erfolgreich dem Account Manager zugewiesen",
    "ATTESTATION_DESCRIPTION_FOR_WINDOWS365":"Ich verstehe, dass jede Person, die Windows 365 Business mit Windows Hybrid Benefit nutzt, auch eine gültige Kopie von Windows 10/11 Pro auf ihrem primären Arbeitsgerät installiert haben muss.",
    "AUDIT_LOG_BUTTON_TEXT_CLOSE":"Schließen",
    "AUDIT_LOG_DETAIL":"Einzelheiten",
    "AUDIT_LOG_HIDE_DETAILS_BTN_TEXT":"Details verbergen",
    "AUDIT_LOG_MORE_DETAILS_BTN_TEXT":"Mehr Details",
    "AUDIT_LOG_PASSWORD_FIELD_TEXT_HIDDEN":"Versteckt",
    "AUDIT_LOG_SELECT_ALL_ACTION_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Aktionen--",
    "AUDIT_LOG_SELECT_ALL_CUSTOMER_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Kunden--",
    "AUDIT_LOG_SELECT_ALL_DOMAINS_DROPDOWN_PLACEHOLDER_TEXT":"--Alle Domänen--",
    "AUDITLOG_BUTTON_LABEL_AUDIT_LOG":"Audit-Log",
    "AUDITLOG_BUTTON_LABEL_SEARCH":"Suche",
    "AUDITLOG_TEXT_ACTION_NAME":"Aktionen",
    "AUDITLOG_TEXT_AUDITLOG":"Audit-Log",
    "AUDITLOG_TEXT_CUSTOMER_NAME":"Kundenname",
    "AUDITLOG_TEXT_EFFECTIVE_FROM":"Gültig ab",
    "AUDITLOG_TEXT_EFFECTIVE_TO":"Wirksam zu",
    "AUDITLOG_TEXT_FROM":"Von",
    "AUDITLOG_TEXT_SERVICE_PROVIDER_CUSTOMER_DOMAIN":"Domainname des Kunden",
    "AUDITLOG_TEXT_SHOW_HIDDEN_AUDIT_LOG":"Versteckte Audit-Protokolle anzeigen",
    "AUDITLOG_TEXT_TABLE_HEADER_ACTIONS":"Aktionen",
    "AUDITLOG_TEXT_TABLE_HEADER_AFFECTED_CUSTOMER":"Betroffener Kunde",
    "AUDITLOG_TEXT_TABLE_HEADER_DATE":"Datum",
    "AUDITLOG_TEXT_TABLE_HEADER_INITIATOR":"Initiator",
    "AUDITLOG_TEXT_TABLE_HEADER_VIEW_DETAILS":"Details anzeigen",
    "AUDITLOG_TEXT_TO":"Zu",
    "AUGUST":"August",
    "AUTH_CODE_RECIEVED_FROM_QUICKBOOKS":"Von Quickbooks empfangener Authentifizierungscode",
    "AUTH_CODE_RECIEVED_FROM_XERO":"Authentifizierungscode von Xero erhalten",
    "AUTHORIZE_NET_CODE_BATCH_ALREADY_CLOSED":"Der Stapel ist bereits geschlossen.",
    "AUTHORIZE_NET_CODE_COMPLETED_SUCCESSFULLY":"Anfrage erfolgreich abgeschlossen",
    "AUTHORIZE_NET_CODE_CREDIT_CARD_NUMBER_EXPIRED":"Die Kreditkarte ist abgelaufen.",
    "AUTHORIZE_NET_CODE_DEVICE_BATCH_FULL":"Der Gerätestapel ist voll. Bitte schließen Sie den Stapel.",
    "AUTHORIZE_NET_CODE_DEVICE_DISABLED":"Das Gerät wurde deaktiviert.",
    "AUTHORIZE_NET_CODE_DUPLICATE_TRANSACTION_SUBMITTED":"Es wurde eine doppelte Transaktion eingereicht.",
    "AUTHORIZE_NET_CODE_ERROR_DEVICE_IDENTIFIER_NOT_REGISTERED":"Die Gerätekennung ist entweder nicht registriert oder nicht aktiviert.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_BATCHID":"Die Gerätechargenkennung ist ungültig.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_DEVICEID":"Die Gerätekennung ist ungültig.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_FLAGID":"Das Stornierungskennzeichen ist ungültig.",
    "AUTHORIZE_NET_CODE_ERROR_INVALID_SECURIY_CODE":"Der angegebene Sicherheitscode war ungültig.",
    "AUTHORIZE_NET_CODE_ERROR_OCCURRED_VALIDATION_ERROR":"Beim Prozessor ist ein Validierungsfehler aufgetreten.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_DATA_INSUFFICIENT":"Die Anfragedaten haben die Prüfung der erforderlichen Felder für diese Anwendung nicht bestanden.",
    "AUTHORIZE_NET_CODE_ERROR_REQUEST_FIELD_INVALID_OR_MISSING":"Das/die Anforderungsfeld(er) sind entweder ungültig oder fehlen.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_ACCEPTED":"V.me-Transaktionen werden von diesem Händler nicht akzeptiert.",
    "AUTHORIZE_NET_CODE_ERROR_TRANSACTION_NOT_FOUND":"Die Transaktion kann nicht gefunden werden.",
    "AUTHORIZE_NET_CODE_ERROR_VOID_REQUEST_FAILED":"Die Stornoanforderung ist fehlgeschlagen. Entweder unterstützt die ursprüngliche Transaktionsart die Stornierung nicht, oder die Transaktion wird gerade abgewickelt.",
    "AUTHORIZE_NET_CODE_MERCHANT_ACTIVATED_SUCCESS":"Der Händler wurde erfolgreich aktiviert.",
    "AUTHORIZE_NET_CODE_MERCHANT_CONFIGURED":"Der Händler ist für die automatische Schließung konfiguriert.",
    "AUTHORIZE_NET_CODE_NEED_CONSET":"Zustimmung des Zahlers erforderlich.",
    "AUTHORIZE_NET_CODE_ORDER_ALREADY_RELEASED":"Diese Bestellung wurde bereits freigegeben oder storniert, daher können keine neuen Transaktionszuordnungen akzeptiert werden.",
    "AUTHORIZE_NET_CODE_ORDER_RECEIVED":"Ihre Bestellung ist eingegangen. Vielen Dank für Ihren Auftrag!",
    "AUTHORIZE_NET_CODE_ORIGINAL_TRANSACTION_IN_CLOSED_BATCH":"Die ursprüngliche Transaktion befindet sich in einem geschlossenen Stapel.",
    "AUTHORIZE_NET_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Der Payment Gateway Account Service (id=8) wurde bereits akzeptiert.",
    "AUTHORIZE_NET_CODE_REQUESTED_NEW_SECURITY_CODE":"Ein neuer Sicherheitscode wurde angefordert.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_ATHORIZED_PAYMENT":"Diese Transaktion wurde bereits autorisiert.",
    "AUTHORIZE_NET_CODE_REQUIRE_ALREADY_CAPTURED_TRANSACTION":"PayPal hat diese Transaktion bereits erfasst.",
    "AUTHORIZE_NET_CODE_REVERSAL_PROCESSED_SUCCESSFULLY":"Die Stornierung wurde erfolgreich verarbeitet.",
    "AUTHORIZE_NET_CODE_SUBMITTED_SUCCESSFULLY":"Das mobile Gerät wurde erfolgreich in die Datenbank eingefügt.",
    "AUTHORIZE_NET_CODE_SUCCESS":"Die Anfrage wurde erfolgreich bearbeitet.",
    "AUTHORIZE_NET_CODE_SUCCESSFULLY_REGISTERED":"Das mobile Gerät wurde erfolgreich registriert und vom Kontoverwalter genehmigt.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_AVOIDED":"Diese Transaktion wurde bereits storniert.",
    "AUTHORIZE_NET_CODE_TRANSACTION_ALREADY_CAPTURED":"Diese Transaktion wurde bereits erfasst.",
    "AUTHORIZE_NET_CODE_TRANSACTION_NOT_FOUND":"Originaltransaktion für die Stornierung wurde nicht gefunden.",
    "AUTHORIZE_NET_CODE_TRANSACTION_REJECTED":"PayPal hat die Transaktion abgelehnt. x_paypal_hdrimg überschreitet die maximal zulässige Länge.",
    "AUTHORIZE_NET_CODE_USE_XMETHOD":"Verwenden Sie x_method zur Angabe der Methode oder senden Sie nur x_call_id oder Karten-/Kontoinformationen.",
    "AUTHORIZE_NET_CODE_WORK_IN_PROGESS":"Dies ist eine laufende Entwicklung. Diese Nachricht wird nicht für die Produktion freigegeben. Sie ist nur ein Platzhalter für Entwickler.",
    "AUTHORIZE_NET_CONVERSIOIN_RATE_AVAILABLE":"Der Umrechnungskurs für diese Karte ist verfügbar.",
    "AUTHORIZE_NET_ERRO _CARD_CODE_INVALID":"Der Kartencode ist ungültig.",
    "AUTHORIZE_NET_ERROR _CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_ACCEPTED":"Diese Transaktion kann nicht akzeptiert werden.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_CANNOT_PROCESSED":"Diese Transaktion kann nicht verarbeitet werden.",
    "AUTHORIZE_NET_ERROR _TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_ACCOUNT_NOT_GIVEN_PERMISSION_FOR_REQUIRED_REQUEST":"Für dieses Konto wurde(n) nicht die erforderliche(n) Genehmigung(en) für diese Anfrage erteilt.",
    "AUTHORIZE_NET_ERROR_ACCOUNTNAME_ACCOUNTTYPE_NO_MATCH_ACTUAL_ACCOUNT":"Der angegebene Name des Kontos und/oder die Kontoart stimmen nicht mit dem tatsächlichen Konto überein.",
    "AUTHORIZE_NET_ERROR_ACH_TRANSACTION_NOT_ACCEPTED_BY_THIS_SYSTEM":"ACH-Transaktionen können von diesem System nicht akzeptiert werden.",
    "AUTHORIZE_NET_ERROR_AUTHENTICATION_INDICATOR_INVALID":"Das Authentifizierungskennzeichen ist ungültig.",
    "AUTHORIZE_NET_ERROR_AUTHORIZATION_CODE_INVALID":"Der Autorisierungscode ist ungültig.",
    "AUTHORIZE_NET_ERROR_BANK_ACCOUNT_TYPE_INVALID":"Die Art des Bankkontos ist ungültig.",
    "AUTHORIZE_NET_ERROR_BILLING_NOT_ALLOWED":"Wiederkehrende Rechnungen sind für diesen eCheck.Net-Typ nicht zulässig.",
    "AUTHORIZE_NET_ERROR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"Der Karteninhaber-Authentifizierungswert ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_ABA_CODE_IS_INVALID":"Der ABA-Code ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_EXPIRED":"Das angegebene Zugriffstoken ist abgelaufen.",
    "AUTHORIZE_NET_ERROR_CODE_ACCESS_TOKEN_VALIDATE":"Das für die Validierung der Anforderung verwendete Zugriffstoken ist nicht ausreichend für die Validierung.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_IN_TEST_MODE":"Die angeforderte API-Methode kann nicht ausgeführt werden, solange sich das Zahlungsgateway-Konto im Testmodus befindet.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MERCHANT_NOT_ASSOCIATED_WITH_THIS_RESELLER":"Dieser Händler ist nicht mit diesem Wiederverkäufer verbunden.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_MUST_SETUP_ACCEPT_PAYMENTS":"Das Händlerkonto muss für die Annahme von Kreditkartenzahlungen, eCheck-Zahlungen oder beidem eingerichtet sein.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_CURRENTLY_ACTIVE":"Das Zahlungsgateway-, Wiederverkäufer- oder Benutzerkonto ist derzeit nicht aktiv.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_CREDIR_CARD_SUBSCRIPTIONS":"Das Zahlungsgateway-Konto ist nicht für die Verarbeitung von Kreditkartenabonnements eingerichtet.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NOT_SETUP_ECHECK":"Das Zahlungsgateway-Konto ist nicht für die Verarbeitung von eCheck.Net-Abonnements eingerichtet.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_NUMBER_IS_INVALID":"Die Kontonummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_ACCOUNT_REQUIRED":"Der Abonnementbetrag ist bei der Erstellung eines Abonnements erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_ACH_TRANSACTIONS":"ACH-Transaktionen werden von diesem Händler nicht akzeptiert.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_CREDITS":"Die Summe aller Gutschriften für diese Transaktion ist größer als der ursprüngliche Transaktionsbetrag.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELD_EMPTY":"Alle Felder waren leer oder fehlten.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_FIELDS_EMPTY":"Alle Felder waren leer oder fehlten.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_INVALID_VALUES":"Ungültiger Wert.",
    "AUTHORIZE_NET_ERROR_CODE_ALL_VALUES_TOO_LONG":"Der Wert ist zu lang.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_CANCELED":"Das Abonnement wurde bereits gekündigt.",
    "AUTHORIZE_NET_ERROR_CODE_ALREADY_DELETED":"Der Datensatz wurde bereits gelöscht.",
    "AUTHORIZE_NET_ERROR_CODE_AN_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_APPROVAL_NOT_RETURNED_FOR_TRANSACTION":"Es wurde keine Genehmigung für die Transaktion zurückgegeben.",
    "AUTHORIZE_NET_ERROR_CODE_AUCTIONVALUE_NOTSUBMITTED":"Ein oder mehrere erforderliche Auktionswerte wurden nicht übermittelt.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHENTICATION_NOT_ALLOWED_REQUESTED_CALL":"Der Authentifizierungstyp ist für den angeforderten Methodenaufruf nicht zulässig.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATION_CODE_IS_REQUIRED":"Ein Autorisierungscode ist erforderlich, aber nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_AUTHORIZATIONS_NOTACCEPTED":"Dieser Prozessor akzeptiert keine Null-Dollar-Autorisierungen.",
    "AUTHORIZE_NET_ERROR_CODE_AVS_MISMATCH":"Die Transaktion wurde aufgrund einer AVS-Fehlanpassung abgelehnt. Die angegebene Adresse stimmt nicht mit der Rechnungsadresse des Karteninhabers überein.",
    "AUTHORIZE_NET_ERROR_CODE_BANK_PAYMENT_METHOD_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Die Bankzahlungsmethode wird für das ausgewählte Geschäftsland nicht akzeptiert.",
    "AUTHORIZE_NET_ERROR_CODE_BLANK":"%s kann nicht leer gelassen werden.",
    "AUTHORIZE_NET_ERROR_CODE_CALL_MERCHANT_SERVICE_PROVIDER":"Bei der Verarbeitung ist ein Fehler aufgetreten. Rufen Sie den Merchant Service Provider an.",
    "AUTHORIZE_NET_ERROR_CODE_CALLING_WEB_SERVICE_RETURN_ERROR":"Der aufrufende Webservice hat einen Fehler zurückgegeben.",
    "AUTHORIZE_NET_ERROR_CODE_CANCEL_URL":"Storno-URL ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_BE_REFUNDED":"Diese Transaktion wartet auf die Abrechnung und kann nicht zurückerstattet werden.",
    "AUTHORIZE_NET_ERROR_CODE_CANNOT_EXTEND_DRATION_SUBSCRIPTION":"Die Gesamtzahl der Vorgänge kann die Laufzeit des Abonnements nicht über drei Jahre ab dem Startdatum hinaus verlängern.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_HAS_EXPIRED":"Die Kreditkarte ist abgelaufen.",
    "AUTHORIZE_NET_ERROR_CODE_CARD_NUMBER_IS_INVALID":"Die Kreditkartennummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_CARDITMEAMOUNT_NOTMATCHTO_ORDERAMOUNT":"Die Summen der Warenkorbartikel stimmen nicht mit den Bestellbeträgen überein. Vergewissern Sie sich, dass die Summe der Parameter der Zahlungsdetails mit der Bestellsumme übereinstimmt.",
    "AUTHORIZE_NET_ERROR_CODE_CIM_SERVICE":"Das Zahlungsgateway-Konto ist nicht für den Fakturierungs- oder CIM-Service aktiviert.",
    "AUTHORIZE_NET_ERROR_CODE_CLIENT_AUTHORIZATION_DENIED":"Client-Autorisierung verweigert.",
    "AUTHORIZE_NET_ERROR_CODE_CONTENT_TYPES_NOT_SUPPORTED":"Die einzigen unterstützten Inhaltstypen sind text/xml und application/xml.",
    "AUTHORIZE_NET_ERROR_CODE_COULD_NOT_DECRYPT_DUKPT_BLOBS":"DUKPT-Blobs konnten nicht entschlüsselt werden und haben einen Fehler zurückgegeben.",
    "AUTHORIZE_NET_ERROR_CODE_COUNTRY_NOT_VALID":"Das Land ist nicht gültig.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_INVALID":"Die Kreditkarte ist zum Startdatum des Abonnements nicht gültig.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_NUMBER_INVALID":"Die Kreditkartennummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_PAYMENT_NOT_ACCEPTED_FOR_SELECTED_BUSINESS_COUNTRY":"Die Zahlungsmethode Kreditkarte wird für das ausgewählte Land nicht akzeptiert.",
    "AUTHORIZE_NET_ERROR_CODE_CREDIT_CARD_TRANSACTIONS_NOT_ACCEPTED":"Kreditkartentransaktionen werden von diesem Händler nicht akzeptiert.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_DATA_IS_EXCLUSIVE":"CustomerProfileId und Kundendaten schließen sich gegenseitig aus; für eine einzelne Anfrage kann nur eine der beiden Angaben gemacht werden.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFO_MISSED":"Fehler beim Erstellen eines Kundenprofils aus einer Transaktion.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_INFORMATION_REQUIRED":"Die Steuer-ID des Kunden oder Führerscheininformationen (Führerscheinnummer, Führerscheinstaat, Geburtsdatum des Führerscheins) sind für das Abonnement erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PAYMENT_PROFILEID":"Bei Verwendung des Kundenprofils muss die CustomerPaymentProfileId angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_CREEATION_FAILED":"Fehler beim Erstellen eines Kundenzahlungsprofils aus einer Transaktion.",
    "AUTHORIZE_NET_ERROR_CODE_CUSTOMER_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Kundenprofil ist mit einem aktiven oder ausgesetzten Abonnement verknüpft und kann nicht gelöscht werden.",
    "AUTHORIZE_NET_ERROR_CODE_DATABASE_OPERATION_TIMED_OUT":"Der Datenbankvorgang konnte nicht abgeschlossen werden, da die Zeit abgelaufen ist.",
    "AUTHORIZE_NET_ERROR_CODE_DEBIT_AMOUNT":"Die Summe der Gutschriften für die referenzierte Transaktion würde den ursprünglichen Belastungsbetrag übersteigen.",
    "AUTHORIZE_NET_ERROR_CODE_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_DECRPYTIING_EMV_DATA":"Beim Entschlüsseln der EMV-Daten ist ein Fehler aufgetreten.",
    "AUTHORIZE_NET_ERROR_CODE_DEFAULT":"Das Kundenprofil verfügt nicht über ein Standard-Zahlungs-/Versandprofil.",
    "AUTHORIZE_NET_ERROR_CODE_DUE_DATE_NOT_SPECIFIED":"Das Fälligkeitsdatum ist überfällig oder nicht angegeben.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_SUBSCRIPTION_ALREADY_SUBMITTED":"Es wurde bereits ein Duplikat des Abonnements eingereicht.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATE_TRANSACTION":"Es wurde eine doppelte Transaktion eingereicht.",
    "AUTHORIZE_NET_ERROR_CODE_DUPLICATED_KSN":"Die KSN ist dupliziert.",
    "AUTHORIZE_NET_ERROR_CODE_EMAIL_REQUIRED":"Eine gültige Kundenprofil-ID oder E-Mail ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_EMPID_LENGTH_1TO%X":"Die Mitarbeiter-ID muss zwischen 1 und %x Zeichen lang sein.",
    "AUTHORIZE_NET_ERROR_CODE_EMV_DATA_NOSUPPORTED":"EMV-Daten werden bei diesem Markttyp nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_DURING_PROCESSING":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_PLEASE_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_OCCURRED_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_PLEASE_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_ERROR_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_CODE_EXPIRATION_DATE_IS_INVALID":"Das Ablaufdatum der Kreditkarte ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SENDING_EMAIL":"E-Mail-Versand fehlgeschlagen.",
    "AUTHORIZE_NET_ERROR_CODE_FAILED_SHARED_KEY":"Validierung des gemeinsamen Schlüssels fehlgeschlagen.",
    "AUTHORIZE_NET_ERROR_CODE_FATAL_ERROR":"Schwerwiegender Fehler beim Aufruf des Webdienstes.",
    "AUTHORIZE_NET_ERROR_CODE_FDC_MERCHANT_ID":"Die FDC-Händler-ID oder Terminal-ID ist falsch. Rufen Sie den Merchant Service Provider an.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_TYPE_INVALID":"Der Feldtyp ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID":"Einer der Feldwerte ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_INVALID_LENGTH":"Eines der Felder hat eine ungültige Länge.",
    "AUTHORIZE_NET_ERROR_CODE_FIELD_VALUES_NOT_PRESENT":"Eines der erforderlichen Felder war nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_CANNOT_GENERATED":"Der Fingerabdruck kann nicht erstellt werden.",
    "AUTHORIZE_NET_ERROR_CODE_FINGER_PRINT_VALUE_INVALID":"Der Wert für den Fingerabdruck ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_GENERIC_INSERT_NEW_MERCHANT_FAILURE":"Generischer InsertNewMerchant-Fehler.",
    "AUTHORIZE_NET_ERROR_CODE_GLOBAL_PAYMENT_SYSTEM":"Die Identifikationsnummern des globalen Zahlungssystems sind falsch. Rufen Sie den Merchant Service Provider an.",
    "AUTHORIZE_NET_ERROR_CODE_HASH":"Hash stimmt nicht überein.",
    "AUTHORIZE_NET_ERROR_CODE_HOSTED_PAYMENT_PAGE":"Die Hosted Payment Page erfasst die Zahlungsinformationen (Bank/Karte), daher sollten diese Informationen nicht in diese Anfrage aufgenommen werden.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_API_KEY_INVALID":"Der Impersonation-API-Schlüssel ist ungültig oder nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_IMPERSONATION_PARTNET_LOGIN_ID_INVALID":"Die Impersonation-Partner-Login-ID ist ungültig oder nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_INTERVAL_INFORMATION_CANNOT_CHANGED":"Sobald ein Abonnement erstellt wurde, kann das Intervall nicht mehr geändert werden.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_AUTHENTICATION_VALUES":"Der API-Benutzername ist ungültig und/oder der Transaktionsschlüssel oder API-Schlüssel ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_BUYERID":"Die ID des Auktionskäufers ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_FOR_ACH_TRANSACTIONS":"Die Transaktionsart ist für ACH-Transaktionen ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INFORMATION":"In einem erforderlichen Feld fehlen Informationen oder sind ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_INTERVAL_LENGTH":"Die Intervalllänge muss 7 bis 365 Tage oder 1 bis 12 Monate betragen.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PAYERID":"PayPal hat die Transaktion abgelehnt.Ungültige Zahler-ID.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PLATEFORMID_PLATEFROMNAME":"Die Kombination aus Auktionsplattform-ID und Auktionsplattformname ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_POS_ENTRY":"Der Wert für den POS-Eingabemodus ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_PROFILE":"Die Kundenprofil-ID, Zahlungsprofil-ID, Lieferadressen-ID oder Transaktions-ID für diese Anfrage ist für diesen Händler nicht gültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_ROOT_NODE":"Der Name des Stammknotens der XML-Anforderung ist die aufgerufene API-Methode. Sie ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SELLERID":"Die ID des Auktionsverkäufers ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_SETTLEMENT_AMOUNT":"Der für die Abrechnung angeforderte Betrag darf sich nicht von dem ursprünglich genehmigten Betrag unterscheiden. Bitte stornieren Sie die Transaktion, falls erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_STATE":"Die V.me-Transaktion befindet sich in einem ungültigen Zustand.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_TRANSACTION_KEY":"Für die Benutzerauthentifizierung ist ein gültiger Wert für den Transaktions- oder API-Schlüssel erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_USER_NAME":"Für die Benutzerauthentifizierung ist ein gültiger Wert für den API-Benutzernamen erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VALUE":"Ungültiger Wert.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_VERSION":"Die EMV-Version ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_CALL_ID":"Der Wert für x_call_id ist nicht gefunden oder ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_DISCOUNT":"Der Wert für x_discount ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_GIFTWRAP":"Der Wert für x_giftwrap ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_MISC":"Der Wert x_misc ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_PAYPAL_PAYFLOWCOLOR":"PayPal hat die Transaktion abgelehnt. x_paypal_payflowcolor muss ein 6-stelliger hexadezimaler Wert sein.",
    "AUTHORIZE_NET_ERROR_CODE_INVALID_X_SUBTOTAL":"Der Wert für x_subtotal ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_CANCELLED":"Diese Rechnung wurde vom Absender storniert. Bitte kontaktieren Sie den Absender direkt, wenn Sie Fragen haben.",
    "AUTHORIZE_NET_ERROR_CODE_INVOICE_NUMBER":"Für die Rechnungsnummer wurden keine Datensätze gefunden.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_AMOUNT_ALLOWED":"Der eingereichte Transaktionsbetrag war größer als der maximal zulässige Betrag.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_ELEMENTS_FOR_ARRAY_REACHED":"Die maximale Anzahl von Elementen für ein Array wurde erreicht.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_PROFILE_REACHED":"Die maximale Anzahl der Zahlungsprofile für das Kundenprofil wurde erreicht.",
    "AUTHORIZE_NET_ERROR_CODE_MAXIMUM_PAYMENT_SHIPPING_ADDRESSES_REACHED":"Die maximale Anzahl der Versandadressen für das Kundenprofil wurde erreicht.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_BOARDING_API_NOT_ENABLE":"Das Wiederverkäuferkonto ist für Merchant Boarding API nicht aktiviert.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_CREDIT_CARD":"Der Händler akzeptiert diese Art von Kreditkarte nicht.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DECLINED_AUTHORIZATION":"Der Händler hat die Autorisierung für die Ressource abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_DOES_NOT_ACCEPT":"Der Händler akzeptiert diese Art von Kreditkarte nicht.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_LOGIN_ID":"Die Login-ID oder das Passwort des Händlers ist ungültig oder das Konto ist inaktiv.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_MANY_DEVICES_PENDING_STATUS":"Der Händler hat zu viele Geräte in einem ausstehenden Status.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_NOT_ACTIVATED":"Der Händler ist nicht aktiviert.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PASSWORD_INVALID":"Das Passwort des Händlers ist ungültig oder nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANT_PROCESSOR":"Der Händler wurde beim Prozessor falsch eingerichtet. Rufen Sie den Händlerdienstleister an.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTECH_IDENTIFICATION_NUMBER_INCORRECT":"Die Paymentech-Identifikationsnummern sind falsch. Rufen Sie den Merchant Service Provider an.",
    "AUTHORIZE_NET_ERROR_CODE_MERCHANTID":"Die MerchantId konnte nicht in eine ganze Zahl umgewandelt werden.",
    "AUTHORIZE_NET_ERROR_CODE_MISSING_X_CALL_ID":"Der Wert x_call_id fehlt.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_NOT_REGISTERED_WITH_ACCOUNT":"Die Kennung des mobilen Geräts ist nicht mit dem Händlerkonto verknüpft.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_PENDING_APPROVAL":"Das mobile Gerät ist vorhanden, befindet sich aber in einem ausstehenden Status.",
    "AUTHORIZE_NET_ERROR_CODE_MOBILE_DEVICE_STATUS_DISABLED":"Das mobile Gerät ist vorhanden, hat aber den Status \"deaktiviert\".",
    "AUTHORIZE_NET_ERROR_CODE_MUSTVALID_COUNTRYNAME":"Land muss ein gültiger zwei- oder dreistelliger Wert sein, falls angegeben.",
    "AUTHORIZE_NET_ERROR_CODE_NO_ACCESS_TO_OTS_TOKEN":"Der authentifizierte Händler hat keinen Zugriff auf das angegebene OTS-Token.",
    "AUTHORIZE_NET_ERROR_CODE_NO_RECORD_FOUND":"Es wurden keine Datensätze gefunden, die Ihrer Abfrage entsprechen.",
    "AUTHORIZE_NET_ERROR_CODE_NO_SUBSCRIPTION_AVAILABLE":"Für das Händlerkonto sind keine Abonnements für die angeforderte Art von Abonnements verfügbar.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_HAVE_PERMISSION":"Das Access-Token hat keine Berechtigung zum Aufruf der API-Methode.",
    "AUTHORIZE_NET_ERROR_CODE_NOT_SETTLED":"Die Transaktion wurde autorisiert, aber der Kunde konnte nicht benachrichtigt werden; sie wird nicht abgerechnet.",
    "AUTHORIZE_NET_ERROR_CODE_NOTSUPPORT_VOIDS":"V.me by Visa unterstützt keine Stornierungen von erfassten oder Kredit-Transaktionen. Bitte lassen Sie die Transaktion abrechnen und verarbeiten Sie dann eine Erstattung für die erfasste Transaktion.",
    "AUTHORIZE_NET_ERROR_CODE_ONE_FIELD_VALUES_INVALID":"Einer der Feldwerte ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_CREDIT_CARD_OR_BANK":"Es ist nur entweder CreditCard oder Bank erlaubt.",
    "AUTHORIZE_NET_ERROR_CODE_ONLY_ONE_PROFILE_PROVIDED_AT_A_TIME":"CreateProfile und profileIds schließen sich gegenseitig aus, es kann immer nur einer von beiden angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_OPAQUE_DATA":"Für die Erstellung eines Zahlungsprofils mit diesem OpaqueData-Deskriptor muss transactionMode auf liveMode gesetzt werden.",
    "AUTHORIZE_NET_ERROR_CODE_ORIGINAL_SUBSCRIPTION":"Wenn die Kundenprofil-ID angegeben wird, muss sie mit der für das ursprüngliche Abonnement verwendeten Kundenprofil-ID übereinstimmen.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_DATA_IS_INVALID":"Die angegebenen OTS-Token-Daten sind ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_HAS_EXPIRED":"Das angegebene OTS-Token ist abgelaufen.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_TOKEN_IS_INVALID":"Das angegebene OTS-Token ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_OTS_VALIDATION_ERROR":"Der OTS-Dienst kann die Anfrage aufgrund eines Validierungs- oder Konfigurationsfehlers nicht abschließen.",
    "AUTHORIZE_NET_ERROR_CODE_PAGING_OFFSET_EXCEEDS_MAXIMUM_ALLOWES_VALUED":"Der Paging-Offset überschreitet den zulässigen Wert. Überprüfen und verringern Sie den Wert.",
    "AUTHORIZE_NET_ERROR_CODE_PARSING_ERROR":"Beim Parsen der EMV-Daten ist ein Fehler aufgetreten.",
    "AUTHORIZE_NET_ERROR_CODE_PARTIAL_REVERSALS":"Dieser Prozessor akzeptiert keine Teilrückbuchungen.",
    "AUTHORIZE_NET_ERROR_CODE_PARTNER_ACCOUNT_NOT_AUTHORIZED_IMPERSONATE_LOGIN_ACCOUNT":"Das Partnerkonto ist nicht berechtigt, sich für das Anmeldekonto auszugeben.",
    "AUTHORIZE_NET_ERROR_CODE_PAYERID_DIFFERNT_CUSTOMER":"PayPal hat die Transaktion abgelehnt. Diese Zahler-ID gehört zu einem anderen Kunden.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_ACCEPTED":"Der Payment Gateway Account Service (id=8) wurde bereits akzeptiert. Eine Ablehnung ist nicht zulässig.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_ALREADY_DECLINED":"Der Zahlungsgateway-Kontodienst (id=8) wurde bereits abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_AUTOMATED_RECURRING_BILLING":"Das Zahlungsgateway-Konto ist nicht für die automatisierte wiederkehrende Rechnungsstellung aktiviert.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_ACCOUNT_NOT_ENABLED_FOR_CUSTOMER_INFORMATION_MANAGER":"Das Zahlungsgateway-Konto ist nicht für den Customer Information Manager (CIM) aktiviert.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_INFORMATION_REQUIRED":"Beim Erstellen eines Abonnements oder Zahlungsprofils sind Zahlungsinformationen erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_NOT_SUPPORTED":"Die Erstellung eines Kundenprofils ist fehlgeschlagen. Diese Zahlungsmethode unterstützt die Profilerstellung nicht.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Zahlungsprofil ist mit einem aktiven oder ausgesetzten Abonnement verknüpft und kann nicht gelöscht werden.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_SCHEDULE_REQUIRED":"Bei der Erstellung eines Abonnements ist ein Zahlungsplan erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_PAYMENT_TYPE_CANNOT_CHANGED":"Das Ändern der Zahlungsart für das Abonnement zwischen Kreditkarte und eCheck.Net wird derzeit nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_CODE_PENDING_STATUS":"Status \"Ausstehend\" (nicht abgeschlossen).",
    "AUTHORIZE_NET_ERROR_CODE_PREREQUISITE_FAILED":"Voraussetzung fehlgeschlagen.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_ACCOUNT_SET_TEST_MODE_FALSE":"Das Verarbeiterkonto wurde nicht so eingerichtet, dass das Kennzeichen für den Testmodus auf false gesetzt werden kann.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_INFORMATION":"Der Händler hat noch keine Prozessorinformationen bereitgestellt, um das Testmodus-Flag auf \"false\" zu setzen.",
    "AUTHORIZE_NET_ERROR_CODE_PROCESSOR_IS_INVALID":"Die Konfiguration mit dem Prozessor ist ungültig. Rufen Sie den Händlerdienstleister an.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_CREATION_FAILED":"Die Erstellung des Kundenprofils ist fehlgeschlagen. Diese Transaktions-ID ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs":"Zahlungs- und Versandprofil-IDs können bei der Erstellung neuer Profile nicht angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_IDs_CANNOT_BE_SPECIFIED":"Zahlungs- und Versandprofil-IDs können nicht angegeben werden, wenn eine gehostete Zahlungsseite angefordert wird.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_MUST_MATCH_ORIGINAL_TANSACTION":"Wenn die ID des Kundenprofils, die ID des Zahlungsprofils und die ID der Versandadresse angegeben werden, müssen sie mit der ursprünglichen Transaktion übereinstimmen.",
    "AUTHORIZE_NET_ERROR_CODE_PROFILE_NOT_FOUND":"Die Suche nach dem Versandprofil anhand der Kundenprofil-ID und der Versandprofil-ID hat keine Datensätze gefunden.",
    "AUTHORIZE_NET_ERROR_CODE_PROFLIE_ALREADY_EXISTS":"Es wurde bereits ein Duplikat des Kundenprofils, des Kundenzahlungsprofils oder der Kundenadresse übermittelt.",
    "AUTHORIZE_NET_ERROR_CODE_RECURRINGBILLING":"Die Einstellung RecurringBilling ist ein Pflichtfeld für wiederkehrende tokenisierte Zahlungstransaktionen.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCE_ID_NOT_FOUND":"Referenz-Id nicht gefunden.",
    "AUTHORIZE_NET_ERROR_CODE_REFERENCED_TRANSACTION":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Ausstellung einer Gutschrift.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTED_ACTION":"Die angeforderte Aktion ist aufgrund des aktuellen Status des Objekts nicht zulässig.",
    "AUTHORIZE_NET_ERROR_CODE_REQUESTING_CREATE_PROFILE_DATA_CANNOT_BE_NULL":"Bei der Anforderung von CreateProfile können die Zahlungsdaten nicht null sein.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_AUTHORIZED_PAYMENT":"Zahlung nicht autorisiert. Die Zahlung wurde vom Benutzer nicht autorisiert.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_EMV-VERSION":"Die EMV-Version ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_NUMERIC_NUMBER":"Terminal/Spur-Nummer muss numerisch sein.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_OPAQUE_DESCRIPTOR":"Opaker Deskriptor ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PAYERID":"Zahler-ID ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_PNG_FORMATE":"Die Unterschrift muss als PNG-formatierte Daten vorliegen.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_CANCEL_URL":"PayPal-Transaktionen erfordern eine gültige URL für cancel_url",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRE_VALID_SUCCESS_URL":"Für PayPal-Transaktionen ist eine gültige URL für success_url erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_REQUIRED_NOE_MISSING":"Erforderlicher Knoten fehlt.",
    "AUTHORIZE_NET_ERROR_CODE_RETURNED_ERROR":"Es wurde ein Validierungsfehler von V.me zurückgegeben.",
    "AUTHORIZE_NET_ERROR_CODE_REVIEW_THE_TRANSACTION":"Wir halten derzeit die letzte Transaktion zur Überprüfung zurück. Bevor Sie das Abonnement reaktivieren, überprüfen Sie die Transaktion.",
    "AUTHORIZE_NET_ERROR_CODE_ROOT_NODE_REFERENCE_INVALID_XML_NAMESPACE":"Der Stammknoten verweist nicht auf einen gültigen XML-Namensraum.",
    "AUTHORIZE_NET_ERROR_CODE_SELL_RATE_LESS_THAN_BUY_RATE":"Die Buyrate kann nicht auf einen niedrigeren Wert als die Sellrate gesetzt werden.",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_CURRENTLY_BUSY_":"Der Server ist derzeit überlastet. Bitte versuchen Sie es später noch einmal.",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_ERROR":"Server-Fehler",
    "AUTHORIZE_NET_ERROR_CODE_SERVER_IN_MAINTENANCE":"Der Server wird gerade gewartet, daher ist die angeforderte Methode nicht verfügbar. Bitte versuchen Sie es später noch einmal.",
    "AUTHORIZE_NET_ERROR_CODE_SESSION_EXPIRED":"Ihre Sitzung ist abgelaufen oder existiert nicht. Sie müssen sich erneut anmelden, um weiterarbeiten zu können.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_CANNOT_BE_GREATER":"Der für die Abrechnung angeforderte Betrag kann nicht größer sein als der ursprünglich genehmigte Betrag.",
    "AUTHORIZE_NET_ERROR_CODE_SETTLEMENT_FAILED":"Die Autorisierung wurde genehmigt, aber die Abrechnung ist fehlgeschlagen.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_DATA_IS_EXCLUSIVE":"Versandadressen-ID und Versanddaten schließen sich gegenseitig aus, es kann nur eine von ihnen für eine einzelne Anfrage angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_IS_ACTIVE_OR_SUSPENDED":"Das angegebene Versandprofil ist mit einem aktiven oder ausgesetzten Abonnement verbunden und kann nicht gelöscht werden.",
    "AUTHORIZE_NET_ERROR_CODE_SHIPPING_PROFILE_NOT_PROVIDED_WITHIN_CUSTOMER_PROFILE":"Wenn Sie das Kundenprofil mit Kreditkarteninformationen zur Angabe des Versandprofils verwenden, muss die Versandprofil-ID angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_SIGNATURE_KEY":"Signaturschlüssel fehlt.",
    "AUTHORIZE_NET_ERROR_CODE_SPECIFIED_DATE_IS_INVALID":"Berichte können nicht für zukünftige Daten erstellt werden, daher ist das angegebene Datum ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_START_DATE_CANNOT_CHANGED":"Sobald ein Abonnement erstellt wurde, kann das Startdatum nicht mehr geändert werden.",
    "AUTHORIZE_NET_ERROR_CODE_STATE_NOT_VALID":"Der Status ist nicht gültig.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_BEFORE_SUBMISSION_DATE":"Das Startdatum des Abonnements kann nicht vor dem Einreichungsdatum des Abonnements liegen.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_BE_CANCELED":"Abgelaufene oder beendete Abonnements können nicht gekündigt werden.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_CANNOT_UPDATE":"Abgelaufene, gekündigte oder beendete Abonnements können nicht aktualisiert werden.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_ID_NOT_FOUND":"Die Abonnement-ID für diese Anfrage ist für diesen Händler nicht gültig.",
    "AUTHORIZE_NET_ERROR_CODE_SUBSCRIPTION_START_DATE_REQUIRED":"Das Startdatum des Abonnements ist erforderlich, um ein Abonnement zu erstellen.",
    "AUTHORIZE_NET_ERROR_CODE_SUPPLIED_CURRENCY_CODE_IS_INVALID":"Der angegebene Währungscode ist entweder ungültig, wird nicht unterstützt, ist für diesen Händler nicht zulässig oder hat keinen Wechselkurs.",
    "AUTHORIZE_NET_ERROR_CODE_TERMINAL_ID_INCORRECT":"Die Elavon-Banknummer oder Terminal-ID ist falsch. Rufen Sie den Händlerdienstanbieter an.",
    "AUTHORIZE_NET_ERROR_CODE_TOOLARGE_DATA":"Die Signaturdaten sind zu groß.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_APPROVED":"Diese Transaktion wurde bereits genehmigt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_BLOCKED_IP_ADDRESS":"Die Transaktion wurde von einer gesperrten IP-Adresse eingereicht.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Erteilung einer fortgesetzten Autorisierung.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_CRITERIA_ISSUE_AUTO_CAPTURE":"Die referenzierte Transaktion erfüllt nicht die Kriterien für die Erteilung einer fortgesetzten Autorisierung mit Auto Capture.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_DOES_NOT_SUPPORT_PROFILE":"Die Erstellung eines Kundenprofils ist fehlgeschlagen. Diese Transaktionsart unterstützt die Profilerstellung nicht.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ENCRYPTED":"Diese Transaktion muss verschlüsselt werden.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_HAS_BEEN_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_ID_INVALID":"Die Transaktions-ID ist ungültig oder nicht vorhanden.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_IS_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_ACCEPTED_SYSTEM_MAINTENANCE":"Diese Transaktion kann derzeit aufgrund von Systemwartungsarbeiten nicht akzeptiert werden. Bitte versuchen Sie es später erneut.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_FOUND":"Die Transaktion kann nicht gefunden werden.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_PROCESSOR_OR_CARD_TYPE":"EMV-basierte Transaktionen werden derzeit für diesen Prozessor und Kartentyp nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOT_SUPPORTED_TRANSACTION_TYPE":"EMV-Daten werden für diese Transaktionsart nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_NOTACCEPTED":"PayPal-Transaktionen werden von diesem Händler nicht akzeptiert.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_REQUIRE_VERSION":"PayPal-Transaktionen erfordern eine x_version von mindestens 3.1.",
    "AUTHORIZE_NET_ERROR_CODE_TRANSACTION_TYPE_INVALID":"Die Transaktionsart ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_TRIAL_OCCURRENCES_MUST_BE_LESSTHAN_TOTAL_OCCURRENCES":"Die Anzahl der angegebenen Versuche muss geringer sein als die Anzahl der insgesamt angegebenen Versuche.",
    "AUTHORIZE_NET_ERROR_CODE_UNEXPECTED_SYSTEM_ERROR":"Bei der Verarbeitung dieser Anfrage ist ein unerwarteter Systemfehler aufgetreten.",
    "AUTHORIZE_NET_ERROR_CODE_UNKNOWN_ERROR":"Unbekannter Fehler.",
    "AUTHORIZE_NET_ERROR_CODE_UPDATE_STATUS":"Rechnung erstellt, aber E-Mail nicht gesendet und Status nicht aktualisiert",
    "AUTHORIZE_NET_ERROR_CODE_URL_INVALID":"Der Referrer, die Relay-Antwort oder der Quittungslink-URL ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_USER_ALREADY_EXISTS":"Der APIUser existiert bereits.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_HAVE_PERMISSION_USE_MOBILE_WITH_THIS_ACCOUNT":"Der Benutzer verfügt nicht über ausreichende Berechtigungen, um ein mobiles Gerät mit diesem Händlerkonto zu verwenden.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION":"Der Benutzer hat nicht die Berechtigung, die API aufzurufen.",
    "AUTHORIZE_NET_ERROR_CODE_USER_NOT_PERMISSION_CALL_API":"Der Benutzer verfügt nicht über die Berechtigung zum Aufrufen der API-Methode.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_AMOUNT_IS_REQUIRED":"Ein gültiger Betrag ist erforderlich.",
    "AUTHORIZE_NET_ERROR_CODE_VALID_TRIAL_AMOUNT_SUBMITTED ":"Die Anzahl der Versuche kann nicht Null sein, wenn ein gültiger Versuchsbetrag übermittelt wird.",
    "AUTHORIZE_NET_ERROR_CODE_VALUES_FOR_BOTH_MUST_BE_SUBMITTED":"Wenn entweder ein Versuchsbetrag oder eine Anzahl von Versuchen angegeben wird, müssen Werte für beide angegeben werden.",
    "AUTHORIZE_NET_ERROR_CODE_VITAL_IDENTIFICATION":"Die VITAL-Identifikationsnummern sind falsch. Rufen Sie den Merchant Service Provider an.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR":"Dies ist das Ergebnis eines XML-Parser-Fehlers.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_INVALID_NODE":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Der Knoten ist ungültig.",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_MISSING_FIELD":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Fehlende(s) Feld(er).",
    "AUTHORIZE_NET_ERROR_CODE_XML_PARSER_ERROR_TOO_MANY_NODES":"Dies ist das Ergebnis eines XML-Parser-Fehlers. Zu viele Knoten angegeben.",
    "AUTHORIZE_NET_ERROR_COMBINATION_OF_CARD_TYPE_AUTHENTICATIO_INDICATOR_CARDHOLDER_AUTHENTICATION_VALUE_INVALID":"Die Kombination aus Kartentyp, Authentifizierungskennzeichen und Karteninhaber-Authentifizierungswert ist ungültig.",
    "AUTHORIZE_NET_ERROR_COMMERCIAL_CARD_TYPE_INVALID":"Der kommerzielle Kartentyp ist ungültig.",
    "AUTHORIZE_NET_ERROR_COUNTRY_NOT_AUTHORIZED_FOR_BUYERS":"Dieses Land ist für Käufer nicht zugelassen.",
    "AUTHORIZE_NET_ERROR_CRYPTOGRAM_INVALID_OR_CANNOT_USED_IN_COMBINATION_WITH_OTHER_PARAMETER":"Das Kryptogramm ist entweder ungültig oder kann nicht in Kombination mit anderen Parametern verwendet werden.",
    "AUTHORIZE_NET_ERROR_CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
    "AUTHORIZE_NET_ERROR_DEVICE_TYPE_INVALID_OR_MISSING":"Der Gerätetyp ist ungültig oder fehlt.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_DATE_OF_BIRTH_INVALID":"Das Geburtsdatum des Führerscheins ist ungültig.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_NUMBER_INVALID":"Die Führerscheinnummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_DRIVING_LICENSE_STATE_INVALID":"Der Zustand des Führerscheins ist ungültig.",
    "AUTHORIZE_NET_ERROR_DURING_PROCESSING_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_DUTY_AMOUNT_INVALID":"Der Steuerbetrag ist ungültig.",
    "AUTHORIZE_NET_ERROR_ECH_NOTALLOWED":"Dieser eCheck.Net-Typ ist bei Verwendung des vom Zahlungsgateway gehosteten Zahlungsformulars nicht zulässig.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED":"Dieser eCheck.Net-Typ ist nicht zulässig.",
    "AUTHORIZE_NET_ERROR_ECHECK_NET_NOT_ALLOWED_FOR_BANK_ACCOUNT_TYPE":"Dieser eCheck.Net-Typ ist für diesen Bankkontotyp nicht zulässig.",
    "AUTHORIZE_NET_ERROR_ECHECK_TYPE_PARAMETER_INVALID":"Der Parameter eCheck-Typ ist ungültig.",
    "AUTHORIZE_NET_ERROR_FREIGHT_AMOUNT_INVALID":"Der Frachtbetrag ist ungültig.",
    "AUTHORIZE_NET_ERROR_GATEWAY_NO_LONGER_SUPPORTS_THE_REQUESTED_METHOD_OF_INTEGRATION":"Das Gateway unterstützt die angeforderte Integrationsmethode nicht mehr.",
    "AUTHORIZE_NET_ERROR_INVALID_CHECK_NUMBER":"Die Schecknummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_CREDIT_CARD_NUMBER":"Die Kreditkartennummer ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_EXPIRED_DATE":"Das Ablaufdatum der Kreditkarte ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_LINE_ITEM":"Die Belegposition %1 ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGID":"Die ID des Auktionsangebots ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_LISTINGTYPE":"Der Typ des Auktionsangebots ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_PLATEFORMID":"Die ID der Auktionsplattform ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_REQUIRE_FIELDS":"Ein oder mehrere Pflichtfelder fehlen oder sind ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_SPLITTENDERID":"Die angegebene SplitTenderID ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ECI_LENGTH":"Ungültige ECI-Länge des Token-Antragstellers.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_ID_LENGTH":"Die Länge der Token-Antragsteller-ID ist ungültig.",
    "AUTHORIZE_NET_ERROR_INVALID_TOKEN_REQUESTOR_NAME":"Ungültiger Token-Antragsteller-Name.",
    "AUTHORIZE_NET_ERROR_LINE_INVALID_AUCTION_PLATEFORM":"Der Name der Auktionsplattform ist ungültig.",
    "AUTHORIZE_NET_ERROR_LINE_ITEM_NOTALLOWED":"Die Anzahl der eingereichten Positionen ist nicht zulässig. Es können maximal %1 Positionen eingereicht werden.",
    "AUTHORIZE_NET_ERROR_MARKET_TYPE_INVALID":"Der Markttyp ist ungültig.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_CLOSED":"Dieses Händlerkonto wurde geschlossen.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Das Händlerkonto ist nicht aktiv. Diese automatische Zahlung wird nicht verarbeitet.",
    "AUTHORIZE_NET_ERROR_MERCHANT_ACCOUNT_TEST_MODE_AUTOMATED_PAYMENT_NOT_BE_PROCESSED":"Das Händlerkonto befindet sich im Testmodus. Diese automatisierte Zahlung wird nicht verarbeitet.",
    "AUTHORIZE_NET_ERROR_NOT_ACCEPTED_AUTHORIZATION":"Dieser Prozessor akzeptiert keine Null-Dollar-Autorisierung für diesen Kartentyp.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_CONTANCT_MERCHANT":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Händler.",
    "AUTHORIZE_NET_ERROR_OCCURED_DURING_PROCESSING_TRY_AGAIN":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_HTML_TYPE_CONFIGURATION_FIELDS_NOT_APPEAR_SAFE":"Ein oder mehrere der HTML-Typ-Konfigurationsfelder scheinen nicht sicher zu sein.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_REQUIRED_SUBMERCHANT_VALUE_MISSING":"Einer oder mehrere der erforderlichen Werte für den Sub-Händler fehlen.",
    "AUTHORIZE_NET_ERROR_ONE_OR_MORE_SUBMERCHANT_VALUE_INVALID":"Einer oder mehrere der Werte des Sub-Händlers sind ungültig.",
    "AUTHORIZE_NET_ERROR_PAYFORM_MASK_INVALID":"Die PayformMask ist ungültig.",
    "AUTHORIZE_NET_ERROR_PROCEESING_PAYMENT_DATA":"Es ist ein Fehler bei der Verarbeitung der Zahlungsdaten aufgetreten.",
    "AUTHORIZE_NET_ERROR_PROCESSING_APPLE_PAYMENT_NOT_ENABLED_FOR_MERCHANT_ACCOUNT":"Die Verarbeitung von Apple Payments ist für dieses Händlerkonto nicht aktiviert.",
    "AUTHORIZE_NET_ERROR_PROCESSING_ERROR":"Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ACCEPT_RECURRING_REQUEST":"Dieser Prozessor akzeptiert keine wiederkehrenden Transaktionen.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_ALLOW_VOIDING_CREDITS":"Dieser Anbieter erlaubt keine Stornierung von Gutschriften.",
    "AUTHORIZE_NET_ERROR_PROCESSOR_NOT_SUPPORT_THIS_METHOD_OF_SUBMITTING_PAYMENT_DATA":"Dieser Verarbeiter unterstützt diese Methode der Übermittlung von Zahlungsdaten nicht.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOT_ACCEPTED":"Diese Anfrage kann nicht akzeptiert werden.",
    "AUTHORIZE_NET_ERROR_REQUEST_NOTACCEPTED":"Transaktions-ID und Split-Tender-ID können nicht beide in derselben Anfrage verwendet werden.",
    "AUTHORIZE_NET_ERROR_REQUESTED_FORM_TYPE_INVALID":"Der angeforderte Formulartyp ist ungültig.",
    "AUTHORIZE_NET_ERROR_REQUIRE_ADDTIONAL_PAYMENT":"Der Betrag dieser Anforderung wurde nur teilweise auf der angegebenen Prepaid-Karte genehmigt. Eine zusätzliche Zahlung ist erforderlich, um den Restbetrag dieser Transaktion zu erfüllen.",
    "AUTHORIZE_NET_ERROR_REQUIRE_SUCCESS_URL":"Erfolgs-URL ist erforderlich.",
    "AUTHORIZE_NET_ERROR_RESPONSE_FORMAT_INVALID":"Das Antwortformat ist ungültig",
    "AUTHORIZE_NET_ERROR_REVERSAL_NOTSUPPORTED":"Storno wird für diese Transaktionsart nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_SCRIPT_REQUESTED_INVALID_OR_NOT_SUPPORTED":"Das angeforderte Skript ist entweder ungültig oder wird nicht mehr unterstützt.",
    "AUTHORIZE_NET_ERROR_SCRIPT_VERSION_SUPPORTED_IN_2.5":"Skripte werden nur in Version 2.5 unterstützt.",
    "AUTHORIZE_NET_ERROR_SHIPPING_STATE_OR_COUNTRY_INVALID":"Der Versandstaat oder das Land ist ungültig.",
    "AUTHORIZE_NET_ERROR_SSN_OR_TAXID_INVALID":"Die SSN oder Steuer-ID ist ungültig.",
    "AUTHORIZE_NET_ERROR_SURCHARGE_AMOUNT_INVALID":"Der Zuschlagsbetrag ist ungültig.",
    "AUTHORIZE_NET_ERROR_TAX_AMOUNT_INVALID":"Der Steuerbetrag ist ungültig.",
    "AUTHORIZE_NET_ERROR_TIP_AMOUNT_INVALID":"Der Trinkgeldbetrag ist ungültig.",
    "AUTHORIZE_NET_ERROR_TRACK1_DATA_INVALID_FORMAT":"Die Track1-Daten haben kein gültiges Format.",
    "AUTHORIZE_NET_ERROR_TRACK2_DATA_INVALID_FORMAT":"Track2-Daten haben ein ungültiges Format.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_AUTHORIZED_CLIENT_COULD_NOT_BE_NOTIFIED_IT_WILL_NOT_BE_SETTLED":"Die Transaktion wurde autorisiert, aber der Kunde konnte nicht benachrichtigt werden; sie wird nicht abgerechnet.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_ACCEPTED_AT_THIS_TIME":"Diese Transaktion kann zur Zeit nicht akzeptiert werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CANNOT_PROCESSED":"Diese Transaktion kann nicht verarbeitet werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CARDHOLDER_AUTHENTICATED_VALUES_CANNOT_MARKED_RECURRING":"Transaktionen mit Karteninhaber-Authentifizierungswerten können nicht als wiederkehrend markiert werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_CURRENTLY_UNDER_REVIEW":"Die Transaktion wird derzeit geprüft.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_DECLINED":"Diese Transaktion wurde abgelehnt.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_MARKET_TYPE_CANNOT_PROCESSED_ON_THIS_SYSTEM":"Transaktionen dieses Markttyps können in diesem System nicht verarbeitet werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_METHOD_INVALID":"Die Transaktionsmethode ist ungültig.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED":"Diese Transaktion kann nicht akzeptiert werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_ACCEPTED_FOR_PROCESSING":"Diese Transaktion kann nicht zur Verarbeitung akzeptiert werden.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_APPROVED":"Die referenzierte Transaktion wurde nicht genehmigt.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_NOT_SUPPORTED_BY_MERCHANT":"Die angegebene Transaktionsart wird für diesen Händler nicht unterstützt.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_RESULTED_IN_AVS_MISMATCH_AND_ADDRESS_PROVIDED_NOT_MATCH_BILLING_ADDRESS_OF_CARD_HOLDER":"Die Transaktion führte zu einer AVS-Fehlanpassung. Die angegebene Adresse stimmt nicht mit der Rechnungsadresse des Karteninhabers überein.",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID":"Die Transaktionsart ist ungültig",
    "AUTHORIZE_NET_ERROR_TRANSACTION_TYPE_INVALID_CONTACT_MERCHANT":"Die Transaktionsart ist ungültig. Bitte kontaktieren Sie den Händler.",
    "AUTHORIZE_NET_ERROR_VALID_BILLING_COUNTRY_REQUIRED":"Ein gültiges Rechnungsland ist erforderlich.",
    "AUTHORIZE_NET_ERROR_VALID_COUNTRY_REQUIRED":"Ein gültiges Land ist erforderlich.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_OR_PROVINCE_REQUIRED":"Ein gültiges Bundesland/Provinz ist erforderlich.",
    "AUTHORIZE_NET_ERROR_VALID_STATE_REQUIRED":"Ein gültiges Bundesland ist erforderlich.",
    "AUTHORIZE_NET_ERROR_VERSION_2.5_NO_LONGER_SUPPORTED":"Die Version 2.5 wird nicht mehr unterstützt.",
    "AUTHORIZE_NET_ERROR_VERSION_PARAMETER_INVALID":"Der Parameter Version ist ungültig.",
    "AUTO_GRANT_ACCESS_TO_GLOBAL_ADMINS":"Zugriff auf globale CSP-Administratoren zulassen",
    "AUTO_PAYMENT_REQUEST_SUCCESS":"Die Zahlung wurde erfolgreich eingeleitet. Die Verarbeitung wird einige Zeit dauern. Bitte überprüfen Sie den Status nach einiger Zeit.",
    "AUTO_RENEW_DENIED_FOR_PERPETUAL_SOFTWARE":"Die Markierung für die automatische Verlängerung gilt nur für NCE-Angebote",
    "AUTO_SYNC_CREATE_PLAN_PRODUCT_FAILED":"Planprodukt erstellen ist während des autoSync-Prozesses nicht erfolgreich",
    "AUTO_SYNC_MACRO_NOT_FOUND":"Makro nicht gefunden",
    "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND":"Mehrere gemappte Angebote gefunden",
    "AUTO_SYNC_MULTIPLE_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Mehrere gemappte Angebote mit Promotion gefunden",
    "AUTO_SYNC_MULTIPLE_PLAN_FOUND":"Mehrere Pläne gefunden",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND":"Keine gemappten Angebote gefunden",
    "AUTO_SYNC_NO_MAPPED_OFFERS_FOUND_WITH_PROMOTION":"Keine zugeordneten Angebote mit Aktion gefunden",
    "AUTO_SYNC_NO_PLAN_FOUND":"Kein Plan gefunden",
    "Autotask":"Automatische Aufgabe",
    "AUTOTASK_API_USER_NAME_IS_MISSING":"API-Benutzername fehlt. Bitte aktualisieren Sie den Benutzernamen im Einstellungsbildschirm.",
    "AUTOTASK_QAUNTITY_MISSMATCH_WITH_C3_OLD_QAUNTITY":"Autotask-Menge stimmt nicht mit alter C3-Menge überein",
    "AZURE_ADVISOR_SELECT_ENTITLEMENT":"Wählen Sie eine Berechtigung aus",
    "AZURE_ALL_RECOMMENDATIONS_CATEGORY":"Kategorie",
    "AZURE_ALL_RECOMMENDATIONS_DESCRIPTION":"Beschreibung",
    "AZURE_ALL_RECOMMENDATIONS_IMPACT":"Auswirkungen",
    "AZURE_ALL_RECOMMENDATIONS_IMPACTED_RESOURCE":"Betroffene Ressource",
    "AZURE_ALL_RECOMMENDATIONS_LAST_UPDATED":"Letzte Aktualisierung",
    "AZURE_ALL_RECOMMENDATIONS_POTENTIAL_BENEFITS":"Mögliche Vorteile",
    "AZURE_BILLING":"Azure-Abrechnung",
    "AZURE_BILLING_CYCLE":"Rechnungskreislauf",
    "AZURE_ENTITLEMENT_MANAGEMENT":"Azure-Berechtigung",
    "AZURE_ENTITLEMENT_MANAGEMENT_TENENTS_DROPDOWN_TOOLTIP_TEXT":"Azure-Berechtigungsverwaltung",
    "AZURE_ENTITLEMENT_NOT_FOUND_FOR_THE_AZUREPLAN":"Dem Mandanten fehlt eine Azure-Berechtigung!",
    "AZURE_ERROR_RECOMMENDATION_TEXT":"Fehler beim Abrufen der Azure Advisor-Empfehlungen",
    "AZURE_ESTIMATE":"Azure-Schätzung",
    "AZURE_ESTIMATE_ENTITLEMENT_BILLING_PERIOD_LABEL":"Abrechnungszeitraum",
    "AZURE_ESTIMATE_ENTITLEMENT_ID_LABEL":"Berechtigungs-ID",
    "AZURE_ESTIMATE_ENTITLEMENT_NAME_LABEL":"Berechtigungsname",
    "AZURE_ESTIMATE_LABEL_DEPARTMENT":"Abteilung :",
    "AZURE_ESTIMATE_LABEL_SITE":"Seite? ˅ :",
    "AZURE_ESTIMATE_REPORT":"Azure-Schätzbericht",
    "AZURE_ESTIMATE_TAGS":"Stichworte",
    "AZURE_ESTIMATE_TAGS_POPUP_INFO":"Bitte wählen Sie mindestens ein Tag zum Herunterladen der Azure-Schätzung aus",
    "AZURE_ESTIMATE_TAGS_POPUP_TITLE":"Azure-Tags",
    "AZURE_ESTIMATE_TAGS_TOOLTIP":"Im Azure-Portal definierte Ressourcentags",
    "AZURE_ESTIMATED_COST":"Geschätzte Kosten",
    "AZURE_ESTIMATES":"Azure-Schätzungen",
    "AZURE_GROUP_DETAILS":"Gruppendetails",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_AZURE_GROUPS":"Azure-Gruppen",
    "AZURE_GROUPS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_ADMIN_TO_GROUP":"Admin zur Gruppe hinzufügen",
    "AZURE_GROUPS_BUTTON_TEXT_ADD_GROUP":"Gruppe hinzufügen",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUP_USERS":"Zurück zu Gruppenbenutzer",
    "AZURE_GROUPS_BUTTON_TEXT_BACK_TO_GROUPS":"Zurück zu Gruppen",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_EDIT_GROUP":"Gruppe bearbeiten",
    "AZURE_GROUPS_BUTTON_TOOLTIPS_TEXT_GROUP_USERS":"Benutzer gruppieren",
    "AZURE_GROUPS_CAPTION_TEXT_ADD_GROUP_USER":"Gruppenbenutzer hinzufügen",
    "AZURE_GROUPS_CAPTION_TEXT_AZURE_GROUPS":"Azure-Gruppen",
    "AZURE_GROUPS_CAPTION_TEXT_GROUP_USERS_OF":"Benutzer von {{groupname}}",
    "AZURE_GROUPS_LABEL_TEXT_ADMINISTRATOR":"Administrator",
    "AZURE_GROUPS_LABEL_TEXT_BILLING_ACCESS":"Abrechnungszugriff",
    "AZURE_GROUPS_LABEL_TEXT_DANGER_THRESHOLD":"Gefahrenschwelle",
    "AZURE_GROUPS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "AZURE_GROUPS_LABEL_TEXT_EMAIL":"Email",
    "AZURE_GROUPS_LABEL_TEXT_ERROR_THRESHOLD":"Fehlerschwelle",
    "AZURE_GROUPS_LABEL_TEXT_GROUP_NAME":"Gruppenname",
    "AZURE_GROUPS_LABEL_TEXT_INFO_THRESHOLD":"Info-Schwelle",
    "AZURE_GROUPS_LABEL_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
    "AZURE_GROUPS_LABEL_TEXT_NOTIFICATION_FREQUENCY":"Benachrichtigungshäufigkeit",
    "AZURE_GROUPS_LABEL_TEXT_WARN_THRESHOLD":"Warnschwelle",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_ADMINISTRATOR":"Administrator",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_BILLING_ACCESS":"Abrechnungszugriff",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NAME":"Name",
    "AZURE_GROUPS_TABLE_HEADER_TEXT_NOTIFICATION_FREQUENCY":"Benachrichtigungshäufigkeit",
    "AZURE_LOADING":"Wenn Sie versuchen, Daten aus Azure zu laden, warten Sie bitte...",
    "AZURE_LOADING_MESSAGE":"Wenn Sie Microsoft Azure abfragen, kann das Laden eine Minute dauern...",
    "AZURE_MARKUP_UPDATED_SUCCESSFULLY_SUBMITTED_TEXT":"Azure-Margenwert erfolgreich aktualisiert",
    "AZURE_NEW_MARKUP":"Neues Markup",
    "AZURE_OLD_MARKUP":"Aktuelle Auszeichnung",
    "AZURE_PLAN_CREATION_FAILED":"Erstellung des Azure-Plans fehlgeschlagen",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_AMOUNT_HELP_TEXT":"Der Rechnungsbetrag wird für den Kunden sowie seine jeweiligen Standorte oder Abteilungen auf der Grundlage des zugewiesenen Prozentsatzes berechnet.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_HELP_TEXT":"Die Zuteilung des Azure-Plans und seiner Berechtigungen kann durch Zuweisung eines Prozentsatzes auf verschiedene Standort- und Abteilungseinheiten aufgeteilt werden.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS":"Anleitung",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_1":"Zuweisungsschritte: <br /> a. Erweitern Sie das gewünschte Abonnement. <br /> b. Klicken Sie auf die Prozentzahl, die einer Entität zugeordnet ist. <br /> c. Geben Sie den gewünschten Prozentsatz ein. <br /> d. Klicken Sie auf <strong>Senden <strong/>, um die Zuordnung zu speichern.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_2":"Um den Azure-Plan oder die Berechtigung vollständig einem Standort/einer Abteilung zuzuweisen, geben Sie 100 ein.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_3":"Um eine Zuordnung freizugeben, geben Sie 0 ein.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_4":"Freigegebene Zuteilungen werden der unmittelbaren Muttergesellschaft zugewiesen.",
    "AZURE_PRODUCT_SLIT_INVOICE_BY_PERCENTAGE_INSTRUCTIONS_PART_5":"Diese Zuweisungen haben keine Auswirkungen auf Berichte und der Azure-Kostenvoranschlags- und Abrechnungsbericht zeigt weiterhin alle Daten des Kunden an.",
    "AZURE_RECOMMENDARION_SCORE_BY_CATEGORY":"Bewerten Sie nach Kategorie",
    "AZURE_RECOMMENDATION_ALL_CATEGORIES":"Alle Kategorien",
    "AZURE_RECOMMENDATION_ALL_COST":"Kosten",
    "AZURE_RECOMMENDATION_ALL_HIGH_AVAILABILITY":"Hohe Verfügbarkeit",
    "AZURE_RECOMMENDATION_ALL_OPERATION_EXCELLENCE":"Operative Exzellenz",
    "AZURE_RECOMMENDATION_ALL_PERFORMANCE":"Leistung",
    "AZURE_RECOMMENDATION_ALL_RELIABILITY":"Zuverlässigkeit",
    "AZURE_RECOMMENDATION_ALL_SECURITY":"Sicherheit",
    "AZURE_RECOMMENDATION_DETAILS_DOWNLOAD_TOOLTIP":"Laden Sie die Empfehlungsdetails herunter",
    "AZURE_RECOMMENDATION_RECOMMENDATION_DETAILS":"Empfehlungsdetails",
    "AZURE_RECOMMENDATION_REQUIRED_ERROR":"Dieses Feld wird benötigt",
    "AZURE_RECOMMENDATION_SUBMIT":"einreichen",
    "AZURE_RECOMMENDATION_TEXT":"Azure-Empfehlung",
    "AZURE_RECOMMENDATIONS_TEXT":"Empfehlungen",
    "AZURE_REPORT_CURRENCY_CODE_DEFAULT_SELECT":"Wählen Sie einen Währungscode aus",
    "AZURE_REPORT_DISTRIBUTOR_LIST":"Verteiler",
    "AZURE_REPORT_DISTRIBUTOR_TOOLTIP":"Bitte wählen Sie entweder den Partner oder einen der Reseller aus.",
    "AZURE_REPORT_ENTITY_DEFAULT_SELECT":"Wählen Sie eine Entität aus",
    "AZURE_REPORT_ENTITY_LIST":"Einheit",
    "AZURE_REPORT_ENTITY_TOOLTIP":"Bitte wählen Sie entweder den Partner oder einen der Reseller aus.",
    "AZURE_REPORT_RESELLERS_DEFAULT_SELECT":"Wählen Sie einen Wiederverkäufer aus",
    "AZURE_REPORT_RESELLERS_LIST":"Wählen Sie einen Wiederverkäufer",
    "AZURE_REPORTS_BITTON_TEXT":"Azure-Schätzbericht",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY":"Details von",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_RESOURCE_GROUP":"Details nach Ressourcengruppe",
    "AZURE_REPORTS_BITTON_TEXT_DETAILS_BY_SERVICE":"Details nach Service",
    "AZURE_REPORTS_BITTON_TEXT_GET_DETAILS":"Beschaffe die Einzelheiten",
    "AZURE_REPORTS_BITTON_TEXT_WITH_OUT_TAGS":"Ohne Etiketten",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_AZURE_REPORTS":"Azure-Berichte",
    "AZURE_REPORTS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "AZURE_REPORTS_BY_TAGS_BITTON_TEXT":"Mit Anhängen",
    "AZURE_REPORTS_CAPTION_TEXT_BILLING_SUMMARY":"Rechnungsübersicht",
    "AZURE_REPORTS_LABEL_TEXT_AZURE_PLAN_PRICE":"Preis des Azure-Plans",
    "AZURE_REPORTS_LABEL_TEXT_DATE":"Datum",
    "AZURE_REPORTS_LABEL_TEXT_ENTITLEMENT":"Anspruch",
    "AZURE_REPORTS_LABEL_TEXT_GROUP":"Gruppe",
    "AZURE_REPORTS_LABEL_TEXT_MONTH":"Monat",
    "AZURE_REPORTS_LABEL_TEXT_SELECT_GROUP":"Wähle die Gruppe",
    "AZURE_REPORTS_LABEL_TEXT_SUBSCRIPTION":"Abonnement",
    "AZURE_REPORTS_OPTION_SELECT_TAG":"Wählen Sie ein Tag aus, um tagweise Daten zu erhalten",
    "AZURE_REPORTS_OPTION_TEXT_ALL_ENTITLEMENTS":"Alle Ansprüche",
    "AZURE_REPORTS_OPTION_TEXT_ALL_GROUPS":"Alle Gruppen",
    "AZURE_REPORTS_OPTION_TEXT_ALL_SUBSCRIPTIONS":"Alle Abonnements",
    "AZURE_REPORTS_OPTION_TEXT_SELECT_DATE":"Datum auswählen",
    "AZURE_REPORTS_OPTION_TEXT_UNASSIGNED":"Nicht zugewiesen",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_COST":"Kosten",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_QUANTITY":"Menge",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_RESOURCE_NAME":"Ressourcenname",
    "AZURE_REPORTS_TABLE_GROUP_HEADER_TEXT_SERVICE_NAME":"Dienstname",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_AUDIT":"Prüfung",
    "AZURE_REPORTS_TABLE_GROUP_TD_BUTTON_TEXT_DETAILS":"Einzelheiten",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Ressourcengruppe",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_RESOURCE_NAME":"Ressourcenname",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SERVICE_NAME":"Dienstname",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
    "AZURE_REPORTS_TABLE_HEADER_TEXT_TOTAL_COST":"Gesamtkosten",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_DATE_TIME":"Terminzeit",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_CATEGORY":"Ereigniskategorie",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_EVENT_INITIATED_BY":"Ereignis initiiert von",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_OPERATION_NAME":"Operationsname",
    "AZURE_REPORTS_TABLE_SHOWAUDIT_HEADER_TEXT_STATUS":"Status",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_AUDIT":"Prüfung",
    "AZURE_REPORTS_TABLE_SHOWROWS_BUTTON_TEXT_DETAILS":"Einzelheiten",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_COST":"Kosten",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_DATE":"Datum",
    "AZURE_REPORTS_TABLE_SHOWROWS_HEADER_TEXT_QUANTITY":"Menge",
    "AZURE_REPORTS_TABLE_TD_TEXT_TOTAL_COST":"Gesamtkosten",
    "AZURE_REPPORT_CURRENCY":"Währung",
    "AZURE_REPPORT_CURRENCY_TOOLTIP":"Sie müssen eine Währung auswählen, um den Bericht zu erhalten",
    "AZURE_REPPORT_CUSTOMERS":"Kunden",
    "AZURE_REPPORT_CUSTOMERS_DEFAULT_SELECT":"Wählen Sie Kunde",
    "AZURE_REPPORT_CUSTOMERS_TOOLTIP":"Wählen Sie einen Kunden aus",
    "AZURE_ROLE_NAME_CONTRIBUTOR":"Mitwirkender",
    "AZURE_ROLE_NAME_CUSTOMER_ROLE":"Benutzerdefinierte Rolle",
    "AZURE_ROLE_NAME_OWNER":"Inhaber",
    "AZURE_ROLE_NAME_READER":"Leser",
    "AZURE_SUBSCRIPTION":"Azure-Abonnement",
    "AZURE_SUBSCRIPTION_BUTTON_EDIT":"Bearbeiten",
    "AZURE_SUBSCRIPTION_DETAILS":"Abonnementdetails",
    "AZURE_SUBSCRIPTION_DROPDOWN_TEXT_SELECT":"Wählen",
    "AZURE_SUBSCRIPTION_ID":"Abonnement-ID",
    "AZURE_SUBSCRIPTION_NOT_FOUND_FOR_THE_TENANT":"Dem Mandanten fehlt ein Azure-Plan!",
    "AZURE_SUBSCRIPTION_SAVED_FAILED_NOTIFICATION":"Das Azure-Abonnement lautet \\''Update fehlgeschlagen\\''",
    "AZURE_SUBSCRIPTION_SAVED_SUCCESS_NOTIFICATION":"Das Azure-Abonnement wurde erfolgreich aktualisiert",
    "AZURE_SUBSCRIPTION_UPGRADE_CONFIRM_ACTION_POPUP":"Sind Sie sicher, dass Sie ein Upgrade durchführen möchten?",
    "AZURE_THRESHOLD_DETAILS":"Schwellendetails",
    "AZURE_THRESHOLD_HELP_TEXT_FIRST":"Legen Sie ein monatliches Budget für dieses Abonnement fest und definieren Sie die Schwellenwerte für verschiedene Schweregrade wie Info, Warnung, Fehler und Gefahr.",
    "AZURE_THRESHOLD_HELP_TEXT_SECOND":"Bei vom Kunden verwalteten Abonnements werden alle Gruppenadministratoren benachrichtigt, wenn die Nutzung einen bestimmten Schwellenwert erreicht oder überschreitet.",
    "AZURE_THRESHOLD_HELP_TEXT_THIRD":"Benachrichtigungen werden einmal pro Abrechnungszyklus gesendet.",
    "AZURE_UPGRADE_AZURE_PLAN_TEXT":"Azure-Plan",
    "AZURE_UPGRADE_ELIGIBILITY_LOADING_MESSAGE":"Bitte warten Sie, wir prüfen die Berechtigung für das Upgrade",
    "AZURE_UPGRADE_INELIGIBLE_MESSAGE":"Das Abonnement ist nicht für ein Upgrade berechtigt",
    "AZURE_UPGRADE_WAIT_MESSAGE":"Bitte warten Wir führen ein Upgrade durch...",
    "AZURE_USAGE_ACTIONS_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_AZURE_USAGE":"Azure-Verwendung",
    "AZURE_USAGE_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "AZURE_USAGE_CAPTION_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
    "AZURE_USAGE_CAPTION_TEXT_USAGE_DETAILS":"Verwendungsdetails",
    "AZURE_USAGE_TABLE_BUTTON_TEXT_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "AZURE_USAGE_TABLE_HEADER_TEXT_ESTIMATE_PRICE":"Schätzpreis",
    "AZURE_USAGE_TABLE_HEADER_TEXT_NAME":"Name",
    "AZURE_USAGE_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
    "AZUREGROUPS_ADD_USER_SUCCESS":"{{username}} erfolgreich zur Gruppe {{groupname}} hinzugefügt",
    "AZUREGROUPS_SAVE_SUCCESS":"Gruppe {{username}} erfolgreich gespeichert",
    "AZUREGROUPSCONTROLLER_SAVEGROUPDETAIL":"Gruppenbenutzer erfolgreich hinzugefügt",
    "AZUREGROUPSCONTROLLER_SAVEGROUPUSERDETAIL":"Gruppe erfolgreich gespeichert",
    "BACK_TO_AZURE_ADVISOR":"Zurück zum Azure Advisor",
    "BACK_TO_POLICIES":"Zurück zu Benutzerdefinierte Richtlinien",
    "BACK_TO_PUBLIC_SIGNUP_SHOP":"Zurück zum Shop",
    "BACK_TO_SERVICE_PROVIDER_TENANT":"Zurück zu Provider-Mietern",
    "BACKGROUND_DROPDOWN_TEMPLATE_1":"Vorlage 1",
    "BACKGROUND_DROPDOWN_TEMPLATE_10":"Vorlage 10",
    "BACKGROUND_DROPDOWN_TEMPLATE_2":"Vorlage 2",
    "BACKGROUND_DROPDOWN_TEMPLATE_3":"Vorlage 3",
    "BACKGROUND_DROPDOWN_TEMPLATE_4":"Vorlage 4",
    "BACKGROUND_DROPDOWN_TEMPLATE_5":"Vorlage 5",
    "BACKGROUND_DROPDOWN_TEMPLATE_6":"Vorlage 6",
    "BACKGROUND_DROPDOWN_TEMPLATE_7":"Vorlage 7",
    "BACKGROUND_DROPDOWN_TEMPLATE_8":"Vorlage 8",
    "BACKGROUND_DROPDOWN_TEMPLATE_9":"Vorlage 9",
    "BAD_INPUT_INVALID_PRODUCTID":"Ungültige Produkt-ID",
    "BAD_INPUT_INVALID_SEATS":"Ungültige Plätze",
    "BANNER_MODAL_ENTITIES_INVOLVED_MODAL_HEADER":"Entitäten, die an der Bannerbenachrichtigung beteiligt sind",
    "BILL_ACTION_DESC_FULL_CHARGE":"Volle Ladung für den ganzen Monat.",
    "BILL_ACTION_DESC_NO_REFUND":"Es wird keine Rückerstattung gewährt",
    "BILL_ACTION_DESC_PRORATE":"Proration wird in Rechnung gestellt.",
    "BILL_ACTION_NAME_FULL_CHARGE":"Volle Ladung",
    "BILL_ACTION_NAME_NO_REFUND":"Keine Rückerstattung",
    "BILL_ACTION_NAME_PRORATE":"Prorate",
    "BILL_CYCLE_DESC_ANNUAL":"Jährlich",
    "BILL_CYCLE_DESC_MONTHLY":"Monatlich",
    "BILL_CYCLE_DESC_ONETIME":"Einmal",
    "BILL_CYCLE_DESC_TRIENNIAL":"Alle drei Jahre",
    "BILL_CYCLE_NAME_ANNUAL":"Jährlich",
    "BILL_CYCLE_NAME_MONTHLY":"Monatlich",
    "BILL_CYCLE_NAME_TRIENNIAL":"Alle drei Jahre",
    "BILL_FROM":"Bill From",
    "BILL_TO":"Gesetzesentwurf für",
    "Billing":"Abrechnung",
    "BILLING_CUSTOMER_LINK_REMOVAL_FAIL":"Entfernung des Rechnungskundenlinks fehlgeschlagen.",
    "BILLING_CUSTOMER_LINK_REMOVAL_SUCCESS":"Rechnungskundenlink erfolgreich entfernt.",
    "BILLING_CYCLE_CHANGE_OF_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Eine Änderung des Abrechnungszyklus des Produkts ist nicht zulässig, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung durchgeführt wird. Bitte versuch es später.",
    "BILLING_CYCLE_CHANGE_TABLE_HEADER_TEXT_CURRENT_PRODUCT_NAME":"Aktueller Produktname",
    "BILLING_CYCLE_OF_ORDER_CHANGED_SUCCESSFULLY":"Abrechnungszyklus der Bestellung erfolgreich geändert",
    "BILLING_CYCLE_SEARCH_DEFAULT_OPTION":"Wählen",
    "BILLING_CYCLE_TOOLTIP_BUNDLE":"Rechnungskreislauf",
    "BILLING_FORECAST_REPORT":"Abrechnungsprognose",
    "BILLING_PERIOD":"Abrechnungszeitraum",
    "BILLING_PERIOD_SEARCH_DEFAULT_OPTION":"Wählen",
    "BILLING_PERIOD_SELECT_VALIDATION_ERROR":"Wählen Sie mindestens einen Abrechnungszeitraum aus",
    "BILLING_TYPE_DESC_MARK_UP":"Markup",
    "BILLING_TYPE_DESC_METERED_BILLING":"Gemessene Abrechnung",
    "BILLING_TYPE_DESC_PERCENTAGE":"Prozentsatz",
    "BILLING_TYPE_DESC_PRICE":"Preis",
    "BILLING_TYPE_DESC_UNIT":"Einheit",
    "BILLING_TYPE_TOOLTIP":"Abrechnungsart",
    "BREADCRUM_BUTTON_TEXT_AZURE_ESTIMATES":"Azure-Schätzungen",
    "BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTION":"Azure-Abonnement",
    "BREADCRUM_BUTTON_TEXT_LICENSES":"Lizenzen",
    "BREADCRUM_BUTTON_TEXT_POWER_BI_REPORTS":"Azure-Verwendung",
    "BREADCRUMB_HEADER_TEST_PAYMENT":"Testzahlung",
    "BREADCRUMB_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
    "BREADCRUMB_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Details zum Zahlungsstatus",
    "BTN_AZURE_BILLING_COMPARISON_REPORT":"Azure-Abrechnungsvergleichsbericht",
    "BTN_BILLING_FORECAST_REPORT_LOADING":"Rechnungsprognose...",
    "BTN_BULK_INVOICE_PROGRESS_DELETE_TOOLTIP":"Aufzeichnung löschen",
    "BTN_BULK_INVOICE_PROGRESS_DOWNLOAD_INVOICES_TOOLTIP":"Rechnungs-ZIP herunterladen",
    "BTN_BULK_INVOICE_PROGRESS_VIEW_INVOICES_TOOLTIP":"Rechnungsdetails anzeigen",
    "BTN_INVOICE_LINE_ITEMS":"Rechnungsposten",
    "BTN_INVOICE_LINE_ITEMS_WITH_TAX_REPORT_LOADING":"Rechnungsposten mit Steuern...",
    "BTN_INVOICE_PAYMENTS":"Rechnungszahlungen",
    "BTN_MULTIPLE_PAYMENTS_BACK_TO_PAYMENTS":"Zurück zu den Zahlungen",
    "BTN_QUOTE_COPY_URL":"URL kopieren",
    "BTN_QUOTE_PUBLISH":"Zitat veröffentlichen",
    "BTN_QUOTE_REPUBLISH":"Zitat erneut veröffentlichen",
    "BTN_QUOTE_SAVE_DRAFT":"Entwurf speichern",
    "BTN_TEXT_ADD_TO_CART":"in den Warenkorb legen",
    "BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Zurück zur Geschichte",
    "BTN_TEXT_BEGIN_UPLOAD":"Hochladen",
    "BTN_TEXT_BEGIN_VALIDATE_DATA":"Bestätigen",
    "BTN_TEXT_BEGIN_VALIDATE_DATA_VIEW_LOGS":"Protokolle anzeigen",
    "BTN_TEXT_CANCEL_ORDER":"Bestellung stornieren",
    "BTN_TEXT_COMMENT":"Kommentar",
    "BTN_TEXT_COPY_TO_CLIPBOARD":"In die Zwischenablage kopieren",
    "BTN_TEXT_DOWNLOAD_ALL_VOUCHERS":"Laden Sie alle Gutscheine herunter",
    "BTN_TEXT_FILTER":"Filter",
    "BTN_TEXT_FIX":"Fix",
    "BTN_TEXT_IGNORE":"Ignorieren",
    "BTN_TEXT_INCLUDE":"Einschließen",
    "BTN_TEXT_MAP_PROMOTIONAL_OFFER":"Kartenförderung",
    "BTN_TEXT_MAPPING_OF_PRODUCTS":"Produktzuordnung",
    "BTN_TEXT_OPEN_IN_EMAIL":"In E-Mail öffnen",
    "BTN_TEXT_PROCEED":"Vorgehen",
    "BTN_TEXT_QUICK_VIEW":"Schnellansicht",
    "BTN_TEXT_RECORD_MULTIPE_ADVANCED_PAYMENT":"Mehrere Zahlungen",
    "BTN_TEXT_RECORD_SINGLE_ADVANCED_PAYMENT":" Einmalzahlung",
    "BTN_TEXT_REMOVE":"Entfernen",
    "BTN_TEXT_REMOVE_MAP_PROMOTIONAL_OFFER":"Werbung entfernen",
    "BTN_TEXT_REQUEST_FOR_RESELLER_RELATIONSHIP":"Fordern Sie eine Reseller-Beziehung an",
    "BTN_TEXT_SHOW_FILTERS":"Filter anzeigen",
    "BTN_TEXT_SIGN_IN":"Einloggen",
    "BTN_TEXT_SKIP":"Überspringen",
    "BTN_TEXT_TAKE_ON":"Einschließen",
    "BTN_TEXT_UPLOAD_TO_AUTOTASK":"Autotask-Uploads verwalten",
    "BTN_TEXT_UPLOAD_TO_CONNECTWISE":"Connectwise-Uploads verwalten",
    "BTN_TEXT_VIEW_PRODUCT":"Produkt anzeigen",
    "BULK_ADD_ENTITLEMENT_BATCH_ID":"Batch-ID",
    "BULK_ADD_ENTITLEMENT_DESCRIPTION":"Zuletzt erstellter Berechtigungsname ist",
    "BULK_ADD_TENANT_DESCRIPTION":"Name des zuletzt erstellten Mieters ist",
    "BULK_ADD_TENANTS_BATCH_ID":"Batch-ID",
    "BULK_DATA_MAPPING_CSV_UPLOAD":"Kartiertes Blatt hochladen",
    "BULK_INVOICE_BACK_TO_REVENUE_AND_CUSTOMER_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
    "BULK_INVOICE_DOWNLOAD_CANCEL":"Stornieren",
    "BULK_INVOICE_DOWNLOAD_CUSTOMERS_ERROR_MESSAGE":"Wählen Sie mindestens einen Kunden aus",
    "BULK_INVOICE_DOWNLOAD_HEADER":"E-Mail alle Rechnungen",
    "BULK_INVOICE_DOWNLOAD_LABEL_TEXT_EMAILS":"E-Mail",
    "BULK_INVOICE_DOWNLOAD_MODAL_HEADER":"Erhalten Sie alle Rechnungen per E-Mail",
    "BULK_INVOICE_DOWNLOAD_MODAL_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie als E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf Senden, um eine ZIP-Datei mit einer Liste von Rechnungen im PDF-Format für den ausgewählten Abrechnungszeitraum zu erhalten",
    "BULK_INVOICE_DOWNLOAD_NOTIFICATION":"Wir benachrichtigen die bereitgestellten E-Mails, sobald die ZIP-Datei zum Download bereit ist! Es dauert normalerweise eine Stunde, bis es fertig ist",
    "BULK_INVOICE_DOWNLOAD_PLACEHOLDER_PLANS":"Plan auswählen",
    "BULK_INVOICE_DOWNLOAD_POPUP_CLOSE":"Schließen",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_DELETE_NOTIFICATION_SUCCESFULL":"Sammelrechnung wurde erfolgreich gelöscht.",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_OWNER":"Inhaber",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_STATUS":"Status",
    "BULK_INVOICE_DOWNLOAD_PROGRESS_POPUP_TABLE_HEADER_USER":"Benutzer",
    "BULK_INVOICE_DOWNLOAD_SEARCH_DEFAULT_OPTION":"Plan auswählen",
    "BULK_INVOICE_DOWNLOAD_SUBMIT":"einreichen",
    "BULK_INVOICE_PROGRESS_INVOICE_DETAILS_CONFIRMATION_MESSAGE":"Möchten Sie diesen Datensatz wirklich löschen?",
    "BULK_INVOICE_PROGRESS_POPUP_HEADER":"Download-Status der Rechnung",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_ACTIONS":"Aktionen",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_COMPLETED_AT":"Abgeschlossen um",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_EMAILS":"E-Mails",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_INITIATOR":"Initiator",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_LINK_AVAILABLE_DATE":"Link bis heute verfügbar",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_START_DATE":"Anfangsdatum",
    "BULK_INVOICE_PROGRESS_TABLE_HEADER_STATUS":"Status",
    "BULK_ONBOARD_BATCH_ID":"Bulk Onboard BatchId",
    "BULK_ONBOARD_CUSTOMERS_BATCH_ID":"Bulk Onboard BatchId",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS":"Kartierungsdetails herunterladen",
    "BULK_ONBOARD_CUSTOMERS_DOWNLOAD_MAPPING_DETAILS_FOR_ENTIRE_BATCH":"Abonnementzuordnung herunterladen",
    "BULK_ONBOARD_CUSTOMERS_INFO_TEXT_FOR_ONBOARDED_SUBSCRIPTION_DETAILS":"<b>{{ TotalSubscriptionsOnboarded }}</b> von <b>{{ TotalSubscriptionsAtProvider }}</b> Abonnements vom Partner Center erfolgreich zugeordnet.",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Kunden entfernen?",
    "BULK_ONBOARD_CUSTOMERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Kunden auswählen?",
    "BULK_ONBOARD_CUSTOMERS_SELECTED_COUNT_OUT_OF_TOTAL_CUSTOMERS":"Ausgewählte <b>{{s selectedCustomers}}</b> von <b>{{totalCustomers}}</b> Kunden für das Onboarding",
    "BULK_ONBOARD_EXISTING_PROVIDER_RESELLERS":"Integrieren Sie bestehende Wiederverkäufer",
    "BULK_ONBOARD_PLEASE_PROVIDE_VALID_EMAIL_ERROR":"Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "BULK_ONBOARD_REQUEST_ACCEPTED":"Enqueued Request for Bulk Onboard von Kunden",
    "BULK_ONBOARD_RESELLERS_BATCH_ID":"Batch-ID",
    "BULK_ONBOARD_RESELLERS_BATCH_STATUS_SET_TO_COMPLETE_CONFIRMATION_MESSAGE":"Der Stapel wird erfolgreich abgeschlossen. Sie werden zu Wiederverkäufern weitergeleitet.",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_SELECT_FROM_LIST":"Aus Liste auswählen",
    "BULK_ONBOARD_RESELLERS_BTN_TEXT_UPLOAD_CSV":"CSV-Datei hochladen",
    "BULK_ONBOARD_RESELLERS_ERROR_NOTIFICATION_SELECT_MINIMUM_ONE":"Bitte wählen Sie mindestens 1 Reseller für das Onboarding aus.",
    "BULK_ONBOARD_RESELLERS_INFO_HEADER":"Zusammenfassung der Onboard-Ergebnisse",
    "BULK_ONBOARD_RESELLERS_INFO_HELP_TEXT":"Insgesamt ausgewählt: {{totalResellersInBatch}}, Insgesamt integriert: {{resellersOnboarded}}, Insgesamt fehlgeschlagen: {{resellersFailedToOnboard}}",
    "BULK_ONBOARD_RESELLERS_LBL_OPTION":"Wie möchten Sie einsteigen?",
    "BULK_ONBOARD_RESELLERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Reseller entfernen?",
    "BULK_ONBOARD_RESELLERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Wiederverkäufer auswählen?",
    "BULK_ONBOARD_RESELLERS_REQUEST_IS_ENQUEUED":"Die Anfrage für Bulk Onboard Reseller befindet sich in der Warteschlange.<br /> Sie werden zum Statusbildschirm weitergeleitet, der den Fortschritt anzeigt.",
    "BULK_ONBOARD_RESELLERS_SELECTED_COUNT_OUT_OF_TOTAL_RESELLERS":"Ausgewählte <b>{{ausgewählteReseller}}</b> von <b>{{totalReseller}}</b> Resellern für das Onboarding",
    "BULK_ONBOARD_RESELLERS_STATUS_ERRORS":"Fehler",
    "BULK_ONBOARD_RESELLERS_STATUS_MPN_ID":"MPN-ID -",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_FAILED_TO_ONBOARD_IN_BATCH":"ResellerFehlgeschlagen",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_ONBOARDED_IN_BATCH":"WiederverkäuferErfolgreich",
    "BULK_ONBOARD_RESELLERS_TOTAL_RESELLERS_SELECTED_IN_BATCH":"TotalResellerSelected",
    "BULK_ONBOARD_VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Geben Sie eine gültige E-Mail-Adresse ein",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Anscheinend fehlen die MPN-ID-Details für den Reseller. Bitte wenden Sie sich an den Partner, um die Details zu aktualisieren.",
    "BULK_ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER_TITLE":"Onboarding wird nicht unterstützt !",
    "BULK_ONBOARDING_MICROSOFT_NON_CSP_CUSTOMERS_IS_NOT_SUPPORTED":"Bulk-Onboarding MicrosoftNonCSP-Kunden werden nicht unterstützt.",
    "BULK_ONBOARDING_RESELLERS_BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
    "BULK_ONBOARDING_RESELLERS_CAPTION_TEXT":"Bulk-Onboard-Wiederverkäufer",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER":"Anbieter",
    "BULK_ONBOARDING_RESELLERS_LABEL_TEXT_PROVIDER_TOOLTIPS":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
    "BULK_ONBOARDING_RESELLERS_STATUS_CAPTION_TEXT":"Status",
    "BULK_ONBOARDING_STATUS_INFO_HEADER":"Spitze! Schritte zum Beheben des teilweisen Onboardings",
    "BULK_ONBOARDING_STATUS_INFO_HELP_TEXT":"Wenn das System während des Onboardings eines Kunden Probleme mit der Abonnementzuordnung meldet, klicken Sie auf den Hyperlink, um die Details herunterzuladen und zu überprüfen. Fixieren Sie Ihre Pläne und arbeiten Sie mit dem Support zusammen, um die Daten in C3 zu synchronisieren.",
    "BULK_PRODUCT_MAPPING":"Massenproduktzuordnung",
    "BULK_PURCHASED_PRODUCT_MAPPING":"Produktzuordnung für Massenkäufe",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_1":"Laden Sie den Katalog herunter, um Kunden und deren IDs zu erhalten.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_2":"Geben Sie den Währungscode, den Zahlungsbetrag und das Zahlungsdatum für die entsprechende C3Id ein.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3":"Stellen Sie sicher, dass die Daten den folgenden Regeln entsprechen:",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_A":"<strong>C3Id -Text–</strong> C3-Kunden-ID (oder die C3-Wiederverkäufer-ID) aus dem Katalog, für den der Kunde die Zahlung erfasst werden möchte",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_B":"<strong>CurrencyCode – Text (ISO-Standard-Währungscode) –</strong> In welcher Währung die Zahlung erfasst werden soll",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_C":"<strong>PaymentAmount – Dezimal –</strong> Zu erfassender Betrag",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_D":"<strong>Bemerkungen – Text –</strong> Kommentare (optional)",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_3_E":"<strong>PaymentDate - Datum (JJJJ-MM-TT) –</strong> An welchem Datum muss die Zahlung verbucht werden! Nur vergangene Termine!",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_4":"Klicken Sie auf <strong>Auswählen</strong> und navigieren Sie zur Datei.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_5":"Klicken Sie auf <strong>Katalog validieren</strong>, damit das System die Eingabe validiert.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_6":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_7":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
    "BULK_RECORD_MULTIPLE_PAYMENTS_INSTRUCTIONS_PART_8":"Wenn in der Eingabe keine Validierungsfehler vorliegen, wird die Schaltfläche <strong>Zahlungen importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
    "BULK_UPLOAD_CUSTOMER_BUTTON_COMPLETE":"Getan",
    "BULK_UPLOAD_CUSTOMER_HEADER_SELECTED_CUSTOMERS":"Ausgewählte Kunden",
    "BULK_UPLOAD_CUSTOMER_NOT_SELECTED":"Wählen Sie mindestens einen Kunden/einen Plan",
    "BULK_UPLOAD_CUSTOMER_REMOVE_CONFORMATION":"Möchten Sie den Kunden entfernen?",
    "BULK_UPLOAD_CUSTOMER_SUCCESS":"Die Anfrage, mehrere Kunden hinzuzufügen, wird angenommen",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PARTNER_OFFER_DAILY_USAGE_DATA_VALIDATION_STATUS_SUCCESS":"Erfolg",
    "BULK_UPLOAD_PRODUCT_CODES_AND_SEQUENCE_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "BULK_UPLOAD_PRODUCT_CODES_DOWNLOAD_SAMPLE_CSV_HYPERLINK_TEXT":"Laden Sie eine Z.B-CSV herunter",
    "BULK_UPLOAD_PRODUCT_CODES_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT":"Laden Sie den Katalog herunter, um die neuesten Codes und Sequenzen zu erhalten.",
    "BULK_UPLOAD_PRODUCT_CODES_HELP_TEXT_2":"Datei als Referenz.",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_CODES_IMPORT_STATUS_SUCCESS":"Erfolg",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS":"Anleitung",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_1":"Füllen Sie den Code oder die Sequenz oder beides für die gewünschten Produkte aus und entfernen Sie die Zeilen, die Sie nicht ändern möchten. Der Produktcode ist optional und unterstützt 100 Zeichen. Die Anzeigesequenz ist eine Zahl und optional.<br /><strong>Hinweis:</strong> Wenn Sie die beiden leer lassen, werden der vorhandene Code und die Sequenz gelöscht.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_2":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_3":"Klicken Sie auf <strong class=\"text-uppercase\">Katalog validieren</strong>, damit das System die Eingabe validiert.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_4":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_5":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
    "BULK_UPLOAD_PRODUCT_CODES_INSTRUCTIONS_PART_6":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_IMPORT_COMPLETED":"Import abgeschlossen!",
    "BULK_UPLOAD_PRODUCT_CODES_NOTIFICATION_VALIDATION_COMPLETED":"Validierung abgeschlossen!",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_IMPORT":"Katalog importieren",
    "BULK_UPLOAD_PRODUCT_CODES_POPUP_TITLE_VALIDATE":"Katalog validieren",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_CODES_VALIDATION_STATUS_SUCCESS":"Erfolg",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie alle Kunden aus",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Abteilung auswählen",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_HELP_TEXT":"Laden Sie den Katalog herunter, um die neuesten Produktprovisionsdetails zu erhalten.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_IMPORT_STATUS_SUCCESS":"Erfolg",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_1":"Füllen Sie den Namen des Agenten, den SPCode, den Provisionsprozentsatz, das Startdatum der Provision und das Enddatum der Provision für die gewünschten Produkte aus und entfernen Sie die Zeilen, die Sie nicht ändern möchten. Alle diese Spalten sind optional. <br /> Agentenname ist ein Text mit 100 Zeichen. <br />SPCode ist ein Text mit 100 Zeichen. <br />Provisionsprozentsatz ist eine Zahl, die auch Dezimalzahlen unterstützt. <br />Das Startdatum der Provision ist ein Datum, das im Format <strong>{{dateFormat}}</strong> definiert werden muss.<br />Das Enddatum der Provision ist ein Datum, das im Format <strong>{ definiert werden muss. {dateFormat}}</strong>-Format.<br /><strong>Hinweis:</strong> Wenn Sie die vorhandenen Werte auf leer aktualisieren, werden die vorhandenen Werte gelöscht, sobald der Katalog importiert wird.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_2":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_3":"Klicken Sie auf <strong class=\"text-uppercase\">Katalog validieren</strong>, damit das System die Eingabe validiert.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_4":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_5":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_INSTRUCTIONS_PART_6":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{validationProcessedCount}}<br />Fehler: {{validationErrorCount}}<br />Erfolg: {{validationSuccessCount}}",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie Standort aus",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_ERROR":"Error",
    "BULK_UPLOAD_PRODUCT_COMMISSIONS_VALIDATION_STATUS_SUCCESS":"Erfolg",
    "BULK_UPLOAD_RESELLERS_DETAILS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "BULK_UPLOAD_RESELLERS_REMOVE_CONFORMATION":"Möchten Sie den Reseller entfernen?",
    "BULK_UPLOAD_USER_ERROR_EQUIVALENT_OFFERS_IN_PLAN":"Die folgenden Abonnements enthalten keine entsprechenden Angebote im Plan. Bitte aktualisieren Sie den Plan und versuchen Sie es erneut.",
    "BULK_UPLOAD_USER_ERROR_UNABLE_TO_FIND_USER":"In CSP konnte kein Kunde mit der ID {{CSPCustomerId}} gefunden werden.",
    "BULK_UPLOAD_USER_ERROR_VALIDATE_DUPLICATE":"Die folgenden Angebote wurden mehrmals in einem Level gekauft, was in C3 nicht unterstützt wird. Bitte überprüfen Sie die Daten.",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Entfernen",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "BUNDLE_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
    "BUNDLE_PRODUCT_UPDATE_ERROR_MESSAGE":"Ihr letzter Update-Vorgang scheint einige Fehler zu haben. Wenden Sie sich an das Support-Team, um Unterstützung zu erhalten",
    "BUNDLE_PRODUCTS_PLACEHOLDER":"Produkte bündeln",
    "BUNDLE_REPORT_FILE_TYPES_HEADER":"Bericht zu Produktdetails im Bundle",
    "BUNDLE_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit zum Abrufen des Bundle-Produktdetailberichts aus",
    "BUNDLE_REPORT_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Geben Sie bitte die E-Mail-Adressen der Empfänger ein, getrennt durch Kommas (,) oder Semikolon (;), und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste mit den Bundle-Produktdetails zu erhalten.",
    "BUNDLE_SAVE_SUCCESS":"Bundle {{customoffer}} erfolgreich gespeichert",
    "BUNDLE_UPDATE_SUCCESS":"Bundle {{customoffer}} erfolgreich aktualisiert",
    "bundles":"Bündel",
    "BUNDLES_BILLING_CYCLE_TOOLTIP":"Abrechnungshäufigkeit, mit der das Paket abgerechnet wird",
    "BUNDLES_BILLING_TYPE_TOOLTIP":"Abrechnungskategorie, auf der das Paket abgerechnet wird",
    "BUNDLES_CANNOT_BE_DELETED":"Bundles können nicht gelöscht werden, da sie einem Plan zugewiesen sind",
    "BUNDLES_SALETYPE_TOOLTIP":"Kategorietyp für das Angebot",
    "BUSY_MESSAGE_IMPORT_IS_IN_PROGRESS":"Der Import wird ausgeführt",
    "BUSY_MESSAGE_TEXT_PLEASE_WAIT":"Bitte warten...",
    "BUSY_MESSAGE_VALIDATION_IS_IN_PROGRESS":"Die Validierung ist in Bearbeitung",
    "BUTTON_ACTIVATE":"aktivieren Sie",
    "BUTTON_APPLY_COUPON_TEXT":"Gutschein einlösen",
    "BUTTON_CLOSE":"Schließen",
    "BUTTON_DEACTIVATE":"Deaktivieren",
    "BUTTON_DOWNLOAD_BULK_INVOICE_CANCEL":"Stornieren",
    "BUTTON_DOWNLOAD_BULK_INVOICE_DOWNLOAD":"Herunterladen",
    "BUTTON_INSTRUNCTION_PSA_REFRESH_BUTTON":"Aktualisierung",
    "BUTTON_LANG_CN":"Chinesisch",
    "BUTTON_LANG_DE":"Deutsch",
    "BUTTON_LANG_EN":"Englisch",
    "BUTTON_LANG_ES":"Spanisch",
    "BUTTON_LANG_FR":"Französisch",
    "BUTTON_LANG_IT":"Italienisch",
    "BUTTON_LANG_SK":"slowakisch",
    "BUTTON_LANG_TH":"Thai",
    "BUTTON_LANG_TR":"Türkisch",
    "BUTTON_LANG_TW":"Taiwanese",
    "BUTTON_LANGUAGE":"Sprache",
    "BUTTON_MANAGE_PRODUCT":"Verwalten",
    "BUTTON_ONHOLD":"In Wartestellung",
    "BUTTON_PROCESSING":"wird bearbeitet",
    "BUTTON_RELEASE":"Freisetzung",
    "BUTTON_REVOKE":"Widerrufen",
    "BUTTON_START_TOUR":"Machen Sie eine Tour",
    "BUTTON_TEXT_ACCEPT":"Akzeptieren",
    "BUTTON_TEXT_ACTIONS":"Aktionen",
    "BUTTON_TEXT_ADD":"Hinzufügen",
    "BUTTON_TEXT_ADD_CURRENCY_CONVERSION":"Neue Währungsumrechnung hinzufügen",
    "BUTTON_TEXT_ADD_CUSTOM_POLICY":"Benutzerdefinierte Richtlinie hinzufügen",
    "BUTTON_TEXT_ADD_EVENT_EMAIL_NOTIFICATION":"Neue Ereignis-E-Mail-Benachrichtigung hinzufügen",
    "BUTTON_TEXT_ADD_SUB_TAX":"Substeuer hinzufügen",
    "BUTTON_TEXT_ADD_WITH_ADDONS":"Mit Add-Ons hinzufügen",
    "BUTTON_TEXT_APPLY":"Anwenden",
    "BUTTON_TEXT_APPROVE":"Genehmigen",
    "BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
    "BUTTON_TEXT_CANCEL":"Stornieren",
    "BUTTON_TEXT_CHECK_ALL_PENDING_PAYMENT_STATUS":"Überprüfen Sie alle Status",
    "BUTTON_TEXT_CLEAR":"klar",
    "BUTTON_TEXT_CLOSE":"Schließen",
    "BUTTON_TEXT_DELETE":"Löschen",
    "BUTTON_TEXT_DOWNLOAD":"Herunterladen",
    "BUTTON_TEXT_DOWNLOAD_ACCOUNT_MANAGERS":"Account Manager herunterladen",
    "BUTTON_TEXT_DOWNLOAD_CATALOGUE":"Katalog herunterladen",
    "BUTTON_TEXT_DOWNLOAD_CUSTOMER":"Kunden herunterladen",
    "BUTTON_TEXT_DOWNLOAD_EXTERNAL_ACCOUNTING_SERVICES_POST_LOG_SUMMARY":"Rechnungen nach Protokollzusammenfassung herunterladen",
    "BUTTON_TEXT_DOWNLOAD_INVOICELINEITEM":"Laden Sie den Rechnungsposten herunter",
    "BUTTON_TEXT_DOWNLOAD_INVOICES_DUES":"Rechnungsgebühren herunterladen",
    "BUTTON_TEXT_DOWNLOAD_PARTNER_OFFERS":"Partnerangebote herunterladen",
    "BUTTON_TEXT_DOWNLOAD_PLAN":"Plan herunterladen",
    "BUTTON_TEXT_DOWNLOAD_PLAN_OFFER":"Planangebot herunterladen",
    "BUTTON_TEXT_DOWNLOAD_REPORT":"Bericht herunterladen",
    "BUTTON_TEXT_EDIT":"Bearbeiten",
    "BUTTON_TEXT_EXPORT_TO_EXCEL":"Nach Excel exportieren",
    "BUTTON_TEXT_FILTER":"Filter",
    "BUTTON_TEXT_LOAD_OFFERS":"Angebote laden",
    "BUTTON_TEXT_OK":"OK",
    "BUTTON_TEXT_OK_CLOSE":"Ok, nah dran",
    "BUTTON_TEXT_OVERRIDE":"Überschreiben",
    "BUTTON_TEXT_PAY_INVOICES":"Ausstehende Rechnungen bezahlen",
    "BUTTON_TEXT_PROCEED_TO_PURCHASE":"Fahren Sie mit dem Kauf fort",
    "BUTTON_TEXT_RECORD_ADVANCE_PAYMENT":"Vorauszahlung aufzeichnen",
    "BUTTON_TEXT_REJECT":"Ablehnen",
    "BUTTON_TEXT_REMOVE":"Entfernen",
    "BUTTON_TEXT_REMOVE_SUB_TAX":"Substeuer entfernen",
    "BUTTON_TEXT_REPORTS":"Berichte",
    "BUTTON_TEXT_RESET":"Zurücksetzen",
    "BUTTON_TEXT_REVERT":"Zurückkehren",
    "BUTTON_TEXT_SAVE":"speichern",
    "BUTTON_TEXT_SEARCH":"Suche",
    "BUTTON_TEXT_SET_OPERATIONAL_ENTITY":"Betriebseinheit festlegen",
    "BUTTON_TEXT_SUBMIT":"einreichen",
    "BUTTON_TEXT_UPDATE":"Aktualisieren",
    "BUTTON_TEXT_UPLOAD_TO_QUICKBOOKS_ONLINE":"HOCHLADEN AUF QUICKBOOKS ONLINE",
    "BUTTON_TEXT_UPLOAD_TO_XERO":"HOCHLADEN AUF XERO",
    "BUTTON_TEXT_VIEW_ADDON_PARENTS":"Eltern ansehen",
    "BUTTON_TEXT_VIEW_EXTERNAL_SERVICE_POST_LOGS":"POST-LOGS ANZEIGEN",
    "BUTTON_TEXT_VIEW_OFFERS":"Angebote anzeigen",
    "BUTTON_VIEW_ERRORS":"Fehler anzeigen",
    "BUTTON_VIEW_PRODUCT":"Aussicht",
    "BUTTONS_TEXT_ADD_PARTNER_USER_TAG":"Tag hinzufügen",
    "BUTTONS_TEXT_EDIT_PARTNER_USER_TAG":"Tag bearbeiten",
    "BUTTONS_TEXT_FOR_PARTNER_USER_TAG":"für",
    "C3_CART":"Wagen",
    "C3_SHOP_POPUP_PROVIDER_CATEGORY":"Anbieterkategorie",
    "C3_TEXT_ONHOLD_STATUS":"In Wartestellung",
    "c3supportAzuremargin":"Azurblauer Rand",
    "CALENDAR_CLEAR_BUTTON":"klar",
    "CALENDAR_CLOSE_BUTTON":"Schließen",
    "CANCEL_SCHEDULED_ORDER_COMMENT_INSTRUCTION":"Bitte geben Sie einen Grund für die Stornierung der Bestellung ein",
    "CANCEL_SCHEDULED_ORDER_EMPTY_REASON_ERROR":"Für die Stornierung der geplanten Bestellung ist ein Grund erforderlich",
    "CANCEL_SCHEDULED_ORDER_SUCCESS":"Bestellung wurde erfolgreich storniert",
    "CANCEL_SCHEDULED_ORDERE_COMMENT_HEADER":"Bestellung stornieren",
    "CANCEL_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Geplante Bestellung stornieren",
    "CANCEL_SCHEDULED_PRODUCT_EMPTY_REASON_ERROR":"Für die Stornierung des geplanten Produkts ist ein Grund erforderlich",
    "CANCEL_SCHEDULED_PRODUCT_REASON_ERROR":"Für die Stornierung der Bestellung ist ein Grund erforderlich.",
    "CANCEL_SCHEDULED_PRODUCT_SUCCESS":"Das Produkt wurde erfolgreich aus dieser Bestellung storniert",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_AUTO_RENEW_STATUS_CHANGE":"Die geplante Verlängerung wurde aufgrund einer Änderung des Status der automatischen Verlängerung abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_QUANTITY_UPDATE":"Geplante Verlängerung wegen Mengenaktualisierung abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_REJECTION_OF_REQUEST_FOR_NEXT_SCHEDULED_RENEWAL_CHANGE":"Die geplante Verlängerung wurde aufgrund der Ablehnung des Verlängerungsantrags abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SCHEDULE_PROPERTY_CHANGED_FROM_PARTNER_CENTER":"Die geplante Verlängerung wurde abgebrochen, da die Zeitplaneigenschaft vom Partnercenter geändert wurde",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_STATUS_CHANGE":"Die geplante Verlängerung wurde aufgrund einer Änderung des Abonnementstatus abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPDATE":"Geplante Verlängerung aufgrund einer Abonnementaktualisierung abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_DUE_TO_SUBSCRIPTION_UPGRADE":"Geplante Verlängerung aufgrund eines Abonnement-Upgrades abgesagt",
    "CANCEL_SCHEDULED_RENEWAL_FAILED_MESSAGE":"Die geplante Verlängerung konnte nicht abgebrochen werden",
    "CANCEL_SCHEDULED_RENEWAL_REASON":"Grund",
    "CANCEL_SCHEDULED_RENEWAL_REASON_PLACEHOLDER":"Bitte geben Sie einen Grund an",
    "CANCEL_SCHEDULED_RENEWAL_REASON_REQUIRED_ERROR":"Dieses Feld wird benötigt",
    "CANCEL_SCHEDULED_RENEWAL_SUCCESS_MESSAGE":"Die geplante Verlängerung wurde erfolgreich abgebrochen",
    "CANCELLED_REASON":"Grund für den Abbruch",
    "CANNOT_DELETE_OLDER_CURRENCY_CONVERSION_RULE":"Eine ältere Währungsumrechnungsdefinition kann nicht gelöscht werden",
    "CANNOT_INITIATE_PAYMENT":"Eine Zahlung kann aufgrund einer ausstehenden Transaktion nicht initiiert werden",
    "CANNOT_LINK_PRODUCT_MESSAGE_INFO":"Dieses Produkt wurde bereits vom Kunden gekauft. Eine Verlinkung des Produkts ist nicht möglich.",
    "CanPriceLag":"KannPreisVerzögerung",
    "CanPriceLead":"KannPreisBlei",
    "CAPTION_REQUEST_FOR_RESELLER_RELATIONSHIP":"Anfrage für Reseller-Beziehung",
    "CARD_CARD_DELETE_ADDON":"Löschen",
    "CARD_EXCHANGE_ADMIN_CENTER_TEXT":"Austauschzentrum",
    "CARD_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Produktnutzungsdaten",
    "CART_CARD_COMMENT_ACTION":"Bemerkungen",
    "CART_CARD_DELETE_PRODUCT":"Löschen",
    "CART_CART_ITEM_ALREADY_AVAILABLE_CONFIRMATION":"Dieses Produkt ist bereits in Ihrem Warenkorb verfügbar. Möchten Sie trotzdem fortfahren?",
    "CART_CART_ITEM_AZURE_PLAN_INELIGIBLE_WARNING":"Ihr Mandant ist nicht berechtigt, den Azure-Plan zu erwerben. Mögliche Gründe: Entweder haben Sie bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert, oder im Partner Center ist ein aktives Azure-Abonnement vorhanden, das aktualisiert werden muss. Bitte kontaktieren Sie den Support für weitere Unterstützung.",
    "CART_CONFIRM_MESSAGE_TO_CHANGE_PROVIDER_CUSTOMER":"Möchten Sie den Mieter für das ausgewählte Angebot wirklich ändern?",
    "CART_CUSTOM_END_DATE_SAVED_SUCCESS":"Benutzerdefiniertes Enddatum erfolgreich gespeichert",
    "CART_DISABLE_PROMOTION":"Deaktivieren Sie die Werbung für das Produkt.",
    "CART_ERROR_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Beim Aktualisieren des Mandanten für das ausgewählte Angebot ist ein Fehler aufgetreten. Bitte wenden Sie sich an das Support-Team",
    "CART_GENERIC_ERROR_MESSAGE":"Beim Abschließen der Bestellung ist etwas schief gelaufen. Bitte wenden Sie sich an den Partner, bevor Sie es erneut versuchen",
    "CART_ITEM_EFFECTIVE_PRICE":"Effektiver Preis",
    "CART_ITEM_LBL_TEXT_SITE_AND_DEPARTMENT":"Bestellung für Standort/Abteilung",
    "CART_ITEM_LBL_TEXT_TENANT_NAME_WITH_PROVIDER":"Mieter",
    "CART_NOT_AVAILABLE_TO_CHECKOUT_OR_ALREADY_CHECKED_OUT":"Warenkorb nicht zum Auschecken verfügbar oder bereits ausgecheckt!",
    "CART_NOT_AVAILABLE_TO_SCHEDULE_OR_ALREADY_SCHEDULED":"Sie können diesen Warenkorb nicht planen, da er entweder nicht verfügbar oder bereits geplant ist",
    "CART_PAGE_ACCORDION_TITLE_LINKED_PRODUCT":"Verknüpftes Produkt",
    "CART_PAGE_AUTORELEASE":"Automatische Freigabe",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_INFORMATION":"{{discount}} % Rabatt",
    "CART_PAGE_AZURE_PLAN_DISCOUNT_TEXT":"Rabatt",
    "CART_PAGE_BUTTON_TEXT_REMOVE":"Entfernen",
    "CART_PAGE_BUTTON_TEXT_UPDATE_QUANTITY":"Anwenden",
    "CART_PAGE_DISCOUNT_INFORMATION":"({{discount}}% reduziert)",
    "CART_PAGE_FILTER_TITLE_CONSUMPTION_TYPES":"Verbrauchsarten",
    "CART_PAGE_TEMPLATE_ADD_COMMENT":"Einen Kommentar hinzufügen",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS":"<strong> Hallo! </strong> Ihr Warenkorb ist leer. <a ng-click='vm.GotoCart()' class='alert-link'> Klicken Sie hier, um einzukaufen. <span class='fa fa-cart-plus'></span></a>",
    "CART_PAGE_TEXT_INFO_NO_PRODUCTS1":"<span class='font-weight-boldest'>Hallo!</span> Ihr Warenkorb ist leer.",
    "CART_PAGE_TITLE_PRICE":"Preis",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE":"Benutzerdefiniertes Enddatum",
    "CART_PLACEHOLDER_SELECT_CUSTOM_END_DATE_TYPE":"Wählen Sie einen benutzerdefinierten Enddatumstyp aus",
    "CART_PLACEHOLDER_SELECT_MS_SERVICE_PROVIDER_CUSTOMER":"Wählen..",
    "CART_PLACEHOLDER_SELECT_SITE_OR_DEPARTMENT":"Wählen..",
    "CART_PRODUCT_AUTO_RELEASE_UPDATE_SUCCESS_MESSAGE":"Auto-Release erfolgreich aktualisiert",
    "CART_PRODUCT_CHECKOUT_ERROR_MESSAGE":"Beim Auschecken des Warenkorbs ist ein Fehler aufgetreten",
    "CART_PRODUCT_CHECKOUT_SUCCESS_MESSAGE":"Bestellung erfolgreich aufgegeben. OrderId: {{orderId}}",
    "CART_PRODUCT_CHECKOUT_TRIAL_ERROR_MESSAGE":"Bei der Verarbeitung Ihrer Anfrage zur Umwandlung des Testangebots für {{productName}} in ein Abonnement ist ein Fehler aufgetreten",
    "CART_PRODUCT_CHECKOUT_TRIAL_SUCCESS_MESSAGE":"Die Anfrage zur Umwandlung des Testangebots für {{productName}} in ein Abonnement wurde erfolgreich gesendet",
    "CART_PRODUCT_DELETE_CONFIRMATION":"Möchten Sie diesen Artikel wirklich aus dem Warenkorb löschen?",
    "CART_PRODUCT_DELETE_ERROR_MESSAGE":"Beim Entfernen des Artikels aus dem Warenkorb ist ein Fehler aufgetreten!",
    "CART_PRODUCT_DELETE_SUCCESS_MESSAGE":"Das angeforderte Element wurde erfolgreich entfernt!",
    "CART_PRODUCT_ELIGIBILITY_CHECK_ERROR":"Beim Überprüfen der Aktionsberechtigung ist ein Fehler aufgetreten",
    "CART_PRODUCT_ELIGIBILITY_CKECK_SUCCESS":"Die verfügbare Aktions-ID ist für die Aktion gültig.",
    "CART_PRODUCT_QUANTITY_UPDATE_SUCCESS_MESSAGE":"Menge erfolgreich aktualisiert",
    "CART_PRODUCT_SCHEDULE_ERROR_MESSAGE":"Beim Planen der Bestellung ist ein Fehler aufgetreten",
    "CART_PRODUCT_SCHEDULED_SUCCESS_MESSAGE":"Die Bestellung wurde erfolgreich geplant.<br/>OrderId ist: {{orderId}}",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_DAYS":"Dieses Produkt wurde bereits vor {{days}} Tagen gekauft. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_HOURS":"Dieses Produkt wurde bereits vor {{hours}} Stunden gekauft. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION_MINUTES":"Dieses Produkt wurde bereits vor {{minutes}} Minuten gekauft. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_DAYS":"Dieses Produkt wurde bereits vor {{days}} Tagen bestellt. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_HOURS":"Dieses Produkt wurde bereits vor {{hours}} Stunden bestellt. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_ORDERED_CONFIRMATION_MINUTES":"Dieses Produkt wurde bereits vor {{minutes}} Minuten bestellt. Möchten Sie trotzdem fortfahren?",
    "CART_PURCHASED_PRODUCT_ALREADY_PURCHASE_TRAIL_OFFER":"Dieses Testangebot ist bereits gekauft.",
    "CART_PURCHASED_PRODUCT_ALREADY_SCHEDULED_CONFIRMATION":"Für dieses Produkt liegt eine geplante Bestellung vor. Möchten Sie trotzdem fortfahren?",
    "CART_SUCCESS_MESSAGE_WHILE_CHANGING_PROVIDER_CUSTOMER":"Der Mieter wurde erfolgreich gegen das ausgewählte Angebot aktualisiert!",
    "CART_UPDATE_INSTANT_PAY_FIELD_IN_CART_ERROR_MESSAGE":"Beim Aktualisieren des Sofortzahlungsfelds im Warenkorb ist ein Fehler aufgetreten",
    "CATEGORY_DESC_ALL":"Alles",
    "CATEGORY_DESC_AZURE":"Azurblau",
    "CATEGORY_DESC_AZURE_PLAN":"Azure-Plan",
    "CATEGORY_DESC_BUNDLES":"Bündel",
    "CATEGORY_DESC_CUSTOM":"BENUTZERDEFINIERT",
    "CATEGORY_DESC_CUSTOM_TRIAL":"Benutzerdefinierte Testversion",
    "CATEGORY_DESC_DISTRIBUTOR_OFFERS":"Händlerangebote",
    "CATEGORY_DESC_LICENSE_SUPPORTED":"Lizenz unterstützt",
    "CATEGORY_DESC_NCE":"NCE",
    "CATEGORY_DESC_NON_CSP_AZURE":"Nicht-CSP-Azure",
    "CATEGORY_DESC_ONE_TIME":"Einmal",
    "CATEGORY_DESC_ONLINCE_SERVICES":"Online Dienste",
    "CATEGORY_DESC_ONLINE_SERVICES_NCE":"OnlineServicesNCE",
    "CATEGORY_DESC_PERPETUAL_SOFTWARE":"Unbefristete Software",
    "CATEGORY_DESC_RESERVED_INSTANCES":"RI",
    "CATEGORY_DESC_SOFTWARE_SUBSCRIPTIONS":"Software-Abonnements",
    "CATEGORY_DESCRIPTION_LICENSE_SUPPORTED":"Lizenz unterstützt",
    "CATEGORY_DESCRIPTION_PERPETUAL_SOFTWARE":"Dauerhafte Software",
    "CATEGORY_DESCRIPTION_RESERVED_INSTANCE":"Reservierte Instanz",
    "CATEGORY_FILTER_DESC_NCE":"NCE",
    "CHANGE_COUPON_STATUS_TO_DISABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Gutschein deaktivieren?",
    "CHANGE_COUPON_STATUS_TO_ENABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Gutschein aktivieren?",
    "CHANGE_PLAN_CHANGE_LABEL_SOURCE_PLANS":"Quellenplan",
    "CHANGE_PLAN_CHANGE_LABEL_TARGET_PLANS":"Zielplan",
    "CHANGE_PLAN_CHECKBOX_TEXT_UNASSIGN_PLAN":"Heben Sie die Zuweisung von Plänen auf, nachdem Sie zu einem neuen Plan gewechselt haben",
    "CHANGE_PLAN_FAILURE_MESSAGE_TEXT":"Beim Ändern des Plans ist ein Fehler aufgetreten.",
    "CHANGE_PLAN_HELP":"Hilfe",
    "CHANGE_PLAN_INCORRECT_CHILD_PARENT_VALIDATION_MESSAGE":"{{firstProduct}} ist nicht dem richtigen untergeordneten Produkt {{secondProduct}} zugeordnet",
    "CHANGE_PLAN_INSTRUCTIONS_1":"Befolgen Sie diese Anweisungen, um Produkte des Kunden von einem bestehenden Plan in einen neu ausgewählten Plan zu verschieben.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_1":"<b>Quellplan </b> – Listet die Pläne auf, die den gekauften Produkten des Kunden zugeordnet sind. Wählen Sie einen oder mehrere Pläne aus, wenn Sie die zugehörigen Produkte verwenden möchten.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_2":"<b>Zielplan </b> – Wählen Sie den neuen Preisplan aus.",
    "CHANGE_PLAN_INSTRUCTIONS_1_SUBINSTRUCTION_3":"<b>Pläne nach dem Wechsel zu einem neuen Plan aufheben </b> – Dies ist praktisch, da das System den Kunden von einem bestehenden Plan trennen würde, wenn im Quellplan keine Produkte mehr vorhanden sind. Hilft, Verwirrung für die Kundenbenutzer auf der Shop-Seite zu beseitigen.",
    "CHANGE_PLAN_INSTRUCTIONS_2":"Die Seite wird aktualisiert, um die gekauften Produkte aus dem Quellplan auf der linken Seite und die Option zur Zuordnung zu einem Äquivalent aus dem Zielplan auf der rechten Seite aufzulisten.",
    "CHANGE_PLAN_INSTRUCTIONS_3":"Ordnen Sie die Produkte zu und senden Sie die Anforderung an das System, die Änderung auszuführen.",
    "CHANGE_PLAN_INSTRUCTIONS_4":"Audit-Protokolle helfen Ihnen mit den Details zur Aktivität, um zu einem späteren Zeitpunkt darauf zurückgreifen zu können.",
    "CHANGE_PLAN_INSTRUCTIONS_5":"<b>Hinweis: Preisänderungen aufgrund von Planänderungen treten sofort in Kraft.</b>",
    "CHANGE_PLAN_INSTRUCTIONS_HEADING":"Anweisungen zum Wechseln zum Plan",
    "CHANGE_PLAN_MISSING_CHILD_PARENT_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie auch {{secondProduct}} zuordnen",
    "CHANGE_PLAN_MISSING_SHARED_PRODUCT_VALIDATION_MESSAGE":"Die freigegebene Menge sollte für {{firstProduct}} übereinstimmen",
    "CHANGE_PLAN_PLACEHOLDER_FOR_SOURCE_PLANS":"Wählen Sie die Quellpläne aus, die Sie ändern möchten",
    "CHANGE_PLAN_PLACEHOLDER_FOR_TARGET_PLANS":"Zielplan auswählen",
    "CHANGE_PLAN_PLACEHOLDER_NO_MATCHED_PRODUCTS_IN_TARGET_PLAN":"Keine übereinstimmenden Produkte im Zielplan",
    "CHANGE_PLAN_STATUS_FAILED":"Gescheitert",
    "CHANGE_PLAN_STATUS_SUCCESS":"Erfolg",
    "CHANGE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan erfolgreich geändert.",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_MAPPED_PRODUCTS":"Wechseln Sie zu einem Produkt im Zielplan",
    "CHANGE_PLAN_TABLE_HEADER_TEXT_PURCHASED_PRODUCTS":"Gekaufte Produkte",
    "CHANGE_PLAN_TABLE_TEXT_BILLING_CYCLE":"Biiling-Zyklus",
    "CHANGE_PLAN_TABLE_TEXT_CATEGORY":"Kategorie",
    "CHANGE_PLAN_TABLE_TEXT_LINKED_PRODUCT":"Verknüpftes Produkt",
    "CHANGE_PLAN_TABLE_TEXT_OFFER":"Angebot :",
    "CHANGE_PLAN_TABLE_TEXT_PLAN_NAME":"Planen",
    "CHANGE_PLAN_TABLE_TEXT_PRICE":"Preis",
    "CHANGE_PLAN_TABLE_TEXT_PROVIDER":"Anbieter",
    "CHANGE_PLAN_TABLE_TEXT_QUANTITY":"Menge",
    "CHANGE_PLAN_TABLE_TEXT_VALIDITY":"Gültigkeit",
    "CHANGE_PLAN_VALIDATION_MESSAGE_ISINVALIDSOURCEPLANS":"Ausgewählte Pläne haben eine andere Währungskonfiguration.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_PLAN_PRODUCT_INPROVISION_STATE":"Das Planprodukt befindet sich im Bereitstellungsstatus.",
    "CHANGE_PLAN_VALIDATION_MESSAGE_SELECT_MAPPED_PRODUCT":"Bitte Produkt auswählen.",
    "CHANGE_PURCHASED_PRODUCTS_STATUS_EXCEPTION":"Beim Aktualisieren des Status des gekauften Produkts ist ein Fehler aufgetreten:",
    "CHANGE_RESELLER_PLAN_OFFER_TO_DISCONTINUED_CONFIRM":"Sind Sie sicher, möchten Sie dieses Angebot einstellen?",
    "CHANGE_RESELLER_PLAN_STATUS_TO_DISABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Plan deaktivieren?",
    "CHANGE_RESELLER_PLAN_STATUS_TO_ENABLE_CONFIRM":"Sind Sie sicher, möchten Sie diesen Plan aktivieren?",
    "Charge":"Aufladung",
    "charity":"Wohltätigkeit",
    "Charity":"Wohltätigkeit",
    "CHECKBOX_TEXT_ENABLE_ALL_PROMOTIONS":"Aktivieren Sie alle Werbeaktionen",
    "CHOOSE_A_BILLING_CYCLE_FILTER_TOOLTIP_TEXT":"Wählen Sie einen Abrechnungszeitraum",
    "CHOOSE_A_BILLING_TYPE_FILTER_TOOLTIP_TEXT":"Wählen Sie eine Abrechnungsart",
    "CHOOSE_A_CONSUMPTION_TYPE_FILTER_TOOLTIP_TEXT":"Wählen Sie eine Verbrauchsart",
    "CHOOSE_AN_OPTION":"Wähle eine Option",
    "CHOOSE_CUSTOMER_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Wählen Sie Kunde",
    "CHOOSE_INVOICE_PDF_LANGUAGE":"Wählen Sie die Sprache der Rechnungs-PDF",
    "CHOOSE_INVOICE_PDF_LANGUAGE_DESC":"Das Rechnungs-PDF wird in der ausgewählten Sprache verfügbar sein",
    "CHOOSE_ONE_RG":"Wählen Sie mindestens eine Ressourcengruppe aus",
    "CHOOSE_RESOURCE_GROUP":"Wählen Sie Richtlinie",
    "CLEAR_PARTNER_OFFER_ICON_CONFIRMATION":"Möchten Sie das Symbol für dieses Produkt wirklich löschen? Das Produkt würde das Symbol nach seiner Kategorie erhalten, wenn es hier gelöscht wird.",
    "CLICK":"Klicken",
    "CLICK_TEXT_FOR_EXTERNAL_LINK":"Klicken",
    "CLONE_CURRENCY_CAPTION_TEXT_CLONE_CURRENCY":"Währungsumrechnung klonen",
    "CLONE_CURRENCY_PLACEHOLDER_TEXT_PLEASE_SELECT_A_DATE":"Bitte wählen Sie ein Datum",
    "CLONE_PLAN_FAILURE":"Plan konnte nicht geklont werden",
    "CLONE_PLAN_SUCCESS":"Erfolgreich geklonter Plan",
    "CLOSE_CANCEL_SCHEDULED_ORDER_COMMENT_POPUP":"Nicht jetzt",
    "CLOSE_REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_POPUP":"Nicht jetzt",
    "COLLAPSE_ALL_PAYMENTS":"Zahlungen reduzieren",
    "COMMENT_AUTHOR":"Autor",
    "COMMENTS_LATEST_COMMENT":"Letzter Kommentar",
    "commercial":"Kommerziell",
    "Commercial":"Kommerziell",
    "COMMISION_REPORT_SEARCH":"Suche",
    "COMMISION_REPORTS":"Kommissionsbericht",
    "Commission Report":"Kommissionsbericht",
    "COMMISSION_EARNING_REPORT_BILLING_PERIOD_DROPDOWN_OPTION_ALL_PERIODS":"Alle Perioden",
    "COMMISSION_REPORT_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie alle Kunden aus",
    "COMMISSION_REPORT_DEPARTMENT_SEARCH_INPUT_PLACEHOLDER_TEXT":"Abteilung auswählen",
    "COMMISSION_REPORT_HEADER_TEXT":"Kommissionsbericht",
    "COMMISSION_REPORT_SEARCH_CLOSE_OPTION":"Schließen",
    "COMMISSION_REPORT_SEARCH_RESET_OPTION":"Zurücksetzen",
    "COMMISSION_REPORT_SEARCH_SUBMIT":"Suche",
    "COMMISSION_REPORT_SITE_SEARCH_INPUT_PLACEHOLDER_TEXT":"Standort auswählen",
    "COMMISSION_REPORTS":"Kommissionsbericht",
    "COMMISSION_REPORTS_TAB_NAME_COMMISSION_REPORT":"Kommissionsbericht",
    "COMMISSION_REPORTS_TAB_NAME_EARNINGS_REPORT":"Ergebnisbericht",
    "COMMISSION_TABLE_HEADER_COMMISION_END_DATE":"Enddatum der Provision",
    "COMMISSION_TABLE_HEADER_COMMISION_START_DATE":"Startdatum der Provision",
    "COMMISSION_TABLE_HEADER_COMMISSION_PERCENT":"Provision(%)",
    "COMMISSION_TABLE_HEADER_DOMAIN_NAME":"Domain Name",
    "COMMISSION_TABLE_HEADER_ENTITY_ID":"Entitäts-ID",
    "COMMISSION_TABLE_HEADER_ORDERED_DATE":"Bestelldatum",
    "COMMISSION_TABLE_HEADER_PLAN_PRODUCT_ID":"Produkt-ID planen",
    "COMMISSION_TABLE_HEADER_PRODUCT_AGENT_NAME":"Agent",
    "COMMISSION_TABLE_HEADER_PRODUCT_COMMISSION_AMOUNT":"Provisionsbetrag",
    "COMMISSION_TABLE_HEADER_PRODUCT_CUSTOMER_NAME":"Kunde",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_ID":"Produkt-ID des Anbieters",
    "COMMISSION_TABLE_HEADER_PRODUCT_PRODUCT_xID":"Produkt-ID des Anbieters",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_ID":"Produktabonnement-ID",
    "COMMISSION_TABLE_HEADER_PRODUCT_SUBSCRIPTION_NAME":"Name des Produktabonnements",
    "COMMISSION_TABLE_HEADER_PURCHASE_PRODUCT_NAME":"Produktname kaufen",
    "COMMISSION_TABLE_HEADER_RECORD_ID":"Datensatz-ID",
    "COMMISSION_TABLE_HEADER_SP_CODE":"SP-Code",
    "COMMON_LOADING_MESSAGE":"Wird geladen ...",
    "CONFIRM_ALERT_MESSAGE_APPROVE_THIS_INVOICE":"Sind Sie sicher, möchten Sie diese Rechnung genehmigen?",
    "CONFIRM_ALERT_MESSAGE_FINALIZE_THIS_INVOICE":"Sind Sie sicher, dass Sie diese Rechnung abschließen möchten?",
    "CONFIRM_ALERT_MESSAGE_UNSAVED_CHANGES":"Du hast nicht gespeicherte Änderungen. Klicken Sie auf OK, um ohne Speichern zu bleiben, oder auf Abbrechen, um auf der Seite zu bleiben",
    "CONFIRMATION_POPUP_MESSAGE":"Möchten Sie dieses Abonnement wirklich aktivieren?",
    "CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Möchten Sie die Anfrage zu diesem Abonnement wirklich stornieren?",
    "CONFIRMATION_POPUP_TITLE_ACTIVATE":"aktivieren Sie",
    "CONFIRMATION_POPUP_TITLE_CANCELLED":"Abgesagt",
    "CONFIRMATION_TEXT_AUTOMATIC_PURCHASE":"Durch Klicken auf „Angebot genehmigen“ werden Einzelposten gekauft. <br/> <b>Hinweis</b>: Das Sitzplatz-/Transaktionslimit findet keine Anwendung.",
    "CONFIRMATION_TEXT_DELETE_ADJUSTMENT":"Möchten Sie diese Anpassung löschen?",
    "CONFIRMATION_TEXT_MANUAL_PURCHASE":"<ul><li><p style='text-align:left;'>Durch Klicken auf „Genehmigen“ wird der Angebotsstatus auf „Genehmigt“ aktualisiert.</p></li><li><p style='text-align:left;'>Der Angebotsinhaber muss den Kauf manuell im Namen des Kunden tätigen.</p></li></ul>",
    "CONFIRMATION_TEXT_PRODUCTS_POPUP_UPDATE_SUBSCRIPTION_NAME":"Möchten Sie den Abonnementnamen aktualisieren?",
    "CONFIRMATION_TEXT_UPDATE_PO_NUMBER":"Möchten Sie die Bestellnummer wirklich aktualisieren?",
    "CONFIRMATION_TEXT_UPDATE_PRODUCT_NAME":"Möchten Sie den Produktnamen wirklich aktualisieren?",
    "CONFIRMATION_TEXT_UPDATE_SITE_DEPARTMENT_NAME":"Möchten Sie den Abonnementnamen aktualisieren, wenn er von untergeordneten Entitäten in C3 geteilt wird?",
    "CONFIRMATION_TEXT_WANT_TO_CANCEL":"Bist du sicher, dass du abbrechen möchtest?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE":"Wollen Sie fortfahren?",
    "CONFIRMATION_TEXT_WANT_TO_CONTINUE_WITH_CURRENT_SELECTION":"Möchten Sie mit der aktuellen Auswahl fortfahren?",
    "CONSENT_CREATION_FAILED":"Einwilligungserstellung fehlgeschlagen",
    "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DESC":"Aktivieren Sie diese Option, um Bearbeitungsgebühren auf Kreditkartenzahlungen anzuwenden.",
    "CONSIDER_CREDIT_CARD_PROCESSING_FEE_DISP":"Kreditkartenbearbeitungsgebühr einschließen?",
    "CONSTANT_EMPTY_STRING":"--Auswählen--",
    "CONSTANT_EVERY_DAY":"Jeden Tag",
    "CONSTANT_EVERY_MONTH":"Jeden Monat",
    "CONSTANT_EVERY_TWO_WEEKS":"Alle zwei Wochen",
    "CONSTANT_EVERY_WEEK":"Jede Woche",
    "CONSTANT_FULL_AUTOMATIC_MAPPING":"Automatische Zuordnung",
    "CONSTANT_FULL_MANUAL_MAPPING":"Manuelle Zuordnung",
    "CONSTANT_MANUAL_MAPPING_WITH_SERVICE_CREATION":"Manuelles Mapping mit Serviceerstellung",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_AMOUNT":"Menge",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_PRODUCT":"Produkt",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_QUANTITY":"Menge",
    "CONSUMPTION_BY_PRODUCT_GRAPH_AXIS_LABLE_SEATS":"Sitz",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_CLOSING_SEATS":"Sitze schließen",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_DIFFERENCE_SEATS":"Unterschied",
    "CONSUMPTION_BY_PRODUCT_TOOLTIP_OPENING_SEATS":"Sitze öffnen",
    "CONSUMPTION_TYPES_DESC_CONTRACT":"Vertrag",
    "CONSUMPTION_TYPES_DESC_QUANTITY":"Menge",
    "CONSUMPTION_TYPES_DESC_SERVICE":"Service",
    "CONSUMPTION_TYPES_DESC_USAGE":"Verwendung",
    "CONTACT_CANCEL":"Möchten Sie stornieren?",
    "CONTACT_HEADER_TEXT":"Eine Frage haben?",
    "CONTACT_HEADER_TEXT_OUR_CONTACTS":"Unsere Kontakte",
    "CONTACT_LABEL_TEXT_EMAIL":"Email",
    "CONTACT_LABEL_TEXT_MESSAGE":"Botschaft",
    "CONTACT_LABEL_TEXT_NAME":"Name",
    "CONTACT_PARAGRAPH_TEXT":"Kontaktieren Sie uns, indem Sie das folgende Formular ausfüllen. Wir werden uns in Kürze bei Ihnen melden.",
    "CONTACT_SENDFEEDBACK_SUCCESS":"Vielen Dank für Ihr Feedback. Unser Team wird sich in Kürze mit Ihnen in Verbindung setzen.",
    "CONTACTCONTROLLER_SENDFEEDBACK":"Ihr Feedback wurde erfolgreich übermittelt",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_ADD":"Hinzufügen",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_DELETE":"Löschen",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "CONTRACT_DETAILS_TOOLTIP_TEXT_SAVE":"speichern",
    "CONTRACT_OFFER_BILLING_TYPE_PLACEHOLDER":"Wählen Sie eine Abrechnungsart aus",
    "CONTRACT_OFFER_BREADCRUMB":"Vertragsangebot",
    "CONTRACT_OFFER_FEEDSOURCE_TOOLTIP":"Quelle, auf der der Vertrag erstellt würde",
    "CONTRACT_OFFER_POPUP_DELETE_SLAB_CONFIRMATION_TEXT":"Möchten Sie diese Platte wirklich löschen?",
    "CONTRACT_OFFER_SLABDATA_TOOLTIP":"Nutzungsbereich, für den der Vertrag gelten würde",
    "CONTRACT_PRODUCT_MANAGE_DEACTIVATE_POPUP_CONFIRMATION_TEXT":"Möchten Sie dieses Vertragsangebot wirklich deaktivieren?",
    "CONTRACT_SERVICE_DELETED_FROM_AUTOTASK":"Vertragsdienst aus Autotask gelöscht",
    "COOKIE_CONSENT_MESSAGE":"Die Anwendung verwendet Cookies, um Ihnen eine bessere Erfahrung zu bieten. Durch den Zugriff auf die Anwendung stimmen Sie der Verwendung von Cookies zu.",
    "COOKIE_POLICY_BUTTON_TEXT":"COOKIE-POLITIK",
    "corporate":"Unternehmen",
    "Corporate":"Unternehmen",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_RESET_OPTION":"Zurücksetzen",
    "COST_SUMMARY_REPORT_BUTTON_TEXT_SEARCH_OPTION":"Suche",
    "COST_SUMMARY_REPORT_FORM_LABEL_CUSTOMERS":"Kunden",
    "COST_SUMMARY_REPORT_FORM_LABEL_PLANS":"Pläne",
    "COST_SUMMARY_REPORT_FORM_LABEL_RESELLERS":"Wiederverkäufer",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_CUSTOMER":"Wählen Sie Kunden",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_PLAN":"Wählen Sie Pläne",
    "COST_SUMMARY_REPORT_FORM_PLACEHOLDER_SELECT_RESELLERS":"Wählen Sie Reseller",
    "COST_SUMMARY_REPORT_SEARCH_FILTER":"Filter",
    "COST_SUMMARY_REPORT_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PLAN_NAME":"Plan Name",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "COST_SUMMARY_REPORT_TABLE_HEADER_PROVIDER_PRICE":"Anbieterpreis",
    "COST_SUMMARY_REPORT_TABLE_HEADER_QUANTITY":"Menge",
    "COST_SUMMARY_REPORT_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_CUSTOMER":"Kosten für den Kunden",
    "COST_SUMMARY_REPORT_TABLE_HEADER_TOTAL_COST_TO_PARTNER":"Kosten für den Partner",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_ADD_ON":"Erweiterung",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_BUNDLE":"Bündeln",
    "COST_SUMMARY_REPORT_TABLE_SUBSCRIPT_LINKED_PRODUCT":"Verknüpft",
    "COST_SUMMARY_REPORT_TEXT_COST_SUMMARY_REPORT":"Kostenübersicht",
    "COTERMINOUSITY_NOT_ONBOARDED_TEXT_MESSGAE":"Nicht an Bord",
    "coupon":"Coupon",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_SELECTED_WARNING_MESSAGE":"Der Gutschein wird auf alle Kunden angewendet",
    "COUPON_APPLY_TO_ALL_TAGGED_CUSTOMERS_UNSELECTED_WARNING_MESSAGE":"Der Gutschein wird nicht auf Kunden angewendet, obwohl sie mit dem ausgewählten Plan gekennzeichnet sind",
    "COUPON_ASSIGNMENT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_1":"Der ausgewählte Coupon ist ein direkter Coupon (ohne Plan).",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_2":"Kunden können jeweils nur einen direkten Coupon haben.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_3":"Bitte beachten Sie, dass ausgewählte Kunden mit bestehenden Gutscheinzuweisungen entfernt werden.",
    "COUPON_ASSIGNMENT_DIRECT_COUPON_INSTRUCTIONS_HEADING":"Direkter Gutschein",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_1":"Der ausgewählte Coupon ist ein Plan-Coupon.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_2":"Kunden, die mit diesem Plan gekennzeichnet sind, können nur mit diesem Coupon zugewiesen werden.",
    "COUPON_ASSIGNMENT_PLAN_COUPON_INSTRUCTIONS_HEADING":"Gutschein planen",
    "COUPON_ASSIGNMENT_SAVE_COUPON_ASSIGNMENT_SUCCESS":"Couponzuordnung erfolgreich gespeichert",
    "COUPON_ASSIGNMENT_UNDER_EDIT_COUPON":"Coupon",
    "COUPON_ASSIGNMENT_UNDER_EDIT_CUSTOMER":"Kunden",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_ACTIVE":"Aktiv",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_ASSIGNMENT_IS_INACTIVE":"Inaktiv",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_ACTIVE":"Aktiv",
    "COUPON_ASSIGNMENTS_BADGE_COUPON_IS_INACTIVE":"Inaktiv",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_ADD_NEW_COUPON_ASSIGNMENT":"Neue Couponzuordnung hinzufügen",
    "COUPON_ASSIGNMENTS_BUTTON_LABEL_EDIT_COUPON_ASSIGNMENT":"Bearbeiten",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTION":"Aktion",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ASSIGNED_ON":"Zugewiesen am",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_APPLICABILITY":"Gilt bis",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_ASSIGNMENT_STATUS":"Status",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_code":"Gutscheincode",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_CODE":"Gutscheincode",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_DESCRIPTION":"Coupon Beschreibung",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_NAME":"Coupon Name",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_coupon_name":"Gutscheinname",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_COUPON_STATUS":"Status",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "COUPON_ASSIGNMENTS_TABLE_HEADER_TEXT_ISACTIVE":"Ist aktiv",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON":"Bitte wählen Sie einen gültigen Gutschein aus",
    "COUPON_ASSINGMENT_SELECT_VALID_COUPON_SUCCESS":"Der ausgewählte Gutschein steht diesem Kunden zur Verfügung.",
    "COUPON_BUTTON_LABEL_ADD_NEW_COUPON":"Gutschein hinzufügen",
    "COUPON_BUTTON_TEXT_BACK_TO_COUPON":"Zurück zu den Gutscheinen",
    "COUPON_CAPTION_TEXT_ADD":"Gutschein hinzufügen",
    "COUPON_CAPTION_TEXT_EDIT":"Gutschein bearbeiten",
    "COUPON_CHECKBOX_TEXT_SELECT_ALL_OFFERS":"Wählen Sie alle Angebote aus",
    "COUPON_DETAILS_TOOLTIP_COLLAPSE":"Zusammenbruch",
    "COUPON_DETAILS_TOOLTIP_EXPAND":"Erweitern",
    "COUPON_DISABLE_BUTTON":"Deaktivieren Sie den Gutschein",
    "COUPON_DISCOUNT_TOOLTIP":"Rabattprozentsatz, der auf den Coupon angewendet werden soll",
    "COUPON_DISCOUNT_VALIDATION_MESSAGE":"Bitte wählen Sie einen Rabattwert zwischen 1 und 100.",
    "COUPON_EDIT_BUTTON":"Gutschein bearbeiten",
    "COUPON_ENABLE_BUTTON":"Aktivieren Sie den Gutschein",
    "COUPON_HEADER_ACTION":"Aktion",
    "COUPON_OWNERSHIPS_CATEGORY":"Kategorie",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS":"Bewerben Sie sich für alle markierten Kunden",
    "COUPON_OWNERSHIPS_CHECKBOX_APPLICABLE_TO_ALL_CUSTOMERS_HELPTEXT":"Wenn Sie dieses Kontrollkästchen aktivieren, wird der Gutschein allen Kunden zugewiesen, die mit dem ausgewählten Plan gekennzeichnet sind. Sie können die Zuweisungen jedoch im Coupon-Zuweisungsbildschirm verwalten.",
    "COUPON_OWNERSHIPS_COUPON_ENTITY":"Anwendbarkeit",
    "COUPON_OWNERSHIPS_COUPON_PLAN_OFFER_TEXT_PROMOTION_AVAILABLE_TO_CUSTOMER":"Aktion verfügbar",
    "COUPON_OWNERSHIPS_PLAN":"Planen",
    "COUPON_OWNERSHIPS_PLAN_OFFERS":"Angebote planen",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_EFFECTIVE_PRICE":"Effektiver Preis",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_ISSELECTED":"Ist ausgewählt",
    "COUPON_OWNERSHIPS_PLAN_OFFERS_NAME":"Angebotsname",
    "COUPON_OWNERSHIPS_PROVIDER":"Anbieter",
    "COUPON_OWNERSHIPS_SALETYPE":"SaleType",
    "COUPON_PLAN_COUPON_OWNERSHIPS":"Anwendbar auf",
    "COUPON_PLAN_PRODUCTS_VALIDATION_MESSAGE":"Sie sollten mindestens ein Planprodukt auswählen.",
    "COUPON_RECORDS_VALIDATION_MESSAGE":"Sie sollten mindestens eine {{entity}} auswählen.",
    "COUPON_SAVE_SUCCESS":"Gutschein {{coupon}} erfolgreich gespeichert",
    "COUPON_STATUS_FLAG_ACTIVE":"Aktiv",
    "COUPON_STATUS_FLAG_EXPIRED":"Abgelaufen",
    "COUPON_STATUS_FLAG_INACTIVE":"Inaktiv",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_CODE":"Gutscheincode",
    "COUPON_STATUS_TABLE_HEADER_TEXT_COUPON_NAME":"Coupon Name",
    "COUPON_STATUS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "COUPON_STATUS_TABLE_HEADER_TEXT_EXPIRE_ON":"Endet am",
    "COUPON_STATUS_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Name",
    "COUPON_TABLE_HEADER_TEXT_APPLYTONEWCUSTOMERS":"Anwendbar auf neue Kunden",
    "COUPON_TABLE_HEADER_TEXT_APPLYTOOLDCUSTOMERS":"Anwendbar auf alte Kunden",
    "COUPON_TABLE_HEADER_TEXT_code":"Code",
    "COUPON_TABLE_HEADER_TEXT_CODE":"Code",
    "COUPON_TABLE_HEADER_TEXT_CREATED_ON":"Erstellt am",
    "COUPON_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "COUPON_TABLE_HEADER_TEXT_DISCOUNT":"Rabatt",
    "COUPON_TABLE_HEADER_TEXT_EXPIRESON":"Läuft aus am",
    "COUPON_TABLE_HEADER_TEXT_ISACTIVE":"Ist aktiv",
    "COUPON_TABLE_HEADER_TEXT_ISPERCENTAGE":"Ist Prozentsatz",
    "COUPON_TABLE_HEADER_TEXT_ISPUBLIC":"Ist öffentlich",
    "COUPON_TABLE_HEADER_TEXT_MAXDISCOUNT":"Maximaler Rabatt",
    "COUPON_TABLE_HEADER_TEXT_MAXREDEMPTIONS":"Max. Einlösungen",
    "COUPON_TABLE_HEADER_TEXT_MINAMOUNT":"Mindestbetrag",
    "COUPON_TABLE_HEADER_TEXT_NAME":"Name",
    "COUPON_TABLE_HEADER_TEXT_NOOFRECURRENCES":"Wiederholungen",
    "COUPON_TABLE_HEADER_TEXT_PLANNAME":"Plan Name",
    "COUPON_TABLE_HEADER_TEXT_planname":"Planname",
    "COUPON_TABLE_HEADER_TEXT_VALIDTILL":"Gültig bis",
    "COUPON_UPDATED_SUCCESS":"Gutschein {{coupon}} erfolgreich aktualisiert",
    "COUPON_VALIDTILL_AND_EXPIRESON_DATE_VALIDATION_MESSAGE":"Das ValidTill-Datum sollte größer als das ExpiresOn-Datum sein",
    "couponassignment":"Gutscheinzuordnung",
    "coupondetails":"Gutscheindetails",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_ADD_COUPON_ASSIGNMENT":"Neue Couponzuordnung hinzufügen",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_COUPONS_ASSIGNMENT":"Couponzuweisungen",
    "COUPONS_ASSIGNMENT_TAB_HEADING_TEXT_EDIT_COUPON_ASSIGNMENT":"Couponzuordnung bearbeiten",
    "COUPONS_EXPIRES_ON_TOOLTIP_TEXT":"Datum, bis zu dem der Gutschein zum Zeitpunkt des Kaufs angewendet werden kann",
    "COUPONS_STATUS_TAB_HEADING_TEXT_COUPONS_STATUS":"Gutscheinstatus",
    "COUPONS_TAB_HEADING_TEXT_COUPONS":"Gutscheine",
    "COUPONS_VALID_TILL_TOOLTIP_TEXT":"Datum, bis zu dem der Gutschein bei der Abrechnung berücksichtigt wird, nachdem er angewendet wurde",
    "couponstatus":"Gutscheinstatus",
    "CPV_CLIPBOARD_SUCCESS_MESSAGE":"Der Link zum Initiieren der Einwilligungserfahrung wird in Ihre Zwischenablage kopiert",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_1":"Klicken Sie auf <b><a ngclipboard data-clipboard-text='{{consentURL}}'ng-click='confirmCopy()'></b>Link in Zwischenablage kopieren </a>, um den Link zu kopieren, der die Zustimmung erfasst vom Partner, um dieser Anwendung nach dem neuen Sicherheitsmodell Zugriff auf das Partner Center zu gewähren.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_2":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Einwilligungserfahrung mit einem Konto ab, das ein globaler Administrator in Ihrem (Partner-) Mandanten ist und dem die Partner-Agent-Rolle im Partnercenter zugewiesen ist.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_3":"Klicken Sie auf die Schaltfläche Test, um zu überprüfen, ob die Anwendung mit dem neuen Sicherheitsmodell funktioniert.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf <b>Zu neuem Sicherheitsmodell wechseln </b>(aktiviert nach erfolgreichem Test), damit die Anwendung Ihre älteren Anmeldeinformationen löscht und zum neuen Sicherheitsmodell wechselt.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_5":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_1":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'><b>Link in die Zwischenablage kopieren< /b></a></b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_2":" Um dieser Anwendung Zugriff auf die Azure-Verwaltungs-APIs zu gewähren, wird über diesen Link Ihre Zustimmung als Kundenadministrator erfasst.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_3":"Nachdem Sie den Link kopiert haben, öffnen Sie ihn in einer neuen Inkognito-Sitzung. Fahren Sie dann mit der Einwilligung für die Kundenadministratorrolle fort. Dieser Schritt ist für uns von entscheidender Bedeutung, um den Prozess voranzutreiben.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_CUSTOMER_4":"Klicken Sie auf die Schaltfläche „Testen“, um zu prüfen, ob die Anwendung mit den Azure-Verwaltungs-APIs funktioniert",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Anweisungen zum Umschalten auf ein neues Sicherheitsmodell",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_CUSTOMER":"Anweisung zur Erteilung der Administratoreinwilligung für die Azure Management API",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_PARTNER":"Anweisung, die Zustimmung des Kundenadministrators durch den Kundenbenutzer zu erteilen",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_HEADING_SCHEDULED_REPORTS":"Anleitung",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_1":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'>Link in die Zwischenablage kopieren</a> </b>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_2":" um den Link zu kopieren, der die Zustimmung des Kundenadministrators erfasst, dieser Anwendung Zugriff auf Azure-Verwaltungs-APIs zu gewähren.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_3":"Sobald Sie den Link kopiert haben, teilen Sie ihn dem Kunden über seinen bevorzugten Kommunikationskanal mit, beispielsweise per E-Mail. Fordern Sie dann ihre Genehmigung für die Rolle des Kundenadministrators an.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_4":"Klicken Sie auf die Schaltfläche „Testen“, um zu prüfen, ob die Anwendung mit den Azure-Verwaltungs-APIs funktioniert.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_5":"ODER Sie können Ihren Kunden auffordern, sich beim C3-Portal anzumelden -> Profil -> Microsoft. Geben Sie im Abschnitt „Erteilen Sie die erforderliche Einwilligung“ ein. Die Einholung der Zustimmung des Kunden ist für unser weiteres Vorgehen von entscheidender Bedeutung.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_PARTNER_6":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_1":"Hier ist eine Liste der Berichte, die zum geplanten Zeitpunkt vorbereitet und ausgeführt werden können.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_2":"Bitte verwenden Sie das Empfängersymbol, um die Empfänger zum Bericht hinzuzufügen.",
    "CPV_CONSENT_VIEW_INSTRUCTIONS_SCHEDULED_REPORTS_3":"Sie können auch überprüfen, wie oft der Bericht in der Vergangenheit ausgeführt wurde und wer ihn erhalten hat.",
    "CPV_LOADING_MESSAGE":"Bitte warten Sie, während wir Ihre Einwilligung bearbeiten...",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Vielen Dank für Ihre Einwilligung. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf das Partner Center zugreift.",
    "CPV_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen unter Einstellungen -> Partnercenter in <b>{{applicationName}} </b>.",
    "CPV_PC_TEST_CONFIRMATION_MESSAGE":"Der Versuch, mit den neuen Sicherheitseinstellungen auf das Partner Center zuzugreifen, ist erfolgreich.",
    "CPV_PC_TEST_CUSTOMER_CONSENT_MESSAGE_SUCCESS":"Der Versuch, auf die Microsoft Azure-Verwaltungs-API zuzugreifen, war erfolgreich.",
    "CREADITCARD_FORM_LABEL_CARD_NUMBER":"Kartennummer",
    "CREADITCARD_FORM_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
    "CREADITCARD_FORM_LABEL_TEXT_CVV":"CVV",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH":"Monat",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_APR":"Thursday, April 4, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_AUG":"Thursday, August 8, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_DEC":"Thursday, December 12, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_FEB":"Friday, February 2, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JAN":"Monday, January 1, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUL":"Sunday, July 7, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_JUN":"Thursday, June 6, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAR":"Sunday, March 3, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_MAY":"Sunday, May 5, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_NOV":"Monday, November 11, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_OCT":"10-Okt",
    "CREADITCARD_FORM_LABEL_TEXT_MONTH_VALUE_SEP":"Monday, September 9, 2024",
    "CREADITCARD_FORM_LABEL_TEXT_NAME_OF_CARD":"Name auf der Karte",
    "CREADITCARD_FORM_LABEL_TEXT_YEAR":"Jahr",
    "CREATE_BANNER_NOTIFICATIONS":"Bannerbenachrichtigung erstellen",
    "CREATE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht erstellt werden",
    "CREATE_COUPON_ASSIGNMENT_SUCCESS":"Gutscheinzuweisung erfolgreich erstellt",
    "CREATE_CUSTOMER_EXCEPTION":"Beim Erstellen des Kunden ist ein Fehler aufgetreten",
    "CREATE_CUSTOMER_USER_ROLE":"Erstellen Sie Kundenbenutzer",
    "CREATE_CUSTOMER_USER_ROLE_FAILURE":"Kundenbenutzer konnten nicht erstellt werden",
    "CREATE_INVOICE_ON_DEMAND_HEADER_TEXT":"Rechnung erstellen",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER":"Provider-Kunde unter Logischer Reseller anlegen",
    "CREATE_PROVIDER_CUSTOMER_UNDER_LOGICAL_RESELLER_DESC":"Erlauben Sie dem logischen Reseller, einen Provider-Kunden zu erstellen",
    "CREATE_RESELLER_PLAN_WITH_ALL_OFFERS_AND_CLONE_RESELLER_PLAN_USAGE_OFFERS_NOTICE":"Die dem Reseller-Tarif hinzugefügten Nutzungsangebote haben den Abrechnungstyp <b>Markup</b>. Bitte definieren Sie das Markup für Nutzungsangebote, sobald der Plan erfolgreich erstellt wurde.",
    "CREATED_ACCOUNT_MANAGER_SUCCESSFULLY":"Account Manager erfolgreich erstellt",
    "CREATED_SUCCESSFULLY":"wird erfolgreich erstellt",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_MESSAGE":"Anscheinend fehlen die MPN-ID-Details für den Reseller. Bitte wenden Sie sich an den Partner, um die Details zu aktualisieren.",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_IS_NOT_ALLOWED_FOR_RESELLER_WITH_NO_LINK_TITLE":"Das Anlegen eines neuen Service Provider Tenants wird nicht unterstützt !",
    "CREATING_NEW_MICROSOFT_CSP_TENANT_UNDER_LOGICAL_RESELLER":"Der Kunde wird im Partnercenter unter dem Partnerkonto angelegt",
    "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DESC":"Prozentuale Gebühr für die Kreditkartenabwicklung.",
    "CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_DISP":"Kreditkartenbearbeitungsgebühr %",
    "CUMULATIVE_TAXES_NOT_BOND_EXCEPTION":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für die kumulierten Steuern ein.",
    "CURRENCY_CONVERSION_ADD_SUCCESS":"Währungsumrechnungsregel erfolgreich hinzugefügt",
    "CURRENCY_CONVERSION_BUTTON_TEXT_CLONE_CONVERSION":"Währung klonen",
    "CURRENCY_CONVERSION_CAPTION_BUTTON_TEXT_BACK_TO_CURRENCY_CONVERSION":"Zurück zur Währungsumrechnung",
    "CURRENCY_CONVERSION_CAPTION_TEXT_ADD":"Hinzufügen",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION":"Währungsumrechnung",
    "CURRENCY_CONVERSION_CAPTION_TEXT_CURRENCY_CONVERSION_SUCCESS_MSG":"Währungsumrechnung erfolgreich übermittelt",
    "CURRENCY_CONVERSION_CAPTION_TEXT_EDIT":"Bearbeiten",
    "CURRENCY_CONVERSION_DELETE_SUCCESS":"Währungsumrechnungsregel erfolgreich gelöscht",
    "CURRENCY_CONVERSION_LABEL_CURRENT_BILLING_MONTH":"Aktueller Abrechnungsmonat",
    "CURRENCY_CONVERSION_LABEL_DEFAULT":"Alles",
    "CURRENCY_CONVERSION_LABEL_LAST_BILLING_MONTH":"Letzter Abrechnungsmonat",
    "CURRENCY_CONVERSION_LABEL_NEXT_BILLING_MONTH":"Nächster Abrechnungsmonat",
    "CURRENCY_CONVERSION_LABEL_PICK_A_DATE":"Wählen Sie ein Datum aus",
    "CURRENCY_CONVERSION_LABEL_TEXT_CONVERSION_RATE":"Wechselkurs",
    "CURRENCY_CONVERSION_LABEL_TEXT_CUSTOMER":"Kunde",
    "CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Quellwährung",
    "CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Zielwährung",
    "CURRENCY_CONVERSION_LABEL_TEXT_TIME_PERIOD":"Zeitraum",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CONVERSION_RATE":"Wechselkurs",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_CUSTOMER":"Kunde",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_EFFECTIVE_FROM":"Gültig ab",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_SOURCE_CURRENCY":"Quellwährung",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_STATUS":"Status",
    "CURRENCY_CONVERSION_LIST_TABLE_HEADER_TARGET_CURRENCY":"Zielwährung",
    "CURRENCY_CONVERSION_RATE_SUCCESS":"Geben Sie einen gültigen Umrechnungskurs ein.",
    "CURRENCY_CONVERSION_RULE_CREATED_SUCCESSFULLY":"Die Währungsumrechnungsregel wurde erfolgreich erstellt.",
    "CURRENCY_CONVERSION_UPDATE_SUCCESS":"Währungsumrechnungsregel erfolgreich aktualisiert",
    "CurrencyCode":"Währungscode",
    "CURRENT_DISTRIBUTOR_PRODUCT_ICON":"Aktuelles Symbol",
    "CURRENT_PARTNER_PRODUCT_ICON":"Aktuelles Symbol",
    "CURRENT_SUBSCRIPTION_DETAILS":"Aktuelle Abonnementdetails",
    "CUSOMTERS_EXPORT_USAGE_DETAILS_ERROR":"Verwendungsdetails noch nicht verfügbar, bitte versuchen Sie es nach einiger Zeit.",
    "CUSROMER_PRODUCTS_LIST_TITLE_QUANTITY":"Menge",
    "Custom Notifications":"Benutzerdefinierte Benachrichtigungen",
    "CUSTOM_END_DATE_INFO_FOR_SCHEDULE_ORDER":"Wenn Sie eine Bestellung für ein zukünftiges Datum planen und ein benutzerdefiniertes Enddatum angegeben haben, beachten Sie bitte, dass das benutzerdefinierte Enddatum automatisch auf die Standardeinstellung zurückgesetzt wird, nachdem Sie Ihre geplante Bestellung für die Zukunft ausgewählt haben.",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOM_END_DATE_POPUP_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOM_ICON_HELP_TEXT":"Empfohlene Größe: 150 x 175. Es werden nur Dateien des Typs jpg, svg, png, gif, bmp unterstützt",
    "CUSTOM_LINE_ITEM_MAXLENGTH_ERROR_MESSAGE":"Der Positionsname sollte weniger als 150 Zeichen lang sein.",
    "CUSTOM_NOTIFICATION_ADD_TAGGED_ENTITIES_BUTTON_TEXT":"Markierte Entitäten hinzufügen",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_BODY_TEXT":"Nachrichtentext",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_FROM":"Gültig ab",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_EFFECTIVE_TO":"Wirksam für",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_NOTIFICATION_MESSAGE_ID":"Benachrichtigungs-ID",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TEMPLATE":"Schablone",
    "CUSTOM_NOTIFICATION_AUDIT_LOG_TITLE":"Titel",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT10":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Marketing &amp; Verkaufsprodukt Vertrauen.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT7":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Beginnen Sie mit einem modernen Website-Design und passen Sie es mit Ihren Branding-Inhalten und Funktionen an. Alle Premium-Blogs enthalten benutzerdefiniertes CSS.",
    "CUSTOM_NOTIFICATION_BODY_EXAMPLE_TEXT9":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "CUSTOM_NOTIFICATION_BREADCRUMB_ADD":"Erstellen Sie eine benutzerdefinierte Benachrichtigung",
    "CUSTOM_NOTIFICATION_BUTTON_ACTIONS_ADD":"Erstellen Sie eine benutzerdefinierte Benachrichtigung",
    "CUSTOM_NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "CUSTOM_NOTIFICATION_CANCEL_NOTIFICATION":"Stornieren",
    "CUSTOM_NOTIFICATION_CLOSE_OPTION":"Filter schließen",
    "CUSTOM_NOTIFICATION_CUSTOMER_PLAN":"Plan Name",
    "CUSTOM_NOTIFICATION_DESCRIPTION_TOOLTIP":"Beschreibung eingeben",
    "CUSTOM_NOTIFICATION_DROPDOWN_DEFAULT_OPTION":"Wählen Sie Pläne",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Wählen Sie das Datum „Gültig ab“ aus",
    "CUSTOM_NOTIFICATION_EFFECTIVE_DATE_TO_TOOLTIP":"Wählen Sie „Gültig bis heute“.",
    "CUSTOM_NOTIFICATION_END_DATE_PLACEHOLER":"Endtermin",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ASSIGN_USER_LICENSE":"Benutzerlizenz zuweisen",
    "CUSTOM_NOTIFICATION_EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Ändern Sie den Produktstatus des Kunden",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_PRODUCT_OWNERSHIP":"Produktbesitz verwalten",
    "CUSTOM_NOTIFICATION_EVENT_DESC_MANAGE_USER_LICENSE":"Benutzerlizenz verwalten",
    "CUSTOM_NOTIFICATION_EVENT_DESC_ONBOARD_CUSTOMER":"Kunden an Bord",
    "CUSTOM_NOTIFICATION_EVENT_DESC_PURCHASE_PRODUCT":"Produkte kaufen",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_DECREASE":"Mengenabnahme",
    "CUSTOM_NOTIFICATION_EVENT_DESC_QUANTITY_INCREASE":"Mengenerhöhung",
    "CUSTOM_NOTIFICATION_EVENT_DESC_REMOVE_USER_LICENSE":"Benutzerlizenz entfernen",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_LICENSE":"Lizenz aktualisieren",
    "CUSTOM_NOTIFICATION_EVENT_DESC_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
    "CUSTOM_NOTIFICATION_FOR_DISPLAY_EXCEPTION":"Bei benutzerdefinierten Benachrichtigungsereignissen ist ein Fehler aufgetreten",
    "CUSTOM_NOTIFICATION_HEADER_ACTIONS":"Aktionen",
    "CUSTOM_NOTIFICATION_HEADER_DESCRIPTION":"Beschreibung",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_FROM":"Gültig ab",
    "CUSTOM_NOTIFICATION_HEADER_EFFECTIVE_TO":"Wirksam für",
    "CUSTOM_NOTIFICATION_HEADER_TAGGED_ENTITIES":"Getaggte Entitäten",
    "CUSTOM_NOTIFICATION_HEADER_TITLE":"Titel",
    "CUSTOM_NOTIFICATION_LABEL_DESCRIPTION":"Beschreibung",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_FROM":"Gültig ab",
    "CUSTOM_NOTIFICATION_LABEL_EFFECTIVE_TO":"Wirksam für",
    "CUSTOM_NOTIFICATION_LABEL_ENTITY":"Einheit",
    "CUSTOM_NOTIFICATION_LABEL_EVENT":"Veranstaltung",
    "CUSTOM_NOTIFICATION_LABEL_TEMPLATE":"Schablone",
    "CUSTOM_NOTIFICATION_LABEL_TITLE_TEXT":"Titel",
    "CUSTOM_NOTIFICATION_POPUP_CLOSE_BUTTON_TEXT":"Schließen",
    "CUSTOM_NOTIFICATION_POPUP_HEADER_TEXT":"Benutzerdefinierte Benachrichtigung",
    "CUSTOM_NOTIFICATION_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
    "CUSTOM_NOTIFICATION_RESET_OPTION":"Zurücksetzen",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION":"einreichen",
    "CUSTOM_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Benutzerdefinierte Benachrichtigung erfolgreich gespeichert",
    "CUSTOM_NOTIFICATION_SEARCH_DEFAULT_OPTION":"Wählen",
    "CUSTOM_NOTIFICATION_SEARCH_OPTION":"Suche",
    "CUSTOM_NOTIFICATION_SELECT":"Wählen",
    "CUSTOM_NOTIFICATION_SELECT_PRODUCTS_TOOLTIP":"Produkte auswählen",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_CONSUMPTION_TYPES":"Verbrauchsarten",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_PROVIDERS":"Anbieter",
    "CUSTOM_NOTIFICATION_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
    "CUSTOM_NOTIFICATION_START_DATE_PLACEHOLER":"Anfangsdatum",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_CARD_TITLE":"Getaggte Entitäten",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Mit Tags versehene Entitätselemente",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_HEADER_ACTIONS":"Aktionen",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ELEMENTS":"Elemente",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_ENTITY":"Einheit",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITIES_TABLE_HEADER_EVENT":"Veranstaltung",
    "CUSTOM_NOTIFICATION_TAGGED_ENTITY_TOOLTIP":"Markierte Entitäten anzeigen",
    "CUSTOM_NOTIFICATION_TEMPLATE_TOOLTIP":"Vorlage wählen",
    "CUSTOM_NOTIFICATION_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Dies überschreitet die maximale Länge von 30",
    "CUSTOM_NOTIFICATION_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_1":"Fang hier an",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_2":"Melden Sie sich jetzt bei SAP an und erhalten Sie 35 % Rabatt",
    "CUSTOM_NOTIFICATION_TITLE_EXAMPLE_TEXT_3":"Benutzervertrauen",
    "CUSTOM_NOTIFICATION_TITLE_TOOLTIP":"Titel auswählen",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION":"Aktualisieren",
    "CUSTOM_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Benutzerdefinierte Benachrichtigung erfolgreich aktualisiert",
    "CUSTOM_NOTIFICATIONS_TAB_HEADING_TEXT_UI_NOTIFICATIONS":"UI-Benachrichtigung",
    "CUSTOM_OFFER_LABEL_TEXT_IS_THIS_A_TRIAL_OFFER":"Ist das Probeangebot?",
    "CUSTOM_OFFER_TEXT_IS_RENEWAL_LABEL_TEXT":"Ist automatisch erneuerbar",
    "CUSTOM_OFFER_TEXT_PRODUCT_FOR_TRAIL_OFFER_QUANTITY":"Standardmenge",
    "CUSTOM_OFFER_TEXT_PRODUCTS_FOR_TRIAL_OFFERS":"Produkte zum Testen anbieten",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOM_OFFER_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CATEGORY":"Kategorie",
    "CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE":"Verbrauchsart",
    "CUSTOM_OFFER_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_CATAGORY":"Kategorie",
    "CUSTOM_OFFERS_ADD_EDIT_FORM_LABEL_PROVIDER":"Anbieter",
    "CUSTOM_OFFERS_BREADCRUMB_BUTTON_TEXT_CUSTOM_OFFERS":"Partnerangebote",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CONTRACT_OFFER":"Neues Vertragsangebot",
    "CUSTOM_OFFERS_BUTTON_TEXT_ADD_CUSTOM_OFFER":"Neues Partnerangebot",
    "CUSTOM_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_CUSTOM_OFFERS":"Zurück zu den Partnerangeboten",
    "CUSTOM_OFFERS_CAPTION_LABLE_TEXT_APPLICABILITY":"Anwendbarkeit",
    "CUSTOM_OFFERS_CAPTION_TEXT_ADD":"Hinzufügen",
    "CUSTOM_OFFERS_CAPTION_TEXT_CONTRACT_OFFERS":"Vertragsangebote",
    "CUSTOM_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Partnerangebote",
    "CUSTOM_OFFERS_CAPTION_TEXT_EDIT":"Bearbeiten",
    "CUSTOM_OFFERS_FILTER_LABEL_TRIAL_DURATION":"TESTDAUER",
    "CUSTOM_OFFERS_HELP_TEXT_CONTENT":"Wählen Sie 'ChargePeriods' als 'Default', 'Billing rule for purchase of seats’ und Billing rule for reduction of seats’ all ‘Prorate’.",
    "CUSTOM_OFFERS_HELP_TEXT_HEADER":"Beachten Sie beim Hinzufügen eines Partnerangebots zu einem zusammengesetzten Angebot die folgenden Punkte",
    "CUSTOM_OFFERS_LABEL_TEXT_APPROVAL_QUANTITY":"Genehmigungsmenge",
    "CUSTOM_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOM_OFFERS_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
    "CUSTOM_OFFERS_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOM_OFFERS_LABEL_TEXT_CATEGORY_NAME":"Kategorie",
    "CUSTOM_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Art des Abrechnungszeitraums",
    "CUSTOM_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
    "CUSTOM_OFFERS_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
    "CUSTOM_OFFERS_LABEL_TEXT_Description":"Beschreibung",
    "CUSTOM_OFFERS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOM_OFFERS_LABEL_TEXT_FEED_SOURCE":"Futterquelle",
    "CUSTOM_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
    "CUSTOM_OFFERS_LABEL_TEXT_INSTRUCTIONS":"Anleitung",
    "CUSTOM_OFFERS_LABEL_TEXT_IS_ADDON":"Ist das Angebot ein AddOn?",
    "CUSTOM_OFFERS_LABEL_TEXT_NAME":"Name",
    "CUSTOM_OFFERS_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Anzahl der Tage für die kostenlose Stornierung",
    "CUSTOM_OFFERS_LABEL_TEXT_OFFER_REF_ID":"Angebots-Ref.-ID",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
    "CUSTOM_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
    "CUSTOM_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Symbol",
    "CUSTOM_OFFERS_LABEL_TEXT_PROPERTIES":"Eigenschaften",
    "CUSTOM_OFFERS_LABEL_TEXT_PROVIDER":"Anbieter",
    "CUSTOM_OFFERS_LABEL_TEXT_Sale_PRICE":"Verkaufspreis",
    "CUSTOM_OFFERS_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
    "CUSTOM_OFFERS_LABEL_TEXT_VALIDITY":"Gültigkeit",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CATEGORY":"Kategorie",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CHARGE_PERIODS_HELP_TEXT":"Was möchten Sie als Abrechnungszeitraum für die Abonnements",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Welchen Verbrauchstyp sollen die Abonnements haben?",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_FEED_SOURCE":"Futterquelle",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Anzahl der maximal zulässigen Tage ohne Stornierung.",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen sollten anteilig oder voll aufgeladen sein",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_PROVIDER":"Anbieter",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SALE_TYPE":"Verkaufsart",
    "CUSTOM_OFFERS_LABEL_TOOLTIP_TEXT_SLAB_DATA":"Platte",
    "CUSTOM_OFFERS_LABEL_TOOTIP_TEXT_VALIDITY":"Validität des Angebots.",
    "CUSTOM_OFFERS_PRODUCT_ICON_PLACEHOLDER_SELECT":"Wählen Sie ein Bild aus",
    "CUSTOM_OFFERS_SAVE_SUCCESS":"Partnerangebot {{customoffer}} erfolgreich gespeichert",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_DELETED_CUSTOM_OFFERS":"Gelöschte Angebote einbeziehen",
    "CUSTOM_OFFERS_SEARCH_CONSIDER_TRAIL_OFFERS":"Probeangebote",
    "CUSTOM_OFFERS_SELECT_BILLING_CYCLE":"Abrechnungszeitraum auswählen",
    "CUSTOM_OFFERS_SELECT_TERM_DURATION":"Laufzeit auswählen",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_COST_PRICE":"Selbstkostenpreis",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_OFFER_DETAILS":"Angebotsdetails",
    "CUSTOM_OFFERS_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_PRODUCT_TAGS":"Gehen Sie zum Produkttags-Bildschirm",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "CUSTOM_OFFERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Aussicht",
    "CUSTOM_OFFERS_TEXT_INFO_DELETED":"(gelöscht)",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_COST_PRICE":"Dies ist der Preis, zu dem Sie diese Komponente erhalten haben. Sie können den Verkaufspreis für dieses Angebot angeben, wenn Sie diesen in einen Plan aufnehmen.",
    "CUSTOM_OFFERS_TOOLTIP_TEXT_SALE_PRICE":"Dies ist der Preis, zu dem Sie diese Komponente verkaufen.",
    "CUSTOM_OFFERS_UPDATE_SUCCESS":"Partnerangebot {{customoffer}} erfolgreich aktualisiert",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_CALENDER_MONTH":"Start- und Enddatum der Gebühr beginnen und enden am Ende eines Monats.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_MS_BILLING":"Die Start- und Enddaten der Gebühren stimmen mit den Start- und Enddaten der MS-Abrechnung überein.",
    "CUSTOM_OFFR_CRG_PRD_DESC_ALIGN_WITH_PARTNER_BILLING":"Die Start- und Enddaten der Gebühren stimmen mit den Start- und Enddaten der Partnerabrechnung überein.",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_CALENDER_MONTH":"Mit Kalendermonat ausrichten",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_MS_BILLING":"An MS Billing ausrichten",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PARTNER_BILLING":"An Partnerabrechnung ausrichten",
    "CUSTOM_OFFR_CRG_PRD_NAME_ALIGN_WITH_PROVIDER_BILLING":"An der Anbieterabrechnung ausrichten",
    "CUSTOM_OFFR_CRG_PRD_NAME_DEFAULT":"Standard (ab Kaufdatum)",
    "CUSTOM_PASSWORD_POPUP_HEADER_TEXT":"Legen Sie ein neues Passwort fest",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_1":"Möchten Sie das Passwort wirklich zurücksetzen?",
    "CUSTOM_PASSWORD_POPUP_HELP_LINE_2":"Ein neues Passwort wird generiert und das aktuelle Passwort des Benutzers funktioniert nicht mehr.",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_ENTER_NEW_PASSWORD":"Neues Passwort eingeben",
    "CUSTOM_PASSWORD_POPUP_LABEL_TEXT_SHOW_PASSWORD":"Passwort anzeigen",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_AUTO_GENERATE_PASSWORD":"Passwort automatisch generieren",
    "CUSTOM_PASSWORD_POPUP_RADIO_BUTTON_TEXT_CUSTOM_PASSWORD":"Benutzerdefiniertes Passwort",
    "CUSTOM_PASSWORD_POPUP_TOOLTIP_TEXT_ENTER_NEW_PASSWORD":"Mindestens 8 Zeichen. Muss mindestens ein Großbuchstaben, ein Kleinbuchstaben, ein Sonderzeichen und einen numerischen Wert enthalten",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_AT_LEAST_8_CHAR":"Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen.",
    "CUSTOM_PASSWORD_VALIDATION_MESSAGE_PASSWORD_NOT_CONTAIN_USER_NAME":"Benutzerdefiniertes Passwort sollte nicht den Vornamen des Benutzers enthalten.",
    "CUSTOM_POLICY_ACTIVETE_SUCCESSFULLY":"Richtlinie erfolgreich aktiviert",
    "CUSTOM_POLICY_ADD_TITLE":"Benutzerdefinierte Richtlinie hinzufügen",
    "CUSTOM_POLICY_ASSIGNED_POLICIES":"Zugewiesene Richtlinien",
    "CUSTOM_POLICY_ASSIGNED_SUCCESS":"Richtlinie erfolgreich zugewiesen",
    "CUSTOM_POLICY_ASSIGNMENT_ADD":"Richtlinie zuweisen",
    "CUSTOM_POLICY_AZURE_POLICY_ID":"Azure PolicyId",
    "CUSTOM_POLICY_CUSTOMER_NAME":"Kundenname",
    "CUSTOM_POLICY_DEACTIVETE_SUCCESSFULLY":"Richtlinie erfolgreich deaktiviert",
    "CUSTOM_POLICY_DESCRIPTION":"Beschreibung",
    "CUSTOM_POLICY_INSTRUCTIONS":"Führen Sie die folgenden Schritte aus, um eine benutzerdefinierte Richtlinie zu erstellen",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP1":"Geben Sie einen Namen und eine Beschreibung für die benutzerdefinierte Richtlinie ein",
    "CUSTOM_POLICY_INSTRUCTIONS_STEP2":"Geben Sie die richtige Definition an",
    "CUSTOM_POLICY_LABEL_SCOPE":"Umfang",
    "CUSTOM_POLICY_NAME":"Name",
    "CUSTOM_POLICY_POLICY_ASSIGNMENT":"Richtlinienzuweisung",
    "CUSTOM_POLICY_POLICYDEFINITION":"Definition",
    "CUSTOM_POLICY_RESOURCEGROUPNAME":"Name der Ressourcengruppe",
    "CUSTOM_POLICY_REVOKE_SUCCESS":"Richtlinie erfolgreich widerrufen",
    "CUSTOM_POLICY_SAVE_SUCCESS":"Richtlinie {{policy}} erfolgreich gespeichert",
    "CUSTOM_POLICY_SUBSCRIPTION":"Abonnementname",
    "CUSTOM_POLICY_TITLE":"Benutzerdefinierte Richtlinien",
    "CUSTOM_POLICYASSIGNMENT_INSTRUCTION":"Um die Richtlinie der Abonnementstufe zuzuweisen, wählen Sie bitte alle Ressourcengruppen aus.",
    "CUSTOM_ROLE_ACTIVETE_SUCCESSFULLY":"Rolle erfolgreich aktiviert",
    "CUSTOM_ROLE_AZURE_ROLE_ID":"Azure RoleId",
    "CUSTOM_ROLE_CUSTOMER_NAME":"Kundenname",
    "CUSTOM_ROLE_DEACTIVETE_SUCCESSFULLY":"Rolle erfolgreich deaktiviert",
    "CUSTOM_ROLE_DESCRIPTION":"Beschreibung",
    "CUSTOM_ROLE_INSTRUCTIONS":"Führen Sie die folgenden Schritte aus, um eine benutzerdefinierte Rolle zu erstellen",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP1":"Geben Sie einen Namen und eine Beschreibung für die benutzerdefinierte Rolle ein",
    "CUSTOM_ROLE_INSTRUCTIONS_STEP2":"Geben Sie nur die Abschnitte \\''Aktion\\'' und \\''Nicht Aktion\\'' für die Rollendefinition ein.",
    "CUSTOM_ROLE_NAME":"Name",
    "CUSTOM_ROLE_RESOURCEGROUPNAME":"Name der Ressourcengruppe",
    "CUSTOM_ROLE_ROLEDEFINITION":"Rollendefinition",
    "CUSTOM_ROLE_SAVE_SUCCESS":"Rolle {{role}} erfolgreich gespeichert",
    "CUSTOM_ROLE_SUBSCRIPTION":"Abonnementname",
    "Customer":"Kunde",
    "CUSTOMER_ADD_ADJUSTMENT_IS_TAXABLE":"Ist steuerpflichtig",
    "CUSTOMER_ADDON_POPUP_BUTTON_TEXT_UPDATE_CART":"Warenkorb aktualisieren",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_Category":"Kategorie",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_ADDON_POPUP_LABEL_TEXT_PER_SEED_PRICE":"Preis pro Samen",
    "CUSTOMER_ADDON_POPUP_TITLE_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_AGREEMENT_EZIDEBIT_ALLOW_TEXT":"Ich/Wir ermächtigen Ezidebit Pty Ltd ACN 096 902 813 (Benutzer-ID Nr. 165969, 303909, 301203, 234040, 234072, 428198), mein/unser Konto bei dem oben genannten Finanzinstitut über das Bulk Electronic Clearing System (BECS) in gemäß dieser Lastschriftanforderung und gemäß dem Ezidebit DDR-Servicevertrag. <br> Ich/Wir genehmige, dass diese Zahlungen in Intervallen und Beträgen gemäß den Bestimmungen der BeCloudSmart-Vereinbarung und nachfolgender Vereinbarungen von BeCloudSmart abgebucht werden.",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_HEADER_TEXT":"Zustimmung",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT":"Ezidebit-Vereinbarung",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_MICROSOFT_CLOUD_RESELLER":"Microsoft Cloud Reseller-Vereinbarung",
    "CUSTOMER_AGREEMENT_HYPERLINK_POPUP_TEXT_FOR_PARTNER":"Nutzungsbedingungen des Partners",
    "CUSTOMER_AGREEMENT_HYPERLINK_TEXT":"Lesen Sie die Ezidebit-Vereinbarung",
    "CUSTOMER_AGREEMENT_PLEASE_READ_TEXT":"Bitte lesen Sie die",
    "CUSTOMER_ALERT_SUCCESS_MESSAGE_PAYMENT_RECORDED":"Zahlung erfolgreich erfasst.",
    "CUSTOMER_BILLING_ONBOARDING__CUSTOMER_ONBAORDING_IN_PROGRESS":"Der Prozess der Integration der Rechnungsdetails des Kunden ist im Gange",
    "CUSTOMER_BILLING_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD":"Fahren Sie mit Onboard fort",
    "CUSTOMER_BILLING_ONBOARDING_CREATED_SUCCESS":"Kundenabrechnungsdetails erfolgreich eingebunden.",
    "CUSTOMER_BILLING_ONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Der Kunde existiert bereits im Abrechnungssystem",
    "CUSTOMER_BILLING_PROFILE_LOOK_UP_STATUS_SUCCESS_MESSAGE":"<p/><p>Es wurde eine Übereinstimmung für die ID <strong>{{billingProviderReferenceID}}</strong> im Abrechnungssystem gefunden.<br/><span> Die Abrechnungsadresse in der Datei lautet <strong> {{formattedAddress}} </strong>.</span><br/> Bitte geben Sie sich aus und verwenden Sie die Option <strong>Profil</strong>, um dieses Detail zu ändern, nachdem die Rechnungsdetails des Kunden erfolgreich eingebunden wurden.",
    "CUSTOMER_BTN_MONTHLY_TURNOVER_REPORT_LOADING":"Monatlicher Umsatz...",
    "CUSTOMER_BULK_ONBOARDING_STATUS_ERROR":"Error",
    "CUSTOMER_BULK_ONBOARDING_STATUS_IN_PROGRESS":"In Bearbeitung",
    "CUSTOMER_BULK_ONBOARDING_STATUS_SUCCESS":"Erfolg",
    "CUSTOMER_BUNDLE_PRODUCT_READ_MORE_DESC_TEXT":"Weiterlesen",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_CUTOMER_CONSENT":"Kundeneinwilligung neu laden",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_DOWNLOAD_REPORT":"Gitterbericht wird neu geladen",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Neuladen von Provider-Kundenprofilen",
    "CUSTOMER_BUSY_MESSAGE_RELOAD_RECON_REPORT":"Neuladen einleiten",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS":"Rechnungsposten",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_LOADING":"Rechnungsposten...",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS":"Rechnungsposten mit Provisionen",
    "CUSTOMER_BUTTON_INVOICE_LINE_ITEMS_WITH_COMMISSIONS_LOADING":"Rechnungsposten mit Provisionen...",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY":"Rechnungszusammenfassung",
    "CUSTOMER_BUTTON_INVOICE_SUMMARY_LOADING":"Rechnungszusammenfassung...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT":"Rentabilitätsbericht",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT":"Rentabilitätsbericht nach Produkt",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_PRODUCT_LOADING":"Rentabilitätsbericht nach Produkt...",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_RESELLER":"Rentabilitätsbericht des Wiederverkäufers",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT":"Rentabilitätsbericht des Mieters",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_BY_TENANT_LOADING":"Rentabilitätsbericht des Mieters",
    "CUSTOMER_BUTTON_PROFITABILITY_REPORT_LOADING":"Rentabilitätsbericht...",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS":"Steuerposten",
    "CUSTOMER_BUTTON_TAX_LINE_ITEMS_LOADING":"Steuerposten...",
    "CUSTOMER_BUTTON_TAX_SUMMARY":"Steuerübersicht",
    "CUSTOMER_BUTTON_TAX_SUMMARY_LOADING":"Steuerübersicht...",
    "CUSTOMER_BUTTON_TEXT_CONSENT_REPORT":"Einwilligungsbericht",
    "CUSTOMER_BUTTON_TEXT_DOWNLOAD_CUSTOMER_REPORT":"Kundenbericht herunterladen",
    "CUSTOMER_BUTTON_TEXT_EXPORT_RECON_REPORT":"Abgleichbericht exportieren",
    "CUSTOMER_BUTTON_TEXT_EXPORT_USER_LICENSE":"Benutzerlizenz exportieren",
    "CUSTOMER_BUTTON_TEXT_RELOAD_CUTOMER_CONSENT":"Kundeneinwilligung neu laden",
    "CUSTOMER_BUTTON_TEXT_RELOAD_PROVIDER_CUSTOMER_PROFILES":"Anbieter-Kundenprofile neu laden",
    "CUSTOMER_BUTTON_TEXT_RELOAD_RECON_REPORT":"Abstimmungsbericht neu laden",
    "CUSTOMER_BUTTON_TEXT_TERMS_AND_CONDITIONS_REPORT":"Bericht zu den Allgemeinen Geschäftsbedingungen",
    "CUSTOMER_BUTTON_TEXT_TRANSACTION_AMOUNT_LIMIT_REPORT":"Bericht zum Transaktionsbetragslimit",
    "CUSTOMER_CANNOT_PERFORM_SHOP_DUE_TO_CUSTOMER_ADMIN_LITE_ROLE_MESSAGE":"Neue Käufe werden über die Plattform deaktiviert. Bitte wenden Sie sich an Ihren Kundenbetreuer, um eine Bestellung aufzugeben.",
    "CUSTOMER_CANNOT_PERFORM_SHOP_MESSAGE_DUE_TO_ROLE":"Wenden Sie sich an Ihren Account Manager, um ein neues Produkt zu erwerben",
    "CUSTOMER_CANNOT_PERFORM_TRANSACTIONS_MESSAGE":"Neukäufe und Änderungen der Lizenzanzahl werden derzeit ausgesetzt. Wenden Sie sich für weitere Informationen bitte an Ihren Account Manager.",
    "CUSTOMER_CART_ADDON_POPUP_REMOVE":"Entfernen",
    "CUSTOMER_CART_BREADCRUM_TEXT_CUSTOMER_CART":"Kundenwagen",
    "CUSTOMER_CART_BUTTON_TEXT_CONTINUE_SHOPPING":"Mit dem Einkaufen fortfahren",
    "CUSTOMER_CART_BUTTON_TEXT_I_M_READY_TO_PAY":"Ich bin bereit zu zahlen",
    "CUSTOMER_CART_BUTTON_TEXT_PAY_NOW":"Jetzt bezahlen",
    "CUSTOMER_CART_BUTTON_TEXT_PLACE_ORDER":"Bestellung aufgeben",
    "CUSTOMER_CART_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
    "CUSTOMER_CART_CONFIRMATION_OF_CART_LINE_ITEM_CUSTOM_END_DATES":"Überprüfen Sie das benutzerdefinierte Enddatum der Warenkorbpositionen, bevor Sie mit dem Kauf fortfahren.",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_ALIGN_END_DATE_WITH_CALENDAR_MONTH":"Richten Sie das Enddatum am Kalendermonat aus",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_DEFAULT":"Standard",
    "CUSTOMER_CART_COTERMINOSITY_DROPDOWN_END_DATE_FROM_EXISTING_SUBSCRIPTIONS":"Enddatum bestehender Abonnements",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_HEADER":"Enddatum bestehender Abonnements",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_ID":"Abonnement-ID",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_CART_COTERMINOSITY_POPUP_TABLE_HEADER_VALIDITY":"Gültigkeit",
    "CUSTOMER_CART_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Aktion angewendet",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_AMOUNT_PAYABLE":"Bezahlbarer Betrag",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_CURRENCY":"Währung:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TAX_NAME":"Steuername:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COST":"Gesamtkosten",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_COUNT":"Komplette Anzahl:",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_DISCOUNT":"Totaler Rabatt",
    "CUSTOMER_CART_PARAGRAPH_TITLE_TEXT_TOTAL_TAXES":"Gesamtsteuer:",
    "CUSTOMER_CART_PORTLET_TITLE_TEXT_PRICE_DETAILS":"Bestellübersicht",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_CART":"Im Warenkorb befindet sich bereits ein Vertragsangebot. Bitte entfernen Sie das vorhandene Vertragsangebot, um ein neues hinzuzufügen.",
    "CUSTOMER_CART_PRODUCT_ADD_EXISTING_CONTACT_OFFER_IN_PURCHASED_PRODUCTS":"Sie haben bereits ein Vertragsangebot erworben. Bitte entfernen Sie das bestehende Vertragsangebot, um ein neues zu erwerben.",
    "CUSTOMER_CART_PRODUCT_ALREADY_ONHOLD":"Eine Bestellung für dieses Produkt wurde bereits an den Anbieter gesendet. Wenden Sie sich für weitere Informationen bitte an Ihren Account Manager",
    "CUSTOMER_CART_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Die Anzahl der gekauften Plätze überschreitet das zulässige Limit von {{SeatLimit}}.",
    "CUSTOMER_CART_SEAT_LIMIT_MESSAGE_FOR_ALL_ORDER":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde.",
    "CUSTOMER_CART_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "CUSTOMER_CART_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "CUSTOMER_CART_SITE_DEPARTMENT_DROPDOWN_PLACRHOLDER_TEXT":"Wählen Sie einen Standort/eine Abteilung aus",
    "CUSTOMER_CART_SUP_TEXT_ADD_ON":"Erweiterung",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_ELIGIBILITY":"Ihr Mandant ist nicht berechtigt, Azure zu erwerben. Mögliche Gründe: Sie haben bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert.",
    "CUSTOMER_CART_TEXT_PLACE_ORDER_WARNING_FOR_AZURE_PLAN_ELIGIBILITY":"Ihr Mandant ist nicht berechtigt, den Azure-Plan zu erwerben. Mögliche Gründe: Entweder haben Sie bereits einen Azure-Plan und dieser ist noch nicht mit C3 synchronisiert, oder es ist ein Azure-Abonnement im Partner Center aktiv, und es muss aktualisiert werden, oder Sie haben mehr als einen Azure-Plan-Einkaufswagenposten unter demselben Mandanten.",
    "CUSTOMER_CART_TRANSACTION_AMOUNT_LIMIT_CONFIRMATION":"Diese Bestellung erfordert die Zustimmung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Der Transaktionsbetrag überschreitet das zulässige Limit von <b> {{TransactionAmountLimit}}</b>.",
    "CUSTOMER_COMMENT_TYPE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
    "CUSTOMER_CONFIG_DISP_SHOW_TAX_IN_CART":"Mehrwertsteuer im Warenkorb anzeigen",
    "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Rechnungsnummern beginnen mit diesem Text",
    "CUSTOMER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Rechnungsnummerntext",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Ermöglicht dem Benutzer, unbezahlte Beiträge bis zur aktuellen Rechnung anzuzeigen",
    "CUSTOMER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Zeigen Sie unbezahlte Beiträge in der Rechnung an",
    "CUSTOMER_CONFIGURATION_LABEL_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
    "CUSTOMER_CONSENT_ACCEPT_OR_REJECT_POPUP":"Möchten Sie der Erneuerungszustimmung für {{ProductName}} wirklich {{Consent}}?",
    "CUSTOMER_CONSENT_ACCEPTED_MESSAGE":"Ihre Zustimmung wurde erfolgreich akzeptiert",
    "CUSTOMER_CONSENT_BTN_TEXT_ADD":"Hinzufügen",
    "CUSTOMER_CONSENT_BTN_TEXT_UPDATE":"Aktualisieren",
    "CUSTOMER_CONSENT_CAPTION_TEXT_CUSTOMER_CONSENT":"Zustimmung des Kunden",
    "CUSTOMER_CONSENT_CLOUD_AGREEMENT_URL":"Vereinbarung URL",
    "CUSTOMER_CONSENT_HELP_TEXT":"Bitte geben Sie die Details der Person in der Organisation des Kunden an, die die Microsoft-Kundenvereinbarung <a ng-click='vm.CustomerConsentClick()'> akzeptiert hat. </a>",
    "CUSTOMER_CONSENT_LABELS_EMAIL_ADDRESS":"E-Mail-Addresse",
    "CUSTOMER_CONSENT_LABELS_FIRST_NAME":"Vorname",
    "CUSTOMER_CONSENT_LABELS_LAST_NAME":"Nachname",
    "CUSTOMER_CONSENT_LABELS_PHONE_NUMBER":"Telefonnummer",
    "CUSTOMER_CONSENT_OR_AGREEMENT_BTN_WITH_TEXT":"Klicken Sie hier auf <a id='customerConsentLink'ng-click='vm.MicrosoftCloudAgreementClicked()'></a>, um die Microsoft-Kundenvereinbarung zu lesen",
    "CUSTOMER_CONSENT_OR_AGREEMENT_TITLE_MICROSOFT":"Microsoft-Kundenvereinbarung",
    "CUSTOMER_CONSENT_REJECT_POPUP":"Möchten Sie<br>{{ProductName}} wirklich ablehnen?",
    "CUSTOMER_CONSENT_REJECTED_MESSAGE":"Ihre Einwilligung wurde erfolgreich abgelehnt",
    "CUSTOMER_CONSENT_SAVED_MESSAGE":"Ihre Einwilligung wurde erfolgreich gespeichert",
    "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_EVENT":"Zustimmung zur Verlängerung des Partnerangebots",
    "CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED_EVENT":"Zustimmung zur Verlängerung des Partnerangebots ist fehlgeschlagen",
    "CUSTOMER_CONSENT_TEXT_DATE":"Datum",
    "CUSTOMER_CONSENT_TEXT_EMAIL":"Email",
    "CUSTOMER_CONSENT_TEXT_IP":"IP",
    "CUSTOMER_CONSENT_TEXT_TIME":"Zeit",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION":"Bitte gehen Sie zum Profilmenü und aktualisieren Sie das Zahlungsprofil",
    "CUSTOMER_DASH_BOAARD_PROFILE_INSTRUCTION_HEADLINE":"Bitte füllen Sie das Zahlungsprofil aus",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_INTRO_CONTENT":"Schnelllink zum Azure-Portal",
    "CUSTOMER_DASHBOARD_AZURE_PORTAL_TITLE":"Azure-Portal",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_CONTENT":"Anzahl der bisher gekauften Produkte",
    "CUSTOMER_DASHBOARD_CUSTOMER_PRODUCT_INTRO_TITLE":"Produkte",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_CONTENT":"Anzahl der Benutzer, die Zugriff auf Ihre Informationen haben",
    "CUSTOMER_DASHBOARD_CUSTOMER_USER_INTRO_TITLE":"Benutzer",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_CONTENT":"Benachrichtigung über eine ausstehende Rechnung",
    "CUSTOMER_DASHBOARD_INVOICE_DATA_INTRO_TITLE":"Rechnung",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_CONTENT":"Schnelllink zum Office 365-Portal",
    "CUSTOMER_DASHBOARD_OFFICE_PORTAL_INTRO_TITLE":"Office-Portal",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_INTRO_CONTENT":"Schnelllink zu Office 365-Nutzungsberichten",
    "CUSTOMER_DASHBOARD_OFFICE365_USAGE_REPORTS_TITLE":"Office 365-Nutzungsberichte",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 SKUs",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Produkt zählt nach SKU",
    "CUSTOMER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Zählt nach SKU",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Filterbereich",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 Monate",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 Monate",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 Jahr",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Kauf von Sitzplätzen",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Kauf von Sitzplätzen",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Kauf von Sitzplätzen",
    "CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sitze gekauft",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_CONTENT":"Kundensitze",
    "CUSTOMER_DASHBOARD_SEATS_TILE_INTRO_TITLE":"Kundenplätze",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_CONTENT":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen",
    "CUSTOMER_DASHBOARD_TEARMS_AND_CONDITIONS_INTRO_TITLE":"Geschäftsbedingungen",
    "CUSTOMER_DASHBOARD_TILE_CUSTOMER_SEATS":"Sitze",
    "CUSTOMER_DELETEINVOICELINEITEMDETAIL_SUCCESS":"Rechnungsposition erfolgreich gelöscht",
    "CUSTOMER_DEPARTMENT_ADD_DEPARTMENT":"Abteilung hinzufügen",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_DEPARTMENT_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOMER_DEPARTMENT_DELETE_SUCCESS_NOTIFICATION":"Abteilung erfolgreich gelöscht",
    "CUSTOMER_DEPARTMENT_EDIT_DEPARTMENT":"Abteilung bearbeiten",
    "CUSTOMER_DEPARTMENT_HEADER_TEXT":"Abteilungen",
    "CUSTOMER_DEPARTMENT_SAVE_SUCCESS_NOTIFICATION":"Abteilung erfolgreich gespeichert",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_ASSIGNED_SITES":"Zugewiesene Sites",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMER_DEPARTMENT_TABLE_HEADER_TEXT_SITE":"Seite ",
    "CUSTOMER_EXPORTINVOICE_WARNING":"Die Rechnung für {{customername}} wurde noch nicht generiert. Bitte versuchen Sie es nach einiger Zeit erneut.",
    "CUSTOMER_HEADER_TEXT_RELOADING_CUSTOMER_CONSENT_CUSTOMERS":"Neuladen der Kunden Zustimmung des Anbieters",
    "CUSTOMER_HEADER_TEXT_RELOADING_PROVIDER_CUSTOMER_PROFILES":"Neuladen von Provider-Kundenprofilen",
    "CUSTOMER_INVOICE_GENERATED_SUBJECT":"Von der Kundenrechnung generierter Text",
    "CUSTOMER_INVOICE_ON_DEMAND_BACK_TO_INVOICES":"Zurück zu Rechnungen",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_ADD_LINEITEM":"Position hinzufügen",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_CANCEL_INVOICE_CREATION":"Stornieren",
    "CUSTOMER_INVOICE_ON_DEMAND_BUTTON_TEXT_SAVE_INVOICE":"Rechnung speichern",
    "CUSTOMER_INVOICE_ON_DEMAND_CANCEL_SAVING_CHANGES_POPUP_TEXT":"Möchten Sie Ihre Änderungen wirklich rückgängig machen?",
    "CUSTOMER_INVOICE_ON_DEMAND_CHARGE_END_DATE_ERROR":"Das Startdatum der Belastung von Einzelposten darf nicht nach dem Enddatum der Belastung liegen",
    "CUSTOMER_INVOICE_ON_DEMAND_DELETE_LINEITEM_CONFIRMATION_MESSAGE":"Möchten Sie die Werbebuchung wirklich löschen?",
    "CUSTOMER_INVOICE_ON_DEMAND_DUE_DATE_ERROR":"Das Fälligkeitsdatum der Rechnung muss nach dem Rechnungsdatum liegen",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_CUSTOMER_DETAILS":"Kundendetails",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
    "CUSTOMER_INVOICE_ON_DEMAND_HEADER_TEXT_LINEITEMS":"Werbebuchungen",
    "CUSTOMER_INVOICE_ON_DEMAND_HELP_TEXT_FOR_TAX_CALCULATION":"Steuern werden angewendet, nachdem die Rechnung gespeichert wurde.",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DATE":"Rechnungsdatum",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_DUE_DATE":"Geburtstermin",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
    "CUSTOMER_INVOICE_ON_DEMAND_INVOICE_SUMMARY_LABEL_TEXT_INVOICE_SUB_TOTAL":"Zwischensumme",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ADDRESS":"Adresse",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CITY":"Stadt",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY":"Land",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Land auswählen",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_CUSTOMER_NAME":"Kundenname",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_STATE":"Zustand",
    "CUSTOMER_INVOICE_ON_DEMAND_LABEL_TEXT_ZIP":"Postleitzahl",
    "CUSTOMER_INVOICE_ON_DEMAND_LINEITEMS_COUNT_ERROR":"Bitte geben Sie mindestens einen Einzelposten an, um die Rechnung zu speichern",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_END_DATE":"Endtermin",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_CHARGE_START_DATE":"Anfangsdatum",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_IS_TAXABLE":"Ist steuerpflichtig",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_ITEM_NAME":"Artikelname",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_LINE_AMOUNT":"Zeilenbetrag",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALE_TYPE":"Verkaufsart",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_SALES_TAX":"Mehrwertsteuer",
    "CUSTOMER_INVOICE_ON_DEMAND_TABLE_HEADER_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_1_CREDIT":"Anerkennung",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_RADIOBUTTON_2_ADDITIONAL_CHARGES":"Zuschlag",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTAMOUNT_FORM_TEXBOX_NUMBER_ADJESTMENT_AMOUNT":"Anpassungsbetrag",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_ADJESTMENT_DETAILS":"Anpassungsdetail",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_PROVIDER_NAME":"Anbietername",
    "CUSTOMER_INVOICELINEDETAILS_ADJUSTMENTDETAIL_FROM_TEXTBOX_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_INVOICELINEDETAILS_BUTTON_1_BACK_TO_INVOICE":"Zurück zur Rechnung",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADD":"Hinzufügen",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_ADJESTMENT":"Einstellung",
    "CUSTOMER_INVOICELINEDETAILS_TITLE_EDIT":"Bearbeiten",
    "CUSTOMER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Rechnungen",
    "CUSTOMER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
    "CUSTOMER_INVOICES_BUTTON_TEXT_CREATE_INVOICE":"Rechnung erstellen",
    "CUSTOMER_INVOICES_HEADER_TEXT_INVOICES":"Rechnungen",
    "CUSTOMER_LABLE_TEXT_TAG_KEY":"Kunden-Tag",
    "CUSTOMER_LABLE_TEXT_TAG_VALUES":"Wert",
    "CUSTOMER_LICENSE_REPORT_NOTIFCATION":"Der Kundenlizenzbericht wird an die entsprechende E-Mail gesendet",
    "CUSTOMER_MANAGE_CUSTOM_LICENSE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_MANAGE_PLAN_FILTER_BUTTON_RESPONSIVE":"Filter",
    "CUSTOMER_MANAGE_PRODUCT_BREADCRUM_MANAGE_PRODUCT":"Produkt verwalten",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_AZURE_PLAN":"Zurück zum Azure-Plan",
    "CUSTOMER_MANAGE_PRODUCT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_GROUP_NAME":"Gruppenname",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
    "CUSTOMER_MANAGE_PRODUCT_GROUP_TABLE_HEADER_TEXT_THRESHOLDS":"Schwellenwerte",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_ADD_ENTITLEMENTS":"Azure-Abonnement hinzufügen",
    "CUSTOMER_MANAGE_PRODUCT_INFO_TEXT_NO_ENTITLEMENTS_FOUND":"Keine Berechtigungen gefunden",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_BUTTON_TEXT_SAVE":"speichern",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_OWNER_DANGER_TEXT":"Besitzer wurde noch nicht zugewiesen",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_NAME":"Name",
    "CUSTOMER_MANAGE_PRODUCT_MANAGE_USAGE_OWNER_LABEL_TEXT_OWNER":"Inhaber",
    "CUSTOMER_MANAGE_PRODUCT_ROLES_AND_POLICIES_PAGE_TEXT":"Rollen &amp; Richtlinieninhalt geht hier....",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_BILLING":"Abrechnung",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_COMMENTS":"Bemerkungen",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ENTITLEMENTS":"Ansprüche",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ESTIMATE":"Schätzen",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_GROUPS":"Gruppen",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_INFO":"Die Info",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_MANAGE_OWNER":"Eigentümer verwalten",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_OPERATIONAL_ENTITIES":"Operative Einheiten",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_Payments":"Zahlungen",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_ROLES_AND_POLICIES":"Rollen & Richtlinien",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_SCHEDULING":"Erneuerung verwalten",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USAGE":"Verwendung",
    "CUSTOMER_MANAGE_PRODUCT_TAB_TEXT_USERS":"Benutzer",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_GROUP":"ResourceGroup",
    "CUSTOMER_MANAGE_PRODUCT_USER_TABLE_DATA_TEXT_RESOURCE_SUBSCRIPTION":"Abonnement",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_CAPTION_LABEL_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_MANAGEPRODUCT_LICENSE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_MARKET_CODE_PLACEHOLDER":"Marktcode auswählen",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_DOWNLOAD_CSV_HYPERLINK_TEXT":"Laden Sie eine Z.B-CSV herunter",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT":"Laden Sie Kontodaten für mehrere Benutzer im CSV-Format hoch. Laden Sie eine Z.B-CSV herunter",
    "CUSTOMER_MICROSOFT_USER_BULK_UPLOAD_HELP_TEXT_DOWNLOAD":"Datei als Referenz.",
    "CUSTOMER_MONTHLY_TURNOVER_REPORT":"Umsatzbericht",
    "CUSTOMER_NAME_CHANGE_POPUP_DESCRIPTION_TEXT":"Bitte geben Sie den neuen Namen ein",
    "CUSTOMER_NAME_CHANGE_POPUP_HEADER_TEXT":"Ändern Sie den Kundennamen",
    "CUSTOMER_NAME_CHANGE_POPUP_LABEL_TEXT_NAME":"Name",
    "CUSTOMER_NAME_CHANGE_SUCCESS":"Name des Kunden erfolgreich geändert",
    "CUSTOMER_NAME_UPDATE_FAILURE":"Aktualisierung des Kundennamens fehlgeschlagen",
    "CUSTOMER_NAME_UPDATE_SUCCESS":"Erfolg beim Aktualisieren des Kundennamens",
    "CUSTOMER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Der Kunde ist keinem Account Manager zugeordnet",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Sie sind mit keinem Microsoft-Mandanten verknüpft.",
    "CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR_MESSAGE":"Der Kauf von Anbieterprodukten kann fehlschlagen, da keine verknüpften Anbietermieter vorhanden sind",
    "CUSTOMER_ONB_STATUS_DESC_ASSIGNING":"Zuweisen",
    "CUSTOMER_ONB_STATUS_DESC_CREATED":"Erstellt",
    "CUSTOMER_ONB_STATUS_DESC_DELETED":"Gelöscht",
    "CUSTOMER_ONB_STATUS_DESC_ERROR":"Error",
    "CUSTOMER_ONB_STATUS_DESC_ONBOARDED":"An Bord",
    "CUSTOMER_ONBOARDING_BILLANDPAY_":"Suche in der Abrechnung!",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP_HEADING":"Befolgen Sie diese Schritte, um die Abrechnungssystem-ID eines Kunden zu erhalten",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP1":"Melden Sie sich bei Billing System an",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP2":"Gehen Sie zu Kunden",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP3":"Klicken Sie zum Öffnen auf den Kundendatensatz",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP4":"Nehmen Sie die Zeichenfolge nach recordkey",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_1":"Holen Sie sich die Zeichenfolge nach recordkey=in der URL",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_2":"z.B:",
    "CUSTOMER_ONBOARDING_BILLANDPAY_STEP5_PART_3":"Wert, der kopiert werden soll",
    "CUSTOMER_ONBOARDING_BILLING_STEP_1_FOR_AUTHRORIZENET":"Melden Sie sich bei Authorize.Net an",
    "CUSTOMER_ONBOARDING_BILLING_STEP_2_FOR_AUTHRORIZENET":"Nehmen Sie die Profil-ID vom Kundeninformationsmanager",
    "CUSTOMER_ONBOARDING_BILLING_STEP_FOR_AUTHRORIZENET_ROOT":"Stammverzeichnis für Kundeninformationsmanager: Home-> Kundeninformationsmanager",
    "CUSTOMER_ONBOARDING_BILLING_STEP5_PART_1":"Nimm den String aus der URL",
    "CUSTOMER_ONBOARDING_BILLINGID_LABEL":"Abrechnungssystem-ID",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_BULK_CUSTOMER_ONBOARDING":"Massenkunden-Onboarding",
    "CUSTOMER_ONBOARDING_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_COLLECT_CUSTOMER_DETAILS":"Kundendaten sammeln",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_DONE":"Getan",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_HERE":"Hier",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_LOOKUP":"Sieh nach oben!",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_PROCEED_TO_ONBOARD_THIS_CUSTOMER":"Fahren Sie mit Onboard dieses Kunden fort",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_REMOVE_UPLOAD":"Entfernen",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_SKIP":"Überspringen",
    "CUSTOMER_ONBOARDING_BUTTON_TEXT_TRY_ANOTHER":"Versuche einen anderen",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_BULK_CUSTOMER_ONBOARDING":"Massenkunden-Onboarding",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_MAP_LICENSES":"Kartenlizenzen",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_ONBOARD_SUCCESS":"Erfolg an Bord",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PAYMENT_TYPE":"Zahlungsart",
    "CUSTOMER_ONBOARDING_CAPTION_TEXT_PLEASESELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
    "CUSTOMER_ONBOARDING_CUSTOMER_NOT_FOUND_BILLING_SYSSTEM_MESSAGE":"Onboarding ist nicht abgeschlossen. Klicken Sie auf die Schaltfläche \\''Weiter mit Onboard dieses Kunden\\'', um fortzufahren.",
    "CUSTOMER_ONBOARDING_EMAIL_VALIDATION_ERROR_MESSAGE":"bitte geben Sie eine gültige E-Mail Adresse an",
    "CUSTOMER_ONBOARDING_EZIDEBIT_":"Suche in der Abrechnung!",
    "CUSTOMER_ONBOARDING_EZIDEBIT_LABEL":"EziDebit Id",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP_HEADING":"Befolgen Sie diese Schritte, um die EziDebit-ID eines Kunden zu erhalten",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP1":"Melden Sie sich bei EziDebit an",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP2":"Gehe zu den Zahlern",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP3":"Klicken Sie zum Öffnen auf den Zahlerdatensatz",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP4":"Nehmen Sie die Zeichenfolge, die sich nach contractId befindet",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_1":"Holen Sie sich die Zeichenfolge nach contractId=in der URL",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_2":"z.B:",
    "CUSTOMER_ONBOARDING_EZIDEBIT_STEP5_PART_3":"Wert, der kopiert werden soll",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMER_DETAILS":"Kundendetails",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_CUSTOMERS":"Kunden können im Partner Center hinzugefügt werden",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_CREATED_SUCCESSFULLY":"wird erfolgreich erstellt",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_IS_ONBOARDED_SUCCESSFULLY":"erfolgreich eingebunden",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_NEXT_STEPS":"Nächste Schritte",
    "CUSTOMER_ONBOARDING_HEADER_TEXT_PLAN_DETAILS":"Plan zuweisen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_BANK_NAME":"Bank Name",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CITY":"Stadt",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_CURRENCY_CODE":"Kundenwährungscode",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CUSTOMER_ID":"Kundennummer",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_CVV":"CVV",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_EMAIL":"Email",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_FIRST_NAME":"Vorname",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_IS_PRODUCT_SHAREABLE":"Ist das Produkt gemeinsam nutzbar",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LAST_NAME":"Nachname",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_1":"Linie 1",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_LINE_2":"Zeile 2",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_ID":"Microsoft ID",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MICROSOFT_TENANT_SELECTOR":"Wählen Sie den Microsoft-Mandanten aus.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_MONTH":"Monat",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NEXT_BILLING_DATE":"Nächstes Abrechnungsdatum",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE":"Hinweis:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_NOTE_HEADING_TEXT":"Aktualisieren Sie keinen anderen Inhalt in der Datei als die Benutzerdetails, wie im obigen Z.B angegeben.",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_1":"Optional:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_HEADER_2":"Wenn Sie bereits über die Details zu den Lizenzen verfügen, die Benutzern für ihr Abonnement zugewiesen wurden, können Sie uns diese wie folgt bereitstellen:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_1":"Geben Sie die Informationen in dem Format ein, das zum Herunterladen verfügbar ist",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_10":"Klicken Sie auf Benutzer und gewähren Sie den Benutzern im Kundenmandanten Zugriff auf dieses Portal",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_11":"Identifizieren und validieren Sie die Abonnementdetails",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_12":"Beheben Sie die Fehler, indem Sie eine aktualisierte Datei mit Lizenzdetails erneut hochladen, um den Onboarding-Vorgang abzuschließen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_2":"Geben Sie einfach die Benutzer-E-Mail-Adressen in den eckigen Klammern ([]) nach diesem Z.B ein:",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_3":"Laden Sie die Datei hoch und klicken Sie auf",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_4":"Sie können die Angabe der Lizenzdetails überspringen, indem Sie auf SKIP klicken, um den Onboarding-Vorgang abzuschließen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_5":"Klicken Sie auf Fertig, um zur Kundenliste zurückzukehren",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_6":"Suchen Sie den Kunden, der gerade an Bord war",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_7":"Eine Aktionsschaltfläche <i class=\"fa fa-star red\"></i> wird angezeigt, falls bei der Verarbeitung der von Ihnen hochgeladenen Lizenzdetails Fehler auftreten",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_8":"Klicken Sie auf die Schaltfläche <i class=\"fa fa-star red\"></i>, um die Fehler anzuzeigen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_OPTIONAL_STEP_9":"Bitte korrigieren Sie die Fehler und versuchen Sie es erneut",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PLAN":"Wählen Sie einen Plan",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER":"Anbieter",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CATEGORY":"Anbieterkategorie",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_STATE":"Zustand",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_TENANTS":"Mieter",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_FILE":"Datei hochladen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_UPLOAD_SELECT":"Wählen",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_YEAR":"Jahr",
    "CUSTOMER_ONBOARDING_LABEL_TEXT_ZIP":"Postleitzahl",
    "CUSTOMER_ONBOARDING_LBL_DELETED":"Gelöscht",
    "CUSTOMER_ONBOARDING_LBL_DISABLED":"Deaktiviert",
    "CUSTOMER_ONBOARDING_LBL_EXPIRED":"Abgelaufen",
    "CUSTOMER_ONBOARDING_LBL_SUSPENDED":"Suspendiert",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_NO_PLANS_WARNING_MESSAGE":"Dem Kunden ist kein Plan zugewiesen. Bitte erstellen Sie einen neuen Plan und weisen Sie ihn dem Kunden zu. Beheben Sie dann die Nichtübereinstimmung.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_SALE_PRICE_INSTRUCTION_MESSAGE":"Für Produkte mit reservierter Instanz berücksichtigen wir den Verkaufspreis in Prozent, daher ist der Standardwert 0 % Prozent. Wenn Sie einen anderen Wert angeben möchten, ändern Sie bitte den Verkaufspreis",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PLAN_NAME":"Plan Name",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_CURRENCY_CODE":"Währungscode",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_RESERVED_INSTANCES_PRICE":"Verkaufspreis (in Prozent)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis (in Prozent)",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_TABLE_TOOLTIP_SALE_PRICE":"Dieser Wert wird auf den Microsoft-Abrechnungspreis angewendet, um die Rechnung entsprechend zu erstellen.",
    "CUSTOMER_ONBOARDING_MANUAL_SYNC_VALIDATION_MESSAGE_SALE_PRICE":"Bitte geben Sie den Wert mit bis zu zwei Dezimalstellen ein.",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PLANS":"Pläne",
    "CUSTOMER_ONBOARDING_PLACEHOLDER_PROVIDER":"Anbieter",
    "CUSTOMER_ONBOARDING_PROCCES_TO_ONBOARDING":"Fahren Sie mit Onboard dieses Kunden fort",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_ACH":"ACH",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_INVOICE":"Manuelle",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_NO":"Nein",
    "CUSTOMER_ONBOARDING_RADIO_BUTTON_TEXT_YES":"Ja",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_1":"Suchen Sie nach Kunden- und Kartenabonnements",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_2":"Kartenlizenzen",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_3":"Komplett",
    "CUSTOMER_ONBOARDING_STEP_TITLE_TEXT_4":"Komplett",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_IMAGE_ALT_TEXT_LOGO":"Logo",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf :",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_EMAIL":"Abrechnung per E-Mail",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_BILLING_SYSTEM_QUANTITY":"Abrechnungssystem Menge",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_COMPANY_NAME":"Name der Firma",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_CSP_ID":"Microsoft ID",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_DOMAIN":"Domain",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_EFFECTIVE_PRICE":"Effektiver Preis",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MARKUP":"Markup",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS":"Passende Produkte",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_MATCHING_PRODUCTS_QUANTITY":"Passende Produktmenge",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PERCENTAGE":"Prozentsatz",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PRICE":"Preis",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_PRODUCT_NAME":"Produktname des Anbieters",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_PROVIDER_QUANTITY":"Anbietermenge",
    "CUSTOMER_ONBOARDING_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "CUSTOMER_ONBOARDING_TABLE_TD_MESSAGE_NO_SUBSCRIPTIONS_FOUND":"Es wurden keine Anbieterproduktabonnements für die Zuordnung gefunden. Klicken Sie dazu auf die Schaltfläche Kundendaten erfassen.",
    "CUSTOMER_ONBOARDING_TABLE_TD_WARNING_TEXT_CUSTOM":"BENUTZERDEFINIERT",
    "CUSTOMER_ONBOARDING_THANKS_MESSAGE":"Sie können über die Option Profil zu anderen Zahlungsmethoden wechseln. Rechnungen werden Ihnen vom Partner per E-Mail zugesandt",
    "CUSTOMER_ONBOARDING_USAGE_SUBSCRIPTIONS_LOAD_SUCCESS":"Nutzungsabonnements wurden erfolgreich geladen.",
    "CUSTOMER_ONLINE_SERVICE_NCE_GRID_LOADING_TEXT":"Wird geladen....",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_DISABLE":"Deaktivieren",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_EDIT":"Bearbeiten",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ENABLE":"Aktivieren",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Verwalten",
    "CUSTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ADDRESSES":"Adressen",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_EMAIL":"Email",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Entitätstyp",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_FIRST_NAME":"Vorname",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_LAST_NAME":"Nachname",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Name",
    "CUSTOMER_OPERATING_ENTITIES_LABEL_TEXT_ROLE":"Rolle",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_PROFILE":"Profil",
    "CUSTOMER_OPERATING_ENTITIES_NAVIGATION_TEXT_USERS":"Benutzer",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_ADD_NEW_USER":"Neuen Benutzer hinzufügen",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_BILLING_ADDRESSES":"Rechnungsadresse",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_EDIT_USER":"Benutzer bearbeiten",
    "CUSTOMER_OPERATING_ENTITIES_TITLE_TEXT_SHIPPING_ADDRESSES":"Lieferanschrift",
    "CUSTOMER_OPTION_ALL_PERIODS":"Alle Perioden",
    "CUSTOMER_ORDER_CART_DEFAULT_TERMS_AND_CONDITION_TEXT":"Geschäftsbedingungen.",
    "CUSTOMER_ORDER_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie das Kontrollkästchen NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie das Kontrollkästchen „Allgemeine Geschäftsbedingungen“, um Microsoft-Produkte zu bestellen.",
    "CUSTOMER_ORDER_CART_TERMS_AND_CONDITION_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">NCE-Geschäftsbedingungen.</a>",
    "CUSTOMER_ORDER_CART_TOOLTIP_TOTAL_TAX_AMOUNT":"Der zu zahlende Betrag sollte die Summe aus Produkt und Gesamtsteuer sein",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_RETRY_PAYMENT":"Zahlung erneut versuchen",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_INVOICE":"Rechnung anzeigen",
    "CUSTOMER_ORDER_DETAILS_BUTTON_TEXT_VIEW_PRODUCTS":"Produkte ansehen",
    "CUSTOMER_ORDER_DETAILS_PAGE_HEADER_BACK_TO_ORDERS_TEXT":"Zurück zu Bestellungen",
    "CUSTOMER_ORDER_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "CUSTOMER_ORDER_SUP_TEXT_ADD_ON":"Erweiterung",
    "CUSTOMER_ORDER_TAB_FILTER_LABEL_TEXT":"Filter",
    "CUSTOMER_ORDERS_ACTION_NAME_NEW_PURCHASE":"Neuer Einkauf",
    "CUSTOMER_ORDERS_ACTION_NAME_QUANTITY_UPDATE":"Mengenaktualisierung",
    "CUSTOMER_ORDERS_ACTION_NAME_STATUS_UPDATE":"Status Änderung",
    "CUSTOMER_ORDERS_BREADCRUMB_BUTTON":"Kundenbestellungen",
    "CUSTOMER_ORDERS_BUTTON_TEXT_VIEW_DETAILS":"Details anzeigen",
    "CUSTOMER_ORDERS_DETAILS_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
    "CUSTOMER_ORDERS_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
    "CUSTOMER_ORDERS_LABEL_TEXT_STATUS":"Status",
    "CUSTOMER_ORDERS_PAGE_HEADER_TEXT":"Kundenbestellungen",
    "CUSTOMER_ORDERS_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "CUSTOMER_ORDERS_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "CUSTOMER_ORDERS_TABLE_REJECT_STATUS_INFO_TEXT":"In Wartestellung",
    "CUSTOMER_ORDERS_TABLE_TITLE_ACTION":"Aktionen",
    "CUSTOMER_ORDERS_TABLE_TITLE_CART_ACTION":"Betrieb",
    "CUSTOMER_ORDERS_TABLE_TITLE_DEPARTMENT_NAME":"Abteilungsname",
    "CUSTOMER_ORDERS_TABLE_TITLE_OPERATION":"Betrieb",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_ID":"Auftragsnummer",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MAX_DATE":"Miteinander ausgehen",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_MIN_DATE":"Ab Datum",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_OPERATION":"Betrieb der Bestellung",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDER_STATUS":"Auftragsstatus",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_BY":"Bestellt durch",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERED_ON":"Bestellt am",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_FROM":"Bestellungen ab",
    "CUSTOMER_ORDERS_TABLE_TITLE_ORDERS_TO":"Bestellungen an",
    "CUSTOMER_ORDERS_TABLE_TITLE_SITE_NAME":"Seite-Name",
    "CUSTOMER_ORDERS_TABLE_TITLE_STATUS":"Status",
    "CUSTOMER_ORDERS_TEXT_INFO_LAST_UPDATED":"Zuletzt aktualisiert",
    "CUSTOMER_PARTNER_MANAGED_PRODUCT_READ_MORE_DESC_TEXT":"Weiterlesen",
    "CUSTOMER_PENDING_INVOICES_BUTTON_TEXT_BACK_TO_CUSTOMER_PAYMENTS":"Zurück zu Kundenzahlungen",
    "CUSTOMER_PENDING_INVOICES_HEADER_TEXT_PENDING_INVOICES_FOR":"Ausstehende Rechnungen für",
    "CUSTOMER_PLACEHOLDER_UNIQUE_IDENTIFIER":"Eindeutige Kennung auswählen",
    "CUSTOMER_PLAN_CHANGE_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
    "CUSTOMER_PLAN_CHANGE_CURRENT_COUPON_CODE_TEXT":"Aktueller Gutscheincode",
    "CUSTOMER_PLAN_CHANGE_ERROR_MESSAGE":"<strong> Fehler! </strong> Der Kunde kann nicht von <strong> {{PlanName}} </strong> zu <strong> {{targetPlanName}} </strong> verschoben werden, da der Zielplan keine Angebote enthält die mit den aktuell aktiven Abonnements übereinstimmen.",
    "CUSTOMER_PLAN_CHANGE_INFO":"Eine Planänderung wird noch nicht unterstützt, wenn im aktuellen Plan mindestens ein aktives Abonnement für ein zusammengesetztes Angebot vorhanden ist.",
    "CUSTOMER_PLAN_CHANGE_LABEL_CURRENT_PLAN":"Derzeitiger Plan",
    "CUSTOMER_PLAN_CHANGE_LABEL_CUSTOMER_SUBSCRIPTIONS":"Kundenabonnements",
    "CUSTOMER_PLAN_CHANGE_LABEL_EFFECTIVE_PRICE":"Effektiver Preis",
    "CUSTOMER_PLAN_CHANGE_LABEL_MATCH_FOUND":"Übereinstimmung gefunden",
    "CUSTOMER_PLAN_CHANGE_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_NAME":"Zielname",
    "CUSTOMER_PLAN_CHANGE_LABEL_TARGET_OFFER_PRICE":"Zielpreis",
    "CUSTOMER_PLAN_CHANGE_NEW_AND_CURENT_COUPON_ARE_SAME_WARNING_MESSAGE":"Dieser Gutschein ist dem Kunden bereits zugeordnet.",
    "CUSTOMER_PLAN_CHANGE_PLACEHOLDER_PLANS":"Pläne",
    "CUSTOMER_PLAN_CHANGE_SUCCESS_MESSAGE":"<strong> Erfolg! </strong> Der Kunde kann von <strong> {{PlanName}} </strong> zu <strong> {{targetPlanName}} </strong> verschoben werden. Klicken Sie auf <strong> Senden </strong>, um fortzufahren.",
    "CUSTOMER_PLAN_CHANGE_TARGET_PLAN_TEXT":"Zielplan",
    "CUSTOMER_PLAN_CHANGE_USE_CURRENT_COUPON_CODE_TEXT":"Verwenden Sie den aktuellen Gutscheincode",
    "CUSTOMER_PLAN_SUBSCRIPT_DELETED":"(gelöscht)",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
    "CUSTOMER_PLANS_TABLE_HEADER_PLAN_NAME":"Plan Name",
    "CUSTOMER_PRODUCT_ADDON_POPUP_BUTTOn_TEXT_CLOSE":"Schließen",
    "CUSTOMER_PRODUCT_ADDON_POPUP_LABEL_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_PRODUCT_ADDONS_LEBEL_TEXT":"Addons",
    "CUSTOMER_PRODUCT_BILLING_CYCLE_CHANGE_LINK_TEXT":"Veränderung",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION":"Abonnement reaktivieren",
    "CUSTOMER_PRODUCT_BTN_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Abonnement mit Addons reaktivieren",
    "CUSTOMER_PRODUCT_BTN_TEXT_SUSPEND_SUBSCRIPTION":"Abonnement aussetzen",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCT_BUNDLE_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
    "CUSTOMER_PRODUCT_BUNDLE_HEADER_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_PRODUCT_BUNDLE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_BUNDLE":"Bündeln",
    "CUSTOMER_PRODUCT_BUNDLE_TAB_TEXT_OPERATIONAL_ENTITY":"Operative Einheit",
    "CUSTOMER_PRODUCT_BUTTON_ADD_MORE_VOUCHERS":"Fügen Sie weitere Gutscheine hinzu",
    "CUSTOMER_PRODUCT_BUTTON_SUBMIT_ADD_MORE_VOUCHERS":"einreichen",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_MESSAGE":"<b>Abonnement fortsetzen</b> <br/> Sind Sie sicher, dass Sie das Abonnement für <b>{{productName}}</b> nach der Testversion fortsetzen möchten?",
    "CUSTOMER_PRODUCT_CONTINUE_SUBSCRIPTION_SUCCESSFULLY_TEXT":" Das Testangebot für {{productName}} wird in ein Abonnement umgewandelt, sobald das effektive Enddatum des Anbieters erreicht ist.",
    "CUSTOMER_PRODUCT_CUSTOM_BILLIG_CYCLE_CHANGE_CONFIRMATION":"Möchten Sie den Abrechnungszyklus von {{billingCycle}} in {{c3billingCycle}} ?<br> Nach der Rechnungsgenerierung kann der Abrechnungszyklus nicht rückgängig gemacht werden.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_CANCEL_CONFIRMATION_TEXT":"<b>Abonnement kündigen</b> <br/> Sind Sie sicher, dass Sie Ihr <b>{{productName}}</b>-Testabonnement kündigen möchten?",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_ERROR_TEXT":"Beim Kündigen des {{productName}}-Abonnements ist ein Fehler aufgetreten.",
    "CUSTOMER_PRODUCT_CUSTOM_OFFER_SUBSCRIPTION_TRIAL_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Testangebot für {{productName}} erfolgreich storniert!",
    "CUSTOMER_PRODUCT_DISCOUNT_TEXT":"aus",
    "CUSTOMER_PRODUCT_ERROR_POPUP_BUTTON_TEXT_IGNORE":"Ignorieren",
    "CUSTOMER_PRODUCT_ERROR_POPUP_HEADER_TEXT_ERROR_DETAILS":"Fehlerdetails",
    "CUSTOMER_PRODUCT_FILTER_CLOSE_BUTTON_TEXT":"Schließen",
    "CUSTOMER_PRODUCT_HEADER_TEXT_CUSTOMER_PRODUCTS":"Kundenprodukte",
    "CUSTOMER_PRODUCT_LABEL_TEXT_TRIAL_PERIOD":"Testzeit",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_ADD_VOUCHERS_POPUP_MESSAGE":"Bitte geben Sie kommagetrennte Werte ein.",
    "CUSTOMER_PRODUCT_LICENSE_SUPPORTED_LABEL_TEXT":"Anleitung",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT":"Ewig",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_PERPETUAL_SOFTWARE_TEXT_TEXT":"Unbefristete Software",
    "CUSTOMER_PRODUCT_LIST_CATEGORY_NAME_SOFTWARE_SUBSCRIPTIONS_TEXT":"Software-Abonnements",
    "CUSTOMER_PRODUCT_MANAGE_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_NAME_CONFIRMATION_TEXT":"Bestätigen Sie die Namensaktualisierung in C3",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_BILLING_CYCLE_CONFIRMATION_TEXT":"Möchten Sie den Abrechnungszyklus dieses Produkts wirklich in {{targetBillingCycle}} ändern?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_OFFER_CONFIRMATION_TEXT":"Möchten Sie das Angebot dieses Produkts wirklich in {{targetOffer}} ändern?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_SCOPE_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie den Umfang dieses Produkts auf {{targetScope}} ändern möchten?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_CHANGE_PRODUCT_TERM_DURATION_CONFIRMATION_TEXT":"Möchten Sie die Laufzeit dieses Produkts wirklich auf {{targetTermDuration}} ändern?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_REACTIVATE_PRODUCT_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie {{productName}} {{withAddons reaktivieren möchten? 'mit Addons': ''}}?",
    "CUSTOMER_PRODUCT_MANAGE_CONFIRMATION_POPUP_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie {{productName}} wirklich aussetzen?",
    "CUSTOMER_PRODUCT_MANAGE_NOTIFICATION_FOR_REQUIRED_PARENT_OFFER":"Übergeordnetes Abonnement fehlt für {{Name}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_NAME_SUCCESSFULLY":"Der Name wurde erfolgreich geändert",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PONUMBER_SUCCESSFULLY":"Die Bestellnummer wurde erfolgreich geändert",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_CHANGED_PRODUCT_BILLING_CYCLE_SUCCESSFULLY":"Der Abrechnungszyklus von {{productName}} wurde in {{targetBillingCycle}} geändert.",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_PARENT_QUATITY_UPDATED_TO_ZERO":"Ungültige Produktmenge",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_FOR_QUATITY_UPDATED_TO_ZERO":"Die Menge für bereits gekaufte Produkte kann nicht auf Null aktualisiert werden",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_ORDER_PLACED_SUCCESSFULLY":"Antrag auf Aktualisierung des Abonnements wurde erfolgreich gesendet",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATE_REQUEST_SUBMITTED_SUCCESSFULLY":"Anfrage zur Reaktivierung von {{productName}} {{withAddons ? 'with addons' : ''}} wurde erfolgreich übermittelt",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_REACTIVATED_PRODUCT_SUCCESSFULLY":"{{productname}} erfolgreich reaktiviert {{withAddons? 'with addons': ''}}",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_RELEASE_OWNERSHIP":"Eigentum erfolgreich freigegeben",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPEND_REQUEST_SUBMITTED_SUCCESSFULLY":"Anfrage zum Aussetzen des Produkts {{productName}} wurde erfolgreich gesendet",
    "CUSTOMER_PRODUCT_MANAGE_PAGE_NOTIFICATION_SUSPENDED_PRODUCT_SUCCESSFULLY":"{{productName}} erfolgreich ausgesetzt",
    "CUSTOMER_PRODUCT_NCE_AUTO_MANAGE_LABEL":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEW_LABEL":"Automatische Verlängerung",
    "CUSTOMER_PRODUCT_NCE_AUTO_RENEWS_ON_LABEL":"Erneuert weiter",
    "CUSTOMER_PRODUCT_NCE_CANCELSUBSCRIPTIONDATE_TEXT":"Stornierung möglich bis",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_DESCRIPTION":"Wenn Sie den Abrechnungszeitraum ändern, werden alle Abonnements aktualisiert, die Teil der \"Bestellung\" sind. Dadurch wird eine neue Jahreslaufzeit für alle Abonnements zum aktuellen Preis gemäß Preisliste erstellt. Die unten stehenden Abonnements werden auf \"jährliche\" Abrechnung aktualisiert. Um diese Anfrage abzuschließen, klicken Sie bitte auf „OK“ und dann auf „Senden“.",
    "CUSTOMER_PRODUCT_NCE_CHANGE_BILLING_CYCLE_LABEL":"Abrechnungszeitraum ändern",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_DESCRIPTION":"<b>Abonnement kündigen</b> </br> <br/> <b> Hinweis: </b> Ihre 7-tägige Kündigungsfrist ist abgelaufen. Wir können Ihr Abonnement nicht kündigen.</br>Wenn Sie dieses Abonnement im nächsten Abrechnungszeitraum kündigen möchten, aktualisieren Sie den Status der automatischen Verlängerung auf \"false\".",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_CENCEL_SUBSCRIPTION_TITLE":"Abonnement kündigen",
    "CUSTOMER_PRODUCT_NCE_ERROR_MESSAGE_TO_UPDATE_THE_AUTO_RENEW_STATUS":"<b>Automatische Verlängerung festlegen</b> </br> <br/> <b> Hinweis: </b> Wir können den Status der automatischen Verlängerung nicht ändern.",
    "CUSTOMER_PRODUCT_NCE_EXPIRES_ON_LABEL":"Läuft aus am",
    "CUSTOMER_PRODUCT_NCE_MANAGE_BILLING_CYCLE":"Rechnungshäufigkeit verwalten",
    "CUSTOMER_PRODUCT_NCE_PAUSE_ACTIVE_SUBSCRIPTION":"Unterbrechen Sie den Zugriff des Kunden auf den Dienst",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_DESCRIPTION":"Durch die Wiederaufnahme eines ausgesetzten Abonnements wird der Zugriff des Kunden auf den Dienst wiederhergestellt. Dies hat keine Auswirkungen auf Ihre Rechnung. Sie werden weiterhin wie gewohnt abgerechnet. Wenn Sie die automatische Verlängerung aktiviert und Änderungen geplant hatten, bevor Sie den Zugriff pausierten, können Sie sie wieder aktivieren.",
    "CUSTOMER_PRODUCT_NCE_RESUME_SUSPENDED_SUBSCRIPTION_TITILE":"Nehmen Sie den Zugriff des Kunden auf den Service wieder auf",
    "CUSTOMER_PRODUCT_NCE_SUBMIT_BUTTON":"Änderungen übermitteln",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_BUTTON":"Abonnement kündigen",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CACEL_CONFIRMATION_TEXT":"<b>Abonnement kündigen</b> <br/> Möchten Sie {{productName}} wirklich kündigen? <p style='text-align:left;' > Um eine Rückerstattung zu erhalten, müssen Sie Abonnements innerhalb von 7 Kalendertagen nach dem Kauf oder der Verlängerung kündigen. <br/> <b> Hinweis:</b> Der Kunde verliert den Zugriff auf dieses Abonnement sofort nach der Kündigung und der Zugriff kann nach der Kündigung nicht wiederhergestellt werden. Wenn Sie das Abonnement innerhalb von 7 Kalendertagen kündigen, wird Ihnen die nicht genutzte Zeit erstattet. In Ihrer nächsten monatlichen Rechnung erscheint eine Gutschrift. <a target='_blank' href=\"{{cancelNewCommerceSubscriptionGuidLineURL}}\"> Weitere Informationen.</a> </p>",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CANCELED_SUCCESSFULLY_TEXT":"Erfolgreich kündigen!",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_CHANGE_AUTO_RENEW_STATUS_TEXT":"<b>Automatische Verlängerung festlegen</b> <br/> Sind Sie sicher, dass Sie den {{autoRenewStatus}}-Status für {{productName}} automatisch verlängern möchten?",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_EXPIRE_LABEL":"Läuft ab",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_AUTO_RENEW_SUCCESSFULLY_TEXT":"Status der automatischen Verlängerung für {{productName}} erfolgreich aktualisiert",
    "CUSTOMER_PRODUCT_NCE_SUBSCRIPTION_UPDATED_SUCCESSFULLY_TEXT":"Erfolgreich geupdated !",
    "CUSTOMER_PRODUCT_NCE_SUSPEND_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie {{productName}} wirklich sperren? <br/> <b> Hinweis: </b> Obwohl das Pausieren des Kundenzugriffs auf den Dienst die Möglichkeit des Kunden, Dienste zu nutzen, sofort blockiert, wirkt sich dies nicht auf Ihre Rechnung aus. Sie werden weiterhin wie gewohnt abgerechnet. Durch das Pausieren wird die automatische Verlängerung deaktiviert und deaktiviert und alle geplanten Änderungen werden gelöscht.",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY":"Aktivität",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_ACTIVITY_STATUS":"Aktivitätsstatus",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_DATE":"Datum",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_QUANTITY":"Quellmenge",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_SOURCE_TO_TARGET":"Upgrade von {{sourceProductName}} auf {{targetProductName}}",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_ACTIVITY_TARGET_QUANTITY":"Zielmenge",
    "CUSTOMER_PRODUCT_NCE_TRANSITION_BUTTON":"Übergang",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_PRODUCT_NCE_UPGRADE_OFFER_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOMER_PRODUCT_ONLINE_SERVICE_TITTLE":"Kundenprodukte",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_NCE_BASIC_DETAILS":"Zurück zu den grundlegenden Details",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SAVE":"speichern",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_QUANTITY":"Menge",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_STATUS":"Status",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CANNOT_BE_GREATER_THAN":"Menge kann nicht größer sein als",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_CUSTOM_BILLING_CYCLE":"Benutzerdefinierter Abrechnungszeitraum",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabatt",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY":"Menge (verfügbar)",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_QUANTITY_FOR":"Menge für",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_PRODUCT_ONLINE_SERVICES_TAB_TEXT_LINKED_SUBSCRIPTION":"Verknüpftes Abonnement",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT__LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SAVE":"speichern",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_BUTTON_TEXT_SUBMIT_CHANGES":"Änderungen einreichen",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_ADDONS":"Add-Ons",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY":"Menge (verfügbar)",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_CANNOT_BE_GREATER_THAN":"Menge kann nicht größer sein als",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_QUANTITY_FOR":"Menge für",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE__LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_DISCOUNT":"Rabatt",
    "CUSTOMER_PRODUCT_PARTNER_MANAGED_PRODUCT_USAGE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_PRODUCT_PURCHASE_SUBSCRIPTION_TEXT":"<b>Abonnement kaufen</b> <br/> Sind Sie sicher, dass Sie Ihr Testangebot für {{productName}} in ein Abonnement umwandeln möchten?",
    "CUSTOMER_PRODUCT_QUANTITY_UPDATE_CONFIRMATION_TEXT":"Möchten Sie die Menge für das aktuelle Produkt wirklich von <b>{{oldQuantity}}</b> auf <b>{{newQuantity}}</b> aktualisieren?",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_AUTO_RENEW_LABEL":"Ist automatisch erneuerbar",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_EXPIRES_ON_LABEL":"Läuft aus am",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_AZURE_SUBSCRIPTION_ID":"Azure-Abonnement-ID",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ID":"Reservierungs-ID",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_ORDER_ID":"Reservierungsauftrags-ID",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_RESERVATION_REGION":"Reservierungsregion",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_LABEL_TEXT_SCOPE":"Umfang",
    "CUSTOMER_PRODUCT_RESERVED_INSTANCES_RENEWS_ON_LABEL":"Erneuert weiter",
    "CUSTOMER_PRODUCT_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "CUSTOMER_PRODUCT_SEAT_LIMIT_CONFIRMATION":"Dieser Artikel erfordert die Genehmigung zur Erfüllung durch den Partner, nachdem die Bestellung aufgegeben wurde. Die Anzahl der gekauften Plätze überschreitet das zulässige Limit von <b> {{SeatLimit}}</b> für <b>{{productName}}</b>.",
    "CUSTOMER_PRODUCT_SEQUENCE_BUTTON_FILTER_ENTITIES":"Entitäten filtern",
    "CUSTOMER_PRODUCT_SEQUENCE_HEADER_TEXT":"Produktsequenz",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_HEADER_TEXT_PRODUCT_SEQUENCE":"Produktsequenz",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_ADDON_SUFFIX":"Erweiterung",
    "CUSTOMER_PRODUCT_SEQUENCE_TABLE_PRODUCT_DESCRIPTION_LINKED_PRODUCT_SUFFIX":"Verknüpft",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_DEPARTMENT":"Abteilung",
    "CUSTOMER_PRODUCT_SEQUENCE_TEXT_FOR":"Für",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CANCEL":"Möchten Sie Ihre Änderungen wirklich rückgängig machen?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATE_CONFIRMATION":"Möchten Sie die Produktsequenz wirklich aktualisieren?",
    "CUSTOMER_PRODUCT_SEQUENCE_UPDATED":"Kunden Produktsequenz aktualisiert",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "CUSTOMER_PRODUCT_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_ERROR_MESSAGE":"Letzte Update-Anfrage haben einige Fehler Lesen Sie mehr...",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_DISCOUNT":"Rabatt",
    "CUSTOMER_PRODUCT_SOFTWARE_SUBSCRIPTION_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_PRODUCT_STATUS_ACTIVE":"Aktiv",
    "CUSTOMER_PRODUCT_STATUS_CANCELLED":"Abgesagt",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CANCEL_TRIAL":"Testversion abbrechen",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_CONTINUE_SUBSCRIPTION":"Abonnement nach der Testphase fortsetzen",
    "CUSTOMER_PRODUCT_STATUS_CHANGE_BUTTON_PURCHASE_SUBSCRIPTION":"Abonnement kaufen",
    "CUSTOMER_PRODUCT_STATUS_COMPLETED":"Vollendet",
    "CUSTOMER_PRODUCT_STATUS_DELETED":"Gelöscht",
    "CUSTOMER_PRODUCT_STATUS_DISABLED":"Deaktiviert",
    "CUSTOMER_PRODUCT_STATUS_EXPIRED":"Abgelaufen",
    "CUSTOMER_PRODUCT_STATUS_FAILED":"Gescheitert",
    "CUSTOMER_PRODUCT_STATUS_INPROVISION":"InProvision",
    "CUSTOMER_PRODUCT_STATUS_PENDING":"steht aus",
    "CUSTOMER_PRODUCT_STATUS_SUCCEEDED":"Erfolgreich",
    "CUSTOMER_PRODUCT_STATUS_SUSPENDED":"Suspendiert",
    "CUSTOMER_PRODUCT_TAB_FILTER_LABEL_TEXT":"Filter",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_COUNTRYCODE":"Land",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_DOMAIN":"Domain",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_LINKED_PRODUCT_PRICE":"Verlinkter Produktpreis",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_PROVIDER":"Anbieter",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION":"Abonnement reaktivieren",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_REACTIVATE_SUBSCRIPTION_WITH_ADDONS":"Abonnement mit Addons reaktivieren",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SCOPE":"Umfang",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SERVICE_TYPE":"Servicetypen",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_STATUS":"Status",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_SUSPEND_SUBSCRIPTION":"Abonnement aussetzen",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
    "CUSTOMER_PRODUCT_TOOLTIP_TEXT_TRIAL_PERIOD":"Testzeit",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "CUSTOMER_PRODUCT_TRIAL_OFFER_CONTINUE_SUCCESS_MESSAGE":"Das Angebot von {{productName}} wird am Ende des Testzeitraums in ein Abonnement umgewandelt.",
    "CUSTOMER_PRODUCT_TRIAL_PARENT_PRODUCT_LABEL_TEXT_CATEGORY":"Probeangebot für Eltern",
    "CUSTOMER_PRODUCT_UPGRAGE_NCE_OFFER_TERM_DURATION":"Laufzeit",
    "CUSTOMER_PRODUCTS_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_BACK_TO_ORDERS":"Zurück zu Bestellungen",
    "CUSTOMER_PRODUCTS_BUTTON_TEXT_INCLUDE_ZERO_QUANTITIES":"0 Mengen einschließen",
    "CUSTOMER_PRODUCTS_COMMITMENT_END_DATE":"Erneuerung",
    "CUSTOMER_PRODUCTS_IN_ACTIVE":"Das Abonnement ist inaktiv",
    "CUSTOMER_PRODUCTS_LABEL_OWNED_BY":"Gehört",
    "CUSTOMER_PRODUCTS_LINKED_SUBSCRIPTION":"Verknüpftes Abonnement",
    "CUSTOMER_PRODUCTS_LIST_STATUS":"Status",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DEPARTMENT":"Abteilung",
    "CUSTOMER_PRODUCTS_LIST_TITLE_DOMAIN":"Domain",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SITE":"Seite ",
    "CUSTOMER_PRODUCTS_LIST_TITLE_SUBSCRIPTION_ID":"Abonnement-ID",
    "CUSTOMER_PRODUCTS_LIST_TITLE_TENANT":"Mieter",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "CUSTOMER_PRODUCTS_MANAGE_BUTTON_TEXT_CANCEL_SUBSCRIPTON":"Abonnement kündigen",
    "CUSTOMER_PRODUCTS_MANAGE_LICENSE_SUPPORTED_VOUCHER_COPIED_SUCCESSFULLY":"Gutschein in die Zwischenablage kopiert",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_BASIC_DETAILS":"Grundlegende Details",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_COMMENTS_DETAILS":"Bemerkungen",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_CONTACT_LOGS":"Benachrichtigungen",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_MANAGE_OWNERSHIP":"Eigentümer verwalten",
    "CUSTOMER_PRODUCTS_MANAGE_NAVBAR_TITLE_USERS_TRACKING":"Benutzer",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_CLOSE":"Schließen",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_BUTTON_TEXT_COPY_LINK":"Link kopieren",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_COPY_LINK":"Link kopieren",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_DOWNLOAD":"Herunterladen",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_LINK_TEXT_GET_KEYS":"Holen Sie sich Schlüssel und Downloads",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_NOTIFICATION_TEXT_COPIED_SUCCESSFULLY":"Link in die Zwischenablage kopiert",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_FILETYPE":"Wählen Sie den Dateityp",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_LANGUAGE":"Sprache auswählen",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_PLACEHOLDER_SELECT_PRODUCT":"Ausgewähltes Produkt",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ACTIONS":"Aktionen",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_FILE_SIZE":"Dateigröße",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_ID":"Ich würde",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_KEY":"Schlüssel",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_NAME":"Name",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TABLE_HEADING_QUANTITY":"Menge",
    "CUSTOMER_PRODUCTS_MANAGE_SOFTWARE_SUBSCRIPTIONS_TITLE_ENTITLEMENTS":"Ansprüche",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_CANCELLATION_DATE":"Stornierungsdatum",
    "CUSTOMER_PRODUCTS_MANAGE_SUBSCRIPTION_TRIAL_OFFER_LABEL_TEXT_RENEWAL_DATE":"Verlängerungsdatum",
    "CUSTOMER_PRODUCTS_NCE_PRODUCT_PROMOTION_APPLIED_TEXT":"Aktion angewendet",
    "CUSTOMER_PRODUCTS_ORDERED_ON":"Bestellt",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_MANAGE":"Verwalten",
    "CUSTOMER_PRODUCTS_PAGE_BUTTON_TEXT_PROCESSING":"wird bearbeitet",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_APPLY":"Anwenden",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLEAR":"klar",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_CLOSE":"Schließen",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_BUTTON_TEXT_INCLUDE_CHILD_ENTITIES":"Untergeordnete Entitäten einschließen",
    "CUSTOMER_PRODUCTS_SET_OPERATING_ENTITIES_FILTER_DOMAIN_NAME":"Domain",
    "CUSTOMER_PRODUCTS_STATUS_FILTER_OPTIONS":"Filteroptionen",
    "CUSTOMER_PRODUCTS_TABLE_BUTTON_TOOLTIP_TEXT_MANAGE_PRODUCT":"Produkt verwalten",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_AMOUNT":"Menge",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_ORDERD_ON":"Bestellt am",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_OWNED_BY":"Gehört",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_RENEWS_ON":"Erneuert weiter",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Gesamtmenge",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_AMOUNT":"Stückpreis",
    "CUSTOMER_PRODUCTS_TABLE_HEADER_TEXT_UNIT_PRICE":"Stückpreis",
    "CUSTOMER_PRODUCTS_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_DEPARTMENT":"Abteilung",
    "CUSTOMER_PRODUCTS_TILE_RIBBON_TEXT_SITE":"Seite ",
    "CUSTOMER_PRODUCTS_TITLE_SCHEDULED_RENEWAL":"Für dieses Abonnement sind geplante Änderungen vorhanden",
    "CUSTOMER_PRODUCTS_VALIDITY":"Gültigkeit",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_ASSIGNED_TO_MANAGER_ON":"Zugewiesen an Manager Ein",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_EMAIL":"Email",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_PHONENUMBER":"Telefonnummer",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_DETAILS_LABEL_TEXT_NAME":"Der Name des Managers",
    "CUSTOMER_PROFILE_ACCOUNT_MANAGER_TAB_HEADER_TEXT":"Account Manager Details",
    "CUSTOMER_PROFILE_BASIC_DETAIL_UPDATE_SUCCESS_NOTIFICATION":"Grundlegende Details erfolgreich aktualisiert",
    "CUSTOMER_PROFILE_CAPTION_TEXT_SHIPPING_ADDRESS":"Lieferanschrift",
    "CUSTOMER_PROFILE_HEADER_TEXT_CUSTOMER_PROFILE":"Kundenprofil",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_DEPARTMENTS":"Abteilungen",
    "CUSTOMER_PROFILE_ORGANIZATION_SETUP_TAB_SITES":"Websites",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_ADDRESSES":"Adresse hinzufügen",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_EMAIL":"E-Mail hinzufügen",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_ADD_Phone":"Telefon hinzufügen",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_PROFILE_PARTNR_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_ADDRESSES":"Adressen",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_EMAIL_ADDRESSES":"E-mailadressen",
    "CUSTOMER_PROFILE_PARTNR_HEADER_TEXT_PHONE_NUMBERS":"Telefonnummern",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_FIRST_NAME":"Vorname",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_LAST_NAME":"Nachname",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_NAME":"Name",
    "CUSTOMER_PROFILE_PARTNR_LABEL_TEXT_SITES":"Websites",
    "CUSTOMER_PROFILE_PARTNR_NO_RECORDS_FOUD_TEXT":"Keine Aufzeichnungen gefunden!",
    "CUSTOMER_PROFILE_PAYMENT_BUTTON_TEXT_ADD":"Hinzufügen",
    "CUSTOMER_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_PROFILES":"Zahlungsprofile",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_BANK_ACCOUNT":"Bankkonto",
    "CUSTOMER_PROFILE_PAYMENT_LABEL_TEXT_CREDIT_CARD":"Kreditkarte",
    "CUSTOMER_PROFILE_PAYMENT_NO_PROFILE_FOUND_MESSAGE":"Keine Profile gefunden. Fügen Sie ein neues Zahlungsprofil hinzu, indem Sie auf die Schaltfläche HINZUFÜGEN klicken",
    "CUSTOMER_PROFILE_PAYMENT_PROFILE_PENDING_MESSAGE":"Zahlungsprofil wird hinzugefügt. Bitte warten Sie einen Moment, während der Vorgang abgeschlossen ist.",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_SHIPPING_ADDRESS":"Lieferanschrift",
    "CUSTOMER_PROFILE_PROVIDER_HEADER_TEXT_USER_ACCOUNTS":"Benutzerkonten",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_ACCOUNT_CREATION_DATE":"Erstellungsdatum des Kontos",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_Billing_ADDRESS":"Rechnungsadresse",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_C3_ONBOARDING_DATE":"C3 Onboarding-Datum",
    "CUSTOMER_PROFILE_PROVIDER_LABEL_TEXT_CANONICAL_NAME":"Eindeutige Bezeichnung",
    "CUSTOMER_PUBLIC_SIGHNUP_PLAN_NAME_ALERT":"Vielen Dank für Ihre Wahl",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_1":"Kontoeinrichtung",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_2":"Shop",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_3":"Bestellung überprüfen",
    "CUSTOMER_PUBLIC_SIGHNUP_STEP_TITLE_TEXT_4":"Bestätigen",
    "CUSTOMER_PUBLIC_SIGN_UP_BUTTON_TEXT_DONE":"Getan",
    "CUSTOMER_PUBLIC_SIGN_UP_EMAIL_VALIDATION_ERROR_MESSAGE":"Die E-Mail-Adresse ist bereits vergeben. Bitte melden Sie sich mit Ihren Zugangsdaten bei C3 an oder erstellen Sie ein neues Konto mit einer anderen E-Mail-Adresse",
    "CUSTOMER_PUBLIC_SIGN_UP_FORM_VALIDATION_FAILED_MESSAGE":"Bitte füllen Sie die erforderlichen Felder aus, um fortzufahren",
    "CUSTOMER_PUBLIC_SIGN_UP_REQUEST_PLACED_SUCCESSFULLY":"Die Anmeldeanfrage wurde erfolgreich platziert",
    "CUSTOMER_PUBLIC_SIGN_UP_SUCCESS_MESSAGE":"Ihre Daten wurden erfolgreich übermittelt. Wir werden uns in Kürze mit Ihnen in Verbindung setzen",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_CUSTOMER_DETAILS":"einreichen",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_BUTTON_TEXT_SHOP_PRODUCTS":"Produkte einkaufen",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_CONSENT_ACCEPTANCE_MUST_BE_PROVIDER":"Die Annahme der Anbieter-Kunden-Vereinbarung sollte bestätigt werden",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_EXIST":"existiert",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_EXISTING_TENANT_FORM_MESSAGE_IS_NOT_EXIST":"ist nicht vorhanden",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_COUNTRY_DROPDOWN_DEFAULT_OPTION":"Land auswählen",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_HEADER_TEXT_PRIMARY_CONTACT":"Hauptansprechpartner",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_1":"Adresse 1",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ADDRESS_2":"Adresse 2",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_CITY":"Stadt",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_COUNTRY":"Land",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_DOMAIN_NAME":"Domain Name",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_EMAIL":"Email",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_FIRST_NAME":"Vorname",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GLOBAL_ADMINS":"E-Mail-Adresse des globalen Administrators",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_GRANT_ACCESS_TO_THE_PRIMARY_CONTACT":"Zugriff auf den primären Kontakt gewähren (als Kundenadministrator)?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_LAST_NAME":"Nachname",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_MIDDLE_NAME":"Zweiter Vorname",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ORGANIZATION_REGISTRATION_NUMBER":"Registrierungsnummer der Organisation",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_PRIMARY_EMAIL_LOGIN_ACCESS":"Die bereitgestellte E-Mail hat den CustomerAdmin-Zugriff auf das Portal.",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_SEND_INVITATION_TO_ACCESS_THE_APPLICATION":"Einladung zum Zugriff auf die Anwendung senden?",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_STATE":"Zustand",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_TENANT_ID":"Mieter-ID",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_LABEL_TEXT_ZIP":"Postleitzahl",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_DOMAIN_IS_INVALID":"Dieser Domänenname ist ungültig",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_AVAILABLE":"ist verfügbar",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_IS_NOT_AVAILABLE":"ist nicht verfügbar",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_MESSAGE_VALIDATING_DOMAN":"Überprüfung der Domainverfügbarkeit",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_DROPDOWN_DEFAULT_OPTION":"Staat wählen",
    "CUSTOMER_PUBLIC_SIGNUP_DETAILS_FORM_STATE_TEXTBOX_DEFAULT_OPTION":"Staat eingeben",
    "CUSTOMER_PUBLIC_SIGNUP_DOMAIN_NAME_TOOLTIP":"Zur Pflege der Mandantendomänendetails",
    "CUSTOMER_PUBLIC_SIGNUP_EMAIL_TOOLTIP":"Um abrechnungsbezogene Dinge zu kommunizieren",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_1":"Bitte geben Sie unten die Details ein. Der Partner wird sich dann mit Ihnen in Verbindung setzen, um weitere Unterstützung zu leisten.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_2":"Klicken Sie auf „Link in die Zwischenablage kopieren“, um den Link der Reseller-Beziehung zu kopieren und dem Reseller-Modell zu folgen.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_3":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Reseller-Beziehung mit einem Konto ab, das ein globaler Administrator ist.<br>Geben Sie nach Abschluss die folgenden Details ein und klicken Sie auf „Senden“, nachdem der Partner mit Ihnen kommuniziert hat.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_4":"Wenn Sie die Reseller-Beziehung nicht akzeptieren möchten, geben Sie einfach die folgenden Details ein und klicken Sie auf „Senden“, nachdem dieser Partner mit Ihnen kommuniziert hat.",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_HEADING":"Anweisungen zur Annahme einer Reseller-Beziehung",
    "CUSTOMER_PUBLIC_SIGNUP_INSTRUCTION_RESELLER_RELATIONSHIP_HEADING":"Anweisungen zur Annahme einer Reseller-Beziehung",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_0":"In der Zwischenzeit können Sie den Partner als Ihren CSP-Reseller autorisieren, indem Sie über Ihren globalen Admin-Benutzer die Zustimmung zum Reseller-Beziehungslink erteilen.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_1":"Klicken Sie auf „Link in die Zwischenablage kopieren“.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_2":"um den Link der Reseller-Beziehung zu kopieren und dem Reseller-Modell zu folgen.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_3":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Reseller-Beziehung mit einem Konto ab, das ein globaler Administrator ist.<br>Geben Sie nach Abschluss die folgenden Details ein und klicken Sie auf „Senden“, woraufhin dieser Partner mit Ihnen kommuniziert.",
    "CUSTOMER_PUBLIC_SIGNUP_RESELLER_RELATIONSHIP_INSTRUCTION_4":"Wenn Sie die Reseller-Beziehung nicht akzeptieren möchten, geben Sie einfach die folgenden Details ein und klicken Sie auf „Senden“, nachdem dieser Partner mit Ihnen kommuniziert hat.",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_DETAILS_BATCH_ID_LABEL":"Anmelde-BatchId",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_ALERT_MESSSAGE":"Die Verarbeitung kann einige Zeit in Anspruch nehmen. Der Status wird auf dieser Seite angezeigt. Wenn Sie den Browser verlassen oder versehentlich schließen, informieren wir Sie per E-Mail.",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER":"Anmeldung läuft",
    "CUSTOMER_PUBLIC_SIGNUP_STATUS_PORTLET_HEADER_MESSSAGE":"Bitte warten Sie, während wir Ihr Konto einrichten. Sie können Ihr Gerät wie gewohnt weiter verwenden. Sobald die Einrichtung abgeschlossen ist, senden wir Ihnen eine E-Mail-Benachrichtigung.",
    "CUSTOMER_PUBLIC_SIGNUP_TENANT_ID_TOOLTIP":"Um die Mieterinformationen und Abonnements abzurufen",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CART":"Wagen",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_CUSTOMER_DETAILS":"Kundendetails",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_PAYMENT_DETAILS":"Zahlungsdetails",
    "CUSTOMER_PUBLIC_SIGNUP_WIZARD_HEADING_TEXT_SHOP_PRODUCTS":"Shop-Produkte",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_EXTERNAL_CUSTOMER_NAME":"Externer Kundenname",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_Action":"Aktion",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_PRODUCTS":"C3-Produkte",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_EXTERNAL_PRODUCTS":"Externes Produkt",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TABLE_HEADER_TEXT_SEQUENCE_NUMBER":"Sequenznummer",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_AGREEMENT":"Zustimmung",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CATEGORY_NAME":"Kategoriename",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CONTRACT":"Vertrag",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_CUSTOMER_NAME":"Kundenname",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_EXTERNAL_CUSTOMER_NAME":"PSA-Kundenname",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_HELP_TEXT_LINE_1":"Bitte verwenden Sie weitere Filter, wenn Sie zusätzliche Einschränkungen für Kategorie, Mieter usw. festlegen möchten.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT":"Ist Standardvertrag?",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_IS_DEFAULT_HELP_TEXT":"Standardverträge werden verwendet, um Vertragsservices hochzuladen, die mit Services verknüpft sind, die automatisch erstellt und zugeordnet werden.",
    "CUSTOMER_PURCHASED_PRODUCT_MAPPING_TEXT_SERVICE_PROVIDER_CUSTOMER":"Mieter",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_DEPARTMENT":"Abteilung :",
    "CUSTOMER_PURCHASED_PRODUCT_REPORT_TEXT_SITE":"Seite? ˅ :",
    "CUSTOMER_RECONCILIATION_REPORT":"Abstimmungsbericht",
    "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_1":"Diese Funktion ist für individuelle Angebote verfügbar und wird für eine Laufzeit von einem Jahr unterstützt.",
    "CUSTOMER_RENEWAL_CONSENT_INSTRUCTIONS_PART_2":"Das verknüpfte Abonnement wird von dieser Funktion nicht unterstützt.",
    "CUSTOMER_REPORT":"Kundenberichte",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER":"Filterprodukte",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_ORDERED_DATE":"Max. Bestelldatum",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MAX_QUANTITY":"Maximale Menge",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_ORDERED_DATE":"Mindestbestelldatum",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_PLACEHOLDER_MIN_QUANTITY":"Min. Menge",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_EXPIRING_IN_DAYS":"Läuft in Tagen ab",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_ORDERED_BETWEEN":"Bestellt zwischen",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_SEARCH_FILTER_TITLE_QUANTITY":"Menge",
    "CUSTOMER_REPORT_WITH_PURCHASED_PRODUCTS_TITLE":"Produktbericht",
    "CUSTOMER_REPORTS":"Berichte",
    "CUSTOMER_SAVEADJUSTMENT_SUCCESS":"Anpassung erfolgreich gespeichert.",
    "CUSTOMER_SAVECUSTOMERDUEASADJUSTMENT_SUCCESS":"Vergangene Gebühren als Anpassung an die aktuelle Rechnung hinzugefügt",
    "CUSTOMER_SAVEINVOICEPAYMENT_SUCCESS":"Zahlung erfolgreich erfasst",
    "CUSTOMER_SEARCH_BILLING_REFERENCE_ID":"Rechnungsreferenz-ID",
    "CUSTOMER_SEARCH_CLOSE_OPTION":"Filter schließen",
    "CUSTOMER_SEARCH_CONSIDER_DELETED_CUSTOMER":"Gelöschten Kunden einschließen",
    "CUSTOMER_SEARCH_CRM_OPPORTUNITY_ID":"CRM-Gelegenheits-ID",
    "CUSTOMER_SEARCH_CUSTOMER_DEFAULT_PAY_METHOD":"Standardzahlungsmethode",
    "CUSTOMER_SEARCH_CUSTOMER_name":"Name",
    "CUSTOMER_SEARCH_CUSTOMER_NAME":"Name",
    "CUSTOMER_SEARCH_CUSTOMER_PAY_METHOD":"Bezahlverfahren",
    "CUSTOMER_SEARCH_CUSTOMER_PLAN":"Planen",
    "CUSTOMER_SEARCH_CUSTOMER_TAGS":"Stichworte",
    "CUSTOMER_SEARCH_DEFAULT_OPTION":"Wählen",
    "CUSTOMER_SEARCH_DOMAIN_NAME":"Domain Name",
    "CUSTOMER_SEARCH_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
    "CUSTOMER_SEARCH_FILTER":"Kunden filtern",
    "CUSTOMER_SEARCH_INVOICE_NOTES":"Rechnungsnotizen",
    "CUSTOMER_SEARCH_MARKET":"Marktcode",
    "CUSTOMER_SEARCH_PROVIDER":"Anbieter",
    "CUSTOMER_SEARCH_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "CUSTOMER_SEARCH_RESET_OPTION":"Zurücksetzen",
    "CUSTOMER_SEARCH_SEARCH_OPTION":"Suche",
    "CUSTOMER_SEARCH_UNIQUE_IDENTIFIER":"Eindeutige Kennung",
    "CUSTOMER_SHOP_ADDON_POPUP_BUTTON_TEXT_ADD_TO_CART":"in den Warenkorb legen",
    "CUSTOMER_SHOP_ADDON_POPUP_CHECKBOX_TEXT_SELECT_ALL_ADDONS":"Wählen Sie Alle Add-Ons",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_SHOP_ADDON_POPUP_LABEL_TEXT_LINKED_SUBSCRIPTION_NAME":"Verknüpftes Abonnement",
    "CUSTOMER_SHOP_CONTRACT_OFFER_DETAILS_POPUP_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "CUSTOMER_SHOP_FILTER_CLOSE_BUTTON_TEXT":"Schließen",
    "CUSTOMER_SHOP_HEADER_TEXT_SHOP":"Shop",
    "CUSTOMER_SHOP_INFO_TEXT_OPERATION_ENTITY":"Zum Filtern oder Einschließen von Produkten aus der Kinderebene",
    "CUSTOMER_SHOP_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_BILLING_TYPES":"Abrechnungsarten",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY":"Gültigkeit",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TERM_DURATION_VALIDITY_TYPE":"Gültigkeitstyp",
    "CUSTOMER_SHOP_SIDEBAR_FILTER_TEXT_TRIAL_DURATION":"TESTDAUER",
    "CUSTOMER_SHOP_SUP_TEXT_ADD_ON":"Erweiterung",
    "CUSTOMER_SHOP_SUP_TEXT_TRIAL_OFFER":"TESTANGEBOT",
    "CUSTOMER_SHOP_TAB_FILTER_LABEL_TEXT":"Filter",
    "CUSTOMER_SIGNUP_FAILED":"Kundenregistrierung fehlgeschlagen",
    "CUSTOMER_SIGNUP_SUCCESS":"Erfolg bei der Kundenanmeldung",
    "CUSTOMER_SIGNUP_SUCCESS_MESSAGE":"Anmeldung erfolgreich. Sie können sich beim C3-Portal mit Zugangsdaten anmelden, die an folgende Adresse gesendet werden:",
    "CUSTOMER_SITE_ADD_SITE":"Site hinzufügen",
    "CUSTOMER_SITE_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUSTOMER_SITE_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUSTOMER_SITE_DELETE_SUCCESS_NOTIFICATION":"Site erfolgreich gelöscht",
    "CUSTOMER_SITE_EDIT_SITE":"Site bearbeiten",
    "CUSTOMER_SITE_HEADER_TEXT":"Websites",
    "CUSTOMER_SITE_SAVE_SUCCESS_NOTIFICATION":"Seite erfolgreich gespeichert",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "CUSTOMER_SITE_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMER_SUBSCRIPTION_ERROR_POPUP_SUPPORT_TEXT_CONTACT_SUPPORT":"Das Ignorieren von Fehlern ist für dieses Abonnement nicht möglich. Bitte wenden Sie sich für weitere Unterstützung an den C3-Support.",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_AZURE_PLAN":"Azure-Plan",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_ENTITLEMENTS":"Ansprüche",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_AZURE_PLAN":"Wählen Sie Azure Plan.",
    "CUSTOMER_SUBSCRIPTION_LABEL_TEXT_SELECT_ENTITLEMENTS":"Wählen Sie Berechtigungen.",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP":"Sind Sie sicher, dass Sie fortfahren möchten?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_CONFIRM_ACTION_POPUP_CUSTOMER_PROMOTION":"Angebot anwenden Für Kunden?",
    "CUSTOMER_SUBSCRIPTION_MANUAL_SYNC_RI_PRICE_CONFIRMATION_FOR_SAME_ROID":" Der Preis darf nicht anders sein als <strong>{{price}}</strong>, da für diese Reservierungsbestell-ID {{reservationorderID}} bereits ein Reservierungsprodukt mit Preis vorhanden ist.<br>Möchten Sie mit dem Preis <strong>{{preis} synchronisieren? </strong> ?",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_ACCEPT_TEXT":"Akzeptieren",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_BUTTON_REJECT_TEXT":"Ablehnen",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_ACTION_TEXT":"Aktionen",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BACK_TO_PRODUCT_BTN":"Zurück zu den Produkten",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_BTN":"Erneuerungsmanager",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HEADER":"Erneuerungsmanager",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_ACTION_TEXT":"Aktion",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BACK_TO_RENEWAL_MNG_BTN":"Zurück zum Erneuerungsmanager",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_BTN":"Verlauf des Erneuerungsmanagers",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_CREATE_DATE_TEXT":"Aktualisiert am",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_DOWNLOAD_BTN_TOOLTIP":"Verlauf des Erneuerungsmanagers herunterladen",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_HEADER":"Verlauf des Renewal Managers",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRICE_TEXT":"Preis",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_PRODUCT_NAME_TEXT":"Produktname",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_QUANTITY_TEXT":"Menge",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_HISTORY_USER_NAME_TEXT":"Nutzername",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRICE_TEXT":"Preis",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_PRODUCT_TEXT":"Produkte",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_QUANTITY_TEXT":"Menge",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_RENEWAL_DATE_TEXT":"Erneuert am",
    "CUSTOMER_SUBSCRIPTION_RENEWAL_CONSENT_SUBSCRIPTION_NAME_TEXT":"Name",
    "CUSTOMER_SUBSCRIPTION_SELECT_ATLEAST_ONE_FILE":"Bitte wählen Sie mindestens eine Datei aus.",
    "CUSTOMER_SUBSCRIPTION_UPLOAD_FILE_SUCCESS":"Datei erfolgreich hochladen",
    "CUSTOMER_SUBSCRIPTIONS_APPROVE":"Genehmigen",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_LOADING_THE_RECONCILATION_REPORT":"Laden des Abstimmungsberichts",
    "CUSTOMER_SUBSCRIPTIONS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_BILLING_DETAILS":"Neuladen der Kunden Rechnungsdetails...",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_CUSTOMER":"Kunden hinzufügen",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Benutzer zum Abonnement hinzufügen",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USERS":"Zurück zu Abonnementbenutzern",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK":"Bestehend (mehrfach)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_BULK_ONBOARDING_CUSTOMER":"Massenkunden-Onboarding",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING":"Bestehende (Single)",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXISTING_CUSTOMER":"Bestehender Kunde",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_BILLING_DETAILS":"Export Billing Summary",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Nutzungsdetails exportieren",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_GO_TO_REPORT":"Zum Bericht gehen",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_HERE":"Hier",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW":"Neu",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_NEW_CUSTOMER":"Neukunde",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_ONBOARD_CUSTOMER":"Kunden an Bord",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RECONCILATION_REPORT":"Abstimmungsbericht",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_BILLING_DETAILS":"Rechnungsdetails neu laden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_CURRENT_MONTH":"Aktuellen Monat neu laden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_DETAILS_DROP_DOWN_FULL":"Voll neu laden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonnementzusammenfassung neu laden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_RELOAD_USAGE_DETAILS":"Nutzungsdetails neu laden",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_REMOVE":"Entfernen",
    "CUSTOMER_SUBSCRIPTIONS_BUTTON_TEXT_SELECT":"Wählen",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_CUSTOMER":"Kunden hinzufügen",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_ADD_SUBSCRIPTIONS_FOR":"Abonnements hinzufügen für",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_AZURE_SUBSCRIPTIONS_OF":"Azure-Abonnements von",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_CUSTOMERS":"Kunden",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonnementbenutzer verwalten",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_OFFERS":"Bietet an",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_PLAN_AND_OFFERS":"Planen & Angebote",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_RECONCILIATION_REPORT_FOR":"Abstimmungsbericht für",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonnementbenutzer von",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_OF":"Kundenkonfiguration von",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_TENANT_CONFIGURATION_VALUE":"Wert",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USAGE_DETAILS_OF":"Verwendungsdetails von",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_USERS_OF":"Benutzer von",
    "CUSTOMER_SUBSCRIPTIONS_CAPTION_TEXT_VALIDATE_ONBOARDING_ERRORS_FOR":"Überprüfen Sie Onboarding-Fehler für",
    "CUSTOMER_SUBSCRIPTIONS_FINALIZE":"Finalisieren",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING":"Abrechnung",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_COMPANY_PROFILE":"Firmenprofil",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PAYMENT_TYPE":"Zahlungsart",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
    "CUSTOMER_SUBSCRIPTIONS_HEADER_TEXT_PRIMARY_CONTACT":"Hauptansprechpartner",
    "CUSTOMER_SUBSCRIPTIONS_IMAGE_ALT_TEXT_LOGO":"Logo",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED":"Rechnung wurde genehmigt!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_APPROVED_2":"Sie können dieser Rechnung noch Anpassungen (zusätzliche Gebühren oder Gutschriften) hinzufügen, bevor diese abgeschlossen ist und der Kunde belastet wird. Der Kunde kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen, sobald sie abgeschlossen ist.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_1":"Rechnung wurde abgeschlossen!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_FINALIZED_2":"Der Benutzer kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen.",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_1":"Rechnung wurde erstellt!",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_GRANTED_2":"Sie können Anpassungen (zusätzliche Gebühren oder Gutschriften) zu dieser Rechnung hinzufügen, bevor diese abgeschlossen ist und der Kunde belastet wird",
    "CUSTOMER_SUBSCRIPTIONS_INVOICE_TEXT_LINE":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Danke dir.",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY":"Autopay",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_OFF":"Auto-Pay: AUS",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_AUTO_PAY_ON":"Automatische Zahlung: EIN",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_BILLING":"Abrechnung",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_ORGANIZATION_IIN":"Organisation IIN",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_PAYMENT_METHOD":"Bezahlverfahren",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_BANK_NAME":"Bank Name",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CITY":"Stadt",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_CVV":"CVV",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_DANGER_THRESHOLD":"Gefahrenschwelle",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_EMAIL":"Email",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ERROR_THRESHOLD":"Fehlerschwelle",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_FIRST_NAME":"Vorname",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_GROUP_NAME":"Gruppenname",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_INFO_THRESHOLD":"Info-Schwelle",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_IS_MANAGED":"Vor Kunden verstecken",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ITEMS_SELECTED":"Ausgewählte Elemente",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LAST_NAME":"Nachname",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_1":"Linie 1",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_LINE_2":"Zeile 2",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTH":"Monat",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_MONTHLY_BUDGET":"Monatliches Budget",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PLAN":"Planen",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROLE":"Rolle",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_STATE":"Zustand",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_WARN_THRESHOLD":"Warnschwelle",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_YEAR":"Jahr",
    "CUSTOMER_SUBSCRIPTIONS_LABEL_TEXT_ZIP":"Postleitzahl",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_PLANS":"Pläne",
    "CUSTOMER_SUBSCRIPTIONS_PLACEHOLDER_QUANTITY":"Menge",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_ACH":"ACH",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_INVOICE":"Manuelle",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_NO":"Nein",
    "CUSTOMER_SUBSCRIPTIONS_RADIO_BUTTON_TEXT_YES":"Ja",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_INACTIVE_SUBSCRIPTION":"Inaktives Abonnement",
    "CUSTOMER_SUBSCRIPTIONS_SPAN_TEXT_QUANTITY_MISMATCH":"Mengeninkongruenz",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_DELETE_USER":"Zugriff entfernen",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_ENABLE_SUBSCRIPTION":"Abonnement aktivieren",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_USERS":"Benutzer",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ACTIVE_IN_CSP":"Aktiv in CSP",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_COMPONENT_NAME":"Komponentenname",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL":"Email",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_EMAIL_ID":"E-Mail-ID",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ENTITLEMENTS":"Ansprüche",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ERROR":"Error",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ESTIMATED_COST":"Geschätzte Kosten",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED":"Vor Kunden verstecken",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_IS_MANAGED_TEXT":"Abonnements sind für Kunden nicht sichtbar",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_LAST_REFRESHED_ON":"Zuletzt aktualisiert",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_MSFT_INVOICE":"MSFT-Rechnung",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ONBOARDING_STATUS":"Onboarding-Status",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PRICE":"Preis",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_PROFIT":"Profitieren",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_ROLE":"Rolle",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_THRESHOLDS":"Schwellenwerte",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER":"Benutzer",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_HEADER_TEXT_USER_NAME":"Nutzername",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_MESSAGE_NO_SUBSCRIPTIONS_ADDED_YET":"Es wurden noch keine Abonnements hinzugefügt.",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ASSIGN_CUSTOMER_PLAN":"Kundenplan zuweisen",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_BILLINGCYCLE_CHANGES":"Abrechnungszyklus ändern",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CHANGE_NAME":"Namen ändern",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAGS":"Tags definieren",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_DEFINE_TAX":"Steuern definieren%",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_ACTIVATION_STATE":"Behebung der Nichtübereinstimmung des Aktivierungsstatus im Partner Center",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_AUTO_RENEW_STATE":"Korrigieren Sie den Zustandskonflikt bei der automatischen Verlängerung im Partner Center",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_OFFER_NOT_FOUND_ISSUE":"Fehlanpassung beheben...",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_FIX_QUANTITY_MISMATCH":"Fehlanpassung beheben",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_OFFER_CHANGES":"Angebot ändern",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PAYMENT_PROFILES":"Zahlungsanbieter",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PERPETUAL_STATUS_FIX":"Aktualisieren Sie den unbefristeten Produktstatus",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PLAN_CHANGE":"Plan ändern",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROMOTION_FIX":"Förderung fixieren",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_PROVIDERS":"Anbieter",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Kunde: Schneller Identitätswechsel",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECONCILIATION_REPORT":"Abstimmungsbericht",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_RELOAD_SUBSCRIPTION_SUMMARY":"Abonnementzusammenfassung neu laden",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_SCOPE_FIX":"Umfang korrigieren",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TENANT_CONFIGURATION":"Kundenkonfiguration",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TERM_CHANGES":"Begriff ändern",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_TEST_AUTO_PAYMENTS":"Testen Sie die automatische Zahlung",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_USERS":"Benutzer",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_VALIDATION_FAILURES":"Validierungsfehler",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_CUSTOM":"BENUTZERDEFINIERT",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TD_TEXT_TOTAL":"Gesamt",
    "CUSTOMER_SUBSCRIPTIONS_TABLE_TOOLTIP_MONTHLY_BUDGET_INFO_WARN_ERROR_DANGER":"Monatliche Budget-Info-Warn-Error-Danger",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_AVAILABLE":"ist verfügbar",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_IS_NOT_AVAILABLE":"ist nicht verfügbar",
    "CUSTOMER_SUBSCRIPTIONS_TEXT_PLEASE_DOWNLOAD_THE_TEMPLATE_FROM":"Bitte laden Sie die Vorlage von herunter",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_DELETE_USER":"Benutzer löschen",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_GRANT_ACCESS_TO_THE_PORTAL":"Gewähren Sie Zugriff auf das Portal",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_IMPERSONATE":"Imitieren",
    "CUSTOMER_SUBSCRIPTIONS_TOOLTIP_REVOKE_ACCESS_FROM_THE_PORTAL":"Zugriff über das Portal widerrufen",
    "CUSTOMER_SUBSCRIPTIONS_VALIDATION_MESSAGE_VALUE_SHOULD_BE_BETWEEN_0_AND_100":"Der Wert sollte zwischen 0 und 100 liegen",
    "CUSTOMER_SUP_TEXT_DELETED":"(gelöscht)",
    "CUSTOMER_SYNC_PROFILES_TOOLTIP":"Profile synchronisieren",
    "CUSTOMER_TAGS_EXAMPLE":"Definieren Sie Tag- und Wertepaare für den Kunden, die beim Definieren der Zugriffssteuerung für Benutzer verwendet werden sollen. <br/> Beispiel: Sie können ein Tag mit dem Namen SupportAgent und einen Spitznamen eines Agenten (z. B. Agent001) als Wert definieren. Wenn für einen Helpdesk-Agenten eines der Tags als SupportAgent und einer der Werte für dieses bestimmte Tag als Agent001 festgelegt ist, kann der Agent die Kunden sehen, für die das Tag-Wert-Paar {SupportAgent, Agent001} festgelegt ist.",
    "CUSTOMER_TAGS_LABLE_TEXT_NAME":"Name",
    "CUSTOMER_TAGS_LABLE_TEXT_VALUE":"Wert",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Löschen",
    "CUSTOMER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMER_TAGS_TABLE_HEADER_TEXT_VALUE":"Wert",
    "CUSTOMER_TERM_ENDDATE_SPECIFIED_ON_SCHEDULED_NEXT_TERM_IS_ONLY_VALID_FOR_ONLINESERVICENCE_PRODUCTS":"Das benutzerdefinierte Enddatum der Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, gilt nur für OnlineServicesNCE-Produkte.",
    "CUSTOMER_TEST_PAYMENT_BUTTON_TEXT_VIEW_PENDING_INVOICES":"Ausstehende Rechnungen anzeigen",
    "CUSTOMER_TEST_PAYMENT_HEADER_TEXT_PAYMENTS_FOR":"Zahlungen für",
    "CUSTOMER_TOOLTIP_CURRENCY_CONVERSION":"Kunde",
    "CUSTOMER_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Kunde",
    "CUSTOMER_USER_DETAIL_SAVED_SUCCESSFULLY_MESSAGE":"Benutzerdaten erfolgreich gespeichert",
    "CUSTOMER_USER_LICENSE_REPORT_SUCCESS_NOTIFICATION":"Der Bericht zur Benutzerlizenzzuweisung wird an die angegebene E-Mail-Adresse gesendet.",
    "CUSTOMER_USERS_BUTTON_ADD_NEW_USER":"Neuen Benutzer hinzufügen",
    "CUSTOMER_USERS_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_GRANT_ACCESS":"Zugriff gewähren",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_MANAGE_USER":"Benutzer verwalten",
    "CUSTOMER_USERS_BUTTON_TOOLTIP_TEXT_REVOKE_ACCESS":"Zugriffsrechte entziehen",
    "CUSTOMER_USERS_FORM_LABEL_DEPARTMENT":"Abteilung",
    "CUSTOMER_USERS_FORM_LABEL_EMAIL_ADDRESS":"E-Mail-Addresse",
    "CUSTOMER_USERS_FORM_LABEL_ROLE":"Rolle",
    "CUSTOMER_USERS_FORM_LABEL_ROLE_TYPE":"Rollentyp",
    "CUSTOMER_USERS_FORM_LABEL_SITE":"Seite ",
    "CUSTOMER_USERS_HEADING":"Benutzer",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_EMAIL_ADDRESS":"E-Mail-Addresse",
    "CUSTOMER_USERS_TABLE_HEADER_TEXT_ROLE":"Rolle",
    "CUSTOMER_VIEW_BUTTON_DOWNLOAD_INVOICES":"Laden Sie Rechnungen/Zahlungen herunter",
    "customerconfiguration":"Kundenkonfiguration",
    "CUSTOMERONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Bitte geben Sie an, ob sich die ACH-Details auf ein Geschäftskonto beziehen",
    "CUSTOMERONBOARDING_ADDUSERTOLICENSEASSIGNLIST_3":"Dieser Kunde wurde bereits ausgewählt",
    "CUSTOMERONBOARDING_CUSTOMER_ALREADY_EXISTS_ERROR":"Der Kunde ist bereits im System vorhanden",
    "CUSTOMERONBOARDING_CUSTOMER_CREATED_SUCCESS":"Kunde erfolgreich erstellt",
    "CUSTOMERONBOARDING_CUSTOMER_ONBAORDING_IN_PROGRESS":"Der Onboarding-Prozess des Kunden ist im Gange",
    "CUSTOMERONBOARDING_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie mindestens eine Datei aus.",
    "CUSTOMERONBOARDING_SUCCESS":"Kunde erfolgreich eingebunden.",
    "CUSTOMERONBOARDING_UPLOAD_LICENSES_ERROR":"Beim Hochladen der Datei ist ein Fehler aufgetreten",
    "CUSTOMERONBOARDING_USER_ALREADY_HAS_LICENSE_ERROR":"{username} hat bereits eine Lizenz für {offername}",
    "CUSTOMERONBOARDING_USERS_EXCEEDING_AVAILABLE_LICENSES_ERROR":"Die Anzahl der bereitgestellten Benutzer ist höher als die Anzahl der verfügbaren Lizenzen. Bitte korrigieren Sie die Benutzerdetails und versuchen Sie es erneut.",
    "customers":"Kunden",
    "Customers":"Kunden",
    "CUSTOMERS_ACTIONS_BUTTON_TEXT":"Aktionen",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_BUSINESS":"Unternehmen",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_CUSTOMERS":"Kunden",
    "CUSTOMERS_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
    "CUSTOMERS_BTN_TEXT_LINK_TO_BILLING_PROFILE":"Link zum Abrechnungsprofil",
    "CUSTOMERS_BTN_TEXT_LINK_TO_PROVIDER":"Link zum Anbieter",
    "CUSTOMERS_BTN_TEXT_REMOVE_LINK_TO_BILLING_PROFILE":"Link zum Abrechnungsprofil entfernen",
    "CUSTOMERS_BUSY_MESSAGE_CREATING_INVOICES":"Rechnungen erstellen…",
    "CUSTOMERS_BUSY_MESSAGE_RELOADING_THE_CUSTOMERS_PROFITABILITY_REPORT":"Neuladen des Kundenprofitabilitätsberichts...",
    "CUSTOMERS_BUTTON_BACK_TO_INVOICES":"Zurück zu Rechnungen",
    "CUSTOMERS_BUTTON_TEXT_ADD_ADJUSTMENT":"Anpassung hinzufügen",
    "CUSTOMERS_BUTTON_TEXT_ADD_THE_DUE_AS_ADJUSTMENT":"Fügen Sie den fälligen Betrag als Anpassung hinzu!",
    "CUSTOMERS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
    "CUSTOMERS_BUTTON_TEXT_DOWNLOAD_INVOICE":"Download Rechnung",
    "CUSTOMERS_BUTTON_TEXT_GO_TO_REPORT":"Zum Bericht gehen",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_DATE":"Verlauf nach Datum",
    "CUSTOMERS_BUTTON_TEXT_HISTORY_BY_OFFER":"Geschichte durch Angebot",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_AUTO_PAYMENT":"Zahlung einleiten",
    "CUSTOMERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Testgebühr Kunde",
    "CUSTOMERS_CAPTION_TEXT_BETA":"BETA",
    "CUSTOMERS_CAPTION_TEXT_INVOICE_FOR":"Rechnung für",
    "CUSTOMERS_CAPTION_TEXT_PROFITABILITY_BY_SUBSCRIPTIONS_FOR":"Rentabilität durch Abonnements für",
    "CUSTOMERS_CAPTION_TEXT_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
    "CUSTOMERS_CAPTION_TEXT_SUBSCRIPTION_CHANGE_HISTORY_FOR":"Abonnementänderungsverlauf für",
    "CUSTOMERS_DOMAIN_CHECK_ERROR":"Die Domainverfügbarkeit kann nicht überprüft werden",
    "CUSTOMERS_DOMAIN_NAME_INVALID_PROMPT":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche",
    "CUSTOMERS_GRANT_ACCESS_CONFIRM":"Möchten Sie wirklich Zugriff auf {{username}} gewähren?",
    "CUSTOMERS_HEADER_TEXT_CUSTOMERS":"Kunden",
    "CUSTOMERS_INVOICE_MESSAGE_NOT_FOUNT":"Keine Rechnungen gefunden. Bitte wenden Sie sich an den Support.",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_ADRESS":"Adresse",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER":"Kunde",
    "CUSTOMERS_INVOICEDETAILS_ACCOUNT_SUMMARY_TABLE_HEADER_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_ACCOUNT_SUMMARY":"Kontoübersicht",
    "CUSTOMERS_INVOICEDETAILS_HEADER_TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DATE":"Rechnungsdatum",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_GENERATED_DATE":"Rechnung generiert am",
    "CUSTOMERS_INVOICEDETAILS_INVOICE_SUMMARY_TABLE_HEADER_TEXT_INVOICE_STATUS":"Rechnungsstatus",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_1":"Rechnung wurde erstellt!",
    "CUSTOMERS_INVOICEDETAILS_LINE_1_PART_2":"Sie können dieser Rechnung Anpassungen (zusätzliche Gebühren oder Gutschriften) hinzufügen, bevor diese verarbeitet werden und der Kunde belastet wird",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_1":"Rechnung wurde bearbeitet!",
    "CUSTOMERS_INVOICEDETAILS_LINE_2_PART_2":"Der Kunde kann diese Rechnung jetzt sehen und Sie können Zahlungen für diese Rechnung erfassen.",
    "CUSTOMERS_INVOICEDUE_HEADER_TEXT_DUE_FROM_PAST_INVOICE":"Gebühren aus früheren Rechnungen",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_1":"Es sieht so aus, als ob es Gebühren aus früheren Rechnungen gibt. Wenn Sie sicher sind, dass Sie alle Zahlungen des Kunden erfasst haben, klicken Sie auf die folgende Schaltfläche, um",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_1_PART_2":"Hinzufügen von <strong> {{InvoiceDueAmount}}</strong> als Anpassung zur aktuellen Rechnung.",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_2":"Es sieht so aus, als ob es Gebühren gibt",
    "CUSTOMERS_INVOICEDUE_PARAGRAPH_3":"insgesamt.",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_1_EDIT":"Bearbeiten",
    "CUSTOMERS_INVOICELINEITEMS_BUTTON_2_DELETE":"Löschen",
    "CUSTOMERS_INVOICELINEITEMS_HEADER_INVOICE_DETAIL":"Rechnungsdetail",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_LICENSE_BASED":"Lizenzbasiert",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_COLUMN_USAGE_BASED":"Nutzungsabhängig",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_AMOUNT":"Menge",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_CHARGE_TYPE":"Ladungsart",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DESCRIPTION":"Beschreibung",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Rabattbetrag",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Angebotspreis",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Menge",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_END_DATE":"Endtermin",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Großer Betrag",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Zwischensummenbetrag",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_START_DATE":"Anfangsdatum",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_SUBSCRIPTIN_NAME":"Abonnementname",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_AMOUNT":"Steuerbetrag",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Steuerprozentsatz",
    "CUSTOMERS_INVOICELINEITEMS_TABLE_HEADER_UNIT_PRICE":"Stückpreis",
    "CUSTOMERS_INVOICELINEITEMS_TITLE_TEXT":"Rechnungspositionen",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_BUTTON":"Rekordzahlung",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_1_NUMBER_PLACEHOLDER_PAYMENTAMOUNT":"Zahlungsbetrag",
    "CUSTOMERS_INVOICEPAYMENTS_FORM_TEXTBOX_2_TEXT_PLACEHOLDER_REMARKS":"Bemerkungen",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_FAILURE_REASON":"Fehlergrund",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAID_AMOUNT":"Bezahlte Menge",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENT_ID":"Zahlungs-ID",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_PAYMENTS":"Rechnungszahlungen",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_REMARKS":"Bemerkungen",
    "CUSTOMERS_INVOICEPAYMENTS_HEADER_TEXT_INVOICE_TRANSACTION_ID":"Transaktions-ID",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_1":"Erfassen Sie die Zahlungen auf dieser Rechnung.",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_2":"z.B:",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_3":"Teilzahlung als Rate usw.",
    "CUSTOMERS_INVOICEPAYMENTS_PARAGRAPH_1_PART_4":"Wenden Sie sich an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder irrtümlich erfasst wurden",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_CC_PROCESSING_FEE":"CC-Bearbeitungsgebühr ({{CCFeePercentage}} % von {{CCPaidAmount}})",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_GRAND_TOTAL":"Gesamtsumme",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_COLUMN_SUB_TOTAL":"Zwischensumme",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_FAILURE_REASON":"Fehlergrund",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_AMOUNT":"Menge",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_DATE":"Datum",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_HEADER_REMARKS":"Bemerkungen",
    "CUSTOMERS_INVOICEPAYMENTS_TABLE_STATUS":"Zahlungsstatus",
    "CUSTOMERS_LOAD_BILLING_HISTORY_SUCCESS":"Rechnungsdetails einschließlich Verlauf werden erfolgreich aktualisiert",
    "CUSTOMERS_LOAD_USAGE_HISTORY_SUCCESS":"Verwendungsdetails einschließlich des Verlaufs werden erfolgreich aktualisiert",
    "CUSTOMERS_ONBOARDED_BY_DURATION_BILLING_EMAIL":"Abrechnung per E-Mail",
    "CUSTOMERS_ONBOARDED_BY_DURATION_ONBOARDED_DATE":"Onboarded On",
    "CUSTOMERS_ONBOARDED_BY_DURATION_PLAN_NAME":"Pläne",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_C3ID":"C3Id",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADER_NAME":"Name",
    "CUSTOMERS_ONBOARDED_BY_DURATION_TABLE_HEADING_TEXT":"Kunden an Bord",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_INPROGRESS":"Suchen Sie nach einem passenden Datensatz im Abrechnungssystem",
    "CUSTOMERS_ONBOARDING_LOOKUP_BILLING_SUCCESS":"Es wurde eine Übereinstimmung mit der Referenz-ID {{ID}} im Abrechnungssystem gefunden.",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_INPROGRESS":"Suchen von Kunden in C3 nach einer Übereinstimmung anhand der Provider-Kunden-ID",
    "CUSTOMERS_ONBOARDING_LOOKUP_LOCAL_SUCCESS":"Einen Kunden mit dem Namen {{customername}} gefunden",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_INPROGRESS":"Suchen von Kunden in {{providerName}} nach einer Übereinstimmung mit der Provider-Kunden-ID",
    "CUSTOMERS_ONBOARDING_LOOKUP_PC_SUCCESS":"Einen Kunden mit dem Namen {{customername}} in {{providerName}} gefunden",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_COMPOSITE_CHILDREN_QUANTITY_ERROR":"Die Mengen für Kinderangebote in einem Komposit sollten gleich sein",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_DUPLICATE_CSP_MAPPED_TO_BILLING_ERROR":"Ein oder mehrere Angebote in CSP werden demselben Angebot in der Abrechnung zugeordnet. Bitte passen Sie Ihre Zuordnung an",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_MULTIPLE_CSP_SUBSCRIPTIONS":"Das Angebot {{Name}} im Plan enthält mehrere übereinstimmende CSP-Abonnements.",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_FOR_BILLING_ERROR":"Das Abonnement {{nameinbilling}} in der Abrechnung konnte nicht zugeordnet werden",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PC_ERROR":"Das Angebot {{name}} enthält keine passenden CSP-Abonnements im Partner Center",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_NO_MATCH_IN_PLAN_ERROR":"Das Abonnement im Partner Center - {{name}} enthält kein entsprechendes Angebot im Kartenplan",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MAPPING_ERROR":"Die folgenden Angebote sollten CSP-Abonnements zugeordnet werden:",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR":"Im Angebot {{name}} sind mehr Benutzer angegeben als in den Sitzplätzen",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_ERROR1":"Die Mengen für die Abonnements sollten mit dem Partner Center übereinstimmen",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR":"Die Mengen für das Abonnement {{subscriptionname}} sollten zwischen Partner Center und Abrechnung übereinstimmen",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SEATS_MISMATCH_WITH_BILLING_ERROR1":"Die Mengen für die Abonnements sollten zwischen Partner Center und Billing übereinstimmen",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_SUM_MATCH_PROMPT":"Die Anzahl der Sitzplätze für ein CSP-Abonnement {{cspsubscription}} sollte mit der Summe aller Nummern übereinstimmen, die für verwandte Angebote im Plan angegeben wurden",
    "CUSTOMERS_ONBOARDING_MAP_OFFERS_UNMAPPED_CSP_OFFERS_ERROR":"Ein oder mehrere CSP-Angebote werden nicht zugeordnet oder es fehlen Abonnements in CSP",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_ERROR":"Beim Aktualisieren der Abonnements für {{customerName}} ist ein Fehler aufgetreten.",
    "CUSTOMERS_REFRESH_SUBSCRIPTIONS_SUCCESS":"Aktualisierte Abonnements für Kunden - {{customername}}",
    "CUSTOMERS_REGISTRATION_SUCCESS":"Kundenregistrierung erfolgreich abgeschlossen. An {{registeredemail}} wird eine E-Mail mit Anweisungen zum Anmelden am Portal gesendet.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET":"Kundenregistrierung erfolgreich abgeschlossen.<br/>Anmeldedaten des Administratorbenutzers lauten:<br/><b>UserId -</b> {{userId}},<br/><b>Temporary Password -</b> {{password }}",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_EMAIL_AND_PASSWORD_RESET_WITH_GDAP_ERROR":"Kundenregistrierung erfolgreich abgeschlossen.<br/>Die Anmeldeinformationen des Administratorbenutzers lauten:<br/><b>Benutzer-ID -</b> {{userId}},<br/><b>Temporäres Kennwort -</b> {{password}} <span class =\"text-danger\"> <br/><br/> <b>Die Sicherheitsgruppen konnten der GDAP-Anforderung nicht zugewiesen werden. Bitte schließen Sie dies im Partner Center ab, da weitere Aktionen andernfalls fehlschlagen.</b> </span>",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL":"Kundenregistrierung erfolgreich abgeschlossen.",
    "CUSTOMERS_REGISTRATION_SUCCESS_WITH_OUT_EMAIL_AND_WITH_LOGIN":"Kundenregistrierung erfolgreich abgeschlossen. Melden Sie sich beim Portal an.",
    "CUSTOMERS_SELECT_SUBSCRIPTION_FOR_ADJUSTMENT":"Bitte fügen Sie ein Angebot für die Anpassung bei",
    "CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedForOnboard",
    "CUSTOMERS_SYNC_PROFILES_BUTTON_TEXT":"Profile synchronisieren",
    "CUSTOMERS_TABLE_BUTTON_TEXT_CUSTOMER_PAYMENTS":"Kundenzahlungen",
    "CUSTOMERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "CUSTOMERS_TABLE_HEADER_TEXT_BILLED_AMOUNT":"Abgerechneter Betrag",
    "CUSTOMERS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "CUSTOMERS_TABLE_HEADER_TEXT_DATE":"Datum",
    "CUSTOMERS_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Abteilungsname",
    "CUSTOMERS_TABLE_HEADER_TEXT_NAME":"Name",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_PRICE":"Neuer Preis",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
    "CUSTOMERS_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
    "CUSTOMERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_PRICE":"Alter Preis",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
    "CUSTOMERS_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
    "CUSTOMERS_TABLE_HEADER_TEXT_OTHER_COST":"Andere Kosten",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_BILLED_AMOUNT":"Abgerechneter Betrag vor Steuern",
    "CUSTOMERS_TABLE_HEADER_TEXT_PRE_TAX_MSFT_BILLED_AMOUNT":"MSFT-Rechnung vor Steuern",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT":"Profitieren",
    "CUSTOMERS_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Gewinn%",
    "CUSTOMERS_TABLE_HEADER_TEXT_SITE_NAME":"Seite-Name",
    "CUSTOMERS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
    "CUSTOMERS_TABLE_HEADER_TEXT_USER":"Benutzer",
    "CUSTOMERS_TABLE_NO_SUBSCRIPTIONS_ADDED_YET":"Es wurden noch keine Abonnements hinzugefügt.",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_DELETE_CUSTOMER":"Kunden löschen",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_CUSTOMER_SETTINGS":"Kundeneinstellungen",
    "CUSTOMERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Pläne verwalten",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_ACTION":"Aktion",
    "CUSTOMERS_TO_UPLOAD_TO_PSA_HEADER_QUALIFIED_NAME":"Name",
    "CUSTOMERS_TOOLTIP_DOWNLOAD_USAGE_BASED_LINE_ITEMS":"Laden Sie nutzungsbasierte Werbebuchungen herunter",
    "CUSTOMERS_TOOLTIP_PROFITABILITY_BY_SUBSCRIPTIONS":"Rentabilität durch Abonnements",
    "CUSTOMERS_TOOLTIP_RELOAD_PROFITABILITY_REPORT":"Profitability Report neu laden",
    "CUSTOMERS_TOOLTIP_SUBSCRIPTION_CHANGE_HISTORY":"Änderungsverlauf des Abonnements",
    "CUSTOMERS_TOOLTIP_VIEW_INVOICE":"Rechnung anzeigen",
    "CUSTOMERS_TOOLTIP_VIEW_USAGE_LINE_ITEMS":"Verwendungsposten anzeigen",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_CUSTOMER_USER_SUCCESS":"{{username}} erfolgreich zum Azure-Abonnement hinzugefügt",
    "CUSTOMERS_USAGE_SUBSCRIPTION_ADD_USER_SUCCESS":"{{username}} erfolgreich zum Azure-Abonnement {{Azuresubscription}} hinzugefügt",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_CUSTOMER_USER_SUCCESS":"{{username}} erfolgreich aus dem Azure-Abonnement entfernt",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_CONFIRM":"Möchten Sie den Benutzer wirklich löschen?",
    "CUSTOMERS_USAGE_SUBSCRIPTION_REMOVE_USER_SUCCESS":"{{username}} erfolgreich aus {{azuresubscription}} entfernt",
    "CUSTOMERS_USAGE_SUBSCRIPTION_SAVE_SUCCESS":"Azure-Abonnement erfolgreich gespeichert",
    "customNotifications":"Benutzerdefinierte Benachrichtigungen",
    "customoffer":"Individuelles Angebot",
    "CUSTOMR_INVOICE_LINEITEM_INVOICE_STATUS_UPDATE_SUCCESSFULL_MESSAGE":"Rechnungsstatus erfolgreich aktualisiert",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "CUSTOMR_MANAGE_LICENSES_CHECKOUT_CART_SUCCESS_MESSAGE":"Bestellung erfolgreich aufgegeben. OrderId: {{orderId}}",
    "CUSTOMR_PASSWORD_EMAIL_POPUP_HEADER_TEXT":"Passwortempfänger",
    "CUSTOMR_PASSWORD_POPUP_EMAIL_RECEPIENTS":"Empfänger E-Mail",
    "CUSTOMR_PASSWORD_POPUP_TOOLTIP_EMAIL_RECEPIENTS":"Geben Sie durch Kommas getrennte E-Mail-Empfänger ein",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_ADD_NEW_ENITITY":"Neue Entität hinzufügen",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_CANCEL":"Stornieren",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_MANAGE":"Verwalten",
    "CUTOMER_OPERATING_ENTITIES_BUTTON_TEXT_SUBMIT":"einreichen",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_ADD_NEW_ENTITY":"Neue Entität hinzufügen",
    "CUTOMER_OPERATING_ENTITIES_HEADER_TEXT_OPERATING_ENTITIES":"Betriebseinheiten",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_ENITITY_TYPE":"Entitätstyp",
    "CUTOMER_OPERATING_ENTITIES_LABEL_TEXT_NAME":"Name",
    "DASHBOAR_HEADER_TEXT_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen",
    "DASHBOAR_INVOICE_BTN_TEXT_VIEW_INVOICE":"Rechnung anzeigen",
    "DASHBOAR_INVOICE_HELP_TEXT_LINE_1":"Neue Rechnung generiert am <b>{{InvoiceGeneratedDate}} </b>für den Abrechnungszeitraum von <b>{{BillingStartDate}} </b>bis <b>{{BillingEndDate}} </b>mit dem Betrag <b > {{InvoiceAmount}} </b>.",
    "DASHBOAR_TEXT_DATE":"Datum",
    "DASHBOAR_TEXT_EMAIL":"Email",
    "DASHBOAR_TEXT_EZIDEBIT_AGREEMENT_URL":"URL der Ezidebit-Vereinbarung",
    "DASHBOAR_TEXT_IP":"IP",
    "DASHBOAR_TEXT_MICROSOFT_CLOUD_AGREEMENT_URL":"Microsoft-Kundenvereinbarungs-URL",
    "DASHBOAR_TEXT_PARTNER_TERMS_AND_CONDITIONS_URL":"URL der Allgemeinen Geschäftsbedingungen des Partners",
    "DASHBOAR_TEXT_TIME":"Zeit",
    "Dashboard":"Dashboard",
    "DASHBOARD_BREADCRUMB":"Dashboard",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_ANALYTICS":"Kundenanalyse",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_CUSTOMER_REPORTS":"Berichte",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_CHANGE_REPORT":"Lizenzänderungsbericht",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_LICENSE_SUMMARY_REPORT":"Lizenzzusammenfassungsbericht",
    "DASHBOARD_BREADCRUMB_BUTTON_TEXT_USAGE_REPORT":"Nutzungsbericht",
    "DASHBOARD_BREADCRUMB_TEXT_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
    "DASHBOARD_BUTTON_TEXT_UPDATE_PROFILE":"Profil aktualisieren",
    "DASHBOARD_CAPTION_PARAGRAPH":"Bereitstellung von Ressourcen für ein erfolgreiches Shop mit der Leistung der Cloud.",
    "DASHBOARD_CAPTION_TEXT_WELCOME_TO_CLOUD_MARKET_PLACE":"Willkommen auf dem Cloud Market Place",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_ALL_SKUS_DOWNLOAD_CSV":" Alle SKUs im CSV",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_CSV":"CSV-Datei herunterladen",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_PNG":"PNG herunterladen",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_DOWNLOAD_SVG":"SVG herunterladen",
    "DASHBOARD_CARD_ACTIONS_OPTION_TEXT_TOP_10_SKUS_DOWNLOAD_CSV":"Top 10 SKUs in CSV",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT":"Microsoft",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
    "DASHBOARD_CARD_REVENUE_VERSUS_COST_PROVIDERS_FILTER_OPTION_TEXT_PARTNER":"Partner",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL":"Alles",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Letzter Abrechnungszeitraum",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 Abrechnungszeiträume",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 Abrechnungszeiträume",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 Abrechnungszeiträume",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_SIX_MONTHS":"6 Monate",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_THREE_MONTHS":"3 Monate",
    "DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS":"12 Monate",
    "DASHBOARD_COMPLAINCE_TEXT":"Compliance-Center",
    "DASHBOARD_COST_TEXT":"Kosten",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_CONTENT":"Anzahl der Abteilungen",
    "DASHBOARD_DEPARTMENTS_COUNT_INTRO_TITLE":"Abteilungen",
    "DASHBOARD_DEPARTMENTS_COUNT_TEXT_DEPARTMENTS":"Abteilungen",
    "DASHBOARD_HEADER_TEXT_WARNING":"Warnung!",
    "DASHBOARD_ICON_TITLE":"Stockholm-Symbole / Design / Ebenen",
    "DASHBOARD_LBL_TEXT_CURRENT_MONTH_SEATS":"Aktueller Monat",
    "DASHBOARD_LBL_TEXT_NET_SEATS":"Netz",
    "DASHBOARD_LBL_TEXT_PREVIOUS_MONTH_SEATS":"Vorheriger Monat",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_LABEL":"Willkommen in Ihrem Lizenzportal Dashboard",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART1":"Verwenden Sie die folgenden Kacheln als Quicklinks, um Ihre Abonnements und Benutzer zu verwalten.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART2":"In diesem Portal können Sie Ihrem Mandanten neue Benutzer hinzufügen, Lizenzen zuweisen und Ihr Kennwort zurücksetzen.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART3":"Sie können zusätzliche Lizenzen und Abonnements erwerben.",
    "DASHBOARD_NEXTSTEP_INSTRUCTIONS_PART4":"Aktualisieren Sie Ihr Profil (Rechnungsadresse und Zahlungsmethode)",
    "DASHBOARD_REVENUE_TEXT":"Einnahmen",
    "DASHBOARD_SEAT_PURCHASED_TEXT":"Sitzplatz gekauft",
    "DASHBOARD_SITES_COUNT_INTRO_CONTENT":"Anzahl der Standorte",
    "DASHBOARD_SITES_COUNT_INTRO_TITLE":"Websites",
    "DASHBOARD_SITES_COUNT_TEXT_SITES":"Websites",
    "DASHBOARD_TAIL_1_EXCHANGE_ADMIN_CENTER_TEXT":"Melden Sie sich am Portal an",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE":"Azure-Portal",
    "DASHBOARD_TAIL_1_TEXT_AZURE_PORTAL_TITLE_BUTTON":"Melden Sie sich bei Portal an",
    "DASHBOARD_TAIL_1_TEXT_AZURE_SUBSCRIPTION":"Azure-Abonnements",
    "DASHBOARD_TAIL_1_TEXT_CURRENT_AZURE_USAGE_FROM":"Aktuelle Azure-Nutzung ab {{startDate}}",
    "DASHBOARD_TAIL_1_TEXT_CUSTOMER_PRODUCTS":"Produkte",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION":"Lizenzabonnements",
    "DASHBOARD_TAIL_1_TEXT_LICENSE_SUBSCRIPTION_BUTTON":"Mehr sehen",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365":"Office 365",
    "DASHBOARD_TAIL_1_TEXT_OFFICE_365_BUTTON":"Melden Sie sich bei Portal an",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS":"Office 365-Berichte",
    "DASHBOARD_TAIL_1_TEXT_OFFICE365_USAGE_REPORTS_BUTTON":"Melden Sie sich bei Portal an",
    "DASHBOARD_TAIL_1_TEXT_USERS":"Benutzer",
    "DASHBOARD_TAIL_1_TEXT_USERS_BUTTON":"Mehr sehen",
    "DASHBOARD_TAIL_TEXT_TREND_THIS_MONTH":"TREND DIESEN MONAT",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_FAILED":"Gescheitert",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_IN_PROGRESS":"In Bearbeitung",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_NOT_RECEIVED":"Nicht erhalten",
    "DASHBOARD_TILE_ACCOUNTS_RECEIVABLE_STATUS_RECEIVED":"Empfangen",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_DATE":"Datum:",
    "DASHBOARD_TILE_SEATS_TOOLTIP_TEXT_SEATS":"Sitzplätze:",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_DATE":"Datum:",
    "DASHBOARD_TILE_SUBSCRIPTIONS_TOOLTIP_TEXT_PRODUCTS":"Produkte:",
    "DASHBOARD_TOOLTIP_LAST_SYNC":"Letzte Synchronisierung {{LastSyncMessage}}",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_1":"Neue Rechnung generiert am <b>{{InvoiceGeneratedDate}} </b>für den Abrechnungszeitraum von <b>{{BillingStartDate}} </b>bis <b>{{BillingEndDate}} </b>mit dem Betrag <b > {{InvoiceAmount}} </b>.",
    "DASHBOARD_USERSTATS_CURRENTINVOICE_LINE_2":"um die Rechnung anzuzeigen. Ignorieren, wenn bereits bezahlt.",
    "DASHBOARD_WARNING_MESSAGE":"Sie müssen Ihr Zahlungsprofil aktualisieren, bevor Sie C3 verwenden können. Bitte klicken Sie zum Aktualisieren auf die folgende Schaltfläche.",
    "DATA_PUSHED_DATES":"Einige oder alle Daten wurden zuvor übertragen, bitte überprüfen Sie die vorherigen Protokolle.",
    "DAVYNTT_INVOICE_LINEITEMS_GRID_ITEM_DESCRIPTION":"Artikelbeschreibung",
    "DAYS":"Tag(e)",
    "DEACTIVATE_CONFORMATION":"Sind Sie sicher, dass Sie deaktivieren möchten?",
    "DEACTIVATE_CUSTOMER_PRODUCT":"Kundenprodukt konnte nicht deaktiviert werden",
    "DEACTIVATE_CUSTOMER_PRODUCT_FAILURE":"Kundenprodukt deaktiviert",
    "DEACTIVATE_PRODUCT_EXCEPTION":"Dieser Benutzer hat keine Berechtigung, dieses Angebot zu entfernen",
    "DECEMBER":"Dezember",
    "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DESC":"Aktivieren Sie diese Konfiguration, um ein Raster/eine Liste als Standardansicht auf der Produktseite für Kunden festzulegen",
    "DEFAULT_CONFIGURATION_PRODUCTS_VIEW_TYPE_DISP":"Standardansicht für Kundenprodukte",
    "DEFAULT_COTERMINOSITY_TYPE_DESC":"Wenn Sie hier einen Wert auswählen, wird dieser als Standard für die Coterminosity-Funktion beim Abonnementkauf festgelegt.",
    "DEFAULT_COTERMINOSITY_TYPE_DISP":"Coterminositätstyp",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DESC":"Liste der Gegenminiositätstypen",
    "DEFAULT_COUNTERMINIOSITY_TYPE_DISP":"Gegenminiositätstyp",
    "DELETE_ALL_ADDONS_BUTTON_TOOLTIP_TEXT":"Löschen Sie alle Add-Ons",
    "DELETE_CURRENCY_CONVERSION_EXCEPTION":"Beim Löschen der Währungsumrechnung ist ein Fehler aufgetreten",
    "DELETE_CUSTOMER_FAILURE":"Aktualisierung des Kundennamens fehlgeschlagen",
    "DELETE_CUSTOMER_SUCCESS":"Kunde wird erfolgreich gelöscht.",
    "DELETE_EMAIL_NOTIFICATION_RECIPIENTS_EXCEPTION":"Beim Löschen der E-Mail-Benachrichtigungsempfänger ist ein Fehler aufgetreten",
    "DELETE_INVOICE_ADJUSTMENT_CONFIRMATION":"Die ausgewählte Rechnungsanpassung wird gelöscht. Wollen Sie fortfahren?",
    "DELETE_PARTNER_BUNDLE_TOOLTIP":"Bündelprodukt löschen",
    "DELETE_PARTNER_OFFERS":"Partnerangebote löschen",
    "DELETE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Löschen der Steuerdetails ist ein Fehler aufgetreten",
    "DELETE_PARTNER_USER":"Partnerbenutzer löschen",
    "DELETE_PARTNER_USER_FAILURE":"Fehler beim Löschen des Partnerbenutzers",
    "DELETE_PONUMBER":"Bestellnummer löschen",
    "DELETE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt löschen",
    "DELETE_QUOTE_SUCCESSFULLY":"Angebot erfolgreich gelöscht",
    "DELETE_RECORD_CONFIRMATION_PROMPT":"Möchten Sie diesen Datensatz wirklich löschen?",
    "DELETE_USER_EXCEPTION":"Dieser Benutzer hat Zugriff auf C3. Entfernen Sie diese",
    "DEPARTMENT":"(Abteilung)",
    "DESC_ PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Hauptüberschrift des öffentlichen Anmeldebanners",
    "DESC_ PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Überschrift des öffentlichen Anmeldebanners",
    "DESC_CUSTOMER_CURRENCY_OPTION":"Kundenwährung",
    "DESC_CUSTOMER_DASHBOARD":"Kunden-Dashboard",
    "DESC_PARTNER_DASHBOARD":"Partner-Dashboard",
    "DESC_PLAN_CURRENCY_OPTION":"Währung planen",
    "DESC_PUBLIC_SIGNUP_ADMIN_PORTAL":"Website-URL für den Admin-Hyperlink in der öffentlichen Anmeldenavigationsleiste",
    "DESC_PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Hauptüberschrift im öffentlichen Anmeldebanner",
    "DESC_PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Der Absatz, der auf dem öffentlichen Anmeldebanner unter der Bannerüberschrift erscheint",
    "DESC_PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Website-URL für den Website-Hyperlink in der öffentlichen Anmelde-Navigationsleiste",
    "DESC_PUBLIC_SIGNUP_CONTACTUS_URL":"Kontaktiere uns",
    "DESC_PUBLIC_SIGNUP_LEARNER_PORTAL":"Website-URL für den Hyperlink des Lernportals in der öffentlichen Anmeldenavigationsleiste",
    "DESC_PUBLIC_SIGNUP_SUPPORT":"Website-URL für den Support-Hyperlink in der öffentlichen Anmeldenavigationsleiste",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Öffentliche Registrierungsbedingungen 1",
    "DESC_PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Allgemeine Geschäftsbedingungen für die Anmeldung 2",
    "DESC_SHOW_INVOICES_TO_CUSTOMER":"Aktivieren Sie diese Option, um dem Kunden Rechnungen anzuzeigen",
    "Description":"Beschreibung",
    "DESCRIPTION_FOR":"Beschreibung für",
    "DISABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt deaktivieren",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL":"Altes und neues sicheres Modell deaktiviert",
    "DISABLED_OLD_AND_ENABLED_NEW_SECURE_MODEL_SUCCESS_MESSAGE":"Danke für die Bestätigung. Die Anwendung greift gemäß den neuen Sicherheitsrichtlinien auf Ihr Partner Center zu.",
    "DISABLING_OLD_AND_ENABLING_NEW_SECURE_MODEL_FAILED":"Deaktivieren des alten und Aktivieren des neuen sicheren Modells fehlgeschlagen",
    "DISCOUNT_MAX_ERROR":"Der Wert muss kleiner oder gleich 100 sein.",
    "DISCOUNT_MIN_ERROR":"Der Wert muss größer oder gleich 0 sein",
    "DISP_CATEGORY_COUPON_ENTITY":"Kategorie",
    "DISP_PLANOFFER_COUPON_ENTITY":"Planangebot",
    "DISP_PROVIDER_COUPON_ENTITY":"Anbieter",
    "DISP_SALETYPE_COUPON_ENTITY":"SaleType",
    "DISP_SHOW_INVOICES_TO_CUSTOMER":"Rechnungen dem Kunden zeigen",
    "DisplayCurrency":"Anzeigewährung",
    "DISTRIBUTOR_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Laufzeit",
    "DISTRIBUTOR_OFFER_BUTTON_TEXT_RESET":"Zurücksetzen",
    "DISTRIBUTOR_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Geben Sie einen positiven Wert ein",
    "DISTRIBUTOR_OFFER_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
    "DISTRIBUTOR_OFFER_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
    "DISTRIBUTOR_OFFERS_BUTTON_TEXT_ADD_DISTRIBUTOR_OFFERS":"Neues Vertriebsangebot",
    "DISTRIBUTOR_OFFERS_CAPTION_BUTTON_TEXT_BACK_TO_DISTRIBUTOR_OFFERS":"Zurück zum Händlerangebot",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS":"Händlerangebote",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_CATEGORY_TEXT":"Kategorie",
    "DISTRIBUTOR_OFFERS_CAPTION_TEXT_CUSTOM_OFFERS_DISTRIBUTOR_TEXT":"Händlerangebote",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ACTION":"Aktion",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BILLING_TYPES":"Abrechnungsarten",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CATEGORY":"Kategorie",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CHARGE_PERIODS":"Art des Abrechnungszeitraums",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_FROM":"Selbstkostenpreis (ab)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_COST_PRICE_TO":"Selbstkostenpreis (bis)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_NAME":"Name",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_COST_PRICE":"Alter Selbstkostenpreis",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_OLD_SALE_PRICE":"Alter Verkaufspreis",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PRODUCT_ICON":"Symbol",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_PROVIDER":"Anbieter",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_FROM":"Verkaufspreis (ab)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_PRICE_TO":"Verkaufspreis (bis)",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
    "DISTRIBUTOR_OFFERS_LABEL_TEXT_TERM":"Begriff",
    "DISTRIBUTOR_OFFERS_SAVE_SUCCESS":"Händlerangebot {{distributorOffer}} erfolgreich gespeichert",
    "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_name":"Name",
    "DISTRIBUTOR_OFFERS_TABLE_HEADER_TEXT_NAME":"Name",
    "DISTRIBUTOR_OFFERS_UPDATE_SUCCESS":"Das Händlerangebot {{distributorOffer}} wurde erfolgreich aktualisiert",
    "DLETE_ACCOUNT_MANAGER_SUCCESSFULLY":"Account Manager erfolgreich löschen",
    "DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION":"Möchten Sie stornieren?",
    "DOCUMETATION_HEADING":"Dokumentation",
    "DOWNLOAD_BULK_INVOICE_LABEL_CURRENCY_CODE":"Währungscode",
    "DOWNLOAD_BULK_INVOICE_LABEL_ENTITY":"Einheit",
    "DOWNLOAD_BULK_INVOICE_LABEL_INVOICE_AMOUNT":"Abgerechneter Betrag",
    "DOWNLOAD_BULK_INVOICE_LABEL_QUALIFIED_NAME":"Qualifizierter Name",
    "DOWNLOAD_BULK_INVOICE_TAB_PROGRESS":"Fortschritt & Link herunterladen",
    "DOWNLOAD_BULK_INVOICES_TAB_CUSTOMERS":"Rechnungen herunterladen",
    "DOWNLOAD_COLUMN_BUNDLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_CYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Abrechnungsregel für den Kauf von Sitzplätzen",
    "DOWNLOAD_COLUMN_BUNDLE_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Abrechnungsregel für Sitzplatzreduzierung",
    "DOWNLOAD_COLUMN_BUNDLE_COST_PRICE":"Selbstkostenpreis",
    "DOWNLOAD_COLUMN_BUNDLE_COST_SALE_TYPE":"Verkaufsart",
    "DOWNLOAD_COLUMN_BUNDLE_DESCRIPTION":"Beschreibung",
    "DOWNLOAD_COLUMN_BUNDLE_IS_AUTORENEWABLE":"Ist Autor erneuerbar",
    "DOWNLOAD_COLUMN_BUNDLE_NAME":"Bündelname",
    "DOWNLOAD_COLUMN_BUNDLE_PRODUCT_NAME":"Produktname",
    "DOWNLOAD_COLUMN_BUNDLE_SALE_PRICE":"Verkaufspreis",
    "DOWNLOAD_COLUMN_BUNDLE_STATUS":"Status",
    "DOWNLOAD_COLUMN_BUNDLE_SUPPORTED_MARKET":"Unterstützter Markt",
    "DOWNLOAD_COLUMN_BUNDLE_TERM":"Begriff",
    "DOWNLOAD_COLUMN_BUNDLEPRODUCTS_TITLE":"Bericht „Bundle-Produkte“",
    "DOWNLOAD_COLUMN_COUPON_ACTIVE_COUPON_NAME":"Name des aktiven Gutscheins",
    "DOWNLOAD_COLUMN_COUPON_APPLICABILITY":"Anwendbarkeit",
    "DOWNLOAD_COLUMN_COUPON_ASSIGNED_ON":"Zugewiesen am",
    "DOWNLOAD_COLUMN_COUPON_COUPON_CODE":"Gutscheincode",
    "DOWNLOAD_COLUMN_COUPON_COUPON_DESCRIPTION":"Gutscheinbeschreibung",
    "DOWNLOAD_COLUMN_COUPON_CUSTOMER_NAME":"Kundenname",
    "DOWNLOAD_COLUMN_COUPON_DISCOUNT":"Rabatt",
    "DOWNLOAD_COLUMN_COUPON_EXPIRES_ON":"Läuft aus am",
    "DOWNLOAD_COLUMN_CUSTOMER_AUTO_PAY":"Automatische Zahlung",
    "DOWNLOAD_COLUMN_CUSTOMER_BILLINGREFERENCEID":"Rechnungsreferenz-ID",
    "DOWNLOAD_COLUMN_CUSTOMER_COUNTRY_CODE":"Marktcode",
    "DOWNLOAD_COLUMN_CUSTOMER_CURRENCY_CODE":"Währungscode",
    "DOWNLOAD_COLUMN_CUSTOMER_DOMAIN_NAME":"Domain Name",
    "DOWNLOAD_COLUMN_CUSTOMER_NAME":"Name",
    "DOWNLOAD_COLUMN_CUSTOMER_PAYMENT_METHOD":"Bezahlverfahren",
    "DOWNLOAD_COLUMN_CUSTOMER_PLAN":"Planen",
    "DOWNLOAD_COLUMN_CUSTOMER_PRODUCTS_IS_TRIAL_OFFER":"Ist Testangebot",
    "DOWNLOAD_COLUMN_CUSTOMER_SIGNUP_DATE":"Anmeldedatum",
    "DOWNLOAD_COLUMN_CUSTOMER_TAGS":"Stichworte",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_ID":"Mieter-ID",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANT_NAME":"Name des Mieters",
    "DOWNLOAD_COLUMN_CUSTOMER_TENANTS":"Mieter",
    "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_IS_TRIAL_OFFER":"Ist ein Testangebot",
    "DOWNLOAD_COLUMN_INVOICE_LINE_ITEM_PROVIDER_INVOICE_NUMBER":"Rechnungsnummer des Anbieters",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLING_CYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_BILLINGREFERENCENUMBER":"Rechnungsreferenznummer",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_END_DATE":"Enddatum der Gebühr",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CHARGE_START_DATE":"Startdatum der Gebühr",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CONVERSION_RATE":"Wechselkurs",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_COST_ON_PARTNER":"Kosten für Partner",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CURRENCY_CODE":"Währungscode",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_CUSTOMER_ID":"ERP-Kunden-ID",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DISCOUNT_AMOUNT":"Rabattbetrag",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DOMAIN_NAME":"Domain Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_DURABLEOFFERID":"Dauerhafte Angebots-ID",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_EFFECTIVE_UNIT_PRICE_BY_MS":"Effektiver Stückpreis nach MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_DATE":"Rechnungsdatum",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NOTES":"Rechnungsnotizen",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICE_NUMBER":"Rechnungsnummer",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_INVOICELINE_DETAILS":"Details zur Rechnungszeile",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_ITEM_CODE":"Produktcode",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_MANDT":"MANDT",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_NAME":"Name",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_OFFER_NAME":"Angebotsname",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PONUMBER":"Bestellnummer",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_POST_TAX_AMOUNT":"Steuerbetrag buchen",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRE_TAX_AMOUNT":"Vorsteuerbetrag",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROMOTION_ID":"Angebots-ID",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PRORATED_UNIT_COST":"Anteilige Stückkosten",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PROVIDER":"Anbieter",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_PS_PSP_PN":"PS_PSP_PN",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_QUANTITY":"Menge",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_ENDDATE":"Enddatum des Abonnements",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_EFFECTIVE_STARTDATE":" Abonnement gültig",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_ID":"Abonnement-ID",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_NAME":"Abonnementname",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TERM":"Abonnementlaufzeit",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SUBSCRIPTION_TYPE":"Abonnementtyp",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_SYNDICATION_PARTNER_SUBSCRIPTION_NUMBER":"Abonnementnummer des Syndication-Partners",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_AMOUNT":"Steuerbetrag",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TAX_CODE":"Steuer-Code",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_ID":"Mieter-ID",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TENANT_NAME":"Name des Mieters",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT":"Gesamtmenge",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_TOTAL_AMOUNT_FOR_PARTNER_BY_MS":"Gesamtbetrag für Partner nach MS",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_COST_PER_PRODUCT":"Stückkosten pro Produkt",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PARTNER_COST":"Kosten pro Einheit für Partner",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE":"Stückpreis",
    "DOWNLOAD_COLUMN_INVOICELINEITEM_UNIT_PRICE_BY_MS":"Stückpreis nach MS",
    "DOWNLOAD_COLUMN_MARKET_REGION":"Unterstützte Märkte",
    "DOWNLOAD_COLUMN_PARTNER_OFFER_IS_TRIAL_OFFER":"Ist Testangebot",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_BUNDLING":"Zur Bündelung verfügbar",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_AVAILABLE_FOR_CONSUMPTION_IMMEDIATELY":"Sofort zum Verzehr verfügbar",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_CYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_PURCHASE_OF_SEATS":"Abrechnungsregel für den Kauf von Sitzplätzen",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_RULE_FOR_REDUCTION_OF_SEATS":"Abrechnungsregel für Sitzplatzreduzierung",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_BILLING_TYPE":"Abrechnungsart",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_CONSUMPTION_TYPE":"Verbrauchsart",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_COST_PRICE":"Selbstkostenpreis",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_DESCRIPTION":"Beschreibung",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_NAME":"Name",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_PRICE":"Verkaufspreis",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_SALE_TYPE":"Verkaufsart",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_STATUS":"Status",
    "DOWNLOAD_COLUMN_PARTNEROFFERS_TERM":"Begriff",
    "DOWNLOAD_COLUMN_PLAN_CURRENCY_CODE":"Währungscode",
    "DOWNLOAD_COLUMN_PLAN_DESCRIPTION":"Beschreibung",
    "DOWNLOAD_COLUMN_PLAN_INVOICE_CURRENCY":"Rechnungswährung",
    "DOWNLOAD_COLUMN_PLAN_IS_PUBLIC":"Ist öffentlich",
    "DOWNLOAD_COLUMN_PLAN_MACRO_DETAILS":"Makrodetails",
    "DOWNLOAD_COLUMN_PLAN_MACRO_VALUE":"Makrowert",
    "DOWNLOAD_COLUMN_PLAN_NAME":"Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_CYCLE_CATEGORY":"Kategorie Abrechnungszeitraum",
    "DOWNLOAD_COLUMN_PLAN_OFFER_BILLING_TYPE":"Abrechnungsart",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CATEGORY_TYPE":"Kategorie",
    "DOWNLOAD_COLUMN_PLAN_OFFER_COST_TO_PARTNER":"Kosten für den Partner",
    "DOWNLOAD_COLUMN_PLAN_OFFER_CURRENCY_CODE":"Währungscode",
    "DOWNLOAD_COLUMN_PLAN_OFFER_EFFECTIVENESS":"Wirksamkeit",
    "DOWNLOAD_COLUMN_PLAN_OFFER_FRIENDLY_OFFER_NAME":"Freundlicher Angebotsname",
    "DOWNLOAD_COLUMN_PLAN_OFFER_LAST_PRICE_CHANGE_HAPPENS_ON":"Die letzte Preisänderung erfolgt am",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_NAME":"Makroname",
    "DOWNLOAD_COLUMN_PLAN_OFFER_MACRO_VALUE":"Makrowert",
    "DOWNLOAD_COLUMN_PLAN_OFFER_NAME":"Plan Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_ID":"Angebots-ID",
    "DOWNLOAD_COLUMN_PLAN_OFFER_OFFER_NAME":"Angebotsname",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PLAN_NAME":"Plan Name",
    "DOWNLOAD_COLUMN_PLAN_OFFER_PROVIDER":"Anbieter",
    "DOWNLOAD_COLUMN_PLAN_OFFER_RETAIL_PRICE":"Endverbraucherpreis",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SALE_PRICE":"Verkaufspreis",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SERVICE_TYPE":"Servicetyp",
    "DOWNLOAD_COLUMN_PLAN_OFFER_STATUS":"Status",
    "DOWNLOAD_COLUMN_PLAN_OFFER_SUPPORTED_MARKET":"Unterstützter Markt",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM":"Begriff",
    "DOWNLOAD_COLUMN_PLAN_OFFER_TERM_DURATION":"Laufzeit",
    "DOWNLOAD_COLUMN_PLAN_PURCHASE_CURRENCY":"Währung kaufen",
    "DOWNLOAD_COLUMN_PLAN_SHARING_URL":"URL teilen",
    "DOWNLOAD_COLUMN_PLANOFFER_IS_AVAILABLE_FOR_PURCHASE":"Ist zum Kauf verfügbar",
    "DOWNLOAD_COLUMN_PLANOFFER_IS_TRIAL_OFFER":"Ist Testangebot",
    "DOWNLOAD_COLUMN_PRODUCT_AVAILABLE_FOR_AUTO_RELEASE":"Verfügbar für automatische Freigabe",
    "DOWNLOAD_COLUMN_PRODUCT_BILLING_CYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_PRODUCT_CATEGORY":"Kategorie",
    "DOWNLOAD_COLUMN_PRODUCT_CONSUMPTION_TYPE":"Verbrauchsart",
    "DOWNLOAD_COLUMN_PRODUCT_DEPARTMENT_NAME":"Abteilungsname",
    "DOWNLOAD_COLUMN_PRODUCT_DISCOUNT":"Rabatt",
    "DOWNLOAD_COLUMN_PRODUCT_DOMAIN_NAME":"Domain Name",
    "DOWNLOAD_COLUMN_PRODUCT_EFFECTIVE_PRICE":"Effektiver Produktpreis",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_NAME":"Produktname",
    "DOWNLOAD_COLUMN_PRODUCT_PRODUCT_SEQUENCE":"Produktsequenz",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER":"Anbieter",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CATEGORY_NAME":"Name der Anbieterkategorie",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_CREATION_DATE":"Erstellungsdatum des Anbieters",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_END_DATE":"Enddatum des Anbieters",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_EFFECTIVE_START_DATE":"Startdatum des Anbieters",
    "DOWNLOAD_COLUMN_PRODUCT_PROVIDER_PRODUCT_ID":"Produkt-ID des Anbieters",
    "DOWNLOAD_COLUMN_PRODUCT_QUANTITY":"Menge",
    "DOWNLOAD_COLUMN_PRODUCT_SITE_NAME":"Seite-Name",
    "DOWNLOAD_COLUMN_PRODUCT_STATUS":"Status",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_ID":"Provider Mieter ID",
    "DOWNLOAD_COLUMN_PRODUCT_TENANT_NAME":"Name des Mieters",
    "DOWNLOAD_COLUMN_PRODUCT_UNIT_PRICE":"Stückpreis",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_BILLING_CYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CATAGORY":"Kategorie",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_COST_TO_PARTNER":"Kosten für den Partner",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_CURRENCY":"Währungscode",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_IS_TRIAL_OFFER":"Ist Testangebot",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_ID":"Produkt-ID",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PRODUCT_NAME":"Produktname",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_CATAGORY":"Anbieterkategorie",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_PROVIDER_NAME":"Anbietername",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_REGION":"Region",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_RETAIL_PRICE":"Verkaufspreis",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_TITLE":"Produktkatalogbericht",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY":"Gültigkeit",
    "DOWNLOAD_COLUMN_PRODUCTCATALOGUE_VALIDITY_TYPE":"Gültigkeitsart",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_BILLINGCYCLE":"Rechnungskreislauf",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CATEGORYNAME":"Kategoriename",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEBY":"Erstellen von",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CREATEDATE":"Erstellungsdatum",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CURRENCYCODE":"Währungscode",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_CUSTOMERNAME":"Kundenname",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_DOMAINNAME":"Domain Name",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ENTITY":"Einheit",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_ISADDON":"Ist Addon",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NAMEOFENTITY":"Name des Unternehmens",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWPRICE":"Neuer Preis",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWQUANTITY":"Neue Menge",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_NEWSTATUS":"Neuer Status",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDPRICE":"Alter Preis",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDQUANTITY":"Alte Menge",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_OLDSTATUS":"Alter Status",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PRODUCTNAME":"Produktname",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDER":"Anbieter",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_PROVIDERCATEGORY":"Anbieterkategorie",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLERNEWPRICE":"Wiederverkäufer neuer Preis",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_RESELLEROLDPRICE":"Wiederverkäufer alter Preis",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_SUBSCRIPTIONID":"Abonnement-ID",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TENANTID":"Mieter-ID",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_TYPEOFCHANGE":"Art der Änderung",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITY":"Gültigkeit",
    "DOWNLOAD_COLUMN_SUBSCRIPTION_HISTORY_VALIDITYTYPE":"Gültigkeitsart",
    "DOWNLOAD_CUSTOMER_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Wählen Sie mindestens eine Spalte aus",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_ADD":"Herunterladen",
    "DOWNLOAD_GRID_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "DOWNLOAD_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "DOWNLOAD_GRID_POPUP_COUPON_DOWNLOAD_HEADER":"Gutscheine herunterladen",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_DOWNLOD_HEADER":"Kunden herunterladen",
    "DOWNLOAD_GRID_POPUP_CUSTOMER_PRODUCT_HEADER":"Produkte herunterladen",
    "DOWNLOAD_GRID_POPUP_PLAN_DOWNLOAD_HEADER":"Pläne herunterladen",
    "DOWNLOAD_GRID_POPUP_PLAN_OFFER_HEADER":"Planangebote herunterladen",
    "DOWNLOAD_INVOICE_LINE_ITEMS_LBL":"Rechnungsposten",
    "DOWNLOAD_INVOICE_PAYMENTS_LBL":"Rechnungszahlungen",
    "DOWNLOAD_INVOICELINEITEM_ATLEAST_SELECT_ONE_COLUMN_ERROR":"Wählen Sie mindestens eine Spalte aus",
    "DOWNLOAD_INVOICELINEITEM_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "DOWNLOAD_INVOICES_AND_PAYMENTS_BUTTON_TEXT_DOWNLOAD_CSV":"CSV-Datei herunterladen",
    "DOWNLOAD_NON_ONBOARDED_CUSTOMERS":"Laden Sie nicht eingebundene Kunden herunter",
    "DOWNLOAD_PARTNER_OFFERS_GRID_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "DOWNLOAD_POPUP_GRID_HEADER":"Rasterbericht herunterladen",
    "DOWNLOAD_PRODUCTCATALOGUE_COLUMN_ERROR":"Es sind keine Daten zum Herunterladen im CSV-Format verfügbar",
    "DOWNLOAD_PRODUCTS_GRID":"Produkte herunterladen",
    "DOWNLOAD_SOFTWARE_AND_KEYS_POP_UP_HEADER":"Laden Sie Software und Schlüssel herunter",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_ADD_MULTIPLE_TENANTS":"Mehrere hinzufügen",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_MULTIPLE_PROVIDERS":"Mehrere verknüpfen",
    "DROPDOWN_MENU_BUTTON_TEXT_TO_LINK_SINGLE_PROVIDER":"Link Single",
    "DROPDOWN_PLACEHOLDER_SELECT":"Wählen",
    "DUE_STATUS_DUE":"Fällig",
    "DUE_STATUS_FINALIZED":"Die Rechnung wurde fertiggestellt",
    "DUE_STATUS_OVERDUE":"Überfällig",
    "DUE_STATUS_PAID":"Bezahlt",
    "DUE_STATUS_PARTIAL_PAID":"Teilweise bezahlt",
    "earningsreport":"Ergebnisbericht",
    "EDIT_BILLING_INFORMATION_IN_PUBLIC_SIGN_UP":"Rechnungsinformationen bearbeiten",
    "EDIT_GROUP_TOOLTIP_AZUREGROUP":"Gruppe bearbeiten",
    "EDIT_INVOICE_BUTTON_TEXT":"Rechnung bearbeiten",
    "EDIT_LICENSES_TABLE_HEADER_PRODUCT":"Produkt",
    "EDIT_LICENSES_TABLE_HEADER_SUMMARY":"Zusammenfassung",
    "EDIT_PONUMBER":"Bestellnummer bearbeiten",
    "EffectivenessTypeId":"Wirksamkeitstyp-ID",
    "EMAIL_LOGO_WIDTH_HEIGHT_CONSTRAIN":"Bitte stellen Sie ein Bild mit einer maximalen Auflösung von 300 x 100 für das E-Mail-Logo bereit",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_BILLING_CONTACTS":"Die im Kundenprofil als Rechnungskontakt angegebene E-Mail-Adresse",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_PEOPLE":"Liste der E-Mail-Adressen der Personen, die per E-Mail benachrichtigt werden sollen",
    "EMAIL_NOTIFCTN_RES_TYPE_DESC_ROLE":"Andere Rollen als HelpDesk",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_BILLING_CONTACTS":"Abrechnungskontakte",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_PEOPLE":"Menschen",
    "EMAIL_NOTIFCTN_RES_TYPE_NAME_ROLE":"Rolle",
    "EMAIL_TABLE_HEADER_ACTIONS":"Aktionen",
    "EMAIL_TABLE_HEADER_EMAIL":"Email",
    "EMAIL_TABLE_HEADER_EMAIL_TYPE":"E-Mail-Typ",
    "EMAIL_TEMPLATE":"Vorschau",
    "EMAIL_TEMPLATE_LABEL":"Vorlagen",
    "EMAIL_TEMPLATE_LABEL_PLACEHOLDER":"Vorlage auswählen",
    "EMAIL_TEMPLATE_NOTIFY_SUCCESS":"E-Mail-Vorlage erfolgreich aktualisiert",
    "EMAIL_TEMPLATE_SUBMIT":"einreichen",
    "EMAIL_TYPE_BILLING":"Abrechnung",
    "EMAIL_TYPE_COMPANY":"Unternehmen",
    "EMAIL_TYPE_INVOICE":"Rechnung",
    "EMAIL_TYPE_PERSONAL":"persönlich",
    "EMAIL_TYPE_SHIPPING":"Versand",
    "EmailConfigurations":"SMTP",
    "ENABLE_ENTITLEMENT_LEVEL_PRICING_DESC":"Die Konfiguration ist so konzipiert, dass die Preisgestaltung auf Berechtigungsebene möglich ist. Nach der Aktivierung erstellen wir Rechnungen auf Grundlage der Berechtigungsebenen, unabhängig vom Rechnungstyp. Bitte bestätigen Sie, dass dies für Sie akzeptabel ist.",
    "ENABLE_ENTITLEMENT_LEVEL_PRICING_DISP":"Aktivieren Sie die Preisgestaltung auf Berechtigungsebene",
    "ENABLE_PRODUCT_BUTTON_TOOLTIP_TEXT":"Produkt aktivieren",
    "ENABLE_RESELLER_PLAN":"Plan dem Reseller zuweisen",
    "ENABLE_RESELLER_PLAN_FAILURE":"Plan konnte dem Reseller nicht zugewiesen werden",
    "END_BILLING_PERIOD":"Abrechnungszeitraum beenden",
    "END_DATE_GRATER_THAN_START_DATE_MESSAGE":"Das Enddatum sollte nach dem Startdatum liegen",
    "END_DATE_REQUIRED_MESSAGE":"Enddatum ist erforderlich",
    "ENGAGE_BREADCRUMB_ADD":"Engagement schaffen",
    "ENGAGE_BREADCRUMB_LIST":"Engagement-Liste",
    "ENGAGE_BUTTON_COLOR_PLACEHOLDER":"Farbe des Call-to-Action-Schaltfläche",
    "ENGAGE_BUTTON_TEXT_COLOR":"Textfarbe der Schaltfläche",
    "ENGAGE_BUTTON_TEXT_COLOR_PLACEHOLDER":"Farbe des Schaltflächentextes",
    "ENGAGE_BUTTON_URL_PLACEHOLDER":"Call-to-Action-Button-Hyperlink",
    "ENGAGE_CTA_BUTTON_COLOR":"Farbe der CTA-Schaltfläche",
    "ENGAGE_CTA_BUTTON_TEXT_PLACEHOLDER":"Text der Call-to-Action-Schaltfläche",
    "ENGAGE_CUSTOMER_PAGENAME":"KundenDashboard",
    "ENGAGE_EFFECTIVE_FROM":"Gültig ab",
    "ENGAGE_EXPIRES_ON":"Läuft aus am",
    "ENGAGE_HEADER_ACTIONS":"Aktionen",
    "ENGAGE_HEADER_ACTIONS_ADD":"Engagement schaffen",
    "ENGAGE_HEADER_ACTIONS_BACK":"Zurück zur Liste",
    "ENGAGE_HEADER_EFFECTIVE_FROM":"Gültig ab",
    "ENGAGE_HEADER_ENTITES":"Entitäten",
    "ENGAGE_HEADER_EXPIRES_ON":"Läuft aus am",
    "ENGAGE_HEADER_MESSAGE":"Botschaft",
    "ENGAGE_HEADER_PAGE":"Buchseite",
    "ENGAGE_HEADER_TITLE":"Titel",
    "ENGAGE_NOTIFICATION_BACKGROUND":"Hintergrund",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT1":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT2":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT3":"Marketing &amp; Verkaufsprodukt Vertrauen.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT4":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT5":"Das Anbieten von Rabatten für bessere Online-Shops kann Loyalitätswaffe ins Fahren bringen.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT6":"Beginnen Sie mit einem Branding-Site-Design und moderner Inhaltserstellung.",
    "ENGAGE_NOTIFICATION_BODY_EXAMPLE_TEXT8":"Beginnen Sie mit einem modernen Website-Design und passen Sie es mit Ihren Branding-Inhalten und Funktionen an. Alle Premium-Blogs enthalten benutzerdefiniertes CSS.",
    "ENGAGE_NOTIFICATION_BODY_TEXT":"Nachrichtentext",
    "ENGAGE_NOTIFICATION_CREATED_SUCCESSFULLY_POP_UP_MESSAGE":"Engage-Benachrichtigung erfolgreich erstellt",
    "ENGAGE_NOTIFICATION_MIN_LENGTH_ERROR_MESSAGE":"Dies überschreitet die Mindestlänge von 30",
    "ENGAGE_NOTIFICATION_PORTAL_PAGE":"Portalseite",
    "ENGAGE_NOTIFICATION_TITLE_TEXT":"Titeltext",
    "ENGAGE_NOTIFICATION_UPDATED_SUCCESSFULLY_POP_UP_MESSAGE":"Engage-Benachrichtigung erfolgreich aktualisiert",
    "ENGAGE_PARTNER_PAGENAME":"Partner-Dashboard",
    "ENGAGE_POPUP_BUTTON_URL":"Schaltflächen-URL",
    "ENGAGE_POPUP_CTA_BUTTON_TEXT":"Text der CTA-Schaltfläche",
    "ENGAGE_POPUP_SELECT_ALL_CUSTOMERS":"Wählen Sie alle Kunden aus",
    "ENGAGE_SUBMIT":"einreichen",
    "ENGAGE_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_15":"Der Text überschreitet die maximale Länge von 15",
    "ENGAGE_THE_MAX_LENGTH":"Die maximale Länge beträgt 250",
    "ENGAGE_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_FAILURE_MESSAGE":"Bitte wählen Sie mindestens eine Rechnung für die Veröffentlichung in QuickBooks aus.",
    "ENQUEUE_REQUEST_FOR_POST_INVOICES_TO_QUICKBOOKS_SUCCESS_MESSAGE":"Ihre Anfrage, Rechnungen an QuickBooks zu senden, wird angenommen und zur Verarbeitung in die Warteschlange gestellt. Klicken Sie auf die Schaltfläche <strong> Beitragsprotokolle anzeigen </strong>, um Informationen zum Fortschritt zu erhalten.",
    "ENQUEUE_REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_FAILED":"Die Anforderung zum Hochladen von Rechnungen in QuickBooks online konnte nicht in die Warteschlange gestellt werden",
    "ENQUEUED_REQUEST_TO_CLONE_PLAN":"In die Warteschlange gestellte Anforderung zum Klonen des Plans",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Enqueued Anfrage, einen neuen Plan mit allen Angeboten zu erstellen",
    "ENQUEUED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Anfrage in die Warteschlange gestellt, um einen neuen Reseller-Plan mit allen Angeboten zu erstellen",
    "ENQUEUING_UPLOAD_INVOICES_TO_XERO_QUEUE_MESSAGE_FAILED":"Die Anforderung zum Hochladen von Rechnungen zu Xero konnte nicht in die Warteschlange gestellt werden",
    "ENTER_AN_E-MAIL_AFTER_THE_COMA_OR_REMOVE_THE_EXTRA_COMMA":"Geben Sie nach dem Komma eine E-Mail ein oder entfernen Sie das zusätzliche Komma",
    "ENTER_AZURE_NEW_MARKUP_VALUE":"Geben Sie einen neuen Markup-Wert ein",
    "ENTER_REASON_FOR_AZURE_NEW_MARKUP_VALUE":"Geben Sie den Grund für den neuen Markup-Wert ein",
    "ENTER_REASON_FOR_UPDATING_MARKUP_VALUE":"Geben Sie den Grund für die Aktualisierung des Markup-Werts ein",
    "enterAddress1":"Adresse 1 eingeben",
    "enterAddress2":"Adresse 2 eingeben",
    "enterCity":"Stadt eingeben",
    "enterEmail":"E-Mail eingeben",
    "enterPhoneNumber":"Telefonnummer eingeben",
    "enterState":"Bundesland eingeben",
    "enterZip":"PLZ eingeben",
    "ENTITLEMENT_EXCEEDED_COUNT_ERROR":"Anzahl der zu erstellenden Berechtigungen, die {{Count}} nicht überschreitet",
    "ENTITLEMENT_MANAGEMENT":"Azure-Berechtigungsverwaltung",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_COUNT_TOOLTIP":"Die Anzahl der zu erstellenden Berechtigungen darf nicht überschritten werden",
    "ENTITLEMENT_MANAGEMENT_ENTITLEMENT_NAME_TOOLTIP":"Berechtigungsnamen beginnen damit.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS":"Anleitung",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE":"Wenn der Berechtigungspreis überschrieben wird, gilt dieser Preis sowohl für Rechnungsberechnungen als auch für Azure-Schätzungen.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_NOTE_LABEL":" <b>Hinweis:</b>",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_1":"Verwenden Sie die Schaltfläche <b>Überschreiben</b>, um den Berechtigungspreis zu ändern.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_2":"Verwenden Sie die Schaltfläche <b>Zurücksetzen</b>, um den Preis für die Berechtigung auf den Standardpreis des Azure-Plans zurückzusetzen.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_3":"Verwenden Sie <b>Exportieren</b>, um Berechtigungsdaten für eine bestimmte Kunden- und Mandantenebene herunterzuladen.",
    "ENTITLEMENT_MANAGEMENT_INSTRUCTIONS_PART_4":"Rufen Sie Berechtigungsdaten für alle aktiven Kunden gleichzeitig ab, indem Sie <b>Alle exportieren</b> wählen.",
    "ENTITLEMENT_MANAGEMENT_LABEL_ACTION":"Aktion",
    "ENTITLEMENT_MANAGEMENT_LABEL_ADD_ENTITLEMENT":"Berechtigung hinzufügen",
    "ENTITLEMENT_MANAGEMENT_LABEL_BULK_ADD_ENTITLEMENT":"Berechtigungen hinzufügen",
    "ENTITLEMENT_MANAGEMENT_LABEL_COMPLETE":"Komplett",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATED_DATE":"Erstellungsdatum",
    "ENTITLEMENT_MANAGEMENT_LABEL_CREATION_STATUS":"Erstellungsstatus",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT":"Abteilung",
    "ENTITLEMENT_MANAGEMENT_LABEL_DEPARTMENT_NAME":"Abteilungsname",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_COUNT":"Anzahl der Berechtigungen",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_ID":"Ich würde",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_LIST":"Berechtigungsliste",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_NAME":"Name",
    "ENTITLEMENT_MANAGEMENT_LABEL_ENTITLEMENT_PRICE_CHANGE_HEDER":" Neuen Preis für Anspruch festlegen",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT":"Export",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_ALL":"Alles exportieren",
    "ENTITLEMENT_MANAGEMENT_LABEL_EXPORT_FILTER":"Export",
    "ENTITLEMENT_MANAGEMENT_LABEL_INFORMATION":"Dieser Preis ist ab dem Zeitpunkt der nächsten Rechnungserstellung gültig und bleibt danach gültig.",
    "ENTITLEMENT_MANAGEMENT_LABEL_PRICE":"Verkaufspreis",
    "ENTITLEMENT_MANAGEMENT_LABEL_SEQUENCE":"Sequenz starten",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE":"Seite ",
    "ENTITLEMENT_MANAGEMENT_LABEL_SITE_NAME":"Seite-Name",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_COMPLETED":"Vorgang abgeschlossen. Weitere Einzelheiten finden Sie im Überwachungsprotokoll.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Anfrage zum Hinzufügen von Berechtigungen wird angenommen und verarbeitet",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"Anfrage zum Hinzufügen von Berechtigungen fehlgeschlagen.",
    "ENTITLEMENT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Bitte geben Sie eine Zahlenfolge an",
    "ENTITLEMENT_MANAGEMENT_POPUP_CONFIRMATION_REVERT_SALE_PRICE":"Möchten Sie den Verkaufspreis für diesen Anspruch wirklich zurücksetzen?",
    "ENTITLEMENT_MANAGEMENT_PRICE_CHANGE_SUCCESSFULLY":"Anspruchspreis erfolgreich geändert.",
    "ENTITLEMENT_MANAGEMENT_PRICE_REVERTED_SUCCESSFULLY":"Der Berechtigungspreis wurde erfolgreich zurückgesetzt.",
    "ENTITLEMENT_MANAGEMENT_SEQUENCE_TOOLTIP":"Das fortlaufende Inkrement wird als Suffix in Berechtigungsnamen verwendet.",
    "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Status der Berechtigungserstellung",
    "ENTITLEMENT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Bitte warten Sie, während die Berechtigungen erstellt werden.",
    "ENTITLEMENT_MANAGEMENT_VALIDATION_MESSAGE_MIN_AND_MAX":"Der Wert muss größer oder gleich -100 und kleiner oder gleich 100 sein.",
    "ENTITY_DESC_AZURE_GROUP":"Azure-Gruppe",
    "ENTITY_DESC_BILLING_PERIODS":"Abrechnungszeiträume",
    "ENTITY_DESC_BILLING_PROVIDER_RESELLER_ID":"Reseller-ID des Abrechnungsanbieters",
    "ENTITY_DESC_BILLING_PROVIDER_USER":"Benutzer des Abrechnungsanbieters",
    "ENTITY_DESC_CART":"Wagen",
    "ENTITY_DESC_CART_LINE_ITEM":"Warenkorb-Werbebuchung",
    "ENTITY_DESC_COUPON":"Coupon",
    "ENTITY_DESC_CURRENCY":"Währung",
    "ENTITY_DESC_CUSTOM_OFFER":"Individuelles Angebot",
    "ENTITY_DESC_CUSTOMER":"Kunde",
    "ENTITY_DESC_CUSTOMER_PRODUCT":"Kundenprodukt",
    "ENTITY_DESC_CUSTOMER_USER":"Kundenbenutzer",
    "ENTITY_DESC_DEPARTMENT":"Abteilung",
    "ENTITY_DESC_INVOICE":"Rechnung",
    "ENTITY_DESC_JOB_LOGS":"Jobprotokolle",
    "ENTITY_DESC_JOBS":"Arbeitsplätze",
    "ENTITY_DESC_LOCAL_STORE_CUSTOMER":"Kunde im örtlichen Geschäft",
    "ENTITY_DESC_LOCAL_STORE_RESELLER":"Wiederverkäufer im örtlichen Geschäft",
    "ENTITY_DESC_PARTNER":"Partner",
    "ENTITY_DESC_PARTNER_SETTINGS":"Partnereinstellungen",
    "ENTITY_DESC_PARTNER_USER":"Partnerbenutzer",
    "ENTITY_DESC_PAYMENT_PROFILE":"Zahlungsprofil",
    "ENTITY_DESC_PLAN":"Planen",
    "ENTITY_DESC_PLAN_PRODUCT":"Produkt planen",
    "ENTITY_DESC_PLAN_PRODUCT_VOUCHER":"Produktgutschein planen",
    "ENTITY_DESC_PRODUCT":"Produkt",
    "ENTITY_DESC_PRODUCT_VARIANT":"Produktvariante",
    "ENTITY_DESC_PRODUCT_VARIANT_VOUCHER":"Produktvarianten-Gutschein",
    "ENTITY_DESC_PROVIDER_CUSTOMER":"Anbieterkunde",
    "ENTITY_DESC_RESELLER":"Wiederverkäufer",
    "ENTITY_DESC_RESELLER_PAYMENT_PROFILE":"Zahlungsprofil des Wiederverkäufers",
    "ENTITY_DESC_RESELLER_PLAN":"Reseller-Plan",
    "ENTITY_DESC_SERVICE_PROVIDER_CUSTOMER":"Kunde des Dienstleisters",
    "ENTITY_DESC_SHOP":"Shop",
    "ENTITY_DESC_SITE":"Seite ",
    "ENTITY_DESC_SITE_DEPARTMENT":"Standortabteilung",
    "ENTITY_DESC_TAG":"Etikett",
    "ENTITY_DESC_TAX":"Steuer",
    "ENTITY_DESC_TERMS_AND_CONDITIONS":"Geschäftsbedingungen",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS":"Aktualisieren Sie die Anbietereinstellungen",
    "ENTITY_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Fehler beim Aktualisieren der Anbietereinstellungen",
    "ENTITY_MAPPING":"Entitätszuordnung",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_RELEASE_MAPPING":"Möchten Sie die Zuordnung wirklich freigeben?",
    "ENTITY_MAPPING_MAPPING_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für <b> {{customerName}}</b> wirklich speichern?",
    "ENTITY_MAPPING_NOTIFIER_TEXT_ATLEAST_MAP_ONE_PRODUCT":"Mindestens 1 Produkt zuordnen",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DISABLED_DEFAULT_CONTRACTS":"Diese Verträge sind nicht mehr Standard: {{ContractNames}}",
    "ENTITY_MAPPING_NOTIFIER_TEXT_DUPLICATE_NUMBER_IS_NOT_ALLOWED":"Eine doppelte Sequenznummer ist nicht zulässig",
    "ENTITY_MAPPING_NOTIFIER_TEXT_MAPPING_SAVED_SUCCESSFULLY":"Zuordnungen erfolgreich gespeichert",
    "ENTITY_NAME":"Entitätsname",
    "ENVIRONMENTS_TABLE_HEADER_ACTIONS":"Aktionen",
    "ENVIRONMENTS_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
    "ENVIRONMENTS_TABLE_HEADER_COUNTRY":"Land",
    "ENVIRONMENTS_TABLE_HEADER_EMAIL":"Email",
    "ENVIRONMENTS_TABLE_HEADER_STATUS":"Status",
    "ERROR__DATA_CONTACT_TYPE":"kontaktart",
    "ERROR__DATA_PAYMENT_TYPE":"Zahlungsart",
    "ERROR__DATA_PROFILE_TYPE":"Profiltyp",
    "ERROR__DATA_URL":"URL",
    "ERROR_AVAILABLE_IN_BATCH":"Fehler ist im Stapel verfügbar.",
    "ERROR_BILLING_PROVIDER_NAME":"Name des Abrechnungsanbieters",
    "ERROR_BUTTON_CONTINUE":"Fortsetzen",
    "ERROR_C3_CUSTOMER_PRODUCT_ID":"C3 Kundenprodukt-ID",
    "ERROR_C3_ENTITY_NAME":"Entitätsname",
    "ERROR_C3_RECORD_ID":"Datensatz-ID",
    "ERROR_C3_USER_ID":"Benutzeridentifikation",
    "ERROR_CART_QUANTITY_INVALID_QUANTITY":"Ungültige Warenkorbmenge",
    "ERROR_DATA_BILLING_CUSTOMER_ID":"Kunden-ID abrechnen",
    "ERROR_DATA_BILLING_REFERENCE_ID":"Rechnungsreferenz-ID",
    "ERROR_DATA_C3_CUSTOMER_ID":"Kunde C3 Id",
    "ERROR_DATA_C3_RECORD_ID":"Datensatz-ID",
    "ERROR_DATA_C3_RESELLER_ID":"Reseller C3 Id",
    "ERROR_DATA_C3_SUBSCRIPTION_ID":"C3 Abonnement-ID",
    "ERROR_DATA_COUNTRY_CODE":"Landesvorwahl",
    "ERROR_DATA_CUSTOMER_NAME":"Kundenname",
    "ERROR_DATA_DOMAIN_PREFIX":"Domain Name",
    "ERROR_DATA_EMAIL_ID":"E-Mail-ID",
    "ERROR_DATA_ENTITY_NAME":"Entitätsname",
    "ERROR_DATA_EVENT_NAME":"Veranstaltungsname",
    "ERROR_DATA_HAS_SUPPORTS_RESELLERS_WITH_MPNID":"Unterstützt Wiederverkäufer mit MPN-ID",
    "ERROR_DATA_INPUT_JSON":"Eingabedaten",
    "ERROR_DATA_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "ERROR_DATA_PROVIDER_NAME":"Anbietername",
    "ERROR_DATA_RESELLER_ID":"Referenz ID",
    "ERROR_DATA_RESELLER_MPNID":"Reseller Provider Id",
    "ERROR_DATA_RESELLER_PROVIDER_ID":"Reseller Provider Id",
    "ERROR_DATA_SEARCH_VALUE":"Suchwert",
    "ERROR_DATA_SUBSCRIPTION_ID":"Abonnement-ID",
    "ERROR_DATA_TAX_CODE":"Steuer-Code",
    "ERROR_DATA_TRANSACTION_REFERENCE":"Transaktionsreferenz",
    "ERROR_DATA_USER_ID":"Benutzeridentifikation",
    "ERROR_DELETE_DEPARTMENT":"Diese Abteilung enthält zugehörige Datensätze, sodass dieser Datensatz nicht gelöscht werden kann.",
    "ERROR_DELETE_DEPARTMENT_PRODUCTS":"Diese Abteilung enthält zugehörige gekaufte Produkte, daher kann dieser Datensatz nicht gelöscht werden.",
    "ERROR_DELETE_SITE":"Diese Site enthält zugehörige Datensätze, sodass dieser Datensatz nicht gelöscht werden kann.",
    "ERROR_DELETE_SITE_PRODUCT":"Diese Website enthält zugehörige gekaufte Produkte, daher kann dieser Datensatz nicht gelöscht werden.",
    "ERROR_DESC_ADD_NEW_PROVIDER_TENANT_FAILED":"Mandant im Anbieter kann nicht erstellt werden.",
    "ERROR_DESC_AGENT_NAME_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Agentenname darf nicht null sein",
    "ERROR_DESC_AUTHORIZE_NET_NO_RESPONSE_EXCEPTION":"Keine Antwort von Authorize.net",
    "ERROR_DESC_AUTHORIZE_NET_UNABLE_TO_FIND_CUSTOMER_EXCEPTION":"Kunde konnte nicht gefunden werden.",
    "ERROR_DESC_BAD_INPUR_CHANGE_CUSTOMER_PRODUCT_STATUS":"Dieses Produkt kann in Ihren Abonnements nicht gefunden werden",
    "ERROR_DESC_BAD_INPUT":"Es scheint etwas mit Ihrer Eingabe nicht zu stimmen",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
    "ERROR_DESC_BAD_INPUT_BULK_PRODUCT_MAPPING_FILE_UPLOADED_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
    "ERROR_DESC_BAD_INPUT_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
    "ERROR_DESC_BAD_INPUT_CUSTOM_NOTIFICATION":"Beim Speichern benutzerdefinierter Benachrichtigungsdetails ist ein Fehler aufgetreten",
    "ERROR_DESC_BAD_INPUT_DOWNLOAD_LICENSE_CONSUMPTION_REPORT":"Beim Herunterladen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_GET_CUSTOMER":"Der Kundenabruf ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
    "ERROR_DESC_BAD_INPUT_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer schlechten Eingabe fehlgeschlagen.",
    "ERROR_DESC_BAD_INPUT_LICENSE_CONSUMPTION_REPORT":"Beim Abrufen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROFILE":"Aktion fehlgeschlagen, da das Zahlungsprofil fehlt.",
    "ERROR_DESC_BAD_INPUT_MISSING_PAYMENT_PROVIDER_CONFIGURATION":"Aktion fehlgeschlagen, da die Konfiguration des Zahlungsprofils fehlt",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_RECORD_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
    "ERROR_DESC_BAD_INPUT_MULTIPLE_PAYMENT_REPORT_FILE_UPLOADED_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILE_SAVE":"Beim Speichern Ihres Zahlungsprofils ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROFILES":"Es scheint ein Problem mit Ihren Zahlungsprofilen zu geben. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_PROVIDER":"Es scheint ein Problem mit Ihrem Abrechnungsanbieter zu geben. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_PAYMENT_SAVE":"Es gibt ein Problem beim Speichern Ihres Zahlungsprofils. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_POWERBI_REPORT_REPORT":"Beim Abrufen des Lizenzverbrauchsberichts ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_PROVIDER_NOT_FOUND":"Anbieter konnte nicht gefunden werden",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_FILE_UPLOADED_IS_EMPTY":"Datei ist leer",
    "ERROR_DESC_BAD_INPUT_REPORT_PARTNER_USAGE_UPLOADED_WITH_WRONG_FILE_FORMAT":"Dateiformat ist nicht kompatibel",
    "ERROR_DESC_BAD_INPUT_SERVICE_NOT_FOUND":"Service ist nicht implementiert. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_SUPPORTED_PAYMENT_TYPES":"Es scheint ein Problem mit Ihren unterstützten Zahlungsarten für den Abrechnungsanbieter zu geben. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_BAD_INPUT_WEBHOOK_NOTIFICATION":"Beim Speichern der Webhook-Benachrichtigungsdetails ist ein Fehler aufgetreten",
    "ERROR_DESC_BAD_REQUEST_ANOTHER_JOB_IS_IN_PROGRESS":"Ungültige Anfrage - ein anderer Auftrag wird ausgeführt",
    "ERROR_DESC_BILLING_CUSTOMER_CREATION":"Beim Erstellen/Aktualisieren des Rechnungskunden ist ein Fehler aufgetreten. Wenden Sie sich an den Support.",
    "ERROR_DESC_BULK_ONBOARD_RESELLERS_NOT_SUPPORTED_HAS_PARTNER_NOT_SUPPORTING_RESELLERS_WITH_MPNID":"Bulk Onboard Reseller nicht unterstützt hat Partner, die Reseller mit MPN-ID nicht unterstützen",
    "ERROR_DESC_C3_CUSTOM_ERROR_CART_CREATED_WIH_ERRORS":"Der Warenkorb enthält fehlerhafte Werbebuchungen. Bitte reparieren Sie sie, um mit diesem Kauf fortzufahren.",
    "ERROR_DESC_CART_SUBSCRIPTION_ALREADY_USED_BY_THIS_CUSTOMER":"Dieses Abonnement wird bereits von diesem Kunden verwendet",
    "ERROR_DESC_CHANGE_CUSTOMER_PLAN":"Beim Ändern des Kundenplans ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_CHANGE_PLAN_SOURCE_PLAN_GET":"Beim Abrufen von Quellplänen für die Änderungsplanfunktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_CHANGE_PLAN_TARGET_PLAN_GET":"Beim Abrufen von Zielplänen für die Änderungsplanfunktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_COMMISSION_END_DATE_CANNOT_BE_LESS_THAN_COMMISSION_START_DATE":"Das Enddatum der Provision darf nicht vor dem Startdatum der Provision liegen",
    "ERROR_DESC_COMMISSION_END_DATE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Enddatum der Provision darf nicht null sein",
    "ERROR_DESC_COMMISSION_PERCENTAGE_REQUIRED_IN_IMPORT_PRODUCT_COMMISSIONS":"Provisionsprozentsatz darf nicht null sein",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_DELETE_ADDITION_BY_ID":"Fehler von ConnectWise Manage beim Versuch, den Löschzusatz nach ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITION_BY_ID_AND_AGREEMENT_ID":"Fehler von ConnectWise Manage beim Versuch, das Hinzufügen nach ID und Vereinbarungs-ID zu erhalten",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_ADDITIONS_BY_AGREEMENT_ID":"Fehler von ConnectWise Manage beim Versuch, Ergänzungen nach Vereinbarungs-ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENT_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Vertragsdetails nach ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREEMENTS":"Fehler von ConnectWise Manage beim Versuch, Vereinbarungen zu erhalten",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENT_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Vertragsdetails nach ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_AGREMENTS":"Fehler von ConnectWise Manage beim Versuch, Vereinbarungen zu erhalten",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEM_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Katalogelement nach ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CATALOG_ITEMS":"Fehler von ConnectWise Manage beim Versuch, Katalogelemente abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMER_DETAIL_BY_ID":"Fehler von ConnectWise Manage beim Versuch, Kundendaten nach ID abzurufen",
    "ERROR_DESC_CONNECT_WISE_FAILED_TO_GET_CUSTOMERS":"Fehler von ConnectWise Manage beim Versuch, Kunden zu gewinnen",
    "ERROR_DESC_DUPLICATE_ENTRIES_C3ID_IN_IMPORT_PAYMENTS_RECORD":"Doppelte Einträge in importierten Zahlungen",
    "ERROR_DESC_DUPLICATE_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Doppelte Einträge im Katalog",
    "ERROR_DESC_DUPLICATE_ENTRIES_WITH_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Doppelte Einträge im Katalog",
    "ERROR_DESC_DUPLICATE_ENTRY_EXISTING_IN_THE_INPUT":"In der Eingabe ist ein doppelter Eintrag vorhanden",
    "ERROR_DESC_ENGAGE_INPUT_FIELD_BLANK_TEXT":"Das Formular enthält Fehler",
    "ERROR_DESC_ENQUING_ENTITY_DATA_RELOAD_FOR_PSA":"Beim Initiieren der Entitätsdatenreferenzierung von einem externen Dienst ist ein Fehler aufgetreten",
    "ERROR_DESC_ERROR_PBI_INVALID_CLIENT_ID":"ClientId ist ungültig. Bitte registrieren Sie Ihre Anwendung als native App unter https://dev.powerbi.com/apps und wenden Sie sich an den Support.",
    "ERROR_DESC_ERROR_PBI_INVALID_GROUP_ID":"Die Gruppen-ID ist ungültig. Bitte wenden Sie sich an das Support-Team.",
    "ERROR_DESC_ERROR_PBI_INVALID_PASSWORD":"Das Passwort ist ungültig. Bitte wenden Sie sich an das Support-Team.",
    "ERROR_DESC_ERROR_PBI_INVALID_REPORT_ID":"Der Bericht ist ungültig. Bitte wenden Sie sich an das Support-Team.",
    "ERROR_DESC_ERROR_PBI_INVALID_USER_NAME":"Der Benutzername ist ungültig. Bitte wenden Sie sich an das Support-Team.",
    "ERROR_DESC_ERROR_WHILE_DELETING_CUSTOMER":"Beim Löschen des Kunden ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_ERROR_WHILE_UPLOADING_IMAGE":"Beim Hochladen Ihres Bildes ist ein Fehler aufgetreten. Bitte überprüfen Sie die Bildabmessungen noch einmal.",
    "ERROR_DESC_FAILED_DUE_TO_BAD_REQUEST":"Fehlgeschlagen aufgrund einer schlechten Anfrage. Bitte versuche es erneut.",
    "ERROR_DESC_FAILED_DUE_TO_CONFLICT_ISSUE":"Fehlgeschlagen aufgrund eines Konflikts. Bitte überprüfen Sie Ihre Anmeldeinformationen erneut",
    "ERROR_DESC_FAILED_DUE_TO_GATEWAY_TIMEOUT":"Gateway-Zeitüberschreitung. Bitte versuche es erneut.",
    "ERROR_DESC_FAILED_DUE_TO_SERVICE_UNAVAILABLE":"Dienst nicht verfügbar. Bitte überprüfen Sie die Konfiguration",
    "ERROR_DESC_FAILED_PAYMENT_INITIATION":"Beim Einleiten der Zahlung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_FAILED_TO_DCRYPT_VALUE":"Beim Entschlüsseln des Werts ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_ENCRYPT_VALUE":"Beim Verschlüsseln des Werts ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_FIND_CONTROL_PANEL_AD_APP_ID_OR_PARTNER_TENANT_ID":"AD APP-ID oder Partner-Mandanten-ID des Anbieters der Systemsteuerung sind nicht vorhanden.",
    "ERROR_DESC_FAILED_TO_FIND_MICROSOFT_PRICING_API_AD_APP_ID_OR_PARTNER_TENANT_ID":"Microsoft-Preis-API-AD-APP-ID oder Partner tenant-ID sind nicht vorhanden.",
    "ERROR_DESC_FAILED_TO_IMPORT_MULTIPLE_PAYMENT_RECORD":"Beim Versuch, mehrere Zahlungen zu importieren, ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_CATALOGUE_INPUT":"Beim Versuch, die Katalogeingabe zu importieren, ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_COMMISSIONS_DETAILS":"Beim Versuch, Produktprovisionen zu importieren, ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_IMPORT_PRODUCT_EXTENSION_DETAILS":"Details zur Produkterweiterung konnten nicht importiert werden",
    "ERROR_DESC_FAILED_TO_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_DUE_TO_VALIDATION_ERRORS":"Reseller konnte aufgrund von Validierungsfehlern nicht unter Bulk-Onboarding aufgenommen werden",
    "ERROR_DESC_FAILED_TO_PLACE_REQUEST_FOR_SIGNUP":"Beim Platzieren der Registrierungsanfrage ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_SEND_INVOICE_TO_THE_GIVEN_LIST_OF_EMAILS":"E-Mail konnte nicht an die angegebene Benutzerliste gesendet werden. Eine Überarbeitung Ihrer SMTP-Konfigurationen kann helfen, dieses Problem zu lösen.",
    "ERROR_DESC_FAILED_TO_STAGE_PAYMENT_RECORD_INPUT":"Beim Versuch, die Zahlungsdatensatzeingabe zu importieren, ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_CATALOGUE_INPUT":"Beim Staging der Produktkatalogeingabe ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_STAGE_PRODUCT_COMMISSIONS_DETAILS":"Beim Bereitstellen der Produktprovisionsdetails ist ein Fehler aufgetreten",
    "ERROR_DESC_FAILED_TO_SYNC_RESERVED_INSTANCES_SUBSCRIPTION_DUE_TO_PRODUCT_UNAVAILABILITY":"Das Abonnement für reservierte Instanzen konnte aufgrund der Nichtverfügbarkeit des Produkts nicht synchronisiert werden. Bitte wenden Sie sich an das Support-Team.",
    "ERROR_DESC_FAILED_TO_UPDATE_THE_PRODUCT_OWNERSHIP_FOR_USAGE_PRODUCT":"Sie können den Dezimalprozentsatz nicht auf einen Wert des verfügbaren Prozentsatzes aktualisieren.",
    "ERROR_DESC_FAILED_TO_UPLOAD_IMAGE":"Bild konnte nicht hochgeladen werden. Bitte überprüfen Sie Ihre Internetverbindung und stellen Sie sicher, dass die Bildgröße den angegebenen Spezifikationen entspricht.",
    "ERROR_DESC_GET_BATCH_STATUS_OF_BULK_ONBOARD_PROVIDER_RESELLERS":"Beim Abrufen des Bulk-Onboard-Batch-Status von Provider-Resellern ist ein Fehler aufgetreten",
    "ERROR_DESC_GETTING_ONGOING_RELOAD_FOR_PSA":"Beim Überprüfen des Status für Entitätsdaten, die von einem externen Dienst stammen, ist ein Fehler aufgetreten",
    "ERROR_DESC_GETTING_PENDING_PAYMENTS_EXCEPTION":"Beim Abrufen ausstehender Zahlungen ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_INVALID_C3ID_IN_IMPORT_PAYMENT_RECORD":"Fehlende oder ungültige C3Id in der Eingabe",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Kosten für den Partner sollten +ve Zahl aus der Eingabe sein",
    "ERROR_DESC_INVALID_COST_TO_PARTNER_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Kosten für den Partner sollten aus der Eingabe dezimal sein",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlender oder ungültiger Währungscode in der Eingabe",
    "ERROR_DESC_INVALID_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Währungscode",
    "ERROR_DESC_INVALID_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Der Währungscode sollte mit der Produktwährung aus der Eingabe übereinstimmen",
    "ERROR_DESC_INVALID_CUSTOMER_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Ungültiger Währungscode in der Eingabe",
    "ERROR_DESC_INVALID_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende oder ungültige Kunden-ID in der Eingabe",
    "ERROR_DESC_INVALID_CUSTOMER_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Sie können die Nutzung für diese Anleitung nicht hochladen (Kunde)",
    "ERROR_DESC_INVALID_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige interne Produkt-ID im Katalog",
    "ERROR_DESC_INVALID_DISPLAY_SEQUENCE_IN_IMPORT_PRODUCT_CODES":"Ungültige Sequenz im Katalog",
    "ERROR_DESC_INVALID_ENTITY_CURRENCY_CODE_NOT_MATCH_IN_IMPORT_PAYMENT_RECORD":"Der Währungscode sollte mit der relevanten Entität übereinstimmen",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PARTNER_PRODUCTS_USAGE_DATA":"Ungültige Einträge im Katalog",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_CODES":"Ungültige Einträge im Katalog",
    "ERROR_DESC_INVALID_ENTRIES_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige Einträge im Katalog",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Zahlungsbetrag",
    "ERROR_DESC_INVALID_PAYMENT_AMOUNT_POSITIVE_IN_IMPORT_PAYMENT_RECORD":"Der Zahlungsbetrag sollte positiv sein",
    "ERROR_DESC_INVALID_PAYMENT_DATE_FORMAT_IN_IMPORT_PAYMENT_RECORD":"Ungültiges Format des Zahlungsdatums",
    "ERROR_DESC_INVALID_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Ungültiges Zahlungsdatum",
    "ERROR_DESC_INVALID_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende oder ungültige Produkt-ID in der Eingabe",
    "ERROR_DESC_INVALID_PRODUCT_OWNERSHIP_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Sie können die Nutzung für das Produkt nicht hochladen, da das Produkt entweder nicht existiert oder Ihnen nicht gehört.",
    "ERROR_DESC_INVALID_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Ungültige Anbieterprodukt-ID im Katalog",
    "ERROR_DESC_INVALID_UNIT_OF_MEASURE_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Die Maßeinheit sollte nicht ganzzahlig/dezimal aus der Eingabe sein",
    "ERROR_DESC_INVALID_UNITS_DATA_TYPE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Einheiten sollten ganzzahlig von Input sein",
    "ERROR_DESC_INVALID_UNITS_POSITIVE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Einheiten sollten +ve Zahl von Input sein",
    "ERROR_DESC_INVALID_USAGE_DATE_FORMAT_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte das Format JJJJ-MM-TT aus der Eingabe haben",
    "ERROR_DESC_INVALID_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte der Datumstyp von Input sein",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Das Verwendungsdatum sollte nach dem Eingabedatum liegen",
    "ERROR_DESC_INVALID_USAGE_DATE_PAST_IN_IMPORT_PAYMENT_RECORD":"Das Zahlungsdatum sollte in der Vergangenheit liegen",
    "ERROR_DESC_INVOICE_IS_NOT_GENERATED_FOR_PRODUCTS_PURCHASED":"Für gekaufte Produkte wird keine Rechnung erstellt",
    "ERROR_DESC_ITEM_IS_NOT_PURCHASABLE":"Artikel ist nicht käuflich",
    "ERROR_DESC_MATCHING_PLAN_OFFER":"Beim Abgleich der Planangebote für Planänderungen ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED":"Der Kunde wurde erfolgreich erstellt, aber die Sicherheitsgruppen konnten der GDAP-Anforderung nicht zugewiesen werden. Schließen Sie dies bitte im Partner Center ab, da weitere Aktionen andernfalls fehlschlagen.",
    "ERROR_DESC_MESSAGE_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_FAILED_FOR_CUSTOMER_PUBLIC_SIGNUP":"Bei der GDAP-Anfrage ist ein Fehler aufgetreten. Arbeiten Sie bitte mit Ihrem Partner zusammen.",
    "ERROR_DESC_MESSAGE_INVALID_CUSTOMER":"Ungültiger Kunde",
    "ERROR_DESC_MISMATCH_BETWEEN_PROVIDER_RESELLERID_SPECIFIED_AND_RESELLERID_IN_PROVIDER":"Nichtübereinstimmung zwischen der angegebenen ProviderResellerId und der ResellerId im Anbieter",
    "ERROR_DESC_MISSING_AGENT_NAME_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender Agentenname im Katalog",
    "ERROR_DESC_MISSING_C3ID_IN_IMPORT_PAYMENT_RECORD":"Fehlende C3Id in der Eingabe",
    "ERROR_DESC_MISSING_COMMISSION_END_DATE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlendes Enddatum der Provision im Katalog",
    "ERROR_DESC_MISSING_COMMISSION_PERCENTAGE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender Provisionsprozentsatz im Katalog",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende CostToPartner in der Eingabe",
    "ERROR_DESC_MISSING_COST_TO_PARTNER_REQUIRED_FOR_PERCENTAGE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"CostToPartner ist für den Prozentsatz der Abrechnungsart erforderlich",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlender CurrencyCode in der Eingabe",
    "ERROR_DESC_MISSING_CURRENCY_CODE_IN_IMPORT_PAYMENT_RECORD":"Fehlender Währungscode in der Eingabe",
    "ERROR_DESC_MISSING_CUSTOMER_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Kunden-ID in der Eingabe",
    "ERROR_DESC_MISSING_CUSTOMER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlende interne Produkt-ID im Katalog",
    "ERROR_DESC_MISSING_OFFER_ID_IN_IMPORT_PRODUCT_CODES":"Fehlende Angebots-ID im Katalog",
    "ERROR_DESC_MISSING_OR_INVALID_BILLING_CYCLE_IN_IMPORT_PRODUCT_CODES":"Fehlender oder ungültiger Abrechnungszeitraum im Katalog",
    "ERROR_DESC_MISSING_OR_INVALID_CATEGORY_IN_IMPORT_PRODUCT_CODES":"Fehlende oder ungültige Kategorie im Katalog",
    "ERROR_DESC_MISSING_OR_INVALID_PROVIDER_IN_IMPORT_PRODUCT_CODES":"Fehlender oder ungültiger Anbieter im Katalog",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_FOR_BULK_UPLOAD_RESELLERS_DETAILS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_MULTIPLE_PAYMENTS_RECORD":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_COMMISSIONS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_PRODUCT_EXTENSIONS":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_MULTIPLE_PAYMENTS":"Bitte überprüfen Sie die von Ihnen hochgeladene Datei, da sie offenbar nicht mit der empfohlenen Vorlage übereinstimmt",
    "ERROR_DESC_MISSING_OR_INVALID_TEMPLATE_REPORT_USAGE":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_OR_INVALID_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Bitte überprüfen Sie die hochgeladene Datei, da sie anscheinend nicht mit der empfohlenen Vorlage übereinstimmt.",
    "ERROR_DESC_MISSING_PAYMENT_AMOUNT_IN_IMPORT_PAYMENT_RECORD":"Fehlender oder ungültiger Zahlungsbetrag in der Eingabe",
    "ERROR_DESC_MISSING_PAYMENT_DATE_IN_IMPORT_PAYMENT_RECORD":"Fehlendes Zahlungsdatum in der Eingabe",
    "ERROR_DESC_MISSING_PRODUCT_ID_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Produkt-ID in der Eingabe",
    "ERROR_DESC_MISSING_PROVIDER_PRODUCT_ID_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlende Anbieterprodukt-ID im Katalog",
    "ERROR_DESC_MISSING_SP_CODE_IN_IMPORT_PRODUCT_COMMISSIONS":"Fehlender SPCode im Katalog",
    "ERROR_DESC_MISSING_UNIT_OF_MEASURE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Maßeinheit in der Eingabe",
    "ERROR_DESC_MISSING_UNITS_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlende Einheiten in der Eingabe",
    "ERROR_DESC_MISSING_USAGE_DATE_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Fehlendes UsageDate in der Eingabe",
    "ERROR_DESC_MS_AZURE_MANAGEMENT_API_FAILED_TO_GET_ACCESS":"Es konnte keine Verbindung zur Microsoft Azure Management-API hergestellt werden.",
    "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_NAME_ERROR":"Fehler vom Microsoft Partner Center beim Aktualisieren des Kundenproduktnamens",
    "ERROR_DESC_MS_CHANGE_CUSTOMER_PRODUCT_STATUS_ERROR":"Beim Versuch, den Abonnementstatus zu ändern, ist eine Partner Center-Ausnahme aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Fehler vom Partnercenter beim Überprüfen der MPNID. Bitte überprüfen Sie Ihre Eingabe erneut.",
    "ERROR_DESC_MS_FAILED_LOGIN_TO_PARTNER_CENTER":"Beim Herstellen einer Verbindung zum Partner Center tritt ein Problem auf. Bitte lassen Sie den Administrator das Token aktualisieren und versuchen Sie es erneut. Wenden Sie sich an den Support, um weitere Unterstützung zu erhalten.",
    "ERROR_DESC_MS_FAILED_TO_ASSIGN_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS_TO_SECURITY_GROUPS":"Beim Zuweisen von Sicherheitsgruppen zum delegierten Administratorzugriff des Kunden ist ein Fehler aufgetreten",
    "ERROR_DESC_MS_FAILED_TO_CREATE_USER":"Fehler vom Partnercenter beim Erstellen des Benutzers",
    "ERROR_DESC_MS_FAILED_TO_DELETE_USER":"Fehler vom Partnercenter beim Löschen des Benutzers",
    "ERROR_DESC_MS_FAILED_TO_GET_ADMIN_AGENT_SECURITY_GROUPS":"Beim Abrufen der Sicherheitsgruppen des Microsoft-Administrator-Agents ist ein Fehler aufgetreten",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_CUSTOMERS":"Fehler vom Partner Center beim Versuch, alle Kunden zu gewinnen",
    "ERROR_DESC_MS_FAILED_TO_GET_ALL_RESELLERS":"Fehler vom Microsoft Partner Center beim Versuch, alle Reseller abzurufen",
    "ERROR_DESC_MS_FAILED_TO_GET_COUNTRY_VALIDATION_RULES":"Fehler vom Partner Center beim Versuch, Ländervalidierungsregeln abzurufen",
    "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER":"Fehler vom Partner Center beim Versuch, Kunden zu gewinnen",
    "ERROR_DESC_MS_FAILED_TO_GET_CUSTOMER_DELEGATED_ADMIN_RELATIONSHIPS":"Beim Abrufen der Details zur delegierten Administratorbeziehung zwischen Anbieter und Kunde vom Partner Center ist ein Fehler aufgetreten.",
    "ERROR_DESC_MS_FAILED_TO_GET_PARTNER_PROFILE":"MS Partner Center hat beim Versuch, Ihr Profil abzurufen, einen Fehler ausgegeben.",
    "ERROR_DESC_MS_FAILED_TO_GET_REFRESH_TOKEN":"Fehler beim Abrufen des Aktualisierungstokens.",
    "ERROR_DESC_MS_FAILED_TO_GET_TENANT_USER":"Fehler vom Partnercenter beim Abrufen des Mandantenbenutzers",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ASSIGNED_LICENSES":"Fehler vom Partnercenter beim Abrufen der vom Benutzer zugewiesenen Lizenzen",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_DETAILS":"Fehler vom Partnercenter beim Abrufen der Benutzerdetails",
    "ERROR_DESC_MS_FAILED_TO_GET_USER_ROLES":"Fehler vom Partnercenter beim Abrufen von Benutzerrollen",
    "ERROR_DESC_MS_FAILED_TO_GET_USERS":"Fehler vom Partnercenter beim Abrufen von Benutzern",
    "ERROR_DESC_MS_FAILED_TO_REGISTER_CUSTOMER_CONSENT":"Fehler vom Partner Center bei der Registrierung der Kundeneinwilligung",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER":"Fehler vom Partnercenter beim Aktualisieren des Benutzers",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Fehler vom Partnercenter beim Aktualisieren der Benutzerlizenzzuweisungen",
    "ERROR_DESC_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Fehler vom Partnercenter beim Aktualisieren des Benutzerkennworts",
    "ERROR_DESC_MS_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Fehler vom Partnercenter beim Erhalten von Kunden.",
    "ERROR_DESC_MS_IS_DOMAIN_NAME_AVAILABLE":"Domainname ist nicht verfügbar",
    "ERROR_DESC_MS_PRICING_API_FAILED_TO_GET_ACCESS":"Fehler beim Abrufen des Zugriffs von der Microsoft-Preis-API",
    "ERROR_DESC_MULTIPLE_PAYMENT_FILE_MISSING_IN_VALIDATE_REQUEST":"Bitte überprüfen Sie noch einmal. Datei fehlt in der Anforderung zur Datenvalidierung",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_AUTOTASK":"Keine Daten zum Staging für Autotask verfügbar",
    "ERROR_DESC_NO_DATA_AVAILABLE_TO_STAGE_FOR_CONNECTWISE_MANAGE":"Keine Daten zum Staging für ConnectWise Manage verfügbar",
    "ERROR_DESC_NO_PROVIDER_RESELLER_DETAIL_FOUND_IN_MICROSOFT_PARTNER_CENTER":"Im Microsoft Partner Center wurden keine Anbieter-Reseller-Details gefunden",
    "ERROR_DESC_NO_RESELLER_MPNID_FOUND_IN_C3":"In C3 wurde kein Wiederverkäufer mit MPN-ID gefunden",
    "ERROR_DESC_NOT_ELIGIBLE_TO_PURCHASE_ITEM":"Der Partner ist nicht berechtigt, einen Artikel zu kaufen",
    "ERROR_DESC_NOT_FOUND_ERROR":"Die Anfrage konnte nicht gefunden werden",
    "ERROR_DESC_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "ERROR_DESC_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Beim Planen der Bestellung ist ein Fehler aufgetreten.",
    "ERROR_DESC_ONBOARDING_FAILED_DUE_TO_INVALID_TENANT":"Onboading fehlgeschlagen aufgrund eines ungültigen Mandanten.",
    "ERROR_DESC_PARENT_OFFER_FOR_TRAIL_OFFER_UNAVAILABILITY":"Das Elternangebot ist für dieses Testangebot nicht verfügbar",
    "ERROR_DESC_PAYMENT_PROFILE_MENDATED":"Zahlungsprofil fehlt. Bitte geben Sie Ihr Zahlungsprofil an und versuchen Sie es erneut.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
    "ERROR_DESC_PAYMENT_PROVIDER_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Die Verwaltung des Zahlungsprofils ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
    "ERROR_DESC_PAYMENT_PROVIDER_DELETE_USER":"Das Löschen des Benutzers ist aufgrund eines Fehlers im Zahlungsanbieterportal fehlgeschlagen.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_CUSTOMER":"Kunde mit ID konnte nicht gefunden werden",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
    "ERROR_DESC_PAYMENT_PROVIDER_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer Anbieterausnahme fehlgeschlagen.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_CUSTOMER_EXCEPTION":"Beim Verwalten des Rechnungskunden ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_PAYMENT_PROFILE_EXCEPTION":"Beim Verwalten des Zahlungsprofils ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_PAYMENT_PROVIDER_MANAGE_TRANSACTION_EXCEPTION":"Beim Erstellen der Transaktion ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_PAYMENT_STATUS_UPDATE_EXCEPTION":"Beim Aktualisieren des Zahlungsstatus ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_PRODUCT_ID_WRONG_CUSTOMER_MAPPING_IN_IMPORT_PARTNER_PRODUCT_USAGE_DATA":"Produktzuordnung mit falschem Kunden",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_AND_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Mandanten- und Kategorieauswahl unterstützt keine bereits zugeordneten Produkte",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_CATEGORY_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Kategorieauswahl unterstützt keine bereits zugeordneten Produkte",
    "ERROR_DESC_PRODUCT_MAPPING_SELECTED_TENANT_ID_DOES_NOT_MATCH_MAPPED_PRODUCTS":"Die Mandantenauswahl unterstützt keine bereits zugeordneten Produkte",
    "ERROR_DESC_PRODUCT_WAS_SUSPENDED_DURING_USAGE_DATE_PROVIDED_PARTNER_PRODUCT_USAGE_DATA":"Das Nutzungsdatum fällt in den Zeitraum der Produktunterbrechung",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_ALREADY_EXISTS_IN_SYSTEM":"Anbieter BusinessId ist bereits im System vorhanden",
    "ERROR_DESC_PROVIDER_BUSINESS_ID_CANNOT_BE_NULL":"Die BusinessId des Anbieters darf nicht null sein",
    "ERROR_DESC_PROVIDER_RESELLER_BILLING_EMAIL_IS_INVALID":"Rechnungs-E-Mail ist ungültig",
    "ERROR_DESC_PROVIDER_RESELLER_COUNTRY_CANNOT_BE_NULL":"Provider Reseller Country darf nicht null sein",
    "ERROR_DESC_PROVIDER_RESELLER_ID_ALREADY_EXISTS_IN_SYSTEM":"Die Reseller-ID des Anbieters ist bereits im System vorhanden",
    "ERROR_DESC_PROVIDER_RESELLER_ID_CANNOT_BE_NULL":"Die Anbieter-Reseller-ID darf nicht null sein",
    "ERROR_DESC_PROVIDER_RESELLER_NAME_CANNOT_BE_NULL":"Der Reseller-Name des Anbieters darf nicht null sein",
    "ERROR_DESC_PROVIDER_RESELLER_NOT_LINKED_TO_C3":"Provider-Reseller-Details sind nicht mit c3 verknüpft, bitte wenden Sie sich an Ihren Partner oder Ihr Support-Team",
    "ERROR_DESC_RECORD_MULTIPLE_PAYMENTS_PARSE_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "ERROR_DESC_REMARKS_CHARACTER_LENGTH_IN_IMPORT_PAYMENT_RECORD":"Bemerkungen: Die Zeichenlänge sollte weniger als 200 betragen",
    "ERROR_DESC_REPORT_USAGE_FILE_MISSING_IN_VALIDATE_REQUEST":"Bitte überprüfen Sie noch einmal. Datei fehlt in der Anforderung zur Datenvalidierung",
    "ERROR_DESC_RESELLER_ALREADY_EXISTS_IN_THE_SYSTEM":"Reseller ist bereits im System vorhanden",
    "ERROR_DESC_SELECT_ATLEAST_ONE_PRODUCT_NOTIFIER":"Wählen Sie mindestens ein Produkt aus",
    "ERROR_DESC_SQL_BILLING_MANAGER_CHARGE_USER":"Zahlung aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_BILLING_MANAGER_PENDING_PAYMENTS":"Das Abrufen der ausstehenden Zahlung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_BILLING_PROVIDER_CONFIGURATION":"Die Konfiguration des Abrechnungsanbieters ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPON_ASSIGNMENT":"Die Couponzuweisung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_COUPONS":"Die Couponverwaltung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_CUSTOMER":"Die Kundenverwaltung ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_CREATE_OR_UPDATE_PAYMENT_PROFILE":"Die Verwaltung des Zahlungsprofils ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_DELETE_CUSTOMER":"Das Löschen des Kunden ist aufgrund eines Datenbankfehlers fehlgeschlagen.",
    "ERROR_DESC_SQL_ERROR_WHILE_ADDING_ALL_OFFERS_TO_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Hinzufügen von Angeboten zum Plan eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
    "ERROR_DESC_SQL_ERROR_WHILE_CLONING_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Klonen des Plans eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CLONE_PLAN":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Versuch, eine Anfrage für den Klonplan zu stellen, eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
    "ERROR_DESC_SQL_ERROR_WHILE_PLACING_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Es tut uns leid, Ihnen mitteilen zu müssen, dass beim Versuch, eine Anfrage zum Erstellen eines neuen Plans mit allen Angeboten anzufordern, eine Datenbankausnahme aufgetreten ist. Bitte wenden Sie sich an den Support",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
    "ERROR_DESC_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Fehler aus der Datenbank beim Speichern der MPN-ID",
    "ERROR_DESC_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
    "ERROR_DESC_SQL_GET_CUSTOMER":"Der Kundenabruf ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_GET_PAYMENT_PROFILES":"Das Abrufen von Zahlungsprofilen ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_GET_PAYMENT_STATUS":"Das Abrufen des Zahlungsstatus ist aufgrund einer Datenbankausnahme fehlgeschlagen.",
    "ERROR_DESC_SQL_GET_SERVICE_PROVIDER_CUSTOMER_USERS":"Fehler aus der Datenbank beim Abrufen von Kunden.",
    "ERROR_DESC_SUBSCRIPTION_COUNT_EXCEEDED":"Die gewünschte Anzahl von Abonnements 6 hat die maximal zulässige Anzahl von Abonnements pro Kunde 5 überschritten",
    "ERROR_DESC_SUSPENSION_REACTIVATION_BLOCKED_AS_PRODUCT_ASSIGNED_TO_OPERATIONAL_ENTITY":"Sie haben keine Berechtigung zum Ändern des Produktstatus, da das Eigentum mit den operativen Einheiten geteilt wird",
    "ERROR_DESC_SUSPENSION_REACTIVATION_IS_NOT_ENTIRELY_SUCCESSFUL":"Die Aktualisierung des Produktstatus war nicht vollständig erfolgreich. Bitte wenden Sie sich an unser Support-Team, um Unterstützung zu erhalten",
    "ERROR_DESC_TAX_CODE_ALREADY_EXISTS":"Steuerkennzeichen existiert bereits. Bitte wählen Sie ein eindeutiges Steuerkennzeichen aus.",
    "ERROR_DESC_THE_PROVIDED_SMTP_SETTINGS_ARE_WRONG":"Die bereitgestellten SMTP-Einstellungen sind falsch",
    "ERROR_DESC_TRANSACTIONS_ARE_DISABLED":"Wir können diese Anfrage nicht bearbeiten, da Transaktionen deaktiviert sind.",
    "ERROR_DESC_TRIAL_OFFER_CANNOT_BE_PURCHASED_WITH_OTHER_CATEGORY":"Das Testangebot kann nicht zusammen mit anderen Kategorien erworben werden",
    "ERROR_DESC_UNABLE_TO_PROCESS_REQUEST":"Bitte wenden Sie sich an den Support, da Ihre Anfrage nicht bearbeitet werden kann",
    "ERROR_DESC_UNABLE_TO_PROCESS_THIS_ORDER":"Diese Bestellung kann nicht bearbeitet werden",
    "ERROR_DESC_UNAUTHORIZED_FOR_ACCESS":"Sie sind nicht berechtigt, auf diese Funktion zuzugreifen",
    "ERROR_DESC_USAGE_LOCATION_IS_INVALID":"Verwendungsort ist ungültig",
    "ERROR_DESC_VALIDATE_CUSTOMER_PLAN":"Beim Validieren des Kundenplans ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_DESC_WHILE_ACCESSING_DATABASE":"Fehler beim Zugriff auf die Datenbank",
    "ERROR_DESC_WHILE_BULK_ADD_ENTITLEMENT":"Beim Hinzufügen der Berechtigung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT":"Beim Hinzufügen des Mieters ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CONSENT":"Beim Erteilen der Zustimmung für den Mieter ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_CREATE_AZURE_PLAN":"Mandant wurde erstellt, aber beim Hinzufügen des Abonnements ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_BULK_ADD_TENANT_ROLE_ASSIGN":"Beim Zuweisen der Rolle ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_CANCELLING_SCHEDULED_RENEWAL":"Beim Abbrechen der geplanten Verlängerung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_CATALOGITEMID":"Die angegebene Katalog-ID ist ungültig",
    "ERROR_DESC_WHILE_CHECKING_MICROSOFT_NCE_PROMOTION_ELIGIBILITY":"Beim Prüfen der Berechtigung für die Microsoft NCE-Promotion ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_CREATING_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC":"Beim Erstellen eines Abonnements für reservierte Instanzen durch Synchronisierung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_CREATING_SCHEDULED_RENEWAL":"Beim Erstellen der geplanten Verlängerung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_ENQUEUING_MESSAGE_FOR_UPLOAD_SUBSCRIPTIONS_HISTORY_INTO_PSA":"Fehler beim Einreihen der Nachricht zum Hochladen des Abonnementverlaufs in den PSA-Dienst",
    "ERROR_DESC_WHILE_GENERATING_BULK_INVOICE":"Beim Generieren einer Sammelrechnung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_SUMMARY_REPORT":"Beim Generieren einer CSV-Datei für den Zusammenfassungsbericht der Rechnungsposten ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_INVOICE_LINEITEMS_WITH_COMMISSION_DETAILS_REPORT":"Beim Generieren einer CSV-Datei für Rechnungsposten mit Provisionsdetailbericht ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_PROFITABILITY_REPORT":"Beim Generieren der CSV für den Rentabilitätsbericht ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GENERATING_CSV_FOR_TAX_AMOUNTS_SUMMARY_REPORT":"Beim Generieren einer CSV-Datei für den Zusammenfassungsbericht zu Steuerbeträgen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_ACTIVE_PSA_SERVICES_DETAIL":"Fehler beim Abrufen der Details des aktiven PSA-Dienstes",
    "ERROR_DESC_WHILE_GETTING_CATEGORIES_FOR_REVENUE_VERSUS_COST_COMPARISION":"Beim Versuch, Kategorien für den Umsatz-Kosten-Vergleich abzurufen, ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_CUSTOMER_RENEW_PRODUCT":"Beim Abrufen der Abonnementdetails ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_CUSTOMER_SUBSCRIPTIONS_MAPPING_TO_PSA":"Fehler beim Abrufen der Zuordnung der Kundenabonnements zu PSA",
    "ERROR_DESC_WHILE_GETTING_CUSTOMERS_COUNT":"Beim Abrufen der Kundenanzahl ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_DETAILS_OF_REVENUE_VERSUS_COST_BY_CATEGORY":"Beim Versuch, Details zum Umsatz im Vergleich zu den Kosten nach Kategorie abzurufen, ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_EXTERNAL_SERVICE_SUBSCRIPTION_DETAIL_BY_SUBSCRIPTION_ID_AND_CONTRACT_SERVICE_ID_AND_CONTRACT_ID":"Beim Abrufen der Details des externen Dienstabonnements nach c3-Abonnement-ID, Vertragsdienst-ID und Vertrags-ID ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_EXTERNAL_SEVICE_MAPPED_ENTITIES_AND_CONTRACT_SERVICES_BY_SEVICESNAME":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Abrufen der Liste der vom Kunden verwalteten Konfigurationen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_LIST_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Abrufen der Liste der vom Reseller verwalteten Konfigurationen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_LIST_SUBSCRIPTION_END_DATES":"Beim Abrufen der Liste der Abonnement-Enddaten ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Fehler beim Abrufen der zugeordneten Entitäten und Vertragsdienste für Autotask",
    "ERROR_DESC_WHILE_GETTING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Fehler beim Abrufen der zugeordneten Entitäten und Produkte für ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_GETTING_NON_ONBOARDED_PROVIDER_RESELLERS":"Beim Abrufen von Resellern von nicht eingebundenen Anbietern ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PORTLET_PARAMETERS":"Beim Abrufen der Portletparameter ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNT":"Beim Abrufen der Produktanzahl ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PRODUCT_COUNTS_AS_ON_DATE":"Beim Abrufen der Produktzähler ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PROMOTION_DETAIL_BY_ID":"Beim Abrufen der Aktionsdetails ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PSA_POST_LOG_SUMMARY":"Fehler beim Abrufen der Zusammenfassung des PSA-Post-Protokolls",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_BY_SKU":"Beim Abrufen der gekauften Plätze nach SKU-ID ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_PURCHASED_SEATS_IN_GIVEN_PERIOD":"Beim Abrufen der gekauften Plätze im angegebenen Zeitraum ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_REVENUE_VERSUS_COST":"Beim Abrufen des Umsatzes im Vergleich zu den Kosten ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_SEATS_COUNT":"Beim Abrufen der Anzahl der Plätze ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_SEATS_COUNT_AS_ON_DATE":"Beim Abrufen der Anzahl der Sitzplätze ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_THE_AUTO_TASK_SETTINGS":"Fehler beim Abrufen der Autotask-Einstellungen",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_LOG_STATUS":"Fehler beim Abrufen des Status des PSA-Dienstprotokolls",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_SERVICE_POST_LOGS":"Fehler beim Abrufen der Post-Protokolle des PSA-Dienstes",
    "ERROR_DESC_WHILE_GETTING_THE_PSA_STAGGED_RECORDS":"Fehler beim Abrufen der gestaffelten PSA-Datensätze",
    "ERROR_DESC_WHILE_GETTING_VALUED_CUSTOMERS":"Beim Abrufen der geschätzten Kunden ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_GETTING_VALUED_PURCHASED_PRODUCTS_BY_SEATS":"Beim Abrufen der geschätzten gekauften Produkte nach Umsatz ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_CONTRCAT_SERVICES_FOR_AUTO_TASK":"Fehler beim Verwalten der zugeordneten Entitäten und Vertragsdienste für Autotask",
    "ERROR_DESC_WHILE_MANAGING_MAPPED_ENTITIES_AND_PRODUCTS_FOR_CONNECT_WISE_MANAGE":"Fehler beim Verwalten der zugeordneten Entitäten und Produkte für ConnectWise Manage Manage",
    "ERROR_DESC_WHILE_ONBOARDING_A_RESELLER_IN_BULK_ONBOARD":"Beim Onboarding des Reseller-Anbieters ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_PROCESSING_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Beim Verarbeiten der Anfrage für Reseller von Bulk-Onboard-Anbietern ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_QUEUING_OF_PROVIDER_RESELLERS_FOR_BULK_ONBOARD":"Beim Einreihen in die Warteschlange für Wiederverkäufer von Onboard-Anbietern ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Zurücksetzen der vom Kunden verwalteten Konfigurationen auf die Standardeinstellungen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_REVERTING_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Zurücksetzen der vom Reseller verwalteten Konfigurationen auf die Standardeinstellungen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_SAVE_OR_UPDATE_CART_LINE_ITEM_CUSTOM_END_DATE":"Beim Speichern oder Aktualisieren des benutzerdefinierten Enddatums der Warenkorbposition ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_SAVING_THE_SELECTED_CUSTOMERS_INTO_STAGING_FOR_PSA":"Fehler beim Speichern der Abonnementverlaufsdaten der ausgewählten Kunden in Staging",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_CUSTOMER":"Beim Aktualisieren der vom Kunden verwalteten Konfigurationen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_UPDATING_CONFIGURATION_MANAGED_BY_RESELLER":"Beim Aktualisieren der vom Reseller verwalteten Konfigurationen ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_UPDATING_SCHEDULED_RENEWAL":"Beim Aktualisieren der geplanten Verlängerung ist ein Fehler aufgetreten",
    "ERROR_DESC_WHILE_UPDATING_STATUS_OF_STAGGED_RECORDS":"Fehler beim Aktualisieren des Status von gestaffelten Datensätzen",
    "ERROR_DESC_WHILE_UPGRADING_THE_SUBSCRIPTIONS":"Beim Upgrade des Abonnements ist ein Fehler aufgetreten",
    "ERROR_DETAILS":"Fehlerdetails",
    "ERROR_DUPLICATE_DEPARTMENT_NMAE":"Dieser Abteilungsname existiert bereits unter diesem Kunden und Standort.",
    "ERROR_DUPLICATE_SITE_NMAE":"Dieser Site-Name existiert bereits unter diesem Kunden.",
    "ERROR_EMPTY_COMMENTS_SUBMITTED":"Leere Kommentare können nicht eingereicht werden",
    "ERROR_FAILED_TO_IMPORT_MULTIPLE_PAYMENTS_DETAILS":"Zahlungsdetails konnten nicht importiert werden",
    "ERROR_FAILED_TO_IMPORT_PRODUCT_COMMISSION_DETAILS":"Details zur Produktprovision konnten nicht importiert werden",
    "ERROR_FAILED_TO_STAGE_ADD_BULK_TENANT_INPUT":"Fehler beim Hinzufügen von Massenmandanteneingaben",
    "ERROR_FAILED_TO_STAGE_BULK_INVOICE_ENTITY_DETAILS":"Fehler beim Generieren von Details in Entitätsdetails für Massenrechnungen",
    "ERROR_FIELD_REQUIRED":"Dieses Feld wird benötigt",
    "ERROR_FORM_INVALID":"Das Formular ist ungültig.",
    "ERROR_GETTING_HOSTED_TOKEN":"Fehler beim Abrufen des gehosteten Tokens",
    "ERROR_GETTING_STRIPE_DETAILS":"Beim Abrufen der Streifendetails ist ein Fehler aufgetreten",
    "ERROR_INSUFFICIENT_PRIVILEGES_BODY":"Sie scheinen nicht über ausreichende Berechtigungen zu verfügen, um diese Aktion auszuführen",
    "ERROR_INSUFFICIENT_PRIVILEGES_TITLE":"Verboten!",
    "ERROR_LICENSE_ASSIGNMENT_INPROGRESS":"Der Status des Produkts kann nicht aktualisiert werden, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
    "ERROR_MESSAGE":"Etwas ist schief gelaufen!",
    "ERROR_MESSAGE_APPROVAL_IS_NOT_SUCCESSFULL_PLEASE_CONTACT_SUPPORT":"Die Genehmigung war nicht erfolgreich. Bitte wenden Sie sich an den Support",
    "ERROR_MESSAGE_ATLEAST_ONE_CUSTOMER_AT_CREATE_ENGAGE":"Mindestens ein Kunde sollte ausgewählt werden",
    "ERROR_MESSAGE_DONOT_HAVE_PERMISSION_TO_REDUCE_SEATS":"Sie haben keine Erlaubnis, Sitzplätze zu reduzieren",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_PLAN":"Beim Erstellen des Plans ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Support-Team.",
    "ERROR_MESSAGE_FAILED_TO_CREATE_NEW_RESELLER_PLAN":"Beim Erstellen des Reseller-Plans ist ein Fehler aufgetreten. Bitte wenden Sie sich an unser Support-Team.",
    "ERROR_MESSAGE_FOR_AUTOTASK_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Autotask unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Ausführung aller Autotask-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Einstellungen -> Autotask</b> aufrufen.",
    "ERROR_MESSAGE_FOR_CONNECTWISE_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Connectwise unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Connectwise-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Einstellungen -> Connectwise verwalten</b> aufrufen.",
    "ERROR_MESSAGE_FOR_INVALID_SMTP_SETTINGS":"Die SMTP-Einstellungen sind ungültig",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_CENTER_CONNECTIVITY":"Es tut uns leid, aber die Konnektivität scheint zwischen Ihrem C3-Konto und dem Microsoft Partner Center unterbrochen zu sein. Dies kann zu Fehlern beim Ausführen aller Partner Center-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie zu <b>Einstellungen -> Anbieter</b> gehen",
    "ERROR_MESSAGE_FOR_MICROSOFT_PARTNER_PRICING_API_CONNECTIVITY":"Es tut uns leid, aber die Konnektivität scheint zwischen Ihrem C3-Konto und der Microsoft Partner Pricing API unterbrochen zu sein. Dies kann zu Fehlern beim Abrufen von Details aus der Microsoft-Preisgestaltungs-API über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie zu <b>Einstellungen -> Anbieter</b> gehen",
    "ERROR_MESSAGE_FOR_QUICKBOOKS_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Quickbooks unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Quickbooks-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Analysieren -> Geschäft -> Rechnungen/Zahlungen herunterladen</b> aufrufen.",
    "ERROR_MESSAGE_FOR_XERO_CONNECTIVITY":"Es tut uns leid, dass die Verbindung zwischen Ihrem C3-Konto und Xero unterbrochen zu sein scheint. Dies kann zu Fehlern bei der Durchführung aller Xero-Transaktionen über C3 führen. Bitte stellen Sie die Verbindung her, indem Sie <b>Analysieren -> Geschäft -> Rechnungen/Zahlungen herunterladen</b> aufrufen.",
    "ERROR_MESSAGE_INVAILD_CART_NUMBER":"Ungültige Warenkorbnummer",
    "ERROR_MESSAGE_INVALID_CUSTOMER":"Ungültiger Kunde",
    "ERROR_MESSAGE_INVALID_TENANT_USER":"Der Mandantenbenutzer ist ungültig",
    "ERROR_MESSAGE_INVOICE_STATUS_UPDATE_IS_NOT_VALID":"Statusänderung ist ungültig.",
    "ERROR_MESSAGE_MANAGE_OWNERSHIP":"Das Feld „Menge“ ist erforderlich und darf weder null noch eine Dezimalzahl sein. Bitte geben Sie eine gültige ganze Zahl ein.",
    "ERROR_MESSAGE_MAXIMUM_THREE_MARKETS_CAN_BE_CHOSEN":"Es können bis zu 3 Märkte ausgewählt werden.",
    "ERROR_MESSAGE_NO_MESSAGE_FOUND_FOR_RESELLER_RELATIONSHIP_REQUEST":"Es wurde keine Nachricht gefunden, um eine Reseller-Beziehung anzufordern. Bitte wenden Sie sich an den C3-Support",
    "ERROR_MESSAGE_NON_LICENSE_SUPPORTED_OFFERS":"Der Einkaufswagen enthält andere Angebote als Partner - LicenseSupported-Angebote. Bitte entfernen Sie diese, um fortzufahren.",
    "ERROR_MESSAGE_NOTIFICATION_EMAIL_ALREADY_EXISTS":"Benutzer mit dieser E-Mail existiert bereits oder Sie haben keine Berechtigung, diesen Benutzer hinzuzufügen",
    "ERROR_MESSAGE_PURCHASES_MAY_FAIL":"Käufe können fehlschlagen. Bitte klicken Sie, um mehr zu erfahren.",
    "ERROR_MESSAGE_QUANTITY_DECREAMENT_NOT_ALLOWED":"Mengenreduzierung ist nicht erlaubt",
    "ERROR_MESSAGE_TENANT_CUSTOMER_NOT_LINKED":"Dieser Kunde ist nicht mit Microsoft csp verbunden",
    "ERROR_MESSAGE_UNABLE_PROCCESS_YOUR_REQUEST":"Ihre Anfrage kann nicht bearbeitet werden.",
    "ERROR_MESSAGE_VOUCHER_COUNT_GREATER_THAN_PRODUCT_QUANTITY":"Sie haben mehr Gutscheine als nötig eingegeben",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_LINE_ITEMS_REPORT":"Beim Exportieren des Berichts über Rechnungsposten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Administrator",
    "ERROR_MESSAGE_WHILE_EXPORTING_INVOICE_SUMMARY_REPORT":"Beim Exportieren des Rechnungszusammenfassungsberichts ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Administrator",
    "ERROR_MESSAGE_WHILE_UPLOADING_FILE":"Beim Hochladen der Datei ist ein Fehler aufgetreten",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE":"Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut mit einem anderen Bild.",
    "ERROR_MESSAGE_WHILE_UPLOADING_IMAGE_EXTENSION":"Bitte wählen Sie die Bilderweiterung aus, z. B .jpg, .png usw.",
    "ERROR_MS_ERROR_WHILE_VERIFYING_RESELLER_MPN_ID":"Fehler vom Partnercenter beim Überprüfen der MPNID. Bitte überprüfen Sie Ihre Eingabe erneut.",
    "ERROR_MS_FAILED_TO_CREATE_USER":"Fehler vom Partnercenter beim Erstellen des Benutzers",
    "ERROR_MS_FAILED_TO_DELETE_USER":"Fehler vom Partnercenter beim Löschen des Benutzers",
    "ERROR_MS_FAILED_TO_GET_TENANT_USER":"Fehler vom Partnercenter beim Abrufen des Mandantenbenutzers",
    "ERROR_MS_FAILED_TO_GET_USER_DETAILS":"Fehler vom Partnercenter beim Abrufen der Benutzerdetails",
    "ERROR_MS_FAILED_TO_GET_USER_ROLES":"Fehler vom Partnercenter beim Abrufen von Benutzerrollen",
    "ERROR_MS_FAILED_TO_GET_USERS":"Fehler vom Partnercenter beim Abrufen von Benutzern",
    "ERROR_MS_FAILED_TO_UPDATE_USER":"Fehler vom Partnercenter beim Aktualisieren des Benutzers",
    "ERROR_MS_FAILED_TO_UPDATE_USER_LICENSE_ASSIGNMENTS":"Fehler vom Partnercenter beim Aktualisieren der Benutzerlizenzzuweisungen",
    "ERROR_MS_FAILED_TO_UPDATE_USER_PASSWORD":"Fehler vom Partnercenter beim Aktualisieren des Benutzerkennworts",
    "ERROR_OCCURRED_LOADING_MISSING_OFFERS":"Beim Versuch, eine Anfrage zur Aktualisierung der neuesten Angebote für den Plan in die Warteschlange zu stellen, ist ein Fehler aufgetreten",
    "ERROR_OCCURRED_WHILE_CHECKING_THE_PROMOTION_ELIGIBILITY":"Beim Prüfen der Berechtigung für Werbeaktionen ist ein Fehler aufgetreten.",
    "ERROR_OCCURRED_WHILE_COUNTING_PROMOTIONS_IN_CURRENT_MONTH":"Beim Zählen der Werbeaktionen im aktuellen Monat ist ein Fehler aufgetreten.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS":"Beim Abrufen der Aktionsdetails ist ein Fehler aufgetreten.",
    "ERROR_OCCURRED_WHILE_GETTING_PROMOTIONS_DETAILS_FROM_PROVIDER":"Beim Abrufen der Aktionsdetails vom Anbieter ist ein Fehler aufgetreten.",
    "ERROR_OCCURRED_WHILE_LOADING_PROMOTIONS":"Beim Laden der Angebote ist ein Fehler aufgetreten.",
    "ERROR_OCCURRED_WHILE_PROCESSING_YOUR_REQUEST":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "ERROR_OCCURRED_WHILE_SAVIGING_SUBSCRIPTION_UPGRADE_DATA_INTO_STAGING_TABLE":"Der Fehler ist beim Speichern der Abonnement-Upgrade-Details in der Staging-Tabelle aufgetreten",
    "ERROR_OCCURRED_WHILE_SCHEDULING_THE_ORDER":"Beim Planen der Bestellung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support",
    "ERROR_OCCURRED_WHILE_TRYING_TO_FETCH_ELIGIBILE_PRODUCTS_FOR_SUBSCRIPTION_UPGRADE":"Der Fehler ist beim Versuch aufgetreten, die berechtigten Produkte für das Abonnement-Upgrade abzurufen",
    "ERROR_OCCURRED_WHILE_UPDATING_PROMOTION_ID_IN_CARTLINEITEM":"Beim Aktualisieren der Angebots-ID in cartlineItem ist ein Fehler aufgetreten.",
    "ERROR_PARTNER_OFFER_DAILY_USAGE_DATA_IMPORT_PRODUCT_MISSING":"Das Produkt fehlt, während die täglichen Nutzungsdaten des Partnerangebots importiert werden",
    "ERROR_PLAN_ID":"Plan-ID",
    "ERROR_PLEASE_CONFIRM_THE_CUSTOMER_ACCEPTANCE_OF_MICROSOFT_CUSTOMER_AGREEMENT":"Bitte bestätigen Sie die Akzeptanz der Microsoft-Kundenvereinbarung durch den Kunden",
    "ERROR_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Fehler beim Importieren der täglichen Nutzungsdaten des Partnerangebots",
    "ERROR_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Beim Versuch, Produktprovisionen zu importieren, ist ein Fehler aufgetreten",
    "ERROR_PRODUCT_SEQUENCE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"Die Produktsequenz hat ungültige oder negative Werte. Bitte geben Sie eine gültige Reihenfolge an.",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_AVAILABLE_C3_MESSAGE":"Der Mieter, den Sie einbinden/verknüpfen möchten, ist bereits im Portal vorhanden. Bitte wenden Sie sich an den Support",
    "ERROR_PROVIDER_CUSTOMER_ALREADY_LINKED_WITH_C3_MESSAGE":"Der Mieter, den Sie an Bord/Link suchen, ist bei einem Ihrer Kunden vorhanden. Bitte versuchen Sie es mit einem anderen Mieter.",
    "ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE":"Die Angebotsposition enthält ungültige oder negative Werte. Bitte geben Sie eine gültige Menge zwischen 1 und 9999 an.",
    "ERROR_RESELLER_PROVIDER_ID":"Reseller Provider Id",
    "ERROR_SAVING_CUSTOMER":"Fehler beim Speichern des Kunden",
    "ERROR_SAVING_PARTNER_OFFER":"Beim Speichern des Partnerangebots ist ein Fehler aufgetreten",
    "ERROR_SAVING_PARTNER_OFFER_CHECK_SAME_CATEGORY_OFFERS":"Das Paket sollte MS-Angebote der gleichen Kategorie enthalten.",
    "ERROR_SAVING_PAYMENT_ACCOUNT":"Fehler beim Speichern des Zahlungskontos",
    "ERROR_SQL_ERROR_WHILE_SAVING_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
    "ERROR_SQL_ERROR_WHILE_SAVING_RESELLER_MPN_ID":"Fehler aus der Datenbank beim Speichern der MPN-ID",
    "ERROR_SQL_FAILED_TO_SAVE_CUSTOMER_USER":"Fehler aus der Datenbank beim Versuch, den Kundenbenutzer zu speichern",
    "ERROR_TRAIL_OFFER_CATEGORY_CUSTOM_ERROR":"Für das Testangebot kommen nur benutzerdefinierte Angebote in Frage.",
    "ERROR_TRAIL_OFFER_CONSUMPTION_TYPE_QUANTITY_ERROR":"Für das Testangebot kommen nur mengenbasierte Angebote in Frage.",
    "ERROR_UNABLE_TO_FIND_A_PROVIDER_TENANT":"Es kann kein Provider-Mieter gefunden werden.",
    "ERROR_UPDATING_AUTO_RENEW_STATUS":"Das automatische Verlängerungsupdate für {{productName}} ist fehlgeschlagen.",
    "ERROR_WHILE_ACCESSING_DATABASE":"Fehler beim Zugriff auf die Datenbank",
    "ERROR_WHILE_CHECKING_HAS_RESELLER_LINKED_WITH_PROVIDER":"Beim Versuch zu überprüfen, ob der Reseller mit dem Anbieter verknüpft ist, ist ein Fehler aufgetreten",
    "ERROR_WHILE_DOWNLOAD_UPLOAD_PROVIDER_RESELLERS_TEMPLATE":"Beim Herunterladen der .csv-Vorlagendatei für den Bulk-Upload ist ein Fehler aufgetreten",
    "ERROR_WHILE_GENERATING_PRODUCT_CATALOGUE_REPORT":"Fehler beim Generieren des Produktkatalogberichts",
    "ERROR_WHILE_GETTING_CUSTOMER_RENEWAL_CONSENT_HISTORY":"Beim Abrufen des Verlängerungszustimmungsverlaufs ist ein Fehler aufgetreten",
    "ERROR_WHILE_GETTING_SUBSCRIPTION_END_DATES_FROM_PC":"Beim Abrufen der Enddaten vom Partnercenter ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Beim Versuch, die Rechnungsbeträge abzurufen, ist ein Fehler aufgetreten",
    "ERROR_WHILE_GETTING_VALUED_PRODUCT_SEATS":"Beim Abrufen der geschätzten Produktplätze ist ein Fehler aufgetreten",
    "ERROR_XERO_POST_LOG_ENQUEUE_MESSAGE":"Bitte wählen Sie mindestens eine Rechnung für die Buchung bei Xero aus.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Fehler: Abbruch des Uploads aufgrund eines Fehlers beim Erstellen von Entitäten",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Fehler: Ergänzung befindet sich im aktiven ConnectWise Manage",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Fehler: Die Vereinbarungs-ID fehlt",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Fehler: Die Rechnungsstellung ist für diese Vereinbarung in ConnectWise Manage nicht aktiviert",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Fehler: Zugeordnete Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht aktiv",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Fehler: Die Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Fehler: Für diesen Kunden sind keine Vereinbarungen zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Fehler: In ConnectWise Manage sind unter diesem Kunden keine Vereinbarungen vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Es liegen mehrdeutige Zusätze vor, eine Zuordnung ist nicht möglich.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Fehler: Vertrags-ID fehlt",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Fehler: Die Rechnungsstellung ist für diesen Vertrag in Autotask nicht aktiviert",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Fehler: Zugeordneter Vertrag ist in Autotask unter diesem Kunden nicht aktiv",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Fehler: Der Vertrag ist unter diesem Kunden nicht in Autotask vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Vertrag ist im externen Service unter diesem Kunden nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Vertragsservice ist diesem Kunden nicht zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Fehler: Zugeordneter Vertragsservice ist in Autotask nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Vertragsleistungen sind diesem Kunden nicht zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Fehler: Für diesen Kunden sind keine Verträge zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Fehler: In Autotask sind unter diesem Kunden keine Verträge vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Es sind keine Verträge im externen Service unter diesem Kunden vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Fehler: Kunde ist im externen Dienst nicht aktiv",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Fehler: Externer Kunde ist nicht mit C3-Kunden verknüpft",
    "ErrorEXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Fehler: Kunde ist im externen Dienst nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Fehler: Entweder fehlt die Entitäts- oder Produktzuordnung.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":" Fehler: Es wurde zuvor ein Exportversuch unternommen. Der Status dieser Operation ist",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Fehler: Vertrag ist im externen Dienst nicht aktiv",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Fehler: Externe Kunden-ID fehlt",
    "ErrorEXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Fehler: Kunden sind im externen Service nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Fehler: Beim Erstellen der Ergänzung in ConnectWise Manage ist ein Fehler aufgetreten",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":" Fehler: Beim Erstellen einer Anpassung ist ein Fehler aufgetreten:",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Fehler: Beim Erstellen der Anpassung für die Preisänderung ist ein Fehler aufgetreten",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Fehler: Beim Erstellen des Dienstes ist ein Fehler aufgetreten",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Fehler: Vertragsgebühr für einmaliges Produkt konnte nicht erstellt werden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":" Fehler: Beim Erstellen des Vertragsdienstes ist ein Fehler aufgetreten:",
    "ErrorEXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Fehler: Beim Aktualisieren des Zusatzes in ConnectWise Manage ist ein Fehler aufgetreten",
    "ErrorEXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Fehler: Eine Zuordnung konnte nicht erstellt werden, da in Autotask mehrere übereinstimmende Dienste gefunden wurden.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Fehler: Es konnten keine passenden Verträge zum automatischen Zuordnen gefunden werden.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Fehler: Es konnte kein passender externer Kunde gefunden werden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Fehler: Es konnte kein passendes externes Produkt gefunden werden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Produkt ist diesem Kunden nicht zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Fehler: Zugeordnetes Produkt ist in ConnectWise Manage nicht vorhanden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Fehler: Produkte sind diesem Kunden nicht zugeordnet",
    "ErrorEXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"Fehler: C3-Produkte haben keinen zugeordneten externen Dienst",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Fehler: Service-ID fehlt",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Kündigungsdatum der Vereinbarung liegen.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Vertragsenddatum liegen.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Kündigungsdatum des Vertrags liegen.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht nach dem Vertragsende liegen.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Abrechnungsstartdatum der Vereinbarung liegen",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Startdatum der Vereinbarung liegen",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Abrechnungsbeginn des Vertrags liegen.",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Fehler: Das Erstellungsdatum des Abonnementverlaufs kann nicht vor dem Vertragsbeginn liegen",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Fehler: Abonnement ist im aktiven Autotask",
    "ErrorEXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Fehler: Das Abonnement ist im externen Dienst inaktiv",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere übereinstimmende Verträge gefunden wurden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere externe Kunden zugeordnet wurden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Fehler: Automatische Zuordnung konnte nicht abgeschlossen werden, da mehrere externe Produkte zugeordnet wurden",
    "ErrorEXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Fehler: Die Validierung war nicht erfolgreich",
    "ERRORMESSAGEPOPUP_BUTTON_OK":"OK",
    "ERRORMESSAGEPOPUP_HEADER":"Bitte geben Sie mindestens eine der Voraussetzungen für die Add-Ons in der folgenden Liste an",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_ADD_ON":"Erweiterung",
    "ERRORMESSAGEPOPUP_TABLE_HEADER_PREREQUISITE_OFFER":"Voraussetzung Angebot",
    "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"Fehler: PSA-Vereinbarung ist nicht mit C3-Kunden verknüpft",
    "ErrorPSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Fehler: Externes Produkt ist nicht mit C3-Produkt verknüpft",
    "ERRORS_FROM_PREVIOUS_ACTION":"Ihre letzte Aktion hat zu Fehlern geführt. Klicken Sie hier, um weitere Informationen zu erhalten.",
    "ERRRO_DESC_OCCURED_WHILE_PROCESSING_YOUR_REQUEST":"Während Ihrer Anfrage ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "EVENT_AZURE_PLAN_SUBSCRIPTION_ID":"Subskriptions-ID des Azure-Plans",
    "EVENT_AZURE_PLAN_SUBSCRIPTION_NAME":"Name des Azure-Plans",
    "EVENT_AZURE_SUBSCRIPTION_UPGRADEID":"Upgrade-ID",
    "EVENT_BATCH_ID":"Batch-ID",
    "EVENT_BATCH_STEP_ID":"Batch-Schritt-ID",
    "EVENT_BULK_ONBOARD_RESELLERS_BATCH_SUMMARY_NOTIFIER":"Zusammenfassung der Bulk-Onboard-Reseller-Charge",
    "EVENT_CAN_PRICE_LAG":"OldCanPriceLag",
    "EVENT_CAN_PRICE_LEAD":"NeuDosePreisBlei",
    "EVENT_CONSTANT_TRUE":"TRUE",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_FAILURE":"Setzen Sie das Testangebot fort, bis das Abonnement fehlschlägt",
    "EVENT_CONTINUE_TRIAL_OFFER_TO_SUSBCRIPTION_SUCCESS":"Setzen Sie das Testangebot fort, bis das Abonnement erfolgreich ist",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_FAILURE":"Testangebot in Abonnementfehler umwandeln",
    "EVENT_CONVERT_TRAIL_OFFER_TO_SUBSCRIPTION_SUCCESS":"Wandeln Sie das Testangebot in einen Abonnementerfolg um",
    "EVENT_CREATED_BY":"Ereignis erstellt von",
    "EVENT_DATA_ACCOUNT_MANAGER_C3ID":"Interne ID",
    "EVENT_DATA_ACCOUNT_MANAGER_EMAIL":"Email",
    "EVENT_DATA_ACCOUNT_MANAGER_FIRST_NAME":"Vorname",
    "EVENT_DATA_ACCOUNT_MANAGER_ID":"Account Manager ID",
    "EVENT_DATA_ACCOUNT_MANAGER_LAST_NAME":"Nachname",
    "EVENT_DATA_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefonnummer",
    "EVENT_DATA_ACCOUNT_NUMBER":"Kontonummer",
    "EVENT_DATA_ADD_MISSING_OFFERS_ACTION":"Aktion",
    "EVENT_DATA_ADD_MISSING_OFFERS_ERROR":"Error",
    "EVENT_DATA_ADD_MISSING_OFFERS_Status":"Status",
    "EVENT_DATA_ADDRESS_CITY":"Stadt",
    "EVENT_DATA_ADDRESS_COUNTRY":"Land",
    "EVENT_DATA_ADDRESS_LINE_1":"Anschrift Zeile 1",
    "EVENT_DATA_ADDRESS_LINE_2":"Adresszeile 2",
    "EVENT_DATA_ADDRESS_STATE":"Zustand",
    "EVENT_DATA_ADDRESS_ZIP_CODE":"Postleitzahl",
    "EVENT_DATA_AGREEMENT":"Zustimmung",
    "EVENT_DATA_AGREEMENT_DATE":"Vereinbarungstermin",
    "EVENT_DATA_AGREEMENT_LINK":"Vereinbarung Link",
    "EVENT_DATA_AGREEMENT_NAME":"Zustimmung",
    "EVENT_DATA_AGREEMENT_TYPE":"Vertragsart",
    "EVENT_DATA_ASSIGN_MS_SECURITY_GROUPS_TO_GDAP_REQUEST_SUCCEEDED":"War die Zuweisung der Microsoft-Sicherheitsgruppen zur GDAP-Anforderung erfolgreich?",
    "EVENT_DATA_ASSIGNED_CUSTOMER_NAMES":"Gutschein zugeordnet",
    "EVENT_DATA_AUTO_RELEASE_NEW_VALUE":"Neuer Wert",
    "EVENT_DATA_AUTO_RELEASE_OLD_VALUE":"Alter Wert",
    "EVENT_DATA_AUTO_SYNC_ACTION":"Aktionen",
    "EVENT_DATA_AUTO_SYNC_ACTION_ERROR":"Error",
    "EVENT_DATA_AUTO_SYNC_ACTION_REASON":"Grund",
    "EVENT_DATA_AUTO_SYNC_ACTION_STATUS":"Status",
    "EVENT_DATA_BCC_RECIPIENTS":"BCC-Empfänger",
    "EVENT_DATA_BILLING_CYCLE_NAME":"Name des Abrechnungszyklus",
    "EVENT_DATA_BILLING_PAYMENT_ALIAS":"Zahlungsalias",
    "EVENT_DATA_BILLING_PERIOD_ID":"Abrechnungszeitraum-ID",
    "EVENT_DATA_BILLING_PROVIDER":"Abrechnungsanbieter",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_ID":"Kunden-ID des Abrechnungsanbieters",
    "EVENT_DATA_BILLING_PROVIDER_CUSTOMER_NAME":"Kundenname des Abrechnungsanbieters",
    "EVENT_DATA_BILLING_PROVIDER_RESELLER_ID":"Reseller-ID des Abrechnungsanbieters",
    "EVENT_DATA_BILLING_PROVIDER_USER":"Benutzeridentifikation",
    "EVENT_DATA_BILLING_PROVIDER_USER_ID":"Benutzeridentifikation",
    "EVENT_DATA_BUNDLE_PRODUCT_NAME":"Bündelname",
    "EVENT_DATA_C3_CUSTOMER_NAME":"C3-Kunde",
    "EVENT_DATA_C3_PRODUCT_NAME":"C3-Produkt",
    "EVENT_DATA_C3_SUBSCRIPTION_NAME":"Name des C3-Abonnements",
    "EVENT_DATA_CARD_TYPE":"Speicherkarten-Typ",
    "EVENT_DATA_CART_ID":"Warenkorb-ID",
    "EVENT_DATA_CART_ITEMS":"Warenkorbartikel",
    "EVENT_DATA_CATEGORY_ID":"Kategorie ID",
    "EVENT_DATA_CATEGORY_NAME":"Kategoriename",
    "EVENT_DATA_CC_RECIPIENTS":"CC-Empfänger",
    "EVENT_DATA_CHANGE_TYPE":"Typ ändern",
    "EVENT_DATA_CLONED_FROM_PLAN":"Geklont von",
    "EVENT_DATA_COMPANY_NAME":"Name der Firma",
    "EVENT_DATA_CONSUMED_SEATS":"Verbrauchte Plätze",
    "EVENT_DATA_CONSUMPTION_TYPE":"Verbrauchsart",
    "EVENT_DATA_CONTRACT":"Vertrag",
    "EVENT_DATA_CONTRACT_NAME":"Vertragsname",
    "EVENT_DATA_CONTRACT_SERVICE_NAME":"Vertragsdienstname",
    "EVENT_DATA_CONVERSION_RATE":"Wechselkurs",
    "EVENT_DATA_CORRELATION_ID":"Korrelations-ID",
    "EVENT_DATA_COUNTRY_CODE":"Landesvorwahl",
    "EVENT_DATA_COUPON_CODE":"Gutscheincode",
    "EVENT_DATA_COUPON_CUSTOMER_COUNT_FOR_WHOM_ASSIGNMENT_CHANGED":"Anzahl der Kunden mit neuen Aufträgen",
    "EVENT_DATA_COUPON_DESCRIPTION":"Coupon Beschreibung",
    "EVENT_DATA_COUPON_DETAILS":"Gutscheindetails",
    "EVENT_DATA_COUPON_DISCOUNT":"Rabatt",
    "EVENT_DATA_COUPON_EXPIRES_ON":"Läuft aus am",
    "EVENT_DATA_COUPON_NOOFRECURRENCES":"Anzahl der Wiederholungen",
    "EVENT_DATA_COUPON_VALID_TILL":"Gültig bis",
    "EVENT_DATA_CREATED_BY_IN_PROVIDER":"Erstellt von in Anbieter",
    "EVENT_DATA_CREATED_DATE_IN_PROVIDER":"Erstellungsdatum im Anbieter",
    "EVENT_DATA_CURRENCY_CODE":"Währungscode",
    "EVENT_DATA_CURRENT_QUANTITY":"Alte Menge",
    "EVENT_DATA_CURRENT_STATUS":"Alter Status",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_NEW_VALUE":"Neuer benutzerdefinierter Abrechnungszeitraum",
    "EVENT_DATA_CUSTOM_BILLING_CYCLE_OLD_VALUE":"Alter benutzerdefinierter Abrechnungszeitraum",
    "EVENT_DATA_CUSTOMER_C3_ID":"Kunde C3 Id",
    "EVENT_DATA_CUSTOMER_CONSENT_ON_RENEWAL":"Zustimmung",
    "EVENT_DATA_CUSTOMER_NAME":"Kundenname",
    "EVENT_DATA_CUSTOMER_NEW_NAME":"Neuer Name des Kunden",
    "EVENT_DATA_CUSTOMER_OLD_NAME":"Alter Name des Kunden",
    "EVENT_DATA_CUSTOMER_REFERENCE_ID":"Kundenreferenz-ID",
    "EVENT_DATA_CUSTOMER_SIGNUP_BATCH_ID":"Kundenregistrierung batchid",
    "EVENT_DATA_CUSTOMER_SIGNUP_INTERNAL_PLAN_ID":"Interner Planid",
    "EVENT_DATA_DEACTIVATE_ALL_PSA_RECORDS":"Werden alle PSA-Protokolle deaktiviert?",
    "EVENT_DATA_DEPARTMENT_NAME":"Abteilungsname",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_BILLING_CYCLE":"Rechnungskreislauf",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_NAME":"Name",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Selbstkostenpreis",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Verkaufspreis",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY":"Gültigkeit",
    "EVENT_DATA_DISTRIBUTOR_PRODUCT_VALIDITY_TYPE":"Gültigkeitsart",
    "EVENT_DATA_DOMAIN":"Domain",
    "EVENT_DATA_DOMAIN_NAME":"Domain Name",
    "EVENT_DATA_EFFECTIVE_FROM":"Gültig ab",
    "EVENT_DATA_EMAIL":"Email",
    "EVENT_DATA_EMAIL_ADDRESS":"Email",
    "EVENT_DATA_END_BILLING_PERIOD_ID":"ID des Abrechnungszeitraums beenden",
    "EVENT_DATA_END_DATE":"Endtermin",
    "EVENT_DATA_ENTITY_ID":"Entitäts-ID",
    "EVENT_DATA_ENTITY_NAME":"Entitätsname",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_ASSIGNED":"Entität des Benutzers",
    "EVENT_DATA_ENTITY_NAME_OF_USER_TO_BE_UNASSIGNED":"Entität des Benutzers",
    "EVENT_DATA_ERROR":"Error",
    "EVENT_DATA_ERROR_PRODUCT_MAPPING":"Produktzuordnung mit Fehler",
    "EVENT_DATA_EVENT":"Veranstaltung",
    "EVENT_DATA_EVENT_ID":"Ereignis-ID",
    "EVENT_DATA_EVENT_NAME":"Veranstaltungsname",
    "EVENT_DATA_EXCEPTION_MESSAGE":"Ausnahmemeldung",
    "EVENT_DATA_EXPIRES_ON":"Läuft aus am",
    "EVENT_DATA_EXPORT_ERROR":"Exportfehler",
    "EVENT_DATA_EXPORT_STATUS":"Exportstatus",
    "EVENT_DATA_EXTERNAL_AGREEMENTS":"Externe Vereinbarungen",
    "EVENT_DATA_EXTERNAL_CONTRACT_SERVICES":"Externe Vertragsdienstleistungen",
    "EVENT_DATA_EXTERNAL_CONTRACTS":"Externe Verträge",
    "EVENT_DATA_EXTERNAL_CUSTOMERS":"PSA-Kunde",
    "EVENT_DATA_EXTERNAL_PRODUCTS":"PSA-Produkt",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_CustomerId":"Kundennummer",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONHISTORYID":"Abonnementverlaufs-ID",
    "EVENT_DATA_EXTERNAL_SERVICE_C3_SUBSCRIPTIONID":"Abonnement-ID",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTID":"Vertrags-ID",
    "EVENT_DATA_EXTERNAL_SERVICE_CONTRACTSERVICEID":"Vertragsdienst-ID",
    "EVENT_DATA_EXTERNAL_SERVICE_CUSTOMERID":"Externe Kunden-ID",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_ERROR":"Exportfehler",
    "EVENT_DATA_EXTERNAL_SERVICE_EXPORT_STATUS":"Exportstatus",
    "EVENT_DATA_EXTERNAL_SERVICE_PRODUCT_MAPPING_TYPE":"Produktzuordnungstyp",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATION_ERROR":"Validierungsfehler",
    "EVENT_DATA_EXTERNAL_SERVICE_VALIDATIONSTATUS":"Validierungsstatus",
    "EVENT_DATA_FAILED_TENANT_ID":"Mandanten-ID fehlgeschlagen",
    "EVENT_DATA_GROUP_DESCRIPTION":"Gruppenbeschreibung",
    "EVENT_DATA_GROUP_NAME":"Gruppenname",
    "EVENT_DATA_HAS_ADDITIONAL_DETAIL":"Hat zusätzliche Details",
    "EVENT_DATA_IMPERSONATOR":"Imitator",
    "EVENT_DATA_INPUT_DATA":"Detail anfordern",
    "EVENT_DATA_INTERNAL_CUSTOMER_ID":"Interne Kunden-ID",
    "EVENT_DATA_INTERNAL_CUSTOMER_PRODUCT_ID":"Interne Kundenprodukt-ID",
    "EVENT_DATA_INTERNAL_ID_FOR_NEW_SCHEDULED_RENEWAL":"Interne ID für neue geplante Verlängerung",
    "EVENT_DATA_INTERNAL_ID_FOR_OLD_SCHEDULED_RENEWAL":"Interne ID für die alte geplante Verlängerung",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_SOURCE_PLAN":"InternalPlanId des Quellplans",
    "EVENT_DATA_INTERNAL_PLAN_ID_OF_TARGET_PLAN":"InternalPlanId des Zielplans",
    "EVENT_DATA_INTERNAL_PRODUCT_ID":"C3 Produkt-ID",
    "EVENT_DATA_INTERNAL_SUBSCRIPTION_ID":"Interne Abonnement-ID",
    "EVENT_DATA_INVOICE_LINEITEM":"Name des Rechnungspostens",
    "EVENT_DATA_INVOICE_NUMBER":"Rechnungsnummer",
    "EVENT_DATA_INVOICE_PROPERTY":"Name des Anwesens",
    "EVENT_DATA_INVOICE_PROPERTY_OLD_VALUE":"Alter Wert",
    "EVENT_DATA_INVOICE_PROPERTY_VALUE":"Neuer Wert",
    "EVENT_DATA_IS_ACCEPTED":"Ist akzeptiert",
    "EVENT_DATA_IS_ENABLED":"Ist aktiviert",
    "EVENT_DATA_IS_IMMEDIATE_PROVISIONING":"Ist sofortige Bereitstellung",
    "EVENT_DATA_IS_SHOW_ON_UI":"Wird auf dem Bildschirm angezeigt",
    "EVENT_DATA_JOB_LOG_C3_ID":"JobLogC3Id",
    "EVENT_DATA_LINE_ITEM":"Werbebuchung",
    "EVENT_DATA_LINKED_PRODUCT_NAME":"Verknüpfter Produktname",
    "EVENT_DATA_LINKED_SUBSCRIPTION_NAME":"Verknüpfter Produktname",
    "EVENT_DATA_LOGGED_IN_USER":"Angemeldeter Benutzer",
    "EVENT_DATA_MANAGE_USER_LICENSE_DETAILS":"Benutzerlizenzdetails verwalten",
    "EVENT_DATA_MESSAGE":"Botschaft",
    "EVENT_DATA_Name":"Name",
    "EVENT_DATA_NAME":"Name",
    "EVENT_DATA_NAME_OF_ENTITY":"Name des Unternehmens",
    "EVENT_DATA_NEW_CONFIGURATION_VALUE":"Neuer Wert",
    "EVENT_DATA_NEW_COUPON_CODE":"Neuer Gutscheincode",
    "EVENT_DATA_NEW_COUPON_DESCRIPTION":"Neue Gutscheinbeschreibung",
    "EVENT_DATA_NEW_COUPON_DISCOUNT":"Neuer Coupon-Rabatt",
    "EVENT_DATA_NEW_COUPON_EXPIRESON":"Neuer Gutschein läuft ab am",
    "EVENT_DATA_NEW_COUPON_NAME":"Neuer Gutscheinname",
    "EVENT_DATA_NEW_COUPON_NOOFRECURRENCES":"Neue Coupon-Anzahl von Wiederholungen",
    "EVENT_DATA_NEW_COUPON_VALIDTILL":"Neuer Gutschein gültig bis",
    "EVENT_DATA_NEW_DESCRIPTION":"Neue Planbeschreibung",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung des neuen Vertriebsangebots",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Neues Vertriebsangebot",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Neues Vertriebsangebot",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_NAME":"Neuer Name des Vertriebspartnerangebots",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Neues Vertriebsangebot",
    "EVENT_DATA_NEW_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Neues Vertriebsangebot",
    "EVENT_DATA_NEW_END_DATE_TYPE":"Neuer Enddatumstyp",
    "EVENT_DATA_NEW_ENTITY_NAME":"Neue Entität",
    "EVENT_DATA_NEW_ENTITY_RECORD_NAME":"Übertragen auf",
    "EVENT_DATA_NEW_ISPUBLIC":"Neuer Plan ist öffentlich",
    "EVENT_DATA_NEW_MARKUP":"Neues Markup",
    "EVENT_DATA_NEW_NAME":"Neuer Planname",
    "EVENT_DATA_NEW_OFFER":"Neues Angebot",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_DESCRIPTION":"Beschreibung des neuen Partnerangebots",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Neues Partnerangebot",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Neues Partnerangebot",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_NAME":"Name des neuen Partnerangebots",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_PRICEFORPARTNER":"Neues Partnerangebot",
    "EVENT_DATA_NEW_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Neues Partnerangebot",
    "EVENT_DATA_NEW_PLAN":"Neuer Plan",
    "EVENT_DATA_NEW_PRICE":"Neuer Preis",
    "EVENT_DATA_NEW_PRODUCT_MAPPING":"Neue Produktzuordnung",
    "EVENT_DATA_NEW_PROMOTION_ID":"Neue Promotion-ID",
    "EVENT_DATA_NEW_PROVIDER_EFFECTIVE_END_DATE":"Neues effektives Enddatum",
    "EVENT_DATA_NEW_QUANTITY":"Neue Menge",
    "EVENT_DATA_NEW_SEATS":"Neue Sitze",
    "EVENT_DATA_NEW_STATUS":"Neuer Status",
    "EVENT_DATA_NEW_VALIDITY":"Neue Gültigkeit",
    "EVENT_DATA_NEW_VALIDITY_TERM":"Neue Gültigkeit",
    "EVENT_DATA_NEW_VALIDITYTYPE":"Neuer Gültigkeitstyp",
    "EVENT_DATA_NOTIFICATION_RECIPIENTS":"Benachrichtigungsempfänger",
    "EVENT_DATA_OFFER_NAME":"Angebotsname",
    "EVENT_DATA_OFFER_PRICE_AFTER_DISCOUNT":"Angebotspreis nach Rabatt",
    "EVENT_DATA_OLD_CONFIGURATION_VALUE":"Alter Wert",
    "EVENT_DATA_OLD_COUPON_CODE":"Alter Gutscheincode",
    "EVENT_DATA_OLD_COUPON_DESCRIPTION":"Alte Gutscheinbeschreibung",
    "EVENT_DATA_OLD_COUPON_DISCOUNT":"Alter Coupon-Rabatt",
    "EVENT_DATA_OLD_COUPON_EXPIRESON":"Alter Coupon verfällt am",
    "EVENT_DATA_OLD_COUPON_NAME":"Alter Gutscheinname",
    "EVENT_DATA_OLD_COUPON_NOOFRECURRENCES":"Alter Coupon Nr. der Wiederholungen",
    "EVENT_DATA_OLD_COUPON_VALIDTILL":"Alter Gutschein gültig bis",
    "EVENT_DATA_OLD_DESCRIPTION":"Alte Planbeschreibung",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_DESCRIPTION":"Beschreibung des alten Händlerangebots",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISAVAILABLEFORBUNDLING":"Altes Händlerangebot",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_ISIMMEDIATEPROVISIONING":"Altes Händlerangebot",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_NAME":"Alter Angebotsname des Händlers",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PRICEFORPARTNER":"Altes Händlerangebot",
    "EVENT_DATA_OLD_DISTRIBUTOR_PRODUCT_PROVIDERSELLINGPRICE":"Altes Händlerangebot",
    "EVENT_DATA_OLD_ENTITY_ID":"Altes Wesen",
    "EVENT_DATA_OLD_ENTITY_NAME":"Altes Wesen",
    "EVENT_DATA_OLD_ENTITY_RECORD_NAME":"Transferiert von",
    "EVENT_DATA_OLD_ISPUBLIC":"Alter Plan ist öffentlich",
    "EVENT_DATA_OLD_NAME":"Alter Planname",
    "EVENT_DATA_OLD_OFFER":"Altes Angebot",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_DESCRIPTION":"Beschreibung des alten Partnerangebots",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISAVAILABLEFORBUNDLING":"Altes Partnerangebot",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_ISIMMEDIATEPROVISIONING":"Altes Partnerangebot",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_NAME":"Name des alten Partnerangebots",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PRICEFORPARTNER":"Altes Partnerangebot",
    "EVENT_DATA_OLD_PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"Altes Partnerangebot",
    "EVENT_DATA_OLD_PLAN":"Alter Plan",
    "EVENT_DATA_OLD_PRICE":"Alter Preis",
    "EVENT_DATA_OLD_QUANTITY":"Alte Menge",
    "EVENT_DATA_OLD_SEATS":"Alte Sitze",
    "EVENT_DATA_OLD_STATUS":"Alter Status",
    "EVENT_DATA_OLD_VALIDITY":"Alte Gültigkeit",
    "EVENT_DATA_OLD_VALIDITY_TERM":"Alte Gültigkeit",
    "EVENT_DATA_OLD_VALIDITYTYPE":"Alter Gültigkeitstyp",
    "EVENT_DATA_PAID_AMOUNT":"Bezahlte Menge",
    "EVENT_DATA_PAID_DATE":"Bezahltes Datum",
    "EVENT_DATA_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
    "EVENT_DATA_PARENT_SUBCRIPTION_ID":"Abonnement-ID des Azure-Plans",
    "EVENT_DATA_PARENT_SUBSCRIPTION_NAME":"Übergeordneter Produktname",
    "EVENT_DATA_PARTNER_NAME":"Partnername",
    "EVENT_DATA_PAYMENT_PROFILE_ALIAS":"Zahlungsprofil Alias",
    "EVENT_DATA_PAYMENT_PROFILE_ID":"Zahlungsprofil-ID",
    "EVENT_DATA_PAYMENT_TYPE":"Zahlungsart",
    "EVENT_DATA_PHONE_NUMBER":"Telefonnummer",
    "EVENT_DATA_PLAN_COUNT":"Anzahl der Pläne",
    "EVENT_DATA_PLAN_DESCRIPTION":"Beschreibung",
    "EVENT_DATA_PLAN_ID":"Plan-ID",
    "EVENT_DATA_PLAN_NAME":"Plan Name",
    "EVENT_DATA_PLAN_PRODUCT_ID":"PlanProduct Id",
    "EVENT_DATA_PLAN_TYPE":"Planart",
    "EVENT_DATA_PRICE":"Preis",
    "EVENT_DATA_PRODUCT_C3_SUBSCRIPTION_ID":"Produkt C3 Abonnement-ID",
    "EVENT_DATA_PRODUCT_MAPPING":"Produktzuordnung",
    "EVENT_DATA_PRODUCT_NAME":"Produktname",
    "EVENT_DATA_PRODUCT_NEW_NAME":"Produkt Neuer Name",
    "EVENT_DATA_PRODUCT_PRICE":"Produktpreis",
    "EVENT_DATA_PRODUCT_SUBSCRIPTION_ID":"Produktabonnement-ID",
    "EVENT_DATA_PROMOTION_ID":"Angebots-ID",
    "EVENT_DATA_PROVIDER":"Anbieter",
    "EVENT_DATA_PROVIDER_BUSINESS_ID":"Geschäfts-ID des Anbieters",
    "EVENT_DATA_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "EVENT_DATA_PROVIDER_CUSTOMER_NAME":"Name des Anbieterkunden",
    "EVENT_DATA_PROVIDER_ENTITY_NAME":"Name der Anbieterentität",
    "EVENT_DATA_PROVIDER_ID":"Anbieter-ID",
    "EVENT_DATA_PROVIDER_NAME":"Anbietername",
    "EVENT_DATA_PROVIDER_PAYMENT_PROFILE_ID":"Zahlungsprofil-ID des Anbieters",
    "EVENT_DATA_PROVIDER_PRICE":"Anbieterpreis",
    "EVENT_DATA_PROVIDER_PRODUCT_ID":"Abonnement-ID",
    "EVENT_DATA_PROVIDER_RESELLER_ID":"Provider Reseller Id",
    "EVENT_DATA_PROVIDER_RESELLER_NAME":"Name des Wiederverkäufers des Anbieters",
    "EVENT_DATA_PROVIDER_SUBSCRIPTION_ID":"Anbieter-Abonnement-ID",
    "EVENT_DATA_PSA_CUSTOMER_NAME":"PSA-Kunde",
    "EVENT_DATA_PSA_LOG_ID":"Batch-ID",
    "EVENT_DATA_PSA_NAME":"PSA-Name",
    "EVENT_DATA_PSA_PRODUCT_NAME":"PSA-Produkt",
    "EVENT_DATA_QUANTITY":"Menge",
    "EVENT_DATA_RECORD_ID":"Datensatz-ID",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_ASSIGNED":"RecordId des Benutzers",
    "EVENT_DATA_RECORD_ID_OF_USER_TO_BE_UNASSIGNED":"RecordId des Benutzers",
    "EVENT_DATA_RECORD_INT_ID":"Int",
    "EVENT_DATA_RECORD_NAME":"Datensatzname",
    "EVENT_DATA_RELEASED_SEATS":"Freigegebene Plätze",
    "EVENT_DATA_RENEWS_EXPIRES_ON":"Erneuert sich/läuft ab am",
    "EVENT_DATA_RENEWS_ON":"Erneuert weiter",
    "EVENT_DATA_REQUESTED_PRODUCT_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung des C3-Produkts",
    "EVENT_DATA_REQUESTED_QUANTITY":"Neue Menge",
    "EVENT_DATA_REQUESTED_STATUS":"Neuer Status",
    "EVENT_DATA_RESELLER_NAME":"Verkäufer Name",
    "EVENT_DATA_RESELLER_NEW_NAME":"Neuer Name des Wiederverkäufers",
    "EVENT_DATA_RESELLER_OLD_NAME":"Alter Name des Wiederverkäufers",
    "EVENT_DATA_RESERVATION_ORDER_ID":"Reservierungsauftrags-ID",
    "EVENT_DATA_ROLE":"Rolle",
    "EVENT_DATA_SALE_PRICE":"Verkaufspreis",
    "EVENT_DATA_SEAT_LIMIT":"Sitzplatz-Limit",
    "EVENT_DATA_SITE_NAME":"Seite-Name",
    "EVENT_DATA_SITEDEPARTMENT_NAME":"Abteilungsname",
    "EVENT_DATA_SOURCE_CURRENCY":"Quellwährung",
    "EVENT_DATA_SOURCE_OFFER_ID":"Quellangebots-ID",
    "EVENT_DATA_SOURCE_PRODUCT_NAME":"Produktname der Quelle",
    "EVENT_DATA_SOURCE_QUANTITY":"Quellmenge",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_BILLING_CYCLE_NAME":"Abrechnungszeitraum der Quelle",
    "EVENT_DATA_SOURCE_SUBSCRIPTION_ID":"Quellabonnement-ID",
    "EVENT_DATA_SOURCE_VALIDITY":"Quellengültigkeit",
    "EVENT_DATA_SOURCE_VALIDITY_TYPE":"Quellgültigkeitstyp",
    "EVENT_DATA_START_BILLING_PERIOD_ID":"ID des Abrechnungszeitraums starten",
    "EVENT_DATA_START_DATE":"Anfangsdatum",
    "EVENT_DATA_STATUS":"Status",
    "EVENT_DATA_SUB_TAXES":"Untersteuern",
    "EVENT_DATA_SUBSCRIPTION_ID":"Abonnement-ID",
    "EVENT_DATA_SUBSCRIPTION_NAME":"Abonnementname",
    "EVENT_DATA_SUBSCRIPTIONID":"Abonnement-ID",
    "EVENT_DATA_SUPPORTED_MARKET_CODE":"Unterstützter Marktcode",
    "EVENT_DATA_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "EVENT_DATA_SYNCED_PRODUCT":"Anzahl der synchronisierten Produkte",
    "EVENT_DATA_TARGET_CURRENCY":"Zielwährung",
    "EVENT_DATA_TARGET_OFFER_ID":"Zielangebots-ID",
    "EVENT_DATA_TARGET_PRODUCT_NAME":"Name des Zielprodukts",
    "EVENT_DATA_TARGET_QUANTITY":"Zielmenge",
    "EVENT_DATA_TARGET_SUBSCRIPTION_BILLING_CYCLE_NAME":"Angestrebter Abrechnungszeitraum",
    "EVENT_DATA_TARGET_SUBSCRIPTION_ID":"Zielabonnement-ID",
    "EVENT_DATA_TARGET_VALIDITY":"Zielgültigkeit",
    "EVENT_DATA_TARGET_VALIDITY_TYPE":"Zielgültigkeitstyp",
    "EVENT_DATA_TAX_CODE":"Steuer-Code",
    "EVENT_DATA_TAX_NAME":"Steuername",
    "EVENT_DATA_TAX_PERCENTAGE":"Steuerprozentsatz",
    "EVENT_DATA_TENANT_ID":"Mieter-ID",
    "EVENT_DATA_TENANT_NAME":"Name des Mieters",
    "EVENT_DATA_TO_RECIPIENTS":"An die Empfänger",
    "EVENT_DATA_TOTAL_AFFECTED_PRODUCTS":"Anzahl der betroffenen Produkte",
    "EVENT_DATA_TOTAL_RELEASED_SEATS":"Anzahl der betroffenen Plätze",
    "EVENT_DATA_TOTAL_TRANSACTION_AMOUNT":"Gesamtbetrag der Transaktion",
    "EVENT_DATA_TRANSACTION_AMOUNT_LIMIT":"Transaktionsbetragslimit",
    "EVENT_DATA_TRANSITION_TYPE":"Übergangstyp",
    "EVENT_DATA_UNSYNCED_PRODUCT":"Anzahl nicht synchronisierter Produkte",
    "EVENT_DATA_UPDATED_BUNDLE_NAME":"Bundle-Name aktualisiert",
    "EVENT_DATA_UPDATED_COUPON_NAME":"Gutscheinname aktualisiert",
    "EVENT_DATA_UPDATED_DISTRIBUTOR_PRODUCT_NAME":"Name des Händlerangebots aktualisiert",
    "EVENT_DATA_UPDATED_PARTNER_PRODUCT_NAME":"Name des Partnerangebots aktualisiert",
    "EVENT_DATA_UPDATED_PLAN_NAME":"Planname aktualisiert",
    "EVENT_DATA_UPDATED_PRODUCT_MAPPING":"Änderung der Produktzuordnung",
    "EVENT_DATA_URL":"URL",
    "EVENT_DATA_USAGE_LOCATION":"Verwendungsort",
    "EVENT_DATA_USER_DISPLAY_NAME":"Anzeigename",
    "EVENT_DATA_USER_EMAIL":"Benutzer Email",
    "EVENT_DATA_USER_TYPE":"Benutzertyp",
    "EVENT_DATA_USERNAME":"Nutzername",
    "EVENT_DATA_VALIDATION_ERROR":"Validierungsfehler",
    "EVENT_DATA_VALIDATION_STATUS":"Validierungsstatus",
    "EVENT_DATA_VALIDITY":"Gültigkeit",
    "EVENT_DATA_VALIDITY_TYPE":"Gültigkeitsart",
    "EVENT_DATA_VALUE":"Wert",
    "EVENT_DATA_VOUCHER_ENTITY":"Gutschein-Einheit",
    "EVENT_DATA_VOUCHER_IDS":"Gutschein-IDs",
    "EVENT_DATA_VOUCHERS":"Gutscheine",
    "EVENT_DATE":"Datum",
    "EVENT_DEPARTMENT_DESCRIPTION":"Abteilungsbeschreibung",
    "EVENT_DEPARTMENT_ID":"Abteilungs-ID",
    "EVENT_DEPARTMENT_NAME":"Abteilungsname",
    "EVENT_DESC_ACCEPT_REJECT_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Zustimmung zur Verlängerung des Partnerangebots annehmen oder ablehnen",
    "EVENT_DESC_ACCEPTED_NCE_TERMS_AND_CONDITIONS":"Akzeptierte NCE-Geschäftsbedingungen für die Bestellung von Microsoft NCE-Produkten",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS":"Akzeptierte Bedingungen und Konditionen",
    "EVENT_DESC_ACCEPTED_TERMSANDCONDITIONS_FAILURE":"Akzeptierte Geschäftsbedingungen scheitern",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION":"Aktivieren Sie das DangerThreshold Met-Abonnement",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_ALERT_EMAIL":"Aktivieren Sie das DangerThreshold Met-Abonnement",
    "EVENT_DESC_ACTIVATE_DANGER_THRESHOLD_MET_SUBSCRIPTION_FAILURE":"Aktivieren Sie den Abonnementfehler bei Erreichen des Gefahrenschwellenwerts",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER":"Aktivieren Sie das benutzerdefinierte Bereitstellungsangebot",
    "EVENT_DESC_ACTIVATE_PROVISIONING_CUSTOM_OFFER_FAILURE":"Aktivieren Sie Provisioning Custom Offer Failure",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_FAILURE":"Fehler beim Aktivieren des Abonnements",
    "EVENT_DESC_ACTIVATE_SUBSCRIPTION_SUCCESS":"Abo-Erfolg aktivieren",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILED":"Fehler beim Erstellen des Kunden",
    "EVENT_DESC_ADD_C3_CUSTOMER_FAILURE":"C3-Kundenfehler hinzufügen",
    "EVENT_DESC_ADD_C3_CUSTOMER_SUCCESS":"Fügen Sie den C3-Kundenerfolg hinzu",
    "EVENT_DESC_ADD_ENTITLEMENTS":"Berechtigung hinzufügen",
    "EVENT_DESC_ADD_ENTITLEMENTS_FAILURE":"Berechtigungsfehler hinzufügen",
    "EVENT_DESC_ADD_GROUP_ADMIN":"Gruppenadministrator hinzufügen",
    "EVENT_DESC_ADD_GROUP_ADMIN_FAILURE":"Gruppenadministrationsfehler hinzufügen",
    "EVENT_DESC_ADJUST_AZURE_MARGIN":"Passen Sie die Azure-Marge an",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_FAILED":"Anpassen der Azure-Marge fehlgeschlagen",
    "EVENT_DESC_ADJUST_AZURE_MARGIN_SUCCESS":"Passen Sie die Azure-Marge an",
    "EVENT_DESC_APPROVED_BY":"Genehmigt von",
    "EVENT_DESC_APPROVED_ON":"Genehmigt am",
    "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES":"Genehmigte oder abgelehnte nächste Planverlängerungsänderungen",
    "EVENT_DESC_APPROVED_OR_REJECTED_NEXT_SCHEDULE_RENEWAL_CHANGES_REQUEST":"Genehmigte oder abgelehnte nächste Planverlängerungsänderungen",
    "EVENT_DESC_APPROVED_OR_REJECTED_PURCHASE_REQUEST":"Genehmigte oder abgelehnte Kaufanfrage",
    "EVENT_DESC_APPROVED_PURCHASE_REQUEST":"Genehmigte Kaufanfrage",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN":"Plan dem Kunden zuweisen",
    "EVENT_DESC_ASSIGN_CUSTOMER_PLAN_FAILURE":"Plan konnte dem Kunden nicht zugewiesen werden",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_FAILED":"Zuweisen verpasster Gutscheine fehlgeschlagen",
    "EVENT_DESC_ASSIGN_MISSING_VOUCHERS_SUCCESS":"Versäumte Gutscheine erfolgreich zugewiesen",
    "EVENT_DESC_AUTO_GRANT_AD_USER_ACCESS_TO_CUSTOMERS":"AD Global Admin erhält automatisch Zugriff auf Kunden",
    "EVENT_DESC_AUTO_PROCESSESED_CUSTOMER_PRODUCT_SUBSCRIPTIONS":"Automatisch verarbeitete Kundenproduktabonnements",
    "EVENT_DESC_AUTO_RELEASE_UNUSED_SEATS":"Unbenutzte Plätze automatisch freigeben",
    "EVENT_DESC_AUTO_SYNC_FIX_MISMATCH":"Diskrepanzen aufgrund von Auto-Sync behoben",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC":"Abrechnungszeitraum erfolgreich geändert (Auto Sync)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_AUTO_SYNC_FAILED":"Änderung des Abrechnungszeitraums fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC":"Abrechnungszeitraum erfolgreich geändert (manuelle Synchronisierung)",
    "EVENT_DESC_BILLING_CYCLE_CHANGE_FROM_MANUAL_SYNC_FAILED":"Änderung des Abrechnungszeitraums fehlgeschlagen (manuelle Synchronisierung)",
    "EVENT_DESC_BULK_ONBOARD_BATCH_SUMMARY":"Massen-Batch-Zusammenfassung an Bord",
    "EVENT_DESC_BULK_USER_UPLOAD":"Massenbenutzer-Upload",
    "EVENT_DESC_BULK_USER_UPLOAD_FAILURE":"Fehler beim Hochladen von Massenbenutzern",
    "EVENT_DESC_BUNDLE_OFFERS_ARE_PURCHASED":"Bundle-Angebote werden gekauft",
    "EVENT_DESC_BUNDLE_PRODUCT_PURCHASE_ORDER":"Produktbestellung bündeln",
    "EVENT_DESC_CANCEL_SCHEDULED_RENEWAL":"Erfolgreiche geplante Verlängerung abbrechen.",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST":"Stornierte Abonnement-Kaufanfrage",
    "EVENT_DESC_CANCELLED_SUBSCRIPTION_PURCHASE_REQUEST_FAILURE":"Fehler bei stornierter Abonnement-Kaufanfrage",
    "EVENT_DESC_CART_AVAILABILITY_STATUS":"Warenkorb-Verfügbarkeitsstatus",
    "EVENT_DESC_CART_CHECKOUT":"Warenkorb Kasse",
    "EVENT_DESC_CART_CHECKOUT_FAILURE":"Warenkorbprüfung fehlgeschlagen",
    "EVENT_DESC_CART_ITEM_SEAT_LIMIT_EXCEEDED_NOTIFICATION":"Benachrichtigen, dass das Sitzplatzlimit überschritten wurde",
    "EVENT_DESC_CART_ITEM_TRANSACTION_LIMIT_EXCEEDED_NOTIFICATION":"Benachrichtigen Sie, dass das Transaktionslimit überschritten wurde",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung des Kundenprodukts geändert",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_AUTO_RENEW_STATUS_FAILED":"Status der automatischen Verlängerung des Kundenprodukts konnte nicht geändert werden",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS":"Der Produktstatus des Kunden wurde erfolgreich aktualisiert",
    "EVENT_DESC_CHANGE_CUSTOMER_PRODUCT_STATUS_FAILURE":"Das Ändern des Produktstatus ist fehlgeschlagen",
    "EVENT_DESC_CHANGE_INVOICE_STATUS":"ChangeInvoice Status",
    "EVENT_DESC_CHANGE_INVOICES_TATUS_FAILURE":"Fehler beim Ändern des Rechnungsstatus",
    "EVENT_DESC_CHANGE_PLAN_FAILURE":"Planversagen ändern",
    "EVENT_DESC_CHANGE_PLAN_SUCCESS":"Planerfolg ändern",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_FAILURE":"Fehler bei der Währungsumrechnung beim Klonen",
    "EVENT_DESC_CLONE_CURRENCY_CONVERSION_SUCCESS":"Erfolgreiche Währungsumrechnung klonen",
    "EVENT_DESC_CLONE_PLAN":"Erfolgreich geklonter Plan",
    "EVENT_DESC_CLONE_PLAN_FAILURE":"Plan konnte nicht geklont werden",
    "EVENT_DESC_COUPON_IS_APPPLIED_TO_ALL_CUSTOMERS":"Coupon gilt für alle markierten Kunden?",
    "EVENT_DESC_COUPON_NAME":"Coupon Name",
    "EVENT_DESC_COUPON_STATUS":"Gutscheinstatus",
    "EVENT_DESC_CREATE_AZURE_GROUPS":"Erstellen Sie Azure-Gruppen",
    "EVENT_DESC_CREATE_AZURE_GROUPS_FAILURE":"Fehler beim Erstellen von Azure-Gruppen",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION":"Erstellen Sie ein Azure-Abonnement",
    "EVENT_DESC_CREATE_AZURE_SUBSCRIPTION_FAILURE":"Erstellen eines Azure-Abonnementfehlers",
    "EVENT_DESC_CREATE_CART":"Warenkorb erstellen",
    "EVENT_DESC_CREATE_CART_FAILURE":"Fehler beim Erstellen des Warenkorbs",
    "EVENT_DESC_CREATE_CUSTOMER_FAILURE":"Kundenfehler erstellen",
    "EVENT_DESC_CREATE_INVOICE":"Rechnung erstellen",
    "EVENT_DESC_CREATE_ORDER":"Bestellung anlegen",
    "EVENT_DESC_CREATE_ORDER_FAILURE":"Bestellfehler erstellen",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE":"Zahlungsprofil erstellen",
    "EVENT_DESC_CREATE_PAYMENT_PROFILE_FAILURE":"Fehler beim Erstellen des Zahlungsprofils",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_FAILED":"Das Erstellen eines Abonnements für reservierte Instanzen durch Synchronisierung ist fehlgeschlagen",
    "EVENT_DESC_CREATE_RESERVED_INSTANCES_SUBSCRIPTION_BY_SYNC_SUCCESS":"Das Abonnement für reservierte Instanzen wurde durch Synchronisierung erfolgreich erstellt",
    "EVENT_DESC_CREATE_SCHEDULED_RENEWAL":"Schaffen Sie eine erfolgreiche geplante Verlängerung",
    "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK":"Abonnementablaufprüfung erstellen",
    "EVENT_DESC_CREATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Ablauf des Abonnements erstellen fehlgeschlagen",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC":"Abonnement erfolgreich erstellt (Auto Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_FAILED":"Erstellung des Abonnements fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_AUTO_SYNC_SKIPPED":"Erstellen eines Abonnements von Auto Sync übersprungen",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC":"Abonnement erfolgreich erstellt (manuelle Synchronisierung)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_FAILED":"Erstellen des Abonnements fehlgeschlagen (manuelle Synchronisierung)",
    "EVENT_DESC_CREATE_SUBSCRIPTION_FROM_MANUAL_SYNC_SKIPPED":"Das Erstellen des Abonnements aus der manuellen Synchronisierung wurde übersprungen",
    "EVENT_DESC_CREATE_TAX_RULE":"Steuerregel erstellen",
    "EVENT_DESC_CREATE_TAX_RULE_FAILURE":"Erstellen Sie Tax RuleFailure",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER":"Benutzer für Nutzungsabonnement erstellen",
    "EVENT_DESC_CREATE_USAGE_SUBSCRIPTION_USER_FAILURE":"Erstellen Sie einen UsageSubscription-Benutzerfehler",
    "EVENT_DESC_CREATE_USER":"Benutzer erstellen",
    "EVENT_DESC_CREATE_USER_FAILURE":"Benutzerfehler erstellen",
    "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL":"Zustimmung zur Verlängerung des Partnerangebots",
    "EVENT_DESC_CUSTOMER_CONSENT_SUBSCRIPTION_RENEWAL_FAILED":"Zustimmung zur Verlängerung des Partnerangebots ist fehlgeschlagen",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API":"Kundenerstellung über API",
    "EVENT_DESC_CUSTOMER_CREATION_THROUGH_API_FAILURE":"Kundenerstellung durch API-Fehler",
    "EVENT_DESC_CUSTOMER_DELETION_FAILURE":"Fehler beim Löschen des Kunden",
    "EVENT_DESC_CUSTOMER_DELETION_SUCCESS":"Kundenlöschung erfolgreich",
    "EVENT_DESC_CUSTOMER_INVOICE_GENERATED":"Kundenrechnung generiert",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_FAILURE":"Fehler beim Aktualisieren des Kundennamens",
    "EVENT_DESC_CUSTOMER_NAME_UPDATE_SUCCESS":"Erfolg beim Aktualisieren des Kundennamens",
    "EVENT_DESC_CUSTOMER_SEATS_LIMIT_EXCEEDED_NOTIFICATION":"Das Limit für Kundenlizenzen überschreitet die Benachrichtigung",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_DESC":"PSA-Protokoll deaktivieren",
    "EVENT_DESC_DEACTIVATE_PSA_LOG_FAILED_DESC":"PSA-Protokoll deaktivieren fehlgeschlagen",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION":"Währungsumrechnung definieren",
    "EVENT_DESC_DEFINE_CURRENCY_CONVERSION_FAILURE":"Währungsumrechnungsfehler definieren",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY":"Planpreise in neuer Währung definieren",
    "EVENT_DESC_DEFINE_PLAN_PRICES_IN_NEW_CURRENCY_FAILURE":"Planpreise bei Ausfall einer neuen Währung definieren",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER":"Verknüpfung zum Rechnungskunden aufheben",
    "EVENT_DESC_DEINK_BILLING_CUSTOMER_FAILURE":"Fehler beim Abrechnungskunden aufheben",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER":"Account Manager-Erfolg löschen",
    "EVENT_DESC_DELETE_ACCOUNT_MANAGER_FAILED":"Account Manager-Fehler löschen",
    "EVENT_DESC_DELETE_CART_ITEM":"Artikel im Warenkorb gelöscht",
    "EVENT_DESC_DELETE_CART_ITEM_FAILURE":"Löschen des Warenkorbartikels fehlgeschlagen",
    "EVENT_DESC_DELETE_CONTRACT_OFFER":"Vertragsangebot erfolgreich gelöscht",
    "EVENT_DESC_DELETE_CONTRACT_OFFER_FAILED":"Löschen des Vertragsangebots fehlgeschlagen",
    "EVENT_DESC_DELETE_COUPON":"Coupon erfolgreich deaktiviert",
    "EVENT_DESC_DELETE_COUPON_FAILED":"Das Löschen des Gutscheins ist fehlgeschlagen.",
    "EVENT_DESC_DELETE_COUPON_FAILURE":"Coupon konnte nicht deaktiviert werden",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION":"Währungsumrechnung löschen",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FAILURE":"Währungsumrechnungsfehler löschen",
    "EVENT_DESC_DELETE_CURRENCY_CONVERSION_FALURE":"Währungsumrechnung konnte nicht gelöscht werden",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Löschen der benutzerdefinierten Benachrichtigung",
    "EVENT_DESC_DELETE_CUSTOM_NOTIFICATIONS_SUCCESS":"Benutzerdefinierte Benachrichtigung erfolgreich löschen",
    "EVENT_DESC_DELETE_CUSTOM_OFFER":"Benutzerdefiniertes Angebot erfolgreich gelöscht",
    "EVENT_DESC_DELETE_CUSTOM_OFFER_FAILED":"Löschen des benutzerdefinierten Angebots fehlgeschlagen",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT":"Kundenzahlung löschen",
    "EVENT_DESC_DELETE_CUSTOMER_PAYMENT_FAILURE":"Fehler beim Löschen der Kundenzahlung",
    "EVENT_DESC_DELETE_DEPARTMENT_FAILURE":"Abteilungsfehler löschen",
    "EVENT_DESC_DELETE_DEPARTMENT_SUCCESS":"Abteilungserfolg löschen",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER":"Händlerangebot erfolgreich gelöscht",
    "EVENT_DESC_DELETE_DISTRIBUTOR_OFFER_FAILURE":"Das Löschen des Händlerangebots ist fehlgeschlagen",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung löschen",
    "EVENT_DESC_DELETE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Löschen der Ereignis-E-Mail-Benachrichtigung",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT":"Rechnungsanpassung löschen",
    "EVENT_DESC_DELETE_INVOICE_ADJUSTMENT_FAILURE":"Fehler bei der Rechnungsanpassung löschen",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM":"Rechnungen löschen LineItem",
    "EVENT_DESC_DELETE_INVOICES_LINEITEM_FAILURE":"Rechnungen löschen LineItemFailure",
    "EVENT_DESC_DELETE_PARTNER_USER_FAILURE":"Partnerbenutzerfehler löschen",
    "EVENT_DESC_DELETE_PAYMENT_OPTION":"Zahlungsoption löschen",
    "EVENT_DESC_DELETE_PAYMENT_OPTION_FAILURE":"Zahlungsoptionsfehler löschen",
    "EVENT_DESC_DELETE_PLAN":"Plan löschen",
    "EVENT_DESC_DELETE_PLAN_FAILURE":"Planfehler löschen",
    "EVENT_DESC_DELETE_QUOTE_FAILED":"Löschen des Angebots fehlgeschlagen",
    "EVENT_DESC_DELETE_QUOTE_SUCCESS":"Angebot löschen erfolgreich",
    "EVENT_DESC_DELETE_SITE_FAILURE":"Site-Fehler löschen",
    "EVENT_DESC_DELETE_SITE_SUCCESS":"Site-Erfolg löschen",
    "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK":"Löschen der Abonnementablaufprüfung",
    "EVENT_DESC_DELETE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Löschen des Abonnements nach Ablauf fehlgeschlagen",
    "EVENT_DESC_DELETE_TAX_RULE":"Steuerregel löschen",
    "EVENT_DESC_DELETE_TAX_RULE_FAILURE":"Steuerregelfehler löschen",
    "EVENT_DESC_DELETE_UPGRADE_AZURESUBSCRIPTION_FAILURE":"Upgrade des Azure-Abonnements fehlgeschlagen",
    "EVENT_DESC_DELETE_USAGE_SUBSCRIPTION_USER":"Benutzer für Nutzungsabonnement löschen",
    "EVENT_DESC_DELETE_USAGESUBSCRIPTION_USER_FAILURE":"Benutzerfehler beim Abonnement löschen",
    "EVENT_DESC_DELETE_USER":"Benutzer löschen",
    "EVENT_DESC_DELETE_USER_FAILURE":"Benutzerfehler löschen",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_FAILED":"Fehler beim Löschen der Webhook-Benachrichtigung",
    "EVENT_DESC_DELETE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Webhook-Benachrichtigung erfolgreich löschen",
    "EVENT_DESC_DISABLE_AZURE_SUBSCRIPTION":"Deaktivieren Sie das Azure-Abonnement",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht deaktiviert werden",
    "EVENT_DESC_DISABLE_COUPON_ASSIGNMENT_SUCCESS":"Gutscheinzuweisung deaktiviert",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN":"Deaktivieren Sie den Plan für den Kunden",
    "EVENT_DESC_DISABLE_CUSTOMER_PLAN_FAILURE":"Plan für Kunden konnte nicht deaktiviert werden",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Bericht zu geplanten Rechnungspositionen",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Geplante Rechnungspositionen mit Provisionsbericht",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Geplanter Rechnungszusammenfassungsbericht",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Geplanter Rentabilitätsbericht",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"Bericht zu geplanten Steuerpositionen",
    "EVENT_DESC_EMAIL_NOTIFICATION_FOR_SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Geplanter Steuerzusammenfassungsbericht",
    "EVENT_DESC_ENABLE_AZURE_SUBSCRIPTION":"Aktivieren Sie das Azure-Abonnement",
    "EVENT_DESC_ENABLE_COUPON":"Coupon erfolgreich aktiviert",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_FAILURE":"Gutscheinzuweisung konnte nicht aktiviert werden",
    "EVENT_DESC_ENABLE_COUPON_ASSIGNMENT_SUCCESS":"Couponzuweisung aktiviert",
    "EVENT_DESC_ENABLE_COUPON_FAILED":"Die Couponaktivierung ist fehlgeschlagen.",
    "EVENT_DESC_ENABLE_COUPON_FAILURE":"Coupon konnte nicht aktiviert werden",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN":"Plan dem Kunden zuweisen",
    "EVENT_DESC_ENABLE_CUSTOMER_PLAN_FAILURE_FAILURE":"Fehler beim Zuweisen des Plans zum Kunden",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_ANNUAL_SUBSCRIPTION_EXPIRATION":"Aktivieren Sie die Benachrichtigung für den Ablauf des Jahresabonnements",
    "EVENT_DESC_ENABLE_NOTIFICATION_FOR_MONTHLY_SUBSCRIPTION_EXPIRATION":"Aktivieren Sie die Benachrichtigung, wenn ein monatliches Abonnement verlängert wird",
    "EVENT_DESC_ENQUEUE_REQUEST_FOR_BULK_ONBOARD_CUSTOMERS_FAILED":"Anforderung für Bulk Onboarding-Kunden konnte nicht in die Warteschlange gestellt werden",
    "EVENT_DESC_ENQUEUED_REQUEST_TO_CLONE_RESELLER_PLAN":"Anfrage zum Klonen des Reseller-Plans in die Warteschlange gestellt",
    "EVENT_DESC_ERROR_LOG_NOTIFY_REPORT":"Fehlerprotokoll Benachrichtigungsbericht",
    "EVENT_DESC_EVENT_DESC_FAILED_TO_UPDATE_SCHEDULED_RENEWAL":"Die geplante Erneuerung des Updates ist fehlgeschlagen",
    "EVENT_DESC_EXPORT_CUSTOMER_LICENSE_REPORT":"Kundenlizenzbericht exportieren.",
    "EVENT_DESC_EXPORT_USER_LICENSE_ASSIGNMENT_REPORT":"Benutzerlizenzzuweisungsbericht exportieren",
    "EVENT_DESC_FAILED_TO_APPROVE_OR_REJECT_NEXT_SCHEDULED_RENEWAL_REQUEST":"Die nächste geplante Verlängerungsanfrage konnte nicht genehmigt oder abgelehnt werden",
    "EVENT_DESC_FAILED_TO_CANCEL_SCHEDULED_RENEWAL":"Das Abbrechen der geplanten Verlängerung ist fehlgeschlagen",
    "EVENT_DESC_FAILED_TO_CLONE_RESELLER_PLAN":"Reseller-Plan konnte nicht geklont werden",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_ACCOUNTING_SOFTWARE":"Verbindung zur Buchhaltungssoftware konnte nicht hergestellt werden",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PARTNER_CENTER":"Verbindung zum Microsoft Partner Center konnte nicht hergestellt werden",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_MICROSOFT_PRICING_API":"Fehler beim Herstellen einer Verbindung mit der Microsoft-Preis-API",
    "EVENT_DESC_FAILED_TO_CONNECT_TO_PSA":"Verbindung zu PSA konnte nicht hergestellt werden",
    "EVENT_DESC_FAILED_TO_CREATE_ONDEMAND_INVOICE":"On-Demand-Rechnung konnte nicht erstellt werden",
    "EVENT_DESC_FAILED_TO_CREATE_SCHEDULED_RENEWAL":"Die Erstellung einer geplanten Verlängerung ist fehlgeschlagen",
    "EVENT_DESC_FAILED_TO_ENQUEUE_REQUEST_FOR_CLONING_RESELLER_PLAN":"Anfrage zum Klonen des Reseller-Plans konnte nicht in die Warteschlange gestellt werden",
    "EVENT_DESC_FAILED_TO_SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_BATCH_TO_COMPLETE":"Fehler beim Festlegen des Batch-Onboard-Batch-Status auf \\''Abgeschlossen\\''",
    "EVENT_DESC_FAILED_TO_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Fehler beim Setzen des Stapelstatus der Benutzerlizenzzuweisung auf \\''Abgeschlossen\\''",
    "EVENT_DESC_FAILED_TO_SYNC_PROVIDER_CUSTOMER_PROFILE":"Anbieter-Kundenprofil konnte nicht synchronisiert werden",
    "EVENT_DESC_FAILED_TO_UPDATE_ONDEMAND_INVOICE":"On-Demand-Rechnung konnte nicht aktualisiert werden",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_AUTOMATICALLY":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung automatisch",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Fix Auto Renew status Mismatch Automatically Failed",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung manuell",
    "EVENT_DESC_FIX_AUTO_RENEW_STATUS_MISMATCH_MANUALLY_FAILED":"Korrigieren Sie den Statuskonflikt bei der automatischen Verlängerung manuell fehlgeschlagen",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY":"Angebotskonflikt erfolgreich behoben (Auto Sync)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_AUTOMATICALLY_FAILED":"Fehler bei der Behebung von Angebotskonflikten (Auto Sync)",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY":"Beheben Sie den Angebotskonflikt manuell",
    "EVENT_DESC_FIX_PROMOTION_MISMATCH_MANUALLY_FAILED":"Angebotskonflikt manuell beheben fehlgeschlagen",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY":"Mengenabweichungen erfolgreich behoben (Auto Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_AUTOMATICALLY_FAILED":"Behebung der Mengenabweichung fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY":"Mengenabweichung erfolgreich behoben (manuelle Sync)",
    "EVENT_DESC_FIX_QUANTITY_MISMATCH_MANUALLY_FAILED":"Behebung der Mengenabweichung fehlgeschlagen (manuelle Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY":"Statuskonflikt erfolgreich behoben (Auto Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_AUTOMATICALLY_FAILED":"Behebung von Statuskonflikten fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY":"Statuskonflikt erfolgreich behoben (manuelle Sync)",
    "EVENT_DESC_FIX_STATUS_MISMATCH_MANUALLY_FAILED":"Beheben von Statuskonflikten fehlgeschlagen (manuelle Sync)",
    "EVENT_DESC_GET_LICENSE_CONSUMPTION_DISCREPANCY":"Diskrepanz beim Lizenzverbrauch",
    "EVENT_DESC_GRANT_ACCESSTO_PORTAL_FAILURE":"Gewähren Sie Zugriff auf Portalfehler",
    "EVENT_DESC_GRANT_ACCESTO_PORTAL":"Gewähren Sie Zugriff auf das Portal",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS":"Fehler beim Hochladen von Massenbenutzern ignorieren",
    "EVENT_DESC_IGNORE_BULKUSER_UPLOAD_ERRORS_FAILURE":"Fehler beim Hochladen von Massenbenutzern ignorieren",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_PURCHASE_ERRORS":"Fehler beim Kauf von Abonnements ignorieren",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS":"Fehler beim Abonnement-Update ignorieren",
    "EVENT_DESC_IGNORE_SUBSCRIPTION_UPDATE_ERRORS_FAILURE":"Fehler bei Abonnementaktualisierungsfehlern ignorieren",
    "EVENT_DESC_IGNORE_THE_ERROR_INS_UBSCRIPTION_PURCHASE_FAILURE":"Ignorieren Sie den Fehler beim Kauf eines Abonnements",
    "EVENT_DESC_INVOICE_AND_REPORT":"Rechnung und Bericht",
    "EVENT_DESC_INVOICE_PAYMENT_INITIATED":"Rechnungszahlung eingeleitet",
    "EVENT_DESC_INVOICES_AND_DUES_REPORT":"Rechnungs- und Gebührenbericht",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_FAILURE":"Linkabrechnungsanbieter Kundenfehler",
    "EVENT_DESC_LINK_BILLING_PROVIDER_CUSTOMER_SUCCESS":"Link Billing Provider Kundenerfolg",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_FAILURE":"Kundenfehler des Verbindungsdienstanbieters",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_CUSTOMER_SUCCESS":"Link Service Provider Kundenerfolg",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_FAILED":"Nicht-csp-Kundenfehler des Verbindungsdienstanbieters",
    "EVENT_DESC_LINK_SERVICE_PROVIDER_NON_CSP_CUSTOMER_SUCCESS":"Link Service Provider nicht-csp Kundenerfolg",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_FAILED":"Link Billing Provider Kundenerfolg",
    "EVENT_DESC_LINKBILLING_PROVIDERCUSTOMER_SUCCESS":"Linkabrechnungsanbieter Kundenfehler",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_FAILED":"Kundenfehler des Verbindungsdienstanbieters",
    "EVENT_DESC_LINKSERVICE_PROVIDERCUSTOMER_SUCCESS":"Link Service Provider Kundenerfolg",
    "EVENT_DESC_MANAGE_USER_LICENSES_FAILED":"Verwaltung von Benutzerlizenzen fehlgeschlagen.",
    "EVENT_DESC_MANAGE_USER_LICENSES_SUCCESS":"Die Verwaltung der Benutzerlizenzen war erfolgreich.",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES":"Planen Sie die nächsten Verlängerungsänderungen",
    "EVENT_DESC_NEXT_SCHEDULE_RENEWAL_CHANGES_FAILED":"Die nächsten Verlängerungsänderungen konnten nicht geplant werden",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_ACTIVATED_STATUS":"Kaufanfrage für NI Partner-Produkte akzeptiert",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_CANCELLED_STATUS":"Kaufanfrage für NI Partner-Produkte storniert",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_PURCHASED":"NI Partner Produkt gekauft",
    "EVENT_DESC_NON_IMMEDIATE_PROVISIONING_OFFER_QUANTITY_UPDATED":"NI Partner Produktmenge aktualisiert",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_ACTIVATE_STATUS":"Benachrichtigen Sie den Aktivierungsstatus des NI Provisioning-Produktkaufs",
    "EVENT_DESC_NOTIFY_NI_PROVISIONING_PRODUCT_PURCHASE_CANCELLED_STATUS":"Benachrichtigen Sie den Status von NI Provisioning Product Purchase Cancelled",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC":"Angebot erfolgreich geändert (Auto Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_AUTO_SYNC_FAILED":"Angebotsänderung fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC":"Angebot erfolgreich geändert (manuelle Sync)",
    "EVENT_DESC_OFFER_CHANGE_FROM_MANUAL_SYNC_FAILED":"Angebotsänderung fehlgeschlagen (manuelle Sync)",
    "EVENT_DESC_OFFERS_ARE_PURCHASED":"Bestellung anlegen",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC":"Integrierter Azure-Plan erfolgreich (Auto Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_AUTO_SYNC_FAILED":"Onboarding des Azure-Plans fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC":"Integrierter Azure-Plan erfolgreich (manuelle Sync)",
    "EVENT_DESC_ONBOARD_AZURE_PLAN_FROM_MANUAL_SYNC_FAILED":"Onboarding des Azure-Plans fehlgeschlagen (manuelle Sync)",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILED":"Kundenversagen an Bord",
    "EVENT_DESC_ONBOARD_CUSTOMER_FAILURE":"Kundenversagen an Bord",
    "EVENT_DESC_ONBOARD_CUSTOMER_SUCCESS":"Kundenerfolg an Bord",
    "EVENT_DESC_PARTNER_OFFERS_EXPORTABLE_REPORT":"Der Partner bietet exportierbare Berichte an",
    "EVENT_DESC_PARTNER_USER_ADDED_FAILURE":"Fehler des Partnerbenutzers hinzugefügt",
    "EVENT_DESC_PARTNER_USERADDED":"Partnerbenutzer hinzugefügt",
    "EVENT_DESC_PASSWORD_EXPIRED":"Passwort abgelaufen",
    "EVENT_DESC_PAYMENT_FAILURE":"Zahlungsfehler",
    "EVENT_DESC_PAYMENT_SUCCESS":"Zahlungserfolg",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_Failed":"Die erfasste Sicherheitseinwilligung des Partner Centers ist fehlgeschlagen",
    "EVENT_DESC_PC_SECURITY_CONSENT_CAPTURED_SUCCESS":"Partner Center-Sicherheitsgenehmigung erfolgreich erfasst",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES":"Zip für alle Rechnungen erfolgreich erstellt.",
    "EVENT_DESC_PREPARED_ZIP_FOR_ALL_INVOICES_FAILED":"Beim Erstellen von Zip für alle Rechnungen ist ein Fehler aufgetreten.",
    "EVENT_DESC_PRODUCT_ASSIGNMENT":"Produktzuweisung",
    "EVENT_DESC_PRODUCT_ASSIGNMENT_FAILURE":"Produktzuweisungsfehler",
    "EVENT_DESC_PROMOTION_LOADED_SUCCESSFULLY":"Angebote erfolgreich geladen",
    "EVENT_DESC_PROMOTION_LOADING_FAILED":"Angebote konnten nicht geladen werden",
    "EVENT_DESC_PROVIDER_PRODUCTS_PRICE_UPDATED":"Preis für Anbieterprodukte aktualisiert",
    "EVENT_DESC_PUBLIC_SIGN_UP_ASSSTANCE_REQUEST":"Bitte um Unterstützung bei der öffentlichen Anmeldung",
    "EVENT_DESC_PURCHASE_BUNDLE_SUBSCRIPTIONS_FAILURE":"Bundle-Kauf fehlgeschlagen",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER":"Kauf eines neuen Partnerangebots",
    "EVENT_DESC_PURCHASE_NEWCUSTOM_OFFER_FAILURE":"Kauf eines neuen benutzerdefinierten Angebots fehlgeschlagen",
    "EVENT_DESC_PURCHASE_NI_PROVISIONING_PRODUCT":"Kaufen Sie das NI Provisioning-Produkt",
    "EVENT_DESC_PURCHASE_REQUEST_PENDING_APPROVAL":"Kaufanfrage ausstehende Genehmigung",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS":"Bestellung anlegen",
    "EVENT_DESC_PURCHASE_SUBSCRIPTIONS_FAILURE":"Bestellfehler erstellen",
    "EVENT_DESC_QUOTATION_CUSTOMER":"Angebot Kunde",
    "EVENT_DESC_QUOTATION_NAME":"Zitatname",
    "EVENT_DESC_QUOTATION_OWNER":"Zitatbesitzer",
    "EVENT_DESC_QUOTE_APPROVAL":"Angebotsfreigabe",
    "EVENT_DESC_QUOTE_CONVERSION_SUCCESS":"Angebotskonvertierung erfolgreich",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT":"Erhöhte Anfrage zur Aktualisierung des gekauften Produkts",
    "EVENT_DESC_RAISED_REQUEST_TO_UPDATE_PURCHASED_PRODUCT_FAILED":"Anfrage zum Aktualisieren des Kaufprodukts fehlgeschlagen",
    "EVENT_DESC_RECON_MISSMATCH_UPFRONT_INVOICE_GENERATION":"Untersuchen Sie Diskrepanzen bei der Erstellung von Vorausrechnungen",
    "EVENT_DESC_RECORD_PAYMENT":"Rekordzahlung",
    "EVENT_DESC_RECORD_PAYMENT_FAILURE":"Zahlungsausfall aufzeichnen",
    "EVENT_DESC_REJECTED_PURCHASE_REQUEST":"Kaufanfrage abgelehnt",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS":"Abgelehnte Geschäftsbedingungen",
    "EVENT_DESC_REJECTED_TERMSANDCONDITIONS_FAILURE":"Abgelehnte Geschäftsbedingungen scheitern",
    "EVENT_DESC_RESELLER_NAME_UPDATE_FAILURE":"Aktualisierung des Reseller-Namens fehlgeschlagen",
    "EVENT_DESC_RESELLER_NAME_UPDATE_SUCCESS":"Reseller-Name erfolgreich aktualisiert",
    "EVENT_DESC_RESET_USER_PASSWORD":"Benutzerkennwort zurücksetzen",
    "EVENT_DESC_RESET_USER_PASSWORD_FAILURE":"Fehler beim Zurücksetzen des Benutzerkennworts",
    "EVENT_DESC_REVERT_ENTITLEMENT_PRICE":"Anspruch auf Preisrücknahme erfolgreich",
    "EVENT_DESC_REVERT_ENTITLEMENT_PRICE_FAILURE":"Fehler beim Zurücksetzen des Anspruchspreises",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION":"TenantConfiguration zurücksetzen",
    "EVENT_DESC_REVERT_TENANTCONFIGURATION_FAILURE":"TenantConfiguration-Fehler zurücksetzen",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL":"Zugriff auf Portal widerrufen",
    "EVENT_DESC_REVOKE_ACCESSTO_PORTAL_FAILURE":"Zugriff auf Portalfehler widerrufen",
    "EVENT_DESC_SAVE_COMMENT":"Neuer Kommentar hinzugefügt",
    "EVENT_DESC_SAVE_CONTRACT_OFFER":"Vertragsangebot erfolgreich gespeichert",
    "EVENT_DESC_SAVE_CONTRACT_OFFER_FAILED":"Speichern des Vertragsangebots fehlgeschlagen",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Speichern der benutzerdefinierten Benachrichtigung",
    "EVENT_DESC_SAVE_CUSTOM_NOTIFICATIONS_SUCCESS":"Speichern Sie den Erfolg der benutzerdefinierten Benachrichtigung",
    "EVENT_DESC_SAVE_CUSTOM_OFFER":"Benutzerdefiniertes Angebot speichern",
    "EVENT_DESC_SAVE_CUSTOM_OFFER_FAILURE":"Benutzerdefinierten Angebotsfehler speichern",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_FAILURE":"Fehler beim Speichern des Kundenabrechnungsprofils",
    "EVENT_DESC_SAVE_CUSTOMER_BILLING_PROFILE":"Kundenabrechnungsprofil speichern",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING":"Kunden in Abrechnung speichern",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FAILURE":"Kunden bei Abrechnungsfehler speichern",
    "EVENT_DESC_SAVE_CUSTOMER_IN_BILLING_FALURE":"Fehler beim Speichern des Kunden bei der Abrechnung",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE":"Kundenzahlungsprofil speichern",
    "EVENT_DESC_SAVE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Fehler beim Speichern des Kundenzahlungsprofils",
    "EVENT_DESC_SAVE_CUSTOMER_USER":"Kundenbenutzer speichern",
    "EVENT_DESC_SAVE_CUSTOMER_USER_FAILURE":"Speichern Sie Kundenfehler",
    "EVENT_DESC_SAVE_DEPARTMENT_FAILURE":"Abteilungsfehler speichern",
    "EVENT_DESC_SAVE_DEPARTMENT_SUCCESS":"Abteilungserfolg speichern",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER":"Händlerangebot speichern",
    "EVENT_DESC_SAVE_DISTRIBUTOR_OFFER_FAILURE":"Fehler beim Speichern des Händlerangebots",
    "EVENT_DESC_SAVE_ENTITLEMENT_PRICE":"Sparen Anspruch Preis Erfolg",
    "EVENT_DESC_SAVE_ENTITLEMENT_PRICE_FAILURE":"Fehler beim Speichern des Anspruchspreises",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung speichern",
    "EVENT_DESC_SAVE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Speichern der E-Mail-Benachrichtigung des Ereignisses",
    "EVENT_DESC_SAVE_INVITES":"Einladungen speichern",
    "EVENT_DESC_SAVE_INVITES_FAILURE":"Speichern lädt Fehler ein",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS":"Rechnungsanpassungen speichern",
    "EVENT_DESC_SAVE_INVOICE_ADJUSTMENTS_FAILURE":"Fehler beim Speichern von Rechnungsanpassungen",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE":"Partnerpaket speichern",
    "EVENT_DESC_SAVE_PARTNER_BUNDLE_FAILURE":"Save Partner Bundle Failure",
    "EVENT_DESC_SAVE_PARTNER_USER":"Partnerbenutzer speichern",
    "EVENT_DESC_SAVE_PARTNER_USER_FAILURE":"Partner-Benutzerfehler speichern",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE":"Zahlungsprofil speichern",
    "EVENT_DESC_SAVE_PAYMENT_PROFILE_FAILURE":"Zahlungsprofil konnte nicht gespeichert werden",
    "EVENT_DESC_SAVE_PLAN":"Plan speichern",
    "EVENT_DESC_SAVE_PLAN_FAILURE":"Planfehler speichern",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Planprodukte in Zielwährung speichern",
    "EVENT_DESC_SAVE_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Speichern Sie Planprodukte in Zielwährungsfehler",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT":"Einwilligung des gespeicherten Anbieterkunden",
    "EVENT_DESC_SAVE_PROVIDER_CUSTOMER_CONSENT_FAILED":"Fehler beim Speichern der Kundeneinwilligung des Anbieters",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT":"Provider-Zahlung speichern",
    "EVENT_DESC_SAVE_PROVIDER_PAYMENT_FAILURE":"Zahlungsfehler des Anbieters speichern",
    "EVENT_DESC_SAVE_QUOTE_FAILED":"Angebot konnte nicht gespeichert werden",
    "EVENT_DESC_SAVE_QUOTE_SUCCESS":"Angebot erfolgreich gespeichert",
    "EVENT_DESC_SAVE_RESELLER":"Reseller speichern",
    "EVENT_DESC_SAVE_RESELLER_FAILURE":"Reseller konnte nicht gespeichert werden",
    "EVENT_DESC_SAVE_SITE_FAILURE":"Site-Fehler speichern",
    "EVENT_DESC_SAVE_SITE_SUCCESS":"Speichern Sie den Site-Erfolg",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_FAILURE":"Fehler beim Speichern der Benutzerkonfiguration",
    "EVENT_DESC_SAVE_USER_CONFIGURATION_SUCCESS":"Erfolgreiche Benutzerkonfiguration speichern",
    "EVENT_DESC_SAVE_WEB_MESSAGE_NOTIFICATION":"Webnachrichtenbenachrichtigung speichern",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_FAILURE":"Fehler beim Speichern der Webhook-Benachrichtigung",
    "EVENT_DESC_SAVE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Erfolgreiche Webhook-Benachrichtigung speichern",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_FAILED":"Die Annahme der Allgemeinen Geschäftsbedingungen ist fehlgeschlagen",
    "EVENT_DESC_SAVED_TERMS_AND_CONDITIONS_SUCCESS":"Allgemeine Geschäftsbedingungen erfolgreich akzeptiert",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_FAILED":"Der Versuch, die hinzuzufügenden/zu entfernenden Lizenzen bereitzustellen, ist fehlgeschlagen.",
    "EVENT_DESC_SAVING_MANAGE_USER_LICENSES_STAGING_SUCCESS":"Die Lizenzen, die hinzugefügt/entfernt werden sollen, wurden erfolgreich bereitgestellt.",
    "EVENT_DESC_SCHEDULE_ORDER":"Reihenfolge einplanen",
    "EVENT_DESC_SCHEDULE_ORDER_FAILED":"Der Zeitplanauftrag ist fehlgeschlagen",
    "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Bericht zu geplanten Rechnungspositionen",
    "EVENT_DESC_SCHEDULED_INVOICE_LINE_ITEM_WITH_COMMISSION_REPORT":"Geplante Rechnungspositionen mit Provisionsbericht",
    "EVENT_DESC_SCHEDULED_INVOICE_SUMMARY_REPORT":"Geplanter Rechnungszusammenfassungsbericht",
    "EVENT_DESC_SCHEDULED_PROFITABLITY_REPORT":"Geplanter Rentabilitätsbericht",
    "EVENT_DESC_SCHEDULED_TAX_LINE_ITEMS_REPORT":"Bericht zu geplanten Steuerpositionen",
    "EVENT_DESC_SCHEDULED_TAX_SUMMARY_REPORT":"Geplanter Steuerzusammenfassungsbericht",
    "EVENT_DESC_SEND_DISTRIBUTOR_OFFER_UPDATE":"Aktualisierung des Händlerangebots senden",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_FROM_UI":"Senden Sie die Rechnung im PDF-Format über die Benutzeroberfläche an den Kunden",
    "EVENT_DESC_SEND_INVOICE_PDF_TO_CUSTOMER_TRANSPARITY":"Senden Sie die Rechnung im PDF-Format transparent an den Kunden",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL":"Senden Sie Warnungen zu Nutzungsschwellenwerten für Abonnements",
    "EVENT_DESC_SEND_SUBSCRIPTION_UTILIZATION_THRESHOLD_ALERT_EMAIL_FAILURE":"Schwellenwertwarnungen für Abonnementnutzung fehlgeschlagen",
    "EVENT_DESC_SET_USER_LICENSE_ASSIGNMENT_BATCH_STATUS_TO_COMPLETE":"Setzen Sie den Stapelstatus der Benutzerlizenzzuweisung auf \\''Abschließen\\''",
    "EVENT_DESC_SMTP_CONNECTIVITY_FAILED":"SMTP-Verbindung fehlgeschlagen",
    "EVENT_DESC_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Benachrichtigung über den Ablauf des Abonnements für das Testangebot",
    "EVENT_DESC_SUBSCRIPTION_HISTORY_REPORT":"Bericht zum Abonnementverlauf",
    "EVENT_DESC_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_ALERT_EMAIL":"Aussetzen, wenn die Gefahrenschwelle erreicht ist",
    "EVENT_DESC_SUBSCRIPTION_SYNC":"Abonnementsynchronisierung",
    "EVENT_DESC_SUBSCRIPTION_SYNC_FAILURE":"Abonnement-Synchronisierungsfehler",
    "EVENT_DESC_SUBSCRIPTION_UPDATED":"Abonnement aktualisieren",
    "EVENT_DESC_SUCCESS":"Erfolg",
    "EVENT_DESC_SUCCESSFULLY_APPROVED_OR_REJECTED_NEXT_SCHEDULED_RENEWAL_REQUEST":"Der nächste geplante Verlängerungsantrag wurde erfolgreich genehmigt oder abgelehnt",
    "EVENT_DESC_SUCCESSFULLY_CLONED_RESELLER_PLAN":"Reseller-Plan erfolgreich geklont",
    "EVENT_DESC_SUCCESSFULLY_CREATED_ONDEMAND_INVOICE":"On-Demand-Rechnung erfolgreich erstellt",
    "EVENT_DESC_SUCCESSFULLY_SYNCED_PROVIDER_CUSTOMER_PROFILE":"Anbieter-Kundenprofil erfolgreich synchronisiert",
    "EVENT_DESC_SUCCESSFULLY_UPDATED_ONDEMAND_INVOICE":"On-Demand-Rechnung erfolgreich aktualisiert",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_FAILURE":"Fehler beim Anhalten des Abonnements",
    "EVENT_DESC_SUSPEND_SUBSCRIPTION_SUCCESS":"Abonnement erfolgreich aussetzen",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET":"Aussetzen, wenn die Gefahrenschwelle erreicht ist",
    "EVENT_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_FAILURE":"Unterbrechen, wenn der Gefahrenschwellenwert erreicht ist. Fehler",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_FAILED":"Die Zustimmung des Synchronisierungsanbieters konnte nicht synchronisiert werden",
    "EVENT_DESC_SYNC_PROVIDER_CUSTOMER_CONSENT_SUCCESS":"Zustimmung des synchronisierten Anbieterkunden",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_FAILURE":"Abonnements nach PC-Fehler synchronisieren",
    "EVENT_DESC_SYNCHRONIZE_SUBSCRIPTIONS_FROM_PC_SUCCEEDED":"Abonnements vom PC synchronisieren erfolgreich",
    "EVENT_DESC_TAGS_ADDED":"Tags hinzugefügt",
    "EVENT_DESC_TAGS_ADDED_FAILURE":"Tags Fehler hinzugefügt",
    "EVENT_DESC_TAGS_DELETE_DFAILURE":"Tags Gelöschter Fehler",
    "EVENT_DESC_TAGS_DELETED":"Tags gelöscht",
    "EVENT_DESC_TAGS_EDITED":"Tags bearbeitet",
    "EVENT_DESC_TAGS_EDITED_FAILURE":"Tags bearbeitet Fehler",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC":"Laufzeit erfolgreich geändert (Auto Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_AUTO_SYNC_FAILED":"Ändern der Laufzeit fehlgeschlagen (Auto Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC":"Vertragsdauer erfolgreich geändert (manuelle Sync)",
    "EVENT_DESC_TERM_DURATION_CHANGE_FROM_MANUAL_SYNC_FAILED":"Ändern der Laufzeit fehlgeschlagen (manuelle Sync)",
    "EVENT_DESC_UNSYNCED_PRODUCT_DETAILS":"Nicht synchronisierte Produktdetails",
    "EVENT_DESC_UPDATE_AZURE_GROUPS":"Aktualisieren Sie Azure-Gruppen",
    "EVENT_DESC_UPDATE_AZURE_GROUPS_FAILURE":"Aktualisieren Sie den Azure-Gruppenfehler",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement",
    "EVENT_DESC_UPDATE_AZURE_SUBSCRIPTION_FAILURE":"Aktualisieren Sie den Azure-Abonnementfehler",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER":"Vertragsangebot erfolgreich aktualisiert",
    "EVENT_DESC_UPDATE_CONTRACT_OFFER_FAILED":"Aktualisierung des Vertragsangebots fehlgeschlagen",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE":"Aktualisieren Sie den benutzerdefinierten Abrechnungszeitraum",
    "EVENT_DESC_UPDATE_CUSTOM_BILLING_CYCLE_FAILED":"Aktualisieren des benutzerdefinierten Abrechnungszeitraums fehlgeschlagen",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_FAILED":"Fehler beim Aktualisieren der benutzerdefinierten Benachrichtigung",
    "EVENT_DESC_UPDATE_CUSTOM_NOTIFICATIONS_SUCCESS":"Benutzerdefinierte Benachrichtigung erfolgreich aktualisieren",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY":"Aktualisieren Sie die Angebotsmenge des Partners",
    "EVENT_DESC_UPDATE_CUSTOM_OFFER_QUANTITY_FAILURE":"Fehler bei der Aktualisierung der Partnerangebotsmenge",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION":"Die Standardzahlungsoption wurde aktualisiert",
    "EVENT_DESC_UPDATE_CUSTOMER_DEFAULT_PAYMENT_OPTION_FAILURE":"Fehler beim Aktualisieren der Standardzahlungsoption",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE":"Aktualisieren Sie das Kundenzahlungsprofil",
    "EVENT_DESC_UPDATE_CUSTOMER_PAYMENT_PROFILE_FAILURE":"Fehler beim Aktualisieren des Kundenzahlungsprofils",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE":"Kundenprofil aktualisiert",
    "EVENT_DESC_UPDATE_CUSTOMER_PROFILE_FAILURE":"Aktualisierung des Kundenprofils fehlgeschlagen",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION":"Aktualisieren Sie die Standardzahlungsoption",
    "EVENT_DESC_UPDATE_DEFAULT_PAYMENT_OPTION_FAILURE":"Fehler beim Aktualisieren der Standardzahlungsoption",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung aktualisieren",
    "EVENT_DESC_UPDATE_EVENT_EMAIL_NOTIFICATION_FAILURE":"Fehler beim Aktualisieren der Ereignis-E-Mail-Benachrichtigung",
    "EVENT_DESC_UPDATE_GROUP_ADMIN":"Gruppenadministrator aktualisieren",
    "EVENT_DESC_UPDATE_GROUP_ADMIN_FAILURE":"Update Group Admin Failure",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_FAILURE":"Fehler bei aktualisierten Rechnungseigenschaften",
    "EVENT_DESC_UPDATE_INVOICE_PROPERTES_SUCCESS":"Rechnungseigenschaften erfolgreich aktualisiert",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS":"Aktualisieren Sie das Auto-Release-Flag",
    "EVENT_DESC_UPDATE_ISAVAILABLE_FOR_AUTORELEAS_FAILED":"Flag für automatische Freigabe aktualisieren fehlgeschlagen",
    "EVENT_DESC_UPDATE_LICENSE":"Lizenz aktualisieren",
    "EVENT_DESC_UPDATE_LICENSE_FAILURE":"Lizenzfehler aktualisieren",
    "EVENT_DESC_UPDATE_PARTNER_CENTER_SETTINGSF_AILURE":"Fehler beim Aktualisieren der Partnercenter-Einstellungen",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS":"Aktualisieren Sie die allgemeinen Partnereinstellungen",
    "EVENT_DESC_UPDATE_PARTNER_GENERAL_SETTINGS_FAILURE":"Fehler beim Aktualisieren der allgemeinen Einstellungen des Partners",
    "EVENT_DESC_UPDATE_PARTNER_LOGO":"Partnerlogo aktualisieren",
    "EVENT_DESC_UPDATE_PARTNER_LOGO_FAILURE":"Fehler beim Aktualisieren des Partnerlogos",
    "EVENT_DESC_UPDATE_PARTNERCENTER_SETTINGS":"Aktualisieren Sie die Partner Center-Einstellungen",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE":"Plan Angebotspreis aktualisieren",
    "EVENT_DESC_UPDATE_PLAN_OFFER_PRICE_FAILURE":"Aktualisieren des Planangebotspreises",
    "EVENT_DESC_UPDATE_PROFILE":"Profil aktualisieren",
    "EVENT_DESC_UPDATE_PROFILE_FAILURE":"Profilfehler aktualisieren",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS":"Anbietereinstellungen aktualisieren",
    "EVENT_DESC_UPDATE_PROVIDER_SETTINGS_FAILURE":"Fehler beim Aktualisieren der Anbietereinstellungen",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME":"Produktattribute aktualisiert",
    "EVENT_DESC_UPDATE_PURCHASED_PRODUCT_NAME_FAILURE":"Produktattribute konnten nicht aktualisiert werden",
    "EVENT_DESC_UPDATE_QUANTITY_NI_PROVISIONING_PRODUCT":"Aktualisieren Sie das NI-Bereitstellungsprodukt",
    "EVENT_DESC_UPDATE_QUOTE_FAILED":"Angebot konnte nicht aktualisiert werden",
    "EVENT_DESC_UPDATE_QUOTE_SUCCESS":"Angebot erfolgreich aktualisiert",
    "EVENT_DESC_UPDATE_SCHEDULED_RENEWAL":"Geplante Erneuerung erfolgreich aktualisieren",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Der Standardwert für den Kunden des Dienstanbieters wurde aktualisiert",
    "EVENT_DESC_UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Fehler beim Aktualisieren des Standardwerts des Service Provider-Kunden",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS":"Aktualisieren Sie die SMTP-Einstellungen",
    "EVENT_DESC_UPDATE_SMTP_SETTINGS_FAILURE":"Fehler beim Aktualisieren der SMTP-Einstellungen",
    "EVENT_DESC_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK":"Überprüfung des Ablaufs des Update-Abonnements",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_EXPIRY_CHECK_FAILED":"Überprüfung des Ablaufdatums des Update-Abonnements fehlgeschlagen",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_FAILURE":"Abonnementfehler aktualisieren",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API":"API-Update-Abonnement",
    "EVENT_DESC_UPDATE_SUBSCRIPTION_THROUGH_API_FAILED":"Fehler beim API-Aktualisierungsabonnement",
    "EVENT_DESC_UPDATE_SUBTAX":"Subtax aktualisieren",
    "EVENT_DESC_UPDATE_SUBTAX_FAILED":"Subtax aktualisieren fehlgeschlagen",
    "EVENT_DESC_UPDATE_TAX_RULE":"Steuerregel aktualisieren",
    "EVENT_DESC_UPDATE_TAX_RULE_FAILURE":"Steuerregelfehler aktualisieren",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION":"Aktualisieren Sie die Mandantenkonfiguration",
    "EVENT_DESC_UPDATE_TENANT_CONFIGURATION_FAILURE":"Update TenantConfiguration Failure",
    "EVENT_DESC_UPDATE_USAGE_SUBSCRIPTION_USER":"Benutzer des Nutzungsabonnements aktualisieren",
    "EVENT_DESC_UPDATE_USAGESUBSCRIPTION_USER_FAILURE":"Benutzerfehler beim Aktualisieren des Nutzungsabonnements",
    "EVENT_DESC_UPDATE_USER":"Benutzer aktualisieren",
    "EVENT_DESC_UPDATE_USER_FAILURE":"Benutzerfehler aktualisieren",
    "EVENT_DESC_UPDATE_WEB_MESSAGE_NOTIFICATION":"Aktualisieren Sie die WebMessage-Benachrichtigung",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_FAILED":"Fehler bei der Aktualisierung der Webhook-Benachrichtigung",
    "EVENT_DESC_UPDATE_WEBHOOK_NOTIFICATIONS_SUCCESS":"Webhook-Benachrichtigung erfolgreich aktualisieren",
    "EVENT_DESC_UPGRADE_AZURESUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_FAILED":"Gutscheinzuweisung fehlgeschlagen",
    "EVENT_DESC_VOUCHER_ASSIGNMENT_SUCCESS":"Gutschein erfolgreich zugewiesen",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_FAILURES":"Die Massenproduktzuordnung ist fehlgeschlagen",
    "EVENT_DESCRIPTION_BULK_PRODUCT_MAPPING_SUCCESS":"Die Massenproduktzuordnung war erfolgreich",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL":"Der Aktualisierungsprozess für externe Serviceeinheiten in C3 wird eingeleitet",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_FAILURES":" Der Aktualisierungsprozess für externe Serviceentitäten in C3 ist fehlgeschlagen",
    "EVENT_DESCRIPTION_REFRESH_EXTERNAL_SERVICE_ENTITY_IN_LOCAL_SUCCESS":" Der Aktualisierungsprozess für externe Serviceeinheiten in C3 war erfolgreich",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE":"Abonnement-Upgrade",
    "EVENT_DESCRIPTION_SUBSCRIPTION_UPGRADE_FAILED":"Abonnement-Upgrade fehlgeschlagen",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_FAILURE":"Der Kundenkatalog konnte nicht heruntergeladen werden.",
    "EVENT_DOWNLOAD_CUSTOMER_AND_RESELLER_ENTITY_CATALOGUE_SUCCESS":"Kundenkatalog erfolgreich heruntergeladen.",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODES_SUCCESS":"Download Produktkatalog Erfolg",
    "EVENT_DOWNLOAD_PRODUCT_CATALOGUE_FOR_CODESFAILURE":"Fehler beim Herunterladen des Produktkatalogs",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_FAILURE":"Fehler im Produktprovisionskatalog herunterladen",
    "EVENT_DOWNLOAD_PRODUCT_COMMISSIONS_CATALOGUE_SUCCESS":"Laden Sie den Produktprovisionskatalog herunter Erfolg",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_FAILURE":"Fehler beim Herunterladen des Berichtsnutzungskatalogs",
    "EVENT_DOWNLOAD_REPORT_USAGE_CATALOGUE_SUCCESS":"Laden Sie den Berichtsnutzungskatalog erfolgreich herunter",
    "EVENT_ENTITLEMENT_ID":"Berechtigungs-ID",
    "EVENT_ENTITLEMENT_NAME":"Entitätsname",
    "EVENT_FILE_NAME":"Dateinamen",
    "EVENT_IMPERSONATOR":"Imitator",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_FAILURE":"Fehler beim Importieren mehrerer Zahlungen",
    "EVENT_IMPORT_MULTIPLE_PAYMENTS_RECORDS_SUCCESS":"Importieren Sie den Erfolg mehrerer Zahlungen",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Validierte Daten konnten nicht importiert werden",
    "EVENT_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Validierte Daten erfolgreich importiert",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_FAILURE":"Fehler beim Importieren von Produktprovisionen",
    "EVENT_IMPORT_PRODUCT_COMMISSIONS_SUCCESS":"Importieren Sie Produktprovisionen erfolgreich",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_FAILURE":"Fehler beim Importieren von Produkterweiterungen",
    "EVENT_IMPORT_PRODUCT_EXTENSIONS_SUCCESS":"Produkterweiterungen erfolgreich importieren",
    "EVENT_IMPORT_VOUCHER_FAILURE":"Die Gutscheine konnten nicht importiert werden.",
    "EVENT_IMPORT_VOUCHER_SUCCESS":" Die Gutscheine wurden erfolgreich importiert.",
    "EVENT_IS_LICENSE_ASSIGNED":"Lizenz zugewiesen ist",
    "EVENT_IS_LICENSE_REMOVED":"Ist die Lizenz entfernt",
    "EVENT_LINK_RESELLER_TO_PROVIDER":"Link Reseller",
    "EVENT_LINK_RESELLER_TO_PROVIDER_FAILURE":"Reseller konnte nicht verknüpft werden",
    "EVENT_NAME_PSA_ENTITY_MAPPING_FAILED":"PSA-Entitätszuordnung ist fehlgeschlagen",
    "EVENT_NAME_PSA_ENTITY_MAPPING_SUCCESS":"C3-Entität erfolgreich einer Entität in PSA zugeordnet",
    "EVENT_NAME_PSA_ENTITY_UN_MAPPING_FAILED":"Das Entfernen der Zuordnung mit der PSA-Entität ist fehlgeschlagen",
    "EVENT_NAME_PSA_ENTITY_UN_MAPPING_SUCCESS":"Zuordnung zur PSA-Entität erfolgreich entfernt",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_FAILED":"PSA-Produktzuordnung fehlgeschlagen",
    "EVENT_NAME_PSA_PRODUCT_MAPPING_SUCCESS":"Zuordnung von C3-Produkten zu Produkten in PSA erfolgreich",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_FAILED":"Entfernen der Zuordnung mit PSA-Entität fehlgeschlagen",
    "EVENT_NAME_PSA_PRODUCT_UN_MAPPING_SUCCESS":"Zuordnung mit PSA-Entität erfolgreich entfernt",
    "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING":"Zuordnung zum PSA-Produkt erfolgreich entfernt",
    "EVENT_NAME_PSA_PRODUCT_VARIENT_UNMAPPING_FAILED":"Das Entfernen der Zuordnung mit dem PSA-Produkt ist fehlgeschlagen",
    "EVENT_NEW_CAN_PRICE_LAG":"NeuDosePreisVerzögerung",
    "EVENT_NEW_CAN_PRICE_LEAD":"NeuDosePreisBlei",
    "EVENT_NEW_PLAN_LEVEL_SEAT_LIMIT":"Neues Sitzplatzlimit auf Planebene",
    "EVENT_NEXT_RUN_ENQUE_ERROR_NOTIFIER":"Führen Sie als Nächstes den Enque-Fehlermelder aus",
    "EVENT_NOTIFICATION_ATLEAST_ONE_VALIDATION_MESSAGE":"Bitte wählen Sie mindestens einen Empfängertyp aus.",
    "EVENT_NOTIFICATION_BUTTON_TEXT_PREVIEW_EMAIL":"Vorschau der E-Mail",
    "EVENT_NOTIFICATION_DELETE_ALERT_MESSAGE":"E-Mail-Benachrichtigung erfolgreich gelöscht.",
    "EVENT_NOTIFICATION_LABLE_TEXT_BCC":"BCC",
    "EVENT_NOTIFICATION_LABLE_TEXT_CC":"CC",
    "EVENT_NOTIFICATION_LABLE_TEXT_ENABLED":"aktiviert",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENT_TYPE":"Empfängertyp",
    "EVENT_NOTIFICATION_LABLE_TEXT_RECIPIENTS":"Empfänger",
    "EVENT_NOTIFICATION_LABLE_TEXT_TO":"Zu",
    "EVENT_NOTIFICATION_SUCCESS_ALERT_MESSAGE":"E-Mail-Benachrichtigungseinstellungen erfolgreich gespeichert.",
    "EVENT_NOTIFICATION_TEXT_PLEASE_CLICK":"Bitte klicken Sie auf die",
    "EVENT_NOTIFICATION_TO_VIEW_PREVIEW_TEMPLATE":"um den Inhalt der E-Mail-Vorlage anzuzeigen.",
    "EVENT_NOTIFICATIONS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_BCC_RECIPIENTS":"BCC-Empfänger",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_CC_RECIPIENTS":"CC-Empfänger",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT":"Veranstaltung",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_EVENT_NAME":"Veranstaltungsname",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_IS_ENABLED":"Aktiviert?",
    "EVENT_NOTIFICATIONS_LIST_TABLE_HEADER_TO_RECIPIENTS":"An die Empfänger",
    "EVENT_NOTIFY_CUSTOMER_CREATION_THROUGH_SIGNUP_TO_CUSTOMER":"Benachrichtigen Sie die Kundenerstellung durch die Anmeldung beim Kunden",
    "EVENT_NOTIFY_PURCHASE_LIMIT_ERROR":"Benachrichtigung über Kauflimitfehler",
    "EVENT_Notify_Purchase_Limit_Error":"Fehler beim Kauflimit benachrichtigen",
    "EVENT_OLD_CAN_PRICE_LAG":"OldCanPriceLag",
    "EVENT_OLD_CAN_PRICE_LEAD":"AltDosePreisBlei",
    "EVENT_OLD_PLAN_LEVEL_SEAT_LIMIT":"Sitzplatzbegrenzung auf alter Planebene",
    "EVENT_PRODUCT_NAME":"Produktname",
    "EVENT_PURCHASE_APPROVE_REASON":"Grund",
    "EVENT_PURCHASE_PRODUCT_ERROR_REASON":"Grund, falls fehlgeschlagen",
    "EVENT_PURCHASE_PRODUCT_ID":"Produkt ID",
    "EVENT_PURCHASE_PRODUCT_NAME":"Produktname",
    "EVENT_PURCHASE_PRODUCT_PLAN_PRODUCT_ID":"Planprodukt-ID",
    "EVENT_PURCHASE_PRODUCT_QUANTITY":"Menge",
    "EVENT_PURCHASE_REASON_ONHOLD":"Grund fürs Anhalten",
    "EVENT_SEND_INVOICES_PDF_ZIP_TO_PARTNER":"Senden Sie Rechnungen im PDF-Format an den Partner",
    "EVENT_SITE_DESCRIPTION":"Seitenbeschreibung",
    "EVENT_SITE_ID":"Webseitenadresse",
    "EVENT_SITE_NAME":"Seite-Name",
    "EVENT_SMTP_TEST_EMAIL":"SMTP-Test-E-Mail",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_FAILURE":"Fehler bei Importzahlungen erkennen und validieren",
    "EVENT_STAGE_AND_VALIDATE_MULTIPLE_PAYMENT_RECORDS_SUCCESS":"Inszenieren und validieren Sie den Erfolg von Importzahlungen",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_FAILURE":"Fehler beim Bereitstellen und Validieren des Partnernutzungsberichts",
    "EVENT_STAGE_AND_VALIDATE_PARTNER_OFFER_DAILY_USAGE_DATA_SUCCESS":"Bereitstellen und Validieren des Partnernutzungsberichts",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_FAILURE":"Produktkatalogfehler inszenieren und validieren",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_CATALOGUE_SUCCESS":"Inszenieren und validieren Sie den Erfolg des Produktkatalogs",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_FAILURE":"Stage und validieren Sie das Scheitern von Produktprovisionen",
    "EVENT_STAGE_AND_VALIDATE_PRODUCT_COMMISSIONS_SUCCESS":"Inszenieren und validieren Sie den Erfolg von Produktprovisionen",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_FAILURE":"Gutscheinkatalog konnte nicht bereitgestellt und validiert werden.",
    "EVENT_STAGE_AND_VALIDATE_VOUCHER_CATALOGUE_SUCCESS":"Erfolgreich erstellter und validierter Gutscheinkatalog.",
    "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION":"Benachrichtigung über den Ablauf des Abonnements",
    "EVENT_SUBCRIPTION_EXPIRATION_NOTIFICATION_CUSTOMER":"Benachrichtigung für Kunden über Ablauf des Abonnements",
    "EVENT_SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_TRIAL":"Benachrichtigung über den Ablauf des Abonnements für das Testangebot",
    "EVENT_TOTAL_RECORD_COUNT":"Rekordzahl",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER":"Reslink zum Reseller aufheben",
    "EVENT_UNLINK_RESELLER_FROM_PROVIDER_FAILURE":"Reseller konnte nicht freigegeben werden",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT":"Produktdetails zur Partnernutzung aktualisiert",
    "EVENT_UPDATE_PARTNER_USAGE_PURCHASED_PRODUCT_FAILURE":"Produktdetails zur Partnernutzung konnten nicht aktualisiert werden",
    "EVENT_UPDATE_PLAN_OFFER_NOTIFICATION_FOR_RESELLER":"Benachrichtigung zum Aktualisierungsplanangebot für Wiederverkäufer",
    "EVENT_USER_CONFIGURATION_NAME":"Benutzerkonfigurationsname",
    "EVENT_USER_CONFIGURATION_VALUE":"Benutzerkonfigurationswert",
    "EVENT_USER_EMAILID ":"Benutzer-E-Mail-ID",
    "EVENT_VOUCHER_ASSIGNMENT":"Gutscheinzuordnung",
    "EVENT_WEBJOB_STATUS_NOTIFIER":"Web-Job-Statusbenachrichtigung",
    "EVNET_DATA_NEW_BUNDLE_DESCRIPTION":"Neue Bündel-Beschreibung",
    "EVNET_DATA_NEW_BUNDLE_NAME":"Neue Bündel-name",
    "EVNET_DATA_OLD_BUNDLE_DESCRIPTION":"Alte Bündelbeschreibung",
    "EVNET_DATA_OLD_BUNDLE_NAME":"Alter Bündelname",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_ACCEPTED":"Bulk-Onboard-Reseller-Anfrage akzeptiert",
    "EVNT_DESC_BULK_ONBOARD_RESELLERS_REQUEST_FAILED":"Akzeptieren der Bulk-Onboard-Reseller-Anfrage fehlgeschlagen",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_FAILED":"Das Onboarding des Wiederverkäufers im Rahmen des Massen-Onboardings ist fehlgeschlagen",
    "EVNT_DESC_ONBOARD_RESELLER_UNDER_BULK_ONBOARD_SUCCESS":"Das Onboarding des Wiederverkäufers im Rahmen des Massen-Onboardings wurde erfolgreich abgeschlossen",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_FAILED":"Das Massen-Onboarding des Wiederverkäufers ist fehlgeschlagen",
    "EVNT_DESC_ONBOARD_RESELLERS_UNDER_BULK_ONBOARD_SUCCESS":"Massen-Onboarding für Wiederverkäufer erfolgreich",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_COMPLETED":"Bulk-Add-Berechtigungen abgeschlossen",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_FAILED":"Bulk-Hinzufügen von Berechtigungen fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_ACCEPTED":"Anfrage zum Massenhinzufügen von Berechtigungen akzeptiert",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_ENTITLEMENT_REQUEST_FAILED":"Anforderung zum Massenhinzufügen von Berechtigungen fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_COMPLETED":"Prozess zum Massenhinzufügen von Mandanten abgeschlossen",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_FAILED":"Prozess zum Massenhinzufügen von Mandanten fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_ACCEPTED":"Anfrage zum Massenhinzufügen von Mietern akzeptiert",
    "EVNT_DESC_PROCESS_FOR_BULK_ADD_TENANT_REQUEST_FAILED":"Anfrage zum Massenhinzufügen von Mandanten fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_COMPLETED":"Prozess für das Massen-Onboarding von Resellern abgeschlossen",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_FAILED":"Der Prozess für das Bulk-Onboarding von Resellern ist fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_BULK_ONBOARD_OF_RESELLERS_INITIATE":"Prozess für Massen-Onboarding von Wiederverkäufern eingeleitet",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_COMPLETED":"Entfernen der Reseller-Beziehung abgeschlossen",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_FAILED":"Das Entfernen der Reseller-Beziehung ist fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_REQUEST_FAILED":"Die Anfrage zum Entfernen einer Reseller-Beziehung ist fehlgeschlagen",
    "EVNT_DESC_PROCESS_FOR_REMOVE_RESELLER_RELATION_TENANT_REQUEST_ACCEPTED":"Antrag auf Entfernung des Reseller-Beziehungsmieters angenommen",
    "EXCEPTION_DESC_RETRY_NOTIFICATION_SEND":"Beim erneuten Versuch, die Webhook-Benachrichtigung zu senden, ist ein Fehler aufgetreten",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_CUSTOM_NOTIFICATION_EXCEPTION":"Beim Speichern oder Aktualisieren benutzerdefinierter Benachrichtigungsdetails ist ein Fehler aufgetreten",
    "EXCEPTION_DESC_SAVE_OR_UPDATE_WEBHOOK_NOTIFICATION_EXCEPTION":"Fehler beim Speichern der Webhook-Benachrichtigungsdetails",
    "EXCEPTION_DESC_TRIAL_OFFER_CONVERSION_ERROR":"Beim Versuch, aus dem Testangebot einen Warenkorb für das Hauptangebot zu erstellen, ist ein Fehler aufgetreten.",
    "EXCEPTION_DESC_UPDATE_STAGED_DATA_NOTIFICATION_EXCEPTION":"Fehler beim Aktualisieren der bereitgestellten Daten für Benachrichtigungsdetails",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_ERROR_SENDING_NOTIFICATION":"Beim Senden der Webhook-Benachrichtigung ist ein Fehler aufgetreten",
    "EXCEPTION_DESC_WEBHOOK_NOTIFICATION_INVALID":"Die angegebene Webhook-URL ist ungültig",
    "EXCEPTION_NO_MAPPED_PRODUCTS_FOR_BILLING_CYCLE_CHANGE":"Es gibt keine zugeordneten Produkte für die Änderung des Abrechnungszyklus. Bitte wenden Sie sich an das Support-Team.",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_NOT_CONFIRMED_BY_PARTNER":"Beim Erstellen eines neuen Anbieterkunden ist eine Ausnahme aufgetreten, da die Annahme der Anbieterkundenvereinbarung vom Partner nicht bestätigt wird",
    "EXCEPTION_PROVIDER_CUSTOMER_AGREEMENT_ACCEPTANCE_SHOULD_BE_CONFIRMED":"Die Annahme der Anbieter-Kunden-Vereinbarung sollte bestätigt werden",
    "EXCEPTION_WHILE_CHECKING_HAS_USER_ACCEPTED_PARTNER_TERMS_AND_CONDITIONS":"Beim Überprüfen, ob der Benutzer die Allgemeinen Geschäftsbedingungen des Partners akzeptiert hat, ist ein Fehler aufgetreten",
    "EXCEPTION_WHILE_GETTING_PARTNER_TERMS_AND_CONDITIONS_CONTENT":"Beim Abrufen des Inhalts der Partnerbedingungen ist ein Fehler aufgetreten",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMER_CONSENT_ACCEPTANCE_DETAIL":"Beim Abrufen der Akzeptanzdetails für die Zustimmung des Anbieters ist ein Fehler aufgetreten",
    "EXCEPTION_WHILE_GETTING_PROVIDER_CUSTOMERS_WHO_NOT_PROVIDED_PROVIDER_CUSTOMER_CONSENT":"Beim Abrufen von Provider-Kunden, die keine Provider-Kunden-Zustimmung erteilt haben, ist ein Fehler aufgetreten",
    "EXCEPTION_WHILE_SAVING_PARTNER_TERMS_AND_CONDITIONS_ACCEPTANCE_DETAILS":"Beim Speichern der Akzeptanzdetails der Partnerbedingungen ist ein Fehler aufgetreten",
    "EXCEPTION_WHILE_SAVING_PROVIDER_CUSTOMER_CONSENT":"Beim Speichern der Zustimmung des Anbieterkunden ist ein Fehler aufgetreten",
    "EXISTING":"Bestehende",
    "EXISTING_REFRESH_TOKEN":"Vorhandenes Aktualisierungstoken",
    "EXPAND_ALL_PAYMENTS":"Erweitern Sie Zahlungen",
    "EXPIRATION_NOTIFICATION_DELETE_RECORD_CONFIRMATION_PROMPT":"Möchten Sie diesen Datensatz wirklich löschen?",
    "EXPIRATION_NOTIFICATION_DELETE_SUCCESS_MSG":"Die Ablaufprüfung wurde erfolgreich gelöscht",
    "EXPIRATION_NOTIFICATION_SAVE_SUCCESS_MSG":"Die Ablaufprüfungen wurden erfolgreich gespeichert",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CLOSE_FILTERS_BUTTON_TEXT":"Filter schließen",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_HEADER":"Kundenname",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT":"Wählen Sie Kunde oder Wiederverkäufer aus",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Suche nach Kunde",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_SEARCH_INPUT_PLACEHOLDER_TEXT_FOR_RESELLER":"Wählen Sie Kunde",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_DEFAULT_OPTION":"Wählen Sie einen Status aus",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_CUSTOMER_STATUS_INPUT_HEADER":"Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_RESET_BUTTON_TEXT":"Zurücksetzen",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SEARCH_BUTTON_TEXT":"Suche",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_STATUS_SEARCH_INPUT_PLACEHOLDER_TEXT_1":"Suche nach Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ABANDONED":"Verlassen",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_DEACTIVATED":"Deaktiviert",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_ERROR":"Error",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_FAILED":"Gescheitert",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_IN_PROGRESS":"In Bearbeitung",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_QUEUED":"In Warteschlange",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_STOPPED":"Gestoppt",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_SUCCESS":"Erfolg",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_SUMMARY_TABLE_CUSTOMER_STATUS_WARNING":"Warnung",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_BILLING_CYCLE_NAME":"Rechnungskreislauf",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_CUSTOMER_NAME":"Kunde",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_END_DATE":"Endtermin",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_ERROR_DETAILS":"Fehlerdetails",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_ERROR":"Exportfehler",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXPORT_STATUS":"Exportstatus",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_EXTERNAL_CUSTOMER_NAME":"Kundenname",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_LOG_MESSAGE":"Protokollnachricht",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_OPERATION_NAME":"Vorgangsname",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRICE":"Preis",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_PROVIDER_PRICE":"Anbieterpreis",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_QUANTITY":"Menge",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_START_DATE":"Anfangsdatum",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS":"Status",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_STATUS_COUNT":"Statuszählung",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_ACTION":"Aktion",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_SUBSCRIPTION_HISTORY_DATE":"Datum des Abonnementverlaufs",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATE_STATUS":"Validierungsstatus",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_HEADER_VALIDATION_ERROR":"Validierungsfehler",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_DEPARTMENT":"Abteilung",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_LABLE_TEXT_SITE":"Seite ",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_DETAIL":"Detail",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_PAGE_MODE_SUMMARY":"Zusammenfassung",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_FAILED":"Gescheitert",
    "EXTERNAL_ACCOUNTING_SERVICES_POST_LOGS_TABLE_SUMMARY_SUCCESS":"Erfolg",
    "EXTERNAL_DATA_SERVICE_LOCAL_ENTITY_REFRESH_TEXT":"Die lokale Datenaktualisierung wurde von einem externen Dienst initiiert",
    "EXTERNAL_PRODUCT_MAPPING_DROPDOWN_TEXT_DELETED":"Gelöscht",
    "EXTERNAL_SERVICE_FAILED_TO_FIND_ACTIVE_OFFER_IN_C3_CATALOGUE":"Angebot in C3 kann nicht gefunden werden",
    "EXTERNAL_SERVICE_FAILED_TO_FIND_DEFAULT_CONTRACT_IN_C3_CATALOGUE":"Es konnten keine Vertragsdetails lokal gefunden werden, um eine Zuordnung zu erstellen.",
    "EXTERNAL_SERVICE_IN_ACTIVE":"Inaktiv",
    "EXTERNAL_SERVICE_LOGS_STATUS_TITLE":"Post-Log-Status des externen Dienstes",
    "EXTERNAL_SERVICE_MESSAGE_ABANDONING_UPLOAD_DUE_TO_ERROR":"Abbruch des Uploads aufgrund eines Fehlers beim Erstellen von Entitäten",
    "EXTERNAL_SERVICE_MESSAGE_ADDITION_IS_IN_ACTIVE_IN_CONNECT_WISE":"Hinzu kommt aktives ConnectWise Manage",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_ID_IS_MISSING_FOR_EXPORT":"Vereinbarungs-ID fehlt",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_INVOICING_NOT_ENABLED_IN_CONNECT_WISE":"Die Rechnungsstellung ist für diese Vereinbarung in ConnectWise Manage nicht aktiviert",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_ACTIVE_IN_CONNECT_WISE":"Die zugeordnete Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht aktiv",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENT_IS_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Die Vereinbarung ist in ConnectWise Manage unter diesem Kunden nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Vereinbarungen werden für diesen Kunden nicht zugeordnet",
    "EXTERNAL_SERVICE_MESSAGE_AGREEMENTS_ARE_NOT_PRESENT_IN_CONNECT_WISE_UNDER_THIS_CUSTOMER":"Vereinbarungen sind in ConnectWise Manage unter diesem Kunden nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_AMBIGUOUS_ADDITION_FOR_MAPPING_PRESENT_IN_EXTERNAL_SERVICE":"Es liegen mehrdeutige Zusätze vor, eine Zuordnung ist nicht möglich.",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_ID_IS_MISSING_FOR_EXPORT":"Vertrags-ID fehlt",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_INVOICING_NOT_ENABLED_IN_AUTO_TASK":"Die Rechnungsstellung ist für diesen Vertrag in Autotask nicht aktiviert",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_ACTIVE_IN_AUTO_TASK":"Der zugeordnete Vertrag ist in Autotask unter diesem Kunden nicht aktiv",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Der Vertrag ist in Autotask unter diesem Kunden nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Bei diesem Kunden liegt kein Vertrag im Außendienst vor",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Vertragsservice wird diesem Kunden nicht zugeordnet",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICE_IS_NOT_PRESENT_IN_AUTO_TASK":"Der zugeordnete Vertragsdienst ist in Autotask nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACT_SERVICES_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Vertragsleistungen werden diesem Kunden nicht zugeordnet",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_CUSTOMER":"Für diesen Kunden werden keine Verträge abgebildet",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_AUTO_TASK_UNDER_THIS_CUSTOMER":"Unter diesem Kunden sind in Autotask keine Verträge vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_CONTRACTS_ARE_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Verträge sind im externen Service unter diesem Kunden nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_ACTIVE_IN_EXTERNAL_SERVICE":"Der Kunde ist im externen Service nicht aktiv",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_MAPPED_WITH_C3_CUSTOMER":"Externer Kunde ist nicht mit c3-Kunde verknüpft",
    "EXTERNAL_SERVICE_MESSAGE_CUSTOMER_IS_NOT_PRESENT_IN_EXTERNAL_SERVICE":"Kunde ist im externen Service nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_ENTITY_OR_PRODUCT_NOT_MAPPED":"Entweder fehlt die Entitäts- oder Produktzuordnung.",
    "EXTERNAL_SERVICE_MESSAGE_EXPORT_WAS_ALREADY_ATTEMPTED_FOR_THIS_RECORD":"Es wurde bereits ein Exportversuch unternommen. Der Status dieses Vorgangs ist",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CONTRACT_IS_NOT_ACTIVE":"Vertrag ist im externen Dienst nicht aktiv",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMER_ID_IS_MISSING_FOR_EXPORT":"Externe Kunden-ID fehlt",
    "EXTERNAL_SERVICE_MESSAGE_EXTERNAL_CUSTOMERS_NOT_AVAILABLE_IN_EXTERNAL_SERVICE":"Kunden sind im externen Dienst nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADDITION_IN_CONNECT_WISE":"Bei der Erstellung eines Zusatzes in ConnectWise Manage ist ein Fehler aufgetreten",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT":"Bei der Erstellung einer Anpassung ist ein Fehler aufgetreten:",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_ADJUSTMENT_FOR_PRICE_CHANGE":"Fehler beim Erstellen einer Anpassung für eine Preisänderung aufgetreten",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_AUTOTASK_SERVICE":"Fehler beim Erstellen einer Dienstleistung",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_CHARGE":"Fehler bei der Erstellung einer Vertragsgebühr für ein einmaliges Produkt",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_CREATE_CONTRACT_SERVICE":"Fehler beim Erstellen einer Vertragsdienstleistung :",
    "EXTERNAL_SERVICE_MESSAGE_FAILED_TO_UPDATE_ADDITION_IN_CONNECT_WISE":"Fehler beim Aktualisieren des Zusatzes in ConnectWise Manage aufgetreten",
    "EXTERNAL_SERVICE_MESSAGE_MANUAL_MAPPING_ERROR_MATCHED_WITH_MULTIPLE_SERVICES_IN_AUTOTASK":"Es konnte keine Zuordnung erstellt werden, da mehrere übereinstimmende Dienste in Autotask gefunden wurden",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_CONTRACTS_COULD_BE_FOUND":"Ein passender Vertrag ist für die automatische Zuordnung nicht verfügbar.",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_CUSTOMER_IN_EXTERNAL_SERVICE":"Es konnte kein passender externer Kunde gefunden werden",
    "EXTERNAL_SERVICE_MESSAGE_NO_MATCHING_EXTERNAL_PRODUCT_COULD_BE_FOUND":"Es konnte kein passendes externes Produkt gefunden werden",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Das Produkt ist nicht unter diesem Kunden zugeordnet",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCT_IS_NOT_PRESENT_IN_CONNECT_WISE":"Das zugeordnete Produkt ist in ConnectWise Manage nicht vorhanden",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_ARE_NOT_MAPPED_UNDER_THIS_CUSTOMER":"Produkte sind unter diesem Kunden nicht zugeordnet",
    "EXTERNAL_SERVICE_MESSAGE_PRODUCTS_DO_NOT_HAVE_MAPPED_SERVICE":"C3 Produkte haben keinen zugeordneten externen Dienst",
    "EXTERNAL_SERVICE_MESSAGE_SERVICE_ID_IS_MISSING_FOR_EXPORT":"Service-ID fehlt",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_CANCELLATION_DATE":"Das Erstellungsdatum des Abonnementverlaufs darf nicht nach dem Datum der Vertragsauflösung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_AGREEMENT_END_DATE":"Das Erstellungsdatum des Abonnementverlaufs darf nicht nach dem Enddatum der Vereinbarung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_CANCELLATION_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht nach dem Datum der Vertragskündigung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_AFTER_CONTRACT_END_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht nach dem Vertragsenddatum liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_BILLING_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Startdatum der Vertragsabrechnung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_AGREEMENT_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Startdatum der Vereinbarung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_BILLING_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Beginn der Vertragsabrechnung liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_CONTRACT_START_DATE":"Das Erstellungsdatum der Abo-Historie darf nicht vor dem Vertragsstartdatum liegen.",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_IN_ACTIVE_IN_AUTO_TASK":"Abonnement ist in aktiver Autotask",
    "EXTERNAL_SERVICE_MESSAGE_SUBSCRIPTION_IS_INACTIVE_IN_EXTERNAL_SERVICE":"Das Abonnement ist im externen Service inaktiv",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING":"Automatisches Mapping kann nicht abgeschlossen werden, da mehrere passende Verträge gefunden wurden",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_CUSTOMER_MATCH":"Automatisches Mapping kann nicht abgeschlossen werden, da mehrere externe Kunden gefunden wurden",
    "EXTERNAL_SERVICE_MESSAGE_UNABLE_TO_COMPLETE_AUTOMATIC_MAPPING_WHEN_MULTIPLE_DUPLICATE_PRODUCTS":"Das automatische Mapping kann nicht abgeschlossen werden, da mehrere externe Produkte gefunden wurden.",
    "EXTERNAL_SERVICE_MESSAGE_VALIDATION_WAS_NOT_SUCCESSFUL_FOR_THIS_RECORD":"Validierung ist nicht erfolgreich",
    "EXTERNAL_SERVICE_NAME":"Name des externen Dienstes",
    "EXTERNAL_SERVICE_PORTLET_TITLE_SELECT_CUSTOMERS_TO_VALIDATE_AND_UPLOAD":"Wählen Sie Kunden zum Validieren und Hochladen",
    "EXTERNAL_SERVICE_POST_BATCH_ID":"Externe Service-Post-Batch-ID",
    "EXTERNAL_SERVICE_POST_LOG_BTN_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "EXTERNAL_SERVICE_POST_LOG_ERROR_IGNORED_SUCCESS_MESSAGE":"Fehler erfolgreich ignoriert",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_EXPORT_TEXT":"Export",
    "EXTERNAL_SERVICE_POST_LOG_OPERATION_NAME_VALIDITION_TEXT":"Validierung",
    "EXTERNAL_SERVICE_POST_LOGS_STATUS":"Externer Dienst Post protokolliert Status",
    "EXTERNAL_SERVICE_POST_LOGS_VIEW_BREADCRUM_HEADER_EXTERNAL_SERVICE_POST_LOGS":"Postprotokolle des externen Dienstes",
    "EXTERNAL_SERVICE_PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Konnektivität testen",
    "EXTERNAL_SERVICE_PSA_HEADER_TEXT_INSTRUCTIONS":"Anleitung",
    "EXTERNAL_SERVICE_PSA_TEST_CONNECTIVITY_INSTRUCTIONS":"Klicken Sie bitte auf diese Schaltfläche, um die Verbindung zu PSA nach Abschluss der Konfiguration zu testen.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_ABLE_TO_ESTABLISH_CONNECTIVITY":"Die Verbindung wurde erfolgreich hergestellt. Sie können jetzt mit dem Hochladen der Abonnementprotokolle fortfahren.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_PLEASE_SELECT_ATLEAST_ONE_CUSTOMER":"Bitte wählen Sie mindestens einen Kunden aus",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_AUTOTASK":"Die Verbindung zu Datto Autotask konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Konfigurationen auf der Einstellungsseite erneut.",
    "EXTERNAL_SERVICES_ERROR_MESSAGE_UNABLE_TO_ESTABLISH_CONNECTIVITY_TO_CONNECTWISE":"Die Verbindung zu ConnectWise Manage ist fehlgeschlagen. Bitte überprüfen Sie noch einmal Ihre Konfigurationen auf der Einstellungsseite",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_LESSTHAN_STARTDATE":"Das Enddatum sollte größer als das Startdatum sein",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_END_DATE_IS_REQUIRED":"Bitte wählen Sie das Enddatum",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_CANNOT_BE_GREATER_THAN_ENDDATE":"Das Startdatum darf nicht größer als das Enddatum sein",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_START_DATE_IS_REQUIRED":"Bitte wählen Sie das Startdatum",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_UPLOAD_HAS_COMMENCED":"Ihre Daten werden gerade hochgeladen. Sie können diese Seite für weitere Aktualisierungen erneut besuchen",
    "EXTERNAL_SERVICES_NOTIFICATION_TEXT_VALIDATION_HAS_COMMENCED":"Die Validierung hat begonnen",
    "EZIDEBIT_CREDIT_CARD_DISHONOUR_FEE_TEXT_NOTE":"Hinweis :",
    "EZIDEBIT_ERROR_CODE_ACCESS_DENIED_DUE_TO_INCORRECT_DEGITIAL_KEY":"Der digitale Schlüssel ist falsch oder verweigert den Zugriff auf diese Funktion. Die Validierung des digitalen Schlüssels ist fehlgeschlagen. Überprüfen Sie, ob Sie den richtigen digitalen Schlüssel verwenden.",
    "EZIDEBIT_ERROR_CODE_ALREADY_EXISTING_CUSTOMER_WITH_UPLOADED_REF":"Es gibt bereits einen Kunden mit der von Ihnen angegebenen Upload-Referenz.",
    "EZIDEBIT_ERROR_CODE_ALREADY_REFUNDS_PRESENT":"Erstattung nicht zulässig. Für diese Transaktion wurde bereits eine Erstattung bearbeitet.",
    "EZIDEBIT_ERROR_CODE_API_NOT_USABLE_DUE_TO_STATUS":"Ihr Status ist xxxxx und erlaubt es Ihnen nicht, die API zu verwenden.",
    "EZIDEBIT_ERROR_CODE_BLANK_BANK_ACCOUNT_NUMBER":"Bankkontonummer kann nicht leer sein",
    "EZIDEBIT_ERROR_CODE_BPAY_PRODUCT_NOT_AVAILABLE":"Sie haben kein aktives BPay-Produkt",
    "EZIDEBIT_ERROR_CODE_CAN_NOT_CHANGE_CANCELLED_CUSTOMER_STATUS":"Sie können den Status eines Kunden, der storniert wurde, nicht ändern.",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_DATE":"Sie müssen einen Wert für den Parameter \"ChangeFromDate\" oder \"PaymentReference\" angeben.",
    "EZIDEBIT_ERROR_CODE_CHANGE_FROM_PAYMENT_NUMBER":"Sie müssen entweder für den Parameter \"ChangeFromDate\" oder \"ChangeFromPaymentNumber\" einen Wert angeben.",
    "EZIDEBIT_ERROR_CODE_CLIENT_IS_NOT_ACTIVE":"Der Kunde ist NICHT aktiv.",
    "EZIDEBIT_ERROR_CODE_CREDIT_CARD_ALREADY_EXPIRED":"Das Jahr/Monat der Kreditkarte ist bereits abgelaufen.",
    "EZIDEBIT_ERROR_CODE_CUSTOMER_NOT_FOUND":"Es konnte kein Kunde mit den angegebenen Details gefunden werden.",
    "EZIDEBIT_ERROR_CODE_DATE_FIELD":"Sie müssen einen Wert für den Parameter \"DateField\" angeben, wenn die Werte \"DateFrom\" oder \"DateTo\" angegeben werden.",
    "EZIDEBIT_ERROR_CODE_DATE_FROM":"Sie müssen einen Wert für die Parameter \"DateFrom\" oder \"DateTo\" angeben, wenn der Wert \"DateField\" angegeben wird.",
    "EZIDEBIT_ERROR_CODE_DATEFROM_CANT_BE_GREATER_THAN_DATETO":"DateFrom\" darf nicht mehr als xx Monate vor \"DateTo\" liegen.",
    "EZIDEBIT_ERROR_CODE_DEBIT_DATE":"Sie müssen einen Wert für den Parameter \"DebitDate\" angeben.",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_INCORRECT_OR_VALIDATION_FAILED":"Der digitale Schlüssel ist falsch oder der Zugriff auf diese Funktion wird verweigert. Die Validierung des digitalen Schlüssels ist fehlgeschlagen. Überprüfen Sie, ob Sie den richtigen digitalen Schlüssel verwenden.",
    "EZIDEBIT_ERROR_CODE_DIGITAL_KEY_VALIDATION_FAILED":"Ungültiges Token. Das Kunden-Token ist für diesen digitalen Schlüssel nicht gültig.",
    "EZIDEBIT_ERROR_CODE_EZIDEBIT_CUSTOMER_ID":"Sie müssen entweder einen Wert für den Parameter \"EziDebitCustomerID\" oder für den Parameter \"YourSystemReference\" angeben",
    "EZIDEBIT_ERROR_CODE_FUNCTION_UNAVAILABLE_DUE_TO_EZIDEBIT_PROCESSING":"Diese Funktion ist derzeit nicht verfügbar, da die Ezidebit-Verarbeitung gerade durchgeführt wird. Bitte versuchen Sie es später noch einmal...",
    "EZIDEBIT_ERROR_CODE_INACTIVE_CUSTOMER_STATUS":"Kunde nicht aktiv(Status:<Statuscode>).Kundenstatus ist nicht A (Aktiv) oder N (Neu)",
    "EZIDEBIT_ERROR_CODE_INAVALID_CREDIT_CARD_DETAILS":"Ungültige Kreditkartennummer eingegeben - Ihre Produktpalette enthält nicht xxxxxxx",
    "EZIDEBIT_ERROR_CODE_INSUFFICIENT_FUNDS":"Der Kunde verfügt nicht über ausreichende Mittel zur Deckung dieses Anpassungsbetrags.",
    "EZIDEBIT_ERROR_CODE_INVALID_ACCOUNT_NUMBER_LENGTH":"Ungültige Länge der Kontonummer",
    "EZIDEBIT_ERROR_CODE_INVALID_APPLY_TO_ALL_FUTURE_PAYMENTS":"Unzulässiger Wert für den Parameter \"ApplyToAllFuturePayments\" angegeben. Gültige Werte sind: 'YES' oder 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB":"Ungültiges Format für den Parameter \"BankAccountBSB\". Gültige Werte dürfen nur sechs Ziffern sein",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_BSB_ENTERED":"Ungültige BSB-Nummer des Bankkontos eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_ENTERED":"Ungültige Bankkontonummer eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_BANK_ACCOUNT_NAME_ENTERED":"Unzulässiger Bankkontoname eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_BANKRECEIPTID":"BankReceiptId' konnte nicht ermittelt werden",
    "EZIDEBIT_ERROR_CODE_INVALID_CARD_NUMBER":"Ungültige Kreditkartennummer eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGE_FROM_PAYMENT_NUMBER":"Unzulässiger Wert für den Parameter 'ChangeFromPaymentNumber' angegeben. Gültige Werte müssen numerisch und größer oder gleich eins sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGEFROMDATE":"Ungültiger Wert für den Parameter \"ChangeFromDate\". Gültige Werte sind alle zukünftigen Daten im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_INVALID_CHANGETODATE":"Unzulässiger Wert für den Parameter \"ChangeToDate\". Gültige Werte sind ein beliebiges Datum im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_INVALID_CONTRACT_STARTDATE":"Unzulässiger Wert für den Parameter \"ContractStartDate\". Gültige Werte sind alle zukünftigen Datumsangaben im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_INVALID_DATE_FIELD":"Unzulässiger Wert für den Parameter \"DateField\". Gültige Werte sind: 'PAYMENT' oder 'SETTLEMENT'",
    "EZIDEBIT_ERROR_CODE_INVALID_DATEFROM":"Ungültiger Wert für den Parameter \"DateFrom\". Gültige Werte sind jedes Datum im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_INVALID_DATETO":"Unzulässiger Wert für den Parameter \"DateTo\". Gültige Werte sind alle Datumsangaben im Format 'JJJJ-MM-TT'.",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_MONTH":"Ungültiger Wert für den Parameter \"DayOfMonth\". Gültige Werte müssen zwischen 1 und 31 liegen.",
    "EZIDEBIT_ERROR_CODE_INVALID_DAY_OF_WEEK":"Unzulässiger Wert für den Parameter \"DayOfWeek\". Gültige Werte sind: MON\", \"TUE\", \"WED\", \"THU\" oder \"FRI\".",
    "EZIDEBIT_ERROR_CODE_INVALID_DEBITDATE":"Ungültiger Wert für den Parameter 'DebitDate'. Gültige Werte sind jedes zukünftige Datum im Format 'JJJJ-MM-TT'.",
    "EZIDEBIT_ERROR_CODE_INVALID_DIGITAL_KEY":"Ungültiger DigitalKey.",
    "EZIDEBIT_ERROR_CODE_INVALID_DIRECT_DEBIT_CARD_DETAILS":"Ungültige Angaben zum Lastschrifteinzug - Ihr Produktangebot umfasst keine Lastschriften.",
    "EZIDEBIT_ERROR_CODE_INVALID_EMAIL_ADDRESS":"Ungültige E-Mail Adresse eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_MONTH":"Ungültiger Gültigkeitsmonat der Kreditkarte eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_EXPIRY_YEAR":"Ungültiges Jahr des Ablaufs der Kreditkarte eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_EZIDEBIT_CUSTOMER_ID":"Ungültiger Wert für den Parameter \"EzidebitCustomerID\" eingegeben. Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_SCHEDULE_PERIOD_TYPE":"Ungültiger Wert für den Parameter \"SchedulePeriodType\". Gültige Werte sind: '4', 'F', 'H', 'M', 'N', 'Q', 'W' oder 'Y'",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRST_WEEK_OF_MONTH":"Ungültiger Wert für den Parameter \"FirstWeekOfMonth\". Gültige Werte sind: 'YES' oder 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_FIRSTNAME":"Der Vorname enthält nicht standardisierte Zeichen, was nicht zulässig ist.",
    "EZIDEBIT_ERROR_CODE_INVALID_FOURTH_WEEK_OF_MONTH":"Unzulässiger Wert für den Parameter \"FourthWeekOfMonth\" (Vierte Woche des Monats). Gültige Werte sind: 'YES' oder 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_INPUT_BILLING_CUSTOMER_REFERENCE":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter 'EziDebitCustomerID' und 'YourSystemReference' eingeben",
    "EZIDEBIT_ERROR_CODE_INVALID_LASTNAME":"Der Nachname enthält Nicht-Standard-Zeichen, was nicht zulässig ist.",
    "EZIDEBIT_ERROR_CODE_INVALID_LENGTH_FOR_YOUR_PAYER_NUMBER":"Die Länge für den Parameter 'YourPayerNumber' ist größer als die maximale Länge.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_NUMBER_OF_PAYMENTS":"Für den Parameter \"LimitToNumberOfPayments\" wurde ein ungültiger Wert angegeben. Gültige Werte müssen numerisch und größer oder gleich Null sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_LIMIT_TO_TOTAL_AMOUNT_IN_CLIENTS":"Ungültiger Wert für den Parameter \"LimitToTotalAmountInCents\". Gültige Werte müssen numerisch und größer oder gleich Null sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_MANUAL_PAYMENTS":"Unzulässiger Wert für den Parameter \"KeepManualPayments\". Gültige Werte sind: 'YES' oder 'NO'",
    "EZIDEBIT_ERROR_CODE_INVALID_NAME_ON_THE_CARD":"Ungültiger Name auf der eingegebenen Kreditkarte",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_PAYMENT_AMOUNT_IN_CLIENTS":"Unzulässiger Wert für den Parameter \"NewPaymentAmountInCents\". Gültige Werte müssen größer oder gleich 200 ($2 Dollar) sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_STATUS":"Unzulässiger Wert für den Parameter \"NewStatus\". Gültige Werte sind: 'A', 'H' oder 'C'.",
    "EZIDEBIT_ERROR_CODE_INVALID_NEW_YOUR_SYSTEM_REFERENCE":"Ungültiger Wert für den Parameter \"NewYourSystemReference\". Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_NUMBER":"Parameterkonflikt. Sie können für 'LimitToNumberOfPayments' und 'LimitToTotalAmountInCents' nicht gleichzeitig eine Zahl größer als Null angeben",
    "EZIDEBIT_ERROR_CODE_INVALID_PARTIAL_REFUND_AMOUNT":"Der Teilerstattungsbetrag kann nicht zwischen dem geplanten Abbuchungsbetrag und dem Gesamtzahlungsbetrag liegen.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_AMOUNT_IN_CLIENTS":"Ungültiger Wert für den Parameter \"PaymentAmountInCents\". Gültige Werte müssen größer oder gleich 200 ($2 Dollar) sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_METHODS":"Unzulässiger Wert für den Parameter \"PaymentMethod\". Gültige Werte sind: ALL\", \"DR\" oder \"CR\".",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_REFERENCE":"Ungültige Zahlungsreferenz eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_SOURCE":"Unzulässiger Wert für den Parameter \"PaymentSource\". Gültige Werte sind: 'ALL', 'SCHEDULED', 'WEB', 'PHONE' oder 'BPAY'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PAYMENT_TYPE":"Unzulässiger Wert für den Parameter 'PaymentType' angegeben. Gültige Werte sind: 'ALL', 'PENDING', 'SUCCESSFUL' oder 'FAILED'.",
    "EZIDEBIT_ERROR_CODE_INVALID_PHONE_NUMBER":"Ungültige Handynummer eingegeben",
    "EZIDEBIT_ERROR_CODE_INVALID_POSTCODE":"Eingabe einer ungültigen Postleitzahl",
    "EZIDEBIT_ERROR_CODE_INVALID_REFERENCE":"Die Zahlung mit der Referenz 'xxxxxxx' konnte nicht gefunden werden.",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_AMOUNT_IN_CENTS":"Ungültiger Wert für den Parameter 'RefundAmountInCents' angegeben. Gültige Werte sind ein Erstattungsbetrag zwischen 100 und dem Betrag der ursprünglichen Transaktion",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUND_PAYMENT":"Das Zahlungsinstrument für die Erstattung stimmt nicht mit dem ursprünglichen Zahlungsinstrument überein",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUNTINCENTS":"Für den Parameter \"RefundAmountInCents\" wurde ein ungültiger Wert angegeben. Gültige Werte sind ein Erstattungsbetrag zwischen 100 und dem Betrag der Originaltransaktion",
    "EZIDEBIT_ERROR_CODE_INVALID_REFUNDAMOUTN":"Erstattungsbetrag übersteigt den Gesamtzahlungsbetrag",
    "EZIDEBIT_ERROR_CODE_INVALID_SCHEDULE_STARTDATE":"Ungültiger Wert für den Parameter \"ScheduleStartDate\". Gültige Werte sind jedes zukünftige Datum im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_INVALID_SECOND_WEEK_OF_MONTH":"Für den Parameter \"SecondWeekOfMonth\" wurde ein ungültiger Wert angegeben. Gültige Werte sind: YES\" oder \"NO\".",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSEXPIREDDATE":"Ungültiger Wert für den Parameter \"SmsExpiredCard\". Gültige Werte sind: YES\" oder \"NO\".",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSFAILEDNOTIFICATION":"Ungültiger Wert für den Parameter \"SmsFailedNotification\". Gültige Werte sind: YES\" oder \"NO\".",
    "EZIDEBIT_ERROR_CODE_INVALID_SMSPAYMENTREMINDER":"Ungültiger Wert für den Parameter \"SmsPaymentReminder\". Gültige Werte sind: 'YES' oder 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_THIRD_WEEK_OF_MONTH":"Ungültiger Wert für den Parameter \"ThirdWeekOfMonth\". Gültige Werte sind: 'YES' oder 'NO'.",
    "EZIDEBIT_ERROR_CODE_INVALID_TOKEN":"Ungültiges Token. Das Token ist nicht numerisch.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CCV_NUMBER":"Ungültige CCV-Kreditkartennummer eingegeben - CCV-Nummer muss entweder 3- oder 4-stellig sein",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_NAME":"Ungültiger Wert für den Parameter 'CustomerName'. Der Wert muss weniger als 80 Zeichen lang sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_CUSTOMER_STATUS":"Unzulässiger Wert für den Parameter \"CustomerStatus\". Gültige Werte sind: 'ALL', 'HOLD', 'PENDING', 'CANCELLED', 'ACTIVE'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER":"Ungültiger Wert für den Parameter Order. Gültige Werte sind: 'ASC', 'DESC'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_ORDER_BY":"Ungültiger Wert für den OrderBy-Parameter. Gültige Werte sind: 'EzidebitCustomerId', 'YourSystemReference', 'YourGeneralReference', 'CustomerCreationDate'.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_PAGE_NUMBER":"Ungültiger Wert für den Parameter PageNumber. Gültige Werte müssen numerisch und größer als Null sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_REATIVATE_ENTERED":"Ungültiger Wert für den Parameter 'Reactivate' eingegeben. Gültige Werte sind \"YES\" oder \"NO\".",
    "EZIDEBIT_ERROR_CODE_INVALID_VALUE_YOUR_PAYMER_NUMBER":"Für den Parameter 'YourPayerNumber' wurde ein ungültiger Wert eingegeben. Gültige Werte sind positive Ganzzahlen.",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_GENERAL__REFERENCE":"Für den Parameter \"YourGeneralReference\" wurde ein ungültiger Wert angegeben. Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
    "EZIDEBIT_ERROR_CODE_INVALID_YOUR_SYSTEM_REFERENCE":"Ungültiger Wert für den Parameter \"YourSystemReference\". Gültige Werte dürfen höchstens 50 Zeichen lang sein.",
    "EZIDEBIT_ERROR_CODE_INVLID_VALUE_PROVIDED_FOR_DEBIT_DATE":"Unzulässiger Wert für den Parameter \"DebitDate\". Gültiger Wert ist ein beliebiges Datum, das einunddreißig (31) Tage in der Vergangenheit liegt, im Format \"JJJJ-MM-TT\".",
    "EZIDEBIT_ERROR_CODE_LAST_NAME":"Sie müssen einen Wert für den Parameter \"LastName\" angeben.",
    "EZIDEBIT_ERROR_CODE_MISSING_DIGITAL_KEY":"Sie müssen einen Wert für den Parameter \"DigitalKey\" angeben",
    "EZIDEBIT_ERROR_CODE_MISSING_PARAM_CUSTOMER_STATUS":"Sie müssen einen Wert für den Parameter \"CustomerStatus\" angeben.",
    "EZIDEBIT_ERROR_CODE_MISSING_PAYMENTID_OR_BANKRECEIPTID":"Sie müssen entweder für den Parameter \"PaymentID\" oder \"BankReceiptID\" einen Wert angeben. Geben Sie einen Wert für PaymentID oder BankReceiptID an.",
    "EZIDEBIT_ERROR_CODE_MISSING_UPDATE_VALUE":"Sie haben keine Kreditkarten- oder Bankkontodaten angegeben. Es wurden keine obligatorischen Daten für die Aktualisierung der Rechnungsdaten angegeben.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_CUSTOMER_NAME":"Sie müssen einen Wert für den Parameter \"customerName\" angeben.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER":"Sie müssen einen Wert für den Parameter \"Order\" angeben, wenn der Wert \"OrderBy\" angegeben wird.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_ORDER_BY":"Sie müssen einen Wert für den Parameter \"OrderBy\" angeben, wenn der Wert \"Order\" angegeben wird.",
    "EZIDEBIT_ERROR_CODE_MISSING_VALUE_YOUR_PAYMER_NUMBER":"Sie müssen einen Wert für den Parameter \"YourPayerNumber\" angeben.",
    "EZIDEBIT_ERROR_CODE_MOBILE_NUMBER":"Sie müssen einen gültigen Wert für den Parameter \"MobileNumber\" angeben, wenn Sie möchten, dass der Kunde SMS-Benachrichtigungen erhält.",
    "EZIDEBIT_ERROR_CODE_MONTH_SCHEDULE":"Sie müssen einen Wochentag angeben, wenn Sie einen wöchentlichen, zweiwöchentlichen, vierwöchentlichen oder einen Wochentag im Monat-Zeitplan erstellen.",
    "EZIDEBIT_ERROR_CODE_NO_DATA_MATCHED_FOR_SELECTED_PARAMS":"Es wurden keine Daten in den Auswahlparametern gefunden.",
    "EZIDEBIT_ERROR_CODE_NULL_VALUE":"Nullwert erkannt, bitte geben Sie bei Parametern, für die Sie keinen Wert angeben möchten, den leeren String anstelle von Null ein",
    "EZIDEBIT_ERROR_CODE_ONLY_ONE_INPUT_REQUIRED_PAYMENTID_OR_BANKRECEIPTID":"Sie müssen entweder für den Parameter \"PaymentID\" oder \"BankReceiptID\" einen Wert angeben",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_CHANGEFROM_DATE_AND_CHANGE_FROM_PAYMENT_NUMBER":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter 'ChangeFromDate' und 'ChangeFromPaymentNumber' angeben",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_MONTH":"Parameterkonflikt. 'ScheduleStartDate' und 'DayOfMonth' stimmen nicht überein.",
    "EZIDEBIT_ERROR_CODE_PARAMETER_CONFLICT_SCHEDULE_START_DATE_AND_DAY_OF_WEEK":"Parameterkonflikt. Die Parameter 'ScheduleStartDate' und 'DayOfWeek' stimmen nicht überein.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED":"Zahlung hinzufügen verweigert - Nur aktive Kunden können Zahlungen zu ihrem Zeitplan hinzufügen",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_ALREADY_REGISTERED_WITH_SAME_REF":"Zahlung hinzufügen verweigert - Sie haben bereits eine Zahlung mit der Referenz xxxxxxx registriert. Eine Zahlungsreferenz muss eine einzelne Zahlung eindeutig identifizieren.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_CUSTOMER_ALREADY_HAS_PAYMENT_ON_THIS_DATE":"Zahlung hinzufügen verweigert - Dieser Kunde hat bereits xx Zahlungen an diesem Datum.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_BANK_ACCOUNT_DETAILS":"Zahlung hinzufügen verweigert - Dieser Kunde befindet sich in der Warteschleife, da seine Bankverbindung ungültig ist, und die Kontodaten wurden nicht geändert.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_INVALID_CREDIT_CARD_DETAILS":"Zahlung hinzufügen verweigert - Dieser Kunde ist in der Warteschleife wegen ungültiger Kreditkartendaten, und diese Kartendaten wurden nicht geändert.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_DUE_TO_STATUS":"Zahlung hinzufügen verweigert - Der Status xx dieses Kunden erlaubt es nicht, neue Zahlungen hinzuzufügen.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_DENIED_STATUS_DOES_NOT_ALLOW_NEW_PAYMENT":"Zahlung hinzufügen verweigert - Der Status \"xxxxx\" dieses Kunden lässt keine neuen Zahlungen zu.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_NOT_CONFIGURED":"Nicht für Kreditkartenzahlungen konfiguriert. Das Kundenkonto ist nicht für die Verarbeitung von Echtzeit-Kreditkartenzahlungen für den Kartentyp des Kunden konfiguriert.",
    "EZIDEBIT_ERROR_CODE_PAYMENT_REFERENCE":"Sie müssen einen Wert für den Parameter 'PaymentReference' angeben",
    "EZIDEBIT_ERROR_CODE_PROCESSED_PAYMENT_CAN_NOT_BE_DELETED":"Die Zahlung wurde verarbeitet und kann nicht gelöscht werden",
    "EZIDEBIT_ERROR_CODE_PROCESSING_PAYMENT":"Die ursprüngliche Zahlung ist noch in Bearbeitung. Sie kann in diesem Stadium nicht zurückerstattet werden.",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID":"Kein Zahlungsdatensatz für PaymentId gefunden",
    "EZIDEBIT_ERROR_CODE_RECORD_NOT_FOUND_FOR_PAYMENTID_OR_BANKRECEIPTID":"Kein übereinstimmender Datensatz für die 'PaymentId' oder 'BankReceiptId' gefunden",
    "EZIDEBIT_ERROR_CODE_REFUND_EXCEEDS_TOTAL_AMOUNT":"Erstattungsbetrag übersteigt den Gesamtzahlungsbetrag",
    "EZIDEBIT_ERROR_CODE_REFUNDS_NOT_ALLOWED":"Rückerstattungen sind für den Kunden nicht zulässig",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATA_FIELD":"Sie haben sowohl Kreditkarten- als auch Bankkontodaten eingegeben. Es dürfen nur Kreditkarten- oder Bankkontodaten eingegeben werden, nicht beide.",
    "EZIDEBIT_ERROR_CODE_REQUIRED_ONE_DATAFIELD":"Parameterkonflikt. Sie können nicht gleichzeitig einen Wert für die Parameter \"PaymentID\" und \"BankReceiptID\" eingeben.",
    "EZIDEBIT_ERROR_CODE_REQUIRED_PARAMETERS":"Es wurden nicht alle erforderlichen Parameter angegeben.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FIRST_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'FirstWeekOfMonth' stimmen nicht überein.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_FORTH_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'FourthWeekOfMonth' stimmen nicht überein.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_SECOND_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'SecondWeekOfMonth' sind nicht aufeinander abgestimmt.",
    "EZIDEBIT_ERROR_CODE_SCHEDULE_START_DATE_AND_THIRD_WEEK_OF_MONTH_NOT_ALLIGNED":"Parameterkonflikt. 'ScheduleStartDate' und 'ThirdWeekOfMonth' stimmen nicht überein.",
    "EZIDEBIT_ERROR_CODE_SELECTED_PAYMENT_NOT_FOUND_FOR_DELETION":"Die zur Löschung ausgewählte Zahlung konnte nicht gefunden werden.",
    "EZIDEBIT_ERROR_CODE_SHARED_BPAY_NOT_SUPPORTED":"Sie verwenden keinen gemeinsamen Ezidebit BPay-Rechnungssteller-Code.",
    "EZIDEBIT_ERROR_CODE_STATUS_UPDATE_DENIED":"Statusaktualisierung verweigert - Nur Kunden mit einem Status von 'A', 'N' oder 'H' können über diesen Dienst geändert werden.",
    "EZIDEBIT_ERROR_CODE_UNABLE_TO_PROCESS_CCV_NUMBER":"Aktualisierung kann nicht verarbeitet werden - Ungültige CCV-Kreditkartennummer eingegeben - CCV-Nummer muss für AMEX (WSvc) 4-stellig sein",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA":"Die Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird. Bitte versuchen Sie es später erneut",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_PT":"Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird (PT). Bitte versuchen Sie es später noch einmal...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_REPORT_DATA_SPS":"Die Berichtsdaten sind derzeit nicht verfügbar, da die Zahlungsverarbeitung gerade durchgeführt wird (SPS). Bitte versuchen Sie es später noch einmal...",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_CANNOT_OPEN_CONNECTION":"Das System ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal. Eine Verbindung zur Datenbank kann aus irgendeinem Grund nicht geöffnet werden. Bitte kontaktieren Sie partner@ezidebit.com.au für Hilfe.",
    "EZIDEBIT_ERROR_CODE_UNAVAILABLE_SYSTEM_UNHANDLED_ERROR":"Das System ist zur Zeit nicht verfügbar. Bitte versuchen Sie es später noch einmal. Beim Extrahieren der Details aus der Datenbank ist ein unbehandelter Fehler aufgetreten. Bitte kontaktieren Sie partner@ezidebit.com.au für Hilfe.",
    "EZIDEBIT_ERROR_CODE_YES":"Sie müssen für mindestens einen der Parameter \"Woche des Monats\" einen \"JA\"-Wert angeben, wenn Sie einen Zeitplan \"Wochentag im Monat\" erstellen.",
    "EZIDEBIT_ERROR_CODE_YOUR_GENERAL__REFERENCE":"Sie müssen einen Wert für den Parameter \"YourGeneralReference\" angeben.",
    "EZIDEBIT_ERROR_CODE_YOUR_SYSTEM_REFERENCE":"Sie müssen einen Wert für den Parameter \"YourSystemReference\" angeben.",
    "EZIDEBIT_NO_ERROR_DETECTED":"Keine Fehler gefunden",
    "FAILED_TO_ASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER":"Fehler beim Zuweisen des Kunden zum Account Manager",
    "FAILED_TO_ASSIGN_RESELLER_TO_ACCOUNT_MANAGER":"Reseller konnte dem Account Manager nicht zugewiesen werden",
    "FAILED_TO_CHANGE_BILLING_CYCLE_OF_ORDER":"Abrechnungszyklus der Bestellung konnte nicht geändert werden",
    "FAILED_TO_CREATE_ACCOUNT_MANAGER":"Account Manager konnte nicht erstellt werden",
    "FAILED_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Fehler beim Erstellen eines neuen Plans mit allen Angeboten",
    "FAILED_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Erstellen eines neuen Wiederverkäuferplans mit allen Angeboten fehlgeschlagen",
    "FAILED_TO_DELETE_ACCOUNT_MANAGER":"Löschen des Account Managers fehlgeschlagen",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CLONE_PLAN":"Anforderung für Klonplan konnte nicht in die Warteschlange gestellt werden",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_PLAN_WITH_ALL_OFFERS":"Anforderung zum Erstellen eines neuen Plans mit allen Angeboten konnte nicht in die Warteschlange gestellt werden",
    "FAILED_TO_ENQUEUE_REQUEST_FOR_CREATING_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Die Anfrage zum Erstellen eines neuen Reseller-Tarifs mit allen Angeboten konnte nicht ausgeführt werden",
    "FAILED_TO_GENERATE_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Fehler beim Abrufen der Zugriffstoken durch den aus Quickbooks empfangenen Authentifizierungscode",
    "FAILED_TO_GENERATE_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Fehler beim Abrufen der Zugriffstoken durch den von Xero empfangenen Authentifizierungscode",
    "FAILED_TO_RESEND_INVOICE":"Rechnung konnte nicht erneut gesendet werden",
    "FAILED_TO_SAVE_AUTHCODE_RECIEVED_FROM_QUICKBOOKS":"Fehler beim Speichern des von Quickbooks empfangenen Authentifizierungscodes",
    "FAILED_TO_SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Fehler beim Speichern des von Xero empfangenen Authentifizierungscodes",
    "FAILED_TO_UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER":"Fehler beim Aufheben der Zuordnung des Kunden eines Account Managers",
    "FAILED_TO_UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER":"Fehler beim Aufheben der Zuordnung des Resellers eines Account Managers",
    "FAILED_TO_UPDATE_ACCOUNT_MANAGER_DETAILS":"Fehler beim Aktualisieren der Account Manager-Details",
    "FAQ_CUSTOMER_CATEGORY_GENERAL":"Allgemeines",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_1":"Sie können die Seeds mithilfe der Bearbeitungsfunktion unter Abonnements einfach aktualisieren.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_2":"Sie können den Zugriff gewähren, indem Sie in der Benutzeransicht Zugriff auf die Portalfunktionen gewähren.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_3":"Ja, Sie können über unser Portal aktualisieren.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_ANS_4":"Ja, Sie können das Abonnement mit c3 aussetzen.",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_1":"1. Wie kann ich das Abonnement erhöhen?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_2":"2. Wie kann ein normaler Benutzer auf das Portal zugreifen?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_3":"3. Gibt es eine Möglichkeit, die Zahlungsmethode mit C3 zu aktualisieren?",
    "FAQ_CUSTOMER_CATEGORY_GENERAL_QUS_4":"4. Kann C3 Abonnements aussetzen?",
    "FAQ_HEADING":"Häufig gestellte Fragen",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL":"Anwendung Allgemein",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_1":"Ab sofort wird C3 von Englisch (en-us), Spanisch (es) und Taiwanesisch (tw) unterstützt. Darüber hinaus unterstützt C3 Währungen in Abhängigkeit von den Partner Center-Regionen und der Abrechnung",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_10":"Ja, dies ist in C3 durch Definieren von Tags möglich. Sie müssen Tags (Wertepaar) definieren und Kunden zuweisen. Z.Bsweise können Sie ein Tag mit dem Namen SupportAgent und einen Spitznamen eines Agenten (z. B. Agent001) als Wert definieren. Wenn für einen Helpdesk-Agenten eines der Tags als SupportAgent und einer der Werte für dieses bestimmte Tag als Agent001 festgelegt ist, kann der Agent die Kunden sehen, für die das Tag-Wert-Paar {SupportAgent, Agent001} festgelegt ist.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_2":"Gehen Sie zu Einstellungen>>E-Mail-Benachrichtigung, wo Sie C3 so konfigurieren können, dass es ereignisbasierte E-Mails versendet. C3 bietet Ihnen verschiedene Ereignisse, für die Sie E-Mails konfigurieren können. Sie können auch die E-Mail-Vorschau für das jeweilige Ereignis sehen.  Sie können eine E-Mail-Benachrichtigung für ein Ereignis konfigurieren, bei dem ein Angebot von einem Kunden gekauft wird. Sie können den Empfänger auf Basis der Rolle oder der Rechnungskontakte auswählen. Zusätzlich können Sie auch die CC- und BCC-Liste auswählen. Sobald dies geschehen ist, sendet C3 jedes Mal, wenn ein solches Ereignis eintritt, E-Mail-Benachrichtigungen an alle in den E-Mail-Benachrichtigungseinstellungen konfigurierten Empfänger.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_3":"Sie können Berichte zu Steuerzusammenfassungen und Steuerpositionen von der Kachel Umsatz- und Kostenübersicht herunterladen, die im Partnerportal verfügbar ist.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_4":"Ja, C3-Support-Rabatte. Sie können Rabattcoupons erstellen und diese auch auf bestimmte Pläne oder Kunden anwenden. Sie können verschiedene Parameter für einen Gutschein definieren, z. B. maximale Einlösung, gültig ab Datum, gültig bis Datum und Rabattprozentsatz.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_5":"Sie können C3 mithilfe von Logos für das Branding anpassen. C3 bietet Ihnen an, das Begrüßungslogo auf der Begrüßungsseite und das angemeldete Logo durch Ihr Logo zu ersetzen. Sie können Benachrichtigungs-E-Mails sogar mithilfe Ihres Logos anpassen, um die Wirkung des Brandings zu verbessern.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_6":"Ja, wir können die erforderlichen Änderungen an unserem Ende vornehmen, damit der Kunde auf der Anmeldeseite anstelle der C3-Begrüßungsseite landet.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_7":"Nein, wir sind noch nicht zertifiziert. Wir verwenden jedoch die Konfiguration derselben Ebene, um Ihre Daten sicher zu verwahren.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_8":"Ja das können wir tun. Wir geben Ihnen eine IP-Adresse. Sie müssen einen Eintrag mit derselben erstellen, in der Ihr DNS verwaltet wird. Ihr DNS-Anbieter hilft Ihnen dabei.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_ANS_9":"Nein, Sie können im Falle einer Änderung direkt zu Festlegen und Aktualisieren der Anmeldeinformationen für Partner Center oder SMTP gehen.",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_1":"1. Welche Sprachen und Währungen werden von C3 unterstützt?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_10":"10. Kann ich Benutzer auf meiner Seite haben, die sich nur mit einer Gruppe von Kunden befassen?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_2":"2. Wie konfiguriere ich C3 so, dass E-Mails versendet werden, wenn ein Kunde einen Kauf tätigt? Wie konfiguriere ich auch die Empfänger solcher E-Mails?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_3":"3. Welche Art von Steuerberichten a3. Welche Steuerberichte stehen zum Download zur Verfügung?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_4":"4. Unterstützt C3 Rabatte?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_5":"5. Welche Art von Anpassung bietet C3 ohne Look-and-Feel (weiße Beschriftung/Branding)?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_6":"6. Gibt es eine Möglichkeit, meine Kunden auf einen Link in meinem Portal klicken zu lassen, der sie zur Anmeldeseite anstelle der C3-Begrüßungsseite führt?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_7":"7. Ist C3 PCI kompatibel?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_8":"8. Kann ich meinen bevorzugten Domainnamen mit C3 verknüpfen? Was braucht es, um das zu tun?",
    "FAQ_PARTNER_CATEGORY_APPLICATION_GENERAL_QUS_9":"9. Soll ich mich an den C3-Support wenden, wenn sich die Anmeldeinformationen für mein Partner Center oder SMTP geändert haben oder abgelaufen sind?",
    "FAQ_PARTNER_CATEGORY_BILLING":"Abrechnung",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_1":"Derzeit unterstützt C3 Manual, Credit Card, ACH (nur für US-Kunden) und Bankkonten (nur für australische und neuseeländische Kunden).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_10":"Ja, Kunden erhalten eine Kopie der Rechnung als PDF in der Benachrichtigungs-E-Mail, wenn die Rechnung vom Partner genehmigt wird.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_11":"In solchen Fällen können Sie verhindern, dass C3 die Kunden belastet, indem Sie die Zahlung auf der jeweiligen Rechnung erfassen. Sie müssen zu Umsatz- und Kostenübersicht >> Kunde auswählen >> Rechnungen gehen. Von hier aus müssen Sie die Rechnung auswählen, für die Sie die Zahlung eingezogen haben. Hier finden Sie eine Option als Rekordzahlung.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_12":"Wir unterstützen nicht mehrere Abrechnungszyklen. Aber in diesem Fall können wir alle Kunden auf einen bestimmten Abrechnungszyklus ausrichten. Dies ist ein manueller Prozess.  Ein Beispiel: Ihr aktueller Abrechnungszyklus für einen Kunden ist der 10. dieses Monats bis zum 9. des nächsten Monats und in C3 ist der Abrechnungszyklus der 1. dieses Monats bis zum 1. des nächsten Monats. Da alle Kunden alle Zahlungen bis zum 9. dieses Monats geleistet haben. Wir berechnen den entsprechenden Betrag für den 1. bis 9. und passen ihn in der nächsten Rechnung an, wodurch das Problem gelöst wird.  In ähnlicher Weise können wir das auch für andere Abrechnungszyklen tun. Ab dem nächsten Monat werden alle Kunden auf denselben Abrechnungszyklus ausgerichtet sein.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_13":"Ja, wir können Anpassungen bezüglich der Zahlungen in der entsprechenden Rechnung vornehmen. Derzeit gibt es keine automatisierte Version. Wir können uns darum kümmern.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_14":"Jetzt unterstützen wir Bill & Pay, Stripe, EziDebit, Authorize.net, Cybersource. Wenn Sie eine andere als diese verwenden, können wir auf jeden Fall auf die Integration Ihres Zahlungsgateways in C3 achten.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_2":"C3 unterstützt bisher mehrere Zahlungsgateways wie Bill & Pay, Stripe, EziDebit, Authorize.net und CyberSource. Es wäre großartig, wenn Sie eines davon als vorhandenes Zahlungsgateway verwenden würden, oder wir könnten versuchen, C3 so zu gestalten, dass es Ihre Zahlungsgateways unterstützt.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_3":"Wir aktualisieren die C3-Angebote jeden Monat mit dem neuesten Angebot von Microsoft. Im Falle einer Preisänderung müssen Sie einen neuen Plan erstellen oder den bestehenden Plan mit den neuen Angebotspreisen aktualisieren.  Standardmäßig wird C3 den neuen Preis für neue Kunden übernehmen. Für bestehende Kunden können Sie jedoch einen neuen Preis für das aktuell abonnierte Angebot festlegen. Zusätzlich können Sie das Datum des Inkrafttretens der neuen Preise wählen, z.B. mit sofortiger Wirkung bei Preisänderung, nächstem Abrechnungszyklus oder nächstem Verlängerungsdatum.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_4":"In C3 werden Steuerregeln durch Kombinationen aus  { Country,State Province and Tax Code }, { Country, Zip } oder { Country, State Province and Zip } definiert. Z.Bsweise bedeutet die für Land - USA definierte Steuerregel, dass die Provinz und die Postleitzahl leer bleiben, dass der Steuersatz auf alle Kunden aus den USA angewendet wird. Wenn Sie eine Regel für einen bestimmten Staat definieren möchten, geben Sie beim Definieren der Regel die Werte für Land und Staat an.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_5":"Ja, das kannst du machen. Sie müssen zu Einstellung >> Steuern gehen und eine Steuerregel mit einem Steuerprozentsatz von 0% erstellen. Wenden Sie es auf Kunden an, die Sie von der Steuerregel befreien möchten (gilt für andere Kundengruppen).",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_6":"Sie können das Datum für die Rechnungserstellung festlegen und uns mitteilen. Wir konfigurieren es für Sie und Sie können es überprüfen, indem Sie auf Partnereinstellung >> Abrechnung gehen. Hier sehen Sie alle Daten zu Abrechnungen. Sie können dieses Datum auch für einen bestimmten Kunden ändern, indem Sie zur Kundenkonfiguration wechseln.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_7":"Mit C3 können Sie festlegen, wann Ihr Kunde die Rechnung sehen soll. Nach der Rechnungserstellung muss der Partner die Rechnung genehmigen (gegebenenfalls Anpassungen vornehmen), um sie den Kunden zur Verfügung zu stellen.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_8":"Ja, C3 bietet Ihnen die Möglichkeit, Änderungen an Werbebuchungen in der Rechnung nach der Rechnungserstellung vorzunehmen. Sie müssen die erforderlichen Änderungen vornehmen (z. B. - Sie möchten eine einmalige Gebühr hinzufügen/verzichten), basierend auf Ihren Anforderungen vor dem Rechnungsabschlussdatum. Sie können keine Änderungen an der Rechnung vornehmen, sobald diese abgeschlossen ist.",
    "FAQ_PARTNER_CATEGORY_BILLING_ANS_9":"In C3 können wir E-Mail-Benachrichtigungen für solche Ereignisse konfigurieren. Im Falle einer erfolgreichen oder fehlgeschlagenen Zahlung schickt C3 eine entsprechende E-Mail. Alle Empfänger, die für solche Ereignisse konfiguriert sind, erhalten Benachrichtigungs-E-Mails.   Außerdem verfügt C3 über eine Funktion für Zahlungsausfälle in der Zusammenfassung der Einnahmen und Kosten, in der Partner Aufzeichnungen für jeden Zahlungsausfall verfolgen können.",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_1":"1. Welche Zahlungsmethoden werden von C3 unterstützt?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_10":"10. Erhält der Kunde eine Kopie der Rechnung als PDF, wenn die Rechnung fertig ist?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_11":"11. Was passiert, wenn ich eine Zahlung per Scheck oder Bargeld eingezogen habe und die Zahlungsmethode des Kunden Kreditkarte ist? Wie verhindere ich, dass C3 dem Kunden die aktuelle Rechnung in Rechnung stellt?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_12":"12. Wir haben unsere eigenen Abrechnungssysteme verwendet und unsere Kunden bis heute belastet. Unsere Kunden hatten unterschiedliche Abrechnungszyklen. Welche Unterstützung erhalten wir bei der Migration auf C3?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_13":"13. Gibt es eine Möglichkeit, Vorauszahlungen gegen eine Rechnung zu bearbeiten?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_14":"14. Welche Zahlungsgateways unterstützt C3 sofort?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_2":"2. Kann ich meinen vorhandenen Zahlungsgateway-Anbieter verwenden oder sollte ich mich für C3 entscheiden?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_3":"3. Was passiert, wenn sich die Preise von Microsoft ändern und wir unsere Preise für unsere Kunden aktualisieren möchten?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_4":"4. Wie geht C3 mit Steuern um?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_5":"5. Kann ich einen bestimmten Kunden von einer Steuerregel befreien, die für andere Kundengruppen gilt?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_6":"6. Wie definiere ich, wann C3 Rechnungen generieren soll?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_7":"7. Kann mein Kunde die Rechnungen direkt nach ihrer Erstellung einsehen?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_8":"8. Habe ich die Möglichkeit, Änderungen an den Positionen in der Rechnung vorzunehmen, nachdem diese generiert wurde? Kann ich einer Rechnung eine einmalige Gebühr hinzufügen/auf entsprechende Änderungen verzichten?",
    "FAQ_PARTNER_CATEGORY_BILLING_QUS_9":"9. Woher weiß ich (Partner), wann eine Zahlung erfolgreich war oder fehlgeschlagen ist?",
    "FAQ_PARTNER_CATEGORY_SELL":"Verkaufen",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_1":"Ein Plan ist eine Gruppe von Angeboten, die Sie zu einem Preis Ihrer Wahl an Ihren Kunden verkaufen können. Sie müssen einen Plan haben, um einen Kunden zu integrieren oder zu erstellen.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_2":"Ja, C3 bietet Ihnen die Möglichkeit, Partnerangebote zu erstellen. Sie möchten Ihren Kunden z.Bsweise rund um die Uhr Support bieten. Anschließend können Sie ein Partnerangebot rund um die Uhr für Office365-Support erstellen und zu einem Preis Ihrer Wahl verkaufen.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_3":"Ja, Sie können jeden bestehenden Kunden im Partner Center in C3 integrieren. Sie benötigen eine Microsoft-ID (vom Partner Center erhalten) und ein Angebot, das dem abonnierten Angebot auf dem PC entspricht. Abgesehen davon bietet Ihnen C3 auch die Möglichkeit, einen neuen Kunden direkt aus C3 heraus zu erstellen (Sie müssen zur Kundenerstellung nicht zum PC gehen).",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_4":"Um dies zu erreichen, müssen wir diesen Kunden mit jedem Plan einbeziehen. Sie können die Angebotsmenge auf Null setzen. Sobald der Kunde erfolgreich eingebunden ist, werden nutzungsbasierte Abonnements für diesen bestimmten Kunden automatisch synchronisiert.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_5":"In solchen Fällen besteht eine Mengenfehlanpassung zwischen PC und C3. Unser Team kann solche Fehlanpassungen beheben.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_6":"Wir können Anpassungen in Rechnungen vornehmen, aber C3 unterstützt derzeit keine Rückerstattungen.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_7":"Ja, C3 berechnet die Nutzung von Kunden und generiert basierend auf dem Datenverbrauch die Rechnung.",
    "FAQ_PARTNER_CATEGORY_SELL_ANS_8":"Ja, Sie können einen Rabattcoupon mit dem erforderlichen Rabattprozentsatz und den maximalen Wiederholungen auf 3 erstellen. Weisen Sie nun den neu erstellten Coupon einem bestimmten Kunden zu. Sobald dies erledigt ist, erhält der Kunde den entsprechenden Rabatt für die nächsten drei Rechnungen.",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_1":"1. Was ist ein Plan?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_2":"2. Kann ich nicht auf Microsoft basierende Angebote wie benutzerdefinierte Dienste verkaufen?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_3":"3. Kann ich einen bestehenden Kunden an Bord nehmen?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_4":"4. Wie kann ich einen Kunden an Bord nehmen, der nur nutzungsbasierte Abonnements hat?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_5":"5. Mein Team hat versehentlich Lizenzen über den PC gekauft. Welche Auswirkungen haben solche Aktionen? Gibt es ein Verfahren, um solche Unstimmigkeiten zu beheben?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_6":"6. Unterstützt C3 Rückerstattungen?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_7":"7. Übernimmt C3 die Azure-Abrechnung für die Nutzung durch meine Kunden?",
    "FAQ_PARTNER_CATEGORY_SELL_QUS_8":"8. Ich habe einen Kunden an Bord von C3 und möchte einen Rabatt für die nächsten drei Rechnungen gewähren. Kann ich das tun?",
    "FEATURE_COMMENTS_ORDER_NO":" Bestell-Nr.:",
    "FEBRUARY":"Februar",
    "FIELD_REQUIRED":"{{atribute}} ist erforderlich",
    "FILTER_SELECT_A_SOURCE_CURRENCY":"Wählen",
    "FILTER_SELECT_A_TARGET_CURRENCY":"Wählen",
    "FIX_AUTO_RENEW_STATUS_MISMATCH":"Behebung von Fehlern beim Status der automatischen Verlängerung",
    "FIX_AZURE_MISSING_OFFER":"Behebung eines fehlenden Azure-Angebots",
    "FIX_BILLING_CYCLE_MISMATCH":"Fehler im Abrechnungszyklus beheben",
    "FIX_MISSING_OFFER":"Fehlendes Angebot beheben",
    "FIX_OFFER_MISMATCH":"Fehlendes Angebot beheben",
    "FIX_QUANTITY_MISMATCH":"Mengeninkongruenz beheben",
    "FIX_SCOPE_MISMATCH":"Bereichskonflikt beheben",
    "FIX_STATUS_MISMATCH":"Statusabweichung beheben",
    "FIX_TERM_DURATION_MISMATCH":"Unstimmigkeit der Laufzeit beheben",
    "FOOTER_TEXT_1_ALL_RIGHTS_RESERVED":". Alle Rechte vorbehalten.",
    "FOOTER_TEXT_1_POWERED_BY":"Unterstützt von",
    "FROM":"Von",
    "General":"Allgemeines",
    "GENERAL_SETTINGS_MAIN_ERROR":"Bitte korrigieren Sie die Fehler im Formular, bevor Sie es absenden",
    "GENERATE_INVOICE_REASON":"Grund",
    "GENERATE_INVOICE_REASON_PLACEHOLDER":"Bitte geben Sie einen Grund an",
    "GENERATED_QUICKBOOKS_ACCESS_TOKENS_BY_AUTH_CODE":"Generierte Quickbooks-Zugriffstoken durch Authentifizierungscode, der von Quickbooks erhalten wurde",
    "GENERATED_XERO_ACCESS_TOKENS_BY_AUTH_CODE":"Generierte Xero-Zugriffstoken durch den von Xero erhaltenen Authentifizierungscode",
    "GET_BILLING_PROVIDER_DETAILS_EXCEPTION":"Beim Abrufen der Details des Abrechnungsanbieters ist ein Fehler aufgetreten.",
    "GET_CURRENCY_CODES_EXCEPTION":"Beim Abrufen der Cuurency-Codes ist ein Fehler aufgetreten",
    "GET_CURRENCY_CONVERSION_EXCEPTION":"Beim Abrufen der Währungsumrechnung ist ein Fehler aufgetreten",
    "GET_CURRENCY_CONVERSIONS_LIST_EXCEPTION":"Beim Abrufen der Währungsumrechnungsliste ist ein Fehler aufgetreten",
    "GET_CURRENCY_OPTIONS_EXCEPTION":"Beim Abrufen der Währungsoptionen ist ein Fehler aufgetreten",
    "GET_CUSTOMER_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Beim Abrufen der Kundenereignisse für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
    "GET_CUSTOMER_LIST_EXCEPTION":"Beim Abrufen der Kunden ist ein Fehler aufgetreten",
    "GET_CUSTOMER_PLANS_WITH_PROMOTION_ACTIVE_FOR_PRODUCTVARIANT_AND_BILLINGCYCLE_ERROR_DESC":"Beim Abrufen der Kundenpläne mit aktiver Aktion ist ein Fehler aufgetreten",
    "GET_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Beim Abrufen der Empfängerliste für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
    "GET_EVENTS_FOR_EMAIL_NOTIFICATIONS_EXCEPTION":"Beim Abrufen der Ereignisse für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
    "GET_PARTNER_LOGO_EXCEPTION":"Beim Abrufen des Partnerlogos ist ein Fehler aufgetreten",
    "GET_PARTNER_SETTINGS_EXCEPTION":"Beim Abrufen der Partnereinstellungen ist ein Fehler aufgetreten",
    "GET_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Abrufen der Steuerdetails ist ein Fehler aufgetreten",
    "GET_PARTNER_TAX_TYPES_EXCEPTION":"Beim Abrufen der Steuertypen ist ein Fehler aufgetreten",
    "GET_TARGET_CURRENCIES_EXCEPTION":"Beim Abrufen der Zielwährungen ist ein Fehler aufgetreten",
    "GETTING_RELLER_RELATIONSHIP_REQUEST_ERROR_DESC":"Beim Abrufen der Anfrage für eine Wiederverkäuferbeziehung ist ein Fehler aufgetreten.",
    "GOT_RESELLER_DETAILS":"Erhielt den Reseller von CSP mit dem Namen: {{resellerName}}",
    "government":"Regierung",
    "Government":"Regierung",
    "GRAND_ACCESS_USER_EXCEPTION":"Diese verwendet haben bereits Zugriff bitte mit einem anderen überprüfen",
    "GRAND_TOTAL":"Gesamtsumme",
    "GRAPH_CONSUMPTION_BY_PRODUCT_TITLE":"Verbrauch nach Produkt",
    "GRAPH_CUSTOMERS_ONBOARDED":"Kunden an Bord",
    "GRAPH_DATA_BILLED_AMOUNT":"Abgerechneter Betrag",
    "GRAPH_DATA_QUANTITY":"Menge",
    "GRAPH_REVENUE_BY_CUSTOMER_TITLE":"Umsatz nach Kunden",
    "GRAPH_TOP_SELLING_PRODUCT":"Die 10 meistverkauften Produkte",
    "GREATER_THAN_ERP":"größer als ERP",
    "GRID_DOWNLOAD_COUPON_GRID":"Gutscheine herunterladen",
    "GROUP_ADMIN_ERROR_MESSAGE_ADMIN_ALREADY_EXISTS":"In dieser Gruppe ist bereits ein Gruppenadministrator mit dieser E-Mail vorhanden",
    "GROUP_USERS_OF":"Gruppenbenutzer von",
    "GROUP_USERS_TOOLTIP_AZUREGROUP":"Benutzer gruppieren",
    "HEADER_CONTENT_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Zeigen Sie die Anwendung in einer von der Anwendung unterstützten Sprache an",
    "HEADER_CONTENT_HELP_MENU":"Kontextsensitive Hilfeinhalte in der rechten Seitenleiste",
    "HEADER_CONTENT_MENU_CONTACT_LOG":"Ermöglicht dem Partner, den Status aller Benachrichtigungen und den Inhalt der E-Mail anzuzeigen.",
    "HEADER_CONTENT_MENU_PARTNER_AUDIT_LOG":"Verfolgen Sie die Benutzeraktivität.",
    "HEADER_CONTENT_MENU_RESELLER_PROFILE":"Grundlegendes Profildetail des angemeldeten Benutzers",
    "HEADER_CONTENT_MENU_TOPBAR_SETTINGS":"Verwalten Sie Ihre C3-Kontoeinstellungen und greifen Sie auf Aktivitätsprotokolle zu.",
    "HEADER_CONTENT_MENUS_PARTNER_SETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
    "HEADER_CONTENT_MENUS_RESELLER_SETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
    "HEADER_CONTENT_MENUSPARTNERSETTINGS":"Ermöglicht das Verwalten von Einstellungen auf Anwendungsebene, E-Mail-Benachrichtigungen, Definieren von Steuern und mehr...",
    "HEADER_CONTENT_SIGNIN_USER":"Grundlegendes Profildetail des angemeldeten Benutzers",
    "HEADER_CUSTOMER_VIEW_BUTTON_CONTAVT_US":"Kontaktiere uns",
    "HEADER_CUSTOMER_VIEW_BUTTON_LOG_OUT":"Ausloggen",
    "HEADER_CUSTOMER_VIEW_TEXT_USER_NOT_AUTHORIZED":"Benutzer nicht autorisiert",
    "HEADER_GO_TO_PROFILE":"Zum Profil gehen",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_CONTACT_US":"Kontaktiere uns",
    "HEADER_IMPERSONATOR_VIEW_BUTTON_STOP_IMPERSONATION":"Stoppen Sie den Identitätswechsel",
    "HEADER_IMPERSONATOR_VIEW_TEXT_USER_NOT_AUTHORIZED":"Benutzer nicht autorisiert",
    "HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Sprache",
    "HEADER_TITLE_HEADER_LANGUAGE_TRANSLATION_DROP_DOWN_LABEL_TEXT_LANGUAGE":"Sprache",
    "HEADER_TITLE_HELP_MENU":"Hilfe",
    "HEADER_TITLE_MENU_CONTACT_LOG":"Benachrichtigungen",
    "HEADER_TITLE_MENU_PARTNER_AUDIT_LOG":"Audit-Log",
    "HEADER_TITLE_MENU_RESELLER_PROFILE":"Profil",
    "HEADER_TITLE_MENU_TOPBAR_SETTINGS":"die Einstellungen",
    "HEADER_TITLE_MENUS_PARTNER_SETTINGS":"die Einstellungen",
    "HEADER_TITLE_MENUS_RESELLER_SETTINGS":"die Einstellungen",
    "HEADER_TITLE_SIGNIN_USER":"Benutzer angemeldet",
    "HEADING_ENTITY_LIST":"{{EntityName}}-Liste",
    "HELP_TEXT_SMTP_TEST_USERNAME":"Geben Sie die E-Mail-Adresse an, um die Test-E-Mail zu erhalten.",
    "HIGH":"Hign",
    "HOURS":"Stunde(n)",
    "HYPERLINK_POPUP_TEXT_FOR_PROVIDING_CONSENT":"Hier",
    "I have read and agreed to":"Ich habe gelesen und stimme zu",
    "IF_ALREADY_EXISTS":"ist bereits vorhanden",
    "IF_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER_DESC":"Dieser Wert entscheidet, ob die Suchfilter im öffentlichen Registrierungs-Shop-Bildschirm aktiviert/deaktiviert werden sollen.",
    "IGNORE_FROM_PSA_TEXT_DISP":"Kunden aus dem externen Service ignorieren",
    "IMPERSONATE_WITH_CURRENT_PRIVILEGE":"Zusammen mit Ihrem aktuellen Privileg",
    "IMPERSONATE_WITHOUT_CURRENT_PRIVILEGE":"Ohne dein aktuelles Privileg",
    "IMPORT_LIST":"Liste importieren",
    "In Progress":"In Bearbeitung",
    "IN_ACTIVE":"Inaktiv",
    "INDEX_LOGOUT_APOLOGY_MESSAGE":"Dieses Programm war viel zu lange untätig. Es tut uns leid, dass wir Sie abgemeldet haben, aber wir hatten keine andere Wahl.",
    "INDEX_LOGOUT_MESSAGE":"Entschuldigung, Sie wurden abgemeldet",
    "INDEX_MSG_LOGOUT_SOON_MESSAGE":"Der Idle-Modus ist aktiviert, da man viel zu lange untätig ist. Das hat zur Folge, dass Sie sich nach wenigen Augenblicken abmelden.",
    "INDXE_LOGOUT_TEXT":"SCHNELL ETWAS TUN! Sie werden in {{countdown}} Sekunde(n) abgemeldet.",
    "INFORMATION_TEXT_NO_CURRENCIES_AVAILABLE_ON_RECORD":"Es sind keine Währungen registriert",
    "INFORMATION_TEXT_NO_STATES_AVAILABLE_ON_RECORD":"Es sind keine Zustände registriert",
    "INFORMATION_TEXT_PLEASE_SELECT_A_COUNTRY":"Bitte wähle ein Land",
    "INFORMATION_TEXT_PLEASE_SELECT_A_SOURCE_CURRENCY":"Bitte wählen Sie zuerst eine Quellwährung aus",
    "INITIATE_INVOICE_PAYMENT_CONFIRMATION":"Sind Sie sicher, dass Sie die Rechnungsbelastung für <%= currencySymbol%><%= invoiceAmount%> mit der Zahlungsmethode einleiten möchten: <br> <b><%= PaymentType%> | <%= AccountNumber%></b> ? <br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder irrtümlich erfasst wurden.</small>",
    "INITIATE_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Es ist kein ausstehender Betrag zu zahlen.",
    "INITIATE_PENDING_INVOICE_PAYMENT_CONFIRMATION":"Alle ausgewählten Rechnungen werden für Paymnets in die Warteschlange gestellt. Wollen Sie fortfahren?",
    "INITIATE_STATUS_UPDATE_CONFIRMATION":"Der Rechnungsstatus ändert sich in <%=invoiceStatus%>. Wollen Sie fortfahren?",
    "INITIATE_TEST_PAYMENT_CONFIRMATION":"Dem Kunden werden $<%= amount%> berechnet. Wollen Sie fortfahren?",
    "INITIATE_TEST_PAYMENT_FOR_RESELLER_CONFIRMATION":"Dem Reseller werden $<%= amount%> berechnet. Wollen Sie fortfahren?",
    "INPUT_BILLINGPERIODID":"BillingPeriodId",
    "INPUT_BILLINGPERIODTYPE":"BillingPeriodType",
    "INPUT_CATEGORYID":"Kategorie ID",
    "INPUT_CHANGE_AUTO_RENEW_STATUS":"Status der automatischen Verlängerung",
    "INPUT_CHANGE_TYPE":"Typ ändern",
    "INPUT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
    "INPUT_CUSTOMERC3ID":"CustomerC3Id",
    "INPUT_CUSTOMERID":"Kundennummer",
    "INPUT_CUSTOMERNAME":"Kundenname",
    "INPUT_DATA":"Benutzereingabe",
    "INPUT_DESCRIPTION":"Beschreibung",
    "INPUT_ENDIND":"EndInd",
    "INPUT_INTERNALPRODUCTID":"InternalProductId",
    "INPUT_INVOICEID":"InvoiceId",
    "INPUT_LABEL_PURCHASE_QUANTITY":"Kaufmenge",
    "INPUT_NAME":"Name",
    "INPUT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
    "INPUT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
    "INPUT_PAGECOUNT":"Seitenzahl",
    "INPUT_PAGEINDEX":"PageIndex",
    "INPUT_PAGESIZE":"Seitengröße",
    "INPUT_PLANC3ID":"Plaid",
    "INPUT_PLANPRODUCTID":"PlanProductId",
    "INPUT_PRICEFORPARTNER":"Preis für Partner",
    "INPUT_PRODUCTID":"Produkt ID",
    "INPUT_PROVIDERID":"ProviderId",
    "INPUT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
    "INPUT_QUANTITY":"Menge",
    "INPUT_REACTIVATE":"Reaktivieren",
    "INPUT_SALETYPE":"SaleType",
    "INPUT_STARTIND":"StartInd",
    "INPUT_SUSPEND":"Aussetzen",
    "INPUT_TENANTID":"TenantId",
    "INPUT_WITHADDONS":"WithAddons",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_CART":"Zurück zum Warenkorb",
    "INSTANT_CHARGE_BUTTON_TEXT_BACK_TO_ORDERS":"Zurück zu Bestellungen",
    "INSTANT_CHARGE_PAYMENT_PROFILE_PORTLET_HEADER":"Zahlungsprofil",
    "INSTANT_PAY_BUTTON_TEXT_PAY":"Zahlen",
    "INSTANT_PAY_PAYMENT_GATEWAY_NOT_SUPPORTED":"Das aktuelle Zahlungsgateway wird noch nicht mit Instant Pay unterstützt. Bitte wenden Sie sich an den Support, um Unterstützung zu erhalten.",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_PAYMENT_METHOD":"Bezahlverfahren",
    "INSTANT_PAY_PROFILE_PAYMENT_HEADER_TEXT_SECURE_PAYMENT":"Sichere Bezahlung",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT1":"Laden Sie das kartierte Blatt für die Kartierung im CSV-Format hoch. Laden Sie die Datei <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Hochladen herunter.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT2":"Diese Datei wird verwendet, um die Entitäten zwischen C3 und AutoTask Manage abzubilden. Löschen Sie keine vorhandenen Texte oder Daten in dieser Datei.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT3":"Die einzigen Felder, die aktualisiert werden müssen, sind „AutoTaskCustomerId, AutoTaskCustomerName, ContractId, ContractName, ContractServiceId und ContractServiceName“. Die restlichen Felder dienen dazu, die C3-Gegenstücke für die Zuordnung zu identifizieren.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT4":"Wenn die oben genannten Felder im Blatt ausgefüllt sind, ist die Zuordnung verfügbar. Wenn die oben genannten Felder leer sind, ist eine Zuordnung erforderlich.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT5":"Zum Ausfüllen des Zuordnungsblatts können Sie sich auf <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">AutoTask Entity Sheet.xlsx.</a> beziehen",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT6":"Name und ganzzahlige (numerische) ID, die zum Ausfüllen des <b>Zuordnungsblatts</b> erforderlich sind, sind im <b>AutoTask-Entitätsblatt</b> verfügbar",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT7":"Das Entfernen der Zuordnung ist über das Zuordnungsblatt nicht möglich. Um die Zuordnung von Vereinbarung und Produkt zu ändern, verwenden Sie bitte das Zuordnungsblatt. Eine Neuzuordnung von Kunden ist nur über den Bildschirm zur Zuordnung gekaufter Produkte möglich.",
    "INSTRUNCTION_PSA_AUTO_TASK_BULK_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
    "INSTRUNCTION_PSA_BULK_BUTTON":"Anweisungen zum Massen-Upload der Zuordnung gekaufter Produkte:",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT1":"Laden Sie das kartierte Blatt für die Kartierung im CSV-Format hoch. Laden Sie die Datei <a href=\"#\" ng-click=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Hochladen herunter.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT2":"Diese Datei wird verwendet, um die Entitäten zwischen C3 und ConnectWise Manage abzubilden. Löschen Sie keine vorhandenen Texte oder Daten in dieser Datei.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT3":"Die einzigen Felder, die aktualisiert werden müssen, sind „ConnectwiseCustomerId, ConnectwiseCustomerName, AgreementId, AgreementName, ConnectwiseProductId und ConnectwiseProductname“. Die restlichen Felder dienen dazu, die C3-Gegenstücke für die Zuordnung zu identifizieren.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT4":"Wenn die oben genannten Felder im Blatt ausgefüllt sind, ist die Zuordnung verfügbar. Wenn die oben genannten Felder leer sind, ist eine Zuordnung erforderlich.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT5":"Zum Ausfüllen des Zuordnungsblatts können Sie sich auf <a href=\"#\" ng-click=\"vm.onDownloadCSVHelper()\" class=\"ng-isolate-scope\">Connectwise Entity Sheet.xlsx.</a> beziehen",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT6":"Name und ganzzahlige (numerische) ID, die zum Ausfüllen des <b>Zuordnungsblatts</b> erforderlich sind, sind im <b>Connectwise-Entitätsblatt</b> verfügbar",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT7":"Das Entfernen der Zuordnung ist über das Zuordnungsblatt nicht möglich. Um die Zuordnung von Vereinbarung und Produkt zu ändern, verwenden Sie bitte das Zuordnungsblatt. Eine Neuzuordnung von Kunden ist nur über den Bildschirm zur Zuordnung gekaufter Produkte möglich.",
    "INSTRUNCTION_PSA_BULK_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT1":"Um die Zuordnung zwischen C3- und ConnectWise Manage-Produkten zu erleichtern, laden Sie das Zuordnungsblatt im CSV-Format hoch. Sie können die Datei <a href=\"javascript:void(0)\" (click)=\"vm.onDownloadCSV()\" class=\"ng-isolate-scope\">Mapping Sheet.csv</a> zum Aktualisieren herunterladen.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT2":"Die Datei dient zum Erstellen von Produktzuordnungen zwischen C3 und ConnectWise Manage. Achten Sie darauf, keinen vorhandenen Text oder keine vorhandenen Daten in der Datei zu löschen.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT3":"Nur die Felder ConnectwiseProductId und ConnectwiseProductname müssen aktualisiert werden. Andere Felder helfen bei der Identifizierung von C3-Gegenstücken für Zuordnungszwecke.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT4":"Wenn die genannten Felder ausgefüllt sind, ist das Mapping abgeschlossen. Wenn sie leer sind, ist ein Mapping notwendig.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT5":"Anleitungen zum Ausfüllen des Zuordnungsblatts finden Sie im <a id='dynamicHtmlConnectwiseBulkPoint5' href=\"javascript:void(0)\" (click)=\"onDownloadCSVHelper()\" >Connectwise Entity Sheet.xlsx.</a>. Der erforderliche Name und die ganzzahligen (numerischen) IDs sind im ConnectWise Entity Sheet verfügbar.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT6":"Bitte beachten Sie, dass das Entfernen von Zuordnungen nicht über das Zuordnungsblatt möglich ist. Um Produktzuordnungen zu ändern, verwenden Sie das bereitgestellte Zuordnungsblatt.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT7":"Laden Sie die Datei hoch und klicken Sie auf SENDEN, um den Vorgang abzuschließen.",
    "INSTRUNCTION_PSA_BULK_PRODUCT_MAPPING_BUTTON_POINT8":"Laden Sie die Datei hoch und klicken Sie auf ABSENDEN.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON":"Anweisungen zur Verwendung der Schaltfläche „Aktualisieren“:",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT1":"Klicken Sie auf die Schaltfläche „Aktualisieren“, um die neuesten Daten von PSA bis C3 zu erhalten.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT2":"Standardmäßig sind in der Produktzuordnung zunächst keine Daten verfügbar. Klicken Sie auf die Schaltfläche „Aktualisieren“, um alle verfügbaren Daten von PSA bis C3 zu erhalten.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT3":"Wenn „Aktualisieren“ ausgeführt wird, deaktiviert das System andere Registerkarten. Bei Erfolg werden deaktivierte Tabs wieder sichtbar sein. Im Fehlerfall bietet dieser Bildschirm die Möglichkeit, den Fehler zu ignorieren. Sie können auf die Schaltfläche „Fehler ignorieren“ klicken, um die deaktivierten Registerkarten zu aktivieren.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT4":"Wenn „Aktualisieren“ ausgeführt wird, löscht das System vorhandene PSA-Daten aus C3 und fügt neue Daten aus PSA ein.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT5":"Wenn in PSA Änderungen vorgenommen werden, beispielsweise das Hinzufügen oder Entfernen von Unternehmen, Vereinbarungen, Produkten oder Statusänderungen für diese Entitäten, muss der Benutzer die Produktzuordnung aktualisieren, um diese Änderungen in C3 widerzuspiegeln.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT6":"<span> Bitte klicken Sie auf die Schaltfläche </span> <a class=\"btn btn-outline-primary btn-xs mr-2\" ng-click=\"vm.onRefresh()\">Aktualisieren</a>, um die neuesten Daten zu erhalten von PSA bis C3.",
    "INSTRUNCTION_PSA_REFRESH_BUTTON_POINT7":" Schaltfläche, um die neuesten Daten von PSA an C3 zu erhalten.",
    "INTERNAL_BILLING_ID":"Rechnungsreferenz-ID:",
    "INTERNAL_SCHEDULE_RENEWAL_ID":"Interne geplante Verlängerungs-ID",
    "INTERNAL_SCHEDULED_RENEWAL_ID":"Interne geplante Verlängerungs-ID",
    "INVALID_ATTEMPT_CHANGE_AUTO_RENEW_STATUS":"Statusänderungen bei automatischer Verlängerung sind nicht zulässig",
    "INVALID_ATTEMPT_DECREASE":"Eine Verringerung der Menge ist nicht zulässig",
    "INVALID_ATTEMPT_REACTIVATE":"Eine erneute Aktivierung des Produkts ist nicht zulässig",
    "INVALID_ATTEMPT_SUSPEND":"Das Aussetzen des Produkts ist nicht zulässig",
    "INVALID_COUNT":"Die Anzahl der Sitzplätze darf nicht kleiner als 1 sein",
    "INVALID_COUPON":"Dieser Gutschein ist nicht gültig",
    "INVALID_EXPIRY_DATE":"Ungültiges Ablaufdatum",
    "INVALID_INPUT":"Ungültige Eingabe",
    "INVALID_MESSAGE_ENTITY":"Ungültige Nachrichtenentität",
    "INVALID_MESSAGE_TYPE":"Ungültiger Nachrichtentyp",
    "INVALID_PORTAL_PAGE":"Ungültige Portalseite",
    "INVALID_PRODUCT_ID":"Ein Produkt mit der angegebenen Kennung wurde im System nicht gefunden.",
    "INVALID_QUANTITY":"Ungültige Menge",
    "INVALID_RECIPIENTS":"Ungültige Empfänger",
    "INVALID_SEATS":"Fehlende oder ungültige Details. Plätze sollten entweder eine positive oder eine negative Zahl sein, die durch die Einschränkungen des Anbieters begrenzt ist",
    "INVALID_VALUE":"{{atribute}} ist ungültig",
    "INVITES_ADD_BUTTON_BACK_TO_INVITES":"Zurück zu Einladungen",
    "INVITES_ADD_CAPTION_TEXT_ADD_INVITE":"Einladung hinzufügen",
    "INVITES_ADD_CAPTION_TEXT_EDIT_INVITE":"Einladen bearbeiten",
    "INVITES_ADD_FORM_BUTTON_CLEAR":"Alles löschen",
    "INVITES_ADD_FORM_DROPDOEN_LABEL_PLAN":"Planen",
    "INVITES_ADD_FORM_LABEL_ITEM_SELECTED":"Ausgewählte Elemente",
    "INVITES_ADD_FORM_LABEL_OFFER":"Bietet an",
    "INVITES_ADD_FORM_TABLE_HEADER_OFFER_NAME":"Angebotsname",
    "INVITES_ADD_FORM_TABLE_HEADER_PRICE":"Preis",
    "INVITES_ADD_FORM_TEXTBOX__LABEL_DESCRIPTION":"Beschreibung",
    "INVITES_ADD_FORM_TEXTBOX_1_LABEL_NAME":"Name",
    "INVITES_ADD_FORM_URL_INVITE_URL":"URL einladen",
    "INVITES_BREADCRUMB_BUTTON_TEXT_INVITES":"Lädt ein",
    "INVITES_LIST_BUTTON_ADD_NEW_INVITATION":"Neue Einladung hinzufügen",
    "INVITES_LIST_CAPTION_TEXT_INVITES":"Lädt ein",
    "INVITES_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
    "INVITES_LIST_TABLE_HEADER_ACTIONS_BUTTON_EDIT":"Bearbeiten",
    "INVITES_LIST_TABLE_HEADER_INVITATION_DESCRIPTION":"Beschreibung der Einladung",
    "INVITES_LIST_TABLE_HEADER_INVITATION_NAME":"Name der Einladung",
    "INVITES_LIST_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
    "INVITES_LIST_TABLE_HEADER_PLAN_NAME":"Plan Name",
    "INVITES_SAVE_SUCCESS":"Einladung erfolgreich gespeichert",
    "INVITES_SELECT_OFFERS_PROMPT":"Bitte fügen Sie der Einladung mindestens ein Angebot bei",
    "INVITES_UPDATE_SUCCESS":"Einladung erfolgreich aktualisiert",
    "INVOICE_ADD_ADJUSTMENT_END_DATE":"Endtermin",
    "INVOICE_ADD_ADJUSTMENT_START_DATE":"Anfangsdatum",
    "INVOICE_ADJUSTMENT_CONFIRMATION":"Möchten Sie dies als Anpassung in dieser Rechnung?",
    "INVOICE_AND_DUES_REPORT_SEND_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Liste der Rechnungen und Gebührenberichte zu erhalten.",
    "INVOICE_BREADCRUMB_BUTTON_1_DASHBOARD":"Dashboard",
    "INVOICE_BREADCRUMB_BUTTON_2_INVOICES":"Rechnungen",
    "INVOICE_COMMENTS":"Rechnung",
    "INVOICE_COUPON_APPLIED_MESSAGE":"Der Rabattcoupon <strong> {{CouponCode}}*</strong> wird angewendet",
    "INVOICE_DETAILS_TABLE_COLUMN_BALANCE_DUE":"Restbetrag fällig",
    "INVOICE_DOWNLOAD_SELECT_ALL":"Wählen Sie Alle",
    "INVOICE_EMAIL_POST_SUCCESS_MESSAGE":"E-Mail wird erfolgreich an die Benutzerliste gesendet",
    "INVOICE_FOOTER_LINE_DESC":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
    "INVOICE_FOOTER_LINE_DESC_FOR_RESELLER":"Erscheint in der Rechnungsansicht und im Rechnungs-PDF als Fußzeile",
    "INVOICE_FOOTER_LINE_DISP":"Rechnungsfußzeile",
    "INVOICE_FOOTER_LINE_DISP_FOR_RESELLER":"Rechnungsfußzeile",
    "INVOICE_GENERATION_REASON":"Grund der Rechnungserstellung",
    "INVOICE_GENERATION_STATUS_TITLE":"Rechnungserstellungsstatus",
    "INVOICE_GENERATION_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Gehen Sie zu Umsatz- und Kostenübersicht",
    "INVOICE_IMPORT_VIEW_BREADCRUM_HEADER_DOWNLOAD_INVOICE":"Laden Sie Rechnungen/Zahlungen herunter",
    "INVOICE_IMPORT_VIEW_BUTTON_BACK_TO_REVENUE_AND_COST_SUMMERY":"Zurück zur Umsatz- und Kostenübersicht",
    "INVOICE_IMPORT_VIEW_HEADER_DOWNLOAD_INVOICE":"Laden Sie Rechnungen/Zahlungen herunter",
    "INVOICE_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Kundenrechnungen erfolgreich importiert",
    "INVOICE_IMPORT_VIEW_MESSAGE_SELECT_CUSTOMER":"Bitte wählen Sie mindestens eine Kundenrechnung aus, bevor Sie fortfahren.",
    "INVOICE_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Rechnungen, die für die Kunden heruntergeladen werden sollen",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_CURRECNY_CODE":"Währungscode",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_MICROSOFT_ID":"MicrosoftId",
    "INVOICE_IMPORT_VIEW_TABLE_COLUMN_HEADER_QUALIFIED_NAME":"Qualifizierter Name",
    "INVOICE_INVOICE_APPROVE":"Genehmigen",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICELINEITEMS":"Zurück zu Rechnungsposten",
    "INVOICE_INVOICE_BUTTON_BACK_TO_INVOICES":"Zurück zu Rechnungen",
    "INVOICE_INVOICE_BUTTON_DOWNLOAD_INVOICE":"Download Rechnung",
    "INVOICE_INVOICE_CAPTION_TEXT":"Rechnung für",
    "INVOICE_INVOICE_FINALIZE":"Finalisieren",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY":"Kontoübersicht",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_ADDRESS":"Adresse",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_CUSTOMER":"Kunde",
    "INVOICE_INVOICE_HEADER_TEXT_ACCOUNT_SUMMARY_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY":"Rechnungszusammenfassung",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_BILLING_PERIOD":"Abrechnungszeitraum",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DATE":"Rechnungsdatum",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "INVOICE_INVOICE_HEADER_TEXT_INVOICE_SUMMERY_TABLE_HEADER_VAT_NUMBER":"Umsatzsteuer-Identifikationsnummer",
    "INVOICE_INVOICE_HEADER_TEXT_TAX_INVOICE":"Steuerrechnung",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_INVOICE_DETAILS":"Rechnungsdetail",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_PREVIOUS_INVOICE_DUES":"Rechnungsgebühren",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_HEADER_TEXT_REMAINING_PREVIOUS_AMOUNT":"Saldo aus vergangenen Zahlungen",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_LICENSE_BASED":"Lizenzbasiert",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_SUB_TABLE_HEADER_USAGE_BASED":"Nutzungsabhängig",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_AMOUNT":"Menge",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_CHARGE_TYPE":"Ladungsart",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COST_ON_PARTNER":"Kosten für Partner",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_COUPON_CODE":"Gutscheincode",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DESCRIPTION":"Beschreibung",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT":"Rabatt",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_DISCOUNT_AMOUNT":"Rabattbetrag",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_PRICE":"Angebotspreis",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_EFFECTIVE_QUANTITY":"Menge",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_END_DATE":"Endtermin",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_GRAND_TOTAL":"Gesamtsumme",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_INVOICE_LINE_AMOUNT":"Rechnungspostenbetrag",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PARTICULARS":"Einzelheiten",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_POST_TAX_AMOUNT":"Betrag nach Steuern",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRE_TAX_AMOUNT":"Zwischensummenbetrag",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_PROVIDER_TENANT":"Mieter",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_START_DATE":"Anfangsdatum",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_AMOUNT":"Steuerbetrag",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_TAX_PERCENTAGE":"Steuerprozentsatz",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_HEADER_UNIT_PRICE":"Stückpreis",
    "INVOICE_INVOICE_INVOICE_LINE_ITEMS_TABLE_NOT_FOUND_MESSAGE":"Keine Rechnungen gefunden. Bitte wenden Sie sich an den c3-Support.",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_HEADER_TEXT":"Rechnungszahlungen",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_AMOUNT":"Menge",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_DATE":"Datum",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Fehlergrund",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_GRAND_TOTAL":"Gesamtsumme",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_REMARK":"Bemerkungen",
    "INVOICE_INVOICE_INVOICE_PAYMENTS_TABLE_HEADER_STATUS":"Status",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_AMOUNT":"Fälliger Betrag",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_DATE":"Rechnungsdatum",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "INVOICE_INVOICE_INVOICE_PREVIOUS_INVOICE_DUES_TABLE_HEADER_INVOICE_STATUS":"Status",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_PAID_AMOUNT":"Bezahlte Menge",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_REMAINING_AMOUNT":"Restbetrag",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
    "INVOICE_INVOICE_INVOICE_REMAINING_PAYMENTS_TABLE_HEADER_USED_AMOUNT":"Verwendeter Betrag",
    "INVOICE_LINE_ITEM_TYPE_ADJUSTMENTS":"Anpassungen",
    "INVOICE_LINE_ITEM_TYPE_CYCLE_INSTANCE_PRORATE":"Cycle Instance Prorate",
    "INVOICE_LINE_ITEM_TYPE_PAST_INVOICES_DUES":"Frühere Rechnungsgebühren",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEE":"Prorate Gebühr",
    "INVOICE_LINE_ITEM_TYPE_PRORATE_FEES_WHEN_PURCHASE":"Anteilige Gebühren beim Kauf",
    "INVOICE_LINE_ITEM_TYPE_PURCHASE_FEE":"Kaufgebühr",
    "INVOICE_LINE_ITEM_TYPE_REFUND_AMOUNT":"Rückerstattungsbetrag",
    "INVOICE_LINE_ITEM_TYPE_RENEWAL_FEE":"Verlängerungsgebühr",
    "INVOICE_LINE_ITEM_TYPE_TAXES":"Steuern",
    "INVOICE_LINE_ITEM_TYPE_USAGE_AMOUNT":"Nutzungsbetrag",
    "INVOICE_LINE_ITEMS_LABEL_CREDIT":"Anerkennung",
    "INVOICE_LINE_ITEMS_LBL_LINKED_TO":"--verknüpft mit {{linkedProductName}}",
    "INVOICE_LINE_ITEMS_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
    "INVOICE_LINE_ITEMS_LBL_PROVIDER_TENANT_NAME":"<b>{{providerName}} Mandant </b>{{providerTenantName}}",
    "INVOICE_LINE_ITEMS_TABLE_HEADER_CONVERSION_RATE":"Wechselkurs",
    "INVOICE_LINE_ITEMS_TEXT_SUBTOTAL":"Zwischensumme",
    "INVOICE_LINE_ITEMS_WITH_TAX_REPORT":"Rechnungsposten mit Steuern",
    "INVOICE_LINEITEMS_LABEL_PROMOTION_DISCOUNT":"Aktionsrabatt",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ACTIONS":"Aktionen",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_EMAIL":"Email",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_HEADER":"Bericht über Rechnungseinzelposten",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Rechnungseinzelpostenbericht zu erhalten",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf „Senden“, um eine Datei mit einer Liste des Rechnungspostenberichts zu erhalten",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_INSTRUCTION_UPDATED":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Liste der Rechnungspostenberichte zu erhalten.",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Stornieren",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_FAVOURATES_BUTTON_TEXT":"Favoriten speichern",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"E-Mail senden",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XLSX_FORMAT":"XLSX",
    "INVOICE_LINEITEMS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "INVOICE_LIST_CAPTION_TEXT_INVOICES":"Rechnungen",
    "INVOICE_LIST_HEADER_TEXT_TRANSACTIONS":"Zahlungen",
    "INVOICE_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
    "INVOICE_LIST_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_GENERATED_DATE":"Rechnung generiert am",
    "INVOICE_LIST_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "INVOICE_LIST_TABLE_HEADER_PAYMENT_FAILURE_REASON":"Fehlergrund",
    "INVOICE_LIST_TABLE_HEADER_REMAINING_AMOUNT":"Restbetrag",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_AMOUNT":"Bezahlte Menge",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_DATE":"Zahlungsdatum",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_ID":"Transaktions-ID",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_REMARKS":"Bemerkungen",
    "INVOICE_LIST_TABLE_HEADER_TRANSACTION_STATUS":"Zahlungsstatus",
    "INVOICE_LIST_TABLE_HEADER_USED_AMOUNT":"Verwendeter Betrag",
    "INVOICE_LIST_TABLE_TOOLTIPS_VIEW_EMAIL":"Kontaktprotokolle anzeigen",
    "INVOICE_NUMBER":"Rechnungsnummer",
    "INVOICE_PAYMENTS_IMPORT_VIEW_MESSAGE_INVOICE_IMPORTING_SUCCESS":"Kunden stellen Zahlungen für den erfolgreichen Import in Rechnung",
    "INVOICE_PAYMENTS_IMPORT_VIEW_PORTLET_HEADER_DOWNLOAD_INVOICE":"Rechnungszahlungen müssen für die Kunden heruntergeladen werden",
    "INVOICE_PAYMENTS_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
    "INVOICE_PROPERTIED_UPDATED_DUE_DATE_NULL_ERROR":"Das Fälligkeitsdatum kann nicht mit einem Nullwert aktualisiert werden",
    "INVOICE_PROPERTIED_UPDATED_SUCCESS_MESSAGE":"Rechnungseigenschaft erfolgreich aktualisiert",
    "INVOICE_SAVE_ADJUSTMENT_DATE_ERROR":"Das Startdatum darf nicht größer als das Enddatum sein",
    "INVOICE_SENT_SUCCESSFULLY":"Rechnung erfolgreich gesendet",
    "INVOICE_STATUS_NAME_APPROVED_FOR_CUSTOMER_REVIEW":"Zur Kundenbewertung zugelassen",
    "INVOICE_STATUS_NAME_FINALIZED_THE_INVOICE":"Die Rechnung wurde fertiggestellt",
    "INVOICE_STATUS_NAME_IN_PROGRESS":"In Bearbeitung",
    "INVOICE_STATUS_NAME_INVOICE_GENERATED":"Rechnung generiert",
    "INVOICE_STATUS_NAME_INVOICE_PUBLISHED":"Rechnung veröffentlicht",
    "INVOICE_STATUS_NAME_PAID_FULL_AMOUNT":"Voller Betrag bezahlt",
    "INVOICE_STATUS_NAME_PAID_PARTIAL_AMOUNT":"Teilbetrag bezahlt",
    "INVOICE_TAB_NAME_COMMENTS":"Bemerkungen",
    "INVOICE_TAB_NAME_DETAILS":"Einzelheiten",
    "INVOICE_TAB_NAME_NOTIFICATIONS":"Benachrichtigungen",
    "INVOICE_TRANSACTION_REPORT_HEADER":"Rechnungen und Gebührenbericht",
    "INVOICE_TYPE_A_COMMENT_INPUT_PLACEHOLDER_TEXT":"Geben Sie eine Nachricht ein",
    "INVOICE_WILLBE_MAILED":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Danke dir.",
    "InvoiceCurrency":"Rechnungswährung",
    "INVOICES_LBL_OWNED_BY":"<b>Im Besitz von </b>{{ownedBy}}",
    "INVOICES_TABLE_HEADER_BILLING_PERIOD":"Abrechnungszeitraum",
    "INVOICES_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
    "INVOICES_TABLE_HEADER_INVOICE_AMOUNT":"Rechnungsbetrag",
    "INVOICES_TABLE_HEADER_INVOICE_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "INVOICES_TABLE_HEADER_INVOICE_GENERATED_DATE":"Rechnungserstellungsdatum",
    "INVOICES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "IS_MANAGEABLE_ONLY_BY_PARTNER":"Ist nur vom Partner verwaltbar",
    "IS_MASTER":"Meister",
    "IS_PARTNER_APPROVAL_REQUIRE_FOR_RESELLER_CUSTOMERS":"Ist für Reseller-Kunden eine Partnergenehmigung erforderlich?",
    "IS_PRODUCT_BEING_REACTIVATED_WITH_ADDONS":"Wird das Produkt mit Addons reaktiviert",
    "IS_PUBLIC_SIGNUP_FILTER_AVAILABLE_FOR_CUSTOMER":"Ist ein Filter für den Kunden verfügbar",
    "IS_TRIAL_OFFER":"Probeangebot",
    "IsCustomPrice":"Ist ein benutzerdefinierter Preis",
    "JANUARY":"Januar",
    "JULY":"Juli",
    "JUNE":"Juni",
    "LABEL_ASSIGNED_RG":"Zugewiesene Ressourcengruppen",
    "LABEL_BANNER_TEXT":"Botschaft",
    "LABEL_BANNER_TEXT_TOOL_TIP":"Schreiben Sie die Nachricht für das Banner.",
    "LABEL_BILLING_ADDRESS":"Rechnungsadresse",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_ORDER_IS_IN_PROGRESS_TEXT":"Das Testangebot kann nur einmal erworben werden.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRAIL_OFFER_TEXT":"Dieses Testangebot ist bereits in Ihrem Warenkorb verfügbar.",
    "LABEL_CUSTOM_OFFER_MANAGE_PLAN_OFFER_TRIAL_OFFER_TEXT":"Probeangebot für",
    "LABEL_CUSTOM_OFFER_TRAIL_OFFER_TEXT_PARENT_AVAILABLE":"Testangebot für Eltern nicht verfügbar",
    "LABEL_Customer_Name":"Kundenname",
    "LABEL_CUSTOMER_NAME":"Kundenname",
    "LABEL_DESC_SELECTED_PLAN_PRODUCTS":"Planprodukt(e) wurden ausgewählt",
    "LABEL_DESC_SELECTED_PLANS":"Pläne wurden ausgewählt",
    "LABEL_DESC_SELECTED_PRODUCT_VARIANTS":"Produktvariante(n) wurden ausgewählt.",
    "LABEL_EFFECTIVE_ENTITY":"Relevante Entität",
    "LABEL_EFFECTIVE_FROM":"Gültig ab",
    "LABEL_EFFECTIVE_RECORD_NAME":"Effektive Aufzeichnung",
    "LABEL_EFFECTIVE_TO":"Läuft aus am",
    "LABEL_IS_ACTIVE":"Ist aktiv",
    "LABEL_NOTIFICATION_RECIPIENTS":"Benachrichtigungsempfänger",
    "LABEL_NOTIFICATION_RECIPIENTS_INSTRUCTION":"Geben Sie die Benutzer als durch Kommas getrennte Werte ein",
    "LABEL_NOTIFICATION_TABLE":"Bannerbenachrichtigungen",
    "LABEL_NOTIFICATION_TYPE":"Nachrichtenstil",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT":"Richtlinie zuweisen für",
    "LABEL_POLICY_CUSTOM_POLICY_ASSIGNMENT_LIST":"Richtlinienzuweisungen von",
    "LABEL_POLICY_CUSTOMER":"Kunde",
    "LABEL_POLICY_DEFINITION":"Richtliniendefinition",
    "LABEL_POLICY_DESCRIPTION":"Richtlinienbeschreibung",
    "LABEL_POLICY_NAME":"Versicherungsname",
    "LABEL_POLICY_RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
    "LABEL_POLICY_SUBSCRIPTION":"Abonnement",
    "LABEL_PORTAL_PAGE":"Portalseite",
    "LABEL_ROLE_ADD_CUSTOM_ROLE":"Benutzerdefinierte Rolle hinzufügen",
    "LABEL_ROLE_BACK_TO_CUSTOM_ROLE":"Zurück zu benutzerdefinierten Rollen",
    "LABEL_ROLE_CUSTOM_ROLE_ADD":"Benutzerdefinierte Rolle hinzufügen",
    "LABEL_ROLE_CUSTOMER":"Kunde",
    "LABEL_ROLE_RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
    "LABEL_ROLE_ROLE_DEFINITION":"Rollendefinition",
    "LABEL_ROLE_ROLE_DESCRIPTION":"Rollenbeschreibung",
    "LABEL_ROLE_ROLE_NAME":"Rollenname",
    "LABEL_ROLE_SUBSCRIPTION":"Abonnement",
    "LABEL_SHIPPING_ADDRESS":"Lieferanschrift",
    "LABEL_TEXT_ADDONS":"Addons",
    "LABEL_TEXT_ADDRESS_1":"Adresse 1",
    "LABEL_TEXT_ADDRESS_2":"Adresse 2",
    "LABEL_TEXT_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden",
    "LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "LABEL_TEXT_BILLING_TYPE":"Abrechnungsart",
    "LABEL_TEXT_CANCELLATION_WINDOW_EXPIRES_IN":" Die Stornierungsfrist läuft ab",
    "LABEL_TEXT_CATEGORY":"Kategorie",
    "LABEL_TEXT_CREATE_MICROSOFT_ACCOUNT":"Erstellen Sie ein Microsoft-Konto",
    "LABEL_TEXT_DISCOUNT":"Rabatt",
    "LABEL_TEXT_DISTRIBUTER_OFFER":"Händlerangebote",
    "LABEL_TEXT_ENABLE_ALL_PROMOTIONS":"Aktivieren Sie alle Werbeaktionen",
    "LABEL_TEXT_LINKED_PRODUCT":"Verknüpftes Produkt",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_CATEGORY":" Kategorie :",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_ORDERS":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_FOR_SHOP":"NCE",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_PERPETUAL_SOFTWARE":"Unbefristete Software",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_SOFTWARE_SUBSCRIPTIONS":"Software-Abonnements",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_TERM":"Begriff",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM":"Begriff -",
    "LABEL_TEXT_NEW_COMMERCE_EXPERIENCE_VALIDITY_TERM_COLON":" Begriff :",
    "LABEL_TEXT_NOT_AVAILABLE_TO_CUSTOMER":"Für den Kunden nicht verfügbar",
    "LABEL_TEXT_OWNER_NAME":"Besitzername",
    "LABEL_TEXT_PRICE":"Preis",
    "LABEL_TEXT_RESERVED_INSTANCES":"RI",
    "LABEL_TEXT_RETAIL_PRICE":"Verkaufspreis",
    "LABEL_TEXT_SOURCE_SUBSCRIPTION":"Quellabonnement",
    "LABEL_TEXT_STATE_PROVINCE":"Landesprovinz",
    "LABEL_TEXT_STATUS":"Status",
    "LABEL_TEXT_TARGET_SUBSCRIPTION":"Zielabonnement",
    "LABEL_TEXT_TAX_CODE":"Steuer-Code",
    "LABEL_TEXT_TAX_TYPE":"Steuertyp",
    "LABEL_TEXT_TENANT_NAME":"Name des Mieters",
    "LABEL_TEXT_TYPE_IN_NAME":"Geben Sie einen Namen ein",
    "LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "LABEL_TEXT_ZIP_CODE":"Postleitzahl",
    "LABEL_TO_INDICATE_A_NEW_OFFER":"Neu",
    "LABEL_UNASSIGNED_RG":"Nicht zugewiesene Ressourcengruppen",
    "LABLE_ADDITIONAL_CHARGES_TOTAL":"Zusätzliche Kosten",
    "LABLE_CUSTOMER_ADMIN_ROLE":"Kundenadministrator",
    "LABLE_CUSTOMER_READER_ROLE":"Kundenleser",
    "LABLE_DISCOUNTS_TOTAL":"Totaler Rabatt",
    "LABLE_SUBSCRIPTIONS_TOTAL":"Abonnements insgesamt",
    "LABLE_TAXES_TOTAL":"Steuern insgesamt",
    "LABLE_TEXT_COST_PRICE":"Selbstkostenpreis",
    "LABLE_TEXT_COST_TO_PARTNER":"Kosten für den Partner",
    "LABLE_TEXT_DISPLAY_NAME":"Anzeigename",
    "LABLE_TEXT_EVENTS":"Veranstaltungen",
    "LABLE_TEXT_FRIENDLY_NAME":"Freundlicher Name",
    "LABLE_TEXT_IS_AVAILABLE_TO_CUSTOMER":"Verfügbar für den Kunden?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_CUSTOMER":"Werbeaktion für Kunden verfügbar?",
    "LABLE_TEXT_IS_PROMOTIONS_AVAILABLE_TO_RESELLER":"Werbeaktion für Reseller verfügbar?",
    "LABLE_TEXT_MAXVALUE":"MaxValue:",
    "LABLE_TEXT_MINVALUE":"MinValue:",
    "LABLE_TEXT_OFFER_NAME":"Angebotsname",
    "LABLE_TEXT_PROVIDER_SELLING_PRICE":"Anbieter Verkaufspreis",
    "LABLE_TEXT_SALE_PRICE":"Verkaufspreis",
    "LABLE_TEXT_SALEPRICE":"Verkaufspreis:",
    "LABLE_TEXT_SLAB":"Plattendetails",
    "LABLE_TEXT_SLAB_SALE_PRICE":"Verkaufspreis (UOM)",
    "LABLE_TEXT_TOOLTIP_COST_TO_PARTNER":"Kosten für den Partner",
    "LABLE_TEXT_TYPE":"Abrechnungsart",
    "LABLE_TEXT_UNIT_OF_MEASURE":"(UOM)",
    "LABLE_WAIVER":"Verzicht",
    "LAST_UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"Der letzte Versuch, die Daten zu laden, schlug fehl. Klicken Sie bitte auf „Aktualisieren“, um die neuesten Änderungen von PSA zu C3 zu synchronisieren.",
    "LATEST_COMMENTS":"Letzter Kommentar",
    "LAYOUTLOGIN_BUTTON_TEXT_LOGIN":"Anmeldung",
    "LAYOUTLOGIN_HEADER_TEXT":"Anmeldung",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_1":"Bitte einloggen zum Fortfahren",
    "LAYOUTLOGIN_PARAGRAPH_TEXT_2":"2016 Spektra Systems. Alle Rechte vorbehalten.",
    "LBL_COUPON_CODE_TEXT":"Gutscheincode",
    "LBL_DISCOUNT_TEXT":"Rabatt",
    "LBL_OFF":"aus",
    "LBL_TEXT_NOT_PROVIDED":"Nicht vorgesehen",
    "LESS_THAN_LIST_PRICE":"LessThanListPrice",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_HEADER_TEXT":"Bitte finden Sie die Fehler unten.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_1":"Dieser Vorgang kann nicht abgeschlossen werden, da die Voraussetzungen nicht erfüllt sind. Für die Zuweisung eines Add-Ons ist ein Basisangebot erforderlich, und für den Widerruf eines Basisangebots müssen die Add-Ons freigegeben werden.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_SUB_HEADER_TEXT_2":"Dieser Vorgang kann nicht abgeschlossen werden, da die Voraussetzungen nicht erfüllt sind. Für die Zuweisung eines Add-Ons ist ein Basisangebot erforderlich, und für den Widerruf eines Basisangebots müssen die Add-Ons freigegeben werden.",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_OFFER":"Angebot",
    "LICENSE_ASSIGNMENT_ERRO_POP_UP_TABLE_HEADER_PREREQUISITE_OFFERS":"Vorausgesetzte Angebote",
    "LICENSE_ASSIGNMENT_STATUS_REJECTED":"Antrag abgelehnt",
    "LICENSE_CHANGE_CAPTION_TEXT_DOWNLOALICENSE_CHANGE_REPORT":"Lizenzänderungsbericht herunterladen",
    "LICENSE_CHANGE_REPORT_BUSY_MESSAGE_GETTING_LICENSE_CHANGE_REPORT":"Abrufen des Lizenzänderungsberichts",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CLOSING_QUANTITY":"Schlussmenge",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CREATED_BY":"Erstellt von",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Kundenname",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_DATE_OF_CHANGE":"Änderungsdatum",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_LICENSE_CHANGE_REPORT":"Lizenzänderungsbericht",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_QUANTITY":"Neue Menge",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_NEW_STATUS":"Neuer Status",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_QUANTITY":"Alte Menge",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OLD_STATUS":"Alter Status",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_OPENING_QUANTITY":"Öffnungsmenge",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_PRODUCT_NAME":"Produkt",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_RESELLER_NAME":"Verkäufer Name",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Name des verknüpften Mandanten",
    "LICENSE_CHANGE_REPORT_CAPTION_TEXT_TERMS_AND_CONDITIONS":"AGB-Status",
    "LICENSE_CHANGE_REPORT_END_DATE_ERROR":"Das „Von“-Datum des Berichts darf nicht größer sein als das „Bis“-Datum",
    "LICENSE_CHANGE_REPORT_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptiert",
    "LICENSE_CONSUMPTION_REPORT":"Lizenzverbrauchsbericht",
    "LICENSE_CONSUMPTION_REPORT_DEFAULT_OPTION":"Wählen",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_CUSTOMER_NAME":"Kunde",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
    "LICENSE_CONSUMPTION_REPORT_FORM_LABEL_TENANT_NAME":"Mieter",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_CUSTOMER_NAME":"Bitte wählen Sie den Kunden aus",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_SUBSCIPTION_NAME":"Bitte geben Sie den Abonnementnamen ein",
    "LICENSE_CONSUMPTION_REPORT_PLACEHOLDER_TENANT_NAME":"Bitte Mieter auswählen",
    "LICENSE_CONSUMPTION_REPORT_SEARCH_FILTER":"Filter",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_ASSIGNED_LICENSE_QUANTITY_DIFFERENCE":"Zugewiesener Mengenunterschied",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_C3":"Verfügbar in C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_AVAILABLE_IN_PROVIDER":"Verfügbar im PC",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_ASSIGNED_LICENSE_COUNT":"Zugewiesene Lizenzen In C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_C3_LICENSE_COUNT":"Lizenzen in C3",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_CUSTOMER":"Kunde",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_EMAIL_ADDRESS":"E-Mail-Addresse",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_LICENSE_QUANTITY_DIFFERENCE":"Gesamtmengenunterschied",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_MAPPED_PRODUCTS":"Zugeordnete Produkte",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_ASSIGNED_LICENSE_COUNT":"Zugewiesene Lizenzen im Anbieter",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_LICENSE_COUNT":"Lizenzen im Anbieter",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_PROVIDER_UNUSED_LICENSE_COUNT":"Nicht verwendete Lizenzen im Anbieter",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_SUBSCRIPTION":"Abonnement",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_TENANT":"Mieter",
    "LICENSE_CONSUMPTION_REPORT_TABLE_HEADER_VIEW_DETAILS":"Details anzeigen",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_CONSUMPTION_REPORT":"Lizenzverbrauchsbericht",
    "LICENSE_CONSUMPTION_REPORT_TEXT_LICENSE_EARNING_REPORT":"Ergebnisbericht",
    "LICENSE_CONUMPTION_BUTTON_TEXT_EXPORT_LICENSE_CONUMPTION_REPORT_CSV":"Export License Consumption Report CSV",
    "LICENSE_CONUMPTION_LABEL_TEXT_TENANTS":"Mieter",
    "LICENSE_SUMMARY_BUSY_MESSAGE_GETTING_LICENSE_SUMMARY":"Lizenzübersicht abrufen",
    "LICENSE_SUMMARY_BUTTON_DOWNLOAD_LICENSE_SUMARY_REPORT":"Lizenzzusammenfassungsbericht herunterladen",
    "LICENSE_SUMMARY_CAPTION_TEXT_LICENSE_SUMMARY":"Lizenzübersicht",
    "LICENSE_SUMMARY_REPORT_CAPTION_TEXT_RESELLER_NAME":"Verkäufer Name",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_BILLING_CYCLE_NAME":"Rechnungskreislauf",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_PRODUCT_NAME":"Übergeordneter Produktname",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PARENT_SUBSCRIPTION_NAME":"Name des übergeordneten Abonnements",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_NAME":"Anbietername",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PROVIDER_TENANT_ID":"Provider Mieter ID",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_PURCHASED_QUANTITY":"Menge",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_IN":"Erneuerung – (in Tagen)",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_RENEWS_ON":"Erneuert weiter",
    "LICENSE_SUMMARY_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_ACTIONS":"Aktionen",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_EXPIRY_DATE":"Verfallsdatum",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_NAME":"Name",
    "LICENSE_SUPPORTED_VOUCHERS_TABLE_HEADER_VOUCHER_KEY":"Gutscheinschlüssel",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "LICENSECONSUMPTION_BREADCRUMB_BUTTON_TEXT_LICENSE_CUNSUMPTION":"Lizenzverbrauch",
    "LICENSECONSUMPTION_BTN_RELEASE_UNUSED_SHEETS":"Lassen Sie nicht verwendete Sitze frei",
    "LICENSECONSUMPTION_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS":"Geben Sie nicht verwendete Sitze frei",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ACTION_BUTTON_RELEASED_UNUSED_SEATS_BUSY_MESSAGE":"Nicht genutzte Sitze freigeben. Warten Sie mal...",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Zugewiesen",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_MAPPED_PRODUCTS":"Zugeordnete Produkte",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Gesamt",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname (n)",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "LICENSECONSUMPTION_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Ungebraucht",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "LICENSELIST_BREADCRUMB_BUTTON_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
    "LICENSELIST_LIST_CAPTION_TEXT_LICENSE_CONSUMPTION":"Lizenzverbrauch",
    "LICENSELIST_LIST_TABLE_HEADER_ASSIGNED_QUANTITY":"Zugewiesen",
    "LICENSELIST_LIST_TABLE_HEADER_PURCHASED_QUANTITY":"Gültig",
    "LICENSELIST_LIST_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
    "LICENSELIST_LIST_TABLE_HEADER_UNUSED_QUANTITY":"Verfügbar",
    "LICENSES_TOOLTIP_MS_USER":"Lizenzen",
    "LINK_CUSTOMER_BILLING_PROFILE_HEADER_TEXT":"Link zum Abrechnungsprofil von",
    "LINK_CUSTOMER_HEADER_TEXT":"Link zum Anbieter von",
    "LINK_CUSTOMER_INFO_MESSAGE_NO_UN_MAPPED_PROVIDERS":"Sie sind mit allen anwesenden Anbietern verbunden",
    "LINK_CUSTOMER_LBL_TEXT_CUSTOMER_TYPE":"Kundentyp",
    "LINK_CUSTOMER_LBL_TOOL_TIP_TEXT_CUSTOMER_TYPE":"Kundentyp",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_ID":"Mieter-ID",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_LBL_TENANT_NAME":"Name des Mieters",
    "LINK_MICROSOFT_NON_CSP_CUSTOMER_SUCCESS_MESSAGE":"Microsoft-Nicht-CSP-Kunde erfolgreich mit c3 verknüpft.",
    "LINK_RESELLER_CAPTION_TEXT":"Link Reseller",
    "LINK_RESELLER_FORM_LABEL_PROVIDER":"Anbieter",
    "LINK_RESELLER_FORM_LABEL_RESELLER_ID_IN_PROVIDER":"Reseller-ID (wie im Anbieter)",
    "LINK_RESELLER_LIST_BUTTON_DELETE":"Löschen",
    "LINK_RESELLER_LIST_TABLE_HEADER_ACTIONS":"Aktionen",
    "LINK_RESELLER_LIST_TABLE_HEADER_BUSINESS_ID":"Geschäfts ID",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_NAME":"Anbietername",
    "LINK_RESELLER_LIST_TABLE_HEADER_PROVIDER_RESELLER_ID":"Provider Reseller Id",
    "LINK_RESELLER_MESSAGE_ALL_PROVIDERS_ARE_LINKED":"Sie sind mit all Ihren Anbieterprofilen verknüpft.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_DELETED_LINK_TO_PROVIDER":"Der Link zu {{providerName}} wurde entfernt.",
    "LINK_RESELLER_NOTIFICATION_SUCCESSFULLY_LINKED_TO_PROVIDER":"Erfolgreich mit {{providerName}} verknüpft.",
    "LINK_TEXT_CLICK_HERE":"Klicke hier",
    "linkCustomerBillingProfile":"Link zum Abrechnungsprofil",
    "LINKED_PRODUCT_INFO_SHOPE":"Dieses Produkt ist verknüpft mit",
    "LINKED_SUBSCRIPTION_BUTTON_TEXT_SELECT":"Wählen",
    "LINKED_SUBSCRIPTION_POPUP_HEDER_SELECT_OFFER_FOR_LINKING":"Link zu einem Produkt",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "LINKED_SUBSCRIPTION_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "LINKING_TENANT_INFO_MESSAGE_VALIDATING_DOMAN":"Überprüfung der Domainverfügbarkeit",
    "LOAD_PROMOTIONS_LBL_TEXT_CATEGORY":"Kategorie",
    "LOAD_PROMOTIONS_LBL_TEXT_PROVIDER":"Anbieter",
    "LOADING_INDICATOR_MESSAGE":"Wird bearbeitet...",
    "LOADING_MESSAGE":"Wird geladen...",
    "LOADING_PROMOTIONS_AGAIN_WARNING":"<p>Aktionen des aktuellen Monats werden bereits geladen</p> <p>Möchten Sie die Aktionen neu laden?</p>",
    "LOADPROMOTION_CATEGORY_TOOLTIP":"Bitte wählen Sie die Produktkategorie aus der Dropdown-Liste aus",
    "LOADPROMOTION_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
    "LOGGED_IN_GETTING_DETAILS_FOR_IMPERSONATION":"Geben Sie uns einen Moment Zeit, um Ihren Identitätswechsel als abzuschließen",
    "LOGGED_IN_GETTING_DETAILS_GREETINGS":"Schöne Grüße,",
    "LOGGED_IN_GETTING_DETAILS_PLEASE_WAIT":"Sie sind jetzt angemeldet. Geben Sie uns einen Moment Zeit, um die Anwendung für den Zugriff vorzubereiten...",
    "LOW":"Niedrig",
    "MACRO_APPLY_X_PERCENT_ON_MARKUP":"Wenden Sie X % auf den Aufschlag an",
    "MACRO_APPLY_X_PERCENT_ON_PARTNER_PRICE":"Wenden Sie X % auf den Partnerpreis an",
    "MACRO_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE":"Wenden Sie X % auf den Aufschlag an",
    "MACRO_COPY_PARTNERT_PRICE":"Partnerpreis kopieren",
    "MACRO_COPY_PROVIDER_SELLING_PRICE":"Verkaufspreis des Kopieranbieters",
    "MACRO_DET_APPLY_X_PERCENT_ON_MARKUP_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts ist die Summe aus Partnerpreis + X % der Marge zwischen Partnerpreis und Anbieterverkaufspreis",
    "MACRO_DET_APPLY_X_PERCENT_ON_PARTNER_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts beträgt Partnerpreis + X % (Partnerpreis).",
    "MACRO_DET_APPLY_X_PERCENT_ON_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts ist der Verkaufspreis des Anbieters + X % (Verkaufspreis des Anbieters).",
    "MACRO_DET_COPY_PARTNERT_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts entspricht dem Partnerpreis",
    "MACRO_DET_COPY_PROVIDER_SELLING_PRICE_CALCULATION_DESC":"Der Verkaufspreis des Planprodukts entspricht dem Verkaufspreis des Anbieters",
    "MACRO_DET_EXAMPLE":"Beispiel:",
    "MACRO_DET_PARTNER_PRICE":"Partnerpreis",
    "MACRO_DET_PERCENTAGE":"Prozentsatz(X)",
    "MACRO_DET_PROVIDER_SELLING_PRICE":"Anbieter Verkaufspreis",
    "MACRO_DET_SALE_PRICE":"Verkaufspreis:",
    "MACRO_PERCENTAGE_TOOLTIP_TEXT":"Makro %",
    "MAIL_SENT_SUCCESSFULY":"Mail erfolgreich gesendet",
    "MANAGE_BUNDLE_PROCESSING_REQUEST_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "MANAGE_CUSTOMER_PRODUCT_TOOLTIP_TEXT_IS_CONSIDER_IN_AUTO_RELEASE":"Berücksichtigen Sie dies bei der automatischen Freigabe",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_NO":"Nein",
    "MANAGE_CUSTOMER_PRODUCTS_INFO_TEXT_YES":"Ja",
    "MANAGE_CUSTOMER_PRODUCTS_IS_CONSIDER_IN_AUTO_RELEASE":"Wird in Auto-Release berücksichtigt",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_CONSIDER_IN_AUTO_RELEASE":"Wird bei der automatischen Veröffentlichung berücksichtigt",
    "MANAGE_CUSTOMER_PRODUCTS_TRIAL_OFFER_IS_MANAGEABLE_EXCLUSIVELY_BY_PARTNER":"Ausschließlich durch den Partner verwaltbar",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_ENQUEUED_SUCCESS":"Ihre Anfrage für Lizenzzuweisungen wird in die Warteschlange gestellt. Bitte besuchen Sie diese Seite nach einiger Zeit für Updates.",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_INVALID_EMAILS_ERROR":"Bitte geben Sie im Mieter gültige E-Mail-Adressen der Benutzer ein",
    "MANAGE_LICENSES_BULK_LICENSE_ASSIGNMENT_LICENSE_ALREADY_EXISTS_ERROR":"Benutzer {{username}} besitzt bereits eine Lizenz für {{Abonnementname}}",
    "MANAGE_LICENSES_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie mindestens eine Datei aus",
    "MANAGE_LICENSES_IGNORE_ERRORS_CONFIRM":"Möchten Sie die Fehler wirklich ignorieren?",
    "MANAGE_LICENSES_MINIMUM_QUANTITY_WARN":"Die Mindestanzahl der Sitzplätze für ein Abonnement sollte 1 betragen. Sitzplätze können nicht freigegeben werden, wenn die verfügbare Anzahl der Sitzplätze 1 beträgt.",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_CONFIRM":"Sind Sie sicher, dass Sie nicht genutzte Sitze freigeben möchten?",
    "MANAGE_LICENSES_RELEASE_UNUSED_SEATS_SUCCESS":"Nicht verwendete Lizenzen wurden erfolgreich freigegeben",
    "MANAGE_LICENSES_REVOKE_USER_SELECTION_PROMPT":"Bitte fügen Sie mindestens einen Benutzer zum Eimer mit den zu entfernenden Lizenzen hinzu",
    "MANAGE_LICENSES_SELECT_USER_FROM_SUGGESTION_PROMPT":"Bitte wählen Sie den Benutzer aus der vorgeschlagenen Liste aus, bevor Sie auf die Schaltfläche Hinzufügen klicken",
    "MANAGE_LICENSES_UPDATE_SUCCESS":"Lizenzzuweisung erfolgreich abgeschlossen",
    "MANAGE_LICENSES_UPLOAD_LICENSES_ERROR":"Bei der Verarbeitung der Datei ist ein Fehler aufgetreten. Versuchen Sie es erneut",
    "MANAGE_LICENSES_UPLOAD_LICENSES_SUCCESS":"Lizenzdetails erfolgreich hochgeladen. Bitte kehren Sie nach einiger Zeit zu diesem Bildschirm zurück, um den Status zu überprüfen.",
    "MANAGE_NAME_CHANGE_ERROR":"Der Name darf nicht leer sein",
    "MANAGE_ONLINE_SERVICES_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "MANAGE_PLAN_BUNDLES_TEMPLATE_TOOLTIP_VIEW_OFFERS":"(Angebote anzeigen)",
    "MANAGE_PLAN_EDIT_MACRO_TOOLTIP":"Makro bearbeiten",
    "MANAGE_PLAN_ERROR_MESSAGE_LINKING_A_PRODUCT_IS_NOT_ALLOWED":"Produkte können nicht mit diesem Angebot verknüpft werden",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY":"Nur Werbung anzeigen",
    "MANAGE_PLAN_FILTER_TITLE_SHOW_PROMOTION_ONLY_OPTION_YES":"Ja",
    "MANAGE_PLAN_FRIENDLY_NAME_TYPE_IN_NAME_PLACEHOLDER":"Geben Sie einen Namen ein",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_1":"Kopieren Sie den Listenpreis in den Verkaufspreis - der Verkaufspreis entspricht dem Listenpreis",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_2":"X% zum Listenpreis hinzufügen - Der Verkaufspreis beträgt X% des Listenpreises + Listenpreis",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_3":"Erhöhen Sie den Preis um Y Cent - der Verkaufspreis ist Listenpreis + Y Cent",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_4":"Löschen Sie alle ausgewählten Angebotspreise",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_5":"X% auf Aufschlag hinzufügen - Verkaufspreis ist Listenpreis + (ERPPreis-Listenpreis)*X%",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X":"Addiere X% zum MS-Preis",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_ADD_X_DES":"Der Verkaufspreis beträgt X% des MS-Preises + des MS-Preises",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS":"Kopieren Sie den MS-Preis in den Verkaufspreis",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_COPY_MS_DES":"Der Verkaufspreis entspricht dem MS-Preis",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP":"Addiere X% zum Aufschlag",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_X_MARK_UP_DES":"Der Verkaufspreis beträgt MS Price + (ERPPreis-MS Preis*X%",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT":"Erhöhen Sie den Preis um Y Cent",
    "MANAGE_PLAN_HELP_TEXT_CONTENT_Y_CENT_DES":"Der Verkaufspreis beträgt MS Price + Y Cent",
    "MANAGE_PLAN_LBL_1":"Kopieren Sie den Listenpreis in den Verkaufspreis",
    "MANAGE_PLAN_LBL_2":"X% zum Listenpreis hinzufügen",
    "MANAGE_PLAN_LBL_3":"Erhöhen Sie den Preis um Y Cent",
    "MANAGE_PLAN_LBL_4":"Löschen Sie alle Preise",
    "MANAGE_PLAN_LBL_5":"Addiere X% zum Aufschlag",
    "MANAGE_PLAN_LBL_6":"Kopieren Sie den ERP-Preis in den Verkaufspreis",
    "MANAGE_PLAN_MACRO_APPLIED":"Makro angewendet",
    "MANAGE_PLAN_MACRO_CHANGE_HELP_TEXT":"Das neu ausgewählte Makro gilt für Angebote, die künftig zum Plan hinzugefügt werden, und hat keine Auswirkungen auf das vorhandene Makro bei Angeboten, die bereits hinzugefügt wurden.",
    "MANAGE_PLAN_NO_MACRO_APPLIED":"Kein Makro angewendet",
    "MANAGE_PLAN_NOT_AVAILABLE_FOR_PURCHASE":"Nicht käuflich zu erwerben",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_BUTTON_CLOSE":"Schließen",
    "MANAGE_PLAN_PARTNER_BUNDLES_CHILD_OFFERS_POPUP_TITLE":"Inbegriffene Angebote",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_BUTTON_CLOSE":"Schließen",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TABLE_HEADER_NAME":"Name",
    "MANAGE_PLAN_PARTNER_PARENT_NAMES_POPUP_TITLE":"Elternnamen",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_ADDONS_CONFIRMATION_TEXT":"Möchten Sie wirklich alle Add-Ons löschen?",
    "MANAGE_PLAN_POPUP_DELETE_PRODUCT_CONFIRMATION_TEXT":"Möchten Sie dieses Produkt wirklich löschen?",
    "MANAGE_PLAN_POPUP_DELETE_TRIAL_PRODUCT_CONFIRMATION_TEXT":"<b>{{productName}}</b> ist ein übergeordnetes Angebot für ein Testprodukt. <br/> Durch das Löschen des Premium-Angebots wird auch das zugehörige Testangebot gelöscht.<br/> Sind Sie sicher, dass Sie <b>{{productName}}<b/> löschen möchten?",
    "MANAGE_PLAN_SUCCESS_TEXT_ADDONS_ARE_ADDED_SAVE_PLAN_TO_COMPLETE":"Addons werden dem Plan hinzugefügt. Bitte speichern Sie den Plan, um Ihren Vorgang abzuschließen.",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN":"zum Plan hinzugefügt!",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_ADD_ONS":"hinzugefügt, um mit Add-Ons zu planen",
    "MANAGE_PLAN_SUCCESS_TEXT_SELECT_ADDED_TO_PLAN_WITH_TRIAL_OFFER":" mit einem Elternangebot zum Plan hinzugefügt",
    "MANAGE_PLAN_TOOLTIP_ON_SELECTING_MARKUP_OR_PERCENTAGE_VALUE":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "MANAGE_PLANS_ADD_ON_NOT_FOUND_ERROR":"Es wurden keine passenden Add-Ons gefunden",
    "MANAGE_PLANS_DELETE_OFFERS_PROMPT":"Möchten Sie das Angebot entfernen?",
    "MANAGE_PLANS_DUPLICATE_ADD_ON_ERROR":"Das Add-On, das Sie hinzufügen möchten, ist bereits enthalten",
    "MANAGE_PLANS_FILTER_TITLE_CONSUMPTION_TYPES":"Verbrauchsarten",
    "MANAGE_PLANS_FILTER_TITLE_IS_TRAIL_OFFER":"Ist Wanderangebot",
    "MANAGE_PLANS_FILTER_TITLE_SUBSCRIPTION_STATUS":"Abonnementstatus",
    "MANAGE_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "MANAGE_PLANS_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_BEGIN":"Bitte überprüfen Sie die folgenden Angebote, da der Verkaufspreis unter dem Listenpreis liegt.",
    "MANAGE_PLANS_LIST_PRICE_MORE_THAN_SALE_PRICE_END":"Klicken Sie auf \\''OK\\'', um die Planänderungen zu übermitteln. 'CANCEL', um die Planangebote erneut zu überprüfen.",
    "MANAGE_PLANS_OFFER_ALREADY_INCLUDED_ERROR":"Das ausgewählte Angebot war bereits enthalten",
    "MANAGE_PLANS_REQUIRED_ATTRIBUTES_PROMPT":"Bitte geben Sie den freundlichen Namen und den effektiven Preis für die im Plan enthaltenen Angebote an",
    "MANAGE_PLANS_SALE_PRICE_MORE_THAN_ERP_PRICE_BEGIN":"Bitte überprüfen Sie die folgenden Angebote, da der Verkaufspreis höher ist als der ERP-Preis.",
    "MANAGE_PLANS_SELECT_OFFERS_PROMPT":"Bitte fügen Sie dem Plan Angebote hinzu",
    "MANAGE_PLANS_TOOLTIP_TEXT_ADD_MORE_ADDONS":"Weitere Addons hinzufügen",
    "MANAGE_PONUMBER_CHANGE_ERROR":"Die PONumber darf nicht leer sein",
    "MANAGE_PRODUCT_OWNERSHIP":"Produktbesitz verwalten",
    "MANAGE_PRODUCT_OWNERSHIP_FAILED":"Verwalten des Produktbesitzes fehlgeschlagen",
    "MANAGE_PRODUCT_OWNERSHIP_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_IS_INPROGRESS":"Das Verwalten des Produktbesitzes ist nicht zulässig, da für eines der Produkte oder deren Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
    "MANAGE_PRODUCT_VIEW_NOTIFICATION_ADDED_NEW_VOUCHERS":"Neue Gutscheine erfolgreich hinzugefügt",
    "MANAGE_QUANTITY_BASED_CUSTOMER_PRODUCT_PROCESSING_ERROR_MESSAGE":"Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
    "MANAGE_RELLER_OFFERS_BTN_CLEAR_PRICES":"Klare Preise",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_1":"Kopieren Sie den MS-Preis in den Reseller-Preis. Der Reseller-Preis entspricht dem MS-Preis",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_2":"X% zum MS-Preis hinzufügen - Der Reseller-Preis beträgt X% des MS-Preises + MS-Preis",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_3":"Erhöhen Sie den Preis um Y Cent - der Reseller-Preis beträgt MS Price + Y Cent",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_4":"Löschen Sie alle ausgewählten Angebotspreise",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_CONTENT_5":"X% auf Aufschlag hinzufügen - Wiederverkäuferpreis ist MS-Preis + (ERPPreis-MS-Preis)*X%",
    "MANAGE_RELLER_OFFERS_HELP_TEXT_HEADER":"Wenn Sie die Reseller-Angebote hinzufügen möchten, müssen Sie die folgenden Anweisungen befolgen",
    "MANAGE_RELLER_OFFERS_ITEMS_SELECTED":"Ausgewählte Elemente",
    "MANAGE_RELLER_OFFERS_LBL_1":"Kopieren Sie den MS-Preis in den Reseller-Preis",
    "MANAGE_RELLER_OFFERS_LBL_2":"Addiere X% zum MS-Preis",
    "MANAGE_RELLER_OFFERS_LBL_3":"Erhöhen Sie den Preis um Y Cent",
    "MANAGE_RELLER_OFFERS_LBL_4":"Löschen Sie alle Preise",
    "MANAGE_RELLER_OFFERS_LBL_5":"Addiere X% zum Aufschlag",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_FILTERED_MESSAGE":"Möchten Sie alle gefilterten Angebote aus ausgewählten Angeboten entfernen?",
    "MANAGE_RELLER_OFFERS_PROMPT_REMOVE_MESSAGE":"Möchten Sie alle ausgewählten Angebote entfernen?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_FILTERED_MESSAGE":"Möchten Sie alle gefilterten Angebote auswählen?",
    "MANAGE_RELLER_OFFERS_PROMPT_SELECT_ALL_MESSAGE":"Möchten Sie alle Angebote auswählen?",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "MANAGE_RELLER_OFFERS_TABLE_1_HEADER_TEXT_VALID_FROM":"Gültig ab",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LICENSE_TYPE":"Lizenz-Typ",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_LIST_PRICE":"Listenpreis",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "MANAGE_RELLER_OFFERS_TABLE_HEADER_TEXT_RESELLER_PRICE":"Wiederverkäuferpreis",
    "MANAGE_RENEWAL_ALERT_MESSAGE_REDUCE_THE_QUANTITY":"Wenn Sie die Menge reduzieren möchten, wenden Sie sich bitte an das Support-Team.",
    "MANAGE_RENEWAL_BUTTON_TEXT_BACK_TO_SCHEDULED_RENEWALS":"Zurück zu den geplanten Verlängerungen",
    "MANAGE_RENEWAL_BUTTON_TEXT_CANCEL":"Stornieren",
    "MANAGE_RENEWAL_BUTTON_TEXT_CREATE_SCHEDULE":" Erstellen Sie einen neuen Zeitplan",
    "MANAGE_RENEWAL_BUTTON_TEXT_SUBMIT":"einreichen",
    "MANAGE_RENEWAL_CUMULATIVE_QUANTITY":"Kumulierte Menge",
    "MANAGE_RENEWAL_CURRENT_SUBCRIPTION":"Aktuelles Abonnement",
    "MANAGE_RENEWAL_DESCRIPTION_1":"Änderungen treten am Verlängerungsdatum des Abonnements in Kraft: <b>{{renewalDate}}</b>. Bei SKU-Upgrades werden Lizenzen automatisch zugewiesen, wenn sich die Menge nicht ändert. Andernfalls müssen Sie die Lizenzen manuell zuweisen.",
    "MANAGE_RENEWAL_HEADER_TEXT":"Erneuerung verwalten",
    "MANAGE_RENEWAL_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "MANAGE_RENEWAL_LABEL_TEXT_CURRENT_ENDDATE":"Aktuell: {{currentEnddate}}",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS":"Wählen Sie aus verschiedenen SKUs:",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_BILLING_CYCLE":"Wählen Sie einen anderen Abrechnungszeitraum:",
    "MANAGE_RENEWAL_LABEL_TEXT_ELIGIBLE_SKUS_WITH_DIFFERENT_TERM":"Wählen Sie einen anderen Begriff:",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE":"Endtermin",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_ALIGNMENT":"Ausrichtung des Enddatums",
    "MANAGE_RENEWAL_LABEL_TEXT_END_DATE_TYPE":"Enddatumstyp",
    "MANAGE_RENEWAL_LABEL_TEXT_PLAN_PRODUCT":"Produkt planen",
    "MANAGE_RENEWAL_LABEL_TEXT_QUANTITY":"Menge",
    "MANAGE_RENEWAL_LABEL_TEXT_SKU":"Wählen Sie ein berechtigtes Planprodukt für Ihr Abonnement (verwenden Sie diese Option, wenn Sie SKU/Abrechnungszeitraum/Laufzeit ändern und die Änderungen planen möchten).",
    "MANAGE_RENEWAL_LABEL_TEXT_TERM":"Begriff",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT":"Zuvor ausgewähltes Planprodukt zur geplanten Verlängerung für SKU-/Laufzeit-/Abrechnungszykluswechsel:",
    "MANAGE_RENEWAL_PREVIOUSLY_SELECTED_PLAN_PRODUCT_NAME":"Produktname planen",
    "MANAGE_RENEWAL_TOOL_TIP_TEXT_END_DATE_ALIGNMENT":"Sie können Ihr Abonnement gleichzeitig mit einem bestehenden OnlineServicesNCE-Abonnement ohne Testversion kündigen oder das Enddatum an den Kalendermonat anpassen, indem Sie abhängig von der Laufzeit ein geeignetes Enddatum auswählen.",
    "MANAGE_RENEWAL_VIEW_UPDATE_SCHEDULE":"Zeitplan aktualisieren",
    "MANAGE_RESELLER_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_OFFERS":"Bietet an",
    "MANAGE_RESELLER_BREADCRUMB_BUTTON_MANAGE_RESELLERS":"Wiederverkäufer",
    "MANAGE_RESELLER_OFFERS_ADD_CAPTION_1_DETAILS":"Reseller-Angebote hinzufügen",
    "MANAGE_RESELLER_OFFERS_ADD_FORM_HEADER_OFFER":"Bietet an",
    "MANAGE_RESELLER_OFFERS_REQUIRED_ATTRIBUTES_PROMPT":"Bitte geben Sie den effektiven Preis für die ausgewählten Angebote an",
    "MANAGE_RESELLER_OFFERS_SAVE_SUCCESS":"Angebote erfolgreich gespeichert",
    "MANAGE_RESELLER_OFFERS_SELECT_OFFERS_PROMPT":"Bitte fügen Sie dem Reseller Angebote hinzu",
    "MANAGE_RESELLER_PLAN_SELECT_ALL_MESSAGE":"Alle Angebote ausgewählt. Wenn Sie etwas bearbeiten möchten, das mit einem Planangebot zusammenhängt, müssen Sie den Plan bearbeiten!",
    "MANAGE_RESELLER_PLANS_CAPTION_TEXT_RESELLER_PLANS":"Liste der Wiederverkäuferpläne",
    "MANAGE_RESELLER_PLANS_NOTIFICATION_PLAN_STATUS_UPDATED_SUCCESSFULLY":"Planstatus erfolgreich aktualisiert",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_DISABLE":"Deaktivieren",
    "MANAGE_RESELLER_PLANS_TABLE_BUTTON_TEXT_ENABLE":"Aktivieren",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "MANAGE_RESELLER_PLANS_TABLE_HEADER_TEXT_NAME":"Name",
    "MANAGE_RESELLERS_PLANS_FILTER_TITLE_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_DISABLED_AUTO_RENEW_STATUS":"Läuft aus am",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_ENABLED_AUTO_RENEW_STATUS":"Automatische Verlängerung aktiviert",
    "MANAGE_SUBSCRIPTION_LABEL_TEXT_PRODUCT_ID":"Produkt ID",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITH_EXPIRY_DATE":"Wenn die automatische Verlängerung deaktiviert ist, läuft dieses Abonnement am ab",
    "MANAGE_SUBSCRIPTION_TOOLTIP_TEXT_AUTO_RENEW_STATUS_WITHOUT_EXPIRY_DATE":"Wenn die automatische Verlängerung deaktiviert ist, läuft dieses Abonnement am Ende der Laufzeit ab",
    "manageplans":"Pläne verwalten",
    "MANAGERG_REMOVE_OFFERS_PROMPT":"Möchten Sie die Ressourcengruppe entfernen?",
    "MANDATE_CUSTOMER_TO_UPDATE_PAYMENTPROFILE":"Beauftragen Sie den Kunden, die Zahlungsdetails zu aktualisieren.",
    "MANUAL_SYNC_PUPUP_TITLE":"Manuelle Synchronisierung",
    "MANUAL_SYNC_SUCCESS_NOTIFICATION_MESSAGE":"Ihr Fix wurde erfolgreich angewendet",
    "MAPPING_WARNING_TEXT_NO_AGREEMENT_IS_SELECTED":"Bitte wählen Sie eine PSA-Vereinbarung aus",
    "MAPPING_WARNING_TEXT_NO_CONTRACT_IS_SELECTED":"Bitte wählen Sie einen Vertrag",
    "MAPPING_WARNING_TEXT_NO_CUSTOMER_IS_SELECTED":"Bitte wählen Sie einen Kunden aus",
    "MAPPING_WARNING_TEXT_NO_PSA_CUSTOMER_IS_SELECTED":"Bitte wählen Sie einen PSA-Kunden aus",
    "MAPPING_WARNING_TEXT_NO_PSA_PRODUCT_IS_SELECTED":"Bitte wählen Sie ein PSA-Produkt",
    "MARCH":"März",
    "MARK_AS_DEFAULT_ADDRESS_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese Adresse als Standardadresse markieren möchten?",
    "MARK_AS_DEFAULT_EMAIL_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese E-Mail als Standard-E-Mail markieren möchten?",
    "MARK_AS_DEFAULT_PHONE_NUMBER_CONFIRMATION_PROMPT":"Sind Sie sicher, dass Sie diese Telefonnummer als Standardnummer markieren möchten?",
    "Markup":"Markup",
    "MATCHING_PRODUCT_PLAN_TEXT":"Planen:",
    "MAY":"Kann",
    "MCB_BILLING_PROVIDER_RECURRING_PAYMENT_CREATION_FIRST_PAYMENT":"Wiederkehrende Zahlungserstellung. Möglicherweise wird auf Ihrer Karte eine Belastung in Höhe von 1,00 $ angezeigt, die in Ihrer nächsten Rechnung als Gutschrift verrechnet wird.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt. Der Kunde sollte sich an seine Bank wenden und die Transaktion erneut versuchen.",
    "MCB_ERROR_CODE_ACCEPTOR_CONTACT_ACQUIRER_SECURITY":"Die Bank des Kunden hat die Transaktion abgelehnt und den Händler aufgefordert, sich mit der Bank in Verbindung zu setzen. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_APPROVED_FOR_PARTIAL_AMOUNT":"Die Transaktion war erfolgreich.",
    "MCB_ERROR_CODE_APPROVED_UPDATE_TRACK_3":"Die Transaktion war erfolgreich.",
    "MCB_ERROR_CODE_APPROVED_VIP":"Die Transaktion war erfolgreich. (Dieser Code wird in Australien nicht verwendet.)",
    "MCB_ERROR_CODE_BANK_NOT_SUPPORTED_BY_SWITCH":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da sie keine Transaktionen zulässt, die per Post/Telefon, Fax, E-Mail oder Internetbestellungen erfolgen. Dieser Fehler bezieht sich auf Kunden, die versuchen, eine Discover Card zu verwenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_CANNOT_COMPLETE_VIOLATION_OF_THE_LAW":"Die Bank des Kunden hat die Transaktion abgelehnt und den Kunden aufgefordert, sich an seine Bank zu wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_CAPTURE_CARD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Karte im Verdacht steht, gefälscht zu sein. Die Bank des Kunden (Kartenherausgeber) hat verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_CARD_ACCEPTOR_CONTACT_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte des Kunden einbehalten wird (Karte als verloren oder gestohlen gemeldet). Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_CARD_ISSUER_UNAVAILABLE":"Die Bank des Kunden kann nicht kontaktiert werden, um die Transaktion zu autorisieren. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_CONTACT_ACQUIRER_SECURITY_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
    "MCB_ERROR_CODE_CUTOFF_IN_PROGRESS":"Die Bank des Kunden kann die Kreditkarte dieses Kunden vorübergehend nicht verarbeiten. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_CVV_VALIDATION_ERROR":"Die Bank des Kunden hat die Transaktion abgelehnt, da der CVV falsch ist. Der Kunde sollte die CVV-Details überprüfen und es erneut versuchen. Wenn dies nicht gelingt, sollte der Kunde eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_DO_NOT_HONOUR":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkartennummer eine Sicherheitsprüfung nicht bestanden hat oder das Guthaben eingefroren oder aufgebraucht wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_DUPLICATE_TRANSACTION":"Die Bank des Kunden hat die Transaktion abgelehnt, da es sich anscheinend um eine doppelte Übertragung handelt. Keine Aktion erforderlich.",
    "MCB_ERROR_CODE_ERROR":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL":"Die Bank des Kunden hat die Transaktion abgelehnt, da der Kunde das Auszahlungshäufigkeitslimit überschritten hat. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_EXCEEDS_WITHDRAWAL_LIMIT":"Die Bank des Kunden hat die Transaktion abgelehnt, da sie das Kartenlimit des Kunden überschreiten wird. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_EXPIRED_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkarte abgelaufen zu sein scheint. Der Kunde sollte das eingegebene Ablaufdatum überprüfen und es erneut versuchen oder eine andere Kreditkarte verwenden.",
    "MCB_ERROR_CODE_EXPIRED_CARD_CAPTURE":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkarte abgelaufen ist oder das Datum falsch ist. Überprüfen Sie das Ablaufdatum in der Transaktion und versuchen Sie erneut, die Transaktion zu verarbeiten.",
    "MCB_ERROR_CODE_FORMAT_ERROR":"Die Bank des Kunden (Kartenherausgeber) kennt die Transaktionsdetails nicht. Der Kunde sollte die Transaktionsinformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten. Normalerweise aufgrund einer falschen TID.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_CARDHOLDER":"Die Bank des Kunden hat die Transaktion abgelehnt, da diese Kreditkarte für diese Art von Transaktion nicht verwendet werden kann. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_FUNCTION_NOT_PERMITTED_TO_TERMINAL":"Die Bank des Kunden hat die Transaktion abgelehnt, da diese Kreditkarte für diese Art von Transaktion nicht verwendet werden kann. Diese kann einer Testkreditkartennummer zugeordnet sein. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_FUNCTION_NOT_SUPPORTED":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da sie diese Art von Transaktion nicht zulässt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_HONOR_WITH_IDENTIFICATION":"Die Transaktion war erfolgreich. Einige Banken verwenden diesen Code anstelle von „00“.",
    "MCB_ERROR_CODE_INCORRECT_PIN":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der Kunde eine falsche PIN eingegeben hat. Der Kunde sollte seine PIN erneut eingeben, andernfalls eine andere Kreditkarte verwenden.",
    "MCB_ERROR_CODE_INSUFFICIENT_FUNDS":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkarte nicht ausreichend gedeckt ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_INVALID_AMOUNT":"Möglicherweise wird ein ungültiges Zeichen (z. B. ein Dollarzeichen oder ein Leerzeichen) an das Gateway übergeben. Überprüfen Sie den Code Ihrer Website.",
    "MCB_ERROR_CODE_INVALID_CARD_NUMBER":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Kreditkartennummer nicht existiert. Überprüfen Sie die Kreditkarteninformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
    "MCB_ERROR_CODE_INVALID_TRANSACTION":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion aufgrund eines ungültigen Formats oder Felds abgelehnt. Überprüfen Sie die Transaktionsinformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
    "MCB_ERROR_CODE_LOST_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Karte als verloren gemeldet wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_ACTION_TAKEN_TAKEN":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_CARD_RECORD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkartennummer nicht existiert. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_CHEQUE_ACCOUNT":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkartennummer einem nicht existierenden Girokonto zugeordnet ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_CREDIT_ACCOUNT":"Die Bank des Kunden hat die Transaktion abgelehnt, da die verwendete Kreditkartennummer kein Kreditkonto ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_INVESTMENT_ACCOUNT":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der ausgewählte Kontotyp für diese Kreditkartennummer nicht gültig ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_ISSUER":"Die Bank des Kunden (Kartenherausgeber) existiert nicht. Überprüfen Sie die Kreditkarteninformationen und versuchen Sie erneut, die Transaktion zu verarbeiten.",
    "MCB_ERROR_CODE_NO_MERCHANT":"Die Händler-ID ist ungültig. Wenden Sie sich an Ihre Bank und vergewissern Sie sich, dass Sie die richtige Händlerkontonummer angegeben haben.",
    "MCB_ERROR_CODE_NO_SAVINGS_ACCOUNT":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt, da die Kreditkartennummer einem nicht existierenden Sparkonto zugeordnet ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_NO_UNIVERSAL_ACCOUNT":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der ausgewählte Kontotyp für diese Kreditkartennummer nicht gültig ist. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_ORIGINAL_AMOUNT_INCORRECT":"Die Bank des Kunden hat die Transaktion abgelehnt, da versucht wird, den Betrag zu verarbeiten. Der Kunde sollte den Transaktionsbetrag überprüfen und es erneut versuchen.",
    "MCB_ERROR_CODE_PICK_UP_CARD":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet). Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_PICK_UP_CARD_SPECIAL":"Die Bank des Kunden (Kartenaussteller) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte Ihres Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED":"Die Bank des Kunden hat die Transaktion abgelehnt, da der Kunde mehr als dreimal die falsche PIN eingegeben hat. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_PIN_TRIES_EXCEEDED_CAPTURE":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da der Kunde dreimal die falsche PIN eingegeben hat. Die Bank des Kunden (Kartenherausgeber) hat Sie aufgefordert, die Kreditkarte einzubehalten. Der Kunde sollte eine alternative Kreditkarte verwenden und sich an seine Bank wenden.",
    "MCB_ERROR_CODE_RE_ENTER_LAST_TRANSACTION":"Die Transaktion wurde nicht verarbeitet und der Kunde sollte versuchen, die Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_REFER_TO_ISSUER_SPECIAL":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_REQUEST_IN_PROGRESS":"Die Bank des Kunden (Kartenaussteller) hat angegeben, dass es ein Problem mit der Kreditkartennummer gibt. Der Kunde sollte sich an seine Bank wenden. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_RESTRICTED_CARD":"Die Bank des Kunden hat die Transaktion abgelehnt, da die Kreditkarte einigen Einschränkungen unterliegt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_RESTRICTED_CARD_RETAIN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt und verlangt, dass die Kreditkarte des Kunden einbehalten wird. (Karte als verloren oder gestohlen gemeldet) Der Kunde sollte eine andere Kreditkarte verwenden.",
    "MCB_ERROR_CODE_SECURITY_VIOLATION":"Die Bank des Kunden hat die Transaktion abgelehnt. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_STOLEN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da die Karte als gestohlen gemeldet wurde. Der Kunde sollte eine alternative Kreditkarte verwenden.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD":"Die Bank des Kunden hat diese Transaktion abgelehnt, da die Kreditkarte betrügerisch zu sein scheint.",
    "MCB_ERROR_CODE_SUSPECTED_FRAUD_RETAIN_CARD":"Die Bank des Kunden (Kartenherausgeber) hat die Transaktion abgelehnt, da ein Betrugsverdacht mit dieser Kreditkartennummer vorliegt.",
    "MCB_ERROR_CODE_SUSPECTED_MALFUNCTION":"Die Bank des Kunden (Kartenherausgeber) kann während der Transaktion nicht kontaktiert werden. Der Kunde sollte die Kreditkarteninformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_SYSTEM_ERROR":"Die Bank des Kunden konnte die Transaktion nicht verarbeiten. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_TRANSACTION_APPROVED":"Die Transaktion war erfolgreich.",
    "MCB_ERROR_CODE_UNABLE_TO_LOCATE_FILE":"Die Bank des Kunden (Kartenherausgeber) erkennt die Kreditkartendaten nicht an. Der Kunde sollte die Kreditkarteninformationen überprüfen und versuchen, die Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_UNABLE_TO_ROUTE_TRANSACTION":"Die Bank des Kunden kann für das Routing nicht gefunden werden. Dieser Antwortcode wird häufig zurückgegeben, wenn der Kunde eine Testkreditkartennummer verwendet. Der Kunde sollte versuchen, diese Transaktion erneut zu verarbeiten.",
    "MCB_ERROR_CODE_UNACCEPTABLE_TRANSACTION_FEE":"Ein nicht näher bezeichneter Fehler ist aufgetreten.",
    "MCB_HOSTED_PAGE_ERRORS_CARD_INVALID_OR_MISSING":"Kartennummer ungültig oder fehlt.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_MONTH_INVALID_OR_MISSING":"Ablaufmonat ungültig oder fehlt.",
    "MCB_HOSTED_PAGE_ERRORS_EXPIRY_YEAR_INVALID_OR_MISSING":"Ablaufjahr ungültig oder fehlt.",
    "MCB_HOSTED_PAGE_ERRORS_SECURITY_CODE_INVALID":"Sicherheitscode ungültig.",
    "MEDIUM":"Medium",
    "MENU_ACCOUNT_MANAGERS":"Kundenbetreuer",
    "MENU_ADMINISTRATION":"Verwaltung",
    "MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Benutzerdefinierte Benachrichtigungen",
    "MENU_ADMINISTRATION_ENGAGE":"Engage <sup class='text-warning'> Beta</sup>",
    "MENU_ADMINISTRATION_MANAGE":"Banner-Benachrichtigungen",
    "MENU_ADMINISTRATION_MANAGE_CREATE_WEB_NOTIFICATION_HEADING":"Bannerbenachrichtigungen",
    "MENU_ADMINISTRATION_MANAGE_HEADING":"Verwaltung",
    "MENU_ADMINISTRATION_NOTIFICATIONS":"Benachrichtigungen",
    "MENU_ADMINISTRATION_WEBHOOK":"Webhook",
    "MENU_AZURE_ADVISOR":"Azure Advisor",
    "MENU_AZURE_BILLING_REPORT":"Azure-Abrechnungsbericht",
    "MENU_AZURE_CUSTOM_POLICY":"Benutzerdefinierte Richtlinien",
    "MENU_AZURE_CUSTOM_ROLE":"Benutzerdefinierte Rollen",
    "MENU_AZURE_MARGIN":"C3-Unterstützung",
    "MENU_AZURE_SUBACRIPTION":"Azure-Abonnement",
    "MENU_BREADCRUM_BUTTON_TEXT_MICROSOFT":"Microsoft",
    "MENU_BREADCRUMB_BUTTON_TEXT_ACCOUNT_MANAGERS":"Kundenbetreuer",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_COMMISSIONS":"Provisionen",
    "MENU_BREADCRUMB_BUTTON_TEXT_PRODUCT_EXTENSIONS":"Produkteigenschaften",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES":"Zitate",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_DETAILS":"Zitatedetails",
    "MENU_BREADCRUMB_BUTTON_TEXT_QUOTES_LIST":"Zitateliste",
    "MENU_BREADCRUMB_BUTTON_TEXT_REPORT_USAGE":"Nutzung melden",
    "MENU_CONTACT_LOG":"Benachrichtigungen",
    "MENU_COST_SUMMARY_REPORT":"Kostenübersicht",
    "MENU_CUSTOMER_ANALYTICS":"Kundenanalyse",
    "MENU_CUSTOMER_COMMENTS":"Bemerkungen",
    "MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lizenzverbrauch",
    "MENU_CUSTOMER_MICROSOFT_REPORT":"Azure-Verwendung",
    "MENU_CUSTOMER_PROFILE":"Profil",
    "MENU_DASHBOARD":"Dashboard",
    "MENU_EXISTING_ACCOUNT_MANAGERS":"Bestehende Manager",
    "MENU_HELP":"Hilfe",
    "MENU_LICENSE_CHANGE":"Lizenzänderungsbericht",
    "MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Zusammenfassender Bericht zum Lizenzverbrauch",
    "MENU_LICENSE_SUMMARY_REPORT":"Lizenzzusammenfassungsbericht",
    "MENU_MANAGE_AZURE_ENTITLEMENTS":"Azure-Berechtigungen verwalten",
    "MENU_MANAGE_SUBSCRIPTIONS_PROVISION":"Partner bietet Provisioning an",
    "MENU_ONBOARDED_CUSTOMERS_REPORT":"Onboarding Analytics",
    "MENU_PARTNER_AUDIT_LOG":"Audit-Log",
    "MENU_PARTNER_COMMISSION_REPORT":"Kommissionsbericht",
    "MENU_PARTNER_COUPON":"Gutscheine",
    "MENU_PARTNER_DOCUMENTATION":"Dokumentation",
    "MENU_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
    "MENU_PARTNER_SUPPORT_LOG":"Unterstützung",
    "MENU_PARTNER_UPLOAD":"Hochladen",
    "MENU_PENDING_PURCHASE_REQUESTS":"Zulassungen",
    "MENU_PRODUCT_COMMISSIONS":"Provisionen",
    "MENU_PRODUCT_EXTENSIONS":"Produkteigenschaften",
    "MENU_PRODUCT_SEQUENCE":"Produktsequenz",
    "MENU_PURCHSED_PRODUCT_MAPPING":"Gekaufte Produktzuordnung",
    "MENU_RENEWAL_MANAGER":"Erneuerungsmanager",
    "MENU_REPORT_USAGE":"Verwendung",
    "MENU_REPORTS_FOR_CUSTOMER":"Berichte",
    "MENU_RESELLER_PLAN":"Reseller Plan",
    "MENU_RESELLER_PROFILE":"Organisationsprofil",
    "MENU_SCHEDULED_REPORTS":"Geplante Berichte",
    "MENU_SCHEDULED_REPORTS_RECIPIENTS":"Empfänger für",
    "MENU_SCHEDULED_REPORTS_RUN_HISTORY":"Ausführungsverlauf für",
    "MENU_TOPBAR_SETTINGS":"die Einstellungen",
    "MENU_USAGE_REPORT":"Azure-Nutzungsbericht",
    "MENU_USERS":"Benutzer",
    "MENUS_ANALYTICS":"Analytik",
    "MENUS_ANALYZE":"Analysieren",
    "MENUS_AZURE_ENTITLEMENT_LEVEL_PRICING":"Preise für Azure-Berechtigungsstufen verwalten",
    "MENUS_AZURE_ESTIMATE":"Azure",
    "MENUS_AZURE_GROUPS":"Azure-Gruppen",
    "MENUS_AZURE_MANGE":"Azure-Verwaltung",
    "MENUS_AZURE_REPORTS":"Azure-Berichte",
    "MENUS_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
    "MENUS_CART":"Wagen",
    "MENUS_COUPONS":"Gutscheine",
    "MENUS_CUSTOM_OFFERS":"Partnerangebote",
    "MENUS_CUSTOMER_CART":"Wagen",
    "MENUS_CUSTOMER_DASHBOARD":"Dashboard",
    "MENUS_CUSTOMER_MICROSOFT":"Microsoft",
    "MENUS_CUSTOMER_MICROSOFT_USERS":"Benutzer",
    "MENUS_CUSTOMER_ONBOARDING":"Kunden-Onboarding",
    "MENUS_CUSTOMER_ORDERS":"Aufträge",
    "MENUS_CUSTOMER_PRODUCTS":"Produkte",
    "MENUS_CUSTOMER_PROFILE":"Profil",
    "MENUS_CUSTOMER_SHOP":"Shop",
    "MENUS_DAAS":"DaaS<sup class=‘text-danger'>BETA</sup>",
    "MENUS_DISTRIBUTOR_OFFERS":"Händlerangebote",
    "MENUS_DOCUMENTATION":"Dokumentation <sup class='text-risk'>BETA</sup>",
    "MENUS_ENTITY_INVOICES":"Rechnungen",
    "MENUS_FAQ":"FAQ",
    "MENUS_INVITES":"Lädt ein",
    "MENUS_INVOICES":"Rechnungen",
    "MENUS_LICENSE_CONSUMPTION":"Lizenzverbrauch",
    "MENUS_LICENSE_LIST":"Lizenzliste",
    "MENUS_MS_OFFERS":"MS Angebote",
    "MENUS_OPERATING_ENTITIES":"Betriebseinheiten",
    "MENUS_ORDERS":"Aufträge",
    "MENUS_PARTNER_BUNDLES":"Bündel",
    "MENUS_PARTNER_CUSTOMERS":"Kunden",
    "MENUS_PARTNER_DASHBOARD":"Dashboard",
    "MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus",
    "MENUS_PARTNER_PLANS":"Pläne",
    "MENUS_PARTNER_QUOTES":"Zitat",
    "MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Unternehmen",
    "MENUS_PARTNER_SETTINGS":"die Einstellungen",
    "MENUS_POWER_BI_REPORTS":"Azure-Verwendung",
    "MENUS_PRODUCTS":"Produkte",
    "MENUS_PROFILE":"Profil",
    "MENUS_RESELLER_DASHBOARD":"Dashboard",
    "MENUS_RESELLER_INVOICES":"Abrechnung",
    "MENUS_RESELLER_PAYMENTS":"Zahlungen",
    "MENUS_RESELLER_PLANS":"Pläne",
    "MENUS_RESELLER_PRICE_AND_OFFERS":"Preise und Angebote",
    "MENUS_RESELLER_PROFILE":"Organisationsprofil",
    "MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Unternehmen",
    "MENUS_RESELLER_SETTINGS":"die Einstellungen",
    "MENUS_RESELLERS":"Wiederverkäufer",
    "MENUS_SELL":"Verkaufen",
    "MENUS_SELL_DIRECT":"Kunden",
    "MENUS_SELL_INDIRECT":"Indirekt verkaufen",
    "MENUS_SELLER_INDIRECT":"Indirekt verkaufen",
    "MENUS_SHOP":"Shop",
    "MENUS_SUBSCRIPTIONS":"Abonnements",
    "MENUS_SUPPORT":"Unterstützung",
    "MENUS_TAXES":"Steuern",
    "MENUS_USER_MANAGEMENT":"Benutzerverwaltung",
    "merge":"Zweig",
    "MESSAGE_ALREADY_EXISTING":"Die Nachricht ist für die relevanten Entitäten bereits vorhanden",
    "MESSAGE_BODY":"Nachrichtentext",
    "MESSAGE_CUSTOM_NOTIFCATION_SUBMIT_ERROR":"Bitte überprüfen Sie alle erforderlichen Felder und versuchen Sie es erneut",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_DETAILS_ERROR":"Für die Entität Produktvariante/Kunde kann dieselbe Kombination nicht noch einmal eingefügt werden.",
    "MESSAGE_CUSTOM_NOTIFCATION_TAGGED_ENTITIES_ERROR":"Bitte wählen Sie alle Felder aus",
    "MESSAGE_NO_COMMENTS_FOUND":"<div ng-include=\"'app/templates/no-record-found-for-comments-cart.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND":"<div ng-include=\"'app/templates/no-data-message.html'\"></div>",
    "MESSAGE_NO_RECORDS_FOUND_USAGE":"Es sind noch keine Daten zu präsentieren, da sie im aktuellen Abrechnungszeitraum nicht verwendet wurden oder die Rechnung von Microsoft noch nicht verfügbar ist. Bitte versuchen Sie es in Kürze erneut.",
    "MESSAGE_NOTIFCATION_SAVED":"Nachrichtenbenachrichtigung erfolgreich gespeichert",
    "MESSAGE_NOTIFCATION_SUBMIT_ERROR":"Bitte beheben Sie alle Validierungsfehler und versuchen Sie es erneut",
    "MESSAGE_NOTIFICATION_EDIT_MODE":"Nachrichtenbenachrichtigungen bearbeiten",
    "MESSAGE_NOTIFICATION_EDIT_TEXT":"Bearbeiten",
    "MESSAGE_NOTIFICATION_ID":"Ich würde",
    "MESSAGE_NOTIFICATION_NO_RECIPIENTS_SELECTED":"Bitte wählen Sie mindestens einen Empfänger für die Nachrichtenbenachrichtigung aus",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_ACTIONS":"Aktionen",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EFFECTIVE_DATE":"Datum des Inkrafttretens",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_EXPIRATION_DATE":"Läuft aus am",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_BODY":"Nachrichtentext",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_MESSAGE_TYPE":"Nachrichtenstil",
    "MESSAGE_NOTIFICATION_TABLE_HEADER_PAGE_NAME":"Seitenname",
    "MESSAGE_NOTIFICATION_TABLE_NAMES_LIST":"Beteiligte Entitäten",
    "MESSAGE_ONE_APPLICABLE_FOR_ALL_CUSTOM_OFFERS":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "MESSAGE_ONE_PRODUCT_HAS_BEEN_SELECTED":"Ein Produkt wurde ausgewählt",
    "MESSAGE_RECORDS_POP_UP":"Rekordliste",
    "MESSAGE_TEXT_AN_ERROR_HAS_OCCURRED":"Ein Fehler ist aufgetreten",
    "MESSAGE_TEXT_NO_RECORDS_FOUND":"Keine Aufzeichnungen gefunden!",
    "MESSAGE_TEXT_OPERATING_ENTITIES_NOT_CONFIGURED":"Leider sind keine Websites und Abteilungen konfiguriert. Sie können auf der Profilseite neue operative Entitäten hinzufügen",
    "MESSAGE_TEXT_PLEASE_WAIT":"Bitte warten…",
    "MESSAGE_TOOLTIP_AZUREGROUP":"Info, Warnung, Fehler, Gefahr",
    "MESSAGE_TYPE_DROPDOWN_SELECT_MESSAGE_TYPE_TEXT":"Wählen Sie den Nachrichtentyp aus",
    "MESSAGE_TYPE_NAME":"Name des Nachrichtentyps",
    "MESSAGE_WEBHOOK_ENTITIES_ERROR":"Bitte wählen Sie alle Felder aus",
    "MESSAGE_WEBHOOK_INVALID_URL_ERROR":"Ungültige URL. Bitte geben Sie eine gültige URL ein.",
    "MESSAGE_WEBHOOK_SUBMIT_ERROR":"Bitte überprüfen Sie alle erforderlichen Felder und versuchen Sie es erneut",
    "MESSAGE_WEBHOOK_VALID_URL_SUCCESS_MESSAGE":"URL ist gültig!",
    "METERED_BILLING_DATA_POPUP_DECIMAL_ERROR":" Dezimalwerte sind nicht zulässig.",
    "METERED_BILLING_DATA_POPUP_HEADER_TEXT":"Neue Stufe",
    "METERED_BILLING_DATA_POPUP_VALIDATION_ERROR":"Geben Sie eine gültige Nummer ein.",
    "METERED_BILLING_TEXT_MODAL_HEADER":"Nutzungsstufen",
    "Microsoft":"Microsoft",
    "MICROSOFT_ATTESTATION_TYPE_TOOLTIP":" Art der Bescheinigung:",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_BUTTON_TEXT_REMOVE":"Entfernen",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_CATEGORY":"Kategorie",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_DISCOUNT":"Rabatt",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "MICROSOFT_CART_LIST_TEMPLATE_AZURE_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_REMOVE":"Entfernen",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_CATEGORY":"Kategorie",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_DISCOUNT":"Rabatt",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_MARKUP":"Markup",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_PERCENTAGE":"Prozentsatz",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
    "MICROSOFT_CART_LIST_TEMPLATE_ONLINE_SERVICES_POPUP_HEADER_TEXT_LINKED_SUBSCRIPTION":"Verknüpftes Produkt",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_BY":"MCA Akzeptiert von",
    "MICROSOFT_CLOUD_AGREEMENT_ACCEPTED_DATE":"MCA akzeptiert am",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_HEADER_TEXT":"Akzeptanzstatus",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_NOT_PROVIDED":"Nicht bereitgestellt",
    "MICROSOFT_CUSTOMER_AGREEMENT_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT_STATUS_TEXT_PROVIDED":"Bereitgestellt",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER":"Kundenakzeptanz durch Partner bestätigt",
    "MICROSOFT_CUSTOMER_AGREEMENT_TITLE_CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT":"Kundenakzeptanz durch Microsoft",
    "MICROSOFT_CUSTOMER_CONSENT_SUCCESS_MESSAGE":"Microsoft-Kundenvereinbarung erfolgreich gespeichert.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_PASSWORD":"Temporäres Passwort",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_LABEL_USERNAME":"Nutzername",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_MESSAGE":"Der Benutzer wurde erfolgreich erstellt und unten sind die Benutzeranmeldeinformationen aufgeführt.",
    "MICROSOFT_CUSTOMER_USER_CREDENTIAL_POPUP_HEADER":"Nutzerdetails",
    "MICROSOFT_CUSTOMER_USER_PASSWORD_INFORMATION":"Nachdem Sie dieses Popup geschlossen haben, können Sie dieses temporäre Passwort nicht mehr sehen.",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_FAILED":"Erfassung der Microsoft Pricing API-Zustimmung fehlgeschlagen",
    "MICROSOFT_PRICING_API_CONSENT_CAPTURED_SUCCESS":"Zustimmung zur Microsoft Pricing API erfolgreich erfasst",
    "MICROSOFT_PRICING_API_CONSENT_TEST_CONFIRMATION_MESSAGE":"Der Versuch, auf die Microsoft-Preis-API zuzugreifen, war erfolgreich.",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PARTNER_CUSTOMERS":"{{loggedInUserName}} von {{applicationName}} möchte Ihr Microsoft Cloud Solution Provider für Ihr Microsoft Cloud-Konto sein.<br/><br/>Um die Einladung anzunehmen, führen Sie die folgenden 2 Schritte aus:<br/><br/>Schritt 1: Überprüfen Sie hier, ob das Kundenprofil vollständig ist: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Schritt 2: Nachdem das Kundenprofil vollständig ist (nach der Aktualisierung des Profils kann die Aktualisierung bis zu 5 Minuten dauern), klicken Sie auf den folgenden Link, um diese Einladung anzunehmen und {{applicationName}} zu autorisieren, Ihr Microsoft Cloud Solution Provider zu sein und die Microsoft Customer-Einladung anzunehmen. Vereinbarung.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId={{partnerTenantId}}&msppId=0#/partners/invitation <br/><br/>Hinweis: Um die Beziehung zu akzeptieren, ist ein Benutzer mit globaler Administratorberechtigung erforderlich.<br/><br/><br/>Zusätzliche Partnerinformationen:<br/>{{contactEmail}}<br/>{{contactNumber}}",
    "MICROSOFT_RESELLER_RELATIONSHIP_REQUEST_TEXT_FOR_PROVIDER_RESELLER_CUSTOMERS":"Sehr geehrter Kunde,<br/><br/>Um Ihnen eine umfassende Palette an Cloud-Lösungen, -Diensten und -Support bieten zu können, arbeitet {{resellerName}} eng mit {{partnerCompanyName}} zusammen. Um {{resellerName}} und {{partnerCompanyName}} zu autorisieren, Ihr Konto zu bedienen, führen Sie die folgenden 2 Schritte aus: <br/><br/>Schritt 1: Überprüfen Sie hier, ob das Kundenprofil vollständig ist: https://admin.microsoft.com/Adminportal/Home?#/BillingAccounts/billing-accounts <br/><br/>Schritt 2: Nachdem das Kundenprofil vollständig ist (die Aktualisierung kann nach der Aktualisierung maximal 5 Minuten dauern), klicken Sie auf den folgenden Link, um dies zu akzeptieren Einladung.<br/><br/>https://admin.microsoft.com/Adminportal/Home?invType=IndirectResellerRelationship&partnerId={{providerResellerId}}&msppId={{providerBusinessId}}&indirectCSPId={{partnerTenantId}}#/partners/invitation <br/><br/>Hinweis: Um die Beziehung zu akzeptieren, ist ein Benutzer mit globaler Administratorberechtigung erforderlich.<br/><br/>{{resellerName}} bleibt weiterhin Ihr lokaler Experte für Produkte, Abonnements, Lizenzen und Support. Seien Sie versichert, dass die Geschäftsbeziehung mit Ihnen unverändert bleibt.<br/><br/>Bitte zögern Sie nicht, uns zu kontaktieren, wenn Sie Fragen haben. Wir und {{resellerName}} freuen uns auf die Zusammenarbeit mit Ihnen!<br/><br/>Mit freundlichen Grüßen<br/>{{partnerCompanyName}}<br/>{{partnerContactEmail}}<br/>{{partnerContactNumber}}",
    "MICROSOFT_TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Software-Abonnement",
    "MICROSOFT_TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Drittanbieterabonnements",
    "MICROSOFT_TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
    "MICROSOFT_TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
    "MICROSOFT_USERS_DELETE_CONFIRM":"Bestehende Lizenzen und der Zugriff auf das Portal werden entfernt. Sind Sie sicher, dass Sie fortfahren möchten?",
    "MicrosoftNonCSP":"Microsoft Nicht-CSP",
    "MIGRATION_ACCOUNT_CREATED_SUCCESSFULLY":"Migrationskonto erfolgreich mit dem Benutzernamen erstellt:",
    "MIGRATION_BREADCRUMB_BUTTON_TEXT_MIGRATION_ACCOUNTS":"Migrationskonten",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_CUSTOMER":"Kunde",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT":"Migrationskonto",
    "MIGRATION_CAPTION_LIST_TABLE_HEADER_MIGRATION_ACCOUNT_BUTTON_TEXT_CREATE":"Erstellen",
    "MIGRATION_CAPTION_TEXT_MIGRATION_ACCOUNTS":"Migrationskonten",
    "MIGRATION_USER_CREATE_SUCCESS":"Migrationskonto erfolgreich mit dem {{username}} erstellt",
    "MINUTES":"Minute(n)",
    "MISSING_PLAN_OFFER_ADD_FAILED":"Die Abstimmung ist fehlgeschlagen. Wenden Sie sich an den Support, um Unterstützung zu erhalten",
    "MISSING_PLAN_OFFER_ADDED":"Die Abstimmung wurde mit dem ausgewählten Angebot abgeschlossen",
    "MNUAL_SYNC_PURCHASED_DATE":" Gekauftes Datum:",
    "MODAL_HEADER_TEXT_DESC_FILTER":"Filter",
    "Month(s)":"Monat(e)",
    "MULTIPLE_COMMENT_STATIC":"Bemerkungen",
    "MULTIPLE_PAYMENTS_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "MULTIPLE_PAYMENTS_IMPORT_FILE_SELECT_TEXT":"Wählen",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_IMPORT_COMPLETE":"Import abgeschlossen!<br />Gesamt: {{ importProcessedCount }}<br />Fehler: {{ importErrorCount }}<br />Erfolg: {{ importSuccessCount }}",
    "MULTIPLE_PAYMENTS_POPUP_TEXT_VALIDATE_COMPLETE":"Validierung abgeschlossen!<br />Gesamt: {{ validationProcessedCount }}<br />Fehler: {{ validationErrorCount }}<br />Erfolg: {{ validationSuccessCount }}",
    "MULTIPLE_PAYMENTS_RECORD_FORM_INSTRUCTIONS":"Anleitung",
    "Name":"Name",
    "NAME":"Name des Unternehmens",
    "NCE_ACRONYM":"Online-Dienste NCE",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS":"Stornierungsbedingungen",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 Stunden: Volle Rückerstattung",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 Stunden: 1. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 Stunden: 2. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 Stunden: 3. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 Stunden: 4. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 Stunden: 5. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 Stunden: 6. Tag anteilig",
    "NCE_CANCELLATION_POLICY_INSTRUCATIONS_8":"Nach 7 Kalendertagen: Eine Stornierung ist nicht zulässig",
    "NCE_INSTRUCTIONS_HEADING":"NCE-Anweisungen",
    "NCE_LINKED_PRODUCT_ESTIMATE_SALE_PRICE_TEXT":"Geschätzter Verkaufspreis des verknüpften Produkts",
    "NCE_PROMOTION_WARNING_MESSAGE":"<p class='font-weight-bold text-danger'>Dieses folgende Produkt ist nicht für Werbeaktionen berechtigt. Bitte wenden Sie sich an den Support, um die Aktion zu beantragen. Wenn Sie dann keine Beförderung wünschen, fahren Sie mit Ihrem Prozessauftrag fort.</p>",
    "NCE_PROMOTIONS_APPLIED":"Diese Aktion wird angewendet",
    "NCE_PROMOTIONS_AVAILABLE_TEXT":"Aktion verfügbar",
    "NCE_PROMOTIONS_CHECK_ELIGIBILITY":"Überprüfen Sie die Förderberechtigung",
    "NCE_PROMOTIONS_NOT_APPLIED":"Die Aktion wird noch nicht angewendet",
    "NCE_PROMOTIONS_POPUP_AVAILABLE_PROMOTIONS":"Verfügbare Aktionen",
    "NCE_PROMOTIONS_POPUP_BILLING_FREQUENCY":"Abrechnungshäufigkeit",
    "NCE_PROMOTIONS_POPUP_CANCEL":"Stornieren",
    "NCE_PROMOTIONS_POPUP_CLOSE":"Schließen",
    "NCE_PROMOTIONS_POPUP_CUSTOMER_ELIGIBILITY_VALIDATED":"Die Berechtigung des Kunden wird bei der Überprüfung des Einkaufswagens validiert",
    "NCE_PROMOTIONS_POPUP_DISCOUNT":"Rabatt",
    "NCE_PROMOTIONS_POPUP_END_DATE":"Endtermin",
    "NCE_PROMOTIONS_POPUP_PROMO_DISCOUNT":"Aktionsrabatt zum Start des neuen Handels",
    "NCE_PROMOTIONS_POPUP_PROMOTION_DETAILS":"Aktionsdetails",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_ID":"ICH WÜRDE:",
    "NCE_PROMOTIONS_POPUP_PROVIDER_REF_Name":"Name:",
    "NCE_PROMOTIONS_POPUP_SUBMIT":"Anwenden",
    "NCE_PROMOTIONS_POPUP_TERM":"Begriff",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_1":"Anzahl/Plätze können jederzeit erhöht werden.",
    "NCE_QUANTITY_CHANGE_INSTRUCATION_2":"Die Anzahl/Plätze können innerhalb von 7 Kalendertagen auf die vorherige oder die Summe der vorherigen Anzahl/Plätze reduziert werden.",
    "NCE_QUANTITY_CHANGE_INSTRUCATIONS":"Mengenänderung",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS":"Planen Sie eine Verlängerungsänderung",
    "NCE_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Der aktuelle Zeitplan wird gelöscht, wenn Menge/Status der automatischen Verlängerung/Abrechnungszeitraum/Laufzeit geändert wird.",
    "NCE_SKU_NOT_PRESENT_IN_PLAN":"Produkt nicht im Kundenplan verfügbar",
    "NCE_TERMS_AND_CONDITION_IS_NOT_ACCEPTED_ERROR_MESSAGE":"Bitte stimmen Sie allen Bedingungen zu",
    "NCE_TEXT":"Neuer Handel ...",
    "NEW_BILLING_CYCLE":"Neuer Abrechnungszeitraum",
    "NEW_COMMENT_SEND":"Senden",
    "NEW_COMMENTS_RECIEVED":"Neuer Kommentar erhalten",
    "NEW_CUSTOMER_ONBOARDING_ERRORS":"Kauf-/Aktualisierungsprodukte können fehlschlagen",
    "NEW_DISTRIBUTOR_PRODUCT_ICON":"Neues Symbol",
    "NEW_PARTNER_PRODUCT_ICON":"Neues Symbol",
    "NewPrice":"NeuPreis",
    "NEXT_SCHEDULE_RENEWAL_CHANGE":"Nächste Änderung des Fahrplans",
    "NGTABLE_PLACEHOLDER_FOR_SEARCH_BAR":"Suche...",
    "NO_CART_LINE_ITEM_EXSITS_WITH_ID":"Es ist kein Artikel mit der ID: {cartLineItemId} im Warenkorb vorhanden",
    "NO_CUSTOMER_ADMIN_AVAILABLE_FOR_THE_SELECTED_TENANT":"Für diesen Mandanten ist kein Kundenadministrator verfügbar.",
    "NO_CUSTOMER_ON_RECORD_CURRENCY_CONVERSION_PLACEHOLDER_TEXT":"Keine Kunden registriert",
    "NO_EXISTING_PLAN_FOR_THE_CUSTOMER":"Kein bestehender Plan",
    "NO_PAYMENT_TYPES_ENABLED":"Es sind keine Zahlungsarten aktiviert. Bitte wenden Sie sich an den Support.",
    "NO_PLAN_WITH_OFFER_AVAILABLE":"Es gibt keinen Plan mit allen verfügbaren Angeboten. Bitte erstellen Sie eines",
    "NO_PRODUCT_SELECTED_PROMPT":"Bitte wählen Sie mindestens ein Produkt aus.",
    "NO_PROVIDER_PAYMENT_DETAILS_WARNING_MESSAGE":"Für den ausgewählten Abrechnungszeitraum oder Anbieter wurden keine Rechnungsdetails gefunden",
    "NO_RESOURCE_GROUP_LEFT":"Keine Ressourcengruppe mehr zuzuweisen/Keine Datensätze gefunden!",
    "NON_CSP_OFFER_DETAILS_CAPTION_TEXT":"Nicht CSP Produktdetails",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_ID":"Service Principal Id",
    "NON_CSP_OFFER_DETAILS_SERVICE_PRINCIPAL_KEY":"Service-Hauptschlüssel",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_ID":"Abonnement-ID",
    "NON_CSP_OFFER_DETAILS_SUBSCRIPTION_NAME":"Abonnementname",
    "NON_RESELLER_PRODUCT_EXIST_EXCEPTION":"Reseller-Benutzer können im Reseller-Plan kein Partnerprodukt hinzufügen.",
    "NOT_MAPPED_PRODUCTS_AGAINEST_PLAN_HEADER":"Folgende Anbieterprodukte werden nicht mit Planprodukten verknüpft. Wenn Sie fortfahren möchten, klicken Sie auf Weiter. Andernfalls klicken Sie auf Abbrechen und ordnen Sie diese Produkte zu",
    "NOT_MAPPED_PRODUCTS_HEADER_PROVIDER_PRODUCTS":"Anbieterprodukte",
    "NOT_VALID_MESSAGE":"ist keine gültige E-Mail",
    "NOTIFICATION_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "NOTIFICATION_BUTTON_TO_CREATE_NEW_NOTIFICATION":"Bannerbenachrichtigung erstellen",
    "NOTIFICATION_CUSTOMER_TAG_DELETED_SUCCESSFULLY_MESSAGE":"Kundentag erfolgreich gelöscht",
    "NOTIFICATION_PRODUCT_NAME_CHANGED_SUCCESSFULLY":"Der Abonnementname wurde erfolgreich geändert",
    "NOTIFICATION_VALUE_WILL_BE_UPDATED_SHORTLY":"Dieser Wert wird in Kürze aktualisiert",
    "notifications":"Benachrichtigung",
    "NOTIFIER_ERROR_CHARACTER_LENGTH_ERROR":"Die Zeichenlänge muss zwischen 2 und 200 Zeichen liegen",
    "NOTIFIER_ERROR_PONUMBER_LENGTH_ERROR":"Die Zeichenlänge muss zwischen 2 und 50 Zeichen liegen",
    "NOTIFIER_SUCCESS_CUSTOM_ENDATE_SAVED_SUCCESSFULLY":"Benutzerdefiniertes Enddatum erfolgreich gespeichert",
    "NOTIFY_ERROR_WEBHOOK_URL_INVALID":"Ungültige Webhook-URL",
    "NOTIFY_SUCCESS_WEBHOOK_URL_VALID":"Gültige Webhook-URL",
    "NOTIFY_WITH_ZIP_OF_INVOICES_HELP_TEXT":"Geben Sie eine E-Mail-Adresse ein und klicken Sie auf OK, um eine Zip-Datei mit einer Liste der PDF-Rechnungen für den ausgewählten Abrechnungszeitraum zu erhalten",
    "NOTIFY_WITH_ZIP_OF_INVOICES_SUCCESS_MESSAGE":"Eine E-Mail mit einer Liste von Rechnungs-PDFs (Zip) als Anhang wird in Kürze an {{emailAddress}} gesendet.",
    "NOVEMBER":"November",
    "NUMBER_OF_CUSTOMERS_FAILED_TO_ONBOARD":"CustomersFailedToOnboard",
    "NUMBER_OF_CUSTOMERS_ONBOARDED_SUCCESSFULLY":"CustomersOnboardedSuccessfully",
    "NUMBER_OF_CUSTOMERS_SELECTED_FOR_BULK_ONBOARD":"CustomersSelectedUnderBatch",
    "NUMBER_OF_PROMOTIONS_LOADED":"Anzahl der geladenen Angebote",
    "OCTOBER":"Oktober",
    "OFFER_FRIENDLY_NAME_VALIDATION_MESSAGE_FOR_UNIQUE":"Angebot Freundlicher Name {{FriendlyName}} muss eindeutig sein",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_NAME":"Angebote mit erworbenem Abonnement anzeigen",
    "OFFER_WITH_PURCHASED_SUBSCRIPTION_VALUE":"offerWithPurchasedSubscription",
    "offers":"Bietet an",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "OFFERS_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
    "OFFERS_BUTTON_HEADER_TEXT_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
    "OFFERS_CAPTION_TEXT_ADD_SUBSCRIPTIONS":"Abonnements hinzufügen",
    "OFFERS_HEADER_TEXT_OFFERS":"Bietet an",
    "OFFERS_NO_MATCH_MESSAGE":"Keine passenden Ergebnisse für Name gefunden:",
    "OFFERS_SUB_HEADER_TEXT_ITEMS_SELECTED":"Ausgewählte Elemente",
    "OFFERS_TABLE_HEADER_TEXT_DISCOUNT_PERCENTAGE":"Rabattprozentsatz",
    "OFFERS_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "OFFERS_TABLE_HEADER_TEXT_QUANTITY":"Menge (Anzahl der Lizenzen)",
    "OFFERS_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Preis pro Sitzplatz (pro Monat)",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL":"Gesamt",
    "OFFERS_TABLE_HEADER_TEXT_TOTAL_AMOUNT":"Gesamtmenge",
    "OLD_BILLING_CYCLE":"Alter Abrechnungszeitraum",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_FAILED":"Das Onboarding eines Kunden unter Bulk Onboard ist fehlgeschlagen",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_INITIATED":"Onboarding eines Kunden unter Bulk Onboard initiiert",
    "ONBOARD_A_CUSTOMER_UNDER_BULK_ONBOARD_SUCCESS":"Onboarding eines Kunden unter großem Erfolg an Bord",
    "ONBOARD_BILLING_CUSTOMER_MATCH_FOUND_MESSAGE":"<p/><p>Es wurde eine Übereinstimmung für die ID <strong>{{billingProviderReferenceID}}</strong> im Abrechnungssystem gefunden.<br/><span> Die Abrechnungsadresse in der Datei lautet <strong> {{formattedAddress}} </strong>.</span><br/> Bitte geben Sie sich aus und verwenden Sie die Option <strong>Profil</strong>, um dieses Detail zu ändern, nachdem die Rechnungsdetails des Kunden erfolgreich eingebunden wurden.",
    "ONBOARD_CUSTOMER_LINKED_OR_BUNDLE_PRODUCT_MISSING_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie auch {{secondProduct}} zuordnen.",
    "ONBOARD_CUSTOMER_LINKED_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Wenn {{firstProduct}} gemappt werden muss, sollten Sie {{secondProduct}} nicht ignorieren",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_IGNORED_VALIDATION_MESSAGE":"Wenn {{firstProduct}} zugeordnet werden muss, sollten Sie {{secondProduct}} einschließen",
    "ONBOARD_CUSTOMER_PARENT_PRODUCT_MISSING_VALIDATION_MESSAGE":"Wenn {{firstProduct}} gemappt werden muss, sollten Sie auch {{secondProduct}} mappen, d.h.; Mindestmenge sollte 1 sein.",
    "ONBOARD_CUSTOMER_QUANTITY_MISMATCH_VALIDATION_MESSAGE":"Anbietermenge und freigegebene Menge sollten für {{product}} übereinstimmen",
    "ONBOARD_CUSTOMER_SIBLING_PRODUCT_QUNATITY_VALIDATION_MESSAGE":"Menge sollte für {{firstProduct}} und {{secondProduct}} gleich sein",
    "ONBOARD_EXISTING_PROVIDER_CUSTOMER_DROPDOWN_TOOLTIP":"Wählen Sie CSPCustomer to OnBoard",
    "ONBOARD_NEW_CUSTOMER_HEADING_PRIMARY_ACCOUNT_DETAILS_TEXT":"Hauptkontodetails",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_1":"Ausfüllen",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_2":"Laden Sie eine CSV-Vorlage herunter",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_1_3":"durch Hinzufügen von Zeilen nach der Kopfzeile. Jede Zeile sollte dem Detail eines Wiederverkäufers entsprechen.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_10":"Die Bearbeitung würde eine Weile dauern und der Status wird auf dieser Seite gemeldet. Wenn Sie sich von der Seite entfernen oder den Browser versehentlich geschlossen haben, könnten Sie wieder auf diese Seite zurückkehren und das System würde den Status Ihres vorherigen Uploads anzeigen.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Klicken Sie auf Auswählen und navigieren Sie zu der Datei, die Sie hochladen möchten.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Die Eingabe wird validiert, um sicherzustellen, dass die folgenden Regeln erfüllt sind:",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Der Reseller-Name ist erforderlich und sollte nicht länger als 50 Zeichen sein.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Die Reseller-ID ist erforderlich und sollte nicht länger als 50 Zeichen sein. Dies sollte die Beziehungs-GUID des Handelspartners von Partner Center sein.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_6":"Die Reseller-MPN-ID ist erforderlich und sollte nicht länger als 50 Zeichen sein. Dies sollte die Microsoft Partner Network-ID des Wiederverkäufers sein.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Der Standort ist erforderlich und sollte der aus zwei Buchstaben bestehende Ländercode des Wiederverkäufers sein. Beispiel: USA, Großbritannien usw.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_8":"E-Mail ist optional, sollte aber eine gültige E-Mail-Adresse sein, falls eine eingegeben wird.",
    "ONBOARD_RESELLER_UPLOAD_FORM_INSTRUCTIONS_PART_9":"Klicken Sie auf <strong class=\"text-uppercase\">Senden</strong>, damit die Daten verarbeitet werden.",
    "ONBOARDING":"Onboarding",
    "ONBOARDING_ANALYTICS_DOWNLOAD_REPORT":"Bericht herunterladen",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_TYPE_OF_CUSTOMER":"Wählen Sie den Kundentyp",
    "ONBOARDING_ANALYTICS_PLACEHOLDER_UNIT_OF_DURATION":"Dauereinheit auswählen",
    "ONBOARDING_ANALYTICS_SEARCH_CLOSE_OPTION":"Schließen",
    "ONBOARDING_ANALYTICS_SEARCH_LABEL_RESELLERS":"Wiederverkäufer",
    "ONBOARDING_ANALYTICS_SEARCH_PLACEHOLDER_SELECT_RESELLERS":"Reseller auswählen",
    "ONBOARDING_ANALYTICS_SEARCH_RESET_OPTION":"Zurücksetzen",
    "ONBOARDING_ANALYTICS_SEARCH_SUBMIT":"Suche",
    "ONBOARDING_ANALYTICS_SEARCH_TYPE_OF_CUSTOMER":"Kundentyp",
    "ONBOARDING_ANALYTICS_SEARCH_UNIT_OF_DURATION":"Einheit der Dauer",
    "ONBOARDING_DURATION_UNIT_MONTH":"Monat",
    "ONBOARDING_DURATION_UNIT_WEEK":"Woche",
    "ONBOARDING_DURATION_UNIT_YEAR":"Jahr",
    "ONBOARDING_GRAPH_COUNT_TEXT":" Zählen:",
    "ONBOARDING_MICROSOFT_CSP_CUSTOMERS_IS_NOT_ALLOWED_FOR_RESELLER":"Das Onboarding von Kunden ist nicht zulässig, da keine Verbindung zum Provider Reseller besteht. Bitte wenden Sie sich an Ihren Partner.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED":"Das Onboarding eines MicrosoftNonCSP-Kunden wird nicht unterstützt. Bitte verknüpfen Sie den Kunden mit einem bestehenden C3-Kunden.",
    "ONBOARDING_MICROSOFT_NON_CSP_CUSTOMER_IS_NOT_SUPPORTED_TITLE":"Das Onboarding eines MicrosoftNonCSP-Kunden wird nicht unterstützt!",
    "ONBOARDING_STATUS":"Onboarding-Status",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_FOR_MAPPING_PRODUCTS":"Zuordnung der Kundenprodukte des Anbieters zu den Planprodukten...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_MESSAGE_PLAN":"Ladepläne...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOADING_PROVIDER_CUSTOMERS":"Laden der Provider-Kunden...",
    "ONBOARDING_THE_MICROSOFT_CUSTOMER_LOOKING_UP_CUSTOMER_DETAILS_IN_C3":"Nachschlagen der Kundendaten in c3...",
    "ONBOARDING_TYPES_OF_CUSTOMERS_ALL":"Alle Kunden",
    "ONBOARDING_TYPES_OF_CUSTOMERS_DIRECT":"Direktkunden",
    "ONBOARDING_TYPES_OF_CUSTOMERS_RESELLER":"Reseller-Kunden",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_ARE_YOU_SUR_YOU_WANT_TO_DELETE":"Sind Sie sicher, dass Sie löschen möchten?",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_PLACEHOLDER_SELECT_A_PRODUCT":"Wählen Sie ein Produkt in der Liste aus oder suchen Sie nach Produktnamen",
    "ONLINCE_SERVICES_USER_LICENSE_TRACKING_USERS_REMOVED_SUCCESSFULLY":"Die Anforderung, Lizenzen von Benutzern zu entfernen, wird akzeptiert und verarbeitet.",
    "OnlineServicesNCE":"Online-Dienst NCE",
    "Open":"Öffnen",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
    "OPERATING_ENTITIES_TABLE_HEADER_PLAN_NAME":"Plan Name",
    "OPERATING_ENTITY_CUSTOMER":"Kunde",
    "OPERATING_ENTITY_ORGANIZATIONAL_SETUP":"Einrichtung der Organisation",
    "OPERATING_ENTITY_SITES_AND_DEPARTMENT":"Standorte & Abteilungen",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "OPERATIONAL_ENTITIES_BUTTON_TEXT_RELEASE":"Freisetzung",
    "ORDER_BACK_TO_ORDER_DETAILS":"Zurück zu den Produkten",
    "ORDER_DETAILS_ENTITY_NAME":"Entitätsname",
    "ORDER_DETAILS_FIELD_ORDERED_ON":"Bestellt",
    "ORDER_DETAILS_SCHEDULED_DATE":"Geplantes Datum",
    "ORDER_ID":"Auftragsnummer",
    "ORDER_LBL_TEXT_PROMOTIONID":"Angebots-ID",
    "ORDER_LIST_STATUS_SUCCESS":"Erfolg",
    "ORDER_STATUS_APPROVAL_ACCEPTED":"Zur Kundenbewertung zugelassen",
    "ORDER_STATUS_APPROVAL_REQUIRED":"Genehmigung erforderlich",
    "ORDER_STATUS_COMMENTS":"Kommentare ansehen",
    "ORDER_STATUS_DELETED":"Gelöscht",
    "ORDER_STATUS_DESC_ACCEPTED":"Akzeptiert",
    "ORDER_STATUS_DESC_FAILED":"Gescheitert",
    "ORDER_STATUS_DESC_IGNORE":"Ignorieren",
    "ORDER_STATUS_DESC_INPROGRESS":"In Bearbeitung",
    "ORDER_STATUS_DESC_ONHOLD":"In Wartestellung",
    "ORDER_STATUS_DESC_ORDERED":"Bestellt",
    "ORDER_STATUS_DESC_PARTIALLYFULFILLED":"Teilweise erfüllt",
    "ORDER_STATUS_DESC_PROCESSING":"wird bearbeitet",
    "ORDER_STATUS_DESC_PROVISIONING":"Bereitstellung",
    "ORDER_STATUS_DESC_REJECTED":"Abgelehnt",
    "ORDER_STATUS_DESC_SCHEDULED":"Geplant",
    "ORDER_STATUS_DESC_SUCCESS":"Erfolg",
    "ORDER_STATUS_ERRORS_IGNORED":"Fehlgeschlagen <br/> (Fehler überprüft und ignoriert)",
    "ORDER_STATUS_FAILED":"Gescheitert",
    "ORDER_STATUS_INPROGRESS":"Arbeiten daran",
    "ORDER_STATUS_ONHOLD":"In Wartestellung",
    "ORDER_STATUS_ORDERED":"Bestellt",
    "ORDER_STATUS_PROCESSING":"Arbeiten daran",
    "ORDER_STATUS_PROVISIONING":"Arbeiten daran",
    "ORDER_STATUS_REACTIVATED":"Reaktiviert",
    "ORDER_STATUS_REJECTED":"Abgelehnt",
    "ORDER_STATUS_SCHEDULED":"Geplant",
    "ORDER_STATUS_SUCCESS":"Bestellung erfüllt",
    "ORDER_STATUS_SUSPENDED":"Suspendiert",
    "OUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Angebot genehmigt und Bestellung erfolgreich aufgegeben.<br/> Bestell-ID ist <b>{{OrderNumber}}</b>",
    "OUOTE_APPROVAL_SUCCESS_MESSAGE":"Angebot erfolgreich genehmigt.",
    "OWNER_ENTITY_NAME":"Name der Eigentümereinheit",
    "PAGE_HEADER_TEXT_PAYMENT_STATUS_INVOICE_DETAIL":"Details zum Zahlungsstatus",
    "PaidAmount":"Bezahlter Betrag",
    "PARENT_ENTITY_NAME":"Name der übergeordneten Entität",
    "PARENT_OFFER_NOT_ACTIVE_EXCEPTION":"Das Add-On kann nicht aktiviert werden, wenn das übergeordnete Angebot nicht aktiv ist.",
    "PARENT_PRODUCT_NOT_EXISTS":"Ohne Eltern kann kein Produkt an Bord gehen!",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_KEYS_AVAILABLE":"Für diesen Anbieter sind keine Schlüssel verfügbar",
    "PARTEER_SETTINGS_PROVIDERS_LABEL_TEXT_NO_PROVIDERS_CONFIGURED":"Es sind keine Anbieter konfiguriert. Bitte wenden Sie sich an den Support",
    "PARTNE_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Speichern Sie Ihre Änderungen",
    "PARTNE_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_DISPLAY_CURRENCY":"Währung anzeigen",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_INVOICE_CURRENCY":"Rechnungswährung",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makrowert",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_NAME":"Name",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_PURCHASE_CURRENCY":"Kaufwährung",
    "PARTNE_PLAN_DETAILS_LABEL_TEXT_SELECT":"Wählen",
    "partner":"Partner",
    "Partner":"Partner",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_BUTTON_TEXT_UNASSIGN":"Zuordnung aufheben",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMER_NAME":"Kundenname",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_BTN_TEXT_BACK_TO_ACCOUNT_MANAGERS":"Zurück zu den Account Managern",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE":"Kunden von Account Manager",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_PAGE_TITLE_WITH_NAME":"Account-Manager Kunden <span class='text-primary'> {{FirstName}} </span>",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_ACTIONS":"Aktionen",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Name",
    "PARTNER_ACCOUNT_MANAGER_CUSTOMERS_TABLE_HEADER_INTERNAL_ID":"C3Id",
    "PARTNER_ACCOUNT_MANAGER_RESELLER_NAME":"Verkäufer Name",
    "PARTNER_ADD_MISSING_OFFERS_TO_ALL_PLAN_BUTTON":"Aktualisieren Sie alle Pläne mit den neuesten Angeboten",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
    "PARTNER_ADD_PLAN_ADDONS_POPUP_MESSAGE_NO_ADDONS_WERE_FOUND":"Es wurden keine Addons gefunden",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Plan löschen",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Planangebote überprüfen",
    "PARTNER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Plan speichern",
    "PARTNER_ADD_PLAN_PARENT_TRAIL_POPUP_MESSAGE_NO_TRAIL_WERE_FOUND":"Für das Testangebot wurde kein übergeordnetes Angebot gefunden. Fügen Sie das Elternangebot hinzu und versuchen Sie es erneut",
    "PARTNER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "PARTNER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Menge",
    "PARTNER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Verwendung",
    "PARTNER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_FROM_TOOLTIP":"Geben Sie das Gültigkeitsdatum ein von",
    "PARTNER_ADMINISTRATION_EFFECTIVE_DATE_TO_TOOLTIP":"Geben Sie das Gültigkeitsdatum bis ein",
    "PARTNER_ADMINISTRATION_MESSAGE_TOOLTIP":"Nachricht eingeben",
    "PARTNER_ADMINISTRATION_MESSAGE_TYPE_TOOLTIP":"Nachrichtentyp auswählen",
    "PARTNER_ADMINISTRATION_PAGE_PORTAL_TOOLTIP":"Seitenportal auswählen",
    "PARTNER_ALL_COMMENT_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Symbole / Kommunikation / Gruppenchat",
    "PARTNER_APPROVAL_PENDING":" Partnergenehmigung -",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_ALERT":"zum Bündel hinzugefügt",
    "PARTNER_BUNDLE_ADDED_TO_BUNDLE_WITH_ADDON_ALERT":"hinzugefügt, um mit Add-Ons zu bündeln",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
    "PARTNER_BUNDLE_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "PARTNER_BUNDLE_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "PARTNER_BUNDLE_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
    "PARTNER_BUNDLE_ALREADY_ADDED_TO_BUNDLE_ALERT":"ist bereits zum Bundle hinzugefügt und kann nicht erneut hinzugefügt werden",
    "PARTNER_BUNDLE_BUTTON_TEXT_FILTERS":"Filter",
    "PARTNER_BUNDLE_BUTTON_TEXT_RESET":"Zurücksetzen",
    "PARTNER_BUNDLE_BUTTON_TEXT_SEARCH":"Suche",
    "PARTNER_BUNDLE_BUTTON_TEXT_VALIDITY":"Gültigkeit",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION":"Laufzeit",
    "PARTNER_BUNDLE_CREATION_LABEL_TEXT_TERM_DURATION_TOOLTIP":"Laufzeit",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_BUNDLE_LABEL_TEXT_BILLING_TYPES":"Abrechnungsarten",
    "PARTNER_BUNDLE_LABEL_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_FROM":"Selbstkostenpreis (ab)",
    "PARTNER_BUNDLE_LABEL_TEXT_COST_PRICE_TO":"Selbstkostenpreis (bis)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_FROM":"Verkaufspreis (ab)",
    "PARTNER_BUNDLE_LABEL_TEXT_SALE_PRICE_TO":"Verkaufspreis (bis)",
    "PARTNER_BUNDLE_LABEL_TEXT_SLAB_DATA":"Plattendaten",
    "PARTNER_BUNDLE_LABEL_TEXT_TO":"- zu -",
    "PARTNER_BUNDLE_LABEL_TEXT_VALIDITY_TYPE":"Gültigkeitsart",
    "PARTNER_BUNDLE_SEARCH_CONSIDER_DELETED_BUNDLES":"Gelöschte Pakete einbeziehen",
    "PARTNER_BUNDLE_SELECT_ATLEASE_ONE_PRODUCT_ALERT":"Bitte wählen Sie mindestens ein Produkt aus.",
    "PARTNER_BUNDLE_SELECT_ATLEAST_TWO_OFFERS_ALERT":"Bitte wählen Sie mindestens 2 Angebote aus, die zusammengelegt werden sollen",
    "PARTNER_BUNDLE_SELLING_PRICE_NOT_PROVIDED_ALERT":"Bitte Verkaufspreis angeben",
    "PARTNER_BUNDLE_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden!",
    "PARTNER_BUNDLE_TEXT_INFO_PRODUCTS_FOUND":"Produkte bündeln",
    "PARTNER_BUNDLES_BREADCRUMB_BUTTON_TEXT_PARTNER_BUNDLES":"Bündel",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD":"Hinzufügen",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_BUNDLE":"Neues Bundle",
    "PARTNER_BUNDLES_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
    "PARTNER_BUNDLES_BUTTON_TEXT_BACK_TO_BUNDLE_DETAILS":"Zurück zu den Bundle-Details",
    "PARTNER_BUNDLES_BUTTON_TEXT_CANCEL":"Stornieren",
    "PARTNER_BUNDLES_BUTTON_TEXT_DELETE":"Löschen",
    "PARTNER_BUNDLES_BUTTON_TEXT_DOWNLOAD_BUNDLE":"Pakete herunterladen",
    "PARTNER_BUNDLES_BUTTON_TEXT_EDIT":"Bearbeiten",
    "PARTNER_BUNDLES_BUTTON_TEXT_INFO_TEXT_SEARCH_APPLIED":"Suche angewendet:",
    "PARTNER_BUNDLES_BUTTON_TEXT_NEXT":"Nächster",
    "PARTNER_BUNDLES_BUTTON_TEXT_REVIEW_BUNDLE_PRODUCTS":"Überprüfen Sie die Bundle-Produkte",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE":"speichern",
    "PARTNER_BUNDLES_BUTTON_TEXT_SAVE_BUNDLE":"Bundle speichern",
    "PARTNER_BUNDLES_BUTTON_TEXT_SHOW_OFFERS":"Angebote anzeigen",
    "PARTNER_BUNDLES_BUTTON_TEXT_TITLE_QUICK_SEARCH":"Schnelle Suche",
    "PARTNER_BUNDLES_CAPTION_BUTTON_TEXT_BACK_TO_PARTNER_BUNDLES":"Zurück zu den Partnerpaketen",
    "PARTNER_BUNDLES_CAPTION_TEXT_ADD":"Bundle hinzufügen",
    "PARTNER_BUNDLES_CAPTION_TEXT_VIEW":"Bundle anzeigen",
    "PARTNER_BUNDLES_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
    "PARTNER_BUNDLES_INFO_TEXT_SUGGESTED_PRICE":"Vorgeschlagener Preis:",
    "PARTNER_BUNDLES_LABEL_TEXT_AUTO_RENEWAL":"Ist Auto erneuerbar?",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PARTNER_BUNDLES_LABEL_TEXT_BILLING_Type":"Abrechnungsart",
    "PARTNER_BUNDLES_LABEL_TEXT_BUNDLING":"Zum Bündeln verfügbar?",
    "PARTNER_BUNDLES_LABEL_TEXT_CATEGORY":"Kategorie",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS":"Ladezeitraum",
    "PARTNER_BUNDLES_LABEL_TEXT_CHARGE_PERIODS_HELP_TEXT":"Ladezeitraum",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE":"Verbrauchsart",
    "PARTNER_BUNDLES_LABEL_TEXT_CONSUMPTION_TYPE_HELP_TEXT":"Hilfstext",
    "PARTNER_BUNDLES_LABEL_TEXT_COST_PRICE":"Selbstkostenpreis",
    "PARTNER_BUNDLES_LABEL_TEXT_Description":"Beschreibung",
    "PARTNER_BUNDLES_LABEL_TEXT_FEED_SOURCE":"Futterquelle",
    "PARTNER_BUNDLES_LABEL_TEXT_IMMEDIATE_PROVISONING":"Sofort zum Verzehr verfügbar?",
    "PARTNER_BUNDLES_LABEL_TEXT_NAME":"Name",
    "PARTNER_BUNDLES_LABEL_TEXT_NO_OF_DAYS_FOR_FREE_CANCELATION":"Anzahl der Tage für eine kostenlose Stornierung",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION":"Abrechnungsregel für den Kauf von Sitzplätzen",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_PURCHASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION":"Abrechnungsregel für Sitzplatzreduzierung",
    "PARTNER_BUNDLES_LABEL_TEXT_ON_RELEASE_BILLING_ACTION_HELP_TEXT":"Mengenänderungen werden anteilig oder vollständig für die neue Menge berechnet",
    "PARTNER_BUNDLES_LABEL_TEXT_PROVIDER":"Anbieter",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_PRICE":"Verkaufspreis",
    "PARTNER_BUNDLES_LABEL_TEXT_SALE_TYPE":"Verkaufsart",
    "PARTNER_BUNDLES_LABEL_TEXT_VALIDITY":"Gültigkeit",
    "PARTNER_BUNDLES_PLACEHOLDER_SEARCH":"Suche nach Produkten",
    "PARTNER_BUNDLES_TAB_TITLE_QUANTITY":"Menge",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_DESC":"Beschreibung",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_NAME":"Name",
    "PARTNER_BUNDLES_TABLE_HEADER_TEXT_OFFER_DETAILS":"Angebotsdetails",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "PARTNER_BUNDLES_TABLE_TD_BUTTON_TOOLTIP_TEXT_VIEW":"Aussicht",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_CATEGORIES":"Kategorien",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_PROVIDERS":"Anbieter",
    "PARTNER_BUNDLES_TITLES_FOR_FILTERS_SERVICE_TYPES":"Servicetypen",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_COST_PRICE":"Kosten für den Partner",
    "PARTNER_BUNDLES_TOOLTIP_TEXT_SALE_PRICE":"Verkaufspreis",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE":"Mehrere Zahlungen",
    "PARTNER_BUSINESS_MULTIPLE_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Mehrere Zahlungen",
    "PARTNER_BUSINESS_PAYMNENTS_TITLE_VIEW_BREADCRUMB":"Zahlungen",
    "PARTNER_BUSINESS_REVENUE_REPORT_RELOAD_MESSAGE":"Bitte aktualisieren Sie den Umsatzbericht, indem Sie auf die Schaltfläche „Neu laden“ klicken, bevor Sie fortfahren.",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_NO_HISTORY_FOUND":"Keine Geschichte gefunden!",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SEARCH_APPLIED":"Suche angewendet",
    "PARTNER_BUSINESS_SUBCRIPTION_CHANGE_HISTORY_SERVICE_TYPES":"Servicetypen",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "PARTNER_BUSINESS_SUBSCRIPTIONCHANGE_HISTORY_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "PARTNER_COMMENTS_DETAILS":"Bemerkungen",
    "PARTNER_COMMENTS_TAB_CART_ITEM":"Warenkorb Artikel",
    "PARTNER_COMMENTS_TAB_INVOICE":"Rechnung",
    "PARTNER_COMMENTS_TAB_PRODUCT":"Produkt",
    "PARTNER_COMMISIONS":"Partnerprovisionen",
    "PARTNER_CONSENT_HELP_TEXT":"Kopieren Sie den Link, indem Sie auf die obige Schaltfläche klicken, das Inkognito-Fenster neu öffnen und den Zustimmungsablauf abschließen.",
    "PARTNER_CONTACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_BILLING_TYPE_FOR_THE_SLAB":"Bitte wählen Sie Abrechnungstyp für die Platte",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_ADD":"Hinzufügen",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_DELETE":"Löschen",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "PARTNER_CONTRACT_OFFERS_TOOLTIP_TEXT_SAVE":"speichern",
    "PARTNER_CONTRACT_OFFERS_VALIDATION_MESSAGE_PLEASE_SELECT_APPLICABILITY_FOR_THE_OFFER":"Bitte wählen Sie die Anwendbarkeit für das Angebot aus",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_DETAILS_POPUP_BUTTON":"OK",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_EMAIL":"Email",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_NAME":"Name",
    "PARTNER_CUSTOMER_ACCOUNT_MANAGER_PHONE_NUMBER":"Telefonnummer",
    "PARTNER_CUSTOMER_FILTER_NAME":"Name",
    "PARTNER_CUSTOMER_FILTER_PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "PARTNER_CUSTOMER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{customer}} ist keinem Account Manager zugeordnet",
    "PARTNER_CUSTOMER_NOT_LINKED_TO_ANY_PROVIDER_TENANTS_ERROR":"Es sind keine Kunden mit einem Anbietermieter verbunden.",
    "PARTNER_CUSTOMER_PLAN_ASSIGN_CONFIRMATION_TEXT":"Durch die Zuweisung dieses Plans werden die Angebote mit den im Plan definierten Markups eingeschlossen. Möchten Sie den Planstatus wirklich aktualisieren?",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ASSIGN":"Zuordnen",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_DISABLE":"Deaktivieren",
    "PARTNER_CUSTOMER_PLAN_BUTTON_TEXT_ENABLE":"Aktivieren",
    "PARTNER_CUSTOMER_PLAN_DISABLE_CONFIRMATION_TEXT":"Durch das Deaktivieren wird dieser Plan einschließlich seiner Angebote entfernt. Vorhandene gekaufte Produkte haben das gleiche Markup dieses Plans. Möchten Sie den Planstatus wirklich aktualisieren?",
    "PARTNER_CUSTOMER_PLAN_ENABLE_CONFIRMATION_TEXT":"Durch die Aktivierung wird dieser Plan einschließlich der Angebote mit im Plan definierten Markups zugewiesen. Möchten Sie den Planstatus wirklich aktualisieren?",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMER_PLANS":"Zurück zu den Kundenplänen",
    "PARTNER_CUSTOMER_PLANS_BTN_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
    "PARTNER_CUSTOMER_PLANS_CHANGE_PLAN":"Plan ändern",
    "PARTNER_CUSTOMER_PLANS_PAGE_TITLE":"Pläne verwalten für:",
    "PARTNER_CUSTOMER_REPORTS":"Berichte",
    "PARTNER_CUSTOMER_TAG_ADDED_SUCCESS":"Kunden-Tag erfolgreich hinzugefügt",
    "PARTNER_CUSTOMER_TAG_UPDATE_SUCCESS":"Kunden-Tag erfolgreich aktualisiert",
    "PARTNER_CUSTOMERS_PLANS_MANAGE_CONFIRMATION_POPUP":"Möchten Sie den Planstatus wirklich aktualisieren?",
    "PARTNER_CUSTOMERS_TEXT_INFO":"Leider wurden keine Kunden gefunden",
    "PARTNER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE":"Accounts erhaltbar",
    "PARTNER_DASHBOARD_CAPTION_PAST_DUES":"Vergangene Gebühren",
    "PARTNER_DASHBOARD_CAPTION_PROFITABILITY":"Rentabilität",
    "PARTNER_DASHBOARD_CAPTION_TOP_10_CUSTOMERS":"Top 10 Kunden",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Ihre aktiven Kunden",
    "PARTNER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Kunden",
    "PARTNER_DASHBOARD_CUSTOMERS_SEATS_TILE_INTRO_TITLE":"Kundensitze",
    "PARTNER_DASHBOARD_CUSTOMERS_SETAS_TILE_INTRO_CONTENT":"Direkte Kundenplätze",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_CONTENT":"Abonnements von Direktkunden",
    "PARTNER_DASHBOARD_CUSTOMERS_SUBSCRIPTIONS_TILE_INTRO_TITLE":"Kundenabonnements",
    "PARTNER_DASHBOARD_INVOICE_DETAILS_NOT_FOUND_ERROR_MESSAGE":"Reseller-Rechnungsdetails wurden für resellerId nicht gefunden: {{resellerId}}, billingPeriodId: {{billingPeriodId}}",
    "PARTNER_DASHBOARD_INVOICE_GENERATED_ON_TOOLTIP_TEXT":"Rechnung generiert am",
    "PARTNER_DASHBOARD_LABLE_NET_PROFIT":"Reingewinn",
    "PARTNER_DASHBOARD_LABLE_OTHER_CHARGES":"Zusatzgebühren",
    "PARTNER_DASHBOARD_LABLE_PERCENTAGE_PROFIT":"% Gewinn",
    "PARTNER_DASHBOARD_LABLE_TOTAL_BILL_AMOUNT":"Abgerechneter Betrag",
    "PARTNER_DASHBOARD_LABLE_TOTAL_MSFT_INVOICE":"MSFT-Rechnung",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Schneller Blick auf die Rentabilität Ihres CSP",
    "PARTNER_DASHBOARD_PARTNER_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilität",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_CONTENT":"Zusammenfassung Ihrer Forderungen",
    "PARTNER_DASHBOARD_PARTNER_INVOICE_PAYMENT_TILE_INTRO_TITLE":"Accounts erhaltbar",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_CONTENT":"Zahlungen müssen noch eingezogen werden",
    "PARTNER_DASHBOARD_PARTNER_PENDING_PAYMENT_TILE_INTRO_TITLE":"Vergangene Gebühren",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "PARTNER_DASHBOARD_PAST_DUES_TABLE_HEADER_PENDING_AMOUNT":"steht aus",
    "PARTNER_DASHBOARD_PIE_CHART_MESSAGE":"Bitte versuchen Sie es erneut, nachdem die Rechnungen fertig sind",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_HEADER":"Top 10 SKUs",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_CONTENT":"Produkt zählt nach Top-10-SKUs",
    "PARTNER_DASHBOARD_PRODUCT_COUNTS_BY_SKU_TILE_INTRO_TITLE":"Zählt nach SKU",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER":"Filterbereich",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR":"6 Monate",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER":"3 Monate",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_YEAR":"1 Jahr",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_HEADER":"Kauf von Sitzplätzen",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_CONTENT":"Trend zum Kauf von Plätzen über Monate hinweg",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_INTRO_TITLE":"Kauf von Sitzplätzen",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_SIDE_TEXT":"Sitze gekauft",
    "PARTNER_DASHBOARD_PURCHASE_OF_SEATS_TILE_TOOLTIP_TEXT":"Sitze gekauft",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_CONTENT":"Ihre aktiven Wiederverkäufer",
    "PARTNER_DASHBOARD_RESELLER_TILE_INTRO_TITLE":"Wiederverkäufer",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY":"Nach Kategorie",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_HEADER":"Einnahmen vs. Kosten",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_CONTENT":"Ein Diagramm zur Anzeige von Umsatz- und Kostendetails nach Kategorie",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_BY_CATEGORY_TILE_INTRO_TITLE":"Umsatz vs. Kosten nach Kategorie",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_COST":"Kosten",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_CONTENT_TOOLTIP_FOR_REVENUE":"Einnahmen",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_FILTER":"Anbieter auswählen",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_HEADER":"Einnahmen vs. Kosten",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_CONTENT":"Einnahmen versus Kosten",
    "PARTNER_DASHBOARD_REVENUE_VS_COST_TILE_INTRO_TITLE":"Einnahmen versus Kosten",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS":"Kunden",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SEATS":"Sitze",
    "PARTNER_DASHBOARD_TILE_CUSTOMERS_SUBSCRIPTIONS":"Abonnements",
    "PARTNER_DASHBOARD_TILE_INVOICES":"Rechnungen",
    "PARTNER_DASHBOARD_TILE_RESELLERS":"Wiederverkäufer",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_RENEWAL_DATE_TILE_INTRO_TITLE":"Nach Verlängerungsdatum",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TABLE_HEADER_REVENUE":"Einnahmen",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_1":"Top 5 Produkte",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_HEADER_2":"Nach Einnahmen",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_CONTENT":"Top 5 Produkte nach Umsatz",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_REVENUE_TILE_INTRO_TITLE":"Top 5 Produkte nach Umsatz",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TABLE_HEADER_SEATS_PURCHASED":"Sitze",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_1":"Top 5 Produkte",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_HEADER_2":"Nach gekauften Plätzen",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_CONTENT":"Top 5 Produkte nach gekauften Plätzen",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_BY_SEATS_PURCHASED_TILE_INTRO_TITLE":"Top 5 Produkte nach gekauften Plätzen",
    "PARTNER_DASHBOARD_TOP_FIVE_PRODUCTS_TILE_INTRO_TITLE":" Bevorstehende Erneuerungen",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TABLE_HEADER_CUSTOMER_REVENUE":"Einnahmen",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_1":"Top 10 Kunden",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_HEADER_2":"Nach Einnahmen",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_CONTENT":"Top 10 Kunden nach Umsatz",
    "PARTNER_DASHBOARD_VALUED_CUSTOMERS_TILE_INTRO_TITLE":"Top 10 Kunden nach Umsatz",
    "PARTNER_LOGOS_LABEL_TEXT":"Wählen Sie Logo",
    "PARTNER_LOGOS_LABEL_TEXT_PREVIEW_IMAGE":"Vorschaubild",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_BUTTON_TEXT_REMOVE":"Entfernen",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_CONTRACT_LABEL_TEXT_CATEGORY":"Kategorie",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_REMOVE":"Entfernen",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_BUTTON_TEXT_UPDATE_QUANTITY":"Menge aktualisieren",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_CATEGORY":"Kategorie",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_DISCOUNT":"Rabatt",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_BUTTON_TEXT_ADDONS":"Add-on löschen",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_QUANTITY_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_BUTTON_TEXT_REMOVE":"Entfernen",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_BILLING_TYPE":"Abrechnungsart",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_CATEGORY":"Kategorie",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_DISCOUNT":"Rabatt",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_LINKED_PRODUCT_NAME":"Name",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_LABEL_TEXT_UNIT_PRICE":"Stückpreis",
    "PARTNER_MANAGED_CART_LIST_TEMPLATE_USAGE_POPUP_HEADER_TEXT_ADDONS":"Add-Ons",
    "PARTNER_MANAGED_CONTACT_BUTTON_TEXT_REMOVE_OFFER":"Angebot entfernen",
    "PARTNER_OFFER_ADD_PARTNER_OFFER_BREAD_CRUMB":"Partnerangebot hinzufügen",
    "PARTNER_OFFER_DEFAULT_QUANTITY_TOOLTIP":"Geben Sie die Menge ein, die Sie kaufen möchten.",
    "PARTNER_OFFER_EDIT_CONTRACT_OFFER_BREAD_CRUMB":"Vertragsangebot bearbeiten",
    "PARTNER_OFFER_EDIT_PARTNER_OFFER_BREAD_CRUMB":"Partnerangebot bearbeiten",
    "PARTNER_OFFER_FILTERS_WARNING_TEXT_ENTER_POSITIVE_VALUE":"Geben Sie einen positiven Wert ein",
    "PARTNER_OFFER_HYPERLINK_READ_LESS":"Lese weniger",
    "PARTNER_OFFER_HYPERLINK_READ_MORE":"...Mehr lesen",
    "PARTNER_OFFER_IS_TRIAL_TOOLTIP":"Aktivieren Sie das Testangebot, indem Sie dieses Kontrollkästchen aktivieren",
    "PARTNER_OFFER_PROVIDER_TOOLTIP":"Bitte wählen Sie den Lösungsanbieter aus der Dropdown-Liste aus",
    "PARTNER_OFFER_SLAB_DATA":"Plattendaten",
    "PARTNER_OFFER_TRIAL_PERIOD_DAYS_TOOLTIP":"Dauer der Testzeit",
    "PARTNER_OFFER_USAGE_REPORT_ALL_CUSTOMERS_TEXT":"Alle Kunden",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ACTIONS":"Aktionen",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL":"Email",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste der Partnerangebote zu erhalten.",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_HEADER":"Bericht über Partnerangebote",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Bericht über Partnerangebote zu erhalten",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_INSTRUCTION":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, geben Sie sie mit Komma (,) oder Semikolon (;) ein und klicken Sie auf „Senden“, um einen Bericht zu erhalten",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_CLOSE_BUTTON_TEXT":"Stornieren",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SEND_EMAIL_BUTTON_TEXT":"E-Mail senden",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_POPUP_SUBMIT_BUTTON_TEXT":"einreichen",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
    "PARTNER_OFFERS_REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_APPROVE_VALIDATION":"Mutterprodukt ist nicht zugelassen!",
    "PARTNER_PENDING_PURCHASE_REQUEST_ADDON_PARENT_REJECT_VALIDATION":"Kinderprodukte werden nicht abgelehnt!",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART":"Alle Artikel im Einkaufswagen werden genehmigt, unabhängig von den Schwellenwertverletzungen bei jedem Einzelposten. Möchten Sie den Warenkorb wirklich genehmigen?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINE_ITEM":"Möchten Sie das Element wirklich genehmigen?",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_LINEITEM_SUCCESS_MESSAGE":"Warenkorb-Werbebuchung erfolgreich genehmigt",
    "PARTNER_PENDING_PURCHASE_REQUEST_APPROVE_CART_SUCCESS_MESSAGE":"Der Einkaufswagen wurde erfolgreich genehmigt",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CART":"Genehmigen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_CHECKBOX":"Genehmigen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_APPROVE_UPGRADE_REQUEST":"Anfrage genehmigen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_BACK_TO_LIST":"Zurück zu Anfragen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CATEGORIES":"Kategorien",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDER_CATEGORIES":"Servicetypen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_PROVIDERS":"Anbieter",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_FILTERS_TEXT_STATUS":"Status",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_ORDERED_ON_LABEL_TEXT":"Bestellt am",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_PRODUCT_TILE_UNIT_PRICE_LABEL_TEXT":"Stückpreis",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CART":"Ablehnen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_CHECKBOX":"Ablehnen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_REJECT_UPGRADE_REQUEST":"Anfrage ablehnen",
    "PARTNER_PENDING_PURCHASE_REQUEST_DETAILS_PAGE_SUBMIT_CHANGES":"Änderungen einreichen",
    "PARTNER_PENDING_PURCHASE_REQUEST_PLEASE_APPROVE_OR_REJECT_ATLEAST_ONE":"Bitte stellen Sie sicher, dass Sie mindestens ein Produkt genehmigen oder ablehnen, bevor Sie es einreichen",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART":"Möchten Sie wirklich den gesamten Einkaufswagen ablehnen?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINE_ITEM":"Möchten Sie den Artikel wirklich ablehnen?",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_LINEITEM_SUCCESS_MESSAGE":"Die Einkaufswagen-Werbebuchung wurde erfolgreich abgelehnt",
    "PARTNER_PENDING_PURCHASE_REQUEST_REJECT_CART_SUCCESS_MESSAGE":"Der Einkaufswagen wurde erfolgreich abgelehnt",
    "PARTNER_PENDING_PURCHASE_REQUEST_SUBMIT_CHANGES_SUCCESS_MESSAGE":"Der Status wurde erfolgreich aktualisiert",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_BREADCRUMB":"Zulassungen",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_INSTRUCTION_1":"Die folgenden Kaufanfragen von Kunden warten auf Ihre Genehmigung.",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PAGE_TITLE":"Zulassungen für",
    "PARTNER_PENDING_PURCHASE_REQUESTS_SCREEN_PORTLET_TITLE":"Kaufanfragen",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ausgewählte Abrechnungszeiträume",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Ausgewählte Kategorien",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Ausgewählte Anbieterkategorien",
    "PARTNER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Ausgewählte Anbieter",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_APPLY_PROMOTION_TO_ALL_PROMOTION":"Werbeaktion auf alle Angebote anwenden",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategorien",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_MACRO_MACRO_TYPE":"Makrotyp auswählen",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PLAN_SEAT_LIMIT":" Sitzplatzbegrenzung planen:",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Anbieterkategorien",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Anbieter",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SEAT_LIMIT":"Sitzplatz-Limit",
    "PARTNER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Servicetypen",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_COMPLETED_CLONE_PLAN":"Klonplan erfolgreich abgeschlossen",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Die Anforderung zum Klonen des Plans wird akzeptiert und bearbeitet",
    "PARTNER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_PLAN_WITH_ALL_OFFERS":"Die Anforderung, einen neuen Plan mit allen Angeboten zu erstellen, wird angenommen und bearbeitet",
    "PARTNER_PLAN_DETAILS_PLACEHOLDER_MACRO_VALUE":"Makrowert",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_LABEL_TEXT":"Kann der Verkaufspreis unter dem <br/> Partnerpreis liegen?",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_BEHIND_TOOLTIP_TEXT":"Der Verkaufspreis darf niedriger sein als der Partnerpreis",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_LABEL_TEXT":"Kann der Verkaufspreis den Verkaufspreis des Anbieters übersteigen?",
    "PARTNER_PLAN_DETAILS_SALE_PRICE_EXCEED_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der Verkaufspreis den Verkaufspreis des Anbieters übersteigt.",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_DEFINE_PLAN_IN_NEW_CURRENCY":"Plan in neuer Währung definieren",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_NAME":"Name",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PLAN_CURRENCY_CODE":"Währungscode planen",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_PRODUCT_NAME":"Produktname",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_SOURCE_CURRENCY":"Quellwährung",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY":"Zielwährung",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_LABEL_TEXT_TARGET_CURRENCY_CODE":"Zielwährungscode",
    "PARTNER_PLAN_PRODUCT_CURRENCY_CONVERSION_NO_TARGET_CURRENCY_MESSAGE":"Es gibt keine Zielwährung, bitte kontaktieren Sie den Partner, um dies zu definieren.",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_HEADING":"Wie man?",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_02":"Legen Sie das Limit auf -1 fest, um eine Genehmigung für alle Käufe/Aktualisierungslizenzen zu erfordern",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_1":"Verwenden Sie diesen Bildschirm, um den Schwellenwert nach Sitzanzahl zu konfigurieren",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_2":"Legen Sie das Limit auf 0 fest, um den Kauf unbegrenzter Plätze zu ermöglichen",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_3":"Legen Sie eine positive Zahl ungleich Null fest, um den Schwellenwert zu definieren",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_4":"Jeder Kauf, der den Schwellenwert überschreitet, muss vom Partner genehmigt werden",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_INSTRUCTION_STEP_5":"<b>Klicken Sie auf die Schaltfläche „Senden“, um die Änderungen zu speichern<b>",
    "PARTNER_PLAN_PRODUCT_SEAT_LIMITS_LABEL_TEXT":"Platzlimits für {{PlanName}} konfigurieren",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makro definieren und mit allen Angeboten speichern",
    "PARTNER_PLANS_BUTTON_TEXT_ADD_MISSING_OFFERS":"Fehlende Angebote ergänzen",
    "PARTNER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Stornieren",
    "PARTNER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klonplan",
    "PARTNER_PLANS_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Plan mit allen Angeboten speichern",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN":"Klonplan",
    "PARTNER_PLANS_BUTTON_TOOLTIP_CLONE_PLAN_IN_PROGRESS":"Klonplan",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Plan erstellen",
    "PARTNER_PLANS_BUTTON_TOOLTIP_PLAN_UPDATION_IS_IN_PROGRESS":"Der Plan ist in Bearbeitung. Klicken Sie hier, um den Status anzuzeigen",
    "PARTNER_PLANS_BUTTON_TOOLTIP_SEAT_LIMITS":"Sitzplatzbeschränkungen",
    "PARTNER_PRODUCT_BILLINGCYCLEID":"BillingCycleId",
    "PARTNER_PRODUCT_BILLINGPERIODTYPE":"BillingPeriodType",
    "PARTNER_PRODUCT_BILLINGTYPEID":"BillingTypeId",
    "PARTNER_PRODUCT_CATALOGUE_BREADCRUMB_HEADING_TEXT":"Produktkatalog",
    "PARTNER_PRODUCT_CATALOGUE_BUTTON":"Produktkatalog",
    "PARTNER_PRODUCT_CATALOGUE_HEADING_TEXT":"Produktkatalog",
    "PARTNER_PRODUCT_CATEGORYID":"Kategorie ID",
    "PARTNER_PRODUCT_CONSUMPTIONTYPEID":"ConsumptionTypeId",
    "PARTNER_PRODUCT_DESCRIPTION":"Beschreibung",
    "PARTNER_PRODUCT_NAME":"Name",
    "PARTNER_PRODUCT_ONPURCHASEBILLINGACTION":"OnPurchaseBillingAction",
    "PARTNER_PRODUCT_ONRELEASEBILLINGACTION":"OnReleaseBillingAction",
    "PARTNER_PRODUCT_PRICEFORPARTNER":"Preis für Partner",
    "PARTNER_PRODUCT_ProviderId":"ProviderId",
    "PARTNER_PRODUCT_PROVIDERSELLINGPRICE":"ProviderSellingPrice",
    "PARTNER_PRODUCT_SALETYPE":"SaleType",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_COST_TO_CUSTOMER":"Kosten für den Kunden",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CURRENCY_CODE":"Währung",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_ID":"Kundennummer",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_CUSTOMER_NAME":"Kundenname",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_DESCRIPTION":"Beschreibung",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_ID":"Produkt-ID",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_PRODUCT_NAME":"Produktname",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNIT_OF_MEASURE":"Maßeinheit",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_UNITS":"Einheiten",
    "PARTNER_PRODUCT_USAGE_REPORT_TITLE_USAGE_DATE":"Verwendungsdatum",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie diese Anfrage wirklich annehmen?",
    "PARTNER_PROVISIONING_PAGE_POPUP_CONFIRMATION_POPUP_MESSAGE_FOR_CANCELLED":"Möchten Sie diese Anfrage wirklich ablehnen?",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_BACK_TO_SUBSCRIPTION_HISTORY":"Zurück zum Abonnementverlauf",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_NEW_PRODUCT":"Neues PSA-Produkt",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE":"Freisetzung",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_AGREEMENT_MAPPING":"Zuordnung von Freigabevereinbarungen",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CONTRACT_MAPPING":"Vertragszuordnung freigeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_BTN_TEXT_RELEASE_CUSTOMER_MAPPING":"Kundenzuordnung freigeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_LABEL_TEXT_NEW_PRODUCT_CREATION":"Neues Produkt erstellen",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_ERROR_TEXT_REQUIRED_FIELD":"Dieses Feld wird benötigt.",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CATEGORY":"Kategorie",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_COST":"Kosten",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_CUSTOMER_DESCRIPTION":"Kundenbeschreibung",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DESCRIPTION":"Beschreibung",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_DROP_SHIP_FLAG":"Dropshipping",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_IDENTIFIER":"Kennung",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_INTEGRATION_CROSS_REFERENCE":"Integrationsquerverweis",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_NOTES":"Anmerkungen",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRICE":"Preis",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_PRODUCT_CLASS":"Produktklasse",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SLA":"SLA",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SPECIAL_ORDER_FLAG":"Sonderbestellung",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_SUBCATEGORY":"Unterkategorie",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TAXABLE_FLAG":"Mehrwertsteuer",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_TYPE":"Produkttyp",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_LABEL_UNIT_OF_MEASURE":"Maßeinheit (UOM)",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_COST":"Kosten eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_CUSTOMER_DESCRIPTION":"Geben Sie die Kundenbeschreibung ein",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_IDENTIFIER":"Kennung eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_NOTES":"Notizen eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRICE":"Preis eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_ENTER_PRODUCT_LABEL":"Produktbeschreibung eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_INTERATION_CROSS_REFERENCE":"Integrationsquerverweis eingeben",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NEW_PRODUCT_PLACEHOLDER_TEXT_SELECT_PRODUCT_TYPE":"Produkttyp auswählen",
    "PARTNER_PURCHASED_PRODUCT_MAPPING_NOTIFICATION_SUCCESSFULLY_CREATED_NEW_PSA_PRODUCT":"Neues PSA-Produkt erfolgreich erstellt",
    "PARTNER_REPORTS_BTN_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
    "PARTNER_REPORTS_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "PARTNER_REPORTS_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "PARTNER_RESELLER_FILTER_NAME":"Name",
    "PARTNER_RESELLER_IS_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"{{reseller}} ist keinem Buchhalter zugeordnet",
    "PARTNER_RESELLER_PLAN_DETAILS_BUTTON_TEXT_SAVE_YOUR_CHANGES":"Speichern Sie Ihre Änderungen",
    "PARTNER_RESELLER_PLAN_DETAILS_HEADER_TEXT_BASIC_DETAILS":"Grundlegende Details",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_DESCRIPTION":"Beschreibung",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_NAME":"Name",
    "PARTNER_RESELLER_PLAN_DETAILS_LABEL_TEXT_SELECT":"Wählen",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_LABEL_TEXT":"Kann der neue Partnerpreis niedriger sein als der<br/>Partnerpreis",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_BEHIND_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der neue Partnerpreis niedriger ist als der Partnerpreis",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_LABEL_TEXT":"Kann der Partnerpreis den Verkaufspreis des Anbieters überschreiten?",
    "PARTNER_RESELLER_PLAN_DETAILS_NEW_PRICE_EXCEED_TOOLTIP_TEXT":"Aktivieren Sie diese Option, um zuzulassen, dass der Partnerpreis den Verkaufspreis des Anbieters übersteigt.",
    "PARTNER_SCHEDULED_ADD_NEW_SCHEDULED_REPORT":"Bericht hinzufügen",
    "PARTNER_SCHEDULED_POPUP_DELETE_CONFIRMATION_TEXT":"Möchten Sie dies wirklich löschen?",
    "PARTNER_SCHEDULED_RECIPIENTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aktionen",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_ADD_RECIPIENT":"Empfänger hinzufügen",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS":"Zurück zu den Berichten",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BACK_TO_SCHEDULED_REPORTS_RECIPIENT":"Zurück zum Empfänger",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_BCC":"BCC",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CANCEL":"Stornieren",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_CC":"CC",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_DELETE":"Löschen",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EDIT":"Bearbeiten",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_EMAIL":"Email",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENT_TYPE":"Empfängertyp",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_RECIPIENTS":"Empfänger",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_SAVE":"speichern",
    "PARTNER_SCHEDULED_RECIPIENTS_BTN_TEXT_TO":"Zu",
    "PARTNER_SCHEDULED_RECIPIENTS_DELIVERY_METHOD_TABLE_HEADER_TEXT_DESC":"Versandart",
    "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TABLE_HEADER_TEXT_DESC":"Empfänger",
    "PARTNER_SCHEDULED_RECIPIENTS_RECIPIENTS_TYPE_TABLE_HEADER_TEXT_DESC":"Empfängertyp",
    "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RECIPIENTS":"Empfänger",
    "PARTNER_SCHEDULED_REPORT_TABLE_TD_BUTTON_TOOLTIP_TEXT_RUN_HISTORY":"Ausführungsverlauf",
    "PARTNER_SCHEDULED_REPORTS_ACTIONS_TABLE_HEADER_TEXT_DESC":"Aktionen",
    "PARTNER_SCHEDULED_REPORTS_LAST_RUN_ON_TABLE_HEADER_TEXT_DESC":"Letzter Lauf am (Ortszeit)",
    "PARTNER_SCHEDULED_REPORTS_REPORT_TABLE_HEADER_TEXT_DESC":"Berichte",
    "PARTNER_SCHEDULED_REPORTS_SCHEDULE_TABLE_HEADER_TEXT_DESC":"Geplant am",
    "PARTNER_SCHEDULED_RUN_HISTORY_DATE_TABLE_HEADER_TEXT_DESC":"Datum",
    "PARTNER_SCHEDULED_RUN_HISTORY_DETAIL_TABLE_HEADER_TEXT_DESC":"Einzelheiten",
    "PARTNER_SCHEDULED_RUN_HISTORY_STATUS_TABLE_HEADER_TEXT_DESC":"Status",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_NAME_AND_PERCENTAGE":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für den {{taxType}} ein",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Bitte geben Sie den gültigen Steuerprozentsatz ein",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Bitte füllen Sie die Felder Steuername aus",
    "PARTNER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_UNSAVED_SUBTAX":"Sie haben nicht gespeicherte Untersteuern, möchten fortfahren?",
    "PARTNER_SETTINGS_ADD_AND_EDIT_EMAIL_NOTIFICATION_HEADER_TEXT_EVENT_EMAIL_NOTIFICATION":"Ereignis-E-Mail-Benachrichtigung",
    "PARTNER_SETTINGS_CUSTOM_VIEW_SELECT_VIEW":"Wählen Sie eine Ansicht",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"Partner-Tag erfolgreich gelöscht",
    "PARTNER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"Partner-Tag erfolgreich aktualisiert",
    "PARTNER_SETTINGS_PORTLET_TITLE_STYLES":"Stile",
    "PARTNER_SETTINGS_PORTLET_TITLE_TEMPLATES":"Vorlagen",
    "PARTNER_STATUS":"Partnerstatus",
    "PARTNER_TABLE_HEADER_NAME":"Name",
    "PARTNER_TABLE_HEADER_VALUE":"Wert",
    "PARTNER_TERMS_OF_USE":"Nutzungsbedingungen des Partners",
    "PARTNER_TRIAL_OFFER_CREATION_LABEL_TEXT_TRIAL_PERIOD_OPTIONS":"Probetage",
    "PARTNER_TRIAL_PLAN_ADD_POPUP_HEADER_TEXT_ADDON_LIST":"Elternangebote im Zusammenhang mit dem Testangebot",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_COST_TO_CUSTOMER":"Kosten für den Kunden",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_CURRENCY_CODE":"Währung",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION":"Beschreibung",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_SUBMIT":"einreichen",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TEXT_MONTH":"Monat",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_DESCRIPTION_TOTAL_COST":"Gesamtkosten",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_LABEL_USAGE_DATE":"Verwendungsdatum",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNIT_OF_MEASURE":"Maßeinheit",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_UNITS":"Einheiten",
    "PARTNER_USAGE_PRODUCT_USAGE_TAB_USAGE_DATE":"Verwendungsdatum",
    "PARTNER_USER_ALREADY_EXECUTE":"Mit dieser E-Mail existiert bereits ein Partnerbenutzer.",
    "PARTNER_USER_REMOVED":"Partnerbenutzer erfolgreich entfernt",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_DELETE":"Löschen",
    "PARTNER_USER_TAGS_TABLE_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_KEY":"Tag-Schlüssel",
    "PARTNER_USER_TAGS_TABLE_HEADER_TEXT_TAG_VALUES":"Tag-Werte",
    "PARTNER_USERS_ADD_SUCCESS":"Gewährt erfolgreich Zugriff auf {{username}}",
    "PARTNER_USERS_ALREADY_EXISTS_ERROR":"Ein Benutzer mit der E-Mail {{useremail}} ist bereits im System vorhanden",
    "PARTNER_USERS_REMOVE_SUCCESS":"Der Partnerbenutzer hat {{username}} erfolgreich entfernt",
    "PARTNER_USERS_SAVE_SUCCESS":"Der Partnerbenutzer hat {{username}} erfolgreich aktualisiert",
    "PARTNER_USERS_SELECT_ROLE_PROMPT":"Bitte weisen Sie eine Rolle zu, um fortzufahren",
    "partnerauditlog":"Audit-Log",
    "PARTNERMANAGEMENTCONTROLLER_CREATEPARTNER_2":"Partnerbenutzer erfolgreich aktualisiert",
    "PARTNERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_PARTNER_USERS":"Zurück zu Partnerbenutzern",
    "PARTNERS_ADD_OR_EDIT_CAPTION_1":"Hinzufügen",
    "PARTNERS_ADD_OR_EDIT_CAPTION_2":"Bearbeiten",
    "PARTNERS_ADD_OR_EDIT_CAPTION_3":"Partnerbenutzer",
    "PARTNERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Email",
    "PARTNERS_HEADER_TEXT_MANAGE_TAG":"Tag verwalten",
    "PARTNERS_LABLE_TEXT_TAG_KEY":"Tag-Schlüssel",
    "PARTNERS_LABLE_TEXT_TAG_VALUES":"Tag-Werte",
    "PASSWORD_VALIDATION_MESSAGE_SMALL_CAPITAL_SPECIAL_NUMBER":"Das Passwort sollte mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
    "PAYMENT_LABEL_FILTER":"Filtern nach",
    "PAYMENT_PROFILE_DEFAULT_CHANGE_SUCCESS":"Zahlungsprofil Auf Standard setzen",
    "PAYMENT_PROFILE_DELETE_SUCCESS":"Zahlungsprofil gelöscht",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_HOLDER_NAME":"Name des Karteninhabers",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_CARD_NUMBER":"Kartennummer",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_MONTH":"Ablaufmonat",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_EXPIRY_YEAR":"Ablaufjahr",
    "PAYMENT_PROFILE_MCB_LBL_TEXT_SECURITY_CODE":"Sicherheitscode",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_HOLDER_NAME":"Name auf der Kreditkarte",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_CARD_NUMBER":"Kreditkartennummer",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_MONTH":"Zweistellige Monatszahl",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_EXPIRY_YEAR":"Vierstellige Jahreszahl",
    "PAYMENT_PROFILE_MCB_TOOL_TIP_TEXT_SECURITY_CODE":"Dreistellige CVV-Nummer auf der Kreditkarte",
    "PAYMENT_PROFILE_SAVE_FAILURE":"Speichern der Zahlungsdetails fehlgeschlagen",
    "PAYMENT_PROFILE_SAVE_SUCCESS":"Zahlungsdetails gespeichert",
    "PAYMENT_PROFILE_SAVE_UPDATED_SHORTLY":"Die Zahlungsdetails werden in Kürze aktualisiert. Bitte melden Sie sich NICHT ZURÜCK und schauen Sie nach einiger Zeit wieder vorbei.",
    "PAYMENT_PROFILE_TEXT_FAILURE":"Fehler",
    "PAYMENT_PROFILE_TEXT_SUCCESS":"Erfolg",
    "PAYMENT_STATUS_DESC_ACCEPTED":"Akzeptiert",
    "PAYMENT_STATUS_DESC_CHARGED":"Berechnet",
    "PAYMENT_STATUS_DESC_DECLINED":"Abgelehnt",
    "PAYMENT_STATUS_DESC_FAILED":"Gescheitert",
    "PAYMENT_STATUS_DESC_FINALIZED":"Aktueller Monat ausstehend",
    "PAYMENT_STATUS_DESC_INPROGRESS":"In Bearbeitung",
    "PAYMENT_STATUS_DESC_PARTIAL_PAID":"Teilweise bezahlt",
    "PAYMENT_STATUS_DESC_SUCCESS":"Erfolgreich",
    "PAYMENT_STATUS_DESC_TECHNICAL_ERROR":"Technischer Fehler",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_CANCEL":"Stornieren",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"einreichen",
    "PAYMENT_TEMPLATE_BUTTON_TEXT_UPDATE":"Aktualisieren",
    "PAYMENT_TEMPLATE_ERROR_TEXT_REQUIRED_FIELD":"Dieses Feld wird benötigt.",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUINT_NUMBER":"Kontonummer",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ALIAS_NAME":"Pseudonym",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT":"Ist Geschäftskonto?",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_COMPANY":"Unternehmen",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_INDIVIDUAL":"Individuell",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_NO":"Nein",
    "PAYMENT_TEMPLATE_LABEL_TEXT_BUSINESS_ACCOUNT_YES":"Ja",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CHECKING":"Überprüfung",
    "PAYMENT_TEMPLATE_LABEL_TEXT_CVV":"CVV",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_MONTH":"Monat ablaufen",
    "PAYMENT_TEMPLATE_LABEL_TEXT_EXPIRE_YEAR":"Ablaufjahr",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
    "PAYMENT_TEMPLATE_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
    "PAYMENT_TEMPLATE_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
    "PAYMENT_TEMPLATE_LABEL_TEXT_SAVING":"speichern",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_BANK_ACCOUNT":"Bankkonto",
    "PAYMENT_TEMPLATE_RADIO_BUTTON_TEXT_CREDIT_CARD":"Kreditkarte",
    "PAYMENT_TEMPLATE_WAIT_MESSAGE":"Bitte warten Sie, während die Seite geladen wird",
    "PAYMENTS_AND_DUES_TABLE_HEADER_BILLED_AMOUNT":"Abgerechneter Betrag",
    "PAYMENTS_AND_DUES_TABLE_HEADER_COMPANY_NAME":"Name der Firma",
    "PAYMENTS_AND_DUES_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAID_AMOUNT":"Bezahlte Menge",
    "PAYMENTS_AND_DUES_TABLE_HEADER_PAYMENT_STATUS":"Zahlungsstatus",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REASON_FOR_FAILURE":"Grund für das Scheitern",
    "PAYMENTS_AND_DUES_TABLE_HEADER_REMARKS":"Bemerkungen",
    "PAYMENTS_AND_DUES_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
    "PAYMENTS_LIST_TABLE_HEADER_CUSTOMER_RESELLER":"Bezahlt von",
    "PAYMENTS_PANEL_TITLE_FAILURE_TRANSACTIONS":"Fehlertransaktionen",
    "PAYMENTS_PANEL_TITLE_PENDING_PAYMENTS":"Ausstehende Zahlungen",
    "PAYMENTS_STATUS_DESC_FAILED":"Gescheitert",
    "PAYMENTS_STATUS_DESC_SUCCESS":"Erfolg",
    "PAYMENTS_TABLE_HEADER_AMONT":"Bezahlte Menge",
    "PAYMENTS_TABLE_HEADER_BILLED_AMONT":"Abgerechneter Betrag",
    "PAYMENTS_TABLE_HEADER_BILLING_EMAIL":"Abrechnung per E-Mail",
    "PAYMENTS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "PAYMENTS_TABLE_HEADER_FAILURE_REASON":"Fehlergrund",
    "PAYMENTS_TABLE_HEADER_INVOICE_BILLING_PERIOD":"Abrechnungszeitraum",
    "PAYMENTS_TABLE_HEADER_INVOICE_DUE_STATUS":"Fälliger Status",
    "PAYMENTS_TABLE_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "PAYMENTS_TABLE_HEADER_IS_INVOICE_BASED":"Ist rechnungsbasiert?",
    "PAYMENTS_TABLE_HEADER_OWNED_BY":"Gehört",
    "PAYMENTS_TABLE_HEADER_PAID_AMONT":"Bezahlte Menge",
    "PAYMENTS_TABLE_HEADER_PAYABLE_AMOUNT":"Bezahlbarer Betrag",
    "PAYMENTS_TABLE_HEADER_PAYMENT_ID":"Zahlungs-ID",
    "PAYMENTS_TABLE_HEADER_PAYMENT_METHOD":"Bezahlverfahren",
    "PAYMENTS_TABLE_HEADER_PENDING_AMONT":"Ausstehende Summe",
    "PAYMENTS_TABLE_HEADER_PENDING_AMOUNT":"Ausstehende Summe",
    "PAYMENTS_TABLE_HEADER_REMARKS":"Bemerkungen",
    "PAYMENTS_TABLE_HEADER_STATUS":"Status",
    "PAYMENTS_TABLE_HEADER_STATUS_PARTIAL":"Teilzahlung",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_DATE":"Transaktionsdatum",
    "PAYMENTS_TABLE_HEADER_TRANSACTION_ID":"Transaktions-ID",
    "PC_7_CALENDAR_DAYS_WINDOW_CLOSED_FOR_SEATS_REDUCING_OF_SUBSCRIPTION":"Sie haben Ihr 7-Tage-Fenster für die Reduzierung von Plätzen für dieses Abonnement überschritten.",
    "PC_ACTIVATION_ERROR":"Ein Kind-Abonnement kann nicht aktiviert werden, wenn das Eltern-Abonnement nicht aktiv ist",
    "PC_ADDON_IS_NOT_PURCHASABLE_WITHOUT_BASE_PRODUCT":"Das Addon ist nicht ohne ein kompatibles Basisprodukt erhältlich",
    "PC_ADVISOR_PARTNER_INFORMATION_NOT_FOUND":"Es wurden keine Beraterpartnerinformationen gefunden, um diese Anfrage zu vervollständigen.",
    "PC_ADVISOR_QUOTE_NOT_FOUND":"Beraterangebot nicht gefunden. Bitte überprüfen Sie die Informationen, bevor Sie es erneut versuchen.",
    "PC_ALREADY_ACTIVATE_ITEM":"Dieser Artikel wurde bereits aktiviert.",
    "PC_AN_AZURE_PLAN_IS_REQUIRED_TO_PURCHASE_A_SAAS_SUBSCRIPTION_WITH_A_METERED_BILLING":"Ein Azure-Plan ist erforderlich, um ein SaaS-Abonnement mit einem Abrechnungstarif zu erwerben. Wenden Sie sich bitte an den Partner für weitere Informationen.",
    "PC_API_METHOD_NOT_SUPPORTED_FOR_LEGACY_SUSBSCRIPTION":"Diese API-Methode wird für Legacy-Abonnements nicht unterstützt",
    "PC_AVAILABILITY_REFERENCED_BY_LINE_ITEM_NOT_FOUND":"Die von der Werbebuchung referenzierte Verfügbarkeit wurde nicht gefunden",
    "PC_AVAILIBILTY_QUANTITY":"Die Verfügbarkeit umfasst mehr als eine Mengenoption. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_AZURE_PLAN_CANNOT_BE_MOVED_TO_SUSPENDED_STATE_SINCE_YOU_HAVE_ONE_OR_ACTIVE_STATE":"Der Azure-Plan kann nicht in den angehaltenen Status verschoben werden, da Sie eine oder mehrere Azure-Abonnements im aktiven Status haben.",
    "PC_AZURE_PLAN_ID_WAS_NOT_FOUND_OR_THERE_ARE_NO_ACTIVE_AZURE_SUBSCRIPTIONS_UNDER_IT":"Die angegebene Azure-Plan-ID wurde nicht gefunden oder es gibt keine aktiven Azure-Abonnements unter ihr. Ein Azure-Plan mit aktiver(n) Subscription(s) ist erforderlich, um ein SaaS-Produkt mit einem Abrechnungstarif zu erwerben",
    "PC_AZURE_SUSBSCRIPTION_WITH_THE_GIVEN_ENTITLEMENT_ID_IS_NOT_FOUND":"Das Azure-Abonnement mit der angegebenen Berechtigungs-ID wurde nicht gefunden.",
    "PC_BILLING_CANNOT_BE_LONGER_THAN_TERM":"Der Abrechnungszeitraum darf nicht länger sein als die Laufzeit.",
    "PC_BILLING_CYCLE_CHANGE_NOT_ALLOWED":"Der Abrechnungszeitraum für Abonnements stimmt nicht mit dem Abrechnungszeitraum der ursprünglichen Bestellung überein",
    "PC_BILLING_CYCLE_IS_INVALID":"Der Abrechnungszeitraum ist ungültig.",
    "PC_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_THIRD_PARTY_MARKETPLACE_PRODUCTS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS":"Dieser Antrag auf Übertragung des Eigentums an der Fakturierung kann nicht abgeschlossen werden, da Produkte von Drittanbietern nicht mit Abonnements übertragen werden können. Entfernen Sie die Marktplatzprodukte von Drittanbietern aus Ihrer Auswahl und versuchen Sie es erneut.",
    "PC_C3_CONNECTION_ERROR":"Fehler bei der Verbindung zum Partner Center, bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",
    "PC_CAN_NOT_CHANGE_BILLING_CYCLE_IF_SUBSCRIPTION_IS_NOT_ASSOCIATED_WITH_MINT_ACCOUNT":"Der Abrechnungszeitraum kann nicht geändert werden, wenn das Abonnement keinem Mint-Konto zugeordnet ist",
    "PC_CAN_NOT_PROCESS_AZURE_UPGRADE_FOR_AZURE_PARTNER_SHARED_SERVICE":"Azure-Upgrade für gemeinsame Azure-Partnerdienste kann nicht verarbeitet werden",
    "PC_CANCEL_PURCHASE_NON_TENANT":"Nicht-Sandbox-Mieter können keine anderen Käufe als Software-Abonnements und unbefristete Software stornieren",
    "PC_CANCELLATION_NOT_ALLOWED":"Eine Stornierung ist derzeit nicht möglich!",
    "PC_CANNOT_CHECKOUT":"Ein bereits eingereichter Warenkorb kann nicht ausgecheckt werden!",
    "PC_CANNOT_PROCESS_AZURE_UPGRADE":"Azure-Upgrade kann nicht durchgeführt werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_CANNOT_PROCESS_REQUEST":"Die Transferanfrage für {1} Transfer {0} kann nicht bearbeitet werden.",
    "PC_CANNOT_TRANSITION_TO_AZURE_PLAN":"Sie haben ein oder mehrere Azure-Abonnements im suspendierten Zustand. Diese können nicht auf den Azure-Plan übertragen werden.",
    "PC_CANNOT_UPDATE_BILLING_CYCLE":"Technische Probleme verhindern die Aktualisierung der Abonnements. Bitte kontaktieren Sie den Support.",
    "PC_CART_IN_PROCESS":"Der Warenkorb wird bereits bearbeitet, bitte warten Sie und schauen Sie nach einiger Zeit wieder vorbei.",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_PURCHASE":"Der Katalogartikel ist nicht zum Kauf berechtigt. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_CATALOG_ITEM_NOT_ELIGIBLE_TRANSACTION":"Der Katalogartikel ist nicht für das Hinzufügen oder Entfernen von Mengen geeignet. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
    "PC_CATALOG_ITEM_REQUIRES_ATTESTATION_ACCEPTANCE":"Katalogartikel erfordert eine Bestätigungsbestätigung.",
    "PC_CREATE_ORDER_ERROR":"Bestellung erstellen ist nicht erfolgreich",
    "PC_CUSTOMER_ACCOUNT_UNDER_REVIEW":"Das Kundenkonto wird gerade überprüft. Transaktionen sind nicht erlaubt, bis das Konto genehmigt ist.",
    "PC_CUSTOMER_MIGRATION_FAILED":"Die Migration der Kundenlizenz ist fehlgeschlagen. Bitte kontaktieren Sie den Support.",
    "PC_CUSTOMER_PHONE_NUMBER_MISSING":"Die Telefonnummer des Kunden fehlt. Dieser Fehler wird ausgelöst, wenn das Unternehmen/die Organisation des Kunden in den folgenden Ländern ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA), aber billingProfile.defaultAddress.phoneNumber nicht übergeben wird.",
    "PC_DISCONTINUED_OFFER":"Dieses Angebot steht derzeit nicht zum Kauf zur Verfügung, da es möglicherweise eingestellt worden ist. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
    "PC_DOES_NOT_SUPPORT_GIVEN_CURRENCY_INPUT_CURRENCY_CUSTOMER_MUST_BE_EITHER_PARTNER_CUSTOMER_CURRENCY":"Mindestens eine der Bestellpositionen unterstützt die angegebene Währung USD nicht. Die Eingabewährung sollte entweder Partner- oder Kunden-(Markt-)Währung sein.",
    "PC_ERROR_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS":"Das in den Anweisungen für die geplante nächste Laufzeit angegebene Enddatum der benutzerdefinierten Laufzeit muss innerhalb der ersten Laufzeit nach der Verlängerung liegen. Außerdem können Produkte mit monatlicher Laufzeit nicht mit einem Abonnement kombiniert werden, das am 28., 29. oder 30. des Monats endet, es sei denn, dieses Datum ist der letzte Tag des Monats.",
    "PC_GENERIC_ERROR":"Ein Fehler ist aufgetreten. Bitte kontaktieren Sie den Support für weitere Informationen oder versuchen Sie es erneut, nachdem Sie den Status der durchgeführten Operation überprüft haben.",
    "PC_INACTIVE_RESELLER_PROGRAM":"Der Status des Wiederverkäuferprogramms ist nicht aktiv. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_INCORRECT_CUSTOMER_ID":"Falsche Kunden-ID. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.",
    "PC_INITIATE_TRANSFER_REQUEST_FAILED":"Überweisungsanforderung fehlgeschlagen. Bitte versuchen Sie es nach einiger Zeit erneut.",
    "PC_INVALID_ADD_ON":"Das Add-on ist nicht mit dem angegebenen übergeordneten Abonnement verbunden",
    "PC_INVALID_AZURE_SUBSCRIPTION":"Dieses Abonnement ist kein gültiges Azure-Abonnement",
    "PC_INVALID_CUSTOMER_INFORMATION":"Die angegebenen Informationen stimmen nicht mit den aktuellen Kundeninformationen überein. Bitte überprüfen Sie die Informationen und versuchen Sie es erneut.",
    "PC_INVALID_DOMAIN":"Ungültige Domain. Bitte geben Sie die korrekte Domainadresse an und versuchen Sie es erneut.",
    "PC_INVALID_ETAG":"Etag ist nicht mehr gültig",
    "PC_INVALID_LINE_ITEM":"Die Positionsreihenfolge ist nicht richtig eingestellt, bitte kontaktieren Sie den Support.",
    "PC_INVALID_METADATA_CUSTOMER_PRICE_OR_CURRENCY_MISSING":"Ungültige Metadaten. (Wenn der Kundenpreis, die Währung fehlt).",
    "PC_INVALID_MPN":"Ungültige MPN, bitte kontaktieren Sie den Support für weitere Untersuchungen.",
    "PC_INVALID_OPERATION":"Dieser Vorgang wird nicht unterstützt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID":"Der Wert der Organisationsregistrierungs-ID wird nicht unterstützt. Bitte überprüfen Sie den eingegebenen Wert, bevor Sie es erneut versuchen.",
    "PC_INVALID_ORGANISATION_REGESTRATION_ID_FORMAT":"Der Wert oder das Format der Registrierungs-ID der Organisation wird nicht unterstützt. Überprüfen Sie bitte den eingegebenen Wert, bevor Sie es noch einmal versuchen.",
    "PC_INVALID_PROMOTION_CODE":"Der Aktionscode ist nicht gültig. Bitte überprüfen Sie dies und versuchen Sie es erneut.",
    "PC_INVALID_QTY_FOR_SUBSCRIPTION":"Die Abonnementmenge liegt nicht innerhalb der zulässigen Mindest- und Höchstmenge.",
    "PC_INVALID_REQUEST":"Ungültige Anfrage. Überprüfen Sie bitte die Attribute der Anfrage, bevor Sie es erneut versuchen.",
    "PC_INVALID_SUBSCRIPTION":"Kein gültiges Abonnement, bitte kontaktieren Sie den Support.",
    "PC_INVALID_SUBSCRIPTION_TRANSER":"Übertragung kann nicht mit ungültigem Abonnement erstellt werden. Bitte überprüfen Sie das Abonnement, bevor Sie es erneut versuchen.",
    "PC_ISSUE_ASSET_STATUS_CHANGE":"Asset-Status-Änderungsvorgang konnte nicht abgeschlossen werden. Wenden Sie sich bitte an den Partner für weitere Informationen.",
    "PC_ISSUE_OFFER_TYPE":"Der Kauf konnte aufgrund eines Problems mit dem Angebotstyp nicht abgeschlossen werden. Bitte kontaktieren Sie den Support.",
    "PC_ITEM_IS_NOT_AVAILABLE":"Dieser Artikel ist nicht verfügbar",
    "PC_LIMIT_EXCEEDE_FOR_SUBSCRIPTION_OFFER":"Die angeforderte Anzahl hat die für das Angebot zulässige Restmenge pro Abonnement überschritten. Bitte passen Sie die Anzahl an, bevor Sie es erneut versuchen.",
    "PC_LIMIT_EXCEEDED":"Das Nutzungslimit für die Angebots-ID wurde überschritten.",
    "PC_LINE_ITEM_ISSUE":"Es liegt ein Problem mit der Position vor. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_MIGRATION_CANNOT_BE_PERFORMED_BECAUSE_TWO_OR_MORE_ADDON_MIGRATION_WERE_SPECIFIED_WITH_SAME_SUBSCRIPTION_ID":"Die Migration kann nicht durchgeführt werden, weil zwei oder mehr Add-On-Migrationen mit derselben aktuellen Abonnement-ID angegeben wurden",
    "PC_MISSING_ASSET_ID":"Der Vorgang konnte nicht abgeschlossen werden, weil eine Asset-ID fehlt. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_MISSING_AZURE_SUBSCRIPTION":"Das Azure-Upgrade kann nicht durchgeführt werden, da keine Azure-Abonnementinformationen gefunden wurden.",
    "PC_MISSING_MCA":"Bitte akzeptieren Sie MCA, bevor Sie mit dem Kauf fortfahren.",
    "PC_MISSING_REQUIRED_TAGS_IN OFFER":"Das Konto verfügt nicht über die erforderlichen Tags für das Angebot. Wenden Sie sich bitte an den Partner für weitere Informationen.",
    "PC_MISSING_TRANSFER_REQUEST":"Es wurde keine Übertragungsanfrage gefunden.",
    "PC_MISSING_UPGRADE_REQUEST":"Es wurde keine Upgrade-Anfrage gefunden.",
    "PC_MPN_NOT_FOUND":"MPN Id wurde nicht gefunden.",
    "PC_MULTIPLE_TRANSFER_REQUEST_NOT_ALLOWED":"Mehrere Überweisungsanfragen sind nicht erlaubt. Eine weitere Übertragungsanfrage ist in Bearbeitung.",
    "PC_NCE_MIGRATION_COULD_NOT_FOUND":"New-Commerce-Migration konnte nicht gefunden werden.",
    "PC_NO_AZURE SUBSCRIPTION":"Azure-Upgrade kann nicht durchgeführt werden, da der Kunde keine Azure-Abonnements hat.",
    "PC_NOT_ELIGIBLE_AUTO_RENEWED":"Das Abonnement kann nicht automatisch verlängert werden. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_NOT_ELIGIBLE_FOR_TRANSACTION":"Der Partner ist für diese Transaktion nicht berechtigt, bitte kontaktieren Sie den Support.",
    "PC_OFFER_DOES_NOT_SUPPORT_ITEM_TRY_PRODUCTS_OR_SKU":"Artikel wird von der Offers-API nicht unterstützt. Versuchen Sie, die APIs für Produkte und/oder SKUs zu verwenden",
    "PC_OFFER_NOT_VALID_FOR_COUNTRY":"Das Angebot gilt nicht für das angegebene Land",
    "PC_OFFER_PURCHASE_ERROR":"Ungültige Angebots-ID",
    "PC_ONE_OR_MORE_AZURE_SUBSCRIPTIONS_WERE_PURCHASED_RECENTLY_THESE_SUBSCRIPTIONS_CANNOT_BE_TRANSITIONED":"Ein oder mehrere Azure-Abonnements wurden kürzlich erworben. Diese Abonnements können derzeit nicht übertragen werden. Bitte versuchen Sie es später erneut.",
    "PC_OPERATION_TYPE_IS_NOT_SOPPORTED_BATCH":"Das Stornieren von platzbasiertem SaaS wird nicht über die Patch-Bestellung unterstützt. Verwenden Sie stattdessen das Patch-Abonnement",
    "PC_ORDER_CANCELLATION":"Die Bestellung kann nicht storniert werden. Bitte wenden Sie sich an den Support, um Hilfe zu erhalten.",
    "PC_ORDER_CANCELLATION_ERROR":"Die Bestellung kann nicht storniert werden.",
    "PC_ORDER_IN_PROGRESS":"Ihre Bestellung ist in Bearbeitung. Bitte prüfen Sie in wenigen Minuten Ihre Bestellhistorie auf neue Bestellungen.",
    "PC_ORDER_NOT_FOUND":"Bestellung nicht gefunden!",
    "PC_ORDER_VENDOR_POLICY":"Die Bestellung konnte aufgrund von Herstellerrichtlinien nicht aufgegeben werden. Bitte kontaktieren Sie den Support, um mehr zu erfahren.",
    "PC_ORGANISATION_REGESTRATION_ID_INFORMATION_MISSING":"Die Registrierungsdaten der Organisation fehlen. Dieser Fehler tritt auf, wenn die Firma/Organisation des Kunden in einem der folgenden Länder ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA)aber organizationRegistrationNumber wird nicht übergeben.",
    "PC_ORGANISATION_REGESTRATION_ID_MISSING":"Die Registrierungsnummer der Organisation ist nicht angegeben. Bitte geben Sie eine gültige Registrierungsnummer ein, bevor Sie es erneut versuchen.",
    "PC_ORGANISATION_REGISTRATION_ID_NOT_SUPPORTED":"Der Wert der Registrierungsnummer der Organisation wird nicht unterstützt. Diese Fehlermeldung wird ausgegeben, wenn die Firma/Organisation des Kunden nicht in einem der folgenden Länder ansässig ist: Armenien(AM), Aserbaidschan(AZ), Weißrussland(BY), Ungarn(HU), Kasachstan(KZ), Kirgisistan(KG), Moldawien(MD), Russland(RU), Tadschikistan(TJ), Usbekistan(UZ), Ukraine(UA), aber sie haben versucht, organizationRegistrationNumber anzugeben.",
    "PC_ORGANIZATION_REGISTRATION_ID_INFORMATION_IS_INVALID":"Die Angabe der Organisationsregistrierungsnummer ist ungültig. Bitte überprüfen Sie die Eingabe und versuchen Sie es erneut.",
    "PC_ORGINAL_TRANSFER_IN_PROGRESS":"Diese Übertragung ist nicht zulässig, da die ursprüngliche Übertragung {0} im Gange ist.",
    "PC_OVERAGE_ALREADY_ASSIGNED_TO_ANOTHER_TENANT_CONTACT_CUSTOMER_TO_RESOLVE_OWNERSHIP":"Überschuss ist bereits einem anderen Mieter zugewiesen. Wenden Sie sich an Ihren Kunden, um Eigentumsfragen zu klären",
    "PC_OVERAGE_CAN_NOT_BE_SET_AS_CUSTOMER_HAS_LEGACY_AZURE_SUBSCRIPTION":"Die Überschreitung kann nicht festgelegt werden, da der Kunde über Legacy-Azure-Abonnements verfügt.",
    "PC_PARTNER_ATTESTATION_MISSING":"Partnerbescheinigung fehlt",
    "PC_PARTNER_INFORMATION_MISSING":"Die Anfrage konnte nicht abgeschlossen werden, da die Partnerinformationen fehlen. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_PARTNER_TENANT_ID_IS_NOT_YET_ALLOWED_TO_PERFORM_MIGRATION_TO_NCE":"Die Partnermandanten-ID darf noch keine Migrationen zu New Commerce für ihre Abonnements durchführen",
    "PC_PARTNER_UPDATE_SUBSCRIPTION_ISSUE":"Der eingetragene Partner für dieses Abonnement kann nicht aktualisiert werden. Wenden Sie sich bitte an den Microsoft-Support, um Hilfe zu erhalten.",
    "PC_PENDING_ADJUSTMENT_ORDER_REQUEST":"Für diese Bestellung wurde eine ausstehende Anpassung angefordert. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_PENDING_OPERATION_ON_THIS_SUSBCRIPTION":"Es gibt einen ausstehenden Vorgang für dieses Abonnement. Bitte versuchen Sie es nach 24 Stunden erneut.",
    "PC_PHONE_NUMBER_MISSING":"Die Kundentelefonnummer fehlt. Bitte geben Sie die Kundentelefonnummer an, bevor Sie es erneut versuchen.",
    "PC_PRICING_INFORMATION_NOT_PERMITTED":"Der Zugriff auf Preisinformationen ist nicht erlaubt. Bitte kontaktieren Sie den Support.",
    "PC_PRODUCT_NOT_FOUND":"Produkt wurde nicht gefunden",
    "PC_PROPERT_NOT_SUPPORTED":"Diese Eigenschaft wird nicht unterstützt. Bitte überprüfen Sie dies und versuchen Sie es erneut.",
    "PC_PURCHASE_NOT_SUPPORTED":"Das Einkaufssystem wird nicht unterstützt. Wenden Sie sich bitte an den Partner für weitere Informationen.",
    "PC_PURCHASE_ORDER_DOCUMENT_IS_REQUIRED":"Bestelldokument ist erforderlich.",
    "PC_PURCHASE_PRE_REQUISTIES_MISSING":"Eine oder mehrere Kaufvoraussetzungen sind nicht erfüllt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_QTY_UPDATE_ERROR_DURING_SUSPEND":"Die Abonnementmenge kann nicht erhöht oder verringert werden, während das Abonnement ausgesetzt ist.",
    "PC_QUANTITY_CANNOT_DECREASED":"Die Abonnementmenge kann nicht verringert werden. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
    "PC_QUANTITY_NOT_AVAILABLE_CATAGLOG_ITEM":"Die Menge ist für diesen Katalogartikel nicht verfügbar. Bitte passen Sie die Menge an, bevor Sie eine Bestellung aufgeben, oder kontaktieren Sie den Partner für weitere Informationen.",
    "PC_QUANTITY_UPDATE_NOT_ALLOWED_ON_SUSPENDED_DELETED_SUBSCRIPTION":"Mengenänderungen bei Abonnements mit dem Status \"ausgesetzt\" oder \"gelöscht\" werden nicht unterstützt. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
    "PC_REQUEST_INFORMATION_NOT_AVAILABLE":"Der Vorgang konnte nicht abgeschlossen werden, da die angeforderten Informationen derzeit nicht verfügbar sind. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.",
    "PC_REQUESTED_QUANTITY_IS_NOT_WITHIN_ALLOWED_QUNATITY_FOR_THIS_OFFER":"Die angeforderte Menge liegt nicht innerhalb der zulässigen Menge für dieses Angebot.",
    "PC_RESELLER_RELATIONSHIP_MISSING":"Es besteht keine Wiederverkäuferbeziehung mit dem Kunden. Bauen Sie bitte eine Reseller-Beziehung auf, bevor Sie diese Aktion erneut versuchen.",
    "PC_RI_PURCHASE_NOT_ENABLED":"Dieses Abonnement ist nicht für den RI-Kauf freigegeben. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_SANDBOX_LIMIT":"Das Sandbox-Limit wurde erreicht. Bitte passen Sie das Limit an, bevor Sie eine Bestellung aufgeben.",
    "PC_SELLING_NOT_ALLOWED_IN_COUNTRY":"Der Verkauf im Land des Kunden wird nicht unterstützt. Bitte prüfen Sie die Liste der Microsoft CSP-Märkte, um weitere zu finden.",
    "PC_SKU_NOT_FOUND":"SKU nicht gefunden",
    "PC_SOMETHING_WENT_WRONG_TRY_AFTER_SOMETIME":"Etwas ist schief gelaufen. Versuchen Sie es nach einiger Zeit erneut.",
    "PC_SUBCRIPTION_ACTIVE_ISSUE":"Dieses Abonnement ist kein aktives Abonnement. Bitte kontaktieren Sie unseren Partner für weitere Informationen.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_CONTAINS_ADDONS":"Das Abonnement kann nicht zu New Commerce migriert werden, da es Add-Ons enthält.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_IT_DOES_NOT_SUPPORT_TERM_DURATION_AND_BILLING_CYCLE":"Das Abonnement kann nicht zu New Commerce migriert werden, da die Kombination aus Laufzeit und Abrechnungszeitraum für das New-Commerce-Produkt nicht unterstützt wird.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_NO_VALID_PATH":"Das Abonnement kann nicht zu New Commerce migriert werden, da es noch keinen gültigen Migrationspfad für dieses Angebot gibt.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_OF_ONE_OR_MORE_SEASON":"Das Abonnement kann aus einem oder mehreren Gründen nicht zu New Commerce migriert werden (mögliche Gründe: das Abonnement befindet sich nicht im Status „Aktiv“ – das Abonnement enthält Werbeaktionen – das Abonnement enthält Add-Ons – das Abonnement steht kurz vor dem Ende der Laufzeit – das Abonnementangebot ist noch nicht in New Commerce verfügbar)",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_PROMOTION_APPLIED":"Das Abonnement kann nicht zu New Commerce migriert werden, da Werbeaktionen darauf angewendet wurden",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_IS_CURRENTLY_BEING_PROCESSED":"Das Abonnement kann nicht zu New Commerce migriert werden, da das Abonnement derzeit verarbeitet wird.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_NCE_BECAUSE_SUSBSCRIPTION_ITS_LESS_THAN_24_HOURS_FOR_TERM_END":"Das Abonnement kann nicht zu New Commerce migriert werden, da es weniger als 24 Stunden vor dem Ende der Laufzeit ist.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_AS_ITS_BEEN_ACTIVE_FOR_ONE_MONTH_OR_LESS":"Das Abonnement kann nicht zu New Commerce migriert werden, da es 1 Monat oder weniger aktiv war. Bitte versuchen Sie es später erneut.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_ONE_OR_MORE_ADDON_DOES_NOT_EXIST_IN_MIGRATION":"Das Abonnement kann nicht zu New-Commerce migriert werden, da mindestens ein Add-on-Abonnement, das in der AddOnMigrations-Sammlung angegeben ist, nicht vorhanden oder nicht aktiv ist.",
    "PC_SUBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_PARTNERID_ON_RECORD_IN_CURRENT_SUSBCRIPTION_IS_NOT_VALID":"Das Abonnement kann nicht zu New Commerce migriert werden, da die im aktuellen Abonnement eingetragene Partner-ID ungültig ist.",
    "PC_SUBSCRIPTION_HISTORY_ISSUE":"Der Abonnementverlauf konnte nicht wiederhergestellt werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_SUBSCRIPTION_IS_IN_THE_PROCESS_OF_BEING_EXPIRED":"Ihr Abonnement läuft gerade ab. Für dieses Abonnement sind keine weiteren Updates zulässig.",
    "PC_SUBSCRIPTION_NOT_FOUND":"Das Abonnement, das Sie zu verwenden versuchen, ist bei Microsoft nicht mehr verfügbar.",
    "PC_SUBSCRIPTION_NOT_REGISTERED":"Dieses Abonnement ist nicht registriert. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_SUBSCRIPTION_OFFER_IS_NOT_AVAILABLE_FOR_PURCHASE":"Das Abonnementangebot ist nicht zum Kauf verfügbar. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
    "PC_SUBSCRIPTION_UPDATE":"Das Abonnement ist nicht bereit für eine Aktualisierung. Bitte versuchen Sie es nach einiger Zeit erneut oder kontaktieren Sie den Support, wenn das Problem weiterhin besteht.",
    "PC_SUBSCRIPTIONS_LIMIT_EXCEEDED":"Die gewünschte Anzahl von Abonnements hat die maximal zulässige Anzahl von Abonnements pro Kunde überschritten",
    "PC_SUSBSCRIPTION_CANNOT_BE_MIGRATED_TO_NCE_BECAUSE_STATUS_INACTIVE":"Das Abonnement kann nicht zu New Commerce migriert werden, da der Status nicht aktiv ist.",
    "PC_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE_SUBSCRIPTION":"Das Enddatum der Zielabonnementverpflichtung muss nach dem Quellabonnement liegen",
    "PC_TARGET_TRANSITION_CAN_OT_HAVE_TERM_DURATION_LESS_THAN_SOURCE_SUBSCRIPTION":"Der Zielübergang darf keine kürzere Laufzeit als das Quellabonnement haben.",
    "PC_TARGET_TRANSITION_SUBSCRIPTION_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_IS_NOT_READY_FOR_UPGRADE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Das Zielabonnement kann nicht aktualisiert werden. Bitte versuchen Sie es nach einiger Zeit erneut.",
    "PC_TENANT_ALREADY_USED":"Die bei der Erstellung des Tenants angegebene Domain wird bereits von einem anderen Tenant verwendet. Wählen Sie eine andere Domain und versuchen Sie es erneut.",
    "PC_TENANT_NOT_EXISTS":"Der Domänenname existiert nicht. Bitte überprüfen Sie den Domänennamen.",
    "PC_TENANT_NOT_FOUND":"Tenant nicht gefunden.",
    "PC_TERM_DURATION_ON_TARGET_IS_INVALID":"Die Begriffsdauer für den Zielübergang ist ungültig.",
    "PC_TERM_EXCEEDED":"Die ursprüngliche Kaufbedingung ist nicht mehr zum Kauf/Aktualisieren verfügbar.",
    "PC_THE_CATALOG_ITEM_ID_IS_NOT_PRESENT":"Die Katalogartikel-ID ist nicht vorhanden. Wenden Sie sich bitte an den Support, um Hilfe zu erhalten.",
    "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_DOES_NOT_MATCH_WITH_SPECIFIED_END_DATE":"Das Enddatum der benutzerdefinierten Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, stimmt nicht mit dem Enddatum eines aktiven OnlineServicesNCE-Abonnements ohne Testversion überein oder stimmt nicht mit dem Ende des Kalendermonats überein.",
    "PC_THE_CUSTOM_TERM_END_DATE_SPECIFIED_ON_THE_SCHEDULED_NEXT_TERM_INSTRUCTIONS_MUST_BE_WITHIN_THE_FIRST_TERM_DURATION_AFTER_RENEWAL":"Das Enddatum der benutzerdefinierten Laufzeit, das in den Anweisungen für die geplante nächste Laufzeit angegeben ist, muss innerhalb der ersten Laufzeit nach der Verlängerung liegen. Produkte mit monatlicher Laufzeit können nicht mit einem Abonnement übereinstimmen, das am 28., 29. oder 30. des Monats endet, es sei denn, dieses Datum ist der letzte Tag des Monats.",
    "PC_THE_REQUEST_FAILED_TO_RETRIEVE_ALL_AVAILABILITIES_RESOURCE_INFORMATION":"Die Anfrage konnte nicht alle Informationen über verfügbare Ressourcen abrufen. Bitte überprüfen Sie dies nach einiger Zeit, bevor Sie es erneut versuchen.",
    "PC_THE_REQUESTED_PROGRAM_IS_NOT_ACTIVE":"Das angeforderte Programm ist nicht aktiv. Wenden Sie sich bitte an den Support, um mehr zu erfahren.",
    "PC_THE_SUPPLIED_ROLE_DOES_NOT_HAVE_THE_RIGHTS_TO_PERFORM_THE_REQUESTED_OPERATION":"Die angegebene Rolle hat nicht die Rechte, um die angeforderte Operation durchzuführen. Wenden Sie sich bitte an den Support, um weitere Informationen zu erhalten.",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED":"Dieser Vorgang wird nicht unterstützt. Wenden Sie sich bitte an den Support, um weitere Informationen zu erhalten.",
    "PC_THIS_OPERATION_IS_NOT_SUPPORTED_IN_THIS_ENV":"Dieser Vorgang wird in dieser Umgebung nicht unterstützt. Bitte kontaktieren Sie den Partner für weitere Informationen.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_CUSTOMER_HAS_NOT_PURCHASED_AZURE_PLAN":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Kunde keinen Azure-Plan erworben hat.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT":"Diese Übertragungsanforderung kann nicht initiiert werden, da der Azure-Plan nicht gekauft werden kann. Wenden Sie sich bitte an den Partner, um weitere Informationen zu erhalten.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_AS_THE_AZURE_PLAN_IS_NOT_ACTIVE":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Azure-Plan nicht aktiv ist. Bitte aktivieren Sie den Azure-Plan und versuchen Sie es erneut.",
    "PC_THIS_TRANSFER_REQUEST_CANNOT_BE_INITIATED_DUE_TO_LEGACY_SUBSCRIPTION":"Diese Übertragungsanfrage kann nicht initiiert werden, da der Kunde über ältere Azure-Abonnements verfügt.",
    "PC_TOKEN_MISSING":"Partner Token fehlt im Anfragekontext.",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_COMMITMENT_END_DATE_MUST_BE_LATER_THAN_SOURCE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. CommitmentEndDate des Zielabonnements muss nach dem Quellabonnement liegen",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTION_MUST_BE_ACTIVE":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Zielabonnement muss aktiv sein.",
    "PC_TRAGET_TRANSITION_TO_SUBSCRIPTIONID_FIELD_IS_INVALID_TARGET_SUBSCRIPTIONID_CANNOT_BE_SAME_AS_SOURCE_SUBSCRIPTION_ID":"Das Feld „ToSubscriptionId“ des Zielübergangs ist ungültig. Die Zielabonnement-ID darf nicht mit der Quellabonnement-ID identisch sein.",
    "PC_TRANSACTION_ELIGIBLE":"Sie sind möglicherweise für diese Transaktion berechtigt. Bitte kontaktieren Sie den Support für Hilfe.",
    "PC_TRANSACTION_NOT_ELIGIBLE":"Sie sind für diese Transaktion nicht berechtigt. Bitte reduzieren Sie die Menge oder wenden Sie sich an den Support, um Hilfe zu erhalten.",
    "PC_TRANSFER_CANNOT_BE_CREATED_NATIONAL_CLOUD_NOT_SUPPORTED":"Der Transfer kann nicht als Partner erstellt werden: {0} nationale Cloud {1} wird nicht unterstützt",
    "PC_TRANSFER_CANNOT_BE_INITIATED_AS_SOURCE_PARTNER_EMAIL_DOMAIN_DETAILS_MISSING":"Die Übertragung kann nicht eingeleitet werden, da die E-Mail/Domain-Details des Quellpartners in der Anfrage fehlen. Bitte geben Sie die erforderlichen Informationen ein, bevor Sie es erneut versuchen.",
    "PC_TRANSFER_CREATION_FAILED_DUE_TO_SOURCE_PARTNER_DETAILS_MATCHED_WITH_REQUESTING_PARTNER":"Die Erstellung der Übertragung ist fehlgeschlagen, da die Details des Quellpartners mit denen des anfordernden Partners übereinstimmen. Bitte kontaktieren Sie den Microsoft-Support für weitere Informationen.",
    "PC_TRANSFER_OF_BILLING_OWNERSHIP_CANNOT_BE_COMPLETED_AS_AZURE_RESERVATIONS_DO_NOT_TRANSFER_WITH_SUBSCRIPTIONS_CANCEL_T":"Diese Anforderung für die Übertragung des Rechnungseigentums kann nicht abgeschlossen werden, da Azure-Reservierungen nicht mit Abonnements übertragen werden. Stornieren Sie die Azure-Reservierungen, die mit den Abonnements in Ihrer Auswahl verbunden sind, und versuchen Sie es erneut.",
    "PC_TRANSFER_REQUEST_CANNOT_BE_ACCEPTED":"Die Übertragungsanforderung kann nicht akzeptiert werden. Bitte fordern Sie den Partner auf, eine Übertragung ohne Azure-Abonnement(e) zu erstellen",
    "PC_TRANSFER_REQUEST_DISABLE":"Übertragung kann nicht als Partner erstellt werden: {0} nicht für diese Funktion aktiviert",
    "PC_UNABLE_TO_COMPLETE_THE_REQUEST":"Die Anfrage kann nicht abgeschlossen werden. Wenden Sie sich bitte an den Microsoft-Support für weitere Informationen.",
    "PC_UNABLE_TO_CREATE_ORDER_DUE_TO_LIMIT_AZURE_PLANS_CREATION_IN_SANDBOX_ACCOUNT":"Auftrag kann nicht erstellt werden. Die Anzahl der Azure-Pläne, die unter Sandbox-Konten erstellt werden können, ist begrenzt",
    "PC_UNABLE_TO_FIND_CUSTOMER_PROFILE":"Das Kundenprofil zum Abschließen der Anfrage kann nicht gefunden werden. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_UNABLE_TO_GET_AZURE_ACTIVE_DIRECTORY_SUBSCRIPTIONS_FOR_A_CUSTOMER":"Es können keine Azure Active Directory-Abonnements für einen Kunden mit der Tenant-ID {0} und der Source Provisioning-ID {1} abgerufen werden.",
    "PC_UNABLE_TO_PROCESS_ORDER_DUE_TO_INSUFFICIENT_CREDIT_LIMIT_PLEASE":"Bestellung kann wegen unzureichendem Kreditlimit nicht bearbeitet werden, bitte wenden Sie sich an ucmwrcsp@microsoft.com für weitere Hilfe.",
    "PC_UNEXPECTED_ERROR_WHILE_PEFORMING_MIGRATION":"Bei der Verarbeitung der New-Commerce-Migration ist ein unerwarteter Fehler aufgetreten.",
    "PC_UNSUCCESSFUL_TRANSFER_ORDER":"Die Übertragung der Bestellung war nicht erfolgreich. Bitte kontaktieren Sie den Support für weitere Informationen.",
    "PC_UPDATE_OPERATION_CURRENTLY_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Aktualisierungsvorgänge sind derzeit für dieses Abonnement nicht zulässig.",
    "PC_UPDATE_OPERATION_NOT_ALLOWED_FOR_SUSBSCRIPTION":"Aktualisierungsvorgänge sind für dieses Abonnement nicht mehr zulässig.",
    "PC_UPDATES_NOT_ALLOWED":"Kann ein Abonnement nicht aktualisieren, bevor es aktiviert wurde'",
    "PC_UPGRADE_REQUEST_FOR_ALREADY_UPGRADED_SUBSCRIPTION":"Die Upgrade-Anforderung kann für ein bereits aktualisiertes Abonnement nicht bearbeitet werden.",
    "PC_UPGRADE_REQUEST_IN_PROGRESS":"Die Upgrade-Anforderung wurde nicht abgeschlossen, da eine andere Upgrade-Anforderung in Bearbeitung ist.",
    "PC_USER_LIMIT_EXCEEDED":"Die gewünschte Anzahl von Plätzen hat die maximal zulässige Anzahl von Plätzen pro Abonnement überschritten",
    "PC_USER_NOT_FOUND":"Der Benutzer wurde im Zielmandanten nicht gefunden.",
    "PC_WHEN_PART_OF_TENDER_EITHER_TENDER_LINK_OR_TENDER_DOCUMENT_IS_REQUIRED":"Wenn Teil der Ausschreibung, ist entweder Ausschreibungslink oder Ausschreibungsdokument erforderlich.",
    "PC_YOU_ARE_NOT_ELIGIBLE_TO_MANAGE_OVERAGE_FOR_THIS_CUSTOMER":"Sie sind nicht berechtigt, den Überschuss für diesen Kunden zu verwalten.",
    "PC_YOU_HAVE_PASSED_YOUR_CANCELLATION_WINDOW_UNABLE_TO_CANCEL_PURCHASE":"Sie haben Ihr Kündigungsfenster überschritten. Wir können Ihren Kauf nicht stornieren",
    "PC_YOU_HAVE_PASSSED_YOUR_DAY_WINDOW_FOR_REDUCING_SEATS_FOR_THIS_SUSBCRIPTION":"Sie haben Ihr Tagesfenster zum Reduzieren von Plätzen für dieses Abonnement überschritten.",
    "PC_YOUR_COMPANY_HAS_NOT_ACCEPTED_THE_MICROSOFT_PARTNER_AGREEMENT":"Ihr Unternehmen hat das Microsoft Partner Agreement (MPA) nicht akzeptiert. Der globale Administrator des CSP-Kontos muss das MPA akzeptieren, um die vollen Transaktionsfunktionen wieder aufzunehmen.",
    "PCA_MISSING_MPA":"Fehlende Microsoft-Partnervereinbarung vom Partner.",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_GET_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus aus der Abrechnung abrufen",
    "PENDING_PAYMENT_VIEW_BUTTON_TOOLTIP_TEXT_UPDATE_PAYMENT_STATUS_IN_C3":"Zahlungsstatus aktualisieren in c3",
    "PENDING_PAYMENT_VIEW_NOTIFICATION_TEXT_SUCCESSFULLY_UPDATED_PAYMENT_STATUS":"Der Zahlungsstatus für {{Dateiname}} wurde aktualisiert.",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ACTION":"Aktionen",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_BILLING_PAYMENT_STATUS":"Zahlungsstatus für die Abrechnung",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_C3_PAYMENT_STATUS":"C3-Zahlungsstatus",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_ENTITY_NAME":"Entitätsname",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_INVOICE_NUMBER":"Rechnungsnummer",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_PAID_AMOUNT":"Bezahlte Menge",
    "PENDING_PAYMENT_VIEW_TABLE_COLUMN_HEADER_RECORD_NAME":"Datensatzname",
    "PENDING_PURCHASE_REQUESTS_BUTTON_TEXT_VIEW_DETAILS":"Details anzeigen",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ACTIONS":"Aktionen",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDER_ID":"Auftragsnummer",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_ORDERED_ON":"Bestellt am",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REMARKS":"Bemerkungen",
    "PENDING_PURCHASE_REQUESTS_DETAILS_TABLE_HEADER_REQUEST_TYPE":"Anfragetyp",
    "Percentage":"Prozentsatz",
    "PERPETUAL_SOFTWARE_PRODUCT_LABEL_SOFTWARE_KEYS":"Software-Schlüssel",
    "PERPETUAL_SOFTWARE_TOOLTIP_TEXT_DOWNLOAD_SOFTWARE_AND_KEYS":"Laden Sie Software und Schlüssel herunter",
    "PHONE_TABLE_HEADER_ACTIONS":"Aktionen",
    "PHONE_TABLE_HEADER_PHONE_NUMBER":"Telefonnummer",
    "PHONE_TABLE_HEADER_PHONE_TYPE":"Telefon-Typ",
    "PHONE_TYPE_HOME":"Heim",
    "PHONE_TYPE_HOME_FAX":"Privatfax",
    "PHONE_TYPE_MAIN":"Hauptsächlich",
    "PHONE_TYPE_MOBILE":"Handy, Mobiltelefon",
    "PHONE_TYPE_OFFICE":"Büro",
    "PHONE_TYPE_OTHER":"Andere",
    "PHONE_TYPE_WORK":"Arbeiten",
    "PHONE_TYPE_WORK_FAX":"Arbeitsfax",
    "PLACE_HOLDER_SEARCH_CUSTOMER":"Kunde suchen",
    "PLACEHOLDER_ENTER_TEXT":"Text eingeben",
    "PLACEHOLDER_FOR_BILLING_CYCLE_DROPDOWN":"Abrechnungszyklus auswählen...",
    "PLACEHOLDER_FOR_BILLING_TYPE_DROPDOWN":"Abrechnungsart auswählen...",
    "PLACEHOLDER_FOR_CATEGORIES_DROPDOWN":"Wählen Sie Kategorien",
    "PLACEHOLDER_FOR_CATEGORY_TYPE_DROPDOWN":"Kategorie-Typ wählen...",
    "PLACEHOLDER_FOR_CONSUMPTION_TYPE_DROPDOWN":"Verbrauchsart auswählen...",
    "PLACEHOLDER_FOR_CURRENCY_CODE_DROPDOWN":"Währungscode suchen",
    "PLACEHOLDER_FOR_CUSTOMERS_DROPDOWN":"Kunden suchen",
    "PLACEHOLDER_FOR_CVV_INPUT_FIELD":"CVV",
    "PLACEHOLDER_FOR_DROPDOWN_TO_SELECT_A_PROVIDER_CUSTOMER":"Wählen Sie einen Provider-Kunden in der Liste aus oder suchen Sie den Namen oder die Mieter-ID...",
    "PLACEHOLDER_FOR_EFFECTIVE_FROM_DATE":"Gültig ab",
    "PLACEHOLDER_FOR_EFFECTIVE_TO_DATE":"Wirksam für",
    "PLACEHOLDER_FOR_MONTH_INPUT_FIELD":"MM",
    "PLACEHOLDER_FOR_PLAN_DESCRIPTION":"Text eingeben",
    "PLACEHOLDER_FOR_PLAN_NAME":"Geben Sie den Plannamen ein",
    "PLACEHOLDER_FOR_PRICE":"Preis",
    "PLACEHOLDER_FOR_PROVIDERS_DROPDOWN":"Wählen Sie Anbieter",
    "PLACEHOLDER_FOR_REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER":"Kunden suchen",
    "PLACEHOLDER_FOR_ROLES_DROPDOWN":"Rollen auswählen ..",
    "PLACEHOLDER_FOR_SCHEDULED_RENEWAL_CUSTOM_END_DATE_TYPE":"Wählen",
    "PLACEHOLDER_FOR_TAGS_DROPDOWN":"Tag-Werte auswählen.",
    "PLACEHOLDER_FOR_TERM_DURATION_DROPDOWN":"Laufzeit auswählen...",
    "PLACEHOLDER_FOR_TEXT_SEARCH":"Suche...",
    "PLACEHOLDER_FOR_TRIAL_DURATION_DROPDOWN":"Testdauer auswählen...",
    "PLACEHOLDER_FOR_VALIDITY_TYPE_INPUT_DROPDOWN":"Gültigkeitsart auswählen...",
    "PLACEHOLDER_FOR_YEAR_INPUT_FIELD":"JJJJ",
    "PLACEHOLDER_SPECIAL_QUALIFICATION":"Besondere Qualifikationen",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT":"Bearbeiten",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_EDIT_HEADING":"Besondere Qualifikationen bearbeiten",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MAX_ERROR":"Die maximal zulässigen Zeichen sind 50",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MIN_ERROR":"sollte mindestens ein Zeichen haben",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_MODAL_SAVE":"speichern",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_NONE":"Keiner",
    "PLACEHOLDER_SPECIAL_QUALIFICATION_REQUIRED":"Dieses Feld wird benötigt",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_CLOSE":"Schließen",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION":"Ausbildung",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT":"Segment Bildung",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_1":"K12",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_OPTION_2":"Höhere Bildung",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_EDUCATION_SEGMENT_WEBSITE":"Webseite",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_NONE":"Keiner",
    "PLACEHOLDER_SPECIAL_QUALIFICATIONS_MODAL_STATE_OWNED_ENTITY":"Staatliches Unternehmen",
    "PLACEHOLDER_SPECIFIC_IDIVIDUALS":"Spezifische {{EntityName}} auswählen? Klicken Sie hier...",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TAG":"Geben Sie ein, um nach einem Tag zu suchen",
    "PLACEHOLDER_TEXT_FOR_DROPDOWN_TO_SELECT_A_TENANT":"Wählen Sie einen Mandanten in der Liste aus oder suchen Sie den Namen/die Mandanten-ID...",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PLAN":"Suchplan",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT":"Produktname",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_ID":"Produkt-ID",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_OR_PRODUCT_ID":"Suchen Sie mit dem Produktnamen oder der Produkt-ID",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_PRODUCT_SHOP_AND_PRODUCT_PAGE":"Produktname, ID",
    "PLACEHOLDER_TEXT_FOR_INPUT_FIELD_TO_SEARCH_A_SUBSCRIPTION":"Nach Abonnement suchen",
    "PLACEHOLDER_TEXT_FOR_INPUT_TEXTAREA_TO_GET_EMAIL_IDS":"Geben Sie gültige E-Mail-IDs ein",
    "PLACEHOLDER_TEXT_FOR_MAX_COST_PRICE":"Max. Selbstkostenpreis",
    "PLACEHOLDER_TEXT_FOR_MAX_SALE_PRICE":"Max. Verkaufspreis",
    "PLACEHOLDER_TEXT_FOR_MAX_VALIDITY":"Max. Gültigkeit",
    "PLACEHOLDER_TEXT_FOR_MIN_COST_PRICE":"Mindest. Selbstkostenpreis",
    "PLACEHOLDER_TEXT_FOR_MIN_SALE_PRICE":"Mindest. Verkaufspreis",
    "PLACEHOLDER_TEXT_FOR_MIN_VALIDITY":"Mindest. Gültigkeit",
    "PLACEHOLDER_TEXT_FOR_PAYMENT_AMOUNT":"Zahlungsbetrag",
    "PLACEHOLDER_TEXT_FOR_PROMPTING_USER_TO_TYPE_INPUT":"Geben Sie hier ein...",
    "PLACEHOLDER_TEXT_FOR_PROVIDER_CUSTOMERS_DROPDOWN":"Wählen Sie einen vorhandenen Kunden aus der Liste aus oder suchen Sie anhand des Kundennamens/der Mandanten-ID",
    "PLACEHOLDER_TEXT_FOR_REMARKS":"Bemerkungen",
    "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_CUSTOMER":"Suche nach Kunde",
    "PLACEHOLDER_UPLOAD_TO_PSA_SEARCH_BY_STATUS":"Suche nach Status",
    "PLAN_ADD_CHECKBOX_TOOLTIP_ALLOW_CSP":"Aktivieren Sie dieses Kontrollkästchen, wenn Sie in Zukunft zusammengesetzte Angebote in diesem Plan haben möchten.",
    "PLAN_ASSIGN_STATUS_SUCCESS":"Der Status wurde erfolgreich aktualisiert",
    "PLAN_BREADCRUMB_BUTTON_PLAN":"Pläne",
    "PLAN_BUTTON_LABEL_ADD_NEW_PLAN":"Neuen Plan hinzufügen",
    "PLAN_CAPTION_TEXT_PLANS":"Pläne",
    "PLAN_CATEGORY_TAG_TEXT_NCE":"NCE",
    "PLAN_CHANGE_FOR_CUSTOMER":"Plan des Kunden ändern",
    "PLAN_CHANGE_TEXT":"Planänderung",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_COMPOSITE_PLAN_OFFERS":"Composite Plan Angebote",
    "PLAN_COMPOSITE_OFFERS_BREADCRUMB_BUTTON_TEXT_PLAN":"Pläne",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_BUTTON_BACK_TO_COMPOSITE_OFFERS":"Zurück zu zusammengesetzten Angeboten",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_ADD_COMPOSITE_OFFER":"Zusammengesetztes Angebot hinzufügen",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_CAPTION_TEXT_EDIT_COMPOSITE_OFFER":"Zusammengesetztes Angebot bearbeiten",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_CHILD_OFFERS":"Bietet an",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_HEADER_ITEMS_SELECTED":"Ausgewählte Elemente",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_LABEL_SUGGESTED_PRICE":"Vorgeschlagener Preis :",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_1":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_PARAGRAPH_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS":"Add-on(s)",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ADD_ONS_PLACEHOLDER":"Add-Ons",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Listenpreis",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Entfernen",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTAREA_DESCRIPTION":"Beschreibung",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_BILLING_COMPONENT_ID":"Abrechnungskomponenten-ID",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_OFFER_NAME":"Angebotsname",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSEDIT_FORM_TEXTBOX_PRICE":"Preis",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_ADD_COMPOSITE_OFFER":"Zusammengesetztes Angebot hinzufügen",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_BUTTON_HEADER_TEXT_BACK_TO_PLAN_DETAILS":"Zurück zu den Planungsdetails",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_CAPTION_TEXT_COMPOSITE_OFFER_FOR":"Zusammengesetzte Angebote für -",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_ACTIONS_BUTTON_EDIT":"Bearbeiten",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_INCLUDED_OFFERS":"Inbegriffene Angebote",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_NAME":"Name",
    "PLAN_COMPOSITE_OFFERS_COMPOSITEOFFERSLIST_TABLE_HEADER_TEXT_PRICE":"Preis",
    "PLAN_DETAILS_ADDON_PARENT_NAME":"<b>unter</b>{{ParentOfferName}}",
    "PLAN_DETAILS_BUTTON_TEXT_SIGNUP":"Anmelden",
    "PLAN_DETAILS_IS_PUBLIC":"Ist öffentlich",
    "PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "PLAN_DETAILS_PARAGRAPH_1_TEXT":"pro Monat",
    "PLAN_DETAILS_PARAGRAPH_2_TEXT":"Geschätzter Verkaufspreis",
    "PLAN_DETAILS_PARAGRAPH_3_TEXT":"pro Jahr",
    "PLAN_DETAILS_PARAGRAPH_4_TEXT":"Einmalig",
    "PLAN_DETAILS_SHARING_URL":"URL teilen",
    "PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Ihre Änderungen wurden erfolgreich übermittelt",
    "PLAN_IS_IN_PROGRESS_EXCEPTION":"Planung noch in Bearbeitung. Bitte überprüfen Sie den Status",
    "PLAN_LEVEL_SEAT_LIMIT_MINIMUM_VALUE_ERROR":"Der Mindestwert ist -1",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_1":"Legen Sie den Grenzwert auf -1 fest, um eine Genehmigung für alle Käufe/Aktualisierungsplätze zu erfordern.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_2":"Legen Sie das Limit auf 0 fest, um den Kauf unbegrenzter Sitzplätze zu ermöglichen.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_3":"Legen Sie eine positive Zahl ungleich Null fest, um den Schwellenwert zu definieren.",
    "PLAN_LEVEL_SEATLIMIT_DETAILS_4":"Jeder Kauf, der den Schwellenwert überschreitet, erfordert die Genehmigung des Partners.",
    "PLAN_LEVEL_SEATLIMIT_EDIT":"Legen Sie das Sitzplatzlimit für das Planprodukt fest",
    "PLAN_LEVEL_SEATLIMIT_REVERT":"Kehren Sie zum Sitzplatzlimit auf Planebene zurück",
    "PLAN_LOAD_PROMOTIONS_BUTTON_TOOLTIP":"Laden Sie die neuesten verfügbaren Microsoft NCE-Promotions in die Pläne.",
    "PLAN_MACRO_DETAILS_LABEL_TEXT":"Makrodetails",
    "PLAN_MACRO_NAME_LABEL_TEXT":"Makroname",
    "PLAN_MACRO_QUANTITY_LABEL_TEXT":"Menge",
    "PLAN_MACRO_VALUE_LABEL_TEXT":"Makrowert",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_BACK_TO_PLAN":"Zurück zu den Plänen",
    "PLAN_MANAGE_ADD_BUTTON_TEXT_MANAGE_COMPOSITE_OFFERS":"Zusammengesetzte Angebote verwalten",
    "PLAN_MANAGE_ADD_CAPTION_1_DETAILS_FOR_PLAN":"Details zum Plan -",
    "PLAN_MANAGE_ADD_CAPTION_2_ADD_NEW_PLAN":"Neuer Plan",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS":"Promotionen laden",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_CLOSE_BUTTON_TEXT":"Stornieren",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_HEADER_TEXT":"Promotionen vom Anbieter laden",
    "PLAN_MANAGE_ADD_CAPTION_3_LOAD_PROMOTIONS_MODEL_SUBMIT_BUTTON_TEXT":"einreichen",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_ISPRIVATE":"Ist privat",
    "PLAN_MANAGE_ADD_FORM_CHECKBOX_LABEL_SHARABLE":"CSP-Abonnements für Angebote im Plan freigeben lassen?",
    "PLAN_MANAGE_ADD_FORM_HEADER_OFFER":"Bietet an",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_1":"Hier sind die Schritte zum Überschreiben eines geerbten Angebots:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_2":"Aktivieren Sie das Kontrollkästchen für das Angebot, das Sie überschreiben möchten",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_3":"Geben Sie einen neuen freundlichen Namen ein",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_4":"Geben Sie den neuen Verkaufspreis an",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_1_PART_5":"Der neue Plan würde beginnen, die überschriebenen Angebote zusammen mit denen aufzulisten, die geerbt, aber nicht überschrieben wurden.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_2":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_1":"Hinweis:",
    "PLAN_MANAGE_ADD_FORM_PARAGRAPH_3_PART_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_2_OVERRIDED_FROM_PARTNER_PLAN":"Vom übergeordneten Plan überschrieben",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_3_SELECTED":"ausgewählt",
    "PLAN_MANAGE_ADD_FORM_SUB_HEADER_ITEMS":"Artikel",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ADD_ON":"Add-on(s)",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Verfügbar zum Kauf",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_BILLING_COMPONENTS_ID":"Abrechnungskomponenten-ID",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_LIST_PRICE":"Listenpreis",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER":"Angebotsname",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_ADD_ON":"Erweiterung",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lizenz-Typ",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_OFFER_NCE":"NCE",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_REMOVE":"Entfernen",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_SALES_PRICE":"Verkaufspreis",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_HEADER_TEXT_UPDATE_PRICE":"Preis aktualisieren",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PLAN_NAME":"Plan Name:",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_PRICE":"Preis:",
    "PLAN_MANAGE_ADD_FORM_TABLE_1_TEXT_SET_NEW_PRICE":"Neuen Preis festlegen",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_LICENSE_AGREEMENT_TYPE":"Lizenz-Typ",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_OVERRIDEN":"Angebot überschrieben",
    "PLAN_MANAGE_ADD_FORM_TABLE_2_HEADER_TEXT_OFFER_SELECTED":"Angebot ausgewählt",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_DESCRIPTION":"Beschreibung",
    "PLAN_MANAGE_ADD_FORM_TEXTBOX_LABEL_PLAN_NAME":"Plan Name",
    "PLAN_MANAGE_ADD_FORM_URL_LABEL":"URL teilen",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_MANAGE_PLANS":"Pläne",
    "PLAN_MANAGE_BREADCRUMB_BUTTON_PLANS":"Verwalten",
    "PLAN_MANAGE_ERROR_FRIENDLY_NAME":"Freundlicher Name darf nicht leer sein",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_NAME":"Angebote mit Werbung anzeigen",
    "PLAN_MANAGE_SELECT_FILTER_SHOW_WITH_PROMOTION_VALUE":"offerWithPromotion",
    "PLAN_MANAGE_TRAIL_OFFER_SYMBOL_TRAIL_OFFER":"TESTANGEBOT",
    "PLAN_MANAGEMENT_TITLE_PARENT_NAMES":"Elternnamen",
    "PLAN_NAME_IS_INVALID":"PlanName ist ungültig",
    "PLAN_OFFER_PRICE_CHANGE_HEDER":"Ist rechnungsbasiert?",
    "PLAN_OFFER_PRICE_CHANGE_HEDER_CHANGE_PLAN_OFFER_PRICE":"Neuen Preis festlegen für",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_EFFECTIVENESS_TYPE":"Preisänderung wäre effektiv",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_NEW_PRICE":"Neuer Preis",
    "PLAN_OFFER_PRICE_CHANGE_LABEL_OLD_PRICE":"Derzeitiger Preis",
    "PLAN_OFFER_PRICE_CHANGE_SUCCESS_NOTIFICATION":"Der Planangebotspreis wurde erfolgreich aktualisiert",
    "PLAN_OFFERS_EQUALTO_OR_LESS_THAN_MARGIN_FILTER":"Angebote mit Marge <= 0 anzeigen",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_0_AND_LESSER_THAN_10":"Angebote mit Marge > 0 und < 10 anzeigen",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_30":"Angebote mit Marge >= 30 anzeigen",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_10_AND_LESSER_THAN_20":"Angebote mit Marge >= 10 und < 20 anzeigen",
    "PLAN_OFFERS_MARGIN_FILTER_GREATER_THAN_EQUAL_TO_20_AND_LESSER_THAN_30":"Angebote mit Marge >= 20 und < 30 anzeigen",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_MANAGE_PLAN":"Plan verwalten",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_OVVERRIDE_PLAN":"Plan überschreiben",
    "PLAN_OVERRIDE_BREADCRUMB_BUTTON_PLANS":"Pläne",
    "PLAN_OVERRIDE_BUTTON_TEXT_BACK_TO_PLANS":"Zurück zu den Plänen",
    "PLAN_OVERRIDE_CAPTION_1_EDIT_PLAN":"Plan bearbeiten",
    "PLAN_OVERRIDE_CAPTION_2_OVERRIDE_PLAN":"Plan überschreiben",
    "PLAN_OVERRIDE_FORM_CHECKBOX_2_LABEL_SHARABLE":"CSP-Abonnements für Angebote im Plan freigeben lassen?",
    "PLAN_OVERRIDE_FORM_CHECKBOX_LABEL_IS_PRIVATE":"Ist privat",
    "PLAN_OVERRIDE_FORM_LABEL_DESCRIPTION":"Beschreibung",
    "PLAN_OVERRIDE_FORM_LABEL_PLAN_NAME":"Plan Name",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_2":"Geben Sie die ID der Abrechnungskomponente an, falls Sie eine vorhandene Komponente in Ihrem Abrechnungssystem verwenden möchten. Wenn Sie den Wert auf 0 belassen, wird eine neue Komponente in Ihrem Abrechnungssystem erstellt.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_1":"Hinweis:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_3_PART_2":"Stellen Sie sicher, dass Sie den Preis angeben, der Ihrer Komponente in Ihrem Abrechnungssystem entspricht. Das System validiert die Preis- und Komponentenkombination derzeit nicht anhand des Abrechnungssystems.",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_1":"Hier sind die Schritte zum Überschreiben eines geerbten Angebots:",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_2":"Aktivieren Sie das Kontrollkästchen für das Angebot, das Sie überschreiben möchten",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_3":"Geben Sie einen neuen freundlichen Namen ein",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_4":"Geben Sie den neuen Verkaufspreis an",
    "PLAN_OVERRIDE_FORM_OFFERS_PARAGRAPH_PART_5":"Der neue Plan würde beginnen, die überschriebenen Angebote zusammen mit denen aufzulisten, die geerbt, aber nicht überschrieben wurden.",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_AVAILABLE_FOR_PURCHASE":"Verfügbar zum Kauf",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_BILLING_COMPONENT_ID":"Abrechnungskomponenten-ID",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_FRIENDLY_NAME":"Freundlicher Name",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_LIST_PRICE":"Listenpreis",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_OFFER_OVERRIDDEN":"Angebot überschrieben",
    "PLAN_OVERRIDE_FORM_SELECTED_TO_OVERRIDE_TABLE_HEADER_TEXT_SALE_PRICE":"Verkaufspreis",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_ITEM_SELECTED_TO_OVERRIDE":"Zum Überschreiben ausgewählte Elemente",
    "PLAN_OVERRIDE_FORM_SUB_HEADER_OFFERS":"Bietet an",
    "PLAN_OVERRIDE_FORM_URL_SHARING_URL":"URL teilen",
    "PLAN_POPUP_GRID_HEADER":"Planraster herunterladen",
    "PLAN_PRODUCT_ALREADY_AVAILABLE_CONFIRMATION":"Dieses Produkt ist bereits im Plan verfügbar. Möchten Sie das Produkt erneut hinzufügen?",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_CURRENCY_CODE":"Währungscode planen",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_PLAN_NAME":"Plan Name",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_LABEL_TARGET_CURRENCY_CODE":"Zielwährungscode",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_PLACEHOLDER_SELECT":"Wählen",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_SOURCE_CURRENCY_CODE":"Quellwährungscode",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TABLE_HEADER_TARGET_CURRENCY_CODE":"Zielwährungscode",
    "PLAN_PRODUCTS_CURRENCY_CONVERSION_TITLE_DEFINE_NEW_CURRENCY":"Neue Währung definieren",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_NUMBER_OF_SEATS":"Erlaubte Anzahl von Plätzen",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
    "PLAN_PRODUCTS_SEAT_LIMITS_DETAILS_TABLE_PLACEHOLDER_FOR_NUMBER_OF_SEATS":"Anzahl der Sitze",
    "PLAN_PRODUCTS_SEAT_LIMITS_INVALID_SEAT_MESSAGE":"Ungültiger Sitzplatzwert für Produkt {{PlanProduktName}}",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_ERROR_MESSAGE":"Fehler beim Aktualisieren der Plätze aufgetreten",
    "PLAN_PRODUCTS_SEAT_LIMITS_UPDATE_SUCCESS_MESSAGE":"Sitzplätze erfolgreich aktualisiert",
    "PLAN_PRODUCTS_SLABS_SUCCESS_MESSAGE":"Platten erfolgreich gespeichert",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_EDIT":"Bearbeiten",
    "PLAN_TABLE_ACTIONS_BUTTON_TEXT_OVERRIDE":"Überschreiben",
    "PLAN_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "PLAN_TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "PLAN_TABLE_HEADER_TEXT_NAME":"Name",
    "plandetails":"Plandetails",
    "planproductcurrencyconversion":"Währungsumrechnung",
    "PlanProductId":"PlanProductId",
    "plans":"Pläne",
    "PLANS_COMPOSITE_OFFERS_MINIMUM_OFFERS_PROMPT":"Bitte wählen Sie mindestens zwei Angebote aus, um ein zusammengesetztes Angebot zu erstellen",
    "PLANS_COMPOSITE_OFFERS_SAVE_SUCCESS":"Zusammengesetztes Angebot erfolgreich gespeichert",
    "PLANS_COMPOSITE_OFFERS_UPDATE_SUCCESS":"Zusammengesetztes Angebot erfolgreich aktualisiert",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CANCEL":"Stornieren",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_CLOSE":"Schließen",
    "PLANS_MANAGE_CONTRACT_DETAILS_BUTTON_TEXT_SUBMIT":"einreichen",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_APPLICABILITY_ALL":"Alle Produkte",
    "PLANS_MANAGE_CONTRACT_DETAILS_INFO_TEXT_NO_RECORDS_FOUND":"Keine Aufzeichnungen gefunden",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_ERROR_":"Bitte speichern bzw. schließen Sie die bestehende neue bzw. Bearbeitungszeile.",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION":"{{(!maxValue) ? \"Bitte geben Sie einen Wert größer als \" + minValue : \"Bitte geben Sie einen Wert zwischen \" + minValue + \" und \" + maxValue }}",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITH_MAX":"Bitte geben Sie einen Wert zwischen {{minValue}} und {{maxValue}} ein",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPOVER_TEXT_FOR_PRICING_SLAB_MINVALUE_VALIDATION_WITHOUT_MAX":"Bitte geben Sie einen Wert größer als {{minValue}} ein",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_HEADER_TEXT":"Vertragsdetails",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_BILLING_TYPE":"Abrechnungsart",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_COST_TO_PARTNER":"Kosten für den Partner",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_HEADER_SLABDATA":"Platten",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_SALE_PRICE":"Verkaufspreis",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MAXVALUE":"Maximaler Wert",
    "PLANS_MANAGE_CONTRACT_DETAILS_POPUP_TABLE_TITLE_MINVALE":"Min. Wert",
    "PLANS_MANAGE_CONTRACT_DETAILS_TABLE_HEADER_AVAILABILITY":"Anwendbarkeit",
    "PLANS_MANAGE_CONTRACT_OFFER_COUNT_WARNING_MESSAGE":"Sie haben bereits ein Vertragsangebot in Ihrem Plan. Löschen/deaktivieren Sie das Angebot, bevor Sie ein neues hinzufügen.",
    "PLANS_MANAGE_TAB_TITLE_CONTRACT":"Vertrag",
    "PLANS_MANAGE_TAB_TITLE_RSERVATION":"Reservierung",
    "PLANS_OVERRIDE_EDIT_SUCCESS":"Überschriebener Plan erfolgreich aktualisiert",
    "PLANS_OVERRIDE_SAVE_SUCCESS":"Plan erfolgreich überschrieben",
    "PLANS_OVERRIDE_SELECT_OFFERS_PROMPT":"Bitte wählen Sie Angebote aus, die aus dem Basisplan überschrieben werden sollen",
    "PLANS_SAVE_SUCCESS":"Plan erfolgreich gespeichert",
    "PLANS_UPDATE_SUCCESS":"Plan erfolgreich aktualisiert",
    "PLEASE_ENTER_THE_REASON":"Bitte geben Sie einen Grund an.",
    "PO_NUMBER":"PO #",
    "POLICY_ASSIGNMENT_SCOPE_TITLE":"Richtlinienzuweisungsbereiche",
    "PONUMBER_BUTTON_CLOSE":"Schließen",
    "PONUMBER_BUTTON_SKIP":"Überspringen",
    "PONUMBER_BUTTON_SUBMIT":"einreichen",
    "PONUMBER_DELETE_CONFIRMATION":"Sind Sie sicher, dass Sie diese Bestellnummer aus dem Warenkorb löschen möchten?",
    "PONUMBER_DELETE_ERROR_MESSAGE":"Beim Entfernen der Bestellnummer aus dem Warenkorb ist ein Fehler aufgetreten!",
    "PONUMBER_DELETE_SUCCESS_MESSAGE":"Die angeforderte Bestellnummer wurde erfolgreich entfernt!",
    "PONUMBER_PLACEHOLDER":"Geben Sie die Bestellnummer ein",
    "PONUMBER_POPUP_TITLE":"Bestellnummer hinzufügen",
    "PONUMBER_TITLE":"Bestellnummer",
    "POPUP_ADDING_MISSING_OFFERS_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie alle Pläne in die Warteschlange stellen möchten?",
    "POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "POPUP_BUTTON_TEXT_CLOSE":"Schließen",
    "POPUP_BUTTON_TEXT_CONFIRM":"Bestätigen",
    "POPUP_CHANGE_BILLING_PERIOD_CONFIRMATION_TEXT":"Alle ausgewählten Angebote im ausgewählten Abrechnungszyklus gehen verloren. Wollen Sie fortfahren?",
    "POPUP_CUSTOMER_COUPON_ALREADY_EXISTS_CONFIRMATION_TEXT":"Dieser Kunde enthält bereits einen Gutschein: <b>{{CouponName}} </b>, möchten Sie ihn ersetzen?.",
    "POPUP_DELETE_BILLING_CUSTOMER_CONFIRMATION_TEXT":"Wenn Sie einen Rechnungskunden entfernen, werden auch die zugehörigen Zahlungsprofile entfernt. Sind Sie sicher, dass Sie den Link zum Rechnungskunden für {{Kundenname}} entfernen möchten?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die benutzerdefinierte Benachrichtigung löschen möchten?",
    "POPUP_DELETE_CUSTOM_NOTIFICATION_SUCCESSFUL_TEXT":"Benutzerdefinierte Benachrichtigung erfolgreich gelöscht.",
    "POPUP_DELETE_CUSTOMER_CONFIRMATION_TEXT":"Möchten Sie {{CustomerName}} wirklich löschen?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_CONFIRMATION_TEXT":"Das Löschen hier führt dazu, dass das Angebot aus den hinzugefügten Reseller-Plänen entfernt wird und deren Kunden es nicht weiter kaufen können! Möchten Sie das Angebot von {{distributorOffer}} wirklich löschen?",
    "POPUP_DELETE_DISTRIBUTOR_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} erfolgreich gelöscht.",
    "POPUP_DELETE_ENGAGE_NOTIFICATION_CONFIRMATION_TEXT2":"Möchten Sie diese Benachrichtigung wirklich löschen?",
    "POPUP_DELETE_HEADER_TEXT_DELETE":"Löschen",
    "POPUP_DELETE_PARTNER_OFFER_CONFIRMATION_TEXT":"Möchten Sie {{customOfferName}} wirklich löschen?",
    "POPUP_DELETE_PARTNER_OFFER_SUCCESSFUL_TEXT":"{{CustomOfferName}} erfolgreich gelöscht.",
    "POPUP_DELETE_PARTNER_TRIAL_OFFER_SUPERSCRIPT":"<sup class='text-warning'>TESTANGEBOT</sup>",
    "POPUP_DELETE_PLAN_CONFIRMATION_TEXT":"Möchten Sie {{planName}} wirklich löschen?",
    "POPUP_DELETE_QUOTE_CONFIRMATION_TEXT":"Möchten Sie das Angebot wirklich löschen?",
    "POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT":"Möchten Sie die Angebotsposition <b>{{ProductName}}</b> wirklich löschen?",
    "POPUP_DELETE_SUB_HEADER_TEXT":"Sind Sie sicher, dass Sie löschen möchten?",
    "POPUP_DELETE_TAGGED_ENTITY_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie diese markierte Entität löschen möchten?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die Nachricht {{MessageBody | limitTo:30}} löschen wollen?",
    "POPUP_DELETE_WEB_NOTIFICATION_CONFIRMATION_TEXT2":"Sind Sie sicher, dass Sie diese Nachricht löschen möchten",
    "POPUP_DELETE_WEBHOOK_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie die Webhook-Benachrichtigung löschen möchten?",
    "POPUP_DELETE_WEBHOOK_NOTIFICATION_SUCCESSFUL_TEXT":"Webhook-Benachrichtigung erfolgreich gelöscht",
    "POPUP_DELETE_WEBHOOK_SUCCESSFUL_TEXT":"Webhook-Benachrichtigung erfolgreich gelöscht.",
    "POPUP_DISABLE_PLAN_PRODUCT_CONFIRMATION":"Möchten Sie {{productName}} wirklich deaktivieren?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> ist das übergeordnete Angebot für das Testprodukt.<br/> Wenn dieses Angebot deaktiviert ist, wird auch das Testangebot deaktiviert.<br/> Sind Sie sicher, dass Sie < deaktivieren möchten? b>{{productName}}<b/>?",
    "POPUP_DISABLE_TRAIL_OFFER_PARENT_PLAN_PURCHASED_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> ist ein übergeordnetes Angebot für das Testprodukt.<br/> Die Deaktivierung dieses Produkts wirkt sich auf die Umwandlung des Testprodukts in ein Abonnement aus.<br/> Sind Sie sicher, dass Sie <b>{ deaktivieren möchten? {productName}}<b/>?",
    "POPUP_ENABLE_PLAN_PRODUCT_CONFIRMATION":"Möchten Sie {{productName}} wirklich aktivieren?",
    "POPUP_ENABLE_TRAIL_OFFER_PARENT_PLAN_PRODUCT_CONFIRMATION":"<b>{{productName}}</b> übergeordnetes Angebot ist nicht aktiv.<br/> Durch die Aktivierung des Testangebots wird auch übergeordnetes Angebot aktiviert.<br/> Sind Sie sicher, dass Sie <b>{{productName}} aktivieren möchten? <b/>?",
    "POPUP_ENGAGE_NOTIFICATION_DELETED2":"Engage-Benachrichtigung wird gelöscht.",
    "POPUP_PLAN_DELETED":"Plan {{planName}} wird gelöscht.",
    "POPUP_REVERT_HEADER_TEXT_DELETE":"Zurückkehren",
    "POPUP_REVERT_SUB_HEADER_TEXT":"Sind Sie sicher, dass Sie zurückkehren möchten?",
    "POPUP_TEXT_DEACTIVATE_PSA_LOG":"Möchten Sie die Protokolle wirklich deaktivieren? Wir löschen keine Protokolle im PSA, dieser Vorgang ermöglicht es Ihnen, die Daten mehr als einmal von C3 auf PSA hochzuladen. Bitte beachten Sie, dass durch das erneute Hochladen möglicherweise Duplikate in PSA entstehen können.",
    "POPUP_TEXT_DEACTIVATED_PSA_LOG_SUCCESSFULY":"PSA-Protokolle erfolgreich deaktiviert",
    "POPUP_UNSAVED_BUNDLE_OFFER_CHANGES_CONFIRMATION_TEXT":"Ihre ausgewählten Angebote mit dem aktuellen Abrechnungszyklus gehen verloren. Möchten Sie trotzdem fortfahren?",
    "POPUP_UNSAVED_CHANGES_CONFIRMATION_TEXT":"Sie haben nicht gespeicherte Änderungen. Möchten Sie trotzdem fortfahren?",
    "POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT":"Möchten Sie den Kundennamen wirklich aktualisieren?",
    "POPUP_UPDATE_RESELLER_NAME_CONFIRMATION_TEXT":"Sind Sie sicher, dass Sie den Resellernamen aktualisieren möchten?",
    "POPUP_WEB_NOTIFICATION_DELETED":"Web-Benachrichtigung {{MessageBody}} ist gelöscht.",
    "POPUP_WEB_NOTIFICATION_DELETED2":"Die Web-Benachrichtigung wurde gelöscht.",
    "PORTAL_ACCESS_GRANTED_TO_USER":"Der Portalzugriff wird auf {{UserName}} gewährt",
    "PORTAL_PAGE_DROPDOWN_SELECT_PORTAL_PAGE_TEXT":"Portalseite auswählen",
    "PORTAL_PAGE_NAME":"Name der Portalseite",
    "PORTLET_TITLE_PARTNER_PRODUCT_USAGE_DATA":"Verwendungsdaten",
    "PRC_CNG_EFF_TYP_DESC_CURRENT_CYCLE":"Die Preisänderung wird ab dem Beginn des aktuellen Gebührenzyklus des Abonnements angewendet",
    "PRC_CNG_EFF_TYP_DESC_IMMEDIATE_EFFECT":"Sofort",
    "PRC_CNG_EFF_TYP_DESC_NEXTCYCLE":"Ab der nächsten Abrechnung",
    "PRC_CNG_EFF_TYP_DESC_NEXTRENEW":"Ab der nächsten Erneuerung",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_BUTTON_TEXT_CLOSE":"Schließen",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_CLOSE":"Schließen",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_HEADER_TEXT_PREVIEW_EMAIL_NOTIFCATION":"Vorschau der E-Mail-Benachrichtigung",
    "PREVIEW_EMAIL_NOTIFICATION_POPUP_PREVIEW_EMAIL_NOTIFICATION":"Vorschau der E-Mail-Benachrichtigung",
    "Price":"Preis",
    "PRICE_CHANGE_CURRENCY":"Währung",
    "PRICE_CHANGE_MODAL_VALIDATION_WARNING_MESSAGE":"Der eingegebene Wert entspricht nicht der gewählten Preissperrkonfiguration.",
    "PRICE_CHANGE_NEW_PRICE":"Neuer Preis",
    "PRICE_CHANGE_OLD_PRICE":"Alter Preis",
    "PRICING_API_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Anweisungen zur Microsoft-Preis-API",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_1":"Vielen Dank für Ihre Zustimmung. Die Anwendung kann so konfiguriert werden, dass sie auf die Microsoft-Preise zugreift.",
    "PRICING_API_PC_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen unter Einstellungen -> Partnercenter in <b>{{applicationName}} </b>.",
    "PRICING_API_VIEW_INSTRUCTIONS_1":", um den Link zu kopieren, der die Zustimmung des Partners einholt, dieser Anwendung gemäß der Microsoft-Preis-API Zugriff auf das Partner Center zu gewähren.",
    "PRICING_API_VIEW_INSTRUCTIONS_2":"Öffnen Sie den Link in einer Inkognito-Sitzung und schließen Sie die Einwilligungserfahrung mit einem Konto ab, das ein globaler Administrator in Ihrem (Partner-) Mandanten ist und dem die Partner-Agent-Rolle im Partnercenter zugewiesen ist.",
    "PRICING_API_VIEW_INSTRUCTIONS_3":"Klicken Sie auf die Schaltfläche Test, um zu überprüfen, ob die Anwendung mit der Microsoft-Preis-API funktioniert.",
    "PRICING_API_VIEW_INSTRUCTIONS_5":"Klicken Sie auf <b class='text-primary'><a ngclipboard data-clipboard-text='{{consentURL}}' href='#' ng-click='confirmCopy()'></b> Link in die Zwischenablage kopieren </a>",
    "PRICING_SLABS_TABLE_HEADER_ACTIONS":"Aktionen",
    "PRICING_SLABS_TABLE_HEADER_BILLING_TYPE":"Abrechnungsart",
    "PRICING_SLABS_TABLE_HEADER_COST_PRICE":"Selbstkostenpreis",
    "PRICING_SLABS_TABLE_HEADER_DISPLAY_NAME":"Anzeigename",
    "PRICING_SLABS_TABLE_HEADER_MAXVALUE":"MaxValue",
    "PRICING_SLABS_TABLE_HEADER_MINVALUE":"MinValue",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE":"Verkaufspreis",
    "PRICING_SLABS_TABLE_HEADER_SELLING_PRICE_MODAL":"Verkaufspreis",
    "PRICING_SLABS_TABLE_HEADER_TYPE":"Abrechnungsart",
    "PRIVACY_POLICY_BUTTON_TEXT":"DATENSCHUTZ-BESTIMMUNGEN",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_COMPLETED":"Prozess des Bulk Onboarding Kunden abgeschlossen",
    "PROCESS_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITIATED":"Prozess des Bulk Onboarding Kunden gestartet",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_COMPLETED":"Vorgang zum Hochladen von Rechnungen in Quickbooks abgeschlossen",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_QUICKBOOKS_INITIATED":"Prozess zum Hochladen von Rechnungen in Quickbooks eingeleitet",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_COMPLETED":"Vorgang zum Hochladen von Rechnungen zu Xero abgeschlossen",
    "PROCESS_FOR_UPLOAD_OF_INVOICES_TO_XERO_INITIATED":"Prozess zum Hochladen von Rechnungen zu Xero eingeleitet",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_FAILURE":"Das Hochladen von Rechnungen in Quickbooks ist fehlgeschlagen",
    "PROCESS_OF_UPLOADING_INVOICES_TO_QUICKBOOKS_SUCCESS":"Prozess des Hochladens von Rechnungen in Quickbooks erfolgreich",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_FAILURE":"Das Hochladen von Rechnungen zu Xero ist fehlgeschlagen",
    "PROCESS_OF_UPLOADING_INVOICES_TO_XERO_SUCCESS":"Prozess des Hochladens von Rechnungen zu Xero Erfolg",
    "PRODUCT_BUNDLE_ALERT_SELECTED_VALUE_LOSS":"Ihre ausgewählten Elemente wurden entfernt.",
    "PRODUCT_BUNDLE_NOTE_SELECTED_VALUE_LOSS":"Das Ändern des Werts dieses Feldes führt zum Verlust ausgewählter Elemente",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_BILLING_TYPE":"Abrechnungsart",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_NUMBER_OF_DAYS_ALLOWED_FOR_FREE_CANCELATION":"Anzahl der maximal zulässigen Tage ohne Stornierung.",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_SALE_TYPE":"Verkaufsart",
    "PRODUCT_BUNDLE_TOOLTIP_TEXT_VALIDITY":"Gültigkeit",
    "PRODUCT_CANCELLATION_TEXT":"Stornierung möglich",
    "PRODUCT_CART_ALREADY_EXISTS_IN_ORDERED_STATE":"Wir nehmen derzeit einige Änderungen an diesem Produkt vor. Bitte warten Sie, bis diese Vorgänge abgeschlossen sind, bevor Sie eine neue Anfrage stellen.",
    "PRODUCT_CATALOGUE_ACTION_DESCRIPTION_TOOLTIP_TEXT":"Wählen Sie eine Möglichkeit aus, um den Produktkatalogbericht zu erhalten",
    "PRODUCT_CATALOGUE_EMAIL_SENT_SUCCESSFULLY":"Email wurde erfolgreich Versendet",
    "PRODUCT_CATALOGUE_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet:",
    "PRODUCT_CATEGORY_TAG_TEXT_NCE":"NCE",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Agentenname",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_END_DATE":"Endtermin",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_PERCENTAGE":"Provision(%)",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_COMMISSION_START_DATE":"Anfangsdatum",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_INTERNAL_PRODUCT_ID":"Interne Produkt-ID",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PRODUCT_NAME":"Produktname",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_PROVIDER_SUBSCRIPTION_ID":"Abonnement-ID des Anbieters",
    "PRODUCT_COMMISSION_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"SP-Code",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_AGENT_NAME":"Agentenname",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_COMMISSION_AMOUNT":"Provisionsbetrag",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CURRENCY_CODE":"Währungscode",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "PRODUCT_COMMISSION_EARNING_DETAILS_TABLE_HEADER_TEXT_SP_CODE":"SP-Code",
    "PRODUCT_COMMISSION_EARNING_REPORT_HELPTEXT":"Die Provision für die Produkte eines Kunden wird berechnet, sobald die Rechnungen abgeschlossen sind. Bei der Berechnung der Provisionen für einen Kunden werden nur die vom Benutzer im jeweiligen Abrechnungszeitraum angegebenen Provisionsdetails berücksichtigt. Wenn in einem Abrechnungszeitraum keine Provisionsdetails angegeben sind, werden die Provisionsdetails berücksichtigt, die im vorherigen Abrechnungszeitraum definiert wurden und bis zum Abrechnungszeitraum der Rechnung Gültigkeit haben.",
    "PRODUCT_COMMISSIONS_BUTTON_TEXT_FILTER_BY":"Filtern nach :",
    "PRODUCT_COMMISSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
    "PRODUCT_COMMISSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
    "PRODUCT_COMMISSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog validieren",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_AGENT_NAME":"Agentenname",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_END_DATE":"Enddatum der Provision",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_PERCENTAGE":"Provision(%)",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_COMMISSION_START_DATE":"Startdatum der Provision",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_DOMAIN_NAME":"Domain Name",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_INTERNAL_SUBSCRIPTION_ID":"Interne Produkt-ID",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_PROVIDER_SUBSCRIPTION_ID":"Abonnement-ID des Anbieters",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_SP_CODE":"SP-Code",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
    "PRODUCT_COMMISSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
    "PRODUCT_COMMISSIONS_UPLOAD_FORM_INSTRUCTIONS":"Anleitung",
    "PRODUCT_COULD_NOT_FOUND_TEXT":"Der Produkttyp konnte nicht gefunden werden.",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_APPROVE_BUTTON_TEXT":"Genehmigen",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_BILLING_CYCLE":"Rechnungskreislauf",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CURRENT_QUANTITY":"Aktuelle Menge",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_CUSTOMER_REMARK":"Kunde Bemerkung",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REJECT_BUTTON_TEXT":"Ablehnen",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_REQUESTED_QUANTITY":"Neue Menge",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SEAT_LIMIT":"Sitzplatz-Limit",
    "PRODUCT_DETAIL_IN_PURCHASE_REQUEST_SOURCE_QUANTITY":"Quellmenge",
    "PRODUCT_DETAILS_READ_LESS_LINK_TEXT":"Lese weniger",
    "PRODUCT_DETAILS_READ_MORE_LINK_TEXT":"Weiterlesen",
    "PRODUCT_EXTENSIONS_BUTTON_TEXT_FILTER_BY":"Filtern nach  :",
    "PRODUCT_EXTENSIONS_CAPTION_TEXT":"Produkteigenschaften",
    "PRODUCT_EXTENSIONS_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
    "PRODUCT_EXTENSIONS_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
    "PRODUCT_EXTENSIONS_LOAD_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog validieren",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_BILLING_CYCLE":"Rechnungskreislauf",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_CATEGORY":"Kategorie",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_DISPLAY_SEQUENCE":"Reihenfolge anzeigen",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_ID":"Angebots-ID",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_OFFER_NAME":"Angebotsname",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PRODUCT_CODE":"Produkt-Code",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_PROVIDER_NAME":"Anbietername",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
    "PRODUCT_EXTENSIONS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
    "PRODUCT_LIST_ADDON_LABEL":"Zusatzangebot",
    "PRODUCT_LIST_TEXT_SUBSCRIPTION_ID":"Abonnement-ID",
    "PRODUCT_MANAGE_RENEWAL_ACTION_DANGER_TOOLTIP_TEXT":"Wir haben diese Anfrage storniert, da einige Änderungen aktualisiert wurden.",
    "PRODUCT_MANAGE_RENEWAL_ACTION_WAITING_APPROVAL_TOOLTIP_TEXT":"Warten auf die Genehmigung.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_ACTIVE_PRODUCT":"Wählen Sie mindestens ein aktives Produkt aus, bevor Sie fortfahren.",
    "PRODUCT_MANAGE_SELECT_ATLEAST_ONE_USER_TO_REMOVE":"Wählen Sie mindestens einen Benutzer aus der Liste aus.",
    "PRODUCT_MAPPING":"Produktzuordnung",
    "PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für ausgewählte Produkte wirklich speichern?",
    "PRODUCT_NOT_AVAILABLE_FOR_PURCHASE":"Dieses Produkt kann nicht gekauft werden.",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED":"Produkt nicht zugeordnet, da der Mieter mit mehreren Kunden verbunden ist",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_MULTIPLE_CUSTOMERS_LINKED_OR_PRODUCT_IGNORED":"Produkt nicht zugeordnet, da der Mandant mit mehreren Kunden verknüpft ist oder das Produkt bereits ignoriert wurde",
    "PRODUCT_ONBOARD_SKIPPED_DUE_TO_SUBSCRIPTION_ALREADY_MAPPED":"Produkt ist bereits zugeordnet",
    "PRODUCT_OWNERSHIP_LABEL_PERCENTAGE":"Prozentsatz",
    "PRODUCT_OWNERSHIP_LABEL_QUANTITY":"Menge",
    "PRODUCT_OWNERSHIP_LABEL_SCOPE_NAME":"Bereichsname",
    "PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Sie können die Menge nicht auf einen Wert über der verfügbaren Menge aktualisieren. Wenn Sie dies tun möchten, erhöhen Sie bitte die Menge in der grundlegenden Detailansicht.",
    "PRODUCT_OWNERSHIP_SUBMIT_TOOLTIP":"Sie können die Menge durch Inline-Bearbeitung aktualisieren und zum Speichern auf die Schaltfläche \\''Senden\\'' klicken",
    "PRODUCT_OWNERSHIP_SUCCESS":"Eigentümer erfolgreich aktualisiert",
    "PRODUCT_TAGS_BUTTON_TEXT_ADD_NEW_PRODUCT_TAGS":"Hinzufügen",
    "PRODUCT_TAGS_BUTTON_TEXT_BACK_TO_PRODUCTS":"Zurück zu den Produkten",
    "PRODUCT_TAGS_BUTTON_TEXT_SAVE_CHANGES":"Änderungen speichern",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "PRODUCT_TAGS_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
    "PRODUCT_TAGS_ERROR_MESSAGE_ENTER_UNIQE_TAG_VALUE":"Geben Sie einen eindeutigen Tag-Wert ein",
    "PRODUCT_TAGS_NOTIFICATION_TAGS_SAVED_SUCCESSFULLY":"Produkt-Tags erfolgreich gespeichert",
    "PRODUCT_TAGS_PLACEHOLDER_PRODUCT_TAGS":"Produkt Tags",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_DESCRIPTION":"Beschreibung",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_KEY":"Tag-Schlüssel hinzufügen",
    "PRODUCT_TAGS_PLACEHOLDER_TAG_VALUE":"Tag-Wert hinzufügen",
    "PRODUCT_TAGS_TABLE_HEADER_ACTIONS":"Aktionen",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_DESCRIPTION":"Beschreibung",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_KEY":"Tag-Schlüssel",
    "PRODUCT_TAGS_TABLE_HEADER_TAG_VALUE":"Tag-Wert",
    "PRODUCT_TAGS_TITLE_PRODUCT_TAGS":"Produkt Tags",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_BILLING_CYCLE":"Rechnungskreislauf",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CATEGORY":"Kategorie",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_CURRENCY_CODE":"Währungscode",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_SUPPORTED_MARKET_CODE":"Unterstützter Marktcode",
    "PRODUCT_VARIENTS_MAPPING_TOOLTIP_TEXT_TERM_DURATION":"Laufzeit",
    "PRODUCT_VIEW_GRID":"Netz",
    "PRODUCT_VIEW_LIST":"Aufführen",
    "productcatalogue":"Produktkatalog",
    "PRODUCTS_PLACEHOLDER_TEXT_RENEWS_EXPIRES_IN_DAYS":"Erneuert sich/läuft ab (in Tagen)",
    "PRODUCTS_REPORT_MAX_ORDERED_DATE":"Maximales Bestelldatum",
    "PRODUCTS_REPORT_MAX_QUANTITY":"Maximale Menge",
    "PRODUCTS_REPORT_MIN_QUANTITY":"Mindestmenge",
    "PROFILE_BREADCRUMB_BUTTON_DASHBOARD":"Dashboard",
    "PROFILE_BREADCRUMB_BUTTON_PROFILE":"Kundenprofil",
    "PROFILE_CAPTION_TEXT_CUSTOMER_POFILE":"Profil",
    "PROFILE_EDITCREDITCARD_ACH_CAPTION_TEXT_BANK_DETAILS":"Bankdaten",
    "PROFILE_EDITCREDITCARD_BUTTON_1_TEXT_CREADIT_CARD":"Kreditkarte",
    "PROFILE_EDITCREDITCARD_BUTTON_2_TEXT_ACH":"ACH",
    "PROFILE_EDITCREDITCARD_BUTTON_3_TEXT_BANK_ACCOUNT":"Bankkonto",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT":"Zahlungsart",
    "PROFILE_EDITCREDITCARD_CAPTION_TEXT_ALERT":"Bitte wählen Sie Zahlungsart",
    "PROFILE_EDITCREDITCARD_CREADITCARD_CAPTION_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
    "PROFILE_EDITCREDITCARD_PAYMENT_TYPE_SUCCESS":"Zahlungsprofil erfolgreich aktualisiert",
    "PROFILE_EDITUSERDETAILS_CAPTION_TEXT":"Nutzerdetails",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_CITY":"Stadt",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_COUNTRY":"Land",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_EMAIL":"Email",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_FIRST_NAME":"Vorname",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LASTNAME":"Nachname",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_1":"Linie 1",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_LINE_2":"Zeile 2",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_STATE":"Zustand",
    "PROFILE_EDITUSERDETAILS_FORM_LABELS_ZIP":"Postleitzahl",
    "PROFILE_EMAIL_ADDRESS_INPUT_PLACEHOLDER_TEXT":"Email",
    "PROFILE_HEADER_CUSTOMER_CONFIGURATION":"Kundenkonfiguration",
    "PROFILE_HEADER_RESELLER_CONFIGURATION":"Reseller-Konfiguration",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_DELETE":"Löschen",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "PROFILE_ORGANISATION_SETUP_DEPARTMENT_TOOLTIP_TEXT_SAVE":"speichern",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "PROFILE_ORGANISATION_SETUP_SITES_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
    "PROFILE_PAYMENTS_DELETE_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Zahlungsprofil löschen",
    "PROFILE_PAYMENTS_SET_DEFAULT_PAYMENT_PROFILE_BUTTON_TOOLTIP_TEXT":"Als Standardzahlungsprofil festlegen",
    "PROFILE_PHONE_NUMBER_INPUT_PLACEHOLDER_TEXT":"Telefonnummer",
    "PROFILE_TAB_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
    "PROFILE_TAB_CONFIGURATION_SETTING":"Konfiguration/Einstellungen",
    "PROFILE_TAB_ORGANIZATION_SETUP":"Einrichtung der Organisation",
    "PROFILE_TAB_PAYMENT_PROFILE":"Zahlungsprofil",
    "PROFILE_TAB_USER_DETAILS":"Nutzerdetails",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_MAKE_DEFAULT":"Machen Standard",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE":"einreichen",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_1":"speichern",
    "PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE_2":"speichern",
    "PROFILE_VIEW_BILLING_FORM_LABEL_CITY":"Stadt",
    "PROFILE_VIEW_BILLING_FORM_LABEL_COUNTRY":"Land",
    "PROFILE_VIEW_BILLING_FORM_LABEL_EMAIL":"Email",
    "PROFILE_VIEW_BILLING_FORM_LABEL_FIRST_NAME":"Vorname",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LAST_NAME":"Nachname",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_1":"Linie 1",
    "PROFILE_VIEW_BILLING_FORM_LABEL_LINE_2":"Zeile 2",
    "PROFILE_VIEW_BILLING_FORM_LABEL_PHONE":"Telefonnummer",
    "PROFILE_VIEW_BILLING_FORM_LABEL_STATE":"Zustand",
    "PROFILE_VIEW_BILLING_FORM_LABEL_ZIP":"Postleitzahl",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_ADDRESS":"Rechnungsadresse",
    "PROFILE_VIEW_BILLING_HEADER_TEXT_BILLING_DETAILS":"Rechnungsdetails",
    "PROFILE_VIEW_PAYMENT_ADD_BUTTON_TEXT_ADD_PAYMENT_PROFILE":"Zahlungsmethode hinzufügen",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_1":"Hinweis:",
    "PROFILE_VIEW_PAYMENT_ALERT_TEXT_PART_2":"Das neue Zahlungsprofil wird für alle nachfolgenden Transaktionen verwendet",
    "PROFILE_VIEW_PAYMENT_BUTTON_TEXT_CHANGE_PAYMENT_PROIFILE":"Bezahlungsmethode ändern",
    "PROFILE_VIEW_PAYMENT_CAPTION_PAYMENT_PROFILE_DETAILS":"Details zum Zahlungsprofil",
    "PROFILE_VIEW_PAYMENT_HEADER_ADDE_PROFILE":"Bitte fügen Sie eine Zahlungsmethode hinzu.",
    "PROFILE_VIEW_PAYMENT_HEADER_ALREDY_HAVE":"Sie haben bereits ein Zahlungsprofil hinterlegt.",
    "PROFILE_VIEW_PAYMENT_HEADER_LAST_FOUR_DIGIT":"Die letzten 4 Ziffern Ihrer Zahlungsmethode",
    "PROFILE_VIEW_PAYMENT_HEADER_PAYMENT_METHOD":"Bezahlverfahren",
    "PROFILE_VIEWUSERDETAILS_CAPTION_TEXT":"Nutzerdetails",
    "PROMOTION_ERRORS_ALERT_TEXT":"Werbefehler",
    "PROVIDER_ACCOUNT_NOT_AVAILABLE_FOR_PURCHASE":"Sie können diesen Artikel nicht kaufen, da Ihr Konto nicht mit diesem Anbieter verknüpft ist!",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_FAILED":"Fehler beim Generieren des Passworts für den Admin-Benutzer des Anbieterkunden",
    "PROVIDER_CUSTOMER_ADMIN_USER_PASSWORD_GENERATION_SUCCEEDED":"Passwort für Anbieter-Kunden-Admin-Benutzer erfolgreich generiert",
    "PROVIDER_CUSTOMER_BILLING_EMAIL":"Abrechnung per E-Mail",
    "PROVIDER_CUSTOMER_CONSENT_DETAILS_NOT_PROVIDED_ERROR_MESSAGE":"Käufe gegen CSP-Mandanten können aufgrund fehlender Zustimmung des Kunden fehlschlagen. <a ui-sref=\"home.profile.provider({providername: 'Microsoft'})\"><b class=\"red \"> Klicken Sie hier, um zum Mieterprofil umzuleiten und MCA hinzuzufügen </b></a>",
    "PROVIDER_CUSTOMER_DOMAIN":"Provider-Kundendomäne",
    "PROVIDER_CUSTOMER_ID":"Kunden-ID des Anbieters",
    "PROVIDER_CUSTOMER_NAME":"Name des Anbieterkunden",
    "PROVIDER_DESC_MICROSOFT":"Microsoft",
    "PROVIDER_DESC_MICROSOFT_NON_CSP":"MicrosoftNonCSP",
    "PROVIDER_DESC_PARTNER":"Partner",
    "PROVIDER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Anbietername",
    "PROVIDER_PAYMENT_DETAILS_COST_TO_PARTNER":"Kosten für den Partner",
    "PROVIDER_PAYMENT_DETAILS_PAID_AMOUNT":"Bezahlte Menge",
    "PROVIDER_PAYMENT_DETAILS_PAID_DATE":"Bezahltes Datum",
    "PROVIDER_PAYMENT_DETAILS_PROVIDER":"Anbieter",
    "PROVIDER_PRODUCT_ID":" Produkt-ID des Anbieters:",
    "PROVIDER_RESELLER_BILLING_EMAIL":"Abrechnung per E-Mail",
    "PROVIDER_RESELLER_COUNTRY_CODE":"Landesvorwahl",
    "PROVIDER_RESELLER_ID":"Reseller-ID des Anbieters",
    "PROVIDER_RESELLER_MPNID":"Anbieter Reseller MPN-ID",
    "PROVIDER_RESELLER_NAME":"Name des Wiederverkäufers des Anbieters",
    "PROVIDER_RESELLER_ONBOARDING_ERROR_DETAILS":"Fehlerdetails",
    "PROVIDER_RESELLER_ONBOARDING_STATUS":"Onboarding-Status",
    "PROVIDER_SEARCH_DEFAULT_OPTION":"Wählen",
    "PROVIDER_SETTINGS_TAB_HEADING_MICROSOFT_TEXT":"Microsoft",
    "PROVIDER_TENENT_CUSTOMER_TYPE_EXISTING_CUSTOMER":"Bestehender Kunde",
    "PROVIDER_TENENT_CUSTOMER_TYPE_NEW_CUSTOMER":"Neukunde",
    "providers":"Anbieter",
    "provisioning":"Bereitstellung",
    "PROVISIONING_LABLE_TEXT":"Bereitstellung...",
    "PSA_AGREEMENT_LABEL_PILL_TEXT_MASTER":"Meister",
    "PSA_AGREEMENT_LABEL_TOOLTIP_TEXT_MASTER_AGREEMENT":"Rahmenvertrag",
    "PSA_BULK_UPLOAD_ERROR_MESSAGE_SELECT_FILE":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "PSA_BULK_UPLOAD_FAILED_STATUS":"Die Aktualisierung der Massenentitätszuordnung in C3 ist fehlgeschlagen.",
    "PSA_BULK_UPLOAD_PARTIAL_SUCCESS_STATUS":"Die Produktzuordnung für Großkäufe wurde teilweise erfolgreich in C3 hochgeladen. Bitte überprüfen Sie das heruntergeladene ungültige Entitätszuordnungsblatt auf Überarbeitung.",
    "PSA_BULK_UPLOAD_SUCCESS_STATUS":"Die Produktzuordnung für den Massenkauf wurde erfolgreich in C3 hochgeladen.",
    "PSA_BUTTON_TEXT_TEST_CONNECTIVITY":"Konnektivität testen",
    "PSA_CUSTOMER_CONTRACT_TYPE_SEARCH_PLACEHOLDER_TEXT":"Suche Vertrag",
    "PSA_CUSTOMER_CONTRACT_TYPE_SELECT_PLACEHOLDER_TEXT":"Vertrag auswählen",
    "PSA_CUSTOMER_PRODUCT_SEARCH_PLACEHOLDER_TEXT":"PSA-Dienst durchsuchen",
    "PSA_CUSTOMER_PRODUCT_SELECT_PLACEHOLDER_TEXT":"PSA-Produkt auswählen",
    "PSA_ERROR_AGREEMENTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Für diesen Abrechnungszeitraum ist keine Vereinbarung zugeordnet",
    "PSA_ERROR_CANNOT_ALIGH_WITH_CONTRACT_AS_NOT_ABLE_TO_GET_CONTRACT_START_DATE_FROM_PSA":"Wir konnten kein Vertragsbeginndatum von PSA erhalten und konnten uns daher nicht anpassen",
    "PSA_ERROR_CONTRACTS_ARE_NOT_MAPPED_FOR_THIS_BILLING_CYCLE":"Für diesen Abrechnungszeitraum sind keine Verträge zugeordnet",
    "PSA_ERROR_DOESNOT_FALL_IN_THE_SELECTED_DATE_PERIOD_IN_FILTER":"Diese Protokolle fallen nicht in den ausgewählten Zeitraum",
    "PSA_ERROR_REQUIRED_VALUES_TO_CREATE_CONTRACT_IN_AUTOTASK_ARE_MISSING":"Startdatum, Enddatum, Zeitberichte erfordern Start- und Endzeiten oder der Wert der Einrichtungsgebühr fehlt. Dies sind erforderliche Werte, um einen Vertrag zu erstellen. Bitte aktualisieren Sie diese Werte in den Kundenkonfigurationen.",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_CREATEDATE_CANNOT_BE_BEFORE_ALIGNED_DATE":"Das Datum des Abonnementverlaufs liegt vor dem neuen Datum, das nach dem Ausführen des Vorgangs zum Abgleichen mit dem Startdatum des Vertrags berechnet wird",
    "PSA_ERROR_SUBSCRIPTION_HISTORY_NO_ACTIVE_SUBSCRIPTIONS_AVAILABLE_TO_ALIGN":"Beim Alignment-Versuch konnten keine aktiven Abonnements gefunden werden, um den Alignment-Vorgang auszuführen.",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_CATEGORY":"Kategorie wählen",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_PRODUCT_CLASS":"Produktklasse auswählen",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_SLA":"SLA auswählen",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_SUB_CATEGORY":"Unterkategorie auswählen",
    "PSA_PRODUCT_CREATION_MAPPING_SELECT_UOM":"Maßeinheit auswählen",
    "PSA_PRODUCT_LABEL_PILL_TEXT_INACTIVE":"Inaktiv",
    "PSA_PRODUCT_MAPPING_SEARCH_EXTERNAL_CUSTOMER":"Suche PSA-Kunden",
    "PSA_PRODUCT_MAPPING_SELECT_AGREEMENT":"Vereinbarung auswählen",
    "PSA_PRODUCT_MAPPING_SELECT_CATEGORY":"Kategorie wählen",
    "PSA_PRODUCT_MAPPING_SELECT_CONTRACT":"Wählen Sie {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'Vertragsvereinbarung' }}",
    "PSA_PRODUCT_MAPPING_SELECT_CUSTOMER":"Wählen Sie Kunde",
    "PSA_PRODUCT_MAPPING_SELECT_EXTERNAL_CUSTOMER":"Wählen Sie PSA-Kunde aus",
    "PSA_PRODUCT_MAPPING_SELECT_PRODUCT":"PSA-Produkt auswählen",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE":"Wählen Sie {{vm.activeServiceDetail === cloudHubConstants.PSA_NAME_AUTOTASK ? 'PSA-Dienst' : 'PSA-Produkt' }}",
    "PSA_PRODUCT_MAPPING_SELECT_SERVICE_PROVIDER_CUSTOMER":"Mieter auswählen",
    "PSA_REFRESH_LOCAL_ENTITY_MAAPING_INPROGRESS_MESSAGE":"Die Datenaktualisierung wird gerade durchgeführt. Weitere Optionen stehen zur Verfügung, sobald der letzte Datenprozess abgeschlossen ist.",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_HEADER_WARNING":"Warnung",
    "PSA_UPLOAD_FILTER_DATE_INSTRUCTIONS_PART_1":"Daten für frühere Daten sind nach Verwendung des gefilterten Datums für ausgewählte Kunden nicht verfügbar.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE":"Notiz",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART1":"Bitte beachten Sie, dass für das Hochladen neu generierter Rechnungen Unterstützung vom C3-Supportteam erforderlich ist.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART2":"Wenn Sie hier das Startdatum und das Enddatum festlegen, wird dies in den in connectwise erstellten Ergänzungen als Startdatum und Stornierungsdatum für alle anwendbaren Rechnungsposten berücksichtigt.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART3":"Wenn kein Startdatum und kein Enddatum festgelegt sind, werden die Daten des Rechnungsabrechnungszeitraums berücksichtigt.",
    "PSA_UPLOAD_FILTER_INSTRUCTIONS_HEADER_NOTE_PART4":"Die nachstehende Kundenliste enthält nur die Kunden, deren Rechnung(en) für den ausgewählten Zeitraum den Status „Abgeschlossen“ haben.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_1":"Wenn das Kontrollkästchen <span class=\"font-italic\">(Am Vertragsbeginn ausrichten)</span> aktiviert ist, berechnen wir die nächsthöhere Vertragslaufzeit zum Hochladen. Bitte beachten Sie, dass die nächste aktuelle Vertragslaufzeit nur auf monatlicher Basis berechnet wird, auch wenn die Vertragslaufzeit <span class=\"font-italic\">jährlich</span> ist.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_2":"Das Startdatum verhindert, dass frühere Protokolle für den Upload berücksichtigt werden.",
    "PSA_UPLOAD_INSTRUCTIONS_AUTOTASK_WARNING_PART_3":"Bitte verwenden Sie die Filter nur, wenn Sie zum ersten Mal versuchen, Daten für einen Kunden zu übertragen. Zum Beispiel: <ul><li>Partnerregistrierung, d. h. zuerst Push von C3 zu Autotask</li><li>Onboarding neuer Kunden</li></ul>",
    "PSA_UPLOAD_INSTRUCTIONS_PART_1":"Beginnen Sie den Upload-Prozess, indem Sie Kunden auswählen und auf die Schaltfläche <span class=\"bold\">Validieren</span> klicken, um den Validierungsprozess zu starten",
    "PSA_UPLOAD_INSTRUCTIONS_PART_10":"Bitte synchronisieren Sie vor dem Hochladen mit Partner Center",
    "PSA_UPLOAD_INSTRUCTIONS_PART_11":"Bitte beachten Sie, dass alle Daten (einschließlich Filter) in UTC berücksichtigt werden.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_2":"Verfolgen Sie den Validierungsfortschritt, indem Sie auf die Schaltfläche <span class=\"bold\">Protokolle anzeigen</span> klicken.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_3":"Nehmen Sie im Falle von Validierungsfehlern die erforderlichen Korrekturen vor und versuchen Sie es erneut.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_AUTOTASK":"Wenn die Validierung erfolgreich ist, können Sie mit dem <span class=\"bold\">Hochladen</span> der Daten in Autotask fortfahren.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_4_CONNECTWISE":"Wenn die Validierung erfolgreich ist, können Sie mit dem <span class=\"bold\">Hochladen</span> der Daten zu ConnectWise fortfahren.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_AUTOTASK":"Eine Wiederholung des Uploads ist nur im Fehlerfall möglich.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_5_CONNECTWISE":"Eine Wiederholung des Uploads ist nur im Fehlerfall möglich.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_6":"Bitte klicken Sie auf diese Schaltfläche, um die Konnektivität mit PSA zu testen, bevor Sie mit der Validierung fortfahren.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_7":"Nur neue Abonnementverlaufsprotokolle ausgewählter Kunden, falls vorhanden, werden für den Upload berücksichtigt. Ausgewählte Kunden können ausgeschlossen werden, wenn keine neuen Protokolle vorhanden sind.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_8":"Durch Klicken auf das Kontrollkästchen neben dem Titel können Sie Abonnementprotokolle für alle aktiven Kunden im System validieren.",
    "PSA_UPLOAD_INSTRUCTIONS_PART_9":"Bitte wählen Sie Kunden aus der folgenden Liste aus, die Sie hochladen möchten",
    "PSA_UPLOAD_INSTRUCTIONS_TOOLTIP_SELECT_ALL_CUSTOMERS":"Aktivieren Sie dieses Kontrollkästchen nur, wenn Sie Abonnementprotokolle für alle aktiven Kunden im System validieren möchten.",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_AGREEMENT_MAPPING":"PSA-Vereinbarung ist nicht mit C3-Kunden verknüpft",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_CUSTOMER_MAPPING":"Externer Kunde ist nicht mit c3-Kunde verknüpft",
    "PSA_VALIDATION_ERROR_MESSAGE_MISSING_PRODUCT_MAPPING":"Externes Produkt ist nicht mit C3-Produkt verknüpft",
    "PUBLIC_SIGNUP_ADD_TO_CART":"in den Warenkorb legen",
    "PUBLIC_SIGNUP_ADMIN_PORTAL":"Admin-Portal",
    "PUBLIC_SIGNUP_APPLY_PROMOTIONS_NCE":"Werbeaktionen anwenden",
    "PUBLIC_SIGNUP_ASSISTANCE_REQUEST":"Bitte um Unterstützung bei der öffentlichen Registrierung",
    "PUBLIC_SIGNUP_AUTHORIZENET_PAYMENT_GATEWAY_NOT_CONFIGURED":"Autorisierungs-Zahlungsgateway wird noch nicht unterstützt. Bitte kontaktieren Sie den Support für Hilfe.",
    "PUBLIC_SIGNUP_BACK_TO_PRODUCT_LIST":"Zurück zur Produktliste",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING":"Suche nach Produkt",
    "PUBLIC_SIGNUP_BANNER_MAIN_HEADING_DEFAULT":"Suche nach Produkten",
    "PUBLIC_SIGNUP_BANNER_MINOR_HEADING":"Erstklassige Funktionen, die Ihnen zum Erfolg verhelfen.",
    "PUBLIC_SIGNUP_BANNER_PARAGRAPH_HEADING":"Überschrift des öffentlichen Anmeldebanners",
    "PUBLIC_SIGNUP_BASIC_DETAILS_PORTLET_HEADER":"Informationen zur Rechnungsstellung",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_ONE_TIME":"Einmalig",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_3_YEAR":"pro 3 Jahr",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_MONTH":"pro Monat",
    "PUBLIC_SIGNUP_BILLING_CYCLE_DESC_PER_YEAR":"pro Jahr",
    "PUBLIC_SIGNUP_BUTTON_VIEW_CART":"Warenkorb anzeigen",
    "PUBLIC_SIGNUP_CARD_CONTAINS_ADDONS":"Enthält Addons",
    "PUBLIC_SIGNUP_CARD_CONTAINS_LINKED":"Enthält verknüpfte Produkte",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_SELECT_ATLEAST_ONE_MS_OFFER":"Bitte wählen Sie mindestens ein Microsoft-Angebot zum Auschecken",
    "PUBLIC_SIGNUP_CART_CREATION_PLEASE_VALIDATE_THE_COUPON_ENTERED":"Bitte wenden Sie den Gutschein an, bevor Sie die Bestellung aufgeben",
    "PUBLIC_SIGNUP_CART_DEFAULT_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"Geschäftsbedingungen.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITION_IS_NOT_ENABLED_TEXT":"Bitte aktivieren Sie die NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen.",
    "PUBLIC_SIGNUP_CART_NCE_TERMS_AND_CONDITIONS_ACCEPT_TEXT":"<a target='_blank' href=\"https://www.microsoft.com/en-us/licensing/news/new-azure-experience-in-csp/\">NCE-Geschäftsbedingungen.</a>",
    "PUBLIC_SIGNUP_CART_PAGE_TEXT_INFO_NO_PRODUCTS2":"Stöbern Sie in unserem Sortiment und finden Sie die Produkte, die Sie brauchen. <a (click)=\"gotoCart()\" class=\"text-primary\" role=\"button\"><strong>Kehren Sie zu unserem Webshop zurück</strong></a> und legen Sie Artikel in Ihren Warenkorb.",
    "PUBLIC_SIGNUP_CLOUDLABS_WEBSITE":"Webseite",
    "PUBLIC_SIGNUP_CONFIRM_PORTLET_HEADER":"Bestätigen",
    "PUBLIC_SIGNUP_CONTACT_SUPPORT":"Kontakt zum Support",
    "PUBLIC_SIGNUP_CONTACTUS_URL":"Kontaktiere uns",
    "PUBLIC_SIGNUP_CONTINUE_SHOPPING":"Mit dem Einkaufen fortfahren",
    "PUBLIC_SIGNUP_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Ich akzeptiere das Neueste",
    "PUBLIC_SIGNUP_DO_NOT_CLOSE_BROWSER":"Schließen Sie diesen Browser nicht, bevor der Vorgang abgeschlossen ist",
    "PUBLIC_SIGNUP_EDIT_TEXT":"Bestellung bearbeiten",
    "PUBLIC_SIGNUP_EZIDEBIT_PAYMENT_GATEWAY_NOT_CONFIGURED":"Ezidebit-Zahlungsgateway wird noch nicht unterstützt. Bitte kontaktieren Sie den Support für Hilfe.",
    "PUBLIC_SIGNUP_FAILED_WARNING":"Bitte entschuldigen Sie. Das Backend-System konnte das Konto aufgrund eines unbekannten Fehlers nicht einrichten. Bitte kontaktieren Sie den Support mit der BatchID",
    "PUBLIC_SIGNUP_FAILED_WARNING1":"um Ihnen weiter zu helfen.",
    "PUBLIC_SIGNUP_FILTER_HEADER_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "PUBLIC_SIGNUP_GO_TO_CART":"Zum Warenkorb gehen",
    "PUBLIC_SIGNUP_GOTO_HOME":"Zur Startseite gehen",
    "PUBLIC_SIGNUP_HEADER_ITEM_SIGNUP":"Anmeldung",
    "PUBLIC_SIGNUP_INVALID_QUANTITY":"Produkte im Warenkorb haben ungültige Mengenangaben",
    "PUBLIC_SIGNUP_LEARNER_PORTAL":"Lernenden-Portal",
    "PUBLIC_SIGNUP_LOGS_WARNING":"Die Bearbeitung kann eine Weile dauern und der Status wird auf dieser Seite angezeigt. Wenn Sie sich von der Seite entfernen oder den Browser-Benutzer geschlossen haben, halten wir Sie per E-Mail auf dem Laufenden.",
    "PUBLIC_SIGNUP_NEXT":"Nächster",
    "PUBLIC_SIGNUP_NO_DISCREPTION_AVAILABLE":"Keine Beschreibung vorhanden",
    "PUBLIC_SIGNUP_NOTIFICATION_CART_CREATION_IS_SUCCESSFUL":"Wir haben den Warenkorb erfolgreich erstellt",
    "PUBLIC_SIGNUP_NOTIFICATION_OFFER_IS_ALREADY_AVAILABLE_IN_CART":"Das Angebot ist bereits im Warenkorb vorhanden",
    "PUBLIC_SIGNUP_NOTIFICATION_QUANTITY_MUST_BE_ATLEAST_ONE_FOR_CART":"Bitte geben Sie mindestens 1 Menge an",
    "PUBLIC_SIGNUP_ORGANIZATION_ID":"Organisations-ID",
    "PUBLIC_SIGNUP_PAYMENT_GATEWAY_NOT_CONFIGURED":"Die Anmeldung wird nicht unterstützt, da das Zahlungsgateway noch nicht konfiguriert ist. Bitte kontaktieren Sie den Support für Hilfe.",
    "PUBLIC_SIGNUP_PAYMENT_HEADER_TEXT":"Bezahlverfahren",
    "PUBLIC_SIGNUP_PAYMENT_TEMPLATE_BUTTON_TEXT_SUBMIT":"Jetzt bezahlen",
    "PUBLIC_SIGNUP_PLACE_ORDER":"Bestellung aufgeben",
    "PUBLIC_SIGNUP_PORTLET_HEADER":"Anmelden",
    "PUBLIC_SIGNUP_PORTLET_HEADER_CART":"Wagen",
    "PUBLIC_SIGNUP_PORTLET_HEADER_REVIEW_CART":"Warenkorb überprüfen",
    "PUBLIC_SIGNUP_PORTLET_HEADER_SHOP":"Shop",
    "PUBLIC_SIGNUP_PRODUCT_DETAILS":"Detail",
    "PUBLIC_SIGNUP_QTY":"ANZAHL",
    "PUBLIC_SIGNUP_QUANTITY_GREATER_THAN_ZERO":"Die Menge muss größer als 0 sein",
    "PUBLIC_SIGNUP_QUANTITY_REQUIRED":"Menge ist erforderlich",
    "PUBLIC_SIGNUP_QUICK_CART":"Einkaufswagen schließen",
    "PUBLIC_SIGNUP_QUICK_VIEW":"Schnellansicht",
    "PUBLIC_SIGNUP_RESTRICT_SINGLE_MARKET_WARNING_MESSAGE":"Durch Klicken auf „OK“ werden die zuvor hinzugefügten NCE-Produkte entfernt, da NCE-Produkte aus verschiedenen Marktregionen nicht gleichzeitig gekauft werden können.",
    "PUBLIC_SIGNUP_SHOP_BACK_TO_CUSTOMER_DETAILS_BUTTON":"Zurück zu Einzelheiten",
    "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_APPLY_FILTERS":"Hotels filtern",
    "PUBLIC_SIGNUP_SHOP_SCREEN_BUTTON_CLEAR_FILTERS":"Filter löschen",
    "PUBLIC_SIGNUP_SIDE_CART":"Wagen",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING":"Aufsätze",
    "PUBLIC_SIGNUP_SIDE_CART_ADDONS_HEADING_LEVEL_2":"Aufsätze",
    "PUBLIC_SIGNUP_SIDE_CART_ESTIMATED_PRICE":"Geschätzter Preis",
    "PUBLIC_SIGNUP_SIDE_CART_LINKED_HEADING":"Verknüpftes Produkt",
    "PUBLIC_SIGNUP_SIDE_CART_REMOVE_TEXT":"Entfernen",
    "PUBLIC_SIGNUP_SIGNUP_AND_BUY":"Anmelden & Kaufen",
    "PUBLIC_SIGNUP_SIGNUP_EXISTING_TENANT_CHECKBOX":"Sie haben bereits einen MS-Mieter?",
    "PUBLIC_SIGNUP_SIGNUP_LOGS_LOADING_TEXT":"Bitte warten Sie, während wir Ihr Konto einrichten. Sie können Ihr Gerät wie gewohnt weiter benutzen. Wir werden Sie per E-Mail benachrichtigen, sobald die Einrichtung abgeschlossen ist.",
    "PUBLIC_SIGNUP_STRIPE_SKIP_AND_CONTINUE_BTN_TXT":"Überspringen und fortfahren",
    "PUBLIC_SIGNUP_STRIPE_SUBMIT_BTN_TXT":"Jetzt bezahlen",
    "PUBLIC_SIGNUP_SUCCESS_MESSAGE":"Anmeldung erfolgreich! Sie können sich jetzt mit den an uns gesendeten Anmeldeinformationen beim C3-Portal anmelden.",
    "PUBLIC_SIGNUP_SUPPORT":"Unterstützung",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITION_1":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITION_2":"Fehler beim Abrufen der dem externen Dienst zugeordneten Entitäten und Vertragsdienste",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS1":"Öffentliche Registrierungsbedingungen 1",
    "PUBLIC_SIGNUP_TERMS_AND_CONDITIONS2":"Allgemeine Geschäftsbedingungen für die Anmeldung 2",
    "PUBLIC_SIGNUP_TERMS_OF_USE":"Nutzungsbedingungen",
    "PUBLIC_SIGNUP_TEXT_ACH":"ACH",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_ASC":"Sortieren von A - Z",
    "PUBLIC_SINGUP_FILTER_DEFAULT_NAME_DESC":"Sortieren Z - A",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PLACEHOLDER":"Sortieren nach",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_HIGH_LOW":"Preis: Hoch bis Niedrig",
    "PUBLIC_SINGUP_FILTER_DEFAULT_PRICE_LOW_HIGH":"Preis niedrig bis hoch",
    "PUBLIC_SINGUP_PLACEHOLDER_VOUCHER_CODE":"Gutschein-Code eingeben",
    "PUBLIC_SINGUP_VALIDATION_NCE_COUNTRY_ERROR":"Die ausgewählten Angebote werden für das ausgewählte Land nicht unterstützt. Überprüfen Sie daher die ausgewählten Angebote noch einmal",
    "PURCHASE_PRODUCT_AUTO_RENEW_LEBEL_TEXT":"Verlängert sich am.",
    "PURCHASE_PRODUCT_EXPIRES_ON_LEBEL_TEXT":"Läuft aus am.",
    "PURCHASE_PRODUCT_MAPPING":"Produktzuordnung kaufen",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY":"Kaufen Sie die Angebotspositionen automatisch.",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLY_DESC":"Wenn wir das Kontrollkästchen deaktivieren, nachdem wir das Angebot genehmigt haben, muss der Partner/Wiederverkäufer die Produkte manuell im Namen des Kunden kaufen.",
    "PURCHASE_QUOTE_LINE_ITEMS_AUTOMATICALLYour sessionY_DESC":"Wenn wir das Kontrollkästchen deaktivieren, nachdem wir das Angebot genehmigt haben, muss der Partner/Wiederverkäufer die Produkte manuell im Namen des Kunden kaufen.",
    "PurchaseCurrency":"Kaufwährung",
    "PURCHASED_PRODUCT_MAPPING_HEADER_TEXT_PURCHASED_PRODUCT_MAPPING":"Gekaufte Produktzuordnung",
    "PURCHASED_PRODUCTS_ADDONS_HYPERLINK_TEXT":"Addons",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_EXPIRES_ON":"Läuft aus am",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_ORDERED_ON":"Bestellt am",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_PRODUCT_NAME":"Produktname",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_QUANTITY":"Menge",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_RENEWS_ON":"Erneuert weiter",
    "PURCHASED_PRODUCTS_REPORT_TABLE_TITLE_STATUS":"Status",
    "QUANTITY_EDIT_POPPUP_TITLE_TEXT":"Menge bearbeiten",
    "QUANTITY_MISMATCH_ISSUE_FIX_FAILED":"Der Versuch, den Unterschied auszugleichen, ist fehlgeschlagen. Bitte wenden Sie sich an den Support, um Unterstützung zu erhalten",
    "QUANTITY_MISMATCH_ISSUE_FIXED":"Mengenabgleich erfolgreich abgeschlossen",
    "QUEUE_DETAILS_TABLE_HEADER_EXPECTED_COUNT":"Erwartete Anzahl",
    "QUEUE_DETAILS_TABLE_HEADER_IS_APPLICABLE":"Anwendbar",
    "QUEUE_DETAILS_TABLE_HEADER_MESSAGE_COUNT":"Anzahl der Nachrichten",
    "QUEUE_DETAILS_TABLE_HEADER_QUEUE_NAME":"Name der Warteschlange",
    "QUICK_IMPERSONATION_CAPTION_TEXT_QUICK_IMPERSONATION":"Schneller Identitätswechsel",
    "QUICK_IMPERSONATION_HEADER_TEXT":"Wenn folgende Benutzer für den Identitätswechsel verfügbar sind, wählen Sie eine aus, um fortzufahren",
    "QUICK_IMPERSONATION_TABLE_ACTION_BUTTON_YTEXT_IMPERSONATION":"Imitieren",
    "QUICK_IMPERSONATION_TABLE_LABELS_ACTION":"Aktionen",
    "QUICK_IMPERSONATION_TABLE_LABELS_USER_ROLES":"Rolle",
    "QUICK_IMPERSONATION_TABLE_LABELS_USERS":"Benutzer",
    "QUICK_SIDEBAR_LABEL_ACTIVITIES":"Aktivitäten",
    "QUICK_SIDEBAR_LABEL_ALERTS":"Warnungen",
    "QUICK_SIDEBAR_LABEL_MORE":"Mehr",
    "QUICK_SIDEBAR_LABEL_NOTIFICATION":"Benachrichtigungen",
    "QUICK_SIDEBAR_LABEL_SETTINGS":"die Einstellungen",
    "QUICK_SIDEBAR_LABEL_USERS":"Benutzer",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE":"Vielen Dank für Ihre Zustimmung zu QuickBooks. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf QuickBooks zugreift. Sie können die Rechnungen Ihrer Kunden in QuickBooks buchen.",
    "QUICKBOOKS_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen zum Hochladen von Rechnungen in QuickBooks in <b>{{applicationName}} </b>.",
    "QUICKBOOKS_CONSENT_PROCESSING_MESSAGE":"Bitte warten Sie, während wir Ihre QuickBooks-Einwilligung bearbeiten",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_1":"Der folgende Link wird in einer neuen Registerkarte geöffnet. Bitte stimmen Sie Ihren QuickBooks-Anmeldeinformationen zu. Wenn Sie bei der Erteilung der Einwilligung einen Fehler haben, wenden Sie sich bitte an unser Support-Team.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_2":"QuickBooks Zustimmung zu erteilen",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_3":"Wenn Sie die Einwilligung bereits erteilt haben, klicken Sie auf die Schaltfläche <b>Token validieren </b>, um die Konnektivität mit QuickBooks zu überprüfen.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf diese Schaltfläche, um die Gültigkeit des vorhandenen QuickBooks-Aktualisierungstokens zu überprüfen.",
    "QUICKBOOKS_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Voraussetzungen für den Partner, um Kundenrechnungen an <b>QuickBooks Online </b>zu senden",
    "QUICKBOOKS_POST_BATCH_ID":"Quickbooks post Batch Id",
    "QUICKBOOKS_POST_LOGS_STATUS":"Quickbooks Post Logs Status",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Das vorhandene QuickBooks-Aktualisierungstoken ist ungültig. Bitte geben Sie Ihre Zustimmung erneut",
    "QUICKBOOKS_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Das vorhandene QuickBooks-Aktualisierungstoken ist gültig. Sie können mit dem Hochladen von Rechnungen fortfahren",
    "QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS":"Angebotsdetails",
    "QUOTE_ALL_SELECTION":"Wählen Sie Alle",
    "QUOTE_APPROVAL_CONFIRMATION":"Möchten Sie das Angebot wirklich genehmigen?",
    "QUOTE_APPROVAL_CONFIRMATION_SUCCESS_MESSAGE":"Angebot genehmigt und Bestellung erfolgreich aufgegeben.<br/> Bestell-ID ist <b>{{OrderNumber}}</b>",
    "QUOTE_APPROVAL_SUCCESS_MESSAGE":"Angebot erfolgreich genehmigt.",
    "QUOTE_AUDIT_LOG_DELETED_BY":"Gelöscht von",
    "QUOTE_AUDIT_LOG_ID":"Angebots-ID",
    "QUOTE_AUDIT_LOG_NAME":"Zitatname",
    "QUOTE_AUDIT_LOG_QUOTE_NAME":"Zitatname",
    "QUOTE_BREADCRUMB_QUOTE_ADD":"Angebote erstellen",
    "QUOTE_BREADCRUMB_QUOTE_EDIT":"Zitate bearbeiten",
    "QUOTE_BREADCRUMB_QUOTE_LIST":"Zitate",
    "QUOTE_BUTTON_TEXT_AGE":"Alter",
    "QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST":"Zurück zur Angebotsliste",
    "QUOTE_BUTTON_TEXT_CANCEL":"Stornieren",
    "QUOTE_BUTTON_TEXT_CLOSE":"Schließen",
    "QUOTE_BUTTON_TEXT_COPY":"Kopie",
    "QUOTE_BUTTON_TEXT_EMAIL_QUOTE":"E-Mail mit Zitat schreiben",
    "QUOTE_BUTTON_TEXT_SUBMIT":"einreichen",
    "QUOTE_BUYER_INFO":"Käuferinformationen",
    "QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO":"Käuferinfo",
    "QUOTE_CHOOSE_BUYER_DETAILS":"Wählen Sie die Kontaktdaten des Käufers aus, die Sie in das Angebot aufnehmen möchten.",
    "QUOTE_CHOOSE_SELLER_DETAILS":"Überprüfen Sie die Angaben zu Ihrer Person und Ihrem Unternehmen, die im Angebot angezeigt werden.",
    "QUOTE_COMMENTS_TO_BUYERS":"Bemerkungen",
    "QUOTE_COMPANY":"Unternehmen",
    "QUOTE_CONTACT":"Kontakt",
    "QUOTE_CONTACTS_MAX_VALIDATION_ERROR":"Es können bis zu drei Angebotskontakte hinzugefügt werden.",
    "QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE":"Fehler beim Kopieren des Textes:",
    "QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE":"URL erfolgreich kopiert.",
    "QUOTE_COUNTERSIGNATURE":" Gegenzeichnung",
    "QUOTE_CREATE_A_LINE_ITEM":"Erstellen einer Position",
    "QUOTE_CREATED":"Angebot erstellt am",
    "QUOTE_CUSTOM_LINE_ITEM_INFORMATION":"Positionsinformationen",
    "QUOTE_CUSTOM_LINE_ITEM_SAVE":"speichern",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING":"Abrechnung",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_FREQUENCY":"Abrechnungshäufigkeit",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_DESCRIPTION":"Beschreibung",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_DUE_DATE":"Rechnungsfälligkeitsdatum",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_END_DATE":"Endtermin",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_DATE":"Rechnungsdatum",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_INVOICE_NUMBER":"Rechnungsnummer",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_IS_TAXABLE":"Ist steuerpflichtig",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_NAME":"Name",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_PRICE":"Preis",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_SALE_TYPE":"Verkaufsart",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_START_DATE":"Anfangsdatum",
    "QUOTE_CUSTOM_LINE_ITEM_TEXT_UNIT_PRICE":"Stückpreis",
    "QUOTE_CUSTOM_LINE_ITEM_UNIT_PRICE_DECIMAL_ERROR":"Bitte geben Sie einen Wert mit bis zu zwei Dezimalstellen ein.",
    "QUOTE_CUSTOMER_DELETE_VALIDATION_ERROR":"Das Angebot kann nicht genehmigt werden, da der Kunde gelöscht wurde.",
    "QUOTE_DATE":"Datum",
    "QUOTE_DOWNLOAD_AS_PDF":" Download als PDF",
    "QUOTE_EMAIL_ERROR":"Angebots-E-Mail-Fehler",
    "QUOTE_EMAIL_MODAL_HEADER":"Zitat E-Mail-Text",
    "QUOTE_ERROR_CUSTOMER_AND_NAME_NOT_UNIQUE":"Beim Speichern des Angebotsnamens und des Kunden ist ein Fehler aufgetreten, da bereits ein Angebot im Entwurfs- oder veröffentlichten Zustand vorliegt.",
    "QUOTE_ERROR_CUSTOMER_NOT_UNIQUE":"Beim Speichern ist ein Fehler aufgetreten. Für den ausgewählten Kunden liegt bereits ein Angebot im Entwurfs- oder veröffentlichten Status vor.",
    "QUOTE_ERROR_NAME_NOT_UNIQUE":"Beim Speichern des Angebots ist ein Fehler aufgetreten, da der Angebotsname nicht eindeutig ist. Es gibt bereits ein anderes Angebot mit demselben Namen, das sich entweder im veröffentlichten Status oder im Entwurfsstatus befindet.",
    "QUOTE_EVENT_ERROR":"Error",
    "QUOTE_EXPIRATION_DATE_REQUIRED_ERROR":" Bitte geben Sie das Ablaufdatum ein",
    "QUOTE_EXPIRES":"Angebot läuft ab am",
    "QUOTE_INCLUDE":"Im Angebot enthalten",
    "QUOTE_LABEL_TEXT_CHECKBOX_CONTACTS":"Kontakte",
    "QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER":"Kunden auswählen",
    "QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER":"Bemerkungen",
    "QUOTE_LABEL_TEXT_COUNTER_SIGNATURE":" Gegenzeichnung",
    "QUOTE_LABEL_TEXT_CURRENCY_CODE":"Währungscode",
    "QUOTE_LABEL_TEXT_EMAIL":"Email",
    "QUOTE_LABEL_TEXT_EXPIRATION_DATE":"Verfallsdatum",
    "QUOTE_LABEL_TEXT_FIRST_NAME":"Vorname",
    "QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE":" Fügen Sie Platz für eine handschriftliche Unterschrift hinzu",
    "QUOTE_LABEL_TEXT_LAST_NAME":"Nachname",
    "QUOTE_LABEL_TEXT_NO_SIGNATURE":" Keine Signatur",
    "QUOTE_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
    "QUOTE_LABEL_TEXT_PURCHASE_TERM":"Einkaufsbedingungen",
    "QUOTE_LABEL_TEXT_QUOTE_NAME":"Zitatname",
    "QUOTE_LABEL_TEXT_SAVE_DRAFT":"Ihre Änderungen wurden gespeichert",
    "QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS":"Signaturoptionen",
    "QUOTE_LABEL_TEXT_SPACE_FOR_COUNTER_SIGNATURE":" Fügen Sie Platz für eine Gegenunterschrift ein",
    "QUOTE_LABEL_TEXT_TEMPLATE":"Schablone",
    "QUOTE_LABEL_TEXT_TOOLTIP_ADD":"Kunden hinzufügen",
    "QUOTE_LABEL_TEXT_WEB_LINK":"Kopieren Sie den unten stehenden Webseiten-Link, um ihn mit potenziellen Kunden zu teilen, oder schreiben Sie eine E-Mail mit dem beigefügten Angebot.",
    "QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR":"Bitte füllen Sie alle erforderlichen Felder aus, bevor Sie fortfahren.",
    "QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM":"Benutzerdefinierten Einzelposten hinzufügen",
    "QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT":"Planprodukt hinzufügen",
    "QUOTE_LINE_ITEM_HEADER":"Einzelposten",
    "QUOTE_LINE_ITEM_LABEL_DISCOUNT":"Totaler Rabatt",
    "QUOTE_LINE_ITEM_LABEL_SUBTOTAL":"Zwischensumme",
    "QUOTE_LINE_ITEM_LABEL_TAX":"Gesamtsteuer",
    "QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE":"Gesamtwert des Angebots",
    "QUOTE_LINE_ITEM_MAX_PRODUCT_LIMIT":"Es dürfen maximal 50 Produkte hinzugefügt werden.",
    "QUOTE_LINE_ITEM_MAX_QUANTITY_ERROR":"Es können maximal zehn Mengen hinzugefügt werden.",
    "QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR":"Bis zu 9999 Positionen können hinzugefügt werden",
    "QUOTE_LINE_ITEM_MIN_QUANTITY_ERROR":"Es ist eine Mindestbestellmenge von einem erforderlich.",
    "QUOTE_LINE_ITEM_SUB_HEADER":"Überprüfen Sie die Position, die in Ihrem Angebot angezeigt werden soll",
    "QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP":"Zwischensumme",
    "QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER":"Menge",
    "QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE":"Die angezeigten Steuern sind Richtwerte und werden zum Zeitpunkt der Rechnungserstellung endgültig festgelegt.",
    "QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE":"Steuertyp",
    "QUOTE_LINE_ITEM_TOOLTIP_DISCOUNT":"Totaler Rabatt",
    "QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP":"Gesamtwert des Angebots",
    "QUOTE_LINE_ITEM_VALIDATION_ERROR":"Fügen Sie mindestens ein Element hinzu.",
    "QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE":"Speichern Sie die Änderungen an den Angebotspositionen, bevor Sie fortfahren.",
    "QUOTE_LINE_ITEMS_PRODUCT_ELEMENTS_POPUP_HEADER":"Produkte für Angebot",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS":"Aktionen",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE":"Selbstkostenpreis",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME":"Angebotsname",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY":"Menge",
    "QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE":"Verkaufspreis",
    "QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS":"Einzelposten",
    "QUOTE_LINE_TABLE_HEADER_SUMMARY":"Zusammenfassung",
    "QUOTE_LINEITME_LABEL_CUSTOMER_PLANS":"Kundenpläne",
    "QUOTE_LIST_BREADCRUMB_QUOTE":"Angebotsliste",
    "QUOTE_LIST_STATUS_PUBLISHED":"Rechnung veröffentlicht",
    "QUOTE_MOVE_BACK_TO_STATE":"Vorherige",
    "QUOTE_MOVE_TO_STATE":"Nächster",
    "QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE":"Der Zitatname sollte weniger als 150 Zeichen lang sein.",
    "QUOTE_NAME_REQUIRED_ERROR":"Bitte geben Sie den Angebotsnamen ein",
    "QUOTE_ONE_TIME_SUBTOTAL":"Einmalige Zwischensumme:",
    "QUOTE_OVERRIDE_CAPTION_APPROVE_QUOTE":"Angebot genehmigen",
    "QUOTE_OVERRIDE_CAPTION_DELETE_QUOTE":"Zitat löschen",
    "QUOTE_OVERRIDE_CAPTION_EDIT_QUOTE":"Zitat bearbeiten",
    "QUOTE_OVERRIDE_CAPTION_VIEW_QUOTE":"Zitat anzeigen",
    "QUOTE_OWNER":"Zitatbesitzer",
    "QUOTE_PDF_PURCHASE_TERM":"Einkaufsbedingungen",
    "QUOTE_PLAN_ASSIGNED_TO_CUSTOMER_TOOLTIP":"Dem Kunden zugewiesene Pläne",
    "QUOTE_PLEASE_ENTER_THE_EMAIL_ADDRESS":"Bitte geben Sie die E-Mail Adresse ein.",
    "QUOTE_PLEASE_ENTER_THE_FIRST_NAME":"Bitte geben Sie den Vornamen ein.",
    "QUOTE_PLEASE_ENTER_THE_LAST_NAME":"Bitte geben Sie den Nachnamen ein.",
    "QUOTE_PLEASE_ENTER_THE_PHONE_NUMBER":"Bitte geben Sie die Telefonnummer ein.",
    "QUOTE_PREPARED_BY":"Hergestellt von:",
    "QUOTE_PRICE_SUMMARY":"Zusammenfassung",
    "QUOTE_PRICE_TOTAL":"Gesamt",
    "QUOTE_PRINTED_NAME":"Gedruckter Name",
    "QUOTE_PRODUCTS_TABLE_PLANS_HEADER":"Plan Name",
    "QUOTE_PRODUCTS_TABLE_PRODUCT_NAME_HEADER":" Produktname",
    "QUOTE_PRODUCTS_TABLE_PROPERTIES_HEADER":"Eigenschaften",
    "QUOTE_PRODUCTS_TABLE_SALE_PRICE_HEADER":"Verkaufspreis",
    "QUOTE_READY_FOR_REVIEW":"Angebot zur Überprüfung bereit",
    "QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH":"Überprüfen und veröffentlichen",
    "QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO":"Verkäuferinformationen",
    "QUOTE_SENDER":"Angebotsabsender",
    "QUOTE_SIGNATURE":"Unterschrift",
    "QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE":"Unterschrift",
    "QUOTE_STATUS":"Status",
    "QUOTE_STATUS_APPROVED":"Zur Kundenbewertung zugelassen",
    "QUOTE_STATUS_DELETED":"Gelöscht",
    "QUOTE_STATUS_DRAFT":"Entwurf",
    "QUOTE_STATUS_EXPIRED":"Abgelaufen",
    "QUOTE_STATUS_OOPS_ERROR":"Hoppla!",
    "QUOTE_STATUS_ORDERPROCESSED":"Bestellung bearbeitet",
    "QUOTE_STATUS_PUBLISHED":"Rechnung veröffentlicht",
    "QUOTE_STATUS_WE_CANT_FIND_THAT_PAGE_ERROR":"Wir können diese Seite nicht finden.",
    "QUOTE_TABLE_HEADER_TEXT_ACTION":"Aktion",
    "QUOTE_TABLE_HEADER_TEXT_CREATE_DATE":"Erstellungsdatum",
    "QUOTE_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "QUOTE_TABLE_HEADER_TEXT_EXPIRATION_DATE":"Verfallsdatum",
    "QUOTE_TABLE_HEADER_TEXT_NAME":"Name",
    "QUOTE_TABLE_HEADER_TEXT_QUOTE_AMOUNT":"Menge",
    "QUOTE_TABLE_HEADER_TEXT_QUOTE_OWNER":"Zitatbesitzer",
    "QUOTE_TABLE_HEADER_TEXT_STATUS":"Status",
    "QUOTE_TABLE_LABELS_USER_ROLES":"Rolle",
    "QUOTE_TABLE_LABELS_USERS":"Kunde(n)",
    "QUOTE_TABLE_PRICE":"Stückpreis",
    "QUOTE_TABLE_PRODUCT_SERVICE":"Produkt und Service",
    "QUOTE_TABLE_QUANTITY":"Menge",
    "QUOTE_TEXT_EMAIL_DETAILS":"Hallo, <br/><br/>Ihr Angebot steht zur Überprüfung bereit.<br/><br/>Wichtige Angaben:<br/><br/>Angebotsname: {{quoteName}}<br/><br/>Gültig bis: {{expirationDate}} <br/><br/> Sie können über den folgenden Link auf Ihr Angebot zugreifen und es überprüfen.<br/><br/>{{quoteURL}}<br/><br/>",
    "RCON_APPLY_PROMOTIONS_NCE":"Förderung anwenden",
    "RD_DEPLOYMENT_ADD_BUTTON_TEXT_BACK_TO_RD_DEPLOYMENT":"Zurück zu RD-Bereitstellungen",
    "RD_DEPLOYMENT_ADD_CAPTION_TEXT_DEPLOY_NEW_RD":"Stellen Sie eine neue RD bereit",
    "RD_DEPLOYMENT_ADD_FORM_BUTTON_TEXT_DEPLOY":"Bereitstellen",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_DETAILS":"Einzelheiten",
    "RD_DEPLOYMENT_ADD_FORM_HEADER_RD_SETTINGS":"RD-Einstellungen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADD_DOMAIN_NAME":"Domainnamen hinzufügen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_PASSWORD":"Administrator-Passwort",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_ADMIN_USER":"Administrator-Benutzername",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_DEPLOYMENT_NAME":"Bereitstellungsname",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATEWAY_DNS_LABEL_PREFIX":"Gateway-DNS-Label-Präfix",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_GATWAY_PUBLIC_IP_ADDRESS_NAME":"Name der öffentlichen IP-Adresse des Gateways",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKU":"Bild-SKU",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_IMAGE_SKUE_PLACE_HOLDER":"Wählen Sie Bild-SKU",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION":"Ort",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_LOCATION_PLACE_HOLDER":"Ort auswählen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_NO_OF_RD_INSTANCES":"Anzahl der Rd-Instanzen",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_RESOURCE_GROUP":"Ressourcengruppe",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION":"Abonnement",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_SUBSCRIPTION_PLACE_HOLDER":"Wählen Sie Abonnement",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE":"VM-Größe",
    "RD_DEPLOYMENT_ADD_FORM_LABEL_VM_SIZE_PLACE_HOLDER":"Wählen Sie Bild-SKU",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "RD_DEPLOYMENT_BREADCRUM_BUTTON_TEXT_RD_DEPLOYMENTS":"RD-Bereitstellungen",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_DEPLOY_NEW_RD":"Stellen Sie eine neue RD bereit",
    "RD_DEPLOYMENT_DEPLOYMENTS_BUTTON_TEXT_REFRESH_DEPLOYMENT_STATUS":"Bereitstellungsstatus aktualisieren",
    "RD_DEPLOYMENT_DEPLOYMENTS_CAPTION_TEXT_RD_DEPLOYMENTS":"RD-Bereitstellungen",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_ACTION_TOOLTIP_MANAGE":"Verwalten",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYED_ON":"Bereitgestellt am",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_DEPLOYMENT_NAME":"Bereitstellungsname",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_LOCATION":"Ort",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_RESOURCE_GROUP":"Ressourcengruppe",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_STATUS":"Status",
    "RD_DEPLOYMENT_DEPLOYMENTS_TABLE_HEADER_TEXT_SUBSCRIPTION":"Abonnement",
    "RD_DEPLOYMENT_MANAGE_BUTTON_BACK_TO_RD_DEPLOYMENTS":"Zurück zu RD-Bereitstellungen",
    "RD_DEPLOYMENT_MANAGE_CAPTION_TEXT_DEPLOYMENT_DETAILS":"Bereitstellungsdetails",
    "RD_DEPLOYMENT_MANAGE_FORM_HEADER_TEXT_DETAILS":"Einzelheiten",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME":"Bereitstellungsname",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_NAME_ENVIRONMENT":"Umgebung",
    "RD_DEPLOYMENT_MANAGE_FORM_LABEL_TEXT_DEPLOYMENT_ON":"Bereitgestellt am",
    "RDS_DEPLOYMENT_STARTED_SUCCESS":"Die RD-Bereitstellung wurde erfolgreich gestartet",
    "READ_THROUGH":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen durch",
    "REASON_FOR_FAILURE":"Grund für das Scheitern",
    "REASON_FOR_UPDATE":"Grund für die Aktualisierung",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_FAIL":"Das erneute Laden des Abstimmungsberichts ist fehlgeschlagen",
    "RECON_CUSTOMER_REPORT_RELOAD_INITIAT_SUCCESS":"Neustart des Abstimmungsberichts erfolgreich",
    "RECON_CUSTOMER_REPORT_RELOADING":"Abgleichsbericht wird neu geladen",
    "RECON_PRODUCT_INPROVISION_TOOLTIP":"Das Produkt hat den Status In Bereitstellung",
    "RECON_PRODUCT_ONHOLD_TOOLTIP":"Produkt wartet auf Zulassung",
    "RECON_REPORT_COLLAPSE_ALL":"Alles einklappen",
    "RECON_REPORT_EXPAND_ALL":"Alle erweitern",
    "RECON_REPORT_MISMATCH_COUNT_BADGE":"Nichtübereinstimmungen",
    "RECON_REPORT_RELOAD":"Neu geladener Abstimmungsbericht",
    "RECON_REPORT_RELOAD_INITIAT_FAIL":"Die Initiierung des erneuten Ladens des Abstimmungsberichts ist fehlgeschlagen",
    "RECON_REPORT_RELOAD_INITIAT_SUCCESS":"Das Neuladen des Abstimmungsberichts wurde eingeleitet",
    "RECON_REPORT_SEARCH_BUTTON_TEXT":"Suche",
    "RECON_REPORT_SEARCH_FIELD_PLACEHOLDER":"Suche nach Angebot, Abonnement-ID",
    "RECON_REPORT_SHOW_FIXED_INACTIVE":"Behoben inaktiv anzeigen",
    "RECON_REPORT_SHOW_MISMATCH":"Nur Diskrepanzen anzeigen",
    "RECON_TABLE_TEXT_AZURE_SUBSCRIPTION_ID":" Azure-Abonnement-ID:",
    "RECON_TABLE_TEXT_BILLING_CYCLE":"Rechnungskreislauf :",
    "RECON_TABLE_TEXT_PROVIDER_SUBSCRIPTION":"Anbieterabonnement",
    "RECON_TABLE_TEXT_REGION":" Region:",
    "RECON_TABLE_TEXT_RESERVATION_ID":" Reservierungs-ID:",
    "RECON_TABLE_TEXT_RESERVATION_ORDER_ID":" Reservierungsauftrags-ID:",
    "RECON_TABLE_TEXT_SUBSCRIPTION_ID":"Abonnement :",
    "RECON_TABLE_TEXT_TERM_DURATION":"Gültigkeit :",
    "RECON_TABLE_TEXT_TRIAL_OFFER":"Schnupperangebot",
    "RECONCILIATION_CUSTOMER_NAME":"Kundenname",
    "RECONCILIATION_HEADER_TABLE_PROVIDER_TEXT":"ANBIETER",
    "RECONCILIATION_MISSMATCH":"Versöhnungsfehler",
    "RECONCILIATION_NOTES":"Anmerkungen",
    "RECONCILIATION_NOTES_MISMATCH":" Elemente in <span class='text-danger'>rot</span> weisen auf eine Nichtübereinstimmung hin",
    "RECONCILIATION_NOTES_PROMOTION_MISMATCH":" Artikel in <span class='text-mediumvioletred'>Violettrot</span> weisen auf eine Diskrepanz im Zusammenhang mit der Werbeaktion hin. Wenden Sie sich an das Support-Team, um den Werbevorteil an den Endkunden weiterzuleiten",
    "RECONCILIATION_NOTES_QUANTITY_MISMATCH":"Artikel in <span class='red'>rot/span> weisen auf eine Mengeninkongruenz hin",
    "RECONCILIATION_NOTES_RI_INVOICE":"Nach dem Onboarding eines RI werden alle zusätzlichen Reservierungen mit demselben Angebot innerhalb der integrierten Reservierungs-Bestell-ID zum integrierten Preis in Rechnung gestellt.",
    "RECONCILIATION_NOTES_SUSPENDED":"Artikel <span class='strike-through'>durchgestrichen</span> weisen auf gesperrte Abonnements hin",
    "RECONCILIATION_PROVIDER":"Anbieter",
    "RECORD_ADVANCE_PAYMENT_AMOUNT_LABEL_TEXT":"Zahlungsbetrag",
    "RECORD_ADVANCE_PAYMENT_CONFIRMATION":"Sind Sie sicher, dass Sie eine Zahlung über <%= Betrag%> (<%= CurrencyCode%>) für den Kunden verbuchen möchten?<br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Unterstützung bei Zahlungen benötigen, bei denen dies nicht der Fall ist auftauchen oder irrtümlich aufgezeichnet wurden.</small>",
    "RECORD_ADVANCE_PAYMENT_CURRENCY_CODE_LABEL_TEXT":"Währungscode",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_DEFAULT_SELECT":"Wählen Sie Kunde",
    "RECORD_ADVANCE_PAYMENT_CUSTOMERS_LABEL_TEXT":"Kunden",
    "RECORD_ADVANCE_PAYMENT_ERROR_MESSAGE_CUSTOMER_REQIRED":"Der Kunde muss die Zahlung verbuchen.",
    "RECORD_ADVANCE_PAYMENT_MODAL_HEADER":"Vorauszahlung aufzeichnen",
    "RECORD_ADVANCE_PAYMENT_REMARKS_LABEL_TEXT":"Bemerkungen",
    "RECORD_ADVANCE_PAYMENT_SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Währungscode",
    "RECORD_ADVANCE_PAYMENT_SELECT_CUSTOMER_TOOLTIP_TEXT":"Wählen Sie Kunde",
    "RECORD_ADVANCE_PAYMENT_SUCCESS_MESSAGE":"Zahlung erfolgreich erfasst",
    "RECORD_ADVANCE_PAYMENT_TRANSACTION_DATE":"Zahlungsdatum",
    "RECORD_ID":"Datensatz-ID",
    "RECORD_ID_RECEIVED_FROM_EXTERNAL_SERVICE":"Datensatz-ID vom externen Dienst erhalten",
    "RECORD_MANUAL_PAYMENT_CONFIRMATION":"Möchten Sie wirklich eine Zahlung über <%= currencySymbol%> <%= Betrag%> auf dieser Rechnung verbuchen?<br/><small> Wenden Sie sich bitte an den Support, falls Sie weitere Hilfe zu Zahlungen benötigen, die entweder nicht angezeigt werden oder wurden irrtümlich aufgezeichnet.</small>",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_ENTITY":"Entitätsname",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TABLE_HEADER_NAME":"Name",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT":"Importieren",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_IMPORT_PAYMENTS":"Zahlungen importieren",
    "RECORD_MULTIPLE_PAYMENTS_CATALOGUE_TITLE_TEMPLATE":"Schablone",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Katalog",
    "RECORD_MULTIPLE_PAYMENTS_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Vorlage herunterladen",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_PAYMENTS_BUTTON_TEXT":"Zahlungen importieren",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_ERROR":"Error",
    "RECORD_MULTIPLE_PAYMENTS_IMPORT_STATUS_SUCCESS":"Erfolg",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_C3_ID":"C3Id",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_CURRENCY_CODE":"Währungscode",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_AMOUNT":"Zahlungsbetrag",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_PAYMENT_DATE":"Zahlungsdatum",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_RECORD_NAME":"Name",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_REMARKS":"Bemerkungen",
    "RECORD_MULTIPLE_PAYMENTS_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
    "RECORD_MULTIPLE_PAYMENTS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATE_INPUT_BUTTON_TEXT":"Katalog validieren",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_ERROR":"Error",
    "RECORD_MULTIPLE_PAYMENTS_VALIDATION_STATUS_SUCCESS":"Erfolg",
    "RECORD_PAYMENT_ERROR_WITH_ZERO_AMOUNT":"Der Zahlungsbetrag sollte größer als 0,00 sein.",
    "RECORD_PAYMENT_PAID_DATE":"Zahlungsdatum",
    "RECURRENCES_MAX_ERROR":"Der Wert muss kleiner oder gleich 11 sein.",
    "RECURRENCES_MIN_ERROR":"Der Wert muss größer oder gleich 0 sein",
    "RECURRENCES_TOOLTIP":"Wie oft der Rabattgutschein angewendet werden kann",
    "REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL":"REFRESH_EXTERNAL_ENTITY_LIST_IN_LOCAL",
    "REFRESH_TOKEN":"Token aktualisieren",
    "REFRESH_TOOLTIP_TEST_PAYMENT":"Aktualisierung",
    "REGISTER_CAPTION_TEXT_THANK_YOU":"Vielen Dank, dass Sie sich für <span class=\"bold uppercase font-blue\" style=\"cursor:pointer\" >{{PortalName}} entschieden haben.</span> Bitte füllen Sie die folgenden Informationen aus, um Ihr neues Abonnement zu bearbeiten.",
    "REGISTER_CAPTION_TEXT_THANK_YOU_CONTACT_US_LINK":"Wenn Sie ein vorhandenes Microsoft-Konto haben, das nicht bei uns ist, <a href=\"{{LinktoContact}}\" target=\"_blank\" class=\"ng-binding\">wenden Sie sich bitte an uns</a>, um Unterstützung zu erhalten.",
    "REGISTER_CHECKCOUPONVALIDITY_ENTER_VALID_COUPON":"Bitte geben Sie einen gültigen Gutschein ein",
    "REGISTER_CHECKDOMAINAVIALBILITY_ENTER_VALID_DOMAIN":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche.",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL":"Email",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_EMAIL_INFO":"Bitte geben Sie eine gültige E-Mail-ID an. Nach Abschluss der Registrierung senden wir eine E-Mail mit Benutzername und Passwort",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_FIRST_NAME":"Vorname",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_LAST_NAME":"Nachname",
    "REGISTER_COMPANY_PRIMARY_CONTACT_FORM_LABEL_PHONE_NUMBER":"Telefonnummer",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_CITY":"Stadt",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_COMPANY_NAME":"Name der Firma",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_1":"Linie 1",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_LINE_2":"Zeile 2",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME":"Primärer Domainname",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_CONTENT":"Um zu beginnen, müssen Sie ein Konto mit Ihrem Firmennamen gefolgt von .onmicrosoft.com erstellen. Wenn Ihre Organisation über eine eigene benutzerdefinierte Domäne verfügt, die Sie ohne .onmicrosoft.com verwenden möchten, können Sie dies später in Office 365 Admin Portal ändern.",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_PRIMARY_DOMAIN_NAME_INFO_LABEL":"Was ist das",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_STATE":"Zustand",
    "REGISTER_COMPANY_PROFILE_FORM_LABEL_ZIP":"Postleitzahl",
    "REGISTER_CUSTOMER_CONSENT_AGREEMENT_DATE":"Vereinbarungstermin",
    "REGISTER_CUSTOMER_CONSENT_BTN_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
    "REGISTER_CUSTOMER_CONSENT_CHECK_BOX_LBL_TEXT":"Ich akzeptiere das Neueste",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN":"Konfigurieren Sie Ihren Plan",
    "REGISTER_HEADER_CONFIGURE_YOUR_PLAN_INFO":"Wählen Sie für jedes Angebot im Plan, das Sie hinzufügen möchten, eine Mindestmenge von 1 aus",
    "REGISTER_HEADER_TEXT_COMPANY_PRIMARY_CONTACT":"Hauptansprechpartner",
    "REGISTER_HEADER_TEXT_COMPANY_PROFILE":"Firmenprofil",
    "REGISTER_HEADER_TEXT_MICROSOFT_CLOUD_AGREEMENT":"Microsoft-Kundenvereinbarung",
    "REGISTER_PAYMENT_PROFILE_BUTTON_ACH":"ACH",
    "REGISTER_PAYMENT_PROFILE_BUTTON_BANK_ACCOUNT":"Bankkonto",
    "REGISTER_PAYMENT_PROFILE_BUTTON_CREDIT_CARD":"Kreditkarte",
    "REGISTER_PAYMENT_PROFILE_COUPON_BUTTON_REMOVE_COUPON":"Gutschein entfernen",
    "REGISTER_PAYMENT_PROFILE_COUPON_HEADER_PLEASE_ENTER_VALID_COUPON":"Bitte geben Sie einen gültigen Gutschein ein",
    "REGISTER_PAYMENT_PROFILE_COUPON_MESSAGE":"Angewendeter Gutscheincode: <b>{{CouponCode}} </b>",
    "REGISTER_PAYMENT_PROFILE_CREDITCARD_HEADER_CREDIT_CARD_DETAILS":"Kreditkartendetails",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE":"Zahlungsart",
    "REGISTER_PAYMENT_PROFILE_HEADER_TEXT_PAYMENT_TYPE_ALERT":"Bitte wählen Sie Zahlungsart",
    "REGISTER_PLAN_SUMMERY_CAPTION_PLAN_SUMMERY":"Planübersicht",
    "REGISTER_PLAN_SUMMERY_HEADER_TEXT_RECURRING_FEE":"Wiederkehrende Gebühr (alle 1 Monat)",
    "REGISTER_PLAN_SUMMERY_LABEL_RENEWAL_ON":"dann <span class='fett'>${{totalCount}} </span> bei der ersten Erneuerung am {{nextBillingDate}}",
    "REGISTER_PLAN_SUMMERY_LABEL_TODAYS_TOTAL":"Gesamt",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_NAME":"Hinweis:",
    "REGISTER_PLAN_SUMMERY_NOTIFICATION_VALUE":"Die Gebühren für den Kauf werden für den Rest der Tage im aktuellen Abrechnungszyklus anteilig berechnet.",
    "REGISTER_PLAN_SUMMERY_TABLE_LINE_LICENSE":"Lizenzen",
    "REGISTER_REGISTERUSER_BUSINESs_OR_NOT":"Sie müssen angeben, ob das ACH-Konto ein Geschäftskonto ist oder nicht!",
    "REGISTER_REGISTERUSER_CREATED_USER":"Benutzer erfolgreich erstellt",
    "REGISTER_REGISTERUSER_UNABLE_TO_CHECK":"Die Domainverfügbarkeit kann nicht überprüft werden",
    "REGISTER_REGISTERUSER_VALID_DOMAIN":"Bitte geben Sie einen gültigen Domainnamen ein. Domain-Namen dürfen nur Buchstaben und Zahlen enthalten. Keine Bindestriche, Punkte oder Unterstriche.",
    "REGISTER_REGISTRATION_STATUS_CAPTION":"Registrationsstatus",
    "REGISTER_REGISTRATION_STATUS_HEADER":"Ihre Registrierung wurde erfolgreich bearbeitet. Bitte melden Sie sich mit den folgenden Anmeldeinformationen beim Portal an:",
    "REGISTER_REGISTRATION_STATUS_LINE_1":"Nutzername",
    "REGISTER_REGISTRATION_STATUS_LINE_2":"Passwort",
    "REGISTER_REGISTRATION_STATUS_LINE_3":"Für den Fall, dass Sie das Kopieren von hier verpasst haben, wurde Ihnen eine E-Mail mit den oben genannten Details gesendet",
    "REGUISTER_BUTTON_PROCESS_MY_ORDER":"Verarbeiten Sie meine Bestellung",
    "REJECT_ALL_PRODUCT_EMPTY_MESSAGE_ERROR":"Bitte geben Sie den Grund für die Ablehnung an",
    "REJECT_ALL_PRODUCT_POPUP_BUTTON":"Ablehnen",
    "REJECT_ALL_PRODUCT_POPUP_HEADER":"Alle Produkte ablehnen",
    "REJECT_ALL_PRODUCTS_COMMENT_HEADER":"Alle Produkte ablehnen",
    "REJECT_ALL_PRODUCTS_COMMENT_INSTRUCTION":"Bitte erläutern Sie den Grund für die Ablehnung der Anfrage",
    "REJECT_PRODUCT_COMMENT_HEADER":"Produkt ablehnen",
    "REJECT_PRODUCT_COMMENT_INSTRUCTION":"Bitte geben Sie die Gründe an, warum dieses Produkt abgelehnt werden sollte",
    "REJECT_PRODUCT_POPUP_BUTTON":"Ablehnen",
    "REJECTED_BY":" Zurückgewiesen von",
    "REJECTED_PRODUCT_EMPTY_COMMENT_ERROR":"Bitte geben Sie einen Grund für die Ablehnung dieses Produkts ein",
    "RELEASE_PRODUCT_OWNERSHIP":"Produktbesitz freigeben",
    "RELEASE_PRODUCT_OWNERSHIP_FAILED":"Freigabe des Produktbesitzes fehlgeschlagen",
    "RELEASE_SEED_ERROR":"Sie können die Menge nur während der Freigabe reduzieren. Bitte versuchen Sie es erneut",
    "RELEASE_SETAS_ERROR_MESSAGE_CHILD":"Es wurde versucht, mehr als die verfügbare Menge für \"{{childName}}\" zu aktualisieren",
    "RELEASE_SETAS_ERROR_MESSAGE_PARENT":"Es wurde versucht, mehr als die verfügbare Menge für das übergeordnete Element „{{parentName}}“ für „{{childName}}“ zu aktualisieren",
    "RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
    "RELOAD_CUSTOMER_DATA_ERROR_LOADING_MESSAGE":"<span class='red'> Bei der Verarbeitung der Daten für <b>{{firstName}} </b>ist ein Fehler aufgetreten. Der Fehler lautet: {{errorMessage}} </span><br/>",
    "RELOAD_CUSTOMER_DATA_SUCCESS_LOADING_MESSAGE":"<span class='success'> Datensätze wurden erfolgreich für <b>{{firstName}} </b></span><br/> verarbeitet",
    "RELOAD_RECONCILIATION_STATUS":"Versöhnungsstatus neu laden",
    "RELOAD_TOOLTIP_REVENUE":"Neu laden",
    "reLoadingCustomersProfileData":"Neuladen von Provider-Kundenprofilen",
    "REMOVE_ALL_SELECTED_RGS":"Möchten Sie alle ausgewählten Ressourcengruppen entfernen?",
    "REMOVE_DISTRIBUTOR_PRODUCT_ICON":"Entfernen",
    "REMOVE_PARTNER_PRODUCT_ICON":"Entfernen",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_HEADER":"Produkt entfernen",
    "REMOVE_SCHEDULED_ORDERED_PRODUCT_COMMENT_INSTRUCTION":"Sind Sie sicher, dass Sie das Produkt entfernen möchten?",
    "REMOVOKE_USER_ACCESS_PERMISSION_PROMPT":"Sind Sie sicher, dass Sie den Zugriff für diesen Benutzer widerrufen möchten?",
    "RENEWAL_ALL_DAYS":"Alles",
    "RENEWAL_DAYS_TEXT":"Tage",
    "REPORT_ACTIONS_DOWNLOAD_TEXT":"Herunterladen",
    "REPORT_ACTIONS_SEND_EMAIL_TEXT":"E-Mail senden",
    "REPORT_ACTIONS_TEXT":"Aktionen",
    "REPORT_ALL_CUSTOMERS_PLACEHOLDER":"Alle Kunden",
    "REPORT_FILE_INVALID_EMAILS_ERROR":"Bitte eine gültige Email eingeben",
    "REPORT_FILE_INVALID_EMAILS_REQUIRED":"Dieses Feld wird benötigt",
    "REPORT_FILE_TYPES_CSV_FORMAT":"CSV",
    "REPORT_FILE_TYPES_JSON_FORMAT":"JSON",
    "REPORT_FILE_TYPES_POPUP_SEND EMAIL_BUTTON_TEXT":"E-Mail senden",
    "REPORT_FILE_TYPES_SUB_HEADER":"Wählen Sie ein Dateiformat",
    "REPORT_FILE_TYPES_XML_FORMAT":"XML",
    "REPORT_INTERVAL_PLACEHOLDER_SELECT_PERIOD":"Zeitraum auswählen",
    "REPORT_INTERVAL_TEXT_CUSTOME":"BENUTZERDEFINIERT",
    "REPORT_INTERVAL_TEXT_LAST_12_MONTHS":"Letzte 12 Monate",
    "REPORT_INTERVAL_TEXT_LAST_3_MONTHS":"Letzte 3 Monate",
    "REPORT_INTERVAL_TEXT_LAST_6_MONTHS":"Letzte 6 Monate",
    "REPORT_INTERVAL_TEXT_TILL_DATE":"Bis zum Datum",
    "REPORT_INVOICE_AND_DUE_ACTIONS_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Rechnungs- und Gebührenbericht zu erhalten",
    "REPORT_PARTNER_OFFERS_USAGE_BACK_TO_UPLOAD":"Zurück zum Berichtsverbrauch",
    "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY":"Upload-Verlauf anzeigen",
    "REPORT_PARTNER_OFFERS_USAGE_VIEW_UPLOAD_HISTORY_HEADER":"Upload-Verlauf anzeigen",
    "REPORT_POPUP_ACTIONS_TEXT":"Aktionen",
    "REPORT_POPUP_DOWNLOAD_BUTTON_TEXT":"Herunterladen",
    "REPORT_POPUP_EMAIL_TEXT":"Email",
    "REPORT_POPUP_SELECT_ALL_CHECKBOX":"Wählen Sie Alle",
    "REPORT_POPUP_SEND_EMAIL_BUTTON_TEXT":"E-Mail senden",
    "REPORT_POPUP_SEND_EMAIL_PLACEHOLDER":"abc@spektra.com",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_ACTION_ICON_DESCRIPTION":"Wählen Sie eine Möglichkeit aus, um den Abonnementverlaufsbericht abzurufen",
    "REPORT_POPUP_SUBSCRIPTION_HISTORY_SEND_EMAIL_INSTRUCTION":"Bitte geben Sie die E-Mail-Adressen der Empfänger getrennt durch Kommas (,) oder Semikolons (;) ein und klicken Sie auf „Senden“, um eine Datei mit der Berichtsliste zum Abonnementverlauf zu erhalten.",
    "REPORT_SELECT_CUSTOMER_All":"Alle Kunden",
    "REPORT_SELECT_CUSTOMER_PLACEHOLDER":"Wählen Sie Kunde",
    "REPORT_SELECT_RESELLER_ALL":"Alle Wiederverkäufer",
    "REPORT_SEND_EMAIL_TEXT":"Email",
    "REPORT_TEXT_FROM":"Von",
    "REPORT_TEXT_TO":"Zu",
    "REPORT_USAGE_BUTTON_TEXT_FILTER_BY":"Filtern nach  :",
    "REPORT_USAGE_COST_TO_PARTNER_INSTRUCTION":"Kosten für den Partner sind erforderlich, wenn die Abrechnungsart „Prozentsatz“ lautet, und optional für die Abrechnungsart „Einheit“.",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_BUTTON_TEXT":"Katalog herunterladen",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_CATALOGUE":"Katalog",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_IMPORT":"Importieren",
    "REPORT_USAGE_DOWNLOAD_CATALOGUE_TITLE_TEMPLATE":"Schablone",
    "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS":"Herunterladen",
    "REPORT_USAGE_DOWNLOAD_LAST_UPLOADED_DETAILS_ERROR":"Beim Herunterladen der Datei ist ein Fehler aufgetreten",
    "REPORT_USAGE_DOWNLOAD_TEMPLATE_BUTTON_TEXT":"Vorlage herunterladen",
    "REPORT_USAGE_DOWNLOAD_UPLOADED_DETAILS_TOOLTIP_TEXT":"Upload-Verlauf herunterladen",
    "REPORT_USAGE_IMPORT_UPDATED_CATALOGUE_BUTTON_TEXT":"Katalog importieren",
    "REPORT_USAGE_INSTRUCTIONS_PART_1":"Laden Sie den Katalog herunter, um Kunden- und Produkt-IDs von nutzungsbasierten Partnerangeboten zu erhalten.",
    "REPORT_USAGE_INSTRUCTIONS_PART_2":"Laden Sie die Vorlage herunter und füllen Sie die Verwendungsdetails für Produkte im Katalog aus.",
    "REPORT_USAGE_INSTRUCTIONS_PART_3":"Stellen Sie sicher, dass die Daten die folgenden Regeln einhalten: <br />&nbsp;&nbsp <b>Einheiten</b> : Zahl <br />&nbsp;&nbsp <b>Maßeinheit</b> : Text <br />&nbsp; &nbsp <b>CostToPartner</b> : Dezimalzahl <br />&nbsp;&nbsp <b>CurrencyCode</b> : Text (ISO-Standard-Währungscode.) <br />&nbsp;&nbsp <b>UsageDate</b> : Datum (JJJJ-MM-TT)",
    "REPORT_USAGE_INSTRUCTIONS_PART_4":"Klicken Sie auf <strong class=\"text-uppercase\">Auswählen</strong> und suchen Sie nach der Datei.",
    "REPORT_USAGE_INSTRUCTIONS_PART_5":"Klicken Sie auf <strong class=\"text-uppercase\">Eingabe validieren</strong>, damit das System die Eingabe validiert.",
    "REPORT_USAGE_INSTRUCTIONS_PART_6":"Überprüfen Sie die Fehler in der Tabelle, nachdem die Validierung abgeschlossen ist.",
    "REPORT_USAGE_INSTRUCTIONS_PART_7":"Beheben Sie die Fehler in der Datei und laden Sie sie erneut hoch.",
    "REPORT_USAGE_INSTRUCTIONS_PART_8":"Wenn die Eingabe keine Validierungsfehler enthält, wird die Schaltfläche <strong class=\"text-uppercase\">Katalog importieren</strong> aktiviert. Klicken Sie auf die Schaltfläche, um Ihre Änderungen zu übermitteln.",
    "REPORT_USAGE_NOTE":"Hinweis :",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_ERROR":"Error",
    "REPORT_USAGE_STATUS_TABLE_FILTER_TEXT_SUCCESS":"Erfolg",
    "REPORT_USAGE_STATUS_TABLE_HEADER_COST_ON_PARTNER":"Kosten für Partner",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CURRENCY_CODE":"Währung",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_ID":"Kundennummer",
    "REPORT_USAGE_STATUS_TABLE_HEADER_CUSTOMER_NAME":"Kundenname",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_ERROR_DETAILS":"Fehler beim Importieren",
    "REPORT_USAGE_STATUS_TABLE_HEADER_IMPORT_STATUS":"Status des Imports",
    "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_ID":"Produkt ID",
    "REPORT_USAGE_STATUS_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNIT_OF_MEASURE":"Maßeinheit",
    "REPORT_USAGE_STATUS_TABLE_HEADER_UNITS":"Einheiten",
    "REPORT_USAGE_STATUS_TABLE_HEADER_USAGE_DATE":"Verwendungsdatum",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_ERROR_DETAILS":"Validierungsfehler",
    "REPORT_USAGE_STATUS_TABLE_HEADER_VALIDATION_STATUS":"Validierungsstatus",
    "REPORT_USAGE_VALIDATE_INPUT_BUTTON_TEXT":"Eingabe validieren",
    "REPORT_USER_LICENSE_ASSIGNMENT":"Der Bericht zur Benutzerlizenzzuweisung wird an die angegebene E-Mail-Adresse gesendet.",
    "reportusage":"Nutzung melden",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_1":"Um eine Reseller-Beziehung mit einem Kunden anzufordern, kopieren Sie den folgenden Text, einschließlich der URL, und fügen Sie ihn in eine E-Mail ein. Bearbeiten Sie den Text bei Bedarf und senden Sie die E-Mail an Ihren Kunden.",
    "REQUEST_A_RESELLER_RELATIONSHIP_HELP_LINE_2":"Schließen Sie delegierte Verwaltungsrechte für Azure Active Directory und Office 365 ein.",
    "REQUEST_A_RESELLER_RELATIONSHIP_LBL_EMAIL_TEXT":"E-Mail-Text",
    "REQUEST_A_RESELLER_RELATIONSHIP_SUBJECT_RESELLER_RELATIONSHIP_REQUEST":"Anfrage für Reseller-Beziehung",
    "REQUEST_FOR_BULK_ONBOARD_OF_CUSTOMERS_INITATED_BY":"Anfrage für Bulk Onboard initiiert von",
    "REQUEST_FOR_LOADING_PROMOTIONS_IS_QUEUED_SUCCESS_MESSAGE":"Die Anfrage zum Laden von Werbeaktionen befindet sich in der Warteschlange",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_QUICKBOOKS_INTIATED_BY":"Anforderung zum Hochladen von Rechnungen in Quickbooks, initiiert von",
    "REQUEST_FOR_UPLOAD_INVOICES_TO_XERO_INTIATED_BY":"Die Anfrage zum Hochladen von Rechnungen zu Xero wird von gestellt",
    "REQUEST_STATUS_CHANGE_OF_NI_OFFER":"Angeforderte Statusaktualisierung des NI-Angebots",
    "REQUEST_TO_UPLOAD_INVOICES_TO_QUICKBOOKS_ACCEPTED":"Anfrage zum Hochladen von Rechnungen in QuickBooks akzeptiert",
    "REQUEST_TO_UPLOAD_INVOICES_TO_XERO_ACCEPTED":"Anfrage zum Hochladen von Rechnungen zu Xero akzeptiert",
    "REQUESTED_PRODUCT_STATUS":"Neuer Status",
    "requestForResellerRelationship":"Anfrage für Reseller-Beziehung",
    "REQUIRED_FIELD_TEXT":"Dieses Feld wird benötigt",
    "REQUIRED_PATTERN_PHONE":"Erlaubt sind nur Zahlen und +",
    "REQUIRED_PATTERN_TEXT":"Sonderzeichen wie `~!@#{}|/<>' sind nicht erlaubt",
    "RESELER_INVOICE_LINE_ITEMS_EXPORTING_ERROR_MESSAGE":"Beim Exportieren der Rechnungspositionen des Wiederverkäufers ist ein Fehler aufgetreten",
    "RESELLER":"(Wiederverkäufer)",
    "Reseller":"Wiederverkäufer",
    "RESELLER_ADD_ADJUSTMENT_ADJUSTMENT":"Einstellung",
    "RESELLER_ADD_ADJUSTMENT_IS_TAXABLE":"Ist steuerpflichtig",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_ADD":"Hinzufügen",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_CHECKBOX_TEXT_SELECT_ALL":"Wählen Sie Alle",
    "RESELLER_ADD_PLAN_ADDONS_POPUP_HEADER_TEXT_ADDON_LIST":"Add-On-Liste",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_ADD_MORE_PRODUCTS":"Weitere Produkte hinzufügen",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_DELETE_PLAN":"Plan löschen",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_REVIEW_PLAN_OFFERS":"Planangebote überprüfen",
    "RESELLER_ADD_PLAN_BUTTON_TEXT_SAVE_PLAN":"Plan speichern",
    "RESELLER_ADD_PLAN_SEARCH_LABEL_TEXT_SEARCH_APPLIED":"Suche angewendet",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_HEADER_QUICK_SEARCH":"Schnelle Suche",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_CATEGORY":"Kategorie",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_PROVIDERS":"Anbieter",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_SERVICE_TYPES":"Servicetypen",
    "RESELLER_ADD_PLAN_SIDEBAR_FILTER_TEXT_TERM_DURATION":"Laufzeit",
    "RESELLER_ADD_PLAN_SUCCESS_PROMPT_HEADER_TEXT_SUCCESS":"Erfolg",
    "RESELLER_ADD_PLAN_SUCCESS_TEXT_PRICE_UPDATED_SUCCESSFULLY":"Preis erfolgreich aktualisiert",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_QUANTITY":"Menge",
    "RESELLER_ADD_PLAN_TAB_HEADER_TEXT_USAGE":"Verwendung",
    "RESELLER_ADD_PLAN_TEXT_INFO_NO_PRODUCTS_FOUND":"Keine Produkte gefunden",
    "RESELLER_AZURE_MACRO_MACRO_TYPE":"Makro",
    "RESELLER_AZURE_MACRO_PRICE_TYPE":"Preis",
    "RESELLER_AZURE_MACRO_VALUE":"Makrowert",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_RESELLER":"Wiederverkäufer",
    "RESELLER_BREADCRUMB_BUTTON_TEXT_REVENUE_AND_COST_SUMMARY":"Zusammenfassung der Einnahmen und Kosten des Wiederverkäufers",
    "RESELLER_BUTTON_TEXT_BACK_TO_LIST_OF_PROVIDER_RESELLERS":"Zurück zur Liste",
    "RESELLER_BUTTON_TEXT_BACK_TO_RESELLERS":"Zurück zu den Wiederverkäufern",
    "RESELLER_BUTTON_TEXT_LINK_NEW_PROVIDER":"Neuen Anbieter verknüpfen",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER":"Reseller hinzufügen",
    "RESELLER_BUTTON_TEXT_NEW_RESELLER_NEW":"Neu",
    "RESELLER_BUTTON_TEXT_ONBOARD_MULTIPLE_RESELLERS":"Bestehend (mehrfach)",
    "RESELLER_CAPTION_TEXT_ADD_RESELLER":"Reseller hinzufügen",
    "RESELLER_CAPTION_TEXT_CONTACT_DETAILS":"Kontaktdetails",
    "RESELLER_CAPTION_TEXT_PAYMENT_TYPE":"Zahlungsart",
    "RESELLER_CAPTION_TEXT_RESELLER":"Wiederverkäufer",
    "RESELLER_CAPTION_TEXT_RESELLER_BILLING_PROFILE":"Reseller-Abrechnungsprofil",
    "RESELLER_CAPTION_TEXT_RESELLER_DETAILS":"Reseller Details",
    "RESELLER_CAPTION_TEXT_RESELLER_PROFILE":"Reseller-Profil",
    "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DESC":"Aktivieren Sie diese Option, um Bearbeitungsgebühren auf Kreditkartenzahlungen anzuwenden.",
    "RESELLER_CONFIG_CONSIDER_CREDIT_CARD_PROCESSING_FEE_TEXT_DISP":"Kreditkartenbearbeitungsgebühr einschließen?",
    "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DESC":"Prozentuale Gebühr für die Kreditkartenabwicklung.",
    "RESELLER_CONFIG_CREDIT_CARD_PROCESSING_FEE_PERCENTAGE_TEXT_DISP":"Kreditkartenbearbeitungsgebühr %",
    "RESELLER_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCESEATS":"Durch Aktivieren dieses Flags wird verhindert, dass Kunden die Abonnementmenge reduzieren",
    "RESELLER_CONFIG_DESC_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Dem Wiederverkäufer erlauben, die Transaktion für seine Rechnung(en) zu initiieren",
    "RESELLER_CONFIG_DESC_APPLY_PROMOTION_TO_RESELLER":"Wählen Sie aus, ob die Werbeaktion für den Wiederverkäufer gelten soll oder nicht",
    "RESELLER_CONFIG_DESC_AZURE_APPLICABLE":"Ermöglichen Sie Kunden die Verwaltung von Azure-Abonnements",
    "RESELLER_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Ermöglichen Sie Kunden, Gruppen über Azure-Abonnements zu definieren",
    "RESELLER_CONFIG_DESC_AZURE_USAGEMULTIPLY_PERCENTAGE":"Markieren Sie (%), um es auf die Azure-Nutzung des Resellers anzuwenden. z.B: Geben Sie 1,15 ein, wenn der Aufschlag 15% betragen soll",
    "RESELLER_CONFIG_DESC_INTERNAL_BILLING_ID":"Zur internen Bezugnahme. Diese ID kann verwendet werden, um den Kunden mit einem Datensatz in Ihrem internen Buchhaltungssystem zu verknüpfen",
    "RESELLER_CONFIG_DESC_INVOICE_APPROVAL_DAY":"Tag des Monats, an dem Rechnungen automatisch genehmigt werden",
    "RESELLER_CONFIG_DESC_INVOICE_AUTO_PAYMENT_DAY":"Tag des Monats, an dem Zahlungen automatisch verarbeitet werden",
    "RESELLER_CONFIG_DESC_INVOICE_AUTOPAYMENT_DAY":"Tag des Monats, an dem Zahlungen automatisch verarbeitet werden",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAY":"Fälligkeitsdatum für Rechnungen",
    "RESELLER_CONFIG_DESC_INVOICE_DUE_DAYS":"Anzahl der für die Rechnungszahlung fälligen Tage",
    "RESELLER_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Tag des Monats, an dem Rechnungen automatisch abgeschlossen werden",
    "RESELLER_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Adresse automatisch als Standardadresse für den Wiederverkäufer festgelegt.",
    "RESELLER_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte E-Mail-Adresse automatisch als Standard-E-Mail-Adresse für den Wiederverkäufer festgelegt.",
    "RESELLER_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Telefonnummer automatisch als Standardtelefonnummer für den Wiederverkäufer festgelegt.",
    "RESELLER_CONFIG_DESC_ONETIME_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die einmaligen Rechnungsposten des Wiederverkäufers angewendet werden soll.",
    "RESELLER_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die unbefristeten Softwareabonnements des Wiederverkäufers angewendet werden soll",
    "RESELLER_CONFIG_DESC_PO_NUMBER":"Bestellnummer für Reseller definieren",
    "RESELLER_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Aktivieren Sie die Funktion zum Übertragen von Rechnungen an externe Buchhaltungsdienste.",
    "RESELLER_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Aktivieren Sie dieses Flag, wenn das System Zahlungen verarbeiten soll (CC/ACH, falls zutreffend).",
    "RESELLER_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Reseller-Azure-Reservierung angewendet werden soll.",
    "RESELLER_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Der Reseller erhält die E-Mail mit den Rechnungsdetails, sobald die Rechnung genehmigt wurde und wenn E-Mail-Benachrichtigungen aktiviert sind",
    "RESELLER_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Der Reseller erhält eine E-Mail-Benachrichtigung über Rechnungen mit Nullbeträgen",
    "RESELLER_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Reseller-Softwareabonnements angewendet werden soll.",
    "RESELLER_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Wir können diese Anfrage nicht bearbeiten, da Transaktionen deaktiviert sind.",
    "RESELLER_CONFIG_DESC_TAX_CODE":"Definieren Sie das Steuerkennzeichen für Wiederverkäufer",
    "RESELLER_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Drittanbieter-Abonnements des Wiederverkäufers anzuwenden ist.",
    "RESELLER_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCESEATS":"Ermöglichen Sie dem Kunden, Sitzplätze zu reduzieren",
    "RESELLER_CONFIG_DISP_ALLOW_RESELLER_TO_INITIATE_TRANSACTION":"Dem Wiederverkäufer erlauben, die Transaktion zu initiieren",
    "RESELLER_CONFIG_DISP_APPLY_PROMOTION_TO_RESELLER":"Bewerben Sie die Werbeaktion auf den Wiederverkäufer",
    "RESELLER_CONFIG_DISP_AZURE_APPLICABLE":"Azure aktivieren?",
    "RESELLER_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Azure für Unternehmen aktivieren?",
    "RESELLER_CONFIG_DISP_AZURE_USAGEMULTIPLY_PERCENTAGE":"Markieren Sie sich für die Azure-Verwendung",
    "RESELLER_CONFIG_DISP_INTERNAL_BILLING_ID":"Rechnungsreferenz-ID",
    "RESELLER_CONFIG_DISP_INVOICE_APPROVAL_DAY":"Tag, an dem Wiederverkäufer Rechnungen sehen können",
    "RESELLER_CONFIG_DISP_INVOICE_AUTO_PAYMENT_DAY":"Tag für die automatische Zahlung",
    "RESELLER_CONFIG_DISP_INVOICE_AUTOPAYMENT_DAY":"Tag für die automatische Zahlung",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAY":"Fälligkeitsdatum für Rechnungen",
    "RESELLER_CONFIG_DISP_INVOICE_DUE_DAYS":"Fälligkeitstage für Rechnungen",
    "RESELLER_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Tag, an dem die Rechnungen abgeschlossen sind",
    "RESELLER_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Weisen Sie automatisch eine neue Adresse als Standard zu",
    "RESELLER_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Weisen Sie automatisch eine neue E-Mail als Standard zu",
    "RESELLER_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Weisen Sie automatisch eine neue Telefonnummer als Standard zu",
    "RESELLER_CONFIG_DISP_ONETIME_MULTIPLY_PERCENTAGE":"Aufschlag für einmalige Rechnungsposten von Microsoft",
    "RESELLER_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag für unbefristete Software",
    "RESELLER_CONFIG_DISP_PO_NUMBER":"PO #",
    "RESELLER_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Buchen Sie Rechnungen an einen externen Buchhaltungsdienst",
    "RESELLER_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Rechnungen automatisch bezahlen?",
    "RESELLER_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
    "RESELLER_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Rechnungs-E-Mails automatisch senden",
    "RESELLER_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Senden Sie Nullbetragsrechnungen",
    "RESELLER_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Software-Abonnement",
    "RESELLER_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Stoppen Sie alle Transaktionen",
    "RESELLER_CONFIG_DISP_TAX_CODE":"Steuer-Code",
    "RESELLER_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Dritte",
    "RESELLER_CONFIG_IGNORE_FROM_PSA_TEXT_DISP":"Ignorieren Sie den Reseller vom externen Dienst",
    "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DESC":"Rechnungsnummern beginnen mit diesem Text",
    "RESELLER_CONFIG_INVOICE_NUMBER_TEXT_DISP":"Rechnungsnummerntext",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DESC":"Ermöglicht dem Benutzer, unbezahlte Beiträge bis zur aktuellen Rechnung anzuzeigen",
    "RESELLER_CONFIG_SHOW_UNPAID_DUES_IN_INVOICE_DISP":"Zeigen Sie unbezahlte Beiträge in der Rechnung an",
    "RESELLER_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Die Reseller-Konfiguration wurde erfolgreich zurückgesetzt",
    "RESELLER_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Reseller-Konfiguration erfolgreich gespeichert",
    "RESELLER_CONFIGURATION_NOTIFICATION_ACCESS_PERMISSION_DENIED":"Sie haben keinen Zugriff zum Bearbeiten",
    "RESELLER_CONFIGURATION_NOTIFICATION_UPDATED_RESELLER_CONFIGURATION":"Reseller-Konfiguration erfolgreich aktualisiert",
    "RESELLER_CONFIGURATION_OF":"Reseller-Konfiguration von",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_CONTENT":"Sehen Sie sich schnell Ihre Geschäftserlöse an",
    "RESELLER_DASHBOARD_BUSINESS_REVENUE_TILE_INTRO_TITLE":"Rentabilität",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_CONTENT":"Zusammenfassung Ihrer Forderungen",
    "RESELLER_DASHBOARD_CAPTION_ACCOUNTS_RECEIVABLE_TILE_INTRO_TITLE":"Debitorenbuchhaltung",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_CONTENT":"Zahlungen müssen noch eingezogen werden",
    "RESELLER_DASHBOARD_CAPTION_PAST_DUES_TILE_INTRO_TITLE":"Vergangene Gebühren",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_CONTENT":"Ihre aktiven Kunden",
    "RESELLER_DASHBOARD_CUSTOMER_TILE_INTRO_TITLE":"Kunden",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_CONTENT":"Benachrichtigung über eine ausstehende Rechnung",
    "RESELLER_DASHBOARD_INVOICE_TILE_INTRO_TITLE":"Rechnung",
    "RESELLER_EXISTS_IN_LOCAL_SYSTEM_WITH_MPN_ID_GIVEN":"Im System ist bereits ein Reseller mit der angegebenen mpn-ID vorhanden. Bitte überprüfen Sie die angegebene mpn-ID.",
    "RESELLER_HEADER_TEXT_BANK_DETAILS":"Bankdaten",
    "RESELLER_HEADER_TEXT_CREDIT_CARD_DETAILS":"Kreditkartendetails",
    "RESELLER_HEADER_TEXT_PLEASE_SELECT_PAYMENT_TYPE":"Bitte wählen Sie Zahlungsart",
    "RESELLER_IMPERSONATE":"Imitieren",
    "RESELLER_IMPERSONATE_USER_ROLE":"Imitieren",
    "RESELLER_IMPERSONATE_WITH_IMPERSONATOR_ROLE":"sich mit eigener Rolle ausgeben",
    "RESELLER_INVOICE":"Rechnung",
    "RESELLER_INVOICE_TEXT_LINE":"Die Rechnung wird an Ihre Rechnungsadresse gesendet. Vielen Dank!",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_INVOICE_STATUS_UPDATED_SUCCESSFULLY":"Rechnungsstatus erfolgreich aktualisiert",
    "RESELLER_INVOICELINE_ITEM_MESSAGE_PAYMENT_RECORDED_SUCCESSFULLY":"Zahlung erfolgreich erfasst",
    "RESELLER_INVOICES_BACK_TO_REVENUE_AAND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
    "RESELLER_INVOICES_BREADCRUM_HEADER_TEXT_INVOICES":"Rechnungen",
    "RESELLER_INVOICES_BREADCRUM_TEXT_INVOICES":"Rechnungen",
    "RESELLER_INVOICES_BUTTON_TEXT_BACK_TO_REVENUE_AND_COST_SUMMARY":"Zurück zur Umsatz- und Kostenübersicht",
    "RESELLER_INVOICES_HEADER_TEXT_INVOICES":"Rechnungen",
    "RESELLER_INVOICES_LINE_ITEMS_INVOICE_LINE_ITEMS":"Rechnungsposten",
    "RESELLER_LABEL_TEXT_ACCOUNT_NUMBER":"Kontonummer",
    "RESELLER_LABEL_TEXT_ACCOUNT_TYPE":"Konto Typ",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_1":"Anschrift Zeile 1",
    "RESELLER_LABEL_TEXT_ADDRESS_LINE_2":"Adresszeile 2",
    "RESELLER_LABEL_TEXT_BANK_NAME":"Bank Name",
    "RESELLER_LABEL_TEXT_CARD_NUMBER":"Kartennummer",
    "RESELLER_LABEL_TEXT_CITY":"Stadt",
    "RESELLER_LABEL_TEXT_COMPANY_NAME":"Name der Firma",
    "RESELLER_LABEL_TEXT_COUNTRY":"Land",
    "RESELLER_LABEL_TEXT_CREDIT_CARD_TYPE":"Kreditkartentyp",
    "RESELLER_LABEL_TEXT_CVV":"CVV",
    "RESELLER_LABEL_TEXT_EMAIL":"Email",
    "RESELLER_LABEL_TEXT_FIRST_NAME":"Vorname",
    "RESELLER_LABEL_TEXT_IS_BUSINESS_ACCOUNT":"Ist Geschäftskonto",
    "RESELLER_LABEL_TEXT_LAST_NAME":"Nachname",
    "RESELLER_LABEL_TEXT_MONTH":"Monat",
    "RESELLER_LABEL_TEXT_MPN_ID":"MPN-ID",
    "RESELLER_LABEL_TEXT_NAME":"Name",
    "RESELLER_LABEL_TEXT_NAME_ON_ACCOUNT":"Name auf Konto",
    "RESELLER_LABEL_TEXT_NAME_ON_CARD":"Name auf der Karte",
    "RESELLER_LABEL_TEXT_PHONE_NUMBER":"Telefonnummer",
    "RESELLER_LABEL_TEXT_PLAN_ID":"Planen",
    "RESELLER_LABEL_TEXT_ROUTING_NUMBER":"Routing-Nummer",
    "RESELLER_LABEL_TEXT_STATE":"Zustand",
    "RESELLER_LABEL_TEXT_YEAR":"Jahr",
    "RESELLER_LABEL_TEXT_ZIP":"Postleitzahl",
    "RESELLER_LIST_PAYMENT_METHOD":"Bezahlverfahren",
    "RESELLER_MICROSOFT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
    "RESELLER_MICROSOFT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Auf den Einstandspreis anzuwendender Gesamtaufschlag (%), um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
    "RESELLER_MICROSOFT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Software-Abonnement",
    "RESELLER_MICROSOFT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE_TEXT":"Markup für Drittanbieterabonnements",
    "RESELLER_NAME_UPDATE_FAILURE":"Die Aktualisierung des Resellernamens ist fehlgeschlagen",
    "RESELLER_NAME_UPDATE_SUCCESS":"Erfolgreiche Aktualisierung des Resellernamens",
    "RESELLER_NOT_ASSIGNED_TO_ANY_ACCOUNT_MANAGER":"Der Reseller ist keinem Account Manager zugeordnet",
    "RESELLER_NOT_EXISTS_IN_PC_WITH_MPN_ID_GIVEN":"Kein Reseller in CSP gefunden. Bitte überprüfen Sie die angegebene mpn-ID.",
    "RESELLER_ONBOARD_UPLOAD_DATA_INVALID_ERROR":"In den hochgeladenen Daten wurden Fehler festgestellt. Laden Sie die Fehler herunter, beheben Sie sie und versuchen Sie es erneut.",
    "RESELLER_ONBOARD_UPLOAD_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "RESELLER_ONBOARDING_ACH_BUSINESS_ACCOUNT_PROMPT":"Bitte geben Sie an, ob sich die ACH-Details auf ein Geschäftskonto beziehen",
    "RESELLER_ONBOARDING_LABEL_TEXT_ADD_MPN_ID_TOOLTIP":"MPN-ID des Wiederverkäufers",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_BILLING_EMAIL":"Abrechnung per E-Mail",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_COUNTRY":"Land",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_ID":"Ich würde",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_MPNID":"MPN-ID",
    "RESELLER_ONBOARDING_TABLE_HEADER_TEXT_RESELLER_NAME":"Name",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_HEADER_SUCCESS":"Erfolg",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_DETAILS_SAVED":"Zahlungsdetails gespeichert",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_DELETED":"Zahlungsprofil gelöscht",
    "RESELLER_PAYMENT_PROFILE_MESSAGE_PAYMENT_PROFILE_SET_TO_DEFAULT":"Zahlungsprofil Auf Standard setzen",
    "RESELLER_PAYMENT_PROFILE_PROMPT_DELETE_PAYMENT_OPTION":"Möchten Sie diese Zahlungsoption wirklich löschen?",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MACRO_AND_SAVE_PLAN_WITH_ALL_OFFERS":"Makro definieren und mit allen Angeboten speichern",
    "RESELLER_PLAN_BUTTON_TEXT_ADD_MISSING_OFFERS":"Fehlende Angebote ergänzen",
    "RESELLER_PLAN_BUTTON_TEXT_SAVE_PLAN_WITH_ALL_OFFERS":"Plan mit allen Angeboten speichern",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_BILLING_CYCLES":"Ausgewählte Abrechnungszeiträume",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_CATEGORIES":"Ausgewählte Kategorien",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDER_CATEGORIES":"Ausgewählte Anbieterkategorien",
    "RESELLER_PLAN_DETAILS_INFO_TEXT_SELECTED_PROVIDERS":"Ausgewählte Anbieter",
    "RESELLER_PLAN_DETAILS_LABEL_SUPPORTED_MARKETS":"Unterstützte Märkte",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_CATEGORIES":"Kategorien",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_FOR_USAGE_MACRO":"Verwendungsmakro",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO":"Makro",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_FOR_USAGE_VALUE":"Verwendungsmakrowert",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_MACRO_VALUE":"Makrowert",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDER_CATEGORIES":"Anbieterkategorien",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_PROVIDERS":"Anbieter",
    "RESELLER_PLAN_DETAILS_LABEL_TEXT_SERVICE_TYPE":"Servicetypen",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CLONE_PLAN":"Anfrage zum Klonen des Reseller-Plans wird angenommen und bearbeitet",
    "RESELLER_PLAN_DETAILS_NOTIFICATION_SUCCESSFULLY_PLACED_REQUEST_TO_CREATE_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Die Anfrage zur Erstellung eines neuen Reseller-Plans mit allen Angeboten wird angenommen und bearbeitet",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING":"Preisfestlegung",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_DROPDOWN_DEFAULT_OPTION":"Wählen",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_APPLY_MACRO_TO_DEFINE_THE_NEW_PRICES":"Makro anwenden, um die neuen Preise zu definieren",
    "RESELLER_PLAN_DETAILS_PRICE_SETTING_OPTION_TEXT_COPY_PRICES_FROM_SOURCE_RESELLER_PLAN":"Kopieren Sie die Preise aus dem Source Reseller Plan",
    "RESELLER_PLAN_DETAILS_SUCCESSFULLY_SUBMITTED_TEXT":"Ihre Änderungen wurden erfolgreich übermittelt",
    "RESELLER_PLAN_DISABLE_BUTTON":"Deaktivieren Sie den Plan",
    "RESELLER_PLAN_ENABLE_BUTTON":"Aktivieren Sie den Plan",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_SUCCESSFULLY_MESSAGE":"Das Angebot wurde erfolgreich eingestellt",
    "RESELLER_PLAN_OFFERS_DISCONTINUE_TOOLTIP_TEXT":"Stellen Sie das Angebot ein",
    "RESELLER_PLAN_SELECTED_OFFERS":"Ausgewählte Angebote",
    "RESELLER_PLANS_BREADCRUMB_BUTTON_TEXT_PLANS":"Pläne",
    "RESELLER_PLANS_BUTTON_TEXT_CANCEL_SAVE_PLAN_WITH_ALL_OFFERS":"Stornieren",
    "RESELLER_PLANS_BUTTON_TEXT_CLONE_PLAN":"Klonplan",
    "RESELLER_PLANS_BUTTON_TOOLTIP_PLAN_CREATION_IS_IN_PROGRESS":"Plan erstellen",
    "RESELLER_PLANS_CAPTION_1":"Neuen Plan hinzufügen",
    "RESELLER_PLANS_CAPTION_2":"Details zum Plan -",
    "RESELLER_PLANS_CAPTION_TEXT_PLANS":"Pläne",
    "RESELLER_PLANS_TABLE_ACTIONS_BUTTON_TEXT_VIEW":"Aussicht",
    "RESELLER_PRICING_AND_OFFERS_BREADCRUMB_BUTTON_TEXT":"Preise und Angebote",
    "RESELLER_PRICING_AND_OFFERS_BUTTON_TEXT_DOWNLOAD_PRICING_AND_OFFERS_LIST":"Laden Sie die Preis- und Angebotsliste herunter",
    "RESELLER_PRICING_AND_OFFERS_CAPTION_TEXT":"Preise und Angebote",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_ERP_PRICE":"ERP-Preis",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LICENSE_AGREEMENT_TYPE":"Art der Lizenzvereinbarung",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_LIST_PRICE":"Listenpreis",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_OFFER_NAME":"Angebotsname",
    "RESELLER_PRICING_AND_OFFERS_HEADER_TEXT_VALID_FROM":"Gültig ab",
    "RESELLER_PRICING_AND_OFFERS_LIST_EXPORTING_ERROR_MESSAGE":"Beim Exportieren der Preisliste und der Angebotsliste für Wiederverkäufer ist ein Fehler aufgetreten",
    "RESELLER_PROFILE_BREADCRUMB_BUTTON_PROFILE":"Organisationsprofil",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_CANCEL":"Stornieren",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_DELETE":"Löschen",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_EDIT":"Bearbeiten",
    "RESELLER_PROFILE_BUTTON_TOOLTIP_TEXT_SAVE":"speichern",
    "RESELLER_PROFILE_CAPTION_TEXT_RESELLER_POFILE":"Profil",
    "RESELLER_PROFILE_CUSTOMER_PROFILE":"Kundenprofil",
    "RESELLER_PROFILE_PARTNER_ADD_ADDRESS":"Adresse hinzufügen",
    "RESELLER_PROFILE_PARTNER_ADD_EMAIL":"E-Mail hinzufügen",
    "RESELLER_PROFILE_PARTNER_ADD_PHONE":"Telefon hinzufügen",
    "RESELLER_PROFILE_PARTNER_ADDRESS":"Adressen",
    "RESELLER_PROFILE_PARTNER_BASIC_DETAILS":"Grundlegende Details",
    "RESELLER_PROFILE_PARTNER_CANCEL":"Stornieren",
    "RESELLER_PROFILE_PARTNER_COMPANY_NAME":"Name der Firma",
    "RESELLER_PROFILE_PARTNER_EMAIL_ADDRESSES":"E-mailadressen",
    "RESELLER_PROFILE_PARTNER_FIRST_NAME":"Vorname",
    "RESELLER_PROFILE_PARTNER_LAST_NAME":"Nachname",
    "RESELLER_PROFILE_PARTNER_MESSAGE_BASIC_DETAILS_UPDATED_SUCCESSFULLY":"Grundlegende Details erfolgreich aktualisiert",
    "RESELLER_PROFILE_PARTNER_NO_RECORDS_FOUND!":"Keine Aufzeichnungen gefunden!",
    "RESELLER_PROFILE_PARTNER_PHONE_NUMBERS":"Telefonnummern",
    "RESELLER_PROFILE_PARTNER_PROMPT_SURE_YOU_WANT_TO_DELETE_THIS_RECORD":"Möchten Sie diesen Datensatz wirklich löschen?",
    "RESELLER_PROFILE_PARTNER_SUBMIT":"einreichen",
    "RESELLER_PROFILE_PAYMENT_ADD":"Hinzufügen",
    "RESELLER_PROFILE_PAYMENT_BANK_ACCOUNT":"Bankkonto",
    "RESELLER_PROFILE_PAYMENT_CREDIT_CARD":"Kreditkarte",
    "RESELLER_PROFILE_PAYMENT_NO_PROFILES_FOUND_ADD_A_NEW_PYMENT_PROFILE_BY_CLICKING_ON_THE_ADD_BUTTON":"Keine Profile gefunden. Fügen Sie ein neues Zahlungsprofil hinzu, indem Sie auf die Schaltfläche HINZUFÜGEN klicken",
    "RESELLER_PROFILE_PAYMENT_PAYMENT_PROFILES":"Zahlungsprofile",
    "RESELLER_PROFILE_PROVIDER_ACCOUNT_CREATION_DATE":"Erstellungsdatum des Kontos:",
    "RESELLER_PROFILE_PROVIDER_BILLING_ADDRESS":"Rechnungsadresse",
    "RESELLER_PROFILE_PROVIDER_C3_ONBOARDING_DATE":"C3 Onboarding-Datum",
    "RESELLER_PROFILE_PROVIDER_RESELLER_PROFILE":"Reseller-Profil",
    "RESELLER_PROFILE_PROVIDER_SHIPPING_ADDRESS":"Lieferanschrift",
    "RESELLER_PROFILE_PROVIDER_USER_ACCOUNTS":"Benutzerkonten",
    "RESELLER_PROFIT_BILLED_AMOUNT":"Dem Wiederverkäufer in Rechnung gestellter Betrag",
    "RESELLER_PROFIT_COST_ON_PARTNER":"Kosten für den Händler",
    "RESELLER_PROFIT_COST_ON_RESELLER":"Kosten für den Wiederverkäufer",
    "RESELLER_PROFIT_CUSTOMER_NAME":"Kundenbetrag",
    "RESELLER_PROFIT_DISTRIBUTOR_NAME":"Verkäufer Name",
    "RESELLER_PROFIT_PRODUCT_NAME":"Produktname",
    "RESELLER_PROFIT_PROFIT_AMOUNT":"Vertriebsgewinn",
    "RESELLER_PROFIT_RESELLER_BILLED_AMOUNT":"Vom Wiederverkäufer in Rechnung gestellter Betrag",
    "RESELLER_PROFIT_RESELLER_NAME":"Verkäufer Name",
    "RESELLER_PROFIT_SUMMARY_REPORT":"Bericht herunterladen",
    "RESELLER_PROFIT_SUMMARY_REPORT_NO_DATA":"Keine Daten zum Download verfügbar",
    "RESELLER_PROFIT_SUMMARY_VIEW_ALL":"Alles",
    "RESELLER_PROFIT_SUMMARY_VIEWBY_RESLLER":"Ansicht durch Wiederverkäufer",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT":"Wiederverkäufergewinn",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_PROFIT_PERCENTAGE":"Vertriebsgewinn %",
    "RESELLER_PROFIT_TABLE_HEADER_TEXT_RESELLER_PROFIT_PERCENTAGE":"Reseller-Gewinn %",
    "RESELLER_RADIO_BUTTON_TEXT_NO":"Nein",
    "RESELLER_RADIO_BUTTON_TEXT_YES":"Ja",
    "RESELLER_REGISTRATION_SUCCESS":"Reseller-Registrierung erfolgreich abgeschlossen.",
    "RESELLER_RELATIONSHIP_LBL":"Bitte teilen Sie dem Kunden den folgenden Inhalt mit, um eine Reseller-Beziehung aufzubauen",
    "RESELLER_REVENUE_AND_COST_SELECT_RESELLER":"Reseller auswählen",
    "RESELLER_REVENUE_AND_COST_SUMMARY_VIEWBY":"Gesehen von",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_ENTER_THE_VALID_TAX_PERCENTAGE":"Bitte geben Sie den gültigen Steuerprozentsatz ein",
    "RESELLER_SETTING_ADD_AND_EDIT_TAX_PERCENTAGE_WARNING_TEXT_PLEASE_FILL_THE_FIELDS_TAX_NAME":"Bitte füllen Sie die Felder Steuername aus",
    "RESELLER_SETTINGS_EMAILNOTIFICATIONS_ADD_NEW_EVENT_EMAIL_NOTIFICATION":"Neue Ereignis-E-Mail-Benachrichtigung hinzufügen",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_DELETED_SUCCESSFULLY":"Partner-Tag erfolgreich gelöscht",
    "RESELLER_SETTINGS_PARTNER_TAGS_NOTIFICATION_MESSAGE_PARTNER_TAG_UPDATED_SUCCESSFULLY":"Partner-Tag erfolgreich aktualisiert",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THERE_ARE_NO_PROVIDERS_CONFIGURED_PLEASE_CONTACT_SUPPORT":"Es sind keine Anbieter konfiguriert. Bitte wenden Sie sich an den Support",
    "RESELLER_SETTINGS_PROVIDERS_LBL_TEXT_THESE_ARE_NO_KEYS_AVILABLE_FOR_THIS_PROVIDER":"Für diesen Anbieter sind keine Schlüssel verfügbar",
    "RESELLER_SUBSCRIPTIONS_TABLE_TD_BUTTON_TOOLTIP_TEXT_QUICK_IMPERSONATION":"Wiederverkäufer: Schneller Identitätswechsel",
    "RESELLER_SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Für den gewählten Währungscode wurde kein Markt gefunden",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_CONFIGURATION":"Reseller-Konfiguration",
    "RESELLER_TABLE_BUTTON_TEXT__RESELLER_SETTING":"Reseller-Einstellung",
    "RESELLER_TABLE_BUTTON_TEXT_ASSIGN_OFFERS":"Angebote zuweisen",
    "RESELLER_TABLE_BUTTON_TEXT_EDIT":"Bearbeiten",
    "RESELLER_TABLE_BUTTON_TEXT_IMPERSONATE":"Imitieren",
    "RESELLER_TABLE_BUTTON_TEXT_MANAGE_LINK_RESELLER":"Provider-Links verwalten",
    "RESELLER_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "RESELLER_TABLE_HEADER_TEXT_CREATED_ON":"Erstellt am",
    "RESELLER_TABLE_HEADER_TEXT_NAME":"Name",
    "RESELLER_TABLE_SIGNUP_DATE":"Anmeldedatum",
    "resellers":"Wiederverkäufer",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_PDF":"Rechnung",
    "RESELLERS_BUTTON_TEXT_DOWNLOAD_INVOICE_RECON_REPORT":"Versöhnung",
    "RESELLERS_BUTTON_TEXT_PROCESS_TEST_PAYMENT":"Test Charge Reseller",
    "RESELLERS_NOTIFICATION_ADDED_RESELLER_SUCCESSFULLY":"Reseller erfolgreich hinzugefügt.",
    "RESELLERS_TABLE_BUTTON_TEXT_RESELLER_PAYMENTS":"Reseller-Zahlungen",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_ACCOUNT_MANAGER_DETAILS":"Account Manager Details",
    "RESELLERS_TABLE_TD_BUTTON_TOOLTIP_TEXT_MANAGE_PLANS":"Pläne verwalten",
    "RESEND_EMAIL_RECEPIENTS_LABEL_DESCRIPTION":"E-Mails",
    "RESEND_INVOICE":"Rechnung erneut senden",
    "RESEND_INVOICE_HEADER_TEXT":"Bitte geben Sie E-Mail-IDs ein, um die Rechnung erneut zu senden",
    "RESEND_INVOICE_NOTE":"<b>Hinweis:</b>Wenn Sie die Rechnung erneut an mehrere E-Mail-IDs senden möchten, geben Sie diese bitte durch Komma an. Z.B: abc@mail.com, new@mail.com",
    "RESEND_INVOICE_USERS_LIST":"Liste der Rechnungsbenutzer erneut senden",
    "RESERVED_INSTANCES_LABEL_TEXT_RETAIL_PRICE":"Endverbraucherpreis (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_COST_TO_PARTNER":"Kosten für Partner (%)",
    "RESERVED_INSTANCES_LABLE_TEXT_SALE_PRICE":"Verkaufspreis (%)",
    "RESERVED_INSTANCES_VALIDATION_MESSAGE_REQUIRED__VALUE":"Geben Sie einen gültigen RI-Prozentsatz ein",
    "RESET_VALUE":"Wert zurücksetzen",
    "Resolved":"Aufgelöst",
    "RESOURCE_GROUP_NAME":"Name der Ressourcengruppe",
    "RETRY_INSTANT_PAY_INVOICE_PAYMENT_REQUEST_SUCCESS":"Die Zahlung wurde erfolgreich eingeleitet. Die Verarbeitung wird einige Zeit dauern. Bitte überprüfen Sie den Status nach einiger Zeit.",
    "REVENUE_AND_COST_SUMMARY_BILLED_AMOUNT":"Abgerechneter Betrag",
    "REVENUE_AND_COST_SUMMARY_BUTTON_NOTIFY_WITH_ZIP_OF_INVOICES":"E-Mail alle Rechnungen",
    "REVENUE_AND_COST_SUMMARY_CATEGORY":"Kategorie",
    "REVENUE_AND_COST_SUMMARY_COST_ON_PARTNER":"Kosten für Partner",
    "REVENUE_AND_COST_SUMMARY_CUSTOMER":"Kunde",
    "REVENUE_AND_COST_SUMMARY_DISTRIBUTOR_INVOICE":"Händlerrechnung",
    "REVENUE_AND_COST_SUMMARY_PROVIDER":"Anbieter",
    "REVENUE_AND_COST_SUMMARY_RESELLER":"Wiederverkäufer",
    "REVENUE_AND_COST_SUMMARY_SALETYPE":"SaleType",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_ACCOUNTS_PAYABLE":"Gebühren",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_INVOICE_TRANSACTIONS":"Rechnungen und Gebühren",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS":"Zahlungen",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_FAILURES_TEXT":"Fehler",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_PAYMENTS_TEXT":"Überfällig",
    "REVENUE_AND_COST_SUMMARY_TAB_HEADING_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
    "REVENUE_AND_COST_SUMMARY_VIEWBY":"Gesehen von",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_COMPLETED":"Rechnungserstellung abgeschlossen",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_FAILED_ALERT":"Beim Generieren der Kundenrechnungen ist ein Fehler aufgetreten",
    "REVENUE_AND_COST_SUMMERY_INVOICE_GENERATION_INITIATED_SUCCESS_ALERT":"Kundenrechnungen erfolgreich generiert",
    "REVENUE_BTN_TEXT_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters",
    "REVENUE_BUSY_MESSAGE_LOADING_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters werden geladen...",
    "REVENUE_BUTTON_TOOLTIP_TEXT_REGENERATE_INVOICE":"Rechnung neu generieren",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_INVOICES":"Rechnungen anzeigen",
    "REVENUE_BUTTON_TOOLTIP_TEXT_VIEW_SUBSCRIPTION_HISTORY":"Abonnementverlauf anzeigen",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_AMOUNT":"Menge",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_BILLING_PERIOD":"Abrechnungszeitraum",
    "REVENUE_BY_CUSTOMER_GRAPH_AXIS_LABLE_CUSTOMER":"Kunde",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_COST_ON_PARTNER":"Kosten für Partner",
    "REVENUE_BY_CUSTOMER_GRAPH_LABEL_PROFIT_AMOUNT":"Gewinnbetrag",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_CUSTOMER":"Kosten für den Kunden",
    "REVENUE_BY_CUSTOMER_TEXT_COST_TO_PARTNER":"Kosten für den Partner",
    "REVENUE_BY_CUSTOMER_TEXT_PROFIT":"Profitieren",
    "REVENUE_TITLE_NOTIFY_WITH_ZIP_OF_INVOICES":"Erhalten Sie alle Rechnungen per E-Mail",
    "REVENUE_TITLE_PROVIDER_PAYMENT_DETAILS":"Zahlungsdetails des Anbieters",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CATEGORIES_FILTER_LABEL_TEXT":"Kategorie",
    "REVENUE_VERSUS_COST_BY_CATEGORY_CUSTOMERS_FILTER_LABEL_TEXT":"Kunde",
    "REVENUE_VERSUS_COST_BY_CATEGORY_DURATION_FILTER_LABEL_TEXT":"Dauer",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD":"Letzter Abrechnungszeitraum",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS":"6 Abrechnungszeiträume",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS":"3 Abrechnungszeiträume",
    "REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS":"12 Abrechnungszeiträume",
    "REVOKE_POLICY_ASSIGNMENT_NOTIFIER":"Sind Sie sicher, dass Sie die Zuordnung widerrufen möchten?",
    "RFRESH_DATA_FROM_PSA":"Daten aus PSA aktualisieren",
    "RIBBON_TITLE_DEPARTMENT":"Abt",
    "RIGISTER_CUSTOMER_SHOULD_ENTER_QUANTIY":"Bitte geben Sie die Menge für mindestens ein Top-Level-Planangebot ein.",
    "ROLE_ASSIGNMENT_EXCEPTION":"Füllen Sie die Benutzerdetails aus",
    "ROLE_ASSIGNMENT_SCOPE_TITLE":"Rollenzuweisungsbereiche",
    "ROLE_NAME_C3_SUPPORT":"C3-Unterstützung",
    "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER":"Kunden-Account-Manager",
    "ROLE_NAME_CUSTOMER_ACCOUNT_MANAGER_DESCRIPTION":"Der Benutzer kann auf Karten und Menüs mit Produkten zugreifen",
    "ROLE_NAME_CUSTOMER_ADMIN":"Kundenadministrator",
    "ROLE_NAME_CUSTOMER_ADMIN_DESCRIPTION":"Der Benutzer kann alle im Kundenportal verfügbaren Aktionen anzeigen und ausführen",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES":"Kundenverwaltung (für den Verkauf)",
    "ROLE_NAME_CUSTOMER_ADMIN_FOR_SALES_DESCRIPTION":"Kundenverwaltung (für den Verkauf)",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE":"Kundenverwaltung Lite",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_DESCRIPTION":"Der Benutzer hat alle Berechtigungen des Kundenadministrators, kann jedoch keine neuen Abonnements erwerben",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS":"Kundenadministrator Lite Plus",
    "ROLE_NAME_CUSTOMER_ADMIN_LITE_PLUS_DESCRIPTION":"Der Benutzer hat alle Berechtigungen von Customer Admin Lite, kann jedoch keine Produkte mit einer Laufzeit von 1 Jahr oder mehr verwalten.",
    "ROLE_NAME_CUSTOMER_MAINTENANCE":"Kundenwartung",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_DESCRIPTION":"Kundenwartung",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES":"Eine Rolle zum Verwalten der App durch den Kunden",
    "ROLE_NAME_CUSTOMER_MAINTENANCE_NOTES_DESCRIPTION":"Eine Rolle zum Verwalten der App durch den Kunden",
    "ROLE_NAME_CUSTOMER_READER":"Kundenleser",
    "ROLE_NAME_CUSTOMER_READER_DESCRIPTION":"Der Benutzer kann nur die im Kundenportal verfügbaren Daten lesen",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN":"Kunden-Super-Admin",
    "ROLE_NAME_CUSTOMER_SUPER_ADMIN_DESCRIPTION":"Kunden-Super-Admin",
    "ROLE_NAME_DEPARTMENT_ADMIN":"Abteilungsadministrator",
    "ROLE_NAME_DEPARTMENT_ADMIN_DESCRIPTION":"Wer kann die Abteilung verwalten und darf nur Produkte verwalten, die den spezifischen Abteilungen zugeordnet sind.",
    "ROLE_NAME_DEPARTMENT_READER":"Abteilungsleser",
    "ROLE_NAME_DEPARTMENT_READER_DESCRIPTION":"Wer kann nur die unter den spezifischen Abteilungen verfügbaren Daten lesen?",
    "ROLE_NAME_DIRECT_ADMIN":"Direkter Administrator",
    "ROLE_NAME_FINANCE_ADMIN":"Finanzadministrator",
    "ROLE_NAME_FINANCE_ADMIN_DESCRIPTION":"Kann Seiten im Zusammenhang mit Finanzen verwalten.",
    "ROLE_NAME_GROUP_ADMIN":"Gruppenadministrator",
    "ROLE_NAME_HELP_DESK":"Beratungsstelle",
    "ROLE_NAME_HELP_DESK_DESCRIPTION":"Beratungsstelle",
    "ROLE_NAME_INDIRECT_ADMIN":"Indirekter Administrator",
    "ROLE_NAME_PARTNER_MAINTENANCE":"Partnerpflege",
    "ROLE_NAME_PARTNER_MAINTENANCE_NOTES":"Eine Rolle zum Verwalten der App durch den Partner",
    "ROLE_NAME_SITE_ADMIN":"Seite Admin",
    "ROLE_NAME_SITE_ADMIN_DESCRIPTION":"Wer kann die Site verwalten und kann nur Produkte unter den spezifischen Sites verwalten?",
    "ROLE_NAME_SITE_READER":"Seite Reader",
    "ROLE_NAME_SITE_READER_DESCRIPTION":"Wer kann nur die auf der Website verfügbaren Daten lesen?",
    "ROLE_NAME_SYSTEM_ADMIN":"System Administrator",
    "ROLE_NAME_SYSTEM_ADMIN_DESCRIPTION":"Kann auf alle Seiten im Portal zugreifen/diese ändern.",
    "ROLE_NAME_SYSTEM_READER":"Systemleser",
    "ROLE_NAME_SYSTEM_READER_DESCRIPTION":"Kann alle Seiten besuchen, aber er kann keine Änderungen vornehmen.",
    "ROLE_NAME_USER":"Benutzer",
    "ROLE_NAME_USER_ADMIN":"Benutzerverwaltung",
    "ROLE_NOTE_C3_SUPPORT":"Der C3-Support kann Mengeninkongruenzsituationen beheben und ist eine Reihe von Berechtigungen",
    "ROLE_NOTE_CUSTOMER_ADMIN":"Der Kundenadministrator kann alle Daten anzeigen und alle Aktionen im Kontext des Mandanten ausführen",
    "ROLE_NOTE_CUSTOMER_ADMIN_FOR_SALES":"Der Kundenadministrator (für den Verkauf) kann Aktionen für Daten sehen und ausführen, aber nicht für Partnerabonnements",
    "ROLE_NOTE_CUSTOMER_READER":"Kundenleser, die nur Lesezugriff auf alle mit dem Kunden verbundenen Daten haben.",
    "ROLE_NOTE_DIRECT_ADMIN":"Direkter Administrator, wäre in der Lage, alles zu tun, was ein Systemadministrator tun kann, aber nur für die direkten Kunden, also kein Zugriff auf die Sell Indirect-Teile",
    "ROLE_NOTE_FINANCE_ADMIN":"Finanzadministratoren können auf die Umsatz- und Kostenübersicht der Kunden zugreifen, Steuer% definieren und den Abstimmungsbericht anzeigen",
    "ROLE_NOTE_GROUP_ADMIN":"GroupAdmins kann alle mit Azure-Abonnements verbundenen Dinge in seiner Gruppe verwalten.",
    "ROLE_NOTE_HELP_DESK":"Helpdesk-Benutzer können alle Kundendaten anzeigen, neue Kunden einbeziehen und Support leisten. Helpdesk-Benutzer können sich als Kundendaten ausgeben und darauf zugreifen",
    "ROLE_NOTE_INDIRECT_ADMIN":"Indirekter Administrator, wäre in der Lage, alles zu tun, was ein Systemadministrator tun kann, aber nur für die Wiederverkäufer (und ihre Kunden), also kein Zugriff auf die Sell Direct-Teile.",
    "ROLE_NOTE_SYSTEM_ADMIN":"Der Systemadministrator kann alle Daten anzeigen und alle Aktionen sowohl im Kontext des Partners als auch des Kunden ausführen",
    "ROLE_NOTE_SYSTEM_READER":"Systemleser, die nur Lesezugriff auf alle Daten des Partners haben. Leser können sich nicht ausgeben",
    "ROLE_NOTE_USER_ADMIN":"Der Benutzeradministrator kann einige Aktionen für Daten sehen und ausführen, aber nicht für Abonnements",
    "RowNum":"Zeilennummer",
    "SALE":"Verkauf",
    "SALE_PRICE_EDIT_TOOLTIP_TEXT":"Verkaufspreis bearbeiten",
    "SALE_PRICE_EMPTY_ERROR":"Bitte geben Sie einen gültigen Preis ein",
    "SALE_PRICE_GREATER_THAN_ERP_PRICE":"Verkaufspreis höher als ERP-Preis",
    "SALE_PRICE_LESS_THAN_LIST_PRICE":"Verkaufspreis kleiner als der Listenpreis",
    "SALE_TYPE_DESC_PRODUCT":"Produkt",
    "SALE_TYPE_DESC_SERVICE":"Service",
    "SalePrice":"Verkaufspreis",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_QUICKBOOKS":"Es wird versucht, den von Quickbooks erhaltenen Authentifizierungscode zu speichern",
    "SAVE_AUTHENTICATION_CODE_RECIEVED_FROM_XERO":"Versuch, den von Xero erhaltenen Authentifizierungscode zu speichern",
    "SAVE_CART_EXCEPTION":"Beim Hinzufügen des Produkts zum Warenkorb ist ein Fehler aufgetreten",
    "SAVE_COUPON_FAILURE":"Gutschein konnte nicht gespeichert werden",
    "SAVE_COUPON_SUCCESS":"Gutschein erfolgreich gespeichert",
    "SAVE_CURRENCY_CONVERSION_EXCEPTION":"Beim Speichern der Währungsumrechnung ist ein Fehler aufgetreten",
    "SAVE_FAVOURITE_COLUMN_NOTIFIER":"Ausgewählte Spalten erfolgreich als Favoriten gespeichert.",
    "SAVE_OR_UPDATE_EMAIL_NOTIFICATION_RECIPIENTS_LIST_EXCEPTION":"Beim Speichern oder Aktualisieren der Empfängerliste für E-Mail-Benachrichtigungen ist ein Fehler aufgetreten",
    "SAVE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Speichern der Steuerdetails ist ein Fehler aufgetreten",
    "SAVE_PLAN_PRODUCT_SLAB":"Gespeicherte Vertragsangebotsplatten",
    "SAVE_PLAN_PRODUCT_SLAB_FAILURE":"Vertragsangebotsplatten konnten nicht gespeichert werden",
    "SAVE_PLAN_SUCCESS_MESSAGE_TEXT":"Plan erfolgreich erstellt",
    "SAVE_PROVIDER_PAYMENT_DETAILS_CONFIRMATION_MESSAGE":"Zahlungsdetails des Anbieters erfolgreich gespeichert.",
    "SAVE_RESELLER_PAYMENT_PROFILE":"Speichern Sie das Reseller-Zahlungsprofil",
    "SAVE_RESELLER_PLAN":"Reseller-Plan speichern",
    "SAVE_RESELLER_PLAN_FAILURE":"Reseller-Plan konnte nicht gespeichert werden",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY":"Definieren des Produktwährungskurses des Resellerplans",
    "SAVE_RESELLER_PLAN_PRODUCTS_IN_TARGET_CURRENCY_FAILURE":"Fehler beim Definieren des Produktwährungskurses des Resellerplans",
    "SAVE_SUB_TAX":"Untersteuer speichern",
    "SAVE_SUB_TAX_FAILURE":"Speichern der Untersteuer fehlgeschlagen",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_SAVESUPPORTTICKET":"Neues Ticket erfolgreich erstellt",
    "SAVESUPPORTTICKETSUPPORTCONTROLLER_UPDATESUPPORTTICKET":"Ticket erfolgreich aktualisiert",
    "SCHEDULE_AN_ORDER_BUTTON_TEXT":"Planen Sie eine Bestellung",
    "SCHEDULE_DATE_VALIDATION_ERROR_MESSAGE":"Bitte wählen Sie ein Datum",
    "SCHEDULE_ORDER_CHECKBOX_TEXT":"Ich bin damit einverstanden, eine Bestellung für einen späteren Zeitpunkt zu planen",
    "SCHEDULE_ORDER_DATE_PLACEHOLDER_TEXT":"Wählen Sie ein Datum aus",
    "SCHEDULE_ORDER_EVENT":"Reihenfolge einplanen",
    "SCHEDULE_ORDER_FAILED_EVENT":"Der Zeitplanauftrag ist fehlgeschlagen",
    "SCHEDULE_REPORT_NOTIFICATION_CREATE_DATE":"Erstellungsdatum",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE":"Empfängertyp",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_TYPE_VALUE":"Empfängerwert",
    "SCHEDULE_REPORT_NOTIFICATION_RECIPIENT_VALUE":"Empfänger",
    "SCHEDULE_REPORT_NOTIFICATION_RECORD_ID":"Datensatz-ID",
    "SCHEDULE_REPORT_NOTIFICATION_REPORT_NAME":"Berichtsname",
    "SCHEDULE_REPORT_NOTIFICATION_RESULT":"Ergebnis",
    "SCHEDULE_REPORT_NOTIFICATION_STATUS":"Status",
    "SCHEDULED_ACTION_DELETE_BUTTON_PLACEHOLDER_TEXT":"Löschen",
    "SCHEDULED_ACTION_EDIT_BUTTON_PLACEHOLDER_TEXT":"Bearbeiten",
    "SCHEDULED_INVOICE_LINE_ITEM_REPORT":"Geplanter Rechnungspositionsbericht",
    "SCHEDULED_INVOICE_LINE_ITEM_REPORT_DELIVERY":"Geplante Rechnungszeilenberichtszustellung",
    "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT":"Bericht zu geplanten Rechnungspositionen mit Provision",
    "SCHEDULED_INVOICE_LINE_ITEMS_WITH_COMMISSION_REPORT_DELIVERY":"Geplante Rechnungspositionen mit Provisionsberichtszustellung",
    "SCHEDULED_INVOICE_SUMMARY_REPORT":"Geplanter Rechnungszusammenfassungsbericht",
    "SCHEDULED_INVOICE_SUMMARY_REPORT_DELIVERY":"Geplante Rechnungszusammenfassungsberichtszustellung",
    "SCHEDULED_POPUP_BUTTON_TEXT_CANCEL":"Stornieren",
    "SCHEDULED_POPUP_BUTTON_TEXT_DELETE":"Löschen",
    "SCHEDULED_PROFITABILITY_REPORT":"Geplanter Rentabilitätsbericht",
    "SCHEDULED_PROFITABILITY_REPORT_DELIVERY":"Geplante Zustellung von Rentabilitätsberichten",
    "SCHEDULED_RENEWAL_CHANGES":"Geplante Verlängerungsänderungen",
    "SCHEDULED_RENEWAL_CHANGES_CONFIRM_MESSAGE_TEXT_PROMOTION_APPLY_OR_NOT":"Möchten Sie die Aktion auf dieses Produkt anwenden?",
    "SCHEDULED_RENEWAL_CHANGES_LBL_TEXT_WAITING_FOR_APPROVAL":"warten auf die Bestätigung",
    "SCHEDULED_RENEWAL_STATUS_ACTIVE":"Aktiv",
    "SCHEDULED_RENEWAL_STATUS_CANCELLED":"Abgesagt",
    "SCHEDULED_RENEWAL_STATUS_COMPLETED":"Vollendet",
    "SCHEDULED_RENEWAL_STATUS_FAILED":"Gescheitert",
    "SCHEDULED_RENEWAL_STATUS_ONHOLD":"In Wartestellung",
    "SCHEDULED_RENEWALS_BUTTON_TEXT_BACK_TO_BASIC_DETAILS":"Zurück zu den grundlegenden Details",
    "SCHEDULED_RENEWALS_HEADER_TEXT":"Geplante Verlängerungen",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ACTIONS":"Aktionen",
    "SCHEDULED_RENEWALS_TABLE_HEADER_ALIGN_END_DATE":"Enddatum ausrichten",
    "SCHEDULED_RENEWALS_TABLE_HEADER_CANCEL_REASON":"Stornierungsgrund",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_BILLING_CYCLE":"Rechnungskreislauf",
    "SCHEDULED_RENEWALS_TABLE_HEADER_NEW_SKU":"Neue Artikelnummer",
    "SCHEDULED_RENEWALS_TABLE_HEADER_QUANTITY":"Menge",
    "SCHEDULED_RENEWALS_TABLE_HEADER_SCHEDULED_DATE":"Geplantes Datum",
    "SCHEDULED_RENEWALS_TABLE_HEADER_STATUS":"Status",
    "SCHEDULED_RENEWALS_TABLE_HEADER_TERM":"Begriff",
    "SCHEDULED_RENEWALS_TABLE_HEADER_TERMS_AND_CONDITIONS":"AGB",
    "SCHEDULED_RENEWALS_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptiert",
    "SCHEDULED_REPORT_INVOICE_LINE_ITEM":"Rechnungsposition",
    "SCHEDULED_REPORT_INVOICE_LINE_ITEMS_WITH_COMMISSION":"Rechnungspositionen mit Provision",
    "SCHEDULED_REPORT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
    "SCHEDULED_REPORT_PROFITABILTY":"Rentabilitätsbericht",
    "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENT_TYPE":"Empfängertyp",
    "SCHEDULED_REPORT_RUN_HISTORY_RECIPIENTS":"Empfänger",
    "SCHEDULED_REPORT_RUN_HISTORY_REPORT":"Bericht",
    "SCHEDULED_REPORT_TAX_LINE_ITEMS":"Steuerposten",
    "SCHEDULED_REPORT_TAX_SUMMARY":"Steuerübersicht",
    "SCHEDULED_TAX_LINE_ITEMS_REPORT":"Bericht zu geplanten Steuerpositionen",
    "SCHEDULED_TAX_LINE_ITEMS_REPORT_DELIVERY":"GeplanteSteuerpositionenBerichtLieferung",
    "SCHEDULED_TAX_SUMMARY_REPORT":"Geplanter Steuerzusammenfassungsbericht",
    "SCHEDULED_TAX_SUMMARY_REPORT_DELIVERY":"Geplante Zustellung des zusammenfassenden Steuerberichts",
    "SCHEDULNG_LODING_MESSAGE_TEXT":"Das Laden des berechtigten Angebots aus dem Partner Center dauert normalerweise etwa 30 Sekunden. Bitte aktualisieren Sie die Seite nicht.",
    "SCORE":"Punktzahl",
    "SEARCH_ACTIVITY_LOGS_TEXT_ACTION_NAME":"Suche nach Ereignis",
    "SEARCH_AUDITLOG_TEXT_ACTION_NAME":"Suchaktionen",
    "SEARCH_AUDITLOG_TEXT_CUSTOMER_NAME":"Suche nach Kundennamen",
    "SEARCH_AUDITLOG_TEXT_DOMAIN_NAME":"Suche nach Domainnamen",
    "SEAT_LIMIT_EXCEED":"Sitzplatzlimit überschritten",
    "SELECT_A_SOURCE_CURRENCY":"Quellwährung auswählen",
    "SELECT_A_SUBSCRIPTION_TEXT":"Wählen Sie ein Abonnement aus",
    "SELECT_AGENT":"Agent",
    "SELECT_ALL_CUSTOMER":"Alle Kunden",
    "SELECT_ALL_RESELLER":"Alle Wiederverkäufer",
    "SELECT_ALL_RG":"Möchten Sie alle Ressourcengruppen auswählen?",
    "SELECT_COUPON":"Gutschein auswählen",
    "SELECT_CURRENCY_CODE_TOOLTIP_TEXT":"Währungscode auswählen",
    "SELECT_CUSTOMER":"Wählen Sie Kunde",
    "SELECT_DATE_RANGE":"Datumsbereich auswählen",
    "SELECT_DATE_VALUE":"Datum auswählen",
    "SELECT_DEFAULT_OPTION_SELECT":"Wählen",
    "SELECT_DEFAULT_OPTION_SELECT_CUSTOMER":"Wählen Sie Kunde",
    "SELECT_DEFAULT_OPTION_SELECT_EVENT":"Wählen Sie Ereignis",
    "SELECT_DEPARTMENT":"Abteilung auswählen",
    "SELECT_DEPARTMENTS":"Abteilung auswählen",
    "SELECT_EDU_SEGMENT":"Wählen Sie das Bildungssegment",
    "SELECT_EVENT":"Wählen Sie Ereignis",
    "SELECT_MESSAGE_TYPE":"Nachrichtentyp auswählen",
    "SELECT_MINUMUN_INVOICE_NOTIFICATION":"Bitte wählen Sie mindestens eine zu zahlende Rechnung aus",
    "SELECT_OFFER_QUICK_PRICING_MESSAGE":"Schnelle Optionen zum Definieren Ihres Verkaufspreises",
    "SELECT_PARTNER_INVOICE":"Partnerrechnungen",
    "SELECT_PLACEHOLDER_DROPDOWN_EVENT":"Wählen Sie Ereignis",
    "SELECT_PLAN_TOOLTIP_TEXT":"Plan auswählen",
    "SELECT_PORTAL_PAGE":"Portalseite auswählen",
    "SELECT_PRIORITY":"Wählen Sie Priorität",
    "SELECT_PRODUCT_AREA":"Wählen Sie Produktbereich",
    "SELECT_RESELLER_AZURE_MACRO":"Auswählen eines Makros",
    "SELECT_RESELLER_AZURE_MACRO_TYPE":"Makrotyp auswählen",
    "SELECT_RESELLER_AZURE_SALE_PRICE_TYPE":"Verkaufspreistyp auswählen",
    "SELECT_SITE":"Standort auswählen",
    "SELECT_SITES":"Wählen Sie Standort aus",
    "SELECT_STATUS":"Status auswählen",
    "SELECT_TARGET_CURRENCY_PLACEHOLDER":"Zielwährung auswählen",
    "SELECT_TYPE":"Art auswählen",
    "selectAddressType":"Adresstyp auswählen",
    "selectCountry":"Land auswählen",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_QUICKBOOKS":"Ausgewählte Kunden zum Hochladen von Rechnungen in Quickbooks",
    "SELECTED_CUSTOMERS_FOR_UPLOADING_INVOICES_TO_XERO":"Ausgewählte Kunden zum Hochladen von Rechnungen auf Xero",
    "SELECTED_FILTER":"Filter auswählen",
    "SELECTED_FILTER_ALL":"Wählen Sie Alle",
    "selectEmailType":"E-Mail-Typ auswählen",
    "selectPhoneType":"Telefontyp auswählen",
    "selectState":"Bundesland auswählen",
    "SELL_DIRECT_ICON_TITLE":"Stockholm-icons / Einkaufen / Box2",
    "sell-direct":"Kunden",
    "sell-indirect":"Indirekt verkaufen",
    "SEND_EMAIL_FOR_PRODUCT_CATALOGUE_TEXTAREA_TEXT":"Geben Sie die E-Mail-Adresse ein, an die Sie eine E-Mail senden möchten, fügen Sie ein Komma (,) oder ein Semikolon (;) ein und klicken Sie auf „Senden“, um eine Datei mit einer Produktkatalogliste zu erhalten",
    "SEND_INVOICE_IN_EMAIL_BUTTON_TEXT":"Rechnung erneut senden",
    "SEND_INVOICE_TO_GIVEN_USERS_POPUP_BUTTON_TEXT":"Senden",
    "SEND_MAIL_BUTTON":"Mail senden",
    "SEPTEMBER":"September",
    "SERVICE_PROVIDER_CUSTOMER_DOMAIN_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"Kundendomäne des Anbieters",
    "SERVICE_PROVIDER_CUSTOMER_ID_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerId",
    "SERVICE_PROVIDER_CUSTOMER_NAME_OF_A_CUSTOMER_UNDER_BULK_ONBOARD":"ServiceProviderCustomerName",
    "SERVICE_PROVIDER_TENANT":"Anbieter Mieter",
    "SERVICE_PROVIDER_TENANT_DEFAULT_CUSTOMER":"Standardmieter",
    "SERVICE_PROVIDER_TENANT_HEADER":"Anbieter Mieter von",
    "SERVICE_PROVIDER_TENANT_LABEL_ACTION":"Aktionen",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_ID":"Mieter-ID",
    "SERVICE_PROVIDER_TENANT_LABEL_TENANT_NAME":"Name des Mieters",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_CONFIRM":"Möchten Sie <b>{{customerName}} </b>als Standardmandanten markieren?",
    "SERVICE_PROVIDER_TENANT_UPDATE_DEFAULT_VALUE_SUCCESS":"{{customername}} als Standardmandant aktualisiert",
    "SESSION_EXPIRED_ERROR_MESSAGE":"Deine Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, um fortzufahren. Leider gehen alle nicht gespeicherten Änderungen verloren.",
    "SESSION_TIMEOUT_WARNING":"Warnung zur Sitzungsüberschreitung",
    "SESSION_WARNING_DURATION":"Ihre Sitzung läuft in {{secondsLeft}} Sekunden ab.",
    "SET_NEW_MACRO":"Neues Makro setzen",
    "SET_STATUS_OF_BULK_ONBOARD_CUSTOMERS_TO_COMPLETE":"Der Stapelstatus von Bulk Onboard-Kunden wird auf \\''Abgeschlossen\\'' gesetzt",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED":"Es wurde versucht, den Status der externen Servicepost-Protokolle auf \\''Fehlerhaft\\'' zu setzen",
    "SET_STATUS_OF_EXTERNALSERVICE_LOGS_TO_ERRORIGNORED_FAILED":"Fehler beim Festlegen des Status externer Service-Post-Protokolle auf ErrorIgnored",
    "SETTING_HEADING_TEXT_EMAIL_TEMPLATE":"E-Mail-Vorlage",
    "settings":"die Einstellungen",
    "SETTINGS_BREADCRUMB_BUTTON_TEXT_EVENT_EMAIL_NOTIFICATION":"E-Mail-Benachrichtigungen für Ereignisse",
    "SETTINGS_HEADING_TEXT_BILLING":"Abrechnung",
    "SETTINGS_HEADING_TEXT_CONNECTWISE_SETTINGS":"ConnectWise-Verwaltungseinstellungen",
    "SETTINGS_HEADING_TEXT_CURRENCY_CONVERSION":"Währungsumrechnung",
    "SETTINGS_HEADING_TEXT_CUSTOMER_DEFAULTS":"Kundenvorgaben",
    "SETTINGS_HEADING_TEXT_DELETE_PLAN":"Plan löschen",
    "SETTINGS_HEADING_TEXT_EVENT_EMAIL_NOTIFICATION":"E-Mail Benachrichtigungen",
    "SETTINGS_HEADING_TEXT_GLOBAL_CUSTOMER_CONFIGURATION":"Kundenkonfigurationen (global)",
    "SETTINGS_HEADING_TEXT_PARTNER_DEFAULTS":"Partner-Standardeinstellungen",
    "SETTINGS_HEADING_TEXT_USER_MANAGEMENT":"Benutzerverwaltung",
    "SETTINGS_LOGO_SELECT_A_LOGO":"Wählen Sie ein Logo",
    "SETTINGS_PASSWORD_HELP_TEXT":"Lassen Sie das Feld leer, wenn Sie das Passwort nicht aktualisieren möchten.",
    "SETTINGS_TAB_AUTOTASK_CONFIGURATION":"Automatische Aufgabe",
    "SETTINGS_TAB_HEADING_CONNECTWISE":"ConnectWise-Verwaltung",
    "SETTINGS_TAB_HEADING_CUSTOM_VIEWS":"Benutzerdefinierte Ansichten",
    "SETTINGS_TAB_HEADING_GENERAL_SETTINGS_TEXT":"Allgemeines",
    "SETTINGS_TAB_HEADING_PARTNER_LOGOS_TEXT":"Logos",
    "SETTINGS_TAB_HEADING_PARTNER_SETTINGS_TEXT":"Partner Center",
    "SETTINGS_TAB_HEADING_PROVIDERS_SETTINGS_TEXT":"Anbieter",
    "SETTINGS_TAB_HEADING_PUBLIC_SIGNUP":"Öffentliche Anmeldung",
    "SETTINGS_TAB_HEADING_SMTP_SETTINGS_TEXT":"SMTP",
    "SETTINGS_TAX_NAME_TOOLTIP":"Sie können einen beliebigen Steuernamen angeben, z. B.: GST.",
    "SETTINGS_TAX_PERCENTAGE_TOOLTIP":"Hier können Sie den Steuerprozentsatz angeben. ZB: 15 für 15%",
    "SHIP_TO":"Ausliefern",
    "SHOP_ADDED_TO_CART_TEXT":" Zum Warenkorb hinzugefügt",
    "SHOP_PAGE_BUTTON_TEXT_ADD_TO_CART":"in den Warenkorb legen",
    "SHOP_PAGE_INFO_TEXT_NO_PRODUCTS":"Keine Produkte gefunden!",
    "SHOP_PAGE_LINK_TEXT_READ_MORE":"Weiterlesen..",
    "SHOP_TILE_HYPERLINK_READ_MORE":"Weiterlesen..",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL":"Kundenkonfigurationseinstellung im Kundenportal anzeigen",
    "SHOW_CUSTOMER_CONFIGURATION_SETTING_IN_CUSTOMER_PORTAL_DESC":"Ermöglichen Sie dem Kunden, Konfigurationen zu verwalten",
    "SHOW_NON_ACTIVE_OFFERS":"Behinderte Angebote anzeigen",
    "SHOW_NOT_ONBOARDED_SUBSCRIPTION_VALIDITY_IN_CUSTOM_END_DATE":"N / A",
    "SHOW_OFFERS_WHICH_ARE_NOT_AVAILABLE_FOR_CUSTOMER":"Angebote anzeigen, die für den Kunden nicht verfügbar sind",
    "SHOW_OFFERS_WHICH_HAS_NO_MACRO":"Angebote anzeigen, die kein Makro haben",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL":"Reseller-Konfigurationseinstellung im Reseller-Portal anzeigen",
    "SHOW_RESELLER_CONFIGURATION_SETTING_IN_RESELLER_PORTAL_DESC":"Ermöglichen Sie dem Reseller, Konfigurationen zu verwalten",
    "SIDEBAR_ACCOUNT_MANAGERS_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Benutzer hinzufügen",
    "SIDEBAR_ADMINISTRATION_ICON_TITLE":"Stockholm-Ikonen / Kommunikation / Gruppe",
    "SIDEBAR_ADMINISTRATION_MANAGE_ICON_TOOLTIP_TEXT":"Stockholm-icons-/-Allgemein-/-Benachrichtigung2",
    "SIDEBAR_ANALYZE_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Rechner",
    "SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Azurblauer Rand",
    "SIDEBAR_CONTENT_ALL_COMMENTS":"Ermöglicht dem Partner, die von Kunden für verschiedene Entitäten (Warenkorbartikel, Rechnungen, Bestellungen) bereitgestellten Kommentare zu überprüfen und darauf zu antworten",
    "SIDEBAR_CONTENT_MENU_ACCOUNT_MANAGERS":"Liste der Kundenbetreuer und Optionen zur Verwaltung der ihnen zugeordneten Kunden.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION":"Verwalten Sie Web-Benachrichtigungen auf Entitätsebene",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Erstellen und verwalten Sie benutzerdefinierte Benachrichtigungen für verschiedene Produkt-/Lizenzverwaltungsereignisse und Kunden-Onboarding.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_ENGAGE":"Teilen Sie Echtzeitinformationen mit Partnern und Kunden durch interaktive Übertragungen auf ihren jeweiligen Dashboards.",
    "SIDEBAR_CONTENT_MENU_ADMINISTRATION_MANAGE":"Erstellen und verwalten Sie Web-Benachrichtigungen auf verschiedenen Entitätsebenen, um über den Systemzustand, Rechnungen und andere wichtige Benachrichtigungen zu informieren",
    "SIDEBAR_CONTENT_MENU_AZURE_ADVISOR":"Azure Advisor ist eine auf Empfehlungen basierende Funktion, die Partnern dabei hilft, die besten Empfehlungen zur Kostenoptimierung zu übernehmen.",
    "SIDEBAR_CONTENT_MENU_AZURE_BILLING_REPORT":"Bericht über die Azure-Rechnungsdaten",
    "SIDEBAR_CONTENT_MENU_AZURE_MARGIN":"Damit können Sie den Azure-Plan-Markup für vergangene Monate aktualisieren und Abrechnungsdaten mit neuen Markup-Werten laden.",
    "SIDEBAR_CONTENT_MENU_AZURE_SUBACRIPTION":"Überprüfen Sie die Verbrauchsdetails Ihres Azure-Abonnements.",
    "SIDEBAR_CONTENT_MENU_COST_SUMMARY_REPORT":"Kurzer Blick auf den Kostenübersichtsbericht",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_ANALYTICS":"Schneller Blick auf Kundenanalysen",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Liste der vom Mandanten verbrauchten Lizenzen anzeigen",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_MICROSOFT_REPORT":"Beschreiben Sie die Verwendung von AZURE",
    "SIDEBAR_CONTENT_MENU_CUSTOMER_PROFILE":"Verwalten Sie Ihr Organisationsprofil, Adressen, Zahlungsprofile und mehr...",
    "SIDEBAR_CONTENT_MENU_DASHBOARD":"Es vermittelt den Partnern ein umfassendes Verständnis der Kunden und ihres Geschäfts in verschiedenen Dimensionen.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CHANGE":"Überprüfen Sie Ihre Lizenzänderungen über benutzerdefinierte Daten hinweg.",
    "SIDEBAR_CONTENT_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Kurzer Überblick über die Zusammenfassung des Lizenzverbrauchs",
    "SIDEBAR_CONTENT_MENU_LICENSE_SUMMARY_REPORT":"Schneller Überblick über die Lizenzvergabe und die nicht zugewiesenen Plätze.",
    "SIDEBAR_CONTENT_MENU_MANAGE_AZURE_ENTITLEMENTS":"Verwalten und erstellen Sie mehrere Azure-Berechtigungen (Abonnements) im Rahmen des Azure-Plans in großen Mengen",
    "SIDEBAR_CONTENT_MENU_ONBOARDED_CUSTOMERS_REPORT":"Schneller Blick auf die Kunden-Onboarding-Analysen Ihrer indirekten Wiederverkäufer.",
    "SIDEBAR_CONTENT_MENU_PARTNER_COMMISSION_REPORT":"Detaillierte Berichterstattung über die Verkaufsprovision auf Agentenebene und den verdienten Betrag",
    "SIDEBAR_CONTENT_MENU_PARTNER_COUPON":"Definieren Sie Rabattgutscheine, die auf Plan-/Kunden-/Serviceebene angewendet werden können.",
    "SIDEBAR_CONTENT_MENU_PARTNER_DOCUMENTATION":"Praktischer Produktleitfaden für Partner und Endkunden",
    "SIDEBAR_CONTENT_MENU_PARTNER_OFFER_USAGE_REPORT":"Ermöglicht Partnern das Anzeigen und Herunterladen von Nutzungsdetails für Produkte, die außerhalb von C3 Solution aufgezeichnet wurden.",
    "SIDEBAR_CONTENT_MENU_PARTNER_UPLOAD":"Ermöglicht Partnern das Hochladen von Produktcodes/-attributen, die in Rechnungspostenberichten erwünscht sind.",
    "SIDEBAR_CONTENT_MENU_PENDING_PURCHASE_REQUESTS":"Ermöglicht dem Partner, Produktkaufanfragen von Kunden zu genehmigen/abzulehnen.",
    "SIDEBAR_CONTENT_MENU_PRODUCT_COMMISSIONS":"Laden Sie Werte für Agentennamen, mit Verkäufen verbundene Produkte, Provisionsprozentsätze und verschiedene andere Provisionsattribute hoch",
    "SIDEBAR_CONTENT_MENU_PRODUCT_EXTENSIONS":"Hilft Ihnen beim Erstellen benutzerdefinierter Spalten, die Ihrem Rechnungspostenbericht hinzugefügt werden.",
    "SIDEBAR_CONTENT_MENU_REPORT_USAGE":"Ermöglicht dem Partner das Hochladen von außerhalb von C3 aufgezeichneten Nutzungsdetails für Produkte im Katalog zur Rechnungserstellung.",
    "SIDEBAR_CONTENT_MENU_REPORTS_FOR_CUSTOMER":"Bietet mehrere Berichte über Ihre Daten in C3.",
    "SIDEBAR_CONTENT_MENU_RESELLER_PLAN":"Hilft Ihnen beim Verwalten (Erstellen/Bearbeiten) von Plänen mit Angeboten, die Sie an indirekte Wiederverkäufer verkaufen möchten, indem Sie Ihre benutzerdefinierten Margen festlegen.",
    "SIDEBAR_CONTENT_MENU_SCHEDULED_REPORTS":"Mithilfe dieser Funktion können Partner verschiedene Berichte in unterschiedlichen Abständen planen.",
    "SIDEBAR_CONTENT_MENU_USAGE_REPORT":"Überprüfen Sie die Nutzung Ihres Azure-Abonnements mit den damit verbundenen Kosten.",
    "SIDEBAR_CONTENT_MENU_USERS":"Verwalten Sie den Zugriff auf Benutzer",
    "SIDEBAR_CONTENT_MENUS_ANALYZE":"Analysieren Sie die Rentabilität Ihres Unternehmens in mehreren Dimensionen.",
    "SIDEBAR_CONTENT_MENUS_AZURE_ESTIMATE":"Zeigen Sie den Azure-Verbrauch auf Ressourcengruppen-/Ressourcen-/Tag-Ebene an.",
    "SIDEBAR_CONTENT_MENUS_AZURE_GROUPS":"Gruppieren Sie ein oder mehrere Abonnements und definieren Sie Budgets und Schwellenwerte",
    "SIDEBAR_CONTENT_MENUS_CUSTOM_OFFERS":"Hilft Ihnen bei der Verwaltung (Erstellung/Bearbeitung) von Produkten/Diensten von Drittanbietern und Ihren eigenen Beratungs-/Beratungs-/Unterstützungsdiensten und -angeboten.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_DASHBOARD":"Führt zum Dashboard",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_INVOICES":"Greifen Sie über den Partner auf Ihre Rechnungen zu",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT":"Ermöglicht Ihnen den Zugriff auf Funktionen, die für Microsoft CSP-Mandanten relevant sind, die mit Ihren Kunden verknüpft sind.",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_MICROSOFT_USERS":"Listen Sie alle Azure AD-Benutzer von Kunden auf und weisen Sie ihnen kundenseitige RBAC-Rollen zu",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_ORDERS":"Verfolgen Sie Ihre Bestellungen",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_PRODUCTS":"Zeigen Sie die gekauften Produkte an",
    "SIDEBAR_CONTENT_MENUS_CUSTOMER_SHOP":"Kaufen und kaufen Sie Angebote aus dem Katalog",
    "SIDEBAR_CONTENT_MENUS_DISTRIBUTOR_OFFERS":"Funktion, die es indirekten Anbietern (Distributoren) ermöglicht, ihre IP-Lösungen zu erstellen und über indirekte Wiederverkäufer an Endkunden zu verkaufen.",
    "SIDEBAR_CONTENT_MENUS_ENTITY_INVOICES":"Rechnungen und Zahlungen verwalten",
    "SIDEBAR_CONTENT_MENUS_PARTNER_BUNDLES":"Ermöglicht es Ihnen, zwei oder mehr Angebote (Microsoft- und/oder Partnerangebote) zu kombinieren und Ihren Kunden zum Kauf zur Verfügung zu stellen",
    "SIDEBAR_CONTENT_MENUS_PARTNER_CUSTOMERS":"Führt zur Liste der aktiven Kunden und Optionen zum Verwalten ihrer Einstellungen.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_DASHBOARD":"Bringt Sie zur Dashboard-Seite",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Überprüfen Sie den Status der vom System ausgelösten Zahlungen.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_PLANS":"Hilft Ihnen bei der Erstellung eines Angebotskatalogs (Produkte/Dienstleistungen) und lässt Sie Ihre benutzerdefinierten Margen als Verkaufspreis für Ihre Kunden definieren.",
    "SIDEBAR_CONTENT_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Gibt Ihnen einen Überblick über Ihre Rentabilität und überprüft die Rechnungen im Detail.",
    "SIDEBAR_CONTENT_MENUS_RESELLER_DASHBOARD":"Führt zum Dashboard",
    "SIDEBAR_CONTENT_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Gibt Ihnen einen Überblick über Ihre Rentabilität und einen Blick auf die Rechnungsdetails",
    "SIDEBAR_CONTENT_MENUS_RESELLERS":"Führt zur Liste der aktiven Reseller und Optionen zur Verwaltung ihres CSP-Portfolios.",
    "SIDEBAR_CONTENT_MENUS_SELL":"Listet die Funktionen auf, mit denen Sie Ihre Verkaufsangebote definieren können",
    "SIDEBAR_CONTENT_MENUS_SELL_DIRECT":"<b>Direktabrechnungspartner (Ebene 1)</b> – Listet alle Funktionen auf, die Ihnen bei der Verwaltung Ihrer Kundenangebote und Preise helfen.",
    "SIDEBAR_CONTENT_MENUS_SELLER_INDIRECT":"<b>Indirekter Anbieter (Ebene 2)</b> – Listet die Funktionen auf, die indirekten Anbietern helfen, ihre indirekten Wiederverkäufer zu verwalten.",
    "SIDEBAR_CONTENT_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"Greifen Sie auf Funktionen zu, die nur für die C3-Support-Rolle gelten.",
    "SIDEBAR_COST_SUMMARY_REPORT_ICON_TITLE":"Stockholm-icons-/-Design-/-Union",
    "SIDEBAR_CUSTOMERS_MICROSOFT_USER_ICON_TITLE":"Stockholm-icons-/-Communication-/-Group",
    "SIDEBAR_CUSTOMERS_PARTNER_USER_ICON_TITLE":"Stockholm-icons-/-Communication-/-Group",
    "SIDEBAR_LICENSE_CHANGE_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
    "SIDEBAR_LICENSE_CONSUMPTION_SUMMARY_REORT_ICON_TITLE":"Stockholm-icons-/-Design-/-Union",
    "SIDEBAR_LICENSE_SUMMARY_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
    "SIDEBAR_ONBOARDED_CUSTOMERS_REPORT_ICON_TOOLTIP_TEXT":"Stockholm-icons / Einkaufen / Chart-line1",
    "SIDEBAR_PARTNER_BUNDLE_ICON_TITLE":"Stockholm-Symbole-/-Dateien-/-Gruppen-Ordner",
    "SIDEBAR_PARTNER_COUPON_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Ticket",
    "SIDEBAR_PARTNER_CUSTOM_OFFER_ICON_TITLE":"Stockholm-Icons-/-Shopping-/-Geschenk",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_BILLING_REPORT_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
    "SIDEBAR_PARTNER_MICROSOFT_AZURE_SUBSCRIPTION_ICON_TITLE":"Stockholm-Symbole-/-Home-/-Taste",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
    "SIDEBAR_PARTNER_PENDING_PAYMENT_STATUS_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Ikonen / Einkaufen / Geld",
    "SIDEBAR_PARTNER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
    "SIDEBAR_PARTNER_REVENUE_COST_SUMMARY_ICON_TITLE":"Stockholm-Symbole-/-Einkaufen-/-Geld",
    "SIDEBAR_PENDING_PURCHASE_REQUEST_ICON_TITLE":"Stockholm-Symbole-/-Navigation-/-Doppelcheck",
    "SIDEBAR_PRODUCT_COMMISSION_ICON_TOOLTIP_TEXT":"Stockholm-Ikonen / Einkaufen / Geld",
    "SIDEBAR_PRODUCT_EXTENSION_ICON_TOOLTIP_TEXT":"Stockholm-Icons-/-Design-/-Komponente",
    "SIDEBAR_RESELLER_PARTNER_USER_ICON_TITLE":"Stockholm-Ikonen / Kommunikation / Gruppe",
    "SIDEBAR_RESELLER_PLAN_ICON_TITLE":"Stockholm-icons-/-Shopping-/-Bag1",
    "SIDEBAR_SELL_INDIRECT_ICON_TOOLTIP":"Stockholm-icons / Einkaufen / Box3",
    "SIDEBAR_TITLE_ALL_COMMENTS":"Bemerkungen",
    "SIDEBAR_TITLE_MENU_ACCOUNT_MANAGERS":"Kundenbetreuer",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION":"Verwaltung",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_CUSTOM_NOTIFICATIONS":"Benutzerdefinierte Benachrichtigungen",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_ENGAGE":"Engagieren",
    "SIDEBAR_TITLE_MENU_ADMINISTRATION_MANAGE":"Bannerbenachrichtigungen",
    "SIDEBAR_TITLE_MENU_AZURE_ADVISOR":"Azure Advisor",
    "SIDEBAR_TITLE_MENU_AZURE_BILLING_REPORT":"Azure-Abrechnungsbericht",
    "SIDEBAR_TITLE_MENU_AZURE_MARGIN":"Marge",
    "SIDEBAR_TITLE_MENU_AZURE_SUBACRIPTION":"Azure-Abonnements",
    "SIDEBAR_TITLE_MENU_COST_SUMMARY_REPORT":"Kostenübersicht",
    "SIDEBAR_TITLE_MENU_CUSTOMER_ANALYTICS":"Kundenanalyse",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_LICENE_CONSUMPTION":"Lizenzverbrauch",
    "SIDEBAR_TITLE_MENU_CUSTOMER_MICROSOFT_REPORT":"Microsoft Azure-Verwendungsbericht",
    "SIDEBAR_TITLE_MENU_CUSTOMER_PROFILE":"Profil",
    "SIDEBAR_TITLE_MENU_DASHBOARD":"Dashboard",
    "SIDEBAR_TITLE_MENU_LICENSE_CHANGE":"Lizenzänderungsbericht.",
    "SIDEBAR_TITLE_MENU_LICENSE_CONSUMPTION_SUMMARY_REPORT":"Zusammenfassender Bericht zum Lizenzverbrauch",
    "SIDEBAR_TITLE_MENU_LICENSE_SUMMARY_REPORT":"Lizenzverbrauchsbericht",
    "SIDEBAR_TITLE_MENU_MANAGE_AZURE_ENTITLEMENTS":"Azure-Berechtigungen verwalten",
    "SIDEBAR_TITLE_MENU_ONBOARDED_CUSTOMERS_REPORT":"Onboarding Analytics",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISIONS":"Kommission",
    "SIDEBAR_TITLE_MENU_PARTNER_COMMISSION_REPORT":"Kommissionsberichte",
    "SIDEBAR_TITLE_MENU_PARTNER_COUPON":"Gutscheine",
    "SIDEBAR_TITLE_MENU_PARTNER_DOCUMENTATION":"Produktdokumentation",
    "SIDEBAR_TITLE_MENU_PARTNER_OFFER_USAGE_REPORT":"Nutzungsbericht",
    "SIDEBAR_TITLE_MENU_PARTNER_QUOTES":"Zitate",
    "SIDEBAR_TITLE_MENU_PARTNER_UPLOAD":"Hochladen",
    "SIDEBAR_TITLE_MENU_PENDING_PURCHASE_REQUESTS":"Zulassungen",
    "SIDEBAR_TITLE_MENU_PRODUCT_COMMISSIONS":"Provisionen",
    "SIDEBAR_TITLE_MENU_PRODUCT_EXTENSIONS":"Produkteigenschaften",
    "SIDEBAR_TITLE_MENU_REPORT_USAGE":"Verwendung",
    "SIDEBAR_TITLE_MENU_REPORTS_FOR_CUSTOMER":"Berichte",
    "SIDEBAR_TITLE_MENU_RESELLER_PLAN":"Pläne",
    "SIDEBAR_TITLE_MENU_SCHEDULED_REPORTS":"Geplante Berichte",
    "SIDEBAR_TITLE_MENU_USAGE_REPORT":"Nutzungsbericht",
    "SIDEBAR_TITLE_MENU_USERS":"Benutzer",
    "SIDEBAR_TITLE_MENUS_ANALYZE":"Analysieren",
    "SIDEBAR_TITLE_MENUS_AZURE_ESTIMATE":"Azure-Schätzungen",
    "SIDEBAR_TITLE_MENUS_AZURE_GROUPS":"Azure-Gruppen",
    "SIDEBAR_TITLE_MENUS_CUSTOM_OFFERS":"Partnerangebote",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_DASHBOARD":"Dashboard",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_INVOICES":"Rechnungen",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT":"Microsoft",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_MICROSOFT_USERS":"Microsoft-Benutzer",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_ORDERS":"Aufträge",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_PRODUCTS":"Produkte",
    "SIDEBAR_TITLE_MENUS_CUSTOMER_SHOP":"Shop",
    "SIDEBAR_TITLE_MENUS_DISTRIBUTOR_OFFERS":"Angebote des Händlers",
    "SIDEBAR_TITLE_MENUS_ENTITY_INVOICES":"Rechnungen",
    "SIDEBAR_TITLE_MENUS_PARTNER_BUNDLES":"Bündel",
    "SIDEBAR_TITLE_MENUS_PARTNER_CUSTOMERS":"Kunden",
    "SIDEBAR_TITLE_MENUS_PARTNER_DASHBOARD":"Dashboard",
    "SIDEBAR_TITLE_MENUS_PARTNER_PENDING_PAYMENT_STATUS":"Ausstehender Zahlungsstatus.",
    "SIDEBAR_TITLE_MENUS_PARTNER_PLANS":"Pläne",
    "SIDEBAR_TITLE_MENUS_PARTNER_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
    "SIDEBAR_TITLE_MENUS_RESELLER_DASHBOARD":"Dashboard",
    "SIDEBAR_TITLE_MENUS_RESELLER_REVENUE_AND_COST_SUMMARY":"Umsatz- und Kostenübersicht",
    "SIDEBAR_TITLE_MENUS_RESELLERS":"Wiederverkäufer",
    "SIDEBAR_TITLE_MENUS_SELL":"Verkaufen",
    "SIDEBAR_TITLE_MENUS_SELL_DIRECT":"Kunden",
    "SIDEBAR_TITLE_MENUS_SELLER_INDIRECT":"Indirekt verkaufen",
    "SIDEBAR_TITLE_SIDEBAR_C3_SUPPORT_AZURE_MARGIN":"C3-Unterstützung",
    "SIDEBAR_UPLOAD_ICON_TITLE_TOOLTIP_TEXT":"Stockholm-Symbole / Dateien / Hochladen",
    "SIDEBAR_USAGE_REPORT_ICON_TITLE":"Stockholm-Symbole / Kommunikation / Zwischenablageliste",
    "SIGN_UP_DATE":"Anmeldedatum",
    "SIGNUP_CART_COUPON_APPLY":"Anwenden",
    "SIGNUP_CART_COUPON_CODE":"Gutscheincode",
    "SIGNUP_CART_REMOVE_APPLIED_COUPON":"Gutschein entfernen",
    "simplereport":"Kommissionsbericht",
    "SINGLE_COMMENT_STATIC":"Kommentar",
    "SITE":"(Seite)",
    "SITE_DEPARTMENT_ID":"SeiteDepartmentId",
    "SITE_ID":"C3SiteId",
    "SLAB_BACKWARD_CONFIRMATION_POPUP":"Willst du wirklich zurück? Sie haben einige Plattendaten gespeichert.",
    "SLAB_DATA_EMPTY_RAW_DATA_AND_SAVE_ERROR":"Bitte füllen Sie die Plattendaten aus und speichern Sie sie.",
    "SLAB_DATA_EMPTY_RAW_DATA_ERROR":"Bitte geben Sie die Plattendaten ein.",
    "SLAB_DATA_TABLE_HEADER_ACTIONS":"Aktionen",
    "SLAB_DATA_TABLE_HEADER_COST_RATE":"Kostensatz/Einheit",
    "SLAB_DATA_TABLE_HEADER_RANGE":"Angebot",
    "SLAB_DATA_TABLE_HEADER_SALE_RATE":"Verkaufspreis/Einheit",
    "SLAB_DISPLAY_NAME_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Die Textlänge ist zu lang",
    "SLAB_TABLE_EMPTY_RAW_DATA_ERROR":"Bitte füllen Sie die Zeilendaten aus.",
    "SLAB_TABLE_EXCEED_10_DATA_ERROR":"Die Plattendaten dürfen nicht mehr als 10 betragen",
    "SLAB_TYPE_SELECT_OPTION":"Wählen",
    "SMTP_SETTINGS_TEST":"Klicken Sie auf die Schaltfläche, um die SMTP-Einstellungen zu testen",
    "SMTP_TEST_BUTTON":"Testen Sie SMTP",
    "SMTPTYPE_LEGACY_SMTP":"Legacy-SMTP",
    "SMTPTYPE_MODERN_0365":"Moderne 0365",
    "SMTPTYPE_SENDGRID":"Sendgrid",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS":"Stornierungsbedingungen",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_1":"0 - 24 Stunden: Volle Rückerstattung",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_2":"24 - 48 Stunden: 1. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_3":"48 – 72 Stunden: 2. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_4":"72 – 96 Stunden: 3. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_5":"96 – 120 Stunden: 4. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_6":"120 – 144 Stunden: 5. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_7":"144 – 168 Stunden: 6. Tag anteilig",
    "SOFTWARE_SUBSCRIPTIONS_CANCELLATION_POLICY_INSTRUCATIONS_8":"Nach 7 Kalendertagen: Eine Stornierung ist nicht zulässig",
    "SOFTWARE_SUBSCRIPTIONS_INSTRUCTIONS_HEADING":"Anweisungen für Software-Abonnements",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_1":"Anzahl/Plätze können jederzeit erhöht werden.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATION_2":"Die Anzahl/Plätze können innerhalb von 7 Kalendertagen auf die vorherige oder die Summe der vorherigen Anzahl/Plätze reduziert werden.",
    "SOFTWARE_SUBSCRIPTIONS_QUANTITY_CHANGE_INSTRUCATIONS":"Mengenänderung",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS":"Planen Sie eine Verlängerungsänderung",
    "SOFTWARE_SUBSCRIPTIONS_SCHEDULED_RNEWAL_INSTRUCATIONS_1":"Der aktuelle Zeitplan wird gelöscht, wenn Menge/Status der automatischen Verlängerung/Abrechnungszeitraum/Laufzeit geändert wird.",
    "SoftwareSubscriptions":"Software-Abonnements",
    "SOURCE_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Quellwährung",
    "SOURCE_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Quellwährung",
    "SOURCE_PLAN_DISPLAY_NAME":"Quellenplan",
    "SOURCE_PRODUCT_DISPLAY_NAME":"Quellprodukt",
    "SPECIAL_QUALIFICATION_CUSTOMER_PROFILE_SYNCE_MESSAGE":"Kundenprofil wurde synchronisiert",
    "SPECIAL_QUALIFICATIONS_TOOLTIP_DESCRIPTION":"Details zur Sonderqualifikation bearbeiten",
    "SQL_ERROR_DESC":"Bei der Bearbeitung Ihrer Anfrage scheint ein technischer Fehler aufgetreten zu sein. Bitte wenden Sie sich an den Support, um weitere Unterstützung zu erhalten.",
    "SQL_ERROR_WHILE_GETTING_UNPAID_DUES_BY_ENTITY_ID":"Fehler in der Datenbank beim Versuch, Rechnungsgebühren abzurufen",
    "START_BILLING_PERIOD":"Abrechnungszeitraum starten",
    "START_DATE_REQUIRED_MESSAGE":"Startdatum ist erforderlich",
    "STATUS":"Status",
    "STATUS_ALL":"Alles",
    "STATUS_DUE":"Gebühren",
    "STATUS_OVER_DUE":"Übergebühren",
    "STATUS_PENDING":"steht aus",
    "STATUS_UPDATED_SUCCESSFULLY_MESSAGE":"Status erfolgreich aktualisiert",
    "SUB_TAXES_NOT_BOND_EXCEPTION":"Bitte geben Sie mindestens einen Steuernamen und einen Prozentsatz für die Untersteuern ein.",
    "SUBCRIPTION_UPGRADE_MAX_QUANTITY_ERROR":"Die maximal zulässige Menge ist",
    "SUBCRIPTION_UPGRADE_MIN_QUANTITY_ERROR":"Die zulässige Mindestmenge beträgt 1",
    "SUBCRIPTION_UPGRADE_QUANTITY_REQUIRED_ERROR":"Dieses Feld wird benötigt",
    "SUBCRIPTION_UPGRADE_REMOVE_PROMOTION":"Werbung entfernen",
    "SUBCRIPTION_UPGRADE_UPGRADE_IN_PROGRESS_TOOLTIP":"Das Upgrade ist im Gange, bitte klicken Sie auf Übergang, um den Fortschritt zu sehen",
    "SUBCRITPION_UPGRADE_MANUAL_SEATASSIGNMENT_TOOLTIP":"Da die Anzahl der Upgrade-Lizenzen geringer ist als die aktuelle, müssen Sie Arbeitsplätze manuell neu zuweisen",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE":"Dieses Kundenabonnement wird zwischen Partnerkunden und Wiederverkäuferkunden geteilt, daher kann das Produkt nicht aktualisiert werden",
    "SUBSCIRPION_UPGRADE_WARNING_MESSAGE_2":"Dieses Abonnement wird von mehreren Einheiten gemeinsam genutzt, daher ist nach dem Upgrade eine manuelle Neuzuordnung erforderlich",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_ADD_TITLE":"Abonnementablaufprüfung hinzufügen",
    "SUBSCRIPTIO_RENEWAL_EXPIRATION_EDIT_TITLE":"Abonnementablaufprüfung hinzufügen",
    "SUBSCRIPTION_CHECK_ID":"Abonnement-Check-ID",
    "SUBSCRIPTION_EXPIRATION_CHECKS_REQUIRED":"Erforderlich",
    "SUBSCRIPTION_EXPIRATION_MAX_DAYS":"Die maximale Anzahl an Tagen sollte kleiner oder gleich {{MaxDate}} sein.",
    "SUBSCRIPTION_EXPIRATION_MIN_DAYS":"Die Mindestanzahl an Tagen sollte größer oder gleich 1 sein",
    "SUBSCRIPTION_EXPIRATION_NAME_MAX_LENGTH":"Sie haben Ihr maximales Limit von 50 zulässigen Zeichen erreicht",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FAILED":"Benachrichtigung über Ablauf des Abonnements fehlgeschlagen",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_FAILED":"Benachrichtigung über Ablauf des Abonnements für Kunden fehlgeschlagen",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_FOR_CUSTOMER_SUCCESS":"Benachrichtigung über Ablauf des Abonnements für den Kundenerfolg",
    "SUBSCRIPTION_EXPIRATION_NOTIFICATION_SUCCESS":"Benachrichtigung über Ablauf des Abonnements erfolgreich",
    "SUBSCRIPTION_EXPIRATION_PLACEHOLDER_SELECT_TERM":"Wählen Sie einen Begriff aus",
    "SUBSCRIPTION_EXPIRATION_SUBMIT_BTN_TEXT":"einreichen",
    "SUBSCRIPTION_HISTORY_BILLING_PERIOD":"Abrechnungszeitraum",
    "SUBSCRIPTION_HISTORY_BREADCRUMB_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_CYCLE":"Rechnungskreislauf",
    "SUBSCRIPTION_HISTORY_FILTERS_BILLING_PERIOD":"Abrechnungszeitraum",
    "SUBSCRIPTION_HISTORY_FILTERS_CATEGORY":"Kategorie",
    "SUBSCRIPTION_HISTORY_FILTERS_CUSTOMERS":"Kunden",
    "SUBSCRIPTION_HISTORY_FILTERS_END_DATE":"Endtermin",
    "SUBSCRIPTION_HISTORY_FILTERS_KEYWORD":"Abonnementname",
    "SUBSCRIPTION_HISTORY_FILTERS_PROVIDER":"Anbieter",
    "SUBSCRIPTION_HISTORY_FILTERS_START_DATE":"Anfangsdatum",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_BILLING_CYCLES":"Abrechnungszyklen",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CATEGORY":"Kategorien",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_CONSUMPTION_TYPES":"Verbrauchsarten",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_PROVIDERS":"Anbieter",
    "SUBSCRIPTION_HISTORY_FILTERS_TEXT_SERVICE_TYPES":"Anbieterkategorien",
    "SUBSCRIPTION_HISTORY_PORTLET_HEADER_SUBSCRIPTION_HISTORY":"Abonnementverlauf",
    "SUBSCRIPTION_HISTORY_REPORT_DOWNLOAD_BUTTON_TOOLTIP":"Abonnementverlauf herunterladen",
    "SUBSCRIPTION_HISTORY_REPORT_FILE_TYPES_HEADER":"Bericht zum Abonnementverlauf",
    "SUBSCRIPTION_HISTORY_SEARCH_CLOSE_OPTION":"Schließen",
    "SUBSCRIPTION_HISTORY_SEARCH_OPTION":"Suche",
    "SUBSCRIPTION_HISTORY_SEARCH_RESET_OPTION":"Zurücksetzen",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_CUSTOMER_NAME":"Kundenname",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DATE":"Datum",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_DEPARTMENT_NAME":"Abteilungsname",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_PRICE":"Neuer Preis",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_ADD_ON":"Erweiterung",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OFFER_NAME":"Produktname",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_PRICE":"Alter Preis",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_PRICE":"Preis",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_SITE_NAME":"Seite-Name",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_STATUS":"Status",
    "SUBSCRIPTION_HISTORY_TABLE_HEADER_TEXT_USER":"Benutzer",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_END_DATE":"Endtermin",
    "SUBSCRIPTION_HISTORY_VIEW_LOGS_FILTERS_START_DATE":"Anfangsdatum",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_MANAGE_LICENSE_ASSIGNMENTS":"Lizenzzuweisungen verwalten",
    "SUBSCRIPTION_MANAGE_BREADCRUM_BUTTON_TEXT_SUBSCRIPTION":"Abonnements",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
    "SUBSCRIPTION_MANAGE_MANAGE_BUTTON_RELEASE_UNUSED_SEATS_BUSY_MESSAGE":"Nicht genutzte Sitze freigeben. Warten Sie mal...",
    "SUBSCRIPTION_MANAGE_MANAGE_CAPTION_MANAGE_LICENSE_ASSIGNMENT_FOR":"Lizenzzuweisungen verwalten für",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_ASSIGN_LICENSES":"Lizenzen zuweisen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_BUTTON_TEXT_REMOVE_LICENSES":"Lizenzen entfernen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_ASSIGN_USERS":"Benutzer zuweisen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_1":"Anleitung",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_INSTUCTIONS_2":"Anleitung",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_ASSIGNED":"Anzeigen und suchen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_LICENSES_TO_TO_BE_REMOVED":"Zu entfernende Lizenzen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_HEADER_VALIDATION_ERRORS":"Validierungsfehler",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_1":"Geben Sie gültige Benutzer-E-Mail-Adressen ein, die durch ein Komma getrennt sind.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_2":"Dieses Portal überprüft die Eingabe und bestimmt, ob vor der Zuweisung zusätzliche Lizenzen erworben werden müssen.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_3":"Während wir an Änderungen an diesem Abonnement arbeiten, kann kein Vorgang ausgeführt werden.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_4":"Alle Fehler, die während der Validierung der Eingabe festgestellt wurden, werden angezeigt.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_1_PART_5":"Beim Senden wird entweder Lizenzen zuweisen oder entfernen (aktive Registerkarte) berücksichtigt.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_1":"Klicken Sie in der Tabelle links auf <i class='fa fa-remove red'></i> für einen Benutzer, um ihn zur Liste der zu entfernenden Lizenzen hinzuzufügen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_2":"Klicken Sie auf Senden, um die Lizenzen zu entfernen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_INSTRUCTION_2_PART_3":"Beim Senden wird entweder Lizenzen zuweisen oder entfernen (aktive Registerkarte) berücksichtigt",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_COST":"Kosten",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_ORGINAL_QUANTITY":"Originalmenge.",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LABEL_RATE_PER_SEAT":"Preis pro Sitzplatz",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_ASSIGNMENT_LABEL_ENTER_MULTIPLE_USER":"Mehrere Benutzer",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_BUTTON_REMOVE":"Entfernen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSE_LABEL_UPLOAD_FILE":"Datei hochladen",
    "SUBSCRIPTION_MANAGE_MANAGE_FORM_LICENSES_ASSIGNED_TABLE_HEADER_NAME":"Name",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_HELP_TEXT":"Neue Benutzer-E-Mail-Adresse hinzufügen",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT":"Geben Sie gültige Benutzer-E-Mails, die durch ein Komma getrennt sind, in eine TXT-Datei ein und laden Sie sie hier hoch.",
    "SUBSCRIPTION_MANAGE_MULTIPLE_USER_UPLOAD_HELP_TEXT_WITH_EXAMPLE":"Geben Sie gültige Benutzer-E-Mails, die durch ein Komma getrennt sind, in eine TXT-Datei ein und laden Sie sie hoch. Z.B: abc @ xyz.com, bcd @ xyz.com",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_IGNOR_ERRORS":"Fehler ignorieren",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "SUBSCRIPTION_MANAGE_STATUS_BUTTON_TEXT_FIX_ERRORS":"Fehler beheben",
    "SUBSCRIPTION_MANAGE_STATUS_CAPTION_STATUS":"Status",
    "SUBSCRIPTION_MANAGE_STATUS_WARNING_MESSAHGE":"Bitte warten Sie, während wir Ihre Anfrage bearbeiten. Dies kann einige Minuten dauern. Sie können es auch erneut versuchen, indem Sie auf der Abonnementseite auf die Schaltfläche \\''Verwalten\\'' klicken",
    "SUBSCRIPTION_NEXTSCHEDULING_CHANGES_PROCEED_WITH_LIMIT_IS_SURPASSED":"Möchten Sie mit den nächsten Änderungen der Terminverlängerung als {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'Sitzplatzlimit und Transaktionslimit': (isSeatLimitExceeded? 'Sitzplatzlimit ist': 'Transaktionslimit ist')}} überschritten",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_CANCELLED":"Bestellung storniert",
    "SUBSCRIPTION_PROVISION_STATUS_ORDER_COMPLETED":"Bestellung abgeschlossen",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_PURCHASED":"Abonnement gekauft",
    "SUBSCRIPTION_PROVISION_STATUS_SUBSCRIPTION_REMOVED":"Abonnement entfernt",
    "SUBSCRIPTION_PROVISIONING_ACTIVATE_SUCCESS":"Die ausgewählte Anforderung wurde erfolgreich genehmigt.",
    "SUBSCRIPTION_PROVISIONING_BREADCRUMB_BUTTON_TEXT_SUBSCRIPTION_PROVISIONING":"Bereitstellung von Abonnements",
    "SUBSCRIPTION_PROVISIONING_CANCELED_SUCCESS":"Die ausgewählte Anforderung wurde erfolgreich abgebrochen.",
    "SUBSCRIPTION_PROVISIONING_DROPDOWN_TEXT_ALL_CUSTOMERS":"Alle Kunden",
    "SUBSCRIPTION_PROVISIONING_HEADING_TEXT_SUBSCRIPTIONS_PROVISIONING":"Bereitstellung von Abonnements",
    "SUBSCRIPTION_PROVISIONING_TABLE_BUTTON_TOOLTIP_ACTIVATED":"aktivieren Sie",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENITITY_NAME":"Entitätsname",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_ENTITY_TITLE":"Titel",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_QUANTITY":"Neue Menge",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_NEW_STATUS":"Neuer Status",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_QUANTITY":"Alte Menge",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_OLD_STATUS":"Alter Status",
    "SUBSCRIPTION_PROVISIONING_TABLE_HEADER_TEXT_SUBSCRIPTION_NAME":"Abonnementname",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE":"Bitte warten Sie, während wir Ihren früheren Kauf bearbeiten. Dies kann einige Minuten dauern.",
    "SUBSCRIPTION_PURCHASE_STATUS_WARNING_MESSAHGE_ON_LIST_VIEW":"Bitte warten Sie, während wir Ihren früheren Kauf bearbeiten. Dies kann einige Minuten dauern. Sie können den Status auch überprüfen, indem Sie auf die Schaltfläche \\''Neues Abonnement hinzufügen\\'' klicken.",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_ADD_CHECK":"Scheck hinzufügen",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_BACK_TO_LIST":"Zurück zur Liste",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_SETTINGS":"Überprüfung des Abonnementablaufs",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_ACTION":"Aktion",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_DAYS":"Tage",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_NAME":"Name",
    "SUBSCRIPTION_RENEWAL_EXPIRATION_TABLE_TERM":"Begriff",
    "SUBSCRIPTION_STATUS_ACTIVE":"Aktiv",
    "SUBSCRIPTION_STATUS_CANCELLED":"Abgesagt",
    "SUBSCRIPTION_STATUS_COMPLETED":"Vollendet",
    "SUBSCRIPTION_STATUS_DELETED":"Gelöscht",
    "SUBSCRIPTION_STATUS_DISABLED":"Deaktiviert",
    "SUBSCRIPTION_STATUS_EXPIRED":"Abgelaufen",
    "SUBSCRIPTION_STATUS_FAILED":"Gescheitert",
    "SUBSCRIPTION_STATUS_IN_PROVISION":"In-Provision",
    "SUBSCRIPTION_STATUS_PENDING":"steht aus",
    "SUBSCRIPTION_STATUS_SUCCEEDED":"Erfolgreich",
    "SUBSCRIPTION_STATUS_SUSPENDED":"Suspendiert",
    "SUBSCRIPTION_UGRADE_TITLE_2":"Wichtig",
    "SUBSCRIPTION_UPDATED":"Abonnement erfolgreich aktualisiert.",
    "SUBSCRIPTION_UPGRADE_AUTOMATIC_MESSAGE":"Automatisch",
    "SUBSCRIPTION_UPGRADE_CHOOSE_ELIGIBLE_UPGRADE":"Wählen Sie ein geeignetes Upgrade für Ihr Abonnement aus",
    "SUBSCRIPTION_UPGRADE_CUMULATIVE_QUANTITY":"Kumulierte Menge",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION":"Aktuelles Abonnement",
    "SUBSCRIPTION_UPGRADE_CURRENT_SUBCRIPTION_TITLE":"Aktuelles Abonnement",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_1":"Wählen Sie ein neues Angebot aus, um das aktuelle Abonnement zu aktualisieren. Dadurch werden Ihre Abonnement- und Bereitstellungsdienste aktualisiert, die mit dem neuen Angebot verbunden sind",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_2":"Vermeiden Sie es während des Upgrades, Teile des ursprünglichen Abonnements oder die Konten des Kunden zu ändern. Dadurch werden ein neues Abonnement erstellt, Dienste bereitgestellt und Benutzerlizenzen dem neuen Abonnement neu zugewiesen. Diese Änderungen können nicht rückgängig gemacht werden.",
    "SUBSCRIPTION_UPGRADE_DESCRIPTION_3":"Bitte stellen Sie sicher, dass Sie eine Datensicherung durchführen, bevor Sie das Abonnement aktualisieren.",
    "SUBSCRIPTION_UPGRADE_IGNORE_ERRORS":"Fehler ignorieren",
    "SUBSCRIPTION_UPGRADE_IGNORE_WARNING":"Warnung ignorieren",
    "SUBSCRIPTION_UPGRADE_MANUAL_MESSAGE":"Manuelle",
    "SUBSCRIPTION_UPGRADE_NO_ELIGIBLE_PRODUCTS_FOUND":"Für das Abonnement waren keine berechtigten Planprodukte vorhanden",
    "SUBSCRIPTION_UPGRADE_NO_TRANSITIONS_FOUND":"Keine Übergänge gefunden",
    "SUBSCRIPTION_UPGRADE_PROCEED_WITH_UPGRADE_WITH_LIMIT_IS_SURPASSED":"Möchten Sie mit dem Abonnement-Upgrade fortfahren als {{isSeatLimitExceeded && isTransactionLimitExceeded ? 'Sitzplatzlimit und Transaktionslimit': (isSeatLimitExceeded? 'Sitzplatzlimit ist': 'Transaktionslimit ist')}} überschritten",
    "SUBSCRIPTION_UPGRADE_SEAT_REASSIGNMENT":"Neuzuweisung von Sitzplätzen",
    "SUBSCRIPTION_UPGRADE_SEATS_TO_BE_UPGRADED":"Arbeitsplätze, die aktualisiert werden müssen (Quellabonnement)",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_LIST":"Wählen Sie ein Zielabonnement aus",
    "SUBSCRIPTION_UPGRADE_SUBSCRIPTION_NAME":"Abonnementname",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION":"Dieses Feld wird benötigt",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LBL":"Zielabonnements",
    "SUBSCRIPTION_UPGRADE_TARGET_SUBSCRIPTION_LIST":"Neues Abonnement",
    "SUBSCRIPTION_UPGRADE_TITLE":"Wählen Sie ein geeignetes Upgrade aus",
    "SUBSCRIPTION_UPGRADE_TRANSITION_ACTIVITY":"Übergangsaktivität",
    "SUBSCRIPTION_UPGRADE_UPGRADE_IN_PROGRESSS":"Die Aktualisierung wird durchgeführt",
    "SUBSCRIPTION_UPGRADE_UPGRADE_TEXT":"Aktualisierung",
    "SUBSCRIPTIONMANAGE_ALREADY_ASSIGNED":"Dieser Kunde wurde bereits zugewiesen.",
    "SUBSCRIPTIONMANAGE_ERRORMESSAGE":"Die folgenden Benutzer haben bereits eine Lizenz für dieses Angebot zugewiesen. Bitte entfernen Sie sie und versuchen Sie zu speichern.",
    "SUBSCRIPTIONMANAGE_IGNORE_ERROR":"Sind Sie sicher, dass Sie die Fehler ignorieren möchten?",
    "SUBSCRIPTIONMANAGE_LICENSE_UPLOAD_SUCCESS":"Lizenzzuweisungen erfolgreich aktualisiert",
    "SUBSCRIPTIONMANAGE_REACTIVATE":"Möchten Sie dieses Abonnement wirklich wieder aktivieren?",
    "SUBSCRIPTIONMANAGE_REACTIVATE_SUCCESSFULLY":"Das ausgewählte Abonnement wurde erfolgreich reaktiviert",
    "SUBSCRIPTIONMANAGE_RELEASE_UNUSED":"Sind Sie sicher, dass Sie die nicht verwendeten Sitze freigeben möchten?",
    "SUBSCRIPTIONMANAGE_REMOVE_ONE_USER":"Bitte entfernen Sie mindestens einen Benutzer, um die Lizenz aus der Tabelle \\''Zugewiesene Lizenzen\\'' zu widerrufen!",
    "SUBSCRIPTIONMANAGE_SELECT_USER":"Bitte wählen Sie den Benutzer aus der vorgeschlagenen Liste aus, bevor Sie auf die Schaltfläche Hinzufügen klicken.",
    "SUBSCRIPTIONMANAGE_SUSPEND":"Möchten Sie dieses Abonnement wirklich aussetzen? Bitte beachten Sie, dass verwandte aktive Add-On-Abonnements ebenfalls ausgesetzt werden (falls vorhanden).",
    "SUBSCRIPTIONMANAGE_SUSPEND_SUCCESSFULLY":"Das Abonnement (gegebenenfalls einschließlich Add-Ons) wird erfolgreich ausgesetzt",
    "SUBSCRIPTIONMANAGE_UNUSED_SEATS_RELEASED_SUCCESSFILLY":"Nicht genutzte Plätze des Abonnements wurden erfolgreich freigegeben.",
    "SUBSCRIPTIONMANAGE_UPLOAD_QUEUE":"Ihre Massenzuweisungsanforderung befindet sich in der Warteschlange",
    "SUBSCRIPTIONMANAGE_UPLOAD_SUCCESSFULLY":"Erfolgreich hochgeladen",
    "SUBSCRIPTIONMANAGE_VALID_EMAIL_FOR_LICENSE_ASSIGNMENT":"Bitte geben Sie die gültigen Benutzer-E-Mails ein, um die Lizenzen in das Textfeld zuzuweisen.",
    "SUBSCRIPTIONS_ADD_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Neues Abonnement hinzufügen",
    "SUBSCRIPTIONS_ADD_CAPTION_SUBSCRIPTIONS":"Abonnements",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_EDIT":"Bearbeiten",
    "SUBSCRIPTIONS_ADD_TABLE_ACTION_BUTTON_MANAGE":"Verwalten",
    "SUBSCRIPTIONS_ADD_TABLE_COLUMN_TOTAL":"Gesamt",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_ACTION":"Aktionen",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_OFFER_NAME":"Name",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_QUANTITY":"Menge",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_RATE_PER_SEAT":"Kosten",
    "SUBSCRIPTIONS_ADD_TABLE_HEADER_TEXT_TOTAL_SUM":"Gesamt",
    "SUBSCRIPTIONS_AZURE_ADD_CAPTION_NEW_AZURE_SUBSCRIPTION":"Neues Azure-Abonnement",
    "SUBSCRIPTIONS_AZURE_ADD_FORM_LABEL":"Name",
    "SUBSCRIPTIONS_AZURE_ADD_HEADER_TEXT_ADD_NEW_SUBSCRIPTION":"Neues Abonnement hinzufügen",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_AZURE":"Azurblau",
    "SUBSCRIPTIONS_AZURE_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "SUBSCRIPTIONS_AZURE_LIST_BUTTON_TEXT_ADD_NEW_SUBSCRIPTION":"Neue Abonnements hinzufügen",
    "SUBSCRIPTIONS_AZURE_LIST_CAPTION_AZURE_SUBSCRIPTION":"Azure-Abonnements",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_ESTIMATE_PRICE":"Schätzpreis",
    "SUBSCRIPTIONS_AZURE_LIST_TABLE_HEADER_OFFER_NAME":"Angebotsname",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "SUBSCRIPTIONS_BREADCRUM_BUTTON_TEXT_SUBSCRIPTIONS":"Abonnements",
    "SUBSCRIPTIONS_EDIT_CAPTION_TEXT_UPDATE_SUBSCRIPTION":"Abonnement aktualisieren",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY":"Neue Menge insgesamt",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_1":"Der Mindestwert ist 1",
    "SUBSCRIPTIONS_EDIT_FORM_LABEL_TARGET_QUANTITY_VALIDATION_MESSAGES_2":"Die Eingabe sollte eine gültige Ganzzahl sein",
    "SUBSCRIPTIONS_EDIT_HEADER_TEXT_OFFER":"Angebot",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_1":"Kaufen Sie zusätzliche Sitzplätze, indem Sie die <strong> Gesamtzahl neuer Mengen </strong> erhöhen",
    "SUBSCRIPTIONS_EDIT_LABEL_TEXT_LINE_2":"Stellen Sie sicher, dass Sie Lizenzen von Benutzern über <strong>Benutzer &-gt; Lizenzen</strong> für den Fall, dass Sie die Anzahl der Sitzplätze von hier aus reduzieren möchten",
    "SUBSCRIPTIONS_PURCHASE_UPLOAD_QUEUE":"Ihre Kaufanfrage für Abonnements befindet sich in der Warteschlange",
    "SUBSCRIPTIONS_REACTIVATE_TABLE_ACTION_BUTTON_MANAGE":"Reaktivieren",
    "SUBSCRIPTIONS_SAVE_SUCCESS":"Abonnement erfolgreich gespeichert",
    "SUBSCRIPTIONS_SELECT_OFFERS_PROMPT":"Bitte wählen Sie mindestens ein Angebot aus, bevor Sie eine Bestellung aufgeben",
    "SUBSCRIPTIONS_SUSPEND_TABLE_ACTION_BUTTON_MANAGE":"Aussetzen",
    "SUCCESS_MESSAGE_FOR_GENERAL_SETTINGS_UPDATE":"Allgemeine Einstellungen erfolgreich aktualisiert",
    "SUCCESS_MESSAGE_UPDATE_CONNECTWISE_SETTINGS":"ConnectWise Manage-Einstellungen erfolgreich aktualisiert.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_AMTP_SETTINGS":"Die SMTP-Einstellungen der Partner wurden erfolgreich aktualisiert.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_LOGO":"Partner {{logo}} wurde erfolgreich aktualisiert.",
    "SUCCESS_MESSAGE_UPDATE_PARTNER_SETTINGS":"Partnereinstellungen erfolgreich aktualisiert.",
    "SUCCESS_MESSAGE_UPDATE_PROVIDER_SETTINGS":"Anbietereinstellungen erfolgreich aktualisiert.",
    "SUCCESS_MESSAGE_UPDATE_PUBLIC_SIGNUP_SETTINGS":"Öffentliche Registrierungseinstellungen erfolgreich aktualisiert.",
    "SUCCESS_PROCESSING_IMPORT_MULTIPLE_PAYMENTS":"Der Import mehrerer Zahlungen wurde erfolgreich abgeschlossen",
    "SUCCESS_PROCESSING_IMPORT_PARTNER_OFFER_DAILY_USAGE_DATA":"Erfolgreich importierte tägliche Nutzungsdaten des Partnerangebots",
    "SUCCESS_PROCESSING_IMPORT_PRODUCT_COMMISSIONS":"Import von Produktprovisionen erfolgreich abgeschlossen",
    "SUCCESSFULLY_CREATED_NEW_PLAN_WITH_ALL_OFFERS":"Erfolgreich einen neuen Plan mit allen Angeboten erstellt",
    "SUCCESSFULLY_CREATED_NEW_RESELLER_PLAN_WITH_ALL_OFFERS":"Es wurde erfolgreich ein neuer Reseller-Plan mit allen Angeboten erstellt",
    "SUPER_USER_POPUP_HEADER_LABLE_TEXT_SUPER_USER_FIX":"Mengeninkongruenz beheben",
    "SUPER_USER_POPUP_LABLE_TEXT_ADD_INTO_EXISTING_PLAN":"Bitte aktualisieren Sie den Plan für diesen Kunden, um relevante Angebote aufzunehmen, und kehren Sie hierher zurück, um das Problem zu beheben",
    "SUPER_USER_POPUP_LABLE_TEXT_CSP_OFFER_NAME":"CSP-Angebotsname",
    "SUPER_USER_POPUP_LABLE_TEXT_NO_OFFERS_FOUND":"Keine passenden Angebote gefunden",
    "SUPER_USER_POPUP_LABLE_TEXT_PLAN_OFFER_NAME":"Passendes Angebot im aktuellen Plan",
    "SUPER_USER_POPUP_LABLE_TEXT_PRICE":"Preis",
    "SUPER_USER_POPUP_LABLE_TEXT_QUANTITY":"Menge",
    "SUPER_USER_POPUP_LABLE_TEXT_SUBSCRIPTION_MAPPING":"Mapping anbieten",
    "SUPPLIER_DETAILS":"Lieferantendetails",
    "SUPPORT_ADD_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "SUPPORT_ADD_CAPTION_NEW_SUPPORT_TICKET":"Neues Support Ticket",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_EMAIL":"Kontakt E-mail",
    "SUPPORT_ADD_FORM_LABELS_CONTACT_PHONE":"Kontakt-Telefon",
    "SUPPORT_ADD_FORM_LABELS_DESCRIPTION":"Beschreibung",
    "SUPPORT_ADD_FORM_LABELS_PRIORITY":"Priorität",
    "SUPPORT_ADD_FORM_LABELS_PRODUCT_AREA":"Produktbereich",
    "SUPPORT_ADD_FORM_LABELS_SUJECT":"Gegenstand",
    "SUPPORT_ADD_FORM_LABELS_TYPE":"Art",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "SUPPORT_BREADCRUM_BUTTON_TEXT_SUPPORT":"Unterstützung",
    "SUPPORT_DETAILS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "SUPPORT_DETAILS_CAPTION_TEXT_SUPPORT_TICKET#":"Unterstüzungsticket #",
    "SUPPORT_DETAILS_FORM_LABELS_ADD_COMMENTS":"Einen Kommentar hinzufügen",
    "SUPPORT_DETAILS_FORM_LABELS_COMMENTS":"Bemerkungen",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_EMAIL":"Kontakt E-mail",
    "SUPPORT_DETAILS_FORM_LABELS_CONTACT_PHONE":"Kontakt-Telefon",
    "SUPPORT_DETAILS_FORM_LABELS_CUMULATIVE_EFFORT":"Kumulativer Aufwand",
    "SUPPORT_DETAILS_FORM_LABELS_CUSTOMER":"Kunde",
    "SUPPORT_DETAILS_FORM_LABELS_DESCRIPTION":"Beschreibung",
    "SUPPORT_DETAILS_FORM_LABELS_EFFORT":"Aufwand (in Stunden)",
    "SUPPORT_DETAILS_FORM_LABELS_PRIORITY":"Priorität",
    "SUPPORT_DETAILS_FORM_LABELS_PRODUCT":"Produktbereich",
    "SUPPORT_DETAILS_FORM_LABELS_STATUS":"Status",
    "SUPPORT_DETAILS_FORM_LABELS_SUBJECT":"Gegenstand",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT":"Support-Agent",
    "SUPPORT_DETAILS_FORM_LABELS_SUPPORT_AGENT_PLACE_HOLDER":"Wählen Sie Support Agent",
    "SUPPORT_DETAILS_FORM_LABELS_TYPE":"Art",
    "SUPPORT_DETAILS_FORM_PARAGRAPH_NO_COMMENTS":"Keine Kommentare hinzugefügt.",
    "SUPPORT_FOR_TEXT":"Unterstützung für",
    "SUPPORT_LIST_BUTTON_TEXT_ADD_SUPPORT_TICKET":"Support-Ticket hinzufügen",
    "SUPPORT_LIST_CAPTION_SUPPORT_TICKET":"Support-Tickets",
    "SUPPORT_LIST_FORM_LABEL_CUSTOMER":"Kunde",
    "SUPPORT_LIST_FORM_LABEL_SEARCH":"Suche",
    "SUPPORT_LIST_FORM_LABEL_STATUS":"Status",
    "SUPPORT_LIST_FORM_LABEL_SUPPORT_AGENT":"Support-Agent",
    "SUPPORT_LIST_FORM_LABEL_TICKET_ID":"Ticketnummer",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION":"Aktionen",
    "SUPPORT_LIST_FORM_TABLE_HEADER_ACTION_BUTTON_TOOLTIP_DETAILS":"Einzelheiten",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CONTACT_EMAIL":"Kontakt E-mail",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CREATED_DATE":"Erstellungsdatum",
    "SUPPORT_LIST_FORM_TABLE_HEADER_CUSTOMER":"Kunde",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRIORITY":"Priorität",
    "SUPPORT_LIST_FORM_TABLE_HEADER_PRODUCT_AREA":"Produktbereich",
    "SUPPORT_LIST_FORM_TABLE_HEADER_STATUS":"Status",
    "SUPPORT_LIST_FORM_TABLE_HEADER_SUBJECT":"Gegenstand",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TICKET_ID":"Ticketnummer",
    "SUPPORT_LIST_FORM_TABLE_HEADER_TYPE":"Art",
    "SUPPORT_TICKET_CREATE_SUCCESS":"Neues Support-Ticket erfolgreich erstellt",
    "SUPPORT_TICKET_STATUS_ARCHIVED":"Archiviert",
    "SUPPORT_TICKET_STATUS_CLOSED":"Geschlossen",
    "SUPPORT_TICKET_STATUS_IN PROGRESS":"In Bearbeitung",
    "SUPPORT_TICKET_STATUS_OPEN":"Öffnen",
    "SUPPORT_TICKET_STATUS_RE_OPEN":"Wieder öffnen",
    "SUPPORT_TICKET_STATUS_RESOLVED":"Aufgelöst",
    "SUPPORT_TICKET_STATUS_WONT_FIX":"Wird nicht repariert",
    "SUPPORT_TICKET_TYPE_ACCESS_ISSUES":"Zugriffsprobleme",
    "SUPPORT_TICKET_TYPE_ANALYTICS":"Analytik",
    "SUPPORT_TICKET_TYPE_MICROSOFT_AZURE":"Microsoft Azure",
    "SUPPORT_TICKET_TYPE_PROFILE_MANAGEMENT":"Profilverwaltung",
    "SUPPORT_TICKET_TYPE_SUBSCRIPTION_MANAGEMENT":"Abonnementverwaltung",
    "SUPPORT_TICKET_TYPE_USER_MANAGEMENT":"Benutzerverwaltung",
    "SUPPORT_TICKET_UPDATE_SUCCESS":"Details zum Support-Ticket erfolgreich aktualisiert",
    "SUPPORTED_MARKET_NOT_FOUND_FOR_THIS_CURRENCY":"Für den gewählten Währungscode wurde kein Markt gefunden",
    "SUPPORTED_MARKETS_NO_CURRENCY_CONVERSION_FOUND":"Die folgenden Märkte werden nicht unterstützt, da für ihre jeweiligen Währungen keine Währungsumrechnung gefunden wurde.",
    "SUPPORTED_MULTIPLE_MARKET_LABEL_INFO_TOOLTIP":"Der unterstützte Markt gilt nur für NCE-Angebote",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY":"Markt",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_COUNTRY_CODE":"Marktcode",
    "SUPPORTED_MULTIPLE_MARKET_TABLE_HEADER_CURRENCY":"Währung",
    "SupportedMarkets":"Unterstützte Märkte",
    "SWAL_OK_TEXT":"OK",
    "SWITCH_TO_MICROSOFT_PRICING_API_BTN_TEXT":"Wechseln Sie zu Microsoft Pricing API",
    "SWITCH_TO_NEW_SECURE_MODEL_BTN_TEXT":"Wechseln Sie zu einem neuen Sicherheitsmodell",
    "SYNC_CUSTOMER_PROFILE":"Kundenprofil synchronisieren",
    "TAB_HEADING_PROVISIONING_STATUS":"Bereitstellungsstatus",
    "TABLE_FOOTER_TEXT_SHOWING_COUNT":"Anzeigen von {{StartIndex}} - {{EndIndex}} von {{Total}} Datensätzen",
    "TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "TABLE_HEADER_TEXT_DATE":"Datum",
    "TABLE_HEADER_TEXT_DESCRIPTION":"Beschreibung",
    "TABLE_HEADER_TEXT_FAILURE_REASON":"Fehlergrund",
    "TABLE_HEADER_TEXT_GRAND_AMOUNT":"Großer Betrag",
    "TABLE_HEADER_TEXT_KEY":"Schlüssel",
    "TABLE_HEADER_TEXT_PAID_AMOUNT":"Bezahlte Menge",
    "TABLE_HEADER_TEXT_PAYMENT_STATUS":"Zahlungsstatus",
    "TABLE_HEADER_TEXT_REMARKS":"Bemerkungen",
    "TABLE_HEADER_TEXT_SUB_TOTAL_AMOUNT":"Zwischensumme",
    "TABLE_HEADER_TEXT_TAX_AMOUNT":"Steuerbetrag",
    "TABLE_HEADER_TEXT_VALUE":"Wert",
    "TABLE_LABLE_TEXT_SELECTED_INVOICES_TOTAL":"Ausgewählte Rechnung (en) insgesamt",
    "TABLE_PLACEHOLDER_TEXT_SEARCH":"Suche...",
    "TAG_DEELTE_SUCCESS_MESSAGE":"Tag erfolgreich gelöscht",
    "TAG_NAME_PLACEHOLDER":"Wählen Sie ein Schlagwort aus",
    "TAG_SAVED_SUCCESS_MESSAGE":"Tag erfolgreich gespeichert",
    "TAGGED_ENTITY_ELEMENT_PLAN_NAME_HEADER":"Plan Name",
    "TAGGED_ENTITY_ELEMENT_PRODUCT_NAME_HEADER":"Produktname",
    "TAGGED_ENTITY_ELEMENT_PROPERTIES_HEADER":"Eigenschaften",
    "TAGS_ACTIONS_TEXT_BACK_TO_TAGS":"Zurück zu Tags",
    "TAGS_ADD_EDIT_CAPTION_TEXT_ADD":"Hinzufügen",
    "TAGS_ADD_EDIT_CAPTION_TEXT_EDIT":"Bearbeiten",
    "TAGS_CAPTION_TEXT_TAG_FOR":"Tag für",
    "TAGS_CAPTION_TEXT_TAGS_FOR":"Tags für",
    "TARGET_CURRENCY_TOOLTIP_CURRENCY_CONVERSION":"Zielwährung",
    "TARGET_CURRENCY_TOOLTIP_RESELLER_CURRENCY_CONVERSION":"Zielwährung",
    "TARGET_PLAN_DISPLAY_NAME":"Zielplan",
    "TARGET_PRODUCT_DISPLAY_NAME":"Zielprodukt",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_3_TAX_PERCENTAGES":"Zurück zu den Steuerprozentsätzen",
    "TAX_PERCENTAGES_ADD_EDIT_BUTTON_TEXT_BACK_TO_TAX_PERCENTAGE":"Steuerliche Prozentsätze",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_1_ADD":"Hinzufügen",
    "TAX_PERCENTAGES_ADD_EDIT_CAPTION_TEXT_2_EDIT":"Bearbeiten",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_1":"Steuerregeln werden durch Kombinationen aus { Country,State Province and Tax Code }, { Country, Zip } oder { Country, State Province and Zip } definiert",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_HELP_CONTENT_LINE_2":"Z.B: Betrachten Sie die Definition von Steuerregeln für ein Land wie die USA. Wenn Sie Bundesstaat, Provinz und Postleitzahl leer lassen, wird der Steuersatz auf alle Kunden aus den USA angewendet. Wenn Sie eine Regel für einen bestimmten Staat definieren möchten, geben Sie beim Definieren der Regel die Werte für Land und Staat an",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_EFFECTIVE_FROM":"Gültig ab",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_NAME":"Steuername",
    "TAX_PERCENTAGES_ADD_EDIT_FORM_LABEL_TAX_PERCENTAGE":"Steuerprozentsatz",
    "TAX_PERCENTAGES_ADD_SUCCESS":"Steuerprozentsatz erfolgreich hinzugefügt.",
    "TAX_PERCENTAGES_BREADCRUM_BUTTON_TEXT_CUSTOMER":"Kunden",
    "TAX_PERCENTAGES_DELETE_SUCCESS":"Steuerprozentsatz erfolgreich gelöscht.",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_ADD_TAX_PERCENTAGE":"Steuerprozentsatz hinzufügen",
    "TAX_PERCENTAGES_TAXPERCENTAGE_BUTTON_BACK_TO_LIST":"Zurück zur Liste",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE":"Steuerliche Prozentsätze",
    "TAX_PERCENTAGES_TAXPERCENTAGE_CAPTION_TEXT_TAX_PERCENTAGE_FOR":"Steuerliche Prozentsätze für",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_DELETE":"Löschen",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_ACTION_BUTTON_TOOLTIP_EDIT":"Bearbeiten",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_ACTION":"Aktionen",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_EFFECTIVE_FROM":"Gültig ab",
    "TAX_PERCENTAGES_TAXPERCENTAGE_TABLE_HEADERS_TAX_PERCENTAGE":"MwSt %",
    "TAX_PERCENTAGES_UPDATE_SUCCESS":"Steuerprozentsatz erfolgreich aktualisiert.",
    "TAX_RULES_ADD_SUCCESS":"Steuerregel erfolgreich hinzugefügt",
    "TAX_RULES_DELETE_SUCCESS":"Steuerregel erfolgreich gelöscht",
    "TAX_RULES_UPDATE_SUCCESS":"Steuerregel erfolgreich aktualisiert",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX":"Kumulierte Steuer",
    "TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX_DESCRIPTION":"Die Kindersteuerregeln werden nacheinander auf den resultierenden Betrag aus jedem Schritt der Berechnung angewendet.",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX":"Allgemeines",
    "TAX_TYPE_DROPDOWN_OPTION_STRAIGHT_TAX_DESCRIPTION":"Auf den Gesamtbetrag wird ein einheitlicher Steuerprozentsatz angewendet.",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX":"Untersteuern",
    "TAX_TYPE_DROPDOWN_OPTION_SUB_TAX_DESCRIPTION":"Der Steuerprozentsatz für diese Definition ist die Summe (sum) aus den Kindersteuerregeln.",
    "TENANT TOOLTIP":"Mandanten zum Laden von Benutzern auswählen",
    "TENANT_CONFIG_DESC_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Aktivieren Sie das Abonnement für die Einhaltung der Gefahrenschwelle",
    "TENANT_CONFIG_DESC_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Aktivieren Sie die automatische Freigabe für nicht zugewiesene Plätze",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Ermöglicht dem Kunden, die Produktmenge in der Raster- oder Listenansicht im Produktbildschirm zu ändern.",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Erlauben Sie dem Kunden, die Transaktion für seine Rechnung(en) zu initiieren",
    "TENANT_CONFIG_DESC_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Durch Aktivieren dieses Flags wird verhindert, dass Kunden die Abonnementmenge reduzieren",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC":"Produktnamensynchronisierung für nutzungsbasierte Angebote zulassen",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS":"Produktnamensynchronisierung für mengenbasierte Angebote zulassen",
    "TENANT_CONFIG_DESC_ALLOW_PRODUCT_NAME_SYNC_QUANTITY_OFFERS_TOOLTIP":"Ermöglicht die Synchronisierung des Anzeigenamens des Abonnements für mengenbasierte Angebote",
    "TENANT_CONFIG_DESC_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Option zur Anwendung von Azure-Plan-Rabatten der Stufe 2 auf den Kunden. Der Standardwert ist „Nein“. Wenn er auf „Ja“ gesetzt ist, werden außer den PEC-Rabatten auch alle anderen Rabatte auf den Kunden angewendet.",
    "TENANT_CONFIG_DESC_AUTO_SYNC":"Dies wird verwendet, um den automatischen Synchronisierungsprozess in Reconciliation zu aktivieren/deaktivieren.",
    "TENANT_CONFIG_DESC_AUTOTASK_ANNUAL_CONTRACT_TERM":"Jährliche Autotask-Vertragslaufzeit (Jahre)",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE":"Enddatum des Autotask-Vertrags",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_END_DATE_DESC":"Dieser Datumswert wird als Enddatum verwendet, um Verträge automatisch für Kunden zu erstellen",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE":"Einrichtungsgebühr für Autotask-Vertrag",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_SET_UP_FEE_DESC":"Bitte geben Sie ggf. Vertragseinrichtungsgebühren ein",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE":"Startdatum des Autotask-Vertrags",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_START_DATE_DESC":"Dieser Datumswert wird als Startdatum verwendet, um Verträge automatisch für Kunden zu erstellen",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_STARTDATE":"Starttag des Autotask-Vertrags",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES":"Aktivieren Sie das Kontrollkästchen, wenn die Vertragszeitberichterstattung Start- und Stoppzeiten erfordert",
    "TENANT_CONFIG_DESC_AUTOTASK_CONTRACT_TIME_REPORTING_REQUIRES_START_AND_STOP_TIMES_DESC":"Autotask-Vertragszeitberichte für erforderliche Start- und Stoppzeiten",
    "TENANT_CONFIG_DESC_AUTOTASK_MONTHLY_CONTRACT_TERM":"Monatliche Autotask-Vertragslaufzeit (Monate)",
    "TENANT_CONFIG_DESC_AZURE_APPLICABLE":"Ermöglichen Sie Kunden die Verwaltung von Azure-Abonnements",
    "TENANT_CONFIG_DESC_AZURE_FOR_ENTERPRISE":"Ermöglichen Sie Kunden, Gruppen über Azure-Abonnements zu definieren",
    "TENANT_CONFIG_DESC_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie (%), um es auf die Azure-Nutzung des Kunden anzuwenden. z.B: Geben Sie 1,15 ein, wenn der Aufschlag 15% betragen soll",
    "TENANT_CONFIG_DESC_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, wird das NCE-Abonnement auf automatische Verlängerung eingestellt.",
    "TENANT_CONFIG_DESC_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Aktivieren, um den ERP-Preis bei der Erneuerung ohne Makro zu berücksichtigen",
    "TENANT_CONFIG_DESC_COOL_OFF_PERIOD":"Nach Kauf oder Mengenerhöhung wird das Produkt bis zu diesem Zeitraum (in Tagen) nicht im Autorelease berücksichtigt.",
    "TENANT_CONFIG_DESC_CRM_OPPORTUNITY_ID":"CRM-Opportunity-ID",
    "TENANT_CONFIG_DESC_CUSTOMER_BILLING_TYPE":"Kundenabrechnungstyp",
    "TENANT_CONFIG_DESC_CUSTOMER_CURRENCY":"Kundenwährung",
    "TENANT_CONFIG_DESC_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Art der Wirksamkeit der automatischen Preisaktualisierung des Support-Kundenprodukts. Diese Einstellung wird wirksam, wenn und nur Sollte Kunden benachrichtigen aktiviert sein!",
    "TENANT_CONFIG_DESC_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Standard-Gefahrenschwellenwert in Prozent für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Standardfehlerschwellenwert in Prozent für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Standard-Infoschwellenwert in Prozent für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Monatliches Standardbudget für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Standardschwellenwert-E-Mail-Empfänger für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Standardwarnschwellenwert in Prozent für das Azure-Abonnement.",
    "TENANT_CONFIG_DESC_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Aktivieren Sie die Allgemeinen Geschäftsbedingungen, um Produkte zu bestellen",
    "TENANT_CONFIG_DESC_ENABLE_NCE_TERMS_AND_CONDITION":"Aktivieren Sie die NCE-Nutzungsbedingungen",
    "TENANT_CONFIG_DESC_ENABLE_POWER_BI_REPORTS":"Aktivieren Sie Power BI-Berichte",
    "TENANT_CONFIG_DESC_ENABLE_SCHEDULE_ORDER":"Wenn Sie diese Option aktivieren, können Benutzer eine Bestellung planen",
    "TENANT_CONFIG_DESC_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
    "TENANT_CONFIG_DESC_EXEMPT_CUSTOMER_FROM_EXPORT":"Befreiter Kunde für PSA-Export",
    "TENANT_CONFIG_DESC_HAS_ENABLED_CUSTOMER_CONSENT":"Wenn dieses Feld aktiviert ist, berücksichtigen wir die Zustimmung des Kunden.",
    "TENANT_CONFIG_DESC_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Dieses Feld wird aktualisiert, wenn mindestens einer der Kunden den MCA akzeptiert hat. Deaktivieren Sie dieses Kontrollkästchen, um die Annahme durch den/die Kunden erneut zu erzwingen.",
    "TENANT_CONFIG_DESC_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Neueste Geschäftsbedingungen akzeptiert?",
    "TENANT_CONFIG_DESC_IMPOSE_TRANSACTION_LIMIT":"Transaktionslimit",
    "TENANT_CONFIG_DESC_INTEGER_CONSTANT_FOR_CUSTOMER":"Ganzzahlige Konstante",
    "TENANT_CONFIG_DESC_INTERNAL_BILLINGID":"Zur internen Bezugnahme. Diese ID kann verwendet werden, um den Kunden mit einem Datensatz in Ihrem internen Buchhaltungssystem zu verknüpfen",
    "TENANT_CONFIG_DESC_INVOICE_APPROVALDAY":"Tag des Monats, an dem die Rechnungen automatisch genehmigt werden",
    "TENANT_CONFIG_DESC_INVOICE_AUTO_PAYMENTDAY":"Tag, an dem automatische Zahlungen verarbeitet werden",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAY":"Fälligkeitsdatum für Rechnungen",
    "TENANT_CONFIG_DESC_INVOICE_DUEDAYS":"Anzahl der für die Rechnungszahlung fälligen Tage",
    "TENANT_CONFIG_DESC_INVOICE_FINALIZE_DAY":"Tag des Monats, an dem Rechnungen automatisch abgeschlossen werden",
    "TENANT_CONFIG_DESC_INVOICE_NOTE":"Erscheint auf der Rechnung pdf",
    "TENANT_CONFIG_DESC_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Stellen Sie die Verfügbarkeit des Zahlungsprofils vor der Kasse des Einkaufswagens fest",
    "TENANT_CONFIG_DESC_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Erforderliche automatische Freigabe für Standort- und Abteilungsprodukte",
    "TENANT_CONFIG_DESC_MANDATE_PROFILE":"Beauftragen Sie den Benutzer, die Zahlungsmethode zu übermitteln",
    "TENANT_CONFIG_DESC_MARK_NEW_ADDRESS_AS_DEFAULT":"Durch die Aktivierung dieser Option wird die neu hinzugefügte Adresse automatisch als Standardadresse für den Kunden festgelegt.",
    "TENANT_CONFIG_DESC_MARK_NEW_EMAIL_AS_DEFAULT":"Durch die Aktivierung dieser Option wird die neu hinzugefügte E-Mail automatisch als Standard-E-Mail für den Kunden festgelegt.",
    "TENANT_CONFIG_DESC_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Wenn Sie diese Option aktivieren, wird die neu hinzugefügte Telefonnummer automatisch als Standardtelefonnummer für den Kunden festgelegt.",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_ENTITY":"Einmaliger Rechnungseigentümer (Typ)",
    "TENANT_CONFIG_DESC_ONETIME_INVOICE_RECORD":"Einmaliger Rechnungseigentümer (Kennung)",
    "TENANT_CONFIG_DESC_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die einmaligen Einzelposten des Kunden angewendet werden soll.",
    "TENANT_CONFIG_DESC_PAN_NUMBER":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
    "TENANT_CONFIG_DESC_PAN_NUMBER_DISPALY_TEXT":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
    "TENANT_CONFIG_DESC_PARTNER_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen Link",
    "TENANT_CONFIG_DESC_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis anzuwenden ist, um den gewünschten Verkaufspreis für unbefristete Softwareabonnements zu erzielen.",
    "TENANT_CONFIG_DESC_PLACE_OF_SUPPLY":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
    "TENANT_CONFIG_DESC_PO_NUMBER":"Bestellnummer für Kunden definieren",
    "TENANT_CONFIG_DESC_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Rechnungs-E-Mail an externe Buchhaltungsdienste senden",
    "TENANT_CONFIG_DESC_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Aktivieren Sie die Funktion zum Übertragen von Rechnungen an externe Buchhaltungsdienste.",
    "TENANT_CONFIG_DESC_PROCESS_PAYMENTS_AUTOMATICALLY":"Aktivieren Sie dieses Flag, wenn das System Zahlungen verarbeiten soll (CC/ACH, falls zutreffend).",
    "TENANT_CONFIG_DESC_PROVIDER_SUBSCRIPTION_NAME":"Wenn aktiviert, wird der im Partner Center angegebene Abonnementname angezeigt",
    "TENANT_CONFIG_DESC_PS_INVOICE_RECORD":"Die unbefristete Softwareabrechnung ist in den Rechnungen dieser Website enthalten.",
    "TENANT_CONFIG_DESC_PURCHASE_SEAT_LIMIT":"Setzen Sie ein Limit für die Anzahl der Lizenzen durch, die der Kunde erwerben kann",
    "TENANT_CONFIG_DESC_QUICKBOOKS_PAYMENT_TERMS":"Die Zahlungsbedingungen, die auf Rechnungen markiert werden sollen, die an QuickBooks gebucht werden",
    "TENANT_CONFIG_DESC_RENEWAL_CONSENT_NOTIFY_PERIOD":"Geben Sie die Anzahl der Tage vor dem Verlängerungsdatum ein, nach denen Zustimmungs-E-Mails an Kunden gesendet werden.",
    "TENANT_CONFIG_DESC_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Geben Sie die Anzahl der Tage nach der Zustimmungsbenachrichtigung an, nach denen die Erinnerungsbenachrichtigung an die Kunden gesendet werden soll.",
    "TENANT_CONFIG_DESC_RI_INVOICE_RECORD":"Die Abrechnung der Azure-Reservierung ist in den Rechnungen dieser Site enthalten.",
    "TENANT_CONFIG_DESC_RI_MULTIPLY_PERCENTAGE":"Aufschlag (%), der auf die Azure-Reservierung des Kunden angewendet werden soll.",
    "TENANT_CONFIG_DESC_RI_PRICING_MODEL":"RI-Preismodell",
    "TENANT_CONFIG_DESC_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie (%), um auf die RI-Nutzung des Kunden angewendet zu werden.",
    "TENANT_CONFIG_DESC_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Der Kunde erhält die E-Mail mit den Rechnungsdetails, sobald die Rechnung genehmigt wurde und wenn E-Mail-Benachrichtigungen aktiviert sind",
    "TENANT_CONFIG_DESC_SEND_ZERO_AMOUNT_INVOICES":"Der Kunde erhält eine E-Mail-Benachrichtigung über Rechnungen mit Nullbeträgen",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NAME":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHIP_TO_CONTACT_NUMBER":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_TYPE_NAME":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHIP_TO_TAX_VALUE":"Erscheint auf der PDF-Rechnung im Abschnitt „Liefern an“.",
    "TENANT_CONFIG_DESC_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Sollte Standard für Kunden aktivieren.",
    "TENANT_CONFIG_DESC_SHOULD_NOTIFY_CUSTOMER":"Sollte den Kunden benachrichtigen",
    "TENANT_CONFIG_DESC_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Sollte den Kunden von Instant Pay-Zahlungen befreien.",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Abonnement innerhalb eines Kündigungsfensters von 7 Kalendertagen kündigen.",
    "TENANT_CONFIG_DESC_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Wenn diese Option aktiviert ist, kann der Kunde das Software-Abonnementprodukt innerhalb einer Kündigungsfrist von 7 Kalendertagen kündigen.",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES":"Ermöglichen Sie dem Kunden, die Enddaten des Abonnements anzupassen",
    "TENANT_CONFIG_DESC_SHOW_CUSTOMER_SUBSCRIPTION_END_DATE_ALIGNMENT_CHANGES_TOOLTIP":"Wenn diese Option aktiviert ist, kann der Kunde die Enddaten des Abonnements anpassen, um Gleichberechtigung zu erreichen",
    "TENANT_CONFIG_DESC_SHOW_NEXT_SCHEDULE_RENEWAL_MENU_DISPLAY_TEXT":"Nächstes Zeitplanverlängerungsmenü anzeigen",
    "TENANT_CONFIG_DESC_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Aktivieren Sie diese Option, um nicht integrierte Abonnements für die Auswahl eines benutzerdefinierten Enddatums während des Kaufs anzuzeigen.",
    "TENANT_CONFIG_DESC_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde ein ausgesetztes NCE-Abonnement reaktivieren.",
    "TENANT_CONFIG_DESC_SHOW_SPECIAL_QUALIFICATIONS_FOR_TENANT":"Button „Besondere Qualifikationen anzeigen“",
    "TENANT_CONFIG_DESC_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Abonnement aussetzen.",
    "TENANT_CONFIG_DESC_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Wenn aktiviert, kann der Kunde das NCE-Produkt auf eine höhere SKU upgraden",
    "TENANT_CONFIG_DESC_SKIP_SUBCRIPTION_IN_ONE_TIME_INVOICE":"Das Abonnement wird während einer einmaligen Rechnung übersprungen",
    "TENANT_CONFIG_DESC_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis angewendet werden soll, um den gewünschten Verkaufspreis für Softwareabonnements zu erzielen.",
    "TENANT_CONFIG_DESC_SS_INVOICE_RECORD":"Die Abrechnung des Software-Abonnements ist in den Rechnungen dieser Website enthalten.",
    "TENANT_CONFIG_DESC_STOP_ALL_TRANSACTIONS":"Wenn Sie diesen Wert auf true setzen, kann der Benutzer keine Einkäufe mehr tätigen.",
    "TENANT_CONFIG_DESC_STRING_CONSTANT_FOR_CUSTOMER":"Zeichenfolgenkonstante für den Kunden",
    "TENANT_CONFIG_DESC_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Automatische Preisaktualisierung des Kundenprodukts unterstützen",
    "TENANT_CONFIG_DESC_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Unterbrechen, wenn die Gefahrenschwelle erreicht ist",
    "TENANT_CONFIG_DESC_TAX_CODE":"Das Steuerkennzeichen wird verwendet, um den anwendbaren Steuerprozentsatz zu bestimmen",
    "TENANT_CONFIG_DESC_TAX_TYPE_NAME":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
    "TENANT_CONFIG_DESC_TAX_VALUE":"Erscheint auf der Rechnungs-PDF im Abschnitt „Rechnung an“.",
    "TENANT_CONFIG_DESC_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Gesamtaufschlag (%), der auf den Selbstkostenpreis angewendet werden soll, um den gewünschten Verkaufspreis für Abonnements von Drittanbietern zu erzielen.",
    "TENANT_CONFIG_DESC_TP_INVOICE_RECORD":"Die Abrechnung von Abonnements Dritter ist in den Rechnungen dieser Website enthalten.",
    "TENANT_CONFIG_DESC_TRANSACTION_AMOUNT_LIMIT":"Erzwingen Sie eine Begrenzung des Geldwerts des mengenbasierten Produktkaufs (in Kundenwährung).",
    "TENANT_CONFIG_DESP_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"ConnectWise-Zusatztyp",
    "TENANT_CONFIG_DESP_IS_ADVANCE_BILLING_DISPLAY_TEXT":"Vorausrechnung",
    "TENANT_CONFIG_DISC_CONNECTWISE_ADDITION_TYPE_DISPLAY_TEXT":"Basierend auf dem ausgewählten Format werden Ergänzungen in connectwise erstellt",
    "TENANT_CONFIG_DISC_IS_ADVANCE_BILLING_DISPALY_TEXT":"Die Abrechnung des aktuellen Monats wird für die Rechnungsstellung auf einen bestimmten Monat in der Zukunft verschoben",
    "TENANT_CONFIG_DISC_SHOW_TC_ACCEPTANCE_LOG":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen für den Mieter anzeigen",
    "TENANT_CONFIG_DISP_ACTIVATE_SUBSCRIPTION_SUSPENDED_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Aktivieren Sie das Abonnement für die Einhaltung der Gefahrenschwelle",
    "TENANT_CONFIG_DISP_ALLOW_AUTO_RELEASE_UNUSED_SEATS":"Lassen Sie die automatische Freigabe unbenutzter Plätze zu",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_CHANGE_PRODUCT_QUANTITY_FROM_LIST":"Ermöglichen Sie dem Kunden, die Produktmenge aus der Liste zu ändern",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_INITIATE_TRANSACTION":"Dem Kunden erlauben, eine Transaktion zu initiieren",
    "TENANT_CONFIG_DISP_ALLOW_CUSTOMER_TO_REDUCE_SEATS":"Ermöglichen Sie dem Kunden, Sitzplätze zu reduzieren",
    "TENANT_CONFIG_DISP_APPLY_TIER2_DISCOUNT_TO_CUSTOMER":"Wenden Sie Rabatte der Azure-Planstufe 2 auf den Kunden an",
    "TENANT_CONFIG_DISP_AUTO_SYNC":"Automatische Synchronisation",
    "TENANT_CONFIG_DISP_AZURE_APPLICABLE":"Azure aktivieren?",
    "TENANT_CONFIG_DISP_AZURE_FOR_ENTERPRISE":"Azure für Unternehmen aktivieren?",
    "TENANT_CONFIG_DISP_AZURE_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie sich für die Azure-Verwendung",
    "TENANT_CONFIG_DISP_CAN_MANAGE_IS_AUTO_RENEW_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Verwalten von NCE-Produkten wird automatisch verlängert",
    "TENANT_CONFIG_DISP_CONSIDER_ERP_PRICE_RENEWAL_FOR_NO_MACRO":"Berücksichtigen Sie den ERP-Preis bei der Erneuerung ohne Makro",
    "TENANT_CONFIG_DISP_COOL_OFF_PERIOD":"Auto-Release-Abkühlzeit",
    "TENANT_CONFIG_DISP_CRM_OPPORTUNITY_ID":"CRM-Opportunity-ID",
    "TENANT_CONFIG_DISP_CUSTOMER_BILLING_TYPE":"Kundenabrechnungstyp",
    "TENANT_CONFIG_DISP_CUSTOMER_CURRENCY":"Kundenwährung",
    "TENANT_CONFIG_DISP_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE_EFFECTIVENESS":"Effektivität der automatischen Preisaktualisierung des Kundenprodukts",
    "TENANT_CONFIG_DISP_DEFAULT_DANGER_THRESHOLD_DISPALY_TEXT":"Standard-Gefahrenschwelle (%)",
    "TENANT_CONFIG_DISP_DEFAULT_ERROR_THRESHOLD_DISPALY_TEXT":"Standardfehlerschwelle (%)",
    "TENANT_CONFIG_DISP_DEFAULT_INFO_THRESHOLD_DISPALY_TEXT":"Standard-Info-Schwellenwert (%)",
    "TENANT_CONFIG_DISP_DEFAULT_MONTHLY_BUDGET_DISPALY_TEXT":"Standard-Monatsbudget",
    "TENANT_CONFIG_DISP_DEFAULT_THRESHOLD_EMAIL_RECIPINET_DISPALY_TEXT":"Standardschwellenwert-E-Mail-Empfänger",
    "TENANT_CONFIG_DISP_DEFAULT_WARN_THRESHOLD_DISPALY_TEXT":"Standardwarnschwelle (%)",
    "TENANT_CONFIG_DISP_ENABLE_DEFAULT_TERMS_AND_CONDITION":"Aktivieren Sie die Allgemeinen Geschäftsbedingungen, um Produkte zu bestellen",
    "TENANT_CONFIG_DISP_ENABLE_NCE_TERMS_AND_CONDITION":"Aktivieren Sie die NCE-Nutzungsbedingungen, um Microsoft NCE-Produkte zu bestellen",
    "TENANT_CONFIG_DISP_ENABLE_SCHEDULE_ORDER":"Zeitplanreihenfolge aktivieren",
    "TENANT_CONFIG_DISP_ERP_CUSTOMER_ID":"ERP-Kunden-ID",
    "TENANT_CONFIG_DISP_EXEMPT_CUSTOMER_FROM_EXPORT":"Befreiter Kunde für PSA-Export",
    "TENANT_CONFIG_DISP_HAS_ENABLED_CUSTOMER_CONSENT":"Kundeneinwilligung aktiviert?",
    "TENANT_CONFIG_DISP_HAS_LATEST_CUSTOMER_CONSENT_ACCEPTED":"Microsoft-Kundenvereinbarung akzeptiert?",
    "TENANT_CONFIG_DISP_HAS_LATEST_TERMS_AND_CONDITIONS_ACCEPTED":"Akzeptierte Partner-Geschäftsbedingungen?",
    "TENANT_CONFIG_DISP_IMPOSE_TRANSACTION_LIMIT":"Transaktionslimit",
    "TENANT_CONFIG_DISP_INTEGER_CONSTANT_FOR_CUSTOMER":"MANDT",
    "TENANT_CONFIG_DISP_INTERNAL_BILLINGID":"Rechnungsreferenz-ID",
    "TENANT_CONFIG_DISP_INVOICE_APPROVALDAY":"Tag, an dem der Kunde Rechnungen sehen kann",
    "TENANT_CONFIG_DISP_INVOICE_AUTO_PAYMENTDAY":"Tag für die automatische Zahlung",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAY":"Fälligkeitsdatum für Rechnungen",
    "TENANT_CONFIG_DISP_INVOICE_DUEDAYS":"Fälligkeitstage für Rechnungen",
    "TENANT_CONFIG_DISP_INVOICE_FINALIZE_DAY":"Tag, an dem die Rechnungen abgeschlossen sind",
    "TENANT_CONFIG_DISP_INVOICE_NOTE":"Rechnungsnotizen",
    "TENANT_CONFIG_DISP_INVOICE_TYPE":"Rechnungsart",
    "TENANT_CONFIG_DISP_IS_PAYMENT_PROFILE_MENDATE_FOR_PURCHASE_DISPALY_TEXT":"Mendate Zahlungsprofil Warenkorb Kasse",
    "TENANT_CONFIG_DISP_IS_REQUIRED_AUTO_RELEASE_FOR_SITE_AND_DEPARTMENT_PRODUCTS":"Erforderliche automatische Freigabe für Standort- und Abteilungsprodukte",
    "TENANT_CONFIG_DISP_MANDATE_PROFILE":"Mandatsprofil",
    "TENANT_CONFIG_DISP_MARK_NEW_ADDRESS_AS_DEFAULT":"Weisen Sie automatisch eine neue Adresse als Standard zu",
    "TENANT_CONFIG_DISP_MARK_NEW_EMAIL_AS_DEFAULT":"Weisen Sie automatisch eine neue E-Mail als Standard zu",
    "TENANT_CONFIG_DISP_MARK_NEW_PHONE_NUMBER_AS_DEFAULT":"Weisen Sie automatisch eine neue Telefonnummer als Standard zu",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_ENTITY":"Einmaliger Rechnungseigentümer (Typ)",
    "TENANT_CONFIG_DISP_ONETIME_INVOICE_RECORD":"Einmaliger Rechnungseigentümer (Kennung)",
    "TENANT_CONFIG_DISP_ONETIME_USAGE_MULTIPLY_PERCENTAGE":"Aufschlag für einmalige Werbebuchungen",
    "TENANT_CONFIG_DISP_PAN_NUMBER":"PAN-Nummer",
    "TENANT_CONFIG_DISP_PAN_NUMBER_DISPALY_TEXT":"PAN-Nummerntaste",
    "TENANT_CONFIG_DISP_PARTNER_TERMS_AND_CONDITION":"Allgemeine Geschäftsbedingungen URL",
    "TENANT_CONFIG_DISP_PERPETUAL_SOFTWARE_MULTIPLY_PERCENTAGE":"Aufschlag für unbefristetes Softwareabonnement",
    "TENANT_CONFIG_DISP_PLACE_OF_SUPPLY":"Ort der Lieferung",
    "TENANT_CONFIG_DISP_PO_NUMBER":"PO #",
    "TENANT_CONFIG_DISP_POST_BILLING_EMAIL_TO_EXTERNAL_ACCOUNTING_SERVICES":"Rechnungs-E-Mail an externe Buchhaltungsdienste senden",
    "TENANT_CONFIG_DISP_POST_INVOICES_TO_EXTERNAL_ACCOUNTING_SERVICES":"Buchen Sie Rechnungen an einen externen Buchhaltungsdienst",
    "TENANT_CONFIG_DISP_PROCESS_PAYMENTS_AUTOMATICALLY":"Rechnungen automatisch bezahlen?",
    "TENANT_CONFIG_DISP_PROVIDER_SUBSCRIPTION_NAME":"Abonnementname des Anbieters anzeigen",
    "TENANT_CONFIG_DISP_PS_INVOICE_RECORD":"Unbefristeter Software-Rechnungseigentümer (Identifikator)",
    "TENANT_CONFIG_DISP_PURCHASE_SEAT_LIMIT":"Sitzplatzlimit anwendbar",
    "TENANT_CONFIG_DISP_QUICKBOOKS_PAYMENT_TERMS":"Zahlungsbedingungen für QuickBooks",
    "TENANT_CONFIG_DISP_RENEWAL_CONSENT_NOTIFY_PERIOD":"Erneuerung der Einwilligungs-Initialisierungsfrist",
    "TENANT_CONFIG_DISP_RENEWAL_CONSENT_REOCCURRENCE_DAYS":"Benachrichtigungstage für die Erinnerung an die Erneuerung der Einwilligung",
    "TENANT_CONFIG_DISP_RI_INVOICE_RECORD":"Besitzer der Azure-Reservierungsrechnung (Identifikator)",
    "TENANT_CONFIG_DISP_RI_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
    "TENANT_CONFIG_DISP_RI_PRICING_MODEL":"RI-Preismodell",
    "TENANT_CONFIG_DISP_RI_USAGE_MULTIPLY_PERCENTAGE":"Markieren Sie für RI",
    "TENANT_CONFIG_DISP_SEND_INVOICE_EMAILS_AUTOMATICALLY":"Rechnungs-E-Mails automatisch senden",
    "TENANT_CONFIG_DISP_SEND_ZERO_AMOUNT_INVOICES":"Senden Sie Nullbetragsrechnungen",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NAME":"Versand an Kontaktname",
    "TENANT_CONFIG_DISP_SHIP_TO_CONTACT_NUMBER":"Versand an Kontaktnummer",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER":"Versand an PAN-Nummer",
    "TENANT_CONFIG_DISP_SHIP_TO_PAN_NUMBER_DISPALY_TEXT":"Liefern an PAN Nummerntaste",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_TYPE_NAME":"Versand an Steuerart",
    "TENANT_CONFIG_DISP_SHIP_TO_TAX_VALUE":"Versand zum Steuerwert",
    "TENANT_CONFIG_DISP_SHOULD_DEFAULT_ENABLED_FOR_CUSTOMER_DISPALY_TEXT":"Sollte Standard für Kunden aktivieren",
    "TENANT_CONFIG_DISP_SHOULD_SKIP_PAYMENT_FOR_INSTANT_PAY_DISPALY_TEXT":"Befreite Instant Pay-Zahlung",
    "TENANT_CONFIG_DISP_SHOW__SPECIAL_QUALIFICATIONS_FOR_TENANT":"Button „Besondere Qualifikationen anzeigen“",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Schaltfläche zum Abbrechen des NCE-Produkts anzeigen",
    "TENANT_CONFIG_DISP_SHOW_CANCEL_BUTTON_SOFTWARE_SUBSCRIPTION":"Schaltfläche zum Abbrechen des Software-Abonnementprodukts anzeigen",
    "TENANT_CONFIG_DISP_SHOW_NOT_ONBOARDED_SUBSCRIPTION":"Nicht integrierte Abonnements für benutzerdefiniertes Enddatum anzeigen",
    "TENANT_CONFIG_DISP_SHOW_REACTIVATE_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"NCE-Produkt-Reaktivierungsschaltfläche anzeigen",
    "TENANT_CONFIG_DISP_SHOW_SUSPEND_BUTTON_FOR_NCE_PRODUCTS_DISPALY_TEXT":"Schaltfläche zum Anhalten des NCE-Produkts anzeigen",
    "TENANT_CONFIG_DISP_SHOW_TC_ACCEPTANCE_LOG":"Akzeptanzprotokoll der Allgemeinen Geschäftsbedingungen anzeigen",
    "TENANT_CONFIG_DISP_SHOW_UPGRADE_BUTTON_FOR_NCE_PRODUCTS_DISPLAY_TEXT":"Schaltfläche NCE-Produkt-Upgrade anzeigen",
    "TENANT_CONFIG_DISP_SOFTWARE_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Aufschlag für Software-Abonnement",
    "TENANT_CONFIG_DISP_SS_INVOICE_RECORD":"Rechnungsinhaber des Software-Abonnements (Identifikator)",
    "TENANT_CONFIG_DISP_STOP_ALL_TRANSACTIONS":"Stoppen Sie alle Transaktionen",
    "TENANT_CONFIG_DISP_STRING_CONSTANT_FOR_CUSTOMER":"Eindeutige Kennung des Kunden",
    "TENANT_CONFIG_DISP_SUPPORT_CUSTOMER_PRODUCT_AUTO_PRICE_UPDATE":"Automatische Preisaktualisierung des Kundenprodukts",
    "TENANT_CONFIG_DISP_SUSPEND_WHEN_DANGER_THRESHOLD_MET_DISPLAY_TEXT":"Unterbrechen, wenn die Gefahrenschwelle erreicht ist",
    "TENANT_CONFIG_DISP_TAX_CODE":"Steuer-Code",
    "TENANT_CONFIG_DISP_TAX_TYPE_NAME":"Steuertyp",
    "TENANT_CONFIG_DISP_TAX_VALUE":"Steuerwert",
    "TENANT_CONFIG_DISP_THIRD_PARTY_SUBSCRIPTION_MULTIPLY_PERCENTAGE":"Markup für Drittanbieterabonnements",
    "TENANT_CONFIG_DISP_TP_INVOICE_RECORD":"Rechnungsinhaber des Drittanbieter-Abonnements (Kennung)",
    "TENANT_CONFIG_DISP_TRANSACTION_AMOUNT_LIMIT":"Transaktionsbetragslimit",
    "TENANT_CONFIG_REQUIRED_VALIDATION_MESSAGE":"Bitte geben Sie den Wert {{tenantConfigName}} ein",
    "TENANT_CONFIG_VALID_NUMBER_VALIDATION_MESSAGE":"Bitte geben Sie eine gültige Anzahl von {{tenantConfigName}} ein",
    "TENANT_CONFIGURATION_AUTO_RENEW_STATUS_TOOLTIP":"Die Konfiguration der automatischen Verlängerung verwalten ist für diesen Mandanten nicht aktiviert.",
    "TENANT_CONFIGURATION_CUSTOMER_REVERT_SUCCESS":"Die Kundenkonfiguration wurde erfolgreich zurückgesetzt",
    "TENANT_CONFIGURATION_CUSTOMER_SAVE_SUCCESS":"Kundenkonfiguration erfolgreich gespeichert",
    "TENANT_CONFIGURATION_SAVE_SUCCESS":"Kundenkonfiguration für {{customername}} erfolgreich aktualisiert",
    "TENANT_COUNTS":"Anzahl",
    "TENANT_CREATION_FAILED":"Mandantenerstellung fehlgeschlagen",
    "TENANT_EXCEEDED_COUNT_ERROR":"Anzahl der zu erstellenden Mandanten, die {{Count}} nicht überschreitet",
    "TENANT_MANAGEMENT_CREATE_SUBSCRIPTION_TOOLTIP":"Wenn Sie das Azure-Abonnement mit dem Mandanten erstellen möchten, kreuzen Sie es bitte an",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX":"Fügen Sie dem Namen das Präfix „OTU“ hinzu",
    "TENANT_MANAGEMENT_LABEL_ADD_PREFIX_TOOLTIP":"Fügen Sie „OTU“ als Präfix im Mandantennamen hinzu",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN":"Weisen Sie den Mandantenadministrator als Eigentümer zu",
    "TENANT_MANAGEMENT_LABEL_ASSIGN_ADMIN_TOOLTIP":"Weisen Sie den Admin-Benutzer als Eigentümer des erstellten Abonnements zu",
    "TENANT_MANAGEMENT_LABEL_BULK_ADD_TENANT":"Mieter hinzufügen",
    "TENANT_MANAGEMENT_LABEL_CREATE_SUBSCRIPTION":"Abonnements erstellen",
    "TENANT_MANAGEMENT_LABEL_DEPARTMENT":"Abteilung",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME":"Domain Name",
    "TENANT_MANAGEMENT_LABEL_DOMAIN_NAME_TOOLTIP":"Domänennamen beginnen damit.",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT":"Wählen Sie Azure-Planangebot aus",
    "TENANT_MANAGEMENT_LABEL_PLAN_PRODUCT_TOOLTIP":"Verwenden Sie dies, um das richtige Planprodukt auszuwählen, um den Azure-Plan zuzuordnen",
    "TENANT_MANAGEMENT_LABEL_PREFIX":"Präfix",
    "TENANT_MANAGEMENT_LABEL_PREFIX_TOOLTIP":"Fügen Sie dem Mandantennamen ein Präfix hinzu",
    "TENANT_MANAGEMENT_LABEL_SEQUENCE":"Sequenz starten",
    "TENANT_MANAGEMENT_LABEL_SITE":"Seite ",
    "TENANT_MANAGEMENT_LABEL_TENANT_COUNT":"Anzahl der Mieter",
    "TENANT_MANAGEMENT_LABEL_TENANT_NAME":"Name",
    "TENANT_MANAGEMENT_NOTIFICATION_COMPLETED":"Vorgang abgeschlossen. Weitere Einzelheiten finden Sie im Überwachungsprotokoll.",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST":"Anfrage zum Hinzufügen von Mietern wird angenommen und bearbeitet",
    "TENANT_MANAGEMENT_NOTIFICATION_ENQUEUED_REQUEST_FAILED":"Anfrage zum Hinzufügen von Mandanten fehlgeschlagen.",
    "TENANT_MANAGEMENT_NOTIFICATION_SEQUENCE_ERROR":"Bitte geben Sie eine Zahlenfolge an",
    "TENANT_MANAGEMENT_SEQUENCE_TOOLTIP":"Das sequenzielle Inkrement wird als Suffix in Mandantennamen verwendet.",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_HEADER":"Mandantenerstellungsstatus",
    "TENANT_MANAGEMENT_STATUS_NOTIFICATION_INFO":"Bitte warten Sie, während die Mandanten erstellt werden.",
    "TENANT_MANAGEMENT_TENANT_COUNT_TOOLTIP":"Anzahl der zu erstellenden Mandanten.",
    "TENANT_MANAGEMENT_TENANT_NAME_TOOLTIP":"Mieternamen beginnen damit.",
    "TENANT_MANAGEMENT_TEXT_BACK_TO_TENAMT":"Zurück zu den Mietern",
    "TENANT_SEQUENCE":"Reihenfolge",
    "TERM_AND_CONDITIONS_ACCEPTS_TEXT":"Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, erklären Sie sich mit den oben genannten Nutzungsbedingungen einverstanden.",
    "TERM_AND_CONDITIONS_LINK_TEXT":"Nutzungsbedingungen des Partners",
    "TERM_AND_CONDITIONS_READ_TEXT":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen durch.",
    "TERM_DURATION_DESC_DAY(S)":"Tage)",
    "TERM_DURATION_DESC_DAYS":"Tag",
    "TERM_DURATION_DESC_MONTH":"Monat",
    "TERM_DURATION_DESC_MONTH(S)":"Monat(e)",
    "TERM_DURATION_DESC_YEAR":"Jahr",
    "TERM_DURATION_DESC_YEAR(S)":"Jahre)",
    "TERM_DURATION_DESC_YEARS":"Jahre",
    "TERM_DURATION_FILTER_DESC_DAYS":"Tage",
    "TERMS_AND_CONDITIONS":"Geschäftsbedingungen",
    "TERMS_AND_CONDITIONS_ACCEPT":"Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen",
    "TERMS_AND_CONDITIONS_ACCEPT_SUCCESSFULLY":"Allgemeine Geschäftsbedingungen erfolgreich akzeptiert.",
    "TERMS_AND_CONDITIONS_BACK":"Zurück zu den Allgemeinen Geschäftsbedingungen",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen</b>. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>Partner-Nutzungsbedingungen</a></li> </ul> < /br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
    "TERMS_AND_CONDITIONS_CONTENT_TEXT_WITH_ONLY_MCA":"Bitte lesen Sie die unten aufgeführten Geschäftsbedingungen von Microsoft durch. </br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
    "TERMS_AND_CONDITIONS_CONTENT_WHEN_HYPERLINK_IS_CUSTOM":"Bitte lesen Sie den unten bereitgestellten Vertrag, die Allgemeinen Geschäftsbedingungen von <b>{{partnerName}}</b> und Microsoft durch. </br> </br> <ul> <li> <a href='#' ng-click='vm.PartnerAgreementClicked()'>{{hyperlinkContent}}</a></li> </ul> </br> Indem Sie auf die Schaltfläche „Akzeptieren“ klicken, stimmen Sie den obigen Nutzungsbedingungen zu.",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_BTN_WITH_TEXT":"Klicken Sie hier <a id='linkEzidebit'ng-click='vm.EzidebitAgreementClicked()'></a>, um die vollständige Ezidebit-Vereinbarung zu lesen",
    "TERMS_AND_CONDITIONS_EZIDEBIT_AGREEMENT_TITLE":"Ezidebit-Vereinbarung",
    "TERMS_AND_CONDITIONS_READ":"Ich habe die Allgemeinen Geschäftsbedingungen gelesen",
    "TERMS_AND_CONDITIONS_UNABLE_TO_PROCESS":"Ihre Anfrage kann nicht bearbeitet werden.",
    "TEST_EMAIL_LABEL":"Testen Sie die EmailId",
    "TEST_MICROSOFT_PRICING_API_CONSENT_ACCESS_BTN_TEXT":"Prüfung",
    "TEST_PARTNER_ACCESS_BTN_TEXT":"Prüfung",
    "TEST_SMTP_SETTING_TITLE":"Testen Sie die SMTP-Einstellungen",
    "TEXT_ACH":"ACH",
    "TEXT_ACTIONS":"Aktionen",
    "TEXT_BILL_FROM":"Bill From",
    "TEXT_BILL_TO":"Gesetzesentwurf für",
    "TEXT_BILLING_PERIOD":"Abrechnungszeitraum",
    "TEXT_EMAIL_ID":"E-Mail-ID",
    "TEXT_GROUP_NAME":"Gruppenname",
    "TEXT_INVOICE_AMOUNT":"Rechnungsbetrag",
    "TEXT_INVOICE_DATE":"Rechnungsdatum",
    "TEXT_INVOICE_NUMBER":"Rechnungsnummer",
    "TEXT_INVOICE_SUMMARY":"Rechnungszusammenfassung",
    "TEXT_MONTHLY_BUDGET":"Monatliches Budget",
    "TEXT_OFF":"AUS)",
    "TEXT_PLAN_ID":"Plan-ID",
    "TEXT_RESPONSIBLE_OFFICIAL":"Verantwortlicher Beamter",
    "TEXT_SAAS_SUBSCRIPTION_STATUS":"Saas-Abonnementstatus",
    "TEXT_SIGN_IN_REQUIRED":"Anmelden erforderlich",
    "TEXT_TENANT_ID":"Mieter-ID",
    "TEXT_THRESHOLDS":"Schwellenwerte",
    "TEXT_VAT_NUMBER":"Umsatzsteuer-Identifikationsnummer",
    "THE_SEQUENCE_LABEL_THE_STARTING_NAME":"Der Sequenzname beginnt mit",
    "THIRD_PARTY_AGREEMENTS_PLACEHOLDER":"Vereinbarung auswählen",
    "THIRD_PARTY_COMPLETE_MAPPING_FOR_C3_ENTITY_PLACEHOLDER":"Vervollständigen Sie die Zuordnung für die C3-Entität",
    "THIRD_PARTY_ENTITY_MAPPING":"Zuordnung von Drittparteien",
    "THIRD_PARTY_ENTITY_MAPPING_NOTIFIER_TEXT_SAVE_MAPPING":"Möchten Sie die Zuordnung für den Kunden <b>{{customerName}}</b> wirklich speichern?",
    "THIRD_PARTY_PRODUCT_MAPPING":"Produktzuordnung von Drittanbietern",
    "THIRD_PARTY_PRODUCT_MAPPING_POPUP_CONFIRM_TEXT_SAVE_MAPPING":"Möchten Sie die ausgewählten Drittanbieterprodukte wirklich speichern?",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_ACTION":"Aktion",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_AGREEMENT":"Zustimmung",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_C3_CUSTOMER":"C3-Kunde",
    "THIRD_PARTY_PRODUCT_MAPPING_TABLE_HEADER_TEXT_PSA_CUSTOMER":"PSA-Kunde",
    "THIRD_PARTY_PRODUCTS_PLACEHOLDER":"PSA-Dienst auswählen",
    "THIRD_PARTY_SELECT_PSA_CUSTOMER_PLACEHOLDER":"Wählen Sie PSA-Kunde aus",
    "THIRD_PARTY_SUBSCRIPTION_MAPPING":"Zuordnung von Drittanbieterabonnements",
    "TIME_DESC_DAY":"Tag",
    "TIMER_DESC_DAYS":"Tage",
    "TITLE_COUPON_CODE_TEXT":"Gutscheincodes",
    "TITLE_CUMULATIVE_TAXES":"Kumulierte Steuern",
    "TITLE_ROLE_SELECT":"Wählen Sie Rolle",
    "TITLE_SUB_TAXES":"Untersteuern",
    "TITLE_TAGS":"Stichworte",
    "TITLE_TEXT":"Willkommen im CSP Control Center",
    "TO_ONBOARD_AZURE_SUBSCRIPTIONS":"um Azure-Abonnements zu integrieren",
    "TOOL_TIP_TEXT_HIDE":"Verstecken",
    "TOOL_TIP_TEXT_SHOW":"Zeigen",
    "TOOLTIP_COUPON_CODE_TEXT":"Gutscheincode",
    "TOOLTIP_TEXT_DELETE_PSA_LOG":"Protokoll deaktivieren",
    "TOOLTIP_TEXT_DISABLE_PRODUCT":"Produkt deaktivieren",
    "TOOLTIP_TEXT_EDIT_NAME":"Namen bearbeiten",
    "TOOLTIP_TEXT_EDIT_QUANTITY":"Menge bearbeiten",
    "TOOLTIP_TEXT_ENABLE_PRODUCT":"Produkt aktivieren",
    "TOOLTIP_TEXT_LINK_PRODUCT":"Produkt verknüpfen",
    "TOOLTIP_TEXT_LINK_PRODUCT_UNSUPPORT":"Linkprodukt wird nicht unterstützt",
    "TOOLTIP_TEXT_QUANTITY":"Menge",
    "TOUR_POPUP_CONTENT":"<b>Willkommen an Bord! </b></br> Sehen Sie sich eine kurze Tour durch die Funktionen in der Anwendung an.",
    "TOUR_POPUP_DISMISS_BUTTON":"Entlassen",
    "TOUR_POPUP_TAKE_A_TOUR_BUTTON":"Machen Sie eine Tour",
    "TOUR_POPUP_TITLE":"Willkommen zu",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ACTIONS":"Aktionen",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_AMOUNT":"Menge",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CATEGORY":"Kategorie",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_CONVERSION_RATE":"Rate",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DESCRIPTION":"Beschreibung",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_DISCOUNT":"Rabatt",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_PRODUCT_NAME":"Produktname",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_QUANTITY":"Menge",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_RATE":"Rate",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_ROLLED_UP_AMOUNT":"Aufgerollter Betrag",
    "TPT_INVOICE_LINE_ITEM_TABLE_HEADER_TAXED":"Besteuert",
    "TRANSACTION_AMOUNT_LIMIT_EXCEED":"Transaktionsbetrag überschritten",
    "TRANSACTION_LIMIT_MESSAGE":"Verbleibendes Limit {{RemainingAmount}} von {{TotalLimit}}",
    "TRANSACTION_TYPE_PURCHASE":"Neuer Einkauf",
    "TRANSACTION_TYPE_UPDATE":"Mengenaktualisierung",
    "TRANSACTION_TYPE_UPGRADE":"Abonnement-Upgrade",
    "TRIAL_OFFER_IN_PROVISION_MESSAGE":"Derzeit besteht der Antrag, das Testangebot in ein Abonnement umzuwandeln; An diesem Produkt dürfen keine Eingriffe vorgenommen werden.",
    "TRIAL_OFFER_NEAREST_VALUE_DECIMAL_ERROR":"Die beiden nächstgelegenen gültigen Werte sind {{lower}} und {{upper}}.",
    "TRIAL_OFFER_PARENT_BILLING_CYCLE_NAME":"Rechnungskreislauf",
    "TRIAL_OFFER_PARENT_BILLING_TYPE":"Abrechnungsart",
    "TRIAL_OFFER_PARENT_CATEGORY_NAME":"Kategorie",
    "TRIAL_OFFER_PARENT_CONSUMPTION_TYPE":"Verbrauchsart",
    "TRIAL_OFFER_PARENT_IS_AUTO_RENEWABLE":"Ist automatisch erneuerbar",
    "TRIAL_OFFER_PARENT_PRODUCT_NAME":"Produktname",
    "TRIAL_OFFER_PARENT_PROVIDER_NAME":"Anbieter",
    "TRIAL_OFFER_PARENT_PROVIDERSELLINGPRICE":"Verkaufspreis",
    "TRIAL_OFFER_PARENT_SALE_TYPE":"Verkaufsart",
    "TRIAL_OFFER_PARENT_TERM_DURATION":"Laufzeit",
    "TRIAL_OFFER_QUANTITY_HEADER_POPUP":"Setzen Sie das Abonnement nach der Testphase fort",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_INSTRUCTIONS":"Anleitung",
    "TRIAL_OFFER_SUBSCRIPTION_HEADING_TEXT_NOTE":"Notiz",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1":"Die Testphase dauert einen Monat, danach wird das Produkt gekündigt.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_APPEND":"Tage, und das Produkt wird danach storniert.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_1_PREPEND":" Die Testzeit dauert",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_2":"Durch Klicken auf „Abonnement fortsetzen“ wird das Produkt nach Ablauf der Testphase gekauft und die Abrechnung beginnt entsprechend.",
    "TRIAL_OFFER_SUBSCRIPTION_INSTRUCTIONS_3":"Sie haben die Flexibilität, Ihr Abonnement über den Testzeitraum hinaus zu verlängern, indem Sie auf die Schaltfläche „Abonnement kaufen“ klicken.",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_1":"Das standort-/abteilungsübergreifende Testangebot wird nicht in ein Abonnement umgewandelt.",
    "TRIAL_OFFER_SUBSCRIPTION_NOTE_2":"Ein Abonnement für eine Menge wird bei der Betriebseinheit des Kunden erworben.",
    "TRIAL_OFFER_TEXT_QUANTITY_DECIMAL_ERROR":"Bitte geben Sie einen gültigen Wert ein",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MAX_OF_10000":"Die maximale Menge beträgt 10.000",
    "TRIAL_OFFER_TEXT_QUANTITY_SUBCEEDS_MIN_OF_1":"Die Mindestmenge beträgt 1",
    "TRIAL_PERIOD_DAYS_TEXT":"Tage",
    "TROUE_BTN_TEXT_END_TOUR":"Ende der Tour",
    "TROUE_BTN_TEXT_NEXT":"Weiter &raquo;",
    "TROUE_BTN_TEXT_PREV":"&laquo; Vorher",
    "UNABLE_TO_FIND_ANY_AZURE_PLAN_FROM_PC":"Auf dem PC kann kein Azure-Plan gefunden werden",
    "UNASSIGN_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Der Kunde wurde nicht erfolgreich zugewiesen",
    "UNASSIGN_CUSTOMER_TO_ACCOUNT_MANAGER_CONFIRMATION":"Möchten Sie die Zuordnung von {{customer}} aufheben?",
    "UNASSIGN_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESS_MESSAGE":"Reseller ist nicht erfolgreich zugeordnet",
    "UNASSIGNED_CUSTOMER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Nicht zugewiesener Kunde eines Account Managers erfolgreich",
    "UNASSIGNED_RESELLER_OF_AN_ACCOUNT_MANAGER_SUCCESSFULLY":"Nicht zugewiesener Reseller eines Account Managers erfolgreich",
    "UNIQUE_NAME_ERROR":"Dieser Name existiert bereits, bitte wählen Sie etwas anderes.",
    "UNSUPPORTED_FILE_TYPE_SELECTED":"Es werden nur Dateien des Typs jpg, svg, png, gif, bmp unterstützt",
    "UP_GRADE_AZURE_SUBSCRIPTION":"Aktualisieren Sie das Azure-Abonnement auf den Azure-Plan.",
    "UPDATE_CART_LINE_ITEM":"Aktualisierter Warenkorbartikel",
    "UPDATE_CART_LINE_ITEM_FAILURE":"Fehler beim Aktualisieren des Warenkorbartikels",
    "UPDATE_CUSTOMER_GLOBAL_CONFIGURATION_SUCCESS_MESSAGE":"Globale Kundenkonfigurationen erfolgreich aktualisiert",
    "UPDATE_DEFAULT_VALUE":"Als Standard markieren",
    "UPDATE_DUE_DATE_NAME":"Geburtstermin",
    "UPDATE_INVOICE_LINEITEM_PONUMBER":"Rechnungsposition Bestellnummer",
    "UPDATE_INVOICE_PONUMBER":"PO #",
    "UPDATE_INVOICE_PROPERTIES":"Rechnungseigenschaft aktualisieren",
    "UPDATE_MANAGE_RENEWAL_TITLE":"Update-Erneuerung",
    "UPDATE_PARTNER_SETTINGS_EXCEPTION":"Beim Aktualisieren der Partnereinstellungen ist ein Fehler aufgetreten",
    "UPDATE_PARTNER_TAX_DETAILS_EXCEPTION":"Beim Aktualisieren der Steuerdetails ist ein Fehler aufgetreten",
    "UPDATE_PRODUCT_NOT_ALLOWED_AS_LICENSE_ASSIGNMENT_INPROGRESS":"Das Aktualisieren der Produkte ist nicht zulässig, da für das Produkt oder eines seiner Addons eine Lizenzzuweisung ausgeführt wird. Bitte versuch es später.",
    "UPDATE_PROFILE":"Profil erfolgreich aktualisiert",
    "UPDATE_PROFILE_FAILURE":"Profil konnte nicht aktualisiert werden",
    "UPDATE_RESELLER_CONFIGURATION":"Aktualisieren Sie die Reseller-Konfiguration",
    "UPDATE_RESELLER_CONFIGURATION_FAILURE":"Reseller-Konfiguration konnte nicht aktualisiert werden",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION":"Aktualisieren Sie die Standardzahlungsoption des Wiederverkäufers",
    "UPDATE_RESELLER_DEFAULT_PAYMENT_OPTION_FAILURE":"Update Reseller Standard Zahlungsoption Fehler",
    "UPDATE_RESELLER_PAYMENT_PROFILE":"Aktualisiertes Reseller-Zahlungsprofil",
    "UPDATE_RESELLER_PAYMENT_PROFILE_FAILURE":"Reseller-Zahlungsprofil konnte nicht aktualisiert werden",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE":"Reseller Plan Produktpreis aktualisiert",
    "UPDATE_RESELLER_PLAN_PRODUCT_PRICE_FAILURE":"Fehler beim Aktualisieren des Produktpreises des Resellerplans",
    "UPDATE_RESELLER_PROFILE":"Reseller-Profil aktualisieren",
    "UPDATE_RESELLER_PROFILE_FAILURE":"Reseller-Profil konnte nicht aktualisiert werden",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE":"Der Standardwert des Anbieterkunden wurde aktualisiert",
    "UPDATE_SERVICE_PROVIDER_CUSTOMER_DEFAULT_VALUE_FAILURE":"Fehler beim Aktualisieren des Standardwerts des Provider-Kunden",
    "UPDATED_ACCOUNT_MANAGER_DETAILS_SUCCESSFULLY":"Account Manager Details erfolgreich aktualisiert",
    "UPDATED_FAILED_C3_PSA_DATA_FAIL":"PSA-Datenaktualisierung fehlgeschlagen",
    "UPDATED_FAILED_C3_PSA_DATA_STATUS_MESSAGE":"Die Datenaktualisierung ist fehlgeschlagen. Andere Optionen sind verfügbar, sobald Sie auf „OK“ klicken.",
    "UPDATED_PAGE_MODE_TO":"Die Ansicht wurde erfolgreich auf {{PageMode}} aktualisiert",
    "UPDATED_SUCCESS_C3_PSA_DATA_STATUS_MESSAGE":"C3-PSA-Daten wurden erfolgreich mit den neuesten Daten aus dem externen PSA-System aktualisiert",
    "UPGRADE_ELIGIBILITY_CHECK":"Überprüfen Sie die Upgrade-Berechtigung",
    "UPGRADE_FAILED_MSG":"Das Azure-Upgrade ist fehlgeschlagen",
    "UPGRADE_SUCCESS_MSG":"Upgrade-Anfrage wird angenommen und bearbeitet.",
    "UPGRADE_SYNC_PUPUP_TITLE":"Aktualisieren Sie Azure",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE":"Richten Sie sich an das Vertragsstartdatum aus",
    "UPLOAD_CUSTOMER_DETAILS_TO_PSA_ALIGN_WITH_CONTRACT_START_DATE_TOOLTIP":"Richten Sie das Wirksamkeitsdatum des Produkts an die nächste aktuelle Vertragslaufzeit aus.Bitte beachten Sie, dass das nächste aktuelle Datum nur auf monatlicher Basis berechnet wird, auch wenn die Vertragslaufzeit jährlich ist.",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_BATCHID":"Laden Sie Rechnungen in die QuickBooks Online-Stapel-ID hoch",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ERROR_MESSAGE":"Laden Sie die Fehlermeldung \\''Rechnungen in Quickbooks hochladen\\'' hoch",
    "UPLOAD_INVOICES_TO_QUICKBOOKS_ONLINE_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie die ausgewählten Rechnungen wirklich in <b>QuickBooks Online</b> hochladen?",
    "UPLOAD_INVOICES_TO_XERO_BATCHID":"Laden Sie Rechnungen in die Xero-Stapel-ID hoch",
    "UPLOAD_INVOICES_TO_XERO_CONFIRMATION_POPUP_MESSAGE":"Möchten Sie die ausgewählten Rechnungen wirklich auf <b>Xero</b> hochladen?",
    "UPLOAD_INVOICES_TO_XERO_ERROR_MESSAGE":"Hochladen von Rechnungen in die Xero-Fehlermeldung",
    "UPLOAD_RESELLERS_DETAILS_FOR_BULK_ONBOARD":"Reseller-Details hochladen",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_FAILURE":"Der Abonnementverlauf konnte nicht in den externen Dienst hochgeladen werden",
    "UPLOAD_SUBSCRIPTION_HISTORY_TO_EXTERNAL_SERVICE_SUCCESS":"Laden Sie den Abonnementverlauf erfolgreich in den externen Dienst hoch",
    "UPLOAD_TO_PSA_SELECT_ALL":"Wählen Sie Alle",
    "Usage":"Nutzung melden",
    "USAGE_PRODUCT_OWNERSHIP_NAGATIVE_QUANTITY_ERROR_MESSAGE":"Sie können den -ve-Prozentsatz nicht auf einen Wert des verfügbaren Prozentsatzes aktualisieren.",
    "USAGE_PRODUCT_OWNERSHIP_SUBMIT_ERROR":"Sie können den Prozentsatz nicht auf einen Wert aktualisieren, der über dem verfügbaren Prozentsatz liegt.",
    "USAGE_REPORT_BUSY_MESSAGE_GETTING_USAGE_REPORT":"Nutzungsbericht abrufen",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_ID":"Abonnement-ID",
    "USAGE_REPORT_CAPTION_TEXT_AZURE_SUBSCRIPTION_NAME":"Abonnementname",
    "USAGE_REPORT_CAPTION_TEXT_BILL_TO_CUSTOMER":"Rechnung an den Kunden",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_END_DATE":"Gebühr END Datum",
    "USAGE_REPORT_CAPTION_TEXT_CHARGE_START_DATE":"Startdatum der Ladung",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_CUSTOMER":"Kosten für den Kunden",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_POST_TAX":"Kosten für den Partner (nach Steuern)",
    "USAGE_REPORT_CAPTION_TEXT_COST_TO_PARTNER_PRE_TAX":"Kosten für Partner (vor Steuern)",
    "USAGE_REPORT_CAPTION_TEXT_CURRENCY_CODE":"Währungscode",
    "USAGE_REPORT_CAPTION_TEXT_CUSTOMER_NAME":"Kundenname",
    "USAGE_REPORT_CAPTION_TEXT_DOWNLOAD_USAGE_REPORT":"Nutzungsbericht herunterladen",
    "USAGE_REPORT_CAPTION_TEXT_TENANT_NAME":"Name des Mieters",
    "USAGE_REPORT_CAPTION_TEXT_USAGE_REPORT":"Nutzungsbericht",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_AZURE_SUBSCRIPTIONS":"Azure-Abonnements",
    "USAGE_SUBSCRIPTION_CUSTOMER_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_BUTTON_TEXT_BACK_TO_SUBSCRIPTION":"Zurück zu den Abonnements",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_1_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_CAPTION_2_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_DANGER_THRESHOLD":"Gefahrenschwelle",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_ERROR_THRESHOLD":"Fehlerschwelle",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME":"Gruppenname",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_GROUP_NAME_PLACE_HOLDER":"Wähle die Gruppe",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_INFO_THRESHOLD":"Info-Schwelle",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_IS_MANAGED":"Vor Kunden verstecken",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_MONTHLY_BUDGET":"Monatliches Budget",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_SUBSCRIPTION_NAME":"Abonnementname",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGESUBSCRIPTION_FORM_LABEL_WARN_THRESHOLD":"Warnschwelle",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_BUTTON_TEXT_BACK_TO_SUBSCRIPTION_USER":"Zurück zu Abonnementbenutzern",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_CAPTION_TEXT_MANAGE_SUBSCRIPTION_USER":"Abonnementbenutzer verwalten",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_EMAIL":"Email",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_LABELS_ROLE":"Rolle",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_CONTRIBUTOR":"Mitwirkender",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_OWNER":"Inhaber",
    "USAGE_SUBSCRIPTION_CUSTOMER_MANAGEUSERASSIGNMENT_FORM_ROLE_OPTION_READER":"Leser",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_BUTTON_TEXT_EXPORT_USAGE_DETAILS":"Nutzungsdetails exportieren",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_BILLING_DETAILS_OF":"Rechnungsübersicht",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_CAPTION_TEXT_USAGE_DETAILS_OF":"Verwendungsdetails von",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_SPEND":"Verbringen",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_CONSUMED_UNITS":"Verbrauchte Einheiten",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_RESOURCE_TYPE":"Ressourcentyp",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGE_DETAILS_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_ADD_SUBSCRIPTION":"Abonnement hinzufügen",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_BUTTON_TEXT_BACK_TO_CUSTOMERS":"Zurück zu den Kunden",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_CAPTION_AZURE_SUBSCRIPTIONS":"Azure-Abonnements für",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_ALL_SUBSCRIPTION":"Alle Abonnements",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_BILLING_PERIOD":"Abrechnungszeitraum",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_LABEL_GROUP":"Gruppe",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_SUBSCRIPTION":"Abonnement bearbeiten",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_USERS":"Benutzer",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_ACTION_BUTTON_TOOLTIP_VIEW_USAGE_DETAILS":"Verwendungsdetails anzeigen",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ACTION":"Aktionen",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_ESTIMATED_COST":"Geschätzte Kosten",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_GROUP":"Gruppe",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_ID":"Abonnement-ID",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_SUBSCRIPTION_NAME":"Abonnementname",
    "USAGE_SUBSCRIPTION_CUSTOMER_USAGESUBSCRIPTIONS_FORM_TABLE_HEADER_THRESHOLD":"Schwellenwerte",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_ADD_USER_TO_SUBSCRIPTION":"Benutzer zum Abonnement hinzufügen",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_BUTTON_TEXT_BACK_TO_SUBSCRIPTIONS":"Zurück zu den Abonnements",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_CAPTION_TEXT_SUBSCRIPTION_USERS_OF":"Abonnementbenutzer von",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_EMAIL":"Email",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_NAME":"Name",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE":"Rolle",
    "USAGE_SUBSCRIPTION_CUSTOMER_USERASSIGNMENTLIST_TABLE_HEADER_ROLE_BUTTON_TOOLTIP_DELETE_USER":"Benutzer löschen",
    "USER_CREATED_POPUP":"Benutzer erstellt",
    "USER_CREATION_SUCCESS_MESSAGE":"Benutzer {{username}} erfolgreich erstellt",
    "USER_DETAILS_NOT_FOUND_BODY":"Die angemeldeten Benutzerdetails konnten nicht gefunden werden. Bitte wenden Sie sich an den Administrator!",
    "USER_DETAILS_NOT_FOUND_HEADER":"Details nicht gefunden",
    "USER_HAS_BEEN_CREATED":"Der Benutzer wurde mit den folgenden Details erstellt",
    "USER_LICENSE_ASSIGNMENT_BATCH_ID":"BatchId für die Zuweisung von Benutzerlizenzen",
    "USER_LICENSE_ASSIGNMENT_BATCH_STATUS":"Stapelstatus der Benutzerlizenzzuweisung",
    "USER_LICENSE_ASSIGNMENT_BUTTON_COMPLETE":"Getan",
    "USER_LICENSE_ASSIGNMENT_PENDING_STATUS_VIEW_CAPTION":"Lizenzzuweisungsstatus",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_NAME":"Kundenname",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_CUSTOMER_USERS":"Benutzer",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_EMAILS":"E-Mail",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENANT_NAME":"Name des Mieters",
    "USER_LICENSE_ASSIGNMENT_POPUP_LABEL_TENENT_ID":"Mieter-ID",
    "USER_LICENSE_ASSIGNMENT_SUPPORT_TEXT_CONTACT_SUPPORT":"Der Status kann nicht aktualisiert werden. Bitte wenden Sie sich an den Support.",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_ADDON":"Erweiterung",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Bundle":"Bündeln",
    "USER_LICENSE_MANAGEMENT_SUPERTEXT_Linked":"Verknüpft",
    "USER_LICENSE_REMOVAL_PENDING_STATUS_VIEW_CAPTION":"Benutzerentfernungsstatus",
    "USER_LICENSE_TRACKING_BUTTON_TEXT_RELEASE_UNUSED_SEATS":"Lassen Sie nicht verwendete Sitze frei",
    "USER_LICENSE_TRACKING_LABEL_APPLY_TO_ADDONS":"Übernehmen Sie Änderungen auf Addons",
    "USER_LICENSE_TRACKING_LABEL_USERS_OF_PRODUCT":"Benutzer des Produkts",
    "USER_LICENSE_TRACKING_NOTIFICATION_ERROR_OCCURRED_WHILE_RELEASING_SEATS":"Beim Freigeben der Sitze ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
    "USER_LICENSE_TRACKING_NOTIFICATION_RELEASE_IS_NOT_POSSIBLE":"Es sollte mindestens eine aktive Lizenz vorhanden sein. Bitte setzen Sie das Produkt aus, wenn Sie es nicht mehr verwenden möchten.",
    "USER_LICENSE_TRACKING_NOTIFICATION_REQUEST_TO_RELEASE_SEATS_IS_BEING_PROCESSED":"Antrag auf Sitzfreigabe wird bearbeitet.",
    "USER_LICENSE_TRACKING_NOTIFICATION_SUCCESSFULLY_UPLOADED":"Erfolgreich hochgeladen",
    "USER_LICENSE_TRACKING_NOTIFICATION_USERS_ADDED_SUCCESSFULLY":"Die Anforderung zum Hinzufügen von Lizenzen wird akzeptiert und bearbeitet.",
    "USER_LICENSE_TRACKING_NOTIFICATION_YOUR_BULK_ASSIGNMENT_REQUEST_IN_QUEUE":"Der Antrag auf Massenzuweisung von Lizenzen wird angenommen und bearbeitet.",
    "USER_LICENSE_TRACKING_TEXT_ASSIGNED":"Zugewiesen",
    "USER_LICENSE_TRACKING_TEXT_TOTAL_SEATS":"Gesamtzahl der Sitzplätze",
    "USER_LISTING_FILTER_LABEL":"E-Mail-ID",
    "USER_LISTING_FILTER_PLACEHOLDER_TEXT":"Suche nach Vorname, Nachname, E-Mail-ID",
    "USER_LISTING_SEARCH_BUTTON":"Suche",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_BUTTON":"Benutzerzuweisungsbericht exportieren",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_ENTITIES_LABEL":"Entitäten",
    "USER_MANAGEMENT_EXPORT_USER_ASSIGNMENT_REPORT_VALIDATION_ERROR":"Wählen Sie mindestens eine Entität aus",
    "USER_REMOVED":"Benutzer erfolgreich entfernt",
    "USER_ROLE_LIST_LABEL_ROLE_NAME":"Rollenname",
    "USER_ROLE_LIST_LABEL_SCOPE":"Bereichsname",
    "USER_ROLE_LIST_LABEL_USER_NAME":"Nutzername",
    "USERCONTROLLER_USER_ADD_SUCCESSFULLY":"Benutzer erfolgreich hinzugefügt",
    "USERCONTROLLER_USERUPDATESUCCESS":"Benutzerdetails erfolgreich aktualisiert",
    "USERS_ACCESS_REVOKED_SUCCEFULLY_MESSAGE":"Benutzerzugriff widerrufen",
    "USERS_ADD_EDIT_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "USERS_ADD_EDIT_CAPTION_TEXT_ADD":"Hinzufügen",
    "USERS_ADD_EDIT_CAPTION_TEXT_EDIT":"Bearbeiten",
    "USERS_ADD_EDIT_CAPTION_TEXT_USER":"Benutzer unter",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_1":"ist nicht verfügbar",
    "USERS_ADD_EDIT_FORM_EMAIL_VALID_MESSAGE_2":"ist verfügbar",
    "USERS_ADD_EDIT_FORM_HEADER_USER_INFO":"Benutzerinformation",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_1":"Aktivieren/deaktivieren Sie die Kontrollkästchen neben den Angeboten zum Zuweisen bzw. Freigeben von Lizenzen",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_2":"Wenn für ein Angebot nicht genügend kostenlose Lizenzen verfügbar sind, wird ein Kauf getätigt, bevor der Auftrag ausgeführt wird",
    "USERS_ADD_EDIT_FORM_INSTRUCTION_PART_3":"zeigt an, dass ein Abonnement von C3 bereitgestellt wird. Bitte warten Sie, bis der Vorgang abgeschlossen ist.",
    "USERS_ADD_EDIT_FORM_LABELS_DISPLAY_NAME":"Anzeigename",
    "USERS_ADD_EDIT_FORM_LABELS_EMAIL":"Email",
    "USERS_ADD_EDIT_FORM_LABELS_FIRST_NAME":"Vorname",
    "USERS_ADD_EDIT_FORM_LABELS_INSTRUCTION":"Anleitung",
    "USERS_ADD_EDIT_FORM_LABELS_LAST_NAME":"Nachname",
    "USERS_ADD_EDIT_FORM_LABELS_USAGE_LOCATION":"Verwendungsort",
    "USERS_ADD_EDIT_FORM_SEATS":"Sitze",
    "USERS_ADD_OR_EDIT_BUTTON_TEXT_BACK_TO_USERS":"Zurück zu den Benutzern",
    "USERS_ADD_OR_EDIT_CAPTION_1":"Hinzufügen",
    "USERS_ADD_OR_EDIT_CAPTION_2":"Bearbeiten",
    "USERS_ADD_OR_EDIT_CAPTION_3":"Benutzer",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_EMAIL":"Email",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_FIRST_NAME":"Vorname",
    "USERS_ADD_OR_EDIT_TEXT_BOX_LABEL_LAST_NAME":"Nachname",
    "USERS_ADDUSER_COMPLETE_LAST_UPLOAD":"Der letzte Massen-Upload zum Erstellen von Benutzern ist abgeschlossen!",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_CANOT_GANT_PERMISION_EXTERNAL_USER":"Externen Benutzern können keine Kundenadministrationsrechte gewährt werden",
    "USERS_ASSIGNCUSTOMERADMINPERMISSION_GRAND_PERMISION_CONFORMATION ":"Sind Sie sicher, dass Sie dem Kunden Administratorrechte gewähren möchten?",
    "USERS_BREADCRUM_BUTTON_TEXT_DASHBOARD":"Dashboard",
    "USERS_BREADCRUM_BUTTON_TEXT_USERS":"Benutzer",
    "USERS_BREADCRUMB_BUTTON_TEXT_ONBOARDING_REPORT":"Onboarding Analytics",
    "USERS_BULK_CREATION_ENQUEUED_SUCCESS":"Ihre Anfrage zum Erstellen von Benutzern wird in die Warteschlange gestellt. Bitte kehren Sie nach einiger Zeit zu dieser Seite zurück, um Updates zu erhalten. Der Vorgang dauert in der Regel einige Minuten.",
    "USERS_BULK_CREATION_ERRORS_DOWNLOAD_ERROR":"Beim Exportieren der Fehlerdetails ist ein Fehler aufgetreten",
    "USERS_BULK_CREATION_FIX_ERRORS":"Bitte korrigieren Sie die in der gerade heruntergeladenen Datei gemeldeten Fehler und versuchen Sie es erneut.",
    "USERS_BULK_CREATION_STATUS_SUCCESS":"Ihre Anforderung zum Erstellen von Benutzern wurde erfolgreich abgeschlossen",
    "USERS_BULKUSERUPLOADSTATUS_GET_UPLOAD_USERDETAILS":"Erhalten Sie hochgeladene Benutzerdetails",
    "USERS_BULKUSERUPLOADSTATUS_REMAINING_OUTOF":"{{AvailableUnits}} verbleibt außerhalb von {{TotalUnits}}",
    "USERS_BUY_MORE":"Kauf mehr",
    "USERS_CREATE_SUCCESS":"Benutzer {{username}} erfolgreich erstellt",
    "USERS_DELETE_CONFIRM":"Alle zugewiesenen Lizenzen werden entfernt und {{userName}} wird gelöscht. Wollen Sie fortfahren?",
    "USERS_DELETE_EXISTENCE_CONFIRM":"Dieser Benutzer hat in C3 Zugriff, der gelöscht wird. Wollen Sie fortfahren?",
    "USERS_DELETE_SUCCESS":"Das Löschen des {{username}} war erfolgreich",
    "USERS_DOWNLOADTEMPLATE_DATANOTFOUND":"Daten nicht gefunden. Bitte versuch es später",
    "USERS_GRANT_ACCESS_CONFIRM":"Sind Sie sicher, dass Sie {{username}} das Administratorrecht für Kunden gewähren möchten?",
    "USERS_GRANT_ACCESS_SUCCESS":"Gewährt erfolgreich Zugriff auf {{username}}",
    "USERS_GRANT_PERMISSIONS_TO_EXTERNAL_USERS_WARN":"Das Gewähren des Zugriffs auf externe Benutzer ist nicht zulässig",
    "USERS_IGNOREERRORS_IGNOR_ERRORS":"Sind Sie sicher, dass Sie fortfahren möchten?",
    "USERS_LICENSE_FREE_OUT_OF":"Lizenzen frei von",
    "USERS_LICENSES_ASSIGNMENT_IN_PROGRESS":"Arbeiten zum Zuweisen von Lizenzen für den Benutzer {{username}}. Dieser Vorgang würde einige Minuten dauern.",
    "USERS_LICENSES_REVOKE_SUCCESS":"Lizenzen von {{username}} erfolgreich widerrufen",
    "USERS_LICENSES_REVOKE_SUCCESS_ASSIGNMENT_IN_PROGRESS":"Lizenzen von {{username}} erfolgreich widerrufen. Arbeiten an der Zuweisung neuer Lizenzen. Dieser Vorgang dauert einige Minuten.",
    "USERS_LICENSES_UPDATE_SUCCESS":"Lizenzen für Benutzer {{username}} wurden erfolgreich aktualisiert",
    "USERS_LIST_BUTTON_TEXT_ADD_NEW_USERS":"Neuen Benutzer hinzufügen",
    "USERS_LIST_BUTTON_TEXT_BULK_USER_UPLOAD":"Upload mehrerer Benutzer",
    "USERS_LIST_CAPTION_TEXT_USERS":"Benutzer",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_EDIT_USER_DETAILS":"Benutzerdetails bearbeiten",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_GRAND_ACCESS_TO_PORTAL":"Gewähren Sie Zugriff auf das Portal",
    "USERS_LIST_TABLE_ACTION_BUTTON_TOOLTIP_RESET_PASSWORD":"Passwort zurücksetzen",
    "USERS_LIST_TABLE_ACTION_VIEW_LICENSE":"Lizenzen anzeigen",
    "USERS_LIST_TABLE_HEADER_ACTION":"Aktionen",
    "USERS_LIST_TABLE_HEADER_EMAIL_ID":"E-Mail-ID",
    "USERS_LIST_TABLE_HEADER_IS_ACTIVE":"Ist aktiv",
    "USERS_LIST_TABLE_HEADER_NO_USER_ROLE_ASSIGNED":"Keine Benutzerrollen zugewiesen.",
    "USERS_LIST_TABLE_HEADER_ROLE":"CSP-Rolle (n)",
    "USERS_LIST_TABLE_HEADER_USER_NAME":"Nutzername",
    "USERS_LIST_TABLE_ROLE_TOOLTIP":"Rollen anzeigen",
    "USERS_PASSWORD_RESET_SUCCESS":"Das Passwort für {{username}} wurde erfolgreich zurückgesetzt",
    "USERS_RESET_USER_PASSWORD_NOTIFICATION_SENT":"Passwort erfolgreich an die angegebenen Empfänger gesendet",
    "USERS_RESETUSERPASSWORD_PASSWORD_RESET":"Passwort zurückgesetzt erfolgreich",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD":"Das Zurücksetzen des Passworts ist erfolgreich. Das Passwort lautet: <a ngclipboard='' data-clipboard-text='{{Password}}' ng-click='vm.confirmCopy()'><b>{{Password}}</b></a><br/><br/><b> Nachdem Sie diese Seite verlassen haben, können Sie dieses temporäre Passwort nicht mehr anzeigen. </ b> <br/> <br/> Wenn Sie das Passwort per E-Mail senden möchten, geben Sie dies bitte an Die durch Kommas getrennten Empfänger im Textfeld unten drücken andernfalls auf Abbrechen. <br/>",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD_1":"Das Zurücksetzen des Passworts war erfolgreich. Das Passwort lautet:",
    "USERS_RESETUSERPASSWORD_SEND_PASSWORD_2":"<br/><br/><b>Nachdem Sie diese Seite verlassen haben, können Sie dieses temporäre Passwort nicht mehr anzeigen.</b><br/><br/>Wenn Sie das Passwort per E-Mail versenden möchten, geben Sie die durch Kommas getrennten Empfänger bitte in das Textfeld unten ein. Andernfalls drücken Sie auf „Abbrechen“.<br/>",
    "USERS_REVOKE_ACCESS_CONFIRM":"Sind Sie sicher, dass Sie {{UserRoleDescription | translate}} Privileg von {{username}}",
    "USERS_REVOKE_ACCESS_SUCCESS":"Zugriff von {{username}} erfolgreich widerrufen",
    "USERS_STATUS_BUTTON_GET_UPLOAD_USER_DETAILS":"Erhalten Sie hochgeladene Benutzerdetails",
    "USERS_STATUS_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "USERS_STATUS_BUTTON_TRY_A_NEW_UPLOAD":"Versuchen Sie einen neuen Upload!",
    "USERS_STATUS_BUTTON_VIEW_FIX_ERRORS":"Fehler anzeigen und beheben",
    "USERS_STATUS_CAPTION_STATUS":"Status",
    "USERS_STATUS_WARNING_MESSAGE":"Die Erstellung von Benutzern aus dem letzten Upload ist noch nicht abgeschlossen. Bitte versuchen Sie es erneut, indem Sie auf der Benutzerseite auf die Schaltfläche \\''BulkUserUpload\\'' klicken.",
    "USERS_TABLE_HEADER_EMAIL":"Email",
    "USERS_TABLE_HEADER_NAME":"Name",
    "USERS_TABLE_HEADER_PLAN_DESCRIPTION":"Planbeschreibung",
    "USERS_UPDATE_LICENCES_QUEUED":"Lizenzen werden zur Aktualisierung in die Warteschlange gestellt",
    "USERS_UPDATE_SUCCESS":"Benutzerdetails für {{username}} wurden erfolgreich aktualisiert",
    "USERS_UPDATELICENCES_SUCCESS":"Lizenzen erfolgreich aktualisiert",
    "USERS_UPDATEUSERDETAILS_UPDATED_SUCCESS":"Benutzerdetails für {{username}} wurden erfolgreich aktualisiert",
    "USERS_UPLOAD_CAPTION_TEXT_UPLOAD":"Hochladen",
    "USERS_UPLOAD_DATA_INVALID_ERROR":"In den hochgeladenen Daten wurden Fehler festgestellt. Laden Sie die Fehler herunter, beheben Sie sie und versuchen Sie es erneut.",
    "USERS_UPLOAD_ERROR":"Beim Parsen der hochgeladenen Datei ist ein Fehler aufgetreten",
    "USERS_UPLOAD_FILE_NOT_PROVIDED_PROMPT":"Bitte wählen Sie eine Datei zum Hochladen aus",
    "USERS_UPLOAD_FORM_BUTTON_TEXT_REMOVE":"Entfernen",
    "USERS_UPLOAD_FORM_HEADER_USER_INFO":"Benutzerinformation",
    "USERS_UPLOAD_FORM_INSTRUCTIONS":"Anleitung",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_1":"Laden Sie Kontodaten für mehrere Benutzer im CSV-Format hoch. Laden Sie eine Z.Bdatei .csv <strong><a ng-click='vm.DownloadTemplate()'> herunter. Laden Sie eine Z.Bdatei .csv </a></strong> als Referenz herunter.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_2":"Diese Datei kann verwendet werden, um mehrere Benutzerkonten für einen Kunden zu erstellen. Aktualisieren oder löschen Sie keine vorhandenen Texte oder Daten in dieser Datei. Benutzerkonten werden mit Benutzerberechtigungen (kein Administrator) erstellt",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_3":"Der Vorname ist ein optionales Feld, das null bis 50 Zeichen unterstützt. Ein Z.B: Chris.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_4":"Nachname ist ein optionales Feld, das null bis 50 Zeichen unterstützt. Ein Z.B: Grün.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_5":"Der Anzeigename ist ein Pflichtfeld, das ein bis 50 Zeichen unterstützt. Ein Z.B: Chris Green.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_6":"E-Mail ist ein Pflichtfeld. Geben Sie in dieses Feld für jeden Benutzerdatensatz eine gültige E-Mail-Adresse ein. Ein Beispiel: chris@contoso.com.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_7":"Das Feld für die Statusaktualisierung wird automatisch ausgefüllt, um den Validierungsstatus anzuzeigen. Lassen Sie dieses Feld leer.",
    "USERS_UPLOAD_FORM_INSTRUCTIONS_PART_8":"Laden Sie die Datei hoch und klicken Sie auf <strong class=\"text-uppercase\">Senden</strong>.",
    "USERS_UPLOAD_FORM_LABEL_UPLOAD_FILE":"Datei hochladen",
    "USERS_UPLOAD_FORM_LABEL_USAGE_LOCATION":"Verwendungsort",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_LICENSES":"Zurück zu den Lizenzen",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_BACK_TO_USERS":"Zurück zu den Benutzern",
    "USERS_VIEWLICENSES_EDITLICENSES_BUTTON_TEXT_EDIT_LICENSES":"Lizenzen bearbeiten",
    "USERS_VIEWLICENSES_EDITLICENSES_CAPTION_TEXT_LICENSE_ASSIGNED_FOR":"Zugewiesene Lizenzen für",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_LICENSE_ASSIGNED_DATE":"Zuweisungsdatum der Lizenz",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_PRODUCT":"Produkt",
    "USERS_VIEWLICENSES_EDITLICENSES_TABLE_HEADER_SUMMERY":"Zusammenfassung",
    "VALIDATE_LIST":"Liste validieren",
    "VALIDATE_PRODUCT_QUANTITY":"{{product}} Menge {{quantity}} sollte zwischen {{minQuantity}} und {{maxQuantity}} liegen",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_FAILURE":"Der Abonnementverlauf für den externen Dienst konnte nicht validiert werden",
    "VALIDATE_SUBSCRIPTION_HISTORY_FOR_EXTERNAL_SERVICE_SUCCESS":"Abonnementverlauf für externen Dienst erfolgreich validiert",
    "VALIDATION_MESSAGE_ALREADY_LINKED_CUSTOMER_WITH_THIS_TENANT":"Bereits mit diesem Mieter verbundener Kunde.",
    "VALIDATION_MESSAGE_COUPON_CODE_ALREADY_EXISTS":"Dieser Gutscheincode existiert bereits",
    "VALIDATION_MESSAGE_COUPON_CODE_INVALID_SPECIAL_CHARACTER":"Im Gutscheincode sind nur alphanumerische Zahlen erlaubt!",
    "VALIDATION_MESSAGE_DISCOUNT_VALEU":"Wir gewähren Rabatte nur zwischen 0 und 100",
    "VALIDATION_MESSAGE_EMAIL_ALREADY_EXIST":"Diese E-Mail '{{emailAddress}}' wird bereits verwendet.",
    "VALIDATION_MESSAGE_ENTER_VALID_PHONE_NUMBER":"Bitte geben Sie eine gültige Nummer ein",
    "VALIDATION_MESSAGE_FOR_ADDING_NEW_USER_IN_QUOTE_CONTACT":"Füllen Sie vor dem Absenden bitte alle erforderlichen Felder aus.",
    "VALIDATION_MESSAGE_FOR_INVOICE_DATE_LESS_THAN_DUE_DATE":"Das Rechnungsdatum muss vor dem Fälligkeitsdatum liegen.",
    "VALIDATION_MESSAGE_FOR_LINKTOCONTACT":"Bitte geben Sie eine gültige URL ein oder geben Sie die Standard-URL als '/#/contact/' ein.",
    "VALIDATION_MESSAGE_FOR_START_DATE_LESS_THAN_END_DATE":"Das Startdatum muss vor dem Enddatum liegen.",
    "VALIDATION_MESSAGE_FOR_URL":"Bitte geben Sie eine gültige URL ein.",
    "VALIDATION_MESSAGE_FOR_URL_LINKTOSUPPORT":"Bitte geben Sie eine gültige URL ein oder geben Sie die Standard-URL als \\''home.support\\'' ein.",
    "VALIDATION_MESSAGE_FOR_VALID_ZIP_CODE":"Bitte geben Sie eine gültige Postleitzahl ein",
    "VALIDATION_MESSAGE_MAX_IIN_LENGTH_22":"Die IIN-Länge des Unternehmens sollte kleiner oder gleich 22 sein",
    "VALIDATION_MESSAGE_MAX_LENGTH_50":"Dieses Feld akzeptiert maximal 50 Zeichen.",
    "VALIDATION_MESSAGE_MIN_IIN_LENGTH_5":"Die IIN-Länge des Unternehmens sollte größer als 4 sein",
    "VALIDATION_MESSAGE_MINIMUM_0":"Mindestwert ist 0",
    "VALIDATION_MESSAGE_MINIMUM_1":"Mindestwert ist 1",
    "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_EMAIL":"Bitte geben Sie eine gültige Email-Adresse ein",
    "VALIDATION_MESSAGE_PLEASE_ENTER_A_VALID_PHONE_NUMBER":"Bitte geben Sie eine gültige Telefonnummer ein",
    "VALIDATION_MESSAGE_REQUIRED":"Dieses Feld wird benötigt",
    "VALIDATION_MESSAGE_REQUIRED_NEGATIVE_VALUE":"Geben Sie einen gültigen Umrechnungskurs ein",
    "VALIDITY_TEXT_FROM":"Von",
    "VALIDITY_TEXT_TO":"Zu",
    "VALIDITY_TYPE_DESC_DAYS":"Tage)",
    "VALIDITY_TYPE_DESC_MONTHS":"Monat(e)",
    "VALIDITY_TYPE_DESC_YEARS":"Jahre)",
    "VALIDITY_TYPE_FILTER_TOOLTIP_TEXT":"Gültigkeitsart",
    "VALIDITY_TYPE_NAME_DAYS":"Tage)",
    "VALIDITY_TYPE_NAME_MONTHS":"Monat(e)",
    "VALIDITY_TYPE_NAME_YEARS":"Jahre)",
    "VALUE_SHOULD_BE_GREATER_THAN":"Der Wert sollte größer als 0 sein",
    "VAT_NUMBER_DESC":"Umsatzsteuer-Identifikationsnummer für den Kunden",
    "VAT_NUMBER_DESC_FOR_RESELLER":"Umsatzsteuer-Identifikationsnummer für Wiederverkäufer",
    "VAT_NUMBER_DISP":"Umsatzsteuer-Identifikationsnummer",
    "VAT_NUMBER_DISP_FOR_RESELLER":"Umsatzsteuer-Identifikationsnummer",
    "VERIFY_TOKEN_VALIDITY_BUTTON_TEXT":"Token validieren",
    "VIEW_ALL_SELECTION":"Wählen Sie Alle",
    "VIEW_MORE_DETAILS_TOOLTIP_AUDIT_LOG":"Weitere Details anzeigen",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_INVOICES":"Verwendungsposten anzeigen",
    "VIEW_USAGE_LINE_ITEMS_TOOLTIP_TEST_INVOICES":"Verwendungsposten anzeigen",
    "VIEWS_CUSTOMER_BUTTON_GENERATE_INVOICE":"Rechnung erstellen",
    "VIEWS_CUSTOMER_BUTTON_RELOADPROFITABILITYREPORT":"Profitability Report neu laden",
    "VIEWS_HOME_BUTTONS_TEXT_ADD_USER":"Nutzer hinzufügen",
    "VIEWS_HOME_BUTTONS_TOOLTIPS_TEXT_DELETE_USERS":"Benutzer löschen",
    "VIEWS_HOME_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "VIEWS_HOME_TABLE_HEADER_TEXT_EMAIL":"Email",
    "VIEWS_HOME_TABLE_HEADER_TEXT_NAME":"Name",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Tags zuweisen",
    "VIEWS_HOME_USERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rollen bearbeiten",
    "VIEWS_PARTNERS_BUTTONS_TEXT_ADD_PARTNER_USER":"Partnerbenutzer hinzufügen",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_ASSIGN_TAGS":"Tags zuweisen",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_DELETE_PARTNER":"Partner löschen",
    "VIEWS_PARTNERS_BUTTONS_TOOLTIPS_TEXT_EDIT_ROLES":"Rollen bearbeiten",
    "VIEWS_PARTNERS_LINK_PARTNER_USER_MANAGEMENT":"Partner User Management",
    "VIEWS_PARTNERS_LINK_USER_MANAGEMENT":"Benutzerverwaltung",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ACTIONS":"Aktionen",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_EMAIL":"Email",
    "VIEWS_PARTNERS_TABLE_HEADER_TEXT_ROLES":"Rollen",
    "VIEWS_TABLE_HEADER_TEXT_ROLES":"Rollen",
    "viewuploadhistory":"Nutzung melden",
    "WAITING_FOR_APPROVAL":"warten auf die Bestätigung",
    "WARNING_MESSAGE_TO_DESELECT_ALL_CUSTOMER_OR_RESELLER":"Sie haben alle abgewählt",
    "WARNING_MESSAGE_TO_SELECT_ALL_CUSTOMER_OR_RESELLER":"Sie haben alle ausgewählt",
    "WEB_NOTIFICATION_SEARCH_PLACEHOLDER":"Geben Sie einen Entitätsnamen ein",
    "WEBHHOOK_LABEL_EFFECTIVE_FROM":"Gültig ab",
    "WEBHHOOK_LABEL_EFFECTIVE_TO":"Wirksam für",
    "WEBHHOOK_LABEL_ENTITY":"Einheit",
    "WEBHHOOK_LABEL_EVENT":"Veranstaltung",
    "WEBHOOK_ASSOCIATED_ENTITY_TOOLTIP":"Getaggte Entitäten",
    "WEBHOOK_BREADCRUMB_ADD":"Webhook erstellen",
    "WEBHOOK_BUTTON_ACTIONS_ADD":"Erstellen Sie eine Webhook-Benachrichtigung",
    "WEBHOOK_BUTTON_TEXT_BACK_TO_LIST":"Zurück zur Liste",
    "WEBHOOK_CANCEL_NOTIFICATION":"Stornieren",
    "WEBHOOK_DESCRIPTION_TOOLTIP":"Beschreibung eingeben",
    "WEBHOOK_EFFECTIVE_DATE_TO_TOOLTIP":"Wählen Sie „Gültig bis heute“.",
    "WEBHOOK_END_DATE_PLACEHOLER":"Endtermin",
    "WEBHOOK_ENTITY_TOOLTIP":"Kategorie wählen",
    "WEBHOOK_EVENT_TOOLTIP":"Wählen Sie Ereignis",
    "WEBHOOK_HEADER_ACTIONS":"Aktionen",
    "WEBHOOK_HEADER_ASSOCIATED_ENTITIES":"Assoziierte Einheiten",
    "WEBHOOK_HEADER_CREATET_DATE":"Erstellungsdatum",
    "WEBHOOK_HEADER_DESCRIPTION":"Beschreibung",
    "WEBHOOK_HEADER_EFFECTIVE_FROM":"Gültig ab",
    "WEBHOOK_HEADER_EFFECTIVE_TO":"Wirksam für",
    "WEBHOOK_HEADER_EVENTNAME":"Veranstaltung",
    "WEBHOOK_HEADER_TITLE":"Titel",
    "WEBHOOK_LABEL_DESCRIPTION":"Beschreibung",
    "WEBHOOK_LABEL_EFFECTIVE_TO":"Wirksam zu",
    "WEBHOOK_LABEL_ENTITY_TEXT":"Kategorie",
    "WEBHOOK_LABEL_EVENT_TEXT":"Veranstaltung",
    "WEBHOOK_LABEL_TITLE_TEXT":"Titel",
    "WEBHOOK_LABEL_WEBHOOK_METHOD_TEXT":"Webhook-Methode",
    "WEBHOOK_LABEL_WEBHOOK_RETRY_COUNT_TEXT":"Anzahl der Wiederholungen",
    "WEBHOOK_LABEL_WEBHOOK_URL_TEXT":"Webhook-URL",
    "WEBHOOK_METHOD_METHOD_TOOLTIP":" Methode, die beim Senden von Daten an ein externes System über einen Webhook verwendet werden soll.",
    "WEBHOOK_METHOD_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
    "WEBHOOK_NOTIFCATION_ERROR_MESSAGE":"Beim Senden der Webhook-Benachrichtigung ist ein Fehler aufgetreten",
    "WEBHOOK_NOTIFCATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich gesendet",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_DESCRIPTION":"Beschreibung",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_ID":"Ich würde",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_RETRY_COUNT":"Alte Wiederholungsanzahl",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_OLD_WEBHOOK_URL":"Alte Webhook-URL",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_PRODUCT_NAME":"Produktname",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_RETRY_COUNT":"Anzahl der Wiederholungen",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_TITLE":"Titel",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_URL":"Webhook-URL",
    "WEBHOOK_NOTIFICATION_AUDIT_LOG_WEBHOOK_METHOD":"Webhook-Methode",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_PRODUCT_SELECT_LIMIT_ERROR":"Es kann nur ein Produkt ausgewählt werden.",
    "WEBHOOK_NOTIFICATION_ERROR_MESSAGE_WHILE_PRODUCT_SELECT":"Fehler bei der Produktauswahl",
    "WEBHOOK_NOTIFICATION_SAVE_NOTIFICATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich erstellt",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_ALL_PRODUCT_SELECT":"Alle Produkte wurden ausgewählt",
    "WEBHOOK_NOTIFICATION_SUCCESS_MESSAGE_FOR_PRODUCT_SELECT":"Produkt wurde ausgewählt.",
    "WEBHOOK_NOTIFICATION_TAGGED_ENTITIES_ELENMENTS_POPUP_HEADER":"Zugehörige Entitätselemente",
    "WEBHOOK_NOTIFICATION_UPDATE_NOTIFICATION_SUCCESS_MESSAGE":"Webhook-Benachrichtigung erfolgreich aktualisiert",
    "WEBHOOK_NOTIFICATION_WARNING_MESSAGE_WHILE_PRODUCT_SELECT":"Alle Produkte wurden ausgewählt.",
    "WEBHOOK_SAVE_NOTIFICATION":"einreichen",
    "WEBHOOK_SELECT_PRODUCT_BUTTON":"Wählen",
    "WEBHOOK_SELECT_PRODUCT_TOOLTIP":"Wählen Sie das zugehörige Entitätselement aus",
    "WEBHOOK_START_DATE_PLACEHOLER":"Anfangsdatum",
    "WEBHOOK_TAB_HEADING_TEXT_WEBHOOK":"Webhook-Benachrichtigung",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_LENGTH_OF_30":"Dies überschreitet die maximale Länge von 30",
    "WEBHOOK_TEXT_EXCEEDS_THE_MAX_OF_3":"Bitte stellen Sie sicher, dass der Wert zwischen 0 und 3 liegt",
    "WEBHOOK_THIS_FIELD_IS_REQUIRED":"Dieses Feld wird benötigt",
    "WEBHOOK_TITLE_TOOLTIP":"Geben Sie den Titel ein",
    "WEBHOOK_URL_TOOLTIP":" Geben Sie die Ziel-URL ein, an die das System HTTP-Anfragen sendet, um einen externen Dienst über bestimmte Ereignisse oder Aktualisierungen zu benachrichtigen. Diese URL sollte in der Lage sein, Webhook-Nutzlasten zu empfangen und zu verarbeiten.",
    "WEBHOOK_VALIDATE_BUTTON":"Bestätigen",
    "WEBHOOK_VALIDATE_URL":"Bitte validieren Sie die URL",
    "WEBHOOK_VALIDATE_URL_BUTTON":"URL validieren",
    "WEBHOOK_VALIDATION_ERROR_MESSAGE":"Ungültige Webhook-URL",
    "WEBHOOK_VALIDATION_SUCCESS_MESSAGE":"Die Webhook-URL ist gültig",
    "WEBHOOK_WEBHOOK_RETRY_COUNT_TOOLTIP":" Anzahl der Versuche, die das System unternimmt, um im Fehlerfall eine bestimmte Aktion auszuführen.",
    "WEBHOOK_WEBHOOK_URL_TOOLTIP":"Webhook-URL",
    "webhookNotifications":"Webhook",
    "WELCOME_HEADER_1_PART_1":"Bei uns haben Sie unendlich viele Möglichkeiten.",
    "WELCOME_HEADER_1_PART_2":"Und es beginnt jetzt.",
    "WELCOME_PORTAL_FEATUR_1":"Self-Service-Portal",
    "WELCOME_PORTAL_FEATUR_2":"Abonnements schnell und einfach hinzufügen/entfernen",
    "WELCOME_PORTAL_FEATUR_3":"Automatisierte Zahlungen",
    "WELCOME_PORTAL_FEATUR_4":"Rechnungen automatisch bezahlen",
    "WELCOME_PORTAL_FEATUR_5":"Sichern",
    "WELCOME_PORTAL_FEATUR_6":"Gesichert mit Microsoft Azure Active Directory und Multi-Faktor-Authentifizierung",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_1_LOGIN":"Anmeldung",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_2_WELCOME":"Herzlich willkommen",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_3_LOGOUT":"Ausloggen",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_4_CONTACT":"Kontaktiere uns",
    "WELCOMELAYOUT_BEGIN_NAVIGATION_5_DASHBOARD":"Zum Dashboard gehen",
    "Won''t Fix":"Wird nicht repariert",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE":"Vielen Dank für Ihre Zustimmung zu Xero. Die Anwendung kann so konfiguriert werden, dass sie gemäß der neuen Sicherheitsrichtlinie auf Xero zugreift. Sie können die Rechnungen Ihrer Kunden an Xero senden.",
    "XERO_CONSENT_CAPTURED_THANKS_MESSAGE_LINE_2":"Befolgen Sie die Anweisungen zum Hochladen von Rechnungen zu Xero in <b>{{applicationName}} </b>.",
    "XERO_CONSENT_DESCRIPTION":"Um Rechnungen an Xero zu senden, müssen Sie Ihre Zustimmung geben.",
    "XERO_CONSENT_PROCESSING_MESSAGE":"Bitte warten Sie, während wir Ihre Xero-Einwilligung bearbeiten",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_1":"Der folgende Link wird in einer neuen Registerkarte geöffnet. Bitte geben Sie Ihre Xero-Anmeldeinformationen an. Wenn Sie bei der Erteilung der Einwilligung einen Fehler haben, wenden Sie sich bitte an unser Support-Team.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_2":"Xero Zustimmung zu geben",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_3":"Wenn Sie die Einwilligung bereits erteilt haben, klicken Sie auf die Schaltfläche <b>Token validieren </b>, um die Konnektivität mit Xero zu überprüfen.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_4":"Klicken Sie auf diese Schaltfläche, um die Gültigkeit des vorhandenen Xero-Aktualisierungstokens zu überprüfen.",
    "XERO_CONSENT_VIEW_INSTRUCTIONS_HEADING":"Voraussetzungen für den Partner, um Kundenrechnungen an <b>XERO </b>zu senden",
    "XERO_POST_BATCH_ID":"Xero Post Batch Id",
    "XERO_POST_LOG_QEUEUED_SUCCESS_MESSAGE":"Ihre Anfrage, Rechnungen an Xero zu senden, wird angenommen und zur Bearbeitung in die Warteschlange gestellt. Klicken Sie auf die Schaltfläche <strong> Beitragsprotokolle anzeigen </strong>, um Informationen zum Fortschritt zu erhalten.",
    "XERO_POST_LOGS_STATUS":"Xero Post Logs Status",
    "XERO_REFRESH_TOKEN_VALIDATION_FAILURE_MESSAGE":"Das vorhandene Xero-Aktualisierungstoken ist ungültig. Bitte geben Sie Ihre Zustimmung erneut",
    "XERO_REFRESH_TOKEN_VALIDATION_SUCCESS_MESSAGE":"Das vorhandene Xero-Aktualisierungstoken ist gültig. Sie können mit dem Hochladen von Rechnungen fortfahren",
    "XERO_TENANT_ID":"Xero Mieter ID",
    "Year(s)":"Jahre)",
    "YOU_CAN_SPECIFY_THE_TAX_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Sie können den Steuerprozentsatz für den Kunden angeben. ZB: 15 für 15%",
    "YOU_CAN_SPECIFY_TOOLTIP_ADD_EDIT_TAX_PERCENTAGE":"Sie können den Steuernamen wie GST angeben.",
    "YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION":"Sie haben keine Berechtigung, diese Aktion auszuführen",
    "YOU_MUST_SELECT_ONE_OR_MORE_OFFER":"Sie müssen ein oder mehrere Angebote auswählen, um ein Abonnement zu erstellen"
 
  }, 
};
