import { Component, Input, OnInit } from '@angular/core';
import { ClientSettingsService } from 'src/app/services/client-settings.service';

@Component({
  selector: 'app-quote-pdf-view',
  templateUrl: './quote-pdf-view.component.html',
  styleUrls: ['./quote-pdf-view.component.scss']
})
export class QuotePDFViewComponent implements OnInit {
  
  @Input() selectedCustomerDetails: any;
  @Input() frmAddQuote_QuoteName: string;
  @Input() addressDetails: any[];
  @Input() QuoteContact: any;
  @Input() users: any[];
  @Input() quoteLineItemsData: any[];
  @Input() totalQuoteValue: number;
  @Input() DisplayTotalFinalSalePrice: number;
  @Input() totalTaxAmount: number;
  @Input() CommentsToBuyer: string;
  @Input() PurchaseTerms: string;
  @Input() IsShowCounterSign: boolean;
  @Input() IsCounterSign: boolean;
  @Input() PDFlogobase64: string;
  @Input() CurrencySymbol: string;
  @Input() CurrencyDecimalPlaces: number;
  @Input() CurrencyThousandSeperator: string;
  @Input() CurrencyDecimalSeperator: string;
  @Input() currentDate: Date;
  @Input() frmAddQuote_ExpiresOn: string;

  PoweredByCompanyName: string;
  AddressLine1: string;
  City: string;
  ZipCode: string;
  Country: string;
  State: string;
  ContactCompanyName: any;
  currentDate1 = new Date();


  constructor(private _clientSettingsService: ClientSettingsService) {}

  ngOnInit(): void {
    this._clientSettingsService.getData().subscribe((data: any) => {
      this.ContactCompanyName = data?.Data?.ContactCompanyName;
      this.AddressLine1 = data?.Data?.AddressLine1;
      this.City = data?.Data?.City;
      this.ZipCode = data?.Data?.ZipCode;
      this.Country = data?.Data?.Country;
      this.State = data?.Data?.State;
    });
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }
}
