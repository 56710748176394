import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { DynamicTemplateService } from 'src/app/services/dynamic-template.service';
import { FileService } from 'src/app/services/file.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ToastService } from 'src/app/services/toast.service';
import { C3BaseComponent } from 'src/app/shared/models/c3BaseComponent';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { mapParamsWithApi } from 'src/app/modules/standalones/c3-table/c3-table-utils';
import { catchError, distinctUntilChanged, interval, of, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { PageInfoService } from 'src/app/_c3-lib/layout';
import { QuoteService } from '../quotes.service';
import { CloudHubConstants } from 'src/app/shared/models/constants/cloudHubConstants';
import moment from 'moment';
import { find } from 'lodash';
import { UserContextService } from 'src/app/services/user-context.service';
import {StepperComponent} from 'src/app/_c3-lib/kt/components'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalOptions, NgbOffcanvas, NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';
import { QuoteCustomLineItemComponent } from '../quote-custom-line-item/quote-custom-line-item.component';
import { QuoteLineItemPopUpComponent } from '../quote-line-item-pop-up/quote-line-item-pop-up.component';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { QuoteAddnewcustomerComponent } from '../quote-addnewcustomer/quote-addnewcustomer.component';
import { QuoteReviewComponent } from '../quote-review/quote-review.component';
import { UnsavedChangesService } from 'src/app/services/unsaved-changes.service';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent extends C3BaseComponent implements OnInit,OnDestroy {

  
  frmWizardOne: FormGroup = new FormGroup({});
  frmWizardTwo: FormGroup = new FormGroup({});
  frmWizardFive: FormGroup = new FormGroup({});
  frmWizardFour: FormGroup = new FormGroup({});
  frmWizardThree: FormGroup = new FormGroup({});
  customers = [];
  users: any;
  partnerLogos: any;
  activeTab = "four";
  QuoteStatus = null;
  PageMode : any;
  localStorageNames = [];
  selectedCustomerDetails : any = {};
  currentC3CustomerId = null;
  QuoteContact:any = [];
  QuoteContactList = [];
  entityName = null;
  recordId = null;
  addressDetails = [];
  CurrencyCodes = [];
  CurrencyCode = "";
  CurrencyThousandSeperator: any = ',';
  CurrencyDecimalSeperator: any = '.';
  CurrencyDecimalPlaces = 2;
  CurrencySymbol: any = '$';
  customerId = 0;
  SelectAllColumn = false;
  IsShowCounterSign = false;
  noSignature = 'noSignature';
  includeSignature = 'includeSignature';
  allCustomers: any;
  PDFlogobase64 :any;
  selectedUserList: any[] = [];
  permissions = {
    SaveQuote: 'Denied',
    ApproveQuote: 'Denied'
  };
  private destroy$ = new Subject<void>; 
  stepper: StepperComponent = null; 

  today: Date = new Date();
  todayDate: NgbDateStruct = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate()+1
  }

  config =  {
    height: 102,
    focus: false,
    airMode: false,
    disableDragAndDrop: true,
    toolbar: [
      ['edit', ['undo', 'redo']],
      ['style', ['bold']],
      ['alignment', ['ul', 'ol']],
    ],
};
  addressDetailsData: any;
  addressFilteredData: any;
  customerName: any;
  taxType: any;
  ContactCompanyName:any;
  AddressLine1: any;
  City: any;
  ZipCode:any;
  Country: any;
  State: string;
  QuoteVersionId: any;
  productsAndLinkedProducts: any;
  quoteLineItemsList: any =[];
  quoteLineItemsData: any = [];
  QuoteId: any;
  approverEmail: any;
  quoteURL: any;
  CommentsToBuyer: any;
  PurchaseTerms: any;
  IsSign: string;
  IsCounterSign: any;
  statusName: any;
  quoteLineItemSubTotal: number;
  totalQuoteValue: number;
  totalDiscount: number;
  productSubTotal: number;
  DisplayTotalFinalSalePrice: number;
  totalTaxAmount: number = 0;
  linkedProductTaxAmount: number;
  deletedQuoteLineItemIds: any[] =[];
  localStorageQuoteKeyNameParsed: any;
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
currentDate: Date;
frmAddQuote_ExpiresOn: any;
frmAddQuote_QuoteName: string;
  frmAddQuote_Template: string;
  isFirstPopUp: boolean;
  isSavingQuotedisabled: boolean;
  isUnsavedChange: boolean = false;
  saveQuoteId: null;
  saveQuoteUrl: null;
  formGroupArray : any = [];
  stateVisited = {
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  };
  CompletedStateCount = 0;
  isUnsaveChange: boolean;
  QuotesStatus: any; 
  globalDateFormat: any ='';
  disableCondition =false
  // quoteVersionId = $stateParams.QuoteVersionId;
  //  this.quoteVersionId = $stateParams.QuoteVersionId;

  forms: { [key: string]: FormGroup } = {
    WizardOne: this.frmWizardOne,
    WizardTwo: this.frmWizardTwo,
    WizardFive: this.frmWizardFive,
    WizardFour: this.frmWizardFour,
    WizardThree: this.frmWizardThree

    // Add other forms here
  };
  presenttab: number = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    public _router: Router,
    public _permissionService: PermissionService,
    public _dynamicTemplateService: DynamicTemplateService,
    private userContext: UserContextService,
    private translateService: TranslateService,
    private _notifierService: NotifierService,
    private _toastService: ToastService,
    private pageInfo: PageInfoService,
    private _fileService: FileService,
    private _commonService: CommonService,
    private renderer: Renderer2,
    private _cdRef: ChangeDetectorRef,
    private _quotesService: QuoteService,
    private _clientSettingsService: ClientSettingsService,
    private _applicationSettings : AppSettingsService,
    private _modalService: NgbModal,
    private _unsavedChangesService: UnsavedChangesService,
    private _appService: AppSettingsService,
  ) {
    super(_permissionService, _dynamicTemplateService, _router, _appService);

    this._applicationSettings.getApplicationData().subscribe((data:any) => {
    this.PDFlogobase64 = data?.Data?.GetImageAsBase64;
    })

    this._clientSettingsService.getData().subscribe((data:any) => { 
      this.ContactCompanyName = data?.Data?.ContactCompanyName;
      this.AddressLine1 = data?.Data?.AddressLine1;
      this.City = data?.Data?.City;
      this.ZipCode = data?.Data?.ZipCode;
      this.Country = data?.Data?.Country;
      this.State = data?.Data?.State;
    });
    this._quotesService.dictOfQuoteLineItems = {} 

    this.frmWizardOne = this._formBuilder.group({
      customer_id: ['', Validators.required],
      frmAddQuote_Template: [''],
      quote_puchasedescription: [''],
      expireDate: ['', Validators.required],
      quote_description: [''],
      frmAddQuote_QuoteName :['', [Validators.required,Validators.maxLength(150)]]
    });

    this.frmWizardTwo = this._formBuilder.group({
      SelectAllColumn : [''],
      checkbox1: [''],
      customerNameToUpdate: [''],
      checkbox2: [''],
    });


    this.frmWizardThree = this._formBuilder.group({
      UserNameUpdate:  [''],
    });

    this.frmWizardFive = this._formBuilder.group({
      radios4:  ['', Validators.required],
      Checkboxes1: [''],
    });

    this.frmWizardFour = this._formBuilder.group({
      Quantity: [null]
    });
      this.QuoteVersionId = this._router.getCurrentNavigation()?.extras.state?.QuoteVersionId;
      this.PageMode = this._router.getCurrentNavigation()?.extras.state?.pageType;
      this.QuotesStatus = this._router.getCurrentNavigation()?.extras.state?.QuotesStatus;

      if(this.PageMode === null || this.PageMode == undefined){
        _router.navigate(['partner/quotelist']);
      }
      Object.values(this.forms).forEach(form => this.trackFormChanges(form));

      this.formGroupArray = [this.frmWizardOne,this.frmWizardTwo,this.frmWizardThree,this.frmWizardFour,this.frmWizardFive];
  }


  ngOnInit(): void { 
    this.globalDateFormat = this._appService.$rootScope.dateFormat;

    StepperComponent.bootstrap();
    this.createStepperInstances("#kt_stepper_example_basic");
    this.stepper.on("kt.stepper.next", (stepper) => {
    this.nextStep();
    });
    this.stepper.on("kt.stepper.previous", (stepper) => {
      this.prevStep(); 
    });
    this.disblecustomer();
    this.checkDisableCondition()

    this.entityName = this._commonService.entityName;

    if(this._commonService.entityName === 'Partner' ) {
      this.pageInfo.updateTitle(this.translateService.instant("TRANSLATE.QUOTE_BREADCRUMB_QUOTE_ADD"), true);
      this.pageInfo.updateBreadcrumbs(['SIDEBAR_TITLE_MENUS_SELL_DIRECT','QUOTE_BREADCRUMB_QUOTE_LIST','QUOTE_BREADCRUMB_QUOTE_ADD']);
    }

    else if(this._commonService.entityName === 'Reseller'){
      this.pageInfo.updateTitle(this.translateService.instant("TRANSLATE.QUOTE_BREADCRUMB_QUOTE_ADD"), true);
      this.pageInfo.updateBreadcrumbs(['SIDEBAR_TITLE_MENUS_SELL','QUOTE_BREADCRUMB_QUOTE_LIST','QUOTE_BREADCRUMB_QUOTE_ADD']);
    }
  }

  checkDisableCondition() {
     this.disableCondition = [this.cloudHubConstants.QUOTE_LIST_STATUS_APPROVED, this.cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, this.cloudHubConstants.QUOTE_LIST_STATUS_DELETED, this.cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(this.QuoteStatus) || this.PageMode === 'View';

    if (this.disableCondition) {
      this.frmWizardOne.get('frmAddQuote_Template')?.disable();
      this.frmWizardOne.get('customer_id')?.disable();
      this.frmWizardOne.get('frmAddQuote_QuoteName')?.disable();
      this.frmWizardOne.get('expireDate')?.disable();
      this.frmWizardFive.get('radios4')?.disable();
      this.frmWizardFive.get('Checkboxes1')?.disable();  // Disable the input field
    } else {
      this.frmWizardOne.get('frmAddQuote_Template')?.enable();
      this.frmWizardOne.get('frmAddQuote_QuoteName')?.enable();
      this.frmWizardOne.get('expireDate')?.enable();
      this.frmWizardFive.get('radios4')?.enable();
      this.frmWizardFive.get('Checkboxes1')?.enable(); // Enable the input field
    }
    this._cdRef.detectChanges();
  }
  
  disblecustomer() {
    if (this.PageMode ==='Edit')
    {
      this.frmWizardOne.get('customer_id').disable();
    }
    else{
      this.frmWizardOne.get('customer_id').enable();
    }
  }
  onFormChange() {
    // this.selectedCustomer = this.frmWizardOne.get('customer_id')?.value;
    this.frmAddQuote_Template = this.frmWizardOne.get('frmAddQuote_Template')?.value;
    this.frmAddQuote_QuoteName = this.frmWizardOne.get('frmAddQuote_QuoteName')?.value;
    const validDate = this.frmWizardOne.value.expireDate;
    if (validDate) {
    let expireDate={year:validDate.year,month:validDate.month-1,day:validDate.day}
    this.frmAddQuote_ExpiresOn =this.formatDateObject(expireDate);
  }
    this.CommentsToBuyer = this.frmWizardOne.get('quote_description')?.value;
    this.PurchaseTerms = this.frmWizardOne.get('quote_puchasedescription')?.value;
    const checkboxes1Value = this.frmWizardFive.get('Checkboxes1')?.value;
    this.IsCounterSign = checkboxes1Value ? true : false;
    this.cdRef.detectChanges();
  }

  getFormControlValue(form: AbstractControl, controlName: string) {
    return form?.get(controlName)?.value;
  }

  getFormControl(form: AbstractControl, controlName: string) {
    return form?.get(controlName);
  }

  editRow(row){
    row.IsEdit = true;
    this.frmWizardTwo.get('customerNameToUpdate').setValue(row.FullName);
  }

  nextStep() {
    let index = this.stepper.getCurrentStepIndex();
    this.presenttab = index;
    let form: FormGroup = this.formGroupArray[index - 1];
    form.markAllAsTouched();
    if (index == 1) {
      form = this.frmWizardOne;
      if (this.frmWizardOne.get('frmAddQuote_QuoteName')?.value.length > 150) {
        this._toastService.error(
          this.translateService.instant('TRANSLATE.QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE'));
        return;
      }
    }
    else if (index == 2) {
      form = this.frmWizardTwo;
      if (this.QuoteContact.filter(x => x.IsChecked == true).length > 3) {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_CONTACTS_MAX_VALIDATION_ERROR'));
        return;
      }
      if (!this.stateVisited[2]) {
        this.CompletedStateCount++;
        this.stateVisited[2] = true;
      }
    }
    else if (index == 3) {
      form = this.frmWizardThree;
      if (!this.stateVisited[3]) {
        this.CompletedStateCount++;
        this.stateVisited[3] = true;
      }
    }
    else if (index == 4) {
      form = this.frmWizardFour;
      if (this.quoteLineItemsData.length == 0 && this.PageMode != 'View') {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_VALIDATION_ERROR'));
        return;
      }
      if (this.quoteLineItemsData.length > 50) {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR'));
        return;
      }
      if (!this.stateVisited['four']) {
        this.CompletedStateCount++;
        this.stateVisited['four'] = true;
      }
      if (this.quoteLineItemsData) {
        this.isUnsaveChange = false;
        this.quoteLineItemsData.forEach((data) => {
          if (data.isEditing === true) {
            this.isUnsaveChange = true;
          }
        });      
        if (this.isUnsaveChange) {
          this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE'));
          return;
        }
      }
    }
    else if (index == 5) {
      form = this.frmWizardFive;
      if (!this.stateVisited[5]) {
        this.CompletedStateCount++;
        this.stateVisited[5] = true;
      }
    }
    if (form.valid || this.PageMode == 'View') {
        this.stepper.goNext();
    }
    else {
      this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LABEL_TEXT_WIZARD_NEXT_ERROR'));
      $("html, body").animate({ scrollTop: 0 }, "slow");
    }
    this._cdRef.detectChanges();
  }

  prevStep(){
    // Handle previous step
      this.stepper.goPrev(); // go previous step
      this.presenttab = this.stepper.getCurrentStepIndex()-1;
  }

  createStepperInstances(selector: string): void {
    const elements = document.body.querySelectorAll(selector);
    elements.forEach((element) => {
      const item = element as HTMLElement;
      this.stepper = StepperComponent.createInsance(item);
    });
    this.getPartnerPDFLogo();
    this.GetCodes();
    this.getQuoteUserDetails();
    this.setPermissions();
    this.getCustomerForQuotes();
    this.initialConditions();
  }

  setPermissions(): void {
    this.permissions.SaveQuote = this._permissionService.hasPermission(CloudHubConstants.SAVEQUOTE) ? 'Allowed' : 'Denied';
    this.permissions.ApproveQuote = this._permissionService.hasPermission(CloudHubConstants.APPROVEQUOTE) ? 'Allowed' : 'Denied';
  }

  saveQuote(saveAsDraft: boolean) {
    this.statusName =saveAsDraft ? CloudHubConstants.QUOTE_LIST_STATUS_DRAFT : CloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED;
    this.isSavingQuotedisabled = true;
    if (!this.frmAddQuote_QuoteName || this.frmAddQuote_QuoteName.trim() === '') {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_NAME_REQUIRED_ERROR'));
        return;
    }   
    if (this.frmAddQuote_QuoteName && this.frmAddQuote_QuoteName.length > 150) {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE'));
        return;
    }
    if (!this.frmAddQuote_ExpiresOn) {
        this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_EXPIRATION_DATE_REQUIRED_ERROR'));
        return;
    }
   
    if (this.stepper.getCurrentStepIndex() == 4) {
        if (this.quoteLineItemsData.length == 0) {
            this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_VALIDATION_ERROR'));
            return;
        }
        if (this.quoteLineItemsData.length > 9999) {
            this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_MAX_VALIDATION_ERROR'));
            return;
        }
        if (this.quoteLineItemsData) {
            this.isUnsavedChange = false;
            this.quoteLineItemsData.forEach((data) => {
                if (data.isEditing == true) {
                    this.isUnsavedChange = true;
                }
            })
            if (this.isUnsavedChange) {
                this._toastService.error(this.translateService.instant('TRANSLATE.QUOTE_LINE_ITEM_VALIDATION_ERROR_SAVE_QUOTE_LINE_ITEM_CHNAGE'));
                return;
            }
        }
    }
    var quoteDetails = {};
    var quoteContacts = [];
    var quoteLineItems = [];
    var buyerCompany = {};
    //Handling case when buyer doesn't have any details
    if (this.addressDetails != null && this.addressDetails != undefined && this.addressDetails.length == 0) {
        buyerCompany = {
            Name: this.selectedCustomerDetails.CustomerName
        }
    }
    else {
        buyerCompany = {
            Name: this.selectedCustomerDetails.CustomerName,
            Logo: '',
            AddressId: this.addressDetails[0].AddressId,
            Line1: this.addressDetails[0].Line1,
            Line2: this.addressDetails[0].Line2,
            City: this.addressDetails[0].City,
            State: this.addressDetails[0].State,
            Country: this.addressDetails[0].Country,
            Zip: this.addressDetails[0].Zip,
            IsChecked: this.addressDetails[0].IsChecked
        };
    } 
    var sellerInfo = { 
        CompanyName: this.ContactCompanyName,
        Logo:/* this.partnerPDFLogo.Value*/ " ",
        AddressLine1: this.AddressLine1,
        City: this.City,
        State: this.State,
        Country: this.Country,
        ZipCode: this.ZipCode,
        EmailAddress: this.users[0].EmailAddress,
        Name : this.users[0].Name
    }
    quoteDetails = {
        QuoteVersionId: this.QuoteVersionId != undefined ? this.QuoteVersionId : 0,
        QuoteId: this.QuoteId != undefined ? this.QuoteId : 0,
        CustomerId: this.currentC3CustomerId,
        CreateBy: this.users[0].EmailAddress,
        ApprovedBy: this.approverEmail != undefined ? this.approverEmail : null,
        Name: this.frmAddQuote_QuoteName,
        BuyerCompany: JSON.stringify(buyerCompany),
        SellerCompany: JSON.stringify(sellerInfo),
        QuoteTemplate: this.frmAddQuote_Template ,
        ExpirationDate: moment.utc(this.frmAddQuote_ExpiresOn).format('LL'),
        QuoteURL: this.quoteURL,
        CommentsToBuyer: this.CommentsToBuyer,
        PurchaseTerms: this.PurchaseTerms,
        IsSign: this.IsSign == 'includeSignature' ? true : false,
        IsCounterSign: this.IsCounterSign,
        StatusName: saveAsDraft ? CloudHubConstants.QUOTE_LIST_STATUS_DRAFT : CloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED
    }
    if (this.QuoteContact != undefined && this.QuoteContact != null && this.QuoteContact.length > 0) {
        this.QuoteContact.forEach((contact) => {
            //if (contact.IsChecked) {
                var quoteContact = {
                    QuoteContactId: contact.QuoteContactId != undefined ? contact.QuoteContactId : 0,
                    FirstName: contact.FirstName,
                    LastName: contact.LastName,
                    Email: contact.EmailId,
                    PhoneNumber: contact.PhoneNumber,
                    UserId: contact.UserId,
                    IsActive: contact.IsChecked,
                }
                quoteContacts.push(quoteContact);
            //}
        })
    }
    if (this.productsAndLinkedProducts && this.productsAndLinkedProducts != null && this.productsAndLinkedProducts != undefined && this.productsAndLinkedProducts.length > 0) {
        this.productsAndLinkedProducts.forEach((product) => {
            let productToAdd = { 
                QuoteLineItemId: product.QuoteLineItemId != undefined ? product.QuoteLineItemId : 0,
                PlanProductId: product.PlanProductId != undefined ? product.PlanProductId : 0,
                Name: product.Name,
                Description: product.Description,
                Quantity: product.Quantity,
                CostPrice: product.ProviderPrice,
                SalePrice: product.DisplayFinalSalePrice,
                Discount: product.Discount != undefined ? product.Discount : 0.00,
                Tax: product.TaxFinalSalePriceAfterDiscount != undefined ? product.TaxFinalSalePriceAfterDiscount : 0.00,
                SaleType: product.SaleTypeId,
                BillingPeriodId: product.BillingPeriodId,
                StartDate: product.StartDate != undefined ? product.StartDate : null,
                EndDate: product.EndDate != undefined ? product.EndDate : null,
                InvoiceNumber: product.InvoiceNumber != undefined ? product.InvoiceNumber : null,
                InvoiceDate: product.InvoiceDate != undefined ? product.InvoiceDate : null,
                DueDate: product.DueDate != undefined ? product.DueDate : null,
                IsActive: product.IsActive || product.QuoteLineItemId,
                DeletedQuoteLineItemIds: this.deletedQuoteLineItemIds.join(',')
            }
            quoteLineItems.push(productToAdd);
        });
    }
    var element = document.getElementById('sample-table-for-pdf');
    var reqBody = {
        QuoteDetails: JSON.stringify(quoteDetails),
        QuoteContacts: JSON.stringify(quoteContacts),
        QuoteLineItems: JSON.stringify(quoteLineItems),
        HtmlTemplate: element.innerHTML
   
    }
   
    this._quotesService.saveQuote(reqBody).subscribe({
      next: (response: any) => {
        if (this.statusName === CloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED) {
            this.saveQuoteId = response.Data.QuoteId
            this.saveQuoteUrl = response.Data.QuoteURL

            this.isSavingQuotedisabled = false;
            this.QuoteReview();
        } else {
          this._notifierService.success({ title: this.translateService.instant('TRANSLATE.QUOTE_LABEL_TEXT_SAVE_DRAFT') }).then((result) => {
            if (result.isConfirmed ) {this._router.navigate(['partner/quotelist']);}
            });
        }
        
    }, error:(error:any)=>{
      let msg = this.translateService.instant('TRANSLATE.' + error.error.ErrorDetail);
      this._toastService.error(msg)
      return of(null);
    }
    })
    // (response: any) => {

    //     if (this.statusName === CloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED) {
    //         this.saveQuoteId = response.Data.QuoteId
    //         this.saveQuoteUrl = response.Data.QuoteURL

    //         this.isSavingQuotedisabled = false;
    //         this.QuoteReview();
    //     } else {
    //       this._notifierService.success({ title: this.translateService.instant('TRANSLATE.QUOTE_LABEL_TEXT_SAVE_DRAFT') }).then((result) => {
    //         if (result.isConfirmed ) {this._router.navigate(['partner/quotelist']);}
    //         });
    //     }
        
    // }, error:(error:any)=>{
    //   let msg = this.translateService.instant('TRANSLATE.' + error.error.ErrorDetail);
    //   this._toastService.error(msg)
    // };
    // return of(null);
  }

  GetCodes() {
    this._commonService.getSupportedCurrencies().subscribe((response: any) => {
      this.CurrencyCodes = response.Data;
    });
  }


  getQuoteUserDetails(): void {
    let userContext = this._commonService.userInfo;
    let userId = userContext[userContext.length - 1].C3UserId
    this._quotesService.getQuoteUserDetails(userId).subscribe((response: any) => {
      this.users = response.Data;
    });
  }

  getPartnerPDFLogo(): void {
    this._quotesService.getPartnerLogos().subscribe((response: any) => {
      this.partnerLogos = response.Data;
      this.getPartnerPDFLogo = find(this.partnerLogos, each => each.Name === CloudHubConstants.PDF_LOGO);
    });
  }

  MoveToState(arg0: string) {
    throw new Error('Method not implemented.');
  }

  initialConditions() {
    if (this.PageMode === 'Add') {
      this.getCustomerForQuotes();
    }
  }

  customerContactList() {
    let value = this.frmWizardOne.get('customer_id').value;
    this.selectedCustomerDetails = {};
    this.currentC3CustomerId = value;
    this._quotesService.getCustomerAdminUsers(value).subscribe((response: any) => {
      
      if (response.Data !== undefined && response.Data !== null && response.Data.length > 0) {
        this.QuoteContact = response.Data;
        console.log(this.QuoteContact);
        this.entityName = CloudHubConstants.ENTITY_CUSTOMER;
        this.recordId = this.QuoteContact[0].RecordId;
        console.log(this.cloudHubConstants.QUOTE_CUSTOMER_ADMIN);
        this.QuoteContact = this.QuoteContact.filter(user => user.RoleName === this.cloudHubConstants.QUOTE_CUSTOMER_ADMIN && user.IsPrimaryContext != false);
        this.QuoteContact.map((each) => {
          return each.IsChecked = true;
        });
        this._quotesService.getAddress(this.entityName,this.recordId).subscribe((response:any) => {
          this.addressDetailsData = response.Data;
          this.addressDetails = this.addressDetailsData.filter(address => address.IsDefault === true && address.ContactType === 'Billing');
          if (this.addressDetails == null || this.addressDetails.length == 0) {
            this.addressDetails = this.addressDetailsData.filter(address => address.ContactType === 'Billing');
            if (this.addressDetails.length > 1) {
              var arrayCount = this.addressDetails.length;
              this.addressFilteredData = this.addressDetails[arrayCount - 1];
              this.addressDetails = [this.addressFilteredData];
            }
          }
          if (this.addressDetails.length > 0) {
            this.addressDetails.forEach(function (address) {
              address.IsChecked = true;
            })
          }
          //this.addressDetails = this.addressDetails.filter(address => address.IsDefault === true && address.ContactType === 'Billing');
        });
        if (this.allCustomers == null || this.allCustomers == undefined) {

        }
        this.selectedCustomerDetails = this.allCustomers.find(customer => customer.C3Id == this.currentC3CustomerId);
        this.CurrencyCode = this.selectedCustomerDetails.CurrencyCode;
        this.customerId = this.selectedCustomerDetails.CustomerId;
        this.taxType = this.selectedCustomerDetails.TaxTypeName;
        this.CurrencySymbol = this.selectedCustomerDetails.CurrencySymbol;
        this.CurrencyDecimalPlaces = this.selectedCustomerDetails.CurrencyDecimalPlaces;
        this.CurrencyThousandSeperator = this.selectedCustomerDetails.CurrencyThousandSeperator;
        this.CurrencyDecimalSeperator = this.selectedCustomerDetails.CurrencyDecimalSeperator;
        this.customerName = this.selectedCustomerDetails.CustomerName;
      }
    });
  }


  addNewCustomer(){
    const modalRef = this._modalService.open(QuoteAddnewcustomerComponent, {
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.type = 'form'
    modalRef.result.then((result) => {
      if (result) {
        let newlyAddedUser = result;
        result.forEach((column) => {
          column.IsChecked = true;
          this.QuoteContact.push(column);
      });
      }
    },
    (reason) => {
      /* Closing modal reference if cancelled or clicked outside of the popup*/
      modalRef.close();
    });
      
  }

  addExistingCustomer(){
    const modalRef = this._modalService.open(QuoteAddnewcustomerComponent, {
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.type = 'list'
    modalRef.componentInstance.quoteContact = this.QuoteContact;
    modalRef.componentInstance.addCustomerC3Id = this.selectedCustomerDetails.C3Id
    modalRef.componentInstance.quoteContact =this.QuoteContact;
    modalRef.result.then((result) => {
      if (result) {
        result.forEach((column) => {
          this.QuoteContact.push(column);
      });
      }
    },
    (reason) => {
      /* Closing modal reference if cancelled or clicked outside of the popup*/
      modalRef.close();
    });
      
  }


  addCustomer(){
    let value = this.frmWizardTwo.get('SelectAllColumn').value;
  }

  CheckAllContact() {
    this.SelectAllColumn = !this.SelectAllColumn;
    this.QuoteContact.forEach((contact) => {
      contact.IsChecked = this.SelectAllColumn;
    });
    this._cdRef.detectChanges();

  }

  getCustomerForQuotes() {
    this.currentC3CustomerId = null;
    this._quotesService.getCustomerForQuotes().subscribe((response: any) => {

      this.allCustomers = response.Data;
      if (this.PageMode == 'Add') {
        this.customers = this.allCustomers.filter(x => x.IsQuoteCreated == 0);
      }
      else {
        this.customers = this.allCustomers;
      }
      if (this.PageMode == 'Edit' || this.PageMode == 'View') {
        this.getQuoteDetails();
      }
    })
  }

  BackToQuote() {
    let callback=()=>{
      this._router.navigate(['partner/quotelist']);
    }
    this._unsavedChangesService.setUnsavedChanges(this.frmWizardOne.dirty || this.frmWizardTwo.dirty || this.frmWizardThree.dirty || this.frmWizardFour.dirty || this.frmWizardFive.dirty);
    this._unsavedChangesService.setCallback = callback;
    this._unsavedChangesService.confirmPopup();
  }

  UpdateUserName(row){
     
  }


  CustomLineItem(customLineItem: any = 'noParameter', index?: number) {
    const customLineItemReqBody = {
      CurrencyCode: this.CurrencyCode,
      CurrencySymbol: this.CurrencySymbol,
      CurrencyDecimalPlaces: this.CurrencyDecimalPlaces,
      CurrencyThousandSeperator: this.CurrencyThousandSeperator,
      CurrencyDecimalSeperator: this.CurrencyDecimalSeperator,
      CustomLineItemDetails: customLineItem
    };
  
    const modalRef = this._modalService.open(QuoteCustomLineItemComponent, {
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.customLineItemDetails = customLineItemReqBody; 
  
    modalRef.result.then((result) => {
      if (index !== undefined) {
        this.quoteLineItemsList.data[index] = result;
        this.quoteLineItemsData = this.quoteLineItemsList.data;
        this.CalculateQuoteLineItemTotal(this.quoteLineItemsList.data);
      } else {
        this.quoteLineItemsData.push(result);
        this.quoteLineItemsList.data = this.quoteLineItemsData;
        this.CalculateQuoteLineItemTotal(this.quoteLineItemsList.data);
      }
    }).catch((error) => {
      console.log('Modal closed with error:', error);
    });
  }
  

QuoteReview(): void {
  this.statusName = 'QUOTE_LIST_STATUS_PUBLISHED';  
  this.isSavingQuotedisabled = true;
  
    this.isSavingQuotedisabled = false;
    
    const modalRef = this._modalService.open(QuoteReviewComponent, {
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      size: 'md',
    });
    modalRef.componentInstance.quoteId =  this.saveQuoteId;
    modalRef.componentInstance.saveQuoteUrl =  this.saveQuoteUrl;
    modalRef.result.then(
      (result) => {
        this.quoteURL = result;
        
      },
      (reason) => {
        this._router.navigate(['partner', 'quotelist']);
      }
    );
}
QuoteEmailDetails(): void {
  this.isFirstPopUp = false;
  const modalRef = this._modalService.open(QuoteReviewComponent, {
    ariaLabelledBy: 'modal-title',
    ariaDescribedBy: 'modal-body',
    size: 'xl',
  });

  modalRef.result.then(
    (response) => {
      this._router.navigate(['partner', 'quotelist']);
    },
    (reason) => {
      this._router.navigate(['partner', 'quotelist']);
    }
  );
}

getQuoteDetails() {
  this._subscription = this._quotesService.getQuoteDetails(this.QuoteVersionId).subscribe((response: any) => {
      const quoteDetails = response.Data;

      this.QuoteVersionId = quoteDetails?.QuoteDetails.QuoteVersionID;
      this.QuoteId = quoteDetails.QuoteDetails.QuoteId;
      this.currentC3CustomerId = quoteDetails.QuoteDetails.CustomerC3Id;
      if (this.users) {
          this.users[0].EmailAddress = quoteDetails.QuoteDetails.CreateBy;
      }
      this.approverEmail = quoteDetails.QuoteDetails.ApprovedBy;
      
      this.frmWizardOne.get('customer_id')?.setValue(quoteDetails.QuoteDetails.CustomerC3Id);
      this.frmWizardOne.get('frmAddQuote_QuoteName')?.setValue(quoteDetails.QuoteDetails.Name);
      this.frmWizardOne.get('frmAddQuote_Template')?.setValue(quoteDetails.QuoteDetails?.QuoteTemplate ||'');
      
      this.frmWizardOne.get('quote_puchasedescription')?.setValue(quoteDetails.QuoteDetails.PurchaseTerms);
      this.frmWizardOne.get('quote_description')?.setValue(quoteDetails.QuoteDetails.CommentsToBuyer);

      if (quoteDetails.QuoteDetails.IsSign) {
        this.frmWizardFive.get('radios4')?.setValue('includeSignature');
        this.IsShowCounterSign = true;
      } else {
        this.frmWizardFive.get('radios4')?.setValue('noSignature');
        this.IsShowCounterSign = false;
      }
      this.frmWizardFive.get('Checkboxes1')?.setValue(quoteDetails.QuoteDetails.IsCounterSign);
      const ExpiryDate = new Date(quoteDetails.QuoteDetails.ExpirationDate);
    

      if (quoteDetails.QuoteDetails.BuyerCompany) {
          this.addressDetails.push(JSON.parse(quoteDetails.QuoteDetails.BuyerCompany));
      }
      if (quoteDetails.QuoteDetails.SellerCompany) {
          this.users = [];
          const SellerCompany = JSON.parse(quoteDetails.QuoteDetails.SellerCompany);
          const usersModel = {
              EmailAddress: SellerCompany.EmailAddress,
              Name: SellerCompany.Name
          }
          this.users.push(usersModel); 
      }
      let expires=  this.getNgbDateStruct(quoteDetails.QuoteDetails.ExpirationDate);
      this.frmWizardOne.get('expireDate')?.setValue(expires);
      let adjustedExpires = {year: expires.year, month: expires.month - 1, day: expires.day};
      this.frmAddQuote_ExpiresOn = this.formatDateObject(adjustedExpires);


      // this.frmWizardOne.get('frmAddQuote_ExpiresOn')?.setValue(new Date(quoteDetails.QuoteDetails.ExpirationDate.getTime() - quoteDetails.QuoteDetails.ExpirationDate.getTimezoneOffset() * 60000));
      this.quoteURL = quoteDetails.QuoteDetails.QuoteURL;
      this.CommentsToBuyer = quoteDetails.QuoteDetails.CommentsToBuyer;
      this.PurchaseTerms = quoteDetails.QuoteDetails.PurchaseTerms;
      this.CurrencyCode = quoteDetails.QuoteDetails.CurrencyCode;
      this.IsSign = quoteDetails.QuoteDetails.IsSign ? 'includeSignature' : 'noSignature';
      this.IsShowCounterSign = this.IsSign === 'includeSignature';
      this.IsCounterSign = quoteDetails.QuoteDetails.IsCounterSign;
      this.statusName = quoteDetails.QuoteDetails.StatusName;
      this.QuoteStatus = quoteDetails.QuoteDetails.StatusName;

      this.QuoteContact = quoteDetails.QuoteContacts.map(contact => ({
          QuoteContactId: contact.QuoteContactId,
          FirstName: contact.FirstName,
          LastName: contact.LastName,
          EmailId: contact.Email,
          PhoneNumber: contact.PhoneNumber,
          UserId: contact.UserId,
          IsChecked: true,
          Type: contact.Type,
          FullName:  [contact.FirstName, contact.LastName].join(' ')
      }));
      this.productsAndLinkedProducts = quoteDetails.QuoteLineItems.map(product => ({
          QuoteLineItemId: product.QuoteLineItemId ,
          PlanProductId: product.PlanProductId ,
          Name: product.Name ,
          Description: product.Description ,
          Quantity: product.Quantity,
          ProviderName: product.ProviderName,
          CategoryName: product.CategoryName,
          Validity: product.Validity,
          ValidityType: product.ValidityType,
          ConsumptionType: product.ConsumptionType ,
          ProviderPrice: product.CostPrice,
          DisplayFinalSalePrice: product.SalePrice,
          BillingCycleName: product.BillingCycleName,
          Discount: product.Discount,
          Tax: product.Tax,
          IsTaxable: product.IsTaxable,
          SaleTypeId: product.SaleType,
          BillingPeriodId: product.BillingPeriodId,
          StartDate: product.StartDate,
          EndDate: product.EndDate,
          InvoiceNumber: product.InvoiceNumber,
          InvoiceDate: product.InvoiceDate,
          DueDate: product.DueDate,
          CurrencyCode: product.CurrencyCode,
          CurrencySymbol: product.CurrencySymbol,
          CurrencyThousandSeperator: product.CurrencyThousandSeperator,
          CurrencyDecimalSeperator: product.CurrencyDecimalSeperator,
          CurrencyDecimalPlaces: product.CurrencyDecimalPlaces,
          FinalSalePrice: product.SalePrice,
          SaleType: product.SaleType,
          SalePrice: product.SalePrice,
         // ProviderPrice: product.ProviderPrice,
          DisplaySalePrice: product.DisplaySalePrice,
          OriginlaSalePrice: product.OriginlaSalePrice,
          DisplayOriginalFinalSalePrice: product.FinalSalePrice,
          //FinalSalePrice: product.FinalSalePrice,
          DisplayOriginlaSalePrice: product.DisplayOriginlaSalePrice,
          //DisplayFinalSalePrice: product.DisplayFinalSalePrice,
          LinkedProduct: product.LinkedProduct,
          LinkedSubscriptionName: product.LinkedSubscriptionName
      }));

      if (this.productsAndLinkedProducts) {
          this.productsAndLinkedProducts.forEach(product => {
              if (product.LinkedProduct) {
                  product.LinkedProduct.SaleTypeId = product.SaleType;
              }
          });
      }

      this.quoteLineItemsData = this.productsAndLinkedProducts;
      this.quoteLineItemsList.data = this.quoteLineItemsData;
      this.selectedCustomerDetails = this.customers.find(customer => customer.C3Id == this.currentC3CustomerId);
      this.CurrencySymbol = this.selectedCustomerDetails?.CurrencySymbol;
      this.CurrencyDecimalPlaces = this.selectedCustomerDetails?.CurrencyDecimalPlaces;
      this.CurrencyThousandSeperator = this.selectedCustomerDetails?.CurrencyThousandSeperator;
      this.CurrencyDecimalSeperator = this.selectedCustomerDetails?.CurrencyDecimalSeperator;
      this.taxType = this.selectedCustomerDetails?.TaxTypeName;
      this.customerName = this.selectedCustomerDetails?.CustomerName;

      this.quoteLineItemsData.forEach(product => {
          product.FinalSalePrice *= product.Quantity;
          product.OriginlaSalePrice *= product.Quantity;
          if (product.LinkedProduct != null) {
              product.LinkedProduct.Quantity = product.Quantity;
              product.LinkedProduct.FinalSalePrice *= product.Quantity;
              product.LinkedProduct.OriginlaSalePrice *= product.Quantity;
          }
      });

      this.CalculateQuoteLineItemTotal(this.quoteLineItemsList.data);
  });
}

 formatDateObject(dateString: any): any {
  return moment(dateString).format("MMMM DD, YYYY").toString();
}
  
  CalculateQuoteLineItemTotal(productItems: any[]) {
    this.quoteLineItemSubTotal = 0;
    this.totalQuoteValue = 0;
    this.totalDiscount = 0;
    this.productSubTotal = 0;
    this.DisplayTotalFinalSalePrice = 0;
    this.productsAndLinkedProducts = [];

    if (productItems.length > 0) {
      productItems.forEach((product) => {
        this.productsAndLinkedProducts.push(product);
        if (product.LinkedProduct) {
          this.productsAndLinkedProducts.push(product.LinkedProduct);
        }
      });

      this.productsAndLinkedProducts.forEach((product) => {
        this.quoteLineItemSubTotal += product.FinalSalePrice;
        this.productSubTotal += product.OriginlaSalePrice;

        const productDiscount = product.OriginlaSalePrice * (product.Discount / 100);
        this.totalDiscount += productDiscount;

        this.DisplayTotalFinalSalePrice += product.DisplayFinalSalePrice * product.Quantity;
      });
    }

    if (this.selectedCustomerDetails?.TaxName && this.selectedCustomerDetails?.TaxPercentage > 0) {
      this.calculateQuoteLineItemTax(this.productsAndLinkedProducts, this.selectedCustomerDetails);
    } else {
      this.totalQuoteValue = this.productSubTotal - this.totalDiscount;
    }
  }

  calculateQuoteLineItemTax(productItems: any[], customerTaxDetails: any) {
    this.totalTaxAmount = 0;
    this.linkedProductTaxAmount = 0;
    this.productsAndLinkedProducts = productItems;

    if (customerTaxDetails.TaxTypeName === 'TAX_TYPE_DROPDOWN_OPTION_CUMULATIVE_TAX') {
      const subTaxArray = customerTaxDetails.SubTaxes.split(',');

      if (this.productsAndLinkedProducts.length > 0) {
        this.productsAndLinkedProducts.forEach((product) => {
          if (product.IsTaxable) {
            let cummulativeTax = 0;
            subTaxArray.forEach((index) => cummulativeTax += parseInt(index));
            product.Tax = cummulativeTax;

            subTaxArray.forEach((TaxPercentage) => {
              const subTax = product.FinalSalePrice * (parseFloat(TaxPercentage) / 100);
              product.FinalSalePrice += subTax;
              product.TaxFinalSalePriceAfterDiscount = product.FinalSalePrice;
              this.totalTaxAmount += subTax;
            });
          }
        });
      }
    } else {
      this.productsAndLinkedProducts.forEach((product) => {
        if (product.IsTaxable) {
          product.Tax = parseInt(customerTaxDetails.TaxPercentage);
          const productTaxAmount = product.FinalSalePrice * (customerTaxDetails.TaxPercentage / 100);
          product.TaxFinalSalePriceAfterDiscount = productTaxAmount;
          this.totalTaxAmount += productTaxAmount;
        }
      });
    }

    this.totalQuoteValue = this.productSubTotal + this.totalTaxAmount - this.totalDiscount;
  }
  getDate(date:any) {
    //let date = this.getFormControlValue(form, controlName);
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    }
    return null;
  }

  getNgbDateStruct(date:any){
    if(date){
      date =new Date(date);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    }
    return null;
  }
  saveQuoteLineItemChanges(row: any, table: any, index: number): void {
    // Ensure Quantity is valid and within the allowed range
    if (row.Quantity === undefined || row.Quantity > 9999 || row.Quantity < 1 ||  row.Quantity % 1 !== 0) {

      this._toastService.error(this.translateService.instant('TRANSLATE.ERROR_QUOTE_DETAILS_ARE_HAVING_INVALID_OR_NEGATIVE_VALUES_MESSAGE'));

      return;
    }

    // Update FinalSalePrice and OriginalSalePrice based on Quantity
    row.OriginlaSalePrice = row.DisplayOriginlaSalePrice;
    row.FinalSalePrice = row.DisplayOriginalFinalSalePrice;
    row.FinalSalePrice *= row.Quantity;
    row.OriginlaSalePrice *= row.Quantity;

    // If there is a linked product, update its prices and quantity as well
    if (row.LinkedProduct != null) {
      row.LinkedProduct.OriginlaSalePrice = row.LinkedProduct.DisplayOriginlaSalePrice;
      row.LinkedProduct.FinalSalePrice = row.LinkedProduct.DisplayOriginalFinalSalePrice;
      row.LinkedProduct.Quantity = row.Quantity;
      row.LinkedProduct.FinalSalePrice *= row.Quantity;
      row.LinkedProduct.OriginlaSalePrice *= row.Quantity;
    }

    // Recalculate the quote line item totals
    this.CalculateQuoteLineItemTotal(table.data);

    // Exit editing mode for the row
    row.isEditing = false;
  }
  

  
  getProductsForQuoteLineItemPoup() {
    const quoteId = this.QuoteId;

    const quoteLineItemsReqBody = {
      QuoteId: quoteId,
      CustomerC3Id: this.currentC3CustomerId,
      CurrencyCode: this.selectedCustomerDetails.CurrencyCode,
      QuoteLineItemTable: this.quoteLineItemsData,
    };

    const modalRef = this._modalService.open(QuoteLineItemPopUpComponent, {
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      size: 'xl',
      backdrop: 'static',

    });

    modalRef.componentInstance.quoteId = quoteId;
    modalRef.componentInstance.currencyCode = this.selectedCustomerDetails.CurrencyCode;
    modalRef.componentInstance.setCustomerC3Id(this.currentC3CustomerId);
    modalRef.componentInstance.quoteLineItemsTableData = this.quoteLineItemsData;

    modalRef.result.then(
      (result: any) => {
        const productDetails = result.UniqueselectedQuoteLineItemList;
        const removedProducts = result.RemovedProducts;

        if (removedProducts) {
          removedProducts.forEach((deletedProduct: any) => {
            this.quoteLineItemsData.forEach((product: any) => {
              if (product.PlanProductId === deletedProduct.PlanProductId) {
                if (product.QuoteLineItemId) {
                  this.deletedQuoteLineItemIds.push(product.QuoteLineItemId);
                }
              }
            });
          });
        }

        productDetails.forEach((product: any) => {
          if (!this.quoteLineItemsData.includes(product)) {
            this.quoteLineItemsData.push(product);
          }
        });

        if (removedProducts && this.quoteLineItemsData) {
          this.quoteLineItemsData = this.quoteLineItemsData.filter(productData =>
            !removedProducts.some(product => product.PlanProductId === productData.PlanProductId)
          );
        }

        // Filtering out duplicates
        this.quoteLineItemsData = this.quoteLineItemsData.filter((obj, index, self) =>
          index === self.findIndex(o => obj.PlanProductId === o.PlanProductId && obj.Name === o.Name)
        );

        this.quoteLineItemsList.data = this.quoteLineItemsData;
        this.CalculateQuoteLineItemTotal(this.quoteLineItemsList.data);
        this.actionHeaderLoader();
      },
      (error) => {
        this.actionHeaderLoader();
      }
    );
  }
  deleteQuoteLineItem(row: any, index: number): void {
    const btnok = this.translateService.instant('TRANSLATE.BUTTON_TEXT_OK')
    const confirmationMessage = this.translateService.instant('TRANSLATE.POPUP_DELETE_QUOTE_LINE_ITEM_CONFIRMATION_TEXT', { ProductName: row.Name });

    this._notifierService.confirm({ title: confirmationMessage,confirmButtonText: btnok,confirmButtonColor:'#f8285a'  }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          // Mark the item as inactive
          row.IsActive = 0;

          // Remove the item from the quote line items list
          this.quoteLineItemsList.data.splice(index, 1);

          // Track the deleted item by adding its ID to deletedQuoteLineItemIds
          this.deletedQuoteLineItemIds.push(row.QuoteLineItemId);

          // Handle localStorage updates
          const localStorageKeyPrepend = 'QuoteLineItems_CustomerC3Id';
          const localStorageQuoteKeyName = `${localStorageKeyPrepend}_${this.currentC3CustomerId}`;
          const storedData = this._quotesService.dictOfQuoteLineItems['QuoteLineItems_CustomerC3Id_' + this.currentC3CustomerId];

          if (storedData !== undefined && storedData !== null && storedData !== '') {
            this.localStorageQuoteKeyNameParsed = JSON.parse(storedData);
            this.localStorageQuoteKeyNameParsed = this.quoteLineItemsList.data;
            this._quotesService.dictOfQuoteLineItems[localStorageQuoteKeyName] = JSON.stringify(this.localStorageQuoteKeyNameParsed);
            //localStorage.setItem(localStorageQuoteKeyName, JSON.stringify(this.localStorageQuoteKeyNameParsed));
          } else {
            this._quotesService.dictOfQuoteLineItems[localStorageQuoteKeyName] = JSON.stringify(this.quoteLineItemsList.data);
            //localStorage.setItem(localStorageQuoteKeyName, JSON.stringify(this.quoteLineItemsList.data));
          }

          // Recalculate the totals after deletion
          this.CalculateQuoteLineItemTotal(this.quoteLineItemsList.data);
          this.cdRef.detectChanges();
        }, 0);
      }
    });
  }



  UpdateCustomerName(row: any){
    const confirmationMessage = this.translateService.instant('TRANSLATE.POPUP_UPDATE_CUSTOMER_NAME_CONFIRMATION_TEXT');
    this._notifierService.confirm({title:confirmationMessage}).then((result: { isConfirmed: any; isDenied: any; }) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (row != null) {
          row.customerNameToUpdate = this.frmWizardTwo.get('customerNameToUpdate')?.value
          let n = this.frmWizardTwo.get('customerNameToUpdate')?.value.split(' ');
          if (n.length >= 2) {
            row.LastName = n[n.length - 1];
            row.FirstName = n.slice(0, n.length - 1).join(' ');
          } else {
            row.FirstName = n[0];
            row.LastName = '';
          }
          row.FullName = row.customerNameToUpdate;
          row.IsEdit = false;
          this._toastService.success(
            this.translateService.instant('TRANSLATE.CUSTOMER_NAME_UPDATE_SUCCESS'));
      }
      else {
        this._toastService.error(
          this.translateService.instant('TRANSLATE.CUSTOMER_NAME_UPDATE_FAILURE'));
      }
      }
    });
  }
  
  onExportPDF() {
    this._quotesService.exportPDF('sample-table-for-pdf-view', this.selectedCustomerDetails.CustomerName, this.frmAddQuote_QuoteName);
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
    this._unsavedChangesService.setUnsavedChanges(false);
  }

  private trackFormChanges(form: FormGroup) {
    form.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this._unsavedChangesService.setUnsavedChanges(form.dirty);
    });
  }
  copyToClipboard(): void {
    let env :any =localStorage.getItem('AvailableEnvironments');
   env=JSON.parse(env);
   let envid =env[0]?.Id;
   const shareableUrl = `${window.location.protocol}//${window.location.host}/quote/${envid}/${this.quoteURL}`;
  

   navigator.clipboard.writeText(shareableUrl).then(() => {
     this._notifierService.success({
       title: this.translateService.instant('TRANSLATE.QUOTE_COPY_CONFIRMATION_SUCCESS_MESSAGE')
     });
   }).catch(() => {
     this._notifierService.alert({
       title: this.translateService.instant('TRANSLATE.QUOTE_COPY_CONFIRMATION_ERROR_MESSAGE')
     });
   });
 }


}

