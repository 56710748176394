<ng-template #actionHeader>
    <div class="header-actions d-flex align-items-center gap-2 gap-lg-3">
        <div class="d-flex align-items-center">
            <!--begin::Button::QUOTE_BUTTON_TEXT_ADD_QUOTE-->
            <a class="btn btn-light-primary btn-sm btn-light-primary" (click)="BackToQuote()">
                <i class="ki-outline ki-to-left fs-2"></i><span
                    translate="TRANSLATE.QUOTE_BUTTON_TEXT_BACK_TO_QUOTE_LIST"></span>
            </a>
            <!--end::Button::QUOTE_BUTTON_TEXT_ADD_QUOTE-->
        </div>
    </div>
</ng-template>
<!--begin::Stepper-->
<div class="stepper stepper-links d-flex flex-column" id="kt_stepper_example_basic">
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
            <div class="card card-custom">
                <div class="card-body p-0">
                    <!--begin::Nav-->
                    <div class="stepper-nav">
                        <!--begin::Step 1-->
                        <div class="stepper-item mx-4 my-4 current"  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class=" stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition || QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 0) && presenttab != 0 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>1.</span>{{'TRANSLATE.QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 1-->

                        <!--begin::Step 2-->
                        <div class="stepper-item mx-4 my-4"  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition ||this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 1) && presenttab != 1 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>2.</span>{{'TRANSLATE.QUOTE_BUYER_INFO_WIZARD_TITLE_BUYER_INFO' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 2-->

                        <!--begin::Step 3-->
                        <div class="stepper-item mx-4 my-4 "  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition ||this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 2 ) && presenttab != 2 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>3.</span>{{'TRANSLATE.QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 3-->

                        <!--begin::Step 4-->
                        <div class="stepper-item mx-4 my-4"  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition ||this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 3) && presenttab != 3 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>4.</span>{{'TRANSLATE.QUOTE_LINE_ITEMS_WIZARD_TITLE_LINE_ITEMS' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 4-->

                        <!--begin::Step 5-->
                        <div class="stepper-item mx-4 my-4"  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition ||this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 4 ) && presenttab != 4 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>5.</span>{{'TRANSLATE.QUOTE_SIGNATURE_WIZARD_TITLE_SIGNATURE' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 5-->

                        <!--begin::Step 6-->
                        <div class="stepper-item mx-4 my-4"  data-kt-stepper-element="nav">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex align-items-center">
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title"  [ngStyle]="(disableCondition ||this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED || CompletedStateCount >= 5 ) && presenttab != 5 ? { color: 'rgb(33, 147, 70)' } : {}">
                                        <span>6.</span>{{'TRANSLATE.QUOTE_REVIEW_AND_PUBLISH_WIZARD_TITLE_REVIEW_AND_PUBLISH' | translate}}
                                    </h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Wrapper-->

                            <!--begin::Line-->
                            <div class="stepper-line"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 6-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Nav-->
    <div class="d-flex flex-column-fluid mt-4">
        <!--begin::Container-->
        <div class="container">
                <div class="p-0">
                    <div class="row">
                        <div class="col-xl-12 col-xxl-12">
                            <div class="row">
                                <!--begin::Form-->
                                <form class="form" [formGroup]="frmWizardOne" novalidate="novalidate"
                                    id="kt_stepper_example_basic_form">
                                    <!--begin::Group-->
                                    <!--begin::Step 1-->
                                    <div class="flex-column current" data-kt-stepper-element="content">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="card shadow wizard-form-height fixed-height-one">
                                                    <div class="card-header">
                                                        <div class="card-title">
                                                            <h4 class="fw-bold fs-2 text-dark"> {{'TRANSLATE.QUOTE_ADD_WIZARD_TITLE_QUOTE_DETAILS' |translate}}</h4>
                                                        </div>
                                                    </div>
                                                    <div class="card-body pb-5">
                                                        <!--begin::Choose Customer--> 
                                                        <div class="form-group row mb-4">
                                                            <div class="text-left text-sm-left text-md-right text-lg-right text-start">
                                                                <label class="text-grey-800 fs-6 fw-semibold">
                                                                    {{'TRANSLATE.QUOTE_LABEL_TEXT_CHOOSE_CUSTOMER' | translate}}
                                                                    <span class="text-danger">*</span>
                                                                </label>
                                                            </div>
                                                           
                                                            <div> 
                                                                <select class="form-select form-select-sm fw-bold" required="true"
                                                                    name="customer_id" formControlName="customer_id"
                                                                    [disabled]="PageMode === 'Edit' || PageMode === 'View'"
                                                                    (change)="customerContactList()">
                                                                    @for(customer of customers; track $index){
                                                                        
                                                                        <option [value]="customer?.C3Id" >
                                                                            {{customer?.CustomerName}}
                                                                        </option>
                                                                    }
                                                                </select>
                                                            </div>
                                                            @if(frmWizardOne.get('customer_id')?.errors?.required &&
                                                            frmWizardOne.get('customer_id')?.touched){
                                                            <span class="text-danger"
                                                                translate="TRANSLATE.VALIDATION_MESSAGE_REQUIRED"></span>
                                                            }
                                                        </div>
                                                        <!--end::Choose Customer-->
                                                        <!--begin::Template-->
                                                        <div class="form-group mb-4">
                                                            <div class="text-left text-sm-left text-md-right text-lg-right text-start">
                                                                <label class="text-grey-800 fs-6 fw-semibold">
                                                                    {{'TRANSLATE.QUOTE_LABEL_TEXT_TEMPLATE' | translate}}
                                                                    <span class="text-danger">*</span>
                                                                </label>
                                                            </div>
                                                            
                                                            <select class="form-select form-select-sm fw-bold" name="frmAddQuote_Template"
                                                                formControlName="frmAddQuote_Template" 
                                                                [disabled]="[cloudHubConstants.QUOTE_LIST_STATUS_APPROVED,cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, cloudHubConstants.QUOTE_LIST_STATUS_DELETED,cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(QuoteStatus) || PageMode === 'View'">
                                                                <option [value]="''">Basic</option>
                                                                
                                                            </select>
                                                            @if(frmWizardOne.get('frmAddQuote_Template')?.errors?.required && frmWizardOne.get('frmAddQuote_Template')?.touched){
                                                            <span class="text-danger"
                                                                translate="TRANSLATE.VALIDATION_MESSAGE_REQUIRED"></span>
                                                            }
                                                        </div>
                                                        <!--end::Template-->
                                                        <!--begin::Quote Name-->
                                                        <div class="form-group mb-4">
                                                            <div class="text-left text-sm-left text-md-right text-lg-right text-start">
                                                                <label class="text-grey-800 fs-6 fw-semibold">
                                                                    {{'TRANSLATE.QUOTE_LABEL_TEXT_QUOTE_NAME' | translate}}
                                                                    <span class="text-danger">*</span>
                                                                </label> 
                                                            </div> 
                                                            <input type="text" name="frmAddQuote_QuoteName"
                                                                formControlName="frmAddQuote_QuoteName"
                                                                (change)="onFormChange()"
                                                                class="form-control form-control-sm "
                                                                placeholder="{{'TRANSLATE.QUOTE_LABEL_TEXT_QUOTE_NAME' | translate}}"
                                                                [disabled]="[cloudHubConstants.QUOTE_LIST_STATUS_APPROVED,cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, cloudHubConstants.QUOTE_LIST_STATUS_DELETED,cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(QuoteStatus) || PageMode === 'View'"
                                                                [required]="true">
                                                                @if (frmWizardOne.get('frmAddQuote_QuoteName')?.errors?.required
                                                            &&
                                                            frmWizardOne.get('frmAddQuote_QuoteName')?.touched){
                                                            <span class="text-danger"><span
                                                                    translate="TRANSLATE.VALIDATION_MESSAGE_REQUIRED"></span></span>
                                                            }
                                                            @if
                                                            (frmWizardOne.get('frmAddQuote_QuoteName')?.errors?.maxlength &&
                                                            frmWizardOne.get('frmAddQuote_QuoteName')?.touched) {
                                                            <span class="text-danger"><span
                                                                    translate="TRANSLATE.QUOTE_NAME_MAXLENGTH_ERROR_MESSAGE"></span></span>
                                                            }
                                                        </div>
                                                        <!--end::Quote Name-->
                                                        <!--begin::Expiration Date-->
                                                        <div class="form-group mb-4">
                                                            <div class="text-left text-sm-left text-md-right text-lg-right text-start">
                                                                <label class="text-grey-800 fs-6 fw-semibold">
                                                                    {{'TRANSLATE.QUOTE_LABEL_TEXT_EXPIRATION_DATE' | translate}}
                                                                    <span class="text-danger">*</span>
                                                                </label>
                                                            </div>
                                                            
                                                            <div ng-controller="DatepickerUIController">
                                                                <div class="form-group">
                                                                  <div class="input-group date mb-2">
                                                                    @if(PageMode != 'View' ){
                                                                      <input class="form-control form-control-sm  mb-lg-0" 
                                                                             name="expireDate" 
                                                                             placement="bottom-right"
                                                                             formControlName="expireDate"
                                                                             (ngModelChange)="onFormChange()"
                                                                             placeholder="{{'TRANSLATE.QUOTE_LABEL_TEXT_EXPIRATION_DATE'|translate}}"
                                                                             [minDate]="todayDate" 
                                                                             [readOnly]="true"
                                                                             ngbDatepicker #sd="ngbDatepicker"
                                                                             [disabled]="[cloudHubConstants.QUOTE_LIST_STATUS_APPROVED, cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, cloudHubConstants.QUOTE_LIST_STATUS_DELETED, cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(QuoteStatus) || PageMode === 'View'"
                                                                             [required]="true">
                                                                      <button class="btn btn-sm btn-light-primary me-6" (click)="sd.toggle()" type="button">
                                                                        <i class="ki-outline ki-calendar fs-3 pe-0"></i>
                                                                      </button>
                                                                    } @else {
                                                                      <input class="form-control  mb-lg-0" 
                                                                             name="expireDate" 
                                                                             formControlName="expireDate"
                                                                             (ngModelChange)="onFormChange()"
                                                                             [readOnly]="true"
                                                                             [placeholder]="frmAddQuote_ExpiresOn ? frmAddQuote_ExpiresOn : globalDateFormat"
                                                                             ngbDatepicker #sd="ngbDatepicker"
                                                                             [disabled]="[cloudHubConstants.QUOTE_LIST_STATUS_APPROVED, cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, cloudHubConstants.QUOTE_LIST_STATUS_DELETED, cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(QuoteStatus) || PageMode === 'View'"
                                                                             [required]="true">
                                                                      <button class="btn btn-sm btn-light-primary me-6" (click)="sd.toggle()" type="button">
                                                                        <i class="ki-outline ki-calendar fs-3 pe-0"></i>
                                                                      </button>
                                                                    }
                                                                  </div>
                                                              
                                                                  <!-- Validation Message Block Moved Here -->
                                                                  @if (frmWizardOne.get('expireDate')?.errors?.required && frmWizardOne.get('expireDate')?.touched){
                                                                    <div class="text-danger mt-1">
                                                                      <span translate="TRANSLATE.VALIDATION_MESSAGE_REQUIRED"></span>
                                                                    </div>
                                                                  }
                                                                </div>
                                                              </div>
                                                              
                                                        </div>
                                                        <!--end::Expiration Date-->
                                                        <!--begin::Comments to Buyers-->
                                                        <div class="form-group mb-4">
                                                            <label class="text-grey-800 fs-6 fw-semibold">
                                                                <span
                                                                    translate="TRANSLATE.QUOTE_LABEL_TEXT_COMMENTS_TO_BUYER"></span>
                                                            </label>
                                                            <div [ngxSummernote]="config" name="quote_description"
                                                                formControlName="quote_description"
                                                                (ngModelChange)="onFormChange()"
                                                                [ngxSummernoteDisabled]="PageMode === 'View'">
                                                            </div>
                                                        </div>
                                                        <!--end::Comments to Buyers-->
                                                        <!--begin::Purchase Team-->
                                                        <div class="form-group">
                                                            <label class="text-grey-800 fs-6 fw-semibold">
                                                                <span
                                                                    translate="TRANSLATE.QUOTE_LABEL_TEXT_PURCHASE_TERM"></span>
                                                            </label>
                                                            <div [ngxSummernote]="config" name="quote_puchasedescription"
                                                                formControlName="quote_puchasedescription"
                                                                (ngModelChange)="onFormChange()"
                                                                [ngxSummernoteDisabled]="PageMode === 'View'">
                                                            </div>
                                                        </div>
                                                        <!--end::Purchase Team-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 shadow  bg-white rounded sc  container"
                                                style="border: 1px solid #B7B5B4;" ng-show="vm.activeTab==='one'">
                                                <div class="fixed-height-one" id="sample-table-for-pdf" infiniteScroll 
                                                [infiniteScrollDistance]="0.3"
                                                [infiniteScrollThrottle]="50" 
                                                [scrollWindow]="false"
                                                >
                                                    <app-quote-pdf-view [selectedCustomerDetails]="selectedCustomerDetails"
                                                    [frmAddQuote_QuoteName]="frmAddQuote_QuoteName"
                                                    [addressDetails]="addressDetails" 
                                                    [QuoteContact]="QuoteContact"
                                                    [users]="users" 
                                                    [quoteLineItemsData]="quoteLineItemsData"
                                                    [totalQuoteValue]="totalQuoteValue"
                                                    [DisplayTotalFinalSalePrice]="DisplayTotalFinalSalePrice"
                                                    [totalTaxAmount]="totalTaxAmount" 
                                                    [CommentsToBuyer]="CommentsToBuyer"
                                                    [PurchaseTerms]="PurchaseTerms" 
                                                    [IsShowCounterSign]="IsShowCounterSign"
                                                    [IsCounterSign]="IsCounterSign" 
                                                    [PDFlogobase64]="PDFlogobase64"
                                                    [CurrencySymbol]="CurrencySymbol"
                                                    [CurrencyDecimalPlaces]="CurrencyDecimalPlaces"
                                                    [CurrencyThousandSeperator]="CurrencyThousandSeperator"
                                                    [CurrencyDecimalSeperator]="CurrencyDecimalSeperator"
                                                    [currentDate]="currentDate"
                                                    [frmAddQuote_ExpiresOn]="frmAddQuote_ExpiresOn"></app-quote-pdf-view>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Step 1-->
                                    <!--end::Group-->
                                </form>
                                <!--end::Form-->
                                <div class="flex-column" data-kt-stepper-element="content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card shadow wizard-form-height fixed-height-two ">
                                                <div class="card-header">
                                                    <div class="card-title w-100">
                                                        <h4 class="fw-bold fs-2 text-dark"> {{'TRANSLATE.QUOTE_BUYER_INFO' |translate}}</h4>
                                                    </div>
                                                    <div class="card-subtitle">
                                                        <span class="text-muted mt-1 fw-semibold fs-7">{{'TRANSLATE.QUOTE_CHOOSE_BUYER_DETAILS' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <h5 class="mb-10 font-weight-bold text-dark mt-5"> {{'TRANSLATE.QUOTE_INCLUDE' | translate}}</h5>
                                                    <form [formGroup]="frmWizardTwo">
                                                        <div class="pb-5">
                                                            <div class="border border-white background-white">
                                                                <div class="">
                                                                    <div class="card card-custom card-dashed card-stretch gutter-b background-white">
                                                                        <div class="card-header pt-3">
                                                                            <h1 class="card-title align-items-start mt-5">
                                                                                <div class="form-check">
                                                                                    <input type="checkbox" class="form-check-input me-1 text-primary" [hidden]="PageMode === 'View'"
                                                                                        (change)="CheckAllContact()" formControlName="SelectAllColumn"/> 
                                                                                    <label class="form-check-label" for="kt_check_indeterminate_1">
                                                                                    </label>
                                                                                </div>
                                                                                <span class="align-top text-grey-700" translate="TRANSLATE.QUOTE_CONTACT"></span>
                                                                                <a (click)="addExistingCustomer()">
                                                                                    <i class="ki-outline ki-address-book text-primary fs-2x align-top">
                                                                                    </i>
                                                                                </a>
                                                                            </h1>
                                                                            <div class="card-toolbar mt-0">
                                                                                <div id="past-dues-apexcharts-menu-wrapper" class="apexcharts-toolbar"
                                                                                    style="position: relative;padding-right: 15px; padding-top:0">
                                                                                    <a (click)="addNewCustomer()" [hidden]="PageMode === 'View'">
                                                                                        <i class="ki-outline ki-plus-circle  fs-1 text-primary mt-0"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="border-bottom"></div>
                                                                        <div class="card-body p-0 background-white">
                                                                            <!-- <perfect-scrollbar class="scroller-custom" wheel-propagation="true" swipe-propagation="true" on-scroll="onScroll(scrollTop, scrollHeight)" always-visible="true" style="height: 270px!important" *ngIf="vm.QuoteContact && vm.QuoteContact.length > 0"> -->
                                                                            <div class="pt-2" id="past-dues-card-png">
                                        
                                                                                @for(row of QuoteContact; track $index){
                                                                                <div class="d-flex flex-wrap align-items-center mb-3" style=" padding: 2rem 2.25rem">
                                                                                    <div class="symbol symbol-primary flex-shrink-0">
                                                                                        <div class="form-check">
                                                                                            <input type="checkbox" class="text-primary form-check-input me-2"  [(ngModel)]="row.IsChecked"
                                                                                            [checked]="SelectAllColumn || row.IsChecked" [hidden]="PageMode === 'View'" formControlName="checkbox1" />
                                                                                        </div>
                                                                                        <!-- <input type="checkbox" class="text-primary form-check-input me-2"  [(ngModel)]="row.IsChecked"
                                                                                            [checked]="SelectAllColumn || row.IsChecked" [hidden]="PageMode === 'View'" formControlName="checkbox1" /> -->
                                                                                    </div>
                                                                                    <div class="me-4">
                                                                                        <div>
                                                                                            <!--begin::Logo-->
                                                                                            <a>
                                                                                                <img src='../../../../assets/media/img/Logo Shapes 19.png' class="theme-light-show"
                                                                                                    style="max-width: 50px; max-height: 50px;" alt="">
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                                                                        <span class="text-primary fw-bold" *ngIf="row.IsEdit != true">
                                                                                            {{row.FullName}}
                                                                                            <i class="ki-outline ki-pencil text-primary" aria-hidden="true" style="margin-bottom:0 !important;padding-bottom:0 !important"
                                                                                                (click)="editRow(row)" *ngIf="row.IsEdit != true" [hidden]="PageMode === 'View'"></i>
                                                                                        </span>
                                                                                        <span class="row" *ngIf="row.IsEdit === true">
                                                                                            <span class="col-md-6">
                                                                                                <input type="text" class="form-control"  formControlName="customerNameToUpdate"
                                                                                                    placeholder="{{'TRANSLATE.PARTNER_CUSTOMER_FILTER_NAME' | translate}}"
                                                                                                    ng-init="row.customerNameToUpdate = row.FullName">
                                                                                            </span>
                                                                                            <span class="btn-group col-md-3 p-0">
                                                                                                <a class="btn btn-icon" (click)="UpdateCustomerName(row)" *ngIf="row.IsEdit === true">
                                                                                                    <i class="ki-outline ki-check fs-1 text-success"></i>
                                                                                                </a>
                                                                                                <a class="btn btn-icon" (click)="row.IsEdit = false" *ngIf="row.IsEdit === true">
                                                                                                    <i class="ki-outline ki-cross fs-1  text-danger"></i>
                                                                                                </a>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span class="fs-lg fs-sm" style="word-wrap: break-word; max-width: 355px;">
                                                                                            {{row.EmailId}}
                                                                                        </span>
                                                                                        <span class="font-size-lg font-size-sm">
                                                                                            {{row.PhoneNumber}}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                            <!-- </perfect-scrollbar> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="border border-white mt-10 background-white">
                                                                <div class="">
                                                                    <div class="card card-custom card-dashed card-stretch gutter-b background-white">
                                                                        <div class="card-header  pt-5">
                                                                            <h3 class="card-title align-items-start flex-column">
                                                                                <span class="card-label font-weight-bolder text-dark font-size-h3"><span
                                                                                        class="align-top" translate="TRANSLATE.QUOTE_COMPANY"></span></span>
                                                                            </h3>
                                                                            <div class="card-toolbar">
                                                                                <div id="past-dues-apexcharts-menu-wrapper" class="apexcharts-toolbar" style="position: relative;padding-right: 15px;">
                                                                                    <div class="apexcharts-menu-icon" title="Menu">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="border-bottom"></div>
                                                                        <div class="card-body pt-0 background-white">
                                                                            <!-- <perfect-scrollbar class="scroller-custom" wheel-propagation="true" swipe-propagation="true" on-scroll="onScroll(scrollTop, scrollHeight)" style="height: 100px!important" always-visible="true" *ngIf="vm.addressDetails && vm.addressDetails.length > 0"> -->
                                                                            <div class="pt-2" id="past-dues-card-png">
                                                                                @for(row of addressDetails; track $index){
                                                                                <div class="d-flex flex-wrap align-items-center mb-5">
                                                                                    <div class="symbol symbol-primary flex-shrink-0">
                                                                                        <div class="form-check">
                                                                                            <input type="checkbox" class="text-primary form-check-input me-2" [checked]="row.IsChecked" [(ngModel)]="row.IsChecked"
                                                                                            [hidden]="PageMode === 'View'" formControlName="checkbox2" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="me-4">
                                                                                        <div>
                                                                                            <!--begin::Logo-->
                                                                                            <a>
                                                                                                <img src='../../../../assets/media/img/Standard Collection 3.png'
                                                                                                    class="theme-light-show"
                                                                                                    style="max-width: 50px; max-height:50px;" alt="">
                                                                                            </a>
                                                                                            <!--End logo-->
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                                                        <span class="text-primary font-weight-bold pb-0 pl-1 mb-0">
                                                                                            {{customerName}}
                                                                                        </span>
                                                                                        <span tooltip-placement="right" class="font-size-lg font-size-sm"
                                                                                            style="max-width: 300px;"> 
                                                                                            {{row.Line1}}
                                                                                            {{row.Line2}}<br />
                                                                                            {{row.City}}
                                                                                            {{row.State}}
                                                                                            {{row.Country}}
                                                                                            {{row.Zip}}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                            <!-- </perfect-scrollbar> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 shadow  bg-white rounded sc pl-7 container "
                                        style="border: 1px solid #B7B5B4;" ng-show="vm.activeTab==='one'">
                                        <div class="fixed-height-two"  id="sample-table-for-pdf" infiniteScroll 
                                        [infiniteScrollDistance]="0.3"
                                        [infiniteScrollThrottle]="50" 
                                        [scrollWindow]="false"
                                        style="overflow-y: auto;">
                                            <app-quote-pdf-view [selectedCustomerDetails]="selectedCustomerDetails"
                                            [frmAddQuote_QuoteName]="frmAddQuote_QuoteName"
                                            [addressDetails]="addressDetails" 
                                            [QuoteContact]="QuoteContact"
                                            [users]="users" 
                                            [quoteLineItemsData]="quoteLineItemsData"
                                            [totalQuoteValue]="totalQuoteValue"
                                            [DisplayTotalFinalSalePrice]="DisplayTotalFinalSalePrice"
                                            [totalTaxAmount]="totalTaxAmount" 
                                            [CommentsToBuyer]="CommentsToBuyer"
                                            [PurchaseTerms]="PurchaseTerms" 
                                            [IsShowCounterSign]="IsShowCounterSign"
                                            [IsCounterSign]="IsCounterSign" 
                                            [PDFlogobase64]="PDFlogobase64"
                                            [CurrencySymbol]="CurrencySymbol"
                                            [CurrencyDecimalPlaces]="CurrencyDecimalPlaces"
                                            [CurrencyThousandSeperator]="CurrencyThousandSeperator"
                                            [CurrencyDecimalSeperator]="CurrencyDecimalSeperator"
                                            [currentDate]="currentDate"
                                            [frmAddQuote_ExpiresOn]="frmAddQuote_ExpiresOn"></app-quote-pdf-view>
                                        </div>

                                    </div>
                                    </div>
                                </div>

                                <div class="flex-column" data-kt-stepper-element="content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card shadow wizard-form-height fixed-height-three">
                                                <div class="card-header">
                                                    <div class="card-title w-100">
                                                        <h4 class="fw-bold fs-2 text-dark"> {{'TRANSLATE.QUOTE_SELLER_INFO_WIZARD_TITLE_SELLER_INFO' |translate}}</h4>
                                                    </div>
                                                    <div class="card-subtitle">
                                                        <span class="text-muted mt-1 fw-semibold fs-7">{{'TRANSLATE.QUOTE_CHOOSE_SELLER_DETAILS' | translate}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <form class="form" [formGroup]="frmWizardThree" novalidate="novalidate"
                                                    id="kt_stepper_example_basic_form">
                                                    <div class="row">
                                                        <div>
                                                            <div class="pb-5" data-wizard-type="step-content">
                                                                <h5 class="mb-10 font-weight-bold text-dark mt-5"> {{'TRANSLATE.QUOTE_INCLUDE' | translate}}</h5>
                                                                <div class="card card-custom card-dashed card-stretch gutter-b background-white mb-10">
                                                                    <div class="card-header">
                                                                        <h3 class="card-title align-items-start flex-column">
                                                                            <span class="card-label fw-bolder text-dark fs-h3"><span class="align-top"
                                                                                    translate="TRANSLATE.QUOTE_SENDER"></span></span>
                                                                        </h3>
                                                                        <div class="card-toolbar">
                                                                            <div id="past-dues-apexcharts-menu-wrapper" class="apexcharts-toolbar"
                                                                                style="position: relative;padding-right: 15px;">
                                                                                <div class="apexcharts-menu-icon" title="Menu">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="border-bottom"></div>
                                                                    <div class="card-body d-flex  background-white">
                                                                        <div class="pt-2" id="past-dues-card-png">
                                                                            @for (row of users; track $index) {
                                                                            <div class="d-flex align-items-center mb-5">
                                                                                <div class="me-2 primary">
                                                                                    <i class="ki-outline ki-user fs-3x text-primary"
                                                                                        style="max-width: 65px !important"></i>
                                                                                </div>
                                                                                <div class="d-flex flex-column">
                                                                                    <span class="text-primary fw-bold pb-0 mb-0"
                                                                                        *ngIf="row.IsEdit != true">
                                                                                        {{row.Name}}
                                                                                        <!--<i class="fas fa-pen text-primary" aria-hidden="true" style="margin-bottom:0 !important;padding-bottom:0 !important" (click)="row.IsEdit = true" *ngIf="row.IsEdit != true"></i>-->
                                                                                    </span>
                                                                                    <span class="row" *ngIf="row.IsEdit === true">
                                                                                        <span class="col-md-9">
                                                                                            <input type="text" formControlName="UserNameUpdate"
                                                                                                class="form-control"
                                                                                                placeholder="{{'TRANSLATE.PARTNER_CUSTOMER_FILTER_NAME' | translate}}"
                                                                                                ng-init="row.userNameToUpdate = row.Name">
                                                                                        </span>
                                                                                        <span class="btn-group col-md-3 p-0">
                                                                                            <a class="btn btn-icon" (click)="UpdateUserName(row)"
                                                                                                *ngIf="row.IsEdit === true">
                                                                                                <i class="ki-outline ki-check fs-1 text-success"></i>
                                                                                            </a>
                                                                                            <a class="btn btn-icon" (click)="row.IsEdit = false"
                                                                                                *ngIf="row.IsEdit === true">
                                                                                                <i class="ki-outline ki-cross fs-1 text-danger"></i>
                                                                                            </a>
                                                                                        </span>
                                                                                    </span>
                                                                                    <span class="fs-lg fs-sm" style=" word-break: break-all; ">
                                                                                        {{row.EmailAddress}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                
                                                                <div class="card card-custom card-dashed card-stretch gutter-b background-white">
                                                                    <div class="card-header">
                                                                        <h3 class="card-title align-items-start flex-column">
                                                                            <span class="card-label fw-bolder text-dark fs-h3"><span class="align-top"
                                                                                    translate="TRANSLATE.QUOTE_COMPANY"></span></span>
                                                                        </h3>
                                                                    </div>
                                                                    <div class="border-bottom"></div>
                                                                    <div class="card-body background-white">
                                                                        <div class="d-flex align-items-center p-0">
                                                                            <div class="me-4">
                                                                                <div>
                                                                                    <!--begin::Logo-->
                                                                                    <a>
                                                                                        <img [src]="PDFlogobase64" alt="logo" class="img-fluid"
                                                                                            style="max-width: 65px !important" />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <div class="d-flex flex-column">
                                                                                <span class="text-primary font-weight-bold pb-0 mb-0">
                                                                                    {{ContactCompanyName}}
                                                                                </span>
                                                                                <div class="text-dark"> 
                                                                                    <span *ngIf="AddressLine1">{{AddressLine1}} &#44;</span>
                                                                                    <span *ngIf="City">{{City}}&#44;</span>
                                                                                    <span *ngIf="State">{{State}}&#44;</span>
                                                                                    <span *ngIf="ZipCode">{{ZipCode}}&#44;</span>
                                                                                    <span *ngIf="Country">{{Country}}&#44;</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                
                                        </div>
                                        <div class="col-md-6 shadow  bg-white rounded sc pl-7 container " style="border: 1px solid #B7B5B4;"
                                            ng-show="vm.activeTab==='one'">
                                            <div class="fixed-height-three" id="sample-table-for-pdf" infiniteScroll [infiniteScrollDistance]="0.3" [infiniteScrollThrottle]="50"
                                                [scrollWindow]="false" style="overflow-y: auto;">
                                                <app-quote-pdf-view [selectedCustomerDetails]="selectedCustomerDetails"
                                                    [frmAddQuote_QuoteName]="frmAddQuote_QuoteName" [addressDetails]="addressDetails"
                                                    [QuoteContact]="QuoteContact" [users]="users" [quoteLineItemsData]="quoteLineItemsData"
                                                    [totalQuoteValue]="totalQuoteValue" [DisplayTotalFinalSalePrice]="DisplayTotalFinalSalePrice"
                                                    [totalTaxAmount]="totalTaxAmount" [CommentsToBuyer]="CommentsToBuyer"
                                                    [PurchaseTerms]="PurchaseTerms" [IsShowCounterSign]="IsShowCounterSign"
                                                    [IsCounterSign]="IsCounterSign" [PDFlogobase64]="PDFlogobase64" [CurrencySymbol]="CurrencySymbol"
                                                    [CurrencyDecimalPlaces]="CurrencyDecimalPlaces"
                                                    [CurrencyThousandSeperator]="CurrencyThousandSeperator"
                                                    [CurrencyDecimalSeperator]="CurrencyDecimalSeperator" [currentDate]="currentDate"
                                                    [frmAddQuote_ExpiresOn]="frmAddQuote_ExpiresOn"></app-quote-pdf-view>
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>

                                <div class="flex-column" data-kt-stepper-element="content">
                                    <form [formGroup]="frmWizardFour" role="form" novalidate>

                                        <div *ngIf="activeTab === 'four'" class="pb-5">
                                            <div class="card shadow ">
                                                <div class="card-header">
                                                    <div class="card-title w-100">
                                                        <h4 class="fw-bold fs-2 text-dark"> {{'TRANSLATE.QUOTE_LINE_ITEM_HEADER' |translate}}</h4>
                                                    </div>
                                                    <div class="card-subtitle">
                                                        <span class="text-muted mt-1 fw-semibold fs-7">{{'TRANSLATE.QUOTE_LINE_ITEM_SUB_HEADER' | translate}}</span>
                                                    </div>
                                                    <div class="card-toolbar">
                                                        <button type="button"
                                                                class="btn btn-outline-primary btn-sm  btn-light-primary "
                                                                (click)="CustomLineItem()" *ngIf="PageMode !== 'View'">
                                                                {{ 'TRANSLATE.QUOTE_LINE_ITEM_BTN_ADD_CUSTOM_LINE_ITEM' |
                                                                translate }}
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button type="button"
                                                            class="btn btn-outline-primary btn-sm  btn-light-primary"
                                                            (click)="getProductsForQuoteLineItemPoup()"
                                                            *ngIf="PageMode !== 'View'">
                                                            {{ 'TRANSLATE.QUOTE_LINE_ITEM_BTN_ADD_PLAN_PRODUCT' | translate
                                                            }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div
                                                        class="table-responsive-lg table-responsive-md table-responsive-xxl table-responsive-sm table-responsive-xl remove-pagination pb-4">
                                                        <table
                                                            class="table table-bordered table-hover table-condensed editable-table fsaTable margin-top-3">
                                                            <thead class="fw-semibold">
                                                                <tr>
                                                                    <td
                                                                        class="body-alignment-normal col-md-4 thead-color-quotes">
                                                                        <span
                                                                            translate="TRANSLATE.QUOTE_LINE_ITEMS_TABLE_HEADER_OFFER_NAME"></span>
                                                                    </td>
                                                                    <td
                                                                        class="col-md-2 header-action-alignment thead-color-quotes text-end">
                                                                        <span
                                                                            translate="TRANSLATE.QUOTE_LINE_ITEMS_TABLE_HEADER_QUANTITY"></span>
                                                                    </td>
                                                                    <td
                                                                        class="col-md-2 header-price-alignment border-0 thead-color-quotes text-end">
                                                                        <span
                                                                            translate="TRANSLATE.QUOTE_LINE_ITEMS_TABLE_HEADER_COST_PRICE"></span>
                                                                    </td>
                                                                    <td
                                                                        class="col-md-2 header-price-alignment border-0 thead-color-quotes text-end">
                                                                        <span
                                                                            translate="TRANSLATE.QUOTE_LINE_ITEMS_TABLE_HEADER_SALE_PRICE"></span>
                                                                    </td>
                                                                    <td
                                                                        class="col-md-2 header-action-alignment thead-color-quotes text-end">
                                                                        <span
                                                                            translate="TRANSLATE.QUOTE_LINE_ITEMS_TABLE_HEADER_ACTIONS"></span>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let row of quoteLineItemsData; let i = index">
                                                                    <td class="body-alignment-normal col-md-3">
                                                                        <span>
                                                                            {{ row.Name }}&nbsp;<span
                                                                                *ngIf="row.LinkedSubscriptionName">
                                                                                <i
                                                                                    class="ki-duotone ki-paper-clip text-warning small"></i>&nbsp;&nbsp;<span
                                                                                    class="h5">|</span>&nbsp;{{
                                                                                row.LinkedSubscriptionName }}
                                                                            </span>
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            *ngIf="row.Validity !== null && row.ValidityType == 'Month(s)'"
                                                                            class="label label-warning font-weight-bold label-inline text-white badge bg-warning ms-md-2"
                                                                            aria-hidden="true"
                                                                            ngbTooltip="{{'TRANSLATE.CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION' | translate}}">
                                                                            {{row.Validity}} {{(row.Validity == 1?
                                                                            (row.ValidityType ===
                                                                            'Month(s)'?
                                                                            'TRANSLATE.TERM_DURATION_DESC_MONTH' :
                                                                            'TRANSLATE.TERM_DURATION_DESC_YEAR') :
                                                                            'TRANSLATE.TERM_DURATION_DESC_YEARS') | translate }}
                                                                        </span>
                                                                        <span
                                                                            *ngIf="row.ValidityType === 'Year(s)' || row.ValidityType === 'Year'"
                                                                            [ngClass]="{'labelcolor':row.BillingCycleName == 'Monthly','TermLabelColor': row.BillingCycleName == 'Annual'}"
                                                                            class="label label-dark font-weight-bold label-inline text-white badge bg-warning ms-md-2"
                                                                            aria-hidden="true"
                                                                            ngbTooltip="{{'TRANSLATE.CUSTOMER_PRODUCT_TOOLTIP_TEXT_TERM_DURATION' | translate}}">
                                                                            {{row.Validity}} {{(row.Validity == 1?
                                                                            (row.ValidityType ===
                                                                            'Month(s)'?
                                                                            'TRANSLATE.TERM_DURATION_DESC_MONTH' :
                                                                            'TRANSLATE.TERM_DURATION_DESC_YEAR') :
                                                                            'TRANSLATE.TERM_DURATION_DESC_YEARS') | translate }}
                                                                        </span>
       
                                                                        <span
                                                                            class="label label-dark font-weight-bold label-inline text-white badge bg-dark ms-md-2"
                                                                            aria-hidden="true"
                                                                            ngbTooltip="{{'TRANSLATE.CUSTOMER_PRODUCT_TOOLTIP_TEXT_BILLING_CYCLE' | translate}}"
                                                                            *ngIf="row.PlanProductId !== 0">
                                                                            {{ row.BillingCycleName }}
                                                                        </span>
                                                                        <span
                                                                            class="label label-light-info font-weight-bold label-inline badge ms-md-2"
                                                                            ngbTooltip="{{'TRANSLATE.CUSTOMER_PRODUCT_TOOLTIP_TEXT_CATEGORY' | translate}}"
                                                                            *ngIf="row.PlanProductId !== 0">
                                                                            {{ row.CategoryName === 'OnlineServicesNCE' ? 'NCE'
                                                                            : row.CategoryName }}
                                                                        </span>
                                                                        <span
                                                                            class="label label-success font-weight-bold label-inline text-white badge bg-success ms-md-2"
                                                                            *ngIf="row.PlanProductId !== 0"
                                                                            ngbTooltip="{{'TRANSLATE.CUSTOM_OFFER_TOOLTIP_TEXT_CONSUMTION_TYPE' | translate}}">
                                                                            {{ row.ConsumptionType }}
                                                                        </span>
        
                                                                    </td>
        
                                                                    <td class="body-action-alignment col-md-2 text-end">
                                                                        <ng-container *ngIf="!row.isEditing; else editQuantity">
                                                                            <span
                                                                                *ngIf="row.ConsumptionType?.toLowerCase() !== 'usagebased'">{{
                                                                                row.Quantity }}</span>
                                                                            <span
                                                                                *ngIf="row.ConsumptionType?.toLowerCase() === 'usagebased'">-</span>
                                                                        </ng-container>
                                                                        <ng-template #editQuantity>
                                                                            <input type="number" formControlName="Quantity"
                                                                                placeholder="{{ 'TRANSLATE.QUOTE_LINE_ITEM_TABLE_PLACE_HOLDER' | translate }}"
                                                                                [(ngModel)]="row.Quantity" min="1" max="9999"
                                                                                class="editable-input form-control input-sm"
                                                                                required />
                                                                        </ng-template>
                                                                    </td>
                                                                    <td class="body-action-alignment col-md-2 text-end">
                                                                        {{ row.ProviderPrice | CurrencyFilter :
                                                                        row.CurrencySymbol : row.CurrencyDecimalPlaces :
                                                                        row.CurrencyThousandSeperator :
                                                                        row.CurrencyDecimalSeperator }}<br />
                                                                        <span *ngIf="row.LinkedProduct">
                                                                            <i
                                                                                class="ki-duotone ki-paper-clip text-warning small"></i>&nbsp;{{
                                                                            row.LinkedProduct.ProviderPrice | CurrencyFilter :
                                                                            row.CurrencySymbol : row.CurrencyDecimalPlaces :
                                                                            row.CurrencyThousandSeperator :
                                                                            row.CurrencyDecimalSeperator }}
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        class="body-action-alignment fw-semibold col-md-2 text-end">
                                                                        <div class="font-weight-bolder">
                                                                            <span>
                                                                                {{ row.DisplayFinalSalePrice | CurrencyFilter :
                                                                                row.CurrencySymbol : row.CurrencyDecimalPlaces :
                                                                                row.CurrencyThousandSeperator :
                                                                                row.CurrencyDecimalSeperator }}
                                                                                <span *ngIf="row.Discount && row.Discount > 0"
                                                                                    class="text-danger">
                                                                                    <span class="strike-through red">
                                                                                        {{ row.DisplayOriginlaSalePrice |
                                                                                        CurrencyFilter : row.CurrencySymbol :
                                                                                        row.CurrencyDecimalPlaces :
                                                                                        row.CurrencyThousandSeperator :
                                                                                        row.CurrencyDecimalSeperator }}
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <div *ngIf="row.LinkedProduct">
                                                                                <span><i
                                                                                        class="ki-duotone ki-paper-clip text-warning small"></i></span>
                                                                                <span>
                                                                                    {{ row.LinkedProduct.DisplayFinalSalePrice |
                                                                                    CurrencyFilter : row.CurrencySymbol :
                                                                                    row.CurrencyDecimalPlaces :
                                                                                    row.CurrencyThousandSeperator :
                                                                                    row.CurrencyDecimalSeperator }}
                                                                                    <span
                                                                                        *ngIf="row.Discount && row.Discount > 0"
                                                                                        class="text-danger">
                                                                                        <span class="strike-through red">
                                                                                            {{
                                                                                            row.LinkedProduct.DisplayOriginlaSalePrice
                                                                                            | CurrencyFilter :
                                                                                            row.CurrencySymbol :
                                                                                            row.CurrencyDecimalPlaces :
                                                                                            row.CurrencyThousandSeperator :
                                                                                            row.CurrencyDecimalSeperator }}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="body-action-alignment col-md-3 text-end">
                                                                        <button type="button"
                                                                            class="btn btn-active-light-primary  btn-xs mr-2"
                                                                            ngbTooltip="{{'TRANSLATE.PROFILE_USER_BUTTON_TOOLTIP_TEXT_SAVE' | translate}}"
                                                                            (click)="saveQuoteLineItemChanges(row,quoteLineItemsList, i)"
                                                                            *ngIf="row.isEditing "
                                                                            [disabled]="PageMode === 'View'">
                                                                            <span class="ki-outline ki-file-added text-primary fs-xl"></span>
                                                                        </button>
                                                                        <button type="button"
                                                                            class="btn btn-active-light-primary  btn-xs mr-1"
                                                                            ngbTooltip="{{'TRANSLATE.PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT' | translate}}"
                                                                            (click)="row.isEditing = true;"
                                                                            *ngIf="!row.isEditing && row.PlanProductId !== 0"
                                                                            [disabled]="PageMode === 'View'">
                                                                            <span class="ki-outline ki-pencil  text-primary fs-xl"></span>
                                                                        </button>
                                                                        <button type="button"
                                                                            class="btn btn-active-light-primary  btn-xs mr-1"
                                                                            ngbTooltip="{{'TRANSLATE.PROFILE_USER_BUTTON_TOOLTIP_TEXT_EDIT' | translate}}"
                                                                            (click)="CustomLineItem(row, i)"
                                                                            *ngIf="!row.isEditing && row.PlanProductId === 0"
                                                                            [disabled]="PageMode === 'View'">
                                                                            <span class="ki-outline ki-pencil  text-primary fs-xl"></span>
                                                                        </button>
                                                                        <button type="button"
                                                                            class="btn btn-active-light-primary  btn-xs mr-1"
                                                                            ngbTooltip="{{'TRANSLATE.PROFILE_USER_BUTTON_TOOLTIP_TEXT_DELETE' | translate}}"
                                                                            (click)="deleteQuoteLineItem(row, i)"
                                                                            *ngIf="!row.isEditing"
                                                                            [disabled]="PageMode === 'View'">
                                                                            <span class="ki-outline ki-trash  text-primary fs-xl"></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div *ngIf="quoteLineItemsData.length === 0">
                                                        <div class="col-md-12">
                                                            <app-common-no-record></app-common-no-record>
                                                        </div>
                                                    </div>
        
                                                    <div
                                                        class="table-responsive-lg table-responsive-md table-responsive-xxl table-responsive-sm table-responsive-xl mt-3">
                                                        <table class="table table-bordered">
                                                            <thead class="thead-color-quotes">
                                                                <tr>
                                                                    <td class="col-md-12">
                                                                        {{ 'TRANSLATE.QUOTE_LINE_TABLE_HEADER_SUMMARY' |
                                                                        translate }}
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="12">
                                                                        <div
                                                                            class=" notice  bg-light-primary rounded border-primary border border-dashed flex-stack h-xl-100 mb-10 p-6">
                                                                            <p class="divided">
                                                                                <span>
                                                                                    {{
                                                                                    'TRANSLATE.QUOTE_LINE_ITEM_LABEL_SUBTOTAL' |
                                                                                    translate }}
                                                                                <i class="ki-duotone ki-information-2 fs-5 ms-1" aria-hidden="true" ngbTooltip="{{'TRANSLATE.QUOTE_LINE_ITEM_SUBTOTAL_TOOLTIP' | translate}}">
                                                                                    <span class="path1"></span>
                                                                                    <span class="path2"></span>
                                                                                    <span class="path3"></span>
                                                                                </i>    
                                                                                </span>
                                                                                <span class="divider"></span>
                                                                                <span class="float-right h4">{{ productSubTotal| CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator}}</span>
                                                                            </p>
                                                                            <p class="divided"
                                                                                *ngIf="selectedCustomerDetails?.TaxName">
                                                                                <span>
                                                                                    {{ 'TRANSLATE.QUOTE_LINE_ITEM_LABEL_TAX' |
                                                                                    translate }} ({{
                                                                                    selectedCustomerDetails.TaxName }})
                                                                                        <i class="ki-duotone ki-information-2 fs-5 ms-1" aria-hidden="true"   ngbTooltip="{{'TRANSLATE.QUOTE_LINE_ITEM_TAX_TOOLTIP_TYPE' | translate}}: {{'TRANSLATE.'+taxType | translate}}. {{'TRANSLATE.QUOTE_LINE_ITEM_TAX_TOOLTIP_APPROXIMATE' | translate}}">
                                                                                            <span class="path1"></span>
                                                                                            <span class="path2"></span>
                                                                                            <span class="path3"></span>
                                                                                        </i> 
                                                                                    </span>
                                                                                <span class="divider"></span>
                                                                                <span class="float-right h4">{{ totalTaxAmount |
                                                                                    CurrencyFilter: CurrencySymbol :CurrencyDecimalPlaces :CurrencyThousandSeperator :CurrencyDecimalSeperator }}<small>({{
                                                                                        selectedCustomerDetails.TaxPercentage
                                                                                        }}%)</small></span>
                                                                            </p>
                                                                            <p class="divided" *ngIf="totalDiscount > 0">
                                                                                <span>
                                                                                    {{'TRANSLATE.QUOTE_LINE_ITEM_LABEL_DISCOUNT' |translate }}
                                                                                        <i class="ki-duotone ki-information-2 fs-5 ms-1" aria-hidden="true" ngbTooltip="{{'TRANSLATE.QUOTE_LINE_ITEM_LABEL_DISCOUNT' | translate}}">
                                                                                            <span class="path1"></span>
                                                                                            <span class="path2"></span>
                                                                                            <span class="path3"></span>
                                                                                        </i>     
                                                                                </span>
                                                                                <span class="divider"></span>
                                                                                <span class="float-right h4">{{ totalDiscount |
                                                                                    CurrencyFilter: CurrencySymbol :
                                                                                    CurrencyDecimalPlaces :
                                                                                    CurrencyThousandSeperator :
                                                                                    CurrencyDecimalSeperator}}</span>
                                                                            </p>
                                                                            <p class="divided" *ngIf="totalQuoteValue">
                                                                                <span class="font-weight-bold pe-1">
                                                                                    {{
                                                                                    'TRANSLATE.QUOTE_LINE_ITEM_LABEL_TOTAL_QUOTE_VALUE'
                                                                                    | translate }}
                                                                                </span>
                                                                                <i class="ki-duotone ki-information-2 fs-5 ms-1" aria-hidden="true" ngbTooltip="{{'TRANSLATE.QUOTE_LINE_ITEM_TOTAL_QUOTE_VALUE_TOOLTIP' | translate}}">
                                                                                    <span class="path1"></span>
                                                                                    <span class="path2"></span>
                                                                                    <span class="path3"></span>
                                                                                </i> 
                                                                                <span class="divider"></span>
                                                                                <span class="float-right h4">{{ totalQuoteValue
                                                                                    | CurrencyFilter: CurrencySymbol :
                                                                                    CurrencyDecimalPlaces :
                                                                                    CurrencyThousandSeperator :
                                                                                    CurrencyDecimalSeperator}}</span>
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="flex-column" data-kt-stepper-element="content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card card-body shadow wizard-form-height fixed-height-four">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h4 class="fw-bold fs-2 text-dark"> {{'TRANSLATE.QUOTE_LABEL_TEXT_SIGNATURE_OPTIONS' |translate}}</h4>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <form class="form" [formGroup]="frmWizardFive" novalidate="novalidate"
                                                id="kt_stepper_example_basic_form">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="pb-5" data-wizard-type="step-content">
                                                            <!--begin::Section-->
                                                            <div class="form-group row">
                                                                    <div class="radio-list">
                                                                        <label
                                                                            class="radio radio-outline radio-outline-2x control-label mb-5">
                                                                            <input type="radio" class="form-check-input"
                                                                                formControlName="radios4" name="radios4"
                                                                                [value]="noSignature"
                                                                                (change)="IsShowCounterSign = false; IsSign = 'noSignature'"
                                                                                [required]="true">
                                                                            <span></span>
                                                                            {{'TRANSLATE.QUOTE_LABEL_TEXT_NO_SIGNATURE' | translate}}
                                                                        </label>
                                                                        <br />
                                                                        <label
                                                                            class="radio radio-outline radio-outline-2x control-label mb-10">
                                                                            <input type="radio" class="form-check-input"
                                                                                formControlName="radios4" name="radios4"
                                                                                [value]="includeSignature"
                                                                                (change)="IsShowCounterSign = true; IsSign = 'includeSignature'"
                                                                                [required]="true">
                                                                            <span></span>
                                                                            {{'TRANSLATE.QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE' | translate}}
                                                                        </label>
                                                                        <div *ngIf="IsShowCounterSign">
                                                                            <h4 class="mb-5 fw-bold text-dark"> {{'TRANSLATE.QUOTE_LABEL_TEXT_COUNTER_SIGNATURE' | translate}}</h4>
                                                                                <label class="checkbox form-check form-check-inline  checkbox-outline checkbox-outline-2x control-label">
                                                                                    <input type="checkbox"
                                                                                           class="form-check-input"
                                                                                           formControlName="Checkboxes1"
                                                                                           name="Checkboxes1"
                                                                                           (change)="onFormChange()">
                                                                                    <span></span>
                                                                                    {{'TRANSLATE.QUOTE_LABEL_TEXT_INCLUDE_SPACE_FOR_SIGNATURE' | translate}}
                                                                                </label>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                                </div>
                                                
                                            </div>
                                        </div>
                                         <div class="col-md-6 shadow  bg-white rounded sc pl-7 container "
                                                style="border: 1px solid #B7B5B4;" ng-show="vm.activeTab==='one'">
                                                <div class="fixed-height-four" id="sample-table-for-pdf" infiniteScroll 
                                                [infiniteScrollDistance]="0.3"
                                                [infiniteScrollThrottle]="50" 
                                                [scrollWindow]="false"
                                                style="overflow-y: auto;">
                                                    <app-quote-pdf-view [selectedCustomerDetails]="selectedCustomerDetails"
                                                    [frmAddQuote_QuoteName]="frmAddQuote_QuoteName"
                                                    [addressDetails]="addressDetails" 
                                                    [QuoteContact]="QuoteContact"
                                                    [users]="users" 
                                                    [quoteLineItemsData]="quoteLineItemsData"
                                                    [totalQuoteValue]="totalQuoteValue"
                                                    [DisplayTotalFinalSalePrice]="DisplayTotalFinalSalePrice"
                                                    [totalTaxAmount]="totalTaxAmount" 
                                                    [CommentsToBuyer]="CommentsToBuyer"
                                                    [PurchaseTerms]="PurchaseTerms" 
                                                    [IsShowCounterSign]="IsShowCounterSign"
                                                    [IsCounterSign]="IsCounterSign" 
                                                    [PDFlogobase64]="PDFlogobase64"
                                                    [CurrencySymbol]="CurrencySymbol"
                                                    [CurrencyDecimalPlaces]="CurrencyDecimalPlaces"
                                                    [CurrencyThousandSeperator]="CurrencyThousandSeperator"
                                                    [CurrencyDecimalSeperator]="CurrencyDecimalSeperator"
                                                    [currentDate]="currentDate"
                                                    [frmAddQuote_ExpiresOn]="frmAddQuote_ExpiresOn"></app-quote-pdf-view>
                                                </div>

                                            </div>
                                    </div>
                                </div>

                                <div class="flex-column" data-kt-stepper-element="content">
                                    <div class="col-md-8 shadow  wizard-form-height p-8 mb-5 bg-white rounded sc pl-7 container "
                                        style="border: 1px solid #B7B5B4;" ng-show="vm.activeTab==='one'">
                                    
                                        <div id="sample-table-for-pdf-view" >
                                            <app-quote-pdf-view [selectedCustomerDetails]="selectedCustomerDetails"
                                                [frmAddQuote_QuoteName]="frmAddQuote_QuoteName"
                                                [addressDetails]="addressDetails" 
                                                [QuoteContact]="QuoteContact"
                                                [users]="users" 
                                                [quoteLineItemsData]="quoteLineItemsData"
                                                [totalQuoteValue]="totalQuoteValue"
                                                [DisplayTotalFinalSalePrice]="DisplayTotalFinalSalePrice"
                                                [totalTaxAmount]="totalTaxAmount" 
                                                [CommentsToBuyer]="CommentsToBuyer"
                                                [PurchaseTerms]="PurchaseTerms" 
                                                [IsShowCounterSign]="IsShowCounterSign"
                                                [IsCounterSign]="IsCounterSign" 
                                                [PDFlogobase64]="PDFlogobase64"
                                                [CurrencySymbol]="CurrencySymbol"
                                                [CurrencyDecimalPlaces]="CurrencyDecimalPlaces"
                                                [CurrencyThousandSeperator]="CurrencyThousandSeperator"
                                                [CurrencyDecimalSeperator]="CurrencyDecimalSeperator"
                                                [currentDate]="currentDate"
                                                [frmAddQuote_ExpiresOn]="frmAddQuote_ExpiresOn"></app-quote-pdf-view>
                                        </div>
                                        <!-- <button (click)="onExportPDF()">Export PDF</button> -->
                                    </div>
                                </div>
                            </div>
                            <!--begin::Actions-->
                            <div class="d-flex flex-stack pt-6 pe-8">
                                <!--begin::Wrapper-->
                                <div class="me-2">
                                    
                                </div>
                                <!--end::Wrapper-->

                                <!--begin::Wrapper-->
                                <div>
                                    <button class="btn btn-light-primary me-2" data-kt-stepper-action="previous"> {{'TRANSLATE.QUOTE_MOVE_BACK_TO_STATE' | translate}} </button>
                                    <button type="button" class="btn btn-secondary me-2"
                                        [disabled]="[cloudHubConstants.QUOTE_LIST_STATUS_APPROVED,cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED, cloudHubConstants.QUOTE_LIST_STATUS_DELETED,cloudHubConstants.QUOTE_LIST_STATUS_ORDER_PROCESSED].includes(this.QuoteStatus)"
                                        (click)="saveQuote(true)"
                                        [hidden]="permissions.SaveQuote !== 'Allowed' || this.PageMode === 'View' || this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED">
                                        <span class="indicator-label" appBusyLoader>
                                            {{'TRANSLATE.BTN_QUOTE_SAVE_DRAFT' | translate}}
                                        </span>
                                    </button>
                                    <button class="btn btn-light-primary me-2" data-kt-stepper-action="submit" 
                                    *ngIf="QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED " (click)="copyToClipboard()">
                                    <span class="indicator-label">
                                        {{'TRANSLATE.BTN_QUOTE_COPY_URL' | translate}}
                                    </span>
                                </button>
                                <!-- QuoteStatus: {{ QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED}} -->

                                    <button  class="btn btn-light-primary me-2" data-kt-stepper-action="submit" (click)="saveQuote(false)"
                                        [hidden]="permissions.SaveQuote !== 'Allowed'  || QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_APPROVED || this.QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_DELETED || QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_EXPIRED">
                                        <span class="indicator-label" >
                                            {{( QuoteStatus === cloudHubConstants.QUOTE_LIST_STATUS_PUBLISHED ? 'TRANSLATE.BTN_QUOTE_REPUBLISH' : 'TRANSLATE.BTN_QUOTE_PUBLISH') | translate }}
                                        </span>
                                    </button>
                                    <button class="btn btn-light-primary" data-kt-stepper-action="next"> {{'TRANSLATE.QUOTE_MOVE_TO_STATE' | translate}} </button>
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Actions-->
                        </div>
                    </div>
                </div>
           
        </div>
    </div>
</div>
<!--end::Stepper-->
