import { AfterViewInit, Directive, ElementRef, SimpleChanges, OnChanges, TemplateRef, ViewChild, AfterViewChecked } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DynamicTemplateService } from "src/app/services/dynamic-template.service";
import { PermissionService } from "src/app/services/permission.service";
import { CloudHubConstants } from "./constants/cloudHubConstants";
import { AppSettingsService } from "src/app/services/app-settings.service";
import { FormGroup } from "@angular/forms";
@Directive()
export abstract  class C3BaseComponent implements AfterViewInit, OnChanges {

    showEditName: boolean = false;
    public permissionlst:string[] =[];
    private permissionStatus:string;
    public  redirectUrl:string = "/";
    _subscription: Subscription;
    stopSkelton:boolean = false;
    get cloudHubConstants() {
        return CloudHubConstants;
    }
    public defaultPageCount:number = 10;
    formBuilderGroupName: string;
    isActionHeaderSet:boolean = false

    @ViewChild('actionHeader', { static: true }) childTemplate: TemplateRef<any>|null = null;
    @ViewChild('fieldName') fieldName!: ElementRef;

    
    constructor(public _permissionService:PermissionService,
        public _dynamicTemplateService:DynamicTemplateService,
        public _router: Router,
        protected _appSettingsService:AppSettingsService)
    {
        this.defaultPageCount = this._appSettingsService.$rootScope.DefaultPageCount;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if(this.childTemplate){
                this.actionHeaderLoader();
            }        
        },100)
        
    }
 
    ngOnChanges(changes :SimpleChanges){
        this.permissionCheck();
    }
    onShoweditButtonBlur() {
        this.showEditName = false;
    }

    onShowEditName() {
        this.showEditName = true;
        setTimeout(() => {
            this.fieldName.nativeElement.focus();
        }, 100)
    }
    
    permissionCheck(){
        if(this.permissionlst.length > 0){
            this.permissionlst.forEach(v=>{
                if(this.permissionStatus == "Allowed"){
                    this.permissionStatus = this._permissionService.hasPermission(v);
                } 
            })
            if(this.permissionStatus != "Allowed"){
                this._router.navigate([this.redirectUrl]);
            }
        }
        
    }

    actionHeaderLoader(){
        // if(this.childTemplate){
        //     this._dynamicTemplateService.sendTemplate(this.childTemplate);
        // } 
        if(this.childTemplate){
            this._dynamicTemplateService.sendTemplate(this.childTemplate);
        }
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
        this._dynamicTemplateService.sendTemplate(null);
    }

    getTimes(n: number): any[] {
        return new Array<number>(Math.floor(n));
    }

    onLazyLoad(item: any) {
        item.loaded = true;  
    }

    isDirtyCheck(){
        Object.keys((this[this.formBuilderGroupName] as FormGroup).controls).forEach(v=>{
            let control= (this[this.formBuilderGroupName]as FormGroup).controls[v];
            if(!control.touched){
                control.markAsPristine()
            }
        })
    }
}
